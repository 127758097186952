import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import { filledArray } from '../../../../utils/collections';
import TextStructure from '../../../../components/molecules/TextStructure';
import { View } from 'react-native';
import { CompleteTheSentenceWithState } from '../../../../components/molecules/CompleteTheSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'alR',
  description: 'alR',
  keywords: ['Times-table', '11', 'Multiply'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 11, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 11, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ left: 11, right: numberC1, sign: 'multiply', answer: 'result' });

    const eqD = getBinOpEquation({ left: 11, right: numberD1, sign: 'multiply', answer: 'right' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'alS',
  description: 'alS',
  keywords: ['Times-table', '11', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ right: 11, result: numberA1, sign: 'divide', answer: 'result' });

    const eqB = getBinOpEquation({ right: 11, result: numberB1, sign: 'divide', answer: 'left' });

    const eqC = getBinOpEquation({ right: 11, result: numberC1, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ right: 11, result: numberD1, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'alT',
  description: 'alT',
  keywords: ['Times-table', '11', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    variant: z.enum(['completeBottomRow', 'splitBottomRowBy11', 'fillBottomRowWith11'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const variant = getRandomFromArray([
      'completeBottomRow',
      'splitBottomRowBy11',
      'fillBottomRowWith11'
    ] as const);

    return { number1, variant };
  },

  Component: ({ question: { number1, variant }, translate, ...props }) => {
    const multiplier = 11;
    const number3 = number1 * multiplier;

    let answerBoxes: number[];
    let answerIndices: number[][];
    let barArray: number[];

    // Three variantions of bar model
    switch (variant) {
      case 'fillBottomRowWith11':
        answerIndices = [[0], []];
        barArray = filledArray(multiplier, number1);
        break;
      case 'splitBottomRowBy11':
        answerIndices = [[0], []];
        barArray = filledArray(number1, multiplier);
        break;
      case 'completeBottomRow':
        answerBoxes = [...Array(multiplier).keys()];
        answerIndices = [[], answerBoxes];
        barArray = filledArray(number1, multiplier);
        break;
    }

    const numbers = [[number3], barArray];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        sameRowColor
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'alU',
  description: 'alU',
  keywords: ['Multiple', '11'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(132)
      .array()
      .length(6)
      .refine(
        numbers => numbers.filter(x => x % 11 === 0).length >= 2,
        'At least 2 multiples of 11'
      )
  }),
  simpleGenerator: () => {
    const numMultiples = randomIntegerInclusive(2, 5);

    const multiples = randomUniqueIntegersInclusive(1, 132, numMultiples, {
      constraint: x => x % 11 === 0
    });

    const notMultiples = randomUniqueIntegersInclusive(1, 132, 6 - numMultiples, {
      constraint: x => x % 11 !== 0
    });

    const numbers = [...multiples, ...notMultiples];

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMultiplesOfX(11)}
        testCorrect={numbers.filter(it => it % 11 === 0)}
        multiSelect
        items={() =>
          shuffle(
            numbers.map(number => ({
              value: number,
              component: number.toLocaleString()
            })),
            { random: seededRandom(props.question) }
          )
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'alV',
  description: 'alV',
  keywords: ['Multiply', 'Divide', '11', 'Problem'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12)
    })
    .refine(val => val.numberA1 !== val.numberB1, 'All numbers must be different.'),
  simpleGenerator: () => {
    const [numberA1, numberB1] = randomUniqueIntegersInclusive(2, 12, 2);
    return { numberA1, numberB1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1 },
      translate,
      displayMode
    } = props;

    return (
      <QF3Content
        title={translate.instructions.completeSentences()}
        inputType="numpad"
        Content={({ dimens }) => {
          return (
            <View style={[dimens, { justifyContent: 'center', alignItems: 'flex-start' }]}>
              <TextStructure
                textVariant="WRN400"
                sentence={translate.answerSentences.footballTeamHas11Players()}
              />
              <CompleteTheSentenceWithState
                id="sentence_1"
                sentence={translate.answerSentences.varTeamsHaveAnsPlayers(
                  numberA1.toLocaleString()
                )}
                testCorrect={userAnswer => userAnswer[0] === (numberA1 * 11).toString()}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                defaultState={
                  displayMode === 'markscheme' ? [(numberA1 * 11).toLocaleString()] : undefined
                }
              />
              <CompleteTheSentenceWithState
                id="sentence_2"
                sentence={translate.answerSentences.ansTeamsHaveVarPlayers(
                  (numberB1 * 11).toLocaleString()
                )}
                testCorrect={userAnswer => userAnswer[0] === numberB1.toString()}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                defaultState={
                  displayMode === 'markscheme' ? [numberB1.toLocaleString()] : undefined
                }
              />
            </View>
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'alW',
  description: 'alW',
  keywords: ['Times-table', '11', 'Multiply'],
  schema: z.object({
    number1: z.number().int().min(13).max(45)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(13, 45);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: number1, right: 10, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: number1, right: 11, sign: 'multiply', answer: 'result' });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Num11TimesTableAndDivisionFacts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
