import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import ColumnOperations from '../../../../components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { SUB } from '../../../../constants';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation } from '../../../../utils/math';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aic',
  description: 'aic',
  keywords: ['Subtraction', 'No exchange'],
  schema: z
    .object({
      minuend: z.number().int().min(1002).max(9999),
      subtrahend: z.number().int().min(1001).max(9998)
    })
    .refine(val => val.minuend > val.subtrahend, 'subtrahend must be less than minuend.'),
  simpleGenerator: () => {
    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(1002, 9999, { constraint: x => x % 10 !== 0 });

        const subtrahend = randomIntegerInclusive(1001, minuend - 1, {
          constraint: x => x % 10 !== 0
        });

        return { minuend, subtrahend };
      },
      // Only permit them if they do not exchange
      ({ minuend, subtrahend }) => numbersDoNotExchange(minuend, -subtrahend)
    );

    return {
      minuend,
      subtrahend
    };
  },
  Component: ({ question: { minuend, subtrahend }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToHelpCalculateX(
          `${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()}`
        )}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(minuend)}
            columnsToShow={[3, 2, 1, 0]}
            counterVariant={'decimalCounter'}
            headerVariant={'shortName'}
            dimens={dimens}
          />
        )}
        testCorrect={[(minuend - subtrahend).toString()]}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aid',
  description: 'aid',
  keywords: ['Subtraction', 'Column'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(1000)
        .max(8998)
        .refine(num => num % 100 !== 0, 'number1 cannot be a multiple of 100'),
      number2: z
        .number()
        .int()
        .min(1001)
        .max(8999)
        .refine(num => num % 100 !== 0, 'number2 cannot be a multiple of 100')
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 and number2 must not exchange.'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1001, 8998, { constraint: x => x % 100 !== 0 });

        const number2 = randomIntegerInclusive(1001, 9999 - number1, {
          constraint: x => x % 100 !== 0
        });

        return { number1, number2 };
      },
      ({ number1, number2 }) => numbersDoNotExchange(number1, number2)
    );

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const answerMissingDigits = range(0, number1.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={number3}
        bottomNumber={number2}
        operation={SUB}
        answerNumber={number1}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number1, answerMissingDigits.length)
          }
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aie',
  description: 'aie',
  keywords: ['Subtraction', 'No exchange', 'Bar model'],
  schema: z.object({
    minuend: z.number().int().min(1002).max(9999),
    subtrahend: z.number().int().min(1001).max(9998),
    isMinuendTopRow: z.boolean(),
    isAnswerBoxLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(1002, 9999, {
          constraint: x => x % 10 !== 0
        });

        const subtrahend = randomIntegerInclusive(1001, minuend - 1, {
          constraint: x => x % 10 !== 0
        });

        return { minuend, subtrahend };
      },
      // Only permit them if they do not exchange
      ({ minuend, subtrahend }) => numbersDoNotExchange(minuend, -subtrahend)
    );

    const isMinuendTopRow = getRandomBoolean();

    const isAnswerBoxLeft = getRandomBoolean();

    return { minuend, subtrahend, isMinuendTopRow, isAnswerBoxLeft };
  },

  Component: props => {
    const {
      question: { minuend, subtrahend, isMinuendTopRow, isAnswerBoxLeft },
      translate
    } = props;

    const difference = minuend - subtrahend;

    const [subtrahendRow, answerRow] = isAnswerBoxLeft
      ? [[difference, subtrahend], [0]]
      : [[subtrahend, difference], [1]];

    const numbers = isMinuendTopRow ? [[minuend], subtrahendRow] : [subtrahendRow, [minuend]];

    const answerIndices = isMinuendTopRow ? [[], answerRow] : [answerRow, []];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={minuend}
        oneFontSize
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question4 = newQuestionContent({
  uid: 'aif',
  description: 'aif',
  keywords: ['Subtraction', 'Column'],
  schema: z
    .object({
      numberA1: z.number().int().min(1000).max(9899),
      numberA2: z.number().int().min(100).max(999),
      numberB1: z.number().int().min(1000).max(9899),
      numberB2: z.number().int().min(100).max(999),
      missingDigit: z.enum(['numberA1 tens', 'numberA2 ones', 'numberA2 tens'])
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.numberB2),
      'numberB1 and numberB2 must not exchange.'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberA1 = randomIntegerInclusive(1000, 9899);
        const numberA2 = randomIntegerInclusive(100, 999, {
          constraint: x => x % 100 !== 0
        });
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => numbersDoNotExchange(numberA1, numberA2)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberB1 = randomIntegerInclusive(1000, 9899);
        const numberB2 = randomIntegerInclusive(101, 999, {
          constraint: x => x % 100 !== 0
        });
        return { numberB1, numberB2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberB1, numberB2 }) => numbersDoNotExchange(numberB1, numberB2)
    );

    const missingDigit = getRandomFromArray([
      'numberA1 tens',
      'numberA2 ones',
      'numberA2 tens'
    ] as const);

    return { numberA1, numberA2, numberB1, numberB2, missingDigit };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, missingDigit },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        topNumber: missingDigit === 'numberA1 tens' ? numberA2 : numberA1 + numberA2,
        bottomNumber:
          missingDigit === 'numberA1 tens'
            ? numberA1 + numberA2
            : missingDigit === 'numberA2 ones'
            ? numberA2 * 10
            : numberA2,
        isCorrect: false,
        topBlankDigits: missingDigit === 'numberA1 tens' ? [1] : [],
        bottomBlankDigits:
          missingDigit === 'numberA2 ones' ? [0] : missingDigit === 'numberA2 tens' ? [1] : []
      };
      const eqB = {
        topNumber: numberB1 + numberB2,
        bottomNumber: numberB2,
        isCorrect: true,
        topBlankDigits: [],
        bottomBlankDigits: []
      };
      return shuffle([eqA, eqB], { random: seededRandom(props.question) });
    }, [missingDigit, numberA1, numberA2, numberB1, numberB2, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.whichColSubIsLaidOutCorrectly()}<br/>${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.whichColSubIsLaidOutCorrectly()}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={2}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={SUB}
                dimens={{ width: dimens.width, height: dimens.height - 30 }}
                removeExtraCells
                topBlankDigits={equation.topBlankDigits}
                bottomBlankDigits={equation.bottomBlankDigits}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aig',
  description: 'aig',
  keywords: ['Subtraction', 'Column'],
  schema: z
    .object({
      number1: z.number().int().min(1000).max(8999),
      number2: z.number().int().min(1000).max(8999)
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 and number2 must not exchange.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1000, 8999);

    const number2 = randomIntegerInclusive(1000, 9999 - number1, {
      constraint: x => numbersDoNotExchange(number1, x)
    });

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutXSubtractY(
          number3.toLocaleString(),
          number2.toLocaleString()
        )}
        testCorrect={[number1.toString()]}
        sentence={`${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`}
        questionHeight={400}
        {...props}
      />
    );
  },
  questionHeight: 400
});

const Question6 = newQuestionContent({
  uid: 'aih',
  description: 'aih',
  keywords: ['Subtraction', 'Column'],
  schema: z
    .object({
      answerNumber: z.number().int().min(1000).max(8999),
      bottomNumber: z.number().int().min(1000).max(8999),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer']),
      missingThousands: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => numbersDoNotExchange(val.answerNumber, val.bottomNumber),
      'answerNumber and bottomNumber must not exchange.'
    ),
  simpleGenerator: () => {
    const answerNumber = randomIntegerInclusive(1000, 8999);

    const bottomNumber = randomIntegerInclusive(1000, 9999 - answerNumber, {
      constraint: x => numbersDoNotExchange(answerNumber, x)
    });

    const numberWithExtraMissingDigit = getRandomFromArray(['top', 'bottom', 'answer'] as const);

    const [missingOnes, missingTens, missingHundreds, missingThousands] = shuffle([
      'top',
      'bottom',
      'answer',
      numberWithExtraMissingDigit
    ] as const);

    return {
      answerNumber,
      bottomNumber,
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    };
  },

  Component: props => {
    const {
      question: {
        answerNumber,
        bottomNumber,
        missingOnes,
        missingTens,
        missingHundreds,
        missingThousands
      },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    );
    const topNumber = answerNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={SUB}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          }
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwo4DigitNumbersNoExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
