import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD, SUB } from '../../../../constants';
import { useMemo } from 'react';
import { arrayHasNoDuplicates, arraysHaveSameContents, range } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { numbersOnlyExchangeAt } from '../../../../utils/exchanges';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';

////
// Questions
////

// Question1 exported to ai4
const Question1 = newQuestionContent({
  uid: 'aiA',
  description: 'aiA',
  keywords: ['Estimate', 'Round'],
  schema: z.object({
    number1: z.number().min(2000).max(8000).multipleOf(1000),
    number4: z.number(),
    selectables: z.number().int().array()
  }),
  simpleGenerator: () => {
    const { selectables, number1, number4 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusiveStep(2000, 8000, 1000);
        const number4PlusOrMinus = getRandomFromArray(['add', 'subtract'] as const);
        const number4Factor = randomIntegerInclusive(1, 350);
        const number4 =
          number4PlusOrMinus === 'add' ? number1 + number4Factor : number1 - number4Factor;

        return {
          selectables: shuffle([
            number1,
            number1 - 1000,
            number1 + 1000,
            Math.floor(number4 / 10),
            number4 % 1000,
            number1 / 10
          ]),
          number1,
          number4
        };
      },
      ({ selectables }) => arrayHasNoDuplicates(selectables)
    );

    return {
      selectables,
      number1,
      number4
    };
  },
  Component: props => {
    const {
      question: { selectables, number1, number4 },
      translate
    } = props;
    return (
      <QF10SelectNumbers
        title={translate.instructions.selectWhichNumberIsClosestToX(number4.toLocaleString())}
        pdfTitle={translate.instructions.circleWhichNumberIsClosestToX(number4.toLocaleString())}
        testCorrect={[number1]}
        items={selectables.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
export const aiA = Question1;

// Question2 exported to ai5
const Question2 = newQuestionContent({
  uid: 'aiB',
  description: 'aiB',
  keywords: ['Estimate'],
  schema: z
    .object({
      number1: z.number().int().min(1000).max(8000).multipleOf(1000),
      number2: z.number().int().min(1000).max(8000).multipleOf(1000),
      number4Factor: z
        .number()
        .int()
        .min(-240)
        .max(240)
        .refine(x => x !== 0, 'number4Factor cannot be zero'),
      number5Factor: z
        .number()
        .int()
        .min(-240)
        .max(240)
        .refine(x => x !== 0, 'number5Factor cannot be zero'),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(val => val.number1 + val.number2 < 10000, 'number1 + number2 must be less than 10,000')
    .refine(val => val.number1 !== val.number2, 'number1 and number2 must be different'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(1000, 8000, 1000);

    const number2 = randomIntegerInclusiveStep(1000, 9000 - number1, 1000, {
      constraint: x => x !== number1
    });

    const number4Factor = randomIntegerInclusive(-240, 240, {
      constraint: x => {
        if (x === 0) {
          return false;
        }
        // Limit the lower bound to be 950
        if (number2 === 1000 && Math.abs(x) > 50) {
          return false;
        }
        return true;
      }
    });

    const number5Factor = randomIntegerInclusive(-240, 240, {
      constraint: x => {
        if (x === 0) {
          return false;
        }
        // Limit the lower bound to be 950
        if (number2 === 1000 && Math.abs(x) > 50) {
          return false;
        }
        return true;
      }
    });

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { number1, number2, number4Factor, number5Factor, addOrSubtract };
  },
  Component: props => {
    const {
      question: { number1, number2, number4Factor, number5Factor, addOrSubtract },
      translate
    } = props;

    const number3 = number1 + number2;
    const number4 = number1 + number4Factor;
    const number5 = number2 + number5Factor;
    const number6 = number4 + number5;

    const instructionOp =
      addOrSubtract === ADD
        ? `${number4.toLocaleString()} ${ADD} ${number5.toLocaleString()}`
        : `${number6.toLocaleString()} ${SUB} ${number5.toLocaleString()}`;

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsEstimateAnswerTo(instructionOp)}
        pdfTitle={translate.instructions.useCardsEstimateAnswerToNum(instructionOp)}
        sentences={
          addOrSubtract === ADD
            ? [
                translate.answerSentences.numRoundedToTheNearestAmountIs(
                  number4.toLocaleString(),
                  1000
                ),
                translate.answerSentences.numRoundedToTheNearestAmountIs(
                  number5.toLocaleString(),
                  1000
                ),
                `<ans/> ${ADD} <ans/> = <ans/>`
              ]
            : [
                translate.answerSentences.numRoundedToTheNearestAmountIs(
                  number6.toLocaleString(),
                  1000
                ),
                translate.answerSentences.numRoundedToTheNearestAmountIs(
                  number5.toLocaleString(),
                  1000
                ),
                `<ans/> ${SUB} <ans/> = <ans/>`
              ]
        }
        testCorrect={answer => {
          if (addOrSubtract === ADD) {
            return (
              arraysHaveSameContents(answer[0], [number1]) &&
              arraysHaveSameContents(answer[1], [number2]) &&
              (arraysHaveSameContents(answer[2], [number1, number2, number3]) ||
                arraysHaveSameContents(answer[2], [number2, number1, number3]))
            );
          } else {
            return (
              arraysHaveSameContents(answer[0], [number3]) &&
              arraysHaveSameContents(answer[1], [number2]) &&
              arraysHaveSameContents(answer[2], [number3, number2, number1])
            );
          }
        }}
        items={range(1000, 9000, 1000)}
        sentencesStyle={{ alignItems: 'flex-start' }}
        pdfSentencesStyle={{ alignItems: 'flex-start' }}
        moveOrCopy="copy"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay:
            addOrSubtract === ADD
              ? [[number1], [number2], [number1, number2, number3]]
              : [[number1], [number2], [number3, number2, number1]]
        }}
      />
    );
  },
  questionHeight: 1200
});
export const aiB = Question2;

// Question3 exported to ai6
const Question3 = newQuestionContent({
  uid: 'aiC',
  description: 'aiC',
  keywords: ['Estimate'],
  schema: z.object({
    number4: z.number(),
    number5: z.number(),
    number6: z.number(),
    selectables: z.array(z.object({ value: z.number(), render: z.string() })),
    answer: z.number(),
    addOrSubtract: z.enum([ADD, SUB])
  }),
  simpleGenerator: () => {
    const { selectables, number4, number5, number6, answer, addOrSubtract } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusiveStep(2000, 7000, 1000);
        const number2 = randomIntegerInclusiveStep(2000, 7000, 1000, {
          constraint: x => x + number1 < 10000
        });

        const number4Adjust = randomIntegerInclusive(-240, 240, {
          constraint: x => x !== 0
        });

        const number5Adjust = randomIntegerInclusive(-240, 240, {
          constraint: x => x !== 0
        });

        const incorrectNumber1 = number4Adjust > 0 ? number1 + 1000 : number1 - 1000;

        const incorrectNumber2 = number5Adjust > 0 ? number2 + 1000 : number2 - 1000;

        const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

        const number3 = number1 + number2;

        const number4 = number1 + number4Adjust;

        const number5 = number2 + number5Adjust;

        const number6 = number4 + number5;

        let answer: number | null = null;

        const selectables: Array<{ value: number; render: string }> = [];

        const createSelectable = (num1: number, num2: number, factor: ADD | SUB) => {
          const multiplier = factor === ADD ? 1 : -1;
          return {
            value: num1 + num2 * multiplier,
            render: `${num1.toLocaleString()} ${factor} ${num2.toLocaleString()}`
          };
        };

        if (addOrSubtract === ADD) {
          selectables.push(createSelectable(number4, number5, ADD));
          selectables.push(createSelectable(number1, number2, ADD));
          selectables.push(createSelectable(incorrectNumber1, number5, ADD));
          selectables.push(createSelectable(number4, incorrectNumber2, ADD));

          answer = number1 + number2;
        } else {
          selectables.push(createSelectable(number6, number5, SUB));
          selectables.push(createSelectable(number3, number2, SUB));
          selectables.push(createSelectable(number3, number5, SUB));
          selectables.push(createSelectable(number6, incorrectNumber2, SUB));

          answer = number3 - number2;
        }

        return {
          selectables,
          number4,
          number5,
          number6,
          answer,
          addOrSubtract
        };
      },
      ({ selectables }) => arrayHasNoDuplicates(selectables.map(selectable => selectable.value))
    );

    return {
      selectables: shuffle(selectables),
      number4,
      number5,
      number6,
      answer,
      addOrSubtract
    };
  },
  Component: props => {
    const {
      question: { selectables, number4, number5, number6, answer, addOrSubtract },
      translate
    } = props;
    return (
      <QF10SelectNumbers
        title={
          addOrSubtract === ADD
            ? translate.instructions.selectWhichCalculationToEstimateAdd(
                number4.toLocaleString(),
                number5.toLocaleString()
              )
            : translate.instructions.selectWhichCalculationToEstimateSubtract(
                number6.toLocaleString(),
                number5.toLocaleString()
              )
        }
        pdfTitle={
          addOrSubtract === ADD
            ? translate.instructions.circleWhichCalculationToEstimateAdd(
                number4.toLocaleString(),
                number5.toLocaleString()
              )
            : translate.instructions.circleWhichCalculationToEstimateSubtract(
                number6.toLocaleString(),
                number5.toLocaleString()
              )
        }
        testCorrect={selectables
          .filter(it => it.value === answer)
          .map(selectable => selectable.value)}
        items={selectables.map(selectable => ({
          value: selectable.value,
          component: selectable.render
        }))}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});
export const aiC = Question3;

// Question4 exported to Qs ai7, ajD, anX
const Question4 = newQuestionContent({
  uid: 'aiD',
  description: 'aiD',
  keywords: ['Estimate', 'Match'],
  schema: z
    .object({
      numberA1: z.number().int().min(2000).max(7000).multipleOf(1000),
      numberA2: z.number().int().min(2000).max(7000).multipleOf(1000),
      numberA1Factor: z.number().int().min(-240).max(240),
      numberA2Factor: z.number().int().min(-240).max(240),
      numberB1: z.number().int().min(2000).max(7000).multipleOf(1000),
      numberB2: z.number().int().min(2000).max(7000).multipleOf(1000),
      numberB1Factor: z.number().int().min(-240).max(240),
      numberB2Factor: z.number().int().min(-240).max(240),
      numberC1: z.number().int().min(2000).max(7000).multipleOf(1000),
      numberC2: z.number().int().min(2000).max(7000).multipleOf(1000),
      numberC1Factor: z.number().int().min(-240).max(240),
      numberC2Factor: z.number().int().min(-240).max(240),
      numberD1: z.number().int().min(2000).max(7000).multipleOf(1000),
      numberD2: z.number().int().min(2000).max(7000).multipleOf(1000),
      numberD1Factor: z.number().int().min(-240).max(240),
      numberD2Factor: z.number().int().min(-240).max(240)
    })
    .refine(
      val =>
        val.numberA1Factor !== 0 &&
        val.numberA2Factor !== 0 &&
        val.numberB1Factor !== 0 &&
        val.numberB2Factor !== 0 &&
        val.numberC1Factor !== 0 &&
        val.numberC2Factor !== 0 &&
        val.numberD1Factor !== 0 &&
        val.numberD2Factor !== 0,
      'No factors can be zero.'
    )
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.numberA1,
          val.numberC1,
          val.numberB1 + val.numberB2,
          val.numberD1 + val.numberD2
        ]),
      'numberA1, numberC1, numberB1+numberB2 and numberD1+numberD2 must all be different.'
    )
    .refine(
      val =>
        val.numberA1 + val.numberA2 < 10000 &&
        val.numberB1 + val.numberB2 < 10000 &&
        val.numberD1 + val.numberD2 < 10000 &&
        val.numberD1 + val.numberD2 < 10000,
      'Each number pair must sum to less than 10,000'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 } =
      rejectionSample(
        () => {
          // Generate 8 random integers independently.
          const [numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2] =
            range(1, 8).map(() => randomIntegerInclusiveStep(2000, 7000, 1000));
          return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
        },
        // Only permit them if each pair sums to less than 1,000, and if A1, C1, B1+B2 and D1+D2 are all different.
        ({ numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 }) => {
          return (
            numberA1 + numberA2 < 10000 &&
            numberB1 + numberB2 < 10000 &&
            numberC1 + numberC2 < 10000 &&
            numberD1 + numberD2 < 10000 &&
            arrayHasNoDuplicates([numberA1, numberC1, numberB1 + numberB2, numberD1 + numberD2])
          );
        }
      );

    const [
      numberA1Factor,
      numberA2Factor,
      numberB1Factor,
      numberB2Factor,
      numberC1Factor,
      numberC2Factor,
      numberD1Factor,
      numberD2Factor
    ] = range(1, 8).map(() =>
      randomIntegerInclusive(-240, 240, {
        constraint: x => x !== 0
      })
    );

    return {
      numberA1,
      numberA2,
      numberA1Factor,
      numberA2Factor,
      numberB1,
      numberB2,
      numberB1Factor,
      numberB2Factor,
      numberC1,
      numberC2,
      numberC1Factor,
      numberC2Factor,
      numberD1,
      numberD2,
      numberD1Factor,
      numberD2Factor
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberA1Factor,
        numberA2Factor,
        numberB1,
        numberB2,
        numberB1Factor,
        numberB2Factor,
        numberC1,
        numberC2,
        numberC1Factor,
        numberC2Factor,
        numberD1,
        numberD2,
        numberD1Factor,
        numberD2Factor
      },
      translate
    } = props;

    const numberA4 = numberA1 + numberA1Factor;
    const numberA5 = numberA2 + numberA2Factor;
    const numberA6 = numberA4 + numberA5;

    const numberB4 = numberB1 + numberB1Factor;
    const numberB5 = numberB2 + numberB2Factor;

    const numberC4 = numberC1 + numberC1Factor;
    const numberC5 = numberC2 + numberC2Factor;
    const numberC6 = numberC4 + numberC5;

    const numberD4 = numberD1 + numberD1Factor;
    const numberD5 = numberD2 + numberD2Factor;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: `${numberA6.toLocaleString()} ${SUB} ${numberA5.toLocaleString()}`,
        value: numberA1
      };
      const statement2 = {
        statement: `${numberB4.toLocaleString()} ${ADD} ${numberB5.toLocaleString()}`,
        value: numberB1 + numberB2
      };
      const statement3 = {
        statement: `${numberC6.toLocaleString()} ${SUB} ${numberC5.toLocaleString()}`,
        value: numberC1
      };
      const statement4 = {
        statement: `${numberD4.toLocaleString()} ${ADD} ${numberD5.toLocaleString()}`,
        value: numberD1 + numberD2
      };
      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [
      numberA1,
      numberA5,
      numberA6,
      numberB1,
      numberB2,
      numberB4,
      numberB5,
      numberC1,
      numberC5,
      numberC6,
      numberD1,
      numberD2,
      numberD4,
      numberD5,
      props.question
    ]);

    const items = statements.map(({ value }) => value).sort((a, b) => a - b);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchEachCalcWithEstAnswer()}
        pdfTitle={translate.instructions.useCardsToMatchEachCalcWithEstAnswer()}
        items={items}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});
export const aiD = Question4;

const Question5 = newQuestionContent({
  uid: 'aiE',
  description: 'aiE',
  keywords: ['Estimate'],
  schema: z
    .object({
      number1: z.number().int().min(2000).max(7000).multipleOf(1000),
      number2: z.number().int().min(2000).max(7000).multipleOf(1000),
      number4Factor: z.number().int().min(-240).max(240),
      number5Factor: z.number().int().min(-240).max(240),
      addOrSubtract: z.enum(['add', 'subtract'])
    })
    .refine(val => val.number1 + val.number2 < 10000, 'number1 + number2 must be less than 10,000')
    .refine(val => val.number4Factor !== 0, 'number4Factor must not be 0')
    .refine(val => val.number5Factor !== 0, 'number5Factor must not be 0'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(2000, 7000, 1000);

    const number2 = randomIntegerInclusiveStep(2000, 7000, 1000, {
      constraint: x => x + number1 < 10000
    });

    const number4Factor = randomIntegerInclusive(-240, 240, {
      constraint: x => x !== 0
    });

    const number5Factor = randomIntegerInclusive(-240, 240, {
      constraint: x => x !== 0
    });

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    return { number1, number2, number4Factor, number5Factor, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, number2, number4Factor, number5Factor, addOrSubtract },
      translate
    } = props;

    const number3 = number1 + number2;

    const number4 = number1 + number4Factor;

    const number5 = number2 + number5Factor;

    const number6 = number4 + number5;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestXToEstimateAnswer(1000)}
        testCorrect={
          addOrSubtract === 'add'
            ? [[], [number1.toString(), number2.toString(), number3.toString()]] // number1 + number2 = number3
            : [[], [number3.toString(), number2.toString(), number1.toString()]] // number3 - number2 = number1
        }
        sentences={
          addOrSubtract === 'add'
            ? [
                `${number4.toLocaleString()} ${ADD} ${number5.toLocaleString()}`,
                `<ans/> ${ADD} <ans/> = <ans/>`
              ]
            : [
                `${number6.toLocaleString()} ${SUB} ${number5.toLocaleString()}`,
                `<ans/> ${SUB} <ans/> = <ans/>`
              ]
        }
        containerStyle={{ alignItems: 'center' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        {...props}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aiF',
  description: 'aiF',
  keywords: ['Estimate'],
  schema: z
    .object({
      number1: z.number().min(1760).max(7240),
      number2: z.number().min(1760).max(7240)
    })
    .refine(val => val.number1 + val.number2 < 10000, 'number1 + number2 must be less than 10,000'),
  simpleGenerator: () => {
    const { randTotal1, randTotal2 } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping number1 uniformly distributed
        const number1 = randomIntegerInclusiveStep(2000, 7000, 1000);
        const number2 = randomIntegerInclusiveStep(2000, 7000, 1000);
        const rand1 = randomIntegerInclusive(1, 240);
        const randOp1 = getRandomFromArray(['add', 'subtract']);
        const randTotal1 = randOp1 === 'add' ? number1 + rand1 : number1 - rand1;
        const rand2 = randomIntegerInclusive(1, 240);
        const randOp2 = getRandomFromArray(['add', 'subtract']);
        const randTotal2 = randOp2 === 'add' ? number2 + rand2 : number2 - rand2;
        return { randTotal1, randTotal2 };
      },
      // Only permit them if they exchanges at the ones
      ({ randTotal1, randTotal2 }) => numbersOnlyExchangeAt(randTotal1, randTotal2, 'ones')
    );

    return {
      number1: randTotal1,
      number2: randTotal2
    };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const correctAnswer = number1 + number2;
    const answer1 = `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${correctAnswer.toLocaleString()}`;
    const incorrectAnswer1 =
      correctAnswer + getRandomFromArray([-1000, 1000] as const, { random: seededRandom(answer1) });
    const incorrectAnswer2 =
      (correctAnswer - (correctAnswer % 100)) * 100 - 900 + (correctAnswer % 100);

    const answer2 = `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${incorrectAnswer1.toLocaleString()}`;
    const answer3 = `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${incorrectAnswer2.toLocaleString()}`;

    const selectables = shuffle([answer1, answer2, answer3], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.useEstimationToWorkoutCorrectCalculationSelectCorrectAnswer()}
        pdfTitle={translate.instructions.useEstimationToWorkoutCorrectCalculationCircleCorrectAnswer()}
        testCorrect={[answer1]}
        itemLayout={'column'}
        itemStyle={{ margin: 8 }}
        numItems={3}
        renderItems={() => {
          return selectables.map(eq => ({
            value: eq,
            component: <Text variant="WRN700">{eq}</Text>
          }));
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateAnswers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
