import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import { ADD, SUB } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { arraysHaveSameContentsUnordered, filledArray } from '../../../../utils/collections';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { compareFractions, simplify } from '../../../../utils/fractions';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModelInteractiveWithCrossesWithState } from '../../../../components/question/representations/BarModelInteractiveWithCrosses';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apE',
  description: 'apE',
  keywords: ['Add', 'Subtract', 'Fractions', 'Same denominator'],
  schema: z.object({
    addOrSubtract: z.enum([ADD, SUB]),
    barModel1Numerator: z.number().int(),
    barModel2Numerator: z.number().int()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    // Create numbers
    let barModel1Numerator: number;
    let barModel2Numerator: number;

    switch (addOrSubtract) {
      case ADD:
        barModel2Numerator = randomIntegerInclusive(1, 4);
        barModel1Numerator = randomIntegerInclusive(1, 4, {
          constraint: x => x + barModel2Numerator !== 5
        });
        break;
      case SUB:
        barModel1Numerator = randomIntegerInclusive(2, 9, {
          constraint: x => x !== 5
        });
        barModel2Numerator = randomIntegerInclusive(1, barModel1Numerator - 1, {
          constraint: x => barModel1Numerator - x !== 5
        });
        break;
    }

    return { addOrSubtract, barModel1Numerator, barModel2Numerator };
  },
  Component: props => {
    const {
      question: { addOrSubtract, barModel1Numerator, barModel2Numerator },
      translate
    } = props;

    // Answers
    const answer1 =
      addOrSubtract === ADD
        ? barModel1Numerator + barModel2Numerator
        : barModel1Numerator - barModel2Numerator;
    const answer2 = 5;

    const bars = [
      {
        rows: 1,
        cols: 5,
        shaded:
          addOrSubtract === ADD
            ? barModel1Numerator
            : barModel1Numerator > 5
            ? 5
            : barModel1Numerator
      },
      {
        rows: 1,
        cols: 5,
        shaded: addOrSubtract === ADD ? 0 : barModel1Numerator > 5 ? barModel1Numerator - 5 : 0
      }
    ];

    const sentence = `<frac n='${barModel1Numerator.toLocaleString()}' d='5'/> ${addOrSubtract.toLocaleString()} <frac n='${barModel2Numerator.toLocaleString()}' d='5'/> = <frac nAns='' dAns=''/>`;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.completeCalculation()}<br/>${translate.instructions.useTheBarModelsToHelp()}`}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [answer1, answer2])
        }
        inputMaxCharacters={2}
        sentence={sentence}
        Content={({ dimens: { width } }) => (
          <View style={{ flexDirection: 'row', columnGap: 64 }}>
            {addOrSubtract === ADD
              ? bars.map((bar, idx) => {
                  const defaultState = [
                    ...filledArray(true, bar.shaded),
                    ...filledArray(false, 5 - bar.shaded)
                  ];
                  return (
                    <BarModelInteractiveWithState
                      id={`barmodel-${idx}`}
                      key={`barmodel-${idx}`}
                      numberOfRows={bar.rows}
                      numberOfCols={bar.cols}
                      tableHeight={100}
                      tableWidth={(width - 100) / 2}
                      defaultState={[defaultState]}
                    />
                  );
                })
              : bars.map((bar, idx) => {
                  const shadedCells = [
                    ...filledArray(true, bar.shaded),
                    ...filledArray(false, 5 - bar.shaded)
                  ];
                  return (
                    <BarModelInteractiveWithCrossesWithState
                      id={`barmodel-${idx}`}
                      key={`barmodel-${idx}`}
                      numberOfRows={bar.rows}
                      numberOfCols={bar.cols}
                      tableHeight={100}
                      tableWidth={(width - 100) / 2}
                      shadedCells={[shadedCells]}
                      crossedCells={[[]]}
                      interactive
                      testComplete={() => true}
                    />
                  );
                })}
          </View>
        )}
        pdfDirection="column"
        questionHeight={900}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractionsBarModelsDoNotNeedToBeShaded(),
          answersToDisplay: [answer1.toLocaleString(), answer2.toLocaleString()]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'apF',
  description: 'apF',
  keywords: ['Addition', 'Subtraction', 'Fractions', 'Same denominator'],
  schema: z.object({
    number1: numberEnum([5, 7, 11, 13]),
    number2: z.number().int().min(1).max(11),
    number3: z.number().int().min(1).max(11),
    number4: numberEnum([5, 7, 11, 13]),
    number5: z.number().int().min(2).max(25),
    number6: z.number().int().min(1).max(24)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([5, 7, 11, 13] as const);
    // number2 + number3 < number1
    const number2 = randomIntegerInclusive(1, number1 - 2);
    const number3 = randomIntegerInclusive(1, number1 - 1 - number2);

    const number4 = getRandomFromArray([5, 7, 11, 13] as const);
    // number5 - number6 < number4
    const number5 = randomIntegerInclusive(2, 2 * number4 - 1);
    const number6 = randomIntegerInclusive(1, number5 - 1, {
      constraint: x => number5 - x < number4
    });

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate,
      displayMode
    } = props;

    // answer1 === number1
    const answer2 = number2 + number3;

    // answer3 === number4
    const answer4 = number5 - number6;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheCalculations()}
        testCorrect={userAnswer =>
          compareFractions([...userAnswer[0]], [answer2, number1]) &&
          compareFractions([...userAnswer[1]], [answer4, number4])
        }
        sentences={[
          `<frac n='${number2}' d='${number1}'/> ${ADD} <frac n='${number3}' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          `<frac n='${number5}' d='${number4}'/> ${SUB} <frac n='${number6}' d='${number4}'/> = <frac nAns='' dAns=''/>`
        ]}
        pdfDirection="row"
        sentenceStyle={{
          alignItems: 'center',
          marginHorizontal: displayMode !== 'digital' ? 128 : undefined
        }}
        pdfContainerStyle={{ flex: 1 }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer2.toLocaleString(), number1.toLocaleString()],
            [answer4.toLocaleString(), number4.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'apG',
  description: 'apG',
  keywords: ['Addition', 'Inverse', 'Fractions', 'Same denominator'],
  schema: z.object({
    number1: z.number().int().min(5).max(10),
    number2: z.number().int().min(6).max(19)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(5, 10);
    const number2 = randomIntegerInclusive(number1 + 1, 2 * number1 - 1);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const testCorrect = (answer: string[][]): boolean => {
      const [[ans1, ans2], [ans3, ans4]] = answer;

      return arraysHaveSameContentsUnordered([ans1, ans2], [ans3, ans4])
        ? false
        : parseInt(ans1) + parseInt(ans2) === number2 && parseInt(ans3) + parseInt(ans4) === number2
        ? true
        : false;
    };

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.whatCouldMissingNumeratorsBe()}
        testCorrect={testCorrect}
        fractionContainerStyle={{ height: 96 }}
        inputMaxCharacters={2}
        sentences={[
          `<frac nAns='' d='${number1}'/> ${ADD} <frac nAns='' d='${number1}'/> = <frac n='${number2}' d='${number1}'/>`,
          `<frac nAns='' d='${number1}'/> ${ADD} <frac nAns='' d='${number1}'/> = <frac n='${number2}' d='${number1}'/>`
        ]}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: `${translate.markScheme.anyPossibleCombinationOfNumeratorsThatSumToX(
            number2
          )}<br/>${translate.markScheme.doNotAcceptDuplicateSentences()}`
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'apH',
  description: 'apH',
  keywords: ['Addition', 'Subtraction', 'Fractions', 'Same denominator'],
  schema: z.object({
    name: nameSchema,
    drink: z.enum(['juice', 'water', 'milk', 'smoothie']),
    number1: numberEnum([3, 5, 7]),
    number2: z.number().int().min(1).max(6),
    number3: z.number().int().min(2).max(5),
    number4: z.number().int().min(4).max(13)
  }),
  simpleGenerator: () => {
    const name = getRandomName();
    const drink = getRandomFromArray(['juice', 'water', 'milk', 'smoothie'] as const);

    const number1 = getRandomFromArray([3, 5, 7] as const);
    const number2 = randomIntegerInclusive(1, number1 - 1);
    const number3 = randomIntegerInclusive(2, 5);
    const number4 = randomIntegerInclusive(number1 + 1, 2 * number1 - 1);

    return { name, drink, number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { name, drink, number1, number2, number3, number4 },
      displayMode,
      translate
    } = props;

    const ansNumerator = number3 * number1 + number2 - number4;
    const ansDenominator = number1;

    // Set flag depending on if answer simplifies to integer or not
    const [simplifiedAns1, simplifiedAns2] = simplify(ansNumerator, ansDenominator);
    const isInteger = simplifiedAns2 === 1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.howManyLitresLeftFraction(
          name,
          `<frac w='${number3}' n='${number2}' d='${number1}'/>`,
          drink,
          `<frac n='${number4}' d='${number1}'/>`
        )}
        testCorrect={isInteger ? [simplifiedAns1.toString()] : [ansNumerator.toString()]}
        sentence={isInteger ? '<ans/> litres' : `<frac nAns='' d='${number1}'/> litres`}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? 44 : 64 }}
        customMarkSchemeAnswer={{
          answersToDisplay: isInteger
            ? [simplifiedAns1.toLocaleString()]
            : [ansNumerator.toLocaleString()]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'apI',
  description: 'apI',
  keywords: ['Addition', 'Subtraction', 'Inverse', 'Fractions', 'Same denominator'],
  schema: z.object({
    number1: z.number().int().min(4).max(10),
    number2: z.number().int().min(5).max(19),
    number3: z.number().int().min(1).max(9),
    number4: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(4, 10);
    const number2 = randomIntegerInclusive(number1 + 1, 2 * number1 - 1);
    const number3 = randomIntegerInclusive(1, number1 - 1);

    const halfNumber1 = Math.floor(number1 * 0.5);
    const number4 = randomIntegerInclusive(halfNumber1 + 1, number1 - 1);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate,
      displayMode
    } = props;

    const answer1 = number2 - number3;
    const answer2 = number2 - number4;
    const answer3 = number2 - number1;

    const essentialSentence = `<frac n='${number3}' d='${number1}'/> ${ADD} <frac nAns='' d='${number1}'/> = <frac n='${number2}' d='${number1}'/>`;

    const sentenceTwo = getRandomFromArray(
      [
        {
          sentence: `<frac n='${number2}' d='${number1}'/> ${SUB} <frac nAns='' d='${number1}'/> = <frac n='${number4}' d='${number1}'/>`,
          answer: answer2
        },
        {
          sentence: `<frac n='${number2}' d='${number1}'/> ${SUB} <frac nAns='' d='${number1}'/> = 1`,
          answer: answer3
        }
      ] as const,
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumerators()}
        testCorrect={[[answer1.toString()], [sentenceTwo.answer.toString()]]}
        sentences={[essentialSentence, sentenceTwo.sentence]}
        fractionContainerStyle={{ height: 96 }}
        pdfDirection="row"
        sentenceStyle={{
          alignItems: 'center',
          marginHorizontal: displayMode !== 'digital' ? 128 : undefined
        }}
        pdfContainerStyle={{ flex: 1 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'apJ',
  description: 'apJ',
  keywords: ['Addition', 'Subtraction', 'Inverse', 'Fractions', 'Same denominator'],
  schema: z.object({
    number1: z.number().int().min(6).max(10),
    number2: z.number().int().min(4).max(9),
    number3: z.number().int().min(3).max(9),
    number4: z.number().int().min(3).max(9),
    number5: z.number().int().min(7).max(19),
    number6: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(6, 10);
    const halfNumber1 = Math.floor(number1 * 0.5);

    const number2 = randomIntegerInclusive(halfNumber1 + 1, number1 - 1);
    const [number3, number4] = randomUniqueIntegersInclusive(halfNumber1, number1 - 1, 2, {
      constraint: x => x !== number2
    });
    const number5 = randomIntegerInclusive(number1 + 1, number1 * 2 - 1);
    const number6 = randomIntegerInclusive(2, number1 - 1);

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    const answer1 = 2 * number1 - 2 * number2;
    const answer2 = 2 * number1 - number3 - number4;
    const answer3 = 3 * number1 - number5 - number6;

    const essentialSentence = `<frac n='${number2}' d='${number1}'/> ${ADD} <frac nAns='' d='${number1}'/> ${ADD} <frac n='${number2}' d='${number1}'/> = 2`;

    const sentenceTwo = getRandomFromArray(
      [
        {
          sentence: `<frac n='${number3}' d='${number1}'/> ${ADD} <frac nAns='' d='${number1}'/> ${ADD} <frac n='${number4}' d='${number1}'/> = 2`,
          answer: answer2
        },
        {
          sentence: `<frac n='${number5}' d='${number1}'/> ${ADD} <frac nAns='' d='${number1}'/> ${ADD} <frac n='${number6}' d='${number1}'/> = 3`,
          answer: answer3
        }
      ] as const,
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumerators()}
        testCorrect={[[answer1.toString()], [sentenceTwo.answer.toString()]]}
        sentences={[essentialSentence, sentenceTwo.sentence]}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractFractionsWithTheSameDenominator',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
