import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import Text from '../../../typography/Text';
import { colors } from '../../../../theme/colors';
import { useI18nContext } from '../../../../i18n/i18n-react';
import { TranslationFunctions } from '../../../../i18n/i18n-types';

const metreStick = (
  translate: TranslationFunctions,
  width: number,
  height: number,
  scaleFactor: number,
  alternateColor: boolean
) => {
  const [color1, color2] = alternateColor ? [colors.red, colors.yellow] : ['#0000FF', colors.white];

  return (
    <View style={{ flexDirection: 'row' }}>
      {countRange(10).map(num => (
        <View
          key={num}
          style={{
            width: width / 10,
            height: height,
            backgroundColor: num % 2 === 0 ? color1 : color2,
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: colors.black,
            borderWidth: 2,
            borderEndWidth: 0,
            borderLeftWidth: num === 0 ? 2 : 0,
            borderRightWidth: num === 9 ? 2 : 0
          }}
        >
          <Text
            variant={'WRN700'}
            numberOfLines={1}
            style={{
              lineHeight: undefined,
              color: num % 2 === 0 ? colors.white : colors.black,
              textAlign: 'right',
              fontSize: 9 * scaleFactor
            }}
          >
            {num === 9 ? translate.units.numberOfM(1) : ''}
          </Text>
        </View>
      ))}
    </View>
  );
};
type Props = {
  rulerLength: number;
};

export default function MetreSticks10Part({
  rulerLength,
  scaleFactor
}: Props & { scaleFactor: number }) {
  const { naturalWidth, naturalHeight, pixelsPerUnit, majorTicks } =
    MetreSticks10Part.getRulerSizingInfo({
      rulerLength
    });

  const translate = useI18nContext().LL;
  const totalWidth = naturalWidth * scaleFactor;
  const metreWidth = pixelsPerUnit * scaleFactor;

  const height = naturalHeight * scaleFactor;

  return (
    <View
      style={{
        width: totalWidth,
        height: height,
        flexDirection: 'row'
      }}
    >
      {countRange(majorTicks).map(i => (
        <View
          key={i}
          style={{
            width: metreWidth,
            height: height
          }}
        >
          {metreStick(translate, metreWidth, height, scaleFactor, i % 2 === 0)}
        </View>
      ))}
    </View>
  );
}

MetreSticks10Part.getRulerSizingInfo = ({ rulerLength }: Props) => {
  const METRE_STICK_WIDTH = 200;
  // A major tick is an entire metre-stick. We always show whole sticks.
  const majorTicks = Math.ceil(rulerLength);
  // The rule is made up of metre-sticks (100 each) joined together.
  const width = majorTicks * METRE_STICK_WIDTH;
  // Make the height bigger as there are more sticks
  const height = 13 * majorTicks;
  // A metre-stick is 200 pixels.
  const pixelsPerUnit = METRE_STICK_WIDTH;
  // Pixel 0 is worth 0m, and pixel 100 is worth 1m, so no offset needed.
  const startOffset = 0;
  return {
    naturalWidth: width,
    naturalHeight: height,
    pixelsPerUnit,
    startOffset,
    majorTicks
  };
};
