/* eslint-disable no-case-declarations */ // Needed for declaring consts in switch statement of Q aot.
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { useMemo } from 'react';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { arrayHasNoDuplicates, countRange, sortNumberArray } from '../../../../utils/collections';
import {
  Centimetres,
  Feet,
  Gallons,
  Inches,
  Metres,
  Ounces,
  Pints,
  Pounds,
  Stone,
  convertUnitsSuffix,
  createMeasurement
} from '../../../../utils/unitConversion';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';
import ContentBox from '../../../../components/molecules/ContentBox';
import { APPROX_EQUAL } from '../../../../constants';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF9DragIntoTableOfGroups from '../../../../components/question/questionFormats/QF9DragIntoTableOfGroups';
import Text from '../../../../components/typography/Text';
import { compareFloats } from '../../../../utils/math';
import AutoScaleText from '../../../../components/typography/AutoScaleText';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aoo',
  description: 'aoo',
  keywords: ['Mass', 'Capacity', 'Length', 'Imperial', 'Metric'],
  schema: z.object({
    chosenMeasurements: z.array(z.enum(['Length', 'Mass', 'Capacity', 'Volume'])).length(2),
    options: z
      .array(
        z.enum([
          'Grams',
          'Pounds',
          'Ounces',
          'Kilograms',
          'Stone',
          'Milligrams',
          'Litres',
          'Millilitres',
          'Gallons',
          'Pints',
          'Centilitres',
          'Feet',
          'Centimetres',
          'Inches',
          'Metres',
          'Kilometres',
          'Millimetres',
          'Miles',
          'Yards'
        ])
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const chosenMeasurements = getRandomSubArrayFromArray(
      ['Length', 'Mass', 'Capacity'] as const,
      2
    );

    const generateUnits = (
      measurementType: 'Length' | 'Mass' | 'Capacity',
      measurementSystem: 'Imperial' | 'Metric',
      numOfUnits: number
    ) => {
      switch (measurementType) {
        case 'Length':
          switch (measurementSystem) {
            case 'Imperial':
              return getRandomSubArrayFromArray(
                ['Inches', 'Feet', 'Yards', 'Miles'] as const,
                numOfUnits
              );
            case 'Metric':
              return getRandomSubArrayFromArray(
                ['Millimetres', 'Centimetres', 'Metres', 'Kilometres'] as const,
                numOfUnits
              );
          }
        case 'Mass':
          switch (measurementSystem) {
            case 'Imperial':
              return getRandomSubArrayFromArray(['Ounces', 'Pounds', 'Stone'] as const, numOfUnits);
            case 'Metric':
              return getRandomSubArrayFromArray(
                ['Milligrams', 'Grams', 'Kilograms'] as const,
                numOfUnits
              );
          }
        case 'Capacity':
          switch (measurementSystem) {
            case 'Imperial':
              return getRandomSubArrayFromArray(['Pints', 'Gallons'] as const, numOfUnits);
            case 'Metric':
              return getRandomSubArrayFromArray(
                ['Millilitres', 'Centilitres', 'Litres'] as const,
                numOfUnits
              );
          }
      }
    };

    // Shuffle distribution of draggable options
    const [numOfMeasurementA, numOfMeasurementB] = getRandomFromArray([
      [3, 1],
      [2, 2],
      [1, 3]
    ]);

    // Ensure we have a max of 2 answers per table group and shuffle which system has 2 in
    const [A1, A2] =
      numOfMeasurementA === 3 ? shuffle([1, 2]) : numOfMeasurementB === 3 ? [1, 0] : [2, 0];

    // Choose at random imperial or metric (only used for when we're choosing from one measurement system)
    const imperialOrMetricA = getRandomFromArray(['Imperial', 'Metric'] as const);
    const A =
      numOfMeasurementA === 3
        ? [
            ...generateUnits(chosenMeasurements[0], 'Imperial', A1),
            ...generateUnits(chosenMeasurements[0], 'Metric', A2)
          ]
        : [...generateUnits(chosenMeasurements[0], imperialOrMetricA, A1)];

    // Do the same for other chosen measurement
    const [B1, B2] =
      numOfMeasurementB === 3 ? shuffle([1, 2]) : numOfMeasurementA === 3 ? [1, 0] : [2, 0];

    const imperialOrMetricB = getRandomFromArray(['Imperial', 'Metric'] as const);
    const B =
      numOfMeasurementB === 3
        ? [
            ...generateUnits(chosenMeasurements[1], 'Imperial', B1),
            ...generateUnits(chosenMeasurements[1], 'Metric', B2)
          ]
        : [...generateUnits(chosenMeasurements[1], imperialOrMetricB, B1)];

    const options = [...A, ...B];

    return { chosenMeasurements, options };
  },
  Component: ({ question: { chosenMeasurements, options }, translate, displayMode }) => {
    const correctAnswer = [
      [[], []],
      [[], []]
    ] as [
      [measureAMetric: number[], measureAImperial: number[]],
      [measureBMetric: number[], measureBImperial: number[]]
    ];

    // Create measurement so we have access to useful methods
    const optionsAsMeasurements = options.map(units => createMeasurement(1, units));

    optionsAsMeasurements.forEach((opt, idx) => {
      // Determine which row and column the answer will be in
      if (opt.type === chosenMeasurements[0]) {
        const rowIndex = 0;
        const columnIndex = opt.system === 'Metric' ? 0 : 1;
        correctAnswer[rowIndex][columnIndex].push(idx);
      } else {
        const rowIndex = 1;
        const columnIndex = opt.system === 'Metric' ? 0 : 1;
        correctAnswer[rowIndex][columnIndex].push(idx);
      }
    });

    return (
      <QF9DragIntoTableOfGroups
        title={translate.instructions.dragCardsToSortUnitsOfMeasurementIntoTable()}
        pdfTitle={translate.instructions.useCardsToSortUnitsOfMeasurementIntoTable()}
        rowNames={[
          translate.tableHeaders[chosenMeasurements[0]](),
          translate.tableHeaders[chosenMeasurements[1]]()
        ]}
        columnNames={[translate.keywords.Metric(), translate.keywords.Imperial()]}
        tableHeaderMaxLines={1}
        testCorrect={correctAnswer}
        items={optionsAsMeasurements.map((opt, idx) => ({
          value: idx,
          component: (
            <Text variant="WRN700" style={displayMode === 'digital' && { fontSize: 24 }}>
              {opt.measurementName}
            </Text>
          )
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={2}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aop',
  description: 'aop',
  keywords: ['Mass', 'Capacity', 'Length', 'Imperial', 'Metric', 'Convert'],
  schema: z
    .object({
      unit1: z.enum(['inch', 'foot', 'pound', 'stone', 'gallon']),
      unit2: z.enum(['inch', 'foot', 'pound', 'stone', 'gallon']),
      unit3: z.enum(['inch', 'foot', 'pound', 'stone', 'gallon']),
      numbers: z.array(z.number().int().min(1).max(5)).length(3)
    })
    .refine(
      val => arrayHasNoDuplicates([val.unit1, val.unit2, val.unit3]),
      'All units must be different.'
    ),
  simpleGenerator: () => {
    const [unit1, unit2, unit3] = getRandomSubArrayFromArray(
      ['inch', 'foot', 'pound', 'stone', 'gallon'] as const,
      3
    );

    const unitToMeasure = (unit: 'inch' | 'foot' | 'pound' | 'stone' | 'gallon', num: number) => {
      switch (unit) {
        case 'inch':
          return convertUnitsSuffix(num, 'in', 'cm').value;
        case 'foot':
          return convertUnitsSuffix(num, 'ft', 'in').value;
        case 'pound':
          return convertUnitsSuffix(num, 'lb', 'oz').value;
        case 'stone':
          return convertUnitsSuffix(num, 'st', 'lb').value;
        case 'gallon':
          return convertUnitsSuffix(num, 'gal', 'pt').value;
      }
    };

    const number1 = randomIntegerInclusive(1, 5);

    const number2 = randomIntegerInclusive(1, 5, {
      constraint: x => unitToMeasure(unit2, x) !== unitToMeasure(unit1, number1)
    });

    const number3 = randomIntegerInclusive(1, 5, {
      constraint: x =>
        unitToMeasure(unit3, x) !== unitToMeasure(unit1, number1) &&
        unitToMeasure(unit3, x) !== unitToMeasure(unit2, number2)
    });

    const numbers = [number1, number2, number3];

    return { unit1, unit2, unit3, numbers };
  },
  Component: props => {
    const {
      question: { unit1, unit2, unit3, numbers },
      translate
    } = props;

    const [items, sentences] = useMemo(() => {
      type Units = 'inch' | 'foot' | 'pound' | 'stone' | 'gallon';

      const unitItemSelector = (unit: Units, num: number) => {
        switch (unit) {
          case 'inch':
            return {
              component: convertUnitsSuffix(num, 'in', 'cm').value.toLocaleString(),
              value: 'inch'
            };
          case 'foot':
            return {
              component: convertUnitsSuffix(num, 'ft', 'in').value.toLocaleString(),
              value: 'foot'
            };
          case 'pound':
            return {
              component: convertUnitsSuffix(num, 'lb', 'oz').value.toLocaleString(),
              value: 'pound'
            };
          case 'stone':
            return {
              component: convertUnitsSuffix(num, 'st', 'lb').value.toLocaleString(),
              value: 'stone'
            };
          case 'gallon':
            return {
              component: convertUnitsSuffix(num, 'gal', 'pt').value.toLocaleString(),
              value: 'gallon'
            };
        }
      };

      const unitItems = [
        unitItemSelector(unit1, numbers[0]),
        unitItemSelector(unit2, numbers[1]),
        unitItemSelector(unit3, numbers[2])
      ];

      const unitStatementSelector = (unit: Units, num: number) => {
        switch (unit) {
          case 'inch':
            return {
              sentence: `${translate.units.numberOfInches(num)} ≈ <ans /> ${translate.units.cm()}`,
              answer: 'inch'
            };
          case 'foot':
            return {
              sentence: `${translate.units.numberOfFeet(num)} = <ans /> ${translate.units.inches(
                convertUnitsSuffix(num, 'ft', 'in').value
              )}`,
              answer: 'foot'
            };
          case 'pound':
            return {
              sentence: `${translate.units.numberOfPounds(num)} = <ans /> ${translate.units.ounces(
                convertUnitsSuffix(num, 'lb', 'oz').value
              )}`,
              answer: 'pound'
            };
          case 'stone':
            return {
              sentence: `${num.toLocaleString()} ${translate.units.stones(
                num
              )} = <ans /> ${translate.units.pounds(convertUnitsSuffix(num, 'st', 'lb').value)}`,
              answer: 'stone'
            };
          case 'gallon':
            return {
              sentence: `${translate.units.numberOfGallons(num)} = <ans /> ${translate.units.pints(
                convertUnitsSuffix(num, 'gal', 'pt').value
              )}`,
              answer: 'gallon'
            };
        }
      };

      const unitStatements = [
        unitStatementSelector(unit1, numbers[0]),
        unitStatementSelector(unit2, numbers[1]),
        unitStatementSelector(unit3, numbers[2])
      ];

      const random = seededRandom(props.question);
      return [shuffle(unitItems, { random }), shuffle(unitStatements, { random })];
    }, [numbers, props.question, translate.units, unit1, unit2, unit3]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentencesEachCardOnlyUsedOnce()}
        items={items}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aop2',
  description: 'aop',
  keywords: ['Mass', 'Capacity', 'Length', 'Imperial', 'Metric', 'Convert'],
  schema: z.object({
    unitsAndAnswer: z.array(
      z.object({
        units: z.enum(['inch', 'foot', 'pound', 'stone', 'gallon']),
        answer: z.enum(['14', '16', '2.5', '12', '8'])
      })
    ),
    items: z.array(z.enum(['14', '16', '2.5', '12', '8']))
  }),
  simpleGenerator: () => {
    const unitsAndAnswer = getRandomSubArrayFromArray(
      [
        { units: 'inch', answer: '2.5' } as const,
        { units: 'foot', answer: '12' } as const,
        { units: 'pound', answer: '16' } as const,
        { units: 'stone', answer: '14' } as const,
        { units: 'gallon', answer: '8' } as const
      ],
      3
    );

    const items = shuffle([
      '14' as const,
      '16' as const,
      '2.5' as const,
      '12' as const,
      '8' as const
    ]);

    return { unitsAndAnswer, items };
  },
  Component: props => {
    const {
      question: { unitsAndAnswer, items },
      translate
    } = props;

    const statements = unitsAndAnswer.map(({ units, answer }) => {
      switch (units) {
        case 'inch':
          return {
            lhsComponent: `${translate.units.numberOfInches(1)} ≈`,
            rhsComponent: translate.units.cm(),
            correctAnswer: answer
          };
        case 'foot':
          return {
            lhsComponent: `${translate.units.numberOfFeet(1)} =`,
            rhsComponent: translate.units.inches(2),
            correctAnswer: answer
          };
        case 'pound':
          return {
            lhsComponent: `${translate.units.numberOfPounds(1)} =`,
            rhsComponent: translate.units.ounces(2),
            correctAnswer: answer
          };
        case 'stone':
          return {
            lhsComponent: `${(1).toLocaleString()} ${translate.units.stones(1)} =`,
            rhsComponent: translate.units.pounds(2),
            correctAnswer: answer
          };
        case 'gallon':
          return {
            lhsComponent: `${translate.units.numberOfGallons(1)} =`,
            rhsComponent: translate.units.pints(2),
            correctAnswer: answer
          };
      }
    });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useCardsCompleteStatementsEachCardCanOnlyBeUsedOnce()}
        items={items}
        itemVariant="square"
        statements={statements}
        questionHeight={900}
        actionPanelVariant="bottom"
        pdfLayout="itemsRight"
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aoq',
  description: 'aoq',
  keywords: ['Length', 'Capacity', 'Metric', 'Imperial', 'Convert'],
  schema: z.object({
    feet1: z.number().int().min(2).max(500),
    gallons1: z.number().int().min(2).max(500)
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const feet1 = randomIntegerInclusive(2, 500, {
      constraint: x => x <= 20 || x % 100 === 0
    });

    const gallons1 = randomIntegerInclusive(2, 500, {
      constraint: x => x <= 20 || x % 50 === 0
    });

    return {
      feet1,
      gallons1
    };
  },
  Component: props => {
    const {
      question: { feet1, gallons1 },
      translate
    } = props;

    const answer1 = convertUnitsSuffix(feet1, 'ft', 'in').value;
    const answer2 = convertUnitsSuffix(gallons1, 'gal', 'pt').value;

    const eqs = [
      `${translate.units.numberOfFeet(feet1)} = <ans/> ${translate.units.inches(answer1)}`,
      `${translate.units.numberOfGallons(gallons1)} = <ans/> ${translate.units.pints(answer2)}`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={[[answer1.toString()], [answer2.toString()]]}
        sentences={eqs}
        questionHeight={500}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aoq2',
  description: 'aoq2',
  keywords: ['Length', 'Capacity', 'Metric', 'Imperial', 'Convert'],
  schema: z.object({
    unit: z.enum(['feet', 'gallons']),
    number: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const unit = getRandomFromArray(['feet', 'gallons'] as const);
    const number = randomIntegerInclusive(2, 10);

    return {
      unit,
      number
    };
  },
  Component: props => {
    const {
      question: { unit, number },
      translate,
      displayMode
    } = props;

    const { sentences, answers } = (() => {
      switch (unit) {
        case 'feet':
          return {
            sentences: [
              `${translate.units.numberOfFeet(1)} = <ans/> ${translate.units.inches(12)}`,
              `${translate.units.numberOfFeet(number)} = <ans/> ${translate.units.inches(
                number * 12
              )}`
            ],
            answers: [['12'], [(number * 12).toString()]]
          };
        case 'gallons':
          return {
            sentences: [
              `${translate.units.numberOfGallons(1)} = <ans/> ${translate.units.pints(8)}`,
              `${translate.units.numberOfGallons(number)} = <ans/> ${translate.units.pints(
                number * 8
              )}`
            ],
            answers: [['8'], [(number * 8).toString()]]
          };
      }
    })();

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answers[0][0]) &&
          compareFloats(userAnswer[0][1], answers[0][1])
        }
        inputMaxCharacters={4}
        sentences={sentences}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: answers.map(arr => arr.map(str => str.toLowerCase())),
          answerText: unit === 'feet' ? translate.markScheme.acceptEquivalentDecimals() : ''
        }}
        extraSymbol="decimalPoint"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aor',
  description: 'aor',
  keywords: ['Mass', 'Metric', 'Imperial', 'Convert'],
  schema: z.object({
    var1: z
      .number()
      .int()
      .min(2)
      .max(100)
      .refine(x => x % 10 === 0 || x <= 49),
    var2: z.number().min(28).max(100).step(14),
    var3: z
      .number()
      .min(2)
      .max(100)
      .refine(x => (x > 49 ? !(x % 10) : !(x % 1)))
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 100, {
      constraint: x => x <= 50 || !(x % 10)
    });
    const var2 = randomIntegerInclusiveStep(28, 100, 14);
    const var3 = randomIntegerInclusive(2, 100, {
      constraint: x => x <= 50 || !(x % 10)
    });

    return { var1, var2, var3 };
  },
  Component: ({ question: { var1, var2, var3 }, translate }) => {
    const answer1 = convertUnitsSuffix(var1, 'lb', 'oz').value;
    const answer2 = convertUnitsSuffix(var2, 'lb', 'st').value;
    const answer3 = convertUnitsSuffix(var3, 'st', 'lb').value;

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={`${translate.instructions.useTheseKeyFactsToCompleteTheConversions()}`}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              columnGap: 32
            }}
          >
            <ContentBox>
              <AutoScaleText
                variant="WRN400"
                containerStyle={{ height: 75, width: (dimens.width - 100) / 2 }}
                minFontSize={28}
                maxFontSize={50}
                group="content"
                maxLines={1}
              >
                {translate.instructions.onePoundEq16ounces()}
              </AutoScaleText>
            </ContentBox>
            <ContentBox>
              <AutoScaleText
                variant="WRN400"
                containerStyle={{ height: 75, width: (dimens.width - 100) / 2 }}
                minFontSize={28}
                maxFontSize={50}
                group="content"
                maxLines={1}
              >
                {translate.instructions.oneStoneEq14pounds()}
              </AutoScaleText>
            </ContentBox>
          </View>
        )}
        sentences={[
          `${translate.units.numberOfLb(var1)} = <ans/> ${translate.units.ounces(answer1)}`,
          `<ans/> ${translate.units.stones(answer2)} =  ${translate.units.numberOfLb(var2)}`,
          `<ans/> ${translate.units.lb()} = ${translate.units.numberOfStone(var3)}`
        ]}
        testCorrect={[[answer1.toString()], [answer2.toString()], [answer3.toString()]]}
        questionHeight={1000}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aor2',
  description: 'aor',
  keywords: ['Mass', 'Metric', 'Imperial', 'Convert'],
  schema: z.object({
    numberOfPounds: z
      .number()
      .int()
      .min(2)
      .max(100)
      .refine(num => isInRange(2, 5)(num) || [10, 100].includes(num)),
    numberOfStone: z
      .number()
      .min(2)
      .max(100)
      .refine(num => isInRange(2, 5)(num) || [10, 100].includes(num)),
    isAnswerBoxLHSA: z.boolean(),
    isAnswerBoxLHSB: z.boolean()
  }),
  simpleGenerator: () => {
    const [numberOfPounds, numberOfStone] = countRange(2).map(() => {
      return randomIntegerInclusive(2, 100, {
        constraint: x => isInRange(2, 5)(x) || [10, 100].includes(x)
      });
    });

    const [isAnswerBoxLHSA, isAnswerBoxLHSB] = countRange(2).map(() => getRandomBoolean());

    return { numberOfPounds, numberOfStone, isAnswerBoxLHSA, isAnswerBoxLHSB };
  },
  Component: ({
    question: { numberOfPounds, numberOfStone, isAnswerBoxLHSA, isAnswerBoxLHSB },
    translate
  }) => {
    const answerA = numberOfPounds * 16;
    const answerB = numberOfStone * 14;

    const answers = [
      isAnswerBoxLHSA ? numberOfPounds : answerA,
      isAnswerBoxLHSB ? numberOfStone : answerB
    ];

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={`${translate.instructions.useTheseKeyFactsToCompleteTheConversions()}`}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              columnGap: 32
            }}
          >
            {countRange(2).map(idx => (
              <ContentBox key={idx}>
                <AutoScaleText
                  variant="WRN400"
                  containerStyle={{ height: 75, width: (dimens.width - 100) / 2 }}
                  minFontSize={28}
                  maxFontSize={50}
                  group="content"
                  maxLines={1}
                >
                  {idx === 0
                    ? translate.instructions.onePoundEq16ounces()
                    : translate.instructions.oneStoneEq14pounds()}
                </AutoScaleText>
              </ContentBox>
            ))}
          </View>
        )}
        sentences={[
          isAnswerBoxLHSA
            ? `<ans/> ${translate.units.pounds(numberOfPounds)} = ${translate.units.numberOfOunces(
                answerA
              )}`
            : `${translate.units.numberOfPounds(numberOfPounds)} = <ans/> ${translate.units.ounces(
                2
              )}`,
          isAnswerBoxLHSB
            ? `<ans/> ${translate.units.stones(numberOfStone)} = ${translate.units.numberOfPounds(
                answerB
              )}`
            : `${translate.units.numberOfStone(numberOfStone)} = <ans/> ${translate.units.pounds(
                2
              )}`
        ]}
        testCorrect={answers.map(answer => [answer.toString()])}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aos',
  description: 'aos',
  keywords: ['Length', 'Metric', 'Imperial', 'Convert'],
  schema: z
    .object({
      person: z.enum(['male', 'female']),
      personInInches: z.number()
    })
    .refine(
      val =>
        (val.person === 'male' && val.personInInches > 70 && val.personInInches < 94) ||
        (val.person === 'female' && val.personInInches > 56 && val.personInInches < 80)
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const person = getRandomFromArray(['male', 'female'] as const);
    const personInInches =
      person === 'male' ? randomIntegerInclusive(71, 93) : randomIntegerInclusive(57, 79);

    return { person, personInInches };
  },
  Component: ({ question: { person, personInInches }, translate }) => {
    const subject = person === 'male' ? translate.objects.Man() : translate.objects.Woman();
    const pronoun =
      person === 'male'
        ? translate.pronouns.maleDeterminer()
        : translate.pronouns.femaleDeterminer();

    const personFeet = Math.floor(personInInches / 12);
    const personInches = personInInches % 12;

    const personInCm = convertUnitsSuffix(personInInches, 'in', 'cm').value;

    const leftEq = translate.answerSentences.feetAndInches(personFeet, personInches);

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={translate.instructions.aXisYfeetandZinchesTall(
          subject.toLowerCase(),
          personFeet,
          personInches,
          pronoun.toLowerCase()
        )}
        Content={({ dimens }) => (
          <View
            style={[
              {
                flexDirection: 'row',
                columnGap: 32
              }
            ]}
          >
            <ContentBox>
              <AutoScaleText
                variant="WRN400"
                containerStyle={{ height: 75, width: (dimens.width - 100) / 2 }}
                minFontSize={28}
                maxFontSize={50}
                group="content"
                maxLines={1}
              >
                {translate.instructions.oneInchApproxEqualCm()}
              </AutoScaleText>
            </ContentBox>
            <ContentBox>
              <AutoScaleText
                variant="WRN400"
                containerStyle={{ height: 75, width: (dimens.width - 100) / 2 }}
                minFontSize={28}
                maxFontSize={50}
                group="content"
                maxLines={1}
              >
                {translate.instructions.oneFootEq12Inches()}
              </AutoScaleText>
            </ContentBox>
          </View>
        )}
        sentences={[
          `${leftEq} = ${translate.answerSentences.ansInches()}`,
          `${leftEq} ${APPROX_EQUAL} ${translate.answerSentences.ansCm()}`
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], personInInches) &&
          compareFloats(userAnswer[1][0], personInCm)
        }
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [[personInInches.toLocaleString()], [personInCm.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aot',
  description: 'aot',
  keywords: ['Order', 'Length', 'Mass', 'Capacity', 'Metric', 'Imperial', 'Convert'],
  schema: z.object({
    numbers: z.array(z.number().min(2).max(100)).length(4),
    selectedMeasure: z.enum(['Capacity', 'Length', 'Mass'])
  }),
  simpleGenerator: () => {
    const selectedMeasure = getRandomFromArray(['Capacity', 'Length', 'Mass'] as const);

    // Switch statement and calculations needed to ensure no two measurements are equal to each other.
    const numbers = (() => {
      switch (selectedMeasure) {
        case 'Capacity':
          const [pintsA, pintsB] = randomUniqueIntegersInclusive(2, 100, 2);

          const [gallonsA, gallonsB] = randomUniqueIntegersInclusive(2, 100, 2, {
            constraint: x =>
              convertUnitsSuffix(x, 'gal', 'pt').value !== pintsA &&
              convertUnitsSuffix(x, 'gal', 'pt').value !== pintsB
          });

          return [gallonsA, pintsA, gallonsB, pintsB];

        case 'Length':
          const cm = randomIntegerInclusiveStep(25, 1000, 25) / 10;

          const inches = randomIntegerInclusive(2, 100, {
            constraint: x => convertUnitsSuffix(x, 'in', 'cm').value !== cm
          });

          const feet = randomIntegerInclusive(2, 100, {
            constraint: x =>
              convertUnitsSuffix(x, 'ft', 'cm').value !== cm &&
              convertUnitsSuffix(x, 'ft', 'in').value !== inches
          });

          const m = randomIntegerInclusive(2, 100, {
            constraint: x =>
              convertUnitsSuffix(x, 'in', 'cm').value !== x * 100 &&
              convertUnitsSuffix(x, 'ft', 'cm').value !== x * 100 &&
              x * 100 !== cm
          });

          return [inches, cm, feet, m];

        case 'Mass':
          const oz = randomIntegerInclusive(2, 100);

          const pounds = randomIntegerInclusive(2, 100, {
            constraint: x => convertUnitsSuffix(x, 'lb', 'oz').value !== oz
          });

          const stone = randomIntegerInclusive(2, 100, {
            constraint: x =>
              convertUnitsSuffix(x, 'st', 'oz').value !== oz &&
              convertUnitsSuffix(x, 'st', 'lb').value !== pounds
          });

          return [pounds, oz, stone, 2];
      }
    })();

    return { numbers, selectedMeasure };
  },
  Component: props => {
    const {
      question: { numbers, selectedMeasure },
      translate
    } = props;

    // Randomly order these equations
    const items = (() => {
      switch (selectedMeasure) {
        case 'Capacity':
          return [
            new Gallons(numbers[0]),
            new Pints(numbers[1]),
            new Gallons(numbers[2]),
            new Pints(numbers[3])
          ];

        case 'Length':
          return [
            new Inches(numbers[0]),
            new Centimetres(numbers[1]),
            new Feet(numbers[2]),
            new Metres(numbers[3])
          ];

        case 'Mass':
          return [new Pounds(numbers[0]), new Ounces(numbers[1]), new Stone(numbers[3])];
      }
    })();

    const shuffledItems = shuffle(items, { random: seededRandom(props.question) });

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderTheseMeasurementsFromSmallestToGreatest()}
        pdfTitle={translate.instructions.useCardsToOrderTheseMeasurementsFromSmallestToGreatest()}
        testCorrect={sortNumberArray(
          shuffledItems.map(measurement => measurement.toBaseImperial().value),
          'ascending'
        )}
        items={shuffledItems.map(measurement => ({
          value: measurement.toBaseImperial().value,
          component: `${measurement.value} ${measurement.measurementName.toLowerCase()}`
        }))}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ImperialMeasures',
  questionTypes: [Question1, Question2v2, Question3v2, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question2, Question3, Question4]
});
export default SmallStep;
