import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { SUB } from '../../../../constants';
import { range } from '../../../../utils/collections';
import { digitAtPowerIsNumber } from '../../../../utils/math';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'agK',
  description: 'agK',
  keywords: ['Subtraction', 'Base 10', 'Column'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(11)
        .max(49)
        .refine(
          x => x % 10 !== 0 && x % 10 > 5,
          'cannot be a multiple of 10 and one digit more than 5'
        ),
      var2: z
        .number()
        .int()
        .min(11)
        .max(49)
        .refine(
          x => x % 10 !== 0 && x % 10 < 6,
          'cannot be a multiple of 10 and one digit less than 6'
        )
    })
    .refine(val => numbersDoNotExchange(val.var1, -val.var2), 'numbers do not exchange ')
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(11, 49, {
          constraint: x => x % 10 !== 0 && x % 10 > 5
        });

        const var2 = randomIntegerInclusive(11, var1 - 1, {
          constraint: x => x % 10 !== 0 && x % 10 < 6
        });
        return { var1, var2 };
      },
      // Only permit them if they do not exchange and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => numbersDoNotExchange(var1, -var2) && var1 - var2 >= 15
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'agK2',
  description: 'agK',
  keywords: ['Subtraction', 'Base 10', 'Column'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(11)
        .max(89)
        .refine(
          x => x % 10 !== 0 && x % 10 > 5,
          'cannot be a multiple of 10 and one digit more than 5'
        ),
      var2: z
        .number()
        .int()
        .min(11)
        .max(89)
        .refine(
          x => x % 10 !== 0 && x % 10 < 6,
          'cannot be a multiple of 10 and one digit less than 6'
        )
    })
    .refine(val => numbersDoNotExchange(val.var1, -val.var2), 'numbers do not exchange ')
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(11, 89, {
          constraint: x => x % 10 !== 0 && x % 10 > 5
        });

        const var2 = randomIntegerInclusive(11, var1 - 1, {
          constraint: x => x % 10 !== 0 && x % 10 < 6
        });
        return { var1, var2 };
      },
      // Only permit them if they do not exchange and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => numbersDoNotExchange(var1, -var2) && var1 - var2 >= 15
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'agL',
  description: 'agL',
  keywords: ['Subtraction', 'Base 10', 'Column'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(201)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(101)
        .max(899)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(val => numbersDoNotExchange(val.var1, -val.var2), 'Numbers do not exchange')
    .refine(
      val => val.var1 - val.var2 >= 100,
      'The two numbers need to have a minimum difference of 100'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(201, 999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(101, var1 - 100, {
          constraint: x => x % 10 !== 0
        });
        return { var1, var2 };
      },
      // Only permit them if they do not exchange and the difference between the two numbers is at least 100
      ({ var1, var2 }) => numbersDoNotExchange(var1, -var2) && var1 - var2 >= 100
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'agM',
  description: 'agM',
  keywords: ['Subtraction', 'Column method'],
  schema: z
    .object({
      minuend: z.number().int().min(210).max(999),
      subtrahend: z.number().int().min(110).max(890)
    })
    .refine(val => val.minuend > val.subtrahend, 'minuend should be greater than subtrahend.'),
  simpleGenerator: () => {
    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(210, 999, {
          constraint: x => x % 10 !== 0
        });

        const subtrahend = randomIntegerInclusive(110, minuend - 100, {
          constraint: x =>
            // Must have a zero in the ones or tens, but not both:
            (digitAtPowerIsNumber(x, 'ones', [0]) || digitAtPowerIsNumber(x, 'tens', [0])) &&
            x % 100 !== 0
        });
        return { minuend, subtrahend };
      },
      // Only permit them if their are no exchanges, and the difference between the two numbers is at least 100
      ({ minuend, subtrahend }) =>
        numbersDoNotExchange(minuend, -subtrahend) && minuend - subtrahend >= 100
    );
    return {
      minuend,
      subtrahend
    };
  },
  Component: ({ question: { minuend, subtrahend }, translate }) => {
    const difference = minuend - subtrahend;
    const answerMissingDigits = range(0, difference.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={minuend}
        bottomNumber={subtrahend}
        operation={SUB}
        answerNumber={difference}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(difference, answerMissingDigits.length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'agN',
  description: 'agN',
  keywords: ['Subtract', 'No exchange', 'Part-whole model'],
  schema: z
    .object({
      minuend: z.number().int().min(151).max(999),
      subtrahend: z.number().int().min(101).max(998),
      leftOrRightAnswerBox: z.enum(['left', 'right'])
    })
    .refine(val => val.minuend > val.subtrahend, 'minuend must be greater than subtrahend.'),
  simpleGenerator: () => {
    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(151, 999);

        const subtrahend = randomIntegerInclusive(101, minuend - 1, {
          constraint: x => x % 10 !== 0
        });
        return { minuend, subtrahend };
      },
      ({ minuend, subtrahend }) => numbersDoNotExchange(minuend, -subtrahend)
    );

    const leftOrRightAnswerBox = getRandomFromArray(['left', 'right'] as const);

    return { minuend, subtrahend, leftOrRightAnswerBox };
  },

  Component: props => {
    const {
      question: { minuend, subtrahend, leftOrRightAnswerBox },
      translate
    } = props;
    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        initialState={['']}
        inputType="numpad"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={minuend.toLocaleString()}
            partition={
              leftOrRightAnswerBox === 'left'
                ? ['$ans', subtrahend.toLocaleString()]
                : [subtrahend.toLocaleString(), '$ans']
            }
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            isInteractive
            dimens={dimens}
          />
        )}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => answer[0] === (minuend - subtrahend).toString()}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [(minuend - subtrahend).toLocaleString()]
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'agO',
  description: 'agO',
  keywords: ['Subtract', 'No exchange', 'Bar model'],
  schema: z
    .object({
      minuend: z.number().int().min(151).max(999),
      subtrahend: z.number().int().min(101).max(998),
      leftOrRightAnswerBox: z.enum(['left', 'right'])
    })
    .refine(val => val.minuend > val.subtrahend, 'minuend must be greater than subtrahend.'),
  simpleGenerator: () => {
    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(151, 999);

        const subtrahend = randomIntegerInclusive(101, minuend - 1, {
          constraint: x => x % 10 !== 0
        });
        return { minuend, subtrahend };
      },
      ({ minuend, subtrahend }) => numbersDoNotExchange(minuend, -subtrahend)
    );

    const leftOrRightAnswerBox = getRandomFromArray(['left', 'right'] as const);

    return { minuend, subtrahend, leftOrRightAnswerBox };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend, leftOrRightAnswerBox },
      translate
    } = props;

    const numbers =
      leftOrRightAnswerBox === 'left'
        ? [[minuend], [minuend - subtrahend, subtrahend]]
        : [[minuend], [subtrahend, minuend - subtrahend]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={[[], [leftOrRightAnswerBox === 'left' ? 0 : 1]]}
        total={minuend}
        questionHeight={600}
        oneFontSize
      />
    );
  },
  questionHeight: 600
});

const Question6 = newQuestionContent({
  uid: 'agP',
  description: 'agP',
  keywords: ['Subtraction', 'Column'],
  schema: z
    .object({
      answerNumber: z.number().int().min(100).max(899),
      bottomNumber: z.number().int().min(100).max(899),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => numbersDoNotExchange(val.answerNumber, val.bottomNumber),
      'answerNumber and bottomNumber must not exchange.'
    )
    .refine(
      val => val.missingOnes !== val.missingTens && val.missingOnes !== val.missingHundreds,
      'missingOnes must be in a different number to missingTens and missingHundreds.'
    )
    .refine(
      val => val.missingTens !== val.missingHundreds,
      'missingTens must be in a different number to missingHundreds.'
    ),
  simpleGenerator: () => {
    const answerNumber = randomIntegerInclusive(100, 899);

    const bottomNumber = randomIntegerInclusive(100, 999 - answerNumber, {
      constraint: x => numbersDoNotExchange(answerNumber, x)
    });

    const [missingOnes, missingTens, missingHundreds] = shuffle([
      'top',
      'bottom',
      'answer'
    ] as const);

    return { answerNumber, bottomNumber, missingOnes, missingTens, missingHundreds };
  },

  Component: props => {
    const {
      question: { answerNumber, bottomNumber, missingOnes, missingTens, missingHundreds },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds
    );
    const topNumber = answerNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={SUB}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          }
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwoNumbersNoExchange',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
