// Autogenerated with: yarn tsxipts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import RomanNumeralsTo12 from './1RomanNumeralsTo12';
import TellTheTimeTo5Minutes from './2TellTheTimeTo5Minutes';
import TellTheTimeToTheMinute from './3TellTheTimeToTheMinute';
import ReadTimeOnADigitalClock from './4ReadTimeOnADigitalClock';
import UseAMAndPM from './5UseAMAndPM';
import YearsMonthsAndDays from './6YearsMonthsAndDays';
import DaysAndHours from './7DaysAndHours';
import HoursAndMinutesUseStartAndEndTimes from './8HoursAndMinutesUseStartAndEndTimes';
import HoursAndMinutesUseDurations from './9HoursAndMinutesUseDurations';
import MinutesAndSeconds from './10MinutesAndSeconds';
import UnitsOfTime from './11UnitsOfTime';
import SolveProblemsWithTime from './12SolveProblemsWithTime';

const Block = newBlockContent({
  block: 'Time',
  field: 'Measurement',
  weeks: [5, 7],
  smallSteps: [
    RomanNumeralsTo12, // Step 1
    TellTheTimeTo5Minutes, // Step 2
    TellTheTimeToTheMinute, // Step 3
    ReadTimeOnADigitalClock, // Step 4
    UseAMAndPM, // Step 5
    YearsMonthsAndDays, // Step 6
    DaysAndHours, // Step 7
    HoursAndMinutesUseStartAndEndTimes, // Step 8
    HoursAndMinutesUseDurations, // Step 9
    MinutesAndSeconds, // Step 10
    UnitsOfTime, // Step 11
    SolveProblemsWithTime // Step 12
  ]
});
export default Block;
