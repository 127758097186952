import { z } from 'zod';
import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep
} from '../../../../utils/random';
import {
  createGridAroundShape,
  createLShape,
  createRectangleFromSquares,
  getSShape,
  getTShape,
  scaleShape
} from '../../../../utils/shapes';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { CreateShapeFromSquaresWithState } from '../../../../components/question/representations/CreateShapeFromSquares';
import { isEqual } from '../../../../utils/matchers';
import {
  QuadrilateralShapesSchema,
  quadrilateralColors,
  quadrilateralColorsSchema
} from '../../../../utils/quadrilateralImages';
import {
  arraysHaveSameContents,
  countRange,
  filledArray,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import { LabelledSmallLargeQuadrilateralsWithState } from '../../../../components/question/representations/LabelledSmallLargeQuadrilaterals';
import { numberEnum } from '../../../../utils/zod';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { compareFloats } from '../../../../utils/math';

////
// Questions
////

const shapes = ['Parallelogram', 'Rectangle', 'Rhombus', 'Square', 'Trapezium', 'Kite'] as const;

const Question1 = newQuestionContent({
  uid: 'aTg',
  description: 'aTg',
  keywords: ['Scale', 'Factor'],
  schema: z.object({
    shapeAWidth: z.number().int().min(1).max(2),
    shapeAHeight: z.number().int().min(1).max(2),
    scale: z.number().int().min(2).max(3),
    isCorrect: z.boolean()
  }),
  simpleGenerator: () => {
    const shapeAWidth = randomIntegerInclusive(1, 2);
    const shapeAHeight = randomIntegerInclusive(1, 2);
    const scale = randomIntegerInclusive(2, 3);
    const isCorrect = getRandomBoolean();

    return { shapeAWidth, shapeAHeight, scale, isCorrect };
  },
  Component: ({ question: { shapeAWidth, shapeAHeight, scale, isCorrect }, translate }) => {
    // work out the shortest so we can ensure the shortest is always the width in order to fit more on
    const longest = Math.max(shapeAHeight, shapeAWidth);
    const shortest = Math.min(shapeAHeight, shapeAWidth);
    const shapeBHeight = isCorrect ? shortest * scale : shortest;
    const shapeBWidth = longest * scale;
    const shapeA = createRectangleFromSquares(longest, shortest);
    const shapeB = createRectangleFromSquares(shapeBWidth, shapeBHeight);

    const gridDimenSizePx = shapeBWidth === 6 && shapeBHeight === 1 ? 150 : 100;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.selectWhetherTheStatementIsTrueOrFalse()}
        pdfTitle={translate.instructions.circleWhetherTheStatementIsTrueOrFalse()}
        trueButtonLabel={translate.misc.True()}
        falseButtonLabel={translate.misc.False()}
        correctAnswer={isCorrect}
        sentence={translate.instructions.shapeAHasBeenEnlargedBy(scale.toLocaleString())}
        content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              columnGap: 20
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <Text variant="WRN400">{'A'}</Text>
              <DisplayShapeOnGrid
                givenShape={createGridAroundShape(
                  [shapeBWidth + 2, shapeBHeight + 2],
                  shapeA,
                  'center'
                )}
                gridCellSize={(dimens.height - gridDimenSizePx) / (shapeBHeight + 2)}
                dimens={{ height: dimens.height - gridDimenSizePx, width: dimens.width / 3 }}
              />
            </View>
            <View style={{ alignItems: 'center', left: -2 }}>
              <Text variant="WRN400">{'B'}</Text>
              <DisplayShapeOnGrid
                givenShape={createGridAroundShape(
                  [shapeBWidth + 2, shapeBHeight + 2],
                  shapeB,
                  'center'
                )}
                gridCellSize={(dimens.height - gridDimenSizePx) / (shapeBHeight + 2)}
                dimens={{ height: dimens.height - gridDimenSizePx, width: dimens.width / 3 }}
              />
            </View>
          </View>
        )}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aTh',
  description: 'aTh',
  keywords: ['Scale', 'Factor'],
  schema: z.object({
    shapeAWidth: z.number().int().min(2).max(4),
    shapeAHeight: z.number().int().min(1).max(4),
    shape: z.enum(['lShape', 'rectangle', 'square', 't', 's']),
    scale: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['lShape', 'rectangle', 'square', 't', 's'] as const);
    const shapeASquares = ['square', 't', 's'].includes(shape) ? 4 : randomIntegerInclusive(3, 4);
    const shapeAWidth = shape === 'rectangle' ? shapeASquares : 2;
    const shapeAHeight = shape === 'square' ? 2 : shape === 'rectangle' ? 1 : shapeASquares - 1;
    const scale = randomIntegerInclusive(2, 4, {
      constraint: x => x * shapeAHeight <= 9 && x * shapeAWidth <= 10
    });

    return { shapeAHeight, shapeAWidth, scale, shape };
  },
  Component: ({ question: { shapeAWidth, shapeAHeight, scale, shape }, translate }) => {
    const shapeA =
      shape === 'lShape'
        ? createLShape({
            rectangleWidth: shapeAWidth,
            rectangleHeight: shapeAHeight,
            whiteSpaceWidth: 1,
            whiteSpaceHeight: shapeAHeight === 2 ? 1 : 2
          })
        : shape === 't'
        ? getTShape(1)
        : shape === 's'
        ? getSShape(1)
        : createRectangleFromSquares(shapeAWidth, shapeAHeight);

    const shapeB =
      shape === 't'
        ? getTShape(scale)
        : shape === 's'
        ? getSShape(scale)
        : scaleShape({ shapeHeight: shapeAHeight, shapeWidth: shapeAWidth, scale, shape });

    const gridRows = Math.max(shapeAHeight * scale, 8);
    const gridCols =
      shapeAWidth * scale >= 10 - shapeAWidth ? shapeAWidth * scale + 1 : 10 - shapeAWidth;
    const gridCellSize = shapeAHeight * scale === 8 ? 30 : 38;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsFactorOfEnlargement()}
        sentence={`<ans/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[scale.toString()]}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              columnGap: 20
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <Text variant="WRN400">{'A'}</Text>
              <DisplayShapeOnGrid
                givenShape={createGridAroundShape([gridCols, gridRows], shapeA, 'center')}
                dimens={{ height: dimens.height - 10, width: dimens.width / 2 }}
                gridCellSize={gridCellSize}
              />
            </View>
            <View style={{ alignItems: 'center', left: -2 }}>
              <Text variant="WRN400">{'B'}</Text>
              <DisplayShapeOnGrid
                givenShape={createGridAroundShape([gridCols, gridRows], shapeB, 'center')}
                dimens={{ height: dimens.height - 10, width: dimens.width / 2 }}
                gridCellSize={gridCellSize}
              />
            </View>
          </View>
        )}
      />
    );
  }
});

/**
 * @deprecated This question is currently broken, so it has been archived.
 * See https://white-rose-education.monday.com/boards/1341306568/pulses/1235766589
 * If it can be fixed with no changes to userAnswer shape or schema it can be un-archived. Otherwise, we need an aTi2.
 */
const Question3 = newQuestionContent({
  uid: 'aTi',
  description: 'aTi',
  keywords: ['Scale', 'Factor', 'Draw'],
  schema: z.object({
    shapeASquares: z.number().int().min(3).max(4),
    shape: z.enum(['lShape', 'rectangle', 'square', 't', 's']),
    scale: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['lShape', 'rectangle', 'square', 't', 's'] as const);
    const shapeASquares = ['square', 't', 's'].includes(shape) ? 4 : randomIntegerInclusive(3, 4);
    const max = ['t', 's'].includes(shape)
      ? 2
      : shape === 'rectangle'
      ? 4
      : shape === 'lShape' && shapeASquares === 4
      ? 2
      : 3;
    const scale = randomIntegerInclusive(2, max);

    return { shape, shapeASquares, scale };
  },
  Component: ({ question: { shape, shapeASquares, scale }, translate, displayMode }) => {
    const shapeAWidth = shape === 'rectangle' ? shapeASquares : 2;
    const shapeAHeight = shape === 'square' ? 2 : shape === 'rectangle' ? 1 : shapeASquares - 1;
    const shapeA =
      shape === 'lShape'
        ? createLShape({
            rectangleWidth: shapeAWidth,
            rectangleHeight: shapeAHeight,
            whiteSpaceWidth: 1,
            whiteSpaceHeight: shapeAHeight === 2 ? 1 : 2
          })
        : shape === 't'
        ? getTShape(1)
        : shape === 's'
        ? getSShape(1)
        : createRectangleFromSquares(shapeAWidth, shapeAHeight);
    const shapeB =
      shape === 't'
        ? getTShape(scale)
        : shape === 's'
        ? getSShape(scale)
        : scaleShape({ shapeHeight: shapeAHeight, shapeWidth: shapeAWidth, scale, shape });

    const array: ('fixed' | null | undefined)[][] = countRange(6).map(() =>
      countRange(13).map(() => null)
    );

    shapeA.forEach((row, rowId) =>
      row.forEach((_cell, cellId) => (array[rowId][cellId] = 'fixed'))
    );

    return (
      <QF3Content
        title={translate.instructions.selectSquaresToEnlargeShapeAByX(scale.toLocaleString())}
        pdfTitle={translate.instructions.shadeSquaresToEnlargeShapeAByX(scale.toLocaleString())}
        Content={({ dimens }) => (
          <CreateShapeFromSquaresWithState
            id="createshape"
            dimens={dimens}
            numberOfCols={13}
            numberOfRows={6}
            defaultState={
              displayMode === 'markscheme'
                ? createGridAroundShape([13, 6], shapeB, 'center')
                : undefined
            }
            array={array}
            testCorrect={answer => {
              const shapeBTrues = shapeB.map(i => i.filter(a => a === true));
              const answerTrues = answer
                .map(i => i.filter(a => a === true))
                .filter(i => i.length > 0);
              return (
                range(0, shapeBTrues.length)
                  .map(i => {
                    return arraysHaveSameContents(shapeBTrues[i] ?? [], answerTrues[i] ?? []);
                  })
                  .every(j => j === true) && shapeBTrues.length === answerTrues.length
              );
            }}
          />
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.shadingCanTakePlaceAnywhereOnGrid()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aTj',
  description: 'aTj',
  keywords: ['Scale', 'Factor'],
  schema: z.object({
    number1: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 4);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const vocab =
      number1 === 2
        ? translate.multiples.doubled()
        : number1 === 3
        ? translate.multiples.tripled()
        : translate.multiples.quadrupled();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testCorrect={[number1.toString()]}
        sentence={translate.answerSentences.shapeHasIncreasedByXEachSideHasScaleFactor(vocab)}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aTj2',
  description: 'aTj',
  keywords: ['Scale', 'Factor'],
  schema: z
    .object({
      width: z.number().int().min(1).max(3),
      height: z.number().int().min(1).max(2),
      multiplier: z.number().int().min(2).max(4)
    })
    .refine(val => val.width !== val.height, 'width and height must be different'),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(1, 3);
    const height = randomIntegerInclusive(1, 2, { constraint: x => x !== width });
    const multiplier = randomIntegerInclusive(2, 4, {
      constraint: x => width * x <= 10 - width && height * x <= 5
    });

    return { width, height, multiplier };
  },
  Component: props => {
    const {
      question: { width, height, multiplier },
      translate,
      displayMode
    } = props;

    const array: ('fixed' | null | undefined)[][] = countRange(5).map(() =>
      countRange(10).map(() => null)
    );
    const givenShape = filledArray(filledArray(true, width), height);

    givenShape.forEach((row, rowId) =>
      row.forEach((_cell, cellId) => (array[rowId][cellId] = 'fixed'))
    );

    const correctAnsArray: boolean[][] = countRange(5).map(() => countRange(10).map(() => false));
    filledArray(filledArray(true, width * multiplier), height * multiplier).forEach((row, rowId) =>
      row.forEach((_cell, cellId) => (correctAnsArray[rowId][cellId] = true))
    );

    const word = multiplier === 2 ? translate.misc.twice() : translate.misc.nTimes(multiplier);

    return (
      <QF3Content
        title={translate.instructions.tapSquaresToDrawRectangleWhereEachSideIsXLong(word)}
        pdfTitle={translate.instructions.shadeSquaresToDrawRectangleWhereSidesAreXLong(word)}
        Content={({ dimens }) => (
          <CreateShapeFromSquaresWithState
            id="createshape"
            dimens={dimens}
            numberOfCols={10}
            numberOfRows={5}
            array={displayMode === 'markscheme' ? undefined : array}
            defaultState={displayMode === 'markscheme' ? correctAnsArray : undefined}
            testCorrect={answer => {
              const answerTrues = answer
                .map(i => i.filter(a => a === true))
                .filter(i => i.length > 0);
              const correct = correctAnsArray
                .map(i => i.filter(a => a === true))
                .filter(i => i.length > 0);
              return (
                countRange(correct.length)
                  .map(i => {
                    return arraysHaveSameContents(correct[i] ?? [], answerTrues[i] ?? []);
                  })
                  .every(j => j === true) && correct.length === answerTrues.length
              );
            }}
          />
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.shadingCanTakePlaceAnywhereOnGrid()
        }}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aTk',
  description: 'aTk',
  keywords: ['Scale', 'Factor'],
  schema: z.object({
    scale: z.number().int().min(2).max(5),
    lengths: z.array(z.number().int().min(1).max(5)),
    shape: QuadrilateralShapesSchema,
    color: quadrilateralColorsSchema
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const scale = randomIntegerInclusive(2, 5);
    const shape = getRandomFromArray(shapes);
    const color = getRandomFromArray(quadrilateralColors);

    // Get lengths for sides. Some shapes have equal side so the length of the array can vary
    const lengths = ['Square', 'Rhombus'].includes(shape)
      ? [randomIntegerInclusive(1, 5)]
      : ['Rectangle', 'Parallelogram', 'Kite'].includes(shape)
      ? randomUniqueIntegersInclusive(1, 5, 2)
      : randomUniqueIntegersInclusive(1, 5, 3);

    return { scale, shape, color, lengths };
  },
  Component: props => {
    const {
      question: { scale, shape, color, lengths },
      translate,
      displayMode
    } = props;

    const orderedLabels = sortNumberArray(lengths, 'descending');
    const labels =
      orderedLabels.length === 2 && shape === 'Kite'
        ? [orderedLabels[1], orderedLabels[0], orderedLabels[0]]
        : orderedLabels.length === 2
        ? [orderedLabels[0], orderedLabels[1], orderedLabels[0]]
        : orderedLabels.length === 3
        ? [orderedLabels[2], orderedLabels[1], orderedLabels[0]]
        : [orderedLabels[0], orderedLabels[0], orderedLabels[0]];

    const labelsSmall = labels.map(i => translate.units.numberOfCm(i));

    return (
      <QF3Content
        title={translate.instructions.bIsAnEnlargementOfACompleteDimensions(scale.toLocaleString())}
        inputType="numpad"
        questionHeight={1100}
        Content={({ dimens }) => (
          <>
            <LabelledSmallLargeQuadrilateralsWithState
              id="labelShape"
              dimens={{ height: dimens.height, width: dimens.width }}
              labelsLarge={[`<ans/>`, `<ans/>`, `<ans/>`]}
              labelsSmall={labelsSmall}
              color={color}
              shape={shape}
              symbol={translate.units.cm()}
              defaultState={
                displayMode === 'markscheme'
                  ? [
                      translate.units.numberOfCm(scale * labels[0]),
                      translate.units.numberOfCm(scale * labels[1]),
                      translate.units.numberOfCm(scale * labels[2])
                    ]
                  : undefined
              }
              testCorrect={isEqual([
                (scale * labels[0]).toString(),
                (scale * labels[1]).toString(),
                (scale * labels[2]).toString()
              ])}
            />
          </>
        )}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aTk2',
  description: 'aTk',
  keywords: ['Scale', 'Factor'],
  schema: z.object({
    scale: z.number().int().min(2).max(5),
    lengths: z.array(z.number().int().min(1).max(5)),
    shape: QuadrilateralShapesSchema,
    color: quadrilateralColorsSchema,
    labelsAmount: numberEnum([2, 4])
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const scale = randomIntegerInclusive(2, 5);
    const shape = getRandomFromArray(shapes);
    const color = getRandomFromArray(quadrilateralColors);
    const labelsAmount = getRandomFromArray([2, 4] as const);

    // Get lengths for sides. Some shapes have equal side so the length of the array can vary
    const lengths = ['Square', 'Rhombus'].includes(shape)
      ? [randomIntegerInclusive(1, 5)]
      : ['Rectangle', 'Parallelogram', 'Kite'].includes(shape)
      ? randomUniqueIntegersInclusive(1, 5, 2)
      : randomUniqueIntegersInclusive(1, 5, 3);

    return { scale, shape, color, labelsAmount, lengths };
  },
  Component: props => {
    const {
      question: { scale, shape, color, labelsAmount, lengths },
      translate,
      displayMode
    } = props;

    const orderedLabels = sortNumberArray(lengths, 'descending');

    const labels =
      orderedLabels.length === 2 && shape === 'Kite'
        ? labelsAmount === 2
          ? [orderedLabels[1], orderedLabels[0]]
          : [orderedLabels[1], orderedLabels[0], orderedLabels[0], orderedLabels[1]]
        : orderedLabels.length === 2
        ? labelsAmount === 2
          ? [orderedLabels[0], orderedLabels[1]]
          : [orderedLabels[0], orderedLabels[1], orderedLabels[0], orderedLabels[1]]
        : orderedLabels.length === 3
        ? labelsAmount === 2
          ? [orderedLabels[2], orderedLabels[1]]
          : [orderedLabels[2], orderedLabels[1], orderedLabels[0], orderedLabels[1]]
        : labelsAmount === 2
        ? [orderedLabels[0], orderedLabels[0]]
        : [orderedLabels[0], orderedLabels[0], orderedLabels[0], orderedLabels[0]];

    const labelsSmall = labels.map(i => translate.units.numberOfCm(i));

    return (
      <QF3Content
        title={translate.instructions.bIsAnEnlargementOfACompleteDimensions(scale.toLocaleString())}
        inputType="numpad"
        questionHeight={1100}
        Content={({ dimens }) => (
          <>
            <LabelledSmallLargeQuadrilateralsWithState
              id="labelShape"
              dimens={dimens}
              labelsLarge={
                labelsAmount === 2 ? [`<ans/>`, `<ans/>`] : [`<ans/>`, `<ans/>`, `<ans/>`, `<ans/>`]
              }
              labelsSmall={labelsSmall}
              color={color}
              shape={shape}
              symbol={translate.units.cm()}
              defaultState={
                displayMode === 'markscheme'
                  ? labelsAmount === 2
                    ? [
                        translate.units.numberOfCm(scale * labels[0]),
                        translate.units.numberOfCm(scale * labels[1])
                      ]
                    : [
                        translate.units.numberOfCm(scale * labels[0]),
                        translate.units.numberOfCm(scale * labels[1]),
                        translate.units.numberOfCm(scale * labels[2]),
                        translate.units.numberOfCm(scale * labels[3])
                      ]
                  : undefined
              }
              testCorrect={isEqual(
                labelsAmount === 2
                  ? [(scale * labels[0]).toString(), (scale * labels[1]).toString()]
                  : [
                      (scale * labels[0]).toString(),
                      (scale * labels[1]).toString(),
                      (scale * labels[2]).toString(),
                      (scale * labels[3]).toString()
                    ]
              )}
            />
          </>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aTl',
  description: 'aTl',
  keywords: ['Scale', 'Factor'],
  schema: z.object({
    scale: numberEnum([1.5, 2.5, 3.5]),
    lengths: z.array(z.number().int().min(2).max(6).step(2)),
    shape: QuadrilateralShapesSchema,
    color: quadrilateralColorsSchema
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const scale = getRandomFromArray([1.5, 2.5, 3.5] as const);
    const shape = getRandomFromArray(shapes);
    const color = getRandomFromArray(quadrilateralColors);

    const lengths = ['Square', 'Rhombus'].includes(shape)
      ? [randomIntegerInclusiveStep(2, 6, 2)]
      : ['Rectangle', 'Parallelogram', 'Kite'].includes(shape)
      ? randomUniqueIntegersInclusiveStep(2, 6, 2, 2)
      : randomUniqueIntegersInclusiveStep(2, 6, 2, 3);

    return { scale, shape, color, lengths };
  },
  Component: props => {
    const {
      question: { scale, shape, color, lengths },
      translate,
      displayMode
    } = props;

    const orderedLabels = sortNumberArray(lengths, 'descending');
    const labels =
      orderedLabels.length === 2 && shape === 'Kite'
        ? [orderedLabels[1], orderedLabels[0], orderedLabels[0]]
        : orderedLabels.length === 2
        ? [orderedLabels[0], orderedLabels[1], orderedLabels[0]]
        : orderedLabels.length === 3
        ? [orderedLabels[2], orderedLabels[1], orderedLabels[0]]
        : [orderedLabels[0], orderedLabels[0], orderedLabels[0]];

    const labelsSmall = labels.map(i => translate.units.numberOfCm(i));

    return (
      <QF3Content
        title={translate.instructions.bIsAnEnlargementOfACompleteDimensions(scale.toLocaleString())}
        inputType="numpad"
        questionHeight={1100}
        Content={({ dimens }) => (
          <>
            <LabelledSmallLargeQuadrilateralsWithState
              id="labelShape"
              dimens={{ height: dimens.height, width: dimens.width }}
              labelsLarge={[`<ans/>`, `<ans/>`, `<ans/>`]}
              labelsSmall={labelsSmall}
              color={color}
              shape={shape}
              symbol={translate.units.cm()}
              defaultState={
                displayMode === 'markscheme'
                  ? [
                      translate.units.numberOfCm(scale * labels[0]),
                      translate.units.numberOfCm(scale * labels[1]),
                      translate.units.numberOfCm(scale * labels[2])
                    ]
                  : undefined
              }
              testCorrect={isEqual([
                (scale * labels[0]).toString(),
                (scale * labels[1]).toString(),
                (scale * labels[2]).toString()
              ])}
            />
          </>
        )}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aTl2',
  description: 'aTl',
  keywords: ['Scale', 'Factor'],
  schema: z.object({
    scale: numberEnum([1.5, 2.5, 3.5]),
    lengths: z.array(z.number().int().min(2).max(6).step(2)),
    numOfLabelledSides: numberEnum([2, 4]),
    shape: QuadrilateralShapesSchema,
    color: quadrilateralColorsSchema
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const scale = getRandomFromArray([1.5, 2.5, 3.5] as const);
    const shape = getRandomFromArray(shapes);
    const color = getRandomFromArray(quadrilateralColors);

    const lengths = ['Square', 'Rhombus'].includes(shape)
      ? [randomIntegerInclusiveStep(2, 6, 2)]
      : ['Rectangle', 'Parallelogram', 'Kite'].includes(shape)
      ? randomUniqueIntegersInclusiveStep(2, 6, 2, 2)
      : randomUniqueIntegersInclusiveStep(2, 6, 2, 3);

    const numOfLabelledSides = ['Rectangle', 'Parallelogram', 'Trapezium'].includes(shape)
      ? 2
      : getRandomFromArray([2, 4] as const);

    return { scale, shape, color, lengths, numOfLabelledSides };
  },
  Component: props => {
    const {
      question: { scale, shape, color, lengths, numOfLabelledSides },
      translate,
      displayMode
    } = props;

    const orderedLabels = sortNumberArray(lengths, 'descending');
    const labels =
      orderedLabels.length === 2 && shape === 'Kite'
        ? [orderedLabels[1], orderedLabels[0], orderedLabels[0], orderedLabels[1]]
        : orderedLabels.length === 2
        ? [orderedLabels[0], orderedLabels[1], orderedLabels[0], orderedLabels[1]]
        : orderedLabels.length === 3
        ? [orderedLabels[2], orderedLabels[1], orderedLabels[0], orderedLabels[1]]
        : [orderedLabels[0], orderedLabels[0], orderedLabels[0], orderedLabels[0]];

    const labelsSmall = labels.map(i => translate.units.numberOfCm(i));

    const answers = [
      (scale * labels[0]).toString(),
      (scale * labels[1]).toString(),
      (scale * labels[2]).toString(),
      (scale * labels[3]).toString()
    ].filter((_val, i) => i < numOfLabelledSides);

    return (
      <QF3Content
        title={translate.instructions.bIsAnEnlargementOfACompleteDimensions(scale.toLocaleString())}
        inputType="numpad"
        extraSymbol="decimalPoint"
        questionHeight={1100}
        Content={({ dimens }) => (
          <LabelledSmallLargeQuadrilateralsWithState
            id="labelShape"
            dimens={{ height: dimens.height, width: dimens.width }}
            labelsLarge={filledArray(`<ans/>`, numOfLabelledSides)}
            labelsSmall={labelsSmall.filter((_val, i) => i < numOfLabelledSides)}
            color={color}
            shape={shape}
            symbol={translate.units.cm()}
            defaultState={
              displayMode === 'markscheme'
                ? [
                    translate.units.numberOfCm(scale * labels[0]),
                    translate.units.numberOfCm(scale * labels[1]),
                    translate.units.numberOfCm(scale * labels[2]),
                    translate.units.numberOfCm(scale * labels[3])
                  ].filter((_val, i) => i < numOfLabelledSides)
                : undefined
            }
            testCorrect={userAnswer => answers.every((val, i) => compareFloats(val, userAnswer[i]))}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseScaleFactors',
  questionTypes: [Question1, Question2, Question4v2, Question5v2, Question6v2],
  archivedQuestionTypes: [Question3, Question4, Question5, Question6]
});
export default SmallStep;
