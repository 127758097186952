import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { arraysHaveSameContents, sortNumberArray } from '../../../../utils/collections';
import {
  randomIntegerInclusive,
  getRandomFromArray,
  seededRandom,
  shuffle,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { fractionSchema, numberEnum } from '../../../../utils/zod';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { Fraction, fractionToDecimal } from '../../../../utils/fractions';
import FractionWall from '../../../../components/question/representations/FractionWall';
import QF34aInteractiveBarModelColumnsWithSentenceDrag from '../../../../components/question/questionFormats/QF34aInteractiveBarModelColumnsWithSentenceDrag';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apy',
  description: 'apy',
  keywords: ['Compare', 'Mixed numbers'],
  schema: z.object({
    fracADenominator: z.number().int().min(2).max(4),
    fracANumerator: z.number().int().min(1).max(3),
    fracAWholeNumber: z.number().int().min(1).max(2),
    fracBDenominator: z.number().int().min(2).max(4),
    fracBNumerator: z.number().int().min(1).max(3),
    fracBWholeNumber: z.number().int().min(1).max(2)
  }),
  simpleGenerator: () => {
    const {
      fracADenominator,
      fracANumerator,
      fracAWholeNumber,
      fracBDenominator,
      fracBNumerator,
      fracBWholeNumber
    } = rejectionSample(
      () => {
        const fracAWholeNumber = randomIntegerInclusive(1, 2);
        const fracADenominator = randomIntegerInclusive(2, 4);
        const fracANumerator = randomIntegerInclusive(1, fracADenominator - 1);
        const fracBWholeNumber = randomIntegerInclusive(1, 2);
        const fracBDenominator = randomIntegerInclusive(2, 4);
        const fracBNumerator = randomIntegerInclusive(1, fracBDenominator - 1);

        return {
          fracAWholeNumber,
          fracADenominator,
          fracANumerator,
          fracBWholeNumber,
          fracBDenominator,
          fracBNumerator
        };
      },
      // Each array represents a fraction
      // Each fraction must have at least 1 differing number
      // Ensure arrays don't match to prove at least 1 number is different in each array
      ({
        fracAWholeNumber,
        fracADenominator,
        fracANumerator,
        fracBWholeNumber,
        fracBDenominator,
        fracBNumerator
      }) =>
        !arraysHaveSameContents(
          [fracAWholeNumber, fracANumerator, fracADenominator],
          [fracBWholeNumber, fracBNumerator, fracBDenominator]
        )
    );

    return {
      fracAWholeNumber,
      fracADenominator,
      fracANumerator,
      fracBWholeNumber,
      fracBDenominator,
      fracBNumerator
    };
  },
  Component: props => {
    const {
      question: {
        fracAWholeNumber,
        fracADenominator,
        fracANumerator,
        fracBWholeNumber,
        fracBDenominator,
        fracBNumerator
      },
      translate
    } = props;

    const bars = [
      {
        idx: 0,
        rows: 1,
        cols: fracADenominator
      },
      {
        idx: 1,
        rows: 1,
        cols: fracADenominator
      },
      {
        idx: 2,
        rows: 1,
        cols: fracADenominator
      },
      {
        idx: 3,
        rows: 1,
        cols: fracBDenominator
      },
      {
        idx: 4,
        rows: 1,
        cols: fracBDenominator
      },
      {
        idx: 5,
        rows: 1,
        cols: fracBDenominator
      }
    ];

    // Convert fractions to decimals
    const fracA = fracAWholeNumber + fractionToDecimal(fracANumerator, fracADenominator);
    const fracB = fracBWholeNumber + fractionToDecimal(fracBNumerator, fracBDenominator);

    // Compare fractions and return correct answer (>, < or =)
    const answer = lessThanGreaterThanOrEqualTo(fracA, fracB);

    return (
      <QF34aInteractiveBarModelColumnsWithSentenceDrag
        title={translate.instructions.dragTheCardsToCompareTheFractionsShadeBarModelsToHelp()}
        pdfTitle={translate.instructions.useInequalitySymbolsToCompareTheFractionsShadeBarModelsToHelp()}
        bars={bars}
        sentence={`<frac w='${fracAWholeNumber}' n='${fracANumerator}' d='${fracADenominator}'/>  <ans />  <frac w='${fracBWholeNumber}' n='${fracBNumerator}' d='${fracBDenominator}'/>`}
        items={[
          { component: <Text variant="WRN700">{'>'}</Text>, value: '>' },
          { component: <Text variant="WRN700">{'<'}</Text>, value: '<' },
          { component: <Text variant="WRN700">{'='}</Text>, value: '=' }
        ]}
        rowLabels={[
          `<frac w='${fracAWholeNumber.toLocaleString()}' n='${fracANumerator}' d='${fracADenominator}'/>`,
          `<frac w='${fracBWholeNumber.toLocaleString()}' n='${fracBNumerator}' d='${fracBDenominator}'/>`
        ]}
        numberOfBarsPerRow={3}
        testCorrectSentence={[answer]}
        actionPanelVariant="end"
        questionHeight={1000}
        hideItemsPdf
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'apz',
  description: 'apz',
  keywords: ['Compare', 'Mixed numbers'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: z.number().int().min(1).max(9),
    number3: z.number().int().min(1).max(9),
    number4: z.number().int().min(2).max(10),
    number5: z.number().int().min(1).max(9),
    number6: z.number().int().min(1).max(9),
    number7: z.number().int().min(3).max(9),
    number8: z.number().int().min(2).max(8),
    number9: z.number().int().min(1).max(8),
    number10: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const [number2, number3] = randomUniqueIntegersInclusive(1, 9, 2);

    const number4 = randomIntegerInclusive(2, 10);
    const number5 = randomIntegerInclusive(1, 9);
    const number6 = randomIntegerInclusive(1, 9);

    const number7 = randomIntegerInclusive(3, 9);
    const number8 = number7 - 1;
    const number9 = randomIntegerInclusive(1, 8);
    const number10 = number9 + 1;

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      number10
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number9,
        number10
      },
      translate,
      displayMode
    } = props;

    const sentences = shuffle(
      [
        {
          text: `<frac w='${number2.toLocaleString()}' n='1' d='${number1}'/> <ans/> <frac w='${number3}' n='1' d='${number1}'/>`,
          correctAnswer: lessThanGreaterThanOrEqualTo(number2 + 1 / number1, number3 + 1 / number1)
        },
        {
          text: `<frac w='${number5.toLocaleString()}' n='1' d='${number4}'/> <ans/> ${number6}`,
          correctAnswer: lessThanGreaterThanOrEqualTo(number5 + 1 / number4, number6)
        },
        {
          text: `<frac w='${number9.toLocaleString()}' n='${number8}' d='${number7}'/> <ans/> <frac w='${number10.toLocaleString()}' n='1' d='${number8}'/>`,
          correctAnswer: lessThanGreaterThanOrEqualTo(
            number9 + number8 / number7,
            number10 + 1 / number8
          )
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToCompareTheFractions()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareFractions()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={sentences.map(it => it.text)}
        testCorrect={sentences.map(it => [it.correctAnswer])}
        moveOrCopy="copy"
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'apA',
  description: 'apA',
  keywords: ['Fractions', 'Mixed numbers', 'Compare', 'Order'],
  schema: z.object({
    number1: z.number().int().min(3).max(8),
    number2: z.number().int().min(1).max(6),
    number3: z.number().int().min(2).max(5),
    number4: z.number().int().min(2).max(9),
    number5: z.number().int().min(1).max(7),
    number6: z.number().int().min(1).max(6),
    ascendingOrDescending: z.enum(['ascending', 'descending'])
  }),
  questionHeight: 1680,
  simpleGenerator: () => {
    const randomA = getRandomFromArray([true, false]);
    const randomB = getRandomFromArray([true, false]);
    const randomC = getRandomFromArray([true, false]);
    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    const number1 = randomIntegerInclusive(3, 8);
    const number2 = randomIntegerInclusive(1, number1 - 2);
    const number3 = randomIntegerInclusive(2, 5);
    const number4 = randomA ? number1 + 1 : number1 - 1;
    const number5 = randomB || number2 === 1 ? number2 + 1 : number2 - 1;
    const number6 = randomC ? number3 + 1 : number3 - 1;

    return { number1, number2, number3, number4, number5, number6, ascendingOrDescending };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6, ascendingOrDescending },
      translate,
      displayMode
    } = props;

    // Randomly order these equations
    const eqA = {
      string: `<frac w='${number3.toLocaleString()}' n='${number2}' d='${number1}'/>`,
      value: number3 + Number(fractionToDecimal(number2, number1))
    };
    const eqB = {
      string: `<frac w='${number3.toLocaleString()}' n='${number2}' d='${number4}'/>`,
      value: number3 + Number(fractionToDecimal(number2, number4))
    };
    const eqC = {
      string: `<frac w='${number3.toLocaleString()}' n='${number5}' d='${number1}'/>`,
      value: number3 + Number(fractionToDecimal(number5, number1))
    };
    const eqD = {
      string: `<frac w='${number6.toLocaleString()}' n='${number2}' d='${number1}'/>`,
      value: number6 + Number(fractionToDecimal(number2, number1))
    };
    const eqs = shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.orderNumbersFromSmallestToGreatest()
            : translate.instructions.orderNumbersFromGreatestToSmallest()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderNumbersFromSmallestToGreatest()
            : translate.instructions.useCardsToOrderNumbersFromGreatestToSmallest()
        }
        testCorrect={sortNumberArray(
          eqs.map(x => x.value),
          ascendingOrDescending === 'ascending' ? 'ascending' : 'descending'
        )}
        draggableVariant="rectangle"
        promptButton={translate.extraContentModal.viewFractionWall()}
        modalTitle={translate.extraContentModal.fractionWall()}
        modalContent={<FractionWall />}
        items={eqs.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontWeight: '700',
                fontSize: displayMode === 'digital' ? 30 : 50
              }}
            />
          )
        }))}
        topLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={1680}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'apB',
  description: 'apB',
  keywords: ['Compare', 'Mixed numbers'],
  schema: z.object({
    fractionA: fractionSchema(),
    fractionB: fractionSchema()
  }),
  simpleGenerator: () => {
    const barModel2Denominator = randomIntegerInclusive(2, 3);
    const number5 = randomIntegerInclusive(1, barModel2Denominator - 1);
    const barModel1Numerator = barModel2Denominator + number5;
    const barModel4Denominator = barModel2Denominator * 2;
    const number6 = randomIntegerInclusive(3, barModel4Denominator - 1);
    const barModel3Numerator = barModel4Denominator + number6;
    const reverse = Math.random() < 0.5;

    const [fracA, fracB]: Fraction[] = [
      [barModel1Numerator, barModel2Denominator],
      [barModel3Numerator, barModel4Denominator]
    ];

    return {
      fractionA: reverse ? fracA : fracB,
      fractionB: reverse ? fracB : fracA
    };
  },
  Component: props => {
    const {
      question: { fractionA, fractionB },
      translate
    } = props;

    const [fracANumerator, fracADenominator] = fractionA;
    const [fracBNumerator, fracBDenominator] = fractionB;

    const bars = [
      {
        idx: 0,
        rows: 1,
        cols: fracADenominator
      },
      {
        idx: 1,
        rows: 1,
        cols: fracADenominator
      },
      {
        idx: 2,
        rows: 1,
        cols: fracBDenominator
      },
      {
        idx: 3,
        rows: 1,
        cols: fracBDenominator
      }
    ];

    const fracADecimal = Number(fractionToDecimal(fracANumerator, fracADenominator));
    const fracBDecimal = Number(fractionToDecimal(fracBNumerator, fracBDenominator));

    // Compare fractions and return correct answer (>, < or =)
    const answer = lessThanGreaterThanOrEqualTo(fracADecimal, fracBDecimal);

    return (
      <QF34aInteractiveBarModelColumnsWithSentenceDrag
        title={translate.instructions.dragTheCardsToCompareTheFractionsShadeBarModelsToHelp()}
        pdfTitle={translate.instructions.useInequalitySymbolsToCompareTheFractionsShadeBarModelsToHelp()}
        bars={bars}
        sentence={`<frac n='${fracANumerator}' d='${fracADenominator}' />  <ans />  <frac n='${fracBNumerator}' d='${fracBDenominator}' />`}
        items={[
          { component: <Text variant="WRN700">{'>'}</Text>, value: '>' },
          { component: <Text variant="WRN700">{'<'}</Text>, value: '<' },
          { component: <Text variant="WRN700">{'='}</Text>, value: '=' }
        ]}
        rowLabels={[
          `<frac n='${fracANumerator}' d='${fracADenominator}'/>`,
          `<frac n='${fracBNumerator}' d='${fracBDenominator}'/>`
        ]}
        numberOfBarsPerRow={2}
        actionPanelVariant="end"
        testCorrectSentence={[answer]}
        questionHeight={1100}
        hideItemsPdf
        pdfTableHeight={200}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'apC',
  description: 'apC',
  keywords: ['Compare', 'Improper fractions'],
  schema: z.object({
    shuffledA: fractionSchema(z.number().int().min(5).max(25), z.number().int().min(2).max(12))
      .array()
      .length(2),
    shuffledB: fractionSchema(z.number().int().min(3).max(23), z.number().int().min(2).max(12))
      .array()
      .length(2),
    shuffledC: fractionSchema(z.number().int().min(3).max(17), z.number().int().min(2).max(10))
      .array()
      .length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);
    const number2 = 2 * number1 + 1;
    const number3 = 2 * number1;
    const number4 = 2 * number3 + 1;

    const number5 = randomIntegerInclusive(2, 6);
    const number6 = 2 * number1 - 1;
    const number7 = 2 * number5;
    const number8 = 2 * number7 - 1;

    const number9 = randomIntegerInclusive(2, 9);
    const number10 = 2 * number9 - 1;
    const number11 = number9 + 1;

    const statementAFractions: Fraction[] = [
      [number2, number1],
      [number4, number3]
    ];

    const statementBFractions: Fraction[] = [
      [number6, number5],
      [number8, number7]
    ];

    const statementCFractions: Fraction[] = [
      [number10, number9],
      [number10, number11]
    ];

    // Shuffle individual fractions
    const shuffledA = shuffle(statementAFractions);
    const shuffledB = shuffle(statementBFractions);
    const shuffledC = shuffle(statementCFractions);

    return { shuffledA, shuffledB, shuffledC };
  },
  Component: props => {
    const {
      question: { shuffledA, shuffledB, shuffledC },
      translate
    } = props;

    const fracs = shuffle(
      [
        [...shuffledA[0], ...shuffledA[1]],
        [...shuffledB[0], ...shuffledB[1]],
        [...shuffledC[0], ...shuffledC[1]]
      ],
      { random: seededRandom(props.question) }
    );

    const correctAnswers = fracs.map(frac => {
      const lhs = fractionToDecimal(frac[0], frac[1]);
      const rhs = fractionToDecimal(frac[2], frac[3]);

      return [lessThanGreaterThanOrEqualTo(lhs, rhs)];
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToCompareTheFractions()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareFractions()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={fracs.map(
          frac =>
            `<frac n='${frac[0]}' d='${frac[1]}'/> <ans/> <frac n='${frac[2]}' d='${frac[3]}'/>`
        )}
        testCorrect={correctAnswers}
        moveOrCopy="copy"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'apD',
  description: 'apD',
  keywords: ['Fractions', 'Mixed numbers', 'Improper fractions', 'Compare', 'Order'],
  schema: z
    .object({
      number1: numberEnum([3, 5, 7, 11]),
      number2: z.number().int().min(1).max(10),
      number4: z.number().int().min(6).max(55),
      number5: z.number().int().min(1).max(54),
      number7: z.number().int().min(4).max(22),
      number8: z.number().int().min(7).max(110),
      ascendingOrDescending: z.enum(['ascending', 'descending'])
    })
    .refine(
      val => val.number7 !== val.number1 + val.number2,
      'number7 is not equal to number1 + number2'
    )
    .refine(val => val.number8 % 2 !== 0, 'number8 must be an odd number')
    .refine(val => val.number2 < val.number1, 'number2 must be less than number1')
    .refine(val => val.number5 < val.number4, 'number5 must be less than number4'),
  questionHeight: 800,
  simpleGenerator: () => {
    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    const number1 = getRandomFromArray([3, 5, 7, 11] as const);
    const number2 = randomIntegerInclusive(1, number1 - 1);
    const number4 = number1 * randomIntegerInclusive(2, 5);
    const number5 = randomIntegerInclusive(1, number4 - 1);
    // Must not be equal to number1 + number2 e.g (22(v7) < 11(v1) + 10(v2))
    const number7 = randomIntegerInclusive(number1 + 1, 2 * number1, {
      constraint: x => x !== number1 + number2
    });

    // number8 must be an odd number
    const number8 = randomIntegerInclusive(number4 + 1, number4 * 2, {
      constraint: number8 => number8 % 2 !== 0
    });

    return { number1, number2, number4, number5, number7, number8, ascendingOrDescending };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number5, number7, number8, ascendingOrDescending },
      translate,
      displayMode
    } = props;

    // Randomly order these equations
    const eqA = {
      string: `1 <frac n='${number2}' d='${number1}'/>`,
      value: 1 + number2 / number1
    };
    const eqB = {
      string: `<frac n='${number5}' d='${number4}'/>`,
      value: number5 / number4
    };
    const eqC = {
      string: `<frac n='${number7}' d='${number1}'/>`,
      value: number7 / number1
    };
    const eqD = {
      string: `<frac n='${number8}' d='${number4}'/>`,
      value: number8 / number4
    };
    const eqs = shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.orderNumbersFromSmallestToGreatest()
            : translate.instructions.orderNumbersFromGreatestToSmallest()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderNumbersFromSmallestToGreatest()
            : translate.instructions.useCardsToOrderNumbersFromGreatestToSmallest()
        }
        testCorrect={sortNumberArray(
          eqs.map(x => x.value),
          ascendingOrDescending === 'ascending' ? 'ascending' : 'descending'
        )}
        draggableVariant="rectangle"
        items={eqs.map(({ value, string }) => ({
          value,
          component: (
            <TextStructure
              sentence={string}
              textVariant="WRN700"
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          )
        }))}
        topLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderFractionsGreaterThan1',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
