import React from 'react';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PieChart from '../../../../components/question/representations/PieChart';
import { View } from 'react-native';
import { countRange, filledArray } from '../../../../utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { PieChartColors, barModelColors } from '../../../../theme/colors';
import {
  compareFractions,
  formatFractionToMarkup,
  improperFractionToMixedNumber,
  portionToText,
  renderFractionCounter
} from '../../../../utils/fractions';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { AssetSvg } from '../../../../assets/svg';
import { characterNameLabel, getCharacterHeadSvgName } from '../../../../utils/characters';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import Text from '../../../../components/typography/Text';
import { all, create, number } from 'mathjs';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMM',
  description: 'aMM',
  keywords: [
    'Fraction counters',
    'Mixed number',
    'Improper fraction',
    'Integer',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(9),
    numerator: z.number().int().min(3).max(12)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 9);
    const numerator = randomIntegerInclusive(denominator + 1, 12);

    return { denominator, numerator };
  },
  Component: props => {
    const {
      question: { denominator, numerator },
      translate,
      displayMode
    } = props;

    const amount = portionToText(denominator, translate, 1) as string;

    const [wholeB, numeratorB, denominatorB] = improperFractionToMixedNumber(
      numerator,
      denominator
    );

    const division = number(math.evaluate(`${numerator} / ${denominator}`));
    const isWholeNumber = division % 1 === 0;

    const rows =
      numerator <= 10
        ? [numerator]
        : [Math.ceil(numerator / 2), numerator - Math.ceil(numerator / 2)];

    return (
      <QF1ContentAndSentence
        sentence={
          isWholeNumber
            ? `${translate.keywords.Improper()} <frac nAns='' dAns=''/> ${translate.keywords.Integer()} <ans/>`
            : `${translate.keywords.Improper()} <frac nAns='' dAns=''/> ${translate.keywords[
                'Mixed number'
              ]()} <frac wAns='' nAns='' dAns=''/>`
        }
        title={translate.instructions.eachCounterRepresentsOneX(amount)}
        sentenceStyle={{ justifyContent: 'space-between' }}
        testCorrect={userAnswer =>
          isWholeNumber
            ? compareFractions([userAnswer[0], userAnswer[1]], [numerator, denominator]) &&
              userAnswer[2] === division.toString()
            : compareFractions([userAnswer[0], userAnswer[1]], [numerator, denominator]) &&
              compareFractions(
                [userAnswer[2], userAnswer[3], userAnswer[4]],
                [wholeB, numeratorB, denominatorB]
              )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: isWholeNumber
            ? [numerator.toLocaleString(), denominator.toLocaleString(), division.toLocaleString()]
            : [
                numerator.toLocaleString(),
                denominator.toLocaleString(),
                wholeB.toLocaleString(),
                numeratorB.toLocaleString(),
                denominatorB.toLocaleString()
              ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={2}
        pdfDirection="column"
        questionHeight={1000}
        Content={
          <View style={{ paddingBottom: 12, gap: 8, width: '100%' }}>
            {rows.map((val, rowId) => (
              <View
                key={`row_${rowId}`}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: 8
                }}
              >
                {countRange(val).map(idx => {
                  return (
                    <React.Fragment key={idx}>
                      {renderFractionCounter(
                        denominator,
                        displayMode === 'digital' ? undefined : 120
                      )}
                    </React.Fragment>
                  );
                })}
              </View>
            ))}
            <Text
              variant="WRN400"
              style={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                justifyContent: 'flex-start'
              }}
            >
              {isWholeNumber
                ? translate.answerSentences.completeTheImproperFractionAndIntegerShownByTheCounters()
                : translate.answerSentences.completeTheImproperFractionAndMixedNumberShownByTheCounters()}
            </Text>
          </View>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aMN',
  description: 'aMN',
  keywords: [
    'Mixed number',
    'Improper fraction',
    'Wholes',
    'Parts',
    'Integer',
    'Numerator',
    'Denominator',
    'Convert'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(6),
      numeratorA: z.number().int().min(1).max(5),
      whole: z.number().int().min(1).max(5)
    })
    .refine(val => val.whole !== val.numeratorA, 'whole must not be equal to numeratorA'),
  questionHeight: 950,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 6);
    const numeratorA = randomIntegerInclusive(1, denominator - 1);
    const whole = randomIntegerInclusive(1, 5, {
      constraint: x => x !== numeratorA
    });

    return { denominator, numeratorA, whole };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, whole },
      translate,
      displayMode
    } = props;

    const numeratorB = whole * denominator + numeratorA;

    const wholeAmount = Math.floor(numeratorB / denominator);

    const remainder = numeratorB % denominator;

    const color = getRandomFromArray(PieChartColors, {
      random: seededRandom(props.question)
    }) as string;

    const pieChartNumbers = [...Array(denominator).keys()].map(i => i + 1);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePieChartsToConvertImproperFractionToMixedNumber()}
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0], userAnswer[1], userAnswer[2]],
            [whole, numeratorA, denominator]
          )
        }
        Content={({ dimens }) => (
          <View style={{ display: 'flex', flexDirection: 'row', columnGap: 8 }}>
            {countRange(wholeAmount).map(idx => (
              <PieChart
                key={idx}
                pieOptions={filledArray({ ratioOfSlices: 1 }, denominator)}
                radius={displayMode === 'digital' ? dimens.height * 0.3 : dimens.height * 0.5}
                color={color}
              />
            ))}
            <PieChart
              missingSlices={pieChartNumbers.slice(remainder - 1)}
              pieOptions={filledArray({ ratioOfSlices: 1 }, denominator)}
              radius={displayMode === 'digital' ? dimens.height * 0.3 : dimens.height * 0.5}
              color={color}
            />
          </View>
        )}
        inputMaxCharacters={1}
        sentence={`<frac n='${numeratorB}' d='${denominator}'/> = <frac wAns='' nAns='' dAns=''/>`}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'center' }}
        questionHeight={950}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole.toLocaleString(),
            numeratorA.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aMO',
  description: 'aMO',
  keywords: [
    'Improper fraction',
    'Mixed numbers',
    'Convert',
    'Parts',
    'Wholes',
    'Numerators',
    'Denominators',
    'Integer'
  ],
  questionHeight: 900,
  schema: z
    .object({
      denominator1A: z.number().int().min(2).max(5),
      numerator1A: z.number().int().min(1).max(4),
      whole1A: z.number().int().min(1).max(5),
      denominator2A: z.number().int().min(6).max(12),
      numerator2A: z.number().int().min(1).max(11),
      whole2A: z.number().int().min(1).max(5),
      improperFractionsRhs: z.array(z.boolean()).length(2)
    })
    .refine(val => val.whole1A !== val.numerator1A, 'whole1A must not be equal to numerator1A'),
  simpleGenerator: () => {
    const denominator1A = randomIntegerInclusive(2, 5);
    const numerator1A = randomIntegerInclusive(1, denominator1A - 1);

    const denominator2A = randomIntegerInclusive(6, 12);
    const numerator2A = randomIntegerInclusive(1, denominator2A - 1);

    const whole1A = randomIntegerInclusive(1, 5, {
      constraint: x => x !== numerator1A
    });

    const whole2A = randomIntegerInclusive(1, 5);

    const improperFractionsRhs = getRandomSubArrayFromArray(
      [getRandomBoolean(), getRandomBoolean()] as const,
      2
    );

    return {
      denominator1A,
      numerator1A,
      whole1A,
      denominator2A,
      numerator2A,
      whole2A,
      improperFractionsRhs
    };
  },
  Component: props => {
    const {
      question: {
        denominator1A,
        numerator1A,
        whole1A,
        denominator2A,
        numerator2A,
        whole2A,
        improperFractionsRhs
      },
      translate
    } = props;

    const numerator1B = whole1A * denominator1A + numerator1A;
    const numerator2B = whole2A * denominator2A + numerator2A;

    const [improperFractionRhs1, improperFractionRhs2] = improperFractionsRhs;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.convertImproperFractionsToMixedNumbers()}
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0][0], userAnswer[0][1], userAnswer[0][2]],
            [whole1A, numerator1A, denominator1A]
          ) &&
          compareFractions(
            [userAnswer[1][0], userAnswer[1][1], userAnswer[1][2]],
            [whole2A, numerator2A, denominator2A]
          )
        }
        inputMaxCharacters={1}
        sentences={[
          improperFractionRhs1
            ? `<frac n='${numerator1B}' d='${denominator1A}' /> = <frac wAns='' nAns='' dAns='' />`
            : `<frac wAns='' nAns='' dAns='' /> = <frac n='${numerator1B}' d='${denominator1A}' />`,
          improperFractionRhs2
            ? `<frac n='${numerator2B}' d='${denominator2A}' /> = <frac wAns='' nAns='' dAns='' />`
            : `<frac wAns='' nAns='' dAns='' /> = <frac n='${numerator2B}' d='${denominator2A}' />`
        ]}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              whole1A.toLocaleString(),
              numerator1A.toLocaleString(),
              denominator1A.toLocaleString()
            ],
            [whole2A.toLocaleString(), numerator2A.toLocaleString(), denominator2A.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aMO2',
  description: 'aMO2',
  keywords: [
    'Improper fraction',
    'Mixed numbers',
    'Convert',
    'Parts',
    'Wholes',
    'Numerators',
    'Denominators',
    'Integer'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(12),
    numerator: z.number().int().min(1).max(11),
    whole: z.number().int().min(1).max(5),
    improperFractionsRhs: z.boolean()
  }),
  simpleGenerator: () => {
    const denominator = getRandomBoolean()
      ? randomIntegerInclusive(2, 5)
      : randomIntegerInclusive(6, 12);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    const whole = randomIntegerInclusive(1, 5, {
      constraint: x => x !== numerator
    });

    const improperFractionsRhs = getRandomBoolean();

    return {
      denominator,
      numerator,
      whole,
      improperFractionsRhs
    };
  },
  Component: props => {
    const {
      question: { denominator, numerator, whole, improperFractionsRhs },
      translate
    } = props;

    const numerator1B = whole * denominator + numerator;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.convertImproperFractionToMixedNumber()}
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0], userAnswer[1], userAnswer[2]],
            [whole, numerator, denominator]
          )
        }
        inputMaxCharacters={1}
        sentence={
          improperFractionsRhs
            ? `<frac n='${numerator1B}' d='${denominator}' /> = <frac wAns='' nAns='' dAns='' />`
            : `<frac wAns='' nAns='' dAns='' /> = <frac n='${numerator1B}' d='${denominator}' />`
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole.toLocaleString(),
            numerator.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aMP',
  description: 'aMP',
  keywords: [
    'Improper fraction',
    'Mixed numbers',
    'Convert',
    'Numerators',
    'Denominators',
    'Integer'
  ],
  schema: z.object({
    denominators: z.array(z.number().min(2).max(10)),
    numerators: z.array(z.number().min(10).max(120))
  }),
  simpleGenerator: () => {
    const { numerators, denominators } = rejectionSample(
      () => {
        const denominators = randomUniqueIntegersInclusive(2, 10, 6);
        const numerators = randomUniqueIntegersInclusive(10, 120, 6);

        return { denominators, numerators };
      },
      val => {
        const isValid = countRange(6).filter(idx => {
          return (
            val.numerators[idx] > val.denominators[idx] &&
            val.numerators[idx] % val.denominators[idx] === 0
          );
        });

        return isValid.length >= 2 ? true : false;
      }
    );

    return { numerators, denominators };
  },
  Component: props => {
    const {
      question: { numerators, denominators },
      translate
    } = props;

    const answers = countRange(6).map(idx => {
      if (numerators[idx] % denominators[idx] === 0) {
        return {
          sentence: formatFractionToMarkup(numerators[idx], denominators[idx], 'fraction'),
          isCorrect: true
        };
      } else {
        return {
          sentence: formatFractionToMarkup(numerators[idx], denominators[idx], 'fraction'),
          isCorrect: false
        };
      }
    });

    const items = [...answers];

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheImproperFractionsThatAreEquivalentToAnInteger()}
        pdfTitle={translate.instructions.circleTheImproperFractionsThatAreEquivalentToAnInteger()}
        items={items.map(item => ({
          value: item.sentence,
          component: (
            <TextStructure
              sentence={item.sentence}
              textVariant="WRN700"
              textStyle={{ fontSize: 40 }}
              fractionTextStyle={{ fontSize: 32, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          )
        }))}
        testCorrect={items.filter(item => item.isCorrect).map(item => item.sentence)}
        multiSelect
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aMQ',
  description: 'aMQ',
  keywords: ['Mixed number', 'Improper fraction', 'Integer', 'Numerator', 'Denominator', 'Convert'],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(10),
      numeratorA: z.number().int().min(1).max(9),
      numeratorB: z.number().int().min(1).max(9),
      numeratorC: z.number().int().min(1).max(9),
      wholeA: z.number().int().min(1).max(10),
      wholeB: z.number().int().min(1).max(10),
      wholeC: z.number().int().min(1).max(10)
    })
    .refine(val => val.numeratorA !== val.wholeA, 'numeratorA must not be equal to wholeA'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 10);
    const [numeratorA, numeratorB, numeratorC] = randomUniqueIntegersInclusive(1, 9, 3, {
      constraint: x => x < denominator
    });
    const wholeA = randomIntegerInclusive(1, 10, {
      constraint: x => x !== numeratorA
    });
    const wholeB = randomIntegerInclusive(1, 10, {
      constraint: x => x !== wholeA && x !== numeratorB
    });
    const wholeC = randomIntegerInclusive(1, 10, {
      constraint: x => x !== wholeA && x !== wholeB && x !== numeratorC
    });

    return {
      denominator,
      numeratorA,
      numeratorB,
      numeratorC,
      wholeA,
      wholeB,
      wholeC
    };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, numeratorB, numeratorC, wholeA, wholeB, wholeC },
      translate,
      displayMode
    } = props;

    const numeratorD = wholeA * denominator + numeratorA;
    const numeratorE = wholeB * denominator + numeratorB;
    const numeratorF = wholeC * denominator + numeratorC;

    const items = [
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac w='${wholeA.toLocaleString()}' n='${numeratorA}' d='${denominator}' />`}
          />
        ),
        value: 'A'
      },
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac w='${wholeB.toLocaleString()}' n='${numeratorB}' d='${denominator}' />`}
          />
        ),
        value: 'B'
      },
      {
        component: (
          <TextStructure
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
            sentence={`<frac w='${wholeC.toLocaleString()}' n='${numeratorC}' d='${denominator}' />`}
          />
        ),
        value: 'C'
      }
    ];

    const sentences = shuffle(
      [
        {
          sentence: `<frac n='${numeratorD}' d='${denominator}' /> = <ans/>`,
          answer: 'A'
        },
        {
          sentence: `<frac n='${numeratorE}' d='${denominator}' /> = <ans/>`,
          answer: 'B'
        },
        {
          sentence: `<frac n='${numeratorF}' d='${denominator}' /> = <ans/>`,
          answer: 'C'
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragNumberCardsToMatchMixedNumbersToImproperFractions()}
        pdfTitle={translate.instructions.useNumberCardsToMatchMixedNumbersToImproperFractions()}
        actionPanelVariant="end"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfLayout="itemsRight"
        items={items}
        sentences={sentences.map(sentence => sentence.sentence)}
        testCorrect={sentences.map(sentence => [sentence.answer])}
        moveOrCopy="move"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aMR',
  description: 'aMR',
  keywords: [
    'Mixed number',
    'Improper fraction',
    'Bar model',
    'Parts',
    'Wholes',
    'Numerators',
    'Denominators'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(5),
      numeratorA: z.number().int().min(1).max(4),
      whole: z.number().int().min(1).max(5),
      name: nameSchema,
      random: z.boolean()
    })
    .refine(val => val.whole !== val.numeratorA, 'wholeA must not be equal to numeratorA'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 5);
    const numeratorA = randomIntegerInclusive(1, denominator - 1);
    const whole = randomIntegerInclusive(1, 5, {
      constraint: x => x !== numeratorA
    });
    const name = getRandomName();
    const random = getRandomBoolean();

    return { denominator, numeratorA, whole, name, random };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, whole, name, random },
      translate,
      displayMode
    } = props;

    const numeratorB = whole * denominator + numeratorA;

    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ numeratorA, numeratorB })
    }) as string;

    const numeratorColorArray1 = filledArray(color, denominator);
    const customColorMapA = [...numeratorColorArray1];

    const numeratorColorArray2 = filledArray(color, numeratorA);
    const remainder1 = filledArray('white', denominator - numeratorA);
    const customColorMapB = [...numeratorColorArray2, ...remainder1];

    // Incorrect
    const incorrect = shuffle(
      [
        translate.answerSentences.theBarModelShowsBothFracAAndFracB(
          `<frac n='${numeratorB}' d='${denominator}'/>`,
          `<frac w='${whole.toLocaleString()}' n='${denominator}' d='${numeratorA}'/>`
        ),
        translate.answerSentences.theBarModelShowsBothFracAAndFracB(
          `<frac n='${denominator}' d='${numeratorB}'/>`,
          `<frac w='${whole.toLocaleString()}' n='${numeratorA}' d='${denominator}'/>`
        )
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.hereIsABarModel()}
        correctAnswer={random ? true : false}
        sentence={
          displayMode === 'digital'
            ? translate.instructions.isCharacterCorrect(name)
            : translate.instructions.isCharacterCorrectPDF(name)
        }
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        content={({ dimens }) => (
          <View style={{ rowGap: 32 }}>
            <View style={{ paddingBottom: 20 }}>
              <View style={{ flexDirection: 'row', columnGap: 32 }}>
                {countRange(whole).map(idx => (
                  <ShadedFractionBarModel
                    key={idx}
                    totalSubSections={denominator}
                    customColorMap={customColorMapA}
                    width={dimens.width / (whole + 2)}
                  />
                ))}
                <ShadedFractionBarModel
                  totalSubSections={denominator}
                  customColorMap={customColorMapB}
                  width={dimens.width / (whole + 2)}
                />
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flex: 1
              }}
            >
              <SpeechBubble
                flickLocation="top-right"
                style={{
                  maxWidth: 0.6 * dimens.width,
                  marginHorizontal: 0.05 * dimens.width,
                  maxHeight: '50%',
                  zIndex: 1
                }}
              >
                {random
                  ? translate.answerSentences.theBarModelShowsBothFracAAndFracB(
                      `<frac n='${numeratorB}' d='${denominator}'/>`,
                      `<frac w='${whole.toLocaleString()}' n='${numeratorA}' d='${denominator}'/>`
                    )
                  : incorrect[0]}
              </SpeechBubble>

              <View
                style={{
                  alignSelf: 'flex-end',
                  alignItems: 'center',
                  zIndex: 0,
                  rowGap: 10
                }}
              >
                <AssetSvg
                  name={getCharacterHeadSvgName(name)}
                  height={dimens.height * 0.4}
                  width={dimens.width * 0.3}
                />
                {characterNameLabel(name, dimens.width * 0.2)}
              </View>
            </View>
          </View>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertImproperFractionsToMixedNumbers',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
