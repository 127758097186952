import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import {
  findExchanges,
  getSumWithOnlyGivenExchanges,
  numbersDoNotExchange,
  numbersDoNotExchangeAt,
  numbersOnlyExchangeAt
} from '../../../../utils/exchanges';
import ColumnOperations from '../../../../components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { SUB } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF9DragIntoTableOfGroups from '../../../../components/question/questionFormats/QF9DragIntoTableOfGroups';
import { NonEmptyArray, range } from '../../../../utils/collections';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { monthAsWord, monthNames } from '../../../../utils/months';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { digitAtPowerIsNumber } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ag8',
  description: 'ag8',
  keywords: ['Subtraction', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(val => numbersOnlyExchangeAt(val.var1, -val.var2, 'tens'), 'numbers exchange at tens')
    .refine(val => val.var1 > val.var2, 'var2 should be less than var1')
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(102, 999, {
          constraint: x => x % 10 !== 0
        });

        const var2 = randomIntegerInclusive(101, var1 - 1, {
          constraint: x => x % 10 !== 0
        });
        return { var1, var2 };
      },
      // Only permit them if they exchange at ones and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1, -var2, 'tens') && var1 - var2 >= 15
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question1v2 = newQuestionContent({
  uid: 'ag82',
  description: 'ag8',
  keywords: ['Subtraction', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(val => numbersOnlyExchangeAt(val.var1, -val.var2, 'tens'), 'numbers exchange at tens')
    .refine(val => val.var1 > val.var2, 'var2 should be less than var1')
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(102, 999, {
          constraint: x => x % 10 !== 0
        });

        const var2 = randomIntegerInclusive(101, var1 - 1, {
          constraint: x => x % 10 !== 0
        });
        return { var1, var2 };
      },
      // Only permit them if they exchange at ones and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1, -var2, 'tens') && var1 - var2 >= 15
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        showExtraLeadingAnswerBox={answerMissingDigits.length < 3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ag9',
  description: 'ag9',
  keywords: ['Subtraction', 'Counters', 'Column', 'Exchange'],
  schema: z
    .object({
      name: nameSchema,
      distance1: z.number().int().min(301).max(659),
      distance2: z.number().int().min(149).max(549),
      month1: z.enum([
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November'
      ])
    })
    .refine(val => val.distance1 > val.distance2, 'distance1 must be greater than distance2.'),
  simpleGenerator: () => {
    const name = getRandomName();

    const { distance1, distance2 } = rejectionSample(
      () => {
        const distance1 = randomIntegerInclusive(301, 659);

        const distance2 = randomIntegerInclusive(149, 549, {
          constraint: x => distance1 > x
        });
        return { distance1, distance2 };
      },
      ({ distance1, distance2 }) => numbersOnlyExchangeAt(distance1, -distance2, 'tens')
    );

    const month1 = getRandomFromArray([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November'
    ] as const);

    return { name, distance1, distance2, month1 };
  },

  Component: props => {
    const {
      question: { name, distance1, distance2, month1 },
      translate
    } = props;

    const month1Index = monthNames.indexOf(month1);

    const month2 = monthNames[month1Index + 1];

    const month1String = monthAsWord(month1, translate);

    const month2String = monthAsWord(month2, translate);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.charCyclesNumKmInMonth1AndNumKmInMonth2(
          name,
          distance1,
          month1String,
          distance2,
          month2String
        )}
        sentence={translate.answerSentences.ansKm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(distance1 - distance2).toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aha',
  description: 'aha',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(899),
      numberA2: z.number().int().min(100).max(899),
      numberB1: z.number().int().min(100).max(899),
      numberB2: z.number().int().min(100).max(899),
      numberC1: z.number().int().min(100).max(899),
      numberC2: z.number().int().min(100).max(899),
      numberD1: z.number().int().min(100).max(899),
      numberD2: z.number().int().min(100).max(899),
      cHasExchange: z.boolean(),
      dHasExchange: z.boolean()
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => findExchanges(val.numberB1, val.numberB2).length === 1,
      'numberB1 and numberB2 must have one exchange.'
    ),
  simpleGenerator: () => {
    const checkConstraint = (firstValue: number, secondNumber: number, exchange: boolean) => {
      if (exchange) {
        return (
          findExchanges(firstValue, secondNumber).length === 1 &&
          numbersDoNotExchangeAt(firstValue, secondNumber, 'hundreds')
        );
      } else return numbersDoNotExchange(firstValue, secondNumber);
    };

    const { numberA1, numberA2 } = rejectionSample(
      () => {
        const numberA1 = randomIntegerInclusive(100, 899);
        const numberA2 = randomIntegerInclusive(100, 999 - numberA1);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => checkConstraint(numberA1, numberA2, false)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 1,000.
        const numberB1 = randomIntegerInclusive(100, 899);
        const numberB2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberB1, numberB2 };
      },
      // Only permit them if they have one exchange
      ({ numberB1, numberB2 }) => checkConstraint(numberB1, numberB2, true)
    );

    const cHasExchange = getRandomBoolean();
    const { numberC1, numberC2 } = rejectionSample(
      () => {
        const numberC1 = randomIntegerInclusive(100, 899);
        const numberC2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberC1, numberC2 };
      },
      ({ numberC1, numberC2 }) => checkConstraint(numberC1, numberC2, cHasExchange)
    );

    const dHasExchange = getRandomBoolean();
    const { numberD1, numberD2 } = rejectionSample(
      () => {
        const numberD1 = randomIntegerInclusive(100, 899);
        const numberD2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberD1, numberD2 };
      },
      ({ numberD1, numberD2 }) => checkConstraint(numberD1, numberD2, dHasExchange)
    );
    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        cHasExchange,
        numberC1,
        numberC2,
        dHasExchange,
        numberD1,
        numberD2
      },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1 + numberA2, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1 + numberB2, bottomNumber: numberB2, isCorrect: true };
      const eqC = {
        topNumber: numberC1 + numberC2,
        bottomNumber: numberC2,
        isCorrect: cHasExchange
      };
      const eqD = {
        topNumber: numberD1 + numberD2,
        bottomNumber: numberD2,
        isCorrect: dHasExchange
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheSubtractionsThatWillNeedAnExchange()}
        pdfTitle={translate.instructions.circleTheSubtractionsThatWillNeedAnExchange()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={SUB}
                dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ahb',
  description: 'ahb',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      number1: z.number().int().min(110).max(799),
      number2: z.number().int().min(110).max(799)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'tens'),
      'number1 and number2 must exchange at the tens only.'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(110, 799, {
          constraint: x => x % 100 > 9 // Ensure number1 ends in at least 10 so number1 and number2 can exchange at the tens
        });

        const number2 = randomIntegerInclusive(110, 799, {
          constraint: x => numbersOnlyExchangeAt(number1, x, 'tens')
        });
        return { number1, number2 };
      },
      ({ number1, number2 }) =>
        // Must be at least one zero, in the ones or tens of the minuend or subtrahend, or ones of the difference:
        digitAtPowerIsNumber(number1, 'ones', [0]) ||
        digitAtPowerIsNumber(number2, 'ones', [0]) ||
        digitAtPowerIsNumber(number2, 'tens', [0]) ||
        digitAtPowerIsNumber(number1 + number2, 'ones', [0]) ||
        digitAtPowerIsNumber(number1 + number2, 'tens', [0])
    );

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;
    const answerMissingDigits = range(0, number1.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutXSubtractY(
          number3.toLocaleString(),
          number2.toLocaleString()
        )}
        topNumber={number3}
        bottomNumber={number2}
        operation={SUB}
        answerNumber={number1}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number1, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ahc',
  description: 'ahc',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z.object({
    sums: z
      .array(
        z
          .array(z.number().int().min(100).max(999))
          .length(2)
          .refine(
            ([minuend, subtrahend]) => subtrahend <= minuend,
            'The second number (subtrahend) must be smaller than the first number (minuend).'
          )
          .refine(([minuend, subtrahend]) => {
            const exchanges = findExchanges(minuend, -subtrahend);
            return exchanges.length <= 2 && exchanges.every(it => it === 0 || it === 1);
          }, 'Each sum must exchange at at most 2 columns, and this must be across the 10s or the 100s.')
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const getSum = (exchanges: number[]): [minuend: number, subtrahend: number] => {
      const [left, right] = getSumWithOnlyGivenExchanges(exchanges, 3, { allowFewerDigits: false });
      return [left + right, right];
    };

    const possibleExchanges = [[], [0], [1], [0, 1]] as NonEmptyArray<number[]>;
    // To make this a good question, we choose 3 of the 4 exchange options and then a random one.
    const sums = getRandomSubArrayFromArray(possibleExchanges, 3).map(getSum);
    sums.push(getSum(getRandomFromArray(possibleExchanges)));
    return { sums: shuffle(sums) };
  },
  Component: ({ question: { sums }, translate }) => {
    const correctAnswer = [
      [[], []],
      [[], []]
    ] as [[both: number[], hundreds: number[]], [tens: number[], neither: number[]]];
    sums.forEach(([minuend, subtrahend], sumIndex) => {
      const exchanges = findExchanges(minuend, -subtrahend);
      const rowIndex = exchanges.includes(1) ? 0 : 1; // 0th column is hundreds, 1st is not hundreds
      const columnIndex = exchanges.includes(0) ? 0 : 1; // 0th column is tens, 1st is not tens
      correctAnswer[rowIndex][columnIndex].push(sumIndex);
    });

    return (
      <QF9DragIntoTableOfGroups
        title={translate.instructions.dragCardsSortSubtractions()}
        pdfTitle={translate.instructions.useCardsSortSubtractions()}
        rowNames={[
          translate.tableHeaders.exchangeA((100).toLocaleString()),
          translate.tableHeaders.doesNotExchangeA((100).toLocaleString())
        ]}
        columnNames={[
          translate.tableHeaders.exchangeA((10).toLocaleString()),
          translate.tableHeaders.doesNotExchangeA((10).toLocaleString())
        ]}
        tableHeaderMaxLines={2}
        tableHeaderStyle={{ fontSize: 20, lineHeight: 26 }}
        testCorrect={correctAnswer}
        items={sums.map(([minuend, subtrahend], sumIndex) => ({
          value: sumIndex,
          component: `${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()}`
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={4}
        questionHeight={1400}
        pdfTableHeaderStyle={{ fontSize: 36, lineHeight: 48 }}
        pdfTableSideHeaderStyle={{ lineHeight: 36 }}
      />
    );
  },
  questionHeight: 1400
});

const Question6 = newQuestionContent({
  uid: 'ahd',
  description: 'ahd',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      answerNumber: z.number().int().min(110).max(799),
      bottomNumber: z.number().int().min(110).max(799),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => numbersOnlyExchangeAt(val.answerNumber, val.bottomNumber, 'tens'),
      'answerNumber and bottomNumber must exchange at the tens only.'
    )
    .refine(
      val => val.missingOnes !== val.missingTens && val.missingOnes !== val.missingHundreds,
      'missingOnes must be in a different number to missingTens and missingHundreds.'
    )
    .refine(
      val => val.missingTens !== val.missingHundreds,
      'missingTens must be in a different number to missingHundreds.'
    ),
  simpleGenerator: () => {
    const answerNumber = randomIntegerInclusive(110, 799, {
      constraint: x => x % 100 > 9 // Ensure answerNumber ends in at least 10 so answerNumber and bottomNumber can exchange at the tens
    });

    const bottomNumber = randomIntegerInclusive(110, 799, {
      constraint: x => numbersOnlyExchangeAt(answerNumber, x, 'tens')
    });

    const [missingOnes, missingTens, missingHundreds] = shuffle([
      'top',
      'bottom',
      'answer'
    ] as const);

    return { answerNumber, bottomNumber, missingOnes, missingTens, missingHundreds };
  },

  Component: props => {
    const {
      question: { answerNumber, bottomNumber, missingOnes, missingTens, missingHundreds },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds
    );
    const topNumber = answerNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={SUB}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwoNumbersAcrossA100',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
