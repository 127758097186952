import { z } from 'zod';
import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { ADD, DIV, MULT, SUB } from '../../../../constants';
import { isEqual } from '../../../../utils/matchers';
import { NumbersWithArrowsWithState } from '../../../../components/question/representations/ValuesWithArrows/NumbersWithArrows';
import { all, create, number } from 'mathjs';
import { SequenceBoxesWithState } from '../../../../components/question/representations/SequenceBoxes';
import { arraysHaveSameContentsUnordered, range } from '../../../../utils/collections';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSM',
  description: 'aSM',
  keywords: ['Add', 'Multiply'],
  schema: z.object({
    number1: z.number().int().min(2).max(6),
    number2: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);
    const number2 =
      number1 === 2 ? getRandomFromArray([1, ...range(3, 12)]) : randomIntegerInclusive(1, 12);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const answer1 = number1 + number2;
    const answer2 = number1 * number2;

    return (
      <QF3Content
        title={translate.instructions.fillInMissingNumbers()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            <NumbersWithArrowsWithState
              id={'left'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              lhs={number2.toLocaleString()}
              rhs={`<ans/>`}
              factors={[`${ADD} ${number1}`, `${SUB} ${number1}`]}
              arrowDirection={['right', 'left']}
              testCorrect={isEqual([answer1.toString()])}
              defaultState={displayMode === 'markscheme' ? [answer1.toLocaleString()] : undefined}
            />
            <NumbersWithArrowsWithState
              id={'right'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              lhs={number2.toLocaleString()}
              rhs={`<ans/>`}
              factors={[`${MULT} ${number1}`, `${DIV} ${number1}`]}
              arrowDirection={['right', 'left']}
              testCorrect={isEqual([answer2.toString()])}
              defaultState={displayMode === 'markscheme' ? [answer2.toLocaleString()] : undefined}
            />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aSN',
  description: 'aSN',
  keywords: ['Add'],
  questionHeight: 800,
  schema: z.object({
    number1: z.number().int().min(2).max(12),
    number2: z.number().int().min(1).max(12),
    number4: z.number().int().min(1).max(12),
    answerPosition: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 12);
    const [number2, number4] = randomUniqueIntegersInclusive(1, 12, 2);
    const answerPosition = getRandomFromArray(['left', 'right'] as const);

    return { number1, number2, number4, answerPosition };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, answerPosition },
      translate,
      displayMode
    } = props;

    const number3 = number1 + number2;
    const number5 = number1 + number4;
    const lhs1 = answerPosition === 'left' ? '<ans />' : number4.toLocaleString();
    const rhs1 = answerPosition === 'left' ? number5.toLocaleString() : '<ans />';
    const answer1 = answerPosition === 'left' ? number4 : number5;

    return (
      <QF3Content
        title={translate.instructions.fillInMissingNumbers()}
        inputType="numpad"
        questionHeight={800}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            <NumbersWithArrowsWithState
              id={'left'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              lhs={lhs1}
              rhs={rhs1}
              factors={[`${ADD} ${number1}`, `${SUB} ${number1}`]}
              arrowDirection={['right', 'left']}
              testCorrect={isEqual([answer1.toString()])}
              defaultState={displayMode === 'markscheme' ? [answer1.toLocaleString()] : undefined}
            />
            <NumbersWithArrowsWithState
              id={'right'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              lhs={number2.toLocaleString()}
              rhs={number3.toLocaleString()}
              factors={[`<ans/>`, `<ans/>`]}
              leadingSymbols={[`${ADD}`, `${SUB}`]}
              arrowDirection={['right', 'left']}
              testCorrect={isEqual([number1.toString(), number1.toString()])}
              defaultState={
                displayMode === 'markscheme'
                  ? [number1.toLocaleString(), number1.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question2V2 = newQuestionContent({
  uid: 'aSN2',
  description: 'aSN',
  keywords: ['Add'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(3).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);
    const number2 = randomIntegerInclusive(3, 12);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const number3 = number1 * number2;
    const number4 = number3 - number1;

    const random = seededRandom(props.question);

    const answerOptions = shuffle(
      [
        { value: 'A', component: translate.operations.moreThan() },
        { value: 'B', component: translate.operations.timesTheSizeOf() }
      ],
      { random }
    );

    const sentences = shuffle(
      [
        {
          sentence: translate.answerSentences.xIsYAnsZ(number3, number4, number1),
          answer: 'A'
        },
        {
          sentence: translate.answerSentences.xIsYAnsZ(number3, number2, number1),
          answer: 'B'
        }
      ],
      { random }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentences()}
        actionPanelVariant="endWide"
        pdfItemVariant="rectangle"
        itemVariant="rectangle"
        pdfSentencesStyle={{ justifyContent: 'center', rowGap: 16 }}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aSO',
  description: 'aSO',
  keywords: ['Multiply'],
  questionHeight: 800,
  schema: z.object({
    number1: z.number().int().min(2).max(6),
    number2: z.number().int().min(1).max(12),
    number4: z.number().int().min(1).max(12),
    answerPosition: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);
    const [number2, number4] = randomUniqueIntegersInclusive(1, 12, 2);
    const answerPosition = getRandomFromArray(['left', 'right'] as const);

    return { number1, number2, number4, answerPosition };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, answerPosition },
      translate,
      displayMode
    } = props;

    const number3 = number1 * number2;
    const number5 = number1 * number4;
    const lhs1 = answerPosition === 'left' ? '<ans />' : number4.toLocaleString();
    const rhs1 = answerPosition === 'left' ? number5.toLocaleString() : '<ans />';
    const answer1 = answerPosition === 'left' ? number4 : number5;

    return (
      <QF3Content
        title={translate.instructions.fillInMissingNumbers()}
        inputType="numpad"
        questionHeight={800}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            <NumbersWithArrowsWithState
              id={'left'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              lhs={lhs1}
              rhs={rhs1}
              factors={[`${MULT} ${number1}`, `${DIV} ${number1}`]}
              arrowDirection={['right', 'left']}
              testCorrect={isEqual([answer1.toString()])}
              defaultState={displayMode === 'markscheme' ? [answer1.toLocaleString()] : undefined}
            />
            <NumbersWithArrowsWithState
              id={'right'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              lhs={number2.toLocaleString()}
              rhs={number3.toLocaleString()}
              factors={[`<ans/>`, `<ans/>`]}
              leadingSymbols={[`${MULT}`, `${DIV}`]}
              arrowDirection={['right', 'left']}
              testCorrect={isEqual([number1.toString(), number1.toString()])}
              defaultState={
                displayMode === 'markscheme'
                  ? [number1.toLocaleString(), number1.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question3V2 = newQuestionContent({
  uid: 'aSO2',
  description: 'aSO',
  keywords: ['Multiply'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(3).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);
    const number2 = randomIntegerInclusive(3, 12);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 * number2;
    const number4 = number3 - number2;

    const words =
      number1 === 2
        ? translate.fractions.halves(1)
        : number1 === 3
        ? translate.fractions.aThird()
        : number1 === 4
        ? translate.fractions.aQuarter()
        : translate.fractions.aFifth();

    const random = seededRandom(props.question);

    const answerOptions = shuffle(
      [
        { value: 'A', component: translate.operations.lessThan() },
        { value: 'B', component: translate.misc.of() }
      ],
      { random }
    );

    const sentences = shuffle(
      [
        {
          sentence: translate.answerSentences.xIsYAnsZ(number2, number4, number3),
          answer: 'A'
        },
        {
          sentence: translate.answerSentences.xIsYAnsZ(number2, words, number3),
          answer: 'B'
        }
      ],
      { random }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentences()}
        actionPanelVariant="endWide"
        pdfItemVariant="rectangle"
        itemVariant="rectangle"
        pdfSentencesStyle={{ justifyContent: 'center', rowGap: 16 }}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aSP',
  description: 'aSP',
  keywords: ['Add', 'Multiply', 'Subtract', 'Divide'],
  schema: z.object({
    number1: z.number().int().min(3).max(6),
    number2: z.number().int().min(3).max(12),
    answerPosition: z.enum(['top', 'bottom'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 6);
    const number2 = randomIntegerInclusive(3, 12);
    const answerPosition = getRandomFromArray(['top', 'bottom'] as const);

    return { number1, number2, answerPosition };
  },
  Component: props => {
    const {
      question: { number1, number2, answerPosition },
      translate,
      displayMode
    } = props;

    const number3 = number1 + number2;
    const number4 = number1 * number2;
    const factorTop1 = answerPosition === 'top' ? '<ans />' : `${ADD} ${number1.toLocaleString()}`;
    const factorBottom1 =
      answerPosition === 'top' ? `${SUB} ${number1.toLocaleString()}` : '<ans />';
    const factorTop2 = answerPosition === 'top' ? '<ans />' : `${MULT} ${number1.toLocaleString()}`;
    const factorBottom2 =
      answerPosition === 'top' ? `${DIV} ${number1.toLocaleString()}` : '<ans />';
    const leadingSymbols1 = answerPosition === 'top' ? [`${ADD}`, ''] : ['', `${SUB}`];
    const leadingSymbols2 = answerPosition === 'top' ? [`${MULT}`, ''] : ['', `${DIV}`];

    return (
      <QF3Content
        title={translate.instructions.fillInMissingNumbers()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            <NumbersWithArrowsWithState
              id={'left'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              lhs={`<ans/>`}
              rhs={number3.toLocaleString()}
              factors={[factorTop1, factorBottom1]}
              arrowDirection={['right', 'left']}
              leadingSymbols={leadingSymbols1}
              testCorrect={isEqual([number2.toString(), number1.toString()])}
              defaultState={
                displayMode === 'markscheme'
                  ? [number2.toLocaleString(), number1.toLocaleString()]
                  : undefined
              }
            />
            <NumbersWithArrowsWithState
              id={'right'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              lhs={`<ans/>`}
              rhs={number4.toLocaleString()}
              factors={[factorTop2, factorBottom2]}
              arrowDirection={['right', 'left']}
              leadingSymbols={leadingSymbols2}
              testCorrect={isEqual([number2.toString(), number1.toString()])}
              defaultState={
                displayMode === 'markscheme'
                  ? [number2.toLocaleString(), number1.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aSQ',
  description: 'aSQ',
  keywords: ['Sequence', 'Add', 'Multiply'],
  schema: z.object({
    number1: z.number().int().min(1).max(6),
    number2: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 6);
    const number2 = randomIntegerInclusive(2, 5);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const number3 = number(math.evaluate(`${number1} * ${number2}`));
    const answer1 = number(math.evaluate(`${number3} * ${number2}`));
    const answer2 = number(math.evaluate(`${number3} + ((${number2} -1) * ${number1})`));

    return (
      <QF3Content
        title={translate.instructions.whatCouldNextNumberBe()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ gap: 20 }}>
            <SequenceBoxesWithState
              id="seq1"
              boxes={[
                [number1.toLocaleString(), number3.toLocaleString(), `<ans/>`],
                [number1.toLocaleString(), number3.toLocaleString(), `<ans/>`]
              ]}
              dimens={dimens}
              testCorrect={userAnswer =>
                (arraysHaveSameContentsUnordered(userAnswer[0], [answer1.toString()]) ||
                  arraysHaveSameContentsUnordered(userAnswer[0], [answer2.toString()]) ||
                  arraysHaveSameContentsUnordered(userAnswer[1], [answer1.toString()]) ||
                  arraysHaveSameContentsUnordered(userAnswer[1], [answer2.toString()])) &&
                !arraysHaveSameContentsUnordered(userAnswer[0], userAnswer[1])
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [[answer1.toLocaleString()], [answer2.toLocaleString()]]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aSR',
  description: 'aSR',
  keywords: ['Sequence', 'Subtract', 'Divide'],
  schema: z.object({
    number1: z.number().int().min(8).max(48).step(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(8, 48, 4);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    const number2 = number(math.evaluate(`${number1} / 2`));
    const answer1 = number(math.evaluate(`${number2} / 2`));
    const answer2 = 0;

    return (
      <QF3Content
        title={translate.instructions.whatCouldNextNumberBe()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ gap: 20 }}>
            <SequenceBoxesWithState
              id="seq1"
              boxes={[
                [number1.toLocaleString(), number2.toLocaleString(), `<ans/>`],
                [number1.toLocaleString(), number2.toLocaleString(), `<ans/>`]
              ]}
              dimens={dimens}
              testCorrect={userAnswer =>
                (arraysHaveSameContentsUnordered(userAnswer[0], [answer1.toString()]) ||
                  arraysHaveSameContentsUnordered(userAnswer[0], [answer2.toString()]) ||
                  arraysHaveSameContentsUnordered(userAnswer[1], [answer1.toString()]) ||
                  arraysHaveSameContentsUnordered(userAnswer[1], [answer2.toString()])) &&
                !arraysHaveSameContentsUnordered(userAnswer[0], userAnswer[1])
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [[answer1.toLocaleString()], [answer2.toLocaleString()]]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddOrMultiply',
  questionTypes: [Question1, Question2V2, Question3V2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
