import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import { Polygon } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { LabelSplitParallelogram } from '../../../../components/question/representations/LabelSplitParallelogram';
import { ALGEBRAIC_X } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aWW',
  description: 'aWW',
  keywords: ['Area', 'Parallelogram'],
  questionHeight: 1200,
  schema: z
    .object({
      width: z.number().int().min(3).max(9),
      height: z.number().int().min(2).max(4),
      overhang: z.number().int().min(1).max(4),
      isRight: z.boolean(),
      incorrectOption: z
        .number()
        .int()
        .min(-2)
        .max(2)
        .refine(val => val !== 0, 'not equal to zero')
    })
    .refine(
      val => val.overhang < val.width && val.overhang + val.width <= 10,
      'overhang must be less than width and the sum must be less than or equal to 10'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const width = randomIntegerInclusive(3, 9);
        const height = randomIntegerInclusive(2, 4);
        const overhang = randomIntegerInclusive(1, width - 1, { constraint: x => x + width <= 10 });
        const isRight = getRandomBoolean();
        const incorrectOption = randomIntegerInclusive(-2, 2, {
          constraint: x => x !== 0 && x !== -overhang
        });

        return {
          width,
          height,
          overhang,
          isRight,
          incorrectOption
        };
      },
      val =>
        arrayHasNoDuplicates([
          val.height * val.width,
          val.height * val.width - val.overhang,
          (val.height + val.width) * 2,
          val.height * val.width + val.incorrectOption
        ])
    ),
  Component: props => {
    const {
      question: { width, height, overhang, isRight, incorrectOption },
      translate
    } = props;

    const items = shuffle(
      [
        (height * width).toLocaleString(),
        (height * width - overhang).toLocaleString(),
        ((height + width) * 2).toLocaleString(),
        (height * width + incorrectOption).toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    const maxWidth = width + overhang;

    const startingXPosition = isRight
      ? Math.floor((10 - maxWidth) / 2)
      : Math.floor((10 - maxWidth) / 2) + overhang;

    const startingYPosition = Math.floor((5 - height) / 2);

    const rectanglePoints = [
      [startingXPosition, startingYPosition],
      [startingXPosition, startingYPosition + height],
      [startingXPosition + width, startingYPosition + height],
      [startingXPosition + width, startingYPosition]
    ];
    const parallelogramPoints = [
      [startingXPosition, startingYPosition],
      [
        isRight ? startingXPosition + overhang : startingXPosition - overhang,
        startingYPosition + height
      ],
      [
        isRight ? startingXPosition + width + overhang : startingXPosition + width - overhang,
        startingYPosition + height
      ],
      [startingXPosition + width, startingYPosition]
    ];

    return (
      <QF36ContentAndSentencesDrag
        title={translate.instructions.dragCardsToShowAreaOfRectangleAndParallelogram()}
        pdfTitle={translate.instructions.useCardsToShowAreaOfRectangleAndParallelogram()}
        items={items}
        sentencesStyle={{ flexDirection: 'row' }}
        moveOrCopy="copy"
        actionPanelVariant="end"
        questionHeight={1200}
        Content={({ dimens }) => (
          <Grid
            width={dimens.width}
            height={dimens.height}
            xMax={10}
            yMax={5}
            yAxis={null}
            xAxis={null}
            hideContinuationLines
            squareGrid
            cellSizeLabel={translate.units.numberOfCm(1)}
            children={({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                <Polygon
                  points={rectanglePoints
                    .map(([x, y]) => `${mathToSvgX(x)},${mathToSvgY(y)}`)
                    .join(' ')}
                  fill={`${colors.pacificBlue}30`}
                />
                <Polygon
                  points={parallelogramPoints
                    .map(([x, y]) => `${mathToSvgX(x)},${mathToSvgY(y)}`)
                    .join(' ')}
                  fill="none"
                  stroke={colors.prussianBlue}
                  strokeDasharray={[15, 15]}
                  strokeWidth={4}
                />
              </GridSvgChildren>
            )}
          />
        )}
        sentences={[
          `${translate.shapes.rectangles(1)} = ${translate.answerSentences.ansCmSquared()}`,
          `${translate.shapes.parallelograms(1)} = ${translate.answerSentences.ansCmSquared()}`
        ]}
        testCorrect={[[(height * width).toLocaleString()], [(height * width).toLocaleString()]]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aWX',
  description: 'aWX',
  keywords: ['Area', 'Parallelogram'],
  schema: z
    .object({
      width: z.number().int().min(3).max(7),
      height: z.number().int().min(2).max(3),
      overhang: z.number().int().min(1).max(4),
      isRight: z.boolean(),
      isHeight: z.boolean()
    })
    .refine(
      val => (val.isHeight ? 2 * val.overhang + val.height <= 5 : val.overhang + val.width <= 8),
      'when overhang is vertical, overhang + height must be <=5 and if overhang horizontal overhang + width must be <=8'
    ),
  simpleGenerator: () => {
    const isHeight = getRandomBoolean();
    const width = randomIntegerInclusive(3, 7);
    const height = randomIntegerInclusive(2, 3);
    const overhang = isHeight
      ? 1
      : randomIntegerInclusive(1, 4, { constraint: x => x + width <= 8 });
    const isRight = getRandomBoolean();

    return {
      width,
      height,
      overhang,
      isRight,
      isHeight
    };
  },
  Component: props => {
    const {
      question: { width, height, overhang, isRight, isHeight },
      translate
    } = props;

    const maxWidth = isHeight ? width : width + overhang;

    const startingXPosition =
      isRight || isHeight
        ? Math.floor((8 - maxWidth) / 2)
        : Math.floor((8 - maxWidth) / 2) + overhang;

    const maxHeight = isHeight ? height + overhang : height;

    const startingYPosition =
      (isHeight && isRight) || !isHeight
        ? Math.ceil((4 - maxHeight) / 2)
        : Math.ceil((4 - maxHeight) / 2) + overhang;

    const parallelogramPoints: number[][] = [];
    if (isHeight) {
      if (isRight) {
        parallelogramPoints.push(
          [startingXPosition, startingYPosition],
          [startingXPosition, startingYPosition + height],
          [startingXPosition + width, startingYPosition + overhang + height],
          [startingXPosition + width, startingYPosition + overhang]
        );
      } else {
        parallelogramPoints.push(
          [startingXPosition, startingYPosition],
          [startingXPosition, startingYPosition + height],
          [startingXPosition + width, startingYPosition - overhang + height],
          [startingXPosition + width, startingYPosition - overhang]
        );
      }
    } else {
      if (isRight) {
        parallelogramPoints.push(
          [startingXPosition, startingYPosition],
          [startingXPosition + overhang, startingYPosition + height],
          [startingXPosition + width + overhang, startingYPosition + height],
          [startingXPosition + width, startingYPosition]
        );
      } else {
        parallelogramPoints.push(
          [startingXPosition, startingYPosition],
          [startingXPosition - overhang, startingYPosition + height],
          [startingXPosition + width - overhang, startingYPosition + height],
          [startingXPosition + width, startingYPosition]
        );
      }
    }

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheAreaOfTheParallelogram()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <Grid
            width={dimens.width}
            height={dimens.height}
            xMax={8}
            yMax={5}
            yAxis={null}
            xAxis={null}
            hideContinuationLines
            squareGrid
            cellSizeLabel={translate.units.numberOfCm(1)}
            children={({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                <Polygon
                  points={parallelogramPoints
                    .map(([x, y]) => `${mathToSvgX(x)},${mathToSvgY(y)}`)
                    .join(' ')}
                  fill={`${colors.pacificBlue}30`}
                  stroke={colors.prussianBlue}
                  strokeWidth={2}
                />
              </GridSvgChildren>
            )}
          />
        )}
        sentence={translate.answerSentences.ansCmSquared()}
        testCorrect={[(height * width).toString()]}
      />
    );
  }
});

/** Archived */
const Question3 = newQuestionContent({
  uid: 'aWY',
  description: 'aWY',
  keywords: ['Area', 'Parallelogram'],
  schema: z
    .object({
      shapeSvg: z.enum([
        'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
        'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
        'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
        'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle',
        'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
      ]),
      isCorrect: z.boolean(),
      isVertical: z.boolean(),
      horizontal: z.number().int().min(4).max(12),
      vertical: z.number().int().min(2).max(10),
      slope: z.number().int().min(2)
    })
    .refine(
      val => (val.isVertical ? val.slope > val.vertical : val.slope > val.horizontal),
      'if the slope is vertical then the length of the slope should be greater than the vertical length, likewise if the slope is horizontal'
    ),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();

    const shapeSvg = getRandomFromArray(
      isVertical
        ? ([
            'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
          ] as const)
        : (['Shapes_with_arrows/Parallelogram7_2_arrows_right_angle'] as const)
    );

    const isCorrect = getRandomBoolean();
    const { horizontal, vertical, slope } = rejectionSample(
      () => {
        const horizontal = randomIntegerInclusive(4, 12);
        const vertical = randomIntegerInclusive(2, horizontal - 2);
        const extension = isVertical
          ? randomIntegerInclusive(2, horizontal)
          : randomIntegerInclusive(2, vertical);
        const slope = isVertical
          ? Math.round(Math.sqrt(Math.pow(vertical, 2) + Math.pow(extension, 2)))
          : Math.round(Math.sqrt(Math.pow(horizontal, 2) + Math.pow(extension, 2)));
        return { horizontal, vertical, extension, slope };
      },
      val => (isVertical ? val.slope > val.vertical : val.slope > val.horizontal)
    );

    return { shapeSvg, isCorrect, isVertical, horizontal, vertical, slope };
  },
  Component: ({ question: { shapeSvg, isCorrect, horizontal, vertical, slope }, translate }) => {
    const var2 = isCorrect ? vertical : slope;
    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isTheAreaOfTheShapeXMultYSelectTrueOrFalse(
          horizontal,
          var2,
          horizontal * var2
        )}
        pdfTitle={translate.instructions.isTheAreaOfTheShapeXMultYCircleTrueOrFalse(
          horizontal,
          var2,
          horizontal * var2
        )}
        correctAnswer={isCorrect}
        content={({ dimens }) => (
          <LabelSplitParallelogram
            dimens={dimens}
            shapeSvg={shapeSvg}
            labels={[
              translate.units.numberOfCm(vertical),
              translate.units.numberOfCm(horizontal),
              translate.units.numberOfCm(slope)
            ]}
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3v2 = newQuestionContent({
  uid: 'aWY2',
  description: 'aWY',
  keywords: ['Area', 'Parallelogram'],
  schema: z
    .object({
      shapeSvg: z.enum([
        'Shapes_with_arrows/Parallelogram1_3_arrows_right_angle_filled',
        'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle_filled',
        'Shapes_with_arrows/Parallelogram5_3_arrows_right_angle_filled',
        'Shapes_with_arrows/Parallelogram7_3_arrows_right_angle_filled',
        'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled'
      ]),
      isCorrect: z.boolean(),
      isVertical: z.boolean(),
      horizontal: z.number().int().min(2).max(12),
      vertical: z.number().int().min(2).max(12),
      slope: z.number().int().min(2)
    })
    .refine(
      val => (val.isVertical ? val.slope > val.vertical : val.slope > val.horizontal),
      'if the slope is vertical then the length of the slope should be greater than the vertical length, likewise if the slope is horizontal'
    ),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();

    const shapeSvg = getRandomFromArray(
      isVertical
        ? ([
            'Shapes_with_arrows/Parallelogram1_3_arrows_right_angle_filled',
            'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle_filled',
            'Shapes_with_arrows/Parallelogram5_3_arrows_right_angle_filled',
            'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled'
          ] as const)
        : (['Shapes_with_arrows/Parallelogram7_3_arrows_right_angle_filled'] as const)
    );

    const isHorizontalLargest =
      shapeSvg === 'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled' ||
      shapeSvg === 'Shapes_with_arrows/Parallelogram7_3_arrows_right_angle_filled';

    const differentLengths =
      shapeSvg === 'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle_filled';

    const isCorrect = getRandomBoolean();
    const { horizontal, vertical, slope } = rejectionSample(
      () => {
        const largest = randomIntegerInclusive(4, 12);
        const smaller = randomIntegerInclusive(
          differentLengths ? 2 : Math.max(largest - 3, 2),
          largest - 1
        );
        const horizontal = isHorizontalLargest ? largest : smaller;
        const vertical = isHorizontalLargest ? smaller : largest;
        const extension = isVertical
          ? randomIntegerInclusive(2, horizontal)
          : randomIntegerInclusive(2, vertical);
        const slope = isVertical
          ? vertical + randomIntegerInclusive(1, 2)
          : Math.round(Math.sqrt(Math.pow(horizontal, 2) + Math.pow(extension, 2)));
        return { horizontal, vertical, extension, slope };
      },
      val => (isVertical ? val.slope > val.vertical : val.slope > val.horizontal)
    );

    return { shapeSvg, isCorrect, isVertical, horizontal, vertical, slope };
  },
  Component: ({ question: { shapeSvg, isCorrect, horizontal, vertical, slope }, translate }) => {
    const var2 = isCorrect ? vertical : slope;
    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.selectIfStatementIsTrueOrFalseAreaOfParallelogramIsXMultY(
          horizontal,
          var2,
          horizontal * var2
        )}
        pdfTitle={translate.instructions.circleIfStatementIsTrueOrFalseAreaOfParallelogramIsXMultY(
          horizontal,
          var2,
          horizontal * var2
        )}
        correctAnswer={isCorrect}
        content={({ dimens }) => (
          <LabelSplitParallelogram
            dimens={
              shapeSvg === 'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled'
                ? { height: dimens.height, width: dimens.width * 0.4 }
                : dimens
            }
            shapeSvg={shapeSvg}
            labels={[
              translate.units.numberOfCm(vertical),
              translate.units.numberOfCm(horizontal),
              translate.units.numberOfCm(slope)
            ]}
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aWZ',
  description: 'aWZ',
  keywords: ['Area', 'Parallelogram'],
  schema: z.object({
    shapeSvg: z.enum([
      'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
    ]),
    horizontal: z.number().int().min(4).max(12),
    vertical: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();

    const shapeSvg = getRandomFromArray(
      isVertical
        ? ([
            'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
          ] as const)
        : (['Shapes_with_arrows/Parallelogram7_2_arrows_right_angle'] as const)
    );

    const { horizontal, vertical } = rejectionSample(
      () => {
        const horizontal = randomIntegerInclusive(4, 12);
        const vertical = randomIntegerInclusive(2, horizontal - 2);
        const extension = isVertical
          ? randomIntegerInclusive(2, horizontal)
          : randomIntegerInclusive(2, vertical);
        const slope = isVertical
          ? Math.round(Math.sqrt(Math.pow(vertical, 2) + Math.pow(extension, 2)))
          : Math.round(Math.sqrt(Math.pow(horizontal, 2) + Math.pow(extension, 2)));
        return { horizontal, vertical, extension, slope };
      },
      val => (isVertical ? val.slope > val.vertical : val.slope > val.horizontal)
    );

    return { shapeSvg, horizontal, vertical };
  },
  Component: ({ question: { shapeSvg, horizontal, vertical }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.calculateAreaOfShape(translate.shapes.parallelograms(1))}
        testCorrect={[(vertical * horizontal).toString()]}
        Content={({ dimens }) => (
          <LabelSplitParallelogram
            dimens={dimens}
            shapeSvg={shapeSvg}
            labels={[translate.units.numberOfCm(vertical), translate.units.numberOfCm(horizontal)]}
          />
        )}
        sentence={translate.answerSentences.ansCmSquared()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aW0',
  description: 'aW0',
  keywords: ['Area', 'Parallelogram'],
  schema: z
    .object({
      shapeSvg: z.enum([
        'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
        'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
        'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
        'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle',
        'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
      ]),
      isVertical: z.boolean(),
      horizontal: z.number().int().min(4).max(12),
      vertical: z.number().int().min(2).max(10),
      slope: z.number().int().min(2)
    })
    .refine(
      val => (val.isVertical ? val.slope > val.vertical : val.slope > val.horizontal),
      'if the slope is vertical then the length of the slope should be greater than the vertical length, likewise if the slope is horizontal'
    ),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();

    const shapeSvg = getRandomFromArray(
      isVertical
        ? ([
            'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
          ] as const)
        : (['Shapes_with_arrows/Parallelogram7_2_arrows_right_angle'] as const)
    );

    const { horizontal, vertical, slope } = rejectionSample(
      () => {
        const horizontal = randomIntegerInclusive(4, 12);
        const vertical = randomIntegerInclusive(2, horizontal - 2);
        const extension = isVertical
          ? randomIntegerInclusive(2, horizontal)
          : randomIntegerInclusive(2, vertical);
        const slope = isVertical
          ? Math.round(Math.sqrt(Math.pow(vertical, 2) + Math.pow(extension, 2)))
          : Math.round(Math.sqrt(Math.pow(horizontal, 2) + Math.pow(extension, 2)));
        return { horizontal, vertical, extension, slope };
      },
      val => (isVertical ? val.slope > val.vertical : val.slope > val.horizontal)
    );

    return { shapeSvg, isVertical, horizontal, vertical, slope };
  },
  Component: ({ question: { shapeSvg, horizontal, vertical, slope }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.calculateAreaOfShape(translate.shapes.parallelograms(1))}
        testCorrect={[(vertical * horizontal).toString()]}
        Content={({ dimens }) => (
          <LabelSplitParallelogram
            dimens={dimens}
            shapeSvg={shapeSvg}
            labels={[
              translate.units.numberOfCm(vertical),
              translate.units.numberOfCm(horizontal),
              translate.units.numberOfCm(slope)
            ]}
          />
        )}
        sentence={translate.answerSentences.ansCmSquared()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aW1',
  description: 'aW1',
  keywords: ['Area', 'Parallelogram'],
  schema: z.object({
    shapeSvg: z.enum([
      'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
    ]),
    horizontal: z.number().int().min(4).max(12),
    vertical: z.number().int().min(2).max(10),
    ansIndex: z.number().int().min(0).max(1)
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();

    const shapeSvg = getRandomFromArray(
      isVertical
        ? ([
            'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
          ] as const)
        : (['Shapes_with_arrows/Parallelogram7_2_arrows_right_angle'] as const)
    );

    const { horizontal, vertical } = rejectionSample(
      () => {
        const horizontal = randomIntegerInclusive(4, 12);
        const vertical = randomIntegerInclusive(2, horizontal - 2);
        const extension = isVertical
          ? randomIntegerInclusive(2, horizontal)
          : randomIntegerInclusive(2, vertical);
        const slope = isVertical
          ? Math.round(Math.sqrt(Math.pow(vertical, 2) + Math.pow(extension, 2)))
          : Math.round(Math.sqrt(Math.pow(horizontal, 2) + Math.pow(extension, 2)));
        return { horizontal, vertical, extension, slope };
      },
      val => (isVertical ? val.slope > val.vertical : val.slope > val.horizontal)
    );

    const ansIndex = randomIntegerInclusive(0, 1);

    return { shapeSvg, isVertical, horizontal, vertical, ansIndex };
  },
  Component: ({ question: { shapeSvg, horizontal, vertical, ansIndex }, translate }) => {
    const labels: string[] = [
      translate.units.numberOfCm(vertical),
      translate.units.numberOfCm(horizontal)
    ];
    labels[ansIndex] = ALGEBRAIC_X;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theAreaOfShapeIsXcmSquaredWorkOutX(
          translate.shapes.parallelograms(1),
          vertical * horizontal
        )}
        testCorrect={[[vertical.toString()], [horizontal.toString()]][ansIndex]}
        Content={({ dimens }) => (
          <LabelSplitParallelogram dimens={dimens} shapeSvg={shapeSvg} labels={labels} />
        )}
        sentence={`${ALGEBRAIC_X} = ${translate.answerSentences.ansCm()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aW12',
  description: 'aW1',
  keywords: ['Area', 'Parallelogram'],
  schema: z.object({
    shapeSvg: z.enum([
      'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle',
      'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
    ]),
    horizontal: z.number().int().min(2).max(12),
    vertical: z.number().int().min(2).max(22),
    ansIndex: z.number().int().min(0).max(1)
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();

    const shapeSvg = getRandomFromArray(
      isVertical
        ? ([
            'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle',
            'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
          ] as const)
        : (['Shapes_with_arrows/Parallelogram7_2_arrows_right_angle'] as const)
    );

    const isHorizontalLargest =
      shapeSvg === 'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle' ||
      shapeSvg === 'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle';

    const differentLengths = shapeSvg === 'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle';

    const { horizontal, vertical } = rejectionSample(
      () => {
        const largest = randomIntegerInclusive(4, 12);

        const smaller = randomIntegerInclusive(
          differentLengths ? 2 : Math.max(largest - 3, 2),
          largest - 1
        );

        const horizontal = isHorizontalLargest ? largest : smaller;

        const vertical = isHorizontalLargest
          ? smaller
          : shapeSvg === 'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle'
          ? smaller * 2
          : largest;

        const extension = isVertical
          ? randomIntegerInclusive(2, horizontal)
          : randomIntegerInclusive(2, vertical);
        const slope = isVertical
          ? vertical + randomIntegerInclusive(1, 2)
          : Math.round(Math.sqrt(Math.pow(horizontal, 2) + Math.pow(extension, 2)));
        return { horizontal, vertical, extension, slope };
      },
      val => (isVertical ? val.slope > val.vertical : val.slope > val.horizontal)
    );

    const ansIndex = randomIntegerInclusive(0, 1);

    return { shapeSvg, isVertical, horizontal, vertical, ansIndex };
  },
  Component: ({ question: { shapeSvg, horizontal, vertical, ansIndex }, translate }) => {
    const labels: string[] = [
      translate.units.numberOfCm(vertical),
      translate.units.numberOfCm(horizontal)
    ];
    labels[ansIndex] = ALGEBRAIC_X;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theAreaOfShapeIsXcmSquaredWorkOutX(
          translate.shapes.parallelograms(1),
          vertical * horizontal
        )}
        testCorrect={[[vertical.toString()], [horizontal.toString()]][ansIndex]}
        Content={({ dimens }) => (
          <LabelSplitParallelogram dimens={dimens} shapeSvg={shapeSvg} labels={labels} />
        )}
        sentence={`${ALGEBRAIC_X} = ${translate.answerSentences.ansCm()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AreaOfAParallelogram',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question3, Question6]
});
export default SmallStep;
