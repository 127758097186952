import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import { z } from 'zod';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersExchangeAt, numbersOnlyExchangeAt } from '../../../../utils/exchanges';
import { range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { SUB } from '../../../../constants';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { roundToTheNearest } from '../../../../utils/math';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { numberToBase10Object } from '../../../../utils/math';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'agm',
  description: 'agm',
  keywords: ['Subtraction', '1s', 'Track'],
  schema: z
    .object({
      startingNumber: z.number().int().min(111).max(995)
    })
    .refine(
      val => isInRange(1, 5)(val.startingNumber % 10),
      'startingNumber must end in 1, 2, 3, 4 or 5.'
    )
    .refine(val => val.startingNumber % 100 >= 10, 'startingNumber must have at least 1 ten.'),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(111, 995, {
      constraint: x => isInRange(1, 5)(x % 10) && x % 100 >= 10
    });
    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = [
      startingNumber.toLocaleString(),
      (startingNumber - 1).toLocaleString(),
      (startingNumber - 2).toLocaleString(),
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>'
    ];

    const answerArray = [
      (startingNumber - 3).toString(),
      (startingNumber - 4).toString(),
      (startingNumber - 5).toString(),
      (startingNumber - 6).toString()
    ];

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        testCorrect={answerArray}
        boxValues={numberArray}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'agn',
  description: 'agn',
  keywords: ['Subtraction', '1s', 'Base 10'],
  questionHeight: 950,
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(599)
        .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
      var2: z.number().int().min(5).max(9)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.var1 - val.var2, val.var2, 'ones'),
      'numbers should exchange at ones'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(101, 599, {
          constraint: x => x % 10 !== 0 && x % 10 < 5
        });
        const var2 = randomIntegerInclusive(5, 9);
        return { var1, var2 };
      },
      // Only permit them if they exchange at ones
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1 - var2, var2, 'ones')
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSubtraction()}
        Content={({ dimens }) => {
          return (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Cubes',
                numbers: numberToBase10Object(var1),
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          );
        }}
        sentence={`${var1} ${SUB} ${var2} = <ans/>`}
        testCorrect={[(var1 - var2).toString()]}
        pdfDirection="column"
        questionHeight={950}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ago',
  description: 'ago',
  keywords: ['Subtraction', '1s', 'Number line'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(998),
      number2: z.number().int().min(2).max(9)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'ones'),
      'number1 + number2 must exchange ones'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 9);

    const number1 = randomIntegerInclusive(11, 998, {
      constraint: x => numbersOnlyExchangeAt(x, number2, 'ones')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    // Array of tick values for number line
    const tickValues = range(number3 - 9, number3 + 1);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`}
        title={translate.instructions.workOutTheSubtraction()}
        testCorrect={[number1.toString()]}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question4 = newQuestionContent({
  uid: 'agp',
  description: 'agp',
  keywords: ['Number line', 'Subtract', '1s', 'Partition'],
  schema: z.object({
    number1: z.number().int().min(9).max(989),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 9);
    const number1 = randomIntegerInclusive(9, 989, {
      constraint: x =>
        // Numbers Exchange Ones -> Tens
        numbersExchangeAt(x, number2, 'ones') &&
        // Must prevent number ticks that are 1 apart - not enough space for jumps with answer boxes that are this size:
        x % 10 !== 9 &&
        (x + number2) % 10 !== 1
    });
    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const middleTick = roundToTheNearest(number3, 10, 'down');

    const answer1 = number3 - middleTick;
    const answer2 = middleTick - number1;
    const answer3 = number1;

    const startingNumber = number1;
    const endNumber = number3;

    const tickArray =
      number3 % 10 === 0
        ? [
            {
              label: '<ans/>',
              position: startingNumber
            },
            { label: endNumber.toLocaleString(), position: endNumber }
          ]
        : [
            {
              label: '<ans/>',
              position: startingNumber
            },
            { label: middleTick.toLocaleString(), position: middleTick },
            { label: endNumber.toLocaleString(), position: endNumber }
          ];

    const jumpArrowArray =
      number3 % 10 === 0
        ? [{ start: endNumber, end: startingNumber, label: `${SUB}<ans/>` }]
        : [
            { start: endNumber, end: middleTick, label: `${SUB}<ans/>` },
            { start: middleTick, end: startingNumber, label: `${SUB}<ans/>` }
          ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.completeNumberLineToWorkOutX(
          `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()}`
        )}
        testCorrect={
          number3 % 10 === 0
            ? [answer2.toString(), answer3.toString()]
            : [answer1.toString(), answer2.toString(), answer3.toString()]
        }
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        subtraction
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'agq',
  description: 'agq',
  keywords: ['Subtraction', '1s', 'Difference'],
  schema: z
    .object({
      number1: z.number().int().min(110).max(998),
      number2: z.number().int().min(2).max(9)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'ones'),
      'number1 + number2 must only exchange at ones'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 9);
    const number1 = randomIntegerInclusive(110, 998, {
      constraint: x => numbersOnlyExchangeAt(x, number2, 'ones')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardCompleteNumberSentence()}
        pdfTitle={translate.instructions.useCardCompleteNumberSentence()}
        items={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
        sentence={`${number3.toLocaleString()} ${SUB} <ans/> = ${number1.toLocaleString()}`}
        testCorrect={[number2]}
        moveOrCopy="copy"
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question6 = newQuestionContent({
  uid: 'agr',
  description: 'agr',
  keywords: ['Subtraction', '1s'],
  schema: z
    .object({
      numberA1: z
        .number()
        .int()
        .min(101)
        .max(989)
        .refine(val => val % 100 < 90, 'numberA1 should have less than 9 tens'),
      numberA2: z.number().int().min(2).max(9),
      numberB1: z
        .number()
        .int()
        .min(101)
        .max(989)
        .refine(val => val % 100 < 90, 'numberB1 should have less than 9 tens'),
      numberB2: z.number().int().min(2).max(9)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.numberA1, val.numberA2, 'ones'),
      'numberA1 + numberA2 must exchange ones'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'ones'),
      'numberB1 + numberB2 must exchange ones'
    ),
  questionHeight: 500,
  simpleGenerator: () => {
    const [numberA2, numberB2] = randomUniqueIntegersInclusive(2, 9, 2);

    const numberA1 = randomIntegerInclusive(101, 989, {
      constraint: x => numbersOnlyExchangeAt(x, numberA2, 'ones')
    });

    const numberB1 = randomIntegerInclusive(101, 989, {
      constraint: x => numbersOnlyExchangeAt(x, numberB2, 'ones')
    });

    return {
      numberA1,
      numberB1,
      numberA2,
      numberB2
    };
  },

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      right: numberA2,
      result: numberA1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      right: numberB2,
      result: numberB1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheSubtractions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'agr2',
  description: 'agr',
  keywords: ['Subtraction', '1s'],
  schema: z
    .object({
      numberA1: z
        .number()
        .int()
        .min(111)
        .max(997)
        .refine(val => val % 100 > 9, 'numberA1 should have more than 0 tens'),
      numberA2: z.number().int().min(2).max(9),
      numberB2: z.number().int().min(2).max(9)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.numberA1, -val.numberA2, 'ones'),
      'numberA1 - numberA2 must exchange ones'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberA1, -val.numberB2, 'ones'),
      'numberA1 - numberB2 must exchange ones'
    ),
  questionHeight: 500,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(111, 997, {
      constraint: x => x % 10 <= 7 && x % 100 >= 10
    });

    const [numberA2, numberB2] = randomUniqueIntegersInclusive(2, 9, 2, {
      constraint: x => numbersOnlyExchangeAt(numberA1, -x, 'ones')
    });

    return {
      numberA1,
      numberA2,
      numberB2
    };
  },

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      right: numberA2,
      left: numberA1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      right: numberB2,
      left: numberA1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheSubtractions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Subtract1sAcrossA10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
