import { StyleSheet, StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { BarModelColorsKey, colors } from '../../../theme/colors';
import { SetState } from '../../../utils/react';
import { filledArray, range } from '../../../utils/collections';
import Text from '../../typography/Text';
import Table from '../../molecules/Table';
import TextStructure from '../../molecules/TextStructure';
import { useCallback, useContext, useMemo } from 'react';
import { withStateHOC } from '../../../stateTree';
import { noop } from '../../../utils/flowControl';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /**
   * Total number of rows required
   */
  numberOfRows: number;
  /**
   * Number of columns per row
   */
  numberOfCols: number;
  /**
   * The user answer, i.e. which cells are highlighted.
   */
  userAnswer: boolean[][];
  /**
   * Set user answer
   */
  setUserAnswer?: SetState<boolean[][]>;
  /**
   * Color
   */
  color?: BarModelColorsKey;
  /**
   * Table width
   */
  tableWidth?: number;
  /**
   * Table height
   */
  tableHeight?: number;
  /**
   * Table styles
   */
  style?: StyleProp<ViewStyle>;
  /**
   * Table cells style
   */
  tableCellStyle?: StyleProp<ViewStyle>;
  /**
   * Text to display before the bar model. Optional prop.
   */
  preBarText?: string;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /** If true renders a full width horizontal line with text above bar model */
  fullWidthTopBrace?: string | number;
  braceTextStyle?: StyleProp<TextStyle>;
  isSquareGrid?: boolean;
};

export const BarModelInteractive = ({
  numberOfRows,
  numberOfCols,
  userAnswer,
  setUserAnswer = noop,
  color,
  tableWidth = 800 * 0.9,
  tableHeight,
  tableCellStyle,
  style,
  preBarText,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle,
  fullWidthTopBrace,
  braceTextStyle,
  isSquareGrid = false
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(displayMode);

  const handleOnPress = useCallback(
    (rowIdx: number, colIdx: number) => {
      setUserAnswer(userAnswer =>
        userAnswer.map((row, rowIndex) =>
          rowIndex !== rowIdx
            ? row
            : row.map((item, columnIndex) => (columnIndex !== colIdx ? item : !item))
        )
      );
    },
    [setUserAnswer]
  );

  const maxCellHeight = tableHeight ? tableHeight / numberOfRows : undefined;
  const maxCellWidth = tableWidth ? tableWidth / numberOfCols : undefined;
  const minDimen =
    isSquareGrid &&
    maxCellHeight &&
    maxCellWidth &&
    Math.round(Math.min(maxCellHeight, maxCellWidth));

  // Now return a table
  return (
    <View style={{ flexDirection: 'row' }}>
      {preBarText && (
        <View
          style={{
            minWidth: displayMode === 'digital' ? 64 : 128,
            height: tableHeight,
            alignContent: 'center',
            justifyContent: 'center'
          }}
        >
          <TextStructure
            sentence={preBarText}
            fractionTextStyle={fractionTextStyle}
            fractionContainerStyle={fractionContainerStyle}
            fractionDividerStyle={fractionDividerStyle}
          />
        </View>
      )}
      <View style={{ flexDirection: 'column' }}>
        {/* If fullWidthTopBrace prop passed render text with horizontal line above bar model */}
        {fullWidthTopBrace && (
          <View style={[styles.wholeContainer]}>
            <View style={styles.wholeHorizontalLine}>
              <Text style={[styles.braceLabel, braceTextStyle]}>{fullWidthTopBrace}</Text>
              <View
                style={[
                  styles.wholeHorizontalVerticalLine,
                  styles.wholeHorizontalVerticalLineMiddle
                ]}
              ></View>
              <View
                style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineLeft]}
              ></View>
              <View
                style={[
                  styles.wholeHorizontalVerticalLine,
                  styles.wholeHorizontalVerticalLineRight
                ]}
              ></View>
            </View>
          </View>
        )}
        <Table
          style={[
            { width: minDimen ? minDimen * numberOfCols : tableWidth, height: tableHeight },
            style
          ]}
          rowStyle={minDimen ? { height: minDimen, width: minDimen * numberOfCols } : { flex: 1 }} // Rows share the table height
          cellStyle={
            minDimen ? { width: minDimen, height: minDimen } : [tableCellStyle, { flex: 1 }]
          } // Cells share the row width
          items={range(0, numberOfRows - 1).map(rowIndex =>
            range(0, numberOfCols - 1).map(columnIndex => {
              return (
                <TouchableOpacity
                  key={`${rowIndex}-${columnIndex}`}
                  style={{
                    flex: 1,
                    alignSelf: 'stretch',
                    backgroundColor: userAnswer[rowIndex][columnIndex]
                      ? color ?? colors.burntSienna
                      : undefined,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onPress={() => handleOnPress(rowIndex, columnIndex)}
                />
              );
            })
          )}
        />
      </View>
    </View>
  );
};

const useStyles = (displayMode: 'digital' | 'pdf' | 'markscheme') => {
  return useMemo(
    () =>
      StyleSheet.create({
        wholeContainer: {
          paddingBottom: 20
        },
        wholeHorizontalLine: {
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
          height: displayMode === 'digital' ? 2 : 4,
          position: 'relative'
        },
        wholeHorizontalVerticalLine: {
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
          height: 10,
          position: 'absolute',
          top: 0,
          width: displayMode === 'digital' ? 2 : 4
        },
        wholeHorizontalVerticalLineLeft: {
          left: 0
        },
        wholeHorizontalVerticalLineRight: {
          right: 0
        },
        wholeHorizontalVerticalLineMiddle: {
          alignSelf: 'center',
          height: 20,
          top: -20
        },
        braceLabel: {
          alignSelf: 'center',
          fontSize: displayMode === 'digital' ? 24 : 32,
          position: 'absolute',
          top: displayMode === 'digital' ? -56 : -96
        }
      }),
    [displayMode]
  );
};

/** See {@link BarModelInteractive}. */
export const BarModelInteractiveWithState = withStateHOC(BarModelInteractive, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    // Start with all cells unchecked
    defaultState: filledArray(filledArray(false, props.numberOfCols), props.numberOfRows)
  })
});

export function shadedAtLeastOneCell(answer: boolean[][]) {
  return answer.some(row => row.some(cell => cell !== false));
}
