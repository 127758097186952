import { z } from 'zod';
import { all, create, number } from 'mathjs';
import { View } from 'react-native';

import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { filledArray, range } from '../../../../utils/collections';
import { isValidMoneyAnswer, numToCurrency } from '../../../../utils/money';
import {
  isValidDecimalString,
  isWithinRange,
  lessThanGreaterThanOrEqualTo,
  numberToBase10Object
} from '../../../../utils/math';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { getRandomName, getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import { ImageWithLabel } from '../../../../components/question/representations/ImageWithLabel';
import { getCheapObjectSvgName } from '../../../../utils/objectsImages';
import { cheapObjectSchema, getRandomUniqueCheapObject } from '../../../../utils/objects';
import { AssetSvg } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'axo',
  description: 'axo',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Number line'],
  schema: z.object({
    pounds: z.number().int().min(2).max(8),
    pence: z.number().int().min(5).max(95)
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(2, 8);
    const pence = randomIntegerInclusiveStep(5, 95, 5);

    return { pounds, pence };
  },
  Component: props => {
    const {
      question: { pounds, pence },
      translate
    } = props;
    const startingNumber = pounds;
    const endNumber = pounds + 1;
    const poundsAndPence = number(math.evaluate(`${pounds} + ${pence}/100`));
    const displayName = numToCurrency({ amount: poundsAndPence });
    const tickInterval = 0.1;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = numToCurrency({ amount: startingNumber });
    numberArray[numberArray.length - 1] = numToCurrency({ amount: endNumber });

    const ansLowerBound = number(math.evaluate(`${poundsAndPence} - 0.01`));
    const ansUpperBound = number(math.evaluate(`${poundsAndPence} + 0.01`));

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.estimatePoundPositionOnNumLine(displayName)}
        pdfTitle={translate.instructions.estimatePoundPositionOnNumLinePdf(displayName)}
        testCorrect={[ansLowerBound, ansUpperBound]}
        min={startingNumber}
        max={endNumber}
        sliderStep={0.01}
        tickValues={numberArray}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'axp',
  description: 'axp',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Estimate'],
  schema: z.object({
    pounds: z.number().int().min(2).max(8),
    pence: z.array(z.number().int().min(1).max(99)).length(4)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(2, 8);
    const pence = randomUniqueIntegersInclusive(1, 99, 4, { constraint: x => x % 5 !== 0 });

    return { pounds, pence };
  },
  Component: ({ question: { pounds, pence }, translate }) => {
    const displayPounds1 = numToCurrency({
      amount: number(math.evaluate(`${pounds} + ${pence[0]}/100`))
    });
    const displayPounds2 = numToCurrency({
      amount: number(math.evaluate(`${pounds} + ${pence[1]}/100`))
    });
    const displayPounds3 = numToCurrency({
      amount: number(math.evaluate(`${pounds} + ${pence[2]}/100`))
    });
    const displayPounds4 = numToCurrency({
      amount: number(math.evaluate(`${pounds} + ${pence[3]}/100`))
    });

    const displayItems = [displayPounds1, displayPounds2, displayPounds3, displayPounds4];

    const correctAnswer = [[], []] as [down: number[], up: number[]];
    pence.forEach((pence, sumIndex) => {
      const zoneIndex = pence < 50 ? 0 : 1;
      correctAnswer[zoneIndex].push(sumIndex);
    });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsSortAmounts()}
        pdfTitle={translate.instructions.sortAmountsPDF()}
        zoneNames={[
          translate.tableHeaders.closerTo(numToCurrency({ amount: pounds, noDecimals: true })),
          translate.tableHeaders.closerTo(numToCurrency({ amount: pounds + 1, noDecimals: true }))
        ]}
        testCorrect={correctAnswer}
        items={displayItems.map((displayItems, sumIndex) => ({
          value: sumIndex,
          component: `${displayItems}`
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={4}
        questionHeight={800}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'axq',
  description: 'axq',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Number line', 'Estimate'],
  schema: z.object({
    pounds: z.number().int().min(3).max(8),
    pence: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(3, 8);
    const pence = randomIntegerInclusive(1, 99, {
      constraint: x => x % 5 !== 0
    });

    return { pounds, pence };
  },
  Component: props => {
    const {
      question: { pounds, pence },
      translate
    } = props;
    const startingNumber = pounds;
    const endNumber = pounds + 1;
    const labelInterval = 50;
    const midLabel = number(math.evaluate(`${pounds} + ${labelInterval}/100`));
    const poundsAndPence = number(math.evaluate(`${pounds} + ${pence}/100`));
    const displayName = numToCurrency({ amount: poundsAndPence });
    const tickInterval = 0.1;

    // Create array of empty strings
    const numberArray = range(startingNumber, endNumber, tickInterval);
    const tickArray = numberArray.map(number => {
      return number % midLabel ? '' : numToCurrency({ amount: number });
    });

    // Set start and end numbers
    tickArray[0] = numToCurrency({ amount: startingNumber });
    tickArray[numberArray.length - 1] = numToCurrency({ amount: endNumber });

    // Get tenths and hundreths to check if answer crosses 0.1 or 0.05 boundary
    const tenths = numberToBase10Object(poundsAndPence).tenths as number;
    const hundreths = numberToBase10Object(poundsAndPence).hundredths as number;

    const correctAnswer = (answer: number): boolean => {
      const answerTenths = numberToBase10Object(answer).tenths as number;
      const answerHundreths = numberToBase10Object(answer).hundredths as number;
      return (
        // not a multiple of 0.05 or 0.1
        answer % 0.05 !== 0 &&
        // answer should not cross a 0.1 boundary.
        answerTenths - tenths === 0 &&
        // answer should not cross 0.05 boundary,
        ((hundreths < 5 && answerHundreths < 5) || (hundreths > 5 && answerHundreths > 5)) &&
        // within 0.02
        isWithinRange(answer, poundsAndPence, 0.02)
      );
    };

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.estimatePoundPositionOnNumLine(displayName)}
        pdfTitle={translate.instructions.estimatePoundPositionOnNumLinePdf(displayName)}
        testCorrect={correctAnswer}
        min={startingNumber}
        max={endNumber}
        sliderStep={0.01}
        tickValues={tickArray}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'axr',
  description: 'axr',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Number line', 'Estimate'],
  schema: z.object({
    pounds: z.number().int().min(3).max(8),
    pence: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(3, 8);
    const pence = randomIntegerInclusive(1, 99);

    return { pounds, pence };
  },
  Component: props => {
    const {
      question: { pounds, pence },
      translate
    } = props;
    const startingNumber = pounds;
    const endNumber = pounds + 1;
    const tickInterval = 0.1;
    const poundsAndPence = number(math.evaluate(`${pounds} + ${pence}/100`));

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = numToCurrency({ amount: startingNumber });
    numberArray[numberArray.length - 1] = numToCurrency({ amount: endNumber });

    // Get tenths and hundreths to check if answer crosses 0.1 or 0.05 boundary
    const tenths = numberToBase10Object(poundsAndPence).tenths as number;
    const hundreths = numberToBase10Object(poundsAndPence).hundredths as number;

    const correctAnswer = (answer: string): boolean => {
      if (!isValidDecimalString(answer)) {
        return false;
      }

      const answerNumber = Number(answer);

      const answerTenths = numberToBase10Object(answerNumber).tenths as number;
      const answerHundreths = numberToBase10Object(answerNumber).hundredths as number;
      return (
        // answer should not cross a 0.1 boundary.
        answerTenths - tenths === 0 &&
        // answer should not cross 0.05 boundary,
        ((hundreths < 5 && answerHundreths < 5) || (hundreths > 5 && answerHundreths > 5)) &&
        // within 0.02
        isWithinRange(answerNumber, poundsAndPence, 0.02)
      );
    };

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.estimateAmountArrow()}
        testCorrect={userAnswer =>
          correctAnswer(userAnswer[0]) && isValidMoneyAnswer(userAnswer[0])
        }
        inputMaxCharacters={4}
        freeNumberLineAnswer={[poundsAndPence]}
        firstNumber={startingNumber}
        lastNumber={endNumber}
        tickValues={numberArray}
        extraSymbol="decimalPoint"
        inputLeadingSymbol="£"
        customMarkSchemeAnswer={{ answersToDisplay: [poundsAndPence.toString()] }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'axr2',
  description: 'axr',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Number line', 'Estimate'],
  schema: z.object({
    pounds: z.number().int().min(3).max(8),
    pence: z
      .number()
      .int()
      .min(1)
      .max(99)
      .refine(val => val % 10 !== 0)
  }),
  simpleGenerator: () => {
    const pounds = randomIntegerInclusive(3, 8);
    const pence = randomIntegerInclusive(1, 99, { constraint: val => val % 10 !== 0 });

    return { pounds, pence };
  },
  Component: props => {
    const {
      question: { pounds, pence },
      translate
    } = props;
    const startingNumber = pounds;
    const endNumber = pounds + 1;
    const tickInterval = 0.1;
    const poundsAndPence = number(math.evaluate(`${pounds} + ${pence}/100`));

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = numToCurrency({ amount: startingNumber });
    numberArray[numberArray.length - 1] = numToCurrency({ amount: endNumber });

    // Get tenths and hundreths to check if answer crosses 0.1 or 0.05 boundary
    const tenths = numberToBase10Object(poundsAndPence).tenths as number;
    const hundreths = numberToBase10Object(poundsAndPence).hundredths as number;

    const correctAnswer = (answer: string): boolean => {
      if (!isValidDecimalString(answer)) {
        return false;
      }

      const answerNumber = Number(answer);

      const answerTenths = numberToBase10Object(answerNumber).tenths as number;
      const answerHundreths = numberToBase10Object(answerNumber).hundredths as number;
      return (
        // answer should not cross a 0.1 boundary.
        answerTenths - tenths === 0 &&
        // answer should not cross 0.05 boundary,
        ((hundreths < 5 && answerHundreths < 5) || (hundreths > 5 && answerHundreths > 5)) &&
        // not a tick Value
        (answerNumber * 10) % 1 !== 0 &&
        // within 0.02
        isWithinRange(answerNumber, poundsAndPence, 0.02)
      );
    };

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.estimateAmountArrow()}
        testCorrect={userAnswer =>
          correctAnswer(userAnswer[0]) && isValidMoneyAnswer(userAnswer[0])
        }
        inputMaxCharacters={4}
        freeNumberLineAnswer={[poundsAndPence]}
        firstNumber={startingNumber}
        lastNumber={endNumber}
        tickValues={numberArray}
        extraSymbol="decimalPoint"
        inputLeadingSymbol="£"
        customMarkSchemeAnswer={{ answersToDisplay: [poundsAndPence.toString()] }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'axs',
  description: 'axs',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Estimate', 'Add'],
  schema: z.object({
    ballPounds: z.number().int().min(6).max(11),
    ballPence: z
      .number()
      .int()
      .min(1)
      .max(99)
      .refine(val => val !== 50, 'ballPence cannot equal 50'),
    ballAmount: z.number().int().min(3).max(4),
    conePounds: z.number().int().min(1).max(5),
    conePence: z
      .number()
      .int()
      .min(1)
      .max(99)
      .refine(val => val !== 50, 'conePence cannot equal 50'),
    coneAmount: z.number().int().min(3).max(4),
    name: nameSchema,
    sport: z.enum(['Basketball', 'Cricket', 'Football', 'Netball', 'Rugby', 'Tennis'])
  }),
  simpleGenerator: () => {
    const ballPounds = randomIntegerInclusive(6, 11);

    const ballPence = randomIntegerInclusive(1, 99, {
      constraint: x => x !== 50
    });

    const ballAmount = randomIntegerInclusive(3, 4);

    const conePounds = randomIntegerInclusive(1, 5);

    const conePence = randomIntegerInclusive(1, 99, {
      constraint: x => x !== 50
    });

    const coneAmount = randomIntegerInclusive(3, 4);

    const name = getRandomName();

    const sport = getRandomFromArray([
      'Basketball',
      'Cricket',
      'Football',
      'Netball',
      'Rugby',
      'Tennis'
    ] as const);

    return { ballPounds, ballPence, ballAmount, conePounds, conePence, coneAmount, name, sport };
  },

  Component: props => {
    const {
      question: {
        ballPounds,
        ballPence,
        ballAmount,
        conePounds,
        conePence,
        coneAmount,
        name,
        sport
      },
      translate
    } = props;
    const ballPrice = number(math.evaluate(`${ballPounds} + ${ballPence}/100`));

    const conePrice = number(math.evaluate(`${conePounds} + ${conePence}/100`));

    const ballRoundedPrice = Math.round(ballPrice);

    const coneRoundedPrice = Math.round(conePrice);

    const ballIncorrectRoundedPrice = ballPence > 0.5 ? ballPounds - 1 : ballPounds + 1;

    const coneIncorrectRoundedPrice = conePence > 0.5 ? conePounds - 1 : conePounds + 1;

    const option1 = number(
      math.evaluate(
        `(${ballRoundedPrice} * ${ballAmount}) + ((${coneRoundedPrice}) * ${coneAmount})`
      )
    );

    const option2 = number(
      math.evaluate(
        `(${ballIncorrectRoundedPrice} * ${ballAmount}) + (${coneIncorrectRoundedPrice} * ${coneAmount})`
      )
    );

    const option3 = getRandomFromArray(
      [
        number(
          math.evaluate(
            `(${ballRoundedPrice} * ${ballAmount}) + (${coneIncorrectRoundedPrice} * ${coneAmount})`
          )
        ),
        number(
          math.evaluate(
            `(${ballIncorrectRoundedPrice} * ${ballAmount}) + (${coneRoundedPrice} * ${coneAmount})`
          )
        )
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const option4 = ballRoundedPrice + coneRoundedPrice;

    const options = shuffle(
      [
        numToCurrency({ amount: option1, noDecimals: true }),
        numToCurrency({ amount: option2, noDecimals: true }),
        numToCurrency({ amount: option3, noDecimals: true }),
        numToCurrency({ amount: option4, noDecimals: true })
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const ballPriceString = numToCurrency({ amount: ballPrice });

    const conePriceString = numToCurrency({ amount: conePrice });

    const sportString = translate.sports[sport]();

    const [ballStringSingular, ballStringPlural] = (() => {
      switch (sport) {
        case 'Basketball':
          return [translate.sports.Basketball(), translate.sports.Basketballs()];
        case 'Cricket':
          return [translate.sports['Cricket ball'](), translate.sports['Cricket balls']()];
        case 'Football':
          return [translate.sports.Football(), translate.sports.Footballs()];
        case 'Netball':
          return [translate.sports.Netball(), translate.sports.Netballs()];
        case 'Rugby':
          return [translate.sports['Rugby ball'](), translate.sports['Rugby balls']()];
        case 'Tennis':
          return [translate.sports['Tennis ball'](), translate.sports['Tennis balls']()];
      }
    })();

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.aBallCostsXAndConesCostYEach(
          ballStringSingular,
          ballPriceString,
          conePriceString,
          name,
          ballAmount,
          ballStringPlural,
          coneAmount,
          sportString
        )}
        pdfTitle={translate.instructions.aBallCostsXAndConesCostYEachPDF(
          ballStringSingular,
          ballPriceString,
          conePriceString,
          name,
          ballAmount,
          ballStringPlural,
          coneAmount,
          sportString
        )}
        testCorrect={[numToCurrency({ amount: option1, noDecimals: true })]}
        renderItems={options.map(option => ({
          value: option,
          component: <Text variant="WRN700">{option}</Text>
        }))}
        numItems={4}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'axt',
  description: 'axt',
  keywords: ['Money', 'Decimals', 'Pounds', 'Pence', 'Estimate', 'Add', 'Compare'],
  schema: z.object({
    number1: z.number().int().min(5).max(7),
    number2: z.number().int().min(9).max(89),
    number3: z.number().int().min(11).max(15),
    number4: z.number().int().min(10).max(90),
    number5: z.number().int().min(5).max(7),
    number6: z.number().int().min(10).max(50),
    number7: z.number().int().min(5).max(95),
    number8: z.number().int().min(11).max(15),
    number10: z.number().int().min(3).max(6),
    number11: z.number().int().min(50).max(90),
    name1: nameSchema,
    name2: nameSchema,
    items: z.array(cheapObjectSchema).length(6)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(5, 7);
    const number2 = randomIntegerInclusive(9, 89, { constraint: x => x % 10 === 9 });
    const number3 = randomIntegerInclusive(11, 15);
    const number4 = randomIntegerInclusiveStep(10, 90, 10);
    const number5 = randomIntegerInclusive(5, 7);
    const number6 = randomIntegerInclusiveStep(10, 50, 10);
    const number7 = randomIntegerInclusiveStep(5, 95, 5);
    const number8 = randomIntegerInclusive(11, 15);
    const number10 = randomIntegerInclusive(3, 6, { constraint: x => x !== number5 });
    const number11 = randomIntegerInclusiveStep(50, 90, 10);

    const [name1, name2] = getRandomUniqueNames(2);

    const items = getRandomUniqueCheapObject(6);

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number10,
      number11,
      name1,
      name2,
      items
    };
  },

  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number10,
        number11,
        name1,
        name2,
        items
      },
      translate
    } = props;
    const number9 = 99;
    const price1 = number(math.evaluate(`${number1} + ${number2}/100`));
    const price2 = number(math.evaluate(`${number3} + ${number4}/100`));
    const price3 = number(math.evaluate(`${number5} + ${number6}/100`));
    const price4 = number(math.evaluate(`${number1} + ${number7}/100`));
    const price5 = number(math.evaluate(`${number8} + ${number9}/100`));
    const price6 = number(math.evaluate(`${number10} + ${number11}/100`));

    const lhs = number(math.evaluate(`${price1} + ${price2} + ${price3}`));
    const rhs = number(math.evaluate(`${price4} + ${price5} + ${price6}`));

    return (
      <QF6DragMatchStatements
        title={translate.instructions.estimateTotalsAndCompareAmounts(name1, name2)}
        pdfTitle={translate.instructions.estimateTotalsAndCompareAmountsPDF(name1, name2)}
        items={['>', '<', '=']}
        itemVariant="square"
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
        statements={[
          {
            correctAnswer: lessThanGreaterThanOrEqualTo(lhs, rhs),
            lhsComponent: (
              <MeasureView>
                {dimens => (
                  <View
                    style={{
                      width: dimens.width,
                      height: dimens.height,
                      alignItems: 'center'
                    }}
                  >
                    <Text variant="WRN400">{name1}</Text>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: dimens.height * 0.85,
                        padding: 10,
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <View
                        style={{
                          alignItems: 'center',
                          height: dimens.height * 0.85,
                          padding: 10,
                          justifyContent: 'space-evenly'
                        }}
                      >
                        <View style={{ height: dimens.height / 3, minWidth: dimens.width / 3 }}>
                          <ImageWithLabel
                            image={
                              <AssetSvg
                                name={getCheapObjectSvgName(items[0])}
                                height={dimens.height * 0.35}
                              />
                            }
                            value={numToCurrency({ amount: price1 })}
                            position="middle"
                            labelHeight={36}
                            labelWidth={dimens.width * 0.2}
                            imageHeight={dimens.height * 0.35}
                          />
                        </View>

                        <View style={{ height: dimens.height / 3, minWidth: dimens.width / 3 }}>
                          <ImageWithLabel
                            image={
                              <AssetSvg
                                name={getCheapObjectSvgName(items[1])}
                                height={dimens.height * 0.35}
                              />
                            }
                            value={numToCurrency({ amount: price2 })}
                            position="middle"
                            labelHeight={36}
                            labelWidth={dimens.width * 0.2}
                            imageHeight={dimens.height * 0.35}
                          />
                        </View>
                      </View>
                      <View style={{ justifyContent: 'center', minWidth: dimens.width / 3 }}>
                        <ImageWithLabel
                          image={
                            <AssetSvg
                              name={getCheapObjectSvgName(items[2])}
                              height={dimens.height * 0.35}
                            />
                          }
                          value={numToCurrency({ amount: price3 })}
                          position="middle"
                          labelHeight={36}
                          labelWidth={dimens.width * 0.2}
                          imageHeight={dimens.height * 0.35}
                        />
                      </View>
                    </View>
                  </View>
                )}
              </MeasureView>
            ),
            rhsComponent: (
              <MeasureView>
                {dimens => (
                  <View
                    style={{
                      width: dimens.width,
                      height: dimens.height,
                      alignItems: 'center'
                    }}
                  >
                    <Text variant="WRN400">{name2}</Text>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: dimens.height * 0.85,
                        padding: 10,
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <View
                        style={{
                          alignItems: 'center',
                          height: dimens.height * 0.85,
                          padding: 10,
                          justifyContent: 'space-evenly'
                        }}
                      >
                        <View style={{ height: dimens.height / 3, minWidth: dimens.width / 3 }}>
                          <ImageWithLabel
                            image={
                              <AssetSvg
                                name={getCheapObjectSvgName(items[3])}
                                height={dimens.height * 0.35}
                              />
                            }
                            value={numToCurrency({ amount: price4 })}
                            position="middle"
                            labelHeight={36}
                            labelWidth={dimens.width * 0.2}
                            imageHeight={dimens.height * 0.35}
                          />
                        </View>

                        <View style={{ height: dimens.height / 3, minWidth: dimens.width / 3 }}>
                          <ImageWithLabel
                            image={
                              <AssetSvg
                                name={getCheapObjectSvgName(items[4])}
                                height={dimens.height * 0.35}
                              />
                            }
                            value={numToCurrency({ amount: price5 })}
                            position="middle"
                            labelHeight={36}
                            labelWidth={dimens.width * 0.2}
                            imageHeight={dimens.height * 0.35}
                          />
                        </View>
                      </View>
                      <View style={{ justifyContent: 'center', minWidth: dimens.width / 3 }}>
                        <ImageWithLabel
                          image={
                            <AssetSvg
                              name={getCheapObjectSvgName(items[5])}
                              height={dimens.height * 0.35}
                            />
                          }
                          value={numToCurrency({ amount: price6 })}
                          position="middle"
                          labelHeight={36}
                          labelWidth={dimens.width * 0.2}
                          imageHeight={dimens.height * 0.35}
                        />
                      </View>
                    </View>
                  </View>
                )}
              </MeasureView>
            )
          }
        ]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateWithMoney',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
