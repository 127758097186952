import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { numbersDoNotExchange, numbersOnlyExchangeAt } from '../../../../utils/exchanges';
import ColumnOperations from '../../../../components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { SUB } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { range } from '../../../../utils/collections';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { digitAtPowerIsNumber } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ag2',
  description: 'ag2',
  keywords: ['Subtraction', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(11)
        .max(99)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(11)
        .max(99)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(val => numbersOnlyExchangeAt(val.var1, -val.var2, 'ones'), 'numbers exchange at ones')
    .refine(val => val.var1 > val.var2, 'var2 should be less than var1')
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(12, 99, {
          constraint: x => x % 10 !== 0
        });

        const var2 = randomIntegerInclusive(11, var1 - 1, {
          constraint: x => x % 10 !== 0
        });
        return { var1, var2 };
      },
      // Only permit them if they exchange at ones and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1, -var2, 'ones') && var1 - var2 >= 15
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ag3',
  description: 'ag3',
  keywords: ['Subtraction', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(val => numbersOnlyExchangeAt(val.var1, -val.var2, 'ones'), 'numbers exchange at ones')
    .refine(val => val.var1 > val.var2, 'var2 should be less than var1')
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(102, 999, {
          constraint: x => x % 10 !== 0
        });

        const var2 = randomIntegerInclusive(101, var1 - 1, {
          constraint: x => x % 10 !== 0
        });
        return { var1, var2 };
      },
      // Only permit them if they exchange at ones and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1, -var2, 'ones') && var1 - var2 >= 15
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'ag32',
  description: 'ag3',
  keywords: ['Subtraction', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(val => numbersOnlyExchangeAt(val.var1, -val.var2, 'ones'), 'numbers exchange at ones')
    .refine(val => val.var1 > val.var2, 'var2 should be less than var1')
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(102, 999, {
          constraint: x => x % 10 !== 0
        });

        const var2 = randomIntegerInclusive(101, var1 - 1, {
          constraint: x => x % 10 !== 0
        });
        return { var1, var2 };
      },
      // Only permit them if they exchange at ones and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1, -var2, 'ones') && var1 - var2 >= 15
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;

    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        showExtraLeadingAnswerBox={answerMissingDigits.length < 3}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'ag4',
  description: 'ag4',
  keywords: ['Subtract', 'Exchange'],
  schema: z
    .object({
      name: nameSchema,
      money: z.number().int().min(301).max(659),
      price: z.number().int().min(149).max(549)
    })
    .refine(val => val.money > val.price, 'money must be greater than price.'),
  simpleGenerator: () => {
    const name = getRandomName();

    const { money, price } = rejectionSample(
      () => {
        const money = randomIntegerInclusive(301, 659);

        const price = randomIntegerInclusive(149, 549, {
          constraint: x => money > x
        });
        return { money, price };
      },
      ({ money, price }) => numbersOnlyExchangeAt(money, -price, 'ones')
    );

    return { name, money, price };
  },

  Component: props => {
    const {
      question: { name, money, price },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.charHasNumPoundCharSpendsNumPoundsOnANewBike(
          name,
          money,
          price
        )}
        sentence={translate.answerSentences.poundAns()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(money - price).toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ag5',
  description: 'ag5',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(899),
      numberA2: z.number().int().min(100).max(899),
      numberB1: z.number().int().min(100).max(899),
      numberB2: z.number().int().min(100).max(899),
      numberC1: z.number().int().min(100).max(899),
      numberC2: z.number().int().min(100).max(899),
      numberD1: z.number().int().min(100).max(899),
      numberD2: z.number().int().min(100).max(899),
      cHasExchange: z.boolean(),
      dHasExchange: z.boolean()
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'ones'),
      'numberB1 and numberB2 must only have exchanges at the ones.'
    ),
  simpleGenerator: () => {
    const checkConstraint = (firstValue: number, secondNumber: number, exchange: boolean) => {
      if (exchange) {
        return numbersOnlyExchangeAt(firstValue, secondNumber, 'ones');
      } else return numbersDoNotExchange(firstValue, secondNumber);
    };

    const { numberA1, numberA2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 1,000.
        const numberA1 = randomIntegerInclusive(100, 899);
        const numberA2 = randomIntegerInclusive(100, 999 - numberA1);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => checkConstraint(numberA1, numberA2, false)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 1,000.
        const numberB1 = randomIntegerInclusive(100, 899);
        const numberB2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberB1, numberB2 };
      },
      // Only permit them if they exchange only at the ones.
      ({ numberB1, numberB2 }) => checkConstraint(numberB1, numberB2, true)
    );

    const cHasExchange = getRandomBoolean();

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        const numberC1 = randomIntegerInclusive(100, 899);
        const numberC2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberC1, numberC2 };
      },
      ({ numberC1, numberC2 }) => checkConstraint(numberC1, numberC2, cHasExchange)
    );

    const dHasExchange = getRandomBoolean();
    const { numberD1, numberD2 } = rejectionSample(
      () => {
        const numberD1 = randomIntegerInclusive(100, 899);
        const numberD2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberD1, numberD2 };
      },
      ({ numberD1, numberD2 }) => checkConstraint(numberD1, numberD2, dHasExchange)
    );
    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        cHasExchange,
        numberC1,
        numberC2,
        dHasExchange,
        numberD1,
        numberD2
      },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1 + numberA2, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1 + numberB2, bottomNumber: numberB2, isCorrect: true };
      const eqC = {
        topNumber: numberC1 + numberC2,
        bottomNumber: numberC2,
        isCorrect: cHasExchange
      };
      const eqD = {
        topNumber: numberD1 + numberD2,
        bottomNumber: numberD2,
        isCorrect: dHasExchange
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      cHasExchange,
      dHasExchange,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheSubtractionsThatNeedAnExchange()}
        pdfTitle={translate.instructions.circleTheSubtractionsThatNeedAnExchange()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={SUB}
                dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'ag6',
  description: 'ag6',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(889),
      number2: z.number().int().min(101).max(889)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'ones'),
      'number1 and number2 must exchange at the ones only.'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(101, 889, {
          constraint: x =>
            x % 10 !== 0 && // Ensure number1 does not end in 0 so number1 and number2 can exchange at the ones
            x % 100 < 90 // Ensure number1 has less than 9 tens to ensure number1 and number2 do not exchange at the tens
        });

        const number2 = randomIntegerInclusive(101, 889, {
          constraint: x => numbersOnlyExchangeAt(number1, x, 'ones')
        });
        return { number1, number2 };
      },
      ({ number1, number2 }) =>
        // Must be at least one zero, in the tens of the minuend, subtrahend or difference:
        digitAtPowerIsNumber(number1, 'tens', [0]) ||
        digitAtPowerIsNumber(number2, 'tens', [0]) ||
        digitAtPowerIsNumber(number1 + number2, 'tens', [0])
    );

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const answerMissingDigits = range(0, number1.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutXSubtractY(
          number3.toLocaleString(),
          number2.toLocaleString()
        )}
        topNumber={number3}
        bottomNumber={number2}
        operation={SUB}
        answerNumber={number1}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number1, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'ag7',
  description: 'ag7',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      answerNumber: z.number().int().min(101).max(889),
      bottomNumber: z.number().int().min(101).max(889),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => numbersOnlyExchangeAt(val.answerNumber, val.bottomNumber, 'ones'),
      'answerNumber and bottomNumber must exchange at the ones only.'
    )
    .refine(
      val => val.missingOnes !== val.missingTens && val.missingOnes !== val.missingHundreds,
      'missingOnes must be in a different number to missingTens and missingHundreds.'
    )
    .refine(
      val => val.missingTens !== val.missingHundreds,
      'missingTens must be in a different number to missingHundreds.'
    ),
  simpleGenerator: () => {
    const answerNumber = randomIntegerInclusive(101, 889, {
      constraint: x =>
        x % 10 !== 0 && // Ensure answerNumber does not end in 0 so answerNumber and bottomNumber can exchange at the ones
        x % 100 < 90 // Ensure answerNumber has less than 9 tens to ensure answerNumber and bottomNumber do not exchange at the tens
    });

    const bottomNumber = randomIntegerInclusive(101, 889, {
      constraint: x => numbersOnlyExchangeAt(answerNumber, x, 'ones')
    });

    const [missingOnes, missingTens, missingHundreds] = shuffle([
      'top',
      'bottom',
      'answer'
    ] as const);

    return { answerNumber, bottomNumber, missingOnes, missingTens, missingHundreds };
  },

  Component: props => {
    const {
      question: { answerNumber, bottomNumber, missingOnes, missingTens, missingHundreds },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds
    );
    const topNumber = answerNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={SUB}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwoNumbersAcrossA10',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
