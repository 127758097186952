import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import { filledArray, range, sortNumberArray } from '../../../../utils/collections';
import { ADD, MULT, SUB } from '../../../../constants';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import {
  algebraicSymbolSchema,
  getAlgebraicSymbol,
  getAlgebraicSymbolPair
} from '../../../../utils/algebraicSymbols';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import ContentBox from '../../../../components/molecules/ContentBox';
import { colors } from '../../../../theme/colors';
import TextStructure from '../../../../components/molecules/TextStructure';
import { MarkupAssets } from '../../../../markup';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aT2',
  description: 'aT2',
  keywords: ['Substitution', 'Expression'],
  schema: z.object({
    ones: z.number().int().min(1).max(5),
    cubes: z.number().int().min(2).max(5),
    cubeValue: z.number().int().min(2).max(6),
    cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
    onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 5);
    const cubes = randomIntegerInclusive(2, 5);
    const cubeValue = randomIntegerInclusive(2, 6);

    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);

    return { cubes, ones, cubeValue, cubeColour, onesCubeColur };
  },
  Component: props => {
    const {
      question: { cubes, ones, cubeValue, cubeColour, onesCubeColur },
      translate,
      displayMode
    } = props;

    const value = cubes * cubeValue + ones;

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const cubeWidth = displayMode === 'digital' ? 70 : 100;

    const arrayOfOnes = range(1, ones).map(i => (
      <AssetSvg name={onesSvgName} width={cubeWidth} key={`one_${i}`} />
    ));
    const arrayOfCubes = range(1, cubes - 1).map(i => (
      <AssetSvg name={cubesSvgName} width={cubeWidth} key={`cube_${i + 1}`} />
    ));

    // Array must start with a cube and the rest is shuffled
    const shuffledArray = [
      <AssetSvg key={`cube_0`} name={cubesSvgName} width={cubeWidth} />,
      ...shuffle([...arrayOfCubes, arrayOfOnes], { random: seededRandom(props.question) })
    ];

    return (
      <QF1ContentAndSentence
        title={''}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <ContentBox
              containerStyle={{
                flexDirection: 'row',
                alignSelf: 'center',
                backgroundColor: colors.white
              }}
            >
              <AssetSvg name={onesSvgName} width={cubeWidth} />
              <Text
                style={{ fontSize: displayMode === 'digital' ? 32 : 50, paddingRight: 30 }}
              >{` = ${(1).toLocaleString()}`}</Text>
              <AssetSvg name={cubesSvgName} width={cubeWidth} />
              <Text
                style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              >{` = ${cubeValue.toLocaleString()}`}</Text>
            </ContentBox>

            <View
              style={{
                alignSelf: 'flex-start'
              }}
            >
              <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50, paddingVertical: 30 }}>
                {translate.instructions.workOutTheValueOfTheSetOfCubes()}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 10
              }}
            >
              {shuffledArray}
            </View>
          </View>
        )}
        sentence="<ans/>"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[value.toString()]}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aT3',
  description: 'aT3',
  keywords: ['Substitution', 'Expression'],
  questionHeight: 1000,
  schema: z
    .object({
      number1: z.number().int().min(5).max(9),
      number2: z.number().int().min(1).max(8),
      shapeA: z.enum(['square', 'circle', 'triangle', 'kite']),
      shapeB: z.enum(['square', 'circle', 'triangle', 'kite'])
    })
    .refine(({ shapeA, shapeB }) => shapeA !== shapeB),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(5, 9);
    const number2 = randomIntegerInclusive(1, number1 - 1);

    const [shapeA, shapeB] = getRandomSubArrayFromArray(
      ['square', 'circle', 'triangle', 'kite'] as const,
      2
    );
    return { number1, number2, shapeA, shapeB };
  },
  Component: props => {
    const {
      question: { number1, number2, shapeA, shapeB },
      translate,
      displayMode
    } = props;

    const isPaper = displayMode !== 'digital';

    const random = seededRandom(props.question);

    const getShapeSvg = (shape: 'kite' | 'triangle' | 'circle' | 'square'): SvgName => {
      switch (shape) {
        case 'kite':
          return isPaper
            ? 'Kite/kite_grey'
            : getRandomFromArray(
                [
                  'Kite/kite_green',
                  'Kite/kite_blue',
                  'Kite/kite_pink',
                  'Kite/kite_purple',
                  'Kite/kite_yellow'
                ] as const,
                { random }
              );
        case 'triangle':
          return isPaper ? 'triangle_grey' : 'Triangle';
        case 'circle': {
          return isPaper
            ? 'Circles/circle_grey'
            : getRandomFromArray(
                [
                  'Circles/circle_green',
                  'Circles/circle_blue',
                  'Circles/circle_pink',
                  'Circles/circle_purple',
                  'Circles/circle_yellow'
                ] as const,
                { random }
              );
        }
        case 'square': {
          return isPaper
            ? 'Square/square_grey'
            : getRandomFromArray(
                [
                  'Square/square_blue',
                  'Square/square_green',
                  'Square/square_pink',
                  'Square/square_purple',
                  'Square/square_yellow'
                ] as const,
                { random }
              );
        }
      }
    };

    const shapeASvg = getShapeSvg(shapeA);
    const shapeBSvg = getShapeSvg(shapeB);

    const answer1 = number1 + number2;
    const answer2 = number1 - number2;
    const answer3 = number1 * number2;

    return (
      <MarkupAssets
        elements={{
          shapeA: <AssetSvg key="shapeA" name={shapeASvg} height={96} />,
          shapeB: <AssetSvg key="shapeB" name={shapeBSvg} height={96} />
        }}
      >
        <QF1ContentAndSentences
          title={translate.instructions.hereValuesOfShapes()}
          questionHeight={1000}
          Content={({ dimens }) => (
            <View
              style={{
                height: dimens.height,
                width: dimens.width,
                justifyContent: 'space-between'
              }}
            >
              <ContentBox
                containerStyle={{
                  flexDirection: 'row',
                  alignSelf: 'center',
                  backgroundColor: colors.white
                }}
              >
                <AssetSvg key="shapeA" name={shapeASvg} height={96} />
                <Text
                  style={{ fontSize: 40, paddingRight: 20 }}
                >{` = ${number1.toLocaleString()} `}</Text>
                <AssetSvg key="shapeB" name={shapeBSvg} height={96} />
                <Text style={{ fontSize: 40 }}>{` = ${number2.toLocaleString()} `}</Text>
              </ContentBox>
              <Text style={{ fontSize: 32 }}>
                {translate.instructions.useGivenFactsToWorkOutCals()}
              </Text>
            </View>
          )}
          sentences={[
            `<asset name='shapeA'/>   ${ADD}   <asset name='shapeB'/>  = <ans/>`,
            `<asset name='shapeA'/>   ${SUB}   <asset name='shapeB'/>  = <ans/>`,
            `<asset name='shapeA'/>   ${MULT}   <asset name='shapeB'/>  = <ans/>`
          ]}
          pdfDirection="column"
          testCorrect={[[answer1.toString()], [answer2.toString()], [answer3.toString()]]}
          style={{ alignItems: 'center', top: 25, rowGap: 0 }}
          mainPanelStyle={{ top: -10 }}
        />
      </MarkupAssets>
    );
  }
});

const q2v2Shapes = ['circle', 'square', 'triangle', 'kite'] as const;
const q2v2Colors = [
  'blue',
  'green',
  'orange',
  'pink',
  'purple',
  'red',
  'turquoise',
  'yellow'
] as const;

const Question2v2 = newQuestionContent({
  uid: 'aT32',
  description: 'aT32',
  keywords: ['Substitution', 'Expression'],
  schema: z
    .object({
      number1: z.number().int().min(5).max(9),
      number2: z.number().int().min(1).max(8),
      shapeA: z.enum(q2v2Shapes),
      shapeB: z.enum(q2v2Shapes),
      colorA: z.enum(q2v2Colors),
      colorB: z.enum(q2v2Colors),
      operation: z.enum([ADD, SUB, MULT])
    })
    .refine(({ shapeA, shapeB }) => shapeA !== shapeB, 'shapeA and shapeB must be different.')
    .refine(({ colorA, colorB }) => colorA !== colorB, 'colorA and colorB must be different.')
    .refine(val => val.number1 > val.number2, 'number1 must be greater than number2'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(5, 9);
    const number2 = randomIntegerInclusive(1, number1 - 1);

    const [shapeA, shapeB] = getRandomSubArrayFromArray(q2v2Shapes, 2);

    const [colorA, colorB] = getRandomSubArrayFromArray(q2v2Colors, 2);

    const operation = getRandomFromArray([ADD, SUB, MULT] as const);
    return { number1, number2, shapeA, shapeB, colorA, colorB, operation };
  },
  Component: props => {
    const {
      question: { number1, number2, shapeA, shapeB, colorA, colorB, operation },
      translate,
      displayMode
    } = props;

    const shapeASvg = getShapeSvgByShapeAndColor(shapeA, colorA);
    const shapeBSvg = getShapeSvgByShapeAndColor(shapeB, colorB);

    const answer =
      operation === ADD
        ? number1 + number2
        : operation === SUB
        ? number1 - number2
        : number1 * number2;

    const shapeHeight = 96;

    const fontSize = displayMode === 'digital' ? 32 : 50;

    return (
      <MarkupAssets
        elements={{
          shapeA: <AssetSvg key="shapeA" name={shapeASvg} height={shapeHeight} />,
          shapeB: <AssetSvg key="shapeB" name={shapeBSvg} height={shapeHeight} />
        }}
      >
        <QF1ContentAndSentence
          title={''}
          Content={({ dimens }) => (
            <View style={[dimens, { justifyContent: 'space-around' }]}>
              <ContentBox
                containerStyle={{
                  flexDirection: 'row',
                  alignSelf: 'center',
                  backgroundColor: colors.white
                }}
              >
                <AssetSvg name={shapeASvg} height={shapeHeight} />
                <Text
                  style={{ fontSize: fontSize, paddingRight: 20 }}
                >{` = ${number1.toLocaleString()} `}</Text>
                <AssetSvg name={shapeBSvg} height={shapeHeight} />
                <Text style={{ fontSize: fontSize }}>{` = ${number2.toLocaleString()} `}</Text>
              </ContentBox>
              <Text style={{ fontSize: fontSize }}>
                {translate.instructions.useTheGivenFactsToWorkOutTheCalculation()}
              </Text>
            </View>
          )}
          sentence={`<asset name='shapeA'/>   ${operation}   <asset name='shapeB'/>  = <ans/>`}
          pdfDirection="column"
          testCorrect={[answer.toString()]}
        />
      </MarkupAssets>
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aT4',
  description: 'aT4',
  keywords: ['Substitution', 'Expression'],
  schema: z.object({
    symb1: algebraicSymbolSchema,
    symb2: algebraicSymbolSchema,
    var1: z.number().int().min(5).max(9),
    var2: z.number().int().min(1).max(9),
    q1a: z.number().int().min(2).max(5),
    q2a: z.number().int().min(2).max(5),
    q2b: z.number().int().min(2).max(5),
    q2Add: z.boolean(),
    q3a: z.number().int().min(2).max(5),
    q3b: z.number().int().min(2).max(5),
    q3Add: z.boolean(),
    q4a: z.number().int().min(2).max(5),
    q4Add: z.boolean()
  }),
  simpleGenerator: () => {
    const [symb1, symb2] = getAlgebraicSymbolPair();

    const var1 = randomIntegerInclusive(5, 9);
    const var2 = randomIntegerInclusive(1, var1);

    const q1a = randomIntegerInclusive(2, 5);

    const q2a = randomIntegerInclusive(2, 5);
    const q2b = randomIntegerInclusive(2, q2a);
    const q2Add = getRandomBoolean();

    const q3a = randomIntegerInclusive(2, 5);
    const q3b = randomIntegerInclusive(2, q3a);
    const q3Add = getRandomBoolean();

    const q4a = randomIntegerInclusive(2, 5);
    const q4Add = getRandomBoolean();

    return { symb1, symb2, var1, var2, q1a, q2a, q2b, q2Add, q3a, q3b, q3Add, q4a, q4Add };
  },
  Component: props => {
    const {
      question: { symb1, symb2, var1, var2, q1a, q2a, q2b, q2Add, q3a, q3b, q3Add, q4a, q4Add },
      translate
    } = props;

    const lhs = [
      `${q1a}${symb1}`,
      `${q2a}${symb2} ${q2Add ? ADD : SUB} ${q2b}`,
      `${q3a}${symb1} ${q3Add ? ADD : SUB} ${q3b}${symb2}`,
      `${q4a}(${symb1} ${q4Add ? ADD : SUB} ${symb2})`
    ];

    const rhs = filledArray(`<ans/>`, 4);

    const q1Answer = q1a * var1;
    const q2Answer = q2Add ? q2a * var2 + q2b : q2a * var2 - q2b;
    const q3Answer = q3Add ? q3a * var1 + q3b * var2 : q3a * var1 - q3b * var2;
    const q4Answer = q4Add ? q4a * (var1 + var2) : q4a * (var1 - var2);

    return (
      <QF2AlignedEquations
        title={translate.instructions.ifXEqualsYZEqualsAWorkOutValues(symb1, var1, symb2, var2)}
        leftSide={lhs}
        rightSide={rhs}
        testCorrect={{
          right: [
            [q1Answer.toString()],
            [q2Answer.toString()],
            [q3Answer.toString()],
            [q4Answer.toString()]
          ]
        }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aT42',
  description: 'aT42',
  keywords: ['Substitution', 'Expression'],
  schema: z
    .object({
      algebraicSymbol1: algebraicSymbolSchema,
      algebraicSymbol2: algebraicSymbolSchema,
      algebraicSymbol1Value: z.number().int().min(5).max(9),
      algebraicSymbol2Value: z.number().int().min(1).max(9),
      number1: z.number().int().min(2).max(5),
      number2: z.number().int().min(2).max(5),
      equation: z.enum([
        'A',
        'B addition',
        'B subtraction',
        'C addition',
        'C subtraction',
        'D addition',
        'D subraction'
      ])
    })
    .refine(
      val => val.algebraicSymbol1Value >= val.algebraicSymbol2Value,
      'algebraicSymbol1Value must be greater than or equal to algebraicSymbol2Value'
    )
    .refine(val => val.number1 >= val.number2, 'number1 must be greater than or equal to number2'),
  simpleGenerator: () => {
    const [algebraicSymbol1, algebraicSymbol2] = getAlgebraicSymbolPair();

    const algebraicSymbol1Value = randomIntegerInclusive(5, 9);

    const algebraicSymbol2Value = randomIntegerInclusive(1, algebraicSymbol1Value);

    const number1 = randomIntegerInclusive(2, 5);

    const number2 = randomIntegerInclusive(2, number1);

    const equation = getRandomFromArray([
      'A',
      'B addition',
      'B subtraction',
      'C addition',
      'C subtraction',
      'D addition',
      'D subraction'
    ] as const);

    return {
      algebraicSymbol1,
      algebraicSymbol2,
      algebraicSymbol1Value,
      algebraicSymbol2Value,
      number1,
      number2,
      equation
    };
  },
  Component: props => {
    const {
      question: {
        algebraicSymbol1,
        algebraicSymbol2,
        algebraicSymbol1Value,
        algebraicSymbol2Value,
        number1,
        number2,
        equation
      },
      translate
    } = props;

    const [sentence, answer] = (() => {
      switch (equation) {
        case 'A':
          return [
            `${number1.toLocaleString()}${algebraicSymbol1} = <ans/>`,
            (number1 * algebraicSymbol1Value).toString()
          ];
        case 'B addition':
          return [
            `${number1.toLocaleString()}${algebraicSymbol2} ${ADD} ${number2.toLocaleString()} = <ans/>`,
            (number1 * algebraicSymbol2Value + number2).toString()
          ];
        case 'B subtraction':
          return [
            `${number1.toLocaleString()}${algebraicSymbol2} ${SUB} ${number2.toLocaleString()} = <ans/>`,
            (number1 * algebraicSymbol2Value - number2).toString()
          ];
        case 'C addition':
          return [
            `${number1.toLocaleString()}${algebraicSymbol1} ${ADD} ${number2.toLocaleString()}${algebraicSymbol2} = <ans/>`,
            (number1 * algebraicSymbol1Value + number2 * algebraicSymbol2Value).toString()
          ];
        case 'C subtraction':
          return [
            `${number1.toLocaleString()}${algebraicSymbol1} ${SUB} ${number2.toLocaleString()}${algebraicSymbol2} = <ans/>`,
            (number1 * algebraicSymbol1Value - number2 * algebraicSymbol2Value).toString()
          ];
        case 'D addition':
          return [
            `${number1.toLocaleString()}(${algebraicSymbol1} ${ADD} ${algebraicSymbol2}) = <ans/>`,
            (number1 * (algebraicSymbol1Value + algebraicSymbol2Value)).toString()
          ];
        case 'D subraction':
          return [
            `${number1.toLocaleString()}(${algebraicSymbol1} ${SUB} ${algebraicSymbol2}) = <ans/>`,
            (number1 * (algebraicSymbol1Value - algebraicSymbol2Value)).toString()
          ];
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={
          equation === 'A'
            ? translate.instructions.ifXEqualsYWorkOutTheValueOfTheExpression(
                algebraicSymbol1,
                algebraicSymbol1Value
              )
            : equation === 'B addition' || equation === 'B subtraction'
            ? translate.instructions.ifXEqualsYWorkOutTheValueOfTheExpression(
                algebraicSymbol2,
                algebraicSymbol2Value
              )
            : translate.instructions.ifXEqualsYAndZEqualsAWorkOutTheValueOfTheExpression(
                algebraicSymbol1,
                algebraicSymbol1Value,
                algebraicSymbol2,
                algebraicSymbol2Value
              )
        }
        sentence={sentence}
        testCorrect={[answer]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aT5',
  description: 'aT5',
  keywords: ['Substitution', 'Compare', 'Greater than', 'Less than', 'Equal to', 'Expression'],
  schema: z.object({
    symb: algebraicSymbolSchema,
    var1: z.number().int().min(3).max(7),
    q1a: z.number().int().min(2).max(4),
    q1b: z.number().int().min(4).max(30),
    q2a: z.number().int().min(1).max(6),
    q2b: z.number().int().min(2).max(6),
    q3a: z.number().int().min(5).max(8),
    q3b: z.number().int().min(2).max(6),
    q3c: z.number().int().min(4).max(44)
  }),
  simpleGenerator: () => {
    const symb = getAlgebraicSymbol();
    const var1 = randomIntegerInclusive(3, 7);

    const q1a = randomIntegerInclusive(2, 4);
    const q1b = randomIntegerInclusive(var1 * q1a - 2, var1 * q1a + 2);

    const q2a = randomIntegerInclusive(1, var1 - 1);
    const q2b = randomIntegerInclusive(2, var1 - 1);

    const q3a = randomIntegerInclusive(5, 8);
    const q3ba = randomIntegerInclusive(2, q3a - 2);
    const q3b = q3a - q3ba;

    const q3c = randomIntegerInclusive(q3ba * var1 - 2, q3ba * var1 + 2);

    return { symb, var1, q1a, q1b, q2a, q2b, q3a, q3b, q3c };
  },
  Component: props => {
    const {
      question: { symb, var1, q1a, q1b, q2a, q2b, q3a, q3b, q3c },
      translate,
      displayMode
    } = props;

    const statements = shuffle(
      [
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'right' }}
            >{`${q1a.toLocaleString()}${symb}`}</Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'left' }}
            >
              {`${q1b.toLocaleString()}`}
            </Text>
          ),
          correctAnswer: lessThanGreaterThanOrEqualTo(q1a * var1, q1b)
        },
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'right' }}
            >{`${symb} ${SUB} ${q2a.toLocaleString()}`}</Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'left' }}
            >{`${q2b.toLocaleString()}`}</Text>
          ),
          correctAnswer: lessThanGreaterThanOrEqualTo(var1 - q2a, q2b)
        },
        {
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'right' }}
            >{`${q3a.toLocaleString()}${symb}`}</Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 200 : 300, textAlign: 'left' }}
            >{`${q3b.toLocaleString()}${symb} ${ADD} ${q3c.toLocaleString()}`}</Text>
          ),
          correctAnswer: lessThanGreaterThanOrEqualTo(q3a * var1, q3b * var1 + q3c)
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareExpressions()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareExpressions()}
        contentBox={`${symb} = ${var1.toLocaleString()}`}
        items={['<', '>', '=']}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        actionPanelVariant="end"
        moveOrCopy="copy"
        pdfLayout="itemsHidden"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aT6',
  description: 'aT6',
  keywords: ['Substitution', 'Order', 'Smallest', 'Greatest', 'Expression'],
  schema: z.object({
    symb: algebraicSymbolSchema,
    number1: z.number().int().min(2).max(4),
    number2: z.number().int().min(2).max(5)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const symb = getAlgebraicSymbol();

    const number1 = randomIntegerInclusive(2, 4);
    const number2 = randomIntegerInclusive(2, 5);

    return { symb, number1, number2 };
  },
  Component: props => {
    const {
      question: { symb, number1, number2 },
      translate,
      displayMode
    } = props;

    const varValue = number1 * number2;

    const items = shuffle(
      [
        {
          sentence: `${number2.toLocaleString()}${symb}`,
          value: number2 * varValue
        },
        {
          sentence: `${symb} ${ADD} ${number2.toLocaleString()}`,
          value: varValue + number2
        },
        {
          sentence: `<frac n="${symb}" d="${number2}"/>`,
          value: varValue / number2
        },
        {
          sentence: `${symb} ${SUB} ${number2.toLocaleString()}`,
          value: varValue - number2
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        title={`${translate.instructions.dragCardsToOrderExpressions()}<br/>${translate.instructions.startWithSmallestValue()}`}
        pdfTitle={`${translate.instructions.useCardsToCompareExpressions()}<br/>${translate.instructions.startWithSmallestValue()}`}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        items={items.map(({ sentence, value }) => {
          return {
            component: (
              <TextStructure
                textVariant="WRN700"
                fractionDividerStyle={{ marginVertical: 2 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  fontWeight: '700'
                }}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                sentence={sentence}
              />
            ),
            value
          };
        })}
        draggableVariant="rectangle"
        contentBox={`${symb} = ${varValue.toLocaleString()}`}
        testCorrect={sortNumberArray(items.map(({ value }) => value))}
        questionHeight={800}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aT7',
  description: 'aT7',
  keywords: ['Table'],
  schema: z.object({
    symb: algebraicSymbolSchema,
    number1: z.number().int().min(3).max(9),
    number2: z.number().int().min(1).max(9),
    add: z.boolean(),
    var1: z.number().int().min(2).max(10),
    var2: z.number().int().min(2).max(10),
    var3: z.number().int().min(2).max(10)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const add = getRandomBoolean();
    const symb = getAlgebraicSymbol();
    const number1 = randomIntegerInclusive(3, 9);
    const number2 = randomIntegerInclusive(1, add ? 9 : 6);

    const [var1, var2, var3] = randomUniqueIntegersInclusive(2, 10, 3);
    return { symb, number1, number2, add, var1, var2, var3 };
  },
  Component: props => {
    const {
      question: { symb, number1, number2, add, var1, var2, var3 },
      translate
    } = props;

    const tableData = [
      [`${var1}`, '<ans/>', '<ans/>'],
      [`<ans/>`, `${number1 * var2}`, '<ans/>'],
      [`<ans/>`, '<ans/>', `${add ? number1 * var3 + number2 : number1 * var3 - number2}`]
    ];

    const correctAnswers = [
      number1 * var1,
      add ? number1 * var1 + number2 : number1 * var1 - number2,
      var2,
      add ? number1 * var2 + number2 : number1 * var2 - number2,
      var3,
      number1 * var3
    ];

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTable()}
        cellHeaders={[symb, `${number1}${symb}`, `${number1}${symb} ${add ? ADD : SUB} ${number2}`]}
        tableData={tableData}
        testCorrect={correctAnswers.map(ans => ans.toString())}
        questionHeight={800}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Substitution',
  questionTypes: [Question1, Question2v2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
