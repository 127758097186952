import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { randomIntegerInclusive, shuffle } from '../../../../utils/random';
import { z } from 'zod';
import CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import {
  base10ObjectToNumber,
  numberToBase10Object,
  ScientificNotation
} from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import ReadPlaceValueChart from '../../../../components/question/questionFormats/ReadPlaceValueChart';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aaQ',
  description: 'aaQ',
  keywords: ['Place value', 'Base 10'],
  schema: z.object({
    number: z.number().int().min(122).max(999)
  }),
  questionHeight: 1440,
  example: {
    number: 124
  },
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(2, 9);
    const tens = randomIntegerInclusive(2, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    const number = base10ObjectToNumber({ hundreds, tens, ones });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const { ones = 0, tens = 0, hundreds = 0 } = numberToBase10Object(number);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        testCorrect={[hundreds.toString(), tens.toString(), ones.toString(), number.toString()]}
        sentence={translate.answerSentences.hundredsTensOnesAns()}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: 'Cubes', numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        pdfDirection="column"
        questionHeight={1440}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aaR',
  description: 'aaR',
  keywords: ['Place value', 'Counters'],
  schema: z.object({
    number: z.number().int().min(111).max(999)
  }),
  example: {
    number: 124
  },
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    const number = base10ObjectToNumber({ hundreds, tens, ones });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const { ones = 0, tens = 0, hundreds = 0 } = numberToBase10Object(number);

    return (
      <QF1ContentAndSentence
        sentence="<ans/>"
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[number.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: 'Counters', numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

export const Question3 = newQuestionContent({
  uid: 'aaS',
  description: 'aaS',
  keywords: ['Place value', 'Counters', 'Chart'],
  schema: z.object({
    number: z.number().int().min(101).max(999)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    const number = base10ObjectToNumber({ hundreds, tens, ones });
    return { number };
  },
  Component: ({ question }) => (
    <ReadPlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[2, 1, 0]}
      counterVariant="decimalCounter"
      questionHeight={900}
    />
  ),
  questionHeight: 900
});

export const Question4 = newQuestionContent({
  uid: 'aaT',
  description: 'aaT',
  keywords: ['Place value', 'Counters', 'Chart'],
  schema: z.object({
    number: z.number().min(101).max(999)
  }),
  example: {
    number: 124
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(101, 999);
    return { number };
  },
  Component: ({ question, translate, ...props }) => (
    <CreatePlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[2, 1, 0]}
      counterVariant="decimalCounter"
      pdfTitle={translate.instructions.drawCountersToRepresentNum(question.number)}
      {...props}
    />
  )
});

export const Question5 = newQuestionContent({
  uid: 'aaU',
  description: 'aaU',
  keywords: ['Place value', 'Counters', 'Chart', 'Place holder'],
  schema: z.object({
    number: z.number().int().min(101).max(999)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(101, 999, {
      constraint: x => {
        // At least 1 of T or O = 0
        const { ones, tens } = numberToBase10Object(x);
        return ones === 0 || tens === 0;
      }
    });
    return { number };
  },
  Component: ({ question }) => (
    <ReadPlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[2, 1, 0]}
      counterVariant="decimalCounter"
      questionHeight={900}
    />
  ),
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aaV',
  description: 'aaV',
  keywords: ['Place value'],
  schema: z.object({
    numbers: z.number().int().min(10).max(999).array().length(3),
    positions: z.enum(['ones', 'tens', 'hundreds']).array().length(3).refine(arrayHasNoDuplicates)
  }),
  example: {
    numbers: [861, 743, 625],
    positions: ['tens', 'ones', 'hundreds']
  },
  simpleGenerator: () => {
    const numbers = [
      randomIntegerInclusive(10, 999),
      randomIntegerInclusive(10, 999),
      randomIntegerInclusive(10, 999)
    ];
    const positions = shuffle(['ones', 'tens', 'hundreds'] as const);
    return { numbers, positions };
  },
  Component: ({ question: { numbers, positions }, translate, ...props }) => {
    const equations = numbers.map((number, index) => {
      const position = positions[index];
      const base10 = numberToBase10Object(number);
      return {
        sentence: `The number ${number} has <ans/> ${position}.`,
        correctAnwer: base10[position] ?? 0
      };
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={equations.map(eq => [eq.correctAnwer.toString()])}
        sentences={equations.map(equation => equation.sentence)}
        containerStyle={{ alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HundredsTensAndOnes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
