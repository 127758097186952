import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import ContentBox from '../../../../components/molecules/ContentBox';
import { numberEnum } from '../../../../utils/zod';
import { all, create, number } from 'mathjs';
import { compareFloats } from '../../../../utils/math';
import TextStructure from '../../../../components/molecules/TextStructure';
import { getRandomName, getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aDi',
  description: 'aDi',
  keywords: ['Converting units', 'Imperial', 'Metric', 'Inches', 'cm', 'Centimetres'],
  schema: z.object({
    centimetres: numberEnum([1.25, 5, 7.5, 10, 12.5, 15, 20, 25, 50, 100, 250]),
    isCmToIn: z.boolean()
  }),
  simpleGenerator: () => {
    const centimetres = getRandomFromArray([
      1.25, 5, 7.5, 10, 12.5, 15, 20, 25, 50, 100, 250
    ] as const);

    const isCmToIn = getRandomBoolean();

    return { centimetres, isCmToIn };
  },

  Component: props => {
    const {
      question: { centimetres, isCmToIn },
      translate,
      displayMode
    } = props;

    const statement = isCmToIn
      ? {
          sentence: `${translate.answerSentences.numCmApproximatelyEqualsAnsInches(centimetres)}`,
          answer: number(math.evaluate(`${centimetres} / 2.5`))
        }
      : {
          sentence: `${translate.answerSentences.numInchesApproximatelyEqualsAnsCm(
            number(math.evaluate(`${centimetres} / 2.5`))
          )}`,
          answer: number(math.evaluate(`${number(math.evaluate(`${centimetres} / 2.5`))} * 2.5`))
        };

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useThisFactToCompleteConversion()}
        inputMaxCharacters={5}
        testCorrect={[statement.answer.toString()]}
        extraSymbol="decimalPoint"
        sentenceStyle={{ alignSelf: 'flex-start' }}
        sentence={statement.sentence}
        Content={
          <ContentBox>
            <TextStructure
              textStyle={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 28 : 50 }}
              sentence={translate.answerSentences.oneXIsApproximatelyEqualToY(
                translate.units.numberOfInches(1),
                translate.units.numberOfCentimetres(2.5)
              )}
            />
          </ContentBox>
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [statement.answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aDj',
  description: 'aDj',
  keywords: [
    'Converting units',
    'Height',
    'Imperial',
    'Metric',
    'Feet',
    'Inches',
    'cm',
    'Centimetres'
  ],
  schema: z.object({
    character: nameSchema,
    feet: numberEnum([4, 5]),
    inches: z.number().int().min(2).max(11)
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const character = getRandomName();
    const feet = getRandomFromArray([4, 5] as const);
    const inches = randomIntegerInclusive(2, 11);

    return { character, feet, inches };
  },

  Component: props => {
    const {
      question: { character, feet, inches },
      translate,
      displayMode
    } = props;

    const statements = [
      {
        sentence: translate.answerSentences.characterIsAnsInchesTall(character),
        answer: number(math.evaluate(`(${feet} * 12) + ${inches}`))
      },
      {
        sentence: translate.answerSentences.characterIsApproximatelyAnsCentimetresTall(character),
        answer: number(math.evaluate(`((${feet} * 12) + ${inches}) * 2.5 `))
      }
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.characterIsXFeetYInchesTall(character, feet, inches)}
        testCorrect={userAnswer =>
          userAnswer
            .filter((_, index) => index !== 0)
            .every((ans, i) => ans && compareFloats(ans[0], statements[i].answer.toString()))
        }
        pdfDirection="column"
        inputMaxCharacters={5}
        questionHeight={1100}
        extraSymbol="decimalPoint"
        Content={
          <ContentBox>
            <TextStructure
              textStyle={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 28 : 50 }}
              sentence={`${translate.answerSentences.oneXIsApproximatelyEqualToY(
                translate.units.numberOfInches(1),
                translate.units.numberOfCentimetres(2.5)
              )}<br/> ${translate.units.numberOfFeet(1)} = ${translate.units.numberOfInches(12)}`}
            />
          </ContentBox>
        }
        sentences={[
          translate.answerSentences.useTheFactsToCompleteTheSentences(),
          ...statements.map(statement => statement.sentence)
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [],
            [statements[0].answer.toLocaleString()],
            [statements[1].answer.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aDk',
  description: 'aDk',
  keywords: ['Converting units', 'Imperial', 'Metric', 'kg', 'Kilograms', 'lb', 'Pounds'],
  schema: z.object({
    choosenQuestion: numberEnum([1, 2, 3]),
    poundsOrKilograms: z.number().min(1).max(880)
  }),
  simpleGenerator: () => {
    const choosenQuestion = getRandomFromArray([1, 2, 3] as const);

    const poundsOrKilograms =
      choosenQuestion === 1
        ? randomIntegerInclusive(1, 10)
        : choosenQuestion === 2
        ? randomIntegerInclusiveStep(10, 100, 5)
        : getRandomFromArray([4.4, 6.6, 8.8, 11, 22, 44, 66, 88, 110, 220, 440, 660, 880] as const);

    return { poundsOrKilograms, choosenQuestion };
  },

  Component: props => {
    const {
      question: { poundsOrKilograms, choosenQuestion },
      translate,
      displayMode
    } = props;

    const statement =
      choosenQuestion === 1 || choosenQuestion === 2
        ? {
            sentence: `${translate.answerSentences.numKgApproximatelyEqualsAnsLb(
              poundsOrKilograms
            )}`,
            answer: number(math.evaluate(`${poundsOrKilograms} * 2.2`))
          }
        : {
            sentence: `${translate.answerSentences.numLbApproximatelyEqualsAnsKg(
              poundsOrKilograms
            )}`,
            answer: number(math.evaluate(`${poundsOrKilograms} / 2.2`))
          };

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useThisFactToCompleteConversion()}
        inputMaxCharacters={5}
        testCorrect={userAnswer => compareFloats(userAnswer[0], statement.answer.toString())}
        mainPanelStyle={{ alignItems: 'flex-start' }}
        extraSymbol="decimalPoint"
        sentenceStyle={{ alignSelf: 'flex-start' }}
        sentence={statement.sentence}
        Content={
          <ContentBox>
            <TextStructure
              textStyle={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 28 : 50 }}
              sentence={translate.answerSentences.oneXIsApproximatelyEqualToY(
                translate.units.numberOfKg(1),
                translate.units.numberOfLb(2.2)
              )}
            />
          </ContentBox>
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [statement.answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aDl',
  description: 'aDl',
  keywords: ['Converting units', 'Imperial', 'Metric', 'kg', 'Kilograms', 'lb', 'Pounds'],
  schema: z.object({
    kilograms: z.number().int().min(10).max(60).step(5),
    items: z.array(z.number().min(0.1).max(216.6)).length(6)
  }),
  simpleGenerator: () => {
    const {
      incorrectPoundsA,
      incorrectStonesA,
      incorrectPoundsB,
      poundsA,
      poundsB,
      stones,
      kilograms
    } = rejectionSample(
      () => {
        const [minusValueA, minusValueB] = countRange(2).map(_ => getRandomBoolean());

        const kilograms = randomIntegerInclusive(10, 60, {
          constraint: x => x % 5 === 0
        });

        const poundsA = number(math.evaluate(`${kilograms} * 2.2`));
        const stones = Math.floor(number(math.evaluate(`${poundsA} / 14`)));
        const poundsB = number(math.evaluate(`((${poundsA} / 14) - ${stones}) * 14`));

        const incorrectPoundsA = number(math.evaluate(`${kilograms} * 2`));

        const incorrectStonesA = minusValueA
          ? number(math.evaluate(`${stones} - 1`))
          : number(math.evaluate(`${stones} + 1`));

        const incorrectPoundsB = minusValueB
          ? number(math.evaluate(`${poundsB} - 1`))
          : number(math.evaluate(`${poundsB} + 1`));

        return {
          incorrectPoundsA,
          incorrectStonesA,
          incorrectPoundsB,
          poundsA,
          poundsB,
          stones,
          kilograms
        };
      },
      // Only permit them if items has no duplicates and every number is greater than 0
      ({ incorrectPoundsA, incorrectStonesA, incorrectPoundsB, poundsB, poundsA, stones }) =>
        arrayHasNoDuplicates([
          incorrectPoundsA,
          incorrectStonesA,
          incorrectPoundsB,
          poundsB,
          poundsA,
          stones
        ]) &&
        [incorrectPoundsA, incorrectStonesA, incorrectPoundsB].every(num => num > 0) &&
        poundsB > 1.1
    );

    const items = [poundsA, stones, poundsB, incorrectPoundsA, incorrectStonesA, incorrectPoundsB];

    return { items, kilograms };
  },
  Component: props => {
    const {
      question: { items, kilograms },
      translate,
      displayMode
    } = props;

    const itemsShuffled = shuffle(items, { random: seededRandom(props.question) });

    return (
      <QF36ContentAndSentencesDrag
        title={translate.instructions.aDogHasAMassOfXKgDragTheApproximateMassOfTheDogInBothPoundsAndStonesAndPounds(
          kilograms
        )}
        pdfTitle={translate.instructions.aDogHasAMassOfXKgUseTheApproximateMassOfTheDogInBothPoundsAndStonesAndPounds(
          kilograms
        )}
        Content={
          <ContentBox>
            <TextStructure
              textStyle={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 28 : 50 }}
              sentence={`${translate.answerSentences.thereAreNumXPoundsInNumStone(
                14,
                1
              )}<br/>${translate.units.numberOfKg(1)} ≈ ${translate.units.numberOfLb(2.2)}`}
            />
          </ContentBox>
        }
        sentences={[
          translate.answerSentences.approximatelyAnsLb(),
          translate.answerSentences.approximatelyAnsStoneAnsLb()
        ]}
        testCorrect={[[items[0]], [items[1], items[2]]]}
        items={itemsShuffled}
        moveOrCopy="move"
        questionHeight={1100}
        actionPanelVariant="endMid"
        sentencesStyle={{ alignItems: 'flex-start' }}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aDm',
  description: 'aDm',
  keywords: ['Converting units', 'Imperial', 'Metric', 'Pint', 'ml', 'Millilitres'],
  schema: z.object({
    millilitres: numberEnum([56.8, 5680, 284, 1136, 1704, 2272, 2840, 11360])
  }),
  simpleGenerator: () => {
    const millilitres = getRandomFromArray([
      56.8, 5680, 284, 1136, 1704, 2272, 2840, 11360
    ] as const);

    return { millilitres };
  },

  Component: props => {
    const {
      question: { millilitres },
      translate,
      displayMode
    } = props;

    const statement =
      millilitres === 56.8 || millilitres === 5680
        ? {
            sentence: `${translate.answerSentences.numMlApproximatelyEqualsAnsPints(millilitres)}`,
            answer: number(math.evaluate(`${millilitres} / 568`))
          }
        : {
            sentence: `${translate.answerSentences.numPintsApproximatelyEqualsAnsMl(
              number(math.evaluate(`${millilitres} / 568`))
            )}`,
            answer: millilitres
          };

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useThisFactToCompleteConversion()}
        inputMaxCharacters={5}
        testCorrect={[statement.answer.toString()]}
        extraSymbol="decimalPoint"
        sentenceStyle={{ alignSelf: 'flex-start' }}
        sentence={statement.sentence}
        Content={
          <ContentBox>
            <TextStructure
              textStyle={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 28 : 50 }}
              sentence={translate.answerSentences.oneXIsApproximatelyEqualToY(
                translate.units.numberOfPints(1),
                translate.units.numberOfMl(568)
              )}
            />
          </ContentBox>
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [statement.answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aDn',
  description: 'aDn',
  keywords: ['Imperial', 'Metric', 'Length'],
  schema: z.object({
    feet: z.number().int().min(3).max(5),
    inches: z.number().int().min(0).max(11),
    char1Cm: z.number().min(90).max(177.5),
    char2Cm: z.number().min(78).max(195.5),
    characterA: nameSchema,
    characterB: nameSchema,
    isCharacterAFirst: z.boolean(),
    isQuestionTaller: z.boolean()
  }),
  simpleGenerator: () => {
    const [characterA, characterB] = getRandomUniqueNames(2);

    const feet = randomIntegerInclusive(3, 5);
    const inches = randomIntegerInclusive(0, 11);
    const isCharacterBTaller = getRandomBoolean();

    const char1Cm = (feet * 12 + inches) * 2.5;
    const difference = randomIntegerInclusive(3, 12);
    const char2Cm = isCharacterBTaller ? char1Cm + difference : char1Cm - difference;

    const isCharacterAFirst = getRandomBoolean();
    const isQuestionTaller = getRandomBoolean();

    return {
      feet,
      inches,
      char1Cm,
      char2Cm,
      characterA,
      characterB,
      isCharacterAFirst,
      isQuestionTaller
    };
  },
  questionHeight: 1000,
  Component: ({
    question: {
      feet,
      inches,
      char1Cm,
      char2Cm,
      characterA,
      characterB,
      isCharacterAFirst,
      isQuestionTaller
    },
    translate
  }) => {
    const [questionCharacterA, questionCharacterB] = isCharacterAFirst
      ? [characterA, characterB]
      : [characterB, characterA];

    const tallerOrShorter = isQuestionTaller ? translate.misc.Taller() : translate.misc.Shorter();

    const answer = (() => {
      switch (isQuestionTaller) {
        case true:
          if (isCharacterAFirst) {
            return char1Cm > char2Cm;
          } else {
            return char1Cm < char2Cm;
          }
        case false:
          if (isCharacterAFirst) {
            return char1Cm < char2Cm;
          } else {
            return char1Cm > char2Cm;
          }
      }
    })();

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.char1IsXFootYInchesTallChar2IsZcmTallCharIsTallerOrShorterThanChar(
          characterA,
          feet,
          inches,
          characterB,
          char2Cm,
          questionCharacterA,
          tallerOrShorter,
          questionCharacterB
        )}<br/>${translate.instructions.isStatementTrueOrFalse()}`}
        pdfTitle={`${translate.instructions.char1IsXFootYInchesTallChar2IsZcmTallCharIsTallerOrShorterThanChar(
          characterA,
          feet,
          inches,
          characterB,
          char2Cm,
          questionCharacterA,
          tallerOrShorter,
          questionCharacterB
        )}<br/>${translate.instructions.isStatementTrueOrFalsePDF()}`}
        correctAnswer={answer}
        questionHeight={1000}
        content={
          <ContentBox>
            <TextStructure
              textStyle={{ textAlign: 'center' }}
              sentence={translate.answerSentences.oneXIsApproximatelyEqualToY(
                translate.units.numberOfInches(1),
                translate.units.numberOfCentimetres(2.5)
              )}
            />
          </ContentBox>
        }
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aDn2',
  description: 'aDn',
  keywords: ['Imperial', 'Metric', 'Length'],
  schema: z.object({
    feet: z.number().int().min(3).max(5),
    inches: z.number().int().min(0).max(11),
    char1Cm: z.number().min(90).max(177.5),
    char2Cm: z.number().min(78).max(195.5),
    characterA: nameSchema,
    characterB: nameSchema,
    isQuestionTaller: z.boolean()
  }),
  simpleGenerator: () => {
    const [characterA, characterB] = getRandomUniqueNames(2);

    const feet = randomIntegerInclusive(3, 5);
    const inches = randomIntegerInclusive(0, 11);
    const isCharacterBTaller = getRandomBoolean();

    const char1Cm = (feet * 12 + inches) * 2.5;
    const difference = randomIntegerInclusive(3, 12);
    const char2Cm = isCharacterBTaller ? char1Cm + difference : char1Cm - difference;
    const isQuestionTaller = getRandomBoolean();

    return {
      feet,
      inches,
      char1Cm,
      char2Cm,
      characterA,
      characterB,
      isQuestionTaller
    };
  },

  Component: ({
    question: { feet, inches, char1Cm, char2Cm, characterA, characterB, isQuestionTaller },
    translate
  }) => {
    const characterACorrect =
      (isQuestionTaller && char1Cm > char2Cm) || (!isQuestionTaller && char1Cm < char2Cm);

    const items = [
      {
        value: 'A',
        string: characterA,
        isCorrect: characterACorrect
      },
      {
        value: 'B',
        string: characterB,
        isCorrect: !characterACorrect
      }
    ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={''}
        testCorrect={items.filter(val => val.isCorrect).map(val => val.value)}
        numItems={2}
        itemLayout="row"
        renderItems={() =>
          items.map(item => ({
            value: item.value,
            component: <Text variant="WRN700">{item.string}</Text>
          }))
        }
        Content={({ dimens }) => (
          <View
            style={{
              height: dimens.height,
              gap: 40,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <ContentBox containerStyle={{ width: dimens.width * 0.8 }}>
              <TextStructure
                textStyle={{ textAlign: 'center' }}
                sentence={translate.answerSentences.oneXIsApproximatelyEqualToY(
                  translate.units.numberOfInches(1),
                  translate.units.numberOfCentimetres(2.5)
                )}
              />
            </ContentBox>

            <View style={{ width: dimens.width, justifyContent: 'flex-start' }}>
              <TextStructure
                sentence={translate.answerSentences.char1IsXFootYInchesTallChar2IsZcmTallWhosIsShorter(
                  characterA,
                  feet,
                  inches,
                  characterB,
                  char2Cm
                )}
              />
            </View>
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertBetweenMetricAndImperialUnits',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
