import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF28FunctionMachines from '../../../../components/question/questionFormats/QF28FunctionMachines';
import { DIV, MULT } from '../../../../constants';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import { compareFloats } from '../../../../utils/math';
import { BigNumber, all, create, number } from 'mathjs';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aCq',
  description: 'aCq',
  keywords: ['Decimal', 'Multiplication', 'Tenths', 'Hundredths'],
  schema: z.object({
    powerOfTen: numberEnum([10, 100, 1000]),
    questionNumber: z.number().min(1.01).max(99.99).multipleOf(0.01),
    questionToShow: z.enum(['a', 'b', 'c', 'd'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const powerOfTen = getRandomFromArray([10, 100, 1000] as const);
    const questionToShow = getRandomFromArray(['a', 'b', 'c', 'd'] as const);
    const questionNumber =
      questionToShow === 'a'
        ? randomIntegerInclusive(11, 99, {
            constraint: x => x % 10 !== 0
          }) / 10
        : questionToShow === 'b'
        ? randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 100
        : questionToShow === 'c'
        ? randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 10
        : randomIntegerInclusive(1001, 9999, {
            constraint: x => x % 10 !== 0
          }) / 100;

    return {
      questionNumber,
      powerOfTen,
      questionToShow
    };
  },
  Component: props => {
    const {
      question: { questionNumber, powerOfTen, questionToShow },
      translate
    } = props;

    const number2 = number(math.evaluate(`${questionNumber} * ${powerOfTen}`));

    const [answer, sentence] =
      questionToShow === 'a' || questionToShow === 'c'
        ? [
            powerOfTen,
            `${questionNumber.toLocaleString()} ${MULT} <ans/> = ${number2.toLocaleString()}`
          ]
        : [
            questionNumber,
            `<ans/> ${MULT} ${powerOfTen.toLocaleString()} = ${number2.toLocaleString()}`
          ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeMultiplication()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={5}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aCr',
  description: 'aCr',
  keywords: ['Decimal', 'Division', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumber: z.number().min(1.1).max(99.99),
    powerOfTen: numberEnum([10, 100, 1000]),
    questionToShow: z.enum(['a', 'b', 'c', 'd'])
  }),
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b', 'c', 'd'] as const);

    const { questionNumber, powerOfTen } = rejectionSample(
      () => {
        const questionNumber =
          questionToShow === 'a'
            ? randomIntegerInclusive(11, 99)
            : questionToShow === 'b'
            ? randomIntegerInclusive(11, 99, {
                constraint: x => x % 10 !== 0
              }) / 10
            : questionToShow === 'c'
            ? randomIntegerInclusive(101, 999, {
                constraint: x => x % 10 !== 0
              }) / 10
            : randomIntegerInclusive(1001, 9999, {
                constraint: x => x % 10 !== 0
              }) / 100;

        const powerOfTen =
          questionToShow === 'a'
            ? getRandomFromArray([10, 100, 1000] as const)
            : getRandomFromArray([10, 100] as const);

        return { questionNumber, powerOfTen };
      },
      ({ questionNumber, powerOfTen }) => {
        const decimalPlaces = number(math.evaluate(`${questionNumber} / ${powerOfTen}`))
          .toString()
          .split('.')[1];
        return decimalPlaces ? decimalPlaces.length <= 3 : true;
      }
    );

    return {
      questionNumber,
      powerOfTen,
      questionToShow
    };
  },
  Component: props => {
    const {
      question: { questionNumber, powerOfTen, questionToShow },
      translate
    } = props;

    const number2 = number(math.evaluate(`${questionNumber} / ${powerOfTen}`));

    const [answer, sentence] =
      questionToShow === 'a' || questionToShow === 'c'
        ? [
            powerOfTen,
            `${questionNumber.toLocaleString()} ${DIV} <ans/> = ${number2.toLocaleString(
              undefined,
              {
                maximumFractionDigits: 5
              }
            )}`
          ]
        : [
            questionNumber,
            `<ans/> ${DIV} ${powerOfTen.toLocaleString()} = ${number2.toLocaleString(undefined, {
              maximumFractionDigits: 5
            })}`
          ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeDivision()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={5}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aCs',
  description: 'aCs',
  keywords: ['Decimal', 'Division', 'Multiplication', 'Tenths', 'Hundredths', 'Function machine'],
  schema: z.object({
    baseNumber: z.number().min(0.001).max(0.999),
    powerOfTenA: numberEnum([10, 100]),
    powerOfTenB: numberEnum([10, 100])
  }),
  simpleGenerator: () => {
    const baseNumber = randomIntegerInclusive(1, 999) / 1000;

    const powerOfTenA = getRandomFromArray([10, 100] as const);
    const powerOfTenB = getRandomFromArray([10, 100] as const);

    return { baseNumber, powerOfTenA, powerOfTenB };
  },
  Component: props => {
    const {
      question: { baseNumber, powerOfTenA, powerOfTenB },
      translate
    } = props;

    const answer1 = baseNumber * powerOfTenA;
    const answer2 = answer1 * powerOfTenB;

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheMissingNumbers()}
        rowsOfBoxes={[
          [
            '<ans/>',
            `${DIV} ${powerOfTenB.toLocaleString()}`,
            `<ans/>`,
            `${DIV} ${powerOfTenA.toLocaleString()}`,
            `${baseNumber.toLocaleString()}`
          ]
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer2) && compareFloats(userAnswer[0][1], answer1)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answerToDisplay: [[answer2.toLocaleString(), answer1.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aCs2',
  description: 'aCs',
  keywords: ['Decimal', 'Division', 'Multiplication', 'Tenths', 'Hundredths', 'Function machine'],
  schema: z.object({
    baseNumber: z.number().min(0.001).max(11.999),
    powerOfTenA: numberEnum([10, 100, 1000]),
    powerOfTenB: numberEnum([10, 100, 1000])
  }),
  simpleGenerator: () => {
    const numberType = getRandomFromArray([1, 2, 3, 4, 5, 6] as const);

    const { baseNumber, powerOfTenA, powerOfTenB } = rejectionSample(
      () => {
        const baseNumber = (() => {
          switch (numberType) {
            case 1:
              return randomIntegerInclusive(1, 9) / 10;
            case 2:
              return randomIntegerInclusive(11, 119, { constraint: x => x % 10 !== 0 }) / 10;
            case 3:
              return randomIntegerInclusive(1, 99, { constraint: x => x % 10 !== 0 }) / 100;
            case 4:
              return randomIntegerInclusive(101, 1199, { constraint: x => x % 10 !== 0 }) / 100;
            case 5:
              return randomIntegerInclusive(111, 999, { constraint: x => x % 10 !== 0 }) / 1000;
            case 6:
              return randomIntegerInclusive(1001, 11999, { constraint: x => x % 10 !== 0 }) / 1000;
          }
        })();

        const powerOfTenA = getRandomFromArray([10, 100, 1000] as const);
        const powerOfTenB = getRandomFromArray([10, 100, 1000] as const);

        return { baseNumber, powerOfTenA, powerOfTenB };
      },
      ({ baseNumber, powerOfTenA, powerOfTenB }) => baseNumber * powerOfTenA * powerOfTenB < 100000
    );

    return { baseNumber, powerOfTenA, powerOfTenB };
  },
  Component: props => {
    const {
      question: { baseNumber, powerOfTenA, powerOfTenB },
      translate
    } = props;

    const answerA = baseNumber * powerOfTenA;

    const output = baseNumber * powerOfTenA * powerOfTenB;

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheMissingNumbers()}
        rowsOfBoxes={[
          [
            '<ans/>',
            `${MULT} ${powerOfTenA.toLocaleString()}`,
            `<ans/>`,
            `${MULT} ${powerOfTenB.toLocaleString()}`,
            `${output.toLocaleString()}`
          ]
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], baseNumber) && compareFloats(userAnswer[0][1], answerA)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answerToDisplay: [[baseNumber.toLocaleString(), answerA.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aCt',
  description: 'aCt',
  keywords: ['Decimal', 'Division', 'Multiplication', 'Tenths', 'Hundredths', 'Function machine'],
  schema: z.object({
    baseNumber: z.number().min(10.1).max(99.9),
    powerOfTenA: numberEnum([10, 100]),
    powerOfTenB: numberEnum([10, 100])
  }),
  simpleGenerator: () => {
    const baseNumber = randomIntegerInclusive(101, 999) / 10;

    const powerOfTenA = getRandomFromArray([10, 100] as const);
    const powerOfTenB = getRandomFromArray([10, 100] as const);

    return { baseNumber, powerOfTenA, powerOfTenB };
  },
  Component: props => {
    const {
      question: { baseNumber, powerOfTenA, powerOfTenB },
      translate
    } = props;

    const answer1 = baseNumber / powerOfTenA;
    const answer2 = answer1 / powerOfTenB;

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheMissingNumbers()}
        rowsOfBoxes={[
          [
            '<ans/>',
            `${MULT} ${powerOfTenB.toLocaleString()}`,
            `<ans/>`,
            `${MULT} ${powerOfTenA.toLocaleString()}`,
            `${baseNumber.toLocaleString()}`
          ]
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer2) && compareFloats(userAnswer[0][1], answer1)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answerToDisplay: [[answer2.toLocaleString(), answer1.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4V2 = newQuestionContent({
  uid: 'aCt2',
  description: 'aCt',
  keywords: ['Decimal', 'Division', 'Multiplication', 'Tenths', 'Hundredths', 'Function machine'],
  schema: z.object({
    output: z.number().min(0.001).max(11.999),
    powerOfTenA: numberEnum([10, 100, 1000]),
    powerOfTenB: numberEnum([10, 100, 1000])
  }),
  simpleGenerator: () => {
    const option = randomIntegerInclusive(1, 6);

    const output =
      option === 1
        ? randomIntegerInclusive(1, 9) / 10
        : option === 2
        ? randomIntegerInclusive(11, 119, { constraint: x => x % 10 !== 0 }) / 10
        : option === 3
        ? randomIntegerInclusive(1, 99, { constraint: x => x % 10 !== 0 }) / 100
        : option === 4
        ? randomIntegerInclusive(101, 1199, { constraint: x => x % 10 !== 0 }) / 100
        : option === 5
        ? randomIntegerInclusive(1, 999, { constraint: x => x % 10 !== 0 }) / 1000
        : randomIntegerInclusive(1001, 11999, { constraint: x => x % 10 !== 0 }) / 1000;

    const { powerOfTenA, powerOfTenB } = rejectionSample(
      () => {
        const powerOfTenA = getRandomFromArray([10, 100, 1000] as const);
        const powerOfTenB = getRandomFromArray([10, 100, 1000] as const);
        return { powerOfTenA, powerOfTenB };
      },
      val => output * val.powerOfTenA * val.powerOfTenB <= 100000
    );

    return { output, powerOfTenA, powerOfTenB };
  },
  Component: props => {
    const {
      question: { output, powerOfTenA, powerOfTenB },
      translate
    } = props;

    const answer2 = output * powerOfTenB;
    const answer1 = answer2 * powerOfTenA;

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheMissingNumbers()}
        rowsOfBoxes={[
          [
            '<ans/>',
            `${DIV} ${powerOfTenA.toLocaleString()}`,
            `<ans/>`,
            `${DIV} ${powerOfTenB.toLocaleString()}`,
            `${output.toLocaleString()}`
          ]
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) && compareFloats(userAnswer[0][1], answer2)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answerToDisplay: [[answer1.toLocaleString(), answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aCu',
  description: 'aCu',
  keywords: ['Decimal', 'Division', 'Multiplication', 'Tenths', 'Hundredths', 'Function machine'],
  schema: z.object({
    baseNumber: z.number().min(1.1).max(9.9),
    powerOfTenA: numberEnum([10, 100, 1000]),
    powerOfTenB: numberEnum([10, 100, 1000])
  }),
  simpleGenerator: () => {
    const baseNumber =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;

    randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    }) / 10;

    const [powerOfTenA, powerOfTenB] = getRandomSubArrayFromArray([10, 100, 1000] as const, 2);

    return { baseNumber, powerOfTenA, powerOfTenB };
  },
  Component: props => {
    const {
      question: { baseNumber, powerOfTenA, powerOfTenB },
      translate,
      displayMode
    } = props;

    const answer1 = baseNumber / powerOfTenA;
    const answer2 = answer1 * powerOfTenB;

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheMissingNumbers()}
        rowsOfBoxes={[
          [
            '<ans/>',
            `${DIV} ${powerOfTenB.toLocaleString()}`,
            `<ans/>`,
            `${MULT} ${powerOfTenA.toLocaleString()}`,
            `${baseNumber.toLocaleString()}`
          ]
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer2) && compareFloats(userAnswer[0][1], answer1)
        }
        extraSymbol="decimalPoint"
        boxWidth={displayMode === 'digital' ? 200 : undefined}
        customMarkSchemeAnswer={{
          answerToDisplay: [[answer2.toLocaleString(), answer1.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5V2 = newQuestionContent({
  uid: 'aCu2',
  description: 'aCu',
  keywords: ['Decimal', 'Division', 'Multiplication', 'Tenths', 'Hundredths', 'Function machine'],
  schema: z.object({
    output: z.number().min(0.001).max(999.99),
    powerOfTenA: numberEnum([10, 100, 1000]),
    powerOfTenB: numberEnum([10, 100, 1000]),
    aIsMult: z.boolean()
  }),
  simpleGenerator: () => {
    const { output, powerOfTenA, powerOfTenB, aIsMult } = rejectionSample(
      () => {
        const powerOfTenA = getRandomFromArray([10, 100, 1000] as const);
        const aIsMult = getRandomBoolean();
        const powerOfTenB = getRandomFromArray([10, 100, 1000] as const);

        const option = randomIntegerInclusive(1, 8);

        const output =
          option === 1
            ? randomIntegerInclusive(1, 9) / 10
            : option === 2
            ? randomIntegerInclusive(11, 119, { constraint: x => x % 10 !== 0 }) / 10
            : option === 3
            ? randomIntegerInclusive(1, 99, { constraint: x => x % 10 !== 0 }) / 100
            : option === 4
            ? randomIntegerInclusive(101, 1199, { constraint: x => x % 10 !== 0 }) / 100
            : option === 5
            ? randomIntegerInclusive(1, 999, { constraint: x => x % 10 !== 0 }) / 1000
            : option === 6
            ? randomIntegerInclusive(1001, 11999, { constraint: x => x % 10 !== 0 }) / 1000
            : option === 7
            ? randomIntegerInclusive(121, 9999, { constraint: x => x % 10 !== 0 }) / 10
            : randomIntegerInclusive(1211, 99999, { constraint: x => x % 100 !== 0 }) / 100;
        return { output, powerOfTenA, powerOfTenB, aIsMult };
      },
      val => {
        const ans2 = val.aIsMult ? val.output * val.powerOfTenB : val.output / val.powerOfTenB;
        const ans1 = val.aIsMult ? ans2 / val.powerOfTenA : ans2 * val.powerOfTenA;

        return (
          ans1 >= 0.001 &&
          ans1 <= 100000 &&
          ans2 >= 0.001 &&
          ans2 <= 100000 &&
          (ans1 * 1000) % 1 === 0 &&
          (ans2 * 1000) % 1 === 0
        );
      }
    );

    return { output, powerOfTenA, powerOfTenB, aIsMult };
  },
  Component: props => {
    const {
      question: { output, powerOfTenA, powerOfTenB, aIsMult },
      translate
    } = props;

    const answer2 = aIsMult ? output * powerOfTenB : output / powerOfTenB;
    const answer1 = aIsMult ? answer2 / powerOfTenA : answer2 * powerOfTenA;

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheMissingNumbers()}
        rowsOfBoxes={[
          [
            '<ans/>',
            `${aIsMult ? MULT : DIV} ${powerOfTenA.toLocaleString()}`,
            `<ans/>`,
            `${aIsMult ? DIV : MULT} ${powerOfTenB.toLocaleString()}`,
            `${output.toLocaleString()}`
          ]
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) && compareFloats(userAnswer[0][1], answer2)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answerToDisplay: [[answer1.toLocaleString(), answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const q6Schema = z.object({
  questionNumber: z.number().min(0.01).max(9.99).multipleOf(0.01),
  powerOfTenA: numberEnum([100, 1000]),
  powerOfTenB: numberEnum([10, 100]),
  questionToShow: z.enum(['a', 'b', 'c'])
});

function q6CheckNoPlaceValueIsLessThanThousandth({
  questionNumber,
  powerOfTenA,
  powerOfTenB,
  questionToShow
}: z.infer<typeof q6Schema>) {
  let answerNumber: BigNumber, rhs: BigNumber;
  switch (questionToShow) {
    case 'a':
      answerNumber = math.evaluate(`${questionNumber} * ${powerOfTenA} / ${powerOfTenB}`);
      rhs = math.evaluate(`${questionNumber} * ${powerOfTenA}`);
      break;
    case 'b':
      answerNumber = math.evaluate(`${questionNumber} / ${powerOfTenA} * ${powerOfTenB}`);
      rhs = math.evaluate(`${questionNumber} / ${powerOfTenA}`);
      break;
    case 'c':
      answerNumber = math.evaluate(`${questionNumber} * ${powerOfTenA} * ${powerOfTenB}`);
      rhs = math.evaluate(`${questionNumber} * ${powerOfTenA}`);
  }

  return [answerNumber, rhs].every(x => x.times(1000).isInteger());
}

const Question6 = newQuestionContent({
  uid: 'aCv',
  description: 'aCv',
  keywords: ['Decimal', 'Division', 'Multiplication', 'Tenths', 'Hundredths'],
  schema: q6Schema,
  questionHeight: 800,
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b', 'c'] as const);

    return rejectionSample(
      () => {
        const questionNumber =
          questionToShow === 'a' || questionToShow === 'c'
            ? randomIntegerInclusive(101, 999, {
                constraint: x => x % 100 !== 0
              }) / 100
            : randomIntegerInclusive(1, 99) / 100;

        const powerOfTenA = getRandomFromArray([100, 1000] as const);

        const powerOfTenB = powerOfTenA === 100 ? 10 : getRandomFromArray([10, 100] as const);

        return { questionNumber, powerOfTenA, powerOfTenB, questionToShow };
      },
      // Check that no question uses a place value less than thousandths
      // Note: This check is not added to the schema because the question still makes sense otherwise, it just targets
      // the wrong age group. Also, there may be old versions of this question floating around which fail this check.
      q6CheckNoPlaceValueIsLessThanThousandth
    );
  },
  Component: props => {
    const {
      question: { questionNumber, powerOfTenA, powerOfTenB, questionToShow },
      translate,
      displayMode
    } = props;

    const answerNumber =
      questionToShow === 'a'
        ? number(math.evaluate(`${questionNumber} * ${powerOfTenA} / ${powerOfTenB}`))
        : questionToShow === 'b'
        ? number(math.evaluate(`${questionNumber} / ${powerOfTenA} * ${powerOfTenB}`))
        : number(math.evaluate(`${questionNumber} * ${powerOfTenA} * ${powerOfTenB}`));

    const [answer, sentence] =
      questionToShow === 'a'
        ? [
            answerNumber,
            `<ans/> ${MULT} ${powerOfTenB.toLocaleString()} = ${questionNumber.toLocaleString()} ${MULT} ${powerOfTenA.toLocaleString()}`
          ]
        : questionToShow === 'b'
        ? [
            powerOfTenB,
            `${answerNumber.toLocaleString()} ${DIV} <ans/> = ${questionNumber.toLocaleString()} ${DIV} ${powerOfTenA.toLocaleString()}`
          ]
        : [
            answerNumber,
            `<ans/> ${DIV} ${powerOfTenB.toLocaleString()} = ${questionNumber.toLocaleString()} ${MULT} ${powerOfTenA.toLocaleString()}`
          ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={6}
        questionHeight={800}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyAndDivideDecimalsMissingValues',
  questionTypes: [Question1, Question2, Question3v2, Question4V2, Question5V2, Question6],
  archivedQuestionTypes: [Question3, Question4, Question5]
});
export default SmallStep;
