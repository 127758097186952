// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import FindFractionsEquivalentToAUnitFraction from './1FindFractionsEquivalentToAUnitFraction';
import FindFractionsEquivalentToANonUnitFraction from './2FindFractionsEquivalentToANonUnitFraction';
import RecogniseEquivalentFractions from './3RecogniseEquivalentFractions';
import ConvertImproperFractionsToMixedNumbers from './4ConvertImproperFractionsToMixedNumbers';
import ConvertMixedNumbersToImproperFractions from './5ConvertMixedNumbersToImproperFractions';
import CompareFractionsLessThan1 from './6CompareFractionsLessThan1';
import OrderFractionsLessThan1 from './7OrderFractionsLessThan1';
import CompareAndOrderFractionsGreaterThan1 from './8CompareAndOrderFractionsGreaterThan1';
import AddAndSubtractFractionsWithTheSameDenominator from './9AddAndSubtractFractionsWithTheSameDenominator';
import AddFractionsWithin1 from './10AddFractionsWithin1';
import AddFractionsWithTotalGreaterThan1 from './11AddFractionsWithTotalGreaterThan1';
import AddToAMixedNumber from './12AddToAMixedNumber';
import AddTwoMixedNumbers from './13AddTwoMixedNumbers';
import SubtractFractions from './14SubtractFractions';
import SubtractFromAMixedNumber from './15SubtractFromAMixedNumber';
import SubtractFromAMixedNumberBreakingTheWhole from './16SubtractFromAMixedNumberBreakingTheWhole';
import SubtractTwoMixedNumbers from './17SubtractTwoMixedNumbers';

const Block = newBlockContent({
  block: 'FractionsA',
  field: 'Number',
  weeks: [9, 12],
  smallSteps: [
    FindFractionsEquivalentToAUnitFraction, // Step 1
    FindFractionsEquivalentToANonUnitFraction, // Step 2
    RecogniseEquivalentFractions, // Step 3
    ConvertImproperFractionsToMixedNumbers, // Step 4
    ConvertMixedNumbersToImproperFractions, // Step 5
    CompareFractionsLessThan1, // Step 6
    OrderFractionsLessThan1, // Step 7
    CompareAndOrderFractionsGreaterThan1, // Step 8
    AddAndSubtractFractionsWithTheSameDenominator, // Step 9
    AddFractionsWithin1, // Step 10
    AddFractionsWithTotalGreaterThan1, // Step 11
    AddToAMixedNumber, // Step 12
    AddTwoMixedNumbers, // Step 13
    SubtractFractions, // Step 14
    SubtractFromAMixedNumber, // Step 15
    SubtractFromAMixedNumberBreakingTheWhole, // Step 16
    SubtractTwoMixedNumbers // Step 17
  ]
});
export default Block;
