import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhO',
  description: 'bhO',
  keywords: ['Shape', '2-D shape', 'Vertical', 'Symmetry'],
  schema: z.object({
    points: z
      .array(z.object({ x: z.number().int().min(-4).max(0), y: z.number().int().min(1).max(4) }))
      .length(4)
  }),
  simpleGenerator: () => {
    const x1 = randomIntegerInclusive(-4, -1);
    const x2 = 0;
    const y1 = randomIntegerInclusive(1, 3);
    const y2 = randomIntegerInclusive(y1 + 1, 4);

    const points = [
      { x: x2, y: y2 },
      { x: x1, y: y2 },
      { x: x1, y: y1 },
      { x: x2, y: y1 }
    ];

    return { points };
  },
  Component: ({ question, translate }) => {
    const { points } = question;

    const fixedPoints = [points[0]];

    const expectedPoints = points.map(coord => ({ x: -coord.x, y: coord.y }));
    // Remove first point as this is given
    expectedPoints.shift();
    // Remove last point as this is given
    expectedPoints.pop();

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.theDiagramShowsHalfAShapeAndALineOfSymmetryTapTheGridToCompleteTheShape()}
        pdfTitle={translate.ks1PDFInstructions.theDiagramShowsHalfAShapeAndALineOfSymmetryCompleteTheShape()}
        testCorrect={expectedPoints}
        gridChildrenPoints={points}
        fixedPoints={fixedPoints}
        gridVariant="grid"
        symmetryLine={'Y'}
        joinToGridChild
        numPoints={points.length - 2}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bhP',
  description: 'bhP',
  keywords: ['Shape', '2-D shape', 'Vertical', 'Symmetry'],
  schema: z.object({
    points: z
      .array(z.object({ x: z.number().int().min(-4).max(0), y: z.number().int().min(1).max(4) }))
      .length(3)
  }),
  simpleGenerator: () => {
    const x1 = randomIntegerInclusive(-4, -1);
    const x2 = 0;
    const y1 = randomIntegerInclusive(1, 3);
    const y2 = randomIntegerInclusive(y1 + 1, 4);

    const points = [
      { x: x2, y: y2 },
      { x: x1, y: y1 },
      { x: x2, y: y1 }
    ];

    return { points };
  },
  Component: ({ question, translate }) => {
    const { points } = question;

    const fixedPoints = [points[0]];

    const expectedPoints = points.map(coord => ({ x: -coord.x, y: coord.y }));
    // Remove first point as this is given
    expectedPoints.shift();
    // Remove last point as this is given
    expectedPoints.pop();

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.theDiagramShowsHalfAShapeAndALineOfSymmetryTapTheGridToCompleteTheShape()}
        pdfTitle={translate.ks1PDFInstructions.theDiagramShowsHalfAShapeAndALineOfSymmetryCompleteTheShape()}
        testCorrect={expectedPoints}
        gridChildrenPoints={points}
        fixedPoints={fixedPoints}
        gridVariant="grid"
        symmetryLine={'Y'}
        joinToGridChild
        numPoints={points.length - 2}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bhQ',
  description: 'bhQ',
  keywords: ['Shape', '2-D shape', 'Vertical', 'Symmetry'],
  schema: z.object({
    points: z.array(
      z.object({ x: z.number().int().min(-4).max(0), y: z.number().int().min(1).max(5) })
    )
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['diamond', 'trapezium', 'hexagonA', 'hexagonB'] as const);

    const x1 = randomIntegerInclusive(
      shape === 'trapezium' || shape === 'diamond' ? -4 : -2,
      shape === 'trapezium' ? -2 : -1
    );
    const x2 = 0;
    const x3 = x1 - 1;
    const y1 = randomIntegerInclusive(1, 2);

    const flipped = getRandomBoolean();

    const points = (() => {
      switch (shape) {
        case 'diamond': {
          const y2 = randomIntegerInclusive(y1 + 1, 3);
          const y3 = randomIntegerInclusive(y2 + 1, 4);
          return [
            { x: x2, y: flipped ? y1 : y3 },
            { x: x1, y: y2 },
            { x: x2, y: flipped ? y3 : y1 }
          ];
        }
        case 'trapezium': {
          const y2 = randomIntegerInclusive(y1 + 2, 4);
          return [
            { x: x2, y: flipped ? y1 : y2 },
            { x: randomIntegerInclusive(x1 + 1, -1), y: flipped ? y1 : y2 },
            { x: x1, y: flipped ? y2 : y1 },
            { x: x2, y: flipped ? y2 : y1 }
          ];
        }
        case 'hexagonA': {
          const y2 = randomIntegerInclusive(y1 + 1, 3);
          const y3 = randomIntegerInclusive(y2 + 1, 4);
          const y4 = randomIntegerInclusive(y3 + 1, 5);
          return [
            { x: x2, y: flipped ? y1 : y4 },
            { x: x3, y: flipped ? y2 : y3 },
            { x: x3, y: flipped ? y3 : y2 },
            { x: x2, y: flipped ? y4 : y1 }
          ];
        }
        case 'hexagonB': {
          const y2 = randomIntegerInclusive(y1 + 1, 3);
          const y3 = randomIntegerInclusive(y2 + 1, 4);
          return [
            { x: x2, y: flipped ? y1 : y3 },
            { x: x1, y: flipped ? y1 : y3 },
            { x: x3, y: y2 },
            { x: x3, y: flipped ? y3 : y1 },
            { x: x2, y: flipped ? y3 : y1 }
          ];
        }
      }
    })();

    return { points };
  },
  Component: ({ question, translate }) => {
    const { points } = question;

    const fixedPoints = [points[0]];

    const expectedPoints = points.map(coord => ({ x: -coord.x, y: coord.y }));
    // Remove first point as this is given
    expectedPoints.shift();
    // Remove last point as this is given
    expectedPoints.pop();

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.theDiagramShowsHalfAShapeAndALineOfSymmetryTapTheGridToCompleteTheShape()}
        pdfTitle={translate.ks1PDFInstructions.theDiagramShowsHalfAShapeAndALineOfSymmetryCompleteTheShape()}
        testCorrect={expectedPoints}
        gridChildrenPoints={points}
        fixedPoints={fixedPoints}
        gridVariant="grid"
        symmetryLine={'Y'}
        joinToGridChild
        numPoints={points.length - 2}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseLinesOfSymmetryToCompleteShapes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
