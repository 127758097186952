import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { filledArray, rangeExcluding } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { fluent } from '@codibre/fluent-iterable';
import { roundToTheNearest } from '../../../../utils/math';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aek',
  description: 'aek',
  keywords: ['Place value', 'Round', 'Number line', '100,000'],
  schema: z.object({
    number: z.number().int().min(11000).max(99000).multipleOf(1000)
  }),
  example: {
    number: 52000
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(11000, 99000, 1000, {
      // For a good question, avoid picking a number that's already a multiple of 10,000
      // Note: this constraint was added after launch
      constraint: x => x % 10000 !== 0
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = roundToTheNearest(number, 10000, 'down');
    const endNumber = startingNumber + 10000;
    const tickValues: (number | null)[] = filledArray(null, 11);
    tickValues[0] = startingNumber;
    tickValues[5] = startingNumber + 5000;
    tickValues[10] = endNumber;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest10000(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 10000).toString()]}
        {...props}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ael',
  description: 'ael',
  keywords: ['Place value', 'Round', 'Number line', '100,000'],
  schema: z.object({
    number: z.number().int().min(10001).max(99999)
  }),
  example: {
    number: 44672
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(10001, 99999, {
      // For a good question, avoid picking a number that's already a multiple of 1,000
      // Note: this constraint was added after launch
      constraint: x => x % 1000 !== 0
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = roundToTheNearest(number, 1000, 'down');
    const endNumber = startingNumber + 1000;
    const tickValues: (number | null)[] = filledArray(null, 11);
    tickValues[0] = startingNumber;
    tickValues[5] = startingNumber + 500;
    tickValues[10] = endNumber;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest1000(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 1000).toString()]}
        {...props}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aem',
  description: 'aem',
  keywords: ['Place value', 'Round', '100,000', 'Sort'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(10001).max(99999)).min(6).max(6)
    })
    .refine(
      val => val.numbers.every(number => number % 10000 !== 0),
      'Number cannot be a multiple of 10000'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    //6x numbers >$var and <$var+10000.
    const numbers: number[] = [];
    const tenThousands = randomIntegerInclusive(1, 9) * 10000;
    // 1. Include $var+5000.
    const fiveHundred = tenThousands + 5000;
    numbers.push(fiveHundred);

    // 2. Include one other multiple of 1000.
    const randomMultipleOf1000 =
      getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 1000 + tenThousands;
    numbers.push(randomMultipleOf1000);

    // 3. Include 1 multiple of 100.
    const randomMultipleOf100Thousands = randomIntegerInclusive(0, 9) * 1000;
    const randomMultipleOf100Hundreds =
      getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 100;
    const randomMultipleOf100 =
      tenThousands + randomMultipleOf100Thousands + randomMultipleOf100Hundreds;
    numbers.push(randomMultipleOf100);

    // 4. Include 1 odd multiple of 500.
    const oddMultiple500Thousands = randomIntegerInclusive(0, 9) * 1000;
    const oddMultiple500 = tenThousands + oddMultiple500Thousands + 500;
    numbers.push(oddMultiple500);

    // 5. Include 1 odd multiple of 50.
    const oddMultiple50Thousands = randomIntegerInclusive(0, 9) * 1000;
    const oddMultiple50Hundreds = randomIntegerInclusive(0, 9) * 100;
    const oddMultiple50 = tenThousands + oddMultiple50Thousands + oddMultiple50Hundreds + 50;
    numbers.push(oddMultiple50);

    // 6. Random number
    const randomThousands = randomIntegerInclusive(0, 9) * 1000;
    const randomHundreds = randomIntegerInclusive(0, 9) * 100;
    const randomTens = randomIntegerInclusive(0, 9) * 10;
    const randomOnes = randomIntegerInclusive(1, 9);
    const randomNumber = tenThousands + randomThousands + randomHundreds + randomTens + randomOnes;
    numbers.push(randomNumber);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    // Round down on the left, round up on the right
    const lower = Math.floor(numbers[0] / 10000) * 10000;
    const upper = Math.ceil(numbers[0] / 10000) * 10000;
    const roundsDown: number[] = [];
    const roundsUp: number[] = [];

    numbers.forEach(number => {
      if (roundToTheNearest(number, 10000) === lower) {
        roundsDown.push(number);
      } else {
        roundsUp.push(number);
      }
    });

    const correctOrder = [roundsDown, roundsUp];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.roundToNearestXDragToSort(10000)}
        pdfTitle={translate.instructions.roundToNearestXDragToSortPDF(10000)}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.roundsTo(lower), translate.instructions.roundsTo(upper)]}
        items={numbers}
        actionPanelVariant="bottom"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aen',
  description: 'aen',
  keywords: ['Place value', 'Round', '1,000', '10,000'],
  schema: z.object({
    numbers: z.array(z.number().int().min(10001).max(99999)).length(2)
  }),

  simpleGenerator: () => {
    const numAnswers = 2;
    const numberSet = new Set<number>();
    while (numberSet.size < numAnswers) {
      const tenThousands = randomIntegerInclusive(1, 9);
      const thousands = randomIntegerInclusive(0, 9);
      const hundreds = randomIntegerInclusive(0, 9);
      const tens = randomIntegerInclusive(0, 9);
      const ones = randomIntegerInclusive(1, 9);
      numberSet.add(tenThousands * 10000 + thousands * 1000 + hundreds * 100 + tens * 10 + ones);
    }
    const numbers = fluent(numberSet).toArray();
    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate,
      displayMode
    } = props;
    const nearest10000 = roundToTheNearest(numbers[0], 10000);
    const nearest1000 = roundToTheNearest(numbers[1], 1000);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[[nearest10000.toString()], [nearest1000.toString()]]}
        containerStyle={{ alignItems: 'flex-start' }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentences={[
          translate.answerSentences.roundToNearest10000(numbers[0]),
          translate.answerSentences.roundToNearest1000(numbers[1])
        ]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aeo',
  description: 'aeo',
  keywords: ['Place value', 'Round', '100,000'],
  schema: z.object({
    comparitor: z.number().int().step(100).min(10001).max(99999),
    numbers: z.number().int().min(0).max(100000).array().length(6)
  }),
  example: {
    comparitor: 70000,
    numbers: [63320, 65000, 68079, 72005, 75000, 78080]
  },
  simpleGenerator: () => {
    // Pick the comparitor.
    const comparitor = randomIntegerInclusive(2, 9) * 10000;

    // $var-10001<num1<$var-5000
    const num1 = randomIntegerInclusive(comparitor - 10000, comparitor - 5001);

    // num2=$var-5000
    const num2 = comparitor - 5000;

    // $var-5000<num3<$var
    const num3 = randomIntegerInclusive(comparitor - 4999, comparitor - 1);

    // $var+5000<num1<$var+10001
    const num4 = randomIntegerInclusive(comparitor + 1, comparitor + 4999);

    // num2=$var+5000
    const num5 = comparitor + 5000;

    // $var<num3<$var+5000
    const num6 = randomIntegerInclusive(comparitor + 5001, comparitor + 10000);

    return { comparitor, numbers: shuffle([num1, num2, num3, num4, num5, num6]) };
  },
  Component: props => {
    const {
      question: { comparitor, numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatRoundToNumber(comparitor, 10000)}
        pdfTitle={translate.instructions.circleNumbersThatRoundToNumber(comparitor, 10000)}
        testCorrect={numbers.filter(it => roundToTheNearest(it, 10000) === comparitor)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aep',
  description: 'aep',
  keywords: ['Place value', 'Round', '100,000'],
  schema: z.object({
    number: z.number().int().min(1000).max(99999)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const tenThousands = randomIntegerInclusive(0, 9);
    const thousands = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(0, 9);
    const tens = randomIntegerInclusive(0, 9);
    const ones = randomIntegerInclusive(0, 9);
    const number = tenThousands * 10000 + thousands * 1000 + hundreds * 100 + tens * 10 + ones;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const nearest10 = roundToTheNearest(number, 10);
    const nearest100 = roundToTheNearest(number, 100);
    const nearest1000 = roundToTheNearest(number, 1000);
    const nearest10000 = roundToTheNearest(number, 10000);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundNum(number)}
        testCorrect={[
          [nearest10.toString()],
          [nearest100.toString()],
          [nearest1000.toString()],
          [nearest10000.toString()]
        ]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        sentences={[
          translate.answerSentences.roundToNearest10Equation(number),
          translate.answerSentences.roundToNearest100Equation(number),
          translate.answerSentences.roundToNearest1000Equation(number),
          translate.answerSentences.roundToNearest10000Equation(number)
        ]}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundWithin100000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
