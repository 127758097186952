import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LineGraphColors } from '../../../../theme/colors';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import { all, create, number } from 'mathjs';
import { SvgName } from '../../../../assets/svg';
import { arraysHaveSameContents } from '../../../../utils/collections';
import { MeasureObjectName, measureObjectWithArticle } from '../../../../utils/objects';
import QF43DraggableLineOnTopOfStaticRuler from '../../../../components/question/questionFormats/QF43DraggableLineOnTopOfStaticRuler';
import { isInRangeExclusive } from '../../../../utils/matchers';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aGM',
  description: 'aGM',
  keywords: ['Measure', 'Length', 'Centimetres', 'Millimetres'],
  schema: z.object({
    lengthCm: z.number().int().min(1).max(14),
    lengthMm: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => ({
    lengthCm: randomIntegerInclusive(1, 14),
    lengthMm: randomIntegerInclusive(2, 8)
  }),
  Component: props => {
    const {
      question: { lengthCm, lengthMm },
      translate
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const acceptAbleMmAnswers = [
      lengthMm.toString(),
      (lengthMm + 1).toString(),
      (lengthMm - 1).toString()
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheLine()}
        inputMaxCharacters={2}
        sentence={translate.answerSentences.ansCmAndAnsMm()}
        testCorrect={userAnswer =>
          userAnswer[0] === lengthCm.toString() && acceptAbleMmAnswers.includes(userAnswer[1])
        }
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={15}
            items={[
              { length: number(math.evaluate(`${lengthCm} + (${lengthMm}/10)`)), lineColor: color }
            ]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [lengthCm.toLocaleString(), lengthMm.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGN',
  description: 'aGN',
  keywords: ['Measure', 'Length', 'Centimetres', 'Millimetres'],
  schema: z.object({
    lengthCm: z.number().int().min(1).max(7),
    lengthMm: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => ({
    lengthCm: randomIntegerInclusive(1, 7),
    lengthMm: randomIntegerInclusive(2, 8)
  }),
  Component: props => {
    const {
      question: { lengthCm, lengthMm },
      translate
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const acceptAbleMmAnswers = [
      lengthMm.toString(),
      (lengthMm + 1).toString(),
      (lengthMm - 1).toString()
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheLine()}
        sentence={translate.answerSentences.ansCmAndAnsMm()}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          userAnswer[0] === lengthCm.toString() && acceptAbleMmAnswers.includes(userAnswer[1])
        }
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            vertical
            rulerKind="mm"
            rulerLength={lengthCm < 5 ? 50 : 80}
            items={[
              {
                length: number(math.evaluate(`(${lengthCm} * 10 + ${lengthMm})`)),
                lineColor: color
              }
            ]}
          />
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [lengthCm.toLocaleString(), lengthMm.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aGO',
  description: 'aGO',
  keywords: ['Measure', 'Length', 'Centimetres', 'Millimetres'],
  schema: z.object({
    lengthCm: z.number().int().min(1).max(14),
    lengthMm: z.number().int().min(2).max(8),
    angle: z.number().int().min(10).max(35).step(5),
    isLeftPlane: z.boolean()
  }),
  simpleGenerator: () => ({
    lengthCm: randomIntegerInclusive(1, 14),
    lengthMm: randomIntegerInclusive(2, 8),
    angle: randomIntegerInclusiveStep(10, 35, 5),
    isLeftPlane: getRandomBoolean()
  }),
  Component: props => {
    const {
      question: { lengthCm, lengthMm, angle, isLeftPlane },
      translate
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const acceptAblemMAnswers = [
      lengthMm.toString(),
      (lengthMm + 1).toString(),
      (lengthMm - 1).toString()
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfTheLine()}
        inputMaxCharacters={2}
        sentence={translate.answerSentences.ansCmAndAnsMm()}
        testCorrect={userAnswer =>
          userAnswer[0] === lengthCm.toString() && acceptAblemMAnswers.includes(userAnswer[1])
        }
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rotation={isLeftPlane ? -angle : angle}
            rulerKind="cm"
            rulerLength={15}
            items={[
              { length: number(math.evaluate(`${lengthCm} + (${lengthMm}/10)`)), lineColor: color }
            ]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [lengthCm.toString(), lengthMm.toString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aGP2',
  description: 'aGP',
  keywords: ['Measure', 'Length', 'Centimetres', 'Millimetres'],
  schema: z.object({
    lengthItemCm: z.number().int().min(5).max(14),
    lengthItemMm: z.number().int().min(2).max(8),
    lengthOfRuler: z.number().int().min(2).max(50),
    object: z.enum(['pencil', 'toy_car'])
  }),
  simpleGenerator: () => {
    const lengthItemCm = randomIntegerInclusive(5, 14);
    const lengthItemMm = randomIntegerInclusive(2, 8);
    const lengthOfRuler = randomIntegerInclusive(lengthItemCm + 1, 15);
    const object = getRandomFromArray(['pencil', 'toy_car'] as const);

    return { lengthItemCm, lengthItemMm, object, lengthOfRuler };
  },
  Component: ({ question, translate }) => {
    const { lengthItemCm, lengthItemMm, object, lengthOfRuler } = question;

    const itemObject: { svg: SvgName; name: MeasureObjectName } = (() => {
      switch (object) {
        case 'pencil':
          return {
            svg: getRandomFromArray(
              ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
              {
                random: seededRandom(question)
              }
            ),
            name: 'pencil'
          };
        case 'toy_car':
          return { svg: 'Car', name: 'toy_car' };
      }
    })();

    const acceptAbleMmAnswers = [
      lengthItemMm.toString(),
      (lengthItemMm + 1).toString(),
      (lengthItemMm - 1).toString()
    ];

    const theObject = measureObjectWithArticle(itemObject.name, translate);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfXInCentimetersAndMillimeters(theObject)}
        sentence={translate.answerSentences.ansCmAndAnsMm()}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          userAnswer[0] === lengthItemCm.toString() && acceptAbleMmAnswers.includes(userAnswer[1])
        }
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={lengthOfRuler}
            items={[
              {
                length: number(math.evaluate(`${lengthItemCm} + (${lengthItemMm}/10)`)),
                svgInfo: { name: itemObject.svg },
                guidelines: true
              }
            ]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [lengthItemCm.toLocaleString(), lengthItemMm.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aGP',
  description: 'aGP',
  keywords: ['Measure', 'Length', 'Centimetres', 'Millimetres'],
  schema: z.object({
    lengthItemCm: z.number().int().min(5).max(14),
    lengthItemMm: z.number().int().min(2).max(8),
    lengthOfRuler: z.number().int().min(2).max(50),
    object: z.enum(['pencil', 'toy_car', 'crayon'])
  }),
  simpleGenerator: () => {
    const lengthItemCm = randomIntegerInclusive(5, 14);
    const lengthItemMm = randomIntegerInclusive(2, 8);
    const lengthOfRuler = randomIntegerInclusive(lengthItemCm + 1, 15);
    const object = getRandomFromArray(['pencil', 'toy_car', 'crayon'] as const);

    return { lengthItemCm, lengthItemMm, object, lengthOfRuler };
  },
  Component: ({ question, translate }) => {
    const { lengthItemCm, lengthItemMm, object, lengthOfRuler } = question;

    const itemObject: { svg: SvgName; name: MeasureObjectName } = (() => {
      switch (object) {
        case 'pencil':
          return {
            svg: getRandomFromArray(
              ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
              {
                random: seededRandom(question)
              }
            ),
            name: 'pencil'
          };
        case 'toy_car':
          return { svg: 'Car', name: 'toy_car' };
        case 'crayon':
          return {
            svg: getRandomFromArray(
              [
                'Base_Ten/Crayons1_blue',
                'Base_Ten/Crayons1_green',
                'Base_Ten/Crayons1_lightblue',
                'Base_Ten/Crayons1_lime',
                'Base_Ten/Crayons1_orange',
                'Base_Ten/Crayons1_lime',
                'Base_Ten/Crayons1_purple',
                'Base_Ten/Crayons1_red',
                'Base_Ten/Crayons1_white',
                'Base_Ten/Crayons1_yellow'
              ] as const,
              {
                random: seededRandom(question)
              }
            ),
            name: 'crayon'
          };
        default:
          return { svg: 'Car', name: 'toy_car' };
      }
    })();

    const acceptAbleMmAnswers = [
      lengthItemMm.toString(),
      (lengthItemMm + 1).toString(),
      (lengthItemMm - 1).toString()
    ];

    const theObject = measureObjectWithArticle(itemObject.name, translate);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheLengthOfXInCentimetersAndMillimeters(theObject)}
        sentence={`<ans/> ${translate.units.cm()} <ans/> ${translate.units.mm()}`}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          userAnswer[0] === lengthItemCm.toString() && acceptAbleMmAnswers.includes(userAnswer[1])
        }
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={lengthOfRuler}
            items={[
              {
                length: number(math.evaluate(`${lengthItemCm} + (${lengthItemMm}/10)`)),
                svgInfo: { name: itemObject.svg },
                guidelines: true
              }
            ]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [lengthItemCm.toLocaleString(), lengthItemMm.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aGQ2',
  description: 'aGQ2',
  keywords: ['Centimetres', 'Millimetres', 'Draw', 'Length'],
  schema: z.object({
    lengthCm: z.number().int().min(1).max(14),
    lengthMm: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const lengthCm = randomIntegerInclusive(1, 14);
    const lengthMm = randomIntegerInclusive(1, 9);

    return { lengthCm, lengthMm };
  },
  Component: props => {
    const {
      question: { lengthCm, lengthMm },
      translate
    } = props;

    const answer = number(math.evaluate(`${lengthCm} + ${lengthMm / 10}`));

    return (
      <QF43DraggableLineOnTopOfStaticRuler
        title={translate.instructions.dragPencilToDrawLineThatIsXCmAndYMmLong(lengthCm, lengthMm)}
        pdfTitle={translate.instructions.dragPencilToDrawLineThatIsXCmAndYMmLongPdf(
          lengthCm,
          lengthMm
        )}
        rulerKind="cm"
        rulerLength={15}
        testCorrect={answer}
        snapToNearest={0.1}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aGQ',
  description: 'aGQ',
  keywords: ['Measure', 'Length', 'Centimetres', 'Millimetres'],
  schema: z
    .object({
      lengthAMm: z.number().int().min(15).max(89),
      lengthBMm: z.number().int().min(15).max(89),
      gapLengthMm: z.number().int().min(12).max(49)
    })
    .refine(
      val => val.lengthAMm + val.lengthBMm + val.gapLengthMm <= 120,
      'lengthAMm + lengthBMm + gapLengthMm is equal to or less than 120'
    ),
  simpleGenerator: () => {
    const { lengthAMm, lengthBMm, gapLengthMm } = rejectionSample(
      () => {
        const lengthAMm = randomIntegerInclusive(15, 89);
        const lengthBMm = randomIntegerInclusive(15, 89);
        const gapLengthMm = randomIntegerInclusive(12, 49);
        return { lengthAMm, lengthBMm, gapLengthMm };
      },
      // Only permit them if lengthAMm + lengthBMm + gapLengthMm is equal to or less than 120.
      ({ lengthAMm, lengthBMm, gapLengthMm }) => lengthAMm + lengthBMm + gapLengthMm <= 120
    );

    return { lengthAMm, lengthBMm, gapLengthMm };
  },
  Component: props => {
    const {
      question: { lengthAMm, lengthBMm, gapLengthMm },
      translate
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const acceptableAnswers = [
      lengthAMm + lengthBMm,
      lengthAMm + (lengthBMm + 1),
      lengthAMm + (lengthBMm + 2),
      lengthAMm + (lengthBMm - 1),
      lengthAMm + (lengthBMm - 2)
    ].map(num => {
      return [Math.floor(num / 10).toString(), num.toString().slice(-1)];
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.findTheSumOfTheLengthsOfLineAAndLineB()}
        inputMaxCharacters={2}
        sentence={`<ans/> ${translate.units.cm()} <ans/> ${translate.units.mm()}`}
        testCorrect={userAnswer =>
          acceptableAnswers.some(answer => arraysHaveSameContents(answer, userAnswer))
        }
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={12}
            items={[
              { length: lengthAMm / 10, lineColor: color },
              { length: lengthBMm / 10, lineColor: color, start: lengthAMm / 10 + gapLengthMm / 10 }
            ]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [acceptableAnswers[0][0], acceptableAnswers[0][1]],
          answerText: translate.markScheme.allowXMmMarginOfError(2)
        }}
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aGR2',
  description: 'aGR',
  keywords: ['Length', 'Draw', 'Centimetres', 'Millimetres'],
  schema: z
    .object({
      // For each of these, 0 is a special value which means "don't display this"
      lowerCm: z.number().int().min(0).max(15),
      lowerMm: z.number().int().min(0).max(150),
      upperCm: z.number().int().min(0).max(15),
      upperMm: z.number().int().min(0).max(150)
    })
    .refine(
      ({ lowerCm, lowerMm, upperCm, upperMm }) =>
        upperCm * 10 + upperMm - lowerCm * 10 + lowerMm >= 2,
      'difference between lower and upper bounds must be at least 2mm'
    )
    .refine(
      ({ lowerCm, lowerMm }) => lowerCm * 10 + lowerMm > 0,
      'lower bound must be more than 0cm'
    )
    .refine(
      ({ upperCm, upperMm }) => upperCm * 10 + upperMm <= 150,
      'upper bound must be less than or equal to 15cm'
    ),
  simpleGenerator: () => {
    const measurementA = getRandomFromArray(['cm', 'mm', 'both'] as const);
    const measurementB = getRandomFromArray(['cm', 'mm', 'both'] as const);

    let lowerCm: number, lowerMm: number, upperCm: number, upperMm: number;

    switch (`${measurementA}_${measurementB}` as `${typeof measurementA}_${typeof measurementB}`) {
      case 'cm_cm':
        lowerCm = randomIntegerInclusive(1, 14);
        lowerMm = 0;
        upperCm = lowerCm + 1;
        upperMm = 0;
        break;
      case 'cm_mm':
        lowerCm = randomIntegerInclusive(1, 12);
        lowerMm = 0;
        upperCm = 0;
        upperMm = lowerCm * 10 + randomIntegerInclusiveStep(10, 25, 5);
        break;
      case 'cm_both':
        lowerCm = randomIntegerInclusive(1, 13);
        lowerMm = 0;
        upperCm = randomIntegerInclusive(lowerCm + 1, 14, { constraint: x => x - lowerCm <= 5 });
        upperMm = randomIntegerInclusive(1, 9);
        break;
      case 'mm_cm':
        lowerCm = 0;
        lowerMm = randomIntegerInclusive(10, 60);
        upperCm = randomIntegerInclusive(lowerMm / 10 + 1, 14);
        upperMm = 0;
        break;
      case 'mm_mm':
        lowerCm = 0;
        lowerMm = randomIntegerInclusive(10, 70);
        upperCm = 0;
        upperMm = lowerMm + randomIntegerInclusiveStep(10, 25, 5);
        break;
      case 'mm_both':
        lowerCm = 0;
        lowerMm = randomIntegerInclusive(10, 70);
        upperCm = randomIntegerInclusive(lowerMm / 10 + 1, 14, {
          constraint: x => x - lowerMm / 10 <= 5
        });
        upperMm = randomIntegerInclusive(1, 9);
        break;
      case 'both_cm':
        lowerCm = randomIntegerInclusive(1, 13);
        lowerMm = randomIntegerInclusive(1, 8);
        upperCm = lowerCm + 1;
        upperMm = 0;
        break;
      case 'both_mm':
        lowerCm = randomIntegerInclusive(1, 12);
        lowerMm = randomIntegerInclusive(1, 8);
        upperCm = 0;
        upperMm = lowerCm * 10 + randomIntegerInclusiveStep(10, 25, 5);
        break;
      case 'both_both':
        lowerCm = randomIntegerInclusive(1, 13);
        lowerMm = randomIntegerInclusive(1, 9);
        upperCm = randomIntegerInclusive(lowerCm + 1, 14, { constraint: x => x - lowerCm <= 5 });
        upperMm = randomIntegerInclusive(1, 9);
        break;
    }

    return { lowerCm, lowerMm, upperCm, upperMm };
  },
  Component: props => {
    const {
      question: { lowerCm, lowerMm, upperCm, upperMm },
      translate
    } = props;

    // Calculate bounds in cm
    const lowerBound = number(math.evaluate(`${lowerCm} + ${lowerMm / 10}`));
    const upperBound = number(math.evaluate(`${upperCm} + ${upperMm / 10}`));

    // Get display strings
    let lowerDisplayText;
    if (lowerCm && lowerMm) {
      lowerDisplayText = translate.units.numberOfCmAndMm(lowerCm, lowerMm);
    } else if (lowerCm && !lowerMm) {
      lowerDisplayText = translate.units.numberOfCm(lowerCm);
    } else if (!lowerCm && lowerMm) {
      lowerDisplayText = translate.units.numberOfMm(lowerMm);
    } else {
      throw new Error('Neither lowerCm or lowerMm was > 0');
    }

    let upperDisplayText;
    if (upperCm && upperMm) {
      upperDisplayText = translate.units.numberOfCmAndMm(upperCm, upperMm);
    } else if (upperCm && !upperMm) {
      upperDisplayText = translate.units.numberOfCm(upperCm);
    } else if (!upperCm && upperMm) {
      upperDisplayText = translate.units.numberOfMm(upperMm);
    } else {
      throw new Error('Neither upperCm or upperMm was > 0');
    }

    return (
      <QF43DraggableLineOnTopOfStaticRuler
        title={translate.instructions.dragPencilToDrawLineLongerThanXButShorterThanY(
          lowerDisplayText,
          upperDisplayText
        )}
        pdfTitle={translate.instructions.dragPencilToDrawLineLongerThanXButShorterThanYPdf(
          lowerDisplayText,
          upperDisplayText
        )}
        rulerKind="cm"
        rulerLength={15}
        testCorrect={isInRangeExclusive(lowerBound, upperBound)}
        snapToNearest={0.1}
        customMarkSchemeAnswer={{
          answerToDisplay:
            randomIntegerInclusive(lowerBound * 10 + 1, upperBound * 10 - 1, {
              random: seededRandom(props.question)
            }) / 10,
          answerText: translate.markScheme.acceptAnyValidAnswerInRangeExclusive(
            translate.units.numberOfCm(lowerBound),
            translate.units.numberOfCm(upperBound)
          )
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aGR',
  description: 'aGR',
  keywords: ['Measure', 'Length', 'Centimetres', 'Millimetres'],
  schema: z
    .object({
      lengthAMm: z.number().int().min(15).max(89),
      lengthBMm: z.number().int().min(15).max(89),
      gapLengthMm: z.number().int().min(12).max(49)
    })
    .refine(
      val => val.lengthAMm + val.lengthBMm + val.gapLengthMm <= 120,
      'lengthAMm + lengthBMm + gapLengthMm is equal to or less than 120'
    ),
  simpleGenerator: () => {
    const { lengthAMm, lengthBMm, gapLengthMm } = rejectionSample(
      () => {
        const lengthAMm = randomIntegerInclusive(15, 89);
        const lengthBMm = randomIntegerInclusive(15, 89);
        const gapLengthMm = randomIntegerInclusive(12, 49);
        return { lengthAMm, lengthBMm, gapLengthMm };
      },
      // Only permit them if lengthAMm + lengthBMm + gapLengthMm is equal to or less than 120.
      ({ lengthAMm, lengthBMm, gapLengthMm }) => lengthAMm + lengthBMm + gapLengthMm <= 120
    );

    return { lengthAMm, lengthBMm, gapLengthMm };
  },
  Component: props => {
    const {
      question: { lengthAMm, lengthBMm, gapLengthMm },
      translate
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const acceptableAnswers = [
      Math.abs(lengthAMm - lengthBMm),
      Math.abs(lengthAMm - (lengthBMm + 1)),
      Math.abs(lengthAMm - (lengthBMm + 2)),
      Math.abs(lengthAMm - (lengthBMm - 1)),
      Math.abs(lengthAMm - (lengthBMm - 2))
    ].map(num => {
      return [Math.floor(num / 10).toString(), num.toString().slice(-1)];
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.findTheDifferenceOfTheLengthsOfLineAAndLineB()}
        inputMaxCharacters={2}
        sentence={`<ans/> ${translate.units.cm()} <ans/> ${translate.units.mm()}`}
        testCorrect={userAnswer =>
          acceptableAnswers.some(answer => arraysHaveSameContents(answer, userAnswer))
        }
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={12}
            items={[
              { length: lengthAMm / 10, lineColor: color },
              { length: lengthBMm / 10, lineColor: color, start: lengthAMm / 10 + gapLengthMm / 10 }
            ]}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [acceptableAnswers[0][0], acceptableAnswers[0][1]],
          answerText: translate.markScheme.allowXMmMarginOfError(2)
        }}
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInCentimetresAndMillimetres',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6v2],
  archivedQuestionTypes: [Question4, Question5, Question6]
});
export default SmallStep;
