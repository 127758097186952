import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { numberToTally } from '../../../../components/question/representations/Tallies';
import QF7aInteractiveTallyChart from '../../../../components/question/questionFormats/QF7aInteractiveTallyChart';
import { arrayHasNoDuplicates } from '../../../../utils/collections';

////
// Questions
////

const lowerCaseColors = [
  'Red',
  'Blue',
  'Green',
  'Orange',
  'Pink',
  'Yellow',
  'Grey',
  'Black',
  'White',
  'Purple'
] as const;
export type LowerCaseColor = (typeof lowerCaseColors)[number];

const Question1 = newQuestionContent({
  uid: 'av8',
  description: 'av8',
  keywords: ['Tally', 'Collect', 'Represent', 'Data'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: z.number().int().min(1).max(5),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(1, 5, 2);
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);

    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), `<ans/>`, number1.toLocaleString()],
      [translate.colors[lowerCase[1]](), `<ans/>`, number2.toLocaleString()]
    ];

    return (
      <QF7aInteractiveTallyChart
        title={translate.instructions.dragCardsCompleteTallyChart()}
        pdfTitle={translate.instructions.completeTallyChart()}
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        testCorrect={[number1, number2]}
        useLegacyTallyCheck
        items={[1, 2, 3, 4, 5]}
        capacity={2}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'av82',
  description: 'av8',
  keywords: ['Tally', 'Collect', 'Represent', 'Data'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: z.number().int().min(1).max(5),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(1, 5, 2);
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);

    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), `<ans/>`, number1.toLocaleString()],
      [translate.colors[lowerCase[1]](), `<ans/>`, number2.toLocaleString()]
    ];

    return (
      <QF7aInteractiveTallyChart
        title={translate.instructions.dragCardsCompleteTallyChart()}
        pdfTitle={translate.instructions.completeTallyChart()}
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        testCorrect={[number1, number2]}
        items={[1, 2, 3, 4, 5]}
        capacity={2}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'av9',
  description: 'av9',
  keywords: ['Tally', 'Collect', 'Represent', 'Data'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(5).max(10),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 10);
    const number2 = randomIntegerInclusive(5, 10, { constraint: x => x !== number1 });
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);
    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), `<ans/>`, number1.toLocaleString()],
      [translate.colors[lowerCase[1]](), `<ans/>`, number2.toLocaleString()]
    ];

    return (
      <QF7aInteractiveTallyChart
        title={translate.instructions.dragCardsCompleteTallyChart()}
        pdfTitle={translate.instructions.completeTallyChart()}
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        useLegacyTallyCheck
        testCorrect={[number1, number2]}
        items={[1, 2, 3, 4, 5]}
        capacity={2}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'av92',
  description: 'av9',
  keywords: ['Tally', 'Collect', 'Represent', 'Data'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(5).max(10),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 10);
    const number2 = randomIntegerInclusive(5, 10, { constraint: x => x !== number1 });
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);
    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), `<ans/>`, number1.toLocaleString()],
      [translate.colors[lowerCase[1]](), `<ans/>`, number2.toLocaleString()]
    ];

    return (
      <QF7aInteractiveTallyChart
        title={translate.instructions.dragCardsCompleteTallyChart()}
        pdfTitle={translate.instructions.completeTallyChart()}
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        testCorrect={[number1, number2]}
        items={[1, 2, 3, 4, 5]}
        capacity={2}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'awa',
  description: 'awa',
  keywords: ['Tally', 'Collect', 'Represent', 'Data'],
  schema: z.object({
    number1: z.number().int().min(10).max(20),
    number2: z.number().int().min(15).max(20),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 20);
    const number2 = randomIntegerInclusive(15, 20, { constraint: x => x !== number1 });
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);
    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), `<ans/>`, number1.toLocaleString()],
      [translate.colors[lowerCase[1]](), `<ans/>`, number2.toLocaleString()]
    ];

    return (
      <QF7aInteractiveTallyChart
        title={translate.instructions.dragCardsCompleteTallyChart()}
        pdfTitle={translate.instructions.completeTallyChart()}
        moveOrCopy="copy"
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        useLegacyTallyCheck
        testCorrect={[number1, number2]}
        items={[1, 2, 3, 4, 5]}
        columnFlexValues={[1, 3, 1]}
        capacity={4}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'awa2',
  description: 'awa',
  keywords: ['Tally', 'Collect', 'Represent', 'Data'],
  schema: z.object({
    number1: z.number().int().min(10).max(20),
    number2: z.number().int().min(15).max(20),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 20);
    const number2 = randomIntegerInclusive(15, 20, { constraint: x => x !== number1 });
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);
    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), `<ans/>`, number1.toLocaleString()],
      [translate.colors[lowerCase[1]](), `<ans/>`, number2.toLocaleString()]
    ];

    return (
      <QF7aInteractiveTallyChart
        title={translate.instructions.dragCardsCompleteTallyChart()}
        pdfTitle={translate.instructions.completeTallyChart()}
        moveOrCopy="copy"
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        testCorrect={[number1, number2]}
        items={[1, 2, 3, 4, 5]}
        columnFlexValues={[1, 3, 1]}
        capacity={4}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awb',
  description: 'awb',
  keywords: ['Tally', 'Collect', 'Represent', 'Data', 'Total'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: z.number().int().min(1).max(5),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(1, 5, 2);
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);
    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), numberToTally(number1), '<ans/>'],
      [translate.colors[lowerCase[1]](), numberToTally(number2), '<ans/>']
    ];

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTallyChart()}
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        tableCellStyleIdx={[1]}
        tableCellStyle={{ justifyContent: 'flex-start' }}
        testCorrect={[number1.toString(), number2.toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'awc',
  description: 'awc',
  keywords: ['Tally', 'Collect', 'Represent', 'Data', 'Total'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(5).max(10),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 10);
    const number2 = randomIntegerInclusive(5, 10, { constraint: x => x !== number1 });
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);
    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), numberToTally(number1), '<ans/>'],
      [translate.colors[lowerCase[1]](), numberToTally(number2), '<ans/>']
    ];

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTallyChart()}
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        tableCellStyleIdx={[1]}
        tableCellStyle={{ justifyContent: 'flex-start' }}
        testCorrect={[number1.toString(), number2.toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'awd',
  description: 'awd',
  keywords: ['Tally', 'Collect', 'Represent', 'Data', 'Total'],
  schema: z.object({
    number1: z.number().int().min(10).max(20),
    number2: z.number().int().min(15).max(20),
    colors: z
      .array(
        z.enum([
          'Red',
          'Blue',
          'Green',
          'Orange',
          'Pink',
          'Yellow',
          'Grey',
          'Black',
          'White',
          'Purple'
        ])
      )
      .length(2)
      .refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 20);
    const number2 = randomIntegerInclusive(15, 20, { constraint: x => x !== number1 });
    const colors = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    const lowerCase = colors.map(val => val.toLowerCase() as LowerCaseColor);
    // Table data
    const data = [
      [translate.colors[lowerCase[0]](), numberToTally(number1), '<ans/>'],
      [translate.colors[lowerCase[1]](), numberToTally(number2), '<ans/>']
    ];

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTallyChart()}
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        tableCellStyleIdx={[1]}
        tableCellStyle={{ justifyContent: 'flex-start' }}
        testCorrect={[number1.toString(), number2.toString()]}
        columnFlexValues={[1, 3, 1]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CollectAndRepresentData',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
