import z from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getItemArrangement,
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange,
  range
} from '../../../../utils/collections';
import { MULT } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import Text from '../../../../components/typography/Text';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import { getGroupSvgName } from '../../../../utils/objectsImages';
import { AssetSvg } from '../../../../assets/svg';
import { displayMoney } from '../../../../utils/money';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjj',
  description: 'bjj',
  keywords: ['Multiply', '5 times-table'],
  schema: z
    .object({
      groups: z.number().int().min(1).max(12),
      item: z.enum([
        'Apple',
        'Pear',
        'Cookie',
        'Muffin',
        '5p',
        '£5',
        'Dice',
        'Donut',
        'Egg',
        'Pencil',
        'Fish',
        'Flower',
        'Hand'
      ]),
      answerOptions: z.array(z.number().int()).length(4),
      groupsFirst: z.boolean(),
      isRandomArrangement: z.boolean()
    })
    .refine(val => arrayHasNoDuplicates(val.answerOptions), 'must be no duplicate options'),
  simpleGenerator: () => {
    const item = getRandomFromArray([
      'Apple',
      'Pear',
      'Cookie',
      'Muffin',
      '5p',
      '£5',
      'Dice',
      'Donut',
      'Egg',
      'Pencil',
      'Fish',
      'Flower',
      'Hand'
    ] as const);

    const { groups, uniqueAnswers } = rejectionSample(
      () => {
        const groups = randomIntegerInclusive(1, 12);

        const answer = 5 * groups;
        const incorrectAnswers = [
          groups + 5,
          5,
          groups - 1,
          groups + 1,
          answer + 5,
          answer - 5
        ].filter(val => val > 0);

        const uniqueAnswers = [...new Set([answer, ...incorrectAnswers])];

        return { groups, uniqueAnswers };
      },
      val => val.uniqueAnswers.length >= 4
    );

    const incorrectAnswers = getRandomSubArrayFromArray(
      [...uniqueAnswers.filter(val => val !== 5 * groups && val !== groups)],
      2
    );
    const groupsFirst = getRandomBoolean();
    const isRandomArrangement = getRandomBoolean();

    return {
      groups,
      item,
      answerOptions: shuffle([5 * groups, groups, ...incorrectAnswers]),
      groupsFirst,
      isRandomArrangement
    };
  },
  Component: props => {
    const {
      question: { groups, item, answerOptions, groupsFirst, isRandomArrangement },
      translate
    } = props;

    const sentence = groupsFirst
      ? `<ans/> ${MULT} ${item === '£5' ? '£' : ''}${(5).toLocaleString()}${
          item === '5p' ? 'p' : ''
        } = <ans/>`
      : `${item === '£5' ? '£' : ''}${(5).toLocaleString()}${
          item === '5p' ? 'p' : ''
        } ${MULT} <ans/> = <ans/>`;

    const svgName = (() => {
      switch (item) {
        case 'Dice':
          return 'Dice_faces/5';
        case 'Apple':
        case 'Cookie':
        case 'Donut':
        case 'Egg':
        case 'Fish':
        case 'Flower':
        case 'Muffin':
        case 'Pear':
        case 'Pencil':
          return getGroupSvgName(item, 5);
        // using default here to keep the type as SVG name. We will handle the money separately
        default:
          return 'Hand_open_fingers';
      }
    })();

    const random = seededRandom(props.question);

    const arrangement =
      groups === 1
        ? []
        : getItemArrangement(
            countRange(groups).map(i => i),
            random,
            2,
            6
          );

    return (
      <QF36ContentAndSentenceDrag
        title={`${translate.ks1Instructions.thePictureShowsAMultiplication()}<br/>${translate.ks1Instructions.dragCardsToCompleteTheMultiplication()}`}
        pdfTitle={`${translate.ks1Instructions.thePictureShowsAMultiplication()}<br/>${translate.ks1PDFInstructions.useCardsCompleteMultiplication()}`}
        sentence={sentence}
        testCorrect={[groups, groups * 5]}
        pdfLayout="itemsTop"
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-start' }}
        Content={({ dimens }) =>
          isRandomArrangement && groups !== 1 ? (
            <View style={{ rowGap: 20 }}>
              {arrangement.map((row, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: dimens.height / 3,
                    width: dimens.width
                  }}
                >
                  {row.map(({ marginLeft }, itemIndex) => (
                    <View key={itemIndex} style={{ marginLeft, gap: 16, justifyContent: 'center' }}>
                      {item === '5p' || item === '£5' ? (
                        displayMoney([item], dimens.width / 14, dimens.height * 0.35, true)
                      ) : (
                        <AssetSvg
                          name={svgName}
                          width={dimens.width / 7}
                          height={dimens.height / 3}
                        />
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          ) : (
            <View style={{ flexDirection: 'column', gap: 25 }}>
              <View
                style={{
                  flexDirection: 'column',
                  gap: 15
                }}
              >
                {countRange(groups > 6 ? 2 : 1).map(rowId => {
                  return (
                    <View key={rowId} style={{ flexDirection: 'row', gap: 15 }}>
                      {countRange(Math.min(groups - rowId * 6, 6)).map(i => {
                        return item !== '5p' && item !== '£5' ? (
                          <AssetSvg
                            key={i}
                            name={svgName}
                            width={dimens.width / 7}
                            height={dimens.height * 0.35}
                          />
                        ) : (
                          displayMoney([item], dimens.width / 14, dimens.height * 0.35, true)
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            </View>
          )
        }
        questionHeight={1000}
        items={answerOptions.map(value => ({
          value,
          component: <Text variant="WRN700">{value.toLocaleString()}</Text>
        }))}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bjk',
  description: 'bjk',
  keywords: ['Number track', 'Multiplication', 'Count', 'Forwards', 'Backwards', '5 times-table'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(25).step(5),
    givenIndexes: z
      .array(z.number().min(0).max(7))
      .refine(arrayHasNoDuplicates)
      .refine(val =>
        val.some(
          i => val.includes(i + 1) || val.includes(i - 1),
          'must be at least two consecutive numbers'
        )
      ),
    isForward: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(0, 25, 5);
    const numberOfGiven = randomIntegerInclusive(3, 5);
    const givenIndex1 = randomIntegerInclusive(0, 7);
    const givenIndex2 =
      givenIndex1 === 0
        ? givenIndex1 + 1
        : givenIndex1 === 7
        ? givenIndex1 - 1
        : getRandomFromArray([givenIndex1 + 1, givenIndex1 - 1] as const);
    const otherGivenIndexes = randomUniqueIntegersInclusive(0, 7, numberOfGiven - 2, {
      constraint: x => ![givenIndex1, givenIndex2].includes(x)
    });
    const isForward = getRandomBoolean();
    return {
      startingNumber,
      givenIndexes: [givenIndex1, givenIndex2, ...otherGivenIndexes],
      isForward
    };
  },
  Component: ({ question: { startingNumber, givenIndexes, isForward }, translate }) => {
    // Create array to pass to NumberTrack
    const ascendingNumberArray = range(startingNumber, startingNumber + 35, 5);
    const numberArray = isForward ? ascendingNumberArray : [...ascendingNumberArray].reverse();
    const ansArray = numberArray
      .filter((_val, i) => !givenIndexes.includes(i))
      .map(val => val.toString());
    const stringArray = numberArray.map((val, id) =>
      givenIndexes.includes(id) ? val.toLocaleString() : '<ans/>'
    );
    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bjl',
  description: 'bjl',
  keywords: ['5 times-table', 'Multiply'],
  schema: z
    .object({
      multiplier: z.number().min(0).max(12),
      order: z.array(z.number()).length(2),
      totalFirst: z.boolean(),
      answerIndex: z.number().int().min(0).max(2)
    })
    .refine(val => arraysHaveSameContentsUnordered(val.order, [5, val.multiplier])),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(0, 12);
    const totalFirst = getRandomBoolean();
    const order = shuffle([5, multiplier]);
    const answerIndex = randomIntegerInclusive(0, 2, {
      constraint: x =>
        (multiplier === 0 && (totalFirst ? x !== order.indexOf(5) + 1 : order.indexOf(5) !== x)) ||
        multiplier !== 0
    });
    return {
      multiplier,
      order,
      totalFirst,
      answerIndex
    };
  },
  Component: ({ question: { multiplier, order, totalFirst, answerIndex }, translate }) => {
    const numberArray = totalFirst ? [multiplier * 5, ...order] : [...order, multiplier * 5];
    const stringArray = numberArray.map((val, i) =>
      i === answerIndex ? '<ans/>' : val.toLocaleString()
    );
    const sentence = totalFirst
      ? `${stringArray[0]} = ${stringArray[1]} ${MULT} ${stringArray[2]}`
      : `${stringArray[0]} ${MULT} ${stringArray[1]} = ${stringArray[2]}`;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeMultiplication()}
        questionHeight={600}
        testCorrect={[numberArray[answerIndex].toString()]}
        sentence={sentence}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The5TimesTable',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
