import { useContext } from 'react';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import { TitleStyleProps } from '../../molecules/TitleRow';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayout from '../../molecules/BaseLayout';
import { AlignedEquations, AlignedEquationsWithState } from '../representations/AlignedEquations';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { isEqual } from '../../../utils/matchers';

type Props = TitleStyleProps & {
  title: string | JSX.Element;
  /**
   * Provide either object of left, right array of arrays of correct answers for both sides of the equation
   * Or function that tests the correctness
   */
  testCorrect:
    | { left?: string[][]; right?: string[][] }
    | ((userAnswer: { left: string[][]; right: string[][] }) => boolean);
  /**
   * The width of each text input in characters. If left undefined and testCorrect is given as strings, it will
   * default to the longest correct answer.
   */
  inputMaxCharacters?: number;
  /** Left and ride side sentences, must both be equal lengths */
  leftSide: string[];
  rightSide: string[];
  extraSymbol?: ExtraSymbols;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<ViewStyle>;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answersToDisplay?: { left?: string[][]; right?: string[][] };
    answerText?: string;
  };
  noEquals?: boolean;
  leftColumnStyle?: StyleProp<ViewStyle>;
  rightColumnStyle?: StyleProp<ViewStyle>;
  middleCharacterStyle?: StyleProp<ViewStyle>;
  mainPanelStyle?: StyleProp<ViewStyle>;
  /**
   * String to align all equations by.
   * Optional prop, defaults to '='.
   */
  middleCharacter?: string;
  /** Default: endWide. */
  actionPanelVariant?: 'endWide' | 'bottomTall';
};

/**
 * Specific layout of QF2 that renders a multi-step mathematical equation.
 * Ensuring that the equals signs align along with the LHS & RHS.
 */
export default function QF2AlignedEquations({
  title,
  inputMaxCharacters: inputMaxCharactersProp,
  leftSide,
  rightSide,
  testCorrect: testCorrectProp,
  extraSymbol,
  questionHeight,
  customMarkSchemeAnswer,
  textStyle,
  fractionTextStyle,
  sentenceStyle,
  noEquals = false,
  leftColumnStyle,
  rightColumnStyle,
  middleCharacter = '=',
  /** Default: endWide. */
  actionPanelVariant = 'endWide',
  middleCharacterStyle,
  mainPanelStyle
}: Props) {
  const displayMode = useContext(DisplayMode);

  const testCorrect = (userAnswer: { left: string[][]; right: string[][] }) => {
    if (typeof testCorrectProp === 'function') {
      return testCorrectProp(userAnswer);
    } else {
      const leftCorrectness =
        testCorrectProp.left === undefined || isEqual(userAnswer.left)(testCorrectProp.left);
      const rightCorrectness =
        testCorrectProp.right === undefined || isEqual(userAnswer.right)(testCorrectProp.right);

      return leftCorrectness && rightCorrectness;
    }
  };

  // Try make the input boxes a suitable width
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (typeof testCorrectProp !== 'function') {
      return Math.max(
        0,
        ...(testCorrectProp.left ?? []).map(it => it.length),
        ...(testCorrectProp.right ?? []).map(it => it.length)
      );
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const emptyAnswer = AlignedEquations.defaultUserAnswer({ leftSide, rightSide });
    const markSchemeAnswer = {
      ...emptyAnswer,
      ...(typeof testCorrectProp !== 'function' && testCorrectProp),
      ...customMarkSchemeAnswer?.answersToDisplay
    };

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <AlignedEquations
              leftSide={leftSide}
              rightSide={rightSide}
              userAnswer={displayMode === 'markscheme' ? markSchemeAnswer : emptyAnswer}
              noEquals={noEquals}
              leftColumnStyle={leftColumnStyle}
              rightColumnStyle={rightColumnStyle}
              middleCharacterStyle={middleCharacterStyle}
              middleCharacter={middleCharacter}
              mainPanelStyle={mainPanelStyle}
            />
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant={actionPanelVariant}
      actionPanelContents={
        <UserInput
          inputType="numpad"
          extraSymbol={extraSymbol}
          variant={actionPanelVariant === 'endWide' ? 'tall' : 'wide'}
        />
      }
      mainPanelContents={
        <AlignedEquationsWithState
          id="aligned-eqs"
          leftSide={leftSide}
          rightSide={rightSide}
          inputMaxCharacters={inputMaxCharacters}
          testCorrect={testCorrect}
          textStyle={textStyle}
          fractionTextStyle={fractionTextStyle}
          sentenceStyle={sentenceStyle}
          noEquals={noEquals}
          leftColumnStyle={leftColumnStyle}
          rightColumnStyle={rightColumnStyle}
          middleCharacterStyle={middleCharacterStyle}
          middleCharacter={middleCharacter}
          mainPanelStyle={mainPanelStyle}
        />
      }
    />
  );
}
