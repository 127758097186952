import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle,
  seededRandom,
  getRandomSubArrayFromArray,
  getRandomFromArray,
  getRandomBoolean
} from '../../../../utils/random';
import { ADD, MULT, SUB } from '../../../../constants';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  arrayObjectAsWord,
  arrayObjectSchema,
  getRandomArrayObject
} from '../../../../utils/arrayObjects';
import QF29CreateArray from '../../../../components/question/questionFormats/QF29CreateArray';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import {
  NonEmptyArray,
  arraysHaveSameContentsUnordered,
  countRange,
  filledArray
} from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { numberEnum } from '../../../../utils/zod';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { View } from 'react-native';
import { CreateArrayOfObjects } from '../../../../components/question/representations/CreateArrayOfObjects';
import { isRectangle, getRectangleDimensions, trueCount } from '../../../../utils/shapes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ajK',
  description: 'ajK',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    object: arrayObjectSchema,
    columns: z.number().int().min(2).max(10),
    rows: z.number().int().min(2).max(4)
  }),
  questionHeight: 1440,
  simpleGenerator: () => {
    const object = getRandomArrayObject();

    const columns = randomIntegerInclusive(2, 10);

    const rows = randomIntegerInclusive(2, 4);

    return { object, columns, rows };
  },
  Component: props => {
    const {
      question: { object, columns, rows },
      translate
    } = props;

    const objectString = arrayObjectAsWord(object, translate, true);

    const middleLineAnswerSentence = (() => {
      let finalString = '';
      for (let i = 0; i < rows - 1; i++) {
        finalString += `<ans/> ${ADD} `;
      }
      finalString += '<ans/> = <ans/>';
      return finalString;
    })();

    const middleLineAnswerCheck = (answer: string[]) => {
      for (let i = 0; i < answer.length - 2; i++) {
        if (answer[i] !== columns.toString()) {
          return false;
        }
      }
      if (answer[answer.length - 1] !== (rows * columns).toString()) {
        return false;
      }
      return true;
    };

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsRowsOfAnsObjects(objectString),
          middleLineAnswerSentence,
          `<ans/> ${MULT} <ans/> = <ans/>`
        ]}
        title={translate.instructions.completeSentencesToMatchArray()}
        testCorrect={answer =>
          answer[0][0] === rows.toString() &&
          answer[0][1] === columns.toString() &&
          middleLineAnswerCheck(answer[1]) &&
          ((answer[2][0] === rows.toString() && answer[2][1] === columns.toString()) ||
            (answer[2][0] === columns.toString() && answer[2][1] === rows.toString())) &&
          answer[2][2] === (rows * columns).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} image={object} />
        )}
        pdfDirection="column"
        questionHeight={1440}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'ajK2',
  description: 'ajK',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    object: arrayObjectSchema,
    columns: z.number().int().min(2).max(10),
    rows: z.number().int().min(2).max(4),
    isRows: z.boolean()
  }),
  questionHeight: 1440,
  simpleGenerator: () => {
    const object = getRandomArrayObject();
    const isRows = getRandomBoolean();
    const columns = randomIntegerInclusive(2, isRows ? 10 : 4);
    const rows = randomIntegerInclusive(2, 4);

    return { object, columns, rows, isRows };
  },
  Component: props => {
    const {
      question: { object, columns, rows, isRows },
      translate
    } = props;

    const objectString = arrayObjectAsWord(object, translate, true);

    const amount = isRows ? rows : columns;
    const middleLineAnswerSentence = (() => {
      let finalString = countRange(amount)
        .map(() => '<ans/>')
        .join(` ${ADD} `);
      finalString += ' = <ans/>';
      return finalString;
    })();

    const middleLineAnswerCheck = (answer: string[]) => {
      const ans1 = isRows ? columns : rows;
      for (let i = 0; i < answer.length - 2; i++) {
        if (answer[i] !== ans1.toString()) {
          return false;
        }
      }
      if (answer[answer.length - 1] !== (rows * columns).toString()) {
        return false;
      }
      return true;
    };

    return (
      <QF1ContentAndSentences
        sentences={[
          isRows
            ? translate.answerSentences.thereAreAnsRowsOfAnsObjects(objectString)
            : translate.answerSentences.thereAreAnsColsOfAnsObjects(objectString),
          middleLineAnswerSentence,
          `<ans/> ${MULT} <ans/> = <ans/>`
        ]}
        title={translate.instructions.completeSentencesToMatchArray()}
        testCorrect={answer => {
          const ans1 = isRows ? rows : columns;
          const ans2 = isRows ? columns : rows;
          return (
            answer[0][0] === ans1.toString() &&
            answer[0][1] === ans2.toString() &&
            middleLineAnswerCheck(answer[1]) &&
            arraysHaveSameContentsUnordered(
              [answer[2][0], answer[2][1]],
              [rows.toString(), columns.toString()]
            ) &&
            answer[2][2] === (rows * columns).toString()
          );
        }}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} image={object} />
        )}
        pdfDirection="column"
        questionHeight={1440}
        customMarkSchemeAnswer={{
          answersToDisplay: isRows
            ? [
                [rows.toLocaleString(), columns.toLocaleString()],
                [...filledArray(columns.toLocaleString(), rows), (rows * columns).toLocaleString()],
                [rows.toLocaleString(), columns.toLocaleString(), (rows * columns).toLocaleString()]
              ]
            : [
                [columns.toLocaleString(), rows.toLocaleString()],
                [...filledArray(rows.toLocaleString(), columns), (rows * columns).toLocaleString()],
                [columns.toLocaleString(), rows.toLocaleString(), (rows * columns).toLocaleString()]
              ],
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ajL',
  description: 'ajL',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    columns: z.number().int().min(2).max(10),
    rows: z.number().int().min(2).max(4)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(2, 10);

    const rows = randomIntegerInclusive(2, 4);

    return { columns, rows };
  },
  Component: props => {
    const {
      question: { columns, rows },
      translate
    } = props;

    const firstLineAnswerSentence = (() => {
      let finalString = '';
      for (let i = 0; i < rows - 1; i++) {
        finalString += `<ans/> ${ADD} `;
      }
      finalString += '<ans/> = <ans/>';
      return finalString;
    })();

    const firstLineAnswerCheck = (answer: string[]) => {
      for (let i = 0; i < answer.length - 2; i++) {
        if (answer[i] !== columns.toString()) {
          return false;
        }
      }
      if (answer[answer.length - 1] !== (rows * columns).toString()) {
        return false;
      }
      return true;
    };

    return (
      <QF1ContentAndSentences
        sentences={[firstLineAnswerSentence, `<ans/> ${MULT} <ans/> = <ans/>`]}
        title={translate.instructions.completeSentencesToMatchArray()}
        testCorrect={answer =>
          firstLineAnswerCheck(answer[0]) &&
          ((answer[1][0] === rows.toString() && answer[1][1] === columns.toString()) ||
            (answer[1][0] === columns.toString() && answer[1][1] === rows.toString())) &&
          answer[1][2] === (rows * columns).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} />}
        pdfDirection="column"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ajM',
  description: 'ajM',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    groupA: z.array(z.number().int().min(2).max(8)).length(4),
    groupB: z.array(z.number().int().min(1).max(4)).length(4)
  }),
  simpleGenerator: () => {
    const groupA = randomUniqueIntegersInclusive(2, 8, 4);
    const groupB = randomUniqueIntegersInclusive(1, 4, 4);

    return { groupA, groupB };
  },
  Component: props => {
    const {
      question: { groupA, groupB },
      translate,
      displayMode
    } = props;

    const random = seededRandom(props.question);

    const [A1, A2, A3, A4] = groupA;
    const [B1, B2, B3, B4] = groupB;

    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 100, width: 250 } : { height: 150, width: 450 };

    // Pick 4 unique colours, to prevent duplicate colours
    const [colorA, colorB, colorC, colorD] = getRandomSubArrayFromArray(
      Object.values(ArrayOfObjectsColors) as NonEmptyArray<string>,
      4,
      { random }
    );

    const counterSize = Math.min(
      dimens.height / Math.min(A1, B1),
      dimens.height / Math.min(A2, B2),
      dimens.height / Math.min(A3, B3),
      dimens.height / Math.min(A4, B4)
    );

    const statements = [
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={Math.min(A1, B1)}
              columns={Math.max(A1, B1)}
              color={colorA}
              counterSize={counterSize}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A1} ${MULT} ${B1}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={Math.min(A2, B2)}
              columns={Math.max(A2, B2)}
              color={colorB}
              counterSize={counterSize}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A2} ${MULT} ${B2}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={Math.min(A3, B3)}
              columns={Math.max(A3, B3)}
              color={colorC}
              counterSize={counterSize}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A3} ${MULT} ${B3}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={Math.min(A4, B4)}
              columns={Math.max(A4, B4)}
              color={colorD}
              counterSize={counterSize}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A4} ${MULT} ${B4}`
      }
    ];

    const shuffledStatements = shuffle(statements, { random });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchNumberSentencesToPictures()}
        items={items}
        statementStyle={{ justifyContent: 'center' }}
        statements={shuffledStatements}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ajN',
  description: 'ajN',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = randomIntegerInclusive(2, 6);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const multiplicationSentence = `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`;

    return (
      <QF29CreateArray
        numberOfRows={6}
        numberOfCols={10}
        title={translate.instructions.createArrayToShow(multiplicationSentence)}
        testCorrect={[number1, number2]}
        questionHeight={1000}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.arrayDimensCanBeFlipped() }}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'ajO',
  description: 'ajO',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    number1: z.number().int().min(3).max(10),
    number2: z.number().int().min(2).max(4),
    addOrSubtract: z.enum([ADD, SUB])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 10);
    const number2 = randomIntegerInclusive(2, 4);

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { number1, number2, addOrSubtract };
  },
  Component: props => {
    const {
      question: { number1, number2, addOrSubtract },
      translate
    } = props;

    const random = seededRandom(props.question);

    const arrayWidth = Math.max(number1, number2);
    const arrayHeight = Math.min(number1, number2);

    // Statements to select
    // Always have number1 * number2 & number2 * number1 as options
    let statements = [
      {
        sentence: `${number1} ${MULT} ${number2}`,
        value: 'A',
        correct: true
      },
      {
        sentence: `${number2} ${MULT} ${number1}`,
        value: 'B',
        correct: true
      }
    ];

    // Pick 2 other statements at random to use as options
    const offsetNumber1 = addOrSubtract === ADD ? number1 + 1 : number1 - 1;
    const offsetNumber2 = addOrSubtract === ADD ? number2 + 1 : number2 - 1;

    const extraStatements = [
      {
        sentence: `${offsetNumber1} ${MULT} ${number2}`,
        value: 'C',
        correct: false
      },
      {
        sentence: `${offsetNumber2} ${MULT} ${number1}`,
        value: 'D',
        correct: false
      },
      {
        sentence: filledArray(number1, number2).join(` ${ADD} `),
        value: 'E',
        correct: true
      },
      {
        sentence: filledArray(number2, number2).join(` ${ADD} `),
        value: 'F',
        correct: number1 === number2
      }
    ];

    const statementChoice = getRandomSubArrayFromArray(extraStatements, 2, {
      random
    });
    statements = statements.concat(statementChoice);

    const shuffledStatements = shuffle(statements, { random });

    // Filter correct answers
    const correctAnswers = statements.filter(it => it.correct);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectCalculationsThatMatchArray()}
        pdfTitle={translate.instructions.circleCalculationsThatMatchArray()}
        testCorrect={correctAnswers.map(it => it.value)}
        multiSelect
        numItems={4}
        Content={({ dimens }) => (
          <ArrayOfObjects
            dimens={dimens}
            columns={arrayWidth}
            rows={arrayHeight}
            containerStyle={{ paddingBottom: 32 }}
          />
        )}
        renderItems={shuffledStatements.map(({ sentence, value }) => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {sentence}
            </Text>
          )
        }))}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'ajP',
  description: 'ajP',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    number: numberEnum([6, 8, 9, 10, 12, 16, 18, 20])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number = getRandomFromArray([6, 8, 9, 10, 12, 16, 18, 20] as const);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    const givenArrayWidth = number < 11 ? number : number / 2;
    const givenArrayHeight = number < 11 ? 1 : 2;

    return (
      <QF3InteractiveContent
        title={translate.instructions.hereIsAnArrayOfNumCounters(number)}
        initialState={filledArray(filledArray(false, 10), 4)}
        testComplete={answer => trueCount(answer) > 0}
        testCorrect={answer => {
          const { width, height } = getRectangleDimensions(answer);
          return (
            trueCount(answer) === number &&
            isRectangle(answer) &&
            width !== givenArrayWidth &&
            height !== givenArrayHeight
          );
        }}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <View>
            <ArrayOfObjects
              dimens={{ height: dimens.height / 3, width: dimens.width }}
              columns={givenArrayWidth}
              rows={givenArrayHeight}
              counterSize={70}
            />
            <Text style={{ fontSize: displayMode === 'digital' ? 28 : 50 }}>
              {displayMode !== 'digital'
                ? translate.instructions.createADifferentArrayUsingNumCountersPDF(number)
                : translate.instructions.createADifferentArrayUsingNumCounters(number)}
            </Text>
            {displayMode === 'digital' ? (
              <CreateArrayOfObjects
                numberOfRows={4}
                numberOfCols={10}
                userAnswer={userAnswer}
                setUserAnswer={setUserAnswer}
                dimens={{
                  height: dimens.height / 1.5,
                  width: dimens.width
                }}
              />
            ) : (
              <View style={{ height: dimens.height / 2 }}></View>
            )}
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText:
            translate.markScheme.acceptAnyArrayOfNumCountersDifferentToGivenArrayAbove(number)
        }}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseArrays',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
