import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import TextStructure from '../../../../components/molecules/TextStructure';
import { MULT } from '../../../../constants';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { all, create, number } from 'mathjs';
import QF27aLongMultiplicationDeprecated from '../../../../components/question/questionFormats/QF27aLongMultiplicationDeprecated';
import { getMarkSchemeAnswer } from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF27aLongMultiplication from '../../../../components/question/questionFormats/QF27aLongMultiplication';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aOU',
  description: 'aOU',
  keywords: ['4-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(1001)
      .max(2999)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z
      .number()
      .int()
      .min(13)
      .max(29)
      .refine(x => x % 10 !== 0, 'number2 should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 2999, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(13, 29, { constraint: x => x % 10 !== 0 });

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number1 * number2;
    return (
      <QF27aLongMultiplicationDeprecated
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        multiplier={number2}
        showPartials
        removeExtraCells
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question1v2 = newQuestionContent({
  uid: 'aOU2',
  description: 'aOU',
  keywords: ['4-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(1001)
      .max(2999)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z
      .number()
      .int()
      .min(13)
      .max(29)
      .refine(x => x % 10 !== 0, 'number2 should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 2999, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(13, 29, { constraint: x => x % 10 !== 0 });

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number1 * number2;
    return (
      <QF27aLongMultiplication
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        multiplier={number2}
        showPartials
        removeExtraCells
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

// Reused by aov
const Question2 = newQuestionContent({
  uid: 'aOV',
  description: 'aOV',
  keywords: ['4-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(1001)
      .max(4999)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z
      .number()
      .int()
      .min(25)
      .max(69)
      .refine(x => x % 10 !== 0, 'number2 should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 4999, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(25, 69, { constraint: x => x % 10 !== 0 });

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number1 * number2;

    return (
      <QF27aLongMultiplicationDeprecated
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        multiplier={number2}
        showPartials
        removeExtraCells
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

export const aOV = Question2;

const Question2v2 = newQuestionContent({
  uid: 'aOV2',
  description: 'aOV',
  keywords: ['4-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(1001)
      .max(4999)
      .refine(x => x % 10 !== 0, 'number1 should not be a multiple of 10'),
    number2: z
      .number()
      .int()
      .min(25)
      .max(69)
      .refine(x => x % 10 !== 0, 'number2 should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 4999, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(25, 69, { constraint: x => x % 10 !== 0 });

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number1 * number2;

    return (
      <QF27aLongMultiplication
        title={translate.instructions.workOutXMultipliedByY(number1, number2)}
        topNumber={number1}
        multiplier={number2}
        showPartials
        removeExtraCells
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

export const aOVv2 = Question2v2;

const Question3 = newQuestionContent({
  uid: 'aOW',
  description: 'aOW',
  keywords: ['4-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(1001)
      .max(4999)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
    number2: z.number().int().min(13).max(19),
    number3: z
      .number()
      .int()
      .min(3001)
      .max(6999)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
    number4: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
    number5: z
      .number()
      .int()
      .min(5001)
      .max(9499)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
    number6: z
      .number()
      .int()
      .min(41)
      .max(99)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 4999, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(13, 19);
    const number3 = randomIntegerInclusive(3001, 6999, { constraint: x => x % 10 !== 0 });
    const number4 = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const number5 = randomIntegerInclusive(5001, 9499, { constraint: x => x % 10 !== 0 });
    const number6 = randomIntegerInclusive(41, 99, { constraint: x => x % 10 !== 0 });
    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;
    const eqA = getBinOpEquation({
      left: number1,
      right: number2,
      sign: 'multiply',
      answer: 'result'
    });
    const eqB = getBinOpEquation({
      left: number3,
      right: number4,
      sign: 'multiply',
      answer: 'result'
    });
    const eqC = getBinOpEquation({
      left: number5,
      right: number6,
      sign: 'multiply',
      answer: 'result'
    });
    const eqs = [eqA, eqB, eqC];
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aOW2',
  description: 'aOW2',
  keywords: ['4-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    multiplier: z
      .number()
      .int()
      .min(1001)
      .max(9499)
      .refine(val => val % 10 !== 0, 'multiplier should not be a multiple of 10'),
    multiplicand: z
      .number()
      .int()
      .min(13)
      .max(99)
      .refine(val => val % 10 !== 0, 'multiplicand should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    // Needed to determine which of the three equations of the v1 Q multiplier and multiplicand's bounds will adhere to:
    const equation = getRandomFromArray(['A', 'B', 'C'] as const);

    const [multiplier, multiplicand] = (() => {
      switch (equation) {
        case 'A':
          return [
            randomIntegerInclusive(1001, 4999, { constraint: x => x % 10 !== 0 }),
            randomIntegerInclusive(13, 19)
          ];
        case 'B':
          return [
            randomIntegerInclusive(3001, 6999, { constraint: x => x % 10 !== 0 }),
            randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 })
          ];
        case 'C':
          return [
            randomIntegerInclusive(5001, 9499, { constraint: x => x % 10 !== 0 }),
            randomIntegerInclusive(41, 99, { constraint: x => x % 10 !== 0 })
          ];
      }
    })();

    return { multiplier, multiplicand };
  },
  Component: props => {
    const {
      question: { multiplier, multiplicand },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[(multiplier * multiplicand).toString()]}
        sentence={`${multiplier.toLocaleString()} ${MULT} ${multiplicand.toLocaleString()} = <ans/>`}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aOX',
  description: 'aOX',
  keywords: ['4-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    raceLength: z
      .number()
      .int()
      .min(1001)
      .max(5999)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
    multiplier: z
      .number()
      .int()
      .min(31)
      .max(69)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const raceLength = randomIntegerInclusive(1001, 5999, { constraint: x => x % 10 !== 0 });
    const multiplier = randomIntegerInclusive(31, 69, { constraint: x => x % 10 !== 0 });
    return { raceLength, multiplier };
  },
  Component: props => {
    const {
      question: { raceLength, multiplier },
      translate
    } = props;
    const answer = raceLength * multiplier;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aRaceISXLongWithYRunnersWhatsTheTotalDistanceRun(
          raceLength.toLocaleString(),
          multiplier.toLocaleString()
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.ansM()}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aOY',
  description: 'aOY',
  keywords: ['Compare', 'Inequalities', '4-digit', 'Multiplication'],
  schema: z.object({
    multiplicationOneNumberOne: z
      .number()
      .int()
      .min(1001)
      .max(9499)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
    multiplicationOneNumberTwo: z
      .number()
      .int()
      .min(31)
      .max(69)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
    multiplicationTwoNumberOne: z.number().int().min(901).max(9599),
    multiplicationTwoNumberTwo: z.number().int().min(26).max(74)
  }),
  simpleGenerator: () => {
    const multiplicationOneNumberOne = randomIntegerInclusive(1001, 9499, {
      constraint: x => x % 10 !== 0
    });
    const multiplicationOneNumberTwo = randomIntegerInclusive(31, 69, {
      constraint: x => x % 10 !== 0
    });

    const var3 = randomIntegerInclusive(50, 100);
    const multiplicationTwoNumberOne = getRandomFromArray([
      multiplicationOneNumberOne - var3,
      multiplicationOneNumberOne + var3
    ]);

    const var5 = randomIntegerInclusive(2, 5);
    const multiplicationTwoNumberTwo = getRandomFromArray([
      multiplicationOneNumberTwo - var5,
      multiplicationOneNumberTwo + var5
    ]);

    return {
      multiplicationOneNumberOne,
      multiplicationOneNumberTwo,
      multiplicationTwoNumberOne,
      multiplicationTwoNumberTwo
    };
  },
  Component: props => {
    const {
      question: {
        multiplicationOneNumberOne,
        multiplicationOneNumberTwo,
        multiplicationTwoNumberOne,
        multiplicationTwoNumberTwo
      },
      translate
    } = props;
    const statements = [
      {
        lhsComponent: (
          <TextStructure
            sentence={`${multiplicationOneNumberOne.toLocaleString()} ${MULT} ${multiplicationOneNumberTwo.toLocaleString()}`}
          />
        ),
        rhsComponent: (
          <TextStructure
            sentence={`${multiplicationTwoNumberOne.toLocaleString()} ${MULT} ${multiplicationTwoNumberTwo.toLocaleString()}`}
          />
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          multiplicationOneNumberOne * multiplicationOneNumberTwo,
          multiplicationTwoNumberOne * multiplicationTwoNumberTwo
        )
      }
    ];
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragLessThanGreaterThanOrEqualsToCompleteStatement()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatement()}
        items={['>', '<', '=']}
        itemVariant="square"
        statements={statements}
        statementStyle={{ alignSelf: 'center' }}
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aOZ',
  description: 'aOZ',
  keywords: ['4-digit', '2-digit', 'Column calculation', 'Multiplication'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(301)
      .max(599)
      .refine(x => x % 10 !== 0, 'should not be a multiple of 10'),
    number2: z.number().int().min(5).max(9),
    number3: z.number().int().min(11).max(19)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(301, 599, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(5, 9);
    const number3 = randomIntegerInclusive(11, 19);
    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;
    const answer = number(math.evaluate(`${number1} *${number3} * ${number2}`));
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutX(
          `${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()} ${MULT} ${number2.toLocaleString()}`
        )}
        testCorrect={[answer.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyA4DigitNumberByA2DigitNumber',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
