import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { isInRange } from '../../../../utils/matchers';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getRandomUniqueKs1Names, ks1NameSchema } from '../../../../utils/names';
import { fruitAsWord } from '../../../../utils/fruits';
import { MarkupAssets } from '../../../../markup';
import { AssetSvg } from '../../../../assets/svg';
import { countRange, filledArray } from '../../../../utils/collections';
import { View } from 'react-native';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbh',
  description: 'bbh',
  keywords: ['Add', 'More'],
  schema: z
    .object({
      names: z.array(ks1NameSchema).length(2),
      numA: z.number().int().min(1).max(9),
      numB: z.number().int().min(1).max(9),
      object: z.enum([
        'Apple_green',
        'Apple_red',
        'Orange',
        'Banana',
        'Pear',
        'Pen',
        'Pencil',
        'Ball',
        'Penny'
      ])
    })
    .refine(({ numA, numB }) => isInRange(2, 10)(numA + numB)),
  simpleGenerator: () => {
    const names = getRandomUniqueKs1Names(2);
    const numA = randomIntegerInclusive(1, 9);
    const numB = randomIntegerInclusive(1, 9, { constraint: x => isInRange(2, 10)(x + numA) });
    const object = getRandomFromArray([
      'Apple_green',
      'Apple_red',
      'Orange',
      'Banana',
      'Pear',
      'Pen',
      'Pencil',
      'Ball',
      'Penny'
    ] as const);

    return {
      names,
      numA,
      numB,
      object
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { names, numA, numB, object } = question;
    const [nameA, nameB] = names;
    const total = numA + numB;

    const getString = (num: number) => {
      switch (object) {
        case 'Apple_green':
        case 'Apple_red':
          return fruitAsWord('Apple', translate, num > 1);
        case 'Orange':
        case 'Banana':
        case 'Pear':
          return fruitAsWord(object, translate, num > 1);
        case 'Pen':
          return translate.items.pens(num);
        case 'Pencil':
          return translate.items.pencils(num);
        case 'Ball':
          return translate.items.balls(num);
        case 'Penny':
          return translate.items.pennies(num);
      }
    };

    const objAString = getString(numA);
    const objBString = getString(numB);
    const objTString = getString(total);

    const svgNameObject = {
      Apple_green: 'Array_objects/AppleGreen',
      Apple_red: 'Array_objects/AppleRed',
      Orange: 'Array_objects/Orange',
      Banana: 'Array_objects/Banana',
      Pear: 'Array_objects/Pear',
      Pen: 'Base_Ten/1RedPen',
      Pencil: 'Measure/pencil',
      Ball: 'Sports_ball_football',
      Penny: 'Money/Pence1'
    } as const;

    const svgName = svgNameObject[object];

    const assetDimen = displayMode === 'digital' ? 70 : 100;

    return (
      <MarkupAssets
        elements={{
          assetA: (
            <View style={{ flexDirection: 'row', gap: 8, paddingVertical: 16 }}>
              {countRange(numA).map(i => (
                <AssetSvg key={`${i}_A`} name={svgName} height={assetDimen} width={assetDimen} />
              ))}
            </View>
          ),
          assetB: (
            <View style={{ flexDirection: 'row', gap: 8, paddingVertical: 16 }}>
              {countRange(numB).map(i => (
                <AssetSvg key={`${i}_B`} name={svgName} height={assetDimen} width={assetDimen} />
              ))}
            </View>
          )
        }}
      >
        <QF2AnswerBoxOneSentence
          title={`${translate.ks1Instructions.charAHasXY(
            nameA,
            numA,
            objAString
          )}<br/><asset name='assetA'/><br/>${translate.ks1Instructions.charAGivesCharBXMoreY(
            nameB,
            nameA,
            numB,
            objBString
          )}<br/><asset name='assetB'/><br/>${translate.ks1Instructions.howManyXDoesCharAHaveNow(
            objTString,
            nameA
          )}`}
          sentenceStyle={{ alignSelf: 'flex-end' }}
          mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
          sentence={'<ans/>'}
          testCorrect={[total.toString()]}
        />
      </MarkupAssets>
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bbi',
  description: 'bbi',
  keywords: ['Add', 'More'],
  schema: z
    .object({
      num1: z.number().int().min(1).max(9),
      addMore: z.number().int().min(1).max(9),
      counterColors: z.array(z.enum(['red', 'yellow', 'blue', 'green'])).length(2)
    })
    .refine(
      ({ num1, addMore }) => isInRange(2, 10)(num1 + addMore),
      'total of num1 and addMore should be between 2 and 10'
    ),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(1, 9);
    const addMore = randomIntegerInclusive(1, 9, { constraint: x => isInRange(2, 10)(x + num1) });
    const counterColors = getRandomSubArrayFromArray(
      ['red', 'yellow', 'blue', 'green'] as const,
      2
    );

    return {
      num1,
      addMore,
      counterColors
    };
  },
  Component: ({ question, translate }) => {
    const { num1, addMore, counterColors } = question;

    const [counter1, counter2] = counterColors;

    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragCountersToAddXMore(addMore)}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToAddXMore(addMore)}
        initialState={[[...filledArray(counter1, num1)]]}
        interactiveCells="notInitial"
        items={[counter2]}
        testCorrect={totalCountersIs(num1 + addMore)}
        exampleCorrectAnswer={[[...filledArray(counter1, num1), ...filledArray(counter2, addMore)]]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbj',
  description: 'bbj',
  keywords: ['Add', 'More'],
  schema: z
    .object({
      num1: z.number().int().min(1).max(9),
      num2: z.number().int().min(1).max(9)
    })
    .refine(({ num1, num2 }) => isInRange(2, 10)(num1 + num2)),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(1, 9);
    const num2 = randomIntegerInclusive(1, 9, { constraint: x => isInRange(2, 10)(x + num1) });

    return {
      num1,
      num2
    };
  },
  Component: ({ question, translate }) => {
    const { num1, num2 } = question;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={translate.ks1AnswerSentences.xMoreThanYIsAns(num1, num2)}
        testCorrect={[(num1 + num2).toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AdditionAddMore',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
