// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import WhatIsArea from './1WhatIsArea';
import CountSquares from './2CountSquares';
import MakeShapes from './3MakeShapes';
import CompareAreas from './4CompareAreas';

const Block = newBlockContent({
  block: 'Area',
  field: 'Measurement',
  weeks: [8, 8],
  smallSteps: [
    WhatIsArea, // Step 1
    CountSquares, // Step 2
    MakeShapes, // Step 3
    CompareAreas // Step 4
  ]
});
export default Block;
