import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD, MULT, SUB } from '../../../../constants';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { useMemo } from 'react';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { all, create } from 'mathjs';
import { parseSymbolsToString } from '../../../../utils/parse';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'anN',
  description: 'anN',
  keywords: ['Order of operations'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(1).max(10),
    number3: z.number().int().min(1).max(10),
    name: nameSchema,
    firstOperation: z.enum([ADD, MULT])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 10);
    const number2 = randomIntegerInclusive(1, 10);
    const number3 = randomIntegerInclusive(1, 10);

    const name = getRandomName();

    const firstOperation = getRandomFromArray([ADD, MULT] as const);

    return { number1, number2, number3, name, firstOperation };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, name, firstOperation },
      translate
    } = props;

    const [equation, options] =
      firstOperation === ADD
        ? [
            `${number1} ${ADD} ${number3} ${MULT} ${number2}`,
            [`${number3} ${MULT} ${number2}`, `${number1} ${ADD} ${number3}`]
          ]
        : [
            `${number3} ${MULT} (${number1} ${ADD} ${number2})`,
            [`${number1} ${ADD} ${number2}`, `${number3} ${MULT} ${number1}`]
          ];

    const correct =
      firstOperation === ADD ? [`${number3} ${MULT} ${number2}`] : [`${number1} ${ADD} ${number2}`];

    const shuffledOptions = shuffle(options, { random: seededRandom(props.question) });

    return (
      <QF10SelectNumbers
        title={translate.instructions.characterIsCalculatingXSelectCharactersFirstStep(
          name,
          equation
        )}
        pdfTitle={translate.instructions.characterIsCalculatingXCircleCharactersFirstStep(
          name,
          equation
        )}
        testCorrect={correct}
        items={shuffledOptions.map(eqs => ({
          value: eqs,
          component: eqs.toLocaleString()
        }))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'anO',
  description: 'anO',
  keywords: ['Order of operations'],
  schema: z.object({
    numbers: z.number().int().min(1).max(10).array().length(12),
    addOrSubtract: z.enum(['add', 'subtract'])
  }),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    // Generating numbers for 4 equations
    // Equations will be:
    // 1. A1 ± (C1 * B1)
    // 2. (C2 * B2) ± A2
    // 3. C3 * (A3 ± B4)
    // 4. (A4 ± B4) * C4
    // Note: Addition and Subtraction are separate cases.
    // User will be asked to match the equations to options where the brackets have been simplified
    //
    // Number Generation Constraints:
    // - Avoid duplicate answers e.g. A1 === C2 * B2 && A2 === C1 * B1
    // - For the negative case, all numbers are generated to avoid any negative answers

    const numbers =
      addOrSubtract === 'add'
        ? rejectionSample(
            () => {
              const [A1, A2, A3, A4] = randomUniqueIntegersInclusive(1, 10, 4);
              const [B1, B2, B3, B4] = randomUniqueIntegersInclusive(1, 10, 4);
              const [C1, C2, C3, C4] = randomUniqueIntegersInclusive(1, 10, 4);

              return [A1, A2, A3, A4, B1, B2, B3, B4, C1, C2, C3, C4];
            },
            ([A1, A2, A3, A4, B1, B2, B3, B4, C1, C2, C3, C4]) => {
              return (
                arrayHasNoDuplicates([
                  `${A1} ${ADD} ${C1 * B1}`,
                  `${C2 * B2} ${ADD} ${A2}`,
                  `${C3} ${MULT} ${A3 + B3}`,
                  `${A4 + B4} ${MULT} ${C4}`
                ]) &&
                arrayHasNoDuplicates([A1 + C1 * B1, C2 * B2 + A2, C3 * (A3 + B3), (A4 + B4) * C4])
              );
            }
          )
        : rejectionSample(
            () => {
              const [A1, A2, A3, A4] = randomUniqueIntegersInclusive(1, 10, 4);
              const [B1, B2, B3, B4] = randomUniqueIntegersInclusive(1, 10, 4);
              const [C1, C2, C3, C4] = randomUniqueIntegersInclusive(1, 10, 4);

              return [A1, A2, A3, A4, B1, B2, B3, B4, C1, C2, C3, C4];
            },
            ([A1, A2, A3, A4, B1, B2, B3, B4, C1, C2, C3, C4]) => {
              return (
                arrayHasNoDuplicates([
                  `${A1} ${SUB} ${C1 * B1}`,
                  `${C2 * B2} ${SUB} ${A2}`,
                  `${C3} ${MULT} ${A3 - B3}`,
                  `${A4 - B4} ${MULT} ${C4}`
                ]) &&
                A1 > C1 * B1 &&
                A2 < C2 * B2 &&
                A3 > B3 &&
                A4 > B4 &&
                arrayHasNoDuplicates([A1 - C1 * B1, C2 * B2 - A2, C3 * (A3 - B3), (A4 - B4) * C4])
              );
            }
          );

    return { addOrSubtract, numbers };
  },
  Component: props => {
    const {
      question: { addOrSubtract, numbers },
      translate,
      displayMode
    } = props;

    const [A1, A2, A3, A4, B1, B2, B3, B4, C1, C2, C3, C4] = numbers;

    const statements = useMemo(() => {
      const statements =
        addOrSubtract === 'add'
          ? [
              {
                lhs: `${A1.toLocaleString()} ${ADD} ${C1.toLocaleString()} ${MULT} ${B1.toLocaleString()}`,
                answer: `${A1.toLocaleString()} ${ADD} ${(C1 * B1).toLocaleString()}`
              },
              {
                lhs: `${C2.toLocaleString()} ${MULT} ${B2.toLocaleString()} ${ADD} ${A2.toLocaleString()}`,
                answer: `${(C2 * B2).toLocaleString()} ${ADD} ${A2.toLocaleString()}`
              },
              {
                lhs: `${C3.toLocaleString()} ${MULT} (${A3.toLocaleString()} ${ADD} ${B3.toLocaleString()})`,
                answer: `${C3.toLocaleString()} ${MULT} ${(A3 + B3).toLocaleString()}`
              },
              {
                lhs: `(${A4.toLocaleString()} ${ADD} ${B4.toLocaleString()}) ${MULT} ${C4.toLocaleString()}`,
                answer: `${(A4 + B4).toLocaleString()} ${MULT} ${C4.toLocaleString()}`
              }
            ]
          : [
              {
                lhs: `${A1.toLocaleString()} ${SUB} ${C1.toLocaleString()} ${MULT} ${B1.toLocaleString()}`,
                answer: `${A1.toLocaleString()} ${SUB} ${(C1 * B1).toLocaleString()}`
              },
              {
                lhs: `${C2.toLocaleString()} ${MULT} ${B2.toLocaleString()} ${SUB} ${A2.toLocaleString()}`,
                answer: `${(C2 * B2).toLocaleString()} ${SUB} ${A2.toLocaleString()}`
              },
              {
                lhs: `${C3.toLocaleString()} ${MULT} (${A3.toLocaleString()} ${SUB} ${B3.toLocaleString()})`,
                answer: `${C3.toLocaleString()} ${MULT} ${(A3 - B3).toLocaleString()}`
              },
              {
                lhs: `(${A4.toLocaleString()} ${SUB} ${B4.toLocaleString()}) ${MULT} ${C4.toLocaleString()}`,
                answer: `${(A4 - B4).toLocaleString()} ${MULT} ${C4.toLocaleString()}`
              }
            ];

      return shuffle(statements, { random: seededRandom(props.question) });
    }, [A1, A2, A3, A4, B1, B2, B3, B4, C1, C2, C3, C4, addOrSubtract, props.question]);

    const answerOptions =
      addOrSubtract === 'add'
        ? [
            `${A1.toLocaleString()} ${ADD} ${(C1 * B1).toLocaleString()}`,
            `${(C2 * B2).toLocaleString()} ${ADD} ${A2.toLocaleString()}`,
            `${C3.toLocaleString()} ${MULT} ${(A3 + B3).toLocaleString()}`,
            `${(A4 + B4).toLocaleString()} ${MULT} ${C4.toLocaleString()}`
          ]
        : [
            `${A1.toLocaleString()} ${SUB} ${(C1 * B1).toLocaleString()}`,
            `${(C2 * B2).toLocaleString()} ${SUB} ${A2.toLocaleString()}`,
            `${C3.toLocaleString()} ${MULT} ${(A3 - B3).toLocaleString()}`,
            `${(A4 - B4).toLocaleString()} ${MULT} ${C4.toLocaleString()}`
          ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        items={answerOptions}
        statements={statements.map(({ lhs, answer }) => ({
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ width: displayMode === 'digital' ? 250 : 300, textAlign: 'right' }}
            >
              {lhs}
            </Text>
          ),
          correctAnswer: answer
        }))}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'anP',
  description: 'anP',
  keywords: ['Order of operations'],
  schema: z.object({
    numberA1: z.number().int().min(1).max(10),
    numberA2: z.number().int().min(1).max(9),
    numberA3: z.number().int().min(1).max(6),
    addOrSubtractA: z.enum(['add', 'subtract']),
    numberB1: z.number().int().min(1).max(10),
    numberB2: z.number().int().min(1).max(9),
    numberB3: z.number().int().min(1).max(6),
    addOrSubtractB: z.enum(['add', 'subtract']),
    numberC1: z.number().int().min(1).max(10),
    numberC2: z.number().int().min(1).max(9),
    numberC3: z.number().int().min(1).max(6),
    addOrSubtractC: z.enum(['add', 'subtract']),
    numberD1: z.number().int().min(1).max(10),
    numberD2: z.number().int().min(1).max(9),
    numberD3: z.number().int().min(1).max(6),
    addOrSubtractD: z.enum(['add', 'subtract'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const generateEquation = (
      eq: (l: number, r: number, m: number, sign: 'add' | 'subtract') => number
    ) => {
      // Get the sign first, so that it's 50/50 which sign we pick.
      const sign = getRandomFromArray(['add', 'subtract'] as const);

      // Get the rest of the values.
      const { n1, n2, n3 } = rejectionSample(
        () => {
          const n1 = randomIntegerInclusive(1, 10);
          const n2 = randomIntegerInclusive(1, 9);
          const n3 = randomIntegerInclusive(1, 6);
          return { n1, n2, n3 };
        },
        ({ n1, n2, n3 }) =>
          eq(n1, n2, n3, sign) > 0 && (sign === 'add' ? n1 + n2 <= 10 : n1 - n2 > 1)
      );

      return { n1, n2, n3, sign };
    };

    const {
      n1: numberA1,
      n2: numberA2,
      n3: numberA3,
      sign: addOrSubtractA
    } = generateEquation((n1, n2, n3, sign) => (sign === 'add' ? n1 + n3 * n2 : n1 - n3 * n2));

    const {
      n1: numberB1,
      n2: numberB2,
      n3: numberB3,
      sign: addOrSubtractB
    } = generateEquation((n1, n2, n3, sign) => (sign === 'add' ? n3 * n2 + n1 : n3 * n2 - n1));

    const {
      n1: numberC1,
      n2: numberC2,
      n3: numberC3,
      sign: addOrSubtractC
    } = generateEquation((n1, n2, n3, sign) => (sign === 'add' ? n3 * (n1 + n2) : n3 * (n1 - n2)));

    const {
      n1: numberD1,
      n2: numberD2,
      n3: numberD3,
      sign: addOrSubtractD
    } = generateEquation((n1, n2, n3, sign) => (sign === 'add' ? (n1 + n2) * n3 : (n1 - n2) * n3));

    return {
      numberA1,
      numberA2,
      numberA3,
      addOrSubtractA,
      numberB1,
      numberB2,
      numberB3,
      addOrSubtractB,
      numberC1,
      numberC2,
      numberC3,
      addOrSubtractC,
      numberD1,
      numberD2,
      numberD3,
      addOrSubtractD
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberA3,
        addOrSubtractA,
        numberB1,
        numberB2,
        numberB3,
        addOrSubtractB,
        numberC1,
        numberC2,
        numberC3,
        addOrSubtractC,
        numberD1,
        numberD2,
        numberD3,
        addOrSubtractD
      },
      translate
    } = props;

    const symbol = (sign: 'add' | 'subtract') => (sign === 'add' ? ADD : SUB);

    const eqA = `${numberA1} ${symbol(addOrSubtractA)} ${numberA3} ${MULT} ${numberA2} = <ans/>`;

    const eqB = `${numberB3} ${MULT} ${numberB2} ${symbol(addOrSubtractB)} ${numberB1} = <ans/>`;

    const eqC = `${numberC3} ${MULT} (${numberC1} ${symbol(addOrSubtractC)} ${numberC2}) = <ans/>`;

    const eqD = `(${numberD1} ${symbol(addOrSubtractD)} ${numberD2}) ${MULT} ${numberD3} = <ans/>`;

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [
            addOrSubtractA === 'add'
              ? (numberA1 + numberA3 * numberA2).toString()
              : (numberA1 - numberA3 * numberA2).toString()
          ],
          [
            addOrSubtractB === 'add'
              ? (numberB3 * numberB2 + numberB1).toString()
              : (numberB3 * numberB2 - numberB1).toString()
          ],
          [
            addOrSubtractC === 'add'
              ? (numberC3 * (numberC1 + numberC2)).toString()
              : (numberC3 * (numberC1 - numberC2)).toString()
          ],
          [
            addOrSubtractD === 'add'
              ? ((numberD1 + numberD2) * numberD3).toString()
              : ((numberD1 - numberD2) * numberD3).toString()
          ]
        ]}
        sentences={eqs}
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'anQ',
  description: 'anQ',
  keywords: ['Order of operations'],
  schema: z.object({
    addOrSubtract: z.enum([ADD, SUB]),
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(1).max(9),
    number3: z.number().int().min(1).max(6),
    answer: z.number().int().min(1).max(60)
  }),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const number1 = randomIntegerInclusive(2, 9);
    const number2 =
      addOrSubtract === ADD
        ? randomIntegerInclusive(1, 10 - number1)
        : randomIntegerInclusive(1, number1 - 1);

    const number3 = randomIntegerInclusive(1, 6);

    const answer =
      addOrSubtract === ADD ? number3 * (number1 + number2) : number3 * (number1 - number2);

    return { addOrSubtract, number1, number2, number3, answer };
  },
  Component: props => {
    const {
      question: { addOrSubtract, number1, number2, number3, answer },
      translate
    } = props;
    const sentenceDisplay = `<ans/> <ans/> <ans/> <ans/> <ans/> <ans/> <ans/> = ${answer}`;

    const answerOptions = shuffle(['(', ')', MULT, number1, number2, number3, addOrSubtract], {
      random: seededRandom({ number1, number2, number3, answer })
    });

    return (
      <QF37SentenceDrag<string | number>
        title={translate.instructions.dragCardsCompleteANumberSentence()}
        pdfTitle={translate.instructions.useCardsCompleteANumberSentencePDF()}
        actionPanelVariant="bottom"
        pdfSentencesStyle={{ justifyContent: 'center', rowGap: 16 }}
        pdfOuterItemsStyle={{ flex: 0.5 }}
        items={answerOptions}
        sentence={sentenceDisplay}
        testCorrect={userAnswer => {
          if (userAnswer.some(ans => ans === undefined)) {
            return false;
          }

          const math = create(all);
          const parser = math.parser();

          try {
            const sanitizedUserAnswer = parseSymbolsToString(userAnswer.join(''));
            const userAnswerEvaluated = parser.evaluate(sanitizedUserAnswer);

            return userAnswerEvaluated === answer;
          } catch (e) {
            return false;
          }
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidNumberSentenceUsingAvailCards()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'anR',
  description: 'anR',
  keywords: ['Order of operations', 'Indices'],
  schema: z.object({
    numbers: z.number().int().min(1).max(10).array().length(8),
    addOrSubtract: z.enum(['add', 'subtract'])
  }),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    let numbers: number[] = [];

    if (addOrSubtract === 'add') {
      const A = randomUniqueIntegersInclusive(1, 10, 4);
      const B = randomUniqueIntegersInclusive(1, 10, 4);

      numbers = [...A, ...B];
    } else {
      const A1 = randomIntegerInclusive(2, 10);
      const B1 = randomIntegerInclusive(1, 10, {
        constraint: x => x ** 2 < A1
      });

      const A2 = randomIntegerInclusive(2, 10);
      const B2 = randomIntegerInclusive(1, 10, {
        constraint: x => x < A2
      });

      const A3 = randomIntegerInclusive(1, 10);
      const B3 = randomIntegerInclusive(1, 10);

      const A4 = randomIntegerInclusive(2, 10);
      const B4 = randomIntegerInclusive(1, 10, {
        constraint: x => x < A4
      });

      numbers = [A1, A2, A3, A4, B1, B2, B3, B4];
    }

    return { addOrSubtract, numbers };
  },
  Component: props => {
    const {
      question: { addOrSubtract, numbers },
      translate
    } = props;

    const [A1, A2, A3, A4, B1, B2, B3, B4] = numbers;

    const statements = useMemo(() => {
      const statements =
        addOrSubtract === 'add'
          ? [
              {
                statement: `${A1.toLocaleString()} ${ADD} ${B1.toLocaleString()}² = `,
                answer: `${A1} ${ADD} ${B1 ** 2}`
              },
              {
                statement: `(${A2.toLocaleString()} ${ADD} ${B2.toLocaleString()})² = `,
                answer: `${A2 + B2}²`
              },
              {
                statement: `${A3.toLocaleString()} ${MULT} ${B3.toLocaleString()}² = `,
                answer: `${A3} ${MULT} ${B3 ** 2}`
              },
              {
                statement: `(${A4.toLocaleString()} ${ADD} ${B4.toLocaleString()}) ${MULT} 2 = `,
                answer: `${A4 + B4} ${MULT} 2`
              }
            ]
          : [
              {
                statement: `${A1.toLocaleString()} ${SUB} ${B1.toLocaleString()}² = `,
                answer: `${A1} ${SUB} ${B1 ** 2}`
              },
              {
                statement: `(${A2.toLocaleString()} ${SUB} ${B2.toLocaleString()})² = `,
                answer: `${A2 - B2}²`
              },
              {
                statement: `${A3.toLocaleString()} ${MULT} ${B3.toLocaleString()}² = `,
                answer: `${A3} ${MULT} ${B3 ** 2}`
              },
              {
                statement: `(${A4.toLocaleString()} ${SUB} ${B4.toLocaleString()}) ${MULT} 2 = `,
                answer: `${A4 - B4} ${MULT} 2`
              }
            ];

      return shuffle(statements, { random: seededRandom(props.question) });
    }, [A1, A2, A3, A4, B1, B2, B3, B4, addOrSubtract, props.question]);

    const answerOptions =
      addOrSubtract === 'add'
        ? [
            `${A1.toLocaleString()} ${ADD} ${(B1 ** 2).toLocaleString()}`,
            `${(A2 + B2).toLocaleString()}²`,
            `${A3.toLocaleString()} ${MULT} ${(B3 ** 2).toLocaleString()}`,
            `${(A4 + B4).toLocaleString()} ${MULT} ${(2).toLocaleString()}`
          ]
        : [
            `${A1.toLocaleString()} ${SUB} ${(B1 ** 2).toLocaleString()}`,
            `${(A2 - B2).toLocaleString()}²`,
            `${A3.toLocaleString()} ${MULT} ${(B3 ** 2).toLocaleString()}`,
            `${(A4 - B4).toLocaleString()} ${MULT} ${(2).toLocaleString()}`
          ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.useCardsToMatchCalcs()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'anS',
  description: 'anS',
  keywords: ['Order of operations', 'Indices'],
  schema: z.object({
    numberA1: z.number().int().min(1).max(9),
    numberA2: z.number().int().min(1).max(9),
    numberB1: z.number().int().min(1).max(9),
    numberB2: z.number().int().min(1).max(10),
    addOrSubtractB: z.enum(['add', 'subtract']),
    numberC2: z.number().int().min(1).max(10),
    numberC3: z.number().int().min(2).max(4),
    numberD1: z.number().int().min(1).max(9),
    numberD2: z.number().int().min(1).max(10),
    numberD3: z.number().int().min(2).max(4),
    addOrSubtractD: z.enum(['add', 'subtract'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    // Question A
    const numberA1 = randomIntegerInclusive(1, 9);

    const numberA2 = randomIntegerInclusive(1, 10 - numberA1);

    // Question B
    const addOrSubtractB = getRandomFromArray(['add', 'subtract'] as const);

    const numberB1 = randomIntegerInclusive(1, 9);

    const numberB2 =
      addOrSubtractB === 'add'
        ? randomIntegerInclusive(1, 10 - numberB1)
        : randomIntegerInclusive(numberB1 + 1, 10);

    // Question C
    const numberC2 = randomIntegerInclusive(1, 10);

    const numberC3 = randomIntegerInclusive(2, 4);

    // Question D
    const addOrSubtractD = getRandomFromArray(['add', 'subtract'] as const);

    const numberD3 = randomIntegerInclusive(2, 4);

    const numberD1 = randomIntegerInclusive(1, 9);

    const numberD2 =
      addOrSubtractD === 'add'
        ? randomIntegerInclusive(1, 10 - numberD1)
        : randomIntegerInclusive(numberD1 + 1, 10);

    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      addOrSubtractB,
      numberC2,
      numberC3,
      numberD1,
      numberD2,
      numberD3,
      addOrSubtractD
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        addOrSubtractB,
        numberC2,
        numberC3,
        numberD1,
        numberD2,
        numberD3,
        addOrSubtractD
      },
      translate
    } = props;

    const eqA = `${numberA1} ${ADD} ${numberA2}² = <ans/>`;

    const eqB =
      addOrSubtractB === 'add'
        ? `(${numberB2} ${ADD} ${numberB1})² = <ans/>`
        : `(${numberB2} ${SUB} ${numberB1})² = <ans/>`;

    const eqC = `${numberC3} ${MULT} ${numberC2}² = <ans/>`;

    const eqD =
      addOrSubtractD === 'add'
        ? `${numberD3} ${MULT} (${numberD2} ${ADD} ${numberD1})² = <ans/>`
        : `${numberD3} ${MULT} (${numberD2} ${SUB} ${numberD1})² = <ans/>`;

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [(numberA1 + numberA2 * numberA2).toString()],
          [
            addOrSubtractB === 'add'
              ? Math.pow(numberB2 + numberB1, 2).toString()
              : Math.pow(numberB2 - numberB1, 2).toString()
          ],
          [(numberC3 * (numberC2 * numberC2)).toString()],
          [
            addOrSubtractD === 'add'
              ? (numberD3 * Math.pow(numberD2 + numberD1, 2)).toString()
              : (numberD3 * Math.pow(numberD2 - numberD1, 2)).toString()
          ]
        ]}
        sentences={eqs}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderOfOperations',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
