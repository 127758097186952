import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import { z } from 'zod';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersExchangeAt, numbersOnlyExchangeAt } from '../../../../utils/exchanges';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ADD } from '../../../../constants';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { roundToTheNearest } from '../../../../utils/math';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'agg',
  description: 'agg',
  keywords: ['Addition', '10s', 'Track'],
  schema: z
    .object({
      startingNumber: z.number().int().min(50).max(860).multipleOf(10)
    })
    .refine(
      val => val.startingNumber % 100 === 50 || val.startingNumber % 100 === 60,
      'startingNumber must end in 50 or 60.'
    ),
  simpleGenerator: () => {
    const zeroOrTen = getRandomFromArray([0, 10] as const);
    const startingNumber = randomIntegerInclusiveStep(50, 850, 100) + zeroOrTen;

    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = [
      startingNumber.toLocaleString(),
      (startingNumber + 10).toLocaleString(),
      (startingNumber + 20).toLocaleString(),
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>'
    ];

    const answerArray = [
      (startingNumber + 30).toString(),
      (startingNumber + 40).toString(),
      (startingNumber + 50).toString(),
      (startingNumber + 60).toString()
    ];

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={numberArray}
      />
    );
  },
  questionHeight: 600
});

const Question1v2 = newQuestionContent({
  uid: 'agg2',
  description: 'agg',
  keywords: ['Addition', '10s', 'Track'],
  schema: z
    .object({
      startingNumber: z.number().int().min(50).max(860).multipleOf(10)
    })
    .refine(
      val => val.startingNumber % 100 === 50 || val.startingNumber % 100 === 60,
      'startingNumber must end in 50 or 60.'
    ),
  simpleGenerator: () => {
    const zeroOrTen = getRandomFromArray([0, 10] as const);
    const startingNumber = randomIntegerInclusiveStep(50, 850, 100) + zeroOrTen;

    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = [
      startingNumber.toLocaleString(),
      (startingNumber + 10).toLocaleString(),
      (startingNumber + 20).toLocaleString(),
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>'
    ];

    const answerArray = [
      (startingNumber + 30).toString(),
      (startingNumber + 40).toString(),
      (startingNumber + 50).toString(),
      (startingNumber + 60).toString(),
      (startingNumber + 70).toString()
    ];

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={numberArray}
      />
    );
  },
  questionHeight: 600
});

const Question1v3 = newQuestionContent({
  uid: 'agg3',
  description: 'agg',
  keywords: ['Addition', '10s', 'Track'],
  schema: z
    .object({
      startingNumber: z.number().int().min(40).max(860).multipleOf(10)
    })
    .refine(
      val =>
        val.startingNumber % 100 === 40 ||
        val.startingNumber % 100 === 50 ||
        val.startingNumber % 100 === 60,
      'startingNumber must end in 40, 50 or 60.'
    ),
  simpleGenerator: () => {
    const zeroOrTenOrTwenty = getRandomFromArray([0, 10, 20] as const);
    const startingNumber = randomIntegerInclusiveStep(40, 840, 100) + zeroOrTenOrTwenty;

    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = [
      startingNumber.toLocaleString(),
      (startingNumber + 10).toLocaleString(),
      (startingNumber + 20).toLocaleString(),
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>'
    ];

    const answerArray = [
      (startingNumber + 30).toString(),
      (startingNumber + 40).toString(),
      (startingNumber + 50).toString(),
      (startingNumber + 60).toString(),
      (startingNumber + 70).toString()
    ];

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={numberArray}
      />
    );
  },
  questionHeight: 600
});

const Question2 = newQuestionContent({
  uid: 'agh',
  description: 'agh',
  keywords: ['Addition', '10s', 'Number line'],
  schema: z
    .object({
      number1: z.number().int().min(110).max(980).multipleOf(10),
      number2: z.number().int().min(20).max(90).multipleOf(10)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'tens'),
      'number1 + number2 must exchange tens'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(20, 90, 10);
    const number1 = randomIntegerInclusiveStep(110, 980, 10, {
      constraint: x => numbersOnlyExchangeAt(x, number2, 'tens')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    // Array of tick values for number line
    const tickValues = range(number1 - 10, number1 + 90, 10);

    const number3 = number1 + number2;

    return (
      <QF1ContentAndSentence
        actionPanelVariant="bottomTall"
        pdfDirection="column"
        sentence={`${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`}
        title={translate.instructions.useNumberLineToCompleteAddition()}
        testCorrect={[number3.toString()]}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        mainPanelStyle={{ alignContent: 'flex-start' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'agi',
  description: 'agi',
  keywords: ['Addition', '10s', 'Bonds'],
  schema: z
    .object({
      number1: z.number().int().min(110).max(980).multipleOf(10),
      number2: z.number().int().min(10).max(90).multipleOf(10)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'tens'),
      'number1 + number2 must only exchange at tens'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(10, 90, 10);
    const number1 = randomIntegerInclusiveStep(110, 980, 10, {
      constraint: x => numbersOnlyExchangeAt(x, number2, 'tens')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardCompleteNumberSentence()}
        pdfTitle={translate.instructions.useCardCompleteNumberSentence()}
        pdfLayout="itemsTop"
        items={[10, 20, 30, 40, 50, 60, 70, 80, 90]}
        sentence={`${number1.toLocaleString()} ${ADD} <ans/> = ${number3.toLocaleString()}`}
        testCorrect={[number2]}
        moveOrCopy="copy"
        questionHeight={600}
        {...props}
      />
    );
  },
  questionHeight: 600
});

const Question4 = newQuestionContent({
  uid: 'agj',
  description: 'agj',
  keywords: ['Number line', 'Partition', '1s', 'Addition'],
  schema: z.object({
    number1: z.number().int().min(110).max(980).multipleOf(10),
    number2: z.number().int().min(20).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    // 100<$var1 is multiple of 10<990. 10<$var2 is multiple of 10<100. $var3=$var1+$var2 with 1 exchange only: tens to hundred.
    const number2 = randomIntegerInclusiveStep(20, 90, 10);
    const number1 = randomIntegerInclusiveStep(110, 980, 10, {
      constraint: x => numbersExchangeAt(x, number2, 'tens') && x % 100 !== 0
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number4 = roundToTheNearest(number1, 100, 'up');
    const number3 = number1 + number2;

    const number5 = number4 - number1;
    const number6 = number3 - number4;

    const ans1 = number5;
    const ans2 = number6;
    const ans3 = number3;

    const startingNumber = number1;
    const endNumber = number3;

    const tickArray =
      number4 === number3
        ? [
            { label: startingNumber.toLocaleString(), position: startingNumber },
            { label: '<ans/>', position: number3 }
          ]
        : [
            { label: startingNumber.toLocaleString(), position: startingNumber },
            { label: number4.toLocaleString(), position: number4 },
            { label: '<ans/>', position: number3 }
          ];

    const jumpArrowArray =
      number3 % 100 === 0
        ? [{ start: startingNumber, end: number4, label: `${ADD}<ans/>` }]
        : [
            { start: startingNumber, end: number4, label: `${ADD}<ans/>` },
            { start: number4, end: number3, label: `${ADD}<ans/>` }
          ];

    return (
      <QF25JumpOnANumberLine
        title={translate.instructions.useNumberLineToWorkOutXAddY(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        start={startingNumber}
        end={endNumber}
        testCorrect={
          number3 === number4
            ? [ans1.toString(), ans3.toString()]
            : [ans1.toString(), ans2.toString(), ans3.toString()]
        }
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

// Q agl to be before Q agk, requested by Maths Specialists
const Question5 = newQuestionContent({
  uid: 'agl',
  description: 'agl',
  keywords: ['Addition', '10s'],
  schema: z
    .object({
      numberA1: z
        .number()
        .int()
        .min(110)
        .max(899)
        .refine(val => val % 100 > 9, 'numberA1 should have at least 1 ten'),
      numberA2: z.number().int().min(20).max(90).multipleOf(10),
      numberB1: z
        .number()
        .int()
        .min(110)
        .max(899)
        .refine(val => val % 100 > 9, 'numberB1 should have at least 1 ten'),
      numberB2: z.number().int().min(20).max(90).multipleOf(10)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.numberA1, val.numberA2, 'tens'),
      'numberA1 + numberA2 must only exchange tens'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'tens'),
      'numberB1 + numberB2 must only exchange tens'
    ),
  questionHeight: 500,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const numberA2 = randomIntegerInclusiveStep(20, 90, 10);
        const numberA1 = randomIntegerInclusive(110, 899, {
          constraint: x => numbersOnlyExchangeAt(x, numberA2, 'tens')
        });

        const numberB2 = randomIntegerInclusiveStep(20, 90, 10);
        const numberB1 = randomIntegerInclusive(110, 899, {
          constraint: x => numbersOnlyExchangeAt(x, numberB2, 'tens')
        });

        return { numberA1, numberA2, numberB1, numberB2 };
      },
      val =>
        arrayHasNoDuplicates([val.numberA1, val.numberB1]) &&
        arrayHasNoDuplicates([val.numberA2, val.numberB2])
    ),

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: numberA1,
      right: numberA2,
      sign: 'add',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: numberB1,
      right: numberB2,
      sign: 'add',
      answer: 'result'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheAdditions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

// Q agk to be after Q agl, requested by Maths Specialists
const Question6 = newQuestionContent({
  uid: 'agk',
  description: 'agk',
  keywords: ['Addition', '10s'],
  schema: z.object({
    numberA1: z.number().int().min(111).max(899),
    numberA2: z.number().int().min(30).max(90).multipleOf(10),
    numberB1: z.number().int().min(111).max(899),
    numberB2: z.number().int().min(30).max(90).multipleOf(10)
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const numberA2 = randomIntegerInclusiveStep(30, 90, 10);
    const numberA1 = randomIntegerInclusive(111, 899, {
      constraint: x => numbersExchangeAt(x, numberA2, 'tens')
    });

    const numberB2 = randomIntegerInclusiveStep(30, 90, 10);
    const numberB1 = randomIntegerInclusive(111, 899, {
      constraint: x => numbersExchangeAt(x, numberB2, 'tens') && x !== numberA1
    });

    return { numberA1, numberA2, numberB1, numberB2 };
  },

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2 },
      translate
    } = props;

    const numberA3 = numberA1 + numberA2;

    const numberB3 = numberB1 + numberB2;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={[[numberA2.toString()], [numberB1.toString()]]}
        sentences={[
          `${numberA1.toLocaleString()} + <ans/> = ${numberA3.toLocaleString()}`,
          `<ans/> + ${numberB2.toLocaleString()} = ${numberB3.toLocaleString()}`
        ]}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Add10sAcrossA100',
  questionTypes: [Question1v3, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1, Question1v2]
});
export default SmallStep;
