import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import {
  foodAsWord,
  foodIngredients,
  foodSchemaNew,
  foodSchemaOld,
  getRandomFoodNew,
  getRandomFoodOld
} from '../../../../utils/food';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { nameSchema, getRandomName } from '../../../../utils/names';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aTE',
  description: 'aTE',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaOld,
    multiple: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const foodItem = getRandomFoodOld();
    const multiple = randomIntegerInclusive(2, 5);

    return { foodItem, multiple };
  },
  Component: props => {
    const {
      question: { foodItem, multiple },
      translate,
      displayMode
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map((item, idx) => {
      // If last row index
      return food.ingredients.length - 1 === idx
        ? [
            item.ingredient.toLocaleLowerCase(),
            `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`,
            `<ans/> ${item.unit.toLowerCase()}`
          ]
        : [
            item.ingredient.toLocaleLowerCase(),
            `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`,
            `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          ];
    });

    // Answer
    const answer = food.ingredients[food.ingredients.length - 1].amount * multiple;

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;

    // Shuffle ingredients to give question more variation
    const shuffledData = shuffle(data, { random: seededRandom(props.question) });

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableCellStyle={{ height: displayMode === 'digital' ? 110 : 200 }}
        inputMaxCharacters={4}
        tableData={shuffledData}
        testCorrect={[answer.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'aTE2',
  description: 'aTE',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaNew,
    multiple: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const foodItem = getRandomFoodNew();
    const multiple = randomIntegerInclusive(2, 5);

    return { foodItem, multiple };
  },
  Component: props => {
    const {
      question: { foodItem, multiple },
      translate,
      displayMode
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map((item, idx) => {
      // If last row index
      return food.ingredients.length - 1 === idx
        ? [
            item.ingredient.toLocaleLowerCase(),
            `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`,
            `<ans/> ${item.unit.toLowerCase()}`
          ]
        : [
            item.ingredient.toLocaleLowerCase(),
            `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`,
            `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          ];
    });

    // Answer
    const answer = food.ingredients[food.ingredients.length - 1].amount * multiple;

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;

    // Shuffle ingredients to give question more variation
    const shuffledData = shuffle(data, { random: seededRandom(props.question) });

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableCellStyle={{
          height: displayMode === 'digital' ? 120 : 200
        }}
        inputMaxCharacters={4}
        tableData={shuffledData}
        tableFontSize={displayMode === 'digital' ? undefined : 50}
        testCorrect={[answer.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aTF',
  description: 'aTF',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaOld,
    multiple: z.number().int().min(2).max(5)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const foodItem = getRandomFoodOld();
    const multiple = randomIntegerInclusive(2, 5);

    return { foodItem, multiple };
  },
  Component: props => {
    const {
      question: { foodItem, multiple },
      translate
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map(item => [
      item.ingredient.toLocaleLowerCase(),
      `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`,
      `<ans/> ${item.unit.toLowerCase()}`
    ]);

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;
    const foodAmountsMultiple = food.ingredients.map(ingredient => ingredient.amount * multiple);

    // Shuffle ingredients to give question more variation
    const shuffledData = shuffle(data, { random: seededRandom(props.question) });
    const shuffledFoodAmountsMultiple = shuffle(foodAmountsMultiple, {
      random: seededRandom(props.question)
    });

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableData={shuffledData}
        inputMaxCharacters={4}
        testCorrect={userAnswer => {
          return shuffledFoodAmountsMultiple.every(
            (amount, index) => amount.toString() === userAnswer[index]
          );
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: shuffledFoodAmountsMultiple.map(amount => amount.toLocaleString())
        }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aTF2',
  description: 'aTF',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaNew,
    multiple: z.number().int().min(2).max(5)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const foodItem = getRandomFoodNew();
    const multiple = randomIntegerInclusive(2, 5);

    return { foodItem, multiple };
  },
  Component: props => {
    const {
      question: { foodItem, multiple },
      translate,
      displayMode
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map(item => [
      item.ingredient.toLocaleLowerCase(),
      `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`,
      `<ans/> ${item.unit.toLowerCase()}`
    ]);

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;
    const foodAmountsMultiple = food.ingredients.map(ingredient => ingredient.amount * multiple);

    // Shuffle ingredients to give question more variation
    const shuffledData = shuffle(data, { random: seededRandom(props.question) });
    const shuffledFoodAmountsMultiple = shuffle(foodAmountsMultiple, {
      random: seededRandom(props.question)
    });

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableData={shuffledData}
        tableFontSize={displayMode === 'digital' ? undefined : 50}
        inputMaxCharacters={4}
        testCorrect={userAnswer => {
          return shuffledFoodAmountsMultiple.every(
            (amount, index) => amount.toString() === userAnswer[index]
          );
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: shuffledFoodAmountsMultiple.map(amount => amount.toLocaleString())
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aTG',
  description: 'aTG',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaOld,
    multiple: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const foodItem = getRandomFoodOld();
    const multiple = randomIntegerInclusive(2, 5);

    return { foodItem, multiple };
  },
  Component: props => {
    const {
      question: { foodItem, multiple },
      translate
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map((item, idx) => {
      // If last row index
      return food.ingredients.length - 1 === idx
        ? [
            item.ingredient,
            `<ans/> ${item.unit.toLowerCase()}`,
            `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          ]
        : [
            item.ingredient,
            `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`,
            `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          ];
    });

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableData={data}
        testCorrect={[food.ingredients[food.ingredients.length - 1].amount.toString()]}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aTG2',
  description: 'aTG',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaNew,
    multiple: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const foodItem = getRandomFoodNew();
    const multiple = randomIntegerInclusive(2, 5);

    return { foodItem, multiple };
  },
  Component: props => {
    const {
      question: { foodItem, multiple },
      translate,
      displayMode
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map((item, idx) => {
      // If last row index
      return food.ingredients.length - 1 === idx
        ? [
            item.ingredient.toLocaleLowerCase(),
            `<ans/> ${item.unit.toLowerCase()}`,
            `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          ]
        : [
            item.ingredient.toLocaleLowerCase(),
            `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`,
            `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          ];
    });

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableFontSize={displayMode === 'digital' ? undefined : 50}
        questionHeight={800}
        tableCellStyle={{ height: displayMode === 'digital' ? 120 : 200 }}
        tableData={data}
        testCorrect={[food.ingredients[food.ingredients.length - 1].amount.toString()]}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aTH',
  description: 'aTH',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaOld,
    multiple: z.number().int().min(2).max(5)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const foodItem = getRandomFoodOld();
    const multiple = randomIntegerInclusive(2, 5);

    return { foodItem, multiple };
  },
  Component: props => {
    const {
      question: { foodItem, multiple },
      translate
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map(item => [
      item.ingredient,
      `<ans/> ${item.unit.toLowerCase()}`,
      `${item.amount * multiple} ${item.unit.toLowerCase()}`
    ]);

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;

    // Shuffle ingredients to give question more variation
    const shuffledData = shuffle(data, { random: seededRandom(props.question) });
    const shuffledFood = shuffle([...food.ingredients], {
      random: seededRandom(props.question)
    });

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableData={shuffledData}
        testCorrect={userAnswer => {
          return shuffledFood.every(({ amount }, index) => {
            return amount.toString() === userAnswer[index];
          });
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: shuffledFood.map(({ amount }) => amount.toLocaleString())
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aTH2',
  description: 'aTH',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaNew,
    multiple: z.number().int().min(2).max(5)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const foodItem = getRandomFoodNew();
    const multiple = randomIntegerInclusive(2, 5);

    return { foodItem, multiple };
  },
  Component: props => {
    const {
      question: { foodItem, multiple },
      translate,
      displayMode
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map(item => [
      item.ingredient.toLocaleLowerCase(),
      `<ans/> ${item.unit.toLowerCase()}`,
      `${item.amount * multiple} ${item.unit.toLowerCase()}`
    ]);

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;

    // Shuffle ingredients to give question more variation
    const shuffledData = shuffle(data, { random: seededRandom(props.question) });
    const shuffledFood = shuffle([...food.ingredients], {
      random: seededRandom(props.question)
    });

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableData={shuffledData}
        testCorrect={userAnswer => {
          return shuffledFood.every(({ amount }, index) => {
            return amount.toString() === userAnswer[index];
          });
        }}
        tableFontSize={displayMode === 'digital' ? undefined : 50}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: shuffledFood.map(({ amount }) => amount.toLocaleString())
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aTI',
  description: 'aTI',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaOld,
    multiple: z.number().int().min(2).max(5),
    random: z.boolean()
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const foodItem = getRandomFoodOld();
    const multiple = randomIntegerInclusive(2, 5);
    const random = getRandomBoolean();

    return { foodItem, multiple, random };
  },
  Component: props => {
    const {
      question: { foodItem, multiple, random },
      translate
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];

    const shuffledIngredients = shuffle([...food.ingredients], {
      random: seededRandom(props.question)
    });

    let ansIndex = 0;

    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = shuffledIngredients.map((item, idx) => {
      // Randomise answer box positions from left/right vice versa
      ansIndex = random ? (idx === 1 ? 1 : 2) : idx === 1 ? 2 : 1;

      return [
        item.ingredient,
        ansIndex === 2
          ? `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          : `<ans/> ${item.unit.toLowerCase()}`,
        ansIndex === 1
          ? `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          : `<ans/> ${item.unit.toLowerCase()}`
      ];
    });

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableData={data}
        inputMaxCharacters={4}
        testCorrect={userAnswer => {
          return data.every((item, i) => {
            const ingredientAmount = shuffledIngredients[i].amount;
            const calculatedAmount = ingredientAmount * multiple * multiple;

            // If first "makes" column
            // Check ingredient default amount
            if (item[1].includes('<ans/>')) {
              return userAnswer[i].toString() === ingredientAmount.toString();
              // Else check second "makes" column
              // Check ingredient * multiplier
            } else {
              return userAnswer[i].toString() === calculatedAmount.toString();
            }
          });
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: data.map((item, i) => {
            const ingredientAmount = shuffledIngredients[i].amount;
            const calculatedAmount = ingredientAmount * multiple * multiple;

            // If first "makes" column
            // Check ingredient default amount
            if (item[1].includes('<ans/>')) {
              return ingredientAmount.toString();
              // Else check second "makes" column
              // Check ingredient * multiplier
            } else {
              return calculatedAmount.toString();
            }
          })
        }}
      />
    );
  }
});

const Question5V2 = newQuestionContent({
  uid: 'aTI2',
  description: 'aTI',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaNew,
    multiple: z.number().int().min(2).max(5),
    random: z.boolean()
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const foodItem = getRandomFoodNew();
    const multiple = randomIntegerInclusive(2, 5);
    const random = getRandomBoolean();

    return { foodItem, multiple, random };
  },
  Component: props => {
    const {
      question: { foodItem, multiple, random },
      translate,
      displayMode
    } = props;

    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];

    const shuffledIngredients = shuffle([...food.ingredients], {
      random: seededRandom(props.question)
    });

    let ansIndex = 0;

    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = shuffledIngredients.map((item, idx) => {
      // Randomise answer box positions from left/right vice versa
      ansIndex = random ? (idx === 1 ? 1 : 2) : idx === 1 ? 2 : 1;

      return ansIndex === 2
        ? [
            item.ingredient.toLocaleLowerCase(),
            `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`,
            `<ans/> ${item.unit.toLowerCase()}`
          ]
        : [
            item.ingredient.toLocaleLowerCase(),
            `<ans/> ${item.unit.toLowerCase()}`,
            `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
          ];
    });

    // Get food individual amounts per ingredient
    const foodMakesMultiple = food.makes * multiple;

    return (
      <QF7InteractiveTable
        title={translate.instructions.hereAreTheIngredientsNeededToMakeFoodACompleteTheTable(
          foodItemTranslated
        )}
        cellHeaders={[
          foodItemTranslated,
          `${translate.answerSentences.makesNum(food.makes)}`,
          `${translate.answerSentences.makesNum(foodMakesMultiple)}`
        ]}
        tableData={data}
        inputMaxCharacters={4}
        tableFontSize={displayMode === 'digital' ? undefined : 50}
        testCorrect={userAnswer => {
          return data.every((item, i) => {
            const ingredientAmount = shuffledIngredients[i].amount;
            const calculatedAmount = ingredientAmount * multiple * multiple;

            // If first "makes" column
            // Check ingredient default amount
            if (item[1].includes('<ans/>')) {
              return userAnswer[i].toString() === ingredientAmount.toString();
              // Else check second "makes" column
              // Check ingredient * multiplier
            } else {
              return userAnswer[i].toString() === calculatedAmount.toString();
            }
          });
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: data.map((item, i) => {
            const ingredientAmount = shuffledIngredients[i].amount;
            const calculatedAmount = ingredientAmount * multiple * multiple;

            // If first "makes" column
            // Check ingredient default amount
            if (item[1].includes('<ans/>')) {
              return ingredientAmount.toString();
              // Else check second "makes" column
              // Check ingredient * multiplier
            } else {
              return calculatedAmount.toString();
            }
          })
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aTJ',
  description: 'aTJ',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaOld,
    ingredientsMultiple: z.number().int().min(2).max(5),
    makesMultiple: z.number().int().min(1).max(90),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const foodItem = getRandomFoodOld();
    const ingredientsMultiple = randomIntegerInclusive(2, 5);
    const makesMultiple = randomIntegerInclusive(1, 90);

    const name = getRandomName();

    return { foodItem, ingredientsMultiple, makesMultiple, name };
  },
  Component: ({
    question: { foodItem, ingredientsMultiple, makesMultiple, name },
    translate,
    displayMode
  }) => {
    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map(item => [
      item.ingredient,
      `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`
    ]);

    // Ingredients
    const ingredients = food.ingredients.filter(ingredient => ingredient);

    // Answer
    const answerAmount = food.makes * ingredientsMultiple;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={
          data.length === 2
            ? translate.instructions.characterHas2IngredientsCharacterCanMakeXFoodItem({
                character: name,
                amount1: ingredients[0].amount * ingredientsMultiple,
                unit1: ingredients[0].unit,
                ingredient1: ingredients[0].ingredient,
                amount2: ingredients[1].amount * ingredientsMultiple,
                unit2: ingredients[1].unit,
                ingredient2: ingredients[1].ingredient,
                foodItemAmount: makesMultiple,
                foodItem: foodAsWord(foodItem, translate, makesMultiple)
              })
            : translate.instructions.characterHas3IngredientsCharacterCanMakeXFoodItem({
                character: name,
                amount1: ingredients[0].amount * ingredientsMultiple,
                unit1: ingredients[0].unit,
                ingredient1: ingredients[0].ingredient,
                amount2: ingredients[1].amount * ingredientsMultiple,
                unit2: ingredients[1].unit,
                ingredient2: ingredients[1].ingredient,
                amount3: ingredients[2].amount * ingredientsMultiple,
                unit3: ingredients[2].unit,
                ingredient3: ingredients[2].ingredient,
                foodItemAmount: makesMultiple,
                foodItem: foodAsWord(foodItem, translate, makesMultiple)
              })
        }
        pdfTitle={translate.instructions.isStatementTrueOrFalsePDF()}
        correctAnswer={makesMultiple <= answerAmount}
        content={
          <CustomizableTable
            cellHeaders={[foodItemTranslated, `${translate.answerSentences.makesNum(food.makes)}`]}
            tableData={data}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6V2 = newQuestionContent({
  uid: 'aTJ2',
  description: 'aTJ',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaOld,
    multiple: z.number().int().min(2).max(10),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const foodItem = getRandomFoodOld();
    const multiple = randomIntegerInclusive(2, 10);

    const name = getRandomName();

    return { foodItem, multiple, name };
  },
  Component: ({ question: { foodItem, multiple, name }, translate, displayMode }) => {
    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map(item => [
      item.ingredient,
      `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`
    ]);

    const questionText = food.ingredients.map(item => [
      item.ingredient,
      `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
    ]);

    // Answer
    const answerAmount = food.makes * multiple;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereAreIngredientsNeededToMakeXFood(
          food.makes,
          foodItemTranslated
        )}
        testCorrect={[answerAmount.toString()]}
        Content={
          <>
            <CustomizableTable
              cellHeaders={[
                foodItemTranslated,
                `${translate.answerSentences.makesNum(food.makes)}`
              ]}
              rowStyle={{ height: displayMode === 'digital' ? 45 : undefined }}
              tableData={data}
              tableFontSize={displayMode === 'digital' ? 24 : 50}
            />
            <View style={{ alignItems: 'flex-start', width: '100%' }}>
              <TextStructure
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                sentence={
                  data.length === 3
                    ? translate.answerSentences.characterHasXOfYAOfBAndCOfDWhatIsMaxNumberOfZCharacterCanMake(
                        {
                          character: name,
                          foodAmount1: questionText[0][1],
                          food1: questionText[0][0],
                          foodAmount2: questionText[1][1],
                          food2: questionText[1][0],
                          foodAmount3: questionText[2][1],
                          food3: questionText[2][0],
                          food4: foodItemTranslated
                        }
                      )
                    : translate.answerSentences.characterHasXOfYAAndOfBWhatIsMaxNumberOfZCharacterCanMake(
                        {
                          character: name,
                          foodAmount1: questionText[0][1],
                          food1: questionText[0][0],
                          foodAmount2: questionText[1][1],
                          food2: questionText[1][0],
                          food4: foodItemTranslated
                        }
                      )
                }
              />
            </View>
          </>
        }
        questionHeight={900}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={'<ans/>'}
      />
    );
  },
  questionHeight: 900
});

const Question6V3 = newQuestionContent({
  uid: 'aTJ3',
  description: 'aTJ',
  keywords: ['Recipes', 'Table'],
  schema: z.object({
    foodItem: foodSchemaNew,
    multiple: z.number().int().min(2).max(10),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const foodItem = getRandomFoodNew();
    const multiple = randomIntegerInclusive(2, 10);

    const name = getRandomName();

    return { foodItem, multiple, name };
  },
  Component: ({ question: { foodItem, multiple, name }, translate, displayMode }) => {
    const foodItemTranslated = foodAsWord(foodItem, translate, 2);

    // Get foodItem
    const food = foodIngredients(translate, 2).filter(food => food.name === foodItemTranslated)[0];
    // Return relevant food info to be displayed inside table e.g ingredients, amounts, answer boxes
    const data = food.ingredients.map(item => [
      item.ingredient,
      `${item.amount.toLocaleString()} ${item.unit.toLowerCase()}`
    ]);

    const questionText = food.ingredients.map(item => [
      item.ingredient,
      `${(item.amount * multiple).toLocaleString()} ${item.unit.toLowerCase()}`
    ]);

    // Answer
    const answerAmount = food.makes * multiple;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereAreIngredientsNeededToMakeXFood(
          food.makes,
          foodItemTranslated
        )}
        testCorrect={[answerAmount.toString()]}
        Content={({ dimens }) => (
          <View style={{ top: 10, width: dimens.width }}>
            <CustomizableTable
              cellHeaders={[
                foodItemTranslated,
                `${translate.answerSentences.makesNum(food.makes)}`
              ]}
              rowStyle={{ height: displayMode === 'digital' ? 47 : undefined }}
              tableData={data}
              tableFontSize={displayMode === 'digital' ? 24 : 50}
              tableStyle={{ marginBottom: displayMode === 'digital' ? 0 : 24 }}
              textStyle={{ lineHeight: displayMode === 'digital' ? 0 : undefined }}
            />
            <View style={{ alignItems: 'flex-start', width: '100%' }}>
              <TextStructure
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                sentence={
                  data.length === 3
                    ? translate.answerSentences.characterHasXOfYAOfBAndCOfDWhatIsMaxNumberOfZCharacterCanMake(
                        {
                          character: name,
                          foodAmount1: questionText[0][1],
                          food1: questionText[0][0],
                          foodAmount2: questionText[1][1],
                          food2: questionText[1][0],
                          foodAmount3: questionText[2][1],
                          food3: questionText[2][0],
                          food4: foodItemTranslated
                        }
                      )
                    : translate.answerSentences.characterHasXOfYAAndOfBWhatIsMaxNumberOfZCharacterCanMake(
                        {
                          character: name,
                          foodAmount1: questionText[0][1],
                          food1: questionText[0][0],
                          foodAmount2: questionText[1][1],
                          food2: questionText[1][0],
                          food4: foodItemTranslated
                        }
                      )
                }
              />
            </View>
          </View>
        )}
        questionHeight={900}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={'<ans/>'}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Recipes',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4v2, Question5V2, Question6V3],
  archivedQuestionTypes: [
    Question1,
    Question2,
    Question3,
    Question4,
    Question5,
    Question6,
    Question6V2
  ]
});
export default SmallStep;
