import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import {
  createRectangleFromSquares,
  getRectangleDimensions,
  isSquareShape,
  isValidRectangle,
  isValidSquare,
  trueCount
} from '../../../../utils/shapes';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import QF45CompletePolygonOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45CompletePolygonOnSquareDottedPaper';
import { arraysHaveSameContentsUnordered } from '../../../../utils/collections';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avs',
  description: 'avs',
  keywords: ['2-D', 'Shape', 'Regular', 'Draw'],
  schema: z.object({
    width: z.number().int().min(1).max(10),
    height: z.number().int().min(1).max(5),
    missingPoint: z.enum(['left', 'top', 'right', 'bottom']),
    isSquare: z.boolean(),
    xOffset: z.number().int().min(0).max(10),
    yOffset: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const isSquare = getRandomBoolean();

    const width = randomIntegerInclusive(1, isSquare ? 5 : 10);
    const height = isSquare ? width : randomIntegerInclusive(1, 5);
    const xOffset = randomIntegerInclusive(0, 10, { constraint: x => x + width <= 10 });
    const yOffset = randomIntegerInclusive(0, 5, { constraint: x => x + height <= 5 });

    const missingPoint = getRandomFromArray(['left', 'top', 'right', 'bottom'] as const);

    return { width, height, missingPoint, isSquare, xOffset, yOffset };
  },
  Component: ({ question, translate }) => {
    const { width, height, missingPoint, isSquare, xOffset, yOffset } = question;

    const missingIndex = (() => {
      switch (missingPoint) {
        case 'left':
          return 0;
        case 'top':
          return 1;
        case 'right':
          return 2;
        case 'bottom':
          return 3;
      }
    })();

    const coords = [
      { x: xOffset, y: yOffset },
      { x: xOffset, y: yOffset + height },
      { x: xOffset + width, y: yOffset + height },
      { x: xOffset + width, y: yOffset }
    ];

    const shapeDisplayString = isSquare
      ? translate.shapes.squares(1)
      : translate.shapes.rectangles(1);

    return (
      <QF45CompletePolygonOnSquareDottedPaper
        title={translate.instructions.tapWhereTheFinalVertexOfTheXWouldBe(shapeDisplayString)}
        pdfTitle={translate.instructions.completeShapeBySelectingADotPdf(shapeDisplayString)}
        polygon={coords}
        missingIndex={missingIndex}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'avt',
  description: 'avt',
  keywords: ['2-D', 'Shape', 'Regular', 'Draw'],
  schema: z.object({}),
  simpleGenerator: () => {
    return {};
  },
  Component: ({ translate }) => {
    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.instructions.tapDotsToCreateX(translate.shapes.aSquare())}
        pdfTitle={translate.instructions.tapDotsToCreateXPdf(translate.shapes.aSquare())}
        numPoints={4}
        closeShape
        testCorrect={userAnswer => {
          const [point1, point2, point3, point4] = userAnswer;
          if (!point1 || !point2 || !point3 || !point4) return false;
          return isValidSquare(
            [point1.x, point1.y],
            [point2.x, point2.y],
            [point3.x, point3.y],
            [point4.x, point4.y],
            true
          );
        }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptValidVerticesForShape() }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'avu',
  description: 'avu',
  keywords: ['2-D', 'Shape', 'Regular', 'Draw'],
  schema: z.object({}),
  simpleGenerator: () => {
    return {};
  },
  Component: ({ translate }) => {
    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.instructions.tapDotsToCreateX(translate.shapes.aRectangle())}
        pdfTitle={translate.instructions.tapDotsToCreateXPdf(translate.shapes.aRectangle())}
        numPoints={4}
        closeShape
        testCorrect={userAnswer => {
          const [point1, point2, point3, point4] = userAnswer;
          if (!point1 || !point2 || !point3 || !point4) return false;
          return isValidRectangle(
            [point1.x, point1.y],
            [point2.x, point2.y],
            [point3.x, point3.y],
            [point4.x, point4.y],
            false,
            true
          );
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptValidVerticesForShape(),
          answersToDisplay: [
            { x: 4, y: 1 },
            { x: 4, y: 4 },
            { x: 6, y: 4 },
            { x: 6, y: 1 }
          ]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'avv',
  description: 'avv',
  keywords: ['Regular 2-D shape', 'Draw'],
  schema: z
    .object({
      width: z.number().int().min(2).max(4),
      height: z.number().int().min(2).max(4),
      missingPoint: z.enum(['left', 'top', 'right', 'bottom'])
    })
    .refine(({ width, height }) => width + height <= 5, 'width+height must be <= 5'),
  simpleGenerator: () => {
    const isSquare = getRandomBoolean();
    const { width, height } = rejectionSample(
      () => ({ width: randomIntegerInclusive(2, 4), height: randomIntegerInclusive(2, 4) }),
      ({ width, height }) => width + height <= 5 && isSquare === (width === height)
    );

    const missingPoint = getRandomFromArray(['left', 'top', 'right', 'bottom'] as const);

    return { width, height, missingPoint };
  },
  Component: ({ question, translate }) => {
    const { width, height, missingPoint } = question;
    const random = seededRandom(question);

    const isSquare = width === height;
    const missingIndex = (() => {
      switch (missingPoint) {
        case 'left':
          return 0;
        case 'top':
          return 1;
        case 'right':
          return 2;
        case 'bottom':
          return 3;
      }
    })();

    // Grid is 10 x 5, and the shape is arranged diagonally.
    // If the shape is placed at the far bottom left, then its x-length and y-length are both width+height.
    // But we want to place it randomly:
    const xOffset = randomIntegerInclusive(0, 10 - (width + height), { random });
    const yOffset = randomIntegerInclusive(0, 5 - (width + height), { random });

    // And now we know the coordinates
    const coords = [
      { x: xOffset + 0, y: yOffset + width },
      { x: xOffset + height, y: yOffset + height + width },
      { x: xOffset + height + width, y: yOffset + height },
      { x: xOffset + width, y: yOffset + 0 }
    ];

    const shapeDisplayString = isSquare
      ? translate.shapes.squares(1)
      : translate.shapes.rectangles(1);

    return (
      <QF45CompletePolygonOnSquareDottedPaper
        title={translate.instructions.tapWhereTheFinalVertexOfTheXWouldBe(shapeDisplayString)}
        pdfTitle={translate.instructions.completeShapeBySelectingADotPdf(shapeDisplayString)}
        polygon={coords}
        missingIndex={missingIndex}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'avw',
  description: 'avw',
  keywords: ['2-D', 'Shape', 'Regular', 'Draw'],
  schema: z.object({
    sideLength: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const sideLength = randomIntegerInclusive(2, 10);

    return { sideLength };
  },
  Component: props => {
    const {
      question: { sideLength },
      translate
    } = props;

    const area = sideLength * sideLength;

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.selectSquareToCreateSquareWithSideLengthOfXcm(sideLength)}
        pdfTitle={translate.instructions.shadeSquareToCreateSquareWithSideLengthOfXcm(sideLength)}
        numberOfRows={10}
        numberOfCols={10}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer => isSquareShape(userAnswer) && trueCount(userAnswer) === area}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anySquareWithAreaOfX(area)
        }}
        questionHeight={900}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'avw2',
  description: 'avw2',
  keywords: ['2-D', 'Shape', 'Regular', 'Draw'],
  schema: z.object({
    sideLength: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const sideLength = randomIntegerInclusive(2, 6);

    return { sideLength };
  },
  Component: props => {
    const {
      question: { sideLength },
      translate
    } = props;

    const area = sideLength * sideLength;

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.tapTheGridToCreateASquareWithASideLengthOfNumCm(sideLength)}
        pdfTitle={translate.instructions.shadeTheGridToCreateASquareWithASideLengthOfNumCm(
          sideLength
        )}
        numberOfRows={10}
        numberOfCols={10}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer => isSquareShape(userAnswer) && trueCount(userAnswer) === area}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anySquareWithAnAreaOfNumCm2(area),
          answerToDisplay: createRectangleFromSquares(sideLength, sideLength)
        }}
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'avx',
  description: 'avx',
  keywords: ['2-D', 'Shape', 'Regular', 'Draw'],
  schema: z
    .object({
      length: z.number().int().min(6).max(9),
      width: z.number().int().min(2).max(9)
    })
    .refine(val => val.length > val.width, 'length must be greater than width'),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(6, 9);

    const width = randomIntegerInclusive(2, Math.min(length - 1, 9));

    return { length, width };
  },
  Component: ({ question: { length, width }, translate }) => {
    const area = length * width;

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.selectSquaresToCreateRectangleWithLengthOfXCmAndWidthOfYCm(
          length,
          width
        )}
        pdfTitle={translate.instructions.shadeSquaresToCreateRectangleWithLengthOfXCmAndWidthOfYCm(
          length,
          width
        )}
        numberOfRows={9}
        numberOfCols={9}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer => {
          const { width: answerWidth, height: answerLength } = getRectangleDimensions(userAnswer);
          return arraysHaveSameContentsUnordered([answerWidth, answerLength], [width, length]);
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithAreaOfX(area)
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6v2 = newQuestionContent({
  uid: 'avx2',
  description: 'avx',
  keywords: ['2-D', 'Shape', 'Regular', 'Draw'],
  schema: z
    .object({
      length: z.number().int().min(3).max(6),
      width: z.number().int().min(2).max(5)
    })
    .refine(val => val.length > val.width, 'length must be greater than width'),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(3, 6);

    const width = randomIntegerInclusive(2, length - 1);

    return { length, width };
  },
  Component: ({ question: { length, width }, translate }) => {
    const area = length * width;

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.tapTheGridToCreateRectangleWithLengthOfXCmAndWidthOfYCm(
          length,
          width
        )}
        pdfTitle={translate.instructions.shadeTheGridToCreateRectangleWithLengthOfXCmAndWidthOfYCm(
          length,
          width
        )}
        numberOfRows={9}
        numberOfCols={9}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer => {
          const { width: answerWidth, height: answerLength } = getRectangleDimensions(userAnswer);
          return arraysHaveSameContentsUnordered([answerWidth, answerLength], [width, length]);
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithAreaOfX(area)
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawPolygons',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
