import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD, MULT } from '../../../../constants';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { isSquare } from '../../../../utils/factors';
import { z } from 'zod';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { nestedArrayHasNoDuplicates } from '../../../../utils/collections';
import { useMemo } from 'react';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'amP',
  description: 'amP',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      rowsAndColsA: z.number().int().min(1).max(4),
      rowsB: z.number().int().min(1).max(8),
      colsB: z.number().int().min(1).max(4),
      rowsC: z.number().int().min(1).max(8),
      colsC: z.number().int().min(1).max(4),
      rowsD: z.number().int().min(1).max(8),
      colsD: z.number().int().min(1).max(4)
    })
    .refine(
      val => val.rowsB !== val.colsB && val.rowsC !== val.colsC && val.rowsD !== val.colsD,
      'rowsB must not equal colsB, rowsC must not equal colsC, and rowsD must not equal colsD'
    )
    .refine(
      val =>
        nestedArrayHasNoDuplicates(
          [
            [val.rowsB, val.colsB],
            [val.rowsC, val.colsC],
            [val.rowsD, val.colsD]
          ],
          true
        ),
      'The pairs [rowsB, colsB], [rowsC, colsC], [rowsD, colsD] must all be different.'
    )
    .refine(
      val => Math.sqrt(val.rowsB * val.colsB) !== Math.floor(Math.sqrt(val.rowsB * val.colsB)),
      'The product of rowsB and colsB must not be a square number.'
    )
    .refine(
      val => Math.sqrt(val.rowsC * val.colsC) !== Math.floor(Math.sqrt(val.rowsC * val.colsC)),
      'The product of rowsC and colsC must not be a square number.'
    )
    .refine(
      val => Math.sqrt(val.rowsD * val.colsD) !== Math.floor(Math.sqrt(val.rowsD * val.colsD)),
      'The product of rowsD and colsD must not be a square number.'
    ),
  simpleGenerator: () => {
    const { rowsAndColsA, rowsB, colsB, rowsC, colsC, rowsD, colsD } = rejectionSample(
      () => {
        const rowsAndColsA = randomIntegerInclusive(1, 4);

        const rowsB = randomIntegerInclusive(1, 8);
        const colsB = randomIntegerInclusive(1, 4, {
          constraint: x => x !== rowsB && !isSquare(x * rowsB)
        });

        const rowsC = randomIntegerInclusive(1, 8);
        const colsC = randomIntegerInclusive(1, 4, {
          constraint: x => x !== rowsC && !isSquare(x * rowsC)
        });

        const rowsD = randomIntegerInclusive(1, 8);
        const colsD = randomIntegerInclusive(1, 4, {
          constraint: x => x !== rowsD && !isSquare(x * rowsD)
        });

        return { rowsAndColsA, rowsB, colsB, rowsC, colsC, rowsD, colsD };
      },
      // Only permit them if [rowsB, colsB], [rowsC, colsC], [rowsD, colsD] are not identical.
      ({ rowsB, colsB, rowsC, colsC, rowsD, colsD }) =>
        nestedArrayHasNoDuplicates(
          [
            [rowsB, colsB],
            [rowsC, colsC],
            [rowsD, colsD]
          ],
          true
        )
    );

    return { rowsAndColsA, rowsB, colsB, rowsC, colsC, rowsD, colsD };
  },
  Component: props => {
    const {
      question: { rowsAndColsA, rowsB, colsB, rowsC, colsC, rowsD, colsD },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const colors = shuffle(Object.values(ArrayOfObjectsColors));

      const eqA = {
        rows: rowsAndColsA,
        columns: rowsAndColsA,
        product: rowsAndColsA * rowsAndColsA,
        isCorrect: true,
        color: colors[0]
      };
      const eqB = {
        rows: rowsB,
        columns: colsB,
        product: rowsB * colsB,
        isCorrect: false,
        color: colors[1]
      };
      const eqC = {
        rows: rowsC,
        columns: colsC,
        product: rowsC * colsC,
        isCorrect: false,
        color: colors[2]
      };
      const eqD = {
        rows: rowsD,
        columns: colsD,
        product: rowsD * colsD,
        isCorrect: false,
        color: colors[3]
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [colsB, colsC, colsD, props.question, rowsAndColsA, rowsB, rowsC, rowsD]);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.whichOfTheseIsSquareNumber()} ${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.whichOfTheseIsSquareNumber()}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          // Subract 20 to give a margin of 10
          const availableHeight = dimens.height - 20;
          // Arrays to take up five-sixths of their available height.
          // All four arrays' counters to be the same size, based on the tallest array's number of rows.
          const counterSize = ((availableHeight / 6) * 5) / Math.max(...eqs.map(eq => eq.rows));

          return eqs.map(equation => ({
            value: equation,
            component: (
              <View
                style={{
                  height: availableHeight,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Text
                  variant="WRN400"
                  style={{ textAlign: 'center', fontSize: 28, lineHeight: 30 }}
                >
                  {equation.product.toLocaleString()}
                </Text>
                <ArrayOfObjects
                  dimens={dimens}
                  rows={equation.rows}
                  columns={equation.columns}
                  color={equation.color}
                  counterSize={counterSize}
                  containerStyle={{ justifyContent: 'flex-start' }}
                />
              </View>
            )
          }));
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question1v2 = newQuestionContent({
  uid: 'amP2',
  description: 'amP2',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      rowsAndColsA: z.number().int().min(1).max(4),
      rowsOrColsB1: z.number().int().min(1).max(8),
      rowsOrColsB2: z.number().int().min(1).max(4),
      rowsOrColsC1: z.number().int().min(1).max(8),
      rowsOrColsC2: z.number().int().min(1).max(4),
      rowsOrColsD1: z.number().int().min(1).max(8),
      rowsOrColsD2: z.number().int().min(1).max(4)
    })
    .refine(
      val =>
        val.rowsOrColsB1 !== val.rowsOrColsB2 &&
        val.rowsOrColsC1 !== val.rowsOrColsC2 &&
        val.rowsOrColsD1 !== val.rowsOrColsD2,
      'rowsOrColsB1 must not equal rowsOrColsB2, rowsOrColsC1 must not equal rowsOrColsC2, and rowsOrColsD1 must not equal rowsOrColsD2'
    )
    .refine(
      val =>
        nestedArrayHasNoDuplicates(
          [
            [val.rowsOrColsB1, val.rowsOrColsB2],
            [val.rowsOrColsC1, val.rowsOrColsC2],
            [val.rowsOrColsD1, val.rowsOrColsD2]
          ],
          true
        ),
      'The pairs [rowsOrColsB1, rowsOrColsB2], [rowsOrColsC1, rowsOrColsC2], [rowsOrColsD1, rowsOrColsD2] must all be different.'
    )
    .refine(
      val =>
        Math.sqrt(val.rowsOrColsB1 * val.rowsOrColsB2) !==
        Math.floor(Math.sqrt(val.rowsOrColsB1 * val.rowsOrColsB2)),
      'The product of rowsOrColsB1 and rowsOrColsB2 must not be a square number.'
    )
    .refine(
      val =>
        Math.sqrt(val.rowsOrColsC1 * val.rowsOrColsC2) !==
        Math.floor(Math.sqrt(val.rowsOrColsC1 * val.rowsOrColsC2)),
      'The product of rowsOrColsC1 and rowsOrColsC2 must not be a square number.'
    )
    .refine(
      val =>
        Math.sqrt(val.rowsOrColsD1 * val.rowsOrColsD2) !==
        Math.floor(Math.sqrt(val.rowsOrColsD1 * val.rowsOrColsD2)),
      'The product of rowsOrColsD1 and rowsOrColsD2 must not be a square number.'
    ),
  simpleGenerator: () => {
    const {
      rowsAndColsA,
      rowsOrColsB1,
      rowsOrColsB2,
      rowsOrColsC1,
      rowsOrColsC2,
      rowsOrColsD1,
      rowsOrColsD2
    } = rejectionSample(
      () => {
        const rowsAndColsA = randomIntegerInclusive(1, 4);

        const rowsOrColsB1 = randomIntegerInclusive(1, 8);

        const rowsOrColsC1 = randomIntegerInclusive(1, 8);

        const rowsOrColsD1 = randomIntegerInclusive(1, 8);

        const [rowsOrColsB2, rowsOrColsC2, rowsOrColsD2] = [
          rowsOrColsB1,
          rowsOrColsC1,
          rowsOrColsD1
        ].map(idx =>
          randomIntegerInclusive(1, 4, {
            constraint: x => x !== idx && !isSquare(x * idx)
          })
        );

        return {
          rowsAndColsA,
          rowsOrColsB1,
          rowsOrColsB2,
          rowsOrColsC1,
          rowsOrColsC2,
          rowsOrColsD1,
          rowsOrColsD2
        };
      },
      // Only permit them if [rowsOrColsB1, rowsOrColsB2], [rowsOrColsC1, rowsOrColsC2], [rowsOrColsD1, colsD] are not identical.
      ({ rowsOrColsB1, rowsOrColsB2, rowsOrColsC1, rowsOrColsC2, rowsOrColsD1, rowsOrColsD2 }) =>
        nestedArrayHasNoDuplicates(
          [
            [rowsOrColsB1, rowsOrColsB2],
            [rowsOrColsC1, rowsOrColsC2],
            [rowsOrColsD1, rowsOrColsD2]
          ],
          true
        )
    );

    return {
      rowsAndColsA,
      rowsOrColsB1,
      rowsOrColsB2,
      rowsOrColsC1,
      rowsOrColsC2,
      rowsOrColsD1,
      rowsOrColsD2
    };
  },
  Component: props => {
    const {
      question: {
        rowsAndColsA,
        rowsOrColsB1,
        rowsOrColsB2,
        rowsOrColsC1,
        rowsOrColsC2,
        rowsOrColsD1,
        rowsOrColsD2
      },
      translate,
      displayMode
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const colors = shuffle(Object.values(ArrayOfObjectsColors));

      const eqA = {
        rows: rowsAndColsA,
        columns: rowsAndColsA,
        product: rowsAndColsA * rowsAndColsA,
        isCorrect: true,
        color: colors[0]
      };
      const eqB = {
        rows: rowsOrColsB1 <= rowsOrColsB2 ? rowsOrColsB1 : rowsOrColsB2,
        columns: rowsOrColsB2 >= rowsOrColsB1 ? rowsOrColsB2 : rowsOrColsB1,
        product: rowsOrColsB1 * rowsOrColsB2,
        isCorrect: false,
        color: colors[1]
      };
      const eqC = {
        rows: rowsOrColsC1 <= rowsOrColsC2 ? rowsOrColsC1 : rowsOrColsC2,
        columns: rowsOrColsC2 >= rowsOrColsC1 ? rowsOrColsC2 : rowsOrColsC1,
        product: rowsOrColsC1 * rowsOrColsC2,
        isCorrect: false,
        color: colors[2]
      };
      const eqD = {
        rows: rowsOrColsD1 <= rowsOrColsD2 ? rowsOrColsD1 : rowsOrColsD2,
        columns: rowsOrColsD2 >= rowsOrColsD1 ? rowsOrColsD2 : rowsOrColsD1,
        product: rowsOrColsD1 * rowsOrColsD2,
        isCorrect: false,
        color: colors[3]
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      props.question,
      rowsAndColsA,
      rowsOrColsB1,
      rowsOrColsB2,
      rowsOrColsC1,
      rowsOrColsC2,
      rowsOrColsD1,
      rowsOrColsD2
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheSquareNumber()}
        pdfTitle={translate.instructions.circleTheSquareNumber()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          // Subract 20 to give a margin of 10
          const availableHeight = dimens.height - 20;
          const availableWidth = dimens.width - 20;

          const possibleHeight = Math.floor(
            ((availableHeight / 6) * 5) / Math.max(...eqs.map(eq => eq.rows))
          );

          const possibleWidth = Math.floor(
            ((availableWidth / 6) * 5) / Math.max(...eqs.map(eq => eq.columns))
          );

          const counterSize = Math.min(possibleHeight, possibleWidth);

          return eqs.map(equation => ({
            value: equation,
            component: (
              <View
                style={{
                  height: availableHeight,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Text
                  variant="WRN400"
                  style={{
                    textAlign: 'center',
                    fontSize: displayMode === 'digital' ? 28 : 50,
                    lineHeight: displayMode === 'digital' ? 30 : 75
                  }}
                >
                  {equation.product.toLocaleString()}
                </Text>
                <ArrayOfObjects
                  dimens={dimens}
                  rows={equation.rows}
                  columns={equation.columns}
                  color={equation.color}
                  counterSize={counterSize}
                  containerStyle={{ justifyContent: 'flex-start' }}
                />
              </View>
            )
          }));
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'amQ',
  description: 'amQ',
  keywords: ['Square', 'Array'],
  schema: z.object({
    squareRoot: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const squareRoot = randomIntegerInclusive(2, 6);

    return { squareRoot };
  },

  Component: props => {
    const {
      question: { squareRoot },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.useTheArrayToCompleteNumSentence()}
        testCorrect={[
          [(squareRoot ** 2).toString(), squareRoot.toString(), squareRoot.toString()],
          [(squareRoot ** 2).toString()]
        ]}
        sentences={[
          `<ans/> = <ans/> ${MULT} <ans/>`,
          translate.answerSentences.ansIsASquareNumber()
        ]}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => (
          <ArrayOfObjects dimens={dimens} rows={squareRoot} columns={squareRoot} />
        )}
      />
    );
  },
  questionHeight: 1000
});

const Question2v2 = newQuestionContent({
  uid: 'amQ2',
  description: 'amQ2',
  keywords: ['Square', 'Array'],
  schema: z.object({
    squareRoot: z.number().int().min(2).max(6),
    counterColor: z.enum(['blue', 'green', 'purple', 'red', 'yellow'])
  }),
  simpleGenerator: () => {
    const squareRoot = randomIntegerInclusive(2, 6);

    const counterColor = getRandomFromArray(['blue', 'green', 'purple', 'red', 'yellow'] as const);

    return { squareRoot, counterColor };
  },

  Component: props => {
    const {
      question: { squareRoot, counterColor },
      translate
    } = props;

    const arrayColor = (() => {
      switch (counterColor) {
        case 'blue':
          return ArrayOfObjectsColors.arrayBlue;
        case 'green':
          return ArrayOfObjectsColors.arrayGreen;
        case 'purple':
          return ArrayOfObjectsColors.arrayPurple;
        case 'red':
          return ArrayOfObjectsColors.arrayRed;
        case 'yellow':
          return ArrayOfObjectsColors.arrayYellow;
      }
    })();

    return (
      <QF1ContentAndSentences
        title={translate.instructions.useTheArrayToCompleteNumSentence()}
        testCorrect={[
          [(squareRoot ** 2).toString(), squareRoot.toString(), squareRoot.toString()],
          [(squareRoot ** 2).toString()]
        ]}
        sentences={[
          `<ans/> = <ans/> ${MULT} <ans/>`,
          translate.answerSentences.ansIsASquareNumber()
        ]}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => (
          <ArrayOfObjects
            dimens={dimens}
            rows={squareRoot}
            columns={squareRoot}
            color={arrayColor}
          />
        )}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'amR',
  description: 'amR',
  keywords: ['Square'],
  schema: z.object({
    number: z.number().min(3).max(36)
  }),
  simpleGenerator: () => {
    // We want a ratio of 1:2 for square numbers to non-square numbers to be generated
    const isSquareNum = getRandomFromArray([true, false, false]);

    const number = randomIntegerInclusive(3, 36, {
      constraint: x => isSquare(x) === isSquareNum
    });

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const numOfRows = 6;
    const numOfCols = 10;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.isXSquareNumber(
          number
        )} ${translate.instructions.selectTheAnswer()}`}
        pdfTitle={`${translate.instructions.isXSquareNumber(
          number
        )} ${translate.instructions.circleCorrectAnswer()}`}
        correctAnswer={isSquare(number)}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        content={({ dimens }) => (
          <BarModelInteractiveWithState
            id="barModel"
            numberOfRows={numOfRows}
            numberOfCols={numOfCols}
            tableWidth={dimens.width}
            tableHeight={dimens.height}
            isSquareGrid
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

// Question4 exported to Q anJ
const Question4v2 = newQuestionContent({
  uid: 'amS2',
  description: 'amS',
  keywords: ['Square', 'Notation'],
  schema: z.object({
    squareVar: z.number().min(3).max(9)
  }),
  simpleGenerator: () => {
    const squareVar = randomIntegerInclusive(3, 9);

    return { squareVar };
  },
  Component: props => {
    const {
      question: { squareVar },
      translate
    } = props;

    const itemA = {
      string: `${squareVar.toLocaleString()} ${MULT} ${squareVar.toLocaleString()}`,
      value: 'A'
    };

    const itemB = {
      string: `${squareVar.toLocaleString()} ${MULT} ${(2).toLocaleString()}`,
      value: 'B'
    };

    const itemC = {
      string: `${squareVar.toLocaleString()} ${ADD} ${squareVar.toLocaleString()}`,
      value: 'C'
    };

    const itemD = {
      string: `${squareVar.toLocaleString()} ${MULT} ${(10).toLocaleString()} ${ADD} ${(2).toLocaleString()}`,
      value: 'D'
    };

    const answers = shuffle([itemA, itemB, itemC, itemD], { random: seededRandom(props.question) });

    return (
      <QF10SelectNumbers
        title={translate.instructions.interpretSquareNumberNotation(squareVar)}
        pdfTitle={translate.instructions.interpretSquareNumberNotationPDF(squareVar)}
        testCorrect={[itemA.value]}
        items={answers.map(({ string, value }) => ({
          value,
          component: string
        }))}
      />
    );
  }
});
export const amS2 = Question4v2;

// Question4 exported to Q anJ
const Question4 = newQuestionContent({
  uid: 'amS',
  description: 'amS',
  keywords: ['Square', 'Notation'],
  schema: z.object({
    squareVar: z.number().min(3).max(9)
  }),
  simpleGenerator: () => {
    const squareVar = randomIntegerInclusive(3, 9);

    return { squareVar };
  },
  Component: props => {
    const {
      question: { squareVar },
      translate
    } = props;

    const answers = shuffle(
      [
        `${squareVar} ${MULT} ${squareVar}`,
        `${squareVar} ${MULT} ${(2).toLocaleString()}`,
        `${squareVar} ${ADD} ${squareVar}`,
        `${squareVar} ${MULT} ${(10).toLocaleString()} ${ADD} ${(2).toLocaleString()}`
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.interpretSquareNumberNotation(squareVar)}
        pdfTitle={`${translate.instructions.interpretSquareNumberNotation(
          squareVar
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={[`${squareVar} ${MULT} ${squareVar}`]}
        multiSelect
        items={answers.map(answer => ({
          value: answer,
          component: answer
        }))}
      />
    );
  }
});
export const amS = Question4;

const Question5 = newQuestionContent({
  uid: 'amT',
  description: 'amT',
  keywords: ['Square'],
  schema: z.object({
    numberA1: z.number().int().min(1).max(3),
    numberB1: z.number().int().min(4).max(6),
    numberC1: z.number().int().min(7).max(10)
  }),
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(1, 3);

    const numberB1 = randomIntegerInclusive(4, 6);

    const numberC1 = randomIntegerInclusive(7, 10);

    return { numberA1, numberB1, numberC1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1 },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={[
          [numberA1.toString(), numberA1.toString(), Math.pow(numberA1, 2).toString()],
          [numberB1.toString(), numberB1.toString(), Math.pow(numberB1, 2).toString()],
          [numberC1.toString(), numberC1.toString(), Math.pow(numberC1, 2).toString()]
        ]}
        sentences={[
          `${numberA1}² = <ans/> ${MULT} <ans/> = <ans/>`,
          `${numberB1}² = <ans/> ${MULT} <ans/> = <ans/>`,
          `${numberC1}² = <ans/> ${MULT} <ans/> = <ans/>`
        ]}
        {...props}
      />
    );
  }
});

// Question6 exported to Q anM
const Question6 = newQuestionContent({
  uid: 'amU',
  description: 'amU',
  keywords: ['Square'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(100))
      .length(8)
      .refine(
        numbers => numbers.filter(x => isSquare(x)).length >= 5,
        'There are at least 5 square numbers'
      )
  }),
  simpleGenerator: () => {
    const smallSquares = randomUniqueIntegersInclusive(1, 49, 4, {
      constraint: x => isSquare(x)
    });
    const bigSquare = randomIntegerInclusive(51, 100, {
      constraint: x => isSquare(x)
    });

    const extras = randomUniqueIntegersInclusive(2, 100, 3, {
      constraint: x => ![...smallSquares, bigSquare].includes(x)
    });

    const numbers = shuffle([...smallSquares, ...extras, bigSquare]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const correct = numbers.filter(x => isSquare(x));

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumsAreSquare()}
        pdfTitle={translate.instructions.whichNumsAreSquarePDF()}
        testCorrect={correct}
        multiSelect
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});
export const amU = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SquareNumbers',
  questionTypes: [Question1v2, Question2v2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question1, Question2, Question4]
});
export default SmallStep;
