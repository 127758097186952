import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { ScientificNotation, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { useMemo } from 'react';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQW',
  description: 'aQW',
  keywords: ['Thousandths', 'Place value chart', 'Compare'],
  schema: z
    .object({
      number1Thousandths: z.number().min(1).max(9),
      number2Thousandths: z.number().min(1).max(9),
      smallerOrGreater: z.enum(['smaller', 'greater'])
    })
    .refine(
      val => val.number1Thousandths !== val.number2Thousandths,
      'number1Thousandths and number2Thousandths must be different.'
    ),
  simpleGenerator: () => {
    const [number1Thousandths, number2Thousandths] = randomUniqueIntegersInclusive(1, 9, 2);

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { number1Thousandths, number2Thousandths, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { number1Thousandths, number2Thousandths, smallerOrGreater },
      translate,
      displayMode
    } = props;

    const items = useMemo(
      () =>
        shuffle(
          [
            {
              number: number1Thousandths / 1000,
              isCorrect:
                smallerOrGreater === 'smaller'
                  ? number1Thousandths < number2Thousandths
                  : number1Thousandths > number2Thousandths
            },
            {
              number: number2Thousandths / 1000,
              isCorrect:
                smallerOrGreater === 'smaller'
                  ? number1Thousandths > number2Thousandths
                  : number1Thousandths < number2Thousandths
            }
          ],
          { random: seededRandom(props.question) }
        ),
      [number1Thousandths, number2Thousandths, props.question, smallerOrGreater]
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectSmallerNumber()
            : translate.instructions.selectGreaterNumber()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.whichNumberIsSmallerTickYourAnswer()
            : translate.instructions.whichNumberIsGreaterTickYourAnswer()
        }
        testCorrect={items.filter(item => item.isCorrect)}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item,
            component: (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(item.number)}
                columnsToShow={[0, -1, -2, -3]}
                dimens={{
                  height: displayMode === 'digital' ? dimens.height * 0.4 : dimens.height * 0.7,
                  width: displayMode === 'digital' ? dimens.width * 0.9 : dimens.width * 0.95
                }}
                counterVariant={'decimalCounter'}
                headerVariant={'shortName'}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQX',
  description: 'aQX',
  keywords: ['Thousandths', 'Hundredths', 'Tenths', 'Place value chart', 'Compare'],
  schema: z
    .object({
      number1Thousandths: z
        .number()
        .min(1)
        .max(999)
        .refine(val => val % 10 !== 0, 'number1Thousandths must not be a multiple of 10'),
      number2Thousandths: z
        .number()
        .min(1)
        .max(999)
        .refine(val => val % 10 !== 0, 'number2Thousandths must not be a multiple of 10'),
      smallerOrGreater: z.enum(['smaller', 'greater'])
    })
    .refine(
      val => val.number1Thousandths !== val.number2Thousandths,
      'number1Thousandths and number2Thousandths must be different.'
    ),
  simpleGenerator: () => {
    const [number1Thousandths, number2Thousandths] = randomUniqueIntegersInclusive(1, 999, 2, {
      // Ensures both numbers have three decimal places:
      constraint: x => x % 10 !== 0
    });

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { number1Thousandths, number2Thousandths, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { number1Thousandths, number2Thousandths, smallerOrGreater },
      translate,
      displayMode
    } = props;

    const items = useMemo(
      () =>
        shuffle(
          [
            {
              number: number1Thousandths / 1000,
              isCorrect:
                smallerOrGreater === 'smaller'
                  ? number1Thousandths < number2Thousandths
                  : number1Thousandths > number2Thousandths
            },
            {
              number: number2Thousandths / 1000,
              isCorrect:
                smallerOrGreater === 'smaller'
                  ? number1Thousandths > number2Thousandths
                  : number1Thousandths < number2Thousandths
            }
          ],
          { random: seededRandom(props.question) }
        ),
      [number1Thousandths, number2Thousandths, props.question, smallerOrGreater]
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectSmallerNumber()
            : translate.instructions.selectGreaterNumber()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.whichNumberIsSmallerTickYourAnswer()
            : translate.instructions.whichNumberIsGreaterTickYourAnswer()
        }
        testCorrect={items.filter(item => item.isCorrect)}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item,
            component: (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(item.number)}
                columnsToShow={[0, -1, -2, -3]}
                dimens={{
                  height: displayMode === 'digital' ? dimens.height * 0.4 : dimens.height * 0.7,
                  width: displayMode === 'digital' ? dimens.width * 0.9 : dimens.width * 0.95
                }}
                counterVariant={'decimalCounter'}
                headerVariant={'shortName'}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aQY',
  description: 'aQY',
  keywords: ['Decimals', 'Thousandths', 'Hundredths', 'Tenths', 'Compare'],
  schema: z.object({
    number1Thousandths: z
      .number()
      .min(1)
      .max(999)
      .refine(val => val % 10 !== 0, 'number1Thousandths must not be a multiple of 10'),
    number2Thousandths: z
      .number()
      .min(1)
      .max(999)
      .refine(val => val % 10 !== 0, 'number2Thousandths must not be a multiple of 10'),
    number3Thousandths: z
      .number()
      .min(1)
      .max(999)
      .refine(val => val % 10 !== 0, 'number3Thousandths must not be a multiple of 10'),
    number4Thousandths: z
      .number()
      .min(1)
      .max(999)
      .refine(val => val % 10 !== 0, 'number4Thousandths must not be a multiple of 10'),
    number5Thousandths: z
      .number()
      .min(51)
      .max(9949)
      .refine(val => val % 10 !== 0, 'number5Thousandths must not be a multiple of 10'),
    number6Thousandths: z
      .number()
      .min(51)
      .max(9949)
      .refine(val => val % 10 !== 0, 'number6Thousandths must not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1Thousandths = randomIntegerInclusive(1, 999, {
      constraint: x => x % 10 !== 0
    });

    const number2Thousandths = randomIntegerInclusive(1, 999, {
      constraint: x => x % 10 !== 0
    });

    const number3And4TenthsAsThousandths = randomIntegerInclusiveStep(0, 900, 100);

    const number3Thousandths = randomIntegerInclusive(
      number3And4TenthsAsThousandths + 1,
      number3And4TenthsAsThousandths + 99,
      {
        constraint: x => x % 10 !== 0
      }
    );

    const number4Thousandths = randomIntegerInclusive(
      number3And4TenthsAsThousandths + 1,
      number3And4TenthsAsThousandths + 99,
      {
        constraint: x => x % 10 !== 0
      }
    );

    const number5And6TenthsAsThousandths = randomIntegerInclusiveStep(100, 9900, 100);

    const number5Thousandths = randomIntegerInclusive(
      number5And6TenthsAsThousandths - 49,
      number5And6TenthsAsThousandths + 49,
      {
        constraint: x => x % 10 !== 0
      }
    );

    const number6Thousandths = randomIntegerInclusive(
      number5And6TenthsAsThousandths - 49,
      number5And6TenthsAsThousandths + 49,
      {
        constraint: x => x % 10 !== 0
      }
    );

    return {
      number1Thousandths,
      number2Thousandths,
      number3Thousandths,
      number4Thousandths,
      number5Thousandths,
      number6Thousandths
    };
  },
  Component: props => {
    const {
      question: {
        number1Thousandths,
        number2Thousandths,
        number3Thousandths,
        number4Thousandths,
        number5Thousandths,
        number6Thousandths
      },
      translate,
      displayMode
    } = props;

    // Not shuffling these as complexity of comparisons increases between each pair of numbers:
    const statements = [
      {
        lhsComponent: (
          <Text
            style={{
              width: displayMode === 'digital' ? 100 : 200,
              textAlign: 'right',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {(number1Thousandths / 1000).toLocaleString()}
          </Text>
        ),
        rhsComponent: (
          <Text
            style={{
              width: displayMode === 'digital' ? 100 : 200,
              textAlign: 'left',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {(number2Thousandths / 1000).toLocaleString()}
          </Text>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(number1Thousandths, number2Thousandths)
      },
      {
        lhsComponent: (
          <Text
            style={{
              width: displayMode === 'digital' ? 100 : 200,
              textAlign: 'right',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {(number3Thousandths / 1000).toLocaleString()}
          </Text>
        ),
        rhsComponent: (
          <Text
            style={{
              width: displayMode === 'digital' ? 100 : 200,
              textAlign: 'left',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {(number4Thousandths / 1000).toLocaleString()}
          </Text>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(number3Thousandths, number4Thousandths)
      },
      {
        lhsComponent: (
          <Text
            style={{
              width: displayMode === 'digital' ? 100 : 200,
              textAlign: 'right',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {(number5Thousandths / 1000).toLocaleString()}
          </Text>
        ),
        rhsComponent: (
          <Text
            style={{
              width: displayMode === 'digital' ? 100 : 200,
              textAlign: 'left',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {(number6Thousandths / 1000).toLocaleString()}
          </Text>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(number5Thousandths, number6Thousandths)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMakeStatementsCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementsCorrect()}
        items={['<', '>', '=']}
        itemVariant="square"
        statements={statements}
        moveOrCopy="copy"
        actionPanelVariant="end"
        statementStyle={{ justifyContent: 'center' }}
        pdfLayout="itemsHidden"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3v2 = newQuestionContent({
  uid: 'aQY2',
  description: 'aQY',
  keywords: ['Decimals', 'Thousandths', 'Hundredths', 'Tenths', 'Compare'],
  schema: z.object({
    number1AThousandths: z
      .number()
      .min(1)
      .max(999)
      .refine(val => val % 10 !== 0, 'number1AThousandths must not be a multiple of 10'),
    number2AThousandths: z
      .number()
      .min(1)
      .max(999)
      .refine(val => val % 10 !== 0, 'number2AThousandths must not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number1AThousandths = randomIntegerInclusive(1, 999, {
      constraint: x => x % 10 !== 0
    });

    const number2AThousandths = randomIntegerInclusive(1, 999, {
      constraint: x => x % 10 !== 0
    });

    return {
      number1AThousandths,
      number2AThousandths
    };
  },
  Component: props => {
    const {
      question: { number1AThousandths, number2AThousandths },
      translate
    } = props;

    const sentence = `${(number1AThousandths / 1000).toLocaleString()}  <ans />  ${(
      number2AThousandths / 1000
    ).toLocaleString()}`;

    const answer = lessThanGreaterThanOrEqualTo(number1AThousandths, number2AThousandths);

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragLessThanGreaterThanOrEqualsToMakeStatementCorrect()}
        pdfTitle={translate.instructions.useInequalitySymbolsToMakeStatementCorrect()}
        items={['<', '>', '=']}
        actionPanelVariant="end"
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aQZ',
  description: 'aQZ',
  keywords: ['Thousandths', 'Hundredths', 'Tenths', 'Decimals', 'Compare'],
  schema: z.object({
    flourGrams: z
      .number()
      .int()
      .min(501)
      .max(3501)
      .refine(val => val % 10 !== 0, 'flourGrams must not be a multiple of 10'),
    sugarGrams: z
      .number()
      .int()
      .min(501)
      .max(3501)
      .refine(val => val % 10 !== 0, 'sugarGrams must not be a multiple of 10'),
    smallerOrGreater: z.enum(['smaller', 'greater'])
  }),
  simpleGenerator: () => {
    const flourGrams = randomIntegerInclusive(501, 3501, {
      constraint: x => x % 10 !== 0
    });

    // Ensure sugarGrams is never more than 300 grams away from flourGrams:
    const sugarLowerBound = flourGrams - 300 < 501 ? 501 : flourGrams - 300;
    const sugarUpperBound = flourGrams + 300 > 3501 ? 3501 : flourGrams + 300;

    const sugarGrams = randomIntegerInclusive(sugarLowerBound, sugarUpperBound, {
      constraint: x => x % 10 !== 0 && x !== flourGrams
    });

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { flourGrams, sugarGrams, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { flourGrams, sugarGrams, smallerOrGreater },
      translate
    } = props;

    const items = [
      {
        string: translate.food.ingredients.Flour(),
        isCorrect:
          smallerOrGreater === 'smaller' ? flourGrams < sugarGrams : flourGrams > sugarGrams
      },
      {
        string: translate.food.ingredients.Sugar(),
        isCorrect:
          smallerOrGreater === 'smaller' ? flourGrams > sugarGrams : flourGrams < sugarGrams
      }
    ];

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.aBagOfFlourHasMassXABagOfSugarHasMassYSelectSmaller(
                flourGrams / 1000,
                sugarGrams / 1000
              )
            : translate.instructions.aBagOfFlourHasMassXABagOfSugarHasMassYSelectGreater(
                flourGrams / 1000,
                sugarGrams / 1000
              )
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.aBagOfFlourHasMassXABagOfSugarHasMassYCircleSmaller(
                flourGrams / 1000,
                sugarGrams / 1000
              )
            : translate.instructions.aBagOfFlourHasMassXABagOfSugarHasMassYCircleGreater(
                flourGrams / 1000,
                sugarGrams / 1000
              )
        }
        testCorrect={items.filter(item => item.isCorrect).map(item => item.string)}
        numItems={2}
        renderItems={items.map(item => ({
          value: item.string,
          component: <Text variant="WRN700">{item.string}</Text>
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aQ0',
  description: 'aQ0',
  keywords: ['Decimals', 'Thousandths', 'Hundredths', 'Tenths', 'Order'],
  schema: z.object({
    distances: z
      .array(z.number().int().min(1).max(2999))
      .length(4)
      .refine(
        val => val.every(distance => distance % 10 !== 0),
        'Each distance must not be a multiple of 10'
      )
      .refine(val => arrayHasNoDuplicates(val), 'All distances must be different.'),
    shortestOrLongestFirst: z.enum(['shortest', 'longest'])
  }),
  simpleGenerator: () => {
    const distances = randomUniqueIntegersInclusive(1, 2999, 4, {
      constraint: x => x % 10 !== 0
    });

    const shortestOrLongestFirst = getRandomFromArray(['shortest', 'longest'] as const);

    return {
      distances,
      shortestOrLongestFirst
    };
  },
  Component: props => {
    const {
      question: { distances, shortestOrLongestFirst },
      translate
    } = props;

    const [distanceAMetres, distanceBMetres, distanceCMetres, distanceDMetres] = distances;

    const items = [
      {
        string: translate.units.numberOfKm(distanceAMetres / 1000),
        valueInM: distanceAMetres
      },
      {
        string: translate.units.numberOfKm(distanceBMetres / 1000),
        valueInM: distanceBMetres
      },
      {
        string: translate.units.numberOfKm(distanceCMetres / 1000),
        valueInM: distanceCMetres
      },
      {
        string: translate.units.numberOfKm(distanceDMetres / 1000),
        valueInM: distanceDMetres
      }
    ];

    return (
      <QF4DragOrderVertical
        title={
          shortestOrLongestFirst === 'shortest'
            ? translate.instructions.dragCardsToOrderDistancesStartWithShortest()
            : translate.instructions.dragCardsToOrderDistancesStartWithLongest()
        }
        pdfTitle={
          shortestOrLongestFirst === 'shortest'
            ? translate.instructions.useCardsToOrderDistancesStartWithShortest()
            : translate.instructions.useCardsToOrderDistancesStartWithLongest()
        }
        testCorrect={sortNumberArray(
          items.map(measurement => measurement.valueInM),
          shortestOrLongestFirst === 'shortest' ? 'ascending' : 'descending'
        )}
        items={items.map(measurement => ({
          value: measurement.valueInM,
          component: measurement.string
        }))}
        topLabel={
          shortestOrLongestFirst === 'shortest'
            ? translate.misc.Shortest()
            : translate.misc.Longest()
        }
        bottomLabel={
          shortestOrLongestFirst === 'shortest'
            ? translate.misc.Longest()
            : translate.misc.Shortest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aQ1',
  description: 'aQ1',
  keywords: ['Decimals', 'Thousandths', 'Hundredths', 'Tenths', 'Order'],
  schema: z.object({
    thousandths: z
      .array(z.number().int().min(2001).max(9999))
      .length(4)
      .refine(
        val => val.every(distance => distance % 10 !== 0),
        'Each thousandth must not be a multiple of 10'
      )
      .refine(val => arrayHasNoDuplicates(val), 'All thousandths must be different.'),
    smallestOrGreatestFirst: z.enum(['smallest', 'greatest'])
  }),
  simpleGenerator: () => {
    const wholes = randomIntegerInclusiveStep(2000, 9000, 1000);

    const thousandths = randomUniqueIntegersInclusive(wholes + 1, wholes + 999, 4, {
      constraint: x => x % 10 !== 0
    });

    const smallestOrGreatestFirst = getRandomFromArray(['smallest', 'greatest'] as const);

    return {
      thousandths,
      smallestOrGreatestFirst
    };
  },
  Component: props => {
    const {
      question: { thousandths, smallestOrGreatestFirst },
      translate
    } = props;

    const [number1Thousandths, number2Thousandths, number3Thousandths, number4Thousandths] =
      thousandths;

    const items = [
      {
        string: (number1Thousandths / 1000).toLocaleString(),
        value: number1Thousandths
      },
      {
        string: (number2Thousandths / 1000).toLocaleString(),
        value: number2Thousandths
      },
      {
        string: (number3Thousandths / 1000).toLocaleString(),
        value: number3Thousandths
      },
      {
        string: (number4Thousandths / 1000).toLocaleString(),
        value: number4Thousandths
      }
    ];

    return (
      <QF4DragOrderVertical
        title={
          smallestOrGreatestFirst === 'smallest'
            ? translate.instructions.dragCardsToOrderNumbersStartWithSmallest()
            : translate.instructions.dragCardsToOrderNumbersStartWithGreatest()
        }
        pdfTitle={
          smallestOrGreatestFirst === 'smallest'
            ? translate.instructions.useCardsToOrderNumbersStartWithSmallest()
            : translate.instructions.useCardsToOrderNumbersStartWithGreatest()
        }
        testCorrect={sortNumberArray(
          items.map(measurement => measurement.value),
          smallestOrGreatestFirst === 'smallest' ? 'ascending' : 'descending'
        )}
        items={items.map(measurement => ({
          value: measurement.value,
          component: measurement.string
        }))}
        topLabel={
          smallestOrGreatestFirst === 'smallest'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          smallestOrGreatestFirst === 'smallest'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderAndCompareDecimalsSameNumberOfDecimalPlaces',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
