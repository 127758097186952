// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MultiplesOf10 from './1MultiplesOf10';
import RelatedCalculations from './2RelatedCalculations';
import ReasoningAboutMultiplication from './3ReasoningAboutMultiplication';
import MultiplyA2DigitNumberByA1DigitNumberNoExchange from './4MultiplyA2DigitNumberByA1DigitNumberNoExchange';
import MultiplyA2DigitNumberByA1DigitNumberWithExchange from './5MultiplyA2DigitNumberByA1DigitNumberWithExchange';
import LinkMultiplicationAndDivision from './6LinkMultiplicationAndDivision';
import DivideA2DigitNumberByA1DigitNumberNoExchange from './7DivideA2DigitNumberByA1DigitNumberNoExchange';
import DivideA2DigitNumberByA1DigitNumberFlexiblePartitioning from './8DivideA2DigitNumberByA1DigitNumberFlexiblePartitioning';
import DivideA2DigitNumberByA1DigitNumberWithRemainders from './9DivideA2DigitNumberByA1DigitNumberWithRemainders';
import Scaling from './10Scaling';
import HowManyWays from './11HowManyWays';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionB',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    MultiplesOf10, // Step 1
    RelatedCalculations, // Step 2
    ReasoningAboutMultiplication, // Step 3
    MultiplyA2DigitNumberByA1DigitNumberNoExchange, // Step 4
    MultiplyA2DigitNumberByA1DigitNumberWithExchange, // Step 5
    LinkMultiplicationAndDivision, // Step 6
    DivideA2DigitNumberByA1DigitNumberNoExchange, // Step 7
    DivideA2DigitNumberByA1DigitNumberFlexiblePartitioning, // Step 8
    DivideA2DigitNumberByA1DigitNumberWithRemainders, // Step 9
    Scaling, // Step 10
    HowManyWays // Step 11
  ]
});
export default Block;
