import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TableWithLeftHeaders from '../../../../components/question/representations/TableWithLeftHeaders';
import { colors } from '../../../../theme/colors';
import { numberEnum } from '../../../../utils/zod';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { addDurationTo24hTime, displayDigitalTime } from '../../../../utils/time';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { busRoutePlaceNamesSchema, getRandomArrayOfBusRoutePlaces } from '../../../../utils/places';
import {
  getRandomRouteAndTimeByNumberOfStops,
  getRandomRouteByNumberOfStops,
  stationNamesSchema
} from '../../../../utils/stations';
import { arraysHaveSameContents, countRange } from '../../../../utils/collections';
import Table from '../../../../components/molecules/Table';
import TextStructure from '../../../../components/molecules/TextStructure';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSG',
  description: 'aSG',
  keywords: ['Timetable', 'Read', 'Interpret'],
  schema: z.object({
    answerOptionIndex: numberEnum([0, 1, 2, 3]),
    answerTimeIndex: numberEnum([0, 1, 2]),
    allTimetables: z.array(z.array(z.string())).length(4),
    options: z.array(busRoutePlaceNamesSchema).length(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingTimeMinutes = randomIntegerInclusiveStep(0, 30, 5);
    const intervalOne = randomIntegerInclusiveStep(10, 60, 5);
    const intervalTwo = randomIntegerInclusive(5, 15);
    const intervalThree = randomIntegerInclusive(5, 15);
    const intervalFour = randomIntegerInclusive(5, 25);
    const startingTime = displayDigitalTime(9, startingTimeMinutes);
    const options = getRandomArrayOfBusRoutePlaces(4);

    const timetableA = [
      startingTime,
      displayDigitalTime(...addDurationTo24hTime(9, startingTimeMinutes, intervalOne)),
      displayDigitalTime(...addDurationTo24hTime(9, startingTimeMinutes, intervalOne * 2))
    ];
    const timetableB = [
      displayDigitalTime(...addDurationTo24hTime(9, startingTimeMinutes, intervalTwo)),
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalOne + intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalOne * 2 + intervalTwo)
      )
    ];
    const timetableC = [
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalTwo + intervalThree)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalOne + intervalTwo + intervalThree)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          9,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree
        )
      )
    ];
    const timetableD = [
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalTwo + intervalThree + intervalFour)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          9,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree + intervalFour
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          9,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree + intervalFour
        )
      )
    ];

    const allTimetables = [timetableA, timetableB, timetableC, timetableD];

    const { answerOptionIndex, answerTimeIndex } = rejectionSample(
      () => {
        const answerOptionIndex = getRandomFromArray([0, 1, 2, 3] as const);

        const answerTimeIndex = getRandomFromArray([0, 1, 2] as const);

        const isIndexFirstOrLast =
          arraysHaveSameContents([answerOptionIndex, answerOptionIndex], [0, 0]) ||
          arraysHaveSameContents([answerOptionIndex, answerOptionIndex], [3, 2]);

        return { answerOptionIndex, answerTimeIndex, isIndexFirstOrLast };
      },
      ({ answerOptionIndex, answerTimeIndex, isIndexFirstOrLast }) =>
        allTimetables
          .flat()
          .filter(time => time === allTimetables[answerOptionIndex][answerTimeIndex]).length ===
          1 && !isIndexFirstOrLast
    );

    return {
      allTimetables,
      answerOptionIndex,
      answerTimeIndex,
      options
    };
  },
  Component: props => {
    const {
      question: { allTimetables, answerOptionIndex, answerTimeIndex, options },
      translate,
      displayMode
    } = props;

    const selectables = options.map(el => [el, el]);

    const busLeavingTime = allTimetables[answerOptionIndex][answerTimeIndex];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectWhichBusStopTheBusLeavesFromAtX(busLeavingTime)}
        pdfTitle={translate.instructions.circleWhichBusStopTheBusLeavesFromAtX(busLeavingTime)}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={[options[answerOptionIndex]]}
        leftContent={
          <MeasureView>
            {dimens => (
              <TableWithLeftHeaders
                style={{ width: dimens.width, height: dimens.height * 0.7 }}
                headers={options}
                items={allTimetables}
                headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
                textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aSH',
  description: 'aSH',
  keywords: ['Timetable', 'Read', 'Interpret'],
  schema: z.object({
    allTimetables: z.array(z.array(z.string())).length(4),
    answerOptionIndex: numberEnum([0, 1, 2, 3]),
    answerTimeIndex: numberEnum([0, 1, 2]),
    options: z.array(busRoutePlaceNamesSchema).length(4),
    isTopLine: z.boolean()
  }),
  simpleGenerator: () => {
    const startingTimeMinutes = randomIntegerInclusiveStep(0, 30, 5);
    const intervalOne = randomIntegerInclusiveStep(10, 60, 5);
    const intervalTwo = randomIntegerInclusive(5, 15);
    const intervalThree = randomIntegerInclusive(5, 15);
    const intervalFour = randomIntegerInclusive(5, 25);
    const startingTime = displayDigitalTime(9, startingTimeMinutes);
    const options = getRandomArrayOfBusRoutePlaces(4);

    const timetableA = [
      startingTime,
      displayDigitalTime(...addDurationTo24hTime(9, startingTimeMinutes, intervalOne)),
      displayDigitalTime(...addDurationTo24hTime(9, startingTimeMinutes, intervalOne * 2))
    ];
    const timetableB = [
      displayDigitalTime(...addDurationTo24hTime(9, startingTimeMinutes, intervalTwo)),
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalOne + intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalOne * 2 + intervalTwo)
      )
    ];
    const timetableC = [
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalTwo + intervalThree)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalOne + intervalTwo + intervalThree)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          9,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree
        )
      )
    ];
    const timetableD = [
      displayDigitalTime(
        ...addDurationTo24hTime(9, startingTimeMinutes, intervalTwo + intervalThree + intervalFour)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          9,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree + intervalFour
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          9,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree + intervalFour
        )
      )
    ];

    const allTimetables = [timetableA, timetableB, timetableC, timetableD];

    const { answerOptionIndex, answerTimeIndex } = rejectionSample(
      () => {
        const answerOptionIndex = getRandomFromArray([0, 1, 2, 3] as const);

        const answerTimeIndex = getRandomFromArray([0, 1, 2] as const);

        return { answerOptionIndex, answerTimeIndex };
      },
      ({ answerOptionIndex, answerTimeIndex }) =>
        allTimetables
          .flat()
          .filter(time => time === allTimetables[answerOptionIndex][answerTimeIndex]).length === 1
    );

    const isTopLine = getRandomBoolean();

    return {
      allTimetables,
      answerOptionIndex,
      answerTimeIndex,
      options,
      isTopLine
    };
  },
  Component: props => {
    const {
      question: { allTimetables, answerOptionIndex, answerTimeIndex, options },
      translate,
      displayMode
    } = props;

    const answer = allTimetables[answerOptionIndex][answerTimeIndex].split(':');

    const hourCheck = (userAnswer: string): boolean => {
      if (answer[0][0] === '0') {
        return userAnswer === answer[0] || userAnswer === answer[0][1];
      } else {
        return userAnswer === answer[0];
      }
    };

    const rowData = countRange(4).map(i => [options[i], ...allTimetables[i]]);

    const tableContents = [
      ['', translate.transport.busN(1), translate.transport.busN(2), translate.transport.busN(3)],
      ...rowData
    ];

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> : <ans/>`}
        title={translate.instructions.whatTimeDoesBusXArriveAtTheY(
          (answerTimeIndex + 1).toLocaleString(),
          options[answerOptionIndex]
        )}
        inputMaxCharacters={2}
        testCorrect={userAnswer => hourCheck(userAnswer[0]) && userAnswer[1] === answer[1]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        questionHeight={900}
        customMarkSchemeAnswer={{ answersToDisplay: [answer[0], answer[1]] }}
        Content={
          <Table
            items={tableContents.map((row, rowIndex) =>
              row.map((cell, cellIndex) => (
                <View
                  key={`${rowIndex}-${cellIndex}`}
                  style={{
                    backgroundColor:
                      rowIndex === 0 || cellIndex === 0 ? colors.tableHeaderBackground : undefined,
                    width: displayMode === 'digital' ? 150 : 350,
                    height: displayMode === 'digital' ? 60 : 80,
                    justifyContent: 'center'
                  }}
                >
                  <TextStructure
                    textVariant={'WRN400'}
                    textStyle={{
                      fontSize: displayMode === 'digital' ? 26 : 50,
                      lineHeight: 30,
                      textAlign: 'center',
                      color: rowIndex === 0 || cellIndex === 0 ? colors.white : colors.black
                    }}
                    fractionTextStyle={{
                      fontSize: displayMode === 'digital' ? 26 : 50,
                      fontWeight: '400',
                      color: rowIndex === 0 ? colors.white : colors.black
                    }}
                    fractionDividerStyle={{ marginVertical: 2 }}
                    sentence={cell}
                  />
                </View>
              ))
            )}
          />
        }
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aSI',
  description: 'aSI',
  keywords: ['Timetable', 'Read', 'Interpret'],
  schema: z.object({
    allTimetables: z.array(z.array(z.string())).length(4),
    answerOptionStartingIndex: numberEnum([0, 1, 2]),
    answerOptionEndingIndex: z.number().int().min(1).max(3),
    answerArrivalTimeIndex: numberEnum([0, 1, 2]),
    options: z.array(busRoutePlaceNamesSchema).length(4),
    characterName: nameSchema,
    showMoreIncorrectFromStartingPlace: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingTimeHours = randomIntegerInclusive(6, 10);
    const startingTimeMinutes = randomIntegerInclusiveStep(0, 30, 5);
    const intervalOne = randomIntegerInclusiveStep(10, 60, 5);
    const intervalTwo = randomIntegerInclusive(5, 15);
    const intervalThree = randomIntegerInclusive(5, 15);
    const intervalFour = randomIntegerInclusive(5, 25);
    const characterName = getRandomName();
    const showMoreIncorrectFromStartingPlace = getRandomBoolean();
    const options = getRandomArrayOfBusRoutePlaces(4);

    const startingTime = displayDigitalTime(startingTimeHours, startingTimeMinutes);

    const timetableA = [
      startingTime,
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne * 2)
      )
    ];
    const timetableB = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne + intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo
        )
      )
    ];
    const timetableC = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalTwo + intervalThree)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree
        )
      )
    ];
    const timetableD = [
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalTwo + intervalThree + intervalFour
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree + intervalFour
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree + intervalFour
        )
      )
    ];

    const allTimetables = [timetableA, timetableB, timetableC, timetableD];

    const { answerOptionEndingIndex, answerArrivalTimeIndex, answerOptionStartingIndex } =
      rejectionSample(
        () => {
          const answerOptionStartingIndex = getRandomFromArray([0, 1, 2] as const);

          const answerOptionEndingIndex = randomIntegerInclusive(answerOptionStartingIndex + 1, 3);

          const answerArrivalTimeIndex = getRandomFromArray([0, 1, 2] as const);

          return { answerOptionEndingIndex, answerArrivalTimeIndex, answerOptionStartingIndex };
        },
        ({ answerOptionEndingIndex, answerArrivalTimeIndex }) =>
          allTimetables
            .flat()
            .filter(time => time === allTimetables[answerOptionEndingIndex][answerArrivalTimeIndex])
            .length === 1
      );

    return {
      answerOptionStartingIndex,
      answerOptionEndingIndex,
      answerArrivalTimeIndex,
      options,
      characterName,
      showMoreIncorrectFromStartingPlace,
      allTimetables
    };
  },
  Component: props => {
    const {
      question: {
        answerOptionStartingIndex,
        answerOptionEndingIndex,
        answerArrivalTimeIndex,
        options,
        characterName,
        showMoreIncorrectFromStartingPlace,
        allTimetables
      },
      translate,
      displayMode
    } = props;

    const busArrivingTime = allTimetables[answerOptionEndingIndex][answerArrivalTimeIndex];

    const answer = allTimetables[answerOptionStartingIndex][answerArrivalTimeIndex];

    const remainingStartingPlaceOptions = shuffle(
      allTimetables[answerOptionStartingIndex].filter(el => el !== answer).map(el => [el, el]),
      { random: seededRandom(props.question) }
    );

    const endingPlaceOptions = shuffle(
      allTimetables[answerOptionEndingIndex]
        .filter(el => el !== busArrivingTime)
        .map(el => [el, el]),
      { random: seededRandom(props.question) }
    );

    const selectables = shuffle(
      [
        [answer, answer],
        remainingStartingPlaceOptions[0],
        endingPlaceOptions[0],
        showMoreIncorrectFromStartingPlace
          ? remainingStartingPlaceOptions[1]
          : endingPlaceOptions[1]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.characterGetsBusFromXToYTheyArriveAtYatZSelectTheTimeTheyGotOnTheBus(
          {
            character: characterName,
            placeA: options[answerOptionStartingIndex],
            placeB: options[answerOptionEndingIndex],
            time: busArrivingTime
          }
        )}
        pdfTitle={translate.instructions.characterGetsBusFromXToYTheyArriveAtYatZSelectTheTimeTheyGotOnTheBusPDF(
          {
            character: characterName,
            placeA: options[answerOptionStartingIndex],
            placeB: options[answerOptionEndingIndex],
            time: busArrivingTime
          }
        )}
        selectables={Object.fromEntries(selectables)}
        selectableStyle={{ gap: 16 }}
        correctAnswer={[answer]}
        leftContent={
          <MeasureView>
            {dimens => (
              <TableWithLeftHeaders
                style={{ width: dimens.width, height: dimens.height * 0.95 }}
                headers={options}
                items={allTimetables}
                headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
                textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aSJ',
  description: 'aSJ',
  keywords: ['Timetable', 'Read', 'Interpret'],
  schema: z.object({
    allTimetables: z.array(z.array(z.string())).length(4),
    answerOptionIndex: numberEnum([0, 1, 2, 3]),
    answerTimeIndex: numberEnum([0, 1, 2]),
    isTopLine: z.boolean(),
    options: z.array(stationNamesSchema).length(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingTimeHours = randomIntegerInclusive(12, 15);
    const startingTimeMinutes = randomIntegerInclusiveStep(0, 45, 15);
    const trainInterval = randomIntegerInclusiveStep(10, 60, 5);
    const { route: options, duration } = getRandomRouteAndTimeByNumberOfStops(4);
    const [stationIntervalOne, stationIntervalTwo, stationIntervalThree] = duration;

    const startingTime = displayDigitalTime(startingTimeHours, startingTimeMinutes);

    const timetableA = [
      startingTime,
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, trainInterval)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, trainInterval * 2)
      )
    ];
    const timetableB = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, stationIntervalOne)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          trainInterval + stationIntervalOne
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          trainInterval * 2 + stationIntervalOne
        )
      )
    ];
    const timetableC = [
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          stationIntervalOne + stationIntervalTwo
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          trainInterval + stationIntervalOne + stationIntervalTwo
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          trainInterval * 2 + stationIntervalOne + stationIntervalTwo
        )
      )
    ];
    const timetableD = [
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          stationIntervalOne + stationIntervalTwo + stationIntervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          trainInterval + stationIntervalOne + stationIntervalTwo + stationIntervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          trainInterval * 2 + stationIntervalOne + stationIntervalTwo + stationIntervalThree
        )
      )
    ];

    const allTimetables = [timetableA, timetableB, timetableC, timetableD];

    const { answerOptionIndex, answerTimeIndex, isTopLine } = rejectionSample(
      () => {
        const isTopLine = getRandomBoolean();

        const answerOptionIndex = isTopLine
          ? getRandomFromArray([1, 2, 3] as const)
          : getRandomFromArray([2, 3] as const);

        const answerTimeIndex = getRandomFromArray([0, 1, 2] as const);

        return { answerOptionIndex, answerTimeIndex, isTopLine };
      },
      ({ answerOptionIndex, answerTimeIndex }) =>
        allTimetables
          .flat()
          .filter(time => time === allTimetables[answerOptionIndex][answerTimeIndex]).length === 1
    );

    return {
      answerOptionIndex,
      answerTimeIndex,
      options,
      isTopLine,
      allTimetables
    };
  },
  Component: props => {
    const {
      question: { answerOptionIndex, answerTimeIndex, options, isTopLine, allTimetables },
      translate,
      displayMode
    } = props;

    const busLeavingTime = isTopLine
      ? allTimetables[0][answerTimeIndex]
      : allTimetables[1][answerTimeIndex];

    const answer = allTimetables[answerOptionIndex][answerTimeIndex].split(':');

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> : <ans/>`}
        title={translate.instructions.whatTimeDoesTheXTrainFromPlaceYArriveAtPlaceZ(
          busLeavingTime,
          isTopLine ? options[0] : options[1],
          options[answerOptionIndex]
        )}
        inputMaxCharacters={2}
        testCorrect={answer}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <MeasureView>
              <TableWithLeftHeaders
                style={{ ...dimens }}
                headers={options}
                items={allTimetables}
                headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
                textStyle={displayMode === 'digital' && { fontSize: 22 }}
              />
            </MeasureView>
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aSK',
  description: 'aSK',
  keywords: ['Timetable', 'Read', 'Interpret'],
  schema: z.object({
    options: z.array(stationNamesSchema).length(4),
    allTrainTimes: z.array(z.array(z.string()).length(3)),
    answerTrainTime: z.string()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingTimeHours = randomIntegerInclusive(12, 20);
    const startingTimeMinutes = randomIntegerInclusiveStep(0, 45, 15);
    const intervalOne = randomIntegerInclusiveStep(10, 60, 5);
    const intervalTwo = randomIntegerInclusive(5, 15);
    const intervalThree = randomIntegerInclusive(5, 15);
    const intervalFour = randomIntegerInclusive(5, 25);
    const options = getRandomRouteByNumberOfStops(4);

    const startingTime = displayDigitalTime(startingTimeHours, startingTimeMinutes);

    const timetableA = [
      startingTime,
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalTwo + intervalThree)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalTwo + intervalThree + intervalFour
        )
      )
    ];
    const timetableB = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne + intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree + intervalFour
        )
      )
    ];
    const timetableC = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne * 2)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree + intervalFour
        )
      )
    ];

    const allTrains = [timetableA, timetableB, timetableC].map(train => {
      const removeOneTime = getRandomBoolean();
      const timeToRemove = getRandomFromArray([0, 1, 2]);
      const timesToRemove = getRandomSubArrayFromArray([0, 1, 2], 2);

      const indexesToRemove = removeOneTime ? [timeToRemove] : timesToRemove;

      const updatedTimetable = train.map((trainTime, index) => {
        if (indexesToRemove.includes(index)) {
          return '-';
        }
        return trainTime;
      });
      return updatedTimetable;
    });

    const allTrainTimes = [
      [allTrains[0][0], allTrains[1][0], allTrains[2][0]],
      [allTrains[0][1], allTrains[1][1], allTrains[2][1]],
      [allTrains[0][2], allTrains[1][2], allTrains[2][2]],
      [allTrains[0][3], allTrains[1][3], allTrains[2][3]]
    ];

    const { answerTrainTime } = rejectionSample(
      () => {
        const answerTrainTime = getRandomFromArray(
          allTrainTimes.flatMap(timeTable => {
            return timeTable.filter(el => el !== '-');
          })
        ) as string;

        return { answerTrainTime };
      },
      ({ answerTrainTime }) =>
        allTrainTimes.flat().filter(time => time === answerTrainTime).length === 1
    );

    return {
      allTrainTimes,
      options,
      answerTrainTime
    };
  },
  Component: props => {
    const {
      question: { allTrainTimes, options, answerTrainTime },
      translate,
      displayMode
    } = props;

    const selectables = options.map(el => [el, el]);

    const answer = (() => {
      const indexOfTrain = allTrainTimes
        .map(timeTable => {
          const correctIndex = timeTable.findIndex(time => time === answerTrainTime);
          return correctIndex;
        })
        .filter(index => index !== -1)[0];

      const correctAnswersIndexes = allTrainTimes
        .map((timeTable, timeTableIndex) => {
          if (timeTable[indexOfTrain] === '-') {
            return timeTableIndex.toString();
          }
        })
        .filter(el => el?.length);

      const answers = options.filter((_, index) =>
        correctAnswersIndexes.includes(index.toString())
      );

      return answers;
    })();

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheStationsThatTheXTrainDoesNotStopAt(answerTrainTime)}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={answer}
        multiSelect
        leftContent={
          <MeasureView>
            {dimens => (
              <TableWithLeftHeaders
                style={{ width: dimens.width, height: dimens.height * 0.95 }}
                headers={options}
                items={allTrainTimes}
                headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
                textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aSK2',
  description: 'aSK',
  keywords: ['Timetable', 'Read', 'Interpret'],
  schema: z.object({
    options: z.array(stationNamesSchema).length(5),
    allTrainTimes: z.array(z.array(z.string()).length(3)),
    answerTrainTime: z.string()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingTimeHours = randomIntegerInclusive(12, 20);
    const startingTimeMinutes = randomIntegerInclusiveStep(0, 45, 15);
    const routeTimes = getRandomRouteAndTimeByNumberOfStops(5);
    const options = routeTimes.route;
    const gapToNextTrain = randomIntegerInclusiveStep(10, 60, 5);
    const [intervalOne, intervalTwo, intervalThree, intervalFour] = routeTimes.duration;

    const startingTime = displayDigitalTime(startingTimeHours, startingTimeMinutes);

    const timetableA = [
      startingTime,
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne + intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree + intervalFour
        )
      )
    ];

    const timetableB = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, gapToNextTrain)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          gapToNextTrain + intervalOne
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          gapToNextTrain + intervalOne + intervalTwo
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          gapToNextTrain + intervalOne + intervalTwo + intervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          gapToNextTrain + intervalOne + intervalTwo + intervalThree + intervalFour
        )
      )
    ];

    const timetableC = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, gapToNextTrain * 2)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          gapToNextTrain * 2 + intervalOne
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          gapToNextTrain * 2 + intervalOne + intervalTwo
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          gapToNextTrain * 2 + intervalOne + intervalTwo + intervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          gapToNextTrain * 2 + intervalOne + intervalTwo + intervalThree + intervalFour
        )
      )
    ];

    const allTrains = [timetableA, timetableB, timetableC].map(train => {
      const removeOneTime = getRandomBoolean();
      const timeToRemove = getRandomFromArray([1, 2, 3]);
      const timesToRemove = getRandomSubArrayFromArray([1, 2, 3], 2);

      const indexesToRemove = removeOneTime ? [timeToRemove] : timesToRemove;

      const updatedTimetable = train.map((trainTime, index) => {
        if (indexesToRemove.includes(index)) {
          return '-';
        }
        return trainTime;
      });
      return updatedTimetable;
    });

    const allTrainTimes = [
      [allTrains[0][0], allTrains[1][0], allTrains[2][0]],
      [allTrains[0][1], allTrains[1][1], allTrains[2][1]],
      [allTrains[0][2], allTrains[1][2], allTrains[2][2]],
      [allTrains[0][3], allTrains[1][3], allTrains[2][3]],
      [allTrains[0][4], allTrains[1][4], allTrains[2][4]]
    ];

    const { answerTrainTime } = rejectionSample(
      () => {
        const answerTrainTime = getRandomFromArray(allTrainTimes[0]) as string;

        return { answerTrainTime };
      },
      ({ answerTrainTime }) =>
        allTrainTimes.flat().filter(time => time === answerTrainTime).length === 1
    );

    return {
      allTrainTimes,
      options,
      answerTrainTime
    };
  },
  Component: props => {
    const {
      question: { allTrainTimes, options, answerTrainTime },
      translate,
      displayMode
    } = props;

    const selectables = options.filter((_val, i) => i !== 0).map(el => [el, el]);

    const answer = (() => {
      const indexOfTrain = allTrainTimes
        .map(timeTable => {
          const correctIndex = timeTable.findIndex(time => time === answerTrainTime);
          return correctIndex;
        })
        .filter(index => index !== -1)[0];

      const correctAnswersIndexes = allTrainTimes
        .map((timeTable, timeTableIndex) => {
          if (timeTable[indexOfTrain] === '-') {
            return timeTableIndex.toString();
          }
        })
        .filter(el => el?.length);

      const answers = options.filter((_, index) =>
        correctAnswersIndexes.includes(index.toString())
      );

      return answers;
    })();

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheStationsThatTheXTrainDoesNotStopAt(answerTrainTime)}
        pdfTitle={translate.instructions.circleTheStationsThatTheXTrainDoesNotStopAt(
          answerTrainTime
        )}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={answer}
        multiSelect
        leftContent={
          <MeasureView>
            {dimens => (
              <TableWithLeftHeaders
                style={{ width: dimens.width, height: dimens.height * 0.95 }}
                headers={options}
                items={allTrainTimes}
                headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
                textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aSL',
  description: 'aSL',
  keywords: ['Timetable', 'Read', 'Interpret'],
  schema: z.object({
    allTimetables: z.array(z.array(z.string())).length(4),
    answerOptionStartingIndex: numberEnum([0, 1, 2]),
    answerOptionEndingIndex: z.number().int().min(1).max(3),
    answerArrivalTimeIndex: numberEnum([0, 1, 2]),
    options: z.array(stationNamesSchema).length(4),
    characterName: nameSchema,
    showMoreIncorrectFromStartingPlace: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingTimeHours = randomIntegerInclusive(12, 20);
    const startingTimeMinutes = randomIntegerInclusiveStep(0, 45, 15);
    const intervalOne = getRandomFromArray([20, 30, 40] as const);
    const intervalTwo = randomIntegerInclusive(6, 14, { constraint: x => x !== 10 });
    const intervalThree = randomIntegerInclusive(11, 19, { constraint: x => x !== 15 });
    const intervalFour = randomIntegerInclusive(21, 29, { constraint: x => x !== 25 });
    const characterName = getRandomName();
    const showMoreIncorrectFromStartingPlace = getRandomBoolean();
    const options = getRandomRouteByNumberOfStops(4);

    const startingTime = displayDigitalTime(startingTimeHours, startingTimeMinutes);

    const timetableA = [
      startingTime,
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne * 2)
      )
    ];

    const timetableB = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne + intervalTwo)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo
        )
      )
    ];
    const timetableC = [
      displayDigitalTime(
        ...addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalTwo + intervalThree)
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree
        )
      )
    ];
    const timetableD = [
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalTwo + intervalThree + intervalFour
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne + intervalTwo + intervalThree + intervalFour
        )
      ),
      displayDigitalTime(
        ...addDurationTo24hTime(
          startingTimeHours,
          startingTimeMinutes,
          intervalOne * 2 + intervalTwo + intervalThree + intervalFour
        )
      )
    ];

    const allTimetables = [timetableA, timetableB, timetableC, timetableD];

    const { answerOptionEndingIndex, answerArrivalTimeIndex, answerOptionStartingIndex } =
      rejectionSample(
        () => {
          const answerOptionStartingIndex = getRandomFromArray([0, 1, 2] as const);

          const answerOptionEndingIndex = randomIntegerInclusive(answerOptionStartingIndex + 1, 3);

          const answerArrivalTimeIndex = getRandomFromArray([0, 1, 2] as const);

          return { answerOptionEndingIndex, answerArrivalTimeIndex, answerOptionStartingIndex };
        },
        ({ answerOptionEndingIndex, answerArrivalTimeIndex }) =>
          allTimetables
            .flat()
            .filter(time => time === allTimetables[answerOptionEndingIndex][answerArrivalTimeIndex])
            .length === 1
      );

    return {
      allTimetables,
      answerOptionStartingIndex,
      answerOptionEndingIndex,
      answerArrivalTimeIndex,
      options,
      characterName,
      showMoreIncorrectFromStartingPlace
    };
  },
  Component: props => {
    const {
      question: {
        allTimetables,
        answerOptionStartingIndex,
        answerOptionEndingIndex,
        answerArrivalTimeIndex,
        options,
        characterName,
        showMoreIncorrectFromStartingPlace
      },
      translate,
      displayMode
    } = props;

    const busArrivingTime = allTimetables[answerOptionStartingIndex][answerArrivalTimeIndex];

    const answer = allTimetables[answerOptionEndingIndex][answerArrivalTimeIndex];

    const remainingStartingPlaceOptions = shuffle(
      allTimetables[answerOptionStartingIndex]
        .filter(el => el !== busArrivingTime)
        .map(el => [el, el]),
      { random: seededRandom(props.question) }
    );

    const endingPlaceOptions = shuffle(
      allTimetables[answerOptionEndingIndex].filter(el => el !== answer).map(el => [el, el]),
      { random: seededRandom(props.question) }
    );

    const selectables = shuffle(
      [
        [answer, answer],
        remainingStartingPlaceOptions[0],
        endingPlaceOptions[0],
        showMoreIncorrectFromStartingPlace
          ? remainingStartingPlaceOptions[1]
          : endingPlaceOptions[1]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.characterTravellingToPlaceXTheyArriveAtPlaceYTrainStationAtZTimeSelectTheTimeTheyWillArriveInPlaceX(
          {
            character: characterName,
            placeA: options[answerOptionEndingIndex],
            placeB: options[answerOptionStartingIndex],
            time: busArrivingTime
          }
        )}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={[answer]}
        leftContent={
          <MeasureView>
            {dimens => (
              <TableWithLeftHeaders
                style={{ width: dimens.width, height: dimens.height * 0.95 }}
                headers={options}
                items={allTimetables}
                headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
                textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aSL2',
  description: 'aSL',
  keywords: ['Timetable', 'Read', 'Interpret'],
  schema: z.object({
    allTimetables: z.array(z.array(z.string())).length(4),
    answerOptionStartingIndex: numberEnum([0, 1, 2]),
    answerOptionEndingIndex: z.number().int().min(1).max(3),
    answerArrivalTimeIndex: numberEnum([0, 1, 2]),
    options: z.array(stationNamesSchema).length(4),
    characterName: nameSchema,
    showMoreIncorrectFromStartingPlace: z.boolean(),
    arrivalTime: z.string()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingTimeHours = randomIntegerInclusive(12, 20);
    const startingTimeMinutes = randomIntegerInclusiveStep(0, 45, 15);
    const gapToNextTrain = getRandomFromArray([20, 30, 40] as const);
    const routeTimes = getRandomRouteAndTimeByNumberOfStops(4);
    const options = routeTimes.route;
    const [intervalOne, intervalTwo, intervalThree] = routeTimes.duration;
    const characterName = getRandomName();
    const showMoreIncorrectFromStartingPlace = getRandomBoolean();

    const timetableA = [
      addDurationTo24hTime(startingTimeHours, startingTimeMinutes, 0),
      addDurationTo24hTime(startingTimeHours, startingTimeMinutes, gapToNextTrain),
      addDurationTo24hTime(startingTimeHours, startingTimeMinutes, gapToNextTrain * 2)
    ];

    const timetableB = [
      addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne),
      addDurationTo24hTime(startingTimeHours, startingTimeMinutes, gapToNextTrain + intervalOne),
      addDurationTo24hTime(startingTimeHours, startingTimeMinutes, gapToNextTrain * 2 + intervalOne)
    ];

    const timetableC = [
      addDurationTo24hTime(startingTimeHours, startingTimeMinutes, intervalOne + intervalTwo),
      addDurationTo24hTime(
        startingTimeHours,
        startingTimeMinutes,
        gapToNextTrain + intervalOne + intervalTwo
      ),
      addDurationTo24hTime(
        startingTimeHours,
        startingTimeMinutes,
        gapToNextTrain * 2 + intervalOne + intervalTwo
      )
    ];

    const timetableD = [
      addDurationTo24hTime(
        startingTimeHours,
        startingTimeMinutes,
        intervalOne + intervalTwo + intervalThree
      ),
      addDurationTo24hTime(
        startingTimeHours,
        startingTimeMinutes,
        gapToNextTrain + intervalOne + intervalTwo + intervalThree
      ),
      addDurationTo24hTime(
        startingTimeHours,
        startingTimeMinutes,
        gapToNextTrain * 2 + intervalOne + intervalTwo + intervalThree
      )
    ];

    const allTimetablesObjs = [timetableA, timetableB, timetableC, timetableD];

    const { answerOptionEndingIndex, answerArrivalTimeIndex, answerOptionStartingIndex } =
      rejectionSample(
        () => {
          const answerOptionStartingIndex = getRandomFromArray([0, 1, 2] as const);

          const answerOptionEndingIndex = randomIntegerInclusive(answerOptionStartingIndex + 1, 3);

          const answerArrivalTimeIndex = getRandomFromArray([0, 1, 2] as const);

          return { answerOptionEndingIndex, answerArrivalTimeIndex, answerOptionStartingIndex };
        },
        ({ answerOptionEndingIndex, answerArrivalTimeIndex }) =>
          allTimetablesObjs
            .flat()
            .filter(
              time => time === allTimetablesObjs[answerOptionEndingIndex][answerArrivalTimeIndex]
            ).length === 1
      );

    const early = randomIntegerInclusive(5, 10);

    const arrivalTime = displayDigitalTime(
      ...addDurationTo24hTime(
        allTimetablesObjs[answerOptionStartingIndex][answerArrivalTimeIndex][0],
        allTimetablesObjs[answerOptionStartingIndex][answerArrivalTimeIndex][1],
        -early
      )
    );

    const allTimetables = allTimetablesObjs.map(array =>
      array.map(time => displayDigitalTime(...time))
    );

    return {
      allTimetables,
      answerOptionStartingIndex,
      answerOptionEndingIndex,
      answerArrivalTimeIndex,
      options,
      characterName,
      showMoreIncorrectFromStartingPlace,
      arrivalTime
    };
  },
  Component: props => {
    const {
      question: {
        allTimetables,
        answerOptionStartingIndex,
        answerOptionEndingIndex,
        answerArrivalTimeIndex,
        options,
        characterName,
        showMoreIncorrectFromStartingPlace,
        arrivalTime
      },
      translate,
      displayMode
    } = props;

    const correctTime = allTimetables[answerOptionStartingIndex][answerArrivalTimeIndex];

    const answer = allTimetables[answerOptionEndingIndex][answerArrivalTimeIndex];

    const remainingStartingPlaceOptions = shuffle(
      allTimetables[answerOptionStartingIndex].filter(el => el !== correctTime).map(el => [el, el]),
      { random: seededRandom(props.question) }
    );

    const endingPlaceOptions = shuffle(
      allTimetables[answerOptionEndingIndex].filter(el => el !== answer).map(el => [el, el]),
      { random: seededRandom(props.question) }
    );

    const selectables = shuffle(
      [
        [answer, answer],
        remainingStartingPlaceOptions[0],
        endingPlaceOptions[0],
        showMoreIncorrectFromStartingPlace
          ? remainingStartingPlaceOptions[1]
          : endingPlaceOptions[1]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.characterTravellingToPlaceXTheyArriveAtPlaceYTrainStationAtZTimeSelectTheEarliestTimeTheyWillArriveInPlaceX(
          {
            character: characterName,
            placeA: options[answerOptionEndingIndex],
            placeB: options[answerOptionStartingIndex],
            time: arrivalTime
          }
        )}
        pdfTitle={translate.instructions.characterTravellingToPlaceXTheyArriveAtPlaceYTrainStationAtZTimeCircleTheEarliestTimeTheyWillArriveInPlaceX(
          {
            character: characterName,
            placeA: options[answerOptionEndingIndex],
            placeB: options[answerOptionStartingIndex],
            time: arrivalTime
          }
        )}
        selectables={Object.fromEntries(selectables)}
        selectableStyle={{ gap: 16 }}
        correctAnswer={[answer]}
        leftContent={
          <MeasureView>
            {dimens => (
              <TableWithLeftHeaders
                style={{ width: dimens.width, height: dimens.height * 0.95 }}
                headers={options}
                items={allTimetables}
                headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
                textStyle={{ fontSize: displayMode === 'digital' ? 22 : 50 }}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadAndInterpretTimetables',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
