import { Theme } from '.';

import { zodEnumFromObjKeys } from '../utils/zod';

export const colors = {
  // Main colors
  prussianBlue: '#002e63',
  burntSienna: '#f48668',
  seaGreenCrayola: '#15e8c5',
  pacificBlue: '#1ea0ae',
  grey: '#6d7f93',
  danger: '#dc3545',
  fieryRose: '#ff5470',
  pdfShading: '#9ca8b5',

  // Variants
  prussianBlueLight: '#d7e3ff',
  prussianBlueDark: '#001b3f',
  prussianBlue600: '#00254F',

  seaGreenCrayolaMid: '#006b5a',
  seaGreenCrayolaDark: '#00201a',

  burntSiennaFocus: '#fbd5ca',
  burntSiennaLight: '#feede9',
  burntSiennaMedium: '#fbd5ca',
  burntSiennaDark: '#da4621',

  pacificBlue600: '#18808b',
  pacificBlueTint15: '#ddf1f3', // background color for area model titles

  greyMatter: '#f7f8f9',
  greys100: '#f0f2f4',
  greys200: '#e5e8ec',
  greys300: '#d0d6dc',
  greys400: '#9ca8b5', // Use for bar model shading on PDF
  greys500: '#6D7F93',
  greys600: '#3a4f68',
  greys700: '#304256',
  greys800: '#212d3a',
  greys900: '#10161d',

  grey300PressIn: '#c6cace',
  seaGreenCrayolaPressIn: '#0CC5A6',

  dangerLight: '#ff5470',
  dangerSurface: '#FAE1E3', // danger, tint 15%
  dangerDark: '#410008',

  fieryRose600: '#cc435a',

  // Gattegno colors
  ones: '#f7a09e',
  tens: '#fee192',
  hundreds: '#baddb2',
  thousands: '#d4e4f5',
  tenThousands: '#c8acd0',
  hundredThousands: '#caa99c',

  // Other colors
  black: 'black',
  white: 'white',
  blue: '#889bb6',
  green: '#198754',
  acidGreen: '#e6f14a',
  yellow: '#ffec51',
  red: '#eb3647',
  infoToast: '#3294cb',
  infoBorder: '#4CADF3',
  orange2: '#ffc107',
  gridBlue: '#68c5ef',
  gridMissingBlue: '#2c70ad',
  redBorder: '#dc3831',
  greenTextBox: '#d3e1a8',
  greenProgressBar: '#2ac769',
  yellowContentBox: '#fffaa9',
  tableHeaderBackground: '#3294cb',
  pdfRightBar: '#fbedd7',
  corn400: '#FFF074',
  digitalClockBackground: '#FFFDEF',
  functionMachineBorder: '#6C757D',
  functionMachineFill: '#D1E4FC',
  jugWater: '#008eff',
  barrelWater: '#9FC5FB',
  jugOrangeJuice: '#ff8800',
  jugSand: '#ffc100'
} as const;

export const barModelColors = {
  barBlue1: '#a9e0f9',
  barBlue2: '#bfe1f3',
  barBlue3: '#e0f1fb',
  barBlue4: '#a5ceed',
  barGold: '#fee192',
  barGreen1: '#baddb2',
  barGreen2: '#c3dbb8',
  barGreen3: '#d1e2c8',
  barGreen4: '#a5d2c1',
  barOrange1: '#fdc68a',
  barOrange2: '#f6d1a4',
  barOrange3: '#f2bd7f',
  barOrange4: '#efb371',
  barPink1: '#f4d6e4',
  barPink2: '#f4adce',
  barPink3: '#e8afcc',
  barPurple1: '#d4e4f5',
  barPurple2: '#d291bc',
  barRed1: '#e9bab6',
  barRed2: '#edb0ac',
  barYellow1: '#fdf6ab',
  barYellow2: '#fef9c7',
  barYellow3: '#fef16d'
} as const;

export const uniqueBarModelColors = {
  blue: ['#a9e0f9', '#bfe1f3', '#e0f1fb', '#a5ceed'],
  gold: ['#fee192'],
  green: ['#baddb2', '#c3dbb8', '#d1e2c8', '#a5d2c1'],
  orange: ['#fdc68a', '#f6d1a4', '#f2bd7f', '#efb371'],
  pink: ['#f4d6e4', '#f4adce', '#e8afcc'],
  purple: ['#d4e4f5', '#d291bc'],
  red: ['#e9bab6', '#edb0ac'],
  yellow: ['#fdf6ab', '#fef9c7', '#fef16d']
} as const;

export const barColorNames = {
  Blue: barModelColors['barBlue1'],
  Green: barModelColors['barGreen1'],
  Orange: barModelColors['barOrange1'],
  Pink: barModelColors['barPink1'],
  Purple: barModelColors['barPurple1'],
  Red: barModelColors['barRed1'],
  Yellow: barModelColors['barYellow1']
} as const;

export const barModelColorsHexArray = Object.values(barModelColors);

export type BarModelColorsKey = keyof typeof barModelColors;
export const barModelColorsArray = Object.keys(barModelColors) as BarModelColorsKey[];
export const barModelColorsSchema = zodEnumFromObjKeys(barModelColors);

export type BarColorNames = keyof typeof barColorNames;
export const barColorsNamesArray = Object.keys(barColorNames) as BarColorNames[];
export const barColorsNamesSchema = zodEnumFromObjKeys(barColorNames);

export type UniqueBarModelColorsKey = keyof typeof uniqueBarModelColors;
export const uniqueBarModelColorsArray = Object.keys(
  uniqueBarModelColors
) as UniqueBarModelColorsKey[];
export const uniqueBarModelColorsSchema = zodEnumFromObjKeys(uniqueBarModelColors);

export const PieChartColors = [colors.pacificBlue, colors.acidGreen, colors.dangerLight];

export const LineGraphColors = [colors.pacificBlue, colors.red, colors.orange2] as const;

export const drawPieChartColors = {
  Pink: '#f4d6e4',
  Purple: '#ECD7FC',
  Red: '#e9bab6',
  Green: '#C8EDB9',
  Yellow: colors.corn400,
  Orange: '#FFE3C3',
  Blue: '#DDF1F3'
} as const;

export type drawPieChartColors = keyof typeof drawPieChartColors;
export const drawPieChartColorsArray = Object.keys(drawPieChartColors) as drawPieChartColors[];
export const drawPieChartColorsSchema = zodEnumFromObjKeys(drawPieChartColors);
export const pieChartColors = [
  '#ff7a68',
  '#56b8c2',
  '#b4dd63',
  '#a0718f',
  '#eb3647',
  '#f7a48e',
  '#d9328a',
  '#fcd257',
  '#82c87c',
  '#dfa5b6'
] as const;

export const PictogramColors = [colors.pacificBlue, colors.red, colors.green, colors.yellow];

export const carColors = {
  Black: colors.black,
  Blue: barModelColors.barBlue4,
  Green: colors.green,
  Orange: colors.burntSienna,
  Pink: barModelColors.barPink1,
  Purple: barModelColors.barPurple2,
  Red: colors.red,
  Silver: colors.grey,
  Yellow: colors.yellow
};

export const hundredSquareColors = [
  colors.pacificBlue,
  colors.acidGreen,
  colors.seaGreenCrayola,
  colors.fieryRose
] as const;

export const ArrayOfObjectsColors = {
  arrayRed: '#dc3831',
  arrayGreen: '#70b461',
  arrayBlue: '#4bace9',
  arrayPurple: '#d9328a',
  arrayYellow: '#feef50'
} as const;

export const arrayOfObjectsWithCrossesColors = {
  arrayBlue: colors.prussianBlue,
  arrayOrange: colors.burntSienna
} as const;

export type ArrayOfObjectsWithCrossesColors = keyof typeof arrayOfObjectsWithCrossesColors;

export const NumberGridColors = {
  barBlue: '#bfe1f3',
  barGold: '#fee192',
  barGreen: '#baddb2',
  barOrange: '#fdc68a',
  barPink: '#f4adce',
  barRed: '#edb0ac',
  barYellow: '#fef16d'
} as const;

export const shapeColors = {
  shapeBlue1: '#b4dff6',
  shapeBlue2: '#4bace9',
  shapeGreen1: '#d3e1a8',
  shapeGreen2: '#b6ce53',
  shapeOrange1: '#f4c791',
  shapeOrange2: '#e9973e',
  shapePink1: '#efc3d8',
  shapePink2: '#e79ec0',
  shapePurple: '#e4dbea',
  shapeRed: '#efbdb7',
  shapeYellow: '#ece5a6'
};

export const yearColors = {
  year1: '#ff7a68',
  year2: '#56b8c2',
  year3: '#f48668',
  year4: '#b4dd63',
  year5: '#a0718f',
  year6: '#6dc2dc',
  year7: '#f7a48e',
  year8: '#a5daf0',
  year9: '#fcd257',
  year10: '#82c87c',
  year11: '#dfa5b6'
} as const;

export const fractionWallColors = [
  '#cde4ff',
  '#ffe3c3',
  '#e4e8ed',
  '#fae19c',
  '#d6f1ff',
  '#c8edb9',
  '#aee4ff',
  '#ffd5a1',
  '#ecd7fc',
  '#ff7d81'
];

export const tableColors = {
  headerGreen: '#cfd8a7',
  headerGreenSecondary: '#c8e2b7'
};

export const placeValueChartColors = {
  millions: '#d9d9d9',
  hundredThousands: '#d4c6c7',
  tenThousandsTenThousandths: '#ccb8d5',
  thousandsThousandths: '#3294CB',
  hundredsHundredths: '#099E83',
  tensTenths: '#FDD814',
  ones: '#EB3647'
};

export const digitalClockColors = {
  orange: '#E58A63',
  pink: '#D3307D',
  purple: '#2D2A7B'
};

export const tenFrameCounterColors = {
  red: '#EB3647',
  yellow: '#FDD814',
  blue: '#3294CB',
  green: '#4da72e',
  grey: '#304256'
};

export function getColorFromTheme(color: string, theme: Theme): string {
  return (theme.colors as Record<string, string>)[color] ?? color;
}
