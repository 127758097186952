import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import {
  binOpEquationToSentenceString,
  getBinOpEquation,
  binOpEquationsToTestCorrect
} from '../../../../utils/fourOperations';
import { useMemo } from 'react';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import {
  PartWholeModel,
  TextPartition
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { af4 } from './5SpotThePattern';
import { digitAtPowerIsNumber } from '../../../../utils/math';

////
// Questions
////

const Question1 = { ...af4, uid: 'agy', description: 'agy' as const };

const Question2 = newQuestionContent({
  uid: 'agz',
  description: 'agz',
  keywords: ['Connections', 'Subtraction', 'Ones', 'Tens', 'Hundreds'],
  schema: z.object({
    minuend: z
      .number()
      .int()
      .min(666)
      .max(999)
      .refine(
        val =>
          digitAtPowerIsNumber(val, 0, [6, 7, 8, 9]) &&
          digitAtPowerIsNumber(val, 1, [6, 7, 8, 9]) &&
          digitAtPowerIsNumber(val, 2, [6, 7, 8, 9]),
        'Every digit of minuend must be greater than 5'
      ),
    subtrahend: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const minuendOnes = randomIntegerInclusive(6, 9);

    const minuendTens = randomIntegerInclusiveStep(60, 90, 10);

    const minuendHundreds = randomIntegerInclusiveStep(600, 900, 100);

    const minuend = minuendOnes + minuendTens + minuendHundreds;

    const subtrahend = randomIntegerInclusive(1, 5);

    return {
      minuend,
      subtrahend
    };
  },

  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate
    } = props;

    const eqs = [
      getBinOpEquation({ left: minuend, right: subtrahend, sign: 'subtract' }),
      getBinOpEquation({ left: minuend, right: subtrahend * 10, sign: 'subtract' }),
      getBinOpEquation({ left: minuend, right: subtrahend * 100, sign: 'subtract' })
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'agA',
  description: 'agA',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(8),
      number2: z.number().int().min(1).max(8),
      addOrSubtract: z.enum(['add', 'subtract'])
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'numberA1 + numberA2 must not exchange'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 8);
    const number2 = randomIntegerInclusive(1, 8, {
      constraint: x => numbersDoNotExchange(x, number1)
    });

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    return { number1, number2, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, number2, addOrSubtract },
      translate
    } = props;

    const number1tens = number1 * 10;
    const number2tens = number2 * 10;
    const number1hundreds = number1 * 100;
    const number2hundreds = number2 * 100;

    const eqA =
      addOrSubtract === 'add'
        ? getBinOpEquation({ left: number1, right: number2, sign: 'add', answer: 'result' })
        : getBinOpEquation({ right: number2, result: number1, sign: 'subtract', answer: 'result' });

    const eqB =
      addOrSubtract === 'add'
        ? getBinOpEquation({ left: number1tens, right: number2tens, sign: 'add', answer: 'result' })
        : getBinOpEquation({
            right: number2tens,
            result: number1tens,
            sign: 'subtract',
            answer: 'result'
          });

    const eqC =
      addOrSubtract === 'add'
        ? getBinOpEquation({
            left: number1hundreds,
            right: number2hundreds,
            sign: 'add',
            answer: 'result'
          })
        : getBinOpEquation({
            right: number2hundreds,
            result: number1hundreds,
            sign: 'subtract',
            answer: 'result'
          });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'agB',
  description: 'agB',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(2).max(9),
    number3: z.number().int().min(2).max(9),
    number4: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const [number1, number2, number3, number4] = randomUniqueIntegersInclusive(2, 9, 4);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const answerOptions = ['ones', 'tens', 'hundreds'];

    const statements = useMemo(() => {
      const questionsAndAnswers = [
        [
          `${translate.misc.numberOfTens(number1)} = ${(number1 * 10).toLocaleString()} <ans/>`,
          'ones'
        ],
        [
          `${translate.misc.numberOfOnes(number2 * 10)} = ${number2.toLocaleString()} <ans/>`,
          'tens'
        ],
        [
          `${translate.misc.numberOfHundreds(number3)} = ${(number3 * 10).toLocaleString()} <ans/>`,
          'tens'
        ],
        [
          `${translate.misc.numberOfTens(number4 * 10)} = ${number4.toLocaleString()} <ans/>`,
          'hundreds'
        ]
      ];

      return shuffle(questionsAndAnswers, { random: seededRandom(props.question) });
    }, [number1, number2, number3, number4, props.question, translate]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteSentences()}
        actionPanelVariant="endWide"
        pdfLayout="itemsRight"
        pdfTitle={translate.instructions.useCardsCompleteSentences()}
        items={answerOptions}
        sentences={statements.map(it => it[0])}
        testCorrect={statements.map(it => [it[1]])}
        moveOrCopy="copy"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        pdfSentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

export const Question5 = newQuestionContent({
  uid: 'agC',
  description: 'agC',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', 'Part-whole'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(1).max(9),
    part: z.enum(['top', 'partA', 'partB'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const part = getRandomFromArray(['top', 'partA', 'partB'] as const);
    const number1 = randomIntegerInclusive(1, 9);
    const number2 = randomIntegerInclusive(1, 9);

    return { number1, number2, part };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, part } = question;

    const number1Product = number1 * 10;
    const number2Product = number2 * 10;

    const number3 = number1 + number2;

    const productAnswer = number1Product + number2Product;

    let partition: TextPartition = [];

    if (part === 'partA') {
      partition = ['$ans', number2Product];
    } else if (part === 'partB') {
      partition = [number1Product, '$ans'];
    } else {
      partition = [number1Product, number2Product];
    }

    const correctAnswer = (() => {
      switch (part) {
        case 'top':
          return productAnswer;
        case 'partA':
          return number1Product;
        case 'partB':
          return number2Product;
      }
    })();

    return (
      <QF3InteractiveContent
        title={translate.instructions.useSumToCompletePartWholeModel(number1, number2, number3)}
        initialState={displayMode === 'markscheme' ? [correctAnswer.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        inputType="numpad"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={part === 'top' ? '$ans' : productAnswer}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={900}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'agD',
  description: 'agD',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', 'Part-whole'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(1).max(9),
    multipleA: z.number().int().min(10).max(90).multipleOf(10),
    multipleB: z.number().int().min(10).max(70).multipleOf(10),
    product: z.enum(['productA', 'productB']),
    part: z.enum(['top', 'partA', 'partB'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const number2 = randomIntegerInclusive(1, 9);

    const multipleA = randomIntegerInclusiveStep(10, 90, 10);
    const multipleB = randomIntegerInclusiveStep(10, 70, 10);

    const product = getRandomFromArray(['productA', 'productB'] as const);
    const part = getRandomFromArray(['top', 'partA', 'partB'] as const);

    return { number1, number2, multipleA, multipleB, product, part };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, multipleA, multipleB, product, part } = question;

    let number1Product: number;
    let number2Product: number;

    if (product === 'productA') {
      number1Product = number1 + multipleA;
      number2Product = number2;
    } else {
      number1Product = (number1 + multipleB) * 10;
      number2Product = number2 * 10;
    }

    const number3 = number1 + number2;

    const productAnswer = number1Product + number2Product;

    let partition: TextPartition = [];

    if (part === 'partA') {
      partition = ['$ans', number2Product];
    } else if (part === 'partB') {
      partition = [number1Product, '$ans'];
    } else {
      partition = [number1Product, number2Product];
    }

    const correctAnswer = (() => {
      switch (part) {
        case 'top':
          return productAnswer;
        case 'partA':
          return number1Product;
        case 'partB':
          return number2Product;
      }
    })();

    return (
      <QF3InteractiveContent
        title={translate.instructions.useSumToCompletePartWholeModel(number1, number2, number3)}
        initialState={displayMode === 'markscheme' ? [correctAnswer.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        inputType="numpad"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={part === 'top' ? '$ans' : productAnswer}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeConnections',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
