import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { filledArray } from '../../../utils/collections';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { CompleteNumberLine } from '../representations/Number Line/NumberLine';
import { parseMarkup } from '../../../markup';
import { useContext, useMemo } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { isEqual } from '../../../utils/matchers';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { parseSymbolsToString } from '../../../utils/parse';
import {
  ObjectAsNumberLine,
  ObjectAsNumberLineWithState,
  ObjectsAsNumberLine
} from '../representations/Number Line/ObjectAsNumberLine';

type Props = TitleStyleProps & {
  title: string;
  tickValues: CompleteNumberLine;
  /**
   * The width of each text input in characters. If left undefined and testCorrect is given as strings, it will
   * default to the longest correct answer.
   */
  inputMaxCharacters?: number;
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  /**
   * Optional extra symbol on keypad
   */
  extraSymbol?: ExtraSymbols;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
  /** Custom Font Size if you want to manually override the scaling */
  customFontSize?: number;
  object: ObjectsAsNumberLine;
};

export default function QF17eCompleteObjectAsNumberLine({
  title,
  tickValues,
  inputMaxCharacters: inputMaxCharactersProp,
  testCorrect: testCorrectProp,
  questionHeight,
  customMarkSchemeAnswer,
  extraSymbol,
  customFontSize,
  object,
  ...props
}: Omit<Props, 'dimens'>) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const totalNumberOfAns = tickValues
    .map(tick => parseMarkup(tick as string).numberOfAns)
    .reduce((sum, numberOfAns) => sum + numberOfAns);
  const initialState = filledArray('', totalNumberOfAns);

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  // Make answer input boxes a suitable width
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (Array.isArray(testCorrectProp)) {
      return Math.max(...testCorrectProp.map(it => it.length));
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (isPdf) {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp.map(ans => {
            // Temporary variable to convert simple string to localized string
            const temp = Number(parseSymbolsToString(ans));
            return temp.toLocaleString();
          });

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <ObjectAsNumberLine
                  object={object}
                  tickValues={tickValues}
                  userAnswer={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
                  inputMaxCharacters={inputMaxCharacters}
                  dimens={dimens}
                  customFontSize={customFontSize}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="bottomTall"
      actionPanelContents={
        <UserInput inputType="numpad" variant="wide" extraSymbol={extraSymbol} />
      }
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <ObjectAsNumberLineWithState
              id="objectNumberLine"
              object={object}
              defaultState={initialState}
              testComplete={userAnswer => userAnswer.every(it => it.length !== 0)}
              testCorrect={testCorrect}
              tickValues={tickValues}
              inputMaxCharacters={inputMaxCharacters}
              dimens={dimens}
              customFontSize={customFontSize}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
