import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { randomIntegerInclusive, shuffle } from '../../../../utils/random';
import { z } from 'zod';
import { DIV } from '../../../../constants';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { randomUniqueIntegersInclusive } from '../../../../utils/random';
import { nameSchema, getRandomName } from '../../../../utils/names';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { filledArray, range } from '../../../../utils/collections';
import QF30GroupCountersAndSentence from '../../../../components/question/questionFormats/QF30GroupCountersAndSentence';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'akN',
  description: 'akN',
  keywords: ['Divide', '8', 'Counters'],
  schema: z.object({
    countersPerGroup: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const countersPerGroup = randomIntegerInclusive(2, 4);

    return { countersPerGroup };
  },
  Component: ({ question: { countersPerGroup }, translate }) => {
    const counters = 8 * countersPerGroup;
    return (
      <QF30GroupCountersAndSentence
        title={translate.instructions.completeSentenceYouCanUseCountersToHelp()}
        pdfTitle={translate.instructions.completeSentenceYouCanDrawCountersToHelp()}
        sentence={translate.answerSentences.xSharedIntoYEqualGroupsIsAns(counters, 8)}
        testCorrect={[countersPerGroup.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'akO',
  description: 'akO',
  keywords: ['Divide', '8', 'Array'],
  schema: z.object({
    rows: z.number().int().min(2).max(6)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 6);

    return { rows };
  },
  Component: props => {
    const {
      question: { rows },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.howManyGroupsOfNumAreInArray((8).toLocaleString()),
          `${(rows * 8).toLocaleString()} ${DIV} ${(8).toLocaleString()} = <ans/>`
        ]}
        title={translate.instructions.hereIsAnArrayOfNumCounters(rows * 8)}
        testCorrect={[[rows.toString()], [rows.toString()]]}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={8} dimens={dimens} />}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'akP',
  description: 'akP',
  keywords: ['Divide', '8', 'Number line'],
  schema: z.object({
    answer: z.number().int().min(3).max(8),
    numberTicks: z.number().int().min(4).max(9)
  }),
  simpleGenerator: () => {
    const answer = randomIntegerInclusive(3, 8);
    const numberTicks = randomIntegerInclusive(answer + 1, 9);

    return { answer, numberTicks };
  },
  Component: props => {
    const {
      question: { answer, numberTicks },
      translate
    } = props;

    const number2 = 8;
    const number3 = answer * number2;

    const startingNumber = 0;
    const endNumber = numberTicks * number2;

    const tickValues = range(startingNumber, endNumber, number2);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyTimesDoesXGoIntoY(number2, number3)}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.thereAreAnsXsinY(
          number2.toLocaleString(),
          number3.toLocaleString()
        )}
        Content={({ dimens }) => <NumberLine dimens={dimens} tickValues={tickValues} />}
        pdfDirection="column"
        {...props}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'akP2',
  description: 'akP',
  keywords: ['Divide', '8', 'Number line'],
  schema: z.object({
    answer: z.number().int().min(3).max(11),
    numberTicks: z.number().int().min(4).max(12)
  }),
  simpleGenerator: () => {
    const answer = randomIntegerInclusive(3, 11);
    const numberTicks = randomIntegerInclusive(answer + 1, 12);

    return { answer, numberTicks };
  },
  Component: props => {
    const {
      question: { answer, numberTicks },
      translate
    } = props;

    const number2 = 8;
    const number3 = answer * number2;

    const startingNumber = 0;
    const endNumber = numberTicks * number2;

    const tickValues = range(startingNumber, endNumber, number2);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyTimesDoesXGoIntoY(number2, number3)}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.thereAreAnsXsinY(
          number2.toLocaleString(),
          number3.toLocaleString()
        )}
        Content={({ dimens }) => (
          <NumberLine dimens={dimens} tickValues={tickValues} scaleFactor={4} />
        )}
        pdfDirection="column"
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'akQ',
  description: 'akQ',
  keywords: ['Divide', '8', 'Bar model'],
  schema: z.object({
    number: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 10);

    return { number };
  },

  Component: ({ question: { number }, translate, ...props }) => {
    const barArray = filledArray(8, number);

    const product = number * 8;

    const numbers = [[product], barArray];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useBarModelToWorkOutX(`${product.toLocaleString()} ${DIV} 8`)}
        testCorrect={[number.toString()]}
        sentence={`${product.toLocaleString()} ${DIV} 8 = <ans/>`}
        {...props}
        Content={({ dimens }) => (
          <BarModel numbers={numbers} total={product} dimens={dimens} sameRowColor />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'akR',
  description: 'akR',
  keywords: ['Divide', '8', 'Problem'],
  schema: z.object({
    groups: z.number().int().min(2).max(12),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 12);

    const name = getRandomName();

    return { groups, name };
  },
  Component: props => {
    const {
      question: { groups, name },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        sentence={`<ans/> ${DIV} <ans/> = <ans/>`}
        title={translate.instructions.characterHasXFlowersAndSomeVasesAVaseCanHoldYFlowers(
          name,
          groups * 8,
          8
        )}
        testCorrect={[(groups * 8).toString(), '8', groups.toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'akS',
  description: 'akS',
  keywords: ['Divide', '8'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(80)
      .array()
      .length(6)
      .refine(numbers => numbers.filter(x => x % 8 === 0).length === 2, 'Only 2 multiples of 8')
  }),
  simpleGenerator: () => {
    const multiples = randomUniqueIntegersInclusive(1, 80, 2, {
      constraint: x => x % 8 === 0
    });
    const notMultiples = randomUniqueIntegersInclusive(1, 80, 4, {
      constraint: x => x % 8 !== 0
    });

    const numbers = shuffle([...multiples, ...notMultiples]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumbersDivideExactly(8)}
        testCorrect={numbers.filter(it => it % 8 === 0)}
        multiSelect
        items={() =>
          numbers.map(number => ({
            value: number,
            component: number.toLocaleString()
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy8',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
