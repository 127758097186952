import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { numberEnum } from '../../../../utils/zod';
import { lowercase } from 'typesafe-i18n/formatters';
import {
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  filledArray
} from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import QF24aCreateShapeAndGivenShape from '../../../../components/question/questionFormats/QF24aCreateShapeAndGivenShape';
import { createRectangleFromSquares, perimeterCount, trueCount } from '../../../../utils/shapes';

////
// Questions
////

const optionsArray = [
  'area',
  'inside',
  'perimeter',
  'distance',
  'around',
  'cm',
  'm',
  'space',
  'cm2',
  'mm2',
  'm2',
  'mm'
] as const;
type Option = (typeof optionsArray)[number];

const Question1 = newQuestionContent({
  uid: 'aWy',
  description: 'aWy',
  keywords: ['Area', 'Perimeter', 'Units'],
  schema: z.object({
    sentenceIndex: numberEnum([0, 1, 2, 3, 4, 5]),
    options: z
      .object({
        string: z.enum(optionsArray),
        isCorrect: z.boolean()
      })
      .array()
      .length(4)
  }),
  simpleGenerator: () => {
    const sentenceIndex = getRandomFromArray([0, 1, 2, 3, 4, 5] as const);
    const options: {
      string: Option;
      isCorrect: boolean;
    }[] = [];

    switch (sentenceIndex) {
      case 0: {
        const arr = getRandomSubArrayFromArray(
          ['perimeter', 'distance', 'around', 'cm'] as const,
          2
        );
        options.push(
          { string: 'area', isCorrect: true },
          { string: 'inside', isCorrect: true },
          ...arr.map(val => ({ string: val, isCorrect: false }))
        );
        break;
      }
      case 1: {
        const arr = getRandomSubArrayFromArray(['area', 'inside', 'space', 'cm2'] as const, 2);
        options.push(
          { string: 'perimeter', isCorrect: true },
          { string: 'around', isCorrect: true },
          ...arr.map(val => ({ string: val, isCorrect: false }))
        );
        break;
      }
      case 2: {
        const arr = getRandomSubArrayFromArray(['mm', 'cm', 'm'] as const, 2);
        const correctArr = getRandomSubArrayFromArray(['mm2', 'cm2', 'm2'] as const, 2);
        options.push(
          ...correctArr.map(val => ({ string: val, isCorrect: true })),
          ...arr.map(val => ({ string: val, isCorrect: false }))
        );
        break;
      }
      case 3: {
        const correctArr = getRandomSubArrayFromArray(['mm', 'cm', 'm'] as const, 2);
        const arr = getRandomSubArrayFromArray(['mm2', 'cm2', 'm2'] as const, 2);
        options.push(
          ...correctArr.map(val => ({ string: val, isCorrect: true })),
          ...arr.map(val => ({ string: val, isCorrect: false }))
        );
        break;
      }
      case 4: {
        const correctArr = getRandomFromArray(['mm2', 'cm2', 'm2'] as const);
        const arr1 = getRandomFromArray(['perimeter', 'distance'] as const);
        const arr2 = getRandomFromArray(['mm', 'cm', 'm'] as const);
        options.push(
          { string: 'area' as const, isCorrect: true },
          { string: correctArr, isCorrect: true },
          { string: arr1, isCorrect: false },
          { string: arr2, isCorrect: false }
        );
        break;
      }
      case 5: {
        const correctArr = getRandomFromArray(['mm', 'cm', 'm'] as const);
        const arr1 = getRandomFromArray(['area', 'space'] as const);
        const arr2 = getRandomFromArray(['mm2', 'cm2', 'm2'] as const);
        options.push(
          { string: 'perimeter', isCorrect: true },
          { string: correctArr, isCorrect: true },
          { string: arr1, isCorrect: false },
          { string: arr2, isCorrect: false }
        );
        break;
      }
    }

    return { sentenceIndex, options };
  },
  Component: props => {
    const {
      question: { sentenceIndex, options },
      translate
    } = props;

    const translateWords = (string: Option) => {
      switch (string) {
        case 'area':
          return lowercase(translate.keywords.Area());
        case 'inside':
          return translate.misc.inside();
        case 'perimeter':
          return lowercase(translate.keywords.Perimeter());
        case 'distance':
          return lowercase(translate.keywords.Distance());
        case 'around':
          return translate.misc.around();
        case 'cm':
          return translate.units.cm();
        case 'm':
          return translate.units.m();
        case 'space':
          return translate.misc.space();
        case 'cm2':
          return translate.units.cm2();
        case 'mm2':
          return translate.units.mm2();
        case 'm2':
          return translate.units.m2();
        case 'mm':
          return translate.units.mm();
      }
    };

    const sentence = (() => {
      switch (sentenceIndex) {
        case 0:
          return translate.answerSentences.ansIsAmountOfSpaceAns2DShape();
        case 1:
          return translate.answerSentences.ansIsDistanceAns2DShape();
        case 2:
          return translate.answerSentences.areaCanBeMeasuredInUnitsAnsAns();
        case 3:
          return translate.answerSentences.perimeterCanBeMeasuredInAnsAns();
        case 4:
          return translate.answerSentences.ansIsAmountOfSpaceInside2DCanBeMeasuredAns();
        case 5:
          return translate.answerSentences.ansIsDistanceAnsAround2DCanBeMeasuredAns();
      }
    })();

    const orderMatters = [0, 1, 4, 6].includes(sentenceIndex);

    const correctAns = options.filter(val => val.isCorrect).map(val => translateWords(val.string));

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        items={shuffle(
          options.map(val => translateWords(val.string)),
          { random: seededRandom(props.question) }
        )}
        sentence={sentence}
        testCorrect={userAnswer =>
          orderMatters
            ? arraysHaveSameContents(correctAns, userAnswer)
            : arraysHaveSameContentsUnordered(correctAns, userAnswer)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAns]
        }}
        itemsLetterEmWidth={1.1}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aWy2',
  description: 'aWy2',
  keywords: ['Perimeter', 'Rectangle'],
  questionHeight: 900,
  schema: z.object({
    width: z.number().int().min(5).max(10),
    height: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(5, 10);
    const height = randomIntegerInclusive(2, 5, { constraint: x => x !== width });

    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;

    const labels = [translate.units.numberOfCm(width), '', '', translate.units.numberOfCm(height)];

    return (
      <QF1ContentAndSentence
        questionHeight={900}
        title={translate.instructions.workOutPerimeterOfRectangle()}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        pdfDirection="column"
        testCorrect={[(2 * (width + height)).toString()]}
        Content={({ dimens }) => (
          <LabelledQuadrilateral
            dimens={dimens}
            shape={'RectangleInteriorAngles'}
            labels={labels}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aWz',
  description: 'aWz',
  keywords: ['Area', 'Perimeter', 'Rectangle'],
  schema: z.object({
    width: z.number().int().min(5).max(10),
    height: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(5, 10);
    const height = randomIntegerInclusive(2, 5, { constraint: x => x !== width });

    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;

    const labels = [translate.units.numberOfCm(width), '', '', translate.units.numberOfCm(height)];

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.areaAnsCm2(),
          translate.answerSentences.perimeterAnsCm()
        ]}
        title={translate.instructions.workOutAreaAndPerimeterOfShape()}
        style={{ flexDirection: 'row' }}
        testCorrect={[[(width * height).toString()], [(2 * (width + height)).toString()]]}
        Content={({ dimens }) => (
          <LabelledQuadrilateral
            dimens={dimens}
            shape={'RectangleInteriorAngles'}
            labels={labels}
          />
        )}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aWz2',
  description: 'aWz2',
  keywords: ['Area', 'Rectangle'],
  questionHeight: 900,
  schema: z.object({
    width: z.number().int().min(5).max(10),
    height: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(5, 10);
    const height = randomIntegerInclusive(2, 5, { constraint: x => x !== width });

    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;

    const labels = [translate.units.numberOfCm(width), '', '', translate.units.numberOfCm(height)];

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.areaEqualsAnsCm2()}
        title={translate.instructions.workOutTheAreaOfTheRectangle()}
        testCorrect={[(width * height).toString()]}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <LabelledQuadrilateral
            dimens={dimens}
            shape={'RectangleInteriorAngles'}
            labels={labels}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aWA',
  description: 'aWA',
  keywords: ['Area', 'Perimeter', 'Rectangle'],
  schema: z
    .object({
      number3: z.number().int().min(3).max(5),
      number4: z.number().int().min(2).max(4),
      number5: z.number().int().min(5).max(8),
      color: z.enum(['pink', 'blue', 'green', 'purple', 'yellow']),
      lshape: z.enum(['LShape_1', 'LShape_2', 'LShape_3', 'LShape_4'])
    })
    .refine(val => val.number4 <= val.number3, 'number4 must be smaller or equal to number3')
    .refine(val => val.number3 < val.number5, 'number3 must be smaller than number5')
    .refine(
      val => val.number3 + val.number5 <= 12,
      'sum of number3 and number 5 must be smaller than or equal to 12'
    ),
  simpleGenerator: () => {
    const number5 = randomIntegerInclusive(5, 8);
    const number3 = randomIntegerInclusive(3, number5 - 1, { constraint: x => x + number5 <= 12 });
    const number4 = randomIntegerInclusive(2, Math.min(4, number3));
    const color = getRandomFromArray(['pink', 'blue', 'green', 'purple', 'yellow'] as const);
    const lshape = getRandomFromArray(['LShape_1', 'LShape_2', 'LShape_3', 'LShape_4'] as const);

    return { number5, number3, number4, color, lshape };
  },
  Component: props => {
    const {
      question: { number5, number3, number4, color, lshape },
      translate
    } = props;

    const number1 = number3 + number5;
    const number2 = number4 * 2;
    const number6 = number4;

    const area = number1 * number6 + number4 * number3;
    const perimeter = 2 * (number1 + number2);

    const array =
      lshape === 'LShape_1'
        ? [number1, number6, number5, number4, number3, number2]
        : lshape === 'LShape_2'
        ? [number3, number4, number5, number6, number1, number2]
        : lshape === 'LShape_3'
        ? [number3, number2, number1, number6, number5, number4]
        : [number1, number2, number3, number4, number5, number6];

    const labels = array.map(val => translate.units.numberOfCm(val));

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.areaAnsCm2(),
          translate.answerSentences.perimeterAnsCm()
        ]}
        title={translate.instructions.workOutAreaAndPerimeterOfShape()}
        style={{ flexDirection: 'row' }}
        testCorrect={[[area.toString()], [perimeter.toString()]]}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={lshape} labels={labels} color={color} />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aWB',
  description: 'aWB',
  keywords: ['Area', 'Perimeter', 'Rectangle'],
  schema: z.object({
    width: z.number().int().min(5).max(10),
    height: z.number().int().min(2).max(5),
    missingDimension: z.number().int().min(0).max(1),
    isArea: z.boolean()
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(5, 10);
    const height = randomIntegerInclusive(2, 5, { constraint: x => x !== width });
    const missingDimension = randomIntegerInclusive(0, 1);
    const isArea = getRandomBoolean();

    return { width, height, missingDimension, isArea };
  },
  Component: props => {
    const {
      question: { width, height, missingDimension, isArea },
      translate
    } = props;

    const labels = [
      missingDimension === 0 ? '' : translate.units.numberOfCm(width),
      '',
      '',
      missingDimension === 1 ? '' : translate.units.numberOfCm(height)
    ];

    const area = height * width;
    const perimeter = 2 * (height + width);
    const title = isArea
      ? translate.instructions.thePerimeterIsXWorkOutArea(translate.units.numberOfCm(perimeter))
      : translate.instructions.theAreaIsXWorkOutPerimeter(translate.units.numberOfCm2(area));

    return (
      <QF1ContentAndSentence
        sentence={
          isArea
            ? translate.answerSentences.areaEqualsAnsCm2()
            : translate.answerSentences.perimeterEqualsAnsCm()
        }
        title={title}
        testCorrect={[isArea ? area.toString() : perimeter.toString()]}
        Content={({ dimens }) => (
          <LabelledQuadrilateral
            dimens={dimens}
            shape={'RectangleInteriorAngles'}
            labels={labels}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aWC',
  description: 'aWC',
  keywords: ['Area', 'Perimeter', 'Rectangle'],
  schema: z
    .object({
      number3: z.number().int().min(3).max(5),
      number4: z.number().int().min(2).max(4),
      number5: z.number().int().min(5).max(8),
      color: z.enum(['pink', 'blue', 'green', 'purple', 'yellow']),
      lshape: z.enum(['LShape_1', 'LShape_2', 'LShape_3', 'LShape_4']),
      missingDimens: z.number().int().min(1).max(6).array().length(2)
    })
    .refine(val => val.number4 <= val.number3, 'number4 must be smaller or equal to number3')
    .refine(val => val.number3 < val.number5, 'number3 must be smaller than number5')
    .refine(
      val => val.number3 + val.number5 <= 12,
      'sum of number3 and number 5 must be smaller than or equal to 12'
    ),
  simpleGenerator: () => {
    const number5 = randomIntegerInclusive(5, 8);
    const number3 = randomIntegerInclusive(3, number5 - 1, { constraint: x => x + number5 <= 12 });
    const number4 = randomIntegerInclusive(2, Math.min(4, number3));
    const color = getRandomFromArray(['pink', 'blue', 'green', 'purple', 'yellow'] as const);
    const lshape = getRandomFromArray(['LShape_1', 'LShape_2', 'LShape_3', 'LShape_4'] as const);
    const missingDimens = [
      getRandomFromArray([1, 3, 5] as const),
      getRandomFromArray([2, 4, 6] as const)
    ];

    return { number5, number3, number4, color, lshape, missingDimens };
  },
  Component: props => {
    const {
      question: { number5, number3, number4, color, lshape, missingDimens },
      translate
    } = props;

    const number1 = number3 + number5;
    const number2 = number4 * 2;
    const number6 = number4;

    const area = number1 * number6 + number4 * number3;
    const perimeter = 2 * (number1 + number2);

    const lengths = [number1, number2, number3, number4, number5, number6].map((val, i) => {
      return missingDimens.includes(i + 1) ? '' : translate.units.numberOfCm(val);
    });

    const labels =
      lshape === 'LShape_1'
        ? [lengths[0], lengths[5], lengths[4], lengths[3], lengths[2], lengths[1]]
        : lshape === 'LShape_2'
        ? [lengths[2], lengths[3], lengths[4], lengths[5], lengths[0], lengths[1]]
        : lshape === 'LShape_3'
        ? [lengths[2], lengths[1], lengths[0], lengths[5], lengths[4], lengths[3]]
        : [lengths[0], lengths[1], lengths[2], lengths[3], lengths[4], lengths[5]];

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.areaAnsCm2(),
          translate.answerSentences.perimeterAnsCm()
        ]}
        title={translate.instructions.workOutAreaAndPerimeterOfShape()}
        style={{ flexDirection: 'row' }}
        testCorrect={[[area.toString()], [perimeter.toString()]]}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={lshape} labels={labels} color={color} />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aWD',
  description: 'aWD',
  keywords: ['Area', 'Perimeter', 'Rectilinear'],
  schema: z.object({
    width: z.number().int().min(2).max(4),
    height: z.number().int().min(2).max(3),
    horizontalPositions: z.number().int().min(0).max(3).array().length(2),
    verticalPositions: z.number().int().min(0).max(2).array().length(2),
    isGreater: z.boolean(),
    sameArea: z.boolean()
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 4);
    const height = randomIntegerInclusive(2, 3);
    const horizontalPositions = randomUniqueIntegersInclusive(0, width - 1, 2);
    const verticalPositions = randomUniqueIntegersInclusive(0, height - 1, 2);
    const isGreater = getRandomBoolean();
    const sameArea = getRandomBoolean();

    return { width, height, horizontalPositions, verticalPositions, isGreater, sameArea };
  },
  Component: props => {
    const {
      question: { width, height, horizontalPositions, verticalPositions, isGreater, sameArea },
      translate
    } = props;

    const rectangle = createRectangleFromSquares(width, height);
    // add col to the left with the added true
    rectangle.forEach((row, i) => row.unshift(verticalPositions[0] === i ? true : false));
    // add col to the right with the added true
    rectangle.forEach((row, i) => row.push(verticalPositions[1] === i ? true : false));
    // add top row
    const topArray = filledArray(false, width + 2);
    topArray[horizontalPositions[0] + 1] = true;
    rectangle.unshift(topArray);
    // add bottom row
    const bottomArray = filledArray(false, width + 2);
    bottomArray[horizontalPositions[1] + 1] = true;
    rectangle.push(bottomArray);

    const perimeter = perimeterCount(rectangle);
    const area = height * width + 4;

    let title = '';
    let pdfTitle = '';
    let answerText = '';
    if (isGreater && sameArea) {
      title = translate.instructions.tapSquaresToCreateRectilinearShapeWithSameAreaGreaterPerim();
      pdfTitle = translate.instructions.shadeARectilinearShapeWithSameAreaGreaterPerim();
      answerText = translate.markScheme.anyRectilinearShapeWithAreaOfXAndPerimeterGreaterThanY(
        area,
        perimeter
      );
    } else if (!isGreater && sameArea) {
      title = translate.instructions.tapSquaresToCreateARectilinearShapeWithSameAreaSmallerPerim();
      pdfTitle = translate.instructions.shadeARectilinearShapeWithSameAreaSmallerPerim();
      answerText = translate.markScheme.anyRectilinearShapeWithAreaOfXAndPerimeterSmallerThanY(
        area,
        perimeter
      );
    } else if (isGreater && !sameArea) {
      title = translate.instructions.tapSquaresToCreateARectilinearShapeWithSamePerimGreaterArea();
      pdfTitle = translate.instructions.shadeARectilinearShapeWithSamePerimGreaterArea();
      answerText = translate.markScheme.anyRectilinearShapeWithPerimeterOfXAndAreaGreaterThanY(
        perimeter,
        area
      );
    } else {
      title = translate.instructions.tapSquaresToCreateARectilinearShapeWithSamePerimSmallerArea();
      pdfTitle = translate.instructions.shadeARectilinearShapeWithSamePerimSmallerArea();
      answerText = translate.markScheme.anyRectilinearShapeWithPerimeterOfXAndAreaSmallerThanY(
        perimeter,
        area
      );
    }

    return (
      <QF24aCreateShapeAndGivenShape
        title={title}
        pdfTitle={pdfTitle}
        givenShape={rectangle}
        numberOfRows={5}
        numberOfCols={6}
        testCorrect={userAnswer => {
          const userArea = trueCount(userAnswer);
          const userPerimeter = perimeterCount(userAnswer);
          if (isGreater && sameArea) {
            return userArea === area && userPerimeter > perimeter;
          } else if (!isGreater && sameArea) {
            return userArea === area && userPerimeter < perimeter;
          } else if (isGreater && !sameArea) {
            return userArea > area && userPerimeter === perimeter;
          } else {
            return userArea < area && userPerimeter === perimeter;
          }
        }}
        customMarkSchemeAnswer={{
          answerText: answerText
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AreaAndPerimeter',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
