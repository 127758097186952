import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import Clock from '../../../../components/question/representations/Clock';
import Table from '../../../../components/molecules/Table';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import { addDurationTo24hTime, convert24hTo12hString } from '../../../../utils/time';
import { colors, tableColors } from '../../../../theme/colors';
import { DigitalClock } from '../../../../components/question/representations/DigitalClock';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aui',
  description: 'aui',
  keywords: ['Time', 'Start times', 'End times', 'Duration', 'Hours'],
  schema: z.object({
    startHourA: numberEnum([6, 7, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
    startMinuteA: z.number().int().min(0).max(55).multipleOf(5),
    durationMinutesA: numberEnum([30, 60, 120]),
    startHourB: numberEnum([6, 7, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
    startMinuteB: z.number().int().min(0).max(55).multipleOf(5),
    durationMinutesB: numberEnum([30, 60, 120]),
    startHourC: numberEnum([6, 7, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
    startMinuteC: z.number().int().min(0).max(55).multipleOf(5),
    durationMinutesC: numberEnum([30, 60, 120]),
    startHourD: numberEnum([6, 7, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
    startMinuteD: z.number().int().min(0).max(55).multipleOf(5),
    missingDurationMinutesD: numberEnum([30, 60, 120])
  }),
  simpleGenerator: () => {
    const [startHourA, startHourB, startHourC, startHourD] = range(1, 4).map(() =>
      getRandomFromArray([6, 7, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19, 20] as const)
    );

    // Duration and minutes A

    const durationMinutesA = getRandomFromArray(
      startHourA === 6 || startHourA === 20 ? ([60, 120] as const) : ([30, 60, 120] as const)
    );

    const startMinuteMinA = startHourA === 6 ? 30 : 0;

    const startMinuteMaxA =
      durationMinutesA === 30 ? 25 : startHourA === 9 || startHourA === 20 ? 30 : 55;

    const startMinuteA = randomIntegerInclusiveStep(startMinuteMinA, startMinuteMaxA, 5);

    // Duration and minutes B

    const durationMinutesB = getRandomFromArray(
      startHourB === 6 || startHourB === 20 ? ([60, 120] as const) : ([30, 60, 120] as const)
    );

    const startMinuteMinB = startHourB === 6 ? 30 : 0;

    const startMinuteMaxB =
      durationMinutesB === 30 ? 25 : startHourB === 9 || startHourB === 20 ? 30 : 55;

    const startMinuteB = randomIntegerInclusiveStep(startMinuteMinB, startMinuteMaxB, 5);

    // Duration and minutes C

    const durationMinutesC = getRandomFromArray(
      startHourC === 6 || startHourC === 20 ? ([60, 120] as const) : ([30, 60, 120] as const)
    );

    const startMinuteMinC = startHourC === 6 ? 30 : 0;

    const startMinuteMaxC =
      durationMinutesC === 30 ? 25 : startHourC === 9 || startHourC === 20 ? 30 : 55;

    const startMinuteC = randomIntegerInclusiveStep(startMinuteMinC, startMinuteMaxC, 5);

    // Duration and minutes D

    const missingDurationMinutesD = getRandomFromArray(
      startHourD === 6 || startHourD === 20 ? ([60, 120] as const) : ([30, 60, 120] as const)
    );

    const startMinuteMinD = startHourD === 6 ? 30 : 0;

    const startMinuteMaxD =
      missingDurationMinutesD === 30 ? 25 : startHourD === 9 || startHourD === 20 ? 30 : 55;

    const startMinuteD = randomIntegerInclusiveStep(startMinuteMinD, startMinuteMaxD, 5);

    return {
      startHourA,
      startMinuteA,
      durationMinutesA,
      startHourB,
      startMinuteB,
      durationMinutesB,
      startHourC,
      startMinuteC,
      durationMinutesC,
      startHourD,
      startMinuteD,
      missingDurationMinutesD
    };
  },
  Component: props => {
    const {
      question: {
        startHourA,
        startMinuteA,
        durationMinutesA,
        startHourB,
        startMinuteB,
        durationMinutesB,
        startHourC,
        startMinuteC,
        durationMinutesC,
        startHourD,
        startMinuteD,
        missingDurationMinutesD
      },
      translate,
      displayMode
    } = props;

    const [endHourA, endMinuteA] = addDurationTo24hTime(startHourA, startMinuteA, durationMinutesA);

    const [endHourB, endMinuteB] = addDurationTo24hTime(startHourB, startMinuteB, durationMinutesB);

    const [endHourC, endMinuteC] = addDurationTo24hTime(startHourC, startMinuteC, durationMinutesC);

    const [endHourD, endMinuteD] = addDurationTo24hTime(
      startHourD,
      startMinuteD,
      missingDurationMinutesD
    );

    const programmes = shuffle(
      [
        translate.programmes.anAdventure(),
        translate.programmes.dennisTheScientist(),
        translate.programmes.pals(),
        translate.programmes.theFootballShow()
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const tableRows = [
      [
        translate.programmes.programme(),
        translate.programmes.startTime(),
        translate.programmes.finishTime(),
        translate.programmes.duration()
      ],
      ...shuffle(
        [
          [
            programmes[0],
            convert24hTo12hString(translate, startHourA, startMinuteA),
            convert24hTo12hString(translate, endHourA, endMinuteA),
            durationMinutesA === 30
              ? translate.time.halfAnHour()
              : translate.time.numHours(durationMinutesA / 60)
          ],
          [
            programmes[1],
            convert24hTo12hString(translate, startHourB, startMinuteB),
            convert24hTo12hString(translate, endHourB, endMinuteB),

            durationMinutesB === 30
              ? translate.time.halfAnHour()
              : translate.time.numHours(durationMinutesB / 60)
          ],
          [
            programmes[2],
            convert24hTo12hString(translate, startHourC, startMinuteC),
            convert24hTo12hString(translate, endHourC, endMinuteC),
            durationMinutesC === 30
              ? translate.time.halfAnHour()
              : translate.time.numHours(durationMinutesC / 60)
          ],
          [
            programmes[3],
            convert24hTo12hString(translate, startHourD, startMinuteD),
            convert24hTo12hString(translate, endHourD, endMinuteD),
            ''
          ]
        ],
        {
          random: seededRandom(props.question)
        }
      )
    ];

    const statements = [
      {
        sentence: translate.time.numHours(1),
        value: 60
      },
      {
        sentence: translate.time.numHours(2),
        value: 120
      },
      {
        sentence: translate.time.halfAnHour(),
        value: 30
      }
    ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheMissingDuration()}
        pdfTitle={translate.instructions.circleTheMissingDuration()}
        testCorrect={[missingDurationMinutesD]}
        contentContainerStyle={{
          flexDirection: 'row',
          justifyContent: 'space-evenly'
        }}
        itemLayout={'row'}
        numItems={3}
        Content={({ dimens }) => (
          <Table
            items={tableRows.map((row, rowIndex) =>
              row.map((cell, cellIndex) => (
                <View
                  key={`${rowIndex}-${cellIndex}`}
                  style={{
                    width: (dimens.width * 0.98) / 4,
                    height: dimens.height / 6,
                    backgroundColor:
                      rowIndex === 0
                        ? displayMode === 'digital'
                          ? tableColors.headerGreen
                          : colors.pdfShading
                        : 'white',
                    justifyContent: 'center'
                  }}
                >
                  <Text
                    // Show bold variant on duration times only.
                    variant={rowIndex !== 0 && cellIndex === 3 ? 'WRN700' : 'WRN400'}
                    style={{ fontSize: displayMode === 'digital' ? 26 : 50, textAlign: 'center' }}
                  >
                    {cell}
                  </Text>
                </View>
              ))
            )}
            style={dimens}
          />
        )}
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: <Text variant="WRN700">{sentence}</Text>
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'auj',
  description: 'auj',
  keywords: ['Time', 'Start time', 'End time', 'Duration', 'Minutes'],
  schema: z
    .object({
      startHour: z.number().int().min(1).max(12),
      startMinute: z.number().int().min(5).max(30).multipleOf(5),
      endMinute: z.number().int().min(30).max(55).multipleOf(5),
      incorrectMinutesA: z.number().int().min(5).max(60).multipleOf(5),
      incorrectMinutesB: z.number().int().min(5).max(60).multipleOf(5)
    })
    .refine(val => val.startMinute < val.endMinute, 'startMinute must be less than endMinute.')
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.endMinute - val.startMinute,
          val.incorrectMinutesA,
          val.incorrectMinutesB
        ]),
      'The correct answer, incorrectMinutesA and incorrectMinutesB must all be different.'
    ),
  simpleGenerator: () => {
    const startHour = randomIntegerInclusive(1, 12);

    const startMinute = randomIntegerInclusiveStep(5, 30, 5);

    const endMinute = randomIntegerInclusiveStep(startMinute === 30 ? 35 : 30, 55, 5);

    const [incorrectMinutesA, incorrectMinutesB] = randomUniqueIntegersInclusiveStep(5, 60, 5, 2, {
      constraint: x => x !== endMinute - startMinute
    });

    return { startHour, startMinute, endMinute, incorrectMinutesA, incorrectMinutesB };
  },
  Component: props => {
    const {
      question: { startHour, startMinute, endMinute, incorrectMinutesA, incorrectMinutesB },
      translate,
      displayMode
    } = props;

    const statements = [
      {
        sentence: translate.time.numMinutes(endMinute - startMinute),
        value: endMinute - startMinute
      },
      {
        sentence: translate.time.numMinutes(incorrectMinutesA),
        value: incorrectMinutesA
      },
      {
        sentence: translate.time.numMinutes(incorrectMinutesB),
        value: incorrectMinutesB
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.theClocksShowTheStartAndFinishOfAnActivity()}
        pdfTitle={translate.instructions.theClocksShowTheStartAndFinishOfAnActivityPDF()}
        testCorrect={[endMinute - startMinute]}
        itemLayout={'row'}
        numItems={3}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-evenly' }]}>
            <View>
              <Text variant="WRN700" style={{ alignSelf: 'center' }}>
                {translate.misc.start()}
              </Text>
              <Clock
                time={{ hours: startHour, minutes: startMinute }}
                width={displayMode === 'digital' ? dimens.height - 75 : dimens.height - 150}
                interactive={false}
              />
            </View>
            <View>
              <Text variant="WRN700" style={{ alignSelf: 'center' }}>
                {translate.misc.finish()}
              </Text>
              <Clock
                time={{ hours: startHour, minutes: endMinute }}
                width={displayMode === 'digital' ? dimens.height - 75 : dimens.height - 150}
                interactive={false}
              />
            </View>
          </View>
        )}
        renderItems={shuffledStatements.map(({ sentence, value }) => ({
          value,
          component: <Text variant="WRN700">{sentence}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'auk',
  description: 'auk',
  keywords: ['Time', 'Start time', 'End time', 'Duration', 'Hours', 'Minutes'],
  schema: z
    .object({
      startHour: z.number().int().min(1).max(9),
      startMinute: z.number().int().min(5).max(55).multipleOf(5),
      endMinute: z
        .number()
        .int()
        .min(1)
        .max(54)
        .refine(val => val % 5 !== 0, 'endMinute must not be a multiple of 5'),
      incorrectMinuteA: z
        .number()
        .int()
        .min(31)
        .max(59)
        .refine(val => val % 10 !== 0, 'incorrectMinuteA cannot be a multiple of 10'),
      incorrectHourB: z.number().int().min(1).max(3),
      incorrectMinuteB: z
        .number()
        .int()
        .min(31)
        .max(59)
        .refine(val => val % 5 !== 0, 'incorrectMinuteB must not be a multiple of 5'),
      amOrPm: z.enum(['am', 'pm'])
    })
    .refine(val => val.endMinute < val.startMinute, 'endMinute must be less than startMinute.')
    .refine(
      val =>
        val.incorrectMinuteB !== val.incorrectMinuteA &&
        val.incorrectMinuteB !== 60 - val.startMinute + val.endMinute,
      'incorrectMinuteB cannot equal incorrectMinuteA, and cannot equal 60 - startMinute + endMinute.'
    ),
  simpleGenerator: () => {
    const startHour = randomIntegerInclusive(1, 9);

    const startMinute = randomIntegerInclusiveStep(5, 55, 5);

    const endMinute = randomIntegerInclusive(1, startMinute - 1, {
      constraint: x => x % 5 !== 0
    });

    const incorrectMinuteA = randomIntegerInclusive(31, 59, {
      constraint: x => x % 10 !== 0
    });

    const incorrectHourB = randomIntegerInclusive(1, 3);

    const incorrectMinuteB = randomIntegerInclusive(31, 59, {
      constraint: x => x % 5 !== 0 && x !== incorrectMinuteA && x !== 60 - startMinute + endMinute
    });

    const amOrPm = getRandomFromArray(['am', 'pm'] as const);

    return {
      startHour,
      startMinute,
      endMinute,
      incorrectMinuteA,
      incorrectHourB,
      incorrectMinuteB,
      amOrPm
    };
  },
  Component: props => {
    const {
      question: {
        startHour,
        startMinute,
        endMinute,
        incorrectMinuteA,
        incorrectHourB,
        incorrectMinuteB,
        amOrPm
      },
      translate
    } = props;

    const statements = [
      {
        sentence: translate.time.numMinutes(incorrectMinuteA),
        isCorrect: false
      },
      {
        sentence: translate.answerSentences.xHoursAndYMinutes(1, 60 - startMinute + endMinute),
        isCorrect: true
      },
      {
        sentence: translate.answerSentences.xHoursAndYMinutes(incorrectHourB, incorrectMinuteB),
        isCorrect: false
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.theClocksShowTheStartAndFinishOfAnActivity()}
        pdfTitle={translate.instructions.theClocksShowTheStartAndFinishOfAnActivityPDF()}
        testCorrect={statements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.sentence)}
        contentContainerStyle={{
          flexDirection: 'row',
          justifyContent: 'space-evenly'
        }}
        numItems={3}
        Content={({ dimens }) => (
          <>
            <View>
              <Text variant="WRN700" style={{ alignSelf: 'center' }}>
                {translate.misc.start()}
              </Text>
              <DigitalClock
                hours={startHour}
                minutes={startMinute}
                dimens={{ height: dimens.height * 0.55, width: (dimens.width * 0.9) / 2 }}
                amOrPm={amOrPm}
                color="orange"
              />
            </View>
            <View>
              <Text variant="WRN700" style={{ alignSelf: 'center' }}>
                {translate.misc.finish()}
              </Text>
              <DigitalClock
                hours={startHour + 2}
                minutes={endMinute}
                dimens={{ height: dimens.height * 0.55, width: (dimens.width * 0.9) / 2 }}
                amOrPm={amOrPm}
                color="orange"
              />
            </View>
          </>
        )}
        renderItems={shuffledStatements.map(({ sentence }) => ({
          value: sentence,
          component: (
            <TextStructure
              sentence={sentence}
              textStyle={{ textAlign: 'center' }}
              textVariant="WRN700"
            />
          )
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aul',
  description: 'aul',
  keywords: ['Time', 'Start time', 'End time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z
    .object({
      name: nameSchema,
      startMinute: z.number().int().min(31).max(59),
      endHour: z.number().int().min(2).max(4),
      endMinute: z.number().int().min(1).max(58)
    })
    .refine(val => val.startMinute > val.endMinute, 'startMinute must be greater than endMinute'),
  simpleGenerator: () => {
    const name = getRandomName();

    const startMinute = randomIntegerInclusive(31, 59, {
      constraint: x => x % 5 !== 0
    });

    const endHour = randomIntegerInclusive(2, 4);

    const endMinute = randomIntegerInclusive(1, startMinute - 1);

    return { name, startMinute, endHour, endMinute };
  },
  Component: props => {
    const {
      question: { name, startMinute, endHour, endMinute },
      translate,
      displayMode
    } = props;

    const hourAns = endHour - 1;

    const svgName = getCharacterHeadSvgName(name);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.howLongDidCharSpendAtPark(name),
          translate.answerSentences.ansHoursAndAnsMinutes(hourAns === 1)
        ]}
        title={translate.instructions.characterWentToTheParkOnSaturday(name)}
        testCorrect={[[], [hourAns.toString(), (60 - startMinute + endMinute).toString()]]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }
            ]}
          >
            <SpeechBubble
              flickLocation="bottom-right"
              style={{
                maxWidth: 0.6 * dimens.width,
                maxHeight: 0.6 * dimens.height,
                marginHorizontal: 0.05 * dimens.width
              }}
            >
              <Text
                variant="WRN400"
                style={{ fontSize: displayMode === 'digital' ? 32 : 50, textAlign: 'center' }}
              >
                {translate.answerSentences.iGotToTheParkAtTimeAndLeftAtTime(
                  12,
                  startMinute,
                  endHour,
                  endMinute.toString().padStart(2, '0') // Ensure minutes < 10 always show a leading zero.
                )}
              </Text>
            </SpeechBubble>
            <View
              style={{
                left: 24,
                top: displayMode === 'digital' ? 48 : 80
              }}
            >
              <AssetSvg
                name={svgName}
                width={displayMode === 'digital' ? dimens.width * 0.2 : dimens.width * 0.1}
              />
            </View>
          </View>
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aum',
  description: 'aum',
  keywords: ['Time', 'Start time', 'End time', 'am', 'pm', 'Digital', 'Duration'],
  schema: z.object({
    hours1: z.number().int().min(7).max(10),
    hours3: z.number().int().min(7).max(9),
    minutes3: z.number().int().min(1).max(59),
    minutesMoreThan3: z.number().int().min(29).max(51),
    hours5: z.number().int().min(8).max(10),
    minutes5: z.number().int().min(0).max(59),
    hours7: z.number().int().min(3).max(6),
    minutes7: z.number().int().min(1).max(59),
    minutesMoreThan7: z.number().int().min(29).max(51),
    hours9: z.number().int().min(3).max(6),
    minutes9: z.number().int().min(0).max(55).multipleOf(5),
    hours10: z.number().int().min(4).max(8),
    minutes10: z.number().int().min(0).max(55).multipleOf(5)
  }),
  simpleGenerator: () => {
    const hours1 = randomIntegerInclusive(7, 10);

    const hours3 = randomIntegerInclusive(7, 9);

    const minutes3 = randomIntegerInclusive(1, 59, {
      constraint: x => x % 5 !== 0
    });

    const minutesMoreThan3 = randomIntegerInclusive(29, 51);

    const hours5 = randomIntegerInclusive(8, 10);

    // Should avoid exactly 8 o'clock
    const minutes5 = randomIntegerInclusive(1, 59);

    const hours7 = randomIntegerInclusive(3, 6);

    const minutes7 = randomIntegerInclusive(1, 59, {
      constraint: x => x % 5 !== 0
    });

    const minutesMoreThan7 = randomIntegerInclusive(29, 51);

    const hours9 = randomIntegerInclusive(3, 6);

    // Should avoid exactly 3 o'clock
    const minutes9 = randomIntegerInclusiveStep(5, 55, 5);

    const hours10 = randomIntegerInclusive(4, 8);

    // Should avoid exactly 4 o'clock
    const minutes10 = randomIntegerInclusiveStep(5, 55, 5);

    return {
      hours1,
      hours3,
      minutes3,
      minutesMoreThan3,
      hours5,
      minutes5,
      hours7,
      minutes7,
      minutesMoreThan7,
      hours9,
      minutes9,
      hours10,
      minutes10
    };
  },

  Component: props => {
    const {
      question: {
        hours1,
        hours3,
        minutes3,
        minutesMoreThan3,
        hours5,
        minutes5,
        hours7,
        minutes7,
        minutesMoreThan7,
        hours9,
        minutes9,
        hours10,
        minutes10
      },
      translate,
      displayMode
    } = props;

    const [hours4, minutes4] = addDurationTo24hTime(hours3, minutes3, minutesMoreThan3);

    // Difference between time5 and time6 is supposed to be the same as that between time3 and time4, hence using minutesMoreThan3:
    const [hours6, minutes6] = addDurationTo24hTime(hours5, minutes5, minutesMoreThan3);

    const [hours8, minutes8] = addDurationTo24hTime(hours7 + 1, minutes7, minutesMoreThan7);

    const statements = shuffle(
      [
        {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={`${translate.time.digitalTimeAm({
                  hours: hours1,
                  minutes: '00'
                })} - ${translate.time.digitalTimePm({ hours: hours1 + 1, minutes: '15' })}`}
              />
            </View>
          ),
          rhsComponent: (
            <View
              style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-start' }}
            >
              <TextStructure
                sentence={`${translate.time.digitalTimeAm({
                  hours: hours1,
                  minutes: '00'
                })} - ${translate.time.digitalTimeAm({ hours: hours1 + 1, minutes: '15' })}`}
              />
            </View>
          ),
          correctAnswer: '>'
        },
        {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={`${translate.time.digitalTimePm({
                  hours: hours3,
                  minutes: minutes3.toString().padStart(2, '0')
                })} - ${translate.time.digitalTimePm({
                  hours: hours4,
                  minutes: minutes4.toString().padStart(2, '0')
                })}`}
              />
            </View>
          ),
          rhsComponent: (
            <View
              style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-start' }}
            >
              <TextStructure
                sentence={`${translate.time.digitalTimeAm({
                  hours: hours5,
                  minutes: minutes5.toString().padStart(2, '0')
                })} - ${translate.time.digitalTimeAm({
                  hours: hours6,
                  minutes: minutes6.toString().padStart(2, '0')
                })}`}
              />
            </View>
          ),
          correctAnswer: '='
        },
        {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-end' }}>
              <TextStructure
                sentence={`${translate.time.digitalTimePm({
                  hours: hours7,
                  minutes: minutes7.toString().padStart(2, '0')
                })} - ${translate.time.digitalTimePm({
                  hours: hours8,
                  minutes: minutes8.toString().padStart(2, '0')
                })}`}
              />
            </View>
          ),
          rhsComponent: (
            <View
              style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-start' }}
            >
              <TextStructure
                sentence={`${translate.time.digitalTimeAm({
                  hours: hours9,
                  minutes: minutes9.toString().padStart(2, '0')
                })} - ${translate.time.digitalTimePm({
                  hours: hours10,
                  minutes: minutes10.toString().padStart(2, '0')
                })}`}
              />
            </View>
          ),
          correctAnswer: '<'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragLessThanGreaterThanEqualsToCompareDurations()}
        pdfTitle={translate.instructions.useLessThanGreaterThanEqualsToCompareDurations()}
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        pdfLayout="itemsHidden"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['<', '>', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5v2 = newQuestionContent({
  uid: 'aum2',
  description: 'aum',
  keywords: ['Time', 'Start time', 'End time', 'am', 'pm', 'Digital', 'Duration'],
  schema: z.object({
    variant: z.discriminatedUnion('questionVariation', [
      z.object({
        questionVariation: z.literal('a'),
        hoursA: z.number().int().min(7).max(10),
        minutesA: z.literal(0),
        hoursB: z.number().int().min(8).max(11),
        minutesB: z.literal(15),
        hoursC: z.number().int().min(7).max(10),
        minutesC: z.literal(0),
        hoursD: z.number().int().min(8).max(11),
        minutesD: z.literal(15)
      }),
      z.object({
        questionVariation: z.literal('b'),
        hoursA: z.number().int().min(7).max(9),
        minutesA: z.number().int().min(1).max(59),
        hoursB: z.number().int().min(7).max(10),
        minutesB: z.number().int().min(0).max(59),
        hoursC: z.number().int().min(7).max(10),
        minutesC: z.number().int().min(0).max(59),
        hoursD: z.number().int().min(7).max(11),
        minutesD: z.number().int().min(0).max(59)
      }),
      z.object({
        questionVariation: z.literal('c'),
        hoursA: z.number().int().min(3).max(6),
        minutesA: z.number().int().min(1).max(59),
        hoursB: z.number().int().min(4).max(8),
        minutesB: z.number().int().min(0).max(59),
        hoursC: z.number().int().min(3).max(6),
        minutesC: z.number().int().min(0).max(55).multipleOf(5),
        hoursD: z.number().int().min(4).max(8),
        minutesD: z.number().int().min(0).max(55).multipleOf(5)
      })
    ])
  }),
  simpleGenerator: () => {
    const questionVariation = getRandomFromArray(['a', 'b', 'c'] as const);

    if (questionVariation === 'a') {
      const hours1 = randomIntegerInclusive(7, 10);
      return {
        variant: {
          questionVariation: 'a' as const,
          hoursA: hours1,
          minutesA: 0 as const,
          hoursB: hours1 + 1,
          minutesB: 15 as const,
          hoursC: hours1,
          minutesC: 0 as const,
          hoursD: hours1 + 1,
          minutesD: 15 as const
        }
      };
    } else if (questionVariation === 'b') {
      const hours3 = randomIntegerInclusive(7, 9);

      const minutes3 = randomIntegerInclusive(1, 59, {
        constraint: x => x % 5 !== 0
      });

      const minutesMoreThan3 = randomIntegerInclusive(29, 51);

      const [hours4, minutes4] = addDurationTo24hTime(hours3, minutes3, minutesMoreThan3);

      const hours5 = randomIntegerInclusive(8, 10);

      // Should avoid exactly 8 o'clock
      const minutes5 = randomIntegerInclusive(1, 59);

      // Difference between time5 and time6 is supposed to be the same as that between time3 and time4, hence using minutesMoreThan3:
      const [hours6, minutes6] = addDurationTo24hTime(hours5, minutes5, minutesMoreThan3);
      return {
        variant: {
          questionVariation: 'b' as const,
          hoursA: hours3,
          minutesA: minutes3,
          hoursB: hours4,
          minutesB: minutes4,
          hoursC: hours5,
          minutesC: minutes5,
          hoursD: hours6,
          minutesD: minutes6
        }
      };
    } else {
      const hours7 = randomIntegerInclusive(3, 6);

      const minutes7 = randomIntegerInclusive(1, 59, {
        constraint: x => x % 5 !== 0
      });

      const minutesMoreThan7 = randomIntegerInclusive(29, 51);

      const [hours8, minutes8] = addDurationTo24hTime(hours7 + 1, minutes7, minutesMoreThan7);

      const hours9 = randomIntegerInclusive(3, 6);

      // Should avoid exactly 3 o'clock
      const minutes9 = randomIntegerInclusiveStep(5, 55, 5);

      const hours10 = randomIntegerInclusive(4, 8);

      // Should avoid exactly 4 o'clock
      const minutes10 = randomIntegerInclusiveStep(5, 55, 5);
      return {
        variant: {
          questionVariation: 'c' as const,
          hoursA: hours7,
          minutesA: minutes7,
          hoursB: hours8,
          minutesB: minutes8,
          hoursC: hours9,
          minutesC: minutes9,
          hoursD: hours10,
          minutesD: minutes10
        }
      };
    }
  },

  Component: props => {
    const {
      question: { variant },
      translate,
      displayMode
    } = props;

    const {
      hoursA,
      hoursB,
      hoursC,
      hoursD,
      minutesA,
      minutesB,
      minutesC,
      minutesD,
      questionVariation
    } = variant;

    const statements = [
      questionVariation === 'a'
        ? {
            lhsComponent: (
              <View
                style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-end' }}
              >
                <TextStructure
                  sentence={`${translate.time.digitalTimeAm({
                    hours: hoursA,
                    minutes: minutesA.toString().padStart(2, '0')
                  })} - ${translate.time.digitalTimePm({
                    hours: hoursB,
                    minutes: minutesB.toString().padStart(2, '0')
                  })}`}
                />
              </View>
            ),
            rhsComponent: (
              <View
                style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-start' }}
              >
                <TextStructure
                  sentence={`${translate.time.digitalTimeAm({
                    hours: hoursC,
                    minutes: minutesC.toString().padStart(2, '0')
                  })} - ${translate.time.digitalTimeAm({
                    hours: hoursD,
                    minutes: hoursD.toString().padStart(2, '0')
                  })}`}
                />
              </View>
            ),
            correctAnswer: '>'
          }
        : variant.questionVariation === 'b'
        ? {
            lhsComponent: (
              <View
                style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-end' }}
              >
                <TextStructure
                  sentence={`${translate.time.digitalTimePm({
                    hours: hoursA,
                    minutes: minutesA.toString().padStart(2, '0')
                  })} - ${translate.time.digitalTimePm({
                    hours: hoursB,
                    minutes: minutesB.toString().padStart(2, '0')
                  })}`}
                />
              </View>
            ),
            rhsComponent: (
              <View
                style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-start' }}
              >
                <TextStructure
                  sentence={`${translate.time.digitalTimeAm({
                    hours: hoursC,
                    minutes: minutesC.toString().padStart(2, '0')
                  })} - ${translate.time.digitalTimeAm({
                    hours: hoursD,
                    minutes: minutesD.toString().padStart(2, '0')
                  })}`}
                />
              </View>
            ),
            correctAnswer: '='
          }
        : {
            lhsComponent: (
              <View
                style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-end' }}
              >
                <TextStructure
                  sentence={`${translate.time.digitalTimePm({
                    hours: hoursA,
                    minutes: minutesA.toString().padStart(2, '0')
                  })} - ${translate.time.digitalTimePm({
                    hours: hoursB,
                    minutes: minutesB.toString().padStart(2, '0')
                  })}`}
                />
              </View>
            ),
            rhsComponent: (
              <View
                style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-start' }}
              >
                <TextStructure
                  sentence={`${translate.time.digitalTimeAm({
                    hours: hoursC,
                    minutes: minutesC.toString().padStart(2, '0')
                  })} - ${translate.time.digitalTimePm({
                    hours: hoursD,
                    minutes: minutesD.toString().padStart(2, '0')
                  })}`}
                />
              </View>
            ),
            correctAnswer: '<'
          }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragACardToCompareTheDurations()}
        pdfTitle={translate.instructions.useLessThanGreaterThanEqualsToCompareDurations()}
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        pdfLayout="itemsHidden"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['<', '>', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aun',
  description: 'aun',
  keywords: ['Time', 'Start time', 'End time', 'Digital', 'Duration'],
  schema: z.object({
    charge: z.number().int().min(2).max(5),
    durationOfCharge: z.number().int().min(20).max(50).multipleOf(10),
    startHour: z.number().int().min(1).max(7),
    startMinute: z.number().int().min(1).max(59),
    endHour: z.number().int().min(1).max(12),
    endMinute: z.number().int().min(0).max(59)
  }),
  simpleGenerator: () => {
    const charge = randomIntegerInclusive(2, 5);

    const durationOfCharge = randomIntegerInclusiveStep(20, 50, 10);

    const startHour = randomIntegerInclusive(1, 7);

    const startMinute = randomIntegerInclusive(1, 59);

    const multiplier = randomIntegerInclusive(2, 6);

    const [endHour, endMinute] = addDurationTo24hTime(
      startHour,
      startMinute,
      multiplier * durationOfCharge
    );

    return { charge, durationOfCharge, startHour, startMinute, endHour, endMinute };
  },

  Component: props => {
    const {
      question: { charge, durationOfCharge, startHour, startMinute, endHour, endMinute },
      translate
    } = props;

    const totalMins = (endHour - startHour) * 60 + (endMinute - startMinute);

    const totalTimesCharged = Math.ceil(totalMins / durationOfCharge);

    const totalCharge = charge * totalTimesCharged;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aCarParkChargesXPoundForEveryYMinutes(
          charge,
          durationOfCharge,
          startHour.toLocaleString(),
          startMinute.toLocaleString(undefined, { minimumIntegerDigits: 2 }),
          endHour.toLocaleString(),
          endMinute.toLocaleString(undefined, { minimumIntegerDigits: 2 })
        )}
        testCorrect={[totalCharge.toString()]}
        sentence={'£<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HoursAndMinutesUseStartAndEndTimes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
