import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { TranslationFunctions } from '../i18n/i18n-types';

// Pets
export const petNames = ['Dogs', 'Hamsters', 'Cats', 'Rabbits', 'Gerbils', 'Fish'] as const;

export const petSchema = z.enum(petNames);

export type PetName = (typeof petNames)[number];

export const petAsWord = (object: PetName, translate: TranslationFunctions, plural = false) => {
  switch (object) {
    case 'Dogs':
      return translate.animals.dogs(plural ? 2 : 1);
    case 'Hamsters':
      return translate.animals.hamsters(plural ? 2 : 1);
    case 'Cats':
      return translate.animals.cats(plural ? 2 : 1);
    case 'Rabbits':
      return translate.animals.rabbits(plural ? 2 : 1);
    case 'Gerbils':
      return translate.animals.gerbils(plural ? 2 : 1);
    case 'Fish':
      return translate.animals.fish();
  }
};

export function getRandomPet() {
  return getRandomFromArray(petNames);
}
export function getRandomUniquePets(numberOfPets: number) {
  return getRandomSubArrayFromArray([...petNames] as const, numberOfPets);
}
