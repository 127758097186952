import { newSmallStepContent } from '../../../SmallStep';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import {
  charactersWithHeights,
  charactersWithHeightsNames,
  getRandomUniqueCharacterHeightObjects
} from '../../../../utils/characters';
import { sortNumberArray } from '../../../../utils/collections';
import { CubeTower } from '../../../../components/question/representations/CubeTower';

////
// Questions
////

const objects = ['flower', 'tree', 'ribbons', 'pencil', 'cube'] as const;
type Objects = (typeof objects)[number];

const Question1 = newQuestionContent({
  uid: 'bdp',
  description: 'bdp',
  keywords: ['Longer', 'Taller', 'Shorter', 'Height', 'Length', 'Compare'],
  schema: z.object({
    lengths: z.array(z.number().int().min(3).max(9)).length(2),
    isVertical: z.boolean(),
    object: z.enum(objects),
    selectShorter: z.boolean(),
    cubeColor: z.enum(['blue', 'green', 'orange', 'purple', 'red', 'pink', 'yellow'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const selectShorter = getRandomBoolean();
    const verticalObjects = ['flower', 'tree', 'cube'] as const;
    const horizontalObjects = ['ribbons', 'pencil'] as const;
    const cubeColor = getRandomFromArray([
      'blue',
      'green',
      'orange',
      'purple',
      'red',
      'pink',
      'yellow'
    ] as const);

    const { object, lengths } = rejectionSample(
      () => {
        const object = isVertical
          ? getRandomFromArray(verticalObjects)
          : getRandomFromArray(horizontalObjects);

        // Some svgs can't be seen well when they are too small/long
        const getMinMax = (obj: Objects): [number, number] =>
          ['ribbons', 'pencil'].includes(obj) ? [4, 9] : [3, 9];

        const length1 = randomIntegerInclusive(...getMinMax(objects[0]));
        const length2 = randomIntegerInclusive(...getMinMax(objects[1]), {
          constraint: x => length1 !== x
        });
        return { object, lengths: [length1, length2] };
      },
      ({ lengths }) => Math.abs(lengths[0] - lengths[1]) < 7 // If the length difference is too large the smaller ones can't be seen
    );

    return { object, lengths, isVertical, selectShorter, cubeColor };
  },
  Component: props => {
    const {
      question: { object, lengths, isVertical, selectShorter, cubeColor },
      translate
    } = props;

    const random = seededRandom(props.question);
    const getSvgNames = (object: Objects) => {
      switch (object) {
        case 'flower':
          return getRandomSubArrayFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            2,
            { random }
          );
        case 'tree':
          return getRandomSubArrayFromArray(['Tree_A', 'Tree_B'] as const, 2, { random });
        case 'ribbons':
          return getRandomSubArrayFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, 2, {
            random
          });
        case 'pencil':
          return getRandomSubArrayFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            2,
            { random }
          );
        case 'cube':
          return getRandomSubArrayFromArray(
            [
              [
                `3D_shapes_full_colors/Cubes/Cube_${cubeColor}`,
                `3D_shapes_full_colors/Cubes/Cube_${cubeColor}`
              ],
              [
                `3D_shapes_full_colors/Cubes/Cube_${cubeColor}_2`,
                `3D_shapes_full_colors/Cubes/Cube_${cubeColor}_2`
              ],
              [
                `3D_shapes_full_colors/Cubes/Cube_${cubeColor}_3`,
                `3D_shapes_full_colors/Cubes/Cube_${cubeColor}_3`
              ]
            ] as const,
            2,
            {
              random
            }
          )[0];
      }
    };

    const objectTranslated =
      object === 'cube'
        ? translate.objects.towerOfCubes()
        : object === 'flower'
        ? translate.objects.Flower()
        : object === 'pencil'
        ? translate.objects.Pencil()
        : object === 'ribbons'
        ? translate.objects.Ribbon()
        : translate.objects.Tree();

    const [answer] = sortNumberArray(lengths, selectShorter ? 'ascending' : 'descending');

    const [svg1, svg2] = getSvgNames(object);

    return (
      <QF11SelectImagesUpTo4
        title={
          selectShorter
            ? translate.ks1Instructions.selectTheShorterXObject(objectTranslated)
            : isVertical
            ? translate.ks1Instructions.selectTheTallerXObject(objectTranslated)
            : translate.ks1Instructions.selectTheLongerXObject(objectTranslated)
        }
        pdfTitle={
          selectShorter
            ? translate.ks1PDFInstructions.tickTheShorterXObject(objectTranslated)
            : isVertical
            ? translate.ks1PDFInstructions.tickTheTallerXObject(objectTranslated)
            : translate.ks1PDFInstructions.tickTheLongerXObject(objectTranslated)
        }
        numItems={2}
        itemLayout={isVertical ? 'grid' : 'column'}
        innerContainerStyle={{ rowGap: !isVertical ? 24 : undefined }}
        itemStyle={
          isVertical
            ? { justifyContent: 'flex-end', paddingBottom: 24 }
            : { alignItems: 'flex-start', paddingLeft: 24 }
        }
        renderItems={({ dimens }) => {
          return lengths.map((length, lengthIdx) => {
            return {
              value: length,
              component: (
                <View
                  style={{
                    flexDirection: isVertical
                      ? lengthIdx === 0
                        ? 'row'
                        : 'row-reverse'
                      : 'column',
                    alignItems: 'flex-end'
                  }}
                >
                  <AssetSvg
                    name={lengthIdx === 0 ? (svg1 as SvgName) : (svg2 as SvgName)}
                    height={isVertical ? dimens.height * (length * 0.1) : undefined}
                    width={!isVertical ? (dimens.width / 2) * (length * 0.1) : undefined}
                  />
                </View>
              )
            };
          });
        }}
        questionHeight={1000}
        testCorrect={[answer]}
      />
    );
  }
});

const verticalObjects = ['flower', 'tree', 'cube'] as const;
const horizontalObjects = ['ribbons', 'pencil'] as const;

const Question2 = newQuestionContent({
  uid: 'bdq',
  description: 'bdq',
  keywords: ['Taller', 'Shorter', 'Longer', 'Height', 'Length', 'Compare'],
  schema: z.object({
    lengths: z.array(z.number().int().min(5).max(9)).length(2),
    objectInfo: z.discriminatedUnion('object', [
      z.object({
        object: z.literal('tree'),
        svgNames: z.enum(['Tree_A', 'Tree_B']).array().length(2)
      }),
      z.object({
        object: z.literal('pencil'),
        svgNames: z
          .enum(['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'])
          .array()
          .length(2)
      }),
      z.object({
        object: z.literal('flower'),
        svgNames: z
          .enum([
            'Flowers/Flower_Red',
            'Flowers/Flower_Orange',
            'Flowers/Flower_Blue',
            'Flowers/Flower_Purple'
          ])
          .array()
          .length(2)
      }),
      z.object({
        object: z.literal('ribbons'),
        svgNames: z.enum(['RibbonGreen', 'RibbonRedStriped']).array().length(2)
      }),
      z.object({
        object: z.literal('cube'),
        cubeColor: z.enum(['red', 'green', 'blue', 'yellow', 'purple', 'orange'])
      })
    ]),
    letterVariation: z.boolean(),
    items: z.array(z.enum(['taller', 'shorter', 'longer']))
  }),
  simpleGenerator: () => {
    const letterVariation = getRandomBoolean();
    const items = shuffle(['taller', 'shorter', 'longer'] as const);

    const object = getRandomFromArray([...verticalObjects, ...horizontalObjects] as const);

    const objectInfo = (() => {
      switch (object) {
        case 'cube':
          return {
            object,
            cubeColor: getRandomFromArray([
              'red',
              'green',
              'blue',
              'yellow',
              'purple',
              'orange'
            ] as const)
          };
        case 'flower':
          return {
            object,
            svgNames: getRandomSubArrayFromArray(
              [
                'Flowers/Flower_Red',
                'Flowers/Flower_Orange',
                'Flowers/Flower_Blue',
                'Flowers/Flower_Purple'
              ] as const,
              2
            )
          };
        case 'pencil':
          return {
            object,
            svgNames: getRandomSubArrayFromArray(
              ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
              2
            )
          };
        case 'ribbons':
          return {
            object,
            svgNames: getRandomSubArrayFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, 2)
          };
        case 'tree':
          return {
            object,
            svgNames: getRandomSubArrayFromArray(['Tree_A', 'Tree_B'] as const, 2)
          };
      }
    })();

    const lengths = rejectionSample(
      () => randomUniqueIntegersInclusive(5, 9, 2),
      // make sure there is noticeable difference
      val => Math.abs(val[0] - val[1]) > 2
    );

    return { objectInfo, lengths, letterVariation, items };
  },
  Component: props => {
    const {
      question: { objectInfo, lengths, letterVariation, items },
      translate
    } = props;

    const { object } = objectInfo;
    const isVertical = verticalObjects.includes(object as string as 'flower' | 'tree' | 'cube');
    const [length1, length2] = lengths;
    const shortestItem = letterVariation ? length1 < length2 : length2 < length1;

    const answer = shortestItem
      ? translate.ks1MiscStrings.shorter()
      : isVertical
      ? translate.ks1MiscStrings.taller()
      : translate.ks1MiscStrings.longer();

    const [letterA, letterB] = letterVariation
      ? [translate.letters.A(), translate.letters.B()]
      : [translate.letters.B(), translate.letters.A()];

    const itemsTranslated = items.map(item => translate.ks1MiscStrings[item]());

    const objectTranslated =
      object === 'cube'
        ? translate.objects.Tower()
        : object === 'flower'
        ? translate.objects.Flower()
        : object === 'pencil'
        ? translate.objects.Pencil()
        : object === 'ribbons'
        ? translate.objects.Ribbon()
        : translate.objects.Tree();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragACardToCompleteSentence()}
        pdfTitle={translate.instructions.useACardToCompleteSentence()}
        items={itemsTranslated}
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        Content={({ dimens }) => {
          return (
            <View
              style={{
                flexDirection: isVertical ? 'row' : 'column',
                alignItems: isVertical ? 'flex-end' : 'flex-start',
                columnGap: isVertical ? 32 : undefined,
                rowGap: isVertical ? undefined : 32
              }}
            >
              {lengths.map((length, lengthIdx) => {
                return (
                  <View
                    style={{
                      flexDirection: isVertical ? 'column' : 'row-reverse',
                      alignItems: 'center',
                      columnGap: !isVertical ? 16 : undefined,
                      rowGap: isVertical ? 16 : undefined
                    }}
                    key={lengthIdx}
                  >
                    {object === 'cube' ? (
                      <CubeTower
                        count={length}
                        dimens={{
                          width: (dimens.height / Math.max(...lengths)) * 2,
                          height: (dimens.height / Math.max(...lengths)) * (length - 1)
                        }}
                        color={objectInfo.cubeColor}
                      />
                    ) : (
                      <AssetSvg
                        name={objectInfo.svgNames[lengthIdx]}
                        height={isVertical ? dimens.height * (length * 0.1) : undefined}
                        width={!isVertical ? (dimens.width / 2) * (length * 0.1) : undefined}
                      />
                    )}
                    <Text variant="WRN400">
                      {lengthIdx === 0 ? translate.letters.A() : translate.letters.B()}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        }}
        sentence={translate.ks1AnswerSentences.xObjectWithLabelIsAnsThanYObjectWithLabel(
          objectTranslated,
          letterA,
          letterB
        )}
        testCorrect={[answer]}
        questionHeight={1500}
        sentenceStyle={{
          alignSelf: 'flex-start'
        }}
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsBottom"
      />
    );
  },
  questionHeight: 1500
});

const Question3 = newQuestionContent({
  uid: 'bdr',
  description: 'bdr',
  keywords: ['Taller', 'Shorter', 'Height', 'Compare'],
  schema: z.object({
    characters: z.array(z.enum(charactersWithHeightsNames)).length(3),
    shorterOrTaller: z.enum(['Shorter', 'Taller']),
    randomCharacterIdx: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const { characters, shorterOrTaller, randomCharacterIdx } = rejectionSample(
      () => {
        const characters = getRandomUniqueCharacterHeightObjects(3).map(
          char => char.name
        ) as charactersWithHeightsNames[];
        const shorterOrTaller = getRandomFromArray(['Shorter', 'Taller'] as const);
        const randomCharacterIdx = randomIntegerInclusive(0, 2);

        return { characters, shorterOrTaller, randomCharacterIdx };
      },
      ({ characters, shorterOrTaller, randomCharacterIdx }) => {
        const characterObjects = characters.map(char =>
          charactersWithHeights.find(charObj => charObj.name === char)
        ) as {
          name: charactersWithHeightsNames;
          icon: SvgName;
          height: number;
          scale: number;
        }[];

        const selectedCharacterHeight = characterObjects[randomCharacterIdx].height;

        return shorterOrTaller === 'Shorter'
          ? characterObjects.some(character => character.height < selectedCharacterHeight)
          : characterObjects.some(character => character.height > selectedCharacterHeight);
      }
    );

    return {
      characters,
      shorterOrTaller,
      randomCharacterIdx
    };
  },
  Component: props => {
    const {
      question: { characters, shorterOrTaller, randomCharacterIdx },
      translate,
      displayMode
    } = props;

    const characterObjects = characters.map(char =>
      charactersWithHeights.find(charObj => charObj.name === char)
    ) as {
      name: charactersWithHeightsNames;
      icon: SvgName;
      height: number;
      scale: number;
    }[];

    const [characterObjectA, characterObjectB, characterObjectC] = characterObjects;

    const randomCharacter = characterObjects[randomCharacterIdx];

    const otherCharacters = characterObjects.filter(
      (_character, idx) => idx !== randomCharacterIdx
    );

    const selectedCharacterHeight = characterObjects[randomCharacterIdx].height;

    const answers =
      shorterOrTaller === 'Shorter'
        ? otherCharacters.filter(character => character.height < selectedCharacterHeight)
        : otherCharacters.filter(character => character.height > selectedCharacterHeight);

    const items = characterObjects.map(character => character.name);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragACardToCompleteSentence()}
        pdfTitle={translate.instructions.useACardToCompleteSentence()}
        items={items}
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'center',
              columnGap: 24
            }}
          >
            <View style={{ alignItems: 'center' }}>
              <AssetSvg
                name={characterObjectA.icon as SvgName}
                width={
                  displayMode === 'digital'
                    ? 280 * characterObjectA.scale
                    : 380 * characterObjectA.scale
                }
              />
              <Text variant="WRN400">{characterObjectA.name}</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <AssetSvg
                name={characterObjectB.icon as SvgName}
                width={
                  displayMode === 'digital'
                    ? 280 * characterObjectB.scale
                    : 380 * characterObjectB.scale
                }
              />
              <Text variant="WRN400">{characterObjectB.name}</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <AssetSvg
                name={characterObjectC.icon as SvgName}
                width={
                  displayMode === 'digital'
                    ? 280 * characterObjectC.scale
                    : 380 * characterObjectC.scale
                }
              />
              <Text variant="WRN400">{characterObjectC.name}</Text>
            </View>
          </View>
        )}
        sentence={translate.ks1AnswerSentences.AnsIsXHeightThanYCharacter(
          shorterOrTaller === 'Shorter' ? translate.misc.Shorter() : translate.misc.Taller(),
          randomCharacter.name
        )}
        testCorrect={userAnswer => {
          if (Array.isArray(userAnswer) && userAnswer.length > 0) {
            const answerString = userAnswer[0];

            // Can accept multiple answers
            return answerString && answers.some(str => str.name.includes(answerString));
          }
        }}
        questionHeight={1300}
        customMarkSchemeAnswer={{
          answersToDisplay: [answers.map(answer => answer.name)],
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
        sentenceStyle={{
          alignSelf: 'flex-start'
        }}
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsBottom"
      />
    );
  },
  questionHeight: 1300
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareLengthsAndHeights',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
