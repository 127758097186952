import { ReactNode, useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { StateTreeRoot } from '../../stateTree';
import { noop } from '../../utils/flowControl';
import MainPanel from './MainPanel';
import { TitleStyleProps } from './TitleRow';
import { MINIMUM_QUESTION_HEIGHT } from '../../theme/scaling';
import { DisplayMode } from '../../contexts/displayMode';

type Props = TitleStyleProps & {
  title?: string | JSX.Element;
  /** Contents of the main panel, below the title. */
  mainPanelContents?: ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  /** Prop for extra content modal */
  modalContent?: JSX.Element;
  /** Height of question */
  questionHeight?: number;
};

/**
 * Similar to BaseLayout, except the layout is designed for printing to PDF document. Therefore, it does no state
 * management.
 */
export default function BaseLayoutPDF({
  title,
  titleStyle,
  containerStyle,
  titleTextStyle,
  titleFractionContainerStyle,
  titleFractionTextStyle,
  mainPanelContents,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  modalContent
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(questionHeight);

  return (
    <StateTreeRoot state={{}} setState={noop} onCorrectChanged={noop} onCompleteChanged={noop}>
      <View style={styles.mainPanel}>
        <MainPanel
          style={styles.mainPanelContainer}
          containerStyle={[styles.contentContainer, containerStyle]}
          title={title}
          titleStyle={titleStyle}
          titleTextStyle={[styles.titleText, titleTextStyle]}
          titleFractionContainerStyle={titleFractionContainerStyle}
          titleFractionTextStyle={[styles.titleText, titleFractionTextStyle]}
          actionPanelVariant={'end'}
          displayMode={displayMode}
        >
          {mainPanelContents}
          {modalContent && <View style={styles.modal}>{modalContent}</View>}
        </MainPanel>
      </View>
    </StateTreeRoot>
  );
}

function useStyles(questionHeight: number) {
  return useMemo(
    () =>
      StyleSheet.create({
        mainPanel: {
          width: 1980,
          flexDirection: questionHeight <= MINIMUM_QUESTION_HEIGHT ? 'row' : 'column',
          height: questionHeight
        },
        mainPanelContainer: {
          width: 1980,
          height: questionHeight
        },
        contentContainer: {
          paddingTop: 70
        },
        modal: {
          flex: 1,
          justifyContent: 'flex-end',
          alignContent: 'flex-end',
          alignItems: 'flex-end'
        },
        titleText: {
          fontSize: 50,
          lineHeight: 75,
          color: 'black'
        },
        fractionContainer: {
          borderColor: 'black'
        }
      }),
    [questionHeight]
  );
}
