import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { ADD, DIV, MULT, SUB } from '../../../../constants';
import QF28FunctionMachines from '../../../../components/question/questionFormats/QF28FunctionMachines';
import { FunctionMachinesInputWithState } from '../../../../components/question/representations/FunctionMachines';
import { CustomizableTableWithState } from '../../../../components/question/representations/CustomizableTable';
import { View } from 'react-native';
import { parseToSUB } from '../../../../utils/parse';
import QF28aFunctionMachinesDraggable from '../../../../components/question/questionFormats/QF28aFunctionMachinesDraggable';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { countRange } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aTQ',
  description: 'aTQ',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z.object({
    multiplier: z.number().int().min(2).max(6),
    addend: z.number().int().min(1).max(10),
    number: z.number().int().min(2).max(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 6);

    const addend = randomIntegerInclusive(1, 10);

    const number = randomIntegerInclusive(2, 10);

    return { multiplier, addend, number };
  },
  Component: props => {
    const {
      question: { multiplier, addend, number },
      translate
    } = props;

    return (
      <QF28FunctionMachines
        title={translate.instructions.calcOutputsForFunctionMachines()}
        actionPanelVariant="endWide"
        rowsOfBoxes={[
          [
            number.toLocaleString(),
            `${MULT} ${multiplier.toLocaleString()}`,
            `${ADD} ${addend.toLocaleString()}`,
            '<ans/>'
          ],
          [
            number.toLocaleString(),
            `${ADD} ${addend.toLocaleString()}`,
            `${MULT} ${multiplier.toLocaleString()}`,
            '<ans/>'
          ]
        ]}
        testCorrect={[
          [(number * multiplier + addend).toString()],
          [((number + addend) * multiplier).toString()]
        ]}
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aTR',
  description: 'aTR',
  keywords: ['Table', 'Function machine', 'Input', 'Output'],
  schema: z.object({
    multiplier: z.number().int().min(2).max(6),
    addend: z.number().int().min(1).max(10),
    startWithAddition: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 6);

    const addend = randomIntegerInclusive(1, 10);

    const startWithAddition = getRandomBoolean();

    return { multiplier, addend, startWithAddition };
  },
  Component: props => {
    const {
      question: { multiplier, addend, startWithAddition },
      translate,
      displayMode
    } = props;

    const answerA = startWithAddition ? (1 + addend) * multiplier : 1 * multiplier + addend;

    const answerB = startWithAddition ? (2 + addend) * multiplier : 2 * multiplier + addend;

    const answerC = startWithAddition ? (3 + addend) * multiplier : 3 * multiplier + addend;

    const functionMachineRow = startWithAddition
      ? [
          translate.misc.Input(),
          `${ADD} ${addend.toLocaleString()}`,
          `${MULT} ${multiplier.toLocaleString()}`,
          translate.misc.Output()
        ]
      : [
          translate.misc.Input(),
          `${MULT} ${multiplier.toLocaleString()}`,
          `${ADD} ${addend.toLocaleString()}`,
          translate.misc.Output()
        ];

    return (
      <QF3Content
        title={translate.instructions.useFunctionMachineToCompleteTable()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-between' }}>
            <FunctionMachinesInputWithState
              dimens={{
                width: dimens.width,
                height: dimens.height / 2
              }}
              rowsOfBoxes={[functionMachineRow]}
              hideLabels
              id={'functionMachine'}
            />
            <CustomizableTableWithState
              id="table"
              inputMaxCharacters={2}
              defaultState={
                displayMode === 'markscheme'
                  ? [answerA.toLocaleString(), answerB.toLocaleString(), answerC.toLocaleString()]
                  : ['', '', '']
              }
              tableData={[
                [
                  translate.misc.Input(),
                  (1).toLocaleString(),
                  (2).toLocaleString(),
                  (3).toLocaleString()
                ],
                [translate.misc.Output(), '<ans/>', '<ans/>', '<ans/>']
              ]}
              testCorrect={userAnswer =>
                userAnswer[0] === answerA.toString() &&
                userAnswer[1] === answerB.toString() &&
                userAnswer[2] === answerC.toString()
              }
              tableStyle={{ width: dimens.width - 52 }}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aTS',
  description: 'aTS',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z.object({
    multiplyOrDivide: z.enum([MULT, DIV]),
    addOrSubtract: z.enum([ADD, SUB]),
    multiplyOrDivideFirst: z.boolean(),
    addendOrSubtrahend: z.number().int().min(1).max(10),
    multiplierOrDivisor: z.number().int().min(2).max(6),
    numberA: z.number().int().min(0).max(22),
    numberB: z.number().int().min(0).max(22)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const multiplyOrDivide = getRandomFromArray([MULT, DIV] as const);

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const multiplyOrDivideFirst = getRandomBoolean();

    const addendOrSubtrahend = randomIntegerInclusive(1, 10);

    const multiplierOrDivisor = randomIntegerInclusive(2, 6);

    const [numberA, numberB] = (() => {
      // The following if statements are written as such below to try and make them a bit more readable:
      if (multiplyOrDivideFirst) {
        if (multiplyOrDivide === MULT) {
          // x then + and x then -
          return randomUniqueIntegersInclusive(1, 10, 2);
        } else if (multiplyOrDivide === DIV) {
          // ÷ then +
          if (addOrSubtract === ADD) {
            return randomUniqueIntegersInclusive(
              addendOrSubtrahend + 1,
              addendOrSubtrahend + 10,
              2
            );
            // ÷ then -
          } else if (addOrSubtract === SUB) {
            return randomUniqueIntegersInclusive(1, 12 - addendOrSubtrahend, 2);
          }
        }
      } else {
        if (multiplyOrDivide === MULT) {
          // + then x
          if (addOrSubtract === ADD) {
            return randomUniqueIntegersInclusive(0, 12 - addendOrSubtrahend, 2);
          } else if (addOrSubtract === SUB) {
            // - then x
            return randomUniqueIntegersInclusive(addendOrSubtrahend, 12 + addendOrSubtrahend, 2);
          }
        } else if (multiplyOrDivide === DIV) {
          // + then ÷
          if (addOrSubtract === ADD) {
            return randomUniqueIntegersInclusive(1, 6, 2);
            // - then ÷
          } else if (addOrSubtract === SUB) {
            return randomUniqueIntegersInclusive(1, 10, 2);
          }
        }
      }
    })() as number[];

    return {
      multiplyOrDivide,
      addOrSubtract,
      multiplyOrDivideFirst,
      addendOrSubtrahend,
      multiplierOrDivisor,
      numberA,
      numberB
    };
  },
  Component: props => {
    const {
      question: {
        multiplyOrDivide,
        addOrSubtract,
        multiplyOrDivideFirst,
        addendOrSubtrahend,
        multiplierOrDivisor,
        numberA,
        numberB
      },
      translate
    } = props;

    const [inputNumberA, outputNumberA, inputNumberB, outputNumberB] = (() => {
      if (multiplyOrDivideFirst) {
        if (multiplyOrDivide === MULT) {
          if (addOrSubtract === ADD) {
            // x then +
            return [
              numberA,
              numberA * multiplierOrDivisor + addendOrSubtrahend,
              numberB,
              numberB * multiplierOrDivisor + addendOrSubtrahend
            ];
          } else if (addOrSubtract === SUB) {
            // x then +
            return [
              numberA,
              numberA * multiplierOrDivisor - addendOrSubtrahend,
              numberB,
              numberB * multiplierOrDivisor - addendOrSubtrahend
            ];
          }
        } else if (multiplyOrDivide === DIV) {
          // ÷ then +
          if (addOrSubtract === ADD) {
            return [
              (numberA - addendOrSubtrahend) * multiplierOrDivisor,
              numberA,
              (numberB - addendOrSubtrahend) * multiplierOrDivisor,
              numberB
            ];
            // ÷ then -
          } else if (addOrSubtract === SUB) {
            return [
              (numberA + addendOrSubtrahend) * multiplierOrDivisor,
              numberA,
              (numberB + addendOrSubtrahend) * multiplierOrDivisor,
              numberB
            ];
          }
        }
      } else {
        if (multiplyOrDivide === MULT) {
          // + then x
          if (addOrSubtract === ADD) {
            return [
              numberA,
              (numberA + addendOrSubtrahend) * multiplierOrDivisor,
              numberB,
              (numberB + addendOrSubtrahend) * multiplierOrDivisor
            ];
          } else if (addOrSubtract === SUB) {
            // - then x
            return [
              numberA,
              (numberA - addendOrSubtrahend) * multiplierOrDivisor,
              numberB,
              (numberB - addendOrSubtrahend) * multiplierOrDivisor
            ];
          }
        } else if (multiplyOrDivide === DIV) {
          // + then ÷
          if (addOrSubtract === ADD) {
            return [
              numberA * multiplierOrDivisor - addendOrSubtrahend,
              numberA,
              numberB * multiplierOrDivisor - addendOrSubtrahend,
              numberB
            ];
            // - then ÷
          } else if (addOrSubtract === SUB) {
            return [
              numberA * multiplierOrDivisor + addendOrSubtrahend,
              numberA,
              numberB * multiplierOrDivisor + addendOrSubtrahend,
              numberB
            ];
          }
        }
      }
    })() as number[];

    const rowsOfBoxes = multiplyOrDivideFirst
      ? [
          [parseToSUB(inputNumberA.toLocaleString()), '', '', '<ans/>'],
          [
            '<ans/>',
            `${multiplyOrDivide} ${multiplierOrDivisor.toLocaleString()}`,
            `${addOrSubtract} ${addendOrSubtrahend}`,
            parseToSUB(outputNumberB.toLocaleString())
          ]
        ]
      : [
          [parseToSUB(inputNumberA.toLocaleString()), '', '', '<ans/>'],
          [
            '<ans/>',
            `${addOrSubtract} ${addendOrSubtrahend}`,
            `${multiplyOrDivide} ${multiplierOrDivisor.toLocaleString()}`,
            parseToSUB(outputNumberB.toLocaleString())
          ]
        ];

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutMissingOutputAndInput()}
        extraSymbol="minus"
        actionPanelVariant="endWide"
        rowsOfBoxes={rowsOfBoxes}
        testCorrect={[
          [parseToSUB(outputNumberA.toString())],
          [parseToSUB(inputNumberB.toString())]
        ]}
        sharedColumns={[1, 2]}
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aTT',
  description: 'aTT',
  keywords: ['Function machine'],
  schema: z
    .object({
      operationA: z.enum([ADD, SUB, MULT, DIV]),
      operationBSameAsA: z.boolean(),
      number1Factor: z.number().int().min(2).max(10),
      number2: z.number().int().min(1).max(6)
    })
    .refine(
      val => val.number1Factor * val.number2 <= 21,
      `number1Factor ${MULT} number must be less than or equal to 21`
    ),
  simpleGenerator: () => {
    const operationA = getRandomFromArray([ADD, SUB, MULT, DIV] as const);

    const operationBSameAsA = getRandomBoolean();

    const number2 = randomIntegerInclusive(2, 6);

    // If number2 is 2, number1Factor cannot also be 2 otherwise two cards will end up being 2
    const number1Factor = randomIntegerInclusive(number2 === 2 ? 3 : 2, 10, {
      constraint: x => x * number2 <= 21
    });

    return { operationA, operationBSameAsA, number1Factor, number2 };
  },
  Component: props => {
    const {
      question: { operationA, operationBSameAsA, number1Factor, number2 },
      translate
    } = props;

    const operationB = (() => {
      if (operationBSameAsA) {
        return operationA;
      } else {
        switch (operationA) {
          case ADD:
            return SUB;
          case SUB:
            return ADD;
          case MULT:
            return DIV;
          case DIV:
            return MULT;
        }
      }
    })();

    const number1 = number1Factor * number2;

    const number4 = number1 + number2;
    const number5 = number1 - number2;
    const number6 = number1 * number2;
    const number7 = number1 / number2;

    const [correctOperation, correctNumber] = (() => {
      if (operationA === ADD && operationB === SUB) {
        return [ADD, number5];
      }
      if (operationA === ADD && operationB === ADD) {
        return [ADD, number4];
      }

      if (operationA === SUB && operationB === SUB) {
        return [SUB, number4];
      }
      if (operationA === SUB && operationB === ADD) {
        return [SUB, number5];
      }
      if (operationA === MULT && operationB === MULT) {
        return [MULT, number6];
      }
      if (operationA === DIV && operationB === DIV) {
        return [DIV, number6];
      }
      if (operationA === MULT && operationB === DIV) {
        return [MULT, number7];
      } else {
        return [DIV, number7];
      }
    })();

    const shuffledNumbers = shuffle(
      [
        number4.toLocaleString(),
        number5.toLocaleString(),
        number6.toLocaleString(),
        number7.toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF28aFunctionMachinesDraggable
        title={translate.instructions.dragCardsToMakeASingleStepThatWouldGiveSameOutput()}
        pdfTitle={translate.instructions.useCardsToMakeASingleStepThatWouldGiveSameOutput()}
        questionHeight={1100}
        rowsOfBoxes={[
          [
            '',
            `${operationA} ${number1.toLocaleString()}`,
            `${operationB} ${number2.toLocaleString()}`,
            ''
          ],
          ['', '<ans/> <ans/>', '']
        ]}
        testCorrect={[correctOperation, correctNumber.toLocaleString()]}
        items={[ADD, SUB, MULT, DIV, ...shuffledNumbers]}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aTU',
  description: 'aTU',
  keywords: ['Table', 'Function machine', 'Input', 'Output'],
  schema: z.object({
    multiplyOrDivide: z.enum([MULT, DIV]),
    addOrSubtract: z.enum([ADD, SUB]),
    multiplyOrDivideFirst: z.boolean(),
    addendOrSubtrahend: z.number().int().min(1).max(10),
    multiplierOrDivisor: z.number().int().min(2).max(6),
    numberA: z.number().int().min(0).max(22),
    numberB: z.number().int().min(0).max(22),
    numberC: z.number().int().min(0).max(22)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const multiplyOrDivide = getRandomFromArray([MULT, DIV] as const);

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const multiplyOrDivideFirst = getRandomBoolean();

    const addendOrSubtrahend = randomIntegerInclusive(1, 10);

    const multiplierOrDivisor = randomIntegerInclusive(2, 6);

    const [numberA, numberB, numberC] = (() => {
      // The following if statements are written as such below to try and make them a bit more readable:
      if (multiplyOrDivideFirst) {
        if (multiplyOrDivide === MULT) {
          // x then + and x then -
          return randomUniqueIntegersInclusive(1, 10, 3);
        } else if (multiplyOrDivide === DIV) {
          // ÷ then +
          if (addOrSubtract === ADD) {
            return randomUniqueIntegersInclusive(
              addendOrSubtrahend + 1,
              addendOrSubtrahend + 10,
              3
            );
            // ÷ then -
          } else if (addOrSubtract === SUB) {
            return randomUniqueIntegersInclusive(0, 12 - addendOrSubtrahend, 3);
          }
        }
      } else {
        if (multiplyOrDivide === MULT) {
          // + then x
          if (addOrSubtract === ADD) {
            return randomUniqueIntegersInclusive(0, 12 - addendOrSubtrahend, 3);
          } else if (addOrSubtract === SUB) {
            // - then x
            return randomUniqueIntegersInclusive(addendOrSubtrahend, 12 + addendOrSubtrahend, 3);
          }
        } else if (multiplyOrDivide === DIV) {
          // + then ÷
          if (addOrSubtract === ADD) {
            return randomUniqueIntegersInclusive(1, 6, 3);
            // - then ÷
          } else if (addOrSubtract === SUB) {
            return randomUniqueIntegersInclusive(1, 10, 3);
          }
        }
      }
    })() as number[];

    return {
      multiplyOrDivide,
      addOrSubtract,
      multiplyOrDivideFirst,
      addendOrSubtrahend,
      multiplierOrDivisor,
      numberA,
      numberB,
      numberC
    };
  },
  Component: props => {
    const {
      question: {
        multiplyOrDivide,
        addOrSubtract,
        multiplyOrDivideFirst,
        addendOrSubtrahend,
        multiplierOrDivisor,
        numberA,
        numberB,
        numberC
      },
      translate,
      displayMode
    } = props;

    const [inputNumberA, outputNumberA, inputNumberB, outputNumberB, inputNumberC, outputNumberC] =
      (() => {
        if (multiplyOrDivideFirst) {
          if (multiplyOrDivide === MULT) {
            if (addOrSubtract === ADD) {
              // x then +
              return [
                numberA,
                numberA * multiplierOrDivisor + addendOrSubtrahend,
                numberB,
                numberB * multiplierOrDivisor + addendOrSubtrahend,
                numberC,
                numberC * multiplierOrDivisor + addendOrSubtrahend
              ];
            } else if (addOrSubtract === SUB) {
              // x then +
              return [
                numberA,
                numberA * multiplierOrDivisor - addendOrSubtrahend,
                numberB,
                numberB * multiplierOrDivisor - addendOrSubtrahend,
                numberC,
                numberC * multiplierOrDivisor - addendOrSubtrahend
              ];
            }
          } else if (multiplyOrDivide === DIV) {
            // ÷ then +
            if (addOrSubtract === ADD) {
              return [
                (numberA - addendOrSubtrahend) * multiplierOrDivisor,
                numberA,
                (numberB - addendOrSubtrahend) * multiplierOrDivisor,
                numberB,
                (numberC - addendOrSubtrahend) * multiplierOrDivisor,
                numberC
              ];
              // ÷ then -
            } else if (addOrSubtract === SUB) {
              return [
                (numberA + addendOrSubtrahend) * multiplierOrDivisor,
                numberA,
                (numberB + addendOrSubtrahend) * multiplierOrDivisor,
                numberB,
                (numberC + addendOrSubtrahend) * multiplierOrDivisor,
                numberC
              ];
            }
          }
        } else {
          if (multiplyOrDivide === MULT) {
            // + then x
            if (addOrSubtract === ADD) {
              return [
                numberA,
                (numberA + addendOrSubtrahend) * multiplierOrDivisor,
                numberB,
                (numberB + addendOrSubtrahend) * multiplierOrDivisor,
                numberC,
                (numberC + addendOrSubtrahend) * multiplierOrDivisor
              ];
            } else if (addOrSubtract === SUB) {
              // - then x
              return [
                numberA,
                (numberA - addendOrSubtrahend) * multiplierOrDivisor,
                numberB,
                (numberB - addendOrSubtrahend) * multiplierOrDivisor,
                numberC,
                (numberC - addendOrSubtrahend) * multiplierOrDivisor
              ];
            }
          } else if (multiplyOrDivide === DIV) {
            // + then ÷
            if (addOrSubtract === ADD) {
              return [
                numberA * multiplierOrDivisor - addendOrSubtrahend,
                numberA,
                numberB * multiplierOrDivisor - addendOrSubtrahend,
                numberB,
                numberC * multiplierOrDivisor - addendOrSubtrahend,
                numberC
              ];
              // - then ÷
            } else if (addOrSubtract === SUB) {
              return [
                numberA * multiplierOrDivisor + addendOrSubtrahend,
                numberA,
                numberB * multiplierOrDivisor + addendOrSubtrahend,
                numberB,
                numberC * multiplierOrDivisor + addendOrSubtrahend,
                numberC
              ];
            }
          }
        }
      })() as number[];

    const functionMachineRow = multiplyOrDivideFirst
      ? [
          '',
          `${multiplyOrDivide} ${multiplierOrDivisor.toLocaleString()}`,
          `${addOrSubtract} ${addendOrSubtrahend.toLocaleString()}`,
          ''
        ]
      : [
          '',
          `${addOrSubtract} ${addendOrSubtrahend.toLocaleString()}`,
          `${multiplyOrDivide} ${multiplierOrDivisor.toLocaleString()}`,
          ''
        ];

    return (
      <QF3Content
        title={translate.instructions.useFunctionMachineToCompleteTable()}
        inputType="numpad"
        extraSymbol="minus"
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-between' }}>
            <FunctionMachinesInputWithState
              dimens={{
                width: dimens.width,
                height: dimens.height / 2
              }}
              rowsOfBoxes={[functionMachineRow]}
              id={'functionMachine'}
            />
            <CustomizableTableWithState
              id="table"
              inputMaxCharacters={2}
              defaultState={
                displayMode === 'markscheme'
                  ? [
                      parseToSUB(inputNumberC.toLocaleString()),
                      parseToSUB(outputNumberA.toLocaleString()),
                      parseToSUB(outputNumberB.toLocaleString())
                    ]
                  : ['', '', '']
              }
              tableData={[
                [
                  translate.misc.Input(),
                  parseToSUB(inputNumberA.toLocaleString()),
                  parseToSUB(inputNumberB.toLocaleString()),
                  '<ans/>'
                ],
                [
                  translate.misc.Output(),
                  '<ans/>',
                  '<ans/>',
                  parseToSUB(outputNumberC.toLocaleString())
                ]
              ]}
              testCorrect={userAnswer =>
                userAnswer[0] === parseToSUB(inputNumberC.toString()) &&
                userAnswer[1] === parseToSUB(outputNumberA.toString()) &&
                userAnswer[2] === parseToSUB(outputNumberB.toString())
              }
              tableStyle={{ width: dimens.width - 52 }}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aTV',
  description: 'aTV',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z.object({
    multiplyOrDivide: z.enum([MULT, DIV]),
    multiplyOrDivideFirst: z.boolean(),
    multiplierOrDivisor: z.number().int().min(2).max(10),
    constant: z.number().int().min(2).max(4),
    number4: z.number().int().min(2).max(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const multiplyOrDivide = getRandomFromArray([MULT, DIV] as const);

    const multiplyOrDivideFirst = getRandomBoolean();

    const multiplierOrDivisor = randomIntegerInclusive(2, 10);

    const constant = randomIntegerInclusive(2, 4);

    const number4 = randomIntegerInclusive(2, 10);

    return {
      multiplyOrDivide,
      multiplyOrDivideFirst,
      multiplierOrDivisor,
      constant,
      number4
    };
  },
  Component: props => {
    const {
      question: { multiplyOrDivide, multiplyOrDivideFirst, multiplierOrDivisor, constant, number4 },
      translate
    } = props;

    const addOrSubtract = multiplyOrDivide === MULT ? SUB : ADD;

    const [number4ToUse, addendOrSubtrahend] = (() => {
      if (multiplyOrDivideFirst) {
        if (multiplyOrDivide === MULT) {
          // x then -
          const number4ToUse = number4;

          const addendOrSubtrahend = number4ToUse * (1 - multiplierOrDivisor);

          return [number4ToUse, addendOrSubtrahend];
        } else {
          // ÷ then +
          const number4ToUse = constant * multiplierOrDivisor;

          const addendOrSubtrahend = number4ToUse - constant;

          return [number4ToUse, addendOrSubtrahend];
        }
      } else {
        if (multiplyOrDivide === MULT) {
          // - then x
          const number4ToUse = constant * multiplierOrDivisor;

          const addendOrSubtrahend = constant * (1 - multiplierOrDivisor);

          return [number4ToUse, addendOrSubtrahend];
        } else {
          // + then ÷
          const number4ToUse = number4;

          const addendOrSubtrahend = number4ToUse * (1 - multiplierOrDivisor);

          return [number4ToUse, addendOrSubtrahend];
        }
      }
    })();

    const functionMachineRow = multiplyOrDivideFirst
      ? [
          '',
          `${multiplyOrDivide} ${multiplierOrDivisor.toLocaleString()}`,
          // Need absolute value to prevent minus sign showing twice:
          `${addOrSubtract} ${Math.abs(addendOrSubtrahend).toLocaleString()}`,
          ''
        ]
      : [
          '',
          `${addOrSubtract} ${Math.abs(addendOrSubtrahend).toLocaleString()}`,
          // Need absolute value to prevent minus sign showing twice:
          `${multiplyOrDivide} ${multiplierOrDivisor.toLocaleString()}`,
          ''
        ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theOutputIsEqualToInputFindInput()}
        sentence={translate.answerSentences.inputEqualsAns()}
        testCorrect={[number4ToUse.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <FunctionMachinesInputWithState
            dimens={{
              width: dimens.width,
              height: dimens.height
            }}
            rowsOfBoxes={[functionMachineRow]}
            id={'functionMachine'}
          />
        )}
        questionHeight={1000}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aTV2',
  description: 'aTV2',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z
    .object({
      operation1: z.enum([MULT, ADD]),
      operation2: z.enum([DIV, SUB, ADD, MULT]),
      number1: z.number().int().min(3).max(12),
      number2: z.number().int().min(2).max(11),
      incorrect1: z.number().int().min(2).max(20),
      incorrectOperations: z.enum([DIV, SUB, ADD, MULT]).array()
    })
    .refine(val => val.number2 < val.number1, 'number1 must be greater than number2')
    .refine(
      val =>
        val.operation1 === MULT && val.operation2 === DIV ? val.number1 % val.number2 === 0 : true,
      'when operation1 is multiply and operation2 is divide, number2 must be a factor of number1'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const operation1 = getRandomFromArray([MULT, ADD] as const);
    const operation2 =
      operation1 === ADD
        ? getRandomFromArray([SUB, ADD] as const)
        : getRandomFromArray([MULT, DIV] as const);

    const number1 =
      operation1 === MULT && operation2 === DIV
        ? randomIntegerInclusive(4, 12, {
            constraint: x =>
              (x % 3 === 0 && x / 3 >= 2) ||
              (x % 2 === 0 && x / 2 >= 2) ||
              (x % 5 === 0 && x / 5 >= 2)
          })
        : randomIntegerInclusive(3, 12);

    const number2 = randomIntegerInclusive(2, number1 - 1, {
      constraint: x => (operation1 === MULT && operation2 === DIV ? number1 % x === 0 : true)
    });

    const incorrect1 = randomIntegerInclusive(2, 20, {
      constraint: x => ![number1 + number2, number1 - number2, number1 * number2].includes(x)
    });

    const incorrectOperations =
      operation2 === SUB
        ? [getRandomFromArray([ADD, SUB] as const)]
        : countRange(3).map(() => getRandomFromArray([MULT, DIV] as const));

    return {
      operation1,
      operation2,
      number1,
      number2,
      incorrect1,
      incorrectOperations
    };
  },
  Component: props => {
    const {
      question: { operation1, operation2, number1, number2, incorrect1, incorrectOperations },
      translate
    } = props;

    const options = (() => {
      if (operation1 === ADD) {
        if (operation2 === ADD) {
          return [
            { value: 'A', text: `${ADD} ${(number1 + number2).toLocaleString()}`, isCorrect: true },
            {
              value: 'B',
              text: `${ADD} ${(number1 - number2).toLocaleString()}`,
              isCorrect: false
            },
            {
              value: 'C',
              text: `${ADD} ${(number1 * number2).toLocaleString()}`,
              isCorrect: false
            },
            { value: 'D', text: `${ADD} ${incorrect1.toLocaleString()}`, isCorrect: false }
          ];
        } else {
          return [
            { value: 'A', text: `${ADD} ${(number1 - number2).toLocaleString()}`, isCorrect: true },
            {
              value: 'B',
              text: `${ADD} ${(number1 + number2).toLocaleString()}`,
              isCorrect: false
            },
            {
              value: 'C',
              text: `${ADD} ${(number1 * number2).toLocaleString()}`,
              isCorrect: false
            },
            {
              value: 'D',
              text: `${incorrectOperations[0]} ${incorrect1.toLocaleString()}`,
              isCorrect: false
            }
          ];
        }
      } else {
        if (operation2 === MULT) {
          return [
            {
              value: 'A',
              text: `${MULT} ${(number1 * number2).toLocaleString()}`,
              isCorrect: true
            },
            {
              value: 'B',
              text: `${MULT} ${(number1 + number2).toLocaleString()}`,
              isCorrect: false
            },
            {
              value: 'C',
              text: `${MULT} ${(number1 - number2).toLocaleString()}`,
              isCorrect: false
            },
            {
              value: 'D',
              text: `${MULT} ${incorrect1.toLocaleString()}`,
              isCorrect: false
            }
          ];
        } else {
          return [
            {
              value: 'A',
              text: `${MULT} ${(number1 / number2).toLocaleString()}`,
              isCorrect: true
            },
            {
              value: 'B',
              text: `${incorrectOperations[0]} ${(number1 + number2).toLocaleString()}`,
              isCorrect: false
            },
            {
              value: 'C',
              text: `${incorrectOperations[1]} ${(number1 - number2).toLocaleString()}`,
              isCorrect: false
            },
            {
              value: 'D',
              text: `${incorrectOperations[2]} ${(number1 * number2).toLocaleString()}`,
              isCorrect: false
            }
          ];
        }
      }
    })();

    const shuffledOptions = shuffle(options, { random: seededRandom(props.question) });

    const functionMachineRow = [
      '',
      `${operation1} ${number1.toLocaleString()}`,
      `${operation2} ${number2.toLocaleString()}`,
      ''
    ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheSingleStepThatGivesOutput()}
        pdfTitle={translate.instructions.circleTheSingleStepThatGivesOutput()}
        testCorrect={shuffledOptions.filter(val => val.isCorrect).map(val => val.value)}
        Content={({ dimens }) => (
          <FunctionMachinesInputWithState
            dimens={{
              width: dimens.width,
              height: dimens.height
            }}
            rowsOfBoxes={[functionMachineRow]}
            id={'functionMachine'}
          />
        )}
        questionHeight={1000}
        numItems={4}
        renderItems={shuffledOptions.map(statement => ({
          value: statement.value,
          component: <Text variant="WRN700">{statement.text}</Text>
        }))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Num2StepFunctionMachines',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
