import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import {
  PartWholeModel,
  PartWholeModelWithState
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { isEqual, isInRange } from '../../../../utils/matchers';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baW',
  description: 'baW',
  keywords: ['Part-whole model', 'Add', 'Counters'],
  questionHeight: 1000,
  schema: z
    .object({
      partA: z.number().int().min(1).max(6),
      partB: z.number().int().min(1).max(6),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft']),
      color: z.enum(['red', 'yellow', 'blue', 'green'])
    })
    .refine(
      ({ partA, partB }) => isInRange(2, 10)(partA + partB),
      'total should be between 2 and 10'
    ),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(1, 6);
    const partB = randomIntegerInclusive(1, 6, { constraint: x => isInRange(2, 10)(partA + x) });

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );
    const color = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);
    return { partA, partB, variation, color };
  },
  Component: ({ question, translate, displayMode }) => {
    const { partA, partB, variation, color } = question;

    const whole = partA + partB;

    return (
      <QF3Content
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.whatIsTheWholeFillInTheNumber()}
        pdfTitle={translate.ks1PDFInstructions.whatIsTheWholeWriteTheNumber()}
        Content={({ dimens }) => (
          <PartWholeModelWithState
            id="part-whole-model"
            top={'$ans'}
            variation={variation}
            color={color}
            partition={[partA, partB]}
            representation="counters"
            isInteractive
            dimens={{
              height: dimens.height,
              width:
                variation === 'leftRight' || variation === 'rightLeft'
                  ? dimens.width * 0.7
                  : dimens.width * 0.9
            }}
            testCorrect={isEqual([whole.toString()])}
            defaultState={displayMode === 'markscheme' ? [whole.toLocaleString()] : undefined}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'baX',
  description: 'baX',
  keywords: ['Part-whole model', 'Add'],
  questionHeight: 1000,
  schema: z
    .object({
      partA: z.number().int().min(0).max(10),
      partB: z.number().int().min(0).max(10),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft'])
    })
    .refine(
      ({ partA, partB }) => isInRange(2, 10)(partA + partB),
      'total should be between 2 and 10'
    ),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(0, 10);
    const partB = randomIntegerInclusive(0, 10, { constraint: x => isInRange(2, 10)(partA + x) });

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { partA, partB, variation };
  },
  Component: ({ question, translate }) => {
    const { partA, partB, variation } = question;

    const whole = partA + partB;

    return (
      <QF3InteractiveContent
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.completeThePartWholeModel()}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={'$ans'}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            variation={variation}
            partition={[partA, partB]}
            isInteractive
            dimens={{
              height: dimens.height,
              width:
                variation === 'leftRight' || variation === 'rightLeft'
                  ? dimens.width * 0.7
                  : dimens.width * 0.9
            }}
          />
        )}
        initialState={['']}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        testCorrect={isEqual([whole.toString()])}
        customMarkSchemeAnswer={{
          answersToDisplay: [whole.toLocaleString()]
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'baY',
  description: 'baY',
  keywords: ['Part-whole model', 'Add', 'Parts'],
  questionHeight: 1000,
  schema: z.object({
    whole: z.number().int().min(3).max(10),
    variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft'])
  }),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(3, 10);

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { whole, variation };
  },
  Component: ({ question, translate }) => {
    const { whole, variation } = question;

    return (
      <QF3InteractiveContent
        inputType="numpad"
        questionHeight={1200}
        title={`${translate.ks1Instructions.wholeIsX(
          whole
        )}<br/>${translate.ks1Instructions.completeThePartWholeModel()}`}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={whole}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            variation={variation}
            partition={['$ans', '$ans']}
            isInteractive
            dimens={{
              height: dimens.height,
              width:
                variation === 'leftRight' || variation === 'rightLeft'
                  ? dimens.width * 0.7
                  : dimens.width * 0.9
            }}
          />
        )}
        initialState={['', '']}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        testCorrect={userAnswer => {
          const [ansA, ansB] = userAnswer.map(Number);
          return ansA + ansB === whole;
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [whole.toLocaleString(), (0).toLocaleString()],
          answerText: translate.markScheme.acceptAnyCombinationThatAddUpToX(whole.toLocaleString())
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartWholeModel',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
