import { ComponentProps, useContext, useEffect, useId, useMemo, useRef } from 'react';
import { useCallback } from 'react';
import { UserInputEvent, UserInputReceiver, useUserInputStore } from '../molecules/UserInput';
import Text from '../typography/Text';
import { StyleProp, TextStyle, StyleSheet, Pressable, Platform } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming
} from 'react-native-reanimated';
import { ScaleFactorContext } from '../../theme/scaling';
import { FontVariantKey, useTheme } from '../../theme';
import { noop } from '../../utils/flowControl';
import { DisplayMode } from '../../contexts/displayMode';
import { withStateHOC } from '../../stateTree';
import { deleteLastCodePoint } from '../../utils/strings';

const DEFAULT_FONT_SIZE = 40;
const DEFAULT_PDF_FONT_SIZE = 50;

type Props = Omit<ComponentProps<typeof Text>, 'children' | 'style' | 'variant'> & {
  value: string;
  onChangeText?: (text: string) => void;
  singleCharacterMode?: boolean;
  style?: StyleProp<TextStyle>;
  selectedStyle?: StyleProp<TextStyle>;
  // TODO: remove concept of autoFocus from app.
  // autoFocus doesn't make sense from a UX persepective without some way to automatically move to the next focusable
  // input, which our text inputs do not have.
  /** Unused! Whether the auto-focus the first text input. Default: false. */
  autoFocus?: boolean;
  /** The max number of characters expected to go in this input. Simply affects the width of the input. */
  maxCharacters?: number;
  fontVariant?: FontVariantKey;
};

/**
 * Custom TextInput, which uses our on-screen input methods (e.g. numpad), not the device keyboard.
 *
 * This inherits all functionality from {@link Text}.
 *
 * This component has the following additional functionality:
 * - Like TextInput, it displays text `value` and calls a callback `onChangeText` when that text is changed.
 * - Within a `UserInputContext`, at most one TextInput can be selected.
 * - Can be selected by clicking on it.
 * - When selected, a blinking cursor appears at the end of the text stream. This cursor is always at the end.
 *   - New letters and deletions work from the end of the text
 * - Can be selected and cleared by long-pressing on it
 * - Can be told single digit mode. This causes extra digit inputs to replace the first digit.
 * - Overflow text is indicated with an ellipsis at the beginning, so the characters that are being edited are visible.
 *   - However, in general we should avoid the user needing to see this if they use the app properly.
 *
 * This component has the following limitations:
 * - Unlike TextInput, we don't support uncontrolled usage (since we never use that).
 * - There's no concept of text selection (i.e. selecting sub-strings of the text), and user cannot copy/paste
 * - Doesn't use the native focus system to indicate which text box will be affected by our on-screen input methods
 *   - Instead, the selected input method is indicated by an extra outline and blinking cursor after the last character
 * - Doesn't work with on-screen keyboard
 */
export default function NoKeyboardTextInput({
  value,
  onChangeText = noop,
  style,
  singleCharacterMode = false,
  maxCharacters,
  selectedStyle,
  fontVariant = 'WRN700',
  ...props
}: Props) {
  const id = useId();
  const selectedInputReceiver = useUserInputStore(state => state.selectedInputReceiver);
  const selectInputReceiver = useUserInputStore(state => state.selectInputReceiver);
  const registerInputReceiver = useUserInputStore(state => state.registerInputReceiver);
  const isSelected = selectedInputReceiver === id;
  const displayMode = useContext(DisplayMode);

  // Handle incoming user input event.
  const onInputEvent = useRef<UserInputReceiver>(() => {
    /* do nothing */
  });
  onInputEvent.current = (event: UserInputEvent) => {
    if (event.inputType === 'numpad') {
      const old = value;

      // Modify the value depending on which event we received.
      if (event.button === 'del') {
        // Delete last character
        onChangeText(deleteLastCodePoint(old));
      } else {
        if (singleCharacterMode) {
          onChangeText(event.button);
        } else {
          onChangeText(old + event.button);
        }
      }
    }
  };
  const inputReceiver = useRef((event: UserInputEvent) => onInputEvent.current(event)).current;

  // Maintain our registration, updating our receiver when it changes.
  useEffect(() => {
    return registerInputReceiver(id, inputReceiver);
  }, [id, inputReceiver, onInputEvent, registerInputReceiver]);

  // Extract the text styles to put in the Text
  const flattenedStyle = StyleSheet.flatten(style);
  const textStyle: TextStyle = {};
  for (const prop in flattenedStyle) {
    if (textStylePropNames.includes(prop)) {
      (textStyle as Record<string, unknown>)[prop] = flattenedStyle[prop as keyof TextStyle];
    }
  }

  const select = useCallback(() => {
    selectInputReceiver(id);
  }, [id, selectInputReceiver]);
  const clearTextAndSelect = useCallback(() => {
    onChangeText('');
    selectInputReceiver(id);
  }, [id, onChangeText, selectInputReceiver]);

  const fontSize =
    textStyle?.fontSize ?? (displayMode === 'digital' ? DEFAULT_FONT_SIZE : DEFAULT_PDF_FONT_SIZE);
  const styles = useStyles(fontSize, displayMode, maxCharacters);

  return (
    <Pressable
      style={[
        styles.container,
        displayMode !== 'digital'
          ? styles.pdfBorder
          : isSelected
          ? [styles.selected, selectedStyle]
          : styles.unselected,
        style
      ]}
      testID="ANSWER_BOX"
      onPress={event => {
        // On web, if the user uses the tab key to put the Pressable in focus, pressing Enter will trigger this onPress callback
        // We actually want the Pressable to not handle that key press, and instead handle the Enter key at the Quiz.
        // The following code is a workaround: we emit a new Enter event, which should be picked up by the quiz.
        if (Platform.OS === 'web' && event instanceof KeyboardEvent) {
          if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            const event = new KeyboardEvent('keydown', {
              key: 'Enter',
              code: 'Enter',
              which: 13,
              keyCode: 13
            });

            window.dispatchEvent(event);
          }
        } else {
          select();
        }
      }}
      onLongPress={clearTextAndSelect}
    >
      <Text
        variant={fontVariant}
        style={[styles.text, textStyle]}
        numberOfLines={1}
        ellipsizeMode="head"
        {...props}
      >
        {value}
      </Text>
      {isSelected && <BlinkingCursor fontSize={fontSize} />}
    </Pressable>
  );
}

function BlinkingCursor({ fontSize }: { fontSize: number }) {
  const sharedOpacity = useSharedValue(1);
  useEffect(() => {
    sharedOpacity.value = withRepeat(withTiming(0, { duration: 500 }), -1, true);
  }, [sharedOpacity]);

  const opacityStyle = useAnimatedStyle(
    () => ({
      opacity: Math.round(sharedOpacity.value)
    }),
    [sharedOpacity]
  );

  const styles = useStyles(fontSize);

  return <Animated.View style={[{ height: fontSize }, styles.cursor, opacityStyle]} />;
}

const textStylePropNames = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'letterSpacing',
  'lineHeight',
  'textAlign',
  'textDecorationLine',
  'textDecorationStyle',
  'textDecorationColor',
  'textShadowColor',
  'textShadowOffset',
  'textShadowRadius',
  'textTransform'
];

function useStyles(
  fontSize: number,
  displayMode: 'digital' | 'pdf' | 'markscheme' = 'digital',
  maxCharacters?: number
) {
  const theme = useTheme();
  const minBorderWidth = 1 / useContext(ScaleFactorContext);

  let width = 96;
  if (maxCharacters !== undefined) {
    // As a heuristic, the average character uses about 70% of the letter em width.
    // We could use the full 100%, but that makes text input boxes much larger than they need to be, which negatively
    // affects the UI design.
    const characterWidth = 0.7 * fontSize;
    width = Math.max(width, characterWidth * maxCharacters);
  }

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width,
          minHeight: displayMode === 'digital' ? 96 : 150,
          minWidth: displayMode === 'digital' ? 96 : 200,
          borderColor: displayMode === 'digital' ? theme.colors.tertiary : theme.colors.pdfPrimary,
          backgroundColor:
            displayMode === 'digital' ? theme.colors.tertiaryContainer : theme.colors.background,
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center'
        },
        /**
         * Use `textAlign: 'right'` since it works best with `ellipsizeMode="head"` and avoids the tail of the string
         * from being cut off when ellipsizing.
         *
         * (Note that when the text string is short enough, the text fits in the Text component snugly so textAlign
         * makes no difference, and the Text is centered in its parent component.)
         */
        text: {
          color:
            displayMode === 'digital' ? theme.colors.onTertiaryContainer : theme.colors.pdfPrimary,
          maxWidth: '100%',
          // Hack (Android only): mitigate text cutoff on android by cutting off left dot of ellipsis, instead of right
          // side of final character.
          //
          // More information:
          //
          // Ideally, textAlign should not make any difference - it only makes a difference if the text is a different
          // width to the <Text> it sits in, but in our case if the text is short then the <Text> wraps it snugly, and
          // if the text is long, it should be ellipsized until it's short.
          //
          // However, on Android there's a bug here https://github.com/facebook/react-native/pull/37248 (fixed in
          // react-native 0.73.0) where, after ellipsizing, the text is still longer than the <Text> so something gets
          // cut off.
          //
          // With textAlign: 'right', we're choosing that the left size gets cut off here, which is the ellipsis.
          ...(Platform.OS === 'android' && { textAlign: 'right' })
        },
        pdfBorder: {
          borderStyle: 'solid',
          borderWidth: 2
        },
        selected: {
          borderStyle: 'solid',
          borderWidth: Math.max(minBorderWidth, 4),
          zIndex: 2
        },
        unselected: {
          // Required to prevent faint black lines from sporadically appearing next to unselected answer box borders:
          borderRadius: 0.1,
          borderStyle: 'dashed',
          borderWidth: Math.max(minBorderWidth, 3),
          zIndex: 1
        },
        cursor: {
          width: 0,
          borderStartWidth: Math.max(minBorderWidth, 2),
          borderColor:
            displayMode === 'digital' ? theme.colors.onTertiaryContainer : theme.colors.pdfPrimary
        }
      }),
    [
      width,
      displayMode,
      theme.colors.tertiary,
      theme.colors.pdfPrimary,
      theme.colors.tertiaryContainer,
      theme.colors.background,
      theme.colors.onTertiaryContainer,
      minBorderWidth
    ]
  );
}

export const NoKeyboardTextInputWithState = withStateHOC(NoKeyboardTextInput, {
  stateProp: 'value',
  setStateProp: 'onChangeText',
  defaults: { defaultState: '', testComplete: ans => ans !== '' }
});
