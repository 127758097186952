import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { aa2, aa4 } from '../../../Year 3/Autumn/PlaceValue/10NumberLineTo1000';
import { NonEmptyArray, filledArray, range } from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';

////
// Questions
////

const Question1 = { ...aa2, uid: 'abI', description: 'abI' as const };

export const Question2 = newQuestionContent({
  uid: 'abJ',
  description: 'abJ',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z.object({
    number: z.number().int().min(50).max(950)
  }),
  simpleGenerator: () => {
    const answerInterval = 50;
    const startingNumber = 0;
    const endNumber = 1000;
    const labelInterval = 500;

    // Make this miss the first, last and middle number
    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    ).filter(i => i !== labelInterval) as NonEmptyArray<number>;

    const number = getRandomFromArray(choices);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickInterval = 100;
    const labelInterval = 500;
    const startingNumber = 0;
    const endNumber = 1000;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number % labelInterval ? null : number;
    });

    const title =
      number % tickInterval === 0
        ? translate.instructions.whatNumberIsTheArrowPointingTo()
        : translate.instructions.estimateNumberArrowIsPointingTo();

    return (
      <QF17CompleteTheNumberLine
        title={title}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question3 = {
  ...aa4,
  uid: 'abK',
  description: 'abK' as const
};

const Question3v2 = newQuestionContent({
  uid: 'abK2',
  description: 'abK',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z.object({
    number: z.number().int().min(50).max(950).step(50)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(50, 950, 50);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 1000;
    const tickInterval = 100;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number.toLocaleString())}
        pdfTitle={translate.instructions.showPositionOfNumPdf(number.toLocaleString())}
        testCorrect={[number - 10, number + 10]}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={numberArray}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'abL',
  description: 'abL',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z.object({
    number: z.number().int().min(50).max(950),
    interval: z.union([z.literal(50), z.literal(200), z.literal(250)])
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray([50, 200, 250] as const);
    const startingNumber = 0;
    const endNumber = interval === 50 ? 250 : 1000;

    // Make this miss the first and last number
    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const number = getRandomFromArray(choices);

    return { number, interval };
  },
  Component: props => {
    const {
      question: { number, interval },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = interval === 50 ? 250 : 1000;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / interval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'abM',
  description: 'abM',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z.number().int().min(100).max(900),
      startingNumber: z.number().int().min(100).max(400),
      endNumber: z.number().int().min(600).max(900)
    })
    .refine(
      val => val.number >= val.startingNumber,
      val => ({ message: `All answers must be greater than ${val.startingNumber}` })
    )
    .refine(
      val => val.number <= val.endNumber,
      val => ({ message: `All answers must less than ${val.endNumber}` })
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 4) * 100;
    const endNumber = startingNumber + 500;
    const answerInterval = 50;

    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 100;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(number.toLocaleString())}
        pdfTitle={translate.instructions.showPositionOfNumPdf(number.toLocaleString())}
        testCorrect={[number - 5, number + 5]}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'abN',
  description: 'abN',
  keywords: ['Number line', 'Place value', '1,000', 'Thousand'],
  schema: z
    .object({
      number: z.number().int().min(101).max(999),
      startingNumber: z.number().int().min(101).max(990),
      endNumber: z.number().int().min(111).max(1000),
      interval: z.union([
        z.literal(1),
        z.literal(2),
        z.literal(5),
        z.literal(10),
        z.literal(20),
        z.literal(50)
      ])
    })
    .refine(
      val => val.number >= val.startingNumber + val.interval,
      val => ({
        message: `All answers must be greater than or equal to ${val.startingNumber + val.interval}`
      })
    )
    .refine(
      val => val.number <= val.endNumber - val.interval,
      val => ({ message: `All answers must less than or equal to ${val.endNumber - val.interval}` })
    ),
  simpleGenerator: () => {
    const interval = getRandomFromArray([1, 2, 5, 10, 20, 50] as const);
    const startingNumber = getRandomFromArray(
      range(Math.max(100 + interval, interval * 10), 1000 - 10 * interval, interval * 10)
    );
    const endNumber = startingNumber + 10 * interval;

    // Make this miss the first and last number
    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const number = getRandomFromArray(choices);

    return { number, startingNumber, endNumber, interval };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber, interval },
      translate
    } = props;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / interval + 1;
    const tickValues: (number | null)[] = filledArray(null, numTicks);

    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = endNumber;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const SmallStep = newSmallStepContent({
  smallStep: 'NumberLineTo1000',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});

export default SmallStep;
