import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF7aInteractiveTallyChart from '../../../../components/question/questionFormats/QF7aInteractiveTallyChart';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { z } from 'zod';
import { numberToTally } from '../../../../components/question/representations/Tallies';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import { View } from 'react-native';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { isInRange } from '../../../../utils/matchers';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const lowerCaseColors = [
  'red',
  'blue',
  'green',
  'orange',
  'pink',
  'yellow',
  'grey',
  'black',
  'white',
  'purple'
] as const;

const Question1 = newQuestionContent({
  uid: 'blc',
  description: 'blc',
  keywords: ['Tally'],
  questionHeight: 850,
  schema: z.object({
    correctNumbers: z.array(z.number().int().min(1).max(20)).length(3),
    items: z.array(z.number().int()).length(4),
    tallyVariation: z.boolean()
  }),
  simpleGenerator: () => {
    const correctNumbers = randomUniqueIntegersInclusive(1, 20, 3);
    const tallyVariation = getRandomBoolean();

    const { incorrectNumber } = rejectionSample(
      () => {
        const arrayOfPlus = correctNumbers.map(num => num + 1);
        const arrayOfMinus = correctNumbers.map(num => num - 1);

        const incorrectNumber = getRandomFromArray([...arrayOfPlus, ...arrayOfMinus]) as number;

        return { incorrectNumber };
      },
      ({ incorrectNumber }) =>
        arrayHasNoDuplicates([...correctNumbers, incorrectNumber]) &&
        // Ensure when adding/subtracting from incorrectNumber it remains within range
        isInRange(1, 20)(incorrectNumber)
    );

    const items = shuffle([...correctNumbers, incorrectNumber]);

    return { correctNumbers, incorrectNumber, items, tallyVariation };
  },
  Component: props => {
    const {
      question: { correctNumbers, items, tallyVariation },
      translate
    } = props;

    const itemsLength = items.filter(item => item > 15);
    // If tally variation or too many tallies to fit in draggables
    const isTallyVariation = tallyVariation || itemsLength.length > 0;

    const itemsVariation = isTallyVariation
      ? items
      : items.map(item => {
          return {
            value: item,
            component: numberToTally(item)
          };
        });

    const statements = correctNumbers.map(number => ({
      lhsComponent: (
        <View
          style={{
            flexDirection: 'row',
            width: isTallyVariation ? 400 : 60
          }}
        >
          {isTallyVariation ? numberToTally(number) : <Text variant="WRN400">{number}</Text>}
        </View>
      ),
      correctAnswer: number
    }));

    return (
      <QF6DragMatchStatements
        title={
          isTallyVariation
            ? translate.ks1Instructions.dragCardsToMatchTheTalliesToTheNumbers()
            : translate.ks1Instructions.dragCardsToMatchTheNumbersToTheTallies()
        }
        pdfTitle={
          isTallyVariation
            ? translate.ks1PDFInstructions.matchTheTalliesToTheNumbers()
            : translate.ks1PDFInstructions.matchTheNumbersToTheTallies()
        }
        items={itemsVariation}
        statementStyle={{ justifyContent: 'center' }}
        statements={statements}
        useRedLinesOnMarkScheme={false}
        questionHeight={850}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bld',
  description: 'bld',
  keywords: ['Tally'],
  schema: z.object({
    number1: z.number().int().min(1).max(20),
    number2: z.number().int().min(1).max(20),
    colors: z.array(z.enum(lowerCaseColors)).length(2).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(1, 20, 2);
    const colors = getRandomSubArrayFromArray(lowerCaseColors, 2);

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    // Table data
    const data = [
      [translate.colors[colors[0]](), `<ans/>`, number1.toLocaleString()],
      [translate.colors[colors[1]](), `<ans/>`, number2.toLocaleString()]
    ];

    return (
      <QF7aInteractiveTallyChart
        title={translate.ks1Instructions.dragCardsCompleteTallyChart()}
        pdfTitle={translate.ks1PDFInstructions.completeTallyChart()}
        moveOrCopy="copy"
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        tableData={data}
        testCorrect={[number1, number2]}
        items={[1, 2, 3, 4, 5]}
        columnFlexValues={[1, 3, 1]}
        capacity={4}
        droppableContainerStyles={{
          justifyContent: 'flex-start'
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ble',
  description: 'ble',
  keywords: ['Tally'],
  schema: z.object({
    number1: z.number().int().min(1).max(20),
    number2: z.number().int().min(1).max(20),
    colors: z.array(z.enum(lowerCaseColors)).length(2).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(1, 20, 2);
    const colors = getRandomSubArrayFromArray(lowerCaseColors, 2);

    return { number1, number2, colors };
  },
  Component: ({ question: { number1, number2, colors }, translate }) => {
    // Table data
    const data = [
      [translate.colors[colors[0]](), numberToTally(number1), '<ans/>'],
      [translate.colors[colors[1]](), numberToTally(number2), '<ans/>']
    ];

    return (
      <QF7InteractiveTable
        title={translate.ks1Instructions.completeTallyChart()}
        cellHeaders={[
          translate.tableHeaders.Colour(),
          translate.tableHeaders.Tally(),
          translate.keywords.Total()
        ]}
        columnFlexValues={[1, 3, 1]}
        tableData={data}
        tableCellStyle={{ justifyContent: 'flex-start' }}
        tableCellStyleIdx={[1]}
        testCorrect={[number1.toString(), number2.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeTallyCharts',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
