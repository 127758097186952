import { z } from 'zod';
import { SvgName } from '../../assets/svg';
import { getRandomFromArray } from '../random';
import {
  equilateralTriangles,
  isoscelesTrianglesNarrow,
  isoscelesTrianglesWide,
  scaleneTriangles
} from './triangles';

export const shapesWithPerpendicularOrParalelleLinesNames = [
  'RAtriangle',
  'triangle',
  'square',
  'rectangle',
  'housePentagon',
  'regularPentagon',
  'rectangle',
  'square',
  'hexagon',
  'trapezium',
  'RAtrapezium',
  'parallelogram'
] as const;

export type ShapesWithPendicularOrParallelLinesName =
  (typeof shapesWithPerpendicularOrParalelleLinesNames)[number];

export const shapesWithPendicularOrParallelLinesSchema = z.enum(
  shapesWithPerpendicularOrParalelleLinesNames
);

export const getShapesWithPerpendicularOrParallelLinesName = (
  shape: ShapesWithPendicularOrParallelLinesName
): SvgName => {
  switch (shape) {
    case 'RAtriangle':
      return getRandomFromArray([
        'Right_angled_triangles/triangle_RA_green',
        'Right_angled_triangles/triangle_RA_pink',
        'Right_angled_triangles/triangle_RA_purple',
        'Right_angled_triangles/triangle_RA_white',
        'Right_angled_triangles/triangle_RA_yellow'
      ] as const);
    case 'triangle':
      return getRandomFromArray([
        ...equilateralTriangles,
        ...isoscelesTrianglesNarrow,
        ...isoscelesTrianglesWide,
        ...scaleneTriangles
      ] as const);
    case 'square': {
      return getRandomFromArray([
        'Square/square_blue',
        'Square/square_green',
        'Square/square_grey',
        'Square/square_pink',
        'Square/square_purple',
        'Square/square_white',
        'Square/square_yellow'
      ] as const);
    }
    case 'rectangle': {
      return getRandomFromArray([
        'Rectangle/rectangle_blue',
        'Rectangle/rectangle_green',
        'Rectangle/rectangle_pink',
        'Rectangle/rectangle_purple',
        'Rectangle/rectangle_white',
        'Rectangle/rectangle_yellow'
      ] as const);
    }
    case 'housePentagon':
      return getRandomFromArray([
        'Pentagon_houses/pentagon_house_blue',
        'Pentagon_houses/pentagon_house_green',
        'Pentagon_houses/pentagon_house_pink',
        'Pentagon_houses/pentagon_house_purple',
        'Pentagon_houses/pentagon_house_white',
        'Pentagon_houses/pentagon_house_yellow'
      ] as const);
    case 'regularPentagon':
      return getRandomFromArray([
        'Pentagons/pentagon_blue',
        'Pentagons/pentagon_green',
        'Pentagons/pentagon_pink',
        'Pentagons/pentagon_purple',
        'Pentagons/pentagon_white',
        'Pentagons/pentagon_yellow'
      ] as const);
    case 'hexagon':
      return getRandomFromArray([
        'Hexagons/hexagon_blue',
        'Hexagons/hexagon_green',
        'Hexagons/hexagon_pink',
        'Hexagons/hexagon_purple',
        'Hexagons/hexagon_white',
        'Hexagons/hexagon_yellow'
      ] as const);
    case 'trapezium':
      return getRandomFromArray([
        'Trapezium/trapezium_isosceles_blue',
        'Trapezium/trapezium_isosceles_green',
        'Trapezium/trapezium_isosceles_pink',
        'Trapezium/trapezium_isosceles_purple',
        'Trapezium/trapezium_isosceles_white',
        'Trapezium/trapezium_isosceles_yellow'
      ] as const);
    case 'RAtrapezium':
      return getRandomFromArray([
        'Right_angled_trapeziums/trapezium_RA_blue',
        'Right_angled_trapeziums/trapezium_RA_green',
        'Right_angled_trapeziums/trapezium_RA_pink',
        'Right_angled_trapeziums/trapezium_RA_purple',
        'Right_angled_trapeziums/trapezium_RA_white',
        'Right_angled_trapeziums/trapezium_RA_yellow'
      ] as const);
    case 'parallelogram':
      return getRandomFromArray([
        'Parallelogram/parallelogram_blue',
        'Parallelogram/parallelogram_green',
        'Parallelogram/parallelogram_pink',
        'Parallelogram/parallelogram_purple',
        'Parallelogram/parallelogram_white',
        'Parallelogram/parallelogram_yellow'
      ] as const);
  }
};

export const shapesWithParallelAndPerpendicularData: {
  name: ShapesWithPendicularOrParallelLinesName;
  isPerpendicular: boolean;
  isParallel: boolean;
}[] = [
  {
    name: 'RAtriangle',
    isPerpendicular: true,
    isParallel: false
  },
  {
    name: 'triangle',
    isPerpendicular: false,
    isParallel: false
  },
  {
    name: 'square',
    isPerpendicular: true,
    isParallel: true
  },
  {
    name: 'rectangle',
    isPerpendicular: true,
    isParallel: true
  },
  {
    name: 'housePentagon',
    isPerpendicular: true,
    isParallel: true
  },
  {
    name: 'regularPentagon',
    isPerpendicular: false,
    isParallel: false
  },
  {
    name: 'hexagon',
    isPerpendicular: false,
    isParallel: true
  },
  {
    name: 'trapezium',
    isPerpendicular: false,
    isParallel: true
  },
  {
    name: 'RAtrapezium',
    isPerpendicular: true,
    isParallel: true
  },
  {
    name: 'parallelogram',
    isPerpendicular: false,
    isParallel: true
  }
];
