// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import NumbersTo1000000 from './1NumbersTo1000000';
import NumbersTo10000000 from './2NumbersTo10000000';
import ReadAndWriteNumbersTo10000000 from './3ReadAndWriteNumbersTo10000000';
import PowersOf10 from './4PowersOf10';
import NumberLineTo10000000 from './5NumberLineTo10000000';
import CompareAndOrderAnyIntegers from './6CompareAndOrderAnyIntegers';
import RoundAnyInteger from './7RoundAnyInteger';
import NegativeNumbers from './8NegativeNumbers';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 2],
  smallSteps: [
    NumbersTo1000000, // Step 1
    NumbersTo10000000, // Step 2
    ReadAndWriteNumbersTo10000000, // Step 3
    PowersOf10, // Step 4
    NumberLineTo10000000, // Step 5
    CompareAndOrderAnyIntegers, // Step 6
    RoundAnyInteger, // Step 7
    NegativeNumbers // Step 8
  ]
});
export default Block;
