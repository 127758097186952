import { z } from 'zod';
import { View } from 'react-native';

import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep
} from '../../../../utils/random';
import { DIV } from '../../../../constants';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import TenFrames from '../../../../components/question/representations/TenFrame/TenFrameLegacy';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { all, create, number } from 'mathjs';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { ScientificNotation } from '../../../../utils/math';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aKg',
  description: 'aKg',
  keywords: ['Ten frame', 'Division', '100'],
  schema: z.object({
    number1: z.number().int().min(200).max(2000).step(100)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(200, 2000, 100);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const numberOfCounters = number1 / 100;
    const answers = [[numberOfCounters.toString()], [numberOfCounters.toLocaleString()]];

    const sentenceB = `${number1} ${DIV} 100 = <ans />`;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.useTenFrameToCompleteSentences()}
        sentences={[
          translate.answerSentences.thereAreAnsGroupsOfXInY(100, number1.toLocaleString()),
          sentenceB
        ]}
        testCorrect={answers}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'center', alignItems: 'center' }]}>
            <TenFrames
              numberOfCounters={numberOfCounters}
              placeValue={'hundreds'}
              flexDirection="row"
            />
          </View>
        )}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aKh',
  description: 'aKh',
  keywords: ['Division', '100'],
  schema: z.object({
    number1: z.number().int().min(100).max(900).step(100),
    number2: z.number().int().min(100).max(900).step(100),
    number3: z.number().int().min(100).max(900).step(100),
    number4: z.number().int().min(1000).max(1200).step(100)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number2, number3] = randomUniqueIntegersInclusiveStep(100, 900, 100, 3);
    const number4 = randomIntegerInclusiveStep(1000, 1200, 100);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const eqs = [
      `${number1.toLocaleString()} ${DIV} 100 = <ans/>`,
      `${number2.toLocaleString()} ${DIV} 100 = <ans/>`,
      `<ans/> = ${number3.toLocaleString()} ${DIV} 100`,
      `<ans/> = ${number4.toLocaleString()} ${DIV} 100`
    ];

    const answers = [
      [number(math.evaluate(`${number1} / 100 `)).toString()],
      [number(math.evaluate(`${number2} / 100 `)).toString()],
      [number(math.evaluate(`${number3} / 100 `)).toString()],
      [number(math.evaluate(`${number4} / 100 `)).toString()]
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={answers}
        sentences={eqs}
        questionHeight={900}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aKh2',
  description: 'aKh2',
  keywords: ['Division', '100'],
  schema: z.object({
    number1: z.number().int().min(100).max(1200).step(100),
    equationFlipped: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(100, 1200, 100);

    const equationFlipped = getRandomBoolean();

    return { number1, equationFlipped };
  },
  Component: props => {
    const {
      question: { number1, equationFlipped },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[(number1 / 100).toString()]}
        sentence={
          equationFlipped
            ? `<ans/> = ${number1.toLocaleString()} ${DIV} ${(100).toLocaleString()}`
            : `${number1.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aKi',
  description: 'aKi',
  keywords: ['Division', '100'],
  schema: z.object({
    number1: z.number().int().min(1).max(4),
    number2: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 4);
    const number2 = randomIntegerInclusive(1, 5);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number(math.evaluate(`${number1} * 1000 + ${number2} * 100`));
    const number4 = number(math.evaluate(`${number3} /100`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        sentence={`${number3.toLocaleString()} = <ans/> ${translate.powersOfTen.hundreds(number4)}`}
        testCorrect={[number4.toString()]}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{
              variant: 'Cubes',
              numbers: { ['thousands']: number1, ['hundreds']: number2 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aKj',
  description: 'aKj',
  keywords: ['Division', '100'],
  schema: z.object({
    number1: z.number().min(1100).max(9900).step(100)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(1100, 9900, 100, {
      constraint: x => x % 1000 !== 0
    });
    return { number1 };
  },
  Component: ({ question: { number1 }, translate, displayMode }) => {
    const power = 100;
    const answer = number(math.evaluate(`${number1} / ${power}`));

    return (
      <QF23CreatePlaceValueChart
        initialState={displayMode === 'digital' ? number1 : undefined}
        number={ScientificNotation.fromNumber(answer)}
        columnsToShow={[5, 4, 3, 2, 1, 0]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        title={translate.instructions.dragCountersToShowAnswerNumDivNum(
          number1.toLocaleString(),
          power.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawCountersToShowTheAnswerToX(
          `${number1.toLocaleString()} ${DIV} ${power.toLocaleString()}`
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aKk',
  description: 'aKk',
  keywords: ['Division', '100'],
  schema: z.object({
    number1: z.number().int().min(100).max(900).step(100),
    number2: z.number().int().min(1000).max(9000).step(1000),
    number3: z.number().int().min(100).max(900).step(100),
    number4: z.number().int().min(10000).max(12000).step(100)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number3] = randomUniqueIntegersInclusiveStep(100, 900, 100, 3);
    const number2 = randomIntegerInclusiveStep(1000, 9000, 1000);
    const number4 = randomIntegerInclusiveStep(10000, 12000, 1000);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const eqs = [
      `${number1.toLocaleString()} ${DIV} 100 = <ans/>`,
      `${number2.toLocaleString()} ${DIV} 100 = <ans/>`,
      `<ans/> = ${number3.toLocaleString()} ${DIV} 100`,
      `<ans/> = ${number4.toLocaleString()} ${DIV} 100`
    ];

    const answers = [
      [number(math.evaluate(`${number1} / 100 `)).toString()],
      [number(math.evaluate(`${number2} / 100 `)).toString()],
      [number(math.evaluate(`${number3} / 100 `)).toString()],
      [number(math.evaluate(`${number4} / 100 `)).toString()]
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={answers}
        sentences={eqs}
        questionHeight={900}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aKk2',
  description: 'aKk2',
  keywords: ['Division', '100'],
  schema: z.object({
    number1: z.number().int().min(1000).max(12000).step(1000),
    equationFlipped: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(1000, 12000, 1000);

    const equationFlipped = getRandomBoolean();

    return { number1, equationFlipped };
  },
  Component: props => {
    const {
      question: { number1, equationFlipped },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[(number1 / 100).toString()]}
        sentence={
          equationFlipped
            ? `<ans/> = ${number1.toLocaleString()} ${DIV} ${(100).toLocaleString()}`
            : `${number1.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aKl',
  description: 'aKl',
  keywords: ['Division', '100'],
  schema: z.object({
    number1: z.number().int().min(10).max(90).step(10)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(10, 90, 10);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    const number2 = number1 * 10;
    const number3 = number1 * 100;

    const eqA = getBinOpEquation({ left: number1, right: 10, sign: 'divide', answer: 'result' });
    const eqB = getBinOpEquation({ left: number2, right: 10, sign: 'divide', answer: 'result' });
    const eqC = getBinOpEquation({ left: number2, right: 100, sign: 'divide', answer: 'result' });
    const eqD = getBinOpEquation({ left: number3, right: 100, sign: 'divide', answer: 'result' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy100',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question2, Question5]
});
export default SmallStep;
