// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import AddOrMultiply from './1AddOrMultiply';
import UseRatioLanguage from './2UseRatioLanguage';
import IntroductionToTheRatioSymbol from './3IntroductionToTheRatioSymbol';
import RatioAndFractions from './4RatioAndFractions';
import ScaleDrawing from './5ScaleDrawing';
import UseScaleFactors from './6UseScaleFactors';
import SimilarShapes from './7SimilarShapes';
import RatioProblems from './8RatioProblems';
import ProportionProblems from './9ProportionProblems';
import Recipes from './10Recipes';

const Block = newBlockContent({
  block: 'Ratio',
  field: 'Number',
  weeks: [1, 2],
  smallSteps: [
    AddOrMultiply, // Step 1
    UseRatioLanguage, // Step 2
    IntroductionToTheRatioSymbol, // Step 3
    RatioAndFractions, // Step 4
    ScaleDrawing, // Step 5
    UseScaleFactors, // Step 6
    SimilarShapes, // Step 7
    RatioProblems, // Step 8
    ProportionProblems, // Step 9
    Recipes // Step 10
  ]
});
export default Block;
