import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import getShadedCounterArrangements from '../../../../components/question/representations/CounterBoxArrangement/shadedCounterArrangements';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  ShadedCounterArrangements,
  ShadedCounterBoxArrangement
} from '../../../../components/question/representations/CounterBoxArrangement/ShadedCounterBoxArrangement';
import deepEqual from 'react-fast-compare';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import { trueCount } from '../../../../utils/shapes';
import { all, create, number } from 'mathjs';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import { View } from 'react-native';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

const equalOption = (
  shape: 'Circle' | 'Cross' | 'Hexagon' | 'Octagon' | 'Rectangle' | 'Square',
  color?: 'blue' | 'yellow' | 'green'
): SvgName => {
  switch (shape) {
    case 'Circle':
    case 'Hexagon':
    case 'Octagon':
    case 'Rectangle':
    case 'Square':
      return `Equal_shapes_4_parts/${shape}_equal_4-3_1_${color ?? 'blue'}`;
    case 'Cross':
      return 'Equal_shapes_4_parts/Cross_equal_3-4';
  }
};

const unequalOption = (
  shape: 'Circle' | 'Cross' | 'Hexagon' | 'Arrow' | 'Rectangle' | 'Square' | 'Heart' | 'Triangle',
  color?: 'blue' | 'yellow' | 'green'
): SvgName => {
  switch (shape) {
    case 'Cross':
      return 'Unequal_shapes_4_parts/Cross_unequal_3-4';
    case 'Circle':
    case 'Arrow':
    case 'Rectangle':
    case 'Square':
    case 'Triangle':
      return `Unequal_shapes_4_parts/${shape}_unequal_3_4_1_${color ?? 'blue'}`;
    case 'Hexagon':
      return `Unequal_shapes_4_parts/${shape}_unequal_3_4_2_${color ?? 'blue'}`;
    case 'Heart':
      return `Unequal_shapes_4_parts/${shape}_unequal_3_4_3_${color ?? 'blue'}`;
  }
};

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkI',
  description: 'bkI',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Three-quarters'],
  schema: z.object({
    items: z
      .array(
        z.discriminatedUnion('isEqual', [
          z.object({
            isEqual: z.literal(true),
            shape: z.enum(['Circle', 'Cross', 'Hexagon', 'Octagon', 'Rectangle', 'Square']),
            color: z.enum(['pink', 'blue', 'green', 'yellow'])
          }),
          z.object({
            isEqual: z.literal(false),
            shape: z.enum([
              'Circle',
              'Cross',
              'Hexagon',
              'Arrow',
              'Rectangle',
              'Square',
              'Heart',
              'Triangle'
            ]),
            color: z.enum(['pink', 'blue', 'green', 'yellow'])
          })
        ])
      )
      .length(4)
      .refine(
        items =>
          arrayHasNoDuplicates(
            items.map(({ color, ...rest }) => rest),
            deepEqual
          ),
        'items must have no duplicates ignoring color'
      ),
    // If this isn't provided, then it's an old question: default to 0.
    rotation: numberEnum([0, 90, 180, 270]).optional()
  }),
  simpleGenerator: () => {
    const correctAnswerCount = randomIntegerInclusive(2, 3);
    const items = rejectionSample(
      () => {
        const correctItems = countRange(correctAnswerCount).map(() => {
          const shape = getRandomFromArray([
            'Circle',
            'Cross',
            'Hexagon',
            'Octagon',
            'Rectangle',
            'Square'
          ] as const);
          return {
            isEqual: true as const,
            shape,
            color:
              shape === 'Cross'
                ? ('pink' as const)
                : getRandomFromArray(['blue', 'green', 'yellow'] as const)
          };
        });

        const incorrectItems = countRange(4 - correctAnswerCount).map(() => {
          const shape = getRandomFromArray([
            'Circle',
            'Cross',
            'Hexagon',
            'Arrow',
            'Rectangle',
            'Square',
            'Heart',
            'Triangle'
          ] as const);
          return {
            isEqual: false as const,
            shape,
            color:
              shape === 'Cross'
                ? ('pink' as const)
                : getRandomFromArray(['blue', 'green', 'yellow'] as const)
          };
        });

        return shuffle([...incorrectItems, ...correctItems]);
      },
      item =>
        arrayHasNoDuplicates(
          item.map(({ color, ...rest }) => rest),
          deepEqual
        )
    );

    const rotation = getRandomFromArray([0, 90, 180, 270] as const);

    return { items, rotation };
  },
  Component: ({ question: { items, rotation = 0 }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheDiagramsThatShow3Quarters()}
        pdfTitle={translate.ks1PDFInstructions.tickTheDiagramsThatShow3Quarters()}
        numItems={4}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <View
                style={{
                  transform: [
                    {
                      rotate:
                        // Do not rotate if the shape is a rectangle and the rotation is 90 or 270 to avoid rectangle exceeding bounds of selectable
                        item.shape === 'Rectangle' && rotation !== 0 && rotation !== 180
                          ? '0deg'
                          : `${rotation}deg`
                    }
                  ]
                }}
              >
                <AssetSvg
                  name={
                    item.isEqual
                      ? equalOption(item.shape, item.color === 'pink' ? undefined : item.color)
                      : unequalOption(item.shape, item.color === 'pink' ? undefined : item.color)
                  }
                  width={dimens.width * 0.8}
                  height={dimens.height * 0.8}
                />
              </View>
            ),
            value: item
          }))
        }
        testCorrect={items.filter(item => item.isEqual)}
        questionHeight={1000}
        multiSelect
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bkJ',
  description: 'bkJ',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Three-quarters'],
  questionHeight: 1000,
  schema: z.object({
    options: z
      .array(
        z.object({
          arrangement: z.array(z.array(numberEnum([0, 1, 2]))),
          isThreeQuarter: z.boolean()
        })
      )
      .length(4)
      .refine(x => arrayHasNoDuplicates(x, deepEqual), 'all options should be different')
  }),
  simpleGenerator: () => {
    const numOfCorrect = getRandomFromArray([2, 3]);

    const threeQuarterOptions = getRandomSubArrayFromArray(
      [
        [2, 2, 'rows'],
        [1, 4, 'rows'],
        [4, 4, 'rows'],
        [4, 4, 'quadrants'],
        [2, 4, 'columns']
      ] as const,
      numOfCorrect
    );
    const correctOptions = threeQuarterOptions.map(([rows, cols, shadedVariation]) => {
      return {
        arrangement: getShadedCounterArrangements({
          rows,
          cols,
          shadedVariation,
          numShaded: rows * cols * 0.75
        }),
        isThreeQuarter: true
      };
    });

    const wrongArrangements: ShadedCounterArrangements[] = getRandomSubArrayFromArray(
      [
        [
          [2, 2, 2, 0],
          [1, 1, 1, 1]
        ],
        [
          [2, 2, 2, 1],
          [1, 1, 1, 1]
        ]
      ],
      4 - numOfCorrect
    );

    const wrongOptions = wrongArrangements.map(arrangement => {
      return {
        arrangement,
        isThreeQuarter: false
      };
    });

    return { options: shuffle([...correctOptions, ...wrongOptions]) };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectThePicturesThatHaveThreeQuartersShaded()}
        pdfTitle={translate.ks1PDFInstructions.circleThePicturesThatHaveThreeQuartersShaded()}
        numItems={4}
        multiSelect
        pdfShowBorder
        renderItems={({ dimens }) =>
          options.map(({ arrangement }, index) => ({
            value: index,
            component: (
              <ShadedCounterBoxArrangement dimens={dimens} arrangement={arrangement} scale={4.5} />
            )
          }))
        }
        testCorrect={options.flatMap((el, index) => (el.isThreeQuarter ? [index] : []))}
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkK',
  description: 'bkK',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z
    .object({
      rows: z.number().int().min(1).max(4),
      columns: z.number().int().min(4).max(8)
    })
    .refine(
      val => val.rows % 4 === 0 || val.columns % 4 === 0,
      'rows or columns must be a multiple of 4'
    ),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(1, 4);
    const columns = randomIntegerInclusiveStep(4, 8, rows === 4 ? 1 : 4);

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate
    } = props;

    const answer = number(math.evaluate(`${rows} * ${columns} * 0.75`));

    return (
      <QF24CreateShapeFromSquares
        title={translate.ks1Instructions.tapToShadeThreeQuartersOfGrid()}
        pdfTitle={translate.ks1PDFInstructions.shadeThreeQuartersOfGrid()}
        numberOfRows={rows}
        numberOfCols={columns}
        testCorrect={userAnswer => deepEqual(trueCount(userAnswer), answer)}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyXSquaresShadedOnTheGrid(answer)
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseThreeQuarters',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
