import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  countRange,
  filledArray,
  range
} from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { integerToWord } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import TextStructure from '../../../../components/molecules/TextStructure';
import TenFrameLayout, {
  counterVariantSchema,
  getRandomUniqueCounters
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bap',
  description: 'bap',
  keywords: ['Ten frames', 'Counters', 'Count'],
  schema: z.object({
    number1: z.number().int().min(1).max(6),
    colors: counterVariantSchema.array().length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 6);
    const colors = getRandomUniqueCounters(2);

    return { number1, colors };
  },
  Component: props => {
    const {
      question: { number1, colors },
      translate,
      displayMode
    } = props;

    const minHeight = displayMode === 'digital' ? 116 : 151.5;
    const minWidth = displayMode === 'digital' ? 96 : 200;

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.whatNumbersAreShown()}
        inputMaxCharacters={1}
        mainPanelStyle={{ flexDirection: 'row' }}
        style={{ marginTop: 0 }}
        sentenceStyle={{
          width: minWidth,
          height: minHeight,
          right: 150
        }}
        textStyle={{ textAlign: 'center', lineHeight: minHeight }}
        pdfSentenceStyle={{
          marginTop: 0,
          right: 450,
          height: '100%',
          justifyContent: 'space-evenly'
        }}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-evenly' }}>
            {countRange(4).map(val => (
              <TenFrameLayout
                key={val}
                size={displayMode === 'digital' ? 'xsmall' : 'small'}
                items={[...filledArray(colors[0], number1), ...filledArray(colors[1], val)]}
              />
            ))}
          </View>
        )}
        sentences={[number1.toLocaleString(), ...filledArray('<ans/>', 3)]}
        testCorrect={countRange(4).map(val => (val === 0 ? [] : [(number1 + val).toString()]))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'baq',
  description: 'baq',
  keywords: ['Number track', 'Count'],
  schema: z.object({
    startingNumber: z.number().min(0).max(4),
    answerIndexes: z.array(z.number().min(2).max(7)).min(1).refine(arrayHasNoDuplicates),
    numCells: z.number().min(6).max(8)
  }),
  simpleGenerator: () => {
    const numCells = randomIntegerInclusive(6, 8);
    const startingNumber = randomIntegerInclusive(0, 10 - numCells);
    const numberOfAns = numCells - 3;
    const answerIndexes = randomUniqueIntegersInclusive(2, numCells - 1, numberOfAns);
    return { startingNumber, answerIndexes, numCells };
  },
  Component: ({ question: { startingNumber, answerIndexes, numCells }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = countRange(numCells).map(i => startingNumber + i);

    const ansArray = numberArray
      .filter((_val, i) => answerIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      answerIndexes.includes(id) ? '<ans/>' : val.toLocaleString()
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bar',
  description: 'bar',
  keywords: ['Words', 'Numeral'],
  schema: z.object({
    startingNumber: z.number().min(1).max(6),
    wordIndexes: z.array(z.number().min(0).max(4)),
    itemOrder: z.number().min(1).max(4).array().length(4).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 6);
    const numWords = randomIntegerInclusive(0, 2);
    const wordIndexes = randomUniqueIntegersInclusive(0, 4, numWords);
    const itemOrder = shuffle(range(1, 4));
    return { startingNumber, wordIndexes, itemOrder };
  },
  Component: ({ question: { startingNumber, wordIndexes, itemOrder }, translate, displayMode }) => {
    const numberArray = range(startingNumber, startingNumber + 4);
    const stringArray = numberArray.map((val, id) =>
      wordIndexes.includes(id) ? integerToWord(val) : val.toLocaleString()
    );
    const sentence = stringArray
      .map((string, index) => (index === 0 ? string : '<ans/>'))
      .join(' ,  ');

    const correctAnswer = numberArray.slice(1);
    const items = itemOrder.map(index => ({
      value: numberArray[index], // use number in user answer's data
      component: <TextStructure sentence={stringArray[index]} /> // use string for showing to user
    }));
    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardsToPutThemInOrder()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToPutThemInOrder()}
        sentence={sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        items={items}
        moveOrCopy="move"
        itemVariant="shortRectangle"
        actionPanelVariant="bottom"
        pdfLayout="itemsTop"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        pdfItemVariant="shortRectangle"
        testCorrect={correctAnswer}
        questionHeight={900}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountOnFromAnyNumber',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
