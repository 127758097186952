import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { newQuestionContent } from '../../../Question';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import LineGraph from '../../../../components/question/representations/Coordinates/LineGraph';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomNumber,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { range } from '../../../../utils/collections';
import { LineGraphColors } from '../../../../theme/colors';
import { getDayTemperaturesGenerator } from '../../../../utils/graphs';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { numberEnum } from '../../../../utils/zod';
import { displayDigitalTime } from '../../../../utils/time';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ay8',
  description: 'ay8',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z.object({
    tempMax: z.number().int().min(5).max(6),
    timeMin: z.number().int().min(5).max(10),
    answerIdx: z.number().int().min(0).max(6),
    temperatures: z.number().int().array()
  }),
  simpleGenerator: () => {
    const tempMax = randomIntegerInclusive(5, 6);
    const timeMin = randomIntegerInclusive(5, 10);
    const answerIdx = randomIntegerInclusive(0, 6);

    const tempMin = 0;
    const timeStep = 2;

    // Temperature
    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      tempMax,
      timeMin,
      timeMin + timeStep * 6,
      // Peak time of day is between 1pm and 3pm
      randomNumber(13, 15)
    );

    const temperatures = range(timeMin, timeMin + 6 * timeStep, timeStep)
      .map(dayTemperatures)
      .map(Math.round);

    return { tempMax, timeMin, answerIdx, temperatures };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { tempMax, timeMin, answerIdx, temperatures },
      translate
    } = props;

    const tempMin = 0;
    const timeStep = 2;

    // Times
    const times = range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(x =>
      displayDigitalTime(x, 0, true, '24')
    );

    const [color] = shuffle(LineGraphColors.slice(0, 2), {
      random: seededRandom(props.question)
    });

    // Get index of time
    const indexOfTime = times.indexOf(times[answerIdx]);

    // Answer
    const ans = temperatures[indexOfTime];

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${translate.keywords.Celsius()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatIsTheTemperatureAtXOption(times[answerIdx])}
        testCorrect={[ans.toString()]}
        questionHeight={1000}
        Content={
          <MeasureView style={{ marginBottom: -30 }}>
            {({ width, height }) => (
              <Grid
                sizingMethod="dimens"
                width={width}
                height={height}
                xAxis={tempMin}
                xMin={timeMin}
                xMax={timeMin + (temperatures.length - 1) * timeStep}
                xStepSize={timeStep}
                yAxis={timeMin}
                yMin={tempMin}
                yMax={tempMax}
                yStepSize={1}
                yAxisLabel={`${translate.keywords.Temperature()} (${translate.keywords.Celsius()})`}
                xAxisLabel={translate.keywords.Time()}
                xLabels={[...times]}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
              >
                <LineGraph points={temperatures} color={color} />
              </Grid>
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ay9',
  description: 'ay9',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z.object({
    tempMin: z.number().int().min(0),
    tempMax: z.number().int().min(5).max(6),
    timeMin: z.number().int().min(5).max(10),
    timeStep: z.number().int().min(2),
    temperatures: z.array(z.number().int().min(0).max(6)).length(7),
    times: z.array(z.string()),
    shuffledUniqueData: z.array(z.object({ time: z.string(), temperature: z.number() }))
  }),
  simpleGenerator: () => {
    const { tempMin, tempMax, timeMin, timeStep, temperatures, times, shuffledUniqueData } =
      rejectionSample(
        () => {
          const tempMin = 0;
          const tempMax = randomIntegerInclusive(5, 6);
          const timeMin = randomIntegerInclusive(5, 10);
          const timeStep = 2;

          // Temperature
          const dayTemperatures = getDayTemperaturesGenerator(
            tempMin,
            tempMax,
            timeMin,
            timeMin + timeStep * 6,
            // Peak time of day is between 1pm and 3pm
            randomNumber(13, 15)
          );

          const temperatures = range(timeMin, timeMin + 6 * timeStep, timeStep)
            .map(dayTemperatures)
            .map(Math.round);

          // Times
          const times = range(
            timeMin,
            timeMin + (temperatures.length - 1) * timeStep,
            timeStep
          ).map(x => displayDigitalTime(x, 0, true, '24'));

          // Data
          const data = shuffle(
            temperatures.map((temperature, idx) => {
              return {
                time: times[idx],
                temperature
              };
            })
          );

          const duplicateTemperatures: number[] = [];

          const seenTemperatures = new Set();
          const uniqueData = data.filter(obj => {
            const isUnique = !seenTemperatures.has(obj.temperature);
            seenTemperatures.add(obj.temperature);
            // If temperature is one of the duplicates
            if (!isUnique) {
              duplicateTemperatures.push(obj.temperature);
            }
            return isUnique;
          });

          const shuffledUniqueData = getRandomSubArrayFromArray(uniqueData, 4);

          return {
            tempMin,
            tempMax,
            timeMin,
            timeStep,
            temperatures,
            times,
            shuffledUniqueData,
            duplicateTemperatures
          };
        },
        ({ temperatures, shuffledUniqueData, duplicateTemperatures }) => {
          // Ensure at least 4 unique values to allow three unique selectable answers
          const hasAtLeastXUniqueValues = (arr: number[], uniqueSize: number) => {
            const uniqueValues = new Set(arr);
            return uniqueValues.size >= uniqueSize;
          };

          // If duplicate temperature for e.g 2 points on the x axis
          // Ensure this is not used as the correct answer
          // We don't want the user to have to pick between two correct answers
          const isDuplicateAnswer = duplicateTemperatures.some(
            temp => temp === shuffledUniqueData[0].temperature
          );

          return hasAtLeastXUniqueValues(temperatures, 4) && !isDuplicateAnswer;
        }
      );

    return { tempMin, tempMax, timeMin, timeStep, temperatures, times, shuffledUniqueData };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { tempMin, tempMax, timeMin, timeStep, temperatures, times, shuffledUniqueData },
      translate
    } = props;

    // Color
    const [color] = shuffle(LineGraphColors.slice(0, 2), {
      random: seededRandom(props.question)
    });

    const shuffledSelectables = shuffle(
      [
        shuffledUniqueData[0].time,
        shuffledUniqueData[1].time,
        shuffledUniqueData[2].time,
        shuffledUniqueData[3].time
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheTimeThatShowsWhenTemperatureIsXTemperatureCelsius(
          shuffledUniqueData[0].temperature.toLocaleString()
        )}
        pdfTitle={translate.instructions.circleTheTimeThatShowsWhenTemperatureIsXTemperatureCelsius(
          shuffledUniqueData[0].temperature.toLocaleString()
        )}
        selectables={{
          timeA: shuffledSelectables[0],
          timeB: shuffledSelectables[1],
          timeC: shuffledSelectables[2],
          timeD: shuffledSelectables[3]
        }}
        selectableTextStyle={{ textTransform: 'uppercase' }}
        correctAnswer={[
          shuffledUniqueData[0].time === shuffledSelectables[0]
            ? 'timeA'
            : shuffledUniqueData[0].time === shuffledSelectables[1]
            ? 'timeB'
            : shuffledUniqueData[0].time === shuffledSelectables[2]
            ? 'timeC'
            : 'timeD'
        ]}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                sizingMethod="dimens"
                xAxis={tempMin}
                xMin={timeMin}
                xMax={timeMin + (temperatures.length - 1) * timeStep}
                xStepSize={timeStep}
                yAxis={timeMin}
                yMin={tempMin}
                yMax={tempMax}
                yStepSize={1}
                yAxisLabel={`${translate.keywords.Temperature()} (${translate.keywords.Celsius()})`}
                xAxisLabel={translate.keywords.Time()}
                xLabels={times}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
              >
                <LineGraph points={temperatures} color={color} />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aza',
  description: 'aza',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z.object({
    tempMin: z.number().int().min(0),
    tempMax: z.number().int().min(5).max(6),
    timeMin: z.number().int().min(5).max(10),
    timeStep: z.number().int().min(2),
    temperatures: z.array(z.number().int().min(0).max(6)).length(7),
    times: z.array(z.string()),
    randomIdx: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const tempMin = 0;
    const tempMax = randomIntegerInclusive(5, 6);
    const timeMin = randomIntegerInclusive(5, 10);
    const timeStep = 2;

    // Temperature
    const dayTemperatures = getDayTemperaturesGenerator(
      tempMin,
      tempMax,
      timeMin,
      timeMin + timeStep * 6,
      // Peak time of day is between 1pm and 3pm
      randomNumber(13, 15)
    );

    const temperatures = range(timeMin, timeMin + 6 * timeStep, timeStep)
      .map(dayTemperatures)
      .map(Math.round);

    // Times
    const times = range(timeMin, timeMin + (temperatures.length - 1) * timeStep, timeStep).map(x =>
      displayDigitalTime(x, 0, true, '24')
    );

    const randomIdx = randomIntegerInclusive(0, 5, {
      constraint: x => temperatures[x] !== temperatures[x + 1]
    });

    return {
      tempMin,
      tempMax,
      timeMin,
      timeStep,
      temperatures,
      times,
      randomIdx
    };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { tempMin, tempMax, timeMin, timeStep, temperatures, times, randomIdx },
      translate
    } = props;

    const [color] = shuffle(LineGraphColors.slice(0, 2), {
      random: seededRandom(props.question)
    });

    const randomTimeA = times[randomIdx];
    const randomTimeB = times[randomIdx + 1];

    const tempGreaterThanOrLessThan = temperatures[randomIdx] > temperatures[randomIdx + 1];

    // Answer
    const ans = tempGreaterThanOrLessThan
      ? temperatures[randomIdx] - temperatures[randomIdx + 1]
      : temperatures[randomIdx + 1] - temperatures[randomIdx];

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${translate.keywords.Celsius()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.howMuchDidTheTemperatureIncreaseOrDecreaseBetweenOptionXAndOptionY(
          tempGreaterThanOrLessThan ? translate.keywords.Decrease() : translate.keywords.Increase(),
          randomTimeA,
          randomTimeB
        )}
        testCorrect={[ans.toString()]}
        questionHeight={1000}
        Content={
          <MeasureView style={{ marginBottom: -30 }}>
            {({ width, height }) => (
              <Grid
                sizingMethod="dimens"
                width={width}
                height={height}
                xAxis={tempMin}
                xMin={timeMin}
                xMax={timeMin + (temperatures.length - 1) * timeStep}
                xStepSize={timeStep}
                yAxis={timeMin}
                yMin={tempMin}
                yMax={tempMax}
                yStepSize={1}
                yAxisLabel={`${translate.keywords.Temperature()} (${translate.keywords.Celsius()})`}
                xAxisLabel={translate.keywords.Time()}
                xLabels={[...times]}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
              >
                <LineGraph points={temperatures} color={color} />
              </Grid>
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'azb',
  description: 'azb',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z.object({
    heightMax: numberEnum([10, 12]),
    heights: z.array(z.number().int().min(1).max(12)).length(6),
    randomDay: numberEnum([0, 1, 2, 3, 4, 5, 6])
  }),
  simpleGenerator: () => {
    const heightMax = getRandomFromArray([10, 12] as const);
    const heights = randomUniqueIntegersInclusive(1, heightMax, 6);
    const randomDay = getRandomFromArray([0, 1, 2, 3, 4, 5, 6] as const);

    return { heightMax, heights, randomDay };
  },
  questionHeight: 1150,
  Component: props => {
    const {
      question: { heightMax, heights, randomDay },
      translate
    } = props;

    const heightMin = 0;
    const days = [
      '0'.toLocaleString(),
      '1'.toLocaleString(),
      '2'.toLocaleString(),
      '3'.toLocaleString(),
      '4'.toLocaleString(),
      '5'.toLocaleString(),
      '6'.toLocaleString()
    ];
    const dayMin = 0;
    const dayMax = 6;
    const dayStep = 1;

    const [color] = shuffle(LineGraphColors.slice(0, 2), {
      random: seededRandom(props.question)
    });

    const heightsAscending = heights.map(temp => temp).sort((a, b) => a - b);

    // Answer
    const ans = randomDay === 0 ? 0 : heightsAscending[randomDay - 1];

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${translate.units.cm()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.whatWasTheHeightOfThePlantOnDayX(randomDay)}
        testCorrect={[ans.toString()]}
        questionHeight={1150}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={
          <MeasureView style={{ marginBottom: -30 }}>
            {({ width, height }) => (
              <Grid
                sizingMethod="dimens"
                width={width}
                height={height}
                xAxis={heightMin}
                xMin={dayMin}
                xMax={dayMax}
                xStepSize={dayStep}
                yAxis={dayMin}
                yMin={heightMin}
                yMax={heightMax}
                yStepSize={2}
                yAxisLabel={`${translate.keywords.Height()} (${translate.units.cm()})`}
                xAxisLabel={translate.keywords.Day()}
                xLabels={days}
                yAxisArrowLabel={null}
                xAxisArrowLabel={null}
              >
                <LineGraph points={[0, ...heightsAscending]} color={color} />
              </Grid>
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'azc',
  description: 'azc',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z.object({
    heightMax: numberEnum([10, 12]),
    heights: z.array(z.number().int().min(1).max(12)).length(6),
    randomDay: numberEnum([0, 1, 2, 3, 4, 5, 6])
  }),
  simpleGenerator: () => {
    const heightMax = getRandomFromArray([10, 12] as const);
    const heights = randomUniqueIntegersInclusive(1, heightMax, 6);
    const randomDay = getRandomFromArray([0, 1, 2, 3, 4, 5, 6] as const);

    return { heightMax, heights, randomDay };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { heightMax, heights },
      translate
    } = props;

    const random = seededRandom(props.question);
    const heightMin = 0;
    const days = [0, 1, 2, 3, 4, 5, 6];
    const dayMin = 0;
    const dayMax = 6;
    const dayStep = 1;

    const [color] = shuffle(LineGraphColors.slice(0, 2), {
      random
    });

    const heightsAscending = heights.map(temp => temp).sort((a, b) => a - b);

    // Data
    const data = shuffle(
      days.map((day, idx) => {
        if (idx === 0) {
          return {
            day: translate.timePeriod.dayX(day),
            height: 0
          };
        } else {
          return {
            day: translate.timePeriod.dayX(day),
            height: heightsAscending[idx - 1]
          };
        }
      }),
      { random }
    );

    // Selectables
    const [selectableA, selectableB, selectableC, selectableD] = shuffle(
      [data[0].day, data[1].day, data[2].day, data[3].day],
      { random }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectTheDayThatShowsWhenHeightOfPlantReachedXHeightCm(
          data[0].height
        )}
        pdfTitle={translate.instructions.circleTheDayThatShowsWhenHeightOfPlantReachedXHeightCm(
          data[0].height
        )}
        selectables={{
          dayA: selectableA,
          dayB: selectableB,
          dayC: selectableC,
          dayD: selectableD
        }}
        correctAnswer={[
          data[0].day === selectableA
            ? 'dayA'
            : data[0].day === selectableB
            ? 'dayB'
            : data[0].day === selectableC
            ? 'dayC'
            : 'dayD'
        ]}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                sizingMethod="dimens"
                width={dimens.width}
                height={dimens.height}
                xAxis={heightMin}
                xMin={dayMin}
                xMax={dayMax}
                xStepSize={dayStep}
                yAxis={dayMin}
                yMin={heightMin}
                yMax={heightMax}
                yStepSize={2}
                yAxisLabel={`${translate.keywords.Height()} (${translate.units.cm()})`}
                xAxisLabel={translate.keywords.Day()}
                xLabels={days.map(val => val.toLocaleString())}
                yAxisArrowLabel={null}
                xAxisArrowLabel={null}
              >
                <LineGraph points={[0, ...heightsAscending]} color={color} />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'azd',
  description: 'azd',
  keywords: ['Line graphs', 'Interpret', 'Axes'],
  schema: z.object({
    heightMax: numberEnum([10, 12]),
    heights: z.array(z.number().int().min(1).max(12)).length(6),
    randomDays: z.array(z.number().int().min(0).max(6)).length(2)
  }),
  simpleGenerator: () => {
    const heightMax = getRandomFromArray([10, 12] as const);
    const heights = randomUniqueIntegersInclusive(1, heightMax, 6);
    const randomDays = randomUniqueIntegersInclusive(0, 6, 2);

    return { heightMax, heights, randomDays };
  },
  questionHeight: 1150,
  Component: props => {
    const {
      question: { heightMax, heights, randomDays },
      translate
    } = props;

    const [randomDay1, randomDay2] = randomDays;

    const heightMin = 0;
    const days = [
      '0'.toLocaleString(),
      '1'.toLocaleString(),
      '2'.toLocaleString(),
      '3'.toLocaleString(),
      '4'.toLocaleString(),
      '5'.toLocaleString(),
      '6'.toLocaleString()
    ];
    const dayMin = 0;
    const dayMax = 6;
    const dayStep = 1;

    const [color] = shuffle(LineGraphColors.slice(0, 2), {
      random: seededRandom(props.question)
    });

    const heightsAscending = [0, ...heights.map(temp => temp).sort((a, b) => a - b)];

    const heightGreaterThanOrLessThan = heightsAscending[randomDay1] > heightsAscending[randomDay2];

    // Answer
    const ans = heightGreaterThanOrLessThan
      ? heightsAscending[randomDay1] - heightsAscending[randomDay2]
      : heightsAscending[randomDay2] - heightsAscending[randomDay1];

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${translate.units.cm()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.howMuchXHeighthWasThePlantOnDayYThanOnDayZ(
          heightGreaterThanOrLessThan ? translate.keywords.Taller() : translate.keywords.Shorter(),
          randomDay1,
          randomDay2
        )}
        testCorrect={[ans.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1150}
        Content={
          <MeasureView style={{ marginBottom: -30 }}>
            {({ width, height }) => (
              <Grid
                sizingMethod="dimens"
                width={width}
                height={height}
                xAxis={heightMin}
                xMin={dayMin}
                xMax={dayMax}
                xStepSize={dayStep}
                yAxis={dayMin}
                yMin={heightMin}
                yMax={heightMax}
                yStepSize={2}
                yAxisLabel={`${translate.keywords.Height()} (${translate.units.cm()})`}
                xAxisLabel={translate.keywords.Day()}
                xLabels={days}
                yAxisArrowLabel={null}
                xAxisArrowLabel={null}
              >
                <LineGraph points={heightsAscending} color={color} />
              </Grid>
            )}
          </MeasureView>
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InterpretLineGraphs',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
