import { isEqual } from '../../../utils/matchers';
import { useContext, useMemo } from 'react';
import { View } from 'react-native';
import { TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import {
  CompleteDoubleNumberLine,
  DoubleNumberLineWithState
} from '../representations/Number Line/DoubleNumberLine';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import TextStructure from '../../molecules/TextStructure';
import { parseMarkup } from '../../../markup';
import { filledArray } from '../../../utils/collections';
import { parseSymbolsToString } from '../../../utils/parse';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  bottomTickValues: CompleteDoubleNumberLine;
  topTickValues: CompleteDoubleNumberLine;
  /**
   * If sending in as an array the answers should be [..topRowLeftToRight, ...bottomRowLeftToRight]
   */
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  /**
   * These 2 parameters are only necessary if the number line you're rendering won't have a label on the first or last
   * ticks. These are required for calculating the position to place arrows/answer boxes on the number line.
   * @param firstNumber - First number on the number line
   * @param lastNumber - Last number on the number line
   */
  firstNumber?: number;
  lastNumber?: number;
  inputMaxCharacters?: number;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: T[]; answerText?: string };
  precedingLinesText?: string[];
  /**
   * Optional extra symbol on keypad
   */
  extraSymbol?: ExtraSymbols;
  /**
   * Optional allow ticks to have no number or answer box above/below
   */
  allowEmptyTicks?: boolean;
  /** optional text for after the answer box */
  inputTrailingSymbol?: string;
  /** optional number to set the fontsize for the labels*/
  customFontSize?: number;
  /** optional variation to increase the height of the number line. Defaults to 'small'*/
  size?: 'small' | 'large';
};

/**
 * QF17c is a double number line with answer boxes
 */
export default function QF17cCompleteTheDoubleNumberLine<T extends string | number>({
  title,
  pdfTitle,
  topTickValues,
  bottomTickValues,
  inputMaxCharacters: inputMaxCharactersProp,
  testCorrect: testCorrectProp,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  precedingLinesText,
  extraSymbol,
  allowEmptyTicks = false,
  inputTrailingSymbol,
  customFontSize,
  size = 'large',
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  const totalNumberOfAns = [...topTickValues, ...bottomTickValues]
    .map(tick => parseMarkup(tick as string).numberOfAns)
    .reduce((sum, numberOfAns) => sum + numberOfAns);
  const initialState = filledArray('', totalNumberOfAns);

  // Make answer input boxes a suitable width
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (Array.isArray(testCorrectProp)) {
      return Math.max(...testCorrectProp.map(it => it.length));
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (isPdf) {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp.map(ans => {
            // Temporary variable to convert simple string to localized string
            const temp = Number(parseSymbolsToString(ans));
            return temp.toLocaleString();
          });

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <View style={{ flexDirection: 'row' }}>
                  {precedingLinesText && (
                    <View
                      style={{
                        width: 350,
                        height: 200,
                        alignSelf: 'center',
                        justifyContent: 'space-evenly',
                        bottom: 24
                      }}
                    >
                      {precedingLinesText.map((text, index) => (
                        <TextStructure
                          key={`${precedingLinesText}_${index}`}
                          textVariant="WRN400"
                          textStyle={{
                            textAlign: 'center',
                            fontSize: customFontSize ?? 50
                          }}
                          sentence={text}
                        />
                      ))}
                    </View>
                  )}
                  <DoubleNumberLineWithState
                    id="doublenumberline"
                    inputMaxCharacters={inputMaxCharacters}
                    defaultState={
                      displayMode === 'markscheme' ? (markSchemeAnswer as string[]) : []
                    }
                    testCorrect={testCorrect}
                    inputTrailingSymbol={inputTrailingSymbol}
                    allowEmptyTicks={allowEmptyTicks}
                    topTickValues={topTickValues}
                    bottomTickValues={bottomTickValues}
                    size={size}
                    dimens={{
                      height: dimens.height / 3,
                      width: precedingLinesText ? dimens.width - 350 : dimens.width
                    }}
                    customFontSize={customFontSize}
                  />
                </View>
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  const widthOffset = (precedingLinesText ? 150 : 0) + (inputTrailingSymbol ? 20 : 0);

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={
        <UserInput inputType="numpad" variant="tall" extraSymbol={extraSymbol} />
      }
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <View style={{ flexDirection: 'row' }}>
              {precedingLinesText && (
                <View
                  style={{
                    width: 150,
                    height: 200,
                    alignSelf: 'center',
                    justifyContent: 'space-evenly',
                    bottom: 18
                  }}
                >
                  {precedingLinesText.map((text, index) => (
                    <TextStructure
                      key={`${precedingLinesText}_${index}`}
                      textVariant="WRN400"
                      textStyle={{
                        textAlign: 'center',
                        fontSize: customFontSize ?? 32
                      }}
                      sentence={text}
                    />
                  ))}
                </View>
              )}
              <DoubleNumberLineWithState
                id="doublenumberline"
                defaultState={initialState}
                inputMaxCharacters={inputMaxCharacters}
                testCorrect={testCorrect}
                allowEmptyTicks={allowEmptyTicks}
                topTickValues={topTickValues}
                bottomTickValues={bottomTickValues}
                inputTrailingSymbol={inputTrailingSymbol}
                size={size}
                dimens={{
                  height: dimens.height / 3,
                  width: precedingLinesText ? dimens.width - widthOffset : dimens.width
                }}
                customFontSize={customFontSize}
              />
            </View>
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
