import { newSmallStepContent } from '../../../SmallStep';
import { View } from 'react-native';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomNumberInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DIV } from '../../../../constants';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { AssetSvg } from '../../../../assets/svg';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import ReadPlaceValueChart from '../../../../components/question/questionFormats/ReadPlaceValueChart';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import { range } from '../../../../utils/collections';
import { useContext } from 'react';
import { DisplayMode } from '../../../../contexts/displayMode';
import QF2DraggableAlignedEquations from '../../../../components/question/questionFormats/QF2DraggableAlignedEquations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVo',
  description: 'aVo',
  keywords: ['Decimal', 'Integer', 'Division', 'Divide', 'Place value counters'],
  schema: z.object({
    bigCounters: z.number().int().min(1).max(9),
    smallCounters: z.number().int().min(1).max(9),
    rows: z.number().int().min(2).max(5),
    useWholeNumbers: z.boolean(),
    calcLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const bigCounters = randomIntegerInclusive(1, 9);
    const smallCounters = randomIntegerInclusive(1, 9, { constraint: x => x + bigCounters <= 10 });
    const rows = randomIntegerInclusive(2, 5);
    const useWholeNumbers = getRandomBoolean();
    const calcLeft = getRandomBoolean();

    return { bigCounters, smallCounters, rows, useWholeNumbers, calcLeft };
  },

  Component: ({
    question: { bigCounters, smallCounters, rows, useWholeNumbers, calcLeft },
    translate
  }) => {
    const displayMode = useContext(DisplayMode);
    const answer = useWholeNumbers
      ? number(math.evaluate(`${bigCounters} + ${smallCounters / 10}`))
      : number(math.evaluate(`${bigCounters / 10} + ${smallCounters / 100}`));

    const total = number(math.evaluate(`${answer} * ${rows}`));

    const sentence = calcLeft
      ? `${total.toLocaleString()} ${DIV} ${rows.toLocaleString()} = <ans/>`
      : `<ans/> = ${total.toLocaleString()} ${DIV} ${rows.toLocaleString()}`;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueCountersToCompleteDivision()}
        Content={({ dimens }) => (
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <ArrayOfObjects
              rows={rows}
              columns={bigCounters}
              dimens={dimens}
              containerStyle={{ alignItems: 'flex-end', width: 'auto' }}
              customImage={
                <AssetSvg
                  name={useWholeNumbers ? 'Place_value/1' : 'Place_value/0.1'}
                  width={displayMode === 'digital' ? 60 : 90}
                />
              }
            />
            <ArrayOfObjects
              rows={rows}
              columns={smallCounters}
              dimens={dimens}
              containerStyle={{ alignItems: 'flex-start', width: 'auto' }}
              customImage={
                <AssetSvg
                  name={useWholeNumbers ? 'Place_value/0.1' : 'Place_value/0.01'}
                  width={displayMode === 'digital' ? 60 : 90}
                />
              }
            />
          </View>
        )}
        sentence={sentence}
        sentenceStyle={{ justifyContent: 'center' }}
        inputMaxCharacters={4}
        testCorrect={[answer.toString()]}
        extraSymbol="decimalPoint"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aVp',
  description: 'aVp',
  keywords: ['Decimal', 'Integer', 'Division', 'Divide'],
  schema: z.object({
    divisor: z.number().int().min(2).max(10),
    answerA: z.number().int().min(2).max(10),
    calcLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 10);
    const answerA = randomIntegerInclusive(2, 10, { constraint: x => divisor * x > 10 });
    const calcLeft = getRandomBoolean();

    return {
      divisor,
      answerA,
      calcLeft
    };
  },
  Component: props => {
    const {
      question: { divisor, answerA, calcLeft },
      translate
    } = props;

    const answerB = answerA / 10;
    const answerC = answerA / 100;

    const numberA = number(math.evaluate(`${divisor} * ${answerA}`));
    const numberB = number(math.evaluate(`${divisor} * ${answerB}`));
    const numberC = number(math.evaluate(`${divisor} * ${answerC}`));

    const answerOptions = shuffle([answerA, answerB, answerC], {
      random: seededRandom(props.question)
    });

    const sentences = calcLeft
      ? [
          {
            sentence: `${numberA.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`,
            answer: answerA
          },
          {
            sentence: `${numberB.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`,
            answer: answerB
          },
          {
            sentence: `${numberC.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`,
            answer: answerC
          }
        ]
      : [
          {
            sentence: `<ans/> = ${numberA.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`,
            answer: answerA
          },
          {
            sentence: `<ans/> = ${numberB.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`,
            answer: answerB
          },
          {
            sentence: `<ans/> = ${numberC.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`,
            answer: answerC
          }
        ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsCompleteCalculationsEachCardOnlyUsedOnce()}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        actionPanelVariant="end"
        pdfLayout="itemsRight"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aVq',
  description: 'aVq',
  keywords: ['Decimal', 'Integer', 'Division', 'Divide'],
  schema: z.object({
    number1: z.number().min(2).max(6),
    number2: z.number().min(2).max(6),
    decimal: z.number().min(1).max(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 6);
    const number2 = randomIntegerInclusive(2, 6);
    const decimal = randomIntegerInclusive(1, 5);

    return { number1, number2, decimal };
  },
  Component: ({ question: { number1, number2, decimal }, translate }) => {
    const answer = number(math.evaluate(`${number2} + ${decimal / 10}`));
    const pvc = number(math.evaluate(`${number1} * ${answer}`));

    return (
      <ReadPlaceValueChart
        title={translate.instructions.workOutTheDivision()}
        number={ScientificNotation.fromNumber(pvc)}
        columnsToShow={[1, 0, -1]}
        counterVariant="decimalCounter"
        testCorrect={userAnswer => compareFloats(userAnswer.toString(), answer.toString())}
        sentence={`${pvc.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toString()]
        }}
        questionHeight={1050}
      />
    );
  },
  questionHeight: 1050
});

const Question4 = newQuestionContent({
  uid: 'aVr',
  description: 'aVr',
  keywords: ['Decimal', 'Integer', 'Division', 'Divide'],
  schema: z.object({
    divisorA: z.number().int().min(2).max(9),
    answerA: z.number().min(1.1).max(9.9).multipleOf(0.1),
    divisorB: z.number().int().min(2).max(9),
    answerB: z.number().min(10.1).max(99.9).multipleOf(0.1),
    divisorC: z.number().int().min(2).max(9),
    answerC: z.number().min(2.01).max(49.99).multipleOf(0.01),
    calcLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const divisorA = randomIntegerInclusive(2, 9);
    const answerA =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const { divisorB, answerB } = rejectionSample(
      () => {
        const divisorB = randomIntegerInclusive(2, 9);
        const answerB =
          randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 10;

        return { divisorB, answerB };
      },
      ({ divisorB, answerB }) =>
        number(math.evaluate(`${divisorB} * ${answerB}`)) > 80 &&
        number(math.evaluate(`${divisorB} * ${answerB}`)) < 360
    );

    const divisorC = randomIntegerInclusive(2, 9);
    const answerC =
      randomIntegerInclusive(201, 4999, {
        constraint: x => x % 100 !== 0
      }) / 100;
    const calcLeft = getRandomBoolean();

    return {
      divisorA,
      answerA,
      divisorB,
      answerB,
      divisorC,
      answerC,
      calcLeft
    };
  },
  Component: props => {
    const {
      question: { divisorA, answerA, divisorB, answerB, divisorC, answerC, calcLeft },
      translate
    } = props;

    const numberA = number(math.evaluate(`${divisorA} * ${answerA}`));
    const numberB = number(math.evaluate(`${divisorB} * ${answerB}`));
    const numberC = number(math.evaluate(`${divisorC} * ${answerC}`));

    const sentences = calcLeft
      ? [
          {
            sentence: `${numberA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()} = <ans/>`,
            answer: answerA
          },
          {
            sentence: `${numberB.toLocaleString()} ${DIV} ${divisorB.toLocaleString()} = <ans/>`,
            answer: answerB
          },
          {
            sentence: `${numberC.toLocaleString()} ${DIV} ${divisorC.toLocaleString()} = <ans/>`,
            answer: answerC
          }
        ]
      : [
          {
            sentence: `<ans/> = ${numberA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()}`,
            answer: answerA
          },
          {
            sentence: `<ans/> = ${numberB.toLocaleString()} ${DIV} ${divisorB.toLocaleString()}`,
            answer: answerB
          },
          {
            sentence: `<ans/> = ${numberC.toLocaleString()} ${DIV} ${divisorC.toLocaleString()}`,
            answer: answerC
          }
        ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheDivisions()}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sentences[0].answer.toString()) &&
          compareFloats(userAnswer[1][0], sentences[1].answer.toString()) &&
          compareFloats(userAnswer[2][0], sentences[2].answer.toString())
        }
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        customMarkSchemeAnswer={{
          answersToDisplay: sentences.map(({ answer }) => [answer.toString()])
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aVr2',
  description: 'aVr2',
  keywords: ['Decimal', 'Integer', 'Division', 'Divide'],
  schema: z.object({
    divisor: z.number().int().min(2).max(9),
    quotient: z.number().min(1.1).max(99.9),
    calcLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const sentence = getRandomFromArrayWithWeights(['A', 'B', 'C'], [2, 1, 1]);

    const { divisor, quotient } = rejectionSample(
      () => {
        const divisor = randomIntegerInclusive(2, 9);

        const quotient =
          // Sentence A: quotient must be 1.1 to 9.9, steps of 0.1 excluding whole numbers:
          sentence === 'A'
            ? randomIntegerInclusive(11, 99, {
                constraint: x => x % 10 !== 0
              }) / 10
            : // Sentence B: quotient must be 10.1 to 99.9, steps of 0.1 excluding whole numbers:
            sentence === 'B'
            ? randomIntegerInclusive(101, 999, {
                constraint: x => x % 10 !== 0
              }) / 10
            : // Sentence C: quotient must be 2.01 to 49.99, steps of 0.01 excluding steps of 0.1:
              randomIntegerInclusive(201, 4999, {
                constraint: x => x % 10 !== 0
              }) / 100;

        return { divisor, quotient };
      },
      ({ divisor, quotient }) => {
        if (sentence === 'B') {
          return (
            number(math.evaluate(`${divisor} * ${quotient}`)) > 80 &&
            number(math.evaluate(`${divisor} * ${quotient}`)) < 360
          );
        } else {
          return true;
        }
      }
    );

    const calcLeft = getRandomBoolean();

    return {
      divisor,
      quotient,
      calcLeft
    };
  },
  Component: props => {
    const {
      question: { divisor, quotient, calcLeft },
      translate
    } = props;

    const dividend = number(math.evaluate(`${divisor} * ${quotient}`));

    const sentence = calcLeft
      ? `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`
      : `<ans/> = ${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheDivision()}
        sentence={sentence}
        testCorrect={userAnswer => compareFloats(userAnswer[0], quotient)}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        customMarkSchemeAnswer={{
          answersToDisplay: [quotient.toLocaleString()]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aVs',
  description: 'aVs',
  keywords: ['Decimal', 'Integer', 'Division', 'Divide'],
  schema: z.object({
    divisorA: z.number().int().min(2).max(9),
    answerA: z.number().min(1.1).max(9.9).multipleOf(0.01),
    divisorB: z.number().int().min(2).max(9),
    answerB: z.number().min(1.1).max(9.9).multipleOf(0.01),
    divisorC: z.number().int().min(2).max(9),
    answerC: z.number().min(1.1).max(9.9).multipleOf(0.01),
    offset1: z.number().min(0.1).max(0.9),
    offset2: z.number().min(0.1).max(0.9),
    offset3: z.number().min(0.1).max(0.9),
    offset4: z.number().min(0.1).max(0.9),
    offset5: z.number().min(0.1).max(0.9),
    offset6: z.number().min(0.1).max(0.9),
    calcLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const { divisorA, divisorB, divisorC } = rejectionSample(
      () => {
        const divisorA = randomIntegerInclusive(2, 9);
        const divisorB = randomIntegerInclusive(2, 9);
        const divisorC = randomIntegerInclusive(2, 9);

        return { divisorA, divisorB, divisorC };
      },
      ({ divisorA, divisorB, divisorC }) =>
        divisorA < divisorB && divisorB < divisorC && divisorC - divisorA < 3
    );

    const answerA =
      randomIntegerInclusive(110, 990, {
        constraint: x => x % 10 !== 0
      }) / 100;

    const answerB =
      randomIntegerInclusive(110, 990, {
        constraint: x => x % 10 !== 0
      }) / 100;

    const answerC =
      randomIntegerInclusive(110, 990, {
        constraint: x => x % 10 !== 0
      }) / 100;

    const [offset1, offset2, offset3, offset4, offset5, offset6] = range(0, 6).map(() =>
      randomNumberInclusive(0.1, 0.9, 1)
    );

    const calcLeft = getRandomBoolean();

    return {
      divisorA,
      answerA,
      divisorB,
      answerB,
      divisorC,
      answerC,
      offset1,
      offset2,
      offset3,
      offset4,
      offset5,
      offset6,
      calcLeft
    };
  },
  Component: props => {
    const {
      question: {
        divisorA,
        answerA,
        divisorB,
        answerB,
        divisorC,
        answerC,
        offset1,
        offset2,
        offset3,
        offset4,
        offset5,
        offset6,
        calcLeft
      },
      translate
    } = props;

    const numberA = number(math.evaluate(`${divisorA} * ${answerA}`));
    const numberB = number(math.evaluate(`${divisorB} * ${answerB}`));
    const numberC = number(math.evaluate(`${divisorC} * ${answerC}`));

    const incorrectOptions = shuffle(
      [
        number(math.evaluate(`${answerA} + ${offset1}`)),
        number(math.evaluate(`${answerA} - ${offset2}`)),
        number(math.evaluate(`${answerB} + ${offset3}`)),
        number(math.evaluate(`${answerB} - ${offset4}`)),
        number(math.evaluate(`${answerC} + ${offset5}`)),
        number(math.evaluate(`${answerC} - ${offset6}`))
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const items = shuffle([...incorrectOptions, answerA, answerB, answerC], {
      random: seededRandom(props.question)
    });

    const sentences = shuffle(
      calcLeft
        ? [
            {
              sentence: `${numberA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()} = <ans/>`,
              answer: answerA
            },
            {
              sentence: `${numberB.toLocaleString()} ${DIV} ${divisorB.toLocaleString()} = <ans/>`,
              answer: answerB
            },
            {
              sentence: `${numberC.toLocaleString()} ${DIV} ${divisorC.toLocaleString()} = <ans/>`,
              answer: answerC
            }
          ]
        : [
            {
              sentence: `<ans/> = ${numberA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()}`,
              answer: answerA
            },
            {
              sentence: `<ans/> = ${numberB.toLocaleString()} ${DIV} ${divisorB.toLocaleString()}`,
              answer: answerB
            },
            {
              sentence: `<ans/> = ${numberC.toLocaleString()} ${DIV} ${divisorC.toLocaleString()}`,
              answer: answerC
            }
          ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteDivisions()}
        pdfTitle={translate.instructions.useCardsCompleteDivisionsEachCardOnlyUsedOnce()}
        items={items}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        actionPanelVariant="endWide"
        pdfLayout="itemsTop"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5v2 = newQuestionContent({
  uid: 'aVs2',
  description: 'aVs',
  keywords: ['Decimal', 'Integer', 'Division', 'Divide'],
  schema: z.object({
    answerArray: z.array(z.number().min(1.1).max(9.9).multipleOf(0.01)).length(3),
    divisorArray: z.array(z.number().int().min(2).max(9)).length(3),
    draggableOptions: z.array(z.number().min(0.1).max(10.9)).length(9),
    calcLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const calcLeft = getRandomBoolean();
    const { divisorA, divisorB, divisorC } = rejectionSample(
      () => {
        const divisorA = randomIntegerInclusive(2, 9);
        const divisorB = randomIntegerInclusive(2, 9);
        const divisorC = randomIntegerInclusive(2, 9);

        return { divisorA, divisorB, divisorC };
      },
      ({ divisorA, divisorB, divisorC }) =>
        divisorA < divisorB && divisorB < divisorC && divisorC - divisorA < 3
    );

    const [answerA, answerB, answerC] = randomUniqueIntegersInclusive(110, 990, 3, {
      constraint: x => x % 10 !== 0
    }).map(x => x / 100);

    const answerArray = shuffle([answerA, answerB, answerC]);
    const divisorArray = shuffle([divisorA, divisorB, divisorC]);

    const [offset1, offset2, offset3, offset4, offset5, offset6] = range(0, 6).map(() =>
      randomNumberInclusive(0.1, 0.9, 1)
    );

    const draggableOptions = shuffle([
      ...answerArray,
      number(math.evaluate(`${answerA} + ${offset1}`)),
      number(math.evaluate(`${answerA} - ${offset2}`)),
      number(math.evaluate(`${answerB} + ${offset3}`)),
      number(math.evaluate(`${answerB} - ${offset4}`)),
      number(math.evaluate(`${answerC} + ${offset5}`)),
      number(math.evaluate(`${answerC} - ${offset6}`))
    ]);

    return {
      answerArray,
      divisorArray,
      draggableOptions,
      calcLeft
    };
  },
  Component: props => {
    const {
      question: { answerArray, divisorArray, draggableOptions, calcLeft },
      translate
    } = props;

    const numArray = answerArray.map((ans, i) =>
      number(math.evaluate(`${divisorArray[i]} * ${ans}`))
    );

    const sentences = answerArray.map((ans, i) => ({
      lhs: calcLeft
        ? `${numArray[i].toLocaleString()} ${DIV} ${divisorArray[i].toLocaleString()}`
        : `<ans/>`,
      rhs: calcLeft
        ? `<ans/>`
        : `${numArray[i].toLocaleString()} ${DIV} ${divisorArray[i].toLocaleString()}`,
      answer: ans
    }));

    const answerOptions = draggableOptions.map(value => ({
      value,
      component: value.toLocaleString()
    }));

    return (
      <QF2DraggableAlignedEquations
        title={translate.instructions.dragCardsCompleteDivisions()}
        pdfTitle={translate.instructions.useCardsCompleteDivisionsEachCardOnlyUsedOnce()}
        actionPanelVariant="endWide"
        items={answerOptions}
        sentences={sentences.map(({ lhs, rhs }) => ({
          left: lhs,
          right: rhs
        }))}
        testCorrect={sentences.map(({ answer }) => ({
          left: calcLeft ? [answer] : undefined,
          right: calcLeft ? undefined : [answer]
        }))}
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

const Question6 = newQuestionContent({
  uid: 'aVt',
  description: 'aVt',
  keywords: ['Decimal', 'Integer', 'Division', 'Divide'],
  schema: z.object({
    divisor: z.number().int().min(2).max(9),
    answer: z.number().min(10.01).max(49.99).multipleOf(0.01),
    offset1: z.number().min(0.1).max(0.9),
    offset2: z.number().min(0.1).max(0.9),
    offset3: z.number().min(0.1).max(0.9),
    offset4: z.number().min(0.1).max(0.9),
    offset5: z.number().min(0.1).max(0.9),
    offset6: z.number().min(0.1).max(0.9)
  }),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 9);

    const answer =
      randomIntegerInclusive(1001, 4999, {
        constraint: x => x % 100 !== 0
      }) / 100;

    const { offset1, offset2, offset3, offset4, offset5, offset6 } = rejectionSample(
      () => {
        const [offset1, offset2, offset3, offset4, offset5, offset6] = range(0, 6).map(() =>
          randomNumberInclusive(0.1, 0.9, 1)
        );
        return { offset1, offset2, offset3, offset4, offset5, offset6 };
      },
      ({ offset1, offset2, offset5, offset6 }) => offset1 !== offset5 && offset2 !== offset6
    );

    return {
      divisor,
      answer,
      offset1,
      offset2,
      offset3,
      offset4,
      offset5,
      offset6
    };
  },
  Component: props => {
    const {
      question: { divisor, answer, offset1, offset2, offset3, offset4, offset5, offset6 },
      translate
    } = props;

    const num = number(math.evaluate(`${divisor} * ${answer}`));
    const incorrectAnswer = number(math.evaluate(`${num} * ${divisor}`));
    const incorrectOptions = shuffle(
      [
        incorrectAnswer,
        number(math.evaluate(`${answer} + ${offset1}`)),
        number(math.evaluate(`${answer} - ${offset2}`)),
        number(math.evaluate(`${incorrectAnswer} + ${offset3}`)),
        number(math.evaluate(`${incorrectAnswer} - ${offset4}`)),
        answer / 10,
        number(math.evaluate(`${answer} + ${offset5}`)),
        number(math.evaluate(`${answer} - ${offset6}`))
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const statements = shuffle(
      [
        {
          value: answer.toString(),
          component: <Text variant="WRN700">{answer.toLocaleString()}</Text>
        },
        {
          value: incorrectOptions[0].toString(),
          component: <Text variant="WRN700">{incorrectOptions[0].toLocaleString()}</Text>
        },
        {
          value: incorrectOptions[1].toString(),
          component: <Text variant="WRN700">{incorrectOptions[1].toLocaleString()}</Text>
        },
        {
          value: incorrectOptions[2].toString(),
          component: <Text variant="WRN700">{incorrectOptions[2].toLocaleString()}</Text>
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectCorrectAnswerDivision()}
        pdfTitle={translate.instructions.circleCorrectAnswerDivision()}
        numItems={4}
        Content={() => (
          <ContentBox containerStyle={{ padding: 50 }}>
            <Text variant="WRN400">
              {`${num.toLocaleString()} ${DIV} ${divisor.toLocaleString()} =`}
            </Text>
          </ContentBox>
        )}
        renderItems={statements}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideDecimalsByIntegers',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question4, Question5]
});
export default SmallStep;
