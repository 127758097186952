import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  filledArray,
  multisetCount,
  sortNumberArray,
  sumNumberArray
} from '../../../../utils/collections';
import { displayMoney, totalPenceToPoundsAndPence } from '../../../../utils/money';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bic',
  description: 'bic',
  keywords: ['Count', 'Multiplication', 'Coins', 'Notes', 'Pounds'],
  schema: z.object({
    moneyValue: numberEnum([100, 200, 500, 1000, 2000, 5000]),
    totalNumberOfCoins: z.number().int().min(2).max(12),
    randomA: z.number().int().min(1).max(1000),
    randomB: z.number().int().min(1).max(1000),
    showRandomArrangement: z.boolean(),
    isPound: z.boolean()
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const values = [100, 200, 500, 1000, 2000, 5000] as const;

    const moneyValue = getRandomFromArray(values);

    const totalNumberOfCoins =
      moneyValue === 5000
        ? 2
        : randomIntegerInclusive(2, moneyValue === 1000 ? 10 : moneyValue === 2000 ? 5 : 12);

    const showRandomArrangement = getRandomFromArrayWithWeights([false, true], [3, 1]);

    const randomA = randomIntegerInclusive(1, 1000);
    const randomB = randomIntegerInclusive(1, 1000);

    const isPound = getRandomBoolean();

    return {
      moneyValue,
      totalNumberOfCoins,
      showRandomArrangement,
      randomA,
      randomB,
      isPound
    };
  },
  Component: props => {
    const {
      question: {
        moneyValue,
        totalNumberOfCoins,
        showRandomArrangement,
        randomA,
        randomB,
        isPound
      },
      translate,
      displayMode
    } = props;

    const denominations = filledArray(moneyValue, totalNumberOfCoins).map(
      number => totalPenceToPoundsAndPence(number)[0]
    );

    const coinSvgs = displayMoney(
      denominations,
      displayMode === 'digital' ? 80 : 150,
      displayMode === 'digital' ? 80 : 150,
      true
    );

    const arrangement = getRandomBooleanArray(
      4,
      4,
      denominations.length,
      seededRandom({ randomA, randomB })
    );

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchMoneyIsThere()}
        questionHeight={1200}
        Content={({ dimens }) =>
          showRandomArrangement ? (
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              {arrangement.map((row, rowIndex) => (
                <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                  {row.map((cell, colIndex) => (
                    <View
                      key={`cell-${rowIndex}-${colIndex}`}
                      style={{
                        width: dimens.width / 4,
                        height: dimens.height / 4,
                        padding: 8
                      }}
                    >
                      {cell ? coinSvgs[0] : null}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          ) : (
            <View
              style={{
                flexDirection: 'row',
                gap: 30,
                flexWrap: 'wrap',
                alignItems: 'center'
              }}
            >
              {displayMoney(
                denominations,
                displayMode === 'digital' ? 80 : 120,
                displayMode === 'digital' ? 80 : 120,
                true
              )}
            </View>
          )
        }
        sentence={
          isPound
            ? translate.ks1AnswerSentences.poundAns()
            : translate.ks1AnswerSentences.ansPounds()
        }
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[((moneyValue / 100) * totalNumberOfCoins).toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bid',
  description: 'bid',
  keywords: ['Count', 'Multiplication', 'Coins', 'Pence'],
  schema: z.object({
    combinationOfMoney: z
      .array(z.number())
      .refine(combinationOfMoney => isInRange(200, 10000)(sumNumberArray(combinationOfMoney))),
    isPound: z.boolean()
  }),
  simpleGenerator: () => {
    const sortCoinsDescending = getRandomFromArrayWithWeights([true, false], [3, 1]);

    const { orderedCombinationOfMoney } = rejectionSample(
      () => {
        const numberOfTypesOfCoins = getRandomFromArray([2, 3]);
        const coinValues = getRandomSubArrayFromArray(
          [100, 200, 500, 1000, 2000, 5000],
          numberOfTypesOfCoins
        );

        const numberOfCoins = randomIntegerInclusive(3, 8);

        const combinationOfMoney = [
          ...coinValues,
          ...filledArray(
            getRandomFromArray(coinValues) as number,
            numberOfCoins - coinValues.length
          )
        ];

        const groupsOfCoins = Array.from(multisetCount(combinationOfMoney)).map(([value, amount]) =>
          filledArray(value, amount)
        );

        const orderedCombinationOfMoney = sortCoinsDescending
          ? sortNumberArray(combinationOfMoney, 'descending')
          : shuffle(groupsOfCoins).flat();

        return { orderedCombinationOfMoney };
      },
      ({ orderedCombinationOfMoney }) =>
        isInRange(200, 10000)(sumNumberArray(orderedCombinationOfMoney)) &&
        isInRange(3, 8)(orderedCombinationOfMoney.length)
    );

    const isPound = getRandomBoolean();

    return {
      combinationOfMoney: orderedCombinationOfMoney,
      isPound
    };
  },
  Component: props => {
    const {
      question: { combinationOfMoney, isPound },
      translate,
      displayMode
    } = props;

    const denominations = combinationOfMoney.map(number => totalPenceToPoundsAndPence(number)[0]);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchMoneyIsThere()}
        Content={
          <View
            style={{
              flexDirection: 'row',
              gap: 30,
              flexWrap: 'wrap',
              alignItems: 'center'
            }}
          >
            {displayMoney(
              denominations,
              displayMode === 'digital' ? 80 : 120,
              displayMode === 'digital' ? 80 : 120,
              true,
              true
            )}
          </View>
        }
        sentence={
          isPound
            ? translate.ks1AnswerSentences.poundAns()
            : translate.ks1AnswerSentences.ansPounds()
        }
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(sumNumberArray(combinationOfMoney) / 100).toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bie',
  description: 'bie',
  keywords: [
    'Count',
    'Coins',
    'Greater than',
    'Less than',
    'Equal to',
    'Compare',
    'Pounds',
    'Notes',
    'Coins'
  ],
  schema: z.object({
    optionAValue: numberEnum([100, 200, 500, 1000, 2000, 5000]),
    optionBValue: numberEnum([100, 200, 500, 1000, 2000, 5000]),
    optionAAmount: z.number().int().min(1).max(12),
    optionBAmount: z.number().int().min(1).max(12)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const [optionAValue, optionBValue] = getRandomSubArrayFromArray(
      [100, 200, 500, 1000, 2000, 5000] as const,
      2
    );

    const getOptionAmount = (
      optionValue: 100 | 200 | 500 | 1000 | 5000 | 2000,
      allowOne = true
    ) => {
      switch (optionValue) {
        case 100:
        case 200:
        case 500:
          return randomIntegerInclusive(allowOne ? 1 : 2, 12);
        case 1000:
          return randomIntegerInclusive(allowOne ? 1 : 2, 10);
        case 2000:
          return randomIntegerInclusive(allowOne ? 1 : 2, 5);
        default:
          return allowOne ? randomIntegerInclusive(1, 2) : 2;
      }
    };

    const optionAAmount = getOptionAmount(optionAValue);
    const optionBAmount = getOptionAmount(optionBValue, optionAAmount !== 1);

    return { optionAAmount, optionBAmount, optionAValue, optionBValue };
  },
  Component: ({
    question: { optionAAmount, optionBAmount, optionAValue, optionBValue },
    translate,
    displayMode
  }) => {
    const moneySvgs = displayMoney(
      [
        ...filledArray(totalPenceToPoundsAndPence(optionAValue)[0], optionAAmount),
        ...filledArray(totalPenceToPoundsAndPence(optionBValue)[0], optionBAmount)
      ],
      displayMode === 'digital' ? 70 : 120,
      displayMode === 'digital' ? 70 : 120,
      true
    );

    const lhsSvgs = moneySvgs.filter((_, index) => index <= optionAAmount - 1);
    const rhsSvgs = moneySvgs.filter((_, index) => index > optionAAmount - 1);

    const lhsComponent = (
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: displayMode === 'digital' ? 450 : 600,
          gap: 10
        }}
      >
        {lhsSvgs}
      </View>
    );

    const rhsComponent = (
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: displayMode === 'digital' ? 450 : 600,
          gap: 10
        }}
      >
        {rhsSvgs}
      </View>
    );

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheAmounts()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheAmounts()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        questionHeight={1200}
        statements={[
          {
            lhsComponent,
            rhsComponent,
            correctAnswer: lessThanGreaterThanOrEqualTo(
              optionAValue * optionAAmount,
              optionBValue * optionBAmount
            )
          }
        ]}
        statementStyle={{ justifyContent: 'center', gap: 16 }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountMoneyPoundsNotesAndCoins',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
