import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { DIV } from '../../../../constants';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF27bShortDivision from '../../../../components/question/questionFormats/QF27bShortDivision';
import { isDivisionExchangesOnlyAt } from '../../../../utils/exchanges';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aPi',
  description: 'aPi',
  keywords: ['Short division', 'Division', 'Remainder'],
  schema: z
    .object({
      divisorA: z.number().int().min(2).max(12),
      multiplierA: z.number().int().min(1).max(12),
      divisorB: z.number().int().min(2).max(12),
      multiplierB: z.number().int().min(1).max(12),
      divisorC: z.number().int().min(2).max(12),
      multiplierC: z.number().int().min(1).max(12),
      divisorD: z.number().int().min(2).max(12),
      multiplierD: z.number().int().min(1).max(12),
      remainderD: z.number().min(0).max(11),
      divisorE: z.number().int().min(2).max(12),
      multiplierE: z.number().int().min(1).max(12),
      remainderE: z.number().min(0).max(11),
      divisorF: z.number().int().min(2).max(12),
      multiplierF: z.number().int().min(1).max(12),
      remainderF: z.number().min(0).max(11),
      divisorG: z.number().int().min(2).max(12),
      multiplierG: z.number().int().min(1).max(12),
      remainderG: z.number().min(0).max(11)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.divisorA,
          val.divisorB,
          val.divisorC,
          val.divisorD,
          val.divisorE,
          val.divisorF,
          val.divisorG
        ]),
      'All divisors must be different.'
    )
    .refine(
      val =>
        val.remainderD < val.divisorD &&
        val.remainderE < val.divisorE &&
        val.remainderF < val.divisorF &&
        val.remainderG < val.divisorG,
      "All remainders must be less than their equation's divisor."
    ),
  simpleGenerator: () => {
    const [divisorA, divisorB, divisorC, divisorD, divisorE, divisorF, divisorG] =
      randomUniqueIntegersInclusive(2, 12, 7);

    const [
      multiplierA,
      multiplierB,
      multiplierC,
      multiplierD,
      multiplierE,
      multiplierF,
      multiplierG
    ] = range(1, 7).map(() => randomIntegerInclusive(1, 12));

    const remainderD = randomIntegerInclusive(1, divisorD - 1);
    const remainderE = randomIntegerInclusive(1, divisorE - 1);
    const remainderF = randomIntegerInclusive(1, divisorF - 1);

    // 50-50 chance of equation G having a remainder:
    const equationGHasRemainder = getRandomBoolean();

    const remainderG = equationGHasRemainder ? randomIntegerInclusive(1, divisorG - 1) : 0;

    return {
      divisorA,
      multiplierA,
      divisorB,
      multiplierB,
      divisorC,
      multiplierC,
      divisorD,
      multiplierD,
      remainderD,
      divisorE,
      multiplierE,
      remainderE,
      divisorF,
      multiplierF,
      remainderF,
      divisorG,
      multiplierG,
      remainderG
    };
  },
  Component: props => {
    const {
      question: {
        divisorA,
        multiplierA,
        divisorB,
        multiplierB,
        divisorC,
        multiplierC,
        divisorD,
        multiplierD,
        remainderD,
        divisorE,
        multiplierE,
        remainderE,
        divisorF,
        multiplierF,
        remainderF,
        divisorG,
        multiplierG,
        remainderG
      },
      translate
    } = props;

    const dividendA = divisorA * multiplierA;
    const dividendB = divisorB * multiplierB;
    const dividendC = divisorC * multiplierC;
    const dividendD = divisorD * multiplierD + remainderD;
    const dividendE = divisorE * multiplierE + remainderE;
    const dividendF = divisorF * multiplierF + remainderF;
    const dividendG = divisorG * multiplierG + remainderG;

    const items = getRandomSubArrayFromArray(
      [
        {
          sentence: `${dividendA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()}`,
          isCorrect: false
        },
        {
          sentence: `${dividendB.toLocaleString()} ${DIV} ${divisorB.toLocaleString()}`,
          isCorrect: false
        },
        {
          sentence: `${dividendC.toLocaleString()} ${DIV} ${divisorC.toLocaleString()}`,
          isCorrect: false
        },
        {
          sentence: `${dividendD.toLocaleString()} ${DIV} ${divisorD.toLocaleString()}`,
          isCorrect: true
        },
        {
          sentence: `${dividendE.toLocaleString()} ${DIV} ${divisorE.toLocaleString()}`,
          isCorrect: true
        },
        {
          sentence: `${dividendF.toLocaleString()} ${DIV} ${divisorF.toLocaleString()}`,
          isCorrect: true
        },
        {
          sentence: `${dividendG.toLocaleString()} ${DIV} ${divisorG.toLocaleString()}`,
          isCorrect: remainderG !== 0
        }
      ],
      6,
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectDivisionsThatProduceARemainder()}
        pdfTitle={translate.instructions.circleDivisionsThatProduceARemainder()}
        testCorrect={items.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        items={items.map(equation => ({
          component: equation.sentence,
          value: equation.sentence
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aPj',
  description: 'aPj',
  keywords: ['Short division', 'Division', 'Remainder'],
  schema: z
    .object({
      divisorA: z.number().int().min(2).max(12),
      multiplierA: z.number().int().min(7).max(12),
      divisorB: z.number().int().min(2).max(12),
      multiplierB: z.number().int().min(7).max(12),
      divisorC: z.number().int().min(2).max(12),
      multiplierC: z.number().int().min(7).max(12),
      remainderC: z.number().min(1).max(11),
      divisorD: z.number().int().min(2).max(12),
      multiplierD: z.number().int().min(7).max(12),
      remainderD: z.number().min(1).max(11)
    })
    .refine(
      val => arrayHasNoDuplicates([val.divisorA, val.divisorB, val.divisorC, val.divisorD]),
      'All divisors must be different.'
    )
    .refine(
      val => val.remainderC < val.divisorC && val.remainderD < val.divisorD,
      "All remainders must be less than their equation's divisor."
    ),
  simpleGenerator: () => {
    const [divisorA, divisorB, divisorC, divisorD] = randomUniqueIntegersInclusive(2, 12, 4);

    const [multiplierA, multiplierB, multiplierC, multiplierD] = range(1, 4).map(() =>
      randomIntegerInclusive(7, 12)
    );

    const remainderC = randomIntegerInclusive(1, divisorC - 1);
    const remainderD = randomIntegerInclusive(1, divisorD - 1);

    return {
      divisorA,
      multiplierA,
      divisorB,
      multiplierB,
      divisorC,
      multiplierC,
      remainderC,
      divisorD,
      multiplierD,
      remainderD
    };
  },
  Component: props => {
    const {
      question: {
        divisorA,
        multiplierA,
        divisorB,
        multiplierB,
        divisorC,
        multiplierC,
        remainderC,
        divisorD,
        multiplierD,
        remainderD
      },
      translate
    } = props;

    const dividendA = divisorA * multiplierA;
    const dividendB = divisorB * multiplierB;
    const dividendC = divisorC * multiplierC + remainderC;
    const dividendD = divisorD * multiplierD + remainderD;

    const items = shuffle(
      [
        {
          sentence: `${dividendA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()}`,
          hasRemainder: false
        },
        {
          sentence: `${dividendB.toLocaleString()} ${DIV} ${divisorB.toLocaleString()}`,
          hasRemainder: false
        },
        {
          sentence: `${dividendC.toLocaleString()} ${DIV} ${divisorC.toLocaleString()}`,
          hasRemainder: true
        },
        {
          sentence: `${dividendD.toLocaleString()} ${DIV} ${divisorD.toLocaleString()}`,
          hasRemainder: true
        }
      ],
      { random: seededRandom(props.question) }
    );

    const equationsWithoutRemainder = items
      .filter(item => !item.hasRemainder)
      .map(item => item.sentence);

    const equationsWithRemainder = items
      .filter(item => item.hasRemainder)
      .map(item => item.sentence);

    const correctOrder = [equationsWithoutRemainder, equationsWithRemainder];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortCalculationsInTable()}
        pdfTitle={translate.instructions.useCardsToSortCalculationsInTable()}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        testCorrect={correctOrder}
        zoneNames={[translate.tableHeaders.noRemainder(), translate.tableHeaders.remainder()]}
        items={items.map(item => item.sentence)}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aPk',
  description: 'aPk',
  keywords: ['4-digit', 'Short division', 'Division', 'Remainder'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(4),
      dividend: z.number().int().min(2001).max(9999)
    })
    .refine(val => val.dividend % val.divisor !== 0, 'dividend must not be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);

    const dividend = randomIntegerInclusive(2001, 9999, {
      constraint: x =>
        x % divisor !== 0 && x % 10 !== 0 && isDivisionExchangesOnlyAt(x, divisor, [0])
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = Math.floor(dividend / divisor);

    const remainder = dividend % divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        remainder={remainder}
        remainderMissingDigits={range(0, remainder.toString().length - 1)}
        // Outer cells need removing, otherwise cells representation touches edges of the content space.
        removeExtraCells
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aPl',
  description: 'aPl',
  keywords: ['4-digit', 'Short division', 'Division', 'Remainder'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(2001).max(9999)
    })
    .refine(val => val.dividend % val.divisor !== 0, 'dividend must not be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 9);

    const exchangePosition = getRandomFromArray(['thousands', 'hundreds', 'tens'] as const);

    const dividend = randomIntegerInclusive(2001, 9999, {
      constraint: x =>
        x % divisor !== 0 &&
        x % 10 !== 0 &&
        isDivisionExchangesOnlyAt(x, divisor, [exchangePosition, 'ones'])
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = Math.floor(dividend / divisor);

    const remainder = dividend % divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        remainder={remainder}
        remainderMissingDigits={range(0, remainder.toString().length - 1)}
        // Outer cells need removing, otherwise cells representation touches edges of the content space.
        removeExtraCells
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aPm',
  description: 'aPm',
  keywords: ['4-digit', 'Short division', 'Division', 'Remainder'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(2001).max(9999)
    })
    .refine(val => val.dividend % val.divisor !== 0, 'dividend must not be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 9);

    const numberOfExchanges = randomIntegerInclusive(2, 3);

    const exchangePositions = getRandomSubArrayFromArray(
      ['thousands', 'hundreds', 'tens'] as const,
      numberOfExchanges
    );
    const dividend = randomIntegerInclusive(2001, 9999, {
      constraint: x =>
        x % divisor !== 0 &&
        x % 10 !== 0 &&
        isDivisionExchangesOnlyAt(x, divisor, [...exchangePositions, 'ones'])
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = Math.floor(dividend / divisor);

    const remainder = dividend % divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        remainder={remainder}
        remainderMissingDigits={range(0, remainder.toString().length - 1)}
        // Outer cells need removing, otherwise cells representation touches edges of the content space.
        removeExtraCells
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aPn',
  description: 'aPn',
  keywords: ['4-digit', 'Short division', 'Division', 'Remainder'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(2001).max(9999)
    })
    .refine(val => val.dividend % val.divisor !== 0, 'dividend must not be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 9);

    const numberOfExchanges = randomIntegerInclusive(2, 3);

    const exchangePositions = getRandomSubArrayFromArray(
      ['thousands', 'hundreds', 'tens'] as const,
      numberOfExchanges
    );

    const dividend = randomIntegerInclusive(2001, 9999, {
      constraint: x =>
        x % divisor !== 0 &&
        x % 10 !== 0 &&
        isDivisionExchangesOnlyAt(x, divisor, [...exchangePositions, 'ones'])
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = Math.floor(dividend / divisor);

    const remainder = dividend % divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutTheMissingDigits()}
        divisor={divisor}
        dividend={dividend}
        dividendMissingDigits={[0, 3]}
        quotient={quotient}
        quotientMissingDigits={[2]}
        remainder={remainder}
        showNumberExchanges
        // Outer cells need removing, otherwise cells representation touches edges of the content space.
        removeExtraCells
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideWithRemainders',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
