import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF30GroupCountersAndSentence from '../../../../components/question/questionFormats/QF30GroupCountersAndSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import {
  ArrayOfObjectsColors,
  barModelColors,
  barModelColorsArray,
  BarModelColorsKey
} from '../../../../theme/colors';
import { all, create, number } from 'mathjs';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { BarModel } from '../../../../components/question/representations/BarModel';
import Table from '../../../../components/molecules/Table';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import Text from '../../../../components/typography/Text';
import { simplify } from '../../../../utils/fractions';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { AssetSvg } from '../../../../assets/svg';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aPS',
  description: 'aPS',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction'],
  schema: z.object({
    totalCounters: z.number().int().min(2).max(20),
    denominator: z.number().int().min(2).max(4),
    numerator: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 4);
    const totalCounters = randomIntegerInclusive(2, 20, { constraint: x => x % denominator === 0 });
    const numerator = randomIntegerInclusive(1, denominator);

    return { totalCounters, denominator, numerator };
  },
  Component: ({ question: { totalCounters, denominator, numerator }, translate }) => {
    const answer = number(math.evaluate(`${totalCounters}/${denominator} * ${numerator}`));
    return (
      <QF30GroupCountersAndSentence
        title={translate.instructions.dragCountersToCreateEqualGroups(
          totalCounters.toLocaleString(),
          denominator.toLocaleString()
        )}
        sentence={translate.answerSentences.fracOfXNumberIsEqualToAns({
          frac: `<frac n="${numerator.toLocaleString()}" d="${denominator.toLocaleString()}" />`,
          number: totalCounters
        })}
        testCorrect={[answer.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'aPS2',
  description: 'aPS',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction'],
  schema: z
    .object({
      totalCounters: z.number().int().min(2).max(20),
      denominator: z.number().int().min(2).max(4),
      numerator: z.number().int().min(1).max(3)
    })
    .refine(
      val => val.totalCounters % val.denominator === 0,
      'totalCounters must be a multiple of denominator'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 4);
    const totalCounters = randomIntegerInclusive(2, 20, { constraint: x => x % denominator === 0 });
    const numerator = randomIntegerInclusive(1, denominator - 1);

    return { totalCounters, denominator, numerator };
  },
  Component: ({ question: { totalCounters, denominator, numerator }, translate }) => {
    const answer = number(math.evaluate(`${totalCounters}/${denominator} * ${numerator}`));
    return (
      <QF30GroupCountersAndSentence
        title={translate.instructions.dragCountersToCreateEqualGroups(
          totalCounters.toLocaleString(),
          denominator.toLocaleString()
        )}
        sentence={translate.answerSentences.fracOfXNumberIsEqualToAns({
          frac: `<frac n="${numerator}" d="${denominator}" />`,
          number: totalCounters
        })}
        testCorrect={[answer.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aPT',
  description: 'aPT',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z.object({
    total: z.number().int().min(2).max(48),
    denominator: z.number().int().min(2).max(8),
    numerator: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);
    const total = randomIntegerInclusive(2, 48, { constraint: x => x % denominator === 0 });
    const numerator = randomIntegerInclusive(2, denominator);
    return { denominator, total, numerator };
  },
  Component: props => {
    const {
      question: { denominator, total, numerator },
      translate
    } = props;

    const answer = number(math.evaluate(`${total}/${denominator} * ${numerator}`));

    const arrayOfSquares = filledArray(filledArray('white', denominator), 1);

    const countersPerSquare = number(math.evaluate(`${total}/${denominator}`));

    const counterColor = getRandomFromArray(Object.values(ArrayOfObjectsColors), {
      random: seededRandom(props.question)
    }) as string;

    const generateArrayData = (countersPerSquare: number): number[][] => {
      if (countersPerSquare === 2) {
        return [[1, 1]];
      }
      const halfCount = countersPerSquare / 2;
      const topRowCount = Math.ceil(halfCount);
      const bottomRowCount = Math.floor(halfCount);
      const topRow: number[] = [];
      const bottomRow: number[] = [];
      countRange(topRowCount).forEach(() => topRow.push(1));
      countRange(bottomRowCount).forEach(() => bottomRow.push(1));
      return [topRow, bottomRow];
    };
    const arrayData = generateArrayData(countersPerSquare);

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.theBarModelShowsHowXCountersHaveBeenSharedIntoYEqualGroups(
          total,
          denominator
        )}<br/>${translate.instructions.useBarModelToCompleteCalc()}`}
        testCorrect={[answer.toString()]}
        sentence={`<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${translate.misc.of()} ${total.toLocaleString()} = <ans/>`}
        sentenceStyle={{ alignSelf: 'center' }}
        Content={({ dimens }) => (
          <View>
            <Table
              style={{ alignItems: 'center' }}
              items={arrayOfSquares.map(rowColors =>
                rowColors.map(number => (
                  <View
                    key={number}
                    style={{
                      width: dimens.width / denominator,
                      height: dimens.height - 150,
                      alignItems: 'center',
                      justifyContent: 'space-around'
                    }}
                  >
                    {arrayData.map((arr, i) => {
                      return arr.length ? (
                        <ArrayOfObjects
                          key={i}
                          color={counterColor}
                          dimens={{
                            height: (dimens.height - 150) / arrayData.length,
                            width: dimens.width / denominator
                          }}
                          rowStyle={{
                            justifyContent: 'space-evenly'
                          }}
                          rows={1}
                          counterSize={
                            Math.min(
                              dimens.width / denominator / arrayData[0].length,
                              (dimens.height - 150) / arrayData.length
                            ) - 10
                          }
                          columns={arr.length}
                        />
                      ) : null;
                    })}
                  </View>
                ))
              )}
            />
          </View>
        )}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aPT2',
  description: 'aPT2',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z
    .object({
      total: z.number().int().min(2).max(48),
      denominator: z.number().int().min(3).max(8),
      numerator: z.number().int().min(2).max(8)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);

    const total = randomIntegerInclusive(2, 48, { constraint: x => x % denominator === 0 });

    const numerator = randomIntegerInclusive(2, denominator - 1);

    return { denominator, total, numerator };
  },
  Component: props => {
    const {
      question: { denominator, total, numerator },
      translate,
      displayMode
    } = props;

    const answer = number(math.evaluate(`${total}/${denominator} * ${numerator}`));

    const arrayOfSquares = filledArray(filledArray('white', denominator), 1);

    const countersPerSquare = number(math.evaluate(`${total}/${denominator}`));

    const counterColor = getRandomFromArray(Object.values(ArrayOfObjectsColors), {
      random: seededRandom(props.question)
    }) as string;

    const generateArrayData = (countersPerSquare: number): number[][] => {
      if (countersPerSquare === 2) {
        return [[1, 1]];
      }
      const halfCount = countersPerSquare / 2;
      const topRowCount = Math.ceil(halfCount);
      const bottomRowCount = Math.floor(halfCount);
      const topRow: number[] = [];
      const bottomRow: number[] = [];
      countRange(topRowCount).forEach(() => topRow.push(1));
      countRange(bottomRowCount).forEach(() => bottomRow.push(1));
      return [topRow, bottomRow];
    };
    const arrayData = generateArrayData(countersPerSquare);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theBarModelShowsNumXCountersSharedIntoNumYEqualGroupsUseTheBarModel(
          total,
          denominator
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.fractionOfNumberEqualsAns({
          frac: `<frac n='${numerator}' d='${denominator}' />`,
          number: total
        })}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View>
            <Table
              style={{ alignItems: 'center' }}
              items={arrayOfSquares.map(rowColors =>
                rowColors.map(number => (
                  <View
                    key={number}
                    style={{
                      width: dimens.width / denominator,
                      height: dimens.height - 150,
                      alignItems: 'center',
                      justifyContent: 'space-around'
                    }}
                  >
                    {arrayData.map((arr, i) => {
                      return arr.length ? (
                        <ArrayOfObjects
                          key={i}
                          color={counterColor}
                          dimens={{
                            height: (dimens.height - 150) / arrayData.length,
                            width: dimens.width / denominator
                          }}
                          rowStyle={{
                            justifyContent: 'space-evenly'
                          }}
                          rows={1}
                          counterSize={
                            Math.min(
                              dimens.width / denominator / arrayData[0].length,
                              (dimens.height - 150) / arrayData.length
                            ) - 10
                          }
                          columns={arr.length}
                        />
                      ) : null;
                    })}
                  </View>
                ))
              )}
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aPU',
  description: 'aPU',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z.object({
    total: z.number().int().min(2).max(100),
    denominator: z.number().int().min(2).max(6),
    numerator: z.number().int().min(1).max(10),
    incorrectNumerator: z.number().int().min(1).max(100)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 6);
    const total = randomIntegerInclusive(10, 100, {
      constraint: x => x % denominator === 0 && number(x.toString()[1]) % denominator === 0
    });
    const numerator = randomIntegerInclusive(2, denominator);

    const incorrectNumerator = randomIntegerInclusive(1, 10, {
      constraint: x => x !== numerator
    });

    return { denominator, total, numerator, incorrectNumerator };
  },
  Component: props => {
    const {
      question: { denominator, total, numerator, incorrectNumerator },
      translate,
      displayMode
    } = props;

    const correctAnswer = number(math.evaluate(`${total}/${denominator} * ${numerator}`));
    const incorrectAnswer = number(
      math.evaluate(`${total}/${denominator} * ${incorrectNumerator}`)
    );

    const statements = shuffle(
      [
        {
          value: correctAnswer,
          text: correctAnswer.toLocaleString()
        },
        {
          value: incorrectAnswer,
          text: incorrectAnswer.toLocaleString()
        }
      ],
      { random: seededRandom(props.question) }
    );

    const arrayOfSquares = filledArray(filledArray('white', denominator), 1);

    const numberOfTensPer = Math.floor(total / denominator / 10);
    const numberOfOnesPer = total / denominator - numberOfTensPer * 10;

    const amountOfCounters = numberOfTensPer + numberOfOnesPer;

    const space = numberOfTensPer ? 3 : 2;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.useTheBarModelToFindFracOfTotalX(
          `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
          total
        )}
        testCorrect={[correctAnswer]}
        numItems={2}
        itemLayout="row"
        Content={({ dimens }) => (
          <View>
            <Table
              style={{ alignItems: 'center' }}
              items={arrayOfSquares.map(rowColors =>
                rowColors.map(number => (
                  <View
                    key={number}
                    style={{
                      width: (dimens.width * 0.95) / denominator,
                      height: dimens.height - 150,
                      alignItems: 'center',
                      justifyContent: 'space-evenly'
                    }}
                  >
                    {numberOfTensPer ? (
                      <ArrayOfObjects
                        dimens={{
                          height: (dimens.height - 150) / space,
                          width: (dimens.width * 0.95) / denominator
                        }}
                        rowStyle={{
                          justifyContent: 'space-evenly'
                        }}
                        customImage={
                          <AssetSvg
                            height={
                              displayMode === 'digital'
                                ? denominator === 6 && amountOfCounters > 4
                                  ? 30
                                  : 35
                                : 60
                            }
                            name={'Place_value/10'}
                          />
                        }
                        rows={1}
                        columns={numberOfTensPer}
                      />
                    ) : null}
                    {numberOfOnesPer > 5 ? (
                      <>
                        <ArrayOfObjects
                          dimens={{
                            height: (dimens.height - 150) / space,
                            width: (dimens.width * 0.95) / denominator
                          }}
                          rowStyle={{
                            justifyContent: 'space-evenly'
                          }}
                          customImage={
                            <AssetSvg
                              height={
                                displayMode === 'digital'
                                  ? denominator === 6 && amountOfCounters > 4
                                    ? 30
                                    : 35
                                  : 60
                              }
                              name={'Place_value/1'}
                            />
                          }
                          rows={1}
                          columns={5}
                        />
                        <ArrayOfObjects
                          dimens={{
                            height: (dimens.height - 150) / space,
                            width: (dimens.width * 0.95) / denominator
                          }}
                          rowStyle={{
                            justifyContent: 'space-evenly'
                          }}
                          customImage={
                            <AssetSvg
                              height={
                                displayMode === 'digital'
                                  ? denominator === 6 && amountOfCounters > 4
                                    ? 30
                                    : 35
                                  : 60
                              }
                              name={'Place_value/1'}
                            />
                          }
                          rows={1}
                          columns={numberOfOnesPer - 5}
                        />
                      </>
                    ) : numberOfOnesPer ? (
                      <ArrayOfObjects
                        dimens={{
                          height: (dimens.height - 150) / space,
                          width: (dimens.width * 0.95) / denominator
                        }}
                        rowStyle={{
                          justifyContent: 'space-evenly'
                        }}
                        customImage={
                          <AssetSvg
                            height={
                              displayMode === 'digital'
                                ? denominator === 6 && amountOfCounters > 4
                                  ? 30
                                  : 35
                                : 60
                            }
                            name={'Place_value/1'}
                          />
                        }
                        rows={1}
                        columns={numberOfOnesPer}
                      />
                    ) : null}
                  </View>
                ))
              )}
            />
          </View>
        )}
        renderItems={statements.map(statement => ({
          value: statement.value,
          component: <Text variant="WRN700">{statement.text}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aPV',
  description: 'aPV',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z.object({
    total: z.number().int().min(2).max(100),
    denominator: z.number().int().min(2).max(10),
    numerator: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);
    const total = randomIntegerInclusive(2, 100, { constraint: x => x % denominator === 0 });
    const numerator = randomIntegerInclusive(1, denominator);
    return { denominator, total, numerator };
  },
  Component: props => {
    const {
      question: { denominator, total, numerator },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    });

    const numeratorColorArray = filledArray(
      barModelColors[numeratorColor as BarModelColorsKey],
      numerator
    );
    const remainder = filledArray('white', denominator - 1);

    const customColorMap = [...numeratorColorArray, ...remainder];

    const answer = number(math.evaluate(`${total}/${denominator} * ${numerator}`));
    const barModelString = number(math.evaluate(`${total}/${denominator}`)).toLocaleString();

    const numbers = [filledArray(1, denominator)];
    const strings = [filledArray(barModelString, denominator)];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheBarModelToHelpCompleteTheCalculation()}
        testCorrect={[answer.toString()]}
        sentence={`<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${translate.misc.of()} ${total.toLocaleString()} = <ans/>`}
        sentenceStyle={{ alignSelf: 'center' }}
        textStyle={{ fontSize: 40 }}
        Content={({ dimens }) => (
          <BarModel
            total={denominator}
            dimens={{ width: dimens.width, height: dimens.height }}
            numbers={numbers}
            strings={strings}
            sameRowColor
            cellColors={[customColorMap]}
            rowHeight={50}
            topBraceText={total}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aPW',
  description: 'aPW',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z.object({
    totalA: z.number().int().min(2).max(400),
    numeratorA: z.number().int().min(1).max(10),
    denominatorA: z.number().int().min(2).max(10),
    totalB: z.number().int().min(2).max(400),
    numeratorB: z.number().int().min(1).max(10),
    denominatorB: z.number().int().min(2).max(10),
    incorrectNumeratorA: z.number().int().min(1).max(10),
    incorrectNumeratorB: z.number().int().min(1).max(10),
    showFractionFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 10, 2);

    const numeratorA = randomIntegerInclusive(1, denominatorA);
    const numeratorB = randomIntegerInclusive(1, denominatorB);

    const totalA = randomIntegerInclusive(2, 400, { constraint: x => x % denominatorA === 0 });
    const totalB = randomIntegerInclusive(2, 400, { constraint: x => x % denominatorB === 0 });

    const incorrectNumeratorA = randomIntegerInclusive(1, 10, {
      constraint: x => x !== numeratorA && x * denominatorA < 400
    });
    const incorrectNumeratorB = randomIntegerInclusive(1, 10, {
      constraint: x => x !== numeratorB && x * denominatorB < 400
    });

    const showFractionFirst = getRandomBoolean();

    return {
      totalA,
      numeratorA,
      denominatorA,
      incorrectNumeratorA,
      totalB,
      numeratorB,
      denominatorB,
      incorrectNumeratorB,
      showFractionFirst
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const {
      totalA,
      numeratorA,
      denominatorA,
      incorrectNumeratorA,
      totalB,
      numeratorB,
      denominatorB,
      incorrectNumeratorB,
      showFractionFirst
    } = question;

    const correctAnswerA = number(math.evaluate(`${totalA}/${denominatorA} * ${numeratorA}`));
    const correctAnswerB = number(math.evaluate(`${totalB}/${denominatorB} * ${numeratorB}`));
    const incorrectAnswerA = number(math.evaluate(`${denominatorA} * ${incorrectNumeratorA}`));
    const incorrectAnswerB = number(math.evaluate(`${denominatorB} * ${incorrectNumeratorB}`));

    const statements = [
      {
        sentence: showFractionFirst
          ? `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> ${translate.misc.of()} ${totalA.toLocaleString()} = <ans/>`
          : `<ans/> = <frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> ${translate.misc.of()} ${totalA.toLocaleString()}`,
        answer: correctAnswerA
      },
      {
        sentence: showFractionFirst
          ? `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/> ${translate.misc.of()} ${totalB.toLocaleString()} = <ans/>`
          : `<ans/> = <frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/> ${translate.misc.of()} ${totalB.toLocaleString()}`,
        answer: correctAnswerB
      }
    ];

    const items = shuffle([incorrectAnswerA, incorrectAnswerB, correctAnswerA, correctAnswerB], {
      random: seededRandom(question)
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentences()}
        items={items}
        sentences={statements.map(({ sentence }) => sentence)}
        testCorrect={statements.map(({ answer }) => [answer])}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        moveOrCopy="move"
        pdfLayout="itemsRight"
        actionPanelVariant="end"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5v2 = newQuestionContent({
  uid: 'aPW2',
  description: 'aPW2',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z.object({
    total: z.number().int().min(2).max(400),
    numerator: z.number().int().min(1).max(10),
    denominator: z.number().int().min(2).max(10),
    showFractionFirst: z.boolean(),
    items: z.array(z.number().int().min(1).max(400)).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);

    const numerator = randomIntegerInclusive(1, denominator);

    const total = randomIntegerInclusive(2, 400, { constraint: x => x % denominator === 0 });

    const correctAnswer = number(math.evaluate(`${total}/${denominator} * ${numerator}`));

    const [incorrectNumeratorA, incorrectNumeratorB] = randomUniqueIntegersInclusive(1, 10, 2, {
      constraint: x => x !== numerator && x * denominator < 400 && x * denominator !== correctAnswer
    });

    const showFractionFirst = getRandomBoolean();

    const incorrectAnswerA = number(math.evaluate(`${denominator} * ${incorrectNumeratorA}`));

    const incorrectAnswerB = number(math.evaluate(`${denominator} * ${incorrectNumeratorB}`));

    const items = shuffle([incorrectAnswerA, incorrectAnswerB, correctAnswer]);

    return {
      total,
      numerator,
      denominator,
      showFractionFirst,
      items
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { total, numerator, denominator, showFractionFirst, items } = question;

    const answer = number(math.evaluate(`${total}/${denominator} * ${numerator}`)).toLocaleString();

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardToCompleteCalculation()}
        pdfTitle={translate.instructions.useCardToCompleteCalculation()}
        items={items.map(it => it.toLocaleString())}
        sentence={
          showFractionFirst
            ? translate.answerSentences.fracOfXNumberIsEqualToAns({
                frac: `<frac n='${numerator}' d='${denominator}'/>`,
                number: total
              })
            : translate.answerSentences.ansEqualsFracOfNum(
                `<frac n='${numerator}' d='${denominator}'/>`,
                total
              )
        }
        testCorrect={[answer]}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        pdfLayout="itemsRight"
        actionPanelVariant="end"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aPX',
  description: 'aPX',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z.object({
    totalA: z.number().int().min(2).max(100),
    numeratorA: z.number().int().min(1).max(10),
    denominatorA: z.number().int().min(2).max(10),
    totalB: z.number().int().min(2).max(100),
    numeratorB: z.number().int().min(1).max(10),
    denominatorB: z.number().int().min(1).max(10),
    denominatorC: z.number().int().min(1).max(20),
    numeratorC: z.number().int().min(1).max(20),
    totalC: z.number().int().min(2).max(200),
    denominatorD: z.number().int().min(1).max(20),
    numeratorD: z.number().int().min(1).max(20),
    totalD: z.number().int().min(2).max(200),
    headingNumberA: z.number().int().min(1).max(100),
    headingNumberB: z.number().int().min(1).max(100)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 10, 2);

    const numeratorA = randomIntegerInclusive(1, denominatorA);
    const numeratorB = randomIntegerInclusive(1, denominatorB);

    const totalA = randomIntegerInclusive(2, 100, { constraint: x => x % denominatorA === 0 });
    const totalB = randomIntegerInclusive(2, 100, {
      constraint: x =>
        x % denominatorB === 0 &&
        x !== totalA &&
        number(math.evaluate(`${totalA}/${denominatorA} * ${numeratorA}`)) !==
          number(math.evaluate(`${x}/${denominatorB} * ${numeratorB}`))
    });

    const headingNumberA = number(math.evaluate(`${totalA}/${denominatorA} * ${numeratorA}`));
    const headingNumberB = number(math.evaluate(`${totalB}/${denominatorB} * ${numeratorB}`));

    const { numeratorC, denominatorC, totalC } = rejectionSample(
      () => {
        const denominatorC = randomIntegerInclusive(2, 20, { constraint: x => x !== denominatorA });

        const numeratorC = randomIntegerInclusive(1, denominatorC);

        const totalC = randomIntegerInclusive(10, 200, { constraint: x => x !== totalA });
        return { numeratorC, denominatorC, totalC };
      },
      ({ numeratorC, denominatorC, totalC }) =>
        number(math.evaluate(`${totalC}/${denominatorC} * ${numeratorC}`)) === headingNumberA
    );

    const { numeratorD, denominatorD, totalD } = rejectionSample(
      () => {
        const denominatorD = randomIntegerInclusive(2, 20, { constraint: x => x !== denominatorB });

        const numeratorD = randomIntegerInclusive(1, denominatorD);

        const totalD = randomIntegerInclusive(10, 200, { constraint: x => x !== totalB });
        return { numeratorD, denominatorD, totalD };
      },
      ({ numeratorD, denominatorD, totalD }) =>
        number(math.evaluate(`${totalD}/${denominatorD} * ${numeratorD}`)) === headingNumberB
    );

    return {
      totalA,
      numeratorA,
      denominatorA,
      totalB,
      numeratorB,
      denominatorB,
      denominatorC,
      numeratorC,
      totalC,
      numeratorD,
      denominatorD,
      totalD,
      headingNumberA,
      headingNumberB
    };
  },
  Component: props => {
    const {
      question: {
        totalA,
        numeratorA,
        denominatorA,
        totalB,
        numeratorB,
        denominatorB,
        denominatorC,
        numeratorC,
        totalC,
        numeratorD,
        denominatorD,
        totalD,
        headingNumberA,
        headingNumberB
      },
      translate,
      displayMode
    } = props;
    const [numA, demA] = simplify(numeratorA, denominatorA);
    const [numB, demB] = simplify(numeratorB, denominatorB);
    const [numC, demC] = simplify(numeratorC, denominatorC);
    const [numD, demD] = simplify(numeratorD, denominatorD);

    const items = shuffle(
      [
        {
          sentence: `<frac n='${numA.toLocaleString()}' d='${demA.toLocaleString()}'/> ${translate.misc.of()} ${totalA.toLocaleString()}`,
          value: headingNumberA
        },
        {
          sentence: `<frac n='${numB.toLocaleString()}' d='${demB.toLocaleString()}'/> ${translate.misc.of()} ${totalB.toLocaleString()}`,
          value: headingNumberB
        },
        {
          sentence: `<frac n='${numC.toLocaleString()}' d='${demC.toLocaleString()}'/> ${translate.misc.of()} ${totalC.toLocaleString()}`,
          value: totalC
        },
        {
          sentence: `<frac n='${numD.toLocaleString()}' d='${demD.toLocaleString()}'/> ${translate.misc.of()} ${totalD.toLocaleString()}`,
          value: totalD
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const testCorrect = [
      [headingNumberA, totalC],
      [headingNumberB, totalD]
    ];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortCalculationsInTable()}
        pdfTitle={translate.instructions.useCardsToSortCalculationsInTable()}
        zoneNames={[
          translate.tableHeaders.EqualTo(headingNumberA),
          translate.tableHeaders.EqualTo(headingNumberB)
        ]}
        items={items.map(({ value, sentence }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                fractionDividerStyle={{ marginVertical: 1 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  fontWeight: '700'
                }}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50, fontWeight: '700' }}
              />
            ),
            value
          };
        })}
        testCorrect={testCorrect}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        questionHeight={800}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aPX2',
  description: 'aPX',
  keywords: ['Fraction', 'Quantity', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z.object({
    totalA: z.number().int().min(2).max(100),
    numeratorA: z.number().int().min(1).max(9),
    denominatorA: z.number().int().min(2).max(10),
    totalB: z.number().int().min(2).max(100),
    numeratorB: z.number().int().min(1).max(9),
    denominatorB: z.number().int().min(1).max(10),
    denominatorC: z.number().int().min(1).max(20),
    numeratorC: z.number().int().min(1).max(19),
    totalC: z.number().int().min(2).max(200),
    denominatorD: z.number().int().min(1).max(20),
    numeratorD: z.number().int().min(1).max(19),
    totalD: z.number().int().min(2).max(200),
    headingNumberA: z.number().int().min(1).max(100),
    headingNumberB: z.number().int().min(1).max(100)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 10, 2);

    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
    const totalA = randomIntegerInclusive(2, 100, {
      constraint: x => x % denominatorA === 0
    });
    const headingNumberA = (totalA / denominatorA) * numeratorA;

    const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

    const totalB = randomIntegerInclusive(2, 100, {
      constraint: x =>
        x % denominatorB === 0 &&
        x !== totalA &&
        (totalA / denominatorA) * numeratorA !== (x / denominatorB) * numeratorB
    });

    const headingNumberB = (totalB / denominatorB) * numeratorB;

    const { numeratorC, denominatorC, totalC } = rejectionSample(
      () => {
        const denominatorC = randomIntegerInclusive(2, 20, {
          constraint: x => x !== denominatorA
        });
        const numeratorC = randomIntegerInclusive(1, denominatorC - 1);
        const totalC = randomIntegerInclusive(10, 200, { constraint: x => x !== totalA });

        return {
          numeratorC,
          denominatorC,
          totalC,
          denominatorA
        };
      },
      ({ numeratorC, denominatorC, totalC }) =>
        (totalC / denominatorC) * numeratorC === headingNumberA
    );

    const { numeratorD, denominatorD, totalD } = rejectionSample(
      () => {
        const denominatorD = randomIntegerInclusive(2, 20, {
          constraint: x => x !== denominatorB
        });

        const numeratorD = randomIntegerInclusive(1, denominatorD - 1);

        const totalD = randomIntegerInclusive(10, 200, { constraint: x => x !== totalB });

        return {
          numeratorD,
          headingNumberB,
          denominatorD,
          totalD
        };
      },
      ({ numeratorD, denominatorD, totalD, headingNumberB }) =>
        (totalD / denominatorD) * numeratorD === headingNumberB
    );

    return {
      totalA,
      numeratorA,
      denominatorA,
      totalB,
      numeratorB,
      denominatorB,
      denominatorC,
      numeratorC,
      totalC,
      numeratorD,
      denominatorD,
      totalD,
      headingNumberA,
      headingNumberB
    };
  },
  Component: props => {
    const {
      question: {
        totalA,
        numeratorA,
        denominatorA,
        totalB,
        numeratorB,
        denominatorB,
        denominatorC,
        numeratorC,
        totalC,
        numeratorD,
        denominatorD,
        totalD,
        headingNumberA,
        headingNumberB
      },
      translate,
      displayMode
    } = props;
    const [numA, demA] = simplify(numeratorA, denominatorA);
    const [numB, demB] = simplify(numeratorB, denominatorB);
    const [numC, demC] = simplify(numeratorC, denominatorC);
    const [numD, demD] = simplify(numeratorD, denominatorD);

    const items = shuffle(
      [
        {
          sentence: `<frac n='${numA}' d='${demA}'/> ${translate.misc.of()} ${totalA.toLocaleString()}`,
          value: headingNumberA
        },
        {
          sentence: `<frac n='${numB}' d='${demB}'/> ${translate.misc.of()} ${totalB.toLocaleString()}`,
          value: headingNumberB
        },
        {
          sentence: `<frac n='${numC}' d='${demC}'/> ${translate.misc.of()} ${totalC.toLocaleString()}`,
          value: totalC
        },
        {
          sentence: `<frac n='${numD}' d='${demD}'/> ${translate.misc.of()} ${totalD.toLocaleString()}`,
          value: totalD
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const testCorrect = [
      [headingNumberA, totalC],
      [headingNumberB, totalD]
    ];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortCalculationsInTable()}
        pdfTitle={translate.instructions.useCardsToSortCalculationsInTable()}
        zoneNames={[
          translate.tableHeaders.EqualTo(headingNumberA),
          translate.tableHeaders.EqualTo(headingNumberB)
        ]}
        items={items.map(({ value, sentence }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                fractionDividerStyle={{ marginVertical: 1 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  fontWeight: '700'
                }}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50, fontWeight: '700' }}
              />
            ),
            value
          };
        })}
        testCorrect={testCorrect}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        questionHeight={800}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateAFractionOfAQuantity',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question1, Question2, Question5, Question6]
});
export default SmallStep;
