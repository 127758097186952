import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';
import Table from '../../../../components/molecules/Table';
import { filledArray, range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { barModelColors } from '../../../../theme/colors';
import { compareFractions } from '../../../../utils/fractions';
import {
  PartWholeModel,
  TextPartition
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import { ADD, SUB } from '../../../../constants';
import { all, create, number } from 'mathjs';
import { compareFloats } from '../../../../utils/math';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import TenFrames from '../../../../components/question/representations/TenFrame/TenFrameLegacy';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'awk',
  description: 'awk',
  keywords: ['Ten frame', 'Addition', 'Decimals', 'Number bonds', 'Tenths'],
  schema: z.object({
    number1: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const number2 = number1 / 10;

    const answer1 = 10 - number1;
    const answer2 = answer1 / 10;

    const sentenceA = `${number1} + <ans /> = 10`;
    const sentenceB = `${number2} + <ans /> = 1`;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeCalculationsShownOn10Frame()}
        sentences={[sentenceA, sentenceB]}
        mainPanelStyle={{ flexDirection: 'row' }}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1.toString()) &&
          compareFloats(userAnswer[1][0], answer2)
        }
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <TenFrames numberOfCounters={number1} placeValue={'ones'} emptyGreys />
            <TenFrames numberOfCounters={number1} placeValue={'tenths'} emptyGreys />
          </View>
        )}
        style={{ justifyContent: 'space-evenly' }}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'awl',
  description: 'awl',
  keywords: ['100 square', 'Decimals', 'Tenths'],
  schema: z.object({
    answer: z.number().int().min(1).max(9),
    shadedOrNot: z.enum(['shaded', 'not shaded']),
    rotation: numberEnum([0, 90, 180, 270]).optional()
  }),
  simpleGenerator: () => {
    const answer = randomIntegerInclusive(1, 9);
    const shadedOrNot = getRandomFromArray(['shaded', 'not shaded'] as const);
    const rotation = getRandomFromArray([0, 90, 180, 270] as const);

    return { answer, shadedOrNot, rotation };
  },
  Component: props => {
    const {
      question: { answer, shadedOrNot, rotation = 0 },
      translate
    } = props;

    const shadingColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    // Create 10 x 10 table of colors for shaded parts
    const shadedPart = filledArray(
      filledArray(shadingColor, 10),
      shadedOrNot === 'shaded' ? answer : 10 - answer
    );
    const notShaded = filledArray(
      filledArray('white', 10),
      shadedOrNot === 'shaded' ? 10 - answer : answer
    );
    const colors = shadedPart.concat(notShaded);

    // Create each square of the 100 square
    const table = colors.map(rowColors =>
      rowColors.map((color, columnIndex) => (
        <View
          key={columnIndex}
          style={{
            backgroundColor: color,
            width: 40,
            height: 40
          }}
        />
      ))
    );

    return (
      <QF1ContentAndSentence
        title={
          // If we use the answer to get the tenths string it can contract to 'tenth'.
          // So here I am 'fixing' it by putting in a number that provides multiple tenths.
          shadedOrNot === 'shaded'
            ? translate.instructions.hundredSquareRepresentsWholeHowManyXAreShaded({
                measure: translate.fractions.tenths(20)
              })
            : translate.instructions.hundredSquareRepresentsWholeHowManyXAreNotShaded({
                measure: translate.fractions.tenths(20)
              })
        }
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <View
            style={[
              {
                transform: [{ rotate: `${rotation}deg` }]
              }
            ]}
          >
            <Table
              style={{ height: dimens.height, width: dimens.width, alignItems: 'center' }}
              items={table}
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'awm',
  description: 'awm',
  keywords: ['Part-whole model', 'Addition', 'Number bonds', 'Decimals', 'Tenths'],
  schema: z.object({
    givenDecimal: z.number().min(0.1).max(0.9).step(0.1),
    givenPart: z.enum(['left', 'right'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const givenDecimal = randomIntegerInclusive(1, 9) / 10;

    const givenPart = getRandomFromArray(['left', 'right'] as const);

    return { givenDecimal, givenPart };
  },
  Component: ({ question, translate, displayMode }) => {
    const { givenDecimal, givenPart } = question;

    // Need mathjs' formatting to account for JavaScript round-off errors:
    const answerPart = number(math.evaluate(`1 - ${givenDecimal}`));

    let partition: TextPartition = [];

    if (givenPart === 'left') {
      partition = ['$ans', givenDecimal.toLocaleString()];
    } else {
      partition = [givenDecimal.toLocaleString(), '$ans'];
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        extraSymbol="decimalPoint"
        inputType="numpad"
        initialState={displayMode === 'markscheme' ? [answerPart.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => compareFloats(answer[0], answerPart)}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={(1).toLocaleString()}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awn',
  description: 'awn',
  keywords: ['Part-whole', 'Addition', 'Number bonds', 'Fractions', 'Tenths'],
  schema: z.object({
    number1: z.number().int().min(1).max(9)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const answerNumerator = 10 - number1;

    // Randomise which side has the answer on
    const modelPartition = shuffle([`<frac n='${number1}' d='10' />`, '?'], {
      random: seededRandom(props.question)
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutMissingValueInThePartWholeModel()}
        sentence={`<frac nAns='' dAns=''/>`}
        testCorrect={userAnswer => compareFractions([...userAnswer], [answerNumerator, 10])}
        inputMaxCharacters={2}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <PartWholeModel
            top={'1'}
            partition={modelPartition}
            representation="text"
            dimens={dimens}
            fractionTextStyle={{ fontSize: 28 }}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [answerNumerator.toLocaleString(), (10).toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'awo',
  description: 'awo',
  keywords: ['Decimals', 'Fractions', 'Tenths', 'Whole', 'Addition'],
  schema: z.object({
    decimalNums: z
      .number()
      .min(0.1)
      .max(0.8)
      .step(0.1)
      .array()
      .length(3)
      .refine(
        val => number(math.format(math.sum(val), { precision: 14 })) === 1,
        'decimals must sum to 1'
      ),
    numeratorNums: z
      .number()
      .int()
      .min(1)
      .max(8)
      .array()
      .length(3)
      .refine(val => math.sum(val) === 10, 'numerators must sum to 10'),
    decimalAnsPosition: numberEnum([0, 1, 2]),
    numeratorAnsPosition: numberEnum([0, 1, 2])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    // Decimals
    const decimalA = getRandomFromArray(range(0.1, 0.8, 0.1));
    const decimalB = getRandomFromArray(
      range(0.1, number(math.evaluate(`0.9 - ${decimalA}`)), 0.1)
    );

    const decimalC = number(math.evaluate(`1 - (${decimalA} + ${decimalB})`));
    const decimalNums = [decimalA, decimalB, decimalC];

    // Numerators
    const numeratorA = randomIntegerInclusive(1, 8);
    const numeratorB = randomIntegerInclusive(1, 9 - numeratorA);
    const numeratorC = 10 - (numeratorA + numeratorB);
    const numeratorNums = [numeratorA, numeratorB, numeratorC];

    const decimalAnsPosition = getRandomFromArray([0, 1, 2] as const);
    const numeratorAnsPosition = getRandomFromArray([0, 1, 2] as const);

    return { decimalNums, numeratorNums, decimalAnsPosition, numeratorAnsPosition };
  },
  Component: props => {
    const {
      question: { decimalNums, numeratorNums, decimalAnsPosition, numeratorAnsPosition },
      translate
    } = props;

    // Create arrays to map into sentences
    const decimals = decimalNums.map(num => num.toLocaleString());
    const decimalAns = decimalNums[decimalAnsPosition];
    decimals[decimalAnsPosition] = '<ans/>';

    const numerators = numeratorNums.map(num => `<frac n='${num.toLocaleString()}' d='10' />`);
    const numeratorAns = numeratorNums[numeratorAnsPosition];
    numerators[numeratorAnsPosition] = `<frac nAns='' dAns='' />`;

    // Create sentences
    const decimalSentence = decimals.join(` ${ADD} `) + ' = 1';
    const fractionSentence = numerators.join(` ${ADD} `) + ' = 1';

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], decimalAns) &&
          compareFractions([...userAnswer[1]], [numeratorAns, 10])
        }
        inputMaxCharacters={3}
        sentences={[decimalSentence, fractionSentence]}
        extraSymbol="decimalPoint"
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [decimalAns.toLocaleString()],
            [numeratorAns.toLocaleString(), (10).toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimalsAndFractions()
        }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'awo2',
  description: 'awo',
  keywords: ['Decimals', 'Fractions', 'Tenths', 'Whole', 'Addition'],
  schema: z.object({
    subQuestion: z.discriminatedUnion('isDecimal', [
      z.object({
        isDecimal: z.literal(true),
        ansPosition: numberEnum([0, 1, 2]),
        decimalNums: z
          .number()
          .min(0.1)
          .max(0.8)
          .step(0.1)
          .array()
          .length(3)
          .refine(
            val => number(math.format(math.sum(val), { precision: 14 })) === 1,
            'decimals must sum to 1'
          )
      }),
      z.object({
        isDecimal: z.literal(false),
        ansPosition: numberEnum([0, 1, 2]),
        numeratorNums: z
          .number()
          .int()
          .min(1)
          .max(8)
          .array()
          .length(3)
          .refine(val => math.sum(val) === 10, 'numerators must sum to 10')
      })
    ])
  }),
  simpleGenerator: () => {
    const isDecimal = getRandomBoolean();
    const ansPosition = getRandomFromArray([0, 1, 2] as const);

    if (isDecimal) {
      // Decimals
      const decimalA = getRandomFromArray(range(0.1, 0.8, 0.1));
      const decimalB = getRandomFromArray(
        range(0.1, number(math.evaluate(`0.9 - ${decimalA}`)), 0.1)
      );

      const decimalC = number(math.evaluate(`1 - (${decimalA} + ${decimalB})`));
      const decimalNums = [decimalA, decimalB, decimalC];

      return { subQuestion: { isDecimal, ansPosition, decimalNums } };
    } else {
      // Numerators
      const numeratorA = randomIntegerInclusive(1, 8);
      const numeratorB = randomIntegerInclusive(1, 9 - numeratorA);
      const numeratorC = 10 - (numeratorA + numeratorB);
      const numeratorNums = [numeratorA, numeratorB, numeratorC];

      return { subQuestion: { isDecimal, ansPosition, numeratorNums } };
    }
  },
  Component: props => {
    const {
      question: { subQuestion },
      translate,
      displayMode
    } = props;

    const { ansPosition } = subQuestion;

    let sentence = '';
    let answer: number;

    if (subQuestion.isDecimal) {
      const { decimalNums } = subQuestion;

      const decimals = decimalNums.map(num => num.toLocaleString());
      answer = decimalNums[ansPosition];
      decimals[ansPosition] = '<ans/>';
      sentence = decimals.join(` ${ADD} `) + ' = 1';
    } else {
      const { numeratorNums } = subQuestion;

      const numerators = numeratorNums.map(num => `<frac n='${num}' d='10' />`);
      answer = numeratorNums[ansPosition];
      numerators[ansPosition] = `<frac nAns='' dAns='' />`;
      sentence = numerators.join(` ${ADD} `) + ' = 1';
    }

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumber()}
        testCorrect={userAnswer =>
          subQuestion.isDecimal
            ? compareFloats(userAnswer[0], answer)
            : compareFractions([userAnswer[0], userAnswer[1]], [answer, 10])
        }
        inputMaxCharacters={3}
        sentence={sentence}
        extraSymbol="decimalPoint"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: subQuestion.isDecimal
            ? [answer.toLocaleString()]
            : [answer.toLocaleString(), (10).toLocaleString()],
          answerText: !subQuestion.isDecimal
            ? translate.markScheme.acceptEquivalentDecimalsAndFractions()
            : ''
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'awp',
  description: 'awp',
  keywords: ['Decimals', 'Fractions', 'Tenths', 'Whole', 'Addition', 'Subtraction'],
  schema: z.object({
    decimalA: z.number().min(0.1).max(0.8).step(0.1),
    decimalB: z.number().min(0.1).max(0.9).step(0.1),
    numeratorA: z.number().int().min(1).max(7),
    numeratorB: z.number().int().min(2).max(8)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const decimalA = getRandomFromArray(range(0.1, 0.8, 0.1));
    const decimalB = getRandomFromArray(
      range(0.1, number(math.format(1 - decimalA - 0.1, { precision: 14 })), 0.1)
    );

    const numeratorB = randomIntegerInclusive(2, 8);
    const numeratorA = randomIntegerInclusive(1, 9 - numeratorB, {
      constraint: x => 10 - x - numeratorB > 0
    });

    return { decimalA, decimalB, numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { decimalA, decimalB, numeratorA, numeratorB },
      translate
    } = props;

    // Sentences to display
    const fractionSentence = `<frac n='${numeratorA.toLocaleString()}' d='10' /> ${ADD} <frac nAns='' dAns='' /> = 1 ${SUB} <frac n='${numeratorB.toLocaleString()}' d='10' />`;
    const decimalSentence = `1 ${SUB} ${decimalA.toLocaleString()} = <ans/> ${ADD} ${decimalB.toLocaleString()}`;

    const fractionAnswer = [10 - numeratorB - numeratorA, 10];
    const decimalAnswer = math.evaluate(`1 - (${decimalA} + ${decimalB})`);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={userAnswer =>
          compareFractions([...userAnswer[0]], fractionAnswer) &&
          compareFloats(userAnswer[1][0], decimalAnswer)
        }
        inputMaxCharacters={3}
        sentences={[fractionSentence, decimalSentence]}
        extraSymbol="decimalPoint"
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [fractionAnswer[0].toLocaleString(), fractionAnswer[1].toLocaleString()],
            [decimalAnswer.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimalsAndFractions()
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'awp2',
  description: 'awp',
  keywords: ['Decimals', 'Fractions', 'Tenths', 'Whole', 'Addition', 'Subtraction'],
  schema: z.object({
    subQuestion: z.discriminatedUnion('isDecimal', [
      z.object({
        isDecimal: z.literal(true),
        decimalA: z.number().min(0.1).max(0.8).step(0.1),
        decimalB: z.number().min(0.1).max(0.9).step(0.1)
      }),
      z.object({
        isDecimal: z.literal(false),
        numeratorA: z.number().int().min(1).max(7),
        numeratorB: z.number().int().min(2).max(8)
      })
    ])
  }),
  simpleGenerator: () => {
    const isDecimal = getRandomBoolean();

    if (isDecimal) {
      const decimalA = getRandomFromArray(range(0.1, 0.8, 0.1));
      const decimalB = getRandomFromArray(
        range(0.1, number(math.format(1 - decimalA - 0.1, { precision: 14 })), 0.1)
      );
      return { subQuestion: { isDecimal, decimalA, decimalB } };
    } else {
      const numeratorB = randomIntegerInclusive(2, 8);
      const numeratorA = randomIntegerInclusive(1, 9 - numeratorB, {
        constraint: x => 10 - x - numeratorB > 0
      });
      return { subQuestion: { isDecimal, numeratorA, numeratorB } };
    }
  },
  Component: props => {
    const {
      question: { subQuestion },
      translate,
      displayMode
    } = props;

    const { isDecimal } = subQuestion;

    const sentence = isDecimal
      ? `1 ${SUB} ${subQuestion.decimalA.toLocaleString()} = <ans/> ${ADD} ${subQuestion.decimalB.toLocaleString()}`
      : `<frac n='${subQuestion.numeratorA}' d='10' /> ${ADD} <frac nAns='' dAns='' /> = 1 ${SUB} <frac n='${subQuestion.numeratorB}' d='10' />`;

    const answer = isDecimal
      ? math.evaluate(`1 - (${subQuestion.decimalA} + ${subQuestion.decimalB})`)
      : [10 - subQuestion.numeratorB - subQuestion.numeratorA, 10];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumber()}
        testCorrect={userAnswer =>
          isDecimal
            ? compareFloats(userAnswer[0], answer)
            : compareFractions([userAnswer[0], userAnswer[1]], answer)
        }
        inputMaxCharacters={3}
        sentence={sentence}
        extraSymbol="decimalPoint"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: isDecimal
            ? [answer.toLocaleString()]
            : [answer[0].toLocaleString(), answer[1].toLocaleString()],
          answerText: isDecimal ? '' : translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeAWholeWithTenths',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
