import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { isValidRectangle, isValidSquare } from '../../../../utils/shapes';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import { countRange, sortNumberArray } from '../../../../utils/collections';
import { GridEllipse, GridHeart, GridPolygon } from '../../../../utils/gridUtils';
import { colors } from '../../../../theme/colors';
import { numberEnum } from '../../../../utils/zod';
import * as math from 'mathjs';
import { isOnLine } from '../../../../utils/lines';
import { Point2d } from '../../../../utils/vectors';
import { roundToTheNearest } from '../../../../utils/math';
import { Polygon } from '../../../../utils/polygon';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ben',
  description: 'ben',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half', 'Rectangle'],
  schema: z.object({
    x1: z.number(),
    x2: z.number(),
    y1: z.number(),
    y2: z.number()
  }),
  simpleGenerator: () => {
    const { x1, x2, y1, y2 } = rejectionSample(
      () => {
        const xCoords = randomUniqueIntegersInclusive(1, 9, 2);
        const yCoords = randomUniqueIntegersInclusive(1, 5, 2);

        const [x1, x2] = sortNumberArray(xCoords, 'ascending');
        const [y1, y2] = sortNumberArray(yCoords, 'ascending');

        return {
          x1,
          x2,
          y1,
          y2
        };
      },
      ({ x1, x2, y1, y2 }) => {
        return (
          isValidRectangle(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) &&
          !isValidSquare(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) &&
          (y2 - y1) % 2 === 0 &&
          (x2 - x1) % 2 === 0
        );
      }
    );

    return { x1, x2, y1, y2 };
  },
  Component: ({ translate, question: { x1, x2, y1, y2 }, displayMode }) => {
    const polygon = Polygon.create([
      [x1, y1],
      [x1, y2],
      [x2, y2],
      [x2, y1]
    ]);

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapTwoDotsToSplitTheShapeInHalf()}
        pdfTitle={translate.ks1PDFInstructions.drawStraightLineToSplitTheShapeInHalf()}
        numPoints={2}
        gridChildren={
          <GridPolygon
            key="border"
            points={polygon.vertices.map(it => it.toArray())}
            color={displayMode === 'digital' ? `${colors.prussianBlue}40` : `${colors.grey}60`}
            showBorder
          />
        }
        testCorrect={userAnswer => {
          const [p, q] = userAnswer;
          if (p === undefined || q === undefined) return false;
          return polygon.isSplitInHalfByLine([p, q]);
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswer(),
          answersToDisplay: [
            { x: (x1 + x2) / 2, y: y1 },
            { x: (x1 + x2) / 2, y: y2 }
          ]
        }}
      />
    );
  }
});

function q2FindCorrectAnswer(
  polygon: Polygon
): { type: 'vertical'; x: number } | { type: 'horizontal'; y: number } | null {
  const centroid = polygon.centroid;

  if (
    Number.isInteger(centroid.x) &&
    polygon.isSplitInHalfByLine([
      [centroid.x, 0],
      [centroid.x, 5]
    ])
  ) {
    // Can be split vertically
    return { type: 'vertical', x: centroid.x };
  } else if (
    Number.isInteger(centroid.y) &&
    polygon.isSplitInHalfByLine([
      [0, centroid.y],
      [10, centroid.y]
    ])
  ) {
    // Can be split horizontally
    return { type: 'horizontal', y: centroid.y };
  } else {
    return null;
  }
}

function randomlyTranslatePolygon(vertices: [number, number][]): [number, number][] {
  const xCoords = vertices.map(([x, _y]) => x);
  const xMin = Math.min(...xCoords);
  const xMax = Math.max(...xCoords);
  const yCoords = vertices.map(([_x, y]) => y);
  const yMin = Math.min(...yCoords);
  const yMax = Math.max(...yCoords);

  const translationX = randomIntegerInclusive(-Math.floor(xMin), 10 - Math.ceil(xMax));
  const translationY = randomIntegerInclusive(-Math.floor(yMin), 5 - Math.ceil(yMax));
  return vertices.map(([x, y]) => [x + translationX, y + translationY]);
}

const Question2 = newQuestionContent({
  uid: 'beo',
  description: 'beo',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('polygon'),
      vertices: z
        .array(z.tuple([z.number().min(0).max(10), z.number().min(0).max(5)]))
        .refine(
          vertices => q2FindCorrectAnswer(Polygon.create(vertices)) !== null,
          'the polygon must be splittable with a vertical or horizontal line on the grid'
        )
    }),
    z.object({
      type: z.enum(['heart', 'circle']),
      left: z.number().int().min(0).max(6),
      bottom: z.number().int().min(0).max(1)
    })
  ]),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'diamond',
      'arrow',
      'triangle',
      'circle',
      'star',
      'heart'
    ] as const);

    // Get the non-polygons out of the way
    if (shape === 'heart') {
      return {
        type: 'heart' as const,
        left: randomIntegerInclusive(0, 6),
        bottom: randomIntegerInclusive(0, 1)
      };
    } else if (shape === 'circle') {
      return {
        type: 'circle' as const,
        left: randomIntegerInclusive(0, 6),
        bottom: randomIntegerInclusive(0, 1)
      };
    }

    // Must be a polygon
    let vertices: [number, number][];

    switch (shape) {
      case 'diamond':
        switch (getRandomFromArray(['wide', 'tall', 'wideBig'] as const)) {
          case 'wide':
            vertices = [
              [0, 1],
              [2, 2],
              [4, 1],
              [2, 0]
            ];
            break;
          case 'tall':
            vertices = [
              [0, 2],
              [1, 4],
              [2, 2],
              [1, 0]
            ];
            break;
          case 'wideBig':
            vertices = [
              [0, 2],
              [4, 4],
              [8, 2],
              [4, 0]
            ];
            break;
        }
        break;
      case 'arrow':
        switch (getRandomFromArray(['small', 'big'] as const)) {
          case 'small':
            vertices = [
              [0, 0.5],
              [0, 1.5],
              [2, 1.5],
              [2, 2],
              [3, 1],
              [2, 0],
              [2, 0.5]
            ];
            break;
          case 'big':
            vertices = [
              [0, 1],
              [0, 3],
              [4, 3],
              [4, 4],
              [6, 2],
              [4, 0],
              [4, 1]
            ];
            break;
        }
        break;
      case 'triangle':
        switch (getRandomFromArray(['up', 'right', 'upBig', 'rightBig'] as const)) {
          case 'up':
            vertices = [
              [0, 0],
              [1, 2],
              [2, 0]
            ];
            break;
          case 'right':
            vertices = [
              [0, 0],
              [2, 1],
              [0, 2]
            ];
            break;
          case 'upBig':
            vertices = [
              [0, 0],
              [2, 3],
              [4, 0]
            ];
            break;
          case 'rightBig':
            vertices = [
              [0, 0],
              [3, 2],
              [0, 4]
            ];
            break;
        }
        break;
      case 'star': {
        // A star is made of two regular hexagons interleved.
        // We ensure that the top and bottom of the outer hexagon lies on the grid (radius 2).
        // We ensure that the left and right of the inner hexagon lies on the grid (radius 1).
        // Also round to the nearest millionth just to keep the integers as integers
        const outerVertices: [number, number][] = countRange(6).map(n => [
          roundToTheNearest(2 + 2 * Math.sin((n / 6) * (2 * Math.PI)), 10e-6),
          roundToTheNearest(2 + 2 * Math.cos((n / 6) * (2 * Math.PI)), 10e-6)
        ]);
        const innerVertices: [number, number][] = countRange(6).map(n => [
          roundToTheNearest(2 + 1 * Math.sin((n / 6 + 1 / 12) * (2 * Math.PI)), 10e-6),
          roundToTheNearest(2 + 1 * Math.cos((n / 6 + 1 / 12) * (2 * Math.PI)), 10e-6)
        ]);
        // interleve them
        vertices = outerVertices.flatMap((v, i) => [v, innerVertices[i]]);
        break;
      }
    }

    return { type: 'polygon' as const, vertices: randomlyTranslatePolygon(vertices) };
  },
  Component: ({ question, translate, displayMode }) => {
    const polygon = question.type === 'polygon' ? Polygon.create(question.vertices) : null;

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapTwoDotsToSplitTheShapeInHalf()}
        pdfTitle={translate.ks1PDFInstructions.drawStraightLineToSplitTheShapeInHalf()}
        numPoints={2}
        gridChildren={
          question.type === 'heart' ? (
            <GridHeart
              center={[question.left + 2, question.bottom + 2]}
              width={4}
              color={displayMode === 'digital' ? `${colors.prussianBlue}40` : `${colors.grey}60`}
              showBorder
            />
          ) : question.type === 'circle' ? (
            <GridEllipse
              center={[question.left + 2, question.bottom + 2]}
              rx={2}
              ry={2}
              color={displayMode === 'digital' ? `${colors.prussianBlue}40` : `${colors.grey}60`}
              showBorder
            />
          ) : question.type === 'polygon' ? (
            <GridPolygon
              points={question.vertices}
              color={displayMode === 'digital' ? `${colors.prussianBlue}40` : `${colors.grey}60`}
              showBorder
            />
          ) : (
            // Unreachable
            (question.type satisfies never)
          )
        }
        testCorrect={userAnswer => {
          const [p, q] = userAnswer;
          if (p === undefined || q === undefined) return false;
          const lineStart = new Point2d(p);
          const lineEnd = new Point2d(q);

          if (question.type === 'heart') {
            // Only the vertical line is allowed
            return (
              lineStart.x === question.left + 2 &&
              lineEnd.x === question.left + 2 &&
              ((lineStart.y <= question.bottom && lineEnd.y >= question.bottom + 3) ||
                (lineEnd.y <= question.bottom && lineStart.y >= question.bottom + 3))
            );
          } else if (question.type === 'circle') {
            // Any line that goes through the center, which starts and ends outside (or on) the circle
            const center = new Point2d(question.left + 2, question.bottom + 2);
            const radius = 2;
            const isOutsideOrOnCircle = (point: Point2d) =>
              math.largerEq(math.distance(center.toArray(), point.toArray()), radius) as boolean;

            return (
              isOnLine({
                coordinatesA: lineStart,
                coordinatesB: lineEnd,
                x: center.x,
                y: center.y
              }) &&
              isOutsideOrOnCircle(lineStart) &&
              isOutsideOrOnCircle(lineEnd)
            );
          } else {
            // Polygon, so we can check this in a generic way
            return polygon!.isSplitInHalfByLine([p, q]);
          }
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswer(),
          answersToDisplay: (() => {
            if (question.type === 'heart') {
              return [
                { x: question.left + 2, y: question.bottom },
                { x: question.left + 2, y: question.bottom + 4 }
              ];
            } else if (question.type === 'circle') {
              return [
                { x: question.left + 2, y: question.bottom },
                { x: question.left + 2, y: question.bottom + 4 }
              ];
            }

            // Must not be null since schema has checked this
            const correctAnswer = q2FindCorrectAnswer(polygon!)!;
            if (correctAnswer.type === 'horizontal') {
              return [
                { x: Math.min(...polygon!.vertices.map(it => it.x)), y: correctAnswer.y },
                { x: Math.max(...polygon!.vertices.map(it => it.x)), y: correctAnswer.y }
              ];
            } else {
              return [
                { x: correctAnswer.x, y: Math.min(...polygon!.vertices.map(it => it.y)) },
                { x: correctAnswer.x, y: Math.max(...polygon!.vertices.map(it => it.y)) }
              ];
            }
          })()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bep',
  description: 'bep',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z
    .intersection(
      z.discriminatedUnion('shape', [
        z.object({
          // Right angled isosceles triangle even lengthed short edges
          shape: z.literal('triangle'),
          width: numberEnum([2, 4]),
          rotation: numberEnum([0, 90, 180, 270])
        }),
        z.object({
          // Rectangle (or square) with odd-lengthed short edges
          shape: z.literal('rectangle'),
          width: numberEnum([3, 5, 7, 9]),
          height: numberEnum([3, 5])
        })
      ]),
      z.object({
        left: z.number().int().min(0).max(10),
        bottom: z.number().int().min(0).max(6)
      })
    )
    .refine(val => {
      switch (val.shape) {
        case 'triangle':
          return val.left + val.width <= 10 && val.bottom + val.width <= 5;
        case 'rectangle':
          return val.left + val.width <= 10 && val.bottom + val.height <= 5;
      }
    }, 'shape must fit on grid'),
  simpleGenerator: () => {
    if (getRandomBoolean()) {
      // triangle
      const width = getRandomFromArray([2, 4] as const);
      const rotation = getRandomFromArray([0, 90, 180, 270] as const);
      const left = randomIntegerInclusive(0, 10 - width);
      const bottom = randomIntegerInclusive(0, 5 - width);
      return { shape: 'triangle' as const, width, rotation, left, bottom };
    } else {
      // rectangle
      const width = getRandomFromArray([3, 5, 7, 9] as const);
      const height = getRandomFromArray([3, 5] as const);
      const left = randomIntegerInclusive(0, 10 - width);
      const bottom = randomIntegerInclusive(0, 5 - height);
      return { shape: 'rectangle' as const, width, height, left, bottom };
    }
  },
  Component: ({ question: { left, bottom, ...question }, translate, displayMode }) => {
    const vertices = (() => {
      switch (question.shape) {
        case 'triangle': {
          const { width, rotation } = question;
          const [a, b, c, d] = [
            [0, 0],
            [0, width],
            [width, width],
            [width, 0]
          ].map(([x, y]) => [x + left, y + bottom] as [number, number]);

          switch (rotation) {
            case 0:
              return [a, b, c];
            case 90:
              return [b, c, d];
            case 180:
              return [c, d, a];
            case 270:
              return [d, a, b];
          }
        }

        case 'rectangle': {
          const { width, height } = question;
          return [
            [0, 0],
            [0, height],
            [width, height],
            [width, 0]
          ].map(([x, y]) => [x + left, y + bottom] as [number, number]);
        }
      }
    })();

    const polygon = Polygon.create(vertices);

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapTwoDotsToSplitTheShapeInHalf()}
        pdfTitle={translate.ks1PDFInstructions.drawStraightLineToSplitTheShapeInHalf()}
        numPoints={2}
        gridChildren={
          <GridPolygon
            key="border"
            points={vertices}
            color={displayMode === 'digital' ? `${colors.prussianBlue}40` : `${colors.grey}60`}
            showBorder
          />
        }
        testCorrect={userAnswer => {
          const [p, q] = userAnswer;
          if (p === undefined || q === undefined) return false;
          return polygon.isSplitInHalfByLine([p, q]);
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswer(),
          answersToDisplay: (() => {
            if (question.shape === 'triangle') {
              switch (question.rotation) {
                case 0:
                  return [
                    { x: left, y: bottom + question.width },
                    { x: left + question.width / 2, y: bottom + question.width / 2 }
                  ];
                case 90:
                  return [
                    { x: left + question.width, y: bottom + question.width },
                    { x: left + question.width / 2, y: bottom + question.width / 2 }
                  ];
                case 180:
                  return [
                    { x: left + question.width, y: bottom },
                    { x: left + question.width / 2, y: bottom + question.width / 2 }
                  ];
                case 270:
                  return [
                    { x: left, y: bottom },
                    { x: left + question.width / 2, y: bottom + question.width / 2 }
                  ];
              }
            } else {
              return [
                { x: left, y: bottom },
                { x: left + question.width, y: bottom + question.height }
              ];
            }
          })()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAHalfOfAnObjectOrAShape',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
