import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  StampShape,
  ThreeDShape,
  ThreeDShapeSvgSchema,
  get3DShapeSvgName,
  getRandom3DShape,
  getRandomCubeNets,
  getRandomUnique3DShapeNames,
  getRandomUniqueNets,
  netsSVGName,
  netsSVGNameNoFill,
  shapeProperties,
  threeDShapesSchema
} from '../../../../utils/threeDShapes';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { nestedArrayHasNoDuplicates } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { getShapeSvgName } from '../../../../utils/shapeImages/shapes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aEW',
  description: 'aEW',
  keywords: ['3-D shape', 'Nets'],
  schema: z.object({
    shapeObjects: z.array(z.object({ svgName: z.string(), correct: z.boolean() })).length(4)
  }),
  simpleGenerator: () => {
    const correctAnswersCount = randomIntegerInclusive(1, 2);
    const correctAnswer = getRandomCubeNets(correctAnswersCount, true);
    const shapeObjects = shuffle([
      ...correctAnswer,
      ...getRandomCubeNets(4 - correctAnswersCount, false)
    ]);

    return { shapeObjects };
  },
  Component: props => {
    const {
      question: { shapeObjects },
      translate
    } = props;

    const shape = translate.shapes.cubes(1);
    const answer = shapeObjects.filter(val => val.correct).map(val => val.svgName);
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectNetThatMakesX(answer.length, shape)}
        pdfTitle={translate.instructions.circleNetThatMakesX(answer.length, shape)}
        testCorrect={answer}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapeObjects.map(shape => ({
            value: shape.svgName,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aEX',
  description: 'aEX',
  keywords: ['3-D shape', 'Nets'],
  schema: z.object({
    shapeObjects: z.array(z.object({ svgName: z.string(), shape: threeDShapesSchema })).length(4),
    answerIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const shapeObjects = getRandomUniqueNets(4);
    const answerIndex = randomIntegerInclusive(0, 3);
    return { shapeObjects, answerIndex };
  },
  Component: props => {
    const {
      question: { shapeObjects, answerIndex },
      translate
    } = props;

    const shapeName =
      shapeObjects[answerIndex].shape === 'triangularBasedPyramids'
        ? 'tetrahedrons'
        : (shapeObjects[answerIndex].shape as ThreeDShape);

    const shape = translate.shapes[shapeName](1);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectNetThatMakesX(1, shape)}
        pdfTitle={translate.instructions.circleNetThatMakesX(1, shape)}
        testCorrect={[shapeObjects[answerIndex].shape]}
        numItems={4}
        renderItems={({ dimens }) => {
          return shapeObjects.map(shape => ({
            value: shape.shape,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aEY',
  description: 'aEY',
  keywords: ['3-D shape', 'Nets'],
  schema: z.object({
    shapeObjects: z.array(z.object({ shape: z.string(), svgName: z.string() })).length(4),
    answerIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const shapes = getRandomUnique3DShapeNames(4);
    const shapeObjects = shapes.map(shape => ({ shape: shape, svgName: get3DShapeSvgName(shape) }));
    const answerIndex = randomIntegerInclusive(0, 3);
    return { shapeObjects, answerIndex };
  },
  Component: props => {
    const {
      question: { shapeObjects, answerIndex },
      translate
    } = props;

    const netSvg = netsSVGName.filter(val => val.shape === shapeObjects[answerIndex].shape);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.select3DShapeThisNetMakes()}
        pdfTitle={translate.instructions.circle3DShapeThisNetMakes()}
        testCorrect={[shapeObjects[answerIndex].shape]}
        numItems={4}
        Content={({ dimens }) => (
          <AssetSvg
            name={netSvg[0].svgName as SvgName}
            height={dimens.height * 0.8}
            width={dimens.width * 0.8}
          />
        )}
        renderItems={({ dimens }) => {
          return shapeObjects.map(shape => ({
            value: shape.shape,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.4}
                width={dimens.width * 0.4}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'aEY2',
  description: 'aEY',
  keywords: ['3-D shape', 'Nets'],
  schema: z.object({
    shapeObjects: z
      .array(z.object({ shape: threeDShapesSchema, svgName: ThreeDShapeSvgSchema }))
      .length(4),
    answerIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const shapes = getRandomUnique3DShapeNames(4);
    const shapeObjects = shapes.map(shape => ({ shape: shape, svgName: get3DShapeSvgName(shape) }));
    const answerIndex = randomIntegerInclusive(0, 3);
    return { shapeObjects, answerIndex };
  },
  Component: props => {
    const {
      question: { shapeObjects, answerIndex },
      translate
    } = props;

    const netSvg = netsSVGNameNoFill.filter(val => val.shape === shapeObjects[answerIndex].shape);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.select3DShapeThisNetMakes()}
        pdfTitle={translate.instructions.circle3DShapeThisNetMakes()}
        testCorrect={[shapeObjects[answerIndex].shape]}
        numItems={4}
        Content={({ dimens }) => (
          <AssetSvg
            name={netSvg[0].svgName as SvgName}
            height={dimens.height * 0.8}
            width={dimens.width * 0.8}
          />
        )}
        renderItems={({ dimens }) => {
          return shapeObjects.map(shape => ({
            value: shape.shape,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.4}
                width={dimens.width * 0.4}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aEZ',
  description: 'aEZ',
  keywords: ['3-D shape', 'Nets', '2-D shape'],
  schema: z.object({
    shape: threeDShapesSchema,
    stamps: z
      .object({
        shape: z.string(),
        svgName: z.string(),
        isCorrect: z.boolean()
      })
      .array()
  }),
  simpleGenerator: () => {
    const shape = getRandom3DShape();
    const shapeProps = shapeProperties.filter(val => val.shape === shape)[0];
    const stamps = shapeProps.stampImages.map(val => {
      return { shape: val, svgName: getShapeSvgName(val as StampShape) as string, isCorrect: true };
    });
    const extraAnswers = getRandomSubArrayFromArray(
      (['triangles', 'circles', 'rectangles', 'pentagons', 'hexagons'] as const).filter(
        val => !stamps.map(val => val.shape).includes(val as StampShape)
      ),
      4 - stamps.length
    ).map(val => {
      return {
        shape: val,
        svgName: getShapeSvgName(val),
        isCorrect: false
      };
    });

    stamps.push(...extraAnswers);
    return { shape, stamps: shuffle(stamps) };
  },
  Component: props => {
    const {
      question: { shape, stamps },
      translate
    } = props;

    const shapeAsWord = translate.shapes[shape as ThreeDShape](1);
    const answer = stamps.filter(val => val.isCorrect).map(val => val.shape);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.select2DShapeYouWouldNeedToMakeX(answer.length, shapeAsWord)}
        pdfTitle={translate.instructions.circle2DShapesYouWouldNeedToMakeX(
          answer.length,
          shapeAsWord
        )}
        multiSelect={answer.length > 1}
        testCorrect={answer}
        numItems={4}
        renderItems={({ dimens }) => {
          return stamps.map(shape => ({
            value: shape.shape,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aE0',
  description: 'aE0',
  keywords: ['3-D shape', 'Nets', '2-D shape'],
  schema: z.object({
    shapes: z
      .object({
        shape: z.string(),
        stamps: z.array(z.string())
      })
      .array()
      .length(4),
    answerIndex: z.number().int().min(0).max(3),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const shapes = rejectionSample(
      () =>
        getRandomSubArrayFromArray(
          [
            { shape: 'squareBasedPyramids', stamps: ['squares', 'triangles'] },
            { shape: 'pentagonalPyramids', stamps: ['pentagons', 'triangles'] },
            { shape: 'hexagonalPyramids', stamps: ['hexagons', 'triangles'] },
            { shape: 'octagonalPyramids', stamps: ['octagons', 'triangles'] },
            { shape: 'cylinders', stamps: ['circles', 'rectangles'] },
            { shape: 'cuboids', stamps: ['squares', 'rectangles'] },
            { shape: 'triangularPrisms', stamps: ['triangles', 'rectangles'] },
            { shape: 'pentagonalPrisms', stamps: ['pentagons', 'rectangles'] },
            { shape: 'hexagonalPrisms', stamps: ['hexagons', 'rectangles'] },
            { shape: 'octagonalPrisms', stamps: ['octagons', 'rectangles'] }
          ],
          4
        ),
      val => nestedArrayHasNoDuplicates(val.map(x => x.stamps))
    );

    const answerIndex = randomIntegerInclusive(0, 3);
    const name = getRandomName();

    return { shapes, answerIndex, name };
  },
  Component: props => {
    const {
      question: { shapes, answerIndex, name },
      translate
    } = props;

    const shapesAsWord = shapes.map(val => translate.shapes[val.shape as ThreeDShape](1));
    const stamps = shapes[answerIndex].stamps.map(val =>
      translate.shapes[
        val as
          | 'squares'
          | 'triangles'
          | 'pentagons'
          | 'hexagons'
          | 'octagons'
          | 'circles'
          | 'rectangles'
      ](2)
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.characterUsesXAndYToMakeANetSelect3dShape(
          name,
          stamps[0],
          stamps[1]
        )}
        pdfTitle={translate.instructions.characterUsesXAndYToMakeANetCircle3dShape(
          name,
          stamps[0],
          stamps[1]
        )}
        testCorrect={[shapes[answerIndex].shape]}
        numItems={4}
        renderItems={shapes.map((shape, i) => ({
          value: shape.shape,
          component: <Text variant="WRN700">{shapesAsWord[i]}</Text>
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aE1',
  description: 'aE1',
  keywords: ['3-D shape', 'Nets'],
  schema: z.object({
    shapeObjects: z
      .array(z.object({ svgName: z.string(), shape: z.string(), correct: z.boolean() }))
      .length(2),
    isPrism: z.boolean()
  }),
  simpleGenerator: () => {
    const isPrism = getRandomBoolean();
    const prism = getRandomFromArray([
      {
        shape: 'triangular prisms',
        svgName: 'Shape_nets/Triangular_prism_net_without_tabs',
        correct: isPrism
      },
      {
        shape: 'pentagonal prisms',
        svgName: 'Shape_nets/Pentagonal_prism_net_without_tab',
        correct: isPrism
      },
      {
        shape: 'hexagonal prisms',
        svgName: 'Shape_nets/Hexagonal_prism_net_without_tabs',
        correct: isPrism
      },
      {
        shape: 'octagonal prisms',
        svgName: 'Shape_nets/Octagonal_prism_net_without_tabs',
        correct: isPrism
      }
    ] as const);
    const pyramids = getRandomFromArray([
      {
        shape: 'triangular-based pyramids',
        svgName: 'Shape_nets/Tatrahedron_net_without_tabs',
        correct: !isPrism
      },
      {
        shape: 'square-based pyramids',
        svgName: 'Shape_nets/Square-based_pyramid_net_without_tabs',
        correct: !isPrism
      },
      {
        shape: 'pentagonal pyramids',
        svgName: 'Shape_nets/Pentagonal_pyramid_net_without_tabs',
        correct: !isPrism
      },
      {
        shape: 'hexagonal pyramids',
        svgName: 'Shape_nets/Hexagonal_pyramid_net_without_tabs',
        correct: !isPrism
      },
      {
        shape: 'octagonal pyramids',
        svgName: 'Shape_nets/Octagonal_pyramid_net_without_tabs',
        correct: !isPrism
      }
    ] as const);

    const shapeObjects = shuffle([prism, pyramids]);
    return { shapeObjects, isPrism };
  },
  Component: props => {
    const {
      question: { shapeObjects, isPrism },
      translate
    } = props;

    const shape = isPrism ? translate.shapes.prisms(1) : translate.shapes.pyramids(1);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectNetThatMakesX(1, shape)}
        pdfTitle={translate.instructions.circleNetThatMakesX(1, shape)}
        testCorrect={shapeObjects.filter(val => val.correct).map(val => val.shape)}
        numItems={2}
        renderItems={({ dimens }) => {
          return shapeObjects.map(shape => ({
            value: shape.shape,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NetsOf3DShapes',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
