import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { useCallback, useContext, useMemo } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import { resolveThingOrFunction, ThingOrFunction } from '../../../utils/react';
import { arraysHaveSameContents, filledArray } from '../../../utils/collections';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import TextStructure from '../../molecules/TextStructure';
import { TitleStyleProps } from '../../molecules/TitleRow';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { parseMarkup } from '../../../markup';
import EasyDragAndDropWithSingleZonesWithGrid from '../../draganddrop/EasyDragAndDropWithSingleZonesWithGrid';
import { DisplayMode } from '../../../contexts/displayMode';
import { Theme } from '../../../theme';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { DraggableVariant } from '../../draganddrop/utils';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { AssetSvg } from '../../../assets/svg';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * Prop to decide how the PDF version will be displayed, based on your choice of if and where items are placed.
   * 'itemsHidden' - removes the items entirely from the PDF.
   * 'itemsRight' - items display in a column list on the right.
   * 'itemsTop' - items display in a row between the instruction and sentence(s).
   * Optional prop, defaults to 'itemsTop'.
   */
  pdfLayout?: 'itemsHidden' | 'itemsRight' | 'itemsTop' | 'itemsBottom';
  pdfInnerItemsStyle?: StyleProp<ViewStyle>;
  pdfOuterItemsStyle?: StyleProp<ViewStyle>;
  pdfSentencesStyle?: StyleProp<ViewStyle>;
  /** Sentences to complete. These use the markup language defined at {@link parseMarkup}. */
  sentences: string[];
  /** Generally speaking you don't need to provide this. Defaults all drop zones empty. */
  initialState?: (T | undefined)[][];
  sentenceStyle?: StyleProp<ViewStyle>;
  sentencesStyle?: StyleProp<ViewStyle>;
  sentencesTextVariant?: keyof Theme['fonts'];
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  /**
   * The draggable items to show. Each one can just be a string/number, or it can have a custom component using the
   * {@link ItemInfo} type.
   *
   * If providing just numbers, .toLocaleString() will be called for you before rendering.
   */
  items: ThingOrFunction<readonly ((T & (string | number)) | ItemInfo<T>)[]>;
  /**
   * Whether each draggable can exist in multiple drop zones or not, and thus whether they are moved around
   * vs copies of them are moved around. Default 'move'.
   */
  moveOrCopy?: 'move' | 'copy';
  /** Default: square */
  itemVariant?: DraggableVariant;
  pdfItemVariant?: DraggableVariant;
  itemsTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  pdfItemsLetterEmWidth?: number;
  /** Default: endWide. */
  actionPanelVariant?: 'bottom' | 'bottomTall' | 'end' | 'endWide' | 'endMid' | 'bottomMid';

  /** Defaults to checking all answer boxes have a draggable in. */
  testComplete?: (userAnswer: readonly (T | undefined)[][]) => boolean;

  /** Either an array of correct answers, or a function for more advanced cases. */
  testCorrect:
    | (T | undefined)[][]
    | ((sentencesUserAnswer: readonly (T | undefined)[][]) => boolean);
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: T[][]; answerText?: string };
  /** PDF Question Height */
  questionHeight?: number;
  itemMaxLines?: number;
};

/**
 * Layout containing a title above, drag and drop zone in the action panel, and sentences in the main panel.
 *
 * In the component name, 'Drag' refers to using drag and drop. This is the most general version of QF37. For a single
 * sentence, see the other QF37 component, which is easier to use for that case.
 *
 * ### Item values
 *
 * The draggable item's values can be anything. If number, .toLocaleString() is called for you.
 *
 * ### Testing functions (testCorrect/testComplete)
 *
 * The easiest way to use these is to omit testComplete, and provide an array for testCorrect, e.g.
 *
 * ```
 *   testCorrect={[[1, 2], [3]]}
 * ```
 */
export default function QF37SentencesDrag<T>({
  title,
  pdfTitle,
  pdfLayout = 'itemsTop',
  pdfInnerItemsStyle,
  pdfOuterItemsStyle,
  pdfSentencesStyle,
  sentences,
  initialState,
  sentenceStyle,
  sentencesStyle,
  sentencesTextVariant,
  textStyle,
  fractionContainerStyle,
  fractionTextStyle,
  items: itemsProp,
  itemsTextVariant = 'WRN700',
  moveOrCopy = 'move',
  itemVariant = 'square',
  pdfItemVariant = 'pdfSquare',
  itemsLetterEmWidth,
  pdfItemsLetterEmWidth,
  // Default to bottom action panel if 7 items or less, otherwise use endWide.
  actionPanelVariant = itemsProp.length <= 7 ? 'bottom' : 'endWide',
  testComplete: testCompleteProp,
  testCorrect: testCorrectProp,
  customMarkSchemeAnswer,
  questionHeight,
  itemMaxLines,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  // Set up items - handle the case where we were given a list of numbers/strings.
  const items = useMemo(() => {
    return resolveThingOrFunction(itemsProp).map(it =>
      typeof it === 'string' || typeof it === 'number'
        ? { value: it, component: it.toLocaleString() }
        : it
    );
  }, [itemsProp]);

  // Set up testComplete
  const testComplete = useCallback(
    (userAnswer: (T | undefined)[][]) => {
      return testCompleteProp !== undefined
        ? // If provided a function for sentences, check that
          testCompleteProp(userAnswer)
        : // Otherwise default to checking every answer is not undefined
          userAnswer.every(sentenceAnswer => sentenceAnswer.every(it => it !== undefined));
    },
    [testCompleteProp]
  );

  // Set up testCorrect
  const testCorrect = useCallback(
    (userAnswer: (T | undefined)[][]) => {
      if (typeof testCorrectProp === 'function') {
        return testCorrectProp(userAnswer);
      } else {
        return arraysHaveSameContents(userAnswer, testCorrectProp, arraysHaveSameContents);
      }
    },
    [testCorrectProp]
  );

  const numberOfAnsArray = sentences.map(parseMarkup).map(it => it.numberOfAns);
  const defaultState =
    initialState ??
    numberOfAnsArray.map(answersThisSentence => filledArray(undefined, answersThisSentence));

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp;

    return (
      <EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState
        id="draganddrop"
        items={items}
        variant={pdfItemVariant}
        columnsPerRow={numberOfAnsArray}
        // We need to always show the items in their original positions on mark schemes, so this should always be 'copy':
        moveOrCopy={'copy'}
        defaultState={displayMode === 'markscheme' ? markSchemeAnswer : defaultState}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={pdfItemsLetterEmWidth ?? itemsLetterEmWidth}
        textVariant={itemsTextVariant}
        maxLines={itemMaxLines}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          questionHeight={questionHeight}
          mainPanelContents={
            <View
              style={[
                styles.containerPDF,
                {
                  flexDirection:
                    pdfLayout === 'itemsTop' || pdfLayout === 'itemsBottom' ? 'column' : 'row'
                }
              ]}
            >
              {pdfLayout === 'itemsTop' && (
                <DragAndDropSectionPDF
                  style={[{ flex: 0.4 }, pdfOuterItemsStyle]}
                  itemsStyle={[
                    { justifyContent: 'center', gap: 16, flexWrap: 'wrap', flexDirection: 'row' },
                    pdfInnerItemsStyle
                  ]}
                >
                  {items.map((_item, index) => (
                    <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
                  ))}
                </DragAndDropSectionPDF>
              )}
              <View style={[styles.sentences, styles.mainPanelPDF, pdfSentencesStyle]}>
                {sentences.map((sentence, sentenceIndex) => (
                  <TextStructure
                    key={sentenceIndex}
                    sentence={sentence}
                    style={sentenceStyle}
                    textStyle={textStyle}
                    textVariant={sentencesTextVariant}
                    fractionContainerStyle={fractionContainerStyle}
                    fractionTextStyle={fractionTextStyle}
                    inputBox={({ index }) => (
                      <>
                        {displayMode === 'markscheme' &&
                          // We do not want to show ticks on empty boxes on mark schemes:
                          (typeof testCorrectProp === 'object' ||
                            customMarkSchemeAnswer?.answersToDisplay) && (
                            <AssetSvg
                              name="True"
                              width={50}
                              style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
                            />
                          )}
                        <EasyDragAndDropWithSingleZonesWithGrid.ZoneSingle
                          key={index}
                          row={sentenceIndex}
                          column={index}
                        />
                      </>
                    )}
                  />
                ))}
              </View>
              {(pdfLayout === 'itemsRight' || pdfLayout === 'itemsBottom') && (
                <DragAndDropSectionPDF
                  itemsStyle={[
                    pdfLayout === 'itemsRight' && {
                      flex: 1,
                      rowGap: 32,
                      justifyContent: 'space-evenly',
                      alignContent: 'center'
                    },
                    pdfLayout === 'itemsBottom' && [
                      {
                        justifyContent: 'center',
                        gap: 16,
                        flexWrap: 'wrap',
                        flexDirection: 'row'
                      },
                      pdfInnerItemsStyle
                    ]
                  ]}
                  style={[
                    pdfLayout === 'itemsRight' && { backgroundColor: 'transparent' },
                    pdfLayout === 'itemsBottom' && [{ flex: 0.4 }, pdfOuterItemsStyle]
                  ]}
                >
                  {items.map((_item, index) => (
                    <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
                  ))}
                </DragAndDropSectionPDF>
              )}
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </View>
          }
          {...props}
        />
      </EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={itemVariant}
      defaultState={defaultState}
      columnsPerRow={numberOfAnsArray}
      testComplete={testComplete}
      testCorrect={testCorrect}
      textAutoScale={longestItemTextLength}
      textVariant={itemsTextVariant}
      maxLines={itemMaxLines}
      textLetterEmWidth={itemsLetterEmWidth}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={
          <DragAndDropSection
            itemsStyle={{
              gap:
                actionPanelVariant === 'bottom' ||
                actionPanelVariant === 'bottomTall' ||
                (actionPanelVariant === undefined && itemsProp.length <= 7)
                  ? 24
                  : 16,
              justifyContent: actionPanelVariant === 'endMid' ? 'center' : undefined
            }}
          >
            {items.map((_item, index) => (
              <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <View style={[styles.sentences, sentencesStyle]}>
            {sentences.map((sentence, sentenceIndex) => (
              <TextStructure
                key={sentenceIndex}
                sentence={sentence}
                style={sentenceStyle}
                textStyle={textStyle}
                textVariant={sentencesTextVariant}
                fractionContainerStyle={fractionContainerStyle}
                fractionTextStyle={fractionTextStyle}
                inputBox={({ index }) => (
                  <EasyDragAndDropWithSingleZonesWithGrid.ZoneSingle
                    key={index}
                    row={sentenceIndex}
                    column={index}
                  />
                )}
              />
            ))}
          </View>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  sentences: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    rowGap: 16,
    flex: 1
  },

  containerPDF: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center'
  },

  mainPanelPDF: {
    flex: 1,
    rowGap: 32,
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    alignContent: 'center'
  }
});
