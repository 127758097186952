import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { findFactors } from '../../../../utils/factors';
import { useMemo } from 'react';
import { ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C, DIV } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { nameSchema, names } from '../../../../utils/names';
import { isPrime } from '../../../../utils/primes';
import { numberToBase10Object } from '../../../../utils/math';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { barModelColors, colors } from '../../../../theme/colors';
import { characterNameLabel, getCharacterHeadSvgName } from '../../../../utils/characters';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { FunctionMachinesInputWithState } from '../../../../components/question/representations/FunctionMachines';
import { CompleteTheSentenceWithState } from '../../../../components/molecules/CompleteTheSentence';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { AssetSvg } from '../../../../assets/svg';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aoM',
  description: 'aoM',
  keywords: ['Divide', 'Factors'],
  schema: z
    .object({
      number1: z.number().int().min(110).max(890).multipleOf(10),
      number5: z.number().int().min(20).max(90).multipleOf(10)
    })
    .refine(
      val => val.number1 % 100 !== 0,
      'number1 must be a multiple of 10, and must not be a multiple of 100'
    )
    .refine(val => val.number1 % val.number5 === 0, 'number1 must be a multiple of number5'),
  simpleGenerator: () => {
    const number5 = randomIntegerInclusiveStep(20, 90, 10);

    const number1 = randomIntegerInclusiveStep(110, 890, 10, {
      constraint: x => x % 100 !== 0 && x % number5 === 0
    });

    return { number1, number5 };
  },

  Component: props => {
    const {
      question: { number1, number5 },
      translate,
      displayMode
    } = props;

    const number2 = number1 / 10;

    const number3 = number5 / 10;

    const functionMachineRow = [
      `${number1}`,
      `${DIV} ${(10).toLocaleString()}`,
      `<ans/>`,
      `${DIV} ${number3.toLocaleString()}`,
      `<ans/>`
    ];

    return (
      <QF3Content
        title={translate.instructions.completeDivisionUsingFactors()}
        inputType="numpad"
        actionPanelVariant="bottomTall"
        inputVariant="wide"
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-between' }}>
            <FunctionMachinesInputWithState
              dimens={{
                width: dimens.width,
                height: dimens.height / 2
              }}
              rowsOfBoxes={[functionMachineRow]}
              hideLabels
              id={'functionMachine'}
              testCorrect={userAnswer =>
                userAnswer[0][0] === number2.toString() &&
                userAnswer[0][1] === (number1 / number5).toString()
              }
              boxHeight={96}
              defaultState={
                displayMode === 'markscheme'
                  ? [[number2.toLocaleString(), (number1 / number5).toLocaleString()]]
                  : undefined
              }
            />
            <CompleteTheSentenceWithState
              id="sentence"
              sentence={`${number1.toLocaleString()} ${DIV} ${number5.toLocaleString()} = <ans/>`}
              testCorrect={userAnswer => userAnswer[0] === (number1 / number5).toString()}
              textStyle={{ fontSize: displayMode !== 'digital' ? 60 : 40 }}
              defaultState={
                displayMode === 'markscheme' ? [(number1 / number5).toLocaleString()] : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aoN',
  description: 'aoN',
  keywords: ['Divide', 'Factors', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(1001).max(9999),
      number2: z.number().int().min(15).max(30),
      number3: z.number().int().min(2).max(15),
      name1: nameSchema,
      name2: nameSchema
    })
    .refine(val => val.number1 % val.number2 === 0, 'number1 must be a multiple of number2')
    .refine(val => !isPrime(val.number2), 'number2 must not be a prime number.')
    .refine(
      val => findFactors(val.number2).includes(val.number3) && val.number3 !== val.number2,
      'number3 must be a factor of number2 that is not 1 or number2'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(15, 30, {
      constraint: x => !isPrime(x)
    });

    const number1 = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % number2 === 0
    });

    const number2AllFactors = findFactors(number2);

    // Remove first factor from array:
    number2AllFactors.shift();

    // Remove final factor from array:
    number2AllFactors.pop();

    const number3 = getRandomFromArray(number2AllFactors) as number;

    const [name1, name2] = getRandomSubArrayFromArray([...names.male, ...names.female], 2);

    return { number1, number2, number3, name1, name2 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, name1, name2 },
      translate
    } = props;

    const number4 = number2 / number3;

    const number2ToBase10 = numberToBase10Object(number2);

    const number5 = number2ToBase10.tens ? number2ToBase10.tens * 10 : 0;

    const number6 = number2ToBase10.ones;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { firstNumber: number3, secondNumber: number4, isCorrect: true, name: name1 };
      const eqB = { firstNumber: number5, secondNumber: number6, isCorrect: false, name: name2 };
      return shuffle([eqA, eqB], { random: seededRandom(props.question) });
    }, [name1, name2, number3, number4, number5, number6, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.char1AndChar2AreWorkingOutX(
          name1,
          name2,
          `${number1.toLocaleString()} ${DIV} ${number2.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.char1AndChar2AreWorkingOutXPDF(
          name1,
          name2,
          `${number1.toLocaleString()} ${DIV} ${number2.toLocaleString()}`
        )}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={2}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <View
                style={[
                  dimens,
                  { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }
                ]}
              >
                <SpeechBubble
                  flickLocation="top-right"
                  style={{
                    maxWidth: 0.35 * dimens.width,
                    maxHeight: 0.5 * dimens.height,
                    marginRight: 55,
                    top: 40
                  }}
                >
                  {translate.answerSentences.iWillDivideByXThenDivideByY(
                    equation.firstNumber,
                    equation.secondNumber
                  )}
                </SpeechBubble>
                <View style={{ rowGap: 15 }}>
                  <AssetSvg
                    name={getCharacterHeadSvgName(equation.name)}
                    height={dimens.height * 0.5}
                    width={dimens.width * 0.3}
                  />
                  {characterNameLabel(equation.name, dimens.width * 0.3)}
                </View>
              </View>
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aoO',
  description: 'aoO',
  keywords: ['Divide', 'Factors', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(1001).max(9999),
      number2: z.number().int().min(15).max(50)
    })
    .refine(val => val.number1 % val.number2 === 0, 'number1 must be a multiple of number2')
    .refine(
      val =>
        (val.number2 % 4 === 0 && val.number2 % 5 !== 0 && val.number2 % 6 !== 0) ||
        (val.number2 % 4 !== 0 && val.number2 % 5 === 0 && val.number2 % 6 !== 0) ||
        (val.number2 % 4 !== 0 && val.number2 % 5 !== 0 && val.number2 % 6 === 0),
      'number2 must be a multiple of only one of the following: 4, 5 or 6'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(15, 50, {
      constraint: x =>
        (x % 4 === 0 && x % 5 !== 0 && x % 6 !== 0) ||
        (x % 4 !== 0 && x % 5 === 0 && x % 6 !== 0) ||
        (x % 4 !== 0 && x % 5 !== 0 && x % 6 === 0)
    });

    const number1 = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % number2 === 0
    });

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const eqs = useMemo(() => {
      const [number3, number4, number5, number6, number7, number8] = (() => {
        if (number2 % 4 === 0) {
          return [4, number2 / 4, 2, number2 / 2, number2 / 2, number2 / 2];
        } else if (number2 % 5 === 0) {
          return [5, number2 / 5, 10, number2 / 10, number2 - 5, 5];
        } else {
          return [6, number2 / 6, 3, number2 / 3, number2 - 6, 6];
        }
      })();

      const selectables = shuffle(
        [
          [
            'A',
            `${number1.toLocaleString()} ${DIV} ${number3.toLocaleString()} ${DIV} ${number4.toLocaleString()}`
          ],
          [
            'B',
            `${number1.toLocaleString()} ${DIV} ${number5.toLocaleString()} ${DIV} ${number6.toLocaleString()}`
          ],
          [
            'C',
            `${number1.toLocaleString()} ${DIV} ${number7.toLocaleString()} ${DIV} ${number8.toLocaleString()}`
          ]
        ],
        { random: seededRandom(props.question) }
      );

      return (
        <QF39ContentWithSelectablesOnRight
          title={translate.instructions.selectMethodsThatWouldGiveCorrectAnswer()}
          pdfTitle={translate.instructions.circleMethodsThatWouldGiveCorrectAnswer()}
          correctAnswer={['A', 'B']}
          selectables={Object.fromEntries(selectables)}
          multiSelect
          leftContent={
            <MeasureView>
              <ContentBox>
                <Text
                  style={{ fontSize: 40, padding: 16 }}
                  variant="WRN400"
                >{`${number1.toLocaleString()} ${DIV} ${number2.toLocaleString()}`}</Text>
              </ContentBox>
            </MeasureView>
          }
        />
      );
    }, [number1, number2, props, translate]);

    return eqs;
  }
});

const Question4 = newQuestionContent({
  uid: 'aoP',
  description: 'aoP',
  keywords: ['Divide', 'Factors', 'Factor pairs'],
  schema: z
    .object({
      dividend: z.number().int().min(101).max(9999),
      divisor: z.number().int().min(15).max(95)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const equationToUse = getRandomFromArray(['A', 'B', 'C'] as const);

    const divisor = (() => {
      switch (equationToUse) {
        case 'A':
          return randomIntegerInclusiveStep(20, 90, 10);
        case 'B':
          return randomIntegerInclusiveStep(10, 90, 10) + 5;
        case 'C':
          return randomIntegerInclusive(16, 30, {
            constraint: x => x % 4 === 0 || x % 6 === 0 || x % 9 === 0
          });
      }
    })();

    const dividend = (() => {
      switch (equationToUse) {
        case 'A':
        case 'C':
          return randomIntegerInclusive(101, 999, {
            constraint: x => x % divisor === 0
          });
        case 'B':
          return randomIntegerInclusive(101, 9999, {
            constraint: x => x % divisor === 0
          });
      }
    })();

    return { dividend, divisor };
  },

  Component: props => {
    const {
      question: { dividend, divisor },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeDivisionUsingFactors()}
        testCorrect={[(dividend / divisor).toString()]}
        sentence={`${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aoQ',
  description: 'aoQ',
  keywords: ['Divide', 'Factors', 'Factor pairs', 'Function machine'],
  schema: z.object({
    divisorA: z.number().int().min(2).max(5),
    divisorB: z.number().int().min(5).max(9),
    quotient: z.number().int().min(5).max(90)
  }),
  simpleGenerator: () => {
    const { divisorA, divisorB, quotient } = rejectionSample(
      () => {
        const divisorA = randomIntegerInclusive(2, 5);

        const divisorB = randomIntegerInclusive(5, 9);

        const quotientStep5 = getRandomBoolean();

        const quotient = quotientStep5
          ? randomIntegerInclusiveStep(5, 25, 5)
          : randomIntegerInclusiveStep(30, 90, 10);

        return { divisorA, divisorB, quotient };
      },
      ({ divisorA, divisorB, quotient }) => divisorA * divisorB * quotient < 1200
    );

    return { divisorA, divisorB, quotient };
  },

  Component: props => {
    const {
      question: { divisorA, divisorB, quotient },
      translate,
      displayMode
    } = props;

    const startingNumber = divisorA * divisorB * quotient;

    const functionMachineRow = [
      startingNumber.toLocaleString(),
      `${DIV} ${divisorA.toLocaleString()}`,
      '<ans/>',
      `${DIV} ${divisorB.toLocaleString()}`,
      '<ans/>'
    ];

    return (
      <QF3Content
        title={translate.instructions.completeDivisionUsingFactors()}
        inputType="numpad"
        actionPanelVariant="bottomTall"
        inputVariant="wide"
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-between' }}>
            <FunctionMachinesInputWithState
              dimens={{
                width: dimens.width,
                height: dimens.height / 2
              }}
              rowsOfBoxes={[functionMachineRow]}
              hideLabels
              id={'functionMachine'}
              testCorrect={userAnswer =>
                userAnswer[0][0] === (startingNumber / divisorA).toString() &&
                userAnswer[0][1] === quotient.toString()
              }
              boxHeight={96}
              defaultState={
                displayMode === 'markscheme'
                  ? [[(startingNumber / divisorA).toLocaleString(), quotient.toLocaleString()]]
                  : undefined
              }
            />
            <CompleteTheSentenceWithState
              id="sentence"
              sentence={`${startingNumber.toLocaleString()} ${DIV} <ans/> = <ans/>`}
              testCorrect={userAnswer =>
                userAnswer[0] === (divisorA * divisorB).toString() &&
                userAnswer[1] === quotient.toString()
              }
              textStyle={{ fontSize: displayMode !== 'digital' ? 60 : 40 }}
              defaultState={
                displayMode === 'markscheme'
                  ? [(divisorA * divisorB).toLocaleString(), quotient.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aoR',
  description: 'aoR',
  keywords: ['Divide', 'Factors', 'Bar model'],
  schema: z
    .object({
      number1: z.number().int().min(1001).max(9999),
      number1Parts: z.number().int().min(3).max(5)
    })
    .refine(
      val => val.number1 % (val.number1Parts * 6) === 0,
      'number1 must be a multiple of 6 x number1Parts.'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const number1Parts = randomIntegerInclusive(3, 5);

    const number1 = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % (number1Parts * 6) === 0
    });

    return { number1, number1Parts };
  },
  Component: props => {
    const {
      question: { number1, number1Parts },
      translate
    } = props;

    const barModels = [
      {
        number: [new Array(number1Parts).fill(number1 / number1Parts)],
        string: [new Array(number1Parts).fill(ALGEBRAIC_A)]
      },
      {
        number: [new Array(3).fill(number1 / number1Parts / 3)],
        string: [new Array(3).fill(ALGEBRAIC_B)]
      },
      {
        number: [new Array(2).fill(number1 / number1Parts / 6)],
        string: [new Array(2).fill(ALGEBRAIC_C)]
      }
    ];

    const barColors = [
      colors.pacificBlue600,
      colors.acidGreen,
      colors.dangerLight,
      ...shuffle(Object.values(barModelColors), {
        random: seededRandom(props.question)
      })
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={800}
        sentence={`${ALGEBRAIC_C} = <ans/>`}
        title={translate.instructions.calculateValueOf(ALGEBRAIC_C)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(number1 / number1Parts / 6).toString()]}
        Content={({ dimens }) => (
          <>
            {barModels.map((bar, index) => (
              <View key={index} style={{ padding: 8 }}>
                <BarModel
                  key={index}
                  numbers={bar.number}
                  strings={bar.string}
                  total={number1}
                  dimens={dimens}
                  proportional
                  sameRowColor
                  cellStyle={{ backgroundColor: barColors[index] }}
                  rowHeight={dimens.height / 5}
                  textStyle={{
                    color:
                      barColors[index] === colors.pacificBlue600 ||
                      barColors[index] === colors.dangerLight
                        ? 'white'
                        : colors.prussianBlue
                  }}
                  letterEmWidth={0.4}
                  topBraceText={index === 0 ? number1.toLocaleString() : undefined}
                />
              </View>
            ))}
          </>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivisionUsingFactors',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
