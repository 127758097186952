// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import Multiples from './1Multiples';
import CommonMultiples from './2CommonMultiples';
import Factors from './3Factors';
import CommonFactors from './4CommonFactors';
import PrimeNumbers from './5PrimeNumbers';
import SquareNumbers from './6SquareNumbers';
import CubeNumbers from './7CubeNumbers';
import MultiplyBy10100And1000 from './8MultiplyBy10100And1000';
import DivideBy10100And1000 from './9DivideBy10100And1000';
import MultiplesOf10100And1000 from './10MultiplesOf10100And1000';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionA',
  field: 'Number',
  weeks: [6, 8],
  smallSteps: [
    Multiples, // Step 1
    CommonMultiples, // Step 2
    Factors, // Step 3
    CommonFactors, // Step 4
    PrimeNumbers, // Step 5
    SquareNumbers, // Step 6
    CubeNumbers, // Step 7
    MultiplyBy10100And1000, // Step 8
    DivideBy10100And1000, // Step 9
    MultiplesOf10100And1000 // Step 10
  ]
});
export default Block;
