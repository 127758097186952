import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { ADD } from '../../../../constants';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { arraysHaveSameContents, countRange, filledArray } from '../../../../utils/collections';
import { isInRange } from '../../../../utils/matchers';
import QF21DraggableTenFrames, {
  totalCountersByColorIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ba5',
  description: 'ba5',
  keywords: ['Number bonds', 'Add', 'Number sentences', 'Counters', 'Ten frame'],
  schema: z
    .object({
      num1: z.number().int().min(0).max(9),
      num2: z.number().int().min(0).max(9),
      counterColors: z.array(z.enum(['red', 'yellow', 'blue', 'green'])).length(2)
    })
    .refine(
      ({ num1, num2 }) => isInRange(3, 9)(num1 + num2),
      'total of num1 and num2 should be between 3 and 9'
    ),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(0, 9);
    const num2 = randomIntegerInclusive(0, 9, { constraint: x => isInRange(3, 9)(x + num1) });
    const counterColors = getRandomSubArrayFromArray(
      ['red', 'yellow', 'blue', 'green'] as const,
      2
    );

    return {
      num1,
      num2,
      counterColors
    };
  },
  Component: ({ question, translate }) => {
    const { num1, num2, counterColors } = question;

    const [counter1, counter2] = counterColors;

    const eq = `${num1.toLocaleString()} ${ADD} ${num2.toLocaleString()} = ${(
      num1 + num2
    ).toLocaleString()}`;

    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragCountersToShowX(eq)}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToShowX(eq)}
        items={counterColors}
        testCorrect={userAnswer =>
          totalCountersByColorIs([counter1, num1], [counter2, num2])(userAnswer) ||
          totalCountersByColorIs([counter1, num2], [counter2, num1])(userAnswer)
        }
        exampleCorrectAnswer={[[...filledArray(counter1, num1), ...filledArray(counter2, num2)]]}
        customMarkSchemeAnswerText={translate.markScheme.acceptEitherColourForEitherPart()}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ba6',
  description: 'ba6',
  keywords: ['Number bonds', 'Add'],
  schema: z
    .object({
      total: z.number().int().min(3).max(9),
      bonds: z
        .array(z.tuple([z.number().int().min(0).max(10), z.number().int().min(0).max(10)]))
        .length(9)
    })
    .refine(
      ({ bonds }) => bonds.every(([num1, num2]) => num1 + num2 <= 10),
      'totals should be less than 10'
    ),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(3, 9);
    const numCorrect = randomIntegerInclusive(3, Math.min(8, total));

    const correct1numbers = randomUniqueIntegersInclusive(0, total, numCorrect);
    const correctBonds = correct1numbers.map(i => [total - i, i] as [number, number]);

    const remainingNum1 = randomUniqueIntegersInclusive(0, 9, 9 - numCorrect);
    const remainingBonds = remainingNum1.map(num1 => {
      const num2 = randomIntegerInclusive(0, 9, {
        constraint: x => num1 + x !== total && num1 + x <= 10
      });
      return [num1, num2] as [number, number];
    });

    return {
      total,
      bonds: shuffle([...correctBonds, ...remainingBonds])
    };
  },
  Component: ({ question, translate }) => {
    const { bonds, total } = question;

    const items = bonds.map(([num1, num2], i) => ({
      component: `${num1.toLocaleString()} ${ADD} ${num2.toLocaleString()}`,
      value: i,
      isCorrect: num1 + num2 === total
    }));

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectTheNumberBondsToNum(total)}
        pdfTitle={translate.ks1PDFInstructions.tickTheNumberBondsToNum(total)}
        items={items}
        multiSelect
        testCorrect={items.filter(it => it.isCorrect).map(it => it.value)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ba7',
  description: 'ba7',
  keywords: ['Number bonds', 'Add'],
  schema: z.object({
    total: z.number().int().min(2).max(9),
    isFlipped: z.boolean()
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(2, 9);
    const isFlipped = getRandomFromArrayWithWeights([false, true], [3, 1]);

    return {
      total,
      isFlipped
    };
  },
  Component: ({ question, translate }) => {
    const { total, isFlipped } = question;

    const sentence = isFlipped
      ? `${total.toLocaleString()} = <ans/> ${ADD} <ans/>`
      : `<ans/> ${ADD} <ans/> = ${total.toLocaleString()}`;

    return (
      <QF2AnswerBoxManySentences
        title={translate.ks1Instructions.completeTheNumberBondsToXFindTwoDifferentWays(total)}
        sentences={countRange(2).map(_ => sentence)}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          !arraysHaveSameContents(userAnswer[0], userAnswer[1]) &&
          userAnswer.every(([num1, num2]) => Number(num1) + Number(num2) === total)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [total.toLocaleString(), (0).toLocaleString()],
            [(total - 1).toLocaleString(), (1).toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyCombinationThatAddUpToX(total)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumberBondsWithin10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
