import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { range } from '../../../utils/collections';
import { Dimens, ScaleFactorContext } from '../../../theme/scaling';
import Table from '../../molecules/Table';
import { useContext } from 'react';
import { colors } from '../../../theme/colors';
import Svg, { G, Path } from 'react-native-svg';
import Text from '../../typography/Text';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /**
   * Dimensions of the grid and shape to show.
   * Within each grid cell, we can show a transparent square with a 'false' boolean value,
   * a coloured square with a 'true' boolean value,
   * or a coloured right-angle triangle with orientations with 'bottomLeft', 'bottomRight', 'topLeft' or 'topRight' string values.
   */
  givenShape: (boolean | string)[][];
  /**
   * Size of each square in the grid. Optional prop, defaults to calculations determined by the givenShape and dimens.
   */
  gridCellSize?: number;
  borderWidthAmount?: number;
  noGrid?: boolean;
  /**
   * Colors to assign to each Cell.
   * If any Cells do not have any style passed from this 2D array,
   * it will default to either pacificBlue or white, depending on the Cell's boolean value.
   */
  cellColorMap?: (string | undefined)[][];
  /**
   * Label to assign to the top-right Cell.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  cellSizeLabel?: string;
  /**
   * Prop to determine that the cells should not be squares, allowing the shape to just take up the entire dimens passed in.
   * Optional prop, defaults to false.
   * Note that using this alongside cellSizeLabel or displaying right-angle triangles may lead to strange results,
   * and should not be used with this prop.
   */
  cellsAreNotSquares?: boolean;
  /**
   * Dimensions
   */
  dimens: Dimens;
};

export const RightAngleGridTriangles = ({
  dimens,
  width,
  orientation,
  color,
  styles
}: {
  dimens: number;
  width?: number;
  orientation: string | boolean;
  color?: string;
  styles?: StyleProp<ViewStyle>;
}) => {
  const rotation = (() => {
    switch (orientation) {
      case 'bottomLeft':
      default:
        return '0deg';
      case 'topLeft':
        return '90deg';
      case 'topRight':
        return '180deg';
      case 'bottomRight':
        return '270deg';
    }
  })();

  const fillColor = color ?? colors.pacificBlue;

  return (
    <View style={[{ transform: [{ rotateZ: rotation }] }, styles]}>
      <Svg width={`${width ?? dimens}px`} height={`${dimens}px`}>
        <G>
          <Path
            fill={fillColor}
            d={
              'M -0.5,-0.5 C -0.166667,-0.5 0.166667,-0.5 0.5,-0.5C 199.833,199.5 399.5,399.167 599.5,598.5C 599.5,598.833 599.5,599.167 599.5,599.5C 399.5,599.5 199.5,599.5 -0.5,599.5C -0.5,399.5 -0.5,199.5 -0.5,-0.5 Z'
            }
          />
        </G>
      </Svg>
    </View>
  );
};

export const DisplayShapeOnGrid = ({
  givenShape,
  gridCellSize,
  borderWidthAmount,
  noGrid,
  cellColorMap,
  cellSizeLabel,
  cellsAreNotSquares = false,
  dimens
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';
  const styles = useStyles();

  // Firstly, calculate various layout dimensions.
  const maxWidth = dimens.width * 0.9;
  const maxHeight = dimens.height * 0.9;
  const squareDimens =
    gridCellSize ??
    Math.round(Math.min(maxHeight / givenShape.length, maxWidth / givenShape[0].length));

  const [cellWidth, cellHeight] = cellsAreNotSquares
    ? [maxWidth / givenShape[0].length, maxHeight / givenShape.length]
    : [];

  const tableWidth = cellsAreNotSquares ? maxWidth : squareDimens * givenShape[0].length;

  const tableHeight = cellsAreNotSquares ? maxHeight : squareDimens * givenShape.length;

  const scaleFactor = useContext(ScaleFactorContext);
  const borderWidth =
    borderWidthAmount ??
    Math.round(isPdf ? Math.max(4, 4 / scaleFactor) : Math.max(2, 2 / scaleFactor));

  // Now return a table
  return (
    <View style={{ width: tableWidth }}>
      {cellSizeLabel && (
        <View style={{ alignSelf: 'flex-end', alignItems: 'flex-end' }}>
          <Text style={styles.cellLabel}>{cellSizeLabel}</Text>
          <Svg width={squareDimens} height={20}>
            <Path d={'M0,10 L10,5 L10,15 Z'} fill={colors.prussianBlue} />
            <Path
              d={`M5,10 L${squareDimens - 5},10`}
              stroke={colors.prussianBlue}
              strokeWidth={isPdf ? 4 : 2}
            />
            <Path
              d={`M${squareDimens},10
                    L${squareDimens - 10},5
                    L${squareDimens - 10},15
                    Z`}
              fill={colors.prussianBlue}
            />
          </Svg>
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        <Table
          style={{ backgroundColor: 'white', width: tableWidth, height: tableHeight }}
          rowStyle={{ flex: 1, height: cellHeight ?? squareDimens }}
          cellStyle={{ flex: 1, width: cellWidth ?? squareDimens }}
          borderWidthAmount={borderWidth}
          noGrid={noGrid}
          items={range(0, givenShape.length - 1).map(rowIndex =>
            range(0, givenShape[0].length - 1).map(columnIndex => {
              const cellColor =
                cellColorMap && cellColorMap[rowIndex] && cellColorMap[rowIndex][columnIndex];
              return typeof givenShape[rowIndex][columnIndex] === 'boolean' &&
                givenShape[rowIndex][columnIndex] ? (
                <View
                  key={`${rowIndex}-${columnIndex}`}
                  style={{
                    flex: 1,
                    alignSelf: 'stretch',
                    backgroundColor: cellColor ?? colors.pacificBlue
                  }}
                />
              ) : typeof givenShape[rowIndex][columnIndex] === 'string' ? (
                <RightAngleGridTriangles
                  dimens={squareDimens}
                  orientation={givenShape[rowIndex][columnIndex]}
                  color={cellColor}
                />
              ) : undefined;
            })
          )}
        />
        {cellSizeLabel && (
          <View style={{ flexDirection: 'row', alignSelf: 'flex-start' }}>
            <Svg width={20} height={squareDimens}>
              <Path d={'M10,0 L5,10 L15,10 Z'} fill={colors.prussianBlue} />
              <Path
                d={`M10,5 L10,${squareDimens - 5}`}
                stroke={colors.prussianBlue}
                strokeWidth={isPdf ? 4 : 2}
              />
              <Path
                d={`M10,${squareDimens}
                    L5,${squareDimens - 10}
                    L15,${squareDimens - 10}
                    Z`}
                fill={colors.prussianBlue}
              />
            </Svg>
            <Text style={styles.cellLabel}>{cellSizeLabel}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

const useStyles = () => {
  const displayMode = useContext(DisplayMode);

  return StyleSheet.create({
    cellLabel: {
      fontSize: displayMode === 'digital' ? 20 : 40,
      alignSelf: 'center',
      lineHeight: displayMode === 'digital' ? 20 : 40
    }
  });
};
