import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getDimenShapeSvgName,
  getLollyStickShapeSvg,
  lollyShapeOptions
} from '../../../../utils/shapeImages/shapes';
import { irregularShapesWithArrowsSvgs } from '../../../../utils/shapeImages/irregular';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { sortNumberArray, sumNumberArray } from '../../../../utils/collections';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import { ShapeNames } from '../../../../utils/labelPositions';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { LabelledHexagon } from '../../../../components/question/representations/LabelledHexagon';
import { LabelledPentagon } from '../../../../components/question/representations/LabelledPentagon';
import { irregularSymmetricalShapesWithArrowsSvgs } from '../../../../utils/shapeImages/symmetry';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aL0',
  description: 'aL0',
  keywords: ['Polygons', 'Irregular', 'Regular', 'Perimeter'],
  schema: z.object({
    shape: z.enum(lollyShapeOptions),
    length: z.number().int().min(3).max(10),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(lollyShapeOptions);
    const length = randomIntegerInclusive(3, 10);
    const name = getRandomName();

    return { shape, length, name };
  },
  Component: props => {
    const {
      question: { shape, length, name },
      translate
    } = props;

    const sides = {
      square: 4,
      pentagon: 5,
      decagon: 10,
      heptagon: 7,
      hexagon: 6,
      nonagon: 9,
      octagon: 8,
      quadrilateral: 4,
      rectangle: 4,
      triangle: 3
    }[shape];

    const seed = props.question;
    const svgName = getLollyStickShapeSvg(shape, seed);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterUsesLollySticksToMakePolygonWorkOutPerim(
          name,
          length
        )}
        Content={({ dimens }) => (
          <AssetSvg name={svgName} height={dimens.height} width={dimens.width} />
        )}
        pdfDirection="column"
        questionHeight={1000}
        sentence={translate.answerSentences.ansCm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(sides * length).toString()]}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aL1',
  description: 'aL1',
  keywords: ['Polygons', 'Irregular', 'Regular'],
  schema: z.object({
    shape: z.enum(['triangles', 'quadrilaterals', 'pentagons', 'hexagons']),
    svgName: z.string(),
    baseLength: z.number().min(1).max(5),
    lengths: z.number().int().min(1).max(15).array(),
    labelComponent: z.enum(['shape', 'triangle', 'quadrilateral', 'octagons']),
    isRegular: z.boolean()
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'triangles',
      'quadrilaterals',
      'pentagons',
      'hexagons'
    ] as const);

    const svgProps = getRandomFromArray([...irregularShapesWithArrowsSvgs[shape]] as const);

    const svgName = svgProps?.svgName as SvgName;

    const { lengths, baseLength } = rejectionSample(
      () => {
        const baseLength = randomIntegerInclusive(1, 5);
        const ratios = svgProps?.sideRatios as number[];
        const lengths = ratios.map(val => baseLength * val);
        return { lengths, baseLength };
      },
      val => val.lengths.every(val => val <= 15)
    );

    const labelComponent = svgProps?.labelComponent as 'shape' | 'triangle' | 'quadrilateral';
    const isRegular = getRandomBoolean();

    return { shape, baseLength, svgName, lengths, labelComponent, isRegular };
  },
  Component: props => {
    const {
      question: { shape, lengths, baseLength, svgName, labelComponent, isRegular },
      translate
    } = props;

    const labels = lengths.map(val => translate.units.numberOfCm(val));
    const regularLabels = lengths.map(() => translate.units.numberOfCm(baseLength));

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheShapeThatIsX(
          isRegular ? translate.keywords.Regular() : translate.keywords.Irregular()
        )}
        testCorrect={[isRegular ? 'regular' : 'irregular']}
        numItems={2}
        renderItems={({ dimens }) => {
          return shuffle(
            [
              {
                value: 'regular',
                component:
                  shape === 'triangles' ? (
                    <LabelledTriangle
                      labels={regularLabels}
                      dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.8 }}
                      assetSvgName={getDimenShapeSvgName('equilateralTriangles', props.question)}
                    />
                  ) : shape === 'quadrilaterals' ? (
                    <LabelledQuadrilateral
                      labels={regularLabels}
                      shape={'Square4Arrows'}
                      dimens={dimens}
                    />
                  ) : shape === 'hexagons' ? (
                    <LabelledHexagon allArrows labels={regularLabels} dimens={dimens} />
                  ) : (
                    <LabelledPentagon allArrows labels={regularLabels} dimens={dimens} />
                  )
              },
              {
                value: 'irregular',
                component:
                  labelComponent === 'triangle' ? (
                    <LabelledTriangle
                      labels={labels}
                      assetSvgName={svgName as SvgName}
                      dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                    />
                  ) : labelComponent === 'quadrilateral' ? (
                    <LabelledQuadrilateral
                      labels={labels}
                      shape={'Rectangle4Arrows'}
                      dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.6 }}
                    />
                  ) : (
                    <LabelledShape
                      dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.9 }}
                      shapeName={svgName as ShapeNames}
                      labels={labels}
                    />
                  )
              }
            ],
            { random: seededRandom(props.question) }
          );
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aL2',
  description: 'aL2',
  keywords: ['Polygons', 'Irregular', 'Perimeter'],
  schema: z.object({
    shape: z.enum(['triangles', 'quadrilaterals', 'pentagons', 'hexagons']),
    svgName: z.string(),
    lengths: z.number().int().min(1).max(15).array(),
    labelComponent: z.enum(['shape', 'triangle', 'quadrilateral'])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'triangles',
      'quadrilaterals',
      'pentagons',
      'hexagons'
    ] as const);

    const svgProps = getRandomFromArray([...irregularShapesWithArrowsSvgs[shape]] as const);

    const svgName = svgProps?.svgName as SvgName;

    const { lengths } = rejectionSample(
      () => {
        const ratios = svgProps?.sideRatios as number[];
        const baseLength = randomIntegerInclusive(1, 5);
        const lengths = ratios.map(val => baseLength * val);
        return { baseLength, lengths };
      },
      val => val.lengths.every(val => val <= 15)
    );

    const labelComponent = svgProps?.labelComponent as 'shape' | 'triangle' | 'quadrilateral';

    return { shape, svgName, lengths, labelComponent };
  },
  Component: props => {
    const {
      question: { shape, lengths, svgName, labelComponent },
      translate
    } = props;

    const labels = lengths.map(val => translate.units.numberOfCm(val));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfX(translate.shapes[shape](1))}
        Content={({ dimens }) =>
          labelComponent === 'triangle' ? (
            <LabelledTriangle labels={labels} assetSvgName={svgName as SvgName} dimens={dimens} />
          ) : labelComponent === 'quadrilateral' ? (
            <LabelledQuadrilateral labels={labels} shape={'Rectangle4Arrows'} dimens={dimens} />
          ) : (
            <LabelledShape dimens={dimens} shapeName={svgName as ShapeNames} labels={labels} />
          )
        }
        pdfDirection="column"
        questionHeight={1000}
        sentence={translate.answerSentences.ansCm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[sumNumberArray(lengths).toString()]}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aL3',
  description: 'aL3',
  keywords: ['Polygons', 'Irregular', 'Perimeter', 'Symmetry'],
  schema: z.object({
    svgName: z.string(),
    lengths: z.number().int().min(1).max(15).array(),
    missingSides: z.number().int().min(0).array(),
    linesOfSymmetry: z.number().int(),
    labelComponent: z.enum(['shape', 'triangle', 'quadrilateral'])
  }),
  simpleGenerator: () => {
    const svgProps = getRandomFromArray([...irregularSymmetricalShapesWithArrowsSvgs] as const);

    const svgName = svgProps?.svgName as SvgName;

    const { lengths } = rejectionSample(
      () => {
        const ratios = svgProps?.sideRatios as number[];
        const baseLength = randomIntegerInclusive(1, 5);
        const lengths = ratios.map(val => baseLength * val);
        return { baseLength, lengths };
      },
      val => val.lengths.every(val => val <= 15)
    );

    const labelComponent = svgProps?.labelComponent as 'shape' | 'triangle' | 'quadrilateral';
    const missingSides = svgProps?.missingSides as number[];
    const linesOfSymmetry = svgProps?.linesOfSymmetry as number;

    return { missingSides, linesOfSymmetry, svgName, lengths, labelComponent };
  },
  Component: props => {
    const {
      question: { missingSides, linesOfSymmetry, lengths, svgName, labelComponent },
      translate
    } = props;

    const labels = lengths.map((val, i) =>
      missingSides.includes(i) ? '' : translate.units.numberOfCm(val)
    );

    return (
      <QF1ContentAndSentence
        title={
          linesOfSymmetry === 1
            ? translate.instructions.shapeHasOneLineOfSymmetryWorkOutPerim()
            : translate.instructions.shapeHasXLinesOfSymmetryWorkOutPerim(
                translate.numbersAsWords[linesOfSymmetry as 1 | 2]()
              )
        }
        Content={({ dimens }) =>
          labelComponent === 'triangle' ? (
            <LabelledTriangle labels={labels} assetSvgName={svgName as SvgName} dimens={dimens} />
          ) : labelComponent === 'quadrilateral' ? (
            <LabelledQuadrilateral labels={labels} shape={'Rectangle4Arrows'} dimens={dimens} />
          ) : (
            <LabelledShape dimens={dimens} shapeName={svgName as ShapeNames} labels={labels} />
          )
        }
        pdfDirection="column"
        questionHeight={1000}
        sentence={translate.answerSentences.ansCm()}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[sumNumberArray(lengths).toString()]}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aL4',
  description: 'aL4',
  keywords: ['Polygons', 'Irregular', 'Perimeter', 'Missing length'],
  schema: z.object({
    shape: z.enum(['triangles', 'quadrilaterals', 'pentagons', 'hexagons', 'octagons']),
    svgName: z.string(),
    missingLength: z.number().int().min(0),
    lengths: z.number().int().min(1).max(15).array(),
    labelComponent: z.enum(['shape', 'triangle', 'quadrilateral'])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'triangles',
      'quadrilaterals',
      'pentagons',
      'hexagons'
    ] as const);

    const svgProps = getRandomFromArray([...irregularShapesWithArrowsSvgs[shape]] as const);

    const svgName = svgProps?.svgName as SvgName;

    const { lengths } = rejectionSample(
      () => {
        const ratios = svgProps?.sideRatios as number[];
        const baseLength = randomIntegerInclusive(1, 5);
        const lengths = ratios.map(val => baseLength * val);
        return { baseLength, lengths };
      },
      val => val.lengths.every(val => val <= 15)
    );

    const labelComponent = svgProps?.labelComponent as 'shape' | 'triangle' | 'quadrilateral';
    const missingLength = randomIntegerInclusive(0, lengths.length - 1);

    return { shape, svgName, lengths, labelComponent, missingLength };
  },
  Component: props => {
    const {
      question: { shape, lengths, svgName, labelComponent, missingLength },
      translate
    } = props;

    const labels = lengths.map((val, i) =>
      i === missingLength ? '?' : translate.units.numberOfM(val)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.perimeterOfShapeIsXWorkOutUnknownLength(
          translate.shapes[shape](1),
          translate.units.numberOfM(sumNumberArray(lengths))
        )}
        Content={({ dimens }) =>
          labelComponent === 'triangle' ? (
            <LabelledTriangle labels={labels} assetSvgName={svgName as SvgName} dimens={dimens} />
          ) : labelComponent === 'quadrilateral' ? (
            <LabelledQuadrilateral labels={labels} shape={'Rectangle4Arrows'} dimens={dimens} />
          ) : (
            <LabelledShape dimens={dimens} shapeName={svgName as ShapeNames} labels={labels} />
          )
        }
        pdfDirection="column"
        questionHeight={1000}
        sentence={`? = ${translate.answerSentences.ansM()}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[lengths[missingLength].toString()]}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aL5',
  description: 'aL5',
  keywords: ['Perimeter', 'Regular', 'Polygons', 'Missing length'],
  schema: z
    .object({
      height: z.number().int().min(1).max(15),
      width: z.number().int().min(2).max(15),
      missingIndex: z.number().int().min(0).max(1)
    })
    .refine(val => val.width > val.height),
  simpleGenerator: () => {
    const [height, width] = sortNumberArray(randomUniqueIntegersInclusive(1, 15, 2));
    const missingIndex = randomIntegerInclusive(0, 1);

    return { height, width, missingIndex };
  },
  Component: props => {
    const {
      question: { height, width, missingIndex },
      translate,
      displayMode
    } = props;

    const labels: string[] = [
      '',
      '',
      translate.units.numberOfCm(width),
      translate.units.numberOfCm(height)
    ];
    labels[missingIndex + 2] = '<ans/>';

    const perimeter = 2 * (height + width);

    const answer = missingIndex === 0 ? width : height;

    return (
      <QF3InteractiveContent
        title={translate.instructions.rectangleHasPerimOfXWorkOutUnknown(perimeter)}
        initialState={displayMode === 'markscheme' ? [answer.toLocaleString()] : ['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <View style={missingIndex === 0 ? { top: -80 } : { left: 80 }}>
            <LabelledQuadrilateral
              dimens={dimens}
              shape={'Rectangle2Arrows'}
              labels={labels}
              shapeSize="large"
              symbol={translate.units.cm()}
              userAnswer={userAnswer}
              setUserAnswer={answer => {
                const newArr = [...answer];
                setUserAnswer(newArr);
              }}
            />
          </View>
        )}
        inputType="numpad"
        testCorrect={userAnswer => userAnswer[0] === answer.toString()}
        testComplete={answer => answer.every(it => it !== '')}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PerimeterOfPolygons',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
