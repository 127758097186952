import { CounterVariant } from '../representations/types';
import {
  getDraggableItems,
  numberToColState
} from '../representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation } from '../../../utils/math';
import { PlaceValueColumn } from '../representations/Place Value Chart/PlaceValueCounters';
import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { HeaderVariant } from '../representations/Place Value Chart/TableHeaderRow';
import { useI18nContext } from '../../../i18n/i18n-react';
import { filledArray } from '../../../utils/collections';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import EasyDragAndDropWithGrid from '../../draganddrop/EasyDragAndDropWithGrid';
import MultiRowPlaceValueChartInteractive from '../representations/Place Value Chart/MultiRowPlaceValueChartInteractive';
import { isNotEqual } from '../../../utils/matchers';
import { equal } from 'mathjs';

type Props = TitleStyleProps & {
  title?: string;
  pdfTitle?: string;
  initialStatePerRow?: number;
  /**
   * This is the correct answer per row. At the moment this assumes that all place value charts have the same correct answer.
   */
  correctAnswerPerRow: ScientificNotation;
  numOfRows: number;
  columnsToShow: PlaceValueColumn[];
  counterVariant: CounterVariant;
  headerVariant?: HeaderVariant;
  questionHeight?: number;
};

/**
 * Question format 23a - multiple stacked draggable place value charts
 * This uses {@link PlaceValueChart} with interactive set to true.
 */
export default function QF23aDraggableMultiRowPlaceValueChart({
  title,
  pdfTitle,
  initialStatePerRow,
  correctAnswerPerRow,
  numOfRows,
  columnsToShow,
  counterVariant,
  headerVariant,
  questionHeight,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const translate = useI18nContext().LL;
  const displayTitle =
    (displayMode === 'pdf' || displayMode === 'markscheme') && pdfTitle
      ? pdfTitle
      : title ??
        translate.instructions.dragCountersToRepresentNum(
          correctAnswerPerRow.toNumber().toLocaleString()
        );

  const items = getDraggableItems(counterVariant, columnsToShow);

  const draggableSource = (
    <DragAndDropSection style={{ padding: 0 }}>
      {items.map((_item, index) => (
        <EasyDragAndDropWithGrid.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  const numRows = numOfRows;
  const numColumns = columnsToShow.length;
  const emptyState = filledArray(filledArray([], numColumns), numRows);
  const columnsPerRow = filledArray(numColumns, numRows);

  const defaultState = initialStatePerRow
    ? filledArray(
        numberToColState(
          ScientificNotation.fromNumber(initialStatePerRow),
          columnsToShow,
          counterVariant
        ),
        numRows
      )
    : emptyState;

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markschemeAnswer = columnsToShow.map(pow =>
      filledArray(
        // grey counters all have value 1 and others have their decimal value
        counterVariant === 'greyCounter' ? 1 : pow,
        correctAnswerPerRow.digitAt(pow)
      )
    );
    return (
      <EasyDragAndDropWithGrid.ProviderWithState
        id="draganddrop"
        items={items}
        moveOrCopy="copy"
        hideBackground
        defaultState={
          displayMode === 'markscheme' ? filledArray(markschemeAnswer, numOfRows) : emptyState
        }
        columnsPerRow={columnsPerRow}
        draggableStyle={{
          borderWidth: 0,
          shadowOpacity: 0,
          backgroundColor: 'transparent',
          alignItems: 'flex-start'
        }}
      >
        <BaseLayoutPDF
          title={displayTitle}
          containerStyle={styles.markSchemeContainer}
          mainPanelContents={
            <MeasureView>
              {dimens => (
                <MultiRowPlaceValueChartInteractive
                  numberPerRow={correctAnswerPerRow}
                  numOfRows={numOfRows}
                  columnsToShow={columnsToShow}
                  counterVariant={counterVariant}
                  dimens={dimens}
                  counterPerRow={4}
                  maxCapacity={4}
                />
              )}
            </MeasureView>
          }
          questionHeight={questionHeight}
        />
      </EasyDragAndDropWithGrid.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithGrid.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy="copy"
      hideBackground
      defaultState={defaultState}
      // Complete if at least one zone has at least one item.
      testComplete={isNotEqual(emptyState)}
      testCorrect={answer => {
        const noRogueCounters =
          // grey can go anywhere so they are never rogue
          counterVariant === 'greyCounter'
            ? true
            : // else the counter should map directly to the same index in the columnsToShow
              answer.every(row =>
                row.every((array, i) => array.every(counter => counter === columnsToShow[i]))
              );
        const countersTotal = filledArray(0, numOfRows);
        answer.forEach((row, rowId) => {
          row.forEach(
            (array, idx) =>
              (countersTotal[rowId] += array.length * Math.pow(10, columnsToShow[idx]))
          );
        });
        const numbersEqual = countersTotal.every(val => equal(val, correctAnswerPerRow.toNumber()));
        return noRogueCounters && numbersEqual;
      }}
      columnsPerRow={columnsPerRow}
      draggableStyle={{
        borderWidth: 0,
        shadowOpacity: 0,
        backgroundColor: 'transparent',
        alignItems: 'flex-start'
      }}
    >
      <BaseLayout
        title={displayTitle}
        actionPanelVariant={counterVariant === 'greyCounter' ? 'end' : 'bottom'}
        actionPanelContents={draggableSource}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <MultiRowPlaceValueChartInteractive
                numberPerRow={correctAnswerPerRow}
                numOfRows={numOfRows}
                columnsToShow={columnsToShow}
                counterVariant={counterVariant}
                dimens={dimens}
                counterPerRow={4}
                maxCapacity={4}
              />
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDropWithGrid.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  markSchemeContainer: {
    paddingLeft: 10
  }
});
