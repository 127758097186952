import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import deepEqual from 'react-fast-compare';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { ADD, DIV, MULT } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { isEqual, isInRange } from '../../../../utils/matchers';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import Text from '../../../../components/typography/Text';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  filledArray,
  sumNumberArray
} from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';
import { CubeTower } from '../../../../components/question/representations/CubeTower';
import { horizontalArrow } from '../../../../components/question/representations/LineSvgs';
import { AssetSvg } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bi7',
  description: 'bi7',
  keywords: ['Doubling', 'Halving'],
  questionHeight: 1100,
  schema: z.object({
    isHalving: z.boolean(),
    items: z
      .discriminatedUnion('variant', [
        z.object({
          variant: z.literal('tenFrame'),
          rhsCount: z.number().int().min(1).max(3),
          colors: z.enum(['red', 'yellow', 'blue', 'green'] as const).array(),
          numbers: z.number().int().array(),
          halvedNumber: numberEnum([1, 2, 3, 4, 5])
        }),
        z.object({
          variant: z.enum(['dice', 'hands']),
          rhsCount: z.number().int().min(1).max(3),
          numbers: z.number().int().array(),
          halvedNumber: numberEnum([1, 2, 3, 4, 5])
        }),
        z.object({
          variant: z.literal('tower'),
          rhsCount: z.number().int().min(1).max(3),
          color: z.enum(['red', 'yellow', 'blue', 'green'] as const),
          numbers: z.number().int().array(),
          halvedNumber: numberEnum([1, 2, 3, 4, 5])
        })
      ])
      .array()
      .length(4)
  }),
  simpleGenerator: () => {
    const isHalving = getRandomBoolean();

    const items = rejectionSample(
      () =>
        countRange(4).map(() => {
          const representation = getRandomFromArray([
            'tenFrame',
            'dice',
            'tower',
            'hands'
          ] as const);
          const halvedNumber = getRandomFromArray([1, 2, 3, 4, 5] as const);
          const numberOf = randomIntegerInclusive(1, 3);
          const isAddTwo = getRandomBoolean();
          const item = (() => {
            switch (representation) {
              case 'dice':
              case 'hands':
                return {
                  variant: representation,
                  rhsCount: isAddTwo ? 2 : numberOf,
                  numbers: isAddTwo ? [halvedNumber, 2] : filledArray(halvedNumber, numberOf),
                  halvedNumber
                };
              case 'tenFrame': {
                return {
                  variant: representation,
                  rhsCount: isAddTwo ? 1 : numberOf,
                  colors: getRandomSubArrayFromArray(
                    ['red', 'yellow', 'blue', 'green'] as const,
                    numberOf === 1 && !isAddTwo ? randomIntegerInclusive(1, 2) : 1
                  ),
                  numbers: isAddTwo ? [halvedNumber + 2] : filledArray(halvedNumber, numberOf),
                  halvedNumber
                };
              }
              case 'tower':
                return {
                  variant: representation,
                  rhsCount: isAddTwo ? 2 : numberOf,
                  color: getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const),
                  numbers: isAddTwo ? [halvedNumber, 2] : filledArray(halvedNumber, numberOf),
                  halvedNumber
                };
            }
          })();
          return item;
        }),
      value => {
        const numberCorrect = value.filter(
          obj =>
            isEqual(sumNumberArray(obj.numbers))(obj.halvedNumber * 2) || obj.colors?.length === 2
        ).length;
        return (
          numberCorrect >= 1 &&
          numberCorrect < 4 &&
          arrayHasNoDuplicates(
            // don't want the same representation just with different colors
            value.map(({ color, colors, ...rest }) => rest),
            deepEqual
          )
        );
      }
    );

    return {
      isHalving,
      items
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { isHalving, items } = question;

    const correctAnswers = items
      .map((val, id) => ({
        numbers: val.numbers,
        halvedNumber: val.halvedNumber,
        value: ['A', 'B', 'C', 'D'][id],
        colors: val.variant === 'tenFrame' ? val.colors : []
      }))
      .filter(
        obj =>
          isEqual(sumNumberArray(obj.numbers))(obj.halvedNumber * 2) || obj.colors?.length === 2
      )
      .map(val => val.value);

    return (
      <QF11SelectImagesUpTo4
        title={
          isHalving
            ? correctAnswers.length === 1
              ? translate.ks1Instructions.selectThePictureThatShowsHalving()
              : translate.ks1Instructions.selectThePicturesThatShowHalving()
            : correctAnswers.length === 1
            ? translate.ks1Instructions.selectThePictureThatShowsDoubling()
            : translate.ks1Instructions.selectThePicturesThatShowDoubling()
        }
        renderItems={({ dimens }) =>
          items.map((item, id) => {
            const [lhs, rhs] = (() => {
              switch (item.variant) {
                case 'tower': {
                  const componentHalf = (
                    <View
                      style={{
                        height: (dimens.height / 6) * Math.max(...item.numbers),
                        justifyContent: 'flex-end'
                      }}
                    >
                      <CubeTower
                        key={'single'}
                        color={item.color}
                        count={item.halvedNumber}
                        dimens={{
                          height: (dimens.height / 6) * item.halvedNumber,
                          width: dimens.width / 6
                        }}
                      />
                    </View>
                  );
                  const componentDouble = (
                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                      {countRange(item.rhsCount).map(index => (
                        <CubeTower
                          key={index}
                          color={item.color}
                          count={item.numbers[index]}
                          dimens={{
                            height: (dimens.height / 6) * item.numbers[index],
                            width: dimens.width / 6
                          }}
                        />
                      ))}
                    </View>
                  );

                  return isHalving
                    ? [componentDouble, componentHalf]
                    : [componentHalf, componentDouble];
                }
                case 'hands':
                case 'dice': {
                  const componentHalf = (
                    <AssetSvg
                      name={
                        item.variant === 'dice'
                          ? `Dice_faces/${item.halvedNumber}`
                          : `fingers/Hand_${item.halvedNumber}`
                      }
                      height={dimens.width / (item.variant === 'hands' ? 4.5 : 6)}
                    />
                  );
                  const componentDouble = (
                    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                      {item.numbers.map((val, i) => (
                        <AssetSvg
                          key={i}
                          name={
                            item.variant === 'dice'
                              ? `Dice_faces/${val as 1 | 2 | 3 | 4 | 5}`
                              : `fingers/Hand_${val as 1 | 2 | 3 | 4 | 5}`
                          }
                          height={dimens.width / (item.variant === 'hands' ? 4.5 : 6)}
                        />
                      ))}
                    </View>
                  );
                  return isHalving
                    ? [componentDouble, componentHalf]
                    : [componentHalf, componentDouble];
                }
                case 'tenFrame': {
                  const componentHalf = (
                    <TenFrameLayout
                      items={filledArray(item.colors[0], item.halvedNumber)}
                      size={displayMode === 'digital' ? 'xxsmall' : 'xsmall'}
                      orientation="vertical"
                      itemOrdering="columnFirst"
                    />
                  );
                  const componentDouble = (
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                      {countRange(item.rhsCount).map((val, i) => (
                        <TenFrameLayout
                          key={val}
                          items={item.colors
                            .map(val => [
                              ...filledArray(val, item.numbers[i]),
                              ...(item.colors.length > 1
                                ? filledArray(undefined, 5 - item.numbers[i])
                                : [])
                            ])
                            .flat()}
                          size={displayMode === 'digital' ? 'xxsmall' : 'xsmall'}
                          orientation="vertical"
                          itemOrdering="columnFirst"
                        />
                      ))}
                    </View>
                  );

                  return isHalving
                    ? [componentDouble, componentHalf]
                    : [componentHalf, componentDouble];
                }
              }
            })();

            return {
              value: ['A', 'B', 'C', 'D'][id],
              component: (
                <View
                  key={`${item}_${id}`}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: item.variant === 'tower' ? 0 : 10
                  }}
                >
                  {lhs}
                  <View
                    style={{
                      height: dimens.height,
                      justifyContent: 'center'
                    }}
                  >
                    {horizontalArrow(50)}
                  </View>
                  {rhs}
                </View>
              )
            };
          })
        }
        multiSelect
        testCorrect={correctAnswers}
        numItems={4}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bi8',
  description: 'bi8',
  keywords: ['Double', 'Halve'],
  questionHeight: 900,
  schema: z
    .object({
      number: z.number().int().min(0).max(20),
      isDouble: z.boolean(),
      answerIndex: z.number().int().min(0).max(1)
    })
    .refine(
      val =>
        (val.isDouble && isInRange(0, 10)(val.number)) ||
        (!val.isDouble && isInRange(2, 20)(val.number) && val.number % 2 === 0)
    ),
  simpleGenerator: () => {
    const isDouble = getRandomBoolean();
    const number = isDouble ? randomIntegerInclusive(0, 10) : randomIntegerInclusiveStep(2, 20, 2);
    const answerIndex = randomIntegerInclusive(0, 1);

    return {
      isDouble,
      number,
      answerIndex
    };
  },
  Component: ({ question, translate }) => {
    const { isDouble, number, answerIndex } = question;

    const [sentences, answers] = (() => {
      if (isDouble) {
        return [
          [
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} <ans/> ${ADD} <ans/>`,
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} ${
              answerIndex === 0 ? '<ans/>' : number.toLocaleString()
            } ${MULT} ${answerIndex === 1 ? '<ans/>' : (2).toLocaleString()}`,
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} <ans/>`
          ],
          ([userAns1, userAns2, userAns3]: string[][]) => {
            const doubleNum = number * 2;
            return (
              isEqual(Number(userAns1[0]) + Number(userAns1[1]))(doubleNum) &&
              isEqual(userAns2)([(answerIndex === 0 ? number : 2).toString()]) &&
              isEqual(userAns3)([doubleNum.toString()])
            );
          }
        ];
      } else {
        return [
          [
            `${translate.ks1AnswerSentences.halfOfXIsEqualTo(number)} ${
              answerIndex === 0 ? '<ans/>' : number.toLocaleString()
            } ${DIV} ${answerIndex === 1 ? '<ans/>' : (2).toLocaleString()}`,
            `${translate.ks1AnswerSentences.halfOfXIsEqualTo(number)} <ans/>`
          ],
          (userAnswer: string[][]) =>
            arraysHaveSameContents(
              [[(answerIndex === 0 ? number : 2).toString()], [(number / 2).toString()]],
              userAnswer,
              deepEqual
            )
        ];
      }
    })();

    return (
      <QF2AnswerBoxManySentences
        questionHeight={900}
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={sentences}
        testCorrect={userAnswer => answers(userAnswer)}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: isDouble
            ? [
                [number.toLocaleString(), number.toLocaleString()],
                [(answerIndex === 0 ? number : 2).toLocaleString()],
                [(number * 2).toLocaleString()]
              ]
            : [
                [(answerIndex === 0 ? number : 2).toLocaleString()],
                [(number / 2).toLocaleString()]
              ],
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'bi82',
  description: 'bi8',
  keywords: ['Double', 'Halve'],
  questionHeight: 900,
  schema: z
    .object({
      number: z.number().int().min(0).max(20),
      isDouble: z.boolean(),
      answerIndex: z.number().int().min(0).max(1)
    })
    .refine(
      val =>
        (val.isDouble && isInRange(0, 10)(val.number)) ||
        (!val.isDouble && isInRange(2, 20)(val.number) && val.number % 2 === 0)
    ),
  simpleGenerator: () => {
    const isDouble = getRandomBoolean();
    const number = isDouble ? randomIntegerInclusive(0, 10) : randomIntegerInclusiveStep(2, 20, 2);
    const answerIndex = randomIntegerInclusive(0, 1);

    return {
      isDouble,
      number,
      answerIndex
    };
  },
  Component: ({ question, translate }) => {
    const { isDouble, number, answerIndex } = question;

    const [sentences, answers] = (() => {
      if (isDouble) {
        return [
          [
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(
              number
            )} ${number.toLocaleString()} ${ADD} <ans/>`,
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} ${
              answerIndex === 0 ? '<ans/>' : number.toLocaleString()
            } ${MULT} ${answerIndex === 1 ? '<ans/>' : (2).toLocaleString()}`,
            `${translate.ks1AnswerSentences.doubleXIsEqualTo(number)} <ans/>`
          ],
          [
            [number.toLocaleString()],
            [(answerIndex === 0 ? number : 2).toLocaleString()],
            [(number * 2).toLocaleString()]
          ]
        ];
      } else {
        return [
          [
            `${translate.ks1AnswerSentences.halfOfXIsEqualTo(number)} ${
              answerIndex === 0 ? '<ans/>' : number.toLocaleString()
            } ${DIV} ${answerIndex === 1 ? '<ans/>' : (2).toLocaleString()}`,
            `${translate.ks1AnswerSentences.halfOfXIsEqualTo(number)} <ans/>`
          ],
          [[(answerIndex === 0 ? number : 2).toLocaleString()], [(number / 2).toLocaleString()]]
        ];
      }
    })();

    return (
      <QF2AnswerBoxManySentences
        questionHeight={900}
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={sentences}
        testCorrect={answers}
        inputMaxCharacters={2}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bi9',
  description: 'bi9',
  keywords: ['Double', 'Halve'],
  schema: z.object({
    number: z.number().int().min(1).max(12),
    variation: z.enum(['doubleEqualAns', 'doubleAns', 'halfEqualAns', 'halfAns']),
    options: z
      .number()
      .int()
      .max(100)
      .array()
      .length(4)
      .refine(val => arrayHasNoDuplicates(val), 'All options must be different.')
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArrayWithWeights(
      ['doubleEqualAns', 'doubleAns', 'halfEqualAns', 'halfAns'] as const,
      [4, 1, 4, 1]
    );

    const [number, options] = (() => {
      switch (variation) {
        case 'doubleEqualAns': {
          const num = randomIntegerInclusive(1, 5);
          const other = num === 2 ? 3 : num + 2;
          return [num, [num * 2, num * 20, other, other * 10]];
        }
        case 'doubleAns': {
          const num = randomIntegerInclusiveStep(2, 4, 2);
          return [num, [num / 2, num * 5, num * 2, num * 20]];
        }
        case 'halfEqualAns': {
          const num = randomIntegerInclusiveStep(2, 10, 2);
          const other = num === 4 ? 3 : num === 2 ? 4 : num - 2;
          return [num, [num / 2, num * 5, other, other * 10]];
        }
        case 'halfAns': {
          const num = randomIntegerInclusive(1, 5);
          const other = num <= 2 ? num - 1 : num - 2;
          // If num is 1, our incorrect options must be 0 and 5, otherwise they all follow the same pattern:
          return [num, [num * 2, num * 20, num === 1 ? 0 : other, num === 1 ? 5 : other * 10]];
        }
      }
    })();

    return {
      number,
      variation,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { number, variation, options },
      translate
    } = props;

    const [sentences, answers] = (() => {
      switch (variation) {
        case 'doubleEqualAns':
          return [
            [
              translate.ks1AnswerSentences.doubleXEqualsY(number, '<ans/>'),
              translate.ks1AnswerSentences.soDoubleXEqualsY(
                (number * 10).toLocaleString(),
                '<ans/>'
              )
            ],
            [[number * 2], [number * 20]]
          ];
        case 'doubleAns':
          return [
            [
              translate.ks1AnswerSentences.doubleXEqualsY('<ans/>', number),
              translate.ks1AnswerSentences.soDoubleXEqualsY(
                '<ans/>',
                (number * 10).toLocaleString()
              )
            ],
            [[number / 2], [number * 5]]
          ];
        case 'halfEqualAns':
          return [
            [
              translate.ks1AnswerSentences.halfXEqualsY(number, '<ans/>'),
              translate.ks1AnswerSentences.soHalfXEqualsY((number * 10).toLocaleString(), '<ans/>')
            ],
            [[number / 2], [number * 5]]
          ];
        case 'halfAns':
          return [
            [
              translate.ks1AnswerSentences.halfXEqualsY('<ans/>', number),
              translate.ks1AnswerSentences.soHalfXEqualsY('<ans/>', (number * 10).toLocaleString())
            ],
            [[number * 2], [number * 20]]
          ];
      }
    })();

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheSentences()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentences()}
        sentences={sentences}
        testCorrect={answers}
        pdfLayout="itemsTop"
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        items={options.map(value => ({
          value,
          component: <Text variant="WRN700">{value.toLocaleString()}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const SmallStep = newSmallStepContent({
  smallStep: 'DoublingAndHalving',
  questionTypes: [Question1, Question2v2, Question3],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
