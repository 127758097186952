import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomNumberInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { AssetSvg } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import deepEqual from 'react-fast-compare';
import QF67SelectingRandomlyArrangedShapes from '../../../../components/question/questionFormats/QF67SelectingRandomlyArrangedShapes';
import { get3DShapeFullColorsSVGPath } from '../../../../utils/threeDShapes';

////
// Questions
////

const q1Shapes = ['circle', 'rectangle', 'square', 'triangle'] as const;
const q1Colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

const Question1 = newQuestionContent({
  uid: 'bbO',
  description: 'bbO',
  keywords: ['2-D shape', 'Circle', 'Triangle', 'Rectangle', 'Square'],
  schema: z.object({
    correctShape: z.enum(q1Shapes),
    items: z
      .array(
        z.object({
          shape: z.enum(q1Shapes),
          color: z.enum(q1Colors)
        })
      )
      .length(4)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must have no duplicates')
  }),
  simpleGenerator: () => {
    const correctShape = getRandomFromArray(q1Shapes);

    const [shapeA, shapeB, shapeC, shapeD] = getRandomSubArrayFromArray(q1Shapes, 4);

    const [colorA, colorB, colorC, colorD] = getRandomSubArrayFromArray(q1Colors, 4);

    const items = shuffle([
      { shape: shapeA, color: colorA },
      { shape: shapeB, color: colorB },
      { shape: shapeC, color: colorC },
      { shape: shapeD, color: colorD }
    ]);

    return { correctShape, items };
  },
  Component: ({ question: { correctShape, items }, translate }) => {
    const shapeString = (() => {
      switch (correctShape) {
        case 'circle':
          return translate.shapes.circles(1);
        case 'rectangle':
          return translate.shapes.rectangles(1);
        case 'square':
          return translate.shapes.squares(1);
        case 'triangle':
          return translate.shapes.triangles(1);
      }
    })();

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheX(shapeString)}
        pdfTitle={translate.ks1PDFInstructions.tickTheX(shapeString)}
        numItems={4}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <AssetSvg
                name={getShapeSvgByShapeAndColor(item.shape, item.color)}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value: item
          }))
        }
        testCorrect={items.filter(item => item.shape === correctShape)}
        pdfShowBorder
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q2CorrectShapes = ['circle', 'rectangle', 'square', 'triangle'] as const;
const q2IncorrectCircleShapes = [
  'ellipse',
  'wavy circle',
  'semi-circle',
  'rounded rectangle'
] as const;
const q2IncorrectRectangleShapes = [
  'rounded rectangle',
  'cuboid',
  'L-shape',
  'parallelogram'
] as const;
const q2IncorrectSquareShapes = ['rounded square', 'cube', 'rhombus', 'rectangle'] as const;
const q2IncorrectTriangleShapes = ['triangle-based pyramid', 'square-based pyramid'] as const;
const q2Shapes = [
  ...q2CorrectShapes,
  ...q2IncorrectCircleShapes,
  ...q2IncorrectRectangleShapes,
  ...q2IncorrectSquareShapes,
  ...q2IncorrectTriangleShapes
] as const;
const q2Colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

type Q2Shape = (typeof q2Shapes)[number];

const Question2 = newQuestionContent({
  uid: 'bbP',
  description: 'bbP',
  keywords: ['2-D shape', 'Circle', 'Triangle', 'Rectangle', 'Square'],
  schema: z
    .object({
      correctShape: z.enum(q2CorrectShapes),
      items: z
        .array(z.object({ shape: z.enum(q2Shapes), color: z.enum(q2Colors) }))
        .length(4)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
    })
    .refine(val => {
      const correctItems = val.items.filter(item => item.shape === val.correctShape);

      return correctItems.length >= 1 && correctItems.length <= 3;
    }, 'Number of correct items must be between 1 and 3'),
  simpleGenerator: () => {
    const correctShape = getRandomFromArray(q2CorrectShapes);

    const incorrectShapeC = (() => {
      switch (correctShape) {
        case 'circle':
          return getRandomFromArray(q2IncorrectCircleShapes);
        case 'rectangle':
          return getRandomFromArray(q2IncorrectRectangleShapes);
        case 'square':
          return getRandomFromArray(q2IncorrectSquareShapes);
        case 'triangle':
          return getRandomFromArray(q2IncorrectTriangleShapes);
      }
    })();

    const otherShapeD = (() => {
      switch (correctShape) {
        case 'circle':
          return getRandomFromArray(
            ['circle', ...q2IncorrectCircleShapes].filter(shape => shape !== incorrectShapeC)
          );
        case 'rectangle':
          return getRandomFromArray(
            ['rectangle', ...q2IncorrectRectangleShapes].filter(shape => shape !== incorrectShapeC)
          );
        case 'square':
          return getRandomFromArray(
            ['square', ...q2IncorrectSquareShapes].filter(shape => shape !== incorrectShapeC)
          );
        case 'triangle':
          return getRandomFromArray(
            ['triangle', ...q2IncorrectTriangleShapes].filter(shape => shape !== incorrectShapeC)
          );
      }
    })() as Q2Shape;

    const [shapeAColor, shapeBColor, shapeCColor, shapeDColor] = getRandomSubArrayFromArray(
      q2Colors,
      4
    );

    const items = shuffle([
      { shape: correctShape, color: shapeAColor },
      { shape: correctShape, color: shapeBColor },
      { shape: incorrectShapeC, color: shapeCColor },
      { shape: otherShapeD, color: shapeDColor }
    ]);

    return {
      correctShape,
      items
    };
  },
  Component: ({ question, translate }) => {
    const { correctShape, items } = question;

    const shapeString = (() => {
      switch (correctShape) {
        case 'circle':
          return translate.shapes.circles(2);
        case 'rectangle':
          return translate.shapes.rectangles(2);
        case 'square':
          return translate.shapes.squares(2);
        case 'triangle':
          return translate.shapes.triangles(2);
      }
    })();

    const shapeToSvg = (shape: (typeof q2Shapes)[number], color: (typeof q2Colors)[number]) => {
      switch (shape) {
        case 'circle':
        case 'rectangle':
        case 'square':
        case 'triangle':
        case 'ellipse':
        case 'wavy circle':
        case 'semi-circle':
        case 'rounded rectangle':
        case 'L-shape':
        case 'parallelogram':
        case 'rounded square':
        case 'rhombus':
          return getShapeSvgByShapeAndColor(shape, color);
        case 'cube':
          return get3DShapeFullColorsSVGPath(color, 'Cube');
        case 'cuboid':
          return get3DShapeFullColorsSVGPath(color, 'Cuboid');
        case 'triangle-based pyramid':
          return get3DShapeFullColorsSVGPath(color, 'Triangle_pyramid');
        case 'square-based pyramid':
          return get3DShapeFullColorsSVGPath(color, 'Square_pyramid');
      }
    };

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheX(shapeString)}
        pdfTitle={translate.ks1PDFInstructions.tickTheX(shapeString)}
        numItems={4}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <AssetSvg
                name={shapeToSvg(item.shape, item.color)}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value: item
          }))
        }
        testCorrect={items.filter(item => item.shape === correctShape)}
        multiSelect
        questionHeight={1000}
        pdfShowBorder
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bbQ',
  description: 'bbQ',
  keywords: ['2-D shape'],
  schema: z.object({
    shapesArray: z
      .array(
        z.object({
          shape: z.enum(['Square', 'Rectangle', 'Circle', 'Triangle', 'RATriangle']),
          rotation: z.number().int().min(0).max(360),
          scale: z.number().min(0.7).max(1.6)
        })
      )
      .length(8),
    chosenShape: z.enum(['Square', 'Rectangle', 'Circle', 'Triangle'])
  }),
  simpleGenerator: () => {
    const shapes = ['Square', 'Rectangle', 'Circle', 'Triangle'] as const;

    const chosenShape = getRandomFromArray(shapes);

    // Not enough other shapes if chosenShape === Rectangle so have at least 2 correct answers
    const numberOfCorrectShapes = randomIntegerInclusive(chosenShape === 'Rectangle' ? 2 : 1, 3);

    const otherShapes =
      chosenShape === 'Rectangle'
        ? shapes.filter(shape => shape !== 'Square' && shape !== chosenShape)
        : shapes.filter(shape => shape !== chosenShape);

    const incorrectArray = rejectionSample(
      () => {
        const incorrectArray = countRange(8 - numberOfCorrectShapes)
          .map(() => getRandomFromArray(otherShapes))
          .filter(el => el !== null);

        return incorrectArray;
      },
      incorrectArray =>
        incorrectArray.filter(shape => shape === 'Circle').length <= 3 &&
        incorrectArray.filter(shape => shape === 'Rectangle').length <= 3 &&
        incorrectArray.filter(shape => shape === 'Square').length <= 3 &&
        incorrectArray.filter(shape => shape === 'Triangle').length <= 3
    );

    const shapesArray = shuffle([
      ...filledArray(chosenShape, numberOfCorrectShapes),
      ...incorrectArray
    ]).map(shape => ({
      shape:
        shape === 'Triangle'
          ? getRandomBoolean()
            ? 'Triangle'
            : 'RATriangle'
          : (shape as 'Square' | 'Rectangle' | 'Circle' | 'Triangle' | 'RATriangle'),
      rotation: shape === 'Circle' ? 0 : randomIntegerInclusive(0, 360),
      scale: randomNumberInclusive(0.7, 1.6, 1)
    }));

    return { chosenShape, shapesArray };
  },
  Component: props => {
    const {
      question: { chosenShape, shapesArray },
      translate
    } = props;

    const correctArray = shapesArray
      .map(({ shape }) => (shape === 'RATriangle' ? 'Triangle' : shape))
      .filter(shape => shape === chosenShape);

    const titleString =
      chosenShape === 'Circle'
        ? translate.shapes.circles(correctArray.length)
        : chosenShape === 'Rectangle'
        ? translate.shapes.rectangles(correctArray.length)
        : chosenShape === 'Square'
        ? translate.shapes.squares(correctArray.length)
        : translate.shapes.Triangles(correctArray.length);

    return (
      <QF67SelectingRandomlyArrangedShapes
        title={translate.ks1Instructions.selectTheX(titleString)}
        pdfTitle={translate.ks1PDFInstructions.circleTheX(titleString)}
        shapesArray={shapesArray}
        testCorrect={correctArray}
        arrangement={filledArray(filledArray(true, shapesArray.length / 2), 2)}
        pdfCircleAnswer
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAndName2DShapes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
