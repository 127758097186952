import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { dogNames, dogNamesSchema } from '../../../../utils/names';
import { countRange, sumNumberArray } from '../../../../utils/collections';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import {
  citiesSchema,
  getRandomUniqueColdCities,
  getRandomUniqueHotCities
} from '../../../../utils/cities';
import TableWithLeftHeaders, {
  TableWithLeftHeadersWithState
} from '../../../../components/question/representations/TableWithLeftHeaders';
import { colors } from '../../../../theme/colors';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { isEqual } from '../../../../utils/matchers';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSu',
  description: 'aSu',
  keywords: ['Table', 'Read', 'Interpret'],
  schema: z.object({
    numbers: z.array(z.number().int().min(10).max(30)).length(4),
    names: z.array(dogNamesSchema).length(4),
    answerIndexes: z.array(z.number().int().min(0).max(3))
  }),
  simpleGenerator: () => {
    const numbers = countRange(4).map(() => randomIntegerInclusive(10, 30));
    const names = getRandomSubArrayFromArray(dogNames, 4);
    const answerIndexes = randomUniqueIntegersInclusive(0, 3, 2);

    return { numbers, names, answerIndexes };
  },
  Component: props => {
    const {
      question: { numbers, names, answerIndexes },
      translate
    } = props;

    const answer = numbers[answerIndexes[0]] + numbers[answerIndexes[1]];
    const dataA = numbers.map((val, i) => [names[i], translate.units.numberOfKg(val)]);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTotalMassOfXAndY(
          names[answerIndexes[0]],
          names[answerIndexes[1]]
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.ansKg()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.tableHeaders.NameOfDog()
              },
              {
                label: translate.keywords.Mass()
              }
            ]}
            tableData={dataA}
            tableStyle={{ width: dimens.width }}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aSv',
  description: 'aSv',
  keywords: ['Table', 'Read', 'Interpret'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(14).max(30),
    number3: z.number().int().min(18).max(30),
    names: z.array(dogNamesSchema).length(3),
    answerIndex: z.number().int().min(0).max(2),
    compareIndex: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 10);
    const number2 = randomIntegerInclusive(14, 30);
    const number3 = randomIntegerInclusive(18, 30);
    const names = getRandomSubArrayFromArray(dogNames, 3);
    const [answerIndex, compareIndex] = randomUniqueIntegersInclusive(0, 2, 2);

    return { number1, number2, number3, names, answerIndex, compareIndex };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, names, answerIndex, compareIndex },
      translate
    } = props;

    const numbers = [number1, number2, number3];
    const answer = numbers[answerIndex];
    const dataA = numbers.map((val, i) => [
      names[i],
      i === answerIndex ? translate.answerSentences.ansKg() : translate.units.numberOfKg(val)
    ]);

    const difference = numbers[compareIndex] - numbers[answerIndex];

    const lighterHeavier = difference > 0 ? translate.misc.Lighter() : translate.misc.Heavier();

    return (
      <QF7InteractiveTable
        title={translate.instructions.XIsYkgThanZ(
          names[answerIndex],
          Math.abs(difference).toLocaleString(),
          lighterHeavier,
          names[compareIndex]
        )}
        testCorrect={[answer.toString()]}
        cellHeaders={[
          {
            label: translate.tableHeaders.NameOfDog()
          },
          {
            label: translate.keywords.Mass()
          }
        ]}
        tableData={dataA}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aSw',
  description: 'aSw',
  keywords: ['Table', 'Read', 'Interpret'],
  schema: z.object({
    number1: z.number().int().min(10).max(20),
    number2: z.number().int().min(5).max(20),
    number3: z.number().int().min(12).max(30),
    number4: z.number().int().min(15).max(30),
    number5: z.number().int().min(15).max(30),
    cities: z.array(citiesSchema).length(5),
    answerIndex: z.number().int().min(0).max(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 20);
    const number2 = randomIntegerInclusive(5, 20);
    const number3 = randomIntegerInclusive(12, 30);
    const number4 = randomIntegerInclusive(15, 30);
    const number5 = randomIntegerInclusive(15, 30);
    const coldCities = getRandomUniqueColdCities(2);
    const hotCities = getRandomUniqueHotCities(3);
    const cities = [...coldCities, ...hotCities];
    const answerIndex = randomIntegerInclusive(0, 4);

    return { number1, number2, number3, number4, number5, cities, answerIndex };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, cities, answerIndex },
      translate,
      displayMode
    } = props;

    const numbers = [number1, number2, number3, number4, number5];
    const answer = numbers[answerIndex];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTempInX(translate.cities[cities[answerIndex]]())}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.ansDegC()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        Content={
          <TableWithLeftHeaders
            headers={[translate.tableHeaders.City(), translate.tableHeaders.TemperatureC()]}
            items={[
              cities.map(city => translate.cities[city]()),
              numbers.map(x => x.toLocaleString())
            ]}
            headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
            textStyle={displayMode === 'digital' && { fontSize: 22 }}
          />
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aSx',
  description: 'aSx',
  keywords: ['Table', 'Read', 'Interpret'],
  schema: z.object({
    number1: z.number().int().min(10).max(20),
    number2: z.number().int().min(5).max(20),
    number3: z.number().int().min(12).max(30),
    number4: z.number().int().min(15).max(30),
    number5: z.number().int().min(15).max(30),
    cities: z.array(citiesSchema).length(5),
    answerIndexes: z.array(z.number().int().min(0).max(4)).length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 20);
    const number2 = randomIntegerInclusive(5, 20);
    const number3 = randomIntegerInclusive(12, 30);
    const number4 = randomIntegerInclusive(15, 30);
    const number5 = randomIntegerInclusive(15, 30);
    const coldCities = getRandomUniqueColdCities(2);
    const hotCities = getRandomUniqueHotCities(3);
    const cities = [...coldCities, ...hotCities];
    const answerIndexes = randomUniqueIntegersInclusive(0, 4, 2);

    return { number1, number2, number3, number4, number5, cities, answerIndexes };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, cities, answerIndexes },
      translate,
      displayMode
    } = props;

    const numbers = [number1, number2, number3, number4, number5];
    const answer = Math.abs(numbers[answerIndexes[0]] - numbers[answerIndexes[1]]);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsDifferenceInTempOfXAndY(
          translate.cities[cities[answerIndexes[0]]](),
          translate.cities[cities[answerIndexes[1]]]()
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.ansDegC()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        Content={
          <TableWithLeftHeaders
            headers={[translate.tableHeaders.City(), translate.tableHeaders.TemperatureC()]}
            items={[
              cities.map(city => translate.cities[city]()),
              numbers.map(x => x.toLocaleString())
            ]}
            headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
            textStyle={displayMode === 'digital' && { fontSize: 22 }}
          />
        }
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aSy',
  description: 'aSy',
  keywords: ['Table', 'Read', 'Interpret'],
  schema: z.object({
    number1: z.number().int().min(10).max(20),
    number2: z.number().int().min(5).max(20),
    number3: z.number().int().min(12).max(30),
    number4: z.number().int().min(15).max(30),
    number5: z.number().int().min(15).max(30),
    cities: z.array(citiesSchema).length(6),
    compareIndexes: z.array(z.number().int().min(0).max(4)).length(2),
    incorrectAnswers: z.array(z.number().int().min(3).max(35)).length(3),
    correctAnswer: z.number().int().min(6).max(29)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 20);
    const number2 = randomIntegerInclusive(5, 20);
    const number3 = randomIntegerInclusive(12, 30);
    const number4 = randomIntegerInclusive(15, 30);
    // make sure we definitely have one that the city can be between
    const number5 = randomIntegerInclusive(15, 30, { constraint: x => Math.abs(x - number4) >= 2 });
    const numbers = [number1, number2, number3, number4, number5];
    const coldCities = getRandomUniqueColdCities(2);
    const hotCities = getRandomUniqueHotCities(4);
    const cities = [...coldCities, ...hotCities];
    const compareIndexes = rejectionSample(
      () => randomUniqueIntegersInclusive(0, 4, 2),
      val => Math.abs(numbers[val[0]] - numbers[val[1]]) >= 2
    );
    const min = Math.min(numbers[compareIndexes[0]], numbers[compareIndexes[1]]);
    const max = Math.max(numbers[compareIndexes[0]], numbers[compareIndexes[1]]);
    const correctAnswer = randomIntegerInclusive(min + 1, max - 1);

    const incorrectAnswerA = randomIntegerInclusive(3, min - 1, {
      constraint: x => x !== correctAnswer
    });
    const incorrectAnswersB = randomUniqueIntegersInclusive(max + 1, 35, 2, {
      constraint: x => x !== correctAnswer
    });

    const incorrectAnswers = [incorrectAnswerA, ...incorrectAnswersB];

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      cities,
      compareIndexes,
      incorrectAnswers,
      correctAnswer
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        cities,
        compareIndexes,
        incorrectAnswers,
        correctAnswer
      },
      translate,
      displayMode
    } = props;

    const numbers = [number1, number2, number3, number4, number5];

    const statements = [
      {
        value: correctAnswer
      },
      {
        value: incorrectAnswers[0]
      },
      {
        value: incorrectAnswers[1]
      },
      {
        value: incorrectAnswers[2]
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    const min =
      numbers[compareIndexes[0]] ===
      Math.min(numbers[compareIndexes[0]], numbers[compareIndexes[1]])
        ? translate.cities[cities[compareIndexes[0]]]()
        : translate.cities[cities[compareIndexes[1]]]();
    const max =
      numbers[compareIndexes[0]] ===
      Math.max(numbers[compareIndexes[0]], numbers[compareIndexes[1]])
        ? translate.cities[cities[compareIndexes[0]]]()
        : translate.cities[cities[compareIndexes[1]]]();

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.theTempInXIsWarmerThanYColderThanZWhatTemp(
          translate.cities[cities[5]](),
          min,
          max
        )}
        pdfTitle={translate.instructions.theTempInXIsWarmerThanYColderThanZWhatTempPdf(
          translate.cities[cities[5]](),
          min,
          max
        )}
        testCorrect={[correctAnswer]}
        itemLayout={'row'}
        numItems={4}
        mainPanelContainer={{ justifyContent: 'space-evenly' }}
        Content={
          <TableWithLeftHeaders
            headers={[translate.tableHeaders.City(), translate.tableHeaders.TemperatureC()]}
            items={[
              countRange(5).map(x => translate.cities[cities[x]]()),
              countRange(5).map(x => numbers[x].toLocaleString())
            ]}
            headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
            textStyle={{
              fontSize: displayMode === 'digital' ? 22 : 50,
              lineHeight: displayMode === 'digital' ? undefined : 75
            }}
          />
        }
        renderItems={shuffledStatements.map(({ value }) => ({
          value,
          component: <Text variant="WRN700">{translate.units.numberOfDegreesC(value)}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aSz',
  description: 'aSz',
  keywords: ['Table', 'Read', 'Interpret'],
  schema: z.object({
    numbers: z.array(z.number().int().min(2).max(8)).length(5),
    answerIndex: z.number().int().min(0).max(4)
  }),
  simpleGenerator: () => {
    const numbers = countRange(5).map(() => randomIntegerInclusive(2, 8));
    const answerIndex = randomIntegerInclusive(0, 4);

    return { numbers, answerIndex };
  },
  Component: props => {
    const {
      question: { numbers, answerIndex },
      translate,
      displayMode
    } = props;

    const total = sumNumberArray(numbers);

    return (
      <QF3Content
        title={translate.instructions.completeTable()}
        inputType="numpad"
        actionPanelVariant="bottomTall"
        inputVariant="wide"
        Content={() => (
          <TableWithLeftHeadersWithState
            id="table"
            items={[
              [...countRange(5).map(i => (i + 1).toLocaleString()), translate.keywords.Total()],
              [
                ...numbers.map((val, i) => (i === answerIndex ? `<ans/>` : val.toLocaleString())),
                total.toLocaleString()
              ]
            ]}
            headers={[translate.keywords.Group(), translate.tableHeaders.NumberOfChildren()]}
            testCorrect={isEqual([numbers[answerIndex].toString()])}
            defaultState={
              displayMode === 'markscheme' ? [numbers[answerIndex].toLocaleString()] : undefined
            }
            headerCellStyle={{
              backgroundColor: colors.tableHeaderBackground
            }}
            textStyle={
              displayMode === 'digital' && { fontSize: 22, lineHeight: 32.5, textAlign: 'center' }
            }
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadAndInterpretTables',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
