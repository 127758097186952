import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  createGridAroundShape,
  createLShape,
  createRectangleFromSquares,
  createShapeWithSquares,
  isRectilinear,
  trueCount
} from '../../../../utils/shapes';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { View } from 'react-native';
import { useMemo } from 'react';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF24aCreateShapeAndGivenShape from '../../../../components/question/questionFormats/QF24aCreateShapeAndGivenShape';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { sortNumberArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'afA',
  description: 'afA',
  keywords: ['Area', 'Rectilinear'],
  schema: z
    .object({
      smallerShapeArea: z.number().int().min(4).max(11),
      greaterShapeAreaFactor: z.number().int().min(1).max(2),
      smallerOrGreater: z.enum(['smaller', 'greater'])
    })
    .refine(
      val => val.smallerShapeArea + val.greaterShapeAreaFactor <= 12,
      'smallerShapeArea + largerShapeAreaFactor must be less than or equal to 12'
    ),
  simpleGenerator: () => {
    const greaterShapeAreaFactor = randomIntegerInclusive(1, 2);

    const smallerShapeArea = randomIntegerInclusive(4, 12 - greaterShapeAreaFactor);

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { smallerShapeArea, greaterShapeAreaFactor, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { smallerShapeArea, greaterShapeAreaFactor, smallerOrGreater },
      translate
    } = props;

    // Randomly order these shapes
    const shapes = useMemo(() => {
      const random = seededRandom(props.question);

      const shapeA = {
        shape: createShapeWithSquares(3, 4, smallerShapeArea, true, { random }),
        size: 'smaller'
      };

      const shapeB = {
        shape: createShapeWithSquares(3, 4, smallerShapeArea + greaterShapeAreaFactor, true, {
          random
        }),
        size: 'greater'
      };

      return shuffle([shapeA, shapeB], { random });
    }, [greaterShapeAreaFactor, props.question, smallerShapeArea]);

    const smallerOrGreaterTitle =
      smallerOrGreater === 'smaller' ? translate.misc.smaller() : translate.misc.greater();

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapeWithTheSmallerOrGreaterArea(smallerOrGreaterTitle)}
        pdfTitle={translate.instructions.circleShapeWithTheSmallerOrGreaterArea(
          smallerOrGreaterTitle
        )}
        testCorrect={shapes
          .filter(shape => shape.size === smallerOrGreater)
          .map(shape => shape.shape)}
        numItems={2}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.shape,
            component: <DisplayShapeOnGrid givenShape={shape.shape} dimens={dimens} />
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'afB',
  description: 'afB',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    shapeA: z.boolean().array().length(4).array().length(6),
    shapeB: z.boolean().array().length(4).array().length(6)
  }),
  simpleGenerator: () => {
    const dimens1 = getRandomFromArray([
      [1, 6],
      [2, 3],
      [3, 2],
      [2, 4],
      [4, 2],
      [3, 3],
      [2, 5],
      [2, 6],
      [3, 4],
      [4, 3],
      [3, 5],
      [4, 4],
      [3, 6]
    ]);

    const rectangle1 = createRectangleFromSquares(dimens1[0], dimens1[1]);
    const shape1 = createGridAroundShape([4, 6], rectangle1, 'random');

    const trueCountShape1 = trueCount(shape1) as 6 | 8 | 9 | 10 | 12 | 15 | 16 | 18;

    const dimens2 = (() => {
      switch (trueCountShape1) {
        case 6:
          return getRandomFromArray([
            [1, 6],
            [2, 3],
            [3, 2],
            [2, 4],
            [4, 2]
          ]);
        case 8:
          return getRandomFromArray([
            [2, 4],
            [4, 2],
            [3, 3]
          ]);
        case 9:
          return getRandomFromArray([
            [3, 3],
            [2, 5]
          ]);
        case 10:
          return getRandomFromArray([
            [2, 5],
            [2, 6],
            [3, 4],
            [4, 3]
          ]);

        case 12:
          return getRandomFromArray([
            [2, 6],
            [3, 4],
            [4, 3]
          ]);

        case 15:
          return getRandomFromArray([
            [3, 5],
            [4, 4]
          ]);

        case 16:
          return getRandomFromArray([
            [4, 4],
            [3, 6]
          ]);

        case 18:
          return [3, 6];
      }
    })();

    const rectangle2 = createRectangleFromSquares(dimens2[0], dimens2[1]);

    const shape2 = createGridAroundShape([4, 6], rectangle2, 'random');

    const [shapeA, shapeB] = shuffle([shape1, shape2]);

    return { shapeA, shapeB };
  },
  Component: props => {
    const {
      translate,
      question: { shapeA, shapeB }
    } = props;

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareAreasOfShadedShapes()}
        pdfTitle={translate.instructions.useLessThanGreaterThanEqualsToCompareAreasOfShadedShapes()}
        items={['<', '>', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statements={[
          {
            correctAnswer: lessThanGreaterThanOrEqualTo(trueCount(shapeA), trueCount(shapeB)),
            lhsComponent: (
              <MeasureView>
                {dimens => <DisplayShapeOnGrid givenShape={shapeA} dimens={dimens} />}
              </MeasureView>
            ),
            rhsComponent: (
              <MeasureView>
                {dimens => <DisplayShapeOnGrid givenShape={shapeB} dimens={dimens} />}
              </MeasureView>
            )
          }
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'afC',
  description: 'afC',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    shapeA: z.boolean().array().length(4).array().length(6),
    shapeB: z.boolean().array().length(4).array().length(6)
  }),
  simpleGenerator: () => {
    const [dimens1, dimens2] = getRandomSubArrayFromArray(
      [
        [2, 3],
        [3, 2],
        [2, 4],
        [4, 2],
        [3, 3],
        [2, 5],
        [2, 6],
        [3, 4],
        [4, 3],
        [3, 5],
        [4, 4],
        [3, 6]
      ],
      2
    );

    const rectangle1 = createRectangleFromSquares(dimens1[0], dimens1[1]);
    const shape1 = createGridAroundShape([4, 6], rectangle1, 'random');

    const rectangle2 = createLShape({ rectangleWidth: dimens2[0], rectangleHeight: dimens2[1] });

    const shape2 = createGridAroundShape([4, 6], rectangle2, 'random');

    const [shapeA, shapeB] = shuffle([shape1, shape2]);

    return { shapeA, shapeB };
  },
  Component: props => {
    const {
      translate,
      question: { shapeA, shapeB }
    } = props;

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareAreasOfShadedShapes()}
        pdfTitle={translate.instructions.useLessThanGreaterThanEqualsToCompareAreasOfShadedShapes()}
        items={['<', '>', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statements={[
          {
            correctAnswer: lessThanGreaterThanOrEqualTo(trueCount(shapeA), trueCount(shapeB)),
            lhsComponent: (
              <MeasureView>
                {dimens => <DisplayShapeOnGrid givenShape={shapeA} dimens={dimens} />}
              </MeasureView>
            ),
            rhsComponent: (
              <MeasureView>
                {dimens => <DisplayShapeOnGrid givenShape={shapeB} dimens={dimens} />}
              </MeasureView>
            )
          }
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'afD',
  description: 'afD',
  keywords: ['Area', 'Rectilinear', 'Order'],
  schema: z.object({
    shape1: z.boolean().array().length(4).array().length(3),
    shape2: z.boolean().array().length(4).array().length(3),
    shape3: z.boolean().array().length(4).array().length(3)
  }),
  simpleGenerator: () => {
    const [shape1Size, shape2Size, shape3Size] = randomUniqueIntegersInclusive(5, 10, 3);

    const shape1 = createShapeWithSquares(3, 4, shape1Size, true);
    const shape2 = createShapeWithSquares(3, 4, shape2Size, true);
    const shape3 = createShapeWithSquares(3, 4, shape3Size, true);

    return { shape1, shape2, shape3 };
  },
  Component: props => {
    const {
      question: { shape1, shape2, shape3 },
      translate
    } = props;

    const shapes = shuffle(
      [
        { shape: shape1, value: trueCount(shape1) },
        { shape: shape2, value: trueCount(shape2) },
        { shape: shape3, value: trueCount(shape3) }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        draggableVariant="tallRectangle"
        title={translate.instructions.orderShapesBySize()}
        pdfTitle={translate.instructions.drawLinesToOrderShapesBySize()}
        testCorrect={sortNumberArray(
          shapes.map(x => x.value),
          'ascending'
        )}
        items={shapes.map(({ value, shape }) => ({
          value,
          component: (
            <MeasureView>
              {dimens => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
            </MeasureView>
          )
        }))}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'afE',
  description: 'afE',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    smallerShapeArea: z.number().int().min(5).max(11),
    greaterShapeArea: z.number().int().min(13).max(19),
    createSmallerOrGreater: z.enum(['smaller', 'greater'])
  }),
  simpleGenerator: () => {
    const smallerShapeArea = randomIntegerInclusive(5, 11);

    const greaterShapeArea = randomIntegerInclusive(13, 19);

    const createSmallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { smallerShapeArea, greaterShapeArea, createSmallerOrGreater };
  },
  Component: props => {
    const {
      question: { smallerShapeArea, greaterShapeArea, createSmallerOrGreater },
      translate
    } = props;

    const random = seededRandom(props.question);

    const givenShape =
      createSmallerOrGreater === 'smaller'
        ? createShapeWithSquares(4, 5, greaterShapeArea, true, { random })
        : createShapeWithSquares(4, 3, smallerShapeArea, true, { random });

    const expectedLength =
      createSmallerOrGreater === 'smaller' ? smallerShapeArea : greaterShapeArea;

    const difference = greaterShapeArea - smallerShapeArea;

    return (
      <QF24aCreateShapeAndGivenShape
        title={
          createSmallerOrGreater === 'smaller'
            ? translate.instructions.createShapeAreaNumSquaresSmallerThan(difference)
            : translate.instructions.createShapeAreaNumSquaresGreaterThan(difference)
        }
        givenShape={givenShape}
        numberOfRows={4}
        numberOfCols={createSmallerOrGreater === 'smaller' ? 3 : 5}
        testCorrect={userAnswer =>
          trueCount(userAnswer) === expectedLength && isRectilinear(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyShapeWithAreaX(expectedLength)
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'afF',
  description: 'afF',
  keywords: ['Area', 'Rectilinear'],
  schema: z
    .object({
      shapeAArea: z.number().int().min(3).max(8),
      shapeBArea: z.number().int().min(6).max(12)
    })
    .refine(
      val => val.shapeBArea >= val.shapeAArea + 2,
      'shapeBArea must be at least 2 more than shapeAArea'
    ),
  simpleGenerator: () => {
    const shapeAArea = randomIntegerInclusive(3, 8);

    const shapeBArea = randomIntegerInclusive(shapeAArea === 3 ? 6 : shapeAArea + 2, 12);

    return { shapeAArea, shapeBArea };
  },
  Component: props => {
    const {
      question: { shapeAArea, shapeBArea },
      translate
    } = props;

    // Randomly order these shapes
    const shapes = useMemo(() => {
      const random = seededRandom(props.question);
      // Width factor calculated to ensure both shapes appear in-scale with each other.
      const shapeA = {
        shape: createShapeWithSquares(4, 2, shapeAArea, true, { random }),
        widthFactor: 1 / 3
      };

      const shapeB = {
        shape: createShapeWithSquares(4, 3, shapeBArea, true, { random }),
        widthFactor: 1 / 2
      };
      return shuffle([shapeA, shapeB], { random });
    }, [props.question, shapeAArea, shapeBArea]);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsDifferenceInAreaOfShapes()}
        sentence={translate.answerSentences.ansSquares()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-around' }]}>
            <DisplayShapeOnGrid
              givenShape={shapes[0].shape}
              dimens={{ width: dimens.width * shapes[0].widthFactor, height: dimens.height }}
            />
            <DisplayShapeOnGrid
              givenShape={shapes[1].shape}
              dimens={{ width: dimens.width * shapes[1].widthFactor, height: dimens.height }}
            />
          </View>
        )}
        testCorrect={[Math.abs(trueCount(shapes[0].shape) - trueCount(shapes[1].shape)).toString()]}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAreas',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
