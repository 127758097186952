import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from '../i18n/i18n-types';
import { SvgName } from '../assets/svg';

const countriesNames = [
  'Spain',
  'France',
  'USA',
  'Italy',
  'Egypt',
  'Japan',
  'India',
  'Belgium',
  'Germany'
] as const;

export const countriesSchema = z.enum(countriesNames);

export function getRandomCountry() {
  return getRandomFromArray(countriesNames);
}

export function getRandomUniqueCountries(numberOfCountries: number) {
  return getRandomSubArrayFromArray([...countriesNames] as const, numberOfCountries);
}

export type CountryName = (typeof countriesNames)[number];

export const countryAsWord = (object: CountryName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Spain':
      return translate.countries.Spain();
    case 'France':
      return translate.countries.France();
    case 'USA':
      return translate.countries.USA();
    case 'Italy':
      return translate.countries.Italy();
    case 'Egypt':
      return translate.countries.Egypt();
    case 'Japan':
      return translate.countries.Japan();
    case 'India':
      return translate.countries.India();
    case 'Belgium':
      return translate.countries.Belgium();
    case 'Germany':
      return translate.countries.Germany();
  }
};

const flags: { country: string; svgName: SvgName; stripe: 'horizontal' | 'vertical' | 'both' }[] = [
  { svgName: 'Flags/Armenia_horizontal_flag', country: 'Armenia', stripe: 'horizontal' },
  { svgName: 'Flags/Australia_both_flag', country: 'Australia', stripe: 'both' },
  { svgName: 'Flags/Austria_horizontal_flag', country: 'Austria', stripe: 'horizontal' },
  { svgName: 'Flags/Belgium_vertical_flag', country: 'Belgium', stripe: 'vertical' },
  { svgName: 'Flags/Botswana_horizontal_flag', country: 'Botswana', stripe: 'horizontal' },
  { svgName: 'Flags/Bulgaria_horizontal_flag', country: 'Bulgaria', stripe: 'horizontal' },
  { svgName: 'Flags/Cameroon_vertical_flag', country: 'Cameroon', stripe: 'vertical' },
  { svgName: 'Flags/Canada_vertical_flag', country: 'Canada', stripe: 'vertical' },
  { svgName: 'Flags/Chad_vertical_flag', country: 'Chad', stripe: 'vertical' },
  { svgName: 'Flags/Columbia_horizontal_flag', country: 'Columbia', stripe: 'horizontal' },
  { svgName: 'Flags/Denmark_both_flag', country: 'Denmark', stripe: 'both' },
  { svgName: 'Flags/Estonia_horizontal_flag', country: 'Estonia', stripe: 'horizontal' },
  { svgName: 'Flags/Finland_both_flag', country: 'Finland', stripe: 'both' },
  { svgName: 'Flags/France_vertical_flag', country: 'France', stripe: 'vertical' },
  { svgName: 'Flags/Germany_horizontal_flag', country: 'Germany', stripe: 'horizontal' },
  { svgName: 'Flags/Greece_both_flag', country: 'Greece', stripe: 'both' },
  { svgName: 'Flags/Iceland_both_flag', country: 'Iceland', stripe: 'both' },
  { svgName: 'Flags/Indonesia_horizontal_flag', country: 'Indonesia', stripe: 'horizontal' },
  { svgName: 'Flags/Ireland_vertical_flag', country: 'Ireland', stripe: 'vertical' },
  { svgName: 'Flags/Italy_vertical_flag', country: 'Italy', stripe: 'vertical' },
  { svgName: 'Flags/Latvia_horizontal_flag', country: 'Lativia', stripe: 'horizontal' },
  { svgName: 'Flags/Lithuania_horizontal_flag', country: 'Lithuania', stripe: 'horizontal' },
  { svgName: 'Flags/Madagascar_both_flag', country: 'Madagascar', stripe: 'both' },
  { svgName: 'Flags/Mali_vertical_flag', country: 'Mali', stripe: 'vertical' },
  { svgName: 'Flags/Mauritius_horizontal_flag', country: 'Mauritius', stripe: 'horizontal' },
  { svgName: 'Flags/Netherlands_horizontal_flag', country: 'Netherlands', stripe: 'horizontal' },
  { svgName: 'Flags/Nigeria_vertical_flag', country: 'Nigeria', stripe: 'vertical' },
  { svgName: 'Flags/Norway_both_flag', country: 'Norway', stripe: 'both' },
  { svgName: 'Flags/Poland_horizontal_flag', country: 'Poland', stripe: 'horizontal' },
  { svgName: 'Flags/Romania_vertical_flag', country: 'Romania', stripe: 'vertical' },
  { svgName: 'Flags/Senagal_vertical_flag', country: 'Senegal', stripe: 'vertical' },
  { svgName: 'Flags/Sierra_Leone_horizontal_flag', country: 'SierraLeone', stripe: 'horizontal' },
  { svgName: 'Flags/Sweden_both_flag', country: 'Sweden', stripe: 'both' },
  { svgName: 'Flags/Thailand_horizontal_flag', country: 'Thailand', stripe: 'horizontal' },
  { svgName: 'Flags/Togo_horizontal_flag', country: 'Togo', stripe: 'horizontal' },
  { svgName: 'Flags/UAE_both_flag', country: 'UAE', stripe: 'both' },
  { svgName: 'Flags/UK_both_flag', country: 'UK', stripe: 'both' },
  { svgName: 'Flags/USA_horizontal_flag', country: 'USA', stripe: 'horizontal' }
];

export function getRandomUniqueFlags(
  quantity: number,
  stripeDirection?: 'horizontal' | 'vertical' | 'both',
  isDirection = true
) {
  const array = stripeDirection
    ? flags.filter(val =>
        isDirection ? val.stripe === stripeDirection : val.stripe !== stripeDirection
      )
    : flags;
  return getRandomSubArrayFromArray([...array] as const, quantity);
}
