import { useContext, useMemo } from 'react';
import { View, type ViewStyle, StyleSheet } from 'react-native';
import { AssetSvg, getSvgInfo, type SvgName } from '../../../assets/svg';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput from '../../molecules/UserInput';
import { ImageWithRotationHandleWithState } from '../../molecules/ImageWithRotationHandle';
import { CompleteTheSentenceWithState } from '../../molecules/CompleteTheSentence';
import {
  describeArc,
  polarToCartesian,
  rotateAroundAPointTransformStyle
} from '../../../utils/angles';
import { isEqual } from '../../../utils/matchers';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { colors } from '../../../theme/colors';
import Svg, { Circle, Line, Path } from 'react-native-svg';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

/** Hard-coded height of the content between the instruction and the sentence */
const CONTENT_HEIGHT = 343;
/** Hard-coded width of the content between the instruction and the sentence */
const CONTENT_WIDTH = 796;

// Information about the protractor. Depends on CONTENT_HEIGHT and CONTENT_WIDTH
const PROTACTOR_IMAGE: { svgName: SvgName } = { svgName: 'Protractor180' };
const PROTRACTOR_HEIGHT = 343; // Largest that fits in the content
const PROTRACTOR_WIDTH = 628.304036; // Calculated based on SVG's aspect ratio and height
const PROTRACTOR_ANCHOR_Y = 313; // Found by eye
const PROTRACTOR_HANDLES = [{ xOffset: 0, yOffset: -PROTRACTOR_HEIGHT * 0.3 }]; // A single handle in the center of the protractor

/** Maximum angle that the protractor can be rotated in degrees. */
const MAX_ROTATION_DEGREES = 20;
/** Maximum angle that the protractor can be rotated in radians. */
const MAX_ROTATION_RADIANS = MAX_ROTATION_DEGREES * (Math.PI / 180);

type Props = TitleStyleProps & {
  /** Title at the top of the question */
  title: string;
  /** Alternative title to use in the PDF case (defaults to `title`) */
  pdfTitle?: string;
  /** Information about the shape that is being measured. */
  shapeToMeasure:
    | {
        type: 'svg';
        /** Name of the svg to use */
        name: SvgName;
        /** Height of the SVG when rendered. Default: 300px. Note, changing this does not affect vertexX and vertexY! */
        height?: number;
        /** Location within the shape's *viewBox coordinates* of the vertex under measurement - x axis
         * Default: 0
         */
        vertexX?: number;
        /** Location within the shape's *viewBox coordinates* of the vertex under measurement - y axis
         * Default: 0
         */
        vertexY?: number;
        /**
         * Rotation to apply to the shape around the vertex, so that one edge of the vertex is flat. In degrees.
         * Default: 0
         */
        baseRotation?: number;
      }
    | {
        type: 'angleFromLines';
        /**
         * Positioning of angle when type is `angleFromLines`
         */
        side: 'left' | 'right';
        /**
         * Size of the angle to be drawn when type is `angleFromLines`
         */
        angleSize: number;
      };
  /**
   * Additional rotation to apply to the shape to force the users to rotate the protractor to match. In degrees.
   * Must be between -20 and 20, and shouldn't be close to 0.
   */
  additionalRotation: number;
  /** Complete-the-sentence markup string to show the question. This is always right-aligned. */
  sentence: string;
  /** Test correct. */
  testCorrect: number | ((userAnswer: string[]) => boolean);
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
};

/**
 * A question showing:
 * - Instruction (a.k.a. title) at the top
 * - Complete-the-sentence at the bottom
 * - Protractor which is centered in the remaining space. This can be rotated by the user up to 20 degrees each way.
 * - SVG, with one angle that the user is trying to measure. This angle is position on the protractor's anchor.
 *
 * Note that the space for the protractor and SVG-to-measure is hard coded to 796x343. This allows for up to two lines
 * of instruction, and one line of answers at the bottom.
 */
export default function QF50bRotatableProtractor({
  title,
  pdfTitle = title,
  shapeToMeasure,
  additionalRotation,
  sentence,
  testCorrect: testCorrectProp,
  customMarkSchemeAnswer
}: Props) {
  const displayMode = useContext(DisplayMode);
  if (
    (shapeToMeasure.type === 'svg' && !shapeToMeasure.name) ||
    (shapeToMeasure.type === 'angleFromLines' && !shapeToMeasure.angleSize)
  ) {
    throw new Error(
      `For type ${shapeToMeasure.type} you must provide ${
        shapeToMeasure.type === 'svg' ? 'name' : 'angleSize'
      } prop`
    );
  }

  // Handle testCorrect
  const testCorrect = useMemo(
    () =>
      typeof testCorrectProp === 'function'
        ? testCorrectProp
        : isEqual([testCorrectProp.toString()]),
    [testCorrectProp]
  );

  const shape = useMemo(() => {
    switch (shapeToMeasure.type) {
      case 'angleFromLines': {
        // The shape is just an angle, render it here
        const { angleSize, side } = shapeToMeasure;

        // theta1: angle of first line, clockwise from pointing right
        // theta2: angle of second line, clockwise from pointing right
        let theta1, theta2: number;
        if (side === 'left') {
          // Go clockwise from pointing left (+ additionalRotation)
          theta1 = 180 + additionalRotation;
          theta2 = theta1 + angleSize;
        } else {
          // Go anticlockwise from pointing right (- additionalRotation).
          // Also we swap theta1 and theta2 so that theta1->theta2 is still clockwise
          theta2 = -additionalRotation;
          theta1 = theta2 - angleSize;
        }

        const center = { x: PROTRACTOR_WIDTH / 2, y: PROTRACTOR_ANCHOR_Y };

        return drawAngle(
          center,
          theta1,
          theta2,
          displayMode === 'digital' ? colors.prussianBlue : 'black'
        );
      }

      case 'svg': {
        // We were given an svg name, calculate the absolute positioning of it
        const { name, height = 300, vertexX = 0, vertexY = 0, baseRotation = 0 } = shapeToMeasure;

        const { width: shapeNaturalWidth, height: shapeNaturalHeight } = getSvgInfo(
          shapeToMeasure.name
        );

        const scale = height / shapeNaturalHeight;
        const width = shapeNaturalWidth * scale;

        const anchorX = vertexX * scale;
        const anchorY = vertexY * scale;

        const rotationRadians = (baseRotation + additionalRotation) * (Math.PI / 180);

        const shapeToMeasureStyle: ViewStyle = {
          position: 'absolute',
          left: PROTRACTOR_WIDTH / 2 - anchorX,
          top: displayMode === 'digital' ? PROTRACTOR_ANCHOR_Y - anchorY : 0,
          transform: rotateAroundAPointTransformStyle(
            rotationRadians,
            anchorX,
            anchorY,
            width,
            height
          )
        };

        return (
          <View style={shapeToMeasureStyle}>
            {displayMode === 'pdf' || displayMode === 'markscheme' ? (
              <View style={{ paddingTop: 40 }}>
                <AssetSvg name={name} height={height * 2} />
              </View>
            ) : (
              <AssetSvg name={name} height={height} />
            )}
          </View>
        );
      }
    }
  }, [additionalRotation, displayMode, shapeToMeasure]);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : [testCorrectProp.toLocaleString()];

    return (
      <BaseLayoutPDF
        title={pdfTitle}
        mainPanelContents={
          <View style={{ alignItems: 'center' }}>
            <View
              style={{
                height: CONTENT_HEIGHT,
                width: CONTENT_WIDTH,
                alignItems: 'center'
              }}
            >
              {shape}
            </View>
            <View style={{ alignSelf: 'flex-end' }}>
              <CompleteTheSentenceWithState
                id="sentence"
                sentence={sentence}
                defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
                style={{ justifyContent: 'flex-end' }}
              />
            </View>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </View>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" />}
      mainPanelContents={
        <>
          <View
            style={{
              height: CONTENT_HEIGHT,
              width: CONTENT_WIDTH,
              alignItems: 'flex-start',
              overflow: 'visible'
            }}
          >
            {shape}
            <ImageWithRotationHandleWithState
              id="rotation"
              image={PROTACTOR_IMAGE}
              width={PROTRACTOR_WIDTH}
              height={PROTRACTOR_HEIGHT}
              anchorY={PROTRACTOR_ANCHOR_Y}
              handles={PROTRACTOR_HANDLES}
              maxRotation={MAX_ROTATION_RADIANS}
              minRotation={-MAX_ROTATION_RADIANS}
            />
          </View>
          <CompleteTheSentenceWithState
            id="sentence"
            sentence={sentence}
            testCorrect={testCorrect}
            style={{ justifyContent: 'flex-end' }}
          />
        </>
      }
    />
  );
}

/** Draw an angle clockwise from theta1 to theta2. */
function drawAngle(
  center: { x: number; y: number },
  theta1: number,
  theta2: number,
  color: string
) {
  const strokeWidth = 4;
  const lineLength = 300;
  const arcRadius = 60;
  const line1End = polarToCartesian(center.x, center.y, lineLength, theta1);
  const line2End = polarToCartesian(center.x, center.y, lineLength, theta2);

  return (
    <Svg width={CONTENT_WIDTH} height={CONTENT_HEIGHT} style={StyleSheet.absoluteFill}>
      <Circle cx={center.x} cy={center.y} r={strokeWidth} fill="black" />
      <Path
        d={describeArc(center.x, center.y, arcRadius, theta1 + 90, theta2 + 90)}
        stroke={color}
        strokeWidth={strokeWidth * 0.8}
        fill="none"
      />
      <Line
        x1={center.x}
        y1={center.y}
        x2={line1End.x}
        y2={line1End.y}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <Line
        x1={center.x}
        y1={center.y}
        x2={line2End.x}
        y2={line2End.y}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </Svg>
  );
}
