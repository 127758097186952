import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import { View } from 'react-native';
import { barModelColors } from '../../../../theme/colors';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import { countRange } from '../../../../utils/collections';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkt',
  description: 'bkt',
  keywords: ['Equal groups', 'Group', 'Third'],
  schema: z
    .object({
      rows: z.number().int().min(2).max(3),
      columns: z.number().int().min(1).max(7)
    })
    .refine(({ columns, rows }) => isInRange(3, 21)(columns * rows) && (columns * rows) % 3 === 0),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 3);

    const columns = randomIntegerInclusive(1, 7, {
      constraint: x => x * rows <= 21 && (x * rows) % 3 === 0
    });

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate,
      displayMode
    } = props;

    const total = rows * columns;

    const answer = total / 3;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.findFracOfX("<frac n='1' d='3' />", total)}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={translate.ks1AnswerSentences.fracOfXIsAns("<frac n='1' d='3'/>", total)}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} />}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bku',
  description: 'bku',
  keywords: ['Whole', 'Part', 'Bar model', 'Third'],
  schema: z.object({
    total: z.number().int().min(3).max(18).step(3),
    barModelColor: z.string()
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const total = randomIntegerInclusiveStep(3, 18, 3);

    const barModelColor = getRandomFromArray(Object.values(barModelColors)) as string;

    return { total, barModelColor };
  },
  Component: props => {
    const {
      question: { total, barModelColor },
      translate,
      displayMode
    } = props;

    const answer = total / 3;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatIsFracOfX("<frac n='1' d='3' />", total)}
        testCorrect={[answer.toString()]}
        sentence={translate.ks1AnswerSentences.fracOfXIsAns("<frac n='1' d='3'/>", total)}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        Content={({ dimens }) => (
          <>
            <BarModel
              dimens={{ width: dimens.width + 40, height: dimens.height }}
              total={total}
              numbers={[[total]]}
              rowColors={[barModelColor]}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              cellStyle={{ borderBottomWidth: 0 }}
            />
            <BarModelInteractiveWithState
              id="barmodel"
              numberOfRows={1}
              numberOfCols={3}
              tableHeight={100}
              tableWidth={dimens.width}
              testCorrect={() => true}
            />
          </>
        )}
        pdfDirection="column"
        questionHeight={800}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.barModelDoesNotNeedToBeShaded()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkv',
  description: 'bkv',
  keywords: ['Whole', 'Part', 'Third'],
  schema: z.object({
    numOfCounters: z.number().int().min(2).max(6),
    color: z.enum(['blue', 'green', 'red', 'yellow']),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);

    const numOfCounters = randomIntegerInclusive(2, 6);

    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;

    return { numOfCounters, seed, color };
  },
  Component: props => {
    const {
      question: { numOfCounters, seed, color },
      translate,
      displayMode
    } = props;

    const answer = numOfCounters * 3;

    return (
      <QF1ContentAndSentence
        title={`${translate.ks1Instructions.oneThirdOfTotalIsXNumberWhatIsTheNumber(
          numOfCounters
        )}`}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={'<ans />'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[answer.toString()]}
        Content={
          <View style={{ flexDirection: 'row', columnGap: 24 }}>
            {countRange(3).map(idx => {
              return idx === 0 ? (
                <View
                  key={idx}
                  style={{
                    borderWidth: 2,
                    borderRadius: 120,
                    width: 240,
                    height: 240,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <CounterBoxArrangement
                    counters={numOfCounters}
                    color={color}
                    seed={seed}
                    dimens={{ height: 180, width: 180 }}
                    noBorder
                    noBackground
                  />
                </View>
              ) : (
                <View
                  key={idx}
                  style={{
                    borderWidth: 2,
                    borderRadius: 120,
                    width: 240,
                    height: 240,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                ></View>
              );
            })}
          </View>
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAThird',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
