import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import { numberEnum } from '../../../../utils/zod';
import { countRange, sortNumberArray } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import {
  createLShape,
  createRectangleFromSquares,
  getSShape,
  getTShape,
  incorrectSScale2,
  incorrectTScale2,
  scaleShape
} from '../../../../utils/shapes';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import {
  QuadrilateralShapesSchema,
  quadrilateralColors,
  quadrilateralColorsSchema
} from '../../../../utils/quadrilateralImages';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledSmallLargeQuadrilateralsWithState } from '../../../../components/question/representations/LabelledSmallLargeQuadrilaterals';
import { algebraicSymbolSchema, getAlgebraicSymbol } from '../../../../utils/algebraicSymbols';

////
// Questions
////

const shapes = ['Parallelogram', 'Rectangle', 'Rhombus', 'Square', 'Trapezium', 'Kite'] as const;

const labelPositionQ1and2 = {
  Triangle: {
    1: {
      1: { top: 110, right: 135 },
      2: { top: 110, right: 180 },
      3: { top: 110, right: 220 }
    },
    2: {
      1: { top: 120, right: 95 },
      2: { top: 120, right: 140 },
      3: { top: 120, right: 190 }
    }
  },
  Rectangle: {
    1: {
      3: { top: 95, right: 200 },
      4: { top: 95, right: 240 }
    }
  },
  Square: {
    2: {
      2: { top: 95, right: 115 }
    }
  }
};

const Question1 = newQuestionContent({
  uid: 'aTm',
  description: 'aTm',
  keywords: ['Similar', 'Shape', 'Scale'],
  schema: z.object({
    height: z.number().int().min(1).max(2),
    width: z.number().int().min(1).max(3),
    scale: z.number().int().min(2).max(3)
  }),
  simpleGenerator: () => {
    const height = randomIntegerInclusive(1, 2);
    const width = randomIntegerInclusive(1, 3);
    const scale = width === 3 ? 2 : randomIntegerInclusive(2, 3);

    return { height, width, scale };
  },
  Component: props => {
    const {
      question: { height, width, scale },
      translate
    } = props;

    const shapeAPoints = [
      [0, 0],
      [width, 0],
      [0, height]
    ];

    const shapeBPoints = [
      [0, 0],
      [width * scale, 0],
      [0, height * scale]
    ];

    const shapeCPoints = [
      [0, 0],
      [width * (scale + 1), 0],
      [0, height * scale]
    ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectSimilarShape()}
        pdfTitle={translate.instructions.circleSimilarShape()}
        testCorrect={['B']}
        numItems={2}
        Content={({ dimens }) => (
          <DisplayShapeOnGridWithBorder
            points={shapeAPoints.map(coord => coord as [number, number])}
            dimens={dimens}
          />
        )}
        renderItems={({ dimens }) => {
          return shuffle(
            [
              {
                value: 'B',
                component: (
                  <DisplayShapeOnGridWithBorder
                    points={shapeBPoints.map(coord => coord as [number, number])}
                    dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                  />
                )
              },
              {
                value: 'C',
                component: (
                  <DisplayShapeOnGridWithBorder
                    points={shapeCPoints.map(coord => coord as [number, number])}
                    dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                  />
                )
              }
            ],
            { random: seededRandom(props.question) }
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question1v2 = newQuestionContent({
  uid: 'aTm2',
  description: 'aTm',
  keywords: ['Similar', 'Shape', 'Scale'],
  schema: z.object({
    height: z.number().int().min(1).max(2),
    width: z.number().int().min(1).max(4),
    scale: z.number().int().min(2).max(3),
    shape: z.enum(['Triangle', 'Rectangle', 'Square'])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['Triangle', 'Rectangle', 'Square'] as const);
    const height =
      shape === 'Triangle' ? randomIntegerInclusive(1, 2) : shape === 'Rectangle' ? 1 : 2;
    const width =
      shape === 'Triangle'
        ? randomIntegerInclusive(1, 3)
        : shape === 'Rectangle'
        ? randomIntegerInclusive(3, 4)
        : 2;
    const scale = width === 3 ? 2 : randomIntegerInclusive(2, 3);

    return { height, width, scale, shape };
  },
  Component: props => {
    const {
      question: { height, width, scale, shape },
      translate,
      displayMode
    } = props;

    const shapeDimens: { [key: number]: { [key: number]: { top: number; right: number } } } =
      labelPositionQ1and2[shape];
    const { top, right } = shapeDimens[height][width];

    const shapeA =
      shape === 'Triangle'
        ? [
            [0, 0],
            [width, 0],
            [0, height]
          ]
        : createRectangleFromSquares(width, height);

    const shapeB =
      shape === 'Triangle'
        ? [
            [0, 0],
            [width * scale, 0],
            [0, height * scale]
          ]
        : createRectangleFromSquares(width * scale, height * scale);

    const shapeC =
      shape === 'Triangle'
        ? [
            [0, 0],
            [width * (scale + 1), 0],
            [0, height * scale]
          ]
        : createRectangleFromSquares(width * 2, height);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectSimilarShapeToShapeX(translate.letters.A())}
        pdfTitle={translate.instructions.circleSimilarShapeToShapeX(translate.letters.A())}
        testCorrect={['B']}
        numItems={2}
        Content={({ dimens }) => (
          <View style={{ position: 'relative' }}>
            {shape === 'Triangle' ? (
              <DisplayShapeOnGridWithBorder
                points={shapeA.map(coord => coord as [number, number])}
                dimens={dimens}
              />
            ) : (
              <DisplayShapeOnGridWithBorder
                givenShape={shapeA as [[boolean, boolean]]}
                dimens={dimens}
              />
            )}
            <Text
              variant="WRN400"
              style={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                position: 'absolute',
                top: displayMode === 'digital' ? top : top * 1.5,
                right: displayMode === 'digital' ? right : right * 1.5
              }}
            >
              {translate.letters.A()}
            </Text>
          </View>
        )}
        renderItems={({ dimens }) => {
          return shuffle(
            [
              {
                value: 'B',
                component:
                  shape === 'Triangle' ? (
                    <DisplayShapeOnGridWithBorder
                      points={shapeB.map(coord => coord as [number, number])}
                      dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                    />
                  ) : (
                    <DisplayShapeOnGridWithBorder
                      givenShape={shapeB as [[boolean, boolean]]}
                      dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                    />
                  )
              },
              {
                value: 'C',
                component:
                  shape === 'Triangle' ? (
                    <DisplayShapeOnGridWithBorder
                      points={shapeC.map(coord => coord as [number, number])}
                      dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                    />
                  ) : (
                    <DisplayShapeOnGridWithBorder
                      givenShape={shapeC as [[boolean, boolean]]}
                      dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                    />
                  )
              }
            ],
            { random: seededRandom(props.question) }
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aTn',
  description: 'aTn',
  keywords: ['Similar', 'Shape', 'Scale', 'Translation'],
  schema: z.object({
    height: z.number().int().min(1).max(2),
    width: z.number().int().min(1).max(3),
    rotations: numberEnum([90, 180, 270]).array().length(2),
    scale: z.number().int().min(2).max(3)
  }),
  simpleGenerator: () => {
    const height = randomIntegerInclusive(1, 2);
    const width = randomIntegerInclusive(1, 3);
    const scale = width === 3 ? 2 : randomIntegerInclusive(2, 3);
    const rotations =
      width * scale > 6
        ? ([180, 180] as (90 | 180)[])
        : getRandomSubArrayFromArray([90, 180, 270] as const, 2);
    return { height, width, rotations, scale };
  },
  Component: props => {
    const {
      question: { height, width, rotations, scale },
      translate
    } = props;

    const shapeAPoints = [
      [0, 0],
      [width, 0],
      [0, height]
    ];

    const shapeBPoints =
      rotations[0] === 90
        ? [
            [0, 0],
            [0, width * scale],
            [height * scale, width * scale]
          ]
        : rotations[0] === 180
        ? [
            [0, height * scale],
            [width * scale, height * scale],
            [width * scale, 0]
          ]
        : [
            [0, 0],
            [width * scale, height * scale],
            [width * scale, 0]
          ];

    const shapeCPoints =
      rotations[1] === 90
        ? [
            [0, 0],
            [0, width * (scale + 1)],
            [height * scale, width * (scale + 1)]
          ]
        : rotations[1] === 180
        ? [
            [0, height * scale],
            [width * (scale + 1), height * scale],
            [width * (scale + 1), 0]
          ]
        : [
            [0, 0],
            [width * (scale + 1), height * scale],
            [width * (scale + 1), 0]
          ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectSimilarShape()}
        pdfTitle={translate.instructions.circleSimilarShape()}
        testCorrect={['B']}
        numItems={2}
        Content={({ dimens }) => (
          <DisplayShapeOnGridWithBorder
            points={shapeAPoints.map(coord => coord as [number, number])}
            dimens={dimens}
          />
        )}
        renderItems={({ dimens }) => {
          return shuffle(
            [
              {
                value: 'B',
                component: (
                  <DisplayShapeOnGridWithBorder
                    points={shapeBPoints.map(coord => coord as [number, number])}
                    dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                  />
                )
              },
              {
                value: 'C',
                component: (
                  <DisplayShapeOnGridWithBorder
                    points={shapeCPoints.map(coord => coord as [number, number])}
                    dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                  />
                )
              }
            ],
            { random: seededRandom(props.question) }
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aTn2',
  description: 'aTn',
  keywords: ['Similar', 'Shape', 'Scale', 'Translation'],
  schema: z.object({
    height: z.number().int().min(1).max(2),
    width: z.number().int().min(1).max(4),
    rotations: numberEnum([90, 180, 270]).array().length(2),
    scale: z.number().int().min(2).max(3),
    shape: z.enum(['Triangle', 'Rectangle'])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['Triangle', 'Rectangle'] as const);
    const height = shape === 'Triangle' ? randomIntegerInclusive(1, 2) : 1;
    const width =
      shape === 'Triangle' ? randomIntegerInclusive(1, 3) : randomIntegerInclusive(3, 4);
    const scale = width === 3 ? 2 : randomIntegerInclusive(2, 3);
    const rotations =
      width * scale > 6
        ? ([180, 180] as (90 | 180)[])
        : getRandomSubArrayFromArray([90, 180, 270] as const, 2);
    return { shape, height, width, rotations, scale };
  },
  Component: props => {
    const {
      question: { shape, height, width, rotations, scale },
      translate,
      displayMode
    } = props;

    const shapeDimens: { [key: number]: { [key: number]: { top: number; right: number } } } =
      labelPositionQ1and2[shape];
    const { top, right } = shapeDimens[height][width];

    const shapeA =
      shape === 'Triangle'
        ? [
            [0, 0],
            [width, 0],
            [0, height]
          ]
        : createRectangleFromSquares(width, height);

    const shapeB =
      shape === 'Triangle'
        ? rotations[0] === 90
          ? [
              [0, 0],
              [0, width * scale],
              [height * scale, width * scale]
            ]
          : rotations[0] === 180
          ? [
              [0, height * scale],
              [width * scale, height * scale],
              [width * scale, 0]
            ]
          : [
              [0, 0],
              [width * scale, height * scale],
              [width * scale, 0]
            ]
        : createRectangleFromSquares(width * scale, height * scale);

    const shapeC =
      shape === 'Triangle'
        ? rotations[1] === 90
          ? [
              [0, 0],
              [0, width * (scale + 1)],
              [height * scale, width * (scale + 1)]
            ]
          : rotations[1] === 180
          ? [
              [0, height * scale],
              [width * (scale + 1), height * scale],
              [width * (scale + 1), 0]
            ]
          : [
              [0, 0],
              [width * (scale + 1), height * scale],
              [width * (scale + 1), 0]
            ]
        : createRectangleFromSquares(width * 2, height);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectSimilarShapeToShapeX(translate.letters.A())}
        pdfTitle={translate.instructions.circleSimilarShapeToShapeX(translate.letters.A())}
        testCorrect={['B']}
        numItems={2}
        Content={({ dimens }) => (
          <View style={{ position: 'relative' }}>
            {shape === 'Triangle' ? (
              <DisplayShapeOnGridWithBorder
                points={shapeA.map(coord => coord as [number, number])}
                dimens={dimens}
              />
            ) : (
              <DisplayShapeOnGridWithBorder
                givenShape={shapeA as [[boolean, boolean]]}
                dimens={dimens}
              />
            )}
            <Text
              variant="WRN400"
              style={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                position: 'absolute',
                top: displayMode === 'digital' ? top : top * 1.5,
                right: displayMode === 'digital' ? right : right * 1.5
              }}
            >
              {translate.letters.A()}
            </Text>
          </View>
        )}
        renderItems={({ dimens }) => {
          return shuffle(
            [
              {
                value: 'B',
                component:
                  shape === 'Triangle' ? (
                    <DisplayShapeOnGridWithBorder
                      points={shapeB.map(coord => coord as [number, number])}
                      dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                    />
                  ) : (
                    <DisplayShapeOnGridWithBorder
                      givenShape={shapeB as [[boolean, boolean]]}
                      dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                    />
                  )
              },
              {
                value: 'C',
                component:
                  shape === 'Triangle' ? (
                    <DisplayShapeOnGridWithBorder
                      points={shapeC.map(coord => coord as [number, number])}
                      dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                    />
                  ) : (
                    <DisplayShapeOnGridWithBorder
                      givenShape={shapeC as [[boolean, boolean]]}
                      dimens={{ height: dimens.height * 0.4, width: dimens.width * 0.9 }}
                    />
                  )
              }
            ],
            { random: seededRandom(props.question) }
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aTo',
  description: 'aTo',
  keywords: ['Similar', 'Shape', 'Scale', 'Translation'],
  schema: z.object({
    height: z.number().int().min(1).max(3),
    width: z.number().int().min(1).max(4),
    shape: z.enum(['lShape', 'rectangle', 'square', 't', 's']),
    scale: z.number().int().min(2).max(3),
    rotations: numberEnum([90, 180]).array().length(2)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['lShape', 'rectangle', 'square', 't', 's'] as const);
    const shapeSquares = ['square', 't', 's'].includes(shape) ? 4 : randomIntegerInclusive(3, 4);
    const width = shape === 'rectangle' ? shapeSquares : 2;
    const height = shape === 'square' ? 2 : shape === 'rectangle' ? 1 : shapeSquares - 1;
    const scale = width >= 3 || height === 3 ? 2 : randomIntegerInclusive(2, 3);
    const rotations = width >= 3 ? ([180, 180] as (90 | 180)[]) : shuffle([90, 180] as const);

    return { height, width, shape, scale, rotations };
  },
  Component: props => {
    const {
      question: { height, width, shape, rotations },
      translate
    } = props;

    const shapeA =
      shape === 'lShape'
        ? createLShape({
            rectangleWidth: width,
            rectangleHeight: height,
            whiteSpaceWidth: 1,
            whiteSpaceHeight: height === 2 ? 1 : 2
          })
        : shape === 't'
        ? getTShape(1)
        : shape === 's'
        ? getSShape(1)
        : createRectangleFromSquares(width, height);

    const shapeB =
      shape === 't'
        ? getTShape(2)
        : shape === 's'
        ? getSShape(2)
        : scaleShape({ shapeHeight: height, shapeWidth: width, scale: 2, shape });

    const shapeC =
      shape === 'lShape'
        ? createLShape({
            rectangleWidth: width * 2,
            rectangleHeight: height,
            whiteSpaceWidth: 2,
            whiteSpaceHeight: height === 2 ? 1 : 2
          })
        : shape === 't'
        ? incorrectTScale2
        : shape === 's'
        ? incorrectSScale2
        : createRectangleFromSquares(width * 2, height);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectSimilarShape()}
        pdfTitle={translate.instructions.circleSimilarShape()}
        testCorrect={['B']}
        numItems={2}
        Content={({ dimens }) => (
          <DisplayShapeOnGridWithBorder givenShape={shapeA} dimens={dimens} />
        )}
        renderItems={({ dimens }) => {
          return shuffle(
            [
              {
                value: 'B',
                component: (
                  <View style={{ transform: `rotate(${rotations[0]}deg)` }}>
                    <DisplayShapeOnGridWithBorder
                      givenShape={shapeB}
                      dimens={
                        rotations[0] === 90
                          ? { height: dimens.width * 0.9, width: dimens.height * 0.4 }
                          : { height: dimens.height * 0.4, width: dimens.width * 0.9 }
                      }
                    />
                  </View>
                )
              },
              {
                value: 'C',
                component: (
                  <View style={{ transform: `rotate(${rotations[1]}deg)` }}>
                    <DisplayShapeOnGridWithBorder
                      givenShape={shapeC}
                      dimens={
                        rotations[1] === 90
                          ? { height: dimens.width * 0.9, width: dimens.height * 0.4 }
                          : { height: dimens.height * 0.4, width: dimens.width * 0.9 }
                      }
                    />
                  </View>
                )
              }
            ],
            { random: seededRandom(props.question) }
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'aTo2',
  description: 'aTo',
  keywords: ['Similar', 'Shape', 'Scale', 'Translation'],
  schema: z.object({
    height: z.number().int().min(1).max(3),
    width: z.number().int().min(1).max(4),
    shape: z.enum(['lShape', 't', 's']),
    scale: z.number().int().min(2).max(3),
    rotations: numberEnum([90, 180]).array().length(2)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['lShape', 't', 's'] as const);
    const shapeSquares = ['t', 's'].includes(shape) ? 4 : randomIntegerInclusive(3, 4);
    const width = 2;
    const height = shapeSquares - 1;
    const scale = width >= 3 || height === 3 ? 2 : randomIntegerInclusive(2, 3);
    const rotations = width >= 3 ? ([180, 180] as (90 | 180)[]) : shuffle([90, 180] as const);

    return { height, width, shape, scale, rotations };
  },
  Component: props => {
    const {
      question: { height, width, shape, rotations },
      translate,
      displayMode
    } = props;

    const top = shape === 'lShape' && height === 2 ? 70 : 95;
    const right = shape === 's' ? 90 : shape === 'lShape' && height === 2 ? 145 : 115;

    const shapeA =
      shape === 'lShape'
        ? createLShape({
            rectangleWidth: width,
            rectangleHeight: height,
            whiteSpaceWidth: 1,
            whiteSpaceHeight: height === 2 ? 1 : 2
          })
        : shape === 't'
        ? getTShape(1)
        : getSShape(1);

    const shapeB =
      shape === 't'
        ? getTShape(2)
        : shape === 's'
        ? getSShape(2)
        : scaleShape({ shapeHeight: height, shapeWidth: width, scale: 2, shape });

    const shapeC =
      shape === 'lShape'
        ? createLShape({
            rectangleWidth: width * 2,
            rectangleHeight: height,
            whiteSpaceWidth: 2,
            whiteSpaceHeight: height === 2 ? 1 : 2
          })
        : shape === 't'
        ? incorrectTScale2
        : incorrectSScale2;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectSimilarShapeToShapeX(translate.letters.A())}
        pdfTitle={translate.instructions.circleSimilarShapeToShapeX(translate.letters.A())}
        testCorrect={['B']}
        numItems={2}
        Content={({ dimens }) => (
          <View style={{ position: 'relative' }}>
            <DisplayShapeOnGridWithBorder givenShape={shapeA} dimens={dimens} />
            <Text
              variant="WRN400"
              style={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                position: 'absolute',
                top: displayMode === 'digital' ? top : top * 1.5,
                right: displayMode === 'digital' ? right : right * 1.5
              }}
            >
              {translate.letters.A()}
            </Text>
          </View>
        )}
        renderItems={({ dimens }) => {
          return shuffle(
            [
              {
                value: 'B',
                component: (
                  <View style={{ transform: `rotate(${rotations[0]}deg)` }}>
                    <DisplayShapeOnGridWithBorder
                      givenShape={shapeB}
                      dimens={
                        rotations[0] === 90
                          ? { height: dimens.width * 0.9, width: dimens.height * 0.4 }
                          : { height: dimens.height * 0.4, width: dimens.width * 0.9 }
                      }
                    />
                  </View>
                )
              },
              {
                value: 'C',
                component: (
                  <View style={{ transform: `rotate(${rotations[1]}deg)` }}>
                    <DisplayShapeOnGridWithBorder
                      givenShape={shapeC}
                      dimens={
                        rotations[1] === 90
                          ? { height: dimens.width * 0.9, width: dimens.height * 0.4 }
                          : { height: dimens.height * 0.4, width: dimens.width * 0.9 }
                      }
                    />
                  </View>
                )
              }
            ],
            { random: seededRandom(props.question) }
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aTp',
  description: 'aTp',
  keywords: ['Similar', 'Shape', 'Scale'],
  schema: z.object({
    isCorrect: z.boolean(),
    height: z.number().int().min(1).max(3),
    width: z.number().int().min(1).max(4),
    shape: z.enum(['lShape', 'rectangle', 'square', 't', 's']),
    rotation: numberEnum([0, 90, 180, 270])
  }),
  simpleGenerator: () => {
    const isCorrect = getRandomBoolean();
    const shape = getRandomFromArray(['lShape', 'rectangle', 'square', 't', 's'] as const);
    const shapeSquares = ['square', 't', 's'].includes(shape) ? 4 : randomIntegerInclusive(3, 4);
    const width = shape === 'rectangle' ? shapeSquares : 2;
    const height = shape === 'square' ? 2 : shape === 'rectangle' ? 1 : shapeSquares - 1;
    const rotation = getRandomFromArray([0, 90, 180, 270] as const);

    return { height, isCorrect, width, shape, rotation };
  },
  Component: ({
    question: { height, isCorrect, width, shape, rotation },
    translate,
    displayMode
  }) => {
    const shapeA =
      shape === 'lShape'
        ? createLShape({
            rectangleWidth: width,
            rectangleHeight: height,
            whiteSpaceWidth: 1,
            whiteSpaceHeight: height === 2 ? 1 : 2
          })
        : shape === 't'
        ? getTShape(1)
        : shape === 's'
        ? getSShape(1)
        : createRectangleFromSquares(width, height);

    let shapeB: boolean[][];
    if (isCorrect) {
      shapeB =
        shape === 't'
          ? getTShape(2)
          : shape === 's'
          ? getSShape(2)
          : scaleShape({ shapeHeight: height, shapeWidth: width, scale: 2, shape });
    } else {
      shapeB =
        shape === 'lShape'
          ? createLShape({
              rectangleWidth: width * 2,
              rectangleHeight: height,
              whiteSpaceWidth: 1,
              whiteSpaceHeight: height === 2 ? 1 : 2
            })
          : shape === 't'
          ? incorrectTScale2
          : shape === 's'
          ? incorrectSScale2
          : createRectangleFromSquares(width * 2, height);
    }

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.selectWhetherTheStatementIsTrueOrFalse()}
        correctAnswer={isCorrect}
        selectableTextStyle={{ fontSize: displayMode === 'digital' ? 40 : 50, fontWeight: '700' }}
        sentence={translate.instructions.theTwoShapesAreSimilar()}
        content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: dimens.width
            }}
          >
            <DisplayShapeOnGridWithBorder
              givenShape={shapeA}
              dimens={{ height: dimens.height, width: dimens.width * 0.4 }}
            />
            <View style={{ transform: `rotate(${rotation}deg)` }}>
              <DisplayShapeOnGridWithBorder
                givenShape={shapeB}
                dimens={
                  rotation === 90 || rotation === 270
                    ? { height: dimens.width * 0.4, width: dimens.height }
                    : { height: dimens.height, width: dimens.width * 0.4 }
                }
              />
            </View>
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aTq',
  description: 'aTq',
  keywords: ['Similar', 'Shape', 'Scale'],
  schema: z.object({
    scale: z.number().int().min(2).max(10),
    lengths: z.array(z.number().int().min(2).max(12)).length(2),
    shape: QuadrilateralShapesSchema,
    color: quadrilateralColorsSchema,
    letter: algebraicSymbolSchema,
    answerIndex: z.number().min(0).max(1)
  }),
  simpleGenerator: () => {
    const scale = randomIntegerInclusive(2, 10);
    const shape = getRandomFromArray(shapes);
    const color = getRandomFromArray(quadrilateralColors);

    const length1 = randomIntegerInclusive(2, 12);
    const lengths = ['Square', 'Rhombus'].includes(shape)
      ? [length1, length1]
      : randomUniqueIntegersInclusive(2, 12, 2);

    const letter = getAlgebraicSymbol();
    const answerIndex = scale * lengths[0] > 99 ? 1 : randomIntegerInclusive(0, 1);

    return { scale, shape, color, lengths, letter, answerIndex };
  },
  Component: props => {
    const {
      question: { scale, shape, color, lengths, letter, answerIndex },
      translate
    } = props;

    const orderedLabels = sortNumberArray(lengths, 'descending');
    const labels = [orderedLabels[0], orderedLabels[1]];

    const labelsSmall = countRange(4).map(i =>
      i > 1 ? translate.units.numberOfCm(labels[i - 2]) : ''
    );

    const labelsLarge = countRange(4).map(i =>
      i > 1 && i === answerIndex + 2
        ? letter
        : i > 1
        ? translate.units.numberOfCm(labels[i - 2] * scale)
        : ''
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.twoShapesAreSimilarWorkOutLengthOfX(letter)}
        Content={({ dimens }) => (
          <LabelledSmallLargeQuadrilateralsWithState
            id="labelShape"
            dimens={{ height: dimens.height, width: dimens.width }}
            labelsLarge={labelsLarge}
            labelsSmall={labelsSmall}
            color={color}
            shape={shape}
            symbol={translate.units.cm()}
          />
        )}
        sentence={translate.answerSentences.xEqualsAnsCm(letter)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(labels[answerIndex] * scale).toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aTr',
  description: 'aTr',
  keywords: ['Similar', 'Shape', 'Scale'],
  schema: z.object({
    scale: z.number().int().min(2).max(10),
    lengths: z.array(z.number().int().min(2).max(12)).length(2),
    shape: QuadrilateralShapesSchema,
    color: quadrilateralColorsSchema,
    letter: algebraicSymbolSchema,
    answerIndex: z.number().min(0).max(1)
  }),
  simpleGenerator: () => {
    const scale = randomIntegerInclusive(2, 10);
    const shape = getRandomFromArray(shapes);
    const color = getRandomFromArray(quadrilateralColors);

    const length1 = randomIntegerInclusive(2, 12);
    const lengths = ['Square', 'Rhombus'].includes(shape)
      ? [length1, length1]
      : randomUniqueIntegersInclusive(2, 12, 2);

    const letter = getAlgebraicSymbol();
    const answerIndex = randomIntegerInclusive(0, 1);

    return { scale, shape, color, lengths, letter, answerIndex };
  },
  Component: props => {
    const {
      question: { scale, shape, color, lengths, letter, answerIndex },
      translate
    } = props;

    const orderedLabels = sortNumberArray(lengths, 'descending');
    const labels = [orderedLabels[0], orderedLabels[1]];

    const labelsSmall = countRange(4).map(i =>
      i > 1 && i === answerIndex + 2
        ? letter
        : i > 1
        ? translate.units.numberOfCm(labels[i - 2])
        : ''
    );

    const labelsLarge = countRange(4).map(i =>
      i > 1 ? translate.units.numberOfCm(labels[i - 2] * scale) : ''
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.twoShapesAreSimilarWorkOutLengthOfX(letter)}
        Content={({ dimens }) => (
          <LabelledSmallLargeQuadrilateralsWithState
            id="labelShape"
            dimens={{ height: dimens.height, width: dimens.width }}
            labelsLarge={labelsLarge}
            labelsSmall={labelsSmall}
            color={color}
            shape={shape}
            symbol={translate.units.cm()}
          />
        )}
        sentence={translate.answerSentences.xEqualsAnsCm(letter)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[labels[answerIndex].toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SimilarShapes',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
