// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MultiplicationEqualGroups from './1MultiplicationEqualGroups';
import UseArrays from './2UseArrays';
import MultiplesOf2 from './3MultiplesOf2';
import MultiplesOf5And10 from './4MultiplesOf5And10';
import SharingAndGrouping from './5SharingAndGrouping';
import MultiplyBy3 from './6MultiplyBy3';
import DivideBy3 from './7DivideBy3';
import The3TimesTable from './8The3TimesTable';
import MultiplyBy4 from './9MultiplyBy4';
import DivideBy4 from './10DivideBy4';
import The4TimesTable from './11The4TimesTable';
import MultiplyBy8 from './12MultiplyBy8';
import DivideBy8 from './13DivideBy8';
import The8TimesTable from './14The8TimesTable';
import The24And8TimesTables from './15The24And8TimesTables';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionA',
  field: 'Number',
  weeks: [9, 12],
  smallSteps: [
    MultiplicationEqualGroups, // Step 1
    UseArrays, // Step 2
    MultiplesOf2, // Step 3
    MultiplesOf5And10, // Step 4
    SharingAndGrouping, // Step 5
    MultiplyBy3, // Step 6
    DivideBy3, // Step 7
    The3TimesTable, // Step 8
    MultiplyBy4, // Step 9
    DivideBy4, // Step 10
    The4TimesTable, // Step 11
    MultiplyBy8, // Step 12
    DivideBy8, // Step 13
    The8TimesTable, // Step 14
    The24And8TimesTables // Step 15
  ]
});
export default Block;
