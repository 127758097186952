import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import { G, Line, TSpan, Text as SvgText } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { arraysHaveSameContentsUnordered, countRange } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import Text from '../../../../components/typography/Text';
import { lineSvg, lineSvgPoints } from '../../../../components/question/representations/LineSvgs';
import { GridLine } from '../../../../utils/gridUtils';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avg',
  description: 'avg',
  keywords: ['Parallel', 'Perpendicular'],
  schema: z.object({
    lines: z.array(z.enum(['parallel', 'perpendicular', 'touching', 'noIntersect'])).length(2),
    isLeft: z.boolean().array().length(2),
    isParallel: z.boolean(),
    rotation: z.number().int().min(0).max(359)
  }),
  simpleGenerator: () => {
    const lines = [
      'parallel',
      getRandomFromArray(['perpendicular', 'touching', 'noIntersect'] as const)
    ] as ('parallel' | 'perpendicular' | 'touching' | 'noIntersect')[];

    const isLeft = countRange(2).map(getRandomBoolean);
    const isParallel = getRandomBoolean();
    const rotation = randomIntegerInclusive(0, 359);

    return { lines: shuffle(lines), isLeft, isParallel, rotation };
  },
  Component: props => {
    const {
      question: { lines, isLeft, isParallel, rotation },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={
          isParallel
            ? translate.instructions.selectLinesThatAreParallel()
            : translate.instructions.selectLinesThatAreNotParallel()
        }
        pdfTitle={translate.instructions.circleLinesThatAreNotParallel()}
        testCorrect={lines.filter(val => (isParallel ? val === 'parallel' : val !== 'parallel'))}
        numItems={2}
        multiSelect
        renderItems={({ dimens }) => {
          return lines.map((line, i) => ({
            value: line,
            component: (
              <View style={{ transform: `rotate(${rotation}deg)` }}>
                {lineSvg(
                  { height: dimens.height * 0.6, width: dimens.width * 0.6 },
                  line,
                  isLeft[i]
                )}
              </View>
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'avh',
  description: 'avh',
  keywords: ['Parallel', 'Perpendicular'],
  schema: z.object({
    isParallel: z.boolean(),
    lineCoords: z
      .array(
        z.object({
          index: z.enum(['A', 'B', 'C', 'D', 'E']),
          x1: z.number().int(),
          y1: z.number().int(),
          x2: z.number().int(),
          y2: z.number().int()
        })
      )
      .length(5),
    parallelQuads: z.array(
      z.object({
        label: z.enum(['B', 'C', 'D', 'E']),
        min: z.number(),
        max: z.number()
      })
    ),
    otherQuads: z.array(
      z.object({
        label: z.enum(['B', 'C', 'D', 'E']),
        min: z.number(),
        max: z.number()
      })
    )
  }),
  simpleGenerator: () => {
    const isParallel = getRandomBoolean();
    const isLineAVertical = getRandomBoolean();
    const showDiagonalLine = getRandomBoolean();

    const quads = shuffle([
      { min: 3, max: 4, label: 'B' as const },
      { min: 6, max: 7, label: 'C' as const },
      { min: 9, max: 10, label: 'D' as const },
      { min: 12, max: 13, label: 'E' as const }
    ]);

    const { lineCoords, parallelQuads, otherQuads } = rejectionSample(
      () => {
        const numberOfParallel = getRandomFromArray([2, 3]);
        const parallelQuads = quads.slice(0, numberOfParallel);
        const otherQuads = quads.slice(numberOfParallel);

        const lineA1x1 = 1;
        const lineA1y1 = randomIntegerInclusive(1, 3);
        const lineA1y2 = randomIntegerInclusive(lineA1y1 + 2, 5);
        const lineA1x2 = randomIntegerInclusive(lineA1x1 + 2, 3);

        const coordA = isLineAVertical
          ? { index: 'A' as const, x1: lineA1x1, y1: lineA1y1, x2: lineA1x1, y2: lineA1y2 }
          : { index: 'A' as const, x1: lineA1x1, y1: lineA1y1, x2: lineA1x2, y2: lineA1y1 };

        const parallelCoords = parallelQuads.map(({ min, max, label }) => {
          const line1x = randomIntegerInclusive(min + 1, max);
          const line1y = randomIntegerInclusive(1, 3, { constraint: x => x !== lineA1y1 });
          const line1y2 = randomIntegerInclusive(line1y + 2, 5);
          const line1x2 = randomIntegerInclusive(line1x + 2, line1x + 3);

          if (isLineAVertical) {
            return { index: label, x1: line1x, y1: line1y, x2: line1x, y2: line1y2 };
          } else {
            return { index: label, x1: line1x, y1: line1y, x2: line1x2, y2: line1y };
          }
        });

        const otherCoords = otherQuads.map(({ min, max, label }, index) => {
          const line1x = randomIntegerInclusive(min + 1, max);
          const line1y = randomIntegerInclusive(1, 3, { constraint: x => x !== lineA1y1 });
          const line1y2 = randomIntegerInclusive(line1y + 2, 5);
          const line1x2 = randomIntegerInclusive(line1x + 2, line1x + 3);
          const line1x2b = randomIntegerInclusive(line1x + 1, line1x + 2);

          if (index === 0 && showDiagonalLine) {
            return { index: label, x1: line1x, y1: line1y, x2: line1x2b, y2: line1y2 };
          }
          if (!isLineAVertical) {
            return { index: label, x1: line1x, y1: line1y, x2: line1x, y2: line1y2 };
          } else {
            return { index: label, x1: line1x, y1: line1y, x2: line1x2, y2: line1y };
          }
        });

        const lineCoords = [coordA, ...parallelCoords, ...otherCoords];

        return { lineCoords, parallelCoords, numberOfParallel, parallelQuads, otherQuads };
      },
      ({ lineCoords, parallelCoords, numberOfParallel }) => {
        const sortedLineCoords = lineCoords.sort((a, b) => a.index.localeCompare(b.index));

        const lengthOfParallelLines: number[] = [];

        // Lengths of lines so we can check at least 1 is different when there are 3 parallel lines
        if (isLineAVertical) {
          lengthOfParallelLines.push(...parallelCoords.map(el => el.y2 - el.y1));
        } else {
          lengthOfParallelLines.push(...parallelCoords.map(el => el.x2 - el.x1));
        }

        const hasDifferentElement =
          lengthOfParallelLines.length > 1 &&
          !lengthOfParallelLines.every(element => element === lengthOfParallelLines[0]);

        const doLineOverLap = sortedLineCoords.every((el, index, array) => {
          // Skip the last element since there's no next element to compare
          if (index === array.length - 1) return true;
          return el.x2 !== array[index + 1].x1;
        });

        // If there are 3 Parallel lines check at least 1 has a different length
        return numberOfParallel === 3 ? hasDifferentElement && doLineOverLap : doLineOverLap;
      }
    );

    return { isParallel, lineCoords, parallelQuads, otherQuads };
  },
  Component: props => {
    const {
      question: { isParallel, lineCoords, parallelQuads, otherQuads },
      translate,
      displayMode
    } = props;

    const answers = isParallel ? parallelQuads.map(el => el.label) : otherQuads.map(el => el.label);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          isParallel
            ? translate.instructions.selectAllOfTheLinesThatAreParallelToA()
            : translate.instructions.selectAllOfTheLinesThatAreNotParallelToA()
        }
        pdfTitle={
          isParallel
            ? translate.instructions.circleAllOfTheLinesThatAreParallelToA()
            : translate.instructions.circleAllOfTheLinesThatAreNotParallelToA()
        }
        testCorrect={answers}
        itemLayout={'row'}
        multiSelect
        numItems={4}
        Content={({ dimens }) => (
          <Grid
            width={dimens.width}
            height={dimens.height * 0.9}
            xAxis={null}
            yAxis={null}
            hideContinuationLines={true}
            squareGrid
            xMax={16}
            yMax={6}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                {lineCoords.map((val, i) => (
                  <G key={i}>
                    <SvgText
                      y={mathToSvgY(Math.max(val.y1, val.y2) + 0.2)}
                      x={mathToSvgX(Math.abs((val.x1 - val.x2) / 2) + Math.min(val.x1, val.x2))}
                      textAnchor="middle"
                    >
                      <TSpan fontSize="36" fill="black" fontFamily="White_Rose_Noto-Bold">
                        {translate.letters[val.index as 'A' | 'B' | 'C' | 'D' | 'E']()}
                      </TSpan>
                    </SvgText>
                    <Line
                      x1={mathToSvgX(val.x1)}
                      y1={mathToSvgY(val.y1)}
                      x2={mathToSvgX(val.x2)}
                      y2={mathToSvgY(val.y2)}
                      stroke={colors.prussianBlue}
                      strokeWidth={displayMode === 'digital' ? 4 : 6}
                    />
                  </G>
                ))}
              </GridSvgChildren>
            )}
          </Grid>
        )}
        renderItems={['B', 'C', 'D', 'E'].map(value => ({
          value,
          component: (
            <Text variant="WRN700">{translate.letters[value as 'B' | 'C' | 'D' | 'E']()}</Text>
          )
        }))}
        customMarkSchemeAnswer={{
          answerToDisplay: answers
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'avi',
  description: 'avi',
  keywords: ['Parallel', 'Perpendicular'],
  schema: z.object({
    isParallel: z.boolean(),
    lineCoords: z
      .array(
        z.object({
          index: z.enum(['A', 'B', 'C', 'D', 'E']),
          x1: z.number().int(),
          y1: z.number().int(),
          x2: z.number().int(),
          y2: z.number().int()
        })
      )
      .length(5),
    parallelQuads: z.array(
      z.object({
        label: z.enum(['B', 'C', 'D', 'E']),
        min: z.number(),
        max: z.number()
      })
    ),
    otherQuads: z.array(
      z.object({
        label: z.enum(['B', 'C', 'D', 'E']),
        min: z.number(),
        max: z.number()
      })
    )
  }),
  simpleGenerator: () => {
    const isParallel = getRandomBoolean();
    const isDiagonalAInverted = getRandomBoolean();

    const quads = shuffle([
      { min: 3, max: 4, label: 'B' as const },
      { min: 6, max: 7, label: 'C' as const },
      { min: 9, max: 10, label: 'D' as const },
      { min: 12, max: 13, label: 'E' as const }
    ]);

    const { lineCoords, parallelQuads, otherQuads } = rejectionSample(
      () => {
        const numberOfParallel = getRandomFromArray([2, 3]);

        const parallelQuads = quads.slice(0, numberOfParallel);
        const otherQuads = quads.slice(numberOfParallel);

        // First coord
        // If there are 3 parallel lines then we need to make sure that we can make another line with same gradient but of different length
        const length =
          numberOfParallel === 2 ? randomIntegerInclusive(2, 4) : randomIntegerInclusive(2, 3);
        const lineA1x1 = 1;
        const lineA1y1 = randomIntegerInclusive(1, length === 4 ? 2 : 3);
        const lineA1y2 = randomIntegerInclusive(lineA1y1 + 2, 6, {
          constraint: x => x - lineA1y1 === length
        });
        const lineA1x2 = isDiagonalAInverted ? 0 : 2;

        const coordA = {
          index: 'A' as const,
          x1: lineA1x1,
          y1: lineA1y1,
          x2: lineA1x2,
          y2: lineA1y2
        };

        const parallelCoords = parallelQuads.map(({ min, max, label }, i) => {
          const line1x = randomIntegerInclusive(min + 1, max);
          const numOfIdentical =
            numberOfParallel === 3 ? 2 : length <= 3 ? randomIntegerInclusive(1, 2) : 2;
          // make some the exact same as A
          if (i < numOfIdentical) {
            const line1y = randomIntegerInclusive(1, length === 4 ? 2 : 3, {
              constraint: x => x !== lineA1y1
            });
            const line1y2 = randomIntegerInclusive(line1y + 2, 6, {
              constraint: x => x - line1y === length
            });
            const line1x2 = isDiagonalAInverted ? line1x - 1 : line1x + 1;
            return { index: label, x1: line1x, y1: line1y, x2: line1x2, y2: line1y2 };
            // else we need one of a different length but same gradient
          } else {
            const line1y = length === 3 ? 0 : randomIntegerInclusive(1, 2);
            const line1y2 = line1y + length * 2;
            const x1 = isDiagonalAInverted ? max + 2 : max;
            const line1x2 = isDiagonalAInverted ? x1 - 2 : x1 + 2;
            return { index: label, x1: x1, y1: line1y, x2: line1x2, y2: line1y2 };
          }
        });

        const otherCoords = otherQuads.map(({ min, max, label }) => {
          const line1x = randomIntegerInclusive(min + 1, max);
          const line1y = randomIntegerInclusive(1, 3, { constraint: x => x !== lineA1y1 });
          const line1y2 = randomIntegerInclusive(line1y + 2, 5);
          const line1x2 = randomIntegerInclusive(line1x + 2, line1x + 3);
          const line1x2b = isDiagonalAInverted ? line1x + 1 : line1x - 1;
          const diagLength = randomIntegerInclusive(line1y + 2, 6, {
            constraint: x => x - line1y !== length
          });
          const line1x2bWrongDiag = getRandomFromArray([line1x + 1, line1x - 1]);

          const direction = getRandomFromArray([
            'horizontal',
            'vertical',
            'diagonal',
            'diagonalDifferentLength'
          ] as const);

          switch (direction) {
            case 'vertical':
              return { index: label, x1: line1x, y1: line1y, x2: line1x, y2: line1y2 };
            case 'diagonal':
              return { index: label, x1: line1x, y1: line1y, x2: line1x2b, y2: line1y2 };
            case 'diagonalDifferentLength':
              return {
                index: label,
                x1: line1x,
                y1: line1y,
                x2: line1x2bWrongDiag,
                y2: diagLength
              };
            default:
              return { index: label, x1: line1x, y1: line1y, x2: line1x2, y2: line1y };
          }
        });

        const lineCoords = [coordA, ...parallelCoords, ...otherCoords];

        return { lineCoords, parallelCoords, numberOfParallel, parallelQuads, otherQuads };
      },
      ({ lineCoords }) => {
        const sortedLineCoords = lineCoords.sort((a, b) => a.index.localeCompare(b.index));

        const doLineOverLap = sortedLineCoords.every((el, index, array) => {
          // Skip the last element since there's no next element to compare
          if (index === array.length - 1) return true;
          return (
            el.x2 !== array[index + 1].x1 &&
            el.x2 !== array[index + 1].x2 &&
            el.x1 !== array[index + 1].x2 &&
            el.x1 !== array[index + 1].x1
          );
        });

        return doLineOverLap;
      }
    );

    return { isParallel, lineCoords, parallelQuads, otherQuads };
  },
  Component: props => {
    const {
      question: { isParallel, lineCoords, parallelQuads, otherQuads },
      translate,
      displayMode
    } = props;

    const answers = isParallel ? parallelQuads.map(el => el.label) : otherQuads.map(el => el.label);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          isParallel
            ? translate.instructions.selectAllOfTheLinesThatAreParallelToA()
            : translate.instructions.selectAllOfTheLinesThatAreNotParallelToA()
        }
        pdfTitle={
          isParallel
            ? translate.instructions.circleAllOfTheLinesThatAreParallelToA()
            : translate.instructions.circleAllOfTheLinesThatAreNotParallelToA()
        }
        testCorrect={answers}
        itemLayout={'row'}
        multiSelect
        numItems={4}
        Content={({ dimens }) => (
          <Grid
            width={dimens.width}
            height={dimens.height * 0.9}
            xAxis={null}
            yAxis={null}
            hideContinuationLines={true}
            squareGrid
            xMax={16}
            yMax={6}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                {lineCoords.map((val, i) => (
                  <G key={i}>
                    <SvgText
                      y={
                        Math.max(val.y1, val.y2) === 6
                          ? mathToSvgY(Math.min(val.y1, val.y2) + 0.2)
                          : mathToSvgY(Math.max(val.y1, val.y2) + 0.2)
                      }
                      x={mathToSvgX(Math.abs((val.x1 - val.x2) / 2) + Math.min(val.x1, val.x2))}
                      textAnchor="middle"
                    >
                      <TSpan fontSize="36" fill="black" fontFamily="White_Rose_Noto-Bold">
                        {translate.letters[val.index as 'A' | 'B' | 'C' | 'D' | 'E']()}
                      </TSpan>
                    </SvgText>
                    <Line
                      x1={mathToSvgX(val.x1)}
                      y1={mathToSvgY(val.y1)}
                      x2={mathToSvgX(val.x2)}
                      y2={mathToSvgY(val.y2)}
                      stroke={colors.prussianBlue}
                      strokeWidth={displayMode === 'digital' ? 4 : 6}
                    />
                  </G>
                ))}
              </GridSvgChildren>
            )}
          </Grid>
        )}
        renderItems={['B', 'C', 'D', 'E'].map(value => ({
          value,
          component: (
            <Text variant="WRN700">{translate.letters[value as 'B' | 'C' | 'D' | 'E']()}</Text>
          )
        }))}
        customMarkSchemeAnswer={{
          answerToDisplay: answers
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'avj',
  description: 'avj',
  keywords: ['Parallel', 'Perpendicular'],
  schema: z.object({
    lines: z
      .array(
        z.object({
          line: z.enum(['parallel', 'perpendicular', 'touching', 'noIntersect']),
          isLeft: z.boolean()
        })
      )
      .length(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const lines = [
      { line: 'perpendicular' as const, isLeft: true },
      { line: 'perpendicular' as const, isLeft: false },
      ...getRandomSubArrayFromArray(['parallel', 'touching', 'noIntersect'] as const, 2).map(
        val => ({ line: val, isLeft: getRandomBoolean() })
      )
    ];

    return { lines: shuffle(lines) };
  },
  Component: props => {
    const {
      question: { lines },
      translate,
      displayMode
    } = props;

    return (
      <QF11SelectImagesUpTo4
        questionHeight={1000}
        title={translate.instructions.selectLinesThatArePerpendicular()}
        pdfTitle={translate.instructions.circleLinesThatArePerpendicular()}
        testCorrect={lines
          .map((val, i) => ({ line: val.line, index: ['A', 'B', 'C', 'D'][i] }))
          .filter(val => val.line === 'perpendicular')
          .map(val => val.index)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return lines.map(({ line, isLeft }, i) => {
            const { line1, line2 } = lineSvgPoints(4, 4, line, isLeft);
            return {
              value: ['A', 'B', 'C', 'D'][i],
              component: (
                <Grid
                  width={dimens.width * 0.9}
                  height={dimens.height * 0.9}
                  xMin={-1}
                  xMax={5}
                  yMax={5}
                  yMin={-1}
                  squareGrid
                  xAxis={null}
                  yAxis={null}
                  hideContinuationLines
                >
                  <GridSvgChildren>
                    <GridLine
                      points={line1 as [number, number][]}
                      strokeWidth={displayMode === 'digital' ? 4 : 6}
                    />
                    <GridLine
                      points={line2 as [number, number][]}
                      strokeWidth={displayMode === 'digital' ? 4 : 6}
                    />
                  </GridSvgChildren>
                </Grid>
              )
            };
          });
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'avk',
  description: 'avk',
  keywords: ['Parallel', 'Perpendicular'],
  schema: z.object({
    isPerpendicular: z.boolean(),
    lineCoords: z
      .array(
        z.object({
          index: z.enum(['A', 'B', 'C', 'D', 'E']),
          x1: z.number().int(),
          y1: z.number().int(),
          x2: z.number().int(),
          y2: z.number().int()
        })
      )
      .length(5),
    perpendicularQuads: z.array(
      z.object({
        label: z.enum(['B', 'C', 'D', 'E']),
        min: z.number(),
        max: z.number()
      })
    ),
    otherQuads: z.array(
      z.object({
        label: z.enum(['B', 'C', 'D', 'E']),
        min: z.number(),
        max: z.number()
      })
    )
  }),
  simpleGenerator: () => {
    const isPerpendicular = getRandomBoolean();
    const isLineAVertical = getRandomBoolean();
    const showDiagonalLine = getRandomBoolean();

    const quads = shuffle([
      { min: 3, max: 4, label: 'B' as const },
      { min: 6, max: 7, label: 'C' as const },
      { min: 9, max: 10, label: 'D' as const },
      { min: 12, max: 13, label: 'E' as const }
    ]);

    const { lineCoords, perpendicularQuads, otherQuads } = rejectionSample(
      () => {
        const numberOfPerpendicular = getRandomFromArray([2, 3]);

        const perpendicularQuads = quads.slice(0, numberOfPerpendicular);
        const otherQuads = quads.slice(numberOfPerpendicular);

        const lineA1x1 = 1;
        const lineA1y1 = randomIntegerInclusive(1, 3);
        const lineA1y2 = randomIntegerInclusive(lineA1y1 + 2, 5);
        const lineA1x2 = randomIntegerInclusive(lineA1x1 + 2, 3);

        const coordA = isLineAVertical
          ? { index: 'A' as const, x1: lineA1x1, y1: lineA1y1, x2: lineA1x1, y2: lineA1y2 }
          : { index: 'A' as const, x1: lineA1x1, y1: lineA1y1, x2: lineA1x2, y2: lineA1y1 };

        const perpendicularCoords = perpendicularQuads.map(({ min, max, label }) => {
          const line1x = randomIntegerInclusive(min + 1, max);
          const line1y = randomIntegerInclusive(1, 3, { constraint: x => x !== lineA1y1 });
          const line1y2 = randomIntegerInclusive(line1y + 2, 5);
          const line1x2 = randomIntegerInclusive(line1x + 2, line1x + 3);
          if (isLineAVertical) {
            return { index: label, x1: line1x, y1: line1y, x2: line1x2, y2: line1y };
          } else {
            return { index: label, x1: line1x, y1: line1y, x2: line1x, y2: line1y2 };
          }
        });

        const otherCoords = otherQuads.map(({ min, max, label }, index) => {
          const line1x = randomIntegerInclusive(min + 1, max);
          const line1y = randomIntegerInclusive(1, 3, { constraint: x => x !== lineA1y1 });
          const line1y2 = randomIntegerInclusive(line1y + 2, 5);
          const line1x2 = randomIntegerInclusive(line1x + 2, line1x + 3);
          const line1x2b = randomIntegerInclusive(line1x + 1, line1x + 2);

          if (index === 0 && showDiagonalLine) {
            return { index: label, x1: line1x, y1: line1y, x2: line1x2b, y2: line1y2 };
          }
          if (!isLineAVertical) {
            return { index: label, x1: line1x, y1: line1y, x2: line1x2, y2: line1y };
          } else {
            return { index: label, x1: line1x, y1: line1y, x2: line1x, y2: line1y2 };
          }
        });

        const lineCoords = [coordA, ...perpendicularCoords, ...otherCoords];

        return {
          lineCoords,
          perpendicularCoords,
          numberOfPerpendicular,
          perpendicularQuads,
          otherQuads
        };
      },
      ({ lineCoords, perpendicularCoords, numberOfPerpendicular }) => {
        const sortedLineCoords = lineCoords.sort((a, b) => a.index.localeCompare(b.index));

        const lengthOfPerpendicularLines: number[] = [];

        // Lengths of lines so we can check at least 1 is different when there are 3 Perpendicular lines
        if (isLineAVertical) {
          lengthOfPerpendicularLines.push(...perpendicularCoords.map(el => el.x2 - el.x1));
        } else {
          lengthOfPerpendicularLines.push(...perpendicularCoords.map(el => el.y2 - el.y1));
        }

        const hasDifferentElement =
          lengthOfPerpendicularLines.length > 1 &&
          !lengthOfPerpendicularLines.every(element => element === lengthOfPerpendicularLines[0]);

        const doLineOverLap = sortedLineCoords.every((el, index, array) => {
          // Skip the last element since there's no next element to compare
          if (index === array.length - 1) return true;
          return el.x2 !== array[index + 1].x1;
        });

        // If there are 3 Perpendicular lines check at least 1 has a different length
        return numberOfPerpendicular === 3 ? hasDifferentElement && doLineOverLap : doLineOverLap;
      }
    );

    return { isPerpendicular, lineCoords, perpendicularQuads, otherQuads };
  },
  Component: props => {
    const {
      question: { isPerpendicular, lineCoords, perpendicularQuads, otherQuads },
      translate,
      displayMode
    } = props;

    const answers = isPerpendicular
      ? perpendicularQuads.map(el => el.label)
      : otherQuads.map(el => el.label);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          isPerpendicular
            ? translate.instructions.selectAllOfTheLinesThatArePerpendicularToA()
            : translate.instructions.selectAllOfTheLinesThatAreNotPerpendicularToA()
        }
        pdfTitle={
          isPerpendicular
            ? translate.instructions.circleAllOfTheLinesThatArePerpendicularToA()
            : translate.instructions.circleAllOfTheLinesThatAreNotPerpendicularToA()
        }
        testCorrect={answers}
        itemLayout={'row'}
        multiSelect
        numItems={4}
        Content={({ dimens }) => (
          <Grid
            width={dimens.width}
            height={dimens.height * 0.9}
            xAxis={null}
            yAxis={null}
            hideContinuationLines={true}
            squareGrid
            xMax={16}
            yMax={6}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                {lineCoords.map((val, i) => (
                  <G key={i}>
                    <SvgText
                      y={mathToSvgY(Math.max(val.y1, val.y2) + 0.2)}
                      x={mathToSvgX(Math.abs((val.x1 - val.x2) / 2) + Math.min(val.x1, val.x2))}
                      textAnchor="middle"
                    >
                      <TSpan fontSize="36" fill="black" fontFamily="White_Rose_Noto-Bold">
                        {translate.letters[val.index as 'A' | 'B' | 'C' | 'D' | 'E']()}
                      </TSpan>
                    </SvgText>
                    <Line
                      x1={mathToSvgX(val.x1)}
                      y1={mathToSvgY(val.y1)}
                      x2={mathToSvgX(val.x2)}
                      y2={mathToSvgY(val.y2)}
                      stroke={colors.prussianBlue}
                      strokeWidth={displayMode === 'digital' ? 4 : 6}
                    />
                  </G>
                ))}
              </GridSvgChildren>
            )}
          </Grid>
        )}
        renderItems={['B', 'C', 'D', 'E'].map(value => ({
          value,
          component: (
            <Text variant="WRN700">{translate.letters[value as 'B' | 'C' | 'D' | 'E']()}</Text>
          )
        }))}
        customMarkSchemeAnswer={{
          answerToDisplay: answers
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'avl',
  description: 'avl',
  keywords: ['Decimals', 'Division', 'Gattegno'],
  schema: z.object({
    isParallel: z.boolean(),
    isHorizontal: z.boolean(),
    lineCoords: z
      .array(
        z.object({
          index: z.enum(['A', 'B', 'C', 'D']),
          x1: z.number().int(),
          y1: z.number().int(),
          x2: z.number().int(),
          y2: z.number().int()
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const isParallel = getRandomBoolean();
    const isHorizontal = getRandomBoolean();

    const quads = shuffle([
      { min: 1, max: 4, label: 'A' as const },
      { min: 5, max: 8, label: 'B' as const },
      { min: 9, max: 12, label: 'C' as const },
      { min: 13, max: 15, label: 'D' as const }
    ]);

    // vertical line 1
    const line1x1x2 = randomIntegerInclusive(quads[0].min, quads[0].max);
    const line1y1 = randomIntegerInclusive(1, 3);
    const line1y2 = randomIntegerInclusive(line1y1 + 2, 5);

    // horizontal line 2
    const line2x1 = randomIntegerInclusive(quads[1].min, quads[1].max - 2);
    const line2y1y2 = randomIntegerInclusive(1, 5);
    const line2x2 = randomIntegerInclusive(line2x1 + 2, quads[1].max);

    // line 3
    const line3x1 = randomIntegerInclusive(
      quads[2].min,
      isHorizontal ? quads[2].max - 2 : quads[2].max
    );
    const line3y1 = randomIntegerInclusive(1, isHorizontal ? 5 : 3, {
      constraint: x => (isHorizontal && x !== line2y1y2) || !isHorizontal
    });
    const line3x2 = isHorizontal ? randomIntegerInclusive(line3x1 + 2, quads[2].max) : line3x1;
    const line3y2 = isHorizontal ? line3y1 : randomIntegerInclusive(line3y1 + 2, 5);

    // line 4
    const line4x1 = randomIntegerInclusive(quads[3].min, quads[3].max);
    const line4y1 = randomIntegerInclusive(1, 3);
    const line4x2 = randomIntegerInclusive(
      Math.max(line4x1 - 2, quads[3].min),
      Math.min(line4x1 + 2, quads[3].max),
      { constraint: x => line4x1 !== x }
    );
    const line4y2 = randomIntegerInclusive(line4y1 + 1, 5);

    const lineCoords = [
      // vertical line 1
      { index: quads[0].label, x1: line1x1x2, y1: line1y1, x2: line1x1x2, y2: line1y2 },
      // horizontal line 2
      { index: quads[1].label, x1: line2x1, y1: line2y1y2, x2: line2x2, y2: line2y1y2 },
      // line 3 - either horizontal or vertical
      { index: quads[2].label, x1: line3x1, y1: line3y1, x2: line3x2, y2: line3y2 },
      // line 4 - diagonal
      { index: quads[3].label, x1: line4x1, y1: line4y1, x2: line4x2, y2: line4y2 }
    ];

    return { isParallel, isHorizontal, lineCoords };
  },
  Component: props => {
    const {
      question: { isParallel, isHorizontal, lineCoords },
      translate,
      displayMode
    } = props;

    const answers: string[][] = [];
    if (isParallel) {
      if (isHorizontal) {
        answers.push([lineCoords[1].index, lineCoords[2].index]);
      } else {
        answers.push([lineCoords[0].index, lineCoords[2].index]);
      }
    } else {
      if (isHorizontal) {
        answers.push(
          [lineCoords[0].index, lineCoords[1].index],
          [lineCoords[0].index, lineCoords[2].index]
        );
      } else {
        answers.push(
          [lineCoords[0].index, lineCoords[1].index],
          [lineCoords[1].index, lineCoords[2].index]
        );
      }
    }
    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          isParallel
            ? translate.instructions.whichTwoLinesAreParallelSelectYourAnswer()
            : translate.instructions.whichTwoLinesArePerpendicularSelectYourAnswer()
        }
        pdfTitle={
          isParallel
            ? translate.instructions.whichTwoLinesAreParallelCircleYourAnswer()
            : translate.instructions.whichTwoLinesArePerpendicularCircleYourAnswer()
        }
        testCorrect={userAnswer =>
          answers.some(array => arraysHaveSameContentsUnordered(array, userAnswer))
        }
        itemLayout={'row'}
        multiSelect
        numItems={4}
        Content={({ dimens }) => (
          <Grid
            width={dimens.width}
            height={dimens.height * 0.9}
            xAxis={null}
            yAxis={null}
            hideContinuationLines={true}
            squareGrid
            xMax={16}
            yMax={6}
          >
            {({ mathToSvgX, mathToSvgY }) => (
              <GridSvgChildren>
                {lineCoords.map((val, i) => (
                  <G key={i}>
                    <SvgText
                      y={mathToSvgY(Math.max(val.y1, val.y2) + 0.2)}
                      x={mathToSvgX(Math.abs((val.x1 - val.x2) / 2) + Math.min(val.x1, val.x2))}
                      textAnchor="middle"
                    >
                      <TSpan fontSize="36" fill="black" fontFamily="White_Rose_Noto-Bold">
                        {translate.letters[val.index as 'A' | 'B' | 'C' | 'D']()}
                      </TSpan>
                    </SvgText>
                    <Line
                      x1={mathToSvgX(val.x1)}
                      y1={mathToSvgY(val.y1)}
                      x2={mathToSvgX(val.x2)}
                      y2={mathToSvgY(val.y2)}
                      stroke={colors.prussianBlue}
                      strokeWidth={displayMode === 'digital' ? 4 : 6}
                    />
                  </G>
                ))}
              </GridSvgChildren>
            )}
          </Grid>
        )}
        renderItems={['A', 'B', 'C', 'D'].map(value => ({
          value,
          component: (
            <Text variant="WRN700">{translate.letters[value as 'A' | 'B' | 'C' | 'D']()}</Text>
          )
        }))}
        customMarkSchemeAnswer={{
          answerToDisplay: answers[0],
          answerText: isParallel
            ? translate.markScheme.acceptAnyParallelLines()
            : translate.markScheme.acceptAnyPerpendicularLines()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ParallelAndPerpendicular',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
