import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { compareFloats } from '../../../../utils/math';
import {
  isValidIsoscelesTriangle,
  isValidScaleneTriangle,
  triangleArea
} from '../../../../utils/shapes';
import { isInRange } from '../../../../utils/matchers';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';

////
// Questions
////

const getTrianglePoints = (isVerticalRA: boolean, rotation: 0 | 90 | 180 | 270, width: number) => {
  const height = isVerticalRA ? width : width / 2;
  switch (rotation) {
    case 0: {
      const point1 = [0, 0];
      const point2 = isVerticalRA ? [0, height] : [height, height];
      const point3 = [width, 0];
      return [point1, point2, point3];
    }
    case 90: {
      const point1 = [0, 0];
      const point2 = isVerticalRA ? [0, height] : [0, width];
      const point3 = isVerticalRA ? [width, height] : [height, width / 2];
      return [point1, point2, point3];
    }
    case 180: {
      const point1 = isVerticalRA ? [0, height] : [0, height];
      const point2 = isVerticalRA ? [width, height] : [width, height];
      const point3 = isVerticalRA ? [width, 0] : [width / 2, 0];
      return [point1, point2, point3];
    }
    case 270: {
      const point1 = isVerticalRA ? [0, 0] : [0, width / 2];
      const point2 = isVerticalRA ? [width, height] : [height, width];
      const point3 = isVerticalRA ? [width, 0] : [height, 0];
      return [point1, point2, point3];
    }
  }
};

const Question1 = newQuestionContent({
  uid: 'aWE',
  description: 'aWE',
  keywords: ['Triangle', 'Count', 'Area'],
  schema: z.object({
    isVerticalRA: z.boolean(),
    rotation: numberEnum([0, 90, 180, 270]),
    width: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const isVerticalRA = getRandomBoolean();
    const width = isVerticalRA ? randomIntegerInclusive(2, 6) : randomIntegerInclusiveStep(2, 6, 2);

    const rotation = getRandomFromArray([0, 90, 180, 270] as const);

    return { width, isVerticalRA, rotation };
  },
  Component: props => {
    const {
      question: { width, isVerticalRA, rotation },
      translate
    } = props;

    const trianglePoints = getTrianglePoints(isVerticalRA, rotation, width);
    const height = isVerticalRA ? width : width / 2;
    const area = (width * height) / 2;
    const fullSquares = area - width / 2;
    const halfSquares = width;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.ansFullSquares(),
          translate.answerSentences.ansHalfSquares(),
          translate.answerSentences.areaAnsCm2()
        ]}
        extraSymbol="decimalPoint"
        title={translate.instructions.countTheFullSquaresAndHalfSquaresToFindTheArea()}
        mainPanelStyle={{ flexDirection: 'row' }}
        testCorrect={userAnswer =>
          userAnswer[0][0] === fullSquares.toString() &&
          userAnswer[1][0] === halfSquares.toString() &&
          compareFloats(userAnswer[2][0], area.toString())
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [fullSquares.toLocaleString()],
            [halfSquares.toLocaleString()],
            [area.toLocaleString()]
          ]
        }}
        inputMaxCharacters={4}
        style={{ alignItems: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
              points={trianglePoints.map(val => val as [number, number])}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aWF',
  description: 'aWF',
  keywords: ['Triangle', 'Count', 'Area'],
  schema: z.object({
    isVerticalRA: z.boolean(),
    rotation: numberEnum([0, 90, 180, 270]),
    width: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const isVerticalRA = getRandomBoolean();
    const width = isVerticalRA ? randomIntegerInclusive(2, 5) : randomIntegerInclusiveStep(2, 8, 2);

    const array = width <= 6 ? ([0, 90, 180, 270] as const) : ([0, 180] as const);
    const rotation = getRandomFromArray(array);

    return { width, isVerticalRA, rotation };
  },
  Component: props => {
    const {
      question: { width, isVerticalRA, rotation },
      translate
    } = props;

    const trianglePoints = getTrianglePoints(isVerticalRA, rotation, width);
    const height = isVerticalRA ? width : width / 2;
    const area = (width * height) / 2;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCmSquared()}
        title={translate.instructions.countSquaresToWorkOutAreaOfTriangle()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], area.toString())}
        customMarkSchemeAnswer={{
          answersToDisplay: [area.toLocaleString()]
        }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={trianglePoints.map(val => val as [number, number])}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aWG',
  description: 'aWG',
  keywords: ['Triangle', 'Estimate', 'Area'],
  schema: z.object({
    height: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(8),
    point2X: z.number().int().min(1).max(7),
    isFlatTop: z.boolean()
  }),
  simpleGenerator: () => {
    const isFlatTop = getRandomBoolean();
    const width = randomIntegerInclusive(2, 8);
    // need to make sure its not a right-angled triangle
    const height = randomIntegerInclusive(2, 5, {
      constraint: x => x / 2 !== width && x * 2 !== width
    });
    const point2X = randomIntegerInclusive(1, width - 1);

    return { isFlatTop, width, height, point2X };
  },
  Component: props => {
    const {
      question: { isFlatTop, width, height, point2X },
      translate
    } = props;

    const point1 = [0, isFlatTop ? height : 0];
    const point2 = [point2X, isFlatTop ? 0 : height];
    const point3 = [width, isFlatTop ? height : 0];

    const coordinates = [point1, point2, point3];

    const area = (height * width) / 2;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCmSquared()}
        title={translate.instructions.countSquaresToEstimateAreaTriangle()}
        testCorrect={userAnswer => isInRange(area - 1, area + 1)(parseFloat(userAnswer[0]))}
        customMarkSchemeAnswer={{
          answersToDisplay: [area.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        extraSymbol="decimalPoint"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={2}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={coordinates.map(val => val as [number, number])}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aWH',
  description: 'aWH',
  keywords: ['Triangle', 'Estimate', 'Area'],
  schema: z.object({
    height: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(8),
    point2X: z.number().int().min(1).max(7),
    isFlatTop: z.boolean(),
    incorrectOptions: numberEnum([1, 2, 3])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const isFlatTop = getRandomBoolean();
    const width = randomIntegerInclusive(2, 8);
    // need to make sure its not a right-angled triangle
    const height = randomIntegerInclusive(2, 5, {
      constraint: x => x / 2 !== width && x * 2 !== width
    });
    const point2X = randomIntegerInclusive(1, width - 1);
    const incorrectOptions =
      height * width <= 6
        ? getRandomFromArray([1, 2] as const)
        : getRandomFromArray([1, 2, 3] as const);

    return { isFlatTop, width, height, point2X, incorrectOptions };
  },
  Component: props => {
    const {
      question: { isFlatTop, width, height, point2X, incorrectOptions },
      translate
    } = props;

    const point1 = [0, isFlatTop ? height : 0];
    const point2 = [point2X, isFlatTop ? 0 : height];
    const point3 = [width, isFlatTop ? height : 0];

    const coordinates = [point1, point2, point3];

    const area = (height * width) / 2;

    const incorrect = (() => {
      switch (incorrectOptions) {
        case 1:
          return [area + 1, area + 2, area + 3];
        case 2:
          return [area + 1, area - 1, area + 2];
        case 3:
          return [area - 1, area - 2, area - 3];
      }
    })();

    const selectables = [area, ...incorrect];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectAreaOfShape(translate.shapes.triangles(1))}
        correctAnswer={[area.toLocaleString()]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        questionHeight={900}
        leftContent={
          <MeasureView>
            {dimens => (
              <DisplayShapeOnGridWithBorder
                dimens={dimens}
                points={coordinates.map(val => val as [number, number])}
                cellSizeLabel={translate.units.numberOfCm(1)}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aWH2',
  description: 'aWH',
  keywords: ['Triangle', 'Estimate', 'Area'],
  schema: z.object({
    height: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(8),
    point2X: z.number().int().min(1).max(7),
    isFlatTop: z.boolean(),
    selectables: z.array(z.number())
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const isFlatTop = getRandomBoolean();
    const width = randomIntegerInclusive(2, 8);
    // need to make sure its not a right-angled triangle
    const height = randomIntegerInclusive(2, 5, {
      constraint: x => x / 2 !== width && x * 2 !== width
    });
    const point2X = randomIntegerInclusive(1, width - 1);
    const incorrectOptions =
      height * width <= 6
        ? getRandomFromArray([1, 2] as const)
        : getRandomFromArray([1, 2, 3] as const);

    const area = (height * width) / 2;
    const incorrect = (() => {
      switch (incorrectOptions) {
        case 1:
          return [area + 1, area + 2, area + 3];
        case 2:
          return [area + 1, area - 1, area + 2];
        case 3:
          return [area - 1, area - 2, area - 3];
      }
    })();

    const selectables = shuffle([area, ...incorrect]);

    return { isFlatTop, width, height, point2X, selectables };
  },
  Component: props => {
    const {
      question: { isFlatTop, width, height, point2X, selectables },
      translate
    } = props;

    const point1 = [0, isFlatTop ? height : 0];
    const point2 = [point2X, isFlatTop ? 0 : height];
    const point3 = [width, isFlatTop ? height : 0];

    const coordinates = [point1, point2, point3];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.countSquaresToEstimateTheAreaOfTheXShapeSelectYourAnswer(
          translate.shapes.triangles(1)
        )}
        pdfTitle={translate.instructions.countSquaresToEstimateTheAreaOfTheXShapeCircleYourAnswer(
          translate.shapes.triangles(1)
        )}
        correctAnswer={[`${((height * width) / 2).toLocaleString()} ${translate.units.cm()}`]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [
            `${selectable.toLocaleString()} ${translate.units.cm()}`,
            `${selectable.toLocaleString()} ${translate.units.cm()}`
          ])
        )}
        questionHeight={900}
        leftContent={
          <MeasureView>
            {dimens => (
              <DisplayShapeOnGridWithBorder
                dimens={dimens}
                points={coordinates.map(val => val as [number, number])}
                cellSizeLabel={translate.units.numberOfCm(1)}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aWI',
  description: 'aWI',
  keywords: ['Triangle', 'Area', 'Estimate'],
  schema: z.object({
    width: z.number().int().min(3).max(8),
    height: z.number().int().min(3).max(5),
    xOffset: z.number().int().min(0).max(7),
    yOffset: z.number().int().min(0).max(2),
    direction: z.enum(['horizontal', 'vertical']),
    position: z.enum(['top', 'bottom', 'left', 'right'])
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(3, 8);
    const height = randomIntegerInclusive(3, 5);

    const xOffset = randomIntegerInclusive(0, 10 - width);
    const yOffset = randomIntegerInclusive(0, 5 - height);

    const direction = getRandomFromArray(['horizontal', 'vertical'] as const);
    const position =
      direction === 'vertical'
        ? getRandomFromArray(['left', 'right'] as const)
        : getRandomFromArray(['top', 'bottom'] as const);

    return { width, height, xOffset, yOffset, direction, position };
  },
  Component: ({ question, translate }) => {
    const { width, height, xOffset, yOffset, direction, position } = question;

    const area = 0.5 * height * width;

    const x = direction === 'vertical' ? (position === 'left' ? 0 : 10) : xOffset;
    const y = direction === 'horizontal' ? (position === 'bottom' ? 0 : 5) : yOffset;

    const coords =
      direction === 'vertical'
        ? [
            { x: x, y: y },
            { x: x, y: y + height }
          ]
        : [
            { x: x, y: y },
            { x: x + width, y: y }
          ];

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.instructions.tapToPlotVertexOfTriangleThatHasAreaX(
          translate.units.numberOfCm2(area)
        )}
        pdfTitle={translate.instructions.plotVertexOfTriangleThatHasAreaX(
          translate.units.numberOfCm2(area)
        )}
        fixedPoints={coords}
        gridChildrenPoints={coords}
        numPoints={1}
        gridVariant="grid"
        joinToGridChild
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer => {
          if (direction === 'vertical') {
            const userWidth = Math.abs(userAnswer[0].x - x);
            const userArea = 0.5 * userWidth * height;
            return userArea === area;
          } else {
            const userHeight = Math.abs(userAnswer[0].y - y);
            const userArea = 0.5 * userHeight * width;
            return userArea === area;
          }
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyTriangleWithAreaofX(translate.units.numberOfCm2(area))
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aWJ',
  description: 'aWJ',
  keywords: ['Triangle', 'Estimate', 'Area'],
  schema: z
    .object({
      coordinates: z.array(z.number().int().min(0).max(6).array().length(2)).length(3)
    })
    .refine(
      val =>
        isValidScaleneTriangle(
          val.coordinates[0] as [number, number],
          val.coordinates[1] as [number, number],
          val.coordinates[2] as [number, number]
        ) ||
        isValidIsoscelesTriangle(
          val.coordinates[0] as [number, number],
          val.coordinates[1] as [number, number],
          val.coordinates[2] as [number, number]
        ),
      'points must make a scalene or isosceles triangle'
    )
    .refine(
      val =>
        arrayHasNoDuplicates(val.coordinates.map(val => val[0])) &&
        arrayHasNoDuplicates(val.coordinates.map(val => val[1])),
      'no horizontal or vertical lines'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const xCoords = randomUniqueIntegersInclusive(0, 6, 3);
        const yCoords = randomUniqueIntegersInclusive(0, 5, 3);
        const coordinates = countRange(3).map(i => [xCoords[i], yCoords[i]]);

        return { coordinates };
      },
      ({ coordinates }) => {
        return (
          (isValidScaleneTriangle(
            coordinates[0] as [number, number],
            coordinates[1] as [number, number],
            coordinates[2] as [number, number]
          ) ||
            isValidIsoscelesTriangle(
              coordinates[0] as [number, number],
              coordinates[1] as [number, number],
              coordinates[2] as [number, number]
            )) &&
          triangleArea(
            coordinates[0] as [number, number],
            coordinates[1] as [number, number],
            coordinates[2] as [number, number]
          ) >= 5
        );
      }
    ),
  Component: props => {
    const {
      question: { coordinates },
      translate
    } = props;

    const area = Math.round(
      triangleArea(
        coordinates[0] as [number, number],
        coordinates[1] as [number, number],
        coordinates[2] as [number, number]
      )
    );

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCmSquared()}
        title={translate.instructions.countSquaresToEstimateAreaTriangle()}
        testCorrect={userAnswer => isInRange(area - 1, area + 1)(parseFloat(userAnswer[0]))}
        customMarkSchemeAnswer={{
          answersToDisplay: [area.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
        extraSymbol="decimalPoint"
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={2}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={{
                width: dimens.width * 1.2,
                height: dimens.height * 1.2
              }}
              points={coordinates.map(val => val as [number, number])}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AreaOfATriangleCountingSquares',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
