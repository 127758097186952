import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { randomIntegerInclusive, seededRandom, shuffle } from '../../../../utils/random';
import { countRange } from '../../../../utils/collections';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMo',
  description: 'aMo',
  keywords: [
    'Number line',
    'Fraction',
    'Mixed number',
    'Counting forwards',
    'Counting backwards',
    'Numerators',
    'Denominators'
  ],
  schema: z.object({
    denominator: z.number().int().min(3).max(5),
    whole: z.number().int().min(1).max(8),
    answerIndex: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 5);
    const whole = randomIntegerInclusive(1, 8);
    const answerIndex = randomIntegerInclusive(1, denominator - 1);

    return { denominator, whole, answerIndex };
  },
  Component: props => {
    const {
      question: { denominator, whole, answerIndex },
      translate
    } = props;
    const startingNumber = whole;
    const endNumber = whole + 1;

    // Create array to pass to Number Line
    const tickValues = countRange(denominator + 1).map(i =>
      i === 0
        ? startingNumber.toLocaleString()
        : i === denominator
        ? endNumber.toLocaleString()
        : `<frac w='${whole.toLocaleString()}' n='${i.toLocaleString()}' d='${denominator.toLocaleString()}' />`
    );

    // Set where the answers should go
    tickValues[answerIndex] = `<frac wAns='' nAns='' dAns='' />`;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={userAnswer => compareFractions(userAnswer, [whole, answerIndex, denominator])}
        tickValues={tickValues}
        tickOffset={8}
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole.toLocaleString(),
            answerIndex.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aMp',
  description: 'aMp',
  keywords: [
    'Number line',
    'Fraction',
    'Mixed number',
    'Counting forwards',
    'Counting backwards',
    'Numerators',
    'Denominators'
  ],
  schema: z.object({
    denominator: z.number().int().min(3).max(4),
    whole: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 4);
    const whole = randomIntegerInclusive(1, 8);

    return { denominator, whole };
  },
  Component: props => {
    const {
      question: { denominator, whole },
      translate
    } = props;
    const startingNumber = whole;
    const endNumber = whole + 1;

    // Create array to pass to Number Line
    const tickValues = countRange(denominator + 1).map(i =>
      i === 0
        ? startingNumber.toLocaleString()
        : i === denominator
        ? endNumber.toLocaleString()
        : `<frac wAns='' nAns='' dAns='' />`
    );

    const answers: number[] = [];
    countRange(denominator - 1, 1).map(i => {
      answers.push(whole);
      answers.push(i);
      answers.push(denominator);
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer.slice(0, 3), answers.slice(0, 3)) &&
          compareFractions(userAnswer.slice(3, 6), answers.slice(3, 6)) &&
          compareFractions(userAnswer.slice(6, 9), answers.slice(6, 9))
        }
        tickValues={tickValues}
        tickOffset={8}
        inputMaxCharacters={1}
        answerPositions="bottom"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: answers.map(num => num.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aMq',
  description: 'aMq',
  keywords: [
    'Number line',
    'Fraction',
    'Mixed number',
    'Counting forwards',
    'Counting backwards',
    'Numerators',
    'Denominators'
  ],
  schema: z.object({
    denominator: z.number().int().min(2).max(6),
    whole: z.number().int().min(1).max(8),
    answerIndex: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 6);
    const whole = randomIntegerInclusive(1, 8);
    const answerIndex = randomIntegerInclusive(1, denominator - 1);

    return { denominator, whole, answerIndex };
  },
  Component: props => {
    const {
      question: { denominator, whole, answerIndex },
      translate
    } = props;
    const startingNumber = whole;
    const endNumber = whole + 1;

    // Create array to pass to Number Line
    const tickValues = countRange(denominator + 1).map(i =>
      i === 0
        ? startingNumber.toLocaleString()
        : i === denominator
        ? endNumber.toLocaleString()
        : ''
    );

    // Set where the answers should go
    tickValues[answerIndex] = `<frac wAns='' nAns='' dAns='' />`;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={userAnswer => compareFractions(userAnswer, [whole, answerIndex, denominator])}
        tickValues={tickValues}
        tickOffset={8}
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole.toLocaleString(),
            answerIndex.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aMr',
  description: 'aMr',
  keywords: [
    'Number line',
    'Fraction',
    'Mixed number',
    'Counting forwards',
    'Counting backwards',
    'Numerators',
    'Denominators'
  ],
  schema: z.object({
    denominator: z.number().int().min(3).max(5),
    whole: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 5);
    const whole = randomIntegerInclusive(1, 8);

    return { denominator, whole };
  },
  Component: props => {
    const {
      question: { denominator, whole },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      countRange(denominator - 1, 1).map(i => ({
        component: (
          <TextStructure
            key={i}
            sentence={`<frac w='${whole.toLocaleString()}' n='${i.toLocaleString()}' d='${denominator.toLocaleString()}' />`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 30 : 50,
              fontWeight: '700'
            }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: `${i}`
      })),
      {
        random: seededRandom(props.question)
      }
    );

    // Create array to pass to Number Line
    const bottomTickValues: string[] = countRange(denominator + 1).map(i =>
      i === 0 ? whole.toLocaleString() : i === denominator ? (whole + 1).toLocaleString() : ''
    );
    const topTickValues = countRange(denominator + 1).map(i =>
      i === 0 || i === denominator ? '' : `<ans/>`
    );

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={countRange(denominator - 1, 1).map(i => i.toString())}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aMs',
  description: 'aMs',
  keywords: [
    'Number line',
    'Fraction',
    'Mixed number',
    'Counting forwards',
    'Counting backwards',
    'Numerators',
    'Denominators',
    'Midpoint'
  ],
  schema: z.object({
    denominator: z.number().int().min(3).max(10),
    whole: z.number().int().min(1).max(8),
    answerNumerator: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);
    const whole = randomIntegerInclusive(1, 8);
    const answerNumerator = randomIntegerInclusive(1, denominator - 1, {
      constraint: x => x !== denominator / 2
    });

    return { denominator, whole, answerNumerator };
  },
  Component: props => {
    const {
      question: { denominator, whole, answerNumerator },
      translate
    } = props;

    const tickValues = countRange(denominator + 1).map(i =>
      i === 0 ? whole.toLocaleString() : i === denominator ? (whole + 1).toLocaleString() : ''
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardToCompleteSentence()}
        pdfTitle={translate.instructions.useCardCompleteSentence()}
        sentence={translate.answerSentences.xIsToTheAnsOfMidpoint(
          `<frac w='${whole.toLocaleString()}' n='${answerNumerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`
        )}
        testCorrect={[
          answerNumerator > denominator / 2
            ? translate.directions.right()
            : translate.directions.left()
        ]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={{ height: dimens.height, width: dimens.width }}
          />
        )}
        itemVariant="square"
        actionPanelVariant="end"
        items={[translate.directions.left(), translate.directions.right()]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aMt',
  description: 'aMt',
  keywords: [
    'Number line',
    'Fraction',
    'Mixed number',
    'Numerators',
    'Denominators',
    'Whole number',
    'Count in fractions',
    'Estimate'
  ],
  schema: z.object({
    intervals: z.number().int().min(1).max(8),
    whole: z.number().int().min(1).max(8),
    answerNumerator: z.number().int().min(1).max(15)
  }),
  simpleGenerator: () => {
    const intervals = randomIntegerInclusive(1, 8);
    const whole = randomIntegerInclusive(1, 8);
    const answerNumerator = randomIntegerInclusive(1, intervals * 2 - 1);

    return { intervals, whole, answerNumerator };
  },
  Component: props => {
    const {
      question: { intervals, whole, answerNumerator },
      translate
    } = props;
    const startingNumber = whole;
    const endNumber = whole + 1;

    // Create array of empty strings
    const emptyArray = countRange(intervals + 1);
    const tickArray = emptyArray.map(i =>
      i === 0 ? startingNumber.toLocaleString() : i === intervals ? endNumber.toLocaleString() : ''
    );

    const decimal = fractionToDecimal(intervals * 2 * whole + answerNumerator, intervals * 2);

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNumOnNumberLine(
          `<frac w='${whole.toLocaleString()}' n='${answerNumerator.toLocaleString()}' d='${(
            intervals * 2
          ).toLocaleString()}' />`
        )}
        pdfTitle={translate.instructions.drawAnArrowToShowPositionOfNumOnNumberLine(
          `<frac w='${whole.toLocaleString()}' n='${answerNumerator.toLocaleString()}' d='${(
            intervals * 2
          ).toLocaleString()}' />`
        )}
        testCorrect={[decimal - 0.1, decimal + 0.1]}
        min={startingNumber}
        max={endNumber}
        sliderStep={0.01}
        tickValues={tickArray}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumberLinesWithMixedNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
