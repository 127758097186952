import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { randomIntegerInclusive } from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ADD } from '../../../../constants';
import { compareFractions } from '../../../../utils/fractions';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { filledArray, range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { colors } from '../../../../theme/colors';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { calculateRadius } from '../../../../components/question/representations/MultiPieChart';
import { View } from 'react-native';
import PieChart from '../../../../components/question/representations/PieChart';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'asK',
  description: 'asK',
  keywords: ['Fractions'],
  schema: z
    .object({
      totalSlices: z.number().int().min(3).max(10),
      slicesColored: z.number().int().min(1).max(9)
    })
    .refine(
      val => val.slicesColored < val.totalSlices,
      'slicesColored must be less than totalSlices.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const totalSlices = randomIntegerInclusive(3, 10);

    const slicesColored = randomIntegerInclusive(1, totalSlices - 1);

    return { totalSlices, slicesColored };
  },
  Component: props => {
    const {
      question: { totalSlices, slicesColored },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        style={{ justifyContent: 'space-evenly' }}
        title={translate.instructions.completeSentences()}
        testCorrect={answer =>
          compareFractions(answer[0], [slicesColored, totalSlices]) &&
          compareFractions(answer[1], [totalSlices - slicesColored, totalSlices])
        }
        sentences={[
          translate.answerSentences.xIsShaded(`<frac nAns='' dAns=''/>`),
          translate.answerSentences.xIsNotShaded(`<frac nAns='' dAns=''/>`)
        ]}
        sentenceStyle={{ justifyContent: 'center' }}
        Content={({ dimens }) => {
          const pieChartRadius = calculateRadius(dimens, 1, 1);

          const missingSlices = range(slicesColored, totalSlices - 1);

          return (
            <View
              style={[
                dimens,
                {
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap'
                }
              ]}
            >
              <PieChart
                pieOptions={filledArray({ ratioOfSlices: 1 }, totalSlices)}
                missingSlices={missingSlices}
                radius={pieChartRadius}
              />
            </View>
          );
        }}
        inputMaxCharacters={2}
        questionHeight={1000}
        pdfSentenceStyle={{ alignItems: 'flex-start' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [slicesColored.toLocaleString(), totalSlices.toLocaleString()],
            [(totalSlices - slicesColored).toLocaleString(), totalSlices.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'asL',
  description: 'asL',
  keywords: ['Fractions', 'Bar model', 'Add'],
  schema: z.object({
    numerator: z.number().int().min(1).max(3),
    denominator: z.number().int().min(4).max(8)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(1, 3);

    const denominator = randomIntegerInclusive(4, 8);

    return { numerator, denominator };
  },
  Component: props => {
    const {
      question: { numerator, denominator },
      translate
    } = props;

    const coloredCells = filledArray(colors.pacificBlue600, numerator);

    return (
      <QF1ContentAndSentence
        sentence={`<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${ADD} <frac nAns='' dAns=''/> = <frac n='${denominator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`}
        title={translate.instructions.completeNumberSentenceToMatchBarModel()}
        testCorrect={answer => compareFractions(answer, [denominator - numerator, denominator])}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            totalPerSection={1}
            height={100}
            width={dimens.width}
            customColorMap={coloredCells}
            color={'white'}
          />
        )}
        inputMaxCharacters={1}
        pdfDirection="column"
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (denominator - numerator).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'asM',
  description: 'asM',
  keywords: ['Fractions', 'Bar model', 'Add'],
  schema: z
    .object({
      numeratorA: z.number().int().min(1).max(6),
      numeratorB: z.number().int().min(1).max(6),
      denominator: z.number().int().min(5).max(8)
    })
    .refine(
      val => val.numeratorA + val.numeratorB < val.denominator,
      'numeratorA + numeratorB must be less than denominator'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 8);

    const numeratorA = randomIntegerInclusive(1, denominator - 2);

    const numeratorB = randomIntegerInclusive(1, denominator - numeratorA - 1);

    return { numeratorA, numeratorB, denominator };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, denominator },
      translate
    } = props;

    const blank = denominator - numeratorA - numeratorB;

    const coloredCellsA = filledArray(colors.pacificBlue600, numeratorA);
    const coloredCellsB = filledArray(colors.acidGreen, numeratorB);

    const coloredCells = [...coloredCellsA, ...coloredCellsB];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${ADD} <frac nAns='' dAns=''/> ${ADD} <frac nAns='' dAns=''/> = 1`}
        title={translate.instructions.completeNumberSentenceToMatchBarModel()}
        testCorrect={answer =>
          // Fractions could be in either order.
          (compareFractions([answer[0], answer[1]], [numeratorB, denominator]) &&
            compareFractions([answer[2], answer[3]], [blank, denominator])) ||
          (compareFractions([answer[0], answer[1]], [blank, denominator]) &&
            compareFractions([answer[2], answer[3]], [numeratorB, denominator]))
        }
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            totalPerSection={1}
            height={100}
            width={dimens.width}
            customColorMap={coloredCells}
            color={'white'}
          />
        )}
        textStyle={{ fontSize: 40 }}
        inputMaxCharacters={1}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            numeratorB.toLocaleString(),
            denominator.toLocaleString(),
            blank.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asN',
  description: 'asN',
  keywords: ['Fractions', 'Add'],
  schema: z
    .object({
      numerator: z.number().int().min(1).max(10),
      denominator: z.number().int().min(5).max(20)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 20);

    const numerator = randomIntegerInclusive(1, Math.min(denominator - 1, 10));

    return { denominator, numerator };
  },
  Component: props => {
    const {
      question: { denominator, numerator },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={answer => compareFractions(answer, [denominator - numerator, denominator])}
        sentence={`<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${ADD} <frac nAns='' dAns=''/> = 1`}
        textStyle={{ fontSize: 40 }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (denominator - numerator).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'asO',
  description: 'asO',
  keywords: ['Fractions', 'Subtract'],
  schema: z
    .object({
      nameA: nameSchema,
      nameB: nameSchema,
      numerator: z.number().int().min(1).max(11),
      denominator: z.number().int().min(4).max(12)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.')
    .refine(val => val.nameA !== val.nameB, 'Chosen names must be different.'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [nameA, nameB] = getRandomUniqueNames(2);

    const denominator = randomIntegerInclusive(4, 12);

    const numerator = randomIntegerInclusive(1, denominator - 1);

    return { nameA, nameB, numerator, denominator };
  },
  Component: props => {
    const {
      question: { nameA, nameB, numerator, denominator },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.char1AndChar2ShareABoxOfStickers(
          nameA,
          nameB,
          `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`
        )}
        testCorrect={answer => compareFractions(answer, [denominator - numerator, denominator])}
        sentence={`<frac nAns='' dAns=''/>`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (denominator - numerator).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'asP',
  description: 'asP',
  keywords: ['Fractions', 'Subtract'],
  schema: z
    .object({
      nameA: nameSchema,
      nameB: nameSchema,
      denominator: z.number().int().min(7).max(12),
      numerator: z.number().int().min(3).max(9)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator')
    .refine(val => val.nameA !== val.nameB, 'Chosen names must be different.'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const [nameA, nameB] = getRandomUniqueNames(2);

    const denominator = randomIntegerInclusive(7, 12);

    const numerator = randomIntegerInclusive(3, Math.min(denominator - 1, 9));

    return { nameA, nameB, denominator, numerator };
  },
  Component: props => {
    const {
      question: { nameA, nameB, denominator, numerator },
      translate,
      displayMode
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.characterHasAPacketOfNumCards(
          nameA,
          denominator,
          numerator,
          nameB
        )}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], [numerator, denominator]) &&
          compareFractions(userAnswer[1], [denominator - numerator, denominator])
        }
        inputMaxCharacters={2}
        sentences={[
          translate.answerSentences.whatFractionOfCardsIsGiven(nameA, nameB) +
            `  <frac nAns='' dAns=''/>`,
          translate.answerSentences.whatFractionOfTheCardsDoesCharacterHaveLeft(nameA) +
            `  <frac nAns='' dAns=''/>`
        ]}
        titleTextStyle={{ fontSize: displayMode === 'digital' ? 24 : 50 }}
        textStyle={{ fontSize: displayMode === 'digital' ? 24 : 50 }}
        containerStyle={{ rowGap: 12 }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start', paddingLeft: 32 }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start', paddingLeft: 32 }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numerator.toLocaleString(), denominator.toLocaleString()],
            [(denominator - numerator).toLocaleString(), denominator.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'asP2',
  description: 'asP',
  keywords: ['Fractions', 'Subtract'],
  schema: z
    .object({
      nameA: nameSchema,
      nameB: nameSchema,
      denominator: z.number().int().min(7).max(12),
      numerator: z.number().int().min(3).max(9)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator')
    .refine(val => val.nameA !== val.nameB, 'Chosen names must be different.'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [nameA, nameB] = getRandomUniqueNames(2);

    const denominator = randomIntegerInclusive(7, 12);

    const numerator = randomIntegerInclusive(3, Math.min(denominator - 1, 9));

    return { nameA, nameB, denominator, numerator };
  },
  Component: props => {
    const {
      question: { nameA, nameB, denominator, numerator },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.characterHasAPacketOfNumCards(
          nameA,
          denominator,
          numerator,
          nameB
        )}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], [numerator, denominator]) &&
          compareFractions(userAnswer[1], [denominator - numerator, denominator])
        }
        inputMaxCharacters={2}
        sentences={["<frac nAns='' dAns='' />", "<frac nAns='' dAns='' />"]}
        Content={({ dimens }) => (
          <View
            style={{
              alignSelf: 'flex-start',
              height: dimens.height,
              justifyContent: 'space-around'
            }}
          >
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 24 : 50,
                top: displayMode === 'digital' ? 12 : displayMode === 'pdf' ? 45 : -38
              }}
            >
              {translate.answerSentences.whatFractionOfCardsIsGiven(nameA, nameB)}
            </Text>
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 24 : 50,
                top: displayMode === 'digital' ? 18 : displayMode === 'pdf' ? 14 : -12
              }}
            >
              {translate.answerSentences.whatFractionOfTheCardsDoesCharacterHaveLeft(nameA)}
            </Text>
          </View>
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        titleTextStyle={{ fontSize: displayMode === 'digital' ? 24 : 50 }}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numerator.toLocaleString(), denominator.toLocaleString()],
            [(denominator - numerator).toLocaleString(), denominator.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionTheWhole',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
