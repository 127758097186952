import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../utils/react';
import TitleRow, { TitleStyleProps } from './TitleRow';
import { ActionPanelVariant } from './BaseLayout';
import { useContext } from 'react';
import { colors } from '../../theme/colors';
import { QuestionNavigationContext } from '../../SchemeOfLearning/Question';

type Props = TitleStyleProps & {
  /** Usually the question intruction, e.g. "Complete the sentences." */
  title?: string | JSX.Element;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * The attached action panel's variant, used to style accordingly.
   */
  actionPanelVariant: ActionPanelVariant;
  /**
   * Boolean to determine whether the content is shown in PDF or not, used to style accordingly.
   */
  displayMode?: 'digital' | 'pdf' | 'markscheme';
  children: ElementOrRenderFunction;
};

/**
 * Main zone where the question is asked.
 * Called "Question container" in figma.
 */
export default function MainPanel({
  title = '',
  children,
  style,
  containerStyle,
  titleStyle,
  titleTextStyle,
  titleFractionContainerStyle,
  titleFractionTextStyle,
  titleFractionDividerStyle,
  actionPanelVariant,
  displayMode = 'digital'
}: Props) {
  const { QuizInformation } = useContext(QuestionNavigationContext);

  const actionPanelIsEndVariant =
    actionPanelVariant === 'end' ||
    actionPanelVariant === 'endWide' ||
    actionPanelVariant === 'endMid';

  if (Platform.OS === 'ios') {
    // Workaround to iOS border issue (https://github.com/facebook/react-native/issues/49442) involves wrapping the
    // container view in another view.
    // When this issue is fixed, you can delete this iOS case, and the relevant style changes
    return (
      <View
        style={[
          styles.iOSBorderWorkaroundView,
          actionPanelIsEndVariant
            ? styles.iOSBorderWorkaroundViewWhenActionPanelIsEnd
            : styles.iOSBorderWorkaroundViewWhenActionPanelIsBottom,
          style
        ]}
      >
        <View
          style={[
            styles.container,
            displayMode === 'digital'
              ? actionPanelIsEndVariant
                ? styles.containerWhenActionPanelIsEnd
                : styles.containerWhenActionPanelIsBottom
              : styles.containerPdf
          ]}
        >
          {QuizInformation}
          <TitleRow
            title={title}
            titleStyle={titleStyle}
            titleTextStyle={titleTextStyle}
            titleFractionContainerStyle={titleFractionContainerStyle}
            titleFractionTextStyle={titleFractionTextStyle}
            titleFractionDividerStyle={titleFractionDividerStyle}
          />
          <View style={[styles.contentContainer, containerStyle]}>
            {resolveElementOrRenderFunction(children)}
          </View>
        </View>
      </View>
    );
  }

  return (
    <View
      style={[
        styles.container,
        displayMode === 'digital'
          ? actionPanelIsEndVariant
            ? styles.containerWhenActionPanelIsEnd
            : styles.containerWhenActionPanelIsBottom
          : styles.containerPdf,
        style
      ]}
    >
      {QuizInformation}
      <TitleRow
        title={title}
        titleStyle={titleStyle}
        titleTextStyle={titleTextStyle}
        titleFractionContainerStyle={titleFractionContainerStyle}
        titleFractionTextStyle={titleFractionTextStyle}
        titleFractionDividerStyle={titleFractionDividerStyle}
      />
      <View style={[styles.contentContainer, containerStyle]}>
        {resolveElementOrRenderFunction(children)}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderWidth: 3,
    borderColor: colors.prussianBlue,
    // Delete me when iOS border workaround is removed
    ...(Platform.OS === 'ios' && {
      width: '100%',
      height: '100%'
    })
  },
  containerWhenActionPanelIsEnd: {
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    // Delete me when iOS border workaround is removed
    ...(Platform.OS === 'ios' && {
      borderRightWidth: 0
    })
  },
  containerWhenActionPanelIsBottom: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    // Delete me when iOS border workaround is removed
    ...(Platform.OS === 'ios' && {
      borderBottomWidth: 0
    })
  },
  containerPdf: {
    borderWidth: 0,
    // If in PDF mode, the right border radiuses are needed; otherwise, the Action Panel will be touching this.
    // CCR: I didn't fully understand this, but I don't want to make changes here when refactoring.
    borderBottomRightRadius: 24,
    borderTopRightRadius: 24
  },

  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: 24,
    paddingHorizontal: 32,
    paddingBottom: 32
  },

  // Workaround to iOS border issue (https://github.com/facebook/react-native/issues/49442) involves wrapping the
  // container view in another view.
  // When this issue is fixed, you can delete that wrapping view and delete these styles.
  iOSBorderWorkaroundView: {
    borderColor: colors.prussianBlue
  },
  iOSBorderWorkaroundViewWhenActionPanelIsEnd: {
    borderEndWidth: 3
  },
  iOSBorderWorkaroundViewWhenActionPanelIsBottom: {
    borderBottomWidth: 3
  }
});
