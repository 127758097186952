import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import { countRange, filledArray, sortNumberArray } from '../../../../utils/collections';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import ItemsAgainstRuler, {
  calculateScaling
} from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { TranslationFunctions } from '../../../../i18n/i18n-types';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const objects = [
  'flower',
  'glass',
  'giraffe',
  'elephant',
  'teddyBear',
  'ribbons',
  'rubber',
  'sharpener',
  'pencil'
] as const;
type Objects = (typeof objects)[number];

const Question1 = newQuestionContent({
  uid: 'bjy',
  description: 'bjy',
  keywords: ['Taller', 'Shorter', 'Longer', 'Height', 'Length', 'Compare', 'Centimetres', 'Ruler'],
  questionHeight: 1000,
  schema: z.object({
    lengths: z.array(z.number().int().min(1).max(15)).length(2),
    isVertical: z.boolean(),
    objects: z.array(z.enum(objects)).length(2),
    selectShorter: z.boolean()
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const selectShorter = getRandomBoolean();
    const verticalObjects = ['flower', 'glass', 'giraffe', 'elephant', 'teddyBear'] as const;
    const horizontalObjects = ['ribbons', 'rubber', 'sharpener', 'pencil'] as const;

    const { objects, lengths } = rejectionSample(
      () => {
        const objects = getRandomSubArrayFromArray(
          isVertical ? verticalObjects : horizontalObjects,
          2
        );

        // Some svgs can't be seen well when they are too small/long
        const getMinMax = (obj: Objects): [number, number] =>
          ['sharpener', 'rubber'].includes(obj)
            ? [2, 5]
            : ['ribbons', 'pencil'].includes(obj)
            ? [4, 15]
            : [1, 15];

        const length1 = randomIntegerInclusive(...getMinMax(objects[0]));
        const length2 = randomIntegerInclusive(...getMinMax(objects[1]), {
          constraint: x => length1 !== x
        });
        return { objects, lengths: [length1, length2] };
      },
      ({ lengths }) => Math.abs(lengths[0] - lengths[1]) < 5 // If the length difference it too large the smaller ones cant be seen
    );

    return { objects, lengths, isVertical, selectShorter };
  },
  Component: props => {
    const {
      question: { objects, lengths, isVertical, selectShorter },
      translate
    } = props;
    const random = seededRandom(props.question);
    const getSvgName = (object: Objects) => {
      switch (object) {
        case 'flower':
          return getRandomFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            { random }
          );
        case 'glass':
          return 'Glasses_of_orange_juice/glass1_oj_empty';
        case 'giraffe':
          return 'Giraffe';
        case 'elephant':
          return 'Elephant';
        case 'teddyBear':
          return 'Teddy_bear_1';
        case 'ribbons':
          return getRandomFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, { random });
        case 'rubber':
          return 'Rubber';
        case 'sharpener':
          return 'Pencil_sharpener';
        case 'pencil':
          return getRandomFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            { random }
          );
      }
    };

    const title = selectShorter
      ? 'selectTheShorterObject'
      : isVertical
      ? 'selectTheTallerObject'
      : 'selectTheLongerObject';

    const pdfTitle = selectShorter
      ? 'tickTheShorterObject'
      : isVertical
      ? 'tickTheTallerObject'
      : 'tickTheLongerObject';

    const [answer] = sortNumberArray(lengths, selectShorter ? 'ascending' : 'descending');

    const svg1 = getSvgName(objects[0]);
    const svg2 = getSvgName(objects[1]);

    const maxLength = Math.max(...lengths);
    const rulerLength = maxLength > 7 ? 15 : maxLength + 2;

    return (
      <QF11SelectImagesUpTo4
        questionHeight={1000}
        title={translate.ks1Instructions[title]()}
        pdfTitle={translate.ks1PDFInstructions[pdfTitle]()}
        testCorrect={[answer]}
        numItems={2}
        itemLayout={isVertical ? 'row' : 'column'}
        itemStyle={{ margin: isVertical ? 0 : 8 }}
        renderItems={({ dimens }) => {
          const scaleFactor = Math.min(
            ...lengths.map((length, i) =>
              calculateScaling({
                width: dimens.width * 0.9,
                height: dimens.height * 0.9,
                vertical: isVertical,
                rulerKind: 'cm',
                rulerLength,
                items: [
                  {
                    length,
                    svgInfo: { name: i === 0 ? svg1 : svg2 },
                    guidelines: true
                  }
                ]
              })
            )
          );
          return lengths.map((length, i) => ({
            value: length,
            component: (
              <ItemsAgainstRuler
                width={dimens.width * 0.9}
                height={dimens.height * 0.9}
                vertical={isVertical}
                scaleFactor={scaleFactor}
                rulerKind="cm"
                rulerLength={rulerLength}
                items={[
                  {
                    length,
                    svgInfo: { name: i === 0 ? svg1 : svg2 },
                    guidelines: true
                  }
                ]}
              />
            )
          }));
        }}
      />
    );
  }
});

const getString = (
  unit: 'cm' | 'm',
  /** This needs to be between 0-100 */
  number: number,
  useWords: boolean,
  translate: TranslationFunctions
) =>
  unit === 'cm'
    ? useWords
      ? translate.units.stringCentimetre(
          translate.numbersAsWords[number.toString() as keyof typeof translate.numbersAsWords](),
          number
        )
      : translate.units.numberOfCm(number)
    : useWords
    ? translate.units.stringMetre(
        translate.numbersAsWords[number.toString() as keyof typeof translate.numbersAsWords](),
        number
      )
    : translate.units.numberOfM(number);

const Question2 = newQuestionContent({
  uid: 'bjz',
  description: 'bjz',
  keywords: ['Shorter', 'Longer', 'Length', 'The same as', 'Compare', 'Centimetres', 'Metres'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(100)).length(2),
    useWords: z.array(z.boolean()).length(2),
    units: z.array(z.enum(['cm', 'm'])).length(2)
  }),
  simpleGenerator: () => {
    const units = countRange(2).map(_ => getRandomFromArray(['cm', 'm'] as const));
    const numbers =
      units[0] === units[1]
        ? countRange(2).map(_ => randomIntegerInclusive(1, 100))
        : filledArray(randomIntegerInclusive(1, 100), 2);

    const useWords = countRange(2).map(_ => getRandomBoolean());

    return { units, numbers, useWords };
  },
  Component: props => {
    const {
      question: { units, numbers, useWords },
      translate
    } = props;

    const A = getString(units[0], numbers[0], useWords[0], translate);
    const B = getString(units[1], numbers[1], useWords[1], translate);

    const items = [
      {
        component: translate.operations.theSameAs(),
        value: 'same'
      },
      {
        component: translate.ks1MiscStrings.xToLowercase(translate.misc.ShorterThan()),
        value: 'short'
      },
      {
        component: translate.ks1MiscStrings.xToLowercase(translate.misc.LongerThan()),
        value: 'long'
      }
    ];

    const aCm = numbers[0] * (units[0] === 'm' ? 100 : 1);
    const bCm = numbers[1] * (units[1] === 'm' ? 100 : 1);
    const answer = aCm > bCm ? 'long' : aCm < bCm ? 'short' : 'same';

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useACardToCompleteSentence()}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        sentence={translate.ks1AnswerSentences.xIsAnsY(A, B)}
        sentencesStyle={{ alignItems: 'flex-start' }}
        pdfSentencesStyle={{ alignItems: 'flex-start' }}
        testCorrect={[answer]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjA',
  description: 'bjA',
  keywords: [
    'Equal to',
    'Less than',
    'Greater than',
    '<',
    '>',
    '=',
    'Length',
    'Height',
    'Compare',
    'Centimetres',
    'Metres'
  ],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(100)).length(2),
    useWords: z.array(z.boolean()).length(2),
    units: z.array(z.enum(['cm', 'm'])).length(2)
  }),
  simpleGenerator: () => {
    const units = countRange(2).map(_ => getRandomFromArray(['cm', 'm'] as const));
    const numbers =
      units[0] === units[1]
        ? countRange(2).map(_ => randomIntegerInclusive(1, 100))
        : filledArray(randomIntegerInclusive(1, 100), 2);

    const useWords = countRange(2).map(_ => getRandomBoolean());

    return { units, numbers, useWords };
  },
  Component: props => {
    const {
      question: { units, numbers, useWords },
      translate,
      displayMode
    } = props;

    const statements = [
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{
              fontSize: displayMode === 'digital' ? 32 : 50,
              textAlign: 'right',
              width: displayMode === 'digital' ? 400 : 600
            }}
          >
            {getString(units[0], numbers[0], useWords[0], translate)}
          </Text>
        ),
        rhsComponent: (
          <Text
            variant="WRN400"
            style={{
              fontSize: displayMode === 'digital' ? 32 : 50,
              textAlign: 'left',
              width: displayMode === 'digital' ? 400 : 600
            }}
          >
            {getString(units[1], numbers[1], useWords[1], translate)}
          </Text>
        ),
        correctAnswer: (() => {
          const aCm = numbers[0] * (units[0] === 'm' ? 100 : 1);
          const bCm = numbers[1] * (units[1] === 'm' ? 100 : 1);
          return lessThanGreaterThanOrEqualTo(aCm, bCm);
        })()
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheLengths()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompare()}
        actionPanelVariant="end"
        items={['<', '>', '=']}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        pdfLayout="itemsHidden"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareLengthsAndHeights',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
