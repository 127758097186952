import { newSmallStepContent } from '../../../SmallStep';
import { avD, avz } from '../../../Year 3/Summer/Shape/9RecogniseAndDescribe3DShapes';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import { getRandomName, nameSchema } from '../../../../utils/names';
import {
  get3DShapeSvgName,
  getRandomThreeDCompoundShapeOnIsometricPaperData,
  getRandomUnique3DShapeNames,
  getThreeDOutlineShapeOnIsometricPaper,
  getThreeDShapeOnIsometricPaper,
  shapeProperties,
  shapes3DAsWord,
  StampShape,
  stampsSchema,
  ThreeDShape,
  threeDShapesSchema,
  threeDShapeSvgArray,
  threeDShapeSvgNameToShapeType
} from '../../../../utils/threeDShapes';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import Text from '../../../../components/typography/Text';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import ContentBox from '../../../../components/molecules/ContentBox';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = { ...avz, uid: 'aAG', description: 'aAG' as const };

const q1v2CubesAndCuboids = ['cubes', 'cuboids'] as const;

const q1v2OtherShapes = [
  'hexagonalPrisms',
  'pentagonalPrisms',
  'squareBasedPyramids',
  'triangularPrisms',
  'cones',
  'cylinders',
  'triangularBasedPyramids'
] as const;

const q1v2ShapeOptions = [...q1v2CubesAndCuboids, ...q1v2OtherShapes] as const;

const Question1v2 = newQuestionContent({
  uid: 'aAG2',
  description: 'aAG2',
  keywords: ['3-D', 'Shape'],
  schema: z
    .object({
      shapeSvgNames: z
        .array(z.enum(threeDShapeSvgArray))
        .length(3)
        .refine(
          val => arrayHasNoDuplicates(val.map(svg => threeDShapeSvgNameToShapeType(svg))),
          'Each selected SVG must correspond to a different shape category.'
        ),
      options: z
        .array(z.enum(q1v2ShapeOptions))
        .length(4)
        .refine(val => arrayHasNoDuplicates(val))
    })
    .refine(
      val =>
        val.shapeSvgNames
          .map(svg => threeDShapeSvgNameToShapeType(svg))
          .every(shapeType => val.options.includes(shapeType)),
      'Every selected SVG shape must also have its matching shape type selected in options.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const cubesOrCuboids = getRandomFromArray(q1v2CubesAndCuboids);

    const selectedOptions = getRandomSubArrayFromArray(
      [cubesOrCuboids, ...q1v2OtherShapes] as const,
      4
    );

    const shapeSvgNames = countRange(3).map(i => get3DShapeSvgName(selectedOptions[i]));

    const options = shuffle(selectedOptions);

    return { shapeSvgNames, options };
  },
  Component: props => {
    const {
      question: { shapeSvgNames, options },
      translate,
      displayMode
    } = props;

    const items = options.map(shapeType => {
      return { component: shapes3DAsWord(shapeType, translate, 1), value: shapeType };
    });

    const statements = shapeSvgNames.map(svgName => {
      return {
        lhsComponent: (
          <View
            style={{
              alignItems: 'center',
              width: displayMode === 'digital' ? 150 : 250,
              paddingRight: 32
            }}
          >
            <AssetSvg name={svgName} height={displayMode === 'digital' ? 150 : 200} />
          </View>
        ),
        correctAnswer: threeDShapeSvgNameToShapeType(svgName)
      };
    });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchNamesToTheShape()}
        pdfTitle={translate.instructions.useCardsToMatchNamesToTheShape()}
        items={items}
        itemsMaxLines={2}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
        useRedLinesOnMarkScheme={false}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAH',
  description: 'aAH',
  keywords: ['3-D', 'Shape'],
  schema: z.object({
    characterName: nameSchema,
    correctShapeName: threeDShapesSchema,
    incorrectShapeNames: z.array(threeDShapesSchema).length(3),
    svgName: z.string()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const characterName = getRandomName();

    const [correctShapeName, ...incorrectShapeNames] = getRandomUnique3DShapeNames(4);

    const svgName = getThreeDShapeOnIsometricPaper(correctShapeName);

    return { correctShapeName, incorrectShapeNames, characterName, svgName };
  },
  Component: props => {
    const {
      question: { correctShapeName, incorrectShapeNames, characterName, svgName },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      [
        { string: shapes3DAsWord(correctShapeName, translate, 1), value: correctShapeName },
        ...incorrectShapeNames.map(shape => {
          return { string: shapes3DAsWord(shape, translate, 1), value: shape };
        })
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.xHasDrawnThreeDShapeOnIsometricPaperWhatShapeHasXDrawn(
          characterName
        )}
        questionHeight={900}
        testCorrect={[correctShapeName]}
        numItems={4}
        mainPanelFlexDirection="row"
        itemLayout="column"
        itemsOuterContainerStyle={{ gap: 12 }}
        renderItems={items.map(item => ({
          value: item.value,
          component: (
            <Text
              variant="WRN700"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {item.string}
            </Text>
          )
        }))}
        Content={({ dimens }) => {
          return <AssetSvg name={svgName as SvgName} height={dimens.height * 0.8} />;
        }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aAH2',
  description: 'aAH',
  keywords: ['3-D', 'Shape'],
  schema: z.object({
    characterName: nameSchema,
    correctShapeName: threeDShapesSchema,
    incorrectShapeNames: z.array(threeDShapesSchema).length(3),
    svgName: z.string()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const characterName = getRandomName();

    const { correctShapeName, incorrectShapeNames } = rejectionSample(
      () => {
        const [correctShapeName, ...incorrectShapeNames] = getRandomUnique3DShapeNames(4);
        return { correctShapeName, incorrectShapeNames };
      },
      // as per QA feedback - only give 1 pyramid option when the answer is pyramid
      val =>
        val.correctShapeName.includes('Pyramid')
          ? val.incorrectShapeNames.every(val => !val.includes('Pyramid'))
          : true
    );

    const svgName = getThreeDOutlineShapeOnIsometricPaper(correctShapeName);

    return { correctShapeName, incorrectShapeNames, characterName, svgName };
  },
  Component: props => {
    const {
      question: { correctShapeName, incorrectShapeNames, characterName, svgName },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      [
        { string: shapes3DAsWord(correctShapeName, translate, 1), value: correctShapeName },
        ...incorrectShapeNames.map(shape => {
          return { string: shapes3DAsWord(shape, translate, 1), value: shape };
        })
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.xHasDrawnThreeDShapeOnIsometricPaperSelectShapeHasXDrawn(
          characterName
        )}
        pdfTitle={translate.instructions.xHasDrawnThreeDShapeOnIsometricPaperCircleShapeHasXDrawn(
          characterName
        )}
        questionHeight={900}
        testCorrect={[correctShapeName]}
        numItems={4}
        mainPanelFlexDirection="row"
        itemLayout="column"
        itemsOuterContainerStyle={{ gap: 12 }}
        renderItems={items.map(item => ({
          value: item.value,
          component: (
            <Text
              variant="WRN700"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {item.string}
            </Text>
          )
        }))}
        Content={({ dimens }) => {
          return <AssetSvg name={svgName as SvgName} height={dimens.height * 0.8} />;
        }}
      />
    );
  }
});

const Question3 = { ...avD, uid: 'aAI', description: 'aAI' as const };

const Question4 = newQuestionContent({
  uid: 'aAJ',
  description: 'aAJ',
  keywords: ['3-D', 'Shape', 'Properties'],
  schema: z.object({
    name: nameSchema,
    shapes: z.array(threeDShapesSchema).length(4),
    ansShapeIdx: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const shapes = getRandomUnique3DShapeNames(4);
    const ansShapeIdx = randomIntegerInclusive(0, 3);

    return { name, shapes, ansShapeIdx };
  },
  Component: ({ question, translate }) => {
    const { name, shapes, ansShapeIdx } = question;

    const statement = (() => {
      switch (shapes[ansShapeIdx]) {
        case 'cubes':
          return translate.answerSentences.myShapeHas6SquareFaces8Vertices12Edges();
        case 'cylinders':
          return translate.answerSentences.myShapeHas2FacesCurvedSurface2Edges();
        case 'cones':
          return translate.answerSentences.myShapeHas1FaceCurvedSurface1Vertex1Edges();
        case 'cuboids':
          return translate.answerSentences.myShapeHas6RectangularFaces8Vertices12Edges();
        default: {
          const { faces, vertices, edges } = shapeProperties.filter(
            val => val.shape === shapes[ansShapeIdx]
          )[0];
          return translate.answerSentences.myShapeHasXFacesYVerticesZEdges(
            translate.numbersAsWords[faces](),
            translate.numbersAsWords[vertices](),
            translate.numbersAsWords[edges]()
          );
        }
      }
    })();

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.select3DShapeXIsThinkingOf(name)}
        pdfTitle={translate.instructions.circle3DShapeXIsThinkingOf(name)}
        numItems={4}
        testCorrect={[shapes[ansShapeIdx]]}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }
            ]}
          >
            <SpeechBubble
              flickLocation="bottom-right"
              style={{
                maxWidth: 0.45 * dimens.width,
                marginHorizontal: 0.05 * dimens.width,
                maxHeight: 0.5 * dimens.height,
                marginVertical: 0.1 * dimens.height,
                top: -20,
                zIndex: 1
              }}
            >
              {statement}
            </SpeechBubble>

            <View style={{ alignSelf: 'flex-end', marginRight: 20, marginVertical: 20, zIndex: 0 }}>
              <AssetSvg
                name={getCharacterHeadSvgName(name)}
                height={dimens.height * 0.7}
                width={dimens.width / 3}
              />
            </View>
          </View>
        )}
        renderItems={shapes.map(shape => ({
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {shapes3DAsWord(shape, translate, 1)}
            </Text>
          ),
          value: shape
        }))}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aAJ2',
  description: 'aAJ',
  keywords: ['3-D', 'Shape', 'Properties'],
  schema: z.object({
    shape: z.enum([
      'cuboids',
      'hexagonalPrisms',
      'pentagonalPrisms',
      'squareBasedPyramids',
      'triangularBasedPyramids',
      'triangularPrisms',
      'cubes'
    ]),
    incorrectOption1: stampsSchema,
    incorrectOption2: z.enum([
      'rectangles',
      'hexagons',
      'pentagons',
      'heptagons',
      'squares',
      'triangles'
    ]),
    incorrectOption3: threeDShapesSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'cuboids',
      'hexagonalPrisms',
      'pentagonalPrisms',
      'squareBasedPyramids',
      'triangularBasedPyramids',
      'triangularPrisms',
      'cubes'
    ] as const);
    const properties = shapeProperties.filter(val => val.shape === shape)[0];

    const { incorrectOption1, incorrectOption2, incorrectOption3 } = rejectionSample(
      () => {
        const incorrectOption1 = properties.stampImages[0];
        const incorrectOption2 = (() => {
          switch (properties.faces) {
            case 3:
              return 'triangles';
            case 4:
              return getRandomFromArray(['squares', 'rectangles'] as const);
            case 5:
              return 'pentagons';
            case 6:
              return 'hexagons';
            default:
              return 'heptagons';
          }
        })() as 'triangles' | 'squares' | 'rectangles' | 'pentagons' | 'hexagons' | 'heptagons';
        const incorrectOption3 = getRandomFromArray(
          [
            'cuboids',
            'hexagonalPrisms',
            'pentagonalPrisms',
            'squareBasedPyramids',
            'triangularBasedPyramids',
            'triangularPrisms',
            'cubes',
            'cones',
            'cylinders'
          ].filter(val => val !== shape)
        ) as ThreeDShape;

        return { incorrectOption1, incorrectOption2, incorrectOption3 };
      },
      val =>
        arrayHasNoDuplicates([val.incorrectOption1, val.incorrectOption2, val.incorrectOption3])
    );

    return { shape, incorrectOption1, incorrectOption2, incorrectOption3 };
  },
  Component: ({ question, translate, displayMode }) => {
    const { shape, incorrectOption1, incorrectOption2, incorrectOption3 } = question;

    const properties = shapeProperties.filter(val => val.shape === shape)[0];

    const getStatementProps = (shape: StampShape, amount: number) => {
      switch (shape) {
        case 'circles':
          return translate.shapes.properties.nCircularFaces(amount);
        case 'hexagons':
          return translate.shapes.properties.nHexagonalFaces(amount);
        case 'pentagons':
          return translate.shapes.properties.nPentagonalFaces(amount);
        case 'rectangles':
          return translate.shapes.properties.nRectangularFaces(amount);
        case 'squares':
          return translate.shapes.properties.nSquareFaces(amount);
        case 'triangles':
          return translate.shapes.properties.nTriangularFaces(amount);
      }
    };

    const statement =
      properties.stampImages.length > 1
        ? translate.answerSentences.theShapeHasXAndY(
            getStatementProps(
              properties.stampImages[0],
              properties.stampCount ? properties.stampCount[0] : 1
            ),
            getStatementProps(
              properties.stampImages[1],
              properties.stampCount ? properties.stampCount[1] : 1
            )
          )
        : translate.answerSentences.theShapeHasX(
            getStatementProps(
              properties.stampImages[0],
              properties.stampCount ? properties.stampCount[0] : 1
            )
          );

    const options = shuffle([shape, incorrectOption1, incorrectOption2, incorrectOption3], {
      random: seededRandom(question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectShapeDescribed()}
        pdfTitle={translate.instructions.circleShapeDescribed()}
        numItems={4}
        testCorrect={[shape]}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'center', alignItems: 'center' }}>
            <ContentBox containerStyle={{ width: dimens.width * 0.8 }}>
              <Text variant="WRN400" style={{ textAlign: 'center' }}>
                {statement}
              </Text>
            </ContentBox>
          </View>
        )}
        renderItems={options.map(shape => ({
          component: (
            <Text
              variant="WRN700"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 30 : 50 }}
            >
              {translate.shapes[shape](1)}
            </Text>
          ),
          value: shape
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aAK',
  description: 'aAK',
  keywords: ['3-D', 'Shape', 'Properties'],
  schema: z.object({
    shape: z.enum([
      'cubes',
      'squareBasedPyramids',
      'triangularPrisms',
      'pentagonalPrisms',
      'hexagonalPrisms',
      'octagonalPrisms'
    ])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'cubes',
      'squareBasedPyramids',
      'triangularPrisms',
      'pentagonalPrisms',
      'hexagonalPrisms',
      'octagonalPrisms'
    ] as const);

    return { shape };
  },
  Component: ({ question, translate }) => {
    const { shape } = question;

    const properties = {
      cubes: [[6, 'squares']],
      squareBasedPyramids: [
        [1, 'squares'],
        [4, 'triangular']
      ],
      triangularPrisms: [
        [2, 'triangular'],
        [3, 'rectangular']
      ],
      pentagonalPrisms: [
        [2, 'pentagonal'],
        [5, 'rectangular']
      ],
      hexagonalPrisms: [
        [2, 'hexagonal'],
        [6, 'rectangular']
      ],
      octagonalPrisms: [
        [2, 'octagonal'],
        [8, 'rectangular']
      ]
    } as const;

    type MutlipleFacedShapes =
      | 'squareBasedPyramids'
      | 'triangularPrisms'
      | 'pentagonalPrisms'
      | 'hexagonalPrisms'
      | 'octagonalPrisms';

    const faceShape1 = properties[shape][0][1];
    const faceShape1Amount = properties[shape][0][0];
    const faceShape2 =
      properties[shape].length > 1
        ? translate.shapes[properties[shape as MutlipleFacedShapes][1][1]]()
        : '';
    const faceShape2Amount =
      properties[shape].length > 1 ? properties[shape as MutlipleFacedShapes][1][0] : 0;

    const sentence =
      shape === 'cubes'
        ? translate.answerSentences.cubeHasAnsSquareFaces()
        : translate.answerSentences.xHasAnsYFacesAndAnsZFaces(
            translate.shapes[shape](1),
            translate.shapes[faceShape1](1),
            faceShape1Amount,
            faceShape2,
            faceShape2Amount
          );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        sentence={sentence}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        testCorrect={
          shape === 'cubes'
            ? [faceShape1Amount.toString()]
            : [faceShape1Amount.toString(), faceShape2Amount.toString()]
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aAL',
  description: 'aAL',
  keywords: ['3-D', 'Shape', 'Properties'],
  schema: z.object({
    characterName: nameSchema,
    shapeData: z.object({ svgName: z.string(), faces: z.number() })
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const characterName = getRandomName();

    const shapeData = getRandomThreeDCompoundShapeOnIsometricPaperData();

    return { characterName, shapeData };
  },

  Component: ({ question: { characterName, shapeData }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.xDrawsACompoundShapeMadeFromOtherThreeDShapesHowManyFacesAreThereOnTheShape(
          characterName
        )}
        questionHeight={900}
        testCorrect={[shapeData.faces.toString()]}
        sentence={`<ans/>`}
        mainPanelStyle={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-end'
        }}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
        Content={({ dimens }) => {
          return <AssetSvg name={shapeData.svgName as SvgName} height={dimens.height * 0.95} />;
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Num3DShapes',
  questionTypes: [Question1v2, Question2v2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question1, Question2, Question4]
});
export default SmallStep;
