import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive, shuffle } from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { ADD, DIV, MULT, SUB } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { useMemo } from 'react';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aFC',
  description: 'aFC',
  keywords: ['Division', '10'],
  schema: z.object({
    rows: z.number().int().min(2).max(4),
    columns: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 4);
    const columns = randomIntegerInclusive(2, 6);

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate
    } = props;

    const var3 = rows * columns;

    const sentences = [
      {
        sentence: `${var3.toLocaleString()} ${translate.keywords
          .Ones()
          .toLocaleLowerCase()} ${DIV} ${rows.toLocaleString()} = <ans/> ${translate.keywords
          .Ones()
          .toLocaleLowerCase()}`,
        answers: [columns.toString()]
      },
      {
        sentence: `${var3.toLocaleString()} ${translate.keywords
          .Tens()
          .toLocaleLowerCase()} ${DIV} ${rows.toLocaleString()} = <ans/> ${translate.keywords
          .Tens()
          .toLocaleLowerCase()}`,
        answers: [columns.toString()]
      }
    ];

    return (
      <QF1ContentAndSentences
        sentences={sentences.map(sentence => sentence.sentence)}
        title={translate.instructions.completeSentences()}
        testCorrect={sentences.map(sentence => sentence.answers)}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', columnGap: 50 }}>
            <ArrayOfObjects
              dimens={{ height: dimens.height, width: dimens.width / 2 - 60 }}
              rows={rows}
              columns={columns}
              customImage={<AssetSvg width={60} name="Place_value/1" />}
              rowStyle={{ columnGap: 4, width: dimens.width / 2 - 60 }}
            />
            <ArrayOfObjects
              dimens={{ height: dimens.height, width: dimens.width / 2 - 60 }}
              rows={rows}
              customImage={<AssetSvg width={60} name="Place_value/10" />}
              columns={columns}
              rowStyle={{ columnGap: 4, width: dimens.width / 2 - 60 }}
            />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aFD',
  description: 'aFD',
  keywords: ['Place value', 'Multiples', '10', 'Multiplication'],
  schema: z.object({
    columns: z.number().int().min(1).max(4),
    rows: z.number().int().min(1).max(5),
    var4: z.number().int().min(1).max(50)
  }),
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(1, 4);
    const rows = randomIntegerInclusive(1, 5);
    const var3 = rows * 10;
    const var4 = getRandomFromArray([rows, var3]);

    return { columns, rows, var4 };
  },
  Component: props => {
    const {
      question: { columns, rows, var4 },
      translate
    } = props;

    const items = useMemo(() => {
      const correctImage = var4 % 10 === 0 ? 'Place_value/10' : 'Place_value/1';
      const incorrectImage = correctImage === 'Place_value/10' ? 'Place_value/1' : 'Place_value/10';

      const lessRows = rows > 1 ? rows - 1 : rows;
      const moreRows = rows < 5 ? rows + 1 : rows;

      const lessColumns = columns > 1 ? columns - 1 : columns;
      const moreColumns = columns < 5 ? columns + 1 : columns;

      const incorrectAnswers = [
        {
          rows: lessRows,
          columns,
          isCorrect: false,
          customImage: incorrectImage
        },
        {
          rows: moreRows,
          columns,
          isCorrect: false,
          customImage: incorrectImage
        },
        {
          rows: rows,
          columns: lessColumns,
          isCorrect: false,
          customImage: incorrectImage
        },
        {
          rows: rows,
          columns: moreColumns,
          isCorrect: false,
          customImage: incorrectImage
        },
        {
          rows: randomIntegerInclusive(1, 5, { constraint: x => x !== rows }),
          columns: columns,
          isCorrect: false,
          customImage: correctImage
        },
        {
          rows: rows,
          columns: randomIntegerInclusive(1, 5, { constraint: x => x !== columns }),
          isCorrect: false,
          customImage: correctImage
        }
      ] as const;

      const correctObject = {
        columns,
        rows,
        isCorrect: true,
        customImage: correctImage
      };

      const incorrectObject = getRandomFromArray(incorrectAnswers);

      return shuffle([correctObject, incorrectObject]);
    }, [columns, rows, var4]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectThePictureThatMatchesX(
          `${columns.toLocaleString()} ${MULT} ${var4.toLocaleString()}`
        )}
        testCorrect={userAnswer => {
          return userAnswer.toString() === items.filter(el => el.isCorrect).toString();
        }}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item,
            component: (
              <ArrayOfObjects
                dimens={dimens}
                rows={item.rows}
                columns={item.columns}
                customImage={<AssetSvg width={65} name={item.customImage as SvgName} />}
                rowStyle={{ columnGap: 4, width: dimens.width / 2, alignSelf: 'center' }}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aFD2',
  description: 'aFD',
  keywords: ['Place value', 'Multiples', '10', 'Multiplication'],
  schema: z.object({
    options: z
      .array(
        z.object({
          rows: z.number().int().min(1).max(5),
          columns: z.number().int().min(1).max(5),
          isCorrect: z.boolean(),
          image: z.enum(['Place_value/1', 'Place_value/10'])
        })
      )
      .length(2)
  }),
  simpleGenerator: () => {
    // Determine which incorrect answer to show
    // A = Wrong number of columns shown, B = Wrong counter type shown
    const variation = getRandomFromArray(['A', 'B'] as const);

    const tensOrOnes = getRandomFromArray(['tens', 'ones'] as const);
    const columns = randomIntegerInclusive(1, 4);

    const rows = randomIntegerInclusive(1, 5);
    const addOrSubtract = columns === 1 ? ADD : getRandomFromArray([ADD, SUB] as const);

    const columnsB =
      variation === 'A' ? (addOrSubtract === ADD ? columns + 1 : columns - 1) : columns;

    const imageB =
      variation === 'B'
        ? tensOrOnes === 'tens'
          ? ('Place_value/1' as const)
          : ('Place_value/10' as const)
        : tensOrOnes === 'tens'
        ? ('Place_value/10' as const)
        : ('Place_value/1' as const);

    const options = shuffle([
      {
        rows,
        columns,
        isCorrect: true,
        image: tensOrOnes === 'tens' ? ('Place_value/10' as const) : ('Place_value/1' as const)
      },
      { rows, columns: columnsB, isCorrect: false, image: imageB }
    ]);

    return { options };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    // Pull out the correct numbers to display in instruction
    const { image, columns, rows } = options.filter(el => el.isCorrect)[0];
    const multiplication = image === 'Place_value/10' ? rows * 10 : rows;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheArrayThatShowsXMultY(columns, multiplication)}
        pdfTitle={translate.instructions.circleArrayThatShowsXMultY(columns, multiplication)}
        testCorrect={options.filter(el => el.isCorrect)}
        numItems={2}
        renderItems={({ dimens }) => {
          return options.map(opt => ({
            value: opt,
            component: (
              <ArrayOfObjects
                dimens={dimens}
                rows={opt.rows}
                columns={opt.columns}
                customImage={<AssetSvg width={65} name={opt.image} />}
                rowStyle={{ columnGap: 4, width: dimens.width / 2, alignSelf: 'center' }}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aFE',
  description: 'aFE',
  keywords: ['Related facts', 'Multiples of 10'],
  schema: z.object({
    name: nameSchema,
    var1: z.number().int().min(2).max(5),
    var2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const name = getRandomName();
    const var1 = randomIntegerInclusive(2, 5);
    const var2 = randomIntegerInclusive(2, 9, {
      constraint: x => x !== var1
    });

    return { var1, var2, name };
  },
  Component: props => {
    const {
      question: { var1, var2, name },
      translate
    } = props;

    const var3 = var1 * var2;
    const var4 = var2 * 10;
    const var5 = var1 * 10;

    const correctAnswerA = var1 * var4;
    const correctAnswerB = var5 * var2;

    const sentences = [
      {
        sentence: `${var1.toLocaleString()} ${MULT} ${var4.toLocaleString()} = <ans/>`,
        answer: [correctAnswerA.toString()]
      },
      {
        sentence: `${var5.toLocaleString()} ${MULT} ${var2.toLocaleString()} = <ans/>`,
        answer: [correctAnswerB.toString()]
      }
    ];

    return (
      <QF1ContentAndSentences
        sentences={sentences.map(sentence => sentence.sentence)}
        title={translate.instructions.useCharactersFactToCompleteTheMultiplications(name)}
        testCorrect={sentences.map(sentence => sentence.answer)}
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row' }}>
              <SpeechBubble
                flickLocation="bottom-right"
                style={{ margin: 30, alignSelf: 'flex-start' }}
                textStyle={{ marginVertical: 10 }}
              >
                {translate.misc.IKnowThat(
                  `${var1.toLocaleString()} ${MULT} ${var2.toLocaleString()} = ${var3.toLocaleString()}`
                )}
              </SpeechBubble>
              <AssetSvg
                name={getCharacterHeadSvgName(name)}
                height={dimens.height * 0.7}
                width={dimens.width * 0.3}
              />
            </View>
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aFF',
  description: 'aFF',
  keywords: ['Multiples', '10', 'Multiplication'],
  schema: z.object({
    var1: z.number().int().min(2).max(5),
    var2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 5);
    const var2 = randomIntegerInclusive(2, 9, {
      constraint: x => x !== var1
    });
    return { var1, var2 };
  },

  Component: props => {
    const {
      question: { var1, var2 },
      translate
    } = props;

    const var3 = var2 * 10;

    const eqA = getBinOpEquation({
      left: var1,
      right: var2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: var1,
      right: var3,
      sign: 'multiply',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: var3,
      right: var1,
      sign: 'multiply',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aFG',
  description: 'aFG',
  keywords: ['Multiplication', 'Division', '10'],
  schema: z.object({
    var1: z.number().int().min(2).max(5),
    var2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 5);
    const var2 = randomIntegerInclusive(2, 9);

    return { var1, var2 };
  },

  Component: props => {
    const {
      question: { var1, var2 },
      translate
    } = props;

    const var3 = var1 * var2;
    const var4 = var3 * 10;
    const var5 = var4 / var1;

    const eqA = getBinOpEquation({
      left: var3,
      right: var1,
      sign: 'divide',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: var4,
      right: var1,
      sign: 'divide',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: var4,
      right: var5,
      sign: 'divide',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aFH',
  description: 'aFH',
  keywords: ['Multiplication', 'Divide', '10'],
  schema: z.object({
    var1: numberEnum([1, 2, 3, 4, 5, 8]),
    var2: z.number().int().min(2).max(12),
    var5: z.number().int().min(2).max(12),
    var6: z.number().int().min(2).max(5)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const var1 = getRandomFromArray([2, 3, 4, 5, 8] as const);
    const var2 = randomIntegerInclusive(2, 12);
    const var5 = randomIntegerInclusive(2, 12);
    const var6 = randomIntegerInclusive(2, 5);

    return { var1, var2, var5, var6 };
  },

  Component: props => {
    const {
      question: { var1, var2, var5, var6 },
      translate
    } = props;

    const var3 = var2 * 10;
    const var4 = var1 * var2;
    const var10 = var1 * var3;
    const var7 = var5 * var6;
    const var8 = var5 * 10;
    const var9 = var6 * var8;

    const eqA = getBinOpEquation({
      left: var1,
      result: var4,
      sign: 'multiply',
      answer: 'right'
    });

    const eqB = getBinOpEquation({
      left: var1,
      result: var10,
      sign: 'multiply',
      answer: 'right'
    });

    const eqC = getBinOpEquation({
      left: var7,
      result: var6,
      sign: 'divide',
      answer: 'right'
    });

    const eqD = getBinOpEquation({
      left: var9,
      result: var6,
      sign: 'divide',
      answer: 'right'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeCalculations()}
        leftSide={[eqs[0], eqs[1]].map(binOpEquationToSentenceString)}
        rightSide={[eqs[2], eqs[3]].map(binOpEquationToSentenceString)}
        testCorrect={{
          left: binOpEquationsToTestCorrect([eqs[0], eqs[1]]),
          right: binOpEquationsToTestCorrect([eqs[2], eqs[3]])
        }}
        noEquals
        leftColumnStyle={{ alignItems: 'flex-start' }}
        rightColumnStyle={{ alignItems: 'flex-end' }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aFH2',
  description: 'aFH',
  keywords: ['Multiplication', 'Divide', '10'],
  schema: z.object({
    var1: numberEnum([1, 2, 3, 4, 5, 8]),
    var2: z.number().int().min(2).max(12),
    var5: z.number().int().min(2).max(12),
    var6: z.number().int().min(2).max(5),
    multiplyOrDivide: z.enum(['multiply', 'divide'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const var1 = getRandomFromArray([2, 3, 4, 5, 8] as const);
    const var2 = randomIntegerInclusive(2, 12);
    const var5 = randomIntegerInclusive(2, 12);
    const var6 = randomIntegerInclusive(2, 5);

    const multiplyOrDivide = getRandomFromArray(['multiply', 'divide'] as const);

    return { var1, var2, var5, var6, multiplyOrDivide };
  },

  Component: props => {
    const {
      question: { var1, var2, var5, var6, multiplyOrDivide },
      translate
    } = props;

    const var3 = var2 * 10;
    const var4 = var1 * var2;
    const var10 = var1 * var3;
    const var7 = var5 * var6;
    const var8 = var5 * 10;
    const var9 = var6 * var8;

    const eqA = getBinOpEquation({
      left: var1,
      result: var4,
      sign: 'multiply',
      answer: 'right'
    });

    const eqB = getBinOpEquation({
      left: var1,
      result: var10,
      sign: 'multiply',
      answer: 'right'
    });

    const eqC = getBinOpEquation({
      left: var7,
      result: var6,
      sign: 'divide',
      answer: 'right'
    });

    const eqD = getBinOpEquation({
      left: var9,
      result: var6,
      sign: 'divide',
      answer: 'right'
    });

    const eqs = multiplyOrDivide === 'multiply' ? [eqA, eqB] : [eqC, eqD];

    const leftSide = [
      `${eqs[0].left.toLocaleString()} ${multiplyOrDivide === 'multiply' ? MULT : DIV} <ans/>`,
      `${eqs[1].left.toLocaleString()} ${multiplyOrDivide === 'multiply' ? MULT : DIV} <ans/>`
    ];
    const rightSide = [eqs[0].result.toLocaleString(), eqs[1].result.toLocaleString()];

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeCalculations()}
        leftSide={leftSide}
        rightSide={rightSide}
        testCorrect={{
          left: binOpEquationsToTestCorrect(eqs)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RelatedCalculations',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question2, Question6]
});
export default SmallStep;
