// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MentalStrategies from './1MentalStrategies';
import AddWholeNumbersWithMoreThanFourDigits from './2AddWholeNumbersWithMoreThanFourDigits';
import SubtractWholeNumbersWithMoreThanFourDigits from './3SubtractWholeNumbersWithMoreThanFourDigits';
import RoundToCheckAnswers from './4RoundToCheckAnswers';
import InverseOperationsAdditionAndSubtraction from './5InverseOperationsAdditionAndSubtraction';
import MultiStepAdditionAndSubtractionProblems from './6MultiStepAdditionAndSubtractionProblems';
import CompareCalculations from './7CompareCalculations';
import FindMissingNumbers from './8FindMissingNumbers';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [4, 5],
  smallSteps: [
    MentalStrategies, // Step 1
    AddWholeNumbersWithMoreThanFourDigits, // Step 2
    SubtractWholeNumbersWithMoreThanFourDigits, // Step 3
    RoundToCheckAnswers, // Step 4
    InverseOperationsAdditionAndSubtraction, // Step 5
    MultiStepAdditionAndSubtractionProblems, // Step 6
    CompareCalculations, // Step 7
    FindMissingNumbers // Step 8
  ]
});
export default Block;
