import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import {
  findExchanges,
  getSumWithOnlyGivenExchanges,
  numbersDoNotExchange,
  numbersDoNotExchangeAt,
  numbersOnlyExchangeAt
} from '../../../../utils/exchanges';
import ColumnOperations from '../../../../components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { ADD } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { range } from '../../../../utils/collections';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { digitAtPowerIsNumber } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'agW',
  description: 'agW',
  keywords: ['Addition', 'Base 10', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z.number().int().min(111).max(888),
      var2: z.number().int().min(111).max(888)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.var1, val.var2, 'tens') && val.var1 + val.var2 < 1000,
      'Numbers must exchange only at tens and total must be less than 1000'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(111, 888, {
          constraint: x =>
            // Must not contain any zeroes:
            !digitAtPowerIsNumber(x, 'ones', [0]) && !digitAtPowerIsNumber(x, 'tens', [0])
        });

        const var2 = randomIntegerInclusive(111, 999 - var1, {
          constraint: x =>
            // Must not contain any zeroes:
            !digitAtPowerIsNumber(x, 'ones', [0]) && !digitAtPowerIsNumber(x, 'tens', [0])
        });

        return { var1, var2 };
      },
      // Only permit them if they exchange at the tens and the total contains no zeroes:
      ({ var1, var2 }) =>
        numbersOnlyExchangeAt(var1, var2, 'tens') &&
        !digitAtPowerIsNumber(var1 + var2, 'ones', [0]) &&
        !digitAtPowerIsNumber(var1 + var2, 'tens', [0])
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'agX',
  description: 'agX',
  keywords: ['Addition', 'Counters', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z.number().int().min(101).max(898),
      var2: z.number().int().min(101).max(898)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.var1, val.var2, 'tens'),
      'var1 and var2 must only exchanges at the tens.'
    )
    .refine(val => val.var1 + val.var2 < 1000, 'total should be less than 1000'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 1,000.
        const var1 = randomIntegerInclusive(101, 898, { constraint: x => x % 100 !== 0 });
        const var2 = randomIntegerInclusive(101, 898, { constraint: x => x % 100 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they exchanges at the tens
      ({ var1, var2 }) =>
        numbersOnlyExchangeAt(var1, var2, 'tens') &&
        // At least one of the addends or the total must have a zero
        // (addends can only have zero ones as they need to exchange at their tens):
        (digitAtPowerIsNumber(var1, 'ones', [0]) ||
          digitAtPowerIsNumber(var2, 'ones', [0]) ||
          digitAtPowerIsNumber(var1 + var2, 'ones', [0]) ||
          digitAtPowerIsNumber(var1 + var2, 'tens', [0])) &&
        (var1 + var2) % 100 !== 0
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'agY',
  description: 'agY',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(899),
      numberA2: z.number().int().min(100).max(899),
      numberB1: z.number().int().min(100).max(899),
      numberB2: z.number().int().min(100).max(899),
      numberC1: z.number().int().min(100).max(899),
      numberC2: z.number().int().min(100).max(899),
      numberD1: z.number().int().min(100).max(899),
      numberD2: z.number().int().min(100).max(899),
      cHasExchange: z.boolean(),
      dHasExchange: z.boolean()
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => findExchanges(val.numberB1, val.numberB2).length === 1,
      'numberB1 and numberB2 must have one exchange.'
    ),
  simpleGenerator: () => {
    const checkConstraint = (firstValue: number, secondNumber: number, exchange: boolean) => {
      if (exchange) {
        return (
          findExchanges(firstValue, secondNumber).length === 1 &&
          numbersDoNotExchangeAt(firstValue, secondNumber, 'hundreds')
        );
      } else return numbersDoNotExchange(firstValue, secondNumber);
    };

    const { numberA1, numberA2 } = rejectionSample(
      () => {
        const numberA1 = randomIntegerInclusive(100, 899);
        const numberA2 = randomIntegerInclusive(100, 999 - numberA1);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => checkConstraint(numberA1, numberA2, false)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 1,000.
        const numberB1 = randomIntegerInclusive(100, 899);
        const numberB2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberB1, numberB2 };
      },
      // Only permit them if they have one exchange
      ({ numberB1, numberB2 }) => checkConstraint(numberB1, numberB2, true)
    );

    const cHasExchange = getRandomBoolean();
    const { numberC1, numberC2 } = rejectionSample(
      () => {
        const numberC1 = randomIntegerInclusive(100, 899);
        const numberC2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberC1, numberC2 };
      },
      ({ numberC1, numberC2 }) => checkConstraint(numberC1, numberC2, cHasExchange)
    );

    const dHasExchange = getRandomBoolean();
    const { numberD1, numberD2 } = rejectionSample(
      () => {
        const numberD1 = randomIntegerInclusive(100, 899);
        const numberD2 = randomIntegerInclusive(100, 999 - numberB1);
        return { numberD1, numberD2 };
      },
      ({ numberD1, numberD2 }) => checkConstraint(numberD1, numberD2, dHasExchange)
    );
    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        cHasExchange,
        numberC1,
        numberC2,
        dHasExchange,
        numberD1,
        numberD2
      },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1, bottomNumber: numberB2, isCorrect: true };
      const eqC = { topNumber: numberC1, bottomNumber: numberC2, isCorrect: cHasExchange };
      const eqD = { topNumber: numberD1, bottomNumber: numberD2, isCorrect: dHasExchange };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.whichAdditionsNeedAnExchange()} ${translate.instructions.selectYourAnswers()}`}
        pdfTitle={`${translate.instructions.whichAdditionsWillNeedAnExchange()}<br/>${translate.instructions.circleYourAnswers()}`}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'agY2',
  description: 'agY',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(101).max(899),
      numberA2: z.number().int().min(101).max(899),
      numberB1: z.number().int().min(101).max(899),
      numberB2: z.number().int().min(101).max(899),
      numberC1: z.number().int().min(101).max(899),
      numberC2: z.number().int().min(101).max(899),
      numberD1: z.number().int().min(101).max(899),
      numberD2: z.number().int().min(101).max(899),
      cHasExchange: z.boolean(),
      dHasExchange: z.boolean()
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => findExchanges(val.numberB1, val.numberB2).length === 1,
      'numberB1 and numberB2 must have one exchange.'
    )
    .refine(
      val =>
        [
          val.numberA1,
          val.numberA2,
          val.numberB1,
          val.numberB2,
          val.numberC1,
          val.numberC2,
          val.numberD1,
          val.numberD2
        ].every(num => num % 100 !== 0),
      'No number should not be a multiple of 100.'
    ),
  simpleGenerator: () => {
    const checkConstraint = (firstValue: number, secondNumber: number, exchange: boolean) => {
      if (exchange) {
        return (
          findExchanges(firstValue, secondNumber).length === 1 &&
          numbersDoNotExchangeAt(firstValue, secondNumber, 'hundreds')
        );
      } else return numbersDoNotExchange(firstValue, secondNumber);
    };

    const { numberA1, numberA2 } = rejectionSample(
      () => {
        const numberA1 = randomIntegerInclusive(101, 899, { constraint: x => x % 100 !== 0 });
        const numberA2 = randomIntegerInclusive(101, 1000 - numberA1, {
          constraint: x => x % 100 !== 0
        });
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => checkConstraint(numberA1, numberA2, false)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 1,000.
        const numberB1 = randomIntegerInclusive(101, 899, { constraint: x => x % 100 !== 0 });
        const numberB2 = randomIntegerInclusive(101, 1000 - numberB1, {
          constraint: x => x % 100 !== 0
        });
        return { numberB1, numberB2 };
      },
      // Only permit them if they have one exchange
      ({ numberB1, numberB2 }) => checkConstraint(numberB1, numberB2, true)
    );

    const cHasExchange = getRandomBoolean();
    const { numberC1, numberC2 } = rejectionSample(
      () => {
        const numberC1 = randomIntegerInclusive(101, 899, { constraint: x => x % 100 !== 0 });
        const numberC2 = randomIntegerInclusive(101, 1000 - numberB1, {
          constraint: x => x % 100 !== 0
        });
        return { numberC1, numberC2 };
      },
      ({ numberC1, numberC2 }) => checkConstraint(numberC1, numberC2, cHasExchange)
    );

    const dHasExchange = getRandomBoolean();
    const { numberD1, numberD2 } = rejectionSample(
      () => {
        const numberD1 = randomIntegerInclusive(101, 899, { constraint: x => x % 100 !== 0 });
        const numberD2 = randomIntegerInclusive(101, 1000 - numberB1, {
          constraint: x => x % 100 !== 0
        });
        return { numberD1, numberD2 };
      },
      ({ numberD1, numberD2 }) => checkConstraint(numberD1, numberD2, dHasExchange)
    );
    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        cHasExchange,
        numberC1,
        numberC2,
        dHasExchange,
        numberD1,
        numberD2
      },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1, bottomNumber: numberB2, isCorrect: true };
      const eqC = { topNumber: numberC1, bottomNumber: numberC2, isCorrect: cHasExchange };
      const eqD = { topNumber: numberD1, bottomNumber: numberD2, isCorrect: dHasExchange };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      cHasExchange,
      numberC1,
      numberC2,
      dHasExchange,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheAdditionsThatNeedAnExchange()}
        pdfTitle={translate.instructions.circleTheAdditionsThatNeedAnExchange()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'agZ',
  description: 'agZ',
  keywords: ['Add', 'Exchange'],
  schema: z
    .object({
      name1: nameSchema,
      name2: nameSchema,
      stickers1: z.number().int().min(101).max(549),
      stickers2: z.number().int().min(149).max(449)
    })
    .refine(val => val.name1 !== val.name2, 'name1 and name2 must be different.'),
  simpleGenerator: () => {
    const [name1, name2] = getRandomUniqueNames(2);

    const { stickers1, stickers2 } = rejectionSample(
      () => {
        const stickers1 = randomIntegerInclusive(101, 549);

        const stickers2 = randomIntegerInclusive(149, 449);
        return { stickers1, stickers2 };
      },
      ({ stickers1, stickers2 }) => numbersOnlyExchangeAt(stickers1, stickers2, 'tens')
    );

    return { name1, name2, stickers1, stickers2 };
  },

  Component: props => {
    const {
      question: { name1, name2, stickers1, stickers2 },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.char1HasNumStickersChar2HasNumStickersHowManyAltogether(
          name1,
          stickers1,
          name2,
          stickers2
        )}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(stickers1 + stickers2).toString()]}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

const Question5 = newQuestionContent({
  uid: 'ag0',
  description: 'ag0',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z.object({
    sums: z
      .array(
        z
          .array(z.number().int().min(100).max(999))
          .length(2)
          .refine(([left, right]) => {
            const exchanges = findExchanges(left, right);
            return exchanges.length <= 1 && exchanges.every(it => it === 0 || it === 1);
          }, 'Each sum must exchange at at most 1 column, and this must be across the 10s or the 100s.')
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const getSum = (exchange?: number): [left: number, right: number] =>
      getSumWithOnlyGivenExchanges(exchange !== undefined ? [exchange] : [], 3, {
        allowFewerDigits: false
      });
    const sums = [getSum(), getSum(0), getSum(1), getSum(getRandomFromArray([undefined, 0, 1]))];
    return { sums: shuffle(sums) };
  },
  Component: ({ question: { sums }, translate }) => {
    const correctAnswer = [[], [], []] as [hundreds: number[], tens: number[], none: number[]];
    sums.forEach(([left, right], sumIndex) => {
      const exchanges = findExchanges(left, right);
      const zoneIndex = exchanges.length === 0 ? 2 : exchanges[0] === 0 ? 1 : 0;
      correctAnswer[zoneIndex].push(sumIndex);
    });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsSortAdditions()}
        pdfTitle={translate.instructions.sortAdditionsPDF()}
        zoneNames={[
          translate.tableHeaders.exchangeAcrossA((100).toLocaleString()),
          translate.tableHeaders.exchangeAcrossA((10).toLocaleString()),
          translate.tableHeaders.noExchange()
        ]}
        testCorrect={correctAnswer}
        items={sums.map(([left, right], sumIndex) => ({
          value: sumIndex,
          component: `${left.toLocaleString()} ${ADD} ${right.toLocaleString()}`
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={4}
        questionHeight={1000}
        pdfItemVariant="tallRectangle"
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'ag1',
  description: 'ag1',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      topNumber: z.number().int().min(110).max(799),
      bottomNumber: z.number().int().min(110).max(799),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => numbersOnlyExchangeAt(val.topNumber, val.bottomNumber, 'tens'),
      'topNumber and bottomNumber must exchange at the tens only.'
    )
    .refine(
      val => val.missingOnes !== val.missingTens && val.missingOnes !== val.missingHundreds,
      'missingOnes must be in a different number to missingTens and missingHundreds.'
    )
    .refine(
      val => val.missingTens !== val.missingHundreds,
      'missingTens must be in a different number to missingHundreds.'
    ),
  simpleGenerator: () => {
    const topNumber = randomIntegerInclusive(110, 799, {
      constraint: x => x % 100 > 9 // Ensure topNumber ends in at least 10 so topNumber and bottomNumber can exchange at the tens
    });

    const bottomNumber = randomIntegerInclusive(110, 799, {
      constraint: x => numbersOnlyExchangeAt(topNumber, x, 'tens')
    });

    const [missingOnes, missingTens, missingHundreds] = shuffle([
      'top',
      'bottom',
      'answer'
    ] as const);

    return { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds };
  },

  Component: props => {
    const {
      question: { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds
    );
    const answerNumber = topNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={ADD}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwoNumbersAcrossA100',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
