import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF13DragLiquidInJug from '../../../../components/question/questionFormats/QF13DragLiquidInJug';
import { getRandomFromArray, randomIntegerInclusiveStep } from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import { getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bjW',
  description: 'bjW',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      intervals: numberEnum([2, 5, 10]),
      volume: z.number().int().min(2).max(100)
    })
    .refine(({ intervals, volume }) => volume % intervals === 0, 'volume should be at an interval'),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([2, 5, 10] as const);

    const capacity = intervals === 10 ? 100 : intervals === 5 ? 50 : 24;
    const volume = randomIntegerInclusiveStep(intervals, capacity - intervals, intervals);

    return { volume, intervals };
  },
  Component: props => {
    const {
      question: { volume, intervals },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchWaterIsInTheJug()}
        sentence={translate.ks1AnswerSentences.ansMl()}
        testCorrect={[volume.toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="row"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={intervals === 10 ? 100 : intervals === 5 ? 50 : 24}
            tickValue={intervals}
            unitsPerMajorTick={intervals === 5 ? 0 : 1}
            labelUnits="ml"
            liquidAmount={volume}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bjX',
  description: 'bjX',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      intervals: numberEnum([2, 10]),
      volume: z.number().int().min(1).max(100)
    })
    .refine(
      ({ intervals, volume }) => volume % (intervals / 2) === 0 && volume % intervals !== 0,
      'volume should be at a mid point of an interval'
    ),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([2, 10] as const);
    const step = intervals / 2;
    const capacity = intervals === 10 ? 100 : 24;
    const volume = randomIntegerInclusiveStep(step, capacity - step, step, {
      constraint: x => x % intervals !== 0
    });

    return { volume, intervals };
  },
  Component: props => {
    const {
      question: { volume, intervals },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchWaterIsInTheJug()}
        sentence={translate.ks1AnswerSentences.ansMl()}
        testCorrect={[volume.toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="row"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={intervals === 10 ? 100 : 24}
            tickValue={intervals}
            unitsPerMajorTick={1}
            labelUnits="ml"
            liquidAmount={volume}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bjY',
  description: 'bjY',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z.object({
    name: ks1NameSchema,
    amount: z.number().int().min(2).max(100),
    intervals: numberEnum([2, 5, 10]),
    containerTypeVariation: z.enum(['jug', 'labelledGlass'])
  }),
  simpleGenerator: () => {
    const name = getRandomKs1Name();
    const intervals = getRandomFromArray([2, 5, 10] as const);

    const capacity = intervals === 10 ? 100 : intervals === 5 ? 50 : 24;
    const amount = randomIntegerInclusiveStep(intervals, capacity - intervals, intervals);

    const containerTypeVariation = getRandomFromArray(['jug', 'labelledGlass'] as const);
    return { name, intervals, amount, containerTypeVariation };
  },
  Component: props => {
    const {
      question: { name, intervals, amount, containerTypeVariation },
      translate
    } = props;

    const title =
      containerTypeVariation === 'jug'
        ? 'xPoursYMlOfWaterIntoTheJug'
        : 'xPoursYMlOfWaterIntoTheGlass';

    const amountString = translate.units.numberOfMl(amount);

    return (
      <QF13DragLiquidInJug
        title={`${translate.ks1Instructions[title](
          name,
          amountString
        )}<br/>${translate.ks1Instructions.dragTheArrowToShowWhereTheWaterReaches()}`}
        pdfTitle={`${translate.ks1Instructions[title](
          name,
          amountString
        )}<br/>${translate.ks1PDFInstructions.drawALineToShowWhereTheWaterReaches()}`}
        testCorrect={amount}
        jugCapacity={intervals === 10 ? 100 : intervals === 5 ? 50 : 24}
        tickValue={intervals}
        liquidType="water"
        labelUnits="ml"
        unitsPerMajorTick={intervals === 5 ? 0 : 1}
        containerType={containerTypeVariation}
        drawLiquidLine
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInMillilitres',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
