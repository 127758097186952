import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { DIV, GREATER_THAN, LESS_THAN, MULT } from '../../../../constants';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { objectNames, objectSchema } from '../../../../utils/objects';
import { getObjectSvgName } from '../../../../utils/objectsImages';
import { numberEnum } from '../../../../utils/zod';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import Text from '../../../../components/typography/Text';
import { findFactorPairs } from '../../../../utils/factors';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { arraysHaveSameContents, range, sortNumberArray } from '../../../../utils/collections';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { isEqual, isLessThan } from '../../../../utils/matchers';
import { useMemo } from 'react';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aFI',
  description: 'aFI',
  keywords: ['Multiplication', 'Reasoning'],
  schema: z.object({
    groups: numberEnum([2, 3, 4, 5, 8, 10]),
    numberInGroups: z.number().int().min(2).max(5),
    object: objectSchema
  }),
  simpleGenerator: () => {
    const groups = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const numberInGroups = randomIntegerInclusive(2, 5, { constraint: x => x !== groups });
    const object = getRandomFromArray(objectNames);
    return { groups, numberInGroups, object };
  },
  Component: ({ question: { groups, numberInGroups, object }, translate }) => {
    const ans = (groups * numberInGroups).toString();

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeMultiplicationsToDescribeThePicture()}
        Content={({ dimens }) => (
          <ArrayOfObjects
            dimens={{
              width: dimens.width,
              height: dimens.height / 3
            }}
            rowStyle={{ columnGap: 4, rowGap: 4 }}
            rows={groups < 6 ? 1 : 2}
            columns={groups < 6 ? groups : groups / 2}
            customImage={
              <AssetSvg
                name={getObjectSvgName(object, numberInGroups)}
                height={dimens.height / 2}
              />
            }
          />
        )}
        sentences={[
          `${groups} ${MULT} ${numberInGroups} = <ans/>`,
          `${numberInGroups} ${MULT} ${groups} = <ans/>`
        ]}
        testCorrect={[[ans], [ans]]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aFJ',
  description: 'aFJ',
  keywords: ['Inequality', 'Compare', 'Multiplication', 'Division'],
  schema: z
    .object({
      number1: numberEnum([2, 3, 4, 5, 8, 10]),
      number2: numberEnum([2, 3, 4, 5, 8]),
      number3: numberEnum([3, 4, 5, 8, 10]),
      number4: numberEnum([2, 3, 4, 5, 8, 10]),
      number5: numberEnum([2, 3, 4, 5, 8, 10]),
      number6: numberEnum([2, 3, 4, 5, 8, 10]),
      number7: numberEnum([2, 3, 4, 5, 8, 10])
    })
    .refine(({ number2, number3 }) => number2 < number3),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const number2 = getRandomFromArray([2, 3, 4, 5, 8] as const);
    const number3 = getRandomFromArray([3, 4, 5, 8, 10].filter(x => x > number2)) as
      | 3
      | 4
      | 5
      | 8
      | 10;
    const number4 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const number5 = getRandomFromArray([2, 3, 4, 5, 8, 10].filter(x => x !== number4)) as
      | 2
      | 3
      | 4
      | 5
      | 8
      | 10;
    const number6 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const number7 = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);

    return { number1, number2, number3, number4, number5, number6, number7 };
  },

  Component: ({
    question: { number1, number2, number3, number4, number5, number6, number7 },
    translate
  }) => {
    const number8 = number6 * number7;
    const statements = shuffle(
      [
        {
          lhs: `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`,
          rhs: `${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()}`,
          correctAnswer: '<' // number2 < number3 so it will always be <
        },
        {
          lhs: `${number4.toLocaleString()} ${MULT} ${number5.toLocaleString()}`,
          rhs: `${number5.toLocaleString()} ${MULT} ${number4.toLocaleString()}`,
          correctAnswer: '=' // same numbers are multiplied so will always be =
        },
        {
          lhs: `${number8.toLocaleString()} ${DIV} ${number6.toLocaleString()}`,
          rhs: `${number8.toLocaleString()} ${DIV} ${number7.toLocaleString()}`,
          correctAnswer: lessThanGreaterThanOrEqualTo(number7, number6) // same divided, the smaller divisor will give a greater quotient
        }
      ],
      { random: seededRandom([number1, number2, number3, number4, number5, number6, number7]) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteCalculations()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteCalculations()}
        itemVariant="square"
        statements={statements.map(({ lhs, rhs, correctAnswer }) => ({
          correctAnswer,
          lhsComponent: (
            <Text variant="WRN400" style={{ textAlign: 'right', width: 200 }}>
              {lhs}
            </Text>
          ),
          rhsComponent: (
            <Text variant="WRN400" style={{ textAlign: 'left', width: 200 }}>
              {rhs}
            </Text>
          )
        }))}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aFK',
  description: 'aFK',
  keywords: ['Multiplication', 'Product'],
  schema: z.object({
    product: z.number().int().min(6).max(80),
    options: z.array(z.array(z.number().int().min(2).max(10)).length(2)).length(6)
  }),
  simpleGenerator: () => {
    const { product, options } = rejectionSample(
      () => {
        const acceptedFactors = [2, 3, 4, 5, 8, 10];

        const [number1, number2] = getRandomSubArrayFromArray(acceptedFactors, 2);

        const product = number1 * number2;

        const correctFactors = findFactorPairs(product).filter(
          ([x, y]) => acceptedFactors.includes(x) && acceptedFactors.includes(y)
        );

        const randomFactors = range(1, 6 - correctFactors.length).map(_ => {
          const factor1 = getRandomFromArray(acceptedFactors)!;
          const factor2 = randomIntegerInclusive(2, 10, {
            constraint: x => x * factor1 !== product
          });
          return [factor1, factor2];
        });

        const options = [...correctFactors, ...randomFactors];

        return { product, options };
      },
      ({ options }) => {
        const isNotUniqueOptions = options.map((arr1, i) => {
          return options.slice(i + 1).map(arr2 => {
            // Arrays have the same contents
            if (arraysHaveSameContents(arr1, arr2)) {
              return true;
            } else {
              return false;
            }
          });
        });

        // If isNotUniqueOptions length is < 1, rerun until no duplicates for e.g [5,5] -> [5,5]
        return (
          isNotUniqueOptions.map(option => option.some(option => option)).filter(option => option)
            .length < 1
        );
      }
    );

    return { product, options };
  },
  Component: ({ question: { product, options }, translate }) => {
    const items = shuffle(
      options.map(([num1, num2], idx) => ({
        value: idx,
        component: `${num1} ${MULT} ${num2}`,
        isCorrect: num1 * num2 === product
      })),
      { random: seededRandom([product, options]) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectCalsThatAreEqualToX(product)}
        items={items}
        testCorrect={items.filter(({ isCorrect }) => isCorrect).map(({ value }) => value)}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aFL',
  description: 'aFL',
  keywords: ['Compare', 'Product', 'Multiplication'],
  schema: z.object({
    multiplier: numberEnum([2, 3, 4, 5, 8, 10]),
    multiplicand: numberEnum([2, 3, 4, 5, 8, 10]),
    options: z.array(z.number().int().min(1).max(12)).length(6)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    type AcceptedFactorType = (2 | 3 | 4 | 5 | 8 | 10)[];
    const acceptedFactors: AcceptedFactorType = [2, 3, 4, 5, 8, 10];

    const [multiplier, multiplicand] = getRandomSubArrayFromArray(
      acceptedFactors as AcceptedFactorType,
      2
    );

    const options = randomUniqueIntegersInclusive(1, 12, 6, {
      constraint: x => x !== multiplicand && x !== multiplier
    });

    return { multiplier, multiplicand, options };
  },
  Component: ({ question: { multiplier, multiplicand, options }, translate }) => {
    const product = multiplier * multiplicand;

    const { items, less, greater } = useMemo(() => {
      const less: number[] = [];
      const greater: number[] = [];

      // Limit number of options due to space (allows the font to be a more readable size)
      const limitedOptions = getRandomSubArrayFromArray(options, 4);

      const items = limitedOptions.map((option, idx) => {
        const factor = getRandomBoolean() ? multiplier : multiplicand;
        factor * option < product ? less.push(idx) : greater.push(idx);
        return { component: `${option} ${MULT} ${factor}`, value: idx };
      });

      return { less, greater, items };
    }, [multiplier, multiplicand, options, product]);

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsSortThemIntoTable()}
        pdfTitle={translate.instructions.sortTheCardsIntoTheTable()}
        zoneNames={[
          `${translate.answerSentences.lessThanXMultY(multiplier, multiplicand)}`,
          `${translate.answerSentences.greaterThanXMultY(multiplier, multiplicand)}`
        ]}
        items={items}
        itemsMaxLines={1}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endMid"
        testCorrect={[less, greater]}
        questionHeight={800}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aFM',
  description: 'aFM',
  keywords: ['Order', 'Product', 'Multiplication'],
  schema: z.object({
    multiplier: numberEnum([2, 3, 4, 5, 8, 10]),
    multiplicands: z.array(z.number().int().min(2).max(12)).length(4),
    ascending: z.boolean()
  }),
  simpleGenerator: () => {
    const multiplier = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);
    const multiplicands = randomUniqueIntegersInclusive(2, 12, 4);
    const ascending = getRandomBoolean();
    return { multiplier, multiplicands, ascending };
  },
  Component: ({ question: { multiplier, multiplicands, ascending }, translate }) => {
    const title = ascending
      ? 'dragCardsToPlaceCalculationsInAscendingOrder'
      : 'dragCardsToPlaceCalculationsInDescendingOrder';

    const labels: ('Smallest' | 'Greatest')[] = ascending
      ? ['Smallest', 'Greatest']
      : ['Greatest', 'Smallest'];

    const items = useMemo(() => {
      return multiplicands.map(multiplicand => ({
        value: multiplicand * multiplier,
        component: getRandomBoolean()
          ? `${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`
          : `${multiplier.toLocaleString()} ${MULT} ${multiplicand.toLocaleString()}`
      }));
    }, [multiplier, multiplicands]);

    return (
      <QF4DragOrderVertical
        title={translate.instructions[title]()}
        pdfTitle={
          ascending
            ? translate.instructions.useCardsToPlaceCalculationsInAscendingOrder()
            : translate.instructions.useCardsToPlaceCalculationsInDescendingOrder()
        }
        topLabel={translate.keywords[labels[0]]()}
        bottomLabel={translate.keywords[labels[1]]()}
        items={items}
        testCorrect={sortNumberArray(
          items.map(({ value }) => value),
          ascending ? 'ascending' : 'descending'
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aFN',
  description: 'aFN',
  keywords: ['Inequality', 'Compare', 'Multiplication'],
  schema: z.object({
    multiplierA: numberEnum([2, 3, 4, 5, 8, 10]),
    multiplierB: numberEnum([2, 3, 4, 5, 8, 10]),
    multiplierC: numberEnum([2, 3, 4, 5, 8, 10]),
    multiplierD: numberEnum([2, 3, 4, 5, 8, 10]),
    multiplicandA: z.number().int().min(2).max(12),
    multiplicandB: z.number().int().min(2).max(12),
    multiplicandC: z.number().int().min(2).max(12),
    multiplicandD: z.number().int().min(2).max(12)
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const [multiplierA, multiplierB, multiplierC, multiplierD] = getRandomSubArrayFromArray(
      [2, 3, 4, 5, 8, 10] as const,
      4
    );
    const [multiplicandA, multiplicandB, multiplicandC, multiplicandD] =
      randomUniqueIntegersInclusive(2, 12, 4);
    return {
      multiplierA,
      multiplierB,
      multiplierC,
      multiplierD,
      multiplicandA,
      multiplicandB,
      multiplicandC,
      multiplicandD
    };
  },
  Component: ({
    question: {
      multiplierA,
      multiplierB,
      multiplierC,
      multiplierD,
      multiplicandA,
      multiplicandB,
      multiplicandC,
      multiplicandD
    },
    translate
  }) => {
    const items = shuffle(
      [
        {
          sentence: `${multiplierA} ${MULT} ${multiplicandA} = <ans/> ${MULT} ${multiplierA}`,
          correctAnswer: isEqual(multiplicandA)
        },
        {
          sentence: `${multiplierB} ${MULT} <ans/> ${LESS_THAN} ${multiplierB} ${MULT} ${multiplicandB}`,
          correctAnswer: isLessThan(multiplicandB)
        },
        {
          sentence: `${multiplierC} ${MULT} ${multiplicandC} ${GREATER_THAN} <ans/> ${MULT} ${multiplierC}`,
          correctAnswer: isLessThan(multiplicandC)
        },
        {
          sentence: `<ans/> ${MULT} ${multiplierD} ${LESS_THAN} ${multiplicandD} ${MULT} ${multiplierD}`,
          correctAnswer: isLessThan(multiplicandD)
        }
      ],
      {
        random: seededRandom([
          multiplierA,
          multiplierB,
          multiplierC,
          multiplierD,
          multiplicandA,
          multiplicandB,
          multiplicandC,
          multiplicandD
        ])
      }
    );
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        sentences={items.map(({ sentence }) => sentence)}
        inputMaxCharacters={2}
        containerStyle={{ alignItems: 'center' }}
        testCorrect={answer =>
          items.every(({ correctAnswer }, i) => correctAnswer(Number(answer[i])))
        }
        questionHeight={1100}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.allNumberSentencesMustBeCorrect()
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aFN2',
  description: 'aFN',
  keywords: ['Inequality', 'Compare', 'Multiplication'],
  schema: z.object({
    multiplierA: numberEnum([2, 3, 4, 5, 8, 10]),
    multiplierB: numberEnum([2, 3, 4, 5, 8, 10]),
    multiplicandA: z.number().int().min(2).max(12),
    multiplicandB: z.number().int().min(2).max(12),
    ansAndInequality: z
      .enum(['LESS_THAN_RIGHT', 'LESS_THAN_LEFT1', '=', 'LESS_THAN_LEFT2'])
      .array()
      .length(2)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [multiplierA, multiplierB] = getRandomSubArrayFromArray([2, 3, 4, 5, 8, 10] as const, 4);
    const [multiplicandA, multiplicandB] = randomUniqueIntegersInclusive(2, 12, 4);
    const ansAndInequality = getRandomSubArrayFromArray(
      ['LESS_THAN_RIGHT', 'LESS_THAN_LEFT1', '=', 'LESS_THAN_LEFT2'] as const,
      2
    );

    return {
      multiplierA,
      multiplierB,
      multiplicandA,
      multiplicandB,
      ansAndInequality
    };
  },
  Component: ({
    question: { multiplierA, multiplierB, multiplicandA, multiplicandB, ansAndInequality },
    translate
  }) => {
    const multipliers = [multiplierA, multiplierB];
    const multiplicand = [multiplicandA, multiplicandB];

    const equations = ansAndInequality.map((val, i) => ({
      lhs1: val === 'LESS_THAN_LEFT1' ? '<ans/>' : multipliers[i].toLocaleString(),
      lhs2:
        val === 'LESS_THAN_LEFT2'
          ? '<ans/>'
          : val === 'LESS_THAN_LEFT1'
          ? multipliers[i].toLocaleString()
          : multiplicand[i].toLocaleString(),
      sign: val === '=' ? val : val === 'LESS_THAN_RIGHT' ? GREATER_THAN : LESS_THAN,
      rhs1:
        val === 'LESS_THAN_LEFT1'
          ? multiplicand[i].toLocaleString()
          : val === 'LESS_THAN_LEFT2'
          ? multipliers[i].toLocaleString()
          : '<ans/>',
      rhs2:
        val === 'LESS_THAN_LEFT2'
          ? multiplicand[i].toLocaleString()
          : multipliers[i].toLocaleString(),
      isCorrect: val === '=' ? isEqual(multiplicand[i]) : isLessThan(multiplicand[i])
    }));

    const items = equations.map(({ lhs1, lhs2, sign, rhs1, rhs2, isCorrect }) => ({
      sentence: `${lhs1} ${MULT} ${lhs2} ${sign} ${rhs1} ${MULT} ${rhs2}`,
      correctAnswer: isCorrect
    }));
    9;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        sentences={items.map(({ sentence }) => sentence)}
        inputMaxCharacters={2}
        containerStyle={{ alignItems: 'center' }}
        testCorrect={answer =>
          items.every(({ correctAnswer }, i) => correctAnswer(Number(answer[i])))
        }
        questionHeight={900}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.allNumberSentencesMustBeCorrect()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReasoningAboutMultiplication',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
