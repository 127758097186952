import { View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import { useContext } from 'react';
import Svg from 'react-native-svg';
import Grid, { GridContext, GridSvgChildren } from './Coordinates/Grid';
import { sortNumberArray } from '../../../utils/collections';
import LollyStickShape, { LollyStick } from './Coordinates/LollyStickShape';
import { GridLine, GridPolygon } from '../../../utils/gridUtils';

type Props = {
  givenShape?: boolean[][];
  points?: [number, number][];
  noGrid?: boolean;
  /**
   * optional fill override. If no fill is required then set to be null
   */
  color?: string | null;
  /**
   * Dimensions
   */
  dimens: Dimens;
  /**
   * Label to assign to the top-right Cell.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  cellSizeLabel?: string;
  isLollySticks?: boolean;
  rotation?: 0 | 45 | 315;
  xMax?: number;
  yMax?: number;
};

function Border({
  points,
  isLollySticks = false,
  noGrid = false
}: {
  points: [number, number][];
  isLollySticks?: boolean;
  noGrid?: boolean;
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  // Assume y axis has same scale factor
  const gridWidth = mathToSvgX(1) - mathToSvgX(0);
  const [x, y] = points[0];
  const [nextX, nextY] = points[1];
  const dy = nextY - y;
  const dx = nextX - x;
  const direction = (3 * Math.PI) / 2 - Math.atan2(dy, dx);
  return isLollySticks ? (
    <LollyStick
      startX={mathToSvgX(x - Math.sin(direction))}
      startY={mathToSvgY(y - Math.cos(direction))}
      length={gridWidth}
      direction={direction}
    />
  ) : (
    <GridLine points={points} noGrid={noGrid} />
  );
}

function getShapeSvg(givenShape: boolean[][], color?: string | null, isLollySticks?: boolean) {
  return (
    <GridSvgChildren>
      {givenShape &&
        givenShape.map((row, rowIndex) =>
          row.map((item, columnIndex) => {
            const lines = [];
            if (item && !row[columnIndex + 1]) {
              // vertical line to the right
              lines.push(
                <Border
                  key={`verticalRight_${rowIndex}_${columnIndex}`}
                  points={[
                    [columnIndex + 1, rowIndex],
                    [columnIndex + 1, rowIndex + 1]
                  ]}
                  isLollySticks={isLollySticks}
                />
              );
            }
            if (item && !row[columnIndex - 1]) {
              // vertical line to the left
              lines.push(
                <Border
                  key={`verticalLeft_${rowIndex}_${columnIndex}`}
                  points={[
                    [columnIndex, rowIndex],
                    [columnIndex, rowIndex + 1]
                  ]}
                  isLollySticks={isLollySticks}
                />
              );
            }
            if (item && (!givenShape[rowIndex - 1] || !givenShape[rowIndex - 1][columnIndex])) {
              // horizontal line at bottom
              lines.push(
                <Border
                  key={`horizontalBottom_${rowIndex}_${columnIndex}`}
                  points={[
                    [columnIndex, rowIndex],
                    [columnIndex + 1, rowIndex]
                  ]}
                  isLollySticks={isLollySticks}
                />
              );
            }
            if (item && (!givenShape[rowIndex + 1] || !givenShape[rowIndex + 1][columnIndex])) {
              // horizontal line at top
              lines.push(
                <Border
                  key={`horizontalTop_${rowIndex}_${columnIndex}`}
                  points={[
                    [columnIndex, rowIndex + 1],
                    [columnIndex + 1, rowIndex + 1]
                  ]}
                  isLollySticks={isLollySticks}
                />
              );
            }
            if (item) {
              color !== null &&
                !isLollySticks &&
                lines.push(
                  <GridPolygon
                    key={`Fill_${rowIndex}_${columnIndex}`}
                    color={color}
                    points={[
                      [columnIndex, rowIndex],
                      [columnIndex + 1, rowIndex],
                      [columnIndex + 1, rowIndex + 1],
                      [columnIndex, rowIndex + 1]
                    ]}
                  />
                );
            }
            return lines;
          })
        )}
    </GridSvgChildren>
  );
}

export const DisplayShapeOnGridWithBorder = ({
  givenShape,
  points,
  noGrid = false,
  color,
  dimens,
  cellSizeLabel,
  isLollySticks,
  rotation,
  xMax: xMaxProp,
  yMax: yMaxProp
}: Props) => {
  if (givenShape === undefined && points === undefined) {
    throw new Error('shape must be defined either through `givenShape` or `points`');
  }

  // work out max grid values
  let xMax = 0;
  let yMax = 0;
  if (givenShape) {
    xMax = givenShape[0].length;
    yMax = givenShape.length;
  } else if (points) {
    xMax = sortNumberArray(
      points.map(point => point[0]),
      'descending'
    )[0];
    yMax = sortNumberArray(
      points.map(point => point[1]),
      'descending'
    )[0];
  }

  let shape;
  if (givenShape) {
    shape = getShapeSvg(givenShape, color, isLollySticks);
  } else if (points) {
    shape = isLollySticks ? (
      <LollyStickShape vertices={[...points, points[0]]} />
    ) : (
      <Svg height={dimens.height}>
        <GridPolygon points={points} showBorder color={isLollySticks ? 'none' : color} />
      </Svg>
    );
  }

  // Now return grid
  return (
    <View style={rotation ? { transform: [{ rotate: `${rotation}deg` }] } : undefined}>
      <Grid
        xMin={-1}
        xMax={xMaxProp ?? xMax + 1}
        yMin={-1}
        yMax={yMaxProp ?? yMax + 1}
        xAxisLabel={null}
        yAxisLabel={null}
        xAxis={null}
        yAxis={null}
        hideContinuationLines
        hideGridLines={noGrid}
        squareGrid
        sizingMethod="dimens"
        width={dimens.width}
        height={dimens.height}
        children={shape}
        cellSizeLabel={cellSizeLabel}
      />
    </View>
  );
};
