import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import Text from '../../../../components/typography/Text';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import { getNumeralOrdinal, getWordOrdinal } from '../../../../utils/ordinals';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const shapeOptions = [
  'Arrow',
  'Equilateral',
  'Isosceles',
  'Rectangle',
  'Square',
  'Star',
  'Sphere',
  'Cuboid',
  'Cone',
  'Square Based Pyramid',
  'Cube',
  'Cylinder'
] as const;
type Shape = (typeof shapeOptions)[number];

const svgNames: Record<Shape, SvgName[]> = {
  Arrow: [
    'SymmetricalShapes/horizontal2_pink',
    'SymmetricalShapes/horizontal2_purple',
    'SymmetricalShapes/horizontal2_green',
    'SymmetricalShapes/horizontal2_yellow'
  ],
  Equilateral: [
    'Equilateral_triangles/triangle_equal_pink',
    'Equilateral_triangles/triangle_equal_purple',
    'Equilateral_triangles/triangle_equal_green',
    'Equilateral_triangles/triangle_equal_yellow'
  ],
  Isosceles: [
    'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
    'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
    'Isosceles_triangles_narrow/triangle_isos_narrow_green',
    'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
  ],
  Square: [
    'Square/square_pink',
    'Square/square_purple',
    'Square/square_green',
    'Square/square_yellow'
  ],
  Rectangle: [
    'Rectangle/rectangle_pink',
    'Rectangle/rectangle_purple',
    'Rectangle/rectangle_green',
    'Rectangle/rectangle_yellow'
  ],
  Star: ['Star_pink', 'Star_purple', 'Star_green', 'Star_yellow'],
  Sphere: [
    '3D_shapes_full_colors/Spheres/Sphere_pink',
    '3D_shapes_full_colors/Spheres/Sphere_purple',
    '3D_shapes_full_colors/Spheres/Sphere_green',
    '3D_shapes_full_colors/Spheres/Sphere_yellow'
  ],
  Cuboid: [
    '3D_shapes_full_colors/Cuboids/Cuboid_pink',
    '3D_shapes_full_colors/Cuboids/Cuboid_purple',
    '3D_shapes_full_colors/Cuboids/Cuboid_green',
    '3D_shapes_full_colors/Cuboids/Cuboid_yellow'
  ],
  Cone: [
    '3D_shapes_full_colors/Cones/Cone_pink',
    '3D_shapes_full_colors/Cones/Cone_purple',
    '3D_shapes_full_colors/Cones/Cone_green',
    '3D_shapes_full_colors/Cones/Cone_yellow'
  ],
  'Square Based Pyramid': [
    '3D_shapes_full_colors/Square_pyramids/Square_pyramid_pink',
    '3D_shapes_full_colors/Square_pyramids/Square_pyramid_purple',
    '3D_shapes_full_colors/Square_pyramids/Square_pyramid_green',
    '3D_shapes_full_colors/Square_pyramids/Square_pyramid_yellow'
  ],
  Cube: [
    '3D_shapes_full_colors/Cubes/Cube_pink',
    '3D_shapes_full_colors/Cubes/Cube_purple',
    '3D_shapes_full_colors/Cubes/Cube_green',
    '3D_shapes_full_colors/Cubes/Cube_yellow'
  ],
  Cylinder: [
    '3D_shapes_full_colors/Cylinders/Cylinder_pink',
    '3D_shapes_full_colors/Cylinders/Cylinder_purple',
    '3D_shapes_full_colors/Cylinders/Cylinder_green',
    '3D_shapes_full_colors/Cylinders/Cylinder_yellow'
  ]
};

const Question1 = newQuestionContent({
  uid: 'beU',
  description: 'beU',
  keywords: ['Ordinal', 'First', 'Second', 'Third', 'Fourth', 'Last'],
  schema: z.object({
    shapes: z.enum(shapeOptions).array(),
    colorIndexes: z.number().int().min(0).max(3).array(),
    answerIndex: z.number().int().min(0).max(4),
    useFirstLast: z.boolean()
  }),
  simpleGenerator: () => {
    const numOfShapes = randomIntegerInclusive(3, 4);
    const colorIndexes = randomUniqueIntegersInclusive(0, 3, numOfShapes);
    const shapes = getRandomSubArrayFromArray(shapeOptions, numOfShapes);
    const answerIndex = randomIntegerInclusive(0, numOfShapes - 1);

    const useFirstLast =
      answerIndex === numOfShapes - 1 || answerIndex === 0 ? getRandomBoolean() : false;

    return {
      colorIndexes,
      shapes,
      answerIndex,
      useFirstLast
    };
  },
  Component: ({ question, translate, locale }) => {
    const { colorIndexes, shapes, answerIndex, useFirstLast } = question;

    const ordinal = useFirstLast
      ? answerIndex === 0
        ? translate.ordinals.first()
        : translate.ks1MiscStrings.last()
      : getNumeralOrdinal(answerIndex + 1, locale, translate);

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheOrdinalShape(ordinal)}
        pdfTitle={translate.ks1PDFInstructions.tickTheOrdinalShape(ordinal)}
        renderItems={shapes.map((val, idx) => ({
          value: idx,
          component: <AssetSvg name={svgNames[val][colorIndexes[idx]]} height={200} width={200} />
        }))}
        numItems={shapes.length as 3 | 4}
        itemLayout="row"
        innerContainerStyle={{ alignItems: 'center' }}
        itemStyle={{ height: 230 }}
        testCorrect={[answerIndex]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'beV',
  description: 'beV',
  keywords: ['Ordinal', 'First', 'Second', 'Third', 'Fourth', 'Last', '2-D shapes'],
  schema: z.object({
    shapes: z.enum(['Arrow', 'Equilateral', 'Isosceles', 'Rectangle', 'Square', 'Star']).array(),
    colorIndexes: z.number().int().min(0).max(3).array(),
    answerIndex: z.number().int().min(0).max(4),
    useLast: z.boolean(),
    isVariation1: z.boolean()
  }),
  simpleGenerator: () => {
    const colorIndexes = shuffle(countRange(4));
    const shapes = [
      getRandomFromArray(['Equilateral', 'Isosceles'] as const),
      getRandomFromArray(['Rectangle', 'Square'] as const),
      'Arrow' as const,
      'Star' as const
    ];
    const answerIndex = randomIntegerInclusive(0, 3);

    const useLast = answerIndex === 3 ? getRandomBoolean() : false;

    const isVariation1 = getRandomBoolean();

    return {
      colorIndexes,
      shapes,
      answerIndex,
      useLast,
      isVariation1
    };
  },
  Component: ({ question, translate, locale }) => {
    const { colorIndexes, shapes, answerIndex, useLast, isVariation1 } = question;

    const ordinal = useLast
      ? translate.ks1MiscStrings.last()
      : getNumeralOrdinal(answerIndex + 1, locale, translate);

    const shapeTranslation = (
      shape: 'Arrow' | 'Equilateral' | 'Isosceles' | 'Rectangle' | 'Square' | 'Star'
    ) => {
      switch (shape) {
        case 'Square':
          return translate.shapes.squares(1);
        case 'Arrow':
          return translate.shapes.arrow();
        case 'Equilateral':
        case 'Isosceles':
          return translate.shapes.triangles(1);
        case 'Rectangle':
          return translate.shapes.rectangles(1);
        case 'Star':
          return translate.shapes.stars(1);
      }
    };

    const random = seededRandom(question);

    const items = isVariation1
      ? shuffle(
          shapes.map(val => ({
            value: val,
            component: <Text variant="WRN700">{shapeTranslation(val)}</Text>
          })),
          { random }
        )
      : shuffle(
          countRange(4).map(val => ({
            value: val,
            component: <Text variant="WRN700">{getWordOrdinal(val + 1, translate)}</Text>
          })),
          { random }
        );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        testCorrect={[isVariation1 ? shapes[answerIndex] : answerIndex]}
        Content={({ dimens }) => (
          <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'space-evenly' }}>
            {shapes.map((val, idx) => (
              <AssetSvg
                key={idx}
                name={svgNames[val][colorIndexes[idx]]}
                height={dimens.height * 0.8}
                width={dimens.width / 5}
              />
            ))}
          </View>
        )}
        items={items}
        sentencesStyle={{ alignItems: 'flex-start' }}
        itemVariant="rectangle"
        pdfLayout="itemsTop"
        pdfItemVariant="rectangle"
        questionHeight={900}
        sentence={
          isVariation1
            ? translate.ks1AnswerSentences.theOrdinalShapeIsAAns(ordinal)
            : translate.ks1AnswerSentences.theAnsShapeIsAShape(
                shapeTranslation(shapes[answerIndex])
              )
        }
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'beW',
  description: 'beW',
  keywords: ['Ordinal', 'First', 'Second', 'Third', 'Fourth', 'Before', 'After'],
  schema: z.object({
    ordinal: z.number().int().min(1).max(10),
    options: z.number().int().min(1).max(20).array().length(4).refine(arrayHasNoDuplicates),
    variation: numberEnum([0, 1, 2, 3]),
    ansIsNumeral: z.boolean(),
    sentenceIsNumeral: z.boolean()
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray([0, 1, 2, 3] as const);
    const min = [0, 3].includes(variation) ? 1 : 2;
    const max = [0, 3].includes(variation) ? 9 : 10;
    const ordinal = randomIntegerInclusive(min, max);

    const options = (() => {
      switch (variation) {
        case 0:
        case 3: {
          const answer = ordinal + 1;
          const incorrectOptions = [ordinal - 1, ordinal - 2, ordinal + 2, ordinal + 10].filter(
            val => val > 0
          );
          if (incorrectOptions.length < 3) incorrectOptions.push(ordinal + 3);
          return [answer, ...getRandomSubArrayFromArray(incorrectOptions, 3)];
        }
        case 1:
        case 2: {
          const answer = ordinal - 1;
          const incorrectOptions = [ordinal + 1, ordinal - 2, ordinal + 2, ordinal + 10].filter(
            val => val > 0
          );
          if (incorrectOptions.length < 3) incorrectOptions.push(ordinal + 3);
          return [answer, ...getRandomSubArrayFromArray(incorrectOptions, 3)];
        }
      }
    })();

    const ansIsNumeral = getRandomBoolean();
    const sentenceIsNumeral = getRandomBoolean();

    return {
      ordinal,
      options,
      variation,
      ansIsNumeral,
      sentenceIsNumeral
    };
  },
  Component: ({ question, translate, locale }) => {
    const { ordinal, options, variation, ansIsNumeral, sentenceIsNumeral } = question;

    const random = seededRandom(question);

    const items = shuffle(
      options.map(val => ({
        value: val,
        component: (
          <Text variant="WRN700">
            {ansIsNumeral
              ? getNumeralOrdinal(val, locale, translate)
              : getWordOrdinal(val, translate)}
          </Text>
        )
      })),
      { random }
    );

    const isBefore = [1, 2].includes(variation);
    const sentenceOrdinal = sentenceIsNumeral
      ? getNumeralOrdinal(ordinal, locale, translate)
      : getWordOrdinal(ordinal, translate);

    const sentence = (() => {
      switch (variation) {
        case 0:
          return translate.ks1AnswerSentences.thePositionAfterOrdinalIsAns(sentenceOrdinal);
        case 1:
          return translate.ks1AnswerSentences.thePositionBeforeOrdinalIsAns(sentenceOrdinal);
        case 2:
          return translate.ks1AnswerSentences.thePositionAfterAnsIsOrdinal(sentenceOrdinal);
        case 3:
          return translate.ks1AnswerSentences.thePositionBeforeAnsIsOrdinal(sentenceOrdinal);
      }
    })();

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        testCorrect={[isBefore ? ordinal - 1 : ordinal + 1]}
        items={items}
        sentencesStyle={{ alignItems: 'flex-start' }}
        pdfSentencesStyle={{ alignItems: 'flex-start' }}
        pdfLayout="itemsTop"
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        actionPanelVariant="endWide"
        sentence={sentence}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrdinalNumbers',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
