import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { ADD, SUB } from '../../../../constants';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';
import QF35ContentAndSentencesDragGridLayout from '../../../../components/question/questionFormats/QF35ContentAndSentencesDragGridLayout';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { findExchanges } from '../../../../utils/exchanges';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aiG',
  description: 'aiG',
  keywords: ['Inverse', 'Fact family', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(100).max(8999),
    number2: z.number().int().min(100).max(8999),
    number3: z.number().int().min(200).max(9999)
  }),
  simpleGenerator: () => {
    //$var1 and $var2 are both (i) multiple of 10 <100 or (ii) multiples of 100 <1000 (equal chance). $var3=$var1+$var2 no exchange. $var3<1000
    const number1 = randomIntegerInclusive(100, 8899);
    const number2 = randomIntegerInclusive(100, 8999 - number1);
    const number3 = number1 + number2;

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const numbers = [[number3], [number1, number2]];

    return (
      <QF35ContentAndSentencesDragGridLayout
        moveOrCopy="copy"
        title={translate.instructions.dragCardsMakeFourDifferentNumberSentences()}
        pdfTitle={translate.instructions.useCardsMakeFourDifferentNumberSentences()}
        items={[number1, number2, number3]}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            total={number3}
            dimens={{ height: dimens.height, width: dimens.width / 2 }}
            oneFontSize
          />
        )}
        sentences={[
          `${number1.toLocaleString()} ${ADD} <ans /> = <ans />`,
          `<ans /> ${SUB} ${number1.toLocaleString()} = <ans />`,
          `<ans /> ${ADD} <ans /> = <ans />`,
          `<ans /> ${SUB} <ans /> = <ans />`
        ]}
        testCorrect={[
          [number2, number3],
          [number3, number2],
          [number2, number1, number3],
          [number3, number2, number1]
        ]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});
export const aiG = Question1;

const Question2 = newQuestionContent({
  uid: 'aiH',
  description: 'aiH',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Match'],
  schema: z
    .object({
      number1: z.number().int().min(500).max(5000).multipleOf(100),
      number2: z.number().int().min(500).max(5000).multipleOf(100),
      isAdd: z.boolean(),
      incorrectAnswerIndexes: z.number().int().min(0).max(5).array().length(3)
    })
    .refine(
      ({ isAdd, number1, number2 }) =>
        arrayHasNoDuplicates([number1, number2, isAdd ? number1 + number2 : number1 - number2]),
      'number1, number2 and number1 + or - number2 must be different.'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const isAdd = getRandomBoolean();
        const numbers = randomUniqueIntegersInclusiveStep(500, 5000, 100, 2);
        // make sure number 1 is the biggest if a subtraction so answer is positive
        const [number1, number2] = isAdd ? numbers : sortNumberArray(numbers, 'descending');
        const incorrectAnswerIndexes = randomUniqueIntegersInclusive(0, 5, 3);
        return { isAdd, number1, number2, incorrectAnswerIndexes };
      },
      ({ isAdd, number1, number2 }) =>
        arrayHasNoDuplicates([number1, number2, isAdd ? number1 + number2 : number1 - number2])
    ),
  Component: props => {
    const {
      question: { isAdd, number1, number2, incorrectAnswerIndexes },
      translate
    } = props;

    const answer = isAdd ? number1 + number2 : number1 - number2;
    const correctOp = isAdd ? ADD : SUB;
    const incorrectOp = isAdd ? SUB : ADD;
    const answerOptions = [
      `${answer.toLocaleString()} ${correctOp} ${number2.toLocaleString()} = ${Math.abs(
        isAdd ? answer + number2 : answer - number2
      ).toLocaleString()}`,
      `${number1.toLocaleString()} ${incorrectOp} ${number2.toLocaleString()} = ${answer.toLocaleString()}`,
      `${number2.toLocaleString()} ${correctOp} ${number1.toLocaleString()} = ${answer.toLocaleString()}`,
      `${number1.toLocaleString()} ${incorrectOp} ${number2.toLocaleString()} = ${Math.abs(
        isAdd ? number1 - number2 : number1 + number2
      ).toLocaleString()}`,
      `${answer.toLocaleString()} ${correctOp} ${number1.toLocaleString()} = ${Math.abs(
        isAdd ? answer + number1 : answer - number1
      ).toLocaleString()}`,
      `${number1.toLocaleString()} ${incorrectOp} ${answer.toLocaleString()} = ${Math.abs(
        isAdd ? number1 - answer : number1 + answer
      ).toLocaleString()}`
    ].filter((_val, i) => incorrectAnswerIndexes.includes(i));

    const items = shuffle(
      [
        {
          string: `${answer.toLocaleString()} ${incorrectOp} ${number2.toLocaleString()} = ${number1.toLocaleString()}`,
          value: 'A'
        },
        ...answerOptions.map((val, i) => ({
          string: val,
          value: ['B', 'C', 'D'][i]
        }))
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectInverseCalculation(
          `${number1.toLocaleString()} ${correctOp} ${number2.toLocaleString()} = ${answer.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.circleInverseCalculation(
          `${number1.toLocaleString()} ${correctOp} ${number2.toLocaleString()} = ${answer.toLocaleString()}`
        )}
        testCorrect={['A']}
        numItems={4}
        renderItems={items.map(({ string, value }) => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {string}
            </Text>
          )
        }))}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

// Question3 exported to Q ajc
const Question3 = newQuestionContent({
  uid: 'aiI',
  description: 'aiI',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Check'],
  schema: z
    .object({
      number1: z.number().int().min(100).max(999),
      number2: z.number().int().min(1000).max(9999),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val => val.number1 + val.number2 < 10000,
      'number1 plus number2 must be less than 10000'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(100, 999);
    const number2 = randomIntegerInclusive(1000, 9999 - number1);
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return {
      addOrSubtract,
      number1,
      number2
    };
  },

  Component: props => {
    const {
      question: { addOrSubtract, number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const questionString =
      addOrSubtract === ADD
        ? `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${number3.toLocaleString()}`
        : `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()} = ${number2.toLocaleString()}`;

    const random = seededRandom(props.question);

    const answers =
      addOrSubtract === ADD
        ? shuffle(
            [
              `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
              `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()}`,
              `${number1.toLocaleString()} ${SUB} ${number3.toLocaleString()}`,
              `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()}`
            ],
            { random }
          )
        : shuffle(
            [
              `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()}`,
              `${number2.toLocaleString()} ${ADD} ${number1.toLocaleString()}`,
              `${number3.toLocaleString()} ${ADD} ${number2.toLocaleString()}`,
              `${number1.toLocaleString()} ${ADD} ${number3.toLocaleString()}`
            ],
            { random }
          );

    const correct =
      addOrSubtract === ADD
        ? [
            `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
            `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()}`
          ]
        : [
            `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()}`,
            `${number2.toLocaleString()} ${ADD} ${number1.toLocaleString()}`
          ];

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectCalcsThatCanBeUsedToCheckCalc(questionString)}
        pdfTitle={translate.instructions.circleCalcsThatCanBeUsedToCheckCalc(questionString)}
        testCorrect={correct}
        multiSelect
        items={answers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});
export const aiI = Question3;

// Question4 exported to Q ajd
const Question4 = newQuestionContent({
  uid: 'aiJ',
  description: 'aiJ',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Check'],
  schema: z
    .object({
      name: nameSchema,
      number3: z.number().int().min(3000).max(9000).multipleOf(1000),
      number1: z.number().int().min(1001).max(7999),
      addOrSubtract: z.enum(['add', 'subtract'])
    })
    .refine(
      val => val.number1 <= val.number3 - 1001,
      'number1 must be at least 1001 less than number3.'
    )
    .refine(val => val.number1 % 1000 !== 0, 'number1 cannot be a multiple of 1,000'),
  simpleGenerator: () => {
    const name = getRandomName();

    const number3 = randomIntegerInclusiveStep(3000, 9000, 1000);

    const number1 = randomIntegerInclusive(1001, number3 - 1001, {
      constraint: x => x % 1000 !== 0
    });

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    return { name, number1, number3, addOrSubtract };
  },

  Component: props => {
    const {
      question: { name, number1, number3, addOrSubtract },
      translate
    } = props;

    const number2 = number3 - number1;

    return (
      <QF2AnswerBoxOneSentence
        title={
          addOrSubtract === 'add'
            ? translate.instructions.characterWorksOutAddition({
                name,
                leftNum: number1.toLocaleString(),
                rightNum: number2.toLocaleString(),
                result: number3.toLocaleString()
              })
            : translate.instructions.characterWorksOutSubtraction({
                name,
                leftNum: number3.toLocaleString(),
                rightNum: number2.toLocaleString(),
                result: number1.toLocaleString()
              })
        }
        testCorrect={answer =>
          addOrSubtract === 'add'
            ? answer[0] === number3.toString() &&
              (answer[1] === number1.toString() || answer[1] === number2.toString())
            : (answer[0] === number1.toString() && answer[1] === number2.toString()) ||
              (answer[0] === number2.toString() && answer[1] === number1.toString())
        }
        inputMaxCharacters={4}
        sentence={addOrSubtract === 'add' ? `<ans/> ${SUB} <ans/>` : `<ans/> ${ADD} <ans/>`}
        customMarkSchemeAnswer={{
          answersToDisplay:
            addOrSubtract === 'add'
              ? [number3.toLocaleString(), number1.toLocaleString()]
              : [number1.toLocaleString(), number2.toLocaleString()],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
        questionHeight={400}
      />
    );
  },
  questionHeight: 400
});
export const aiJ = Question4;

// Question5 exported to Q aje
const Question5 = newQuestionContent({
  uid: 'aiK',
  description: 'aiK',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Check'],
  schema: z.object({
    topAddend: z.number().int().min(1).max(9998),
    bottomAddend: z.number().int().min(1).max(9998),
    totalToDisplay: z.number().int().min(2).max(9999)
  }),
  simpleGenerator: () => {
    const { topAddend, bottomAddend } = rejectionSample(
      () => {
        const topAddend = randomIntegerInclusive(1000, 9899);
        const bottomAddend = randomIntegerInclusive(100, 8999);
        return { topAddend, bottomAddend };
      },
      // Only permit them if they total less than 10,000 and have 0 or 1 exchanges.
      ({ topAddend, bottomAddend }) =>
        topAddend + bottomAddend < 10000 && findExchanges(topAddend, bottomAddend).length <= 1
    );

    const actualTotal = topAddend + bottomAddend;

    const incorrectAnswerDifference = getRandomFromArray(
      [-100, -10, 10, 100].filter(num => num + actualTotal < 10000 && num + actualTotal > 0)
    );

    const totalToDisplay = actualTotal + getRandomFromArray([0, incorrectAnswerDifference ?? 0]);
    return { topAddend, bottomAddend, totalToDisplay };
  },
  Component: ({ question: { topAddend, bottomAddend, totalToDisplay }, translate }) => {
    const actualTotal = topAddend + bottomAddend;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.useInverseOperationToWorkOutWhetherCalcIsCorrect()}
        pdfTitle={translate.instructions.useInverseOperationToWorkOutWhetherCalcIsCorrectPDF()}
        correctAnswer={actualTotal === totalToDisplay}
        trueButtonLabel={translate.misc.Correct()}
        falseButtonLabel={translate.misc.Incorrect()}
        content={
          <Text variant="WRN400">{`${totalToDisplay.toLocaleString()} ${SUB} ${bottomAddend.toLocaleString()} = ${topAddend.toLocaleString()}`}</Text>
        }
      />
    );
  },
  questionHeight: 800
});
export const aiK = Question5;

// Question6 exported to Q ajf
const Question6 = newQuestionContent({
  uid: 'aiL',
  description: 'aiL',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Check'],
  schema: z.object({
    result: z.number().int().min(1).max(9998),
    bottomNumber: z.number().int().min(1).max(9998),
    totalToDisplay: z.number().int().min(2).max(9999)
  }),
  simpleGenerator: () => {
    const { result, bottomNumber } = rejectionSample(
      () => {
        const result = randomIntegerInclusive(1000, 9899);
        const bottomNumber = randomIntegerInclusive(100, 8999);
        return { result, bottomNumber };
      },
      // Only permit them if they total less than 10,000 and have 0 or 1 exchanges.
      ({ result, bottomNumber }) =>
        result + bottomNumber < 10000 && findExchanges(result, bottomNumber).length <= 1
    );

    const actualTotal = result + bottomNumber;

    const incorrectAnswerDifference = getRandomFromArray(
      [-100, -10, 10, 100].filter(num => num + actualTotal < 10000 && num + actualTotal > 0)
    );

    const totalToDisplay = actualTotal + getRandomFromArray([0, incorrectAnswerDifference ?? 0]);

    return { result, bottomNumber, totalToDisplay };
  },
  Component: ({ question: { result, bottomNumber, totalToDisplay }, translate }) => {
    const actualTotal = result + bottomNumber;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.useInverseOperationToWorkOutWhetherCalcIsCorrect()}
        pdfTitle={translate.instructions.useInverseOperationToWorkOutWhetherCalcIsCorrectPDF()}
        correctAnswer={actualTotal === totalToDisplay}
        trueButtonLabel={translate.misc.Correct()}
        falseButtonLabel={translate.misc.Incorrect()}
        content={
          <Text variant="WRN400">{`${result.toLocaleString()} ${ADD} ${bottomNumber.toLocaleString()} = ${totalToDisplay.toLocaleString()}`}</Text>
        }
      />
    );
  },
  questionHeight: 900
});
export const aiL = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CheckingStrategies',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
