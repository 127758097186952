import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import { barModelColors } from '../../../../theme/colors';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { findFactors } from '../../../../utils/factors';
import QF20aBarModelInteractive from '../../../../components/question/questionFormats/QF20aBarModelInteractive';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { FractionsWithArrowsWithState } from '../../../../components/question/representations/ValuesWithArrows/FractionsWithArrows';
import { DIV, MULT } from '../../../../constants';
import { isEqual } from '../../../../utils/matchers';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aoS',
  description: 'aoS',
  keywords: ['Unit fraction', 'Equivalent'],
  schema: z
    .object({
      barModel1Denominator: z.number().int().min(2).max(6),
      multiplier: numberEnum([2, 3, 4]),
      numeratorColor: z.string()
    })
    .refine(
      val => val.barModel1Denominator * val.multiplier <= 20,
      'barModel1Denominator multiplied by the multiplier must be less or equal to 20'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const multiplier = getRandomFromArray([2, 3, 4] as const);
    const barModel1Denominator = randomIntegerInclusive(2, 6, {
      constraint: x => x * multiplier <= 20
    });

    const numeratorColor = getRandomFromArray(Object.values(barModelColors)) as string;

    return { barModel1Denominator, multiplier, numeratorColor };
  },
  Component: props => {
    const {
      question: { barModel1Denominator, multiplier, numeratorColor },
      translate
    } = props;

    const barModel2Denominator = barModel1Denominator * multiplier;

    // Bar Model 1
    const numeratorColorArray1 = filledArray(numeratorColor, 1);
    const remainder1 = filledArray('white', barModel1Denominator - 1);

    // Bar Model 2
    const barModel2Numerator = barModel2Denominator / barModel1Denominator;
    const numeratorColorArray2 = filledArray(numeratorColor, barModel2Numerator);
    const remainder2 = filledArray('white', barModel2Denominator - barModel2Numerator);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1200}
        sentence={`<frac n='1' d='${barModel1Denominator}'/> = <frac nAns='' dAns=''/>`}
        title={translate.instructions.useBarModelsToFindTheEquivalentFraction()}
        testCorrect={[barModel2Numerator.toString(), barModel2Denominator.toString()]}
        inputMaxCharacters={2}
        textStyle={{ fontSize: 40 }}
        Content={({ dimens }) => (
          <>
            <ShadedFractionBarModel
              totalSubSections={barModel1Denominator}
              width={dimens.width}
              height={dimens.height / 3}
              customColorMap={customColorMap[0]}
            />
            <ShadedFractionBarModel
              totalSubSections={barModel2Denominator}
              width={dimens.width}
              height={dimens.height / 3}
              customColorMap={customColorMap[1]}
            />
          </>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aoT',
  description: 'aoT',
  keywords: ['Unit fraction', 'Equivalent'],
  schema: z
    .object({
      rectangleWidth: z.number().int().min(2).max(10),
      rectangleHeight: z.number().int().min(2).max(8),
      numerator: z.number().int(),
      denominator: z.number().int()
    })
    .refine(
      val => (val.rectangleWidth * val.rectangleHeight) % val.denominator === 0,
      'denominator must be a factor of width times height'
    )
    .refine(
      val =>
        val.numerator < val.denominator &&
        val.denominator < val.rectangleWidth * val.rectangleHeight,
      'must be a proper non-unit fraction'
    )
    .refine(
      val => val.rectangleHeight <= val.rectangleWidth,
      'for display purposes, rectangle height must be not be greater than width'
    ),
  simpleGenerator: () => {
    const rectangleWidth = randomIntegerInclusive(3, 10);
    const rectangleHeight = randomIntegerInclusive(2, 6, {
      constraint: x => x <= rectangleWidth
    });
    const totalCells = rectangleWidth * rectangleHeight;
    // this has to be a factor of totalCells (so that the user needs to shade a whole number of cells) - this is the 1st refine
    // however, also pay attention to the 2nd refine. The numerator needs to be < denominator.
    const possibleDenominators = findFactors(totalCells).filter(
      factor => factor >= 2 && factor < totalCells
    );
    const denominator = getRandomFromArray(possibleDenominators) as number;
    const numerator = 1;
    return { rectangleWidth, rectangleHeight, numerator, denominator };
  },
  Component: props => {
    const {
      question: { rectangleWidth, rectangleHeight, numerator, denominator },
      translate,
      displayMode
    } = props;

    const totalCells = rectangleWidth * rectangleHeight;
    const answer = (numerator / denominator) * totalCells;

    return (
      <QF20aBarModelInteractive
        numberOfRows={rectangleHeight}
        numberOfCols={rectangleWidth}
        title={translate.instructions.shadeFractionOfTheRectangle(
          `<frac n='1' d='${denominator}' />`
        )}
        testCorrect={answer}
        questionHeight={1000}
        tableHeight={displayMode === 'digital' ? undefined : 500}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aoU',
  description: 'aoU',
  keywords: ['Unit fraction', 'Equivalent', 'Number line'],
  schema: z.object({
    numberLineDenominator: z.number().int().min(3).max(6),
    fracToFindNumerator: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const numberLineDenominator = randomIntegerInclusive(3, 6);
    const fracToFindNumerator = randomIntegerInclusive(2, 5);

    return { numberLineDenominator, fracToFindNumerator };
  },
  Component: props => {
    const {
      question: { numberLineDenominator, fracToFindNumerator },
      translate
    } = props;

    const fracToFindDenominator = numberLineDenominator * fracToFindNumerator;

    const startingNumber = 0;
    const endNumber = 1;
    const tickInterval = 1 / numberLineDenominator;
    const sliderStep = 1 / (numberLineDenominator * 50);

    const fractionToFind = `<frac n='${fracToFindNumerator}' d='${fracToFindDenominator}'/>`;

    // Create tick array
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const tickValues = [];

    for (let i = 0; i < numTicks; i++) {
      tickValues.push(`<frac n='${i}' d='${numberLineDenominator}'/>`);
    }
    tickValues[0] = startingNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(fractionToFind)}
        pdfTitle={translate.instructions.showPositionOfNumPdf(fractionToFind)}
        testCorrect={[
          fractionToDecimal(fracToFindNumerator, fracToFindDenominator) -
            1 / (numberLineDenominator * 25),
          fractionToDecimal(fracToFindNumerator, fracToFindDenominator) +
            1 / (numberLineDenominator * 25)
        ]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderStep}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aoV',
  description: 'aoV',
  keywords: ['Unit fraction', 'Equivalent'],
  schema: z.object({
    denominator: z.number().int().min(2).max(9),
    multiplier: z.number().int().min(2).max(6),
    name: nameSchema
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 9);
    const multiplier = randomIntegerInclusive(2, 6);

    const name = getRandomName();

    return { denominator, multiplier, name };
  },
  Component: props => {
    const {
      question: { denominator, multiplier, name },
      displayMode,
      translate
    } = props;

    const answer = denominator * multiplier;

    return (
      <QF3Content
        title={translate.instructions.useNamesMethodToFindEquivalentFraction(name)}
        pdfTitle={translate.instructions.findTheEquivalentFraction()}
        inputType="numpad"
        promptButton={translate.extraContentModal.viewCharacterMethod(name)}
        promptButtonPosition="right"
        modalTitle={translate.extraContentModal.characterMethod(name)}
        modalContent={
          <View
            style={{
              height: 400,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <SpeechBubble
              flickLocation="bottom-right"
              style={{
                maxWidth: '50%',
                maxHeight: '70%',
                marginHorizontal: '5%',
                top: '-10%',
                zIndex: 2
              }}
            >
              {translate.extraContentModal.characterFactA()}
            </SpeechBubble>
            <AssetSvg
              name={getCharacterHeadSvgName(name)}
              height={200}
              style={{ top: '10%', zIndex: 1 }}
            />
          </View>
        }
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', justifyContent: 'center', ...dimens }}>
            <FractionsWithArrowsWithState
              id="frac1"
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              numerators={[(1).toLocaleString(), '<ans/>']}
              denominators={[denominator.toLocaleString(), '<ans/>']}
              factors={[
                `${MULT} ${multiplier.toLocaleString()}`,
                `${MULT} ${multiplier.toLocaleString()}`
              ]}
              arrowDirection="right"
              testCorrect={isEqual([multiplier.toString(), answer.toString()])}
              defaultState={
                displayMode === 'markscheme'
                  ? [multiplier.toLocaleString(), answer.toLocaleString()]
                  : undefined
              }
              textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
            />
          </View>
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aoW',
  description: 'aoW',
  keywords: ['Unit fraction', 'Equivalent'],
  schema: z.object({
    numbers: z.number().int().min(2).max(10).array().length(4),
    name: nameSchema
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 9, 2);
    const numeratorA = randomIntegerInclusive(2, 10);
    const randomNumber = randomIntegerInclusive(2, 10);

    const numbers = [denominatorA, denominatorB, numeratorA, randomNumber];

    const name = getRandomName();

    return { numbers, name };
  },
  Component: props => {
    const {
      question: { numbers, name },
      displayMode,
      translate
    } = props;

    const [denominatorA, denominatorB, numeratorA, randomNumber] = numbers;
    const number5 = denominatorB * randomNumber;

    const answer1 = numeratorA * denominatorA;
    const answer2 = randomNumber;

    return (
      <QF3Content
        title={translate.instructions.useNamesMethodToFindEquivalentFractions(name)}
        pdfTitle={translate.instructions.findTheEquivalentFractions()}
        inputType="numpad"
        promptButton={translate.extraContentModal.viewCharacterMethod(name)}
        modalTitle={translate.extraContentModal.characterMethod(name)}
        modalContent={
          <View
            style={{
              height: 400,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <SpeechBubble
              flickLocation="bottom-right"
              style={{
                maxWidth: '50%',
                maxHeight: '70%',
                marginHorizontal: '5%',
                top: '-10%',
                zIndex: 2
              }}
            >
              {translate.extraContentModal.characterFactB()}
            </SpeechBubble>
            <AssetSvg
              name={getCharacterHeadSvgName(name)}
              height={200}
              style={{ top: '10%', zIndex: 1 }}
            />
          </View>
        }
        Content={({ dimens }) => (
          <View
            style={{
              rowGap: displayMode !== 'digital' ? 128 : 16,
              ...dimens
            }}
          >
            <FractionsWithArrowsWithState
              id="frac1"
              dimens={{ height: dimens.height / 2, width: dimens.width }}
              numerators={[(1).toLocaleString(), numeratorA.toLocaleString()]}
              denominators={[denominatorA.toLocaleString(), '<ans/>']}
              factors={[
                `${MULT} ${denominatorA.toLocaleString()}`,
                `${MULT} ${denominatorA.toLocaleString()}`
              ]}
              arrowDirection="down"
              testCorrect={isEqual([answer1.toString()])}
              defaultState={displayMode === 'markscheme' ? [answer1.toLocaleString()] : undefined}
            />
            <FractionsWithArrowsWithState
              id="frac2"
              dimens={{ height: dimens.height / 2, width: dimens.width }}
              numerators={[(1).toLocaleString(), '<ans/>']}
              denominators={[denominatorB.toLocaleString(), number5.toLocaleString()]}
              factors={[
                `${DIV} ${denominatorB.toLocaleString()}`,
                `${DIV} ${denominatorB.toLocaleString()}`
              ]}
              arrowDirection="up"
              testCorrect={isEqual([answer2.toString()])}
              defaultState={displayMode === 'markscheme' ? [answer2.toLocaleString()] : undefined}
            />
          </View>
        )}
        questionHeight={1100}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aoX',
  description: 'aoX',
  keywords: ['Fractions', 'Equivalent', 'Unit fraction'],
  schema: z.object({
    denominator: z.number().int().min(2).max(12)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 12);

    return { denominator };
  },
  Component: props => {
    const {
      question: { denominator },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.findThreeDifferentEquivalentFractionsToFrac(
          `<frac n='${(1).toLocaleString()}' d='${denominator.toLocaleString()}' />`
        )}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [1, denominator]) &&
          compareFractions([userAnswer[2], userAnswer[3]], [1, denominator]) &&
          compareFractions([userAnswer[4], userAnswer[5]], [1, denominator]) &&
          // Check each of the numerators are unique - if any are the same, they must be duplicates or one or more are incorrect.
          // Need to parseInt to check for equivalent numbers with leading zeroes, otherwise
          // e.g. '020' and '20' would be treated as different to each other.
          arrayHasNoDuplicates([
            1,
            parseInt(userAnswer[0]),
            parseInt(userAnswer[2]),
            parseInt(userAnswer[4])
          ]) &&
          // Check each of the denominators are unique - if any are the same, they must be duplicates or one or more are incorrect.
          // Need to parseInt to check for equivalent numbers with leading zeroes, otherwise
          // e.g. '020' and '20' would be treated as different to each other.
          arrayHasNoDuplicates([
            denominator,
            parseInt(userAnswer[1]),
            parseInt(userAnswer[3]),
            parseInt(userAnswer[5])
          ])
        }
        sentence={`<frac n='${(1).toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac nAns='' dAns=''/> = <frac nAns='' dAns=''/> = <frac nAns='' dAns=''/>`}
        fractionContainerStyle={{ height: 96 }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindFractionsEquivalentToAUnitFraction',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
