// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import Num1StepFunctionMachines from './1Num1StepFunctionMachines';
import Num2StepFunctionMachines from './2Num2StepFunctionMachines';
import FormExpressions from './3FormExpressions';
import Substitution from './4Substitution';
import Formulae from './5Formulae';
import FormEquations from './6FormEquations';
import Solve1StepEquations from './7Solve1StepEquations';
import Solve2StepEquations from './8Solve2StepEquations';
import FindPairsOfValues from './9FindPairsOfValues';
import SolveProblemsWithTwoUnknowns from './10SolveProblemsWithTwoUnknowns';

const Block = newBlockContent({
  block: 'Algebra',
  field: 'Number',
  weeks: [3, 4],
  smallSteps: [
    Num1StepFunctionMachines, // Step 1
    Num2StepFunctionMachines, // Step 2
    FormExpressions, // Step 3
    Substitution, // Step 4
    Formulae, // Step 5
    FormEquations, // Step 6
    Solve1StepEquations, // Step 7
    Solve2StepEquations, // Step 8
    FindPairsOfValues, // Step 9
    SolveProblemsWithTwoUnknowns // Step 10
  ]
});
export default Block;
