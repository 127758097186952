import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { getDimenShapeSvgName } from '../../../../utils/shapeImages/shapes';
import {
  IrregularWithArrowsNames,
  irregularShapesWithArrowsSvgs,
  irregularWithArrows
} from '../../../../utils/shapeImages/irregular';
import { countRange, sumNumberArray } from '../../../../utils/collections';
import { SvgName } from '../../../../assets/svg';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import { LabelledHexagon } from '../../../../components/question/representations/LabelledHexagon';
import { LabelledPentagon } from '../../../../components/question/representations/LabelledPentagon';
import { ShapeNames } from '../../../../utils/labelPositions';
import { all, create, number } from 'mathjs';
import { compareFloats } from '../../../../utils/math';
import { View } from 'react-native';
import { getRegularSvg } from '../../../../utils/shapes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aRU',
  description: 'aRU',
  keywords: [
    'Perimeter',
    'Millimetres',
    'mm',
    'Centimetres',
    'cm',
    'Metres',
    'm',
    'Rectilinear shape',
    'Addition',
    'Side lengths'
  ],
  schema: z.object({
    unit: z.enum(['mm', 'cm', 'm']),
    sideLengths: z.number().min(1).max(10).step(0.1).array(),
    svgName: z.string(),
    labelComponent: z.enum(['shape', 'triangle', 'quadrilateral', 'pentagons', 'hexagons']),
    isIrregular: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const polygonSides = randomIntegerInclusive(3, 6);
    const unit = getRandomFromArray(['mm', 'cm', 'm'] as const);

    const shape =
      polygonSides === 3
        ? 'triangles'
        : polygonSides === 4
        ? 'quadrilaterals'
        : polygonSides === 5
        ? 'pentagons'
        : 'hexagons';

    const isIrregular = getRandomBoolean();
    const irregularSvgProps = getRandomFromArray([
      ...irregularShapesWithArrowsSvgs[shape]
    ] as const);

    const svgName = isIrregular
      ? (irregularSvgProps?.svgName as SvgName)
      : getDimenShapeSvgName(
          shape === 'triangles'
            ? 'equilateralTriangles'
            : shape === 'quadrilaterals'
            ? 'rectangles'
            : shape
        );

    const sideLengths = rejectionSample(
      () => {
        const baseValue = randomIntegerInclusive(10, isIrregular ? 50 : 100) / 10;
        const sideLengths = countRange(polygonSides).map(val =>
          isIrregular
            ? Math.round((irregularSvgProps?.sideRatios[val] ?? 1) * baseValue)
            : baseValue
        );
        return sideLengths;
      },
      val => val.every(num => num >= 1 && num <= 10)
    );

    const labelComponent: 'shape' | 'triangle' | 'quadrilateral' | 'pentagons' | 'hexagons' =
      isIrregular
        ? irregularSvgProps?.labelComponent ?? 'shape'
        : shape === 'triangles'
        ? 'triangle'
        : shape === 'quadrilaterals'
        ? 'quadrilateral'
        : shape === 'pentagons'
        ? 'pentagons'
        : 'hexagons';

    return { sideLengths, unit, svgName, labelComponent, isIrregular };
  },
  Component: ({ question, translate }) => {
    const { sideLengths, unit, svgName, labelComponent, isIrregular } = question;

    const [sideUnit, sentence] = ((): [
      'numberOfCm' | 'numberOfM' | 'numberOfMm',
      'ansMm' | 'ansCm' | 'ansM'
    ] => {
      switch (unit) {
        case 'mm': {
          return ['numberOfMm', 'ansMm'];
        }
        case 'cm': {
          return ['numberOfCm', 'ansCm'];
        }
        case 'm': {
          return ['numberOfM', 'ansM'];
        }
      }
    })();

    const labels = sideLengths.map(val => translate.units[sideUnit](val));

    const perimeter = sideLengths.reduce((sum, x) => number(math.evaluate(`${sum} + ${x}`)), 0);

    return (
      <QF1ContentAndSentence
        questionHeight={1000}
        title={translate.instructions.calculatePerimeterOfPolygon()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences[sentence]()}
        extraSymbol="decimalPoint"
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) =>
          labelComponent === 'triangle' ? (
            <LabelledTriangle dimens={dimens} labels={labels} assetSvgName={svgName as SvgName} />
          ) : labelComponent === 'quadrilateral' ? (
            <LabelledQuadrilateral
              labels={labels}
              shape={isIrregular ? 'Rectangle4Arrows' : 'Square4Arrows'}
              dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.8 }}
            />
          ) : labelComponent === 'hexagons' ? (
            <LabelledHexagon allArrows labels={labels} dimens={dimens} />
          ) : labelComponent === 'pentagons' ? (
            <LabelledPentagon allArrows labels={labels} dimens={dimens} />
          ) : (
            <LabelledShape
              dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.9 }}
              shapeName={svgName as ShapeNames}
              labels={labels}
            />
          )
        }
        testCorrect={userAnswer => compareFloats(userAnswer[0], perimeter.toString())}
        inputMaxCharacters={4}
        customMarkSchemeAnswer={{ answersToDisplay: [perimeter.toLocaleString()] }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aRV',
  description: 'aRV',
  keywords: [
    'Perimeter',
    'Millimetres',
    'mm',
    'Centimetres',
    'cm',
    'Metres',
    'm',
    'Rectilinear shape',
    'Addition',
    'Side lengths'
  ],
  schema: z.object({
    unit: z.enum(['mm', 'cm', 'm']),
    sideLengths: z.number().min(1).max(10).step(0.1).array(),
    svgName: z.string(),
    labelComponent: z.enum(['shape', 'triangle', 'quadrilateral', 'pentagons', 'hexagons']),
    isIrregular: z.boolean(),
    incorrectAnswers: z.number().min(6).max(60).array().length(3)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const polygonSides = randomIntegerInclusive(3, 6);
    const unit = getRandomFromArray(['mm', 'cm', 'm'] as const);

    const shape =
      polygonSides === 3
        ? 'triangles'
        : polygonSides === 4
        ? 'quadrilaterals'
        : polygonSides === 5
        ? 'pentagons'
        : 'hexagons';

    const isIrregular = getRandomBoolean();
    const irregularSvgProps = getRandomFromArray([
      ...irregularShapesWithArrowsSvgs[shape]
    ] as const);

    const svgName = isIrregular
      ? (irregularSvgProps?.svgName as SvgName)
      : getDimenShapeSvgName(
          shape === 'triangles'
            ? 'equilateralTriangles'
            : shape === 'quadrilaterals'
            ? 'rectangles'
            : shape
        );

    const sideLengths = rejectionSample(
      () => {
        const baseValue = randomIntegerInclusive(10, isIrregular ? 50 : 100) / 10;
        const sideLengths = countRange(polygonSides).map(val =>
          isIrregular
            ? number(math.evaluate(`${irregularSvgProps?.sideRatios[val] ?? 1} * ${baseValue}`))
            : baseValue
        );
        return sideLengths;
      },
      val => val.every(num => num >= 1 && num <= 10)
    );

    const labelComponent: 'shape' | 'triangle' | 'quadrilateral' | 'pentagons' | 'hexagons' =
      isIrregular
        ? irregularSvgProps?.labelComponent ?? 'shape'
        : shape === 'triangles'
        ? 'triangle'
        : shape === 'quadrilaterals'
        ? 'quadrilateral'
        : shape === 'pentagons'
        ? 'pentagons'
        : 'hexagons';

    const incorrectAnswerWhole = randomUniqueIntegersInclusive(60, 600, 3, {
      constraint: x =>
        x / 10 !== sideLengths.reduce((sum, x) => number(math.evaluate(`${sum} + ${x}`)), 0)
    });

    const incorrectAnswers = incorrectAnswerWhole.map(val => val / 10);

    return { sideLengths, unit, svgName, labelComponent, isIrregular, incorrectAnswers };
  },
  Component: ({ question, translate }) => {
    const { sideLengths, unit, svgName, labelComponent, isIrregular, incorrectAnswers } = question;

    const [sideUnit] = ((): ['numberOfCm' | 'numberOfM' | 'numberOfMm'] => {
      switch (unit) {
        case 'mm': {
          return ['numberOfMm'];
        }
        case 'cm': {
          return ['numberOfCm'];
        }
        case 'm': {
          return ['numberOfM'];
        }
      }
    })();

    const labels = sideLengths.map(val => translate.units[sideUnit](val));

    const perimeter = sideLengths.reduce((sum, x) => number(math.evaluate(`${sum} + ${x}`)), 0);

    return (
      <QF11SelectImagesUpTo4WithContent
        questionHeight={1000}
        title={translate.instructions.selectCorrectPerimeterOfPolygon()}
        pdfTitle={translate.instructions.circleCorrectPerimeterOfPolygon()}
        testCorrect={[perimeter]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'center', alignItems: 'center' }}>
            {labelComponent === 'triangle' ? (
              <LabelledTriangle
                dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                labels={labels}
                assetSvgName={svgName as SvgName}
              />
            ) : labelComponent === 'quadrilateral' ? (
              <LabelledQuadrilateral
                labels={labels}
                shape={isIrregular ? 'Rectangle4Arrows' : 'Square4Arrows'}
                dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.8 }}
              />
            ) : labelComponent === 'hexagons' ? (
              <LabelledHexagon
                allArrows
                labels={labels}
                dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.8 }}
              />
            ) : labelComponent === 'pentagons' ? (
              <LabelledPentagon
                allArrows
                labels={labels}
                dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.8 }}
              />
            ) : (
              <LabelledShape
                dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.9 }}
                shapeName={svgName as ShapeNames}
                labels={labels}
              />
            )}
          </View>
        )}
        itemLayout="row"
        renderItems={shuffle(
          [perimeter, ...incorrectAnswers].map(value => ({
            value,
            component: <Text variant="WRN700">{translate.units[sideUnit](value)}</Text>
          })),
          { random: seededRandom(question) }
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aRX',
  description: 'aRX',
  keywords: ['Perimeter', 'Centimetres', 'Regular polygon', 'Addition', 'Side lengths', 'Equal'],
  schema: z.object({
    unit: z.enum(['mm', 'cm', 'm']),
    polygonSides: z.number().int().min(3).max(10),
    sideLength: z.number().int().min(1).max(20),
    color: z.enum(['pink', 'blue', 'green', 'purple', 'yellow'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const sideLength = randomIntegerInclusive(1, 20);
    const polygonSides = randomIntegerInclusive(3, 10);
    const unit = getRandomFromArray(['mm', 'cm', 'm'] as const);
    const color = getRandomFromArray(['pink', 'blue', 'green', 'purple', 'yellow'] as const);

    return { sideLength, polygonSides, unit, color };
  },
  Component: ({ question, translate }) => {
    const { unit, polygonSides, sideLength, color } = question;

    const perimeter = sideLength * polygonSides;

    const shape = (():
      | 'Pentagon_1_arrow'
      | 'Hexagon_1_arrow'
      | 'Heptagon_1_arrow'
      | 'Octagon_1_arrow'
      | 'Nonagon_1_arrow'
      | 'Decagon_1_arrow'
      | 'triangle'
      | 'Square_1_arrow' => {
      switch (polygonSides) {
        case 3: {
          return 'triangle';
        }
        case 4: {
          return 'Square_1_arrow';
        }
        case 5: {
          return 'Pentagon_1_arrow';
        }
        case 6: {
          return 'Hexagon_1_arrow';
        }
        case 7: {
          return 'Heptagon_1_arrow';
        }
        case 8: {
          return 'Octagon_1_arrow';
        }
        case 9: {
          return 'Nonagon_1_arrow';
        }
        default:
        case 10: {
          return 'Decagon_1_arrow';
        }
      }
    })();

    const [sideUnit, sentence] = ((): [
      'numberOfCm' | 'numberOfM' | 'numberOfMm',
      'ansMm' | 'ansCm' | 'ansM'
    ] => {
      switch (unit) {
        case 'mm': {
          return ['numberOfMm', 'ansMm'];
        }
        case 'cm': {
          return ['numberOfCm', 'ansCm'];
        }
        case 'm': {
          return ['numberOfM', 'ansM'];
        }
      }
    })();

    const label = translate.units[sideUnit](sideLength);

    return (
      <QF1ContentAndSentence
        questionHeight={1000}
        title={translate.instructions.workOutPerimeterOfRegularPolygon()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences[sentence]()}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          if (shape === 'triangle')
            return (
              <LabelledTriangle
                dimens={dimens}
                labels={[label]}
                assetSvgName={`Shapes_with_dimension_arrows/triangle_equal_1_arrow_${
                  color as 'pink' | 'blue' | 'green' | 'purple' | 'yellow'
                }`}
              />
            );
          return (
            <LabelledShape
              dimens={dimens}
              shapeName={shape}
              labels={[label]}
              color={color as 'pink' | 'blue' | 'green' | 'purple' | 'yellow'}
            />
          );
        }}
        testCorrect={[perimeter.toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRW',
  description: 'aRW',
  keywords: ['Perimeter', 'Centimetres', 'Regular polygon', 'Addition', 'Side lengths', 'Equal'],
  schema: z.object({
    unit: z.enum(['mm', 'cm', 'm']),
    polygonSides: z.number().int().min(3).max(10),
    sideLength: z.number().int().min(1).max(20),
    color: z.enum(['pink', 'blue', 'green', 'purple', 'yellow']),
    incorrectAnswers: z.number().int().min(3).max(200).array()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const sideLength = randomIntegerInclusive(1, 20);
    const polygonSides = randomIntegerInclusive(3, 10);
    const unit = getRandomFromArray(['mm', 'cm', 'm'] as const);
    const color = getRandomFromArray(['pink', 'blue', 'green', 'purple', 'yellow'] as const);
    const oneLess = sideLength * (polygonSides - 1);
    const oneMore = sideLength * (polygonSides + 1);
    const incorrectAnswers = [
      // if we can have answers close to actual then lets!
      oneLess < 4 || oneLess > 200 ? randomIntegerInclusive(3, 200) : oneLess,
      oneMore < 7 || oneMore > 200 ? randomIntegerInclusive(6, 200) : oneMore,
      randomIntegerInclusive(Math.max(sideLength, 6), Math.min(sideLength * 10, 200), {
        constraint: x =>
          x !== sideLength * (polygonSides - 1) && x !== sideLength * (polygonSides + 1)
      })
    ];

    return { sideLength, polygonSides, unit, color, incorrectAnswers };
  },
  Component: ({ question, translate }) => {
    const { unit, polygonSides, sideLength, color, incorrectAnswers } = question;

    const perimeter = sideLength * polygonSides;

    const shape = (():
      | 'Pentagon_1_arrow'
      | 'Hexagon_1_arrow'
      | 'Heptagon_1_arrow'
      | 'Octagon_1_arrow'
      | 'Nonagon_1_arrow'
      | 'Decagon_1_arrow'
      | 'triangle'
      | 'Square_1_arrow' => {
      switch (polygonSides) {
        case 3: {
          return 'triangle';
        }
        case 4: {
          return 'Square_1_arrow';
        }
        case 5: {
          return 'Pentagon_1_arrow';
        }
        case 6: {
          return 'Hexagon_1_arrow';
        }
        case 7: {
          return 'Heptagon_1_arrow';
        }
        case 8: {
          return 'Octagon_1_arrow';
        }
        case 9: {
          return 'Nonagon_1_arrow';
        }
        default:
        case 10: {
          return 'Decagon_1_arrow';
        }
      }
    })();

    const [sideUnit] = ((): ['numberOfCm' | 'numberOfM' | 'numberOfMm'] => {
      switch (unit) {
        case 'mm': {
          return ['numberOfMm'];
        }
        case 'cm': {
          return ['numberOfCm'];
        }
        case 'm': {
          return ['numberOfM'];
        }
      }
    })();

    const label = translate.units[sideUnit](sideLength);

    return (
      <QF11SelectImagesUpTo4WithContent
        questionHeight={1000}
        title={translate.instructions.selectCorrectPerimeterOfRegularPolygon()}
        pdfTitle={translate.instructions.circleCorrectPerimeterOfRegularPolygon()}
        Content={({ dimens }) => {
          if (shape === 'triangle')
            return (
              <LabelledTriangle
                dimens={dimens}
                labels={[label]}
                assetSvgName={`Shapes_with_dimension_arrows/triangle_equal_1_arrow_${
                  color as 'pink' | 'blue' | 'green' | 'purple' | 'yellow'
                }`}
              />
            );
          return (
            <LabelledShape
              dimens={dimens}
              shapeName={shape}
              labels={[label]}
              color={color as 'pink' | 'blue' | 'green' | 'purple' | 'yellow'}
            />
          );
        }}
        testCorrect={[perimeter]}
        numItems={4}
        itemLayout="row"
        renderItems={shuffle(
          [perimeter, ...incorrectAnswers].map(value => ({
            component: (
              <Text variant="WRN700" style={{ textAlign: 'center' }}>
                {translate.units[sideUnit](value)}
              </Text>
            ),
            value
          })),
          { random: seededRandom(question) }
        )}
      />
    );
  }
});

// this feels very specific to this question so I haven't pulled out into new file
const svgNameOptionsQ5 = [
  'Square_1_arrow',
  'Shapes_with_dimension_arrows/triangle_equal_1_arrow_pink',
  'Shapes_with_dimension_arrows/triangle_equal_1_arrow_blue',
  'Shapes_with_dimension_arrows/triangle_equal_1_arrow_green',
  'Shapes_with_dimension_arrows/triangle_equal_1_arrow_purple',
  'Shapes_with_dimension_arrows/triangle_equal_1_arrow_yellow',
  'Pentagon_1_arrow',
  'Hexagon_1_arrow',
  'Heptagon_1_arrow',
  'Octagon_1_arrow',
  'Nonagon_1_arrow',
  'Decagon_1_arrow',
  ...irregularWithArrows
] as const;
export const svgNameOptionsQ5Schema = z.enum(svgNameOptionsQ5);
export type SvgNameOptionsQ5 = (typeof svgNameOptionsQ5)[number];

const Question5 = newQuestionContent({
  uid: 'aRY',
  description: 'aRY',
  keywords: ['Perimeter', 'Centimetres', 'Regular polygon', 'Addition', 'Side lengths', 'Equal'],
  schema: z.object({
    unit: z.enum(['mm', 'cm', 'm']),
    options: z
      .object({
        svgName: svgNameOptionsQ5Schema,
        isRegular: z.boolean(),
        numberOfSides: z.number().int().min(3).max(10),
        sideLengths: z.number().int().min(1).max(20).array(),
        labelComponent: z.enum(['shape', 'triangle', 'quadrilateral', 'pentagons', 'hexagons']),
        color: z.enum(['pink', 'purple', 'blue', 'green', 'yellow']).optional()
      })
      .array()
      .length(2),
    correctIndex: z.number().int().min(0).max(1)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const polygonSides = randomUniqueIntegersInclusive(3, 10, 2);
    const getShape = (polygonSides: 3 | 4 | 5 | 6) => {
      switch (polygonSides) {
        case 3: {
          return 'triangles';
        }
        case 4: {
          return 'quadrilaterals';
        }
        case 5: {
          return 'pentagons';
        }
        case 6: {
          return 'hexagons';
        }
      }
    };

    const getPolygonObject = (isRegular: boolean, polygonSides: number, baseLength: number) => {
      let option: {
        svgName: SvgNameOptionsQ5;
        isRegular: boolean;
        numberOfSides: number;
        sideLengths: number[];
        labelComponent: 'shape' | 'triangle' | 'quadrilateral' | 'pentagons' | 'hexagons';
        color?: 'pink' | 'purple' | 'blue' | 'green' | 'yellow';
      };
      if (isRegular) {
        option = {
          svgName:
            polygonSides === 3
              ? `Shapes_with_dimension_arrows/triangle_equal_1_arrow_${getRandomFromArray([
                  'pink',
                  'blue',
                  'green',
                  'purple',
                  'yellow'
                ] as const)}`
              : getRegularSvg(polygonSides),
          isRegular: true,
          numberOfSides: polygonSides,
          sideLengths: [baseLength],
          labelComponent: polygonSides === 3 ? 'triangle' : 'shape',
          color: getRandomFromArray(['pink', 'blue', 'green', 'purple', 'yellow'] as const)
        };
      } else {
        const shape = getRandomFromArray([
          ...irregularShapesWithArrowsSvgs[getShape(polygonSides as 3 | 4 | 5 | 6)]
        ] as const);
        option = {
          svgName: shape?.svgName as IrregularWithArrowsNames,
          isRegular: false,
          numberOfSides: polygonSides,
          sideLengths: shape?.sideRatios.map(val => val * baseLength) ?? [baseLength],
          labelComponent: shape?.labelComponent ?? 'shape'
        };
      }
      return option;
    };

    const unit = getRandomFromArray(['mm', 'cm', 'm'] as const);

    const polygon1Regular = polygonSides[0] > 6 ? true : getRandomBoolean();
    const polygon1BaseLength = polygon1Regular
      ? randomIntegerInclusive(1, 20)
      : randomIntegerInclusive(1, 4);

    const polygon2Regular = polygonSides[1] > 6 ? true : getRandomBoolean();
    const polygon2BaseLength = polygon2Regular
      ? randomIntegerInclusive(1, 20)
      : randomIntegerInclusive(1, 4);

    const option1 = getPolygonObject(polygon1Regular, polygonSides[0], polygon1BaseLength);
    const option2 = getPolygonObject(polygon2Regular, polygonSides[1], polygon2BaseLength);

    const options = [option1, option2];
    const correctIndex = randomIntegerInclusive(0, 1);

    return { options, unit, correctIndex };
  },
  Component: ({ question, translate }) => {
    const { options, unit, correctIndex } = question;

    const perimeters = options.map(val =>
      val.isRegular ? val.sideLengths[0] * val.numberOfSides : sumNumberArray(val.sideLengths)
    );

    const [sideUnit] = ((): ['numberOfCm' | 'numberOfM' | 'numberOfMm'] => {
      switch (unit) {
        case 'mm': {
          return ['numberOfMm'];
        }
        case 'cm': {
          return ['numberOfCm'];
        }
        case 'm': {
          return ['numberOfM'];
        }
      }
    })();

    const labels = options.map(val => val.sideLengths.map(side => translate.units[sideUnit](side)));

    return (
      <QF11SelectImagesUpTo4
        questionHeight={1000}
        title={translate.instructions.selectTheShapeWithPerimeterX(
          translate.units[sideUnit](perimeters[correctIndex])
        )}
        pdfTitle={translate.instructions.circleTheShapeWithPerimeterX(
          translate.units[sideUnit](perimeters[correctIndex])
        )}
        renderItems={({ dimens }) =>
          options.map((option, i) => {
            if (option.labelComponent === 'triangle')
              return {
                value: perimeters[i],
                component: (
                  <LabelledTriangle
                    dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.9 }}
                    labels={labels[i]}
                    assetSvgName={option.svgName as SvgName}
                  />
                )
              };
            if (option.labelComponent === 'shape')
              return {
                value: perimeters[i],
                component: (
                  <LabelledShape
                    dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.9 }}
                    shapeName={option.svgName as ShapeNames}
                    labels={labels[i]}
                    color={option.color}
                  />
                )
              };
            return {
              value: perimeters[i],
              component: (
                <LabelledQuadrilateral labels={labels[i]} shape={'Square4Arrows'} dimens={dimens} />
              )
            };
          })
        }
        testCorrect={[perimeters[correctIndex]]}
        numItems={2}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aRZ',
  description: 'aRZ',
  keywords: ['Perimeter', 'Centimetres', 'Regular polygon', 'Addition', 'Side lengths', 'Equal'],
  schema: z.object({
    unit: z.enum(['cm', 'm']),
    polygonSides: z.number().int().min(3).max(10),
    options: z.array(z.number().int().min(1).max(20)).length(4),
    ansIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const unit = getRandomFromArray(['cm', 'm'] as const);
    const polygonSides = randomIntegerInclusive(3, 10);
    const options = randomUniqueIntegersInclusive(1, 20, 4);
    const ansIndex = randomIntegerInclusive(0, 3);

    return { polygonSides, options, unit, ansIndex };
  },
  Component: props => {
    const { question, translate } = props;
    const { polygonSides, options, unit, ansIndex } = question;

    const shape = (() => {
      switch (polygonSides) {
        case 3: {
          return translate.shapes.triangles(1);
        }
        case 4: {
          return translate.shapes.squares(1);
        }
        case 5: {
          return translate.shapes.pentagons(1);
        }
        case 6: {
          return translate.shapes.hexagons(1);
        }
        case 7: {
          return translate.shapes.heptagons(1);
        }
        case 8: {
          return translate.shapes.octagons(1);
        }
        case 9: {
          return translate.shapes.nonagons(1);
        }
        default:
        case 10: {
          return translate.shapes.decagons(1);
        }
      }
    })();

    const optionsTranslated = options.map(l =>
      translate.units[unit === 'cm' ? 'numberOfCm' : 'numberOfMm'](l)
    );

    const perimeter = options[ansIndex] * polygonSides;
    const perimeterTranslated =
      translate.units[unit === 'cm' ? 'numberOfCm' : 'numberOfMm'](perimeter);

    return (
      <QF37SentenceDrag
        title={`${translate.instructions.regularXHasPerimeterOfYZWhatIsSideLength(
          shape,
          perimeterTranslated
        )}<br/>${translate.instructions.dragCorrectCard()}`}
        pdfTitle={`${translate.instructions.regularXHasPerimeterOfYZWhatIsSideLength(
          shape,
          perimeterTranslated
        )}<br/>${translate.instructions.useCorrectCard()}`}
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ justifyContent: 'flex-end' }}
        items={optionsTranslated}
        itemVariant={'rectangle'}
        pdfItemVariant="rectangle"
        actionPanelVariant="endWide"
        testCorrect={[optionsTranslated[ansIndex]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PerimeterOfPolygons',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
