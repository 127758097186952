import { getRandomFromArray } from '../../../../utils/random';
import {
  Base10RepTo100sVariantArray,
  Base10RepTo100sVariantSchema,
  Base10RepTo100sVariant
} from '../../../../components/question/representations/types';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { randomIntegerInclusive } from '../../../../utils/random';
import CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { ScientificNotation } from '../../../../utils/math';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { Dimens } from '../../../../theme/scaling';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'abw',
  description: 'abw',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Object'],
  schema: z.object({
    variant: Base10RepTo100sVariantSchema,
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const variant = getRandomFromArray(
      Base10RepTo100sVariantArray.filter(x => x !== 'Counters' && x !== 'Cubes')
    ) as Base10RepTo100sVariant;
    const tens = randomIntegerInclusive(1, 9);
    const ones = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);

    return {
      variant,
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, variant },
      translate
    } = props;

    const getScale = (dimens: Dimens) => {
      const { scale } = BaseTenRepCalcGridsAndScale(
        dimens.width - 60,
        dimens.height,
        { ones, tens, hundreds },
        variant
      );

      switch (variant) {
        case 'Nails':
          return Math.min(scale, 0.6);
        case 'Marbles':
        case 'Lollipops':
          return Math.min(scale, 0.75);
        case 'Bricks':
          return Math.min(scale, 2);
        default:
          return Math.min(scale, 1);
      }
    };

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.howManyObjectsAreThere(variant)}
        testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <BaseTenRepresentation
              b10Rep={{ variant: variant, numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
              usableWidth={dimens.width - 60}
              usableHeight={dimens.height}
              containerStyle={{ columnGap: 20 }}
              scale={getScale(dimens)}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'abx',
  description: 'abx',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Object'],
  schema: z.object({
    variant: Base10RepTo100sVariantSchema,
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    // We don't have straws 100 representation, so use NoStrawsArray and NoStrawsVariant to avoid them.
    const variant = getRandomFromArray(
      Base10RepTo100sVariantArray.filter(x => x !== 'Counters' && x !== 'Cubes')
    ) as Base10RepTo100sVariant;
    // Randomly decide if the ones are zero or not.
    const areOnesZero = getRandomFromArray([true, false]);
    // Limit ones to 1-9 and tens to 1-9 (if ones are not zero from above, tens are zero instead) and hundreds to 1-9
    const ones = areOnesZero ? 0 : randomIntegerInclusive(1, 9);
    const tens = areOnesZero ? randomIntegerInclusive(1, 9) : 0;
    const hundreds = randomIntegerInclusive(1, 9);

    return {
      variant,
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.howManyObjectsAreThere(variant)}
        testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aby',
  description: 'aby',
  keywords: ['Place value', 'Base 10', '1,000', 'Thousand', 'Represent'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(9),
    hundreds: z.number().int().min(1).max(9)
  }),
  example: {
    ones: 3,
    tens: 1,
    hundreds: 2
  },
  simpleGenerator: () => {
    // Limit tens to 1-9, ones to 0-9 (or 1-9 if tens are 0) and hundreds to 1-9
    const tens = randomIntegerInclusive(0, 9);
    const ones = tens === 0 ? randomIntegerInclusive(1, 9) : randomIntegerInclusive(0, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    return {
      ones,
      tens,
      hundreds
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: 'Cubes', numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'abz',
  description: 'abz',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Base 10', 'Select'],
  questionHeight: 900,
  schema: z
    .object({
      number: z.number().int().min(101).max(499),
      numberPartition: z.object({
        hundreds: z.number().int().min(1).max(4),
        tens: z.number().int().min(0).max(9),
        ones: z.number().int().min(0).max(9)
      }),
      wrongNumberPartitions: z
        .array(
          z.object({
            hundreds: z.number().int().min(0).max(5),
            tens: z.number().int().min(0).max(9),
            ones: z.number().int().min(0).max(9)
          })
        )
        .nonempty(),
      correctPosition: z.number().int().min(0).max(3)
    })
    .refine(
      val =>
        val.wrongNumberPartitions.every(
          it => it.hundreds * 100 + it.tens * 10 + it.ones !== val.number
        ),
      'Each wrong number must not sum to the number provided'
    ),
  example: {
    number: 240,
    numberPartition: { hundreds: 2, tens: 4, ones: 0 },
    wrongNumberPartitions: [
      { hundreds: 2, tens: 0, ones: 8 },
      { hundreds: 1, tens: 2, ones: 0 },
      { hundreds: 3, tens: 0, ones: 3 }
    ],
    correctPosition: 3
  },
  simpleGenerator: () => {
    // Randomly decide if the ones are zero or not.
    const areOnesZero = getRandomFromArray([true, false]);
    // Limit ones to 1-9 and tens to 1-9 (if ones are not zero from above, tens are zero instead) and hundreds to 1-9
    const ones = areOnesZero ? 0 : randomIntegerInclusive(1, 9);
    const tens = areOnesZero ? randomIntegerInclusive(1, 9) : 0;

    // Chose the number
    const hundreds = randomIntegerInclusive(1, 4);
    const number = hundreds * 100 + tens * 10 + ones;

    type Partition = { hundreds: number; tens: number; ones: number };
    const numberPartition = { hundreds: hundreds, tens: tens, ones: ones };

    const wrongNumberPartitions: [Partition, ...Partition[]] = [
      { hundreds: hundreds, tens: tens <= 5 ? tens + 2 : tens - 2, ones: 0 },
      {
        hundreds: hundreds < 2 ? hundreds + 1 : hundreds - 1,
        tens: 0,
        ones: ones <= 5 ? ones + 2 : ones - 2
      },
      {
        hundreds: hundreds < 2 ? hundreds + 1 : hundreds - 1,
        tens: tens <= 5 ? tens + 3 : tens - 3,
        ones: ones <= 5 ? ones + 3 : ones - 3
      }
    ];

    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);
    return { number, numberPartition, wrongNumberPartitions, correctPosition };
  },
  Component: props => {
    const {
      question: { number, numberPartition, wrongNumberPartitions, correctPosition },
      translate
    } = props;
    const allPartitions = [
      ...wrongNumberPartitions.slice(0, correctPosition),
      numberPartition,
      ...wrongNumberPartitions.slice(correctPosition)
    ];

    const variant = 'Cubes';

    return (
      <QF11SelectImagesUpTo4<number>
        title={translate.instructions.selectPictureThatRepresentsNum(number)}
        pdfTitle={translate.instructions.circleThePictureThatRepresentsNum(number)}
        testCorrect={[correctPosition]}
        questionHeight={900}
        numItems={4}
        renderItems={({ dimens }) => {
          const scale = Math.min(
            ...allPartitions.map(
              part => BaseTenRepCalcGridsAndScale(dimens.width, dimens.height, part, variant).scale
            )
          );
          return allPartitions.map((partition, index) => ({
            value: index,
            component: (
              <BaseTenRepresentation
                b10Rep={{
                  variant: variant,
                  numbers: {
                    ones: partition.ones,
                    tens: partition.tens,
                    hundreds: partition.hundreds
                  },
                  arrangement: 'ltr'
                }}
                usableWidth={dimens.width}
                usableHeight={dimens.height - 20} // -20 to give a margin of 10
                scale={scale}
              />
            )
          }));
        }}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'abA',
  description: 'abA',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Base 10'],
  schema: z.object({
    number: z.number().min(101).max(999)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(101, 999);
    return { number };
  },
  Component: ({ question: { number }, translate, ...props }) => (
    <CreatePlaceValueChart
      number={ScientificNotation.fromNumber(number)}
      columnsToShow={[2, 1, 0]}
      counterVariant="base10Block"
      title={translate.instructions.useBase10ToMakeNum(number)}
      {...props}
    />
  )
});

const Question6 = newQuestionContent({
  uid: 'abB',
  description: 'abB',
  keywords: ['Place value', 'Represent', '1,000', 'Thousand', 'Counters'],
  schema: z
    .object({
      variant: Base10RepTo100sVariantSchema,
      number: z.number().int().min(101).max(999),
      ones: z.number().int().min(0).max(9),
      tens: z.number().int().min(0).max(9),
      hundreds: z.number().int().min(1).max(9)
    })
    .refine(
      val => val.ones + val.tens * 10 + val.hundreds * 100 === val.number,
      'Ones + tens + hundreds must be equal to the whole'
    ),
  example: {
    variant: 'Counters',
    number: 241,
    ones: 1,
    tens: 4,
    hundreds: 2
  },
  simpleGenerator: () => {
    const variant: Base10RepTo100sVariant = 'Counters';
    const tens = randomIntegerInclusive(0, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    // Ensure minimum of 101 for answer.
    const ones =
      tens === 0 && hundreds === 1 ? randomIntegerInclusive(1, 9) : randomIntegerInclusive(0, 9);
    const number = ones + tens * 10 + hundreds * 100;
    return { variant, number, ones, tens, hundreds };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, variant },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        sentence="<ans/>"
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[(hundreds * 100 + tens * 10 + ones).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens, hundreds }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
            scale={displayMode === 'digital' ? 0.3 : 0.4}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RepresentNumbersTo1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
