import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { z } from 'zod';
import { BigNumber, all, create, number } from 'mathjs';
import { ADD } from '../../../../constants';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  ScientificNotation,
  compareFloats,
  lessThanGreaterThanOrEqualTo
} from '../../../../utils/math';
import { numbersDoNotExchange, numbersExchangeAt } from '../../../../utils/exchanges';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF27MissingDigitColumnOperations, {
  getDecimalMissingDigits,
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aBE',
  description: 'aBE',
  keywords: ['Place value chart', 'Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().int().min(111).max(666),
    number2: z.number().int().min(111).max(666)
  }),
  simpleGenerator: () => {
    // Limit the digit to 6 (otherwise counters do not fit)
    // Make sure it would always have 2dp e.g. never 2.50
    const number1 = randomIntegerInclusive(111, 666, {
      constraint: x => Math.floor((x % 100) / 10) <= 6 && x % 10 <= 6 && x % 10 !== 0
    });
    const number2 = randomIntegerInclusive(111, 666, {
      constraint: x =>
        Math.floor((x % 100) / 10) <= 6 &&
        x % 10 <= 6 &&
        numbersDoNotExchange(number1, x) &&
        x % 10 !== 0
    });

    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate, displayMode }) => {
    const firstNumber = number(math.evaluate(`${number1} / 100`));
    const secondNumber = number(math.evaluate(`${number2} / 100`));

    const correctAnswer = number(math.evaluate(`${firstNumber} + ${secondNumber}`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToHelpCompleteAddition()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(firstNumber)}
            secondNumber={ScientificNotation.fromNumber(secondNumber)}
            columnsToShow={[0, -1, -2]}
            counterVariant="greyCounter"
            counterSize={displayMode === 'digital' ? undefined : 60}
            dimens={dimens}
            rowsToUse={3}
          />
        )}
        sentence={`${firstNumber.toLocaleString(undefined, {
          minimumFractionDigits: 2
        })} ${ADD} ${secondNumber.toLocaleString(undefined, {
          minimumFractionDigits: 2
        })} = <ans/>`}
        testCorrect={userAnswer => compareFloats(userAnswer[0], correctAnswer)}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q2NumberSchema = z
  .number()
  .multipleOf(0.01)
  .min(1.11)
  .max(6.66)
  .refine(
    // Limit digits to 6 (otherwise counters do not fit)
    a => ScientificNotation.fromNumber(a).digits.every(d => 1 <= d && d <= 6),
    'All digits must be between 1 and 6 inclusive'
  );

const Question2v2 = newQuestionContent({
  uid: 'aBF2',
  description: 'aBF',
  keywords: ['Place value chart', 'Addition', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      a: q2NumberSchema,
      b: q2NumberSchema,
      options: z.number().multipleOf(0.001).array().length(3)
    })
    .refine(
      ({ a, b }) => numbersExchangeAt(a, b, 'tenths') !== numbersExchangeAt(a, b, 'hundredths'),
      'Must exchange either tenths or hundreds but not both'
    )
    .refine(({ a, b, options }) => {
      const answer = number(math.evaluate(`${a} + ${b}`));
      return options.filter(x => x === answer).length === 1;
    }, 'Exactly one of the options must be correct'),
  simpleGenerator: () => {
    const {
      aDigits: [aOnes, aTenths, aHundredths],
      bDigits: [bOnes, bTenths, bHundredths],
      a,
      b,
      exchangesAtTenths,
      exchangesAtHundredths
    } = rejectionSample(
      () => {
        // Pick each digit randomly
        const aDigits = Array.from({ length: 3 }, () => randomIntegerInclusive(1, 6));
        const bDigits = Array.from({ length: 3 }, () => randomIntegerInclusive(1, 6));
        const a = parseFloat(`${aDigits[0]}.${aDigits[1]}${aDigits[2]}`);
        const b = parseFloat(`${bDigits[0]}.${bDigits[1]}${bDigits[2]}`);

        // Check exchanges
        const exchangesAtTenths = numbersExchangeAt(a, b, 'tenths');
        const exchangesAtHundredths = numbersExchangeAt(a, b, 'hundredths');
        return { aDigits, bDigits, a, b, exchangesAtTenths, exchangesAtHundredths };
      },
      ({ exchangesAtTenths, exchangesAtHundredths }) => exchangesAtTenths !== exchangesAtHundredths
    );

    const answerBigNum: BigNumber = math.evaluate(`${a} + ${b}`);
    const answer = number(answerBigNum);

    const wrongAnswers = [
      number(answerBigNum.minus(1)),
      number(answerBigNum.minus(0.1)),
      // Wrong answer like 2.61 + 3.42 = 5.103
      exchangesAtTenths &&
        number(
          math.evaluate(
            `${aOnes} + ${bOnes} + 0.1 * (0.${aTenths}${aHundredths} + 0.${bTenths}${bHundredths})`
          )
        ),
      // Wrong answer like 2.36 + 3.55 = 5.811
      exchangesAtHundredths &&
        number(
          math.evaluate(
            `${aOnes}.${aTenths} + ${bOnes}.${bTenths} + 0.1 * (0.0${aHundredths} + 0.0${bHundredths})`
          )
        )
    ].filter((x): x is number => x !== false);

    const options = shuffle([answer, ...getRandomSubArrayFromArray(wrongAnswers, 2)]);

    return { a, b, options };
  },
  Component: props => {
    const {
      question: { a, b, options },
      translate,
      displayMode
    } = props;
    const correctAnswer = number(math.evaluate(`${a} + ${b}`));
    const correctIndex = options.indexOf(correctAnswer);

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.workOutXAddYUsePVCToSelectAnswer(a, b)}
        pdfTitle={translate.instructions.workOutXAddYUsePVCToCircleAnswer(a, b)}
        correctAnswer={[correctIndex.toString()]}
        leftContent={
          <MeasureView>
            {dimens => (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(a)}
                secondNumber={ScientificNotation.fromNumber(b)}
                rowsToUse={3}
                columnsToShow={[0, -1, -2]}
                counterVariant="greyCounter"
                counterSize={displayMode === 'digital' ? undefined : 70}
                dimens={{ width: dimens.width, height: dimens.height * 0.9 }}
              />
            )}
          </MeasureView>
        }
        selectables={Object.fromEntries(
          options.map((value, index) => [index.toString(), value.toLocaleString()])
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

/** @deprecated */
const Question2 = newQuestionContent({
  uid: 'aBF',
  description: 'aBF',
  keywords: ['Place value chart', 'Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    numberADigits: z.array(z.number().int().min(1).max(6)).length(3),
    numberBDigits: z.array(z.number().int().min(1).max(6)).length(3)
  }),
  simpleGenerator: () => {
    // Limit digits to 6 (otherwise counters do not fit)
    const { numberADigits, numberBDigits } = rejectionSample(
      () => {
        const numberADigits = Array.from({ length: 3 }, () => randomIntegerInclusive(1, 6));
        const numberBDigits = Array.from({ length: 3 }, () => randomIntegerInclusive(1, 6));

        return { numberADigits, numberBDigits };
      },
      ({ numberADigits: [, tenthsA, hundredthsA], numberBDigits: [, tenthsB, hundredthsB] }) =>
        hundredthsA + hundredthsB >= 10 && tenthsA + tenthsB <= 8
    );

    return { numberADigits, numberBDigits };
  },
  Component: props => {
    const {
      question: {
        numberADigits: [onesA, tenthsA, hundredthsA],
        numberBDigits: [onesB, tenthsB, hundredthsB]
      },
      translate
    } = props;

    const firstNumber = number(math.evaluate(`${onesA} + ${tenthsA} / 10 + ${hundredthsA} / 100`));
    const secondNumber = number(math.evaluate(`${onesB} + ${tenthsB} / 10 + ${hundredthsB} / 100`));

    const random = seededRandom(props.question);

    const correctOption = number(math.evaluate(`${firstNumber} + ${secondNumber}`));
    const incorrectOptions = getRandomSubArrayFromArray(
      [
        number(math.evaluate(`${correctOption} - 1`)),
        number(math.evaluate(`${correctOption} - 0.1`)),
        number(
          math.evaluate(
            `${onesA} + ${onesB} + 0.1 * (${tenthsA} / 10 + ${hundredthsA} / 100 + ${hundredthsB} / 100)`
          )
        ),
        number(
          math.evaluate(
            `${onesA} + ${onesB} + ${tenthsA} / 10 + ${tenthsB} / 10 + 0.1 * ${tenthsB} / 10 + ${hundredthsB} / 100`
          )
        )
      ],
      3,
      { random, constraint: x => arrayHasNoDuplicates([correctOption, x]) } // Ensure that the options won't include any duplicates
    );

    const shuffledOptions = shuffle([correctOption, ...incorrectOptions], { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.usePlaceValueChartToSelectCorrectAnswerToXAddY(
          firstNumber,
          secondNumber
        )}
        pdfTitle={translate.instructions.usePlaceValueChartToCircleCorrectAnswerToXAddYPDF(
          firstNumber,
          secondNumber
        )}
        testCorrect={[correctOption]}
        numItems={4}
        itemLayout="row"
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(firstNumber)}
            secondNumber={ScientificNotation.fromNumber(secondNumber)}
            rowsToUse={3}
            columnsToShow={[0, -1, -2]}
            counterVariant="greyCounter"
            dimens={{ width: dimens.width, height: dimens.height * 0.9 }}
          />
        )}
        renderItems={shuffledOptions.map(opt => ({
          value: opt,
          component: (
            <Text variant="WRN700">
              {opt.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </Text>
          )
        }))}
        questionHeight={1250}
      />
    );
  },
  questionHeight: 1250
});

const Question3 = newQuestionContent({
  uid: 'aBG',
  description: 'aBG',
  keywords: ['Column addition', 'Tenths'],
  schema: z.object({
    number1: z
      .number()
      .min(1.1)
      .max(9.9)
      .refine(number1 => number1 % 1 !== 0),
    number2: z
      .number()
      .min(1.1)
      .max(9.9)
      .refine(number1 => number1 % 1 !== 0)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 }) / 10;

    const number2 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 }) / 10;

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const number3 = number1 + number2;
    const answerMissingDigits = getDecimalMissingDigits(number3, 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutAddition()}
        topNumber={number1}
        bottomNumber={number2}
        operation={ADD}
        answerNumber={number3}
        persistExchangeBoxes
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aBH',
  description: 'aBH',
  keywords: ['Column addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(number1 => number1 % 1 !== 0 && number1 % 0.1 !== 0),
    number2: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(number1 => number1 % 1 !== 0 && number1 % 0.1 !== 0)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 }) / 100;

    const number2 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 }) / 100;

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const correctAnswer = number(math.evaluate(`${number1}+${number2}`));
    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutAddition()}
        topNumber={number1}
        bottomNumber={number2}
        operation={ADD}
        answerNumber={correctAnswer}
        persistExchangeBoxes
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aBI',
  description: 'aBI',
  keywords: ['Column addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(number1 => number1 % 1 !== 0 && number1 % 0.1 !== 0),
    number2: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(number1 => number1 % 1 !== 0 && number1 % 0.1 !== 0),
    number3: z
      .number()
      .min(1.01)
      .max(9.99)
      .refine(number1 => number1 % 1 !== 0 && number1 % 0.1 !== 0)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 }) / 100;

    const number2 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 }) / 100;
    const number3 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 }) / 100;

    return {
      number1,
      number2,
      number3
    };
  },
  Component: ({ question: { number1, number2, number3 }, translate }) => {
    const correctAnswer = number(math.evaluate(`${number1}+${number2}+${number3}`));
    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutAddition()}
        topNumber={number1}
        bottomNumber={number2}
        middleNumber={number3}
        operation={ADD}
        answerNumber={correctAnswer}
        persistExchangeBoxes
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aBJ',
  description: 'aBJ',
  keywords: ['Decimal', 'Addition', 'Tenths', 'Hundredths', 'Match'],
  schema: z.object({
    statementNums: z.array(z.number().min(0.11).max(18.99)).length(4)
  }),
  simpleGenerator: () => {
    // Statement A
    const number1 = randomIntegerInclusive(201, 899, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(11, 61, { constraint: x => x % 10 !== 0 });
    const number3 = randomIntegerInclusive(number1 - 69, number1 - 0.41, {
      constraint: x => x % 10 !== 0
    });
    const number4 = randomIntegerInclusive(number2 + 41, number1 + 0.69, {
      constraint: x => x % 10 !== 0
    });

    const statementANums = [number1 / 100, number2 / 100, number3 / 100, number4 / 100];

    // Statement B
    const number5 = randomIntegerInclusive(3001, 7999, { constraint: x => x % 10 !== 0 });
    const number6 = randomIntegerInclusive(2001, 4999, { constraint: x => x % 10 !== 0 });
    const number7 = randomIntegerInclusive(number5 - 1999, number5 - 1001, {
      constraint: x => x % 10 !== 0
    });
    // Adjusted lower bound to ensure it is always lower than the upper bound
    const number8 = randomIntegerInclusive(2001 + 1001, number5 + 1999, {
      constraint: x => x % 10 !== 0
    });

    const statementBNums = [number5 / 1000, number6 / 1000, number7 / 1000, number8 / 1000];

    // Statement C
    const number9 = randomIntegerInclusive(1201, 1899, { constraint: x => x % 10 !== 0 });
    const number10 = randomIntegerInclusive(111, 899, { constraint: x => x % 10 !== 0 });
    const number11 = randomIntegerInclusive(number1 - 169, number1 - 141, {
      constraint: x => x % 10 !== 0
    });
    const number12 = randomIntegerInclusive(number2 + 141, number1 + 169, {
      constraint: x => x % 10 !== 0
    });

    const statementCNums = [number9 / 100, number10 / 100, number11 / 100, number12 / 100];

    return { statementNums: getRandomFromArray([statementANums, statementBNums, statementCNums]) };
  },
  Component: props => {
    const {
      question: { statementNums },
      translate
    } = props;

    const statement = {
      lhsComponent: (
        <Text variant="WRN400">{`${statementNums[0].toLocaleString(undefined, {
          minimumFractionDigits: 2
        })} ${ADD} ${statementNums[1].toLocaleString(undefined, {
          minimumFractionDigits: 2
        })}`}</Text>
      ),
      rhsComponent: (
        <Text variant="WRN400">{`${statementNums[2].toLocaleString(undefined, {
          minimumFractionDigits: 2
        })} ${ADD} ${statementNums[3].toLocaleString(undefined, {
          minimumFractionDigits: 2
        })}`}</Text>
      ),
      correctAnswer: lessThanGreaterThanOrEqualTo(
        number(
          math.evaluate(
            `${statementNums[0].toLocaleString(undefined, {
              minimumFractionDigits: 2
            })} ${ADD} ${statementNums[1].toLocaleString(undefined, { minimumFractionDigits: 2 })}`
          )
        ),
        number(
          math.evaluate(
            `${statementNums[2].toLocaleString(undefined, {
              minimumFractionDigits: 2
            })} ${ADD} ${statementNums[3].toLocaleString(undefined, { minimumFractionDigits: 2 })}`
          )
        )
      )
    };

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatement()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatement()}
        itemVariant="square"
        statements={[statement]}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddDecimalsWithTheSameNumberOfDecimalPlaces',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
