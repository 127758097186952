import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { projectSetState, SetState } from '../../utils/react';
import CompleteTheSentence from './CompleteTheSentence';
import { withStateHOC } from '../../stateTree';
import { filledArray } from '../../utils/collections';
import { parseMarkup } from '../../markup';

type Props<UserAnswer extends string[][]> = {
  /**
   * Array of arrays of strings to display in each text input. Each element of the outer array corresponds to a
   * sentence, and the elements of that array correspond to the strings in each text input.
   *
   * For example, [['1', '2'], ['12']] corresponds to two sentences, the first with 2 user inputs and the
   * second with 1.
   */
  value: UserAnswer;
  onValueChanged: SetState<UserAnswer>;
  /**
   * Array of sentences to display on the screen, using '<ans/>' to denote a place where an answer text input should go.
   * Elements of this array correspond to elements in the prop `value`.
   *
   * For example: ['The tens digit is <ans/>, the ones digit is <ans/>.', 'The number is <ans/>.']
   *
   * See {@link parseMarkup} for more information about this syntax.
   */
  sentences: string[];
  /** Whether the auto-focus the first text input. Default: false. */
  autoFocus?: boolean;
  inputMaxCharacters?: number;
  inputStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
};

/**
 * Several sentences with text input boxes in each, one above the other.
 */
export default function CompleteTheSentences<UserAnswer extends string[][]>({
  value,
  onValueChanged,
  sentences,
  autoFocus = false,
  inputMaxCharacters,
  inputStyle,
  sentenceStyle,
  style,
  textStyle,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle
}: Props<UserAnswer>) {
  return (
    <View style={[styles.sentences, style]}>
      {sentences.map((sentence, index) => (
        <CompleteTheSentence
          key={index}
          value={value[index]}
          onValueChanged={projectSetState(onValueChanged, index)}
          sentence={sentence}
          autoFocus={autoFocus && index === 0}
          inputMaxCharacters={inputMaxCharacters}
          style={sentenceStyle}
          inputStyle={inputStyle}
          textStyle={textStyle}
          fractionContainerStyle={fractionContainerStyle}
          fractionDividerStyle={fractionDividerStyle}
          fractionTextStyle={fractionTextStyle}
        />
      ))}
    </View>
  );
}

/** See {@link CompleteTheSentences}. */
export const CompleteTheSentencesWithState = withStateHOC(CompleteTheSentences, {
  stateProp: 'value',
  setStateProp: 'onValueChanged',
  defaults: props => ({
    testComplete: state => state.every(sentence => sentence.every(it => it !== '')),
    defaultState: props.sentences
      .map(parseMarkup)
      .map(it => it.numberOfAns)
      .map(numberOfAns => filledArray('', numberOfAns))
  })
});

const styles = StyleSheet.create({
  sentences: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    rowGap: 8
  }
});
