import { ADD, SUB, MULT, DIV } from '../constants';
import { all, create, number } from 'mathjs';
// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

/**
 * An object representing an equation of a binary operation ? of the form:
 * - left ? right = result, or
 * - result = left ? right.
 */
export type BinOpEquation = {
  left: number;
  right: number;
  result: number;
  sign: 'add' | 'subtract' | 'multiply' | 'divide';
  /** indicates that it goes result = left ? right instead */
  flipped: boolean;
  /** indicates which part of the equation the user is supposed to enter */
  answer: 'left' | 'right' | 'result';
};

/** Get a binary operation equation representation. At least 2 of left/right/result must be given. */
export function getBinOpEquation({
  left,
  right,
  result,
  sign,
  flipped = false,
  answer = 'result'
}: {
  left?: number;
  right?: number;
  result?: number;
  sign: 'add' | 'subtract' | 'multiply' | 'divide';
  /** Default: false */
  flipped?: boolean;
  /** Default: 'result'; */
  answer?: 'left' | 'right' | 'result';
}): BinOpEquation {
  // We allow the caller to specify just two on left/right/result. Work out the third.
  if (left === undefined) {
    if (right === undefined || result === undefined) {
      throw Error('2 of left/right/result must be given');
    }
    switch (sign) {
      case 'add':
        left = number(math.evaluate(`${result} - ${right}`));
        break;
      case 'subtract':
        left = number(math.evaluate(`${result} + ${right}`));
        break;
      case 'multiply':
        left = number(math.evaluate(`${result} / ${right}`));
        break;
      case 'divide':
        left = number(math.evaluate(`${result} * ${right}`));
        break;
    }
  }

  if (right === undefined) {
    if (left === undefined || result === undefined) {
      throw Error('2 of left/right/result must be given');
    }
    switch (sign) {
      case 'add':
        right = number(math.evaluate(`${result} - ${left}`));
        break;
      case 'subtract':
        right = number(math.evaluate(`${left} - ${result}`));
        break;
      case 'multiply':
        right = number(math.evaluate(`${result} / ${left}`));
        break;
      case 'divide':
        right = number(math.evaluate(`${left} / ${result}`));
        break;
    }
  }

  if (result === undefined) {
    if (left === undefined || right === undefined) {
      throw Error('2 of left/right/result must be given');
    }
    switch (sign) {
      case 'add':
        result = number(math.evaluate(`${left} + ${right}`));
        break;
      case 'subtract':
        result = number(math.evaluate(`${left} - ${right}`));
        break;
      case 'multiply':
        result = number(math.evaluate(`${left} * ${right}`));
        break;
      case 'divide':
        result = number(math.evaluate(`${left} / ${right}`));
        break;
    }
  }

  return { left, right, result, sign, flipped, answer };
}

/**
 * Converts a binary operation equation to a sentence string, using our custom markup language - for use in
 * CompleteTheSentence.
 */
export function binOpEquationToSentenceString({
  left,
  right,
  result,
  sign,
  flipped,
  answer
}: BinOpEquation): string {
  let symbol: string;
  switch (sign) {
    case 'add':
      symbol = ADD;
      break;
    case 'subtract':
      symbol = SUB;
      break;
    case 'multiply':
      symbol = MULT;
      break;
    case 'divide':
      symbol = DIV;
      break;
  }

  if (flipped) {
    return `${answer === 'result' ? '<ans/>' : result.toLocaleString()} = ${
      answer === 'left' ? '<ans/>' : left.toLocaleString()
    } ${symbol} ${answer === 'right' ? '<ans/>' : right.toLocaleString()}`;
  } else {
    return `${answer === 'left' ? '<ans/>' : left.toLocaleString()} ${symbol} ${
      answer === 'right' ? '<ans/>' : right.toLocaleString()
    } = ${answer === 'result' ? '<ans/>' : result.toLocaleString()}`;
  }
}

/** Converts a binary operation equation to a simple checking function, which takes a single user input value. */
export function binOpEquationToCheck(eq: BinOpEquation): (userAnswer: number | string) => boolean {
  return userAnswer => {
    userAnswer = typeof userAnswer === 'number' ? userAnswer.toString() : userAnswer;
    return userAnswer === eq[eq.answer].toString();
  };
}

/**
 * Converts a binary operation equation to a TestCorrect for use in QF2. The userAnswer is assumed to be of form:
 * [['1'], ['7'], ['9']]
 */
export function binOpEquationsToTestCorrect(eqs: BinOpEquation[]): string[][] {
  return eqs.map(eq => [eq[eq.answer].toString()]);
}

type AlignedEquations = {
  leftSide: string[];
  rightSide: string[];
  leftAnswers: string[][];
  rightAnswers: string[][];
};
/**
 * Converts an array of binOpEquations into the left and right hand side equations and answers for aligned equations.
 */
export function eqsToAlignedEqs(eqs: BinOpEquation[]): AlignedEquations {
  const allAnswers = binOpEquationsToTestCorrect(eqs);
  return eqs.reduce<AlignedEquations>(
    (acc, currentEq, idx) => {
      const equationString = binOpEquationToSentenceString(currentEq);
      const [lhs, rhs] = equationString.split('=');
      if (!lhs?.trim() || !rhs?.trim()) {
        throw new Error(`Malformed equation: ${equationString}`);
      }
      // Update LHS + RHS
      acc.leftSide.push(lhs);
      acc.rightSide.push(rhs);
      // Figure out answer positioning
      const isAnswerOnRight = currentEq.answer === 'result';
      const currentAnswers = allAnswers[idx];
      acc.leftAnswers.push(isAnswerOnRight ? [] : currentAnswers);
      acc.rightAnswers.push(isAnswerOnRight ? currentAnswers : []);
      return acc;
    },
    {
      leftSide: [],
      rightSide: [],
      leftAnswers: [],
      rightAnswers: []
    }
  );
}
