import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { MULT } from '../../../../constants';
import { getRandomObject, objectAsWord, objectSchema } from '../../../../utils/objects';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { range } from '../../../../utils/collections';
import { getObjectImage } from '../../../../utils/objectsImages';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { numberEnum } from '../../../../utils/zod';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { useMemo } from 'react';
import QF2DraggableAlignedEquations from '../../../../components/question/questionFormats/QF2DraggableAlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'al3',
  description: 'al3',
  keywords: ['Multiply', '1', 'Object'],
  schema: z.object({
    number: z.number().int().min(2).max(8),
    object: objectSchema,
    multiplierOrMultiplicand: z.enum(['multiplier', 'multiplicand'])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 8);

    const object = getRandomObject();

    const multiplierOrMultiplicand = getRandomFromArray(['multiplier', 'multiplicand'] as const);

    return { number, object, multiplierOrMultiplicand };
  },
  Component: props => {
    const {
      question: { number, object, multiplierOrMultiplicand },
      translate
    } = props;

    const [groups, amountPerGroup] = (() => {
      if (number > 6) {
        // If number is more than 6, we cannot show this - no images exist of 8 of these objects, so have to override this:
        return [number, 1];
      } else {
        if (multiplierOrMultiplicand === 'multiplier') {
          return [number, 1];
        } else {
          return [1, number];
        }
      }
    })();

    const objectPlural = objectAsWord(object, translate, true);

    const topLine = groups <= 5 ? groups : Math.ceil(groups / 2);
    const botLine = groups - topLine;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeMultiplication()}
        sentence={translate.answerSentences.ansMultAnsEqualsAnsObjects(objectPlural)}
        testCorrect={answer =>
          ((answer[0] === '1' && answer[1] === number.toString()) ||
            (answer[0] === number.toString() && answer[1] === '1')) &&
          answer[2] === number.toString()
        }
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <View
            style={{
              alignSelf: 'center',
              rowGap: 8
            }}
          >
            <View style={{ flexDirection: 'row', columnGap: 8 }}>
              {range(1, topLine).map(index => (
                <View key={index}>
                  {getObjectImage(
                    object,
                    amountPerGroup,
                    dimens.height / 2.5,
                    dimens.width / topLine
                  )}
                </View>
              ))}
            </View>
            <View style={{ flexDirection: 'row', columnGap: 8 }}>
              {botLine !== 0 &&
                range(1, botLine).map(index => (
                  <View key={index}>
                    {getObjectImage(
                      object,
                      amountPerGroup,
                      dimens.height / 2.5,
                      dimens.width / topLine
                    )}
                  </View>
                ))}
            </View>
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number.toLocaleString(),
            (1).toLocaleString(),
            number.toLocaleString()
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'al4',
  description: 'al4',
  keywords: ['Multiply', '0', 'Object'],
  schema: z.object({
    groups: z.number().int().min(2).max(10),
    object: objectSchema
  }),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 10);

    const object = getRandomObject();

    return { groups, object };
  },
  Component: props => {
    const {
      question: { groups, object },
      translate
    } = props;

    const objectPlural = objectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansMultAnsEqualsAnsObjects(objectPlural)}
        title={translate.instructions.completeMultiplication()}
        testCorrect={answer =>
          ((answer[0] === '0' && answer[1] === groups.toString()) ||
            (answer[0] === groups.toString() && answer[1] === '0')) &&
          answer[2] === '0'
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => {
          const topLine = groups <= 5 ? groups : Math.ceil(groups / 2);
          const botLine = groups - topLine;
          return (
            <View
              style={{
                alignSelf: 'center',
                rowGap: 8
              }}
            >
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {range(1, topLine).map(index => (
                  <View key={index}>
                    {getObjectImage(object, 0, dimens.height / 2.5, dimens.width / topLine)}
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {botLine !== 0 &&
                  range(1, botLine).map(index => (
                    <View key={index}>
                      {getObjectImage(object, 0, dimens.height / 2.5, dimens.width / topLine)}
                    </View>
                  ))}
              </View>
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [groups.toLocaleString(), (0).toLocaleString(), (0).toLocaleString()],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'al5',
  description: 'al5',
  keywords: ['Multiply', '0', '1'],
  schema: z.object({
    number1A: z.number().int().min(1).max(12),
    number1B: z.number().int().min(1).max(12),
    number1C: z.number().int().min(1).max(12),
    number1D: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const number1A = randomIntegerInclusive(1, 12);
    const number1B = randomIntegerInclusive(1, 12);
    const number1C = randomIntegerInclusive(1, 12);
    const number1D = randomIntegerInclusive(1, 12);

    return { number1A, number1B, number1C, number1D };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, number1D },
      translate
    } = props;

    // Randomly order these statements
    const sentences = useMemo(() => {
      const lineA = {
        sentence: `${number1A.toLocaleString()} ${MULT} <ans /> = ${number1A.toLocaleString()}`,
        answer: 1
      };

      const lineB = {
        sentence: `${number1B.toLocaleString()} ${MULT} <ans /> = 0`,
        answer: 0
      };

      const lineC = {
        sentence: `<ans /> ${MULT} ${number1C.toLocaleString()} = ${number1C.toLocaleString()}`,
        answer: 1
      };

      const lineD = {
        sentence: `<ans /> ${MULT} ${number1D.toLocaleString()} = 0`,
        answer: 0
      };
      return shuffle([lineA, lineB, lineC, lineD], { random: seededRandom(props.question) });
    }, [number1A, number1B, number1C, number1D, props.question]);

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentences()}
        pdfLayout="itemsRight"
        actionPanelVariant="end"
        items={[0, 1]}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'al52',
  description: 'al5',
  keywords: ['Multiply', '0', '1'],
  schema: z.object({
    number1A: z.number().int().min(1).max(12),
    number1B: z.number().int().min(1).max(12),
    number1C: z.number().int().min(1).max(12),
    number1D: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const number1A = randomIntegerInclusive(1, 12);
    const number1B = randomIntegerInclusive(1, 12);
    const number1C = randomIntegerInclusive(1, 12);
    const number1D = randomIntegerInclusive(1, 12);

    return { number1A, number1B, number1C, number1D };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, number1D },
      translate
    } = props;

    // Randomly order these statements
    const sentences: { left: string; right: string; answer: number }[] = useMemo(() => {
      const string0 = (0).toLocaleString();
      const lineA = {
        left: `${number1A.toLocaleString()} ${MULT} <ans />`,
        right: `${number1A.toLocaleString()}`,
        answer: 1
      };

      const lineB = {
        left: `${number1B.toLocaleString()} ${MULT} <ans />`,
        right: `${string0}`,
        answer: 0
      };

      const lineC = {
        left: `<ans /> ${MULT} ${number1C.toLocaleString()}`,
        right: `${number1C.toLocaleString()}`,
        answer: 1
      };

      const lineD = {
        left: `<ans /> ${MULT} ${number1D.toLocaleString()}`,
        right: `${string0}`,
        answer: 0
      };
      return shuffle([lineA, lineB, lineC, lineD], {
        random: seededRandom(props.question)
      });
    }, [number1A, number1B, number1C, number1D, props.question]);

    // All answers are on the lhs
    const answers = sentences.map(({ answer }) => ({ left: [answer] }));
    return (
      <QF2DraggableAlignedEquations
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentences()}
        actionPanelVariant="end"
        items={[0, 1].map(value => ({ value, component: value.toLocaleString() }))}
        sentences={sentences}
        testCorrect={answers}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'al6',
  description: 'al6',
  keywords: ['Multiply', '0', '1'],
  schema: z.object({
    numberA1: z.number().int().min(1).max(12),
    numberB1: z.number().int().min(1).max(12),
    numberC1: z.number().int().min(1).max(12),
    numberD1: z.number().int().min(1).max(12)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(1, 12);
    const numberB1 = randomIntegerInclusive(1, 12);
    const numberC1 = randomIntegerInclusive(1, 12);
    const numberD1 = randomIntegerInclusive(1, 12);

    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 1, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 0, sign: 'multiply', answer: 'result' });

    const eqC = getBinOpEquation({ left: 1, right: numberC1, sign: 'multiply', answer: 'result' });

    const eqD = getBinOpEquation({ left: 0, right: numberD1, sign: 'multiply', answer: 'result' });

    // Randomly order these equations
    const eqs = shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'al7',
  description: 'al7',
  keywords: ['Multiple', '0'],
  schema: z.object({
    numbers: z.number().int().min(2).max(9).array().length(4)
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(2, 9, 4);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const [A1, A2, A3, A4] = numbers;

    const equations = shuffle(
      [
        { component: `${(0).toLocaleString()} ${MULT} ${(0).toLocaleString()}`, value: '0a' },
        { component: `${(1).toLocaleString()} ${MULT} ${(0).toLocaleString()}`, value: '0b' },
        { component: `${(1).toLocaleString()} ${MULT} ${(1).toLocaleString()}`, value: '1a' },
        {
          component: `${(1).toLocaleString()} ${MULT} ${(10).toLocaleString()}`,
          value: 'not equal a'
        },
        { component: `${A1.toLocaleString()} ${MULT} ${(0).toLocaleString()}`, value: '0c' },
        {
          component: `${(1).toLocaleString()} ${MULT} ${A2.toLocaleString()}`,
          value: 'not equal b'
        },
        {
          component: `${A3.toLocaleString()} ${MULT} ${(1).toLocaleString()} ${MULT} ${(0).toLocaleString()}`,
          value: '0d'
        },
        { component: `${(0).toLocaleString()} ${MULT} ${A4.toLocaleString()}`, value: '0e' }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichCalcsHaveAnswerOfX(0)}
        testCorrect={equations.filter(({ value }) => value.includes('0')).map(eq => eq.value)}
        multiSelect
        items={equations}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'al8',
  description: 'al8',
  keywords: ['Multiply', '0', '1'],
  schema: z.object({
    digits: z.array(z.number().int().min(0).max(9)).length(4),
    number1: z.number().int().min(101).max(991),
    number2: numberEnum([0, 1])
  }),
  simpleGenerator: () => {
    const randomNumberA = randomIntegerInclusive(1, 9);
    const randomNumberB = randomIntegerInclusive(1, 9);
    const number2 = getRandomFromArray([0, 1] as const);

    const digits = [0, 1, randomNumberA, randomNumberB];

    let number1;

    if (number2 === 0) {
      // Remove the zero and shuffle number for randomisation
      number1 = Number(shuffle(digits.filter(digit => digit !== 0)).join(''));
    } else {
      // Copy digits array
      let numArray = [...digits];
      // Get the index of the first occurance of 1
      const index = numArray.indexOf(1);
      if (index > -1) {
        // Remove the first 1 from the array to prevent duplicates
        numArray.splice(index, 1);
      }

      // While the first index of numArray is 0
      while (numArray[0] === 0) {
        // Shuffle array so 0 isn't the first number
        numArray = shuffle(numArray);
      }

      // Convert numArray to number
      number1 = Number(numArray.join(''));
    }

    return { digits, number1, number2 };
  },
  Component: props => {
    const {
      question: { digits, number1, number2 },
      translate
    } = props;

    const number3 = number1 * number2;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsCompleteNumberSentence()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentences()}
        items={digits}
        sentence={`<ans/> <ans/> <ans/> ${MULT} <ans/> = ${number3.toLocaleString()}`}
        testCorrect={ans => {
          const [ans1, ans2, ans3, ans4] = ans;

          const lhs = Number([ans1, ans2, ans3].join(''));

          const product = lhs * (ans4 as number);

          return product === number3;
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidMultiplicationUsingAvailCards()
        }}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy1And0',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
