import { StyleProp, View, ViewStyle } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import { NonEmptyArray, filledArray, range } from '../../../utils/collections';
import { getRandomFromArray, seededRandom } from '../../../utils/random';
import { ArrayOfObjectsColors } from '../../../theme/colors';
import { ArrayObjectName } from '../../../utils/arrayObjects';
import { getArrayObjectSvgName } from '../../../utils/arrayObjectsImages';
import { AssetSvg } from '../../../assets/svg';

type Props = {
  /**
   * Number of rows in the array of objects.
   */
  rows: number;
  /**
   * Number of columns in the array of objects.
   */
  columns: number;
  /**
   * Custom color to pass to the array. Optional prop, defaults to undefined and picks a random ArrayOfObjectsColor.
   */
  color?: string;
  /**
   * Image to be used instead of counters in the array. Must be of type ArrayObjectName.
   * This will be rendered in a square container, even if the SVG itself isn't square.
   */
  image?: ArrayObjectName;
  /**
   * Custom JSX Element to be used instead of counters, usually an SVG.
   */
  customImage?: JSX.Element;
  /**
   * Size of each array object/counter.
   * Will be calculated automatically for the array to take up as much space as possible by default,
   * with object/counters size proportionally.
   */
  counterSize?: number;
  /**
   * Styling to be passed to the array's outer container. Overrides any default styling within.
   */
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Style for per row i.e if you want to have a columnGap between objects
   */
  rowStyle?: StyleProp<ViewStyle>;
  /**
   * Usable dimensions for the question
   */
  dimens: Dimens;
};

/**
 * This component renders an array of objects, which will be rows x columns in size.
 * By default, these objects will be colored counters.
 */
export const ArrayOfObjects = ({
  rows,
  columns,
  color,
  image,
  customImage,
  counterSize,
  containerStyle,
  rowStyle,
  dimens
}: Props) => {
  const counterColor =
    color ??
    // Default to random color, using rows and columns as a random seed
    getRandomFromArray(Object.values(ArrayOfObjectsColors) as NonEmptyArray<string>, {
      random: seededRandom({ rows, columns })
    });

  const containerMargin = 10;

  const usableWidth = dimens.width - containerMargin;
  const usableHeight = dimens.height - containerMargin;

  const maxWidthOfCounter = Math.floor(usableWidth / columns);
  const maxHeightOfCounter = Math.floor(usableHeight / rows);

  const sizeOfCounter = Math.min(maxWidthOfCounter, maxHeightOfCounter);

  const rowsArray = range(1, rows);

  return (
    <View
      style={[
        {
          width: usableWidth,
          height: usableHeight,
          flexDirection: 'column',
          justifyContent: 'center'
        },
        containerStyle
      ]}
    >
      {rowsArray.map(index => {
        return (
          <Row
            counterSize={counterSize ?? sizeOfCounter}
            columns={columns}
            key={index}
            color={counterColor}
            image={image}
            customImage={customImage}
            rowStyle={rowStyle}
          />
        );
      })}
    </View>
  );
};

const Row = ({
  counterSize,
  columns,
  color,
  image,
  customImage,
  rowStyle
}: {
  counterSize: number;
  columns: number;
  color: string;
  image?: ArrayObjectName;
  customImage?: JSX.Element;
  rowStyle?: StyleProp<ViewStyle>;
}) => {
  const columnsArray = filledArray('', columns);

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center'
        },
        rowStyle
      ]}
    >
      {columnsArray.map((_, index) => (
        <CounterRepresentative
          key={index}
          image={image}
          customImage={customImage}
          counterSize={counterSize}
          color={color}
        />
      ))}
    </View>
  );
};

const CounterRepresentative = ({
  image,
  customImage,
  counterSize,
  color
}: {
  image?: ArrayObjectName;
  customImage?: JSX.Element;
  counterSize: number;
  color: string;
}) => {
  return customImage ? (
    customImage
  ) : image ? (
    <AssetSvg
      name={getArrayObjectSvgName(image)}
      width={counterSize - 2}
      height={counterSize - 2}
      style={{ margin: 1 }}
    />
  ) : (
    <AssetSvg
      name="CounterCustomizable"
      width={counterSize - 2}
      height={counterSize - 2}
      svgProps={{ color: color }}
      style={{ margin: 1 }}
    />
  );
};
