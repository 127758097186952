import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF50aDraggableProtractor from '../../../../components/question/questionFormats/QF50aDraggableProtractor';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getRandomUniqueOneInteriorBottomAngleShape } from '../../../../utils/shapeImages/angles';
import { isInRange } from '../../../../utils/matchers';
import { DraggableProtractorWithState } from '../../../../components/question/representations/DraggableProtractor';
import AnglesAroundAPoint from '../../../../components/question/representations/AnglesAroundAPoint';
import { algebraicSymbolSchema, getAlgebraicSymbolPair } from '../../../../utils/algebraicSymbols';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF50bRotatableProtractor from '../../../../components/question/questionFormats/QF50bRotatableProtractor';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'az6',
  description: 'az6',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    angle: z.number().int().min(5).max(175).multipleOf(5)
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusiveStep(5, 175, 5);

    return { angle };
  },
  Component: ({ question: { angle }, translate, displayMode }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheSizeOfTheAngle()}
        Content={
          <AngleFromLines
            lineLength={displayMode === 'digital' ? 350 : 400}
            degrees={[90 - angle, 90]}
            protractor={displayMode === 'digital' ? true : false}
          />
        }
        sentence={translate.answerSentences.ansDeg()}
        testCorrect={[angle.toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'az7',
  description: 'az7',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    angle: z
      .number()
      .int()
      .min(1)
      .max(179)
      .refine(x => x % 5 !== 0),
    tilt: z.number().int().min(5).max(30)
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(1, 179, { constraint: x => x % 5 !== 0 });
    const tilt = randomIntegerInclusive(5, 30);

    return { angle, tilt };
  },
  Component: ({ question: { angle, tilt }, translate, displayMode }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheSizeOfTheAngle()}
        Content={
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              transform: [{ rotate: `-${tilt}deg` }]
            }}
          >
            <AngleFromLines
              lineLength={displayMode === 'digital' ? 350 : 400}
              degrees={[90 - angle, 90]}
              protractor={displayMode === 'digital' ? true : false}
            />
          </View>
        }
        inputMaxCharacters={3}
        sentence={translate.answerSentences.ansDeg()}
        testCorrect={([userAnswer]) =>
          [(angle - 1).toString(), angle.toString(), (angle + 1).toString()].includes(userAnswer)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [angle.toLocaleString()],
          answerText: translate.markScheme.allowMarginOfErrorOfX(1)
        }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'az8',
  description: 'az8',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    shapeObj: z.object({
      svgName: z.string(),
      shape: z.string(),
      angleSize: z.number().int()
    })
  }),
  simpleGenerator: () => {
    const shapeObj = getRandomUniqueOneInteriorBottomAngleShape(1)[0];

    return { shapeObj };
  },
  Component: ({ question: { shapeObj }, translate, displayMode }) => {
    const { angleSize, svgName } = shapeObj;

    const digitalWidth = [
      'Shapes_with_interior_angles/Regular_pentagon_one_interior_angle',
      'Shapes_with_interior_angles/Irregular_heptagon_one_interior_angle'
    ].includes(svgName)
      ? 300
      : 350;

    return (
      <QF50aDraggableProtractor
        title={translate.instructions.dragTheProtractorToMeasureTheSizeOfTheMarkedAngle()}
        pdfTitle={translate.instructions.useAProtractorToMeasureTheSizeOfTheMarkedAngle()}
        questionHeight={1000}
        shapeImage={
          <AssetSvg
            name={svgName as SvgName}
            width={displayMode === 'digital' ? digitalWidth : 560}
          />
        }
        sentence={'<ans />°'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={ans => isInRange(angleSize - 1, angleSize + 1)(parseFloat(ans[0]))}
        customMarkSchemeAnswer={{
          answersToDisplay: [angleSize.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'az9',
  description: 'az9',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    startingAngle: z.number().int().min(2).max(18),
    angle: z.number().int().min(21).max(159),
    side: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const startingAngle = randomIntegerInclusive(2, 18);
    const angle = randomIntegerInclusive(21, 159, {
      constraint: x => x % 5 !== 0
    });
    const side = getRandomFromArray(['left', 'right'] as const);
    return { angle, startingAngle, side };
  },
  Component: ({ question: { angle, startingAngle, side }, translate }) => {
    return (
      <QF50bRotatableProtractor
        title={translate.instructions.measureTheSizeOfTheAngleDragToRotateProtractor()}
        pdfTitle={translate.instructions.measureTheSizeOfTheAngle()}
        sentence="<ans/>°"
        testCorrect={userAnswer => isInRange(angle - 1, angle + 1)(parseInt(userAnswer[0]))}
        shapeToMeasure={{
          type: 'angleFromLines',
          angleSize: angle,
          side
        }}
        additionalRotation={startingAngle}
        customMarkSchemeAnswer={{
          answersToDisplay: [angle.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
      />
    );
  }
});

export const az9 = Question4;

const Question5 = newQuestionContent({
  uid: 'aAa',
  description: 'aAa',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    start: z.number().int().min(5).max(40).multipleOf(5),
    end: z.number().int().min(65).max(175).multipleOf(5)
  }),
  simpleGenerator: () => {
    const start = randomIntegerInclusiveStep(5, 40, 5);
    const end = randomIntegerInclusiveStep(65, 175, 5);

    return { start, end };
  },
  Component: ({ question: { start, end }, translate, displayMode }) => {
    const angle = end - start;

    const acceptableAnswers = [angle.toString(), (angle + 1).toString(), (angle - 1).toString()];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheSizeOfTheAngle()}
        pdfDirection="column"
        Content={
          <AngleFromLines
            lineLength={displayMode === 'digital' ? 350 : 400}
            degrees={[90 - end, 90 - start]}
            protractor={displayMode === 'digital' ? true : false}
          />
        }
        inputMaxCharacters={3}
        sentence={translate.answerSentences.ansDeg()}
        testCorrect={userAnswer => {
          return acceptableAnswers.includes(userAnswer[0]);
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [angle.toString()],
          answerText: translate.markScheme.allowMarginOfErrorOfXDegrees(1)
        }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aAb',
  description: 'aAb',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    angleA: z.number().int().min(20).max(89),
    angleB: z.number().int().min(91).max(179),
    letterA: algebraicSymbolSchema,
    letterB: algebraicSymbolSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const angleA = randomIntegerInclusive(20, 89);
    const angleB = randomIntegerInclusive(91, 179, {
      constraint: x => x + angleA === 180
    });

    const [letterA, letterB] = getAlgebraicSymbolPair();

    return { angleA, angleB, letterA, letterB };
  },

  Component: props => {
    const {
      question: { angleA, angleB, letterA, letterB },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[`${letterA} = <ans />°`, `${letterB} = <ans />°`]}
        sentenceStyle={{ justifyContent: 'space-evenly' }}
        style={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        title={translate.instructions.measureTheSizeOfEachAngleDragTheCircleToMoveTheProtractor()}
        pdfTitle={translate.instructions.measureTheSizeOfEachAngleYouWillNeedAProtractorToHelpYou()}
        testCorrect={ans =>
          isInRange(angleA - 1, angleA + 1)(parseFloat(ans[0][0])) &&
          isInRange(angleB - 1, angleB + 1)(parseFloat(ans[1][0]))
        }
        inputMaxCharacters={3}
        Content={({ dimens }) => {
          return (
            <DraggableProtractorWithState
              protractorSize="large"
              absolutePositionProtractorOverShape
              centerHorizontally
              shapeImage={
                <AnglesAroundAPoint
                  radius={dimens.height * 0.9}
                  labelPositionTransformScale={1.5}
                  angles={shuffle(
                    [
                      { angle: angleA, innerLabel: letterA },
                      { angle: angleB, innerLabel: letterB }
                    ],
                    { random: seededRandom(props.question) }
                  )}
                  arcSizeGroups={[[0], [1]]}
                />
              }
              dimens={dimens}
              id={'draggable-protractor'}
            />
          );
        }}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [[angleA.toLocaleString()], [angleB.toLocaleString()]],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureAnglesUpTo180',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
