import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import { getMultiLinkCubeSvgName } from '../../../../utils/multiLinkCubesImages';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import {
  horizontalDoubleEndedArrow,
  VerticalDoubleEndedArrow
} from '../../../../components/question/representations/LineSvgs';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import FlexibleRibbon from '../../../../components/question/representations/FlexibleRibbon';
import ItemsAgainstRuler, {
  calculateScaling
} from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bjE',
  description: 'bjE',
  keywords: ['Length', 'Total', 'Four operations'],
  schema: z
    .object({
      length1: z.number().int().min(10).max(89),
      length2: z.number().int().min(10).max(89),
      color: z.enum(['green', 'red'])
    })
    .refine(
      ({ length1, length2 }) => (
        numbersDoNotExchange(length1, length2), 'lengths should not exchange'
      )
    ),
  simpleGenerator: () => {
    const length1 = randomIntegerInclusive(10, 89);
    const length2 = randomIntegerInclusive(10, 89, {
      constraint: x => numbersDoNotExchange(length1, x)
    });

    const color = getRandomFromArray(['green', 'red'] as const);

    return { length1, length2, color };
  },
  Component: props => {
    const {
      question: { length1, length2, color },
      translate,
      displayMode
    } = props;

    const total = length1 + length2;

    return (
      <QF1ContentAndSentence
        questionHeight={900}
        title={translate.ks1Instructions.whatIsTheTotalLengthOfRibbon()}
        sentence={translate.ks1AnswerSentences.ansCm()}
        testCorrect={[total.toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                ...dimens,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {[length1, length2].map((length, i) => {
                const ribbonWidth = (dimens.width / total) * length - 8;
                return (
                  <View key={`${i}_${length}`}>
                    <FlexibleRibbon
                      ribbonLength={ribbonWidth}
                      color={color}
                      tail={i % 2 === 0 ? 'left' : 'right'}
                    />
                    <View style={{ paddingTop: 8 }}>
                      {horizontalDoubleEndedArrow(ribbonWidth, 3)}
                      <Text
                        variant="WRN400"
                        style={{
                          fontSize: displayMode === 'digital' ? 32 : 50,
                          alignSelf: 'center'
                        }}
                      >
                        {translate.units.numberOfCm(length)}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          );
        }}
      />
    );
  }
});

const objects = [
  'flower',
  'tree',
  'glass',
  'giraffe',
  'elephant',
  'teddyBear',
  'ribbons',
  'rubber',
  'sharpener',
  'pencil'
] as const;
type Objects = (typeof objects)[number];

const Question2 = newQuestionContent({
  uid: 'bjF',
  description: 'bjF',
  keywords: ['Length', 'Height', 'Difference', 'Four operations', 'Centimetres'],
  questionHeight: 1000,
  schema: z.object({
    lengths: z.array(z.number().int().min(1).max(15)).length(2),
    isVertical: z.boolean(),
    objects: z.array(z.enum(objects)).length(2)
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();

    const verticalObjects = [
      'flower',
      'tree',
      'glass',
      'giraffe',
      'elephant',
      'teddyBear'
    ] as const;
    const horizontalObjects = ['ribbons', 'rubber', 'sharpener', 'pencil'] as const;
    const { objects, lengths } = rejectionSample(
      () => {
        const objects = getRandomSubArrayFromArray(
          isVertical ? verticalObjects : horizontalObjects,
          2
        );
        // Some svgs can't be seen well when they are too small/long
        const getMinMax = (obj: Objects): [number, number] => {
          switch (obj) {
            case 'sharpener':
            case 'rubber':
              return [2, 5];
            case 'ribbons':
            case 'pencil':
              return [4, isVertical ? 7 : 15];
            case 'elephant':
            case 'teddyBear':
            case 'tree':
              return [4, 6];
            case 'glass':
              return [1, 7];
            default:
              return [1, isVertical ? 7 : 15];
          }
        };

        const length1 = randomIntegerInclusive(...getMinMax(objects[0]));
        const length2 = randomIntegerInclusive(...getMinMax(objects[1]), {
          constraint: x => length1 !== x
        });
        return { objects, lengths: [length1, length2] };
      },
      ({ lengths }) => Math.abs(lengths[0] - lengths[1]) < 10 // If the length difference it too large the smaller ones cant be seen
    );
    return { objects, lengths, isVertical };
  },
  Component: props => {
    const {
      question: { objects, lengths, isVertical },
      translate
    } = props;
    const random = seededRandom(props.question);
    const getSvgName = (object: Objects) => {
      switch (object) {
        case 'flower':
          return getRandomFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            { random }
          );
        case 'tree':
          return getRandomFromArray(['Tree_A', 'Tree_B'] as const, { random });
        case 'glass':
          return 'Glasses_of_orange_juice/glass1_oj_empty';
        case 'giraffe':
          return 'Giraffe';
        case 'elephant':
          return 'Elephant';
        case 'teddyBear':
          return 'Teddy_bear_1';
        case 'ribbons':
          return getRandomFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, { random });
        case 'rubber':
          return 'Rubber';
        case 'sharpener':
          return 'Pencil_sharpener';
        case 'pencil':
          return getRandomFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            { random }
          );
      }
    };
    const title = isVertical ? 'whatIsTheDifferenceInHeight' : 'whatIsTheDifferenceInLength';

    const answer = Math.abs(lengths[0] - lengths[1]);
    const svg1 = getSvgName(objects[0]);
    const svg2 = getSvgName(objects[1]);
    const maxLength = Math.max(...lengths);
    const rulerLength = maxLength > 10 ? 15 : maxLength + 2;

    return (
      <QF1ContentAndSentence
        questionHeight={1000}
        title={translate.ks1Instructions[title]()}
        sentence={translate.ks1AnswerSentences.ansCm()}
        testCorrect={[answer.toString()]}
        mainPanelStyle={{ flexDirection: !isVertical ? 'row' : 'column' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => {
          const scaleFactor = Math.min(
            ...lengths.map((length, i) =>
              calculateScaling({
                width: isVertical ? dimens.width * 0.45 : dimens.width * 0.9,
                height: isVertical ? dimens.height * 0.9 : dimens.height * 0.45,
                vertical: isVertical,
                rulerKind: 'cm',
                rulerLength,
                items: [
                  {
                    length,
                    svgInfo: { name: i === 0 ? svg1 : svg2 },
                    guidelines: true
                  }
                ]
              })
            )
          );
          return (
            <View
              style={{
                ...dimens,
                flexDirection: isVertical ? 'row' : 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center'
              }}
            >
              {lengths.map((length, i) => (
                <ItemsAgainstRuler
                  key={`${i}_${length}`}
                  width={dimens.width * 0.9}
                  height={dimens.height * 0.9}
                  vertical={isVertical}
                  scaleFactor={scaleFactor}
                  rulerKind="cm"
                  rulerLength={rulerLength}
                  items={[
                    {
                      length,
                      svgInfo: { name: i === 0 ? svg1 : svg2 },
                      guidelines: true
                    }
                  ]}
                />
              ))}
            </View>
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjG',
  description: 'bjG',
  keywords: ['Height', 'Centimetres', 'Cube', 'Multiplication', 'Division'],
  questionHeight: 900,
  schema: z.object({
    cubes: numberEnum([2, 5]),
    cubeHeight: z.number().int().min(2).max(9),
    colour: z.enum(['Orange', 'Blue', 'Purple', 'Green', 'Red']),
    showTowerHeight: z.boolean()
  }),
  simpleGenerator: () => {
    const cubes = getRandomFromArray([2, 5] as const);
    const cubeHeight = randomIntegerInclusive(2, 9);
    const colour = getRandomFromArray(['Orange', 'Blue', 'Purple', 'Green', 'Red'] as const);
    const showTowerHeight = getRandomBoolean();

    return { cubes, cubeHeight, colour, showTowerHeight };
  },
  Component: props => {
    const {
      question: { cubes, cubeHeight, colour, showTowerHeight },
      translate,
      displayMode
    } = props;

    const columnSvgPath = getMultiLinkCubeSvgName(colour, cubes);

    const translateUnits = translate.units.numberOfCm;

    const { title, label, answer, labelCubes } = (() => {
      if (showTowerHeight) {
        return {
          title: translate.ks1Instructions.towerIsYCubesTowerIsXTallWhatIsHeightOfCube(
            cubes,
            translateUnits(cubeHeight * cubes)
          ),
          answer: cubeHeight,
          label: cubeHeight * cubes,
          labelCubes: cubes
        };
      } else {
        return {
          title: translate.ks1Instructions.cubeIsXTallTowerIsYCubesWhatIsHeight(
            translateUnits(cubeHeight),
            cubes
          ),
          answer: cubeHeight * cubes,
          label: cubeHeight,
          labelCubes: 1
        };
      }
    })();

    return (
      <QF1ContentAndSentence
        questionHeight={900}
        title={title}
        sentence={translate.ks1AnswerSentences.ansCm()}
        testCorrect={[answer.toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => {
          const arrowHeight = (dimens.height / (cubes + 0.8)) * labelCubes;
          return (
            <View
              style={{
                ...dimens,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-end'
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  height: arrowHeight,
                  bottom: dimens.height / (cubes / 0.32),
                  paddingRight: 4
                }}
              >
                <Text
                  variant="WRN400"
                  style={{
                    fontSize: displayMode === 'digital' ? 24 : 32,
                    alignSelf: 'center'
                  }}
                >
                  {translateUnits(label)}
                </Text>
                {VerticalDoubleEndedArrow(arrowHeight, 3)}
              </View>
              <AssetSvg name={columnSvgPath} height={dimens.height} />
            </View>
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FourOperationsWithLengthsAndHeights',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
