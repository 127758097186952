import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { arrayHasNoDuplicates, arraysHaveSameContents } from '../../../../utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import { ADD } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQg',
  description: 'aQg',
  keywords: ['Tenths', 'Hundredths', 'Decimals', 'Place value chart'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tenths: z.number().int().min(0).max(9),
    hundredths: z.number().int().min(0).max(9)
  }),

  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const tenths = randomIntegerInclusive(0, 9);

    const hundredths = randomIntegerInclusive(0, 9, {
      constraint: x => !arraysHaveSameContents([ones, tenths], [x, 0]) //There should be no more than two 0s
    });

    return {
      ones,
      tenths,
      hundredths
    };
  },
  Component: ({ question: { ones, tenths, hundredths }, translate }) => {
    const number = `${ones}.${tenths}${hundredths}`;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        Content={({ dimens }) => (
          <PlaceValueChart
            dimens={{ width: dimens.width, height: dimens.height + 30 }}
            number={ScientificNotation.fromFixedString(number)}
            columnsToShow={[0, -1, -2]}
            counterVariant={'decimalCounter'}
            headerVariant={'numberTitle'}
          />
        )}
        sentences={[
          ones === 1
            ? translate.answerSentences.thereIsAnsOnesAnsTenthsAndAnsHundredths(tenths, hundredths)
            : translate.answerSentences.thereAreAnsOnesAnsTenthsAndAnsHundredthsPlurals(
                tenths,
                hundredths
              ),
          translate.answerSentences.theNumberIsAns()
        ]}
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer[0], [
            ones.toString(),
            tenths.toString(),
            hundredths.toString()
          ]) && compareFloats(userAnswer[1][0], number)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ones.toLocaleString(), tenths.toLocaleString(), hundredths.toLocaleString()],
            [number.toLocaleString()]
          ]
        }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'aQg2',
  description: 'aQg',
  keywords: ['Tenths', 'Hundredths', 'Decimals', 'Place value chart'],
  schema: z.object({
    ones: z.number().int().min(0).max(6),
    tenths: z.number().int().min(0).max(6),
    hundredths: z.number().int().min(0).max(6)
  }),

  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 6);
    const tenths = randomIntegerInclusive(0, 6);

    const hundredths = randomIntegerInclusive(0, 6, {
      constraint: x => !arraysHaveSameContents([ones, tenths], [x, 0]) //There should be no more than two 0s
    });

    return {
      ones,
      tenths,
      hundredths
    };
  },
  Component: ({ question: { ones, tenths, hundredths }, translate }) => {
    const number = `${ones}.${tenths}${hundredths}`;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        Content={({ dimens }) => (
          <PlaceValueChart
            dimens={{ width: dimens.width, height: dimens.height + 30 }}
            number={ScientificNotation.fromFixedString(number)}
            columnsToShow={[0, -1, -2]}
            counterVariant={'decimalCounter'}
            headerVariant={'numberTitle'}
            rowsToUse={2}
          />
        )}
        sentences={[
          ones === 1
            ? translate.answerSentences.thereIsAnsOnesAnsTenthsAndAnsHundredths(tenths, hundredths)
            : translate.answerSentences.thereAreAnsOnesAnsTenthsAndAnsHundredthsPlurals(
                tenths,
                hundredths
              ),
          translate.answerSentences.theNumberIsAns()
        ]}
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer[0], [
            ones.toString(),
            tenths.toString(),
            hundredths.toString()
          ]) && compareFloats(userAnswer[1][0], number)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ones.toLocaleString(), tenths.toLocaleString(), hundredths.toLocaleString()],
            [number.toLocaleString()]
          ]
        }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aQh',
  description: 'aQh',
  keywords: ['Tenths', 'Hundredths', 'Decimals', 'Place value chart'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tenths: z.number().int().min(0).max(9),
    hundredths: z.number().int().min(0).max(9)
  }),

  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const tenths = randomIntegerInclusive(0, 9);

    const hundredths = randomIntegerInclusive(0, 9, {
      constraint: x => !arraysHaveSameContents([ones, tenths], [x, 0]) //There should be no more than two 0s
    });

    return {
      ones,
      tenths,
      hundredths
    };
  },
  Component: ({ question: { ones, tenths, hundredths }, translate }) => {
    const number = Number(`${ones}.${tenths}${hundredths}`);

    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragInCountersToRepresentNum(number.toLocaleString())}
        pdfTitle={translate.instructions.drawCountersToRepresentNum(number.toLocaleString())}
        columnsToShow={[0, -1, -2]}
        number={ScientificNotation.fromFixedString(number.toString())}
        counterVariant={'decimalCounter'}
        headerVariant={'numberTitle'}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aQi',
  description: 'aQi',
  keywords: ['Tenths', 'Hundredths', 'Decimals'],
  questionHeight: 750,
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tenths: z.number().int().min(0).max(9),
    hundredths: z.number().int().min(0).max(9)
  }),

  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const tenths = randomIntegerInclusive(0, 9);

    const hundredths = randomIntegerInclusive(0, 9, {
      constraint: x => !arraysHaveSameContents([ones, tenths], [x, 0]) //There should be no more than two 0s
    });

    return {
      ones,
      tenths,
      hundredths
    };
  },
  Component: ({ question: { ones, tenths, hundredths }, translate }) => {
    const number = Number(`${ones}.${tenths}${hundredths}`);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        Content={
          <Text variant="WRN700" style={{ fontSize: 50 }}>
            {number.toLocaleString()}
          </Text>
        }
        sentences={[
          translate.answerSentences.thereAreAnsOnesAnsTenthsAndAnsHundredths(),
          translate.answerSentences.theNumberIsAns()
        ]}
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer[0], [
            ones.toString(),
            tenths.toString(),
            hundredths.toString()
          ]) && compareFloats(userAnswer[1][0], number)
        }
        pdfDirection={'column'}
        questionHeight={750}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ones.toLocaleString(), tenths.toLocaleString(), hundredths.toLocaleString()],
            [number.toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aQi2',
  description: 'aQi',
  keywords: ['Tenths', 'Hundredths', 'Decimals'],
  questionHeight: 750,
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tenths: z.number().int().min(0).max(9),
    hundredths: z.number().int().min(0).max(9)
  }),

  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const tenths = randomIntegerInclusive(0, 9);

    const hundredths = randomIntegerInclusive(0, 9, {
      constraint: x => !arraysHaveSameContents([ones, tenths], [x, 0]) //There should be no more than two 0s
    });

    return {
      ones,
      tenths,
      hundredths
    };
  },
  Component: ({ question: { ones, tenths, hundredths }, translate }) => {
    const number = Number(`${ones}.${tenths}${hundredths}`);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={
          <Text variant="WRN700" style={{ fontSize: 50 }}>
            {number.toLocaleString()}
          </Text>
        }
        sentence={
          ones === 1
            ? translate.answerSentences.thereIsAnsOnesAnsTenthsAndAnsHundredths(tenths, hundredths)
            : translate.answerSentences.thereAreAnsOnesAnsTenthsAndAnsHundredthsPlurals(
                tenths,
                hundredths
              )
        }
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer, [
            ones.toString(),
            tenths.toString(),
            hundredths.toString()
          ])
        }
        pdfDirection={'column'}
        questionHeight={750}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ones.toLocaleString(),
            tenths.toLocaleString(),
            hundredths.toLocaleString()
          ]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQj',
  description: 'aQj',
  keywords: ['Tenths', 'Hundredths', 'Decimals'],
  schema: z.object({
    numberAsInt: z.number().int().min(123).max(10987),
    uniqueDigit: z.number().int().min(0).max(9),
    position: z.number().int().min(0).max(4),
    wrongPositions: z.array(z.number().int().min(0).max(4)).length(3)
  }),
  simpleGenerator: () => {
    const numberAsInt = randomIntegerInclusive(123, 10987, {
      constraint: x => arrayHasNoDuplicates([...x.toString()])
    });

    const numberAsArray = [...numberAsInt.toString()];

    const uniqueDigit = Number(getRandomFromArray(numberAsArray));
    const index = numberAsArray.findIndex(x => x === uniqueDigit.toString());

    const position = numberAsArray.length - index - 1;
    const wrongPositions = randomUniqueIntegersInclusive(0, 4, 3, {
      constraint: x => x !== position
    });

    return {
      numberAsInt,
      uniqueDigit,
      position,
      wrongPositions
    };
  },
  Component: ({ question: { numberAsInt, uniqueDigit, position, wrongPositions }, translate }) => {
    const number = (numberAsInt / 100).toLocaleString();

    const placeValue = (pos: number) => {
      switch (pos) {
        case 0:
          return translate.fractions.hundredths(uniqueDigit);
        case 1:
          return translate.fractions.tenths(uniqueDigit);
        case 2:
          return translate.powersOfTen.ones(uniqueDigit);
        case 3:
          return translate.powersOfTen.tens(uniqueDigit);
        case 4:
          return translate.powersOfTen.hundreds(uniqueDigit);
      }
    };

    // const x =
    const options = shuffle([position, ...wrongPositions], {
      random: seededRandom({ numberAsInt, uniqueDigit, position, wrongPositions })
    });
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.whatIsValueOfDigitXInY(uniqueDigit, number)}
        testCorrect={[position]}
        numItems={4}
        renderItems={() => {
          return options.map(option => ({
            value: option,
            component: (
              <Text variant="WRN700">{`${uniqueDigit.toLocaleString()} ${placeValue(
                option
              )}`}</Text>
            )
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aQk',
  description: 'aQk',
  keywords: ['Tenths', 'Hundredths', 'Decimals'],
  schema: z
    .object({
      digit1: z.number().int().min(1).max(9),
      digit2: z.number().int().min(0).max(9),
      tenths: z.boolean()
    })
    .refine(val => val.digit1 !== val.digit2, 'Digits must be different.'),

  simpleGenerator: () => {
    const digit1 = randomIntegerInclusive(1, 9);
    const digit2 = randomIntegerInclusive(0, 9, { constraint: x => x !== digit1 });

    const tenths = getRandomBoolean();

    return {
      digit1,
      digit2,
      tenths
    };
  },
  Component: ({ question: { digit1, digit2, tenths }, translate }) => {
    const eqs = shuffle(
      [
        {
          sentence: Number(`${digit1}.${digit2}`).toLocaleString(),
          isCorrect: false
        },
        {
          sentence: Number(`${digit1}${digit2}`).toLocaleString(),
          isCorrect: false
        },
        {
          sentence: Number(`0.${digit1}${digit2}`).toLocaleString(),
          isCorrect: tenths ? true : false
        },
        {
          sentence: Number(`0.${digit2}${digit1}`).toLocaleString(),
          isCorrect: tenths ? false : true
        }
      ],
      { random: seededRandom({ digit1, digit2, tenths }) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          tenths
            ? translate.instructions.selectNumberWhichHasXinTheTenthsPosition(digit1)
            : translate.instructions.selectNumberWhichHasXinTheHundredthsPosition(digit1)
        }
        pdfTitle={
          tenths
            ? translate.instructions.circleNumberWhichHasXinTheTenthsPosition(digit1)
            : translate.instructions.circleNumberWhichHasXinTheHundredthsPosition(digit1)
        }
        testCorrect={eqs.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={4}
        renderItems={() => {
          return eqs.map(equation => ({
            value: equation.sentence,
            component: <Text variant="WRN700">{equation.sentence}</Text>
          }));
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aQl',
  description: 'aQl',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Partition'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(99),
      number2: z.number().int().min(11).max(99),
      number3: z.number().int().min(11).max(99)
    })
    .refine(({ number1, number2, number3 }) => arrayHasNoDuplicates([number1, number2, number3])),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number2, number3] = randomUniqueIntegersInclusive(11, 99, 3, {
      constraint: x => x % 10 !== 0
    });

    return {
      number1,
      number2,
      number3
    };
  },
  Component: ({ question: { number1, number2, number3 }, translate }) => {
    const number1String = number1.toString();
    const tenthsDigit1 = number1String[0];
    const ans1String = number1String[1];

    const number2String = number2.toString();
    const tenthsDigit2 = number2String[0];
    const ans2String = number2String[1];

    const number3String = number3.toString();
    const ans3String = number3String[0];
    const hundredthsDigit3 = number3String[1];

    const answer1 = number(math.evaluate(`${ans1String} / 100`));
    const answer2 = number(math.evaluate(`${ans2String} / 100`));
    const answer3 = number(math.evaluate(`${ans3String} / 10`));

    const LHS = [
      number(math.evaluate(`${number1} / 100`)).toLocaleString(),
      number(math.evaluate(`${number2} / 100`)).toLocaleString(),
      number(math.evaluate(`${number3} / 100`)).toLocaleString()
    ];

    const rightNumA = number(math.evaluate(`${tenthsDigit1} / 10`));
    const rightNumB = number(math.evaluate(`${tenthsDigit2} / 10`));
    const rightNumC = number(math.evaluate(`${hundredthsDigit3} / 100`));

    const RHS = [
      `${rightNumA.toLocaleString()} ${ADD} <ans/>`,
      `${rightNumB.toLocaleString()} ${ADD} <ans/>`,
      `<ans/> ${ADD} ${rightNumC.toLocaleString()}`
    ];

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeCalculations()}
        extraSymbol={'decimalPoint'}
        leftSide={LHS}
        rightSide={RHS}
        inputMaxCharacters={4}
        testCorrect={({ right }) =>
          compareFloats(right[0][0], answer1) &&
          compareFloats(right[1][0], answer2) &&
          compareFloats(right[2][0], answer3)
        }
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: {
            right: [
              [answer1.toLocaleString()],
              [answer2.toLocaleString()],
              [answer3.toLocaleString()]
            ]
          },
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aQl2',
  description: 'aQl',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Partition'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(99),
      number2: z.number().int().min(11).max(99),
      number3: z.number().int().min(11).max(99),
      randomQuestion: numberEnum([1, 2, 3])
    })
    .refine(({ number1, number2, number3 }) => arrayHasNoDuplicates([number1, number2, number3])),
  simpleGenerator: () => {
    const [number1, number2, number3] = randomUniqueIntegersInclusive(11, 99, 3, {
      constraint: x => x % 10 !== 0
    });

    const randomQuestion = getRandomFromArray([1, 2, 3] as const);

    return {
      number1,
      number2,
      number3,
      randomQuestion
    };
  },
  Component: ({ question: { number1, number2, number3, randomQuestion }, translate }) => {
    const number1String = number1.toString();
    const tenthsDigit1 = number1String[0];
    const ans1String = number1String[1];

    const number2String = number2.toString();
    const tenthsDigit2 = number2String[0];
    const ans2String = number2String[1];

    const number3String = number3.toString();
    const ans3String = number3String[0];
    const hundredthsDigit3 = number3String[1];

    const answer1 = number(math.evaluate(`${ans1String} / 100`));
    const answer2 = number(math.evaluate(`${ans2String} / 100`));
    const answer3 = number(math.evaluate(`${ans3String} / 10`));

    const LHS =
      randomQuestion === 1
        ? [number(math.evaluate(`${number1} / 100`)).toLocaleString()]
        : randomQuestion === 2
        ? [number(math.evaluate(`${number2} / 100`)).toLocaleString()]
        : [number(math.evaluate(`${number3} / 100`)).toLocaleString()];

    const rightNumA = number(math.evaluate(`${tenthsDigit1} / 10`));
    const rightNumB = number(math.evaluate(`${tenthsDigit2} / 10`));
    const rightNumC = number(math.evaluate(`${hundredthsDigit3} / 100`));

    const RHS =
      randomQuestion === 1
        ? [`${rightNumA.toLocaleString()} ${ADD} <ans/>`]
        : randomQuestion === 2
        ? [`${rightNumB.toLocaleString()} ${ADD} <ans/>`]
        : [`<ans/> ${ADD} ${rightNumC.toLocaleString()}`];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        sentence={`${LHS} = ${RHS}`}
        inputMaxCharacters={4}
        extraSymbol={'decimalPoint'}
        testCorrect={userAnswer =>
          randomQuestion === 1
            ? compareFloats(userAnswer[0], answer1)
            : randomQuestion === 2
            ? compareFloats(userAnswer[0], answer2)
            : compareFloats(userAnswer[0], answer3)
        }
        customMarkSchemeAnswer={{
          answersToDisplay:
            randomQuestion === 1
              ? [answer1.toLocaleString()]
              : randomQuestion === 2
              ? [answer2.toLocaleString()]
              : [answer3.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DecimalsUpTo2DecimalPlaces',
  questionTypes: [Question1v2, Question2, Question3v2, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question1, Question3, Question6]
});
export default SmallStep;
