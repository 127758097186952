import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { range, rangeExcluding } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { roundToTheNearest } from '../../../../utils/math';
import { ac7 } from '../../../Year 4/Autumn/PlaceValue/17RoundToTheNearest10100Or1000';
import { ac3 } from '../../../Year 4/Autumn/PlaceValue/16RoundToTheNearest1000';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aee',
  description: 'aee',
  keywords: ['Place value', 'Round', 'Number line', '10', '100', '1,000'],
  schema: z.object({
    number: z.number().int().min(110).max(9990),
    round: z.union([z.literal(10), z.literal(100), z.literal(1000)])
  }),
  example: {
    number: 8300,
    round: 1000
  },
  simpleGenerator: () => {
    const round = getRandomFromArray([10, 100, 1000] as const);
    let number = 0;
    switch (round) {
      case 10: {
        const ones = randomIntegerInclusive(1, 9);
        const tens = randomIntegerInclusive(1, 8);
        const hundreds = randomIntegerInclusive(1, 9);
        number = hundreds * 100 + tens * 10 + ones;
        break;
      }
      case 100: {
        const hundreds = randomIntegerInclusive(1, 9);
        const tens = randomIntegerInclusive(1, 9);
        number = hundreds * 100 + tens * 10;
        break;
      }
      case 1000: {
        const hundreds = randomIntegerInclusive(1, 9);
        const thousands = randomIntegerInclusive(1, 9);
        number = thousands * 1000 + hundreds * 100;
        break;
      }
    }
    return { number, round };
  },
  Component: props => {
    const {
      question: { number, round },
      translate
    } = props;
    const startingNumber = Math.floor(number / round) * round;
    const endNumber = Math.ceil(number / round) * round;
    const tickInterval = round / 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest(number, round)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, round).toString()]}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aef',
  description: 'aef',
  keywords: ['Place value', 'Round', 'Number line', '10', '100', '1,000'],
  schema: z.object({
    number: z.number().int().min(111).max(9990),
    round: z.union([z.literal(10), z.literal(100), z.literal(1000)])
  }),
  example: {
    number: 8355,
    round: 100
  },
  simpleGenerator: () => {
    const round = getRandomFromArray([100, 1000] as const);
    let number = 0;
    switch (round) {
      case 100: {
        const hundreds = randomIntegerInclusive(1, 9);
        const tens = randomIntegerInclusive(1, 9);
        const ones = randomIntegerInclusive(1, 9);
        number = hundreds * 100 + tens * 10 + ones;
        break;
      }
      case 1000: {
        const tens = randomIntegerInclusive(1, 9);
        const hundreds = randomIntegerInclusive(1, 9);
        const thousands = randomIntegerInclusive(1, 9);
        number = thousands * 1000 + hundreds * 100 + tens * 10;
        break;
      }
    }
    return { number, round };
  },
  Component: props => {
    const {
      question: { number, round },
      translate
    } = props;
    const startingNumber = Math.floor(number / round) * round;
    const endNumber = Math.ceil(number / round) * round;
    const tickInterval = round / 10;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest(number, round)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, round).toString()]}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aeg',
  description: 'aeg',
  keywords: ['Place value', 'Round', '10', '100', '1,000'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(11).max(9999)).min(6).max(6),
      round: z.number().int().min(10).max(1000)
    })
    .refine(
      val => val.round === 10 || val.round === 100 || val.round === 1000,
      'Round must be either 10, 100 or 1000'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const round = getRandomFromArray([10, 100, 1000] as const);
    const numbers: number[] = [];

    switch (round) {
      case 10: {
        const tens = randomIntegerInclusive(1, 9);
        // Find random numbers excluding the 5
        const ones = getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 5);
        const tensNumbers = ones.map(value => {
          return tens * 10 + value;
        });
        numbers.push(...tensNumbers);
        // Include the 5
        numbers.push(tens * 10 + 5);
        break;
      }
      case 100: {
        const hundreds = randomIntegerInclusive(1, 9);

        // Find a random multiple of 10, excluding the 50
        const randomMultipleOf10 =
          getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 10 + hundreds * 100;

        // Find random numbers excluding the 50 and the randomMultipleOf10
        const tensAndOnes = getRandomSubArrayFromArray(
          rangeExcluding(1, 99, [50, randomMultipleOf10]),
          4
        );

        const hundredsNumbers = tensAndOnes.map(value => {
          return hundreds * 100 + value;
        });
        numbers.push(...hundredsNumbers);

        // Add the 50
        numbers.push(hundreds * 100 + 50);
        // Add the random multiple of 10
        numbers.push(randomMultipleOf10);
        break;
      }
      case 1000: {
        //6x numbers >$var and <$var+1000.
        const thousands = randomIntegerInclusive(1, 9) * 1000;
        // 1. Include $var+500.
        const fiveHundred = thousands + 500;
        numbers.push(fiveHundred);

        // 2. Include one other multiple of 100.
        const randomMultipleOf100 =
          getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 100 + thousands;
        numbers.push(randomMultipleOf100);

        // 3. Include 1 multiple of 10.
        const randomMultipleOf10Hundreds = randomIntegerInclusive(0, 9) * 100;
        const randomMultipleOf10Tens =
          getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 10;
        const randomMultipleOf10 = thousands + randomMultipleOf10Hundreds + randomMultipleOf10Tens;
        numbers.push(randomMultipleOf10);

        // 4. Include 1 odd multiple of 50.
        const oddMultiple50Hundreds = randomIntegerInclusive(0, 9) * 100;
        const oddMultiple50 = thousands + oddMultiple50Hundreds + 50;
        numbers.push(oddMultiple50);

        // 5. Include 1 odd multiple of 5.
        const oddMultiple5Hundreds = randomIntegerInclusive(0, 9) * 100;
        const oddMultiple5Tens = randomIntegerInclusive(0, 9) * 10;
        const oddMultiple5 = thousands + oddMultiple5Hundreds + oddMultiple5Tens + 5;
        numbers.push(oddMultiple5);

        // 6. Random number
        const randomHundreds = randomIntegerInclusive(0, 9) * 100;
        const randomTens = randomIntegerInclusive(0, 9) * 10;
        const randomOnes = getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0];
        const randomNumber = thousands + randomHundreds + randomTens + randomOnes;
        numbers.push(randomNumber);
        break;
      }
    }
    return { numbers, round };
  },
  Component: props => {
    const {
      question: { numbers, round },
      translate
    } = props;

    // Round down on the left, round up on the right
    const lower = Math.floor(numbers[0] / round) * round;
    const upper = Math.ceil(numbers[0] / round) * round;
    const roundsDown: number[] = [];
    const roundsUp: number[] = [];

    numbers.forEach(number => {
      if (roundToTheNearest(number, round) === lower) {
        roundsDown.push(number);
      } else {
        roundsUp.push(number);
      }
    });

    const correctOrder = [roundsDown, roundsUp];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.roundToNearestXDragToSort(round)}
        pdfTitle={translate.instructions.roundToNearestXDragToSortPDF(round)}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.roundsTo(lower), translate.instructions.roundsTo(upper)]}
        items={numbers}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
      />
    );
  }
});

const Question4: typeof ac7 = { ...ac7, uid: 'aeh', description: 'aeh' };

const Question5 = newQuestionContent({
  uid: 'aei',
  description: 'aei',
  keywords: ['Place value', 'Round', '10', '100', '1,000'],
  schema: z.object({
    comparitor: z.number().int().min(20).max(9000),
    numbers: z.number().int().min(0).max(10000).array().length(6),
    round: z.union([z.literal(10), z.literal(100), z.literal(1000)])
  }),
  example: {
    round: 10,
    comparitor: 40,
    numbers: [33, 35, 38, 41, 45, 48]
  },
  simpleGenerator: () => {
    const round = getRandomFromArray([10, 100, 1000] as const);
    let comparitor, num1, num2, num3, num4, num5, num6;
    switch (round) {
      case 10:
        // Pick the comparitor.
        comparitor = randomIntegerInclusive(2, 9) * 10;
        //$var-11<num1<$var-5,
        num1 = randomIntegerInclusive(comparitor - 10, comparitor - 6);
        //num2=$var-5,
        num2 = comparitor - 5;
        //$var-5<num3<$var,
        num3 = randomIntegerInclusive(comparitor - 4, comparitor - 1);
        //num4<$var+5,
        num4 = randomIntegerInclusive(comparitor + 1, comparitor + 4);
        //num5=$var+5,
        num5 = comparitor + 5;
        //$var+5<num6<$var+11
        num6 = randomIntegerInclusive(comparitor + 6, comparitor + 10);
        break;
      case 100:
        // Pick the comparitor.
        comparitor = randomIntegerInclusive(2, 9) * 100;
        // $var-101<num1<$var-50
        num1 = randomIntegerInclusive(comparitor - 100, comparitor - 51);
        //num2=$var-50,
        num2 = comparitor - 50;
        //$var-50<num3<$var,
        num3 = randomIntegerInclusive(comparitor - 49, comparitor - 1);
        //num4<$var+50,
        num4 = randomIntegerInclusive(comparitor + 1, comparitor + 49);
        //num5=$var+50,
        num5 = comparitor + 50;
        //$var+50<num6<$var+101
        num6 = randomIntegerInclusive(comparitor + 51, comparitor + 100);
        break;
      case 1000:
        // Pick the comparitor.
        comparitor = randomIntegerInclusive(2, 9) * 1000;
        // $var-1001<num1<$var-500
        num1 = randomIntegerInclusive(comparitor - 1000, comparitor - 501);
        //num2=$var-500,
        num2 = comparitor - 500;
        //$var-500<num3<$var,
        num3 = randomIntegerInclusive(comparitor - 499, comparitor - 1);
        //num4<$var+500,
        num4 = randomIntegerInclusive(comparitor + 1, comparitor + 499);
        //num5=$var+50,
        num5 = comparitor + 500;
        //$var+50<num6<$var+101
        num6 = randomIntegerInclusive(comparitor + 501, comparitor + 1000);
        break;
    }

    return { comparitor, numbers: shuffle([num1, num2, num3, num4, num5, num6]), round };
  },
  Component: props => {
    const {
      question: { comparitor, numbers, round },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatRoundToNumber(comparitor, round)}
        pdfTitle={translate.instructions.circleNumbersThatRoundToNumber(comparitor, round)}
        testCorrect={numbers.filter(it => roundToTheNearest(it, round) === comparitor)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6: typeof ac3 = { ...ac3, uid: 'aej', description: 'aej' };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundToTheNearest10100Or1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
