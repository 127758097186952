import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { range, rangeAsString } from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'be6',
  description: 'be6',
  keywords: ['Number line'],
  schema: z.object({
    number: z.number().int().min(0).max(93),
    answerIndices: z.array(z.number()),
    intervals: numberEnum([7, 8, 9, 10, 11])
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArrayWithWeights([10, 7, 8, 9, 11] as const, [4, 1, 1, 1, 1]);
    const numberOfAnswers = getRandomFromArray([3, 4, 5]);
    const answerIndices = randomUniqueIntegersInclusive(0, intervals - 1, numberOfAnswers);

    const number = randomIntegerInclusive(0, 100 - intervals);

    return { number, intervals, answerIndices };
  },
  Component: props => {
    const {
      question: { number, intervals, answerIndices },
      translate
    } = props;

    const tickValues = rangeAsString(number, number + intervals, 1, true);

    const numberArray = tickValues.map((number, index) =>
      answerIndices.includes(index) ? `<ans/>` : number.toString()
    );

    const answerArray = tickValues.flatMap((number, idx) =>
      answerIndices.includes(idx) ? [number.toString()] : []
    );

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.completeTheNumberLine()}
        testCorrect={answerArray}
        tickValues={numberArray}
        answerPositions="bottom"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'be7',
  description: 'be7',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(90).multipleOf(10),
    answerIndex: z.number().int().min(1).max(8),
    step: numberEnum([10, 1])
  }),
  simpleGenerator: () => {
    const step = getRandomFromArray([1, 10] as const);
    const startingNumber = step === 1 ? randomIntegerInclusiveStep(0, 90, 10) : 0;
    const answerIndex = randomIntegerInclusive(1, 8);

    return { startingNumber, answerIndex, step };
  },
  Component: props => {
    const {
      question: { startingNumber, answerIndex, step },
      translate
    } = props;

    // Create array to pass to Number Line
    const tickValues = range(
      startingNumber,
      step === 1 ? startingNumber + 10 : 100,
      step === 1 ? 1 : 10
    ).map((number, index) => {
      return index === 0 || index === 10 ? number : null;
    });

    const answer = step === 1 ? startingNumber + answerIndex : startingNumber + answerIndex * 10;

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[answer.toString()]}
        tickValues={tickValues}
        freeNumberLineAnswer={[answer]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'be8',
  description: 'be8',
  keywords: ['Number line', 'Estimate'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(1)
      .max(99)
      .refine(x => x % 10 !== 0, 'Number cannot be a multiple of 10')
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 99, { constraint: x => x % 10 !== 0 });

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickInterval = 10;
    const labelInterval = 10;
    const startingNumber = 0;
    const endNumber = 100;

    // Create array of empty strings
    const numberArray = range(startingNumber, endNumber, tickInterval);
    const tickArray = numberArray.map(number => {
      return number % labelInterval ? '' : number.toLocaleString();
    });

    const testCorrect = (userAnswer: number) => {
      const acceptAbleAnswers = [number, number - 1, number - 2, number + 1, number + 2];
      return (
        acceptAbleAnswers.includes(userAnswer) &&
        Math.floor(userAnswer / 10) === Math.floor(number / 10)
      );
    };

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragArrowToEstimateWhereXIsOnNumberLine(number)}
        pdfTitle={translate.ks1PDFInstructions.drawArrowEstimateNumberIsOnNumberLine(number)}
        testCorrect={userAnswer => testCorrect(userAnswer)}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={tickArray}
        customMarkSchemeAnswer={{
          answerToDisplay: number,
          answerText: translate.markScheme.allowMarginOfErrorOfXWithoutCrossingATen(2)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TheNumberLineTo100',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
