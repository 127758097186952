import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { View } from 'react-native';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import AnglesAroundAPoint from '../../../../components/question/representations/AnglesAroundAPoint';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { countRange, sumNumberArray } from '../../../../utils/collections';
import {
  algebraicSymbolSchema,
  getAlgebraicSymbol,
  getRandomUniqueAlgebraicSymbols,
  getSmallAlgebraicSymbol,
  smallAlgebraicSymbolSchema
} from '../../../../utils/algebraicSymbols';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  ADD,
  ALGEBRAIC_A,
  ALGEBRAIC_B,
  ALGEBRAIC_C,
  ALGEBRAIC_D,
  ALGEBRAIC_X,
  ALGEBRAIC_Y,
  DEGREES
} from '../../../../constants';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aEa',
  description: 'aEa',
  keywords: ['Angles', 'Vertically opposite'],
  schema: z
    .object({
      correctAngle1: z.number().int().min(50).max(150),
      correctAngle2: z.number().int().min(50).max(150),
      offset: z.number().int().min(-35).max(50),
      rotation: z.number().int().min(0).max(90)
    })
    .refine(
      val => Math.abs(val.correctAngle1 - val.correctAngle2) >= 15,
      'difference between angles should be 15'
    ),
  simpleGenerator: () => {
    const correctAngle1 = randomIntegerInclusive(50, 150, { constraint: x => x !== 90 });
    // make sure there is a difference of 15 with angle 1 and 2 for aesthetics
    const correctAngle2 = randomIntegerInclusive(50, 150, {
      constraint: x => !countRange(50, correctAngle1 - 25).includes(x) && x !== 90
    });
    const offset = randomIntegerInclusive(-35, 50, {
      constraint: x =>
        !countRange(30, -15).includes(x) &&
        correctAngle1 + x >= 30 &&
        180 - correctAngle1 - x >= 30 &&
        correctAngle2 + x >= 30 &&
        180 - correctAngle2 - x >= 30
    });
    const rotation = randomIntegerInclusive(0, 90);

    return { correctAngle1, correctAngle2, offset, rotation };
  },
  Component: props => {
    const {
      question: { correctAngle1, correctAngle2, offset, rotation },
      translate
    } = props;

    const items = shuffle(
      [
        {
          angles: [correctAngle1, 180 - correctAngle1, correctAngle1, 180 - correctAngle1],
          isCorrect: true,
          innerArc: [0, 2],
          label: 'A'
        },
        {
          angles: [correctAngle2, 180 - correctAngle2, correctAngle2, 180 - correctAngle2],
          isCorrect: true,
          innerArc: [1, 3],
          label: 'B'
        },
        {
          angles: [
            correctAngle1 + offset,
            180 - correctAngle1 - offset,
            correctAngle1,
            180 - correctAngle1
          ],
          innerArc: [0, 2],
          isCorrect: false,
          label: 'C'
        },
        {
          angles: [
            correctAngle2 + offset,
            180 - correctAngle2 - offset,
            correctAngle2,
            180 - correctAngle2
          ],
          innerArc: [1, 3],
          isCorrect: false,
          label: 'D'
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPairsOfAnglesVerticallyOpposite(items.length)}
        pdfTitle={translate.instructions.circlePairsOfAnglesVerticallyOpposite(items.length)}
        testCorrect={items.filter(val => val.isCorrect).map(val => val.label)}
        numItems={4}
        multiSelect
        questionHeight={900}
        renderItems={({ dimens: { width, height } }) => {
          return items.map(val => ({
            value: val.label,
            component: (
              <View style={{ transform: `rotate(${rotation}deg)` }}>
                <AnglesAroundAPoint
                  radius={Math.min(width * 0.4, height * 0.4)}
                  angles={val.angles.map(angle => ({
                    angle
                  }))}
                  innerArcSegments={val.innerArc}
                />
              </View>
            )
          }));
        }}
      />
    );
  },
  questionHeight: 900
});

const Question1v2 = newQuestionContent({
  uid: 'aEa2',
  description: 'aEa',
  keywords: ['Angles', 'Vertically opposite'],
  schema: z.object({
    items: z
      .array(
        z.object({
          angles: z
            .array(z.number().int().min(30).max(150))
            .length(4)
            .refine(arr => sumNumberArray(arr) === 360, 'angles must sum to 360'),
          rotation: z.number().int().min(0).max(180)
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const numberOfCorrectAngles = randomIntegerInclusive(1, 3);

    // First generate 3 angles between 50 and 130, where all at least 15 apart.
    // This will always be possible, no matter the choice of 1st and 2nd angle
    const correctAngle1 = randomIntegerInclusive(50, 130);
    const correctAngle2 = randomIntegerInclusive(50, 130, {
      constraint: x => Math.abs(x - correctAngle1) >= 15
    });
    const correctAngle3 = randomIntegerInclusive(50, 150, {
      constraint: x => Math.abs(x - correctAngle1) >= 15 && Math.abs(x - correctAngle2) >= 15
    });

    // The correct answers are that angle opposite itself (with whatever is needed to fill the gap).
    const correctItems = [correctAngle1, correctAngle2, correctAngle3]
      .map(correctAngle => ({
        angles: [correctAngle, 180 - correctAngle, correctAngle, 180 - correctAngle],
        rotation: randomIntegerInclusive(0, 180)
      }))
      .slice(0, numberOfCorrectAngles);

    // The incorrect answers are generated from the correct answers, but adding some offset to one angle
    const incorrectItems = [correctAngle1, correctAngle2, correctAngle3]
      .map(correctAngle => {
        const offset = randomIntegerInclusive(-35, 50, {
          constraint: x =>
            (x < -20 || x > 20) && correctAngle + x >= 30 && 180 - correctAngle - x >= 30
        });
        return {
          angles: [
            correctAngle + offset,
            180 - correctAngle - offset,
            correctAngle,
            180 - correctAngle
          ],
          rotation: randomIntegerInclusive(0, 180)
        };
      })
      .slice(0, 4 - numberOfCorrectAngles);

    const items = shuffle([...correctItems, ...incorrectItems]);

    return { items };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    const isItemCorrect = (item: (typeof items)[number]) =>
      item.angles[0] === item.angles[2] && item.angles[1] === item.angles[3];
    const correctItemIndices = items.flatMap((item, index) => (isItemCorrect(item) ? [index] : []));
    const numCorrectAnswers = correctItemIndices.length;

    return (
      // Use the item's index within the `items` array as its value
      <QF11SelectImagesUpTo4<number>
        title={translate.instructions.selectPairsOfAnglesVerticallyOpposite(numCorrectAnswers)}
        pdfTitle={translate.instructions.circlePairsOfAnglesVerticallyOpposite(numCorrectAnswers)}
        testCorrect={correctItemIndices}
        numItems={4}
        multiSelect={numCorrectAnswers > 1}
        questionHeight={900}
        renderItems={({ dimens: { width, height } }) => {
          return items.map((item, index) => ({
            value: index,
            component: (
              <View style={{ transform: `rotate(${item.rotation}deg)` }}>
                <AnglesAroundAPoint
                  radius={Math.min(width * 0.4, height * 0.4)}
                  angles={item.angles.map(angle => ({
                    angle
                  }))}
                  innerArcSegments={[0, 2]}
                />
              </View>
            )
          }));
        }}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aEb',
  description: 'aEb',
  keywords: ['Angles', 'Vertically opposite', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(36).max(69),
    startAngle: z.number().int().min(-45).max(45),
    correctIndexes: z.array(z.number().min(0).max(11)),
    incorrectIndexes: z.array(z.number().min(0).max(11))
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(36, 69);
    const startAngle = randomIntegerInclusive(-45, 45);
    const numberOfCorrect = randomIntegerInclusive(1, 3);

    const correctIndexes = randomUniqueIntegersInclusive(0, 11, numberOfCorrect);
    const incorrectIndexes = randomUniqueIntegersInclusive(0, 11, 4 - numberOfCorrect);

    return { angle, startAngle, correctIndexes, incorrectIndexes };
  },
  Component: props => {
    const {
      question: { angle, startAngle, correctIndexes, incorrectIndexes },
      translate
    } = props;

    const correctOptions = [
      `${ALGEBRAIC_A} = ${ALGEBRAIC_C}`,
      `${ALGEBRAIC_D} = ${ALGEBRAIC_B}`,
      `${ALGEBRAIC_B} = ${ALGEBRAIC_D}`,
      `${ALGEBRAIC_C} = ${ALGEBRAIC_A}`,
      `${ALGEBRAIC_D} ${ADD} ${ALGEBRAIC_A} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_A} ${ADD} ${ALGEBRAIC_D} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_A} ${ADD} ${ALGEBRAIC_B} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_B} ${ADD} ${ALGEBRAIC_A} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_B} ${ADD} ${ALGEBRAIC_C} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_C} ${ADD} ${ALGEBRAIC_B} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_D} ${ADD} ${ALGEBRAIC_C} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_C} ${ADD} ${ALGEBRAIC_D} = ${(180).toLocaleString()}${DEGREES}`
    ].filter((_val, i) => correctIndexes.includes(i));

    const incorrectOptions = [
      `${ALGEBRAIC_A} = ${ALGEBRAIC_B}`,
      `${ALGEBRAIC_B} = ${ALGEBRAIC_A}`,
      `${ALGEBRAIC_B} = ${ALGEBRAIC_C}`,
      `${ALGEBRAIC_C} = ${ALGEBRAIC_B}`,
      `${ALGEBRAIC_C} = ${ALGEBRAIC_D}`,
      `${ALGEBRAIC_D} = ${ALGEBRAIC_C}`,
      `${ALGEBRAIC_D} = ${ALGEBRAIC_A}`,
      `${ALGEBRAIC_A} = ${ALGEBRAIC_D}`,
      `${ALGEBRAIC_A} ${ADD} ${ALGEBRAIC_C} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_C} ${ADD} ${ALGEBRAIC_A} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_B} ${ADD} ${ALGEBRAIC_D} = ${(180).toLocaleString()}${DEGREES}`,
      `${ALGEBRAIC_D} ${ADD} ${ALGEBRAIC_B} = ${(180).toLocaleString()}${DEGREES}`
    ].filter((_val, i) => incorrectIndexes.includes(i));

    const items = shuffle(
      [
        ...correctOptions.map(val => ({ string: val, isCorrect: true })),
        ...incorrectOptions.map(val => ({ string: val, isCorrect: false }))
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectStatementsThatMatchTheDiagram()}
        pdfTitle={translate.instructions.circleStatementsThatMatchTheDiagram()}
        testCorrect={items.filter(val => val.isCorrect).map(it => it.string)}
        multiSelect
        itemLayout="row"
        numItems={4}
        Content={({ dimens }) => (
          <AnglesAroundAPoint
            radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
            angles={[
              { angle: 180 - angle, innerLabel: ALGEBRAIC_B },
              { angle: angle, innerLabel: ALGEBRAIC_C },
              { angle: 180 - angle, innerLabel: ALGEBRAIC_D },
              { angle: angle, innerLabel: ALGEBRAIC_A }
            ]}
            startAngle={startAngle}
            arcSizeGroups={[
              [0, 2],
              [1, 3]
            ]}
          />
        )}
        renderItems={items.map(({ string }) => ({
          value: string,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {string}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aEb2',
  description: 'aEb',
  keywords: ['Angles', 'Vertically opposite', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(36).max(69),
    startAngle: z.number().int().min(-45).max(45),
    randomAlgebraicSymbols: z.array(algebraicSymbolSchema).length(4),
    items: z.array(
      z.object({
        value: z.string(),
        isCorrect: z.boolean()
      })
    )
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(36, 69);
    const startAngle = randomIntegerInclusive(-45, 45);
    const numberOfCorrect = randomIntegerInclusive(1, 3);

    const correctIndexes = randomUniqueIntegersInclusive(0, 11, numberOfCorrect);
    const incorrectIndexes = randomUniqueIntegersInclusive(0, 11, 4 - numberOfCorrect);

    const randomAlgebraicSymbols = getRandomUniqueAlgebraicSymbols(4);

    const [letterA, letterB, letterC, letterD] = randomAlgebraicSymbols;

    const correctOptions = [
      `${letterA} = ${letterC}`,
      `${letterD} = ${letterB}`,
      `${letterB} = ${letterD}`,
      `${letterC} = ${letterA}`,
      `${letterD} ${ADD} ${letterA} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterA} ${ADD} ${letterD} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterA} ${ADD} ${letterB} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterB} ${ADD} ${letterA} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterB} ${ADD} ${letterC} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterC} ${ADD} ${letterB} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterD} ${ADD} ${letterC} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterC} ${ADD} ${letterD} = ${(180).toLocaleString()}${DEGREES}`
    ].filter((_val, i) => correctIndexes.includes(i));

    const incorrectOptions = [
      `${letterA} = ${letterB}`,
      `${letterB} = ${letterA}`,
      `${letterB} = ${letterC}`,
      `${letterC} = ${letterB}`,
      `${letterC} = ${letterD}`,
      `${letterD} = ${letterC}`,
      `${letterD} = ${letterA}`,
      `${letterA} = ${letterD}`,
      `${letterA} ${ADD} ${letterC} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterC} ${ADD} ${letterA} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterB} ${ADD} ${letterD} = ${(180).toLocaleString()}${DEGREES}`,
      `${letterD} ${ADD} ${letterB} = ${(180).toLocaleString()}${DEGREES}`
    ].filter((_val, i) => incorrectIndexes.includes(i));

    const items = shuffle([
      ...correctOptions.map(val => ({ value: val, isCorrect: true })),
      ...incorrectOptions.map(val => ({ value: val, isCorrect: false }))
    ]);

    return { angle, startAngle, items, randomAlgebraicSymbols };
  },
  Component: props => {
    const {
      question: { angle, startAngle, items, randomAlgebraicSymbols },
      translate,
      displayMode
    } = props;

    const title =
      items.filter(({ isCorrect }) => isCorrect).length === 1
        ? translate.instructions.selectStatementThatMatchesTheDiagram()
        : translate.instructions.selectStatementsThatMatchTheDiagram();

    const pdfTitle =
      items.filter(({ isCorrect }) => isCorrect).length === 1
        ? translate.instructions.circleStatementThatMatchesTheDiagram()
        : translate.instructions.circleStatementsThatMatchTheDiagram();

    const answer = items.filter(val => val.isCorrect).map(it => it.value);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={items.filter(val => val.isCorrect).map(it => it.value)}
        multiSelect={answer.length > 1}
        numItems={4}
        itemLayout="row"
        Content={({ dimens }) => (
          <AnglesAroundAPoint
            radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
            angles={[
              { angle: 180 - angle, innerLabel: randomAlgebraicSymbols[1] },
              { angle: angle, innerLabel: randomAlgebraicSymbols[2] },
              { angle: 180 - angle, innerLabel: randomAlgebraicSymbols[3] },
              { angle: angle, innerLabel: randomAlgebraicSymbols[0] }
            ]}
            startAngle={startAngle}
            arcSizeGroups={[
              [0, 2],
              [1, 3]
            ]}
          />
        )}
        renderItems={items.map(({ value }) => ({
          value,
          component: (
            <Text
              variant="WRN700"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {value}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aEc',
  description: 'aEc',
  keywords: ['Angles', 'Vertically opposite', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(36).max(69),
    letter: algebraicSymbolSchema,
    startAngle: z.number().int().min(0).max(90),
    isTopShown: z.boolean().optional()
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(36, 69, { constraint: x => x % 5 !== 0 });
    const letter = getAlgebraicSymbol();
    const startAngle = randomIntegerInclusive(0, 90);
    const isTopShown = getRandomBoolean();
    return { angle, letter, startAngle, isTopShown };
  },
  Component: props => {
    const {
      question: { angle, letter, startAngle, isTopShown = false },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[angle.toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: 180 - angle },
                {
                  angle: angle,
                  innerLabel: isTopShown ? letter : `${angle.toLocaleString()}${DEGREES}`
                },
                { angle: 180 - angle },
                {
                  angle: angle,
                  innerLabel: isTopShown ? `${angle.toLocaleString()}${DEGREES}` : letter
                }
              ]}
              innerArcSegments={[1, 3]}
              startAngle={startAngle}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aEd',
  description: 'aEd',
  keywords: ['Angles', 'Vertically opposite', 'Calculate', 'Straight lines'],
  schema: z.object({
    angle: z.number().int().min(36).max(143),
    startAngle: z.number().int().min(0).max(379)
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(36, 143, { constraint: x => x % 5 !== 0 });
    const startAngle = randomIntegerInclusive(0, 379);
    return { angle, startAngle };
  },
  Component: props => {
    const {
      question: { angle, startAngle },
      translate
    } = props;
    return (
      <QF1ContentAndSentences
        sentences={[
          `${ALGEBRAIC_X} = <ans/> ${DEGREES}`,
          `${ALGEBRAIC_Y} = <ans/> ${DEGREES}`,
          `${ALGEBRAIC_B} = <ans/> ${DEGREES}`
        ]}
        style={{ flexDirection: 'row' }}
        title={translate.instructions.workOutSizeOfUnknownAngles()}
        testCorrect={[[(180 - angle).toString()], [(180 - angle).toString()], [angle.toString()]]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: 180 - angle, innerLabel: ALGEBRAIC_X },
                { angle: angle, innerLabel: ALGEBRAIC_B },
                { angle: 180 - angle, innerLabel: ALGEBRAIC_Y },
                { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` }
              ]}
              startAngle={startAngle}
              arcSizeGroups={[
                [0, 2],
                [1, 3]
              ]}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aEe',
  description: 'aEe',
  keywords: ['Angles', 'Vertically opposite', 'Calculate', 'Straight lines'],
  schema: z.object({
    angle: z.number().int().min(66).max(89),
    offset: z.number().int().min(25).max(49),
    letter: smallAlgebraicSymbolSchema,
    startAngle: z.number().int().min(0).max(359)
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(66, 89);
    const offset = randomIntegerInclusive(25, angle - 40);
    const letter = getSmallAlgebraicSymbol();
    const startAngle = randomIntegerInclusive(0, 359);

    return { angle, offset, letter, startAngle };
  },
  Component: props => {
    const {
      question: { angle, offset, letter, startAngle },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        title={`${translate.instructions.eachDiagramHas3StraightLines()}<br/>${translate.instructions.workOutSizeOfUnknownAngle()}`}
        testCorrect={[offset.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: 180 - angle },
                {
                  angle: angle - offset,
                  innerLabel: `${(angle - offset).toLocaleString()}${DEGREES}`
                },
                { angle: offset, innerLabel: letter },
                {
                  angle: 180 - angle
                },
                { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` }
              ]}
              innerArcSegments={[1, 2, 4]}
              arcSizeGroups={[[1], [2], [4]]}
              startAngle={startAngle}
            />
          );
        }}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aEf',
  description: 'aEf',
  keywords: ['Angles', 'Vertically opposite'],
  schema: z
    .object({
      correctAngles1: z.number().int().min(40).max(140).array().length(5),
      correctAngles2: z.number().int().min(40).max(140).array().length(5),
      incorrectAngles: z.number().int().min(40).max(140).array().length(5)
    })
    .refine(val => sumNumberArray(val.correctAngles1) === 360, 'angles must add to 360'),
  simpleGenerator: () => {
    const [correctAngles11, correctAngles21] = randomUniqueIntegersInclusive(40, 140, 2);
    const correctAngles12 = 180 - correctAngles11;
    const correctAngles22 = 180 - correctAngles21;
    const [correctAngles13, correctAngles23] = randomUniqueIntegersInclusive(40, 80, 2);
    const correctAngles14 = randomIntegerInclusive(40, 80, {
      constraint: x => 180 - correctAngles13 - x >= 40
    });
    const correctAngles24 = randomIntegerInclusive(40, 80, {
      constraint: x => x !== correctAngles14 && 180 - correctAngles23 - x >= 40
    });
    const correctAngles15 = 180 - correctAngles13 - correctAngles14;
    const correctAngles25 = 180 - correctAngles23 - correctAngles24;

    const angle3 = 90;
    const offset = randomIntegerInclusive(40, 50);

    const incorrectAngles = [180 - angle3, angle3 - offset, offset, 180 - angle3, angle3];

    const correctAngles1 = [
      correctAngles11,
      correctAngles12,
      correctAngles13,
      correctAngles14,
      correctAngles15
    ];

    const correctAngles2 = [
      correctAngles23,
      correctAngles24,
      correctAngles25,
      correctAngles21,
      correctAngles22
    ];

    return { correctAngles1, correctAngles2, incorrectAngles };
  },
  Component: props => {
    const {
      question: { correctAngles1, correctAngles2, incorrectAngles },
      translate
    } = props;

    const items = shuffle(
      [
        {
          angles: correctAngles1,
          isCorrect: true,
          missingAngle: 0,
          innerArc: [0, 1],
          label: 'A'
        },
        {
          angles: correctAngles1,
          isCorrect: false,
          missingAngle: 4,
          innerArc: [2, 4],
          label: 'B'
        },
        {
          angles: correctAngles2,
          innerArc: [3, 4],
          missingAngle: 3,
          isCorrect: true,
          label: 'C'
        },
        {
          angles: incorrectAngles,
          innerArc: [2, 4],
          missingAngle: 2,
          isCorrect: false,
          label: 'D'
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const letters = [ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C, ALGEBRAIC_D];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.eachDiagramHas3StraightLinesSelectWhenYouCanGetUnknownAngle()}
        pdfTitle={translate.instructions.eachDiagramHas3StraightLinesCircleWhenYouCanGetUnknownAngle()}
        testCorrect={items.filter(val => val.isCorrect).map(val => val.label)}
        numItems={4}
        questionHeight={900}
        multiSelect
        renderItems={({ dimens: { width, height } }) => {
          return items.map((val, i) => ({
            value: val.label,
            component: (
              <AnglesAroundAPoint
                radius={Math.min(width * 0.4, height * 0.4)}
                angles={val.angles.map((angle, idx) => ({
                  angle,
                  innerLabel: val.innerArc.includes(idx)
                    ? idx === val.missingAngle
                      ? letters[i]
                      : `${angle.toLocaleString()}${DEGREES}`
                    : ''
                }))}
                innerArcSegments={val.innerArc}
              />
            )
          }));
        }}
      />
    );
  },
  questionHeight: 900
});

const Question6v2 = newQuestionContent({
  uid: 'aEf2',
  description: 'aEf',
  keywords: ['Angles', 'Vertically opposite'],
  schema: z
    .object({
      correctAngles1: z.number().int().min(20).max(140).array().length(5),
      correctAngles2: z.number().int().min(20).max(140).array().length(5),
      incorrectAngles: z.number().int().min(20).max(140).array().length(5)
    })
    .refine(val => sumNumberArray(val.correctAngles1) === 360, 'angles must add to 360'),
  simpleGenerator: () => {
    const [correctAngles11, correctAngles21] = randomUniqueIntegersInclusive(40, 130, 2);
    const correctAngles12 = 180 - correctAngles11;
    const correctAngles13 = correctAngles11;
    const correctAngles14 = randomIntegerInclusive(20, 180 - correctAngles11 - 30);
    const correctAngles15 = 180 - correctAngles13 - correctAngles14;

    const correctAngles22 = 180 - correctAngles21;
    const correctAngles23 = correctAngles21;
    const correctAngles24 = randomIntegerInclusive(30, 180 - correctAngles21 - 20);
    const correctAngles25 = 180 - correctAngles23 - correctAngles24;

    const angle3 = 90;
    const offset = randomIntegerInclusive(40, 50);

    const incorrectAngles = [180 - angle3, angle3 - offset, offset, 180 - angle3, angle3];

    const correctAngles1 = [
      correctAngles11,
      correctAngles12,
      correctAngles13,
      correctAngles14,
      correctAngles15
    ];

    const correctAngles2 = [
      correctAngles23,
      correctAngles24,
      correctAngles25,
      correctAngles21,
      correctAngles22
    ];

    return { correctAngles1, correctAngles2, incorrectAngles };
  },
  Component: props => {
    const {
      question: { correctAngles1, correctAngles2, incorrectAngles },
      translate
    } = props;

    const items = shuffle(
      [
        {
          angles: correctAngles1,
          isCorrect: true,
          missingAngle: 0,
          innerArc: [0, 1],
          label: 'A'
        },
        {
          angles: correctAngles1,
          isCorrect: false,
          missingAngle: 4,
          innerArc: [2, 4],
          label: 'B'
        },
        {
          angles: correctAngles2,
          innerArc: [3, 4],
          missingAngle: 3,
          isCorrect: true,
          label: 'C'
        },
        {
          angles: incorrectAngles,
          innerArc: [2, 4],
          missingAngle: 2,
          isCorrect: false,
          label: 'D'
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const letters = [ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C, ALGEBRAIC_D];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.eachDiagramHas3StraightLinesSelectWhenYouCanGetUnknownAngle()}
        pdfTitle={translate.instructions.eachDiagramHas3StraightLinesCircleWhenYouCanGetUnknownAngle()}
        testCorrect={items.filter(val => val.isCorrect).map(val => val.label)}
        numItems={4}
        questionHeight={900}
        multiSelect
        renderItems={({ dimens: { width, height } }) => {
          return items.map((val, i) => ({
            value: val.label,
            component: (
              <AnglesAroundAPoint
                radius={Math.min(width * 0.4, height * 0.4)}
                angles={val.angles.map((angle, idx) => ({
                  angle,
                  innerLabel: val.innerArc.includes(idx)
                    ? idx === val.missingAngle
                      ? letters[i]
                      : `${angle.toLocaleString()}${DEGREES}`
                    : ''
                }))}
                innerArcSegments={val.innerArc}
              />
            )
          }));
        }}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'VerticallyOppositeAngles',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question1, Question2, Question6]
});
export default SmallStep;
