import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  arrayHasNoDuplicates,
  countRange,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  drawPieChartColors,
  drawPieChartColorsArray,
  drawPieChartColorsSchema
} from '../../../../theme/colors';
import PieChart from '../../../../components/question/representations/PieChart';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { numberEnum } from '../../../../utils/zod';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { simplify } from '../../../../utils/fractions';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { generateRandomPartitions } from '../../../../utils/graphs';
import { lowercase } from 'typesafe-i18n/formatters';

////
// Questions
////

const fruitOptions = [
  'Banana',
  'Orange',
  'Grape',
  'Apple',
  'Mango',
  'Watermelon',
  'Cherry',
  'Kiwi',
  'Pineapple',
  'Strawberry'
] as const;

const fruitOptionsLowercase = {
  Banana: 'banana',
  Orange: 'orange',
  Grape: 'grape',
  Apple: 'apple',
  Mango: 'mango',
  Watermelon: 'watermelon',
  Cherry: 'cherry',
  Kiwi: 'kiwi',
  Pineapple: 'pineapple',
  Strawberry: 'strawberry'
} as const;

const colorOptions = ['Red', 'Yellow', 'Green', 'Pink', 'Blue'] as const;
const colorOptionsLowercase = {
  Red: 'red',
  Yellow: 'yellow',
  Green: 'green',
  Pink: 'pink',
  Blue: 'blue'
} as const;

const Question1 = newQuestionContent({
  uid: 'aXq',
  description: 'aXq',
  keywords: ['Pie chart', 'Parts', 'Whole', 'Greater', 'Smaller', 'Most', 'Least', 'Interpret'],
  schema: z
    .object({
      fruits: z
        .array(z.enum(fruitOptions))
        .length(4)
        .refine(arrayHasNoDuplicates, 'fruit must be different'),
      quantities: z.array(z.number().int().min(10).max(60)),
      lessOrMore: z.enum(['Least', 'Most']),
      colors: z.array(drawPieChartColorsSchema).length(4)
    })
    .refine(val => {
      const asc = sortNumberArray(val.quantities, 'ascending');
      const desc = sortNumberArray(val.quantities, 'descending');
      return val.lessOrMore === 'Least' ? asc[1] - asc[0] >= 10 : desc[0] - desc[1] >= 10;
    }, 'smallest/largest should have difference of 10 to closest neighbour'),
  simpleGenerator: () => {
    const fruits = getRandomSubArrayFromArray(fruitOptions, 4);

    const lessOrMore = getRandomFromArray(['Least', 'Most'] as const);
    let value1;
    let value2;
    let value3;
    let value4;

    switch (lessOrMore) {
      case 'Most': {
        // make 45 the min as worst case we have 45,10,10,35
        value1 = randomIntegerInclusive(45, 60);
        // need at least 20 left over for var3 and var4
        value2 = randomIntegerInclusive(10, Math.min(100 - value1 - 10 - 20, value1 - 10));
        value3 = randomIntegerInclusive(10, (100 - value1 - value2) / 2);
        value4 = 100 - value1 - value2 - value3;
        break;
      }
      case 'Least': {
        // 17 is highest number because we need all others to at least be 10 larger (27) and 27* 3 + 17 =98
        value1 = randomIntegerInclusive(10, 17);
        // take away 54 as this is (17 + 10)*2 which is the min we need left for value 3 and 4
        value2 = randomIntegerInclusive(value1 + 10, 100 - value1 - 54);
        value3 = randomIntegerInclusive(value1 + 10, (100 - value1 - value2) / 2);
        value4 = 100 - value1 - value2 - value3;
        break;
      }
    }
    const quantities = shuffle([value1, value2, value3, value4]);
    const colors = getRandomSubArrayFromArray(drawPieChartColorsArray, 4);

    return { fruits, quantities, colors, lessOrMore };
  },
  Component: props => {
    const {
      question: { fruits, quantities, colors, lessOrMore },
      translate,
      displayMode
    } = props;

    const options = countRange(4).map(i => {
      return {
        ratioOfSlices: quantities[i],
        color: drawPieChartColors[colors[i]],
        innerLabel: translate.acronyms[fruits[i]]()
      };
    });

    const keys = countRange(4).map(i => {
      return {
        color: drawPieChartColors[colors[i]],
        label: `${translate.fruits[fruitOptionsLowercase[fruits[i]]]()} (${translate.acronyms[
          fruits[i]
        ]()})`
      };
    });

    const items = fruits.map(fruit => translate.fruits[fruitOptionsLowercase[fruit]]());

    const minMax =
      lessOrMore === 'Least'
        ? Math.min(quantities[0], quantities[1], quantities[2], quantities[3])
        : Math.max(quantities[0], quantities[1], quantities[2], quantities[3]);
    const answerIndex = quantities.indexOf(minMax);

    const popularFruitStatement =
      lessOrMore === 'Least' ? 'leastPopularFruitIsAns' : 'mostPopularFruitIsAns';

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragACardToCompleteSentence()}
        pdfTitle={translate.instructions.useACardToCompleteSentence()}
        questionHeight={1000}
        items={shuffle(items, { random: seededRandom(props.question) })}
        itemVariant="rectangle"
        Content={({ dimens }) => (
          <PieChart
            pieOptions={options}
            radius={dimens.height * (displayMode === 'digital' ? 0.5 : 0.4)}
            keyValues={keys}
            pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
          />
        )}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        sentence={translate.answerSentences[popularFruitStatement]()}
        testCorrect={[items[answerIndex]]}
        pdfItemVariant="tallRectangle"
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aXr',
  description: 'aXr',
  questionHeight: 1000,
  keywords: ['Pie chart', 'Parts', 'Whole', 'More', 'Less', 'Half', 'One quarter'],
  schema: z.object({
    fruits: z
      .array(z.enum(fruitOptions))
      .length(4)
      .refine(arrayHasNoDuplicates, 'fruit must be different'),
    quantities: z.array(z.number().int().min(10).max(60)),
    quarterHalf: z.enum(['One quarter', 'Half']),
    colors: z.array(drawPieChartColorsSchema).length(4),
    answerIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const fruits = getRandomSubArrayFromArray(fruitOptions, 4);
    const quantities = rejectionSample(
      () => generateRandomPartitions({ total: 100, parts: 4, min: 10, max: 60 }),
      val => val.every(i => !range(20, 30).includes(i) && !range(45, 55).includes(i))
    );
    const colors = getRandomSubArrayFromArray(drawPieChartColorsArray, 4);
    const quarterHalf = getRandomFromArray(['One quarter', 'Half'] as const);
    const answerIndex = randomIntegerInclusive(0, 3);

    return { fruits, quantities, colors, quarterHalf, answerIndex };
  },
  Component: props => {
    const {
      question: { fruits, quantities, colors, quarterHalf, answerIndex },
      translate,
      displayMode
    } = props;

    const options = countRange(4).map(i => {
      return {
        ratioOfSlices: quantities[i],
        color: drawPieChartColors[colors[i]],
        innerLabel: translate.acronyms[fruits[i]]()
      };
    });

    const keys = countRange(4).map(i => {
      return {
        color: drawPieChartColors[colors[i]],
        label: `${lowercase(translate.fruits[fruits[i]]())} (${translate.acronyms[fruits[i]]()})`
      };
    });

    const answerFruit = translate.fruits[fruits[answerIndex]]();
    const answerValue = quantities[answerIndex];
    const isMore =
      quarterHalf === 'Half'
        ? answerValue > 50
        : quarterHalf === 'One quarter'
        ? answerValue > 25
        : false;
    const quantity = translate.keywords[quarterHalf]();

    const draggables = [
      { string: translate.operations.moreThanCaps(), isCorrect: isMore },
      { string: translate.operations.lessThanCaps(), isCorrect: !isMore }
    ];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={draggables.map(val => val.string)}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        questionHeight={1000}
        Content={({ dimens }) => (
          <PieChart
            pieOptions={options}
            radius={dimens.height * (displayMode === 'digital' ? 0.5 : 0.4)}
            keyValues={keys}
            pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
          />
        )}
        sentence={translate.answerSentences.ansXOfChildrenChoseYAsFavouriteFruit(
          quantity,
          answerFruit
        )}
        testCorrect={draggables.filter(val => val.isCorrect).map(val => val.string)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aXs',
  description: 'aXs',
  keywords: ['Pie chart', 'Parts', 'Whole', 'Numerator', 'Denominator', 'Multiply', 'Divide'],
  schema: z.object({
    item: z.enum(['Televisions', 'Pairs of shoes', 'Watches']),
    totalParts: numberEnum([8, 10, 12]),
    quantities: z.array(z.number().int().min(1).max(9)),
    people: z.number().int().min(16).max(12000),
    colors: z.array(drawPieChartColorsSchema).length(4),
    answerIndex: z.number().int().min(0).max(3),
    answerQuantities: z.array(z.number().int().min(1).max(9))
  }),
  simpleGenerator: () => {
    const item = getRandomFromArray(['Televisions', 'Pairs of shoes', 'Watches'] as const);

    const totalParts = getRandomFromArray([8, 10, 12] as const);
    const quantities = generateRandomPartitions({
      total: totalParts,
      parts: 4,
      min: 1,
      max: totalParts - 3
    });

    const colors = getRandomSubArrayFromArray(drawPieChartColorsArray, 4);

    const people =
      getRandomFromArray([
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150,
        200, 250, 300, 400, 500, 1000
      ] as const) * totalParts;
    const answerIndex = randomIntegerInclusive(0, 3);

    const uniqueQuantities = [...new Set(quantities)];
    const otherAnswers = randomUniqueIntegersInclusive(1, 6, 4 - uniqueQuantities.length, {
      constraint: x => !uniqueQuantities.includes(x)
    });

    const answerQuantities = [...uniqueQuantities, ...otherAnswers];

    return { item, totalParts, quantities, people, colors, answerIndex, answerQuantities };
  },
  Component: props => {
    const {
      question: { item, totalParts, quantities, people, colors, answerIndex, answerQuantities },
      translate,
      displayMode
    } = props;

    const options = countRange(4)
      .map(i =>
        countRange(quantities[i]).map(() => {
          return {
            ratioOfSlices: 1,
            color: drawPieChartColors[colors[i]],
            innerLabel: (i + 1).toLocaleString()
          };
        })
      )
      .flat(1);

    const keys = countRange(4).map(i => {
      return {
        color: drawPieChartColors[colors[i]],
        label: (i + 1).toLocaleString()
      };
    });

    const onePart = people / totalParts;
    const answers = answerQuantities.map(val => onePart * val);
    const shuffledAnswers = shuffle(answers, { random: seededRandom(props.question) });
    const ans = onePart * quantities[answerIndex];

    let singularItem;
    let pluralItem;
    if (item === 'Pairs of shoes') {
      singularItem = translate.objects['Pair of shoes']();
      pluralItem = translate.objects['Pairs of shoes']();
    } else if (item === 'Televisions') {
      singularItem = translate.objects.Television();
      pluralItem = translate.objects.Televisions();
    } else {
      singularItem = translate.objects.Watch();
      pluralItem = translate.objects.Watches();
    }

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.aSurveyAsksXPeopleHowManyYSelectNumberOfPeopleWhoOwnZ(
          people,
          pluralItem,
          answerIndex + 1,
          answerIndex > 0 ? pluralItem : singularItem
        )}
        pdfTitle={translate.instructions.aSurveyAsksXPeopleHowManyYCircleNumberOfPeopleWhoOwnZ(
          people,
          pluralItem,
          answerIndex + 1,
          answerIndex > 0 ? pluralItem : singularItem
        )}
        selectables={Object.fromEntries(
          shuffledAnswers.map((ans, i) => [['A', 'B', 'C', 'D'][i], ans.toLocaleString()])
        )}
        selectableTextStyle={{ fontWeight: '700' }}
        leftContent={
          <MeasureView>
            {dimens => (
              <PieChart
                pieOptions={options}
                radius={displayMode === 'digital' ? dimens.height * 0.4 : dimens.height * 0.5}
                keyValues={keys}
                pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
              />
            )}
          </MeasureView>
        }
        correctAnswer={[['A', 'B', 'C', 'D'][shuffledAnswers.indexOf(ans)]]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aXt',
  description: 'aXt',
  questionHeight: 900,
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Multiply',
    'Divide',
    'Simplified fractions',
    'Equivalent fractions'
  ],
  schema: z.object({
    item: z.enum(['Televisions', 'Pairs of shoes', 'Watches']),
    totalParts: numberEnum([8, 10, 12]),
    quantities: z.array(z.number().int().min(1).max(9)),
    people: z.number().int().min(16).max(12000),
    multiplier: numberEnum([
      2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150,
      200, 250, 300, 400, 500, 1000
    ]),
    colors: z.array(drawPieChartColorsSchema).length(4),
    answerIndex: z.number().int().min(0).max(3),
    answerQuantities: z.array(z.number().int().min(1).max(9)),
    fractionTypes: z.array(z.enum(['equivalent', 'simplified', 'standard'])).length(4)
  }),
  simpleGenerator: () => {
    const item = getRandomFromArray(['Televisions', 'Pairs of shoes', 'Watches'] as const);

    const totalParts = getRandomFromArray([8, 10, 12] as const);
    const quantities = generateRandomPartitions({
      total: totalParts,
      parts: 4,
      min: 1,
      max: totalParts - 3
    });

    const colors = getRandomSubArrayFromArray(drawPieChartColorsArray, 4);
    const multiplier = getRandomFromArray([
      2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150,
      200, 250, 300, 400, 500, 1000
    ] as const);
    const answerIndex = randomIntegerInclusive(0, 3);
    const people = multiplier * totalParts;
    const uniqueQuantities = [...new Set(quantities)];
    const otherAnswers = randomUniqueIntegersInclusive(1, 6, 4 - uniqueQuantities.length, {
      constraint: x => !uniqueQuantities.includes(x)
    });
    const answerQuantities = [...uniqueQuantities, ...otherAnswers];
    const fractionTypes = countRange(4).map(() =>
      getRandomFromArray(['equivalent', 'simplified', 'standard'] as const)
    );

    return {
      item,
      totalParts,
      quantities,
      multiplier,
      people,
      colors,
      answerIndex,
      answerQuantities,
      fractionTypes
    };
  },
  Component: props => {
    const {
      question: {
        item,
        totalParts,
        quantities,
        people,
        multiplier,
        colors,
        answerIndex,
        answerQuantities,
        fractionTypes
      },
      translate,
      displayMode
    } = props;

    const options = countRange(4)
      .map(i =>
        countRange(quantities[i]).map(() => {
          return {
            ratioOfSlices: 1,
            color: drawPieChartColors[colors[i]],
            innerLabel: (i + 1).toLocaleString()
          };
        })
      )
      .flat(1);

    const keys = countRange(4).map(i => {
      return {
        color: drawPieChartColors[colors[i]],
        label: translate.numbersAsWords[(i + 1) as 1 | 2 | 3 | 4]()
      };
    });

    const ans = quantities[answerIndex] / totalParts;

    const answers = answerQuantities.map((val, i) => {
      if (fractionTypes[i] === 'equivalent') {
        return [
          ans === val / totalParts ? 'A' : ['B', 'C', 'D'][i],
          <TextStructure
            key={val}
            sentence={`<frac n='${val * multiplier}' d='${totalParts * multiplier}' />`}
            fractionDividerStyle={{ marginVertical: 2 }}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ];
      } else if (fractionTypes[i] === 'simplified') {
        const [n, d] = simplify(val, totalParts);
        return [
          ans === val / totalParts ? 'A' : ['B', 'C', 'D'][i],
          <TextStructure
            key={val}
            sentence={`<frac n='${n}' d='${d}' />`}
            fractionDividerStyle={{ marginVertical: 2 }}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ];
      } else {
        return [
          ans === val / totalParts ? 'A' : ['B', 'C', 'D'][i],
          <TextStructure
            key={val}
            sentence={`<frac n='${val}' d='${totalParts}' />`}
            fractionDividerStyle={{ marginVertical: 2 }}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ];
      }
    });

    const shuffledAnswers = shuffle(answers, { random: seededRandom(props.question) });

    let singularItem;
    let pluralItem;
    if (item === 'Pairs of shoes') {
      singularItem = translate.objects['Pair of shoes']();
      pluralItem = translate.objects['Pairs of shoes']();
    } else if (item === 'Televisions') {
      singularItem = translate.objects.Television();
      pluralItem = translate.objects.Televisions();
    } else {
      singularItem = translate.objects.Watch();
      pluralItem = translate.objects.Watches();
    }

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.aSurveyAsksXPeopleHowManyYSelectFractionOfPeopleWhoOwnZ(
          people,
          pluralItem,
          translate.numbersAsWords[(answerIndex + 1) as 1 | 2 | 3 | 4](),
          answerIndex > 0 ? pluralItem : singularItem
        )}
        pdfTitle={translate.instructions.aSurveyAsksXPeopleHowManyYCircleFractionOfPeopleWhoOwnZ(
          people,
          pluralItem,
          translate.numbersAsWords[(answerIndex + 1) as 1 | 2 | 3 | 4](),
          answerIndex > 0 ? pluralItem : singularItem
        )}
        selectables={Object.fromEntries(shuffledAnswers)}
        leftContent={
          <MeasureView>
            {dimens => (
              <PieChart
                pieOptions={options}
                radius={dimens.height * 0.4}
                keyValues={keys}
                pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
              />
            )}
          </MeasureView>
        }
        correctAnswer={['A']}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aXu',
  description: 'aXu',
  questionHeight: 800,
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Numerator',
    'Denominator',
    'Multiply',
    'Divide',
    'Addition',
    'Total'
  ],
  schema: z.object({
    colors: z
      .array(z.enum(colorOptions))
      .length(3)
      .refine(arrayHasNoDuplicates, 'colors must be different'),
    totalParts: z.number().int().min(3).max(10),
    quantities: z.array(z.number().int().min(1).max(8)),
    people: z.number().int().min(2).max(100),
    colorIndex: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const colors = getRandomSubArrayFromArray(colorOptions, 3);
    const totalParts = randomIntegerInclusive(3, 10);
    const quantities = generateRandomPartitions({
      total: totalParts,
      parts: 3,
      min: 1,
      max: totalParts - 2
    });
    const people = getRandomFromArray([2, 3, 4, 5, 6, 7, 8, 9, 10] as const) * totalParts;
    const colorIndex = randomIntegerInclusive(0, 2);

    return {
      colors,
      totalParts,
      quantities,
      people,
      colorIndex
    };
  },
  Component: props => {
    const {
      question: { colors, totalParts, quantities, people, colorIndex },
      translate,
      displayMode
    } = props;

    const onePart = people / totalParts;

    const options = countRange(3)
      .map(i =>
        countRange(quantities[i]).map(() => {
          return {
            ratioOfSlices: 1,
            color: drawPieChartColors[colors[i]],
            innerLabel: translate.colors[colorOptionsLowercase[colors[i]]]()
          };
        })
      )
      .flat(1);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thereAreXColorCountersHowManyInTotal(
          quantities[colorIndex] * onePart,
          translate.colors[colors[colorIndex]]()
        )}
        sentence={`<ans />`}
        testCorrect={[people.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <PieChart
              pieOptions={options}
              radius={dimens.height * 0.5}
              pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
              labelFontSize={displayMode === 'digital' ? 21 : 36}
            />
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aXv',
  description: 'aXv',
  questionHeight: 900,
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Multiply',
    'Divide',
    'Simplified fractions',
    'Equivalent fractions'
  ],
  schema: z.object({
    colors: z
      .array(z.enum(colorOptions))
      .length(3)
      .refine(arrayHasNoDuplicates, 'colors must be different'),
    totalParts: z.number().int().min(3).max(10),
    quantities: z.array(z.number().int().min(1).max(8)),
    counters: z.number().int().min(2).max(100),
    colorIndexes: z.number().int().min(0).max(2).array().length(2),
    answerDifferences: z.array(z.number().int().min(0).max(10))
  }),
  simpleGenerator: () => {
    const colors = getRandomSubArrayFromArray(colorOptions, 3);
    const colorIndexes = randomUniqueIntegersInclusive(0, 2, 2);

    const totalParts = randomIntegerInclusive(3, 10);
    const quantities = generateRandomPartitions({
      total: totalParts,
      parts: 3,
      min: 1,
      max: totalParts - 2
    });
    const counters = getRandomFromArray([2, 3, 4, 5, 6, 7, 8, 9, 10] as const) * totalParts;
    const differences = [
      Math.abs(quantities[0] - quantities[1]),
      Math.abs(quantities[0] - quantities[2]),
      Math.abs(quantities[1] - quantities[2])
    ];
    const uniqueDifferences = [...new Set(differences)];
    const otherAnswers = randomUniqueIntegersInclusive(
      0,
      totalParts,
      4 - uniqueDifferences.length,
      {
        constraint: x => !uniqueDifferences.includes(x)
      }
    );
    const answerDifferences = [...uniqueDifferences, ...otherAnswers];

    return {
      colors,
      totalParts,
      quantities,
      counters,
      colorIndexes,
      answerDifferences
    };
  },
  Component: props => {
    const {
      question: { colors, totalParts, quantities, counters, colorIndexes, answerDifferences },
      translate,
      displayMode
    } = props;

    const onePart = counters / totalParts;

    const options = countRange(3)
      .map(i =>
        countRange(quantities[i]).map(() => {
          return {
            ratioOfSlices: 1,
            color: drawPieChartColors[colors[i]],
            innerLabel: lowercase(translate.colors[colors[i]]())
          };
        })
      )
      .flat(1);

    const answers = answerDifferences.map(val => val * onePart);
    const shuffledAnswers = shuffle(answers, { random: seededRandom(props.question) });
    const ans = Math.abs(quantities[colorIndexes[0]] - quantities[colorIndexes[1]]) * onePart;

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.thereAreXCountersInTotalSelectDifferenceBetweenXAndY(
          counters,
          translate.colors[colors[colorIndexes[0]]](),
          translate.colors[colors[colorIndexes[1]]]()
        )}
        pdfTitle={translate.instructions.thereAreXCountersInTotalCircleDifferenceBetweenXAndY(
          counters,
          translate.colors[colors[colorIndexes[0]]](),
          translate.colors[colors[colorIndexes[1]]]()
        )}
        selectables={Object.fromEntries(
          shuffledAnswers.map((selectable, i) => [
            ['A', 'B', 'C', 'D'][i],
            selectable.toLocaleString()
          ])
        )}
        selectableTextStyle={{ fontWeight: '700' }}
        leftContent={
          <MeasureView>
            {dimens => (
              <PieChart
                pieOptions={options}
                radius={dimens.height * 0.4}
                pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
                labelFontSize={displayMode === 'digital' ? 21 : 36}
              />
            )}
          </MeasureView>
        }
        correctAnswer={[['A', 'B', 'C', 'D'][shuffledAnswers.indexOf(ans)]]}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadAndInterpretPieCharts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
