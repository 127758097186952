import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBooleanArray,
  getRandomFromArray,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import TenFrameLayout, {
  counterVariantSchema,
  counterVariants
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import { View } from 'react-native';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { filledArray, sortNumberArray } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import QF60DragCountersIntoGroups from '../../../../components/question/questionFormats/QF60DragCountersIntoGroups';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcu',
  description: 'bcu',
  keywords: ['Order', 'Compare', 'Greatest', 'Smallest'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(20)).length(4),
    smallestOrGreatest: z.enum(['smallest', 'greatest']),
    representation: z.discriminatedUnion('name', [
      z.object({
        name: z.literal('ten_frame'),
        pairOrFiveWise: z.enum(['pair', 'five']),
        colorVariant: counterVariantSchema
      }),
      z.object({
        name: z.literal('rekenrek')
      })
    ])
  }),
  simpleGenerator: () => {
    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);
    const name = getRandomFromArray(['ten_frame', 'rekenrek'] as const);
    const numbers = shuffle(randomUniqueIntegersInclusive(1, 20, 4));

    const representation = (() => {
      switch (name) {
        case 'ten_frame':
          return {
            name,
            colorVariant: getRandomFromArray(counterVariants),
            pairOrFiveWise: getRandomFromArray(['pair', 'five'] as const)
          };
        case 'rekenrek':
          return { name };
      }
    })();

    return { numbers, smallestOrGreatest, representation };
  },
  Component: props => {
    const {
      question: { numbers, smallestOrGreatest, representation },
      translate,
      displayMode
    } = props;

    const renderComponent = (n: number) => {
      if (representation.name === 'rekenrek') {
        return <Rekenrek numberShown={n} rows={2} scale="small" />;
      } else {
        if (n > 10) {
          return (
            <View style={{ rowGap: displayMode === 'digital' ? 8 : 16 }}>
              <TenFrameLayout
                items={filledArray(representation.colorVariant, 10)}
                size="xxsmall"
                itemOrdering={representation.pairOrFiveWise === 'pair' ? 'columnFirst' : 'rowFirst'}
              />
              <TenFrameLayout
                items={filledArray(representation.colorVariant, n - 10)}
                size="xxsmall"
                itemOrdering={representation.pairOrFiveWise === 'pair' ? 'columnFirst' : 'rowFirst'}
              />
            </View>
          );
        } else {
          return (
            <TenFrameLayout
              items={filledArray(representation.colorVariant, n)}
              size="xxsmall"
              itemOrdering={representation.pairOrFiveWise === 'pair' ? 'columnFirst' : 'rowFirst'}
            />
          );
        }
      }
    };

    const items = numbers.map(num => ({
      component: renderComponent(num),
      value: num
    }));

    const instruction =
      smallestOrGreatest === 'smallest'
        ? translate.ks1Instructions.selectTheSmallestNumber()
        : translate.ks1Instructions.selectTheGreatestNumber();
    const pdfInstruction =
      smallestOrGreatest === 'smallest'
        ? translate.ks1PDFInstructions.tickTheSmallestNumber()
        : translate.ks1PDFInstructions.tickTheGreatestNumber();

    const answer = smallestOrGreatest === 'smallest' ? Math.min(...numbers) : Math.max(...numbers);

    return (
      <QF11SelectImagesUpTo4
        title={instruction}
        pdfTitle={pdfInstruction}
        renderItems={items}
        numItems={4}
        testCorrect={[answer]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bcv',
  description: 'bcv',
  keywords: ['Order'],
  schema: z.object({
    numbers: z.array(z.number().int().min(0).max(20)).length(3),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(0, 20, 3);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numbers, ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const pdfTitle =
      ordering === 'ascending'
        ? translate.ks1PDFInstructions.orderTheNumbersFromSmallestToGreatest()
        : translate.ks1PDFInstructions.orderTheNumbersFromGreatestToSmallest();

    return (
      <QF5DragOrderHorizontal
        title={translate.ks1Instructions.dragTheCardsToOrderTheNumbers()}
        pdfTitle={pdfTitle}
        testCorrect={correctOrder}
        items={numbers}
        labelsPosition="bottom"
        arrowWidth={115}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcw',
  description: 'bcw',
  keywords: ['Order'],
  schema: z
    .object({
      emptyBoxIdx: numberEnum([0, 1, 2]),
      numberOfCounters: z.array(z.number().int().min(1).max(20)).length(2),
      counterColor: counterVariantSchema,
      ordering: z.enum(['ascending', 'descending'])
    })
    .refine(val => {
      if (val.emptyBoxIdx === 0) {
        return val.ordering === 'ascending'
          ? Math.min(val.numberOfCounters[0], val.numberOfCounters[1]) >= 2
          : Math.max(val.numberOfCounters[0], val.numberOfCounters[1]) <= 19;
      } else if (val.emptyBoxIdx === 1) {
        return Math.abs(val.numberOfCounters[0] - val.numberOfCounters[1]) >= 2;
      } else {
        return val.ordering === 'ascending'
          ? Math.max(val.numberOfCounters[0], val.numberOfCounters[1]) <= 19
          : Math.min(val.numberOfCounters[0], val.numberOfCounters[1]) >= 2;
      }
    }, 'Numbers generated must allow for at least 1 possible answer!'),
  simpleGenerator: () => {
    const emptyBoxIdx = getRandomFromArray([0, 1, 2] as const);
    const counterColor = getRandomFromArray(counterVariants);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    // Ensure question is answerable!
    const { num1, num2 } = rejectionSample(
      () => {
        const [num1, num2] = randomUniqueIntegersInclusive(1, 20, 2);

        return { num1, num2 };
      },
      ({ num1, num2 }) => {
        if (emptyBoxIdx === 0) {
          return ordering === 'ascending' ? Math.min(num1, num2) >= 2 : Math.max(num1, num2) <= 19;
        } else if (emptyBoxIdx === 1) {
          return Math.abs(num1 - num2) >= 2;
        } else {
          return ordering === 'ascending' ? Math.max(num1, num2) <= 19 : Math.min(num1, num2) >= 2;
        }
      }
    );

    return {
      emptyBoxIdx,
      numberOfCounters: [num1, num2],
      counterColor,
      ordering
    };
  },
  Component: props => {
    const {
      question: { emptyBoxIdx, numberOfCounters, counterColor, ordering },
      translate
    } = props;

    const sortedNumbers = sortNumberArray(numberOfCounters, ordering);

    const random = seededRandom(props.question);

    const pdfInstruction =
      ordering === 'ascending'
        ? translate.ks1PDFInstructions.theCountersAreInOrderFromGreatestToSmallestDrawTheMissingCounters()
        : translate.ks1PDFInstructions.theCountersAreInOrderFromSmallestToGreatestDrawTheMissingCounters();

    const prefilledBoxes: (boolean[][] | '<ans/>')[] = [
      getRandomBooleanArray(5, 4, sortedNumbers[0], random),
      getRandomBooleanArray(5, 4, sortedNumbers[1], random)
    ];
    // Insert answer box at index
    prefilledBoxes.splice(emptyBoxIdx, 0, '<ans/>');

    const markSchemeAnswer = (() => {
      switch (emptyBoxIdx) {
        case 0:
          return ordering === 'ascending'
            ? filledArray(counterColor, sortedNumbers[0] - 1)
            : filledArray(counterColor, sortedNumbers[0] + 1);
        case 1:
          return ordering === 'ascending'
            ? filledArray(counterColor, sortedNumbers[0] + 1)
            : filledArray(counterColor, sortedNumbers[0] - 1);
        case 2:
          return ordering === 'ascending'
            ? filledArray(counterColor, sortedNumbers[1] + 1)
            : filledArray(counterColor, sortedNumbers[1] - 1);
      }
    })();

    return (
      <QF60DragCountersIntoGroups
        numberOfGroups={3}
        title={translate.ks1Instructions.dragCountersToFillTheEmptyBox()}
        pdfTitle={pdfInstruction}
        items={[counterColor]}
        testCorrect={userAnswer => {
          if (emptyBoxIdx === 0) {
            return ordering === 'ascending'
              ? userAnswer[0].length < sortedNumbers[0]
              : userAnswer[0].length > sortedNumbers[0];
          } else if (emptyBoxIdx === 1) {
            return ordering === 'ascending'
              ? userAnswer[0].length > sortedNumbers[0] && userAnswer[0].length < sortedNumbers[1]
              : userAnswer[0].length < sortedNumbers[0] && userAnswer[0].length > sortedNumbers[1];
          } else {
            return ordering === 'ascending'
              ? userAnswer[0].length > sortedNumbers[1]
              : userAnswer[0].length < sortedNumbers[1];
          }
        }}
        prefilledBoxes={{ type: 'random', value: prefilledBoxes }}
        markSchemeAnswer={{
          answerToDisplay: [markSchemeAnswer],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        maxCapacity={20}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderNumbersTo20',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
