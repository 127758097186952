import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import Text from '../../typography/Text';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { DisplayMode } from '../../../contexts/displayMode';
import { getActualDimens } from '../../../utils/getActualDimens';

type Props = {
  /**
   * @param labels An array of strings to hold the labels that go around the shape
   * Should be given in form [base, length, hyp]
   */
  labels?: string[];
  /**
   * @param angleLabels An array of strings to hold the labels that are for the angle
   * Should be given in form [larger angle, smaller angle]
   * (Use variation "E" or "F")
   */
  angleLabels?: string[];

  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  /**
   * @param variation Different right angle triangle svgs. "E" and "F" have angle options
   */
  variation: 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
};
/**
 * This component renders a right-angled triangle with labels on the sides.
 */
export const LabelledRATriangle = (props: Props) => {
  const {
    labels,
    dimens: { width, height },
    variation,
    angleLabels
  } = props;

  const displayMode = useContext(DisplayMode);

  const styles = useStyles(width, height);
  const shapeWidth = width - 64;
  const shapeHeight = height - 64;

  const labelWidth = displayMode === 'digital' ? 52 : 75;
  const font = displayMode === 'digital' ? 22 : 32;

  const getInfo = (): {
    svgName: SvgName;
    actualHeight: number;
    actualWidth: number;
  } => {
    switch (variation) {
      case 'A': {
        const svgName = 'Shapes_with_arrows/Right_angle_triangle4_3_arrows';
        return getActualDimens(svgName, {
          width: shapeWidth,
          height: shapeHeight
        });
      }
      case 'B': {
        const svgName = 'Shapes_with_arrows/Right_angle_triangle1_3_arrows';
        return getActualDimens(svgName, {
          width: shapeWidth,
          height: shapeHeight
        });
      }
      case 'C': {
        const svgName = 'Shapes_with_arrows/Right_angle_triangle10_3_arrows';
        return getActualDimens(svgName, {
          width: shapeWidth,
          height: shapeHeight
        });
      }
      case 'D': {
        const svgName = 'Shapes_with_arrows/Right_angle_triangle2_3_arrows';
        return getActualDimens(svgName, {
          width: shapeWidth,
          height: shapeHeight
        });
      }
      case 'E': {
        const svgName = 'Shapes_with_interior_angles/Interior_angles_in_triangle13';
        return getActualDimens(svgName, {
          width: shapeWidth,
          height: shapeHeight
        });
      }
      case 'F': {
        const svgName = 'Shapes_with_interior_angles/Interior_angles_in_triangle9';
        return getActualDimens(svgName, {
          width: shapeWidth,
          height: shapeHeight
        });
      }
    }
  };

  const triangleInfo = getInfo();

  const shapeLabels = () => {
    const containerCenter = width * 0.5;

    const widthDiff = (width - triangleInfo.actualWidth) * 0.5;
    const heightDiff = (height - triangleInfo.actualHeight) * 0.5;

    const styles = {
      A: [
        // Base label
        { left: triangleInfo.actualWidth + widthDiff + 8 },
        // Length label
        { top: triangleInfo.actualHeight + heightDiff, left: containerCenter - labelWidth * 0.5 },
        // Hyp label
        {
          right: triangleInfo.actualWidth * 0.5 + widthDiff + 8,
          bottom: triangleInfo.actualHeight * 0.5 + heightDiff
        }
      ],
      B: [
        // Base label
        { bottom: triangleInfo.actualHeight + 16, left: containerCenter - labelWidth * 0.5 },
        // Length label
        { right: triangleInfo.actualWidth + widthDiff + 8 },
        // Hyp label
        {
          left: triangleInfo.actualWidth * 0.5 + widthDiff + 8,
          top: triangleInfo.actualHeight * 0.5 + heightDiff
        }
      ],
      C: [
        // Base label
        { right: triangleInfo.actualWidth + widthDiff + 8 },
        // Length label
        { top: triangleInfo.actualHeight + heightDiff, left: containerCenter - labelWidth * 0.5 },
        // Hyp label
        {
          left: triangleInfo.actualWidth * 0.5 + widthDiff + 8,
          bottom: triangleInfo.actualHeight * 0.5 + heightDiff
        }
      ],
      D: [
        // Base label
        {
          right: triangleInfo.actualWidth * 0.85 + widthDiff,
          bottom: triangleInfo.actualHeight * 0.6 + heightDiff
        },
        // Length label
        {
          left: triangleInfo.actualWidth * 0.7 + widthDiff,
          bottom: triangleInfo.actualHeight * 0.5 + heightDiff
        },
        // Hyp label
        {
          top: triangleInfo.actualHeight * 0.9 + heightDiff,
          left: containerCenter * 0.95 - labelWidth * 0.2
        }
      ],
      E: [
        // Base label
        { right: triangleInfo.actualWidth + widthDiff + 8 },
        // Length label
        { top: triangleInfo.actualHeight + heightDiff, left: containerCenter - labelWidth * 0.5 },
        // Hyp label
        {
          left: triangleInfo.actualWidth * 0.5 + widthDiff + 8,
          bottom: triangleInfo.actualHeight * 0.5 + heightDiff
        },
        // Smaller angle - top
        {
          left: widthDiff + 8,
          top: heightDiff + triangleInfo.actualHeight * 0.15
        },
        // Larger angle bottom right
        {
          right: widthDiff + triangleInfo.actualWidth * 0.15,
          bottom: heightDiff
        },
        // 90 angle - bottom left
        {
          left: widthDiff + triangleInfo.actualWidth * 0.1,
          bottom: heightDiff + triangleInfo.actualHeight * 0.05
        }
      ],
      F: [
        // Base label
        { bottom: triangleInfo.actualHeight + 16, left: containerCenter - labelWidth * 0.5 },
        // Length label
        { left: triangleInfo.actualWidth + widthDiff + 8 },
        // Hyp label
        {
          right: triangleInfo.actualWidth * 0.5 + widthDiff + 8,
          top: triangleInfo.actualHeight * 0.5 + heightDiff
        },
        // Smaller angle - bottom
        {
          right: widthDiff,
          bottom: heightDiff + triangleInfo.actualHeight * 0.15
        },
        // Larger angle - top left
        {
          left: widthDiff + triangleInfo.actualWidth * 0.16,
          top: heightDiff - 8
        },
        // 90 angle
        {
          right: widthDiff + triangleInfo.actualWidth * 0.07,
          top: heightDiff + triangleInfo.actualHeight * 0.05
        }
      ]
    };

    const labelsSide = labels
      ? labels.map((label, index) => {
          return (
            <View
              key={index}
              style={[
                styles[variation][index],
                { position: 'absolute', minWidth: labelWidth, zIndex: 2 }
              ]}
            >
              <Text
                variant="WRN700"
                style={{ fontSize: font, textAlign: 'center' }}
                numberOfLines={1}
              >
                {label}
              </Text>
            </View>
          );
        })
      : [];

    const labelsAngle = angleLabels
      ? angleLabels.map((label, index) => {
          return (
            <View
              key={index + 3}
              style={[
                styles[variation][index + 3],
                { position: 'absolute', minWidth: labelWidth, zIndex: 2 }
              ]}
            >
              <Text variant="WRN700" style={{ fontSize: font, textAlign: 'center' }}>
                {label}
              </Text>
            </View>
          );
        })
      : [];
    return [...labelsSide, ...labelsAngle];
  };

  return (
    <View>
      <View style={[styles.imageWrapper]}>
        {shapeLabels()}
        <AssetSvg name={triangleInfo.svgName} width={shapeWidth} height={shapeHeight} />
      </View>
    </View>
  );
};

const useStyles = (width: number, height: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageWrapper: {
          alignItems: 'center',
          justifyContent: 'center',
          width,
          height
        }
      }),
    [width, height]
  );
};
