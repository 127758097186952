import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, deduplicate, range } from '../../../../utils/collections';
import { ADD } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgT',
  description: 'bgT',
  keywords: ['Number line', 'Multiple', 'Tens', 'Add'],
  schema: z.object({
    startNumber: z.number().int().min(0).max(90).multipleOf(10),
    number: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const startNumber = randomIntegerInclusiveStep(0, 90, 10);
    const number = randomIntegerInclusive(1, 8);

    return { startNumber, number };
  },
  Component: props => {
    const {
      question: { startNumber, number },
      translate
    } = props;

    const endNumber = startNumber + 10;

    const tickValues = range(1, 9).map(i => ({
      label: i === number ? (startNumber + number).toLocaleString() : '',
      position: startNumber + i
    }));

    const tickArray = [
      {
        label: startNumber.toLocaleString(),
        position: startNumber
      },
      ...tickValues,
      { label: endNumber.toLocaleString(), position: endNumber }
    ];

    const jumpArrowArray = [
      { start: startNumber + number, end: endNumber, label: `${ADD} <ans/>` }
    ];

    return (
      <QF25JumpOnANumberLine
        title={translate.ks1Instructions.completeTheNumberLine()}
        start={startNumber}
        end={endNumber}
        testCorrect={[(10 - number).toString()]}
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        ignoreMinSpacing
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bgU',
  description: 'bgU',
  keywords: ['Add', 'Multiple', 'Tens', 'Related facts'],
  schema: z.object({
    oneDigitNumberA: z.number().int().min(1).max(9),
    tensToAdd: z.number().int().min(10).max(90).multipleOf(10),
    tensAddedToAOrB: z.enum(['A', 'B'])
  }),
  simpleGenerator: () => {
    const oneDigitNumberA = randomIntegerInclusive(1, 9);

    const tensToAdd = randomIntegerInclusiveStep(10, 90, 10);

    const tensAddedToAOrB = getRandomFromArray(['A', 'B'] as const);

    return { oneDigitNumberA, tensToAdd, tensAddedToAOrB };
  },
  Component: props => {
    const {
      question: { oneDigitNumberA, tensToAdd, tensAddedToAOrB },
      translate
    } = props;

    const oneDigitNumberB = 10 - oneDigitNumberA;

    const numberA = tensAddedToAOrB === 'A' ? oneDigitNumberA + tensToAdd : oneDigitNumberA;

    const numberB = tensAddedToAOrB === 'B' ? oneDigitNumberB + tensToAdd : oneDigitNumberB;

    const sentences = [
      `${oneDigitNumberA.toLocaleString()} ${ADD} ${oneDigitNumberB.toLocaleString()} = <ans/>`,
      `${numberA.toLocaleString()} ${ADD} ${numberB.toLocaleString()} = <ans/>`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.ks1Instructions.completeTheAdditions()}
        sentences={sentences}
        testCorrect={[
          [(oneDigitNumberA + oneDigitNumberB).toString()],
          [(numberA + numberB).toString()]
        ]}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bgV',
  description: 'bgV',
  keywords: ['Add', 'Missing number', 'Multiple', 'Tens'],
  schema: z
    .object({
      eqNumbers: z.array(z.number().int().min(1).max(90)).length(2),
      flipEq: z.boolean(),
      options: z.array(z.number().int().min(0).max(180)).length(4)
    })
    .refine(({ options }) => arrayHasNoDuplicates(options), 'All draggable options must be unique')
    .refine(val => val.options.includes(val.eqNumbers[1]), 'Correct answer must be an option'),
  simpleGenerator: () => {
    const flipEq = getRandomBoolean();

    const numberB = randomIntegerInclusive(1, 9);
    const numberA = randomIntegerInclusiveStep(20, 90, 10) - numberB;

    // Incorrect options
    // 1. Sum of two numbers shown (not the 1-digit)
    // 2. Number bond to 10 for tens digit e.g. 42 -> 6
    // 3. Correct answer + 1
    // 4. Correct answer - 1
    // 5. Sum of tens digits
    const incorrectA = numberA + (numberA + numberB);
    const incorrectB = 10 - Math.floor(numberA / 10);
    const incorrectC = numberB + 1;
    const incorrectD = numberB - 1;
    const incorrectE = Math.floor(numberA / 10) + Math.floor((numberA + numberB) / 10);

    // Throwaway any options that are duplicates
    const filteredOptions = deduplicate(
      [incorrectA, incorrectB, incorrectC, incorrectD, incorrectE].filter(x => x !== numberB),
      x => x
    );
    const options = getRandomSubArrayFromArray(filteredOptions, 3);
    const shuffledOptions = shuffle([...options, numberB]);

    return {
      eqNumbers: [numberA, numberB],
      flipEq,
      options: shuffledOptions
    };
  },
  Component: props => {
    const {
      question: { eqNumbers, flipEq, options },
      translate
    } = props;

    const [numberA, numberB] = eqNumbers;

    const { sentence, answer } = buildSimpleNumberSentence(
      flipEq ? [numberB, numberA, numberA + numberB] : [numberA, numberB, numberA + numberB],
      ADD,
      flipEq ? 0 : 1
    );

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheAddition()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteAddition()}
        sentence={sentence}
        items={options}
        testCorrect={[answer]}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddToTheNext10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
