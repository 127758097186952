import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getNumberOfIntervals,
  JugWithLiquid
} from '../../../../components/question/representations/JugWithLiquid';
import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjZ',
  description: 'bjZ',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      intervals: numberEnum([1, 2]),
      volume: z.number().int().min(1).max(10)
    })
    .refine(({ intervals, volume }) => volume % intervals === 0, 'volume should be at an interval'),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([1, 2] as const);

    const capacity = intervals * 5;
    const volume = randomIntegerInclusiveStep(intervals, capacity - intervals, intervals);

    return { volume, intervals };
  },
  Component: props => {
    const {
      question: { volume, intervals },
      translate,
      displayMode
    } = props;

    const intervalsMl = intervals * 1000;
    const volMl = volume * 1000;
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchWaterIsInTheBucket()}
        sentence={translate.ks1AnswerSentences.ansL()}
        testCorrect={[volume.toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="row"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={intervalsMl * 5}
            tickValue={intervalsMl}
            liquidAmount={volMl}
            labelUnits="litres"
            containerType="bucket"
            fontSize={displayMode === 'digital' ? 40 : 60}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bj0',
  description: 'bj0',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      intervals: numberEnum([2000, 5000]),
      volume: z.number().int().min(20).max(50_000)
    })
    .refine(({ volume }) => volume % 10_000 !== 0, 'volume should not be at a major interval'),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([2000, 5000] as const);

    const capacity = intervals * 10;

    const volume = randomIntegerInclusiveStep(intervals, capacity - intervals, intervals, {
      constraint: x => x % 10_000 !== 0
    });

    return { volume, intervals };
  },
  Component: props => {
    const {
      question: { volume, intervals },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchWaterIsInTheBarrel()}
        sentence={translate.ks1AnswerSentences.ansL()}
        testCorrect={[(volume / 1000).toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="row"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <JugWithLiquid
            dimens={dimens}
            jugCapacity={intervals * 10}
            tickValue={10_000}
            unitsPerMajorTick={getNumberOfIntervals(intervals, 10_000)}
            fontSize={30}
            liquidAmount={volume}
            labelUnits="litres"
            containerType="barrel"
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bj1',
  description: 'bj1',
  keywords: ['Volume', 'Measure', 'Litres'],
  schema: z.object({
    numBottles: z.number().int().min(2).max(10),
    bottleCapacity: numberEnum([2, 5]),
    units: z.enum(['l', 'litres'])
  }),
  simpleGenerator: () => {
    const units = getRandomFromArray(['l', 'litres'] as const);
    const bottleCapacity = getRandomFromArray([2, 5] as const);

    const numBottles = randomIntegerInclusive(2, bottleCapacity === 2 ? 10 : 8);

    return { units, bottleCapacity, numBottles };
  },
  Component: props => {
    const {
      question: { units, bottleCapacity, numBottles },
      translate
    } = props;

    const total = bottleCapacity * numBottles;

    const svgName = bottleCapacity === 2 ? 'Milk_carton_2l' : 'Milk_carton_5l';

    const ansString = units === 'l' ? 'ansL' : 'ansLitres';

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchMilkIsThereAltogether()}
        sentence={translate.ks1AnswerSentences[ansString]()}
        testCorrect={[total.toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="row"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            {countRange(numBottles).map((_, i) => (
              <View key={i}>
                <AssetSvg
                  name={svgName}
                  width={dimens.width / numBottles}
                  height={dimens.height * 0.7}
                />
              </View>
            ))}
          </View>
        )}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInLitres',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
