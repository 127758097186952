import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';
import { isInRange } from '../../../../utils/matchers';
import QF48TranslateSvg from '../../../../components/question/questionFormats/QF48TranslateSvg';
import { Point2d } from '../../../../utils/vectors';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { GridWithArrowsBetweenChildren } from '../../../../components/question/representations/GridWithArrowsBetweenChildren';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'blA',
  description: 'blA',
  keywords: ['Forwards', 'Backwards', 'Move', 'Position', 'Left', 'Right', 'Up', 'Down'],
  schema: z
    .object({
      startSpaceX: z.number().int().min(0).max(3),
      startSpaceY: z.number().int().min(0).max(3),
      spacesX: z.number().int().min(-3).max(3),
      spacesY: z.number().int().min(-3).max(3),
      isDownUp: z.boolean(),
      image: z.enum(['Bee', 'Ant']),
      variant: z.discriminatedUnion('variant', [
        z.object({
          variant: z.literal('direction'),
          options: z
            .enum(['left', 'right', 'up', 'down', 'forwards', 'backwards'])
            .array()
            .length(4)
        }),
        z.object({
          variant: z.literal('distance'),
          options: z.number().int().min(1).max(4).array().length(4)
        })
      ])
    })
    .refine(val => {
      const afterY = val.startSpaceY + val.spacesY;
      const afterX = val.startSpaceX + val.spacesX;
      return isInRange(0, 3)(afterY) && isInRange(0, 3)(afterX);
    }, 'item must stay inside track')
    .refine(
      val => [val.spacesX, val.spacesY].filter(val => val !== 0).length === 1,
      'shape must move in one direction'
    ),
  simpleGenerator: () => {
    const image = getRandomFromArray(['Bee', 'Ant'] as const);
    const trackSize = 4;
    const startSpaceX = randomIntegerInclusive(0, trackSize - 1);
    const startSpaceY = randomIntegerInclusive(0, trackSize - 1);
    const isDx = getRandomBoolean();
    const spacesX = isDx
      ? randomIntegerInclusive(1 - trackSize, trackSize - 1, {
          constraint: x => isInRange(0, trackSize - 1)(startSpaceX + x) && x !== 0
        })
      : 0;
    const spacesY = isDx
      ? 0
      : randomIntegerInclusive(1 - trackSize, trackSize - 1, {
          constraint: x => isInRange(0, trackSize - 1)(startSpaceY + x) && x !== 0
        });
    const isDownUp = getRandomBoolean();
    const variantType = getRandomFromArray(['distance', 'direction'] as const);
    const variant =
      variantType === 'direction'
        ? {
            variant: variantType,
            options: shuffle([
              ...(['left', 'right'] as const),
              ...(isDownUp ? (['up', 'down'] as const) : (['forwards', 'backwards'] as const))
            ])
          }
        : { variant: variantType, options: shuffle([1, 2, 3, 4]) };
    return { image, startSpaceX, startSpaceY, spacesX, spacesY, isDownUp, variant };
  },
  Component: props => {
    const {
      question: { image, startSpaceX, startSpaceY, spacesX, spacesY, isDownUp, variant },
      translate
    } = props;
    const [animal, svgName]: [string, SvgName] = (() => {
      switch (image) {
        case 'Ant':
          return [translate.animals.ants(1), 'Ant'];
        case 'Bee':
          return [translate.animals.bees(1), 'Bee_from_above'];
      }
    })();
    const start = new Point2d(startSpaceX, startSpaceY);
    const end = start.add(new Point2d(spacesX, spacesY));
    const [yDirection, yDistance] = ((): ['up' | 'down' | 'forwards' | 'backwards', number] => {
      if (spacesY > 0) return [isDownUp ? 'up' : 'forwards', spacesY];
      else return [isDownUp ? 'down' : 'backwards', -spacesY];
    })();
    const [xDirection, xDistance] = ((): ['left' | 'right', number] => {
      if (spacesX > 0) return ['right', spacesX];
      else return ['left', -spacesX];
    })();
    const isDx = xDistance !== 0;
    const spaces = Math.max(xDistance, yDistance);
    const direction = isDx ? xDirection : yDirection;
    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        questionHeight={1100}
        Content={({ dimens }) => (
          <GridWithArrowsBetweenChildren
            dimens={dimens}
            xMax={4}
            yMax={4}
            points={[start, end]}
            svgNames={[svgName]}
            imageStyle={{ transform: `rotate(${image === 'Ant' ? -90 : 0}deg)` }}
            arrowXOffset={!isDx ? 0 : 0.42}
            arrowYOffset={isDx ? 0 : 0.42}
            squareGrid
          />
        )}
        items={variant.options.map(val => ({
          value: val,
          component: (
            <Text variant="WRN700">
              {typeof val === 'number'
                ? val.toLocaleString()
                : translate.ks1MiscStrings.directions[val]()}
            </Text>
          )
        }))}
        pdfLayout="itemsTop"
        itemVariant="rectangle"
        sentencesStyle={{ alignItems: 'flex-start' }}
        sentence={
          variant.variant === 'direction'
            ? translate.ks1AnswerSentences.theObjectIsMovingXSpacesAns(animal, spaces)
            : translate.ks1AnswerSentences.theObjectIsMovingAnsSpacesX(
                animal,
                spaces,
                translate.ks1MiscStrings.directions[direction]()
              )
        }
        testCorrect={[variant.variant === 'direction' ? direction : spaces]}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'blB',
  description: 'blB',
  keywords: ['Forwards', 'Backwards', 'Move', 'Position', 'Left', 'Right', 'Up', 'Down'],
  schema: z
    .object({
      startSpaceX: z.number().int().min(0).max(3),
      startSpaceY: z.number().int().min(0).max(3),
      spacesX: z.number().int().min(-3).max(3),
      spacesY: z.number().int().min(-3).max(3),
      isUpDown: z.boolean(),
      image: z.enum(['Bee', 'Ant'])
    })
    .refine(val => {
      const afterY = val.startSpaceY + val.spacesY;
      const afterX = val.startSpaceX + val.spacesX;
      return isInRange(0, 3)(afterY) && isInRange(0, 3)(afterX);
    }, 'item must stay inside track')
    .refine(
      val => [val.spacesX, val.spacesY].filter(val => val !== 0).length === 1,
      'shape must move in one direction'
    ),
  simpleGenerator: () => {
    const image = getRandomFromArray(['Bee', 'Ant'] as const);

    const trackSize = 4;
    const startSpaceX = randomIntegerInclusive(0, trackSize - 1);
    const startSpaceY = randomIntegerInclusive(0, trackSize - 1);

    const isDx = getRandomBoolean();
    const isUpDown = getRandomBoolean();

    const spacesX = isDx
      ? randomIntegerInclusive(1 - trackSize, trackSize - 1, {
          constraint: x => isInRange(0, trackSize - 1)(startSpaceX + x) && x !== 0
        })
      : 0;
    const spacesY = isDx
      ? 0
      : randomIntegerInclusive(1 - trackSize, trackSize - 1, {
          constraint: x => isInRange(0, trackSize - 1)(startSpaceY + x) && x !== 0
        });

    return { image, startSpaceX, startSpaceY, spacesX, spacesY, isUpDown };
  },
  Component: props => {
    const {
      question: { image, startSpaceX, startSpaceY, spacesX, spacesY, isUpDown },
      translate
    } = props;

    const [animal, svgName]: [string, SvgName] = (() => {
      switch (image) {
        case 'Ant':
          return [translate.animals.ants(1), 'Ant'];
        case 'Bee':
          return [translate.animals.bees(1), 'Bee_from_above'];
      }
    })();

    const start = new Point2d(startSpaceX, startSpaceY);
    const end = start.add(new Point2d(spacesX, spacesY));

    const [yDirection, yDistance] = ((): ['backwards' | 'forwards' | 'up' | 'down', number] => {
      if (spacesY > 0) return [isUpDown ? 'up' : 'forwards', spacesY];
      else return [isUpDown ? 'down' : 'backwards', -spacesY];
    })();

    const [xDirection, xDistance] = ((): ['left' | 'right', number] => {
      if (spacesX > 0) return ['right', spacesX];
      else return ['left', -spacesX];
    })();

    const isDx = xDistance !== 0;

    return (
      <QF48TranslateSvg
        title={
          isDx
            ? translate.ks1Instructions.dragTheItemXSpacesToTheDx(
                animal,
                xDistance,
                translate.ks1MiscStrings.directions[xDirection]()
              )
            : translate.ks1Instructions.dragTheItemXSpacesDy(
                animal,
                yDistance,
                translate.ks1MiscStrings.directions[yDirection]()
              )
        }
        pdfTitle={
          isDx
            ? translate.ks1PDFInstructions.theItemMovesXSpacesToTheDxDrawItOnTheTrack(
                animal,
                xDistance,
                translate.ks1MiscStrings.directions[xDirection]()
              )
            : translate.ks1PDFInstructions.theItemMovesXSpacesDyDrawACrossOnTheTrack(
                animal,
                yDistance,
                translate.ks1MiscStrings.directions[yDirection]()
              )
        }
        start={[start]}
        end={[end]}
        questionHeight={900}
        svg="custom"
        customDraggable={(_color: string, opacity?: number, _index = 0, width = 200) => ({
          component: (
            <View
              style={{
                opacity: opacity,
                width: width,
                height: width,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <View style={{ transform: `rotate(${image === 'Ant' ? 270 : 0}deg)` }}>
                <AssetSvg name={svgName} width={width * 0.9} height={width * 0.9} />
              </View>
            </View>
          ),
          width: width,
          height: width
        })}
        anchorDX={0}
        anchorDY="height"
        yMax={4}
        xMax={4}
        gridLineWidth={2}
        markSchemeIsGhost
        gridMaxOffset={1}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'blC',
  description: 'blC',
  keywords: ['Forwards', 'Backwards', 'Move', 'Position', 'Left', 'Right', 'Up', 'Down'],
  schema: z
    .object({
      startSpaceX: z.number().int().min(0).max(3),
      startSpaceY: z.number().int().min(0).max(3),
      spacesX: z
        .number()
        .int()
        .min(-3)
        .max(3)
        .refine(val => val !== 0),
      spacesY: z
        .number()
        .int()
        .min(-3)
        .max(3)
        .refine(val => val !== 0),
      image: z.enum(['Bee', 'Ant']),
      isUpDown: z.boolean()
    })
    .refine(val => {
      const afterY = val.startSpaceY + val.spacesY;
      const afterX = val.startSpaceX + val.spacesX;
      return isInRange(0, 3)(afterY) && isInRange(0, 3)(afterX);
    }, 'item must stay inside track'),
  simpleGenerator: () => {
    const image = getRandomFromArray(['Bee', 'Ant'] as const);

    const trackSize = 4;
    const startSpaceX = randomIntegerInclusive(0, trackSize - 1);
    const startSpaceY = randomIntegerInclusive(0, trackSize - 1);

    const spacesX = randomIntegerInclusive(1 - trackSize, trackSize - 1, {
      constraint: x => isInRange(0, trackSize - 1)(startSpaceX + x) && x !== 0
    });

    const spacesY = randomIntegerInclusive(1 - trackSize, trackSize - 1, {
      constraint: x => isInRange(0, trackSize - 1)(startSpaceY + x) && x !== 0
    });

    const isUpDown = getRandomBoolean();

    return { image, startSpaceX, startSpaceY, spacesX, spacesY, isUpDown };
  },
  Component: props => {
    const {
      question: { image, startSpaceX, startSpaceY, spacesX, spacesY, isUpDown },
      translate
    } = props;

    const [animal, svgName]: [string, SvgName] = (() => {
      switch (image) {
        case 'Ant':
          return [translate.animals.ants(1), 'Ant'];
        case 'Bee':
          return [translate.animals.bees(1), 'Bee_from_above'];
      }
    })();

    const start = new Point2d(startSpaceX, startSpaceY);
    const end = start.add(new Point2d(spacesX, spacesY));

    const [yDirection, yDistance] = ((): ['backwards' | 'forwards' | 'up' | 'down', number] => {
      if (spacesY > 0) return [isUpDown ? 'up' : 'forwards', spacesY];
      else return [isUpDown ? 'down' : 'backwards', -spacesY];
    })();

    const [xDirection, xDistance] = ((): ['left' | 'right', number] => {
      if (spacesX > 0) return ['right', spacesX];
      else return ['left', -spacesX];
    })();

    return (
      <QF48TranslateSvg
        title={translate.ks1Instructions.dragTheItemYSpacesDyAndXSpacesDx({
          item: animal,
          dy: yDistance,
          yDirection,
          dx: xDistance,
          xDirection
        })}
        pdfTitle={translate.ks1PDFInstructions.theItemMovesYSpacesDyAndXSpacesDxDrawACrossOnTheTrack(
          {
            item: animal,
            dy: yDistance,
            yDirection,
            dx: xDistance,
            xDirection
          }
        )}
        start={[start]}
        end={[end]}
        questionHeight={900}
        svg="custom"
        customDraggable={(_color: string, opacity?: number, _index = 0, width = 200) => ({
          component: (
            <View
              style={{
                opacity: opacity,
                width: width,
                height: width,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <View style={{ transform: `rotate(${image === 'Ant' ? 270 : 0}deg)` }}>
                <AssetSvg name={svgName} width={width * 0.9} height={width * 0.9} />
              </View>
            </View>
          ),
          width: width,
          height: width
        })}
        anchorDX={0}
        anchorDY="height"
        yMax={4}
        xMax={4}
        gridLineWidth={2}
        markSchemeIsGhost
        gridMaxOffset={1}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribeMovement',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
