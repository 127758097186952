import { BarModelInteractiveWithState } from '../representations/BarModelInteractive';
import { BarModelColorsKey } from '../../../theme/colors';
import Text from '../../typography/Text';
import { trueCount } from '../../../utils/shapes';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { MeasureView } from '../../atoms/MeasureView';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import QF1ContentAndSentence from './QF1ContentAndSentence';

type Props = {
  color?: BarModelColorsKey;
  bars: { rows: number; cols: number; label?: string | JSX.Element }[];
  title: string;
  sentence: string;
  textStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /**
   * Text to display before each bar model. Optional prop.
   */
  preBarText?: string[];
  /** Don't assess the bar model for correctness (i.e. ignore testCorrect.content). Default: true. */
  shadeWithoutAnswer?: boolean;
  testCorrect: {
    sentence: ((answer: string[]) => boolean) | number[];
    content?: number[] | (() => boolean);
  };
  inputMaxCharacters?: number;
  /** If true renders a full width horizontal line with text above bar model */
  fullWidthTopBrace?: string | number;
  braceTextStyle?: StyleProp<TextStyle>;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answersToDisplay?: { sentence?: string[]; content?: boolean[][][] };
    answerText?: string;
  };
  pdfTableHeight?: number;
};

/**
 * Interactive bar model, with a sentence below.
 *
 * Important: by default, with shadeWithoutAnswer unset or set to true, the bar model is not assessed, and may be
 * incomplete.
 */
export default function QF20bInteractiveBarModelWithSentence({
  color,
  bars,
  title,
  sentence,
  textStyle,
  fractionContainerStyle,
  fractionTextStyle,
  fractionDividerStyle,
  preBarText,
  shadeWithoutAnswer = true,
  testCorrect: testCorrectProp,
  fullWidthTopBrace,
  braceTextStyle,
  questionHeight,
  customMarkSchemeAnswer: customMarkSchemeAnswerProp,
  pdfTableHeight,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const customMarkSchemeAnswer =
    customMarkSchemeAnswerProp !== undefined
      ? {
          answersToDisplay: customMarkSchemeAnswerProp.answersToDisplay?.sentence,
          answerText: customMarkSchemeAnswerProp.answerText
        }
      : undefined;

  return (
    <QF1ContentAndSentence
      title={title}
      testCorrect={
        typeof testCorrectProp.sentence === 'function'
          ? testCorrectProp.sentence
          : testCorrectProp.sentence.map(it => it.toString())
      }
      sentence={sentence}
      textStyle={textStyle}
      fractionContainerStyle={fractionContainerStyle}
      fractionTextStyle={fractionTextStyle}
      Content={
        <MeasureView>
          {dimens => (
            <View style={{ rowGap: displayMode === 'digital' ? 16 : 64 }}>
              {bars.map((bar, idx) => {
                return (
                  <View key={idx} style={{ flexDirection: 'row' }}>
                    {bar.label && <Text style={{ paddingRight: 20 }}>{bar.label}</Text>}
                    <BarModelInteractiveWithState
                      id={`barmodel${idx}`}
                      defaultState={
                        displayMode === 'markscheme'
                          ? customMarkSchemeAnswerProp?.answersToDisplay?.content?.[idx]
                          : undefined
                      }
                      testComplete={shadeWithoutAnswer ? undefined : state => trueCount(state) > 0}
                      testCorrect={
                        shadeWithoutAnswer || testCorrectProp.content === undefined
                          ? undefined
                          : typeof testCorrectProp.content === 'function'
                          ? testCorrectProp.content
                          : state => {
                              // Check that this bar model has the right number of cells shaded.
                              const shadedCells = trueCount(state);
                              const correctShadedCells = (testCorrectProp.content as number[])[idx];
                              return shadedCells === correctShadedCells;
                            }
                      }
                      numberOfRows={bar.rows}
                      numberOfCols={bar.cols}
                      color={color}
                      tableHeight={
                        displayMode === 'digital' || !pdfTableHeight
                          ? Math.round(Math.min(dimens.height / bars.length, 100))
                          : pdfTableHeight
                      }
                      tableWidth={
                        // Account for label to the left of the bar model
                        bar.label !== undefined || preBarText !== undefined
                          ? displayMode === 'digital'
                            ? dimens.width - 64
                            : dimens.width - 128
                          : dimens.width
                      }
                      preBarText={preBarText?.[idx]}
                      fractionContainerStyle={fractionContainerStyle}
                      fractionDividerStyle={[{ marginVertical: 0 }, fractionDividerStyle]}
                      fractionTextStyle={[{ fontSize: 32 }, fractionTextStyle]}
                      fullWidthTopBrace={fullWidthTopBrace}
                      braceTextStyle={braceTextStyle}
                    />
                  </View>
                );
              })}
            </View>
          )}
        </MeasureView>
      }
      questionHeight={questionHeight}
      customMarkSchemeAnswer={customMarkSchemeAnswer}
      {...props}
    />
  );
}
