import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { View } from 'react-native';
import { LinesOfSymmetry } from '../../../../components/question/representations/LinesOfSymmetry';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';

////
// Questions
////

const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';
const DIAGONAL = 'diagonal';
type HORIZONTAL = typeof HORIZONTAL;
type VERTICAL = typeof VERTICAL;
type DIAGONAL = typeof DIAGONAL;

const verticalSymmetry = ['square', 'rectangle', 'triangle', 'hexagon', 'star', 'cross'] as const;
const horizontalSymmetry = ['square', 'rectangle', 'pentagon_house', 'hexagon', 'cross'] as const;
const diagonalSymmetry = ['square', 'hexagon', 'star', 'cross'] as const;
const allShapes = [...new Set([...verticalSymmetry, ...horizontalSymmetry])];
const oneLine = ['triangle', 'pentagon_house'] as const;
const bothSymmetries = verticalSymmetry.filter(x =>
  horizontalSymmetry.map(i => i.toString()).includes(x)
);

const assetNameMapper = [
  ['rectangle', 'Rectangle/rectangle'],
  ['square', 'Square/square'],
  ['triangle', 'Isosceles_triangles_narrow/triangle_isos_narrow'],
  ['hexagon', 'Hexagons/hexagon'],
  ['pentagon_house', 'Pentagon_houses/pentagon_house'],
  ['cross', 'Crosses/cross'],
  ['crossRotated', 'Crosses/cross'],
  ['star', 'Star'],
  ['l-shape', 'Hexagons/Irregular_hexagon1']
];

const Question1 = newQuestionContent({
  uid: 'aBa',
  description: 'aBa',
  keywords: ['Horizontal', 'Vertical', 'Diagonal', 'Symmetry', 'Rectangle', 'Square'],
  schema: z.object({
    shape: z.enum(['square', 'rectangleVertial', 'rectangleHorizontal']),
    offset1: z.number().min(0.3).max(1.7),
    offset2: z.number().min(0.3).max(1.7),
    correctSymmetry: z.enum([HORIZONTAL, VERTICAL, DIAGONAL]),
    incorrectOptions: z
      .enum(['incorrectVertical', 'incorrectHorizontal', HORIZONTAL, VERTICAL, DIAGONAL])
      .array()
      .length(2)
  }),
  simpleGenerator: () => {
    const offset1 = getRandomBoolean()
      ? randomIntegerInclusive(3, 7, { constraint: x => x !== 0 }) / 10
      : randomIntegerInclusive(13, 17, { constraint: x => x !== 0 }) / 10;
    const offset2 = getRandomBoolean()
      ? randomIntegerInclusive(3, 7, { constraint: x => x !== 0 && x / 10 !== offset1 }) / 10
      : randomIntegerInclusive(13, 17, { constraint: x => x !== 0 && x / 10 !== offset1 }) / 10;
    const correctSymmetry = getRandomFromArray([HORIZONTAL, VERTICAL, DIAGONAL] as const);
    const shape =
      correctSymmetry === DIAGONAL
        ? ('square' as 'square' | 'rectangleVertial' | 'rectangleHorizontal')
        : getRandomFromArray(['square', 'rectangleVertial', 'rectangleHorizontal'] as const);

    const option1 = getRandomFromArray(['incorrectVertical', 'incorrectHorizontal'] as const);
    const option2 = getRandomFromArray(
      [HORIZONTAL, VERTICAL, DIAGONAL].filter(val => val !== correctSymmetry)
    ) as HORIZONTAL | VERTICAL | DIAGONAL;

    const incorrectOptions = [option1, option2];

    return { shape, offset1, offset2, correctSymmetry, incorrectOptions };
  },
  Component: props => {
    const {
      question: { shape, offset1, offset2, correctSymmetry, incorrectOptions },
      translate
    } = props;

    const mappedIncorrectOptions = incorrectOptions.map(val => {
      if (val === 'incorrectVertical') {
        return { lineOfSymmetry: VERTICAL, offset: offset1, isCorrect: false };
      }
      if (val === 'incorrectHorizontal') {
        return { lineOfSymmetry: HORIZONTAL, offset: offset1, isCorrect: false };
      } else {
        return { lineOfSymmetry: val, isCorrect: false };
      }
    });

    const options = shuffle(
      [
        ...mappedIncorrectOptions,
        { lineOfSymmetry: correctSymmetry, isCorrect: true },
        { lineOfSymmetry: correctSymmetry, offset: offset2, isCorrect: false }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheShapeWithCorrectXLineOfSymmetry(
          translate.symmetry[correctSymmetry]()
        )}
        pdfTitle={translate.instructions.circleTheShapeWithCorrectXLineOfSymmetry(
          translate.symmetry[correctSymmetry]()
        )}
        testCorrect={options
          .filter(eq => eq.isCorrect)
          .map(option => `${option.lineOfSymmetry}${option.offset}`)}
        numItems={4}
        renderItems={({ dimens }) => {
          return options.map(option => ({
            value: `${option.lineOfSymmetry}${option.offset}`,
            component: (
              <LinesOfSymmetry
                shape={shape}
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                linesOfSymmetry={[option.lineOfSymmetry] as Array<HORIZONTAL | VERTICAL | DIAGONAL>}
                offset={option.offset}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aBb',
  description: 'aBb',
  keywords: ['Vertical', 'Symmetry', 'Square'],
  schema: z.object({
    shapes: z
      .enum(['rectangle', 'triangle', 'hexagon', 'pentagon_house', 'star', 'cross', 'square'])
      .array()
      .length(4),
    color: z.enum(['green', 'pink', 'purple', 'yellow'])
  }),
  simpleGenerator: () => {
    // there is only one shape that doesnt have vertical line of symmetry so must have 3 answers
    const correctShapes = getRandomSubArrayFromArray(verticalSymmetry, 3);
    const incorrectShapes = getRandomSubArrayFromArray(
      horizontalSymmetry.filter(x => !verticalSymmetry.map(i => i.toString()).includes(x)),
      1
    );
    const shapes = shuffle([...correctShapes, ...incorrectShapes]);

    const color = getRandomFromArray(['green', 'pink', 'purple', 'yellow'] as const);

    return { shapes, color };
  },
  Component: props => {
    const {
      question: { shapes, color },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithVeritcalLineOfSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithVeritcalLineOfSymmetry()}
        testCorrect={shapes.filter(shape =>
          verticalSymmetry.map(i => i.toString()).includes(shape)
        )}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapes.map(shape => {
            const assetName = `${Object.fromEntries(assetNameMapper)[shape]}_${color}`;
            return {
              value: shape,
              component: (
                <View style={shape === 'pentagon_house' && { transform: 'rotate(90deg)' }}>
                  <AssetSvg
                    name={assetName as SvgName}
                    height={shape === 'pentagon_house' ? undefined : dimens.height * 0.8}
                    width={shape === 'pentagon_house' ? dimens.height * 0.8 : undefined}
                  />
                </View>
              )
            };
          });
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aBb2',
  description: 'aBb',
  keywords: ['Vertical', 'Symmetry', 'Square'],
  schema: z.object({
    shapes: z
      .enum([
        'rectangle',
        'triangle',
        'hexagon',
        'pentagon_house',
        'star',
        'cross',
        'square',
        'l-shape'
      ])
      .array()
      .length(4),
    color: z.enum(['green', 'pink', 'purple', 'yellow'])
  }),
  simpleGenerator: () => {
    const numberOfCorrect = randomIntegerInclusive(2, 3);

    const correctShapes = getRandomSubArrayFromArray(verticalSymmetry, numberOfCorrect);
    const incorrectShapes = getRandomSubArrayFromArray(
      ['l-shape', 'pentagon_house'] as const,
      4 - numberOfCorrect
    );
    const shapes = shuffle([...correctShapes, ...incorrectShapes]);

    const color = getRandomFromArray(['green', 'pink', 'purple', 'yellow'] as const);

    return { shapes, color };
  },
  Component: props => {
    const {
      question: { shapes, color },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithVeritcalLineOfSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithVeritcalLineOfSymmetry()}
        testCorrect={shapes.filter(shape =>
          verticalSymmetry.map(i => i.toString()).includes(shape)
        )}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapes.map(shape => {
            const assetName = `${Object.fromEntries(assetNameMapper)[shape]}_${color}`;
            return {
              value: shape,
              component: (
                <View style={shape === 'pentagon_house' && { transform: 'rotate(90deg)' }}>
                  <AssetSvg
                    name={assetName as SvgName}
                    height={shape === 'pentagon_house' ? undefined : dimens.height * 0.8}
                    width={shape === 'pentagon_house' ? dimens.height * 0.8 : undefined}
                  />
                </View>
              )
            };
          });
        }}
        questionHeight={950}
      />
    );
  },
  questionHeight: 950
});

const Question3 = newQuestionContent({
  uid: 'aBc',
  description: 'aBc',
  keywords: ['Horizontal', 'Symmetry', 'Square'],
  schema: z.object({
    shapes: z
      .enum(['rectangle', 'triangle', 'hexagon', 'pentagon_house', 'star', 'cross', 'square'])
      .array()
      .length(4),
    color: z.enum(['green', 'pink', 'purple', 'yellow'])
  }),
  simpleGenerator: () => {
    const answerCount = randomIntegerInclusive(2, 3);
    const correctShapes = getRandomSubArrayFromArray(horizontalSymmetry, answerCount);
    const incorrectShapes = getRandomSubArrayFromArray(
      verticalSymmetry.filter(x => !horizontalSymmetry.map(i => i.toString()).includes(x)),
      4 - answerCount
    );
    const shapes = shuffle([...correctShapes, ...incorrectShapes]);

    const color = getRandomFromArray(['green', 'pink', 'purple', 'yellow'] as const);

    return { shapes, color };
  },
  Component: props => {
    const {
      question: { shapes, color },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithHorizontalLineOfSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithHorizontalLineOfSymmetry()}
        testCorrect={shapes.filter(shape =>
          horizontalSymmetry.map(i => i.toString()).includes(shape)
        )}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapes.map(shape => {
            const assetName = `${Object.fromEntries(assetNameMapper)[shape]}_${color}`;
            return {
              value: shape,
              component: (
                <View style={shape === 'pentagon_house' && { transform: 'rotate(90deg)' }}>
                  <AssetSvg
                    name={assetName as SvgName}
                    height={shape === 'pentagon_house' ? undefined : dimens.height * 0.8}
                    width={shape === 'pentagon_house' ? dimens.height * 0.8 : undefined}
                  />
                </View>
              )
            };
          });
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'aBc2',
  description: 'aBc',
  keywords: ['Horizontal', 'Symmetry', 'Square'],
  schema: z.object({
    shapes: z
      .enum([
        'rectangle',
        'triangle',
        'hexagon',
        'pentagon_house',
        'star',
        'cross',
        'square',
        'crossRotated',
        'l-shape'
      ])
      .array()
      .length(4),
    color: z.enum(['green', 'pink', 'purple', 'yellow'])
  }),
  simpleGenerator: () => {
    const answerCount = randomIntegerInclusive(2, 3);
    const correctShapes = getRandomSubArrayFromArray(
      [...horizontalSymmetry, 'crossRotated'] as const,
      answerCount
    );
    const incorrectShapes = getRandomSubArrayFromArray(
      [
        ...verticalSymmetry.filter(x => !horizontalSymmetry.map(i => i.toString()).includes(x)),
        'l-shape'
      ] as const,
      4 - answerCount
    );
    const shapes = shuffle([...correctShapes, ...incorrectShapes]);

    const color = getRandomFromArray(['green', 'pink', 'purple', 'yellow'] as const);

    return { shapes, color };
  },
  Component: props => {
    const {
      question: { shapes, color },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithHorizontalLineOfSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithHorizontalLineOfSymmetry()}
        testCorrect={shapes.filter(shape =>
          [...horizontalSymmetry, 'crossRotated'].map(i => i.toString()).includes(shape)
        )}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapes.map(shape => {
            const assetName = `${Object.fromEntries(assetNameMapper)[shape]}_${color}`;
            return {
              value: shape,
              component: (
                <View
                  style={[
                    shape === 'pentagon_house' && { transform: 'rotate(90deg)' },
                    shape === 'crossRotated' && { transform: 'rotate(45deg)' }
                  ]}
                >
                  <AssetSvg
                    name={assetName as SvgName}
                    height={shape === 'pentagon_house' ? undefined : dimens.height * 0.8}
                    width={
                      shape === 'pentagon_house' || shape === 'crossRotated'
                        ? dimens.height * 0.8
                        : undefined
                    }
                  />
                </View>
              )
            };
          });
        }}
        questionHeight={950}
      />
    );
  },
  questionHeight: 950
});

const Question4 = newQuestionContent({
  uid: 'aBd',
  description: 'aBd',
  keywords: ['Diagonal', 'Symmetry', 'Square'],
  schema: z.object({
    shapes: z
      .enum(['rectangle', 'triangle', 'hexagon', 'pentagon_house', 'star', 'cross', 'square'])
      .array()
      .length(4),
    color: z.enum(['green', 'pink', 'purple', 'yellow'])
  }),
  simpleGenerator: () => {
    const answerCount = randomIntegerInclusive(2, 3);
    const correctShapes = getRandomSubArrayFromArray(diagonalSymmetry, answerCount);
    const incorrectShapes = getRandomSubArrayFromArray(
      allShapes.filter(x => !diagonalSymmetry.map(i => i.toString()).includes(x)),
      4 - answerCount
    );
    const shapes = shuffle([...correctShapes, ...incorrectShapes]);

    const color = getRandomFromArray(['green', 'pink', 'purple', 'yellow'] as const);

    return { shapes, color };
  },
  Component: props => {
    const {
      question: { shapes, color },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithDiagonalLineOfSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithDiagonalLineOfSymmetry()}
        testCorrect={shapes.filter(shape =>
          diagonalSymmetry.map(i => i.toString()).includes(shape)
        )}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapes.map(shape => {
            const assetName = `${Object.fromEntries(assetNameMapper)[shape]}_${color}`;
            return {
              value: shape,
              component: (
                <View style={shape === 'pentagon_house' && { transform: 'rotate(90deg)' }}>
                  <AssetSvg
                    name={assetName as SvgName}
                    height={shape === 'pentagon_house' ? undefined : dimens.height * 0.8}
                    width={shape === 'pentagon_house' ? dimens.height * 0.8 : undefined}
                  />
                </View>
              )
            };
          });
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aBe',
  description: 'aBe',
  keywords: ['Horizontal', 'Vertical', 'Symmetry', 'Square'],
  schema: z.object({
    shapes: z
      .enum(['rectangle', 'triangle', 'hexagon', 'pentagon_house', 'star', 'cross', 'square'])
      .array()
      .length(4),
    color: z.enum(['green', 'pink', 'purple', 'yellow'])
  }),
  simpleGenerator: () => {
    const answerCount = randomIntegerInclusive(2, 3);
    const correctShapes = getRandomSubArrayFromArray(bothSymmetries, answerCount);
    const incorrectShapes = getRandomSubArrayFromArray(
      allShapes.filter(x => !bothSymmetries.map(i => i.toString()).includes(x)),
      4 - answerCount
    );
    const shapes = shuffle([...correctShapes, ...incorrectShapes]);

    const color = getRandomFromArray(['green', 'pink', 'purple', 'yellow'] as const);

    return { shapes, color };
  },
  Component: props => {
    const {
      question: { shapes, color },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithBothHorizontalVeritcalLineOfSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithBothHorizontalVeritcalLineOfSymmetry()}
        testCorrect={shapes.filter(shape => bothSymmetries.map(i => i.toString()).includes(shape))}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapes.map(shape => {
            const assetName = `${Object.fromEntries(assetNameMapper)[shape]}_${color}`;
            return {
              value: shape,
              component: (
                <View style={shape === 'pentagon_house' && { transform: 'rotate(90deg)' }}>
                  <AssetSvg
                    name={assetName as SvgName}
                    height={shape === 'pentagon_house' ? undefined : dimens.height * 0.8}
                    width={shape === 'pentagon_house' ? dimens.height * 0.8 : undefined}
                  />
                </View>
              )
            };
          });
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aBf',
  description: 'aBf',
  keywords: ['Horizontal', 'Vertical', 'Diagonal', 'Symmetry', 'Square'],
  schema: z.object({
    shapes: z
      .enum(['rectangle', 'triangle', 'hexagon', 'pentagon_house', 'star', 'cross', 'square'])
      .array()
      .length(4),
    color: z.enum(['green', 'pink', 'purple', 'yellow'])
  }),
  simpleGenerator: () => {
    const oneLineCount = randomIntegerInclusive(1, 2);
    const oneLineShapes = getRandomSubArrayFromArray(oneLine, oneLineCount);
    const multipleLineShapes = getRandomSubArrayFromArray(
      allShapes.filter(x => !oneLine.map(i => i.toString()).includes(x)),
      4 - oneLineCount
    );
    const shapes = shuffle([...oneLineShapes, ...multipleLineShapes]);

    const color = getRandomFromArray(['green', 'pink', 'purple', 'yellow'] as const);

    return { shapes, color };
  },
  Component: props => {
    const {
      question: { shapes, color },
      translate
    } = props;

    const correctAnswer = [
      shapes.filter(i => oneLine.map(i => i.toString()).includes(i)),
      shapes.filter(i => !oneLine.map(i => i.toString()).includes(i))
    ];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortShapesInTable()}
        pdfTitle={translate.instructions.useCardsToSortShapesInTable()}
        zoneNames={[
          translate.tableHeaders.oneLineOfSymmetry(),
          translate.tableHeaders.moreThanOneLineOfSymmetry()
        ]}
        testCorrect={correctAnswer}
        items={shapes.map(shape => {
          const assetName = `${Object.fromEntries(assetNameMapper)[shape]}_${color}`;
          return {
            value: shape,
            component: (
              <View style={shape === 'pentagon_house' && { transform: 'rotate(90deg)' }}>
                <AssetSvg
                  name={assetName as SvgName}
                  height={shape === 'pentagon_house' ? undefined : 70}
                  width={shape === 'pentagon_house' ? 70 : undefined}
                />
              </View>
            )
          };
        })}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="pdfSquare"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        zoneCapacity={4}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LinesOfSymmetry',
  questionTypes: [Question1, Question2v2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2, Question3]
});
export default SmallStep;
