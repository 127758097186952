import { SvgName } from '../../assets/svg';
import { getRandomFromArray, getRandomSubArrayFromArray } from '../random';
import {
  hexagons,
  irregularDodecagons,
  irregularHexagons,
  irregularOctagons,
  kites,
  parallelograms,
  pentagonHouses,
  pentagons,
  raTrapeziums,
  rectangles,
  squares,
  trapeziums
} from './polygons';
import { isoscelesTrianglesNarrow, raTriangles, scaleneTriangles } from './triangles';

export const twoDShapeProperties: {
  rightAngles: number;
  shape: string;
  svg: SvgName[];
}[] = [
  {
    shape: 'isoscelesTriangles',
    svg: [...isoscelesTrianglesNarrow],
    rightAngles: 0
  },
  {
    shape: 'regularHexagons',
    svg: [...hexagons],
    rightAngles: 0
  },
  {
    shape: 'kites',
    svg: [...kites],
    rightAngles: 1
  },
  {
    shape: 'parallelograms',
    svg: [...parallelograms],
    rightAngles: 0
  },
  {
    shape: 'regularPentagons',
    svg: [...pentagons],
    rightAngles: 0
  },
  {
    shape: 'pentagons',
    svg: [...pentagonHouses],
    rightAngles: 2
  },
  {
    shape: 'rectangles',
    svg: [...rectangles],
    rightAngles: 4
  },
  {
    shape: 'rightAngledTrapeziums',
    svg: [...raTrapeziums],
    rightAngles: 2
  },
  {
    shape: 'rightAngledTriangles',
    svg: [...raTriangles],
    rightAngles: 1
  },
  {
    shape: 'scaleneTriangles',
    svg: [...scaleneTriangles],
    rightAngles: 0
  },
  {
    shape: 'squares',
    svg: [...squares],
    rightAngles: 4
  },
  {
    shape: 'trapeziums',
    svg: [...trapeziums],
    rightAngles: 0
  },
  { shape: 'irregularDodecagons', svg: [...irregularDodecagons], rightAngles: 8 },
  { shape: 'irregularHexagon', svg: [...irregularHexagons], rightAngles: 5 },
  { shape: 'irregularOctagon', svg: [...irregularOctagons], rightAngles: 6 }
];

export function getRandomUniqueShapesWithRightAngles(
  quantity: number,
  rightAngles?: number,
  exclude = false,
  color?: 'blue' | 'green' | 'pink' | 'purple' | 'white' | 'yellow'
) {
  const array = rightAngles
    ? twoDShapeProperties.filter(val =>
        exclude ? val.rightAngles !== rightAngles : val.rightAngles === rightAngles
      )
    : twoDShapeProperties;

  // Special filter for right-angles to remove kites
  const filteredArray = array.filter(val => val.shape !== 'kites');

  const object = getRandomSubArrayFromArray([...filteredArray] as const, quantity);
  return object.map(val => {
    const svg = color !== undefined ? [...val.svg].filter(it => it.endsWith(color)) : [...val.svg];
    return {
      ...val,
      svg: getRandomFromArray(svg) as SvgName
    };
  });
}

export const shapeInteriorAngles: { shape: string; sumOfInteriorAngles: number }[] = [
  {
    shape: 'triangles',
    sumOfInteriorAngles: 180
  },
  {
    shape: 'squares',
    sumOfInteriorAngles: 360
  },
  {
    shape: 'octagons',
    sumOfInteriorAngles: 1080
  },
  {
    shape: 'pentagons',
    sumOfInteriorAngles: 540
  },
  {
    shape: 'nonagons',
    sumOfInteriorAngles: 1260
  },
  {
    shape: 'decagons',
    sumOfInteriorAngles: 1440
  },
  {
    shape: 'heptagons',
    sumOfInteriorAngles: 900
  },
  {
    shape: 'hexagons',
    sumOfInteriorAngles: 720
  },
  {
    shape: 'rectangles',
    sumOfInteriorAngles: 360
  }
];

export const allInteriorAnglesShapes: {
  svgName: SvgName;
  shape:
    | 'triangle'
    | 'square'
    | 'pentagon'
    | 'hexagon'
    | 'heptagon'
    | 'octagon'
    | 'nonagon'
    | 'decagon';
  angleSize: number;
}[] = [
  {
    svgName: 'Shapes_with_interior_angles/Equilateral_triangle_all_interior_angles',
    shape: 'triangle',
    angleSize: 60
  },
  {
    svgName: 'Shapes_with_interior_angles/Square_all_interior_angles',
    shape: 'square',
    angleSize: 90
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_pentagon_all_interior_angles',
    shape: 'pentagon',
    angleSize: 108
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_hexagon_all_interior_angles',
    shape: 'hexagon',
    angleSize: 120
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_heptagon_all_interior_angles',
    shape: 'heptagon',
    angleSize: 128.5
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_octagon_all_interior_angles',
    shape: 'octagon',
    angleSize: 135
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_nonagon_all_interior_angles',
    shape: 'nonagon',
    angleSize: 140
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_decagon_all_interior_angles',
    shape: 'decagon',
    angleSize: 144
  }
];

export const markedOneAngle: {
  svgName: SvgName;
  angleSize: number;
  shape: 'decagons' | 'heptagons' | 'hexagons' | 'nonagons' | 'octagons' | 'pentagons';
}[] = [
  {
    svgName: 'Other_shapes/Regular_decagon_equal_sides_one_angle',
    angleSize: 144,
    shape: 'decagons'
  },
  {
    svgName: 'Other_shapes/Regular_heptagon_equal_sides_one_angle',
    angleSize: 128.57,
    shape: 'heptagons'
  },
  {
    svgName: 'Other_shapes/Regular_hexagon_equal_sides_one_angle',
    angleSize: 120,
    shape: 'hexagons'
  },
  {
    svgName: 'Other_shapes/Regular_nonagon_equal_sides_one_angle',
    angleSize: 140,
    shape: 'nonagons'
  },
  {
    svgName: 'Other_shapes/Regular_octagon_equal_sides_one_angle',
    angleSize: 135,
    shape: 'octagons'
  },
  {
    svgName: 'Other_shapes/Regular_pentagon_equal_sides_one_angle',
    angleSize: 108,
    shape: 'pentagons'
  }
];

export function getRandomMarkedShapeOneAngle(quantity: number) {
  const array = getRandomSubArrayFromArray(markedOneAngle, quantity);
  return quantity === 1 ? array[0] : array;
}

export const compoundShapes: {
  svgName: SvgName;
  angleSize: number;
}[] = [
  {
    svgName: 'Layered_shapes/Layered_compound_shapes1',
    angleSize: 141.43
  },
  {
    svgName: 'Layered_shapes/Layered_compound_shapes2',
    angleSize: 15
  },
  {
    svgName: 'Layered_shapes/Layered_compound_shapes3',
    angleSize: 171.43
  },
  {
    svgName: 'Layered_shapes/Layered_compound_shapes4',
    angleSize: 68.57
  },
  {
    svgName: 'Layered_shapes/Layered_compound_shapes5',
    angleSize: 117
  }
];

export function getRandomCompoundShapeOneAngle(quantity: number) {
  const array = getRandomSubArrayFromArray(compoundShapes, quantity);
  return quantity === 1 ? array[0] : array;
}

const oneInteriorAngle: { svgName: SvgName; shape: string; angleSize: number }[] = [
  {
    svgName: 'Shapes_with_interior_angles/Equilateral_triangle_1_interior_angle',
    shape: 'triangle',
    angleSize: 60
  },
  {
    svgName: 'Shapes_with_interior_angles/Square_1_interior_angle',
    shape: 'square',
    angleSize: 90
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_heptagon_1_interior_angle',
    shape: 'heptagon',
    angleSize: 128.5
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_hexagon_1_interior_angle',
    shape: 'hexagon',
    angleSize: 120
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_pentagon_1_interior_angle',
    shape: 'pentagon',
    angleSize: 108
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_decagon_1_interior_angle',
    shape: 'decagon',
    angleSize: 144
  }
];

export function getRandomUniqueOneInteriorAngleShape(quantity: number) {
  return getRandomSubArrayFromArray([...oneInteriorAngle] as const, quantity);
}

export const oneInteriorBottomAngle: { svgName: SvgName; shape: string; angleSize: number }[] = [
  {
    svgName: 'Shapes_with_interior_angles/Regular_pentagon_one_interior_angle',
    shape: 'pentagon',
    angleSize: 108
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_hexagon_one_interior_angle',
    shape: 'hexagon',
    angleSize: 120
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_heptagon_one_interior_angle',
    shape: 'heptagon',
    angleSize: 129
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_octagon_one_interior_angle',
    shape: 'octagon',
    angleSize: 135
  },
  {
    svgName: 'Shapes_with_interior_angles/Regular_decagon_one_interior_angle',
    shape: 'decagon',
    angleSize: 144
  },
  {
    svgName: 'Shapes_with_interior_angles/Irregular_pentagon_one_interior_angle',
    shape: 'irregular pentagon',
    angleSize: 125
  },
  {
    svgName: 'Shapes_with_interior_angles/Irregular_hexagon_one_interior_angle',
    shape: 'irregular hexagon',
    angleSize: 145
  },
  {
    svgName: 'Shapes_with_interior_angles/Irregular_heptagon_one_interior_angle',
    shape: 'irregular heptagon',
    angleSize: 114
  },
  {
    svgName: 'Shapes_with_interior_angles/Irregular_octagon_one_interior_angle',
    shape: 'irregular octagon',
    angleSize: 135
  },
  {
    svgName: 'Shapes_with_interior_angles/Irregular_nonagon_one_interior_angle',
    shape: 'irregular nonagon',
    angleSize: 140
  }
];

export function getRandomUniqueOneInteriorBottomAngleShape(quantity: number) {
  return getRandomSubArrayFromArray([...oneInteriorBottomAngle] as const, quantity);
}
