import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { barModelColors } from '../../../../theme/colors';
import { filledArray, sortNumberArray } from '../../../../utils/collections';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { numberEnum } from '../../../../utils/zod';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { all, create, number } from 'mathjs';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { compareFloats } from '../../../../utils/math';
import QF17cCompleteTheDoubleNumberLine from '../../../../components/question/questionFormats/QF17cCompleteTheDoubleNumberLine';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aC0',
  description: 'aC0',
  keywords: [
    'Converting units',
    'kg',
    'Kilograms',
    'g',
    'Grams',
    'Kilometres',
    'Metres',
    'Bar model'
  ],
  schema: z.object({
    amountOfMeasurement: z.number().int().min(2).max(8),
    isGrams: z.boolean()
  }),
  simpleGenerator: () => {
    const amountOfMeasurement = randomIntegerInclusive(2, 8);
    const isGrams = getRandomBoolean();

    return { amountOfMeasurement, isGrams };
  },
  questionHeight: 800,
  Component: props => {
    const {
      question: { amountOfMeasurement, isGrams },
      translate
    } = props;

    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const sentence = isGrams
      ? translate.answerSentences.numKgEqualsAnsG(amountOfMeasurement.toLocaleString())
      : translate.answerSentences.numKmEqualsAnsM(amountOfMeasurement);

    const numbers = [filledArray(1, amountOfMeasurement), filledArray(1, amountOfMeasurement)];

    const strings = isGrams
      ? [
          filledArray(translate.units.numberOfKg(1), amountOfMeasurement),
          [translate.units.numberOfG(1000), ...filledArray('', amountOfMeasurement - 1)]
        ]
      : [
          filledArray(translate.units.numberOfKm(1), amountOfMeasurement),
          [translate.units.numberOfM(1000), ...filledArray('', amountOfMeasurement - 1)]
        ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToHelpCompleteTheConversion()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(amountOfMeasurement * 1000).toString()]}
        sentence={sentence}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            oneFontSize
            total={amountOfMeasurement}
            dimens={dimens}
            rowColors={[color, 'white']}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aC1',
  description: 'aC1',
  keywords: [
    'Converting units',
    'kg',
    'Kilograms',
    'g',
    'Grams',
    'Kilometres',
    'Metres',
    'Bar model'
  ],
  schema: z.object({
    amountOfMeasurement: z.number().int().min(2).max(8),
    isGrams: z.boolean()
  }),
  simpleGenerator: () => {
    const amountOfMeasurement = randomIntegerInclusive(2, 8);
    const isGrams = getRandomBoolean();

    return { amountOfMeasurement, isGrams };
  },
  questionHeight: 800,
  Component: props => {
    const {
      question: { amountOfMeasurement, isGrams },
      translate
    } = props;

    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const sentence = isGrams
      ? translate.answerSentences.numGEqualsAnsKg(amountOfMeasurement * 1000)
      : translate.answerSentences.numMEqualsAnsKm(amountOfMeasurement * 1000);

    const numbers = [filledArray(1, amountOfMeasurement), filledArray(1, amountOfMeasurement)];

    const strings = isGrams
      ? [
          [translate.units.numberOfKg(1), ...filledArray('', amountOfMeasurement - 1)],
          filledArray(translate.units.numberOfG(1000), amountOfMeasurement)
        ]
      : [
          [translate.units.numberOfKm(1), ...filledArray('', amountOfMeasurement - 1)],
          filledArray(translate.units.numberOfM(1000), amountOfMeasurement)
        ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToHelpCompleteTheConversion()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[amountOfMeasurement.toString()]}
        sentence={sentence}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            oneFontSize
            total={amountOfMeasurement}
            dimens={dimens}
            rowColors={['white', color]}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aC2',
  description: 'aC2',
  keywords: [
    'Converting units',
    'kg',
    'Kilograms',
    'g',
    'Grams',
    'Kilometres',
    'Metres',
    'km',
    'm',
    'Number line',
    'Double number line'
  ],
  schema: z
    .object({
      isGrams: z.boolean(),
      topRowNumbers: z.array(z.number().min(0).max(99)),
      answerIndexA: numberEnum([0, 1, 2, 3]),
      answerIndexB: numberEnum([0, 1, 2, 3])
    })
    .refine(
      val => val.answerIndexA !== val.answerIndexB,
      'answer index for each each must be different'
    ),
  simpleGenerator: () => {
    const isGrams = getRandomBoolean();
    const startingNumber = randomIntegerInclusive(0, 93);
    const topRowNumbers = [
      startingNumber,
      startingNumber + 2,
      startingNumber + 4,
      startingNumber + 6
    ];

    const [answerIndexA, answerIndexB] = getRandomSubArrayFromArray([0, 1, 2, 3] as const, 2);

    return { isGrams, topRowNumbers, answerIndexA, answerIndexB };
  },

  Component: props => {
    const {
      question: { isGrams, topRowNumbers, answerIndexA, answerIndexB },
      translate,
      displayMode
    } = props;

    const bottomRowNumbers = topRowNumbers.map(num => num * 1000);

    const measurements = isGrams
      ? [translate.units.kg(), translate.units.g()]
      : [translate.units.km(), translate.units.m()];

    const topRowTickValues: string[] = [];
    const bottomRowTickValues: string[] = [];

    topRowNumbers.forEach((num, index) => {
      if (index === answerIndexA) {
        topRowTickValues.push('<ans/>');
      } else {
        topRowTickValues.push(num.toLocaleString());
      }
      if (index !== topRowNumbers.length - 1) {
        topRowTickValues.push('');
      }
    });

    bottomRowNumbers.forEach((num, index) => {
      if (index === answerIndexB) {
        bottomRowTickValues.push('<ans/>');
      } else {
        bottomRowTickValues.push(num.toLocaleString());
      }
      if (index !== bottomRowNumbers.length - 1) {
        bottomRowTickValues.push('');
      }
    });

    return (
      <QF17cCompleteTheDoubleNumberLine
        title={translate.instructions.completeNumberLine()}
        topTickValues={topRowTickValues}
        bottomTickValues={bottomRowTickValues}
        inputMaxCharacters={5}
        testCorrect={[
          topRowNumbers[answerIndexA].toString(),
          bottomRowNumbers[answerIndexB].toString()
        ]}
        precedingLinesText={[measurements[0], measurements[1]]}
        questionHeight={700}
        allowEmptyTicks
        customFontSize={displayMode === 'digital' ? 32 : 50}
      />
    );
  },
  questionHeight: 700
});

const Question4 = newQuestionContent({
  uid: 'aC3',
  description: 'aC3',
  keywords: [
    'Converting units',
    'kg',
    'Kilograms',
    'g',
    'Grams',
    'km',
    'Kilometres',
    'm',
    'Metres'
  ],
  schema: z.object({
    units: z.number().min(0.5).max(100000),
    unitToUse: z.enum(['kilograms', 'kilometres', 'metres', 'grams'])
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const unitToUse = getRandomFromArray(['kilograms', 'kilometres', 'metres', 'grams'] as const);

    const units =
      unitToUse === 'kilograms'
        ? randomIntegerInclusive(1, 100)
        : unitToUse === 'kilometres'
        ? number(math.evaluate(`${randomIntegerInclusive(1, 100)} - 0.5`))
        : unitToUse === 'metres'
        ? randomIntegerInclusiveStep(2000, 100000, 1000)
        : randomIntegerInclusiveStep(1100, 99900, 100, { constraint: x => x % 1000 !== 0 });

    return { units, unitToUse };
  },

  Component: props => {
    const {
      question: { units, unitToUse },
      translate
    } = props;

    const sentenceAndAnswer = (() => {
      switch (unitToUse) {
        case 'kilograms':
          return {
            sentence: translate.answerSentences.numKgEqualsAnsG(units.toLocaleString()),
            answer: number(math.evaluate(`${units} * 1000`))
          };
        case 'kilometres':
          return {
            sentence: translate.answerSentences.numKmEqualsAnsM(units),
            answer: number(math.evaluate(`${units} * 1000`))
          };
        case 'metres':
          return {
            sentence: translate.answerSentences.numMEqualsAnsKm(units),
            answer: number(math.evaluate(`${units} / 1000`))
          };
        case 'grams':
          return {
            sentence: translate.answerSentences.numGEqualsAnsKg(units),
            answer: number(math.evaluate(`${units} / 1000`))
          };
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeConversion()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], sentenceAndAnswer.answer.toString())
        }
        questionHeight={500}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        sentence={sentenceAndAnswer.sentence}
        customMarkSchemeAnswer={{
          answersToDisplay: [sentenceAndAnswer.answer.toLocaleString()]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aC4',
  description: 'aC4',
  keywords: [
    'Converting units',
    'kg',
    'Kilograms',
    'g',
    'Grams',
    'km',
    'Kilometres',
    'm',
    'Metres'
  ],
  schema: z.object({
    denominator: numberEnum([2, 4, 5, 10, 20, 50, 100, 500, 1000]),
    numerator: z.number().int().min(1).max(999),
    isGrams: z.boolean()
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([2, 4, 5, 10, 20, 50, 100, 500, 1000] as const);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const isGrams = getRandomBoolean();

    return { denominator, numerator, isGrams };
  },

  Component: props => {
    const {
      question: { denominator, numerator, isGrams },
      translate,
      displayMode
    } = props;

    const sentenceAndAnswer = isGrams
      ? {
          sentence: translate.answerSentences.fracKgEqualsAnsG(
            `<frac n='${1}' d='${denominator}'/>`
          ),
          answer: number(math.evaluate(`(1 / ${denominator}) * 1000`))
        }
      : {
          sentence: translate.answerSentences.fracKmEqualsAnsM(
            `<frac n='${numerator}' d='${denominator}'/>`
          ),
          answer: number(math.evaluate(`(${numerator} / ${denominator}) * 1000`))
        };

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeConversion()}
        testCorrect={[sentenceAndAnswer.answer.toString()]}
        fractionContainerStyle={{ height: 96 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={sentenceAndAnswer.sentence}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aC5',
  description: 'aC5',
  keywords: [
    'Ordering',
    'Converting units',
    'km',
    'Kilometres',
    'm',
    'Metres',
    'Kilograms',
    'Grams',
    'kg',
    'g',
    'Ascending',
    'Descending'
  ],
  schema: z.object({
    kilogramsOrKilometersA: z.number().min(0.1).max(9.9).step(0.1),
    kilogramsOrKilometersB: z.number().min(0.1).max(9.9).step(0.1),
    gramsOrMetersA: z.number().min(1).max(9999),
    gramsOrMetersB: z.number().min(1).max(9999),
    ascending: z.boolean(),
    isGrams: z.boolean()
  }),
  simpleGenerator: () => {
    const [gramsOrMetersA, gramsOrMetersB] = randomUniqueIntegersInclusive(1, 9999, 2);
    const [kilogramsOrKilometersA, kilogramsOrKilometersB] = randomUniqueIntegersInclusive(
      1,
      99,
      2
    ).map(el => el / 10);

    const isGrams = getRandomBoolean();
    const ascending = getRandomBoolean();

    return {
      gramsOrMetersA,
      gramsOrMetersB,
      kilogramsOrKilometersA,
      kilogramsOrKilometersB,
      ascending,
      isGrams
    };
  },
  Component: props => {
    const {
      question: {
        gramsOrMetersA,
        gramsOrMetersB,
        kilogramsOrKilometersA,
        kilogramsOrKilometersB,
        ascending,
        isGrams
      },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: gramsOrMetersA,
          component: isGrams
            ? translate.units.numberOfG(gramsOrMetersA)
            : translate.units.numberOfM(gramsOrMetersA)
        },
        {
          value: gramsOrMetersB,
          component: isGrams
            ? translate.units.numberOfG(gramsOrMetersB)
            : translate.units.numberOfM(gramsOrMetersB)
        },
        {
          value: number(math.evaluate(`${kilogramsOrKilometersA} * 1000`)),
          component: isGrams
            ? translate.units.numberOfKg(kilogramsOrKilometersA)
            : translate.units.numberOfKm(kilogramsOrKilometersA)
        },
        {
          value: number(math.evaluate(`${kilogramsOrKilometersB} * 1000`)),
          component: isGrams
            ? translate.units.numberOfKg(kilogramsOrKilometersB)
            : translate.units.numberOfKm(kilogramsOrKilometersB)
        }
      ],
      { random: seededRandom(props.question) }
    );

    const headings = isGrams
      ? [translate.misc.Heaviest(), translate.misc.Lightest()]
      : [translate.misc.Longest(), translate.misc.Shortest()];

    return (
      <QF4DragOrderVertical
        title={translate.instructions.dragTheCardsToOrderXFromYToZ(
          isGrams ? translate.misc.masses() : translate.misc.distances(),
          ascending ? headings[1] : headings[0],
          ascending ? headings[0] : headings[1]
        )}
        pdfTitle={translate.instructions.useCardsToOrderXFromYToZ(
          isGrams ? translate.misc.masses() : translate.misc.distances(),
          ascending ? headings[1] : headings[0],
          ascending ? headings[0] : headings[1]
        )}
        testCorrect={sortNumberArray(
          items.map(el => el.value),
          ascending ? 'ascending' : 'descending'
        )}
        items={items.map(({ component, value }) => ({
          value,
          component
        }))}
        topLabel={ascending ? headings[1] : headings[0]}
        bottomLabel={ascending ? headings[0] : headings[1]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'KilogramsAndKilometres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
