import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { z } from 'zod';
import { countRange, nestedArraysHaveSameContentsUnordered } from '../../../../utils/collections';
import {
  beforeAfterSchoolActivities,
  beforeAfterSchoolActivitiesNameSchema,
  getRandomUniqueBeforeAfterSchoolActivities
} from '../../../../utils/activities';
import { getRandomFromArray, shuffle } from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import { BeforeAndAfterObject, beforeAndAfterObjects } from '../../../../utils/objects';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfu',
  description: 'bfu',
  keywords: ['Before', 'After', 'Time'],
  schema: z.object({
    items: z.array(z.enum(['before', 'after'])),
    statements: z.array(
      z.object({
        name: z.enum([
          'chocolate',
          'banana',
          'balloon',
          'cookie',
          'donut',
          'sunflower',
          'gift',
          'drawing'
        ]),
        lhsComponent: z.string(),
        correctAnswer: z.enum(['before', 'after'])
      })
    )
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'chocolate',
      'banana',
      'balloon',
      'cookie',
      'donut',
      'sunflower',
      'gift',
      'drawing'
    ] as const);

    const { before, after, name } = beforeAndAfterObjects.find(
      obj => obj.name === object
    ) as BeforeAndAfterObject;

    const items = ['before', 'after'] as const;

    const statements = countRange(2).map(idx => ({
      name,
      lhsComponent: idx === 0 ? before : after,
      correctAnswer: items[idx]
    }));

    return { items: shuffle(items), statements: shuffle(statements) };
  },
  Component: ({ question: { items, statements }, translate, displayMode }) => {
    const itemComponents = items.map(item => {
      return {
        value: item,
        component: <Text variant="WRN700">{translate.ks1MiscStrings[item]()}</Text>
      };
    });

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheWordsToThePictures()}
        pdfTitle={translate.ks1PDFInstructions.matchTheWordsToThePictures()}
        items={itemComponents}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        mainPanelStyle={{ flexDirection: 'column', alignItems: 'center', rowGap: 60 }}
        questionHeight={1000}
        statements={statements.map(statement => ({
          lhsComponent: (
            <AssetSvg
              name={statement.lhsComponent as SvgName}
              width={
                displayMode === 'digital'
                  ? statement.name === 'sunflower'
                    ? 100
                    : 150
                  : statement.name === 'sunflower'
                  ? 150
                  : 250
              }
            />
          ),
          correctAnswer: statement.correctAnswer
        }))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bfv',
  description: 'bfv',
  keywords: ['Before', 'After', 'Time'],
  schema: z.object({
    beforeAfterSchoolActivitiesNames: z.array(beforeAfterSchoolActivitiesNameSchema).length(3)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const beforeAfterSchoolActivitiesNames = getRandomUniqueBeforeAfterSchoolActivities(3);

    return {
      beforeAfterSchoolActivitiesNames
    };
  },
  Component: ({ question: { beforeAfterSchoolActivitiesNames }, translate }) => {
    const schoolActivities = beforeAfterSchoolActivitiesNames.map(
      activity => beforeAfterSchoolActivities(translate)[activity]
    );
    const beforeSchoolActivities = schoolActivities
      .filter(activity => activity.beforeAfterEitherSchool === 'Before')
      .map(activity => activity.activity);
    const afterSchoolActivities = schoolActivities
      .filter(activity => activity.beforeAfterEitherSchool === 'After')
      .map(activity => activity.activity);
    const eitherSchoolActivities = schoolActivities
      .filter(activity => activity.beforeAfterEitherSchool === 'Either')
      .map(activity => activity.activity);

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragCardsToSortTheActivities()}
        pdfTitle={translate.ks1PDFInstructions.sortTheActivitiesIntoTheTable()}
        zoneNames={[
          translate.ks1Instructions.beforeSchool(),
          translate.ks1Instructions.afterSchool()
        ]}
        items={schoolActivities.map(activity => activity.label)}
        itemVariant="rectangle"
        testCorrect={userAnswer => {
          const [beforeSchoolUserAns, afterSchoolUserAns] = userAnswer;

          const beforeOrEitherAns =
            nestedArraysHaveSameContentsUnordered(beforeSchoolUserAns, beforeSchoolActivities) ||
            nestedArraysHaveSameContentsUnordered(userAnswer, [
              ...beforeSchoolUserAns,
              ...eitherSchoolActivities
            ]);

          const afterOrEitherAns =
            nestedArraysHaveSameContentsUnordered(afterSchoolUserAns, afterSchoolActivities) ||
            nestedArraysHaveSameContentsUnordered(userAnswer, [
              ...afterSchoolActivities,
              ...eitherSchoolActivities
            ]);

          return beforeOrEitherAns || afterOrEitherAns;
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            [...beforeSchoolActivities, ...eitherSchoolActivities],
            afterSchoolActivities
          ],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bfw',
  description: 'bfw',
  keywords: ['Before', 'After', 'Time', 'First', 'Then', 'Now'],
  schema: z.object({
    items: z.array(z.enum(['then', 'first', 'now'])),
    statements: z.array(
      z.object({
        name: z.enum([
          'chocolate',
          'banana',
          'balloon',
          'cookie',
          'donut',
          'sunflower',
          'gift',
          'drawing'
        ]),
        lhsComponent: z.string(),
        correctAnswer: z.enum(['first', 'then', 'now'])
      })
    )
  }),
  questionHeight: 1300,
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'chocolate',
      'banana',
      'balloon',
      'cookie',
      'donut',
      'sunflower',
      'gift',
      'drawing'
    ] as const);

    const { before, during, after, name } = beforeAndAfterObjects.find(
      obj => obj.name === object
    ) as BeforeAndAfterObject;

    const items = ['first', 'then', 'now'] as const;

    const statements = countRange(3).map(idx => ({
      name,
      lhsComponent: idx === 0 ? before : idx === 1 ? during : after,
      correctAnswer: items[idx]
    }));

    return { items: shuffle(items), statements: shuffle(statements) };
  },
  Component: ({ question: { items, statements }, translate, displayMode }) => {
    const itemComponents = items.map(item => {
      const itemString =
        item === 'then' ? translate.ks1MiscStrings['thenStr']() : translate.ks1MiscStrings[item]();

      return {
        value: item,
        component: <Text variant="WRN700">{itemString}</Text>
      };
    });

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheWordsToThePictures()}
        pdfTitle={translate.ks1PDFInstructions.matchTheWordsToThePictures()}
        items={itemComponents}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        mainPanelStyle={{ flexDirection: 'column', alignItems: 'center', rowGap: 40 }}
        questionHeight={1300}
        statements={statements.map(statement => ({
          lhsComponent: (
            <AssetSvg
              name={statement.lhsComponent as SvgName}
              width={
                displayMode === 'digital'
                  ? statement.name === 'sunflower' || statement.name === 'balloon'
                    ? 80
                    : 120
                  : statement.name === 'sunflower' || statement.name === 'balloon'
                  ? 150
                  : 250
              }
            />
          ),
          correctAnswer: statement.correctAnswer
        }))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'BeforeAndAfter',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
