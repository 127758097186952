import { fluent } from '@codibre/fluent-iterable';
import { useCallback, useContext, useEffect, useId, useMemo, useState } from 'react';
import { LayoutChangeEvent, StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { Theme, useTheme } from '../../theme';
import { readonlyMapEntry } from '../../utils/collections';
import Text from './Text';
import { createStoreWithContext } from '../../utils/stores';
import { DisplayMode } from '../../contexts/displayMode';

////
// Store setup. Enables AutoScaleText components to coordinate within a group, choosing the smallest font size that
// fits them all.
//
// We create a global store, but often you will want to scope your group IDs, e.g. within a question. For that, there
// is a store provider component. If that is an ancestor, it will be used instead.
////

type Id = string;
type Group = number | string;
type AutoScaleTextStoreState = {
  fontSizeRegistrations: ReadonlyMap<Id, readonly [Group, number | null]>;
  registerFontSize: (id: Id, g: Group, fontSize: number | null) => () => void;
};

export const { Provider: AutoScaleTextStoreProvider, useStore: useAutoScaleTextStore } =
  createStoreWithContext<AutoScaleTextStoreState>(set => ({
    fontSizeRegistrations: new Map(),

    registerFontSize: (id, g, fontSize) => {
      // Update registrations.
      set(old => ({
        fontSizeRegistrations: readonlyMapEntry(old.fontSizeRegistrations, id, oldEntry => {
          if (oldEntry === undefined) {
            return [g, fontSize] as const;
          }

          const [oldGroup, oldFontSize] = oldEntry;
          // Be careful to only make changes if something has truly changed.
          if (g === oldGroup && fontSize === oldFontSize) {
            return oldEntry;
          } else {
            return [g, fontSize] as const;
          }
        })
      }));

      // Return function to unregister
      return () =>
        set(old => ({
          fontSizeRegistrations: readonlyMapEntry(old.fontSizeRegistrations, id, () => undefined)
        }));
    }
  }));

export function groupFontSizeSelector(group?: Group) {
  if (group === undefined) return () => null;
  return (state: AutoScaleTextStoreState) =>
    fluent(state.fontSizeRegistrations)
      .filter(([_id, [g, _fontSize]]) => g === group)
      .map(([_id, [_g, fontSize]]) => fontSize)
      .min() ?? null;
}

////
// The component itself
////

type Props = {
  /** The text to render */
  children: string | number | null | undefined;
  /**
   * Optionally provide a group ID - all components within this group will choose the smallest font size that works
   * for all. Looks at all currently rendered components - if a new component is added which requires a smaller font
   * size the rest will change to match it, which may not be what you want.
   */
  group?: Group;
  /**
   * An alternative to `group` for a specific case for which `group` is not helpful. Do not provide both.
   *
   * In the case where this component will have many instances each with identical variant and dimensions but varying
   * text, and you cannot use the `group` prop because not all components are rendered at the same time, you can
   * instead simply provide the length (in number of characters) of the longest text in the group.
   */
  longestInGroup?: number;
  /**
   * Slightly hacky prop to allow you to override the average letter width used in the calculation. Defaults to 1.
   *
   * Normally, the calculations in this component assume every letter takes up the full em-square. In reality,
   * even the letter m doesn't take up the em-square in width, and most letters are much less. You can use this
   * prop to override the default value of 1 em-width, to be used in the calculation.
   */
  letterEmWidth?: number;
  variant?: keyof Theme['fonts'];
  textStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  minFontSize?: number;
  maxFontSize?: number;
  maxLines?: number;
  shrinkOnly?: boolean;
};

/**
 * Auto-scale some text. This is pretty simple implementation which involves making a lot of assumptions and
 * calculating, rather than measuring a simulation and iterating. The latter approach is how adjustsFontSizeToFit
 * works, and is better overall, but it is not easy to implement.
 *
 * Because we're not simulating how the text will actually look, we are not taking into account the characters
 * involved, where the lines break, or the specifics of the chosen font. See assumptions and usage notes below.
 *
 * Background:
 *
 * Why not just use adjustsFontSizeToFit?
 * - It's not available for web, and it was proving challenging to write a version of it for web and exactly match the
 *   behaviour.
 * - It doesn't have a concept of a group of components which all want the same scaling, and there's no way to query
 *   which font size was chosen for each component in order to retro-fit this concept.
 *
 * Assumptions and limitations:
 * - We don't actually look at what text is entered, just its length. We assume the text is evenly distributed over
 *   the lines. It's up to the user to give text that doesn't exceed the given max lines.
 * - We assume the worst case: that each letter takes up the full em-square (i.e. font-size x font-size).
 *   - This is not usually the case - letters are often narrower (except for monospace fonts).
 *   - We additionally add letter-spacing to the horizontal width, and line-height for the vertical width.
 * - Doesn't support ellipsizing if the font size is scaled below a minimum font size, though this could be added.
 *
 * Usage notes:
 * - Best used for small pieces of text where each line has roughly the same distribution of characters. For example,
 *   displaying a single number or few words.
 * - fontSize, lineHeight and letterSpacing will be ignored from the text style, since there are dedicated props for
 *   this (i.e. pass the variant), and they will be modified by the scaling code anyway.
 * - You must provide containerStyle which decides the container size independently of how the text is displayed.
 * - Default to centered horizontally and vertically. If you don't want this, override justifyContent and alignItems
 *   in containerStyle, and textAlign in textStyle.
 * - To make a group of such texts scale, place {@link AutoScaleTextStoreProvider} as an ancestor and use the `group`
 *   property.
 */
export default function AutoScaleText({
  children,
  group,
  longestInGroup,
  letterEmWidth,
  variant,
  textStyle,
  containerStyle,
  minFontSize,
  maxFontSize,
  maxLines,
  shrinkOnly = false
}: Props) {
  const displayMode = useContext(DisplayMode);
  const theme = useTheme();
  const id = useId();
  const groupFontSize = useAutoScaleTextStore(groupFontSizeSelector(group));
  const registerFontSize = useAutoScaleTextStore(state => state.registerFontSize);

  if (typeof children === 'number') {
    children = children.toString();
  }

  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const onLayout = useCallback((e: LayoutChangeEvent) => {
    const { width, height } = e.nativeEvent.layout;
    setWidth(width);
    setHeight(height);
  }, []);

  // Get the default fontSize, lineHeight and letterSpacing.
  const variantStyle = variant !== undefined ? theme.fonts[variant] : undefined;
  const { fontSize, lineHeight, letterSpacing, fontFamily } = variantStyle ?? {};

  // LibreBaskerville has a higher base letterEmWidth
  letterEmWidth = letterEmWidth ?? (fontFamily?.includes('LibreBaskerville') ? 0.8 : 0.7);

  const lineHeightRatio =
    fontSize !== undefined && lineHeight !== undefined ? lineHeight / fontSize : 1.3;
  const letterSpacingRatio =
    fontSize !== undefined && letterSpacing !== undefined ? letterSpacing / fontSize : 0;

  let chosenFontSize: number | null = null;
  if (
    width !== null &&
    width !== 0 &&
    height !== null &&
    height !== 0 &&
    typeof children === 'string'
  ) {
    chosenFontSize = calculateLargestFontSize(
      height,
      width,
      longestInGroup ?? children.length,
      lineHeightRatio,
      letterSpacingRatio,
      letterEmWidth,
      maxLines
    );

    if (shrinkOnly && fontSize !== undefined) {
      chosenFontSize = Math.min(chosenFontSize, fontSize);
    }
    if (maxFontSize !== undefined) {
      chosenFontSize = Math.min(chosenFontSize, maxFontSize);
    }
    if (minFontSize !== undefined) {
      chosenFontSize = Math.max(chosenFontSize, minFontSize);
    }
  }

  useEffect(() => {
    if (group !== undefined && chosenFontSize !== null) {
      const unregister = registerFontSize(id, group, chosenFontSize);
      return unregister;
    }
  }, [chosenFontSize, group, id, registerFontSize]);

  if (group !== undefined && chosenFontSize !== null) {
    chosenFontSize = Math.min(chosenFontSize, groupFontSize ?? Number.POSITIVE_INFINITY);
  }

  const fontStyle = useMemo(
    () => ({
      ...variantStyle,
      fontSize: chosenFontSize ?? 0,
      lineHeight: (chosenFontSize ?? 0) * lineHeightRatio,
      letterSpacing: (chosenFontSize ?? 0) * letterSpacingRatio
    }),
    [chosenFontSize, letterSpacingRatio, lineHeightRatio, variantStyle]
  );

  return (
    <View
      style={[{ justifyContent: 'center', alignItems: 'center' }, containerStyle]}
      onLayout={onLayout}
    >
      {width !== null && height !== null && (
        <Text
          numberOfLines={maxLines}
          style={[
            { textAlign: 'center' },
            displayMode !== 'digital' && { color: theme.colors.pdfPrimary },
            textStyle,
            fontStyle,
            { maxWidth: width, maxHeight: height }
          ]}
        >
          {children}
        </Text>
      )}
    </View>
  );
}

function calculateLargestFontSizePerLine(
  height: number,
  width: number,
  numCharacters: number,
  lineHeightRatio: number,
  letterSpacingRatio: number,
  letterEmWidth: number
) {
  // Vertical constraints
  const fontSizeVertical = height / lineHeightRatio;

  // Horizontal constraints
  const fontSizeHorizontal = width / numCharacters / (letterEmWidth + letterSpacingRatio);

  // Needs to fit both constraints
  return Math.min(fontSizeHorizontal, fontSizeVertical);
}

function calculateLargestFontSize(
  height: number,
  width: number,
  numCharacters: number,
  lineHeightRatio: number,
  letterSpacingRatio: number,
  letterEmWidth: number,
  maxLines?: number
) {
  let bestFontSize = 1;

  for (let numLines = 1; numLines <= (maxLines ?? Number.POSITIVE_INFINITY); numLines++) {
    // Assume each line is evenly distributed with characters.
    const fontSize = calculateLargestFontSizePerLine(
      height / numLines,
      width,
      Math.ceil(numCharacters / numLines),
      lineHeightRatio,
      letterSpacingRatio,
      letterEmWidth
    );
    if (fontSize > bestFontSize) {
      bestFontSize = fontSize;
    } else {
      break;
    }
  }

  return bestFontSize;
}
