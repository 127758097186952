import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { SimpleBaseTenWithCrossOut } from './Base Ten/SimpleBaseTenWithCrossOut';

type Partition = [number, ...number[]];

export function PartitionNumbersBlockRepresentation({
  usableWidth,
  usableHeight,
  partition,
  scale
}: {
  usableWidth: number;
  usableHeight: number;
  partition: Partition;
  scale: number;
}) {
  const styles = useMemo(() => getStyles(usableWidth, usableHeight), [usableWidth, usableHeight]);

  return (
    <View style={styles.subQuestionContainer}>
      <View style={{ flexDirection: 'row', alignItems: 'stretch', gap: 16 }}>
        {partition.map((part, index) => (
          <Partition
            usableWidth={usableWidth / 2}
            usableHeight={usableHeight}
            scale={scale}
            key={index}
            number={part}
          />
        ))}
      </View>
    </View>
  );
}

function Partition({
  usableWidth,
  usableHeight,
  scale,
  number
}: {
  usableWidth: number;
  usableHeight: number;
  scale: number;
  number: number;
}) {
  const styles = useMemo(() => getStyles(usableWidth, usableHeight), [usableWidth, usableHeight]);
  const tens = Math.floor((number / 10) % 10);
  const ones = Math.floor(number % 10);

  return (
    <View style={styles.partitionPartContainer}>
      <SimpleBaseTenWithCrossOut
        ones={ones}
        tens={tens}
        dimens={{ width: usableWidth, height: usableHeight }}
        scale={scale}
      />
    </View>
  );
}

const getStyles = (w: number, h: number) =>
  StyleSheet.create({
    subQuestionContainer: {
      marginBottom: 0.01 * w,
      flexDirection: 'row',
      flex: 1,
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center'
    },

    partitionPartContainer: {
      flexDirection: 'row',
      borderWidth: 2,
      borderColor: 'red',
      borderRadius: 0.01 * w,
      padding: 0.01 * w,
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      width: w,
      height: h
    }
  });
