import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { all, create, number } from 'mathjs';
import { ScientificNotation, compareFloats, isValidDecimalString } from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { GattegnoChart } from '../../../../components/question/representations/Gattegno Chart/GattegnoChart';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { countRange } from '../../../../utils/collections';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aww',
  description: 'aww',
  keywords: ['Partition', 'Decimals', 'Tens', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    givenNumber: z.number().min(11.11).max(99.99).step(0.01)
  }),
  questionHeight: 1024,
  simpleGenerator: () => {
    const numberTens = randomIntegerInclusiveStep(10, 90, 10);
    const numberOnes = randomIntegerInclusive(1, 9);
    const numberTenths = randomIntegerInclusive(1, 9) / 10;
    const numberHundredths = randomIntegerInclusive(1, 9) / 100;

    const givenNumber = number(
      math.evaluate(`${numberTens} + ${numberOnes} + ${numberTenths} + ${numberHundredths}`)
    );

    return { givenNumber };
  },
  Component: ({ question: { givenNumber }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], givenNumber)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        sentence="<ans/>"
        Content={({ dimens }) => (
          <GattegnoChart number={givenNumber} rowsToShow={[1, 0, -1, -2]} dimens={dimens} />
        )}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        pdfDirection="column"
        questionHeight={1024}
        customMarkSchemeAnswer={{
          answersToDisplay: [givenNumber.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'awx',
  description: 'awx',
  keywords: ['Decimals', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    numberOnes: z.number().int().min(0).max(5),
    numberTenths: z.number().int().min(0).max(5),
    numberHundredths: z.number().int().min(0).max(5)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const { numberOnes, numberTenths, numberHundredths } = rejectionSample(
      () => {
        const [numberOnes, numberTenths, numberHundredths] = countRange(3).map(() => {
          return randomIntegerInclusive(0, 5, {
            constraint: x => x !== 1
          });
        });

        return { numberOnes, numberTenths, numberHundredths };
      },
      ({ numberOnes, numberTenths, numberHundredths }) =>
        [numberOnes, numberTenths, numberHundredths].some(num => num !== 0)
    );

    return { numberOnes, numberTenths, numberHundredths };
  },
  Component: props => {
    const {
      question: { numberOnes, numberTenths, numberHundredths },
      translate
    } = props;

    const total = number(
      math.evaluate(`${numberOnes} + ${numberTenths} * 0.1 + ${numberHundredths} * 0.01`)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], numberOnes) &&
          compareFloats(userAnswer[1], numberTenths) &&
          compareFloats(userAnswer[2], numberHundredths) &&
          compareFloats(userAnswer[3], total)
        }
        sentence={translate.answerSentences.thereAreAnsOnesAnsTenthsAndAnsHundredthsTheNumberIsAns()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(total)}
            columnsToShow={[0, -1, -2]}
            counterVariant={'decimalCounter'}
            headerVariant={'name'}
            dimens={dimens}
            counterPerRow={4}
            rowsToUse={3}
          />
        )}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        pdfDirection="column"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            numberOnes.toLocaleString(),
            numberTenths.toLocaleString(),
            numberHundredths.toLocaleString(),
            total.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'awy',
  description: 'awy',
  keywords: ['Partition', 'Decimals', 'Tens', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    givenNumber: z.number().min(11.0).max(99.99).step(0.01)
  }),
  simpleGenerator: () => {
    const givenInteger = randomIntegerInclusive(11, 99);
    const givenTenths = randomIntegerInclusive(0, 9);
    const givenHundredths = randomIntegerInclusive(0, 9);

    const givenNumber = number(
      math.evaluate(`${givenInteger} + ${givenTenths} * 0.1 + ${givenHundredths} * 0.01`)
    );

    return { givenNumber };
  },
  Component: ({ question: { givenNumber }, translate }) => {
    return (
      <QF12CreateGattegnoChart
        title={translate.instructions.useGattegnotoShowNum(
          givenNumber.toLocaleString(undefined, { minimumFractionDigits: 2 })
        )}
        correctAnswer={givenNumber}
        rowsToShow={[1, 0, -1, -2]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awz',
  description: 'awz',
  keywords: ['Decimals', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    givenNumber: z.number().min(0.01).max(9.99)
  }),
  simpleGenerator: () => {
    const [numberOnes, numberTenths, numberHundredths] = rejectionSample(
      () => {
        const numberOnes = randomIntegerInclusive(0, 9);
        const numberTenths = randomIntegerInclusive(0, 9);
        const numberHundredths = randomIntegerInclusive(0, 9);

        return [numberOnes, numberTenths, numberHundredths];
      },
      digits => digits.filter(elem => elem === 0).length === 1
    );

    const givenNumber = number(
      math.evaluate(`${numberOnes} + ${numberTenths} * 0.1 + ${numberHundredths} * 0.01`)
    );

    return { givenNumber };
  },
  Component: ({ question: { givenNumber }, translate }) => {
    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragInCountersToRepresentNum(
          givenNumber.toLocaleString(undefined, { minimumFractionDigits: 2 })
        )}
        pdfTitle={translate.instructions.drawCountersToRepresentNum(
          givenNumber.toLocaleString(undefined, { minimumFractionDigits: 2 })
        )}
        number={ScientificNotation.fromNumber(givenNumber)}
        columnsToShow={[0, -1, -2]}
        counterVariant="greyCounter"
        headerVariant="name"
        questionHeight={850}
      />
    );
  },
  questionHeight: 850
});

const Question5 = newQuestionContent({
  uid: 'awA',
  description: 'awA',
  keywords: ['Part-whole model', 'Addition', 'Number bonds', 'Decimals', 'Hundredths'],
  schema: z.object({
    givenTenthsOrOnes: z.number().min(0.1).max(9).step(0.1),
    givenHundredths: z.number().min(0.01).max(0.09).step(0.01),
    answerPosition: z.enum(['top', 'bottom'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const tenthsOrOnes = getRandomFromArray([10, 1]);

    const givenTenthsOrOnes = randomIntegerInclusive(1, 9) / tenthsOrOnes;

    const givenHundredths = randomIntegerInclusive(1, 9) / 100;

    const answerPosition = getRandomFromArray(['top', 'bottom'] as const);

    return { givenTenthsOrOnes, givenHundredths, answerPosition };
  },
  Component: ({ question, translate, displayMode }) => {
    const { givenTenthsOrOnes, givenHundredths, answerPosition } = question;

    const total = number(math.evaluate(`${givenTenthsOrOnes} + ${givenHundredths}`));

    const partition = (() => {
      switch (answerPosition) {
        case 'top':
          return [givenHundredths.toLocaleString(), givenTenthsOrOnes.toLocaleString()];
        case 'bottom':
          return ['$ans', '$ans'];
      }
    })();

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        extraSymbol="decimalPoint"
        inputType="numpad"
        initialState={
          displayMode === 'markscheme'
            ? answerPosition === 'top'
              ? [total.toLocaleString()]
              : ['', '']
            : answerPosition === 'top'
            ? ['']
            : ['', '']
        }
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => {
          // If more than 1 decimal point in an answer, mark as false
          if (!answer.every(ans => isValidDecimalString(ans))) {
            return false;
          } else {
            return answerPosition === 'top'
              ? compareFloats(answer[0], total)
              : compareFloats(math.evaluate(`${answer[0]} + ${answer[1]}`), total);
          }
        }}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={answerPosition === 'top' ? '$ans' : total.toLocaleString()}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answerText:
            answerPosition === 'top'
              ? undefined
              : translate.markScheme.anyPossibleCombinationThatSumsToX(total)
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'awB',
  description: 'awB',
  keywords: ['Decimals', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    givenOnes: z.number().int().min(1).max(9),
    givenTenths: z.number().min(0.1).max(0.9).step(0.1),
    givenHundredths: z.number().min(0.01).max(0.09).step(0.01),
    answerPositions: z.enum(['top', 'left and middle', 'left and right', 'middle and right'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const givenOnes = randomIntegerInclusive(1, 9);

    const givenTenths = randomIntegerInclusive(1, 9) / 10;

    const givenHundredths = randomIntegerInclusive(1, 9) / 100;

    const answerPositions = getRandomFromArray([
      'top',
      'left and middle',
      'left and right',
      'middle and right'
    ] as const);

    return { givenOnes, givenTenths, givenHundredths, answerPositions };
  },
  Component: ({ question, translate, displayMode }) => {
    const { givenOnes, givenTenths, givenHundredths, answerPositions } = question;

    const total = number(math.evaluate(`${givenOnes} + ${givenTenths} + ${givenHundredths}`));

    const partition = (() => {
      switch (answerPositions) {
        case 'top':
          return [
            givenHundredths.toLocaleString(),
            givenTenths.toLocaleString(),
            givenOnes.toLocaleString()
          ];
        case 'left and middle':
          return [givenHundredths.toLocaleString(), '$ans', '$ans'];
        case 'left and right':
          return ['$ans', givenTenths.toLocaleString(), '$ans'];
        case 'middle and right':
          return ['$ans', '$ans', givenOnes.toLocaleString()];
      }
    })();

    // Function to check different variations of answers. format needed to accurately calculate totals of answers compared to
    // accurate totals of parts of the number required.
    const answerCheck = (answer: string[]) => {
      // If more than 1 decimal point in an answer, mark as false
      if (!answer.every(ans => isValidDecimalString(ans))) {
        return false;
      }

      // Calculate total of user answers
      const userAnswer =
        answerPositions === 'top' ? answer[0] : math.evaluate(`${answer[0]} + ${answer[1]}`);

      switch (answerPositions) {
        case 'top':
          return compareFloats(answer[0], total);
        case 'left and middle':
          return compareFloats(userAnswer, math.evaluate(`${givenOnes} + ${givenTenths}`));
        case 'left and right':
          return compareFloats(userAnswer, math.evaluate(`${givenOnes} + ${givenHundredths}`));
        case 'middle and right':
          return compareFloats(userAnswer, math.evaluate(`${givenTenths} + ${givenHundredths}`));
      }
    };

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        extraSymbol="decimalPoint"
        inputType="numpad"
        initialState={
          displayMode === 'markscheme'
            ? answerPositions === 'top'
              ? [total.toLocaleString()]
              : ['', '']
            : answerPositions === 'top'
            ? ['']
            : ['', '']
        }
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => answerCheck(answer)}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={answerPositions === 'top' ? '$ans' : total.toLocaleString()}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answerText:
            answerPositions === 'top'
              ? undefined
              : translate.markScheme.anyPossibleCombinationThatSumsToX(total)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
