import { useContext, useMemo } from 'react';
import { StyleSheet, View, ViewStyle, StyleProp, TextStyle } from 'react-native';
import { barModelColors, colors } from '../../../theme/colors';
import { Dimens, ScaleFactorContext } from '../../../theme/scaling';
import { seededRandom, shuffle } from '../../../utils/random';
import AutoScaleText from '../../typography/AutoScaleText';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { projectSetState, SetState } from '../../../utils/react';
import { useId } from 'react';
import TextStructure from '../../molecules/TextStructure';
import Svg, { Line, Path } from 'react-native-svg';
import { withStateHOC } from '../../../stateTree';
import { filledArray } from '../../../utils/collections';
import { BarModelCurlyBrace } from './BarModelCurlyBrace';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /**
   * The numbers that each Cell represents. Each inner array corresponds to each row of the bar model,
   * and each number within corresponds to each number in the bar model.
   * These numbers are used to calculate the width/proportion of the Cell, and will be displayed in the Cell if no string
   * is passed in at this position, and if this cell is not determined to be an answerIndex.
   */
  numbers: number[][];
  /**
   * An optional prop to display strings rather than numbers in each Cell. There are instances where we need to show
   * a different string in a Cell, such as an amount followed by a unit. Questions exist where different units
   * are shown in different rows of the bar model.
   *
   * These strings should be written using the markup language used by {@link TextStructure}.
   */
  strings?: string[][];
  /**
   * The total amount that the bar model is working with - should be equivalent to the total per row.
   * Used to determine the width proportions of each Cell.
   */
  total: number;
  /**
   * The indices of each row that should show an input box instead of the number or string.
   * Each inner array corresponds to each row of the bar model, and each number refers to the indices of that row where
   * an input box should be shown.
   */
  answerIndices?: number[][];
  /**
   * The indices for each cell that should render an arrow rather than a cell.
   * The string or number will be displayed as a label below the arrow.
   */
  arrowIndices?: number[][];
  /**
   * The indices for each cell that should be hidden.
   * The space for the cell will still be occupied, but will just be blank.
   */
  hiddenIndices?: number[][];
  /**
   * An optional prop for a string to show directly after an answer box in the bar model.
   */
  postAnswerString?: string;
  /**
   * An optional prop to determine the height of the rows. Defaults to 100.
   * If a very large number is passed, this will not overflow the maximum dimensions of the whole bar model.
   */
  rowHeight?: number;
  /**
   * An optional prop to determine if all Cells in each row should be the same color.
   * Some bar models require this.
   * Defaults to false.
   */
  sameRowColor?: boolean;
  /**
   * If set to true, keep the same font size across all rows.
   * Defaults to false, i.e. the font size is kept the same within a row, but each
   * row can be a different font size to the others.
   */
  oneFontSize?: boolean;
  /**
   * If set to true, keeps all cells in the bar model proportional.
   * Defaults to false.
   */
  proportional?: boolean;
  /**
   * Custom styling to be applied to each Cell in the bar model.
   */
  cellStyle?: StyleProp<ViewStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /**
   * Colors to assign to each Cell.
   * If any Cells do not have any style passed from this 2D array,
   * it will default to selecting a random color from the Bar Model colors.
   * Overrides rowColors.
   */
  cellColors?: (string | undefined)[][];
  /**
   * Colors to assign to each row.
   * Overridden by cellColors.
   */
  rowColors?: string[];
  /**
   * Custom styling to be applied to the text of each Cell in the bar model.
   */
  textStyle?: StyleProp<TextStyle & ViewStyle>;
  /**
   * Maximum font size for text in all cells.
   */
  maxFontSize?: number;
  /** Vertical bar model */
  vertical?: boolean;
  /**
   * Optional prop to change the letterEmWidth on the AutoScaleText of the Cells.
   * Defaults to 1.
   */
  letterEmWidth?: number;
  /**
   * Text to go above a curly brace that sits over the bar model.
   */
  topBraceText?: string | number;
  /**
   * Text to go below a curly brace that sits under the bar model.
   */
  bottomBraceText?: string | number;
  /**
   * Boolean for when the PDF shading needs to be overridden - usually needed in Qs where shading has to be very specific.
   * Optional prop, defaults to false.
   */
  pdfShadingOverride?: boolean;
  /**
   * Usable dimensions for the question
   */
  dimens: Dimens;
  /**
   * State to hold the users input answers
   */
  userAnswer?: string[][];
  inputMaxCharacters?: number;
  setUserAnswer?: SetState<string[][]>;
  /**
   * Boolean to determine if answer box should be shown above the arrow.
   */
  arrowAnsweBoxPosition?: 'above' | 'below' | undefined;
};

const useRowStyles = (dimens: Dimens, rows: number, rowHeight: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        row: {
          maxHeight: dimens.height / rows,
          height: rowHeight
        }
      }),
    [dimens.height, rowHeight, rows]
  );
};

const useCellStyles = (borderWidth: number, displayMode: 'digital' | 'pdf' | 'markscheme') => {
  return useMemo(
    () =>
      StyleSheet.create({
        cell: {
          borderColor: displayMode === 'digital' ? colors.prussianBlue : 'black',
          borderWidth: borderWidth,
          borderBottomWidth: 0,
          borderEndWidth: 0,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center'
        },
        lastCell: {
          borderEndWidth: borderWidth
        },
        lastRow: {
          borderBottomWidth: borderWidth
        },
        inputBar: {
          backgroundColor: 'white'
        },
        answerCellWithPostAnswerString: {
          flexDirection: 'row',
          justifyContent: 'center'
        },
        arrowCell: {
          borderBottomWidth: 0,
          borderEndWidth: 0,
          backgroundColor: 'transparent'
        },
        singleRowArrowCell: {
          borderBottomWidth: 0,
          borderTopWidth: 0,
          borderEndWidth: 0,
          backgroundColor: 'transparent'
        },
        arrowCellNextToHiddenCell: {
          borderStartWidth: 0
        },
        arrowCellWithoutTopBorder: {
          borderTopWidth: 0
        },
        hiddenCell: {
          borderBottomWidth: 0,
          borderEndWidth: 0,
          backgroundColor: 'transparent'
        },
        hiddenCellStartRow: {
          borderStartWidth: 0
        },
        answerBoxBelowArrow: {
          borderTopWidth: borderWidth
        }
      }),
    [borderWidth, displayMode]
  );
};

/**
 * This component renders a bar model, with multiple rows.
 * The bars go horizontally across the screen.
 */
export const BarModel = ({
  numbers,
  strings,
  total,
  answerIndices = [],
  arrowIndices = [],
  hiddenIndices = [],
  postAnswerString,
  rowHeight,
  sameRowColor = false,
  oneFontSize = false,
  proportional = false,
  cellStyle,
  cellColors,
  rowColors,
  vertical,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle,
  textStyle,
  maxFontSize,
  letterEmWidth = 1,
  topBraceText,
  bottomBraceText,
  pdfShadingOverride = false,
  dimens,
  userAnswer = [],
  inputMaxCharacters,
  arrowAnsweBoxPosition,
  setUserAnswer = () => {
    /* do nothing */
  }
}: Props) => {
  const horizontalMargin = 20;

  const totalModelWidth = dimens.width - 2 * horizontalMargin;

  const heightOfRows =
    rowHeight ?? (dimens.height / numbers.length < 100 ? dimens.height / numbers.length : 100);

  const rowStyles = useRowStyles(dimens, numbers.length, heightOfRows);

  const id = useId();

  const barColors = [
    colors.pacificBlue600,
    colors.acidGreen,
    colors.dangerLight,
    ...shuffle(Object.values(barModelColors), {
      random: seededRandom({ numbers, strings, total })
    })
  ];

  return (
    <View style={[{ width: totalModelWidth }]}>
      {topBraceText && <BarModelCurlyBrace braceText={topBraceText} />}
      {numbers.map((row, rowIndex) => {
        return (
          <Row
            numbers={numbers}
            strings={strings?.[rowIndex]}
            key={rowIndex}
            rowStyle={[rowStyles.row]}
            row={row}
            rowHeight={heightOfRows}
            total={total}
            width={totalModelWidth}
            vertical={vertical}
            answerIndices={answerIndices[rowIndex] ?? []}
            arrowIndices={arrowIndices[rowIndex] ?? []}
            hiddenIndices={hiddenIndices[rowIndex] ?? []}
            rowUserAnswer={userAnswer[rowIndex] ?? []}
            setRowUserAnswer={projectSetState(setUserAnswer, rowIndex)}
            isLastRow={rowIndex === numbers.length - 1}
            postAnswerString={postAnswerString}
            rowIndex={rowIndex}
            barColors={barColors}
            sameRowColor={sameRowColor}
            oneFontSize={oneFontSize}
            proportional={proportional}
            cellStyle={cellStyle}
            cellColors={cellColors?.[rowIndex]}
            rowColor={rowColors?.[rowIndex]}
            fractionContainerStyle={fractionContainerStyle}
            fractionDividerStyle={fractionDividerStyle}
            fractionTextStyle={fractionTextStyle}
            textStyle={textStyle}
            maxFontSize={maxFontSize}
            letterEmWidth={letterEmWidth}
            pdfShadingOverride={pdfShadingOverride}
            id={id}
            inputMaxCharacters={inputMaxCharacters}
            arrowAnsweBoxPosition={arrowAnsweBoxPosition}
          />
        );
      })}
      {bottomBraceText && (
        <View>
          <BarModelCurlyBrace braceText={bottomBraceText} topOrBottomBrace="bottom" />
        </View>
      )}
    </View>
  );
};

const Row = ({
  numbers,
  strings,
  row,
  rowStyle,
  rowHeight,
  total,
  width,
  answerIndices,
  arrowIndices,
  hiddenIndices,
  postAnswerString,
  barColors,
  rowIndex,
  rowUserAnswer,
  setRowUserAnswer,
  isLastRow = false,
  sameRowColor,
  oneFontSize,
  proportional,
  cellStyle,
  cellColors,
  rowColor,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle,
  textStyle,
  maxFontSize,
  letterEmWidth,
  pdfShadingOverride,
  id,
  vertical,
  inputMaxCharacters,
  arrowAnsweBoxPosition
}: {
  numbers: number[][];
  strings?: string[];
  row: number[];
  rowStyle: StyleProp<ViewStyle>;
  rowHeight: number;
  total: number;
  width: number;
  answerIndices: number[];
  arrowIndices: number[];
  hiddenIndices: number[];
  postAnswerString?: string;
  barColors: string[];
  rowColor?: string;
  rowIndex: number;
  rowUserAnswer: string[];
  setRowUserAnswer: SetState<string[]>;
  isLastRow: boolean;
  sameRowColor: boolean;
  oneFontSize: boolean;
  proportional: boolean;
  cellStyle: StyleProp<ViewStyle>;
  cellColors?: (string | undefined)[];
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  textStyle: StyleProp<TextStyle>;
  maxFontSize?: number;
  letterEmWidth: number;
  pdfShadingOverride: boolean;
  id: string;
  vertical?: boolean;
  inputMaxCharacters?: number;
  arrowAnsweBoxPosition?: 'above' | 'below' | undefined;
}) => {
  return (
    <View style={[{ flexDirection: 'row', width }, rowStyle]}>
      {row.map((number, cellIndex) => {
        // If a row only has one Cell, that Cell will take up the entire width of that row.
        // If a row has two Cells, each Cell must be a minimum of 18% and maximum of 82% of the totalModelWidth.
        // Otherwise, each Cell will simply be the correction proportional width of the totalModelWidth.
        const clamp = (input: number, min: number, max: number) =>
          Math.max(min, Math.min(input, max));
        const proportionalWidth =
          row.length === 2 && !proportional ? clamp(number / total, 0.18, 0.82) : number / total;
        const cellWidth = proportionalWidth * width;

        return (
          <Cell
            numbers={numbers}
            string={strings?.[cellIndex]}
            key={cellIndex}
            number={number}
            width={cellWidth}
            barColors={barColors}
            cellColor={cellColors?.[cellIndex]}
            rowColor={rowColor}
            rowIndex={rowIndex}
            rowHeight={rowHeight}
            cellIndex={cellIndex}
            isAnswer={answerIndices.includes(cellIndex)}
            isArrow={arrowIndices.includes(cellIndex)}
            isHidden={hiddenIndices.includes(cellIndex)}
            hiddenIndices={hiddenIndices}
            isLastRow={isLastRow}
            isLastCell={cellIndex === row.length - 1}
            postAnswerString={postAnswerString}
            cellUserAnswer={rowUserAnswer[cellIndex] ?? ''}
            setCellUserAnswer={projectSetState(setRowUserAnswer, cellIndex)}
            sameRowColor={sameRowColor}
            oneFontSize={oneFontSize}
            cellStyle={cellStyle}
            fractionContainerStyle={fractionContainerStyle}
            fractionDividerStyle={fractionDividerStyle}
            fractionTextStyle={fractionTextStyle}
            textStyle={textStyle}
            maxFontSize={maxFontSize}
            letterEmWidth={letterEmWidth}
            pdfShadingOverride={pdfShadingOverride}
            id={id}
            vertical={vertical}
            inputMaxCharacters={inputMaxCharacters}
            arrowAnsweBoxPosition={arrowAnsweBoxPosition}
          />
        );
      })}
    </View>
  );
};

const Cell = ({
  numbers,
  string,
  number,
  width,
  barColors,
  cellColor,
  rowColor,
  rowIndex,
  rowHeight,
  cellIndex,
  isAnswer,
  isArrow,
  isHidden,
  hiddenIndices,
  isLastCell,
  isLastRow = false,
  postAnswerString,
  cellUserAnswer,
  setCellUserAnswer,
  sameRowColor,
  oneFontSize,
  cellStyle,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle,
  textStyle,
  maxFontSize,
  letterEmWidth,
  id,
  vertical,
  inputMaxCharacters,
  arrowAnsweBoxPosition
}: {
  numbers: number[][];
  string?: string;
  number: number;
  width: number;
  barColors: string[];
  cellColor?: string;
  rowColor?: string;
  rowIndex: number;
  rowHeight: number;
  cellIndex: number;
  isAnswer: boolean;
  isArrow: boolean;
  isHidden: boolean;
  hiddenIndices: number[];
  isLastCell: boolean;
  isLastRow: boolean;
  postAnswerString?: string;
  cellUserAnswer: string;
  setCellUserAnswer: SetState<string>;
  sameRowColor: boolean;
  oneFontSize: boolean;
  cellStyle: StyleProp<ViewStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  textStyle: StyleProp<TextStyle>;
  maxFontSize?: number;
  letterEmWidth: number;
  pdfShadingOverride: boolean;
  id: string;
  vertical?: boolean;
  inputMaxCharacters?: number;
  arrowAnsweBoxPosition?: 'above' | 'below' | undefined;
}) => {
  const displayMode = useContext(DisplayMode);
  const scaleFactor = useContext(ScaleFactorContext);
  const borderWidth =
    displayMode === 'digital' ? Math.max(2, 2 / scaleFactor) : Math.max(4, 2 / scaleFactor);

  const cellStyles = useCellStyles(borderWidth, displayMode);

  const isAnswerWithPostAnswerString = isAnswer && postAnswerString !== undefined;

  const minFontSize = 21;

  const additionalHeight =
    arrowAnsweBoxPosition === 'above' ? (displayMode === 'digital' ? 200 : 300) : 0;

  const currentCellNumber = (() => {
    let cellCount = 0;
    for (let i = 0; i < rowIndex; i++) {
      cellCount += numbers[i].length;
    }
    cellCount += cellIndex;

    return cellCount;
  })();

  const backgroundColor =
    cellColor !== undefined
      ? cellColor
      : rowColor !== undefined
      ? rowColor
      : sameRowColor
      ? barColors[rowIndex]
      : barColors[currentCellNumber];

  return (
    <View
      style={[
        cellStyles.cell,
        isLastCell && cellStyles.lastCell,
        isLastRow && cellStyles.lastRow,
        {
          width: width,
          backgroundColor
        },
        isAnswerWithPostAnswerString && cellStyles.answerCellWithPostAnswerString,
        numbers.length === 1
          ? isArrow && cellStyles.singleRowArrowCell
          : isArrow && cellStyles.arrowCell,
        isArrow && hiddenIndices.includes(cellIndex - 1) && cellStyles.arrowCellNextToHiddenCell,
        isArrow && arrowAnsweBoxPosition === 'above' && cellStyles.arrowCellWithoutTopBorder,
        isHidden && cellStyles.hiddenCell,
        isHidden && cellIndex === 0 && cellStyles.hiddenCellStartRow,
        cellStyle
      ]}
    >
      {isAnswer ? (
        postAnswerString ? (
          <>
            <NoKeyboardTextInput
              value={cellUserAnswer}
              maxCharacters={inputMaxCharacters}
              onChangeText={setCellUserAnswer}
              style={[
                cellStyles.inputBar,
                {
                  // Account for borderTopWidth and borderBottomWidth if isLastRow, otherwise account only for borderTopWidth
                  minHeight: isLastRow ? rowHeight - 2 * borderWidth : rowHeight - borderWidth
                }
              ]}
            />
            <AutoScaleText
              group={oneFontSize ? id : `${id}_${rowIndex}`}
              minFontSize={minFontSize}
              maxFontSize={maxFontSize}
              containerStyle={{
                width: maxFontSize || minFontSize,
                height: rowHeight,
                alignItems: 'flex-start',
                marginLeft: 5
              }}
              maxLines={1}
              letterEmWidth={letterEmWidth}
              textStyle={[
                {
                  color:
                    backgroundColor === colors.pacificBlue600 ||
                    backgroundColor === colors.dangerLight
                      ? colors.white
                      : displayMode === 'digital'
                      ? colors.prussianBlue
                      : colors.black
                },
                textStyle
              ]}
            >
              {postAnswerString}
            </AutoScaleText>
          </>
        ) : (
          <NoKeyboardTextInput
            value={cellUserAnswer}
            onChangeText={setCellUserAnswer}
            style={[
              cellStyles.inputBar,
              {
                // Account for borderTopWidth and borderBottomWidth if isLastRow, otherwise account only for borderTopWidth
                minHeight: isLastRow ? rowHeight - 2 * borderWidth : rowHeight - borderWidth,
                // Account for borderLeftWidth and borderRightWidth if isLastCell otherwise account only for borderLeftWidth
                minWidth: isLastCell ? width - 2 * borderWidth : width - borderWidth,
                width: isLastCell ? width - 2 * borderWidth : width - borderWidth
              }
            ]}
          />
        )
      ) : isArrow ? (
        <View
          style={{
            width: width,
            minWidth: 100,
            height: rowHeight + additionalHeight,
            alignItems: 'center'
          }}
        >
          <View style={{ alignItems: 'center' }}>
            {arrowAnsweBoxPosition === 'above' && (
              <NoKeyboardTextInput
                value={cellUserAnswer}
                maxCharacters={inputMaxCharacters}
                onChangeText={setCellUserAnswer}
                style={{ top: 20 }}
              />
            )}
            <Svg width={width} height={rowHeight}>
              <Path
                d={`M0,${rowHeight / 2}
                      L10,${rowHeight / 2 - 10}
                      L10,${rowHeight / 2 + 10}
                      Z`}
                fill={displayMode === 'digital' ? colors.prussianBlue : colors.black}
              />
              <Line
                x1="0"
                y1={rowHeight / 2}
                x2={width - 10}
                y2={rowHeight / 2}
                stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
                strokeWidth={displayMode === 'digital' ? 2 : 4}
              />
              <Path
                d={`M${width},${rowHeight / 2}
                      L${width - 10},${rowHeight / 2 - 10}
                      L${width - 10},${rowHeight / 2 + 10}
                      Z`}
                fill={displayMode === 'digital' ? colors.prussianBlue : 'black'}
              />
            </Svg>
            <TextStructure
              sentence={string === undefined ? number.toLocaleString() : string}
              fractionTextStyle={fractionTextStyle}
              fractionContainerStyle={fractionContainerStyle}
              fractionDividerStyle={fractionDividerStyle}
              style={{ position: 'absolute', top: displayMode === 'digital' ? 52 : 72 }}
            />
            {arrowAnsweBoxPosition === 'below' && (
              <NoKeyboardTextInput
                value={cellUserAnswer}
                maxCharacters={inputMaxCharacters}
                onChangeText={setCellUserAnswer}
                style={{ bottom: 20 }}
              />
            )}
          </View>
        </View>
      ) : !string?.match(/<frac/) ? (
        <AutoScaleText
          group={oneFontSize ? id : `${id}_${rowIndex}`}
          containerStyle={
            vertical
              ? { width: rowHeight, height: width, transform: 'rotate(-90deg)' }
              : { width: width, height: rowHeight }
          }
          maxFontSize={maxFontSize}
          maxLines={1}
          minFontSize={minFontSize}
          letterEmWidth={letterEmWidth}
          textStyle={[
            {
              color:
                backgroundColor === colors.pacificBlue600 || backgroundColor === colors.dangerLight
                  ? colors.white
                  : displayMode === 'digital'
                  ? colors.prussianBlue
                  : colors.black
            },
            textStyle
          ]}
        >
          {string === undefined ? number.toLocaleString() : string}
        </AutoScaleText>
      ) : (
        <TextStructure
          sentence={string}
          fractionTextStyle={[
            {
              fontSize: 28,
              color:
                backgroundColor === colors.pacificBlue600 || backgroundColor === colors.dangerLight
                  ? colors.white
                  : displayMode === 'digital'
                  ? colors.prussianBlue
                  : colors.black
            },
            fractionTextStyle
          ]}
          fractionContainerStyle={[{ height: 48 }, fractionContainerStyle]}
          fractionDividerStyle={[
            {
              borderColor:
                backgroundColor === colors.pacificBlue600 || backgroundColor === colors.dangerLight
                  ? colors.white
                  : displayMode === 'digital'
                  ? colors.prussianBlue
                  : colors.black
            },
            fractionDividerStyle
          ]}
        />
      )}
    </View>
  );
};

export const BarModelWithState = withStateHOC(BarModel, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray(filledArray('', props.numbers[0].length), props.numbers.length)
  })
});
