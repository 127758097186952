// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import TenthsAsFractions from './1TenthsAsFractions';
import TenthsAsDecimals from './2TenthsAsDecimals';
import TenthsOnAPlaceValueChart from './3TenthsOnAPlaceValueChart';
import TenthsOnANumberLine from './4TenthsOnANumberLine';
import DivideA1DigitNumberBy10 from './5DivideA1DigitNumberBy10';
import DivideA2DigitNumberBy10 from './6DivideA2DigitNumberBy10';
import HundredthsAsFractions from './7HundredthsAsFractions';
import HundredthsAsDecimals from './8HundredthsAsDecimals';
import HundredthsOnAPlaceValueChart from './9HundredthsOnAPlaceValueChart';
import DivideA1Or2DigitNumberBy100 from './10DivideA1Or2DigitNumberBy100';

const Block = newBlockContent({
  block: 'DecimalsA',
  field: 'Number',
  weeks: [10, 12],
  smallSteps: [
    TenthsAsFractions, // Step 1
    TenthsAsDecimals, // Step 2
    TenthsOnAPlaceValueChart, // Step 3
    TenthsOnANumberLine, // Step 4
    DivideA1DigitNumberBy10, // Step 5
    DivideA2DigitNumberBy10, // Step 6
    HundredthsAsFractions, // Step 7
    HundredthsAsDecimals, // Step 8
    HundredthsOnAPlaceValueChart, // Step 9
    DivideA1Or2DigitNumberBy100 // Step 10
  ]
});
export default Block;
