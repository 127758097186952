import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { countRange } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { LinesOfSymmetry } from '../../../../components/question/representations/LinesOfSymmetry';
import { numberEnum } from '../../../../utils/zod';
import { AssetSvg } from '../../../../assets/svg';
import { View } from 'react-native';
import {
  equalHalfShadedShapesSvgNameSchema,
  unequalHalfShadedShapesSvgNameSchema,
  getRandomUnequalHalfShadedShapesSvgName,
  getRandomEqualHalfShadedShapesSvgName,
  equalHalfShadedShapes,
  unequalHalfShadedShapes
} from '../../../../utils/shapes';

////
// Questions
////

const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';
const DIAGONAL = 'diagonal';
type HORIZONTAL = typeof HORIZONTAL;
type VERTICAL = typeof VERTICAL;
type DIAGONAL = typeof DIAGONAL;

const Question1 = newQuestionContent({
  uid: 'bek',
  description: 'bek',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z.object({
    options: z
      .array(
        z.object({
          /** 1 indicates an object split in half. */
          offset: z.number(),
          lineOfSymmetry: z.enum([HORIZONTAL, VERTICAL, DIAGONAL])
        })
      )
      .length(4),
    object: z.enum(['chocolateBar', 'pizza'])
  }),
  simpleGenerator: () => {
    const offsets = randomUniqueIntegersInclusive(4, 8, 3).map(it => it / 10);

    const object = getRandomFromArray(['chocolateBar', 'pizza'] as const);
    const numberOfObjectsHalved = getRandomFromArray([1, 2, 3] as const);

    const waysToHalf = getRandomSubArrayFromArray(
      [HORIZONTAL, VERTICAL, DIAGONAL] as const,
      numberOfObjectsHalved
    );

    const correctOptions = waysToHalf.map(symmetry => {
      return { lineOfSymmetry: symmetry, offset: 1 };
    });

    const incorrectOptions = countRange(4 - numberOfObjectsHalved).map((_, index) => {
      return {
        lineOfSymmetry: getRandomFromArray([HORIZONTAL, VERTICAL, DIAGONAL] as const),
        offset: offsets[index]
      };
    });

    const options = shuffle([...correctOptions, ...incorrectOptions]);

    return { object, options };
  },
  Component: props => {
    const {
      question: { object, options },
      translate
    } = props;

    const objectsHalved = options.filter(option => option.offset === 1);
    const numberOfObjectsHalved = objectsHalved.length;

    const objectTranslated = (() => {
      switch (object) {
        case 'chocolateBar':
          return numberOfObjectsHalved === 1
            ? translate.objects.ChocolateBar()
            : translate.objects.ChocolateBars();
        case 'pizza':
          return numberOfObjectsHalved === 1
            ? translate.objects.Pizza()
            : translate.objects.Pizzas();
      }
    })();

    const title =
      numberOfObjectsHalved > 1
        ? translate.ks1Instructions.selectTheXThatAreCutInHalf(objectTranslated)
        : translate.ks1Instructions.selectTheXThatIsCutInHalf(objectTranslated);

    const pdfTitle =
      numberOfObjectsHalved > 1
        ? translate.ks1PDFInstructions.circleTheXThatAreCutInHalf(objectTranslated)
        : translate.ks1PDFInstructions.circleTheXThatIsCutInHalf(objectTranslated);

    return (
      <QF11SelectImagesUpTo4<{
        lineOfSymmetry: 'vertical' | 'horizontal' | 'diagonal';
        offset: number;
      }>
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={objectsHalved}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map(option => ({
            value: option,
            component: (
              <LinesOfSymmetry
                shape={object}
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                linesOfSymmetry={[option.lineOfSymmetry]}
                offset={option.offset}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bel',
  description: 'bel',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z.object({
    options: z
      .array(
        z.object({
          offset: z.number(),
          lineOfSymmetry: z.enum([HORIZONTAL, VERTICAL, DIAGONAL])
        })
      )
      .length(4),
    object: z.enum(['circle', 'square'])
  }),
  simpleGenerator: () => {
    const offset1 = randomIntegerInclusive(3, 8, { constraint: x => x !== 0 }) / 10;

    const offset2 =
      randomIntegerInclusive(3, 8, {
        constraint: x => x !== 0 && x / 10 !== offset1
      }) / 10;

    const offset3 =
      randomIntegerInclusive(3, 8, {
        constraint: x => x !== 0 && x / 10 !== offset1 && x / 10 !== offset2
      }) / 10;

    const object = getRandomFromArray(['circle', 'square'] as const);
    const numberOfObjectsHalved = getRandomFromArray([1, 2, 3] as const);

    const waysToHalf = getRandomSubArrayFromArray(
      [HORIZONTAL, VERTICAL, DIAGONAL] as const,
      numberOfObjectsHalved
    );

    const correctOptions = waysToHalf.map(symmetry => {
      return { lineOfSymmetry: symmetry, offset: 1 };
    });

    const incorrectOptions = [
      {
        lineOfSymmetry: HORIZONTAL,
        offset: offset1
      } as const,
      {
        lineOfSymmetry: VERTICAL,
        offset: offset2
      } as const,
      {
        lineOfSymmetry: DIAGONAL,
        offset: offset3
      } as const
    ];

    incorrectOptions.length = 4 - numberOfObjectsHalved;

    const options = shuffle([...correctOptions, ...incorrectOptions]);

    return { object, options };
  },
  Component: props => {
    const {
      question: { object, options },
      translate
    } = props;

    const objectsHalved = options.filter(option => option.offset === 1);
    const numberOfObjectsHalved = objectsHalved.length;

    const objectTranslated =
      object === 'circle'
        ? translate.shapes.circles(numberOfObjectsHalved)
        : translate.shapes.squares(numberOfObjectsHalved);

    const title =
      numberOfObjectsHalved > 1
        ? translate.ks1Instructions.selectTheXThatAreCutInHalf(objectTranslated)
        : translate.ks1Instructions.selectTheXThatIsCutInHalf(objectTranslated);

    const pdfTitle =
      numberOfObjectsHalved > 1
        ? translate.ks1PDFInstructions.circleTheXThatAreCutInHalf(objectTranslated)
        : translate.ks1PDFInstructions.circleTheXThatIsCutInHalf(objectTranslated);

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={objectsHalved}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map(option => ({
            value: option,
            component: (
              <LinesOfSymmetry
                shape={object}
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                linesOfSymmetry={[option.lineOfSymmetry]}
                offset={option.offset}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bem',
  description: 'bem',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z.object({
    options: z
      .array(
        z.object({
          shape: z.union([
            equalHalfShadedShapesSvgNameSchema,
            unequalHalfShadedShapesSvgNameSchema
          ]),
          isEqual: z.boolean(),
          rotation: numberEnum([0, 90, 180, 270])
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const numberOfCorrectAnswers = getRandomFromArray([1, 2, 3]);

    const correctShapeSvgs = getRandomSubArrayFromArray(
      equalHalfShadedShapes,
      numberOfCorrectAnswers
    ).map(shape => {
      return {
        shape: getRandomEqualHalfShadedShapesSvgName(shape),
        isEqual: true,
        rotation: shape === 'Rectangle' ? 0 : getRandomFromArray([0, 90, 180, 270] as const)
      };
    });

    const incorrectShapesSvgs = getRandomSubArrayFromArray(
      unequalHalfShadedShapes,
      4 - numberOfCorrectAnswers
    ).map(shape => {
      return {
        shape: getRandomUnequalHalfShadedShapesSvgName(shape),
        isEqual: false,
        rotation: shape === 'Rectangle' ? 0 : getRandomFromArray([0, 90, 180, 270] as const)
      };
    });

    const options = shuffle([...correctShapeSvgs, ...incorrectShapesSvgs]);

    return { options };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    const numberOfCorrectAnswers = options.filter(it => it.isEqual).length;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheDiagramsThatShowAHalf(numberOfCorrectAnswers)}
        pdfTitle={translate.ks1PDFInstructions.circleTheDiagramsThatShowAHalf(
          numberOfCorrectAnswers
        )}
        testCorrect={options.flatMap((el, index) => (el.isEqual ? [index] : []))}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map((option, index) => ({
            value: index,
            component: (
              <View style={{ transform: `rotate(${option.rotation}deg)` }}>
                <AssetSvg height={dimens.height * 0.7} name={option.shape} />
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAHalfOfAnObjectOrAShape',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
