import { useContext } from 'react';
import { ALGEBRAIC_X, ALGEBRAIC_Y } from '../../../constants';
import { DisplayMode } from '../../../contexts/displayMode';
import { View } from 'react-native';
import { colors } from '../../../theme/colors';
import { isEqual } from '../../../utils/matchers';
import { MeasureView } from '../../atoms/MeasureView';
import BaseLayout from '../../molecules/BaseLayout';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import TableWithLeftHeaders from '../representations/TableWithLeftHeaders';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import MultiBarChart, {
  MultiBarChartWithState
} from '../representations/Coordinates/MultiBarChart';
import Table from '../../molecules/Table';
import TextStructure from '../../molecules/TextStructure';
import { Key } from '../representations/Coordinates/LineGraph';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle: string;
  correctAnswer: { option: number; values: number[] }[];
  /** Default: one bar per option with value 0. */
  initialState?: { option: number; values: number[] }[];
  /**
   * The label for each bar. Must be the same length as `bars`.
   *
   * Also defines how many bars there are.
   */
  barLabels: string[];
  /**
   * The color that each bar should be.
   *
   * Must be the same length as `barLabels`.
   */
  barColors: string[];
  /** Which bars are interactive. Default: all of them */
  interactiveIndices?: number[];
  /**
   * Whether items should snap to the nearest multiple of some number. 'grid' means snap to nearest grid point.
   * Default: no snapping
   */
  snapToNearest?: number | 'grid';
  /** (PDF-only) how high is the overall question. Must agree with question type definition. */
  questionHeight?: number;
  keyLabel: string;
  /* When this is set a key will be displayed */
  keyValues?: { label: string; color: string }[];

  ////
  // Grid props
  ////
  /** Distance between two grid lines, in math coordinates. Default: 1. */
  yStepSize?: number;
  /** Value of last point on x axis. */
  yMax: number;
  /** Null for no label. Default: '𝑦'. */
  yAxisArrowLabel?: string | null;
  /** Null for no label. Default: null. */
  xAxisLabel?: string | null;
  /** Null for no label. Default: null. */
  yAxisLabel?: string | null;
  /** Null for no labels. Default: numbers yMin to yMax. */
  yLabels?: string[] | null;
  /** If provided, y-axis labels are given with this fixed number of decimal places. */
  yDecimalPlaces?: number;
};

/**
 * Question Format 62b: Draw Multi Bar Charts
 *
 * Draggable bar charts.
 * Can be used for single bars or more. Should be used going forward instead of QF62
 */
export default function QF62bDrawMultiBarCharts({
  title,
  pdfTitle,
  correctAnswer,
  initialState,
  barLabels,
  barColors,
  interactiveIndices = [...correctAnswer.keys()],
  snapToNearest,
  questionHeight,
  keyLabel,
  keyValues,
  ...barChartProps
}: Props) {
  const displayMode = useContext(DisplayMode);

  const maxBarsPerOption = Math.max(...correctAnswer.map(val => val.values.length));

  let table;
  if (maxBarsPerOption > 1 && keyValues) {
    const tableRows = [
      [keyLabel, ...barLabels],
      ...keyValues.map(({ label }, i) => [
        label,
        ...correctAnswer.map(x => x.values[i].toLocaleString())
      ])
    ];

    table = (
      <Table
        items={tableRows.map((row, rowIndex) =>
          row.map((cell, cellIndex) => (
            <View
              key={`${rowIndex}-${cellIndex}`}
              style={{
                backgroundColor: rowIndex === 0 ? colors.tableHeaderBackground : undefined,
                width: displayMode === 'digital' ? 200 : 450,
                height: displayMode === 'digital' ? 40 : 60,
                justifyContent: 'center'
              }}
            >
              <TextStructure
                textVariant={'WRN400'}
                textStyle={{
                  fontSize: displayMode === 'digital' ? 26 : 50,
                  lineHeight: 30,
                  textAlign: 'center',
                  color: rowIndex === 0 ? colors.white : colors.black
                }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 26 : 50,
                  fontWeight: '400',
                  color: rowIndex === 0 ? colors.white : colors.black
                }}
                fractionDividerStyle={{ marginVertical: 2 }}
                sentence={cell}
              />
            </View>
          ))
        )}
      />
    );
  } else {
    table = (
      <TableWithLeftHeaders
        headers={[barChartProps.xAxisLabel ?? ALGEBRAIC_X, barChartProps.yAxisLabel ?? ALGEBRAIC_Y]}
        items={[barLabels, correctAnswer.map(it => it.toLocaleString())]}
        style={{ width: '80%', alignSelf: 'center', marginBottom: 20 }}
        headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
        textStyle={displayMode === 'digital' && { fontSize: 21.667, lineHeight: 35 }}
      />
    );
  }

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            {table}
            {keyValues && (
              <Key
                colors={keyValues.map(val => val.color)}
                labels={keyValues.map(val => val.label)}
                style={{ alignSelf: 'flex-end', marginBottom: -15 }}
              />
            )}
            <MeasureView>
              {dimens => (
                <MultiBarChart
                  barValues={
                    displayMode === 'markscheme'
                      ? correctAnswer
                      : initialState ?? barLabels.map((_val, i) => ({ option: i, values: [0] }))
                  }
                  width={dimens.width}
                  height={dimens.height}
                  barLabels={barLabels}
                  barColors={barColors}
                  {...barChartProps}
                />
              )}
            </MeasureView>
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <>
          {table}
          {keyValues && (
            <Key
              colors={keyValues.map(val => val.color)}
              labels={keyValues.map(val => val.label)}
              style={{ alignSelf: 'flex-end', marginBottom: -15 }}
            />
          )}
          <MeasureView>
            {dimens => (
              <MultiBarChartWithState
                id="barchart"
                defaultState={initialState}
                testCorrect={isEqual(correctAnswer)}
                width={dimens.width}
                height={dimens.height}
                barLabels={barLabels}
                barColors={barColors}
                interactive={interactiveIndices}
                snapToNearest={snapToNearest}
                {...barChartProps}
              />
            )}
          </MeasureView>
        </>
      }
    />
  );
}
