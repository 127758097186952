// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import UnderstandTheWhole from './1UnderstandTheWhole';
import CountBeyond1 from './2CountBeyond1';
import PartitionAMixedNumber from './3PartitionAMixedNumber';
import NumberLinesWithMixedNumbers from './4NumberLinesWithMixedNumbers';
import CompareAndOrderMixedNumbers from './5CompareAndOrderMixedNumbers';
import UnderstandImproperFractions from './6UnderstandImproperFractions';
import ConvertMixedNumbersToImproperFractions from './7ConvertMixedNumbersToImproperFractions';
import ConvertImproperFractionsToMixedNumbers from './8ConvertImproperFractionsToMixedNumbers';
import EquivalentFractionsOnANumberLine from './9EquivalentFractionsOnANumberLine';
import EquivalentFractionFamilies from './10EquivalentFractionFamilies';
import AddTwoOrMoreFractions from './11AddTwoOrMoreFractions';
import AddFractionsAndMixedNumbers from './12AddFractionsAndMixedNumbers';
import SubtractTwoFractions from './13SubtractTwoFractions';
import SubtractFromWholeAmounts from './14SubtractFromWholeAmounts';
import SubtractFromMixedNumbers from './15SubtractFromMixedNumbers';

const Block = newBlockContent({
  block: 'Fractions',
  field: 'Number',
  weeks: [6, 9],
  smallSteps: [
    UnderstandTheWhole, // Step 1
    CountBeyond1, // Step 2
    PartitionAMixedNumber, // Step 3
    NumberLinesWithMixedNumbers, // Step 4
    CompareAndOrderMixedNumbers, // Step 5
    UnderstandImproperFractions, // Step 6
    ConvertMixedNumbersToImproperFractions, // Step 7
    ConvertImproperFractionsToMixedNumbers, // Step 8
    EquivalentFractionsOnANumberLine, // Step 9
    EquivalentFractionFamilies, // Step 10
    AddTwoOrMoreFractions, // Step 11
    AddFractionsAndMixedNumbers, // Step 12
    SubtractTwoFractions, // Step 13
    SubtractFromWholeAmounts, // Step 14
    SubtractFromMixedNumbers // Step 15
  ]
});
export default Block;
