import { z } from 'zod';

import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF29CreateArray from '../../../../components/question/questionFormats/QF29CreateArray';
import { MULT } from '../../../../constants';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { arraysHaveSameContentsUnordered, filledArray } from '../../../../utils/collections';
import { findFactors, findFactorsExcludingSelfAnd1 } from '../../../../utils/factors';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { chunk } from '../../../../utils/chunk';
import { FactorBugWithState } from '../../../../components/question/representations/FactorBug';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import TextStructure from '../../../../components/molecules/TextStructure';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJM',
  description: 'aJM',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    number1: z.number().int().min(1).max(6),
    number2: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 6);
    const number2 = randomIntegerInclusive(1, 10);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const multiplicationSentence = `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()}`;

    return (
      <QF29CreateArray
        numberOfRows={6}
        numberOfCols={10}
        title={translate.instructions.tapCountersToCreateArrayToShow(multiplicationSentence)}
        pdfTitle={translate.instructions.createArrayToShow(multiplicationSentence)}
        testCorrect={[number1, number2]}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.arrayDimensCanBeFlipped() }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aJN',
  description: 'aJN',
  keywords: ['Multiply', 'Array'],
  schema: z.object({
    number1: z.number().int().min(1).max(6),
    number2: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 6);
    const number2 = randomIntegerInclusive(1, 12);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const answer = number(math.evaluate(`${number1} * ${number2}`));

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = ${answer.toLocaleString()}`}
        title={translate.instructions.whatCalculationIsRepresentedByArray()}
        testCorrect={answer =>
          arraysHaveSameContentsUnordered(answer, [number1.toString(), number2.toString()])
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <ArrayOfObjects rows={number1} columns={number2} dimens={dimens} />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [number1.toLocaleString(), number2.toLocaleString()],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aJO',
  description: 'aJO',
  keywords: ['Multiplication', 'Factors', 'Division'],
  questionHeight: 1200,
  schema: z.object({
    number1: z.number().int().min(12).max(96),
    givenFactors: z.array(z.number().int().min(0).max(5)).length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(12, 96, {
      constraint: x => findFactors(x).length === 6
    });
    const factor1 = getRandomFromArray([0, 5] as const);
    const givenFactors = [factor1, 4];
    return { number1, givenFactors };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, givenFactors } = question;
    const numFactors = findFactors(number1);
    const factorsArray = filledArray('<ans/>', 6);

    givenFactors.map(i => (factorsArray[i] = numFactors[i].toLocaleString()));

    const answerArray = findFactors(number1);
    // remove the second biggest first as the first and last will never change position
    if (givenFactors[0] === 5) {
      answerArray.splice(4, 2);
    } else {
      answerArray.splice(4, 1);
      answerArray.splice(0, 1);
    }

    const stringArray = answerArray.map(ans => ans.toString());

    return (
      <QF3Content
        title={translate.instructions.completeFactorBug()}
        inputType="numpad"
        questionHeight={1200}
        Content={({ dimens }) => (
          <FactorBugWithState
            id={'bug1'}
            centerNumber={number1.toLocaleString()}
            factors={factorsArray}
            dimens={dimens}
            testCorrect={answer => arraysHaveSameContentsUnordered(answer, stringArray)}
            defaultState={displayMode === 'markscheme' ? stringArray : undefined}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aJP',
  description: 'aJP',
  keywords: ['Factors'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(10)
        .max(99)
        .refine(x => findFactors(x).length >= 4),
      number2: z.number().int().min(1).max(99),
      number3: z.number().int().min(1).max(99),
      number4: z.number().int().min(1).max(99),
      number5: z.number().int().min(1).max(99),
      number6: z.number().int().min(2).max(24),
      number7: z.number().int().min(2).max(24),
      number8: z.number().int().min(2).max(24)
    })
    .refine(
      x =>
        x.number1 % x.number2 === 0 &&
        x.number1 % x.number3 === 0 &&
        x.number1 % x.number4 === 0 &&
        x.number1 % x.number5 === 0,
      'number2, number3, number4 and number5 should be factors of number1'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 99, { constraint: x => findFactors(x).length >= 4 });
    const [number2, number3, number4, number5] = getRandomSubArrayFromArray(
      findFactors(number1),
      4
    );
    const [number6, number7, number8] = randomUniqueIntegersInclusive(2, 24, 3, {
      constraint: x =>
        x !== number1 &&
        x !== number2 &&
        x !== number3 &&
        x !== number4 &&
        x !== number5 &&
        x !== number1 * 2
    });

    return { number1, number2, number3, number4, number5, number6, number7, number8 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6, number7, number8 },
      translate
    } = props;

    const number9 = number1 * 2;
    const items = shuffle(
      [number2, number3, number4, number5, number6, number7, number8, number9],
      { random: seededRandom(props.question) }
    );
    return (
      <QF10SelectNumbers
        title={translate.instructions.selectFactorsOfX(number1.toLocaleString())}
        pdfTitle={translate.instructions.circleFactorsOfX(number1.toLocaleString())}
        multiSelect
        testCorrect={items.filter(i => number1 % i === 0)}
        items={items.map(item => ({
          value: item,
          component: item.toLocaleString()
        }))}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aJQ',
  description: 'aJQ',
  keywords: ['Factors'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(10)
      .max(60)
      .refine(x => findFactors(x).length >= 4),
    allFactors: z.array(z.number().int().min(1).max(60)),
    factorsWithoutOneAndSelf: z.array(z.number().int().min(2).max(60)),
    twoWrong: z.array(z.number().int().min(1).max(60)),
    timesTwo: z.array(z.number().int().min(1).max(120))
  }),

  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 60, { constraint: x => findFactors(x).length >= 4 });
    const allFactors = findFactors(number1);
    const factorsWithoutOneAndSelf = findFactorsExcludingSelfAnd1(number1);
    const randomIncorrects = randomUniqueIntegersInclusive(1, 60, 2, {
      constraint: x => number1 % x !== 0
    });
    const twoWrong = [...findFactorsExcludingSelfAnd1(number1), ...randomIncorrects].sort(
      (a, b) => a - b
    );
    const timesTwo = findFactors(number1);
    timesTwo[0] = number1 * 2;

    const orderedTimesTwo = timesTwo.sort((a, b) => a - b);

    return { number1, allFactors, factorsWithoutOneAndSelf, twoWrong, timesTwo: orderedTimesTwo };
  },
  Component: props => {
    const {
      question: { number1, allFactors, factorsWithoutOneAndSelf, twoWrong, timesTwo },
      translate,
      displayMode
    } = props;

    const options = shuffle([allFactors, factorsWithoutOneAndSelf, twoWrong, timesTwo], {
      random: seededRandom(props.question)
    });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectOptionThatShowsAllFactorsOfX(number1.toLocaleString())}
        pdfTitle={translate.instructions.circleOptionThatShowsAllFactorsOfX(
          number1.toLocaleString()
        )}
        testCorrect={[allFactors]}
        items={options.map(factors => ({
          value: factors,
          component: (
            // If there are more than six factors, split these factors across two lines evenly.
            // If there are an odd number of factors, the top line should have one more than the bottom line.
            // Two separate TextStructures are needed for this to display properly on mobile - using <br/> on mobile
            // in items causes the second line to disappear.
            <>
              <TextStructure
                textVariant="WRN700"
                textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50, textAlign: 'center' }}
                sentence={
                  factors.length > 6
                    ? factors
                        .slice(0, Math.ceil(factors.length / 2))
                        .map(it => it.toLocaleString())
                        .join(', ')
                    : factors.map(it => it.toLocaleString()).join(', ')
                }
              />
              {factors.length > 6 && (
                <TextStructure
                  textVariant="WRN700"
                  textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50, textAlign: 'center' }}
                  sentence={factors
                    .slice(Math.ceil(factors.length / 2))
                    .map(it => it.toLocaleString())
                    .join(', ')}
                />
              )}
            </>
          )
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aJR',
  description: 'aJR',
  keywords: ['Factors'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(10)
      .max(99)
      .refine(x => findFactors(x).length >= 4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 99, {
      constraint: x => findFactors(x).length >= 4
    });

    return { number1 };
  },

  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const factors = findFactors(number1);
    const sentences = chunk(filledArray('<ans/>', factors.length), 6);
    const answers = chunk(
      factors.map(i => i.toString()),
      6
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.findTheFactorsOfNum(number1.toLocaleString())}
        testCorrect={userAnswers => {
          if (userAnswers[1]) {
            return (
              arraysHaveSameContentsUnordered(userAnswers[0], answers[0]) &&
              arraysHaveSameContentsUnordered(userAnswers[1], answers[1])
            );
          }
          return arraysHaveSameContentsUnordered(userAnswers[0], answers[0]);
        }}
        sentences={sentences.map(i => i.join(' '))}
        inputMaxCharacters={3}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyOfTheFollowingXYZ({
            acceptableAnswers: factors.map(num => num.toLocaleString())
          })
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aJR2',
  description: 'aJR2',
  keywords: ['Factors'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(10)
      .max(99)
      .refine(x => findFactors(x).length >= 4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 99, {
      constraint: x => findFactors(x).length >= 4
    });

    return { number1 };
  },

  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const factors = findFactors(number1);

    const sentences =
      factors.length > 6
        ? chunk(filledArray('<ans/>', factors.length), Math.ceil(factors.length / 2))
        : chunk(filledArray('<ans/>', factors.length), 6);

    const answers =
      factors.length > 6
        ? chunk(
            factors.map(i => i.toString()),
            Math.ceil(factors.length / 2)
          )
        : chunk(
            factors.map(i => i.toString()),
            6
          );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.listTheFactorsOfNum(number1)}
        testCorrect={userAnswers => {
          if (userAnswers[1]) {
            return arraysHaveSameContentsUnordered(
              [...userAnswers[0], ...userAnswers[1]],
              [...answers[0], ...answers[1]]
            );
          }
          return arraysHaveSameContentsUnordered(userAnswers[0], answers[0]);
        }}
        sentences={sentences.map(i => i.join(' '))}
        sentenceStyle={{ alignSelf: 'center' }}
        inputMaxCharacters={3}
        customMarkSchemeAnswer={{
          answersToDisplay: answers,
          answerText: translate.markScheme.answersInAnyOrder()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FactorPairs',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
