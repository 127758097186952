import { View } from 'react-native';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { PieChartColors, barModelColors, colors } from '../../../../theme/colors';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  filledArray
} from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { compareFractions, simplify } from '../../../../utils/fractions';
import { ADD } from '../../../../constants';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import PieChart from '../../../../components/question/representations/PieChart';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMi',
  description: 'aMi',
  keywords: ['Bar model', 'Mixed number', 'Fraction', 'Whole', 'Parts', 'Numerator', 'Denominator'],
  schema: z.object({
    whole: z.number().int().min(1).max(4),
    denominator: z.number().int().min(2).max(6),
    numerator: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 4);
    const denominator = randomIntegerInclusive(2, whole > 2 ? 5 : 6);
    const numerator = randomIntegerInclusive(1, denominator - 1);

    return { denominator, whole, numerator };
  },
  Component: props => {
    const {
      question: { denominator, whole, numerator },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barModelArrays = [
      ...countRange(whole).map(() => filledArray(numeratorColor, denominator)),
      countRange(denominator).map(i => (i < numerator ? numeratorColor : 'white'))
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatMixedNumberIsShown()}
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              height: dimens.height,
              flexWrap: 'wrap',
              flexDirection: whole > 2 ? 'row' : 'column',
              justifyContent: 'space-evenly',
              rowGap: 5
            }}
          >
            {barModelArrays.map((array, idx) => (
              <View
                key={idx}
                style={{
                  width: whole > 2 ? dimens.width * 0.4 : dimens.width,
                  height:
                    whole > 2
                      ? dimens.height * (0.8 / (whole - 1))
                      : dimens.height / (barModelArrays.length + 1)
                }}
              >
                <ShadedFractionBarModel
                  totalSubSections={denominator}
                  width={whole > 2 ? dimens.width * 0.4 : dimens.width}
                  height={
                    whole > 2
                      ? dimens.height * (0.8 / (whole - 1))
                      : dimens.height / (barModelArrays.length + 1)
                  }
                  customColorMap={array}
                />
              </View>
            ))}
          </View>
        )}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={`<frac wAns='' nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole.toLocaleString(),
            numerator.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        testCorrect={answer =>
          compareFractions([answer[0], answer[1], answer[2]], [whole, numerator, denominator])
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aMj',
  description: 'aMj',
  keywords: [
    'Bar model',
    'Mixed number',
    'Fraction',
    'Whole',
    'Parts',
    'Numerator',
    'Denominator',
    'Partition'
  ],
  schema: z.object({
    whole: z.number().int().min(1).max(3),
    denominator: z.number().int().min(2).max(6),
    numerator: z.number().int().min(1).max(5),
    fromLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 3);
    const denominator = randomIntegerInclusive(2, whole === 3 ? 5 : 6);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const fromLeft = whole === 3 ? true : getRandomBoolean();

    return { denominator, whole, numerator, fromLeft };
  },
  Component: props => {
    const {
      question: { denominator, whole, numerator, fromLeft },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const finalRow = countRange(denominator).map(i => (i < numerator ? numeratorColor : 'white'));

    const barModelArrays = [
      ...countRange(whole).map(() => filledArray(numeratorColor, denominator)),
      fromLeft ? finalRow : finalRow.reverse()
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              height: dimens.height,
              flexWrap: 'wrap',
              flexDirection: whole === 3 ? 'row' : 'column',
              justifyContent: 'space-evenly',
              rowGap: 5
            }}
          >
            {barModelArrays.map((array, idx) => (
              <View
                key={idx}
                style={{
                  width: whole === 3 ? dimens.width * 0.4 : dimens.width,
                  height:
                    whole > 2
                      ? dimens.height * (0.8 / (whole - 1))
                      : dimens.height / (barModelArrays.length + 1)
                }}
              >
                <ShadedFractionBarModel
                  totalSubSections={denominator}
                  width={whole === 3 ? dimens.width * 0.4 : dimens.width}
                  height={
                    whole === 3 ? dimens.height * 0.4 : dimens.height / (barModelArrays.length + 1)
                  }
                  customColorMap={array}
                />
              </View>
            ))}
          </View>
        )}
        sentence={`<frac w='${whole.toLocaleString()}' n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' /> = <ans/> ${translate.fractions.wholes(
          whole
        )} ${ADD} <frac nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole.toLocaleString(),
            numerator.toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        testCorrect={answer =>
          compareFractions([answer[0], answer[1], answer[2]], [whole, numerator, denominator])
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aMk',
  description: 'aMk',
  keywords: [
    'Bar model',
    'Mixed number',
    'Fraction',
    'Whole',
    'Parts',
    'Numerator',
    'Denominator',
    'Partition'
  ],
  schema: z.object({
    whole: z.number().int().min(1).max(4),
    denominator: z.number().int().min(2).max(6),
    numerator: z.number().int().min(1).max(5),
    incorrectOptionIndexes: z.array(z.number().int().min(0).max(4)).length(3)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 6);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    // unqiue for selectable options
    const whole = randomIntegerInclusive(1, 4, {
      constraint: x => x !== numerator && x !== denominator
    });
    const incorrectOptionIndexes = randomUniqueIntegersInclusive(0, 4, 3);

    return { whole, denominator, numerator, incorrectOptionIndexes };
  },
  Component: props => {
    const {
      question: { whole, denominator, numerator, incorrectOptionIndexes },
      translate,
      displayMode
    } = props;

    const numeratorColor =
      displayMode === 'digital'
        ? (getRandomFromArray(Object.values(barModelColors), {
            random: seededRandom(props.question)
          }) as string)
        : colors.pdfShading;

    const barModelArrays = [
      ...countRange(whole).map(() => filledArray(numeratorColor, denominator)),
      countRange(denominator).map(i => (i < numerator ? numeratorColor : 'white'))
    ];

    const incorrectOptions = [
      [numerator, denominator, whole],
      [whole, denominator, numerator],
      [whole, denominator, whole],
      [numerator, denominator, numerator],
      [whole * denominator, denominator, numerator]
    ].filter((_x, idx) => incorrectOptionIndexes.includes(idx));

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheMixedNumberThatIsShownByTheBarModel()}
        pdfTitle={translate.instructions.circleTheMixedNumberThatIsShownByTheBarModel()}
        testCorrect={['A']}
        numItems={4}
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              height: dimens.height,
              flexWrap: 'wrap',
              flexDirection: whole > 2 ? 'row' : 'column',
              justifyContent: 'space-evenly',
              rowGap: 5
            }}
          >
            {barModelArrays.map((array, idx) => (
              <View
                key={idx}
                style={{
                  width: whole > 2 ? dimens.width * 0.4 : dimens.width,
                  height:
                    whole > 2
                      ? dimens.height * (0.8 / (whole - 1))
                      : dimens.height / (barModelArrays.length + 1)
                }}
              >
                <ShadedFractionBarModel
                  totalSubSections={denominator}
                  width={whole > 2 ? dimens.width * 0.4 : dimens.width}
                  height={
                    whole > 2
                      ? dimens.height * (0.8 / (whole - 1))
                      : dimens.height / (barModelArrays.length + 1)
                  }
                  customColorMap={array}
                />
              </View>
            ))}
          </View>
        )}
        renderItems={shuffle(
          [
            {
              component: (
                <TextStructure
                  fractionTextStyle={{ fontWeight: '700' }}
                  fractionDividerStyle={{ marginVertical: 2 }}
                  sentence={`<frac w='${whole.toLocaleString()}' n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`}
                />
              ),
              value: 'A'
            },
            ...incorrectOptions.map((x, idx) => {
              return {
                component: (
                  <TextStructure
                    fractionTextStyle={{ fontWeight: '700' }}
                    fractionDividerStyle={{ marginVertical: 2 }}
                    sentence={`<frac w='${x[0]}' n='${x[1]}' d='${x[2]}' />`}
                  />
                ),
                value: `${idx}`
              };
            })
          ],
          { random: seededRandom(props.question) }
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aMl',
  description: 'aMl',
  keywords: [
    'Addition',
    'Mixed number',
    'Fraction',
    'Whole',
    'Parts',
    'Numerator',
    'Denominator',
    'Partition'
  ],
  questionHeight: 850,
  schema: z.object({
    whole1: z.number().int().min(1).max(9),
    denominator1: z.number().int().min(4).max(11),
    numerator1: z.number().int().min(3).max(9),
    whole2: z.number().int().min(1).max(9),
    denominator2: z.number().int().min(2).max(11),
    numerator2: z.number().int().min(1).max(9),
    isLeft: z.array(z.boolean()).length(2)
  }),
  simpleGenerator: () => {
    const [whole1, whole2] = randomUniqueIntegersInclusive(1, 9, 2);
    const numerator1 = randomIntegerInclusive(3, 9);
    const numerator2 = randomIntegerInclusive(1, 9, { constraint: x => x !== numerator1 });
    const denominator1 = randomIntegerInclusive(numerator1 + 1, 11);
    const denominator2 = randomIntegerInclusive(numerator2 + 1, 11, {
      constraint: x => x !== denominator1
    });
    const isLeft = countRange(2).map(getRandomBoolean);

    return {
      whole1,
      whole2,
      denominator1,
      denominator2,
      numerator1,
      numerator2,
      isLeft
    };
  },
  Component: props => {
    const {
      question: { whole1, whole2, denominator1, denominator2, numerator1, numerator2, isLeft },
      translate
    } = props;
    // Sentences
    const sentences = [
      isLeft[0]
        ? `${whole1} ${ADD} <frac nAns='' dAns='' /> = <frac w='${whole1.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}' />`
        : `<frac w='${whole1.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}' /> = ${whole1} ${ADD} <frac nAns='' dAns='' />`,
      isLeft[1]
        ? `<ans/> ${ADD} <frac n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}' /> = <frac w='${whole2.toLocaleString()}' n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}' />`
        : `<frac w='${whole2.toLocaleString()}' n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}' /> = <ans/> ${ADD} <frac n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}' />`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        questionHeight={850}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], [numerator1, denominator1]) &&
          userAnswer[1][0] === whole2.toString()
        }
        sentences={sentences}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numerator1.toLocaleString(), denominator1.toLocaleString()],
            [whole2.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aMl2',
  description: 'aMl',
  keywords: [
    'Addition',
    'Mixed number',
    'Fraction',
    'Whole',
    'Parts',
    'Numerator',
    'Denominator',
    'Partition'
  ],
  questionHeight: 850,
  schema: z.object({
    whole: z.number().int().min(1).max(9),
    denominator: z.number().int().min(2).max(11),
    numerator: z.number().int().min(1).max(9),
    isLeft: z.array(z.boolean()).length(2),
    randomSet: z.boolean()
  }),
  simpleGenerator: () => {
    const randomSet = getRandomBoolean();
    const whole = randomIntegerInclusive(1, 9);
    const numerator = randomSet ? randomIntegerInclusive(3, 9) : randomIntegerInclusive(1, 9);
    const denominator = randomIntegerInclusive(numerator + 1, 11);
    const isLeft = countRange(2).map(getRandomBoolean);

    return {
      whole,
      denominator,
      numerator,
      isLeft,
      randomSet
    };
  },
  Component: props => {
    const {
      question: { whole, denominator, numerator, isLeft, randomSet },
      translate,
      displayMode
    } = props;

    // Sentence
    const sentence = randomSet
      ? isLeft[0]
        ? `${whole} ${ADD} <frac nAns='' dAns='' /> = <frac w='${whole.toLocaleString()}' n='${numerator}' d='${denominator}' />`
        : `<frac w='${whole.toLocaleString()}' n='${numerator}' d='${denominator}' /> = ${whole} ${ADD} <frac nAns='' dAns='' />`
      : isLeft[1]
      ? `<ans/> ${ADD} <frac n='${numerator}' d='${denominator}' /> = <frac w='${whole.toLocaleString()}' n='${numerator}' d='${denominator}' />`
      : `<frac w='${whole.toLocaleString()}' n='${numerator}' d='${denominator}' /> = <ans/> ${ADD} <frac n='${numerator}' d='${denominator}' />`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        questionHeight={850}
        testCorrect={userAnswer =>
          randomSet
            ? compareFractions(userAnswer, [numerator, denominator])
            : userAnswer[0] === whole.toString()
        }
        sentence={sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: randomSet
            ? [numerator.toLocaleString(), denominator.toLocaleString()]
            : [whole.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aMm',
  description: 'aMm',
  keywords: [
    'Bar model',
    'Mixed number',
    'Fraction',
    'Whole',
    'Parts',
    'Numerator',
    'Denominator',
    'Addition',
    'Flexible partition'
  ],
  schema: z.object({
    whole1: z.number().int().min(1).max(4),
    whole2: z.number().int().min(0).max(4),
    denominator: z.number().int().min(2).max(6),
    numerator: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const whole1 = randomIntegerInclusive(1, 4);
    const whole2 = randomIntegerInclusive(0, 4);
    const denominator = randomIntegerInclusive(2, 6);
    const numerator = randomIntegerInclusive(1, denominator - 1);

    return { denominator, whole1, whole2, numerator };
  },
  Component: props => {
    const {
      question: { denominator, whole1, whole2, numerator },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barModelArray1 = countRange(whole1).map(() => filledArray(numeratorColor, denominator));

    const barModelArray2 = [
      ...countRange(whole2).map(() => filledArray(numeratorColor, denominator)),
      countRange(denominator).map(i => (i < numerator ? numeratorColor : 'white'))
    ];

    const longest = Math.max(barModelArray1.length + 1, barModelArray2.length + 1);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.fillInMixedNumbers()}
        Content={({ dimens }) => (
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: dimens.width }}
          >
            <View
              style={{
                justifyContent: 'space-evenly',
                height: dimens.height,
                width: dimens.width * 0.4,
                borderColor: colors.black,
                borderWidth: 1,
                borderRadius: 9
              }}
            >
              {barModelArray1.map((array, idx) => (
                <ShadedFractionBarModel
                  key={idx}
                  totalSubSections={denominator}
                  width={dimens.width * 0.3}
                  height={dimens.height / longest}
                  customColorMap={array}
                />
              ))}
            </View>
            <View
              style={{
                justifyContent: 'space-evenly',
                height: dimens.height,
                width: dimens.width * 0.4,
                borderColor: colors.black,
                borderWidth: 1,
                borderRadius: 9
              }}
            >
              {barModelArray2.map((array, idx) => (
                <ShadedFractionBarModel
                  key={idx}
                  totalSubSections={denominator}
                  width={dimens.width * 0.3}
                  height={dimens.height / longest}
                  customColorMap={array}
                />
              ))}
            </View>
          </View>
        )}
        sentence={
          whole2 === 0
            ? `<ans/> ${ADD} <frac nAns='' dAns='' />`
            : `<ans/> ${ADD} <frac wAns='' nAns='' dAns='' />`
        }
        customMarkSchemeAnswer={{
          answersToDisplay:
            whole2 !== 0
              ? [
                  whole1.toLocaleString(),
                  whole2.toLocaleString(),
                  numerator.toLocaleString(),
                  denominator.toLocaleString()
                ]
              : [whole1.toLocaleString(), numerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        testCorrect={answer =>
          compareFractions([answer[2], answer[3]], [numerator, denominator]) &&
          answer[0] === whole1.toString() &&
          whole2 !== 0
            ? true
            : answer[1] === whole2.toString()
        }
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aMm2',
  description: 'aMm',
  keywords: [
    'Mixed number',
    'Fraction',
    'Whole',
    'Parts',
    'Numerator',
    'Denominator',
    'Addition',
    'Flexible partition'
  ],
  schema: z
    .object({
      whole1: z.number().int().min(2).max(9),
      whole2: z.number().int().min(0).max(9),
      denominator: z.number().int().min(3).max(9),
      numerator1: z.number().int().min(1).max(8),
      numerator2: z.number().int().min(0).max(8),
      correctFractions: z.array(z.number()),
      incorrectFractions: z.array(z.array(z.number()))
    })
    .refine(val => val.numerator1 < val.denominator, 'numerator1 must be less than denominator')
    .refine(
      val =>
        val.whole1 +
          val.numerator1 / val.denominator -
          (val.whole2 + val.numerator2 / val.denominator) !==
        0,
      'fractions subtracted from each other must not be equal to 0'
    )
    .refine(val =>
      arraysHaveSameContents(simplify(val.numerator1, val.denominator), [
        val.numerator1,
        val.denominator
      ])
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const {
      denominator,
      whole1,
      whole2,
      numerator1,
      numerator2,
      correctFractions,
      incorrectFractions
    } = rejectionSample(
      () => {
        const whole1 = randomIntegerInclusive(2, 9);
        const whole2 = randomIntegerInclusive(0, whole1, {
          // Prevent whole values in selectable mixed fractions to be less than 1
          constraint: x => whole1 - x > 1
        });
        const denominator = randomIntegerInclusive(3, 9);
        const numerator1 = randomIntegerInclusive(1, denominator - 1, {
          // Ensure fraction does not simplify
          constraint: x => arraysHaveSameContents(simplify(x, denominator), [x, denominator])
        });

        const numerator2 = getRandomBoolean() && whole2 !== 0 ? 0 : numerator1;
        const additionOrSubtraction = getRandomBoolean();

        const whole3 = whole1 - whole2;
        const whole4 = additionOrSubtraction ? whole3 + 1 : whole3 - 1;

        const numerator3 = numerator1 - numerator2;

        const correctFractions =
          whole3 === 0
            ? [numerator3, denominator]
            : numerator3 === 0
            ? [whole3]
            : [whole3, numerator3, denominator];
        const incorrectFrac1 = numerator3 === 0 ? [whole4] : [whole4, numerator3, denominator];
        const incorrectFrac2 = [numerator1, denominator];
        const incorrectFrac3 = [numerator3, denominator];
        const incorrectFrac4 = [whole4, numerator1, denominator];
        const incorrectFrac5 =
          whole3 === 0 ? [numerator1, denominator] : [whole3, numerator1, denominator];

        const incorrectFractions = [
          incorrectFrac1,
          incorrectFrac2,
          ...(numerator3 !== 0 ? [incorrectFrac3] : []),
          incorrectFrac4,
          incorrectFrac5,
          [whole3],
          [whole4]
        ];

        const newFracs = getRandomSubArrayFromArray(incorrectFractions, 3);

        const fractions = newFracs.map(el => el.join('')).concat(correctFractions.join(''));

        return {
          denominator,
          whole1,
          whole2,
          numerator1,
          numerator2,
          additionOrSubtraction,
          correctFractions,
          incorrectFractions: newFracs,
          fractions
        };
      },
      ({ denominator, whole1, whole2, numerator1, numerator2, fractions }) =>
        // Ensure fractions subtracted from each other do not equal 0
        whole1 + numerator1 / denominator - (whole2 + numerator2 / denominator) !== 0 &&
        numerator1 - numerator2 >= 0 &&
        // Ensure no duplicate fractions
        arrayHasNoDuplicates(fractions)
    );

    return {
      denominator,
      whole1,
      whole2,
      numerator1,
      numerator2,
      correctFractions,
      incorrectFractions
    };
  },
  Component: props => {
    const {
      question: {
        denominator,
        whole1,
        whole2,
        numerator1,
        numerator2,
        correctFractions,
        incorrectFractions
      },
      translate,
      displayMode
    } = props;

    const [incorrectFracA, incorrectFracB, incorrectFracC] = shuffle(incorrectFractions);

    const selectables = shuffle(
      [
        {
          value:
            correctFractions.length === 1
              ? `${correctFractions[0]}`
              : correctFractions.length === 2
              ? `<frac n='${correctFractions[0]}' d='${correctFractions[1]}' />`
              : `<frac w='${correctFractions[0].toLocaleString()}' n='${correctFractions[1]}' d='${
                  correctFractions[2]
                }' />`,
          isCorrect: true
        },
        {
          value:
            incorrectFracA.length === 1
              ? `${incorrectFracA[0]}`
              : incorrectFracA.length === 2
              ? `<frac n='${incorrectFracA[0]}' d='${incorrectFracA[1]}' />`
              : `<frac w='${incorrectFracA[0].toLocaleString()}' n='${incorrectFracA[1]}' d='${
                  incorrectFracA[2]
                }' />`,
          isCorrect: false
        },
        {
          value:
            incorrectFracB.length === 1
              ? `${incorrectFracB[0]}`
              : incorrectFracB.length === 2
              ? `<frac n='${incorrectFracB[0]}' d='${incorrectFracB[1]}' />`
              : `<frac w='${incorrectFracB[0].toLocaleString()}' n='${incorrectFracB[1]}' d='${
                  incorrectFracB[2]
                }' />`,
          isCorrect: false
        },
        {
          value:
            incorrectFracC.length === 1
              ? `${incorrectFracC[0]}`
              : incorrectFracC.length === 2
              ? `<frac n='${incorrectFracC[0]}' d='${incorrectFracC[1]}' />`
              : `<frac w='${incorrectFracC[0].toLocaleString()}' n='${incorrectFracC[1]}' d='${
                  incorrectFracC[2]
                }' />`,
          isCorrect: false
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const partition1 =
      whole2 === 0
        ? `<frac n='${numerator2}' d='${denominator}' />`
        : numerator2 === 0
        ? whole2.toLocaleString()
        : `<frac w='${whole2.toLocaleString()}' n='${numerator2}' d='${denominator}' />`;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectMissingPartCompletePartWholeModel()}
        pdfTitle={translate.instructions.circleMissingPartCompletePartWholeModel()}
        testCorrect={selectables
          .filter(statement => statement.isCorrect)
          .map(statement => statement.value)}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        itemStyle={{ height: displayMode === 'digital' ? 110 : 150 }}
        numItems={3}
        Content={({ dimens }) => (
          <PartWholeModel
            top={`<frac w='${whole1}' n='${numerator1}' d='${denominator}' />`}
            partition={[partition1, '?']}
            dimens={dimens}
          />
        )}
        renderItems={selectables.map(({ value }) => ({
          value,
          component: (
            <TextStructure
              sentence={value}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 48 }}
            />
          )
        }))}
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aMn',
  description: 'aMn',
  keywords: [
    'Bar model',
    'Mixed number',
    'Fraction',
    'Whole',
    'Parts',
    'Numerator',
    'Denominator',
    'Addition',
    'Flexible partition'
  ],
  schema: z.object({
    whole1: z.number().int().min(0).max(4),
    whole2: z.number().int().min(2).max(4),
    denominator: z.number().int().min(3).max(8),
    numerator1: z.number().int().min(0).max(7),
    numerator2: z.number().int().min(2).max(7),
    isPie: z.boolean()
  }),
  simpleGenerator: () => {
    const whole2 = randomIntegerInclusive(2, 4);
    const whole1 = randomIntegerInclusive(0, whole2);
    const denominator = randomIntegerInclusive(3, 8);
    const numerator2 = randomIntegerInclusive(2, denominator - 1);
    // if whole2 is 0 this can't be and if whole2 is whole1 then this can't match num2 else answer would be 0
    const numerator1 = randomIntegerInclusive(
      whole1 === 0 ? 1 : 0,
      whole1 === whole2 ? numerator2 - 1 : numerator2
    );
    const isPie = getRandomBoolean();

    return { denominator, whole1, whole2, numerator1, numerator2, isPie };
  },
  Component: props => {
    const {
      question: { denominator, whole1, whole2, numerator1, numerator2, isPie },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const pieColor = getRandomFromArray(Object.values(PieChartColors), {
      random: seededRandom(props.question)
    }) as string;

    const colorArrays = [
      ...countRange(whole2).map(() => filledArray(numeratorColor, denominator)),
      countRange(denominator).map(i => (i < numerator2 ? numeratorColor : 'white'))
    ];

    let answerBox;
    let markSchemeAnswer;
    if (whole1 === whole2) {
      answerBox = `<frac nAns='' dAns='' />`;
      markSchemeAnswer = [(numerator2 - numerator1).toLocaleString(), denominator.toLocaleString()];
    } else if (numerator1 === numerator2) {
      answerBox = `<ans/>`;
      markSchemeAnswer = [(whole2 - whole1).toLocaleString()];
    } else {
      answerBox = `<frac wAns='' nAns='' dAns='' />`;
      markSchemeAnswer = [
        (whole2 - whole1).toLocaleString(),
        (numerator2 - numerator1).toLocaleString(),
        denominator.toLocaleString()
      ];
    }

    const firstFraction =
      whole1 === 0
        ? `<frac n='${numerator1.toLocaleString()}' d='${denominator.toLocaleString()}' />`
        : numerator1 === 0
        ? whole1.toLocaleString()
        : `<frac w='${whole1.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator.toLocaleString()}' />`;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.fillInMissingNumbersUseTheDiagramToHelp()}
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              flexWrap: 'wrap',
              flexDirection: whole2 > 2 || isPie ? 'row' : 'column',
              justifyContent: 'space-evenly',
              rowGap: 5
            }}
          >
            {isPie
              ? countRange(whole2 + 1).map(idx => (
                  <PieChart
                    key={idx}
                    pieOptions={filledArray({ ratioOfSlices: 1 }, denominator)}
                    radius={Math.min(dimens.height / 2, dimens.width / (2 * (whole2 + 3)))}
                    color={pieColor}
                    missingSlices={idx === whole2 ? countRange(denominator - numerator2) : []}
                  />
                ))
              : colorArrays.map((array, idx) => (
                  <View key={idx} style={{ width: whole2 > 2 ? dimens.width * 0.4 : dimens.width }}>
                    <ShadedFractionBarModel
                      totalSubSections={denominator}
                      width={whole2 > 2 ? dimens.width * 0.4 : dimens.width}
                      height={
                        whole2 > 2
                          ? dimens.height * (0.8 / (whole2 - 1))
                          : dimens.height / (colorArrays.length + 1)
                      }
                      customColorMap={array}
                    />
                  </View>
                ))}
          </View>
        )}
        sentence={`${firstFraction} ${ADD} ${answerBox} = <frac w='${whole2.toLocaleString()}' n='${numerator2.toLocaleString()}' d='${denominator.toLocaleString()}' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: markSchemeAnswer,
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        textStyle={{ fontSize: 40 }}
        inputMaxCharacters={1}
        testCorrect={answer => {
          if (whole1 === whole2) {
            return compareFractions([answer[0], answer[1]], [numerator2 - numerator1, denominator]);
          } else if (numerator1 === numerator2) {
            return answer[0] === (whole2 - whole1).toString();
          } else {
            return compareFractions(
              [answer[0], answer[1], answer[2]],
              [whole2 - whole1, numerator2 - numerator1, denominator]
            );
          }
        }}
      />
    );
  }
});

// //
// Small Step
// //

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionAMixedNumber',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question4, Question5]
});
export default SmallStep;
