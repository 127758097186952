import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { useContext, useMemo } from 'react';
import { CompleteTheSentencesWithState } from '../../molecules/CompleteTheSentences';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../../utils/react';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { Dimens, MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import { MeasureView } from '../../atoms/MeasureView';
import { TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { isEqual } from '../../../utils/matchers';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { parseSymbolsToString } from '../../../utils/parse';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /** Sentences to complete. */
  sentences: string[];
  mainPanelStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<ViewStyle>;
  pdfSentenceStyle?: StyleProp<TextStyle>;
  pdfContentStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  /** Either an array of the correct answers for each sentence, or a function for more complex use cases. */
  testCorrect: string[][] | ((userAnswer: string[][]) => boolean);
  /**
   * The width of each text input in characters. If left undefined and testCorrect is given as strings, it will
   * default to the longest correct answer.
   */
  inputMaxCharacters?: number;
  /**
   * Direction for this question's PDF content to be displayed.
   * Optional prop, defaults to row.
   */
  pdfDirection?: 'row' | 'column';
  /** Content to fill the area under the title. */
  Content: ElementOrRenderFunction<{
    dimens: Dimens;
  }>;
  extraSymbol?: ExtraSymbols;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[][]; answerText?: string };
};

/**
 * Layout containing a title, numpad, multiple sentences (500x200), and content (500x200) only.
 */
export default function QF1ContentAndSentences({
  title,
  pdfTitle,
  sentences,
  mainPanelStyle,
  inputStyle,
  sentenceStyle,
  pdfSentenceStyle,
  textStyle,
  fractionTextStyle,
  fractionContainerStyle,
  style,
  testCorrect: testCorrectProp,
  inputMaxCharacters: inputMaxCharactersProp,
  pdfDirection = 'row',
  Content,
  extraSymbol,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(displayMode, pdfDirection, questionHeight);

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  // Try to make the input boxes a suitable width.
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (Array.isArray(testCorrectProp)) {
      return Math.max(
        ...testCorrectProp.map(sentence => Math.max(...sentence.map(it => it.length)))
      );
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <View style={styles.mainPanel}>
            <MeasureView>
              {dimens => resolveElementOrRenderFunction(Content, { dimens })}
            </MeasureView>
            <CompleteTheSentencesWithState
              id="sentences"
              sentences={sentences}
              autoFocus
              inputMaxCharacters={inputMaxCharacters}
              inputStyle={inputStyle}
              sentenceStyle={sentenceStyle}
              textStyle={[styles.pdfText, textStyle]}
              fractionTextStyle={fractionTextStyle}
              fractionContainerStyle={fractionContainerStyle}
              style={[styles.sentences, styles.pdfSentences, pdfSentenceStyle]}
            />
          </View>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <View style={styles.mainPanel}>
              <MeasureView>
                {dimens => resolveElementOrRenderFunction(Content, { dimens })}
              </MeasureView>
              <CompleteTheSentencesWithState
                id="sentences"
                sentences={sentences}
                autoFocus
                inputMaxCharacters={inputMaxCharacters}
                inputStyle={inputStyle}
                sentenceStyle={sentenceStyle}
                textStyle={[styles.pdfText, textStyle]}
                fractionTextStyle={fractionTextStyle}
                fractionContainerStyle={fractionContainerStyle}
                style={[styles.sentences, styles.pdfSentences, pdfSentenceStyle]}
                defaultState={
                  typeof testCorrectProp === 'function'
                    ? customMarkSchemeAnswer?.answersToDisplay
                    : testCorrectProp.map(ansLine =>
                        ansLine.map(ans => {
                          // Temporary variable to convert simple string to localized string
                          const temp = Number(parseSymbolsToString(ans));
                          return temp.toLocaleString();
                        })
                      )
                }
              />
            </View>
            {customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" extraSymbol={extraSymbol} />}
      mainPanelContents={
        <View style={[styles.mainPanel, mainPanelStyle]}>
          <MeasureView>{dimens => resolveElementOrRenderFunction(Content, { dimens })}</MeasureView>
          <CompleteTheSentencesWithState
            id="sentences"
            testCorrect={testCorrect}
            sentences={sentences}
            autoFocus
            inputMaxCharacters={inputMaxCharacters}
            inputStyle={inputStyle}
            sentenceStyle={sentenceStyle}
            textStyle={textStyle}
            fractionTextStyle={fractionTextStyle}
            fractionContainerStyle={fractionContainerStyle}
            style={[styles.sentences, style]}
          />
        </View>
      }
      {...props}
    />
  );
}

function useStyles(
  displayMode: 'digital' | 'pdf' | 'markscheme',
  pdfDirection: 'row' | 'column',
  questionHeight: number
) {
  return useMemo(
    () =>
      StyleSheet.create({
        mainPanel: {
          flex: 1,
          justifyContent: 'center',
          flexDirection: displayMode !== 'digital' ? pdfDirection : 'column'
        },

        sentences: {
          marginTop: 16
        },

        pdfSentences: {
          justifyContent: pdfDirection === 'row' ? 'flex-end' : 'flex-start',
          alignItems: pdfDirection === 'row' ? 'flex-end' : 'flex-start',
          paddingLeft: 16,
          flex: questionHeight <= MINIMUM_QUESTION_HEIGHT ? 1 : undefined,
          marginTop: questionHeight > MINIMUM_QUESTION_HEIGHT ? 64 : 0
        },
        pdfText: {
          fontSize: 50,
          lineHeight: 75
        }
      }),
    [displayMode, pdfDirection, questionHeight]
  );
}
