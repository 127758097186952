import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import { filledArray } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { MULT, DIV } from '../../../../constants';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { objectSchema, getRandomObject } from '../../../../utils/objects';
import { getObjectImage } from '../../../../utils/objectsImages';
import QF31NumberGridInteractive from '../../../../components/question/questionFormats/QF31NumberGridInteractive';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'akB',
  description: 'akB',
  keywords: ['Times-table', '4', 'Object', 'Multiply'],
  schema: z.object({
    groups: z.number().int().min(2).max(8),
    object: objectSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 8);

    const object = getRandomObject();

    return { groups, object };
  },
  Component: props => {
    const {
      question: { groups, object },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        title={translate.instructions.whatMultIsShown()}
        testCorrect={answer =>
          ((answer[0] === '4' && answer[1] === groups.toString()) ||
            (answer[0] === groups.toString() && answer[1] === '4')) &&
          answer[2] === (groups * 4).toString()
        }
        inputMaxCharacters={2}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => {
          const topLine = groups <= 4 ? groups : Math.ceil(groups / 2);
          const botLine = groups - topLine;
          return (
            <View
              style={{
                alignSelf: 'center',
                rowGap: 8
              }}
            >
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {range(1, topLine).map(index => (
                  <View key={index}>
                    {getObjectImage(object, 4, dimens.height / 2, dimens.width / topLine)}
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {botLine !== 0 &&
                  range(1, botLine).map(index => (
                    <View key={index}>
                      {getObjectImage(object, 4, dimens.height / 2, dimens.width / topLine)}
                    </View>
                  ))}
              </View>
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            groups.toLocaleString(),
            (4).toLocaleString(),
            (groups * 4).toLocaleString()
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'akC',
  description: 'akC',
  keywords: ['Times-table', '4', 'Array', 'Multiply', 'Fact family'],
  schema: z.object({
    columnsOrRows: z.number().int().min(2).max(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const columnsOrRows = randomIntegerInclusive(2, 10, {
      constraint: x => x !== 4
    });

    return { columnsOrRows };
  },
  Component: props => {
    const {
      question: { columnsOrRows },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.writeTwoMultAndTwoDivFactsShownByArray()}
        inputMaxCharacters={2}
        testCorrect={answer =>
          ((answer[0][0] === columnsOrRows.toString() &&
            answer[0][1] === '4' &&
            answer[1][0] === '4' &&
            answer[1][1] === columnsOrRows.toString()) ||
            (answer[0][0] === '4' &&
              answer[0][1] === columnsOrRows.toString() &&
              answer[1][0] === columnsOrRows.toString() &&
              answer[1][1] === '4')) &&
          answer[0][2] === (4 * columnsOrRows).toString() &&
          answer[1][2] === (4 * columnsOrRows).toString() &&
          ((answer[2][1] === columnsOrRows.toString() &&
            answer[2][2] === '4' &&
            answer[3][1] === '4' &&
            answer[3][2] === columnsOrRows.toString()) ||
            (answer[2][1] === '4' &&
              answer[2][2] === columnsOrRows.toString() &&
              answer[3][1] === columnsOrRows.toString() &&
              answer[3][2] === '4')) &&
          answer[2][0] === (4 * columnsOrRows).toString() &&
          answer[3][0] === (4 * columnsOrRows).toString()
        }
        {...props}
        Content={({ dimens }) => (
          <ArrayOfObjects
            // columnsOrRows always displays in a tall form - columns is always less than or equal to rows.
            rows={columnsOrRows > 4 ? columnsOrRows : 4}
            columns={columnsOrRows > 4 ? 4 : columnsOrRows}
            dimens={dimens}
          />
        )}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              columnsOrRows.toLocaleString(),
              (4).toLocaleString(),
              (4 * columnsOrRows).toLocaleString()
            ],
            [
              (4).toLocaleString(),
              columnsOrRows.toLocaleString(),
              (4 * columnsOrRows).toLocaleString()
            ],
            [
              (4 * columnsOrRows).toLocaleString(),
              (4).toLocaleString(),
              columnsOrRows.toLocaleString()
            ],
            [
              (4 * columnsOrRows).toLocaleString(),
              columnsOrRows.toLocaleString(),
              (4).toLocaleString()
            ]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'akD',
  description: 'akD',
  keywords: ['Times-table', '4', '100 square'],
  schema: z.object({
    startNumber: numberEnum([1, 11, 21, 31, 41])
  }),
  simpleGenerator: () => {
    const startNumber = getRandomFromArray([1, 11, 21, 31, 41] as const);

    return { startNumber };
  },
  Component: props => {
    const {
      question: { startNumber },
      translate
    } = props;

    const correctNumbers = range(startNumber, startNumber + 49).filter(number => number % 4 === 0);

    return (
      <QF31NumberGridInteractive
        startNumber={startNumber}
        finishNumber={startNumber + 49}
        title={translate.instructions.selectAllNumbersInNumTimesTable(4)}
        pdfTitle={translate.instructions.shadeNumbersInXTimesTable(4)}
        testCorrect={correctNumbers}
        initialState={[correctNumbers[0]]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'akE',
  description: 'akE',
  keywords: ['Times-table', '4', 'Fact family', 'Inverse', 'Multiply', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 4, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 4, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ result: numberC1, right: 4, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ result: numberD1, right: 4, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'akF',
  description: 'akF',
  keywords: ['Times-table', '4', 'Bar model'],
  schema: z.object({
    parts: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(2, 10);

    return { parts };
  },

  Component: ({ question: { parts }, translate }) => {
    const answerIndices = [[0], []];

    const barArray = filledArray(4, parts);

    const numbers = [[parts * 4], barArray];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={parts * 4}
        oneFontSize
        sameRowColor
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'akG',
  description: 'akG',
  keywords: ['Times-table', '4', 'Given one find other'],
  schema: z.object({
    numberA1: z.number().int().min(3).max(12),
    numberB1Relative: z.union([z.literal(-1), z.literal(1)])
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(3, 12);

    const numberB1Relative = getRandomFromArray([-1, 1]) as -1 | 1;

    return { numberA1, numberB1Relative };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1Relative },
      translate
    } = props;

    const numberB1 = numberA1 + numberB1Relative;
    const numberC1 = numberA1 + 10;

    const eqB = getBinOpEquation({ left: numberB1, right: 4, sign: 'multiply', answer: 'result' });

    const eqC = getBinOpEquation({ left: numberC1, right: 4, sign: 'multiply', answer: 'result' });

    const eqs = [eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.useTheFactThatXToCompleteCalcs(
          `${numberA1} ${MULT} ${(4).toLocaleString()} = ${(numberA1 * 4).toLocaleString()}`
        )}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The4TimesTable',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
