import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import TextStructure from '../../../../components/molecules/TextStructure';
import Table from '../../../../components/molecules/Table';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { arraysHaveSameContentsUnordered, filledArray } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import { lessThanGreaterThanOrEqualTo, numberToBase10Object } from '../../../../utils/math';
import { Dimens } from '../../../../theme/scaling';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'asQ',
  description: 'asQ',
  keywords: ['Fractions', 'Array', 'Divide', 'Unit'],
  schema: z.object({
    number1: z.number().int().min(3).max(5),
    number2: z.number().int().min(8).max(25)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 5);
    const number2 = randomIntegerInclusive(8, 25, { constraint: x => x % number1 === 0 });

    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number2 / number1;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheCountersToHelpYouFindXofY(
          `<frac n="1" d="${number1}" />`,
          number2
        )}
        Content={({ dimens }) => <ArrayOfObjects rows={number1} columns={answer} dimens={dimens} />}
        sentence={'<ans/>'}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'asR',
  description: 'asR',
  keywords: ['Fractions', 'Divide', 'Unit'],
  schema: z
    .object({
      lhs: z.enum(['2', '3', '4', '5', '8', '10']).array().length(4),
      rhs: z.enum(['2', '3', '4', '5', '8', '10']).array().length(4)
    })
    .refine(
      value => arraysHaveSameContentsUnordered(value.lhs, value.rhs),
      'both sides should have the same content'
    ),
  simpleGenerator: () => {
    const lhs = getRandomSubArrayFromArray(['2', '3', '4', '5', '8', '10'] as const, 4);
    const rhs = shuffle(lhs);
    return { lhs, rhs };
  },
  Component: ({ question: { lhs, rhs }, translate, displayMode }) => {
    const translateToParts = {
      '2': translate.fractions.toFindXFracOfAnAmountYou(`<frac n='1' d='2' />`),
      '3': translate.fractions.toFindXFracOfAnAmountYou(`<frac n='1' d='3' />`),
      '4': translate.fractions.toFindXFracOfAnAmountYou(`<frac n='1' d='4' />`),
      '5': translate.fractions.toFindXFracOfAnAmountYou(`<frac n='1' d='5' />`),
      '8': translate.fractions.toFindXFracOfAnAmountYou(`<frac n='1' d='8' />`),
      '10': translate.fractions.toFindXFracOfAnAmountYou(`<frac n='1' d='10' />`)
    };

    return (
      <QF37SentencesDrag
        pdfTitle={translate.instructions.useCardsCompleteSentences()}
        title={translate.instructions.dragCardsToCompleteSentences()}
        items={lhs.map(value => ({
          component: `${translate.answerSentences.divideByX(value)}`,
          value: value
        }))}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentencesStyle={{ alignSelf: 'flex-start' }}
        sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        sentences={rhs.map(value => `${translateToParts[value]} <ans/>`)}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={rhs.map(answer => [answer])}
        pdfLayout="itemsTop"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'asS',
  description: 'asS',
  keywords: ['Fractions', 'Bar model', 'Base 10', 'Divide', 'Unit'],
  schema: z.object({
    number1: z.number().int().min(3).max(5),
    number2: z.number().int().min(33).max(99)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 5);
    const number2a = randomIntegerInclusiveStep(number1, 9, number1) * 10;
    const number2b = randomIntegerInclusiveStep(number1, 9, number1);

    const number2 = number2a + number2b;

    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number2 / number1;

    const base10Images = (number: number, { height, width }: Dimens) => {
      return (
        <BaseTenRepresentation
          usableHeight={height * 0.75}
          usableWidth={width * 0.75}
          b10Rep={{
            variant: 'Cubes',
            numbers: numberToBase10Object(number),
            arrangement: 'ltr'
          }}
        />
      );
    };

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToFindFractionOfAnAmount()}
        Content={({ dimens }) => (
          <Table
            rowStyle={{ justifyContent: 'flex-end' }}
            items={[filledArray(base10Images(number2 / number1, dimens), number1)]}
            cellStyle={{
              //cellStyle in table has paddingTop and paddingLeft defined, so need to explicitly write them add and can't use just "padding"
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 16,
              paddingBottom: 16
            }}
          />
        )}
        sentence={`${translate.answerSentences.xOfY(
          `<frac n="1" d="${number1}"/>`,
          number2.toLocaleString()
        )} = <ans/>`}
        fractionContainerStyle={{ height: 42 }}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asT',
  description: 'asT',
  keywords: ['Fractions', 'Bar model', 'Place value', 'Counters', 'Divide', 'Unit'],
  schema: z.object({
    number1: numberEnum([3, 4, 5, 8]),
    number2: z.number().int().min(45).max(96)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([3, 4, 5, 8] as const);

    const getNumber2 = (number1: number): number => {
      if (number1 === 3) {
        return getRandomFromArray([45, 48, 72, 75, 78] as const);
      } else if (number1 === 4) {
        return getRandomFromArray([52, 56, 92, 96] as const);
      } else if (number1 === 5) {
        return getRandomFromArray([60, 65] as const);
      } else {
        return 96;
      }
    };

    const number2 = getNumber2(number1);

    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const answer = number2 / number1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheFractionOfTheAmount()}
        sentence={`${translate.answerSentences.xOfY(
          `<frac n="1" d="${number1}"/>`,
          number2.toLocaleString()
        )} = <ans/>`}
        fractionTextStyle={{ fontSize: 32 }}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'asU',
  description: 'asU',
  keywords: ['Fractions', 'Divide', 'Unit'],
  schema: z.object({
    number1: numberEnum([2, 3, 4, 5, 8, 10]),
    number2: numberEnum([2, 3, 4, 5, 8, 10]),
    number3: z.number().int().min(16).max(100),
    number4: numberEnum([2, 3, 4, 5, 8, 10]),
    number5: numberEnum([2, 3, 4, 5, 8, 10]),
    number6: z.number().int().min(16).max(100),
    number7: numberEnum([2, 3, 4, 5, 8, 10]),
    number8: numberEnum([2, 3, 4, 5, 8, 10]),
    number9: z.number().int().min(16).max(100)
  }),
  simpleGenerator: () => {
    const [number1, number2, number4, number7] = getRandomSubArrayFromArray(
      [2, 3, 4, 5, 8, 10] as const,
      4
    );

    type Parts = 2 | 10 | 3 | 8 | 5 | 4;
    const number5 = getRandomFromArray([2, 3, 4, 5, 8, 10].filter(x => x !== number4)) as Parts;
    const number8 = getRandomFromArray([2, 3, 4, 5, 8, 10].filter(x => x !== number7)) as Parts;

    const number3 = randomIntegerInclusive(16, 100, {
      constraint: x => x % number1 === 0 && x % number2 === 0
    });

    const number6 = randomIntegerInclusive(16, 100, {
      constraint: x => x % number4 === 0 && x % number5 === 0
    });

    const number9 = randomIntegerInclusive(16, 100, {
      constraint: x => x % number7 === 0 && x % number8 === 0
    });

    return { number1, number2, number3, number4, number5, number6, number7, number8, number9 };
  },
  Component: ({
    question: { number1, number2, number3, number4, number5, number6, number7, number8, number9 },
    translate,
    displayMode
  }) => {
    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 210 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="1" d="${number1}" />`,
                `£${number3}`
              )}`}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 210 : 300, alignItems: 'flex-start' }}>
            <TextStructure
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="1" d="${number2}" />`,
                `£${number3}`
              )}`}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(number2, number1) // Note: Switched the order here because dealing with fractions
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 210 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="1" d="${number4}" />`,
                number6
              )} ${translate.units.kg()}`}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 210 : 300, alignItems: 'flex-start' }}>
            <TextStructure
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="1" d="${number5}" />`,
                number6
              )} ${translate.units.kg()}`}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(number5, number4) // Note: Switched the order here because dealing with fractions
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 210 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="1" d="${number7}" />`,
                number9
              )} ${translate.units.cm()}`}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 210 : 300, alignItems: 'flex-start' }}>
            <TextStructure
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="1" d="${number8}" />`,
                number9
              )} ${translate.units.cm()}`}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(number8, number7) // Note: Switched the order here because dealing with fractions
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareAmounts()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareAmounts()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'asV',
  description: 'asV',
  keywords: ['Fractions', 'Divide', 'Unit'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(10).max(30)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);
    const number2 = randomIntegerInclusiveStep(10, 30, 10, { constraint: x => x % number1 === 0 });
    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate, displayMode }) => {
    const answer = number2 * number1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={`${translate.answerSentences.xOfY(
          `<frac n="1" d="${number1}"/>`,
          `<ans/> = ${number2}`
        )}`}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnitFractionsOfASetOfObjects',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
