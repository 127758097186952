import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomIntegerInclusive, rejectionSample } from '../../../../utils/random';
import { Direction, Point2d } from '../../../../utils/vectors';
import QF48TranslateSvg from '../../../../components/question/questionFormats/QF48TranslateSvg';
import { isValidTriangle } from '../../../../utils/shapes';
import Svg, { TSpan, Text } from 'react-native-svg';
import { GridPolygon } from '../../../../utils/gridUtils';
import { colors } from '../../../../theme/colors';
import { AssetSvg } from '../../../../assets/svg';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import {
  arraysHaveSameContents,
  countRange,
  nestedArrayHasNoDuplicates
} from '../../../../utils/collections';
import { GridSvgChildren } from '../../../../components/question/representations/Coordinates/Grid';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'azC',
  description: 'azC',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      startX: z.number().int().min(0).max(5),
      startY: z.number().int().min(0).max(5),
      translateX: z.number().int().min(-3).max(3)
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translation'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(0, 5);
    const startY = randomIntegerInclusive(0, 5);

    const translateX = randomIntegerInclusive(-3, 3, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });

    return { startX, startY, translateX };
  },
  Component: ({ question: { startX, startY, translateX }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, 0));
    const [direction, distance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const title = translate.instructions.translateShapeXSquaresDX({
      shape: translate.shapes.points(1),
      x: distance,
      dx: translate.directions[direction]()
    });

    const pdfTitle = translate.instructions.translateShapeXSquaresDXPDF({
      shape: translate.shapes.points(1),
      x: distance,
      dx: translate.directions[direction]()
    });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={[start]}
        end={[end]}
        svg="dot"
        xMax={5}
        yMax={5}
        hideContinuationLines={false}
        hideAxis={false}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'azD',
  description: 'azD',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      startX: z.number().int().min(0).max(5),
      startY: z.number().int().min(0).max(5),
      translateY: z.number().int().min(-3).max(3)
    })
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translation'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(0, 5);
    const startY = randomIntegerInclusive(0, 5);

    const translateY = randomIntegerInclusive(-3, 3, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });

    return { startX, startY, translateY };
  },
  Component: ({ question: { startX, startY, translateY }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(0, translateY));
    const [direction, distance] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();
    const title = translate.instructions.translateShapeXSquaresDY({
      shape: translate.shapes.points(1),
      x: distance,
      dy: translate.directions[direction]()
    });

    const pdfTitle = translate.instructions.translateShapeXSquaresDYPDF({
      shape: translate.shapes.points(1),
      x: distance,
      dy: translate.directions[direction]()
    });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={[start]}
        end={[end]}
        svg="dot"
        xMax={5}
        yMax={5}
        hideContinuationLines={false}
        hideAxis={false}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'azE',
  description: 'azE',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      startX: z.number().int().min(0).max(5),
      startY: z.number().int().min(0).max(5),
      translateX: z.number().int().min(-3).max(3),
      translateY: z.number().int().min(-3).max(3)
    })
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translation'
    )
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translation'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(0, 5);
    const startY = randomIntegerInclusive(0, 5);

    const translateX = randomIntegerInclusive(-3, 3, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });
    const translateY = randomIntegerInclusive(-3, 3, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });

    return { startX, startY, translateX, translateY };
  },
  Component: ({ question: { startX, startY, translateX, translateY }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));
    const [directionY, distanceY] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [directionX, distanceX] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const title = translate.instructions.translateShapeXSquaresDXAndYSquaresDY({
      shape: translate.shapes.points(1),
      x: distanceX,
      dx: translate.directions[directionX](),
      y: distanceY,
      dy: translate.directions[directionY]()
    });

    const pdfTitle = translate.instructions.translateShapeXSquaresDXAndYSquaresDYPDF({
      shape: translate.shapes.points(1),
      x: distanceX,
      dx: translate.directions[directionX](),
      y: distanceY,
      dy: translate.directions[directionY]()
    });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={[start]}
        end={[end]}
        svg="dot"
        xMax={5}
        yMax={5}
        hideContinuationLines={false}
        hideAxis={false}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'azF',
  description: 'azF',
  keywords: ['Translate', 'Coordinate', 'Triangle', 'Vertex'],
  schema: z
    .object({
      coordinate1: z.number().int().min(0).max(5).array().length(2),
      coordinate2: z.number().int().min(0).max(5).array().length(2),
      coordinate3: z.number().int().min(0).max(5).array().length(2),
      translateX: z
        .number()
        .int()
        .refine(x => x !== 0, 'Must not be zero'),
      translateY: z
        .number()
        .int()
        .refine(y => y !== 0, 'Must not be zero')
    })
    .refine(({ coordinate1, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate1[0], coordinate1[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)')
    .refine(({ coordinate2, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate2[0], coordinate2[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)')
    .refine(({ coordinate3, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate3[0], coordinate3[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate2 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate3 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];

        const translateX = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const translateY = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });

        return { coordinate1, coordinate2, coordinate3, translateX, translateY };
      },
      ({ coordinate1, coordinate2, coordinate3, translateX, translateY }) => {
        const newPoint1 = new Point2d(coordinate1[0], coordinate1[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint2 = new Point2d(coordinate2[0], coordinate2[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint3 = new Point2d(coordinate3[0], coordinate3[1]).add(
          new Point2d(translateX, translateY)
        );
        return (
          0 <= newPoint1.x &&
          newPoint1.x <= 5 &&
          0 <= newPoint1.y &&
          newPoint1.y <= 5 &&
          0 <= newPoint2.x &&
          newPoint2.x <= 5 &&
          0 <= newPoint2.y &&
          newPoint2.y <= 5 &&
          0 <= newPoint3.x &&
          newPoint3.x <= 5 &&
          0 <= newPoint3.y &&
          newPoint3.y <= 5 &&
          isValidTriangle(
            coordinate1 as [number, number],
            coordinate2 as [number, number],
            coordinate3 as [number, number]
          )
        );
      }
    ),
  Component: ({
    question: { coordinate1, coordinate2, coordinate3, translateX, translateY },
    translate,
    displayMode
  }) => {
    const start = new Point2d(coordinate1[0], coordinate1[1]);
    const end = start.add(new Point2d(translateX, translateY));
    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const title = translate.instructions.translateShapeXSquaresDXAndYSquaresDY({
      shape: translate.shapes.points(1),
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });

    const pdfTitle = translate.instructions.translateShapeXSquaresDXAndYSquaresDYPDF({
      shape: translate.shapes.points(1),
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={[start]}
        end={[end]}
        svg="dot"
        gridChildren={
          <Svg height={1000}>
            <GridPolygon
              points={[
                coordinate1 as [number, number],
                coordinate2 as [number, number],
                coordinate3 as [number, number]
              ]}
              color={displayMode !== 'digital' ? `${colors.greys400}70` : undefined}
              showBorder
            />
          </Svg>
        }
        xMax={5}
        yMax={5}
        hideContinuationLines={false}
        hideAxis={false}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'azG',
  description: 'azG',
  keywords: ['Translate', 'Coordinate', 'Triangle', 'Vertex'],
  schema: z
    .object({
      coordinate1: z.number().int().min(0).max(5).array().length(2),
      coordinate2: z.number().int().min(0).max(5).array().length(2),
      coordinate3: z.number().int().min(0).max(5).array().length(2),
      translateX: z
        .number()
        .int()
        .refine(x => x !== 0, 'Must not be zero'),
      translateY: z
        .number()
        .int()
        .refine(y => y !== 0, 'Must not be zero')
    })
    .refine(({ coordinate1, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate1[0], coordinate1[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)')
    .refine(({ coordinate2, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate2[0], coordinate2[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)')
    .refine(({ coordinate3, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate3[0], coordinate3[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate2 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate3 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];

        const translateX = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const translateY = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });

        return { coordinate1, coordinate2, coordinate3, translateX, translateY };
      },
      ({ coordinate1, coordinate2, coordinate3, translateX, translateY }) => {
        const newPoint1 = new Point2d(coordinate1[0], coordinate1[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint2 = new Point2d(coordinate2[0], coordinate2[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint3 = new Point2d(coordinate3[0], coordinate3[1]).add(
          new Point2d(translateX, translateY)
        );
        return (
          0 <= newPoint1.x &&
          newPoint1.x <= 5 &&
          0 <= newPoint1.y &&
          newPoint1.y <= 5 &&
          0 <= newPoint2.x &&
          newPoint2.x <= 5 &&
          0 <= newPoint2.y &&
          newPoint2.y <= 5 &&
          0 <= newPoint3.x &&
          newPoint3.x <= 5 &&
          0 <= newPoint3.y &&
          newPoint3.y <= 5 &&
          isValidTriangle(
            coordinate1 as [number, number],
            coordinate2 as [number, number],
            coordinate3 as [number, number],
            3
          )
        );
      }
    ),

  Component: ({
    question: { coordinate1, coordinate2, coordinate3, translateX, translateY },
    translate,
    displayMode
  }) => {
    const start1 = new Point2d(coordinate1[0], coordinate1[1]);
    const end1 = start1.add(new Point2d(translateX, translateY));
    const start2 = new Point2d(coordinate2[0], coordinate2[1]);
    const end2 = start2.add(new Point2d(translateX, translateY));
    const start3 = new Point2d(coordinate3[0], coordinate3[1]);
    const end3 = start3.add(new Point2d(translateX, translateY));

    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const title = translate.instructions.dragPointsToTranslateEachVertexXSquaresDXAndYSquaresDY({
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });

    const pdfTitle =
      translate.instructions.dragPointsToTranslateEachVertexXSquaresDXAndYSquaresDYPDF({
        x: xDistance,
        dx: translate.directions[xDirection](),
        y: yDistance,
        dy: translate.directions[yDirection]()
      });

    return (
      <QF48TranslateSvg
        title={title}
        pdfTitle={pdfTitle}
        start={[start1, start2, start3]}
        end={[end1, end2, end3]}
        svg="custom"
        gridChildren={
          <Svg height={1000}>
            <GridPolygon
              points={[
                coordinate1 as [number, number],
                coordinate2 as [number, number],
                coordinate3 as [number, number]
              ]}
              color={displayMode !== 'digital' ? `${colors.greys400}70` : undefined}
              showBorder
            />
          </Svg>
        }
        customDraggable={(color: string, opacity?: number, index = 0) => ({
          component: (
            <Svg width={36} height={36}>
              <AssetSvg
                name="Coordinates/CirclePointCustomizable"
                width={36}
                svgProps={{ fill: opacity && opacity < 1 ? `${color}${opacity * 100}` : color }}
              />
              <Text y={26} x={18} textAnchor="middle" fontFamily="White_Rose_Noto-Regular">
                <TSpan
                  fontSize={displayMode === 'digital' ? 22 : 28}
                  fill={displayMode === 'digital' ? 'black' : 'white'}
                >
                  {['A', 'B', 'C'][index]}
                </TSpan>
              </Text>
            </Svg>
          ),
          width: 36,
          height: 36
        })}
        xMax={5}
        yMax={5}
        hideContinuationLines={false}
        hideAxis={false}
        anchorDX={18}
        anchorDY={18}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'azH',
  description: 'azH',
  keywords: ['Translate', 'Coordinate', 'Triangle', 'Vertex'],
  schema: z
    .object({
      coordinate1: z.number().int().min(0).max(5).array().length(2),
      coordinate2: z.number().int().min(0).max(5).array().length(2),
      coordinate3: z.number().int().min(0).max(5).array().length(2),
      translateX: z
        .number()
        .int()
        .refine(x => x !== 0, 'Must not be zero'),
      translateY: z
        .number()
        .int()
        .refine(y => y !== 0, 'Must not be zero')
    })
    .refine(({ coordinate1, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate1[0], coordinate1[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)')
    .refine(({ coordinate2, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate2[0], coordinate2[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)')
    .refine(({ coordinate3, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate3[0], coordinate3[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate2 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate3 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];

        const translateX = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const translateY = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });

        return { coordinate1, coordinate2, coordinate3, translateX, translateY };
      },
      ({ coordinate1, coordinate2, coordinate3, translateX, translateY }) => {
        const newPoint1 = new Point2d(coordinate1[0], coordinate1[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint2 = new Point2d(coordinate2[0], coordinate2[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint3 = new Point2d(coordinate3[0], coordinate3[1]).add(
          new Point2d(translateX, translateY)
        );
        return (
          0 <= newPoint1.x &&
          newPoint1.x <= 5 &&
          0 <= newPoint1.y &&
          newPoint1.y <= 5 &&
          0 <= newPoint2.x &&
          newPoint2.x <= 5 &&
          0 <= newPoint2.y &&
          newPoint2.y <= 5 &&
          0 <= newPoint3.x &&
          newPoint3.x <= 5 &&
          0 <= newPoint3.y &&
          newPoint3.y <= 5 &&
          isValidTriangle(
            coordinate1 as [number, number],
            coordinate2 as [number, number],
            coordinate3 as [number, number],
            3
          )
        );
      }
    ),

  Component: ({
    question: { coordinate1, coordinate2, coordinate3, translateX, translateY },
    translate,
    displayMode,
    theme
  }) => {
    const start1 = new Point2d(coordinate1[0], coordinate1[1]);
    const end1 = start1.add(new Point2d(translateX, translateY));
    const start2 = new Point2d(coordinate2[0], coordinate2[1]);
    const end2 = start2.add(new Point2d(translateX, translateY));
    const start3 = new Point2d(coordinate3[0], coordinate3[1]);
    const end3 = start3.add(new Point2d(translateX, translateY));

    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const title = translate.instructions.dragPointsToTranslateEachVertexXSquaresDXAndYSquaresDY({
      x: xDistance,
      dx: translate.directions[xDirection](),
      y: yDistance,
      dy: translate.directions[yDirection]()
    });

    const pdfTitle =
      translate.instructions.dragPointsToTranslateEachVertexXSquaresDXAndYSquaresDYPDF({
        x: xDistance,
        dx: translate.directions[xDirection](),
        y: yDistance,
        dy: translate.directions[yDirection]()
      });

    return (
      <QF46PlotCoordinate
        title={displayMode === 'digital' ? title : pdfTitle}
        gridChildren={[
          <GridSvgChildren key={'polygon'}>
            <GridPolygon
              points={[
                coordinate1 as [number, number],
                coordinate2 as [number, number],
                coordinate3 as [number, number]
              ]}
              color={displayMode !== 'digital' ? `${colors.greys400}70` : undefined}
              showBorder
            />
          </GridSvgChildren>
        ]}
        snapToGrid
        items={countRange(3).map(() => ({
          // In PDF mode, we use a cross instead.
          component:
            displayMode === 'digital'
              ? 'Coordinates/CirclePointCustomizable'
              : 'Coordinates/CrossPointCustomizable',
          svgProps: { fill: theme.colors.tertiary }
        }))}
        questionHeight={900}
        testCorrect={ans =>
          nestedArrayHasNoDuplicates(ans, true) &&
          ans.every(
            coord =>
              arraysHaveSameContents(coord, [end1.x, end1.y]) ||
              arraysHaveSameContents(coord, [end2.x, end2.y]) ||
              arraysHaveSameContents(coord, [end3.x, end3.y])
          )
        }
        gridProps={{ xMax: 5, yMax: 5, squareGrid: true }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [end1.x, end1.y] as [number, number],
            [end2.x, end2.y] as [number, number],
            [end3.x, end3.y] as [number, number]
          ]
        }}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TranslateOnAGrid',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
