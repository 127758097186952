import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { MULT } from '../../../../constants';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { ScientificNotation, base10ObjectToNumber } from '../../../../utils/math';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { horizontalDoubleEndedArrow } from '../../../../components/question/representations/LineSvgs';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJY',
  description: 'aJY',
  keywords: ['Multiplication', 'Base 10'],
  schema: z.object({
    number: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 10);

    return { number };
  },
  Component: ({ question: { number }, translate, displayMode }) => {
    return (
      <QF1ContentAndSentences
        title={translate.instructions.useBase10ToCompleteCalculations()}
        sentences={[
          `${number} ${MULT} 1 ${translate.powersOfTen.tens(
            1
          )} = <ans/> ${translate.powersOfTen.tens(number)}`,
          `${number} ${MULT} 10 = <ans/>`
        ]}
        sentenceStyle={{ alignSelf: 'center' }}
        testCorrect={[[number.toString()], [(number * 10).toString()]]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={1}
            columns={number}
            dimens={dimens}
            customImage={
              // Force each base 10 to always have a width of dimens.width / 15 (dimens.width / 25 on PDF) to keep the appearance consistent.
              // This makes them appear to have the same width gap as the SVG width:
              <AssetSvg
                name="Base_Ten/10cube"
                height={dimens.height}
                width={displayMode === 'digital' ? dimens.width / 15 : dimens.width / 25}
              />
            }
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aJZ',
  description: 'aJZ',
  keywords: ['Multiplication', '10'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(1).max(10),
    number3: z.number().int().min(1).max(10),
    number4: z.number().int().min(10).max(12)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number2, number3] = randomUniqueIntegersInclusive(1, 10, 3);
    const number4 = randomIntegerInclusive(10, 12, {
      constraint: x => x !== number1 && x !== number2 && x !== number3
    });

    return { number1, number2, number3, number4 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    // Answers
    const eqA = getBinOpEquation({
      left: number1,
      right: 10,
      sign: 'multiply',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: 10,
      right: number2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: number3,
      right: 10,
      sign: 'multiply',
      answer: 'result'
    });

    const eqD = getBinOpEquation({
      left: 10,
      right: number4,
      sign: 'multiply',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aJZ2',
  description: 'aJZ2',
  keywords: ['Multiplication', '10'],
  schema: z.object({
    number1: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 12);

    return { number1 };
  },

  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[(number1 * 10).toString()]}
        sentence={`${number1.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aJ0',
  description: 'aJ0',
  keywords: ['Multiplication', '10'],
  schema: z.object({
    number: z.number().min(1).max(999)
  }),
  simpleGenerator: () => {
    // Make sure the smallest number is at least 1
    const firstDigit = randomIntegerInclusive(1, 9);
    const secondDigit = randomIntegerInclusive(0, 9);
    const thirdDigit = randomIntegerInclusive(0, 9);

    const number = base10ObjectToNumber({
      ones: firstDigit,
      tens: secondDigit,
      hundreds: thirdDigit
    });

    return { number };
  },
  Component: ({ question: { number }, translate, displayMode }) => {
    const power = Math.pow(10, 1);

    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragTheCountersToShowNumTimesNum(number, 10)}
        pdfTitle={translate.instructions.drawCountersToShowTheAnswerToX(
          `${number.toLocaleString()} ${MULT} ${(10).toLocaleString()}`
        )}
        initialState={displayMode === 'digital' ? number : undefined}
        number={ScientificNotation.fromNumber(number * power)}
        columnsToShow={[3, 2, 1, 0]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aJ1',
  description: 'aJ1',
  keywords: ['Multiplication', '10'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number3: z.number().int().min(10).max(90).multipleOf(10),
    number4: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const number3 = randomIntegerInclusiveStep(10, 90, 10);
    const number4 = randomIntegerInclusive(1, 9);

    return { number1, number3, number4 };
  },

  Component: props => {
    const {
      question: { number1, number3, number4 },
      translate
    } = props;

    // More numbers
    const number2 = number1 * 10;
    const number5 = number3 + number4;
    const number6 = number5 * 10;
    const number7 = number3 * 10;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[number1.toString()], [number5.toString()], [number3.toString()]]}
        sentences={[
          `<ans/> ${MULT} 10 = ${number2}`,
          `10 ${MULT} <ans/> = ${number6}`,
          `<ans/> ${MULT} 10 = ${number7}`
        ]}
        pdfContainerStyle={{ alignItems: 'center' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aJ2',
  description: 'aJ2',
  keywords: ['Multiplication', '10'],
  schema: z.object({
    redRibbonLength: z.number().int().min(5).max(99)
  }),
  simpleGenerator: () => {
    const redRibbonLength = randomIntegerInclusive(5, 99);

    return { redRibbonLength };
  },
  Component: props => {
    const {
      question: { redRibbonLength },
      translate,
      displayMode
    } = props;

    // Answer
    const ans = redRibbonLength * 10;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.theGreenRibbonIsNumTimesAsLongAsTheRedRibbonLengthOfTheGreenRibbonIsAnsCm(
          10
        )}
        title={translate.instructions.completeSentence()}
        testCorrect={[ans.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <>
            <View
              style={{
                alignItems: displayMode === 'digital' ? 'flex-start' : 'center',
                alignSelf: 'flex-start'
              }}
            >
              <Text
                style={{
                  fontSize: displayMode === 'digital' ? 32 : 50
                }}
              >
                {translate.units.numberOfCm(redRibbonLength)}
              </Text>
              {horizontalDoubleEndedArrow(
                dimens.width / 10,
                displayMode === 'digital' ? 2 : 4,
                colors.prussianBlue
              )}
              <View style={{ marginTop: 8 }}>
                <AssetSvg name={'Ribbons/RibbonRed1-10'} width={dimens.width / 10} />
              </View>
            </View>
            <View style={{ alignItems: 'center', alignSelf: 'flex-start', marginTop: 32 }}>
              <AssetSvg name={'RibbonGreen'} width={dimens.width} />
            </View>
          </>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aJ3',
  description: 'aJ3',
  keywords: ['Multiplication', '10'],
  schema: z.object({
    number1: z.number().int().min(1).max(100),
    number2: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 100);
    const number2 = randomIntegerInclusive(1, 99);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = 2 * 5 * number2;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[number1.toString()], [number2.toString()]]}
        sentences={[
          `${number1} ${MULT} 10 = 10 ${MULT} <ans/>`,
          `${number3} = 2 ${MULT} 5 ${MULT} <ans/>`
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy10',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
