import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import { countRange } from '../../../../utils/collections';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const shortObjects = [
  'Pencil',
  'Book',
  'Rubber',
  'Pen',
  'Crayon',
  'paperclip',
  'glueStick',
  'sharpener'
] as const;
const tallObjects = ['door', 'lampPost', 'swing', 'House', 'bus', 'giraffe', 'tractor'] as const;
const allObjects = [...shortObjects, ...tallObjects] as const;
type Objects = (typeof allObjects)[number];

const Question1 = newQuestionContent({
  uid: 'bjv',
  description: 'bjv',
  keywords: ['Length', 'Height', 'Metres', 'Shorter', 'Taller'],
  schema: z.object({
    objects: z.array(z.enum(allObjects)).length(4)
  }),
  simpleGenerator: () => {
    const numShortObjects = randomIntegerInclusive(1, 3);
    const shortObjs = getRandomSubArrayFromArray(shortObjects, numShortObjects);
    const tallObjs = getRandomSubArrayFromArray(tallObjects, 4 - numShortObjects);

    return { objects: shuffle([...shortObjs, ...tallObjs]) };
  },
  Component: ({ question: { objects }, translate }) => {
    const objectAsString = (obj: Objects) => {
      switch (obj) {
        case 'door':
        case 'glueStick':
        case 'giraffe':
        case 'lampPost':
        case 'paperclip':
        case 'swing':
        case 'sharpener':
        case 'tractor':
          return translate.items[obj](1);
        case 'Book':
        case 'Crayon':
        case 'Pen':
        case 'Pencil':
        case 'Rubber':
          return translate.objects[obj]();
        case 'House':
          return translate.places[obj]();
        case 'bus':
          return translate.transport[obj]();
      }
    };

    const short = shortObjects.filter(obj => objects.includes(obj));
    const tall = tallObjects.filter(obj => objects.includes(obj));
    const correctOrder = [short, tall];
    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragCardsToSortThemIntoTheTable()}
        pdfTitle={translate.ks1PDFInstructions.sortTheCardsIntoTheTable()}
        zoneNames={[
          translate.ks1MiscStrings.shorterThan1Metre(),
          translate.ks1MiscStrings.tallerThan1Metre()
        ]}
        items={objects.map(obj => ({
          component: translate.ks1MiscStrings.xToLowercase(objectAsString(obj)),
          value: obj
        }))}
        testCorrect={correctOrder}
        questionHeight={900}
        itemVariant="rectangle"
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bjw',
  description: 'bjw',
  keywords: ['Length', 'Height', 'Measure', 'Metres', 'Metre stick', 'Tall', 'Long'],
  questionHeight: 1000,
  schema: z
    .object({
      length: z.number().int().min(1).max(4),
      isVertical: z.boolean(),
      object: z.enum([
        'lion',
        'car',
        'table',
        'cow',
        'wardrobe',
        'door',
        'tree',
        'shed',
        'giraffe',
        'chair'
      ])
    })
    .refine(
      val =>
        (val.object === 'chair' && val.length === 1) ||
        (['shed', 'door'].includes(val.object) && isInRange(2, 3)(val.length)) ||
        (val.object === 'car' && isInRange(2, 4)(val.length)) ||
        val.length <= 4
    ),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();

    const object = isVertical
      ? getRandomFromArray(['wardrobe', 'door', 'tree', 'shed', 'giraffe', 'chair'] as const)
      : getRandomFromArray(['lion', 'car', 'table', 'cow', 'shed'] as const);

    const length =
      object === 'chair'
        ? 1
        : ['shed', 'door'].includes(object)
        ? randomIntegerInclusive(2, 3)
        : object === 'car'
        ? randomIntegerInclusive(2, 4)
        : randomIntegerInclusive(1, 4);

    return { object, length, isVertical };
  },
  Component: props => {
    const {
      question: { object, length, isVertical },
      translate
    } = props;

    const random = seededRandom(props.question);

    const svgName = (() => {
      switch (object) {
        case 'wardrobe':
          return 'Wardrobe';
        case 'door':
          return 'Door';
        case 'tree':
          return getRandomFromArray(['Tree_A', 'Tree_B'] as const, { random });
        case 'shed':
          return 'Shed';
        case 'giraffe':
          return 'Giraffe';
        case 'chair':
          return 'ChairPink';
        case 'lion':
          return 'Lion_side_profile';
        case 'car':
          return 'Toy_car';
        case 'table':
          return 'Desk';
        case 'cow':
          return 'Cow';
      }
    })();

    const title = {
      chair: 'whatIsTheHeightOfTheChair',
      car: 'whatIsTheLengthOfTheCar',
      cow: 'whatIsTheLengthOfTheCow',
      door: 'whatIsTheHeightOfTheDoor',
      giraffe: 'whatIsTheHeightOfTheGiraffe',
      lion: 'whatIsTheLengthOfTheLion',
      shed: isVertical ? 'whatIsTheHeightOfTheShed' : 'whatIsTheLengthOfTheShed',
      table: 'whatIsTheLengthOfTheTable',
      tree: 'whatIsTheHeightOfTheTree',
      wardrobe: 'whatIsTheHeightOfTheWardrobe'
    } as const;

    return (
      <QF1ContentAndSentence
        questionHeight={1000}
        title={translate.ks1Instructions[title[object]]()}
        sentence={translate.ks1AnswerSentences.ansM()}
        testCorrect={[length.toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end', paddingLeft: 8 }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            vertical={isVertical}
            maxScaleFactor={2}
            rulerKind="m10Part"
            rulerLength={length}
            items={[
              {
                length,
                svgInfo: { name: svgName },
                guidelines: true
              }
            ]}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjx',
  description: 'bjx',
  keywords: ['Taller', 'Shorter', 'Longer', 'Length', 'Compare', 'Centimetres'],
  schema: z.object({
    number: z.number().int().min(1).max(20),
    useUnits: z.array(z.boolean()).length(2),
    answer: z.enum(['short', 'long'])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 20);
    const useUnits = countRange(2).map(_ => getRandomBoolean());
    const answer = getRandomFromArray(['short', 'long'] as const);
    return { number, useUnits, answer };
  },
  Component: props => {
    const {
      question: { number, useUnits, answer },
      translate
    } = props;

    const numM = useUnits[0]
      ? translate.units.numberOfM(number)
      : translate.units.numberOfMetres(number);
    const numCm = useUnits[1]
      ? translate.units.numberOfCm(number)
      : translate.units.numberOfCentimetres(number);

    const [A, B] = answer === 'short' ? [numCm, numM] : [numM, numCm];

    const items = [
      {
        component: translate.ks1MiscStrings.xToLowercase(translate.misc.Shorter()),
        value: 'short'
      },
      {
        component: translate.ks1MiscStrings.xToLowercase(
          translate.misc.Longer().toLocaleLowerCase()
        ),
        value: 'long'
      }
    ];

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useACardToCompleteSentence()}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        sentence={translate.ks1AnswerSentences.xIsAnsThanY(A, B)}
        sentencesStyle={{ alignItems: 'flex-start' }}
        testCorrect={[answer]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInMetres',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
