import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import NoKeyboardTextInput from '../atoms/NoKeyboardTextInput';
import { projectSetState, SetState } from '../../utils/react';
import TextStructure from './TextStructure';
import { parseMarkup } from '../../markup';
import { useCallback } from 'react';
import { withStateHOC } from '../../stateTree';
import { filledArray } from '../../utils/collections';

type Props<UserAnswer extends string[]> = {
  value: UserAnswer;
  onValueChanged: SetState<UserAnswer>;
  /**
   * Sentence to display on the screen, using '<ans/>' to denote a place where an answer text input should go.
   *
   * See {@link parseMarkup} for more information about this syntax.
   */
  sentence: string;
  /** Whether the auto-focus the first text input. Default: false. inputMethod==='textInput' only */
  autoFocus?: boolean;
  inputMaxCharacters?: number;
  inputStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
};

/**
 * Number sentence, with text input boxes to fill in the blanks.
 *
 * A one-line sentence is 96px tall.
 */
export default function CompleteTheSentence<UserAnswer extends string[]>({
  value,
  onValueChanged,
  sentence,
  autoFocus = false,
  inputMaxCharacters,
  inputStyle,
  style,
  textStyle,
  fractionContainerStyle,
  fractionDividerStyle,
  fractionTextStyle
}: Props<UserAnswer>) {
  return (
    <TextStructure
      sentence={sentence}
      fractionContainerStyle={fractionContainerStyle}
      fractionDividerStyle={fractionDividerStyle}
      fractionTextStyle={fractionTextStyle}
      inputBox={useCallback(
        ({ index }: { index: number }) => (
          <NoKeyboardTextInput
            key={index}
            style={inputStyle}
            value={value[index]}
            onChangeText={projectSetState(onValueChanged, index)}
            autoFocus={autoFocus && index === 0}
            maxCharacters={inputMaxCharacters}
          />
        ),
        [autoFocus, inputMaxCharacters, inputStyle, onValueChanged, value]
      )}
      style={style}
      textStyle={textStyle}
    />
  );
}

/** See {@link CompleteTheSentence}. */
export const CompleteTheSentenceWithState = withStateHOC(CompleteTheSentence, {
  stateProp: 'value',
  setStateProp: 'onValueChanged',
  defaults: props => ({
    testComplete: state => state.every(it => it !== ''),
    defaultState: filledArray('', parseMarkup(props.sentence).numberOfAns)
  })
});
