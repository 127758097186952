import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  filledArray,
  multisetCount,
  NonEmptyArray,
  sortNumberArray,
  sumNumberArray
} from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import { ADD } from '../../../../constants';
import {
  displayMoney,
  possibleMoneyDenominations,
  PossibleMoneyDenominations,
  totalPenceToPoundsAndPence
} from '../../../../utils/money';
import { isInRange } from '../../../../utils/matchers';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bif',
  description: 'bif',
  keywords: ['Coins', 'Notes', 'Pounds', 'Pence', 'Money'],
  schema: z.object({
    combinationOfMoney: z
      .array(numberEnum(possibleMoneyDenominations))
      .refine(
        combinationOfMoney => isInRange(200, 10000)(sumNumberArray(combinationOfMoney)),
        'Total must be between £2 and £100'
      )
  }),
  simpleGenerator: () => {
    const sortCoinsDescending = getRandomFromArrayWithWeights([true, false], [3, 1]);
    const { orderedCombinationOfMoney } = rejectionSample(
      () => {
        const combinationOfMoney: PossibleMoneyDenominations[] = [];

        const numberOfCoinsOrNotes = randomIntegerInclusive(3, 8);

        for (let i = 0; i < numberOfCoinsOrNotes; i++) {
          let availableValues: PossibleMoneyDenominations[];
          if (i === 0) {
            availableValues = possibleMoneyDenominations.filter(num => num < 100);
          } else if (i === 1) {
            availableValues = possibleMoneyDenominations.filter(num => num > 100);
          } else {
            availableValues = [...possibleMoneyDenominations];
          }
          combinationOfMoney.push(
            getRandomFromArray(availableValues as NonEmptyArray<PossibleMoneyDenominations>)
          );
        }
        const groupsOfCoins = Array.from(multisetCount(combinationOfMoney)).map(([value, amount]) =>
          filledArray<PossibleMoneyDenominations>(value as PossibleMoneyDenominations, amount)
        );
        const orderedCombinationOfMoney: PossibleMoneyDenominations[] = sortCoinsDescending
          ? sortNumberArray(combinationOfMoney, 'descending')
          : shuffle(groupsOfCoins).flat();
        return { orderedCombinationOfMoney };
      },
      ({ orderedCombinationOfMoney }) =>
        isInRange(200, 10000)(sumNumberArray(orderedCombinationOfMoney))
    );

    return {
      combinationOfMoney: orderedCombinationOfMoney
    };
  },
  Component: props => {
    const {
      question: { combinationOfMoney },
      translate,
      displayMode
    } = props;
    const denominations = combinationOfMoney.map(number => totalPenceToPoundsAndPence(number)[0]);

    const totalMoney = sumNumberArray(combinationOfMoney);

    const answerPounds = Math.floor(number(math.evaluate(`${totalMoney} / 100`))).toString();
    const answerPence = number(math.evaluate(`${totalMoney} % 100`)).toString();

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchMoneyIsThere()}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              {
                flexDirection: 'row',
                gap: 30,
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center'
              }
            ]}
          >
            {displayMoney(
              denominations,
              displayMode === 'digital' ? 96 : 120,
              displayMode === 'digital' ? 96 : 120,
              true,
              true
            )}
          </View>
        )}
        sentence={translate.ks1AnswerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[answerPounds, answerPence]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'big',
  description: 'big',
  keywords: ['Pounds', 'Pence', 'Money', 'Add'],
  schema: z.object({
    pence: z.array(numberEnum([1, 2, 5, 10, 20, 50])),
    pounds: z.array(numberEnum([100, 200, 500, 1000, 2000, 5000])),
    isAnswerFirst: z.boolean(),
    orderedCombinationOfMoney: z.array(
      numberEnum([1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000])
    )
  }),
  simpleGenerator: () => {
    const isAnswerFirst = getRandomBoolean();
    const numberOfPounds = randomIntegerInclusive(1, 3);
    const numberOfPence = 4 - numberOfPounds;

    const sortMoneyDescending = getRandomFromArrayWithWeights([true, false], [3, 1]);

    const { orderedCombinationOfMoney, pence, pounds } = rejectionSample(
      () => {
        const pounds = filledArray(
          getRandomFromArray([100, 200, 500, 1000, 2000, 5000] as const),
          numberOfPounds
        );
        const pence = filledArray(
          getRandomFromArray([1, 2, 5, 10, 20, 50] as const),
          numberOfPence
        );

        const combinationOfMoney = [...pounds, ...pence];

        const orderedCombinationOfMoney = sortMoneyDescending
          ? sortNumberArray(combinationOfMoney, 'descending')
          : shuffle(combinationOfMoney);

        return { orderedCombinationOfMoney, pence, pounds };
      },
      ({ pence, pounds }) => sumNumberArray(pence) < 100 && sumNumberArray(pounds) < 10000
    );

    return { isAnswerFirst, orderedCombinationOfMoney, pence, pounds };
  },
  Component: props => {
    const {
      question: { isAnswerFirst, orderedCombinationOfMoney, pence, pounds },
      translate
    } = props;

    const moneyStrings = orderedCombinationOfMoney
      .map(num => totalPenceToPoundsAndPence(num))
      .flat();

    const sentence = isAnswerFirst
      ? `${moneyStrings[0]} ${ADD} ${moneyStrings[1]} ${ADD} ${moneyStrings[2]} ${ADD} ${
          moneyStrings[3]
        } = £<ans/> ${translate.ks1MiscStrings.and()} <ans/>p`
      : `£<ans/> ${translate.ks1MiscStrings.and()} <ans/>p = ${moneyStrings[0]} ${ADD} ${
          moneyStrings[1]
        } ${ADD} ${moneyStrings[2]} ${ADD} ${moneyStrings[3]}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        testCorrect={[(sumNumberArray(pounds) / 100).toString(), sumNumberArray(pence).toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bih',
  description: 'bih',
  keywords: ['Pounds', 'Pence', 'Money', 'Add'],
  schema: z.object({
    pence: z.array(numberEnum([1, 2, 5, 10, 20, 50])),
    pounds: z.array(numberEnum([100, 200, 500, 1000, 2000, 5000])),
    isAnswerFirst: z.boolean(),
    orderedCombinationOfMoney: z.array(
      numberEnum([1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000])
    ),
    missingIndexes: z.array(z.number().int().min(0).max(4))
  }),
  simpleGenerator: () => {
    const isAnswerFirst = getRandomBoolean();
    const numberOfPounds = randomIntegerInclusive(1, 3);
    const numberOfPence = 4 - numberOfPounds;

    const sortMoneyDescending = getRandomFromArrayWithWeights([true, false], [3, 1]);
    const numberOfMissingIndex = getRandomFromArrayWithWeights([1, 2], [3, 1]);

    const { orderedCombinationOfMoney, pence, pounds } = rejectionSample(
      () => {
        const pounds = filledArray(
          getRandomFromArray([100, 200, 500, 1000, 2000, 5000] as const),
          numberOfPounds
        );

        const pence = filledArray(
          getRandomFromArray([1, 2, 5, 10, 20, 50] as const),
          numberOfPence
        );

        const combinationOfMoney = [...pounds, ...pence];

        const orderedCombinationOfMoney = sortMoneyDescending
          ? sortNumberArray(combinationOfMoney, 'descending')
          : shuffle(combinationOfMoney);

        return { orderedCombinationOfMoney, pence, pounds };
      },
      ({ pence, pounds }) => sumNumberArray(pence) < 100 && sumNumberArray(pounds) < 10000
    );

    const penceIndices = orderedCombinationOfMoney
      .map((value, index) => value < 100 && index)
      .filter(index => index !== false);

    const poundsIndices = orderedCombinationOfMoney
      .map((value, index) => value >= 100 && index)
      .filter(index => index !== false);

    const missingIndexes =
      numberOfMissingIndex === 1
        ? [getRandomFromArray([...penceIndices, ...poundsIndices]) as number]
        : [getRandomFromArray(penceIndices) as number, getRandomFromArray(poundsIndices) as number];

    return { isAnswerFirst, orderedCombinationOfMoney, pence, pounds, missingIndexes };
  },
  Component: props => {
    const {
      question: { isAnswerFirst, orderedCombinationOfMoney, pence, pounds, missingIndexes },
      translate
    } = props;

    const moneyStrings = orderedCombinationOfMoney
      .map((num, index) =>
        missingIndexes.includes(index)
          ? `${num >= 100 ? '£' : ''}<ans/>${num < 100 ? 'p' : ''}`
          : totalPenceToPoundsAndPence(num)
      )
      .flat();

    const sentence = isAnswerFirst
      ? `${moneyStrings[0]} ${ADD} ${moneyStrings[1]} ${ADD} ${moneyStrings[2]} ${ADD} ${
          moneyStrings[3]
        } = £${(
          sumNumberArray(pounds) / 100
        ).toString()} ${translate.ks1MiscStrings.and()} ${sumNumberArray(pence).toString()}p`
      : `£${(
          sumNumberArray(pounds) / 100
        ).toString()} ${translate.ks1MiscStrings.and()} ${sumNumberArray(pence).toString()}p = ${
          moneyStrings[0]
        } ${ADD} ${moneyStrings[1]} ${ADD} ${moneyStrings[2]} ${ADD} ${moneyStrings[3]}`;

    const answer = orderedCombinationOfMoney
      .filter((_, index) => missingIndexes.includes(index))
      .map(num => (num < 100 ? num.toString() : (num / 100).toString()));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        inputMaxCharacters={2}
        testCorrect={answer}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountMoneyPoundsAndPence',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
