import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { names, nameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF31aInteractiveNumberGridAndSelectables from '../../../../components/question/questionFormats/QF31aInteractiveNumberGridAndSelectables';
import { numberEnum } from '../../../../utils/zod';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { multiplesNumberTrackArray } from '../../../../utils/multiples';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ajW',
  description: 'ajW',
  keywords: ['Multiple', 'Track', '5', '10'],
  schema: z.object({
    startingNumber: z.number().int().min(5).max(50).multipleOf(5),
    step: numberEnum([5, 10])
  }),
  simpleGenerator: () => {
    const step = getRandomFromArray([5, 10] as const);
    const startingNumber = randomIntegerInclusiveStep(step, 50, step);
    return { startingNumber, step };
  },
  Component: ({ question: { startingNumber, step }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(
      startingNumber,
      step,
      step === 5 ? 7 : 3
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'anZ',
  description: 'anZ',
  keywords: ['Multiple', '5', '10', '100 square'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(70)).length(6),
    preshade: numberEnum([5, 10]),
    startNumber: numberEnum([1, 11, 21, 31, 41])
  }),
  simpleGenerator: () => {
    const preshade = getRandomFromArray([5, 10] as const);
    const startNumber = getRandomFromArray([1, 11, 21, 31, 41] as const);

    const commonMultiple = randomIntegerInclusiveStep(startNumber + 9, startNumber + 29, 10);

    const randomNumbers = randomUniqueIntegersInclusive(startNumber, startNumber + 29, 5, {
      constraint: x => commonMultiple !== x
    });

    const numbers = [commonMultiple, ...randomNumbers] as number[];
    return { preshade, startNumber, numbers: shuffle(numbers) };
  },
  Component: ({ question: { preshade, startNumber, numbers }, translate, displayMode }) => {
    const multiple1 = range(startNumber + preshade - 1, startNumber + 29, preshade);

    const commonMultiples = [] as number[];

    // Get the common multiples (all multiples of 10 are common with 5)
    numbers.forEach(number => {
      if (number % 10 === 0) {
        commonMultiples.push(number);
      }
    });

    return (
      <QF31aInteractiveNumberGridAndSelectables
        title={translate.instructions.theGridHasMultiplesOfXShadedShadeMultiplesOfY(
          preshade,
          preshade === 5 ? 10 : 5
        )}
        pdfTitle={translate.instructions.hereIsAGridWithMultiplesOfXShadedInCircleMultiplesOfY(
          preshade,
          preshade === 5 ? 10 : 5
        )}
        startNumber={startNumber}
        finishNumber={startNumber + 29}
        preshaded={multiple1}
        items={numbers}
        testCorrect={commonMultiples}
        sentence={
          displayMode !== 'digital'
            ? translate.instructions.whichNumsAreMultsOfXAndY(5, 10)
            : translate.instructions.selectNumbersThatAreMultiplesOfBothXAndY(5, 10)
        }
        questionHeight={1050}
      />
    );
  },
  questionHeight: 1050
});

const Question3 = newQuestionContent({
  uid: 'ajX',
  description: 'ajX',
  keywords: ['Multiple', '5', '10'],
  schema: z
    .object({
      numberArray: z.number().int().min(0).max(50).array().length(8),
      name1: nameSchema,
      name2: nameSchema
    })
    .refine(val => {
      const oddMultsOf5 = val.numberArray.filter(number => number % 5 === 0 && number % 10 !== 0);
      const multsOf10 = val.numberArray.filter(number => number % 10 === 0);
      return oddMultsOf5.length > 1 && multsOf10.length > 1;
    }, 'numberArray must contain at least two odd multiples of 5 and two multiples of 10.')
    .refine(
      val => arrayHasNoDuplicates(val.numberArray),
      'All numbers in numberArray must be different.'
    )
    .refine(val => val.name1 !== val.name2, 'Chosen names must be different.'),
  simpleGenerator: () => {
    // Create two odd multiples of 5.
    const [number1, number2] = randomUniqueIntegersInclusiveStep(5, 45, 10, 2);

    // Create two multiples of 10.
    const [number3, number4] = randomUniqueIntegersInclusiveStep(0, 50, 10, 2);

    // Create four other numbers.
    const [number5, number6, number7, number8] = randomUniqueIntegersInclusive(0, 50, 4, {
      constraint: x => x !== number1 && x !== number2 && x !== number3 && x !== number4
    });

    const numberArray = shuffle([
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8
    ]);

    const [name1, name2] = getRandomSubArrayFromArray([...names.male, ...names.female], 2);

    return { numberArray, name1, name2 };
  },
  Component: props => {
    const {
      question: { numberArray, name1, name2 },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.char1CountsIn5sChar2CountsIn10s(name1, name2)}
        testCorrect={numberArray.filter(it => it % 10 === 0)}
        items={numberArray.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'ajY',
  description: 'ajY',
  keywords: ['Multiple', '5', '10'],
  schema: z.object({
    numbers: z.array(z.number().int().min(5).max(50).multipleOf(5)).length(8)
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusiveStep(5, 50, 5, 8);

    return { numbers };
  },
  Component: ({ question: { numbers }, translate }) => {
    const multiple5: number[] = [];
    const multipleBoth: number[] = [];
    const multiple10: number[] = [];
    // All multiples of 10 are multiples of 5.
    // Need an array for the multiple of 10 even though it is empty, otherwise it will crash when validating the answer.

    numbers.forEach(number => {
      if (number % 10 === 0) {
        multipleBoth.push(number);
      } else {
        multiple5.push(number);
      }
    });

    const correctOrder = [multiple5, multipleBoth, multiple10];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsSortMultiplesOfXAndY(5, 10)}
        pdfTitle={translate.instructions.sortMultiplesOfXAndYPDF(5, 10)}
        zoneNames={[
          translate.instructions.multiplesOfXOnly(5),
          translate.instructions.multiplesOfXAndY(5, 10),
          translate.instructions.multiplesOfXOnly(10)
        ]}
        items={numbers}
        testCorrect={correctOrder}
        questionHeight={900}
        pdfItemVariant="pdfSquare"
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ajZ',
  description: 'ajZ',
  keywords: ['5', '10', 'Multiple'],
  schema: z.object({
    digits: z.array(z.number().int().min(0).max(9)).length(6),
    multipleOf5Or10: numberEnum([5, 10])
  }),
  simpleGenerator: () => {
    const multipleOf5Or10 = getRandomFromArray([5, 10] as const);

    const digits = [0, 5];

    const extras = randomUniqueIntegersInclusive(1, 9, 4, {
      constraint: x => !digits.includes(x)
    });
    digits.push(...extras);

    return { digits, multipleOf5Or10 };
  },
  Component: props => {
    const {
      question: { digits, multipleOf5Or10 },
      translate
    } = props;

    const testCorrect = (ans: readonly (string | undefined)[]): boolean => {
      const userAnswer = parseInt(ans.join(''));
      return multipleOf5Or10 === 5
        ? userAnswer % multipleOf5Or10 === 0 && userAnswer % 10 !== 0
        : userAnswer % 10 === 0;
    };

    return (
      <QF37SentenceDrag
        title={
          multipleOf5Or10 === 5
            ? translate.instructions.dragCardsToMakeMultipleOf5Not10()
            : translate.instructions.dragCardsToMakeMultipleOf5And10()
        }
        pdfTitle={
          multipleOf5Or10 === 5
            ? translate.instructions.useCardsToMakeMultipleOf5Not10EachNumberCanOnlyBeUsedOnce()
            : translate.instructions.useCardsToMakeMultipleOf5And10EachNumberCanOnlyBeUsedOnce()
        }
        items={digits.map(num => num.toLocaleString())}
        sentence="<ans/> <ans/> <ans/>"
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answerText:
            multipleOf5Or10 === 5
              ? translate.markScheme.anyMultipleOfXAndNotYUsingAvailCards(5, 10)
              : translate.markScheme.anyMultipleOfXAndYUsingAvailCards(5, 10)
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aj0',
  description: 'aj0',
  keywords: ['Multiple', '5', '10'],
  schema: z.object({
    multipleOf: z.enum(['only 5', 'only 10', 'both']),
    number: z.number().int().min(0).max(999)
  }),
  simpleGenerator: () => {
    // Determines which version of the question to ask, i.e. what Tiny thinks the number is a multiple of.
    const multipleOf = getRandomFromArray(['only 5', 'only 10', 'both'] as const);

    // Allow a 50-50 chance of number being a multiple of whichever multipleOf chooses.
    const isDivisible = getRandomFromArray([true, false]);

    const number = randomIntegerInclusive(0, 999, {
      constraint: x => {
        switch (multipleOf) {
          case 'only 5':
            return isDivisible ? x % 5 === 0 && x % 10 !== 0 : x % 5 !== 0;
          case 'only 10':
            return isDivisible ? x % 10 === 0 : x % 10 !== 0;
          case 'both':
            return isDivisible ? x % 5 === 0 && x % 10 === 0 : x % 5 !== 0;
        }
      }
    });

    return { multipleOf, number };
  },
  Component: props => {
    const {
      question: { multipleOf, number },
      translate,
      displayMode
    } = props;

    const getTitle = () => {
      if (multipleOf === 'both') {
        return translate.instructions.tinySaysXIsAMultOf5And10(number.toLocaleString());
      } else if (multipleOf === 'only 5') {
        return translate.instructions.tinySaysXIsAMultOf5AndNot10(number.toLocaleString());
      } else {
        return translate.instructions.tinySaysXIsAMultOf10AndNot5(number.toLocaleString());
      }
    };

    const testCorrect = () => {
      if (multipleOf === 'both') {
        return number % 5 === 0 && number % 10 === 0 ? ['Yes'] : ['No'];
      } else if (multipleOf === 'only 5') {
        return number % 5 === 0 && number % 10 !== 0 ? ['Yes'] : ['No'];
      } else {
        return ['No'];
      }
    };

    return (
      <QF11SelectImagesUpTo4
        title={`${getTitle()}<br/>${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${getTitle()}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={testCorrect()}
        numItems={2}
        itemStyle={{ height: 150, alignSelf: 'flex-end' }}
        renderItems={() => [
          {
            value: 'Yes',
            component: (
              <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 40 : 50 }}>
                {translate.misc.Yes()}
              </Text>
            )
          },
          {
            value: 'No',
            component: (
              <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 40 : 50 }}>
                {translate.misc.No()}
              </Text>
            )
          }
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplesOf5And10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
