import { useCallback, useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';
import { Dimens } from '../../../../theme/scaling';
import Text from '../../../typography/Text';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import { colors } from '../../../../theme/colors';
import { parseMarkup } from '../../../../markup';
import TextStructure from '../../../molecules/TextStructure';
import { SetState } from '../../../../utils/react';
import { withStateHOC } from '../../../../stateTree';
import { filledArray } from '../../../../utils/collections';
import { DisplayMode } from '../../../../contexts/displayMode';

type UserAnswer = string;

type Props = {
  userAnswer: UserAnswer[];
  setUserAnswer: SetState<UserAnswer[]>;
  /**
   * @param numerators An array of objects to hold the labels that go
   * on the top of the fraction
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> numerators should return an array of number strings
   */
  numerators: string[];
  /**
   * @param denominators An array of objects to hold the labels that go
   * on the bottom of the fraction
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> denominators should return an array of number strings
   */
  denominators: string[];
  /**
   * @param factors A string array to hold the label for the arrows.
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> factors should return an array of number strings
   */
  factors: string[];
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  /**
   * Flag for which way the arrows should be pointing
   */
  arrowDirection: 'up' | 'down' | 'left' | 'right';
  /**
   * leading symbol in front of input box for the factors
   */
  leadingSymbol?: string;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
};

/**
 * This component renders a fraction equality with arrows either vertically or horizontally across the fractions
 * The <ans/> string is used to annotate where a user answer input field is require.
 * Both the factors and the fractions can have input boxes.
 * The userAnswer array take the form of [answerNumerator, answerDenominator, answerFactorTopOrLeft, answerFactorBottomOrRight]
 */
export const FractionsWithArrows = (props: Props) => {
  const {
    userAnswer = [],
    setUserAnswer = () => {},
    numerators,
    denominators,
    factors,
    dimens: { width, height },
    leadingSymbol,
    arrowDirection,
    textStyle,
    fractionTextStyle
  } = props;
  const displayMode = useContext(DisplayMode);

  // Parse all values to identify answer boxes
  const numeratorsParsed = numerators.map(numerators => parseMarkup(numerators));
  const denominatorsParsed = denominators.map(denominators => parseMarkup(denominators));
  const factorsParsed = factors.map(factors => parseMarkup(factors));

  const styles = useMemo(() => getStyles(width, height), [width, height]);

  // Fraction sentence
  const nom1 =
    numeratorsParsed[0].tokens[0].type === 'text'
      ? `n='${numeratorsParsed[0].tokens[0].value}'`
      : `nAns=''`;
  const nom2 =
    numeratorsParsed[1].tokens[0].type === 'text'
      ? `n='${numeratorsParsed[1].tokens[0].value}'`
      : `nAns=''`;
  const denom1 =
    denominatorsParsed[0].tokens[0].type === 'text'
      ? ` d='${denominatorsParsed[0].tokens[0].value}'`
      : `dAns=''`;
  const denom2 =
    denominatorsParsed[1].tokens[0].type === 'text'
      ? ` d='${denominatorsParsed[1].tokens[0].value}'`
      : `dAns=''`;
  const sentence = `<frac ${nom1} ${denom1}/> = <frac ${nom2} ${denom2}/> `;

  // If there are answer boxes in the factors we need to restrict the amount of room a fraction takes up.
  const isLeftAnswerBox =
    numeratorsParsed[0].numberOfAns > 0 || denominatorsParsed[0].numberOfAns > 0;
  const isRightAnswerBox =
    numeratorsParsed[1].numberOfAns > 0 || denominatorsParsed[1].numberOfAns > 0;
  const maxFractionHeight = isRightAnswerBox || isLeftAnswerBox ? 450 : 70;

  const fraction = (
    <TextStructure
      sentence={sentence}
      fractionContainerStyle={{ maxHeight: maxFractionHeight, height: 96 }}
      inputBox={useCallback(
        ({ index }: { index: number }) => (
          <NoKeyboardTextInput
            value={userAnswer[index]}
            onChangeText={text => {
              const newState = [...userAnswer];
              newState[index] = text;
              setUserAnswer(newState);
            }}
          />
        ),
        [setUserAnswer, userAnswer]
      )}
      fractionTextStyle={[{ fontSize: 40 }, fractionTextStyle]}
      textStyle={[{ fontSize: 40 }, textStyle]}
    />
  );

  // Starting variables for curve
  // These may need to be altered (including the svg width) if we need to increase length or curve of arrow
  const startPointX = 10;
  const endPointX = 700;
  const curve = 300;
  const arrowHalfWidth = 50;

  // Create arrow with head to the right
  const arrowRight = (
    <G key={'arrow'}>
      <Path
        d={`M${startPointX},${curve} C${startPointX},10 ${endPointX},0 ${endPointX},${curve}`}
        fill="none"
        stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
        strokeWidth={30}
      />
      <Path
        d={`M${endPointX - arrowHalfWidth} ${curve - arrowHalfWidth * 0.5} L${
          endPointX + arrowHalfWidth
        } ${curve - arrowHalfWidth * 0.5} L ${endPointX} ${curve + 50} Z`}
        fill={displayMode !== 'digital' ? colors.black : colors.burntSienna}
        stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
      />
    </G>
  );

  // Create arrow with head to the left
  const arrowLeft = (
    <G key={'arrow'}>
      <Path
        d={`M${startPointX + arrowHalfWidth},${curve} C${
          startPointX + arrowHalfWidth
        },10 ${endPointX},0 ${endPointX},${curve}`}
        fill="none"
        stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
        strokeWidth={30}
      />
      <Path
        d={`M${startPointX} ${curve - arrowHalfWidth * 0.5} L${startPointX + 2 * arrowHalfWidth} ${
          curve - arrowHalfWidth * 0.5
        } L ${startPointX + arrowHalfWidth} ${curve + 50} Z`}
        fill={displayMode !== 'digital' ? colors.black : colors.burntSienna}
        stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
      />
    </G>
  );

  const isVertical = arrowDirection === 'up' || arrowDirection === 'down';
  const rightViewBox = `0 0 ${endPointX + arrowHalfWidth} ${curve}`;
  const leftViewBox = `${startPointX} 0 ${endPointX} ${curve}`;

  // Get answer count for input indexing
  const factorAnswersCount = factorsParsed[0].numberOfAns + factorsParsed[1].numberOfAns;
  const answerCount = getAnswerCount(props);

  // Generate the factors to sit above the arrows
  const getFactor = (index: number, leadingSymbol?: string) => {
    const factor = factorsParsed[index];
    const answerIndex = index === 0 ? 1 : factorAnswersCount;
    return factor.numberOfAns > 0 ? (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {leadingSymbol && (
          <View style={isVertical && index === 1 ? {} : { position: 'absolute', left: -40 }}>
            <Text variant="WRN400">{`${leadingSymbol} `}</Text>
          </View>
        )}
        <NoKeyboardTextInput
          value={userAnswer[answerCount - answerIndex]}
          onChangeText={text => {
            const newState = [...userAnswer];
            newState[answerCount - answerIndex] = text;
            setUserAnswer(newState);
          }}
        />
      </View>
    ) : (
      factor.tokens[0].type === 'text' && <Text variant="WRN400">{factor.tokens[0].value}</Text>
    );
  };

  // These can be updated for different use cases but keep the ratio of 120 / 70
  const svgWidth = answerCount === factorAnswersCount ? 86 : 120;
  const svgHeight = answerCount === factorAnswersCount ? 50 : 70;

  // Define padding to centralise the arrow. We need padding to ensure the arrows are central as the markup has padding
  const arrowPadding =
    !isLeftAnswerBox && isRightAnswerBox
      ? isVertical
        ? { paddingRight: 0 }
        : { paddingRight: 70 }
      : isLeftAnswerBox && !isRightAnswerBox
      ? { paddingLeft: 50 }
      : { paddingRight: 14 };

  const factorAStyle = isVertical ? { transform: [{ rotate: '270deg' }] } : {};

  const factorBStyle = isVertical
    ? { transform: [{ rotate: '90deg' }] }
    : { transform: [{ rotate: '180deg' }] };

  const isArrowUpOrRight = arrowDirection === 'up' || arrowDirection === 'right';

  return (
    <View style={isVertical ? styles.containerVertical : styles.containerHorizontal}>
      <View
        style={{
          ...arrowPadding,
          alignItems: 'center',
          flexDirection: isVertical ? 'row' : 'column',
          paddingBottom:
            !isVertical && displayMode !== 'digital' && factorsParsed[0].numberOfAns === 0 ? 50 : 0
        }}
      >
        {getFactor(0, leadingSymbol)}
        <View style={factorAStyle}>
          <Svg
            height={svgHeight}
            width={svgWidth}
            viewBox={isArrowUpOrRight ? rightViewBox : leftViewBox}
            style={{ top: 0, left: 0 }}
            pointerEvents={'none'}
          >
            {isArrowUpOrRight ? arrowRight : arrowLeft}
          </Svg>
        </View>
      </View>
      <View style={{ justifyContent: 'center' }}>{fraction}</View>
      <View
        style={{
          ...arrowPadding,
          alignItems: 'center',
          flexDirection: isVertical ? 'row' : 'column',
          paddingTop:
            !isVertical && displayMode !== 'digital' && factorsParsed[1].numberOfAns === 0 ? 50 : 0
        }}
      >
        <View style={factorBStyle}>
          <Svg
            height={svgHeight}
            width={svgWidth}
            viewBox={isArrowUpOrRight ? leftViewBox : rightViewBox}
            style={{ top: 0, left: 0 }}
            pointerEvents={'none'}
          >
            {isArrowUpOrRight ? arrowLeft : arrowRight}
          </Svg>
        </View>
        {getFactor(1, leadingSymbol)}
      </View>
    </View>
  );
};

export const FractionsWithArrowsWithState = withStateHOC(FractionsWithArrows, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray('', getAnswerCount(props)),
    testComplete: userAnswer => userAnswer.every(i => i !== '')
  })
});

const getAnswerCount = (props: Omit<Props, 'userAnswer' | 'setUserAnswer'>) => {
  const factorsParsed = props.factors
    .map(factors => parseMarkup(factors))
    .filter(i => i.numberOfAns > 0);
  const numeratorsParsed = props.numerators
    .map(numerators => parseMarkup(numerators))
    .filter(i => i.numberOfAns > 0);
  const denominatorsParsed = props.denominators
    .map(denominators => parseMarkup(denominators))
    .filter(i => i.numberOfAns > 0);
  return numeratorsParsed.length + denominatorsParsed.length + factorsParsed.length;
};

const getStyles = (width: number, height: number) =>
  StyleSheet.create({
    containerHorizontal: {
      width: width,
      height: height,
      alignItems: 'center',
      justifyContent: 'center'
    },
    containerVertical: {
      flexDirection: 'row',
      width: width,
      height: height,
      justifyContent: 'center'
    }
  });
