import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { ADD } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { createHundredSquareShape } from '../../../../utils/shapes';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { roundToTheNearest } from '../../../../utils/math';
import { NumberLineVariableTickWithState } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ahq',
  description: 'ahq',
  keywords: ['Complement', '100', 'Hundred', '100 square'],
  schema: z.object({
    number1: z.number().int().min(6).max(94)
  }),
  questionHeight: 1024,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(6, 94);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const shape = createHundredSquareShape(number1);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.shadedSquares(number1)}
        sentence={translate.answerSentences.howManySquaresAreNotShaded()}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
        testCorrect={[(100 - number1).toString()]}
        pdfDirection="column"
        questionHeight={1024}
        {...props}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ahr',
  description: 'ahr',
  keywords: ['Complement', '100', 'Hundred', 'Match'],
  schema: z.object({
    A1: z.number().int().min(1).max(99),
    A2: z.number().int().min(1).max(99),
    A3: z.number().int().min(1).max(99),
    A4: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const base = randomIntegerInclusive(1, 9) * 10;

    const [A1, A2, A3, A4] = randomUniqueIntegersInclusive(base, base + 9, 4);

    return { A1, A2, A3, A4 };
  },
  Component: props => {
    const {
      question: { A1, A2, A3, A4 },
      translate
    } = props;
    const answerOptions = [100 - A1, 100 - A2, 100 - A3, 100 - A4].sort((a, b) => a - b);

    const sentences = [
      {
        sentence: `${A1.toLocaleString()} ${ADD} <ans /> = ${(100).toLocaleString()}`,
        answer: 100 - A1
      },
      {
        sentence: `${A2.toLocaleString()} ${ADD} <ans /> = ${(100).toLocaleString()}`,
        answer: 100 - A2
      },
      {
        sentence: `${A3.toLocaleString()} ${ADD} <ans /> = ${(100).toLocaleString()}`,
        answer: 100 - A3
      },
      {
        sentence: `${A4.toLocaleString()} ${ADD} <ans /> = ${(100).toLocaleString()}`,
        answer: 100 - A4
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMakeComplementsTo100()}
        pdfTitle={translate.instructions.useCardsMakeComplementsTo100EachNumberOnlyUsedOnce()}
        actionPanelVariant="end"
        pdfLayout="itemsRight"
        pdfSentencesStyle={{ justifyContent: 'center', rowGap: 16 }}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'ahs',
  description: 'ahs',
  keywords: ['Complement', '100', 'Hundred', 'Match'],
  schema: z.object({
    A1: z.number().int().min(1).max(99),
    A2: z.number().int().min(1).max(99),
    A3: z.number().int().min(1).max(99),
    A4: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const base = randomIntegerInclusive(1, 9);

    const [A1, A2, A3, A4] = randomUniqueIntegersInclusiveStep(base + 10, base + 90, 10, 4);

    return { A1, A2, A3, A4 };
  },
  Component: props => {
    const {
      question: { A1, A2, A3, A4 },
      translate
    } = props;
    const answerOptions = [100 - A1, 100 - A2, 100 - A3, 100 - A4].sort((a, b) => a - b);

    const sentences = [
      {
        sentence: `${A1.toLocaleString()} ${ADD} <ans /> = ${(100).toLocaleString()}`,
        answer: 100 - A1
      },
      {
        sentence: `${A2.toLocaleString()} ${ADD} <ans /> = ${(100).toLocaleString()}`,
        answer: 100 - A2
      },
      {
        sentence: `${A3.toLocaleString()} ${ADD} <ans /> = ${(100).toLocaleString()}`,
        answer: 100 - A3
      },
      {
        sentence: `${A4.toLocaleString()} ${ADD} <ans /> = ${(100).toLocaleString()}`,
        answer: 100 - A4
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMakeComplementsTo100()}
        pdfTitle={translate.instructions.useCardsMakeComplementsTo100EachNumberOnlyUsedOnce()}
        actionPanelVariant="end"
        pdfLayout="itemsRight"
        pdfSentencesStyle={{ justifyContent: 'center', rowGap: 16 }}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aht',
  description: 'aht',
  keywords: ['Complement', '100s', 'Hundred', 'Number line'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(89)
    })
    .refine(val => val.number1 % 10 !== 0, 'number1 must not be a multiple of 10'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 89, {
      constraint: x => x % 10 !== 0
    });

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    const nearest10 = roundToTheNearest(number1, 10, 'up');

    const sentenceAns = 100 - number1;
    const numberLineAns1 = nearest10 - number1;
    const numberLineAns2 = 100 - nearest10;

    const startingNumber = number1;
    const endNumber = 100;

    const tickArray = [
      { label: startingNumber.toLocaleString(), position: startingNumber },
      { label: nearest10.toLocaleString(), position: nearest10 },
      { label: endNumber.toLocaleString(), position: endNumber }
    ];

    const jumpArrowArray = [
      { start: startingNumber, end: nearest10, label: `${ADD}<ans/>` },
      { start: nearest10, end: endNumber, label: `${ADD}<ans/>` }
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useNumberLineToFindComplementTo100()}
        testCorrect={[sentenceAns.toString()]}
        pdfDirection="column"
        sentence={`${number1.toLocaleString()} ${ADD} <ans/> = ${(100).toLocaleString()}`}
        Content={({ dimens }) => (
          <NumberLineVariableTickWithState
            id="numberline"
            dimens={dimens}
            tickValues={tickArray}
            start={startingNumber}
            end={endNumber}
            jumpArrowArray={jumpArrowArray}
            testCorrect={userAnswer =>
              userAnswer[0] === numberLineAns1.toString() &&
              userAnswer[1] === numberLineAns2.toString()
            }
            defaultState={
              displayMode === 'markscheme'
                ? [numberLineAns1.toLocaleString(), numberLineAns2.toLocaleString()]
                : undefined
            }
          />
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ahu',
  description: 'ahu',
  keywords: ['Complement', '100', 'Hundred'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(99),
      number2: z.number().int().min(1).max(99),
      number3: z.number().int().min(1).max(99)
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 cannot be the same')
    .refine(
      val => val.number1 !== 100 - val.number2,
      "number1 and number2's complement cannot be the same"
    )
    .refine(val => val.number1 !== val.number3, 'number1 and number3 cannot be the same')
    .refine(
      val => val.number1 !== 100 - val.number3,
      "number1 and number3's complement cannot be the same"
    )
    .refine(val => val.number2 !== val.number3, 'number2 and number3 cannot be the same')
    .refine(
      val => val.number2 !== 100 - val.number3,
      "number2 and number3's complement cannot be the same"
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99);

    const number2 = rejectionSample(
      () => randomIntegerInclusive(1, 99),
      x => x !== number1 && x !== 100 - number1
    );

    const number3 = rejectionSample(
      () => randomIntegerInclusive(1, 99),
      x => x !== number1 && x !== 100 - number1 && x !== number2 && x !== 100 - number2
    );

    return { number1, number2, number3 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.whatIsComplementTo100ForEachNumber()}
        testCorrect={[
          [(100 - number1).toString()],
          [(100 - number2).toString()],
          [(100 - number3).toString()]
        ]}
        sentences={[
          `${number1.toLocaleString()} ${ADD} <ans/> = ${(100).toLocaleString()}`,
          `${number2.toLocaleString()} ${ADD} <ans/> = ${(100).toLocaleString()}`,
          `${number3.toLocaleString()} ${ADD} <ans/> = ${(100).toLocaleString()}`
        ]}
        {...props}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ahv',
  description: 'ahv',
  keywords: ['Complement', '100', 'Hundred', 'Select'],
  schema: z
    .object({
      numberPairs: z
        .object({
          left: z.number().int().min(1).max(99),
          right: z.number().int().min(1).max(99)
        })
        .array()
        .length(6)
    })
    .refine(
      ({ numberPairs }) =>
        numberPairs.filter(({ left, right }) => left + right !== 100).length >= 1,
      'There must be at least one correct answer'
    ),
  simpleGenerator: () => {
    // Pair A - Total 110, not multiples of 10
    const A1 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    });
    const A2 = 110 - A1;

    // Pair B - Total 90, not multiples of 10
    const B1 = randomIntegerInclusive(1, 89, {
      constraint: x => x % 10 !== 0
    });
    const B2 = 90 - B1;

    // Pair C - Total 99, not multiples of 10
    const C1 = randomIntegerInclusive(1, 98, {
      constraint: x => x % 10 !== 0 && (99 - x) % 10 !== 0
    });
    const C2 = 99 - C1;

    // Pair D - 10s total 90 and 1s the same, not multiples of 10
    const D1Tens = randomIntegerInclusive(0, 8) * 10;
    const D1Ones = randomIntegerInclusive(1, 9, {
      constraint: x => x !== 5
    });
    const D1 = D1Tens + D1Ones;
    const D2 = 90 - D1Tens + D1Ones;

    // Pair E - Total 100, not multiples of 10
    const E1 = randomIntegerInclusive(1, 99, {
      constraint: x => x % 10 !== 0
    });
    const E2 = 100 - E1;

    // Pair F - Total 100, multiples of 10
    const F1 = randomIntegerInclusiveStep(10, 90, 10);
    const F2 = 100 - F1;

    const numberPairs = shuffle([
      { left: A1, right: A2 },
      { left: B1, right: B2 },
      { left: C1, right: C2 },
      { left: D1, right: D2 },
      { left: E1, right: E2 },
      { left: F1, right: F2 }
    ]);

    return {
      numberPairs
    };
  },

  Component: props => {
    const {
      question: { numberPairs },
      translate
    } = props;

    const statements = numberPairs.map(({ left, right }, index) => ({
      value: index,
      sum: left + right,
      text: `${left.toLocaleString()} ${ADD} ${right.toLocaleString()}`
    }));

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichPairsDoNotComplement100()}
        pdfTitle={translate.instructions.whichCalcsDoNotShowComplementsToXCircleAnswer(100)}
        testCorrect={statements
          .filter(statement => statement.sum !== 100)
          .map(statement => statement.value)}
        multiSelect
        items={statements.map(statement => ({
          value: statement.value,
          component: statement.text
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ComplementsTo100',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
