import { isEqual } from '../../../utils/matchers';
import { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { Theme } from '../../../theme';
import { ThingOrFunction, resolveThingOrFunction } from '../../../utils/react';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import { TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import DoubleNumberLine, {
  CompleteDoubleNumberLine
} from '../representations/Number Line/DoubleNumberLine';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { DraggableVariant } from '../../draganddrop/utils';
import TextStructure from '../../molecules/TextStructure';
import { AssetSvg } from '../../../assets/svg';
import { countRange } from '../../../utils/collections';
import { parseMarkup } from '../../../markup';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  bottomTickValues: CompleteDoubleNumberLine;
  topTickValues: CompleteDoubleNumberLine;
  /**
   * If sending in as an array the answers should be [..topRowLeftToRight, ...bottomRowLeftToRight]
   */
  testCorrect: T[] | ((ans: (T | undefined)[]) => boolean);
  initialState?: T[];
  /**
   * These 2 parameters are only necessary if the number line you're rendering won't have a label on the first or last
   * ticks. These are required for calculating the position to place arrows/answer boxes on the number line.
   * @param firstNumber - First number on the number line
   * @param lastNumber - Last number on the number line
   */
  firstNumber?: number;
  lastNumber?: number;
  itemsTextVariant?: keyof Theme['fonts'];
  contentTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;
  items: ThingOrFunction<(T | ItemInfo<T>)[]>;
  pdfItemVariant?: DraggableVariant;
  customBoxHeight?: number;
  customBoxWidth?: number;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: T[]; answerText?: string };
  precedingLinesText?: string[];
  /** optional variation to increase the height of the number line. Defaults to 'small'*/
  size?: 'small' | 'large';
};

/**
 * QF17a is a double number line with draggable items
 */
export default function QF17aCompleteDoubleNumberLineDraggable<T extends string | number>({
  title,
  pdfTitle,
  topTickValues,
  bottomTickValues,
  testCorrect: testCorrectProp,
  initialState: initialStateProp,
  itemsTextVariant = 'WRN700',
  items: itemsProp,
  pdfItemVariant = 'pdfSquare',
  customBoxHeight,
  customBoxWidth,
  contentTextVariant,
  itemsLetterEmWidth,
  itemsMaxLines,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  precedingLinesText,
  size = 'small',
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const length =
    topTickValues.reduce((sum: number, tick) => sum + parseMarkup(String(tick)).numberOfAns, 0) +
    bottomTickValues.reduce((sum: number, tick) => sum + parseMarkup(String(tick)).numberOfAns, 0);
  const initialState = countRange(length).map(i => (initialStateProp ?? [])[i] as T | undefined);

  const testCorrect =
    typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp);

  const items: ItemInfo<T>[] = resolveThingOrFunction(itemsProp).map(item =>
    typeof item === 'object' ? item : { component: item.toLocaleString(), value: item }
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  const draggableInputBox = (index: number, boxWidth: number, boxHeight: number) => (
    // Outer view is required to limit the space that the drag and drop zone will take up
    <View style={{ width: boxWidth }}>
      {displayMode === 'markscheme' && (
        <AssetSvg
          name="True"
          width={50}
          style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
        />
      )}
      <EasyDragAndDropWithSingleZones.ZoneSingle
        style={[
          {
            width: boxWidth,
            minHeight: boxHeight
          },
          displayMode === 'digital' && styles.draggableDimens
        ]}
        id={index}
        key={index}
      />
    </View>
  );

  const draggableSource =
    displayMode === 'digital' ? (
      <DragAndDropSection style={{ padding: 0 }}>
        {items.map((_item, index) => (
          <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
        ))}
      </DragAndDropSection>
    ) : (
      <DragAndDropSectionPDF
        itemsStyle={{ flexDirection: 'row', gap: 16, flexWrap: 'wrap', justifyContent: 'center' }}
      >
        {items.map((_, index) => (
          <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
        ))}
      </DragAndDropSectionPDF>
    );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp;

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="dragandgrop"
        items={items}
        testCorrect={testCorrect}
        textVariant={itemsTextVariant}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={itemsLetterEmWidth}
        variant={pdfItemVariant}
        maxLines={itemsMaxLines}
        moveOrCopy="copy"
        defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              {draggableSource}
              <MeasureView>
                {dimens => (
                  <View style={{ flexDirection: 'row' }}>
                    {precedingLinesText && (
                      <View
                        style={{
                          width: 350,
                          height: 200,
                          alignSelf: 'center',
                          justifyContent: 'space-evenly',
                          bottom: 24
                        }}
                      >
                        {precedingLinesText.map((text, index) => (
                          <TextStructure
                            key={`${precedingLinesText}_${index}`}
                            textVariant="WRN400"
                            textStyle={{
                              textAlign: 'center',
                              fontSize: 50
                            }}
                            sentence={text}
                          />
                        ))}
                      </View>
                    )}
                    <DoubleNumberLine
                      inputBox={draggableInputBox}
                      topTickValues={topTickValues}
                      bottomTickValues={bottomTickValues}
                      customBoxHeight={customBoxHeight}
                      customBoxWidth={customBoxWidth}
                      size={size}
                      dimens={{ height: dimens.height / 3, width: dimens.width * 0.85 }}
                      pdfItemVariant={pdfItemVariant}
                    />
                  </View>
                )}
              </MeasureView>
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="dragandgrop"
      items={items}
      testCorrect={testCorrect}
      defaultState={initialState}
      draggableStyle={styles.draggableDimens}
      textVariant={itemsTextVariant}
      textAutoScale={longestItemTextLength}
      textLetterEmWidth={itemsLetterEmWidth}
      maxLines={itemsMaxLines}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="bottom"
        actionPanelContents={draggableSource}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View style={{ flexDirection: 'row' }}>
                {precedingLinesText && (
                  <View
                    style={{
                      width: 150,
                      justifyContent: 'center'
                    }}
                  >
                    {precedingLinesText.map((text, index) => (
                      <TextStructure
                        key={`${precedingLinesText}_${index}`}
                        textVariant="WRN400"
                        textStyle={{
                          textAlign: 'center',
                          fontSize: 32
                        }}
                        sentence={text}
                      />
                    ))}
                  </View>
                )}
                <DoubleNumberLine
                  inputBox={draggableInputBox}
                  topTickValues={topTickValues}
                  bottomTickValues={bottomTickValues}
                  customBoxHeight={customBoxHeight}
                  customBoxWidth={customBoxWidth}
                  size={size}
                  dimens={{
                    height: dimens.height / 2,
                    width: precedingLinesText ? dimens.width - 150 : dimens.width
                  }}
                />
              </View>
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  draggableDimens: {
    width: 88,
    height: 88
  }
});
