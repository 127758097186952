import { useContext } from 'react';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { DisplayMode } from '../../../contexts/displayMode';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { isNotEqual } from '../../../utils/matchers';
import {
  arraysHaveSameContents,
  countRange,
  filledArray,
  sumNumberArray
} from '../../../utils/collections';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import { tallyImages } from '../representations/Tallies';
import { parseMarkup } from '../../../markup';
import { CustomizableTable } from '../representations/CustomizableTable';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /** Cell headers */
  cellHeaders:
    | string[]
    | { label: string; containerStyle?: StyleProp<ViewStyle>; textStyle?: StyleProp<TextStyle> }[];
  /**
   * Array of an array. The first array covers the number of rows and the second the columns
   * The array accepts a string (text/ans box). Answer box will render a multiple drag/drop zone
   */
  tableData: string[][];
  /** Either an array the tally count, or a function for more complex use cases. */
  testCorrect: number[] | ((state: number[][]) => boolean);
  /** PDF Question Height */
  questionHeight?: number;
  items: (1 | 2 | 3 | 4 | 5)[];
  /** Whether to move or copy items. Default to copy */
  moveOrCopy?: 'move' | 'copy';
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: number[]; answerText?: string };
  columnFlexValues?: number[];
  /* drag drop section capacity */
  capacity?: number;
  droppableContainerStyles?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  /** @deprecated Does not check if tallies use a “5 tally” where possible and it being used on the left */
  useLegacyTallyCheck?: boolean;
};

/**
 * Question Format 7a: QF7aInteractiveTallyChart.
 * Draggable tally chart. Will render tally images as the draggables depending on the draggableItems specified
 */
export default function QF7aInteractiveTallyChart({
  title,
  pdfTitle,
  cellHeaders,
  tableData,
  items: itemsProp,
  moveOrCopy = 'copy',
  testCorrect: testCorrectProp,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  columnFlexValues,
  capacity,
  droppableContainerStyles,
  useLegacyTallyCheck = false,
  ...props
}: Props) {
  if (typeof testCorrectProp === 'function' && customMarkSchemeAnswer === undefined) {
    throw new Error(
      'testCorrect is a function, so you must provide the customMarkSchemeAnswer prop'
    );
  }
  const displayMode = useContext(DisplayMode);

  const items = itemsProp.map(item => tallyImages({ value: item }));

  const getDefaultState = (number: number) => {
    const wholeTally = filledArray(5, Math.floor(number / 5));
    const remainder = number % 5;
    return [...wholeTally, remainder];
  };

  const draggableSource = (
    <DragAndDropSection style={{ padding: 0 }}>
      {items.map((_item, index) => (
        <EasyDragAndDrop.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  const data = tableData.map((row, idx) =>
    row.map(col =>
      parseMarkup(col).numberOfAns > 0 ? (
        <EasyDragAndDrop.ZoneMultiple
          style={[
            {
              width: '100%',
              minHeight: 120,
              borderColor: 'transparent',
              justifyContent: 'flex-start'
            },
            droppableContainerStyles
          ]}
          id={idx}
          key={`${row}_${col}`}
          capacity={capacity}
        />
      ) : (
        col
      )
    )
  );

  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <CustomizableTable
            cellHeaders={cellHeaders}
            tableData={data}
            columnFlexValues={columnFlexValues}
            textStyle={{ fontSize: 40 }}
          />
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  if (displayMode === 'markscheme') {
    let defaultState: number[][];
    if (customMarkSchemeAnswer?.answersToDisplay !== undefined) {
      defaultState = customMarkSchemeAnswer.answersToDisplay.map(x => getDefaultState(x));
    } else {
      const array = testCorrectProp as number[];
      defaultState = array.map(x => getDefaultState(x));
    }

    return (
      <EasyDragAndDrop.ProviderWithState
        id="draganddrop"
        items={items}
        defaultState={defaultState}
        moveOrCopy="copy"
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              <CustomizableTable
                cellHeaders={cellHeaders}
                tableData={data}
                columnFlexValues={columnFlexValues}
                textStyle={{ fontSize: 40 }}
              />
              {customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDrop.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState<number>
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      defaultState={filledArray([], tableData.length)}
      // Complete if at least one zone has at least one item.
      testComplete={isNotEqual(filledArray([], tableData.length))}
      testCorrect={
        typeof testCorrectProp === 'function'
          ? testCorrectProp
          : userAnswer =>
              useLegacyTallyCheck
                ? arraysHaveSameContents(
                    userAnswer.map(i => sumNumberArray(i)),
                    testCorrectProp
                  )
                : tallyCheck(userAnswer, testCorrectProp)
      }
    >
      <BaseLayout
        title={title}
        actionPanelVariant="endWide"
        actionPanelContents={draggableSource}
        mainPanelContents={
          <CustomizableTable
            cellHeaders={cellHeaders}
            tableData={data}
            columnFlexValues={columnFlexValues}
            textStyle={{ fontSize: 32 }}
          />
        }
        {...props}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}

const tallyCheck = (userAnswer: number[][], expectedTotal: number[]) =>
  userAnswer.every((row, i) => {
    const numberOfFives = Math.floor(expectedTotal[i] / 5);
    const arrayOfFives = countRange(numberOfFives).map(() => 5);

    return (
      arrayOfFives.every((_five, index) => row[index] === 5) &&
      sumNumberArray(row) === expectedTotal[i]
    );
  });
