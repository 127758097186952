import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { barModelColors } from '../../../../theme/colors';
import { filledArray } from '../../../../utils/collections';
import {
  randomIntegerInclusive,
  getRandomBoolean,
  getRandomFromArray,
  seededRandom,
  randomIntegerInclusiveStep,
  rejectionSample,
  getRandomSubArrayFromArray
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { compareFloats, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { ADD } from '../../../../constants';
import { all, create, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF17cCompleteTheDoubleNumberLine from '../../../../components/question/questionFormats/QF17cCompleteTheDoubleNumberLine';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aC6',
  description: 'aC6',
  keywords: [
    'Converting units',
    'Bar model',
    'Metres',
    'm',
    'Millimetres',
    'mm',
    'Litres',
    'l',
    'Millilitres',
    'ml'
  ],
  schema: z.object({
    amountOfMeasurement: z.number().int().min(2).max(8),
    isMetres: z.boolean()
  }),
  simpleGenerator: () => {
    const isMetres = getRandomBoolean();
    // For sizing maximum it could be is 7 for mm.
    const amountOfMeasurement = randomIntegerInclusive(2, isMetres ? 7 : 8);
    return { amountOfMeasurement, isMetres };
  },
  questionHeight: 800,
  Component: props => {
    const {
      question: { amountOfMeasurement, isMetres },
      translate
    } = props;

    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const sentence = isMetres
      ? translate.answerSentences.numMEqualsAnsMm(amountOfMeasurement)
      : translate.answerSentences.numLEqualsAnsMl(amountOfMeasurement);

    const numbers = [filledArray(1, amountOfMeasurement), filledArray(1, amountOfMeasurement)];

    const strings = isMetres
      ? [
          filledArray(translate.units.numberOfM(1), amountOfMeasurement),
          [translate.units.numberOfMm(1000), ...filledArray('', amountOfMeasurement - 1)]
        ]
      : [
          filledArray(translate.units.numberOfLitres(1), amountOfMeasurement),
          [translate.units.numberOfMl(1000), ...filledArray('', amountOfMeasurement - 1)]
        ];
    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToHelpCompleteTheConversion()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(amountOfMeasurement * 1000).toString()]}
        sentence={sentence}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            oneFontSize
            total={amountOfMeasurement}
            dimens={dimens}
            rowColors={[color, 'white']}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aC7',
  description: 'aC7',
  keywords: [
    'Converting units',
    'Bar model',
    'Metres',
    'm',
    'Millimetres',
    'mm',
    'Litres',
    'l',
    'Millilitres',
    'ml'
  ],
  schema: z.object({
    amountOfMeasurement: z.number().int().min(2).max(8),
    isMetres: z.boolean()
  }),
  simpleGenerator: () => {
    const isMetres = getRandomBoolean();
    // For sizing maximum it could be is 7 for mm.
    const amountOfMeasurement = randomIntegerInclusive(2, isMetres ? 7 : 8);
    return { amountOfMeasurement, isMetres };
  },
  questionHeight: 800,
  Component: props => {
    const {
      question: { amountOfMeasurement, isMetres },
      translate
    } = props;

    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const sentence = isMetres
      ? translate.answerSentences.numMmEqualsAnsM(amountOfMeasurement * 1000)
      : translate.answerSentences.numMlEqualsAnsL(amountOfMeasurement * 1000);

    const numbers = [filledArray(1, amountOfMeasurement), filledArray(1, amountOfMeasurement)];

    const strings = isMetres
      ? [
          [translate.units.numberOfM(1), ...filledArray('', amountOfMeasurement - 1)],
          filledArray(translate.units.numberOfMm(1000), amountOfMeasurement)
        ]
      : [
          [translate.units.numberOfL(1), ...filledArray('', amountOfMeasurement - 1)],
          filledArray(translate.units.numberOfMl(1000), amountOfMeasurement)
        ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToHelpCompleteTheConversion()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[amountOfMeasurement.toString()]}
        sentence={sentence}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            oneFontSize
            total={amountOfMeasurement}
            dimens={dimens}
            rowColors={['white', color]}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aC8',
  description: 'aC8',
  keywords: [
    'Converting units',
    'Double number line',
    'Number line',
    'Metres',
    'm',
    'Millimetres',
    'mm',
    'Litres',
    'l',
    'Millilitres',
    'ml'
  ],
  schema: z
    .object({
      isMetres: z.boolean(),
      topRowNumbers: z.array(z.number().min(0).max(9.6)),
      answerIndexA: numberEnum([0, 1, 2, 3]),
      answerIndexB: numberEnum([0, 1, 2, 3])
    })
    .refine(
      val => val.answerIndexA !== val.answerIndexB,
      'answer index for each each must be different'
    ),
  simpleGenerator: () => {
    const isMetres = getRandomBoolean();
    const startingNumber = randomIntegerInclusive(0, 9);
    const topRowNumbers = [
      startingNumber,
      startingNumber + 0.2,
      startingNumber + 0.4,
      startingNumber + 0.6
    ];

    const [answerIndexA, answerIndexB] = getRandomSubArrayFromArray([0, 1, 2, 3] as const, 2);

    return { isMetres, topRowNumbers, answerIndexA, answerIndexB };
  },

  Component: props => {
    const {
      question: { isMetres, topRowNumbers, answerIndexA, answerIndexB },
      translate,
      displayMode
    } = props;

    const bottomRowNumbers = topRowNumbers.map(num => num * 1000);

    const measurements = isMetres
      ? [translate.units.metres(2), translate.units.mm()]
      : [translate.units.litres(2), translate.units.ml()];

    const topRowTickValues: string[] = [];
    const bottomRowTickValues: string[] = [];

    topRowNumbers.forEach((num, index) => {
      if (index === answerIndexA) {
        topRowTickValues.push('<ans/>');
      } else {
        topRowTickValues.push(num.toLocaleString());
      }
      if (index !== topRowNumbers.length - 1) {
        topRowTickValues.push('');
      }
    });

    bottomRowNumbers.forEach((num, index) => {
      if (index === answerIndexB) {
        bottomRowTickValues.push('<ans/>');
      } else {
        bottomRowTickValues.push(num.toLocaleString());
      }
      if (index !== bottomRowNumbers.length - 1) {
        bottomRowTickValues.push('');
      }
    });

    return (
      <QF17cCompleteTheDoubleNumberLine
        title={translate.instructions.completeNumberLine()}
        topTickValues={topRowTickValues}
        bottomTickValues={bottomRowTickValues}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], topRowNumbers[answerIndexA]) &&
          compareFloats(userAnswer[1], bottomRowNumbers[answerIndexB])
        }
        extraSymbol="decimalPoint"
        precedingLinesText={[measurements[0], measurements[1]]}
        questionHeight={700}
        allowEmptyTicks
        customFontSize={displayMode === 'digital' ? 32 : 50}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            topRowNumbers[answerIndexA].toLocaleString(),
            bottomRowNumbers[answerIndexB].toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  },
  questionHeight: 700
});

const Question4 = newQuestionContent({
  uid: 'aC9',
  description: 'aC9',
  keywords: [
    'Converting units',
    'Metres',
    'm',
    'Millimetres',
    'mm',
    'Litres',
    'l',
    'Millilitres',
    'ml'
  ],
  schema: z.object({
    units: z.number().min(0.5).max(100000),
    unitToUse: z.enum(['millilitres', 'millimetres', 'metres', 'litres'])
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const unitToUse = getRandomFromArray([
      'millilitres',
      'millimetres',
      'metres',
      'litres'
    ] as const);

    const units =
      unitToUse === 'litres'
        ? randomIntegerInclusive(1, 100)
        : unitToUse === 'metres'
        ? number(math.evaluate(`${randomIntegerInclusive(1, 100)} - 0.5`))
        : unitToUse === 'millimetres'
        ? randomIntegerInclusiveStep(1000, 100000, 1000)
        : randomIntegerInclusiveStep(1100, 99900, 100, { constraint: x => x % 1000 !== 0 });

    return { units, unitToUse };
  },
  Component: props => {
    const {
      question: { units, unitToUse },
      translate
    } = props;

    const sentenceAndAnswer = (() => {
      switch (unitToUse) {
        case 'litres':
          return {
            sentence: translate.answerSentences.numLEqualsAnsMl(units),
            answer: number(math.evaluate(`${units} * 1000`))
          };
        case 'metres':
          return {
            sentence: translate.answerSentences.numMEqualsAnsMm(units),
            answer: number(math.evaluate(`${units} * 1000`))
          };
        case 'millimetres':
          return {
            sentence: translate.answerSentences.numMmEqualsAnsM(units),
            answer: number(math.evaluate(`${units} / 1000`))
          };
        case 'millilitres':
          return {
            sentence: translate.answerSentences.numMlEqualsAnsL(units),
            answer: number(math.evaluate(`${units} / 1000`))
          };
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeConversion()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], sentenceAndAnswer.answer.toString())
        }
        questionHeight={500}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        sentence={sentenceAndAnswer.sentence}
        customMarkSchemeAnswer={{
          answersToDisplay: [sentenceAndAnswer.answer.toLocaleString()]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aDa',
  description: 'aDa',
  keywords: [
    'Comparison',
    'Converting units',
    'Metres',
    'm',
    'Millimetres',
    'mm',
    'Litres',
    'l',
    'Millilitres',
    'ml'
  ],
  schema: z.object({
    questionNumbers: z.array(z.number().min(1).max(4999)),
    numbersToUse: numberEnum([1, 2, 3])
  }),
  simpleGenerator: () => {
    const numbersToUse = getRandomFromArray([1, 2, 3] as const);

    const questionNumbers =
      numbersToUse === 1
        ? [randomIntegerInclusive(1, 4), randomIntegerInclusiveStep(100, 1000, 100)]
        : numbersToUse === 2
        ? [randomIntegerInclusive(1, 9), randomIntegerInclusiveStep(100, 500, 100)]
        : rejectionSample(
            () => {
              const litres = number(math.evaluate(`${randomIntegerInclusive(101, 499)} / 100`));
              const millilitresB = randomIntegerInclusiveStep(200, 900, 100);
              const millilitresC = randomIntegerInclusive(1001, 4999);
              const millilitresD = randomIntegerInclusiveStep(200, 900, 100);
              return [litres, millilitresB, millilitresC, millilitresD];
            },
            // Only permit them if the difference between litres add millilitresB and millilitresC add millilitresD is 1000ml or less
            ([litres, millilitresB, millilitresC, millilitresD]) =>
              Math.abs(litres * 1000 + millilitresB - (millilitresC + millilitresD)) <= 1000
          );

    return {
      numbersToUse,
      questionNumbers
    };
  },
  Component: props => {
    const {
      question: { numbersToUse, questionNumbers },
      translate,
      displayMode
    } = props;

    const statement =
      numbersToUse === 1
        ? {
            sentence: `<frac d='${(5).toLocaleString()}' n='${questionNumbers[0].toLocaleString()}'/> m <ans/> ${translate.units.numberOfMm(
              questionNumbers[1]
            )}`,
            answer: lessThanGreaterThanOrEqualTo(200 * questionNumbers[0], questionNumbers[1])
          }
        : numbersToUse === 2
        ? {
            sentence: `<frac d='${(10).toLocaleString()}' n='${questionNumbers[0].toLocaleString()}'/> l ${ADD} ${translate.units.numberOfMl(
              questionNumbers[1]
            )} <ans/> ${translate.units.numberOfMl(1000)}`,
            answer: lessThanGreaterThanOrEqualTo(
              100 * questionNumbers[0] + questionNumbers[1],
              1000
            )
          }
        : {
            sentence: `${translate.units.numberOfL(
              questionNumbers[0]
            )} ${ADD} ${translate.units.numberOfMl(
              questionNumbers[1]
            )}  <ans/> ${translate.units.numberOfMl(
              questionNumbers[2]
            )} ${ADD} ${translate.units.numberOfMl(questionNumbers[3])}`,
            answer: lessThanGreaterThanOrEqualTo(
              questionNumbers[0] * 1000 + questionNumbers[1],
              questionNumbers[2] + questionNumbers[3]
            )
          };

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsCompleteStatement()}
        pdfTitle={translate.instructions.useInequalitiesToCompleteStatement()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentence={statement.sentence}
        testCorrect={[statement.answer.toString()]}
        sentenceStyle={{ alignItems: 'center', alignSelf: 'center' }}
        actionPanelVariant="end"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        moveOrCopy="move"
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aDa2',
  description: 'aDa',
  keywords: [
    'Comparison',
    'Converting units',
    'Metres',
    'm',
    'Millimetres',
    'mm',
    'Litres',
    'l',
    'Millilitres',
    'ml'
  ],
  schema: z.object({
    questionNumbers: z.array(z.number().min(1).max(4999)).length(2)
  }),
  simpleGenerator: () => {
    const questionNumbers = [
      randomIntegerInclusive(1, 4),
      randomIntegerInclusiveStep(100, 1000, 100)
    ];

    return {
      questionNumbers
    };
  },
  Component: props => {
    const {
      question: { questionNumbers },
      translate,
      displayMode
    } = props;

    const statement = {
      sentence: `${translate.units.stringM(
        `<frac d='5' n='${questionNumbers[0]}'/>`
      )} <ans/> ${translate.units.numberOfMm(questionNumbers[1])}`,
      answer: lessThanGreaterThanOrEqualTo(200 * questionNumbers[0], questionNumbers[1])
    };
    return (
      <QF37SentenceDrag
        title={translate.instructions.dragLessThanGreaterThanOrEqualsToMakeStatementCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementCorrect()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentence={statement.sentence}
        testCorrect={[statement.answer.toString()]}
        sentenceStyle={{ alignItems: 'center', alignSelf: 'center' }}
        actionPanelVariant="end"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aDb',
  description: 'aDb',
  keywords: ['Converting units', 'm', 'Metres', 'mm', 'Millimetres'],
  schema: z.object({
    totalLength: z.number().int().min(101).max(999),
    lengthOfCut: z.number().int().min(10).max(100)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const totalLength = randomIntegerInclusive(101, 999, {
      constraint: x => x % 10 !== 0 && x % 100 !== 0
    });

    const lengthOfCut = randomIntegerInclusiveStep(10, 100, 10);

    return { totalLength, lengthOfCut };
  },
  Component: ({ question: { totalLength, lengthOfCut }, translate }) => {
    const answer = Math.floor((totalLength * 10) / lengthOfCut);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aPieceOfStringIsNumXMLongHowManyNumYMmPiecesCanBeCut(
          totalLength / 100,
          lengthOfCut
        )}
        questionHeight={1000}
        testCorrect={[answer.toString()]}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
        sentence={'<ans/>'}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aDb2',
  description: 'aDb2',
  keywords: [
    'Comparison',
    'Converting units',
    'Metres',
    'm',
    'Millimetres',
    'mm',
    'Litres',
    'l',
    'Millilitres',
    'ml'
  ],
  schema: z
    .object({
      questionNumbers: z.array(z.number().min(1).max(4999)).min(2).max(4),
      numbersToUse: numberEnum([2, 4])
    })
    .refine(
      val => val.numbersToUse === val.questionNumbers.length,
      'Length of questionNumbers array must be the same as numbersToUse.'
    ),
  simpleGenerator: () => {
    const numbersToUse = getRandomFromArray([2, 4] as const);

    const questionNumbers =
      numbersToUse === 2
        ? [randomIntegerInclusive(1, 9), randomIntegerInclusiveStep(100, 500, 100)]
        : rejectionSample(
            () => {
              const litres = number(math.evaluate(`${randomIntegerInclusive(101, 499)} / 100`));
              const millilitresB = randomIntegerInclusiveStep(200, 900, 100);
              const millilitresC = randomIntegerInclusive(1001, 4999);
              const millilitresD = randomIntegerInclusiveStep(200, 900, 100);
              return [litres, millilitresB, millilitresC, millilitresD];
            },
            // Only permit them if the difference between litres add millilitresB and millilitresC add millilitresD is 1000ml or less
            ([litres, millilitresB, millilitresC, millilitresD]) =>
              Math.abs(litres * 1000 + millilitresB - (millilitresC + millilitresD)) <= 1000
          );

    return {
      numbersToUse,
      questionNumbers
    };
  },
  Component: props => {
    const {
      question: { numbersToUse, questionNumbers },
      translate,
      displayMode
    } = props;

    const statement =
      numbersToUse === 2
        ? {
            sentence: `<frac d='10' n='${
              questionNumbers[0]
            }'/> l ${ADD} ${translate.units.numberOfMl(
              questionNumbers[1]
            )} <ans/> ${translate.units.numberOfMl(1000)}`,
            answer: lessThanGreaterThanOrEqualTo(
              100 * questionNumbers[0] + questionNumbers[1],
              1000
            )
          }
        : {
            sentence: `${translate.units.numberOfL(
              questionNumbers[0]
            )} ${ADD} ${translate.units.numberOfMl(
              questionNumbers[1]
            )}  <ans/> ${translate.units.numberOfMl(
              questionNumbers[2]
            )} ${ADD} ${translate.units.numberOfMl(questionNumbers[3])}`,
            answer: lessThanGreaterThanOrEqualTo(
              questionNumbers[0] * 1000 + questionNumbers[1],
              questionNumbers[2] + questionNumbers[3]
            )
          };

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragLessThanGreaterThanOrEqualsToMakeStatementCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementCorrect()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentence={statement.sentence}
        testCorrect={[statement.answer.toString()]}
        sentenceStyle={{ alignItems: 'center', alignSelf: 'center' }}
        actionPanelVariant="end"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MillimetresAndMillilitres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
