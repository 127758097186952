// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import EquivalentFractionsAndSimplifying from './1EquivalentFractionsAndSimplifying';
import EquivalentFractionsOnANumberLine from './2EquivalentFractionsOnANumberLine';
import CompareAndOrderDenominator from './3CompareAndOrderDenominator';
import CompareAndOrderNumerator from './4CompareAndOrderNumerator';
import AddAndSubtractSimpleFractions from './5AddAndSubtractSimpleFractions';
import AddAndSubtractAnyTwoFractions from './6AddAndSubtractAnyTwoFractions';
import AddMixedNumbers from './7AddMixedNumbers';
import SubtractMixedNumbers from './8SubtractMixedNumbers';
import MultiStepProblems from './9MultiStepProblems';

const Block = newBlockContent({
  block: 'FractionsA',
  field: 'Number',
  weeks: [8, 9],
  smallSteps: [
    EquivalentFractionsAndSimplifying, // Step 1
    EquivalentFractionsOnANumberLine, // Step 2
    CompareAndOrderDenominator, // Step 3
    CompareAndOrderNumerator, // Step 4
    AddAndSubtractSimpleFractions, // Step 5
    AddAndSubtractAnyTwoFractions, // Step 6
    AddMixedNumbers, // Step 7
    SubtractMixedNumbers, // Step 8
    MultiStepProblems // Step 9
  ]
});
export default Block;
