import { useCallback, useContext, useMemo } from 'react';
import { StyleProp, ViewStyle, TextStyle, View, StyleSheet } from 'react-native';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayout from '../../molecules/BaseLayout';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { resolveThingOrFunction, ThingOrFunction } from '../../../utils/react';
import { DraggableVariant } from '../../draganddrop/utils';
import { arraysHaveSameContents, countRange, filledArray } from '../../../utils/collections';
import { parseMarkup } from '../../../markup';
import { Theme } from '../../../theme';
import { MeasureView } from '../../atoms/MeasureView';
import TextStructure from '../../molecules/TextStructure';
import { AssetSvg } from '../../../assets/svg';
import Text from '../../typography/Text';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import EasyDragAndDropWithSingleZonesWithGrid from '../../draganddrop/EasyDragAndDropWithSingleZonesWithGrid';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string | JSX.Element;
  pdfTitle?: string;
  /**
   * Provide either object of left, right array of arrays of correct answers for both sides of the equation
   * Or function that tests the correctness
   */
  testCorrect: { left?: T[]; right?: T[] }[];
  /** Defaults to checking all answer boxes have a draggable in. */
  testComplete?: (userAnswer: readonly (T | undefined)[][]) => boolean;
  /** Left and ride side sentences, must both be equal lengths */
  sentences: { left?: string; right?: string }[];
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<ViewStyle>;
  /** Styling to be applied to the main panel container on digital. This is not applied to PDFs. */
  mainPanelContainerStyle?: StyleProp<ViewStyle>;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answerText?: string;
  };
  /**
   * The draggable items to show. Each one can just be a string/number, or it can have a custom component using the
   * {@link ItemInfo} type.
   *
   * If providing just numbers, .toLocaleString() will be called for you before rendering.
   */
  items: ThingOrFunction<readonly ((T & (string | number)) | ItemInfo<T>)[]>;
  /**
   * Whether each draggable can exist in multiple drop zones or not, and thus whether they are moved around
   * vs copies of them are moved around. Default 'move'.
   */
  moveOrCopy?: 'move' | 'copy';
  /** Default: square */
  itemVariant?: DraggableVariant;
  itemsTextVariant?: keyof Theme['fonts'];
  pdfItemVariant?: DraggableVariant;
  /**
   * Prop to decide how the PDF version will be displayed, based on your choice of if and where items are placed.
   * 'itemsHidden' - removes the items entirely from the PDF.
   * 'itemsRight' - items display in a column list on the right.
   * 'itemsTop' - items display in a row between the instruction and sentence(s).
   * Optional prop, defaults to 'itemsTop'.
   */
  pdfLayout?: 'itemsHidden' | 'itemsRight' | 'itemsTop';
  itemsLetterEmWidth?: number;
  pdfItemsLetterEmWidth?: number;
  /** Default: endWide. */
  actionPanelVariant?: 'bottom' | 'bottomTall' | 'end' | 'endWide' | 'endMid';
};

/**
 * Specific layout of QF2 that renders a multi-step mathematical equation.
 * Ensuring that the equals signs align along with the LHS & RHS.
 */
export default function QF2DraggableAlignedEquations<T>({
  title,
  pdfTitle,
  sentences,
  testCorrect: testCorrectProp,
  questionHeight,
  customMarkSchemeAnswer,
  textStyle,
  fractionTextStyle,
  sentenceStyle,
  mainPanelContainerStyle,
  items: itemsProp,
  itemsTextVariant = 'WRN700',
  moveOrCopy = 'move',
  itemVariant = 'square',
  pdfItemVariant = 'pdfSquare',
  pdfLayout = 'itemsTop',
  itemsLetterEmWidth,
  pdfItemsLetterEmWidth,
  // Default to bottom action panel if 7 items or less, otherwise use endWide.
  actionPanelVariant = itemsProp.length <= 7 ? 'bottom' : 'endWide'
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  // Set up items - handle the case where we were given a list of numbers/strings.
  const items = useMemo(() => {
    return resolveThingOrFunction(itemsProp).map(it =>
      typeof it === 'string' || typeof it === 'number'
        ? { value: it, component: <Text>{it.toLocaleString()}</Text> }
        : it
    );
  }, [itemsProp]);

  const numOfSentences = sentences.length;

  // Set up testCorrect
  const testCorrect = useCallback(
    (userAnswer: (T | undefined)[][]) => {
      return countRange(numOfSentences).every(i => {
        const correctAnswerArray = [
          ...(testCorrectProp[i].left ?? []),
          ...(testCorrectProp[i].right ?? [])
        ];
        return arraysHaveSameContents(correctAnswerArray, userAnswer[i]);
      });
    },
    [numOfSentences, testCorrectProp]
  );

  // Set up testComplete
  const testComplete = (userAnswer: (T | undefined)[][]) =>
    userAnswer.every(sentenceAnswer => sentenceAnswer.every(it => it !== undefined));

  const leftSideAnsArray = countRange(numOfSentences).map(i => {
    return parseMarkup(sentences[i].left ?? '').numberOfAns;
  });
  const rightSideAnsArray = countRange(numOfSentences).map(i => {
    return sentences[i].right ? parseMarkup(sentences[i].right ?? '').numberOfAns : 0;
  });

  const numberOfAnsArray = countRange(numOfSentences).map(
    i => leftSideAnsArray[i] + rightSideAnsArray[i]
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  const emptyState = numberOfAnsArray.map(answersThisSentence =>
    filledArray(undefined, answersThisSentence)
  );

  const draggableSource =
    displayMode === 'digital' ? (
      <DragAndDropSection style={{ padding: 0 }}>
        {items.map((_item, index) => (
          <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
        ))}
      </DragAndDropSection>
    ) : (
      <DragAndDropSectionPDF
        itemsStyle={{
          flexDirection:
            pdfLayout === 'itemsTop' ? 'row' : pdfLayout === 'itemsRight' ? 'column' : 'column',
          gap: 16,
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        {items.map((_item, index) => (
          <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
        ))}
      </DragAndDropSectionPDF>
    );

  const equalsWidth = 50;

  const content = (
    <MeasureView>
      {dimens => (
        <View style={[styles.container, dimens]}>
          {sentences.map((sentence, row) => {
            return (
              <View key={row} style={{ flexDirection: 'row' }}>
                <View style={[styles.leftHandColumn, { width: dimens.width * 0.5 - equalsWidth }]}>
                  <View
                    key={`left_${row}`}
                    style={[
                      styles.sentenceLine,
                      { height: dimens.height / numOfSentences },
                      sentenceStyle
                    ]}
                  >
                    <TextStructure
                      sentence={sentence.left ?? ''}
                      style={sentenceStyle}
                      textStyle={textStyle}
                      fractionTextStyle={fractionTextStyle}
                      inputBox={({ index }) => (
                        <>
                          {displayMode === 'markscheme' && (
                            <AssetSvg
                              name="True"
                              width={50}
                              style={{
                                zIndex: 999,
                                position: 'absolute',
                                top: -10,
                                right: -10
                              }}
                            />
                          )}
                          <EasyDragAndDropWithSingleZonesWithGrid.ZoneSingle
                            row={row}
                            column={index}
                          />
                        </>
                      )}
                    />
                  </View>
                </View>

                <View style={[styles.equalsColumn, { width: equalsWidth }]}>
                  <View
                    style={[
                      styles.sentenceLine,
                      { height: dimens.height / numOfSentences },
                      sentenceStyle
                    ]}
                  >
                    {<Text variant="WRN400">=</Text>}
                  </View>
                </View>

                <View style={[styles.rightHandColumn, { width: dimens.width * 0.5 - equalsWidth }]}>
                  <View
                    style={[
                      styles.sentenceLine,
                      { height: dimens.height / numOfSentences },
                      sentenceStyle
                    ]}
                  >
                    <TextStructure
                      key={`right_${row}`}
                      sentence={sentence.right ?? ''}
                      style={sentenceStyle}
                      textStyle={textStyle}
                      fractionTextStyle={fractionTextStyle}
                      inputBox={({ index }) => (
                        <>
                          {displayMode === 'markscheme' && (
                            <AssetSvg
                              name="True"
                              width={50}
                              style={{
                                zIndex: 999,
                                position: 'absolute',
                                top: -10,
                                right: -10
                              }}
                            />
                          )}
                          <EasyDragAndDropWithSingleZonesWithGrid.ZoneSingle
                            row={row}
                            column={leftSideAnsArray[row] + index}
                          />
                        </>
                      )}
                    />
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      )}
    </MeasureView>
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer = countRange(numOfSentences).map(i => [
      ...(testCorrectProp[i].left ?? []),
      ...(testCorrectProp[i].right ?? [])
    ]);

    return (
      <EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState
        id="draganddrop"
        items={items}
        variant={pdfItemVariant}
        columnsPerRow={numberOfAnsArray}
        // We need to always show the items in their original positions on mark schemes, so this should always be 'copy':
        moveOrCopy={'copy'}
        defaultState={displayMode === 'markscheme' ? markSchemeAnswer : emptyState}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={pdfItemsLetterEmWidth ?? itemsLetterEmWidth}
        textVariant={itemsTextVariant}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <View
              style={[
                styles.container,
                {
                  flexDirection:
                    pdfLayout === 'itemsTop'
                      ? 'column'
                      : pdfLayout === 'itemsRight'
                      ? 'row-reverse'
                      : 'row'
                }
              ]}
            >
              {pdfLayout === 'itemsTop' && draggableSource}
              {content}
              {pdfLayout === 'itemsRight' && draggableSource}
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </View>
          }
          questionHeight={questionHeight}
        />
      </EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={itemVariant}
      defaultState={emptyState}
      columnsPerRow={numberOfAnsArray}
      testComplete={testComplete}
      testCorrect={testCorrect}
      textAutoScale={longestItemTextLength}
      textLetterEmWidth={pdfItemsLetterEmWidth ?? itemsLetterEmWidth}
      textVariant={itemsTextVariant}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={draggableSource}
        mainPanelContainerStyle={mainPanelContainerStyle}
        mainPanelContents={<View style={[styles.container]}>{content}</View>}
      />
    </EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },

  leftHandColumn: {
    alignItems: 'flex-end'
  },

  rightHandColumn: {
    alignItems: 'flex-start'
  },

  equalsColumn: {
    marginHorizontal: 24
  },

  sentenceLine: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
