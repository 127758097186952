import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { useMemo } from 'react';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  ScientificNotation,
  base10ObjectToNumber,
  powersOfTenPowToWord
} from '../../../../utils/math';
import { numbersDoNotExchange, numbersDoNotExchangeAt } from '../../../../utils/exchanges';
import { ADD, SUB } from '../../../../constants';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF28FunctionMachines from '../../../../components/question/questionFormats/QF28FunctionMachines';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';

////
// Questions
////

// Question1 exported to agy
const Question1 = newQuestionContent({
  uid: 'af4',
  description: 'af4',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(998),
      number2Factor: z.number().int().min(1).max(9),
      number2PowerOfTen: z.number().int().min(0).max(2),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val =>
        numbersDoNotExchange(val.number1, Math.pow(10, val.number2PowerOfTen) * val.number2Factor),
      'number1 and number2 must not exchange.'
    ),
  simpleGenerator: () => {
    const number2PowerOfTen = randomIntegerInclusive(0, 2);

    const number2Factor = randomIntegerInclusive(1, number2PowerOfTen === 2 ? 8 : 9);

    const number1 = randomIntegerInclusive(101, 998, {
      constraint: x => numbersDoNotExchange(x, Math.pow(10, number2PowerOfTen) * number2Factor)
    });

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { number1, number2Factor, number2PowerOfTen, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, number2Factor, number2PowerOfTen, addOrSubtract },
      translate
    } = props;

    const number2 = Math.pow(10, number2PowerOfTen) * number2Factor;

    const number3 = number1 + number2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToHelpCompleteNumberSentence()}
        testCorrect={[(addOrSubtract === ADD ? number3 : number1).toString()]}
        sentence={`${(addOrSubtract === ADD
          ? number1
          : number3
        ).toLocaleString()} ${addOrSubtract} ${number2.toLocaleString()} = <ans />`}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(addOrSubtract === ADD ? number1 : number3)}
            columnsToShow={[2, 1, 0]}
            dimens={dimens}
            counterVariant={'base10Block'}
          />
        )}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
export const af4 = Question1;

const Question2 = newQuestionContent({
  uid: 'af5',
  description: 'af5',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', 'Inverse'],
  schema: z
    .object({
      number1: z.number().int().min(100).max(999),
      number2: z.number().int().min(1).max(9),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 and number2 do not exchange'
    )
    .refine(
      val =>
        val.addOrSubtract === ADD
          ? val.number1 % 111 === 0
          : (val.number1 + val.number2) % 111 === 0,
      'Left hand value (addend if addition, minuend if subtraction) must be a multiple of 111'
    ),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    let number1: number;
    let number2: number;

    if (addOrSubtract === ADD) {
      number1 = randomIntegerInclusiveStep(111, 888, 111);
      number2 = randomIntegerInclusive(1, 8, {
        constraint: x => numbersDoNotExchange(x, number1)
      });
    } else {
      const number3 = randomIntegerInclusiveStep(111, 888, 111);
      number2 = randomIntegerInclusive(1, 9, {
        constraint: x => numbersDoNotExchange(-x, number3)
      });
      number1 = number3 - number2;
    }

    return {
      addOrSubtract,
      number1,
      number2
    };
  },
  Component: props => {
    const {
      question: { addOrSubtract, number1, number2 },
      translate
    } = props;
    const answerOptions = [number2, number2 * 10, number2 * 100];

    const number3 = number1 + number2;

    const sentences = useMemo(() => {
      const questionsAndAnswers =
        addOrSubtract === ADD
          ? [
              {
                sentence: `${number1.toLocaleString()} ${ADD} <ans /> = ${(
                  number1 + number2
                ).toLocaleString()}`,
                answer: number2
              },
              {
                sentence: `${number1.toLocaleString()} ${ADD} <ans /> = ${(
                  number1 +
                  number2 * 10
                ).toLocaleString()}`,
                answer: number2 * 10
              },
              {
                sentence: `${number1.toLocaleString()} ${ADD} <ans /> = ${(
                  number1 +
                  number2 * 100
                ).toLocaleString()}`,
                answer: number2 * 100
              }
            ]
          : [
              {
                sentence: `${number3.toLocaleString()} ${SUB} <ans /> = ${(
                  number3 - number2
                ).toLocaleString()}`,
                answer: number2
              },
              {
                sentence: `${number3.toLocaleString()} ${SUB} <ans /> = ${(
                  number3 -
                  number2 * 10
                ).toLocaleString()}`,
                answer: number2 * 10
              },
              {
                sentence: `${number3.toLocaleString()} ${SUB} <ans /> = ${(
                  number3 -
                  number2 * 100
                ).toLocaleString()}`,
                answer: number2 * 100
              }
            ];

      return shuffle(questionsAndAnswers, { random: seededRandom(props.question) });
    }, [addOrSubtract, number1, number2, number3, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardOnlyUsedOnce()}
        items={answerOptions}
        pdfLayout="itemsRight"
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'af6',
  description: 'af6',
  keywords: ['Complement', '100', 'Hundred', 'Select'],
  schema: z
    .object({
      number1: z.number().int().min(100).max(999),
      number2: z.number().int().min(0).max(800),
      addOrSubtract: z.enum([ADD, SUB]),
      correct: z.enum(['hundreds', 'tens', 'ones']),
      name: nameSchema
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange'
    ),
  simpleGenerator: () => {
    const correct = getRandomFromArray(['hundreds', 'tens', 'ones'] as const);
    const number2Base10Object = { [correct]: randomIntegerInclusive(1, 8) };
    const number2 = base10ObjectToNumber(number2Base10Object);

    const number1 = randomIntegerInclusive(101, 999, {
      constraint: x => numbersDoNotExchange(x, number2)
    });

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);
    const name = getRandomName();

    return {
      number1,
      number2,
      addOrSubtract,
      correct,
      name
    };
  },

  Component: props => {
    const {
      question: { number1, number2, addOrSubtract, correct, name },
      translate
    } = props;
    const number3 = number1 + number2;

    const questionEquation =
      addOrSubtract === ADD ? `${number1} ${ADD} ${number2}` : `${number3} ${SUB} ${number2}`;

    const answers = shuffle(['hundreds', 'tens', 'ones'] as const, {
      random: seededRandom(props.question)
    });

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichDigitChanges(name, questionEquation)}
        testCorrect={[correct]}
        multiSelect
        items={answers.map(number => ({
          value: number,
          component: translate.powersOfTen[number](2)
        }))}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'af62',
  description: 'af6',
  keywords: ['Complement', '100', 'Hundred', 'Select'],
  schema: z
    .object({
      number1: z.number().int().min(100).max(999),
      number2: z.number().int().min(0).max(800),
      addOrSubtract: z.enum([ADD, SUB]),
      correct: z.enum(['hundreds', 'tens', 'ones']),
      options: z
        .array(
          z.enum(['hundreds', 'tens', 'ones', 'tensAndOnes', 'hundredsAndOnes', 'hundredsAndTens'])
        )
        .length(4),
      name: nameSchema
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange'
    ),
  simpleGenerator: () => {
    const correct = getRandomFromArray(['hundreds', 'tens', 'ones'] as const);
    const number2Base10Object = { [correct]: randomIntegerInclusive(1, 8) };
    const number2 = base10ObjectToNumber(number2Base10Object);

    const number1 = randomIntegerInclusive(101, 999, {
      constraint: x => numbersDoNotExchange(x, number2)
    });

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);
    const name = getRandomName();

    const readonlyOptions = ['hundreds', 'tens', 'ones'] as const;
    const extraOption = getRandomFromArray([
      'tensAndOnes',
      'hundredsAndOnes',
      'hundredsAndTens'
    ] as const);

    return {
      number1,
      number2,
      addOrSubtract,
      correct,
      options: shuffle([...readonlyOptions, extraOption]),
      name
    };
  },

  Component: props => {
    const {
      question: { number1, number2, addOrSubtract, correct, options, name },
      translate
    } = props;
    const number3 = number1 + number2;

    const questionEquation =
      addOrSubtract === ADD
        ? `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()}`
        : `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()}`;

    const multiParamString = (
      str: 'hundreds' | 'tens' | 'ones' | 'tensAndOnes' | 'hundredsAndOnes' | 'hundredsAndTens'
    ): str is 'tensAndOnes' | 'hundredsAndOnes' | 'hundredsAndTens' => {
      return str === 'tensAndOnes' || str === 'hundredsAndOnes' || str === 'hundredsAndTens';
    };

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.whichDigitChanges(name, questionEquation)}
        testCorrect={[correct]}
        numItems={4}
        renderItems={options.map(opt => ({
          value: opt,
          component: (
            <Text variant="WRN700">
              {multiParamString(opt)
                ? translate.powersOfTen[opt](2, 2)
                : translate.powersOfTen[opt](2)}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'af7',
  description: 'af7',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(998),
      numberA2: z.number().int().min(1).max(6),
      numberB2: z.number().int().min(2).max(7),
      numberC2: z.number().int().min(3).max(8),
      powerOfTen: z.number().int().min(0).max(2),
      addOrSubtract: z.enum(['add', 'subtract'])
    })
    .refine(val => val.numberA2 < val.numberB2, 'numberA2 must be smaller than 2b')
    .refine(val => val.numberA2 < val.numberC2, 'numberA2 must be smaller than 2c')
    .refine(val => val.numberB2 < val.numberC2, 'numberB2 must be smaller than 2c'),
  simpleGenerator: () => {
    const powerOfTen = randomIntegerInclusive(0, 2);

    const numberA2 = randomIntegerInclusive(1, 6);

    // B2 must be larger than A2
    const numberB2 = randomIntegerInclusive(numberA2 + 1, 7);

    // C2 must be larger than B2 and A2
    const numberC2 = randomIntegerInclusive(numberB2 + 1, 8);

    const powerAsWord = powersOfTenPowToWord[powerOfTen as 0 | 1 | 2];

    // Any number that meets the requirement of never creating an exchange in the relevant power of ten.
    const number1 = rejectionSample(
      () => randomIntegerInclusive(101, 998),
      x => numbersDoNotExchangeAt(x, numberC2 * Math.pow(10, powerOfTen), powerAsWord) // Checking against C2 as this is the largest num2
    );

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    return { number1, numberA2, numberB2, numberC2, powerOfTen, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, numberA2, numberB2, numberC2, powerOfTen, addOrSubtract },
      translate
    } = props;

    const power = Math.pow(10, powerOfTen);

    const numberA2Powered = numberA2 * power;
    const numberB2Powered = numberB2 * power;
    const numberC2Powered = numberC2 * power;

    const number3 = number1 + numberC2Powered;

    const eqA =
      addOrSubtract === 'add'
        ? getBinOpEquation({ left: number1, right: numberA2Powered, sign: 'add', answer: 'result' })
        : getBinOpEquation({
            right: numberA2Powered,
            left: number3,
            sign: 'subtract',
            answer: 'result'
          });

    const eqB =
      addOrSubtract === 'add'
        ? getBinOpEquation({ left: number1, right: numberB2Powered, sign: 'add', answer: 'result' })
        : getBinOpEquation({
            right: numberB2Powered,
            left: number3,
            sign: 'subtract',
            answer: 'result'
          });

    const eqC =
      addOrSubtract === 'add'
        ? getBinOpEquation({ left: number1, right: numberC2Powered, sign: 'add', answer: 'result' })
        : getBinOpEquation({
            right: numberC2Powered,
            left: number3,
            sign: 'subtract',
            answer: 'result'
          });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'af8',
  description: 'af8',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', 'Function machine'],
  schema: z.object({
    number1: z.number().int().min(101).max(898),
    number2a: z.number().int().min(1).max(9),
    number2b: z.number().int().min(10).max(90).multipleOf(10),
    number2c: z.number().int().min(100).max(800).multipleOf(100),
    negativeNumber: z.enum(['number2a', 'number2b', 'number2c'])
  }),
  simpleGenerator: () => {
    const negativeNumber = getRandomFromArray(['number2a', 'number2b', 'number2c'] as const);

    const number1 = randomIntegerInclusive(101, 898, {
      constraint: x => {
        const numSciNot = ScientificNotation.fromNumber(x);
        const hundreds = numSciNot.digitAt('hundreds');
        const tens = numSciNot.digitAt('tens');
        const ones = numSciNot.digitAt('ones');

        return (
          (negativeNumber === 'number2c' ? hundreds > 2 : true) &&
          (negativeNumber === 'number2b' ? tens > 1 : tens < 9) &&
          (negativeNumber === 'number2a' ? ones > 1 : ones < 9)
        );
      }
    });

    const number2c = randomIntegerInclusiveStep(100, 800, 100, {
      // If -number2c, do not allow number1 - number2c to be <= 0, else do not allow number1 + number2c to be >= 999
      // Also ensure that the leading digit can be add/subtracted from all of number1's digits
      constraint: x => {
        const number1SciNot = ScientificNotation.fromNumber(number1);
        const leadingDigit = x / 100;

        return (
          (negativeNumber === 'number2c' ? number1 - x > 0 : number1 + x < 1000) &&
          (negativeNumber === 'number2b'
            ? number1SciNot.digitAt('tens') - leadingDigit >= 0
            : number1SciNot.digitAt('tens') + leadingDigit < 10) &&
          (negativeNumber === 'number2a'
            ? number1SciNot.digitAt('ones') - leadingDigit >= 0
            : number1SciNot.digitAt('ones') + leadingDigit < 10)
        );
      }
    });

    // We want to have the leading digit of every number to be the same
    const leadingDigit = number2c / 100;
    const number2b = leadingDigit * 10;
    const number2a = leadingDigit;

    return { number1, number2a, number2b, number2c, negativeNumber };
  },
  Component: props => {
    const {
      question: { number1, number2a, number2b, number2c, negativeNumber },
      translate
    } = props;

    const output = (() => {
      switch (negativeNumber) {
        case 'number2a':
          return number1 - number2a + number2b + number2c;
        case 'number2b':
          return number1 + number2a - number2b + number2c;
        case 'number2c':
          return number1 + number2a + number2b - number2c;
      }
    })();

    const functionMachineArray = (() => {
      switch (negativeNumber) {
        case 'number2a':
          return [
            `${number1.toLocaleString()}`,
            `${ADD} ${number2c.toLocaleString()}`,
            `${ADD} ${number2b.toLocaleString()}`,
            `${SUB} ${number2a.toLocaleString()}`,
            `<ans/>`
          ];
        case 'number2b':
          return [
            `${number1.toLocaleString()}`,
            `${ADD} ${number2c.toLocaleString()}`,
            `${SUB} ${number2b.toLocaleString()}`,
            `${ADD} ${number2a.toLocaleString()}`,
            `<ans/>`
          ];
        case 'number2c':
          return [
            `${number1.toLocaleString()}`,
            `${SUB} ${number2c.toLocaleString()}`,
            `${ADD} ${number2b.toLocaleString()}`,
            `${ADD} ${number2a.toLocaleString()}`,
            `<ans/>`
          ];
      }
    })();

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheOutput()}
        rowsOfBoxes={[functionMachineArray]}
        testCorrect={[[output.toString()]]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'af9',
  description: 'af9',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', 'Function machine'],
  schema: z.object({
    number1: z.number().int().min(101).max(898),
    number2a: z.number().int().min(1).max(9),
    number2b: z.number().int().min(10).max(90).multipleOf(10),
    number2c: z.number().int().min(100).max(800).multipleOf(100),
    negativeNumber: z.enum(['number2a', 'number2b', 'number2c'])
  }),
  simpleGenerator: () => {
    const negativeNumber = getRandomFromArray(['number2a', 'number2b', 'number2c'] as const);

    const number1 = randomIntegerInclusive(101, 898, {
      constraint: x => {
        const numSciNot = ScientificNotation.fromNumber(x);
        const hundreds = numSciNot.digitAt('hundreds');
        const tens = numSciNot.digitAt('tens');
        const ones = numSciNot.digitAt('ones');

        return (
          (negativeNumber === 'number2c' ? hundreds > 2 : true) &&
          (negativeNumber === 'number2b' ? tens > 1 : tens < 9) &&
          (negativeNumber === 'number2a' ? ones > 1 : ones < 9)
        );
      }
    });

    const number2c = randomIntegerInclusiveStep(100, 800, 100, {
      // If -number2c, do not allow number1 - number2c to be <= 0, else do not allow number1 + number2c to be >= 999
      // Also ensure that the leading digit can be add/subtracted from all of number1's digits
      constraint: x => {
        const number1SciNot = ScientificNotation.fromNumber(number1);
        const leadingDigit = x / 100;

        return (
          (negativeNumber === 'number2c' ? number1 - x > 0 : number1 + x < 1000) &&
          (negativeNumber === 'number2b'
            ? number1SciNot.digitAt('tens') - leadingDigit >= 0
            : number1SciNot.digitAt('tens') + leadingDigit < 10) &&
          (negativeNumber === 'number2a'
            ? number1SciNot.digitAt('ones') - leadingDigit >= 0
            : number1SciNot.digitAt('ones') + leadingDigit < 10)
        );
      }
    });

    // We want to have the leading digit of every number to be the same
    const leadingDigit = number2c / 100;
    const number2b = leadingDigit * 10;
    const number2a = leadingDigit;

    return { number1, number2a, number2b, number2c, negativeNumber };
  },
  Component: props => {
    const {
      question: { number1, number2a, number2b, number2c, negativeNumber },
      translate
    } = props;

    const output = (() => {
      switch (negativeNumber) {
        case 'number2a':
          return number1 - number2a + number2b + number2c;
        case 'number2b':
          return number1 + number2a - number2b + number2c;
        case 'number2c':
          return number1 + number2a + number2b - number2c;
      }
    })();

    const functionMachineArray = (() => {
      switch (negativeNumber) {
        case 'number2a':
          return [
            `<ans/>`,
            `${ADD} ${number2c.toLocaleString()}`,
            `${ADD} ${number2b.toLocaleString()}`,
            `${SUB} ${number2a.toLocaleString()}`,
            `${output.toLocaleString()}`
          ];
        case 'number2b':
          return [
            `<ans/>`,
            `${ADD} ${number2c.toLocaleString()}`,
            `${SUB} ${number2b.toLocaleString()}`,
            `${ADD} ${number2a.toLocaleString()}`,
            `${output.toLocaleString()}`
          ];
        case 'number2c':
          return [
            `<ans/>`,
            `${SUB} ${number2c.toLocaleString()}`,
            `${ADD} ${number2b.toLocaleString()}`,
            `${ADD} ${number2a.toLocaleString()}`,
            `${output.toLocaleString()}`
          ];
      }
    })();

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheOutput()}
        rowsOfBoxes={[functionMachineArray]}
        testCorrect={[[number1.toString()]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SpotThePattern',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
