import { getRandomFromArray, seededRandom, shuffle } from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { randomIntegerInclusive } from '../../../../utils/random';
import { ScientificNotation, base10ObjectToNumber } from '../../../../utils/math';
import IdentifyPartition from '../../../../components/question/questionFormats/IdentifyPartition';
import QF15CreateBaseTenNumber, {
  sumDigits
} from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ADD } from '../../../../constants';
import { sumNumberArray } from '../../../../utils/collections';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aag',
  description: 'aag',
  keywords: ['Place value', 'Partition', 'Base 10', '100', 'Hundred'],
  schema: z.object({
    ones: z.number().int().min(2).max(9),
    tens: z.number().int().min(2).max(9)
  }),
  questionHeight: 1440,
  example: {
    ones: 4,
    tens: 2
  },
  simpleGenerator: () => {
    // Limit ones to 2-9 and tens to 2-9
    const ones = randomIntegerInclusive(2, 9);
    const tens = randomIntegerInclusive(2, 9);
    return {
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;

    const number = base10ObjectToNumber({ ones, tens });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        testCorrect={answer =>
          parseInt((parseInt(answer[0]) * 10).toString()) + parseInt(answer[1]) === number &&
          parseInt(answer[2]) + parseInt(answer[3]) === number &&
          // 0 is not acceptable as any of the answers on the second line.
          parseInt(answer[2]) !== 0 &&
          parseInt(answer[3]) !== 0
        }
        inputMaxCharacters={2}
        sentence={translate.answerSentences.numEqualsTensOnesNumEquals2Ans(number)}
        {...props}
        Content={({ dimens }) => (
          <SimpleBaseTenWithCrossOut ones={ones} tens={tens} dimens={dimens} />
        )}
        pdfDirection="column"
        questionHeight={1440}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            tens.toLocaleString(),
            ones.toLocaleString(),
            (tens * 10).toLocaleString(),
            ones.toLocaleString()
          ]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aah',
  description: 'aah',
  keywords: ['Place value', 'Represent', '100', 'Hundred', 'Base 10'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(10)
      .max(99)
      .refine(
        number => sumDigits(number) <= 12,
        'number must be representable with <=12 base ten counters'
      )
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(10, 99, {
      constraint: number => sumDigits(number) <= 12
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF15CreateBaseTenNumber
        title={translate.instructions.dragTheBase10ToRepresentTheNumber(number)}
        pdfTitle={translate.instructions.useBase10ToMakeNum(number)}
        number={ScientificNotation.fromNumber(number)}
        counterSize="large"
        rows={2}
        draggablesToShow={[1, 0]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aai',
  description: 'aai',
  keywords: ['Place value', 'Partition', 'Part-whole', '100', 'Hundred'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9)
  }),
  example: {
    ones: 4,
    tens: 2
  },
  simpleGenerator: () => {
    // Limit ones to 1-9 and tens to 1-9
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    return {
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;
    const number = base10ObjectToNumber({ ones, tens });

    const partition = shuffle([ones, tens * 10], { random: seededRandom(props.question) });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePartWholeModelToCompleteSentence()}
        testCorrect={[tens.toString(), ones.toString()]}
        sentence={translate.answerSentences.numEqualsTensOnes(number, tens, ones)}
        Content={({ dimens }) => (
          <PartWholeModel top={number} partition={partition} dimens={dimens} />
        )}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'aaj',
  description: 'aaj',
  keywords: ['Place value', 'Partition', 'Part-whole', '100', 'Hundred'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    unitOfAnswer: z.enum(['ones', 'tens'])
  }),
  example: {
    ones: 6,
    tens: 2,
    unitOfAnswer: 'tens'
  },
  simpleGenerator: () => {
    const unitOfAnswer = getRandomFromArray(['ones', 'tens'] as const);
    // Added condition of ensuring that thousands and ten-thousands are never both 0
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);

    return { ones, tens, unitOfAnswer };
  },
  Component: ({ question, ...props }) => {
    const { translate } = props;
    const { ones, tens, unitOfAnswer } = question;

    const number = base10ObjectToNumber({ ones, tens });

    let partition: (number | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'tens') {
      partition = [ones, '$ans'];
      correctAnswer = tens * 10;
    } else {
      partition = ['$ans', tens * 10];
      correctAnswer = ones;
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={shuffle(partition, { random: seededRandom(question) })}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aak',
  description: 'aak',
  keywords: ['Place value', 'Partition', '100', 'Hundred'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9)
  }),
  example: {
    tens: 2,
    ones: 4
  },
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(2, 9);
    const tens = randomIntegerInclusive(2, 9);
    return { ones, tens };
  },

  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;
    const number = base10ObjectToNumber({ ones, tens });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        sentences={[
          translate.answerSentences.numEqualsTensAndOnes(number.toLocaleString()),
          `${number.toLocaleString()} = <ans/> ${ADD} <ans/>`
        ]}
        testCorrect={userAnswer => {
          const topRowAnswers = [Number(userAnswer[0][0]) * 10, Number(userAnswer[0][1])];
          return (
            sumNumberArray(topRowAnswers) === number &&
            sumNumberArray(userAnswer[1].map(str => Number(str))) === number
          );
        }}
        inputMaxCharacters={3}
        containerStyle={{ alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [tens.toLocaleString(), ones.toLocaleString()],
            [(tens * 10).toLocaleString(), ones.toLocaleString()]
          ],
          answerText: translate.markScheme.anyValidPartition()
        }}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'aal',
  description: 'aal',
  keywords: ['Base 10', 'Partition', 'Partition', '100', 'Hundred'],
  schema: z.object({
    number: z.number().int().min(21).max(99),
    leftPart: z.number().int().min(1).max(99)
  }),
  example: {
    number: 45,
    leftPart: 30
  },
  simpleGenerator: () => {
    const kind = getRandomFromArray(['splitTens', 'splitOnes'] as const);
    const tens = randomIntegerInclusive(2, 9);
    // We need at least 2 ones if kind is 'splitOnes', otherwise we can go down to 1 one.
    const ones = kind === 'splitOnes' ? randomIntegerInclusive(2, 9) : randomIntegerInclusive(1, 9);
    const number = base10ObjectToNumber({ ones, tens });

    // We want to split the tens or split the ones
    let leftPart: number;
    switch (kind) {
      case 'splitTens':
        leftPart = randomIntegerInclusive(1, tens - 1) * 10;
        break;
      case 'splitOnes':
        leftPart = tens * 10 + randomIntegerInclusive(1, ones - 1);
        break;
    }
    return { number, leftPart };
  },
  Component: ({ question: { number, leftPart }, ...props }) => (
    <IdentifyPartition number={number} leftPart={leftPart} {...props} />
  )
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionNumbersTo100',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
