import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { all, create, number } from 'mathjs';
import {
  randomIntegerInclusiveStep,
  getRandomBoolean,
  randomIntegerInclusive,
  seededRandom,
  shuffle,
  getRandomFromArray,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import ContentBox from '../../../../components/molecules/ContentBox';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { numberEnum } from '../../../../utils/zod';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { compareFloats } from '../../../../utils/math';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aWg',
  description: 'aWg',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Hundredths'],
  schema: z.object({
    number1: z.number().int().min(200).max(9000),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const random = getRandomBoolean();
    const number1 = random
      ? randomIntegerInclusiveStep(200, 1000, 100)
      : randomIntegerInclusiveStep(2000, 9000, 1000);
    const number2 = randomIntegerInclusive(2, 9);

    return { number1, number2, random };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    // Sentences
    const sentences = [
      translate.answerSentences.xPercentOfYAns(1, number1),
      translate.answerSentences.xPercentOfYAns(number2, number1)
    ];

    // Answers
    const ans1 = number(math.evaluate(`${number1} * 0.01`));
    const ans2 = number(math.evaluate(`${number1} * 0.01 * ${number2}`));

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={userAnswer => {
          return compareFloats(userAnswer[0][0], ans1) && compareFloats(userAnswer[1][0], ans2);
        }}
        sentences={sentences}
        sentenceStyle={{ justifyContent: 'center' }}
        textStyle={{ textAlign: 'center' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        containerStyle={{ alignItems: 'center' }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        customMarkSchemeAnswer={{
          answersToDisplay: [[ans1.toLocaleString()], [ans2.toLocaleString()]]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aWh',
  description: 'aWh',
  keywords: ['Percentage', 'Amount', 'Bar model', 'Parts', 'Whole', 'Division', 'Multiplication'],
  schema: z.object({
    number1: z.number().int().min(200).max(9000)
  }),
  simpleGenerator: () => {
    const random = getRandomBoolean();
    const number1 = random
      ? randomIntegerInclusiveStep(200, 1000, 100)
      : randomIntegerInclusiveStep(2000, 9000, 1000);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    const number3 = number(math.evaluate(`${number1} * 0.01 * 10`));

    const barModelNumbers = filledArray(1, 10);
    const barModelStrings = filledArray('', 10);
    const barModelCellColors = filledArray('', 10);

    const tickValues =
      displayMode === 'digital'
        ? [
            translate.answerSentences.numPercent(0),
            translate.answerSentences.numPercent(10),
            translate.answerSentences.numPercent(20),
            translate.answerSentences.numPercent(30),
            translate.answerSentences.numPercent(40),
            translate.answerSentences.numPercent(50),
            translate.answerSentences.numPercent(60),
            translate.answerSentences.numPercent(70),
            translate.answerSentences.numPercent(80),
            translate.answerSentences.numPercent(90),
            translate.answerSentences.numPercent(100)
          ]
        : [
            (0).toLocaleString(),
            (10).toLocaleString(),
            (20).toLocaleString(),
            (30).toLocaleString(),
            (40).toLocaleString(),
            (50).toLocaleString(),
            (60).toLocaleString(),
            (70).toLocaleString(),
            (80).toLocaleString(),
            (90).toLocaleString(),
            (100).toLocaleString()
          ];

    return (
      <QF1ContentAndSentence
        sentence={`${translate.answerSentences.xPercentOfY(10, number1)} = <ans/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ flex: 0.5, justifyContent: 'center' }}
        title={translate.instructions.useBarModelToCompleteCalc()}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer => compareFloats(userAnswer[0], number3)}
        customMarkSchemeAnswer={{
          answersToDisplay: [number3.toLocaleString()]
        }}
        Content={({ dimens }) => {
          return (
            <View>
              <View style={{ paddingLeft: 38 }}>
                <View
                  style={{
                    alignSelf: 'flex-start',
                    width: (dimens.width - 103) / 10
                  }}
                >
                  <BarModelCurlyBrace topOrBottomBrace="top" braceText={'?'} />
                </View>
                <BarModel
                  total={10}
                  numbers={[barModelNumbers]}
                  strings={[barModelStrings]}
                  cellColors={[barModelCellColors]}
                  dimens={{ width: dimens.width - 68, height: dimens.height }}
                  rowHeight={50}
                />
              </View>
              <View style={{ paddingTop: displayMode === 'digital' ? 0 : 40 }}>
                <NumberLine
                  tickValues={tickValues}
                  dimens={{
                    width: displayMode === 'digital' ? dimens.width - 33 : dimens.width + 68,
                    height: displayMode === 'digital' ? 120 : 140
                  }}
                  customFontSize={displayMode === 'digital' ? 24 : 32}
                />
                {displayMode !== 'digital' && (
                  <View style={{ position: 'absolute', right: 60, top: 90 }}>
                    <Text variant="WRN400" style={{ fontSize: 32 }}>
                      ({translate.answerSentences.percent()})
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={{
                  alignSelf: displayMode === 'digital' ? 'center' : 'flex-start',
                  width: displayMode === 'digital' ? dimens.width - 110 : dimens.width - 75,
                  paddingLeft: displayMode === 'digital' ? 0 : 34,
                  paddingTop: displayMode === 'digital' ? 0 : 40
                }}
              >
                <BarModelCurlyBrace
                  topOrBottomBrace="bottom"
                  braceText={number1.toLocaleString()}
                />
              </View>
            </View>
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aWi',
  description: 'aWi',
  keywords: ['Percentage', 'Amount', 'Bar model', 'Parts', 'Whole', 'Division', 'Multiplication'],
  schema: z.object({
    number1: z.number().int().min(200).max(9000),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const random = getRandomBoolean();
    const number1 = random
      ? randomIntegerInclusiveStep(200, 900, 100)
      : randomIntegerInclusiveStep(2000, 9000, 1000);
    const number2 = randomIntegerInclusive(2, 9);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const number3 = number(math.evaluate(`${number2} * 10`));
    const number4 = number(math.evaluate(`${number3} * (${number1} / 100)`));

    const barModelNumbers = filledArray(1, 10);
    const barModelStrings = filledArray('', 10);
    const barModelCellColors = filledArray('', 10);

    const tickValues =
      displayMode === 'digital'
        ? [
            translate.answerSentences.numPercent(0),
            translate.answerSentences.numPercent(10),
            translate.answerSentences.numPercent(20),
            translate.answerSentences.numPercent(30),
            translate.answerSentences.numPercent(40),
            translate.answerSentences.numPercent(50),
            translate.answerSentences.numPercent(60),
            translate.answerSentences.numPercent(70),
            translate.answerSentences.numPercent(80),
            translate.answerSentences.numPercent(90),
            translate.answerSentences.numPercent(100)
          ]
        : [
            (0).toLocaleString(),
            (10).toLocaleString(),
            (20).toLocaleString(),
            (30).toLocaleString(),
            (40).toLocaleString(),
            (50).toLocaleString(),
            (60).toLocaleString(),
            (70).toLocaleString(),
            (80).toLocaleString(),
            (90).toLocaleString(),
            (100).toLocaleString()
          ];

    return (
      <QF1ContentAndSentence
        sentence={`${translate.answerSentences.xPercentOfY(number3, number1)} = <ans/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ flex: 0.5, justifyContent: 'center' }}
        title={translate.instructions.useBarModelToWorkOutThePercentage()}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer => compareFloats(userAnswer[0], number4)}
        customMarkSchemeAnswer={{
          answersToDisplay: [number4.toLocaleString()]
        }}
        Content={({ dimens }) => {
          return (
            <View>
              <View style={{ paddingLeft: 38 }}>
                <View
                  style={{
                    alignSelf: 'flex-start',
                    width: ((dimens.width - 103) / 10) * (number3 / 10)
                  }}
                >
                  <BarModelCurlyBrace topOrBottomBrace="top" braceText={'?'} />
                </View>
                <BarModel
                  total={10}
                  numbers={[barModelNumbers]}
                  strings={[barModelStrings]}
                  cellColors={[barModelCellColors]}
                  dimens={{ width: dimens.width - 68, height: dimens.height }}
                  rowHeight={50}
                />
              </View>
              <View style={{ paddingTop: displayMode === 'digital' ? 0 : 40 }}>
                <NumberLine
                  tickValues={tickValues}
                  dimens={{
                    width: displayMode === 'digital' ? dimens.width - 33 : dimens.width + 68,
                    height: displayMode === 'digital' ? 120 : 140
                  }}
                  customFontSize={displayMode === 'digital' ? 24 : 32}
                />
                {displayMode !== 'digital' && (
                  <View style={{ position: 'absolute', right: 60, top: 90 }}>
                    <Text variant="WRN400" style={{ fontSize: 32 }}>
                      ({translate.answerSentences.percent()})
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={{
                  alignSelf: displayMode === 'digital' ? 'center' : 'flex-start',
                  width: displayMode === 'digital' ? dimens.width - 110 : dimens.width - 75,
                  paddingLeft: displayMode === 'digital' ? 0 : 34,
                  paddingTop: displayMode === 'digital' ? 0 : 40
                }}
              >
                <BarModelCurlyBrace
                  topOrBottomBrace="bottom"
                  braceText={number1.toLocaleString()}
                />
              </View>
            </View>
          );
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aWj',
  description: 'aWj',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication'],
  schema: z.object({
    number1: numberEnum([11, 21, 31, 41, 49, 51, 61, 71, 81, 91, 98, 99]),
    number2: z.number().int().min(200).max(9000),
    moreNumbers: z.array(z.number().int().min(2).max(99)).length(3)
  }),
  simpleGenerator: () => {
    const random = getRandomBoolean();
    const number1 = getRandomFromArray([11, 21, 31, 41, 49, 51, 61, 71, 81, 91, 98, 99] as const);
    const number2 = random
      ? randomIntegerInclusiveStep(200, 900, 100)
      : randomIntegerInclusiveStep(2000, 9000, 1000);
    const moreNumbers = randomUniqueIntegersInclusive(2, 99, 3, {
      constraint: x => x !== number(math.evaluate(`(${number2} * ${number1}) * 0.01`))
    });

    return {
      number1,
      number2,
      moreNumbers
    };
  },

  Component: props => {
    const {
      question: { number1, number2, moreNumbers },
      translate,
      displayMode
    } = props;

    const [number3, number4, number5] = moreNumbers;

    // Items
    const item1 = number(math.evaluate(`(${number2} * ${number1}) * 0.01`));
    const item2 = number(math.evaluate(`(${number2} * ${number3}) * 0.01`));
    const item3 = number(math.evaluate(`(${number2} * ${number4}) * 0.01`));
    const item4 = number(math.evaluate(`(${number2} * ${number5}) * 0.01`));

    const statements = shuffle(
      [
        {
          component: item1.toLocaleString(),
          value: item1
        },
        {
          component: item2.toLocaleString(),
          value: item2
        },
        {
          component: item3.toLocaleString(),
          value: item3
        },
        {
          component: item4.toLocaleString(),
          value: item4
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.workOutThePercentage()}
        testCorrect={[item1]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <ContentBox
              containerStyle={{
                alignSelf: 'center',
                width: dimens.width / 3,
                height: displayMode === 'digital' ? 100 : null,
                justifyContent: 'center'
              }}
            >
              <Text variant="WRN400">
                {translate.answerSentences.xPercentOfY(number1, number2)}
              </Text>
            </ContentBox>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode === 'digital'
                ? translate.answerSentences.selectTheCorrectAnswer()
                : translate.answerSentences.circleTheCorrectAnswer()}
            </Text>
          </View>
        )}
        renderItems={statements.map(({ value, component }) => ({
          value,
          component: <Text variant="WRN700">{component.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aWj2',
  description: 'aWj',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int().min(200).max(9000),
    items: z
      .array(z.number().int().min(1).max(99))
      .length(4)
      .refine(val => arrayHasNoDuplicates(val))
  }),
  simpleGenerator: () => {
    const random = getRandomBoolean();
    const number1 = getRandomBoolean()
      ? randomIntegerInclusiveStep(5, 95, 5)
      : randomIntegerInclusive(1, 99, { constraint: x => x % 5 !== 0 });
    const number2 = random
      ? randomIntegerInclusiveStep(200, 900, 100)
      : randomIntegerInclusiveStep(2000, 9000, 1000);

    const items = shuffle([
      number1,
      ...randomUniqueIntegersInclusive(2, 99, 3, {
        constraint: x => x !== number1
      })
    ]);

    return {
      number1,
      number2,
      items
    };
  },

  Component: props => {
    const {
      question: { number1, number2, items },
      translate,
      displayMode
    } = props;

    // Items
    const answer = number(math.evaluate(`(${number2} * ${number1}) * 0.01`));

    const statements = items.map(x => {
      const num = number(math.evaluate(`(${number2} * ${x}) * 0.01`));
      return { component: num.toLocaleString(), value: num };
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.workOutThePercentage()}
        testCorrect={[answer]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <ContentBox
              containerStyle={{
                alignSelf: 'center',
                width: dimens.width / 3,
                height: displayMode === 'digital' ? 100 : null,
                justifyContent: 'center'
              }}
            >
              <Text variant="WRN400">
                {translate.answerSentences.xPercentOfY(number1, number2)}
              </Text>
            </ContentBox>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode === 'digital'
                ? translate.answerSentences.selectTheCorrectAnswer()
                : translate.answerSentences.circleTheCorrectAnswer()}
            </Text>
          </View>
        )}
        renderItems={statements.map(({ value, component }) => ({
          value,
          component: <Text variant="WRN700">{component.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aWk',
  description: 'aWk',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication'],
  schema: z.object({
    number1: z.number().int().min(20).max(90),
    number2: z.number().int().min(1000).max(9000).multipleOf(1000),
    number3: z.number().int().min(100).max(900).multipleOf(100),
    number4: z.number().int().min(10).max(90).multipleOf(10),
    random: z.array(z.boolean()).length(3)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(20, 90, 10);
    const number2 = randomIntegerInclusiveStep(1000, 9000, 1000);
    const number3 = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => x !== number2
    });
    const number4 = randomIntegerInclusiveStep(10, 90, 10, {
      constraint: x => x !== number3 && x !== number1
    });
    const random = [getRandomBoolean(), getRandomBoolean(), getRandomBoolean()];

    return { number1, number2, number3, number4, random };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, number4, random },
      translate
    } = props;

    // Sentences
    const sentences = [
      random[0]
        ? translate.answerSentences.xPercentOfYAns(number1, number2)
        : translate.answerSentences.ansEqualsXPercentOfY(number1, number2),
      random[1]
        ? translate.answerSentences.xPercentOfYAns(number1, number3)
        : translate.answerSentences.ansEqualsXPercentOfY(number1, number3),
      random[2]
        ? translate.answerSentences.xPercentOfYAns(number1, number4)
        : translate.answerSentences.ansEqualsXPercentOfY(number1, number4)
    ];

    // Answers
    const ans1 = number(math.evaluate(`${number2} * ${number1} * 0.01`));
    const ans2 = number(math.evaluate(`${number3} * ${number1} * 0.01`));
    const ans3 = number(math.evaluate(`${number4} * ${number1} * 0.01`));

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) &&
          compareFloats(userAnswer[1][0], ans2) &&
          compareFloats(userAnswer[2][0], ans3)
        }
        sentences={sentences}
        sentenceStyle={{ justifyContent: 'center' }}
        containerStyle={{ alignItems: random ? 'flex-end' : 'flex-start' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ans1.toLocaleString()],
            [ans2.toLocaleString()],
            [ans3.toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aWk2',
  description: 'aWk',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication'],
  schema: z.object({
    number1: z.number().int().min(20).max(90),
    number2: z.number().int().min(1000).max(9000).multipleOf(1000),
    number3: z.number().int().min(100).max(900).multipleOf(100),
    number4: z.number().int().min(10).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(20, 90, 10);
    const number2 = randomIntegerInclusiveStep(1000, 9000, 1000);
    const number3 = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => x !== number2
    });
    const number4 = randomIntegerInclusiveStep(10, 90, 10, {
      constraint: x => x !== number3 && x !== number1
    });

    return { number1, number2, number3, number4 };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    // Sentences
    const sentences = [
      translate.answerSentences.xPercentOfYAns(number1, number2),
      translate.answerSentences.xPercentOfYAns(number1, number3),
      translate.answerSentences.xPercentOfYAns(number1, number4)
    ];

    // Answers
    const ans1 = number(math.evaluate(`${number2} * ${number1} * 0.01`));
    const ans2 = number(math.evaluate(`${number3} * ${number1} * 0.01`));
    const ans3 = number(math.evaluate(`${number4} * ${number1} * 0.01`));

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) &&
          compareFloats(userAnswer[1][0], ans2) &&
          compareFloats(userAnswer[2][0], ans3)
        }
        sentences={sentences}
        sentenceStyle={{ justifyContent: 'center' }}
        containerStyle={{ alignItems: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ans1.toLocaleString()],
            [ans2.toLocaleString()],
            [ans3.toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aWl',
  description: 'aWl',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication'],
  schema: z.object({
    number1: z.number().int().min(10).max(100).multipleOf(10),
    number2: z.number().int().min(10).max(100).multipleOf(10),
    number3: z.number().int().min(10).max(100).multipleOf(10),
    number4: z.number().int().min(5).max(100).multipleOf(5),
    number5: z.number().int().min(1).max(99),
    number6: z.number().int().min(1).max(99),
    number7: z.number().min(0.5).max(99.5).multipleOf(0.5),
    number8: z.number().min(0.5).max(99.5).multipleOf(0.5),
    random: z.array(z.boolean()).length(4)
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const { number1, number2, number3, number4, number5, number6, number7, number8, random } =
      rejectionSample(
        () => {
          const [number1, number2, number3] = randomUniqueIntegersInclusiveStep(10, 100, 10, 3);
          const number4 = randomIntegerInclusiveStep(5, 100, 5);
          const [number5, number6] = randomUniqueIntegersInclusive(1, 99, 2);
          const number7 = randomIntegerInclusiveStep(5, number3 * 10 - 10, 5) / 10;
          const number8 = randomIntegerInclusiveStep(5, number4 * 10 - 5, 5) / 10;
          const random = [
            getRandomBoolean(),
            getRandomBoolean(),
            getRandomBoolean(),
            getRandomBoolean()
          ];

          return {
            number1,
            number2,
            number3,
            number4,
            number5,
            number6,
            number7,
            number8,
            random
          };
        },
        ({ number1, number2, number3, number4, number5, number6, number7, number8 }) =>
          arrayHasNoDuplicates([
            number1,
            number2,
            number3,
            number4,
            number5,
            number6,
            number7,
            number8
          ])
      );

    return { number1, number2, number3, number4, number5, number6, number7, number8, random };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6, number7, number8, random },
      translate
    } = props;

    // More numbers
    const number9 = number(math.evaluate(`${number1} * ${number2} * 0.01`));
    const number10 = number(math.evaluate(`${number3} * ${number4} * 0.01`));

    const productA = number(math.evaluate(`(${number1} * ${number2}) / 100`));
    const productB = number(math.evaluate(`(${number2} * ${number1}) / 100`));
    const productC = number(math.evaluate(`(${number3} * ${number4}) / 100`));
    const productD = number(math.evaluate(`(${number4} * ${number3}) / 100`));

    const sentences = shuffle(
      [
        {
          sentence: random[0]
            ? translate.answerSentences.xPercentOfYAns(number1, number2)
            : translate.answerSentences.ansEqualsXPercentOfY(number1, number2),
          answer: productA
        },
        {
          sentence: random[1]
            ? translate.answerSentences.xPercentOfYAns(number2, number1)
            : translate.answerSentences.ansEqualsXPercentOfY(number2, number1),
          answer: productB
        },
        {
          sentence: random[2]
            ? translate.answerSentences.xPercentOfYAns(number3, number4)
            : translate.answerSentences.ansEqualsXPercentOfY(number3, number4),
          answer: productC
        },
        {
          sentence: random[3]
            ? translate.answerSentences.xPercentOfYAns(number4, number3)
            : translate.answerSentences.ansEqualsXPercentOfY(number4, number3),
          answer: productD
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const answerOptions = shuffle([number5, number6, number7, number8, number9, number10], {
      random: seededRandom(props.question)
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculations()}
        actionPanelVariant="endWide"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        questionHeight={1100}
        moveOrCopy="copy"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PercentageOfAnAmountMultiStep',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question4, Question5]
});
export default SmallStep;
