import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getItemArrangement,
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange,
  range
} from '../../../../utils/collections';
import { getGroupSvgName } from '../../../../utils/objectsImages';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import { MULT } from '../../../../constants';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { displayMoney } from '../../../../utils/money';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjd',
  description: 'bjd',
  keywords: ['Multiply', '10 times-table'],
  schema: z.object({
    groups: z.number().int().min(1).max(12),
    item: z.enum([
      'MuffinRack',
      'Donut',
      'Egg',
      'Fish',
      'Marbles',
      'Sweets',
      'Balloons',
      'Pencils',
      '10p',
      '£10',
      'Crayons'
    ]),
    isRandomArrangement: z.boolean(),
    isAnsFirst: z.boolean(),
    options: z
      .array(z.number().int())
      .length(4)
      .refine(val => arrayHasNoDuplicates(val), 'must have no duplicate values')
  }),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(1, 12);
    const isRandomArrangement = getRandomBoolean();
    const isAnsFirst = getRandomBoolean();

    const item = getRandomFromArray([
      'MuffinRack',
      'Donut',
      'Egg',
      'Fish',
      'Marbles',
      'Sweets',
      'Balloons',
      'Pencils',
      '10p',
      '£10',
      'Crayons'
    ] as const);

    const incorrectOptions = [
      groups + 10,
      10,
      groups + 1,
      groups - 1,
      (groups - 1) * 10,
      (groups + 1) * 10
    ];

    const options = rejectionSample(
      () => {
        const incorrectOptions2 = getRandomSubArrayFromArray([...incorrectOptions] as const, 2);
        return [groups, groups * 10, ...incorrectOptions2];
      },
      val => arrayHasNoDuplicates(val)
    );

    return { groups, item, isRandomArrangement, isAnsFirst, options: shuffle(options) };
  },
  Component: props => {
    const {
      question: { groups, item, isRandomArrangement, isAnsFirst, options },
      translate
    } = props;

    const inEachGroup = 10;

    const svgName: SvgName = (() => {
      switch (item) {
        case 'Balloons':
        case 'Marbles':
        case 'Sweets':
          return `Base_Ten/${item}10`;
        case 'Donut':
        case 'Egg':
        case 'Fish':
        case 'MuffinRack':
          return getGroupSvgName(item, inEachGroup);
        case 'Crayons':
          return 'Pack_of_10_crayons';
        // I have defaulted this as Pounds10 is a png but I want svgName to have type svgName and I will override 10pounds later
        default:
          return 'pencil_packs/Pack_of_pencils_10';
      }
    })();

    const random = seededRandom(props.question);

    const arrangement =
      groups === 1
        ? []
        : getItemArrangement(
            countRange(groups).map(i => i),
            random,
            2,
            6
          );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheMultiplication()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheMultiplication()}
        actionPanelVariant="end"
        Content={({ dimens }) =>
          isRandomArrangement && groups !== 1 ? (
            <View style={{ rowGap: 20 }}>
              {arrangement.map((row, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: dimens.height / 3,
                    width: dimens.width
                  }}
                >
                  {row.map(({ marginLeft }, itemIndex) => (
                    <View key={itemIndex} style={{ marginLeft, gap: 16, justifyContent: 'center' }}>
                      {item === '£10' || item === '10p' ? (
                        displayMoney([item], dimens.width / 16, dimens.height / 3, true)
                      ) : (
                        <AssetSvg
                          name={svgName}
                          width={dimens.width / 8}
                          height={dimens.height / 3}
                        />
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          ) : (
            <View style={{ rowGap: 20 }}>
              {countRange(groups < 5 ? 1 : 2).map(i => (
                <View
                  key={i}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 20,
                    height: dimens.height / 3,
                    width: dimens.width
                  }}
                >
                  {countRange(
                    groups < 5
                      ? groups
                      : i === 0
                      ? Math.ceil(groups / 2)
                      : groups - Math.ceil(groups / 2)
                  ).map(idx =>
                    item === '£10' || item === '10p' ? (
                      displayMoney([item], dimens.width / 16, dimens.height / 3, true)
                    ) : (
                      <AssetSvg
                        key={idx}
                        name={svgName}
                        width={dimens.width / 8}
                        height={dimens.height / 3}
                      />
                    )
                  )}
                </View>
              ))}
            </View>
          )
        }
        testCorrect={[groups, groups * 10]}
        questionHeight={1000}
        sentencesStyle={{ alignItems: 'flex-start' }}
        sentence={
          isAnsFirst
            ? `<ans/> ${MULT} ${(10).toLocaleString()} = <ans/>`
            : `${(10).toLocaleString()} ${MULT} <ans/> = <ans/>`
        }
        items={options}
        pdfLayout="itemsTop"
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bje',
  description: 'bje',
  keywords: ['Number track', 'Multiplication', 'Count', 'Forwards', 'Backwards', '10 times-table'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(30).step(10),
    givenIndexes: z
      .array(z.number().min(0).max(7))
      .refine(arrayHasNoDuplicates)
      .refine(val =>
        val.some(
          i => val.includes(i + 1) || val.includes(i - 1),
          'must be at least two consecutive numbers'
        )
      ),
    isForward: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(0, 30, 10);
    const numberOfGiven = randomIntegerInclusive(3, 5);
    const givenIndex1 = randomIntegerInclusive(0, 7);
    const givenIndex2 =
      givenIndex1 === 0
        ? givenIndex1 + 1
        : givenIndex1 === 7
        ? givenIndex1 - 1
        : getRandomFromArray([givenIndex1 + 1, givenIndex1 - 1] as const);
    const otherGivenIndexes = randomUniqueIntegersInclusive(0, 7, numberOfGiven - 2, {
      constraint: x => ![givenIndex1, givenIndex2].includes(x)
    });
    const isForward = getRandomBoolean();

    return {
      startingNumber,
      givenIndexes: [givenIndex1, givenIndex2, ...otherGivenIndexes],
      isForward
    };
  },
  Component: ({ question: { startingNumber, givenIndexes, isForward }, translate }) => {
    // Create array to pass to NumberTrack
    const ascendingNumberArray = range(startingNumber, startingNumber + 70, 10);

    const numberArray = isForward ? ascendingNumberArray : [...ascendingNumberArray].reverse();

    const ansArray = numberArray
      .filter((_val, i) => !givenIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      givenIndexes.includes(id) ? val.toLocaleString() : '<ans/>'
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bjf',
  description: 'bjf',
  keywords: ['10 times-table', 'Multiply'],
  schema: z
    .object({
      multiplier: z.number().min(0).max(10),
      order: z.array(z.number()).length(2),
      totalFirst: z.boolean(),
      answerIndex: z.number().int().min(0).max(2)
    })
    .refine(val => arraysHaveSameContentsUnordered(val.order, [10, val.multiplier])),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(0, 10);
    const totalFirst = getRandomBoolean();
    const order = shuffle([10, multiplier]);
    const answerIndex = randomIntegerInclusive(0, 2, {
      constraint: x =>
        (multiplier === 0 &&
          (totalFirst ? x !== order.indexOf(10) + 1 : order.indexOf(10) !== x)) ||
        multiplier !== 0
    });
    return {
      multiplier,
      order,
      totalFirst,
      answerIndex
    };
  },
  Component: ({ question: { multiplier, order, totalFirst, answerIndex }, translate }) => {
    const numberArray = totalFirst ? [multiplier * 10, ...order] : [...order, multiplier * 10];
    const stringArray = numberArray.map((val, i) =>
      i === answerIndex ? '<ans/>' : val.toLocaleString()
    );
    const sentence = totalFirst
      ? `${stringArray[0]} = ${stringArray[1]} ${MULT} ${stringArray[2]}`
      : `${stringArray[0]} ${MULT} ${stringArray[1]} = ${stringArray[2]}`;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeMultiplication()}
        questionHeight={600}
        testCorrect={[numberArray[answerIndex].toString()]}
        sentence={sentence}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The10TimesTable',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
