import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { roundToTheNearest } from '../../../../utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { filledArray } from '../../../../utils/collections';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ae6',
  description: 'ae6',
  keywords: ['Place value', 'Round', 'Number line', 'Integer'],
  schema: z.object({
    number: z.number().int().min(10100).max(99900).multipleOf(100)
  }),
  example: {
    number: 73400
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(10100, 99900, 100, {
      // For a good question, avoid picking a number that's already a multiple of 10,000
      // Note: this constraint was added after launch
      constraint: x => x % 10000 !== 0
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickValues: (number | null)[] = filledArray(null, 11);
    tickValues[0] = 0;
    tickValues[5] = 50000;
    tickValues[10] = 100000;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest10000(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 10000).toString()]}
        {...props}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} focusNumber={number} />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ae7',
  description: 'ae7',
  keywords: ['Place value', 'Round', 'Integer'],
  schema: z.object({
    numbers: z.array(z.number().int().min(500).max(99999)).length(2)
  }),
  example: {
    numbers: [1675, 15678]
  },
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(500, 9500);
    const var2 = randomIntegerInclusive(9500, 99500);
    const numbers = [var1, var2];
    return { numbers: numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const equations = numbers.map(number => {
      const correctAnswer = roundToTheNearest(number, 1000);
      const sentence = `${number.toLocaleString()}  <ans/>`;
      return { correctAnswer, sentence };
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestX(1000)}
        testCorrect={equations.map(it => [it.correctAnswer.toString()])}
        sentences={equations.map(it => it.sentence)}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ae8',
  description: 'ae8',
  keywords: ['Place value', 'Round', 'Integer'],
  schema: z.object({
    numbers: z.array(z.number().int().min(5000).max(999999)).length(2)
  }),
  example: {
    numbers: [51675, 815678]
  },
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(5000, 95000);
    const var2 = randomIntegerInclusive(95000, 995000);
    const numbers = [var1, var2];
    return { numbers: numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const equations = numbers.map(number => {
      const correctAnswer = roundToTheNearest(number, 10000);
      const sentence = `${number.toLocaleString()}  <ans/>`;
      return { correctAnswer, sentence };
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestX(10000)}
        testCorrect={equations.map(it => [it.correctAnswer.toString()])}
        sentences={equations.map(it => it.sentence)}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ae9',
  description: 'ae9',
  keywords: ['Place value', 'Round', 'Integer'],
  schema: z.object({
    numbers: z.array(z.number().int().min(50000).max(9999999)).length(2)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(50000, 950000);
    const var2 = randomIntegerInclusive(950000, 9950000);
    const numbers = [var1, var2];
    return { numbers: numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const equations = numbers.map(number => {
      const correctAnswer = roundToTheNearest(number, 100000);
      const sentence = `${number.toLocaleString()}  <ans/>`;
      return { correctAnswer, sentence };
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestX(100000)}
        testCorrect={equations.map(it => [it.correctAnswer.toString()])}
        sentences={equations.map(it => it.sentence)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'afa',
  description: 'afa',
  keywords: ['Place value', 'Round', '100,000'],
  schema: z.object({
    comparitor: z.number().int().step(100).min(10001).max(999999),
    numbers: z.number().int().min(0).max(1000000).array().length(6)
  }),
  example: {
    comparitor: 70000,
    numbers: [63320, 65000, 68079, 72005, 75000, 78080]
  },
  simpleGenerator: () => {
    // Pick the comparitor.
    const comparitor = (randomIntegerInclusive(200, 999) / 10) * 10000;

    // $var-1001<num1<$var-500
    const num1 = randomIntegerInclusive(comparitor - 1000, comparitor - 501);

    // num2=$var-500
    const num2 = comparitor - 500;

    // $var-500<num3<$var
    const num3 = randomIntegerInclusive(comparitor - 499, comparitor - 1);

    // $var+500<num1<$var+1001
    const num4 = randomIntegerInclusive(comparitor + 1, comparitor + 499);

    // num2=$var+500
    const num5 = comparitor + 500;

    // $var<num3<$var+500
    const num6 = randomIntegerInclusive(comparitor + 501, comparitor + 1000);

    return { comparitor, numbers: shuffle([num1, num2, num3, num4, num5, num6]) };
  },
  Component: props => {
    const {
      question: { comparitor, numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatRoundToNumber(comparitor, 1000)}
        pdfTitle={translate.instructions.circleNumbersThatRoundToNumber(comparitor, 1000)}
        testCorrect={numbers.filter(it => roundToTheNearest(it, 1000) === comparitor)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'afb',
  description: 'afb',
  keywords: ['Place value', 'Round', 'Integer'],
  schema: z.object({
    number: z.number().int().min(100000).max(9999999)
  }),
  simpleGenerator: () => {
    const millions = randomIntegerInclusive(0, 9);
    const hundredThousands = randomIntegerInclusive(1, 9);
    const tenThousands = randomIntegerInclusive(0, 9);
    const thousands = randomIntegerInclusive(0, 9);
    const hundreds = randomIntegerInclusive(0, 9);
    const tens = randomIntegerInclusive(0, 9);
    const ones = randomIntegerInclusive(0, 9);
    const number =
      millions * 1000000 +
      hundredThousands * 100000 +
      tenThousands * 10000 +
      thousands * 1000 +
      hundreds * 100 +
      tens * 10 +
      ones;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      displayMode,
      translate
    } = props;
    const nearest1000 = roundToTheNearest(number, 1000);
    const nearest10000 = roundToTheNearest(number, 10000);
    const nearest100000 = roundToTheNearest(number, 100000);
    const nearest1000000 = roundToTheNearest(number, 1000000);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[
          [nearest1000.toString()],
          [nearest10000.toString()],
          [nearest100000.toString()],
          [nearest1000000.toString()]
        ]}
        sentences={[
          translate.answerSentences.roundToNearest1000(number),
          translate.answerSentences.roundToNearest10000(number),
          translate.answerSentences.roundToNearest100000(number),
          translate.answerSentences.roundToNearest1000000(number)
        ]}
        containerStyle={{ alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        // Decrease the font size, but only necessary for digital version
        inputStyle={displayMode === 'digital' && { fontSize: 28 }}
        textStyle={displayMode === 'digital' && { fontSize: 28 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundAnyInteger',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
