import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { arrayHasNoDuplicates, sumNumberArray } from '../../../../utils/collections';
import {
  CompoundShapeSchema,
  TwoShapesOnGridSchema,
  TwoShapesSchema,
  compoundShapeOnGridNoLabels,
  compoundShapes,
  getRandomCompoundShapeName,
  getRandomCompoundShapeOnGridNoLabelsName,
  getRandomTwoShapesName,
  getRandomTwoShapesOnGridName,
  archivedRectilinearShapes,
  twoShapes,
  twoShapesOnGrid,
  CompoundShapeOnGridNoLabelsSchema
} from '../../../../utils/compoundShapes';
import { AssetSvg } from '../../../../assets/svg';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { ShapeNames } from '../../../../utils/labelPositions';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import {
  getRandomRectilinearNonLShape,
  getRandomRectilinearShape,
  RectilinearShapeNameSchema,
  nonLShapeProperties,
  NonLShapesNameSchema
} from '../../../../utils/rectilinearShapes';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aR6',
  description: 'aR6',
  keywords: [
    'Area',
    'Centimetres squared',
    'cm2',
    'Shape',
    'Multiplication',
    'Addition',
    'Length',
    'Width',
    'Grid',
    'Squares',
    'Compound shape',
    'Rectangle',
    'Rectilinear shape'
  ],
  schema: z.object({
    shape: CompoundShapeOnGridNoLabelsSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomCompoundShapeOnGridNoLabelsName();

    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;
    const { svgName, totalArea } = compoundShapeOnGridNoLabels[shape];

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.onTheGridTheAreaOfEachSquareIsX(
          translate.units.numberOfCm2(1)
        )}.<br/>${translate.instructions.calcAreaOfShape()}`}
        Content={({ dimens }) => (
          <AssetSvg name={svgName} height={dimens.height} width={dimens.width} />
        )}
        sentence={translate.answerSentences.ansCmSquared()}
        testCorrect={[totalArea.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aR7',
  description: 'aR7',
  keywords: [
    'Area',
    'Centimetres squared',
    'cm2',
    'Shape',
    'Multiplication',
    'Addition',
    'Length',
    'Width',
    'Grid',
    'Squares',
    'Compound shape',
    'Rectangle',
    'Rectilinear shape'
  ],
  schema: z.object({
    shape: TwoShapesOnGridSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomTwoShapesOnGridName();

    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    const { svgName, totalArea } = twoShapesOnGrid[shape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.compoundShapeIsMadeOfTwoRectanglesABWhatIsAreaOfCompoundShape()}
        Content={({ dimens }) => (
          <AssetSvg name={svgName} height={dimens.height} width={dimens.width} />
        )}
        sentence={translate.answerSentences.ansCmSquared()}
        testCorrect={[totalArea.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aR8',
  description: 'aR8',
  keywords: [
    'Area',
    'Centimetres squared',
    'cm2',
    'Shape',
    'Multiplication',
    'Addition',
    'Length',
    'Width',
    'Compound shape',
    'Rectangle'
  ],
  schema: z.object({
    shape: TwoShapesSchema,
    sideLengths: z.array(z.number().int().min(1).max(12)).length(4),
    unit: z.enum(['cm2', 'm2', 'mm2']),
    areaTotal: z.number().int().min(3).max(288),
    allOptions: z
      .array(z.number().int().min(3).max(288))
      .length(6)
      .refine(x => arrayHasNoDuplicates(x))
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shape = getRandomTwoShapesName();

    const { sideRatios, areaA, areaB } = twoShapes[shape];
    const maxLength = Math.max(...sideRatios);
    const scale = randomIntegerInclusive(1, 12 / maxLength);

    const sideLengths = sideRatios.map(l => l * scale);
    const areaOfA = areaA.reduce((acc, curr) => acc * curr * scale, 1);
    const areaOfB = areaB.reduce((acc, curr) => acc * curr * scale, 1);
    const areaTotal = areaOfA + areaOfB;

    const unit = getRandomFromArray(['cm2', 'mm2', 'm2'] as const);

    const wrongArea = sideLengths.reduce((acc, curr) => acc + curr * scale, 0);
    const options = [...new Set([areaTotal, areaOfA, areaOfB, wrongArea, wrongArea * 2])];
    const wrongOptions = randomUniqueIntegersInclusive(4, 288, 6 - options.length, {
      constraint: x => arrayHasNoDuplicates([...options, x])
    });
    const allOptions = shuffle([...options, ...wrongOptions]);

    return { shape, sideLengths, unit, areaTotal, allOptions };
  },
  Component: props => {
    const {
      question: { shape, sideLengths, unit, areaTotal, allOptions },
      translate
    } = props;

    const sideUnit = () => {
      switch (unit) {
        case 'cm2': {
          return 'numberOfCm';
        }
        case 'm2': {
          return 'numberOfM';
        }
        case 'mm2': {
          return 'numberOfMm';
        }
      }
    };

    const sideLabels = sideLengths.map(l => translate.units[sideUnit()](l));

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardToWorkOutTheArea()}
        pdfTitle={translate.instructions.useCardToWorkOutTheArea()}
        sentence={translate.answerSentences.areaOfCompoundShapeIsAnsY(translate.units[unit]())}
        testCorrect={[areaTotal]}
        items={allOptions}
        Content={({ dimens }) => (
          <LabelledShape shapeName={shape as ShapeNames} dimens={dimens} labels={sideLabels} />
        )}
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aR9',
  description: 'aR9',
  keywords: [
    'Area',
    'Centimetres squared',
    'cm2',
    'Shape',
    'Multiplication',
    'Addition',
    'Length',
    'Width',
    'Compound shape',
    'Rectangle',
    'Rectilinear shape'
  ],
  schema: z.object({
    shape: CompoundShapeSchema,
    sideLengths: z.array(z.number().int().min(1).max(12)),
    unit: z.enum(['cm2', 'm2', 'mm2']),
    totalArea: z.number().int().min(3).max(288),
    allOptions: z
      .array(z.number().int().min(3).max(288))
      .length(4)
      .refine(x => arrayHasNoDuplicates(x))
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shape = getRandomCompoundShapeName();

    const { sideRatios, compoundSides } = compoundShapes[shape];
    const maxLength = Math.max(...sideRatios);
    const scale = randomIntegerInclusive(1, 12 / maxLength);

    const sideLengths = sideRatios.map(l => l * scale);
    const totalArea = compoundSides.reduce((acc, [l, w]) => acc + l * w, 0) * Math.pow(scale, 2);

    const wrongArea = sideLengths.reduce((acc, curr) => acc + curr * scale, 0);
    const options = Array.from(new Set([totalArea, wrongArea]));
    const wrongOptions = randomUniqueIntegersInclusive(4, 288, 4 - options.length, {
      constraint: x => arrayHasNoDuplicates([...options, x])
    });
    const allOptions = shuffle([...options, ...wrongOptions]);

    const unit = getRandomFromArray(['cm2', 'mm2', 'm2'] as const);
    return { shape, sideLengths, unit, totalArea, allOptions };
  },
  Component: props => {
    const {
      question: { shape, sideLengths, unit, totalArea, allOptions },
      translate
    } = props;

    const sideUnit = () => {
      switch (unit) {
        case 'cm2': {
          return 'numberOfCm';
        }
        case 'm2': {
          return 'numberOfM';
        }
        case 'mm2': {
          return 'numberOfMm';
        }
      }
    };

    const sideLabels = sideLengths.map(l => translate.units[sideUnit()](l));

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardToWorkOutTheAreaOfRectilinearShape()}
        pdfTitle={translate.instructions.useCardToWorkOutTheAreaOfRectilinearShape()}
        sentence={`<ans/> ${translate.units[unit]()}`}
        actionPanelVariant="end"
        items={allOptions}
        testCorrect={[totalArea]}
        Content={({ dimens }) => (
          <LabelledShape shapeName={shape as ShapeNames} dimens={dimens} labels={sideLabels} />
        )}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aSa',
  description: 'aSa',
  keywords: [
    'Area',
    'Centimetres squared',
    'cm2',
    'Shape',
    'Multiplication',
    'Addition',
    'Length',
    'Width',
    'Compound shape',
    'Rectangle',
    'Rectilinear shape'
  ],
  schema: z.object({
    shape: RectilinearShapeNameSchema,
    sideLengths: z.array(z.number().int().min(1).max(12)),
    unit: z.enum(['cm2', 'm2', 'mm2']),
    totalArea: z.number().int().min(3).max(288),
    allOptions: z
      .array(z.number().int().min(3).max(288))
      .length(4)
      .refine(x => arrayHasNoDuplicates(x))
  }),
  simpleGenerator: () => {
    const shape = getRandomRectilinearShape();

    const { sideRatios, compoundSides } = archivedRectilinearShapes[shape];

    const sideLengths = [...sideRatios];
    const totalArea = compoundSides.reduce((acc, [l, w]) => acc + l * w, 0);

    const wrongArea = sideLengths.reduce((acc, curr) => acc + curr, 0);
    const options = Array.from(new Set([totalArea, wrongArea]));
    const wrongOptions = randomUniqueIntegersInclusive(4, 288, 4 - options.length, {
      constraint: x => arrayHasNoDuplicates([...options, x])
    });
    const allOptions = shuffle([...options, ...wrongOptions]);

    const unit = getRandomFromArray(['cm2', 'mm2', 'm2'] as const);
    return { shape, sideLengths, unit, totalArea, allOptions };
  },
  Component: props => {
    const {
      question: { shape, sideLengths, unit, totalArea, allOptions },
      translate
    } = props;

    const sideUnit = () => {
      switch (unit) {
        case 'cm2': {
          return 'numberOfCm';
        }
        case 'm2': {
          return 'numberOfM';
        }
        case 'mm2': {
          return 'numberOfMm';
        }
      }
    };
    const areaUnits = () => {
      switch (unit) {
        case 'cm2': {
          return 'numberOfCm2';
        }
        case 'm2': {
          return 'numberOfM2';
        }
        case 'mm2': {
          return 'numberOfMm2';
        }
      }
    };

    const sideLabels = sideLengths.map(l => translate.units[sideUnit()](l));

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectAreaOfRectilinearShape()}
        pdfTitle={translate.instructions.circleAreaOfRectilinearShape()}
        selectables={Object.fromEntries(
          allOptions.map(key => [key.toString(), translate.units[areaUnits()](key)])
        )}
        correctAnswer={[totalArea.toString()]}
        leftContent={
          <MeasureView>
            {dimens => (
              <LabelledShape
                shapeName={shape as ShapeNames}
                dimens={{ height: dimens.height, width: dimens.width * 0.8 }}
                labels={sideLabels}
                seed={props.question}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aSa2',
  description: 'aSa',
  keywords: [
    'Area',
    'Centimetres squared',
    'cm2',
    'Shape',
    'Multiplication',
    'Addition',
    'Length',
    'Width',
    'Compound shape',
    'Rectangle',
    'Rectilinear shape'
  ],
  schema: z.object({
    shape: NonLShapesNameSchema,
    sideLengths: z.array(z.number().int().min(1).max(12)),
    unit: z.enum(['cm2', 'm2', 'mm2']),
    totalArea: z.number().int().min(3).max(288),
    allOptions: z
      .array(z.number().int().min(3).max(288))
      .length(4)
      .refine(x => arrayHasNoDuplicates(x))
  }),
  simpleGenerator: () => {
    const shape = getRandomRectilinearNonLShape();

    const { sideLengthRatioOptions, calcArea } = nonLShapeProperties[shape];

    const sideLengths = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptions) as number[],
      // Side length should be a max of 12
      x => Math.max(...x) <= 12
    );

    const totalArea = calcArea(sideLengths);

    const wrongArea = sumNumberArray(sideLengths);
    const options = [...new Set([totalArea, wrongArea])];
    const wrongOptions = randomUniqueIntegersInclusive(4, 288, 4 - options.length, {
      constraint: x => arrayHasNoDuplicates([...options, x])
    });
    const allOptions = shuffle([...options, ...wrongOptions]);

    const unit = getRandomFromArray(['cm2', 'mm2', 'm2'] as const);
    return { shape, sideLengths, unit, totalArea, allOptions };
  },
  Component: props => {
    const {
      question: { shape, sideLengths, unit, totalArea, allOptions },
      translate
    } = props;

    const sideUnit = () => {
      switch (unit) {
        case 'cm2': {
          return 'numberOfCm';
        }
        case 'm2': {
          return 'numberOfM';
        }
        case 'mm2': {
          return 'numberOfMm';
        }
      }
    };
    const areaUnits = () => {
      switch (unit) {
        case 'cm2': {
          return 'numberOfCm2';
        }
        case 'm2': {
          return 'numberOfM2';
        }
        case 'mm2': {
          return 'numberOfMm2';
        }
      }
    };

    const sideLabels = sideLengths.map(l => translate.units[sideUnit()](l));

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectAreaOfRectilinearShape()}
        pdfTitle={translate.instructions.circleAreaOfRectilinearShape()}
        selectables={Object.fromEntries(
          allOptions.map(key => [key.toString(), translate.units[areaUnits()](key)])
        )}
        correctAnswer={[totalArea.toString()]}
        leftContent={
          <MeasureView>
            {dimens => (
              <LabelledShape
                shapeName={shape}
                dimens={{ height: dimens.height, width: dimens.width * 0.8 }}
                labels={sideLabels}
                seed={props.question}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aSb',
  description: 'aSb',
  keywords: [
    'Area',
    'Centimetres squared',
    'cm2',
    'Shape',
    'Multiplication',
    'Addition',
    'Length',
    'Width',
    'Compound shape',
    'Rectangle',
    'Rectilinear shape'
  ],
  schema: z.object({
    shape: CompoundShapeSchema,
    unit: z.enum(['cm2', 'm2', 'mm2']),
    scale: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const shape = getRandomCompoundShapeName();
    const { sideRatios } = compoundShapes[shape];
    const maxLength = Math.max(...sideRatios);
    const scale = randomIntegerInclusive(1, 25 / maxLength);
    const unit = getRandomFromArray(['cm2', 'mm2', 'm2'] as const);
    return { shape, unit, scale };
  },
  Component: props => {
    const {
      question: { shape, unit, scale },
      translate
    } = props;

    const [sideUnit, sentence]: ['numberOfCm' | 'numberOfM' | 'numberOfMm', string] = (() => {
      switch (unit) {
        case 'cm2': {
          return ['numberOfCm', translate.answerSentences.ansCmSquared()];
        }
        case 'm2': {
          return ['numberOfM', translate.answerSentences.ansMSquared()];
        }
        case 'mm2': {
          return ['numberOfMm', translate.answerSentences.ansMmSquared()];
        }
      }
    })();

    const { sideRatios, compoundSides } = compoundShapes[shape];

    const sideLabels = sideRatios.map(l => translate.units[sideUnit](l * scale));
    const totalArea = compoundSides.reduce((acc, [l, w]) => acc + l * w, 0) * Math.pow(scale, 2);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcAreaOfCompoundShape()}
        Content={({ dimens }) => (
          <LabelledShape shapeName={shape as ShapeNames} dimens={dimens} labels={sideLabels} />
        )}
        sentence={sentence}
        testCorrect={[totalArea.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AreaOfCompoundShapes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
