import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  randomNumberWithSpecificDigit,
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle,
  rejectionSample,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { CounterVariantNoBlockSchema } from '../../../../components/question/representations/types';
import ReadPlaceValueChart from '../../../../components/question/questionFormats/ReadPlaceValueChart';
import CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import {
  Digit,
  numberToBase10Object,
  powersOfTenPowToWord,
  ScientificNotation
} from '../../../../utils/math';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

export const Question1 = newQuestionContent({
  uid: 'adm',
  description: 'adm',
  keywords: ['Place value', 'Represent', '100,000', 'Chart'],
  schema: z.object({
    number: z.number().int().min(10001).max(99999),
    counterVariant: CounterVariantNoBlockSchema
  }),
  simpleGenerator: () => {
    // Generate random number between 10001 and 99999
    const number = randomIntegerInclusive(10001, 99999);

    const counterVariant = 'greyCounter' as const;

    return { number, counterVariant };
  },
  Component: ({ question }) => (
    <ReadPlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[4, 3, 2, 1, 0]}
      counterVariant={question.counterVariant}
      headerVariant={'shortName'}
      questionHeight={1050}
    />
  ),
  questionHeight: 1050
});

export const Question2 = newQuestionContent({
  uid: 'adn',
  description: 'adn',
  keywords: ['Place value', 'Represent', '100,000', 'Chart'],
  schema: z.object({
    number: z.number().min(10001).max(99999)
  }),
  example: {
    number: 32465
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(10001, 99999);
    return { number };
  },
  Component: ({ question, translate, ...props }) => (
    <CreatePlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[4, 3, 2, 1, 0]}
      counterVariant="greyCounter"
      pdfTitle={translate.instructions.drawCountersToRepresentNum(question.number)}
      headerVariant="shortName"
      {...props}
    />
  )
});

export const Question3 = newQuestionContent({
  uid: 'ado',
  description: 'ado',
  keywords: ['Place value', 'Represent', '100,000', 'Bar model'],
  schema: z.object({
    total: z.number().min(10001).max(99999),
    powerOfAnswer: z.number().int().min(2).max(4) as z.Schema<2 | 3 | 4>,
    answerIndex: z.number().min(0).max(1)
  }),
  simpleGenerator: () => {
    const powerOfAnswer = randomIntegerInclusive(2, 4) as 2 | 3 | 4;

    // Prevents answer being 0 and rejects multiples of 10,000
    const total = rejectionSample(
      () =>
        randomNumberWithSpecificDigit(
          powerOfAnswer,
          { lower: 10001, upper: 99999 },
          randomIntegerInclusive(1, 9) as Digit
        ),
      x => x % 10000 !== 0
    );

    const answerIndex = randomIntegerInclusive(0, 1);

    return { total, powerOfAnswer, answerIndex };
  },
  Component: ({ question: { total, powerOfAnswer, answerIndex }, translate, ...props }) => {
    const hiddenBarFactor = ScientificNotation.fromNumber(total).unsignedDigitAt(powerOfAnswer);
    const hiddenBarAmount = hiddenBarFactor ? Math.pow(10, powerOfAnswer) * hiddenBarFactor : 0;
    const givenNumber = total - hiddenBarAmount;

    const numbers = [
      [total],
      answerIndex === 0 ? [hiddenBarAmount, givenNumber] : [givenNumber, hiddenBarAmount]
    ];
    const answerIndices = [[], [answerIndex]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={total}
        oneFontSize
        {...props}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'adp',
  description: 'adp',
  keywords: ['Place value', 'Represent', '100,000', 'Select'],
  schema: z.object({
    numbers: z.number().int().min(10000).max(99999).array().length(8),
    digit: z.number().int().min(1).max(9),
    power: z.enum(['tens', 'hundreds', 'thousands', 'tenThousands'])
  }),
  example: {
    numbers: [14040, 39865, 21234, 51009, 15249, 62048, 86000, 43616],
    digit: 4,
    power: 'tens'
  },
  simpleGenerator: () => {
    const power = getRandomFromArray(['tens', 'hundreds', 'thousands', 'tenThousands'] as const);
    const digit = randomIntegerInclusive(1, 9) as Digit;
    const range = { lower: 10000, upper: 99999 };

    const num1 = randomNumberWithSpecificDigit(4, range, digit);
    const num2 = randomNumberWithSpecificDigit(4, range, digit);
    const num3 = randomNumberWithSpecificDigit(3, range, digit);
    const num4 = randomNumberWithSpecificDigit(3, range, digit);
    const num5 = randomNumberWithSpecificDigit(2, range, digit);
    const num6 = randomNumberWithSpecificDigit(2, range, digit);
    const num7 = randomNumberWithSpecificDigit(1, range, digit);
    const num8 = randomNumberWithSpecificDigit(1, range, digit);

    return { numbers: shuffle([num1, num2, num3, num4, num5, num6, num7, num8]), digit, power };
  },
  Component: props => {
    const {
      question: { numbers, digit, power },
      translate
    } = props;

    const powerAsString = translate.powersOfTen[power](0);

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatHaveXInTheYPlace(digit, powerAsString)}
        pdfTitle={translate.instructions.circleNumbersThatHaveXInTheYPlace(digit, powerAsString)}
        testCorrect={numbers.filter(it => numberToBase10Object(it)[power] === digit)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'adq',
  description: 'adq',
  keywords: ['Place value', 'Represent', '100,000'],
  schema: z
    .object({
      digits: z.array(z.number().int().min(0).max(9)).min(5).max(5),
      powerOfTen: z.number().int().min(1).max(4) as z.Schema<1 | 2 | 3 | 4>,
      value: z.number().int().min(0).max(9)
    })
    .refine(val => val.digits.includes(val.value), 'The value must be one of the choices'),
  simpleGenerator: () => {
    const numOfValues = 5;
    const value = randomIntegerInclusive(0, 9);

    // Ensure question doesn't ask for 0 in the first column - also, only allow the first four unit types to be selected
    const powerOfTen = (
      value === 0 ? randomIntegerInclusive(1, 3) : randomIntegerInclusive(1, 4)
    ) as 1 | 2 | 3 | 4;

    // Ensure each digit will be unique
    const digits = new Set<number>();

    digits.add(value);
    while (digits.size < numOfValues) {
      digits.add(randomIntegerInclusive(0, 9));
    }

    const shuffled = shuffle(Array.from(digits));

    return { digits: shuffled, value, powerOfTen };
  },
  Component: props => {
    const {
      question: { digits, value, powerOfTen },
      translate
    } = props;

    const testCorrect = (ans: readonly (string | undefined)[]): boolean => {
      if (ans[0] === (0).toLocaleString()) {
        return false;
      }
      return ScientificNotation.fromFixedString(ans.join('')).digitAt(powerOfTen) === value;
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.create5DigitNumberWith(
          value,
          translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
        )}
        pdfTitle={translate.instructions.create5DigitNumberWithDigitsOnlyUsedOnce(
          value,
          translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
        )}
        items={digits.map(num => num.toLocaleString())}
        sentence={'<ans/> '.repeat(5)}
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyNumberWithXInTheYPlace(
            value,
            translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
          )
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

export const Question6 = newQuestionContent({
  uid: 'adr',
  description: 'adr',
  keywords: ['Place value', 'Represent', '100,000', 'Part-whole'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(10000)
      .max(100000)
      .multipleOf(100)
      .refine(x => x % 10000 !== 0),
    unitOfAnswer: z.enum(['thousands', 'tenThousands', 'hundreds'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const unitOfAnswer = getRandomFromArray(['hundreds', 'thousands', 'tenThousands'] as const);
    const number = randomIntegerInclusiveStep(10000, 100000, 100, {
      constraint: x => x % 10000 !== 0 && numberToBase10Object(x)[unitOfAnswer] !== 0
    });
    return { number, unitOfAnswer };
  },
  Component: ({ question: { number, unitOfAnswer }, translate, displayMode }) => {
    const { hundreds = 0, thousands = 0, tenThousands = 0 } = numberToBase10Object(number);

    let partition: (number | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'hundreds') {
      partition = ['$ans', thousands * 1000 + tenThousands * 10000];
      correctAnswer = hundreds * 100;
    } else if (unitOfAnswer === 'thousands') {
      partition = ['$ans', hundreds * 100 + tenThousands * 10000];
      correctAnswer = thousands * 1000;
    } else {
      partition = ['$ans', hundreds * 100 + thousands * 1000];
      correctAnswer = tenThousands * 10000;
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        initialState={displayMode === 'markscheme' ? [correctAnswer.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => answer[0] === correctAnswer.toString()}
        inputType="numpad"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={number}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumbersTo100000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
