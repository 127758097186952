import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { BarModelColorsKey, barModelColors, barModelColorsArray } from '../../../../theme/colors';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import TextStructure from '../../../../components/molecules/TextStructure';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  equalFractionShapePartsSchema,
  equalShapeByPartSVGPaths,
  equalShapePartsSchema,
  fractionPartSVGPaths,
  getEqualShapesSVGPaths,
  getRandomEqualShapePart,
  getRandomUnequalShapePart,
  getRandomUniqueEqualFractionParts,
  getUnequalShapesSVGPaths,
  shapePartsArray,
  shapePartsSchema,
  allPartsShapesSchema,
  unequalShapePartsSchema,
  getRandomEqualFractionPart
} from '../../../../utils/shapes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { compareFractions } from '../../../../utils/fractions';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aHK',
  description: 'aHK',
  keywords: ['Fraction', 'Equal', 'Unequal', 'Shape'],
  schema: z
    .object({
      equalShape: equalShapePartsSchema,
      unequalShape: unequalShapePartsSchema,
      equalOrUnequalShapeA: shapePartsSchema,
      equalOrUnequalShapeB: shapePartsSchema
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.equalShape,
          val.unequalShape,
          val.equalOrUnequalShapeA,
          val.equalOrUnequalShapeB
        ]),
      'All shapes must be different.'
    ),
  simpleGenerator: () => {
    const equalShape = getRandomEqualShapePart();

    const unequalShape = getRandomUnequalShapePart();

    const [equalOrUnequalShapeA, equalOrUnequalShapeB] = getRandomSubArrayFromArray(
      shapePartsArray,
      2,
      {
        constraint: x => arrayHasNoDuplicates([x, equalShape, unequalShape])
      }
    );

    return {
      equalShape,
      unequalShape,
      equalOrUnequalShapeA,
      equalOrUnequalShapeB
    };
  },
  Component: props => {
    const {
      question: { equalShape, unequalShape, equalOrUnequalShapeA, equalOrUnequalShapeB },
      translate
    } = props;

    const items = shuffle(
      [
        {
          name: equalShape,
          isCorrect: true,
          value: 1
        },
        {
          name: unequalShape,
          isCorrect: false,
          value: 2
        },
        {
          name: equalOrUnequalShapeA,
          isCorrect: !equalOrUnequalShapeA.includes('Unequal'),
          value: 3
        },
        {
          name: equalOrUnequalShapeB,
          isCorrect: !equalOrUnequalShapeB.includes('Unequal'),
          value: 4
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const correctAnswers = items.filter(item => item.isCorrect);

    return (
      <QF11SelectImagesUpTo4
        title={
          correctAnswers.length > 1
            ? translate.instructions.selectShapesThatHaveBeenSplitIntoEqualParts()
            : translate.instructions.selectShapeThatHasBeenSplitIntoEqualParts()
        }
        pdfTitle={
          correctAnswers.length > 1
            ? translate.instructions.circleShapesThatHaveBeenSplitIntoEqualParts()
            : translate.instructions.circleShapeThatHasBeenSplitIntoEqualParts()
        }
        testCorrect={correctAnswers.map(item => item.value)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return items.map(({ name, value }) => ({
            value,
            component: <AssetSvg name={name} height={dimens.height * 0.9} />
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aHL',
  description: 'aHL',
  keywords: ['Fraction', 'Equal', 'Bar model', 'Denominator'],
  schema: z.object({
    denominator: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 8);

    return { denominator };
  },
  Component: props => {
    const {
      question: { denominator },
      translate
    } = props;

    const numbers = [filledArray(1, denominator)];

    const strings = [filledArray('', denominator)];

    const numeratorColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    });

    const customColorMap = [
      barModelColors[numeratorColor as BarModelColorsKey],
      ...filledArray('white', denominator - 1)
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        Content={({ dimens }) => (
          <BarModel
            total={denominator}
            dimens={dimens}
            numbers={numbers}
            strings={strings}
            sameRowColor
            cellColors={[customColorMap]}
            pdfShadingOverride
          />
        )}
        sentences={[
          translate.answerSentences.theWholeIsSplitIntoXEqualParts(),
          translate.answerSentences.theFractionShadedIsX(`<frac n='1' dAns=''/>`)
        ]}
        fractionContainerStyle={{ height: 96 }}
        testCorrect={[[denominator.toString()], [denominator.toString()]]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHM',
  description: 'aHM',
  keywords: ['Fraction', 'Denominator', 'Equal parts', 'Unequal parts', 'Shaded'],
  schema: z.object({
    correctAnswers: equalFractionShapePartsSchema.array(),
    incorrectShapes: allPartsShapesSchema.array(),
    denominator: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 5);
    const numberOfCorrect = randomIntegerInclusive(1, denominator === 2 ? 3 : 2);
    const correctAnswers = getRandomUniqueEqualFractionParts(
      numberOfCorrect,
      denominator as 2 | 3 | 4 | 5
    );

    const incorrectShapes = getRandomSubArrayFromArray(
      [
        ...fractionPartSVGPaths.unequal[denominator as 2 | 3 | 4 | 5],
        ...equalShapeByPartSVGPaths[denominator as 2 | 3 | 4 | 5]
      ] as const,
      4 - numberOfCorrect
    );

    return {
      correctAnswers,
      incorrectShapes,
      denominator
    };
  },
  Component: props => {
    const {
      question: { correctAnswers, incorrectShapes, denominator },
      translate
    } = props;

    const items = shuffle(
      [
        ...correctAnswers.map(val => ({ name: val, isCorrect: true })),
        ...incorrectShapes.map(val => ({ name: val, isCorrect: false }))
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const correctItems = items.filter(item => item.isCorrect);

    return (
      <QF11SelectImagesUpTo4
        title={
          correctAnswers.length > 1
            ? translate.instructions.selectShapesThatHaveXShaded(
                `<frac n='1' d='${denominator}' />`
              )
            : translate.instructions.selectShapeThatHasXShaded(`<frac n='1' d='${denominator}' />`)
        }
        pdfTitle={
          correctAnswers.length > 1
            ? translate.instructions.circleShapesThatHaveXShaded(
                `<frac n='1' d='${denominator}' />`
              )
            : translate.instructions.circleShapeThatHasXShaded(`<frac n='1' d='${denominator}' />`)
        }
        testCorrect={correctItems.map(item => item.name)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return items.map(({ name }) => ({
            value: name,
            component: <AssetSvg name={name} height={dimens.height * 0.9} />
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aHN',
  description: 'aHN',
  keywords: ['Equal parts', 'Unequal parts', 'Denominators'],
  schema: z.object({
    partsPerShape: z.number().int().min(2).max(5),
    numberOfEqualShapes: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const partsPerShape = randomIntegerInclusive(2, 5);

    const numberOfEqualShapes = randomIntegerInclusive(1, 3);

    return { partsPerShape, numberOfEqualShapes };
  },
  Component: props => {
    const {
      question: { partsPerShape, numberOfEqualShapes },
      translate
    } = props;

    const equalShapePaths = getEqualShapesSVGPaths(partsPerShape);

    const unequalShapePaths = getUnequalShapesSVGPaths(partsPerShape);

    const equalShapes = getRandomSubArrayFromArray(equalShapePaths, numberOfEqualShapes, {
      random: seededRandom(props.question)
    });

    const unequalShapes = getRandomSubArrayFromArray(unequalShapePaths, 4 - numberOfEqualShapes, {
      random: seededRandom(props.question)
    });

    const allShapes = [...equalShapes, ...unequalShapes];

    const items = shuffle(
      [
        {
          name: allShapes[0],
          isCorrect: true,
          value: 1
        },
        {
          name: allShapes[1],
          isCorrect: numberOfEqualShapes >= 2,
          value: 2
        },
        {
          name: allShapes[2],
          isCorrect: numberOfEqualShapes >= 3,
          value: 3
        },
        {
          name: allShapes[3],
          isCorrect: false,
          value: 4
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const itemsCorrect = items.filter(item => item.isCorrect);

    return (
      <QF11SelectImagesUpTo4
        title={
          itemsCorrect.length > 1
            ? translate.instructions.selectShapesThatHaveBeenSplitIntoNumEqualParts(partsPerShape)
            : translate.instructions.selectShapeThatHasBeenSplitIntoNumEqualParts(partsPerShape)
        }
        pdfTitle={
          itemsCorrect.length > 1
            ? translate.instructions.circleShapesThatHaveBeenSplitIntoNumEqualParts(partsPerShape)
            : translate.instructions.circleShapeThatHasBeenSplitIntoNumEqualParts(partsPerShape)
        }
        testCorrect={itemsCorrect.map(item => item.value)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return items.map(({ name, value }) => ({
            value,
            component: <AssetSvg name={name} height={dimens.height * 0.9} />
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aHO',
  description: 'aHO',
  keywords: ['Fraction', 'Denominator', 'Equal parts', 'Unequal parts', 'Shaded'],
  schema: z.object({
    image: equalFractionShapePartsSchema,
    denominator: z.number().int().min(2).max(5),
    isCorrect: z.boolean()
  }),
  simpleGenerator: () => {
    const isCorrect = getRandomBoolean();
    const denominator = randomIntegerInclusive(2, 5);
    const offset =
      denominator === 5 ? -1 : denominator === 2 ? 1 : getRandomFromArray([-1, 1] as const);
    const shapeDenom = isCorrect ? denominator : denominator + offset;

    const image = getRandomEqualFractionPart(shapeDenom as 2 | 3 | 4 | 5);

    return {
      image,
      denominator,
      isCorrect
    };
  },
  Component: props => {
    const {
      question: { image, denominator, isCorrect },
      translate
    } = props;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.isStatementTrueOrFalse()} ${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.isStatementTrueOrFalse()} ${translate.instructions.circleYourAnswer()}`}
        questionHeight={1000}
        correctAnswer={isCorrect}
        content={({ dimens }) => (
          <View
            style={{
              height: dimens.height,
              width: dimens.width,
              flexDirection: 'row',
              gap: 20,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AssetSvg name={image} width={dimens.width * 0.3} />
            <View style={{ alignSelf: 'flex-start' }}>
              <SpeechBubble flickLocation="bottom-right" style={{ margin: 20 }}>
                {translate.answerSentences.xIsShaded(`<frac n='1' d='${denominator}' />`)}
              </SpeechBubble>
            </View>
            <AssetSvg name="Tiny" width={dimens.width * 0.3} />
          </View>
        )}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aHP',
  description: 'aHP',
  keywords: ['Fraction', 'Denominator', 'Longer', 'Shorter', 'Parts', 'Whole'],
  schema: z
    .object({
      nameA: nameSchema,
      denominatorA: z.number().int().min(2).max(10),
      nameB: nameSchema,
      denominatorB: z.number().int().min(2).max(10),
      shorterOrLonger: z.enum(['shorter', 'longer'])
    })
    .refine(val => val.nameA !== val.nameB, 'nameA and nameB must be different.')
    .refine(
      val => val.denominatorA !== val.denominatorB,
      'denominatorA and denominatorB must be different.'
    ),
  simpleGenerator: () => {
    const [nameA, nameB] = getRandomUniqueNames(2);

    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 10, 2);

    const shorterOrLonger = getRandomFromArray(['shorter', 'longer'] as const);

    return { nameA, denominatorA, nameB, denominatorB, shorterOrLonger };
  },

  Component: props => {
    const {
      question: { nameA, denominatorA, nameB, denominatorB, shorterOrLonger },
      translate,
      displayMode
    } = props;

    const [ribbonPathA, ribbonPathB] = shuffle(['RibbonGreenStriped', 'RibbonRedStriped'], {
      random: seededRandom(props.question)
    });

    const items = shuffle(
      [
        {
          name: nameA,
          sentence: translate.answerSentences.hereIsXOfYsRibbon(
            `<frac n='1' d='${denominatorA}'/>`,
            nameA
          ),
          isCorrect:
            shorterOrLonger === 'shorter'
              ? denominatorA < denominatorB
              : denominatorA > denominatorB
        },
        {
          name: nameB,
          sentence: translate.answerSentences.hereIsXOfYsRibbon(
            `<frac n='1' d='${denominatorB}'/>`,
            nameB
          ),
          isCorrect:
            shorterOrLonger === 'shorter'
              ? denominatorA > denominatorB
              : denominatorA < denominatorB
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.char1AndChar2EachHaveAPieceOfRibbon(
          items[0].name,
          items[1].name
        )}
        testCorrect={items.filter(item => item.isCorrect).map(item => item.name)}
        numItems={2}
        itemLayout="row"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-between' }]}>
            <View
              style={{
                width: dimens.width,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <TextStructure
                sentence={items[0].sentence}
                fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              />
              <AssetSvg name={ribbonPathA as SvgName} width={dimens.width / 2} />
            </View>
            <View
              style={{
                width: dimens.width,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <TextStructure
                sentence={items[1].sentence}
                fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              />
              <AssetSvg name={ribbonPathB as SvgName} width={dimens.width / 2} />
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {shorterOrLonger === 'shorter'
                ? translate.answerSentences.whosePieceOfRibbonIsShorter()
                : translate.answerSentences.whosePieceOfRibbonIsLonger()}
            </Text>
          </View>
        )}
        renderItems={items.map(({ name }) => ({
          value: name,
          component: <Text variant="WRN700">{name}</Text>
        }))}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aHP2',
  description: 'aHP',
  keywords: ['Unit fraction', 'Numerator', 'Denominator', 'Equal parts'],
  schema: z.object({
    denominator: z.number().int().min(2).max(10),
    randomIdx: z.number().int().min(0).max(9)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);
    const randomIdx = randomIntegerInclusive(0, denominator - 1);

    return { denominator, randomIdx };
  },
  Component: props => {
    const {
      question: { denominator, randomIdx },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    });

    const barColor = barModelColors[numeratorColor as BarModelColorsKey];
    const blankArray = filledArray('white', denominator - 1);

    const colors = [...blankArray.slice(0, randomIdx), barColor, ...blankArray.slice(randomIdx)];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatFractionOfTheBarModelIsShaded()}
        pdfDirection="column"
        sentence={'<frac nAns="" dAns="" />'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0], userAnswer[1]], [1, denominator])
        }
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            customColorMap={colors}
            width={dimens.width}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: ['1', denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandTheDenominatorsOfUnitFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
