import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import {
  findExchanges,
  numbersDoNotExchange,
  numbersExchange,
  numbersExchangeAt,
  numbersOnlyExchangeAt
} from '../../../../utils/exchanges';
import ColumnOperations from '../../../../components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { SUB } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { PowersOfTenWord } from '../../../../utils/math';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import deepEqual from 'react-fast-compare';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aii',
  description: 'aii',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z.number().int().min(2001).max(9999),
      var2: z.number().int().min(1001).max(8999)
    })
    .refine(val => val.var1 > val.var2, 'var2 should be less than var1'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(2001, 9999, {
          constraint: x => x % 10 !== 0
        });

        const var2 = randomIntegerInclusive(1001, var1 - 1000, {
          constraint: x => x % 10 !== 0
        });
        return { var1, var2 };
      },
      // Only permit them if they have exchange at the ones and the difference between the two numbers is at least 1,000
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1, -var2, 'ones') && var1 - var2 >= 1000
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aij',
  description: 'aij',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z.number().int().min(2001).max(9999),
      var2: z.number().int().min(1001).max(8999)
    })
    .refine(val => val.var1 > val.var2, 'var2 should be less than var1'),
  simpleGenerator: () => {
    const exchange = getRandomFromArray(['tens', 'hundreds'] as const);

    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(2001, 9999, { constraint: x => x % 10 !== 0 });

        const var2 = randomIntegerInclusive(1001, var1 - 1000, { constraint: x => x % 10 !== 0 });

        return { var1, var2 };
      },
      // Only permit them if they only exchange at the tens or hundreds, and the difference between the two numbers is at least 1,000
      ({ var1, var2 }) =>
        var1 > var2 && numbersOnlyExchangeAt(var1, -var2, exchange) && var1 - var2 >= 1000
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aik',
  description: 'aik',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(1000).max(8999),
      numberA2: z.number().int().min(1000).max(8999),
      numberB1: z.number().int().min(1000).max(8999),
      numberB2: z.number().int().min(1000).max(8999),
      numberC1: z.number().int().min(1000).max(8999),
      numberC2: z.number().int().min(1000).max(8999),
      numberD1: z.number().int().min(1000).max(8999),
      numberD2: z.number().int().min(1000).max(8999)
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'ones'),
      'numberB1 and numberB2 must only exchanges at the ones.'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberC1, val.numberC2, 'tens'),
      'numberC1 and numberC2 must only exchanges at the tens.'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberD1, val.numberD2, 'hundreds'),
      'numberD1 and numberD2 must only exchanges at the hundreds.'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberA1 = randomIntegerInclusive(1000, 8999);
        const numberA2 = randomIntegerInclusive(1000, 9999 - numberA1);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => numbersDoNotExchange(numberA1, numberA2)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberB1 = randomIntegerInclusive(1000, 8999);
        const numberB2 = randomIntegerInclusive(1000, 9999 - numberB1);
        return { numberB1, numberB2 };
      },
      // Only permit them if they exchange only at the ones.
      ({ numberB1, numberB2 }) => numbersOnlyExchangeAt(numberB1, numberB2, 'ones')
    );

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberC1 = randomIntegerInclusive(1000, 8999);
        const numberC2 = randomIntegerInclusive(1000, 9999 - numberC1);
        return { numberC1, numberC2 };
      },
      // Only permit them if they exchange only at the tens.
      ({ numberC1, numberC2 }) => numbersOnlyExchangeAt(numberC1, numberC2, 'tens')
    );

    const { numberD1, numberD2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberD1 = randomIntegerInclusive(1000, 8999);
        const numberD2 = randomIntegerInclusive(1000, 9999 - numberD1);
        return { numberD1, numberD2 };
      },
      // Only permit them if they exchange only at the hundreds.
      ({ numberD1, numberD2 }) => numbersOnlyExchangeAt(numberD1, numberD2, 'hundreds')
    );

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1 + numberA2, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1 + numberB2, bottomNumber: numberB2, isCorrect: true };
      const eqC = { topNumber: numberC1 + numberC2, bottomNumber: numberC2, isCorrect: true };
      const eqD = { topNumber: numberD1 + numberD2, bottomNumber: numberD2, isCorrect: true };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheSubtractionsThatWillNeedAnExchange()}
        pdfTitle={translate.instructions.circleTheSubtractionsThatWillNeedAnExchange()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={SUB}
                dimens={{ width: dimens.width, height: dimens.height - 30 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ail',
  description: 'ail',
  keywords: ['Subtraction', 'Exchange'],
  schema: z
    .object({
      minuend: z.number().int().min(2001).max(9999),
      subtrahend: z.number().int().min(1001).max(8999)
    })
    .refine(val => val.minuend > val.subtrahend, 'subtrahend should be less than minuend'),
  simpleGenerator: () => {
    const exchange = getRandomFromArray(['ones', 'tens', 'hundreds'] as const);

    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(2001, 9999, { constraint: x => x % 10 !== 0 });

        const subtrahend = randomIntegerInclusive(1001, minuend - 1000, {
          constraint: x => x % 10 !== 0
        });

        return { minuend, subtrahend };
      },
      // Only permit them if they only exchange at the tens or hundreds, and the difference between the two numbers is at least 1,000
      ({ minuend, subtrahend }) =>
        minuend > subtrahend &&
        numbersOnlyExchangeAt(minuend, -subtrahend, exchange) &&
        minuend - subtrahend >= 1000
    );

    return {
      minuend,
      subtrahend
    };
  },

  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        sentence={`${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()} = <ans/>`}
        testCorrect={[(minuend - subtrahend).toString()]}
        questionHeight={400}
      />
    );
  },
  questionHeight: 400
});

/** Helper function for generating a random subtraction which exchanges at (at least) the given power of ten. */
const exchangesAtX = (x: PowersOfTenWord | 'nowhere' | 'no constraint') =>
  rejectionSample(
    () => ({
      minuend: randomIntegerInclusive(1000, 9999),
      subtrahend: randomIntegerInclusive(1000, 9999)
    }),
    ({ minuend: m, subtrahend: s }) =>
      m - s >= 1000 &&
      (x === 'no constraint'
        ? true
        : x === 'nowhere'
        ? numbersDoNotExchange(m, -s)
        : numbersExchangeAt(m, -s, x))
  );

const Question5 = newQuestionContent({
  uid: 'aim',
  description: 'aim',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z.object({
    subtractions: z
      .array(
        z
          .object({
            minuend: z.number().int().min(1).max(9999),
            subtrahend: z.number().int().min(1).max(9999)
          })
          .refine(
            ({ minuend, subtrahend }) => 0 < minuend - subtrahend && minuend - subtrahend <= 9999,
            'Result must be > 0 and <= 9,999'
          )
      )
      .refine(
        sums => sums.some(({ minuend, subtrahend }) => numbersExchange(minuend, -subtrahend)),
        'There must be at least one calculation which exchanges.'
      )
      .refine(calcs => arrayHasNoDuplicates(calcs, deepEqual), 'No duplicate calculations allowed')
  }),
  simpleGenerator: () => {
    const subtractions = shuffle(
      rejectionSample(
        () => [
          exchangesAtX('nowhere'),
          exchangesAtX('nowhere'),
          exchangesAtX('ones'),
          exchangesAtX('tens'),
          exchangesAtX('hundreds'),
          exchangesAtX('no constraint')
        ],
        arr => arrayHasNoDuplicates(arr, deepEqual)
      )
    );

    return { subtractions };
  },
  Component: ({ question: { subtractions }, translate }) => {
    const calculations = subtractions.map(({ minuend, subtrahend }, i) => ({
      value: i,
      text: `${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()}`,
      exchange: numbersExchange(minuend, -subtrahend)
    }));

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheSubtractionsThatWillNeedAnExchange()}
        pdfTitle={translate.instructions.circleTheSubtractionsThatWillNeedAnExchange()}
        testCorrect={calculations.filter(it => it.exchange).map(it => it.value)}
        items={calculations.map(({ value, text }) => ({
          value,
          component: text
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'ain',
  description: 'ain',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      answerNumber: z.number().int().min(1000).max(8999),
      bottomNumber: z.number().int().min(1000).max(8999),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer']),
      missingThousands: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => findExchanges(val.answerNumber, val.bottomNumber).length === 1,
      'answerNumber and bottomNumber must have only one exchange.'
    )
    .refine(
      val => val.answerNumber + val.bottomNumber < 10000,
      'answerNumber + bottomNumber must be less than 10,000'
    ),
  simpleGenerator: () => {
    const { answerNumber, bottomNumber } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping number1 uniformly distributed
        const answerNumber = randomIntegerInclusive(1000, 8999);
        const bottomNumber = randomIntegerInclusive(1000, 9999 - answerNumber);
        return { answerNumber, bottomNumber };
      },
      // Only permit them if they have 1 exchange.
      ({ answerNumber, bottomNumber }) => findExchanges(answerNumber, bottomNumber).length === 1
    );

    const numberWithExtraMissingDigit = getRandomFromArray(['top', 'bottom', 'answer'] as const);

    const [missingOnes, missingTens, missingHundreds, missingThousands] = shuffle([
      'top',
      'bottom',
      'answer',
      numberWithExtraMissingDigit
    ] as const);

    return {
      answerNumber,
      bottomNumber,
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    };
  },

  Component: props => {
    const {
      question: {
        answerNumber,
        bottomNumber,
        missingOnes,
        missingTens,
        missingHundreds,
        missingThousands
      },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    );
    const topNumber = answerNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={SUB}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwo4DigitNumbersOneExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
