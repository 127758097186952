import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { randomIntegerInclusive, randomIntegerInclusiveStep } from '../../../../utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { getRandomName, nameSchema } from '../../../../utils/names';
import {
  displayMoney,
  moneyToHighestDenominations,
  penceToPoundsAndPence
} from '../../../../utils/money';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { getRandomSmallItem, smallItemAsWord, smallItemsSchema } from '../../../../utils/items';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'atq',
  description: 'atq',
  keywords: ['Money', 'Pounds', 'Pence', 'Subtract'],
  schema: z.object({
    poundsA: z.number().int().min(4).max(9),
    poundsB: z.number().int().min(1).max(3),
    penceA: z.number().int().min(10).max(90).step(10),
    penceB: z.number().int().min(5).max(85).step(5),
    characterName: nameSchema,
    item: smallItemsSchema
  }),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(4, 9);
    const poundsB = randomIntegerInclusive(1, 3);
    const penceA = randomIntegerInclusiveStep(10, 90, 10);
    const penceB = randomIntegerInclusiveStep(5, 85, 5, { constraint: x => x < penceA });
    const characterName = getRandomName();
    const item = getRandomSmallItem();

    return { poundsA, poundsB, penceA, penceB, characterName, item };
  },
  Component: ({ question, translate, displayMode }) => {
    const { poundsA, poundsB, penceA, penceB, characterName, item } = question;

    const moneyA = displayMoney(
      [
        ...moneyToHighestDenominations(poundsA, 'pounds'),
        ...moneyToHighestDenominations(penceA, 'pence')
      ],
      displayMode === 'digital' ? 100 : 150,
      displayMode === 'digital' ? 100 : 150,
      true
    );

    const totalPounds = poundsA - poundsB;
    const totalPence = penceA - penceB;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterHasWPoundsAndXPenceTheyBuyXItemForYPoundsAndZPenceHowMuchDoesCharHaveLeft(
          characterName,
          poundsA,
          penceA,
          smallItemAsWord(item, translate, 1),
          poundsB,
          penceB
        )}
        sentence={translate.answerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        testCorrect={[totalPounds.toString(), totalPence.toString()]}
        inputMaxCharacters={2}
        Content={() => (
          <View
            style={{
              flexWrap: 'wrap',
              flexDirection: 'row',
              columnGap: 16,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {moneyA}
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'atr',
  description: 'atr',
  keywords: ['Money', 'Pounds', 'Pence', 'Subtract'],
  schema: z
    .object({
      poundsA: z.number().int().min(3).max(7),
      penceA: z.number().int().min(55).max(95).multipleOf(5),
      poundsB: z.number().int().min(1).max(5),
      penceB: z.number().int().min(10).max(50).multipleOf(10),
      name: nameSchema
    })
    .refine(val => val.poundsB < val.poundsA, 'poundsB must be less than poundsA'),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(3, 7);
    const penceA = randomIntegerInclusiveStep(55, 95, 5);

    const poundsB = randomIntegerInclusive(1, 5, {
      constraint: x => x < poundsA
    });
    const penceB = randomIntegerInclusiveStep(10, 50, 10);

    const name = getRandomName();

    return { poundsA, penceA, poundsB, penceB, name };
  },
  Component: ({ question, translate }) => {
    const { poundsA, penceA, poundsB, penceB, name } = question;

    // Answers
    const answerPounds = poundsA - poundsB;
    const answerPence = penceA - penceB;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterHasXPoundsAndYPenceCharacterPutsMoneyInMoneyBox(
          name,
          poundsA,
          penceA,
          poundsB,
          penceB
        )}
        sentence={translate.answerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[answerPounds.toString(), answerPence.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ats',
  description: 'ats',
  keywords: ['Money', 'Pounds', 'Pence', 'Subtract'],
  schema: z
    .object({
      poundsA: z.number().int().min(4).max(10),
      penceA: z.number().int().min(46).max(79),
      poundsB: z.number().int().min(1).max(9),
      penceB: z.number().int().min(1).max(45)
    })
    .refine(
      val => Number(val.penceA.toString()[val.penceA.toString().length - 1]) > 5,
      'penceA ones digit must be greater than 5'
    )
    .refine(
      val => Number(val.penceB.toString()[val.penceB.toString().length - 1]) <= 5,
      'penceB ones digit must be 5 or less'
    )
    .refine(val => val.poundsB < val.poundsA, 'poundsB must be less than poundsA'),
  simpleGenerator: () => {
    // A
    const poundsA = randomIntegerInclusive(4, 10);
    const penceATens = randomIntegerInclusiveStep(40, 70, 10);
    const penceAOnes = randomIntegerInclusive(6, 9);
    const penceA = penceATens + penceAOnes;

    // B
    const poundsB = randomIntegerInclusive(1, poundsA - 1);
    const penceBTens = randomIntegerInclusive(0, 4) * 10;
    const penceBOnes = randomIntegerInclusive(1, 5);
    const penceB = penceBTens + penceBOnes;

    return { poundsA, penceA, poundsB, penceB };
  },
  Component: props => {
    const {
      question: { poundsA, penceA, poundsB, penceB },
      translate
    } = props;

    // Answers
    const answerPounds = poundsA - poundsB;
    const answerPence = penceA - penceB;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        testCorrect={[answerPounds.toString(), answerPence.toString()]}
        sentence={translate.answerSentences.aPoundsAndBPenceSubtractXPoundsAndYPence({
          poundsA,
          penceA,
          poundsB,
          penceB
        })}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'att',
  description: 'att',
  keywords: ['Money', 'Pounds', 'Pence', 'Subtract', 'Convert'],
  schema: z.object({
    poundsA: z.number().int().min(5).max(9),
    penceA: z.number().int().min(10).max(50).multipleOf(10),
    poundsB: z.number().int().min(1).max(8),
    penceB: z.number().int().min(60).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(5, 9);
    const penceA = randomIntegerInclusiveStep(10, 50, 10);

    const poundsB = randomIntegerInclusive(1, poundsA - 1);
    const penceB = randomIntegerInclusiveStep(60, 90, 10);

    return { poundsA, penceA, poundsB, penceB };
  },
  Component: props => {
    const {
      question: { poundsA, penceA, poundsB, penceB },
      translate
    } = props;

    // Answers
    const answerPounds = poundsA - poundsB - 1;
    const answerPence = penceA - penceB + 100;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        testCorrect={[answerPounds.toString(), answerPence.toString()]}
        sentence={translate.answerSentences.aPoundsAndBPenceSubtractXPoundsAndYPence({
          poundsA,
          penceA,
          poundsB,
          penceB
        })}
      />
    );
  }
});

export const Question5 = newQuestionContent({
  uid: 'atu',
  description: 'atu',
  keywords: ['Money', 'Pounds', 'Pence', 'Subtract', 'Convert'],
  schema: z
    .object({
      poundsA: z.number().int().min(2).max(10),
      penceA: z.number().int().min(5).max(49),
      poundsB: z.number().int().min(1).max(9),
      penceB: z.number().int().min(51).max(94)
    })
    .refine(val => val.poundsB < val.poundsA, 'poundsB must be less than poundsA')
    .refine(
      val => Number(val.penceA.toString()[val.penceA.toString().length - 1]) >= 5,
      'penceA ones digit must be 5 or more'
    )
    .refine(
      val => Number(val.penceB.toString()[val.penceB.toString().length - 1]) < 5,
      'penceB ones digit must be less than 5'
    ),
  simpleGenerator: () => {
    // A
    const poundsA = randomIntegerInclusive(2, 10);
    const penceATens = randomIntegerInclusive(0, 4) * 10;
    const penceAOnes = randomIntegerInclusive(5, 9);
    const penceA = penceATens + penceAOnes;

    // B
    const poundsB = randomIntegerInclusive(1, poundsA - 1);
    const penceBTens = randomIntegerInclusiveStep(50, 90, 10);
    const penceBOnes = randomIntegerInclusive(1, 4);
    const penceB = penceBTens + penceBOnes;

    return { poundsA, penceA, poundsB, penceB };
  },

  Component: ({ question: { poundsA, penceA, poundsB, penceB }, translate, displayMode }) => {
    // Pounds/Pence A
    const poundsAInPence = poundsA * 100;
    const poundsAPenceASum = poundsAInPence + penceA;
    const poundsAndPenceA = penceToPoundsAndPence(poundsAPenceASum);

    // Pounds/Pence B
    const poundsBInPence = poundsB * 100;
    const poundsBPenceBSum = poundsBInPence + penceB;
    const poundsAndPenceB = penceToPoundsAndPence(poundsBPenceBSum);

    const poundsBAsPercentage = poundsBPenceBSum / poundsAPenceASum;

    // poundsAndPenceA/poundsAndPenceB totals in pence
    const poundsAndPenceATotal = poundsAPenceASum;
    const poundsBndPenceBTotal =
      poundsBAsPercentage < 0.38 ? Math.round(poundsAPenceASum * 0.38) : poundsBPenceBSum;

    // Remainder
    const remainder = poundsAndPenceATotal - poundsBndPenceBTotal;

    // Total
    const total = poundsBndPenceBTotal + remainder;

    // Answer
    const answer = penceToPoundsAndPence(poundsAndPenceATotal - poundsBPenceBSum);

    const strings = [
      [translate.answerSentences.xPoundsAndYPence(poundsAndPenceA.pounds, poundsAndPenceA.pence)],
      [
        '?',
        translate.answerSentences.xPoundsAndYPence(poundsAndPenceB.pounds, poundsAndPenceB.pence)
      ]
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.workOutMissingValue()}
        testCorrect={[answer.pounds.toString(), answer.pence.toString()]}
        sentence={translate.answerSentences.poundAnsAndAnsPence()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <BarModel
            numbers={[[total], [remainder, poundsBndPenceBTotal]]}
            strings={strings}
            total={total}
            dimens={dimens}
            sameRowColor
            maxFontSize={displayMode === 'digital' ? 28 : 50}
            oneFontSize
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'atv',
  description: 'atv',
  keywords: ['Subtract', 'Money'],
  schema: z
    .object({
      poundsA: z.number().int().min(4).max(9),
      poundsB: z.number().int().min(1).max(7),
      penceB: z
        .number()
        .int()
        .min(55)
        .max(95)
        .multipleOf(5)
        .refine(val => val % 10 !== 0, 'penceB cannot be a multiple of 10')
    })
    .refine(val => val.poundsB <= val.poundsA - 2, 'poundsB must be at least 2 less than poundsA.'),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(4, 9);

    const poundsB = randomIntegerInclusive(1, poundsA - 2);

    const penceB = randomIntegerInclusiveStep(55, 95, 5, {
      constraint: x => x % 10 !== 0
    });

    return { poundsA, poundsB, penceB };
  },
  Component: props => {
    const {
      question: { poundsA, poundsB, penceB },
      translate
    } = props;

    const expectedAnswerA = poundsA - poundsB;

    const expectedAnswerB = expectedAnswerA - 1;

    const expectedAnswerC = 100 - penceB;

    const lhs = [
      translate.answerSentences.poundsXMinusPoundsYAndZPence(poundsA, poundsB, penceB),
      '',
      ''
    ];

    const rhs = [
      translate.answerSentences.poundsXMinusPoundsAnsMinusAnsPence(poundsA),
      translate.answerSentences.poundsAnsMinusAnsPence(),
      translate.answerSentences.poundAnsAndAnsPence()
    ];

    return (
      <QF2AlignedEquations
        title={translate.instructions.fillInMissingNumbersToWorkOutPoundsXMinusPoundsYAndZPence(
          poundsA,
          poundsB,
          penceB
        )}
        leftSide={lhs}
        rightSide={rhs}
        inputMaxCharacters={2}
        testCorrect={({ right }) =>
          right[0][0] === poundsB.toString() &&
          right[0][1] === penceB.toString() &&
          right[1][0] === expectedAnswerA.toString() &&
          right[1][1] === penceB.toString() &&
          right[2][0] === expectedAnswerB.toString() &&
          right[2][1] === expectedAnswerC.toString()
        }
        customMarkSchemeAnswer={{
          answersToDisplay: {
            right: [
              [poundsB.toLocaleString(), penceB.toLocaleString()],
              [expectedAnswerA.toLocaleString(), penceB.toString()],
              [expectedAnswerB.toLocaleString(), expectedAnswerC.toLocaleString()]
            ]
          }
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'atv2',
  description: 'atv',
  keywords: ['Subtract', 'Money'],
  schema: z
    .object({
      poundsA: z.number().int().min(4).max(9),
      poundsB: z.number().int().min(1).max(7),
      penceB: z
        .number()
        .int()
        .min(55)
        .max(95)
        .multipleOf(5)
        .refine(val => val % 10 !== 0, 'penceB cannot be a multiple of 10')
    })
    .refine(val => val.poundsB <= val.poundsA - 2, 'poundsB must be at least 2 less than poundsA.'),
  simpleGenerator: () => {
    const poundsA = randomIntegerInclusive(4, 9);

    const poundsB = randomIntegerInclusive(1, poundsA - 2);

    const penceB = randomIntegerInclusiveStep(55, 95, 5, {
      constraint: x => x % 10 !== 0
    });

    return { poundsA, poundsB, penceB };
  },
  Component: props => {
    const {
      question: { poundsA, poundsB, penceB },
      translate
    } = props;

    const expectedAnswerA = poundsA - (poundsB + 1);

    const expectedAnswerB = 100 - penceB;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        sentence={translate.answerSentences.poundXSubtractPoundYAndZPenceEqualsPoundAnsAndPenceAns(
          poundsA,
          poundsB,
          penceB
        )}
        testCorrect={[expectedAnswerA.toString(), expectedAnswerB.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractMoney',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
