import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aPY',
  description: 'aPY',
  keywords: ['Fraction', 'Amount', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(10),
      numerator: z.number().int().min(1).max(10),
      total: z.number().int().min(2).max(100)
    })
    .refine(
      val => val.numerator <= val.denominator,
      'numerator must be less than or equal to denominator.'
    )
    .refine(val => val.total % val.denominator === 0, 'total must be a multiple of denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);

    const numerator = randomIntegerInclusive(1, denominator);

    const total = randomIntegerInclusive(2, 100, {
      constraint: x => x % denominator === 0
    });

    return { denominator, numerator, total };
  },

  Component: props => {
    const {
      question: { denominator, numerator, total },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteCalc()}
        testCorrect={[((total / denominator) * numerator).toString()]}
        textStyle={{ fontSize: 40 }}
        sentence={translate.answerSentences.fracOfNumEqualsAns(
          `<frac n='${numerator}' d='${denominator}'/>`,
          total
        )}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width}
            coloredSections={range(0, numerator - 1)}
            fullWidthTopBrace={total.toLocaleString()}
          />
        )}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aPY2',
  description: 'aPY2',
  keywords: ['Fraction', 'Amount', 'Unit fraction', 'Non-unit fraction', 'Bar model'],
  schema: z
    .object({
      denominator: z.number().int().min(2).max(10),
      numerator: z.number().int().min(1).max(10),
      total: z.number().int().min(2).max(100)
    })
    .refine(
      val => val.numerator <= val.denominator,
      'numerator must be less than or equal to denominator.'
    )
    .refine(val => val.total % val.denominator === 0, 'total must be a multiple of denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);

    const numerator = randomIntegerInclusive(1, denominator);

    const total = randomIntegerInclusive(2, 100, {
      // Do not allow the total to generate beyond the 12 times table:
      constraint: x => x % denominator === 0 && x <= denominator * 12
    });

    return { denominator, numerator, total };
  },

  Component: props => {
    const {
      question: { denominator, numerator, total },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheBarModelToHelpCompleteTheCalculation()}
        testCorrect={[((total / denominator) * numerator).toString()]}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        sentence={translate.answerSentences.fracOfNumEqualsAns(
          `<frac n='${numerator}' d='${denominator}'/>`,
          total
        )}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width}
            coloredSections={range(0, numerator - 1)}
            fullWidthTopBrace={total.toLocaleString()}
            topBraceTextStyle={{
              fontSize: displayMode === 'digital' ? 32 : 50,
              top: displayMode === 'digital' ? -64 : -84
            }}
            height={displayMode === 'digital' ? undefined : 150}
          />
        )}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aPZ',
  description: 'aPZ',
  keywords: ['Fraction', 'Amount', 'Unit fraction', 'Non-unit fraction'],
  schema: z
    .object({
      denominatorA: z.number().int().min(3).max(10),
      numeratorA: z.number().int().min(1).max(10),
      totalA: z.number().int().min(2).max(200),
      denominatorB: z.number().int().min(3).max(10),
      numeratorB: z.number().int().min(1).max(10),
      totalB: z.number().int().min(2).max(200)
    })
    .refine(
      val => val.numeratorA <= val.denominatorA,
      'numeratorA must be less than or equal to denominatorA.'
    )
    .refine(
      val => val.totalA % val.denominatorA === 0,
      'totalA must be a multiple of denominatorA.'
    )
    .refine(
      val => val.numeratorB <= val.denominatorB,
      'numeratorB must be less than or equal to denominatorB.'
    )
    .refine(
      val => val.totalB % val.denominatorB === 0,
      'totalB must be a multiple of denominatorB.'
    )
    .refine(
      val =>
        val.denominatorA !== val.denominatorB &&
        val.numeratorA !== val.numeratorB &&
        val.totalA !== val.totalB,
      "Equation A and B's denominators, numerators and totals must not be equal to each other."
    ),
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(3, 10, 2);

    const numeratorA = randomIntegerInclusive(1, denominatorA);

    const totalA = randomIntegerInclusive(2, 200, {
      constraint: x => x % denominatorA === 0
    });

    const numeratorB = randomIntegerInclusive(1, denominatorB, {
      constraint: x => x !== numeratorA
    });

    const totalB = randomIntegerInclusive(2, 200, {
      constraint: x => x % denominatorB === 0 && x !== totalA
    });

    return { denominatorA, numeratorA, totalA, denominatorB, numeratorB, totalB };
  },
  Component: props => {
    const {
      question: { denominatorA, numeratorA, totalA, denominatorB, numeratorB, totalB },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [((totalA / denominatorA) * numeratorA).toString()],
          [((totalB / denominatorB) * numeratorB).toString()]
        ]}
        sentences={[
          translate.answerSentences.fracOfNumEqualsAns(
            `<frac n='${numeratorA}' d='${denominatorA}'/>`,
            totalA
          ),
          translate.answerSentences.fracOfNumEqualsAns(
            `<frac n='${numeratorB}' d='${denominatorB}'/>`,
            totalB
          )
        ]}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aPZ2',
  description: 'aPZ2',
  keywords: ['Fraction', 'Amount', 'Unit fraction', 'Non-unit fraction'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(10),
      numerator: z.number().int().min(1).max(10),
      total: z.number().int().min(2).max(200)
    })
    .refine(
      val => val.numerator <= val.denominator,
      'numerator must be less than or equal to denominator.'
    )
    .refine(val => val.total % val.denominator === 0, 'total must be a multiple of denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);

    const numerator = randomIntegerInclusive(1, denominator);

    const total = randomIntegerInclusive(2, 200, {
      constraint: x => x % denominator === 0
    });

    return { denominator, numerator, total };
  },
  Component: props => {
    const {
      question: { denominator, numerator, total },
      translate,
      displayMode
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[((total / denominator) * numerator).toString()]}
        sentence={translate.answerSentences.fracOfNumEqualsAns(
          `<frac n='${numerator}' d='${denominator}'/>`,
          total
        )}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aP0',
  description: 'aP0',
  keywords: ['Fraction', 'Amount', 'Unit fraction', 'Non-unit fraction'],
  schema: z
    .object({
      denominatorA: z.number().int().min(2).max(10),
      numeratorA: z.number().int().min(1).max(10),
      totalA: z.number().int().min(2).max(200),
      denominatorB: z.number().int().min(2).max(10),
      numeratorB: z.number().int().min(1).max(10),
      totalB: z.number().int().min(2).max(200),
      denominatorC: z.number().int().min(2).max(10),
      numeratorC: z.number().int().min(1).max(10),
      totalC: z.number().int().min(2).max(200)
    })
    .refine(
      val => val.numeratorA <= val.denominatorA,
      'numeratorA must be less than or equal to denominatorA.'
    )
    .refine(
      val => val.totalA % val.denominatorA === 0,
      'totalA must be a multiple of denominatorA.'
    )
    .refine(
      val => val.numeratorB <= val.denominatorB,
      'numeratorB must be less than or equal to denominatorB.'
    )
    .refine(
      val => val.totalB % val.denominatorB === 0,
      'totalB must be a multiple of denominatorB.'
    )
    .refine(
      val => val.numeratorC <= val.denominatorC,
      'numeratorC must be less than or equal to denominatorC.'
    )
    .refine(
      val => val.totalC % val.denominatorC === 0,
      'totalC must be a multiple of denominatorC.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.denominatorA, val.denominatorB, val.denominatorC]),
      'All denominators must be different.'
    ),
  simpleGenerator: () => {
    const [denominatorA, denominatorB, denominatorC] = randomUniqueIntegersInclusive(2, 10, 3);

    const numeratorA = randomIntegerInclusive(1, denominatorA);

    const totalA = randomIntegerInclusive(2, 200, {
      constraint: x => x % denominatorA === 0
    });

    const answerA = (totalA / denominatorA) * numeratorA;

    const numeratorB = randomIntegerInclusive(1, denominatorB);

    const totalB = randomIntegerInclusive(2, 200, {
      // Ensure final answers are all unique:
      constraint: x => x % denominatorB === 0 && (x / denominatorB) * numeratorB !== answerA
    });

    const answerB = (totalB / denominatorB) * numeratorB;

    const numeratorC = randomIntegerInclusive(1, denominatorC);

    const totalC = randomIntegerInclusive(2, 200, {
      // Ensure final answers are all unique:
      constraint: x => x % denominatorC === 0 && arrayHasNoDuplicates([x, answerA, answerB])
    });

    return {
      denominatorA,
      numeratorA,
      totalA,
      denominatorB,
      numeratorB,
      totalB,
      denominatorC,
      numeratorC,
      totalC
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        numeratorA,
        totalA,
        denominatorB,
        numeratorB,
        totalB,
        denominatorC,
        numeratorC,
        totalC
      },
      translate,
      displayMode
    } = props;

    const answerA = (totalA / denominatorA) * numeratorA;
    const answerB = (totalB / denominatorB) * numeratorB;
    const answerC = (totalC / denominatorC) * numeratorC;

    const statements = shuffle(
      [
        {
          sentence: `${translate.answerSentences.xOfY(
            `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`,
            totalA.toLocaleString()
          )} <ans/>`,
          correctAnswer: answerA
        },
        {
          sentence: `${translate.answerSentences.xOfY(
            `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`,
            totalB.toLocaleString()
          )} <ans/>`,
          correctAnswer: answerB
        },
        {
          sentence: `${translate.answerSentences.xOfY(
            `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}'/>`,
            totalC.toLocaleString()
          )} <ans/>`,
          correctAnswer: answerC
        }
      ],
      { random: seededRandom(props.question) }
    );

    const items = [answerA, answerB, answerC];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        items={items}
        actionPanelVariant="end"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ sentence }) => sentence)}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aP1',
  description: 'aP1',
  keywords: ['Fraction', 'Amount', 'Unit fraction', 'Non-unit fraction'],
  schema: z
    .object({
      denominatorA: z.number().int().min(3).max(12),
      numeratorA: z.number().int().min(1).max(11),
      totalA: z.number().int().min(2).max(500),
      unitA: z.enum(['kg', 'cm', 'm', 'ml']),
      denominatorB: z.number().int().min(3).max(12),
      numeratorB: z.number().int().min(1).max(11),
      totalB: z.number().int().min(2).max(500),
      unitB: z.enum(['kg', 'cm', 'm', 'ml'])
    })
    .refine(val => val.numeratorA < val.denominatorA, 'numeratorA must be less than denominatorA.')
    .refine(
      val => val.totalA % val.denominatorA === 0,
      'totalA must be a multiple of denominatorA.'
    )
    .refine(val => val.numeratorB < val.denominatorB, 'numeratorB must be less than denominatorB.')
    .refine(
      val => val.totalB % val.denominatorB === 0,
      'totalB must be a multiple of denominatorB.'
    )
    .refine(
      val => val.denominatorA !== val.denominatorB,
      'denominatorA and denominatorB must be different.'
    )
    .refine(val => val.unitA !== val.unitB, 'unitA and unitB must be different.'),
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(3, 12, 3);

    const [unitA, unitB] = getRandomSubArrayFromArray(['kg', 'cm', 'm', 'ml'] as const, 2);

    const numeratorA = randomIntegerInclusive(1, denominatorA - 1, {
      // Fraction generated cannot be equivalent to 1/2, to avoid incorrect answer being the same:
      constraint: x => !compareFractions([x, denominatorA], [1, 2])
    });

    const totalA = randomIntegerInclusive(2, 500, {
      constraint: x => x % denominatorA === 0
    });

    const numeratorB = randomIntegerInclusive(1, denominatorB - 1, {
      // Fraction generated cannot be equivalent to 1/2, to avoid incorrect answer being the same:
      constraint: x => !compareFractions([x, denominatorB], [1, 2])
    });

    const totalB = randomIntegerInclusive(2, 500, {
      constraint: x => x % denominatorB === 0
    });

    return {
      denominatorA,
      numeratorA,
      totalA,
      unitA,
      denominatorB,
      numeratorB,
      totalB,
      unitB
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA,
        numeratorA,
        totalA,
        unitA,
        denominatorB,
        numeratorB,
        totalB,
        unitB
      },
      translate,
      displayMode
    } = props;

    const sentenceMaker = (
      numerator: number,
      denominator: number,
      total: number,
      unit: 'kg' | 'cm' | 'm' | 'ml'
    ): string => {
      switch (unit) {
        case 'kg':
          return translate.answerSentences.fracOfNumKgEqualsAns(
            `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
            total
          );
        case 'cm':
          return translate.answerSentences.fracOfNumCmEqualsAns(
            `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
            total
          );
        case 'm':
          return translate.answerSentences.fracOfNumMEqualsAns(
            `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
            total
          );
        case 'ml':
          return translate.answerSentences.fracOfNumMlEqualsAns(
            `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`,
            total
          );
      }
    };

    const itemMaker = (answer: number, unit: 'kg' | 'cm' | 'm' | 'ml'): string => {
      switch (unit) {
        case 'kg':
          return translate.units.numberOfKg(answer);
        case 'cm':
          return translate.units.numberOfCm(answer);
        case 'm':
          return translate.units.numberOfM(answer);
        case 'ml':
          return translate.units.numberOfMl(answer);
      }
    };

    const answerA = itemMaker((totalA / denominatorA) * numeratorA, unitA);
    const answerB = itemMaker((totalB / denominatorB) * numeratorB, unitB);
    const incorrectAnswerA = itemMaker(totalA - (totalA / denominatorA) * numeratorA, unitA);
    const incorrectAnswerB = itemMaker(totalB - (totalB / denominatorB) * numeratorB, unitB);

    const statements = shuffle(
      [
        {
          sentence: sentenceMaker(numeratorA, denominatorA, totalA, unitA),
          correctAnswer: answerA
        },
        {
          sentence: sentenceMaker(numeratorB, denominatorB, totalB, unitB),
          correctAnswer: answerB
        }
      ],
      { random: seededRandom(props.question) }
    );

    // This question requires the items to be shuffled, not the statements.
    const items = shuffle([answerA, answerB, incorrectAnswerA, incorrectAnswerB], {
      random: seededRandom(props.question)
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculations()}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endWide"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ sentence }) => sentence)}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aP12',
  description: 'aP12',
  keywords: ['Fraction', 'Amount', 'Unit fraction', 'Non-unit fraction'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(12),
      numerator: z.number().int().min(1).max(11),
      total: z.number().int().min(2).max(500),
      unitA: z.enum(['kg', 'cm', 'm', 'ml']),
      unitB: z.enum(['kg', 'cm', 'm', 'ml'])
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.')
    .refine(val => val.total % val.denominator === 0, 'total must be a multiple of denominator.')
    .refine(val => val.unitA !== val.unitB, 'unitA and unitB must be different.')
    .refine(
      val => !compareFractions([val.numerator, val.denominator], [1, 2]),
      'numerator/denominator must not be equivalent to 1/2'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 12);

    const [unitA, unitB] = getRandomSubArrayFromArray(['kg', 'cm', 'm', 'ml'] as const, 2);

    const numerator = randomIntegerInclusive(1, denominator - 1, {
      // Fraction generated cannot be equivalent to 1/2, to avoid incorrect answer being the same:
      constraint: x => !compareFractions([x, denominator], [1, 2])
    });

    const total = randomIntegerInclusive(2, 500, {
      constraint: x => x % denominator === 0
    });

    return {
      denominator,
      numerator,
      total,
      unitA,
      unitB
    };
  },
  Component: props => {
    const {
      question: { denominator, numerator, total, unitA, unitB },
      translate,
      displayMode
    } = props;

    const sentenceMaker = (
      numerator: number,
      denominator: number,
      total: number,
      unit: 'kg' | 'cm' | 'm' | 'ml'
    ): string => {
      switch (unit) {
        case 'kg':
          return translate.answerSentences.fracOfNumKgEqualsAns(
            `<frac n='${numerator}' d='${denominator}' />`,
            total
          );
        case 'cm':
          return translate.answerSentences.fracOfNumCmEqualsAns(
            `<frac n='${numerator}' d='${denominator}' />`,
            total
          );
        case 'm':
          return translate.answerSentences.fracOfNumMEqualsAns(
            `<frac n='${numerator}' d='${denominator}' />`,
            total
          );
        case 'ml':
          return translate.answerSentences.fracOfNumMlEqualsAns(
            `<frac n='${numerator}' d='${denominator}' />`,
            total
          );
      }
    };

    const itemMaker = (answer: number, unit: 'kg' | 'cm' | 'm' | 'ml'): string => {
      switch (unit) {
        case 'kg':
          return translate.units.numberOfKg(answer);
        case 'cm':
          return translate.units.numberOfCm(answer);
        case 'm':
          return translate.units.numberOfM(answer);
        case 'ml':
          return translate.units.numberOfMl(answer);
      }
    };

    const answerA = itemMaker((total / denominator) * numerator, unitA);
    const incorrectAnswerA = itemMaker(total - (total / denominator) * numerator, unitA);
    const incorrectAnswerB = itemMaker((total / denominator) * numerator, unitB);
    const incorrectAnswerC = itemMaker(total - (total / denominator) * numerator, unitB);

    const random = seededRandom(props.question);

    const items = shuffle([answerA, incorrectAnswerA, incorrectAnswerB, incorrectAnswerC], {
      random
    });

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardToCompleteCalculation()}
        pdfTitle={translate.instructions.useCardToCompleteCalculation()}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endWide"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentence={sentenceMaker(numerator, denominator, total, unitA)}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        testCorrect={[answerA]}
        pdfLayout="itemsRight"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aP2',
  description: 'aP2',
  keywords: [
    'Fraction',
    'Amount',
    'Unit fraction',
    'Non-unit fraction',
    'Compare',
    'Greater than',
    'Less than',
    'Equal to'
  ],
  schema: z
    .object({
      denominatorA1: z.number().int().min(3).max(10),
      denominatorA2: z.number().int().min(3).max(10),
      numeratorA: z.number().int().min(1).max(10),
      totalA: z.number().int().min(3).max(200),
      denominatorB1: z.number().int().min(3).max(10),
      numeratorB1: z.number().int().min(1).max(10),
      denominatorB2: z.number().int().min(3).max(10),
      numeratorB2: z.number().int().min(1).max(10),
      totalB: z.number().int().min(3).max(200)
    })
    .refine(
      val => val.denominatorA1 !== val.denominatorA2,
      'denominatorA1 and denominatorA2 must be different.'
    )
    .refine(val => val.totalA !== val.totalB, 'totalA and totalB must be different.')
    .refine(
      val => val.numeratorA <= val.denominatorA1,
      'numeratorA must be less than or equal to denominatorA1.'
    )
    .refine(
      val => val.totalA % val.denominatorA1 === 0 && val.totalA % val.denominatorA2 === 0,
      'totalA must be a multiple of denominatorA1 and denominatorA2.'
    )
    .refine(
      val => val.denominatorB1 !== val.denominatorB2,
      'denominatorB1 and denominatorB2 must be different.'
    )
    .refine(
      val => val.numeratorB1 <= val.denominatorB1,
      'numeratorB1 must be less than or equal to denominatorB1.'
    )
    .refine(
      val => val.numeratorB2 <= val.denominatorB2,
      'numeratorB2 must be less than or equal to denominatorB2.'
    )
    .refine(
      val => val.totalB % val.denominatorB1 === 0 && val.totalB % val.denominatorB2 === 0,
      'totalB must be a multiple of denominatorB1 and denominatorB2.'
    ),
  simpleGenerator: () => {
    const [denominatorA1, denominatorA2] = randomUniqueIntegersInclusive(3, 10, 2);

    // numeratorA must not be more than denominatorA1; denominatorA2 does not affect this:
    const numeratorA = randomIntegerInclusive(1, denominatorA1);

    const totalA = randomIntegerInclusive(3, 200, {
      constraint: x => x % denominatorA1 === 0 && x % denominatorA2 === 0
    });

    const [denominatorB1, denominatorB2] = randomUniqueIntegersInclusive(3, 10, 2);

    const numeratorB1 = randomIntegerInclusive(1, denominatorB1);

    const numeratorB2 = randomIntegerInclusive(1, denominatorB2);

    const totalB = randomIntegerInclusive(3, 200, {
      constraint: x => x % denominatorB1 === 0 && x % denominatorB2 === 0 && x !== totalA
    });

    return {
      denominatorA1,
      denominatorA2,
      numeratorA,
      totalA,
      denominatorB1,
      numeratorB1,
      denominatorB2,
      numeratorB2,
      totalB
    };
  },
  Component: props => {
    const {
      question: {
        denominatorA1,
        denominatorA2,
        numeratorA,
        totalA,
        denominatorB1,
        numeratorB1,
        denominatorB2,
        numeratorB2,
        totalB
      },
      translate,
      displayMode
    } = props;

    const valueA1 = fractionToDecimal(numeratorA, denominatorA1);
    const valueA2 = fractionToDecimal(numeratorA, denominatorA2);

    const answerA = lessThanGreaterThanOrEqualTo(valueA1, valueA2);

    const valueB1 = fractionToDecimal(numeratorB1, denominatorB1);
    const valueB2 = fractionToDecimal(numeratorB2, denominatorB2);

    const answerB = lessThanGreaterThanOrEqualTo(valueB1, valueB2);

    const statements = shuffle(
      [
        {
          lhsComponent: (
            <TextStructure
              sentence={translate.answerSentences.xOfY(
                `<frac n='${numeratorA}' d='${denominatorA1}' />`,
                totalA.toLocaleString()
              )}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          ),
          rhsComponent: (
            <TextStructure
              sentence={translate.answerSentences.xOfY(
                `<frac n='${numeratorA}' d='${denominatorA2}' />`,
                totalA.toLocaleString()
              )}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          ),
          correctAnswer: answerA
        },
        {
          lhsComponent: (
            <TextStructure
              sentence={translate.answerSentences.xOfY(
                `<frac n='${numeratorB1}' d='${denominatorB1}' />`,
                totalB.toLocaleString()
              )}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          ),
          rhsComponent: (
            <TextStructure
              sentence={translate.answerSentences.xOfY(
                `<frac n='${numeratorB2}' d='${denominatorB2}' />`,
                totalB.toLocaleString()
              )}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          ),
          correctAnswer: answerB
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareCalculations()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareCalculations()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['<', '>', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aP22',
  description: 'aP22',
  keywords: [
    'Fraction',
    'Amount',
    'Unit fraction',
    'Non-unit fraction',
    'Compare',
    'Greater than',
    'Less than',
    'Equal to'
  ],
  schema: z
    .object({
      denominator1: z.number().int().min(3).max(10),
      numerator1: z.number().int().min(1).max(10),
      denominator2: z.number().int().min(3).max(10),
      numerator2: z.number().int().min(1).max(10),
      total: z.number().int().min(3).max(200)
    })
    .refine(
      val => val.denominator1 !== val.denominator2,
      'denominator1 and denominator2 must be different.'
    )
    .refine(
      val => val.numerator1 <= val.denominator1,
      'numerator1 must be less than or equal to denominator1'
    )
    .refine(
      val => val.numerator2 <= val.denominator2,
      'numeratorB2 must be less than or equal to denominator2'
    )
    .refine(
      val => val.total % val.denominator1 === 0 && val.total % val.denominator2 === 0,
      'total must be a multiple of denominator1 and denominator2'
    ),
  simpleGenerator: () => {
    const [denominator1, denominator2] = randomUniqueIntegersInclusive(3, 10, 2);

    const numerator1 = randomIntegerInclusive(1, denominator1);

    const numerator2 = randomIntegerInclusive(1, denominator2);

    const total = randomIntegerInclusive(3, 200, {
      constraint: x => x % denominator1 === 0 && x % denominator2 === 0
    });

    return {
      denominator1,
      numerator1,
      denominator2,
      numerator2,
      total
    };
  },
  Component: props => {
    const {
      question: { denominator1, numerator1, denominator2, numerator2, total },
      translate,
      displayMode
    } = props;

    const value1 = fractionToDecimal(numerator1, denominator1);
    const value2 = fractionToDecimal(numerator2, denominator2);

    const answer = lessThanGreaterThanOrEqualTo(value1, value2);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragLessThanGreaterThanOrEqualsToCompareTheStatements()}
        pdfTitle={translate.instructions.useLessThanGreaterThanOrEqualsToCompareTheStatements()}
        itemVariant="square"
        statements={[
          {
            lhsComponent: (
              <TextStructure
                sentence={translate.answerSentences.xOfY(
                  `<frac n='${numerator1}' d='${denominator1}' />`,
                  total.toLocaleString()
                )}
                textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
              />
            ),
            rhsComponent: (
              <TextStructure
                sentence={translate.answerSentences.xOfY(
                  `<frac n='${numerator2}' d='${denominator2}' />`,
                  total.toLocaleString()
                )}
                textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
              />
            ),
            correctAnswer: answer
          }
        ]}
        statementStyle={{ justifyContent: 'center' }}
        items={['<', '>', '=']}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aP3',
  description: 'aP3',
  keywords: ['Fraction', 'Amount', 'Unit fraction', 'Non-unit fraction', 'Whole', 'Total'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(10),
      numerator: z.number().int().min(1).max(9),
      total: z.number().int().min(3).max(200)
    })
    .refine(
      val => val.numerator <= val.denominator,
      'numerator must be less than or equal to denominator.'
    )
    .refine(val => val.total % val.denominator === 0, 'total must be a multiple of denominator.'),
  simpleGenerator: () => {
    const { denominator, numerator, total } = rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(3, 10);

        const numerator = randomIntegerInclusive(1, denominator - 1);

        const total = randomIntegerInclusive(3, 200, {
          constraint: x => x % denominator === 0
        });
        return { denominator, numerator, total };
      },
      ({ denominator, numerator, total }) =>
        // Check that none of the generated answers will be duplicates:
        arrayHasNoDuplicates([
          (total / denominator) * numerator,
          Math.floor((total / numerator) * denominator),
          total - (total / denominator) * numerator,
          Math.ceil((total / numerator) * denominator) ===
          Math.floor((total / numerator) * denominator)
            ? Math.floor((total / numerator) * denominator) + 1
            : Math.ceil(total * numerator * denominator)
        ])
    );

    return {
      denominator,
      numerator,
      total
    };
  },
  Component: props => {
    const {
      question: { denominator, numerator, total },
      translate
    } = props;

    const answer = (total / denominator) * numerator;
    const incorrectAnswerA = Math.floor((total / numerator) * denominator);
    const incorrectAnswerB =
      Math.ceil((total / numerator) * denominator) === incorrectAnswerA
        ? incorrectAnswerA + 1
        : Math.ceil(total * numerator * denominator);
    const incorrectAnswerC = total - answer;

    // This question requires the items to be shuffled, not the statements.
    const items = shuffle([answer, incorrectAnswerA, incorrectAnswerB, incorrectAnswerC], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.numChildrenAndAdultsGoOnASchoolTrip(
          total,
          `<frac n='${numerator}' d='${denominator}' />`
        )}
        testCorrect={[answer]}
        numItems={4}
        renderItems={() => {
          return items.map(answer => ({
            value: answer,
            component: <Text variant="WRN700">{answer.toLocaleString()}</Text>
          }));
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FractionOfAnAmount',
  questionTypes: [Question1v2, Question2v2, Question3, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question1, Question2, Question4, Question5]
});
export default SmallStep;
