// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import DecimalsUpTo2DecimalPlaces from './1DecimalsUpTo2DecimalPlaces';
import EquivalentFractionsAndDecimalsTenths from './2EquivalentFractionsAndDecimalsTenths';
import EquivalentFractionsAndDecimalsHundredths from './3EquivalentFractionsAndDecimalsHundredths';
import EquivalentFractionsAndDecimals from './4EquivalentFractionsAndDecimals';
import ThousandthsAsFractions from './5ThousandthsAsFractions';
import ThousandthsAsDecimals from './6ThousandthsAsDecimals';
import ThousandthsOnAPlaceValueChart from './7ThousandthsOnAPlaceValueChart';
import OrderAndCompareDecimalsSameNumberOfDecimalPlaces from './8OrderAndCompareDecimalsSameNumberOfDecimalPlaces';
import OrderAndCompareAnyDecimalsWithUpTo3DecimalPlaces from './9OrderAndCompareAnyDecimalsWithUpTo3DecimalPlaces';
import RoundToTheNearestWholeNumber from './10RoundToTheNearestWholeNumber';
import RoundTo1DecimalPlace from './11RoundTo1DecimalPlace';
import UnderstandPercentages from './12UnderstandPercentages';
import PercentagesAsFractions from './13PercentagesAsFractions';
import PercentagesAsDecimals from './14PercentagesAsDecimals';
import EquivalentFractionsDecimalsAndPercentages from './15EquivalentFractionsDecimalsAndPercentages';

const Block = newBlockContent({
  block: 'DecimalsAndPercentages',
  field: 'Number',
  weeks: [6, 8],
  smallSteps: [
    DecimalsUpTo2DecimalPlaces, // Step 1
    EquivalentFractionsAndDecimalsTenths, // Step 2
    EquivalentFractionsAndDecimalsHundredths, // Step 3
    EquivalentFractionsAndDecimals, // Step 4
    ThousandthsAsFractions, // Step 5
    ThousandthsAsDecimals, // Step 6
    ThousandthsOnAPlaceValueChart, // Step 7
    OrderAndCompareDecimalsSameNumberOfDecimalPlaces, // Step 8
    OrderAndCompareAnyDecimalsWithUpTo3DecimalPlaces, // Step 9
    RoundToTheNearestWholeNumber, // Step 10
    RoundTo1DecimalPlace, // Step 11
    UnderstandPercentages, // Step 12
    PercentagesAsFractions, // Step 13
    PercentagesAsDecimals, // Step 14
    EquivalentFractionsDecimalsAndPercentages // Step 15
  ]
});
export default Block;
