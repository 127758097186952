/* Helper functions for and extensions to `bignumber`, from the package 'mathjs'. */
import * as math from 'mathjs';

/** Get the (unsigned) digit from a number, at a particular position (given by a power of ten). */
export function getDigit(number: number | math.BigNumber, pow: number): number {
  // e.g. if number is 12.34, pow is -1, then x is 0.34
  const x = math
    .bignumber(number)
    .abs()
    .modulo(`1e${pow + 1}`);

  return x
    .minus(
      // e.g. 0.04
      x.modulo(`1e${pow}`)
    )
    .times(`1e${-pow}`)
    .truncated()
    .toNumber();
}

/**
 * Set the (unsigned) digit of a number, at a particular position (given by a power of ten).
 *
 * The return type is equal to the type of the input `number`.
 */
export function setDigit(number: number, pow: number, digit: number): number;
export function setDigit(number: math.BigNumber, pow: number, digit: number): math.BigNumber;
export function setDigit(
  number: number | math.BigNumber,
  pow: number,
  digit: number
): number | math.BigNumber {
  const x = math.bignumber(number);
  const oldDigit = getDigit(x, pow);
  const oldSign = x.s;
  const y = x
    .abs()
    .plus(math.bignumber(digit).minus(oldDigit).times(`1e${pow}`))
    .times(oldSign);

  return typeof number === 'number' ? y.toNumber() : y;
}
