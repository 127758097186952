import { useContext, useMemo } from 'react';
import { StyleSheet, StyleProp, View, ViewStyle, TextStyle } from 'react-native';
import { colors } from '../../../theme/colors';
import { ScaleFactorContext } from '../../../theme/scaling';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { SetState } from '../../../utils/react';
import Text from '../../typography/Text';
import TextStructure from '../../molecules/TextStructure';
import { withStateHOC } from '../../../stateTree';
import { parseMarkup } from '../../../markup';
import { ADD, DIV, MULT, SUB } from '../../../constants';
import { DisplayMode } from '../../../contexts/displayMode';

type UserAnswer = string[];

type Props = TitleStyleProps & {
  /**
   * The user answer
   */
  userAnswer?: UserAnswer;
  /**
   * Set user answer
   */
  setUserAnswer?: SetState<UserAnswer>;
  inputMaxCharacters?: number;
  /** Cell headers */
  cellHeaders?:
    | string[]
    | { label: string; containerStyle?: StyleProp<ViewStyle>; textStyle?: StyleProp<TextStyle> }[];
  /**
   * Array of an array. The first array covers the number of rows and the second the columns
   * The array accepts a string (text/ans box) or a react component
   */
  tableData: (string | JSX.Element)[][];
  tableStyle?: StyleProp<ViewStyle>;
  rowStyle?: StyleProp<ViewStyle>;
  tableCellStyle?: StyleProp<ViewStyle>;
  tableCellStyleIdx?: number[];
  tableFontSize?: number;
  /**
   * Specify the column where the decimal point goes.
   * The decimal point will always be on the left of this index
   */
  tenthsColumnIndex?: number;
  rowSeperatorExtraSymbolLeft?: ADD | SUB | MULT | DIV;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  columnFlexValues?: number[];
  answerBoxStyling?: StyleProp<ViewStyle>;
};

export const CustomizableTable = ({
  userAnswer = [],
  setUserAnswer = () => {},
  inputMaxCharacters,
  cellHeaders,
  tableData,
  tableStyle,
  rowStyle,
  tableCellStyle,
  tableCellStyleIdx,
  tableFontSize,
  tenthsColumnIndex,
  rowSeperatorExtraSymbolLeft,
  textStyle,
  fractionTextStyle,
  columnFlexValues,
  answerBoxStyling
}: Props) => {
  const scaleFactor = useContext(ScaleFactorContext);
  const displayMode = useContext(DisplayMode);

  const borderWidth =
    displayMode === 'digital' ? Math.max(2, 2 / scaleFactor) : Math.max(4, 2 / scaleFactor);
  const styles = useStyles(borderWidth, displayMode);

  let ansIndex = -1;
  const renderCell = (cell: string | JSX.Element) => {
    if (typeof cell === 'string') {
      const ansCount = parseMarkup(cell).numberOfAns;

      // Add number of answers
      ansIndex += ansCount;
      // Create scoped copy, otherwise all answer indices will equal final index
      const i = ansIndex;

      return (
        <TextStructure
          sentence={cell}
          textStyle={[{ textAlign: 'center', fontSize: tableFontSize ?? 40 }, textStyle]}
          fractionTextStyle={[{ fontSize: tableFontSize ?? 40 }, fractionTextStyle]}
          inputBox={({ index }: { index: number }) => (
            <NoKeyboardTextInput
              value={userAnswer[i - ansCount + (index + 1)]}
              onChangeText={text => {
                const newState = [...userAnswer];
                newState[i - ansCount + (index + 1)] = text;
                setUserAnswer(newState);
              }}
              maxCharacters={inputMaxCharacters}
              style={answerBoxStyling}
            />
          )}
        />
      );
    } else {
      return cell;
    }
  };

  const decimalPoint = () => (
    <View style={styles.decimalWrapper}>
      <View style={styles.decimalBase} />
    </View>
  );

  return (
    <View style={[styles.container, tableStyle]}>
      {/* Cell headers */}
      {cellHeaders && (
        <View style={[styles.subContainer, rowStyle]}>
          {cellHeaders.map((cell, cellIdx) => {
            const borderStyles = {
              borderLeftWidth: cellIdx === 0 ? borderWidth : 0,
              borderRightWidth: cellIdx !== cellHeaders.length - 1 ? borderWidth : 0
            };
            const flex = columnFlexValues ? columnFlexValues[cellIdx] : 1;
            return typeof cell === 'string' ? (
              <View key={cellIdx} style={[styles.cellHeader, borderStyles, { flex }]}>
                {cellIdx === tenthsColumnIndex && decimalPoint()}
                <Text
                  style={[
                    styles.cellHeaderText,
                    {
                      fontSize: tableFontSize ?? styles.cellHeaderText.fontSize
                    }
                  ]}
                >
                  {cell}
                </Text>
              </View>
            ) : (
              <View
                key={cellIdx}
                style={[styles.cellHeader, borderStyles, cell.containerStyle, { flex }]}
              >
                {cellIdx === tenthsColumnIndex && decimalPoint()}
                <Text
                  style={[
                    styles.cellHeaderText,
                    {
                      fontSize: tableFontSize ?? styles.cellHeaderText.fontSize
                    },
                    cell.textStyle
                  ]}
                >
                  {cell.label}
                </Text>
              </View>
            );
          })}
        </View>
      )}

      {/* Row data */}
      {tableData.map((row, rowIdx) => {
        return (
          <View key={rowIdx} style={[styles.subContainer, rowStyle]}>
            {rowIdx > 0 && (
              <Text style={styles.rowSeperatorSymbolLeft}>{rowSeperatorExtraSymbolLeft}</Text>
            )}
            {row.map((col, colIdx) => {
              const flex = columnFlexValues ? columnFlexValues[colIdx] : 1;
              return (
                <View
                  key={colIdx}
                  style={[
                    styles.cellData,
                    !cellHeaders && rowIdx === 0 && { borderTopWidth: 0 },
                    tableCellStyleIdx?.includes(colIdx) ? tableCellStyle : undefined,
                    !tableCellStyleIdx && tableCellStyle,
                    { flex }
                  ]}
                >
                  {colIdx === tenthsColumnIndex && decimalPoint()}
                  {renderCell(col)}
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

/** See {@link CustomizableTable} */
export const CustomizableTableWithState = withStateHOC(CustomizableTable, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: {
    testComplete: state => (state ? state.every(it => it !== '') : false)
  }
});

const useStyles = (borderWidth: number, displayMode: 'digital' | 'pdf' | 'markscheme') => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          borderColor: colors.prussianBlue,
          borderBottomWidth: borderWidth,
          borderRightWidth: borderWidth,
          borderTopWidth: borderWidth,
          width: '100%'
        },
        subContainer: {
          flexDirection: 'row'
        },
        cellHeader: {
          backgroundColor: colors.pacificBlue,
          borderColor: colors.prussianBlue,
          padding: 6,
          flex: 1,
          justifyContent: 'center'
        },
        cellHeaderText: {
          color: colors.white,
          fontSize: 32,
          textAlign: 'center'
        },
        cellData: {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          borderLeftWidth: borderWidth,
          borderTopWidth: borderWidth,
          borderColor: colors.prussianBlue,
          flex: 1,
          justifyContent: 'center',
          padding: 6
        },
        cellDataText: {
          fontSize: 32
        },
        // Decimal between columns
        decimalBase: {
          height: displayMode === 'digital' ? 12 : 20,
          width: displayMode === 'digital' ? 12 : 20,
          borderRadius: 50,
          backgroundColor: colors.prussianBlue,
          position: 'absolute',
          left: displayMode === 'digital' ? -7 : -12
        },
        decimalWrapper: {
          height: '100%',
          width: 0,
          justifyContent: 'center',
          position: 'absolute',
          left: 0
        },
        decimalHeader: {
          top: displayMode === 'digital' ? 18 : 36
        },
        rowSeperatorSymbolLeft: {
          position: 'absolute',
          top: displayMode === 'digital' ? -22 : -40,
          left: displayMode === 'digital' ? -30 : -40,
          fontSize: 32
        }
      }),
    [borderWidth, displayMode]
  );
};
