import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { ShapeNames } from '../../../../utils/labelPositions';
import { LinesWithAnswersWithState } from '../../../../components/question/representations/LinesWithAnswers';
import { isEqual, isInRange } from '../../../../utils/matchers';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { colors } from '../../../../theme/colors';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { rectilinearWithSideRatios } from '../../../../utils/shapeImages/polygons';
import {
  getRandomRectilinearLShape,
  LShapeNameSchema,
  LShapeProperties,
  getRandomUniqueRectilinearLShapes
} from '../../../../utils/rectilinearShapes';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aLI',
  description: 'aLI',
  keywords: ['Missing length'],
  schema: z.object({
    total: z.number().int().min(10).max(20),
    partition1: z.number().int().min(2).max(18),
    answerIndex: z.number().int().min(0).max(2),
    rowColors: z.string().array().array().length(2)
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(10, 20);
    const partition1 = randomIntegerInclusive(3, total - 3);
    const answerIndex = rejectionSample(
      () => randomIntegerInclusive(0, 2),
      // for spacing, make sure that the answer box is on a number greater than an fifth of total
      val => [partition1, total - partition1, total][val] / total > 1 / 5
    );

    const colorOptions = getRandomSubArrayFromArray(
      [colors.red, colors.green, colors.gridBlue, colors.orange2],
      3
    );

    const rowColors = [[colorOptions[0], colorOptions[1]], [colorOptions[2]]];

    return { total, partition1, answerIndex, rowColors };
  },
  Component: ({ question, translate, displayMode }) => {
    const { total, partition1, answerIndex, rowColors } = question;

    let top: string[];
    let bottom: string[];
    let answer: number;
    if (answerIndex === 0) {
      top = [`<ans/> ${translate.units.cm()}`, translate.units.numberOfCm(total - partition1)];
      bottom = [translate.units.numberOfCm(total)];
      answer = partition1;
    }
    if (answerIndex === 1) {
      top = [translate.units.numberOfCm(partition1), `<ans/> ${translate.units.cm()}`];
      bottom = [translate.units.numberOfCm(total)];
      answer = total - partition1;
    }
    if (answerIndex === 2) {
      top = [
        translate.units.numberOfCm(partition1),
        translate.units.numberOfCm(total - partition1)
      ];
      bottom = [`<ans/> ${translate.units.cm()}`];
      answer = total;
    }

    return (
      <QF3Content
        title={translate.instructions.workOutTheMissingLength()}
        inputType="numpad"
        Content={({ dimens }) => (
          <LinesWithAnswersWithState
            total={total}
            dimens={dimens}
            partition={[partition1, total - partition1]}
            labels={[top, bottom]}
            testCorrect={isEqual([answer.toString()])}
            testComplete={userAnswer => userAnswer.every(i => i !== '')}
            id={'linesWithAnswers'}
            rowColors={rowColors}
            defaultState={displayMode === 'markscheme' ? [answer.toLocaleString()] : undefined}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aLJ',
  description: 'aLJ',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    answerIndex: z.number(),
    answerValue: z.number(),
    shape: z.object({ name: z.string(), sideLengths: z.array(z.number()) })
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const baseValue = randomIntegerInclusive(1, 5);
    const shapeToUse = {
      name: 'Rectilinear_shape1_all_arrows',
      sideLengths: rectilinearWithSideRatios['Rectilinear_shape1_all_arrows'].map(
        x => x * baseValue
      )
    };

    const answerIndex = randomIntegerInclusive(0, shapeToUse.sideLengths.length - 1);
    const answerValue = shapeToUse.sideLengths[answerIndex];

    return { answerIndex, shape: shapeToUse, answerValue };
  },

  Component: props => {
    const {
      question: { answerIndex, shape, answerValue },
      translate
    } = props;

    const labels = shape.sideLengths;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMissingLength()}
        testCorrect={[answerValue.toString()]}
        sentence={`? = ${translate.answerSentences.ansCm()}`}
        pdfDirection="column"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shape.name as ShapeNames}
            labels={labels.map((el, i) =>
              i === answerIndex ? '?' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aLJ2',
  description: 'aLJ',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    answerIndex: z.number(),
    sideLengths: z.array(z.number().min(1).max(12)).length(6),
    shapeName: LShapeNameSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shapeName = getRandomRectilinearLShape();

    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[shapeName];

    const { answerIndex, sideLengths } = rejectionSample(
      () => {
        const sideLengths = getRandomFromArray(sideLengthRatioOptions) as number[];
        const answerIndex = getRandomFromArray([oppLSides[0][0], oppLSides[1][0]]);

        return { answerIndex, sideLengths };
      },
      ({ answerIndex, sideLengths }) =>
        // All lengths should be less than 12 and hidden side length should be between 3 and 9
        Math.max(...sideLengths) <= 12 && isInRange(3, 9)(sideLengths[answerIndex])
    );

    return { answerIndex, shapeName, sideLengths };
  },

  Component: props => {
    const {
      question: { answerIndex, shapeName, sideLengths },
      translate
    } = props;

    const missingValue = sideLengths[answerIndex];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMissingLength()}
        testCorrect={[missingValue.toString()]}
        sentence={`? = ${translate.answerSentences.ansCm()}`}
        pdfDirection="column"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shapeName}
            labels={sideLengths.map((el, i) =>
              i === answerIndex ? '?' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aLK',
  description: 'aLK',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    answerIndex: z.number(),
    answerValue: z.number(),
    shape: z.object({ name: z.string(), sideLengths: z.array(z.number()) })
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const baseValue = randomIntegerInclusive(1, 5);

    const shapeToUse = {
      name: 'Rectilinear_shape1_all_arrows',
      sideLengths: rectilinearWithSideRatios['Rectilinear_shape1_all_arrows'].map(
        x => x * baseValue
      )
    };

    const answerIndex = randomIntegerInclusive(0, shapeToUse.sideLengths.length - 1);
    const answerValue = shapeToUse.sideLengths[answerIndex];

    return { answerIndex, shape: shapeToUse, answerValue };
  },

  Component: props => {
    const {
      question: { answerIndex, shape, answerValue },
      translate
    } = props;

    const labels = shape.sideLengths;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMissingLength()}
        testCorrect={[answerValue.toString()]}
        sentence={`? = ${translate.answerSentences.ansCm()}`}
        pdfDirection="column"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shape.name as ShapeNames}
            labels={labels.map((el, i) =>
              i === answerIndex ? '?' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aLK2',
  description: 'aLK',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    answerIndex: z.number().int().min(0).max(5),
    sideLengths: z.array(z.number().int().min(1).max(12)).length(6),
    shapeName: LShapeNameSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shapeName = getRandomRectilinearLShape();
    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[shapeName];

    const { answerIndex, sideLengths } = rejectionSample(
      () => {
        const baseValue = randomIntegerInclusive(1, 5);
        const sideLengthRatios = getRandomFromArray(sideLengthRatioOptions) as number[];
        const sideLengths = sideLengthRatios.map(x => x * baseValue);
        const answerIndex = getRandomFromArray([...oppLSides[0][1], ...oppLSides[1][1]]) as number;

        return { answerIndex, sideLengths };
      },
      ({ answerIndex, sideLengths }) =>
        // All lengths should be less than 12 and hidden side length should be between 3 and 9
        Math.max(...sideLengths) <= 12 && isInRange(1, 8)(sideLengths[answerIndex])
    );

    return { answerIndex, shapeName, sideLengths };
  },

  Component: props => {
    const {
      question: { answerIndex, shapeName, sideLengths },
      translate
    } = props;

    const missingValue = sideLengths[answerIndex];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMissingLength()}
        testCorrect={[missingValue.toString()]}
        sentence={`? = ${translate.answerSentences.ansCm()}`}
        pdfDirection="column"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shapeName}
            labels={sideLengths.map((el, i) =>
              i === answerIndex ? '?' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aLL',
  description: 'aLL',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z
    .object({
      answerShape: z.enum(['A', 'B']),
      missingIndexA: z.number().int().min(0).max(5),
      missingIndexB: z.number().int().min(0).max(5),
      sideLengthsA: z.array(z.number().min(1).max(12)).length(6),
      sideLengthsB: z.array(z.number().min(1).max(12)).length(6),
      shapeA: LShapeNameSchema,
      shapeB: LShapeNameSchema
    })
    .refine(
      ({ sideLengthsA, sideLengthsB, missingIndexA, missingIndexB }) =>
        sideLengthsA[missingIndexA] !== sideLengthsB[missingIndexB],
      'missing lengths should be different'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [shapeA, shapeB] = getRandomUniqueRectilinearLShapes(2);

    const { sideLengthRatioOptions: sideLengthRatioOptionsA, oppLSides: oppLSidesA } =
      LShapeProperties[shapeA];

    const { sideLengthRatioOptions: sideLengthRatioOptionsB, oppLSides: oppLSidesB } =
      LShapeProperties[shapeB];

    const sideLengthsA = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptionsA) as number[],
      // Side length should be a max of 12
      x => Math.max(...x) <= 12
    );
    const missingIndexA = getRandomFromArray([oppLSidesA[0][0], oppLSidesA[1][0]]) as number;

    const sideLengthsB = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptionsB) as number[],
      // Side length should be a max of 12
      x => Math.max(...x) <= 12
    );

    const { missingIndexB } = rejectionSample(
      () => {
        const missingIndexB = getRandomFromArray([
          ...oppLSidesB[0][1],
          ...oppLSidesB[1][1]
        ]) as number;
        return { missingIndexB };
      },
      ({ missingIndexB }) => sideLengthsA[missingIndexA] !== sideLengthsB[missingIndexB]
    );

    const answerShape = getRandomFromArray(['A', 'B'] as const);

    return {
      shapeA,
      shapeB,
      missingIndexA,
      missingIndexB,
      sideLengthsA,
      sideLengthsB,
      answerShape
    };
  },

  Component: props => {
    const {
      question: {
        shapeA,
        shapeB,
        missingIndexA,
        missingIndexB,
        sideLengthsA,
        sideLengthsB,
        answerShape
      },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: 'A',
          svgName: shapeA,
          sideLengths: sideLengthsA,
          missingIndex: missingIndexA
        },
        {
          value: 'B',
          svgName: shapeB,
          sideLengths: sideLengthsB,
          missingIndex: missingIndexB
        }
      ],
      { random: seededRandom(props.question) }
    );

    const missingLength =
      answerShape === 'A' ? sideLengthsA[missingIndexA] : sideLengthsB[missingIndexB];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheShapeThatHasMissingLengthX(missingLength)}
        pdfTitle={translate.instructions.circleTheShapeThatHasMissingLengthX(missingLength)}
        testCorrect={[answerShape]}
        questionHeight={1000}
        numItems={2}
        renderItems={({ dimens }) =>
          items.map(({ value, svgName, sideLengths, missingIndex }) => ({
            value,
            component: (
              <LabelledShape
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                shapeName={svgName}
                labels={sideLengths.map((val, idx) =>
                  idx === missingIndex ? '?' : translate.units.numberOfCm(val)
                )}
                seed={props.question}
              />
            )
          }))
        }
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aLL2',
  description: 'aLL2',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    missingIndex: z.number().int().min(0).max(5),
    sideLengths: z.array(z.number().min(1).max(12)).length(6),
    shape: LShapeNameSchema,
    options: z
      .array(z.number().min(1).max(24))
      .length(4)
      .refine(x => arrayHasNoDuplicates(x), 'options should have no duplicates')
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [shape] = getRandomUniqueRectilinearLShapes(2);

    const { sideLengthRatioOptions: sideLengthRatioOptions, oppLSides: oppLSides } =
      LShapeProperties[shape];

    const sideLengths = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptions) as number[],
      // Side length should be a max of 12
      x => Math.max(...x) <= 12
    );

    const [missingSet, oppSet] = shuffle([0, 1]);
    const missingLengthIsSum = getRandomBoolean();

    const { missingIndex, wrong1Length, wrong2Length, wrong3Length } = (() => {
      if (missingLengthIsSum) {
        const missingIndex = oppLSides[missingSet][0];
        const [missingSetOtherIdx1, missingSetOtherIdx2] = oppLSides[missingSet][1];
        const otherSetSum = oppLSides[oppSet][0];
        const oppSetOtherIdx = oppLSides[missingSet][1][1];

        const wrong1 = Math.abs(
          sideLengths[missingSetOtherIdx1] - sideLengths[missingSetOtherIdx2]
        );
        const wrong1Length = wrong1 === 0 ? 1 : wrong1;
        const wrong2Length = sideLengths[missingSetOtherIdx2] + sideLengths[oppSetOtherIdx];
        const wrong3 = Math.abs(sideLengths[otherSetSum] - sideLengths[missingSetOtherIdx2]);
        const wrong3Length = wrong3 === 0 ? 1 : wrong3;
        return { missingIndex, wrong1Length, wrong2Length, wrong3Length };
      } else {
        const [index, otherIdx] = [0, 1];
        const missingIndex = oppLSides[missingSet][1][index];
        const missingSetOtherIdx = oppLSides[missingSet][1][otherIdx];
        const missingSetSum = oppLSides[missingSet][0];
        const otherSetIndex = oppLSides[oppSet][1][index];

        const wrong1Length = sideLengths[missingSetOtherIdx] + sideLengths[missingSetSum];
        const wrong2Length = sideLengths[missingSetOtherIdx] + sideLengths[otherSetIndex];
        const wrong3 = Math.abs(sideLengths[missingSetSum] - sideLengths[otherSetIndex]);
        const wrong3Length = wrong3 === 0 ? 1 : wrong3;

        return { missingIndex, wrong1Length, wrong2Length, wrong3Length };
      }
    })();

    // Remove all duplicates
    const noDups = [
      ...new Set([sideLengths[missingIndex], wrong1Length, wrong2Length, wrong3Length])
    ];
    // Replace any options removed with random numbers
    const options = shuffle([
      ...noDups,
      ...randomUniqueIntegersInclusive(1, 24, 4 - noDups.length, {
        constraint: x => arrayHasNoDuplicates([...noDups, x])
      })
    ]);

    return {
      shape,
      missingIndex,
      sideLengths,
      options
    };
  },

  Component: props => {
    const {
      question: { shape, missingIndex, sideLengths, options },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.whatIsMissingLength()}
        testCorrect={[sideLengths[missingIndex]]}
        numItems={4}
        itemLayout="row"
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
            shapeName={shape}
            labels={sideLengths.map((val, idx) =>
              idx === missingIndex ? '?' : translate.units.numberOfCm(val)
            )}
            seed={props.question}
          />
        )}
        renderItems={options.map(item => ({
          component: (
            <Text style={{ textAlign: 'center' }} variant="WRN700">
              {translate.units.numberOfCm(item)}
            </Text>
          ),
          value: item
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aLM',
  description: 'aLM',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    missingIdx: z.number().int().min(0).max(5),
    shapeName: LShapeNameSchema,
    sideLengths: z.array(z.number().int().min(1).max(12)).length(6),
    isCorrect: z.boolean(),
    missingValue: z.number().int()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shapeName = getRandomRectilinearLShape();
    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[shapeName];
    const sideLengths = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptions) as number[],
      // Side length should be a max of 12
      x => Math.max(...x) <= 12
    );
    const missingIdx = randomIntegerInclusive(0, sideLengths.length - 1);
    const isCorrect = getRandomBoolean();

    const getIncorrectValue = (): number => {
      let incorrectValue = 0;
      oppLSides.forEach(([sidesL, [side1, side2]]) => {
        const sideLValue = sideLengths[sidesL];
        const side1Value = sideLengths[side1];
        const side2Value = sideLengths[side2];

        // If the missing idx is the long side, get the difference of the oppsite sides
        if (missingIdx === sidesL) {
          const sortOppSides = sortNumberArray([side1Value, side2Value]);
          incorrectValue = sortOppSides[1] - sortOppSides[0];
          return;
        } // If the missing idx one of the shorter sides, add the remaining side to the long opposite side
        else if (missingIdx === side1) {
          incorrectValue = sideLValue + side2Value;
          return;
        } else if (missingIdx === side2) {
          incorrectValue = sideLValue + side1Value;
        } else return;
      });
      return incorrectValue;
    };
    const missingValue = isCorrect ? sideLengths[missingIdx] : getIncorrectValue();

    return { missingIdx, shapeName, missingValue, isCorrect, sideLengths };
  },

  Component: props => {
    const {
      question: { missingIdx, shapeName, missingValue, isCorrect, sideLengths },
      translate
    } = props;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isStatementTrueOrFalse()}
        pdfTitle={translate.instructions.isStatementTrueOrFalse()}
        sentence={translate.answerSentences.theMissingLengthIsXcm(missingValue)}
        correctAnswer={isCorrect}
        questionHeight={1000}
        content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
            shapeName={shapeName}
            labels={sideLengths.map((el, i) =>
              i === missingIdx ? '?' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aLN',
  description: 'aLN',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    missingIndices: z.array(z.number().int().min(0).max(5)).length(2),
    sideLengths: z.array(z.number().int().min(1).max(20)).length(6),
    shapeName: LShapeNameSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shapeName = getRandomRectilinearLShape();
    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[shapeName];

    const sideLengths = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptions) as number[],
      // Side length should be a max of 20
      x => Math.max(...x) <= 20
    );

    // Get a missing index from different sides
    const missingIdx1 = getRandomFromArray([oppLSides[0][0], ...oppLSides[0][1]]);
    const missingIdx2 = getRandomFromArray([oppLSides[1][0], ...oppLSides[1][1]]);

    return { missingIndices: [missingIdx1, missingIdx2], shapeName, sideLengths };
  },

  Component: props => {
    const {
      question: { missingIndices, shapeName, sideLengths },
      translate
    } = props;

    const answerValues = [
      [sideLengths[missingIndices[0]].toString()],
      [sideLengths[missingIndices[1]].toString()]
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutTheMissingLengths()}
        testCorrect={answerValues}
        sentences={[
          `${translate.letters.A()} = ${translate.answerSentences.ansCm()}`,
          `${translate.letters.B()} = ${translate.answerSentences.ansCm()}`
        ]}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
            shapeName={shapeName}
            labels={sideLengths.map((el, i) =>
              missingIndices.includes(i)
                ? translate.letters[['A', 'B'][missingIndices.indexOf(i)] as 'A' | 'B']()
                : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindMissingLengthsInRectilinearShapes',
  questionTypes: [Question1, Question2v2, Question3v2, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question2, Question3, Question4]
});
export default SmallStep;
