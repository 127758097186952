import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Text from '../../typography/Text';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { Dimens } from '../../../theme/scaling';

type Props = {
  shapeName:
    | 'equilateralTriangles'
    | 'squares'
    | 'pentagons'
    | 'hexagons'
    | 'heptagons'
    | 'octagons'
    | 'nonagons'
    | 'decagons';
  dimens: Dimens;
  displayMode: 'digital' | 'pdf' | 'markscheme';
  shapeSvgName: string;
  labelText: string;
  shapeSize?: 'large' | 'small';
};

export const LabelledShapesWithMarkedSides = ({
  shapeName,
  dimens,
  displayMode,
  shapeSvgName,
  labelText,
  shapeSize = 'large'
}: Props) => {
  switch (shapeName) {
    case 'equilateralTriangles': {
      const divisible =
        displayMode === 'digital'
          ? shapeSize === 'large'
            ? 2.3
            : 4.1
          : shapeSize === 'large'
          ? 1.9
          : 4.2;

      return (
        <View>
          <AssetSvg name={shapeSvgName as SvgName} width={dimens.width} height={dimens.height} />
          <View
            style={{
              position: 'absolute',
              right: shapeSize === 'large' ? 205 : 295,
              top: shapeSize === 'large' ? 50 : 35
            }}
          >
            <View
              style={{
                transform: `rotate(150deg)`,
                top: displayMode === 'digital' ? (shapeSize === 'large' ? -70 : -50) : -70,
                right:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 70
                      : 125
                    : shapeSize === 'large'
                    ? 95
                    : 520
              }}
            >
              <Svg width={40} height={dimens.width / divisible}>
                <Path d={'M10,0 L0,16 L20,16 Z'} fill={colors.black} />
                <Path
                  d={`M10,5 L10,${dimens.width / divisible - 5}`}
                  stroke={colors.black}
                  strokeWidth={2}
                />
                <Path
                  d={`M10,${dimens.width / divisible}
                    L0,${dimens.width / divisible - 16}
                    L20,${dimens.width / divisible - 16}
                    Z`}
                  fill={colors.black}
                />
              </Svg>
            </View>
            <View
              style={{
                bottom:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 285
                      : 215
                    : shapeSize === 'large'
                    ? 380
                    : 360,
                left:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? -15
                      : undefined
                    : shapeSize === 'large'
                    ? -5
                    : undefined,
                right:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? undefined
                      : 90
                    : shapeSize === 'large'
                    ? undefined
                    : 490
              }}
            >
              {labelText && (
                <Text style={{ fontSize: displayMode === 'digital' ? 28 : 40 }}>{labelText}</Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    case 'squares': {
      const divisible =
        displayMode === 'digital'
          ? shapeSize === 'large'
            ? 2.75
            : 4.9
          : shapeSize === 'large'
          ? 2.25
          : 5.2;
      return (
        <View>
          <AssetSvg name={shapeSvgName as SvgName} height={dimens.height} width={dimens.width} />
          <View
            style={{
              position: 'absolute',
              right: displayMode === 'digital' ? (shapeSize === 'large' ? 205 : 365) : 720,
              top: 50
            }}
          >
            <View
              style={{
                transform: `rotate(180deg)`,
                top: displayMode === 'digital' ? (shapeSize === 'large' ? -35 : -40) : -30,
                right: displayMode === 'digital' ? 15 : 20
              }}
            >
              <Svg width={40} height={dimens.width / divisible}>
                <Path d={'M10,0 L0,16 L20,16 Z'} fill={colors.black} />
                <Path
                  d={`M10,5 L10,${dimens.width / divisible - 5}`}
                  stroke={colors.black}
                  strokeWidth={2}
                />
                <Path
                  d={`M10,${dimens.width / divisible}
                    L0,${dimens.width / divisible - 16}
                    L20,${dimens.width / divisible - 16}
                    Z`}
                  fill={colors.black}
                />
              </Svg>
            </View>
            <View
              style={{
                bottom:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 195
                      : 165
                    : shapeSize === 'large'
                    ? 265
                    : 250,
                left:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 45
                      : 25
                    : shapeSize === 'large'
                    ? 70
                    : 25
              }}
            >
              {labelText && (
                <Text style={{ fontSize: displayMode === 'digital' ? 28 : 40 }}>{labelText}</Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    case 'pentagons': {
      const divisible =
        displayMode === 'digital'
          ? shapeSize === 'large'
            ? 4
            : 7
          : shapeSize === 'large'
          ? 3.45
          : 8;
      return (
        <View>
          <AssetSvg name={shapeSvgName as SvgName} height={dimens.height} width={dimens.width} />
          <View
            style={{
              position: 'absolute',
              right:
                displayMode === 'digital'
                  ? shapeSize === 'large'
                    ? 280
                    : 420
                  : shapeSize === 'large'
                  ? 280
                  : 800
            }}
          >
            <View
              style={{
                transform: `rotate(126deg)`,
                top:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? -40
                      : -35
                    : shapeSize === 'large'
                    ? -50
                    : -60,
                right: displayMode === 'digital' ? 5 : 35
              }}
            >
              <Svg width={40} height={dimens.width / divisible}>
                <Path d={'M10,0 L0,16 L20,16 Z'} fill={colors.black} />
                <Path
                  d={`M10,5 L10,${dimens.width / divisible - 5}`}
                  stroke={colors.black}
                  strokeWidth={2}
                />
                <Path
                  d={`M10,${dimens.width / divisible}
                    L0,${dimens.width / divisible - 16}
                    L20,${dimens.width / divisible - 16}
                    Z`}
                  fill={colors.black}
                />
              </Svg>
            </View>
            <View
              style={{
                bottom:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 195
                      : 160
                    : shapeSize === 'large'
                    ? 265
                    : 240,
                left:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 45
                      : 25
                    : shapeSize === 'large'
                    ? 40
                    : 5
              }}
            >
              {labelText && (
                <Text style={{ fontSize: displayMode === 'digital' ? 28 : 40 }}>{labelText}</Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    case 'hexagons': {
      const divisible =
        displayMode === 'digital'
          ? shapeSize === 'large'
            ? 5
            : 8.6
          : shapeSize === 'large'
          ? 4
          : 9.5;
      return (
        <View>
          <AssetSvg name={shapeSvgName as SvgName} height={dimens.height} width={dimens.width} />
          <View
            style={{
              position: 'absolute',
              right:
                displayMode === 'digital'
                  ? shapeSize === 'large'
                    ? 280
                    : 430
                  : shapeSize === 'large'
                  ? 280
                  : 810
            }}
          >
            <View
              style={{
                transform: `rotate(150deg)`,
                top: shapeSize === 'large' ? 10 : 0,
                left: shapeSize === 'large' ? 40 : 35
              }}
            >
              <Svg width={40} height={dimens.width / divisible}>
                <Path d={'M10,0 L0,16 L20,16 Z'} fill={colors.black} />
                <Path
                  d={`M10,5 L10,${dimens.width / divisible - 5}`}
                  stroke={colors.black}
                  strokeWidth={2}
                />
                <Path
                  d={`M10,${dimens.width / divisible}
                    L0,${dimens.width / divisible - 16}
                    L20,${dimens.width / divisible - 16}
                    Z`}
                  fill={colors.black}
                />
              </Svg>
            </View>
            <View
              style={{
                bottom:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 110
                      : 95
                    : shapeSize === 'large'
                    ? 170
                    : 150,
                left:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 100
                      : 70
                    : shapeSize === 'large'
                    ? 130
                    : 75
              }}
            >
              {labelText && (
                <Text style={{ fontSize: displayMode === 'digital' ? 28 : 40 }}>{labelText}</Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    case 'heptagons': {
      const divisible =
        displayMode === 'digital'
          ? shapeSize === 'large'
            ? 6
            : 10.7
          : shapeSize === 'large'
          ? 5
          : 11.5;
      return (
        <View>
          <AssetSvg name={shapeSvgName as SvgName} height={dimens.height} width={dimens.width} />
          <View
            style={{
              position: 'absolute',
              right:
                displayMode === 'digital'
                  ? shapeSize === 'large'
                    ? 280
                    : 420
                  : shapeSize === 'large'
                  ? 280
                  : 810
            }}
          >
            <View
              style={{
                transform: `rotate(115deg)`,
                top: displayMode === 'digital' ? -35 : shapeSize === 'large' ? -50 : -65,
                right: displayMode === 'digital' ? 20 : 50
              }}
            >
              <Svg width={40} height={dimens.width / divisible}>
                <Path d={'M10,0 L0,16 L20,16 Z'} fill={colors.black} />
                <Path
                  d={`M10,5 L10,${dimens.width / divisible - 5}`}
                  stroke={colors.black}
                  strokeWidth={2}
                />
                <Path
                  d={`M10,${dimens.width / divisible}
                    L0,${dimens.width / divisible - 16}
                    L20,${dimens.width / divisible - 16}
                    Z`}
                  fill={colors.black}
                />
              </Svg>
            </View>
            <View
              style={{
                bottom:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 160
                      : 135
                    : shapeSize === 'large'
                    ? 230
                    : 215,
                left:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 15
                      : 5
                    : shapeSize === 'large'
                    ? 5
                    : -30
              }}
            >
              {labelText && (
                <Text style={{ fontSize: displayMode === 'digital' ? 28 : 40 }}>{labelText}</Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    case 'octagons': {
      const divisible =
        displayMode === 'digital'
          ? shapeSize === 'large'
            ? 7
            : 12
          : shapeSize === 'large'
          ? 6
          : 12.5;
      return (
        <View>
          <AssetSvg name={shapeSvgName as SvgName} height={dimens.height} width={dimens.width} />
          <View
            style={{
              position: 'absolute',
              right:
                displayMode === 'digital'
                  ? shapeSize === 'large'
                    ? 280
                    : 420
                  : shapeSize === 'large'
                  ? 280
                  : 820
            }}
          >
            <View
              style={{
                transform: `rotate(135deg)`,
                top:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 5
                      : -5
                    : shapeSize === 'large'
                    ? 15
                    : -20,
                right:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? -20
                      : -10
                    : shapeSize === 'large'
                    ? -5
                    : -15
              }}
            >
              <Svg width={40} height={dimens.width / divisible}>
                <Path d={'M10,0 L0,16 L20,16 Z'} fill={colors.black} />
                <Path
                  d={`M10,5 L10,${dimens.width / divisible - 5}`}
                  stroke={colors.black}
                  strokeWidth={2}
                />
                <Path
                  d={`M10,${dimens.width / divisible}
                    L0,${dimens.width / divisible - 16}
                    L20,${dimens.width / divisible - 16}
                    Z`}
                  fill={colors.black}
                />
              </Svg>
            </View>
            <View
              style={{
                bottom:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 105
                      : 90
                    : shapeSize === 'large'
                    ? 140
                    : 155,
                left:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 65
                      : 40
                    : shapeSize === 'large'
                    ? 75
                    : 45
              }}
            >
              {labelText && (
                <Text style={{ fontSize: displayMode === 'digital' ? 28 : 40 }}>{labelText}</Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    case 'nonagons': {
      const divisible =
        displayMode === 'digital'
          ? shapeSize === 'large'
            ? 8
            : 14
          : shapeSize === 'large'
          ? 7
          : 14;
      return (
        <View>
          <AssetSvg name={shapeSvgName as SvgName} height={dimens.height} width={dimens.width} />
          <View
            style={{
              position: 'absolute',
              right:
                displayMode === 'digital'
                  ? shapeSize === 'large'
                    ? 280
                    : 420
                  : shapeSize === 'large'
                  ? 280
                  : 810
            }}
          >
            <View
              style={{
                transform: `rotate(110deg)`,
                top: displayMode === 'digital' ? -30 : shapeSize === 'large' ? -35 : -60,
                right: displayMode === 'digital' ? 35 : shapeSize === 'large' ? 80 : 70
              }}
            >
              <Svg width={40} height={dimens.width / divisible}>
                <Path d={'M10,0 L0,16 L20,16 Z'} fill={colors.black} />
                <Path
                  d={`M10,5 L10,${dimens.width / divisible - 5}`}
                  stroke={colors.black}
                  strokeWidth={2}
                />
                <Path
                  d={`M10,${dimens.width / divisible}
                    L0,${dimens.width / divisible - 16}
                    L20,${dimens.width / divisible - 16}
                    Z`}
                  fill={colors.black}
                />
              </Svg>
            </View>
            <View
              style={{
                bottom: displayMode === 'digital' ? (shapeSize === 'large' ? 140 : 110) : 190,
                right:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 5
                      : 15
                    : shapeSize === 'large'
                    ? 30
                    : 50
              }}
            >
              {labelText && (
                <Text style={{ fontSize: displayMode === 'digital' ? 28 : 40 }}>{labelText}</Text>
              )}
            </View>
          </View>
        </View>
      );
    }
    case 'decagons': {
      const divisible =
        displayMode === 'digital'
          ? shapeSize === 'large'
            ? 9
            : 16
          : shapeSize === 'large'
          ? 8
          : 15.8;
      return (
        <View>
          <AssetSvg name={shapeSvgName as SvgName} height={dimens.height} width={dimens.width} />
          <View
            style={{
              position: 'absolute',
              right:
                displayMode === 'digital'
                  ? shapeSize === 'large'
                    ? 280
                    : 420
                  : shapeSize === 'large'
                  ? 280
                  : 810
            }}
          >
            <View
              style={{
                transform: `rotate(127deg)`,
                top:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 0
                      : -10
                    : shapeSize === 'large'
                    ? 10
                    : -20,
                right:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 0
                      : 5
                    : shapeSize === 'large'
                    ? 30
                    : 20
              }}
            >
              <Svg width={40} height={dimens.width / divisible}>
                <Path d={'M10,0 L0,16 L20,16 Z'} fill={colors.black} />
                <Path
                  d={`M10,5 L10,${dimens.width / divisible - 5}`}
                  stroke={colors.black}
                  strokeWidth={2}
                />
                <Path
                  d={`M10,${dimens.width / divisible}
                    L0,${dimens.width / divisible - 16}
                    L20,${dimens.width / divisible - 16}
                    Z`}
                  fill={colors.black}
                />
              </Svg>
            </View>
            <View
              style={{
                bottom:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 100
                      : 85
                    : shapeSize === 'large'
                    ? 130
                    : 140,
                left:
                  displayMode === 'digital'
                    ? shapeSize === 'large'
                      ? 50
                      : 25
                    : shapeSize === 'large'
                    ? 45
                    : 10
              }}
            >
              {labelText && (
                <Text style={{ fontSize: displayMode === 'digital' ? 28 : 40 }}>{labelText}</Text>
              )}
            </View>
          </View>
        </View>
      );
    }
  }
};
