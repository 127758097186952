import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF41MovableLinesForCreatingAngles from '../../../../components/question/questionFormats/QF41MovableLinesForCreatingAngles';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'azU',
  description: 'azU',
  keywords: ['Angles', 'Right angles', 'Acute', 'Obtuse'],
  schema: z.object({
    angles: z.number().int().min(30).max(359).array().length(4),
    startingPosition: z.number().int().min(0).max(359).array().length(4)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const acuteAngles = randomUniqueIntegersInclusive(30, 80, 2);
    const obtuseAngles = randomUniqueIntegersInclusive(100, 175, 2);

    const angles = shuffle([...acuteAngles, ...obtuseAngles]);

    const startingPosition = countRange(4).map(() => randomIntegerInclusive(0, 359));

    return { angles, startingPosition };
  },
  Component: props => {
    const {
      question: { angles, startingPosition },
      translate
    } = props;

    const correctAnswer = [[], []] as [lessThan: number[], moreThan: number[]];

    angles.forEach((angle, sumIndex) => {
      const zoneIndex = angle < 90 ? 0 : 1;
      correctAnswer[zoneIndex].push(sumIndex);
    });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortAnglesIntoTheTable()}
        pdfTitle={translate.instructions.useCardsToSortAnglesIntoTheTable()}
        zoneNames={[
          translate.tableHeaders.lessThanARightAngle(),
          translate.tableHeaders.greaterThanARightAngle()
        ]}
        testCorrect={correctAnswer}
        items={angles.map((angleValue, i) => ({
          value: i,
          component: (
            <AngleFromLines
              degrees={[startingPosition[i], startingPosition[i] + angleValue]}
              strokeWidth={3}
              lineLength={48}
            />
          )
        }))}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="pdfSquare"
        itemsMaxLines={3}
        questionHeight={800}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'azV',
  description: 'azV',
  keywords: ['Angles', 'Right angles', 'Acute', 'Obtuse', 'Reflex'],
  schema: z.object({
    optionIndexes: z
      .array(z.number().int().min(0).max(4))
      .length(3)
      .refine(val => arrayHasNoDuplicates(val), 'optionIndexes must contain 4 different values.')
  }),
  simpleGenerator: () => {
    const optionIndexes = randomUniqueIntegersInclusive(0, 4, 3);

    return { optionIndexes };
  },
  Component: props => {
    const {
      question: { optionIndexes },
      translate,
      displayMode
    } = props;

    const dimens =
      displayMode === 'digital' ? { height: 120, width: 400 } : { height: 150, width: 450 };

    const statements = [
      {
        option: (
          <View style={{ height: dimens.height, width: dimens.width, justifyContent: 'center' }}>
            <Text
              variant="WRN400"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.angles.acuteAngle()}
            </Text>
          </View>
        ),
        component: (
          <View style={{ height: dimens.height, width: dimens.width, justifyContent: 'center' }}>
            <Text variant="WRN700" style={{ fontSize: 32, textAlign: 'center' }}>
              {translate.answerSentences.lessThan('90°')}
            </Text>
          </View>
        ),
        value: 'A'
      },
      {
        option: (
          <View style={{ height: dimens.height, width: dimens.width, justifyContent: 'center' }}>
            <Text
              variant="WRN400"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.angles.reflexAngle()}
            </Text>
          </View>
        ),
        component: (
          <View
            style={{
              height: dimens.height,
              width: dimens.width,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Text variant="WRN700" style={{ fontSize: 32, textAlign: 'center' }}>
              {translate.answerSentences.greaterThanAngleDegrees(180)}
            </Text>
          </View>
        ),
        value: 'B'
      },
      {
        option: (
          <View style={{ height: dimens.height, width: dimens.width, justifyContent: 'center' }}>
            <Text
              variant="WRN400"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.angles.obtuseAngle()}
            </Text>
          </View>
        ),
        component: (
          <View
            style={{
              height: dimens.height,
              width: dimens.width,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Text variant="WRN700" style={{ fontSize: 32, textAlign: 'center', lineHeight: 45 }}>
              {translate.answerSentences.greaterThan90DegreesButLessThan180Degrees()}
            </Text>
          </View>
        ),
        value: 'C'
      },
      {
        option: (
          <View style={{ height: dimens.height, width: dimens.width, justifyContent: 'center' }}>
            <Text
              variant="WRN400"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.angles.rightAngle()}
            </Text>
          </View>
        ),
        component: (
          <View
            style={{
              height: dimens.height,
              width: dimens.width,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Text variant="WRN700" style={{ fontSize: 32, textAlign: 'center' }}>
              {translate.units.numberOfDegrees(90)}
            </Text>
          </View>
        ),
        value: 'D'
      },
      {
        option: (
          <View style={{ height: dimens.height, width: dimens.width, justifyContent: 'center' }}>
            <Text
              variant="WRN400"
              style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.angles.anglesAtApointOnAStraightLine()}
            </Text>
          </View>
        ),
        component: (
          <View
            style={{
              height: dimens.height,
              width: dimens.width,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Text variant="WRN700" style={{ fontSize: 32, textAlign: 'center' }}>
              {translate.units.numberOfDegrees(180)}
            </Text>
          </View>
        ),
        value: 'E'
      }
    ].filter((_, index) => optionIndexes.includes(index));

    const items = shuffle(statements, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchTypeOfAnglesToTheDescriptions()}
        pdfTitle={translate.instructions.useCardsToMatchTypeOfAnglesToTheDescriptions()}
        items={items}
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        itemsMaxLines={2}
        statementMaxLines={2}
        statements={statements.map(({ option, value }) => ({
          lhsComponent: option,
          correctAnswer: value
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'azW',
  description: 'azW',
  keywords: ['Angles', 'Acute', 'Obtuse'],
  schema: z.object({}),
  simpleGenerator: () => {
    return {};
  },
  Component: ({ translate }) => {
    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragLinesToMakeXAngle(translate.keywords.Acute())}
        pdfTitle={translate.instructions.drawAnXAngle(translate.keywords.Acute())}
        testCorrect={angle => angle >= 1 && angle < 90}
        startAngles={[0, 0]}
        markScheme={{ exampleCorrectAnswer: [15, 70], notes: translate.markScheme.anyAcuteAngle() }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'azX',
  description: 'azX',
  keywords: ['Angles', 'Obtuse'],
  schema: z.object({}),
  simpleGenerator: () => {
    return {};
  },
  Component: ({ translate }) => {
    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragLinesToMakeXAngle(translate.keywords.Obtuse())}
        pdfTitle={translate.instructions.drawAnXAngle(translate.keywords.Obtuse())}
        testCorrect={angle => angle > 90 && angle < 180}
        startAngles={[0, 0]}
        markScheme={{
          exampleCorrectAnswer: [5, 130],
          notes: translate.markScheme.anyObtuseAngle()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'azY',
  description: 'azY',
  keywords: ['Angles', 'Reflex'],
  schema: z.object({
    angles: z.number().int().min(1).max(359).array().length(4),
    startingPosition: z.number().int().min(0).max(359).array().length(4)
  }),
  simpleGenerator: () => {
    const [acuteAngleA, acuteAngleB] = randomUniqueIntegersInclusive(10, 80, 2);
    const [obtuseAngleA, obtuseAngleB] = randomUniqueIntegersInclusive(100, 175, 2);
    const reflexAngle = randomIntegerInclusive(185, 359);

    const randomAngle = getRandomFromArray([acuteAngleB, obtuseAngleB] as const);

    const angles = shuffle([acuteAngleA, obtuseAngleA, reflexAngle, randomAngle]);

    const startingPosition = countRange(4).map(() => randomIntegerInclusive(0, 359));

    return { angles, startingPosition };
  },
  Component: props => {
    const {
      question: { angles, startingPosition },
      translate,
      displayMode
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheReflexAngle()}
        pdfTitle={translate.instructions.circleAllAcuteAngles()}
        testCorrect={angles.filter(i => i > 180)}
        numItems={4}
        renderItems={({ dimens }) =>
          angles.map((val, i) => ({
            value: val,
            component: (
              <AngleFromLines
                degrees={[startingPosition[i], startingPosition[i] + val]}
                dimens={{ height: dimens.height - 75, width: dimens.width - 75 }}
                strokeWidth={displayMode === 'digital' ? 4 : 6}
              />
            )
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'azZ',
  description: 'azZ',
  keywords: ['Angles', 'Acute', 'Obtuse', 'Reflex'],
  schema: z.object({
    acuteAngleA: z.number().int().min(1).max(89),
    obtuseAngleA: z.number().int().min(91).max(179),
    reflexAngle: z.number().int().min(181).max(359),
    randomAngle: z.number().int().min(1).max(359)
  }),
  simpleGenerator: () => {
    const acuteAngleA = randomIntegerInclusive(1, 89);
    const obtuseAngleA = randomIntegerInclusive(91, 179);
    const reflexAngle = randomIntegerInclusive(181, 359);
    const randomAngle = randomIntegerInclusive(1, 359, { constraint: x => x % 90 !== 0 });

    return { acuteAngleA, obtuseAngleA, reflexAngle, randomAngle };
  },
  Component: props => {
    const {
      question: { acuteAngleA, obtuseAngleA, reflexAngle, randomAngle },
      translate
    } = props;

    const displayDegrees1 = translate.units.numberOfDegrees(acuteAngleA);
    const displayDegrees2 = translate.units.numberOfDegrees(obtuseAngleA);
    const displayDegrees3 = translate.units.numberOfDegrees(reflexAngle);
    const displayDegrees4 = translate.units.numberOfDegrees(randomAngle);

    const displayItems = [displayDegrees1, displayDegrees2, displayDegrees3, displayDegrees4];

    const correctAnswer = [[], []] as [acuteObtuse: number[], reflex: number[]];

    [acuteAngleA, obtuseAngleA, reflexAngle, randomAngle].forEach((angle, sumIndex) => {
      const zoneIndex = angle < 180 ? 0 : 1;
      correctAnswer[zoneIndex].push(sumIndex);
    });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortAnglesIntoTheTable()}
        pdfTitle={translate.instructions.useCardsToSortAnglesIntoTheTable()}
        zoneNames={[
          translate.tableHeaders.acuteOrObtuseAngle(),
          translate.tableHeaders.reflexAngle()
        ]}
        testCorrect={correctAnswer}
        items={shuffle(
          displayItems.map((displayItem, sumIndex) => ({
            value: sumIndex,
            component: displayItem
          })),
          { random: seededRandom(props.question) }
        )}
        itemVariant="rectangle"
        pdfItemVariant="pdfSquare"
        itemsMaxLines={1}
        itemsLetterEmWidth={0.6}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ClassifyAngles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
