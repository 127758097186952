import {
  PartWholeModel,
  PartWholeModelWithState
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  aaJ,
  Question1 as Y3Question1,
  Question3 as Y3Question3,
  Question4 as Y3Question4,
  Question5 as Y3Question5
} from '../../../Year 3/Autumn/PlaceValue/6PartitionNumbersTo1000';
import { base10ObjectToNumber } from '../../../../utils/math';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { numberEnum } from '../../../../utils/zod';
import { sumNumberArray } from '../../../../utils/collections';
import { isEqual } from '../../../../utils/matchers';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = { ...Y3Question1, uid: 'abC', description: 'abC' as const };

const Question2 = { ...Y3Question3, uid: 'abD', description: 'abD' as const };

const Question3 = { ...Y3Question4, uid: 'abE', description: 'abE' as const };

const Question3v2 = newQuestionContent({
  uid: 'abE2',
  description: 'abE',
  keywords: ['Place value', 'Partition', 'Part-whole', '1,000', 'Thousand'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(90).multipleOf(10),
    hundreds: z.number().int().min(100).max(900).multipleOf(100),
    powerToShow: numberEnum([0, 1, 2])
  }),
  simpleGenerator: () => {
    const powerToShow = getRandomFromArray([0, 1, 2] as const);
    const ones = randomIntegerInclusive(powerToShow === 0 ? 1 : 0, 9);
    const tens = randomIntegerInclusive(ones === 0 || powerToShow === 1 ? 1 : 0, 9) * 10;

    const hundreds = randomIntegerInclusive(1, 9) * 100;
    return { ones, tens, hundreds, powerToShow };
  },
  Component: ({ question, translate, displayMode }) => {
    const { ones, tens, hundreds, powerToShow } = question;

    const number = hundreds + tens + ones;

    let partition: (number | '$ans')[];
    switch (powerToShow) {
      case 0:
        if (tens === 0) {
          partition = [ones, '$ans'];
        } else {
          partition = [ones, '$ans', '$ans'];
        }
        break;
      case 1:
        if (ones === 0) {
          partition = [tens, '$ans'];
        } else {
          partition = ['$ans', tens, '$ans'];
        }
        break;
      case 2:
        if (ones === 0) {
          partition = ['$ans', hundreds];
        } else if (tens === 0) {
          partition = ['$ans', hundreds];
        } else {
          partition = ['$ans', '$ans', hundreds];
        }
        break;
    }
    const numberGiven = powerToShow === 0 ? ones : powerToShow === 1 ? tens : hundreds;
    const remainingNumber = number - numberGiven;

    const testCorrect = (answer: string[]) => {
      const totalOfAnswers = sumNumberArray(answer.map(value => parseInt(value)));

      return isEqual(totalOfAnswers)(remainingNumber) && !answer.includes('0');
    };

    const splitNumberIntoPlaceValues = (number: number) => {
      const result = [];
      let placeValue = 1;

      while (number > 0) {
        const part = (number % 10) * placeValue;
        if (part > 0) result.unshift(part.toLocaleString());
        number = Math.floor(number / 10);
        placeValue *= 10;
      }

      return result;
    };

    const markschemeAns = splitNumberIntoPlaceValues(remainingNumber);

    return (
      <QF3Content
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        Content={({ dimens }) => (
          <PartWholeModelWithState
            id="pwm-1"
            dimens={dimens}
            top={number}
            partition={shuffle(partition, { random: seededRandom(question) })}
            isInteractive
            testCorrect={testCorrect}
            defaultState={displayMode === 'markscheme' ? markschemeAns : undefined}
          />
        )}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.anyValidPartition() }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'abF',
  description: 'abF',
  keywords: ['Place value', 'Partition', 'Part-whole', '1,000', 'Thousand'],
  schema: z.object({
    number: z.number().int().min(101).max(989),
    numberPartition: z.number().int().array().length(2),
    wrongNumberPartitions: z.number().int().array().length(2).array().length(3),
    correctPosition: z.number().int().min(0).max(3)
  }),
  example: {
    number: 348,
    numberPartition: [8, 340],
    wrongNumberPartitions: [
      [8, 34],
      [8, 304],
      [40, 380]
    ],
    correctPosition: 3
  },
  questionHeight: 1100,
  simpleGenerator: () => {
    // Limit ones to 1-9 and tens to 1-9 (if ones are not zero from above, tens are zero instead) and hundreds to 1-9
    const tens = randomIntegerInclusive(0, 9);

    // Ensure ones are never the same as tens
    const ones = rejectionSample(
      () => randomIntegerInclusive(0, 9),
      x => x !== tens
    );

    // Ensure hundreds are never the same as tens
    const hundreds = rejectionSample(
      () => randomIntegerInclusive(1, 9),
      x => x !== tens
    );

    const number = base10ObjectToNumber({ ones, tens, hundreds });

    // Ensure correct part-whole model doesn't show one circle as just 0
    const numberPartitionUnshuffled =
      ones === 0 ? [tens * 10, ones + hundreds * 100] : [ones, tens * 10 + hundreds * 100];

    const wrongNumberPartitions = [
      shuffle([ones, (tens * 10 + hundreds * 100) / 10]),
      shuffle([ones * 10, tens + hundreds * 100]),
      shuffle([tens, ones * 10 + hundreds * 100])
    ];

    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);
    return {
      number,
      numberPartition: shuffle(numberPartitionUnshuffled),
      wrongNumberPartitions,
      correctPosition
    };
  },
  Component: props => {
    const { question, translate } = props;
    const { number, numberPartition, wrongNumberPartitions, correctPosition } = question;
    const allPartitions = [
      ...wrongNumberPartitions.slice(0, correctPosition),
      numberPartition,
      ...wrongNumberPartitions.slice(correctPosition)
    ];

    return (
      <QF11SelectImagesUpTo4<number>
        questionHeight={1100}
        title={`${translate.instructions.selectTheCorrectPartWholeModelForNum(
          number.toLocaleString()
        )}`}
        pdfTitle={`${translate.instructions.circleTheCorrectPartWholeModelForNum(
          number.toLocaleString()
        )}`}
        testCorrect={[correctPosition]}
        numItems={4}
        renderItems={({ dimens }) => {
          return allPartitions.map((partition, index) => ({
            value: index,
            component: (
              <PartWholeModel
                top={number}
                partition={partition}
                dimens={dimens}
                representation="text"
                pdfCircleDimens={200}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question5 = { ...Y3Question5, uid: 'abG', description: 'abG' as const };

const Question6 = { ...aaJ, uid: 'abH', description: 'abH' as const };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionNumbersTo1000',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
