import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import { filledArray } from '../../../utils/collections';
import { CreateShapeFromSquaresWithState } from '../representations/CreateShapeFromSquares';
import { DisplayShapeOnGrid } from '../representations/DisplayShapeOnGrid';
import { View } from 'react-native';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /**
   * Given shape's dimensions, as an array of array of boolean values.
   */
  givenShape: boolean[][];
  /**
   * Number of rows
   */
  numberOfRows: number;
  /**
   * Number of columns - Will be the same amount per row
   */
  numberOfCols: number;
  /**
   * Label to assign to the top-right Cell of the touchable grid.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  createCellSizeLabel?: string;
  /**
   * Label to assign to the top-right Cell of the given shape's grid.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  displayCellSizeLabel?: string;

  testCorrect: (userAnswer: boolean[][]) => boolean;
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: { answerToDisplay?: boolean[][]; answerText?: string };
};

export default function QF24aCreateShapeAndGivenShape({
  numberOfRows,
  numberOfCols,
  givenShape,
  createCellSizeLabel,
  displayCellSizeLabel,
  title,
  pdfTitle,
  testCorrect,
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const filterSelectedCols = (array: boolean[][]) => {
    return array.map(row => row.filter(col => col === true)).filter(col => col.length > 0);
  };

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => {
                const maxWidth = ((dimens.width * 0.9) / 8) * 3.5;
                const maxHeight = dimens.height * 0.9;

                const displayShapeSquareSize = Math.min(
                  maxHeight / givenShape.length,
                  maxWidth / givenShape[0].length
                );
                const createShapeSquareSize = Math.min(
                  maxHeight / numberOfRows,
                  maxWidth / numberOfCols
                );

                const squareSize = Math.min(displayShapeSquareSize, createShapeSquareSize);

                return (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <DisplayShapeOnGrid
                      givenShape={givenShape}
                      dimens={{ width: (dimens.width / 8) * 3.5, height: dimens.height }}
                      gridCellSize={squareSize}
                      cellSizeLabel={displayCellSizeLabel}
                    />
                    <View style={{ width: dimens.width / 8 }} />
                    <CreateShapeFromSquaresWithState
                      id="shape"
                      defaultState={
                        displayMode === 'markscheme'
                          ? customMarkSchemeAnswer?.answerToDisplay
                          : undefined
                      }
                      numberOfRows={numberOfRows}
                      numberOfCols={numberOfCols}
                      dimens={{ width: (dimens.width / 8) * 3.5, height: dimens.height }}
                      squareSize={squareSize}
                      cellSizeLabel={createCellSizeLabel}
                    />
                  </View>
                );
              }}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => {
            const maxWidth = ((dimens.width * 0.9) / 8) * 3.5;
            const maxHeight = dimens.height * 0.9;

            const displayShapeSquareSize = Math.min(
              maxHeight / givenShape.length,
              maxWidth / givenShape[0].length
            );
            const createShapeSquareSize = Math.min(
              maxHeight / numberOfRows,
              maxWidth / numberOfCols
            );

            const squareSize = Math.min(displayShapeSquareSize, createShapeSquareSize);

            return (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <DisplayShapeOnGrid
                  givenShape={givenShape}
                  dimens={{ width: (dimens.width / 8) * 3.5, height: dimens.height }}
                  gridCellSize={squareSize}
                  cellSizeLabel={displayCellSizeLabel}
                />
                <View style={{ width: dimens.width / 8 }} />
                <CreateShapeFromSquaresWithState
                  id="shape"
                  defaultState={filledArray(null, numberOfRows).map(() =>
                    filledArray(false, numberOfCols)
                  )}
                  testComplete={answer => filterSelectedCols(answer).length > 0}
                  testCorrect={testCorrect}
                  numberOfRows={numberOfRows}
                  numberOfCols={numberOfCols}
                  dimens={{ width: (dimens.width / 8) * 3.5, height: dimens.height }}
                  squareSize={squareSize}
                  cellSizeLabel={createCellSizeLabel}
                />
              </View>
            );
          }}
        </MeasureView>
      }
      {...props}
    />
  );
}
