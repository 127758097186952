import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomIntegerInclusive, getRandomFromArray } from '../../../../utils/random';
import QF64CreatePictogram from '../../../../components/question/questionFormats/QF64CreatePictogram';
import { FruitNameLC, fruitsSchema, getRandomUniqueFruits } from '../../../../utils/fruits';
import { numberEnum } from '../../../../utils/zod';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avQ',
  description: 'avQ',
  keywords: ['Pictogram', 'Draw'],
  questionHeight: 850,
  schema: z.object({
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().int().min(1).max(6),
    fruit2: z.number().int().min(1).max(6),
    fruit3: z.number().int().min(1).max(6),
    missingRow: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const fruit1 = randomIntegerInclusive(1, 6);
    const fruit2 = randomIntegerInclusive(1, 6);
    const missingRow = randomIntegerInclusive(0, 2);

    const fruit3 = randomIntegerInclusive(1, 6);

    const fruits = getRandomUniqueFruits(3);

    return { fruit1, fruit2, fruit3, fruits, missingRow };
  },
  Component: ({ question, translate }) => {
    const { fruit1, fruit2, fruit3, fruits, missingRow } = question;

    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      numbers[idx].toLocaleString()
    ]);

    const givenCols = [...numbers];
    givenCols[missingRow] = 0;

    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        filledCols={givenCols}
        testCorrect={numbers}
        draggableItems={['whole']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(1)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'avR',
  description: 'avR',
  keywords: ['Pictogram', 'Draw'],
  questionHeight: 850,
  schema: z.object({
    key: z.number().int().min(2).max(5),
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().int().min(1).max(6),
    fruit2: z.number().int().min(0).max(6),
    fruit3: z.number().int().min(0).max(6),
    missingRow: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const key = randomIntegerInclusive(2, 5);

    const fruit1 = randomIntegerInclusive(1, 6);
    const fruit2 = randomIntegerInclusive(0, 6);
    const fruit3 = randomIntegerInclusive(0, 6, {
      constraint: x => !(fruit2 === 0 && x === 0)
    });

    const fruits = getRandomUniqueFruits(3);

    const missingRow = randomIntegerInclusive(0, 2, {
      constraint: x => !(fruit2 === 0 && x === 1) && !(fruit3 === 0 && x === 2)
    });

    return { key, fruit1, fruit2, fruit3, fruits, missingRow };
  },
  Component: ({ question, translate }) => {
    const { key, fruit1, fruit2, fruit3, fruits, missingRow } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    const givenCols = [...numbers];
    givenCols[missingRow] = 0;
    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        filledCols={givenCols}
        testCorrect={numbers}
        draggableItems={['whole']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(key)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'avS',
  description: 'avS',
  keywords: ['Pictogram', 'Draw'],
  questionHeight: 850,
  schema: z.object({
    key: z.number().int().min(2).max(5),
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().int().min(5).max(6),
    fruit2: z.number().int().min(0).max(6),
    fruit3: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const key = randomIntegerInclusive(2, 5);

    const fruit1 = randomIntegerInclusive(5, 6);
    const fruit2 = randomIntegerInclusive(0, 6);
    const fruit3 = randomIntegerInclusive(1, 6);

    const fruits = getRandomUniqueFruits(3);

    return { key, fruit1, fruit2, fruit3, fruits };
  },
  Component: ({ question, translate }) => {
    const { key, fruit1, fruit2, fruit3, fruits } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    const givenCols = [0, 0, numbers[2]];

    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        filledCols={givenCols}
        testCorrect={numbers}
        draggableItems={['whole']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(key)}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'avT',
  description: 'avT',
  keywords: ['Pictogram', 'Draw'],
  questionHeight: 850,
  schema: z.object({
    key: z.number().int().min(2).max(5),
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().int().min(5).max(6),
    fruit2: z.number().int().min(0).max(6),
    fruit3: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const key = randomIntegerInclusive(2, 5);

    const fruit1 = randomIntegerInclusive(5, 6);
    const fruit2 = randomIntegerInclusive(0, 6);
    const fruit3 = randomIntegerInclusive(1, 6);

    const fruits = getRandomUniqueFruits(3);

    return { key, fruit1, fruit2, fruit3, fruits };
  },
  Component: ({ question, translate }) => {
    const { key, fruit1, fruit2, fruit3, fruits } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        testCorrect={numbers}
        draggableItems={['whole']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(key)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'avU',
  description: 'avU',
  keywords: ['Pictogram', 'Draw'],
  questionHeight: 850,
  schema: z.object({
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().min(1).max(6).step(0.5),
    fruit2: z.number().min(1).max(6).step(0.5),
    fruit3: z.number().min(1).max(6).step(0.5)
  }),
  simpleGenerator: () => {
    const fruit1 = number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));
    const fruit2 = number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));
    const fruit3 =
      randomIntegerInclusive(2, 12, {
        constraint: x => {
          // Make sure atleast one fruit will have a half circle
          if (fruit1 % 1 === 0 && fruit2 % 1 === 0) return x % 2 !== 0;
          return true;
        }
      }) / 2;

    const fruits = getRandomUniqueFruits(3);

    return { fruit1, fruit2, fruit3, fruits };
  },
  Component: ({ question, translate }) => {
    const { fruit1, fruit2, fruit3, fruits } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * 2).toLocaleString()
    ]);

    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        testCorrect={numbers}
        draggableItems={['whole', 'half']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(2)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'avV',
  description: 'avV',
  keywords: ['Pictogram', 'Draw'],
  questionHeight: 850,
  schema: z.object({
    key: numberEnum([2, 4]),
    fruits: z.array(fruitsSchema).length(3),
    fruit1: z.number().min(1).max(6).step(0.5),
    fruit2: z.number().min(1).max(6).step(0.5),
    fruit3: z.number().min(1).max(6).step(0.5)
  }),
  simpleGenerator: () => {
    const key = getRandomFromArray([2, 4] as const);

    const fruit1 = number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));
    const fruit2 = number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));
    const fruit3 =
      randomIntegerInclusive(2, 12, {
        constraint: x => {
          // Make sure atleast one fruit will have a half circle
          if (fruit1 % 1 === 0 && fruit2 % 1 === 0) return x % 2 !== 0;
          return true;
        }
      }) / 2;

    const fruits = getRandomUniqueFruits(3);

    return { key, fruit1, fruit2, fruit3, fruits };
  },
  Component: ({ question, translate }) => {
    const { key, fruit1, fruit2, fruit3, fruits } = question;
    const numbers = [fruit1, fruit2, fruit3];
    const lowerCase = fruits.map(val => val.toLowerCase() as FruitNameLC);
    const rowData = lowerCase.map((x, idx) => [
      translate.fruits[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        testCorrect={numbers}
        draggableItems={['whole', 'half']}
        columnNames={[
          translate.tableHeaders.Fruit(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.numberOfFruits()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.numberOfFruits(key)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawPictograms',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
