import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import TenFrameLayout, {
  counterVariantSchema,
  getRandomUniqueCounters
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  deduplicate,
  filledArray,
  sumNumberArray
} from '../../../../utils/collections';
import { ADD } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { numberEnum } from '../../../../utils/zod';
import { chunk } from '../../../../utils/chunk';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgQ',
  description: 'bgQ',
  keywords: ['Add', 'Ten frames', 'Counters', 'Bonds to 10'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(9)).length(3),
    colors: z.array(counterVariantSchema).length(3)
  }),
  simpleGenerator: () => {
    const colors = getRandomUniqueCounters(3);

    // Ensure 2 numbers in the array sum to 10, other can be random
    const numberA = randomIntegerInclusive(1, 9);
    const numberB = 10 - numberA;
    const numberC = randomIntegerInclusive(1, 9);

    return { numbers: shuffle([numberA, numberB, numberC]), colors };
  },
  Component: props => {
    const {
      question: { numbers, colors },
      translate,
      displayMode
    } = props;
    const [numberA, numberB, numberC] = numbers;

    const arrayOfColours = numbers.map((num, index) => filledArray(colors[index], num)).flat();

    const frames = chunk(arrayOfColours, 10);

    const sentence = `${numberA.toLocaleString()} ${ADD} ${numberB.toLocaleString()} ${ADD} ${numberC.toLocaleString()} = <ans/>`;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        Content={
          <View style={{ flexDirection: 'row', gap: displayMode === 'digital' ? 16 : 32 }}>
            <TenFrameLayout
              items={frames[0]}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
            <TenFrameLayout
              items={frames[1]}
              size={displayMode === 'digital' ? 'small' : 'large'}
            />
          </View>
        }
        pdfDirection="column"
        sentence={sentence}
        testCorrect={[(numberA + numberB + numberC).toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgR',
  description: 'bgR',
  keywords: ['Add'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(9)).length(3),
    reversed: z.boolean()
  }),
  simpleGenerator: () => {
    const reversed = getRandomBoolean();

    // 25% any 3-digit numbers that sum to 10-20
    // 75% two numbers sum to 10, other random
    const sumToTen = getRandomFromArrayWithWeights([true, false], [3, 1]);

    let numbers: number[];

    if (sumToTen) {
      const numberA = randomIntegerInclusive(1, 9);
      const numberB = 10 - numberA;
      const numberC = randomIntegerInclusive(1, 9);
      numbers = [numberA, numberB, numberC];
    } else {
      numbers = rejectionSample(
        () => {
          const numberA = randomIntegerInclusive(1, 9);
          const numberB = randomIntegerInclusive(1, 9);
          const numberC = randomIntegerInclusive(1, 9);
          return [numberA, numberB, numberC];
        },
        nums => isInRange(10, 20)(sumNumberArray(nums))
      );
    }

    return { numbers: shuffle(numbers), reversed };
  },
  Component: props => {
    const {
      question: { numbers, reversed },
      translate
    } = props;

    const [numberA, numberB, numberC] = numbers;
    const answer = numberA + numberB + numberC;

    const { sentence } = buildSimpleNumberSentence([numberA, numberB, numberC, answer], ADD, 3, {
      reversed
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheCalculation()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

const Question3 = newQuestionContent({
  uid: 'bgS',
  description: 'bgS',
  keywords: ['Add', 'Missing number'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(1).max(9)).length(3),
      ansIndex: numberEnum([0, 1, 2]),
      reversed: z.boolean(),
      incorrectOptions: z.array(z.number().int().min(0).max(50)).length(3)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numbers[val.ansIndex], ...val.incorrectOptions]),
      'All draggable options must be unique'
    ),
  simpleGenerator: () => {
    const reversed = getRandomBoolean();
    const ansIndex = getRandomFromArray([0, 1, 2] as const);

    const numberA = randomIntegerInclusive(1, 9);
    const numberB = 10 - numberA;
    const numberC = randomIntegerInclusive(1, 9);
    const numbers = shuffle([numberA, numberB, numberC]);

    // Incorrect options
    // 1. Sum of numbers in the equation except the answer
    // 2. Sum of numbers in the equation, plus total sum
    // 3. Correct answer + 1
    // 4. Correct answer - 1
    const incorrectA = numbers.reduce((a, b, i) => (i === ansIndex ? a : a + b), 0);
    const incorrectB = incorrectA + numberA + numberB + numberC;
    const incorrectC = numbers[ansIndex] + 1;
    const incorrectD = numbers[ansIndex] - 1;

    // Just in case we have duplicates
    // Throwaway option if equal to correct answer
    const options = [incorrectA, incorrectB, incorrectC, incorrectD];
    // Just in case we have duplicates
    const filteredOpts = deduplicate(
      options.filter(opt => opt !== numbers[ansIndex]),
      x => x
    );
    const shuffledIncorrectOptions = getRandomSubArrayFromArray(filteredOpts, 3);

    return {
      numbers,
      reversed,
      ansIndex,
      incorrectOptions: shuffledIncorrectOptions
    };
  },
  Component: props => {
    const {
      question: { numbers, reversed, ansIndex, incorrectOptions },
      translate
    } = props;

    const [numberA, numberB, numberC] = numbers;

    const { sentence, answer } = buildSimpleNumberSentence(
      [numberA, numberB, numberC, numberA + numberB + numberC],
      ADD,
      ansIndex,
      {
        reversed
      }
    );

    const items = [...incorrectOptions.map(opt => opt.toLocaleString()), answer.toLocaleString()];

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheAddition()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteAddition()}
        sentence={sentence}
        items={items}
        testCorrect={[answer.toString()]}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddThree1DigitNumbers',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
