import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { z } from 'zod';
import { range } from '../../../../utils/collections';
import QF27bShortDivision from '../../../../components/question/questionFormats/QF27bShortDivision';
import {
  isDivisionExchangesOnlyAt,
  numbersDoNotHaveDivisionExchange
} from '../../../../utils/exchanges';
import { powersOfTenWordToPow } from '../../../../utils/math';

// Function to provide lower and upper bounds for all Qs.
// Used to speed up overnight tests by allowing us to use randomIntegerInclusiveStep function in generators with these bounds.
const dividendBoundsFinder = (divisor: number) => {
  switch (divisor) {
    case 2:
      return [2002, 9998];
    case 3:
      return [2001, 9999];
    case 4:
      return [2004, 9996];
    case 5:
      return [2005, 9995];
    case 6:
      return [2004, 9996];
    case 7:
      return [2002, 9996];
    case 8:
      return [2008, 9992];
    case 9:
      return [2007, 9999];
    default:
      return [2001, 9999];
  }
};

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aPc',
  description: 'aPc',
  keywords: ['4-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(4),
      dividend: z.number().int().min(2001).max(9999)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 4);

    const [lowerBound, upperBound] = dividendBoundsFinder(divisor);

    const dividend = randomIntegerInclusiveStep(lowerBound, upperBound, divisor, {
      constraint: x => x % 10 !== 0 && numbersDoNotHaveDivisionExchange(x, divisor)
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aPd',
  description: 'aPd',
  keywords: ['4-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(2001).max(9999)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const exchangePosition = getRandomFromArray(['thousands', 'hundreds'] as const);

    const divisor = randomIntegerInclusive(2, 9);

    const [lowerBound, upperBound] = dividendBoundsFinder(divisor);

    const dividend = randomIntegerInclusiveStep(lowerBound, upperBound, divisor, {
      constraint: x => x % 10 !== 0 && isDivisionExchangesOnlyAt(x, divisor, [exchangePosition])
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aPe',
  description: 'aPe',
  keywords: ['4-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(9),
      dividend: z.number().int().min(2001).max(9999)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const numberOfExchanges = randomIntegerInclusive(2, 3);

    const exchangePositions = getRandomSubArrayFromArray(
      ['thousands', 'hundreds', 'tens'] as const,
      numberOfExchanges
    );

    const divisor = randomIntegerInclusive(2, 9);

    const [lowerBound, upperBound] = dividendBoundsFinder(divisor);

    const dividend = randomIntegerInclusiveStep(lowerBound, upperBound, divisor, {
      constraint: x => x % 10 !== 0 && isDivisionExchangesOnlyAt(x, divisor, exchangePositions)
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        divisor={divisor}
        dividend={dividend}
        quotient={quotient}
        quotientMissingDigits={range(0, quotient.toString().length - 1)}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aPf',
  description: 'aPf',
  keywords: ['4-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(5),
      dividend: z.number().int().min(2001).max(9999)
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 5);

    const [lowerBound, upperBound] = dividendBoundsFinder(divisor);

    const dividend = randomIntegerInclusiveStep(lowerBound, upperBound, divisor, {
      constraint: x => x % 10 !== 0 && numbersDoNotHaveDivisionExchange(x, divisor)
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const quotient = dividend / divisor;

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutTheMissingDigits()}
        divisor={divisor}
        dividend={dividend}
        dividendMissingDigits={range(0, dividend.toString().length - 1)}
        quotient={quotient}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aPg',
  description: 'aPg',
  keywords: ['4-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(5),
      dividend: z.number().int().min(2001).max(9999),
      quotientAnswerBoxPosition: z.enum(['thousands', 'hundreds', 'tens', 'ones']),
      dividendAnswerBoxPosition: z.enum(['thousands', 'hundreds', 'tens', 'ones'])
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.')
    .refine(val => val.dividend / val.divisor >= 1000, 'dividend / divisor must be at least 1000')
    .refine(
      val => val.quotientAnswerBoxPosition !== val.dividendAnswerBoxPosition,
      'quotientAnswerBoxPosition and dividendAnswerBoxPosition must be different.'
    ),
  simpleGenerator: () => {
    const exchangePosition = getRandomFromArray(['hundreds', 'tens'] as const);

    const divisor = randomIntegerInclusive(2, 5);

    const [lowerBound, upperBound] = dividendBoundsFinder(divisor);

    const dividend = randomIntegerInclusiveStep(lowerBound, upperBound, divisor, {
      constraint: x =>
        x % 10 !== 0 &&
        isDivisionExchangesOnlyAt(x, divisor, [exchangePosition]) &&
        x / divisor >= 1000
    });

    const [quotientAnswerBoxPosition, dividendAnswerBoxPosition] = getRandomSubArrayFromArray(
      ['thousands', 'hundreds', 'tens', 'ones'] as const,
      2
    );

    return { divisor, dividend, quotientAnswerBoxPosition, dividendAnswerBoxPosition };
  },
  Component: props => {
    const {
      question: { divisor, dividend, quotientAnswerBoxPosition, dividendAnswerBoxPosition },
      translate
    } = props;

    const quotient = dividend / divisor;

    const quotientMissingDigit = powersOfTenWordToPow[quotientAnswerBoxPosition];

    const dividendMissingDigit = powersOfTenWordToPow[dividendAnswerBoxPosition];

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutTheMissingDigits()}
        divisor={divisor}
        divisorMissingDigits={[0]}
        dividend={dividend}
        dividendMissingDigits={[dividendMissingDigit]}
        quotient={quotient}
        quotientMissingDigits={[quotientMissingDigit]}
        showNumberExchanges
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aPh',
  description: 'aPh',
  keywords: ['4-digit', 'Short division', 'Division'],
  schema: z
    .object({
      divisor: z.number().int().min(2).max(5),
      dividend: z.number().int().min(2001).max(9999),
      quotientAnswerBoxPosition: z.enum(['thousands', 'hundreds', 'tens', 'ones']),
      dividendAnswerBoxPosition: z.enum(['thousands', 'hundreds', 'tens', 'ones'])
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.')
    .refine(val => val.dividend / val.divisor >= 1000, 'dividend / divisor must be at least 1000')
    .refine(
      val => val.quotientAnswerBoxPosition !== val.dividendAnswerBoxPosition,
      'quotientAnswerBoxPosition and dividendAnswerBoxPosition must be different.'
    ),
  simpleGenerator: () => {
    const numberOfExchanges = randomIntegerInclusive(2, 3);

    const exchangePositions = getRandomSubArrayFromArray(
      ['thousands', 'hundreds', 'tens'] as const,
      numberOfExchanges
    );

    const divisor = randomIntegerInclusive(2, 5);

    const [lowerBound, upperBound] = dividendBoundsFinder(divisor);

    const dividend = randomIntegerInclusiveStep(lowerBound, upperBound, divisor, {
      constraint: x =>
        x % 10 !== 0 &&
        isDivisionExchangesOnlyAt(x, divisor, exchangePositions) &&
        x / divisor >= 1000
    });

    const [quotientAnswerBoxPosition, dividendAnswerBoxPosition] = getRandomSubArrayFromArray(
      ['thousands', 'hundreds', 'tens', 'ones'] as const,
      2
    );

    return { divisor, dividend, quotientAnswerBoxPosition, dividendAnswerBoxPosition };
  },
  Component: props => {
    const {
      question: { divisor, dividend, quotientAnswerBoxPosition, dividendAnswerBoxPosition },
      translate
    } = props;

    const quotient = dividend / divisor;

    const quotientMissingDigit = powersOfTenWordToPow[quotientAnswerBoxPosition];

    const dividendMissingDigit = powersOfTenWordToPow[dividendAnswerBoxPosition];

    return (
      <QF27bShortDivision
        title={translate.instructions.workOutTheMissingDigits()}
        divisor={divisor}
        divisorMissingDigits={[0]}
        dividend={dividend}
        dividendMissingDigits={[dividendMissingDigit]}
        quotient={quotient}
        quotientMissingDigits={[quotientMissingDigit]}
        showNumberExchanges
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA4DigitNumberByA1DigitNumber',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
