import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getGenderFromKs1Name, getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  displayMoney,
  moneyPenceToString,
  penceToPoundsAndPence,
  possibleMoneyDenominations,
  totalPenceToPoundsAndPence
} from '../../../../utils/money';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TextStructure from '../../../../components/molecules/TextStructure';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { arrayHasNoDuplicates, countRange, sumNumberArray } from '../../../../utils/collections';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bix',
  description: 'bix',
  keywords: ['Coins', 'Notes', 'Pounds', 'Pence', 'Money', 'Subtract', 'Change'],
  schema: z
    .object({
      denominations: z
        .array(numberEnum(possibleMoneyDenominations))
        .refine(
          denominations => isInRange(6, 6000)(sumNumberArray(denominations)),
          'Total must be between 6p and £60'
        ),
      amountSpent: z.number().int().min(5).max(5900),
      isPence: z.boolean(),
      characterName: ks1NameSchema
    })
    .refine(
      val => sumNumberArray(val.denominations) > val.amountSpent,
      'denominations must total to more than amountSpent.'
    )
    .refine(
      val =>
        val.isPence
          ? true
          : sumNumberArray(val.denominations) % 100 === 0 && val.amountSpent % 100 === 0,
      'If isPence is false, total of denominations and amountSpent must both be multiples of 100'
    ),
  simpleGenerator: () => {
    const { isPence, denominations } = rejectionSample(
      () => {
        const isPence = getRandomBoolean();

        const possibleDenominations = isPence
          ? ([2, 5, 10, 20, 50] as const)
          : ([200, 500, 1000, 2000] as const);

        const numberOfDenominations = randomIntegerInclusive(2, 8);

        const denominations = countRange(numberOfDenominations).map(_ =>
          getRandomFromArray(possibleDenominations)
        );

        denominations.sort((a, b) => b - a);

        return { isPence, denominations };
      },
      // Only permit them if their products are all different.
      ({ isPence, denominations }) => {
        const totalMoney = sumNumberArray(denominations);
        return isPence
          ? // Must total to between 6p and 99p if isPence:
            totalMoney >= 6 && totalMoney <= 99
          : // Must total to £60 or less if !isPence:
            totalMoney <= 6000;
      }
    );

    const amountSpentLowerBound = isPence
      ? sumNumberArray(denominations) - (Math.min(...denominations) - 1)
      : sumNumberArray(denominations) - (Math.min(...denominations) - 100);

    const amountSpentUpperBound = isPence
      ? sumNumberArray(denominations) - 1
      : sumNumberArray(denominations) - 100;

    const amountSpent = isPence
      ? randomIntegerInclusive(amountSpentLowerBound, amountSpentUpperBound)
      : randomIntegerInclusiveStep(amountSpentLowerBound, amountSpentUpperBound, 100);

    const characterName = getRandomKs1Name();

    return { denominations, amountSpent, isPence, characterName };
  },
  Component: ({ question, translate, displayMode }) => {
    const { denominations, amountSpent, isPence, characterName } = question;

    const totalMoney = sumNumberArray(denominations);

    const heOrShe =
      getGenderFromKs1Name(characterName) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const answer = isPence
      ? (totalMoney - amountSpent).toString()
      : ((totalMoney - amountSpent) / 100).toString();

    const sentence = isPence
      ? translate.ks1AnswerSentences.ansP()
      : translate.ks1AnswerSentences.poundAns();

    const combinationOfMoneyInStringForm = denominations.map(denom => moneyPenceToString[denom]);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.charHasThisMoney(characterName)}
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer]}
        inputMaxCharacters={2}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{
                flexDirection: 'row',
                gap: 16,
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              {displayMoney(
                combinationOfMoneyInStringForm,
                displayMode === 'digital' ? 80 : 130,
                displayMode === 'digital' ? 80 : 130,
                true,
                true
              )}
            </View>
            <TextStructure
              sentence={
                isPence
                  ? translate.ks1Instructions.xSpendsYP(heOrShe, amountSpent)
                  : translate.ks1Instructions.xSpendsPoundsY(heOrShe, amountSpent / 100)
              }
            />
            <TextStructure sentence={translate.ks1AnswerSentences.howMuchChangeDoesXGet(heOrShe)} />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biy',
  description: 'biy',
  keywords: ['Pounds', 'Pence', 'Money', 'Subtract', 'Change', 'Notes', 'Coins'],
  schema: z
    .object({
      characterName: ks1NameSchema,
      startingAmount: numberEnum([200, 500, 1000, 2000]),
      amountSpent: z.number().int().min(5).max(1995).step(5),
      items: z
        .object({
          value: z.enum(['A', 'B', 'C', 'D']),
          moneyValue: z.number(),
          denominations: z.string().array()
        })
        .array()
    })
    .refine(val => val.startingAmount > val.amountSpent),
  questionHeight: 1000,
  simpleGenerator: () => {
    const characterName = getRandomKs1Name();

    const startingAmount = getRandomFromArray([200, 500, 1000, 2000] as const);

    const amountSpent = randomIntegerInclusiveStep(5, startingAmount - 5, 5);

    const incorrectValues = ['B', 'C', 'D'] as const;

    const correctItem = {
      value: 'A' as const,
      moneyValue: startingAmount - amountSpent,
      denominations: totalPenceToPoundsAndPence(startingAmount - amountSpent)
    };

    const incorrectMoneyValues = [
      startingAmount - amountSpent + 5,
      startingAmount - amountSpent - 5,
      startingAmount - amountSpent + 10,
      startingAmount - amountSpent - 10,
      startingAmount - amountSpent + 100,
      amountSpent
    ].filter(num => num > 0);

    const incorrectItems = getRandomSubArrayFromArray(incorrectMoneyValues, 3).map(
      (moneyValue, index) => {
        return {
          value: incorrectValues[index],
          moneyValue,
          denominations: totalPenceToPoundsAndPence(moneyValue)
        };
      }
    );

    const items = shuffle([...incorrectItems, correctItem]);

    return {
      characterName,
      startingAmount,
      amountSpent,
      items
    };
  },

  Component: props => {
    const {
      question: { characterName, startingAmount, amountSpent, items },
      translate,
      displayMode
    } = props;

    const heOrShe =
      getGenderFromKs1Name(characterName) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const poundsAndPenceSpent = penceToPoundsAndPence(amountSpent);

    const poundsAndPenceSpentString =
      poundsAndPenceSpent.pence && poundsAndPenceSpent.pounds
        ? `£${poundsAndPenceSpent.pounds} ${translate.ks1MiscStrings.and()} ${
            poundsAndPenceSpent.pence
          }p`
        : !poundsAndPenceSpent.pounds
        ? `${poundsAndPenceSpent.pence}p`
        : `£${poundsAndPenceSpent.pounds}`;

    const selectableHasTwoNotes =
      items.filter(
        ({ denominations }) =>
          denominations.filter(denomination => denomination === '£10' || denomination === '£5')
            .length === 2
      ).length > 0;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.charHasPoundsXHeSheSpendsMoneyOnAComicTheBoxThatShowsTheChangeCharGets(
          characterName,
          startingAmount / 100,
          heOrShe,
          poundsAndPenceSpentString
        )}
        pdfTitle={translate.ks1PDFInstructions.charHasPoundsXHeSheSpendsMoneyOnAComicTickTheBoxThatShowsTheChangeCharGets(
          characterName,
          startingAmount / 100,
          heOrShe,
          poundsAndPenceSpentString
        )}
        testCorrect={['A']}
        questionHeight={1000}
        pdfShowBorder
        renderItems={items.map(({ value, denominations }) => {
          return {
            value,
            component: (
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: 4 }}>
                {displayMoney(
                  denominations,
                  displayMode === 'digital' ? (selectableHasTwoNotes ? 50 : 65) : 100,
                  displayMode === 'digital' ? (selectableHasTwoNotes ? 50 : 65) : 100,
                  true
                )}
              </View>
            )
          };
        })}
        numItems={4}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'biy2',
  description: 'biy',
  keywords: ['Pounds', 'Pence', 'Money', 'Subtract', 'Change', 'Notes', 'Coins'],
  schema: z
    .object({
      characterName: ks1NameSchema,
      startingAmount: numberEnum([200, 500, 1000, 2000]),
      amountSpent: z.number().int().min(5).max(1995).step(5),
      items: z
        .object({
          value: z.enum(['A', 'B', 'C', 'D']),
          moneyValue: z.number(),
          denominations: z.string().array()
        })
        .array()
        .refine(items => arrayHasNoDuplicates(items.map(obj => obj.moneyValue)))
    })
    .refine(val => val.startingAmount > val.amountSpent),
  questionHeight: 1000,
  simpleGenerator: () => {
    const characterName = getRandomKs1Name();

    const startingAmount = getRandomFromArray([200, 500, 1000, 2000] as const);

    const amountSpent = randomIntegerInclusiveStep(5, startingAmount - 5, 5);

    const incorrectValues = ['B', 'C', 'D'] as const;

    const correctItem = {
      value: 'A' as const,
      moneyValue: startingAmount - amountSpent,
      denominations: totalPenceToPoundsAndPence(startingAmount - amountSpent)
    };

    const incorrectMoneyValues = [
      ...new Set([
        startingAmount - amountSpent + 5,
        startingAmount - amountSpent - 5,
        startingAmount - amountSpent + 10,
        startingAmount - amountSpent - 10,
        startingAmount - amountSpent + 100,
        amountSpent
      ])
    ].filter(num => num > 0 && num !== correctItem.moneyValue);

    const incorrectItems = getRandomSubArrayFromArray(incorrectMoneyValues, 3).map(
      (moneyValue, index) => {
        return {
          value: incorrectValues[index],
          moneyValue,
          denominations: totalPenceToPoundsAndPence(moneyValue)
        };
      }
    );

    const items = shuffle([...incorrectItems, correctItem]);

    return {
      characterName,
      startingAmount,
      amountSpent,
      items
    };
  },

  Component: props => {
    const {
      question: { characterName, startingAmount, amountSpent, items },
      translate,
      displayMode
    } = props;

    const heOrShe =
      getGenderFromKs1Name(characterName) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const poundsAndPenceSpent = penceToPoundsAndPence(amountSpent);

    const poundsAndPenceSpentString =
      poundsAndPenceSpent.pence && poundsAndPenceSpent.pounds
        ? `£${poundsAndPenceSpent.pounds} ${translate.ks1MiscStrings.and()} ${
            poundsAndPenceSpent.pence
          }p`
        : !poundsAndPenceSpent.pounds
        ? `${poundsAndPenceSpent.pence}p`
        : `£${poundsAndPenceSpent.pounds}`;

    const selectableHasTwoNotes =
      items.filter(
        ({ denominations }) =>
          denominations.filter(denomination => denomination === '£10' || denomination === '£5')
            .length === 2
      ).length > 0;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.charHasPoundsXHeSheSpendsMoneyOnAComicTheBoxThatShowsTheChangeCharGets(
          characterName,
          startingAmount / 100,
          heOrShe,
          poundsAndPenceSpentString
        )}
        pdfTitle={translate.ks1PDFInstructions.charHasPoundsXHeSheSpendsMoneyOnAComicTickTheBoxThatShowsTheChangeCharGets(
          characterName,
          startingAmount / 100,
          heOrShe,
          poundsAndPenceSpentString
        )}
        testCorrect={['A']}
        questionHeight={1000}
        pdfShowBorder
        renderItems={items.map(({ value, denominations }) => {
          return {
            value,
            component: (
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: 4 }}>
                {displayMoney(
                  denominations,
                  displayMode === 'digital' ? (selectableHasTwoNotes ? 50 : 65) : 100,
                  displayMode === 'digital' ? (selectableHasTwoNotes ? 50 : 65) : 100,
                  true
                )}
              </View>
            )
          };
        })}
        numItems={4}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'biz',
  description: 'biz',
  keywords: ['Coins', 'Notes', 'Pounds', 'Pence', 'Money', 'Subtract', 'Change'],
  schema: z
    .object({
      moneyObject: z.object({
        value: numberEnum([200, 500, 1000, 2000]),
        denominations: z.string().array()
      }),
      characterName: ks1NameSchema,
      amountSpent: z.number().int().min(10).max(1890)
    })
    .refine(val => val.amountSpent < val.moneyObject.value)
    .refine(val => val.moneyObject.value - val.amountSpent >= 110),
  simpleGenerator: () => {
    const value = getRandomFromArray([200, 500, 1000, 2000] as const);
    const moneyObject = { value, denominations: totalPenceToPoundsAndPence(value) };

    const amountSpent = randomIntegerInclusiveStep(10, moneyObject.value - 110, 10, {
      constraint: x => x % 100 !== 0
    });

    const characterName = getRandomKs1Name();
    return { moneyObject, characterName, amountSpent };
  },
  Component: ({ question, translate, displayMode }) => {
    const { moneyObject, characterName, amountSpent } = question;

    const heOrShe =
      getGenderFromKs1Name(characterName) === 'male'
        ? translate.pronouns.maleObject()
        : translate.pronouns.femaleObject();

    const answerPoundsAndPence = penceToPoundsAndPence(moneyObject.value - amountSpent);

    const spentPoundsAndPence = penceToPoundsAndPence(amountSpent);

    const sentence = translate.ks1AnswerSentences.poundAnsAndAnsPence();

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.charHasThisMoney(characterName)}
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[
          answerPoundsAndPence.pounds.toString(),
          answerPoundsAndPence.pence.toString()
        ]}
        inputMaxCharacters={2}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View
              style={{
                flexDirection: 'row',
                columnGap: 16,
                alignItems: 'center'
              }}
            >
              {displayMoney(
                moneyObject.denominations,
                displayMode === 'digital' ? 100 : 130,
                displayMode === 'digital' ? 100 : 130
              )}
            </View>
            <TextStructure
              sentence={
                spentPoundsAndPence.pounds === 0
                  ? translate.ks1Instructions.xSpendsYP(heOrShe, spentPoundsAndPence.pence)
                  : translate.ks1Instructions.xSpendsPoundsYAndZP(
                      heOrShe,
                      spentPoundsAndPence.pounds,
                      spentPoundsAndPence.pence
                    )
              }
            />
            <TextStructure sentence={translate.ks1AnswerSentences.howMuchChangeDoesXGet(heOrShe)} />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindChange',
  questionTypes: [Question1, Question2v2, Question3],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
