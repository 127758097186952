import { z } from 'zod';
import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import { all, create, number } from 'mathjs';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { Dimens } from '../../../../theme/scaling';
import { countRange, filledArray, range } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import { ADD } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aUI',
  description: 'aUI',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Place value chart'
  ],
  schema: z
    .object({
      tenths: z.number().int().min(0).max(9),
      hundredths: z.number().int().min(0).max(9),
      thousandths: z.number().int().min(0).max(9)
    })
    .refine(
      val => val.tenths + val.hundredths + val.thousandths >= 0.01,
      'number should be greater than 0.01'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const [tenths, hundredths, thousandths] = countRange(3).map(() =>
          randomIntegerInclusive(0, 9)
        );

        return { tenths, hundredths, thousandths };
      },
      val => val.tenths + val.hundredths + val.thousandths >= 0.01
    ),
  Component: ({ question: { tenths, hundredths, thousandths }, translate }) => {
    const answer = number(math.evaluate(`${tenths}/10 + ${hundredths}/100 + ${thousandths}/1000`));
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(answer)}
            columnsToShow={[0, -1, -2, -3]}
            counterVariant={'greyCounter'}
            dimens={dimens}
          />
        )}
        sentence={translate.answerSentences.theNumberIsAns()}
        inputMaxCharacters={5}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
        extraSymbol="decimalPoint"
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aUJ',
  description: 'aUJ',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Place value chart'
  ],
  schema: z
    .object({
      tenths: z.number().int().min(0).max(9),
      hundredths: z.number().int().min(0).max(9),
      thousandths: z.number().int().min(0).max(9)
    })
    .refine(
      val => val.tenths + val.hundredths + val.thousandths >= 0.01,
      'number should be greater than 0.01'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const [tenths, hundredths, thousandths] = countRange(3).map(() =>
          randomIntegerInclusive(0, 9)
        );

        return { tenths, hundredths, thousandths };
      },
      val => val.tenths + val.hundredths + val.thousandths >= 0.01
    ),
  Component: props => {
    const {
      question: { tenths, hundredths, thousandths },
      translate
    } = props;

    const answer = number(math.evaluate(`${tenths}/10 + ${hundredths}/100 + ${thousandths}/1000`));

    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragCountersToMakeNumOnPVC(answer.toLocaleString())}
        pdfTitle={translate.instructions.drawCountersToMakeNumOnPVC(answer.toLocaleString())}
        number={ScientificNotation.fromNumber(answer)}
        columnsToShow={[0, -1, -2, -3]}
        counterVariant="greyCounter"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aUK',
  description: 'aUK',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Partition',
    'Addition',
    'Commutative'
  ],
  schema: z.object({
    tenths: z.number().int().min(1).max(9),
    hundredths: z.number().int().min(1).max(9),
    thousandths: z.number().int().min(1).max(9),
    answerBoxIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(1, 9);
    const hundredths = randomIntegerInclusive(1, 9);
    const thousandths = randomIntegerInclusive(1, 9);
    const answerBoxIndex = randomIntegerInclusive(0, 3);
    return { tenths, hundredths, thousandths, answerBoxIndex };
  },

  Component: ({ question: { tenths, hundredths, thousandths, answerBoxIndex }, translate }) => {
    const number1 = number(math.evaluate(`${tenths}/10 + ${hundredths}/100 + ${thousandths}/1000`));

    const getPlaceValueCounterImages = (
      tenths: number,
      hundredths: number,
      thousandths: number,
      dimens: Dimens
    ) => {
      return (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: dimens.width / 3,
              flexWrap: 'wrap',
              justifyContent: 'flex-start'
            }}
          >
            {range(0, tenths - 1).map(i => (
              <AssetSvg key={i} name="Place_value/0.1" width={60} />
            ))}
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: dimens.width / 3,
              flexWrap: 'wrap',
              justifyContent: 'flex-start'
            }}
          >
            {range(0, hundredths - 1).map(i => (
              <AssetSvg key={i} name="Place_value/0.01" width={60} />
            ))}
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: dimens.width / 3,
              flexWrap: 'wrap',
              justifyContent: 'flex-start'
            }}
          >
            {range(0, thousandths - 1).map(i => (
              <AssetSvg key={i} name="Place_value/0.001" width={60} />
            ))}
          </View>
        </View>
      );
    };

    const sentence =
      answerBoxIndex === 0
        ? `${number1.toLocaleString()} = <ans/> ${ADD} ${(
            hundredths / 100
          ).toLocaleString()} ${ADD} ${(thousandths / 1000).toLocaleString()}`
        : answerBoxIndex === 1
        ? `${number1.toLocaleString()} = ${(tenths / 10).toLocaleString()} ${ADD} <ans/> ${ADD} ${(
            thousandths / 1000
          ).toLocaleString()}`
        : `${number1.toLocaleString()} = ${(tenths / 10).toLocaleString()} ${ADD} ${(
            hundredths / 100
          ).toLocaleString()} ${ADD} <ans/> `;

    const answers = [tenths, hundredths / 100, thousandths / 1000];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.partitionNumberUsePVCCounters()}
        Content={({ dimens }) =>
          getPlaceValueCounterImages(tenths, hundredths, thousandths, dimens)
        }
        sentence={sentence}
        inputMaxCharacters={5}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answers[answerBoxIndex])}
        extraSymbol="decimalPoint"
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aUK2',
  description: 'aUK',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Partition',
    'Addition',
    'Commutative'
  ],
  schema: z.object({
    tenths: z.number().int().min(1).max(9),
    hundredths: z.number().int().min(1).max(9),
    thousandths: z.number().int().min(1).max(9),
    answerBoxIndex: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(1, 9);
    const hundredths = randomIntegerInclusive(1, 9);
    const thousandths = randomIntegerInclusive(1, 9);
    const answerBoxIndex = randomIntegerInclusive(0, 2);
    return { tenths, hundredths, thousandths, answerBoxIndex };
  },

  Component: ({ question: { tenths, hundredths, thousandths, answerBoxIndex }, translate }) => {
    const number1 = number(math.evaluate(`${tenths}/10 + ${hundredths}/100 + ${thousandths}/1000`));

    const getPlaceValueCounterImages = (
      tenths: number,
      hundredths: number,
      thousandths: number,
      dimens: Dimens
    ) => {
      return (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start'
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: dimens.width / 3,
              flexWrap: 'wrap',
              justifyContent: 'flex-start'
            }}
          >
            {range(0, tenths - 1).map(i => (
              <AssetSvg key={i} name="Place_value/0.1" width={60} />
            ))}
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: dimens.width / 3,
              flexWrap: 'wrap',
              justifyContent: 'flex-start'
            }}
          >
            {range(0, hundredths - 1).map(i => (
              <AssetSvg key={i} name="Place_value/0.01" width={60} />
            ))}
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: dimens.width / 3,
              flexWrap: 'wrap',
              justifyContent: 'flex-start'
            }}
          >
            {range(0, thousandths - 1).map(i => (
              <AssetSvg key={i} name="Place_value/0.001" width={60} />
            ))}
          </View>
        </View>
      );
    };

    const sentence =
      answerBoxIndex === 0
        ? `${number1.toLocaleString()} = <ans/> ${ADD} ${(
            hundredths / 100
          ).toLocaleString()} ${ADD} ${(thousandths / 1000).toLocaleString()}`
        : answerBoxIndex === 1
        ? `${number1.toLocaleString()} = ${(tenths / 10).toLocaleString()} ${ADD} <ans/> ${ADD} ${(
            thousandths / 1000
          ).toLocaleString()}`
        : `${number1.toLocaleString()} = ${(tenths / 10).toLocaleString()} ${ADD} ${(
            hundredths / 100
          ).toLocaleString()} ${ADD} <ans/> `;

    const answers = [tenths / 10, hundredths / 100, thousandths / 1000];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.partitionNumberUsePVCCounters()}
        Content={({ dimens }) =>
          getPlaceValueCounterImages(tenths, hundredths, thousandths, dimens)
        }
        sentence={sentence}
        inputMaxCharacters={5}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answers[answerBoxIndex])}
        customMarkSchemeAnswer={{ answersToDisplay: [answers[answerBoxIndex].toLocaleString()] }}
        extraSymbol="decimalPoint"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aUL',
  description: 'aUL',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Partition',
    'Addition'
  ],
  schema: z.object({
    number1: z.array(z.number().int().min(1).max(9)).length(3),
    number2: z.array(z.number().int().min(1).max(9)).length(3),
    number3: z.array(z.number().int().min(1).max(9)).length(3),
    answerBoxB: z.number().int().min(0).max(2)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomUniqueIntegersInclusive(1, 9, 3);
    const number2 = randomUniqueIntegersInclusive(1, 9, 3);
    const number3 = randomUniqueIntegersInclusive(1, 9, 3);

    const answerBoxB = randomIntegerInclusive(0, 2);

    return {
      number1,
      number2,
      number3,
      answerBoxB
    };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, answerBoxB },
      translate
    } = props;

    const eqA = `${(number1[0] / 10).toLocaleString()} ${ADD} ${(
      number2[0] / 100
    ).toLocaleString()} ${ADD} ${(number3[0] / 1000).toLocaleString()} = <ans/>`;
    const answer1 = number(
      math.evaluate(`${number1[0]}/10 + ${number2[0]}/100 + ${number3[0]}/1000`)
    );

    const display2B = answerBoxB === 0 ? `<ans/>` : (number2[1] / 100).toLocaleString();
    const display3B = answerBoxB === 1 ? `<ans/>` : (number3[1] / 1000).toLocaleString();
    const totalB = number(
      math.evaluate(`${number1[1]}/10 + ${number2[1]}/100 + ${number3[1]}/1000`)
    );
    const displayTotalB = answerBoxB === 2 ? `<ans/>` : totalB.toLocaleString();
    const eqB = `${(
      number1[1] / 10
    ).toLocaleString()} ${ADD} ${display2B} ${ADD} ${display3B} = ${displayTotalB}`;
    const answer2 = [number2[1] / 100, number3[1] / 1000, totalB][answerBoxB];

    const options = shuffle(
      [
        (number1[2] / 10).toLocaleString(),
        (number2[2] / 100).toLocaleString(),
        (number3[2] / 1000).toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    const eqC = `${options[0]} ${ADD} ${options[1]} ${ADD} ${options[2]} = <ans/>`;
    const answer3 = number(
      math.evaluate(`${number1[2]}/10 + ${number2[2]}/100 + ${number3[2]}/1000`)
    );

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) &&
          compareFloats(userAnswer[1][0], answer2) &&
          compareFloats(userAnswer[2][0], answer3)
        }
        sentences={eqs}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer1.toLocaleString()],
            [answer2.toLocaleString()],
            [answer3.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aUL2',
  description: 'aUL2',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Partition',
    'Addition'
  ],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(9)).length(3),
    divisionArray: z.array(z.number().int().multipleOf(10)).length(3),
    reversed: z.boolean(),
    answerIdx: numberEnum([0, 1, 2, 3])
  }),
  simpleGenerator: () => {
    const numbers = countRange(3).map(() => randomIntegerInclusive(1, 9));
    const divisionArray = shuffle([10, 100, 1000]);

    const reversed = getRandomBoolean();
    const answerIdx = getRandomFromArray([0, 1, 2, 3] as const);

    return {
      numbers,
      divisionArray,
      reversed,
      answerIdx
    };
  },

  Component: props => {
    const {
      question: { numbers, divisionArray, reversed, answerIdx },
      translate
    } = props;

    const [numA, numB, numC] = numbers.map((num, i) => num / divisionArray[i]);
    const total = number(math.evaluate(`${numA} + ${numB} + ${numC}`));

    const { sentence, answer } = buildSimpleNumberSentence(
      [numA, numB, numC, total],
      ADD,
      answerIdx,
      { reversed }
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeNumberSentence()}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aUM',
  description: 'aUM',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Number line'
  ],
  schema: z.object({
    number1: numberEnum([1, 2, 5, 0.1, 0.2, 0.5, 0.01, 0.02, 0.05]),
    answerBoxIndexes: z.array(z.number().int().min(1).max(8)).length(2)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([1, 2, 5, 0.1, 0.2, 0.5, 0.01, 0.02, 0.05] as const);
    const answerBoxIndexA = randomIntegerInclusive(1, 8);
    // For spacing make sure that there is at least one tick between the answer boxes
    const answerBoxIndexB = randomIntegerInclusive(1, 8, {
      constraint: x => ![answerBoxIndexA - 1, answerBoxIndexA, answerBoxIndexA + 1].includes(x)
    });

    const answerBoxIndexes = [answerBoxIndexA, answerBoxIndexB];

    return { number1, answerBoxIndexes };
  },
  Component: ({ question: { number1, answerBoxIndexes }, translate }) => {
    const tickValues = filledArray('', 11);
    tickValues[0] = (0).toLocaleString();
    tickValues[10] = number1.toLocaleString();

    const answers = range(0, number1, number1 / 10).filter((_val, i) =>
      answerBoxIndexes.includes(i)
    );

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatDecimalNumbers()}
        tickValues={tickValues}
        freeNumberLineAnswer={answers}
        firstNumber={0}
        lastNumber={number1}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], answers[0]) && compareFloats(userAnswer[1], answers[1])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [answers[0].toLocaleString(), answers[1].toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aUN',
  description: 'aUN',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Place value',
    'Partition',
    'Flexible partition',
    'Addition'
  ],
  schema: z.object({
    number1Tenths: z.number().int().min(3).max(9),
    number1Hundredths: z.number().int().min(1).max(9),
    number1Thousandths: z.number().int().min(1).max(9),
    number5: z.number().min(0.1).max(0.8).step(0.1),
    number6: z.number().min(0.001).max(0.009).step(0.001),
    number8: z.number().min(0.1).max(0.8).step(0.1),
    number9: z.number().min(0.001).max(0.019).step(0.001),
    number10: z.number().min(0.1).max(0.8).step(0.001)
  }),
  simpleGenerator: () => {
    const number1Tenths = randomIntegerInclusive(3, 9);
    const [number1Hundredths, number1Thousandths] = countRange(2).map(() =>
      randomIntegerInclusive(1, 9)
    );

    const [number5Tenths, number8Tenths] = randomUniqueIntegersInclusive(1, number1Tenths - 1, 2);
    const number5 = number5Tenths / 10;
    const number6Hundredths = randomIntegerInclusive(1, 9);
    const number6 = number6Hundredths / 1000;
    const number8 = number8Tenths / 10;
    const number9 =
      randomIntegerInclusive(1, 19, { constraint: x => x !== number6Hundredths }) / 1000;
    const number10 =
      randomIntegerInclusive(100, (number1Tenths - 1) * 100, {
        constraint: x => x !== number5Tenths * 100 && x !== number8Tenths * 100
      }) / 1000;

    return {
      number1Tenths,
      number1Hundredths,
      number1Thousandths,
      number5,
      number6,
      number8,
      number9,
      number10
    };
  },
  Component: props => {
    const {
      question: {
        number1Tenths,
        number1Hundredths,
        number1Thousandths,
        number5,
        number6,
        number8,
        number9,
        number10
      },
      translate,
      displayMode
    } = props;

    const number1 = number(
      math.evaluate(
        `${number1Tenths} / 10 + ${number1Hundredths} / 100 + ${number1Thousandths} / 1000`
      )
    );

    const number7 = number(math.evaluate(`${number1} - ${number5} - ${number6}`));
    const answerOptions = shuffle(
      [
        number5.toLocaleString(),
        number6.toLocaleString(),
        number7.toLocaleString(),
        number8.toLocaleString(),
        number9.toLocaleString(),
        number10.toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsFlexiblyPartitionX(number1.toLocaleString())}
        pdfTitle={translate.instructions.useCardsFlexiblyPartitionX(number1.toLocaleString())}
        items={answerOptions}
        sentenceStyle={{ gap: 2 }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        sentence={`${number1.toLocaleString()} = <ans/> ${ADD} <ans/>  ${ADD} <ans/>`}
        testCorrect={userAnswer =>
          compareFloats(
            number(math.evaluate(`${userAnswer[0]} + ${userAnswer[1]} + ${userAnswer[2]}`)),
            number1
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [number5.toLocaleString(), number7.toLocaleString(), number6.toLocaleString()]
          ],
          answerText: translate.markScheme.anyValidPartitionWithAvailCards()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PlaceValueWithin1',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question3, Question4]
});
export default SmallStep;
