import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  ScientificNotation,
  compareFloats,
  lessThanGreaterThanOrEqualTo
} from '../../../../utils/math';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import { DIV } from '../../../../constants';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aOq',
  description: 'aOq',
  keywords: [
    'Hundredths',
    'Tenths',
    'Ones',
    'Divide by 100',
    'Place value chart',
    '1-digit',
    'Decimals'
  ],
  schema: z.object({
    number: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const random = Math.random() < 0.5;
    const number = random ? randomIntegerInclusive(1, 9) : randomIntegerInclusive(10, 99);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    // Answer
    const answer = number / 100;

    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragTheCountersToShowTheAnswer(
          `${number.toLocaleString()} ${DIV} ${(100).toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersToShowAnswer(
          `${number.toLocaleString()} ${DIV} ${(100).toLocaleString()}`
        )}
        initialState={displayMode === 'digital' ? number : undefined}
        number={ScientificNotation.fromNumber(answer)}
        columnsToShow={[1, 0, -1, -2]}
        counterVariant="greyCounter"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aOr',
  description: 'aOr',
  keywords: ['Divide by 100', '1-digit', '2-digit', 'Decimals'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number3: z.number().int().min(10).max(90).multipleOf(10),
    number5: z.number().int().min(1).max(90)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number5 =
      Math.random() < 0.5 ? randomIntegerInclusive(1, 9) : randomIntegerInclusiveStep(10, 90, 10);
    const number1 = randomIntegerInclusive(1, 9, {
      constraint: x => x !== number5
    });
    const number3 = randomIntegerInclusiveStep(10, 90, 10, {
      constraint: x => x !== number5
    });

    return { number1, number3, number5 };
  },

  Component: props => {
    const {
      question: { number1, number3, number5 },
      translate
    } = props;

    // Sentences
    const sentences = [
      `${number1} ${DIV} 100 = <ans/>`,
      `${number3} ${DIV} 100 = <ans/>`,
      `<ans/> = ${number5} ${DIV} 100`
    ];

    // Answers
    const ans1 = number1 / 100;
    const ans2 = number3 / 100;
    const ans3 = number5 / 100;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) &&
          compareFloats(userAnswer[1][0], ans2) &&
          compareFloats(userAnswer[2][0], ans3)
        }
        sentences={sentences}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ans1.toLocaleString()],
            [ans2.toLocaleString()],
            [ans3.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aOr2',
  description: 'aOr',
  keywords: ['Divide by 100', '1-digit', '2-digit', 'Decimals'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number3: z.number().int().min(10).max(90).multipleOf(10)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const number3 = randomIntegerInclusiveStep(10, 90, 10);

    return { number1, number3 };
  },
  Component: props => {
    const {
      question: { number1, number3 },
      translate
    } = props;

    const sentences = [`${number1} ${DIV} 100 = <ans/>`, `${number3} ${DIV} 100 = <ans/>`];

    const ans1 = number1 / 100;
    const ans2 = number3 / 100;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) && compareFloats(userAnswer[1][0], ans2)
        }
        sentences={sentences}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[ans1.toLocaleString()], [ans2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aOs',
  description: 'aOs',
  keywords: ['Gattegno', 'Divide by 100', '1-digit', '2-digit', 'Decimals'],
  schema: z.object({
    number: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const sci = ScientificNotation.fromNumber(number);

    const tens = sci.digitAt('tens') * 10;
    const ones = sci.digitAt('ones');

    return (
      <QF12CreateGattegnoChart
        correctAnswer={number / 100}
        rowsToShow={[1, 0, -1, -2]}
        title={translate.instructions.useGattegnotoShowAnswer(
          `${number.toLocaleString()} ${DIV} ${(100).toLocaleString()}`
        )}
        preshaded={[tens, ones]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aOt',
  description: 'aOt',
  keywords: ['Divide by 100', '2-digit', 'Decimals'],
  schema: z.object({
    number1: z.number().int().min(11).max(99),
    number3: z.number().int().min(11).max(99),
    number5: z.number().int().min(11).max(99)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number3, number5] = randomUniqueIntegersInclusive(11, 99, 3, {
      constraint: x => x % 10 !== 0
    });

    return { number1, number3, number5 };
  },

  Component: props => {
    const {
      question: { number1, number3, number5 },
      translate
    } = props;

    // Sentences
    const sentences = [
      `${number1} ${DIV} 100 = <ans/>`,
      `${number3} ${DIV} 100 = <ans/>`,
      `<ans/> = ${number5} ${DIV} 100`
    ];

    // Answers
    const ans1 = number1 / 100;
    const ans2 = number3 / 100;
    const ans3 = number5 / 100;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) &&
          compareFloats(userAnswer[1][0], ans2) &&
          compareFloats(userAnswer[2][0], ans3)
        }
        sentences={sentences}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ans1.toLocaleString()],
            [ans2.toLocaleString()],
            [ans3.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aOt2',
  description: 'aOt',
  keywords: ['Divide by 100', '2-digit', 'Decimals'],
  schema: z.object({
    number1: z.number().int().min(11).max(99),
    number3: z.number().int().min(11).max(99)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number3] = randomUniqueIntegersInclusive(11, 99, 2, {
      constraint: x => x % 10 !== 0
    });

    return { number1, number3 };
  },

  Component: props => {
    const {
      question: { number1, number3 },
      translate
    } = props;

    const sentences = [`${number1} ${DIV} 100 = <ans/>`, `${number3} ${DIV} 100 = <ans/>`];

    const ans1 = number1 / 100;
    const ans2 = number3 / 100;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) && compareFloats(userAnswer[1][0], ans2)
        }
        sentences={sentences}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[ans1.toLocaleString()], [ans2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aOu',
  description: 'aOu',
  keywords: ['Divide by 100', '1-digit', '2-digit', 'Decimals'],
  schema: z.object({
    number1: z.number().int().min(11).max(99),
    number3: z.number().int().min(1).max(9),
    number5: z.number().int().min(11).max(99)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0
    });

    const number3 = randomIntegerInclusive(1, 9);

    const number5 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0 && x !== number1
    });

    return { number1, number3, number5 };
  },

  Component: props => {
    const {
      question: { number1, number3, number5 },
      translate
    } = props;

    // Sentences
    const sentences = [
      `<ans/> = ${number1} ${DIV} 100`,
      `<ans/> = ${number3} ${DIV} 100`,
      `${number5} ${DIV} 100 = <ans/>`
    ];

    // Answers
    const ans1 = number1 / 100;
    const ans2 = number3 / 100;
    const ans3 = number5 / 100;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) &&
          compareFloats(userAnswer[1][0], ans2) &&
          compareFloats(userAnswer[2][0], ans3)
        }
        sentences={sentences}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ans1.toLocaleString()],
            [ans2.toLocaleString()],
            [ans3.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aOv',
  description: 'aOv',
  keywords: ['Divide by 100', '1-digit', '2-digit', 'Decimals'],
  schema: z.object({
    number1: z.number().int().min(11).max(99),
    number2: z.number().int().min(11).max(99),
    number3: z.number().int().min(11).max(99),
    number4: z.number().int().min(11).max(99),
    number5: z.number().int().min(1).max(9),
    number6: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(11, 99, 2);
    const number3 = randomIntegerInclusive(11, 99);
    const number4 = randomIntegerInclusive(11, 99);
    const number5 = randomIntegerInclusive(1, 9);
    const number6 = randomIntegerInclusive(11, 99);

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    // Sums
    const sum1A = number1 / 100;
    const sum1B = number2 / 100;

    const sum2A = number3 / 100;
    const sum2B = number4 / 10;

    const sum3A = number5 / 10;
    const sum3B = number6 / 100;

    // Statements
    const statements = shuffle(
      [
        {
          sentence: `${number1.toLocaleString()} ${DIV} 100 <ans/> ${number2.toLocaleString()} ${DIV} 100`,
          answer: lessThanGreaterThanOrEqualTo(sum1A, sum1B)
        },
        {
          sentence: `${number3.toLocaleString()} ${DIV} 100 <ans/> ${number4.toLocaleString()} ${DIV} 10`,
          answer: lessThanGreaterThanOrEqualTo(sum2A, sum2B)
        },
        {
          sentence: `${number5.toLocaleString()} ${DIV} 10 <ans/> ${number6.toLocaleString()} ${DIV} 100`,
          answer: lessThanGreaterThanOrEqualTo(sum3A, sum3B)
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useInequalitiesToCompleteStatementsEachSymbolCanBeUsedMoreThanOnce()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={statements.map(statement => statement.sentence)}
        testCorrect={statements.map(statement => [statement.answer])}
        actionPanelVariant="end"
        moveOrCopy="copy"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6v2 = newQuestionContent({
  uid: 'aOv2',
  description: 'aOv',
  keywords: ['Divide by 100', '1-digit', '2-digit', 'Decimals'],
  schema: z.discriminatedUnion('sentenceId', [
    z.object({
      sentenceId: z.literal(0),
      numberA: z.number().int().min(11).max(99),
      numberB: z.number().int().min(11).max(99)
    }),
    z.object({
      sentenceId: z.literal(1),
      numberA: z.number().int().min(11).max(99),
      numberB: z.number().int().min(11).max(99)
    }),
    z.object({
      sentenceId: z.literal(2),
      numberA: z.number().int().min(1).max(9),
      numberB: z.number().int().min(11).max(99)
    })
  ]),
  simpleGenerator: () => {
    const sentenceId = getRandomFromArray([0, 1, 2] as const);

    const [numberA, numberB] = (() => {
      switch (sentenceId) {
        case 0:
        case 1:
          return [randomIntegerInclusive(11, 99), randomIntegerInclusive(11, 99)];
        case 2:
          return [randomIntegerInclusive(1, 9), randomIntegerInclusive(11, 99)];
      }
    })();

    return { sentenceId, numberA, numberB };
  },
  Component: props => {
    const {
      question: { sentenceId, numberA, numberB },
      translate
    } = props;

    const statement =
      sentenceId === 0
        ? {
            sentence: `${numberA.toLocaleString()} ${DIV} 100 <ans/> ${numberB.toLocaleString()} ${DIV} 100`,
            answer: lessThanGreaterThanOrEqualTo(numberA / 100, numberB / 100)
          }
        : sentenceId === 1
        ? {
            sentence: `${numberA.toLocaleString()} ${DIV} 100 <ans/> ${numberB.toLocaleString()} ${DIV} 10`,
            answer: lessThanGreaterThanOrEqualTo(numberA / 100, numberB / 10)
          }
        : {
            sentence: `${numberA.toLocaleString()} ${DIV} 10 <ans/> ${numberB.toLocaleString()} ${DIV} 100`,
            answer: lessThanGreaterThanOrEqualTo(numberA / 10, numberB / 100)
          };

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardCompleteStatement()}
        pdfTitle={translate.instructions.useInequalitiesToCompleteStatement()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentence={statement.sentence}
        testCorrect={[statement.answer]}
        actionPanelVariant="end"
        moveOrCopy="move"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA1Or2DigitNumberBy100',
  questionTypes: [Question1, Question2v2, Question3, Question4v2, Question5, Question6v2],
  archivedQuestionTypes: [Question2, Question4, Question6]
});
export default SmallStep;
