import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { integerToWord, numberOfZeroDigits, ScientificNotation } from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import AutoScaleText from '../../../../components/typography/AutoScaleText';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { arrayHasNoDuplicates } from '../../../../utils/collections';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'ady',
  description: 'ady',
  keywords: ['Place value', 'Words', '1,000,000', 'Million', 'Order'],
  schema: z
    .object({
      number: z.number().min(100000).max(1000000)
    })
    .refine(val => numberOfZeroDigits(val.number) === 0, 'number must not contain any 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(111111, 999999, {
      constraint: x => {
        // Reject any number that has 0 as a digit
        return numberOfZeroDigits(x) === 0;
      }
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const correctOrder = Array.from(number.toString());
    const digits = shuffle(correctOrder, { random: seededRandom(props.question) });

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragDigitCardsToWriteX(integerToWord(number))}
        pdfTitle={translate.instructions.useDigitCardsToWriteX(integerToWord(number))}
        sentence={'<ans/> '.repeat(correctOrder.length)}
        items={digits}
        testCorrect={correctOrder}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'adz',
  description: 'adz',
  keywords: ['Place value', '1,000,000', 'Million', 'Words'],
  schema: z
    .object({
      number: z.number().min(100000).max(1000000)
    })
    .refine(val => numberOfZeroDigits(val.number) === 0, 'number must not contain any 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(111111, 999999, {
      constraint: x => {
        // Reject any number that has 0 as a digit
        return numberOfZeroDigits(x) === 0;
      }
    });
    return { number };
  },
  Component: ({ question: { number }, translate }) => {
    const numberString = integerToWord(number);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={''}
        testCorrect={[number.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AutoScaleText
            variant="WRN400"
            containerStyle={{ height: 200, width: dimens.width }}
            maxFontSize={50}
            minFontSize={32}
          >
            {translate.instructions.writeNumWordsInNum(numberString)}
          </AutoScaleText>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'adA',
  description: 'adA',
  keywords: ['Place value', '1,000,000', 'Million', 'Words'],
  schema: z
    .object({
      number: z.number().min(100000).max(1000000),
      wrongNumbers: z.array(z.number().min(100000).max(1000000)).length(3),
      correctPosition: z.number().int().min(0).max(3)
    })
    .refine(val => numberOfZeroDigits(val.number) > 0, 'number must contain some 0s'),
  simpleGenerator: () => {
    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);

    const number = randomIntegerInclusive(100000, 1000000, {
      constraint: x => {
        // There must be at least 2 unique numbers after the first one
        const numArray = Array.from(x.toString());
        const numUnique = new Set(numArray.slice(1, numArray.length)).size;
        // Number must contain some 0's
        return numberOfZeroDigits(x) > 0 && numUnique >= 2;
      }
    });

    const wrongNumbers = new Set<number>();
    const numArray = Array.from(number.toString());
    while (wrongNumbers.size < 3) {
      // Shuffle digits except the first one
      const shuffledNumberArray = [numArray[0], ...shuffle(numArray.slice(1, numArray.length))];
      if (number !== Number(shuffledNumberArray.join(''))) {
        // Reform the integer and push to wrong numbers
        wrongNumbers.add(Number(shuffledNumberArray.join('')));
      }
    }

    return { number, wrongNumbers: Array.from(wrongNumbers), correctPosition };
  },
  Component: props => {
    const {
      question: { number, wrongNumbers, correctPosition },
      translate,
      displayMode
    } = props;
    const allAnswers = [
      ...wrongNumbers.slice(0, correctPosition),
      number,
      ...wrongNumbers.slice(correctPosition)
    ];

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectThePlaceValueChartThatShowsX(integerToWord(number))}
        pdfTitle={`${translate.instructions.selectThePlaceValueChartThatShowsX(
          integerToWord(number)
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={[number]}
        numItems={allAnswers.length as 2 | 3 | 4}
        renderItems={({ dimens }) => {
          return allAnswers.map(number => ({
            value: number,
            component: (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(number)}
                columnsToShow={[5, 4, 3, 2, 1, 0]}
                dimens={{ height: dimens.height * 0.9, width: dimens.width * 0.9 }}
                counterVariant={'greyCounter'}
                counterSize={displayMode === 'digital' ? undefined : 55}
                headerVariant={'shortName'}
              />
            )
          }));
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'adB',
  description: 'adB',
  keywords: ['Place value', '1,000,000', 'Million', 'Words'],
  schema: z
    .object({
      number: z.number().min(100000).max(1000000),
      wrongNumbers: z.array(z.number().min(100000).max(1000000)).length(3),
      correctPosition: z.number().int().min(0).max(3)
    })
    .refine(val => numberOfZeroDigits(val.number) > 0, 'number must contain some 0s')
    .refine(
      val => arrayHasNoDuplicates([val.number, ...val.wrongNumbers]),
      'there must be no duplicate numbers'
    ),
  simpleGenerator: () => {
    const { number, wrongNumbersArray } = rejectionSample(
      () => {
        const number = randomIntegerInclusive(100000, 1000000, {
          constraint: x => {
            // There must be at least 2 unique numbers after the first one
            const numArray = Array.from(x.toString());
            const numUnique = new Set(numArray.slice(1)).size;
            // Number must contain some 0's
            return numberOfZeroDigits(x) > 0 && numUnique >= 2;
          }
        });

        const wrongNumbers = new Set<number>();
        const numArray = Array.from(number.toString());
        while (wrongNumbers.size < 3) {
          // Shuffle digits except the first one
          const shuffledNumberArray = [numArray[0], ...shuffle(numArray.slice(1))];
          // Reform the integer and push to wrong numbers
          wrongNumbers.add(Number(shuffledNumberArray.join('')));
        }

        const wrongNumbersArray = Array.from(wrongNumbers);

        return { number, wrongNumbersArray };
      },
      // Only permit them if there are no duplicate numbers.
      ({ number, wrongNumbersArray }) => arrayHasNoDuplicates([number, ...wrongNumbersArray])
    );

    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);
    return { number, wrongNumbers: wrongNumbersArray, correctPosition };
  },
  Component: props => {
    const {
      question: { number, wrongNumbers, correctPosition },
      translate
    } = props;

    const allAnswers = [
      ...wrongNumbers.slice(0, correctPosition),
      number,
      ...wrongNumbers.slice(correctPosition)
    ];

    let longestLength = 0;
    const sentences: string[] = [];
    allAnswers.forEach((answer, index) => {
      sentences.push(integerToWord(answer));
      longestLength = Math.max(longestLength, sentences[index].length);
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectCardThatShowsXWrittenInWords(number)}
        pdfTitle={translate.instructions.circleCardThatShowsXWrittenInWords(number)}
        testCorrect={[number]}
        numItems={4}
        renderItems={allAnswers.map((number, index) => ({
          value: number,
          component: (
            <AutoScaleText
              longestInGroup={longestLength}
              containerStyle={{ width: '95%', height: '95%' }}
            >
              {sentences[index]}
            </AutoScaleText>
          )
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'adC',
  description: 'adC',
  keywords: ['Place value', 'Words', '1,000,000', 'Million', 'Order'],
  schema: z
    .object({
      number: z.number().min(100000).max(1000000)
    })
    .refine(val => numberOfZeroDigits(val.number) > 0, 'number must contain some 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(100000, 1000000, {
      constraint: x => {
        // Number must contain some 0's
        return numberOfZeroDigits(x) > 0;
      }
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const correctOrder = Array.from(number.toString());
    const digits = shuffle(correctOrder, { random: seededRandom(props.question) });

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragDigitCardsToWriteX(integerToWord(number))}
        pdfTitle={translate.instructions.useDigitCardsToWriteX(integerToWord(number))}
        sentence={'<ans/> '.repeat(correctOrder.length)}
        items={digits}
        testCorrect={correctOrder}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'adD',
  description: 'adD',
  keywords: ['Place value', '1,000,000', 'Million', 'Words'],
  schema: z
    .object({
      number: z.number().min(100000).max(1000000)
    })
    .refine(val => numberOfZeroDigits(val.number) > 0, 'number must contain some 0s'),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(100000, 1000000, {
      constraint: x => {
        // Number must contain some 0's
        return numberOfZeroDigits(x) > 0;
      }
    });
    return { number };
  },
  Component: ({ question: { number }, translate }) => {
    const numberString = integerToWord(number);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.answerTheQuestion()}
        testCorrect={[number.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AutoScaleText
            variant="WRN400"
            containerStyle={{ height: 200, width: dimens.width }}
            maxFontSize={50}
            minFontSize={32}
          >
            {translate.instructions.writeNumWordsInNum(numberString)}
          </AutoScaleText>
        )}
      />
    );
  }
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadAndWriteNumbersTo1000000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
