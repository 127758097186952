import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { isInRange } from '../../../../utils/matchers';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { SUB } from '../../../../constants';
import { arraysHaveSameContents } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { BarModel } from '../../../../components/question/representations/BarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbq',
  description: 'bbq',
  keywords: ['Bar model', 'Subtract', 'Part'],
  schema: z
    .object({
      partA: z.number().int().min(1).max(9),
      partB: z.number().int().min(1).max(9),
      flipBarModel: z.boolean()
    })
    .refine(({ partA, partB }) => isInRange(3, 10)(partA + partB) && partA !== partB),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(1, 9);
    const partB = randomIntegerInclusive(1, 9, {
      constraint: x => isInRange(3, 10)(partA + x) && partA !== x
    });
    const flipBarModel = getRandomBoolean();

    return { partA, partB, flipBarModel };
  },
  Component: props => {
    const {
      question: { partA, partB, flipBarModel },
      translate
    } = props;

    const total = partA + partB;

    const [topRow, bottomRow] = flipBarModel
      ? [[partA, partB], [total]]
      : [[total], [partA, partB]];

    const sentence1 = getBinOpEquation({
      left: total,
      right: partA,
      result: partB,
      sign: 'subtract',
      answer: 'result'
    });

    const sentence2 = getBinOpEquation({
      left: total,
      right: partB,
      result: partA,
      sign: 'subtract',
      answer: 'result'
    });

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheNumberSentences()}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => {
          return <BarModel dimens={dimens} numbers={[topRow, bottomRow]} total={partA + partB} />;
        }}
        sentences={[
          binOpEquationToSentenceString(sentence1),
          binOpEquationToSentenceString(sentence2)
        ]}
        testCorrect={binOpEquationsToTestCorrect([sentence1, sentence2])}
      />
    );
  }
});

const Question2 = newQuestionContent({
  questionHeight: 1200,
  uid: 'bbr',
  description: 'bbr',
  keywords: ['Part-whole model', 'Subtract'],
  schema: z
    .object({
      partA: z.number().int().min(1).max(9),
      partB: z.number().int().min(1).max(9),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft'])
    })
    .refine(({ partA, partB }) => isInRange(3, 10)(partA + partB)),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(1, 9);
    const partB = randomIntegerInclusive(1, 9, {
      constraint: x => isInRange(3, 10)(partA + x)
    });

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { partA, partB, variation };
  },
  Component: props => {
    const {
      question: { partA, partB, variation },
      translate,
      displayMode
    } = props;
    const total = partA + partB;

    const sentence = `<ans/> ${SUB} <ans/> = <ans/>`;
    const items = shuffle(
      [partA, partB, total].map(num => {
        return { value: num.toString(), component: num.toLocaleString() };
      }),
      { random: seededRandom(props.question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        questionHeight={1200}
        actionPanelVariant="end"
        title={translate.ks1Instructions.dragTheCardsToCompleteTheNumberSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheNumberSentence()}
        mainPanelStyle={{ flexDirection: displayMode !== 'digital' ? 'row' : 'column' }}
        Content={({ dimens }) => (
          <PartWholeModel
            top={total}
            variation={variation}
            partition={[partA, partB]}
            dimens={{
              width:
                variation === 'leftRight' || variation === 'rightLeft'
                  ? dimens.width * 0.7
                  : dimens.width * 0.9,
              height: dimens.height
            }}
          />
        )}
        items={items}
        pdfLayout={'itemsAboveContent'}
        sentence={sentence}
        sentencesStyle={{ alignItems: 'flex-start' }}
        testCorrect={userAnswer =>
          arraysHaveSameContents(
            [total.toString(), partA.toString(), partB.toString()],
            userAnswer
          ) ||
          arraysHaveSameContents([total.toString(), partB.toString(), partA.toString()], userAnswer)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [total.toLocaleString(), partA.toLocaleString(), partB.toLocaleString()]
          ],
          answerText: translate.markScheme.xAndYCanBeInEitherOrder(partA, partB)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbs',
  description: 'bbs',
  keywords: ['Number sentence', 'Subtract'],
  schema: z
    .object({
      num1: z.number().int().min(1).max(10),
      num2: z.number().int().min(0).max(10)
    })
    .refine(({ num1, num2 }) => num1 >= num2),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(1, 10);
    const num2 = randomIntegerInclusive(0, num1);

    return {
      num1,
      num2
    };
  },
  Component: ({ question, translate }) => {
    const { num1, num2 } = question;
    const sentence = getBinOpEquation({
      left: num1,
      right: num2,
      result: num1 - num2,
      sign: 'subtract',
      answer: 'result'
    });
    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheNumberSentence()}
        sentence={binOpEquationToSentenceString(sentence)}
        testCorrect={binOpEquationsToTestCorrect([sentence])[0]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractionFindAPart',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
