import { filledArray } from '../../../utils/collections';
import { BarModelInteractiveWithState } from '../representations/BarModelInteractive';
import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import parseMarkup from '../../../markup/parseMarkup';
import TextStructure from '../../molecules/TextStructure';
import { View } from 'react-native';
import { chunk } from '../../../utils/chunk';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { DraggableVariant } from '../../draganddrop/utils';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { MeasureView } from '../../atoms/MeasureView';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { useI18nContext } from '../../../i18n/i18n-react';
import { isEqual } from '../../../utils/matchers';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { AssetSvg } from '../../../assets/svg';

type ItemInfo<T> = { component: JSX.Element | string; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  bars: Bar[];
  /**
   * If absent, bar model is always marked as complete and correct.
   * If present, at least one cell must be shaded to be complete, and this is used to test correctness.
   * Usually only testCorrectSentence is used.
   */
  testCorrectBarModel?: number[];
  testCorrectSentence?: T[];
  sentence: string;
  items: ItemInfo<T>[];
  numberOfBarsPerRow: number;
  actionPanelVariant?: 'end' | 'endWide' | 'bottom';
  pdfItemVariant?: DraggableVariant;
  /** PDF Question Height */
  questionHeight?: number;
  rowLabels?: string[];
  /** Whether to hide draggables in the pdf and markscheme.
   * Default: false
   * */
  hideItemsPdf?: boolean;
  pdfTableHeight?: number;
};

type Bar = { idx?: number; rows: number; cols: number; label?: string };

export default function QF34aInteractiveBarModelColumnsWithSentenceDrag<T>({
  title,
  pdfTitle,
  bars,
  testCorrectBarModel,
  sentence,
  items,
  testCorrectSentence,
  numberOfBarsPerRow,
  actionPanelVariant = 'endWide',
  pdfItemVariant = 'pdfSquare',
  questionHeight,
  rowLabels,
  hideItemsPdf = false,
  pdfTableHeight,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  // Set entire bar models in a grid
  const chunks = chunk(bars, numberOfBarsPerRow);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const correctItemOrder = testCorrectSentence?.map(ans => {
      const [{ value }] = items.filter(it => it.value === ans);
      return value;
    });

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="draganddrop"
        items={items}
        moveOrCopy="copy"
        variant={pdfItemVariant}
        defaultState={displayMode === 'markscheme' ? correctItemOrder : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              <DragAndDropSectionPDF
                style={{ flex: 0.2 }}
                itemsStyle={{ flexDirection: 'row', gap: 16, flexWrap: 'wrap' }}
              >
                {!hideItemsPdf &&
                  items.map((_item, index) => (
                    <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
                  ))}
              </DragAndDropSectionPDF>
              <MeasureView>
                {dimens => (
                  <View style={{ alignItems: 'center', rowGap: 16 }}>
                    {chunks.map((bars, chunkIdx) => (
                      <View
                        key={chunkIdx}
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                          gap: 16,
                          alignItems: 'center'
                        }}
                      >
                        {rowLabels && (
                          <View key={`label_${chunkIdx}`}>
                            <TextStructure
                              style={{
                                alignSelf: 'center',
                                paddingRight: 20,
                                position: 'relative',
                                top: -7.5
                              }}
                              fractionDividerStyle={{ marginVertical: 0 }}
                              sentence={rowLabels[chunkIdx]}
                            />
                          </View>
                        )}
                        {bars.map((bar, barIdx) => (
                          <View key={barIdx} style={{ flexDirection: 'row' }}>
                            <BarModelInteractiveWithState
                              key={barIdx}
                              id={`barmodel-${barIdx}`}
                              numberOfRows={bar.rows}
                              numberOfCols={bar.cols}
                              tableHeight={
                                pdfTableHeight ?? Math.min(dimens.height / bars.length, 100)
                              }
                              // Overrides the style set in the table component so that cells fill completely
                              tableCellStyle={{ paddingLeft: 0 }}
                              tableWidth={
                                // Account for label to the left of the bar model
                                rowLabels !== undefined && rowLabels[chunkIdx] !== undefined
                                  ? dimens.width / numberOfBarsPerRow - 50
                                  : dimens.width / numberOfBarsPerRow
                              }
                            />
                          </View>
                        ))}
                      </View>
                    ))}

                    <TextStructure
                      sentence={sentence}
                      fractionDividerStyle={{ marginVertical: 0 }}
                      inputBox={({ index }) => (
                        <>
                          {displayMode === 'markscheme' && (
                            <AssetSvg
                              name="True"
                              width={50}
                              style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
                            />
                          )}
                          <EasyDragAndDropWithSingleZones.ZoneSingle key={index} id={index} />
                        </>
                      )}
                    />
                  </View>
                )}
              </MeasureView>
              {displayMode === 'markscheme' &&
                renderMarkSchemeProp(translate.markScheme.barModelsDoNotNeedShading())}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy="move"
      variant="square"
      defaultState={filledArray(undefined as T | undefined, parseMarkup(sentence).numberOfAns)}
      testComplete={state => state.every(it => it !== undefined)}
      testCorrect={isEqual(testCorrectSentence)}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={
          <DragAndDropSection>
            {items.map((_item, index) => (
              <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View style={{ alignItems: 'center', rowGap: 16 }}>
                {chunks.map((bars, chunkIdx) => (
                  <View
                    key={chunkIdx}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: 16,
                      alignItems: 'center'
                    }}
                  >
                    {rowLabels && rowLabels[chunkIdx] && (
                      <View key={`label_${chunkIdx}`}>
                        <TextStructure
                          style={{
                            alignSelf: 'center',
                            paddingRight: 20,
                            position: 'relative',
                            top: -7.5
                          }}
                          fractionDividerStyle={{ marginVertical: 0 }}
                          sentence={rowLabels[chunkIdx]}
                        />
                      </View>
                    )}
                    {bars.map((bar, barIdx) => (
                      <View key={barIdx} style={{ flexDirection: 'row' }}>
                        <BarModelInteractiveWithState
                          key={barIdx}
                          id={`chunk-${chunkIdx}-bar-${barIdx}`}
                          numberOfRows={bar.rows}
                          numberOfCols={bar.cols}
                          tableHeight={Math.min(dimens.height / bars.length, 100)}
                          // Overrides the style set in the table component so that cells fill completely
                          tableCellStyle={{ paddingLeft: 0 }}
                          tableWidth={
                            // Account for label to the left of the bar model
                            rowLabels !== undefined && rowLabels[chunkIdx] !== undefined
                              ? dimens.width / numberOfBarsPerRow - 50
                              : dimens.width / numberOfBarsPerRow
                          }
                        />
                      </View>
                    ))}
                  </View>
                ))}

                <TextStructure
                  sentence={sentence}
                  fractionDividerStyle={{ marginVertical: 0 }}
                  inputBox={({ index }) => (
                    <EasyDragAndDropWithSingleZones.ZoneSingle key={index} id={index} />
                  )}
                />
              </View>
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}
