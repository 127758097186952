import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { arrayHasNoDuplicates, countRange, sumNumberArray } from '../../../../utils/collections';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { barColorNames, barColorsNamesArray } from '../../../../theme/colors';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { petSchema, petAsWord, getRandomUniquePets } from '../../../../utils/pets';
import MultiBarChart from '../../../../components/question/representations/Coordinates/MultiBarChart';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avW',
  description: 'avW',
  keywords: ['Bar chart', 'Interpret'],
  schema: z.object({
    pets: petSchema.array().length(4).refine(arrayHasNoDuplicates, 'Must be 4 unique pets'),
    data: z
      .number()
      .int()
      .min(0)
      .max(7)
      .array()
      .length(4)
      .refine(data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }, 'Min and max must be unique'),
    lookingForMostOrLeast: z.enum(['most', 'least'])
  }),
  simpleGenerator: () => {
    const pets = getRandomUniquePets(4);

    const data = rejectionSample(
      () => countRange(4).map(() => randomIntegerInclusive(1, 7)),
      data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }
    );

    return { pets, data, lookingForMostOrLeast: getRandomFromArray(['most', 'least'] as const) };
  },
  Component: ({ question: { pets, data, lookingForMostOrLeast }, translate }) => {
    const random = seededRandom({ pets, data });
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4, { random });
    const min = Math.min(...data);
    const max = Math.max(...data);
    const leastPopular = pets.filter((_, i) => data[i] === min)[0];
    const mostPopular = pets.filter((_, i) => data[i] === max)[0];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions[
          lookingForMostOrLeast === 'most' ? 'selectTheCommonPetMost' : 'selectTheCommonPetLeast'
        ]()}
        pdfTitle={translate.instructions[
          lookingForMostOrLeast === 'most'
            ? 'selectTheCommonPetMostPdf'
            : 'selectTheCommonPetLeastPdf'
        ]()}
        selectables={Object.fromEntries(pets.map(key => [key, petAsWord(key, translate)]))}
        correctAnswer={[lookingForMostOrLeast === 'most' ? mostPopular : (leastPopular as string)]}
        leftContent={
          <MeasureView>
            {dimens => (
              <MultiBarChart
                width={dimens.width}
                height={dimens.height}
                squareGrid
                barValues={data.map((val, i) => ({ option: i, values: [val] }))}
                barLabels={pets.map(key => petAsWord(key, translate))}
                barColors={colors.map(key => barColorNames[key])}
                yMax={7}
                xAxisLabel={translate.misc.typeOfPet()}
                yAxisLabel={translate.misc.numberOfAnimals()}
                yAxisArrowLabel={null}
              />
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'avX',
  description: 'avX',
  keywords: ['Bar chart', 'Interpret'],
  schema: z.object({
    pets: petSchema.array().length(5).refine(arrayHasNoDuplicates, 'Must be 5 unique pets'),
    data: z.number().int().min(0).max(7).array().length(5),
    answerIndex: numberEnum([0, 1, 2, 3, 4])
  }),
  simpleGenerator: () => {
    const pets = getRandomUniquePets(5);
    const answerIndex = getRandomFromArray([0, 1, 2, 3, 4] as const);

    const data = countRange(5).map(i => randomIntegerInclusive(i === 2 ? 0 : 1, 7));

    return { pets, data, answerIndex };
  },
  Component: props => {
    const {
      question: { pets, data, answerIndex },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const answer = data[answerIndex].toString();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyXAreThere(
          petAsWord(pets[answerIndex], translate, true)
        )}
        testCorrect={[answer]}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1150}
        Content={({ dimens }) => (
          <MultiBarChart
            width={dimens.width}
            height={dimens.height}
            squareGrid
            barValues={data.map((val, i) => ({ option: i, values: [val] }))}
            barLabels={pets.map(key => petAsWord(key, translate))}
            barColors={colors.map(key => barColorNames[key])}
            yMax={7}
            xAxisLabel={translate.misc.typeOfPet()}
            yAxisLabel={translate.misc.numberOfAnimals()}
            yAxisArrowLabel={null}
          />
        )}
      />
    );
  },
  questionHeight: 1150
});

const Question3 = newQuestionContent({
  uid: 'avY',
  description: 'avY',
  keywords: ['Bar chart', 'Interpret'],
  schema: z
    .object({
      pets: petSchema.array().length(5).refine(arrayHasNoDuplicates, 'Must be 5 unique pets'),
      answerIndexA: numberEnum([0, 1, 2, 3, 4]),
      answerIndexB: numberEnum([0, 1, 2, 3, 4]),
      data: z.number().int().min(0).max(7).array().length(5)
    })
    .refine(
      val => val.data[val.answerIndexA] !== val.data[val.answerIndexB],
      'values of childen that have pets used in question must not equal the same'
    ),
  simpleGenerator: () => {
    const pets = getRandomUniquePets(5);
    const [answerIndexA, answerIndexB] = getRandomSubArrayFromArray([0, 1, 2, 3, 4] as const, 2);

    const data = rejectionSample(
      () => countRange(5).map(i => randomIntegerInclusive(i === 2 ? 0 : 1, 7)),
      data => {
        return data[answerIndexA] !== data[answerIndexB];
      }
    );

    return { pets, data, answerIndexA, answerIndexB };
  },
  Component: props => {
    const {
      question: { pets, data, answerIndexA, answerIndexB },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const [petA, petB] =
      data[answerIndexA] > data[answerIndexB]
        ? [
            petAsWord(pets[answerIndexA], translate, true),
            petAsWord(pets[answerIndexB], translate, true)
          ]
        : [
            petAsWord(pets[answerIndexB], translate, true),
            petAsWord(pets[answerIndexA], translate, true)
          ];

    const answer =
      data[answerIndexA] > data[answerIndexB]
        ? data[answerIndexA] - data[answerIndexB]
        : data[answerIndexB] - data[answerIndexA];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyMoreXThanYAreThere(petA, petB)}
        testCorrect={[answer.toString()]}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1150}
        Content={({ dimens }) => (
          <MultiBarChart
            width={dimens.width}
            height={dimens.height}
            squareGrid
            barValues={data.map((val, i) => ({ option: i, values: [val] }))}
            barLabels={pets.map(key => petAsWord(key, translate))}
            barColors={colors.map(key => barColorNames[key])}
            yMax={7}
            xAxisLabel={translate.misc.typeOfPet()}
            yAxisLabel={translate.misc.numberOfAnimals()}
            yAxisArrowLabel={null}
          />
        )}
      />
    );
  },
  questionHeight: 1150
});

const Question4 = newQuestionContent({
  uid: 'avZ',
  description: 'avZ',
  keywords: ['Bar chart', 'Interpret'],
  schema: z.object({
    pets: petSchema.array().length(5).refine(arrayHasNoDuplicates, 'Must be 5 unique pets'),
    data: z
      .number()
      .int()
      .min(0)
      .max(70)
      .array()
      .length(5)
      .refine(arrayHasNoDuplicates, 'Must have 5 unique values'),
    answerIndex: numberEnum([0, 1, 2, 3, 4]),
    multiplier: numberEnum([2, 5, 10])
  }),
  simpleGenerator: () => {
    const pets = getRandomUniquePets(5);
    const answerIndex = getRandomFromArray([0, 1, 2, 3, 4] as const);
    const multiplier = getRandomFromArray([2, 5, 10] as const);

    const data = randomUniqueIntegersInclusiveStep(0, 7 * multiplier, multiplier, 5);

    return { pets, data, answerIndex, multiplier };
  },
  Component: props => {
    const {
      question: { pets, data, answerIndex, multiplier },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const answer = data[answerIndex].toString();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyXAreThere(
          petAsWord(pets[answerIndex], translate, true)
        )}
        testCorrect={[answer]}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1150}
        Content={({ dimens }) => (
          <MultiBarChart
            width={dimens.width}
            height={dimens.height}
            squareGrid
            barValues={data.map((val, i) => ({ option: i, values: [val] }))}
            barLabels={pets.map(key => petAsWord(key, translate))}
            barColors={colors.map(key => barColorNames[key])}
            yMax={multiplier * 7}
            yStepSize={multiplier}
            xAxisLabel={translate.misc.typeOfPet()}
            yAxisLabel={translate.misc.numberOfAnimals()}
            yAxisArrowLabel={null}
          />
        )}
      />
    );
  },
  questionHeight: 1150
});

const Question5 = newQuestionContent({
  uid: 'av0',
  description: 'av0',
  keywords: ['Bar chart', 'Interpret'],
  schema: z.object({
    pets: petSchema.array().length(5).refine(arrayHasNoDuplicates, 'Must be 5 unique pets'),
    data: z
      .number()
      .int()
      .min(0)
      .max(70)
      .array()
      .length(5)
      .refine(arrayHasNoDuplicates, 'Must have 5 unique values'),
    answerIndexA: numberEnum([0, 1, 2, 3, 4]),
    answerIndexB: numberEnum([0, 1, 2, 3, 4]),
    multiplier: numberEnum([2, 5, 10])
  }),
  simpleGenerator: () => {
    const pets = getRandomUniquePets(5);
    const [answerIndexA, answerIndexB] = getRandomSubArrayFromArray([0, 1, 2, 3, 4] as const, 2);
    const multiplier = getRandomFromArray([2, 5, 10] as const);

    const data = randomUniqueIntegersInclusiveStep(0, 7 * multiplier, multiplier, 5);

    return { pets, data, answerIndexA, answerIndexB, multiplier };
  },
  Component: props => {
    const {
      question: { pets, data, answerIndexA, answerIndexB, multiplier },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const answer = (data[answerIndexA] + data[answerIndexB]).toString();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyXAndYAreThereAltogether(
          petAsWord(pets[answerIndexA], translate, true),
          petAsWord(pets[answerIndexB], translate, true)
        )}
        testCorrect={[answer]}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1150}
        Content={({ dimens }) => (
          <MultiBarChart
            width={dimens.width}
            height={dimens.height}
            squareGrid
            barValues={data.map((val, i) => ({ option: i, values: [val] }))}
            barLabels={pets.map(key => petAsWord(key, translate))}
            barColors={colors.map(key => barColorNames[key])}
            yMax={multiplier * 7}
            yStepSize={multiplier}
            xAxisLabel={translate.misc.typeOfPet()}
            yAxisLabel={translate.misc.numberOfAnimals()}
            yAxisArrowLabel={null}
          />
        )}
      />
    );
  },
  questionHeight: 1150
});

const Question6 = newQuestionContent({
  uid: 'av1',
  description: 'av1',
  keywords: ['Bar chart', 'Interpret'],
  schema: z.object({
    pets: petSchema.array().length(5).refine(arrayHasNoDuplicates, 'Must be 5 unique pets'),
    data: z
      .number()
      .int()
      .min(0)
      .max(70)
      .array()
      .length(5)
      .refine(arrayHasNoDuplicates, 'Must have 5 unique values'),
    multiplier: numberEnum([2, 5, 10])
  }),
  simpleGenerator: () => {
    const pets = getRandomUniquePets(5);
    const multiplier = getRandomFromArray([2, 5, 10] as const);

    const data = randomUniqueIntegersInclusiveStep(0, 7 * multiplier, multiplier, 5);

    return { pets, data, multiplier };
  },
  Component: props => {
    const {
      question: { pets, data, multiplier },
      translate
    } = props;

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5, {
      random: seededRandom(props.question)
    });

    const answer = sumNumberArray(data).toString();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyAnimalsAreThereInTotal()}
        testCorrect={[answer]}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1150}
        Content={({ dimens }) => (
          <MultiBarChart
            width={dimens.width}
            height={dimens.height}
            squareGrid
            barValues={data.map((val, i) => ({ option: i, values: [val] }))}
            barLabels={pets.map(key => petAsWord(key, translate))}
            barColors={colors.map(key => barColorNames[key])}
            yMax={multiplier * 7}
            yStepSize={multiplier}
            xAxisLabel={translate.misc.typeOfPet()}
            yAxisLabel={translate.misc.numberOfAnimals()}
            yAxisArrowLabel={null}
          />
        )}
      />
    );
  },
  questionHeight: 1150
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InterpretBarCharts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
