import { z } from 'zod';
import { View } from 'react-native';

import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { isPrime } from '../../../../utils/primes';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { createHundredSquareShape } from '../../../../utils/shapes';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import {
  ScientificNotation,
  compareFloats,
  isValidDecimalString,
  numberToBase10Object
} from '../../../../utils/math';
import Text from '../../../../components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { range } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { fractionToDecimal, simplify } from '../../../../utils/fractions';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aOe',
  description: 'aOe',
  keywords: ['Hundredths', 'Decimals', 'Fractions'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(2)
      .max(97)
      .refine(val => isPrime(val), 'number1 should be a prime')
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([
      2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97
    ]);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const shape = createHundredSquareShape(number1);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.fractionOverX(100),
          translate.answerSentences.decimalAns()
        ]}
        mainPanelStyle={{ flexDirection: 'row' }}
        title={translate.instructions.whatFractionDecimalIsShownOnHundredSquare()}
        testCorrect={answer =>
          answer[0][0] === number1.toString() &&
          compareFloats((number1 / 100).toString(), answer[1][0])
        }
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => {
          return <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />;
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [[number1.toLocaleString()], [(number1 / 100).toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aOf',
  description: 'aOf',
  keywords: ['Hundredths', 'Decimals'],
  schema: z.object({
    number1: z.number().min(0.01).max(0.99).step(0.01)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99) / 100;

    return { number1 };
  },
  Component: ({ question: { number1 }, translate }) => {
    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragInCountersToRepresentNum(`${number1.toLocaleString()}`)}
        pdfTitle={translate.instructions.drawCountersToRepresentNum(`${number1.toLocaleString()}`)}
        number={ScientificNotation.fromNumber(number1)}
        columnsToShow={[0, -1, -2]}
        counterVariant="greyCounter"
        headerVariant="name"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aOg',
  description: 'aOg',
  keywords: ['Hundredths', 'Decimals'],
  schema: z.object({
    number1: z.number().min(0.01).max(0.99).step(0.01),
    number2: z.number().min(0.01).max(0.99).step(0.01),
    number3: z.number().min(0.01).max(0.99).step(0.01)
  }),
  simpleGenerator: () => {
    const [whole1, whole2, whole3] = randomUniqueIntegersInclusive(1, 99, 3);
    const number1 = whole1 / 100;
    const number2 = whole2 / 100;
    const number3 = whole3 / 100;

    return {
      number1,
      number2,
      number3
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate,
      displayMode
    } = props;

    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 100, width: 450 } : { height: 150, width: 700 };

    const getPlaceValueImages = (number: number) => {
      const base10 = numberToBase10Object(number);
      return (
        <View
          style={{
            borderWidth: displayMode === 'digital' ? 2 : 4,
            padding: displayMode === 'digital' ? 5 : 10,
            borderColor: colors.prussianBlue
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              width: dimens.width,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            {base10.tenths && base10.tenths > 0
              ? range(0, base10.tenths - 1).map(i => (
                  <AssetSvg key={i} name="Place_value/0.1" width={dimens.height / 2} />
                ))
              : null}
          </View>
          <View
            style={{
              flexDirection: 'row',
              width: dimens.width,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            {base10.hundredths && base10.hundredths > 0
              ? range(0, base10.hundredths - 1).map(i => (
                  <AssetSvg key={i} name="Place_value/0.01" width={dimens.height / 2} />
                ))
              : null}
          </View>
        </View>
      );
    };

    // Randomly order these statements
    const images = shuffle(
      [
        {
          lhsComponent: getPlaceValueImages(number1),
          correctAnswer: 'A'
        },
        {
          lhsComponent: getPlaceValueImages(number2),
          correctAnswer: 'B'
        },
        {
          lhsComponent: getPlaceValueImages(number3),
          correctAnswer: 'C'
        }
      ],
      { random: seededRandom(props.question) }
    );

    const items = [
      {
        component: <Text variant="WRN700">{number1}</Text>,
        value: 'A'
      },
      {
        component: <Text variant="WRN700">{number2}</Text>,
        value: 'B'
      },
      {
        component: <Text variant="WRN700">{number3}</Text>,
        value: 'C'
      }
    ];

    return (
      <QF6DragMatchStatements
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        title={translate.instructions.dragCardsMatchDecimalsToCounters()}
        pdfTitle={translate.instructions.useCardsMatchDecimalsToCounters()}
        items={items}
        statements={images}
        statementStyle={{ columnGap: 20 }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aOh',
  description: 'aOh',
  keywords: ['Hundredths', 'Decimals', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99);
    return { number1 };
  },

  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    const [numerator, denominator] = simplify(number1, 100);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.giveXAsFraction((number1 / 100).toLocaleString())}
        testCorrect={answer =>
          fractionToDecimal(Number(answer[0]), Number(answer[1]), 2) === number1 / 100
        }
        inputMaxCharacters={3}
        mainPanelContainerStyle={{
          justifyContent: 'flex-end'
        }}
        sentenceStyle={{ justifyContent: displayMode === 'digital' ? 'flex-end' : 'center' }}
        sentence={`<frac nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [numerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aOi',
  description: 'aOi',
  keywords: ['Hundredths', 'Decimals'],
  schema: z.object({
    tenths: z.number().int().min(1).max(9),
    hundredths: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const [tenths, hundredths] = randomUniqueIntegersInclusive(1, 9, 2);

    return {
      tenths,
      hundredths
    };
  },

  Component: props => {
    const {
      question: { tenths, hundredths },
      translate,
      displayMode
    } = props;

    const number1 = tenths / 10 + hundredths / 100;
    const number2 = tenths * 10 + hundredths;

    // Correct answers are indices 0 and 1
    const items = [
      `${tenths.toLocaleString()} ${translate.fractions.tenths(
        tenths
      )} ${translate.misc.and()} ${hundredths.toLocaleString()} ${translate.fractions.hundredths(
        hundredths
      )}`,
      `${number2.toLocaleString()} ${translate.fractions.hundredths(number2)}`,
      `${number2.toLocaleString()} ${translate.fractions.tenths(number2)}`,
      `${hundredths.toLocaleString()} ${translate.fractions.tenths(
        hundredths
      )} ${translate.misc.and()} ${tenths.toLocaleString()} ${translate.fractions.hundredths(
        tenths
      )}`
    ];

    const shuffledItems = shuffle(items, { random: seededRandom(props.question) });

    // Indices of shuffledItems where the correct answers are.
    const correct = [shuffledItems.indexOf(items[0]), shuffledItems.indexOf(items[1])];

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectDescriptionsThatMatchX(`${number1.toLocaleString()}`)}
        pdfTitle={translate.instructions.circleDescriptionsThatMatchX(
          `${number1.toLocaleString()}`
        )}
        testCorrect={correct}
        multiSelect
        itemsMaxLines={1}
        itemsTextAutoScale={false}
        itemsTextStyle={{ fontSize: displayMode === 'digital' ? 22 : 40 }}
        items={shuffledItems.map((displayString, index) => ({
          value: index,
          component: displayString
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aOj',
  description: 'aOj',
  keywords: ['Hundredths', 'Decimals', 'Partition'],
  schema: z.object({
    tenths: z.number().int().min(1).max(9),
    hundredths: z.number().int().min(1).max(9),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(1, 9);
    const hundredths = randomIntegerInclusive(1, 9);
    const name = getRandomName();

    return { tenths, hundredths, name };
  },

  Component: props => {
    const {
      question: { tenths, hundredths, name },
      translate
    } = props;

    const number1 = tenths / 10 + hundredths / 100;
    const number2 = tenths / 10;
    const number3 = hundredths / 100;

    const checkAnswer = (answer: string[]) => {
      if (!answer.every(isValidDecimalString)) {
        return false;
      }
      const [firstAnswer, secondAnswer] = answer.map(Number);

      return (
        firstAnswer !== number2 &&
        firstAnswer !== number3 &&
        secondAnswer !== number2 &&
        secondAnswer !== number3 &&
        compareFloats(firstAnswer + secondAnswer, number1)
      );
    };

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterPartitionsXintoYZFindDifferentWay(
          name,
          number1.toLocaleString(),
          number2.toLocaleString(),
          number3.toLocaleString()
        )}
        testCorrect={checkAnswer}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        sentence={`<ans/> ${translate.misc.and()} <ans/>`}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.anyValidPartition() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HundredthsAsDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
