import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  createShapeWithSquares,
  archivedRectilinearShapesProperties,
  perimeterCount
} from '../../../../utils/shapes';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import {
  shapeOnGridMissingLabelProperties,
  shapeOnGridProperties,
  shapesOnGridWithNumberOfSidesNoLabels
} from '../../../../utils/shapesOnGrid';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { barModelColors } from '../../../../theme/colors';
import { sumNumberArray } from '../../../../utils/collections';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import {
  getRandomRectilinearShape,
  labelledRectilinearShapesProperties,
  RectilinearShapeNameSchema
} from '../../../../utils/rectilinearShapes';
import { ShapeNames } from '../../../../utils/labelPositions';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aLC',
  description: 'aLC',
  keywords: ['Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    shape1: z.array(z.array(z.boolean())),
    shape2: z.array(z.array(z.boolean())),
    name: nameSchema,
    smallestOrGreatest: z.enum(['smallest', 'greatest'])
  }),
  simpleGenerator: () => {
    const { shape1, shape2 } = rejectionSample(
      () => {
        const numberOfSquares1 = randomIntegerInclusive(5, 18);
        const shape1 = createShapeWithSquares(
          4,
          5,
          numberOfSquares1,
          true,
          undefined,
          undefined,
          true,
          true
        );
        const numberOfSquares2 = randomIntegerInclusive(5, 18, {
          constraint: x => x !== numberOfSquares1
        });
        const shape2 = createShapeWithSquares(
          4,
          5,
          numberOfSquares2,
          true,
          undefined,
          undefined,
          true,
          true
        );
        return { shape1, shape2 };
      },
      val => perimeterCount(val.shape1) !== perimeterCount(val.shape2)
    );
    const name = getRandomName();
    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    return { shape1, shape2, name, smallestOrGreatest };
  },

  Component: ({ question: { shape1, shape2, name, smallestOrGreatest }, translate }) => {
    const shapes = [shape1, shape2];
    const answer =
      smallestOrGreatest === 'smallest'
        ? Math.min(perimeterCount(shape1), perimeterCount(shape2))
        : Math.max(perimeterCount(shape1), perimeterCount(shape2));
    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.xHasMadeSomeShapes(name)}<br/>${
          smallestOrGreatest === 'smallest'
            ? translate.instructions.selectShapeWithSmallerPerimeter()
            : translate.instructions.selectShapeWithGreatestPerimeter()
        }`}
        pdfTitle={`${translate.instructions.xHasMadeSomeShapes(name)}<br/>${
          smallestOrGreatest === 'smallest'
            ? translate.instructions.circleShapeWithSmallestPerimeter()
            : translate.instructions.circleShapeWithGreatestPerimeter()
        }`}
        numItems={2}
        testCorrect={[answer]}
        renderItems={({ dimens }) =>
          shapes.map(shape => {
            return {
              value: perimeterCount(shape),
              component: (
                <DisplayShapeOnGridWithBorder
                  givenShape={shape}
                  dimens={dimens}
                  isLollySticks
                  noGrid
                />
              )
            };
          })
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aLD',
  description: 'aLD',
  keywords: ['Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    numberOfSides: numberEnum([6, 8]),
    shapeSvgName: z.string()
  }),
  simpleGenerator: () => {
    const numberOfSides = getRandomFromArray([6, 8] as const);
    const shapeSvgName = shapesOnGridWithNumberOfSidesNoLabels(numberOfSides);

    return { numberOfSides, shapeSvgName };
  },
  Component: props => {
    const {
      question: { numberOfSides, shapeSvgName },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManySideLengthsDoYouNeedToAddTogetherToWorkOutThePerimeterOfTheShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ flex: 1, alignSelf: 'flex-end' }}
        sentence={'<ans/>'}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <AssetSvg name={shapeSvgName as SvgName} height={dimens.height} width={dimens.width} />
        )}
        testCorrect={[numberOfSides.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aLE',
  description: 'aLE',
  keywords: ['Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    shapePropertiesObject: z.object({ name: z.string(), perimeter: z.number(), sides: z.number() })
  }),
  simpleGenerator: () => {
    const shapePropertiesObject = getRandomFromArray(shapeOnGridProperties) as {
      name: string;
      perimeter: number;
      sides: number;
    };

    return { shapePropertiesObject };
  },
  Component: props => {
    const {
      question: { shapePropertiesObject },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.aRectilinearShapeIsDrawnOnASquareGridWorkOutThePerimeterOfTheShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences.ansCm()}
        Content={({ dimens }) => (
          <AssetSvg
            name={shapePropertiesObject.name as SvgName}
            height={dimens.height}
            width={dimens.width}
          />
        )}
        testCorrect={[shapePropertiesObject.perimeter.toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aLF',
  description: 'aLF',
  keywords: ['Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    shapePropertiesObject: z.object({ name: z.string(), missingLabelValue: z.number() })
  }),
  simpleGenerator: () => {
    const shapePropertiesObject = getRandomFromArray(shapeOnGridMissingLabelProperties) as {
      name: string;
      missingLabelValue: number;
    };

    return { shapePropertiesObject };
  },
  Component: props => {
    const {
      question: { shapePropertiesObject },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMissingLengthOnShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ flex: 1, alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={`? = <ans/>`}
        Content={({ dimens }) => (
          <AssetSvg
            name={shapePropertiesObject.name as SvgName}
            width={dimens.width}
            height={dimens.height}
          />
        )}
        testCorrect={[shapePropertiesObject.missingLabelValue.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aLG',
  description: 'aLG',
  keywords: ['Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    shape1: z.array(z.array(z.boolean()))
  }),
  simpleGenerator: () => {
    const numberOfSquares1 = randomIntegerInclusive(15, 20);
    const shape1 = createShapeWithSquares(
      5,
      5,
      numberOfSquares1,
      true,
      undefined,
      undefined,
      true,
      true
    );

    return { shape1 };
  },
  Component: ({ question: { shape1 }, translate }) => {
    const color = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom({ shape1 })
    }) as string;

    const answer = perimeterCount(shape1);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences.ansCm()}
        Content={({ dimens }) => (
          <DisplayShapeOnGridWithBorder
            givenShape={shape1}
            cellSizeLabel={translate.units.numberOfCm(1)}
            dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
            color={`${color}70`}
          />
        )}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aLH',
  description: 'aLH',
  keywords: ['Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    labelledRectilinearShapeName: RectilinearShapeNameSchema,
    sideLengthsIndex: z.number()
  }),
  simpleGenerator: () => {
    const labelledRectilinearShapeName = getRandomRectilinearShape();
    const sideLengthsIndex = getRandomFromArray([0, 1, 2]);

    return { labelledRectilinearShapeName, sideLengthsIndex };
  },
  Component: props => {
    const {
      question: { labelledRectilinearShapeName, sideLengthsIndex },
      translate
    } = props;

    const properties = archivedRectilinearShapesProperties.find(
      el => el.name === labelledRectilinearShapeName
    ) as { name: string; sideLengths: number[][] };

    const sideLengths = properties.sideLengths[sideLengthsIndex];

    const answer = sumNumberArray(sideLengths);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences.ansCm()}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height - 100, width: dimens.width - 100 }}
            shapeName={labelledRectilinearShapeName as ShapeNames}
            labels={sideLengths.map(el => translate.units.numberOfCm(el))}
            seed={props.question}
          />
        )}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aLH2',
  description: 'aLH',
  keywords: ['Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    shapeName: RectilinearShapeNameSchema,
    sideLengths: z.array(z.number().min(1).max(12))
  }),
  simpleGenerator: () => {
    const shapeName = getRandomRectilinearShape();
    const { sideLengthRatioOptions } = labelledRectilinearShapesProperties[shapeName];

    const sideLengths = rejectionSample(
      () => getRandomFromArray(sideLengthRatioOptions) as number[],
      // Side length should be a max of 12
      x => Math.max(...x) <= 12
    );

    return { shapeName, sideLengths };
  },
  Component: props => {
    const {
      question: { shapeName, sideLengths },
      translate
    } = props;

    const perimeter = sumNumberArray(sideLengths);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentence={translate.answerSentences.ansCm()}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height - 100, width: dimens.width - 100 }}
            shapeName={shapeName}
            labels={sideLengths.map(el => translate.units.numberOfCm(el))}
            seed={props.question}
          />
        )}
        testCorrect={[perimeter.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PerimeterOfRectilinearShapes',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
