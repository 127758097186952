import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import Text from '../../../../components/typography/Text';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import { TranslationFunctions } from '../../../../i18n/i18n-types';
import { colors } from '../../../../theme/colors';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import QF77DragShapeToTheX from '../../../../components/question/questionFormats/QF77DragShapeToTheX';

////
// Questions
////

const shapes = [
  'Arrow',
  'Equilateral',
  'Isosceles',
  'Square',
  'Rectangle',
  'Star',
  'Circle'
] as const;
type Shape = (typeof shapes)[number];

const svgNames: Record<Shape, SvgName[]> = {
  Arrow: [
    'SymmetricalShapes/horizontal2_pink',
    'SymmetricalShapes/horizontal2_purple',
    'SymmetricalShapes/horizontal2_green',
    'SymmetricalShapes/horizontal2_yellow'
  ],
  Equilateral: [
    'Equilateral_triangles/triangle_equal_pink',
    'Equilateral_triangles/triangle_equal_purple',
    'Equilateral_triangles/triangle_equal_green',
    'Equilateral_triangles/triangle_equal_yellow'
  ],
  Isosceles: [
    'Isosceles_triangles_narrow/triangle_isos_narrow_pink',
    'Isosceles_triangles_narrow/triangle_isos_narrow_purple',
    'Isosceles_triangles_narrow/triangle_isos_narrow_green',
    'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
  ],
  Square: [
    'Square/square_pink',
    'Square/square_purple',
    'Square/square_green',
    'Square/square_yellow'
  ],
  Rectangle: [
    'Rectangle/rectangle_pink',
    'Rectangle/rectangle_purple',
    'Rectangle/rectangle_green',
    'Rectangle/rectangle_yellow'
  ],
  Star: ['Star_pink', 'Star_purple', 'Star_green', 'Star_yellow'],
  Circle: [
    'Circles/circle_pink',
    'Circles/circle_purple',
    'Circles/circle_green',
    'Circles/circle_yellow'
  ]
};

const shapeTranslation = (shape: Shape, translate: TranslationFunctions) => {
  switch (shape) {
    case 'Square':
      return translate.shapes.squares(1);
    case 'Arrow':
      return translate.shapes.arrow();
    case 'Star':
      return translate.shapes.stars(1);
    case 'Equilateral':
    case 'Isosceles':
      return translate.shapes.triangles(1);
    case 'Rectangle':
      return translate.shapes.rectangles(1);
    case 'Circle':
      return translate.shapes.circles(1);
  }
};

const Question1 = newQuestionContent({
  uid: 'beR',
  description: 'beR',
  keywords: ['Above', 'Below', '2-D shapes'],
  schema: z.object({
    shapes: z
      .object({
        name: z.enum(shapes),
        colorIndex: z.number().int().min(0).max(3)
      })
      .array()
      .length(3)
      .refine(arrayHasNoDuplicates),
    variantInfo: z.discriminatedUnion('variant', [
      z.object({
        /** Student is asked whether shape a is above or below shape b */
        variant: z.literal('aboveOrBelow'),
        shapeIndices: z
          .tuple([z.number().int().min(0).max(3), z.number().int().min(0).max(3)])
          .refine(arrayHasNoDuplicates)
      }),
      z.object({
        /** Student is asked to find a shape above or below the given shape */
        variant: z.literal('shape'),
        isAbove: z.boolean(),
        shapeIndex: z.number().int().min(0).max(3),
        options: z.enum(shapes).array().length(3).refine(arrayHasNoDuplicates)
      }),
      z.object({
        /** Student is asked to find the top shape */
        variant: z.literal('top'),
        options: z.enum(shapes).array().length(3).refine(arrayHasNoDuplicates)
      })
    ])
  }),
  simpleGenerator: () => {
    // generate 4 so we have an extra one for the options
    const shapeOptions = [
      'Star' as const,
      'Arrow' as const,
      getRandomFromArray(['Equilateral', 'Isosceles'] as const),
      getRandomFromArray(['Square', 'Rectangle'] as const)
    ];

    const numberOfShapes = 3;
    const shapes = getRandomSubArrayFromArray([...shapeOptions] as const, numberOfShapes);
    const colorIndices = getRandomSubArrayFromArray(countRange(4), 3);
    const variant = getRandomFromArray(['aboveOrBelow', 'shape', 'top'] as const);
    const variantInfo = (() => {
      switch (variant) {
        case 'aboveOrBelow':
          return {
            variant,
            shapeIndices: randomUniqueIntegersInclusive(0, numberOfShapes - 1, 2) as [
              number,
              number
            ]
          };
        case 'shape': {
          const shapeIndex = randomIntegerInclusive(0, numberOfShapes - 1);
          return {
            variant,
            isAbove:
              shapeIndex === numberOfShapes - 1
                ? false
                : shapeIndex === 0
                ? true
                : getRandomBoolean(),
            shapeIndex,
            options: shuffle(shapeOptions.filter(val => val !== shapes[shapeIndex]))
          };
        }
        case 'top':
          return { variant, options: shuffle(shapes) };
      }
    })();
    return {
      shapes: shapes.map((name, i) => ({ name, colorIndex: colorIndices[i] })),
      variantInfo
    };
  },
  Component: props => {
    const {
      question: { shapes, variantInfo },
      translate
    } = props;

    const random = seededRandom(props.question);
    type Value = Shape | 'above' | 'below';

    const { draggables, exampleAnswer, testCorrect, sentence } = (() => {
      const translateIndex = (index: number) => shapeTranslation(shapes[index].name, translate);

      switch (variantInfo.variant) {
        case 'aboveOrBelow': {
          const { shapeIndices } = variantInfo;
          const [shape1Index, shape2Index] = shapeIndices;
          const draggables = (['above', 'below'] as const).map(val => ({
            value: val,
            component: <Text variant="WRN700">{translate.ks1MiscStrings.directions[val]()}</Text>
          }));
          const exampleAnswer = [
            shape1Index < shape2Index ? ('above' as const) : ('below' as const)
          ];
          const testCorrect = exampleAnswer;
          const sentence = translate.ks1AnswerSentences.theShapeIsAnsTheShape(
            translateIndex(shape1Index),
            translateIndex(shape2Index)
          );
          return { draggables, exampleAnswer, testCorrect, sentence };
        }
        case 'shape': {
          const { shapeIndex, options, isAbove } = variantInfo;
          const draggables = options.map(val => ({
            value: val,
            component: <Text variant="WRN700">{shapeTranslation(val, translate)}</Text>
          }));

          const correctAnswers: Value[] = shapes
            .filter(
              (shape, index) =>
                options.includes(shape.name) && (isAbove ? index > shapeIndex : index < shapeIndex)
            )
            .map(shape => shape.name);
          const exampleAnswer = [getRandomFromArray(correctAnswers, { random })!];
          const testCorrect = (userAnswer: readonly (Value | undefined)[]) => {
            if (userAnswer[0] === undefined) return false;
            return correctAnswers.includes(userAnswer[0]);
          };
          const sentence = isAbove
            ? translate.ks1AnswerSentences.theShapeIsAboveTheAns(translateIndex(shapeIndex))
            : translate.ks1AnswerSentences.theShapeIsBelowTheAns(translateIndex(shapeIndex));
          return { draggables, exampleAnswer, testCorrect, sentence };
        }

        case 'top': {
          const { options } = variantInfo;
          const draggables = options.map(val => ({
            value: val,
            component: <Text variant="WRN700">{shapeTranslation(val, translate)}</Text>
          }));
          const exampleAnswer = [shapes[0].name];
          const testCorrect = exampleAnswer;
          const sentence =
            shapes[0].name === 'Arrow'
              ? translate.ks1AnswerSentences.theTopShapeIsAnAns()
              : translate.ks1AnswerSentences.theTopShapeIsAAns();
          return { draggables, exampleAnswer, testCorrect, sentence };
        }
      }
    })();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={draggables}
        actionPanelVariant="endWide"
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-evenly' }}>
            {shapes.map((shape, i) => (
              <View
                key={i}
                style={{
                  transform: `rotate(${shape.name === 'Arrow' ? 270 : 0}deg)`,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <AssetSvg
                  name={svgNames[shape.name][shape.colorIndex]}
                  height={dimens.height * 0.25}
                />
              </View>
            ))}
          </View>
        )}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        pdfLayout="itemsTop"
        sentence={sentence}
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answersToDisplay: [exampleAnswer],
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
        sentencesStyle={{ alignItems: 'flex-start' }}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'beS',
  description: 'beS',
  keywords: ['Above', 'Below', '2-D shapes'],
  schema: z.object({
    shapes: z
      .enum(['Arrow', 'Equilateral', 'Isosceles', 'Square', 'Rectangle', 'Circle', 'Star'])
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates),
    colorIndex: z.number().int().min(0).max(3),
    itemRotation: numberEnum([0, 90, 180, 270]),
    shapeIndex: z.number().int().min(1).max(3),
    isAbove: z.boolean()
  }),
  simpleGenerator: () => {
    const shapes = getRandomSubArrayFromArray(
      [
        'Arrow',
        'Circle',
        getRandomFromArray(['Equilateral', 'Isosceles'] as const),
        getRandomFromArray(['Square', 'Rectangle'] as const),
        'Star'
      ] as const,
      4
    );

    const itemRotation = getRandomFromArray([0, 90, 180, 270] as const);

    return {
      shapes,
      colorIndex: randomIntegerInclusive(0, 3),
      isAbove: getRandomBoolean(),
      itemRotation,
      shapeIndex: randomIntegerInclusive(1, 3)
    };
  },
  Component: props => {
    const {
      question: { shapes, colorIndex, isAbove, shapeIndex, itemRotation },
      translate
    } = props;

    const cardArray: (SvgName | '<ans/>')[] = ['<ans/>', svgNames[shapes[0]][colorIndex], '<ans/>'];
    const items = shapes.slice(1, 4).map(val => svgNames[val][colorIndex]);
    const aboveAnswer = [[svgNames[shapes[shapeIndex]][colorIndex]], []];

    const shapeA = shapeTranslation(shapes[shapeIndex], translate);
    const shapeB = shapeTranslation(shapes[0], translate);

    return (
      <QF77DragShapeToTheX
        title={
          isAbove
            ? translate.ks1Instructions.dragTheShapeSoItIsAboveTheShape(shapeA, shapeB)
            : translate.ks1Instructions.dragTheShapeSoItIsBelowTheShape(shapeA, shapeB)
        }
        pdfTitle={
          isAbove
            ? translate.ks1PDFInstructions.drawAShapeAboveTheShape(shapeA, shapeB)
            : translate.ks1PDFInstructions.drawAShapeBelowTheShape(shapeA, shapeB)
        }
        variation="vertical"
        items={items}
        shapeRotation={itemRotation}
        cardArray={cardArray}
        testCorrect={isAbove ? aboveAnswer : [...aboveAnswer].reverse()}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'beT',
  description: 'beT',
  keywords: ['Above', 'Below', '2-D shapes'],
  schema: z.object({
    shapes: z
      .enum(['Arrow', 'Equilateral', 'Isosceles', 'Square', 'Rectangle', 'Star'])
      .array()
      .refine(arrayHasNoDuplicates),
    shapeIndex: z.number().int().min(0).max(3),
    isBelow: z.boolean(),
    positions: z
      .tuple([z.number().int().min(0).max(3), z.number().int().min(0).max(3)])
      .array()
      .refine(arrayHasNoDuplicates),
    colorIndexes: z.number().int().min(0).max(3).array(),
    shapeRotations: numberEnum([90, 180, 270, 0]).array()
  }),
  simpleGenerator: () => {
    const numberOfShapes = randomIntegerInclusive(3, 4);
    const shapes = getRandomSubArrayFromArray(
      [
        'Star' as const,
        'Arrow' as const,
        getRandomFromArray(['Equilateral', 'Isosceles'] as const),
        getRandomFromArray(['Square', 'Rectangle'] as const)
      ],
      numberOfShapes
    );

    const { positions, shapeIndex, isBelow } = rejectionSample(
      () => {
        const positions = countRange(numberOfShapes).map(() => [
          randomIntegerInclusive(0, 3),
          randomIntegerInclusive(0, 3)
        ]) as [number, number][];
        const shapeIndex = randomIntegerInclusive(0, numberOfShapes - 1);
        const isBelow =
          positions[shapeIndex][1] === 3
            ? true
            : positions[shapeIndex][1] === 0
            ? false
            : getRandomBoolean();

        return { positions, shapeIndex, isBelow };
      },
      val =>
        arrayHasNoDuplicates(
          [
            ...val.positions,
            // also dont want one directly above or below
            [
              val.positions[val.shapeIndex][0],
              val.positions[val.shapeIndex][1] + (val.isBelow ? -1 : 1)
            ]
          ],
          (a, b) => a[0] === b[0] && a[1] === b[1]
        )
    );

    const colorIndexes = randomUniqueIntegersInclusive(0, 3, 4);
    const shapeRotations = getRandomSubArrayFromArray([0, 90, 180, 270] as const, numberOfShapes);

    return {
      shapes,
      positions,
      shapeIndex,
      isBelow,
      colorIndexes,
      shapeRotations
    };
  },
  Component: ({
    question: { shapes, positions, shapeIndex, isBelow, colorIndexes, shapeRotations },
    translate,
    displayMode
  }) => {
    const shapeName = shapeTranslation(shapes[shapeIndex], translate);

    const title = isBelow
      ? translate.ks1Instructions.dragTheCircleBelowTheShape(shapeName)
      : translate.ks1Instructions.dragTheCircleAboveTheShape(shapeName);

    const pdfTitle = isBelow
      ? translate.ks1PDFInstructions.drawACircleBelowTheShape(shapeName)
      : translate.ks1PDFInstructions.drawACircleAboveTheShape(shapeName);

    // We always have 4x4 grid so lets hard code this so we know the size required for the draggables
    const gridSize = 125;

    return (
      <QF46PlotCoordinate
        title={displayMode === 'digital' ? title : pdfTitle}
        snapToGrid
        testCorrect={ans =>
          isBelow ? ans[0][1] < positions[shapeIndex][1] : ans[0][1] > positions[shapeIndex][1]
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [positions[shapeIndex][0], positions[shapeIndex][1] + (isBelow ? -1 : 1)]
          ]
        }}
        gridProps={{
          xMax: 4,
          yMax: 4,
          squareGrid: true,
          hideContinuationLines: true,
          yAxis: null,
          xAxis: null,
          sizingMethod: 'gridScale',
          xScale: gridSize,
          yScale: gridSize,
          gridLineWidth: 2
        }}
        gridChildren={shapes.map((shape, i) => (
          <GridImage
            key={i}
            mathCoord={positions[i]}
            item={{
              component: (
                <View
                  style={{
                    width: gridSize,
                    height: gridSize,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <View style={{ transform: `rotate(${shapeRotations[i]}deg)` }}>
                    <AssetSvg
                      key={shape}
                      name={svgNames[shape][colorIndexes[i]]}
                      width={gridSize * 0.9}
                      height={gridSize * 0.9}
                    />
                  </View>
                </View>
              ),
              width: gridSize,
              height: gridSize
            }}
            anchorDX={0}
            anchorDY={gridSize}
          />
        ))}
        items={[
          {
            component: (
              <View
                style={{
                  width: gridSize,
                  height: gridSize,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <AssetSvg
                  name="Coordinates/CirclePointCustomizable"
                  width={gridSize * 0.9}
                  height={gridSize * 0.9}
                  svgProps={{ fill: colors.burntSienna }}
                />
              </View>
            ),
            width: gridSize,
            height: gridSize,
            anchor: [0, gridSize]
          }
        ]}
        gridMaxOffset={1}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribePositionAboveAndBelow',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
