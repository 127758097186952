import { useContext, useMemo } from 'react';
import { StyleProp, View, ViewStyle, StyleSheet, FlexAlignType } from 'react-native';
import { ScaleFactorContext } from '../../../../theme/scaling';
import { colors } from '../../../../theme/colors';
import Text from '../../../typography/Text';
import { SetState, projectSetState } from '../../../../utils/react';
import { FontVariantKey } from '../../../../theme';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import { DisplayMode } from '../../../../contexts/displayMode';
import { countRange } from '../../../../utils/collections';

type InputCellProps = {
  colIndex: number;
  /** if absolutely positioned on the whole grid this works out the top */
  rowIndex?: number;
  autoFocus: boolean;
  numberUserAnswer: (string | undefined)[];
  numberSetUserAnswer: SetState<string[]>;
  indexOfDigit: number;
  mergeLeftBorder: boolean;
  mergeRightBorder: boolean;
  mergeTopBorder?: boolean;
  mergeBottomBorder?: boolean;
  gridLineWidth: number;
  cellDimens: number;
  answerBoxBorderWidth: number;
  isExchange?: boolean;
  showBorderAroundBoxesOnPdf: boolean;
  textPosition?: 'topLeft' | 'bottomLeft' | 'center';
};

/**
 * Singular Input Cell with orange background by default.
 * Foe exchanges pass isExchange to see exchange styling
 * If rowIndex is provided it will be absolutely positioned left by this many cells
 */
export function InputCell({
  colIndex,
  rowIndex = 0,
  autoFocus,
  numberUserAnswer,
  numberSetUserAnswer,
  indexOfDigit,
  mergeLeftBorder,
  mergeRightBorder,
  mergeTopBorder = false,
  mergeBottomBorder = false,
  gridLineWidth,
  cellDimens,
  answerBoxBorderWidth,
  isExchange,
  textPosition: textPositionProp,
  showBorderAroundBoxesOnPdf
}: InputCellProps) {
  const displayMode = useContext(DisplayMode);
  const styles = useSharedStyles(cellDimens, gridLineWidth);

  const textPosition = textPositionProp ?? (isExchange ? 'bottomLeft' : 'center');

  const top = rowIndex * cellDimens + gridLineWidth;
  const left = colIndex * cellDimens + gridLineWidth;
  const insideCellDimens = cellDimens - 2 * gridLineWidth;
  // Distance required to place an answer box border directly over a grid line.
  const mergedBorderOffset = answerBoxBorderWidth - gridLineWidth / 2;

  const textAlignment: {
    justifyContent:
      | 'center'
      | 'flex-start'
      | 'flex-end'
      | 'space-between'
      | 'space-around'
      | 'space-evenly'
      | undefined;
    alignItems: FlexAlignType;
  } =
    textPosition === 'bottomLeft'
      ? { justifyContent: 'flex-start', alignItems: 'flex-end' }
      : { justifyContent: 'flex-start', alignItems: 'flex-start' };

  const style = isExchange ? { ...styles.exchangeCell, ...textAlignment } : undefined;

  const leftOffset =
    (mergeLeftBorder && !mergeRightBorder) || (mergeLeftBorder && mergeRightBorder)
      ? mergedBorderOffset
      : 0;

  const widthOffset =
    mergeLeftBorder && !mergeRightBorder
      ? mergedBorderOffset
      : !mergeLeftBorder && mergeRightBorder
      ? mergedBorderOffset
      : mergeLeftBorder && mergeRightBorder
      ? answerBoxBorderWidth + 2 * gridLineWidth
      : 0;

  const topOffset = mergeTopBorder ? mergedBorderOffset : 0;
  let heightOffset = 0;
  if (mergeTopBorder) heightOffset += mergedBorderOffset;
  if (mergeBottomBorder) heightOffset += mergedBorderOffset;

  return displayMode !== 'pdf' || showBorderAroundBoxesOnPdf ? (
    <NoKeyboardTextInput
      value={numberUserAnswer[indexOfDigit] ?? ''}
      onChangeText={projectSetState(numberSetUserAnswer, indexOfDigit)}
      style={[
        style,
        {
          position: 'absolute',
          top: top - topOffset,
          left: left - leftOffset,
          width: insideCellDimens + widthOffset,
          height: insideCellDimens + heightOffset,
          minWidth: insideCellDimens + widthOffset,
          minHeight: insideCellDimens + heightOffset
        },
        displayMode !== 'digital' && {
          borderColor: showBorderAroundBoxesOnPdf && !isExchange ? colors.black : colors.gridBlue
        }
      ]}
      fontVariant={isExchange ? 'WRN400' : undefined}
      selectedStyle={{ zIndex: 3 }}
      autoFocus={autoFocus}
      singleCharacterMode={!isExchange}
    />
  ) : (
    <></>
  );
}

type LabelCellProps = {
  character?: string;
  cellDimens: number;
  answerBoxBorderWidth: number;
  gridLineWidth: number;
  style?: StyleProp<ViewStyle>;
  fontVariant?: FontVariantKey;
  fontStyle?: {
    fontSize?: number;
    lineHeight?: number;
  };
  hideBorder?: boolean;
};

/**
 * Label cell for column operations
 * Contains text when character is provided
 */
export function LabelCell({
  character,
  cellDimens,
  gridLineWidth,
  style,
  fontVariant = 'WRN400',
  fontStyle,
  hideBorder
}: LabelCellProps) {
  const sharedStyles = useSharedStyles(cellDimens, gridLineWidth);
  return (
    <View
      style={[
        sharedStyles.cell,
        {
          borderColor: hideBorder ? 'transparent' : undefined,
          position: hideBorder ? 'absolute' : undefined
        },
        style
      ]}
    >
      {character !== undefined && (
        <Text variant={fontVariant} style={fontStyle}>
          {character}
        </Text>
      )}
    </View>
  );
}

type OverlayTextCellProps = {
  cellDimens: number;
  text: string;
  colIndex: number;
  gridLineWidth: number;
  position?: 'center' | 'topLeft';
};

/**
 * A centered view to place over any cell
 */
export function OverlayText({
  cellDimens,
  text,
  colIndex,
  gridLineWidth,
  position = 'center'
}: OverlayTextCellProps) {
  const displayMode = useContext(DisplayMode);
  const lineHeight = displayMode === 'digital' ? 22.5 : 36;
  const fontSize = displayMode === 'digital' ? 22 : 32;
  const characters = text.length;
  const width = fontSize * characters;
  const height = position === 'center' ? 60 : lineHeight;
  const left =
    position === 'center'
      ? colIndex * cellDimens + cellDimens / 2 + gridLineWidth - width / 2
      : colIndex * cellDimens + 5;
  const top =
    position === 'center' ? cellDimens / 2 - height / 2 : gridLineWidth + lineHeight * 0.5;
  const fontStyle =
    position === 'center'
      ? undefined
      : {
          fontSize,
          lineHeight
        };
  return (
    <View
      style={{
        position: 'absolute',
        left: left,
        top: top,
        width: width,
        height: height,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10
      }}
    >
      <Text variant="WRN400" style={fontStyle}>
        {text}
      </Text>
    </View>
  );
}

type GivenExchangesCellProps = {
  colIndex: number;
  character: string;
  mergeLeftBorder: boolean;
  mergeRightBorder: boolean;
  gridLineWidth: number;
  cellDimens: number;
  answerBoxBorderWidth: number;
};

/**
 * Grey exchange box with value given
 */
export function GivenExchangesCell({
  colIndex,
  character,
  mergeLeftBorder,
  mergeRightBorder,
  gridLineWidth,
  cellDimens,
  answerBoxBorderWidth
}: GivenExchangesCellProps) {
  const displayMode = useContext(DisplayMode);
  const top = gridLineWidth;
  const left = colIndex * cellDimens + gridLineWidth;
  const insideCellDimens = cellDimens - 2 * gridLineWidth;
  // Distance required to place an answer box border directly over a grid line.
  const mergedBorderOffset = answerBoxBorderWidth - gridLineWidth / 2;

  const leftOffset =
    (mergeLeftBorder && !mergeRightBorder) || (mergeLeftBorder && mergeRightBorder)
      ? mergedBorderOffset
      : 0;

  const widthOffset =
    (mergeLeftBorder && !mergeRightBorder) || (!mergeLeftBorder && mergeRightBorder)
      ? mergedBorderOffset
      : mergeLeftBorder && mergeRightBorder
      ? answerBoxBorderWidth + 2 * gridLineWidth
      : 0;

  const digitalStyle: StyleProp<ViewStyle> = {
    borderTopColor: colors.greys500,
    borderBottomColor: colors.greys500,
    borderStartColor: colors.greys500,
    borderEndColor: colors.greys500,
    backgroundColor: colors.greys100,
    borderStyle: 'solid'
  };

  return (
    <LabelCell
      key={colIndex}
      cellDimens={cellDimens}
      character={character}
      gridLineWidth={gridLineWidth}
      answerBoxBorderWidth={answerBoxBorderWidth}
      fontStyle={{
        fontSize: displayMode === 'digital' ? 22 : 32,
        lineHeight: displayMode === 'digital' ? 22.5 : 36
      }}
      style={[
        {
          position: 'absolute',
          top: top,
          left: left - leftOffset,
          width: insideCellDimens + widthOffset,
          height: insideCellDimens,
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          padding: 5,
          borderWidth: displayMode === 'digital' ? 3 : 0
        },
        displayMode === 'digital' ? digitalStyle : undefined
      ]}
    />
  );
}

/**
 * Row of empty cells of given length
 */
export const emptyRow = (
  length: number,
  rowIndex: string,
  gridLineWidth: number,
  answerBoxBorderWidth: number,
  cellDimens: number
) => {
  return (
    <View key={rowIndex} style={{ flexDirection: 'row' }}>
      {countRange(length).map(digit => (
        <LabelCell
          key={digit}
          cellDimens={cellDimens}
          gridLineWidth={gridLineWidth}
          answerBoxBorderWidth={answerBoxBorderWidth}
        />
      ))}
    </View>
  );
};

const useSharedStyles = (cellDimens: number, gridLineWidth: number) => {
  const displayMode = useContext(DisplayMode);
  const scaleFactor = useContext(ScaleFactorContext);
  const minBorderWidth = 1 / scaleFactor;
  return useMemo(
    () =>
      StyleSheet.create({
        cell: {
          width: cellDimens,
          height: cellDimens,
          borderWidth: Math.max(minBorderWidth, gridLineWidth),
          borderTopColor: colors.gridBlue,
          borderBottomColor: colors.gridBlue,
          borderStartColor: colors.gridBlue,
          borderEndColor: colors.gridBlue,
          justifyContent: 'center',
          alignItems: 'center'
        },
        exchangeCell: {
          borderColor: colors.greys500,
          backgroundColor: displayMode === 'digital' ? colors.greys100 : undefined,
          fontSize: displayMode === 'digital' ? 22 : 32,
          lineHeight: displayMode === 'digital' ? 22.5 : 36,
          padding: 5
        }
      }),
    [cellDimens, displayMode, gridLineWidth, minBorderWidth]
  );
};
