import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { filledArray } from '../../../../utils/collections';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { fractionSchema, mixedFractionSchema, numberEnum } from '../../../../utils/zod';
import {
  Fraction,
  MixedFraction,
  compareFractions,
  decimalToFraction,
  fractionArithmetic,
  fractionToDecimal,
  improperFractionToMixedNumber,
  simplify
} from '../../../../utils/fractions';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import PieChart from '../../../../components/question/representations/PieChart';
import { View } from 'react-native';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import Table from '../../../../components/molecules/Table';
import { barModelColors, colors } from '../../../../theme/colors';
import { gcd } from 'mathjs';
import TextStructure from '../../../../components/molecules/TextStructure';
import { isPrime } from '../../../../utils/primes';
import { findFactorsExcludingSelfAnd1 } from '../../../../utils/factors';
import { mixedFractionToMarkup } from '../../../../utils/markup';
import { SUB } from '../../../../constants';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import { parseToSUB } from '../../../../utils/parse';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aqf',
  description: 'aqf',
  keywords: ['Number line', 'Fractions'],
  schema: z
    .object({
      numTicks: z.number().int().min(5).max(12),
      fractionNum: z.number().int().min(1).max(11)
    })
    .refine(val => val.fractionNum < val.numTicks, 'fractionNum must be less than numTicks'),
  simpleGenerator: () => {
    const numTicks = randomIntegerInclusive(5, 12);
    // the fraction cannot be at the start or end of the number line
    const fractionNum = randomIntegerInclusive(1, numTicks - 2);
    return { numTicks, fractionNum };
  },
  Component: props => {
    const {
      question: { numTicks, fractionNum },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 1;
    const fractionSentence = `<frac nAns='' d='${numTicks - 1}'/>`;

    // Create the tick values array
    const tickValues = filledArray('', numTicks);
    tickValues[0] = startingNumber.toLocaleString();
    tickValues[numTicks - 1] = endNumber.toLocaleString();
    tickValues[fractionNum] = fractionSentence;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.labelTheFractionOnTheNumberLine()}
        testCorrect={[fractionNum.toString()]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aqg',
  description: 'aqg',
  keywords: ['Number line', 'Fraction', 'Equivalent', 'Representations'],
  schema: z.object({
    targetFraction: fractionSchema(
      z.number().int().min(1).max(12),
      z.number().int().min(6).max(12).step(2)
    )
      .refine(([n, d]) => (gcd([n, d]) as unknown as number) !== 1, 'must not be in simplest form')
      .refine(([n, d]) => n < d, 'must be a proper fraction less than 1'),
    pieFraction: fractionSchema(
      z.number().int().min(1).max(12),
      z.number().int().min(2).max(12)
    ).refine(([n, d]) => n <= d, 'must be a proper fraction less than or equal to 1'),
    barFraction: fractionSchema(
      z.number().int().min(1).max(12),
      z.number().int().min(2).max(12)
    ).refine(([n, d]) => n <= d, 'must be a proper fraction less than or equal to 1'),
    tableFraction: fractionSchema(z.number().int().min(1).max(12), z.number().int().min(2).max(12))
      .refine(([_, d]) => !isPrime(d), 'denominator must not be prime')
      .refine(([n, d]) => n <= d, 'must be a proper fraction less than or equal to 1')
  }),
  simpleGenerator: () => {
    // Target fraction
    const targetFraction: Fraction = rejectionSample(
      () => {
        const d = randomIntegerInclusiveStep(6, 12, 2);
        const n = randomIntegerInclusive(1, d - 1);

        return [n, d];
      },
      ([n, d]) => (gcd([n, d]) as unknown as number) !== 1
    );

    // Generate three answer fractions with following constraints:
    // - At least 1 must be correct (numCorrect >= 1)
    // - At least 1 in simplest form and at least 1 not (1 <= numInSimplestForm < 3)
    // Can include equivalent fractions
    const fractions: [Fraction, Fraction, Fraction] = rejectionSample(
      () => {
        const fractions: Fraction[] = [];

        const getRandomCorrectFraction = (): Fraction =>
          getRandomFromArray([simplify(...targetFraction), targetFraction]);

        // Pick 1st fraction - always correct
        fractions.push(getRandomCorrectFraction());
        // Pick 2nd fraction - 50% chance to be correct
        if (getRandomFromArray([true, false])) {
          fractions.push(getRandomCorrectFraction());
        } else {
          const { numeratorB, denominatorB } = rejectionSample(
            () => {
              const denominatorB = randomIntegerInclusive(2, 12);

              const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

              return { numeratorB, denominatorB };
            },
            ({ numeratorB, denominatorB }) =>
              // Need to ensure this does not generate a fraction whose unshaded part could be seen as correct:
              !compareFractions([denominatorB - numeratorB, denominatorB], targetFraction) &&
              !compareFractions([numeratorB, denominatorB], targetFraction)
          );
          fractions.push([numeratorB, denominatorB]);
        }
        // Pick 3rd fraction - fully random, possible to be correct

        const { numeratorC, denominatorC } = rejectionSample(
          () => {
            const denominatorC = randomIntegerInclusive(2, 12);

            const numeratorC = randomIntegerInclusive(1, denominatorC - 1);

            return { numeratorC, denominatorC };
          },
          ({ numeratorC, denominatorC }) =>
            // Need to ensure this does not generate a fraction whose unshaded part could be seen as correct:
            !compareFractions([denominatorC - numeratorC, denominatorC], targetFraction)
        );
        fractions.push([numeratorC, denominatorC]);

        return fractions as [Fraction, Fraction, Fraction];
      },
      fractions => {
        const numInSimplestForm = fractions.filter(
          ([n, d]) => (gcd([n, d]) as unknown as number) === 1
        ).length;
        return 1 <= numInSimplestForm && numInSimplestForm < 3;
      }
    );

    // Randomly distribute fractions, but tableFraction must have a denominator that is not prime
    // There will always be one, because at least 1 fraction is not in simplest form
    const [pieFraction, barFraction, tableFraction] = rejectionSample(
      () => shuffle(fractions),
      ([_pieFraction, _barFraction, [_n, d]]) => !isPrime(d)
    );

    return { targetFraction, pieFraction, barFraction, tableFraction };
  },
  Component: props => {
    const {
      question: { targetFraction, pieFraction, barFraction, tableFraction },
      translate,
      displayMode
    } = props;
    const random = seededRandom(props.question);

    // Number Line
    const startingNumber = 0;
    const endNumber = 1;
    const tickValues = filledArray('', targetFraction[1] + 1);
    tickValues[0] = startingNumber.toLocaleString();
    tickValues[targetFraction[1]] = endNumber.toLocaleString();

    // Select 3 unique colours for all representations
    const [tableColor, barModelColor, pieChartColor] = getRandomSubArrayFromArray(
      Object.values(barModelColors),
      3,
      { random }
    ) as string[];

    // Choose which sections to shade for pie chart and bar model
    const missingSlices = randomUniqueIntegersInclusive(
      0,
      pieFraction[1] - 1,
      pieFraction[1] - pieFraction[0],
      { random }
    );
    const coloredBarModelSections = randomUniqueIntegersInclusive(
      0,
      barFraction[1] - 1,
      barFraction[0],
      { random }
    );

    // Create table rows
    const tableRows = Math.min(...findFactorsExcludingSelfAnd1(tableFraction[1]));
    const tableShaded = tableFraction[0];
    const tableColumns = tableFraction[1] / tableRows;
    let table = filledArray(filledArray('white', tableColumns), tableRows);

    // Shade from top-left, row by row
    let cellsShadedSofar = 0;
    table = table.map(row =>
      row.map(() => {
        if (cellsShadedSofar < tableShaded) {
          cellsShadedSofar++;
          return displayMode === 'digital' ? tableColor : colors.pdfShading;
        } else {
          return 'white';
        }
      })
    );

    const coloredItems = table.map(rowColors =>
      rowColors.map((color, columnIndex) => (
        <View
          key={columnIndex}
          style={{
            backgroundColor: color,
            flex: 1,
            width: (displayMode === 'digital' ? 400 : 600) / tableColumns,
            height: 45
          }}
        />
      ))
    );

    // Statements to select
    const A = {
      component: (
        <PieChart
          pieOptions={filledArray({ ratioOfSlices: 1 }, pieFraction[1])}
          radius={displayMode === 'digital' ? 50 : 75}
          missingSlices={missingSlices}
          color={displayMode === 'digital' ? pieChartColor : colors.pdfShading}
        />
      ),
      value: 'A',
      correct: compareFractions(pieFraction, targetFraction)
    };
    const B = {
      component: (
        <ShadedFractionBarModel
          totalSubSections={barFraction[1]}
          height={displayMode === 'digital' ? 40 : 60}
          width={displayMode === 'digital' ? 400 : 600}
          totalPerSection={1}
          coloredSections={coloredBarModelSections}
          color={barModelColor}
        />
      ),
      value: 'B',
      correct: compareFractions(barFraction, targetFraction)
    };
    const C = {
      component: (
        <Table
          style={{
            width: displayMode === 'digital' ? 400 : 600,
            height: displayMode === 'digital' ? 90 : 120
          }}
          rowStyle={{ flex: 1 }}
          cellStyle={{ flex: 1 }}
          items={coloredItems}
        />
      ),
      value: 'C',
      correct: compareFractions(tableFraction, targetFraction)
    };

    const statements = shuffle([A, B, C], { random });

    const correctStatements = statements.filter(it => it.correct);
    const correctValues = correctStatements.map(it => it.value);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectAllRepresentationsShownByNumberLine()}
        testCorrect={correctValues}
        multiSelect
        numItems={3}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={fractionToDecimal(...targetFraction)}
          />
        )}
        renderItems={statements}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aqh',
  description: 'aqh',
  keywords: ['Equivalent', 'Fraction', 'Number line'],
  schema: z.object({
    numOfIntervals: numberEnum([6, 8, 9, 10, 12]),
    numerator: z.number().int().min(1).max(4),
    denominator: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const numOfIntervals = getRandomFromArray([6, 8, 9, 10, 12] as const);
    let numerator, denominator;

    // numerator & denominator must be a pair of "proper" factors of numOfIntervals
    // and in their simplest form (in terms of a fraction) e.g. [2, 4] === [1, 2]
    switch (numOfIntervals) {
      case 6:
        [numerator, denominator] = getRandomFromArray([
          [1, 2],
          [1, 3],
          [2, 3]
        ]);
        break;
      case 8:
        [numerator, denominator] = getRandomFromArray([
          [1, 2],
          [1, 4],
          [3, 4]
        ]);
        break;
      case 9:
        [numerator, denominator] = getRandomFromArray([
          [1, 3],
          [2, 3]
        ]);
        break;
      case 10:
        [numerator, denominator] = getRandomFromArray([
          [1, 2],
          [1, 5],
          [2, 5],
          [3, 5],
          [4, 5]
        ]);
        break;
      case 12:
        [numerator, denominator] = getRandomFromArray([
          [1, 2],
          [1, 4],
          [3, 4],
          [1, 3],
          [2, 3]
        ]);
        break;
    }

    return { numOfIntervals, numerator, denominator };
  },
  Component: props => {
    const {
      question: { numOfIntervals, numerator, denominator },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 1;
    const tickInterval = 1 / numOfIntervals;
    const sliderStep = 1 / (numOfIntervals * 50);

    const fractionToFind = `<frac n='${numerator}' d='${denominator}'/>`;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;
    const numberArray = filledArray('', numTicks);

    // Set start and end numbers
    numberArray[0] = startingNumber.toLocaleString();
    numberArray[numberArray.length - 1] = endNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(fractionToFind)}
        pdfTitle={translate.instructions.showPositionOfNumPdf(fractionToFind)}
        testCorrect={[
          fractionToDecimal(numerator, denominator) - 1 / (numOfIntervals * 20),
          fractionToDecimal(numerator, denominator) + 1 / (numOfIntervals * 20)
        ]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderStep}
        tickValues={numberArray}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqi',
  description: 'aqi',
  keywords: ['Equivalent', 'Fraction', 'Number line'],
  schema: z
    .object({
      numTicks: numberEnum([5, 6, 7, 8, 9]),
      multiplier: numberEnum([3, 4, 5]),
      numerator: z.number().int().min(1).max(8)
    })
    .refine(
      val => val.numerator < val.numTicks - 1 && val.numerator > 0,
      'fraction numerator must be less than numTicks and also not at end of number line'
    ),
  simpleGenerator: () => {
    const numTicks = getRandomFromArray([5, 6, 7, 8, 9] as const);

    const multiplier = getRandomFromArray([3, 4, 5] as const);

    // the fraction cannot be at the start or end of the number line
    const numerator = randomIntegerInclusive(1, numTicks - 2);

    return { numTicks, multiplier, numerator };
  },
  Component: props => {
    const {
      question: { numTicks, multiplier, numerator },
      translate
    } = props;

    // Use multiplier
    const ansNumerator = numerator * multiplier;
    const denominator = (numTicks - 1) * multiplier;

    const startingNumber = 0;
    const endNumber = 1;
    const fractionSentence = `<frac nAns='' d='${denominator}'/>`;

    // Create the tick values array
    const tickValues = filledArray('', numTicks);
    tickValues[0] = startingNumber.toLocaleString();
    tickValues[numTicks - 1] = endNumber.toLocaleString();
    tickValues[numerator] = fractionSentence;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeFractionShownOnNumberLine()}
        testCorrect={[ansNumerator.toString()]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aqj',
  description: 'aqj',
  keywords: ['Number line', 'Subtract', 'Mixed number'],
  schema: z
    .object({
      wholeNum: z.number().int().min(4).max(9),
      numerator: z.number().int().min(2).max(4),
      denominator: z.number().int().min(3).max(5)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator'),
  simpleGenerator: () => {
    const wholeNum = randomIntegerInclusive(4, 9);
    const denominator = randomIntegerInclusive(3, 5);
    const numerator = randomIntegerInclusive(2, denominator - 1);

    return { wholeNum, numerator, denominator };
  },
  Component: props => {
    const {
      question: { wholeNum, numerator, denominator },
      translate
    } = props;

    // Simplify jump fraction
    const [simplifiedNum, simplifiedDenom] = simplify(numerator, denominator);

    // Convert to fraction to use in fraction arithmetic
    const wholeNumFrac = decimalToFraction(wholeNum);

    // Calculate the tick values
    const tick1 = fractionArithmetic(wholeNumFrac, [numerator, denominator], SUB);
    const tick2 = fractionArithmetic(wholeNumFrac, [2 * numerator, denominator], SUB);
    const tick1Decimal = fractionToDecimal(...tick1);
    const tick2Decimal = fractionToDecimal(...tick2);
    const answerTick = fractionArithmetic(wholeNumFrac, [3 * numerator, denominator], SUB);

    // Convert to decimal and to mixed fraction
    const answerTickDecimal = fractionToDecimal(...answerTick);
    const [ansWhole, ansNumerator, ansDenominator] = improperFractionToMixedNumber(...answerTick);

    const startingNumber = answerTickDecimal;
    const endNumber = wholeNum;

    // Custom spacing because of the mixed fraction at the start
    const tickOffset = 0.18;
    const tickOffsetStep = 0.05;

    // Tick Array
    const tickArray = [
      {
        label: ansNumerator === 0 ? '<ans/>' : `<frac wAns='' nAns='' dAns='' />`,
        position: startingNumber + tickOffset
      },
      { label: '', position: tick2Decimal + (tickOffset - tickOffsetStep) },
      { label: '', position: tick1Decimal + (tickOffset - 2 * tickOffsetStep) },
      {
        label: endNumber.toLocaleString(),
        position: endNumber
      }
    ];

    // Jump Arrow Array
    const jumpArrowArray = [
      {
        start: endNumber,
        end: tick1Decimal + (tickOffset - 2 * tickOffsetStep),
        label: `${SUB}<frac n='${simplifiedNum}' d='${simplifiedDenom}' />`
      },
      {
        start: tick1Decimal + (tickOffset - 2 * tickOffsetStep),
        end: tick2Decimal + (tickOffset - tickOffsetStep),
        label: `${SUB}<frac n='${simplifiedNum}' d='${simplifiedDenom}' />`
      },
      {
        start: tick2Decimal + (tickOffset - tickOffsetStep),
        end: startingNumber + tickOffset,
        label: `${SUB}<frac n='${simplifiedNum}' d='${simplifiedDenom}' />`
      }
    ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.completeNumberLine()}
        testCorrect={
          ansNumerator === 0
            ? [ansWhole.toString()]
            : userAnswer => compareFractions(userAnswer, [ansWhole, ansNumerator, ansDenominator])
        }
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        subtraction
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ansWhole.toLocaleString(),
            ansNumerator.toLocaleString(),
            ansDenominator.toLocaleString()
          ],
          answerText:
            ansNumerator === 0 ? undefined : translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aqk',
  description: 'aqk',
  keywords: ['Number line', 'Fraction', 'Equivalent', 'Difference'],
  schema: z.object({
    numIntervals: numberEnum([9, 12, 15]),
    difference: z.number().min(3).max(6),
    fractionOptions: mixedFractionSchema().array().length(3),
    AorB: z.enum(['A', 'B'])
  }),
  simpleGenerator: () => {
    const numIntervals = getRandomFromArray([9, 12, 15] as const);
    const AorB = getRandomFromArray(['A', 'B'] as const);

    // All of the numbers/calculations are pre-set to follow the following conditions:
    // - difference always divides numIntervals, hence each tick is worth a "nice" fraction
    // - A is always positioned at -4 ticks i.e. if tick size is 1/3, A is -4/3
    // - 3 options to choose from: A, B and 1 wrong answer
    let difference;
    let option1: MixedFraction, option2: MixedFraction, option3: MixedFraction;

    switch (numIntervals) {
      case 9:
        difference = 3;
        option1 = [-1, 1, 3];
        option2 = [1, 2, 3];
        option3 = getRandomFromArray([
          [-1, 0, 1],
          [1, 0, 1],
          [1, 1, 3],
          [0, -2, 3]
        ]) as MixedFraction;
        break;

      case 12:
        difference = getRandomFromArray([4, 6] as const);
        // Different numbers depending on what `difference` is
        switch (difference) {
          case 4:
            option1 = [-1, 1, 3];
            option2 = [2, 2, 3];
            option3 = getRandomFromArray([
              [-1, 0, 1],
              [1, 0, 1],
              [1, 1, 3],
              [0, -2, 3]
            ]) as MixedFraction;
            break;

          case 6:
            option1 = [-2, 0, 1];
            option2 = [4, 0, 1];
            option3 = getRandomFromArray([
              [-1, 1, 2],
              [1, 2, 3],
              [3, 1, 2],
              [0, -1, 2],
              [2, 0, 1]
            ]) as MixedFraction;
            break;
        }
        break;

      case 15:
        difference = getRandomFromArray([3, 5] as const);
        // Different numbers depending on what `difference` is
        switch (difference) {
          case 3:
            option1 = [0, -4, 5];
            option2 = [2, 1, 5];
            option3 = getRandomFromArray([
              [2, 0, 1],
              [0, -3, 5],
              [0, -1, 5],
              [1, 0, 1],
              [1, 4, 5]
            ]) as MixedFraction;
            break;

          case 5:
            option1 = [-1, 1, 3];
            option2 = [3, 2, 3];
            option3 = getRandomFromArray([
              [-1, 0, 1],
              [0, -2, 3],
              [1, 0, 1],
              [2, 0, 1],
              [3, 0, 1],
              [2, 2, 3],
              [3, 1, 3]
            ]) as MixedFraction;
            break;
        }
        break;
    }

    const fractionOptions = [option1, option2, option3];

    return {
      numIntervals,
      AorB,
      difference,
      fractionOptions
    };
  },
  Component: props => {
    const {
      question: { numIntervals, AorB, difference, fractionOptions },
      translate,
      displayMode
    } = props;

    // Option1 = A
    // Option2 = B
    // Option3 always wrong
    const [option1, option2, option3] = fractionOptions;

    // Number Line
    const tickValues = filledArray('', numIntervals + 1);
    tickValues[0] = 'A';
    tickValues[4] = '0';
    tickValues[numIntervals] = 'B';
    const aValue = -4;
    const bValue = numIntervals - 4;

    // Statements to select
    const A = {
      sentence: mixedFractionToMarkup(option1),
      value: 'A'
    };
    const B = {
      sentence: mixedFractionToMarkup(option2),
      value: 'B'
    };
    const C = {
      sentence: mixedFractionToMarkup(option3),
      value: 'C'
    };
    const D = {
      sentence:
        AorB === 'A' ? parseToSUB(aValue.toLocaleString()) : parseToSUB(bValue.toLocaleString()),
      value: 'D'
    };

    const statements = shuffle([A, B, C, D], { random: seededRandom(props.question) });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.whatAreValuesOfAandB(difference.toLocaleString(), AorB)}
        testCorrect={[AorB]}
        numItems={4}
        Content={({ dimens }) => (
          <NumberLine tickValues={tickValues} dimens={dimens} customFontSize={32} />
        )}
        renderItems={statements.map(({ sentence, value }) => ({
          component: (
            <TextStructure
              sentence={sentence}
              fractionTextStyle={{
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textStyle={{ fontWeight: '700', fontSize: displayMode === 'digital' ? 40 : 50 }}
            />
          ),
          value
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsOnANumberLine',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
