// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import LineGraphs from './1LineGraphs';
import DualBarCharts from './2DualBarCharts';
import ReadAndInterpretPieCharts from './3ReadAndInterpretPieCharts';
import PieChartsWithPercentages from './4PieChartsWithPercentages';
import DrawPieCharts from './5DrawPieCharts';
import TheMean from './6TheMean';

const Block = newBlockContent({
  block: 'Statistics',

  weeks: [11, 12],
  smallSteps: [
    LineGraphs, // Step 1
    DualBarCharts, // Step 2
    ReadAndInterpretPieCharts, // Step 3
    PieChartsWithPercentages, // Step 4
    DrawPieCharts, // Step 5
    TheMean // Step 6
  ]
});
export default Block;
