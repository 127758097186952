import { newBlockContent } from '../../../Block';

import CountFrom20To50 from './1CountFrom20To50';
import TwentyThirtyFortyAndFifty from './2TwentyThirtyFortyAndFifty';
import CountByMakingGroupsOfTens from './3CountByMakingGroupsOfTens';
import GroupsOfTensAndOnes from './4GroupsOfTensAndOnes';
import PartitionIntoTensAndOnes from './5PartitionIntoTensAndOnes';
import TheNumberLineTo50 from './6TheNumberLineTo50';
import EstimateOnANumberLineTo50 from './7EstimateOnANumberLineTo50';
import OneMoreOneLess from './8OneMoreOneLess';

const Block = newBlockContent({
  block: 'PlaceValueWithin50',
  field: 'Number',
  weeks: [7, 8],
  smallSteps: [
    CountFrom20To50, // Step 1
    TwentyThirtyFortyAndFifty, // Step 2
    CountByMakingGroupsOfTens, // Step 3
    GroupsOfTensAndOnes, // Step 4
    PartitionIntoTensAndOnes, // Step 5
    TheNumberLineTo50, // Step 6
    EstimateOnANumberLineTo50, // Step 7
    OneMoreOneLess // Step 8
  ]
});
export default Block;
