import { GridContext } from './Grid';
import { Platform, View, StyleProp, TextStyle } from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue
} from 'react-native-reanimated';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { SetState } from '../../../../utils/react';
import { useContext, useMemo } from 'react';
import { withStateHOC } from '../../../../stateTree';
import {
  AssetSvg,
  getSvgInfo,
  type SvgNameCustomizable,
  type SvgNameFixed
} from '../../../../assets/svg';
import { ScaleFactorContext } from '../../../../theme/scaling';
import Text from '../../../typography/Text';
import { DisplayMode } from '../../../../contexts/displayMode';
import { type SvgProps } from 'react-native-svg';

export const MIN_TOUCHABLE_AREA = 54;

export default function GridImage({
  mathCoord,
  setMathCoord,
  snapToGrid,
  anchorDX,
  anchorDY,
  label,
  item: itemProp,
  gridMaxOffset = 0
}: {
  mathCoord: [number, number];
  setMathCoord?: SetState<[number, number]>;
  snapToGrid?: boolean;
  /**
   * Information to render the item.
   *
   * For asset SVGs, you can use a string "component".
   * Note that you can only provide the svgProps field if the SVG name corresponds to a customizable SVG asset.
   *
   * As a fallback, you can just pass an arbitrary JSX Element, however in that case you need to also provide its
   * width and height.
   */
  item:
    | {
        component: SvgNameCustomizable;
        /** Defaults to the natural width */
        width?: number;
        /** Defaults to the natural height */
        height?: number;
        svgProps?: SvgProps;
      }
    | {
        component: SvgNameFixed;
        /** Defaults to the natural width */
        width?: number;
        /** Defaults to the natural height */
        height?: number;
      }
    | {
        component: JSX.Element;
        width: number;
        height: number;
      };
  /** Label for each point */
  label?:
    | string
    | {
        text: string;
        styles?: StyleProp<TextStyle>;
      };
  /** Defaults to the middle */
  anchorDX?: number;
  anchorDY?: number;
  gridMaxOffset?: number;
}) {
  const displayMode = useContext(DisplayMode);
  let item: {
    component: JSX.Element;
    width: number;
    height: number;
  } = (() => {
    if (typeof itemProp.component === 'string') {
      // SVG name
      const svgInfo = getSvgInfo(itemProp.component);
      const width = itemProp.width ?? svgInfo.width;
      const height = itemProp.height ?? svgInfo.height;

      return {
        component:
          'svgProps' in itemProp ? (
            <AssetSvg
              name={itemProp.component}
              width={width}
              height={height}
              svgProps={itemProp.svgProps}
            />
          ) : (
            <AssetSvg name={itemProp.component} width={width} height={height} />
          ),
        width,
        height
      };
    } else {
      // Any other react component
      itemProp = itemProp as {
        component: JSX.Element;
        width: number;
        height: number;
      };
      return { ...itemProp };
    }
  })();

  // Add the label if necessary
  item = {
    ...item,
    component: label ? (
      <View style={{ position: 'relative', height: item.height, justifyContent: 'center' }}>
        <Text
          style={[
            {
              position: 'absolute',
              alignSelf: 'center',
              color: 'white',
              fontSize: displayMode === 'digital' ? 24 : 40,
              fontWeight: '700',
              zIndex: 1
            },
            typeof label === 'string' ? null : label.styles
          ]}
        >
          {typeof label === 'string' ? label : label.text}
        </Text>
        {item.component}
      </View>
    ) : (
      item.component
    )
  };

  const scaleFactor = useContext(ScaleFactorContext);
  const {
    mathToSvgX,
    mathToSvgY,
    svgToMathX,
    svgToMathY,
    xMin,
    xMax,
    xStepSize,
    yMin,
    yMax,
    yStepSize
  } = useContext(GridContext);

  const animatedMathCoord = useSharedValue(mathCoord);
  // Animated version of the SVG coords
  const derivedSvgCoord = useDerivedValue(
    () =>
      [mathToSvgX(animatedMathCoord.value[0]), mathToSvgY(animatedMathCoord.value[1])] as [
        number,
        number
      ],
    [animatedMathCoord, mathToSvgX, mathToSvgY]
  );

  const beginPagePosition = useSharedValue<[number, number] | null>(null);
  const beginSvgCoord = useSharedValue<[number, number] | null>(null);
  const updateParent = setMathCoord !== undefined;

  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginPagePosition.value = [event.absoluteX, event.absoluteY];
          beginSvgCoord.value = derivedSvgCoord.value;
        })
        .onUpdate(event => {
          const translation = [
            (event.absoluteX - beginPagePosition.value![0]) / scaleFactor,
            (event.absoluteY - beginPagePosition.value![1]) / scaleFactor
          ];

          const currentSvgCoord = [
            beginSvgCoord.value![0] + translation[0],
            beginSvgCoord.value![1] + translation[1]
          ];

          // Clamp to stay in the grid
          animatedMathCoord.value = [
            Math.min(Math.max(svgToMathX(currentSvgCoord[0]), xMin), xMax - gridMaxOffset),
            Math.min(Math.max(svgToMathY(currentSvgCoord[1]), yMin), yMax - gridMaxOffset)
          ];
        })
        .onFinalize(() => {
          // Snap to the grid
          if (snapToGrid) {
            const snappedMathCoord = [
              Math.round((animatedMathCoord.value[0] - xMin) / xStepSize) * xStepSize + xMin,
              Math.round((animatedMathCoord.value[1] - yMin) / yStepSize) * yStepSize + yMin
            ] as [number, number];
            animatedMathCoord.value = snappedMathCoord;
          }

          updateParent && runOnJS(setMathCoord)(animatedMathCoord.value);
        }),
    [
      animatedMathCoord,
      beginPagePosition,
      beginSvgCoord,
      derivedSvgCoord.value,
      gridMaxOffset,
      scaleFactor,
      setMathCoord,
      snapToGrid,
      svgToMathX,
      svgToMathY,
      updateParent,
      xMax,
      xMin,
      xStepSize,
      yMax,
      yMin,
      yStepSize
    ]
  );

  anchorDX = anchorDX ?? item.width / 2;
  anchorDY = anchorDY ?? item.height / 2;

  const animatedStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      left: derivedSvgCoord.value[0] - (anchorDX ?? 0),
      top: derivedSvgCoord.value[1] - (anchorDY ?? 0)
    };
  }, [anchorDX, anchorDY, derivedSvgCoord]);

  const horizontalHitSlop = Math.max(0, MIN_TOUCHABLE_AREA / scaleFactor - item.width);
  const verticalHitSlop = Math.max(0, MIN_TOUCHABLE_AREA / scaleFactor - item.height);
  const hitSlop = {
    left: horizontalHitSlop / 2,
    right: horizontalHitSlop / 2,
    top: verticalHitSlop / 2,
    bottom: verticalHitSlop / 2
  };

  return (
    <GestureDetector
      gesture={(Platform.OS === 'android'
        ? // On android, hit slop can be passed in to the gesture. This is documented to only work on Android.
          panGesture.hitSlop(hitSlop)
        : panGesture
      ).enabled(setMathCoord !== undefined)}
    >
      <Animated.View
        style={animatedStyle}
        // On iOS, hit slop can be passed in as a prop. This doesn't seem to work for Android or web.
        {...(Platform.OS === 'ios' && {
          hitSlop: hitSlop
        })}
      >
        {item.component}
      </Animated.View>
    </GestureDetector>
  );
}

export const GridImageWithState = withStateHOC(GridImage, {
  stateProp: 'mathCoord',
  setStateProp: 'setMathCoord'
});
