import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { randomIntegerInclusive } from '../../../../utils/random';
import { z } from 'zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { MULT } from '../../../../constants';
import { amM } from '../../../Year 5/Autumn/MultiplicationAndDivisionA/5PrimeNumbers';
import { amK } from '../../../Year 5/Autumn/MultiplicationAndDivisionA/5PrimeNumbers';
import { amO } from '../../../Year 5/Autumn/MultiplicationAndDivisionA/5PrimeNumbers';
import { amL } from '../../../Year 5/Autumn/MultiplicationAndDivisionA/5PrimeNumbers';
import { amN } from '../../../Year 5/Autumn/MultiplicationAndDivisionA/5PrimeNumbers';

////
// Questions
////

const Question1 = { ...amK, uid: 'anB', description: 'anB' as const };

const Question2 = newQuestionContent({
  uid: 'anC',
  description: 'anC',
  keywords: ['Prime', 'Array'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const number2 = randomIntegerInclusive(2, 6);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 * number2;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.tinySaysNumIsAPrimeNumber(number3)}
        testCorrect={answer =>
          answer[0] !== '1' &&
          answer[1] !== '1' &&
          // Need to parseInt both answers to multiply them together
          (parseInt(answer[0]) * parseInt(answer[1])).toString() === number3.toString()
        }
        inputMaxCharacters={2}
        sentence={`${number3} = <ans/> ${MULT} <ans/>`}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationThatMultiplyToX(number3)
        }}
      />
    );
  }
});

const Question3 = { ...amL, uid: 'anD', description: 'anD' as const };

const Question4 = { ...amM, uid: 'anE', description: 'anE' as const };

const Question5 = { ...amN, uid: 'anF', description: 'anF' as const };

const Question6 = { ...amO, uid: 'anG', description: 'anG' as const };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PrimesTo100',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
