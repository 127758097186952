import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import {
  numberToBase10Object,
  roundToTheNearest,
  ScientificNotation
} from '../../../../utils/math';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import QF6DragMatchStatements, {
  toNumberWithString
} from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'acu',
  description: 'acu',
  keywords: ['Place value', 'Compare', '10,000', 'Base 10'],
  questionHeight: 900,
  schema: z.object({
    numbers: z.number().int().min(1).max(9999).array().length(2).refine(arrayHasNoDuplicates),
    lookingForLarger: z.boolean()
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 9999, 2);
    const lookingForLarger = getRandomFromArray([true, false] as const);
    return { numbers, lookingForLarger };
  },
  Component: ({ question: { numbers, lookingForLarger }, translate, displayMode }) => {
    const greaterOrSmallerTitle = lookingForLarger
      ? translate.instructions.selectNumberThatIsGreater()
      : translate.instructions.selectNumberThatIsSmaller();

    const greaterOrSmallerTitlePdf = lookingForLarger
      ? translate.instructions.circleNumberThatIsGreater()
      : translate.instructions.circleNumberThatIsSmaller();

    const isDigital = displayMode === 'digital';

    return (
      <QF11SelectImagesUpTo4
        title={greaterOrSmallerTitle}
        pdfTitle={greaterOrSmallerTitlePdf}
        testCorrect={[lookingForLarger ? Math.max(...numbers) : Math.min(...numbers)]}
        numItems={numbers.length as 2 | 3 | 4}
        questionHeight={900}
        renderItems={({ dimens: { width, height } }) => {
          const numbersWithBase10 = numbers.map(
            number => [number, numberToBase10Object(number)] as const
          );

          return numbersWithBase10.map(([number, base10]) => ({
            value: number,
            component: (
              <View style={{ flexDirection: 'row', width: width - 20, height: height - 20 }}>
                <View style={{ flexDirection: 'row' }}>
                  {base10.thousands ? (
                    <View
                      style={{
                        width:
                          base10.thousands === 1 ? (isDigital ? 90 : 130) : isDigital ? 180 : 250,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        height: (isDigital ? 90 : 140) * (base10.thousands * 0.5)
                      }}
                    >
                      {countRange(base10.thousands as number).map(num => (
                        <AssetSvg
                          key={num}
                          name="Place_value/1000cube"
                          width={isDigital ? 90 : 125}
                          height={isDigital ? 90 : 125}
                        />
                      ))}
                    </View>
                  ) : null}
                  {base10.hundreds ? (
                    <View
                      style={{
                        width:
                          base10.hundreds === 1 ? (isDigital ? 88 : 130) : isDigital ? 170 : 250,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        height: (isDigital ? 88 : 125) * Math.ceil(base10.hundreds * 0.5),
                        rowGap: 5
                      }}
                    >
                      {countRange(base10.hundreds as number).map(num => (
                        <AssetSvg
                          key={num}
                          name="Place_value/100cube"
                          width={isDigital ? 85 : 120}
                          height={isDigital ? 85 : 120}
                        />
                      ))}
                    </View>
                  ) : null}
                  {base10.tens ? (
                    <SimpleBaseTenWithCrossOut
                      dimens={{ height: isDigital ? 300 : 600, width: isDigital ? 100 : 200 }}
                      tens={base10.tens}
                      scale={isDigital ? 0.1 : 0.143}
                      containerStyle={{ bottom: 5, left: 2 }}
                    />
                  ) : null}
                  {base10.ones ? (
                    <SimpleBaseTenWithCrossOut
                      dimens={{ height: isDigital ? 300 : 600, width: isDigital ? 100 : 200 }}
                      ones={base10.ones}
                      scale={isDigital ? 0.098 : 0.14}
                      containerStyle={{ bottom: 4 }}
                    />
                  ) : null}
                </View>
              </View>
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'acv',
  description: 'acv',
  keywords: ['Place value', 'Compare', '10,000', 'Counters'],
  schema: z.object({
    numbers: z.number().int().min(1).max(9999).array().length(2).refine(arrayHasNoDuplicates),
    lookingForLarger: z.boolean()
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 9999, 2);
    const lookingForLarger = getRandomFromArray([true, false] as const);
    return { numbers, lookingForLarger };
  },
  Component: ({ question: { numbers, lookingForLarger }, translate }) => {
    const greaterOrSmallerTitle = lookingForLarger
      ? translate.instructions.selectNumberThatIsGreater()
      : translate.instructions.selectNumberThatIsSmaller();

    const greaterOrSmallerTitlePdf = lookingForLarger
      ? translate.instructions.circleNumberThatIsGreater()
      : translate.instructions.circleNumberThatIsSmaller();

    const variant = 'Counters';

    return (
      <QF11SelectImagesUpTo4
        title={greaterOrSmallerTitle}
        pdfTitle={greaterOrSmallerTitlePdf}
        testCorrect={[lookingForLarger ? Math.max(...numbers) : Math.min(...numbers)]}
        numItems={numbers.length as 2 | 3 | 4}
        renderItems={({ dimens: { width, height } }) => {
          const numbersWithBase10 = numbers.map(
            number => [number, numberToBase10Object(number)] as const
          );
          const scale = Math.min(
            ...numbersWithBase10.map(
              ([_number, base10]) =>
                BaseTenRepCalcGridsAndScale(width, height, base10, variant, 2).scale
            )
          );

          return numbersWithBase10.map(([number, base10]) => ({
            value: number,
            component: (
              <BaseTenRepresentation
                b10Rep={{ variant: variant, numbers: base10, arrangement: 'ltr' }}
                usableWidth={width}
                usableHeight={height - 20}
                scale={scale}
                numCols={2}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'acw',
  description: 'acw',
  keywords: ['Place value', 'Compare', '10,000'],
  schema: z.object({
    comparitor: z.number().int().multipleOf(100).min(2000).max(8000),
    numbers: z.number().int().min(1).max(9999).array().length(6).refine(arrayHasNoDuplicates),
    lookingForLarger: z.boolean()
  }),
  simpleGenerator: () => {
    const comparitor = randomIntegerInclusiveStep(2000, 8000, 100);

    const pickedNumbers: number[] = [];
    const pickNumber = (sample: () => number) =>
      pickedNumbers.push(rejectionSample(sample, x => !pickedNumbers.includes(x)));

    // 3 numbers < target - includes one number within 100
    pickNumber(() => randomIntegerInclusive(comparitor - 100, comparitor - 1));
    pickNumber(() => randomIntegerInclusive(1, comparitor - 1));
    pickNumber(() => randomIntegerInclusive(1, comparitor - 1));

    // 3 numbers > target - includes one multiple of 500 and one number within 100
    pickNumber(() =>
      randomIntegerInclusiveStep(roundToTheNearest(comparitor + 1, 500, 'up'), 9500, 500)
    );
    pickNumber(() => randomIntegerInclusive(comparitor + 1, comparitor + 100));
    pickNumber(() => randomIntegerInclusive(comparitor + 1, 9999));

    return {
      comparitor,
      numbers: shuffle(pickedNumbers),
      lookingForLarger: getRandomFromArray([true, false] as const)
    };
  },
  Component: ({ question: { comparitor, numbers, lookingForLarger }, translate }) => (
    <QF10SelectNumbers
      title={
        lookingForLarger
          ? translate.instructions.selectAllNumbersGreaterThanNum(comparitor.toLocaleString())
          : translate.instructions.selectAllNumbersLessThanNum(comparitor.toLocaleString())
      }
      pdfTitle={
        lookingForLarger
          ? translate.instructions.circleAllNumbersGreaterThanNum(comparitor.toLocaleString())
          : translate.instructions.circleAllNumbersLessThanNum(comparitor.toLocaleString())
      }
      testCorrect={numbers.filter(it => (lookingForLarger ? it > comparitor : it < comparitor))}
      items={numbers.map(number => ({
        value: number,
        component: number.toLocaleString()
      }))}
      multiSelect
      questionHeight={800}
    />
  ),
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'acx',
  description: 'acx',
  keywords: ['Place value', 'Compare', '10,000', 'Inequality'],
  schema: z.object({
    numbers: z
      .tuple([z.number().int().min(500).max(9999), z.number().int().min(500).max(9999)])
      .refine(([lhs, rhs]) => lhs !== rhs, 'numbers must not be equal')
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    // Any two distinct numbers
    return { numbers: randomUniqueIntegersInclusive(500, 9999, 2) as [number, number] };
  },
  Component: ({ question: { numbers }, displayMode, translate }) => {
    const [lhs, rhs] = numbers;
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardMakeStatementCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementCorrect()}
        items={['<', '>']}
        questionHeight={1000}
        itemVariant="square"
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
        statements={[
          {
            correctAnswer: lhs > rhs ? '>' : '<',
            lhsComponent: (
              <MeasureView>
                {dimens => (
                  <PlaceValueChart
                    number={ScientificNotation.fromNumber(lhs)}
                    columnsToShow={[3, 2, 1, 0]}
                    counterVariant="greyCounter"
                    headerVariant="shortName"
                    counterSize={displayMode === 'digital' ? undefined : 60}
                    dimens={{ width: dimens.width, height: dimens.height * 0.7 }}
                  />
                )}
              </MeasureView>
            ),
            rhsComponent: (
              <MeasureView>
                {dimens => (
                  <PlaceValueChart
                    number={ScientificNotation.fromNumber(rhs)}
                    columnsToShow={[3, 2, 1, 0]}
                    counterVariant="greyCounter"
                    headerVariant="shortName"
                    counterSize={displayMode === 'digital' ? undefined : 60}
                    dimens={{ width: dimens.width, height: dimens.height * 0.7 }}
                  />
                )}
              </MeasureView>
            )
          }
        ]}
      />
    );
  }
});

const q5and6Generator = () => {
  // For the first pair, their thousands column is the same.
  const aThousands = randomIntegerInclusive(1, 9) * 1000;
  const aRest = randomUniqueIntegersInclusive(0, 999, 2);
  const a0 = aThousands + aRest[0];
  const a1 = aThousands + aRest[1];

  // For the second pair, the larger is a multiple of 1000.
  const b0 = randomIntegerInclusive(1, 9) * 1000;
  const b1 = randomIntegerInclusive(10, b0 - 1);

  // For the third pair, their tens, hundreds and thousands columns are the same.
  const cThousandsHundredsAndTens = randomIntegerInclusive(100, 999) * 10;
  const cRest = randomUniqueIntegersInclusive(0, 9, 2);
  const c0 = cThousandsHundredsAndTens + cRest[0];
  const c1 = cThousandsHundredsAndTens + cRest[1];

  // For the fourth pair, one number is <1000, but its first digit is larger than the other number's first digit.
  const dFirstDigit = randomIntegerInclusive(2, 9);
  const d0 = dFirstDigit * 100 + randomIntegerInclusive(0, 99);
  const d1 = randomIntegerInclusive(1000, dFirstDigit * 1000 - 1);

  // Shuffle everything and return
  return {
    sentences: shuffle([
      shuffle([a0, a1]) as [number, number],
      shuffle([b0, b1]) as [number, number],
      shuffle([c0, c1]) as [number, number],
      shuffle([d0, d1]) as [number, number]
    ])
  };
};

const Question5 = newQuestionContent({
  uid: 'acy',
  description: 'acy',
  keywords: ['Place value', 'Compare', '10,000'],
  questionHeight: 950,
  schema: z.object({
    sentences: z
      .tuple([z.number().int().min(10).max(9999), z.number().int().min(10).max(9999)])
      .refine(([lhs, rhs]) => lhs !== rhs, 'numbers in a sentence must not be equal')
      .array()
      .length(4)
  }),
  simpleGenerator: q5and6Generator,
  Component: ({ question: { sentences: sentencesProp }, displayMode, translate }) => {
    const ltString = translate.operations['lessThan']();
    const gtString = translate.operations['greaterThan']();

    const items = ['>', '<'] as const;

    // Numbers in sentences might have been given as just numbers, or numbers with strings to display. Convert them all
    // to the latter format.
    const sentences = sentencesProp.map(
      ([lhs, rhs]) => [toNumberWithString(lhs), toNumberWithString(rhs)] as const
    );

    return (
      <QF6DragMatchStatements
        moveOrCopy="copy"
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        useRedLinesOnMarkScheme={false}
        title={translate.instructions.dragCardsToCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentences()}
        items={items.map(item => {
          return {
            value: item,
            component: item === '>' ? gtString : ltString
          };
        })}
        statementStyle={{ justifyContent: 'center' }}
        statements={sentences.map(([lhs, rhs]) => {
          return {
            correctAnswer: lhs.value > rhs.value ? '>' : lhs.value < rhs.value ? '<' : '=',
            lhsComponent: (
              <Text
                variant="WRN400"
                style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'right' }}
              >
                {translate.answerSentences.numIs(lhs.string)}
              </Text>
            ),
            rhsComponent: (
              <Text
                variant="WRN400"
                style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'left' }}
              >
                {rhs.string}
              </Text>
            )
          };
        })}
        questionHeight={950}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'acz',
  description: 'acz',
  keywords: ['Place value', 'Compare', '10,000', 'Inequality'],
  schema: z.object({
    sentences: z
      .tuple([z.number().int().min(10).max(9999), z.number().int().min(10).max(9999)])
      .refine(([lhs, rhs]) => lhs !== rhs, 'numbers in a sentence must not be equal')
      .array()
      .length(4)
  }),
  questionHeight: 1000,
  simpleGenerator: q5and6Generator,
  Component: ({ question: { sentences: sentencesProp }, displayMode, translate }) => {
    const items = ['>', '<'] as const;

    // Numbers in sentences might have been given as just numbers, or numbers with strings to display. Convert them all
    // to the latter format.
    const sentences = sentencesProp.map(
      ([lhs, rhs]) => [toNumberWithString(lhs), toNumberWithString(rhs)] as const
    );

    return (
      <QF6DragMatchStatements
        moveOrCopy="copy"
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        useRedLinesOnMarkScheme={false}
        title={translate.instructions.dragCardsMakeStatementsCorrect()}
        pdfTitle={translate.instructions.useGreaterThanAndLessThanToCompleteStatements()}
        items={items.map(item => {
          return {
            value: item,
            component: item
          };
        })}
        statementStyle={{ justifyContent: 'center' }}
        statements={sentences.map(([lhs, rhs]) => {
          return {
            correctAnswer: lhs.value > rhs.value ? '>' : lhs.value < rhs.value ? '<' : '=',
            lhsComponent: (
              <Text
                variant="WRN400"
                style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'right' }}
              >
                {lhs.string}
              </Text>
            ),
            rhsComponent: (
              <Text
                variant="WRN400"
                style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'left' }}
              >
                {rhs.string}
              </Text>
            )
          };
        })}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareNumbersTo10000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
