import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import {
  countRange,
  filledArray,
  nestedArraysHaveSameContentsUnordered,
  sumNumberArray
} from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';

import { isEqual, isInRange } from '../../../../utils/matchers';
import { Dimens } from '../../../../theme/scaling';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baT',
  description: 'baT',
  keywords: ['Parts', 'Group', 'Counters', 'Cubes'],
  questionHeight: 900,
  schema: z.object({
    groupedBy: z.enum(['colour', 'shape', 'both']),
    shapeA: z
      .array(
        z.enum([
          'cube_blue',
          'cube_red',
          'cube_yellow',
          'cube_green',
          'circle_blue',
          'circle_red',
          'circle_yellow',
          'circle_green'
        ])
      )
      .min(1)
      .max(7),
    shapeB: z
      .array(
        z.enum([
          'cube_blue',
          'cube_red',
          'cube_yellow',
          'cube_green',
          'circle_blue',
          'circle_red',
          'circle_yellow',
          'circle_green'
        ])
      )
      .min(1)
      .max(7),
    colourA: z
      .array(
        z.enum([
          'cube_blue',
          'cube_red',
          'cube_yellow',
          'cube_green',
          'circle_blue',
          'circle_red',
          'circle_yellow',
          'circle_green'
        ])
      )
      .min(1)
      .max(7),
    colourB: z
      .array(
        z.enum([
          'cube_blue',
          'cube_red',
          'cube_yellow',
          'cube_green',
          'circle_blue',
          'circle_red',
          'circle_yellow',
          'circle_green'
        ])
      )
      .min(1)
      .max(7)
  }),
  simpleGenerator: () => {
    const groupedBy = getRandomFromArrayWithWeights(
      ['colour', 'shape', 'both'] as const,
      [3, 3, 14]
    );

    const colourOptions = ['blue', 'red', 'yellow', 'green'];
    const [colourAChoice, colourBChoice] =
      groupedBy === 'shape'
        ? filledArray(getRandomFromArray(colourOptions), 2)
        : getRandomSubArrayFromArray(colourOptions, 2);

    const [shapeAChoice, shapeBChoice] =
      groupedBy === 'colour'
        ? filledArray(getRandomFromArray(['cube', 'circle']), 2)
        : (['cube', 'circle'] as const);

    const numCards = randomIntegerInclusive(6, 9);

    const numShapeA = randomIntegerInclusive(3, numCards - 3);
    const numShapeB = numCards - numShapeA;

    // Used the 1s and 2s here so there is atleast 3 of a colour
    const numShapeAColourA = randomIntegerInclusive(2, numShapeA - 1);
    const numShapeAColourB = numShapeA - numShapeAColourA;
    const numShapeBColourA = randomIntegerInclusive(1, numShapeB - 2);
    const numShapeBColourB = numShapeB - numShapeBColourA;

    const shapeAColourA = new Array(numShapeAColourA).fill(`${shapeAChoice}_${colourAChoice}`);
    const shapeAColourB = new Array(numShapeAColourB).fill(`${shapeAChoice}_${colourBChoice}`);
    const shapeBColourA = new Array(numShapeBColourA).fill(`${shapeBChoice}_${colourAChoice}`);
    const shapeBColourB = new Array(numShapeBColourB).fill(`${shapeBChoice}_${colourBChoice}`);

    const shapeA = [...shapeAColourA, ...shapeAColourB];
    const shapeB = [...shapeBColourA, ...shapeBColourB];
    const colourA = [...shapeAColourA, ...shapeBColourA];
    const colourB = [...shapeAColourB, ...shapeBColourB];

    return { groupedBy, shapeA, shapeB, colourA, colourB };
  },
  Component: ({ question, translate, displayMode }) => {
    const { shapeA, shapeB, colourA, colourB, groupedBy } = question;
    const correctOrderByShape = [shapeA, shapeB];
    const correctOrderByColour = [colourA, colourB];
    const items = shuffle([...shapeA, ...shapeB], {
      random: seededRandom(question)
    });

    const shapeColourSvgPaths = {
      cube_blue: 'Cubes_blank/Coloured_cube_unlabelled_blue',
      cube_red: 'Cubes_blank/Coloured_cube_unlabelled_red',
      cube_green: 'Cubes_blank/Coloured_cube_unlabelled_green',
      cube_yellow: 'Cubes_blank/Coloured_cube_unlabelled_yellow',
      circle_blue: 'Circles/circle_blue',
      circle_red: 'Circles/circle_red',
      circle_green: 'Circles/circle_green',
      circle_yellow: 'Circles/circle_yellow'
    } as const;

    return (
      <QF8DragIntoUpTo3Groups
        questionHeight={900}
        title={translate.ks1Instructions.dragTheCardsToSortTheObjectsIntoTwoGroups()}
        pdfTitle={translate.ks1PDFInstructions.drawLinesToSortTheObjectsIntoTwoGroups()}
        zoneNames={[
          translate.ks1Instructions.groupN({ numberA: 1 }),
          translate.ks1Instructions.groupN({ numberA: 2 })
        ]}
        testCorrect={userAnswer => {
          return groupedBy === 'both'
            ? nestedArraysHaveSameContentsUnordered(userAnswer, correctOrderByShape) ||
                nestedArraysHaveSameContentsUnordered(userAnswer, correctOrderByColour)
            : groupedBy === 'shape'
            ? nestedArraysHaveSameContentsUnordered(userAnswer, correctOrderByShape)
            : nestedArraysHaveSameContentsUnordered(userAnswer, correctOrderByColour);
        }}
        items={items.map((val, index) => ({
          value: val,
          component: (
            <AssetSvg
              key={index}
              name={shapeColourSvgPaths[val]}
              width={displayMode === 'digital' ? 80 : 120}
              height={displayMode === 'digital' ? 80 : 120}
            />
          )
        }))}
        actionPanelVariant="endWide"
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        pdfLayout="itemsBottom"
        itemsMaxLines={3}
        itemsLetterEmWidth={0.6}
        customMarkSchemeAnswer={{
          answerToDisplay: groupedBy === 'colour' ? correctOrderByColour : correctOrderByShape,
          answerText:
            groupedBy === 'both'
              ? translate.markScheme.canBeSortedByColourOrShape()
              : translate.markScheme.acceptAnyOrder()
        }}
        zoneCapacity={8}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'baU',
  description: 'baU',
  keywords: ['Parts', 'Counters', 'Cubes'],
  schema: z
    .object({
      partA: z.number().int().min(1).max(9),
      partB: z.number().int().min(1).max(9),
      object: z.enum(['counter', 'cube']),
      colors: z.array(z.enum(['red', 'green', 'blue', 'yellow', 'orange', 'purple'])).length(2)
    })
    .refine(({ partA, partB }) => isInRange(3, 10)(partA + partB)),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(1, 9);
    const partB = randomIntegerInclusive(1, 9, {
      constraint: x => isInRange(3, 10)(partA + x)
    });

    const object = getRandomFromArray(['counter', 'cube'] as const);
    const colorOptions =
      object === 'cube'
        ? (['red', 'green', 'blue', 'yellow', 'orange', 'purple'] as const)
        : (['red', 'green', 'blue', 'yellow'] as const);
    const colors = getRandomSubArrayFromArray(colorOptions, 2);

    return { partA, partB, object, colors };
  },
  Component: props => {
    const {
      question: { partA, partB, object, colors },
      translate
    } = props;

    const partASvg =
      object === 'cube'
        ? (`Cubes_blank/Coloured_cube_unlabelled_${colors[0]}` as const)
        : (`Circles/circle_${colors[0]}` as const);
    const partBSvg =
      object === 'cube'
        ? (`Cubes_blank/Coloured_cube_unlabelled_${colors[1]}` as const)
        : (`Circles/circle_${colors[1]}` as const);

    const array = ({ width, height }: { width: number; height: number }) =>
      shuffle(
        [
          ...countRange(partA).map((_, i) => (
            <AssetSvg
              key={`${partASvg}_${i}`}
              name={partASvg}
              height={height * 0.4}
              width={width * 0.2}
            />
          )),
          ...countRange(partB).map((_, i) => (
            <AssetSvg
              key={`${partBSvg}_${i}`}
              name={partBSvg}
              height={height * 0.4}
              width={width * 0.2}
            />
          ))
        ],
        { random: seededRandom(props.question) }
      );

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.whatAreTheParts()}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => {
          return (
            <View
              style={{
                ...dimens,
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              {array(dimens)}
            </View>
          );
        }}
        sentences={[
          translate.ks1AnswerSentences.ansIsAPart(),
          translate.ks1AnswerSentences.ansIsAPart()
        ]}
        testCorrect={([[userPartA], [userPartB]]) =>
          isEqual(sumNumberArray([parseInt(userPartA), parseInt(userPartB)]))(partA + partB)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [[partA.toLocaleString()], [partB.toLocaleString()]],
          answerText: translate.markScheme.acceptAnyPairOfNumbersThatSumToX(partA + partB)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'baV',
  description: 'baV',
  keywords: ['Parts', 'Counters', 'Cubes'],
  questionHeight: 1000,
  schema: z
    .object({
      partA: z.number().int().min(1).max(9),
      partB: z.number().int().min(1).max(9),
      colors: z.array(z.enum(['red', 'green', 'blue', 'yellow'])).length(2),
      shuffleParts: z.boolean()
    })
    .refine(({ partA, partB }) => isInRange(3, 10)(partA + partB)),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(1, 9);
    const partB = randomIntegerInclusive(1, 9, {
      constraint: x => isInRange(3, 10)(partA + x)
    });

    const colorOptions = ['red', 'green', 'blue', 'yellow'] as const;
    const colors = getRandomSubArrayFromArray(colorOptions, 2);
    const shuffleParts = getRandomFromArrayWithWeights([false, true], [7, 3]);

    return { partA, partB, colors, shuffleParts };
  },
  Component: props => {
    const {
      question: { partA, partB, colors, shuffleParts },
      translate
    } = props;
    const whole = partA + partB;

    const partASvg = `Circles/circle_${colors[0]}` as const;
    const partBSvg = `Circles/circle_${colors[1]}` as const;

    const groupedArray = ({ width, height }: { width: number; height: number }) => [
      ...countRange(partA).map((_, i) => (
        <AssetSvg key={`${partASvg}_${i}`} name={partASvg} height={height} width={width * 0.09} />
      )),
      ...countRange(partB).map((_, i) => (
        <AssetSvg key={`${partBSvg}_${i}`} name={partBSvg} height={height} width={width * 0.09} />
      ))
    ];

    const shuffleArray = (dimens: Dimens) =>
      shuffle(groupedArray(dimens), { random: seededRandom(props.question) });

    return (
      <QF1ContentAndSentences
        questionHeight={1000}
        title={translate.ks1Instructions.completeTheSentences()}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => {
          return (
            <View
              style={{
                ...dimens,
                columnGap: 8,
                flexDirection: 'row'
              }}
            >
              {shuffleParts ? shuffleArray(dimens) : groupedArray(dimens)}
            </View>
          );
        }}
        sentences={[
          translate.ks1AnswerSentences.ansIsAPart(),
          translate.ks1AnswerSentences.ansIsAPart(),
          translate.ks1AnswerSentences.wholeIsAns()
        ]}
        testCorrect={([[userPartA], [userPartB], [userWhole]]) =>
          isEqual(sumNumberArray([parseInt(userPartA), parseInt(userPartB)]))(partA + partB) &&
          userWhole === whole.toString()
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [partA.toLocaleString()],
            [partB.toLocaleString()],
            [whole.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyPairOfNumbersThatSumToX(partA + partB)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'IntroducePartsAndWholes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
