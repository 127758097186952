import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import {
  arraysHaveSameContents,
  countRange,
  nestedArrayHasNoDuplicates,
  nestedArraysHaveSameContentsUnordered
} from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import { isValidTriangle } from '../../../../utils/shapes';
import Svg from 'react-native-svg';
import { GridPolygon } from '../../../../utils/gridUtils';
import { ALGEBRAIC_X, ALGEBRAIC_Y } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aAM',
  description: 'aAM',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(1).max(5).array().length(2)
  }),
  simpleGenerator: () => ({
    coordinate: [randomIntegerInclusive(1, 5), randomIntegerInclusive(1, 5)]
  }),
  Component: ({ question: { coordinate }, translate, displayMode, theme }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatAreTheCoordinatesOfThePoints(1)}
        sentence="( <ans/> , <ans/> )"
        testCorrect={coordinate.map(it => it.toString())}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={coordinate as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: displayMode === 'digital' ? colors.pacificBlue : theme.colors.tertiary
                }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAN',
  description: 'aAN',
  keywords: ['Coordinate', 'Coordinate grid', 'X-Coordinate', 'Y-Coordinate'],
  schema: z.object({
    shareX: z.boolean(),
    coordinate1: z.number().int().min(0).max(5).array().length(2),
    coordinate2: z.number().int().min(0).max(5).array().length(2),
    coordinate3: z.number().int().min(0).max(5).array().length(2),
    coordinate4: z.number().int().min(0).max(5).array().length(2),
    coordinate5: z.number().int().min(0).max(5).array().length(2),
    sharedPairIndexes: z.number().int().min(0).max(4).array().length(2)
  }),
  simpleGenerator: () => {
    const shareX = getRandomBoolean();
    const { sharedXIndexes, sharedYIndexes } = rejectionSample(
      () => {
        const sharedXIndexes = randomUniqueIntegersInclusive(0, 4, 2);
        const sharedYIndexes = randomUniqueIntegersInclusive(0, 4, 2);
        return { sharedXIndexes, sharedYIndexes };
      },
      val => !nestedArraysHaveSameContentsUnordered(val.sharedXIndexes, val.sharedYIndexes)
    );
    const xCoords = randomUniqueIntegersInclusive(0, 5, 5);
    const yCoords = randomUniqueIntegersInclusive(0, 5, 5);

    const [coordinate1, coordinate2, coordinate3, coordinate4, coordinate5] = countRange(5).map(
      i => [
        sharedXIndexes[1] === i ? xCoords[sharedXIndexes[0]] : xCoords[i],
        sharedYIndexes[1] === i ? yCoords[sharedYIndexes[0]] : yCoords[i]
      ]
    );

    const sharedPairIndexes = shareX ? sharedXIndexes : sharedYIndexes;

    return {
      shareX,
      coordinate1,
      coordinate2,
      coordinate3,
      coordinate4,
      coordinate5,
      sharedPairIndexes
    };
  },
  Component: ({
    question: {
      shareX,
      coordinate1,
      coordinate2,
      coordinate3,
      coordinate4,
      coordinate5,
      sharedPairIndexes
    },
    translate,
    theme,
    displayMode
  }) => {
    const coordinates = [coordinate1, coordinate2, coordinate3, coordinate4, coordinate5];
    const answers = coordinates
      .filter((_val, i) => sharedPairIndexes.includes(i))
      .map(coord => coord.map(i => i.toString()));

    return (
      <QF1ContentAndSentences
        title={translate.instructions.whatAreTheCoordsOfThePointsWithSameXYCoord(
          shareX ? ALGEBRAIC_X : ALGEBRAIC_Y
        )}
        sentences={['( <ans/> , <ans/> )', '( <ans/> , <ans/> )']}
        testCorrect={userAnswer =>
          nestedArrayHasNoDuplicates(userAnswer, true) &&
          userAnswer.every(
            ans =>
              arraysHaveSameContents(ans, answers[0]) || arraysHaveSameContents(ans, answers[1])
          )
        }
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: coordinates
            .filter((_val, i) => sharedPairIndexes.includes(i))
            .map(coord => coord.map(i => i.toLocaleString())),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            {coordinates.map((coord, idx) => (
              <GridImage
                key={idx}
                mathCoord={coord as [number, number]}
                item={{
                  component: 'Coordinates/CirclePointCustomizable',
                  svgProps: {
                    fill: displayMode === 'digital' ? colors.pacificBlue : theme.colors.tertiary
                  }
                }}
              />
            ))}
          </Grid>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aAO',
  description: 'aAO',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(0).max(5).array().length(2)
  }),
  simpleGenerator: () => ({
    coordinate: [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)]
  }),
  Component: ({ question: { coordinate }, translate, theme, displayMode }) => {
    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointToCoordinatesXY(
                coordinate[0].toLocaleString(),
                coordinate[1].toLocaleString()
              )
            : translate.instructions.drawPointAtCoordinatesXY(
                coordinate[0].toLocaleString(),
                coordinate[1].toLocaleString()
              )
        }
        testCorrect={ans => arraysHaveSameContents(ans[0], coordinate)}
        customMarkSchemeAnswer={{
          answersToDisplay: [coordinate as [number, number]]
        }}
        gridProps={{
          xMax: 5,
          yMax: 5,
          squareGrid: true
        }}
        snapToGrid
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aAP',
  description: 'aAP',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(0).max(5).array().length(2)
  }),
  simpleGenerator: () => {
    const isXZero = getRandomBoolean();
    const coordinate = [
      isXZero ? 0 : randomIntegerInclusive(1, 5),
      isXZero ? randomIntegerInclusive(1, 5) : 0
    ];
    return { coordinate };
  },
  Component: ({ question: { coordinate }, translate, displayMode, theme }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatAreTheCoordinatesOfThePoints(1)}
        sentence="( <ans/> , <ans/> )"
        testCorrect={coordinate.map(it => it.toString())}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={coordinate as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: {
                  fill: displayMode === 'digital' ? colors.pacificBlue : theme.colors.tertiary
                }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aAQ',
  description: 'aAQ',
  keywords: ['Coordinate', 'Coordinate grid', 'Vertex', 'Triangle'],
  schema: z.object({
    coordinate1: z.number().int().min(0).max(10).step(2).array().length(2),
    coordinate2: z.number().int().min(0).max(10).step(2).array().length(2),
    coordinate3: z.number().int().min(0).max(10).step(2).array().length(2)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [
          randomIntegerInclusiveStep(0, 10, 2),
          randomIntegerInclusiveStep(0, 10, 2)
        ];
        const coordinate2 = [
          randomIntegerInclusiveStep(0, 10, 2),
          randomIntegerInclusiveStep(0, 10, 2)
        ];
        const coordinate3 = [
          randomIntegerInclusiveStep(0, 10, 2),
          randomIntegerInclusiveStep(0, 10, 2)
        ];
        return { coordinate1, coordinate2, coordinate3 };
      },
      val => {
        const point1 = val.coordinate1 as [number, number];
        const point2 = val.coordinate2 as [number, number];
        const point3 = val.coordinate3 as [number, number];

        return isValidTriangle(point1, point2, point3, 2);
      }
    ),
  Component: ({
    question: { coordinate1, coordinate2, coordinate3 },
    translate,
    theme,
    displayMode
  }) => {
    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToPlotCoords(
                `(${coordinate1[0]}, ${coordinate1[1]}), (${coordinate2[0]}, ${coordinate2[1]}), (${coordinate3[0]}, ${coordinate3[1]})`
              )
            : translate.instructions.drawPointToPlotCoords(
                `(${coordinate1[0]}, ${coordinate1[1]}), (${coordinate2[0]}, ${coordinate2[1]}), (${coordinate3[0]}, ${coordinate3[1]})`
              )
        }
        testCorrect={ans =>
          nestedArrayHasNoDuplicates(ans, true) &&
          ans.every(
            coord =>
              arraysHaveSameContents(coord, coordinate1) ||
              arraysHaveSameContents(coord, coordinate2) ||
              arraysHaveSameContents(coord, coordinate3)
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            coordinate1 as [number, number],
            coordinate2 as [number, number],
            coordinate3 as [number, number]
          ]
        }}
        gridProps={{
          xMax: 10,
          yMax: 10,
          xStepSize: 2,
          yStepSize: 2,
          squareGrid: true
        }}
        snapToGrid
        items={countRange(3).map(() => ({
          // In PDF mode, we use a cross instead.
          component:
            displayMode === 'digital'
              ? 'Coordinates/CirclePointCustomizable'
              : 'Coordinates/CrossPointCustomizable',
          svgProps: { fill: theme.colors.tertiary }
        }))}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aAR',
  description: 'aAR',
  keywords: ['Coordinate', 'Coordinate grid'],
  questionHeight: 850,
  schema: z.object({
    coordinate1: z.number().int().min(0).max(5).array().length(2),
    coordinate2: z.number().int().min(0).max(5).array().length(2),
    coordinate3: z.number().int().min(0).max(5).array().length(2)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate2 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate3 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        return { coordinate1, coordinate2, coordinate3 };
      },
      val => {
        const point1 = val.coordinate1 as [number, number];
        const point2 = val.coordinate2 as [number, number];
        const point3 = val.coordinate3 as [number, number];

        return isValidTriangle(point1, point2, point3, 2);
      }
    ),
  Component: ({ question: { coordinate1, coordinate2, coordinate3 }, translate, displayMode }) => {
    const answer = [coordinate1, coordinate2, coordinate3].map(i => [
      i[0].toString(),
      i[1].toString()
    ]);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.whatAreTheCoordinatesOfTheVerticesOfTheTriangle()}
        sentences={['( <ans/> , <ans/> )', '( <ans/> , <ans/> )', '( <ans/> , <ans/> )']}
        questionHeight={850}
        testCorrect={userAnswer =>
          userAnswer.every(
            array =>
              arraysHaveSameContents(array, answer[0]) ||
              arraysHaveSameContents(array, answer[1]) ||
              arraysHaveSameContents(array, answer[2])
          ) && nestedArrayHasNoDuplicates(userAnswer, true)
        }
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: [coordinate1, coordinate2, coordinate3].map(i => [
            i[0].toLocaleString(),
            i[1].toLocaleString()
          ]),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <Svg height={height}>
              <GridPolygon
                points={[
                  coordinate1 as [number, number],
                  coordinate2 as [number, number],
                  coordinate3 as [number, number]
                ]}
                color={displayMode !== 'digital' ? `${colors.pdfShading}70` : undefined}
                showBorder
              />
            </Svg>
          </Grid>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReadAndPlotCoordinates',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
