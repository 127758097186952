import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import { ADD, SUB } from '../../../../constants';
import {
  Scale,
  SimpleBaseTenWithCrossOut
} from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import Text from '../../../../components/typography/Text';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import { range } from '../../../../utils/collections';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import { buildSimpleNumberSentence } from '../../../../utils/strings';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgE',
  description: 'bgE',
  keywords: ['Add', 'Related facts', 'Tens', 'Ones', 'Base 10'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(8),
      number2: z.number().int().min(1).max(9)
    })
    .refine(val => numbersDoNotExchange(val.number1, val.number2), 'Numbers do not exchange'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 8);
    const number2 = randomIntegerInclusive(1, 9, {
      constraint: x => numbersDoNotExchange(x, number1)
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 * 10;
    const number4 = number2 * 10;

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheAdditions()}
        sentences={[
          `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`,
          `${number3.toLocaleString()} ${ADD} ${number4.toLocaleString()} = <ans/>`
        ]}
        testCorrect={[[(number1 + number2).toString()], [(number3 + number4).toString()]]}
        mainPanelStyle={{ flexDirection: 'row' }}
        style={{ justifyContent: 'space-evenly', alignItems: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'space-evenly', alignItems: 'flex-end' }}
        Content={({ dimens }) => {
          const tensScale = Scale(dimens.width * 0.5, dimens.height * 0.45, {
            tens: Math.max(number1, number2)
          });
          return (
            <View style={{ justifyContent: 'space-evenly', height: dimens.height }}>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  columnGap: 16
                }}
              >
                <SimpleBaseTenWithCrossOut
                  ones={number1}
                  scale={tensScale}
                  dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.45 }}
                />
                <Text variant="WRN400">{ADD}</Text>
                <SimpleBaseTenWithCrossOut
                  ones={number2}
                  scale={tensScale}
                  dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.45 }}
                />
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignItems: 'center',
                  columnGap: 16
                }}
              >
                <SimpleBaseTenWithCrossOut
                  tens={number3 / 10}
                  scale={tensScale}
                  dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.45 }}
                />
                <Text variant="WRN400">{ADD}</Text>
                <SimpleBaseTenWithCrossOut
                  tens={number4 / 10}
                  scale={tensScale}
                  dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.45 }}
                />
              </View>
            </View>
          );
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bgF',
  description: 'bgF',
  keywords: ['Subtract', 'Related facts', 'Tens', 'Ones', 'Base 10'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);
    const number2 = randomIntegerInclusive(1, number1);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 * 10;
    const number4 = number2 * 10;

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSubtractions()}
        sentences={[
          `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`,
          `${number3.toLocaleString()} ${SUB} ${number4.toLocaleString()} = <ans/>`
        ]}
        testCorrect={[[(number1 - number2).toString()], [(number3 - number4).toString()]]}
        mainPanelStyle={{ flexDirection: 'row' }}
        style={{ justifyContent: 'space-evenly', alignItems: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'space-evenly', alignItems: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ justifyContent: 'space-evenly', height: dimens.height }}>
            <View style={{ height: dimens.height * 0.5, justifyContent: 'center' }}>
              <SimpleBaseTenWithCrossOut
                ones={number1}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.45 }}
                crossedOutIndices={{ ones: range(number1 - 1, number1 - number2), tens: [] }}
              />
            </View>
            <View style={{ height: dimens.height * 0.5, justifyContent: 'center' }}>
              <SimpleBaseTenWithCrossOut
                tens={number3 / 10}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.45 }}
                crossedOutIndices={{
                  ones: [],
                  tens: range(number3 / 10 - 1, (number3 - number4) / 10)
                }}
              />
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bgG',
  description: 'bgG',
  keywords: ['Related facts', 'Add', 'Subtract', 'Tens', 'Ones'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(0).max(10),
    addOrSubtract: z.enum([ADD, SUB]),
    ansIndex: numberEnum([0, 1, 2])
  }),
  simpleGenerator: () => {
    const ansIndex = getRandomFromArray([0, 1, 2] as const);
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const number1 =
      addOrSubtract === ADD ? randomIntegerInclusive(1, 8) : randomIntegerInclusive(2, 10);
    const number2 =
      addOrSubtract === ADD
        ? randomIntegerInclusive(1, 9, { constraint: x => numbersDoNotExchange(x, number1) })
        : randomIntegerInclusive(0, number1);

    return { number1, number2, addOrSubtract, ansIndex };
  },
  Component: props => {
    const {
      question: { number1, number2, addOrSubtract, ansIndex },
      translate
    } = props;

    const number3 = number1 * 10;
    const number4 = number2 * 10;

    const { sentence: sentenceA, answer: answerA } = buildSimpleNumberSentence(
      [number1, number2, addOrSubtract === ADD ? number1 + number2 : number1 - number2],
      addOrSubtract,
      ansIndex
    );
    const { sentence: sentenceB, answer: answerB } = buildSimpleNumberSentence(
      [number3, number4, addOrSubtract === ADD ? number3 + number4 : number3 - number4],
      addOrSubtract,
      ansIndex
    );

    const instruction =
      addOrSubtract === ADD
        ? translate.ks1Instructions.completeTheAdditions()
        : translate.ks1Instructions.completeTheSubtractions();

    return (
      <QF2AnswerBoxManySentences
        title={instruction}
        sentences={[sentenceA, sentenceB]}
        testCorrect={[[answerA.toString()], [answerB.toString()]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RelatedFacts',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
