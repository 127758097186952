import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import QF31NumberGridInteractive from '../../../../components/question/questionFormats/QF31NumberGridInteractive';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import { getPrimeNumbers } from '../../../../utils/primes';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { isPrime } from 'mathjs';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'amJ',
  description: 'amJ',
  keywords: ['Prime', 'Array'],
  schema: z.object({
    number: z.number().min(3).max(25)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(3, 25);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const numOfRows = 5;
    const numOfCols = 12;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.tinySaysNumIsPrimeNumberTrueFalse(
          number
        )} ${translate.instructions.selectCorrectAnswer()}`}
        pdfTitle={`${translate.instructions.tinySaysNumIsPrimeNumberTrueFalse(
          number
        )} ${translate.instructions.circleYourAnswer()}`}
        correctAnswer={isPrime(number)}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        content={({ dimens }) => (
          <BarModelInteractiveWithState
            id="barModel"
            numberOfRows={numOfRows}
            numberOfCols={numOfCols}
            tableWidth={dimens.width}
            tableHeight={dimens.height}
            isSquareGrid
          />
        )}
      />
    );
  },
  questionHeight: 1000
});

// Question2 exported to Q anB
const Question2 = newQuestionContent({
  uid: 'amK',
  description: 'amK',
  keywords: ['Prime'],
  schema: z
    .object({
      number1: z.number().int().min(21).max(99)
    })
    .refine(
      val => isPrime(val.number1),
      'number1 must be a prime number greater than 21 and less than 100'
    ),
  simpleGenerator: () => {
    // If this returns nothing, default to first prime number in range, 23.
    const number1 = getRandomFromArray(getPrimeNumbers(20, 100)) ?? 23;

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={answer =>
          answer[1][0] === '2' &&
          ((answer[2][0] === '1' && answer[2][1] === number1.toString()) ||
            (answer[2][0] === number1.toString() && answer[2][1] === '1'))
        }
        inputMaxCharacters={2}
        sentences={[
          translate.answerSentences.numIsAPrimeNumber(number1),
          translate.answerSentences.itHasOnlyAnsFactors(),
          translate.answerSentences.theFactorsAreAnsAndAnd()
        ]}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [],
            [(2).toLocaleString()],
            [(1).toLocaleString(), number1.toLocaleString()]
          ],
          answerText: translate.markScheme.factorsInAnyOrder()
        }}
      />
    );
  }
});
export const amK = Question2;

// Question3 exported to Q anD
const Question3 = newQuestionContent({
  uid: 'amL',
  description: 'amL',
  keywords: ['Prime'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(1).max(20)).length(8)
    })
    .refine(val => val.numbers.filter(x => isPrime(x)).length >= 4, 'At least 4 numbers are prime'),
  simpleGenerator: () => {
    const initial = [1, 2, ...getRandomSubArrayFromArray(getPrimeNumbers(3, 20), 3)];

    let numbers = rejectionSample(
      () =>
        randomUniqueIntegersInclusive(4, 20, 3, {
          constraint: x => !initial.includes(x)
        }),
      // Ensure at least one prime
      numbers => numbers.filter(x => !isPrime(x)).length > 0
    );

    numbers = numbers.concat(initial);

    return { numbers: shuffle(numbers) };
  },

  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const correct = numbers.filter(x => isPrime(x));

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectThePrimeNumbers()}
        pdfTitle={translate.instructions.circleThePrimeNumbers()}
        testCorrect={correct}
        multiSelect
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});
export const amL = Question3;

// Question4 exported to Q anE
const Question4 = newQuestionContent({
  uid: 'amM',
  description: 'amM',
  keywords: ['Prime', '100 square'],
  schema: z.object({
    startingNumber: numberEnum([1, 11, 21, 31, 41, 51])
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([1, 11, 21, 31, 41, 51] as const);
    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    return (
      <QF31NumberGridInteractive
        title={translate.instructions.selectAllThePrimeNumbers()}
        pdfTitle={translate.instructions.shadePrimeNumbers()}
        startNumber={startingNumber}
        finishNumber={startingNumber + 49}
        testCorrect={getPrimeNumbers(startingNumber, startingNumber + 49)}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});
export const amM = Question4;

// Question5 exported to Q anF
const Question5 = newQuestionContent({
  uid: 'amN',
  description: 'amN',
  keywords: ['Prime'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(40))
      .length(8)
      .refine(numbers => numbers.filter(x => isPrime(x)).length > 0, 'At least 1 number is prime')
      .refine(numbers => arrayHasNoDuplicates(numbers), 'there are no duplicate numbers')
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const prime = randomIntegerInclusive(1, 40, {
      constraint: x => isPrime(x)
    });

    const others = randomUniqueIntegersInclusive(1, 40, 7, {
      constraint: x => x !== prime
    });

    const numbers = shuffle([prime, ...others]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const primes = numbers.filter(x => isPrime(x));
    const notPrimes = numbers.filter(x => !isPrime(x));

    const correctOrder = [primes, notPrimes];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortNumbersIntoTheTable()}
        pdfTitle={translate.instructions.sortNumbersPDF()}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.prime(), translate.instructions.notPrime()]}
        items={numbers}
        pdfItemVariant="pdfSquare"
        questionHeight={800}
      />
    );
  }
});
export const amN = Question5;

const Question5v2 = newQuestionContent({
  uid: 'amN2',
  description: 'amN2',
  keywords: ['Prime'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(40))
      .length(9)
      .refine(numbers => numbers.filter(x => isPrime(x)).length > 0, 'At least 1 number is prime')
      .refine(numbers => arrayHasNoDuplicates(numbers), 'There must be no duplicate numbers.')
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const prime = randomIntegerInclusive(1, 40, {
      constraint: x => isPrime(x)
    });

    const others = randomUniqueIntegersInclusive(1, 40, 8, {
      constraint: x => x !== prime
    });

    const numbers = shuffle([prime, ...others]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const primes = numbers.filter(x => isPrime(x));
    const notPrimes = numbers.filter(x => !isPrime(x));

    const correctOrder = [primes, notPrimes];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortNumbersIntoTheTable()}
        pdfTitle={translate.instructions.sortNumbersPDF()}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.prime(), translate.instructions.notPrime()]}
        items={numbers}
        pdfItemVariant="pdfSquare"
        questionHeight={800}
      />
    );
  }
});

// Question6 exported to Q anG
const Question6 = newQuestionContent({
  uid: 'amO',
  description: 'amO',
  keywords: ['Prime'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(20).max(100)).length(4)
    })
    .refine(val => val.numbers.filter(x => isPrime(x)).length === 1, 'Only 1 number is prime'),
  simpleGenerator: () => {
    const multipleOf2 = randomIntegerInclusiveStep(20, 98, 2);
    const multipleOf3 = randomIntegerInclusiveStep(21, 99, 3, {
      constraint: x => x !== multipleOf2
    });
    const multipleOf5 = randomIntegerInclusiveStep(20, 95, 5, {
      constraint: x => x !== multipleOf2 && x !== multipleOf3
    });

    const prime = randomIntegerInclusive(20, 99, {
      constraint: x => isPrime(x)
    });

    const numbers = shuffle([multipleOf2, multipleOf3, multipleOf5, prime]);

    return { numbers };
  },

  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectThePrimeNumber()}
        pdfTitle={translate.instructions.circleThePrimeNumber()}
        testCorrect={numbers.filter(x => isPrime(x))}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
      />
    );
  }
});
export const amO = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PrimeNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
