import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arraysHaveSameContentsUnordered, filledArray, range } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { compareFractions, simplify } from '../../../../utils/fractions';
import { compareFloats } from '../../../../utils/math';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numberEnum } from '../../../../utils/zod';
import { all, create, number } from 'mathjs';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import { createHundredSquareShape } from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aRC',
  description: 'aRC',
  keywords: ['Fractions', 'Decimals', 'Percentages', 'FDP', 'Hundred square', 'Equivalent'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(1).max(99),
    rotation: numberEnum([0, 90, 180, 270]).optional()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusive(1, 99);

    const rotation = getRandomFromArray([0, 90, 180, 270] as const);

    return { numberOfShadedSquares, rotation };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares, rotation = 0 },
      translate
    } = props;

    const shape = createHundredSquareShape(numberOfShadedSquares);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.theHundredSquareRepresentsOneWholeGiveFractionDecimalAndPercentageOfTheHundredSquareThatIsShaded()}
        sentences={[
          translate.answerSentences.fracEqualsOverX(100),
          translate.answerSentences.decimalEqualsAns(),
          translate.answerSentences.percentageEqualsAnsPercent()
        ]}
        questionHeight={1000}
        sentenceStyle={{ alignSelf: 'flex-end', bottom: 44 }}
        mainPanelStyle={{ flexDirection: 'row' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFractions([userAnswer[0][0], 100], [numberOfShadedSquares, 100]) &&
          compareFloats(userAnswer[1][0], numberOfShadedSquares / 100) &&
          userAnswer[2][0] === numberOfShadedSquares.toString()
        }
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              {
                transform: [{ rotate: `${rotation}deg` }],
                alignItems: 'center',
                justifyContent: 'center'
              }
            ]}
          >
            <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [numberOfShadedSquares.toString()],
            [(numberOfShadedSquares / 100).toLocaleString()],
            [numberOfShadedSquares.toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aRD',
  description: 'aRD',
  keywords: ['Fraction', 'Decimal', 'Percentage', 'FDP', 'Equivalent'],
  schema: z.object({
    number1: z.number().int().min(1).max(100),
    number2: z.number().int().min(1).max(100)
  }),
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(1, 100, 2);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const percentage1 = `${number1.toLocaleString()}%`;
    const decimal1 = (number1 / 100).toLocaleString();

    const percentage2 = `${number2.toLocaleString()}%`;
    const decimal2 = (number2 / 100).toLocaleString();

    const sentences = [
      {
        sentence: `<frac n='${number1.toLocaleString()}' d='${(100).toLocaleString()}' /> = <ans/> = <ans/>`,
        answer: [decimal1, percentage1]
      },
      {
        sentence: `<frac n='${number2.toLocaleString()}' d='${(100).toLocaleString()}' /> = <ans/> = <ans/>`,
        answer: [decimal2, percentage2]
      }
    ];

    const items = shuffle([percentage1, percentage2, decimal1, decimal2], {
      random: seededRandom(props.question)
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchEquivalentFractionsDecimalsPercentages()}
        pdfTitle={translate.instructions.useCardsToMatchEquivalentFractionsDecimalsPercentages()}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsRight"
        items={items}
        sentences={sentences.map(({ sentence }) => sentence)}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [decimal1.toString(), percentage1],
            [decimal2.toString(), percentage2]
          ]
        }}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer[0], sentences[0].answer) &&
          arraysHaveSameContentsUnordered(userAnswer[1], sentences[1].answer)
        }
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aRD2',
  description: 'aRD',
  keywords: ['Fraction', 'Decimal', 'Percentage', 'FDP', 'Equivalent'],
  schema: z.object({
    number: z.number().int().min(1).max(100),
    items: z.array(z.number().min(0.001).max(100))
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 100);

    const items = shuffle([number, number / 10, number / 100, number / 1000]);

    return { number, items };
  },
  Component: props => {
    const {
      question: { number, items },
      translate,
      displayMode
    } = props;

    const percentage = `${number.toLocaleString()}%`;
    const decimal = (number / 100).toLocaleString();

    const sentence = {
      sentence: `<frac n='${number.toString()}' d='${(100).toString()}' /> = <ans/> = <ans/>`,
      answer: [decimal, percentage]
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsMatchEquivalentFractionDecimalPercentage()}
        pdfTitle={translate.instructions.useCardsToMatchEquivalentFractionDecimalPercentage()}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsRight"
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        items={items.map(num =>
          [number, number / 10].includes(num) ? `${num}%` : num.toLocaleString()
        )}
        sentence={sentence.sentence}
        customMarkSchemeAnswer={{
          answersToDisplay: [[decimal.toString(), percentage]]
        }}
        testCorrect={userAnswer => arraysHaveSameContentsUnordered(userAnswer, sentence.answer)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aRE',
  description: 'aRE',
  keywords: [
    'Hundredths',
    'Parts',
    'Whole',
    'Equivalent',
    'Fraction',
    'Decimal',
    'Percentage',
    'FDP'
  ],
  schema: z.object({
    number1: z.number().int().min(1).max(100),
    number2: z.number().int().min(1).max(100)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(1, 100, 2);

    return { number1, number2 };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    // Answers
    const answer1 = (number1 / 100).toString();
    const answer2 = number1.toString();
    const answer4 = number2.toString();

    // Table data
    const data = [
      [
        `<frac n='${number1.toLocaleString()}' d='${(100).toLocaleString()}' />`,
        '<ans/>',
        '<ans/>%'
      ],
      ["<frac nAns='' dAns='' />", `${(number2 / 100).toLocaleString()}`, '<ans/>%']
    ];

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTable()}
        questionHeight={1000}
        cellHeaders={[
          translate.keywords.Fraction(),
          translate.keywords.Decimal(),
          translate.keywords.Percentage()
        ]}
        textStyle={{ fontSize: 40 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer1, answer2, number2.toString(), '100', answer4],
          answerText: translate.markScheme.acceptEquivalentDecimalsAndFractions()
        }}
        extraSymbol="decimalPoint"
        tableData={data}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], answer1) &&
          userAnswer[1] === answer2 &&
          compareFractions([userAnswer[2], userAnswer[3]], [number2, 100]) &&
          userAnswer[4] === answer4
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRF',
  description: 'aRF',
  keywords: ['Percentages', 'Fractions', 'Decimals', 'FDP', 'Equivalent'],
  schema: z.object({
    denominator: numberEnum([2, 5, 10]),
    numerator: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([2, 5, 10] as const);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    return { denominator, numerator };
  },
  Component: props => {
    const {
      question: { denominator, numerator },
      translate,
      displayMode
    } = props;

    const percentage = (numerator / denominator) * 100;

    const numbers = [
      filledArray(1, 1),
      filledArray(1 / 2, 2),
      filledArray(1 / 5, 5),
      filledArray(1 / 10, 10)
    ];

    const strings = [
      range(1, 1).map(() => `100%`),
      range(1, 2).map(() => `50%`),
      range(1, 5).map(() => `20%`),
      range(1, 10).map(() => `10%`)
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useTheDiagramToHelpCompleteTheEquivalenceStatements()}
        testCorrect={userAnswer =>
          userAnswer[0] === percentage.toString() && compareFloats(userAnswer[1], percentage / 100)
        }
        inputMaxCharacters={4}
        sentence={`<frac n='${numerator.toString()}' d='${denominator.toString()}' /> = <ans/>% = <ans/>`}
        sentenceStyle={{ alignSelf: 'center' }}
        extraSymbol="decimalPoint"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [percentage.toLocaleString(), (percentage / 100).toLocaleString()]
        }}
        Content={({ dimens }) => (
          <View>
            <BarModel
              oneFontSize
              total={1}
              dimens={dimens}
              numbers={numbers}
              strings={strings}
              sameRowColor
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aRG',
  description: 'aRG',
  keywords: ['Fraction', 'Decimal', 'Percentage', 'FDP', 'Equivalent'],
  schema: z.object({
    denominatorA: numberEnum([2, 5, 10, 20, 50]),
    numeratorA: z.number().int().min(1).max(49),
    denominatorB: numberEnum([2, 5, 10, 20, 50]),
    numeratorB: z.number().int().min(1).max(49)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = getRandomSubArrayFromArray([2, 5, 10, 20, 50] as const, 2);
    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
    const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

    return { denominatorA, denominatorB, numeratorA, numeratorB };
  },
  Component: ({ question: { denominatorA, denominatorB, numeratorA, numeratorB }, translate }) => {
    const [simplifiedNumeratorA, simplifiedDenominatorA] = simplify(numeratorA, denominatorA);

    const percentageA = (numeratorA / denominatorA) * 100;
    const percentageB = (numeratorB / denominatorB) * 100;

    // Answers
    const answer1 = (percentageA / 100).toString();
    const answer2 = percentageA.toString();
    const answer4 = percentageB.toString();

    // Table data
    const data = [
      [
        `<frac n='${simplifiedNumeratorA.toLocaleString()}' d='${simplifiedDenominatorA.toLocaleString()}' />`,
        '<ans/>',
        '<ans/>%'
      ],
      ["<frac nAns='' dAns='' />", `${(percentageB / 100).toLocaleString()}`, '<ans/>%']
    ];

    return (
      <QF7InteractiveTable
        title={translate.instructions.completeTable()}
        questionHeight={1000}
        cellHeaders={[
          translate.keywords.Fraction(),
          translate.keywords.Decimal(),
          translate.keywords.Percentage()
        ]}
        textStyle={{ fontSize: 40 }}
        extraSymbol="decimalPoint"
        tableData={data}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            answer1,
            answer2,
            numeratorB.toString(),
            denominatorB.toString(),
            answer4
          ],
          answerText: translate.markScheme.acceptEquivalentDecimalsAndFractions()
        }}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], answer1) &&
          userAnswer[1] === answer2 &&
          compareFractions([userAnswer[2], userAnswer[3]], [numeratorB, denominatorB]) &&
          userAnswer[4] === answer4
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aRH',
  description: 'aRH',
  keywords: ['Percentages', 'Fractions', 'FDP', 'Equivalent'],
  schema: z.object({
    denominator: numberEnum([2, 5, 10, 20, 50]),
    numerator: z.number().int().min(1).max(49),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([2, 5, 10, 20, 50] as const);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const name = getRandomName();

    return { denominator, numerator, name };
  },
  Component: props => {
    const {
      question: { denominator, numerator, name },
      translate
    } = props;
    const answer = number(math.evaluate(`100 - (${numerator} / ${denominator} * 100)`));

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.characterGetsMoneyForBirthday({
          character: name
        })}<br/>${translate.instructions.characterSpendsFracOfMoneyAndSavesTheRestWhatPercentageIsSaved(
          {
            character: name,
            frac: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`
          }
        )}`}
        testCorrect={[answer.toString()]}
        sentence={'<ans/>%'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsDecimalsAndPercentages',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
