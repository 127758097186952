import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive
} from '../../../../utils/random';
import { View } from 'react-native';
import TextStructure from '../../../../components/molecules/TextStructure';
import { integerToWord, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { GREATER_THAN, LESS_THAN } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgm',
  description: 'bgm',
  keywords: ['Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    numberA: z.number().int().min(1).max(100),
    numberB: z.number().int().min(1).max(100),
    isNumberANumeral: z.boolean(),
    isNumberBNumeral: z.boolean()
  }),
  simpleGenerator: () => {
    const isSameNumbers = getRandomFromArrayWithWeights([false, true], [4, 1]);

    const numberA = randomIntegerInclusive(1, 100);

    const numberB = isSameNumbers
      ? numberA
      : randomIntegerInclusive(1, 100, { constraint: x => x !== numberA });

    const isNumberANumeral = getRandomBoolean();

    const isNumberBNumeral = isSameNumbers ? !isNumberANumeral : getRandomBoolean();

    return {
      numberA,
      numberB,
      isNumberANumeral,
      isNumberBNumeral
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB, isNumberANumeral, isNumberBNumeral },
      translate
    } = props;

    const items = [
      { component: translate.operations.greaterThan(), value: '>' },
      { component: translate.operations.lessThan(), value: '<' },
      { component: translate.operations.equalTo(), value: '=' }
    ];

    const numberAString = isNumberANumeral ? numberA.toLocaleString() : integerToWord(numberA);

    const numberBString = isNumberBNumeral
      ? numberB.toLocaleString()
      : `${integerToWord(numberB)}.`;

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.writeTheMissingPhrase()}
        items={items}
        sentence={translate.ks1AnswerSentences.stringAIsAnsStringB({
          numberA: numberAString,
          numberB: numberBString
        })}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        questionHeight={900}
        testCorrect={[lessThanGreaterThanOrEqualTo(numberA, numberB)]}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bgn',
  description: 'bgn',
  keywords: ['Greater than', 'Less than', 'Equal to', '<', '>', '='],
  schema: z.object({
    numberA: z.number().int().min(1).max(100),
    numberB: z.number().int().min(1).max(100),
    isNumberANumeral: z.boolean(),
    isNumberBNumeral: z.boolean()
  }),
  simpleGenerator: () => {
    const isSameNumbers = getRandomFromArrayWithWeights([false, true], [4, 1]);

    const numberA = randomIntegerInclusive(1, 100);

    const numberB = isSameNumbers
      ? numberA
      : randomIntegerInclusive(1, 100, { constraint: x => x !== numberA });

    const isNumberANumeral = getRandomBoolean();

    const isNumberBNumeral = isSameNumbers ? !isNumberANumeral : getRandomBoolean();

    return {
      numberA,
      numberB,
      isNumberANumeral,
      isNumberBNumeral
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB, isNumberANumeral, isNumberBNumeral },
      translate,
      displayMode
    } = props;

    const items = ['>', '<', '='];

    const numberAString = isNumberANumeral ? numberA.toLocaleString() : integerToWord(numberA);

    const numberBString = isNumberBNumeral ? numberB.toLocaleString() : integerToWord(numberB);

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-end' }}>
            <TextStructure sentence={numberAString} />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 350 : 500, alignItems: 'flex-start' }}>
            <TextStructure sentence={numberBString} />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(numberA, numberB)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheNumbers()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheNumbers()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bgo',
  description: 'bgo',
  keywords: ['Less than', 'Greater than', '<', '>', '='],
  schema: z.object({
    numA: z.number().int().min(0).max(100),
    inequality: z.enum(['<', '>', '=']),
    wordOrNum: z.enum(['Number', 'Word'])
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(0, 100);
    const wordOrNum = getRandomFromArrayWithWeights(['Number', 'Word'] as const, [3, 1]);
    const inequalities = ['<', '>', '='] as const;
    let inequality = getRandomFromArray(inequalities);

    if (numA === 0) {
      inequality = getRandomFromArray(['<', '='] as const);
    }

    if (numA === 100) {
      inequality = getRandomFromArray(['>', '='] as const);
    }

    if (wordOrNum === 'Number' && ![0, 100].includes(numA)) {
      inequality = getRandomFromArray(['<', '>'] as const);
    }

    return { numA, wordOrNum, inequality };
  },
  Component: props => {
    const {
      question: { numA, wordOrNum, inequality },
      translate
    } = props;

    const numberA = wordOrNum === 'Number' ? numA.toLocaleString() : integerToWord(numA);
    const symbol = inequality === '<' ? LESS_THAN : inequality === '>' ? GREATER_THAN : '=';

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheStatement()}
        pdfTitle={translate.ks1PDFInstructions.writeMissingNumber()}
        sentence={`${numberA} ${symbol} <ans/>`}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          inequality === lessThanGreaterThanOrEqualTo(numA, parseInt(userAnswer[0]))
        }
        customMarkSchemeAnswer={{
          answerText:
            symbol === LESS_THAN
              ? translate.markScheme.acceptAnyNumberGreaterThanX(numberA)
              : symbol === GREATER_THAN
              ? translate.markScheme.acceptAnyNumberLessThanX(numberA)
              : translate.markScheme.acceptNumberEqualToX(numberA)
        }}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareNumbers',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
