import Text from '../../../../components/typography/Text';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { aOrAnShapeAsWords, moreShapesAsWord } from '../../../../utils/shapes';
import {
  getShapeSvgByShapeAndColor,
  getShapeSvgName,
  twoDShapeColors
} from '../../../../utils/shapeImages/shapes';
import {
  getShapesWithPerpendicularOrParallelLinesName,
  shapesWithParallelAndPerpendicularData,
  ShapesWithPendicularOrParallelLinesName,
  shapesWithPendicularOrParallelLinesSchema
} from '../../../../utils/shapeImages/perpendicularParallel';
import {
  getIrregularShapeSvgName,
  getRandomIrregularShapeSvgNames
} from '../../../../utils/shapeImages/irregular';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  getQuadrilateralSvgName,
  quadrilateralColors
} from '../../../../utils/quadrilateralImages';
import { View } from 'react-native';
import { arrayHasNoDuplicates } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'avm',
  description: 'avm',
  keywords: ['2-D', 'Shape', 'Regular'],
  schema: z.object({
    shapeNames: z
      .array(
        z.enum([
          'squares',
          'rectangles',
          'triangles',
          'circles',
          'hexagons',
          'pentagons',
          'heptagons',
          'nonagons',
          'octagons',
          'decagons'
        ])
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const shapeNames = getRandomSubArrayFromArray(
      [
        'squares',
        'rectangles',
        'triangles',
        'circles',
        'hexagons',
        'pentagons',
        'heptagons',
        'nonagons',
        'octagons',
        'decagons'
      ] as const,
      4
    );
    return { shapeNames };
  },
  Component: props => {
    const {
      question: { shapeNames },
      translate,
      displayMode
    } = props;

    const statements = shapeNames.map(name => {
      return {
        lhsComponent: (
          <View style={{ alignItems: 'center', width: 160 }}>
            <AssetSvg
              name={getShapeSvgName(name, props.question) as SvgName}
              height={displayMode === 'digital' ? 110 : 150}
            />
          </View>
        ),
        correctAnswer: moreShapesAsWord(name, translate, 1)
      };
    });

    const items = shapeNames.map(name => moreShapesAsWord(name, translate, 1));

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchNamesToTheShape()}
        pdfTitle={translate.instructions.useCardsToMatchNamesToTheShape()}
        items={items}
        itemsMaxLines={2}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'avm2',
  description: 'avm2',
  keywords: ['2-D', 'Shape', 'Regular'],
  schema: z.object({
    shapeNames: z
      .array(
        z.enum([
          'squares',
          'rectangles',
          'triangles',
          'circles',
          'hexagons',
          'pentagons',
          'heptagons',
          'nonagons',
          'octagons',
          'decagons'
        ])
      )
      .length(4)
      .refine(val => arrayHasNoDuplicates(val), 'shapeNames must have no duplicates.'),
    shapeColors: z
      .array(z.enum(twoDShapeColors))
      .length(4)
      .refine(val => arrayHasNoDuplicates(val), 'shapeColors must have no duplicates.')
  }),
  simpleGenerator: () => {
    const shapeNames = getRandomSubArrayFromArray(
      [
        'squares',
        'rectangles',
        'triangles',
        'circles',
        'hexagons',
        'pentagons',
        'heptagons',
        'nonagons',
        'octagons',
        'decagons'
      ] as const,
      4
    );

    const shapeColors = getRandomSubArrayFromArray(twoDShapeColors, 4);

    return { shapeNames, shapeColors };
  },
  Component: props => {
    const {
      question: { shapeNames, shapeColors },
      translate,
      displayMode
    } = props;

    const shapeNameToSVGName = (shapeName: (typeof shapeNames)[number]) => {
      switch (shapeName) {
        case 'squares':
          return 'square' as const;
        case 'rectangles':
          return 'rectangle' as const;
        case 'triangles':
          return 'triangle' as const;
        case 'circles':
          return 'circle' as const;
        case 'hexagons':
          return 'hexagon' as const;
        case 'pentagons':
          return 'pentagon' as const;
        case 'heptagons':
          return 'heptagon' as const;
        case 'nonagons':
          return 'nonagon' as const;
        case 'octagons':
          return 'octagon' as const;
        case 'decagons':
          return 'decagon' as const;
      }
    };

    const statements = shapeNames.map((name, i) => {
      const shapeSVGName = shapeNameToSVGName(name);

      return {
        lhsComponent: (
          <View style={{ alignItems: 'center', width: 160 }}>
            <AssetSvg
              name={getShapeSvgByShapeAndColor(shapeSVGName, shapeColors[i]) as SvgName}
              height={displayMode === 'digital' ? 110 : 150}
            />
          </View>
        ),
        correctAnswer: moreShapesAsWord(name, translate, 1)
      };
    });

    const items = shapeNames.map(name => moreShapesAsWord(name, translate, 1));

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchNamesToTheShape()}
        pdfTitle={translate.instructions.useCardsToMatchNamesToTheShape()}
        items={items}
        itemsMaxLines={2}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'avn',
  description: 'avn',
  keywords: ['2-D', 'Shape', 'Regular', 'Irregular'],
  schema: z.object({
    answerShapeName: z.enum([
      'squares',
      'rectangles',
      'triangles',
      'circles',
      'hexagons',
      'pentagons',
      'octagons'
    ]),
    incorrectShapeNames: z
      .array(
        z.enum([
          'squares',
          'rectangles',
          'triangles',
          'circles',
          'hexagons',
          'pentagons',
          'octagons'
        ])
      )
      .length(3),
    answerSvg: z.string()
  }),
  simpleGenerator: () => {
    const isIrregular = getRandomBoolean();

    const [answerShapeName, ...incorrectShapeNames] = isIrregular
      ? [
          getRandomFromArray(['hexagons', 'pentagons', 'octagons'] as const),
          ...getRandomSubArrayFromArray(
            ['squares', 'rectangles', 'triangles', 'circles'] as const,
            3
          )
        ]
      : getRandomSubArrayFromArray(
          [
            'squares',
            'rectangles',
            'triangles',
            'circles',
            'hexagons',
            'pentagons',
            'octagons'
          ] as const,
          4
        );

    const answerSvg = isIrregular
      ? getIrregularShapeSvgName(answerShapeName as 'hexagons' | 'pentagons' | 'octagons')
      : getShapeSvgName(answerShapeName);

    return { answerShapeName, incorrectShapeNames, answerSvg };
  },
  Component: props => {
    const {
      question: { answerShapeName, incorrectShapeNames, answerSvg },
      translate
    } = props;

    const items = shuffle([answerShapeName, ...incorrectShapeNames], {
      random: seededRandom(props.question)
    });
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheNameOfTheShape()}
        pdfTitle={translate.instructions.circleTheNameOfTheShape()}
        testCorrect={[answerShapeName]}
        numItems={4}
        Content={<AssetSvg name={answerSvg as SvgName} height={200} width={200} />}
        renderItems={items.map(shapeName => ({
          value: shapeName,
          component: (
            <Text style={{ textAlign: 'center' }} variant="WRN700">
              {moreShapesAsWord(shapeName, translate, 1)}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'avo',
  description: 'avo',
  keywords: ['Shape', 'Quadrilaterals', 'Regular', 'Irregular'],
  schema: z.object({
    correctAnswerSvgNameA: z.string(),
    correctAnswerSvgNameB: z.string(),
    incorrectAnswerSvgNameA: z.string(),
    incorrectAnswerSvgNameB: z.string()
  }),
  simpleGenerator: () => {
    const [randomQuadsA, randomQuadsB] = getRandomSubArrayFromArray(
      [
        'Parallelogram',
        'Rectangle',
        'Rhombus',
        'Square',
        'Trapezium',
        'Trapezium4Arrows',
        'Kite'
      ] as const,
      2
    );
    const [colourA, colourB] = getRandomSubArrayFromArray(quadrilateralColors, 2);

    const correctAnswerSvgNameA = getQuadrilateralSvgName(randomQuadsA, colourA);
    const correctAnswerSvgNameB = getQuadrilateralSvgName(randomQuadsB, colourB);

    const [randomNonQuadA, randomNonQuadB] = getRandomSubArrayFromArray(
      [
        'triangles',
        'circles',
        'hexagons',
        'pentagons',
        'heptagons',
        'nonagons',
        'octagons',
        'decagons'
      ] as const,
      2
    );

    const incorrectAnswerARegular = getShapeSvgName(randomNonQuadA);
    const incorrectAnswerBRegular = getShapeSvgName(randomNonQuadB);

    const [incorrectAnswerAIrregular, incorrectAnswerBIrregular] =
      getRandomIrregularShapeSvgNames(2);

    const [incorrectAnswerSvgNameA, incorrectAnswerSvgNameB] = [
      getRandomFromArray([incorrectAnswerARegular, incorrectAnswerAIrregular]),
      getRandomFromArray([incorrectAnswerBRegular, incorrectAnswerBIrregular])
    ];

    return {
      correctAnswerSvgNameA,
      correctAnswerSvgNameB,
      incorrectAnswerSvgNameA,
      incorrectAnswerSvgNameB
    };
  },
  Component: props => {
    const {
      question: {
        correctAnswerSvgNameA,
        correctAnswerSvgNameB,
        incorrectAnswerSvgNameA,
        incorrectAnswerSvgNameB
      },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: 'A',
          component: <AssetSvg name={correctAnswerSvgNameA as SvgName} width={150} height={150} />
        },
        {
          value: 'B',
          component: <AssetSvg name={correctAnswerSvgNameB as SvgName} width={150} height={150} />
        },
        {
          value: 'C',
          component: <AssetSvg name={incorrectAnswerSvgNameA as SvgName} width={150} height={150} />
        },
        {
          value: 'D',
          component: <AssetSvg name={incorrectAnswerSvgNameB as SvgName} width={150} height={150} />
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectAllQuadrilaterals()}
        pdfTitle={translate.instructions.circleAllQuadrilaterals()}
        testCorrect={['A', 'B']}
        numItems={4}
        multiSelect
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
      />
    );
  }
});

const q3v2CorrectShapes = [
  'parallelogram',
  'rectangle',
  'rhombus',
  'square',
  'trapezium isosceles',
  'kite'
] as const;

const q3v2IncorrectShapes = [
  'triangle',
  'circle',
  'hexagon',
  'pentagon',
  'heptagon',
  'nonagon',
  'octagon',
  'decagon',
  'pentagon house',
  'irregular pentagon 1',
  'irregular pentagon 2',
  'irregular pentagon 3',
  'irregular pentagon 4',
  'irregular pentagon 5',
  'irregular pentagon 6',
  'irregular hexagon 1',
  'irregular hexagon 2',
  'irregular hexagon 3',
  'irregular hexagon 4',
  'irregular hexagon 5',
  'irregular hexagon 6',
  'irregular heptagon 1',
  'irregular heptagon 2',
  'irregular heptagon 3',
  'irregular octagon 1',
  'irregular octagon 2',
  'irregular octagon 3'
] as const;

const Question3v2 = newQuestionContent({
  uid: 'avo2',
  description: 'avo2',
  keywords: ['Shape', 'Quadrilaterals', 'Regular', 'Irregular'],
  schema: z
    .object({
      correctShapeA: z.enum(q3v2CorrectShapes),
      correctShapeB: z.enum(q3v2CorrectShapes),
      incorrectShapeA: z.enum(q3v2IncorrectShapes),
      incorrectShapeB: z.enum(q3v2IncorrectShapes),
      shapeColors: z
        .array(z.enum(twoDShapeColors))
        .length(4)
        .refine(val => arrayHasNoDuplicates(val), 'shapeColors must have no duplicates.')
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.correctShapeA,
          val.correctShapeB,
          val.incorrectShapeA,
          val.incorrectShapeB
        ]),
      'All shapes must be different.'
    ),
  simpleGenerator: () => {
    const [correctShapeA, correctShapeB] = getRandomSubArrayFromArray(q3v2CorrectShapes, 2);

    const [incorrectShapeA, incorrectShapeB] = getRandomSubArrayFromArray(q3v2IncorrectShapes, 2);

    const shapeColors = getRandomSubArrayFromArray(twoDShapeColors, 4);

    return {
      correctShapeA,
      correctShapeB,
      incorrectShapeA,
      incorrectShapeB,
      shapeColors
    };
  },
  Component: props => {
    const {
      question: { correctShapeA, correctShapeB, incorrectShapeA, incorrectShapeB, shapeColors },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: 'A',
          component: (
            <AssetSvg
              name={getShapeSvgByShapeAndColor(correctShapeA, shapeColors[0])}
              width={150}
              height={150}
            />
          )
        },
        {
          value: 'B',
          component: (
            <AssetSvg
              name={getShapeSvgByShapeAndColor(correctShapeB, shapeColors[1])}
              width={150}
              height={150}
            />
          )
        },
        {
          value: 'C',
          component: (
            <AssetSvg
              name={getShapeSvgByShapeAndColor(incorrectShapeA, shapeColors[2])}
              width={150}
              height={150}
            />
          )
        },
        {
          value: 'D',
          component: (
            <AssetSvg
              name={getShapeSvgByShapeAndColor(incorrectShapeB, shapeColors[3])}
              width={150}
              height={150}
            />
          )
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectAllQuadrilaterals()}
        pdfTitle={translate.instructions.circleAllQuadrilaterals()}
        testCorrect={['A', 'B']}
        numItems={4}
        multiSelect
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'avp',
  description: 'avp',
  keywords: ['2-D', 'Shape', 'Regular', 'Irregular'],
  schema: z.object({
    correctAnswerSvgName: z.string(),
    incorrectAnswerSvgNames: z.array(z.string()).length(3),
    answerShape: z.enum(['pentagons', 'hexagons', 'octagons'])
  }),
  simpleGenerator: () => {
    const answerShape = getRandomFromArray(['pentagons', 'hexagons', 'octagons'] as const);
    const incorrectShapes = getRandomSubArrayFromArray(
      ['heptagons', 'nonagons', 'decagons', 'dodecagons'] as const,
      3
    );

    const correctAnswerSvgName = getIrregularShapeSvgName(answerShape);

    const incorrectAnswerSvgNames = incorrectShapes.map(name => getIrregularShapeSvgName(name));

    return {
      correctAnswerSvgName,
      incorrectAnswerSvgNames,
      answerShape
    };
  },
  Component: props => {
    const {
      question: { correctAnswerSvgName, incorrectAnswerSvgNames, answerShape },
      translate
    } = props;

    const [incorrectAnswersSvgNameA, incorrectAnswersSvgNameB, incorrectAnswersSvgNameC] =
      incorrectAnswerSvgNames;

    const items = shuffle(
      [
        {
          value: 'A',
          component: <AssetSvg name={correctAnswerSvgName as SvgName} width={150} height={150} />
        },
        {
          value: 'B',
          component: (
            <AssetSvg name={incorrectAnswersSvgNameA as SvgName} width={150} height={150} />
          )
        },
        {
          value: 'C',
          component: (
            <AssetSvg name={incorrectAnswersSvgNameB as SvgName} width={150} height={150} />
          )
        },
        {
          value: 'D',
          component: (
            <AssetSvg name={incorrectAnswersSvgNameC as SvgName} width={150} height={150} />
          )
        }
      ],
      { random: seededRandom(props.question) }
    );

    const titleShape = aOrAnShapeAsWords(answerShape, translate);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheShapeThatIsX(titleShape)}
        pdfTitle={translate.instructions.circleTheShapeThatIsX(titleShape)}
        testCorrect={['A']}
        numItems={4}
        multiSelect
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
      />
    );
  }
});

const q4v2Pentagons = [
  'irregular pentagon 1',
  'irregular pentagon 2',
  'irregular pentagon 3',
  'irregular pentagon 4',
  'irregular pentagon 5',
  'irregular pentagon 6'
] as const;

const q4v2Hexagons = [
  'irregular hexagon 1',
  'irregular hexagon 2',
  'irregular hexagon 3',
  'irregular hexagon 4',
  'irregular hexagon 5',
  'irregular hexagon 6'
] as const;

const q4v2Octagons = ['irregular octagon 1', 'irregular octagon 2', 'irregular octagon 3'] as const;

const q4v2CorrectShapes = [...q4v2Pentagons, ...q4v2Hexagons, ...q4v2Octagons] as const;

const q4v2Heptagons = ['irregular heptagon 4'] as const;

const q4v2Nonagons = ['irregular nonagon 1'] as const;

const q4v2Decagons = ['irregular decagon 1', 'irregular decagon 2'] as const;

const q4v2Dodecagons = ['irregular dodecagon 1', 'irregular dodecagon 2'] as const;

const q4v2IncorrectShapes = [
  ...q4v2Heptagons,
  ...q4v2Nonagons,
  ...q4v2Decagons,
  ...q4v2Dodecagons
] as const;

const q4v2AllShapes = [...q4v2CorrectShapes, ...q4v2IncorrectShapes] as const;

const Question4v2 = newQuestionContent({
  uid: 'avp2',
  description: 'avp2',
  keywords: ['2-D', 'Shape', 'Regular', 'Irregular'],
  schema: z
    .object({
      selectedShape: z.enum(q4v2CorrectShapes),
      shapes: z
        .array(z.enum(q4v2AllShapes))
        .length(4)
        .refine(val => arrayHasNoDuplicates(val), 'All shapes must be different.'),
      shapeColors: z
        .array(z.enum(twoDShapeColors))
        .length(4)
        .refine(val => arrayHasNoDuplicates(val), 'shapeColors must have no duplicates.')
    })
    .refine(val => val.shapes.includes(val.selectedShape), 'shapes msut include selectedShape.'),
  simpleGenerator: () => {
    const validShapeTypes = ['pentagon', 'hexagon', 'octagon'] as const;

    // Give equal weighting to selecting a pentagon, hexagon or octagon:
    const selectedShapeType = getRandomFromArray(validShapeTypes);

    const selectedShape = getRandomFromArray(
      selectedShapeType === 'pentagon'
        ? q4v2Pentagons
        : selectedShapeType === 'hexagon'
        ? q4v2Hexagons
        : q4v2Octagons
    );

    const incorrectShapeTypes = getRandomSubArrayFromArray(
      [
        'heptagon',
        'nonagon',
        'decagon',
        'dodecagon',
        ...validShapeTypes.filter(shape => shape !== selectedShapeType)
      ] as const,
      3
    );

    const incorrectShapes = incorrectShapeTypes.map(shape => {
      switch (shape) {
        case 'heptagon':
          return getRandomFromArray(q4v2Heptagons);
        case 'nonagon':
          return getRandomFromArray(q4v2Nonagons);
        case 'decagon':
          return getRandomFromArray(q4v2Decagons);
        case 'dodecagon':
          return getRandomFromArray(q4v2Dodecagons);
        case 'pentagon':
          return getRandomFromArray(q4v2Pentagons);
        case 'hexagon':
          return getRandomFromArray(q4v2Hexagons);
        case 'octagon':
          return getRandomFromArray(q4v2Octagons);
      }
    });

    const shapes = shuffle([selectedShape, ...incorrectShapes]);

    const shapeColors = getRandomSubArrayFromArray(twoDShapeColors, 4);

    return {
      selectedShape,
      shapes,
      shapeColors
    };
  },
  Component: props => {
    const {
      question: { selectedShape, shapes, shapeColors },
      translate
    } = props;

    const shapeString = (() => {
      // Upcasting (always safe) to get around .includes annoyances
      type CorrectShape = (typeof q4v2CorrectShapes)[number];
      const q4v2PentagonsUpcasted: readonly CorrectShape[] = q4v2Pentagons;
      const q4v2HexagonsUpcasted: readonly CorrectShape[] = q4v2Hexagons;
      if (q4v2PentagonsUpcasted.includes(selectedShape)) {
        return translate.shapes.pentagons(1);
      } else if (q4v2HexagonsUpcasted.includes(selectedShape)) {
        return translate.shapes.hexagons(1);
      } else {
        return translate.shapes.octagons(1);
      }
    })();

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheString(shapeString)}
        pdfTitle={translate.instructions.circleTheString(shapeString)}
        testCorrect={[selectedShape]}
        numItems={4}
        renderItems={shapes.map((shape, index) => ({
          value: shape,
          component: (
            <AssetSvg
              name={getShapeSvgByShapeAndColor(shape, shapeColors[index])}
              height={200}
              width={400}
            />
          )
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'avq',
  description: 'avq',
  keywords: ['2-D', 'Shape', 'Regular'],
  schema: z.object({
    shapeNames: z.array(z.enum(['heptagons', 'nonagons', 'octagons', 'decagons']))
  }),
  simpleGenerator: () => {
    const shapeNames = shuffle(['heptagons', 'nonagons', 'octagons', 'decagons']) as [];

    return { shapeNames };
  },
  Component: props => {
    const {
      translate,
      displayMode,
      question: { shapeNames }
    } = props;

    const statements = shapeNames.map(name => {
      return {
        lhsComponent: (
          <View style={{ alignItems: 'center', width: 160 }}>
            <AssetSvg
              name={getShapeSvgName(name, props.question) as SvgName}
              height={displayMode === 'digital' ? 110 : 150}
            />
          </View>
        ),
        correctAnswer: moreShapesAsWord(name, translate, 1)
      };
    });

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = shapeNames.map(name => moreShapesAsWord(name, translate, 1));

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchNamesToTheShape()}
        pdfTitle={translate.instructions.useCardsToMatchNamesToTheShape()}
        items={items}
        itemsMaxLines={2}
        statements={shuffledStatements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'avr',
  description: 'avr',
  keywords: ['2-D', 'Shape', 'Regular', 'Irregular', 'Parallel', 'Perpendicular'],
  schema: z.object({
    isAnswerParallel: z.boolean(),
    allAnswers: z.array(
      z.object({
        name: z.string(),
        isPerpendicular: z.boolean(),
        isParellel: z.boolean()
      })
    ),
    correctAnswerNames: z.array(z.string()),
    answersSvgNames: z.array(z.string())
  }),
  simpleGenerator: () => {
    const isAnswerParallel = getRandomBoolean();
    const numOfCorrectAnswers = randomIntegerInclusive(1, 3);

    // All shape objects that have perpendiculars lines only
    const isPerpendicular = shapesWithParallelAndPerpendicularData.filter(
      el => el.isPerpendicular && !el.isParallel
    );

    // All shape objects that have parallel lines only
    const isParallel = shapesWithParallelAndPerpendicularData.filter(
      el => el.isParallel && !el.isPerpendicular
    );

    // All shape objects have no parallel and perpendicular line because these shapes can be used for the incorrect answers.
    const shapesWithNoPerOrPar = shapesWithParallelAndPerpendicularData.filter(
      el => !el.isPerpendicular && !el.isParallel
    );

    // All shape objects that have parallel and perpendicular lines because these shapes have both we need to make sure they're only used for correct answers
    const shapesWithBothPerOrPar = shapesWithParallelAndPerpendicularData.filter(
      el => el.isPerpendicular && el.isParallel
    );

    // If answer is parallel get the correct answers from isParallel and shapesWithBothPerOrPar else get correct answers from isPerpendicular and shapesWithBothPerOrPar
    const correctAnswers = getRandomSubArrayFromArray(
      isAnswerParallel
        ? [...isParallel, ...shapesWithBothPerOrPar]
        : [...isPerpendicular, ...shapesWithBothPerOrPar],
      numOfCorrectAnswers
    );

    // If answer is parallel get the incorrect answers from isPerpendicular and shapesWithNoPerOrPar else get correct answers from isParallel and shapesWithNoPerOrPar
    const incorrectAnswers = getRandomSubArrayFromArray(
      isAnswerParallel
        ? [...isPerpendicular, ...shapesWithNoPerOrPar]
        : [...isParallel, ...shapesWithNoPerOrPar],
      4 - numOfCorrectAnswers
    );

    const allAnswers = [...correctAnswers, ...incorrectAnswers].map(it => ({
      name: it.name,
      // Purposefully retained the typo in this key-name for the sake of backwards compatability:
      isParellel: it.isParallel,
      isPerpendicular: it.isPerpendicular
    }));
    const correctAnswerNames = correctAnswers.map(ans => ans.name);

    const answersSvgNames = allAnswers.map(({ name }) =>
      getShapesWithPerpendicularOrParallelLinesName(name as ShapesWithPendicularOrParallelLinesName)
    );

    return { isAnswerParallel, allAnswers, answersSvgNames, correctAnswerNames };
  },
  Component: props => {
    const {
      question: { isAnswerParallel, allAnswers, answersSvgNames, correctAnswerNames },
      translate
    } = props;

    const items = allAnswers.map(({ name }, index) => {
      return {
        value: name,
        component: <AssetSvg height={150} width={150} name={answersSvgNames[index] as SvgName} />
      };
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheShapesThatHaveAtLeastOnePairOfX(
          isAnswerParallel ? translate.misc.parallel() : translate.misc.perpendicular()
        )}
        pdfTitle={translate.instructions.circleTheShapesThatHaveAtLeastOnePairOfX(
          isAnswerParallel ? translate.misc.parallel() : translate.misc.perpendicular()
        )}
        testCorrect={correctAnswerNames}
        numItems={4}
        multiSelect
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'avr2',
  description: 'avr2',
  keywords: ['2-D', 'Shape', 'Regular', 'Irregular', 'Parallel', 'Perpendicular'],
  schema: z.object({
    isAnswerParallel: z.boolean(),
    allAnswers: z.array(
      z.object({
        name: shapesWithPendicularOrParallelLinesSchema,
        isPerpendicular: z.boolean(),
        isParallel: z.boolean(),
        color: z.enum(twoDShapeColors)
      })
    )
  }),
  simpleGenerator: () => {
    const isAnswerParallel = getRandomBoolean();
    const numOfCorrectAnswers = randomIntegerInclusive(1, 3);

    const potentialCorrectAnswers: typeof shapesWithParallelAndPerpendicularData = [];
    const potentialIncorrectAnswers: typeof shapesWithParallelAndPerpendicularData = [];
    shapesWithParallelAndPerpendicularData.forEach(el => {
      if (isAnswerParallel) {
        el.isParallel ? potentialCorrectAnswers.push(el) : potentialIncorrectAnswers.push(el);
      } else {
        el.isPerpendicular ? potentialCorrectAnswers.push(el) : potentialIncorrectAnswers.push(el);
      }
    });

    const correctAnswers = getRandomSubArrayFromArray(potentialCorrectAnswers, numOfCorrectAnswers);
    const incorrectAnswers = getRandomSubArrayFromArray(
      potentialIncorrectAnswers,
      4 - numOfCorrectAnswers
    );
    const answers = [...correctAnswers, ...incorrectAnswers];

    const colors = getRandomSubArrayFromArray(twoDShapeColors, 4);

    const allAnswers = shuffle(
      answers.map((answer, index) => ({
        ...answer,
        color: colors[index]
      }))
    );

    return { isAnswerParallel, allAnswers };
  },
  Component: props => {
    const {
      question: { isAnswerParallel, allAnswers },
      translate
    } = props;

    const correctAnswerNames = allAnswers
      .filter(ans => (isAnswerParallel ? ans.isParallel : ans.isPerpendicular))
      .map(ans => ans.name);

    const items = allAnswers.map(({ name, color }) => {
      const svgName = (() => {
        switch (name) {
          case 'RAtriangle':
            return 'right angle triangle';
          case 'triangle':
            return 'triangle';
          case 'square':
            return 'square';
          case 'rectangle':
            return 'rectangle';
          case 'housePentagon':
            return 'pentagon house';
          case 'regularPentagon':
            return 'pentagon';
          case 'hexagon':
            return 'hexagon';
          case 'trapezium':
            return 'trapezium isosceles';
          case 'RAtrapezium':
            return 'right angle trapezium';
          case 'parallelogram':
            return 'parallelogram';
        }
      })();

      return {
        value: name,
        component: <AssetSvg height={200} name={getShapeSvgByShapeAndColor(svgName, color)} />
      };
    });

    return (
      <QF11SelectImagesUpTo4
        title={
          isAnswerParallel
            ? correctAnswerNames.length === 1
              ? translate.instructions.selectTheShapeThatHasAtLeastOnePairOfParallelLines()
              : translate.instructions.selectTheShapesThatHaveAtLeastOnePairOfParallelLines()
            : correctAnswerNames.length === 1
            ? translate.instructions.selectTheShapeThatHasAtLeastOnePairOfPerpendicularLines()
            : translate.instructions.selectTheShapesThatHaveAtLeastOnePairOfPerpendicularLines()
        }
        pdfTitle={
          isAnswerParallel
            ? correctAnswerNames.length === 1
              ? translate.instructions.circleTheShapeThatHasAtLeastOnePairOfParallelLines()
              : translate.instructions.circleTheShapesThatHaveAtLeastOnePairOfParallelLines()
            : correctAnswerNames.length === 1
            ? translate.instructions.circleTheShapeThatHasAtLeastOnePairOfPerpendicularLines()
            : translate.instructions.circleTheShapesThatHaveAtLeastOnePairOfPerpendicularLines()
        }
        testCorrect={correctAnswerNames}
        numItems={4}
        multiSelect={correctAnswerNames.length > 1}
        renderItems={items.map(({ component, value }) => ({
          value,
          component
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAndDescribe2DShapes',
  questionTypes: [Question1v2, Question2, Question3v2, Question4v2, Question5, Question6v2],
  archivedQuestionTypes: [Question1, Question3, Question4, Question6]
});
export default SmallStep;
