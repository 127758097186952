import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ADD } from '../../../../constants';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { compareFractions, simplify } from '../../../../utils/fractions';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { filledArray, nestedArrayHasNoDuplicates } from '../../../../utils/collections';
import { colors } from '../../../../theme/colors';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { calculateRadius } from '../../../../components/question/representations/MultiPieChart';
import { PieChartInteractiveWithState } from '../../../../components/question/representations/PieChartInteractive';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'asy',
  description: 'asy',
  keywords: ['Fractions', 'Bar model', 'Add'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(8),
      numerator: z.number().int().min(1).max(4)
    })
    .refine(
      val => val.numerator < val.denominator - 1,
      'numerator should be less than denominator - 1'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);

    const numerator = randomIntegerInclusive(1, Math.min(denominator - 2, 4));

    return { denominator, numerator };
  },
  Component: props => {
    const {
      question: { denominator, numerator },
      translate
    } = props;

    const coloredCellsA: string[] = filledArray(colors.pacificBlue600, 1);
    const coloredCellsB: string[] = filledArray(colors.acidGreen, numerator);

    const coloredCells = coloredCellsA.concat(coloredCellsB);

    const [simplifiedAnswerNumerator, simplifiedAnswerDenominator] = simplify(
      1 + numerator,
      denominator
    );

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`<frac n='1' d='${denominator.toLocaleString()}'/> ${ADD} <frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac nAns='' dAns=''/>`}
        title={translate.instructions.useBarModelToHelpCompleteAddition()}
        testCorrect={answer =>
          compareFractions(answer, [simplifiedAnswerNumerator, simplifiedAnswerDenominator])
        }
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            totalPerSection={1}
            height={100}
            width={dimens.width}
            customColorMap={coloredCells}
            color={'white'}
          />
        )}
        inputMaxCharacters={1}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            simplifiedAnswerNumerator.toLocaleString(),
            simplifiedAnswerDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'asz',
  description: 'asz',
  keywords: ['Fractions', 'Addition'],
  schema: z
    .object({
      numeratorA: z.number().int().min(1).max(3),
      numeratorB: z.number().int().min(2).max(7),
      denominator: z.number().int().min(6).max(10)
    })
    .refine(
      val => val.numeratorA + val.numeratorB < val.denominator,
      'addition of numerators must sum to less than denominator'
    ),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(1, 3);
    const denominator = randomIntegerInclusive(6, 10);
    const numeratorB = randomIntegerInclusive(2, 7, {
      constraint: x => x + numeratorA < denominator
    });

    return { numeratorA, numeratorB, denominator };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, denominator },
      translate
    } = props;

    const ansNumerator = numeratorA + numeratorB;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.tapCircleToHelpCompleteAddition()}
        pdfTitle={translate.instructions.shadeCircleToHelpCompleteAddition()}
        sentenceStyle={{ justifyContent: 'center' }}
        testCorrect={answer => compareFractions([...answer], [ansNumerator, denominator])}
        sentence={`<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${ADD} <frac n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac nAns='' dAns=''/>`}
        Content={({ dimens }) => (
          <PieChartInteractiveWithState
            id={'pie'}
            numberOfSlices={denominator}
            radius={calculateRadius(dimens, 1, 1)}
            // make it non markable
            testComplete={() => true}
            testCorrect={() => true}
          />
        )}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [ansNumerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'asA',
  description: 'asA',
  keywords: ['Fractions', 'Add'],
  schema: z
    .object({
      nameA: nameSchema,
      nameB: nameSchema,
      denominator: z.number().int().min(6).max(10),
      numeratorA: z.number().int().min(2).max(4),
      numeratorB: z.number().int().min(3).max(6)
    })
    .refine(
      val => val.numeratorA + val.numeratorB < val.denominator,
      'numeratorA + numeratorB must be less than denominator.'
    )
    .refine(val => val.nameA !== val.nameB, 'Chosen names must be different.'),
  questionHeight: 1100,
  simpleGenerator: () => {
    const [nameA, nameB] = getRandomUniqueNames(2);

    const denominator = randomIntegerInclusive(6, 10);

    // If denominator is 6, numeratorA must be 2; if denominator is 7, numeratorA must be 2 or 3; otherwise, any from 2 to 4.
    const numeratorA = denominator === 6 ? 2 : randomIntegerInclusive(2, denominator === 7 ? 3 : 4);

    const numeratorB = randomIntegerInclusive(3, Math.min(denominator - numeratorA - 1, 6));

    return { nameA, nameB, denominator, numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { nameA, nameB, denominator, numeratorA, numeratorB },
      displayMode,
      translate
    } = props;

    const [simplifiedAnswerNumerator, simplifiedAnswerDenominator] = simplify(
      numeratorA + numeratorB,
      denominator
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.char1AndChar2AreEatingACake(
          nameA,
          nameB,
          `<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/>`,
          `<frac n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}'/>`
        )}
        testCorrect={answer =>
          compareFractions(answer, [simplifiedAnswerNumerator, simplifiedAnswerDenominator])
        }
        sentence={`<frac nAns='' dAns=''/>`}
        mainPanelContainerStyle={{ alignItems: 'flex-end' }}
        inputMaxCharacters={2}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? 36 : 64 }}
        titleFractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        titleTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            simplifiedAnswerNumerator.toLocaleString(),
            simplifiedAnswerDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asB',
  description: 'asB',
  keywords: ['Fractions', 'Add'],
  schema: z
    .object({
      numeratorA1: z.number().int().min(3).max(9),
      numeratorA2: z.number().int().min(6).max(9),
      denominatorA: z.number().int().min(10).max(29),
      numeratorB1: z.number().int().min(11).max(19),
      numeratorB2: z.number().int().min(16).max(29),
      denominatorB: z.number().int().min(31).max(119)
    })
    .refine(
      val => val.numeratorA1 + val.numeratorA2 < val.denominatorA,
      'numeratorA1 + numeratorA2 must be less than denominatorA'
    )
    .refine(
      val => val.numeratorB1 + val.numeratorB2 < val.denominatorB,
      'numeratorB1 + numeratorB2 must be less than denominatorB'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numeratorA1 = randomIntegerInclusive(3, 9);

    const numeratorA2 = randomIntegerInclusive(6, 9);

    // denominatorA must be at least 1 larger than numeratorA1 + numeratorA2.
    const denominatorA = randomIntegerInclusive(numeratorA1 + numeratorA2 + 1, 29);

    const numeratorB1 = randomIntegerInclusive(11, 19);

    const numeratorB2 = randomIntegerInclusive(16, 29);

    // denominatorB must be at least 1 larger than numeratorB1 + numeratorB2, or 31 .
    const denominatorB = randomIntegerInclusive(Math.max(numeratorB1 + numeratorB2 + 1, 31), 119);

    return { numeratorA1, numeratorA2, denominatorA, numeratorB1, numeratorB2, denominatorB };
  },
  Component: props => {
    const {
      question: { numeratorA1, numeratorA2, denominatorA, numeratorB1, numeratorB2, denominatorB },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeAdditions()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], [numeratorA1 + numeratorA2, denominatorA]) &&
          compareFractions(userAnswer[1], [numeratorB1 + numeratorB2, denominatorB])
        }
        inputMaxCharacters={3}
        sentences={[
          `<frac n='${numeratorA1.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> ${ADD} <frac n='${numeratorA2.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> = <frac nAns='' dAns=''/>`,
          `<frac n='${numeratorB1.toLocaleString()}' d='${denominatorB.toLocaleString()}'/> ${ADD} <frac n='${numeratorB2.toLocaleString()}' d='${denominatorB.toLocaleString()}'/> = <frac nAns='' dAns=''/>`
        ]}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(numeratorA1 + numeratorA2).toLocaleString(), denominatorA.toLocaleString()],
            [(numeratorB1 + numeratorB2).toLocaleString(), denominatorB.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'asB2',
  description: 'asB',
  keywords: ['Fractions', 'Add'],
  schema: z.discriminatedUnion('sentenceId', [
    z.object({
      sentenceId: z.literal(0),
      numerator1: z.number().int().min(3).max(9),
      numerator2: z.number().int().min(6).max(9),
      denominator: z.number().int().min(10).max(29)
    }),
    z.object({
      sentenceId: z.literal(1),
      numerator1: z.number().int().min(11).max(19),
      numerator2: z.number().int().min(16).max(29),
      denominator: z.number().int().min(31).max(119)
    })
  ]),
  questionHeight: 1000,
  simpleGenerator: () => {
    const sentenceId = getRandomFromArray([0, 1] as const);

    if (sentenceId === 0) {
      const numerator1 = randomIntegerInclusive(3, 9);
      const numerator2 = randomIntegerInclusive(6, 9);
      // denominatorA must be at least 1 larger than numerator1 + numerator2.
      const denominator = randomIntegerInclusive(numerator1 + numerator2 + 1, 29);
      return { sentenceId, numerator1, numerator2, denominator };
    } else {
      const numerator1 = randomIntegerInclusive(11, 19);
      const numerator2 = randomIntegerInclusive(16, 29);
      // denominatorA must be at least 1 larger than numerator1 + numerator2.
      const denominator = randomIntegerInclusive(Math.max(numerator1 + numerator2 + 1, 31), 119);
      return { sentenceId, numerator1, numerator2, denominator };
    }
  },
  Component: props => {
    const {
      question: { numerator1, numerator2, denominator },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer, [numerator1 + numerator2, denominator])
        }
        inputMaxCharacters={3}
        sentence={`<frac n='${numerator1}' d='${denominator}'/> ${ADD} <frac n='${numerator2}' d='${denominator}'/> = <frac nAns='' dAns=''/>`}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [(numerator1 + numerator2).toString(), denominator.toString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'asC',
  description: 'asC',
  keywords: ['Fractions', 'Add'],
  schema: z.object({
    denominator: z.number().int().min(5).max(12),
    numeratorA: z.number().int().min(4).max(11),
    numeratorB: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 12);

    // Ensure numeratorA is less than denominator
    const numeratorA = randomIntegerInclusive(4, denominator - 1);

    // Ensure numeratorB is less than numeratorA, up to 7
    const numeratorB = randomIntegerInclusive(1, Math.min(numeratorA - 1, 7));

    return { denominator, numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, numeratorB },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumerator()}
        testCorrect={[(numeratorA - numeratorB).toString()]}
        sentence={`<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${ADD} <frac nAns='' d='${denominator.toLocaleString()}'/>`}
        textStyle={{ fontSize: 40 }}
        fractionContainerStyle={{ height: 48 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'asD',
  description: 'asD',
  keywords: ['Fractions', 'Bar model'],
  schema: z.object({
    number: z.number().int().min(7).max(12)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(7, 12);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeAdditionsGiveTwoDifferentWays()}
        testCorrect={userAnswer =>
          // parseInt required to check total of answer boxes on each line.
          parseInt(userAnswer[0][0]) + parseInt(userAnswer[0][1]) + parseInt(userAnswer[0][2]) ===
            number &&
          parseInt(userAnswer[1][0]) + parseInt(userAnswer[1][1]) + parseInt(userAnswer[1][2]) ===
            number &&
          nestedArrayHasNoDuplicates([userAnswer[0], userAnswer[1]], true)
        }
        inputMaxCharacters={3}
        sentences={[
          `<frac n='${number.toLocaleString()}' d='${number.toLocaleString()}'/> = <frac nAns='' d='${number.toLocaleString()}'/> ${ADD} <frac nAns='' d='${number.toLocaleString()}'/> ${ADD} <frac nAns='' d='${number.toLocaleString()}'/>`,
          `<frac n='${number.toLocaleString()}' d='${number.toLocaleString()}'/> = <frac nAns='' d='${number.toLocaleString()}'/> ${ADD} <frac nAns='' d='${number.toLocaleString()}'/> ${ADD} <frac nAns='' d='${number.toLocaleString()}'/>`
        ]}
        fractionContainerStyle={{ height: 96 }}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationOfNumeratorsThatSumToX(number)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddFractions',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
