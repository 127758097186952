import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import Text from '../../../../components/typography/Text';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { all, create, number } from 'mathjs';
import { sortNumberArray } from '../../../../utils/collections';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import {
  ScaleObjectName,
  getRandomUniqueScaleObjectNamesOld,
  scaleObjectAsWord,
  scaleObjects,
  scaleObjectsSchemaOld
} from '../../../../utils/objects';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { labelWeight } from '../../../../utils/weightImages';
import WoodenBalanceScale from '../../../../components/question/representations/WoodenBalanceScale';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { AssetSvg } from '../../../../assets/svg';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aI6',
  description: 'aI6',
  keywords: ['Mass', 'Measure', 'Scales', 'Heavier', 'Lighter', 'Compare'],
  schema: z.object({
    scaleObjectNames: z.array(scaleObjectsSchemaOld).length(2),
    lighterOrHeavier: z.enum(['Lighter', 'Heavier']),
    scaleDirection: z.enum(['Left', 'Right'])
  }),
  simpleGenerator: () => {
    const scaleObjectNames = getRandomUniqueScaleObjectNamesOld(2);
    const lighterOrHeavier = getRandomFromArray(['Lighter', 'Heavier'] as const);
    const scaleDirection = getRandomFromArray(['Left', 'Right'] as const);

    return {
      scaleObjectNames,
      lighterOrHeavier,
      scaleDirection
    };
  },
  Component: props => {
    const {
      translate,
      question: { scaleObjectNames, lighterOrHeavier, scaleDirection }
    } = props;

    const titleWeight =
      lighterOrHeavier === 'Lighter' ? translate.keywords.Lighter() : translate.keywords.Heavier();

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const [heavierItemName, lighterItemName] = [scaleObjectNameA, scaleObjectNameB].sort(
      (a, b) => scaleObjects[b].weight - scaleObjects[a].weight
    );

    // Items order matches the scaleDirection
    const items: [ScaleObjectName, ScaleObjectName] =
      scaleDirection === 'Left'
        ? [heavierItemName, lighterItemName]
        : [lighterItemName, heavierItemName];

    const statements = items.map(item => ({
      component: <AssetSvg name={scaleObjects[item].name} height={80} />,
      value: item
    }));

    // Correct answer depends on the weight
    const correctAnswer = lighterOrHeavier === 'Lighter' ? lighterItemName : heavierItemName;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheXWeightObject(titleWeight)}
        pdfTitle={translate.instructions.circleTheXWeightObject(titleWeight)}
        testCorrect={[correctAnswer]}
        numItems={2}
        Content={({ dimens }) => (
          <WoodenBalanceScale
            direction={scaleDirection === 'Left' ? 'left' : 'right'}
            items={items}
            containerStyle={{ marginTop: 80 }}
            dimens={dimens}
          />
        )}
        itemLayout="row"
        renderItems={statements}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aI7',
  description: 'aI7',
  keywords: ['Mass', 'Measure', 'Scales', 'Heavier', 'Lighter', 'Compare'],
  schema: z.object({
    scaleObjectNames: z.array(scaleObjectsSchemaOld).length(2),
    scaleDirection: z.enum(['Left', 'Right'])
  }),
  simpleGenerator: () => {
    const scaleObjectNames = getRandomUniqueScaleObjectNamesOld(2);
    const scaleDirection = getRandomFromArray(['Left', 'Right'] as const);

    return { scaleObjectNames, scaleDirection };
  },
  Component: props => {
    const {
      translate,
      question: { scaleObjectNames, scaleDirection }
    } = props;

    const random = seededRandom(props.question);
    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    // Statement order follows what we were given in the schema.
    const statement =
      translate.answerSentences.theXScaleObjectIsLighterOrHeavierThanTheYScaleObject(
        scaleObjectNameA,
        scaleObjectNameB
      );

    // Selectables are randomly shuffled
    const selectables = shuffle(
      [
        {
          component: <Text variant="WRN700">{translate.keywords.Lighter().toLowerCase()}</Text>,
          value: 'lighter'
        },
        {
          component: <Text variant="WRN700">{translate.keywords.Heavier().toLowerCase()}</Text>,
          value: 'heavier'
        }
      ],
      { random }
    );

    // Correct answer depends on the weight
    const answer =
      scaleObjects[scaleObjectNameA].weight > scaleObjects[scaleObjectNameB].weight
        ? 'heavier'
        : 'lighter';

    // Items order matches the scaleDirection
    const [itemA, itemB] = [scaleObjectNameA, scaleObjectNameB].sort(
      scaleDirection === 'Left'
        ? (a, b) => scaleObjects[b].weight - scaleObjects[a].weight
        : (a, b) => scaleObjects[a].weight - scaleObjects[b].weight
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheWordToCompleteTheSentence()}
        pdfTitle={translate.instructions.circleTheWordToCompleteTheSentence()}
        testCorrect={[answer]}
        itemLayout="row"
        numItems={2}
        Content={({ dimens }) => (
          <>
            <WoodenBalanceScale
              direction={scaleDirection === 'Left' ? 'left' : 'right'}
              items={[itemA, itemB]}
              containerStyle={{ marginTop: 56 }}
              dimens={dimens}
            />
            <Text variant="WRN400" style={{ paddingTop: 32 }}>
              {statement}
            </Text>
          </>
        )}
        renderItems={selectables}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aI8',
  description: 'aI8',
  keywords: ['Mass', 'Measure', 'Compare'],
  schema: z.object({
    var1: z.number().int().min(1).max(9),
    var2: z
      .number()
      .int()
      .min(11)
      .max(99)
      .refine(val => val % 10 !== 0),
    var3: z.number().int().min(100).max(900).step(100),
    isSmallest: z.boolean()
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(1, 9);
    const var2 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
    const var3 = randomIntegerInclusiveStep(100, 900, 100);
    const isSmallest = getRandomBoolean();

    return { var1, var2, var3, isSmallest };
  },
  Component: props => {
    const {
      question: { var1, var2, var3, isSmallest },
      translate
    } = props;

    const dimens = { width: 180, height: 180 };

    const selectables = shuffle(
      [
        {
          value: var1,
          component: labelWeight(translate.answerSentences.xG(var1), dimens.width, dimens.height)
        },
        {
          value: var1 * 1000,
          component: labelWeight(
            translate.answerSentences.numXKg(var1),
            dimens.width,
            dimens.height
          )
        },
        {
          value: var2,
          component: labelWeight(translate.answerSentences.xG(var2), dimens.width, dimens.height)
        },
        {
          value: var3,
          component: labelWeight(translate.answerSentences.xG(var3), dimens.width, dimens.height)
        }
      ],
      { random: seededRandom(props.question) }
    );

    const title = isSmallest
      ? translate.instructions.selectSmallestMass()
      : translate.instructions.selectGreatestMass();
    const pdfTitle = isSmallest
      ? translate.instructions.circleSmallestMass()
      : translate.instructions.circleGreatestMass();
    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[isSmallest ? var1 : var1 * 1000]}
        numItems={4}
        renderItems={selectables}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aI9',
  description: 'aI9',
  keywords: ['Mass', 'Measure', 'Scales', 'Compare'],
  schema: z
    .object({
      objectATotal: z.number().int().min(2).max(6),
      objectBTotal: z.number().int().min(4).max(12),
      scaleObjectNames: z.array(scaleObjectsSchemaOld).length(2),
      randomSentence: z.boolean()
    })
    .refine(
      val => val.objectBTotal % val.objectATotal === 0,
      'objectBTotal must be a multiple of objectATotal'
    )
    .refine(
      val => val.objectBTotal > val.objectATotal,
      'objectBTotal must be greater than objectATotal'
    ),
  simpleGenerator: () => {
    const objectATotal = randomIntegerInclusive(2, 6);
    const objectBTotal = randomIntegerInclusive(objectATotal + 1, 12, {
      constraint: x => x % objectATotal === 0
    });
    const scaleObjectNames = getRandomUniqueScaleObjectNamesOld(2);
    const randomSentence = getRandomBoolean();

    return { objectATotal, objectBTotal, scaleObjectNames, randomSentence };
  },
  Component: props => {
    const {
      question: { objectATotal, objectBTotal, scaleObjectNames, randomSentence },
      translate
    } = props;

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const object1 = scaleObjectAsWord(scaleObjectNameA, translate, randomSentence ? true : false);
    const object2 = scaleObjectAsWord(scaleObjectNameB, translate, true);

    const sentence = randomSentence
      ? translate.answerSentences.xAmountYObjectsHaveTheSameMassAsAnsZObjects(
          objectATotal,
          object1,
          object2
        )
      : translate.answerSentences.xAmountYObjectHasTheSameMassAsAnsZObjects(1, object1, object2);

    const answer = randomSentence
      ? [objectBTotal.toString()]
      : [(objectBTotal / objectATotal).toString()];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        testCorrect={answer}
        sentence={sentence}
        pdfDirection="column"
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={[scaleObjectNameA, scaleObjectNameB]}
            containerStyle={{ marginTop: 150 }}
            amount={[objectATotal, objectBTotal]}
            dimens={dimens}
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4v2 = newQuestionContent({
  uid: 'aI92',
  description: 'aI9',
  keywords: ['Mass', 'Measure', 'Scales', 'Compare'],
  schema: z
    .object({
      objectATotal: z.number().int().min(2).max(6),
      objectBTotal: z.number().int().min(4).max(12),
      scaleObjectNames: z.array(scaleObjectsSchemaOld).length(2),
      objectAGiven: z.number().int().min(1).max(6)
    })
    .refine(
      val => val.objectBTotal % val.objectATotal === 0,
      'objectBTotal must be a multiple of objectATotal'
    )
    .refine(
      val => val.objectBTotal > val.objectATotal,
      'objectBTotal must be greater than objectATotal'
    )
    .refine(
      ({ objectATotal, objectAGiven }) => objectAGiven !== objectATotal,
      'objectAGiven should not equal objectATotal'
    ),
  simpleGenerator: () => {
    const objectATotal = randomIntegerInclusive(2, 6);
    const objectBTotal = randomIntegerInclusive(objectATotal + 1, 12, {
      constraint: x => x % objectATotal === 0
    });
    const scaleObjectNames = getRandomUniqueScaleObjectNamesOld(2);

    const objectAGiven = randomIntegerInclusive(1, 6, { constraint: x => x !== objectATotal });

    return { objectATotal, objectBTotal, scaleObjectNames, objectAGiven };
  },
  Component: props => {
    const {
      question: { objectATotal, objectBTotal, scaleObjectNames, objectAGiven },
      translate
    } = props;

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const objectAIsPlural = objectAGiven !== 1;

    const object1 = scaleObjectAsWord(scaleObjectNameA, translate, objectAIsPlural ? true : false);
    const object2 = scaleObjectAsWord(scaleObjectNameB, translate, true);

    const sentence = objectAIsPlural
      ? translate.answerSentences.xAmountYObjectsHaveTheSameMassAsAnsZObjects(
          objectAGiven,
          object1,
          object2
        )
      : translate.answerSentences.xAmountYObjectHasTheSameMassAsAnsZObjects(1, object1, object2);

    const answer = [((objectBTotal * objectAGiven) / objectATotal).toString()];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        testCorrect={answer}
        sentence={sentence}
        pdfDirection="column"
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={[scaleObjectNameA, scaleObjectNameB]}
            containerStyle={{ marginTop: 150 }}
            amount={[objectATotal, objectBTotal]}
            dimens={dimens}
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aJa',
  description: 'aJa',
  keywords: ['Mass', 'Measure', 'Grams', 'Kilograms', 'Compare'],
  schema: z
    .object({
      isQuestionAReversed: z.boolean(),
      isQuestionBReversed: z.boolean(),
      numberA: z.number().int().min(100).max(900).step(100),
      numberB: z.number().int().min(100).max(900).step(100),
      numberC: z.number().int().min(1).max(9),
      numberD: z.number().int().min(100).max(900).step(100),
      numberE: z.number().int().min(100).max(900).step(100),
      numberF: z.number().int().min(1).max(9),
      numberG: z
        .number()
        .int()
        .min(11)
        .max(49)
        .refine(num => num % 5 !== 0, 'numberG should not be a multiple of 5')
    })
    .refine(val => val.numberA !== val.numberB, 'numberA cannot equal numberB'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [numberA, numberB] = randomUniqueIntegersInclusive(100, 900, 2, {
      constraint: x => x % 100 === 0
    });
    const numberC = randomIntegerInclusive(1, 9);
    const numberD = randomIntegerInclusive(100, 900, { constraint: x => x % 100 === 0 });
    const numberE = randomIntegerInclusive(100, 900, { constraint: x => x % 100 === 0 });
    const numberF = randomIntegerInclusive(1, 9);
    const numberG = randomIntegerInclusive(11, 49, { constraint: x => x % 5 !== 0 });

    const isQuestionAReversed = getRandomBoolean();
    const isQuestionBReversed = getRandomBoolean();

    return {
      numberA,
      numberB,
      numberC,
      numberD,
      numberE,
      numberF,
      numberG,
      isQuestionAReversed,
      isQuestionBReversed
    };
  },
  Component: props => {
    const {
      translate,
      question: {
        numberA,
        numberB,
        numberC,
        numberD,
        numberE,
        numberF,
        numberG,
        isQuestionAReversed,
        isQuestionBReversed
      }
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: isQuestionAReversed
            ? `${translate.units.numberOfKg(numberA)} <ans/> ${translate.units.numberOfG(numberA)}`
            : `${translate.units.numberOfG(numberA)} <ans/> ${translate.units.numberOfKg(numberA)}`,
          answer: isQuestionAReversed
            ? lessThanGreaterThanOrEqualTo(numberA * 1000, numberA)
            : lessThanGreaterThanOrEqualTo(numberA, numberA * 1000)
        },
        {
          sentence: isQuestionBReversed
            ? `${translate.units.numberOfKg(numberA)} <ans/> ${translate.units.numberOfG(numberB)}`
            : `${translate.units.numberOfG(numberB)} <ans/> ${translate.units.numberOfKg(numberA)}`,
          answer: isQuestionBReversed
            ? lessThanGreaterThanOrEqualTo(numberA * 1000, numberB)
            : lessThanGreaterThanOrEqualTo(numberB, numberA * 1000)
        },

        {
          sentence: `${translate.units.numberOfKg(numberC)} ${translate.units.numberOfG(
            numberD
          )} <ans/> ${translate.units.numberOfKg(numberC)} ${translate.units.numberOfG(numberE)}`,
          answer: lessThanGreaterThanOrEqualTo(numberC * 1000 + numberD, numberC * 1000 + numberE)
        },
        {
          sentence: `${translate.units.numberOfKg(numberF)} ${translate.units.numberOfG(
            numberG
          )} <ans/> ${translate.units.numberOfKg(numberG)} ${translate.units.numberOfG(numberF)}`,
          answer: lessThanGreaterThanOrEqualTo(numberF * 1000 + numberG, numberG * 1000 + numberF)
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompareMasses()}
        pdfTitle={translate.instructions.useCardsCompareMasses()}
        actionPanelVariant="end"
        items={['<', '>', '=']}
        sentenceStyle={{ alignSelf: 'center' }}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        moveOrCopy="copy"
        questionHeight={1000}
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aJa2',
  description: 'aJa',
  keywords: ['Mass', 'Measure', 'Grams', 'Kilograms', 'Compare'],
  schema: z.object({
    isQuestionReversed: z.boolean(),
    isMixedUnits: z.boolean(),
    lhsKg: z.number().int().min(1).max(900),
    lhsG: z.number().int().min(1).max(900),
    rhsKg: z.number().int().min(1).max(900),
    rhsG: z.number().int().min(1).max(900)
  }),
  simpleGenerator: () => {
    const isMixedUnits = getRandomBoolean();
    let lhsKg: number;
    let lhsG: number;
    let rhsKg: number;
    let rhsG: number;

    if (isMixedUnits) {
      lhsKg = randomIntegerInclusive(1, 9);
      if (getRandomBoolean()) {
        // kgs are the same but different g
        rhsKg = lhsKg;
        lhsG = randomIntegerInclusive(100, 900, { constraint: x => x % 100 === 0 });
        rhsG = randomIntegerInclusive(100, 900, { constraint: x => x % 100 === 0 });
      } else {
        // kg and g values are swapped on the other side
        lhsG = randomIntegerInclusive(11, 49, { constraint: x => x % 5 !== 0 });
        rhsKg = lhsG;
        rhsG = lhsKg;
      }
    } else {
      // only one unit on either side
      lhsKg = randomIntegerInclusiveStep(100, 900, 100);
      lhsG = lhsKg;
      rhsG = randomIntegerInclusiveStep(100, 900, 100);
      rhsKg = rhsG;
    }

    const isQuestionReversed = getRandomBoolean();

    return {
      lhsKg,
      lhsG,
      rhsKg,
      rhsG,
      isQuestionReversed,
      isMixedUnits
    };
  },
  Component: props => {
    const {
      translate,
      question: { lhsKg, lhsG, rhsKg, rhsG, isMixedUnits, isQuestionReversed }
    } = props;

    const info = isMixedUnits
      ? {
          sentence: `${translate.units.numberOfKg(lhsKg)} ${translate.units.numberOfG(
            lhsG
          )} <ans/> ${translate.units.numberOfKg(rhsKg)} ${translate.units.numberOfG(rhsG)}`,
          answer: lessThanGreaterThanOrEqualTo(lhsKg * 1000 + lhsG, rhsKg * 1000 + rhsG)
        }
      : {
          sentence: isQuestionReversed
            ? `${translate.units.numberOfKg(lhsKg)} <ans/> ${translate.units.numberOfG(rhsG)}`
            : `${translate.units.numberOfG(lhsG)} <ans/> ${translate.units.numberOfKg(rhsKg)}`,
          answer: isQuestionReversed
            ? lessThanGreaterThanOrEqualTo(lhsKg * 1000, rhsG)
            : lessThanGreaterThanOrEqualTo(lhsG, rhsKg * 1000)
        };

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsCompareMasses()}
        pdfTitle={translate.instructions.useCardsCompareMasses()}
        actionPanelVariant="end"
        items={['<', '>', '=']}
        sentenceStyle={{ alignSelf: 'center' }}
        sentence={info.sentence}
        testCorrect={[info.answer]}
        moveOrCopy="move"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aJb',
  description: 'aJb',
  keywords: ['Mass', 'Measure', 'Grams', 'Kilograms', 'Compare', 'Order'],
  schema: z
    .object({
      startWithLightest: z.boolean(),
      kilogramA: z.number().int().min(10).max(100),
      gramsA: z.number().int().min(5).max(995),
      gramsB: z.number().int().min(5).max(995),
      kilogramB: z.number().int().min(10).max(100),
      gramsC: z.number().int().min(5).max(995),
      gramsD: z.number().int().min(5).max(995)
    })
    .refine(val => val.gramsA !== val.gramsB, 'gramsA cannot equal gramB')
    .refine(val => val.kilogramA !== val.kilogramB, 'kilogramA cannot equal kilogramB')
    .refine(val => val.gramsC !== val.gramsD, 'gramsC cannot equal gramsD'),
  simpleGenerator: () => {
    const startWithLightest = getRandomBoolean();

    const [kilogramA, kilogramB] = randomUniqueIntegersInclusive(10, 100, 2);
    const [gramsA, gramsB, gramsC, gramsD] = randomUniqueIntegersInclusive(5, 995, 4);

    return { startWithLightest, kilogramA, kilogramB, gramsA, gramsB, gramsC, gramsD };
  },
  Component: props => {
    const {
      question: { startWithLightest, kilogramA, kilogramB, gramsA, gramsB, gramsC, gramsD },
      translate
    } = props;

    const topLabel = startWithLightest ? translate.misc.Lightest() : translate.misc.Heaviest();
    const bottomLabel = startWithLightest ? translate.misc.Heaviest() : translate.misc.Lightest();

    const value1 = number(math.evaluate(`${kilogramA} * 1000 + ${gramsA}`));
    const value2 = number(math.evaluate(`${kilogramA} * 1000 + ${gramsB}`));
    const value3 = number(math.evaluate(`${kilogramB} * 1000 + ${gramsC}`));
    const value4 = number(math.evaluate(`${kilogramB} * 1000 + ${gramsD}`));

    const items = shuffle(
      [
        {
          value: value1,
          string: `${translate.units.numberOfKg(kilogramA)} ${translate.units.numberOfG(gramsA)}`
        },
        {
          value: value2,
          string: `${translate.units.numberOfKg(kilogramA)} ${translate.units.numberOfG(gramsB)}`
        },
        {
          value: value3,
          string: `${translate.units.numberOfKg(kilogramB)} ${translate.units.numberOfG(gramsC)}`
        },
        {
          value: value4,
          string: `${translate.units.numberOfKg(kilogramB)} ${translate.units.numberOfG(gramsD)}`
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF4DragOrderVertical
        title={translate.instructions.dragTheCardsToOrderTheMassesStartWithTheXMass(
          startWithLightest ? translate.misc.Lightest() : translate.misc.Heaviest()
        )}
        pdfTitle={translate.instructions.useCardsToOrderTheMassesStartWithTheXMass(
          startWithLightest ? translate.misc.Lightest() : translate.misc.Heaviest()
        )}
        testCorrect={sortNumberArray(
          [value1, value2, value3, value4],
          startWithLightest ? 'ascending' : 'descending'
        )}
        items={items.map(({ value, string }) => ({
          value,
          component: string
        }))}
        topLabel={topLabel}
        bottomLabel={bottomLabel}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareMass',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question4, Question5]
});
export default SmallStep;
