import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { countRange, sumNumberArray } from '../../../../utils/collections';
import { View } from 'react-native';
import { chunk } from '../../../../utils/chunk';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import Text from '../../../../components/typography/Text';
import { waterVolumeObjects } from '../../../../utils/objects';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdN',
  description: 'bdN',
  keywords: ['Volume', 'Capacity', 'Measure'],
  schema: z.object({
    cupsOfSand: z.number().int().min(2).max(10),
    incorrectAns: z.number().int().min(1).max(19)
  }),
  simpleGenerator: () => {
    const cupsOfSand = randomIntegerInclusive(2, 10);

    const incorrectAns = randomIntegerInclusive(1, cupsOfSand * 2 - 1, {
      constraint: x => x !== cupsOfSand
    });

    return { cupsOfSand, incorrectAns };
  },
  Component: props => {
    const {
      question: { cupsOfSand, incorrectAns },
      translate
    } = props;

    const correctAnswer = cupsOfSand * 2;

    const [optionA, optionB, optionC] = shuffle(
      [correctAnswer.toLocaleString(), incorrectAns.toLocaleString(), cupsOfSand.toLocaleString()],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.ks1Instructions.theCapacityOfOneJugIsXCupsOfSandSelectTheCapacityOfTwoJugs(
          cupsOfSand
        )}
        pdfTitle={translate.ks1PDFInstructions.theCapacityOfOneJugIsXCupsOfSandTickTheCapacityOfTwoJugs(
          cupsOfSand
        )}
        leftContent={
          <MeasureView>
            {dimens => {
              const icons = countRange(cupsOfSand).map(idx => (
                <AssetSvg
                  key={idx}
                  name={'cupOfSand'}
                  width={dimens.width * 0.15}
                  height={dimens.height * 0.15}
                />
              ));

              const chunkedIcons = chunk(icons, 5);

              return (
                <View style={{ ...dimens, justifyContent: 'space-evenly' }}>
                  {chunkedIcons.map((icons, idx) => (
                    <View key={idx} style={{ display: 'flex', flexDirection: 'row' }}>
                      {icons.map(icon => icon)}
                    </View>
                  ))}
                </View>
              );
            }}
          </MeasureView>
        }
        selectables={{
          optionA,
          optionB,
          optionC
        }}
        correctAnswer={
          optionA === correctAnswer.toString()
            ? ['optionA']
            : optionB === correctAnswer.toString()
            ? ['optionB']
            : ['optionC']
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdO',
  description: 'bdO',
  keywords: ['Volume', 'Capacity', 'Measure'],
  schema: z.object({
    numberOfGlasses: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const numberOfGlasses = randomIntegerInclusive(2, 10);

    return { numberOfGlasses };
  },

  Component: ({ question: { numberOfGlasses }, translate, displayMode }) => {
    const correctAnswer = numberOfGlasses * 2;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.xGlassesOfWaterFillOneJug(numberOfGlasses)}
        testCorrect={[correctAnswer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={'<ans/>'}
        Content={({ dimens }) => {
          const icons = countRange(numberOfGlasses).map(idx => (
            <AssetSvg
              key={idx}
              name={'Glasses_of_water/glass_water_full'}
              width={displayMode === 'digital' ? dimens.width * 0.2 : dimens.width * 0.2}
              height={displayMode === 'digital' ? dimens.height * 0.2 : dimens.height * 0.4}
            />
          ));
          const chunkedIcons = chunk(icons, 5);
          return (
            <>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 8,
                  height: dimens.height * 0.9,
                  justifyContent: 'center'
                }}
              >
                {chunkedIcons.map((icons, idx) => (
                  <View key={idx} style={{ display: 'flex', flexDirection: 'row' }}>
                    {icons.map(icon => icon)}
                  </View>
                ))}
              </View>
              <View style={{ alignItems: 'flex-start', width: dimens.width }}>
                <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                  {translate.ks1AnswerSentences.howManyGlassesFillTwoJugs()}
                </Text>
              </View>
            </>
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdP',
  description: 'bdP',
  keywords: ['Volume', 'Capacity', 'Measure'],
  schema: z.object({
    numberOfGlasses: z.number().int().min(2).max(8),
    amountOfGlassesVariant: z.number().int().min(2).max(6),
    waterObjects: z.array(z.object({ svg: z.string(), value: z.number() }))
  }),
  simpleGenerator: () => {
    const numberOfGlasses = randomIntegerInclusive(2, 8);
    const amountOfGlassesVariant = randomIntegerInclusive(2, 6);
    const titleVariant = getRandomBoolean();

    const waterObjects = countRange(amountOfGlassesVariant).map(_value => {
      const randomWaterObject = getRandomFromArray(waterVolumeObjects);

      return randomWaterObject as {
        svg: string;
        value: number;
      };
    });

    return {
      numberOfGlasses,
      amountOfGlassesVariant,

      titleVariant,
      waterObjects
    };
  },
  Component: props => {
    const {
      question: { numberOfGlasses, waterObjects },
      translate,
      displayMode
    } = props;

    const waterBottle = 'Water_bottle_glass';

    const greaterThan = translate.keywords['Greater than']().toLowerCase();
    const lessThan = translate.keywords['Less than']().toLowerCase();
    const theSameAs = translate.keywords['The same as']().toLowerCase();

    const total = sumNumberArray(waterObjects.map(icon => icon?.value));

    const icons = waterObjects.map((icon, idx) => (
      <AssetSvg key={idx} name={icon.svg as SvgName} width={displayMode === 'digital' ? 50 : 100} />
    ));

    const correctAnswer =
      numberOfGlasses > total ? lessThan : numberOfGlasses < total ? greaterThan : theSameAs;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.theWaterInTheGlassesFillsTheBottle()}
        pdfTitle={translate.ks1Instructions.theWaterInTheGlassesFillsTheBottle()}
        sentence={translate.ks1AnswerSentences.theCapacityOfTheBottleIsAnsXGlasses(numberOfGlasses)}
        sentencesStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 48 }}
        Content={
          <>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: 100,
                justifyContent: 'center',
                paddingBottom: 24
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: 18,
                  alignItems: 'flex-end'
                }}
              >
                {icons.map(icon => icon)}
              </View>
              <View>
                <AssetSvg name={waterBottle} width={50} />
              </View>
            </View>
            <Text
              style={{ fontSize: displayMode === 'digital' ? 32 : 48, alignSelf: 'flex-start' }}
            >
              {displayMode === 'digital'
                ? translate.ks1AnswerSentences.dragACardToCompleteTheSentence()
                : translate.ks1AnswerSentences.useTheCardsToCompleteTheSentence()}
            </Text>
          </>
        }
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        actionPanelVariant="bottom"
        questionHeight={1000}
        items={[greaterThan, lessThan, theSameAs]}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureCapacity',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
