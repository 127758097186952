import Text from 'common/components/typography/Text';
import { View, StyleSheet, ImageBackground, FlatList } from 'react-native';
import { useCallback, useRef, useState } from 'react';
import { type RootStackProps } from '../navigation/types';
import { useI18nContext } from '../i18n/i18n-react';
import { colors } from 'common/theme/colors';
import Spinner from 'common/components/molecules/Spinner';
import HomeMenu from '../components/HomeMenu';
import { useStudentQuizzes } from '../network/studentQuizzes';
import BaseScreen from 'common/components/screens/BaseScreen';
import useLoginStore, { withLoggedIn } from '../storage/useLoginStore';
import FilledButton from '../components/FilledButton';
import { Image } from 'expo-image';
import { useCheckCameraPermissions } from '../utils/permissions';
import { QUIZ_TILE_WIDTH, QuizTile } from '../components/QuizTile';
import { useFocusEffect } from '@react-navigation/native';
import useQuizSessionStore from '../storage/useQuizSessionStore';
import { createAssignedQuizSession, getExistingQuizSession } from '../network/quizSession';
import { getPlayer } from 'common/utils/Audio';
import ModalCard from 'common/components/modals/ModalCard';
import Button from 'common/components/atoms/Button';
import { useTheme } from 'common/theme';
import { Portal } from 'common/components/portal';
import { useStudentData } from '../network/studentData';
import { showErrorToast } from '../components/Toast';
import { SystemBars } from 'react-native-edge-to-edge';
import { AssetSvg } from 'common/assets/svg';
import IconButton from 'common/components/atoms/IconButton';

const QUIZ_TILE_GAP = 52;
const WIDTH = (QUIZ_TILE_WIDTH + QUIZ_TILE_GAP) * 4 - QUIZ_TILE_GAP;

/** Home screen when logged in as a pupil */
const PupilHomeScreen = withLoggedIn(function PupilHomeScreen({
  navigation
}: RootStackProps<'PupilHome'>) {
  const loggedInUser = useLoginStore(state => state.loggedInUser);
  if (!loggedInUser) {
    // Impossible for loggedInUser to be null inside a `withLoggedIn` HOC
    throw new Error(
      'Logic error: Logged in user is undefined, this should not be possible since we used `withLoggedIn`'
    );
  }

  const theme = useTheme();
  const translate = useI18nContext().LL;

  const setQuizSession = useQuizSessionStore(state => state.setQuizSession);
  const school = useLoginStore(state => state.school);

  const studentData = useStudentData(loggedInUser.profile.studentId);
  const studentQuizzes = useStudentQuizzes();

  // Keep track of whether the current screen is focused (this doesn't cause rerenders though!)
  const isFocused = useRef(false);
  useFocusEffect(
    useCallback(() => {
      isFocused.current = true;
      return () => (isFocused.current = false);
    }, [])
  );

  // Permissions
  const checkCameraPermissions = useCheckCameraPermissions();
  const checkPermissionsAndGoToScanQR = useCallback(() => {
    (async () => {
      // On success, *push* the ScanQR screen, so the back button goes back here (PupilHome)
      await checkCameraPermissions(() => navigation.push('ScanQR'));
    })();
  }, [checkCameraPermissions, navigation]);

  /** Callback for when something has gone wrong starting the quiz */
  const handleError = useCallback((title: string, message: string) => {
    showErrorToast({ title, message, extraDuration: 1000 });
  }, []);

  /** Start a new Quiz session from a quiz instance assignment ID. */
  const startNewQuizSession = useCallback(
    async (quizInstanceAssignmentId: number | undefined) => {
      if (quizInstanceAssignmentId === undefined) {
        // Dodgy data from backend
        handleError(
          translate.errorModals.somethingWentWrong(),
          translate.errorModals.pleaseTryAgainLater()
        );
        return;
      }

      const quizSessionResult = await createAssignedQuizSession({ quizInstanceAssignmentId });

      if (!isFocused.current) {
        // User cancelled by navigating away while we were communicating with the backend
        return;
      }

      if (typeof quizSessionResult === 'string') {
        // Request failed. Show a toast.
        switch (quizSessionResult) {
          case 'network error':
            handleError(
              translate.errorModals.internetConnectionLost(),
              translate.errorModals.thisAppRequiresAnInternetConnection()
            );
            return;
          case 'quiz locked':
            handleError(
              translate.errorModals.thisQuizIsLocked(),
              translate.errorModals.speakToYourTeacher()
            );
            return;
          case 'invalid response':
          case 'unknown error':
          case 'http error':
          case 'not found':
          case 'logged out':
            handleError(
              translate.errorModals.somethingWentWrong(),
              translate.errorModals.pleaseTryAgainLater()
            );
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${quizSessionResult satisfies never})`);
        }
      }

      // Create the audio handler with the correct settings
      getPlayer(quizSessionResult.quizSounds);

      // Go to the Quiz Screen
      setQuizSession(quizSessionResult);
      navigation.navigate('Quiz');
    },
    [handleError, navigation, setQuizSession, translate]
  );

  const [showResumeQuizModal, setShowResumeQuizModal] = useState<
    { quizInstanceAssignmentId: number | undefined; quizSessionId: string } | undefined
  >(undefined);

  /** Resume a quiz session from a quiz session ID */
  const resumeQuizSession = useCallback(
    async (quizInstanceAssignmentId: number | undefined, quizSessionId: string) => {
      if (quizInstanceAssignmentId === undefined) {
        // Dodgy data from backend
        handleError(
          translate.errorModals.somethingWentWrong(),
          translate.errorModals.pleaseTryAgainLater()
        );
        return;
      }

      const quizSessionResult = await getExistingQuizSession(quizSessionId);

      if (!isFocused.current) {
        // User cancelled by navigating away while we were communicating with the backend
        return;
      }

      if (typeof quizSessionResult === 'string') {
        // Request failed. Show a toast.
        switch (quizSessionResult) {
          case 'network error':
            handleError(
              translate.errorModals.internetConnectionLost(),
              translate.errorModals.thisAppRequiresAnInternetConnection()
            );
            return;
          case 'invalid response':
          case 'unknown error':
          case 'http error':
          case 'not found':
          case 'logged out':
            handleError(
              translate.errorModals.somethingWentWrong(),
              translate.errorModals.pleaseTryAgainLater()
            );
            return;
          default:
            // Produces TS error and throws runtime error if we missed a case
            throw new Error(`Logic error: unreachable (${quizSessionResult satisfies never})`);
        }
      }

      // Create the audio handler with the correct settings
      getPlayer(quizSessionResult.quizSounds);

      // Got to the quiz screen
      setQuizSession({
        ...quizSessionResult,
        retryInfo: { type: 'assigned', quizInstanceAssignmentId }
      });
      navigation.navigate('Quiz');
    },
    [handleError, navigation, setQuizSession, translate]
  );

  const noQuizzesView = (
    <View style={styles.menuActionsContainer}>
      <FilledButton
        icon={() => (
          <Image
            source={require('pupil-app/assets/svg/QRCodeIcon.svg')}
            style={{ width: 48, height: 48 }}
          />
        )}
        onPress={checkPermissionsAndGoToScanQR}
        testID="PUPIL_HOME_NO_QUIZ_SCAN_BUTTON"
      >
        {translate.home.scanQrCode()}
      </FilledButton>
      <FilledButton
        icon={() => (
          <Image
            source={require('pupil-app/assets/svg/Plus.svg')}
            style={{ width: 48, height: 48 }}
          />
        )}
        onPress={
          school !== undefined
            ? () => navigation.push('EnterQuizPIN')
            : () => navigation.push('EnterSchoolCode', { nextScreen: 'EnterQuizPIN' })
        }
        testID="PUPIL_HOME_NO_QUIZ_ENTER_PIN_BUTTON"
      >
        {translate.home.enterCode()}
      </FilledButton>
    </View>
  );

  const data = studentQuizzes.studentQuizzes?.pages.flat() ?? [];

  const flatList = studentQuizzes.studentQuizzes && (
    <FlatList
      numColumns={4}
      data={data}
      renderItem={({ item }) => (
        <QuizTile
          item={item}
          startQuiz={startNewQuizSession}
          showResumeRestartModal={(quizInstanceAssignmentId, quizSessionId) =>
            setShowResumeQuizModal({ quizInstanceAssignmentId, quizSessionId })
          }
        />
      )}
      keyExtractor={item => item.id.toString()}
      style={{ alignSelf: 'center' }}
      contentContainerStyle={{
        rowGap: 48,
        // For centering the ListEmptyComponent
        flex: data.length === 0 ? 1 : undefined
      }}
      columnWrapperStyle={{ columnGap: QUIZ_TILE_GAP }}
      showsVerticalScrollIndicator={false}
      ListHeaderComponentStyle={styles.introComponent}
      ListEmptyComponent={noQuizzesView}
      ListFooterComponent={
        data.length === 0 ? null : (
          <View style={{ height: 300, justifyContent: 'center' }}>
            {studentQuizzes.loadingNextPage && (
              <>
                <Spinner height={156} />
                <Text
                  variant="WRN400"
                  style={{ color: colors.white, fontSize: 32, lineHeight: 48 }}
                >
                  {translate.loadingEllipsis()}
                </Text>
              </>
            )}
          </View>
        )
      }
      onEndReachedThreshold={0.1}
      onEndReached={() => studentQuizzes.loadNextPage?.()}
    />
  );

  const errorMessages = (
    <View testID="STUDENT_QUIZZES_ERROR_MESSAGE">
      <Text
        style={{
          color: colors.white,
          fontSize: 32
        }}
      >
        {translate.dashboard.studentQuizzesError()}
      </Text>
    </View>
  );

  const loadingSpinnerOverlay = (
    <View
      key="loading"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <View style={{ justifyContent: 'center' }}>
        <Spinner height={156} />
        <Text variant="WRN400" style={{ color: colors.white, fontSize: 32, lineHeight: 48 }}>
          {translate.loadingEllipsis()}
        </Text>
      </View>
    </View>
  );

  const resumeQuizModal = showResumeQuizModal && (
    <ModalCard
      onDismiss={() => setShowResumeQuizModal(undefined)}
      containerStyle={styles.modalContainer}
    >
      {/* Close button */}
      <IconButton
        iconWidth={23}
        onPress={() => setShowResumeQuizModal(undefined)}
        style={styles.iconClose}
        // Use same color as Modal background
        baseColor={theme.colors.surface}
      >
        <AssetSvg name="Close" />
      </IconButton>

      <Text variant="WRN700">{translate.dashboard.restartOrContinueTheQuiz()}</Text>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          columnGap: 40,
          marginTop: 32
        }}
        testID="PupilHomeScreen_QuizTileModal_Buttons"
      >
        <Button
          baseColor={theme.colors.background}
          onPress={() => {
            startNewQuizSession(showResumeQuizModal.quizInstanceAssignmentId);
            setShowResumeQuizModal(undefined);
          }}
          style={{ width: 270, height: 96 }}
        >
          {translate.misc.restart()}
        </Button>
        <Button
          baseColor={theme.colors.secondaryContainer}
          onPress={() => {
            resumeQuizSession(
              showResumeQuizModal.quizInstanceAssignmentId,
              showResumeQuizModal.quizSessionId
            );
            setShowResumeQuizModal(undefined);
          }}
          style={{ width: 270, height: 96 }}
        >
          {translate.misc.continue()}
        </Button>
      </View>
    </ModalCard>
  );

  return (
    <ImageBackground
      source={require('pupil-app/assets/images/SpaceBackground.png')}
      resizeMode="cover"
      fadeDuration={0}
      style={{
        flex: 1,
        justifyContent: 'center',
        backgroundColor: colors.prussianBlue
      }}
    >
      <SystemBars style="light" />
      <BaseScreen>
        {/* Modals */}
        <Portal>
          {resumeQuizModal}
          {studentData.status === 'loading' ||
            (studentQuizzes.status === 'loading' && loadingSpinnerOverlay)}
        </Portal>

        <View
          style={{
            flex: 1,
            alignSelf: 'center',
            width: WIDTH,
            justifyContent: 'flex-start'
          }}
        >
          {/* Menu header */}
          <HomeMenu
            menuActive={() => navigation.push('PupilMenu')}
            firstName={loggedInUser.profile.firstName}
            lastName={loggedInUser.profile.lastName}
            stars={studentData?.studentData?.stars ?? 0}
          />

          {/* Main body */}
          {studentData.status === 'error' || studentQuizzes.status === 'error'
            ? errorMessages
            : flatList}
        </View>
      </BaseScreen>
    </ImageBackground>
  );
});

export default PupilHomeScreen;

const styles = StyleSheet.create({
  menuActionsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    rowGap: 32
  },
  introText: {
    color: colors.white,
    fontWeight: '700',
    fontSize: 32
  },
  introComponent: {
    alignSelf: 'flex-start',
    paddingBottom: 0
  },
  modalContainer: { alignItems: 'center', rowGap: 8, height: 324, width: 708 },
  iconClose: {
    position: 'absolute',
    right: 10,
    top: 3,
    zIndex: 2
  }
});
