import { Context, createContext, PropsWithChildren, useContext } from 'react';
import * as zustand from 'zustand';
import { useRefWithInitializer } from './react';

/**
 * A combination of the benefits of a zustand store, with react context.
 *
 * ### Why?
 *
 * Zustand stores are much better than react context at implementing a global store. For example,
 * {@link https://github.com/reactjs/rfcs/pull/119 react context selectors haven't been implemented yet}.
 * Also, zustand is not opinionated, and can be easily used to create small stores for specific purposes.
 * However, it is always globally scoped, whereas for some use cases we may want to use it more like react context,
 * where only the nearest store in the hierarchy to the calling component is used.
 *
 * This is discussed in the
 * {@link https://github.com/pmndrs/zustand#react-context zustand documentation}
 * and we basically just use their approach but slightly more ergonomically.
 *
 * ### What?
 *
 * This function creates a react context (i.e. a Provider component, and a hook for accessing that component). Since
 * the provider can be placed in the component hierarchy, the store is effectively scoped to that piece of the
 * hierarchy. The hook can be used exactly like standard zustand usage: you provide a selector and it returns that
 * data from the store.
 *
 * ### Usage
 *
 * In one place, at the global scope, write the the following:
 *
 * ```
 * type BearStoreState = {
 *   bears: number,
 *   increasePopulation: () => void,
 *   removeAllBears: () => void,
 * };
 *
 * export const {
 *   Provider: BearStoreProvider,
 *   useStore: useBearStore
 * } = createStoreWithContext<BearStoreState>(set => ({
 *   bears: 0,
 *   increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
 *   removeAllBears: () => set({ bears: 0 }),
 * }));
 * ```
 *
 * Then somewhere in your hierarchy:
 *
 * ```
 * <BearStoreProvider>
 *   ...
 * </BearStoreProvider>
 * ```
 *
 * Then your components, which are decendants of BearStoreProvider, might look like:
 *
 * ```
 * function BearCounter() {
 *   const bears = useBearStore((state) => state.bears);
 *   return <Text>{bears} around here ...</Text>;
 * }
 *
 * function Controls() {
 *   const increasePopulation = useBearStore((state) => state.increasePopulation);
 *   return <Button onClick={increasePopulation}>one up</Button>;
 * }
 * ```
 */
export function createStoreWithContext<State extends Record<string, unknown>>(
  initialStoreState: zustand.StateCreator<State>
): StoreWithContext<State> {
  const globalStore = zustand.createStore<State>(initialStoreState);
  const context = createContext<zustand.StoreApi<State>>(globalStore);

  return {
    globalStore,
    context,
    Provider: ({ children }) => {
      const store = useRefWithInitializer<zustand.StoreApi<State>>(() =>
        zustand.createStore<State>(initialStoreState)
      ).current;
      return <context.Provider value={store}>{children}</context.Provider>;
    },
    useStore: (selector, equalityFn) => {
      const store = useContext(context);
      return zustand.useStore(store, selector, equalityFn);
    }
  };
}

export type StoreWithContext<State extends Record<string, unknown>> = {
  /** This global store is used if Provider is not found in the hierarchy. */
  globalStore: zustand.StoreApi<State>;

  /**
   * The react context for the store. The nearest provider in the hierarchy is chosen when using this context, and
   * if none are available it uses globalStore.
   */
  context: Context<zustand.StoreApi<State>>;

  /** A react context provider holding an instance of the store. */
  Provider: (props: PropsWithChildren) => JSX.Element;

  /**
   * React hook for accessing the store of the nearest {@link Provider} in the hierarchy.
   *
   * @param selector A function describing which part of the store you would like to access
   * @param equalityFn (Optional) An equality function used when deciding if your selector is returning a new value,
   * which requires a re-render. Defaults to `===`, but you may want to override it if your selector returns
   * non-primitives such as objects or arrays.
   */
  useStore: <U>(selector: (state: State) => U, equalityFn?: (a: U, b: U) => boolean) => U;
};
