import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import {
  findExchanges,
  numbersDoNotExchange,
  numbersOnlyExchangeAt
} from '../../../../utils/exchanges';
import ColumnOperations from '../../../../components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import { SUB } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { range } from '../../../../utils/collections';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation } from '../../../../utils/math';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ahk',
  description: 'ahk',
  keywords: ['Place value chart', 'Place value counters'],
  schema: z
    .object({
      minuendOnes: z.number().int().min(1).max(9),
      minuendTens: z.number().int().min(1).max(9),
      minuendHundreds: z.number().int().min(1).max(9),
      subtrahendOnes: z.number().int().min(1).max(9),
      subtrahendTens: z.number().int().min(1).max(9)
    })
    .refine(val =>
      numbersDoNotExchange(
        val.minuendHundreds * 100 + val.minuendTens * 10 + val.minuendOnes,
        -(val.subtrahendTens * 10 + val.subtrahendOnes)
      )
    ),
  questionHeight: 1100,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const minuendOnes = randomIntegerInclusive(1, 9);

        const minuendTens = randomIntegerInclusive(1, 9);

        const minuendHundreds = randomIntegerInclusive(1, 9);

        const subtrahendOnes = randomIntegerInclusive(1, 9);

        const subtrahendTens = randomIntegerInclusive(1, 9);

        return { minuendOnes, minuendTens, minuendHundreds, subtrahendOnes, subtrahendTens };
      },
      val =>
        numbersDoNotExchange(
          val.minuendHundreds * 100 + val.minuendTens * 10 + val.minuendOnes,
          -(val.subtrahendTens * 10 + val.subtrahendOnes)
        )
    ),
  Component: props => {
    const {
      question: { minuendOnes, minuendTens, minuendHundreds, subtrahendOnes, subtrahendTens },
      translate
    } = props;

    const minuend = minuendOnes + minuendTens * 10 + minuendHundreds * 100;

    const subtrahend = subtrahendOnes + subtrahendTens * 10;

    return (
      <QF1ContentAndSentence
        sentence={`${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()} = <ans/>`}
        title={translate.instructions.completeCalculation()}
        testCorrect={[(minuend - subtrahend).toString()]}
        questionHeight={1100}
        pdfDirection="column"
        Content={({ dimens }) => {
          return (
            <PlaceValueChart
              columnsToShow={[2, 1, 0]}
              counterVariant="decimalCounter"
              dimens={dimens}
              number={ScientificNotation.fromNumber(minuend)}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ahl',
  description: 'ahl',
  keywords: ['Subtraction', 'Counters', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(10)
        .max(100)
        .refine(x => x % 10 !== 0)
    })
    .refine(
      val =>
        numbersOnlyExchangeAt(val.var1, -val.var2, 'tens') ||
        numbersOnlyExchangeAt(val.var1, -val.var2, 'ones'),
      'must only an exchange at the ones or tens.'
    )
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const exchange = getRandomFromArray(['ones', 'tens'] as const);
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have exchanges at the ones/tens and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => numbersOnlyExchangeAt(var1, -var2, exchange) && var1 - var2 >= 15
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'ahm',
  description: 'ahm',
  keywords: ['Subtraction', 'Counters', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(101)
        .max(999)
        .refine(x => x % 10 !== 0),
      var2: z
        .number()
        .int()
        .min(10)
        .max(100)
        .refine(x => x % 10 !== 0)
    })
    .refine(
      val => findExchanges(val.var1, -val.var2).length === 2,
      'must have exchanges at the ones ond tens.'
    )
    .refine(
      val => val.var1 - val.var2 >= 15,
      'The two numbers need to have a minimum difference of 15'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have exchanges at the ones and tens and the difference between the two numbers is atleast 15.
      ({ var1, var2 }) => findExchanges(var1, -var2).length === 2 && var1 - var2 >= 15
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 - var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={var1}
        bottomNumber={var2}
        operation={SUB}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(number3, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ahn',
  description: 'ahn',
  keywords: ['Bar model', 'Subtraction', 'Difference'],
  schema: z.object({
    total: z.number().int().min(100).max(999),
    givenPart: z.number().int().min(11).max(99),
    incorrectAnswerCDifference: numberEnum([-10, 10])
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(100, 999);

    const givenPart = randomIntegerInclusive(11, 99);

    const incorrectAnswerCDifference = getRandomFromArray([-10, 10] as const);

    return { total, givenPart, incorrectAnswerCDifference };
  },

  Component: props => {
    const {
      question: { total, givenPart, incorrectAnswerCDifference },
      translate
    } = props;

    const missingPart = total - givenPart;

    const items = shuffle(
      [missingPart, missingPart + 1, missingPart - 1, missingPart + incorrectAnswerCDifference],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectMissingValue()}
        pdfTitle={translate.instructions.circleMissingValue()}
        testCorrect={[missingPart]}
        numItems={4}
        Content={({ dimens }) => (
          <BarModel
            numbers={[[total], [givenPart, missingPart]]}
            strings={[[total.toLocaleString()], [givenPart.toLocaleString(), '']]}
            total={total}
            dimens={dimens}
            cellColors={[[], [undefined, colors.white]]}
            oneFontSize
          />
        )}
        renderItems={items.map(string => ({
          value: string,
          component: <Text variant="WRN700">{string.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aho',
  description: 'aho',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(989),
      numberA2: z.number().int().min(10).max(99),
      numberB1: z.number().int().min(100).max(989),
      numberB2: z.number().int().min(10).max(99),
      numberC1: z.number().int().min(100).max(989),
      numberC2: z.number().int().min(10).max(99),
      numberD1: z.number().int().min(100).max(989),
      numberD2: z.number().int().min(10).max(99)
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.numberB2),
      'numberB1 and numberB2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberC1, val.numberC2),
      'numberC1 and numberC2 must not exchange.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberD1, val.numberD2),
      'numberD1 and numberD2 must not exchange.'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberA1 = randomIntegerInclusive(100, 989);
        const numberA2 = randomIntegerInclusive(10, 99);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => numbersDoNotExchange(numberA1, numberA2)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberB1 = randomIntegerInclusive(100, 989);
        const numberB2 = randomIntegerInclusive(10, 99);
        return { numberB1, numberB2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberB1, numberB2 }) => numbersDoNotExchange(numberB1, numberB2)
    );

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberC1 = randomIntegerInclusive(100, 989);
        const numberC2 = randomIntegerInclusive(10, 99);
        return { numberC1, numberC2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberC1, numberC2 }) => numbersDoNotExchange(numberC1, numberC2)
    );

    const { numberD1, numberD2 } = rejectionSample(
      () => {
        // Generate 2 random integers.
        const numberD1 = randomIntegerInclusive(100, 989);
        const numberD2 = randomIntegerInclusive(10, 99);
        return { numberD1, numberD2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberD1, numberD2 }) => numbersDoNotExchange(numberD1, numberD2)
    );

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        topNumber: numberA2 * 10,
        bottomNumber: numberA1 + numberA2,
        isCorrect: false,
        topBlankDigits: [0],
        bottomBlankDigits: []
      };
      const eqB = {
        topNumber: numberB1 + numberB2,
        bottomNumber: numberB2 * 10,
        isCorrect: false,
        topBlankDigits: [],
        bottomBlankDigits: [0]
      };
      const eqC = {
        topNumber: numberC1 + numberC2,
        bottomNumber: numberC2,
        isCorrect: false,
        topBlankDigits: [1],
        bottomBlankDigits: []
      };
      const eqD = {
        topNumber: numberD1 + numberD2,
        bottomNumber: numberD2,
        isCorrect: true,
        topBlankDigits: [],
        bottomBlankDigits: []
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      props.question
    ]);

    const correctAnswer = eqs.filter(eq => eq.isCorrect);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheColumnSubtractionThatIsLaidOutCorrectly()}
        pdfTitle={translate.instructions.circleTheColumnSubtractionThatIsLaidOutCorrectly()}
        testCorrect={correctAnswer}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={SUB}
                dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                removeExtraCells
                topBlankDigits={equation.topBlankDigits}
                bottomBlankDigits={equation.bottomBlankDigits}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'ahp',
  description: 'ahp',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      number1: z.number().int().min(10).max(99),
      number2: z.number().int().min(101).max(879)
    })
    .refine(
      val =>
        numbersOnlyExchangeAt(val.number1, val.number2, 'ones') ||
        numbersOnlyExchangeAt(val.number1, val.number2, 'tens'),
      'number1 and number2 must exchange at either the ones or tens only.'
    ),
  simpleGenerator: () => {
    const onesOrTensExchange = randomIntegerInclusive(0, 1);

    const number2 =
      onesOrTensExchange === 0
        ? randomIntegerInclusive(101, 879, {
            constraint: x =>
              x % 10 !== 0 && // Ensure number1 does not end in 0 so number1 and number2 can exchange at the ones
              x % 100 < 80 // Ensure number1 has less than 8 tens to ensure number1 and number2 do not exchange at the tens
          })
        : randomIntegerInclusive(110, 799, {
            constraint: x => x % 100 > 9 // Ensure number1 ends in at least 10 so number1 and number2 can exchange at the tens
          });

    const number1 = randomIntegerInclusive(10, 99, {
      constraint: x => numbersOnlyExchangeAt(number2, x, [onesOrTensExchange])
    });

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[number2.toString()]}
        sentence={`${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()} = <ans/>`}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractA2DigitNumberFromA3DigitNumber',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
