import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import { parseMarkup } from '../../../markup';
import { SetState } from '../../../utils/react';
import { withStateHOC } from '../../../stateTree';
import { filledArray } from '../../../utils/collections';
import { QuadrilateralColors, QuadrilateralShapes } from '../../../utils/quadrilateralImages';

import { LabelledQuadrilateral } from './LabelledQuadrilateral';

type UserAnswer = string;

type Props = {
  userAnswer: UserAnswer[];
  setUserAnswer: SetState<UserAnswer[]>;
  /**
   * @param labelsLarge An array of strings to hold the labels that go around the shape
   * Should be given in form [top, right, bottom, left]
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> labels should return an array of strings
   *
   */
  labelsLarge: string[];
  /**
   * @param labelsSmall An array of strings to hold the labels that go around the shape
   * Should be given in form [top, right, bottom, left] and be an array of strings
   *
   */
  labelsSmall: string[];
  /**
   * which shape to render
   */
  shape: QuadrilateralShapes;
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  color?: QuadrilateralColors;
  /**
   * Text to go after answer box
   */
  symbol?: string;
};

/**
 * This component renders a quadrilateral with labels on the sides.
 * The <ans/> string is used to annotate where a user answer input field is require.
 * At the moment the spacing works well for 3 labelled sides and below.
 * If we need 4 labels we will need to decrease the size of svgs via smallHeight and largeHeight
 */
export const LabelledSmallLargeQuadrilaterals = (props: Props) => {
  const {
    userAnswer = [],
    setUserAnswer = () => {},
    labelsLarge,
    labelsSmall,
    shape,
    color,
    dimens,
    symbol
  } = props;
  const styles = useStyles(dimens.width, dimens.height);
  const isLargeShape = ['Square', 'Rhombus', 'Kite'].includes(shape);

  return (
    <View style={[styles.containerHorizontal]}>
      <LabelledQuadrilateral
        labels={labelsSmall}
        shape={shape}
        dimens={dimens}
        shapeSize={'small'}
        centerLabel="A"
        color={color}
        symbol={symbol}
      />

      <LabelledQuadrilateral
        labels={labelsLarge}
        shape={shape}
        color={color}
        symbol={symbol}
        dimens={dimens}
        centerLabel="B"
        userAnswer={userAnswer}
        setUserAnswer={setUserAnswer}
        imageWidthScale={isLargeShape ? 0.25 : 0.275}
      />
    </View>
  );
};

export const LabelledSmallLargeQuadrilateralsWithState = withStateHOC(
  LabelledSmallLargeQuadrilaterals,
  {
    stateProp: 'userAnswer',
    setStateProp: 'setUserAnswer',
    defaults: props => ({
      defaultState: filledArray('', getAnswerCount(props)),
      testComplete: userAnswer => userAnswer.every(i => i !== '')
    })
  }
);

const getAnswerCount = (props: Omit<Props, 'userAnswer' | 'setUserAnswer'>) => {
  return props.labelsLarge.map(label => parseMarkup(label)).filter(i => i.numberOfAns > 0).length;
};

const useStyles = (width: number, height: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        containerHorizontal: {
          flexDirection: 'row',
          width: width,
          height: height,
          alignItems: 'center',
          justifyContent: 'space-evenly'
        }
      }),
    [width, height]
  );
};
