import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { filledArray, arrayHasNoDuplicates } from '../../../../utils/collections';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import { AssetSvg } from '../../../../assets/svg';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TextStructure from '../../../../components/molecules/TextStructure';
import { fractionToDecimal, portionToText } from '../../../../utils/fractions';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import { NumberLineVariableTick } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';
import { horizontalDoubleEndedArrow } from '../../../../components/question/representations/LineSvgs';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aIk',
  description: 'aIk',
  keywords: ['Number line', 'Fraction', 'Numerator', 'Denominator', 'Intervals'],
  schema: z.object({
    numberLineParts: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const numberLineParts = randomIntegerInclusive(2, 8);

    return { numberLineParts };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { numberLineParts },
      translate,
      displayMode
    } = props;

    const tickValues = filledArray(0, Number(numberLineParts + 1)).map((_, i) =>
      i === 0 ? '0' : i === numberLineParts ? '1' : ''
    );

    return (
      <QF1ContentAndSentence
        questionHeight={1000}
        pdfDirection="column"
        sentence={translate.answerSentences.theNumberLineIsCountingUpInFrac({
          frac: '<frac nAns="" dAns=""/>'
        })}
        customMarkSchemeAnswer={{ answersToDisplay: ['1', numberLineParts.toString()] }}
        title={translate.instructions.completeSentence()}
        testCorrect={['1', numberLineParts.toString()]}
        Content={({ dimens }) => {
          return (
            <View style={{ top: 50 }}>
              <View style={{ flexDirection: 'row', left: 40, top: 105 }}>
                {filledArray(0, numberLineParts).map((_, i) => {
                  return (
                    <View
                      key={i}
                      style={{
                        width:
                          displayMode === 'digital'
                            ? (dimens.width - 80) / numberLineParts
                            : (dimens.width - 180) / numberLineParts
                      }}
                    >
                      {horizontalDoubleEndedArrow(
                        (displayMode === 'digital' ? dimens.width - 80 : dimens.width - 180) /
                          numberLineParts
                      )}
                    </View>
                  );
                })}
              </View>
              <NumberLine tickValues={tickValues} dimens={dimens} />
            </View>
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aIl',
  description: 'aIl',
  keywords: ['Number line', 'Fraction', 'Numerator', 'Denominator', 'Intervals', 'Parts'],
  schema: z.object({
    numberLineParts: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const numberLineParts = randomIntegerInclusive(2, 8);

    return { numberLineParts };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { numberLineParts },
      translate
    } = props;

    const tickValues = filledArray(0, Number(numberLineParts + 1)).map((_, i) =>
      i === 0 ? '0' : i === numberLineParts ? '1' : ''
    );

    return (
      <QF1ContentAndSentences
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [[numberLineParts.toString()], [numberLineParts.toString()]]
        }}
        pdfDirection="column"
        sentences={[
          translate.answerSentences.theNumberLineIsSplitIntoAnsParts(),
          translate.answerSentences.theNumberLineIsCountingUpInFrac({
            frac: '<frac n="1" dAns=""/>'
          })
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={[[numberLineParts.toString()], [numberLineParts.toString()]]}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aIm',
  description: 'aIm',
  keywords: ['Number line', 'Parts', 'Equal', 'Unequal', 'Intervals'],
  schema: z.object({
    numberLineParts: z.number().int().min(2).max(8),
    unequalLineParts: z.array(z.number()),
    numberForFraction: z.number().int().min(2).max(8),
    name: nameSchema,
    equalOrUnequal: z.enum(['equal', 'unequal'])
  }),
  simpleGenerator: () => {
    const numberForFraction = randomIntegerInclusive(2, 8);

    const numberLineParts = randomIntegerInclusive(2, numberForFraction);

    const name = getRandomName();
    const equalOrUnequal = getRandomFromArray(['equal', 'unequal'] as const);

    const unequalLineParts = randomUniqueIntegersInclusive(10, 100, numberLineParts - 1, {
      constraint: x => x % numberLineParts !== 0
    });
    unequalLineParts.push(0, 100);

    return { numberLineParts, name, numberForFraction, equalOrUnequal, unequalLineParts };
  },
  Component: ({
    question: { numberLineParts, name, numberForFraction, equalOrUnequal, unequalLineParts },
    translate,
    displayMode
  }) => {
    const fraction = portionToText(numberForFraction, translate);

    const testCorrect = () => {
      if (equalOrUnequal === 'unequal') {
        return false;
      }
      switch (fraction) {
        case 'halves': {
          return numberLineParts === 2;
        }
        case 'thirds': {
          return numberLineParts === 3;
        }
        case 'quarters': {
          return numberLineParts === 4;
        }
        case 'fiths': {
          return numberLineParts === 5;
        }
        case 'sixths': {
          return numberLineParts === 6;
        }
        case 'sevenths': {
          return numberLineParts === 7;
        }
        case 'eighths': {
          return numberLineParts === 8;
        }
        default: {
          return false;
        }
      }
    };

    const tickValuesArray = filledArray(0, Number(numberLineParts + 1));

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.characterIsLabellingFracOnANumberLine({ name: name })}
        correctAnswer={testCorrect()}
        sentence={
          displayMode === 'digital'
            ? `${translate.instructions.isStatementTrueOrFalse()} ${translate.instructions.selectYourAnswer()}`
            : `${translate.instructions.isStatementTrueOrFalse()} ${translate.instructions.circleYourAnswer()}`
        }
        content={({ dimens }) => (
          <View>
            {equalOrUnequal === 'equal' ? (
              <NumberLine
                tickValues={tickValuesArray.map(() => '')}
                dimens={{ height: dimens.height / 3, width: dimens.width }}
              />
            ) : (
              <NumberLineVariableTick
                dimens={{ height: dimens.height / 3, width: dimens.width }}
                tickValues={unequalLineParts.map(number => {
                  return { label: '', position: number };
                })}
                start={0}
                end={100}
              />
            )}

            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <SpeechBubble
                flickLocation="bottom-right"
                style={{ margin: 20, alignSelf: 'flex-start' }}
                textStyle={{ marginVertical: 10 }}
              >
                {translate.instructions.theNumberLineShowsX(`${fraction}`)}
              </SpeechBubble>

              <AssetSvg
                name={getCharacterHeadSvgName(name)}
                height={dimens.height * 0.4}
                width={dimens.width * 0.3}
              />
            </View>
          </View>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aIn',
  description: 'aIn',
  keywords: ['Number line', 'Intervals', 'Equal', 'Parts'],
  schema: z.object({
    optionsArray: z
      .array(z.number().int().min(1).max(8))
      .length(4)
      .refine(val => arrayHasNoDuplicates(val), 'optionsArray must contain 4 different values.')
  }),
  simpleGenerator: () => {
    const optionsArray = randomUniqueIntegersInclusive(1, 8, 4);

    return { optionsArray };
  },
  Component: props => {
    const {
      question: { optionsArray },
      displayMode,
      translate
    } = props;

    const statements = shuffle(optionsArray, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.matchNumberLinesToIntervals()}
        items={optionsArray}
        statementStyle={{
          justifyContent: 'center',
          columnGap: displayMode === 'digital' ? 32 : 32
        }}
        statements={statements.map(option => ({
          lhsComponent: (
            <View style={{ alignItems: 'flex-end', top: displayMode === 'digital' ? 0 : 40 }}>
              <NumberLine
                tickValues={filledArray(0, Number(option + 1)).map(() => '')}
                dimens={
                  displayMode === 'digital'
                    ? { height: 100, width: 400 }
                    : { height: 150, width: 600 }
                }
              />
            </View>
          ),
          correctAnswer: option
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aIo',
  description: 'aIo',
  keywords: [
    'Number line',
    'Intervals',
    'Parts',
    'Equal',
    'Numerator',
    'Denominator',
    'Unit fraction'
  ],
  schema: z.object({
    denominatorA: z.number().int().min(4).max(10),
    denominatorB: z.number().int().min(3).max(11),
    denominatorC: z.number().int().min(4).max(10),
    numeratorA: z.number().int().min(3).max(11)
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(4, 10);
    const denominatorB = getRandomFromArray([denominatorA + 1, denominatorA - 1]);
    const denominatorC = randomIntegerInclusive(4, 10, {
      constraint: x => x !== denominatorA && x !== denominatorB
    });
    const numeratorA = getRandomFromArray([denominatorA, denominatorB, denominatorC]);

    return { denominatorA, denominatorB, denominatorC, numeratorA };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, denominatorC, numeratorA },
      translate,
      displayMode
    } = props;

    const statements = shuffle(
      [
        { value: fractionToDecimal(1, denominatorC), text: `<frac n='1' d='${denominatorC}' />` },
        { value: fractionToDecimal(1, denominatorB), text: `<frac n='1' d='${denominatorB}' />` },
        { value: fractionToDecimal(1, denominatorA), text: `<frac n='1' d='${denominatorA}' />` },
        {
          value: fractionToDecimal(numeratorA, 1),
          text: `<frac n='${numeratorA}' d='1' />`
        }
      ],
      { random: seededRandom(props.question) }
    );

    const tickValues = filledArray(0, denominatorA + 1).map((_, i) =>
      i === 0 ? '0' : i === denominatorA ? '1' : ''
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.whichFracShownOnNumberLine()}
        pdfTitle={translate.instructions.whichFracShownOnNumberLinePDF()}
        testCorrect={[fractionToDecimal(1, denominatorA)]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ top: 50 }}>
            <View
              style={{
                width:
                  displayMode === 'digital'
                    ? Math.ceil((dimens.width / denominatorA) * 0.93)
                    : Math.floor((dimens.width / denominatorA) * 0.925),
                top: 40,
                left: displayMode === 'digital' ? 39 : 38
              }}
            >
              <BarModelCurlyBrace braceText="?" />
            </View>
            <NumberLine
              tickValues={tickValues}
              dimens={{ height: dimens.height / 2, width: dimens.width }}
            />
          </View>
        )}
        renderItems={statements.map(statement => ({
          value: statement.value,
          component: (
            <TextStructure
              sentence={statement.text}
              fractionTextStyle={{ fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          )
        }))}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'aIp',
  description: 'aIp',
  keywords: ['Fractions', 'Number line', 'Intervals'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    incorrectVariant: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const incorrectVariant = getRandomBoolean();

    return {
      number1,
      incorrectVariant
    };
  },
  Component: props => {
    const {
      question: { number1, incorrectVariant },
      translate
    } = props;

    const statements = shuffle(
      [
        {
          value: number1,
          isCorrect: true
        },
        {
          value: incorrectVariant ? number1 + 1 : number1 - 1,
          isCorrect: false
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.selectTheNumberLineThatIsCountingInFrac(
          `<frac n='1' d='${number1}' />`
        )}`}
        pdfTitle={`${translate.instructions.circleTheNumberLineThatIsCountingInFrac(
          `<frac n='1' d='${number1}' />`
        )}`}
        itemLayout="column"
        innerContainerStyle={{ rowGap: 24 }}
        testCorrect={statements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.value)}
        numItems={2}
        renderItems={({ dimens }) =>
          statements.map(statement => ({
            value: statement.value,
            component: (
              <NumberLine
                tickValues={filledArray(0, statement.value + 1).map((_, i) =>
                  i === 0 ? '0' : i === statement.value ? '1' : ''
                )}
                dimens={{ height: dimens.height / 2, width: dimens.width }}
              />
            )
          }))
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FractionsOnANumberLine',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
