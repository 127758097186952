// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import FactorPairs from './1FactorPairs';
import UseFactorPairs from './2UseFactorPairs';
import MultiplyBy10 from './3MultiplyBy10';
import MultiplyBy100 from './4MultiplyBy100';
import DivideBy10 from './5DivideBy10';
import DivideBy100 from './6DivideBy100';
import RelatedFactsMultiplicationAndDivision from './7RelatedFactsMultiplicationAndDivision';
import InformalWrittenMethodsForMultiplication from './8InformalWrittenMethodsForMultiplication';
import MultiplyA2DigitNumberByA1DigitNumber from './9MultiplyA2DigitNumberByA1DigitNumber';
import MultiplyA3DigitNumberByA1DigitNumber from './10MultiplyA3DigitNumberByA1DigitNumber';
import DivideA2DigitNumberByA1DigitNumber1 from './11DivideA2DigitNumberByA1DigitNumber1';
import DivideA2DigitNumberByA1DigitNumber2 from './12DivideA2DigitNumberByA1DigitNumber2';
import DivideA3DigitNumberByA1DigitNumber from './13DivideA3DigitNumberByA1DigitNumber';
import CorrespondenceProblems from './14CorrespondenceProblems';
import EfficientMultiplication from './15EfficientMultiplication';

const Block = newBlockContent({
  block: 'MultiplicationAndDivisionB',
  field: 'Number',
  weeks: [1, 3],
  smallSteps: [
    FactorPairs, // Step 1
    UseFactorPairs, // Step 2
    MultiplyBy10, // Step 3
    MultiplyBy100, // Step 4
    DivideBy10, // Step 5
    DivideBy100, // Step 6
    RelatedFactsMultiplicationAndDivision, // Step 7
    InformalWrittenMethodsForMultiplication, // Step 8
    MultiplyA2DigitNumberByA1DigitNumber, // Step 9
    MultiplyA3DigitNumberByA1DigitNumber, // Step 10
    DivideA2DigitNumberByA1DigitNumber1, // Step 11
    DivideA2DigitNumberByA1DigitNumber2, // Step 12
    DivideA3DigitNumberByA1DigitNumber, // Step 13
    CorrespondenceProblems, // Step 14
    EfficientMultiplication // Step 15
  ]
});
export default Block;
