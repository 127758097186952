import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { portionToText } from '../../../../utils/fractions';
import { compareFractions } from '../../../../utils/fractions';
import PieChart from '../../../../components/question/representations/PieChart';
import MultiPieChart, {
  calculateRadius
} from '../../../../components/question/representations/MultiPieChart';
import { PieChartColors } from '../../../../theme/colors';
import QF20bInteractiveBarModelWithSentence from '../../../../components/question/questionFormats/QF20bInteractiveBarModelWithSentence';
import { foodFractionObjectSchema, getRandomFoodFractionObject } from '../../../../utils/objects';
import { getFoodFractionObjectSvgName } from '../../../../utils/foodFractionImages';
import RowOfImages from '../../../../components/molecules/RowOfImages';
import { filledArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apg',
  description: 'apg',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    number1: z.number().int().min(1).max(2),
    number2: z.number().int().min(2).max(8),
    number3: z.number().int().min(1).max(7),
    missingSlices: z.array(z.number().int().min(0).max(7))
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 2);
    const number2 = randomIntegerInclusive(2, 8);

    // number2 must not be divisible by number3 and number3 must be odd
    const number3 = randomIntegerInclusive(1, number2 - 1, {
      constraint: x => x % 2 !== 0 && (number2 % x !== 0 || x === 1)
    });

    // Generate random indices for missing slices
    const missingSlices = randomUniqueIntegersInclusive(0, number2 - 1, number2 - number3);

    return { number1, number2, number3, missingSlices };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, missingSlices },
      translate
    } = props;

    // answer1 === number2
    const answer2 = number1 * number2 + number3;
    const correctFrac = [answer2, number2];

    // Test correct for simplified answers
    const testCorrect = (answer: string[]): boolean => {
      const frac = [...answer];

      return compareFractions(frac, correctFrac);
    };

    const color = getRandomFromArray(PieChartColors, {
      random: seededRandom(props.question)
    }) as string;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.convertMixedNumberToImproperFraction()}
        testCorrect={testCorrect}
        inputMaxCharacters={2}
        sentence={`<frac w='${number1}' n='${number3}' d='${number2}'/> = <frac nAns='' dAns=''/>`}
        pdfDirection="column"
        Content={({ dimens }) => {
          // Limit MultiPieChart to 4 per row
          const perRowMax = 4;

          // perRowTotal will be 1 extra (for the partially shaded pie chart)
          const perRowTotal = Math.min(perRowMax + 1, number1 + 1);
          const numberOfRows = Math.ceil(number1 / perRowMax);
          const pieChartRadius = calculateRadius(dimens, perRowTotal, numberOfRows);

          return (
            <View
              style={[
                dimens,
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexWrap: 'wrap'
                }
              ]}
            >
              <MultiPieChart
                numberOfPieCharts={number1}
                slicesPerChart={number2}
                chartsPerRow={perRowMax}
                dimens={dimens}
                radius={pieChartRadius}
                color={color}
              />
              <PieChart
                pieOptions={filledArray({ ratioOfSlices: 1 }, number2)}
                missingSlices={missingSlices}
                radius={pieChartRadius}
                color={color}
              />
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: correctFrac.map(String),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aph',
  description: 'aph',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    denominator: z.number().int().min(3).max(8),
    numerator: z.number().int().min(1).max(7)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    let numerator = 1;

    switch (denominator) {
      case 4:
        numerator = getRandomFromArray([1, 3]);
        break;
      case 6:
        numerator = getRandomFromArray([1, 5]);
        break;
      case 8:
        numerator = getRandomFromArray([1, 3, 5, 7]);
        break;
    }

    return { denominator, numerator };
  },

  Component: props => {
    const {
      question: { denominator, numerator },
      translate
    } = props;

    const wholeNumber = 1;

    // Answers
    const answer1 = denominator;
    const answer2 = wholeNumber * denominator + numerator;

    // Number of bars === wholeNumber + 1
    const bars = new Array(wholeNumber + 1).fill({ rows: 1, cols: denominator });

    const sentence = `<frac w='${wholeNumber}' n='${numerator}' d='${denominator}' /> = <frac nAns='' dAns='' />`;

    return (
      <QF20bInteractiveBarModelWithSentence
        title={`${translate.instructions.convertMixedNumberToImproperFraction()} ${translate.instructions.shadeBarModelsToHelp()}`}
        testCorrect={{
          sentence: userAnswer =>
            compareFractions([userAnswer[0], userAnswer[1]], [answer2, answer1])
        }}
        inputMaxCharacters={2}
        sentence={sentence}
        bars={bars}
        questionHeight={800}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.barModelsDoNotNeedShading() }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'api',
  description: 'api',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    number1: numberEnum([3, 5, 7]),
    number2: z.number().int().min(2).max(7)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([3, 5, 7] as const);
    const number2 = randomIntegerInclusive(2, 7);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    // answer1 === number1
    const answer2 = number2 * number1 + 1;
    const answer3 = answer2 + 1;
    const answer4 = answer2 + 2;
    const answer5 = answer2 + 3;

    const essential = {
      sentence: `<frac w='${number2}' n='1' d='${number1}'/> = <frac nAns='' dAns=''/>`,
      answer: [answer2, number1]
    };

    const sentenceTwo = getRandomFromArray(
      [
        {
          sentence: `<frac w='${number2}' n='2' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer3, number1]
        },
        {
          sentence: `<frac w='${number2}' n='3' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer4, number1]
        },
        {
          sentence: `<frac w='${number2}' n='4' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer5, number1]
        }
      ] as const,
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.convertMixedNumbersToImproperFractions()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], essential.answer) &&
          compareFractions(userAnswer[1], sentenceTwo.answer)
        }
        inputMaxCharacters={2}
        sentences={[essential.sentence, sentenceTwo.sentence]}
        pdfContainerStyle={{ columnGap: 256 }}
        pdfDirection="row"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            essential.answer.map(num => num.toLocaleString()),
            sentenceTwo.answer.map(num => num.toLocaleString())
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={700}
      />
    );
  },
  questionHeight: 700
});

const Question4 = newQuestionContent({
  uid: 'apj',
  description: 'apj',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    number1: z.number().int().min(3).max(8),
    number2: z.number().int().min(2).max(7)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 8);
    const number2 = randomIntegerInclusive(2, 7);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number2 + 1;
    const number4 = number2 + 2;
    const number5 = number2 + 3;

    // answer1 === number1
    const answer2 = number2 * number1 + 1;
    const answer3 = number3 * number1 + 1;
    const answer4 = number4 * number1 + 1;
    const answer5 = number5 * number1 + 1;

    const essential = {
      sentence: `<frac w='${number2}' n='1' d='${number1}'/> = <frac nAns='' dAns=''/>`,
      answer: [answer2, number1]
    };

    const sentenceTwo = getRandomFromArray(
      [
        {
          sentence: `<frac w='${number3}' n='1' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer3, number1]
        },
        {
          sentence: `<frac w='${number4}' n='1' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer4, number1]
        },
        {
          sentence: `<frac w='${number5}' n='1' d='${number1}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer5, number1]
        }
      ] as const,
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.convertMixedNumbersToImproperFractions()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], essential.answer) &&
          compareFractions(userAnswer[1], sentenceTwo.answer)
        }
        inputMaxCharacters={2}
        sentences={[essential.sentence, sentenceTwo.sentence]}
        pdfContainerStyle={{ columnGap: 256 }}
        pdfDirection="row"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            essential.answer.map(num => num.toLocaleString()),
            sentenceTwo.answer.map(num => num.toLocaleString())
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question5 = newQuestionContent({
  uid: 'apk',
  description: 'apk',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(8),
    number2: z.number().int().min(2).max(7)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 8);
    const number2 = randomIntegerInclusive(2, 7);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number2 + 1;
    const number4 = number2 + 2;
    const number5 = number2 + 3;

    // answer1 === number2
    const answer2 = number2 * number1 + 1;

    // answer3 === number3
    const answer4 = number3 * number1 + 1;

    // answer5 === number4
    const answer6 = number4 * number1 + 1;

    // answer7 === number5
    const answer8 = number5 * number1 + 1;

    const essential = {
      sentence: `<frac w='${number1}' n='1' d='${number2}'/> = <frac nAns='' dAns=''/>`,
      answer: [answer2, number2]
    };

    const sentenceTwo = getRandomFromArray(
      [
        {
          sentence: `<frac w='${number1}' n='1' d='${number3}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer4, number3]
        },
        {
          sentence: `<frac w='${number1}' n='1' d='${number4}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer6, number4]
        },
        {
          sentence: `<frac w='${number1}' n='1' d='${number5}'/> = <frac nAns='' dAns=''/>`,
          answer: [answer8, number5]
        }
      ] as const,
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.convertMixedNumbersToImproperFractions()}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], essential.answer) &&
          compareFractions(userAnswer[1], sentenceTwo.answer)
        }
        inputMaxCharacters={2}
        sentences={[essential.sentence, sentenceTwo.sentence]}
        pdfContainerStyle={{ columnGap: 256 }}
        pdfDirection="row"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            essential.answer.map(num => num.toLocaleString()),
            sentenceTwo.answer.map(num => num.toLocaleString())
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question6 = newQuestionContent({
  uid: 'apl',
  description: 'apl',
  keywords: ['Convert', 'Mixed numbers', 'Improper fractions'],
  schema: z.object({
    foodType: foodFractionObjectSchema,
    slicesPer: z.number().int().min(4).max(8),
    numberOfWhole: z.number().int().min(1).max(4),
    additionalSlices: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const foodType = getRandomFoodFractionObject();

    const slicesPer = randomIntegerInclusive(4, 8);
    const numberOfWhole = randomIntegerInclusive(1, 4);

    // additionalSlices cannot represent 1 whole or more.
    const additionalSlices = randomIntegerInclusive(1, slicesPer - 1);

    return { foodType, slicesPer, numberOfWhole, additionalSlices };
  },
  Component: props => {
    const {
      question: { foodType, slicesPer, numberOfWhole, additionalSlices },
      translate
    } = props;

    const answer = numberOfWhole * slicesPer + additionalSlices;

    const images = [
      ...filledArray(getFoodFractionObjectSvgName(foodType, slicesPer, slicesPer), numberOfWhole),
      getFoodFractionObjectSvgName(foodType, additionalSlices, slicesPer)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsSomeFoodCutIntoSlices(
          foodType.toLowerCase(),
          portionToText(slicesPer, translate)
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.howManySlicesAreThere()}
        Content={
          <RowOfImages containerStyle={{ margin: 20 }} style={{ gap: 20 }} images={images} />
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertMixedNumbersToImproperFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
