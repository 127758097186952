// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import UseKnownFactsToAddAndSubtractDecimalsWithin1 from './1UseKnownFactsToAddAndSubtractDecimalsWithin1';
import ComplementsTo1 from './2ComplementsTo1';
import AddAndSubtractDecimalsAcross1 from './3AddAndSubtractDecimalsAcross1';
import AddDecimalsWithTheSameNumberOfDecimalPlaces from './4AddDecimalsWithTheSameNumberOfDecimalPlaces';
import SubtractDecimalsWithTheSameNumberOfDecimalPlaces from './5SubtractDecimalsWithTheSameNumberOfDecimalPlaces';
import AddDecimalsWithDifferentNumbersOfDecimalPlaces from './6AddDecimalsWithDifferentNumbersOfDecimalPlaces';
import SubtractDecimalsWithDifferentNumbersOfDecimalPlaces from './7SubtractDecimalsWithDifferentNumbersOfDecimalPlaces';
import EfficientStrategiesForAddingAndSubtractingDecimals from './8EfficientStrategiesForAddingAndSubtractingDecimals';
import DecimalSequences from './9DecimalSequences';
import MultiplyBy10100And1000 from './10MultiplyBy10100And1000';
import DivideBy10100And1000 from './11DivideBy10100And1000';
import MultiplyAndDivideDecimalsMissingValues from './12MultiplyAndDivideDecimalsMissingValues';

const Block = newBlockContent({
  block: 'Decimals',
  field: 'Number',
  weeks: [6, 8],
  smallSteps: [
    UseKnownFactsToAddAndSubtractDecimalsWithin1, // Step 1
    ComplementsTo1, // Step 2
    AddAndSubtractDecimalsAcross1, // Step 3
    AddDecimalsWithTheSameNumberOfDecimalPlaces, // Step 4
    SubtractDecimalsWithTheSameNumberOfDecimalPlaces, // Step 5
    AddDecimalsWithDifferentNumbersOfDecimalPlaces, // Step 6
    SubtractDecimalsWithDifferentNumbersOfDecimalPlaces, // Step 7
    EfficientStrategiesForAddingAndSubtractingDecimals, // Step 8
    DecimalSequences, // Step 9
    MultiplyBy10100And1000, // Step 10
    DivideBy10100And1000, // Step 11
    MultiplyAndDivideDecimalsMissingValues // Step 12
  ]
});
export default Block;
