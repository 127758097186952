import { useContext } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { MeasureView } from '../../atoms/MeasureView';
import {
  ElementOrRenderFunction,
  SetState,
  resolveElementOrRenderFunction
} from '../../../utils/react';
import TextStructure from '../../molecules/TextStructure';
import { Selectable } from '../../atoms/Selectable';
import { Dimens } from '../../../theme/scaling';
import { useI18nContext } from '../../../i18n/i18n-react';
import { withStateHOC } from '../../../stateTree';
import { is } from '../../../utils/matchers';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  /**
   * Content to show above the statement
   */
  content: ElementOrRenderFunction<{ dimens: Dimens }>;
  /**
   * Statement using our markup language (e.g. <frac> and <ans> notation, though this isn't interactive so avoid <ans>).
   */
  sentence?: string;
  /**
   * The correct answer. If not specified, then the answer won't be assessed.
   */
  correctAnswer?: boolean;
  /**
   * Label to display over the 'true' button. Optional, defaults to the i18n translation of 'TRUE'.
   */
  trueButtonLabel?: string;
  /**
   * Label to display over the 'false' button. Optional, defaults to the i18n translation of 'FALSE'.
   */
  falseButtonLabel?: string;
  /**
   * Optional styling to apply to the selectables.
   */
  selectableTextStyle?: StyleProp<TextStyle>;
  /**
   * Title at the top of the question for pdf
   */
  pdfTitle?: string;
  /** PDF Question Height */
  questionHeight?: number;
  markSchemeText?: string;
};

export default function QF38ContentWithSentenceTrueOrFalse({
  title,
  content,
  sentence,
  correctAnswer,
  trueButtonLabel,
  falseButtonLabel,
  selectableTextStyle,
  pdfTitle,
  questionHeight,
  markSchemeText,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => resolveElementOrRenderFunction(content, { dimens })}
            </MeasureView>
            {sentence && <TextStructure sentence={sentence} />}
            <TrueFalseSelectablesWithState
              id="truefalse"
              trueButtonLabel={trueButtonLabel}
              falseButtonLabel={falseButtonLabel}
              selectableTextStyle={selectableTextStyle}
              defaultState={displayMode === 'markscheme' ? correctAnswer : undefined}
            />
            {displayMode === 'markscheme' && markSchemeText && renderMarkSchemeProp(markSchemeText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }
  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <>
          <MeasureView>{dimens => resolveElementOrRenderFunction(content, { dimens })}</MeasureView>
          {sentence && <TextStructure sentence={sentence} />}
          <TrueFalseSelectablesWithState
            id="truefalse"
            trueButtonLabel={trueButtonLabel}
            falseButtonLabel={falseButtonLabel}
            testCorrect={correctAnswer === undefined ? undefined : is(correctAnswer)}
            selectableTextStyle={selectableTextStyle}
          />
        </>
      }
      {...props}
    />
  );
}

/** Two selectables in a row, showing true and false, with at most one selected at a time. */
export function TrueFalseSelectables({
  state,
  setState,
  trueButtonLabel,
  falseButtonLabel,
  selectableTextStyle,
  style
}: {
  /** null represents neither selected */
  state: boolean | null;
  setState?: SetState<boolean | null>;
  trueButtonLabel?: string;
  falseButtonLabel?: string;
  selectableTextStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
}) {
  const translate = useI18nContext().LL;

  return (
    <View style={[styles.trueFalseRow, style]}>
      <Selectable
        selected={state === true}
        toggleSelected={
          setState === undefined ? undefined : () => setState(ans => (ans === true ? null : true))
        }
        textVariant="WRN700"
        textStyle={[styles.selectableText, selectableTextStyle]}
      >
        {trueButtonLabel ?? translate.misc.True()}
      </Selectable>
      <Selectable
        selected={state === false}
        toggleSelected={
          setState === undefined ? undefined : () => setState(ans => (ans === false ? null : false))
        }
        textVariant="WRN700"
        textStyle={[styles.selectableText, selectableTextStyle]}
      >
        {falseButtonLabel ?? translate.misc.False()}
      </Selectable>
    </View>
  );
}

/** See {@link TrueFalseSelectables}. */
export const TrueFalseSelectablesWithState = withStateHOC(TrueFalseSelectables, {
  defaults: { testComplete: ans => ans !== null, defaultState: null }
});

const styles = StyleSheet.create({
  trueFalseRow: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 16
  },
  selectableText: {
    textTransform: 'uppercase'
  }
});
