import { View, StyleSheet } from 'react-native';
import { filledArray } from '../../../utils/collections';
import { useContext } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { DisplayMode } from '../../../contexts/displayMode';
import { isEqual, isNotEqual } from '../../../utils/matchers';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { parseMarkup } from '../../../markup';

type Props = {
  title: string;
  pdfTitle?: string;
  /** An array of correct answers */
  testCorrect: (string | undefined)[][];
  /**
   * The draggable items to show. Each one should be a SvgName.
   */
  items: SvgName[];
  questionHeight?: number;
  /**
   * The array to be shown as the content.
   * When <ans/> is present, there will be a dropzone else the Svg will be displayed
   */
  cardArray: (SvgName | '<ans/>')[];
  /**
   * Determines the drop zone orientation. Optional, defaults to 'horizontal'.
   */
  variation?: 'vertical' | 'horizontal';
  shapeRotation?: 0 | 90 | 180 | 270;
};

/**
 * Question Format 77: Drag the shape to the X
 */
export default function QF77DragShapeToTheX({
  title,
  pdfTitle,
  testCorrect,
  items: itemsProp,
  questionHeight,
  cardArray,
  shapeRotation = 0,
  variation = 'horizontal'
}: Props) {
  const numberOfGroups = cardArray.length;

  if (numberOfGroups > 4) {
    throw new Error('NumberOfGroups must be less than 4.');
  }

  const styles = getStyles(variation);
  const displayMode = useContext(DisplayMode);

  const numberOfAns = cardArray
    .map(card => (typeof card === 'string' ? parseMarkup(card).numberOfAns : 0))
    .reduce((sum, numberOfAns) => sum + numberOfAns);

  const initialState = filledArray([], numberOfAns);

  const items = itemsProp.map(value => ({
    value,
    component: <AssetSvg name={value} style={{ transform: `rotate(${shapeRotation}deg)` }} />
  }));

  const dropSource = (
    <DragAndDropSection>
      {itemsProp.map((_, index) => (
        <EasyDragAndDrop.Source key={index} id={index} componentStyle={{ width: 80, height: 80 }} />
      ))}
    </DragAndDropSection>
  );

  let answerId = -1;
  const dragZones = (
    <View style={styles.dragZone}>
      {cardArray.map((card, index) => {
        const isAnswer = card === '<ans/>';
        if (isAnswer) {
          answerId += 1;
          return (
            <EasyDragAndDrop.ZoneSingle
              key={index}
              id={answerId}
              style={styles.zoneSingle}
              droppedStyle={styles.droppedStyle}
            />
          );
        } else {
          return (
            <View
              key={index}
              style={{ height: 136, width: 136, transform: `rotate(${shapeRotation}deg)` }}
            >
              <AssetSvg name={card} height={136} width={136} />
            </View>
          );
        }
      })}
    </View>
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const defaultState = displayMode === 'markscheme' ? testCorrect : initialState;

    return (
      <EasyDragAndDrop.ProviderWithState
        id="QF77"
        moveOrCopy="copy"
        items={items}
        defaultState={defaultState}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={dragZones}
          questionHeight={questionHeight}
        />
      </EasyDragAndDrop.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="QF77"
      items={items}
      defaultState={initialState}
      // Complete if at least one zone has had a change.
      testComplete={isNotEqual(initialState)}
      testCorrect={isEqual(testCorrect)}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="end"
        actionPanelContents={dropSource}
        mainPanelContents={dragZones}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}

const getStyles = (variation: 'horizontal' | 'vertical') => {
  return StyleSheet.create({
    dragZone: {
      flex: 1,
      flexDirection: variation === 'vertical' ? 'column' : 'row',
      flexWrap: 'wrap',
      alignContent: 'center',
      gap: 20,
      alignItems: 'center',
      justifyContent: 'center'
    },
    zoneSingle: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 291,
      height: variation === 'horizontal' ? 317 : 152,
      borderRadius: 24
    },
    droppedStyle: {
      width: variation === 'horizontal' ? 136 : 120,
      height: variation === 'horizontal' ? 136 : 120,
      borderWidth: 0,
      backgroundColor: 'none',
      shadowOpacity: 0
    }
  });
};
