import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { compareFloats } from '../../../../utils/math';
import { ADD, SUB } from '../../../../constants';
import {
  createHundredSquareShape,
  numbersToHundredSquareColors,
  rotateShape270
} from '../../../../utils/shapes';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aBm',
  description: 'aBm',
  keywords: ['100 square', 'Addition'],
  schema: z.object({
    number1: z.number().int().min(1).max(25),
    number2: z.number().int().min(1).max(25),
    topOrLeft: z.enum(['top', 'left'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 25);

    const number2 = randomIntegerInclusive(1, 25);

    const topOrLeft = getRandomFromArray(['top', 'left'] as const);

    return { number1, number2, topOrLeft };
  },
  Component: props => {
    const {
      question: { number1, number2, topOrLeft },
      translate
    } = props;

    const squareToDisplay =
      topOrLeft === 'top'
        ? createHundredSquareShape(number1 + number2)
        : rotateShape270(createHundredSquareShape(number1 + number2));

    const coloredSquare = numbersToHundredSquareColors(
      [number1, number2],
      [colors.fieryRose],
      topOrLeft === 'top' ? 0 : 270
    );

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${ADD} <ans /> = <ans/>`}
        title={translate.instructions.whatCalculationIsShown()}
        testCorrect={answer => {
          return (
            ((answer[0] === number1.toString() && answer[1] === number2.toString()) ||
              (answer[0] === number2.toString() && answer[1] === number1.toString())) &&
            answer[2] === (number1 + number2).toString()
          );
        }}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid
            givenShape={squareToDisplay}
            cellColorMap={coloredSquare}
            dimens={dimens}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number1.toLocaleString(),
            number2.toLocaleString(),
            (number1 + number2).toLocaleString()
          ],
          answerText: translate.markScheme.validSentenceMatchingContent()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aBn',
  description: 'aBn',
  keywords: ['100 square', 'Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1Hundredths: z.number().int().min(11).max(25),
    number2Hundredths: z.number().int().min(11).max(25),
    topOrLeft: z.enum(['top', 'left'])
  }),
  simpleGenerator: () => {
    const number1Hundredths = randomIntegerInclusive(11, 25, {
      constraint: x => x !== 20
    });

    const number2Hundredths = randomIntegerInclusive(11, 25, {
      constraint: x => x !== 20 && (x + number1Hundredths) % 10 !== 0
    });

    const topOrLeft = getRandomFromArray(['top', 'left'] as const);

    return { number1Hundredths, number2Hundredths, topOrLeft };
  },
  Component: props => {
    const {
      question: { number1Hundredths, number2Hundredths, topOrLeft },
      translate
    } = props;

    const squareToDisplay =
      topOrLeft === 'top'
        ? createHundredSquareShape(number1Hundredths + number2Hundredths)
        : rotateShape270(createHundredSquareShape(number1Hundredths + number2Hundredths));

    const coloredSquare = numbersToHundredSquareColors(
      [number1Hundredths, number2Hundredths],
      [colors.fieryRose],
      topOrLeft === 'top' ? 0 : 270
    );

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${ADD} <ans /> = <ans/>`}
        title={translate.instructions.whatCalculationIsShown()}
        testCorrect={answer => {
          return (
            ((compareFloats(answer[0], number1Hundredths / 100) &&
              compareFloats(answer[1], number2Hundredths / 100)) ||
              (compareFloats(answer[0], number2Hundredths / 100) &&
                compareFloats(answer[1], number1Hundredths / 100))) &&
            compareFloats(answer[2], (number1Hundredths + number2Hundredths) / 100)
          );
        }}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid
            givenShape={squareToDisplay}
            cellColorMap={coloredSquare}
            dimens={dimens}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (number1Hundredths / 100).toLocaleString(),
            (number2Hundredths / 100).toLocaleString(),
            ((number1Hundredths + number2Hundredths) / 100).toLocaleString()
          ],
          answerText: translate.markScheme.validSentenceMatchingContent()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aBo',
  description: 'aBo',
  keywords: ['100 square', 'Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1Hundredths: z.number().int().min(10).max(50).multipleOf(10),
    number2Hundredths: z.number().int().min(11).max(25),
    topOrLeft: z.enum(['top', 'left'])
  }),
  simpleGenerator: () => {
    const number1Hundredths = randomIntegerInclusiveStep(10, 50, 10);

    const number2Hundredths = randomIntegerInclusive(11, 25, {
      constraint: x => x !== 20
    });

    const topOrLeft = getRandomFromArray(['top', 'left'] as const);

    return { number1Hundredths, number2Hundredths, topOrLeft };
  },
  Component: props => {
    const {
      question: { number1Hundredths, number2Hundredths, topOrLeft },
      translate
    } = props;

    const squareToDisplay =
      topOrLeft === 'top'
        ? createHundredSquareShape(number1Hundredths + number2Hundredths)
        : rotateShape270(createHundredSquareShape(number1Hundredths + number2Hundredths));

    return (
      <QF1ContentAndSentence
        sentence={`${(number1Hundredths / 100).toLocaleString()} ${ADD} <ans /> = ${(
          (number1Hundredths + number2Hundredths) /
          100
        ).toLocaleString()}`}
        title={translate.instructions.useTheHundredSquareToHelpYouCompleteTheCalculation()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], (number2Hundredths / 100).toString())
        }
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={squareToDisplay} dimens={dimens} />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [(number2Hundredths / 100).toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aBp',
  description: 'aBp',
  keywords: ['Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().int().min(11).max(49),
    number2: z.number().int().min(12).max(48).multipleOf(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(11, 49, 2);

    const number2 = randomIntegerInclusiveStep(12, 48, 2, {
      constraint: x => x % 10 !== 0
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const [sentence1, answer1] = [
      `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`,
      number1 + number2
    ];

    const [sentence2, answer2] = [
      `${(number1 / 100).toLocaleString()} ${ADD} ${(number2 / 100).toLocaleString()} = <ans/>`,
      (number1 + number2) / 100
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        extraSymbol="decimalPoint"
        testCorrect={answer =>
          compareFloats(answer[0][0], answer1) && compareFloats(answer[1][0], answer2)
        }
        sentences={[sentence1, sentence2]}
        inputMaxCharacters={4}
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aBq',
  description: 'aBq',
  keywords: ['Subtraction', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(99),
      number2: z.number().int().min(11).max(28)
    })
    .refine(val => val.number1 > val.number2, 'number1 must be larger than number2'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(12, 99);

    const number2 = randomIntegerInclusive(11, Math.min(28, number1 - 1), {
      // number1 - number2 must not be a multiple of 10, and number2 itself must not be a multiple of 10
      constraint: x => (number1 - x) % 10 !== 0 && x % 10 !== 0
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const [sentence1, answer1] = [
      `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`,
      number1 - number2
    ];

    const [sentence2, answer2] = [
      `${(number1 / 100).toLocaleString()} ${SUB} ${(number2 / 100).toLocaleString()} = <ans/>`,
      (number1 - number2) / 100
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        extraSymbol="decimalPoint"
        testCorrect={answer =>
          compareFloats(answer[0][0], answer1) && compareFloats(answer[1][0], answer2)
        }
        sentences={[sentence1, sentence2]}
        inputMaxCharacters={4}
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aBr',
  description: 'aBr',
  keywords: ['Addition', 'Subtraction', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(89),
      number2: z.number().int().min(10).max(80).multipleOf(10)
    })
    .refine(val => val.number1 > val.number2, 'number1 must be larger than number2'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 89, {
      constraint: x => x % 10 !== 0
    });

    const number2 = randomIntegerInclusiveStep(10, 80, 10, {
      constraint: x => x < number1 && x + number1 < 100
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const [sentence1, answer1] = [
      `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`,
      number1 + number2
    ];

    const [sentence2, answer2] = [
      `${(number1 / 100).toLocaleString()} ${ADD} ${(number2 / 100).toLocaleString()} = <ans/>`,
      (number1 + number2) / 100
    ];

    const [sentence3, answer3] = [
      `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`,
      number1 - number2
    ];

    const [sentence4, answer4] = [
      `${(number1 / 100).toLocaleString()} ${SUB} ${(number2 / 100).toLocaleString()} = <ans/>`,
      (number1 - number2) / 100
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        extraSymbol="decimalPoint"
        testCorrect={answer =>
          compareFloats(answer[0][0], answer1) &&
          compareFloats(answer[1][0], answer2) &&
          compareFloats(answer[2][0], answer3) &&
          compareFloats(answer[3][0], answer4)
        }
        sentences={[sentence1, sentence2, sentence3, sentence4]}
        inputMaxCharacters={4}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer1.toLocaleString()],
            [answer2.toLocaleString()],
            [answer3.toLocaleString()],
            [answer4.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseKnownFactsToAddAndSubtractDecimalsWithin1',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
