import { SmallStepKey } from '../i18n/custom-types';
import { QuestionTypeContent } from './Question';
import { BlockId } from './Block';

export type SmallStepId = BlockId & { smallStep: SmallStepKey };

/**
 * All of the information we store about a small step.
 */
export interface SmallStepContent {
  smallStep: SmallStepKey;
  questionTypes: QuestionTypeContent<Record<string, unknown>>[];
  archivedQuestionTypes: QuestionTypeContent<Record<string, unknown>>[];
}

export function newSmallStepContent(params: {
  smallStep: SmallStepKey;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionTypes: QuestionTypeContent<any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  archivedQuestionTypes?: QuestionTypeContent<any>[];
}): SmallStepContent {
  return {
    smallStep: params.smallStep,
    questionTypes: params.questionTypes,
    archivedQuestionTypes: params.archivedQuestionTypes ?? []
  };
}
