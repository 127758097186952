import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomFromArray,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  ShapeFormulae,
  getRandomShapeFormulaeName,
  shapeFormulaNameSchema,
  shapeFormulae
} from '../../../../utils/shapeFormulae';
import ContentBox from '../../../../components/molecules/ContentBox';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { colors } from '../../../../theme/colors';
import { arraysHaveSameContents, range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  ADD,
  ALGEBRAIC_C,
  ALGEBRAIC_H,
  ALGEBRAIC_L,
  ALGEBRAIC_S,
  ALGEBRAIC_T
} from '../../../../constants';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import Text from '../../../../components/typography/Text';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import {
  getRandomLollyShape,
  getStickNumbers,
  LollyPatterns,
  lollyShapesSchema
} from '../../../../components/question/representations/LollyPatterns';
import { numberEnum } from '../../../../utils/zod';
import { TableWithLeftHeadersWithState } from '../../../../components/question/representations/TableWithLeftHeaders';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const shapeRepetitions = (svgName: SvgName, repetitions: number, width = 70) => {
  const shape = (key: string) => <AssetSvg key={key} name={svgName} width={width} />;

  return (
    <ContentBox
      containerStyle={{
        flexDirection: 'row',
        gap: 8,
        alignSelf: 'center',
        backgroundColor: colors.white
      }}
    >
      {range(1, repetitions).map(x => shape(`${repetitions}_${x}`))}
    </ContentBox>
  );
};

const Question1 = newQuestionContent({
  uid: 'aT8',
  description: 'aT8',
  keywords: ['Formula', 'Sequence', 'Pattern'],
  schema: z.object({
    shape: shapeFormulaNameSchema,
    start: numberEnum([1, 2])
  }),
  simpleGenerator: () => {
    const shape = getRandomShapeFormulaeName();
    const start = getRandomFromArray([1, 2] as const);

    return { shape, start };
  },
  Component: props => {
    const {
      question: { shape, start },
      translate,
      displayMode
    } = props;

    const { svgName, largeShape, smallShape, smallShapeAmount } = shapeFormulae[
      shape
    ] as ShapeFormulae;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.patternIsMadeUsingXandY(
          translate.shapes[largeShape](1),
          translate.shapes[smallShape](smallShapeAmount)
        )}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-around' }]}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around', gap: 4 }}>
              {shapeRepetitions(svgName, start, displayMode === 'digital' ? 70 : 180)}
              {shapeRepetitions(svgName, start + 1, displayMode === 'digital' ? 70 : 180)}
              {shapeRepetitions(svgName, start + 2, displayMode === 'digital' ? 70 : 180)}
            </View>
            <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.howManyXWillThereBeWithNumY(
                translate.shapes[smallShape](smallShapeAmount),
                start + 3,
                translate.shapes[largeShape](start + 3)
              )}
            </Text>
          </View>
        )}
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[(smallShapeAmount * (start + 3)).toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aT9',
  description: 'aT9',
  keywords: ['Formula', 'Table', 'Sequence', 'Pattern'],
  schema: z.object({
    shape: shapeFormulaNameSchema,
    start: numberEnum([1, 2])
  }),
  simpleGenerator: () => {
    const shape = getRandomShapeFormulaeName();
    const start = getRandomFromArray([1, 2] as const);

    return { shape, start };
  },
  Component: props => {
    const {
      question: { shape, start },
      translate,
      displayMode
    } = props;

    const { svgName, largeShape, smallShape, smallShapeAmount } = shapeFormulae[
      shape
    ] as ShapeFormulae;

    const tableData = [
      [
        start.toLocaleString(),
        (start + 1).toLocaleString(),
        (start + 2).toLocaleString(),
        (start + 3).toLocaleString()
      ],
      [(smallShapeAmount * start).toLocaleString(), '<ans/>', '<ans/>', '<ans/>']
    ];

    return (
      <QF3Content
        title={translate.instructions.completeTheTableToMatchThePattern()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-around' }}>
            <View
              style={{
                width: dimens.width,
                flexDirection: 'row',
                justifyContent: 'space-around',
                gap: 8
              }}
            >
              {shapeRepetitions(svgName, start, displayMode === 'digital' ? 70 : 180)}
              {shapeRepetitions(svgName, start + 1, displayMode === 'digital' ? 70 : 180)}
              {shapeRepetitions(svgName, start + 2, displayMode === 'digital' ? 70 : 180)}
            </View>
            <TableWithLeftHeadersWithState
              id="table"
              defaultState={
                displayMode === 'markscheme'
                  ? [
                      (smallShapeAmount * (start + 1)).toLocaleString(),
                      (smallShapeAmount * (start + 2)).toLocaleString(),
                      (smallShapeAmount * (start + 3)).toLocaleString()
                    ]
                  : ['', '', '']
              }
              headers={[translate.shapes[largeShape](2), translate.shapes[smallShape](2)]}
              items={tableData}
              testCorrect={userAnswer =>
                arraysHaveSameContents(userAnswer, [
                  (smallShapeAmount * (start + 1)).toString(),
                  (smallShapeAmount * (start + 2)).toString(),
                  (smallShapeAmount * (start + 3)).toString()
                ])
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aUa',
  description: 'aUa',
  keywords: ['Formula', 'Sequence', 'Pattern'],
  schema: z.object({
    shape: z.enum([
      'circle_2_triangles',
      'square_2_circles',
      'triangle_3_circles',
      'triangle_3_squares',
      'circle_4_squares',
      'square_4_triangles'
    ])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'circle_2_triangles',
      'square_2_circles',
      'triangle_3_circles',
      'triangle_3_squares',
      'circle_4_squares',
      'square_4_triangles'
    ] as const);

    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate,
      displayMode
    } = props;

    const { svgName, largeShape, smallShape, smallShapeAmount } = shapeFormulae[
      shape
    ] as ShapeFormulae;

    const getShapeSymbol = (shape: 'squares' | 'triangles' | 'circles') => {
      switch (shape) {
        case 'squares':
          return ALGEBRAIC_S;
        case 'triangles':
          return ALGEBRAIC_T;
        case 'circles':
          return ALGEBRAIC_C;
      }
    };

    const symbol1 = getShapeSymbol(largeShape as 'squares' | 'triangles' | 'circles');
    const symbol2 = getShapeSymbol(smallShape);

    const selectables = shuffle(
      [
        {
          text: `${symbol2} = ${smallShapeAmount.toLocaleString()}${symbol1}`,
          value: 'A'
        },
        {
          text: `${symbol2} = ${smallShapeAmount.toLocaleString()} ${ADD} ${symbol1}`,
          value: 'B'
        },
        {
          text: `${symbol1} = ${smallShapeAmount.toLocaleString()}${symbol2}`,
          value: 'C'
        },
        {
          text: `${symbol1} = ${smallShapeAmount.toLocaleString()} ${ADD} ${symbol2}`,
          value: 'D'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.xNumberOfYNumberOfZNumberOfA(
          symbol1,
          translate.shapes[largeShape](2),
          symbol2,
          translate.shapes[smallShape](2)
        )}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-evenly' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
              {shapeRepetitions(svgName, 1, displayMode === 'digital' ? 70 : 180)}
              {shapeRepetitions(svgName, 2, displayMode === 'digital' ? 70 : 180)}
              {shapeRepetitions(svgName, 3, displayMode === 'digital' ? 70 : 180)}
            </View>
            <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode === 'digital'
                ? translate.instructions.selectFormulaDescribesPattern()
                : translate.instructions.selectFormulaDescribesPatternPDF()}
            </Text>
          </View>
        )}
        renderItems={selectables.map(({ text, value }) => ({
          component: <Text variant="WRN700">{text}</Text>,
          value
        }))}
        testCorrect={['A']}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aUb',
  description: 'aUb',
  keywords: ['Formula', 'Table', 'Sequence'],
  schema: z.object({
    lollyShape: lollyShapesSchema
  }),
  simpleGenerator: () => {
    const lollyShape = getRandomLollyShape();

    return { lollyShape };
  },
  Component: props => {
    const {
      question: { lollyShape },
      translate,
      displayMode
    } = props;

    const { shapeSticks, patternSticks } = getStickNumbers(lollyShape);

    const tableData = [
      [
        (1).toLocaleString(),
        (2).toLocaleString(),
        (3).toLocaleString(),
        (4).toLocaleString(),
        (5).toLocaleString()
      ],
      [
        shapeSticks.toLocaleString(),
        (shapeSticks + patternSticks).toLocaleString(),
        '<ans/>',
        '<ans/>',
        '<ans/>'
      ]
    ];

    return (
      <QF3Content
        title={translate.instructions.completeTheTableToMatchThePattern()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-around' }}>
            <View
              style={{ width: dimens.width, flexDirection: 'row', justifyContent: 'space-around' }}
            >
              {LollyPatterns(lollyShape)}
              {LollyPatterns(lollyShape, 1)}
              {LollyPatterns(lollyShape, 2)}
            </View>
            <TableWithLeftHeadersWithState
              id="table"
              defaultState={
                displayMode === 'markscheme'
                  ? [
                      (shapeSticks + 2 * patternSticks).toLocaleString(),
                      (shapeSticks + 3 * patternSticks).toLocaleString(),
                      (shapeSticks + 4 * patternSticks).toLocaleString()
                    ]
                  : ['', '', '']
              }
              headers={[translate.keywords.Pattern(), translate.objects.LollySticks()]}
              items={tableData}
              testCorrect={userAnswer =>
                arraysHaveSameContents(userAnswer, [
                  (shapeSticks + 2 * patternSticks).toString(),
                  (shapeSticks + 3 * patternSticks).toString(),
                  (shapeSticks + 4 * patternSticks).toString()
                ])
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aUc',
  description: 'aUc',
  keywords: ['Formula', 'Sequence', 'Pattern'],
  schema: z.object({
    lollyShape: z.enum(['square', 'triangle', 'house', 'longRect'])
  }),
  simpleGenerator: () => {
    const lollyShape = getRandomFromArray(['square', 'triangle', 'house', 'longRect'] as const);

    return { lollyShape };
  },
  Component: props => {
    const {
      question: { lollyShape },
      translate,
      displayMode
    } = props;

    const { shapeSticks, patternSticks } = getStickNumbers(lollyShape);
    const stickDiff = shapeSticks - patternSticks;

    const selectables = shuffle(
      [
        {
          text: `${ALGEBRAIC_S} = ${stickDiff.toLocaleString()}${ALGEBRAIC_L} ${ADD} ${patternSticks.toLocaleString()}`,
          value: 'A'
        },
        {
          text: `${ALGEBRAIC_L} = ${stickDiff.toLocaleString()}${ALGEBRAIC_S} ${ADD} ${patternSticks.toLocaleString()}`,
          value: 'B'
        },
        {
          text: `${ALGEBRAIC_S} = ${patternSticks.toLocaleString()}${ALGEBRAIC_L} ${ADD} ${stickDiff.toLocaleString()}`,
          value: 'C'
        },
        {
          text: `${ALGEBRAIC_L} = ${patternSticks.toLocaleString()}${ALGEBRAIC_S} ${ADD} ${stickDiff.toLocaleString()}`,
          value: 'D'
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.numberOfShapesNumberOfLollySticks()}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ justifyContent: 'flex-start' }}>
            <View
              style={{
                height: dimens.height * 0.7,
                width: dimens.width,
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              {LollyPatterns(lollyShape)}
              {LollyPatterns(lollyShape, 1)}
              {LollyPatterns(lollyShape, 2)}
            </View>
            <Text
              variant="WRN400"
              style={{
                fontSize: displayMode === 'digital' ? 32 : 50
              }}
            >
              {displayMode === 'digital'
                ? translate.instructions.selectFormulaDescribesPattern()
                : translate.instructions.selectFormulaDescribesPatternPDF()}
            </Text>
          </View>
        )}
        renderItems={selectables.map(({ text, value }) => ({
          component: <Text variant="WRN700">{text}</Text>,
          value
        }))}
        testCorrect={['D']}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aUd',
  description: 'aUd',
  keywords: ['Formula'],
  questionHeight: 1000,
  schema: z.object({
    cost: z.number().int().min(15).max(55).step(5),
    fixedCharge: z.number().int().min(20).max(60).step(10),
    occupation: z.enum(['Plumber', 'Electrician', 'WindowCleaner'])
  }),
  simpleGenerator: () => {
    const cost = randomIntegerInclusiveStep(15, 55, 5);
    const fixedCharge = randomIntegerInclusiveStep(20, 60, 10);
    const occupation = getRandomFromArray(['Plumber', 'Electrician', 'WindowCleaner'] as const);

    return {
      cost,
      fixedCharge,
      occupation
    };
  },
  Component: props => {
    const {
      question: { cost, fixedCharge, occupation },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      [ALGEBRAIC_C, ALGEBRAIC_H, ADD, cost.toLocaleString(), fixedCharge.toLocaleString()],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions[`hereAre${occupation}Prices`]()}
        items={items}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <ContentBox
              containerStyle={{
                alignSelf: 'center',
                flexDirection: 'row',
                gap: 20
              }}
            >
              <TextStructure
                textStyle={{ fontSize: 36 }}
                sentence={`${translate.instructions.xPerHourPlusYFixedCharge(
                  cost.toLocaleString(),
                  fixedCharge.toLocaleString()
                )}`}
              />
              <AssetSvg name={'White_van'} width={dimens.width * 0.25} />
            </ContentBox>
            <TextStructure
              sentence={
                displayMode === 'digital'
                  ? translate.instructions.dragTheCardsToMakeAFormulaToShowTheCostCForHHours()
                  : translate.instructions.useTheCardsToMakeAFormulaToShowTheCostCForHHours()
              }
            />
          </View>
        )}
        sentence={`<ans/>  =  <ans/>  <ans/>  <ans/>  <ans/>`}
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer, [
            ALGEBRAIC_C,
            cost.toString(),
            ALGEBRAIC_H,
            ADD,
            fixedCharge.toString()
          ]) ||
          arraysHaveSameContents(userAnswer, [
            ALGEBRAIC_C,
            fixedCharge.toString(),
            ADD,
            cost.toString(),
            ALGEBRAIC_H
          ])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ALGEBRAIC_C, cost.toString(), ALGEBRAIC_H, ADD, fixedCharge.toString()]
          ],
          answerText: translate.markScheme.acceptValidAnswersForContent()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Formulae',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
