import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { sortNumberArray } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import {
  getNumberOfDaysInMonth,
  getRandomMonth,
  MonthName,
  monthSchema
} from '../../../../utils/months';
import { getRandomName, nameSchema } from '../../../../utils/names';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'auc',
  description: 'auc',
  keywords: ['Time', 'Days', 'Hours'],
  schema: z.object({
    timesOfTheDay: z
      .array(
        z.object({
          string: z.enum(['Morning', 'Midday', 'Afternoon', 'Evening']),
          value: numberEnum([1, 2, 3, 4])
        })
      )
      .length(3)
  }),
  simpleGenerator: () => {
    const timesOfTheDay = getRandomSubArrayFromArray(
      [
        { string: 'Morning', value: 1 },
        { string: 'Midday', value: 2 },
        { string: 'Afternoon', value: 3 },
        { string: 'Evening', value: 4 }
      ] as const,
      3
    );

    return { timesOfTheDay };
  },
  Component: props => {
    const {
      question: { timesOfTheDay },
      translate
    } = props;

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderTheTimesOfDayInOrderOfWhenTheyHappen()}
        pdfTitle={translate.instructions.useCardsToOrderTheTimesOfDayInOrderOfWhenTheyHappen()}
        testCorrect={sortNumberArray(
          timesOfTheDay.map(x => x.value),
          'ascending'
        )}
        items={timesOfTheDay.map(({ value, string }) => ({
          value,
          component: <Text variant="WRN700">{translate.timePeriod[string]()}</Text>
        }))}
        topLabel={translate.keywords.First()}
        bottomLabel={translate.keywords.Last()}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aud',
  description: 'aud',
  keywords: ['Time', 'Days', 'Hours'],
  schema: z.object({
    calendarItems: z
      .array(
        z.object({
          string: z.enum([
            'Months in a year',
            'Days in a week',
            'Hours in a day',
            'Days in a non-leap year',
            'Days in a leap year',
            'Hours in a week',
            'Days in a school week',
            'Maximum number of days in a month',
            'Minimum numbers of days in a month'
          ]),
          value: numberEnum([12, 7, 24, 365, 366, 168, 5, 31, 28])
        })
      )
      .length(3)
  }),
  simpleGenerator: () => {
    const calendarItems = getRandomSubArrayFromArray(
      [
        {
          string: 'Months in a year',
          value: 12
        },
        {
          string: 'Days in a week',
          value: 7
        },
        {
          string: 'Hours in a day',
          value: 24
        },
        {
          string: 'Days in a non-leap year',
          value: 365
        },
        {
          string: 'Days in a leap year',
          value: 366
        },
        {
          string: 'Hours in a week',
          value: 168
        },
        {
          string: 'Days in a school week',
          value: 5
        },
        {
          string: 'Maximum number of days in a month',
          value: 31
        },
        {
          string: 'Minimum numbers of days in a month',
          value: 28
        }
      ] as const,
      3
    );

    return {
      calendarItems
    };
  },
  Component: props => {
    const {
      question: { calendarItems },
      translate,
      displayMode
    } = props;

    const answerOptions = calendarItems.map(({ value }) => {
      return { component: value.toLocaleString(), value };
    });

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF37SentencesDrag
        title={translate.instructions.matchTheDescriptionToTheCorrectNumber()}
        pdfTitle={translate.instructions.matchTheDescriptionToTheCorrectNumberPDF()}
        items={shuffledAnswerOptions}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={displayMode === 'digital' && { alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={calendarItems.map(({ string }) => `${translate.calendar[string]()} <ans/>`)}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={calendarItems.map(({ value }) => [value])}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'aue',
  description: 'aue',
  keywords: ['Time', 'Weeks', 'Days', 'Hours'],
  schema: z.object({
    daysOrWeeks: z.enum(['days', 'weeks']),
    numberOfDaysOrWeeksA: z.number().int().min(1).max(5),
    numberOfDaysOrWeeksB: z.number().int().min(10).max(60).multipleOf(10)
  }),
  simpleGenerator: () => {
    const daysOrWeeks = getRandomFromArray(['days', 'weeks'] as const);

    const numberOfDaysOrWeeksA = randomIntegerInclusive(1, 5);

    const numberOfDaysOrWeeksB = randomIntegerInclusiveStep(
      10,
      daysOrWeeks === 'days' ? 50 : 60,
      10
    );

    return { daysOrWeeks, numberOfDaysOrWeeksA, numberOfDaysOrWeeksB };
  },

  Component: props => {
    const {
      question: { daysOrWeeks, numberOfDaysOrWeeksA, numberOfDaysOrWeeksB },
      translate,
      displayMode
    } = props;

    const sentences =
      daysOrWeeks === 'days'
        ? [
            translate.answerSentences.xDaysEqualsYHours(1, 24),
            translate.answerSentences.xDaysEqualsAnsHours(numberOfDaysOrWeeksA),
            translate.answerSentences.ansDaysEqualsXHours(numberOfDaysOrWeeksB * 24)
          ]
        : [
            translate.answerSentences.xWeeksEqualsYDays(1, 7),
            translate.answerSentences.xWeeksEqualsAnsDays(numberOfDaysOrWeeksA),
            translate.answerSentences.ansWeeksEqualsXDays(numberOfDaysOrWeeksB * 7)
          ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeStatements()}
        containerStyle={{ alignItems: 'center' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        testCorrect={[
          [],
          [
            daysOrWeeks === 'days'
              ? (numberOfDaysOrWeeksA * 24).toString()
              : (numberOfDaysOrWeeksA * 7).toString()
          ],
          [numberOfDaysOrWeeksB.toString()]
        ]}
        sentences={sentences}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'auf',
  description: 'auf',
  keywords: ['Time', 'Weeks', 'Days'],
  schema: z.object({
    numberOfWeeks: numberEnum([2, 3, 4, 5, 8, 10])
  }),
  simpleGenerator: () => {
    const numberOfWeeks = getRandomFromArray([2, 3, 4, 5, 8, 10] as const);

    return { numberOfWeeks };
  },

  Component: props => {
    const {
      question: { numberOfWeeks },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.thereAreFiveDaysInASchoolWeek(numberOfWeeks)}
        testCorrect={[(7 * numberOfWeeks - 5 * numberOfWeeks).toString()]}
        sentence={translate.answerSentences.ansDays()}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aug',
  description: 'aug',
  keywords: ['Time', 'Days', 'Hours'],
  schema: z.object({
    numberOfDays: numberEnum([3, 4, 5, 8])
  }),
  simpleGenerator: () => {
    const numberOfDays = getRandomFromArray([3, 4, 5, 8] as const);

    return { numberOfDays };
  },

  Component: props => {
    const {
      question: { numberOfDays },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.thereAreSixHoursInASchoolDay(numberOfDays)}
        testCorrect={[(24 * numberOfDays - 6 * numberOfDays).toString()]}
        sentence={translate.answerSentences.ansHours()}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'auh2',
  description: 'auh',
  keywords: ['Days', 'Months', 'Hours'],
  schema: z.object({
    monthA: monthSchema,
    monthB: monthSchema
  }),
  simpleGenerator: () => {
    // Make 50/50 choice for question params
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);

    const constraintA = (x: MonthName) =>
      x !== 'February' &&
      (moreOrLess === 'less' ? getNumberOfDaysInMonth(x) === 31 : getNumberOfDaysInMonth(x) !== 31);
    const monthA = rejectionSample(() => getRandomMonth(), constraintA);

    const constraintB = (x: MonthName) =>
      x !== 'February' &&
      (moreOrLess === 'less'
        ? getNumberOfDaysInMonth(x) < getNumberOfDaysInMonth(monthA)
        : getNumberOfDaysInMonth(x) > getNumberOfDaysInMonth(monthA));
    const monthB = rejectionSample(() => getRandomMonth(), constraintB);

    return { monthA, monthB };
  },

  Component: props => {
    const {
      question: { monthA, monthB },
      translate
    } = props;

    const hoursA = getNumberOfDaysInMonth(monthA) * 24;
    const hoursB = getNumberOfDaysInMonth(monthB) * 24;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.thereAreXHoursInMonthAHowManyHoursAreThereInMonthB(
          hoursA,
          monthA,
          monthB
        )}
        testCorrect={[hoursB.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansHours()}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'auh',
  description: 'auh',
  keywords: ['Time', 'Months', 'Weeks', 'Days', 'Hours'],
  schema: z.object({
    name: nameSchema,
    month: z.enum(['March', 'June', 'November']),
    day: z.enum(['Monday', 'Saturday', 'Sunday'])
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const month = getRandomFromArray(['March', 'June', 'November'] as const);

    const day = getRandomFromArray(['Monday', 'Saturday', 'Sunday'] as const);

    return { name, month, day };
  },

  Component: props => {
    const {
      question: { name, month, day },
      translate
    } = props;

    const answer = (() => {
      switch (day) {
        case 'Monday':
          if (month === 'March') {
            return 138;
          }
          return 132;
        case 'Saturday':
          if (month === 'March') {
            return 126;
          }
          return 120;
        case 'Sunday':
          if (month === 'March') {
            return 132;
          }
          return 126;
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterGoesToSchoolForSixHoursADay(name, month, day)}
        testCorrect={[answer.toString()]}
        sentence={'<ans/>'}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DaysAndHours',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
