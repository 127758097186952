import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { useCallback, useContext, useMemo } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import {
  ElementOrRenderFunction,
  resolveElementOrRenderFunction,
  resolveThingOrFunction,
  ThingOrFunction
} from '../../../utils/react';
import { Dimens } from '../../../theme/scaling';
import { arraysHaveSameContents, filledArray } from '../../../utils/collections';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { MeasureView } from '../../atoms/MeasureView';
import TextStructure from '../../molecules/TextStructure';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { parseMarkup } from '../../../markup';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import EasyDragAndDropWithSingleZonesWithGrid from '../../draganddrop/EasyDragAndDropWithSingleZonesWithGrid';
import { DisplayMode } from '../../../contexts/displayMode';
import { DraggableVariant } from '../../draganddrop/utils';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { AssetSvg } from '../../../assets/svg';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  /** Content to fill the area under the title. */
  Content: ElementOrRenderFunction<{ dimens: Dimens }>;

  /** Sentences to complete. These use the markup language defined at {@link parseMarkup}. */
  sentences: string[];
  /** Generally speaking you don't need to provide this. Defaults all drop zones empty. */
  sentencesInitialState?: (T | undefined)[][];
  sentenceStyle?: StyleProp<ViewStyle>;
  sentencesStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  mainPanelStyle?: StyleProp<ViewStyle>;
  /**
   * Prop to decide how the items on the PDF will be displayed.
   * 'itemsBottom' -  items display in a row below the sentence(s).
   * 'itemsHidden' - removes the items entirely from the PDF.
   * 'itemsTop' - items display in a row between the content and sentence(s).
   * Optional prop, defaults to 'itemsTop'.
   */
  pdfLayout?: 'itemsBottom' | 'itemsHidden' | 'itemsTop' | 'itemsAboveContent';
  /**
   * Prop to decide if the answer boxes will display on the PDF.
   * Optional prop, defaults to 'shown'.
   */
  pdfAnswerBoxes?: 'hidden' | 'shown';
  /**
   * The draggable items to show. Each one can just be a string/number, or it can have a custom component using the
   * {@link ItemInfo} type.
   *
   * If providing just numbers, .toLocaleString() will be called for you before rendering.
   */
  items: ThingOrFunction<((T & (number | string)) | ItemInfo<T>)[]>;
  itemVariant?: DraggableVariant;
  itemMaxLines?: number;
  pdfItemVariant?: DraggableVariant;
  /** Default: endWide. */
  actionPanelVariant?: 'end' | 'endWide' | 'endMid' | 'bottom';
  /**
   * Whether each draggable can exist in multiple drop zones or not, and thus whether they are moved around
   * vs copies of them are moved around. Default 'move'.
   */
  moveOrCopy?: 'move' | 'copy';

  /** Defaults to checking all answer boxes have a draggable in. */
  testComplete?: (userAnswer: readonly (T | undefined)[][]) => boolean;

  /** Either an array of correct answers, or a function for more advanced cases. */
  testCorrect: (T | undefined)[][] | ((userAnswer: readonly (T | undefined)[][]) => boolean);

  /** PDF Question Height */
  questionHeight?: number;
  /** Custom Mark Scheme Answer */
  customMarkSchemeAnswer?: { answersToDisplay?: (T | undefined)[][]; answerText?: string };
};

/**
 * Layout containing a title above, drag and drop zone in the action panel, and arbitrary content with sentences below
 * in the main panel.
 *
 * For a single sentence, see the other QF36 component, which is easier to use for that case.
 *
 * To use interactive content, wrap that content in a `StateTreeLeaf` (either manually or using `withStateHoC`).
 *
 * ### Item values
 *
 * The draggable item's values can be either string or number. If number, .toLocaleString() is called for you for
 * rendering.
 *
 * ### Generic arguments
 *
 * The generic argument is the type that the draggables use for their value. Most of the time this is string | number,
 * but specifying it explicitly as something more specific may make it easier to write testComplete/testCorrect
 * functions.
 *
 * ### Testing functions (testCorrect/testComplete)
 *
 * The easiest way to use these is to omit testComplete, and provide an array for testCorrect, e.g.
 *
 * ```
 *   testCorrect={[[1, 2], [3]]}
 * ```
 */
export default function QF36ContentAndSentencesDrag<T>({
  title,
  pdfTitle,
  Content,
  sentences,
  sentencesInitialState,
  sentenceStyle,
  sentencesStyle,
  textStyle,
  fractionTextStyle,
  mainPanelStyle,
  pdfLayout = 'itemsBottom',
  pdfAnswerBoxes = 'shown',
  items: itemsProp,
  itemVariant = 'square',
  itemMaxLines,
  pdfItemVariant = 'pdfSquare',
  actionPanelVariant = 'endWide',
  moveOrCopy = 'move',
  testComplete: testCompleteProp,
  testCorrect: testCorrectProp,
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  // Set up items - handle the case where we were given a list of numbers/strings.
  const items = useMemo(() => {
    return resolveThingOrFunction(itemsProp).map(it =>
      typeof it === 'string' || typeof it === 'number'
        ? { value: it, component: it.toLocaleString() }
        : it
    );
  }, [itemsProp]);

  // Set up testComplete
  const testComplete = useCallback(
    (userAnswer: (T | undefined)[][]) => {
      return testCompleteProp !== undefined
        ? // If provided a function for sentences, check that
          testCompleteProp(userAnswer)
        : // Otherwise default to checking every answer is not undefined
          userAnswer.every(sentenceAnswer => sentenceAnswer.every(it => it !== undefined));
    },
    [testCompleteProp]
  );

  // Set up testCorrect
  const testCorrect = useCallback(
    (userAnswer: (T | undefined)[][]) => {
      if (typeof testCorrectProp === 'function') {
        return testCorrectProp(userAnswer);
      } else {
        return arraysHaveSameContents(userAnswer, testCorrectProp, arraysHaveSameContents);
      }
    },
    [testCorrectProp]
  );

  const numberOfAnsArray = sentences.map(parseMarkup).map(it => it.numberOfAns);
  const defaultState =
    sentencesInitialState ??
    numberOfAnsArray.map(answersThisSentence => filledArray(undefined, answersThisSentence));

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp;

    return (
      <EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState
        id="draganddrop"
        items={items}
        variant={pdfItemVariant}
        columnsPerRow={numberOfAnsArray}
        defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
        moveOrCopy={displayMode === 'markscheme' ? 'copy' : moveOrCopy}
        textVariant="WRN700"
        maxLines={itemMaxLines}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          questionHeight={questionHeight}
          mainPanelContents={
            <>
              {pdfLayout === 'itemsAboveContent' && (
                <DragAndDropSectionPDF
                  style={{ flex: 0.4 }}
                  itemsStyle={{
                    justifyContent: 'center',
                    gap: 16,
                    flexWrap: 'wrap',
                    flexDirection: 'row'
                  }}
                >
                  {items.map((_item, index) => (
                    <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
                  ))}
                </DragAndDropSectionPDF>
              )}
              <View style={[styles.mainPanel, mainPanelStyle]}>
                <MeasureView>
                  {dimens =>
                    resolveElementOrRenderFunction(Content, {
                      dimens,
                      userAnswer: undefined,
                      setUserAnswer: () => []
                    })
                  }
                </MeasureView>
                {pdfLayout === 'itemsTop' && (
                  <DragAndDropSectionPDF
                    style={{ flex: 0.4 }}
                    itemsStyle={{
                      justifyContent: 'center',
                      gap: 16,
                      flexWrap: 'wrap',
                      flexDirection: 'row'
                    }}
                  >
                    {items.map((_item, index) => (
                      <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
                    ))}
                  </DragAndDropSectionPDF>
                )}
                <View style={[styles.sentences, sentencesStyle]}>
                  {sentences.map((sentence, sentenceIndex) => (
                    <TextStructure
                      key={sentenceIndex}
                      sentence={sentence}
                      style={sentenceStyle}
                      textStyle={textStyle}
                      fractionTextStyle={fractionTextStyle}
                      inputBox={({ index }) => {
                        return pdfAnswerBoxes === 'shown' ? (
                          <View>
                            <EasyDragAndDropWithSingleZonesWithGrid.ZoneSingle
                              key={index}
                              row={sentenceIndex}
                              column={index}
                            />
                            {displayMode === 'markscheme' && (
                              <AssetSvg
                                name="True"
                                width={50}
                                style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
                              />
                            )}
                          </View>
                        ) : (
                          <></>
                        );
                      }}
                    />
                  ))}
                </View>
                {pdfLayout === 'itemsBottom' && (
                  <DragAndDropSectionPDF
                    style={{ flex: 0.4, paddingTop: 64 }}
                    itemsStyle={{
                      justifyContent: 'center',
                      gap: 16,
                      flexWrap: 'wrap',
                      flexDirection: 'row'
                    }}
                  >
                    {items.map((_item, index) => (
                      <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
                    ))}
                  </DragAndDropSectionPDF>
                )}
              </View>
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </>
          }
          {...props}
        />
      </EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={itemVariant}
      columnsPerRow={numberOfAnsArray}
      defaultState={defaultState}
      testComplete={testComplete}
      testCorrect={testCorrect}
      textVariant="WRN700"
      maxLines={itemMaxLines}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={
          <DragAndDropSection
            style={{ alignSelf: 'center' }}
            itemsStyle={{ justifyContent: 'center', gap: 8 }}
          >
            {items.map((_item, index) => (
              <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <View style={[styles.mainPanel, mainPanelStyle]}>
            <MeasureView>
              {dimens => resolveElementOrRenderFunction(Content, { dimens })}
            </MeasureView>
            <View style={[styles.sentences, sentencesStyle]}>
              {sentences.map((sentence, sentenceIndex) => (
                <TextStructure
                  key={sentenceIndex}
                  sentence={sentence}
                  style={sentenceStyle}
                  textStyle={textStyle}
                  fractionTextStyle={fractionTextStyle}
                  inputBox={({ index }) => (
                    <EasyDragAndDropWithSingleZonesWithGrid.ZoneSingle
                      key={index}
                      row={sentenceIndex}
                      column={index}
                    />
                  )}
                />
              ))}
            </View>
          </View>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  mainPanel: {
    flex: 1,
    justifyContent: 'space-evenly'
  },

  sentences: {
    marginTop: 16,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    rowGap: 16
  }
});
