import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { LineGraphColors } from '../../../../theme/colors';
import { SvgName } from '../../../../assets/svg';
import { measureObjectWithArticle } from '../../../../utils/objects';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { SUB } from '../../../../constants';
import { arrayHasNoDuplicates } from '../../../../utils/collections';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aGG',
  description: 'aGG',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z.object({
    length: z.number().int().min(15).max(99),
    /** Defaults to false, doesn't cause an error if isVertical isn't set. */
    isVertical: z.boolean().optional()
  }),
  questionHeight: 900,
  simpleGenerator: () => ({
    length: randomIntegerInclusive(15, 99),
    isVertical: getRandomBoolean()
  }),
  Component: ({ question, translate }) => {
    const { length, isVertical = false } = question;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.whatIsTheLengthOfTheLine()}<br/>${translate.instructions.giveYourAnswerInMillimetres()}`}
        sentence={`<ans/> ${translate.units.mm()}`}
        inputMaxCharacters={3}
        questionHeight={900}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="mm"
            rulerLength={100}
            items={[{ length, lineColor: color }]}
            vertical={isVertical}
          />
        )}
        sentenceStyle={{
          justifyContent: 'flex-end',
          alignSelf: 'flex-end'
        }}
        mainPanelStyle={{ flexDirection: isVertical ? 'row' : 'column' }}
        testCorrect={userAnswer =>
          [length.toString(), (length + 1).toString(), (length - 1).toString()].includes(
            userAnswer[0]
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [length.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGH',
  description: 'aGH',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z.object({
    length: z.number().int().min(10).max(79),
    /** Defaults to false, doesn't cause an error if isVertical isn't set. */
    isVertical: z.boolean().optional()
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(10, 79),
    isVertical: getRandomBoolean()
  }),
  Component: ({ question, translate }) => {
    const { length, isVertical = false } = question;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    const rulerLength = length < 50 ? 50 : 80;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.whatIsTheLengthOfTheLine()}<br/>${translate.instructions.giveYourAnswerInMillimetres()}`}
        sentence={`<ans/> ${translate.units.mm()}`}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={rulerLength / 10}
            items={[{ length: length / 10, lineColor: color }]}
            vertical={isVertical}
          />
        )}
        mainPanelStyle={{ flexDirection: isVertical ? 'row' : 'column' }}
        sentenceStyle={{
          alignSelf: 'flex-end',
          justifyContent: 'flex-end'
        }}
        testCorrect={userAnswer =>
          [length.toString(), (length + 1).toString(), (length - 1).toString()].includes(
            userAnswer[0]
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [length.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aGI',
  description: 'aGI',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z.object({
    length: z.number().int().min(10).max(99),
    angle: z.number().int().min(10).max(35).step(5),
    isLeftPlane: z.boolean(),
    /** Defaults to false, doesn't cause an error if isCm isn't set. */
    isCm: z.boolean().optional()
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(10, 99),
    angle: randomIntegerInclusiveStep(10, 35, 5),
    isLeftPlane: getRandomBoolean(),
    isCm: getRandomBoolean()
  }),
  Component: ({ question, translate }) => {
    const { length, angle, isLeftPlane, isCm = false } = question;
    const rulerLength = length < 50 ? 50 : 100;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(question)
    }) as string;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.whatIsTheLengthOfTheLine()}<br/>${translate.instructions.giveYourAnswerInMillimetres()}`}
        sentence={`<ans/> ${translate.units.mm()}`}
        inputMaxCharacters={3}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind={isCm ? 'cm' : 'mm'}
            rotation={isLeftPlane ? -angle : angle}
            rulerLength={isCm ? rulerLength / 10 : rulerLength}
            items={[{ length: isCm ? length / 10 : length, lineColor: color }]}
          />
        )}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
        testCorrect={userAnswer =>
          [length.toString(), (length + 1).toString(), (length - 1).toString()].includes(
            userAnswer[0]
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [length.toLocaleString()],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aGJ',
  description: 'aGJ',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z
    .object({
      start: z.number().int().min(5).max(50),
      length: z.number().int().min(40).max(90),
      object: z.enum(['lollipop', 'pencil', 'stick', 'drinking_straw', 'toy_car']),
      wrongStart: z.number().int().min(1).max(60),
      wrongEnd: z.number().int().min(35).max(100),
      wrongLength: z.number().int().min(30).max(100)
    })
    .refine(({ start, length }) => start + length <= 100 && length - start > 6)
    .refine(({ start, length, wrongStart, wrongEnd, wrongLength }) =>
      arrayHasNoDuplicates([start, length, length + start, wrongStart, wrongEnd, wrongLength])
    ),
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'lollipop',
      'pencil',
      'stick',
      'drinking_straw',
      'toy_car'
    ] as const);

    const { start, length, wrongStart, wrongEnd, wrongLength } = rejectionSample(
      () => {
        const start = randomIntegerInclusive(5, 50);

        const length = randomIntegerInclusive(40, 90, {
          constraint: x => x + start <= 100
        });
        const wrongStart = start <= 10 ? start + 10 : getRandomBoolean() ? start + 10 : start - 10;
        const wrongLength = getRandomBoolean() ? length + 10 : length - 10;

        const end = start + length;
        const wrongEnd = end >= 90 ? end - 10 : getRandomBoolean() ? end + 10 : end - 10;

        return { start, length, wrongStart, wrongEnd, wrongLength };
      },

      ({ start, length, wrongStart, wrongEnd, wrongLength }) =>
        length - start > 6 &&
        arrayHasNoDuplicates([start, length, length + start, wrongStart, wrongEnd, wrongLength])
    );

    return { length, object, start, wrongStart, wrongEnd, wrongLength };
  },
  Component: ({ question, translate }) => {
    const { length, object, start, wrongStart, wrongEnd, wrongLength } = question;

    let objectSvgName = object as SvgName;

    if (object === 'pencil') {
      objectSvgName = getRandomFromArray(
        ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
        {
          random: seededRandom(question)
        }
      );
    }

    if (object === 'lollipop') objectSvgName = 'Base_Ten/Lollipops1';
    if (object === 'toy_car') objectSvgName = 'Car';

    const theObject = measureObjectWithArticle(object, translate);

    const selectables = shuffle(
      [
        `${(length + start).toLocaleString()}`,
        `${start.toLocaleString()}`,
        `${length.toLocaleString()}`,
        `${wrongStart.toLocaleString()}`,
        `${wrongEnd.toLocaleString()}`,
        `${wrongLength.toLocaleString()}`
      ],
      { random: seededRandom(question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsMakeCorrectCalculationToWorkOutLengthOfObject(
          theObject
        )}
        pdfTitle={translate.instructions.useCardsMakeCorrectCalculationToWorkOutLengthOfObjectPDF(
          theObject
        )}
        sentence={`<ans/> ${translate.units.mm()} ${SUB} <ans/> ${translate.units.mm()} = <ans/> ${translate.units.mm()}`}
        items={selectables}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="mm"
            rulerLength={100}
            items={[
              {
                start,
                length,
                svgInfo: { name: objectSvgName },
                guidelines: true
              }
            ]}
            // make the vertical lollipop svg lay horizontally
            vertical={object === 'lollipop'}
            rotation={object === 'lollipop' ? 90 : 0}
          />
        )}
        testCorrect={[`${length + start}`, `${start}`, `${length}`]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5v2 = newQuestionContent({
  uid: 'aGK2',
  description: 'aGK2',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z
    .object({
      start: z.number().int().min(5).max(50),
      length: z.number().int().min(40).max(90),
      object: z.enum(['lollipop', 'pencil', 'stick', 'drinking_straw', 'toy_car']),
      options: z
        .array(z.number().int().min(0).max(100))
        .length(6)
        .refine(arrayHasNoDuplicates, 'options must all be unique')
    })
    .refine(({ start, length }) => start + length <= 100 && length - start > 6),
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'drinking_straw',
      'lollipop',
      'pencil',
      'stick',
      'toy_car'
    ] as const);

    const { start, length, wrongStart, wrongEnd, wrongLength } = rejectionSample(
      () => {
        const start = randomIntegerInclusive(5, 50);

        const length = randomIntegerInclusive(40, 90, {
          constraint: x => x + start <= 100
        });
        const wrongStart = start <= 10 ? start + 10 : getRandomBoolean() ? start + 10 : start - 10;
        const wrongLength = getRandomBoolean() ? length + 10 : length - 10;

        const end = start + length;
        const wrongEnd = end >= 90 ? end - 10 : getRandomBoolean() ? end + 10 : end - 10;

        return { start, length, wrongStart, wrongEnd, wrongLength };
      },

      ({ start, length, wrongStart, wrongEnd, wrongLength }) =>
        length - start > 6 &&
        arrayHasNoDuplicates([start, length, length + start, wrongStart, wrongEnd, wrongLength])
    );

    const options = shuffle([start, start + length, length, wrongStart, wrongEnd, wrongLength]);

    return {
      length,
      object,
      start,
      options
    };
  },
  Component: ({ question, translate }) => {
    const { length, object, start, options } = question;

    let objectSvgName = object as SvgName;

    if (object === 'pencil') {
      objectSvgName = getRandomFromArray(
        ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
        {
          random: seededRandom(question)
        }
      );
    }

    if (object === 'lollipop') objectSvgName = 'Base_Ten/Lollipops1';
    if (object === 'toy_car') objectSvgName = 'Car';

    const theObject = measureObjectWithArticle(object, translate);

    const draggableItems = options.map(it => it.toLocaleString());

    const answer1 = (length + start).toString();
    const answer2 = start.toString();
    const answer3 = length.toString();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsMakeCorrectCalculationToWorkOutLengthOfObject(
          theObject
        )}
        pdfTitle={translate.instructions.useCardsMakeCorrectCalculationToWorkOutLengthOfObjectPDF(
          theObject
        )}
        sentence={`<ans/> ${translate.units.mm()} ${SUB} <ans/> ${translate.units.mm()} = <ans/> ${translate.units.mm()}`}
        items={draggableItems}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={10}
            items={[
              {
                start: start / 10,
                length: length / 10,
                svgInfo: { name: objectSvgName },
                guidelines: true
              }
            ]}
            // make the vertical lollipop svg lay horizontally
            vertical={object === 'lollipop'}
            rotation={object === 'lollipop' ? 90 : 0}
          />
        )}
        testCorrect={[answer1, answer2, answer3]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aGK',
  description: 'aGK',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z
    .object({
      start: z.number().int().min(5).max(50),
      length: z.number().int().min(40).max(90),
      object: z.enum(['lollipop', 'pencil', 'stick', 'drinking_straw', 'toy_car']),
      wrongLength1: z.number().int().min(30).max(100),
      wrongLength2: z.number().int().min(37).max(93),
      wrongLength3: z.number().int().min(35).max(95)
    })
    .refine(({ start, length }) => start + length <= 100 && length - start > 6)
    .refine(({ start, length, wrongLength1, wrongLength2, wrongLength3 }) =>
      arrayHasNoDuplicates([
        start,
        length,
        length + start,
        wrongLength1,
        wrongLength2,
        wrongLength3
      ])
    ),
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'lollipop',
      'pencil',
      'stick',
      'drinking_straw',
      'toy_car'
    ] as const);

    const { start, length, wrongLength1, wrongLength2, wrongLength3 } = rejectionSample(
      () => {
        const start = randomIntegerInclusive(5, 50);

        const length = randomIntegerInclusive(40, 90, {
          constraint: x => x + start <= 100
        });
        const wrongLength1 = getRandomBoolean() ? length + 10 : length - 10;
        const wrongLength2 = getRandomFromArray([length + 2, length + 3, length - 2, length - 3]);
        const wrongLength3 = getRandomBoolean() ? length + 5 : length - 5;

        return { start, length, wrongLength1, wrongLength2, wrongLength3 };
      },

      ({ start, length, wrongLength1, wrongLength2, wrongLength3 }) =>
        length - start > 6 &&
        arrayHasNoDuplicates([
          start,
          length,
          length + start,
          wrongLength1,
          wrongLength2,
          wrongLength3
        ])
    );

    return { length, object, start, wrongLength1, wrongLength2, wrongLength3 };
  },
  Component: ({ question, translate }) => {
    const { length, object, start, wrongLength1, wrongLength2, wrongLength3 } = question;

    let objectSvgName = object as SvgName;

    if (object === 'pencil') {
      objectSvgName = getRandomFromArray(
        ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
        {
          random: seededRandom(question)
        }
      );
    }

    if (object === 'lollipop') objectSvgName = 'Base_Ten/Lollipops1';
    if (object === 'toy_car') objectSvgName = 'Car';

    const theObject = measureObjectWithArticle(object, translate);

    const selectables = shuffle(
      [
        `${length.toLocaleString()}`,
        `${start.toLocaleString()}`,
        `${(length + start).toLocaleString()}`,
        `${wrongLength1.toLocaleString()}`,
        `${wrongLength2.toLocaleString()}`,
        `${wrongLength3.toLocaleString()}`
      ],
      { random: seededRandom(question) }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.makeCorrectCalcToWorkOutLenOfX(theObject)}
        sentence={`<ans/> ${translate.units.mm()}`}
        items={selectables}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="mm"
            rulerLength={100}
            items={[
              {
                start,
                length,
                svgInfo: { name: objectSvgName },
                guidelines: true
              }
            ]}
            // make the vertical lollipop svg lay horizontally
            vertical={object === 'lollipop'}
            rotation={object === 'lollipop' ? 90 : 0}
          />
        )}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
        testCorrect={[`${length}`]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aGL',
  description: 'aGL',
  keywords: ['Measure', 'Length', 'Millimetres'],
  schema: z
    .object({
      start: z.number().int().min(5).max(50),
      length: z.number().int().min(40).max(90),
      object: z.enum(['lollipop', 'pencil', 'stick', 'drinking_straw', 'toy_car']),
      /** Defaults to false, doesn't cause an error if isCm isn't set. */
      isCm: z.boolean().optional()
    })
    .refine(({ start, length }) => start + length <= 100),
  simpleGenerator: () => {
    const start = randomIntegerInclusive(5, 50);
    const length = randomIntegerInclusive(40, 90, { constraint: x => x + start <= 100 });

    const object = getRandomFromArray([
      'lollipop',
      'pencil',
      'stick',
      'drinking_straw',
      'toy_car'
    ] as const);

    const isCm = getRandomBoolean();

    return { length, object, start, isCm };
  },
  Component: ({ question, translate }) => {
    const { length, object, start, isCm = false } = question;

    let objectSvgName = object as SvgName;

    if (object === 'pencil') {
      objectSvgName = getRandomFromArray(
        ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
        {
          random: seededRandom(question)
        }
      );
    }

    if (object === 'lollipop') objectSvgName = 'Base_Ten/Lollipops1';
    if (object === 'toy_car') objectSvgName = 'Car';

    const theObject = measureObjectWithArticle(object, translate);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsLengthOfX(theObject)}
        sentence={`<ans/> ${translate.units.mm()}`}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind={isCm ? 'cm' : 'mm'}
            rulerLength={isCm ? 10 : 100}
            items={[
              {
                start: isCm ? start / 10 : start,
                length: isCm ? length / 10 : length,
                svgInfo: { name: objectSvgName },
                guidelines: true
              }
            ]}
            // make the vertical lollipop svg lay horizontally
            vertical={object === 'lollipop'}
            rotation={object === 'lollipop' ? 90 : 0}
          />
        )}
        inputMaxCharacters={3}
        sentenceStyle={{
          alignSelf: 'flex-end'
        }}
        testCorrect={userAnswer =>
          [
            length.toString(),
            (length + 1).toString(),
            (length - 1).toString(),
            (length + 2).toString(),
            (length - 2).toString()
          ].includes(userAnswer[0])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [length.toLocaleString()],
          answerText: translate.markScheme.allowMarginOfErrorOfX(2)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInMillimetres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
