import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { decimalToFraction } from '../../../../utils/fractions';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { algebraicSymbolSchema, getAlgebraicSymbol } from '../../../../utils/algebraicSymbols';
import { DEGREES } from '../../../../constants';
import AnglesAroundAPoint from '../../../../components/question/representations/AnglesAroundAPoint';
import { countRange } from '../../../../utils/collections';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aAi',
  description: 'aAi',
  keywords: ['Angles', 'Turns', 'Degrees'],
  schema: z.object({
    turn: numberEnum([1, 0.75, 0.5, 0.25])
  }),
  simpleGenerator: () => {
    const turn = getRandomFromArray([1, 0.75, 0.5, 0.25] as const);

    return { turn };
  },

  Component: props => {
    const {
      question: { turn },
      translate
    } = props;

    const answers = ((): [string, string] => {
      switch (turn) {
        case 1:
          return [(4).toString(), (360).toString()];
        case 0.75:
          return [(3).toString(), (270).toString()];
        case 0.5:
          return [(2).toString(), (180).toString()];
        case 0.25:
          return [(1).toString(), (90).toString()];
        default:
          throw new Error('Unreachable');
      }
    })();

    const frac = decimalToFraction(turn);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        sentence={
          turn === 1
            ? translate.answerSentences.oneTurnEqualsAnsRAEqualsDegrees()
            : translate.answerSentences.xOfATurnEqualsAnsRAEqualsDegrees(
                `<frac n='${frac[0]}' d='${frac[1]}' />`
              )
        }
        testCorrect={answers}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aAi2',
  description: 'aAi',
  keywords: ['Angles', 'Turns', 'Degrees'],
  schema: z.object({
    turn: numberEnum([1, 0.75, 0.5, 0.25])
  }),
  simpleGenerator: () => {
    const turn = getRandomFromArray([1, 0.75, 0.5, 0.25] as const);

    return { turn };
  },

  Component: props => {
    const {
      question: { turn },
      translate,
      displayMode
    } = props;

    const answers = ((): [string, string] => {
      switch (turn) {
        case 1:
          return [(4).toString(), (360).toString()];
        case 0.75:
          return [(3).toString(), (270).toString()];
        case 0.5:
          return [(2).toString(), (180).toString()];
        case 0.25:
          return [(1).toString(), (90).toString()];
      }
    })();

    const [numerator, denominator] = decimalToFraction(turn);

    const LHS = [
      turn === 1
        ? translate.directions.oneCompleteTurn()
        : translate.directions.fracOfCompleteTurn(numerator, denominator),
      ''
    ];

    const RHS = [translate.answerSentences.ansRightAngles(), translate.answerSentences.ansDeg()];

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeSentence()}
        testCorrect={{
          left: [[], []],
          right: [[answers[0]], [answers[1]]]
        }}
        leftSide={LHS}
        rightSide={RHS}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentenceStyle={{ height: 120 }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAj',
  description: 'aAj',
  keywords: ['Angles', 'Around a point', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(30).max(325).step(5),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusiveStep(30, 325, 5);
    const letter = getAlgebraicSymbol();

    return { angle, letter };
  },
  Component: props => {
    const {
      question: { angle, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[(360 - angle).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` },
                { angle: 360 - angle, innerLabel: letter }
              ]}
              showOuterCircle
              arcSizeGroups={[[0], [1]]}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aAk',
  description: 'aAk',
  keywords: ['Angles', 'Around a point', 'Calculate'],
  schema: z.object({
    angle: z.number().int().min(31).max(329),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(31, 329, { constraint: x => x % 5 !== 0 });
    const letter = getAlgebraicSymbol();

    return { angle, letter };
  },
  Component: props => {
    const {
      question: { angle, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[(360 - angle).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: angle, innerLabel: `${angle.toLocaleString()}${DEGREES}` },
                { angle: 360 - angle, innerLabel: letter }
              ]}
              arcSizeGroups={[[0], [1]]}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aAl',
  description: 'aAl',
  keywords: ['Angles', 'Around a point', 'Calculate'],
  schema: z
    .object({
      angle1: z.number().int().min(31).max(89),
      angle2: z.number().int().min(91).max(269),
      letter: algebraicSymbolSchema
    })
    .refine(val => val.angle1 + val.angle2 <= 330, 'the missing angle must be 30 or greater'),
  simpleGenerator: () => {
    const angle1 = randomIntegerInclusive(31, 89, { constraint: x => x % 10 !== 0 });
    const angle2 = randomIntegerInclusive(91, Math.min(269, 330 - angle1), {
      constraint: x => x % 5 !== 0
    });
    const letter = getAlgebraicSymbol();

    return { angle1, angle2, letter };
  },
  Component: props => {
    const {
      question: { angle1, angle2, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[(360 - (angle1 + angle2)).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={[
                { angle: angle1, innerLabel: `${angle1.toLocaleString()}${DEGREES}` },
                { angle: angle2, innerLabel: `${angle2.toLocaleString()}${DEGREES}` },
                { angle: 360 - (angle1 + angle2), innerLabel: letter }
              ]}
              arcSizeGroups={[[0], [1], [2]]}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aAm',
  description: 'aAm',
  keywords: ['Angles', 'Around a point', 'Calculate'],
  schema: z.object({
    splits: numberEnum([6, 8, 9, 10, 12]),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const splits = getRandomFromArray([6, 8, 9, 10, 12] as const);
    const letter = getAlgebraicSymbol();

    return { splits, letter };
  },
  Component: props => {
    const {
      question: { splits, letter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutMissingAngles()}
        testCorrect={[(360 / splits).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={countRange(splits).map(() => ({
                angle: 360 / splits,
                innerLabel: letter
              }))}
              showOuterCircle
            />
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aAn',
  description: 'aAn',
  keywords: ['Angles', 'Around a point', 'Calculate'],
  schema: z.object({
    splits: numberEnum([6, 8, 9, 10, 12]),
    splitCount: z.number().int().min(2).max(11),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const splits = getRandomFromArray([6, 8, 9, 10, 12] as const);
    const splitCount = randomIntegerInclusive(2, splits - 1);
    const letter = getAlgebraicSymbol();

    return { splits, splitCount, letter };
  },
  Component: props => {
    const {
      question: { splits, splitCount, letter },
      translate
    } = props;

    const labelledArcIndex = Math.floor(splitCount / 2);

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans/> ${DEGREES}`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.aCircleIsDividedIntoXPartsWhatIsSizeOfAngle(splits, letter)}
        testCorrect={[((360 / splits) * splitCount).toString()]}
        Content={({ dimens }) => {
          return (
            <AnglesAroundAPoint
              radius={Math.min(dimens.height * 0.4, dimens.width * 0.4)}
              angles={countRange(splits).map(i => ({
                angle: 360 / splits,
                innerLabel: i === labelledArcIndex ? letter : '',
                fill: i < splitCount ? colors.pacificBlueTint15 : colors.white
              }))}
              showOuterCircle
              innerArcSegments={countRange(splitCount)}
            />
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateAnglesAroundAPoint',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
