import z from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { countRange, filledArray, sortNumberArray } from '../../../../utils/collections';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import { getCounterArrangementScale } from '../../../../components/question/representations/CounterBoxArrangement/counterArrangements';
import { getGroupSvgName } from '../../../../utils/objectsImages';
import QF60DragCountersIntoGroups from '../../../../components/question/questionFormats/QF60DragCountersIntoGroups';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bd2',
  description: 'bd2',
  keywords: ['Equal', 'Unequal', 'Groups'],
  schema: z.object({
    items: z.enum(['Apple', 'Pear', 'Banana', 'Muffin', 'Cookie', 'Counters']).array().length(3),
    isEqual: z.boolean(),
    options: z
      .array(
        z.object({
          groups: z.number().int().min(2).max(5),
          wrongGroups: z.number().int(),
          inEachGroup: z.number().int().min(2).max(5),
          isAdd: z.boolean()
        })
      )
      .length(3),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const items = getRandomSubArrayFromArray(
      ['Apple', 'Pear', 'Banana', 'Muffin', 'Cookie', 'Counters'] as const,
      3
    );

    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;

    const isEqual = getRandomBoolean();

    const correctOption = {
      groups: randomIntegerInclusive(2, 5),
      wrongGroups: 0,
      inEachGroup: randomIntegerInclusive(2, 5),
      isAdd: getRandomBoolean()
    };

    const incorrectGroup = randomIntegerInclusive(2, 5);
    const incorrectEachGroup = randomIntegerInclusive(2, 5);
    const incorrectOption = {
      groups: incorrectGroup,
      wrongGroups: randomIntegerInclusive(1, incorrectGroup - 1),
      inEachGroup: incorrectEachGroup,
      isAdd: incorrectEachGroup === 5 ? false : getRandomBoolean()
    };

    const thirdGroup = randomIntegerInclusive(2, 5);
    const thirdEachGroup = randomIntegerInclusive(2, 5);
    const thirdOption = {
      groups: thirdGroup,
      wrongGroups: isEqual ? randomIntegerInclusive(1, thirdGroup - 1) : 0,
      inEachGroup: thirdEachGroup,
      isAdd: thirdEachGroup === 5 ? false : getRandomBoolean()
    };

    const options = shuffle([correctOption, incorrectOption, thirdOption]);

    return { items, options, seed, isEqual };
  },
  Component: props => {
    const {
      question: { items, options, seed, isEqual },
      translate
    } = props;

    const random = seededRandom({ items, options, isEqual });
    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const, { random });

    const arrays = options.map((arr, i) => ({
      isCorrect: isEqual ? arr.wrongGroups === 0 : arr.wrongGroups > 0,
      value: ['A', 'B', 'C'][i],
      array: shuffle(
        [
          ...filledArray(arr.inEachGroup + (arr.isAdd ? 1 : -1), arr.wrongGroups),
          ...filledArray(arr.inEachGroup, arr.groups - arr.wrongGroups)
        ],
        { random }
      )
    }));

    return (
      <QF11SelectImagesUpTo4
        title={
          isEqual
            ? translate.ks1Instructions.selectThePictureThatShowsEqualGroups()
            : translate.ks1Instructions.selectThePictureThatShowsUnequalGroups()
        }
        pdfTitle={
          isEqual
            ? translate.ks1PDFInstructions.tickThePictureThatShowsEqualGroups()
            : translate.ks1PDFInstructions.tickThePictureThatShowsUnequalGroups()
        }
        numItems={3}
        innerContainerStyle={{ justifyContent: 'center' }}
        renderItems={({ dimens }) => {
          return arrays.map((obj, i) => {
            const sort = sortNumberArray(obj.array, 'descending');
            const scale = Math.max(
              getCounterArrangementScale(sort[0], true),
              getCounterArrangementScale(sort[obj.array.length - 1], true)
            );
            return {
              value: obj.value,
              component: (
                <View
                  style={{
                    width: dimens.width,
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10
                  }}
                >
                  {obj.array.map((val, idx) => {
                    const item = items[i];
                    return item === 'Counters' ? (
                      <CounterBoxArrangement
                        key={`${idx}`}
                        counters={val}
                        color={color}
                        dimens={{ height: dimens.width / 6, width: dimens.width / 6 }}
                        isCircle
                        isSimpleArrangements
                        seed={seed}
                        scale={scale}
                      />
                    ) : (
                      <AssetSvg
                        key={`${i}_${idx}`}
                        name={getGroupSvgName(item, val)}
                        height={dimens.height / 3}
                        width={dimens.width / 6}
                      />
                    );
                  })}
                </View>
              )
            };
          });
        }}
        testCorrect={arrays.filter(val => val.isCorrect).map(val => val.value)}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bd3',
  description: 'bd3',
  keywords: ['Equal', 'Groups', 'Counters'],
  schema: z.object({
    groups: z.number().int().min(2).max(6),
    inEachGroup: z.number().int().min(2).max(6),
    sameLayout: z.boolean(),
    color: z.enum(['blue', 'green', 'red', 'yellow']),
    rotation: numberEnum([90, 180, 270, 0]).array(),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);
    const inEachGroup = randomIntegerInclusive(2, 6);
    const sameLayout = getRandomFromArrayWithWeights([true, false], [3, 1]);
    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;
    const rotation =
      inEachGroup > 3
        ? sameLayout
          ? // if all same layout then have the same rotation
            filledArray(getRandomFromArray([90, 180, 270, 0] as const), groups)
          : countRange(groups).map(() => getRandomFromArray([90, 180, 270, 0] as const))
        : filledArray(0 as const, groups);

    return { groups, inEachGroup, sameLayout, seed, color, rotation };
  },
  Component: props => {
    const {
      question: { groups, inEachGroup, sameLayout, seed, color, rotation },
      translate
    } = props;

    const scale = getCounterArrangementScale(inEachGroup);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentence()}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: groups > 2 ? 'flex-start' : 'center',
                gap: 20
              }}
            >
              {countRange(groups).map(idx => (
                <CounterBoxArrangement
                  key={`${idx}`}
                  counters={inEachGroup}
                  color={color}
                  dimens={{ height: dimens.height * 0.45, width: dimens.width * 0.31 }}
                  seed={sameLayout ? seed : idx * Math.random() * 10000}
                  rotation={rotation[idx]}
                  scale={scale}
                />
              ))}
            </View>
          );
        }}
        testCorrect={[groups.toString(), inEachGroup.toString()]}
        pdfDirection="column"
        questionHeight={1000}
        sentence={translate.ks1AnswerSentences.thereAreAnsEqualGroupsOfAns()}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bd4',
  description: 'bd4',
  keywords: ['Equal', 'Groups', 'Counters'],
  schema: z.object({
    numberOfGroups: numberEnum([2, 3, 4]),
    countersPerGroup: z.array(z.array(z.enum(['red', 'yellow', 'blue', 'green']))),
    correctNumberOfCounters: z.number().int().min(2).max(5),
    color: z.enum(['red', 'yellow', 'blue', 'green'])
  }),
  simpleGenerator: () => {
    const numberOfGroups = getRandomFromArray([2, 3, 4] as const);

    const correctNumberOfCounters = randomIntegerInclusive(2, 5);

    const numberOfCorrectGroups = randomIntegerInclusive(1, numberOfGroups - 1);

    const numberOfIncorrectCountersPerGroup = countRange(
      numberOfGroups - numberOfCorrectGroups
    ).map(() => randomIntegerInclusive(0, correctNumberOfCounters - 1));

    const correctCountGroups = countRange(numberOfCorrectGroups).map(() => correctNumberOfCounters);

    const numberOfCountersPerGroup = shuffle([
      ...numberOfIncorrectCountersPerGroup,
      ...correctCountGroups
    ]);

    const color = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);

    const countersPerGroup = numberOfCountersPerGroup.map(number => {
      return filledArray(color, number);
    });

    return {
      numberOfGroups,
      correctNumberOfCounters,
      countersPerGroup,
      color
    };
  },
  Component: props => {
    const {
      question: { correctNumberOfCounters, numberOfGroups, countersPerGroup, color },
      translate
    } = props;

    const testCorrect = countRange(numberOfGroups).map(() =>
      filledArray(color, correctNumberOfCounters)
    );

    return (
      <QF60DragCountersIntoGroups
        numberOfGroups={numberOfGroups}
        title={translate.ks1Instructions.dragCountersToMakeXEqualGroupsOfY(
          numberOfGroups,
          correctNumberOfCounters
        )}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToMakeXEqualGroupsOfY(
          numberOfGroups,
          correctNumberOfCounters
        )}
        items={[color]}
        prefilledBoxes={{ type: 'color', value: countersPerGroup }}
        testCorrect={testCorrect}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseEqualGroups',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
