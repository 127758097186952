import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { fractionSchema } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  logUniformSample,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import deepEqual from 'react-fast-compare';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import TextStructure from '../../../../components/molecules/TextStructure';
import { AssetSvg, getSvgInfo, type SvgName } from '../../../../assets/svg';
import { StyleSheet, View } from 'react-native';
import { colors } from '../../../../theme/colors';
import { Dimens } from '../../../../theme/scaling';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';

////
// Questions
////

const fractionShapes = {
  2: ['Circle', 'Hexagon', 'Octagon', 'Rectangle', 'Square', 'Triangle'],
  3: ['Square', 'Triangle'],
  4: ['Circle', 'Hexagon', 'Octagon', 'Rectangle', 'Square'],
  5: ['Pentagon', 'Square']
} as const;

const fractionColors = ['blue', 'yellow', 'green'] as const;

const equalOption = (
  options:
    | {
        shape: 'Circle' | 'Hexagon' | 'Octagon' | 'Rectangle' | 'Square' | 'Triangle';
        denominator: 2;
      }
    | {
        shape: 'Square' | 'Triangle';
        denominator: 3;
      }
    | {
        shape: 'Circle' | 'Hexagon' | 'Octagon' | 'Rectangle' | 'Square';
        denominator: 4;
      }
    | {
        shape: 'Pentagon' | 'Square';
        denominator: 5;
      },
  color: 'blue' | 'yellow' | 'green'
): SvgName => {
  switch (options.denominator) {
    case 2:
      return `Equal_shapes_2_parts/${options.shape}_equal_2-1_1_${color}`;
    case 3: {
      return `Equal_shapes_3_parts/${options.shape}_equal_3-1_1_${color}`;
    }
    case 4:
      return `Equal_shapes_4_parts/${options.shape}_equal_4-1_1_${color}`;
    case 5: {
      return `Equal_shapes_5_parts/${options.shape}_equal_5-1_1_${color}`;
    }
  }
};

const unequalOption = (
  options:
    | {
        shape: 'Circle' | 'Hexagon' | 'Octagon' | 'Rectangle' | 'Square' | 'Triangle';
        denominator: 2;
      }
    | {
        shape: 'Square' | 'Triangle';
        denominator: 3;
      }
    | {
        shape: 'Circle' | 'Hexagon' | 'Octagon' | 'Rectangle' | 'Square';
        denominator: 4;
      }
    | {
        shape: 'Pentagon' | 'Square';
        denominator: 5;
      },
  color: 'blue' | 'yellow' | 'green'
): SvgName => {
  switch (options.denominator) {
    case 2:
      return `Unequal_shapes_2_parts/${options.shape}_unequal_1_2_1_${color}`;
    case 3: {
      return `Unequal_shapes_3_parts/${options.shape}_unequal_1-3`;
    }
    case 4:
      return `Unequal_shapes_4_parts/${options.shape}_unequal_1_4_1_${color}`;
    case 5: {
      return `Unequal_shapes_5_parts/${options.shape}_unequal_1-5`;
    }
  }
};

const Question1 = newQuestionContent({
  uid: 'bkz',
  description: 'bkz',
  keywords: ['Fraction', 'Unit fraction', 'Parts', 'Whole'],
  schema: z.object({
    items: z
      .array(
        z.discriminatedUnion('denominator', [
          z.object({
            denominator: z.literal(2),
            shape: z.enum(fractionShapes[2]),
            color: z.enum(fractionColors),
            isCorrect: z.boolean()
          }),
          z.object({
            denominator: z.literal(3),
            shape: z.enum(fractionShapes[3]),
            color: z.enum(fractionColors),
            isCorrect: z.boolean()
          }),
          z.object({
            denominator: z.literal(4),
            shape: z.enum(fractionShapes[4]),
            color: z.enum(fractionColors),
            isCorrect: z.boolean()
          }),
          z.object({
            denominator: z.literal(5),
            shape: z.enum(fractionShapes[5]),
            color: z.enum(fractionColors),
            isCorrect: z.boolean()
          })
        ])
      )
      .length(4)
      .refine(
        items =>
          arrayHasNoDuplicates(
            items.map(({ color, ...rest }) => rest),
            deepEqual
          ),
        'items must have no duplicates ignoring color'
      )
  }),
  simpleGenerator: () => {
    const correctAnswerCount = randomIntegerInclusive(1, 3);
    const items = rejectionSample(
      () => {
        const correctIndexes = randomUniqueIntegersInclusive(0, 3, correctAnswerCount);
        const items = countRange(4).map(i => {
          const denominator = getRandomFromArray([2, 3, 4, 5] as const);
          // I've only used a switch statement because the types are complaining.
          switch (denominator) {
            case 2:
              return {
                denominator,
                isCorrect: correctIndexes.includes(i),
                shape: getRandomFromArray([...fractionShapes[denominator]] as const),
                color: getRandomFromArray(fractionColors)
              };
            case 3:
              return {
                denominator,
                isCorrect: correctIndexes.includes(i),
                shape: getRandomFromArray([...fractionShapes[denominator]] as const),
                color: getRandomFromArray(fractionColors)
              };
            case 4:
              return {
                denominator,
                isCorrect: correctIndexes.includes(i),
                shape: getRandomFromArray([...fractionShapes[denominator]] as const),
                color: getRandomFromArray(fractionColors)
              };
            case 5:
              return {
                denominator,
                isCorrect: correctIndexes.includes(i),
                shape: getRandomFromArray([...fractionShapes[denominator]] as const),
                color: getRandomFromArray(fractionColors)
              };
          }
        });

        return items;
      },
      item =>
        arrayHasNoDuplicates(
          item.map(({ color, ...rest }) => rest),
          deepEqual
        )
    );

    return { items };
  },
  Component: ({ question: { items }, translate }) => {
    const correctAnswers = items.filter(val => val.isCorrect);

    return (
      <QF11SelectImagesUpTo4
        title={
          correctAnswers.length > 1
            ? translate.ks1Instructions.selectTheShapesThatHaveOneEqualPartShaded()
            : translate.ks1Instructions.selectTheShapeThatHasOneEqualPartShaded()
        }
        pdfTitle={
          correctAnswers.length > 1
            ? translate.ks1PDFInstructions.tickTheShapesThatHaveOneEqualPartShaded()
            : translate.ks1PDFInstructions.tickTheShapeThatHasOneEqualPartShaded()
        }
        numItems={4}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <AssetSvg
                name={
                  item.isCorrect ? equalOption(item, item.color) : unequalOption(item, item.color)
                }
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value: item
          }))
        }
        testCorrect={correctAnswers}
        questionHeight={1000}
        multiSelect
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bkA',
  description: 'bkA',
  keywords: ['Fraction', 'Unit fraction'],
  schema: z
    .object({
      object: z.enum(['orange', 'pear', 'cherry', 'chair', 'eraser']),
      rows: z.number().int().min(1).max(3),
      columns: z.number().int().min(1).max(5),
      circledRegion: z.discriminatedUnion('variant', [
        z.object({
          variant: z.literal('row'),
          rowIndex: z.number()
        }),
        z.object({
          variant: z.literal('column'),
          columnIndex: z.number()
        }),
        z.object({
          variant: z.literal('quadrant'),
          quadrantIndex: z.number().int().min(0).max(3)
        })
      ])
    })
    .refine(val => {
      switch (val.circledRegion.variant) {
        case 'row':
          return val.rows - 1 >= val.circledRegion.rowIndex;
        case 'column':
          return val.columns - 1 >= val.circledRegion.columnIndex;
        case 'quadrant':
          return val.rows % 2 === 0 && val.columns % 2 === 0;
      }
    }, 'circledRegion must correspond to a region in the array'),
  simpleGenerator: () => {
    const object = getRandomFromArray(['orange', 'pear', 'cherry', 'chair', 'eraser'] as const);

    const variant = getRandomFromArrayWithWeights(
      ['column', 'row', 'quadrant'] as const,
      [2, 2, 1]
    );

    // 2 to 5, but give 4 twice the weight
    const correctDenominator =
      variant === 'quadrant'
        ? 4
        : variant === 'column'
        ? randomIntegerInclusive(2, 5)
        : randomIntegerInclusive(2, 3);

    const { rows, columns, circledRegion } = (() => {
      switch (variant) {
        case 'row': {
          const rows = correctDenominator;
          const columns = randomIntegerInclusive(1, 5);
          const circledRegion = { variant, rowIndex: randomIntegerInclusive(0, rows - 1) };
          return { rows, columns, circledRegion };
        }
        case 'column': {
          const rows = randomIntegerInclusive(1, 3);
          const columns = correctDenominator;
          const circledRegion = { variant, columnIndex: randomIntegerInclusive(0, columns - 1) };
          return { rows, columns, circledRegion };
        }
        case 'quadrant': {
          const rows = 2;
          const columns = randomIntegerInclusiveStep(2, 4, 2);
          const circledRegion = { variant, quadrantIndex: randomIntegerInclusive(0, 3) };
          return { rows, columns, circledRegion };
        }
      }
    })();

    return { object, rows, columns, circledRegion };
  },
  Component: ({ question: { object, rows, columns, circledRegion }, translate, displayMode }) => {
    let correctDenominator: number;
    switch (circledRegion.variant) {
      case 'row':
        correctDenominator = rows;
        break;
      case 'column':
        correctDenominator = columns;
        break;
      case 'quadrant':
        correctDenominator = 4;
        break;
    }

    let svgName: SvgName;
    switch (object) {
      case 'orange':
        svgName = 'Array_objects/Orange';
        break;
      case 'pear':
        svgName = 'Array_objects/Pear';
        break;
      case 'cherry':
        svgName = 'Cherry';
        break;
      case 'chair':
        svgName = 'ChairBlue';
        break;
      case 'eraser':
        svgName = 'Rubber';
        break;
    }
    const svgInfo = getSvgInfo(svgName);

    const array = ({ dimens }: { dimens: Dimens }) => {
      const gap = 30;
      const svgMaxWidth = (dimens.width * 0.8 - gap * (columns - 1)) / columns;
      const svgMaxHeight = (dimens.height * 0.8 - gap * (rows - 1)) / rows;
      const scale = Math.min(svgMaxWidth / svgInfo.width, svgMaxHeight / svgInfo.height);
      const svgWidth = scale * svgInfo.width;
      const svgHeight = scale * svgInfo.height;

      const getCircledRegionWidth = (numCols: number) => (svgWidth + gap) * numCols + gap / 4;
      const getCircledRegionHeight = (numRows: number) => (svgHeight + gap) * numRows + gap / 4;
      const getCircledRegionLeft = (startColumn: number) =>
        (svgWidth + gap) * startColumn - gap / 2;
      const getCircledRegionTop = (startRow: number) => (svgHeight + gap) * startRow - gap / 2;

      let circledRegionWidth, circledRegionHeight, circledRegionLeft, circledRegionTop: number;
      switch (circledRegion.variant) {
        case 'row':
          circledRegionWidth = getCircledRegionWidth(columns);
          circledRegionHeight = getCircledRegionHeight(1);
          circledRegionLeft = getCircledRegionLeft(0);
          circledRegionTop = getCircledRegionTop(circledRegion.rowIndex);
          break;
        case 'column':
          circledRegionWidth = getCircledRegionWidth(1);
          circledRegionHeight = getCircledRegionHeight(rows);
          circledRegionLeft = getCircledRegionLeft(circledRegion.columnIndex);
          circledRegionTop = getCircledRegionTop(0);
          break;
        case 'quadrant':
          circledRegionWidth = getCircledRegionWidth(columns / 2);
          circledRegionHeight = getCircledRegionHeight(rows / 2);
          circledRegionLeft = getCircledRegionLeft(
            circledRegion.quadrantIndex % 2 === 0 ? 0 : columns / 2
          );
          circledRegionTop = getCircledRegionTop(circledRegion.quadrantIndex < 2 ? 0 : rows / 2);
          break;
      }

      return (
        <View style={{ gap }}>
          {countRange(rows).map(row => (
            <View key={row} style={{ flexDirection: 'row', gap }}>
              {countRange(columns).map(column => (
                <AssetSvg key={column} name={svgName} width={svgWidth} height={svgHeight} />
              ))}
            </View>
          ))}

          <View
            style={[
              styles.circledRegion,
              {
                width: circledRegionWidth,
                height: circledRegionHeight,
                left: circledRegionLeft,
                top: circledRegionTop
              }
            ]}
          />
        </View>
      );
    };

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheUnitFractionThatMatchesThePicture()}
        pdfTitle={translate.ks1PDFInstructions.tickTheUnitFractionThatMatchesThePicture()}
        numItems={4}
        renderItems={[2, 3, 4, 5].map(d => ({
          value: d,
          component: (
            <TextStructure
              sentence={`<frac n="1" d="${d}" />`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 28 : 40,
                fontWeight: '700'
              }}
            />
          )
        }))}
        Content={array}
        contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        testCorrect={[correctDenominator]}
        itemLayout="row"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkB',
  description: 'bkB',
  keywords: ['Fraction', 'Unit fraction'],
  schema: z.object({
    items: z
      .array(fractionSchema())
      .length(4)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must have no duplicates')
      .refine(
        items => items.some(([n, _d]) => n === 1),
        'items must contain at least one correct answer'
      )
  }),
  simpleGenerator: () => {
    const numCorrectAnswers = randomIntegerInclusive(2, 3);
    const correctAnswers = randomUniqueIntegersInclusive(2, 5, numCorrectAnswers).map(
      d => [1, d] as [number, number]
    );

    const numIncorrectAnswers = 4 - numCorrectAnswers;
    const incorrectAnswerTypes = getRandomSubArrayFromArray(
      ['proper', 'improper'],
      numIncorrectAnswers
    );
    const incorrectAnswers = incorrectAnswerTypes.map(type => {
      let denominator, numerator: number;
      if (type === 'proper') {
        denominator = randomIntegerInclusive(3, 5);
        numerator = randomIntegerInclusive(2, denominator - 1);
      } else {
        denominator = randomIntegerInclusive(1, 5, { sample: logUniformSample }); // Add bias to choosing 1
        numerator = randomIntegerInclusive(Math.max(denominator, 2), 9);
      }
      return [numerator, denominator] as [number, number];
    });

    return { items: shuffle([...correctAnswers, ...incorrectAnswers]) };
  },
  Component: ({ question: { items }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheUnitFractions()}
        pdfTitle={translate.ks1PDFInstructions.tickTheUnitFractions()}
        multiSelect
        numItems={4}
        renderItems={items.map(([n, d]) => ({
          value: [n, d],
          component: (
            <TextStructure
              sentence={`<frac n="${n}" d="${d}"/>`}
              fractionTextStyle={{ fontWeight: '700' }}
            />
          )
        }))}
        testCorrect={items.filter(([n, _d]) => n === 1)}
      />
    );
  }
});

const styles = StyleSheet.create({
  circledRegion: {
    borderWidth: 5,
    borderColor: colors.prussianBlue,
    borderRadius: 30,
    position: 'absolute'
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnitFractions',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
