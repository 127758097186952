import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  countRange,
  filledArray,
  sumNumberArray
} from '../../../../utils/collections';
import { barColorNames, barColorsNamesArray, barColorsNamesSchema } from '../../../../theme/colors';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { dayNames } from '../../../../utils/days';
import { Key } from '../../../../components/question/representations/Coordinates/LineGraph';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { monthAsWord, monthNames } from '../../../../utils/months';
import MultiBarChart from '../../../../components/question/representations/Coordinates/MultiBarChart';
import BarChart from '../../../../components/question/representations/Coordinates/BarChart';
import QF62bDrawMultiBarCharts from '../../../../components/question/questionFormats/QF62bDrawMultiBarCharts';
import { citiesSchema, getRandomUniqueCities } from '../../../../utils/cities';
////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aXk',
  description: 'aXk',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Interpret', 'Most', 'Fewest'],
  schema: z.object({
    firstDay: z.number().int().min(0).max(2),
    yAxisScale: numberEnum([1, 2, 5, 10]),
    data1: z
      .number()
      .int()
      .min(0)
      .max(80)
      .array()
      .length(3)
      .refine(data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }, 'Min and max must be unique'),
    data2: z
      .number()
      .int()
      .min(0)
      .max(80)
      .array()
      .length(3)
      .refine(data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }, 'Min and max must be unique'),
    lookingForMostOrLeast: z.enum(['most', 'least']),
    lookingForDinnersOrPacked: z.enum(['dinners', 'packed'])
  }),
  simpleGenerator: () => {
    const firstDay = randomIntegerInclusive(0, 2);
    const yAxisScale = getRandomFromArray([1, 2, 5, 10] as const);
    const maxY = 8 * yAxisScale;

    const data1 = rejectionSample(
      () =>
        countRange(3).map(() => randomIntegerInclusiveStep(0, maxY, yAxisScale % 5 === 0 ? 5 : 1)),
      data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }
    );

    const data2 = rejectionSample(
      () =>
        countRange(3).map(() => randomIntegerInclusiveStep(0, maxY, yAxisScale % 5 === 0 ? 5 : 1)),
      data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }
    );

    return {
      firstDay,
      yAxisScale,
      data1,
      data2,
      lookingForMostOrLeast: getRandomFromArray(['most', 'least'] as const),
      lookingForDinnersOrPacked: getRandomFromArray(['dinners', 'packed'] as const)
    };
  },
  Component: ({
    question: {
      firstDay,
      yAxisScale,
      data1,
      data2,
      lookingForMostOrLeast,
      lookingForDinnersOrPacked
    },
    translate
  }) => {
    const random = seededRandom({ firstDay, yAxisScale, data1, data2, lookingForMostOrLeast });
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2, { random });
    const ansData = lookingForDinnersOrPacked === 'dinners' ? data1 : data2;
    const min = Math.min(...ansData);
    const max = Math.max(...ansData);

    const dayIndexes = [firstDay, (firstDay + 1) % 5, (firstDay + 2) % 5];
    const days = dayIndexes.map(val => translate.time[dayNames[val]]());
    const leastPopular = days.filter((_, i) => ansData[i] === min)[0];
    const mostPopular = days.filter((_, i) => ansData[i] === max)[0];

    let title;
    let pdfTitle;

    if (lookingForMostOrLeast === 'most') {
      if (lookingForDinnersOrPacked === 'dinners') {
        title = translate.instructions.selectTheDayMostDinners();
        pdfTitle = translate.instructions.selectTheDayMostDinnersPdf();
      } else {
        title = translate.instructions.selectTheDayMostPacked();
        pdfTitle = translate.instructions.selectTheDayMostPackedPdf();
      }
    } else {
      if (lookingForDinnersOrPacked === 'dinners') {
        title = translate.instructions.selectTheDayLeastDinners();
        pdfTitle = translate.instructions.selectTheDayLeastDinnersPdf();
      } else {
        title = translate.instructions.selectTheDayLeastPacked();
        pdfTitle = translate.instructions.selectTheDayLeastPackedPdf();
      }
    }

    const dataArray = [data1, data2];
    const data = countRange(3).map(val => ({
      option: val,
      values: dataArray.map(data => data[val])
    }));

    return (
      <QF39ContentWithSelectablesOnRight
        title={title}
        pdfTitle={pdfTitle}
        selectables={Object.fromEntries(days.map(key => [key, key]))}
        correctAnswer={[lookingForMostOrLeast === 'most' ? mostPopular : leastPopular]}
        leftContent={
          <MeasureView>
            {dimens => (
              <View>
                <Key
                  colors={colors.map(key => barColorNames[key])}
                  labels={[translate.misc.schoolDinner(), translate.misc.packedLunch()]}
                  style={{ alignSelf: 'flex-end', marginBottom: -15 }}
                />
                <MultiBarChart
                  width={dimens.width}
                  height={dimens.height}
                  barValues={data}
                  barLabels={days}
                  barColors={colors.map(key => barColorNames[key])}
                  yMax={8 * yAxisScale}
                  yStepSize={yAxisScale}
                  xAxisLabel={translate.misc.day()}
                  yAxisLabel={translate.misc.numberOfChildren()}
                  yAxisArrowLabel={null}
                />
              </View>
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aXl',
  description: 'aXl',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Interpret', 'Most', 'Fewest'],
  schema: z.object({
    firstDay: z.number().int().min(0).max(2),
    yAxisScale: numberEnum([1, 2, 5, 10]),
    data1: z
      .number()
      .int()
      .min(0)
      .max(80)
      .array()
      .length(3)
      .refine(data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }, 'Min and max must be unique'),
    data2: z
      .number()
      .int()
      .min(0)
      .max(80)
      .array()
      .length(3)
      .refine(data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }, 'Min and max must be unique'),
    dayIndex: z.number().int().min(0).max(2),
    lookingForDinnersOrPacked: z.enum(['dinners', 'packed'])
  }),
  simpleGenerator: () => {
    const firstDay = randomIntegerInclusive(0, 2);
    const yAxisScale = getRandomFromArray([1, 2, 5, 10] as const);
    const maxY = 8 * yAxisScale;

    const data1 = rejectionSample(
      () =>
        countRange(3).map(() => randomIntegerInclusiveStep(0, maxY, yAxisScale % 5 === 0 ? 5 : 1)),
      data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }
    );

    const data2 = rejectionSample(
      () =>
        countRange(3).map(() => randomIntegerInclusiveStep(0, maxY, yAxisScale % 5 === 0 ? 5 : 1)),
      data => {
        const min = Math.min(...data);
        const max = Math.max(...data);
        return (
          data.filter(it => it === min).length === 1 && data.filter(it => it === max).length === 1
        );
      }
    );

    return {
      firstDay,
      yAxisScale,
      data1,
      data2,
      dayIndex: randomIntegerInclusive(0, 2),
      lookingForDinnersOrPacked: getRandomFromArray(['dinners', 'packed'] as const)
    };
  },
  Component: ({
    question: { firstDay, yAxisScale, data1, data2, dayIndex, lookingForDinnersOrPacked },
    translate
  }) => {
    const random = seededRandom({ firstDay, yAxisScale, data1, data2 });
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2, { random });

    const dayIndexes = [firstDay, (firstDay + 1) % 5, (firstDay + 2) % 5];
    const days = dayIndexes.map(val => translate.time[dayNames[val]]());

    const ansArray = lookingForDinnersOrPacked === 'dinners' ? data1 : data2;

    const dataArray = [data1, data2];
    const data = countRange(3).map(val => ({
      option: val,
      values: dataArray.map(data => data[val])
    }));

    return (
      <QF1ContentAndSentence
        title={
          lookingForDinnersOrPacked === 'dinners'
            ? translate.instructions.howManyChildrenHadDinnerOnX(days[dayIndex])
            : translate.instructions.howManyChildrenHadPackedLunchOnX(days[dayIndex])
        }
        sentence="<ans/>"
        mainPanelStyle={{ flexDirection: 'row', alignItems: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        pdfDirection="column"
        testCorrect={[ansArray[dayIndex].toString()]}
        Content={({ dimens }) => (
          <View>
            <Key
              colors={colors.map(key => barColorNames[key])}
              labels={[translate.misc.schoolDinner(), translate.misc.packedLunch()]}
              style={{ alignSelf: 'flex-end', marginBottom: -15 }}
            />
            <MultiBarChart
              width={dimens.width * 0.8}
              height={dimens.height}
              barValues={data}
              barLabels={days}
              barColors={colors.map(key => barColorNames[key])}
              yMax={8 * yAxisScale}
              yStepSize={yAxisScale}
              xAxisLabel={translate.misc.day()}
              yAxisLabel={translate.misc.numberOfChildren()}
              yAxisArrowLabel={null}
            />
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aXm',
  description: 'aXm',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Interpret', 'Total', 'Addition'],
  schema: z.object({
    month1: z.enum([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October'
    ]),
    selectedMonth: z.number().int().min(1).max(3),
    yAxisScale: numberEnum([20, 50, 100, 200, 1000]),
    adultsMonth1: z.number().int().min(0).max(30000),
    childrenMonth1: z.number().int().min(0).max(30000),
    adultsMonth2: z.number().int().min(0).max(30000),
    childrenMonth2: z.number().int().min(0).max(30000),
    adultsMonth3: z.number().int().min(0).max(30000),
    childrenMonth3: z.number().int().min(0).max(30000),
    adultsOrChildren: z.enum(['adults', 'children'])
  }),
  simpleGenerator: () => {
    const month1 = getRandomFromArray([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October'
    ] as const);

    const selectedMonth = randomIntegerInclusive(1, 3);

    const yAxisScale = getRandomFromArray([20, 50, 100, 200, 1000] as const);

    const maxY = 30 * yAxisScale;

    const [
      adultsMonth1,
      childrenMonth1,
      adultsMonth2,
      childrenMonth2,
      adultsMonth3,
      childrenMonth3
    ] = randomUniqueIntegersInclusiveStep(0, maxY, yAxisScale, 6);

    const adultsOrChildren = getRandomFromArray(['adults', 'children'] as const);

    return {
      month1,
      selectedMonth,
      yAxisScale,
      adultsMonth1,
      childrenMonth1,
      adultsMonth2,
      childrenMonth2,
      adultsMonth3,
      childrenMonth3,
      adultsOrChildren
    };
  },
  Component: props => {
    const {
      question: {
        month1,
        selectedMonth,
        yAxisScale,
        adultsMonth1,
        childrenMonth1,
        adultsMonth2,
        childrenMonth2,
        adultsMonth3,
        childrenMonth3,
        adultsOrChildren
      },
      translate
    } = props;

    const random = seededRandom(props.question);

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2, { random });

    const month1Index = monthNames.indexOf(month1);

    const month2 = monthNames[month1Index + 1];

    const month3 = monthNames[month1Index + 2];

    const month1String = monthAsWord(month1, translate);

    const month2String = monthAsWord(month2, translate);

    const month3String = monthAsWord(month3, translate);

    const selectedMonthString =
      selectedMonth === 1 ? month1String : selectedMonth === 2 ? month2String : month3String;

    const selectedAnswer = (() => {
      if (adultsOrChildren === 'adults') {
        switch (selectedMonth) {
          case 1:
            return adultsMonth1;
          case 2:
            return adultsMonth2;
          case 3:
            return adultsMonth3;
        }
      } else {
        switch (selectedMonth) {
          case 1:
            return childrenMonth1;
          case 2:
            return childrenMonth2;
          case 3:
            return childrenMonth3;
        }
      }
    })() as number;

    const yLabels = [
      (0).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 10).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 20).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 30).toLocaleString()
    ];

    return (
      <QF1ContentAndSentence
        title={
          adultsOrChildren === 'adults'
            ? translate.instructions.howManyAdultsVisitedTheMuseumInX(selectedMonthString)
            : translate.instructions.howManyChildrenVisitedTheMuseumInX(selectedMonthString)
        }
        sentence="<ans/>"
        mainPanelStyle={{ flexDirection: 'row', alignItems: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
        testCorrect={[selectedAnswer.toString()]}
        Content={({ dimens }) => (
          <View>
            <Key
              colors={colors.map(key => barColorNames[key])}
              labels={[translate.misc.adults(), translate.misc.children()]}
              style={{ alignSelf: 'flex-end', marginBottom: -15 }}
            />
            <BarChart
              width={dimens.width * 0.9}
              height={dimens.height}
              barValues={[adultsMonth1, adultsMonth2, adultsMonth3]}
              secondaryBarValues={[childrenMonth1, childrenMonth2, childrenMonth3]}
              barLabels={[month1String, month2String, month3String]}
              barColors={colors.map(key => barColorNames[key])}
              yMax={30 * yAxisScale}
              yStepSize={yAxisScale}
              xAxisLabel={translate.time.month()}
              yAxisLabel={translate.misc.numberOfVisitors()}
              yAxisArrowLabel={null}
              yLabels={yLabels}
              darkGridLinesForYLabels
            />
          </View>
        )}
        questionHeight={1400}
      />
    );
  },
  questionHeight: 1400
});

const Question3v2 = newQuestionContent({
  uid: 'aXm2',
  description: 'aXm',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Interpret', 'Total', 'Addition'],
  schema: z.object({
    month1: z.enum([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October'
    ]),
    selectedMonth: z.number().int().min(1).max(3),
    yAxisScale: numberEnum([20, 50, 100, 200, 1000]),
    adultsMonth1: z.number().int().min(0).max(30000),
    childrenMonth1: z.number().int().min(0).max(30000),
    adultsMonth2: z.number().int().min(0).max(30000),
    childrenMonth2: z.number().int().min(0).max(30000),
    adultsMonth3: z.number().int().min(0).max(30000),
    childrenMonth3: z.number().int().min(0).max(30000),
    adultsOrChildren: z.enum(['adults', 'children'])
  }),
  simpleGenerator: () => {
    const month1 = getRandomFromArray([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October'
    ] as const);

    const selectedMonth = randomIntegerInclusive(1, 3);

    const yAxisScale = getRandomFromArray([20, 50, 100, 200, 1000] as const);

    const maxY = 20 * yAxisScale;

    const [
      adultsMonth1,
      childrenMonth1,
      adultsMonth2,
      childrenMonth2,
      adultsMonth3,
      childrenMonth3
    ] = randomUniqueIntegersInclusiveStep(0, maxY, yAxisScale, 6);

    const adultsOrChildren = getRandomFromArray(['adults', 'children'] as const);

    return {
      month1,
      selectedMonth,
      yAxisScale,
      adultsMonth1,
      childrenMonth1,
      adultsMonth2,
      childrenMonth2,
      adultsMonth3,
      childrenMonth3,
      adultsOrChildren
    };
  },
  Component: props => {
    const {
      question: {
        month1,
        selectedMonth,
        yAxisScale,
        adultsMonth1,
        childrenMonth1,
        adultsMonth2,
        childrenMonth2,
        adultsMonth3,
        childrenMonth3,
        adultsOrChildren
      },
      translate
    } = props;

    const random = seededRandom(props.question);

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2, { random });

    const month1Index = monthNames.indexOf(month1);

    const month2 = monthNames[month1Index + 1];

    const month3 = monthNames[month1Index + 2];

    const month1String = monthAsWord(month1, translate);

    const month2String = monthAsWord(month2, translate);

    const month3String = monthAsWord(month3, translate);

    const selectedMonthString =
      selectedMonth === 1 ? month1String : selectedMonth === 2 ? month2String : month3String;

    const selectedAnswer = (() => {
      if (adultsOrChildren === 'adults') {
        switch (selectedMonth) {
          case 1:
            return adultsMonth1;
          case 2:
            return adultsMonth2;
          case 3:
            return adultsMonth3;
        }
      } else {
        switch (selectedMonth) {
          case 1:
            return childrenMonth1;
          case 2:
            return childrenMonth2;
          case 3:
            return childrenMonth3;
        }
      }
    })() as number;

    const yLabels = [
      (0).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 10).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 20).toLocaleString()
    ];

    return (
      <QF1ContentAndSentence
        title={
          adultsOrChildren === 'adults'
            ? translate.instructions.howManyAdultsVisitedTheMuseumInX(selectedMonthString)
            : translate.instructions.howManyChildrenVisitedTheMuseumInX(selectedMonthString)
        }
        sentence="<ans/>"
        mainPanelStyle={{ flexDirection: 'row', alignItems: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
        testCorrect={[selectedAnswer.toString()]}
        Content={({ dimens }) => (
          <View>
            <Key
              colors={colors.map(key => barColorNames[key])}
              labels={[translate.misc.adults(), translate.misc.children()]}
              style={{ alignSelf: 'flex-end', marginBottom: -15 }}
            />
            <BarChart
              width={dimens.width * 0.9}
              height={dimens.height}
              barValues={[adultsMonth1, adultsMonth2, adultsMonth3]}
              secondaryBarValues={[childrenMonth1, childrenMonth2, childrenMonth3]}
              barLabels={[month1String, month2String, month3String]}
              barColors={colors.map(key => barColorNames[key])}
              yMax={20 * yAxisScale}
              yStepSize={yAxisScale}
              xAxisLabel={translate.time.month()}
              yAxisLabel={translate.misc.numberOfVisitorsLower()}
              yAxisArrowLabel={null}
              yLabels={yLabels}
              darkGridLinesForYLabels
            />
          </View>
        )}
        questionHeight={1400}
      />
    );
  },
  questionHeight: 1400
});

const Question4 = newQuestionContent({
  uid: 'aXn',
  description: 'aXn',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Interpret', 'Total', 'Addition'],
  schema: z.object({
    month1: z.enum([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October'
    ]),
    selectedMonth: z.number().int().min(1).max(3),
    yAxisScale: numberEnum([20, 50, 100, 200, 1000]),
    adultsMonth1: z.number().int().min(0).max(30000),
    childrenMonth1: z.number().int().min(0).max(30000),
    adultsMonth2: z.number().int().min(0).max(30000),
    childrenMonth2: z.number().int().min(0).max(30000),
    adultsMonth3: z.number().int().min(0).max(30000),
    childrenMonth3: z.number().int().min(0).max(30000)
  }),
  simpleGenerator: () => {
    const month1 = getRandomFromArray([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October'
    ] as const);

    const selectedMonth = randomIntegerInclusive(1, 3);

    const yAxisScale = getRandomFromArray([20, 50, 100, 200, 1000] as const);

    const maxY = 30 * yAxisScale;

    const [adultsMonth1, childrenMonth1, adultsMonth2, adultsMonth3] =
      randomUniqueIntegersInclusiveStep(0, maxY, yAxisScale, 4);

    const childrenMonth2 = randomIntegerInclusiveStep(0, maxY, yAxisScale, {
      constraint: x =>
        arrayHasNoDuplicates([adultsMonth1, childrenMonth1, adultsMonth2, adultsMonth3, x]) &&
        // Need to ensure the differences between the pairs of numbers are all different to prevent duplicate answers:
        arrayHasNoDuplicates([Math.abs(adultsMonth1 - childrenMonth1), Math.abs(adultsMonth2 - x)])
    });

    const childrenMonth3 = randomIntegerInclusiveStep(0, maxY, yAxisScale, {
      constraint: x =>
        arrayHasNoDuplicates([
          adultsMonth1,
          childrenMonth1,
          adultsMonth2,
          childrenMonth2,
          adultsMonth3,
          x
        ]) &&
        // Need to ensure the differences between the pairs of numbers are all different to prevent duplicate answers:
        arrayHasNoDuplicates([
          Math.abs(adultsMonth1 - childrenMonth1),
          Math.abs(adultsMonth2 - childrenMonth2),
          Math.abs(adultsMonth3 - x)
        ])
    });

    return {
      month1,
      selectedMonth,
      yAxisScale,
      adultsMonth1,
      childrenMonth1,
      adultsMonth2,
      childrenMonth2,
      adultsMonth3,
      childrenMonth3
    };
  },
  Component: props => {
    const {
      question: {
        month1,
        selectedMonth,
        yAxisScale,
        adultsMonth1,
        childrenMonth1,
        adultsMonth2,
        childrenMonth2,
        adultsMonth3,
        childrenMonth3
      },
      translate
    } = props;

    const random = seededRandom(props.question);

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2, { random });

    const month1Index = monthNames.indexOf(month1);

    const month2 = monthNames[month1Index + 1];

    const month3 = monthNames[month1Index + 2];

    const month1String = monthAsWord(month1, translate);

    const month2String = monthAsWord(month2, translate);

    const month3String = monthAsWord(month3, translate);

    const selectedMonthString =
      selectedMonth === 1 ? month1String : selectedMonth === 2 ? month2String : month3String;

    const answerMonth1 = Math.abs(adultsMonth1 - childrenMonth1).toLocaleString();

    const answerMonth2 = Math.abs(adultsMonth2 - childrenMonth2).toLocaleString();

    const answerMonth3 = Math.abs(adultsMonth3 - childrenMonth3).toLocaleString();

    const shuffledAnswers = shuffle([answerMonth1, answerMonth2, answerMonth3], {
      random
    });

    const [selectedAnswer, moreAdultsOrChildren] = (() => {
      switch (selectedMonth) {
        case 1:
          return [answerMonth1, adultsMonth1 > childrenMonth1 ? 'adults' : 'children'];
        case 2:
          return [answerMonth2, adultsMonth2 > childrenMonth2 ? 'adults' : 'children'];
        case 3:
          return [answerMonth3, adultsMonth3 > childrenMonth3 ? 'adults' : 'children'];
      }
    })() as [string, string];

    const yLabels = [
      (0).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 10).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 20).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 30).toLocaleString()
    ];

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          moreAdultsOrChildren === 'adults'
            ? translate.instructions.howManyMoreAdultsThanChildrenVisitedInX(selectedMonthString)
            : translate.instructions.howManyMoreChildrenThanAdultsVisitedInX(selectedMonthString)
        }
        pdfTitle={
          moreAdultsOrChildren === 'adults'
            ? translate.instructions.howManyMoreAdultsThanChildrenVisitedInXPDF(selectedMonthString)
            : translate.instructions.howManyMoreChildrenThanAdultsVisitedInXPDF(selectedMonthString)
        }
        selectables={Object.fromEntries(shuffledAnswers.map(key => [key, key]))}
        correctAnswer={[selectedAnswer]}
        leftContent={
          <MeasureView>
            {dimens => (
              <View>
                <Key
                  colors={colors.map(key => barColorNames[key])}
                  labels={[translate.misc.adults(), translate.misc.children()]}
                  style={{ alignSelf: 'flex-end', marginBottom: -15 }}
                />
                <BarChart
                  width={dimens.width * 0.9}
                  height={dimens.height}
                  barValues={[adultsMonth1, adultsMonth2, adultsMonth3]}
                  secondaryBarValues={[childrenMonth1, childrenMonth2, childrenMonth3]}
                  barLabels={[month1String, month2String, month3String]}
                  barColors={colors.map(key => barColorNames[key])}
                  yMax={30 * yAxisScale}
                  yStepSize={yAxisScale}
                  xAxisLabel={translate.time.Month()}
                  yAxisLabel={translate.misc.numberOfVisitors()}
                  yAxisArrowLabel={null}
                  yLabels={yLabels}
                  darkGridLinesForYLabels
                />
              </View>
            )}
          </MeasureView>
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4v2 = newQuestionContent({
  uid: 'aXn2',
  description: 'aXn',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Interpret', 'Total', 'Addition'],
  schema: z.object({
    month1: z.enum([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October'
    ]),
    selectedMonth: z.number().int().min(1).max(3),
    yAxisScale: numberEnum([20, 50, 100, 200, 1000]),
    adultsMonth1: z.number().int().min(0).max(30000),
    childrenMonth1: z.number().int().min(0).max(30000),
    adultsMonth2: z.number().int().min(0).max(30000),
    childrenMonth2: z.number().int().min(0).max(30000),
    adultsMonth3: z.number().int().min(0).max(30000),
    childrenMonth3: z.number().int().min(0).max(30000)
  }),
  simpleGenerator: () => {
    const month1 = getRandomFromArray([
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October'
    ] as const);

    const selectedMonth = randomIntegerInclusive(1, 3);

    const yAxisScale = getRandomFromArray([20, 50, 100, 200, 1000] as const);

    const maxY = 20 * yAxisScale;

    const [adultsMonth1, childrenMonth1, adultsMonth2, adultsMonth3] =
      randomUniqueIntegersInclusiveStep(0, maxY, yAxisScale, 4);

    const childrenMonth2 = randomIntegerInclusiveStep(0, maxY, yAxisScale, {
      constraint: x =>
        arrayHasNoDuplicates([adultsMonth1, childrenMonth1, adultsMonth2, adultsMonth3, x]) &&
        // Need to ensure the differences between the pairs of numbers are all different to prevent duplicate answers:
        arrayHasNoDuplicates([Math.abs(adultsMonth1 - childrenMonth1), Math.abs(adultsMonth2 - x)])
    });

    const childrenMonth3 = randomIntegerInclusiveStep(0, maxY, yAxisScale, {
      constraint: x =>
        arrayHasNoDuplicates([
          adultsMonth1,
          childrenMonth1,
          adultsMonth2,
          childrenMonth2,
          adultsMonth3,
          x
        ]) &&
        // Need to ensure the differences between the pairs of numbers are all different to prevent duplicate answers:
        arrayHasNoDuplicates([
          Math.abs(adultsMonth1 - childrenMonth1),
          Math.abs(adultsMonth2 - childrenMonth2),
          Math.abs(adultsMonth3 - x)
        ])
    });

    return {
      month1,
      selectedMonth,
      yAxisScale,
      adultsMonth1,
      childrenMonth1,
      adultsMonth2,
      childrenMonth2,
      adultsMonth3,
      childrenMonth3
    };
  },
  Component: props => {
    const {
      question: {
        month1,
        selectedMonth,
        yAxisScale,
        adultsMonth1,
        childrenMonth1,
        adultsMonth2,
        childrenMonth2,
        adultsMonth3,
        childrenMonth3
      },
      translate
    } = props;

    const random = seededRandom(props.question);

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2, { random });

    const month1Index = monthNames.indexOf(month1);

    const month2 = monthNames[month1Index + 1];

    const month3 = monthNames[month1Index + 2];

    const month1String = monthAsWord(month1, translate);

    const month2String = monthAsWord(month2, translate);

    const month3String = monthAsWord(month3, translate);

    const selectedMonthString =
      selectedMonth === 1 ? month1String : selectedMonth === 2 ? month2String : month3String;

    const answerMonth1 = Math.abs(adultsMonth1 - childrenMonth1).toLocaleString();

    const answerMonth2 = Math.abs(adultsMonth2 - childrenMonth2).toLocaleString();

    const answerMonth3 = Math.abs(adultsMonth3 - childrenMonth3).toLocaleString();

    const shuffledAnswers = shuffle([answerMonth1, answerMonth2, answerMonth3], {
      random
    });

    const [selectedAnswer, moreAdultsOrChildren] = (() => {
      switch (selectedMonth) {
        case 1:
          return [answerMonth1, adultsMonth1 > childrenMonth1 ? 'adults' : 'children'];
        case 2:
          return [answerMonth2, adultsMonth2 > childrenMonth2 ? 'adults' : 'children'];
        case 3:
          return [answerMonth3, adultsMonth3 > childrenMonth3 ? 'adults' : 'children'];
      }
    })() as [string, string];

    const yLabels = [
      (0).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 10).toLocaleString(),
      ...filledArray('', 9),
      (yAxisScale * 20).toLocaleString()
    ];

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          moreAdultsOrChildren === 'adults'
            ? translate.instructions.howManyMoreAdultsThanChildrenVisitedInX(selectedMonthString)
            : translate.instructions.howManyMoreChildrenThanAdultsVisitedInX(selectedMonthString)
        }
        pdfTitle={
          moreAdultsOrChildren === 'adults'
            ? translate.instructions.howManyMoreAdultsThanChildrenVisitedInXPDF(selectedMonthString)
            : translate.instructions.howManyMoreChildrenThanAdultsVisitedInXPDF(selectedMonthString)
        }
        selectables={Object.fromEntries(shuffledAnswers.map(key => [key, key]))}
        correctAnswer={[selectedAnswer]}
        leftContent={
          <MeasureView>
            {dimens => (
              <View>
                <Key
                  colors={colors.map(key => barColorNames[key])}
                  labels={[translate.misc.adults(), translate.misc.children()]}
                  style={{ alignSelf: 'flex-end', marginBottom: -15 }}
                />
                <BarChart
                  width={dimens.width * 0.9}
                  height={dimens.height}
                  barValues={[adultsMonth1, adultsMonth2, adultsMonth3]}
                  secondaryBarValues={[childrenMonth1, childrenMonth2, childrenMonth3]}
                  barLabels={[month1String, month2String, month3String]}
                  barColors={colors.map(key => barColorNames[key])}
                  yMax={20 * yAxisScale}
                  yStepSize={yAxisScale}
                  xAxisLabel={translate.time.month()}
                  yAxisLabel={translate.misc.numberOfVisitorsLower()}
                  yAxisArrowLabel={null}
                  yLabels={yLabels}
                  darkGridLinesForYLabels
                />
              </View>
            )}
          </MeasureView>
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'aXo',
  description: 'aXo',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Interpret', 'Total', 'Addition'],
  schema: z.object({
    yAxisScale: numberEnum([2, 5, 10]),
    data1: z.number().int().min(0).max(60).array().length(3),
    data2: z.number().int().min(0).max(60).array().length(3),
    data3: z.number().int().min(0).max(60).array().length(3),
    teamIndex: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const yAxisScale = getRandomFromArray([2, 5, 10] as const);
    const maxY = 6 * yAxisScale;

    const data1 = countRange(3).map(() =>
      randomIntegerInclusiveStep(0, maxY, yAxisScale % 5 === 0 ? 5 : 1)
    );

    const data2 = countRange(3).map(() =>
      randomIntegerInclusiveStep(0, maxY, yAxisScale % 5 === 0 ? 5 : 1)
    );

    const data3 = countRange(3).map(() =>
      randomIntegerInclusiveStep(0, maxY, yAxisScale % 5 === 0 ? 5 : 1)
    );

    return {
      yAxisScale,
      data1,
      data2,
      data3,
      teamIndex: randomIntegerInclusive(0, 2)
    };
  },
  Component: ({ question: { yAxisScale, data1, data2, data3, teamIndex }, translate }) => {
    const random = seededRandom({ yAxisScale, data1, data2, data3, teamIndex });

    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 3, { random });

    const dataArray = [data1, data2, data3];
    const data = countRange(3).map(val => ({
      option: val,
      values: dataArray.map(data => data[val])
    }));

    const teams = [translate.misc.teamA(), translate.misc.teamB(), translate.misc.teamC()];
    const games = [translate.misc.gameA(), translate.misc.gameB(), translate.misc.gameC()];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyPointsDidXScoreInTotal(teams[teamIndex])}
        sentence="<ans/>"
        mainPanelStyle={{ flexDirection: 'row', alignItems: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        pdfDirection="column"
        testCorrect={[sumNumberArray(dataArray[teamIndex]).toString()]}
        Content={({ dimens }) => (
          <View>
            <Key
              colors={colors.map(key => barColorNames[key])}
              labels={teams}
              style={{ alignSelf: 'flex-end', marginBottom: -15 }}
            />
            <MultiBarChart
              width={dimens.width * 0.8}
              height={dimens.height}
              yLabels={countRange(7).map(val =>
                val % 2 === 0 ? (val * yAxisScale).toLocaleString() : ''
              )}
              barValues={data}
              barLabels={games}
              barColors={colors.map(key => barColorNames[key])}
              yMax={6 * yAxisScale}
              yStepSize={yAxisScale}
              xAxisLabel={translate.graphLabels.game()}
              yAxisLabel={translate.graphLabels.pointsScored()}
              yAxisArrowLabel={null}
            />
          </View>
        )}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'aXp',
  description: 'aXp',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Draw', 'Millilitres'],
  schema: z.object({
    firstMonth: z.number().int().min(0).max(11),
    yAxisScale: numberEnum([5, 10, 20]),
    data1: z.number().int().min(0).max(120).array().length(2),
    data2: z.number().int().min(0).max(120).array().length(2),
    cities: citiesSchema.array().length(2)
  }),
  simpleGenerator: () => {
    const firstMonth = randomIntegerInclusive(0, 11);
    const yAxisScale = getRandomFromArray([5, 10, 20] as const);
    const maxY = 6 * yAxisScale;

    const data1 = countRange(2).map(() =>
      randomIntegerInclusiveStep(0, maxY, yAxisScale === 20 ? 10 : 5)
    );

    const data2 = countRange(2).map(() =>
      randomIntegerInclusiveStep(0, maxY, yAxisScale === 20 ? 10 : 5)
    );

    const cities = getRandomUniqueCities(2);

    return {
      firstMonth,
      yAxisScale,
      data1,
      data2,
      cities
    };
  },
  Component: ({ question: { firstMonth, yAxisScale, data1, data2, cities }, translate }) => {
    const random = seededRandom({ firstMonth, yAxisScale, data1, data2, cities });
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2, { random });

    const monthIndexes = [firstMonth, (firstMonth + 1) % 12];
    const months = monthIndexes.map(val => translate.time[monthNames[val]]());

    const dataArray = [data1, data2];
    const data = countRange(2).map(val => ({
      option: val,
      values: dataArray.map(data => data[val])
    }));

    const barColors = colors.map(name => barColorNames[name]);

    return (
      <QF62bDrawMultiBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheDualBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheDualBarChartPdf()}
        correctAnswer={data}
        initialState={data.map((_val, i) => ({ option: i, values: [0, 0] }))}
        barLabels={months}
        barColors={barColors}
        snapToNearest={yAxisScale === 20 ? 10 : 5}
        yMax={6 * yAxisScale}
        yStepSize={yAxisScale}
        xAxisLabel={translate.time.month()}
        yAxisLabel={translate.graphLabels.averageRainfallMm()}
        yAxisArrowLabel={null}
        keyLabel={translate.tableHeaders.City()}
        keyValues={countRange(2).map(i => ({
          label: translate.cities[cities[i]](),
          color: barColors[i]
        }))}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6v2 = newQuestionContent({
  uid: 'aXp2',
  description: 'aXp',
  keywords: ['Dual bar chart', 'X-axis', 'Y-axis', 'Axes', 'Draw', 'Millilitres'],
  schema: z.object({
    firstMonth: z.number().int().min(0).max(11),
    yAxisScale: numberEnum([5, 10, 20]),
    data1: z.number().int().min(0).max(120).array().length(2),
    data2: z.number().int().min(0).max(120).array().length(2),
    data3: z.number().int().min(0).max(120).array().length(2),
    cities: citiesSchema.array().length(2),
    colors: z.array(barColorsNamesSchema).length(2)
  }),
  simpleGenerator: () => {
    const firstMonth = randomIntegerInclusive(0, 11);
    const yAxisScale = getRandomFromArray([5, 10, 20] as const);
    const maxY = 6 * yAxisScale;

    const data1 = countRange(2).map(() =>
      randomIntegerInclusiveStep(0, maxY, yAxisScale === 20 ? 10 : 5)
    );
    const data2 = countRange(2).map(() =>
      randomIntegerInclusiveStep(0, maxY, yAxisScale === 20 ? 10 : 5)
    );
    const data3 = countRange(2).map(() =>
      randomIntegerInclusiveStep(0, maxY, yAxisScale === 20 ? 10 : 5)
    );

    const cities = getRandomUniqueCities(2);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 2);

    return {
      firstMonth,
      yAxisScale,
      data1,
      data2,
      data3,
      cities,
      colors
    };
  },
  Component: ({
    question: { firstMonth, yAxisScale, data1, data2, data3, cities, colors },
    translate
  }) => {
    const monthIndexes = [firstMonth, (firstMonth + 1) % 12, (firstMonth + 2) % 12];
    const months = monthIndexes.map(val => translate.time[monthNames[val]]());

    const dataArray = [data1, data2, data3];
    const data = countRange(3).map(val => ({
      option: val,
      values: dataArray[val]
    }));

    const barColors = colors.map(name => barColorNames[name]);

    return (
      <QF62bDrawMultiBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheDualBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheDualBarChartPdf()}
        correctAnswer={data}
        initialState={data.map((_val, i) => ({ option: i, values: [0, 0] }))}
        barLabels={months}
        barColors={barColors}
        snapToNearest={yAxisScale === 20 ? 10 : 5}
        yMax={6 * yAxisScale}
        yStepSize={yAxisScale}
        xAxisLabel={translate.time.month()}
        yAxisLabel={translate.graphLabels.averageRainfallMm()}
        yAxisArrowLabel={null}
        keyLabel={translate.tableHeaders.city()}
        keyValues={countRange(2).map(i => ({
          label: translate.cities[cities[i]](),
          color: barColors[i]
        }))}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DualBarCharts',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5, Question6v2],
  archivedQuestionTypes: [Question3, Question4, Question6]
});
export default SmallStep;
