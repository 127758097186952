import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange,
  range
} from '../../../../utils/collections';
import AutoScaleText from '../../../../components/typography/AutoScaleText';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { numberEnum } from '../../../../utils/zod';
import { useMemo } from 'react';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aml',
  description: 'aml',
  keywords: ['Multiple'],
  schema: z.object({
    numbers: z.number().int().min(2).max(9).array().length(4),
    firstMultGivenA1: z.boolean(),
    firstMultGivenA2: z.boolean(),
    firstMultGivenA3: z.boolean(),
    firstMultGivenA4: z.boolean()
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(2, 9, 4);

    const [firstMultGivenA1, firstMultGivenA2, firstMultGivenA3, firstMultGivenA4] = range(
      1,
      4
    ).map(() => getRandomBoolean());

    return { numbers, firstMultGivenA1, firstMultGivenA2, firstMultGivenA3, firstMultGivenA4 };
  },
  Component: props => {
    const {
      question: { numbers, firstMultGivenA1, firstMultGivenA2, firstMultGivenA3, firstMultGivenA4 },
      translate
    } = props;
    const [A1, A2, A3, A4] = numbers;

    const multiplesOfA1 = `${firstMultGivenA1 ? `${A1.toLocaleString()}, ` : ''}${(
      A1 * 2
    ).toLocaleString()}, ${(A1 * 3).toLocaleString()}, ${(A1 * 4).toLocaleString()}`;

    const multiplesOfA2 = `${firstMultGivenA2 ? `${A2.toLocaleString()}, ` : ''}${(
      A2 * 2
    ).toLocaleString()}, ${(A2 * 3).toLocaleString()}, ${(A2 * 4).toLocaleString()}`;

    const multiplesOfA3 = `${firstMultGivenA3 ? `${A3.toLocaleString()}, ` : ''}${(
      A3 * 2
    ).toLocaleString()}, ${(A3 * 3).toLocaleString()}, ${(A3 * 4).toLocaleString()}`;

    const multiplesOfA4 = `${firstMultGivenA4 ? `${A4.toLocaleString()}, ` : ''}${(
      A4 * 2
    ).toLocaleString()}, ${(A4 * 3).toLocaleString()}, ${(A4 * 4).toLocaleString()}`;

    const answerOptions = [multiplesOfA1, multiplesOfA2, multiplesOfA3, multiplesOfA4];

    const statements = useMemo(() => {
      const questionStatements = [
        { statement: translate.answerSentences.multiplesOfX(A1), answer: multiplesOfA1 },
        { statement: translate.answerSentences.multiplesOfX(A2), answer: multiplesOfA2 },
        { statement: translate.answerSentences.multiplesOfX(A3), answer: multiplesOfA3 },
        { statement: translate.answerSentences.multiplesOfX(A4), answer: multiplesOfA4 }
      ];

      return shuffle(questionStatements, { random: seededRandom(props.question) });
    }, [
      translate.answerSentences,
      A1,
      multiplesOfA1,
      A2,
      multiplesOfA2,
      A3,
      multiplesOfA3,
      A4,
      multiplesOfA4,
      props.question
    ]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchListsWithStatements()}
        pdfTitle={translate.instructions.useCardsToMatchListsWithStatements()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', justifyContent: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question1v2 = newQuestionContent({
  uid: 'aml2',
  description: 'aml2',
  keywords: ['Multiple'],
  schema: z
    .object({
      numbersStatements: z.number().int().min(2).max(9).array().length(4),
      numbersItems: z.number().int().min(2).max(9).array().length(4),
      firstMultGiven1: z.number().int().min(1).max(4),
      firstMultGiven2: z.number().int().min(1).max(4),
      firstMultGiven3: z.number().int().min(1).max(4),
      firstMultGiven4: z.number().int().min(1).max(4)
    })
    .refine(
      val => arraysHaveSameContentsUnordered(val.numbersStatements, val.numbersItems),
      'numbersStatements and numbersItems must each have the same 4 numbers, in any order.'
    ),
  simpleGenerator: () => {
    const numbersStatements = randomUniqueIntegersInclusive(2, 9, 4);

    const numbersItems = shuffle(numbersStatements);

    const [firstMultGiven1, firstMultGiven2, firstMultGiven3, firstMultGiven4] = countRange(4).map(
      () => randomIntegerInclusive(1, 4)
    );

    return {
      numbersStatements,
      numbersItems,
      firstMultGiven1,
      firstMultGiven2,
      firstMultGiven3,
      firstMultGiven4
    };
  },
  Component: props => {
    const {
      question: {
        numbersStatements,
        numbersItems,
        firstMultGiven1,
        firstMultGiven2,
        firstMultGiven3,
        firstMultGiven4
      },
      translate
    } = props;
    const [numberStatement1, numberStatement2, numberStatement3, numberStatement4] =
      numbersStatements;

    const [numberItem1, numberItem2, numberItem3, numberItem4] = numbersItems;

    const multipleStringMaker = (multiple: number, firstMultipleGiven: number) => {
      return `${(multiple * firstMultipleGiven).toLocaleString()}, ${(
        multiple *
        (firstMultipleGiven + 1)
      ).toLocaleString()}, ${(multiple * (firstMultipleGiven + 2)).toLocaleString()}, ${(
        multiple *
        (firstMultipleGiven + 3)
      ).toLocaleString()}`;
    };

    const multiplesOf1 = multipleStringMaker(numberItem1, firstMultGiven1);
    const multiplesOf2 = multipleStringMaker(numberItem2, firstMultGiven2);
    const multiplesOf3 = multipleStringMaker(numberItem3, firstMultGiven3);
    const multiplesOf4 = multipleStringMaker(numberItem4, firstMultGiven4);

    const answerOptions = [multiplesOf1, multiplesOf2, multiplesOf3, multiplesOf4];

    const statementToAnswer = (statement: number) => {
      if (statement === numberItem1) {
        return multiplesOf1;
      } else if (statement === numberItem2) {
        return multiplesOf2;
      } else if (statement === numberItem3) {
        return multiplesOf3;
      } else if (statement === numberItem4) {
        return multiplesOf4;
      }
    };

    const statements = [
      {
        statement: translate.answerSentences.multiplesOfX(numberStatement1),
        answer: statementToAnswer(numberStatement1)
      },
      {
        statement: translate.answerSentences.multiplesOfX(numberStatement2),
        answer: statementToAnswer(numberStatement2)
      },
      {
        statement: translate.answerSentences.multiplesOfX(numberStatement3),
        answer: statementToAnswer(numberStatement3)
      },
      {
        statement: translate.answerSentences.multiplesOfX(numberStatement4),
        answer: statementToAnswer(numberStatement4)
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchListsWithStatements()}
        pdfTitle={translate.instructions.useCardsToMatchListsWithStatements()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        pdfItemsLetterEmWidth={1}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', justifyContent: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'amm',
  description: 'amm',
  keywords: ['Multiple'],
  schema: z.object({
    number1: z.number().int().min(2).max(12)
  }),
  questionHeight: 600,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 12);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const correctArray = [
      number1.toString(),
      (number1 * 2).toString(),
      (number1 * 3).toString(),
      (number1 * 4).toString(),
      (number1 * 5).toString()
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.giveFirst5MultiplesOfNum(number1)}
        // Allow for the answers to be in any order.
        testCorrect={answer =>
          answer.every(ans => correctArray.includes(ans[0])) &&
          arrayHasNoDuplicates([
            answer[0][0],
            answer[1][0],
            answer[2][0],
            answer[3][0],
            answer[4][0]
          ])
        }
        inputMaxCharacters={2}
        sentences={['<ans/>', '<ans/>', '<ans/>', '<ans/>', '<ans/>']}
        containerStyle={{ flexDirection: 'row', alignItems: 'center', columnGap: 16 }}
        pdfContainerStyle={{ alignItems: 'center' }}
        pdfDirection="row"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [number1.toLocaleString()],
            [(number1 * 2).toLocaleString()],
            [(number1 * 3).toLocaleString()],
            [(number1 * 4).toLocaleString()],
            [(number1 * 5).toLocaleString()]
          ],
          answerText: translate.markScheme.factorsInAnyOrder()
        }}
        questionHeight={600}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'amn',
  description: 'amn',
  keywords: ['Multiple'],
  schema: z.object({
    seed: z.number().int().min(1).max(999)
  }),
  simpleGenerator: () => {
    // Needed in order to get variation each time it's generated
    const seed = randomIntegerInclusive(1, 999);
    return { seed };
  },
  Component: props => {
    const { translate } = props;

    const answerOptions = ['Multiples of 2', 'Multiples of 3', 'Multiples of 4', 'Multiples of 5'];

    const statements = useMemo(() => {
      const questionStatements = [
        { statement: translate.answerSentences.allEvenNumbers(), answer: 'Multiples of 2' },
        {
          statement: translate.answerSentences.sumOfDigitsDivisibleByX(3),
          answer: 'Multiples of 3'
        },
        { statement: translate.answerSentences.halveAndAnswerEven(), answer: 'Multiples of 4' },
        { statement: translate.answerSentences.endsIn5Or0(), answer: 'Multiples of 5' }
      ];

      return shuffle(questionStatements, { random: seededRandom(props.question) });
    }, [props.question, translate.answerSentences]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.matchMultiplesToDescriptions()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'amo',
  description: 'amo',
  keywords: ['Multiple'],
  schema: z
    .object({
      chosenFactor: z.number().int().min(2).max(5),
      numberArray: z.number().int().min(1).max(25).array().length(8)
    })
    .refine(val => {
      const multsOfFactor = val.numberArray.filter(number => number % val.chosenFactor === 0);
      return multsOfFactor.length > 2 && multsOfFactor.length < 8;
    }, 'numberArray must contain at least three numbers that are multiples of chosenFactor, and one that is not a multiple of chosenFactor')
    .refine(
      val => arrayHasNoDuplicates(val.numberArray),
      'All numbers in numberArray must be different.'
    ),
  simpleGenerator: () => {
    const chosenFactor = randomIntegerInclusive(2, 5);

    // Choose three unique numbers that are all multiples of chosenFactor.
    const [number1, number2, number3] = randomUniqueIntegersInclusive(1, 25, 3, {
      constraint: x => x % chosenFactor === 0
    });

    // Choose a number that is not a multiple of chosenFactor.
    const number4 = randomIntegerInclusive(1, 25, {
      constraint: x => x % chosenFactor !== 0
    });

    // Choose four other numbers.
    const [number5, number6, number7, number8] = randomUniqueIntegersInclusive(1, 25, 4, {
      constraint: x => x !== number1 && x !== number2 && x !== number3 && x !== number4
    });

    const numberArray = shuffle([
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8
    ]);

    return { chosenFactor, numberArray };
  },
  Component: props => {
    const {
      question: { chosenFactor, numberArray },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectWhichAreMultiplesOf(chosenFactor)}
        pdfTitle={translate.instructions.circleWhichAreMultiplesOf(chosenFactor)}
        testCorrect={numberArray.filter(it => it % chosenFactor === 0)}
        items={numberArray.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'amp',
  description: 'amp',
  keywords: ['Multiple'],
  schema: z.object({
    number1: numberEnum([2, 3, 5, 10]),
    correctArrayNums: z.array(z.number().int().min(99).max(1001)).length(5),
    incorrectArrayNums: z.array(z.number().int().min(99).max(1001)).length(2)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 5, 10] as const);

    // Correct answers
    const correctArrayNums = randomUniqueIntegersInclusive(99, 1001, 5, {
      constraint: x => x % number1 === 0
    });

    // Incorrect answers
    const incorrectArrayNums = randomUniqueIntegersInclusive(99, 1001, 2, {
      constraint: x => x % number1 !== 0
    });

    return {
      number1,
      correctArrayNums,
      incorrectArrayNums
    };
  },

  Component: props => {
    const {
      question: { number1, correctArrayNums, incorrectArrayNums },
      translate
    } = props;

    const renderNumbers = getRandomSubArrayFromArray(
      [...correctArrayNums, ...incorrectArrayNums],
      6,
      { random: seededRandom(props.question) }
    );

    const testCorrect = renderNumbers.filter(number => correctArrayNums.includes(number));

    // Randomise numbers
    const randomNumbers = shuffle(renderNumbers, { random: seededRandom(props.question) });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectMultiplesOf(number1)}
        testCorrect={testCorrect}
        multiSelect
        items={randomNumbers.map((number: number) => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'amq',
  description: 'amq',
  keywords: ['Multiple'],
  schema: z.object({
    chosenFactor: numberEnum([2, 3, 5, 10]),
    number: z.number().int().min(101).max(999)
  }),
  simpleGenerator: () => {
    const chosenFactor = getRandomFromArray([2, 3, 5, 10] as const);

    const isDivisible = getRandomFromArray([true, false]);

    // Allow a 50-50 chance of number having chosenFactor as a factor.
    // Without these conditions, it would be rare to have chosenFactor as a factor.
    const number = randomIntegerInclusive(101, 999, {
      constraint: x => (isDivisible ? x % chosenFactor === 0 : x % chosenFactor !== 0)
    });

    return { chosenFactor, number };
  },
  Component: props => {
    const {
      question: { chosenFactor, number },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.tinySaysXIsAMultipleOfYIsTinyCorrect(
          number.toLocaleString(),
          chosenFactor.toLocaleString()
        )} ${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.tinySaysXIsAMultipleOfYIsTinyCorrect(
          number.toLocaleString(),
          chosenFactor.toLocaleString()
        )}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={number % chosenFactor === 0 ? ['Yes'] : ['No']}
        numItems={2}
        renderItems={() => [
          {
            value: 'Yes',
            component: (
              <AutoScaleText
                variant="labelLarge"
                containerStyle={{ width: '50%', height: '50%' }}
                group={0}
              >
                {translate.misc.Yes()}
              </AutoScaleText>
            )
          },
          {
            value: 'No',
            component: (
              <AutoScaleText
                variant="labelLarge"
                containerStyle={{ width: '50%', height: '50%' }}
                group={0}
              >
                {translate.misc.No()}
              </AutoScaleText>
            )
          }
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Multiples',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
