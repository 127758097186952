import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { range, rangeAsString } from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgg',
  description: 'bgg',
  keywords: ['Number line', 'Estimate'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(90).multipleOf(10),
    answerIndex: z.number().int().min(1).max(9),
    intervals: numberEnum([10, 2])
  }),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([2, 10] as const);
    const startingNumber = intervals === 2 ? randomIntegerInclusiveStep(0, 90, 10) : 0;
    const answerIndex = intervals === 2 ? 5 : randomIntegerInclusive(1, 9);

    return { startingNumber, answerIndex, intervals };
  },
  Component: props => {
    const {
      question: { startingNumber, answerIndex, intervals },
      translate
    } = props;

    // Create array to pass to Number Line
    const tickValues = range(
      startingNumber,
      intervals === 2 ? startingNumber + 10 : 100,
      intervals === 2 ? 5 : 10
    ).map((number, index) => {
      return intervals === 10 || [0, 2].includes(index) ? number : null;
    });

    const answer =
      intervals === 2 ? startingNumber + answerIndex : startingNumber + answerIndex * 10 - 5;

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[answer.toString()]}
        tickValues={tickValues}
        freeNumberLineAnswer={[answer]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgh',
  description: 'bgh',
  keywords: ['Number line', 'Estimate', 'Midpoint'],
  schema: z.object({
    number: z.number().int().min(5).max(95).step(5)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(5, 95, 10);

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const tickValues = rangeAsString(0, 100, 10, true).map((el, index) =>
      [0, 10].includes(index) ? el : ''
    );
    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragTheArrowToEstimateWhereNumBelongsOnTheNumberLine(
          number
        )}
        pdfTitle={translate.ks1PDFInstructions.drawAnArrowToEstimateWhereNumBelongsOnTheNumberLine(
          number
        )}
        testCorrect={[number - 1, number + 1]}
        min={0}
        max={100}
        sliderStep={1}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.allowMarginOfErrorOfX(1)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bgi',
  description: 'bgi',
  keywords: ['Number line', 'Estimate'],
  schema: z.object({
    number: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 99, { constraint: x => x % 5 !== 0 });

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const [tens, ones] = number
      .toString()
      .split('')
      .map(numAsString => parseInt(numAsString));

    const tickValues = rangeAsString(0, 100, 10, true);

    const testCorrect = (answer: number) => {
      switch (ones) {
        case 1:
        case 2:
        case 3:
          return isInRange(tens * 10 + 1, tens * 10 + 4)(answer);
        case 4:
          return isInRange(tens * 10 + 1, tens * 10 + 6)(answer);
        case 6:
          return isInRange(tens * 10 + 4, tens * 10 + 9)(answer);
        case 7:
        case 8:
        case 9:
          return isInRange(tens * 10 + 6, tens * 10 + 9)(answer);
        default:
          return false;
      }
    };

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragTheArrowToEstimateWhereNumBelongsOnTheNumberLine(
          number
        )}
        pdfTitle={translate.ks1PDFInstructions.drawAnArrowToEstimateWhereNumBelongsOnTheNumberLine(
          number
        )}
        testCorrect={userAnswer => testCorrect(userAnswer)}
        min={0}
        max={100}
        sliderStep={1}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answerToDisplay: number
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateNumbersOnANumberLine',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
