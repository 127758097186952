import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { useMemo } from 'react';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  numbersDoNotExchange,
  numbersDoNotExchangeAt,
  numbersExchange,
  numbersExchangeAt,
  numbersOnlyExchangeAt
} from '../../../../utils/exchanges';
import {
  ScientificNotation,
  digitAtPowerIsNumber,
  powersOfTenPowToWord
} from '../../../../utils/math';
import { ADD, SUB } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import {
  arrayHasNoDuplicates,
  countRange,
  sortNumberArray,
  zipArrays
} from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import deepEqual from 'react-fast-compare';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ahO',
  description: 'ahO',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', '1,000s', 'Base 10'],
  schema: z
    .object({
      number1: z.number().int().min(1001).max(9998),
      number2Factor: z.number().int().min(1).max(9),
      number2PowerOfTen: z.number().int().min(0).max(3),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val =>
        numbersDoNotExchange(val.number1, Math.pow(10, val.number2PowerOfTen) * val.number2Factor),
      'number1 and number2 must not exchange.'
    ),
  simpleGenerator: () => {
    const number2PowerOfTen = randomIntegerInclusive(0, 3);

    const number2Factor = randomIntegerInclusive(1, number2PowerOfTen === 3 ? 8 : 9);

    const number1 = randomIntegerInclusive(1001, 9998, {
      constraint: x => numbersDoNotExchange(x, Math.pow(10, number2PowerOfTen) * number2Factor)
    });

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { number1, number2Factor, number2PowerOfTen, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, number2Factor, number2PowerOfTen, addOrSubtract },
      translate
    } = props;

    const number2 = Math.pow(10, number2PowerOfTen) * number2Factor;

    const number3 = number1 + number2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToHelpCompleteCalculation()}
        testCorrect={[(addOrSubtract === ADD ? number3 : number1).toString()]}
        sentence={`${(addOrSubtract === ADD
          ? number1
          : number3
        ).toLocaleString()} ${addOrSubtract} ${number2.toLocaleString()} = <ans />`}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(addOrSubtract === ADD ? number1 : number3)}
            columnsToShow={[3, 2, 1, 0]}
            dimens={dimens}
            counterVariant={'decimalCounter'}
          />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'ahP',
  description: 'ahP',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', '1,000s'],
  schema: z
    .object({
      number1: z.number().int().min(1111).max(9999).multipleOf(1111),
      number2: z.number().int().min(1).max(9),
      addOrSubtract: z.enum(['add', 'subtract'])
    })
    .refine(
      val =>
        numbersDoNotExchangeAt(
          val.number1,
          (val.addOrSubtract === 'add' ? 1 : -1) * val.number2,
          'ones'
        ),
      'number1 and number2 cannot exchange when added or subtracted'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    const number2 =
      addOrSubtract === 'add' ? randomIntegerInclusive(1, 8) : randomIntegerInclusive(1, 9);

    const number1 = rejectionSample(
      () => randomIntegerInclusive(1, 9) * 1111,
      x => numbersDoNotExchangeAt(x, (addOrSubtract === 'add' ? 1 : -1) * number2, 'ones')
    );

    return { number1, number2, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, number2, addOrSubtract },
      translate
    } = props;

    const number2tens = number2 * 10;
    const number2hundreds = number2 * 100;
    const number2thousands = number2 * 1000;

    const eqA = getBinOpEquation({
      left: number1,
      right: number2,
      sign: addOrSubtract,
      answer: 'result'
    });
    const eqB = getBinOpEquation({
      left: number1,
      right: number2tens,
      sign: addOrSubtract,
      answer: 'result'
    });
    const eqC = getBinOpEquation({
      left: number1,
      right: number2hundreds,
      sign: addOrSubtract,
      answer: 'result'
    });
    const eqD = getBinOpEquation({
      left: number1,
      right: number2thousands,
      sign: addOrSubtract,
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={800}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ahQ',
  description: 'ahQ',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', '1,000s', 'Inverse'],
  schema: z
    .object({
      number1: z.number().int().min(1111).max(9999).multipleOf(1111),
      diff: z.number().int().min(1).max(9),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val => numbersDoNotExchange(val.number1, (val.addOrSubtract === ADD ? 1 : -1) * val.diff),
      'number1 and diff do not exchange when added or subtracted'
    ),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);
    const number1 =
      (addOrSubtract === ADD ? randomIntegerInclusive(1, 8) : randomIntegerInclusive(1, 9)) * 1111;

    const diff = randomIntegerInclusive(1, 9, {
      constraint: x => numbersDoNotExchange((addOrSubtract === ADD ? 1 : -1) * x, number1)
    });

    return { number1, diff, addOrSubtract };
  },
  Component: props => {
    const {
      question: { number1, diff, addOrSubtract },
      translate
    } = props;
    const answerOptions = [diff, diff * 10, diff * 100, diff * 1000];

    const sentences = useMemo(() => {
      const questionsAndAnswers =
        addOrSubtract === ADD
          ? [
              {
                sentence: `${number1.toLocaleString()} ${ADD} <ans /> = ${(
                  number1 + diff
                ).toLocaleString()}`,
                answer: diff
              },
              {
                sentence: `${number1.toLocaleString()} ${ADD} <ans /> = ${(
                  number1 +
                  diff * 10
                ).toLocaleString()}`,
                answer: diff * 10
              },
              {
                sentence: `${number1.toLocaleString()} ${ADD} <ans /> = ${(
                  number1 +
                  diff * 100
                ).toLocaleString()}`,
                answer: diff * 100
              },
              {
                sentence: `${number1.toLocaleString()} ${ADD} <ans /> = ${(
                  number1 +
                  diff * 1000
                ).toLocaleString()}`,
                answer: diff * 1000
              }
            ]
          : [
              {
                sentence: `${number1.toLocaleString()} ${SUB} <ans /> = ${(
                  number1 - diff
                ).toLocaleString()}`,
                answer: diff
              },
              {
                sentence: `${number1.toLocaleString()} ${SUB} <ans /> = ${(
                  number1 -
                  diff * 10
                ).toLocaleString()}`,
                answer: diff * 10
              },
              {
                sentence: `${number1.toLocaleString()} ${SUB} <ans /> = ${(
                  number1 -
                  diff * 100
                ).toLocaleString()}`,
                answer: diff * 100
              },
              {
                sentence: `${number1.toLocaleString()} ${SUB} <ans /> = ${(
                  number1 -
                  diff * 1000
                ).toLocaleString()}`,
                answer: diff * 1000
              }
            ];

      return shuffle(questionsAndAnswers, { random: seededRandom(props.question) });
    }, [addOrSubtract, diff, number1, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteCalculations()}
        actionPanelVariant="endMid"
        pdfLayout="itemsRight"
        pdfTitle={translate.instructions.useCardsCompleteCalculationsEachCardOnlyUsedOnce()}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'ahQ2',
  description: 'ahQ',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', '1,000s', 'Inverse'],
  schema: z
    .object({
      number1: z.number().int().min(1111).max(9999).multipleOf(1111),
      diffs: z.array(z.number().int().min(1).max(9000)).length(4),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val =>
        numbersDoNotExchange(
          val.number1,
          (val.addOrSubtract === ADD ? 1 : -1) * Math.min(...val.diffs)
        ),
      'number1 and diff do not exchange when added or subtracted'
    ),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);
    const number1 =
      (addOrSubtract === ADD ? randomIntegerInclusive(1, 8) : randomIntegerInclusive(1, 9)) * 1111;

    const diff = randomIntegerInclusive(1, 9, {
      constraint: x => numbersDoNotExchange((addOrSubtract === ADD ? 1 : -1) * x, number1)
    });

    const diffs = shuffle([diff, diff * 10, diff * 100, diff * 1000]);

    return { number1, diffs, addOrSubtract };
  },
  Component: props => {
    const {
      question: { number1, diffs, addOrSubtract },
      translate,
      displayMode
    } = props;

    const sortedDiffs = sortNumberArray(diffs);

    const items = sortedDiffs.map(value => {
      return {
        component: (
          <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
            {value}
          </Text>
        ),
        value
      };
    });

    const statements = diffs.map(diff => {
      return {
        lhsComponent: (
          <View style={{ width: 175, alignItems: 'flex-end' }}>
            <Text
              style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            >{`${number1.toLocaleString()} ${addOrSubtract}`}</Text>
          </View>
        ),
        rhsComponent: (
          <View style={{ width: 175, alignItems: 'flex-start' }}>
            <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>{`= ${(addOrSubtract ===
            ADD
              ? number1 + diff
              : number1 - diff
            ).toLocaleString()}`}</Text>
          </View>
        ),
        correctAnswer: diff
      };
    });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculations()}
        items={items}
        itemVariant="square"
        actionPanelVariant="endMid"
        statements={statements}
        mainPanelStyle={{ alignSelf: 'center' }}
        statementStyle={{
          columnGap: displayMode === 'digital' ? 0 : 32,
          justifyContent: displayMode === 'digital' ? undefined : 'center'
        }}
        useRedLinesOnMarkScheme={false}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ahR',
  description: 'ahR',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', '1,000s'],
  schema: z.object({
    number1: z.number().int().min(1001).max(9997),
    numberA2: z.number().int().min(1).max(7),
    powerOfTen: z.number().int().min(0).max(2),
    addOrSubtract: z.enum(['add', 'subtract'])
  }),
  simpleGenerator: () => {
    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    const powerOfTen = randomIntegerInclusive(0, 2);
    const powerAsWord = powersOfTenPowToWord[powerOfTen as 0 | 1 | 2];

    const numberA2 = randomIntegerInclusive(1, 7);

    const numberB2 = numberA2 + 1;

    const numberC2 = numberA2 + 2;

    // Any number that meets the requirement of not creating an exchange for numberA2 or numberB2, but will for numberC2
    const number1 = rejectionSample(
      () => randomIntegerInclusive(1001, 9997),
      x =>
        addOrSubtract === 'add'
          ? numbersDoNotExchangeAt(x, numberB2 * Math.pow(10, powerOfTen), powerAsWord) && // number1 + numberB2 won't exchange
            numbersExchangeAt(x, numberC2 * Math.pow(10, powerOfTen), powerAsWord) && // number1 + numberC2 will exchange
            numbersDoNotExchangeAt(x, numberC2 * Math.pow(10, powerOfTen), 'thousands') && // number1 + numberC2 will not exchange thousands i.e. total less than 10,000
            digitAtPowerIsNumber(x + numberC2 * Math.pow(10, powerOfTen), powerOfTen, [0, 1]) // Last addition's answer must have zero or one in the selected power
          : numbersDoNotExchangeAt(x, -numberB2 * Math.pow(10, powerOfTen), powerAsWord) && // number1 - numberB2 won't exchange
            numbersExchangeAt(x, -numberC2 * Math.pow(10, powerOfTen), powerAsWord) && // number1 - numberC2 will exchange
            digitAtPowerIsNumber(x - numberC2 * Math.pow(10, powerOfTen), powerOfTen, [0, 9]) // Last addition's answer must have zero or nine in the selected power
    );

    return { number1, numberA2, powerOfTen, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, numberA2, powerOfTen, addOrSubtract },
      translate
    } = props;

    const numberB2 = numberA2 + 1;

    const numberC2 = numberA2 + 2;

    const power = Math.pow(10, powerOfTen);

    const numberA2Powered = numberA2 * power;
    const numberB2Powered = numberB2 * power;
    const numberC2Powered = numberC2 * power;

    const eqA = getBinOpEquation({
      left: number1,
      right: numberA2Powered,
      sign: addOrSubtract,
      answer: 'result'
    });
    const eqB = getBinOpEquation({
      left: number1,
      right: numberB2Powered,
      sign: addOrSubtract,
      answer: 'result'
    });
    const eqC = getBinOpEquation({
      left: number1,
      right: numberC2Powered,
      sign: addOrSubtract,
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ahS',
  description: 'ahS',
  keywords: ['Addition', 'Subtraction', '1s', '10s', '100s', '1,000s'],
  schema: z.object({
    number1: z.number().int().min(1001).max(9998),
    number2: z.number().int().min(1).max(8000),
    addOrSubtract: z.enum(['add', 'subtract']),
    answerBox: z.enum(['left', 'right', 'result'])
  }),
  simpleGenerator: () => {
    // Should only use one of the equations from below:
    const equationToUse = getRandomFromArray(['A', 'B', 'C', 'D']);

    const number2 = (() => {
      switch (equationToUse) {
        case 'A':
          return randomIntegerInclusive(1, 9);
        case 'B':
          return randomIntegerInclusiveStep(10, 90, 10);
        case 'C':
          return randomIntegerInclusiveStep(100, 900, 100);
        default:
        case 'D':
          return randomIntegerInclusiveStep(1000, 8000, 1000);
      }
    })();

    const number1 = (() => {
      switch (equationToUse) {
        case 'A':
          return rejectionSample(
            () => randomIntegerInclusive(1001, 9998),
            x => numbersOnlyExchangeAt(x, number2, 'ones')
          );
        case 'B':
          return rejectionSample(
            () => randomIntegerInclusive(1001, 9989),
            x => numbersOnlyExchangeAt(x, number2, 'tens')
          );
        case 'C':
          return rejectionSample(
            () => randomIntegerInclusive(1001, 9899),
            x => numbersOnlyExchangeAt(x, number2, 'hundreds')
          );
        default:
        case 'D':
          return rejectionSample(
            () => randomIntegerInclusive(1001, 8999),
            // If this case is selected, we do not want an exchange at the thousands, as this would enter into the 10,000s:
            x => numbersDoNotExchangeAt(x, number2, 'thousands')
          );
      }
    })();

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    const answerBox = getRandomFromArray(['left', 'right', 'result'] as const);

    return {
      number1,
      number2,
      addOrSubtract,
      answerBox
    };
  },

  Component: props => {
    const {
      question: { number1, number2, addOrSubtract, answerBox },
      translate
    } = props;

    const eqA =
      addOrSubtract === 'add'
        ? getBinOpEquation({ left: number1, right: number2, sign: 'add', answer: answerBox })
        : getBinOpEquation({
            right: number2,
            result: number1,
            sign: 'subtract',
            answer: answerBox
          });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={binOpEquationsToTestCorrect([eqA])[0]}
        sentence={binOpEquationToSentenceString(eqA)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ahT',
  description: 'ahT',
  keywords: ['Addition', 'Subtraction', 'Column', 'Exchange'],
  schema: z.object({
    calcs: z
      .array(
        z
          .object({
            left: z.number().int().min(1).max(9999),
            right: z.number().int().min(1).max(9999),
            /** If this is SUB, then the calculation is actually left+right - right. */
            symbol: z.enum([ADD, SUB])
          })
          .refine(({ left, right }) => left + right <= 9999, 'Result must be <= 9,999')
      )
      .length(6)
      .refine(
        calcs => calcs.some(({ left, right }) => numbersExchange(left, right)),
        'There must be at least one calculation which exchanges.'
      )
      .refine(calcs => arrayHasNoDuplicates(calcs, deepEqual), 'No duplicate calculations allowed')
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        // For this question, the right addends need to be 1, 10 or 100.
        // Ensure some variety by including every option at least once.
        const rightAddendsOptions = [1, 10, 100] as const;
        const rightAddendsChosen = countRange(3).map(() => getRandomFromArray(rightAddendsOptions));
        const rightAddends = shuffle([...rightAddendsOptions, ...rightAddendsChosen]);

        // Have at least two adds and two subtracts, and then two of any
        const addOrSubtractsOptions = [ADD, SUB] as const;
        const addOrSubtractsChosen = countRange(2).map(() =>
          getRandomFromArray(addOrSubtractsOptions)
        );
        const addOrSubtracts = shuffle([
          ...addOrSubtractsOptions,
          ...addOrSubtractsOptions,
          ...addOrSubtractsChosen
        ]);

        // Choose which equations should exchange - should be between 2 and 4
        const doesExchangeArray = shuffle([
          ...[true, true, false, false],
          ...countRange(2).map(() => getRandomFromArray([true, false]))
        ]);

        // Finally pick the left addends
        const calcs = zipArrays(rightAddends, addOrSubtracts, doesExchangeArray).map(
          ([right, symbol, doesExchange]) => ({
            left: randomIntegerInclusive(1000, 6000, {
              constraint: left =>
                left + right <= 9999 && numbersExchange(left, right) === doesExchange
            }),
            right,
            symbol
          })
        );

        return { calcs };
      },
      ({ calcs }) => arrayHasNoDuplicates(calcs, deepEqual)
    ),
  Component: ({ question: { calcs }, translate }) => {
    const calculations = calcs.map(({ left, right, symbol }, i) => ({
      value: i,
      text:
        symbol === ADD
          ? `${left.toLocaleString()} ${ADD} ${right.toLocaleString()}`
          : `${(left + right).toLocaleString()} ${SUB} ${right.toLocaleString()}`,
      exchange: numbersExchange(left, right)
    }));

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectCalcsWillNeedAnExchange()}
        pdfTitle={translate.instructions.circleCalcsWillNeedAnExchange()}
        testCorrect={calculations.filter(it => it.exchange).map(it => it.value)}
        items={calculations.map(({ value, text }) => ({
          value,
          component: text
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtract1s10s100sAnd1000s',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
