import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  range,
  sortNumberArray,
  sumNumberArray,
  zipArrays
} from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import {
  getRandomArrayOfStreetNames,
  streetNamesAsWord,
  streetNamesSchema
} from '../../../../utils/places';
import {
  addDurationTo24hTime,
  displayDigitalTime,
  subtractDurationTo24hTime
} from '../../../../utils/time';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { NumberLineVariableTickWithState } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';
import { ADD } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { colors } from '../../../../theme/colors';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aDu',
  description: 'aDu',
  keywords: ['Timetable', 'Table'],
  schema: z.object({
    startHours: z.array(z.number().int().min(6).max(10)).length(3),
    startMins: z.array(z.number().int().min(0).max(59)).length(3),
    busIntervals: z.array(z.number().int().min(5).max(20)).length(3),
    busStopNames: z.array(streetNamesSchema).length(4),
    ansBusIndex: z.number().int().min(1).max(3),
    ansStopIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const startHours = sortNumberArray(randomUniqueIntegersInclusive(6, 10, 3));
    const startMins = randomUniqueIntegersInclusive(0, 59, 3);
    const busIntervals = countRange(3).map(() => randomIntegerInclusive(5, 20));
    const busStopNames = getRandomArrayOfStreetNames(4);
    const ansBusIndex = randomIntegerInclusive(1, 3);
    const ansStopIndex = randomIntegerInclusive(0, 3);

    return { startHours, startMins, busIntervals, busStopNames, ansBusIndex, ansStopIndex };
  },
  Component: props => {
    const {
      question: { startHours, startMins, busIntervals, busStopNames, ansBusIndex, ansStopIndex },
      translate,
      displayMode
    } = props;

    const buses = [
      '',
      translate.misc['Bus A'](),
      translate.misc['Bus B'](),
      translate.misc['Bus C']()
    ];
    const busStopsAsWords = busStopNames.map(street => streetNamesAsWord(street, translate));

    const intervalsFromStart = [0, ...busIntervals].map((_, i) =>
      busIntervals.slice(0, i).reduce((acc, prev) => acc + prev, 0)
    );

    const startTimes = zipArrays(startHours, startMins);
    const busTimes = startTimes.map(([hours, mins]) => [
      ...intervalsFromStart.map(interval =>
        displayDigitalTime(...addDurationTo24hTime(hours, mins, interval))
      )
    ]);

    const dataA = zipArrays(busStopsAsWords, ...busTimes);

    const answer = dataA[ansStopIndex][ansBusIndex].split(':');

    return (
      <QF1ContentAndSentence
        title={
          ansStopIndex === 0
            ? translate.instructions.hereIsABusTimetableWhatTimeDoesXLeaveY(
                buses[ansBusIndex],
                busStopsAsWords[ansStopIndex]
              )
            : translate.instructions.hereIsABusTimetableWhatTimeDoesXArriveAtY(
                buses[ansBusIndex],
                busStopsAsWords[ansStopIndex]
              )
        }
        testCorrect={answer.map(String)}
        sentence={`<ans/>:<ans/>`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => (
          <CustomizableTable
            textStyle={{ fontSize: displayMode === 'digital' ? 24 : 50 }}
            cellHeaders={buses}
            tableData={dataA}
            tableStyle={{ width: dimens.width }}
            columnFlexValues={[3, 1, 1, 1]}
          />
        )}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: answer,
          answerText: translate.markScheme.acceptEquivalentTime()
        }}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aDv',
  description: 'aDv',
  keywords: ['Timetable', 'Table'],
  schema: z.object({
    startHours: z.array(z.number().int().min(6).max(10)).length(3),
    startMins: z.array(z.number().int().min(0).max(59)).length(3),
    busIntervals: z.array(z.number().int().min(5).max(20)).length(3),
    busStopNames: z.array(streetNamesSchema).length(4),
    ansBusIndex: z.number().int().min(1).max(3),
    ansStopIndex: z.number().int().min(1).max(3),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const startHours = sortNumberArray(randomUniqueIntegersInclusive(6, 10, 3));
    const startMins = randomUniqueIntegersInclusive(0, 59, 3);
    const busIntervals = countRange(3).map(() => randomIntegerInclusive(5, 20));
    const busStopNames = getRandomArrayOfStreetNames(4);
    const ansBusIndex = randomIntegerInclusive(1, 3);
    const ansStopIndex = randomIntegerInclusive(1, 3);
    const name = getRandomName();

    return { startHours, startMins, busIntervals, busStopNames, ansBusIndex, ansStopIndex, name };
  },
  Component: props => {
    const {
      question: {
        startHours,
        startMins,
        busIntervals,
        busStopNames,
        ansBusIndex,
        ansStopIndex,
        name
      },
      translate,
      displayMode
    } = props;

    const buses = [translate.misc['Bus A'](), translate.misc['Bus B'](), translate.misc['Bus C']()];

    const busStopsAsWords = busStopNames.map(street => streetNamesAsWord(street, translate));

    const intervalsFromStart = [0, ...busIntervals].map((_, i) =>
      busIntervals.slice(0, i).reduce((acc, prev) => acc + prev, 0)
    );

    const startTimes = zipArrays(startHours, startMins);
    const busTimes = startTimes.map(([hours, mins]) => [
      ...intervalsFromStart.map(interval =>
        displayDigitalTime(...addDurationTo24hTime(hours, mins, interval))
      )
    ]);

    const dataA = zipArrays(busStopsAsWords, ...busTimes);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.xNeedsToGetToYByZSelectTheBusXTakesFromY(
          name,
          busStopsAsWords[ansStopIndex],
          dataA[ansStopIndex][ansBusIndex]
        )}
        pdfTitle={translate.instructions.xNeedsToGetToYByZCircleTheBusXTakesFromY(
          name,
          busStopsAsWords[ansStopIndex],
          dataA[ansStopIndex][ansBusIndex]
        )}
        mainPanelContainer={{ justifyContent: 'space-between' }}
        Content={({ dimens }) => (
          <CustomizableTable
            textStyle={{ fontSize: displayMode === 'digital' ? 24 : 50 }}
            cellHeaders={['', ...buses]}
            tableData={dataA}
            tableStyle={{ width: dimens.width }}
            columnFlexValues={[3, 1, 1, 1]}
          />
        )}
        renderItems={buses.map(bus => ({
          value: bus,
          component: <Text variant="WRN400">{bus}</Text>
        }))}
        questionHeight={900}
        numItems={2}
        testCorrect={[buses[ansBusIndex - 1]]}
        itemLayout={'row'}
        itemStyle={{ width: 250 }}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aDw',
  description: 'aDw',
  keywords: ['Timetable', 'Time'],
  schema: z.object({
    startTime: z.string(),
    endTime: z.string(),
    timings: z.array(z.array(z.number())),
    answer: z.string(),
    incorrectAnswerA: z.string(),
    incorrectAnswerB: z.string(),
    answerIndex: numberEnum([0, 1, 2, 3, 4]),
    showLengths: z.array(numberEnum([30, 45, 60])).length(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { showLengths } = rejectionSample(
      () => {
        const showLengths = countRange(5).map(() => getRandomFromArray([30, 45, 60] as const));

        return { showLengths };
      },
      ({ showLengths }) => sumNumberArray(showLengths) === 210
    );

    const { startTime, endTime, timings, answer, incorrectAnswerA, incorrectAnswerB, answerIndex } =
      rejectionSample(
        () => {
          const answerIndex = getRandomFromArray([0, 1, 2, 3, 4] as const);

          const startingTimeHours = randomIntegerInclusive(6, 18);
          const startingTimeMins = getRandomFromArray([0, 30] as const);

          const incorrectAnswerADifference = getRandomFromArray([15, 30, 45] as const);
          const isIncorrectAnswerAPlusTime = getRandomBoolean();

          const incorrectAnswerBDifference = getRandomFromArray([1, 2] as const);
          const isIncorrectAnswerBPlusTime = getRandomBoolean();

          const startTime = displayDigitalTime(startingTimeHours, startingTimeMins);
          const endTime = displayDigitalTime(
            ...addDurationTo24hTime(startingTimeHours + 3, startingTimeMins, 30)
          );

          const showTwoStartTime = addDurationTo24hTime(
            startingTimeHours,
            startingTimeMins,
            showLengths[0]
          );
          const showThreeStartTime = addDurationTo24hTime(
            showTwoStartTime[0],
            showTwoStartTime[1],
            showLengths[1]
          );
          const showFourStartTime = addDurationTo24hTime(
            showThreeStartTime[0],
            showThreeStartTime[1],
            showLengths[2]
          );
          const showFiveStartTime = addDurationTo24hTime(
            showFourStartTime[0],
            showFourStartTime[1],
            showLengths[3]
          );

          const timings = [
            [startingTimeHours, startingTimeMins],
            showTwoStartTime,
            showThreeStartTime,
            showFourStartTime,
            showFiveStartTime
          ];

          const [answerHours, answerMins] = timings[answerIndex];

          const answer = displayDigitalTime(answerHours, answerMins);

          const incorrectAnswerA = isIncorrectAnswerAPlusTime
            ? displayDigitalTime(
                ...addDurationTo24hTime(answerHours, answerMins, incorrectAnswerADifference)
              )
            : displayDigitalTime(
                ...subtractDurationTo24hTime(answerHours, answerMins, incorrectAnswerADifference)
              );

          const incorrectAnswerB = isIncorrectAnswerBPlusTime
            ? displayDigitalTime(answerHours + incorrectAnswerBDifference, answerMins)
            : displayDigitalTime(answerHours - incorrectAnswerBDifference, answerMins);

          return {
            startTime,
            endTime,
            timings,
            answer,
            incorrectAnswerA,
            incorrectAnswerB,
            answerIndex
          };
        },
        ({ answer, endTime, incorrectAnswerA, incorrectAnswerB }) =>
          arrayHasNoDuplicates([answer, endTime, incorrectAnswerA, incorrectAnswerB])
      );

    return {
      startTime,
      endTime,
      timings,
      answer,
      incorrectAnswerA,
      incorrectAnswerB,
      answerIndex,
      showLengths
    };
  },

  Component: props => {
    const {
      question: {
        startTime,
        endTime,
        timings,
        answer,
        incorrectAnswerA,
        incorrectAnswerB,
        answerIndex,
        showLengths
      },
      translate,
      displayMode
    } = props;

    const shows = shuffle(
      [
        translate.tvShows.Buzz(),
        translate.tvShows.CatchIt(),
        translate.tvShows.CodeBlue(),
        translate.tvShows.Starbite(),
        translate.tvShows.Zoos()
      ],
      { random: seededRandom(props.question) }
    );

    const startingTimesAndShow = [
      { times: timings[0], showName: shows[0], value: showLengths[0], previousShowLength: 0 },
      {
        times: timings[1],
        showName: shows[1],
        value: showLengths[1],
        previousShowLength: showLengths[0]
      },
      {
        times: timings[2],
        showName: shows[2],
        value: showLengths[2],
        previousShowLength: showLengths[1]
      },
      {
        times: timings[3],
        showName: shows[3],
        value: showLengths[3],
        previousShowLength: showLengths[2]
      },
      {
        times: timings[4],
        showName: shows[4],
        value: showLengths[4],
        previousShowLength: showLengths[3]
      }
    ];

    const items = shuffle([answer, endTime, incorrectAnswerA, incorrectAnswerB], {
      random: seededRandom(props.question)
    });

    const numbers = startingTimesAndShow.map(el => el.value);
    const intervals = range(0, 13).map(() => 15);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsAnExtractFromATVGuideWhatTimeDoesXStart(
          startingTimesAndShow[answerIndex].showName
        )}
        pdfTitle={translate.instructions.hereIsAnExtractFromATVGuideWhatTimeDoesXStartCircleAnswer(
          startingTimesAndShow[answerIndex].showName
        )}
        testCorrect={[answer]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ marginTop: 10 }}>
            <View style={{ flexDirection: 'row' }}>
              {startingTimesAndShow.map(({ times, previousShowLength }, index) => {
                if (index === 0) {
                  return (
                    <TextStructure
                      key={startTime}
                      textStyle={{ fontSize: displayMode === 'digital' ? 24 : 40 }}
                      style={{ left: displayMode === 'digital' ? -30 : -50 }}
                      sentence={startTime}
                    />
                  );
                } else if (index === 1) {
                  const margin = (() => {
                    switch (displayMode) {
                      case 'digital':
                        return showLengths[0] === 30 ? 49 : showLengths[0] === 45 ? 119 : 189;
                      default:
                        return showLengths[0] === 30 ? 120 : showLengths[0] === 45 ? 255 : 390;
                    }
                  })();
                  return (
                    <TextStructure
                      key={displayDigitalTime(times[0], times[1])}
                      textStyle={{ fontSize: displayMode === 'digital' ? 24 : 40 }}
                      style={{
                        marginLeft: margin
                      }}
                      sentence={displayDigitalTime(times[0], times[1])}
                    />
                  );
                } else {
                  const margin = (() => {
                    switch (displayMode) {
                      case 'digital':
                        return previousShowLength === 30
                          ? 78
                          : previousShowLength === 45
                          ? 148
                          : 218;
                      default:
                        return previousShowLength === 30
                          ? 170
                          : previousShowLength === 45
                          ? 305
                          : 440;
                    }
                  })();
                  return (
                    <TextStructure
                      key={displayDigitalTime(times[0], times[1])}
                      textStyle={{ fontSize: displayMode === 'digital' ? 24 : 40 }}
                      style={{
                        marginLeft: margin
                      }}
                      sentence={displayDigitalTime(times[0], times[1])}
                    />
                  );
                }
              })}
            </View>
            <BarModel
              numbers={[intervals, numbers]}
              strings={[intervals.map(() => ''), startingTimesAndShow.map(el => el.showName)]}
              total={210}
              dimens={{ height: 120, width: dimens.width }}
              cellColors={[
                intervals.map(() => colors.pacificBlue),
                startingTimesAndShow.map(() => colors.white)
              ]}
            />
          </View>
        )}
        renderItems={items.map(string => ({
          value: string,
          component: <Text variant="WRN700">{string.toLocaleString()}</Text>
        }))}
        questionHeight={1000}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aDw2',
  description: 'aDw',
  keywords: ['Timetable', 'Time'],
  schema: z.object({
    endTime: z.string(),
    timings: z.array(z.array(z.number())),
    answer: z.string(),
    incorrectAnswerA: z.string(),
    incorrectAnswerB: z.string(),
    answerIndex: z.number().int().min(0).max(4),
    showLengths: z.array(numberEnum([30, 45, 60])).length(5),
    isStart: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { showLengths } = rejectionSample(
      () => {
        const showLengths = countRange(5).map(() => getRandomFromArray([30, 45, 60] as const));

        return { showLengths };
      },
      ({ showLengths }) => sumNumberArray(showLengths) === 210
    );

    const { endTime, timings, answer, incorrectAnswerA, incorrectAnswerB, answerIndex, isStart } =
      rejectionSample(
        () => {
          const isStart = getRandomBoolean();
          const answerIndex = randomIntegerInclusive(0, isStart ? 4 : 3);

          const startingTimeHours = randomIntegerInclusive(6, 18);
          const startingTimeMins = getRandomFromArray([0, 30] as const);

          const incorrectAnswerADifference = getRandomFromArray([15, 30, 45] as const);
          const isIncorrectAnswerAPlusTime = getRandomBoolean();

          const incorrectAnswerBDifference = getRandomFromArray([1, 2] as const);
          const isIncorrectAnswerBPlusTime = getRandomBoolean();

          const endTime = displayDigitalTime(
            ...addDurationTo24hTime(startingTimeHours + 3, startingTimeMins, 30)
          );

          const showTwoStartTime = addDurationTo24hTime(
            startingTimeHours,
            startingTimeMins,
            showLengths[0]
          );
          const showThreeStartTime = addDurationTo24hTime(
            showTwoStartTime[0],
            showTwoStartTime[1],
            showLengths[1]
          );
          const showFourStartTime = addDurationTo24hTime(
            showThreeStartTime[0],
            showThreeStartTime[1],
            showLengths[2]
          );
          const showFiveStartTime = addDurationTo24hTime(
            showFourStartTime[0],
            showFourStartTime[1],
            showLengths[3]
          );

          const timings = [
            [startingTimeHours, startingTimeMins],
            showTwoStartTime,
            showThreeStartTime,
            showFourStartTime,
            showFiveStartTime
          ];

          const [answerHours, answerMins] = timings[isStart ? answerIndex : answerIndex + 1];

          const answer = displayDigitalTime(answerHours, answerMins);

          const incorrectAnswerA = isIncorrectAnswerAPlusTime
            ? displayDigitalTime(
                ...addDurationTo24hTime(answerHours, answerMins, incorrectAnswerADifference)
              )
            : displayDigitalTime(
                ...subtractDurationTo24hTime(answerHours, answerMins, incorrectAnswerADifference)
              );

          const incorrectAnswerB = isIncorrectAnswerBPlusTime
            ? displayDigitalTime(answerHours + incorrectAnswerBDifference, answerMins)
            : displayDigitalTime(answerHours - incorrectAnswerBDifference, answerMins);

          return {
            endTime,
            timings,
            answer,
            incorrectAnswerA,
            incorrectAnswerB,
            answerIndex,
            isStart
          };
        },
        ({ answer, endTime, incorrectAnswerA, incorrectAnswerB }) =>
          arrayHasNoDuplicates([answer, endTime, incorrectAnswerA, incorrectAnswerB])
      );

    return {
      endTime,
      timings,
      answer,
      incorrectAnswerA,
      incorrectAnswerB,
      answerIndex,
      showLengths,
      isStart
    };
  },

  Component: props => {
    const {
      question: {
        endTime,
        timings,
        answer,
        incorrectAnswerA,
        incorrectAnswerB,
        answerIndex,
        showLengths,
        isStart
      },
      translate,
      displayMode
    } = props;

    const shows = shuffle(
      [
        translate.tvShows.Buzz(),
        translate.tvShows.CatchIt(),
        translate.tvShows.CodeBlue(),
        translate.tvShows.Starbite(),
        translate.tvShows.Zoos()
      ],
      { random: seededRandom(props.question) }
    );

    const startingTimesAndShow = [
      { times: timings[0], showName: shows[0], value: showLengths[0], previousShowLength: 0 },
      {
        times: timings[1],
        showName: shows[1],
        value: showLengths[1],
        previousShowLength: showLengths[0]
      },
      {
        times: timings[2],
        showName: shows[2],
        value: showLengths[2],
        previousShowLength: showLengths[1]
      },
      {
        times: timings[3],
        showName: shows[3],
        value: showLengths[3],
        previousShowLength: showLengths[2]
      },
      {
        times: timings[4],
        showName: shows[4],
        value: showLengths[4],
        previousShowLength: showLengths[3]
      }
    ];

    const items = shuffle([answer, endTime, incorrectAnswerA, incorrectAnswerB], {
      random: seededRandom(props.question)
    });

    const numbers = startingTimesAndShow.map(el => el.value);
    const intervals = range(0, 13).map(() => 15);
    const firstHourIndex = timings[0][1] === 30 ? 2 : 0;
    const firstHour = timings[0][1] === 30 ? timings[0][0] + 1 : timings[0][0];
    const labelsPositions = range(firstHourIndex, 13, 3);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          isStart
            ? translate.instructions.hereIsAnExtractFromATVGuideWhatTimeDoesXStart(
                startingTimesAndShow[answerIndex].showName
              )
            : translate.instructions.hereIsAnExtractFromATVGuideWhatTimeDoesXFinish(
                startingTimesAndShow[answerIndex].showName
              )
        }
        pdfTitle={
          isStart
            ? translate.instructions.hereIsAnExtractFromATVGuideWhatTimeDoesXStartCircleAnswer(
                startingTimesAndShow[answerIndex].showName
              )
            : translate.instructions.hereIsAnExtractFromATVGuideWhatTimeDoesXFinishCircleAnswer(
                startingTimesAndShow[answerIndex].showName
              )
        }
        testCorrect={[answer]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ marginTop: 10 }}>
            <View style={{ bottom: -2 }}>
              <View style={{ flexDirection: 'row' }}>
                {intervals.map((_val, index) => {
                  if (labelsPositions.includes(index)) {
                    const margin = (dimens.width / 14) * index;
                    const hourOffset = labelsPositions.indexOf(index);
                    return (
                      <TextStructure
                        key={index}
                        textStyle={{ fontSize: displayMode === 'digital' ? 24 : 40 }}
                        style={{ left: margin + (displayMode === 'digital' ? -35 : -50) }}
                        sentence={displayDigitalTime(firstHour + hourOffset)}
                      />
                    );
                  }
                })}
              </View>
              <BarModel
                numbers={[intervals]}
                strings={[intervals.map(() => '')]}
                total={210}
                dimens={{ height: displayMode === 'digital' ? 20 : 40, width: dimens.width }}
                cellColors={[intervals.map(() => colors.pacificBlue)]}
              />
            </View>
            <BarModel
              numbers={[numbers]}
              strings={[startingTimesAndShow.map(el => el.showName)]}
              total={210}
              dimens={{ height: displayMode === 'digital' ? 60 : 120, width: dimens.width }}
              cellColors={[startingTimesAndShow.map(() => colors.white)]}
            />
          </View>
        )}
        renderItems={items.map(string => ({
          value: string,
          component: <Text variant="WRN700">{string.toLocaleString()}</Text>
        }))}
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aDx',
  description: 'aDx',
  keywords: ['Timetable', 'Time'],
  schema: z.object({
    startTime: z.string(),
    timings: z.array(z.array(z.number())),
    answerIndex: numberEnum([0, 1, 2, 3, 4]),
    showLengths: z.array(numberEnum([30, 45, 60])).length(5)
  }),
  simpleGenerator: () => {
    const { showLengths } = rejectionSample(
      () => {
        const showLengths = countRange(5).map(() => getRandomFromArray([30, 45, 60] as const));

        return { showLengths };
      },
      ({ showLengths }) => sumNumberArray(showLengths) === 210
    );

    const answerIndex = getRandomFromArray([0, 1, 2, 3, 4] as const);

    const startingTimeHours = randomIntegerInclusive(6, 18);
    const startingTimeMins = getRandomFromArray([0, 30] as const);

    const startTime = displayDigitalTime(startingTimeHours, startingTimeMins);

    const showTwoStartTime = addDurationTo24hTime(
      startingTimeHours,
      startingTimeMins,
      showLengths[0]
    );
    const showThreeStartTime = addDurationTo24hTime(
      showTwoStartTime[0],
      showTwoStartTime[1],
      showLengths[1]
    );
    const showFourStartTime = addDurationTo24hTime(
      showThreeStartTime[0],
      showThreeStartTime[1],
      showLengths[2]
    );
    const showFiveStartTime = addDurationTo24hTime(
      showFourStartTime[0],
      showFourStartTime[1],
      showLengths[3]
    );

    const timings = [
      [startingTimeHours, startingTimeMins],
      showTwoStartTime,
      showThreeStartTime,
      showFourStartTime,
      showFiveStartTime
    ];

    const [answerHours, answerMins] = timings[answerIndex];

    const answer = displayDigitalTime(answerHours, answerMins);

    return {
      startTime,
      timings,
      answer,
      answerIndex,
      showLengths
    };
  },

  Component: props => {
    const {
      question: { timings, answerIndex, showLengths },
      translate,
      displayMode
    } = props;

    const shows = shuffle(
      [
        translate.tvShows.Buzz(),
        translate.tvShows.CatchIt(),
        translate.tvShows.CodeBlue(),
        translate.tvShows.Starbite(),
        translate.tvShows.Zoos()
      ],
      { random: seededRandom(props.question) }
    );

    const startingTimesAndShow = [
      { times: timings[0], showName: shows[0], value: showLengths[0], previousShowLength: 0 },
      {
        times: timings[1],
        showName: shows[1],
        value: showLengths[1],
        previousShowLength: showLengths[0]
      },
      {
        times: timings[2],
        showName: shows[2],
        value: showLengths[2],
        previousShowLength: showLengths[1]
      },
      {
        times: timings[3],
        showName: shows[3],
        value: showLengths[3],
        previousShowLength: showLengths[2]
      },
      {
        times: timings[4],
        showName: shows[4],
        value: showLengths[4],
        previousShowLength: showLengths[3]
      }
    ];

    const items = shuffle(
      [
        { string: translate.time.numMinutes(15), value: 15 },
        { string: translate.time.numMinutes(30), value: 30 },
        { string: translate.time.numMinutes(45), value: 45 },
        { string: translate.time.numHours(1), value: 60 }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const numbers = startingTimesAndShow.map(el => el.value);
    const intervals = range(0, 13).map(() => 15);
    const firstHourIndex = timings[0][1] === 30 ? 2 : 0;
    const firstHour = timings[0][1] === 30 ? timings[0][0] + 1 : timings[0][0];
    const labelsPositions = range(firstHourIndex, 13, 3);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.hereIsAnExtractFromATVGuideHowLongIsXOnFor(
          startingTimesAndShow[answerIndex].showName
        )}
        pdfTitle={translate.instructions.hereIsAnExtractFromATVGuideHowLongIsXOnForCircleAnswer(
          startingTimesAndShow[answerIndex].showName
        )}
        testCorrect={[startingTimesAndShow[answerIndex].value]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ marginTop: 10 }}>
            <View style={{ bottom: -2 }}>
              <View style={{ flexDirection: 'row' }}>
                {intervals.map((_val, index) => {
                  if (labelsPositions.includes(index)) {
                    const margin = (dimens.width / 14) * index;
                    const hourOffset = labelsPositions.indexOf(index);
                    return (
                      <TextStructure
                        key={index}
                        textStyle={{ fontSize: displayMode === 'digital' ? 24 : 40 }}
                        style={{ left: margin + (displayMode === 'digital' ? -35 : -50) }}
                        sentence={displayDigitalTime(firstHour + hourOffset)}
                      />
                    );
                  }
                })}
              </View>
              <BarModel
                numbers={[intervals]}
                strings={[intervals.map(() => '')]}
                total={210}
                dimens={{ height: displayMode === 'digital' ? 20 : 40, width: dimens.width }}
                cellColors={[intervals.map(() => colors.pacificBlue)]}
              />
            </View>
            <BarModel
              numbers={[numbers]}
              strings={[startingTimesAndShow.map(el => el.showName)]}
              total={210}
              dimens={{ height: displayMode === 'digital' ? 60 : 120, width: dimens.width }}
              cellColors={[startingTimesAndShow.map(() => colors.white)]}
            />
          </View>
        )}
        renderItems={items.map(({ string, value }) => ({
          value: value,
          component: <Text variant="WRN700">{string.toLocaleString()}</Text>
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aDy',
  description: 'aDy',
  keywords: ['Number line', 'Time', 'Duration', 'Difference between', 'Jumps', 'Addition'],
  schema: z.object({
    middleHour: z.number().int().min(6).max(11),
    jump1: z.number().int().min(1).max(59),
    jump2: z.number().int().min(1).max(59)
  }),
  simpleGenerator: () => {
    const middleHour = randomIntegerInclusive(6, 11);
    const jump1 = randomIntegerInclusive(1, 59);
    const jump2 = randomIntegerInclusive(1, 59);

    return { middleHour, jump1, jump2 };
  },
  Component: props => {
    const {
      question: { middleHour, jump1, jump2 },
      translate,
      displayMode
    } = props;

    const middleHrInMins = middleHour * 60;
    const totalJumpTime = jump1 + jump2;

    const tickValues = [middleHrInMins - jump1, middleHrInMins, middleHrInMins + jump2].map(
      mins => ({
        position: mins,
        label: displayDigitalTime(...addDurationTo24hTime(0, 0, mins))
      })
    );

    // If the ratios are too big/small, move the middle position so the labels don't overlap
    if (jump1 / totalJumpTime > 0.9) {
      tickValues[1].position = tickValues[0].position + 0.8 * totalJumpTime;
    }
    if (jump1 / totalJumpTime < 0.1) {
      tickValues[1].position = tickValues[0].position + 0.2 * totalJumpTime;
    }

    const jumpArrowArray = [
      {
        start: tickValues[0].position,
        end: tickValues[1].position,
        label: `${ADD} ${translate.answerSentences.ansMins()}`
      },
      {
        start: tickValues[1].position,
        end: tickValues[2].position,
        label: `${ADD} ${translate.answerSentences.ansMins()}`
      }
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeNumberLineToWorkOutHowLongItIsBetweenXAndY(
          tickValues[0].label,
          tickValues[2].label
        )}
        sentence={translate.answerSentences.fromTimeToTimeIsAnsMins(
          tickValues[0].label,
          tickValues[2].label
        )}
        testCorrect={[totalJumpTime.toString()]}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ marginTop: 100 }}>
            <NumberLineVariableTickWithState
              id="numberline"
              dimens={dimens}
              tickValues={tickValues}
              start={middleHrInMins - jump1}
              end={middleHrInMins + jump2}
              jumpArrowArray={jumpArrowArray}
              ignoreMinSpacing
              testCorrect={userAnswer =>
                arraysHaveSameContents(userAnswer, [jump1.toString(), jump2.toString()])
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [jump1.toLocaleString(), jump2.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aDz',
  description: 'aDz',
  keywords: ['Timetable', 'Table'],
  schema: z.object({
    startHours: z.array(z.number().int().min(6).max(10)).length(3),
    startMins: z.array(z.number().int().min(0).max(59)).length(3),
    busIntervals: z.array(z.number().int().min(5).max(20)).length(3),
    busStopNames: z.array(streetNamesSchema).length(4),
    ansBusIndex: z.number().int().min(1).max(3),
    ansStopindexes: z.array(z.number().int().min(0).max(3)).length(2)
  }),
  simpleGenerator: () => {
    const startHours = sortNumberArray(randomUniqueIntegersInclusive(6, 10, 3));
    const startMins = randomUniqueIntegersInclusive(0, 59, 3);
    const busIntervals = countRange(3).map(() => randomIntegerInclusive(5, 20));
    const busStopNames = getRandomArrayOfStreetNames(4);
    const ansBusIndex = randomIntegerInclusive(1, 3);
    const ansStopindexes = sortNumberArray(randomUniqueIntegersInclusive(0, 3, 2));

    return { startHours, startMins, busIntervals, busStopNames, ansBusIndex, ansStopindexes };
  },
  Component: props => {
    const {
      question: { startHours, startMins, busIntervals, busStopNames, ansBusIndex, ansStopindexes },
      translate
    } = props;
    const buses = [
      '',
      translate.misc['Bus A'](),
      translate.misc['Bus B'](),
      translate.misc['Bus C']()
    ];

    const busStopsAsWords = busStopNames.map(street => streetNamesAsWord(street, translate));

    const intervalsFromStart = [0, ...busIntervals].map((_, i) =>
      busIntervals.slice(0, i).reduce((acc, prev) => acc + prev, 0)
    );

    const startTimes = zipArrays(startHours, startMins);
    const busTimes = startTimes.map(([hours, mins]) => [
      ...intervalsFromStart.map(interval =>
        displayDigitalTime(...addDurationTo24hTime(hours, mins, interval))
      )
    ]);

    const dataA = zipArrays(busStopsAsWords, ...busTimes);

    const answer = intervalsFromStart[ansStopindexes[1]] - intervalsFromStart[ansStopindexes[0]];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howLongDoesItTakeXtoGetFromStopYToStopZ(
          buses[ansBusIndex],
          busStopsAsWords[ansStopindexes[0]],
          busStopsAsWords[ansStopindexes[1]]
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.ansMinutes()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <CustomizableTable
            textStyle={{ fontSize: 24 }}
            cellHeaders={buses}
            tableData={dataA}
            tableStyle={{ width: dimens.width }}
            columnFlexValues={[3, 1, 1, 1]}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateWithTimetables',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
