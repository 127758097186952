import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { isEqual } from '../../../utils/matchers';
import { useContext } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import {
  Thermometer,
  ThermometerWithState,
  ThermometerProps
} from '../representations/Thermometer';

import { View } from 'react-native';

type Props = TitleStyleProps &
  ThermometerProps & {
    title: string;
    pdfTitle?: string;
    testCorrect: number | ((userAnswer: number) => boolean);
    /** Optional custom mark scheme answer */
    customMarkSchemeAnswer?: { answerToDisplay?: number; answerText?: string };
    questionHeight?: number;
    /** Optional snap arrow to nearest degree on themermometer. Defaults to step */
    snapToNearest?: number;
    /** Optional. Default starting temperature, otherwise the temperature starts below the first tick */
    defaultTemperature?: number;
    /** Optional. Shows an arrow as the answer on the markscheme. Defaults to false */
    showArrowMarkscheme?: boolean;
  };

export default function QF66InteractiveThermometer({
  title,
  pdfTitle,
  testCorrect,
  customMarkSchemeAnswer,
  questionHeight,
  topScale,
  bottomScale,
  snapToNearest,
  step = 1,
  defaultTemperature,
  showArrowMarkscheme = false,
  ...props
}: Omit<Props, 'dimens' | 'temperature'>) {
  const displayMode = useContext(DisplayMode);
  if (displayMode === 'pdf') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <MeasureView style={{ alignItems: 'center' }}>
            {dimens => (
              <>
                <Thermometer
                  dimens={dimens}
                  topScale={topScale}
                  bottomScale={bottomScale}
                  step={step}
                  temperature={defaultTemperature ?? bottomScale - 2 * step}
                />
              </>
            )}
          </MeasureView>
        }
        {...props}
      />
    );
  }
  if (displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <MeasureView style={{ alignItems: 'center' }}>
              {dimens => (
                <Thermometer
                  dimens={dimens}
                  topScale={topScale}
                  bottomScale={bottomScale}
                  step={step}
                  temperature={typeof testCorrect === 'number' ? testCorrect : bottomScale}
                  // isInteractive will display the arrow
                  isInteractive={showArrowMarkscheme}
                />
              )}
            </MeasureView>
            {customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </View>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="end"
      mainPanelContents={
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <MeasureView>
            {dimens => (
              <ThermometerWithState
                id="draggableTempInThermometer"
                testCorrect={typeof testCorrect === 'function' ? testCorrect : isEqual(testCorrect)}
                dimens={dimens}
                defaultState={defaultTemperature}
                topScale={topScale}
                bottomScale={bottomScale}
                step={step}
                snapToNearest={snapToNearest === undefined ? step : snapToNearest}
                isInteractive={true}
              />
            )}
          </MeasureView>
        </View>
      }
      {...props}
    />
  );
}
