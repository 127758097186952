import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { ADD, SUB } from '../../../../constants';
import { Kilometres, Miles, convertUnitsSuffix } from '../../../../utils/unitConversion';
import { useMemo } from 'react';
import { filledArray, range } from '../../../../utils/collections';
import { compareFloats, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { barModelColors } from '../../../../theme/colors';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF17aCompleteDoubleNumberLineDraggable from '../../../../components/question/questionFormats/QF17aCompleteDoubleNumberLineDraggable';
import { all, create, number } from 'mathjs';
import { parseToSUB } from '../../../../utils/parse';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aoi',
  description: 'aoi',
  keywords: ['Length', 'Miles', 'Kilometres', 'Convert'],
  schema: z.object({
    number2: z.number().int().min(2).max(5),
    number4: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 5);

    const number4 = randomIntegerInclusive(2, 8);

    return { number2, number4 };
  },
  Component: props => {
    const {
      question: { number2, number4 },
      translate,
      displayMode
    } = props;

    const number1 = 1;

    const number3 = number2 - 1;

    // Bar Models are static
    const mileNumbers = [filledArray(1, 5)];
    const mileStrings = [filledArray(`${translate.units.numberOfMiles(1)}`, 5)];

    const kmNumbers = [filledArray(1, 8)];
    const kmStrings = [filledArray(`${translate.units.numberOfKm(1)}`, 8)];

    // Select unique colours
    const [barModelColor1, barModelColor2] = getRandomSubArrayFromArray(
      Object.values(barModelColors),
      2,
      { random: seededRandom(props.question) }
    );

    const shuffledStatements = shuffle(
      [
        {
          sentence: translate.answerSentences.xMilesLongerThanYKm({
            numOfMiles: number1,
            numOfKms: number1
          }),
          isCorrect: true
        },
        {
          sentence: translate.answerSentences.xMilesApproxEqualToYKm({
            numOfMiles: 5,
            numOfKms: 8
          }),
          isCorrect: true
        },
        {
          sentence: translate.answerSentences.xMilesLongerThanYKm({
            numOfMiles: number2,
            numOfKms: number3
          }),
          isCorrect: true
        },
        {
          sentence: translate.answerSentences.xKmLongerThanYMiles({
            numOfKms: number4,
            numOfMiles: number4
          }),
          isCorrect: false
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.useBarModelsToSelectCorrectStatements()}
        pdfTitle={translate.instructions.useBarModelsToCircleCorrectStatements()}
        testCorrect={shuffledStatements
          .filter(statement => statement.isCorrect)
          .map(statement => statement.sentence)}
        multiSelect
        numItems={4}
        Content={({ dimens }) => {
          // Adjust miles bar model width slightly to make it accurate
          const milesBarModelWidth = dimens.width + 10;

          return (
            <View style={{ marginTop: 50, rowGap: 32, alignItems: 'center' }}>
              <BarModel
                total={5}
                numbers={mileNumbers}
                strings={mileStrings}
                dimens={{ width: milesBarModelWidth, height: dimens.height }}
                rowColors={[barModelColor1]}
                rowHeight={displayMode === 'digital' ? 50 : 100}
              />
              <BarModel
                total={8}
                numbers={kmNumbers}
                strings={kmStrings}
                dimens={dimens}
                rowColors={[barModelColor2]}
                rowHeight={displayMode === 'digital' ? 50 : 100}
              />
            </View>
          );
        }}
        renderItems={shuffledStatements.map(statement => ({
          value: statement.sentence,
          component: (
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 28 : 40,
                textAlign: 'center',
                padding: 20
              }}
            >
              {statement.sentence}
            </Text>
          )
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aoj',
  description: 'aoj',
  keywords: ['Length', 'Miles', 'Kilometres', 'Convert'],
  schema: z.object({
    number1: z.number().min(2.5).max(5).step(2.5),
    number2: z.number().int().min(10).max(20).step(5),
    number3: z.number().int().min(15).max(35).step(5),
    number4: z.number().int().min(20).max(60).step(5),
    topAnsIndices: z.array(z.number().int().min(1).max(3)),
    botAnsIndices: z.array(z.number().int().min(1).max(3)),
    addOrSubtract: z.enum([ADD, SUB])
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2.5, 5]); // Can only ever be 2 numbers
    const number2 = randomIntegerInclusiveStep(10, 20, 5);
    const number3 = randomIntegerInclusive(number2 + 1, 35, { constraint: x => x % 5 === 0 });
    const number4 = randomIntegerInclusive(number3 + 1, 60, { constraint: x => x % 5 === 0 });

    const { topAnsIndices, botAnsIndices } = rejectionSample(
      () => {
        const distribution = getRandomBoolean() ? [1, 2] : [2, 1];
        const topAnsIndices = randomUniqueIntegersInclusive(1, 3, distribution[0]);
        const botAnsIndices = randomUniqueIntegersInclusive(1, 3, distribution[1]);

        return { topAnsIndices, botAnsIndices };
      },
      ({ topAnsIndices, botAnsIndices }) =>
        topAnsIndices.every(elem => !botAnsIndices.includes(elem))
    );

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    // Also need to generate same distribution for what to do to create incorrect answers

    return {
      number1,
      number2,
      number3,
      number4,
      topAnsIndices: topAnsIndices.sort(),
      botAnsIndices: botAnsIndices.sort(),
      addOrSubtract
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, topAnsIndices, botAnsIndices, addOrSubtract },
      translate
    } = props;

    const random = seededRandom(props.question);

    const topTickIncorrect = shuffle(
      [
        addOrSubtract === ADD ? '+5' : '-5',
        addOrSubtract === ADD ? '+10' : '-10',
        '*2',
        '/ 5 * 4',
        '/ 5 * 6'
      ],
      { random }
    );
    const botTickIncorrect = shuffle(
      [
        addOrSubtract === ADD ? '+2' : '-2',
        addOrSubtract === ADD ? '+4' : '-4',
        addOrSubtract === ADD ? '+8' : '-8',
        '/ 2'
      ],
      { random }
    );

    const number5 = (number1 * 8) / 5;
    const number6 = (number2 * 8) / 5;
    const number7 = (number3 * 8) / 5;
    const number8 = (number4 * 8) / 5;

    const correctAnswers: string[] = [];
    const incorrectAnswers: string[] = [];

    const topTicks = [
      (0).toLocaleString(),
      number1.toLocaleString(),
      number2.toLocaleString(),
      number3.toLocaleString(),
      number4.toLocaleString()
    ];
    topAnsIndices.forEach(it => {
      // Add correct and incorrect answers
      correctAnswers.push(topTicks[it]);

      // Must ensure that incorrect answer isn't negative
      let modifier;
      let incorrectAns;
      do {
        modifier = getRandomFromArray(topTickIncorrect, { random });
        incorrectAns = number(math.evaluate(`${topTicks[it]} ${modifier}`));
      } while (number(math.evaluate(`${topTicks[it]} ${modifier}`)) < 0);

      incorrectAnswers.push(incorrectAns.toLocaleString());

      topTicks[it] = '<ans/>';
    });

    const botTicks = [
      (0).toLocaleString(),
      number5.toLocaleString(),
      number6.toLocaleString(),
      number7.toLocaleString(),
      number8.toLocaleString()
    ];
    botAnsIndices.forEach(it => {
      // Add correct and incorrect answers
      correctAnswers.push(botTicks[it]);

      // Must ensure that incorrect answer isn't negative
      let modifier, incorrectAns;
      do {
        modifier = getRandomFromArray(botTickIncorrect, { random });
        incorrectAns = number(math.evaluate(`${botTicks[it]} ${modifier}`));
      } while (number(math.evaluate(`${botTicks[it]} ${modifier}`)) < 0);
      incorrectAnswers.push(incorrectAns.toLocaleString());

      botTicks[it] = '<ans/>';
    });

    const shuffledItems = shuffle(
      [...correctAnswers, ...incorrectAnswers].map(elem => parseToSUB(elem)),
      { random }
    );

    return (
      <QF17aCompleteDoubleNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={botTicks}
        topTickValues={topTicks}
        items={shuffledItems}
        testCorrect={correctAnswers}
        precedingLinesText={[translate.units.miles(2), translate.units.km()]}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'aok',
  description: 'aok',
  keywords: ['Length', 'Miles', 'Kilometres', 'Convert'],
  schema: z
    .object({
      milesOrKmGiven: z.enum(['miles', 'km']),
      milesOrKmB: z.number().int().min(10).max(96)
    })
    .refine(
      val => (val.milesOrKmGiven === 'miles' ? val.milesOrKmB % 5 === 0 : val.milesOrKmB % 8 === 0),
      'If milesOrKmGiven is miles, milesOrKmB must be a multiple of 5, otherwise it must be a multiple of 8'
    ),
  questionHeight: 500,
  simpleGenerator: () => {
    const milesOrKmGiven = getRandomFromArray(['miles', 'km'] as const);

    // Given amount in second equation must be between 2 times and 12 times larger than 5 or 8, depending on if they are miles or km:
    const milesOrKmB =
      milesOrKmGiven === 'miles'
        ? randomIntegerInclusiveStep(10, 60, 5)
        : randomIntegerInclusiveStep(16, 96, 8);

    return {
      milesOrKmGiven,
      milesOrKmB
    };
  },
  Component: props => {
    const {
      question: { milesOrKmGiven, milesOrKmB },
      translate
    } = props;

    const answer1 =
      milesOrKmGiven === 'miles'
        ? convertUnitsSuffix(5, 'mi', 'km').value
        : convertUnitsSuffix(8, 'km', 'mi').value;

    const answer2 =
      milesOrKmGiven === 'miles'
        ? convertUnitsSuffix(milesOrKmB, 'mi', 'km').value
        : convertUnitsSuffix(milesOrKmB, 'km', 'mi').value;

    const eqs =
      milesOrKmGiven === 'miles'
        ? [
            `${translate.units.numberOfMiles(5)} ≈ ${translate.units.stringKm('<ans/>')}`,
            `${translate.units.numberOfMiles(milesOrKmB)} ≈ ${translate.units.stringKm('<ans/>')}`
          ]
        : [
            `${translate.units.numberOfKm(8)} ≈ ${translate.units.stringMiles('<ans/>')}`,
            `${translate.units.numberOfKm(milesOrKmB)} ≈ ${translate.units.stringMiles('<ans/>')}`
          ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={[[answer1.toString()], [answer2.toString()]]}
        sentences={eqs}
        questionHeight={500}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aol',
  description: 'aol',
  keywords: ['Length', 'Miles', 'Kilometres', 'Convert'],
  schema: z.object({
    milesOrKmGiven: z.enum(['miles', 'km']),
    milesOrKmC: z.number().min(7.5).max(44)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const milesOrKmGiven = getRandomFromArray(['miles', 'km'] as const);

    const milesOrKmC =
      milesOrKmGiven === 'miles'
        ? randomIntegerInclusiveStep(125, 225, 25, {
            // Must be a multiple of 2.5 and not a multiple of 5:
            constraint: x => x % 50 !== 0
          }) / 10
        : randomIntegerInclusiveStep(20, 44, 4, {
            // Must be a multiple of 4 and not a multiple of 8:
            constraint: x => x % 8 !== 0
          });

    return { milesOrKmGiven, milesOrKmC };
  },
  Component: props => {
    const {
      question: { milesOrKmGiven, milesOrKmC },
      translate
    } = props;

    const answerA =
      milesOrKmGiven === 'miles'
        ? convertUnitsSuffix(5, Miles.suffix, Kilometres.suffix).value
        : convertUnitsSuffix(8, Kilometres.suffix, Miles.suffix).value;

    const answerB =
      milesOrKmGiven === 'miles'
        ? convertUnitsSuffix(2.5, Miles.suffix, Kilometres.suffix).value
        : convertUnitsSuffix(4, Kilometres.suffix, Miles.suffix).value;

    const answerC =
      milesOrKmGiven === 'miles'
        ? convertUnitsSuffix(milesOrKmC, Miles.suffix, Kilometres.suffix).value
        : convertUnitsSuffix(milesOrKmC, Kilometres.suffix, Miles.suffix).value;

    const eqs =
      milesOrKmGiven === 'miles'
        ? [
            `${translate.units.numberOfMiles(5)} ≈ ${translate.units.stringKm('<ans/>')}`,
            `${translate.units.numberOfMiles(2.5)} ≈ ${translate.units.stringKm('<ans/>')}`,
            `${translate.units.numberOfMiles(milesOrKmC)} ≈ ${translate.units.stringKm('<ans/>')}`
          ]
        : [
            `${translate.units.numberOfKm(8)} ≈ ${translate.units.stringMiles('<ans/>')}`,
            `${translate.units.numberOfKm(4)} ≈ ${translate.units.stringMiles('<ans/>')}`,
            `${translate.units.numberOfKm(milesOrKmC)} ≈ ${translate.units.stringMiles('<ans/>')}`
          ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answerA) &&
          compareFloats(userAnswer[1][0], answerB) &&
          compareFloats(userAnswer[2][0], answerC)
        }
        sentences={eqs}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answerA.toLocaleString()],
            [answerB.toLocaleString()],
            [answerC.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aom',
  description: 'aom',
  keywords: ['Compare', 'Inequality', 'Length', 'Miles', 'Kilometres', 'Convert'],
  schema: z.object({
    milesA: z.number().min(0.5).max(900),
    kmA: z.number().min(0.8).max(900),
    milesB: z.number().min(0.5).max(900),
    kmB: z.number().min(0.8).max(900),
    milesC: z.number().min(0.5).max(900),
    kmC: z.number().min(0.8).max(900)
  }),
  simpleGenerator: () => {
    const milesRanges = range(1, 3).map(_item =>
      getRandomFromArray(['lessThan5', 'lessThan99', 'lessThan1000'] as const)
    );

    const [milesA, milesB, milesC] = milesRanges.map(range => {
      switch (range) {
        case 'lessThan5':
          return randomIntegerInclusiveStep(5, 45, 5) / 10;
        case 'lessThan99':
          return randomIntegerInclusiveStep(5, 95, 5);
        case 'lessThan1000':
          return randomIntegerInclusiveStep(100, 900, 100);
      }
    });

    const kmRanges = range(1, 3).map(_item =>
      getRandomFromArray(['lessThan8', 'lessThan99', 'lessThan1000'] as const)
    );

    const [kmA, kmB, kmC] = kmRanges.map(range => {
      switch (range) {
        case 'lessThan8':
          return randomIntegerInclusiveStep(8, 72, 8) / 10;
        case 'lessThan99':
          return randomIntegerInclusiveStep(8, 96, 8);
        case 'lessThan1000':
          return randomIntegerInclusiveStep(100, 900, 100);
      }
    });

    return { milesA, kmA, milesB, kmB, milesC, kmC };
  },
  Component: props => {
    const {
      question: { milesA, kmA, milesB, kmB, milesC, kmC },
      translate
    } = props;

    // Randomly order these statements
    const sentences = useMemo(() => {
      const milesAToKm = convertUnitsSuffix(milesA, 'mi', 'km').value;
      const kmBToMi = convertUnitsSuffix(kmB, 'km', 'mi').value;
      const milesCToKm = convertUnitsSuffix(milesC, 'mi', 'km').value;

      const lineA = {
        lhsComponent: `${translate.units.numberOfMiles(milesA)}`,
        rhsComponent: `${translate.units.numberOfKm(kmA)}`,
        correctAnswer:
          lessThanGreaterThanOrEqualTo(milesAToKm, kmA) === '='
            ? '≈'
            : lessThanGreaterThanOrEqualTo(milesAToKm, kmA)
      };

      const lineB = {
        lhsComponent: `${translate.units.numberOfKm(kmB)}`,
        rhsComponent: `${translate.units.numberOfMiles(milesB)}`,
        correctAnswer:
          lessThanGreaterThanOrEqualTo(kmBToMi, milesB) === '='
            ? '≈'
            : lessThanGreaterThanOrEqualTo(kmBToMi, milesB)
      };

      const lineC = {
        lhsComponent: `${translate.units.numberOfMiles(milesC)}`,
        rhsComponent: `${translate.units.numberOfKm(kmC)}`,
        correctAnswer:
          lessThanGreaterThanOrEqualTo(milesCToKm, kmC) === '='
            ? '≈'
            : lessThanGreaterThanOrEqualTo(milesCToKm, kmC)
      };

      return shuffle([lineA, lineB, lineC], { random: seededRandom(props.question) });
    }, [kmA, kmB, kmC, milesA, milesB, milesC, props.question, translate.units]);

    return (
      <QF6DragMatchStatements
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useGreaterLessThanOrApproxEqualsToCompleteStatements()}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        items={['<', '>', '≈']}
        itemVariant={'square'}
        statements={sentences}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aon',
  description: 'aon',
  keywords: ['Length', 'Miles', 'Kilometres', 'Convert', 'Problem'],
  schema: z.object({
    miles1: z.number().int().min(15).max(95).multipleOf(5),
    km1: z.number().int().min(16).max(70),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const miles1 = randomIntegerInclusiveStep(15, 95, 5);
    const km1 = randomIntegerInclusive(16, 70);

    const name = getRandomName();

    return {
      miles1,
      km1,
      name
    };
  },
  Component: props => {
    const {
      question: { miles1, km1, name },
      translate
    } = props;

    const answer = convertUnitsSuffix(miles1, 'mi', 'km').value + km1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterHasWorkedOutConversion({
          name,
          num1: miles1.toLocaleString(),
          num2: km1.toLocaleString()
        })}
        testCorrect={[answer.toString()]}
        sentence={`${translate.units.numberOfMiles(miles1)} ${ADD} ${translate.units.numberOfKm(
          km1
        )} ≈ <ans/> ${translate.units.km()}`}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MilesAndKilometres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
