import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { range, rangeExcluding } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { fluent } from '@codibre/fluent-iterable';
import { roundToTheNearest } from '../../../../utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'acY',
  description: 'acY',
  keywords: ['Place value', 'Round', 'Number line', '1,000', 'Thousand'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(100)
      .max(9900)
      .multipleOf(100)
      .refine(x => x % 1000 !== 0)
  }),
  example: {
    number: 6400
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(100, 9900, 100, { constraint: x => x % 1000 !== 0 });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 1000) * 1000;
    const endNumber = Math.ceil(number / 1000) * 1000;
    const tickInterval = 100;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest1000(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 1000).toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={number}
            scaleFactor={3.5}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'acZ',
  description: 'acZ',
  keywords: ['Place value', 'Round', 'Number line', '1,000', 'Thousand'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(10)
      .max(9990)
      .multipleOf(10)
      .refine(x => x % 1000 !== 0)
  }),
  example: {
    number: 2370
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusiveStep(10, 9900, 10, { constraint: x => x % 1000 !== 0 });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    const startingNumber = Math.floor(number / 1000) * 1000;
    const endNumber = Math.ceil(number / 1000) * 1000;
    const tickInterval = 100;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber, tickInterval);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={translate.answerSentences.roundToNearest1000(number)}
        title={translate.instructions.completeSentence()}
        testCorrect={[roundToTheNearest(number, 1000).toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={number}
            scaleFactor={3.5}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ac0',
  description: 'ac0',
  keywords: ['Place value', 'Round', 'Thousand', '1,000', 'Sort'],
  schema: z.object({
    numbers: z
      .array(
        z
          .number()
          .int()
          .min(1001)
          .max(9999)
          .refine(x => x % 1000 !== 0)
      )
      .length(6)
  }),
  simpleGenerator: () => {
    //6x numbers >$var and <$var+1000.
    const numbers: number[] = [];
    const thousands = randomIntegerInclusive(1, 9) * 1000;
    // 1. Include $var+500.
    const fiveHundred = thousands + 500;
    numbers.push(fiveHundred);

    // 2. Include one other multiple of 100.
    const randomMultipleOf100 =
      getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 100 + thousands;
    numbers.push(randomMultipleOf100);

    // 3. Include 1 multiple of 10.
    const randomMultipleOf10Hundreds = randomIntegerInclusive(0, 9) * 100;
    const randomMultipleOf10Tens = getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0] * 10;
    const randomMultipleOf10 = thousands + randomMultipleOf10Hundreds + randomMultipleOf10Tens;
    numbers.push(randomMultipleOf10);

    // 4. Include 1 odd multiple of 50.
    const oddMultiple50Hundreds = randomIntegerInclusive(0, 9) * 100;
    const oddMultiple50 = thousands + oddMultiple50Hundreds + 50;
    numbers.push(oddMultiple50);

    // 5. Include 1 odd multiple of 5.
    const oddMultiple5Hundreds = randomIntegerInclusive(0, 9) * 100;
    const oddMultiple5Tens = randomIntegerInclusive(0, 9) * 10;
    const oddMultiple5 = thousands + oddMultiple5Hundreds + oddMultiple5Tens + 5;
    numbers.push(oddMultiple5);

    // 6. Random number
    const randomHundreds = randomIntegerInclusive(0, 9) * 100;
    const randomTens = randomIntegerInclusive(0, 9) * 10;
    const randomOnes = getRandomSubArrayFromArray(rangeExcluding(1, 9, [5]), 1)[0];
    const randomNumber = thousands + randomHundreds + randomTens + randomOnes;
    numbers.push(randomNumber);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    // Round down on the left, round up on the right
    const lower = Math.floor(numbers[0] / 1000) * 1000;
    const upper = Math.ceil(numbers[0] / 1000) * 1000;
    const roundsDown: number[] = [];
    const roundsUp: number[] = [];

    numbers.forEach(number => {
      if (roundToTheNearest(number, 1000) === lower) {
        roundsDown.push(number);
      } else {
        roundsUp.push(number);
      }
    });

    const correctOrder = [roundsDown, roundsUp];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.roundToNearestXDragToSort(1000)}
        pdfTitle={translate.instructions.roundToNearestXDragToSortPDF(1000)}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.roundsTo(lower), translate.instructions.roundsTo(upper)]}
        items={numbers}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'ac1',
  description: 'ac1',
  keywords: ['Place value', 'Round', '100', 'Hundred'],
  schema: z.object({
    numbers: z.array(z.number().int().min(10).max(9999)).length(3)
  }),
  example: {
    numbers: [816, 9225, 3704]
  },
  simpleGenerator: () => {
    const numAnswers = 3;
    const numberSet = new Set<number>();
    while (numberSet.size < numAnswers) {
      const ones = randomIntegerInclusive(0, 9);
      const tens = randomIntegerInclusive(1, 9);
      const hundreds = randomIntegerInclusive(0, 9);
      const thousands = randomIntegerInclusive(0, 9);
      numberSet.add(thousands * 1000 + hundreds * 100 + tens * 10 + ones);
    }
    const numbers = fluent(numberSet).toArray();
    return { numbers: numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const equations = numbers.map(number => {
      const correctAnswer = roundToTheNearest(number, 1000);
      const sentence = `${number.toLocaleString()}  <ans/>`;
      return { correctAnswer, sentence };
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundToNearestX(1000)}
        testCorrect={equations.map(it => [it.correctAnswer.toString()])}
        sentences={equations.map(it => it.sentence)}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ac2',
  description: 'ac2',
  keywords: ['Place value', 'Round', '1,000'],
  schema: z.object({
    comparitor: z.number().int().step(100).min(2000).max(9000),
    numbers: z.number().int().min(0).max(10000).array().length(6)
  }),
  example: {
    comparitor: 7000,
    numbers: [6330, 6500, 6879, 7205, 7500, 7880]
  },
  simpleGenerator: () => {
    // Pick the comparitor.
    const comparitor = randomIntegerInclusive(2, 9) * 1000;

    // $var-1001<num1<$var-500
    const num1 = randomIntegerInclusive(comparitor - 1000, comparitor - 501);

    //num2=$var-500,
    const num2 = comparitor - 500;

    //$var-500<num3<$var,
    const num3 = randomIntegerInclusive(comparitor - 499, comparitor - 1);

    //num4<$var+500,
    const num4 = randomIntegerInclusive(comparitor + 1, comparitor + 499);

    //num5=$var+50,
    const num5 = comparitor + 500;

    //$var+50<num6<$var+101
    const num6 = randomIntegerInclusive(comparitor + 501, comparitor + 1000);

    return { comparitor, numbers: shuffle([num1, num2, num3, num4, num5, num6]) };
  },
  Component: props => {
    const {
      question: { comparitor, numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectWhichRoundToXToNearestY(comparitor, 1000)}
        pdfTitle={translate.instructions.circleWhichRoundToXToNearestY(comparitor, 1000)}
        testCorrect={numbers.filter(it => roundToTheNearest(it, 1000) === comparitor)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

// Copied by ac8 and aej
const Question6 = newQuestionContent({
  uid: 'ac3',
  description: 'ac3',
  keywords: ['Place value', 'Round', '1,000', 'Thousand'],
  schema: z.object({
    number: z.number().int().min(100).max(9999)
  }),
  example: {
    number: 7254
  },
  simpleGenerator: () => {
    const thousands = randomIntegerInclusive(0, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(0, 9);
    const ones = randomIntegerInclusive(0, 9);
    const number = thousands * 1000 + hundreds * 100 + tens * 10 + ones;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;
    const nearest10 = roundToTheNearest(number, 10);
    const nearest100 = roundToTheNearest(number, 100);
    const nearest1000 = roundToTheNearest(number, 1000);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[[nearest10.toString()], [nearest100.toString()], [nearest1000.toString()]]}
        textStyle={displayMode === 'digital' ? { fontSize: 32 } : undefined}
        containerStyle={{ alignItems: 'flex-start' }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        sentences={[
          translate.answerSentences.roundToNearest10(number),
          translate.answerSentences.roundToNearest100(number),
          translate.answerSentences.roundToNearest1000(number)
        ]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});
export const ac3 = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundToTheNearest1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
