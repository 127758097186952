import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { Direction, Point2d } from '../../../../utils/vectors';
import Text from '../../../../components/typography/Text';
import { arraysHaveSameContents, sortNumberArray } from '../../../../utils/collections';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { GridPolygon } from '../../../../utils/gridUtils';
import { isValidTriangle } from '../../../../utils/shapes';
import { LocalizedString } from 'typesafe-i18n';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'azI',
  description: 'azI',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      startX: z.number().int().min(1).max(5),
      startY: z.number().int().min(1).max(5),
      translateX: z.number().int().min(-5).max(5),
      translateY: z.number().int().min(-5).max(5),
      axis: z.enum(['x', 'y'])
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translationX'
    )
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translationY'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(1, 5);
    const startY = randomIntegerInclusive(1, 5);
    const translateX = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });
    const translateY = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });
    const axis = getRandomFromArray(['x', 'y'] as const);
    return { startX, startY, translateX, translateY, axis };
  },
  questionHeight: 1000,
  Component: ({ question: { startX, startY, translateX, translateY, axis }, translate }) => {
    let translatedDirection = '';

    const [direction, distance] = ((): [Direction, number] => {
      switch (true) {
        case axis === 'x' && translateX > 0:
          translatedDirection = translate.directions.right();
          return ['right', translateX];
        case axis === 'x' && translateX < 0:
          translatedDirection = translate.directions.left();
          return ['left', -translateX];
        case axis === 'y' && translateY > 0:
          translatedDirection = translate.directions.up();
          return ['up', translateY];
        case axis === 'y' && translateY < 0:
          translatedDirection = translate.directions.down();
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    // PointB Coordinates
    const pointBCoordinates = [
      direction === 'right' ? startX + distance : direction === 'left' ? startX - distance : startX,
      direction === 'up' ? startY + distance : direction === 'down' ? startY - distance : startY
    ] as [number, number];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentenceToDescribeTheTranslation()}
        sentence={translate.answerSentences.xToYIsATranslationAnsSquaresDirectionZ(
          translate.letters.A(),
          translate.letters.B(),
          distance,
          translatedDirection
        )}
        testCorrect={[distance.toString()]}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={[startX, startY] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
              label={translate.letters.A()}
            />
            <GridImage
              mathCoord={pointBCoordinates}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
              label={translate.letters.B()}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'azJ',
  description: 'azJ',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      startX: z.number().int().min(1).max(5),
      startY: z.number().int().min(1).max(5),
      translateX: z.number().int().min(-5).max(5),
      translateY: z.number().int().min(-5).max(5)
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translationX'
    )
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translationY'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(1, 5);
    const startY = randomIntegerInclusive(1, 5);
    const translateX = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });
    const translateY = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });
    return { startX, startY, translateX, translateY };
  },
  Component: props => {
    const {
      question: { startX, startY, translateX, translateY },
      translate,
      displayMode
    } = props;

    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));

    // Direction/Distance X
    const [translatedDirectionX, distanceX] = ((): [LocalizedString, number] => {
      switch (true) {
        case translateX > 0:
          return [translate.directions.right(), translateX];
        case translateX < 0:
          return [translate.directions.left(), -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();

    // Direction/Distance Y
    const [translatedDirectionY, distanceY] = ((): [LocalizedString, number] => {
      switch (true) {
        case translateY > 0:
          return [translate.directions.up(), translateY];
        case translateY < 0:
          return [translate.directions.down(), -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const items = [
      translate.directions.left(),
      translate.directions.right(),
      translate.directions.up(),
      translate.directions.down()
    ];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToDescribeTranslation()}
        pdfTitle={translate.instructions.useCardsToDescribeTranslation()}
        actionPanelVariant="end"
        items={shuffle(items, { random: seededRandom(props.question) })}
        itemVariant="square"
        pdfLayout="itemsBottom"
        questionHeight={1200}
        sentencesStyle={{ alignItems: 'flex-start' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={[startX, startY] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue },
                height: displayMode === 'digital' ? undefined : 60,
                width: displayMode === 'digital' ? undefined : 60
              }}
              label={translate.letters.A()}
            />
            <GridImage
              mathCoord={[end.x, end.y] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue },
                height: displayMode === 'digital' ? undefined : 60,
                width: displayMode === 'digital' ? undefined : 60
              }}
              label={translate.letters.B()}
            />
          </Grid>
        )}
        sentence={translate.answerSentences.xToYIsATranslationXSquaresDirectionAnsAndYSquaresDirectionAns(
          translate.letters.A(),
          translate.letters.B(),
          distanceX.toLocaleString(),
          distanceY.toLocaleString()
        )}
        testCorrect={[translatedDirectionX, translatedDirectionY]}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'azK',
  description: 'azK',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      startX: z.number().int().min(1).max(5),
      startY: z.number().int().min(1).max(5),
      translateX: z.number().int().min(-5).max(5),
      translateY: z.number().int().min(-5).max(5)
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translationX'
    )
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translationY'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(1, 5);
    const startY = randomIntegerInclusive(1, 5);
    const translateX = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });
    const translateY = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });
    return { startX, startY, translateX, translateY };
  },
  questionHeight: 1000,
  Component: ({ question: { startX, startY, translateX, translateY }, translate }) => {
    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));

    // Direction/Distance X
    const [translatedDirectionX, distanceX] = ((): [LocalizedString, number] => {
      switch (true) {
        case translateX > 0:
          return [translate.directions.right(), translateX];
        case translateX < 0:
          return [translate.directions.left(), -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();

    // Direction/Distance Y
    const [translatedDirectionY, distanceY] = ((): [LocalizedString, number] => {
      switch (true) {
        case translateY > 0:
          return [translate.directions.up(), translateY];
        case translateY < 0:
          return [translate.directions.down(), -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentenceToDescribeTheTranslation()}
        sentence={translate.answerSentences.xToYIsATranslationAnsXSquaresDirectionXAndAnsYSquaresDirectionY(
          translate.letters.A(),
          translate.letters.B(),
          distanceX,
          translatedDirectionX,
          distanceY,
          translatedDirectionY
        )}
        testCorrect={[distanceX.toString(), distanceY.toString()]}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={[startX, startY] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
              label={translate.letters.A()}
            />
            <GridImage
              mathCoord={[end.x, end.y] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
              label={translate.letters.B()}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'azL',
  description: 'azL',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      startX: z.number().int().min(1).max(5),
      startY: z.number().int().min(1).max(5),
      translateX: z.number().int().min(-5).max(5),
      translateY: z.number().int().min(-5).max(5),
      letters: z.array(z.enum(['A', 'B', 'C', 'D'])).length(4)
    })
    .refine(
      ({ translateX, startX }) => startX + translateX > 0 && startX + translateX < 6,
      'Point must still be in grid after translationX'
    )
    .refine(
      ({ translateY, startY }) => startY + translateY > 0 && startY + translateY < 6,
      'Point must still be in grid after translationY'
    ),
  simpleGenerator: () => {
    const startX = randomIntegerInclusive(1, 5);
    const startY = randomIntegerInclusive(1, 5);
    const translateX = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0 && startX + x > 0 && startX + x < 6
    });
    const translateY = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0 && startY + x > 0 && startY + x < 6
    });

    const letters = getRandomSubArrayFromArray(['A', 'B', 'C', 'D'] as const, 4);
    return { startX, startY, translateX, translateY, letters };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { startX, startY, translateX, translateY, letters },
      translate
    } = props;

    const start = new Point2d(startX, startY);
    const end = start.add(new Point2d(translateX, translateY));

    // Direction/Distance X
    const [translatedDirectionX, distanceX] = ((): [LocalizedString, number] => {
      switch (true) {
        case translateX > 0:
          return [translate.directions.right(), translateX];
        case translateX < 0:
          return [translate.directions.left(), -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();

    // Direction/Distance Y
    const [translatedDirectionY, distanceY] = ((): [LocalizedString, number] => {
      switch (true) {
        case translateY > 0:
          return [translate.directions.up(), translateY];
        case translateY < 0:
          return [translate.directions.down(), -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    // Letters
    const [letterA, letterB, letterC, letterD] = letters;

    // Get all coordinates
    const allCoordinates = [];
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 6; j++) {
        allCoordinates.push([i, j]);
      }
    }

    // Filter out point A/B coordinates
    const availableCoordinates = allCoordinates.filter(
      coordinates =>
        !arraysHaveSameContents(coordinates, [startX, startY]) &&
        !arraysHaveSameContents(coordinates, [end.x, end.y])
    );

    // Get 2 random coordinates from the remaining available for points C/D
    const [pointsC, pointsD] = getRandomSubArrayFromArray(availableCoordinates, 2);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentenceToDescribeTheTranslation()}
        sentence={translate.answerSentences.xToYIsATranslationAnsXSquaresDirectionXAndAnsYSquaresDirectionY(
          letterA,
          letterB,
          distanceX,
          translatedDirectionX,
          distanceY,
          translatedDirectionY
        )}
        testCorrect={[distanceX.toString(), distanceY.toString()]}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={[startX, startY] as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
              label={translate.letters[letterA]()}
            />
            <GridImage
              mathCoord={[end.x, end.y]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
              label={translate.letters[letterB]()}
            />
            <GridImage
              mathCoord={pointsC as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
              label={translate.letters[letterC]()}
            />
            <GridImage
              mathCoord={pointsD as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
              label={translate.letters[letterD]()}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'azM',
  description: 'azM',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      coordinate1: z.number().int().min(0).max(5).array().length(2),
      coordinate2: z.number().int().min(0).max(5).array().length(2),
      coordinate3: z.number().int().min(0).max(5).array().length(2),
      translateX: z
        .number()
        .int()
        .refine(x => x !== 0, 'Must not be zero'),
      translateY: z
        .number()
        .int()
        .refine(y => y !== 0, 'Must not be zero')
    })
    .refine(({ coordinate1, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate1[0], coordinate1[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate2 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate3 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const translateX = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const translateY = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const directionAsked = getRandomFromArray(['A to B', 'B to A'] as const);

        return {
          coordinate1,
          coordinate2,
          coordinate3,
          translateX,
          translateY,
          directionAsked
        };
      },
      ({ coordinate1, coordinate2, coordinate3, translateX, translateY }) => {
        const newPoint1 = new Point2d(coordinate1[0], coordinate1[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint2 = new Point2d(coordinate2[0], coordinate2[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint3 = new Point2d(coordinate3[0], coordinate3[1]).add(
          new Point2d(translateX, translateY)
        );
        return (
          0 <= newPoint1.x &&
          newPoint1.x <= 5 &&
          0 <= newPoint1.y &&
          newPoint1.y <= 5 &&
          0 <= newPoint2.x &&
          newPoint2.x <= 5 &&
          0 <= newPoint2.y &&
          newPoint2.y <= 5 &&
          0 <= newPoint3.x &&
          newPoint3.x <= 5 &&
          0 <= newPoint3.y &&
          newPoint3.y <= 5 &&
          isValidTriangle(
            coordinate1 as [number, number],
            coordinate2 as [number, number],
            coordinate3 as [number, number],
            3
          )
        );
      }
    ),
  Component: props => {
    const {
      question: { coordinate1, coordinate2, coordinate3, translateX, translateY },
      translate,
      displayMode
    } = props;

    const [translatedDirectionX, xDistance] = ((): [LocalizedString, number] => {
      switch (true) {
        case translateX > 0:
          return [translate.directions.right(), translateX];
        case translateX < 0:
          return [translate.directions.left(), -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();

    const [translatedDirectionY, yDistance] = ((): [LocalizedString, number] => {
      switch (true) {
        case translateY > 0:
          return [translate.directions.up(), translateY];
        case translateY < 0:
          return [translate.directions.down(), -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const start1 = new Point2d(coordinate1[0], coordinate1[1]);
    const end1 = start1.add(new Point2d(translateX, translateY));
    const start2 = new Point2d(coordinate2[0], coordinate2[1]);
    const end2 = start2.add(new Point2d(translateX, translateY));
    const start3 = new Point2d(coordinate3[0], coordinate3[1]);
    const end3 = start3.add(new Point2d(translateX, translateY));

    const startXPoints = [start1.x, start2.x, start3.x];
    const startYPoints = [start1.y, start2.y, start3.y];
    const endXPoints = [end1.x, end2.x, end3.x];
    const endYPoints = [end1.y, end2.y, end3.y];
    const highestY = sortNumberArray(startYPoints, 'descending')[0];
    const index = startYPoints.indexOf(highestY);

    const triangleAHighestPoint = [startXPoints[index], startYPoints[index]] as [number, number];
    const triangleBHighestPoint = [endXPoints[index], endYPoints[index]] as [number, number];

    const items = [
      translate.directions.left(),
      translate.directions.right(),
      translate.directions.up(),
      translate.directions.down()
    ];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToDescribeTranslation()}
        pdfTitle={translate.instructions.useCardsToDescribeTranslation()}
        actionPanelVariant="end"
        items={shuffle(items, { random: seededRandom(props.question) })}
        sentencesStyle={{ alignItems: 'flex-start' }}
        itemVariant="square"
        pdfLayout="itemsBottom"
        Content={({ dimens }) => (
          <Grid {...dimens} xMax={5} yMax={5} squareGrid>
            {({ mathToSvgX, mathToSvgY, svgWidth, svgHeight }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon
                    points={[
                      [start1.x, start1.y],
                      [start2.x, start2.y],
                      [start3.x, start3.y]
                    ]}
                    color={`${colors.prussianBlue}70`}
                    showBorder
                  />
                  <GridPolygon
                    points={[
                      [end1.x, end1.y],
                      [end2.x, end2.y],
                      [end3.x, end3.y]
                    ]}
                    color={`${colors.burntSienna}70`}
                    showBorder
                  />
                </GridSvgChildren>
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    left: mathToSvgX(triangleAHighestPoint[0]) - 100,
                    right: svgWidth - (mathToSvgX(triangleAHighestPoint[0]) + 100),
                    bottom: svgHeight - mathToSvgY(triangleAHighestPoint[1]),
                    fontSize: displayMode === 'digital' ? 24 : 40,
                    lineHeight: 24,
                    color: colors.prussianBlue,
                    textAlign: 'center',
                    textShadowColor: 'white',
                    textShadowRadius: 3
                  }}
                >
                  {triangleAHighestPoint[1] > triangleBHighestPoint[1] ? 'A' : 'B'}
                </Text>
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    left: mathToSvgX(triangleBHighestPoint[0]) - 100,
                    right: svgWidth - (mathToSvgX(triangleBHighestPoint[0]) + 100),
                    bottom: svgHeight - mathToSvgY(triangleBHighestPoint[1]),
                    color: colors.prussianBlue,
                    fontSize: displayMode === 'digital' ? 24 : 40,
                    lineHeight: 24,
                    textAlign: 'center',
                    textShadowColor: 'white',
                    textShadowRadius: 3
                  }}
                >
                  {triangleAHighestPoint[1] < triangleBHighestPoint[1] ? 'A' : 'B'}
                </Text>
              </>
            )}
          </Grid>
        )}
        sentence={`${
          triangleAHighestPoint[1] > triangleBHighestPoint[1]
            ? translate.answerSentences.aToB()
            : translate.answerSentences.bToA()
        } ${translate.answerSentences.isATranslationXSquaresAnsDirectionAndYSquaresAnsDirection(
          xDistance,
          yDistance
        )}`}
        testCorrect={[translatedDirectionX, translatedDirectionY]}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'azN',
  description: 'azN',
  keywords: ['Translate', 'Coordinate'],
  schema: z
    .object({
      coordinate1: z.number().int().min(0).max(5).array().length(2),
      coordinate2: z.number().int().min(0).max(5).array().length(2),
      coordinate3: z.number().int().min(0).max(5).array().length(2),
      translateX: z
        .number()
        .int()
        .refine(x => x !== 0, 'Must not be zero'),
      translateY: z
        .number()
        .int()
        .refine(y => y !== 0, 'Must not be zero')
    })
    .refine(({ coordinate1, translateX, translateY }) => {
      const newPoint = new Point2d(coordinate1[0], coordinate1[1]).add(
        new Point2d(translateX, translateY)
      );
      return 0 <= newPoint.x && newPoint.x <= 5 && 0 <= newPoint.y && newPoint.y <= 5;
    }, 'translation must not place the point out of the grid (5x5)'),
  questionHeight: 1000,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate2 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const coordinate3 = [randomIntegerInclusive(0, 5), randomIntegerInclusive(0, 5)];
        const translateX = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const translateY = randomIntegerInclusive(-3, 3, { constraint: x => x !== 0 });
        const directionAsked = getRandomFromArray(['A to B', 'B to A'] as const);

        return {
          coordinate1,
          coordinate2,
          coordinate3,
          translateX,
          translateY,
          directionAsked
        };
      },
      ({ coordinate1, coordinate2, coordinate3, translateX, translateY }) => {
        const newPoint1 = new Point2d(coordinate1[0], coordinate1[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint2 = new Point2d(coordinate2[0], coordinate2[1]).add(
          new Point2d(translateX, translateY)
        );
        const newPoint3 = new Point2d(coordinate3[0], coordinate3[1]).add(
          new Point2d(translateX, translateY)
        );
        return (
          0 <= newPoint1.x &&
          newPoint1.x <= 5 &&
          0 <= newPoint1.y &&
          newPoint1.y <= 5 &&
          0 <= newPoint2.x &&
          newPoint2.x <= 5 &&
          0 <= newPoint2.y &&
          newPoint2.y <= 5 &&
          0 <= newPoint3.x &&
          newPoint3.x <= 5 &&
          0 <= newPoint3.y &&
          newPoint3.y <= 5 &&
          isValidTriangle(
            coordinate1 as [number, number],
            coordinate2 as [number, number],
            coordinate3 as [number, number],
            3
          )
        );
      }
    ),
  Component: props => {
    const {
      question: { coordinate1, coordinate2, coordinate3, translateX, translateY },
      translate
    } = props;

    const [xDirection, xDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateX > 0:
          return ['right', translateX];
        case translateX < 0:
          return ['left', -translateX];
        default:
          throw new Error('Unreachable');
      }
    })();
    const [yDirection, yDistance] = ((): [Direction, number] => {
      switch (true) {
        case translateY > 0:
          return ['up', translateY];
        case translateY < 0:
          return ['down', -translateY];
        default:
          throw new Error('Unreachable');
      }
    })();

    const start1 = new Point2d(coordinate1[0], coordinate1[1]);
    const end1 = start1.add(new Point2d(translateX, translateY));
    const start2 = new Point2d(coordinate2[0], coordinate2[1]);
    const end2 = start2.add(new Point2d(translateX, translateY));
    const start3 = new Point2d(coordinate3[0], coordinate3[1]);
    const end3 = start3.add(new Point2d(translateX, translateY));

    const startXPoints = [start1.x, start2.x, start3.x];
    const startYPoints = [start1.y, start2.y, start3.y];
    const endXPoints = [end1.x, end2.x, end3.x];
    const endYPoints = [end1.y, end2.y, end3.y];
    const highestY = sortNumberArray(startYPoints, 'descending')[0];
    const index = startYPoints.indexOf(highestY);

    const triangleAHighestPoint = [startXPoints[index], startYPoints[index]] as [number, number];
    const triangleBHighestPoint = [endXPoints[index], endYPoints[index]] as [number, number];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentenceToDescribeTheTranslation()}
        pdfDirection="column"
        questionHeight={1000}
        sentence={`${
          triangleAHighestPoint[1] > triangleBHighestPoint[1]
            ? translate.answerSentences.aToB()
            : translate.answerSentences.bToA()
        } ${translate.answerSentences.isAnsSquares(xDistance)} ${
          xDirection === 'left' ? translate.directions.left() : translate.directions.right()
        } ${translate.answerSentences.andAnsSquares(yDistance)} ${
          yDirection === 'up' ? translate.directions.up() : translate.directions.down()
        }`}
        testCorrect={[xDistance.toString(), yDistance.toString()]}
        Content={({ dimens }) => (
          <Grid {...dimens} xMax={5} yMax={5} squareGrid>
            {({ mathToSvgX, mathToSvgY, svgWidth, svgHeight }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon
                    points={[
                      [start1.x, start1.y],
                      [start2.x, start2.y],
                      [start3.x, start3.y]
                    ]}
                    color={`${colors.prussianBlue}70`}
                    showBorder
                  />
                  <GridPolygon
                    points={[
                      [end1.x, end1.y],
                      [end2.x, end2.y],
                      [end3.x, end3.y]
                    ]}
                    color={`${colors.burntSienna}70`}
                    showBorder
                  />
                </GridSvgChildren>
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    left: mathToSvgX(triangleAHighestPoint[0]) - 100,
                    right: svgWidth - (mathToSvgX(triangleAHighestPoint[0]) + 100),
                    bottom: svgHeight - mathToSvgY(triangleAHighestPoint[1]),
                    fontSize: 24,
                    lineHeight: 24,
                    color: colors.prussianBlue,
                    textAlign: 'center',
                    textShadowColor: 'white',
                    textShadowRadius: 3
                  }}
                >
                  {triangleAHighestPoint[1] > triangleBHighestPoint[1] ? 'A' : 'B'}
                </Text>
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    left: mathToSvgX(triangleBHighestPoint[0]) - 100,
                    right: svgWidth - (mathToSvgX(triangleBHighestPoint[0]) + 100),
                    bottom: svgHeight - mathToSvgY(triangleBHighestPoint[1]),
                    color: colors.prussianBlue,
                    fontSize: 24,
                    lineHeight: 24,
                    textAlign: 'center',
                    textShadowColor: 'white',
                    textShadowRadius: 3
                  }}
                >
                  {triangleAHighestPoint[1] < triangleBHighestPoint[1] ? 'A' : 'B'}
                </Text>
              </>
            )}
          </Grid>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribeTranslationOnAGrid',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
