import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { Base10RepresentationInfo, Base10RepTo10sVariant } from '../types';
import {
  BaseTenImages,
  ONES,
  TENS,
  HUNDREDS,
  THOUSANDS,
  TENTHOUSANDS,
  HUNDREDTHOUSANDS
} from './BaseTenImages';
import {
  gridCalcRowsOf1,
  gridCalcRowsOf2,
  gridCalcRowsOf3,
  gridCalcRowsOf5,
  gridDef
} from '../utils/gridCalc';
import { useMemo } from 'react';

const margin = 4;
const questionMargin = 20;

export type align = 'baseline' | 'flex-end' | 'flex-start' | 'center' | 'stretch';

function rowWidth(
  variant: Base10RepTo10sVariant,
  place: 'ones' | 'tens' | 'hundreds' | 'thousands' | 'tenThousands' | 'hundredThousands'
): 2 | 3 | 5 {
  switch (place) {
    case 'ones':
      return variant === 'Counters' || variant === 'Cubes' ? 2 : 3;
    case 'tens':
      return variant === 'Counters' ? 2 : variant === 'Cubes' ? 5 : 3;
    case 'hundreds':
    case 'thousands':
    case 'tenThousands':
    case 'hundredThousands':
      return variant === 'Counters' ? 2 : 3;
  }
}

function calcFunction(rowWidth: 1 | 2 | 3 | 5) {
  return rowWidth === 1
    ? gridCalcRowsOf1
    : rowWidth === 2
    ? gridCalcRowsOf2
    : rowWidth === 3
    ? gridCalcRowsOf3
    : gridCalcRowsOf5;
}

export const BaseTenRepCalcGridsAndScale = (
  usableWidth: number,
  usableHeight: number,
  numbers: {
    ones?: number;
    tens?: number;
    hundreds?: number;
    thousands?: number;
    tenThousands?: number;
    hundredThousands?: number;
  },
  variant: Base10RepTo10sVariant,
  numCols?: 1 | 2 | 3 | 5
): { scale: number; grids: gridDef[] } => {
  // Set numbers
  const numOnes = numbers.ones ?? 0;
  const numTens = numbers.tens ?? 0;
  const numHundreds = numbers.hundreds ?? 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const numThousands = numbers.thousands ?? 0;
  const numTenThousands = numbers.tenThousands ?? 0;
  const numHundredThousands = numbers.hundredThousands ?? 0;

  // Get images
  const onesObject = BaseTenImages[variant].ONES;
  const tensObject = BaseTenImages[variant].TENS;
  const hundredsObject = BaseTenImages[variant].HUNDREDS;
  const thousandsObject = BaseTenImages[variant].THOUSANDS;
  const tenThousandsObject = BaseTenImages[variant].TENTHOUSANDS;
  const hundredThousandsObject = BaseTenImages[variant].HUNDREDTHOUSANDS;

  // Use 6 as we only support up to hundred thousands
  const grids: gridDef[] = new Array(6);

  // Get dimensions of arrays
  grids[ONES] = calcFunction(rowWidth(variant, 'ones'))(numOnes);
  const onesTotalHeight = grids[ONES].totalHeight;
  const onesTotalWidth = grids[ONES].totalWidth;

  grids[TENS] = calcFunction(rowWidth(variant, 'tens'))(numTens);
  const tensTotalHeight = grids[TENS].totalHeight;
  const tensTotalWidth = grids[TENS].totalWidth;

  // Only want numCols to apply to hundreds and thousands - other powers stack cleaner without this.
  grids[HUNDREDS] = calcFunction(numCols ?? rowWidth(variant, 'hundreds'))(numHundreds);
  const hundredsTotalHeight = grids[HUNDREDS].totalHeight;
  const hundredsTotalWidth = grids[HUNDREDS].totalWidth;

  grids[THOUSANDS] = calcFunction(numCols ?? rowWidth(variant, 'thousands'))(numThousands);
  const thousandsTotalHeight = grids[THOUSANDS].totalHeight;
  const thousandsTotalWidth = grids[THOUSANDS].totalWidth;

  grids[TENTHOUSANDS] = calcFunction(rowWidth(variant, 'tenThousands'))(numTenThousands);
  const tenThousandsTotalHeight = grids[TENTHOUSANDS].totalHeight;
  const tenThousandsTotalWidth = grids[TENTHOUSANDS].totalWidth;

  grids[HUNDREDTHOUSANDS] = calcFunction(rowWidth(variant, 'hundredThousands'))(
    numHundredThousands
  );
  const hundredThousandsTotalHeight = grids[HUNDREDTHOUSANDS].totalHeight;
  const hundredThousandsTotalWidth = grids[HUNDREDTHOUSANDS].totalWidth;

  // Calculate scale amount
  // Calculate total width
  // Images are in rows of up to three per unit type (or rows of up to five for tens Cubes / rows of up to two for ones Cubes)

  const widthOnes = onesObject.width * onesTotalWidth;
  const widthTens = tensObject.width * tensTotalWidth;
  const widthHundreds = hundredsObject.width * hundredsTotalWidth;
  const widthThousands = thousandsObject.width * thousandsTotalWidth;
  const widthTenThousands = tenThousandsObject.width * tenThousandsTotalWidth;
  const widthHundredThousands = hundredThousandsObject.width * hundredThousandsTotalWidth;
  const totalImageWidth =
    widthOnes +
    widthTens +
    widthHundreds +
    widthThousands +
    widthTenThousands +
    widthHundredThousands;
  const totalAmountWidth =
    onesTotalWidth +
    tensTotalWidth +
    hundredsTotalWidth +
    thousandsTotalWidth +
    tenThousandsTotalWidth +
    hundredThousandsTotalWidth;

  // Calculate total height
  // There can be one, two or three rows of images per unit type (or one or two rows for tens and ones 'Cubes')
  const heightOnes = onesObject.height * onesTotalHeight;
  const heightTens = tensObject.height * tensTotalHeight;
  const heightHundreds = hundredsObject.height * hundredsTotalHeight;
  const heightThousands = thousandsObject.height * thousandsTotalHeight;
  const heightTenThousands = tenThousandsObject.height * tenThousandsTotalHeight;
  const heightHundredThousands = hundredThousandsObject.height * hundredThousandsTotalHeight;
  const totalImageHeight = Math.max(
    heightOnes,
    heightTens,
    heightHundreds,
    heightThousands,
    heightTenThousands,
    heightHundredThousands
  );
  const totalAmountHeight = Math.max(
    onesTotalHeight,
    tensTotalHeight,
    hundredsTotalHeight,
    thousandsTotalHeight,
    tenThousandsTotalHeight,
    hundredThousandsTotalHeight
  );

  // Calculate smallest scale
  const widthScale =
    (usableWidth - questionMargin - margin * (totalAmountWidth - 1)) / Math.ceil(totalImageWidth);
  const heightScale =
    (usableHeight - questionMargin - margin * (totalAmountHeight - 1)) /
    Math.ceil(totalImageHeight);
  return { scale: Math.min(widthScale, heightScale), grids };
};

interface BaseTenRepresentationProps {
  b10Rep: Base10RepresentationInfo;
  usableWidth: number;
  usableHeight: number;
  scale?: number;
  align?: align;
  numCols?: 1 | 2 | 3 | 5;
  containerStyle?: StyleProp<ViewStyle>;
  allFlexDirectionRow?: boolean;
  noFixedSize?: boolean;
}

const BaseTenRepresentation = (props: BaseTenRepresentationProps) => {
  // TODO
  // 1. Implement different arrangements

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { numbers, variant } = props.b10Rep;
  const { usableWidth, usableHeight, scale, numCols, containerStyle } = props;
  const allFlexDirectionRow = props.allFlexDirectionRow ?? false;
  const noFixedSize = props.noFixedSize ?? false;

  const align = props.align ?? 'flex-start';

  const styles = useMemo(() => getStyles(align), [align]);

  // Set numbers

  // Get images
  const onesObject = BaseTenImages[variant].ONES;
  const tensObject = BaseTenImages[variant].TENS;
  const hundredsObject = BaseTenImages[variant].HUNDREDS;
  const thousandsObject = BaseTenImages[variant].THOUSANDS;
  const tenThousandsObject = BaseTenImages[variant].TENTHOUSANDS;
  const hundredThousandsObject = BaseTenImages[variant].HUNDREDTHOUSANDS;

  const layoutDef = BaseTenRepCalcGridsAndScale(
    usableWidth,
    usableHeight,
    numbers,
    variant,
    numCols
  );

  const imageScale = scale !== undefined ? scale : layoutDef.scale;

  // TODO: Math.floors can potentially be removed - May help with previous scaling issues for e.g Cubes not aligning at the top
  // Calculated scaled widths and heights
  const scaledOnesWidth = Math.floor(onesObject.width * imageScale);
  const scaledTensWidth = Math.floor(tensObject.width * imageScale);
  const scaledHundredWidth = Math.floor(hundredsObject.width * imageScale);
  const scaledThousandsWidth = Math.floor(thousandsObject.width * imageScale);
  const scaledTenThousandsWidth = Math.floor(tenThousandsObject.width * imageScale);
  const scaledHundredThousandsWidth = Math.floor(hundredThousandsObject.width * imageScale);

  const scaledOnesHeight = Math.floor(onesObject.height * imageScale);
  const scaledTensHeight = Math.floor(tensObject.height * imageScale);
  const scaledHundredHeight = Math.floor(hundredsObject.height * imageScale);
  const scaledThousandsHeight = Math.floor(thousandsObject.height * imageScale);
  const scaledTenThousandsHeight = Math.floor(tenThousandsObject.height * imageScale);
  const scaledHundredThousandsHeight = Math.floor(hundredThousandsObject.height * imageScale);

  // Creates rows of representations

  // Add ones
  const ones = (
    <View style={{ flexDirection: 'column' }}>
      {
        // Arrange grids vertically
        layoutDef.grids[ONES].grids.map((grid, gridIndex) => (
          <View key={gridIndex} style={{ flexDirection: 'column' }}>
            {
              // Arrange rows vertically
              grid.grid.map((row, rowIndex) => (
                <View key={rowIndex} style={{ flexDirection: 'row' }}>
                  {
                    // Arrange items horizontally
                    row.map((_item, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          width: scaledOnesWidth,
                          height: scaledOnesHeight,
                          margin: margin / 2,
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {onesObject.component}
                      </View>
                    ))
                  }
                </View>
              ))
            }
          </View>
        ))
      }
    </View>
  );

  // Add tens
  const tens = (
    <View style={{ flexDirection: 'column' }}>
      {
        // Arrange grids vertically
        layoutDef.grids[TENS].grids.map((grid, gridIndex) => (
          <View key={gridIndex} style={{ flexDirection: 'column' }}>
            {
              // Arrange rows vertically
              grid.grid.map((row, rowIndex) => (
                <View key={rowIndex} style={{ flexDirection: 'row' }}>
                  {
                    // Arrange items horizontally
                    row.map((_item, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          width: scaledTensWidth,
                          height: scaledTensHeight,
                          margin: margin / 2,
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {tensObject.component}
                      </View>
                    ))
                  }
                </View>
              ))
            }
          </View>
        ))
      }
    </View>
  );

  // Add hundreds
  const hundreds = (
    <View style={{ flexDirection: 'column' }}>
      {
        // Arrange grids vertically
        layoutDef.grids[HUNDREDS].grids.map((grid, gridIndex) => (
          <View key={gridIndex} style={{ flexDirection: 'column' }}>
            {
              // Arrange rows vertically
              grid.grid.map((row, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{
                    flexDirection: 'row'
                  }}
                >
                  {
                    // Arrange items horizontally - will be 'row' if there are any thousands
                    row.map((_item, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          width: scaledHundredWidth,
                          height: scaledHundredHeight,
                          margin: margin / 2,
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {hundredsObject.component}
                      </View>
                    ))
                  }
                </View>
              ))
            }
          </View>
        ))
      }
    </View>
  );

  // Add thousands
  const thousands = (
    <View style={{ flexDirection: 'column' }}>
      {
        // Arrange grids vertically
        layoutDef.grids[THOUSANDS].grids.map((grid, gridIndex) => (
          <View key={gridIndex} style={{ flexDirection: 'column' }}>
            {
              // Arrange rows vertically
              grid.grid.map((row, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{ flexDirection: allFlexDirectionRow ? 'row' : 'row-reverse' }}
                >
                  {
                    // Arrange items horizontally
                    row.map((_item, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          width: scaledThousandsWidth,
                          height: scaledThousandsHeight,
                          margin: margin / 2,
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {thousandsObject.component}
                      </View>
                    ))
                  }
                </View>
              ))
            }
          </View>
        ))
      }
    </View>
  );

  // Add ten thousands
  const tenThousands = (
    <View style={{ flexDirection: 'column' }}>
      {
        // Arrange grids vertically
        layoutDef.grids[TENTHOUSANDS].grids.map((grid, gridIndex) => (
          <View key={gridIndex} style={{ flexDirection: 'column' }}>
            {
              // Arrange rows vertically
              grid.grid.map((row, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{ flexDirection: allFlexDirectionRow ? 'row' : 'row-reverse' }}
                >
                  {
                    // Arrange items horizontally
                    row.map((_item, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          width: scaledTenThousandsWidth,
                          height: scaledTenThousandsHeight,
                          margin: margin / 2,
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {tenThousandsObject.component}
                      </View>
                    ))
                  }
                </View>
              ))
            }
          </View>
        ))
      }
    </View>
  );

  // Add hundred thousands
  const hundredThousands = (
    <View style={{ flexDirection: 'column' }}>
      {
        // Arrange grids vertically
        layoutDef.grids[HUNDREDTHOUSANDS].grids.map((grid, gridIndex) => (
          <View key={gridIndex} style={{ flexDirection: 'column' }}>
            {
              // Arrange rows vertically
              grid.grid.map((row, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{ flexDirection: allFlexDirectionRow ? 'row' : 'row-reverse' }}
                >
                  {
                    // Arrange items horizontally
                    row.map((_item, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          width: scaledHundredThousandsWidth,
                          height: scaledHundredThousandsHeight,
                          margin: margin / 2,
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {hundredThousandsObject.component}
                      </View>
                    ))
                  }
                </View>
              ))
            }
          </View>
        ))
      }
    </View>
  );

  return (
    <View
      style={[
        styles.baseTen,
        // Only fix the size if we were given an explicit scale, because we probably want to be justified the same as others.
        // Otherwise, don't set width and height - i.e. just wrap content. This makes it easier for our parent to center us.
        props.scale !== undefined &&
          !noFixedSize && {
            width: usableWidth - questionMargin * 2,
            height: usableHeight,
            margin: questionMargin / 2
          },
        containerStyle
      ]}
    >
      {numbers.hundredThousands ? hundredThousands : null}
      {numbers.tenThousands ? tenThousands : null}
      {numbers.thousands ? thousands : null}
      {numbers.hundreds ? hundreds : null}
      {numbers.tens ? tens : null}
      {numbers.ones ? ones : null}
    </View>
  );
};

export default BaseTenRepresentation;

const getStyles = (align: align) =>
  StyleSheet.create({
    baseTen: {
      flexDirection: 'row',
      display: 'flex',
      alignContent: 'center',
      alignItems: align,
      justifyContent: 'center'
    }
  });
