import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  Base10RepTo1000sVariant,
  Base10RepTo1000sVariantSchema,
  CounterVariantNoBlockSchema
} from '../../../../components/question/representations/types';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { randomIntegerInclusive } from '../../../../utils/random';
import {
  base10ObjectToNumber,
  numberOfNonZeroDigits,
  numberToBase10Object,
  powersOfTenPowToWord
} from '../../../../utils/math';
import { ScientificNotation } from '../../../../utils/math';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { arraysHaveSameContents, sumNumberArray } from '../../../../utils/collections';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ab0',
  description: 'ab0',
  keywords: ['Place value', 'Partition', 'Base 10', '10,000'],
  schema: z.object({
    variant: Base10RepTo1000sVariantSchema,
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    hundreds: z.number().int().min(1).max(9),
    thousands: z.number().int().min(1).max(9)
  }),
  questionHeight: 1440,
  example: {
    variant: 'Cubes',
    ones: 4,
    tens: 2,
    hundreds: 2,
    thousands: 1
  },
  simpleGenerator: () => {
    const variant = 'Cubes' as Base10RepTo1000sVariant;
    // Limit all units to 1-9
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    const thousands = randomIntegerInclusive(1, 9);
    return {
      variant,
      ones,
      tens,
      hundreds,
      thousands
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, thousands, variant },
      translate
    } = props;
    const number = base10ObjectToNumber({ ones, tens, hundreds, thousands });
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={answer =>
          parseInt(answer[0]) + parseInt(answer[1]) + parseInt(answer[2]) + parseInt(answer[3]) ===
            number &&
          // 0 is not acceptable as any of the answers on the second line.
          parseInt(answer[0]) !== 0 &&
          parseInt(answer[1]) !== 0 &&
          parseInt(answer[2]) !== 0 &&
          parseInt(answer[3]) !== 0
        }
        inputMaxCharacters={4}
        sentence={translate.answerSentences.numEquals4Ans(number)}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{
              variant: variant,
              numbers: { ones, tens, hundreds, thousands },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        pdfDirection="column"
        questionHeight={1440}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyCorrectAdditionWithNoZeros()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ab1',
  description: 'ab1',
  keywords: ['Place value', 'Partition', 'Counters', '10,000'],
  schema: z.object({
    variant: Base10RepTo1000sVariantSchema,
    number: z.number().int().min(1001).max(9999)
  }),
  questionHeight: 900,
  example: {
    variant: 'Counters',
    number: 1224
  },
  simpleGenerator: () => {
    const variant = 'Counters' as Base10RepTo1000sVariant;
    const number = randomIntegerInclusive(1001, 9999);
    return {
      number,
      variant
    };
  },
  Component: props => {
    const {
      question: { number, variant },
      translate
    } = props;

    const { ones, tens, hundreds, thousands } = numberToBase10Object(number);

    const numberToAnswerString = () => {
      switch (numberOfNonZeroDigits(number)) {
        case 4:
          return translate.answerSentences.numEquals4Ans(number);
        case 3:
          return translate.answerSentences.numEquals3Ans(number);
        case 2:
          return translate.answerSentences.numEquals2Ans(number);
        case 1:
          return translate.answerSentences.numEquals1Ans(number);
        default:
          return '';
      }
    };

    // Totals the parsed integers of all the strings from the answer boxes.
    const totalOfAnswer = (answer: string[]) => {
      const reducer = (a: number, b: string) => a + parseInt(b);
      return answer.reduce(reducer, 0);
    };

    const exampleAnswer = [
      (thousands ?? 0) * 1000,
      (hundreds ?? 0) * 100,
      (tens ?? 0) * 10,
      ones ?? 0
    ].filter(val => val !== 0);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={answer => totalOfAnswer(answer) === number}
        inputMaxCharacters={4}
        sentence={numberToAnswerString()}
        Content={({ dimens }) => {
          const scale = BaseTenRepCalcGridsAndScale(
            dimens.width,
            dimens.height,
            { ones, tens, hundreds, thousands },
            'Counters',
            2
          ).scale;
          return (
            <BaseTenRepresentation
              b10Rep={{
                variant: variant,
                numbers: { ones, tens, hundreds, thousands },
                arrangement: 'ltr'
              }}
              containerStyle={{ alignSelf: 'center', alignItems: 'flex-start' }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
              scale={Math.min(scale, 0.3)}
              allFlexDirectionRow={true}
              noFixedSize={true}
            />
          );
        }}
        pdfDirection="column"
        questionHeight={900}
        pdfSentenceStyle={{ justifyContent: 'flex-start' }}
        customMarkSchemeAnswer={{
          answersToDisplay: exampleAnswer.map(val => val.toLocaleString())
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ab2',
  description: 'ab2',
  keywords: ['Place value', 'Partition', 'Chart', '10,000'],
  schema: z.object({
    counterVariant: CounterVariantNoBlockSchema,
    number: z.number().int().min(1001).max(8800)
  }),
  simpleGenerator: () => {
    const counterVariant = 'greyCounter' as const;
    const number = randomIntegerInclusive(1001, 8800, {
      constraint: x => {
        const sci = ScientificNotation.fromNumber(x);
        const zeroCount = sci.digits.filter(v => v === 0).length;
        const limit = zeroCount > 1 ? 8 : 6;
        return sci.digits.every(i => i < limit);
      }
    });
    return {
      number,
      counterVariant
    };
  },
  Component: props => {
    const {
      question: { number, counterVariant },
      translate,
      displayMode
    } = props;

    const nonZeroesAmount = numberOfNonZeroDigits(number);

    const powerArray = ScientificNotation.fromNumber(number);

    const answer1: number[] = [];
    const answer2: number[] = [];

    let answerString1 = `${number.toLocaleString()} =`;
    let answerString2 = `${number.toLocaleString()} =`;

    let count = -1;
    // This generates the first line of answers, returning the correct answer boxes and words/punctuation
    powerArray.digits.forEach((value, index) => {
      const pow = (powerArray.e - index) as 0 | 1 | 2 | 3;

      // All of these should only perform if the digit is not 0; otherwise, we should remove an answer box
      if (value !== 0) {
        count++;
        answerString1 += ` <g><ans/> ${translate.powersOfTen[powersOfTenPowToWord[pow]](
          value
        )}</g>`;

        const answerToPush = (() => {
          switch (translate.powersOfTen[powersOfTenPowToWord[pow]](0)) {
            case 'thousands':
              return value * 1000;
            case 'hundreds':
              return value * 100;
            case 'tens':
              return value * 10;
            default:
              return value;
          }
        })();
        answer1.unshift(value);
        answer2.unshift(answerToPush);

        if (count < nonZeroesAmount - 2) {
          answerString1 += ',';
        }
        if (count === nonZeroesAmount - 2) {
          answerString1 += ' ' + translate.misc.and();
        }
      }
    });

    answerString1 += '.';

    // This generates the second line of answers, returning the correct amount of answer boxes.
    powerArray.digits
      .filter(val => val !== 0)
      .forEach((_value, index) => {
        if (index === 0) {
          answerString2 += ` <ans/>`;
        } else {
          answerString2 += ' + <ans/>';
        }
      });

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        testCorrect={answer =>
          arraysHaveSameContents(answer[0], answer1.map(x => x.toString()).reverse()) &&
          sumNumberArray(answer[1].map(x => parseInt(x))) === number
        }
        inputMaxCharacters={4}
        sentences={[answerString1, answerString2]}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[3, 2, 1, 0]}
            counterVariant={counterVariant}
            dimens={dimens}
            counterSize={displayMode === 'digital' ? undefined : 70}
            rowsToUse={4}
          />
        )}
        pdfDirection="column"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            answer1.map(x => x.toLocaleString()).reverse(),
            answer2.map(x => x.toLocaleString())
          ]
        }}
      />
    );
  },
  questionHeight: 1200
});

const Question3v2 = newQuestionContent({
  uid: 'ab22',
  description: 'ab2',
  keywords: ['Place value', 'Partition', 'Chart', '10,000'],
  schema: z.object({
    number: z.number().int().min(1001).max(8800)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1001, 8800, {
      constraint: x => {
        const sci = ScientificNotation.fromNumber(x);
        const zeroCount = sci.digits.filter(v => v === 0).length;
        const limit = zeroCount > 1 ? 8 : 6;
        return sci.digits.every(i => i < limit);
      }
    });
    return {
      number
    };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    const nonZeroesAmount = numberOfNonZeroDigits(number);

    const powerArray = ScientificNotation.fromNumber(number);

    const answer: number[] = [];
    let sentence = `${number.toLocaleString()} =`;

    let count = -1;
    // This generates the first line of answers, returning the correct answer boxes and words/punctuation
    powerArray.digits.forEach((value, index) => {
      const pow = (powerArray.e - index) as 0 | 1 | 2 | 3;

      // All of these should only perform if the digit is not 0; otherwise, we should remove an answer box
      if (value !== 0) {
        count++;
        sentence += ` <g><ans/> ${translate.powersOfTen[powersOfTenPowToWord[pow]](value)}</g>`;

        answer.unshift(value);

        if (count < nonZeroesAmount - 2) {
          sentence += ',';
        }
        if (count === nonZeroesAmount - 2) {
          sentence += ' ' + translate.misc.and();
        }
      }
    });

    sentence += '.';

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        testCorrect={answer.map(x => x.toString()).reverse()}
        inputMaxCharacters={4}
        sentence={sentence}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[3, 2, 1, 0]}
            counterVariant={'greyCounter'}
            dimens={dimens}
            counterSize={displayMode === 'digital' ? undefined : 70}
            rowsToUse={4}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

export const Question4 = newQuestionContent({
  uid: 'ab3',
  description: 'ab3',
  keywords: ['Place value', 'Partition', 'Part-whole', '10,000'],
  schema: z.object({
    number: z.number().int().min(1011).max(9999),
    powerToBeZero: z.union([z.literal(0), z.literal(1), z.literal(2)])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const powerToBeZero = randomIntegerInclusive(0, 2) as 0 | 1 | 2;
    const ones = powerToBeZero === 0 ? 0 : randomIntegerInclusive(1, 9);
    const tens = powerToBeZero === 1 ? 0 : randomIntegerInclusive(1, 9);
    const hundreds = powerToBeZero === 2 ? 0 : randomIntegerInclusive(1, 9);
    const thousands = randomIntegerInclusive(1, 9);
    const number = base10ObjectToNumber({ ones, tens, hundreds, thousands });
    return { number, powerToBeZero };
  },
  Component: ({ question, translate }) => {
    const { number } = question;

    const partition = ['$ans', '$ans', '$ans'];

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        initialState={['', '', '']}
        inputType="numpad"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={number}
            partition={partition}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            isInteractive
            dimens={dimens}
          />
        )}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer =>
          !answer.includes('0') && answer.reduce((sum, ans) => sum + parseInt(ans), 0) === number
        }
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationThatSumsToX(number)
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ab4',
  description: 'ab4',
  keywords: ['Place value', 'Partition', '10,000'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tens: z.number().int().min(0).max(9),
    hundreds: z.number().int().min(0).max(9),
    thousands: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    // Ones, tens and hundreds cannot be 1, but can be 0.
    const tens = randomIntegerInclusive(0, 9, { constraint: x => x !== 1 });
    const hundreds = randomIntegerInclusive(0, 9, { constraint: x => x !== 1 });
    const thousands = randomIntegerInclusive(1, 9);
    // if thoudands is 1 then ones must be greater than 1
    const min = thousands === 1 ? 2 : 0;
    const ones = randomIntegerInclusive(min, 9, {
      constraint: x => x !== 1
    });
    return { ones, tens, hundreds, thousands };
  },

  Component: props => {
    const {
      question: { ones, tens, hundreds, thousands },
      translate,
      displayMode
    } = props;
    const number = base10ObjectToNumber({ ones, tens, hundreds, thousands });

    const nonZeroesAmount = numberOfNonZeroDigits(number);

    const powerArray = ScientificNotation.fromNumber(number);

    const answer1: number[] = [];
    const answer2: number[] = [];

    let answerString1 = `${number.toLocaleString()} =`;
    let answerString2 = `${number.toLocaleString()} =`;

    // This generates the first line of answers, returning the correct answer boxes and words/punctuation
    let count = -1;
    powerArray.digits.forEach((value, index) => {
      const pow = (powerArray.e - index) as 0 | 1 | 2 | 3;
      // All of these should only perform if the digit is not 0; otherwise, we should remove an answer box
      if (value !== 0) {
        count++;
        answerString1 += ` <g><ans/> ${translate.powersOfTen[powersOfTenPowToWord[pow]](
          value
        )}</g>`;

        const answerToPush = (() => {
          switch (translate.powersOfTen[powersOfTenPowToWord[pow]](0)) {
            case 'thousands':
              return value * 1000;
            case 'hundreds':
              return value * 100;
            case 'tens':
              return value * 10;
            default:
              return value;
          }
        })();
        answer1.unshift(value);
        answer2.unshift(answerToPush);

        if (count < nonZeroesAmount - 2) {
          answerString1 += ',';
        }
        if (count === nonZeroesAmount - 2) {
          answerString1 += ' ' + translate.misc.and();
        }
      }
    });

    answerString1 += '.';

    // This generates the second line of answers, returning the correct amount of answer boxes.
    powerArray.digits
      .filter(val => val !== 0)
      .forEach((_value, index) => {
        if (index === 0) {
          answerString2 += ` <ans/>`;
        } else {
          answerString2 += ' + <ans/>';
        }
      });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        sentences={[answerString1, answerString2]}
        containerStyle={{ alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        questionHeight={900}
        inputMaxCharacters={4}
        textStyle={displayMode === 'digital' ? { fontSize: 32 } : undefined}
        testCorrect={answer =>
          arraysHaveSameContents(answer[0], answer1.map(x => x.toString()).reverse()) &&
          sumNumberArray(answer[1].map(x => parseInt(x))) === number
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            answer1.map(x => x.toLocaleString()).reverse(),
            answer2.map(x => x.toLocaleString())
          ],
          answerText: translate.markScheme.anyValidPartitionWithMatchingAdditionSentence()
        }}
      />
    );
  },
  questionHeight: 900
});

export const Question6 = newQuestionContent({
  uid: 'ab5',
  description: 'ab5',
  keywords: ['Place value', 'Partition', '10,000', 'Gattegno'],
  schema: z.object({
    number: z.number().int().min(1001).max(9999)
  }),
  example: {
    number: 2124
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1001, 9999);
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF12CreateGattegnoChart
        correctAnswer={number}
        rowsToShow={[3, 2, 1, 0]}
        title={translate.instructions.useGattegnotoShowNum(number.toLocaleString())}
        pdfTitle={translate.instructions.circleGattegnotoShowNum(number.toLocaleString())}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionNumbersTo10000',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
