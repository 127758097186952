import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive
} from '../../../../utils/random';
import { base10ObjectToNumber } from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  PartWholeModel,
  PartWholeModelWithState
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { isEqual } from '../../../../utils/matchers';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { countRange, filledArray } from '../../../../utils/collections';
import { View } from 'react-native';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdd',
  description: 'bdd',
  keywords: ['Tens and ones', 'Partition', 'Part', 'Whole'],
  schema: z.object({
    numberOfOnes: z.number().int().min(1).max(9),
    numberOfTens: z.number().int().min(2).max(4),
    variation: z.enum(['Straws', 'Cubes', 'tenFrame'])
  }),
  simpleGenerator: () => {
    const numberOfOnes = randomIntegerInclusive(1, 9);
    const numberOfTens = randomIntegerInclusive(2, 4);
    const variation = getRandomFromArray(['Straws', 'Cubes', 'tenFrame'] as const);

    return {
      numberOfOnes,
      numberOfTens,
      variation
    };
  },
  Component: props => {
    const {
      question: { numberOfOnes, numberOfTens, variation },
      translate
    } = props;

    const total = numberOfTens * 10 + numberOfOnes;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) =>
          variation === 'Straws' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Straws',
                numbers: { tens: numberOfTens, ones: numberOfOnes },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          ) : variation === 'Cubes' ? (
            <SimpleBaseTenWithCrossOut
              dimens={dimens}
              tens={numberOfTens}
              ones={numberOfOnes}
              onesPosition="top"
            />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              {countRange(Math.ceil(total / 10)).map(i => (
                <TenFrameLayout
                  items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                  orientation="vertical"
                  size="xsmall"
                  key={i}
                />
              ))}
            </View>
          )
        }
        sentence={translate.ks1AnswerSentences.ansIsAPartAndAnsIsAPartTheWholeIsAns()}
        testCorrect={userAnswer => {
          const [answerA, answerB, answerC] = userAnswer.map(str => parseInt(str));
          return answerA + answerB === answerC && answerC === total;
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (numberOfTens * 10).toLocaleString(),
            numberOfOnes.toLocaleString(),
            total.toLocaleString()
          ],
          answerText: translate.markScheme.anyValidPartitions()
        }}
        inputMaxCharacters={2}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bde',
  description: 'bde',
  keywords: ['Partition', 'Part-whole', 'Tens', 'Ones'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(2, 4);

    return { ones, tens };
  },
  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;

    const number = base10ObjectToNumber({ ones, tens });

    const partition = [(tens * 10).toLocaleString(), ones.toLocaleString()];

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.usePartWholeModelToCompleteSentence()}
        testCorrect={[tens.toString(), ones.toString()]}
        sentence={translate.ks1AnswerSentences.numEqualsAnsTensAnsOnes(number, tens, ones)}
        Content={({ dimens }) => (
          <PartWholeModel top={number.toLocaleString()} partition={partition} dimens={dimens} />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bdf',
  description: 'bdf',
  keywords: ['Partition', 'Part-whole', 'Tens', 'Ones'],
  schema: z.object({
    isTensLHS: z.boolean(),
    unitOfAnswer: z.enum(['tens', 'ones']),
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(2, 4);

    const isTensLHS = getRandomFromArrayWithWeights([true, false] as const, [4, 1]);

    const unitOfAnswer = getRandomFromArray(['tens', 'ones'] as const);

    return { ones, tens, isTensLHS, unitOfAnswer };
  },
  Component: props => {
    const {
      question: { ones, tens, isTensLHS, unitOfAnswer },
      translate,
      displayMode
    } = props;

    const number = base10ObjectToNumber({ ones, tens });

    let partition: (string | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'tens') {
      partition = isTensLHS ? ['$ans', ones.toLocaleString()] : [ones.toLocaleString(), '$ans'];
      correctAnswer = tens * 10;
    } else {
      partition = isTensLHS
        ? [(tens * 10).toLocaleString(), '$ans']
        : ['$ans', (tens * 10).toLocaleString()];
      correctAnswer = ones;
    }

    return (
      <QF3Content
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.completeThePartWholeModel()}
        Content={({ dimens }) => (
          <PartWholeModelWithState
            id="part-whole-model"
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
            testCorrect={isEqual([correctAnswer.toString()])}
            defaultState={displayMode === 'markscheme' ? [correctAnswer.toLocaleString()] : ['']}
          />
        )}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionIntoTensAndOnes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
