import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { findFactors } from '../../../../utils/factors';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numToCurrency } from '../../../../utils/money';
import { all, create, number } from 'mathjs';
import QF17aCompleteDoubleNumberLineDraggable from '../../../../components/question/questionFormats/QF17aCompleteDoubleNumberLineDraggable';
import { countRange, range } from '../../../../utils/collections';
import QF17cCompleteTheDoubleNumberLine from '../../../../components/question/questionFormats/QF17cCompleteTheDoubleNumberLine';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aTy',
  description: 'aTy',
  keywords: ['Proportion', 'Problem'],
  schema: z.object({
    object: z.enum(['Ruler', 'Pencil', 'Pen', 'Rubber', 'Crayon']),
    number1: z.number().int().min(5).max(30).step(5),
    incorrectAnswers: z.array(z.number().int().min(5).max(149)).length(3)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Ruler', 'Pencil', 'Pen', 'Rubber', 'Crayon'] as const);
    const number1 = randomIntegerInclusiveStep(5, 30, 5);
    const incorrectAnswers = randomUniqueIntegersInclusive(number1 + 1, number1 * 5, 3, {
      constraint: x => x !== number1 * 2 && x !== number1 * 3 && x !== number1 * 4 && x <= 149
    });
    return { object, number1, incorrectAnswers };
  },

  Component: props => {
    const {
      question: { object, number1, incorrectAnswers },
      translate
    } = props;

    const objectWordLowerCase = (() => {
      switch (object) {
        case 'Ruler':
          return translate.objects.ruler();
        case 'Pencil':
          return translate.objects.pencil();
        case 'Pen':
          return translate.objects.pen();
        case 'Rubber':
          return translate.objects.rubber();
        case 'Crayon':
          return translate.objects.crayon();
      }
    })();
    const answer1 = translate.units.numberOfP(number1 * 2);
    const answer2 = translate.units.numberOfP(number1 * 3);
    const answer3 = translate.units.numberOfP(number1 * 4);

    const items = shuffle(
      [
        answer1,
        answer2,
        answer3,
        translate.units.numberOfP(incorrectAnswers[0]),
        translate.units.numberOfP(incorrectAnswers[1]),
        translate.units.numberOfP(incorrectAnswers[2])
      ],
      {
        random: seededRandom(props.question)
      }
    );

    // Create array to pass to Number Line
    const bottomTickValues = range(0, number1 * 4, number1).map(i => `${i.toLocaleString()}p`);
    const topTickValues = range(0, 4);

    // Set where the answers should go
    range(2, 4).forEach(number => {
      bottomTickValues[number] = '<ans/>';
    });

    return (
      <QF17aCompleteDoubleNumberLineDraggable
        title={translate.instructions.xCostsYCompleteDoubleNumberLine(
          objectWordLowerCase,
          `${number1.toLocaleString()}p`
        )}
        pdfTitle={translate.instructions.xCostsYCompleteDoubleNumberLinePDF(
          objectWordLowerCase,
          `${number1.toLocaleString()}p`
        )}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={[answer1.toString(), answer2.toString(), answer3.toString()]}
        precedingLinesText={[objectWordLowerCase, translate.misc.cost()]}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aTz',
  description: 'aTz',
  keywords: ['Proportion', 'Problem'],
  schema: z.object({
    object: z.enum(['Ruler', 'Pencil', 'Pen', 'Rubber', 'Crayon']),
    number1: z.number().int().min(5).max(30).step(5),
    quantity: z.number().int().min(6).max(12),
    answerIndex: z.array(z.number().int().min(0).max(4)).length(3)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Ruler', 'Pencil', 'Pen', 'Rubber', 'Crayon'] as const);
    const number1 = randomIntegerInclusiveStep(5, 30, 5);
    const quantity = randomIntegerInclusive(6, 12);
    const answerIndex = randomUniqueIntegersInclusive(0, 4, 3);

    return { object, number1, quantity, answerIndex };
  },

  Component: props => {
    const {
      question: { object, number1, quantity, answerIndex },
      translate
    } = props;

    const objectWord = translate.objects[object]();
    const correctAnswer = `${(number1 * quantity).toLocaleString()}p`;
    const answer1 = `${(number1 + quantity).toLocaleString()}p`;
    const answer2 = `${(number1 * (quantity - 1)).toLocaleString()}p`;
    const answer3 = `${(number1 * (quantity + 1)).toLocaleString()}p`;
    const answer4 = `${((number1 - 1) * quantity).toLocaleString()}p`;
    const answer5 = `${((number1 + 1) * quantity).toLocaleString()}p`;
    const min = number1 * (quantity + 1);

    const allOptions = [answer1, answer2, answer3, answer4, answer5];

    const displayOptions = answerIndex.map(i => allOptions[i]);

    const items = shuffle([correctAnswer, ...displayOptions], {
      random: seededRandom(props.question)
    });

    // Create array to pass to Number Line
    const bottomTickValues = range(0, min - number1, number1).map((val, i) =>
      i < 2 ? `${val.toLocaleString()}p` : val === min - number1 ? `<ans />` : ''
    );

    const topTickValues = range(0, quantity).map(i =>
      i < 2 || i === quantity ? i.toLocaleString() : ''
    );

    return (
      <QF17aCompleteDoubleNumberLineDraggable
        title={translate.instructions.xCostsYCompleteDoubleNumberLine(
          objectWord,
          `${number1.toLocaleString()}p`
        )}
        pdfTitle={translate.instructions.xCostsYCompleteDoubleNumberLinePDF(
          objectWord,
          `${number1.toLocaleString()}p`
        )}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={[correctAnswer]}
        precedingLinesText={[objectWord, translate.misc.cost()]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2v2 = newQuestionContent({
  uid: 'aTz2',
  description: 'aTz',
  keywords: ['Proportion', 'Problem', 'Double number line'],
  schema: z.object({
    object: z.enum(['Ruler', 'Pencil', 'Pen', 'Rubber', 'Crayon']),
    number1: z.number().int().min(5).max(30).step(5),
    quantity: z.number().int().min(6).max(12)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Ruler', 'Pencil', 'Pen', 'Rubber', 'Crayon'] as const);
    const number1 = randomIntegerInclusiveStep(5, 30, 5);
    const quantity = randomIntegerInclusive(6, 12);

    return { object, number1, quantity };
  },

  Component: props => {
    const {
      question: { object, number1, quantity },
      translate
    } = props;

    const objectWordLowerCase = (() => {
      switch (object) {
        case 'Ruler':
          return translate.objects.ruler();
        case 'Pencil':
          return translate.objects.pencil();
        case 'Pen':
          return translate.objects.pen();
        case 'Rubber':
          return translate.objects.rubber();
        case 'Crayon':
          return translate.objects.crayon();
      }
    })();

    const correctAnswer = number1 * quantity;

    // Create array to pass to Number Line
    // for spacing lets mock it so for numbers greater than 9 the first value is at point 2
    const bottomTickValues = countRange(quantity + 1).map(val =>
      val === 0
        ? `${(number1 * val).toLocaleString()}p`
        : val === 1 && quantity < 9
        ? `${(number1 * val).toLocaleString()}p`
        : val === 2 && quantity >= 9
        ? `${(number1 * (val - 1)).toLocaleString()}p`
        : val === quantity
        ? `<ans />`
        : ''
    );

    const topTickValues = countRange(quantity + 1).map(i =>
      i === 0 || i === quantity
        ? i.toLocaleString()
        : i === 1 && quantity < 9
        ? i.toLocaleString()
        : i === 2 && quantity >= 9
        ? (i - 1).toLocaleString()
        : ''
    );

    return (
      <QF17cCompleteTheDoubleNumberLine
        title={translate.instructions.oneObjectCostsXCompleteDoubleNumberLine(
          objectWordLowerCase,
          number1
        )}
        topTickValues={topTickValues}
        bottomTickValues={bottomTickValues}
        testCorrect={[correctAnswer.toString()]}
        precedingLinesText={[objectWordLowerCase, translate.misc.costPence()]}
        questionHeight={700}
        inputTrailingSymbol="p"
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aTA',
  description: 'aTA',
  keywords: ['Proportion', 'Problem'],
  schema: z.object({
    object: z.enum(['Marble', 'Button', 'Bead', 'Shell', 'Pebble']),
    mass1: z.number().int().min(2).max(12),
    quantity1: z.number().int().min(1).max(3),
    quantity2: z.number().int().min(3).max(6),
    quantity3: z.number().int().min(5).max(12),
    quantity4: z.number().int().min(7).max(25),
    incorrectAnswers: z.array(z.number().int().min(1).max(25)).length(2)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Marble', 'Button', 'Bead', 'Shell', 'Pebble'] as const);
    const mass1 = randomIntegerInclusive(2, 12);
    const quantity1 = randomIntegerInclusive(2, 3);
    const quantity2 = randomIntegerInclusive(quantity1 + 2, 6);
    const quantity3 = randomIntegerInclusive(quantity2 + 2, 12);
    const quantity4 = randomIntegerInclusive(quantity3 + 2, 21);
    const incorrectAnswers = randomUniqueIntegersInclusive(1, 25, 2, {
      constraint: x =>
        ![quantity1, quantity2, quantity3, quantity4].includes(x) && !findFactors(x).includes(mass1)
    });

    return { object, mass1, quantity1, quantity2, quantity3, quantity4, incorrectAnswers };
  },

  Component: props => {
    const {
      question: { object, mass1, quantity1, quantity2, quantity3, quantity4, incorrectAnswers },
      translate
    } = props;

    const objectWordLowerCase = (() => {
      switch (object) {
        case 'Marble':
          return translate.objects.marble();
        case 'Button':
          return translate.objects.button();
        case 'Bead':
          return translate.objects.bead();
        case 'Shell':
          return translate.objects.shell();
        case 'Pebble':
          return translate.objects.pebble();
      }
    })();

    const answer1 = translate.units.numberOfG(number(math.evaluate(`${mass1} * ${quantity1}`)));
    const answer2 = quantity2.toLocaleString();
    const answer3 = translate.units.numberOfG(number(math.evaluate(`${mass1} * ${quantity3}`)));
    const answer4 = quantity4.toLocaleString();
    const answer5 = translate.units.numberOfG(number(math.evaluate(`${mass1} * ${quantity2}`)));
    const answer6 = incorrectAnswers[0].toLocaleString();
    const answer7 = translate.units.numberOfG(
      number(math.evaluate(`${mass1} * ${incorrectAnswers[1]}`))
    );

    const allOptions = [answer1, answer2, answer3, answer4, answer5, answer6, answer7];

    const items = shuffle(allOptions, {
      random: seededRandom(props.question)
    });

    // Create array to pass to Number Line
    const topTickValues = range(0, quantity4).map(i =>
      [0, quantity1, quantity3].includes(i)
        ? i.toLocaleString()
        : [quantity2, quantity4].includes(i)
        ? `<ans />`
        : ''
    );

    const bottomTickValues = range(0, quantity4).map(i =>
      [0, quantity4].includes(i)
        ? translate.units.numberOfG(number(math.evaluate(`${mass1} * ${i}`)))
        : [quantity1, quantity2, quantity3].includes(i)
        ? `<ans />`
        : ''
    );

    return (
      <QF17aCompleteDoubleNumberLineDraggable
        title={translate.instructions.eachXHasMassYDragToCompleteDoubleNumberLine(
          objectWordLowerCase,
          translate.units.numberOfG(mass1)
        )}
        pdfTitle={translate.instructions.eachXHasMassYDragToCompleteDoubleNumberLinePDF(
          objectWordLowerCase,
          translate.units.numberOfG(mass1)
        )}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={[
          quantity2.toLocaleString(),
          quantity4.toLocaleString(),
          answer1,
          answer5,
          answer3
        ]}
        precedingLinesText={[objectWordLowerCase, translate.misc.mass()]}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aTB',
  description: 'aTB',
  keywords: ['Proportion', 'Problem'],
  schema: z.object({
    object: z.enum(['Rulers', 'Pencils', 'Pens', 'Rubbers', 'Crayons']),
    quantity1: z.number().int().min(5).max(10),
    cost1: z.number().int().min(20).max(50).step(5),
    quantity2: z.number().int().min(2).max(9),
    incorrectAnswers: z.array(z.number().int().min(20).max(50).step(5)).length(2),
    answerIndex: z.array(z.number().int().min(2).max(5)).length(2)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Rulers', 'Pencils', 'Pens', 'Rubbers', 'Crayons'] as const);
    const quantity1 = randomIntegerInclusive(5, 10);
    const [cost1, incorrectAnswer1, incorrectAnswer2] = randomUniqueIntegersInclusiveStep(
      20,
      50,
      10,
      3
    );
    const quantity2 = randomIntegerInclusive(2, quantity1 - 1);
    const incorrectAnswers = [incorrectAnswer1, incorrectAnswer2];
    const answerIndex = randomUniqueIntegersInclusive(2, 5, 2);

    return { object, quantity1, quantity2, cost1, incorrectAnswers, answerIndex };
  },

  Component: props => {
    const {
      question: { object, quantity1, quantity2, cost1, incorrectAnswers, answerIndex },
      translate
    } = props;

    const objectWords = translate.objects[object]();

    const singularObjectWordLowerCase = (() => {
      switch (object) {
        case 'Rulers':
          return translate.objects.ruler();
        case 'Pencils':
          return translate.objects.pencil();
        case 'Pens':
          return translate.objects.pen();
        case 'Rubbers':
          return translate.objects.rubber();
        case 'Crayons':
          return translate.objects.crayon();
      }
    })();

    const displayCost = numToCurrency({
      amount: number(math.evaluate(`${quantity1} * ${cost1} / 100`))
    });
    const endNumber = number(math.evaluate(`${quantity2} * ${cost1}`));

    const answer1 = numToCurrency({
      amount: number(math.evaluate(`${quantity2} * ${cost1} /100`))
    });
    const answer2 = numToCurrency({ amount: cost1 / 100 });
    const answer3 = numToCurrency({ amount: incorrectAnswers[0] / 100 });
    const answer4 = numToCurrency({
      amount: number(math.evaluate(`${incorrectAnswers[0]} * ${quantity2} /100`))
    });
    const answer5 = numToCurrency({ amount: incorrectAnswers[1] / 100 });
    const answer6 = numToCurrency({
      amount: number(math.evaluate(`${incorrectAnswers[1]} * ${quantity2} /100`))
    });

    const allOptions = [answer1, answer2, answer3, answer4, answer5, answer6];
    // add first and second to optionsIndex
    const optionsIndex = [0, 1, ...answerIndex];
    const displayOptions = optionsIndex.map(i => allOptions[i]);
    const items = shuffle(displayOptions, {
      random: seededRandom(props.question)
    });

    // Create array to pass to Number Line
    const topTickValues = range(0, quantity2).map(i =>
      i < 2 || i === quantity2 ? i.toLocaleString() : ''
    );

    const bottomTickValues = range(0, endNumber, cost1).map((val, i) =>
      i === 0 ? `${i.toLocaleString()}` : val === endNumber || i === 1 ? `<ans />` : ''
    );

    return (
      <QF17aCompleteDoubleNumberLineDraggable
        title={translate.instructions.xObjectsCostsYHowMuchDoZObjectsCostCompleteDoubleNumberLine(
          quantity1.toLocaleString(),
          objectWords,
          displayCost,
          quantity2.toLocaleString()
        )}
        pdfTitle={translate.instructions.xObjectsCostsYHowMuchDoZObjectsCostCompleteDoubleNumberLinePdf(
          quantity1.toLocaleString(),
          objectWords,
          displayCost,
          quantity2.toLocaleString()
        )}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={[answer2, answer1]}
        precedingLinesText={[singularObjectWordLowerCase, translate.misc.cost()]}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question5 = newQuestionContent({
  uid: 'aTC',
  description: 'aTC',
  keywords: ['Proportion', 'Problem'],
  schema: z.object({
    object: z.enum(['Nails', 'Clips', 'Pins', 'Bolts']),
    seconds: z.number().int().min(2).max(6),
    quantity: z.number().int().min(20).max(300),
    perSecond: z.number().int().min(10).max(50).step(10),
    answerSeconds: z.number().int().min(6).max(60)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['Nails', 'Clips', 'Pins', 'Bolts'] as const);
    const seconds = randomIntegerInclusive(2, 6);

    const perSecond = randomIntegerInclusiveStep(10, 50, 10);
    const quantity = number(math.evaluate(`${seconds} * ${perSecond}`));

    const mult2 = randomIntegerInclusive(3, 10);
    const answerSeconds = number(math.evaluate(`${seconds} * ${mult2}`));

    return { object, seconds, quantity, answerSeconds, perSecond };
  },

  Component: props => {
    const {
      question: { object, seconds, quantity, answerSeconds, perSecond },
      translate
    } = props;

    const answer = number(math.evaluate(`${perSecond} * ${answerSeconds}`));
    const objectWords = translate.objects[object]();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.machineMakesXObjectsInYHowManyInZ(
          quantity.toLocaleString(),
          objectWords,
          seconds.toLocaleString(),
          answerSeconds.toLocaleString()
        )}
        testCorrect={[answer.toString()]}
        sentence={`<ans/>`}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aTD',
  description: 'aTD',
  keywords: ['Proportion', 'Problem'],
  schema: z
    .object({
      quantity: z.number().int().min(2).max(6),
      cost: z.number().min(1.1).max(1.9).step(0.1),
      answerQuantity: z.number().int().min(3).max(10)
    })
    .refine(
      val => !findFactors(val.answerQuantity).includes(val.quantity),
      'quantity should not be a factor of answer'
    ),
  simpleGenerator: () => {
    const quantity = randomIntegerInclusive(2, 6);
    const cost = randomIntegerInclusiveStep(110, 190, 10) / 100;
    const answerQuantity = randomIntegerInclusive(quantity + 1, 10, {
      constraint: x => !findFactors(x).includes(quantity)
    });

    return { quantity, cost, answerQuantity };
  },

  Component: props => {
    const {
      question: { quantity, cost, answerQuantity },
      translate
    } = props;

    const totalCost = number(math.evaluate(`${cost} * ${quantity}`));
    const answer = number(math.evaluate(`${cost} * ${answerQuantity}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.xLitresPetrolCostsYHowMuchZCost(
          quantity.toLocaleString(),
          numToCurrency({ amount: totalCost }),
          answerQuantity.toLocaleString()
        )}
        extraSymbol="decimalPoint"
        testCorrect={[answer.toFixed(2)]}
        sentence={`£<ans/>`}
        inputMaxCharacters={5}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ProportionProblems',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
