import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { createHundredSquareShape, rotateShape270 } from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import Text from '../../../../components/typography/Text';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { all, create, number } from 'mathjs';
import { range } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { compareFractions, simplify } from '../../../../utils/fractions';
import { DIV, MULT } from '../../../../constants';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVS',
  description: 'aVS',
  keywords: ['Fraction', 'Percentage', 'Equivalent', 'Hundred square', 'Hundredths', 'Convert'],
  schema: z.object({
    number1: z.number().int().min(1).max(100),
    topOrLeft: z.enum(['top', 'left'])
  }),
  simpleGenerator: () => {
    const multOf5 = getRandomFromArrayWithWeights([true, false] as const, [7, 1]);
    const number1 = multOf5
      ? getRandomBoolean()
        ? randomIntegerInclusiveStep(10, 100, 5)
        : randomIntegerInclusive(1, 9)
      : randomIntegerInclusive(1, 100);
    const topOrLeft = getRandomFromArray(['top', 'left'] as const);

    return { number1, topOrLeft };
  },
  Component: props => {
    const {
      question: { number1, topOrLeft },
      translate
    } = props;

    const shape =
      topOrLeft === 'top'
        ? createHundredSquareShape(number1)
        : rotateShape270(createHundredSquareShape(number1));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.convertFractionToPercentage()}
        testCorrect={[number1.toString()]}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
        sentence={`<frac n='${number1}' d='${100}' /> = <ans/> %`}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aVT',
  description: 'aVT',
  keywords: ['Fraction', 'Percentage', 'Equivalent', 'Hundred square', 'Hundredths', 'Convert'],
  schema: z.object({
    number1: z.number().int().min(1).max(100),
    number3: z.number().int().min(1).max(100),
    number4: z.number().int().min(1).max(100),
    topOrLeft: z.enum(['top', 'left'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 100, { constraint: x => x !== 50 });
    const [number3, number4] = randomUniqueIntegersInclusive(1, 100, 2, {
      constraint: x => x !== number1 && x !== 100 - number1
    });
    const topOrLeft = getRandomFromArray(['top', 'left'] as const);
    return { number1, number3, number4, topOrLeft };
  },
  Component: props => {
    const {
      question: { number1, number3, number4, topOrLeft },
      translate
    } = props;

    const number2 = 100 - number1;

    const statements = shuffle(
      [
        {
          sentence: `${number1}%`,
          value: 'A',
          correct: true
        },
        {
          sentence: `${number2}%`,
          value: 'B',
          correct: false
        },
        {
          sentence: `${number3}%`,
          value: 'C',
          correct: false
        },
        {
          sentence: `${number4}%`,
          value: 'D',
          correct: false
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const correctAnswers = statements.filter(it => it.correct);
    const shape =
      topOrLeft === 'top'
        ? createHundredSquareShape(number1)
        : rotateShape270(createHundredSquareShape(number1));

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectPercentageShownByXOnHundredSquare(
          `<frac n='${number1}' d='${100}'/>`
        )}
        pdfTitle={translate.instructions.circlePercentageShownByXOnHundredSquare(
          `<frac n='${number1}' d='${100}'/>`
        )}
        testCorrect={correctAnswers.map(it => it.value)}
        numItems={4}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
        mainPanelFlexDirection="row"
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {sentence}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aVT2',
  description: 'aVT',
  keywords: ['Fraction', 'Percentage', 'Equivalent', 'Hundred square', 'Hundredths', 'Convert'],
  schema: z.object({
    number1: z.number().int().min(1).max(100),
    shouldSimplify: z.boolean(),
    items: z.number().int().min(0).max(100).array().length(4),
    topOrLeft: z.enum(['top', 'left'])
  }),
  simpleGenerator: () => {
    const shouldSimplify = getRandomFromArrayWithWeights([true, false], [1, 5]);
    const number1 = shouldSimplify
      ? getRandomFromArray([10, 20, 25, 30, 40, 60, 70, 75, 80, 90])
      : randomIntegerInclusive(1, 100, { constraint: x => x !== 50 });
    const [number3, number4] = randomUniqueIntegersInclusive(1, 100, 2, {
      constraint: x => x !== number1 && x !== 100 - number1
    });

    const items = shuffle([number1, 100 - number1, number3, number4]);

    const topOrLeft = getRandomFromArray(['top', 'left'] as const);

    return { number1, topOrLeft, shouldSimplify, items };
  },
  Component: props => {
    const {
      question: { number1, topOrLeft, shouldSimplify, items },
      translate
    } = props;

    const statements = items.map((val, i) => ({
      sentence: `${val.toLocaleString()}%`,
      value: ['A', 'B', 'C', 'D'][i],
      correct: val === number1
    }));

    const correctAnswers = statements.filter(it => it.correct);
    const shape =
      topOrLeft === 'top'
        ? createHundredSquareShape(number1)
        : rotateShape270(createHundredSquareShape(number1));

    const [numerator, denominator] = shouldSimplify ? simplify(number1, 100) : [number1, 100];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectPercentageShownByXOnHundredSquare(
          `<frac n='${numerator}' d='${denominator}'/>`
        )}
        pdfTitle={translate.instructions.circlePercentageShownByXOnHundredSquare(
          `<frac n='${numerator}' d='${denominator}'/>`
        )}
        testCorrect={correctAnswers.map(it => it.value)}
        numItems={4}
        Content={({ dimens }) => <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />}
        mainPanelFlexDirection="row"
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {sentence}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aVU',
  description: 'aVU',
  keywords: ['Fraction', 'Percentage', 'Equivalent', 'Hundredths', 'Convert'],
  schema: z
    .object({
      denominatorA: numberEnum([10, 20, 25, 50]),
      numeratorA1: z.number().int().min(1).max(49),
      denominatorB: numberEnum([10, 20, 25, 50]),
      numeratorB1: z.number().int().min(1).max(49),
      fracAnswerBoxes: z.array(z.enum(['num1', 'num2', 'denom1', 'denom2'])).length(2)
    })
    .refine(val => val.numeratorA1 < val.denominatorA, 'numeratorA must be less than denominatorA')
    .refine(val => val.numeratorB1 < val.denominatorB, 'numeratorB must be less than denominatorB'),
  simpleGenerator: () => {
    const denominatorA = getRandomFromArray([10, 20, 25, 50] as const);
    const denominatorB = getRandomFromArray([10, 20, 25, 50] as const);

    const numeratorA1 = randomIntegerInclusive(1, denominatorA - 1);
    const numeratorB1 = randomIntegerInclusive(1, denominatorB - 1, {
      constraint: x => !compareFractions([numeratorA1, denominatorA], [x, denominatorB])
    });

    const fracAnswerBoxes = range(0, 1).map(() =>
      getRandomFromArray(['num1', 'num2', 'denom1', 'denom2'] as const)
    );

    return { denominatorA, denominatorB, numeratorA1, numeratorB1, fracAnswerBoxes };
  },

  Component: props => {
    const {
      question: { denominatorA, denominatorB, numeratorA1, numeratorB1, fracAnswerBoxes },
      translate
    } = props;

    const numeratorA2 = number(math.evaluate(`(${numeratorA1} / ${denominatorA}) * 100`));
    const numeratorB2 = number(math.evaluate(`(${numeratorB1} / ${denominatorB}) * 100`));

    let answerA;
    let sentenceA;
    let answerB;
    let sentenceB;
    switch (fracAnswerBoxes[0]) {
      case 'denom1': {
        answerA = denominatorA;
        sentenceA = `<frac n='${numeratorA1}' dAns=''/> = <frac n='${numeratorA2}' d='${100}' /> = <ans/> %`;
        break;
      }
      case 'denom2': {
        answerA = 100;
        sentenceA = `<frac n='${numeratorA1}' d='${denominatorA}' /> = <frac n='${numeratorA2}' dAns='' /> = <ans/> %`;
        break;
      }
      case 'num1': {
        answerA = numeratorA1;
        sentenceA = `<frac nAns='' d='${denominatorA}' /> = <frac n='${numeratorA2}' d='${100}' /> = <ans/> %`;
        break;
      }
      case 'num2': {
        answerA = numeratorA2;
        sentenceA = `<frac n='${numeratorA1}' d='${denominatorA}' /> = <frac nAns='' d='${100}' /> = <ans/> %`;
        break;
      }
    }

    switch (fracAnswerBoxes[1]) {
      case 'denom1': {
        answerB = denominatorB;
        sentenceB = `<frac n='${numeratorB1}' dAns=''/> = <frac n='${numeratorB2}' d='${100}' /> = <ans/> %`;
        break;
      }
      case 'denom2': {
        answerB = 100;
        sentenceB = `<frac n='${numeratorB1}' d='${denominatorB}' /> = <frac n='${numeratorB2}' dAns='' /> = <ans/> %`;
        break;
      }
      case 'num1': {
        answerB = numeratorB1;
        sentenceB = `<frac nAns='' d='${denominatorB}' /> = <frac n='${numeratorB2}' d='${100}' /> = <ans/> %`;
        break;
      }
      case 'num2': {
        answerB = numeratorB2;
        sentenceB = `<frac n='${numeratorB1}' d='${denominatorB}' /> = <frac nAns='' d='${100}' /> = <ans/> %`;
        break;
      }
    }

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={[
          [answerA.toString(), numeratorA2.toString()],
          [answerB.toString(), numeratorB2.toString()]
        ]}
        fractionContainerStyle={{ height: 96 }}
        sentences={[sentenceA, sentenceB]}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aVU2',
  description: 'aVU',
  keywords: ['Fraction', 'Percentage', 'Equivalent', 'Hundredths', 'Convert'],
  schema: z
    .object({
      denominatorA: numberEnum([10, 20, 25, 50]),
      numeratorA1: z.number().int().min(1).max(49),
      fracAnswerBoxes: z.enum(['num1', 'num2', 'denom1', 'denom2'])
    })
    .refine(val => val.numeratorA1 < val.denominatorA, 'numeratorA must be less than denominatorA'),
  simpleGenerator: () => {
    const denominatorA = getRandomFromArray([10, 20, 25, 50] as const);
    const numeratorA1 = randomIntegerInclusive(1, denominatorA - 1);

    const fracAnswerBoxes = getRandomFromArray(['num1', 'num2', 'denom1', 'denom2'] as const);

    return { denominatorA, numeratorA1, fracAnswerBoxes };
  },

  Component: props => {
    const {
      question: { denominatorA, numeratorA1, fracAnswerBoxes },
      translate
    } = props;

    const numeratorA2 = number(math.evaluate(`(${numeratorA1} / ${denominatorA}) * 100`));

    let answerA;
    let sentenceA;
    switch (fracAnswerBoxes) {
      case 'denom1': {
        answerA = denominatorA;
        sentenceA = `<frac n='${numeratorA1}' dAns=''/> = <frac n='${numeratorA2}' d='${100}' /> = <ans/> %`;
        break;
      }
      case 'denom2': {
        answerA = 100;
        sentenceA = `<frac n='${numeratorA1}' d='${denominatorA}' /> = <frac n='${numeratorA2}' dAns='' /> = <ans/> %`;
        break;
      }
      case 'num1': {
        answerA = numeratorA1;
        sentenceA = `<frac nAns='' d='${denominatorA}' /> = <frac n='${numeratorA2}' d='${100}' /> = <ans/> %`;
        break;
      }
      case 'num2': {
        answerA = numeratorA2;
        sentenceA = `<frac n='${numeratorA1}' d='${denominatorA}' /> = <frac nAns='' d='${100}' /> = <ans/> %`;
        break;
      }
    }

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumbers()}
        testCorrect={[answerA.toString(), numeratorA2.toString()]}
        fractionContainerStyle={{ height: 96 }}
        sentence={sentenceA}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question4 = newQuestionContent({
  uid: 'aVV',
  description: 'aVV',
  keywords: [
    'Fraction',
    'Percentage',
    'Equivalent',
    'Bar model',
    'Quarters',
    'Fifths',
    'Tenths',
    'Convert'
  ],
  schema: z
    .object({
      denominator: numberEnum([4, 5, 10]),
      numerator: z.number().int().min(2).max(9),
      fractionOrPercent: z.enum(['fraction', 'percent']),
      answerBoxIndex: z.number().int().min(0).max(2)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = getRandomFromArrayWithWeights([4, 5, 10] as const, [5, 4, 1]);
    const numerator = randomIntegerInclusive(2, denominator - 1);
    const fractionOrPercent = getRandomFromArray(['fraction', 'percent'] as const);
    const answerBoxIndex = randomIntegerInclusive(0, 2);

    return { denominator, numerator, fractionOrPercent, answerBoxIndex };
  },
  Component: ({
    question: { denominator, numerator, fractionOrPercent, answerBoxIndex },
    translate
  }) => {
    const percent = number(math.evaluate(`100/${denominator}`));
    const numbers = [
      [1],
      range(1, denominator).map(() => number(math.evaluate(`1/${denominator}`)))
    ];
    const strings = [
      [fractionOrPercent === 'fraction' ? '1' : '100%'],
      range(1, denominator).map(i =>
        i === 1
          ? fractionOrPercent === 'fraction'
            ? `<frac n='1' d='${denominator}' />`
            : `${percent}%`
          : ''
      )
    ];

    const percentAnswer = number(math.evaluate(`(${numerator}/${denominator})*100`));
    const answers = [numerator, denominator, percentAnswer];

    const sentence =
      answerBoxIndex === 0
        ? `<frac nAns='' d='${denominator}' /> = ${percentAnswer}%`
        : answerBoxIndex === 1
        ? `<frac n='${numerator}' dAns='' /> = ${percentAnswer}%`
        : `<frac n='${numerator}' d='${denominator}' /> = <ans/> %`;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.barModelShowsXIsEqualToYPercent(
          `<frac n='1' d='${denominator}' />`,
          percent.toLocaleString()
        )}<br/>${translate.instructions.useBarModelToCompleteStatement()}`}
        textStyle={{ fontSize: 40 }}
        Content={({ dimens }) => (
          <BarModel
            total={1}
            numbers={numbers}
            strings={strings}
            dimens={dimens}
            sameRowColor
            // Reduce margin of the dividing line so that numerators/denominators are not brushing against container edges:
            fractionDividerStyle={{ marginVertical: 4 }}
          />
        )}
        sentence={sentence}
        inputMaxCharacters={2}
        testCorrect={[answers[answerBoxIndex].toString()]}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aVV2',
  description: 'aVV',
  keywords: [
    'Fraction',
    'Percentage',
    'Equivalent',
    'Bar model',
    'Quarters',
    'Fifths',
    'Tenths',
    'Convert'
  ],
  schema: z
    .object({
      denominator: numberEnum([4, 5, 10]),
      numerator: z.number().int().min(2).max(9),
      answerBoxIndex: z.number().int().min(0).max(2)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = getRandomFromArrayWithWeights([4, 5, 10] as const, [5, 4, 1]);
    const numerator = randomIntegerInclusive(2, denominator - 1);
    const answerBoxIndex = randomIntegerInclusive(0, 2);

    return { denominator, numerator, answerBoxIndex };
  },
  Component: ({ question: { denominator, numerator, answerBoxIndex }, translate }) => {
    const multipliedNumerator = numerator * 2;

    const numbers = [
      range(1, 10).map(() => (multipliedNumerator / 10) * denominator),
      range(1, denominator).map(() => multipliedNumerator)
    ];

    const strings = [
      range(1, 10).map(() => '10%'),
      range(1, denominator).map(() => `<frac n='1' d='${denominator}' />`)
    ];

    const percentAnswer = number(math.evaluate(`(${numerator}/${denominator})*100`));
    const answers = [numerator, denominator, percentAnswer];

    const sentence =
      answerBoxIndex === 0
        ? `<frac nAns='' d='${denominator}' /> = ${percentAnswer}%`
        : answerBoxIndex === 1
        ? `<frac n='${numerator}' dAns='' /> = ${percentAnswer}%`
        : `<frac n='${numerator}' d='${denominator}' /> = <ans/> %`;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteStatement()}
        textStyle={{ fontSize: 40 }}
        Content={({ dimens }) => (
          <BarModel
            total={denominator * multipliedNumerator}
            numbers={numbers}
            strings={strings}
            dimens={dimens}
            sameRowColor
            // Reduce margin of the dividing line so that numerators/denominators are not brushing against container edges:
            fractionDividerStyle={{ marginVertical: 4 }}
          />
        )}
        sentence={sentence}
        inputMaxCharacters={2}
        pdfDirection="column"
        testCorrect={[answers[answerBoxIndex].toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aVW',
  description: 'aVW',
  keywords: ['Fraction', 'Percentage', 'Equivalent', 'Convert'],
  schema: z
    .object({
      numeratorA: z.number().min(1).max(49),
      numeratorB: z.number().min(1).max(49),
      numeratorC: z.number().min(1).max(49),
      denominatorA: numberEnum([2, 4, 5, 10, 20, 25, 50]),
      denominatorB: numberEnum([2, 4, 5, 10, 20, 25, 50]),
      denominatorC: numberEnum([2, 4, 5, 10, 20, 25, 50])
    })
    .refine(
      val =>
        !compareFractions([val.numeratorA, val.denominatorA], [val.numeratorB, val.denominatorB]) &&
        !compareFractions([val.numeratorA, val.denominatorA], [val.numeratorC, val.denominatorC]),
      'should be unique percentages'
    ),
  simpleGenerator: () => {
    const denominatorA = getRandomFromArray([2, 4, 5, 10, 20, 25, 50] as const);
    const denominatorB =
      denominatorA === 2
        ? getRandomFromArray([4, 5, 10, 20, 25, 50] as const)
        : getRandomFromArray([2, 4, 5, 10, 20, 25, 50] as const);
    const denominatorC =
      denominatorA === 2 || denominatorB === 2
        ? getRandomFromArray([4, 5, 10, 20, 25, 50] as const)
        : getRandomFromArray([2, 4, 5, 10, 20, 25, 50] as const);

    const { numeratorA, numeratorB, numeratorC } = rejectionSample(
      () => {
        const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
        const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
        const numeratorC = randomIntegerInclusive(1, denominatorC - 1);
        return { numeratorA, numeratorB, numeratorC };
      },
      val =>
        !compareFractions([val.numeratorA, denominatorA], [val.numeratorB, denominatorB]) &&
        !compareFractions([val.numeratorA, denominatorA], [val.numeratorC, denominatorC])
    );

    return { denominatorA, denominatorB, denominatorC, numeratorA, numeratorB, numeratorC };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, denominatorC, numeratorA, numeratorB, numeratorC },
      translate,
      displayMode
    } = props;

    const correctAnswerA = number(math.evaluate(`${numeratorA} / ${denominatorA} * 100`));
    const correctAnswerB = number(math.evaluate(`${numeratorB} / ${denominatorB} * 100`));
    const correctAnswerC = number(math.evaluate(`${numeratorC} / ${denominatorC} * 100`));

    const sentences = [
      {
        sentence: `<frac n='${numeratorA}' d='${denominatorA}'/> = <ans/>`,
        correctAnswer: `${correctAnswerA.toLocaleString()}%`
      },
      {
        sentence: `<frac n='${numeratorB}' d='${denominatorB}'/>  = <ans/>`,
        correctAnswer: `${correctAnswerB.toLocaleString()}%`
      },
      {
        sentence: `<frac n='${numeratorC}' d='${denominatorC}'/>  = <ans/>`,
        correctAnswer: `${correctAnswerC.toLocaleString()}%`
      }
    ];

    const items = shuffle(
      [
        `${correctAnswerA.toLocaleString()}%`,
        `${correctAnswerB.toLocaleString()}%`,
        `${correctAnswerC.toLocaleString()}%`
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchPercentagesToFractions()}
        pdfTitle={translate.instructions.useCardsToMatchPercentagesToFractions()}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ correctAnswer }) => [correctAnswer])}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        items={items}
        actionPanelVariant="end"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsRight"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aVX',
  description: 'aVX',
  keywords: [
    'Fraction',
    'Percentage',
    'Equivalent',
    'Hundredths',
    'Convert',
    'Multiplication',
    'Division',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    number1: z.number().int().min(4).max(1000),
    number2: z.number().int().min(1).max(999),
    number3: z.number().int().min(1).max(99),
    number4: z.number().int().min(2).max(25),
    answerBoxPosition: numberEnum([0, 1])
  }),
  simpleGenerator: () => {
    const multOrDiv = getRandomFromArrayWithWeights([MULT, DIV], [3, 1]);
    let number1;
    let number2;
    let number3;
    let number4;

    if (multOrDiv === MULT) {
      number1 = getRandomFromArray([4, 5, 10, 20, 25, 50] as const);
      number2 = randomIntegerInclusive(1, number1 - 1);
      number3 = number(math.evaluate(`(${number2} / ${number1}) * 100`));
      number4 = number(math.evaluate(`100 / ${number1}`));
    } else {
      number1 = randomIntegerInclusiveStep(200, 1000, 100);
      number3 = randomIntegerInclusiveStep(10, 90, 10);
      number4 = number(math.evaluate(`${number1} / 100`));
      number2 = number(math.evaluate(`${number3} * ${number4}`));
    }

    const answerBoxPosition = getRandomFromArray([0, 1] as const);

    return { number1, number2, number3, number4, answerBoxPosition };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, answerBoxPosition },
      translate
    } = props;

    const answerOptions = shuffle(
      [
        number1.toLocaleString(),
        number2.toLocaleString(),
        number3.toLocaleString(),
        (100).toLocaleString(),
        number4.toLocaleString()
      ],
      { random: seededRandom(props.question) }
    );

    const uniqueAnswerOptions = answerOptions.filter(
      (item, index) => answerOptions.indexOf(item) === index
    );

    const answers = [number3.toLocaleString(), (100).toLocaleString(), number3.toLocaleString()];

    const numerator2 = answerBoxPosition === 0 ? `nAns=''` : `n='${number3.toLocaleString()}'`;
    const denominator2 = answerBoxPosition === 1 ? `dAns=''` : `d='${(100).toLocaleString()}'`;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsConvertXToPercentage(
          `<frac n='${number2.toLocaleString()}' d='${number1.toLocaleString()}'/>`
        )}
        pdfTitle={translate.instructions.useCardsConvertXToPercentage(
          `<frac n='${number2.toLocaleString()}' d='${number1.toLocaleString()}'/>`
        )}
        items={uniqueAnswerOptions}
        fractionContainerStyle={{ height: 96 }}
        sentence={`<frac n='${number2.toLocaleString()}' d='${number1.toLocaleString()}' /> = <frac ${numerator2} ${denominator2} /> = <ans/> %`}
        testCorrect={[answers[answerBoxPosition], number3.toLocaleString()]}
        moveOrCopy="copy"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FractionsToPercentages',
  questionTypes: [Question1, Question2v2, Question3v2, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4, Question2, Question3]
});
export default SmallStep;
