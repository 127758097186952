// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MeasureAndClassifyAngles from './1MeasureAndClassifyAngles';
import CalculateAngles from './2CalculateAngles';
import VerticallyOppositeAngles from './3VerticallyOppositeAngles';
import AnglesInATriangle from './4AnglesInATriangle';
import AnglesInATriangleSpecialCases from './5AnglesInATriangleSpecialCases';
import AnglesInATriangleMissingAngles from './6AnglesInATriangleMissingAngles';
import AnglesInQuadrilaterals from './7AnglesInQuadrilaterals';
import AnglesInPolygons from './8AnglesInPolygons';
import Circles from './9Circles';
import DrawShapesAccurately from './10DrawShapesAccurately';
import NetsOf3DShapes from './11NetsOf3DShapes';

const Block = newBlockContent({
  block: 'Shape',
  field: 'Geometry',
  weeks: [1, 3],
  smallSteps: [
    MeasureAndClassifyAngles, // Step 1
    CalculateAngles, // Step 2
    VerticallyOppositeAngles, // Step 3
    AnglesInATriangle, // Step 4
    AnglesInATriangleSpecialCases, // Step 5
    AnglesInATriangleMissingAngles, // Step 6
    AnglesInQuadrilaterals, // Step 7
    AnglesInPolygons, // Step 8
    Circles, // Step 9
    DrawShapesAccurately, // Step 10
    NetsOf3DShapes // Step 11
  ]
});
export default Block;
