import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { QuadrilateralWithDimens } from '../../../../components/question/representations/QuadrilateralWithDimens';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import { isRectilinear } from '../../../../utils/shapes';
import { arraysHaveSameContents } from '../../../../utils/collections';
import { createMeasurement } from '../../../../utils/unitConversion';
import { isSquare } from '../../../../utils/factors';
import { MULT } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aR0',
  description: 'aR0',
  keywords: [
    'Area',
    'Centimetres',
    'Squared',
    'cm2',
    'Rectangle',
    'Square',
    'Multiplication',
    'Length',
    'Width',
    'Grid',
    'Squares'
  ],
  schema: z.object({
    length: z.number().int().min(1).max(7),
    width: z.number().int().min(1).max(7),
    measurement: z.enum(['cm', 'mm'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 7);
    const width = randomIntegerInclusive(1, 7);
    const measurement = getRandomFromArray(['cm', 'mm'] as const);

    return { length, width, measurement };
  },
  Component: props => {
    const {
      question: { length, width, measurement },
      translate
    } = props;

    const sentence = (() => {
      switch (measurement) {
        case 'mm':
          return translate.answerSentences.ansMmSquared();
        default:
          return translate.answerSentences.ansCmSquared();
      }
    })();

    const answer = length * width;
    const isSquare = length === width;

    const measurementString = `${createMeasurement(1, measurement).value.toLocaleString()} ${
      createMeasurement(1, measurement).suffix
    }`;

    return (
      <QF1ContentAndSentence
        sentence={sentence}
        title={`${translate.instructions.onTheGridTheAreaOfEachSquareIsX(measurementString)}²<br/>${
          isSquare
            ? translate.instructions.calcAreaOfSquare()
            : translate.instructions.calcAreaOfRectangle()
        }`}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={[
                [0, 0],
                [0, length],
                [width, length],
                [width, 0]
              ]}
              cellSizeLabel={measurementString}
              color={null}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aR1',
  description: 'aR1',
  keywords: [
    'Area',
    'Rectangle',
    'Square',
    'Multiplication',
    'Length',
    'Width',
    'Grid',
    'Squares',
    'Rows'
  ],
  schema: z.object({
    rows: z.number().int().min(1).max(6),
    columns: z.number().int().min(1).max(6)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(1, 6);
    const columns = randomIntegerInclusive(1, 6);

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate
    } = props;

    const answer = rows * columns;
    const isSquare = rows === columns;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsRowsEachRowHasAnsSquares(),
          `<ans/> ${MULT} <ans/> = <ans/>`
        ]}
        title={translate.instructions.completeSentenceToDescribeTheX(
          isSquare ? translate.keywords.Square() : translate.keywords.Rectangle()
        )}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          (arraysHaveSameContents(userAnswer[0], [rows.toString(), columns.toString()]) &&
            userAnswer[1][0] === rows.toString() &&
            userAnswer[1][1] === columns.toString()) ||
          (userAnswer[1][0] === columns.toString() &&
            userAnswer[1][1] === rows.toString() &&
            userAnswer[1][2] === answer.toString())
        }
        pdfDirection="column"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [rows.toLocaleString(), columns.toLocaleString()],
            [rows.toLocaleString(), columns.toLocaleString(), answer.toLocaleString()]
          ]
        }}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={[
                [0, 0],
                [0, rows],
                [columns, rows],
                [columns, 0]
              ]}
              color={null}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aR2',
  description: 'aR2',
  keywords: [
    'Area',
    'Rectangle',
    'Square',
    'Multiplication',
    'Length',
    'Width',
    'Squared',
    'Millimetres',
    'Centimetres',
    'Metres',
    'mm2',
    'cm2',
    'm2'
  ],
  schema: z.object({
    length: z.number().int().min(1).max(30),
    width: z.number().int().min(1).max(30),
    incorrectAnswerArray: z.array(z.number().min(1).max(30)).length(3),
    measurement: z.enum(['cm', 'mm', 'm'])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 30);
    const width = randomIntegerInclusive(1, 30, { constraint: x => x !== length });
    const incorrectAnswerArray = randomUniqueIntegersInclusive(1, 30, 3, {
      constraint: x => x !== length * width
    });
    const measurement = getRandomFromArray(['cm', 'mm', 'm'] as const);

    return { length, width, incorrectAnswerArray, measurement };
  },
  Component: props => {
    const {
      question: { length, width, incorrectAnswerArray, measurement },
      translate
    } = props;

    const answer = length * width;

    const selectables = shuffle(
      [
        [
          'A',
          `${createMeasurement(answer, measurement).value.toLocaleString()} ${
            createMeasurement(answer, measurement).suffix
          }²`
        ],
        [
          'B',
          `${createMeasurement(incorrectAnswerArray[0], measurement).value.toLocaleString()} ${
            createMeasurement(incorrectAnswerArray[0], measurement).suffix
          }²`
        ],
        [
          'C',
          `${createMeasurement(incorrectAnswerArray[1], measurement).value.toLocaleString()} ${
            createMeasurement(incorrectAnswerArray[1], measurement).suffix
          }²`
        ],
        [
          'D',
          `${createMeasurement(incorrectAnswerArray[2], measurement).value.toLocaleString()} ${
            createMeasurement(incorrectAnswerArray[2], measurement).suffix
          }²`
        ]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={`${translate.instructions.calcAreaOfRectangle()}<br/>${translate.instructions.selectCorrectAnswer()}`}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={['A']}
        leftContent={
          <MeasureView>
            {dimens => (
              <QuadrilateralWithDimens
                dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.8 }}
                x={width}
                y={length}
                widthLabel={`${createMeasurement(width, measurement).value} ${
                  createMeasurement(width, measurement).suffix
                }`}
                heightLabel={`${createMeasurement(length, measurement).value} ${
                  createMeasurement(length, measurement).suffix
                }`}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aR3',
  description: 'aR3',
  keywords: [
    'Area',
    'Rectangle',
    'Square',
    'Multiplication',
    'Length',
    'Width',
    'Squared',
    'Millimetres',
    'Centimetres',
    'Metres',
    'mm2',
    'cm2',
    'm2'
  ],
  schema: z.object({
    length: z.number().int().min(1).max(30),
    width: z.number().int().min(1).max(30),
    measurement: z.enum(['cm', 'mm', 'm'])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 30);
    const width = randomIntegerInclusive(1, 30, { constraint: x => x !== length });
    const measurement = getRandomFromArray(['cm', 'mm', 'm'] as const);

    return { length, width, measurement };
  },
  Component: props => {
    const {
      question: { length, width, measurement },
      translate
    } = props;

    const sentence = (() => {
      switch (measurement) {
        case 'mm':
          return translate.answerSentences.ansMmSquared();
        case 'cm':
          return translate.answerSentences.ansCmSquared();
        default:
          return translate.answerSentences.ansMSquared();
      }
    })();

    const rotate = getRandomFromArray([0, 10, -10], {
      random: seededRandom(props.question)
    });

    const answer = length * width;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheAreaOfTheRectangle()}
        testCorrect={[answer.toString()]}
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width * 0.65, height: dimens.height * 0.65 }}
            x={width}
            y={length}
            widthLabel={`${createMeasurement(width, measurement).value} ${
              createMeasurement(width, measurement).suffix
            }`}
            heightLabel={`${createMeasurement(length, measurement).value} ${
              createMeasurement(length, measurement).suffix
            }`}
            containerStyle={{
              transform: [{ rotate: `${rotate}deg` }]
            }}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aR4',
  description: 'aR4',
  keywords: [
    'Area',
    'Rectangle',
    'Square',
    'Multiplication',
    'Length',
    'Width',
    'Squared',
    'Millimetres',
    'Centimetres',
    'Metres',
    'mm2',
    'cm2',
    'm2'
  ],
  schema: z.object({
    length: z.number().int().min(1).max(20),
    width: z.number().int().min(1).max(20),
    measurement: z.enum(['cm', 'mm', 'm']),
    incorrectAnswerArray: z.array(z.number().min(1).max(20)).length(3),
    isAnswerWidth: z.boolean()
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 20);
    const width = randomIntegerInclusive(1, 20, { constraint: x => x !== length });
    const measurement = getRandomFromArray(['cm', 'mm', 'm'] as const);
    const incorrectAnswerArray = randomUniqueIntegersInclusive(1, 20, 3, {
      constraint: x => x !== length && x !== width
    });
    const isAnswerWidth = getRandomBoolean();

    return { length, width, measurement, incorrectAnswerArray, isAnswerWidth };
  },
  Component: props => {
    const {
      question: { length, width, measurement, incorrectAnswerArray, isAnswerWidth },
      translate
    } = props;

    const convertedIncorrectAnswers = incorrectAnswerArray.map(
      num =>
        `${createMeasurement(num, measurement).value.toLocaleString()} ${
          createMeasurement(num, measurement).suffix
        }`
    );

    const area = `${createMeasurement(width * length, measurement).value.toLocaleString()} ${
      createMeasurement(width * length, measurement).suffix
    }²`;

    const answer = isAnswerWidth
      ? `${createMeasurement(width, measurement).value} ${
          createMeasurement(width, measurement).suffix
        }`
      : `${createMeasurement(length, measurement).value} ${
          createMeasurement(length, measurement).suffix
        }`;

    const answerOptions = shuffle([...convertedIncorrectAnswers, answer], {
      random: seededRandom(props.question)
    });
    const isSquare = length === width;

    return (
      <QF36ContentAndSentenceDrag
        title={`${translate.instructions.theAreaOfTheXIsY(
          isSquare ? translate.keywords.Square() : translate.keywords.Rectangle(),
          area
        )}<br/>${translate.instructions.dragCardsToCompleteSentence()}`}
        pdfTitle={`${translate.instructions.theAreaOfTheXIsY(
          isSquare ? translate.keywords.Square() : translate.keywords.Rectangle(),
          area
        )}<br/>${translate.instructions.useCardsCompleteSentence()}`}
        items={answerOptions}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        pdfLayout="itemsBottom"
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width * 0.7, height: dimens.height * 0.7 }}
            x={width}
            y={length}
            widthLabel={
              isAnswerWidth
                ? '?'
                : `${createMeasurement(width, measurement).value} ${
                    createMeasurement(width, measurement).suffix
                  }`
            }
            heightLabel={
              isAnswerWidth
                ? `${createMeasurement(length, measurement).value} ${
                    createMeasurement(length, measurement).suffix
                  }`
                : '?'
            }
          />
        )}
        sentence={`? = <ans />`}
        testCorrect={[answer.toString()]}
        questionHeight={1100}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aR5',
  description: 'aR5',
  keywords: [
    'Length',
    'Width',
    'Centimetres',
    'cm',
    'Millimetres',
    'mm',
    'Perimeter',
    'Addition',
    'Repeated addition',
    'Multiplication',
    'Double',
    'Rectangle',
    'Square'
  ],
  schema: z.object({
    length: z.number().int().min(1).max(14),
    width: z.number().int().min(1).max(7),
    measurement: z.enum(['cm', 'mm'])
  }),
  simpleGenerator: () => {
    const length = randomIntegerInclusive(1, 14);
    const width = randomIntegerInclusive(1, 7);
    const measurement = getRandomFromArray(['cm', 'mm'] as const);

    return { length, width, measurement };
  },
  Component: props => {
    const {
      question: { length, width, measurement },
      translate
    } = props;

    const area = length * width;

    const measurementString = `${createMeasurement(area, measurement).value.toLocaleString()} ${
      createMeasurement(area, measurement).suffix
    }²`;

    const isShape = isSquare(area);

    return (
      <QF24CreateShapeFromSquares
        title={`${translate.instructions.createXThatHasAreaOfY(
          isShape ? translate.keywords.Square() : translate.keywords.Rectangle(),
          measurementString
        )}`}
        numberOfRows={7}
        numberOfCols={14}
        cellSizeLabel={`${createMeasurement(1, measurement).value.toLocaleString()} ${
          createMeasurement(1, measurement).suffix
        }`}
        testCorrect={userAnswer => {
          const rowsSelected = userAnswer.filter(el => el.includes(true));
          const countTrueItems = rowsSelected.map(
            subArray => subArray.filter(item => item === true).length
          );
          const expectedAmountSelectedPerRow = area / rowsSelected.length;
          const isSelectedAmountCorrectEveryRow = countTrueItems.every(
            value => value === expectedAmountSelectedPerRow
          );
          return isSelectedAmountCorrectEveryRow && isRectilinear(userAnswer);
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithAreaOfX(measurementString)
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AreaOfRectangles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
