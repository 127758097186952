import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { randomIntegerInclusive } from '../../../../utils/random';
import { z } from 'zod';
import { getRandomName, getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { getRandomShopObject, shopObjectAsWord, shopObjectSchema } from '../../../../utils/objects';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import { BarModelWithState } from '../../../../components/question/representations/BarModel';
import AutoScaleText from '../../../../components/typography/AutoScaleText';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ahI',
  description: 'ahI',
  keywords: ['Addition', 'Subtraction', 'Bar model'],
  schema: z
    .object({
      name: nameSchema,
      number1: z.number().int().min(101).max(898),
      number2: z.number().int().min(101).max(999)
    })
    .refine(val => val.number1 + val.number2 < 1000, 'number1 + number2 must not exceed 1000'),
  simpleGenerator: () => {
    const name = getRandomName();

    const number1 = randomIntegerInclusive(101, 898);
    const number2 = randomIntegerInclusive(101, 999 - number1);

    return { name, number1, number2 };
  },
  Component: ({ question: { name, number1, number2 }, translate, displayMode }) => {
    const number3 = number1 + number2;

    const numbers = [[number3], [number1, number2]];

    const answerIndices = [[0], []];

    return (
      <QF3Content
        title={translate.instructions.calculatePlayerTotalScore(
          name,
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        inputType="numpad"
        Content={({ dimens }) => (
          <BarModelWithState
            id="barmodel"
            numbers={numbers}
            answerIndices={answerIndices}
            total={number3}
            dimens={dimens}
            rowHeight={displayMode === 'digital' ? 100 : 150}
            testComplete={answer => answer[0][0] !== ''}
            testCorrect={answer => answer[0][0] === number3.toString()}
            defaultState={displayMode === 'markscheme' ? [[number3.toLocaleString()]] : undefined}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ahJ',
  description: 'ahJ',
  keywords: ['Addition', 'Subtraction', 'Bar model'],
  schema: z
    .object({
      numberToSell: z.number().int().min(101).max(898),
      cookiesLeftover: z.number().int().min(101).max(898)
    })
    .refine(
      val => val.numberToSell + val.cookiesLeftover < 1000,
      'numbers must not sum to more than 1000'
    )
    .refine(
      val => val.numberToSell - val.cookiesLeftover > 100,
      'unknown value must always be more than 100'
    ),
  simpleGenerator: () => {
    const numberToSell = randomIntegerInclusive(202, 898);
    const cookiesLeftover = randomIntegerInclusive(101, numberToSell - 101, {
      constraint: x => x + numberToSell < 1000
    });

    return { numberToSell, cookiesLeftover };
  },
  Component: ({ question: { numberToSell, cookiesLeftover }, translate, ...props }) => {
    const totalCookies = numberToSell + cookiesLeftover;

    const numbers = [[totalCookies], [numberToSell, cookiesLeftover]];

    const strings = [['<ans/>'], ['<ans/>', '<ans/>']];

    const answerIndices = [[0], [0, 1]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.bakerBakesCookiesAndSellsThem(totalCookies, numberToSell)}
        numbers={numbers}
        strings={strings}
        answerIndices={answerIndices}
        total={totalCookies}
        oneFontSize
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ahK',
  description: 'ahK',
  keywords: ['Addition', 'Subtraction', 'Bar model'],
  schema: z.object({
    difference: z.number().int().min(10).max(99),
    busAPeople: z.number().int().min(101).max(299)
  }),
  simpleGenerator: () => {
    const difference = randomIntegerInclusive(10, 99);
    const busAPeople = randomIntegerInclusive(101, 299);

    return { busAPeople, difference };
  },
  Component: ({ question: { busAPeople, difference }, translate, displayMode }) => {
    const busBPeople = busAPeople - difference;

    const barModelANums = [[busAPeople]];
    const barModelAStrings = [['<ans/>']];

    const barModelBNums = [[busBPeople, difference]];
    const barModelBStrings = [['<ans/>']];

    const answerIndices = [[0]];
    const arrowIndices = [[1]];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.busATravelsXMilesBusATravelsYMilesFurtherThanBusB(
          busAPeople,
          difference
        )}
        Content={({ dimens }) => (
          <View style={{ rowGap: 8 }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: displayMode === 'digital' ? 16 : 32
              }}
            >
              <Text
                variant={'WRN400'}
                style={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  width: dimens.width * 0.15,
                  textAlign: 'right'
                }}
              >
                {translate.misc['Bus A']()}
              </Text>
              <BarModelWithState
                id="bar-model-A"
                numbers={barModelANums}
                strings={barModelAStrings}
                answerIndices={answerIndices}
                total={busAPeople}
                dimens={{ height: dimens.height / 2, width: dimens.width * 0.8 }}
                defaultState={
                  displayMode === 'markscheme' ? [[busAPeople.toLocaleString()]] : undefined
                }
                testCorrect={userAnswer => userAnswer[0][0] === busAPeople.toString()}
                testComplete={userAnswer => userAnswer[0][0] !== ''}
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: displayMode === 'digital' ? 16 : 32
              }}
            >
              <Text
                variant={'WRN400'}
                style={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  width: dimens.width * 0.15,
                  textAlign: 'right'
                }}
              >
                {translate.misc['Bus B']()}
              </Text>
              <BarModelWithState
                id="bar-model-B"
                numbers={barModelBNums}
                strings={barModelBStrings}
                answerIndices={answerIndices}
                arrowIndices={arrowIndices}
                total={busAPeople}
                dimens={{ height: dimens.height / 2, width: dimens.width * 0.8 }}
                defaultState={
                  displayMode === 'markscheme' ? [[busBPeople.toLocaleString()]] : undefined
                }
                testCorrect={userAnswer => userAnswer[0][0] === busBPeople.toString()}
                testComplete={userAnswer => userAnswer[0][0] !== ''}
              />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.howFarDidTheBusesTravelInTotal()}
        testCorrect={[(busAPeople + busBPeople).toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'ahL',
  description: 'ahL',
  keywords: ['Addition', 'Subtraction', 'Bar model', 'Problem'],
  schema: z.object({
    difference: z.number().int().min(10).max(99),
    nameAMoney: z.number().int().min(101).max(299),
    nameA: nameSchema,
    nameB: nameSchema
  }),
  simpleGenerator: () => {
    const difference = randomIntegerInclusive(10, 99);
    const nameAMoney = randomIntegerInclusive(101, 299);

    const [nameA, nameB] = getRandomUniqueNames(2);

    return { difference, nameAMoney, nameA, nameB };
  },
  Component: ({ question: { difference, nameAMoney, nameA, nameB }, translate, displayMode }) => {
    const nameBMoney = nameAMoney - difference;

    // Bar models are single row bar models to allow for a gap in between them
    const barModelANums = [[nameAMoney]];
    const barModelAStrings = [['<ans/>']];

    const barModelBNums = [[nameBMoney, difference]];
    const barModelBStrings = [['<ans/>']];

    const answerIndices = [[0]];
    const arrowIndices = [[1]];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterAHasXInBankCharacterAHasYMoreThanCharacterB({
          nameA,
          nameB,
          nameAMoney,
          difference
        })}
        Content={({ dimens }) => (
          <View style={{ rowGap: 8 }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: displayMode === 'digital' ? 16 : 32
              }}
            >
              <Text
                variant={'WRN400'}
                style={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  width: dimens.width * 0.15,
                  textAlign: 'right'
                }}
              >
                {nameA}
              </Text>
              <BarModelWithState
                id="bar-model-A"
                numbers={barModelANums}
                strings={barModelAStrings}
                answerIndices={answerIndices}
                total={nameAMoney}
                dimens={{ height: dimens.height / 2, width: dimens.width * 0.8 }}
                testCorrect={userAnswer => userAnswer[0][0] === nameAMoney.toString()}
                testComplete={userAnswer => userAnswer[0][0] !== ''}
                defaultState={
                  displayMode === 'markscheme' ? [[nameAMoney.toLocaleString()]] : undefined
                }
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: displayMode === 'digital' ? 16 : 32
              }}
            >
              <Text
                variant={'WRN400'}
                style={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  width: dimens.width * 0.15,
                  textAlign: 'right'
                }}
              >
                {nameB}
              </Text>
              <BarModelWithState
                id="bar-model-B"
                numbers={barModelBNums}
                strings={barModelBStrings}
                answerIndices={answerIndices}
                arrowIndices={arrowIndices}
                total={nameAMoney}
                dimens={{ height: dimens.height / 2, width: dimens.width * 0.8 }}
                testCorrect={userAnswer => userAnswer[0][0] === nameBMoney.toString()}
                testComplete={userAnswer => userAnswer[0][0] !== ''}
                defaultState={
                  displayMode === 'markscheme' ? [[nameBMoney.toLocaleString()]] : undefined
                }
              />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.howMuchMoneyDoTheyHaveInTotal()}
        testCorrect={[(nameAMoney + nameBMoney).toString()]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'ahM',
  description: 'ahM',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z.object({
    number1: z.number().int().min(101).max(299),
    number2: z.number().int().min(10).max(99),
    number3: z.number().int().min(10).max(99),
    object: shopObjectSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 299);

    const number2 = randomIntegerInclusive(10, 99);

    const number3 = randomIntegerInclusive(10, 99);

    const object = getRandomShopObject();

    return { number1, number2, number3, object };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, object },
      translate
    } = props;

    const objectAsString = shopObjectAsWord(object, translate);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        // Specifically requested to not have an instruction here.
        title={''}
        testCorrect={[(number1 + number3 - number2).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansObject(objectAsString)}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'flex-start' }]}>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={50}
              group="sentence"
              containerStyle={{ height: 75, width: dimens.width }}
            >
              {translate.answerSentences.aShopHasNumXOfY1(number1.toLocaleString(), objectAsString)}
            </AutoScaleText>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={50}
              group="sentence"
              containerStyle={{ height: 75, width: dimens.width }}
            >
              {translate.answerSentences.aShopHasNumXOfY2(number2.toLocaleString(), objectAsString)}
            </AutoScaleText>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={50}
              group="sentence"
              containerStyle={{ height: 75, width: dimens.width }}
            >
              {translate.answerSentences.aShopHasNumXOfY3(number3.toLocaleString(), objectAsString)}
            </AutoScaleText>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={50}
              group="sentence"
              containerStyle={{ height: 75, width: dimens.width }}
            >
              {translate.answerSentences.aShopHasNumXOfY4(objectAsString)}
            </AutoScaleText>
          </View>
        )}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'ahM2',
  description: 'ahM2',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z.object({
    number1: z.number().int().min(101).max(299),
    number2: z.number().int().min(10).max(99),
    number3: z.number().int().min(10).max(99),
    object: shopObjectSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 299);

    const number2 = randomIntegerInclusive(10, 99);

    const number3 = randomIntegerInclusive(10, 99);

    const object = getRandomShopObject();

    return { number1, number2, number3, object };
  },

  Component: props => {
    const {
      question: { number1, number2, number3, object },
      translate
    } = props;

    const objectAsString = shopObjectAsWord(object, translate);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aShopHasNumOfItemNumItemsAreSoldNumNewItemsArriveHowManyItemsAreInTheShopNow(
          number1,
          objectAsString,
          number2,
          number3
        )}
        testCorrect={[(number1 + number3 - number2).toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansObjectFullStop(objectAsString)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ahN',
  description: 'ahN',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z.object({
    name: nameSchema,
    number1: z.number().int().min(51).max(99),
    number2: z.number().int().min(101).max(199),
    number3: z.number().int().min(10).max(149),
    number4: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const number1 = randomIntegerInclusive(51, 99);

    const number2 = randomIntegerInclusive(101, 199);

    const number3 = randomIntegerInclusive(10, 149);

    const number4 = randomIntegerInclusive(11, 99);

    return { name, number1, number2, number3, number4 };
  },

  Component: props => {
    const {
      question: { name, number1, number2, number3, number4 },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        // Specifically requested to not have an instruction here.
        title={''}
        testCorrect={[(number1 + number2 - number3 + number4).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ marginTop: 32, justifyContent: 'center' }}
        sentence={'<ans/>'}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'flex-start' }]}>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={46}
              group="sentence"
              containerStyle={{ height: 70, width: dimens.width }}
            >
              {translate.answerSentences.characterStartsWithTheNumberX1(
                name,
                number1.toLocaleString()
              )}
            </AutoScaleText>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={46}
              group="sentence"
              containerStyle={{ height: 70, width: dimens.width }}
            >
              {translate.answerSentences.characterStartsWithTheNumberX2(
                name,
                number2.toLocaleString()
              )}
            </AutoScaleText>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={46}
              group="sentence"
              containerStyle={{ height: 70, width: dimens.width }}
            >
              {translate.answerSentences.characterStartsWithTheNumberX3(
                name,
                number3.toLocaleString()
              )}
            </AutoScaleText>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={46}
              group="sentence"
              containerStyle={{ height: 70, width: dimens.width }}
            >
              {translate.answerSentences.characterStartsWithTheNumberX4(
                name,
                number4.toLocaleString()
              )}
            </AutoScaleText>
            <AutoScaleText
              variant="WRN400"
              minFontSize={30}
              maxFontSize={46}
              group="sentence"
              containerStyle={{ height: 70, width: dimens.width }}
            >
              {translate.answerSentences.characterStartsWithTheNumberX5(name)}
            </AutoScaleText>
          </View>
        )}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'ahN2',
  description: 'ahN2',
  keywords: ['Addition', 'Subtraction', 'Problem'],
  schema: z.object({
    name: nameSchema,
    number1: z.number().int().min(51).max(99),
    number2: z.number().int().min(101).max(199),
    number3: z.number().int().min(10).max(149),
    number4: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const number1 = randomIntegerInclusive(51, 99);

    const number2 = randomIntegerInclusive(101, 199);

    const number3 = randomIntegerInclusive(10, 149);

    const number4 = randomIntegerInclusive(11, 99);

    return { name, number1, number2, number3, number4 };
  },

  Component: props => {
    const {
      question: { name, number1, number2, number3, number4 },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterStartsWithNumCharAddsNumCharSubtractsNumCharAddsNumWhatNumDoesCharEndWith(
          name,
          number1,
          number2,
          number3,
          number4
        )}
        testCorrect={[(number1 + number2 - number3 + number4).toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        sentence={'<ans/>'}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeDecisions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
