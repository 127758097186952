import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange,
  filledArray
} from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import { chunk } from '../../../../utils/chunk';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Table from '../../../../components/molecules/Table';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import { Dimens } from '../../../../theme/scaling';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'beC',
  description: 'beC',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z
    .object({
      numberOfCounters: numberEnum([8, 12, 16]),
      options: z
        .array(z.array(z.number().int().min(1)))
        .length(4)
        .refine(
          val => arrayHasNoDuplicates(val, arraysHaveSameContentsUnordered),
          'must not have duplicate options'
        )
    })
    .refine(
      val =>
        val.options.some(option =>
          arraysHaveSameContentsUnordered(option, [
            val.numberOfCounters / 4,
            val.numberOfCounters / 4,
            val.numberOfCounters / 4,
            val.numberOfCounters / 4
          ])
        ),
      'options must have at least 1 correct answer'
    ),
  simpleGenerator: () => {
    const numberOfCounters = getRandomFromArray([8, 12, 16] as const);

    const correctAnswer = [
      numberOfCounters / 4,
      numberOfCounters / 4,
      numberOfCounters / 4,
      numberOfCounters / 4
    ];

    const options = shuffle([
      correctAnswer,
      [numberOfCounters / 2, numberOfCounters / 2],
      [numberOfCounters],
      // 4 numbers, made up of 2 pairs which sum to numberOfCounters / 2, but not a correct answer
      rejectionSample(
        () => {
          const a = randomIntegerInclusive(1, numberOfCounters / 2 - 1);
          const b = numberOfCounters / 2 - a;
          const c = randomIntegerInclusive(1, numberOfCounters / 2 - 1);
          const d = numberOfCounters / 2 - c;
          return [a, b, c, d];
        },
        val => !arraysHaveSameContentsUnordered(val, correctAnswer)
      )
    ]);

    return { numberOfCounters, options };
  },
  Component: props => {
    const {
      question: { numberOfCounters, options },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheGroupThatShowsX(translate.fractions.quarters(2))}
        pdfTitle={translate.ks1PDFInstructions.circleTheGroupThatShowsX(
          translate.fractions.quarters(2)
        )}
        testCorrect={[
          [numberOfCounters / 4, numberOfCounters / 4, numberOfCounters / 4, numberOfCounters / 4]
        ]}
        numItems={4}
        renderItems={({ dimens }) => {
          return options.map(option => ({
            value: option,
            component: (
              <View
                style={{
                  flexDirection: 'row',
                  maxWidth: numberOfCounters === 12 ? dimens.width * 0.8 : dimens.width,
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {option.map((group, idx) => {
                  return (
                    <View
                      style={{
                        borderColor: colors.prussianBlue,
                        borderWidth: 3,
                        borderRadius: 12,
                        flexDirection: group > 8 ? 'column' : 'row',
                        margin: 1,
                        paddingTop: 2
                      }}
                      key={`group_${idx}`}
                    >
                      {(() => {
                        if (group > 8 && option.length === 1) {
                          const numberArrays = chunk(countRange(group), group / 2);

                          return numberArrays.map((numArr, index) => {
                            return (
                              <View key={`row_${index}`} style={{ flexDirection: 'row' }}>
                                {countRange(numArr.length).map((_, idx) => {
                                  return (
                                    <AssetSvg
                                      key={`counters_${idx}`}
                                      name="CounterRed"
                                      height={55}
                                      width={55}
                                    />
                                  );
                                })}
                              </View>
                            );
                          });
                        } else
                          return countRange(group).map(idx => (
                            <AssetSvg
                              key={`counters_${idx}`}
                              name="CounterRed"
                              height={55}
                              width={55}
                            />
                          ));
                      })()}
                    </View>
                  );
                })}
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'beD',
  description: 'beD',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z.object({
    numberOfCorrectAnswers: numberEnum([1, 2]),
    numberOfCounters: numberEnum([4, 8, 12, 16]),
    incorrectOptionsRedCounters: z.array(z.number().int().min(1).max(16)),
    shouldAnswerRedsBeFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const numberOfCorrectAnswers = getRandomFromArray([1, 2] as const);
    const numberOfCounters = getRandomFromArray([4, 8, 12, 16] as const);

    const incorrectOptionsRedCounters = randomUniqueIntegersInclusive(
      1,
      numberOfCounters,
      4 - numberOfCorrectAnswers,
      { constraint: x => x !== numberOfCounters / 4 }
    );

    const shouldAnswerRedsBeFirst = getRandomBoolean();

    return {
      numberOfCounters,
      numberOfCorrectAnswers,
      incorrectOptionsRedCounters,
      shouldAnswerRedsBeFirst
    };
  },
  Component: props => {
    const {
      question: {
        numberOfCounters,
        numberOfCorrectAnswers,
        shouldAnswerRedsBeFirst,
        incorrectOptionsRedCounters
      },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctOptions = countRange(numberOfCorrectAnswers).map(num => {
      return {
        numberOfRed: numberOfCounters / 4,
        numberOfWhites: (numberOfCounters / 4) * 3,
        shouldAnswerRedsBeFirst: num === 1 ? shouldAnswerRedsBeFirst : !shouldAnswerRedsBeFirst
      };
    });

    const incorrectOptions = countRange(4 - numberOfCorrectAnswers).map((_, index) => {
      return {
        numberOfRed: incorrectOptionsRedCounters[index],
        numberOfWhites: numberOfCounters - incorrectOptionsRedCounters[index],
        shouldAnswerRedsBeFirst: getRandomBoolean()
      };
    });

    const options = [...correctOptions, ...incorrectOptions];

    const shuffledOptions = shuffle(options, { random });

    const title =
      numberOfCorrectAnswers === 1
        ? translate.ks1Instructions.selectTheGroupThatHasAXShaded(translate.fractions.quarters(1))
        : translate.ks1Instructions.selectTheGroupsThatHaveAXShaded(
            translate.fractions.quarters(1)
          );

    const pdfTitle =
      numberOfCorrectAnswers === 1
        ? translate.ks1PDFInstructions.circleTheGroupThatHasAXShaded(
            translate.fractions.quarters(1)
          )
        : translate.ks1PDFInstructions.circleTheGroupsThatHaveAXShaded(
            translate.fractions.quarters(1)
          );

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={correctOptions}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shuffledOptions.map(option => ({
            value: option,
            component: (
              <View
                style={{
                  flexDirection: 'row',
                  maxWidth: numberOfCounters === 12 ? dimens.width * 0.8 : dimens.width,
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {countRange(
                  option.shouldAnswerRedsBeFirst ? option.numberOfRed : option.numberOfWhites
                ).map((_, index) => {
                  return (
                    <AssetSvg
                      key={
                        option.shouldAnswerRedsBeFirst
                          ? `counters_red_${index}`
                          : `counters_white_${index}`
                      }
                      name={option.shouldAnswerRedsBeFirst ? 'CounterRed' : 'CounterWhite'}
                      height={60}
                      width={60}
                    />
                  );
                })}
                {countRange(
                  option.shouldAnswerRedsBeFirst ? option.numberOfWhites : option.numberOfRed
                ).map((_, index) => {
                  return (
                    <AssetSvg
                      key={
                        option.shouldAnswerRedsBeFirst
                          ? `counters_white_${index}`
                          : `counters_red_${index}`
                      }
                      name={option.shouldAnswerRedsBeFirst ? 'CounterWhite' : 'CounterRed'}
                      height={60}
                      width={60}
                    />
                  );
                })}
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'beE',
  description: 'beE',
  keywords: ['Equal groups', 'Group'],
  schema: z.object({
    numberOfCounters: z.number().int().min(8).max(24).step(4)
  }),
  simpleGenerator: () => {
    const numberOfCounters = randomIntegerInclusiveStep(8, 24, 4);
    return { numberOfCounters };
  },
  questionHeight: 800,
  Component: ({ question: { numberOfCounters }, translate }) => {
    const answer = numberOfCounters / 4;

    const sectionOfCounters = ({ height, width }: Dimens) => {
      switch (answer) {
        case 2:
          return (
            <View
              style={{
                width: width / 4 - 30,
                height: height / 2.5,
                justifyContent: 'space-between'
              }}
            >
              <View>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
              <View style={{ alignSelf: 'flex-end' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
            </View>
          );
        case 3:
          return (
            <View style={{ width: width / 4 - 30, height: height / 2.5 }}>
              <View>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
              <View style={{ alignSelf: 'center' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
              <View style={{ alignSelf: 'flex-end' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
            </View>
          );
        case 4:
          return (
            <View
              style={{
                width: width / 4 - 30,
                height: height / 2.5,
                justifyContent: 'space-between'
              }}
            >
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
            </View>
          );
        case 5:
          return (
            <View style={{ width: width / 4 - 30, height: height / 2.5 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
              <View style={{ alignSelf: 'center' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
            </View>
          );
        default:
          return (
            <View style={{ width: width / 4 - 30, height: height / 2.5 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <AssetSvg name={'CounterRed'} height={50} width={50} />
                <AssetSvg name={'CounterRed'} height={50} width={50} />
              </View>
            </View>
          );
      }
    };

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentence()}
        Content={({ dimens }) => (
          <View>
            <BarModelCurlyBrace braceText={numberOfCounters} />
            <Table
              items={[filledArray(sectionOfCounters(dimens), 4)]}
              cellStyle={{
                //cellStyle in table has paddingTop and paddingLeft defined, so need to explicitly write them add and can't use just "padding"
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
                paddingBottom: 16
              }}
            />
          </View>
        )}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        questionHeight={800}
        sentence={translate.ks1AnswerSentences.aQuarterOfXIsAns(numberOfCounters)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAQuarterOfAQuantity',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
