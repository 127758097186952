import z from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange
} from '../../../../utils/collections';
import { MULT } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getGroupSvgName } from '../../../../utils/objectsImages';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bi1',
  description: 'bi1',
  keywords: ['Multiply', '2 times-table'],
  schema: z
    .object({
      groups: z.number().int().min(1).max(12),
      item: z.enum([
        'Apple',
        'Pear',
        'Cookie',
        'Muffin',
        '2p',
        '2Pound',
        'Dice',
        'Donut',
        'Egg',
        'Pencil',
        'Fish'
      ]),
      answerOptions: z.array(z.number().int()).length(4),
      groupsFirst: z.boolean()
    })
    .refine(val => arrayHasNoDuplicates(val.answerOptions), 'must be no duplicate options'),
  simpleGenerator: () => {
    const item = getRandomFromArray([
      'Apple',
      'Pear',
      'Cookie',
      'Muffin',
      '2p',
      '2Pound',
      'Dice',
      'Donut',
      'Egg',
      'Pencil',
      'Fish'
    ] as const);

    const { groups, uniqueAnswers } = rejectionSample(
      () => {
        const groups = randomIntegerInclusive(1, 12);

        const answer = 2 * groups;
        const incorrectAnswers = [answer - 2, answer + 2, 2, groups, groups + 2].filter(
          val => val > 0
        );

        const uniqueAnswers = [...new Set([answer, ...incorrectAnswers])];

        return { groups, uniqueAnswers };
      },
      val => val.uniqueAnswers.length >= 4
    );

    const incorrectAnswers = getRandomSubArrayFromArray(
      [...uniqueAnswers.filter(val => val !== 2 * groups)],
      3
    );
    const groupsFirst = getRandomBoolean();

    return { groups, item, answerOptions: shuffle([2 * groups, ...incorrectAnswers]), groupsFirst };
  },
  Component: props => {
    const {
      question: { groups, item, answerOptions, groupsFirst },
      translate
    } = props;

    const svgName =
      item === '2p'
        ? 'Money/Pence2'
        : item === '2Pound'
        ? 'Money/Pounds2'
        : item === 'Dice'
        ? 'Dice_faces/2'
        : getGroupSvgName(item, 2);

    const sentence = groupsFirst
      ? `${groups.toLocaleString()} ${MULT} ${(2).toLocaleString()} = <ans/>`
      : `${(2).toLocaleString()} ${MULT} ${groups.toLocaleString()} = <ans/>`;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheMultiplication()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteMultiplication()}
        sentence={sentence}
        testCorrect={[groups * 2]}
        pdfLayout="itemsTop"
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-start' }}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            <View
              style={{
                flexDirection: 'column',
                gap: 15
              }}
            >
              {countRange(groups > 6 ? 2 : 1).map(rowId => {
                return (
                  <View key={rowId} style={{ flexDirection: 'row', gap: 15 }}>
                    {countRange(Math.min(groups - rowId * 6, 6)).map(i => (
                      <AssetSvg
                        key={i}
                        name={svgName}
                        width={dimens.width / 7}
                        height={dimens.height * 0.35}
                      />
                    ))}
                  </View>
                );
              })}
            </View>
          </View>
        )}
        questionHeight={1000}
        items={answerOptions.map(value => ({
          value,
          component: <Text variant="WRN700">{value.toLocaleString()}</Text>
        }))}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bi2',
  description: 'bi2',
  keywords: ['Number track', 'Multiplication', 'Count', 'Forwards', 'Backwards', '2 times-table'],
  schema: z.object({
    startingNumber: z.number().min(0).max(10).step(2),
    givenIndexes: z
      .array(z.number().min(0).max(7))
      .refine(arrayHasNoDuplicates)
      .refine(val =>
        val.some(
          i => val.includes(i + 1) || val.includes(i - 1),
          'must be at least two consecutive numbers'
        )
      ),
    isForward: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(0, 10, 2);
    const numberOfGiven = randomIntegerInclusive(3, 5);

    const givenIndex1 = randomIntegerInclusive(0, 7);
    const givenIndex2 =
      givenIndex1 === 0
        ? givenIndex1 + 1
        : givenIndex1 === 7
        ? givenIndex1 - 1
        : getRandomFromArray([givenIndex1 + 1, givenIndex1 - 1] as const);

    const otherGivenIndexes = randomUniqueIntegersInclusive(0, 7, numberOfGiven - 2, {
      constraint: x => ![givenIndex1, givenIndex2].includes(x)
    });

    const isForward = getRandomBoolean();

    return {
      startingNumber,
      givenIndexes: [givenIndex1, givenIndex2, ...otherGivenIndexes],
      isForward
    };
  },
  Component: ({ question: { startingNumber, givenIndexes, isForward }, translate }) => {
    // Create array to pass to NumberTrack
    const ascendingNumberArray = countRange(8).map(i => startingNumber + i * 2);
    const numberArray = isForward ? ascendingNumberArray : [...ascendingNumberArray].reverse();

    const ansArray = numberArray
      .filter((_val, i) => !givenIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      givenIndexes.includes(id) ? val.toLocaleString() : '<ans/>'
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bi3',
  description: 'bi3',
  keywords: ['2 times-table', 'Multiply'],
  schema: z
    .object({
      multiplier: z.number().min(0).max(12),
      order: z.array(z.number()).length(2),
      totalFirst: z.boolean(),
      answerIndex: z.number().int().min(0).max(2)
    })
    .refine(val => arraysHaveSameContentsUnordered(val.order, [2, val.multiplier])),

  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(0, 12);

    const totalFirst = getRandomBoolean();

    const order = shuffle([2, multiplier]);

    const answerIndex = randomIntegerInclusive(0, 2, {
      constraint: x =>
        (multiplier === 0 && (totalFirst ? x !== order.indexOf(2) + 1 : order.indexOf(2) !== x)) ||
        multiplier !== 0
    });

    return {
      multiplier,
      order,
      totalFirst,
      answerIndex
    };
  },
  Component: ({ question: { multiplier, order, totalFirst, answerIndex }, translate }) => {
    const numberArray = totalFirst ? [multiplier * 2, ...order] : [...order, multiplier * 2];
    const stringArray = numberArray.map((val, i) =>
      i === answerIndex ? '<ans/>' : val.toLocaleString()
    );

    const sentence = totalFirst
      ? `${stringArray[0]} = ${stringArray[1]} ${MULT} ${stringArray[2]}`
      : `${stringArray[0]} ${MULT} ${stringArray[1]} = ${stringArray[2]}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeMultiplication()}
        questionHeight={600}
        testCorrect={[numberArray[answerIndex].toString()]}
        sentence={sentence}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The2TimesTable',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
