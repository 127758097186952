import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { rangeAsString } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { parseToSUB } from '../../../../utils/parse';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { citiesSchema, getColdCity, getHotCity } from '../../../../utils/cities';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aCU',
  description: 'aCU',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    start: z.number().int().min(-3).max(0),
    numberIdx: z.number().int().min(1).max(10)
  }),

  simpleGenerator: () => {
    const start = randomIntegerInclusive(-3, 0);

    const zeroIndex = Math.abs(start);
    // number needs to be a positive number
    const numberIdx = randomIntegerInclusive(zeroIndex + 1, 10);

    return { start, numberIdx };
  },
  Component: props => {
    const {
      question: { start, numberIdx },
      translate
    } = props;

    const endNumber = start + 10;

    const tickValues = rangeAsString(start, endNumber, 1, true).map(el => parseToSUB(el));
    const number = tickValues[numberIdx];

    return (
      <QF1ContentAndSentence
        actionPanelVariant={'bottomTall'}
        title={translate.instructions.howManyJumpsFromXtoZero(number)}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentence={
          number === '1'
            ? translate.answerSentences.ansJump()
            : translate.answerSentences.ansJumps()
        }
        testCorrect={[number]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aCV',
  description: 'aCV',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    start: z.number().int().min(-10).max(-7),
    numberIdx: z.number().int().min(1).max(9)
  }),

  simpleGenerator: () => {
    const start = randomIntegerInclusive(-10, -7);

    const zeroIndex = Math.abs(start);
    // number needs to be a negative number
    const numberIdx = randomIntegerInclusive(1, zeroIndex - 1);

    return { start, numberIdx };
  },
  Component: props => {
    const {
      question: { start, numberIdx },
      translate
    } = props;

    const endNumber = start + 10;

    const tickValues = rangeAsString(start, endNumber, 1, true).map(el => parseToSUB(el));
    const number = tickValues[numberIdx];

    const answer = Math.abs(start + numberIdx);

    return (
      <QF1ContentAndSentence
        actionPanelVariant={'bottomTall'}
        title={translate.instructions.howManyJumpsFromXtoZero(number)}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentence={
          answer === 1 ? translate.answerSentences.ansJump() : translate.answerSentences.ansJumps()
        }
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aCW',
  description: 'aCW',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    start: z.number().int().min(-7).max(-3),
    negNumIdx: z.number().int().min(1).max(6),
    posNumIdx: z.number().int().min(4).max(10)
  }),

  simpleGenerator: () => {
    const start = randomIntegerInclusive(-7, -3);

    const zeroIndex = Math.abs(start);

    const negNumIdx = randomIntegerInclusive(1, zeroIndex - 1);
    const posNumIdx = randomIntegerInclusive(zeroIndex + 1, 10);

    return { start, negNumIdx, posNumIdx };
  },
  Component: props => {
    const {
      question: { start, negNumIdx, posNumIdx },
      translate
    } = props;

    const endNumber = start + 10;

    const tickValues = rangeAsString(start, endNumber, 1, true).map(el => parseToSUB(el));
    const negNum = tickValues[negNumIdx];
    const posNum = tickValues[posNumIdx];

    return (
      <QF1ContentAndSentence
        actionPanelVariant={'bottomTall'}
        title={translate.instructions.useNumberLineToFindDiffBetweenXAnsY(posNum, negNum)}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        sentence={translate.answerSentences.differenceBetweenXandYisAns(posNum, negNum)}
        testCorrect={[Math.abs(posNumIdx - negNumIdx).toString()]}
        pdfDirection="column"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aCX',
  description: 'aCX',
  keywords: ['Negative numbers', 'Find the difference'],
  schema: z.object({
    posNum: z.number().int().min(1).max(10),
    difference: z.number().int().min(2).max(11),
    isPosNumFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const posNum = randomIntegerInclusive(1, 10);
    const difference = randomIntegerInclusive(2, 11, {
      constraint: x => posNum - x > -11 && posNum - x < 0
    });
    const isPosNumFirst = getRandomBoolean();

    return { posNum, isPosNumFirst, difference };
  },

  Component: props => {
    const {
      question: { posNum, isPosNumFirst, difference },
      translate
    } = props;

    const num1 = isPosNumFirst ? posNum : posNum - difference;
    const num2 = isPosNumFirst ? posNum - difference : posNum;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.findDiffBetweenNumbers()}
        sentence={translate.answerSentences.differenceBetweenXandYisAns(
          parseToSUB(num1.toLocaleString()),
          parseToSUB(num2.toLocaleString())
        )}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        testCorrect={[difference.toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aCY',
  description: 'aCY',
  keywords: ['Negative numbers', 'Find the difference'],
  schema: z.object({
    posNum1: z.number().int().min(5).max(60),
    posNum2: z.number().int().min(5).max(60),
    negNum1: z.number().int().min(-60).max(-5),
    negNum2: z.number().int().min(-60).max(-5)
  }),
  simpleGenerator: () => {
    const [posNum1, posNum2] = randomUniqueIntegersInclusive(5, 60, 2);
    const [negNum1, negNum2] = randomUniqueIntegersInclusive(-60, -5, 2);

    return { posNum1, posNum2, negNum1, negNum2 };
  },

  Component: props => {
    const {
      question: { posNum1, posNum2, negNum1, negNum2 },
      translate
    } = props;

    const values = [
      [posNum1, negNum1],
      [posNum2, negNum2]
    ];

    const answers = values.map(([num1, num2]) => [parseToSUB(Math.abs(num1 - num2).toString())]);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.findDiffBetweenNumbers()}
        sentences={values.map(
          ([num1, num2]) =>
            `${translate.answerSentences.xAndY(
              parseToSUB(num1.toLocaleString()),
              parseToSUB(num2.toLocaleString())
            )}     <ans/>`
        )}
        testCorrect={answers}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aCY2',
  description: 'aCY',
  keywords: ['Negative numbers', 'Find the difference'],
  schema: z.object({
    posNum: z.number().int().min(5).max(60),
    negNum: z.number().int().min(-60).max(-5)
  }),
  simpleGenerator: () => {
    const posNum = randomIntegerInclusive(5, 60);
    const negNum = randomIntegerInclusive(-60, -5);

    return { posNum, negNum };
  },

  Component: props => {
    const {
      question: { posNum, negNum },
      translate,
      displayMode
    } = props;

    const answer = parseToSUB(Math.abs(posNum - negNum).toString());

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.findDiffBetweenNumbers()}
        sentence={`${translate.answerSentences.xAndY(
          posNum.toLocaleString(),
          parseToSUB(negNum.toLocaleString())
        )}     <ans/>`}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={[answer]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aCZ',
  description: 'aCZ',
  keywords: ['Negative numbers', 'Find the difference', 'Temperature'],
  schema: z.object({
    hotTemp: z.number().int().min(1).max(10),
    coldTemp: z.number().int().min(-10).max(-1),
    hotCity: citiesSchema,
    coldCity: citiesSchema
  }),
  simpleGenerator: () => {
    const hotTemp = randomIntegerInclusive(1, 10);
    const coldTemp = randomIntegerInclusive(-10, -1);
    const hotCity = getHotCity();
    const coldCity = getColdCity();
    return { hotTemp, coldTemp, hotCity, coldCity };
  },

  Component: props => {
    const {
      question: { hotTemp, coldTemp, hotCity, coldCity },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.tempInXisYdegrees(
          translate.cities[hotCity](),
          hotTemp.toLocaleString()
        )}<br/>${translate.instructions.tempInXisYdegrees(
          translate.cities[coldCity](),
          parseToSUB(coldTemp.toLocaleString())
        )}<br/><br/>${translate.answerSentences.howMuchWarmerIsTempInXThanInY(
          translate.cities[hotCity](),
          translate.cities[coldCity]()
        )}`}
        sentence={translate.answerSentences.ansDegC()}
        testCorrect={[Math.abs(hotTemp - coldTemp).toString()]}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindTheDifference',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
