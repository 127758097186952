import { ScientificNotation } from '../../../utils/math';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput from '../../molecules/UserInput';
import { MeasureView } from '../../atoms/MeasureView';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { all, create, number } from 'mathjs';
import { range } from '../../../utils/collections';
import { DisplayMode } from '../../../contexts/displayMode';
import { useContext } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import MissingDigitsLongMultiplicationDeprecated, {
  MissingDigitsLongMultiplicationDeprecatedWithState
} from '../representations/ColumnOperations/MissingDigitsLongMultiplicationDeprecated';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

type UserAnswer = {
  top: string[];
  multiplier: string[];
  partialProduct1: string[];
  partialProduct2: string[];
  answer: string[];
};

type Props = TitleStyleProps & {
  title: string;
  topNumber: number;
  multiplier: number;
  showPartials?: boolean;
  /**
   * Removes extra cells from top/bottom rows and left/right columns around the operation.
   * Defaults to false.
   */
  removeExtraCells?: boolean;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answerToDisplay?: { [K in keyof UserAnswer]: { [P in K]: UserAnswer[K] } }[keyof UserAnswer];
    answerText?: string;
  };
};

/**
 * @deprecated use QF27aLongMultiplication
 * Multiplication column operators with partial products.
 * If the multiplier has 1 digit, the partial products are the products of the multiplier and each of the topNumber digits.
 * If the multiplier has 2 digits, the partial products are the products of the topNumber and each of the multiplier digits.
 * showPartials should be set to true if the partials should be shown on the grid. If it is "false" so they are shown as answerboxes. fasle as default.
 */
export default function QF27aLongMultiplicationDeprecated({
  title,
  topNumber,
  multiplier,
  showPartials = false,
  questionHeight,
  removeExtraCells,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const answerNumber = number(math.evaluate(`${topNumber} * ${multiplier}`));

  const topNumberSci = ScientificNotation.fromNumber(topNumber);
  const answerNumberSci = ScientificNotation.fromNumber(answerNumber);

  const multiplierSci = ScientificNotation.fromNumber(multiplier);

  let partialProduct1 = number(math.evaluate(`${topNumberSci.digitAt('ones')} * ${multiplier}`));

  let partialProduct2 = number(
    math.evaluate(`${topNumberSci.digitAt('tens')} * ${multiplier} * 10`)
  );
  // If the multiplier is a two digit, the topNumber is multiplied by each digit for the partial products
  if (multiplierSci.e === 1) {
    partialProduct1 = math.evaluate(`${multiplierSci.digitAt('ones')} * ${topNumber}`);
    partialProduct2 = math.evaluate(`${multiplierSci.digitAt('tens')} * ${topNumber} * 10`);
  }

  const partialProduct1Sci = ScientificNotation.fromNumber(partialProduct1);

  const partialProduct2Sci = ScientificNotation.fromNumber(partialProduct2);

  // Find the indices of the cells that we’re going to replace with an answer box, where MissingDigitsLongMultiplication interprets
  // these indices as the place value of a number, i.e. 0 corresponds to units, 1 to tens, -1 to tenths.
  // Here the whole of the partial products and answer number are going to be answer boxes.
  const answerBoxes = [
    range(Math.min(partialProduct1Sci.resolution, 0), Math.max(partialProduct1Sci.e, 0)),
    range(Math.min(partialProduct2Sci.resolution, 0), Math.max(partialProduct2Sci.e, 0)),
    range(Math.min(answerNumberSci.resolution, 0), Math.max(answerNumberSci.e, 0))
  ];

  /* Confimed with maths specialists that answer must be the ones partial product first, then the tens */
  const testCorrect = (answer: UserAnswer) => {
    if (showPartials) {
      // If partials are shown, only test the answer
      return answerBoxes[2].every(
        i => answer.answer[i] === answerNumberSci.unsignedDigitAt(i).toString()
      );
    } else {
      return (
        answerBoxes[0].every(
          i => answer.partialProduct1[i] === partialProduct1Sci.unsignedDigitAt(i).toString()
        ) &&
        answerBoxes[1].every(
          i => answer.partialProduct2[i] === partialProduct2Sci.unsignedDigitAt(i).toString()
        ) &&
        answerBoxes[2].every(
          i => answer.answer[i] === answerNumberSci.unsignedDigitAt(i).toString()
        )
      );
    }
  };

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const correctAnswerDefault = {
      top: [],
      multiplier: [],
      partialProduct1: [],
      partialProduct2: [],
      answer: []
    };

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <MissingDigitsLongMultiplicationDeprecated
                  topNumber={topNumber}
                  multiplier={multiplier}
                  partialProduct1={Number(partialProduct1)}
                  partialProduct2={Number(partialProduct2)}
                  partialProduct1MissingDigits={showPartials ? undefined : answerBoxes[0]}
                  partialProduct2MissingDigits={showPartials ? undefined : answerBoxes[1]}
                  answerNumberMissingDigits={answerBoxes[2]}
                  removeExtraCells={removeExtraCells}
                  userAnswer={
                    displayMode === 'markscheme'
                      ? { ...correctAnswerDefault, ...customMarkSchemeAnswer?.answerToDisplay }
                      : undefined
                  }
                  dimens={dimens}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }
  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" />}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <MissingDigitsLongMultiplicationDeprecatedWithState
              id="longmult"
              testCorrect={testCorrect}
              topNumber={topNumber}
              multiplier={multiplier}
              partialProduct1={Number(partialProduct1)}
              partialProduct2={Number(partialProduct2)}
              partialProduct1MissingDigits={showPartials ? undefined : answerBoxes[0]}
              partialProduct2MissingDigits={showPartials ? undefined : answerBoxes[1]}
              answerNumberMissingDigits={answerBoxes[2]}
              removeExtraCells={removeExtraCells}
              dimens={dimens}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
