import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import { AssetSvg } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bc4',
  description: 'bc4',
  keywords: ['Tens'],
  schema: z.object({
    number: numberEnum([20, 30, 40, 50]),
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.literal('TenFrame'),
        color: z.enum(['red', 'blue', 'yellow', 'green']),
        isVertical: z.boolean()
      }),
      z.object({
        variant: z.literal('Rekenrek')
      }),
      z.object({
        variant: z.literal('Straws')
      }),
      z.object({
        variant: z.literal('Base10'),
        isVertical: z.boolean()
      })
    ])
  }),
  simpleGenerator: () => {
    const number = getRandomFromArray([20, 30, 40, 50] as const);
    const variant = getRandomFromArray(['TenFrame', 'Rekenrek', 'Straws', 'Base10'] as const);
    const isVertical = getRandomBoolean();
    const color = getRandomFromArray(['red', 'blue', 'yellow', 'green'] as const);

    const subQuestion =
      variant === 'Base10'
        ? { variant, isVertical }
        : variant === 'TenFrame'
        ? { variant, isVertical, color }
        : { variant };

    return { number, subQuestion };
  },
  Component: props => {
    const {
      question: { number, subQuestion },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatNumberIsShown()}
        Content={({ dimens }) => {
          return (
            <View>
              {subQuestion.variant === 'TenFrame' ? (
                subQuestion.isVertical ? (
                  <View style={{ flexDirection: 'row', gap: 20 }}>
                    {countRange(number / 10).map((_, index) => (
                      <TenFrameLayout
                        key={index}
                        orientation="vertical"
                        size="xsmall"
                        items={filledArray(subQuestion.color, 10)}
                      />
                    ))}
                  </View>
                ) : (
                  <View style={{ width: dimens.width * 0.75 }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        gap: 20,
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {countRange(number / 10).map((_, index) => (
                        <TenFrameLayout
                          key={index}
                          orientation="horizontal"
                          size="xsmall"
                          items={filledArray(subQuestion.color, 10)}
                        />
                      ))}
                    </View>
                  </View>
                )
              ) : subQuestion.variant === 'Rekenrek' ? (
                <Rekenrek numberShown={number} dimens={dimens} rows={10} />
              ) : subQuestion.variant === 'Base10' ? (
                subQuestion.isVertical ? (
                  <View style={{ flexDirection: 'row', gap: 24 }}>
                    {countRange(number / 10).map((_, index) => (
                      <AssetSvg key={index} name="Base_Ten/10cube" height={dimens.height} />
                    ))}
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap'
                    }}
                  >
                    {countRange(number / 10).map((_, index) => (
                      <View
                        key={index}
                        style={{
                          width: dimens.width * 0.5,
                          height: dimens.height * 0.2
                        }}
                      >
                        <View style={{ transform: [{ rotate: '90deg' }] }}>
                          <AssetSvg name="Base_Ten/10cube" height={dimens.height} />
                        </View>
                      </View>
                    ))}
                  </View>
                )
              ) : (
                <View style={{ flexDirection: 'row' }}>
                  {countRange(number / 10).map((_, index) => (
                    <AssetSvg
                      key={index}
                      name="Bundle_of_10_straws"
                      height={dimens.height * 0.5}
                      width={dimens.width / (number / number === 20 ? 10 : 5)}
                    />
                  ))}
                </View>
              )}
            </View>
          );
        }}
        testCorrect={[number.toString()]}
        pdfDirection="column"
        questionHeight={1000}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bc5',
  description: 'bc5',
  keywords: ['Tens'],
  schema: z.object({
    number: numberEnum([20, 30, 40, 50]),
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.literal('TenFrame'),
        color: z.enum(['red', 'blue', 'yellow', 'green']),
        isVertical: z.boolean()
      }),
      z.object({
        variant: z.literal('Rekenrek')
      }),
      z.object({
        variant: z.literal('Straws')
      }),
      z.object({
        variant: z.literal('Base10'),
        isVertical: z.boolean()
      })
    ])
  }),
  simpleGenerator: () => {
    const number = getRandomFromArray([20, 30, 40, 50] as const);
    const variant = getRandomFromArray(['TenFrame', 'Rekenrek', 'Straws', 'Base10'] as const);
    const isVertical = variant === 'TenFrame' && number === 50 ? true : getRandomBoolean();
    const color = getRandomFromArray(['red', 'blue', 'yellow', 'green'] as const);

    const subQuestion =
      variant === 'Base10'
        ? { variant, isVertical }
        : variant === 'TenFrame'
        ? { variant, isVertical, color }
        : { variant };

    return { number, subQuestion };
  },
  Component: props => {
    const {
      question: { number, subQuestion },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) => {
          return (
            <View>
              {subQuestion.variant === 'TenFrame' ? (
                subQuestion.isVertical ? (
                  <View style={{ flexDirection: 'row', gap: 20 }}>
                    {countRange(number / 10).map((_, index) => (
                      <TenFrameLayout
                        key={index}
                        orientation="vertical"
                        size="xsmall"
                        items={filledArray(subQuestion.color, 10)}
                      />
                    ))}
                  </View>
                ) : (
                  <View style={{ width: dimens.width * 0.75 }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        gap: 20,
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {countRange(number / 10).map((_, index) => (
                        <TenFrameLayout
                          key={index}
                          orientation="horizontal"
                          size="xsmall"
                          items={filledArray(subQuestion.color, 10)}
                        />
                      ))}
                    </View>
                  </View>
                )
              ) : subQuestion.variant === 'Rekenrek' ? (
                <Rekenrek numberShown={number} dimens={dimens} rows={10} />
              ) : subQuestion.variant === 'Base10' ? (
                subQuestion.isVertical ? (
                  <View style={{ flexDirection: 'row', gap: 16 }}>
                    {countRange(number / 10).map((_, index) => (
                      <AssetSvg key={index} name="Base_Ten/10cube" height={dimens.height} />
                    ))}
                  </View>
                ) : (
                  <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: dimens.width }}>
                    {countRange(number / 10).map((_, index) => (
                      <View
                        key={index}
                        style={{
                          width: dimens.width * 0.32,
                          height: dimens.height * 0.2
                        }}
                      >
                        <View style={{ transform: [{ rotate: '90deg' }] }}>
                          <AssetSvg name="Base_Ten/10cube" height={dimens.height * 0.9} />
                        </View>
                      </View>
                    ))}
                  </View>
                )
              ) : (
                <View style={{ flexDirection: 'row' }}>
                  {countRange(number / 10).map((_, index) => (
                    <AssetSvg key={index} name="Bundle_of_10_straws" height={dimens.height * 0.7} />
                  ))}
                </View>
              )}
            </View>
          );
        }}
        // The answer should reflect the total count of 'ones' present, not just the number of 'ones' in the place value column.
        testCorrect={[[number.toString()], [(number / 10).toString()]]}
        pdfDirection="column"
        questionHeight={1000}
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsOnesInNum(number),
          translate.ks1AnswerSentences.thereAreAnsTensInNum(number)
        ]}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bc6',
  description: 'bc6',
  keywords: ['Tens'],
  schema: z.object({
    tens: z.number().int().min(2).max(5),
    draggableValues: z
      .array(z.number().int().min(0).max(50))
      .length(4)
      .refine(arrayHasNoDuplicates),
    isTens: z.boolean(),
    isAnswerBoxFirst: z.boolean()
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const isTens = getRandomBoolean();
    const isAnswerBoxFirst = getRandomBoolean();
    const tens = randomIntegerInclusive(2, 5);

    const [correctNumber, incorrectNumber] =
      isTens && isAnswerBoxFirst ? [tens, tens * 10] : [tens * 10, tens];

    const inCorrectDraggables =
      tens === 5
        ? [tens * 10 - 10, tens + 10, incorrectNumber]
        : getRandomSubArrayFromArray(
            [tens * 10 + 10, tens * 10 - 10, tens + 10, incorrectNumber],
            3
          );

    const draggableValues = shuffle([correctNumber, ...inCorrectDraggables]);

    return { tens, draggableValues, isTens, isAnswerBoxFirst };
  },
  Component: props => {
    const {
      question: { tens, draggableValues, isTens, isAnswerBoxFirst },
      translate
    } = props;

    const items = draggableValues.map(number => {
      return { value: number.toString(), component: number.toLocaleString() };
    });

    const [sentence, answer] = isTens
      ? isAnswerBoxFirst
        ? [translate.ks1AnswerSentences.thereAreAnsTensInNum(tens * 10), tens.toString()]
        : [translate.ks1AnswerSentences.thereAreNumTensInAns(tens), (tens * 10).toString()]
      : isAnswerBoxFirst
      ? // The answer should reflect the total count of 'ones' present, not just the number of 'ones' in the place value column.
        [translate.ks1AnswerSentences.thereAreAnsOnesInNum(tens * 10), (tens * 10).toString()]
      : [translate.ks1AnswerSentences.thereAreNumOnesInAns(tens * 10), (tens * 10).toString()];

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useACardToCompleteSentence()}
        items={items}
        actionPanelVariant="end"
        sentence={sentence}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        testCorrect={[answer]}
        questionHeight={800}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TwentyThirtyFortyAndFifty',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
