// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import DrawLineGraphs from './1DrawLineGraphs';
import ReadAndInterpretLineGraphs from './2ReadAndInterpretLineGraphs';
import ReadAndInterpretTables from './3ReadAndInterpretTables';
import TwoWayTables from './4TwoWayTables';
import ReadAndInterpretTimetables from './5ReadAndInterpretTimetables';

const Block = newBlockContent({
  block: 'Statistics',

  weeks: [11, 12],
  smallSteps: [
    DrawLineGraphs, // Step 1
    ReadAndInterpretLineGraphs, // Step 2
    ReadAndInterpretTables, // Step 3
    TwoWayTables, // Step 4
    ReadAndInterpretTimetables // Step 5
  ]
});
export default Block;
