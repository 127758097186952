import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { ADD, SUB } from '../../../../constants';
import { NonEmptyArray, sumNumberArray } from '../../../../utils/collections';
import { isEqual, isInRange } from '../../../../utils/matchers';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bht',
  description: 'bht',
  keywords: ['Compare', 'Add', 'Subtract', 'Greater than', 'Less than', 'Equal to'],
  schema: z
    .object({
      isAddition: z.boolean(),
      number1: z.number().int().min(1).max(99),
      number2: z.number().int().min(1).max(99),
      number3: z.number().int().min(1).max(99),
      number4: z.number().int().min(1).max(99)
    })
    .refine(
      val =>
        val.isAddition
          ? val.number1 + val.number2 <= 100 && val.number3 + val.number4 <= 100
          : val.number1 - val.number2 > 0 && val.number3 - val.number4 > 0,
      'answers between 1 and 100'
    ),
  simpleGenerator: () => {
    const isAddition = getRandomBoolean();

    let number1, number2, number3, number4;

    if (isAddition) {
      const num1 = randomIntegerInclusive(1, 97);
      const num2 = randomIntegerInclusive(1, 100 - num1);
      number1 = num1;
      number2 = num2;
      number3 = num1;
      number4 = getRandomFromArray(
        [num2 + 1, num2 + 2, num2 + 3, num2 - 1, num2 - 2, num2 - 3].filter(
          val => isInRange(1, 99)(val) && val + num1 <= 99
        ) as NonEmptyArray<number>
      );
    } else if (getRandomBoolean()) {
      const num1 = randomIntegerInclusive(3, 99);
      const num2 = randomIntegerInclusive(1, num1 - 1);
      number1 = num1;
      number2 = num2;
      number3 = num1;
      number4 = getRandomFromArray(
        [num2 + 1, num2 + 2, num2 + 3, num2 - 1, num2 - 2, num2 - 3].filter(
          val => isInRange(1, 99)(val) && num1 - val > 0
        ) as NonEmptyArray<number>
      );
    } else {
      const num1 = randomIntegerInclusive(3, 99);
      const num2 = randomIntegerInclusive(1, num1 - 2);
      number1 = num1;
      number2 = num2;
      number3 = getRandomFromArray(
        [num1 + 1, num1 + 2, num1 + 3, num1 - 1, num1 - 2, num1 - 3].filter(
          val => isInRange(1, 99)(val) && val - num2 > 0
        ) as NonEmptyArray<number>
      );
      number4 = num2;
    }

    const [equation1A, equation1B] = isAddition ? shuffle([number1, number2]) : [number1, number2];
    const [equation2A, equation2B] = isAddition ? shuffle([number3, number4]) : [number3, number4];

    return {
      number1: equation1A,
      number2: equation1B,
      number3: equation2A,
      number4: equation2B,
      isAddition
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, isAddition },
      translate
    } = props;

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompareTheCalculations()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToMakeTheStatementCorrect()}
        items={['<', '>', '=']}
        actionPanelVariant="end"
        sentence={`${number1.toLocaleString()} ${
          isAddition ? ADD : SUB
        } ${number2.toLocaleString()} <ans/> ${number3.toLocaleString()} ${
          isAddition ? ADD : SUB
        } ${number4.toLocaleString()}`}
        pdfLayout="itemsHidden"
        testCorrect={
          isAddition
            ? [lessThanGreaterThanOrEqualTo(number1 + number2, number3 + number4)]
            : [lessThanGreaterThanOrEqualTo(number1 - number2, number3 - number4)]
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bhu',
  description: 'bhu',
  keywords: ['Add', 'Subtract', 'Compare', 'Equal to'],
  schema: z
    .object({
      isAddition: z.boolean(),
      number1: z.number().int().min(11).max(99),
      number2: z.number().int().min(1).max(99),
      options: z
        .tuple([z.number().int().min(1).max(99), z.number().int().min(1).max(99)])
        .array()
        .length(4)
    })
    .refine(
      val =>
        val.options.every(arr => (val.isAddition ? arr[0] + arr[1] <= 99 : arr[0] - arr[1] > 0)),
      'answers between 1 and 99'
    ),
  simpleGenerator: () => {
    const isAddition = getRandomBoolean();

    let options: [number, number][], number1: number, number2: number;

    if (isAddition) {
      const { number1A, number2A, correctOptionChoices, incorrectOptionChoices, correctAnswers } =
        rejectionSample(
          () => {
            const number1A = randomIntegerInclusive(11, 98);
            const number2A = randomIntegerInclusive(1, 100 - number1A - 1);
            const correctOptionChoices = [
              [number1A + 1, number2A - 1],
              [number1A + 2, number2A - 2],
              [number1A + 10, number2A - 10],
              [number1A - 1, number2A + 1],
              [number1A - 2, number2A + 2],
              [number1A - 10, number2A + 10]
            ].filter(
              val => val.every(num => isInRange(1, 98)(num)) && sumNumberArray(val) <= 99
            ) as [number, number][];

            const incorrectOptionChoices = [
              [number1A + 1, number2A + 1],
              [number1A + 2, number2A + 2],
              [number1A + 10, number2A + 10],
              [number1A - 1, number2A - 1],
              [number1A - 2, number2A - 2],
              [number1A - 10, number2A - 10],
              [number1A - 1, number2A],
              [number1A - 2, number2A],
              [number1A - 10, number2A],
              [number1A + 1, number2A],
              [number1A + 2, number2A],
              [number1A + 10, number2A],
              [number1A, number2A + 1],
              [number1A, number2A + 2],
              [number1A, number2A + 10],
              [number1A, number2A - 1],
              [number1A, number2A - 2],
              [number1A, number2A - 10]
            ].filter(
              val => val.every(num => isInRange(1, 98)(num)) && sumNumberArray(val) <= 99
            ) as [number, number][];

            const correctAnswers = randomIntegerInclusive(
              1,
              Math.min(correctOptionChoices.length, 3)
            );
            return {
              number1A,
              number2A,
              correctOptionChoices,
              incorrectOptionChoices,
              correctAnswers
            };
          },
          val =>
            val.correctOptionChoices.length >= val.correctAnswers &&
            val.incorrectOptionChoices.length >= 4 - val.correctAnswers
        );

      number1 = number1A;
      number2 = number2A;
      const correctOptions = getRandomSubArrayFromArray(correctOptionChoices, correctAnswers);

      const incorrectOptions = getRandomSubArrayFromArray(
        incorrectOptionChoices,
        4 - correctAnswers
      );

      options = [...correctOptions, ...incorrectOptions];
    } else {
      const { number1B, number2B, correctOptionChoices, incorrectOptionChoices, correctAnswers } =
        rejectionSample(
          () => {
            const number1B = randomIntegerInclusive(11, 98);
            const number2B = randomIntegerInclusive(1, number1B - 1);
            const correctOptionChoices = [
              [number1B + 1, number2B + 1],
              [number1B + 2, number2B + 2],
              [number1B + 10, number2B + 10],
              [number1B - 1, number2B - 1],
              [number1B - 2, number2B - 2],
              [number1B - 10, number2B - 10]
            ].filter(val => val.every(num => isInRange(1, 99)(num))) as [number, number][];
            const incorrectOptionChoices = [
              [number1B + 1, number2B - 1],
              [number1B + 2, number2B - 2],
              [number1B + 10, number2B - 10],
              [number1B - 1, number2B + 1],
              [number1B - 2, number2B + 2],
              [number1B - 10, number2B + 10],
              [number1B - 1, number2B],
              [number1B - 2, number2B],
              [number1B - 10, number2B],
              [number1B + 1, number2B],
              [number1B + 2, number2B],
              [number1B + 10, number2B],
              [number1B, number2B + 1],
              [number1B, number2B + 2],
              [number1B, number2B + 10],
              [number1B, number2B - 1],
              [number1B, number2B - 2],
              [number1B, number2B - 10]
            ].filter(val => val[0] - val[1] > 0 && val.every(num => isInRange(1, 99)(num))) as [
              number,
              number
            ][];

            const correctAnswers = randomIntegerInclusive(
              1,
              Math.min(correctOptionChoices.length, 3)
            );
            return {
              number1B,
              number2B,
              correctOptionChoices,
              incorrectOptionChoices,
              correctAnswers
            };
          },
          val =>
            val.correctOptionChoices.length >= val.correctAnswers &&
            val.incorrectOptionChoices.length >= 4 - val.correctAnswers
        );
      number1 = number1B;
      number2 = number2B;
      const correctOptions = getRandomSubArrayFromArray(correctOptionChoices, correctAnswers);

      const incorrectOptions = getRandomSubArrayFromArray(
        incorrectOptionChoices,
        4 - correctAnswers
      );
      options = [...correctOptions, ...incorrectOptions];
    }
    return {
      isAddition,
      number1,
      number2,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { isAddition, number1, number2, options },
      translate,
      displayMode
    } = props;

    const items = options.map((rhs, id) => ({
      value: id,
      component: (
        <Text
          variant="WRN700"
          style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        >{`${number1.toLocaleString()} ${
          isAddition ? ADD : SUB
        } ${number2.toLocaleString()} = ${rhs[0].toLocaleString()} ${
          isAddition ? ADD : SUB
        } ${rhs[1].toLocaleString()}`}</Text>
      ),
      isCorrect: isAddition
        ? isEqual(number1 + number2)(sumNumberArray(rhs))
        : isEqual(number1 - number2)(rhs[0] - rhs[1])
    }));

    const correctAnswers = items.filter(val => val.isCorrect).map(val => val.value);
    return (
      <QF10SelectNumbers
        title={
          correctAnswers.length > 1
            ? translate.ks1Instructions.selectTheStatementsThatAreTrue()
            : translate.ks1Instructions.selectTheStatementThatIsTrue()
        }
        pdfTitle={
          correctAnswers.length > 1
            ? translate.ks1PDFInstructions.tickTheStatementsThatAreTrue()
            : translate.ks1PDFInstructions.tickTheStatementsThatIsTrue()
        }
        testCorrect={correctAnswers}
        items={items}
        multiSelect
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bhv',
  description: 'bhv',
  keywords: ['Compare', 'Equal to', 'Add', 'Subtract', 'Constant difference'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(96),
      number2: z.number().int().min(1).max(96),
      correctAnswer: z.tuple([z.number().int().min(1).max(99), z.number().int().min(1).max(99)]),
      options: z
        .object({
          numbers: z.tuple([z.number().int().min(1).max(99), z.number().int().min(1).max(99)]),
          symbol: z.enum([ADD, SUB])
        })
        .array()
        .length(3)
    })
    .refine(val => val.number1 - val.number2 > 0, 'answers greater than 0'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 96);
    const number2 = randomIntegerInclusive(1, number1 - 1);

    const correctAnswer = getRandomFromArray(
      [
        [number1 + 1, number2 + 1],
        [number1 + 2, number2 + 2],
        [number1 + 3, number2 + 3],
        [number1 + 4, number2 + 4],
        [number1 + 5, number2 + 5],
        [number1 + 10, number2 + 10],
        [number1 - 1, number2 - 1],
        [number1 - 2, number2 - 2],
        [number1 - 3, number2 - 3],
        [number1 - 4, number2 - 4],
        [number1 - 5, number2 - 5],
        [number1 - 10, number2 - 10]
      ].filter(
        val => val.every(num => isInRange(1, 99)(num)) && val[0] - val[1] > 0
      ) as NonEmptyArray<[number, number]>
    );

    const incorrectOptions = getRandomSubArrayFromArray(
      [
        { numbers: [number1 + 1, number2 - 1], symbol: SUB },
        { numbers: [number1 + 2, number2 - 2], symbol: SUB },
        { numbers: [number1 + 3, number2 - 3], symbol: SUB },
        { numbers: [number1 - 1, number2 + 1], symbol: SUB },
        { numbers: [number1 - 2, number2 + 2], symbol: SUB },
        { numbers: [number1 - 3, number2 + 3], symbol: SUB },
        { numbers: [number1, number2], symbol: ADD },
        { numbers: [number2, number1], symbol: ADD }
      ].filter(
        val =>
          val.numbers.every(num => isInRange(1, 99)(num)) &&
          (val.symbol === SUB
            ? val.numbers[0] - val.numbers[1] > 0
            : val.numbers[0] + val.numbers[1] <= 99)
      ) as NonEmptyArray<{ numbers: [number, number]; symbol: ADD | SUB }>,
      3
    );

    return {
      number1,
      number2,
      options: incorrectOptions,
      correctAnswer
    };
  },
  Component: props => {
    const {
      question: { correctAnswer, number1, number2, options },
      translate
    } = props;

    const random = seededRandom(props.question);

    const items = shuffle(
      [
        {
          value: 'A',
          component: (
            <Text variant="WRN700">{`${correctAnswer[0].toLocaleString()} ${SUB} ${correctAnswer[1].toLocaleString()}`}</Text>
          )
        },
        ...options.map(({ numbers, symbol }, id) => ({
          value: ['B', 'C', 'D'][id],
          component: (
            <Text variant="WRN700">{`${numbers[0].toLocaleString()} ${symbol} ${numbers[1].toLocaleString()}`}</Text>
          )
        }))
      ],
      { random }
    );

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheStatement()}
        pdfTitle={translate.ks1PDFInstructions.tickACardToCompleteTheComparison()}
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        testCorrect={['A']}
        items={items}
        pdfLayout="itemsBottom"
        pdfItemVariant="rectangle"
        sentence={`${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareNumberSentences',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
