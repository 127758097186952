import { newSmallStepContent } from '../../../SmallStep';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getItemArrangement,
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import {
  arraysHaveSameContents,
  filledArray,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import { bgy } from '../../../Year 2/Autumn/AdditionAndSubtraction/1BondsTo10';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import { generateRandomPartitions } from '../../../../utils/graphs';
import { View } from 'react-native';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getCounterArrangementScale } from '../../../../components/question/representations/CounterBoxArrangement/counterArrangements';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bb0',
  description: 'bb0',
  keywords: ['Ten', 'Ten frames', 'Counters'],
  schema: z.object({
    number: z.number().int().min(1).max(9),
    itemOrdering: z.enum(['fivewise', 'pairwise']),
    counterColor: z.enum(['red', 'yellow', 'blue', 'green'])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);
    const itemOrdering = getRandomFromArray(['fivewise', 'pairwise'] as const);
    const counterColor = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);

    return {
      number,
      itemOrdering,
      counterColor
    };
  },
  Component: ({ question, translate }) => {
    const { number, itemOrdering, counterColor } = question;

    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragCountersToMakeX((10).toLocaleString())}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToMakeX((10).toLocaleString())}
        initialState={[[...filledArray(counterColor, number), ...filledArray(null, 10 - number)]]}
        itemOrdering={itemOrdering === 'pairwise' ? 'columnFirst' : 'rowFirst'}
        interactiveCells="notInitial"
        items={[counterColor]}
        testCorrect={totalCountersIs(10)}
        exampleCorrectAnswer={[filledArray(counterColor, 10)]}
      />
    );
  }
});

const Question2: typeof bgy = {
  ...bgy,
  uid: 'bb1',
  description: 'bb1',
  keywords: ['Ten', 'Ten frames', 'Counters']
};

const Question3 = newQuestionContent({
  uid: 'bb2',
  description: 'bb2',
  keywords: ['Ten', 'Ten frames', 'Counters'],
  schema: z.object({
    randomObjects: z
      .array(
        z.object({
          representation: z.enum(['TenFrame', 'Random', 'Dominos']),
          total: z.number().int().min(7).max(12),
          counters: z.array(z.number().int()),
          counterColors: z.array(z.enum(['red', 'yellow', 'blue', 'green']))
        })
      )
      .length(4),
    smallCounters: z.boolean().optional()
  }),
  simpleGenerator: () => {
    // Use "large" or "small" counter representations
    // When using the Dominos, they are too small and look weird if the other representations are large.
    const smallCounters = getRandomBoolean();
    // We need to ensure there is at least 1 correct answer
    const randomObjects = rejectionSample(
      () => {
        return range(0, 3).map(() => {
          const representation = getRandomFromArray(
            smallCounters === true
              ? (['Random', 'Dominos'] as const)
              : (['TenFrame', 'Random'] as const)
          );
          const total =
            representation === 'TenFrame'
              ? randomIntegerInclusive(7, 10)
              : randomIntegerInclusive(7, 12);
          const counters =
            representation === 'TenFrame'
              ? generateRandomPartitions({
                  total,
                  parts: randomIntegerInclusive(1, 3),
                  min: 1,
                  max: 10
                })
              : representation === 'Dominos'
              ? generateRandomPartitions({
                  total,
                  parts: randomIntegerInclusive(2, 3),
                  min: 1,
                  max: 6
                })
              : [total];
          const counterColors = getRandomSubArrayFromArray(
            ['red', 'yellow', 'blue', 'green'] as const,
            counters.length
          );

          return { representation, total, counters, counterColors };
        });
      },
      x => {
        const isAtLeast1Correct = x.some(object => object.total === 10);

        const noDuplicates = x.every((object, i, arr) => {
          const { representation, counters, counterColors, total } = object;

          const clonedArray = [...arr];
          clonedArray.splice(i, 1);
          const duplicatesArr = clonedArray.filter(otherObject => {
            return otherObject.representation === representation &&
              otherObject.total === total &&
              arraysHaveSameContents(otherObject.counters, counters) &&
              representation === 'Dominos' // Dominos will generate 3 colours but we only use 1 so we don't want to test all of them here just incase the first is the same colour and the rest aren't
              ? otherObject.counterColors[0] === counterColors[0]
              : arraysHaveSameContents(otherObject.counterColors, counterColors);
          });

          return duplicatesArr.length === 0;
        });

        return isAtLeast1Correct && noDuplicates;
      }
    );

    return { randomObjects, smallCounters };
  },
  Component: props => {
    const {
      question: { randomObjects, smallCounters = false },
      translate
    } = props;

    const items = randomObjects.map(
      ({ total, counters, counterColors, representation }, index) => ({
        value: index,
        component: (() => {
          switch (representation) {
            case 'TenFrame':
              return (
                <TenFrameLayout
                  items={range(0, counters.length - 1)
                    .map(value => filledArray(counterColors[value], counters[value]))
                    .flat()}
                />
              );
            case 'Dominos': {
              const scale = getCounterArrangementScale(
                sortNumberArray([...counters], 'descending')[0]
              );
              return (
                <View style={{ flexDirection: 'row', justifyContent: 'center', padding: 0 }}>
                  {counters.map((counter, idx) => (
                    <CounterBoxArrangement
                      key={idx}
                      counters={counter}
                      scale={scale}
                      color={counterColors[0]} // We only have 1 colour for dominos
                      seed={props.question}
                      dimens={{ height: 150, width: 150 }}
                      noBorder
                      noBackground
                      borderStyle={{ borderWidth: 0.5, borderColor: colors.prussianBlue }}
                    />
                  ))}
                </View>
              );
            }
            case 'Random': {
              const generateCounters = (number: number) => {
                return range(0, number - 1).map(i => {
                  const color = counterColors[0];
                  const name: SvgName = `Circles/circle_${color}`;
                  return (
                    <AssetSvg
                      key={`${counterColors[0]}-${i}`}
                      name={name}
                      width={smallCounters === true ? 25 : 60}
                    />
                  );
                });
              };

              const arrangement = getItemArrangement(
                generateCounters(total),
                seededRandom(props.question),
                3,
                4
              );

              return (
                <>
                  {arrangement.map((row, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={{
                        flexDirection: 'row',
                        height: smallCounters === true ? 35 : 70,
                        alignItems: 'center'
                      }}
                    >
                      {row.map(({ marginLeft, item }, itemIndex) => (
                        <View key={itemIndex} style={{ marginLeft: marginLeft / 1.5 }}>
                          {item}
                        </View>
                      ))}
                    </View>
                  ))}
                </>
              );
            }
          }
        })(),
        isCorrect: total === 10
      })
    );

    const testCorrect = items.filter(it => it.isCorrect).map(it => it.value);

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheBoxesThatShowX(testCorrect.length, 10)}
        pdfTitle={translate.ks1PDFInstructions.tickTheBoxesThatShowX(testCorrect.length, 10)}
        testCorrect={testCorrect}
        numItems={4}
        multiSelect
        renderItems={items}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Understand10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
