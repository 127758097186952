import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import { arraysHaveSameContentsUnordered } from '../../../../utils/collections';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aLw',
  description: 'aLw',
  keywords: ['Perimeter', 'Rectangle', 'Centimetres'],
  schema: z.object({
    width: z.number().int().min(4).max(8),
    height: z.number().int().min(1).max(6)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const width = randomIntegerInclusive(4, 8);
    const height = randomIntegerInclusive(1, 6, { constraint: x => x !== width });
    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;

    const perimeter = height + height + width + width;
    const expectedCalc = [height.toString(), height.toString(), width.toString(), width.toString()];
    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCmPlusAnsCmPlusAnsCmPlusAnsCmEqualsAns()}
        title={translate.instructions.workOutPerimeterOfRectangle()}
        testCorrect={userAnswer => {
          const calc = userAnswer.slice(0, 4);
          const answer = userAnswer[4];

          return (
            arraysHaveSameContentsUnordered(calc, expectedCalc) && answer === perimeter.toString()
          );
        }}
        inputMaxCharacters={1}
        sentenceStyle={{ justifyContent: 'flex-start' }}
        textStyle={{ fontSize: 22 }}
        pdfDirection="column"
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={[
                [0, 0],
                [0, height],
                [width, height],
                [width, 0]
              ]}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [...expectedCalc, perimeter.toString()],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
      />
    );
  }
});

const Question1V2 = newQuestionContent({
  uid: 'aLw2',
  description: 'aLw',
  keywords: ['Perimeter', 'Rectangle', 'Centimetres'],
  schema: z.object({
    width: z.number().int().min(4).max(8),
    height: z.number().int().min(1).max(6)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const width = randomIntegerInclusive(4, 8);
    const height = randomIntegerInclusive(1, 6, { constraint: x => x !== width });
    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;

    const perimeter = height + height + width + width;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCm()}
        title={translate.instructions.workOutPerimeterOfRectangle()}
        testCorrect={[perimeter.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={dimens}
              points={[
                [0, 0],
                [0, height],
                [width, height],
                [width, 0]
              ]}
              cellSizeLabel={translate.units.numberOfCm(1)}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aLx',
  description: 'aLx',
  keywords: ['Perimeter', 'Rectangle', 'Centimetres'],
  schema: z.object({
    widthA: z.number().int().min(2).max(12),
    heightA: z.number().int().min(2).max(5),
    widthB: z.number().int().min(2).max(12),
    heightB: z.number().int().min(2).max(5),
    perimeterA: z.number().int().min(8).max(34),
    rotateA: numberEnum([-90, -45, 0, 45, 90]),
    rotateB: numberEnum([-90, -45, 0, 45, 90])
  }),
  simpleGenerator: () => {
    const { heightA, heightB, widthA, widthB, perimeterA } = rejectionSample(
      () => {
        const heightA = randomIntegerInclusive(2, 5);
        const heightB = randomIntegerInclusive(2, 5);

        const widthA = randomIntegerInclusive(2, 12, { constraint: x => x !== heightA });
        const widthB = randomIntegerInclusive(2, 12, { constraint: x => x !== heightB });

        const perimeterA = 2 * (heightA + widthA);
        const perimeterB = 2 * (heightB + widthB);

        return { heightA, heightB, widthA, widthB, perimeterA, perimeterB };
      },
      ({ perimeterA, perimeterB }) => perimeterA !== perimeterB
    );

    const rotateA = getRandomFromArray([-90, -45, 0, 45, 90] as const);
    const rotateB = getRandomFromArray([-90, -45, 0, 45, 90] as const);

    return { heightA, heightB, widthA, widthB, perimeterA, rotateA, rotateB };
  },
  Component: props => {
    const {
      question: { heightA, heightB, widthA, widthB, perimeterA, rotateA, rotateB },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: 'A',
          labels: [
            translate.units.numberOfCm(widthA),
            translate.units.numberOfCm(heightA),
            translate.units.numberOfCm(widthA),
            translate.units.numberOfCm(heightA)
          ],
          scaleHeight: heightA * 20,
          scaleWidth: widthA * 20,
          rotate: rotateA
        },
        {
          value: 'B',
          labels: [
            translate.units.numberOfCm(widthB),
            translate.units.numberOfCm(heightB),
            translate.units.numberOfCm(widthB),
            translate.units.numberOfCm(heightB)
          ],
          scaleHeight: heightB * 20,
          scaleWidth: widthB * 20,
          rotate: rotateB
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapeWithThePerimeterXcm(perimeterA)}
        testCorrect={['A']}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(({ value, labels, scaleHeight, scaleWidth, rotate }) => ({
            component: (
              <LabelledQuadrilateral
                dimens={{ height: dimens.height + scaleHeight, width: dimens.width + scaleWidth }}
                shape={'Rectangle4Arrows'}
                labels={labels}
                shapeSize="large"
                rotate={rotate}
              />
            ),
            value
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aLy',
  description: 'aLy',
  keywords: ['Perimeter', 'Rectangle', 'Centimetres'],
  schema: z.object({
    width: z.number().int().min(2).max(12),
    height: z.number().int().min(5).max(12)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 12);
    const height = randomIntegerInclusive(5, 12, { constraint: x => x !== width });
    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;

    const rotate = getRandomFromArray([-90, -45, 0, 45, 90], {
      random: seededRandom(props.question)
    });

    const answer = height + height + width + width;

    const labels =
      width > height
        ? ['', '', translate.units.numberOfCm(width), translate.units.numberOfCm(height)]
        : ['', '', translate.units.numberOfCm(height), translate.units.numberOfCm(width)];

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCm()}
        title={translate.instructions.workOutPerimeterOfRectangle()}
        testCorrect={[answer.toString()]}
        inputMaxCharacters={1}
        sentenceStyle={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
        textStyle={{ fontSize: 22 }}
        Content={({ dimens }) => {
          return (
            <LabelledQuadrilateral
              dimens={{ height: dimens.height + height * 15, width: dimens.width + width * 15 }}
              shape={'Rectangle2Arrows'}
              labels={labels}
              shapeSize="large"
              rotate={rotate}
            />
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aLz',
  description: 'aLz',
  keywords: ['Perimeter', 'Rectangle', 'Kilometres', 'Metres'],
  schema: z.object({
    width: z.number().int().min(2).max(8),
    height: z.number().int().min(100).max(400).step(100)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 8);
    const height = randomIntegerInclusiveStep(100, 400, 100);
    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;

    const rotate = getRandomFromArray([-90, -45, 0, 45, 90], {
      random: seededRandom(props.question)
    });

    const answerKm = width + width;
    const answerM = height + height;

    const labels = ['', '', translate.units.numberOfKm(width), translate.units.numberOfM(height)];

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansKmAnsM()}
        title={translate.instructions.workOutPerimeterOfRectangle()}
        testCorrect={[answerKm.toString(), answerM.toString()]}
        inputMaxCharacters={1}
        sentenceStyle={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
        textStyle={{ fontSize: 22 }}
        Content={({ dimens }) => {
          return (
            <LabelledQuadrilateral
              dimens={{ height: dimens.height + height * 15, width: dimens.width + width * 15 }}
              shape={'Rectangle2Arrows'}
              labels={labels}
              shapeSize="large"
              rotate={rotate}
            />
          );
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aLA',
  description: 'aLA',
  keywords: ['Perimeter', 'Rectangle', 'Centimetres'],
  schema: z.object({
    height: z.number().int().min(2).max(5),
    width: z.number().int().min(2).max(6),
    stickLength: numberEnum([3, 4, 5, 6, 8, 10]),
    rotation: numberEnum([45, 0, 315])
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(3, 6);
    const height = randomIntegerInclusive(2, 5, { constraint: x => x < width });
    const stickLength = getRandomFromArray([3, 4, 5, 6, 8, 10] as const);
    // for spacing restrict rotation
    const rotation = width > 4 || height > 4 ? 0 : getRandomFromArray([45, 0, 315] as const);
    return { height, width, stickLength, rotation };
  },
  Component: props => {
    const {
      question: { height, width, stickLength, rotation },
      translate
    } = props;

    const answer = (height + width) * 2 * stickLength;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansCm()}
        title={translate.instructions.eachLollyStickXLongFindPerimeter(
          stickLength.toLocaleString()
        )}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <DisplayShapeOnGridWithBorder
              dimens={{ width: dimens.width, height: dimens.height }}
              points={[
                [0, 0],
                [0, height],
                [width, height],
                [width, 0]
              ]}
              noGrid
              isLollySticks
              rotation={rotation}
            />
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aLB',
  description: 'aLB',
  keywords: ['Perimeter', 'Rectangle', 'Centimetres'],
  schema: z.object({
    width: z.number().int().min(2).max(6),
    height: z.number().int().min(6).max(15)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 6);
    const height = randomIntegerInclusive(6, 15, { constraint: x => x !== width });
    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;

    const perimeter = height + height + width + width;

    return (
      <QF2AnswerBoxOneSentence
        sentence={translate.answerSentences.ansCm()}
        title={translate.instructions.thePerimeterOfShapeIsNumTheLengthOfShapeIsNumWhatIsWidthOfShape(
          'rectangle',
          perimeter,
          height
        )}
        testCorrect={[width.toString()]}
        inputMaxCharacters={1}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PerimeterOfARectangle',
  questionTypes: [Question1V2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
