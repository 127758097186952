import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  displayMoney,
  numberToPennyPoundOrPence,
  totalPenceToPoundsAndPence
} from '../../../../utils/money';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { countRange } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfo',
  description: 'bfo',
  keywords: ['Coins', 'Pounds', 'Pence'],
  schema: z.object({
    incorrectAnswers: z.array(z.number()),
    correctAnswers: z.array(numberEnum([500, 1000, 2000, 5000])).length(2),
    isMoneyAnSelectable: z.boolean()
  }),
  simpleGenerator: () => {
    const [extraAnswerA, extraAnswerB, ...correctAnswers] = shuffle([
      500, 1000, 2000, 5000
    ] as const);

    const incorrectAnswers = getRandomSubArrayFromArray(
      [correctAnswers[0] / 100, correctAnswers[1] / 100, extraAnswerA, extraAnswerB],
      2
    );

    const isMoneyAnSelectable = getRandomBoolean();

    return { correctAnswers, incorrectAnswers, isMoneyAnSelectable };
  },
  Component: props => {
    const {
      question: { correctAnswers, incorrectAnswers, isMoneyAnSelectable },
      translate,
      displayMode
    } = props;

    const coinAndNotesSvgs = displayMoney(
      [
        ...correctAnswers.map(num => totalPenceToPoundsAndPence(num)[0]),
        ...incorrectAnswers.map(num => totalPenceToPoundsAndPence(num)[0])
      ],
      displayMode === 'digital' ? 90 : 120,
      displayMode === 'digital' ? 90 : 120,
      true,
      true
    );

    const money = [...correctAnswers, ...incorrectAnswers].map((money, index) => {
      return {
        value: money,
        string: numberToPennyPoundOrPence(money, translate),
        component: isMoneyAnSelectable ? (
          <View>{coinAndNotesSvgs[index]}</View>
        ) : (
          numberToPennyPoundOrPence(money, translate)
        )
      };
    });

    const statements = [money[0], money[1]].map(({ value, string }, index) => {
      return {
        lhsComponent: (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              flex: displayMode === 'digital' ? 1 : undefined,
              justifyContent: 'space-evenly'
            }}
          >
            {isMoneyAnSelectable ? (
              <Text variant="WRN400">{string}</Text>
            ) : (
              <View>{coinAndNotesSvgs[index]}</View>
            )}
            {displayMode === 'digital' ? <Text variant="WRN400">=</Text> : null}
          </View>
        ),
        correctAnswer: value
      };
    });

    const items = shuffle(money, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheCorrectValueToTheNotes()}
        pdfTitle={translate.ks1PDFInstructions.matchTheNotesToTheCorrectValues()}
        statements={statements}
        mainPanelStyle={{ justifyContent: 'space-evenly' }}
        statementStyle={{ justifyContent: 'center' }}
        items={items.map(({ value, component }) => ({
          value: value,
          component
        }))}
        useRedLinesOnMarkScheme={false}
        actionPanelVariant="endWide"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bfp',
  description: 'bfp',
  keywords: ['Notes', 'Pounds'],
  schema: z.object({
    titleNoteValue: numberEnum([500, 1000, 2000, 5000]),
    totalNumberOfTitleNotes: z.number().int().min(2).max(8),
    otherNotesValues: z.array(numberEnum([500, 1000, 2000, 5000])),
    randomA: z.number().int().min(1).max(1000),
    randomB: z.number().int().min(1).max(1000)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const values = [500, 1000, 2000, 5000] as const;

    const titleNoteValue = getRandomFromArray(values);

    const totalNumberOfTitleNotes = randomIntegerInclusive(2, 8);

    const remainingValues = [...values.filter(num => num !== titleNoteValue)];

    const otherNotesValues: (500 | 1000 | 2000 | 5000)[] = [];

    countRange(10 - totalNumberOfTitleNotes).forEach(() => {
      const num = getRandomFromArray(remainingValues);
      if (num) otherNotesValues.push(num);
    });

    const randomA = randomIntegerInclusive(1, 1000);
    const randomB = randomIntegerInclusive(1, 1000);

    return {
      titleNoteValue,
      totalNumberOfTitleNotes,
      otherNotesValues,
      randomA,
      randomB
    };
  },
  Component: props => {
    const {
      question: { titleNoteValue, totalNumberOfTitleNotes, otherNotesValues, randomA, randomB },
      translate,
      displayMode
    } = props;

    const name = numberToPennyPoundOrPence(titleNoteValue, translate, true);

    const money = countRange(totalNumberOfTitleNotes).map(() => titleNoteValue);

    const denominations = shuffle(
      [...money, ...otherNotesValues].map(number => totalPenceToPoundsAndPence(number)[0]),
      { random: seededRandom(props.question) }
    );

    const arrangement = getRandomBooleanArray(
      4,
      4,
      denominations.length,
      seededRandom({ randomA, randomB })
    );

    const notesSvgs = displayMoney(
      denominations,
      displayMode === 'digital' ? 90 : 150,
      displayMode === 'digital' ? 90 : 150,
      true
    );

    let denominationIndex = 0;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyXNotesAreThere(name)}
        questionHeight={1200}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            {arrangement.map((row, rowIndex) => (
              <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                {row.map((cell, colIndex) => (
                  <View
                    key={`cell-${rowIndex}-${colIndex}`}
                    style={{
                      width: dimens.width / 4,
                      height: dimens.height / 4,
                      padding: 8
                    }}
                  >
                    {cell ? notesSvgs[denominationIndex++] : null}
                  </View>
                ))}
              </View>
            ))}
          </View>
        )}
        sentence={translate.ks1AnswerSentences.thereAreAnsXNotes(name)}
        testCorrect={[totalNumberOfTitleNotes.toString()]}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bfq',
  description: 'bfq',
  keywords: ['Coins', 'Pounds', 'Notes', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    isOptionAWords: z.boolean(),
    isOptionBWords: z.boolean(),
    optionAValue: numberEnum([100, 200, 500, 1000, 2000, 5000]),
    optionBValue: numberEnum([100, 200, 500, 1000, 2000, 5000])
  }),
  simpleGenerator: () => {
    const valuesA = getRandomFromArrayWithWeights(
      [
        [500, 1000, 2000, 5000],
        [200, 100, 500, 1000, 2000, 5000]
      ] as const,
      [4, 1]
    );

    const valuesB = getRandomFromArrayWithWeights(
      [
        [500, 1000, 2000, 5000],
        [200, 100, 500, 1000, 2000, 5000]
      ] as const,
      [4, 1]
    );

    const optionAValue = getRandomFromArray(valuesA);
    const optionBValue = getRandomFromArray(valuesB);

    const isOptionAWords = getRandomBoolean();
    const isOptionBWords = optionAValue === optionBValue ? !isOptionAWords : getRandomBoolean();

    return { isOptionAWords, isOptionBWords, optionAValue, optionBValue };
  },
  Component: ({
    question: { isOptionAWords, isOptionBWords, optionAValue, optionBValue },
    translate,
    displayMode
  }) => {
    const coinAndNotesSvgs = displayMoney(
      [...totalPenceToPoundsAndPence(optionAValue), ...totalPenceToPoundsAndPence(optionBValue)],
      displayMode === 'digital' ? 90 : 150,
      displayMode === 'digital' ? 90 : 150,
      true
    );

    const lhsComponent = isOptionAWords ? (
      <Text variant="WRN400">{numberToPennyPoundOrPence(optionAValue, translate)}</Text>
    ) : (
      <View>{coinAndNotesSvgs[0]}</View>
    );

    const rhsComponent = isOptionBWords ? (
      <View>
        <Text variant="WRN400">{numberToPennyPoundOrPence(optionBValue, translate)}</Text>
      </View>
    ) : (
      <View>{coinAndNotesSvgs[1]}</View>
    );

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheAmounts()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheAmounts()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={[
          {
            lhsComponent,
            rhsComponent,
            correctAnswer: lessThanGreaterThanOrEqualTo(optionAValue, optionBValue)
          }
        ]}
        statementStyle={{ justifyContent: 'center', gap: displayMode === 'digital' ? 16 : 32 }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseNotes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
