import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { View } from 'react-native';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom
} from '../../../../utils/random';
import { barModelColors } from '../../../../theme/colors';
import { arraysHaveSameContents, countRange, filledArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { SUB } from '../../../../constants';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { compareFractions } from '../../../../utils/fractions';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNs',
  description: 'aNs',
  keywords: [
    'Subtraction',
    'Reduction',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(8),
      whole1: z.number().int().min(2).max(3),
      whole2: z.number().int().min(1).max(3),
      numerator: z.number().int().min(1).max(7)
    })
    .refine(
      val => val.numerator < val.denominator,
      'The numerator must be less than the denominator.'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const whole1 = randomIntegerInclusive(2, 3);
    const whole2 = randomIntegerInclusive(1, whole1);

    return { numerator, denominator, whole1, whole2 };
  },
  Component: props => {
    const {
      question: { numerator, denominator, whole1, whole2 },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const wholes = countRange(whole1).map(() => filledArray(numeratorColor, denominator));
    const remainder = [
      ...filledArray(numeratorColor, numerator),
      ...filledArray('white', denominator - numerator)
    ];

    const barModelArrays = [...wholes, remainder];
    const fracAnswer =
      whole2 === whole1 ? `<frac  nAns='' dAns=''/>` : `<frac wAns='' nAns='' dAns=''/>`;

    const displayAnswers =
      whole2 === whole1
        ? [numerator.toLocaleString(), denominator.toLocaleString()]
        : [
            (whole1 - whole2).toLocaleString(),
            numerator.toLocaleString(),
            denominator.toLocaleString()
          ];

    return (
      <QF1ContentAndSentence
        sentence={`<frac w='${whole1.toLocaleString()}' n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${SUB} ${whole2.toLocaleString()} = ${fracAnswer}`}
        title={translate.instructions.useBarModelToWorkOutSubtraction()}
        testCorrect={answer =>
          whole2 === whole1
            ? compareFractions([answer[0], answer[1]], [numerator, denominator])
            : compareFractions(
                [answer[0], answer[1], answer[2]],
                [whole1 - whole2, numerator, denominator]
              )
        }
        inputMaxCharacters={1}
        textStyle={{ fontSize: 40 }}
        Content={({ dimens }) => (
          <View style={{ gap: 5 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / (barModelArrays.length + 1)}
                customColorMap={array}
                isFullStrikeThrough={idx < whole2}
              />
            ))}
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: displayAnswers,
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aNt',
  description: 'aNt',
  keywords: ['Subtraction', 'Fraction', 'Mixed number', 'Integer', 'Numerator', 'Denominator'],
  schema: z
    .object({
      denominator1: z.number().int().min(3).max(12),
      numerator1: z.number().int().min(1).max(11),
      whole1A: z.number().int().min(2).max(12),
      whole1B: z.number().int().min(1).max(12),
      denominator2: z.number().int().min(10).max(20),
      numerator2: z.number().int().min(1).max(19),
      whole2A: z.number().int().min(10).max(50),
      whole2B: z.number().int().min(1).max(50),
      isLeft: z.array(z.boolean()).length(2)
    })
    .refine(val => val.denominator1 > val.numerator1, 'numerator1 should be less than denominator1')
    .refine(
      val => val.denominator2 > val.numerator2,
      'numerator2 should be less than denominator2'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 12);
    const numerator1 = randomIntegerInclusive(1, denominator1 - 1);
    const whole1A = randomIntegerInclusive(2, 12);
    const whole1B = randomIntegerInclusive(1, whole1A);
    const denominator2 = randomIntegerInclusive(10, 20);
    const numerator2 = randomIntegerInclusive(1, denominator2 - 1);
    const whole2A = randomIntegerInclusive(10, 50);
    const whole2B = randomIntegerInclusive(1, whole2A);
    const isLeft = countRange(2).map(() => getRandomBoolean());

    return {
      denominator1,
      denominator2,
      numerator1,
      numerator2,
      whole1A,
      whole1B,
      whole2A,
      whole2B,
      isLeft
    };
  },

  Component: props => {
    const {
      question: {
        denominator1,
        denominator2,
        numerator1,
        numerator2,
        whole1A,
        whole1B,
        whole2A,
        whole2B,
        isLeft
      },
      translate
    } = props;

    const fracAnswer1 =
      whole1A === whole1B ? `<frac  nAns='' dAns=''/>` : `<frac wAns='' nAns='' dAns=''/>`;
    const fracAnswer2 =
      whole2A === whole2B ? `<frac  nAns='' dAns=''/>` : `<frac wAns='' nAns='' dAns=''/>`;

    const eqA = isLeft[0]
      ? `<frac w='${whole1A.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}'/> - ${whole1B.toLocaleString()} = ${fracAnswer1}`
      : `${fracAnswer1} = <frac w='${whole1A.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}'/> - ${whole1B.toLocaleString()}`;

    const eqB = isLeft[1]
      ? `<frac w='${whole2A.toLocaleString()}' n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}'/> - ${whole2B.toLocaleString()} = ${fracAnswer2}`
      : `${fracAnswer2} = <frac w='${whole2A.toLocaleString()}' n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}'/> - ${whole2B.toLocaleString()}`;

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSubtractions()}
        questionHeight={1000}
        testCorrect={userAnswer => {
          const answer1 =
            whole1A === whole1B
              ? compareFractions([userAnswer[0][0], userAnswer[0][1]], [numerator1, denominator1])
              : compareFractions(
                  [userAnswer[0][0], userAnswer[0][1], userAnswer[0][2]],
                  [whole1A - whole1B, numerator1, denominator1]
                );
          const answer2 =
            whole2A === whole2B
              ? compareFractions([userAnswer[1][0], userAnswer[1][1]], [numerator2, denominator2])
              : compareFractions(
                  [userAnswer[1][0], userAnswer[1][1], userAnswer[1][2]],
                  [whole2A - whole2B, numerator2, denominator2]
                );
          return answer1 && answer2;
        }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              (whole1A - whole1B).toLocaleString(),
              numerator1.toLocaleString(),
              denominator1.toLocaleString()
            ],
            [
              (whole2A - whole2B).toLocaleString(),
              numerator2.toLocaleString(),
              denominator2.toLocaleString()
            ]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        sentences={eqs}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aNt2',
  description: 'aNt',
  keywords: ['Subtraction', 'Fraction', 'Mixed number', 'Integer', 'Numerator', 'Denominator'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(20),
      numerator: z.number().int().min(1).max(19),
      whole1A: z.number().int().min(2).max(50),
      whole1B: z.number().int().min(1).max(50),
      isLeft: z.boolean()
    })
    .refine(val => val.denominator > val.numerator, 'numerator should be less than denominator'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const randomSet = getRandomBoolean();
    const denominator = randomSet ? randomIntegerInclusive(3, 12) : randomIntegerInclusive(10, 20);
    const numerator = randomIntegerInclusive(1, denominator - 1);
    const whole1A = randomSet ? randomIntegerInclusive(2, 12) : randomIntegerInclusive(10, 50);
    const whole1B = randomIntegerInclusive(1, whole1A);
    const isLeft = getRandomBoolean();

    return {
      denominator,
      numerator,
      whole1A,
      whole1B,
      isLeft
    };
  },

  Component: props => {
    const {
      question: { denominator, numerator, whole1A, whole1B, isLeft },
      translate,
      displayMode
    } = props;

    const fracAnswer1 =
      whole1A === whole1B ? `<frac  nAns='' dAns=''/>` : `<frac wAns='' nAns='' dAns=''/>`;

    const eq = isLeft
      ? `<frac w='${whole1A.toLocaleString()}' n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${SUB} ${whole1B.toLocaleString()} = ${fracAnswer1}`
      : `${fracAnswer1} = <frac w='${whole1A.toLocaleString()}' n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${SUB} ${whole1B.toLocaleString()}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        questionHeight={1000}
        testCorrect={userAnswer => {
          const answer =
            whole1A === whole1B
              ? compareFractions([userAnswer[0], userAnswer[1]], [numerator, denominator])
              : compareFractions(
                  [userAnswer[0], userAnswer[1], userAnswer[2]],
                  [whole1A - whole1B, numerator, denominator]
                );

          return answer;
        }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 48 }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay:
            whole1A === whole1B
              ? [numerator.toLocaleString(), denominator.toLocaleString()]
              : [
                  (whole1A - whole1B).toLocaleString(),
                  numerator.toLocaleString(),
                  denominator.toLocaleString()
                ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        sentence={eq}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aNu',
  description: 'aNu',
  keywords: [
    'Subtraction',
    'Reduction',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(8),
      whole1: z.number().int().min(2).max(4),
      numerator1: z.number().int().min(2).max(7),
      numerator2: z.number().int().min(1).max(6)
    })
    .refine(
      val => val.denominator > val.numerator1 && val.denominator > val.numerator2,
      'numerators must be smaller than denominators'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator1 = randomIntegerInclusive(2, denominator - 1);
    const numerator2 = randomIntegerInclusive(1, numerator1 - 1);
    const whole1 = randomIntegerInclusive(2, 4);

    return { numerator1, denominator, whole1, numerator2 };
  },
  Component: props => {
    const {
      question: { numerator1, denominator, whole1, numerator2 },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const wholes = countRange(whole1).map(() => filledArray(numeratorColor, denominator));
    const remainder = [
      ...filledArray(numeratorColor, numerator1),
      ...filledArray('white', denominator - numerator1)
    ];

    const barModelArrays = [...wholes, remainder];

    return (
      <QF1ContentAndSentence
        sentence={`<frac w='${whole1.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${SUB} <frac n='${numerator2.toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        title={translate.instructions.useBarModelToWorkOutSubtraction()}
        testCorrect={answer =>
          compareFractions(
            [answer[0], answer[1], answer[2]],
            [whole1, numerator1 - numerator2, denominator]
          )
        }
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <View style={{ gap: 5 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / (barModelArrays.length + 1)}
                customColorMap={array}
                strikeThroughIndexes={
                  idx === barModelArrays.length - 1
                    ? countRange(numerator2, numerator1 - numerator2)
                    : undefined
                }
              />
            ))}
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole1.toLocaleString(),
            (numerator1 - numerator2).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aNv',
  description: 'aNv',
  keywords: ['Subtraction', 'Fraction', 'Mixed number', 'Integer', 'Numerator', 'Denominator'],
  questionHeight: 1000,
  schema: z
    .object({
      denominator1: z.number().int().min(3).max(12),
      numerator1A: z.number().int().min(2).max(11),
      numerator1B: z.number().int().min(1).max(10),
      whole1: z.number().int().min(2).max(10),
      denominator2: z.number().int().min(3).max(12),
      numerator2A: z.number().int().min(2).max(12),
      numerator2B: z.number().int().min(1).max(11),
      whole2A: z.number().int().min(2).max(10),
      whole2B: z.number().int().min(1).max(9)
    })
    .refine(
      val => val.denominator1 > val.numerator1A && val.denominator1 > val.numerator1B,
      'numerators must be smaller than denominators'
    )
    .refine(
      val => val.denominator2 > val.numerator2A && val.denominator2 > val.numerator2B,
      'numerators must be smaller than denominators'
    ),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 12);
    const numerator1A = randomIntegerInclusive(2, denominator1 - 1);
    const numerator1B = randomIntegerInclusive(1, numerator1A - 1);
    const whole1 = randomIntegerInclusive(2, 10);
    const denominator2 = randomIntegerInclusive(3, 12);
    const numerator2A = randomIntegerInclusive(2, denominator2 - 1);
    const numerator2B = randomIntegerInclusive(1, numerator2A - 1);
    const whole2A = randomIntegerInclusive(2, 10);
    const whole2B = randomIntegerInclusive(1, whole2A - 1);

    return {
      denominator1,
      denominator2,
      numerator1A,
      numerator1B,
      whole1,
      numerator2A,
      numerator2B,
      whole2A,
      whole2B
    };
  },

  Component: props => {
    const {
      question: {
        denominator1,
        denominator2,
        numerator1A,
        numerator1B,
        whole1,
        numerator2A,
        numerator2B,
        whole2A,
        whole2B
      },
      translate
    } = props;

    const eqA = `<frac w='${whole1.toLocaleString()}' n='${numerator1A.toLocaleString()}' d='${denominator1.toLocaleString()}'/> ${SUB} <frac n='${numerator1B.toLocaleString()}' d='${denominator1.toLocaleString()}'/>  = <frac wAns='' nAns='' dAns='' />`;

    const eqB = `<frac w='${whole2A.toLocaleString()}' n='${numerator2A.toLocaleString()}' d='${denominator2.toLocaleString()}'/> ${SUB} <frac n='${numerator2B.toLocaleString()}' d='${denominator2.toLocaleString()}'/> ${SUB} ${whole2B.toLocaleString()}  = <frac wAns='' nAns='' dAns='' />`;

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSubtractions()}
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0][0], userAnswer[0][1], userAnswer[0][2]],
            [whole1, numerator1A - numerator1B, denominator1]
          ) &&
          compareFractions(
            [userAnswer[1][0], userAnswer[1][1], userAnswer[1][2]],
            [whole2A - whole2B, numerator2A - numerator2B, denominator2]
          )
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              whole1.toLocaleString(),
              (numerator1A - numerator1B).toLocaleString(),
              denominator1.toLocaleString()
            ],
            [
              (whole2A - whole2B).toLocaleString(),
              (numerator2A - numerator2B).toLocaleString(),
              denominator2.toLocaleString()
            ]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1000}
        sentences={eqs}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aNv2',
  description: 'aNv',
  keywords: ['Subtraction', 'Fraction', 'Mixed number', 'Integer', 'Numerator', 'Denominator'],
  questionHeight: 1000,
  schema: z
    .object({
      denominator: z.number().int().min(3).max(12),
      numerator1: z.number().int().min(2).max(12),
      numerator2: z.number().int().min(1).max(11),
      whole1: z.number().int().min(2).max(10),
      whole2: z.number().int().min(1).max(9)
    })
    .refine(
      val => val.denominator > val.numerator2 && val.denominator > val.numerator2,
      'numerators must be smaller than denominators'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 12);
    const numerator1 = randomIntegerInclusive(2, denominator - 1);
    const numerator2 = randomIntegerInclusive(1, numerator1 - 1);
    const whole1 = randomIntegerInclusive(2, 10);
    const whole2 = randomIntegerInclusive(1, whole1 - 1);

    return {
      denominator,
      numerator1,
      numerator2,
      whole1,
      whole2
    };
  },

  Component: props => {
    const {
      question: { denominator, numerator1, numerator2, whole1, whole2 },
      translate,
      displayMode
    } = props;

    const eq = `<frac w='${whole1.toLocaleString()}' n='${numerator1}' d='${denominator}'/> ${SUB} <frac n='${numerator2}' d='${denominator}'/> ${SUB} ${whole2.toLocaleString()}  = <frac wAns='' nAns='' dAns='' />`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0], userAnswer[1], userAnswer[2]],
            [whole1 - whole2, numerator1 - numerator2, denominator]
          )
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (whole1 - whole2).toLocaleString(),
            (numerator1 - numerator2).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        questionHeight={1000}
        sentence={eq}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 48 }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aNw',
  description: 'aNw',
  keywords: [
    'Subtraction',
    'Reduction',
    'Difference',
    'Convert',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(8),
      whole1: z.number().int().min(2).max(4),
      numerator1: z.number().int().min(1).max(7),
      numerator2: z.number().int().min(2).max(7)
    })
    .refine(
      val => val.denominator > val.numerator1 && val.denominator > val.numerator2,
      'numerators must be smaller than denominators'
    ),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator1 = randomIntegerInclusive(1, denominator - 2);
    const numerator2 = randomIntegerInclusive(numerator1 + 1, denominator - 1);
    const whole1 = randomIntegerInclusive(2, 4);

    return { numerator1, denominator, whole1, numerator2 };
  },
  Component: props => {
    const {
      question: { numerator1, denominator, whole1, numerator2 },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const wholes = countRange(whole1).map(() => filledArray(numeratorColor, denominator));
    const remainder = [
      ...filledArray(numeratorColor, numerator1),
      ...filledArray('white', denominator - numerator1)
    ];

    const barModelArrays = [...wholes, remainder];

    return (
      <QF1ContentAndSentence
        sentence={`<frac w='${whole1.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${SUB} <frac n='${numerator2.toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        title={translate.instructions.useBarModelToWorkOutSubtraction()}
        testCorrect={answer =>
          compareFractions(
            [answer[0], answer[1], answer[2]],
            [whole1 - 1, denominator + numerator1 - numerator2, denominator]
          )
        }
        inputMaxCharacters={1}
        Content={({ dimens }) => (
          <View style={{ gap: 5 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / (barModelArrays.length + 1)}
                customColorMap={array}
                strikeThroughIndexes={
                  idx === barModelArrays.length - 1
                    ? countRange(numerator1)
                    : idx === barModelArrays.length - 2
                    ? countRange(numerator2 - numerator1, denominator - numerator2 + numerator1)
                    : undefined
                }
              />
            ))}
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (whole1 - 1).toLocaleString(),
            (denominator + numerator1 - numerator2).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aNx',
  description: 'aNx',
  keywords: [
    'Subtraction',
    'Fraction',
    'Integer',
    'Difference',
    'Numerator',
    'Denominator',
    'Mixed number'
  ],
  questionHeight: 1000,
  schema: z
    .object({
      denominator1: z.number().int().min(3).max(12),
      numerator1A: z.number().int().min(1).max(11),
      numerator1B: z.number().int().min(1).max(11),
      whole1: z.number().int().min(2).max(9),
      denominator2: z.number().int().min(3).max(12),
      numerator2A: z.number().int().min(1).max(11),
      numerator2B: z.number().int().min(1).max(11),
      whole2: z.number().int().min(2).max(9),
      wholeIsAnswer: z.boolean()
    })
    .refine(
      val => val.denominator1 > val.numerator1A && val.denominator1 > val.numerator1B,
      'numerators must be smaller than denominators'
    )
    .refine(
      val => val.denominator2 > val.numerator2A && val.denominator2 > val.numerator2B,
      'numerators must be smaller than denominators'
    ),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 12);
    const numerator1A = randomIntegerInclusive(1, denominator1 - 2);
    const numerator1B = randomIntegerInclusive(numerator1A + 1, denominator1 - 1);
    const whole1 = randomIntegerInclusive(2, 9);
    const denominator2 = randomIntegerInclusive(3, 12);
    const numerator2A = randomIntegerInclusive(1, denominator2 - 2);
    const numerator2B = randomIntegerInclusive(numerator2A + 1, denominator2 - 1);
    const whole2 = randomIntegerInclusive(2, 9);
    const wholeIsAnswer = getRandomBoolean();

    return {
      denominator1,
      denominator2,
      numerator1A,
      numerator1B,
      whole1,
      numerator2A,
      numerator2B,
      whole2,
      wholeIsAnswer
    };
  },

  Component: props => {
    const {
      question: {
        denominator1,
        denominator2,
        numerator1A,
        numerator1B,
        whole1,
        numerator2A,
        numerator2B,
        whole2,
        wholeIsAnswer
      },
      translate
    } = props;

    const eqA = `<frac w='${whole1.toLocaleString()}' n='${numerator1A.toLocaleString()}' d='${denominator1.toLocaleString()}'/> ${SUB} <frac n='${numerator1B.toLocaleString()}' d='${denominator1.toLocaleString()}'/>  = <frac wAns='' nAns='' dAns='' />`;

    const eqB = wholeIsAnswer
      ? `<frac wAns='' nAns='' d='${denominator2.toLocaleString()}'/> ${SUB} <frac n='${numerator2B.toLocaleString()}' d='${denominator2.toLocaleString()}'/>  = <frac w='${
          whole2 - 1
        }' n='${(
          denominator2 +
          numerator2A -
          numerator2B
        ).toLocaleString()}' d='${denominator2.toLocaleString()}'/>`
      : `<frac w='${whole2.toLocaleString()}' n='${numerator2A.toLocaleString()}' d='${denominator2.toLocaleString()}'/> ${SUB} <frac nAns='' d='${denominator2.toLocaleString()}'/>  = <frac w='${
          whole2 - 1
        }' n='${(
          denominator2 +
          numerator2A -
          numerator2B
        ).toLocaleString()}' d='${denominator2.toLocaleString()}'/>`;

    const eqs = [eqA, eqB];
    const answer2 = wholeIsAnswer
      ? [whole2.toString(), numerator2A.toString()]
      : [numerator2B.toString()];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMissingNumbers()}
        questionHeight={1000}
        testCorrect={userAnswer =>
          compareFractions(
            [userAnswer[0][0], userAnswer[0][1], userAnswer[0][2]],
            [whole1 - 1, denominator1 + numerator1A - numerator1B, denominator1]
          ) && arraysHaveSameContents(userAnswer[1], answer2)
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              (whole1 - 1).toLocaleString(),
              (denominator1 + numerator1A - numerator1B).toLocaleString(),
              denominator1.toLocaleString()
            ],
            wholeIsAnswer
              ? [whole2.toLocaleString(), numerator2A.toLocaleString()]
              : [numerator2B.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        fractionContainerStyle={{ height: 96 }}
        pdfContainerStyle={{ rowGap: 128 }}
        sentences={eqs}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aNx2',
  description: 'aNx',
  keywords: [
    'Subtraction',
    'Fraction',
    'Integer',
    'Difference',
    'Numerator',
    'Denominator',
    'Mixed number'
  ],
  questionHeight: 1000,
  schema: z
    .object({
      denominator: z.number().int().min(3).max(12),
      numerator1: z.number().int().min(1).max(11),
      numerator2: z.number().int().min(1).max(11),
      whole: z.number().int().min(2).max(9),
      wholeIsAnswer: z.boolean(),
      randomSet: z.boolean()
    })
    .refine(
      val => val.denominator > val.numerator1 && val.denominator > val.numerator2,
      'numerators must be smaller than denominators'
    ),
  simpleGenerator: () => {
    const randomSet = getRandomBoolean();
    const denominator = randomIntegerInclusive(3, 12);
    const numerator1 = randomIntegerInclusive(1, denominator - 2);
    const numerator2 = randomIntegerInclusive(numerator1 + 1, denominator - 1);
    const whole = randomIntegerInclusive(2, 9);
    const wholeIsAnswer = getRandomBoolean();

    return {
      denominator,
      numerator1,
      numerator2,
      whole,
      wholeIsAnswer,
      randomSet
    };
  },

  Component: props => {
    const {
      question: { denominator, numerator1, numerator2, whole, wholeIsAnswer, randomSet },
      translate
    } = props;

    const eqA = `<frac w='${whole.toLocaleString()}' n='${numerator1}' d='${denominator}'/> ${SUB} <frac n='${numerator2}' d='${denominator}'/>  = <frac wAns='' nAns='' dAns='' />`;

    const eqB = wholeIsAnswer
      ? `<frac wAns='' nAns='' d='${denominator}'/> ${SUB} <frac n='${numerator2}' d='${denominator}'/>  = <frac w='${(
          whole - 1
        ).toLocaleString()}' n='${denominator + numerator1 - numerator2}' d='${denominator}'/>`
      : `<frac w='${whole.toLocaleString()}' n='${numerator1}' d='${denominator}'/> ${SUB} <frac nAns='' d='${denominator}'/>  = <frac w='${(
          whole - 1
        ).toLocaleString()}' n='${denominator + numerator1 - numerator2}' d='${denominator}'/>`;

    const eq = randomSet ? eqA : eqB;
    const answer2 = wholeIsAnswer
      ? [whole.toString(), numerator1.toString()]
      : [numerator2.toString()];

    return (
      <QF2AnswerBoxOneSentence
        title={
          randomSet
            ? translate.instructions.workOutTheMissingNumbers()
            : wholeIsAnswer
            ? translate.instructions.workOutTheMissingNumbers()
            : translate.instructions.workOutTheMissingNumber()
        }
        questionHeight={1000}
        testCorrect={userAnswer =>
          randomSet
            ? compareFractions(
                [userAnswer[0], userAnswer[1], userAnswer[2]],
                [whole - 1, denominator + numerator1 - numerator2, denominator]
              )
            : arraysHaveSameContents(userAnswer, answer2)
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: randomSet
            ? [
                (whole - 1).toLocaleString(),
                (denominator + numerator1 - numerator2).toLocaleString(),
                denominator.toLocaleString()
              ]
            : wholeIsAnswer
            ? [whole.toLocaleString(), numerator1.toLocaleString()]
            : [numerator2.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        fractionContainerStyle={{ height: 96 }}
        sentence={eq}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractFromMixedNumbers',
  questionTypes: [Question1, Question2v2, Question3, Question4v2, Question5, Question6v2],
  archivedQuestionTypes: [Question2, Question4, Question6]
});
export default SmallStep;
