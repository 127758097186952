import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { MULT } from '../../../../constants';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ScientificNotation } from '../../../../utils/math';
import { all, create, number } from 'mathjs';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aJ4',
  description: 'aJ4',
  keywords: ['Multiplication', '100', 'Base 10'],
  schema: z.object({
    number1: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[
          `${number1} ${MULT} 1 ${translate.powersOfTen.hundreds(
            1
          )} = <ans/> ${translate.powersOfTen.hundreds(number1)}`,
          `${number1.toLocaleString()} ${MULT} 100 = <ans/>`
        ]}
        title={translate.instructions.useBase10ToCompleteCalculations()}
        testCorrect={[[number1.toString()], [(number1 * 100).toString()]]}
        Content={({ dimens }) => {
          return (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Cubes',
                numbers: { ['hundreds']: number1 },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
              numCols={5}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aJ5',
  description: 'aJ5',
  keywords: ['Multiplication', '100'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    variant: z.enum(['Balloons', 'Bricks', 'Lollipops', 'Marbles', 'Sweets'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const variant = getRandomFromArray([
      'Balloons',
      'Bricks',
      'Lollipops',
      'Marbles',
      'Sweets'
    ] as const);

    return { number1, variant };
  },
  Component: props => {
    const {
      question: { number1, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${number1.toLocaleString()} ${MULT} 100 = <ans/>`}
        title={translate.instructions.completeMultiplicationUsePictureToHelp()}
        testCorrect={[(number1 * 100).toString()]}
        Content={({ dimens }) => {
          return (
            <BaseTenRepresentation
              b10Rep={{
                variant: variant,
                numbers: { ['hundreds']: number1 },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
              numCols={5}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aJ6',
  description: 'aJ6',
  keywords: ['Multiplication', '100', 'Place value chart'],
  schema: z.object({
    hundreds: z.number().min(1).max(9),
    tens: z.number().min(1).max(9),
    ones: z.number().min(1).max(9)
  }),
  simpleGenerator: () => {
    const hundreds = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const ones = randomIntegerInclusive(1, 9);

    return { hundreds, tens, ones };
  },
  Component: ({ question: { hundreds, tens, ones }, translate, displayMode }) => {
    const number1 = number(math.evaluate(`${hundreds}* 100 + ${tens} * 10 + ${ones}`));
    return (
      <QF23CreatePlaceValueChart
        initialState={displayMode === 'digital' ? number1 : undefined}
        number={ScientificNotation.fromNumber(number1 * 100)}
        columnsToShow={[4, 3, 2, 1, 0]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        title={translate.instructions.dragTheCountersToShowNumTimesNum(number1, 100)}
        pdfTitle={translate.instructions.drawCountersToShowTheAnswerToX(
          `${number1.toLocaleString()} ${MULT} ${(100).toLocaleString()}`
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aJ7',
  description: 'aJ7',
  keywords: ['Multiplication', '100'],
  schema: z.object({
    number1: z.number().int().min(1).max(100),
    variant: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 100);
    const variant = getRandomBoolean();

    return { number1, variant };
  },
  Component: props => {
    const {
      question: { number1, variant },
      translate
    } = props;

    const ans = variant ? number1 * 100 : 100 * number1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[ans.toString()]}
        sentence={variant ? `${number1} ${MULT} 100 = <ans/>` : `100 ${MULT} ${number1} = <ans/>`}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aJ8',
  description: 'aJ8',
  keywords: ['Multiplication', '100'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    variant: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99);
    const variant = getRandomBoolean();

    return { number1, variant };
  },
  Component: props => {
    const {
      question: { number1, variant },
      translate
    } = props;

    const ans = variant ? number1 * 100 : 100 * number1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[number1.toString()]}
        sentence={
          variant
            ? `<ans/> ${MULT} 100 = ${ans.toLocaleString()}`
            : `100 ${MULT} <ans/> = ${ans.toLocaleString()}`
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aJ9',
  description: 'aJ9',
  keywords: ['Multiplication', '100'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int().min(1000).max(9900).step(100)
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(1000, 9900, 100);
    const number1 = randomIntegerInclusive(1, 99, { constraint: x => x !== number2 / 100 });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const sentences = [
      translate.answerSentences.ansIsXTimesTheSizeOfY(100, number1.toLocaleString()),
      translate.answerSentences.xIsYTimesTheSizeOfAns(number2.toLocaleString(), 100)
    ];

    const answers = [
      [number(math.evaluate(`${number1} * 100`)).toString()],
      [number(math.evaluate(`${number2} / 100`)).toString()]
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={answers}
        sentences={sentences}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy100',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
