import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF41MovableLinesForCreatingAngles from '../../../../components/question/questionFormats/QF41MovableLinesForCreatingAngles';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { angleBetweenTwoLines } from '../../../../utils/angles';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Clock from '../../../../components/question/representations/Clock';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import { countRange } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { isWithinRange, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { shapeAngles, shapeAnglesSchema } from '../../../../utils/shapes';
import { AssetSvg, SvgName } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'auY',
  description: 'auY',
  keywords: ['Right angles', 'Compare'],
  schema: z.object({
    angles: z.number().int().min(10).max(170).array().length(4),
    startingPosition: z.number().int().min(0).max(359).array().length(4),
    isGreaterThan: z.boolean()
  }),
  simpleGenerator: () => {
    const [acuteAngleA, acuteAngleB] = randomUniqueIntegersInclusive(10, 80, 2);
    const [obtuseAngleA, obtuseAngleB] = randomUniqueIntegersInclusive(100, 170, 2);

    const angles = shuffle([acuteAngleA, obtuseAngleA, acuteAngleB, obtuseAngleB]);

    const startingPosition = countRange(4).map(() => randomIntegerInclusive(0, 359));

    const isGreaterThan = getRandomBoolean();

    return { angles, startingPosition, isGreaterThan };
  },
  Component: props => {
    const {
      question: { angles, startingPosition, isGreaterThan },
      translate,
      displayMode
    } = props;

    const answer = isGreaterThan ? angles.filter(i => i > 90) : angles.filter(i => i < 90);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheAnglesThatAreXThanARightAngle(
          isGreaterThan ? translate.misc.greater() : translate.misc.less()
        )}
        pdfTitle={translate.instructions.circleTheAnglesThatAreXThanARightAngle(
          isGreaterThan ? translate.misc.greater() : translate.misc.less()
        )}
        testCorrect={answer}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          angles.map((val, i) => ({
            value: val,
            component: (
              <AngleFromLines
                degrees={[startingPosition[i], startingPosition[i] + val]}
                dimens={{ height: dimens.height - 75, width: dimens.width - 75 }}
                strokeWidth={displayMode === 'digital' ? 4 : 6}
              />
            )
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'auZ',
  description: 'auZ',
  keywords: ['Right angles', 'Compare'],
  schema: z.object({
    // Start at any two lines that don't already make a correct angle!
    startAngles: z
      .tuple([z.number().int().min(-89).max(90), z.number().int().min(-89).max(90)])
      .refine(([a, b]) => angleBetweenTwoLines(a, b, false) >= 100)
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(100, 170);
    const startAngle = randomIntegerInclusive(-89, 90 - angle);
    return { startAngles: [startAngle, startAngle + angle] as [number, number] };
  },
  Component: ({ question: { startAngles }, translate }) => {
    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragTheLinesToMakeAnAngleLessThanARightAngle()}
        pdfTitle={translate.instructions.drawAnAngleLessThanARightAngle()}
        testCorrect={angle => angle >= 1 && angle < 90}
        startAngles={startAngles}
        markScheme={{ exampleCorrectAnswer: [15, 70], notes: translate.markScheme.anyAcuteAngle() }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'au0',
  description: 'au0',
  keywords: ['Right angles', 'Compare'],
  schema: z.object({}),
  simpleGenerator: () => {
    return {};
  },
  Component: ({ translate }) => {
    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragTheLinesToMakeAnAngleThatIsGreaterThanOneRightAngleButLessThanTwoRightAngles()}
        pdfTitle={translate.instructions.drawAnAngleGreaterThanOneRightAngleButLessThanTwoRightAngles()}
        testCorrect={angle => angle >= 91 && angle < 180}
        startAngles={[0, 0]}
        markScheme={{
          exampleCorrectAnswer: [-50, 90],
          notes: translate.markScheme.anyObtuseAngle()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'au1',
  description: 'au1',
  keywords: ['Right angles', 'Compare', 'Acute', 'Obtuse'],
  schema: z.object({
    hours: z.number().int().min(1).max(5),
    minutes: z.number().int().min(2).max(10),
    isAcute: z.boolean()
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 5);
    const isAcute = getRandomBoolean();

    const minutes = isAcute
      ? getRandomFromArray([hours + 1, hours + 2] as const)
      : getRandomFromArray([hours + 4, hours + 5] as const);

    return { hours, minutes, isAcute };
  },
  Component: ({ question: { hours, minutes, isAcute }, translate }) => {
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.isTheAngleBetweenTheHandsOfTheClockAcuteOrObtuseSelectAnswer()}
        pdfTitle={translate.instructions.isTheAngleBetweenTheHandsOfTheClockAcuteOrObtuseCircleAnswer()}
        testCorrect={isAcute ? [translate.angles.acute()] : [translate.angles.obtuse()]}
        numItems={2}
        Content={({ dimens }) => (
          <Clock
            time={{ hours, minutes: minutes * 5 }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            showAngle={{
              startMinutes: 'hour',
              endMinutes: 'minute'
            }}
          />
        )}
        renderItems={[translate.angles.acute(), translate.angles.obtuse()].map(angle => ({
          value: angle,
          component: <Text variant="WRN700">{angle}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'au2',
  description: 'au2',
  keywords: ['Right angles', 'Compare', 'Acute', 'Obtuse'],
  schema: z
    .object({
      angleA: z.number().int().min(10).max(170),
      angleB: z.number().int().min(10).max(170),
      startingPosition: z.number().int().min(0).max(359).array().length(2)
    })
    .refine(
      val => Math.abs(val.angleA - val.angleB) >= 15 || val.angleA === val.angleB,
      'difference between angles should be atleast 15 or angleA and angleB can be the same'
    ),
  simpleGenerator: () => {
    const isEqual = getRandomFromArrayWithWeights([true, false] as const, [1, 2]);
    const angleA = randomIntegerInclusive(10, 170);
    const angleB = isEqual
      ? angleA
      : randomIntegerInclusive(10, 170, { constraint: x => !isWithinRange(x, angleA, 15) });

    const startingPosition = countRange(2).map(() => randomIntegerInclusive(0, 359));

    return { angleA, angleB, startingPosition };
  },
  Component: ({ question: { angleA, angleB, startingPosition }, translate, displayMode }) => {
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompareSizeOfAngles()}
        pdfTitle={translate.instructions.useLessThanGreaterThanEqualsToCompareAngles()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={[
          {
            lhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  width: 400,
                  gap: 5
                }}
              >
                <AngleFromLines
                  degrees={[startingPosition[0], startingPosition[0] + angleA]}
                  lineLength={200}
                  strokeWidth={displayMode === 'digital' ? 4 : 6}
                />
              </View>
            ),
            rhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  width: 400,
                  gap: 5
                }}
              >
                <AngleFromLines
                  degrees={[startingPosition[1], startingPosition[1] + angleB]}
                  lineLength={200}
                  strokeWidth={displayMode === 'digital' ? 4 : 6}
                />
              </View>
            ),
            correctAnswer: lessThanGreaterThanOrEqualTo(angleA, angleB)
          }
        ]}
        mainPanelStyle={{ alignItems: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question5v2 = newQuestionContent({
  uid: 'au22',
  description: 'au2',
  keywords: ['Right angles', 'Compare', 'Acute', 'Obtuse'],
  schema: z
    .object({
      angleA: z.number().int().min(10).max(170),
      angleB: z.number().int().min(10).max(170),
      startingPosition: z.number().int().min(0).max(359).array().length(2)
    })
    .refine(
      val => Math.abs(val.angleA - val.angleB) >= 15,
      'difference between angles should be at least 15'
    ),
  simpleGenerator: () => {
    const angleA = randomIntegerInclusive(10, 170);
    const angleB = randomIntegerInclusive(10, 170, {
      constraint: x => !isWithinRange(x, angleA, 15)
    });

    const startingPosition = countRange(2).map(() => randomIntegerInclusive(0, 359));

    return { angleA, angleB, startingPosition };
  },
  Component: ({ question: { angleA, angleB, startingPosition }, translate, displayMode }) => {
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompareSizeOfAngles()}
        pdfTitle={translate.instructions.useLessThanGreaterThanToCompareAngles()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={[
          {
            lhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  width: 400,
                  gap: 5
                }}
              >
                <AngleFromLines
                  degrees={[startingPosition[0], startingPosition[0] + angleA]}
                  lineLength={200}
                  strokeWidth={displayMode === 'digital' ? 4 : 6}
                />
              </View>
            ),
            rhsComponent: (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  width: 400,
                  gap: 5
                }}
              >
                <AngleFromLines
                  degrees={[startingPosition[1], startingPosition[1] + angleB]}
                  lineLength={200}
                  strokeWidth={displayMode === 'digital' ? 4 : 6}
                />
              </View>
            ),
            correctAnswer: lessThanGreaterThanOrEqualTo(angleA, angleB)
          }
        ]}
        mainPanelStyle={{ alignItems: 'center' }}
        items={['>', '<']}
        moveOrCopy="move"
        actionPanelVariant="end"
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question6 = newQuestionContent({
  uid: 'au3',
  description: 'au3',
  keywords: ['Right angles', 'Compare', 'Acute', 'Obtuse', 'Shape', 'Regular', 'Irregular'],
  schema: z.object({
    angle: z.enum(['rightAngle', 'acuteAngle', 'obtuseAngle']),
    angleAmount: z.number().int().min(1).max(4),
    correctShapes: shapeAnglesSchema,
    incorrectShapes: shapeAnglesSchema
  }),
  simpleGenerator: () => {
    const { angle, angleAmount, correctShapes, incorrectShapes } = rejectionSample(
      () => {
        const angle = getRandomFromArray(['rightAngle', 'acuteAngle', 'obtuseAngle'] as const);
        const angleToCheck =
          angle === 'acuteAngle'
            ? 'acuteAngles'
            : angle === 'rightAngle'
            ? 'rightAngles'
            : 'obtuseAngles';

        const angleAmount = randomIntegerInclusive(1, 4);

        const correctShapes = shapeAngles.filter(
          shape => shape[angleToCheck] && shape[angleToCheck] === angleAmount
        );

        const incorrectShapes = getRandomSubArrayFromArray(
          shapeAngles.filter(shape => shape[angleToCheck] !== angleAmount),
          Math.max(1, 4 - correctShapes.length)
        );

        return { angle, angleAmount, correctShapes, incorrectShapes };
      },

      ({ correctShapes }) => correctShapes.length > 0 && correctShapes.length < 4
    );
    return {
      angle,
      angleAmount,
      correctShapes,
      incorrectShapes
    };
  },
  Component: props => {
    const {
      question: { angle, angleAmount, correctShapes, incorrectShapes },
      translate
    } = props;

    const shapeObjects = shuffle(
      [
        ...correctShapes.map(shape => ({ ...shape, correct: true })),
        ...incorrectShapes.map(shape => ({ ...shape, correct: false }))
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheShapeThatHasOrHaveXAmountYAngles(
          correctShapes.length,
          correctShapes.length !== 1 ? translate.misc.have() : translate.misc.has(),
          angleAmount,
          translate.angles[angle](),
          angleAmount
        )}
        pdfTitle={translate.instructions.circleTheShapeThatHasOrHaveXAmountYAngles(
          correctShapes.length,
          correctShapes.length !== 1 ? translate.misc.have() : translate.misc.has(),
          angleAmount,
          translate.angles[angle](),
          angleAmount
        )}
        testCorrect={shapeObjects.filter(val => val.correct).map(val => val.name)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shapeObjects.map(shape => ({
            value: shape.name,
            component: (
              <AssetSvg
                name={shape.shapeImages[0] as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAngles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
