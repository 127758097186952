import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, { runOnJS, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { AssetSvg, getSvgInfo } from '../../../assets/svg';
import { useContext, useMemo } from 'react';
import { View } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import { withStateHOC } from '../../../stateTree';
import { ScaleFactorContext } from '../../../theme/scaling';

const PAN_HANDLE_TOUCHABLE_AREA = 96;
const PROTRACTOR_WIDTH_SMALL = 380;
const PROTRACTOR_WIDTH_LARGE = 450;

type Props = {
  state: { x: number; y: number };
  setState?: (newState: { x: number; y: number }) => void;
  shapeImage: JSX.Element;
  protractorSize?: 'small' | 'large';
  /**
   * Boolean that determines whether the protractors starting point is overlapping the shapeImage.
   */
  absolutePositionProtractorOverShape?: boolean;
  /**
   * Boolean that determines whether the protractor and shape is aligned centrally on the x-axis.
   */
  centerHorizontally?: boolean;
  dimens: {
    width: number;
    height: number;
  };
};

export function DraggableProtractor({
  state,
  setState,
  shapeImage: shapeImage,
  dimens,
  protractorSize = 'small',
  absolutePositionProtractorOverShape = false,
  centerHorizontally
}: Props) {
  const PROTRACTOR_WIDTH =
    protractorSize === 'small' ? PROTRACTOR_WIDTH_SMALL : PROTRACTOR_WIDTH_LARGE;

  const scaleFactor = useContext(ScaleFactorContext);

  const protractorInfo = getSvgInfo('Protractor180').aspectRatio;
  const protractorHeight = PROTRACTOR_WIDTH / protractorInfo;

  const displayMode = useContext(DisplayMode);

  const x = useSharedValue(state.x);
  const y = useSharedValue(state.y);

  const translateStyle = useAnimatedStyle(
    () => ({
      left: x.value / scaleFactor,
      top: y.value / scaleFactor
    }),
    [x, y, scaleFactor]
  );

  const panStartX = useSharedValue(0);
  const panStartY = useSharedValue(0);

  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(() => {
          panStartX.value = x.value;
          panStartY.value = y.value;
        })
        .minDistance(1)
        .onUpdate(event => {
          x.value = panStartX.value + event.translationX;
          y.value = panStartY.value + event.translationY;

          // X clamp
          x.value =
            x.value < -38
              ? -38
              : x.value + PROTRACTOR_WIDTH > dimens.width + 38
              ? dimens.width + 38 - PROTRACTOR_WIDTH
              : x.value;

          // Y clamp
          const protractorBottomLimit =
            dimens.height -
            protractorHeight *
              (protractorSize === 'large'
                ? 0.95
                : absolutePositionProtractorOverShape
                ? 0.9
                : 1.35);
          const protractorTopLimit = -protractorHeight * 0.35;

          y.value =
            y.value > protractorBottomLimit
              ? protractorBottomLimit
              : y.value < protractorTopLimit
              ? protractorTopLimit
              : y.value;
        })

        .onFinalize(() => {
          setState &&
            runOnJS(setState)({
              x: x.value,
              y: y.value
            });
        }),
    [
      panStartX,
      x,
      panStartY,
      y,
      PROTRACTOR_WIDTH,
      dimens.width,
      dimens.height,
      protractorHeight,
      protractorSize,
      absolutePositionProtractorOverShape,
      setState
    ]
  );

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf:
          displayMode === 'digital' ? (centerHorizontally ? 'center' : 'flex-start') : undefined,
        zIndex: 10
      }}
    >
      {displayMode === 'digital' && (
        <Animated.View
          style={[
            {
              backgroundColor: 'transparent',
              width: PROTRACTOR_WIDTH,
              height: protractorHeight,
              alignItems: 'center',
              justifyContent: 'center',
              marginRight:
                displayMode === 'digital' && !absolutePositionProtractorOverShape
                  ? protractorSize === 'small'
                    ? 40
                    : 5
                  : undefined,
              zIndex: 10,
              position: absolutePositionProtractorOverShape ? 'absolute' : undefined
            },
            translateStyle
          ]}
        >
          {<AssetSvg name={'Protractor180'} width={PROTRACTOR_WIDTH} height={PROTRACTOR_WIDTH} />}
          <GestureDetector gesture={panGesture}>
            <Animated.View
              style={[
                {
                  position: 'absolute',
                  width: PAN_HANDLE_TOUCHABLE_AREA,
                  height: PAN_HANDLE_TOUCHABLE_AREA,
                  borderRadius: 999,
                  backgroundColor: 'transparent',
                  alignItems: 'center',
                  justifyContent: 'center'
                }
              ]}
            >
              <AssetSvg name="Circles/circle_orange_protractor" width={50} />
            </Animated.View>
          </GestureDetector>
        </Animated.View>
      )}
      <View>{shapeImage}</View>
    </View>
  );
}

export const DraggableProtractorWithState = withStateHOC(DraggableProtractor, {
  stateProp: 'state',
  setStateProp: 'setState',
  defaults: {
    defaultState: {
      x: 0,
      y: 0
    }
  }
});
