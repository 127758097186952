// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import PlaceValueWithin1 from './1PlaceValueWithin1';
import PlaceValueIntegersAndDecimals from './2PlaceValueIntegersAndDecimals';
import RoundDecimals from './3RoundDecimals';
import AddAndSubtractDecimals from './4AddAndSubtractDecimals';
import MultiplyBy10100And1000 from './5MultiplyBy10100And1000';
import DivideBy10100And1000 from './6DivideBy10100And1000';
import MultiplyDecimalsByIntegers from './7MultiplyDecimalsByIntegers';
import DivideDecimalsByIntegers from './8DivideDecimalsByIntegers';
import MultiplyAndDivideDecimalsInContext from './9MultiplyAndDivideDecimalsInContext';

const Block = newBlockContent({
  block: 'Decimals',
  field: 'Number',
  weeks: [5, 6],
  smallSteps: [
    PlaceValueWithin1, // Step 1
    PlaceValueIntegersAndDecimals, // Step 2
    RoundDecimals, // Step 3
    AddAndSubtractDecimals, // Step 4
    MultiplyBy10100And1000, // Step 5
    DivideBy10100And1000, // Step 6
    MultiplyDecimalsByIntegers, // Step 7
    DivideDecimalsByIntegers, // Step 8
    MultiplyAndDivideDecimalsInContext // Step 9
  ]
});
export default Block;
