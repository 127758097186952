import { View, StyleSheet, Pressable, StyleProp, ViewStyle } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import { countRange } from '../../../../utils/collections';
import { BaseTenImages } from './BaseTenImages';
import { projectSetState, type SetState } from '../../../../utils/react';
import { Line, Svg } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import { withStateHOC } from '../../../../stateTree';
import { noop } from '../../../../utils/flowControl';
import { useContext } from 'react';
import { DisplayMode } from '../../../../contexts/displayMode';

type createSubitizedGridProps = {
  number: number;
  numOfCols: number;
};

/**
 * creates a boolean grid to represent a configuration for a base 10 representation
 * automatically calculates number of rows based on number of {tens/hundreds/thousands} / number of cols
 */
const createSubitizedGrid = ({ number, numOfCols }: createSubitizedGridProps): boolean[][] => {
  const numOfRows = Math.ceil(number / numOfCols);

  let numberRemaining = number;

  return countRange(numOfRows).map(() => {
    const spaceInRow = numberRemaining > numOfCols ? numOfCols : numberRemaining;
    numberRemaining -= numOfCols;

    return new Array(spaceInRow).fill(true);
  });
};

const getSubitizedNumOfCols = (number: number) => {
  switch (number) {
    case 4:
      return 2;
    case 7:
    case 8:
      return 4;
    default:
      return Math.min(number, 3);
  }
};

type Props = {
  /**
   * Number of thousands to show. Default 0
   */
  thousands?: number /**
   * Number of hundreds to show. Default 0
   */;
  hundreds?: number /**
   * Number of tens to show. Default 0
   */;
  tens?: number;
  /**
   * Number of ones to show. Default 0
   */
  ones?: number;
  /**
   * Default crossed out values
   */
  crossedOutIndices?: {
    thousands?: number[];
    hundreds?: number[];
    tens?: number[];
    ones?: number[];
  };
  /**
   * When provided the blocks will be interactive
   */
  setCrossedOutIndices?: SetState<{
    thousands?: number[];
    hundreds?: number[];
    tens?: number[];
    ones?: number[];
  }>;
  /**
   * For interactive, this will be overridden.
   * If no value provided for none interactive, then this will default to BaseTenRepCalcGridsAndScale.
   * This works well for most values but not well for numbers less than 10
   */
  scale?: number;
  /**
   * Rotate the representation (can be extended in future if required to varying degrees)
   */
  rotate?: '90deg' | '180deg' | '270deg';
  dimens: Dimens;
  /** Position to render the ones inside the main container. Default: 'bottom' */
  onesPosition?: 'top' | 'bottom' | 'leftTop' | 'leftBottom';
  /**
   * Optional boolean prop, determine whether each group of ten ones should have distinct styling.
   * When true, this adds in a greater marginRight between each group of ten ones.
   * NOTE: This is currently only configured to be applied when onesPosition is 'bottom' - other positions will need extra work.
   * Default is false.
   */
  splitOnesIntoGroupsOfTen?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Optional boolean prop, if set to true it will display all 10s, 100s, 1000s in a single row
   * DOES NOT AFFECT 1s
   * Default is false
   */
  singleRow?: boolean;
};

/**
 * Representation showing base 10 cubes up to tens with the ability to cross through cubes.
 * The ones are organised in columns of 5
 */
export function SimpleBaseTenWithCrossOut({
  thousands = 0,
  hundreds = 0,
  tens = 0,
  ones = 0,
  crossedOutIndices = { thousands: [], hundreds: [], tens: [], ones: [] },
  setCrossedOutIndices,
  scale,
  dimens,
  rotate,
  onesPosition = 'bottom',
  splitOnesIntoGroupsOfTen = false,
  containerStyle,
  singleRow = false
}: Props) {
  const isInteractive = setCrossedOutIndices !== undefined;

  const onesAlignment = onesPosition.includes('top') ? 'flex-start' : 'flex-end';
  const styles = getStyles({
    dimens,
    isInteractive,
    onesAlignment,
    rotate,
    containerStyleOverride: containerStyle
  });

  const onesObject = BaseTenImages['Cubes'].ONES;
  const tensObject = BaseTenImages['Cubes'].TENS;
  const hundredsObject = BaseTenImages['Cubes'].HUNDREDS;
  const thousandsObject = BaseTenImages['Cubes'].THOUSANDS;

  const layoutDef = Scale(
    dimens.width,
    dimens.height,
    { ones, tens, hundreds, thousands },
    singleRow
  );

  const imageScale = scale !== undefined ? scale : layoutDef;

  // Touchable area around the ones
  // scaled tens interactive height / scaler (currently unknown why this value works)
  const interactiveSpan = 271 / 4.45;

  // When interactive we have fixed values as per figma. Otherwise we scale as per the base 10 scale
  const scaledOnesWidth = isInteractive ? 27 : Math.floor(onesObject.width * imageScale);
  const scaledTensWidth = isInteractive ? 70 : Math.floor(tensObject.width * imageScale);
  const scaledHundredsWidth = isInteractive ? 271 : Math.floor(hundredsObject.width * imageScale);
  const scaledThousandsWidth = isInteractive ? 380 : Math.floor(thousandsObject.width * imageScale);
  const scaledOnesHeight = isInteractive ? 27 : Math.floor(onesObject.height * imageScale);
  const scaledTensHeight = isInteractive ? 271 : Math.floor(tensObject.height * imageScale);
  const scaledHundredsHeight = isInteractive ? 271 : Math.floor(hundredsObject.height * imageScale);
  const scaledThousandsHeight = isInteractive
    ? 390
    : Math.floor(thousandsObject.height * imageScale);

  const onesMargin = isInteractive ? 0 : (scaledTensHeight - (scaledOnesHeight + 4) * 5) / 4;

  const onesRows = ones === 0 ? 0 : Math.ceil(ones / 5);

  const displayMode = useContext(DisplayMode);

  const crossThrough = ({ width, height }: Dimens) => {
    const strokeWidth = displayMode === 'digital' ? 4 : 8;
    return (
      <Svg width={width} height={height}>
        <Line
          x1={strokeWidth / 2}
          y1={height}
          x2={width - strokeWidth / 2}
          y2={0}
          stroke={colors.prussianBlue}
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
        />
      </Svg>
    );
  };

  const numberOfThousandsCols = singleRow ? thousands : getSubitizedNumOfCols(thousands);

  const thousandsGrid = createSubitizedGrid({
    number: thousands,
    numOfCols: numberOfThousandsCols
  });

  const thousandsComponents = thousands > 0 && (
    <View key="thousands" style={{ flexDirection: 'column', alignSelf: 'flex-start' }}>
      {thousandsGrid.map((thousandsRow, rowNum) => {
        const setThousandsState = setCrossedOutIndices
          ? projectSetState(setCrossedOutIndices, 'thousands')
          : noop;

        return (
          <View key={`row_${rowNum}`} style={{ flexDirection: 'row' }}>
            {thousandsRow.map((_isActive, colNum) => {
              const thousand = colNum + rowNum * numberOfThousandsCols;
              return (
                <Pressable
                  key={`thousands_${thousand}`}
                  disabled={!isInteractive}
                  onPress={() => {
                    if (!crossedOutIndices.thousands?.includes(thousand)) {
                      const newArray = crossedOutIndices.thousands || [];
                      newArray.push(thousand);
                      setThousandsState(newArray);
                    } else {
                      const newArray =
                        crossedOutIndices.thousands.length === 1
                          ? []
                          : crossedOutIndices.thousands.filter(index => index !== thousand);
                      setThousandsState(newArray);
                    }
                  }}
                >
                  <View
                    style={{
                      height: scaledThousandsHeight + 5,
                      width: scaledThousandsWidth + 5,
                      bottom: isInteractive ? -15 : 0,
                      justifyContent: 'flex-end'
                    }}
                  >
                    <View
                      style={{
                        height: scaledThousandsHeight,
                        width: scaledThousandsWidth
                      }}
                    >
                      {thousandsObject.component}
                    </View>
                    {crossedOutIndices.thousands?.includes(thousand) && (
                      <View style={{ position: 'absolute' }}>
                        {crossThrough({
                          height: scaledThousandsHeight,
                          width: scaledThousandsWidth
                        })}
                      </View>
                    )}
                  </View>
                </Pressable>
              );
            })}
          </View>
        );
      })}
    </View>
  );

  const numberOfHundredsCols = singleRow ? hundreds : getSubitizedNumOfCols(hundreds);
  const hundredsGrid = createSubitizedGrid({ number: hundreds, numOfCols: numberOfHundredsCols });

  const hundredsComponents = hundreds > 0 && (
    <View key="hundreds" style={{ flexDirection: 'column', alignSelf: 'flex-start' }}>
      {hundredsGrid.map((hundredsRow, rowNum) => {
        const setHundredsState = setCrossedOutIndices
          ? projectSetState(setCrossedOutIndices, 'hundreds')
          : noop;

        return (
          <View key={`row_${rowNum}`} style={{ flexDirection: 'row' }}>
            {hundredsRow.map((_isActive, colNum) => {
              const hundred = colNum + rowNum * numberOfHundredsCols;

              return (
                <Pressable
                  key={`hundred_${hundred}`}
                  disabled={!isInteractive}
                  onPress={() => {
                    if (!crossedOutIndices.hundreds?.includes(hundred)) {
                      const newArray = crossedOutIndices.hundreds || [];
                      newArray.push(hundred);
                      setHundredsState(newArray);
                    } else {
                      const newArray =
                        crossedOutIndices.hundreds.length === 1
                          ? []
                          : crossedOutIndices.hundreds.filter(index => index !== hundred);
                      setHundredsState(newArray);
                    }
                  }}
                >
                  <View
                    style={{
                      height: scaledHundredsHeight + 5,
                      width: scaledHundredsWidth + 5,
                      justifyContent: 'flex-end'
                    }}
                  >
                    <View
                      style={{
                        height: scaledHundredsHeight,
                        width: scaledHundredsWidth
                      }}
                    >
                      {hundredsObject.component}
                    </View>
                    {crossedOutIndices.hundreds?.includes(hundred) && (
                      <View style={{ position: 'absolute' }}>
                        {crossThrough({
                          height: scaledHundredsHeight,
                          width: scaledHundredsWidth
                        })}
                      </View>
                    )}
                  </View>
                </Pressable>
              );
            })}
          </View>
        );
      })}
    </View>
  );

  //Tens are a special case and we don't need getSubitizedNumOfCols because it's always 5 or 1
  const numberOfTensCols = singleRow ? tens : 5;
  const tensGrid = createSubitizedGrid({ number: tens, numOfCols: numberOfTensCols });

  const tensComponents = tens > 0 && (
    <View key="tens" style={{ flexDirection: 'column', alignSelf: 'flex-start' }}>
      {tensGrid.map((tensRow, rowNum) => {
        const setTensState = setCrossedOutIndices
          ? projectSetState(setCrossedOutIndices, 'tens')
          : noop;

        return (
          <View key={`row_${rowNum}`} style={{ flexDirection: 'row' }}>
            {tensRow.map((_isActive, colNum) => {
              const ten = colNum + rowNum * numberOfTensCols;

              return (
                <Pressable
                  key={`ten_${ten}`}
                  disabled={!isInteractive}
                  onPress={() => {
                    if (!crossedOutIndices.tens?.includes(ten)) {
                      const newArray = crossedOutIndices.tens || [];
                      newArray.push(ten);
                      setTensState(newArray);
                    } else {
                      const newArray =
                        crossedOutIndices.tens.length === 1
                          ? []
                          : crossedOutIndices.tens.filter(index => index !== ten);
                      setTensState(newArray);
                    }
                  }}
                >
                  <View
                    style={{
                      height: scaledTensHeight + 5,
                      width: scaledTensWidth + 5,
                      justifyContent: 'flex-end'
                    }}
                  >
                    <View
                      style={{
                        height: scaledTensHeight,
                        width: scaledTensWidth
                      }}
                    >
                      {tensObject.component}
                    </View>
                    {crossedOutIndices.tens?.includes(ten) && (
                      <View style={{ position: 'absolute' }}>
                        {crossThrough({
                          height: scaledTensHeight,
                          width: scaledTensWidth
                        })}
                      </View>
                    )}
                  </View>
                </Pressable>
              );
            })}
          </View>
        );
      })}
    </View>
  );

  const onesComponents = ones > 0 && (
    <View
      key="ones"
      style={{
        flexDirection: 'row',
        gap: 5,
        bottom: isInteractive ? 14 : undefined,
        alignSelf: 'flex-start'
      }}
    >
      {countRange(onesRows).map(rowIdx => (
        <View key={`row_${rowIdx}`} style={{ flexDirection: 'column', justifyContent: 'flex-end' }}>
          {countRange(Math.min(ones - rowIdx * 5, 5)).map(one => {
            const setOnesState = setCrossedOutIndices
              ? projectSetState(setCrossedOutIndices, 'ones')
              : noop;
            return (
              <Pressable
                key={`row_${rowIdx}_one_${one}`}
                disabled={!isInteractive}
                style={
                  isInteractive && {
                    height: interactiveSpan,
                    width: interactiveSpan,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }
                onPress={() => {
                  if (!crossedOutIndices.ones?.includes(one + rowIdx * 5)) {
                    const newArray = crossedOutIndices.ones || [];
                    newArray.push(one + rowIdx * 5);
                    setOnesState(newArray);
                  } else {
                    const newArray =
                      crossedOutIndices.ones.length === 1
                        ? []
                        : crossedOutIndices.ones.filter(index => index !== one + rowIdx * 5);
                    setOnesState(newArray);
                  }
                }}
              >
                <View
                  style={[
                    {
                      height: scaledOnesHeight + 5,
                      width: scaledOnesWidth + 5,
                      marginTop: one === 0 ? 0 : onesMargin,
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    },
                    // The following style should only be applied when splitOnesIntoGroupsOfTen is passed, onesPosition is bottom, and the rowIndex is odd:
                    splitOnesIntoGroupsOfTen &&
                      onesPosition === 'bottom' &&
                      rowIdx % 2 === 1 && {
                        // marginRight needs to be greater on PDF:
                        marginRight: displayMode === 'digital' ? (isInteractive ? 20 : 10) : 20
                      }
                  ]}
                >
                  <View
                    style={{
                      height: scaledOnesHeight,
                      width: scaledOnesWidth
                    }}
                  >
                    {onesObject.component}
                  </View>
                  {crossedOutIndices.ones?.includes(one + rowIdx * 5) && (
                    <View style={{ position: 'absolute' }}>
                      {crossThrough({
                        height: scaledOnesHeight + 5,
                        width: scaledOnesWidth + 5
                      })}
                    </View>
                  )}
                </View>
              </Pressable>
            );
          })}
        </View>
      ))}
    </View>
  );

  const baseTenComponents = onesPosition.includes('left')
    ? [onesComponents, tensComponents, hundredsComponents, thousandsComponents]
    : [thousandsComponents, hundredsComponents, tensComponents, onesComponents];

  return <View style={styles.mainContainer}>{baseTenComponents}</View>;
}

/** See {@link SimpleBaseTenWithCrossOut} */
export const SimpleBaseTenWithCrossOutWithState = withStateHOC(SimpleBaseTenWithCrossOut, {
  stateProp: 'crossedOutIndices',
  setStateProp: 'setCrossedOutIndices',
  defaults: {
    defaultState: {}
  }
});

/**
 * Handles interactive {@link SimpleBaseTenWithCrossOut} and pdf view
 * Due to hardcoded values for interactive questions we can just use 'dimens' prop to scale in pdf
 */
export function CrossOutBaseTen({
  thousands = 0,
  hundreds = 0,
  tens = 0,
  ones = 0,
  crossedOutIndices = { thousands: [], hundreds: [], tens: [], ones: [] },
  scale,
  dimens,
  singleRow = false
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'digital') {
    return (
      <SimpleBaseTenWithCrossOutWithState
        dimens={dimens}
        id={'SimpleBaseTenWithCrossOutWithState'}
        thousands={thousands}
        hundreds={hundreds}
        tens={tens}
        ones={ones}
        defaultState={crossedOutIndices}
        singleRow={singleRow}
      />
    );
  } else
    return (
      <SimpleBaseTenWithCrossOut
        dimens={dimens}
        thousands={thousands}
        hundreds={hundreds}
        tens={tens}
        ones={ones}
        scale={scale}
        crossedOutIndices={crossedOutIndices}
        singleRow={singleRow}
      />
    );
}

const getStyles = ({
  dimens,
  isInteractive,
  onesAlignment,
  rotate,
  containerStyleOverride
}: {
  dimens: Dimens;
  isInteractive: boolean;
  onesAlignment: 'flex-start' | 'flex-end';
  rotate?: '90deg' | '180deg' | '270deg';
  containerStyleOverride?: StyleProp<ViewStyle>;
}) =>
  StyleSheet.create({
    mainContainer: {
      alignItems: onesAlignment,
      justifyContent: 'center',
      flexDirection: 'row',
      gap: isInteractive ? 20 : 10,
      maxHeight: dimens.height,
      maxWidth: dimens.width,
      transform: rotate ? [{ rotate }] : undefined,
      ...(containerStyleOverride as object)
    }
  });

export const Scale = (
  usableWidth: number,
  usableHeight: number,
  numbers: {
    ones?: number;
    tens?: number;
    hundreds?: number;
    thousands?: number;
  },
  singleRow: boolean = false
): number => {
  const margin = 4;
  const questionMargin = 20;
  // Set numbers
  const numOnes = numbers.ones ?? 0;
  const numTens = numbers.tens ?? 0;
  const numHundreds = numbers.hundreds ?? 0;
  const numThousands = numbers.thousands ?? 0;

  // Get images
  const onesObject = BaseTenImages['Cubes'].ONES;
  const tensObject = BaseTenImages['Cubes'].TENS;
  const hundredsObject = BaseTenImages['Cubes'].HUNDREDS;
  const thousandsObject = BaseTenImages['Cubes'].THOUSANDS;

  const onesColCount = Math.ceil(numOnes / 5);
  const onesRowCount = Math.min(numOnes, 5);

  const tensColCount = singleRow ? numTens : Math.min(numTens, 5);
  // only ever have one row of tens. For ones to scale properly we have to take the tens heigh into account
  const tensRowCount = numTens === 0 ? 1 : Math.ceil(numTens / tensColCount);

  //This is the same as the logic in the component but it needs to make sure if there are no hundred
  // we don't try to use the dimensions
  const hundredsColCount = singleRow ? numHundreds : getSubitizedNumOfCols(numHundreds);
  const hundredsRowCount = numHundreds === 0 ? 0 : Math.ceil(numHundreds / hundredsColCount);

  //This is the same as the logic in the component but it needs to make sure if there are no thousands
  // we don't try to use the dimensions
  const thousandsColCount = singleRow ? numThousands : getSubitizedNumOfCols(numThousands);
  const thousandsRowCount = numThousands === 0 ? 0 : Math.ceil(numThousands / thousandsColCount);

  // Calculate scale amount
  // Calculate total width
  const widthOnes = onesObject.width * onesColCount;
  const widthTens = tensObject.width * tensColCount;
  const widthHundreds = hundredsObject.width * hundredsColCount;
  const widthThousands = thousandsObject.width * thousandsColCount;
  const totalImageWidth = widthOnes + widthTens + widthHundreds + widthThousands;
  const totalAmountWidth = onesColCount + tensColCount + hundredsColCount + thousandsColCount;

  // Calculate total height
  // There can be one, two or three rows of images per unit type (or one or two rows for tens and ones 'Cubes')
  const heightOnes = onesObject.height * onesRowCount;
  const heightTens = tensObject.height * tensRowCount;
  const heightHundreds = hundredsObject.height * hundredsRowCount;
  const heightThousands = thousandsObject.height * thousandsRowCount;
  const totalImageHeight = Math.max(heightOnes, heightTens, heightHundreds, heightThousands);
  const totalAmountHeight = Math.max(
    onesRowCount,
    tensRowCount,
    hundredsColCount,
    thousandsColCount
  );

  // Calculate smallest scale
  const widthScale =
    (usableWidth - questionMargin - margin * (totalAmountWidth - 1)) / Math.ceil(totalImageWidth);
  const heightScale =
    (usableHeight - questionMargin - margin * (totalAmountHeight - 1)) /
    Math.ceil(totalImageHeight);
  return Math.min(widthScale, heightScale);
};
