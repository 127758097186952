import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { filledArray, range } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { MULT, DIV } from '../../../../constants';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  objectSchema,
  getRandomObject,
  objectAsWord,
  containerOfObject,
  objectPrepositionWord
} from '../../../../utils/objects';
import { getObjectImage } from '../../../../utils/objectsImages';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { useMemo } from 'react';
import { nameSchema, getRandomName } from '../../../../utils/names';
import { getCharacterHeadImage } from '../../../../utils/characters';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'alF',
  description: 'alF',
  keywords: ['Multiply', 'Divide', '7', 'Object'],
  schema: z.object({
    numberPerGroup: z.number().int().min(2).max(6),
    object: objectSchema
  }),
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    return { numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, object },
      translate
    } = props;

    const objectPlural = objectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.howManyObjectsAreThere(objectPlural)}
        testCorrect={[(numberPerGroup * 7).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={800}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }
            ]}
          >
            {range(1, 7).map(index => (
              <View key={index}>
                {getObjectImage(object, numberPerGroup, dimens.height / 2, dimens.width / 4)}
              </View>
            ))}
          </View>
        )}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'alG',
  description: 'alG',
  keywords: ['Times-table', '7', 'Array', 'Multiply', 'Fact family'],
  schema: z.object({
    columns: z.number().int().min(2).max(6)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(2, 6);

    return { columns };
  },
  Component: props => {
    const {
      question: { columns },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.writeTwoMultAndTwoDivFactsShownByArray()}
        testCorrect={answer =>
          ((answer[0][0] === columns.toString() &&
            answer[0][1] === '7' &&
            answer[1][0] === '7' &&
            answer[1][1] === columns.toString()) ||
            (answer[0][0] === '7' &&
              answer[0][1] === columns.toString() &&
              answer[1][0] === columns.toString() &&
              answer[1][1] === '7')) &&
          answer[0][2] === (7 * columns).toString() &&
          answer[1][2] === (7 * columns).toString() &&
          ((answer[2][1] === columns.toString() &&
            answer[2][2] === '7' &&
            answer[3][1] === '7' &&
            answer[3][2] === columns.toString()) ||
            (answer[2][1] === '7' &&
              answer[2][2] === columns.toString() &&
              answer[3][1] === columns.toString() &&
              answer[3][2] === '7')) &&
          answer[2][0] === (7 * columns).toString() &&
          answer[3][0] === (7 * columns).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => <ArrayOfObjects rows={7} columns={columns} dimens={dimens} />}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [columns.toLocaleString(), (7).toLocaleString(), (7 * columns).toLocaleString()],
            [(7).toLocaleString(), columns.toLocaleString(), (7 * columns).toLocaleString()],
            [(7 * columns).toLocaleString(), columns.toLocaleString(), (7).toLocaleString()],
            [(7 * columns).toLocaleString(), (7).toLocaleString(), columns.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'alH',
  description: 'alH',
  keywords: ['Times-table', '7', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    variant: z.enum(['completeBottomRow', 'splitBottomRowBy7', 'fillBottomRowWith7'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const variant = getRandomFromArray([
      'completeBottomRow',
      'splitBottomRowBy7',
      'fillBottomRowWith7'
    ] as const);

    return { number1, variant };
  },

  Component: ({ question: { number1, variant }, translate, displayMode }) => {
    const multiplier = 7;
    const number3 = number1 * multiplier;

    let answerBoxes: number[];
    let answerIndices: number[][];
    let barArray: number[];

    // Three variantions of bar model
    switch (variant) {
      case 'fillBottomRowWith7':
        answerIndices = [[0], []];
        barArray = filledArray(multiplier, number1);
        break;
      case 'splitBottomRowBy7':
        answerIndices = [[0], []];
        barArray = filledArray(number1, multiplier);
        break;
      case 'completeBottomRow':
        answerBoxes = [...Array(multiplier).keys()];
        answerIndices = [[], answerBoxes];
        barArray = filledArray(number1, multiplier);
        break;
    }

    const numbers = [[number3], barArray];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        sameRowColor
        maxFontSize={displayMode === 'digital' ? 40 : 50}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'alI',
  description: 'alI',
  keywords: ['Multiply', 'Divide', '7', 'Problem'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberMultipleA: z.number().int().min(14).max(84).multipleOf(7),
      numberMultipleB: z.number().int().min(14).max(84).multipleOf(7)
    })
    .refine(
      val => val.numberB1 !== 7 && val.numberB1 !== val.numberA1,
      'numberB1 cannot be the same as numberA1 or 7'
    )
    .refine(
      val => val.numberMultipleA !== val.numberMultipleB,
      'numberMultipleA cannot be the same as numberMultipleB.'
    )
    .refine(
      val => val.numberMultipleA !== val.numberA1 * 7 && val.numberMultipleA !== val.numberA1 * 7,
      'numberMultipleA and numberMultipleB must not equal 7 multiplied by numberA1'
    ),
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(2, 12);
    const numberB1 = randomIntegerInclusive(2, 12, {
      constraint: x => x !== 7 && x !== numberA1
    });
    const [numberMultipleA, numberMultipleB] = randomUniqueIntegersInclusiveStep(14, 84, 7, 2, {
      constraint: x => x !== numberA1 * 7
    });

    return { numberA1, numberB1, numberMultipleA, numberMultipleB };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberMultipleA, numberMultipleB },
      translate
    } = props;

    const number2 = 7;
    const numberA3 = numberA1 * number2;
    const numberB3 = numberB1 * number2;

    // Shuffle the items, not the sentences:
    const items = useMemo(() => {
      const itemChoices = [numberA3, numberB1, number2, numberMultipleA, numberMultipleB];

      return shuffle(itemChoices, { random: seededRandom(props.question) });
    }, [numberA3, numberB1, numberMultipleA, numberMultipleB, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteSentences()}
        pdfTitle={translate.instructions.useCardsCompleteSentencesEachCardOnlyUsedOnce()}
        items={items}
        sentences={[
          translate.answerSentences.numWeeksHaveAnsDays(1),
          translate.answerSentences.numWeeksHaveAnsDays(numberA1),
          translate.answerSentences.ansWeeksHaveNumDays(numberB3)
        ]}
        sentencesStyle={{ rowGap: 8 }}
        testCorrect={[[number2], [numberA3], [numberB1]]}
        moveOrCopy="move"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'alJ',
  description: 'alJ',
  keywords: ['Multiply', '7', 'Problem'],
  schema: z.object({
    objectsPerGroup: z.number().int().min(2).max(12),
    object: objectSchema,
    name: nameSchema
  }),
  simpleGenerator: () => {
    const objectsPerGroup = randomIntegerInclusive(2, 12);

    const object = getRandomObject();

    const name = getRandomName();

    return { objectsPerGroup, object, name };
  },
  Component: props => {
    const {
      question: { objectsPerGroup, object, name },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        title={translate.instructions.characterHasNumGroupsCharPutsNumObjects({
          name,
          num1: 7,
          containerPlural,
          num2: objectsPerGroup,
          objectPlural,
          preposition,
          containerSingular
        })}
        testCorrect={answer =>
          ((answer[0] === objectsPerGroup.toString() && answer[1] === '7') ||
            (answer[0] === '7' && answer[1] === objectsPerGroup.toString())) &&
          answer[2] === (objectsPerGroup * 7).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <>{getCharacterHeadImage(name, dimens.height * 0.6, dimens.width)}</>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            objectsPerGroup.toLocaleString(),
            (7).toLocaleString(),
            (objectsPerGroup * 7).toLocaleString()
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'alK',
  description: 'alK',
  keywords: ['Divide', '7', 'Problem'],
  schema: z.object({
    groups: z.number().int().min(2).max(6),
    object: objectSchema,
    name: nameSchema
  }),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    const name = getRandomName();

    return { groups, object, name };
  },
  Component: props => {
    const {
      question: { groups, object, name },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${DIV} <ans/> = <ans/>`}
        title={translate.instructions.characterHasNumObjectsAndSomeGroupsCompleteDivision({
          name,
          num1: groups * 7,
          objectPlural,
          containerPlural,
          num2: 7,
          preposition,
          containerSingular
        })}
        testCorrect={[(groups * 7).toString(), (7).toLocaleString(), groups.toString()]}
        Content={({ dimens }) => (
          <>{getCharacterHeadImage(name, dimens.height * 0.6, dimens.width)}</>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyAndDivideBy7',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
