import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  arrayHasNoDuplicates,
  NonEmptyArray,
  range,
  rangeAsString,
  sortNumberArray
} from '../../../../utils/collections';
import {
  randomIntegerInclusive,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive,
  shuffle,
  randomIntegerInclusiveStep,
  getTwoConsecutiveAndOneNot
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ADD, SUB } from '../../../../constants';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { parseToSUB } from '../../../../utils/parse';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'afc',
  description: 'afc',
  keywords: ['Number line', 'Negative', 'Place value'],
  schema: z.object({
    numbers: z.array(z.number().int().min(-2).max(2)).length(3).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const startingNumber = -3;
    const endNumber = 3;
    const answerInterval = 1;

    /* We want the answers to not be the first or last value on the line,
     * also have two values that are consecutive and a third that isn't */
    const numbers = getTwoConsecutiveAndOneNot(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const startingNumber = -3;
    const endNumber = 3;
    const tickInterval = 1;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(value => {
      return numbers.includes(value) ? '<ans/>' : value.toLocaleString();
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={answer =>
          answer.every((it, index) => it === parseToSUB(numbers[index].toLocaleString()))
        }
        inputMaxCharacters={2}
        tickValues={tickValues}
        extraSymbol="minus"
        customMarkSchemeAnswer={{
          answersToDisplay: numbers.map(num => parseToSUB(num.toLocaleString()))
        }}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'afc2',
  description: 'afc2',
  keywords: ['Number line', 'Negative', 'Place value'],
  schema: z
    .object({
      startingNumber: z.number().int().min(-5).max(-2),
      answerBoxes: z.array(z.number().int().min(-4).max(3)).length(3).refine(arrayHasNoDuplicates)
    })
    .refine(
      val => val.answerBoxes.every(num => num > val.startingNumber && num < val.startingNumber + 6),
      'All answer boxes must be greater than startingNumber, and less than startingNumber + 6'
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(-5, -2);
    const endNumber = startingNumber + 6;
    const tickInterval = 1;

    /* We want the answers to not be the first or last value on the line,
     * also have two values that are consecutive and a third that isn't */
    const answerBoxes = getTwoConsecutiveAndOneNot(
      startingNumber + tickInterval,
      endNumber - tickInterval,
      tickInterval
    );

    return { startingNumber, answerBoxes };
  },
  Component: props => {
    const {
      question: { startingNumber, answerBoxes },
      translate
    } = props;
    const endNumber = startingNumber + 6;
    const tickInterval = 1;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(value => {
      return answerBoxes.includes(value) ? '<ans/>' : parseToSUB(value.toLocaleString());
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={answer =>
          answer.every((it, index) => it === parseToSUB(answerBoxes[index].toLocaleString()))
        }
        inputMaxCharacters={2}
        tickValues={tickValues}
        extraSymbol="minus"
        customMarkSchemeAnswer={{
          answersToDisplay: answerBoxes.map(num => parseToSUB(num.toLocaleString()))
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'afd',
  description: 'afd',
  keywords: ['Place value', 'Number line', 'Negative'],
  schema: z.object({
    var2: z.number().int().min(-5).max(-1),
    var1: z.number().int().min(1).max(10),
    var4: z.number().int().min(1).max(5),
    var3: z.number().int().min(2).max(10)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(-5, -1);
    const var1 = randomIntegerInclusive(1, 5 - var2);
    const var4 = randomIntegerInclusive(1, 5);
    const var3 = randomIntegerInclusive(2, 5 + var4);
    return { var2, var1, var4, var3 };
  },
  Component: props => {
    const {
      question: { var1, var2, var3, var4 },
      translate
    } = props;
    const startingNumber = -5;
    const endNumber = 5;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber);

    const sentences = [
      translate.answerSentences.numXMoreThanNumYIs(var1, var2),
      translate.answerSentences.numXLessThanNumYIs(var3, var4)
    ];
    const answers = [
      [parseToSUB((var1 + var2).toString())],
      [parseToSUB((var4 - var3).toString())]
    ];

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        sentences={sentences}
        title={translate.instructions.completeSentences()}
        testCorrect={answers}
        extraSymbol="minus"
        style={{ rowGap: 16 }}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        questionHeight={800}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'afe',
  description: 'afe',
  keywords: ['Place value', 'Number line', 'Negative'],
  schema: z
    .object({
      number: z.number().int().min(-10).max(-1),
      startingNumber: z.number().int().min(-9).max(-4),
      endNumber: z.number().int().min(0).max(6)
    })
    .refine(val => val.number > val.startingNumber, {
      message: 'Number must be bigger than the starting number'
    })
    .refine(val => val.number < val.endNumber, {
      message: 'Number must be smaller than the end number'
    }),
  simpleGenerator: () => {
    const answerInterval = 1;
    const startingNumber = randomIntegerInclusive(-9, -4);
    const endNumber = startingNumber + 10;

    // Make this miss the first number, and it must be negative
    const number = randomIntegerInclusiveStep(startingNumber + answerInterval, -1, answerInterval);

    return { number, startingNumber, endNumber };
  },
  Component: props => {
    const {
      question: { number, startingNumber, endNumber },
      translate
    } = props;
    const tickInterval = 1;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      if (number === 0) {
        return 0;
      } else if (number === 1) {
        return 1;
      } else return null;
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[parseToSUB(number.toLocaleString())]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
        firstNumber={startingNumber}
        lastNumber={endNumber}
        extraSymbol="minus"
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aff',
  description: 'aff',
  keywords: ['Place value', 'Order', 'Negative'],
  schema: z.object({
    numbers: z.number().int().min(-10).max(10).array().length(4).refine(arrayHasNoDuplicates),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    // Include 0
    let pickedNumbers = [0];
    // Include 2 x negative numbers
    pickedNumbers = pickedNumbers.concat(randomUniqueIntegersInclusive(-10, -1, 2));
    // Include 1 x positive number
    pickedNumbers.push(randomIntegerInclusive(1, 10));

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numbers: shuffle(pickedNumbers), ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const [leftLabel, rightLabel] =
      ordering === 'ascending'
        ? [translate.keywords.Smallest(), translate.keywords.Greatest()]
        : [translate.keywords.Greatest(), translate.keywords.Smallest()];

    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsStartingGreatest' : 'dragCardsStartingSmallest';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsStartingGreatest' : 'useCardsStartingSmallest';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        leftLabel={leftLabel}
        rightLabel={rightLabel}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'afg',
  description: 'afg',
  keywords: ['Place value', 'Number line', 'Negative'],
  schema: z.object({
    var2: z.number().int().min(-5).max(-1),
    var1: z.number().int().min(1).max(10),
    var4: z.number().int().min(1).max(5),
    var3: z.number().int().min(2).max(10)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const var2 = randomIntegerInclusive(-5, -1);
    const var1 = randomIntegerInclusive(1, 5 - var2);
    const var4 = randomIntegerInclusive(1, 5);
    const var3 = randomIntegerInclusive(2, 5 + var4);
    return { var2, var1, var4, var3 };
  },
  Component: props => {
    const {
      question: { var1, var2, var3, var4 },
      translate
    } = props;
    const startingNumber = -5;
    const endNumber = 5;

    // Array of tick values for number line
    const tickValues = range(startingNumber, endNumber);
    const sentences = [
      `${parseToSUB(var2.toLocaleString())} ${ADD} ${var1.toLocaleString()} = <ans/>`,
      `${var4.toLocaleString()} ${SUB} ${var3.toLocaleString()} = <ans/>`
    ];
    const answers = [
      [parseToSUB((var1 + var2).toString())],
      [parseToSUB((var4 - var3).toString())]
    ];

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        sentences={sentences}
        title={translate.instructions.completeCalculations()}
        testCorrect={answers}
        extraSymbol="minus"
        style={{ rowGap: 16 }}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
        questionHeight={800}
      />
    );
  }
});

export const intervals: NonEmptyArray<number> = [-5, -4, -2, 2, 4, 5];
export const Question6 = newQuestionContent({
  uid: 'afh',
  description: 'afh',
  keywords: ['Place value', '50', 'Track'],
  schema: z.object({
    numbers: z.array(z.number().int().min(-25).max(25)).min(4).max(4),
    endNumber: z.number().int().min(-25).max(25),
    startingNumber: z.number().int().min(-25).max(25),
    interval: z
      .number()
      .int()
      .refine(val => intervals.includes(val), `Interval must be one of ${intervals}`)
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray(intervals);
    const startingNumber = randomIntegerInclusive(-4, -1) * interval;
    const endNumber = startingNumber + interval * 5;

    const choices = range(startingNumber, endNumber, Math.abs(interval)); // A negative interval throws and error from the range function.

    // Make this miss the first number
    const numbers = getRandomSubArrayFromArray(choices.slice(1), 4);

    // Convert the set in to an array and sort the numbers.
    // Depending on the interval we sometimes need to sort in ascending and descending.
    const sortedNumbers = sortNumberArray(numbers, interval < 0 ? 'descending' : 'ascending');
    return { numbers: sortedNumbers, startingNumber, endNumber, interval };
  },
  Component: ({ question, translate }) => {
    const { numbers, startingNumber, endNumber, interval } = question;

    // Create array to pass to Number Line
    const numberArray = rangeAsString(startingNumber, endNumber, Math.abs(interval)); // A negative interval throws and error from the range function.

    // Set where the answers should go
    numbers.forEach(number => {
      numberArray[numberArray.indexOf(number.toString())] = '<ans/>';
    });

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        extraSymbol={'minus'}
        testCorrect={numbers.map(it => parseToSUB(it.toString()))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NegativeNumbers',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
