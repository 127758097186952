import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ContentBox from '../../../../components/molecules/ContentBox';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import {
  algebraicSymbolSchema,
  algebraicSymbols,
  getAlgebraicSymbol
} from '../../../../utils/algebraicSymbols';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { ADD, ALGEBRAIC_A, SUB } from '../../../../constants';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { MarkupAssets } from '../../../../markup';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aUk',
  description: 'aUk',
  keywords: ['Variable', 'Constant', 'Algebra'],
  schema: z.object({
    cubes: z.number().int().min(2).max(9),
    cubeValue: z.number().int().min(2).max(10),
    cubeColour: z.enum(['blue', 'green', 'red', 'yellow'])
  }),
  simpleGenerator: () => {
    const cubes = randomIntegerInclusive(2, 9);
    const cubeValue = randomIntegerInclusive(2, 10);
    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    return { cubes, cubeValue, cubeColour };
  },
  Component: props => {
    const {
      question: { cubes, cubeValue, cubeColour },
      translate,
      displayMode
    } = props;

    const total = cubes * cubeValue;

    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfCubes = range(1, cubes).map(i => (
      <AssetSvg
        name={cubesSvgName}
        width={displayMode === 'digital' ? 70 : 100}
        key={`cube_${i}`}
      />
    ));

    return (
      <MarkupAssets
        elements={{
          cube: (
            <AssetSvg key="cube" name={cubesSvgName} width={displayMode === 'digital' ? 70 : 100} />
          )
        }}
      >
        <QF1ContentAndSentence
          title={translate.instructions.workOutValueOfLinkingCube()}
          Content={
            <View style={{ alignItems: 'center' }}>
              <View style={{ width: arrayOfCubes.length * (displayMode === 'digital' ? 80 : 110) }}>
                <BarModelCurlyBrace braceText={total.toLocaleString()} />
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 10
                }}
              >
                {arrayOfCubes}
              </View>
            </View>
          }
          pdfDirection="column"
          sentenceStyle={{ justifyContent: 'flex-end' }}
          pdfSentenceStyle={{ justifyContent: 'flex-end' }}
          sentence={`<asset name='cube'/> = <ans/>`}
          testCorrect={[cubeValue.toLocaleString()]}
        />
      </MarkupAssets>
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aUl',
  description: 'aUl',
  keywords: ['Variable', 'Constant', 'Algebra'],
  schema: z.object({
    ones: z.number().int().min(2).max(9),
    cubeValue: z.number().int().min(3).max(10),
    cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
    onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(2, 9);
    const cubeValue = randomIntegerInclusive(3, 10);
    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);

    return { ones, cubeValue, cubeColour, onesCubeColur };
  },
  Component: props => {
    const {
      question: { ones, cubeValue, cubeColour, onesCubeColur },
      translate,
      displayMode
    } = props;
    const total = cubeValue + ones;

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfOnes = range(1, ones).map(i => (
      <AssetSvg name={onesSvgName} width={displayMode === 'digital' ? 70 : 100} key={`one_${i}`} />
    ));
    const cube = [
      <AssetSvg name={cubesSvgName} width={displayMode === 'digital' ? 70 : 100} key={`cube_0`} />
    ];

    const arrayOfObjects = [...cube, ...arrayOfOnes];
    return (
      <MarkupAssets
        elements={{
          cube: (
            <AssetSvg key="cube" name={cubesSvgName} width={displayMode === 'digital' ? 70 : 100} />
          ),
          ones: (
            <AssetSvg key="ones" name={onesSvgName} width={displayMode === 'digital' ? 70 : 100} />
          )
        }}
      >
        <QF1ContentAndSentence
          title={translate.instructions.workOutValueOfLinkingCube()}
          Content={({ dimens }) => (
            <View style={{ ...dimens, justifyContent: 'space-around' }}>
              <ContentBox
                containerStyle={{
                  flexDirection: 'row',
                  alignSelf: 'flex-end'
                }}
              >
                <TextStructure sentence={`<asset name='ones'/> = ${(1).toLocaleString()}`} />
              </ContentBox>
              <View style={{ alignItems: 'center' }}>
                <View
                  style={{ width: arrayOfObjects.length * (displayMode === 'digital' ? 80 : 110) }}
                >
                  <BarModelCurlyBrace braceText={total.toLocaleString()} />
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 10
                  }}
                >
                  {arrayOfObjects}
                </View>
              </View>
            </View>
          )}
          sentence={`<asset name='cube'/> = <ans/>`}
          sentenceStyle={{ alignSelf: 'flex-end' }}
          pdfSentenceStyle={{ alignSelf: 'flex-end' }}
          testCorrect={[cubeValue.toString()]}
          pdfDirection="column"
        />
      </MarkupAssets>
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aUm',
  description: 'aUm',
  keywords: ['Solve', 'Equation', 'Bar model'],
  schema: z.object({
    var1: z.number().int().min(1).max(10),
    number: z.number().int().min(1).max(10),
    combinedBar: z.boolean(),
    symbol: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const symbol = getAlgebraicSymbol();
    const combinedBar = getRandomBoolean();

    const var1 = randomIntegerInclusive(1, combinedBar ? 6 : 10);
    const number = randomIntegerInclusive(2, combinedBar ? 5 : 10);
    return { symbol, var1, number, combinedBar };
  },
  Component: props => {
    const {
      question: { symbol, var1, number, combinedBar },
      translate,
      displayMode
    } = props;
    const total = combinedBar ? var1 + number : number * var1;

    const bar = combinedBar ? [var1, number] : range(1, number).map(_ => var1);
    const labels = combinedBar
      ? [`${symbol}`, number.toLocaleString()]
      : range(1, number).map(_ => symbol);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheValueOf(symbol)}
        Content={({ dimens }) => (
          <BarModel
            dimens={dimens}
            total={total}
            numbers={[[total], bar]}
            strings={[[total.toLocaleString()], labels]}
            maxFontSize={displayMode === 'digital' ? 32 : 50}
            sameRowColor
          />
        )}
        sentence={`${symbol} = <ans/>`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[var1.toString()]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aUn',
  description: 'aUn',
  keywords: ['Equation', 'Solve'],
  schema: z.object({
    symbol: algebraicSymbolSchema,
    var1: z.number().int().min(1).max(6),
    var2: z.number().int().min(1).max(6),
    var3: z.number().int().min(1).max(6),
    var4: z.number().int().min(1).max(6),
    multiplier1: z.number().int().min(2).max(10),
    multiplier4: z.number().int().min(2).max(10),
    number2: z.number().int().min(1).max(20),
    number3: z.number().int().min(2).max(20)
  }),
  simpleGenerator: () => {
    const symbol = getAlgebraicSymbol();

    const [var1, var2, var3, var4] = randomUniqueIntegersInclusive(1, 6, 4);

    const multiplier1 = randomIntegerInclusive(2, 10);
    const multiplier4 = randomIntegerInclusive(2, 10);

    const number2 = randomIntegerInclusive(1, 20);
    const number3 = randomIntegerInclusive(var3 + 1, 20);

    return {
      symbol,
      var1,
      var2,
      var3,
      var4,
      multiplier1,
      multiplier4,
      number2,
      number3
    };
  },
  Component: props => {
    const {
      question: { symbol, var1, var2, var3, var4, multiplier1, multiplier4, number2, number3 },
      translate
    } = props;

    const total1 = multiplier1 * var1;
    const total2 = var2 + number2;
    const total3 = number3 - var3;
    const total4 = multiplier4 * var4;

    const statements = [
      {
        sentence: `${total1.toLocaleString()} = ${multiplier1.toLocaleString()}${symbol}        ${symbol} = <ans/>`,
        correctAnswer: var1
      },
      {
        sentence: `${number2.toLocaleString()} ${ADD} ${symbol} = ${total2.toLocaleString()}        ${symbol} = <ans/>`,
        correctAnswer: var2
      },
      {
        sentence: `${number3.toLocaleString()} ${SUB} ${symbol} = ${total3.toLocaleString()}        ${symbol} = <ans/>`,
        correctAnswer: var3
      },
      {
        sentence: `${multiplier4.toLocaleString()}${symbol} = ${total4.toLocaleString()}        ${symbol} = <ans/>`,
        correctAnswer: var4
      }
    ];

    const items = shuffle([`${var1}`, `${var2}`, `${var3}`, `${var4}`], {
      random: seededRandom(props.question)
    });
    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsSolveEquations()}
        pdfTitle={translate.instructions.useCardsSolveEquations()}
        items={items}
        sentences={statements.map(({ sentence }) => sentence)}
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        actionPanelVariant="end"
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer.toString()])}
        pdfSentencesStyle={{ alignItems: 'flex-end' }}
        pdfLayout={'itemsRight'}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4v2 = newQuestionContent({
  uid: 'aUn2',
  description: 'aUn2',
  keywords: ['Equation', 'Solve'],
  schema: z
    .object({
      symbol1: algebraicSymbolSchema,
      symbol2: algebraicSymbolSchema,
      symbol3: algebraicSymbolSchema,
      symbol4: algebraicSymbolSchema,
      var1: z.number().int().min(1).max(6),
      var2: z.number().int().min(1).max(6),
      var3: z.number().int().min(1).max(6),
      var4: z.number().int().min(1).max(6),
      multiplier1: z.number().int().min(2).max(10),
      multiplier4: z.number().int().min(2).max(10),
      number2: z.number().int().min(1).max(20),
      number3: z.number().int().min(2).max(20)
    })
    .refine(
      val => arrayHasNoDuplicates([val.symbol1, val.symbol2, val.symbol3, val.symbol4]),
      'All algebraic symbols must be different.'
    ),
  simpleGenerator: () => {
    const [symbol1, symbol2, symbol3, symbol4] = getRandomSubArrayFromArray(algebraicSymbols, 4);

    const [var1, var2, var3, var4] = randomUniqueIntegersInclusive(1, 6, 4);

    const multiplier1 = randomIntegerInclusive(2, 10);
    const multiplier4 = randomIntegerInclusive(2, 10);

    const number2 = randomIntegerInclusive(1, 20);
    const number3 = randomIntegerInclusive(var3 + 1, 20);

    return {
      symbol1,
      symbol2,
      symbol3,
      symbol4,
      var1,
      var2,
      var3,
      var4,
      multiplier1,
      multiplier4,
      number2,
      number3
    };
  },
  Component: props => {
    const {
      question: {
        symbol1,
        symbol2,
        symbol3,
        symbol4,
        var1,
        var2,
        var3,
        var4,
        multiplier1,
        multiplier4,
        number2,
        number3
      },
      translate,
      displayMode
    } = props;

    const total1 = multiplier1 * var1;
    const total2 = var2 + number2;
    const total3 = number3 - var3;
    const total4 = multiplier4 * var4;

    const statements = [
      {
        sentence: `${total1.toLocaleString()} = ${multiplier1.toLocaleString()}${symbol1}        ${symbol1} = <ans/>`,
        correctAnswer: var1.toLocaleString()
      },
      {
        sentence: `${number2.toLocaleString()} ${ADD} ${symbol2} = ${total2.toLocaleString()}        ${symbol2} = <ans/>`,
        correctAnswer: var2.toLocaleString()
      },
      {
        sentence: `${number3.toLocaleString()} ${SUB} ${symbol3} = ${total3.toLocaleString()}        ${symbol3} = <ans/>`,
        correctAnswer: var3.toLocaleString()
      },
      {
        sentence: `${multiplier4.toLocaleString()}${symbol4} = ${total4.toLocaleString()}        ${symbol4} = <ans/>`,
        correctAnswer: var4.toLocaleString()
      }
    ];

    const items = shuffle(
      [
        `${var1.toLocaleString()}`,
        `${var2.toLocaleString()}`,
        `${var3.toLocaleString()}`,
        `${var4.toLocaleString()}`
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsSolveEquations()}
        pdfTitle={translate.instructions.useCardsSolveEquations()}
        items={items}
        sentences={statements.map(({ sentence }) => sentence)}
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        actionPanelVariant="end"
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer.toString()])}
        pdfSentencesStyle={{ alignItems: 'flex-end' }}
        pdfLayout={'itemsRight'}
        questionHeight={900}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aUo',
  description: 'aUo',
  keywords: ['Equation', 'Solve'],
  schema: z.object({
    symbol1: algebraicSymbolSchema,
    symbol2: algebraicSymbolSchema,
    symbol3: algebraicSymbolSchema,
    var1: z.number().int().min(1).max(10),
    var2: z.number().int().min(1).max(10),
    var3: z.number().int().min(2).max(10),
    multiplier2: z.number().int().min(2).max(10),
    number1: z.number().int().min(1).max(20),
    number3: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const [symbol1, symbol2, symbol3] = getRandomSubArrayFromArray(algebraicSymbols, 3);

    const var1 = randomIntegerInclusive(1, 6);
    const var2 = randomIntegerInclusive(1, 6);
    const var3 = randomIntegerInclusive(2, 6);

    const multiplier2 = randomIntegerInclusive(2, 10);

    const number1 = randomIntegerInclusive(1, 20);
    const number3 = randomIntegerInclusive(1, var3 - 1);

    return {
      symbol1,
      symbol2,
      symbol3,
      var1,
      var2,
      var3,
      multiplier2,
      number1,
      number3
    };
  },
  Component: props => {
    const {
      question: { symbol1, symbol2, symbol3, var1, var2, var3, multiplier2, number1, number3 },
      translate
    } = props;

    const total1 = var1 + number1;
    const total2 = multiplier2 * var2;
    const total3 = var3 - number3;

    const statements = shuffle(
      [
        {
          sentence: `${number1.toLocaleString()} ${ADD} ${symbol1} = ${total1.toLocaleString()}        ${symbol1} = <ans/>`,
          correctAnswer: var1
        },
        {
          sentence: `${multiplier2.toLocaleString()}${symbol2} = ${total2.toLocaleString()}         ${symbol2} = <ans/>`,
          correctAnswer: var2
        },
        {
          sentence: `${total3.toLocaleString()} = ${symbol3} ${SUB} ${number3.toLocaleString()}         ${symbol3} = <ans/>`,
          correctAnswer: var3
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.solveEquations()}
        sentences={statements.map(({ sentence }) => sentence)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer.toString()])}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aUo2',
  description: 'aUo2',
  keywords: ['Equation', 'Solve'],
  schema: z.object({
    symbol: algebraicSymbolSchema,
    symbolAmount: z.number().int().min(1).max(6),
    otherNumber: z.number().int().min(1).max(20),
    equation: z.enum(['addition', 'subtraction', 'multiplication'])
  }),
  simpleGenerator: () => {
    const symbol = getRandomFromArray(algebraicSymbols);

    const equation = getRandomFromArray(['addition', 'subtraction', 'multiplication'] as const);

    const symbolAmount = randomIntegerInclusive(equation === 'subtraction' ? 2 : 1, 6);

    const otherNumber =
      equation === 'addition'
        ? randomIntegerInclusive(1, 20)
        : equation === 'subtraction'
        ? randomIntegerInclusive(1, symbolAmount - 1)
        : randomIntegerInclusive(2, 10);

    return {
      symbol,
      symbolAmount,
      otherNumber,
      equation
    };
  },
  Component: props => {
    const {
      question: { symbol, symbolAmount, otherNumber, equation },
      translate
    } = props;

    const sentenceAnswer =
      equation === 'addition'
        ? symbolAmount + otherNumber
        : equation === 'subtraction'
        ? symbolAmount - otherNumber
        : symbolAmount * otherNumber;

    const sentence = (() => {
      switch (equation) {
        case 'addition':
          return `${otherNumber.toLocaleString()} ${ADD} ${symbol} = ${sentenceAnswer.toLocaleString()}        ${symbol} = <ans/>`;
        case 'multiplication':
          return `${otherNumber.toLocaleString()}${symbol} = ${sentenceAnswer.toLocaleString()}         ${symbol} = <ans/>`;
        case 'subtraction':
          return `${sentenceAnswer.toLocaleString()} = ${symbol} ${SUB} ${otherNumber.toLocaleString()}         ${symbol} = <ans/>`;
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.solveTheEquation()}
        sentence={sentence}
        testCorrect={[symbolAmount.toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aUp',
  description: 'aUp',
  keywords: ['Perimeter', 'Equation', 'Solve'],
  schema: z.object({
    symbol: algebraicSymbolSchema,
    shape: z.enum(['Rectangle', 'Trapezium', 'Triangle']),
    varValue: z.number().int().min(1).max(6),
    varMultiplier1: z.number().int().min(1).max(4),
    varMultiplier2: z.number().int().min(1).max(6),
    varMultiplier3: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['Rectangle', 'Trapezium', 'Triangle'] as const);
    const symbol = getAlgebraicSymbol();

    const varValue = randomIntegerInclusive(1, 6);

    const varMultiplier1 = randomIntegerInclusive(1, 4, {
      constraint: x => (symbol === ALGEBRAIC_A ? x !== 1 : true)
    });

    const varMultiplier2 = randomIntegerInclusive(varMultiplier1, 6);
    const varMultiplier3 = randomIntegerInclusive(varMultiplier2 + 1, 8);

    return { shape, symbol, varValue, varMultiplier1, varMultiplier2, varMultiplier3 };
  },
  Component: props => {
    const {
      question: { shape, symbol, varValue, varMultiplier1, varMultiplier2, varMultiplier3 },
      translate
    } = props;
    let perimeter = 0;
    let labels: string[];

    switch (shape) {
      case 'Rectangle': {
        perimeter = 2 * varValue * (varMultiplier1 + varMultiplier2);
        labels = [
          `${varMultiplier1.toLocaleString()}${symbol}`,
          `${varMultiplier2.toLocaleString()}${symbol}`
        ];
        break;
      }
      case 'Trapezium': {
        perimeter = varValue * (2 * varMultiplier1 + varMultiplier2 + varMultiplier3);
        labels = [
          `${varMultiplier2.toLocaleString()}${symbol}`,
          `${varMultiplier1.toLocaleString()}${symbol}`,
          `${varMultiplier3.toLocaleString()}${symbol}`
        ];
        break;
      }
      case 'Triangle': {
        perimeter = varValue * (2 * varMultiplier1 + varMultiplier2);
        labels = [
          `${varMultiplier1.toLocaleString()}${symbol}`,
          `${varMultiplier2.toLocaleString()}${symbol}`,
          `${varMultiplier1.toLocaleString()}${symbol}`
        ];
        break;
      }
    }
    return (
      <QF1ContentAndSentence
        title={translate.instructions.perimeterOfShapeIsXFindValueOfY(perimeter, symbol)}
        Content={({ dimens }) => (
          <>
            {shape === 'Triangle' ? (
              <LabelledTriangle labels={labels} dimens={dimens} labelFontSize="large" />
            ) : (
              <LabelledQuadrilateral
                dimens={dimens}
                shape={shape}
                labels={labels}
                shapeSize="large"
                labelFontSize="large"
              />
            )}
          </>
        )}
        sentence={`${symbol} = <ans/>`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[varValue.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Solve1StepEquations',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question4, Question5]
});
export default SmallStep;
