import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  countRange,
  filledArray
} from '../../../../utils/collections';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import PieChart from '../../../../components/question/representations/PieChart';
import {
  compareFractions,
  fractionToDecimal,
  improperFractionToMixedNumber,
  mixedNumberToImproperFraction
} from '../../../../utils/fractions';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import TextStructure from '../../../../components/molecules/TextStructure';
import ContentBox from '../../../../components/molecules/ContentBox';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF14aCompleteNumberTrackDraggable from '../../../../components/question/questionFormats/QF14aCompleteNumberTrackDraggable';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMc',
  description: 'aMc',
  keywords: ['Whole', 'Equal parts', 'Numerator', 'Denominator', 'One whole'],
  schema: z.object({
    number1: z.number().int().min(2).max(8),
    representation: z.enum(['numberLine', 'barModel', 'pie']),
    answerBoxIndex: z.number().min(0).max(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 8);
    const representation = getRandomFromArray(['numberLine', 'barModel', 'pie'] as const);
    const answerBoxIndex = randomIntegerInclusive(0, 2);

    return { number1, representation, answerBoxIndex };
  },
  Component: props => {
    const {
      question: { number1, representation, answerBoxIndex },
      translate
    } = props;

    let sentence;
    let answer;
    switch (answerBoxIndex) {
      case 0:
        sentence = `<frac nAns='' d='${number1}' /> = 1`;
        answer = number1.toString();
        break;
      case 1:
        sentence = `<frac n='${number1}' dAns='' /> = 1`;
        answer = number1.toString();
        break;
      default:
        sentence = `<frac n='${number1}' d='${number1}' /> = <ans/>`;
        answer = '1';
        break;
    }

    return (
      <QF1ContentAndSentence
        sentence={sentence}
        title={translate.instructions.completeMissingNumber()}
        testCorrect={[answer]}
        textStyle={{ fontSize: 40 }}
        Content={({ dimens }) => {
          return representation === 'barModel' ? (
            <ShadedFractionBarModel
              totalSubSections={number1}
              width={dimens.width * 0.8}
              coloredSections={countRange(number1)}
            />
          ) : representation === 'numberLine' ? (
            <NumberLine
              tickValues={countRange(number1 + 1).map(i =>
                i === 0 ? (0).toLocaleString() : i === number1 ? (1).toLocaleString() : ''
              )}
              dimens={dimens}
            />
          ) : (
            <PieChart
              pieOptions={filledArray({ ratioOfSlices: 1 }, number1)}
              radius={dimens.height / 3}
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aMd',
  description: 'aMd',
  keywords: ['Number line', 'Fraction', 'Numerators', 'Denominator', 'One whole', 'Mixed numbers'],
  schema: z.object({
    denominator: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 6);

    return { denominator };
  },

  Component: props => {
    const {
      question: { denominator },
      translate,
      displayMode
    } = props;

    const values = countRange(3, 2)
      .map(i => improperFractionToMixedNumber(denominator + i, denominator, false))
      .map((val, i) => ({
        component: (
          <TextStructure
            key={i}
            sentence={
              val[1] === 0
                ? val[0].toLocaleString()
                : val[0] === 0
                ? `<frac n='${val[1]}' d='${val[2]}' />`
                : `<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${val[2]}' />`
            }
            textVariant="WRN700"
            textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            fractionDividerStyle={{ marginVertical: 2 }}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ),
        value: `${i}`
      }));

    const items = shuffle(values, {
      random: seededRandom(props.question)
    });

    // Create array to pass to Number Line
    const bottomTickValues: string[] = countRange(5).map(i =>
      i === 0
        ? (1).toLocaleString()
        : i === 1
        ? `<frac w='${(1).toLocaleString()}' n='${1}' d='${denominator}' />`
        : '<ans/>'
    );
    const topTickValues = countRange(5).map(() => '');

    const correct = values.map(i => i.value);

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={correct}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aMe',
  description: 'aMe',
  keywords: ['Counting forwards', 'Counting backwards', 'Fraction', 'Mixed number'],
  schema: z.object({
    denominator: z.number().int().min(2).max(10),
    startingNumber: z.number().int().min(1).max(15),
    startingIndex: z.number().int().min(0).max(2),
    answerIndex: z.number().int().min(0).max(6),
    order: z.enum(['forwards', 'backwards']),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);
    const startingNumber = randomIntegerInclusive(1, 15);
    const startingIndex = randomIntegerInclusive(0, 2);
    const max = startingIndex === 0 ? 4 : startingIndex === 1 ? 5 : 6;
    const answerIndex = randomIntegerInclusive(startingIndex, max);
    const order = getRandomFromArray(['forwards', 'backwards'] as const);
    const name = getRandomName();

    return { denominator, startingIndex, startingNumber, answerIndex, order, name };
  },
  Component: props => {
    const {
      question: { denominator, startingNumber, answerIndex, startingIndex, order, name },
      translate,
      displayMode
    } = props;

    let numberArray = countRange(7).map(i =>
      improperFractionToMixedNumber(startingNumber + i, denominator, false)
    );
    numberArray = order === 'backwards' ? numberArray.reverse() : numberArray;

    const displayFractions = countRange(5, startingIndex).map(i => numberArray[i]);

    const incorrectOptionIndexes =
      startingIndex === 0 ? [5, 6] : startingIndex === 1 ? [0, 6] : [0, 1];

    const vocab = () => {
      switch (denominator) {
        case 2:
          return translate.fractions.halves(2);
        case 3:
          return translate.fractions.thirds(2);
        case 4:
          return translate.fractions.quarters(2);
        case 5:
          return translate.fractions.fifths(2);
        case 6:
          return translate.fractions.sixths(2);
        case 7:
          return translate.fractions.sevenths(2);
        case 8:
          return translate.fractions.eighths(2);
        case 9:
          return translate.fractions.ninths(2);
        case 10:
          return translate.fractions.tenths(2);
      }
    };

    const statements = [...incorrectOptionIndexes, answerIndex].map(i => {
      return {
        sentence:
          numberArray[i][1] === 0
            ? numberArray[i][0].toLocaleString()
            : numberArray[i][0] === 0
            ? `<frac n='${numberArray[i][1]}' d='${numberArray[i][2]}' />`
            : `<frac w='${numberArray[i][0].toLocaleString()}' n='${numberArray[i][1]}' d='${
                numberArray[i][2]
              }' />`,
        value: `${i}`,
        correct: i === answerIndex
      };
    });

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    // Filter correct answers
    const correctAnswer = statements.filter(it => it.correct);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          order === 'backwards'
            ? translate.instructions.characterIsCountingBackwardsInXMissingFraction(name, vocab())
            : translate.instructions.characterIsCountingForwardsInXMissingFraction(name, vocab())
        }
        testCorrect={correctAnswer.map(it => it.value)}
        multiSelect
        numItems={4}
        Content={
          <ContentBox containerStyle={{ flexDirection: 'row', gap: 60 }}>
            {displayFractions.map((val, i) => {
              const sentence =
                i + startingIndex === answerIndex
                  ? '?'
                  : val[1] === 0
                  ? val[0].toLocaleString()
                  : val[0] === 0
                  ? `<frac n='${val[1]}' d='${val[2]}' />`
                  : `<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${val[2]}' />`;
              return (
                <TextStructure
                  key={i}
                  sentence={sentence}
                  textVariant="WRN400"
                  fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                />
              );
            })}
          </ContentBox>
        }
        renderItems={shuffledStatements.map(({ sentence, value }) => ({
          value,
          component: (
            <TextStructure
              sentence={sentence}
              textVariant="WRN700"
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
            />
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'aMe2',
  description: 'aMe',
  keywords: ['Counting forwards', 'Counting backwards', 'Fraction', 'Mixed number'],
  schema: z.object({
    denominator: z.number().int().min(2).max(10),
    startingNumber: z.number().int().min(1).max(15),
    startingIndex: z.number().int().min(0).max(2),
    answerIndex: z.number().int().min(0).max(6),
    order: z.enum(['forwards', 'backwards']),
    name: nameSchema,
    options: z
      .array(z.tuple([z.number().int(), z.number().int(), z.number().int().min(1).max(11)]))
      .length(4)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(2, 10);
    const startingNumber = randomIntegerInclusive(1, 15);
    const startingIndex = randomIntegerInclusive(0, 2);
    const max = startingIndex === 0 ? 4 : startingIndex === 1 ? 5 : 6;
    const answerIndex = randomIntegerInclusive(startingIndex, max);
    const order = getRandomFromArray(['forwards', 'backwards'] as const);
    const name = getRandomName();

    const incorrectOptionIndexes =
      startingIndex === 0 ? [5, 6] : startingIndex === 1 ? [0, 6] : [0, 1];

    let numberArray = countRange(7).map(i =>
      improperFractionToMixedNumber(startingNumber + i, denominator, false)
    );
    numberArray = order === 'backwards' ? numberArray.reverse() : numberArray;

    const options: [number, number, number][] = numberArray.filter((_, i) =>
      [...incorrectOptionIndexes, answerIndex].includes(i)
    );

    options.push([
      numberArray[answerIndex][0],
      numberArray[answerIndex][1],
      order === 'backwards' ? denominator - 1 : denominator + 1
    ]);

    return {
      denominator,
      startingIndex,
      startingNumber,
      answerIndex,
      order,
      name,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { denominator, startingNumber, answerIndex, startingIndex, order, name, options },
      translate,
      displayMode
    } = props;

    let numberArray = countRange(7).map(i =>
      improperFractionToMixedNumber(startingNumber + i, denominator, false)
    );
    numberArray = order === 'backwards' ? numberArray.reverse() : numberArray;

    const displayFractions = countRange(5, startingIndex).map(i => numberArray[i]);

    const vocab = (() => {
      switch (denominator) {
        case 2:
          return translate.fractions.halves(2);
        case 3:
          return translate.fractions.thirds(2);
        case 4:
          return translate.fractions.quarters(2);
        case 5:
          return translate.fractions.fifths(2);
        case 6:
          return translate.fractions.sixths(2);
        case 7:
          return translate.fractions.sevenths(2);
        case 8:
          return translate.fractions.eighths(2);
        case 9:
          return translate.fractions.ninths(2);
        case 10:
          return translate.fractions.tenths(2);
      }
    })();

    const statements = options.map((value, i) => {
      return {
        sentence:
          value[1] === 0
            ? value[2] === denominator
              ? value[0].toLocaleString()
              : // Need to force the numerator to be '1' in this particular instance so we don't have duplicate selectables:
                `<frac w='${value[0].toLocaleString()}' n='1' d='${
                  // In some cases, the denominator can sometimes be 1, which we never want to use
                  // - in this case, force this incorrect denominator to instead be correctDenominator + 1
                  // (this is the only item to ever have an incorrect denominator).
                  value[2] === 1 ? denominator + 1 : value[2]
                }' />`
            : value[0] === 0
            ? `<frac n='${value[1]}' d='${value[2]}' />`
            : `<frac w='${value[0].toLocaleString()}' n='${value[1]}' d='${value[2]}' />`,
        value: `${i}`,
        correct: arraysHaveSameContents(value, numberArray[answerIndex])
      };
    });

    // Filter correct answers
    const correctAnswer = statements.filter(it => it.correct);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          order === 'backwards'
            ? translate.instructions.characterIsCountingBackwardsInXMissingFractionSelectTheMissingFraction(
                name,
                vocab
              )
            : translate.instructions.characterIsCountingForwardsInXMissingFractionSelectTheMissingFraction(
                name,
                vocab
              )
        }
        pdfTitle={
          order === 'backwards'
            ? translate.instructions.characterIsCountingBackwardsInXMissingFractionCircleTheMissingFraction(
                name,
                vocab
              )
            : translate.instructions.characterIsCountingForwardsInXMissingFractionCircleTheMissingFraction(
                name,
                vocab
              )
        }
        testCorrect={correctAnswer.map(it => it.value)}
        numItems={4}
        Content={
          <View style={{ flexDirection: 'row', gap: 20 }}>
            {displayFractions.map((val, i) => {
              const sentence =
                i + startingIndex === answerIndex
                  ? '?'
                  : val[1] === 0
                  ? val[0].toLocaleString()
                  : val[0] === 0
                  ? `<frac n='${val[1]}' d='${val[2]}' />`
                  : `<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${val[2]}' />`;
              return (
                <ContentBox
                  key={i}
                  containerStyle={{
                    width: displayMode === 'digital' ? 120 : 150,
                    justifyContent: 'center'
                  }}
                >
                  <TextStructure
                    sentence={sentence}
                    textVariant="WRN400"
                    fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                  />
                </ContentBox>
              );
            })}
          </View>
        }
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: (
            <TextStructure
              sentence={sentence}
              textVariant="WRN700"
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
            />
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v3 = newQuestionContent({
  uid: 'aMe3',
  description: 'aMe',
  keywords: ['Counting forwards', 'Counting backwards', 'Fraction', 'Mixed number'],
  schema: z.object({
    denominator: z.number().int().min(2).max(10),
    startingNumber: z.number().int().min(1).max(15),
    startingIndex: z.number().int().min(0).max(2),
    answerIndex: z.number().int().min(0).max(6),
    order: z.enum(['forwards', 'backwards']),
    name: nameSchema,
    options: z
      .array(z.tuple([z.number().int(), z.number().int(), z.number().int().min(1).max(11)]))
      .length(4)
  }),
  simpleGenerator: () => {
    const { denominator, startingIndex, startingNumber, answerIndex, order, name, options } =
      rejectionSample(
        () => {
          const denominator = randomIntegerInclusive(2, 10);
          const startingNumber = randomIntegerInclusive(1, 15);
          const startingIndex = randomIntegerInclusive(0, 2);
          const max = startingIndex === 0 ? 4 : startingIndex === 1 ? 5 : 6;
          const answerIndex = randomIntegerInclusive(startingIndex, max);
          const order = getRandomFromArray(['forwards', 'backwards'] as const);
          const name = getRandomName();

          const incorrectOptionIndexes =
            startingIndex === 0 ? [5, 6] : startingIndex === 1 ? [0, 6] : [0, 1];

          let numberArray = countRange(7).map(i =>
            improperFractionToMixedNumber(startingNumber + i, denominator, false)
          );
          numberArray = order === 'backwards' ? numberArray.reverse() : numberArray;

          const options: [number, number, number][] = numberArray.filter((_, i) =>
            [...incorrectOptionIndexes, answerIndex].includes(i)
          );

          options.push([
            numberArray[answerIndex][0],
            numberArray[answerIndex][1],
            order === 'backwards' ? denominator - 1 : denominator + 1
          ]);

          return { denominator, startingIndex, startingNumber, answerIndex, order, name, options };
        },
        // Only permit if there are no equivalent options:
        ({ options }) =>
          arrayHasNoDuplicates(
            options.map(option => {
              const improperFraction = mixedNumberToImproperFraction(
                option[0],
                option[1],
                option[2]
              );
              // Convert to decimals to prevent equivalent fractions, e.g. 2 whole and 2/2, and 3 whole and 0/2, would both equal 3:
              return fractionToDecimal(improperFraction[0], improperFraction[1]);
            })
          )
      );

    return {
      denominator,
      startingIndex,
      startingNumber,
      answerIndex,
      order,
      name,
      options: shuffle(options)
    };
  },
  Component: props => {
    const {
      question: { denominator, startingNumber, answerIndex, startingIndex, order, name, options },
      translate,
      displayMode
    } = props;

    let numberArray = countRange(7).map(i =>
      improperFractionToMixedNumber(startingNumber + i, denominator, false)
    );
    numberArray = order === 'backwards' ? numberArray.reverse() : numberArray;

    const displayFractions = countRange(5, startingIndex).map(i => numberArray[i]);

    const vocab = (() => {
      switch (denominator) {
        case 2:
          return translate.fractions.halves(2);
        case 3:
          return translate.fractions.thirds(2);
        case 4:
          return translate.fractions.quarters(2);
        case 5:
          return translate.fractions.fifths(2);
        case 6:
          return translate.fractions.sixths(2);
        case 7:
          return translate.fractions.sevenths(2);
        case 8:
          return translate.fractions.eighths(2);
        case 9:
          return translate.fractions.ninths(2);
        case 10:
          return translate.fractions.tenths(2);
      }
    })();

    const statements = options.map((value, i) => {
      const [currentWhole, currentNumerator, currentDenominator] = value;

      return {
        sentence:
          // Prevent fractions where the numerator and denominator are showing - just include these as part of the whole:
          currentNumerator === currentDenominator
            ? (currentWhole + 1).toLocaleString()
            : currentNumerator === 0
            ? currentDenominator === denominator
              ? currentWhole.toLocaleString()
              : // Need to force the numerator to be '1' in this particular instance so we don't have duplicate selectables:
                `<frac w='${currentWhole.toLocaleString()}' n='1' d='${
                  // In some cases, the denominator can sometimes be 1, which we never want to use
                  // - in this case, force this incorrect denominator to instead be correctDenominator + 1
                  // (this is the only item to ever have an incorrect denominator).
                  currentDenominator === 1 ? denominator + 1 : currentDenominator
                }' />`
            : currentWhole === 0
            ? `<frac n='${currentNumerator}' d='${currentDenominator}' />`
            : `<frac w='${currentWhole.toLocaleString()}' n='${currentNumerator}' d='${currentDenominator}' />`,
        value: `${i}`,
        correct: arraysHaveSameContents(value, numberArray[answerIndex])
      };
    });

    // Filter correct answers
    const correctAnswer = statements.filter(it => it.correct);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          order === 'backwards'
            ? translate.instructions.characterIsCountingBackwardsInXMissingFractionSelectTheMissingFraction(
                name,
                vocab
              )
            : translate.instructions.characterIsCountingForwardsInXMissingFractionSelectTheMissingFraction(
                name,
                vocab
              )
        }
        pdfTitle={
          order === 'backwards'
            ? translate.instructions.characterIsCountingBackwardsInXMissingFractionCircleTheMissingFraction(
                name,
                vocab
              )
            : translate.instructions.characterIsCountingForwardsInXMissingFractionCircleTheMissingFraction(
                name,
                vocab
              )
        }
        testCorrect={correctAnswer.map(it => it.value)}
        numItems={4}
        Content={
          <View style={{ flexDirection: 'row', gap: 20 }}>
            {displayFractions.map((val, i) => {
              const sentence =
                i + startingIndex === answerIndex
                  ? '?'
                  : val[1] === 0
                  ? val[0].toLocaleString()
                  : val[0] === 0
                  ? `<frac n='${val[1]}' d='${val[2]}' />`
                  : `<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${val[2]}' />`;
              return (
                <ContentBox
                  key={i}
                  containerStyle={{
                    width: displayMode === 'digital' ? 120 : 150,
                    justifyContent: 'center'
                  }}
                >
                  <TextStructure
                    sentence={sentence}
                    textVariant="WRN400"
                    fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                  />
                </ContentBox>
              );
            })}
          </View>
        }
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: (
            <TextStructure
              sentence={sentence}
              textVariant="WRN700"
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
            />
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aMf',
  description: 'aMf',
  keywords: ['Number track', 'Counting forwards', 'Counting backwards', 'Fraction', 'Mixed number'],
  schema: z.object({
    denominator: z.number().int().min(3).max(10),
    startingNumerator: z.number().min(1).max(50),
    step: z.number().int().min(1).max(3),
    isIncreasing: z.boolean()
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);
    const isIncreasing = getRandomBoolean();
    const step = randomIntegerInclusive(1, 3);
    const min = isIncreasing ? denominator - 2 : Math.max(denominator - 2, 5 * step);
    const startingNumerator = randomIntegerInclusive(min, denominator * 5);

    return { denominator, startingNumerator, step, isIncreasing };
  },
  Component: props => {
    const {
      question: { denominator, startingNumerator, step, isIncreasing },
      translate,
      displayMode
    } = props;

    const numberArray = countRange(6).map(i =>
      isIncreasing
        ? improperFractionToMixedNumber(startingNumerator + i * step, denominator, false)
        : improperFractionToMixedNumber(startingNumerator - i * step, denominator, false)
    );
    const boxValues = countRange(6).map(i =>
      i < 2
        ? numberArray[i][1] === 0
          ? numberArray[i][0].toLocaleString()
          : numberArray[i][0] === 0
          ? `<frac n='${numberArray[i][1]}' d='${numberArray[i][2]}' />`
          : `<frac w='${numberArray[i][0].toLocaleString()}' n='${numberArray[i][1]}' d='${
              numberArray[i][2]
            }' />`
        : `<ans/>`
    );

    const items = numberArray
      .filter((_x, i) => i > 1)
      .map((val, idx) => {
        return {
          value: idx,
          component: (
            <TextStructure
              sentence={
                val[1] === 0
                  ? val[0].toLocaleString()
                  : val[0] === 0
                  ? `<frac n='${val[1]}' d='${val[2]}' />`
                  : `<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${val[2]}' />`
              }
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            />
          )
        };
      });
    const shuffledItems = shuffle(items, { random: seededRandom(props.question) });

    const correctOrder = items.map(it => it.value);

    return (
      <QF14aCompleteNumberTrackDraggable
        title={translate.instructions.dragCardsCompleteNumberTrack()}
        pdfTitle={translate.instructions.useCardsCompleteNumberTrack()}
        testCorrect={correctOrder}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        fractionDividerStyle={{ marginVertical: 2 }}
        boxValues={boxValues}
        items={shuffledItems}
        pdfItemVariant="pdfSquare"
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aMf2',
  description: 'aMf',
  keywords: ['Counting forwards', 'Counting backwards', 'Fraction', 'Mixed number'],
  schema: z.object({
    denominator: z.number().int().min(3).max(10),
    startingNumerator: z.number().min(1).max(50),
    step: z.number().int().min(1).max(3),
    isIncreasing: z.boolean()
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);
    const isIncreasing = getRandomBoolean();
    const step = randomIntegerInclusive(1, 3);
    const min = isIncreasing ? denominator - 2 : Math.max(denominator - 2, 5 * step);
    const startingNumerator = randomIntegerInclusive(min, denominator * 5);

    return { denominator, startingNumerator, step, isIncreasing };
  },
  Component: props => {
    const {
      question: { denominator, startingNumerator, step, isIncreasing },
      translate,
      displayMode
    } = props;

    const numberArray = countRange(6).map(i =>
      isIncreasing
        ? improperFractionToMixedNumber(startingNumerator + i * step, denominator, false)
        : improperFractionToMixedNumber(startingNumerator - i * step, denominator, false)
    );
    const boxValues = countRange(6).map(i =>
      i < 2
        ? numberArray[i][1] === 0
          ? numberArray[i][0].toLocaleString()
          : numberArray[i][0] === 0
          ? `<frac n='${numberArray[i][1]}' d='${numberArray[i][2]}' />`
          : `<frac w='${numberArray[i][0].toLocaleString()}' n='${numberArray[i][1]}' d='${
              numberArray[i][2]
            }' />`
        : `<ans/>`
    );

    const items = numberArray
      .filter((_x, i) => i > 1)
      .map((val, idx) => {
        return {
          value: idx,
          component: (
            <TextStructure
              sentence={
                val[1] === 0
                  ? val[0].toLocaleString()
                  : val[0] === 0
                  ? `<frac n='${val[1]}' d='${val[2]}' />`
                  : `<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${val[2]}' />`
              }
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          )
        };
      });
    const shuffledItems = shuffle(items, { random: seededRandom(props.question) });

    const correctOrder = items.map(it => it.value);

    const sentence = boxValues.join(' ,  ');

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsCompleteSequence()}
        pdfTitle={translate.instructions.useCardsCompleteSequence()}
        sentence={sentence}
        items={shuffledItems.map(el => ({
          value: el.value,
          component: el.component
        }))}
        itemsTextVariant="roman"
        moveOrCopy="move"
        pdfLayout="itemsTop"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        pdfItemVariant="pdfSquare"
        testCorrect={correctOrder}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aMg',
  description: 'aMg',
  keywords: ['Number track', 'Counting forwards', 'Counting backwards', 'Fraction', 'Mixed number'],
  schema: z.object({
    denominator: z.number().int().min(3).max(10),
    startingNumerator: z.number().min(1).max(50),
    step: z.number().int().min(1).max(3),
    isIncreasing: z.boolean()
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);
    const isIncreasing = getRandomBoolean();
    const step = randomIntegerInclusive(1, 3);
    const min = isIncreasing ? denominator - 2 : Math.max(denominator - 2, 5 * step);
    const startingNumerator = randomIntegerInclusive(min, denominator * 5);

    return { denominator, startingNumerator, step, isIncreasing };
  },
  Component: props => {
    const {
      question: { denominator, startingNumerator, step, isIncreasing },
      translate
    } = props;

    const numberArray = countRange(6).map(i =>
      isIncreasing
        ? improperFractionToMixedNumber(startingNumerator + i * step, denominator, false)
        : improperFractionToMixedNumber(startingNumerator - i * step, denominator, false)
    );

    const wholeIndex = 0;
    const numeratorIndex = 1;
    const denominatorIndex = 2;

    const answerIndexes: number[] = [];
    const boxValues = countRange(6).map(i => {
      // first two values are shown as text
      if (i < 2) {
        // if numerator is 0 then just show integer
        if (numberArray[i][numeratorIndex] === 0) {
          return numberArray[i][wholeIndex].toLocaleString();
        }
        // if whole is 0 then just show fraction without whole
        else if (numberArray[i][wholeIndex] === 0) {
          return `<frac n='${numberArray[i][numeratorIndex].toLocaleString()}' d='${numberArray[i][
            denominatorIndex
          ].toLocaleString()}' />`;
        }
        // else show mixed fraction
        else {
          return `<frac w='${numberArray[i][wholeIndex].toLocaleString()}' n='${numberArray[i][
            numeratorIndex
          ].toLocaleString()}' d='${numberArray[i][denominatorIndex].toLocaleString()}' />`;
        }
      } else {
        // if numerator is 0 then give one answer box for integer
        if (numberArray[i][numeratorIndex] === 0) {
          answerIndexes.push(1);
          return `<ans/>`;
        }
        // if whole is 0 then give fractions without whole
        else if (numberArray[i][wholeIndex] === 0) {
          answerIndexes.push(2);
          return `<frac nAns='' dAns='' />`;
        } else {
          answerIndexes.push(2);
          return `<frac w='${numberArray[i][wholeIndex].toLocaleString()}' nAns='' dAns='' />`;
        }
      }
    });

    const answers = numberArray
      .filter((_x, i) => i > 1)
      .map(val => (val[1] === 0 ? [val[0]] : [val[1], val[2]]))
      .flat(1);

    function checkAnswers(userAnswer: string[]) {
      let ansIndex = -1;
      const answerIsCorrect = [];
      // always have 4 answer boxes
      for (let answerCount = 0; answerCount < 4; answerCount++) {
        // if the answer is just an integer then we want to simply check equality
        if (answerIndexes[answerCount] === 1) {
          ansIndex++;
          const i = ansIndex;
          answerIsCorrect.push(userAnswer[i] === answers[i].toString());
          // else we want to compare fractions
        } else {
          ansIndex += 2;
          const i = ansIndex;
          answerIsCorrect.push(
            compareFractions([userAnswer[i - 1], userAnswer[i]], [answers[i - 1], answers[i]])
          );
        }
      }
      // all must match i.e. be true
      return answerIsCorrect.every(i => i === true);
    }

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        testCorrect={userAnswer => checkAnswers(userAnswer)}
        textStyle={{ fontSize: 40 }}
        boxValues={boxValues}
        isWholeAnswerBox={false}
        customMarkSchemeAnswer={{
          answerToDisplay: answers.map(i => i.toLocaleString()),
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aMh',
  description: 'aMh',
  keywords: ['Counting forwards', 'Counting backwards', 'Fraction', 'Mixed number'],
  schema: z.object({
    denominator: z.number().int().min(5).max(10),
    startingNumber: z.number().min(1).max(8),
    step: z.number().int().min(2).max(3),
    isIncreasing: z.boolean(),
    answerIndexes: z.array(z.number().min(0).max(5)),
    incorrectAnswerIndexes: z.array(z.number().min(0).max(3)),
    name: nameSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 10);
    const isIncreasing = getRandomBoolean();
    const step = randomIntegerInclusive(2, 3);
    const startingNumber = randomIntegerInclusive(isIncreasing ? 1 : 2, 8);
    const numberOfCorrect =
      step === 3 && !isIncreasing && startingNumber === 1
        ? randomIntegerInclusive(2, 3)
        : randomIntegerInclusive(2, 4);
    const answerIndexes = randomUniqueIntegersInclusive(0, 5, numberOfCorrect);
    const incorrectAnswerIndexes = randomUniqueIntegersInclusive(0, 3, 6 - numberOfCorrect);
    const name = getRandomName();

    return {
      denominator,
      startingNumber,
      step,
      isIncreasing,
      answerIndexes,
      incorrectAnswerIndexes,
      name
    };
  },
  Component: props => {
    const {
      question: {
        denominator,
        startingNumber,
        step,
        isIncreasing,
        answerIndexes,
        incorrectAnswerIndexes,
        name
      },
      translate
    } = props;

    const correctOptionValues = countRange(6)
      .map(i =>
        isIncreasing
          ? improperFractionToMixedNumber(
              startingNumber * denominator + (i + 1) * step,
              denominator,
              false
            )
          : improperFractionToMixedNumber(
              startingNumber * denominator - (i + 1) * step,
              denominator,
              false
            )
      )
      .filter((_x, idx) => answerIndexes.includes(idx))
      .map((val, i) => {
        return {
          sentence:
            val[1] === 0
              ? val[0].toLocaleString()
              : val[0] === 0
              ? `<frac n='${val[1]}' d='${val[2]}' />`
              : `<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${val[2]}' />`,
          value: `correct_${i}`
        };
      });

    const incorrectOptionValues = [
      isIncreasing
        ? improperFractionToMixedNumber(startingNumber * denominator - step, denominator, false)
        : improperFractionToMixedNumber(startingNumber * denominator + step, denominator, false),
      isIncreasing
        ? improperFractionToMixedNumber(
            startingNumber * denominator + (answerIndexes[0] + 1) * step + 1,
            denominator,
            false
          )
        : improperFractionToMixedNumber(
            startingNumber * denominator - (answerIndexes[0] + 1) * step + 1,
            denominator,
            false
          ),
      isIncreasing
        ? improperFractionToMixedNumber(
            startingNumber * (denominator + step) + step,
            denominator + step,
            false
          )
        : improperFractionToMixedNumber(
            startingNumber * (denominator + step) - step,
            denominator + step,
            false
          ),
      isIncreasing
        ? improperFractionToMixedNumber(
            startingNumber * (denominator - step) + step,
            denominator - step,
            false
          )
        : improperFractionToMixedNumber(
            startingNumber * (denominator - step) - step,
            denominator - step,
            false
          )
    ]
      .filter((_x, idx) => incorrectAnswerIndexes.includes(idx))
      .map((val, i) => {
        return {
          sentence:
            val[1] === 0
              ? val[0].toLocaleString()
              : val[0] === 0
              ? `<frac n='${val[1]}' d='${val[2]}' />`
              : `<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${val[2]}' />`,
          value: `${i}`
        };
      });

    const options = [...correctOptionValues, ...incorrectOptionValues].map(val => ({
      value: val.value,
      component: (
        <TextStructure
          sentence={val.sentence}
          fractionTextStyle={{ fontSize: 32, fontWeight: '700' }}
          textStyle={{ fontSize: 32, fontWeight: '700' }}
        />
      )
    }));

    return (
      <QF10SelectNumbers
        title={
          isIncreasing
            ? translate.instructions.characterIsCountingForwardsInXFromYSelectNumbers(
                name,
                `<frac n='${step}' d='${denominator}' />`,
                startingNumber.toLocaleString()
              )
            : translate.instructions.characterIsCountingBackwardsInXFromYSelectNumbers(
                name,
                `<frac n='${step}' d='${denominator}' />`,
                startingNumber.toLocaleString()
              )
        }
        pdfTitle={
          isIncreasing
            ? translate.instructions.characterIsCountingForwardsInXFromYCircleNumbers(
                name,
                `<frac n='${step}' d='${denominator}' />`,
                startingNumber.toLocaleString()
              )
            : translate.instructions.characterIsCountingBackwardsInXFromYCircleNumbers(
                name,
                `<frac n='${step}' d='${denominator}' />`,
                startingNumber.toLocaleString()
              )
        }
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(
            userAnswer,
            countRange(answerIndexes.length).map(i => `correct_${i}`)
          )
        }
        multiSelect
        items={shuffle(options, { random: seededRandom(props.question) })}
        customMarkSchemeAnswer={{
          answerToDisplay: correctOptionValues.map(option => option.value.toLocaleString())
        }}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountBeyond1',
  questionTypes: [Question1, Question2, Question3v3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4, Question3, Question3v2]
});
export default SmallStep;
