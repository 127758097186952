import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { colors } from '../../theme/colors';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
};

const ContentBox = ({ children, containerStyle }: Props) => {
  return <View style={[styles.box, containerStyle]}>{children}</View>;
};

const styles = StyleSheet.create({
  box: {
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    backgroundColor: colors.yellowContentBox,
    rowGap: 20
  }
});

export default ContentBox;
