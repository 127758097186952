import z from 'zod';

import { type SvgName } from '../assets/svg';
import { throwError } from './flowControl';

export const quadrilateralColors = ['Purple', 'Pink', 'Blue', 'Yellow', 'Green'] as const;
export const quadrilateralColorsSchema = z.enum(quadrilateralColors);
export type QuadrilateralColors = (typeof quadrilateralColors)[number];

export const quadrilateralShapes = [
  'Parallelogram',
  'Rectangle',
  'Rectangle2Arrows',
  'Rectangle4Arrows',
  'Square4Arrows',
  'Rhombus',
  'Square',
  'Trapezium',
  'Trapezium4Arrows',
  'Kite',
  'RectangleInteriorAngles'
] as const;
export const QuadrilateralShapesSchema = z.enum(quadrilateralShapes);
export type QuadrilateralShapes = (typeof quadrilateralShapes)[number];

/**
 * Object contain all the names of SVGs related to quadrilateral shapes.
 * They are in order of the quadrilateralColors above as we use this to index later
 */
const quadrilateralAssetNames: Record<QuadrilateralShapes, SvgName[]> = {
  Parallelogram: [
    'Parallelogram/parallelogram_purple',
    'Parallelogram/parallelogram_pink',
    'Parallelogram/parallelogram_blue',
    'Parallelogram/parallelogram_yellow',
    'Parallelogram/parallelogram_green'
  ],
  Rectangle: [
    'Rectangle/rectangle_purple',
    'Rectangle/rectangle_pink',
    'Rectangle/rectangle_blue',
    'Rectangle/rectangle_yellow',
    'Rectangle/rectangle_green'
  ],
  Rectangle2Arrows: [
    'Shapes_with_dimension_arrows/rectangle_purple_arrows_2',
    'Shapes_with_dimension_arrows/rectangle_red_arrows_2',
    'Shapes_with_dimension_arrows/rectangle_blue_arrows_2',
    'Shapes_with_dimension_arrows/rectangle_orange_arrows_2',
    'Shapes_with_dimension_arrows/rectangle_green_arrows_2'
  ],
  Rectangle4Arrows: [
    'Shapes_with_dimension_arrows/rectangle_purple_arrows_4',
    'Shapes_with_dimension_arrows/rectangle_red_arrows_4',
    'Shapes_with_dimension_arrows/rectangle_blue_arrows_4',
    'Shapes_with_dimension_arrows/rectangle_orange_arrows_4',
    'Shapes_with_dimension_arrows/rectangle_green_arrows_4'
  ],
  Rhombus: [
    'Rhombus/rhombus_purple',
    'Rhombus/rhombus_pink',
    'Rhombus/rhombus_blue',
    'Rhombus/rhombus_yellow',
    'Rhombus/rhombus_green'
  ],
  Square: [
    'Square/square_purple',
    'Square/square_pink',
    'Square/square_blue',
    'Square/square_yellow',
    'Square/square_green'
  ],
  Trapezium: [
    'Trapezium/trapezium_isosceles_purple',
    'Trapezium/trapezium_isosceles_pink',
    'Trapezium/trapezium_isosceles_blue',
    'Trapezium/trapezium_isosceles_yellow',
    'Trapezium/trapezium_isosceles_green'
  ],
  Trapezium4Arrows: [
    'Shapes_with_dimension_arrows/trapezium_isosceles_blue_arrows',
    'Shapes_with_dimension_arrows/trapezium_isosceles_green_arrows',
    'Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows',
    'Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows',
    'Shapes_with_dimension_arrows/trapezium_isosceles_white_arrows',
    'Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows'
  ],
  Kite: [
    'Kite/kite_purple',
    'Kite/kite_pink',
    'Kite/kite_blue',
    'Kite/kite_yellow',
    'Kite/kite_green'
  ],
  RectangleInteriorAngles: [
    'Rectangle/Rectangle_angles_length_arrows_purple',
    'Rectangle/Rectangle_angles_length_arrows_pink',
    'Rectangle/Rectangle_angles_length_arrows_blue',
    'Rectangle/Rectangle_angles_length_arrows_yellow',
    'Rectangle/Rectangle_angles_length_arrows_green'
  ],
  Square4Arrows: [
    'Shapes_with_dimension_arrows/Square_purple_arrows_4',
    'Shapes_with_dimension_arrows/Square_red_arrows_4',
    'Shapes_with_dimension_arrows/Square_blue_arrows_4',
    'Shapes_with_dimension_arrows/Square_orange_arrows_4',
    'Shapes_with_dimension_arrows/Square_green_arrows_4'
  ]
};

type Props = {
  /**
   * Width of the svg
   * */
  adjustedWidth: number;
  /**
   * Height of the svg
   * */
  adjustedHeight: number;
  containerWidth: number;
  shape: QuadrilateralShapes;
  isTrailingText: boolean;
  displayMode: 'digital' | 'pdf' | 'markscheme';
  rotate?: number;
};

/**
 * Get position of shape edge labels
 * It has been built to be dynamic based on the size of the shape
 */
export const quadrilateralLabelPositionOffsets = ({
  adjustedWidth,
  adjustedHeight,
  containerWidth,
  shape,
  isTrailingText,
  displayMode,
  rotate = 0
}: Props) => {
  const ANS_BOX_EXTRA_SPACING = 20;
  let ansStyle;
  let labelStyle;
  let smallLabelStyle;
  const answerBoxWidth = displayMode === 'digital' ? 96 : 200;
  const answerBoxHeight = displayMode === 'digital' ? 96 : 150;
  const answerPadding = 10;
  const rotationPadding = Math.abs(rotate) === 90 ? (displayMode === 'digital' ? 10 : 40) : 0;

  const labelPadding = displayMode === 'digital' ? 50 : 70;

  const rightAnsOffset = isTrailingText ? 50 : 0;
  const svgCenter = adjustedWidth / 2;
  const containerCenter = containerWidth / 2;

  switch (shape) {
    case 'Parallelogram': {
      labelStyle = [
        { top: -labelPadding, left: containerCenter - 15 },
        { left: containerCenter + svgCenter },
        { bottom: -labelPadding, right: containerCenter - 15 },
        { right: containerCenter + svgCenter }
      ];
      ansStyle = [
        {
          top: -answerBoxHeight - answerPadding,
          left: containerCenter - 20
        },
        { left: containerCenter + svgCenter + answerPadding },
        {
          bottom: -answerBoxHeight - answerPadding,
          left: containerCenter - answerBoxWidth + 20
        },
        // ANS_BOX_EXTRA_SPACING to accomodate 4 answer boxes
        { right: containerCenter + svgCenter + answerPadding + ANS_BOX_EXTRA_SPACING }
      ];
      break;
    }
    case 'Rhombus': {
      labelStyle = [
        { top: -labelPadding, left: containerCenter - 20 },
        { left: containerCenter + svgCenter },
        { bottom: -labelPadding, right: containerCenter - 20 },
        { right: containerCenter + svgCenter }
      ];
      ansStyle = [
        {
          top: -answerBoxHeight - answerPadding,
          left: containerCenter - 40
        },
        { left: containerCenter + svgCenter + answerPadding },
        {
          bottom: -answerBoxHeight - answerPadding,
          right: containerCenter - 40
        },
        // ANS_BOX_EXTRA_SPACING to accomodate 4 answer boxes
        { right: containerCenter + svgCenter + answerPadding + ANS_BOX_EXTRA_SPACING }
      ];
      break;
    }
    case 'Kite': {
      labelStyle = [
        { top: -20, left: containerCenter + adjustedWidth * 0.25 + 10 },
        { top: adjustedHeight * 0.6, left: containerCenter + adjustedWidth * 0.25 + 10 },
        { top: adjustedHeight * 0.6, right: containerCenter + adjustedWidth * 0.25 + 10 },
        { top: -20, right: containerCenter + adjustedWidth * 0.25 + 10 }
      ];
      ansStyle = [
        {
          top: -answerBoxHeight / 2,
          left: containerCenter + adjustedWidth * 0.25 + 2 * answerPadding
        },
        {
          top: adjustedHeight * 0.68 + answerPadding,
          left: containerCenter + adjustedWidth * 0.25 + 2 * answerPadding
        },
        {
          top: adjustedHeight * 0.68 + answerPadding,
          right: containerCenter + adjustedWidth * 0.25 + answerPadding + rightAnsOffset
        },
        {
          top: -answerBoxHeight / 2,
          right: containerCenter + adjustedWidth * 0.25 + answerPadding + rightAnsOffset
        }
      ];
      break;
    }
    default: {
      labelStyle = [
        { top: -labelPadding - rotationPadding },
        { left: containerCenter + svgCenter - rotationPadding },
        { bottom: -labelPadding - rotationPadding },
        { right: containerCenter + svgCenter - rotationPadding }
      ];
      ansStyle = [
        { top: -answerBoxHeight - answerPadding },
        {
          // Conditional styles to accomodate 4 answer boxes
          left:
            shape !== 'Rectangle' && shape !== 'Square'
              ? containerCenter + svgCenter
              : containerCenter + svgCenter + answerPadding
        },
        { bottom: -answerBoxHeight - answerPadding },
        {
          right:
            // Conditional styles to accomodate 4 answer boxes
            shape === 'Trapezium'
              ? containerCenter + svgCenter - rotationPadding + 30
              : containerCenter +
                svgCenter -
                rotationPadding +
                (displayMode === 'digital' ? 42 : 60)
        }
      ];
      break;
    }
  }

  return { labelStyle, ansStyle, smallLabelStyle };
};

/**
 * Get quadrilateral SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this.
 */
export const getQuadrilateralSvgName = (
  shape: QuadrilateralShapes,
  colour: QuadrilateralColors
): SvgName => {
  const index = quadrilateralColors.indexOf(colour);
  return (
    quadrilateralAssetNames[shape][index] ??
    throwError(`Couldn't find getQuadrilateral image: ${shape} ${colour}`)
  );
};
