import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { sumNumberArray } from '../../../../utils/collections';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ShapeNames } from '../../../../utils/labelPositions';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { rectilinearWithSideRatios } from '../../../../utils/shapeImages/polygons';
import {
  getRandomRectilinearLShape,
  LShapeNameSchema,
  LShapeProperties
} from '../../../../utils/rectilinearShapes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aLO',
  description: 'aLO',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    answerIndex: z.number(),
    answerValue: z.number(),
    shape: z.object({ name: z.string(), sideLengths: z.array(z.number()) })
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const baseValue = randomIntegerInclusive(1, 5);
    const shapeToUse = {
      name: 'Rectilinear_shape1_all_arrows',
      sideLengths: rectilinearWithSideRatios['Rectilinear_shape1_all_arrows'].map(
        x => x * baseValue
      )
    };

    const answerIndex = randomIntegerInclusive(0, shapeToUse.sideLengths.length - 1);
    const answerValue = shapeToUse.sideLengths[answerIndex];

    return { answerIndex, shape: shapeToUse, answerValue };
  },

  Component: props => {
    const {
      question: { answerIndex, shape, answerValue },
      translate
    } = props;

    const labels = shape.sideLengths;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMissingLength()}
        testCorrect={[answerValue.toString()]}
        sentence={`? = ${translate.answerSentences.ansCm()}`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        pdfDirection="column"
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shape.name as ShapeNames}
            labels={labels.map((el, i) =>
              i === answerIndex ? '?' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aLO2',
  description: 'aLO',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    sideIndexToHide: z.number(),
    shape: z.object({ name: z.string(), sideLengths: z.array(z.number()) })
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const baseValue = randomIntegerInclusive(1, 5);
    const shape = {
      name: 'Rectilinear_shape1_all_arrows',
      sideLengths: rectilinearWithSideRatios['Rectilinear_shape1_all_arrows'].map(
        x => x * baseValue
      )
    };

    const sideIndexToHide = getRandomFromArray([0, 5]);

    return { sideIndexToHide, shape };
  },

  Component: props => {
    const {
      question: { sideIndexToHide, shape },
      translate
    } = props;

    const labels = shape.sideLengths;
    const answer = sumNumberArray(labels);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansCm()}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shape.name as ShapeNames}
            labels={labels.map((el, i) =>
              i === sideIndexToHide ? '' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aLP',
  description: 'aLP',
  keywords: ['Missing length', 'Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    sideIndexToHide: z.number(),
    shape: z.object({ name: z.string(), sideLengths: z.array(z.number()) })
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const baseValue = randomIntegerInclusive(1, 5);
    const shapeToUse = {
      name: 'Rectilinear_shape1_all_arrows',
      sideLengths: rectilinearWithSideRatios['Rectilinear_shape1_all_arrows'].map(
        x => x * baseValue
      )
    };

    const sideIndexToHide = randomIntegerInclusive(0, shapeToUse.sideLengths.length - 1);

    return { sideIndexToHide, shape: shapeToUse };
  },
  Component: props => {
    const {
      question: { sideIndexToHide, shape },
      translate
    } = props;

    const labels = shape.sideLengths;
    const answer = sumNumberArray(labels);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansCm()}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shape.name as ShapeNames}
            labels={labels.map((el, i) =>
              i === sideIndexToHide ? '' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aLP2',
  description: 'aLP',
  keywords: ['Missing length', 'Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    sideIndexToHide: z.number(),
    shape: z.object({ name: z.string(), sideLengths: z.array(z.number()) })
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const baseValue = randomIntegerInclusive(1, 5);
    const shape = {
      name: 'Rectilinear_shape1_all_arrows',
      sideLengths: rectilinearWithSideRatios['Rectilinear_shape1_all_arrows'].map(
        x => x * baseValue
      )
    };

    const sideIndexToHide = getRandomFromArray([1, 2, 3, 4]);

    return { sideIndexToHide, shape };
  },
  Component: props => {
    const {
      question: { sideIndexToHide, shape },
      translate
    } = props;

    const labels = shape.sideLengths;
    const answer = sumNumberArray(labels);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansCm()}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shape.name as ShapeNames}
            labels={labels.map((el, i) =>
              i === sideIndexToHide ? '' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aLQ',
  description: 'aLQ',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    missingIndices: z.array(z.number().int().min(0).max(5)).length(2),
    sideLengths: z.array(z.number().int().min(0).max(20)).length(6),
    shapeName: LShapeNameSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shapeName = getRandomRectilinearLShape();

    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[shapeName];

    const sideLengthsRatio = getRandomFromArray(sideLengthRatioOptions) as number[];
    const baseValue = Math.max(...sideLengthsRatio) <= 10 ? randomIntegerInclusive(1, 2) : 1;
    const sideLengths = sideLengthsRatio.map(x => x * baseValue);

    // Get a missing index from different sides
    const missingIdx1 = getRandomFromArray([oppLSides[0][0], ...oppLSides[0][1]]);
    const missingIdx2 = getRandomFromArray([oppLSides[1][0], ...oppLSides[1][1]]);

    return { missingIndices: [missingIdx1, missingIdx2], shapeName, sideLengths };
  },

  Component: props => {
    const {
      question: { missingIndices, shapeName, sideLengths },
      translate
    } = props;

    const answerValues = [
      [sideLengths[missingIndices[0]].toString()],
      [sideLengths[missingIndices[1]].toString()]
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutTheMissingLength()}
        testCorrect={answerValues}
        sentences={[
          `${translate.letters.A()} = ${translate.answerSentences.ansCm()}`,
          `${translate.letters.B()} = ${translate.answerSentences.ansCm()}`
        ]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
            shapeName={shapeName as ShapeNames}
            labels={sideLengths.map((el, i) =>
              missingIndices.includes(i)
                ? translate.letters[['A', 'B'][missingIndices.indexOf(i)] as 'A' | 'B']()
                : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aLR',
  description: 'aLR',
  keywords: ['Missing length', 'Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    missingIndices: z.array(z.number().int().min(0).max(5)).length(2),
    sideLengths: z.array(z.number().int().min(0).max(20)).length(6),
    shapeName: LShapeNameSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const shapeName = getRandomRectilinearLShape();

    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[shapeName];
    const sideLengthsRatio = getRandomFromArray(sideLengthRatioOptions) as number[];
    const baseValue = Math.max(...sideLengthsRatio) <= 10 ? randomIntegerInclusive(1, 2) : 1;
    const sideLengths = sideLengthsRatio.map(x => x * baseValue);

    // Get a missing index from different sides
    const missingIdx1 = getRandomFromArray([oppLSides[0][0], ...oppLSides[0][1]]);
    const missingIdx2 = getRandomFromArray([oppLSides[1][0], ...oppLSides[1][1]]);

    return { missingIndices: [missingIdx1, missingIdx2], shapeName, sideLengths };
  },

  Component: props => {
    const {
      question: { missingIndices, shapeName, sideLengths },
      translate
    } = props;

    const perimeter = sumNumberArray(sideLengths);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        testCorrect={[perimeter.toString()]}
        sentence={translate.answerSentences.ansCm()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
        pdfDirection="column"
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName={shapeName}
            labels={sideLengths.map((el, i) =>
              missingIndices.includes(i) ? '' : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aLS',
  description: 'aLS',
  keywords: ['Missing length', 'Rectilinear', 'Shape', 'Perimeter'],
  schema: z.object({
    sideLengths: z.array(z.number()),
    perimeter: z.number().int()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const baseValue = randomIntegerInclusive(1, 3);

    const sideLengths = [10, 7, 4, 3, 3].map(x => x * baseValue);

    const perimeter = sumNumberArray([3, 3, 1, 3, 3, 10].map(x => x * baseValue)) * 2;

    return { sideLengths, perimeter };
  },

  Component: props => {
    const {
      question: { sideLengths, perimeter },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutPerimeterOfShape()}
        testCorrect={[perimeter.toString()]}
        sentence={translate.answerSentences.ansM()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        pdfDirection="column"
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height, width: dimens.width }}
            shapeName="Compound_shape4_arrows"
            labels={sideLengths.map(el => translate.units.numberOfM(el))}
            seed={props.question}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aLT',
  description: 'aLT',
  keywords: ['Missing length', 'Rectilinear', 'Shape'],
  schema: z.object({
    missingIndices: z.array(z.number().int().min(0).max(5)).length(2),
    sideLengths: z.array(z.number().int().min(0).max(20)).length(6),
    shapeName: LShapeNameSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shapeName = getRandomRectilinearLShape();
    const { sideLengthRatioOptions, oppLSides } = LShapeProperties[shapeName];

    const sideLengthsRatio = getRandomFromArray(sideLengthRatioOptions) as number[];
    const baseValue = Math.max(...sideLengthsRatio) <= 10 ? randomIntegerInclusive(1, 2) : 1;
    const sideLengths = sideLengthsRatio.map(x => x * baseValue);

    const missingSides = getRandomFromArray(oppLSides) as [number, number[]];

    const missingIdx1 = missingSides[0];

    const missingIdx2 = getRandomFromArray(missingSides[1]) as number;

    return { missingIndices: [missingIdx1, missingIdx2], shapeName, sideLengths };
  },

  Component: props => {
    const {
      question: { missingIndices, shapeName, sideLengths },
      translate
    } = props;

    const perimeter = sumNumberArray(sideLengths);
    const answerValues = [
      [sideLengths[missingIndices[0]].toString()],
      [sideLengths[missingIndices[1]].toString()]
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.thePerimeterOfRectilinearShapeIsXWorkOutUnkownLengths(
          perimeter
        )}
        testCorrect={answerValues}
        sentences={[
          `${translate.letters.A()} = ${translate.answerSentences.ansCm()}`,
          `${translate.letters.B()} = ${translate.answerSentences.ansCm()}`
        ]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={1000}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
            shapeName={shapeName}
            labels={sideLengths.map((el, i) =>
              missingIndices.includes(i)
                ? translate.letters[['A', 'B'][missingIndices.indexOf(i)] as 'A' | 'B']()
                : translate.units.numberOfCm(el)
            )}
            seed={props.question}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculateThePerimeterOfRectilinearShapes',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
