import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import Text from '../../typography/Text';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import { Path, Svg } from 'react-native-svg';
import { getActualDimens } from '../../../utils/getActualDimens';

type Props = {
  /**
   * @param labels An array of strings to hold the labels that go around the shape
   * Should be given in form [left, bottom, right]
   */
  labels: string[];
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  centerLabel?: string;
  showEqualSidesLines?: boolean;
  showArrow?: boolean;
  assetSvgName?: SvgName;
  /**
   * default: false
   * Ensures when using marked lines we only show 2
   */
  isIsosceles?: boolean;
  /**
   * Prop to determine whether to use the larger or smaller font size for the labels.
   * Optional prop, defaults to 'small'.
   */
  labelFontSize?: 'large' | 'small';
};
/**
 * This component renders a triangle with labels on the sides.
 * Works best with symmetrical triangles.
 */
export const LabelledTriangle = (props: Props) => {
  const {
    labels,
    dimens: { width, height },
    centerLabel,
    showEqualSidesLines,
    showArrow,
    assetSvgName,
    isIsosceles = false,
    labelFontSize = 'small'
  } = props;
  const displayMode = useContext(DisplayMode);

  const shapeWidth = width - 64;
  const shapeHeight = height - 64;

  const styles = useStyles(width, height, displayMode);
  const labelWidth = displayMode === 'digital' ? 52 : 75;
  const font = displayMode === 'digital' ? 32 : 40;
  const smallFont = displayMode === 'digital' ? 22 : 32;
  const largeFont = displayMode === 'digital' ? 32 : 40;
  const { actualHeight, actualWidth } = getActualDimens(assetSvgName ?? 'Triangle', {
    width: shapeWidth,
    height: shapeHeight
  });

  const widthDiff = (width - actualWidth) * 0.5;
  const heightDiff = (height - actualHeight) * 0.5;

  // get labels for shapes and absolute positions
  const shapeLabels = () => {
    const containerCenter = width * 0.5;

    const style = [
      // Left label
      {
        right: widthDiff + actualWidth * 0.8,
        bottom: heightDiff + actualHeight * 0.5
      },
      // Bottom label
      { top: heightDiff + actualHeight, left: containerCenter - labelWidth * 0.5 },
      // Right label
      {
        left: widthDiff + actualWidth * 0.8,
        bottom: heightDiff + actualHeight * 0.5
      }
    ];
    return labels.map((_label, index) => {
      return (
        <View
          key={index}
          style={[style[index], { position: 'absolute', minWidth: labelWidth, zIndex: 2 }]}
        >
          <Text
            variant="WRN700"
            style={{
              // Override this if labelFontSize is 'large', otherwise use default smallFont.
              fontSize: labelFontSize === 'large' ? largeFont : smallFont,
              textAlign: 'center'
            }}
          >
            {labels[index]}
          </Text>
        </View>
      );
    });
  };

  const sideArrow = () => {
    const arrowSvg =
      displayMode === 'digital' ? (
        <Svg width={20} height={actualWidth}>
          <Path d={'M10,0 L5,10 L15,10 Z'} fill={colors.prussianBlue} />
          <Path d={`M10,5 L10,${actualWidth - 5}`} stroke={colors.prussianBlue} strokeWidth={2} />
          <Path
            d={`M10,${actualWidth}
              L5,${actualWidth - 10}
              L15,${actualWidth - 10}
              Z`}
            fill={colors.prussianBlue}
          />
        </Svg>
      ) : (
        <Svg width={40} height={actualWidth}>
          <Path d={'M10,0 L0,20 L20,20 Z'} fill={colors.black} />
          <Path d={`M10,5 L10,${actualWidth - 5}`} stroke={colors.black} strokeWidth={4} />
          <Path
            d={`M10,${actualWidth}
              L0,${actualWidth - 20}
              L20,${actualWidth - 20}
              Z`}
            fill={colors.black}
          />
        </Svg>
      );

    const arrowPosition = {
      right:
        displayMode === 'digital' ? widthDiff + actualWidth * 0.82 : widthDiff + actualWidth * 0.73
    };

    return (
      <View
        style={[
          displayMode === 'digital' ? styles.arrowContainer : styles.arrowContainerPDF,
          arrowPosition
        ]}
      >
        {arrowSvg}
      </View>
    );
  };

  const equilateralLines = () => {
    const array = [
      <View
        key="rightLine"
        style={[
          styles.line,
          styles.horizontalLine,
          {
            right: widthDiff + actualWidth * 0.66,
            top: heightDiff + actualHeight * 0.5,

            transform: [{ rotate: '30deg' }]
          }
        ]}
      />,
      <View
        key="leftLine"
        style={[
          styles.line,
          styles.horizontalLine,
          {
            left: widthDiff + actualWidth * 0.66,
            top: heightDiff + actualHeight * 0.5,
            transform: [{ rotate: '150deg' }]
          }
        ]}
      />
    ];
    if (!isIsosceles)
      array.push(
        <View
          key="bottomLine"
          style={[styles.line, styles.verticalLine, { bottom: heightDiff * 0.5 }]}
        />
      );
    return array;
  };
  return (
    <View style={[styles.imageWrapper]}>
      {shapeLabels()}
      {showArrow && sideArrow()}
      <View style={{ position: 'absolute', zIndex: 1 }}>
        {centerLabel && (
          <Text variant="WRN700" style={{ fontSize: font, color: colors.white }}>
            {centerLabel}
          </Text>
        )}
      </View>
      {showEqualSidesLines && equilateralLines()}
      <AssetSvg
        name={(assetSvgName ?? 'Triangle') as SvgName}
        width={shapeWidth}
        height={shapeHeight}
      />
    </View>
  );
};

const useStyles = (
  width: number,
  height: number,
  displayMode: 'digital' | 'pdf' | 'markscheme'
) => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageWrapper: {
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          width,
          height
        },
        verticalLine: {
          height: displayMode === 'digital' ? 30 : 50,
          width: displayMode === 'digital' ? 2 : 4
        },
        horizontalLine: {
          width: displayMode === 'digital' ? 30 : 50,
          height: displayMode === 'digital' ? 2 : 4
        },
        line: {
          position: 'absolute',
          zIndex: 2,
          backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black
        },
        arrowContainer: {
          zIndex: 2,
          borderColor: colors.prussianBlue,
          position: 'absolute',
          transform: [{ rotate: '30deg' }]
        },
        arrowContainerPDF: {
          borderColor: colors.black,
          alignItems: 'center',
          position: 'absolute',
          transform: [{ rotate: '30deg' }]
        }
      }),
    [width, height, displayMode]
  );
};
