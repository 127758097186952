import { useMemo, useContext, useRef, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue
} from 'react-native-reanimated';
import Svg, { Line } from 'react-native-svg';
import { ScaleFactorContext } from '../../../../theme/scaling';
import { withStateHOC } from '../../../../stateTree';
import { colors } from '../../../../theme/colors';
import scaleNumberLineFontSize from '../../../typography/scaleNumberLineFont';
import TextStructure from '../../../molecules/TextStructure';
import { parseMarkup } from '../../../../markup';
import { DisplayMode } from '../../../../contexts/displayMode';
import { AssetSvg } from '../../../../assets/svg';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

type NumberLineWithSliderProps = {
  /** The minimum value of the slider. This is the left most value. */
  min: number;
  /** The maximum value of the slider. This is the right most value. */
  max: number;
  /** The interval that the slider will snap to when dragged. */
  sliderStep?: number;
  /** The numeric value of the slider */
  sliderValue?: number;
  /** An array of strings to hold the values that go on the number line. */
  tickValues: string[];
  /** This representation supports scaling with width, but not height. */
  width: number;
  valueOnChange: (value: number) => void;
  /** Custom Font Size if you want to manually override the scaling */
  customFontSize?: number;
};

/**
 * A component that displays a number line with a draggable slider
 */
export const NumberLineWithSlider = ({
  min,
  max,
  valueOnChange,
  sliderStep = 1,
  sliderValue = min,
  tickValues,
  width,
  customFontSize
}: NumberLineWithSliderProps) => {
  const scaleFactor = useContext(ScaleFactorContext);
  const displayMode = useContext(DisplayMode);

  const tickValuesParsed = tickValues.map(tick => parseMarkup(tick));
  const showFractions = tickValuesParsed.some(tick => tick.tokens.some(el => el.type === 'frac'));

  const height = showFractions ? 290 : 220;
  const arrowWidth = displayMode === 'digital' ? 26 : 52;
  const arrowHeight = displayMode === 'digital' ? 111 : 222;
  const textVerticalPad = 10;
  const horizontalPad = 40;
  const tickHeight = 40;
  const lineWidth = width - 2 * horizontalPad;
  const centerLineY = 140;
  const tickSpacing = lineWidth / (tickValues.length - 1);
  const startingTickX = horizontalPad;
  const numberOfSteps = (max - min) / sliderStep;
  const stepSize = lineWidth / numberOfSteps;

  const styles = useMemo(() => getStyles(width, height), [width, height]);

  const fontSize = customFontSize ?? scaleNumberLineFontSize(tickValues, lineWidth, displayMode);

  // Helper worklets for translating between values and pixel-offset.
  // Value ranges between min and max, with step size of sliderStep.
  // Offset ranges between 0 and lineWidth, with step size of stepSize.
  // These offsets are unscaled, e.g. if the scale factor is 2, and the view thinks it's 100px (lineWidth) wide but
  // it's actually 200px wide after scaling, this will range from 0 to 100.
  const valueToOffset = useCallback(
    (value: number) => {
      'worklet';
      return ((value - min) / sliderStep) * stepSize;
    },
    [min, sliderStep, stepSize]
  );
  const offsetToValue = useCallback(
    (offset: number) => {
      'worklet';
      return (offset * (max - min)) / lineWidth + min;
    },
    [lineWidth, max, min]
  );

  // animation values
  const animatedSliderValue = useSharedValue(sliderValue);

  // If 'sliderValue' changes from outside, update the animated value
  // Note: this raises a warning in react-native-reanimated, so we may want to think about doing this a different way
  const lastSliderValue = useRef(sliderValue);
  if (lastSliderValue.current !== sliderValue) {
    lastSliderValue.current = sliderValue;
    animatedSliderValue.value = sliderValue;
  }

  // derived animation values
  const animatedSliderOffset = useDerivedValue(
    () => valueToOffset(animatedSliderValue.value),
    [animatedSliderValue, valueToOffset]
  );
  const sliderTranslateXStyle = useAnimatedStyle(
    () => ({ transform: [{ translateX: animatedSliderOffset.value - arrowWidth }] }),
    [animatedSliderOffset, arrowWidth]
  );

  ////
  // Gesture
  ////

  /** Used to calculate translationX since we don't trust that value from the event. (Scaled.) */
  const beginAbsX = useSharedValue<number | null>(null);
  /** Remembers the value of `animatedSliderOffset` in `onBegin` (null when no gesture is in progress). (Unscaled.) */
  const beginSliderOffset = useSharedValue<number | null>(null);

  /** Gesture to detect dragging left and right on the arrow. Updates `animatedSliderValue`, and snaps at the end. */
  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .runOnJS(true)
        .onBegin(event => {
          beginAbsX.value = event.absoluteX;

          beginSliderOffset.value = animatedSliderOffset.value;
        })
        .onUpdate(event => {
          // Calculate translationX manually rather than using the one from the event, since the one from the event
          // is the diff since the second onUpdate, which is wrong because the pointer might have already moved before
          // then!
          const translationX = (event.absoluteX - beginAbsX.value!) / scaleFactor;

          let totalOffset = beginSliderOffset.value! + translationX;

          // Clamp to left and right edges
          totalOffset = Math.max(totalOffset, 0);
          totalOffset = Math.min(totalOffset, lineWidth);
          animatedSliderValue.value = offsetToValue(totalOffset);
        })
        .onFinalize(() => {
          // Snap to the nearest step
          const newValue = number(
            math.evaluate(`${Math.round(animatedSliderValue.value / sliderStep)} * ${sliderStep}`)
          );

          // Set both in the local animated state for instant update, and also inform the owner of the react state
          animatedSliderValue.value = newValue;
          runOnJS(valueOnChange)(newValue);
        }),
    [
      animatedSliderOffset,
      animatedSliderValue,
      beginAbsX,
      beginSliderOffset,
      lineWidth,
      offsetToValue,
      scaleFactor,
      sliderStep,
      valueOnChange
    ]
  );

  /* Draw the numberline */
  // Center line
  const centerLine = (
    <Line
      x1={horizontalPad}
      y1={centerLineY}
      x2={width - horizontalPad}
      y2={centerLineY}
      stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
      strokeWidth={displayMode === 'digital' ? 2 : 4}
      key={'CenterLine'}
    />
  );

  // Ticks
  const ticks = tickValues.map((_, index) => {
    const tickX = startingTickX + index * tickSpacing;
    return (
      <Line
        x1={tickX}
        y1={centerLineY - tickHeight / 2}
        x2={tickX}
        y2={centerLineY + tickHeight / 2}
        stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
        strokeWidth={displayMode === 'digital' ? 2 : 4}
        key={'tick' + index}
      />
    );
  });

  // Numbers
  const numberComponents = tickValues.map((tick, index) => {
    const tickX = startingTickX + index * tickSpacing;

    return (
      <View
        key={'tickNum' + index}
        style={[
          styles.labelContainer,
          {
            left: tickX - 60,
            top: centerLineY + tickHeight / 2 + textVerticalPad
          }
        ]}
      >
        <TextStructure
          sentence={tick}
          textStyle={[styles.text, { fontSize }]}
          fractionContainerStyle={{ height: displayMode === 'digital' ? 48 : 56 }}
        />
      </View>
    );
  });

  return (
    <Animated.View style={[styles.container]}>
      <Svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={styles.svg}
        pointerEvents={'none'}
      >
        {centerLine}
        {ticks}
      </Svg>
      {numberComponents}
      {displayMode !== 'pdf' && (
        <View
          style={[
            styles.sliderContainer,
            {
              width: lineWidth + arrowWidth * 2,
              height: arrowHeight,
              left: horizontalPad
            }
          ]}
        >
          <GestureDetector gesture={panGesture}>
            <Animated.View
              style={[
                {
                  alignItems: 'center',
                  position: 'absolute',
                  width: arrowWidth * 2,
                  top: centerLineY - arrowHeight
                },
                sliderTranslateXStyle
              ]}
            >
              <AssetSvg
                name="SliderArrowCustomizable"
                height={arrowHeight}
                width={displayMode === 'digital' ? 26 : 52}
                svgProps={{ fill: colors.burntSienna }}
              />
            </Animated.View>
          </GestureDetector>
        </View>
      )}
    </Animated.View>
  );
};

/** See {@link NumberLineWithSlider}. */
export const NumberLineWithSliderWithState = withStateHOC(NumberLineWithSlider, {
  stateProp: 'sliderValue',
  setStateProp: 'valueOnChange'
});

const getStyles = (width: number, height: number) =>
  StyleSheet.create({
    container: {
      width: width,
      height: height
    },
    labelContainer: {
      position: 'absolute',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 120
    },
    text: {
      flex: 1,
      textAlign: 'center',
      overflow: 'visible'
    },
    svg: {
      position: 'absolute',
      top: 0,
      left: 0
    },
    sliderContainer: {
      top: -28,
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center'
    }
  });
