import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { findExchanges, numbersDoNotExchange } from '../../../../utils/exchanges';
import ColumnOperations from '../../../../components/question/representations/ColumnOperations/ColumnOperations';
import { useMemo } from 'react';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { ADD } from '../../../../constants';
import {
  arraysHaveSameContentsUnordered,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ah6',
  description: 'ah6',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z
        .number()
        .int()
        .min(1001)
        .max(9999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10'),
      var2: z
        .number()
        .int()
        .min(1001)
        .max(9999)
        .refine(x => x % 10 !== 0, 'cannot be a multiple of 10')
    })
    .refine(
      val => findExchanges(val.var1, val.var2).length > 1 && val.var1 + val.var2 < 10_000,
      'There must be one exchange and the total is less than 10,000'
    ),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        const var1 = randomIntegerInclusive(1001, 9999, { constraint: x => x % 10 !== 0 });
        const var2 = randomIntegerInclusive(1001, 9999, { constraint: x => x % 10 !== 0 });
        return { var1, var2 };
      },
      // Only permit them if they have more than one exchange
      ({ var1, var2 }) => findExchanges(var1, var2).length > 1 && var1 + var2 < 10_000
    );
    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: getMarkSchemeAnswer(number3, answerMissingDigits.length) },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ah7',
  description: 'ah7',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      var1: z.number().int().min(1000).max(9988),
      var2: z.number().int().min(11).max(999)
    })
    .refine(val => findExchanges(val.var1, val.var2).length > 1, 'must be more than 1 exchange')
    .refine(val => val.var1 + val.var2 < 10_000, 'total should be less than 10,000'),
  simpleGenerator: () => {
    const { var1, var2 } = rejectionSample(
      () => {
        // Probably want to weight these to have 50:50 chance, rather than being more likely to select a 3-digit number from 11 - 999:
        const var2TwoOrThreeDigits = randomIntegerInclusive(2, 3);

        const [var2Min, var2Max] = var2TwoOrThreeDigits === 2 ? [11, 99] : [101, 999];

        const var2 = randomIntegerInclusive(var2Min, var2Max);

        const var1 = randomIntegerInclusive(1000, 9999 - var2);

        return { var1, var2 };
      },
      // Only permit them if they do not exchange and total is less than 10,000.
      ({ var1, var2 }) => var1 + var2 < 10_000 && findExchanges(var1, var2).length > 1
    );

    return {
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2 }, translate }) => {
    const number3 = var1 + var2;
    const answerMissingDigits = range(0, number3.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnAddition()}
        topNumber={var1}
        bottomNumber={var2}
        operation={ADD}
        answerNumber={number3}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: getMarkSchemeAnswer(number3, answerMissingDigits.length) },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'ah8',
  description: 'ah8',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(1000).max(8999),
      numberA2: z.number().int().min(1000).max(8999),
      numberB1: z.number().int().min(1000).max(8999),
      numberB2: z.number().int().min(1000).max(8999),
      numberC1: z.number().int().min(1000).max(8999),
      numberC2: z.number().int().min(1000).max(8999),
      numberD1: z.number().int().min(1000).max(8999),
      numberD2: z.number().int().min(1000).max(8999)
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val =>
        findExchanges(val.numberB1, val.numberB2).length === 1 &&
        val.numberB1 + val.numberB2 < 10000,
      'numberB1 + numberB2 must have one exchange and total less than 10,000'
    )
    .refine(
      val =>
        findExchanges(val.numberC1, val.numberC2).length === 2 &&
        val.numberC1 + val.numberC2 < 10000,
      'numberC1 + numberC2 must have two exchanges and total less than 10,000'
    )
    .refine(
      val =>
        findExchanges(val.numberD1, val.numberD2).length === 3 &&
        val.numberD1 + val.numberD2 < 10000,
      'numberD1 + numberD2 must have three exchanges and total less than 10,000'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberA1 = randomIntegerInclusive(1000, 8999);
        const numberA2 = randomIntegerInclusive(1000, 9999 - numberA1);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => numbersDoNotExchange(numberA1, numberA2)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberB1 = randomIntegerInclusive(1000, 8999);
        const numberB2 = randomIntegerInclusive(1000, 9999 - numberB1);
        return { numberB1, numberB2 };
      },
      // Only permit them if they have exactly one exchange.
      ({ numberB1, numberB2 }) => findExchanges(numberB1, numberB2).length === 1
    );

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberC1 = randomIntegerInclusive(1000, 8999);
        const numberC2 = randomIntegerInclusive(1000, 9999 - numberC1);
        return { numberC1, numberC2 };
      },
      // Only permit them if they have exactly two exchanges.
      ({ numberC1, numberC2 }) => findExchanges(numberC1, numberC2).length === 2
    );

    const { numberD1, numberD2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberD1 = randomIntegerInclusive(1000, 8999);
        const numberD2 = randomIntegerInclusive(1000, 9999 - numberD1);
        return { numberD1, numberD2 };
      },
      // Only permit them if they have exactly three exchanges.
      ({ numberD1, numberD2 }) => findExchanges(numberD1, numberD2).length === 3
    );

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1, bottomNumber: numberB2, isCorrect: false };
      const eqC = { topNumber: numberC1, bottomNumber: numberC2, isCorrect: true };
      const eqD = { topNumber: numberD1, bottomNumber: numberD2, isCorrect: true };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheAdditionsWhichWillNeedMoreThanOneExchange()}
        pdfTitle={translate.instructions.circleTheAdditionsWhichWillNeedMoreThanOneExchange()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={ADD}
                dimens={{ width: dimens.width, height: dimens.height * 0.9 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ah9',
  description: 'ah9',
  keywords: ['Addition', 'Exchange', 'Thousands', 'Hundreds', 'Tens', 'Ones'],
  schema: z
    .object({
      number1: z.number().int().min(1001).max(9999),
      number2: z.number().int().min(11).max(9999),
      nameA: nameSchema,
      nameB: nameSchema,
      nameAHasNumber1: z.boolean()
    })
    .refine(val => val.nameA !== val.nameB, 'nameA and nameB must be different.'),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(1001, 9999);

        const number2Digits = randomIntegerInclusive(2, 4);

        const [number2Min, number2Max] =
          number2Digits === 2 ? [11, 99] : number2Digits === 3 ? [101, 999] : [1001, 9999];

        const number2 = randomIntegerInclusive(number2Min, number2Max);
        return { number1, number2 };
      },
      // Only permit them if they have more than 1 exchange.
      ({ number1, number2 }) =>
        findExchanges(number1, number2).length > 1 && number1 + number2 < 10000
    );

    const [nameA, nameB] = getRandomUniqueNames(2);

    const nameAHasNumber1 = getRandomBoolean();

    return { number1, number2, nameA, nameB, nameAHasNumber1 };
  },

  Component: props => {
    const {
      question: { number1, number2, nameA, nameB, nameAHasNumber1 },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.char1AndChar2ArePlayingAGameChar1EarnsNumPoints(
          nameA,
          nameB,
          nameAHasNumber1 ? number1 : number2,
          nameAHasNumber1 ? number2 : number1
        )}
        testCorrect={[(number1 + number2).toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

const Question5 = newQuestionContent({
  uid: 'aia',
  description: 'aia',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z.object({
    additions: z
      .object({
        exchanges: numberEnum([0, 1, 2, 3]),
        leftAddend: z.number().int().min(1000).max(9999),
        rightAddend: z.number().int().min(1000).max(9999)
      })
      .refine(
        ({ leftAddend, rightAddend }) => leftAddend + rightAddend <= 9999,
        'Each pair of numbers must sum to < 1,000'
      )
      .refine(
        ({ exchanges, leftAddend, rightAddend }) =>
          findExchanges(leftAddend, rightAddend).length === exchanges,
        'The given number of exchanges was not correct for this sum'
      )
      .array()
      .length(4)
      .refine(
        val =>
          arraysHaveSameContentsUnordered(
            val.map(it => it.exchanges),
            [0, 1, 2, 3]
          ),
        'There must be a sum for each number of exchanges from: 0, 1, 2 and 3'
      )
  }),
  simpleGenerator: () => {
    const additionWithNExchanges = (n: 0 | 1 | 2 | 3) =>
      rejectionSample(
        () => {
          const leftAddend = randomIntegerInclusive(1000, 8999);
          const rightAddend = randomIntegerInclusive(1000, 9999 - leftAddend);
          return { leftAddend, rightAddend, exchanges: n };
        },
        ({ leftAddend, rightAddend, exchanges }) =>
          findExchanges(leftAddend, rightAddend).length === exchanges
      );
    const additions = shuffle([
      additionWithNExchanges(0),
      additionWithNExchanges(1),
      additionWithNExchanges(2),
      additionWithNExchanges(3)
    ]);
    return { additions };
  },
  Component: ({ question: { additions }, translate }) => (
    <QF37SentencesDrag
      title={translate.instructions.dragCalculationsToNumberExchanges()}
      pdfTitle={translate.instructions.useCardsAdditionsToNumberExchanges()}
      items={sortNumberArray(additions.map(it => it.exchanges)).map(exchanges => ({
        component: translate.misc.numberOfExchanges(exchanges),
        value: exchanges
      }))}
      actionPanelVariant="endWide"
      itemVariant="rectangle"
      sentenceStyle={{ alignSelf: 'flex-end' }}
      sentencesStyle={{ alignSelf: 'center', rowGap: 6 }}
      sentences={additions.map(
        ({ leftAddend, rightAddend }) =>
          `${leftAddend.toLocaleString()} ${ADD} ${rightAddend.toLocaleString()} ${translate.misc.has()} <ans/>`
      )}
      testCorrect={additions.map(({ exchanges }) => [exchanges])}
      pdfLayout="itemsRight"
      pdfItemVariant="tallRectangle"
      itemMaxLines={1}
      questionHeight={1000}
    />
  ),
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aib',
  description: 'aib',
  keywords: ['Addition', 'Column', 'Exchange'],
  schema: z
    .object({
      topNumber: z.number().int().min(1000).max(8999),
      bottomNumber: z.number().int().min(1000).max(8999),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer']),
      missingThousands: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => findExchanges(val.topNumber, val.bottomNumber).length > 1,
      'topNumber and bottomNumber must have more than one exchange.'
    )
    .refine(
      val => val.topNumber + val.bottomNumber < 10000,
      'topNumber + bottomNumber must be less than 10,000'
    ),
  simpleGenerator: () => {
    const { topNumber, bottomNumber } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping topNumber uniformly distributed
        const topNumber = randomIntegerInclusive(1000, 8999);
        const bottomNumber = randomIntegerInclusive(1000, 9999 - topNumber);
        return { topNumber, bottomNumber };
      },
      // Only permit them if they have more than 1 exchange.
      ({ topNumber, bottomNumber }) => findExchanges(topNumber, bottomNumber).length > 1
    );

    const numberWithExtraMissingDigit = getRandomFromArray(['top', 'bottom', 'answer'] as const);

    const [missingOnes, missingTens, missingHundreds, missingThousands] = shuffle([
      'top',
      'bottom',
      'answer',
      numberWithExtraMissingDigit
    ] as const);

    return { topNumber, bottomNumber, missingOnes, missingTens, missingHundreds, missingThousands };
  },

  Component: props => {
    const {
      question: {
        topNumber,
        bottomNumber,
        missingOnes,
        missingTens,
        missingHundreds,
        missingThousands
      },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    );
    const answerNumber = topNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={ADD}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwo4DigitNumbersMoreThanOneExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
