import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { View } from 'react-native';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import Text from '../../../../components/typography/Text';
import TextStructure from '../../../../components/molecules/TextStructure';
import { NumberLineTicksTopBottomInputWithState } from '../../../../components/question/representations/Number Line/NumberLineTicksTopBottom';
import { countRange, range } from '../../../../utils/collections';
import {
  compareFractions,
  convertMixedNumberToWhole,
  fractionToDecimal
} from '../../../../utils/fractions';
import { numberEnum } from '../../../../utils/zod';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import QF17dSelectTheNumberLine from '../../../../components/question/questionFormats/QF17dSelectTheNumberLine';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMS',
  description: 'aMS',
  keywords: [
    'Double number line',
    'Fraction',
    'Mixed number',
    'Integer',
    'Numerator',
    'Denominator',
    'Equivalent'
  ],
  schema: z.object({
    whole: z.number().int().min(1).max(9),
    denominator1: z.number().int().min(2).max(6),
    denominator2: z.number().int().min(4).max(18),
    multiplier: z.number().int().min(2).max(3),
    answerIndex: z.number().int().min(1).max(6),
    answerTopOrBottom: z.enum(['top', 'bottom'])
  }),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 9);
    const denominator1 = randomIntegerInclusive(2, 6);
    // make sure we have 6 intervals to avoid improper fractions
    const multiplier = denominator1 === 2 ? 3 : randomIntegerInclusive(2, 3);
    const denominator2 = denominator1 * multiplier;

    const answerIndex = randomIntegerInclusiveStep(multiplier, 6, multiplier);
    const answerTopOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return {
      whole,
      denominator1,
      denominator2,
      multiplier,
      answerIndex,
      answerTopOrBottom
    };
  },
  Component: props => {
    const {
      question: { whole, denominator1, denominator2, multiplier, answerIndex, answerTopOrBottom },
      translate,
      displayMode
    } = props;

    const selectableValuesSmall = range(multiplier, 6, multiplier);
    const selectableValuesLarge = countRange(6, 1);

    const selectables = [selectableValuesSmall, selectableValuesLarge];

    const labelsValuesSmall = countRange(7).map(val =>
      val === 0
        ? whole.toLocaleString()
        : selectableValuesSmall.includes(val)
        ? `<frac w='${whole.toLocaleString()}' n='${val / multiplier}' d='${denominator1}' />`
        : ''
    );
    const labelsValuesLarge = countRange(7).map(val =>
      val === 0
        ? whole.toLocaleString()
        : `<frac w='${whole.toLocaleString()}' n='${val}' d='${denominator2}' />`
    );

    const labels = [labelsValuesSmall, labelsValuesLarge];

    const ticks = shuffle(
      countRange(2).map(val => ({ selectables: selectables[val], label: labels[val] })),
      { random: seededRandom(props.question) }
    );

    const tickValues = ticks.map(val => val.label);
    const selectableIndexes = ticks.map(val => val.selectables);

    const answer = answerTopOrBottom === 'top' ? [[answerIndex], []] : [[], [answerIndex]];
    const fraction =
      answerTopOrBottom === 'top' ? tickValues[1][answerIndex] : tickValues[0][answerIndex];

    if (answerTopOrBottom === 'top') {
      selectableIndexes[1] = [];
    } else {
      selectableIndexes[0] = [];
    }

    return (
      <QF17dSelectTheNumberLine
        title={translate.instructions.selectTheFractionEquivalentToX(fraction)}
        pdfTitle={translate.instructions.circleTheFractionEquivalentToX(fraction)}
        testCorrect={answer}
        selectableIndexes={selectableIndexes}
        tickValues={tickValues}
        questionHeight={1000}
        customFontSize={displayMode === 'digital' ? 32 : 50}
      />
    );
  },
  questionHeight: 1000
});

const Question1V2 = newQuestionContent({
  uid: 'aMS2',
  description: 'aMS',
  keywords: [
    'Double number line',
    'Fraction',
    'Mixed number',
    'Integer',
    'Numerator',
    'Denominator',
    'Equivalent'
  ],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(1),
    denominator1: z.number().int().min(2).max(5),
    answerIndex: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 1);
    const denominator1 = randomIntegerInclusive(2, 5);
    const answerIndex = randomIntegerInclusive(1, denominator1 - 1);

    return {
      startingNumber,
      denominator1,
      answerIndex
    };
  },
  Component: props => {
    const {
      question: { startingNumber, denominator1, answerIndex },
      translate,
      displayMode
    } = props;

    const topNumerators = countRange(denominator1 + 1).map(i =>
      i === 0
        ? startingNumber
        : i === denominator1
        ? startingNumber + 1
        : startingNumber * denominator1 + i
    );

    const answer = topNumerators[answerIndex] * 2;

    const ticksA = countRange(denominator1 + 1).map(i =>
      i === 0
        ? startingNumber.toLocaleString()
        : i === denominator1
        ? (startingNumber + 1).toLocaleString()
        : `<frac n='${topNumerators[i]}' d='${denominator1}' />`
    );

    const ticksB = countRange(denominator1 * 2 + 1).map(i =>
      i === 0
        ? startingNumber.toLocaleString()
        : i === denominator1 * 2
        ? (startingNumber + 1).toLocaleString()
        : `<frac n='${startingNumber * (denominator1 * 2) + i}' d='${denominator1 * 2}' />`
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useNumberLineToCompleteEquivFractions()}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.fracIsEquivalentTo(
          topNumerators[answerIndex],
          denominator1,
          denominator1 * 2
        )}
        pdfDirection="column"
        questionHeight={900}
        fractionContainerStyle={{ height: 96 }}
        Content={({ dimens }) => (
          <View style={{ top: -10 }}>
            <NumberLineTicksTopBottomInputWithState
              topTickValues={ticksA.map(val => val.toLocaleString())}
              bottomTickValues={ticksB.map(val => val.toLocaleString())}
              dimens={dimens}
              customFontSize={displayMode === 'digital' ? 28 : undefined}
              id={'numberLine'}
            />
          </View>
        )}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aMT',
  description: 'aMT',
  keywords: [
    'Double number line',
    'Fraction',
    'Mixed number',
    'Integer',
    'Numerator',
    'Denominator',
    'Equivalent'
  ],
  schema: z.object({
    startingNumber: z.number().int().min(1).max(9),
    denominatorA: z.number().int().min(4).max(6),
    bottowRowMultiplierIs2: z.boolean(),
    correctAnsIdx: z.number().int(),
    randomIncorrectIndexes: z.array(z.boolean()).length(3)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 9);
    const denominatorA = randomIntegerInclusive(4, 6);
    const bottowRowMultiplierIs2 = getRandomBoolean();
    const correctAnsIdx = bottowRowMultiplierIs2
      ? getRandomFromArray([1, 2, 3])
      : getRandomFromArray([1, 2]);
    const randomIncorrectIndexes = countRange(3).map(() => getRandomBoolean());

    return {
      startingNumber,
      denominatorA,
      bottowRowMultiplierIs2,
      correctAnsIdx,
      randomIncorrectIndexes
    };
  },

  Component: props => {
    const {
      question: {
        startingNumber,
        denominatorA,
        bottowRowMultiplierIs2,
        correctAnsIdx,
        randomIncorrectIndexes
      },
      translate
    } = props;

    const multiplier = bottowRowMultiplierIs2 ? 2 : 3;
    const denominatorB = denominatorA * multiplier;

    // Items
    const correctItem = convertMixedNumberToWhole(startingNumber, correctAnsIdx, denominatorA);

    // Randomise +/- and prevent 0 wholes
    const incorrectA = convertMixedNumberToWhole(
      randomIncorrectIndexes[0] && startingNumber !== 1 ? startingNumber - 1 : startingNumber + 1,
      correctAnsIdx,
      denominatorA
    );

    // Randomise +/- and prevent 0 numerators
    const incorrectB = convertMixedNumberToWhole(
      startingNumber,
      randomIncorrectIndexes[1] && correctAnsIdx !== 1 ? correctAnsIdx - 1 : correctAnsIdx + 1,
      denominatorA
    );

    const incorrectC = convertMixedNumberToWhole(
      startingNumber + 1,
      correctAnsIdx,
      randomIncorrectIndexes[2] ? denominatorA - 1 : denominatorA + 1
    );

    const statements = shuffle(
      [
        {
          component: correctItem,
          value: 'A'
        },
        {
          component: incorrectA,
          value: 'B'
        },
        {
          component: incorrectB,
          value: 'C'
        },
        {
          component: incorrectC,
          value: 'D'
        }
      ],
      { random: seededRandom(props.question) }
    );

    const [fractionTopA, fractionTopB, fractionTopC] = countRange(
      bottowRowMultiplierIs2 ? 3 : 2
    ).map(idx => convertMixedNumberToWhole(startingNumber, idx + 1, denominatorA));

    const fractionsBottom = countRange(6).map(idx =>
      convertMixedNumberToWhole(startingNumber, idx + 1, denominatorB)
    );

    const [correctAnswer1, correctAnswer2] = countRange(2).map(idx => {
      const fraction = idx === 0 ? fractionTopA : fractionTopB;
      return correctAnsIdx === idx + 1
        ? ''
        : fraction.length > 1
        ? `<frac w='${fraction[0].toLocaleString()}' n='${fraction[1]}' d='${fraction[2]}' />`
        : fraction[0].toLocaleString();
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheEquivalentFractionTheArrowIsPointingTo()}
        pdfTitle={translate.instructions.circleTheEquivalentFractionTheArrowIsPointingTo()}
        testCorrect={['A']}
        numItems={4}
        itemLayout="row"
        Content={({ dimens }) => (
          <NumberLineTicksTopBottomInputWithState
            id={'numberline'}
            topTickValues={
              bottowRowMultiplierIs2
                ? [
                    `${startingNumber.toLocaleString()}`,
                    '',
                    correctAnswer1,
                    '',
                    correctAnswer2,
                    '',
                    correctAnsIdx === 3
                      ? ''
                      : fractionTopC.length > 1
                      ? `<frac w='${fractionTopC[0].toLocaleString()}' n='${fractionTopC[1]}' d='${
                          fractionTopC[2]
                        }' />`
                      : fractionTopC[0].toLocaleString()
                  ]
                : [`${startingNumber}`, '', '', correctAnswer1, '', '', correctAnswer2]
            }
            bottomTickValues={[
              `${startingNumber}`,
              ...fractionsBottom.map(fraction =>
                fraction.length > 1
                  ? `<frac w='${fraction[0].toLocaleString()}' n='${fraction[1]}' d='${
                      fraction[2]
                    }' />`
                  : fraction.toLocaleString()
              )
            ]}
            arrowIndices={[[correctAnsIdx * multiplier], [null]]}
            dimens={{ height: dimens.height - 100, width: dimens.width }}
          />
        )}
        renderItems={statements.map(({ value, component }) => ({
          value,
          component:
            component.length > 1 ? (
              <TextStructure
                fractionTextStyle={{ fontWeight: '700' }}
                sentence={`<frac w='${component[0].toLocaleString()}' n='${component[1]}' d='${
                  component[2]
                }' />`}
              />
            ) : (
              <Text variant="WRN700">{component[0].toLocaleString()}</Text>
            )
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aMU',
  description: 'aMU',
  keywords: [
    'Double number line',
    'Equivalent fractions',
    'Mixed number',
    'Integer',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    wholeA: z.number().int().min(1).max(3),
    denominatorA: z.number().int().min(2).max(5),
    numeratorA: z.number().int().min(1).max(4),
    correctAnswerWeight: z.boolean(),
    incorrectAnswerWeight: z.boolean()
  }),
  simpleGenerator: () => {
    const wholeA = randomIntegerInclusive(1, 3);
    const denominatorA = randomIntegerInclusive(2, 5);
    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
    const correctAnswerWeight = getRandomBoolean();
    const incorrectAnswerWeight = getRandomBoolean();

    return {
      wholeA,
      denominatorA,
      numeratorA,
      correctAnswerWeight,
      incorrectAnswerWeight
    };
  },
  Component: props => {
    const {
      question: { wholeA, denominatorA, numeratorA, correctAnswerWeight, incorrectAnswerWeight },
      translate
    } = props;

    const wholeB = wholeA * 2;
    const denominatorB = denominatorA * 2;
    const numeratorB = numeratorA * 2;

    const incorrectDenominator = denominatorA + 1;
    const incorrectNumerator = numeratorA + 1;

    const [fracAWhole, fracANumerator, fracADenominator] = [wholeA, numeratorA, denominatorA];

    const [fracBWhole, fracBNumerator, fracBDenominator] = correctAnswerWeight
      ? [wholeA, numeratorB, denominatorB]
      : incorrectAnswerWeight
      ? [wholeA, incorrectNumerator, incorrectDenominator]
      : [wholeB, incorrectNumerator, incorrectDenominator];

    const topTickValues: string[] = [];
    const bottomTickValues: string[] = [];

    const calculateTopTickValues = (
      tickValuesArr: string[],
      whole: number,
      denominator: number
    ) => {
      return countRange(denominator + 1).map(idx => {
        tickValuesArr.push(`${whole + idx}`);

        if (idx + 1 !== denominator + 1) {
          tickValuesArr.push(...countRange(denominator - 1).map(() => ''));
        }

        return tickValuesArr;
      });
    };

    const calculateBottomTickValues = (tickValuesArr: string[], denominator: number) => {
      const multiple = fracBDenominator / fracADenominator;

      const ticks = countRange(fracADenominator * multiple)
        .map(() => {
          return [...countRange(denominator).map(() => '')];
        })
        .flat();

      tickValuesArr.push(...ticks);
      tickValuesArr.push('');
      return tickValuesArr;
    };

    const topTicks = calculateTopTickValues(topTickValues, fracAWhole, fracADenominator);
    const bottomTicks = calculateBottomTickValues(bottomTickValues, fracADenominator);

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.isStatementTrueOrFalse()}
        pdfTitle={translate.instructions.isStatementTrueOrFalsePDF()}
        correctAnswer={compareFractions(
          [fracAWhole, fracANumerator, fracADenominator],
          [fracBWhole, fracBNumerator, fracBDenominator]
        )}
        trueButtonLabel={translate.misc.True()}
        falseButtonLabel={translate.misc.False()}
        content={({ dimens }) => (
          <View style={{ width: dimens.width }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <TextStructure
                sentence={`<frac w='${fracAWhole.toLocaleString()}' n='${fracANumerator}' d='${fracADenominator}' />`}
              />
              <Text
                variant="WRN400"
                style={{
                  paddingLeft: 16,
                  paddingRight: 16
                }}
              >
                {translate.answerSentences.isEqualTo()}
              </Text>
              <TextStructure
                textStyle={{ fontSize: 40 }}
                sentence={`<frac w='${fracBWhole.toLocaleString()}' n='${fracBNumerator}' d='${fracBDenominator}' />`}
              />
            </View>
            <NumberLineTicksTopBottomInputWithState
              id={'numberline'}
              topTickValues={topTicks[0]}
              bottomTickValues={bottomTicks}
              dimens={{ height: dimens.height - 140, width: dimens.width }}
            />
          </View>
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3V2 = newQuestionContent({
  uid: 'aMU2',
  description: 'aMU',
  keywords: ['Number line', 'Fraction', 'Mixed number', 'Numerators', 'Denominators', 'Equivalent'],
  schema: z.object({
    wholeA: z.number().int().min(0).max(8),
    denominatorA: z.number().int().min(2).max(5),
    multiplier: z.number().int().min(2).max(5),
    answerIndex: z.number().int().min(1).max(4),
    incorrectNumerators: z.array(z.number().int().min(1)).length(3)
  }),
  simpleGenerator: () => {
    const wholeA = randomIntegerInclusive(0, 8);
    const denominatorA = randomIntegerInclusive(2, 5);
    const multiplier = randomIntegerInclusive(2, 5, {
      constraint: x => (denominatorA === 3 ? x * denominatorA < 9 : x * denominatorA <= 10)
    });
    const answerIndex = randomIntegerInclusive(1, denominatorA - 1);

    const length =
      denominatorA * multiplier < 5 ? denominatorA * multiplier + 2 : denominatorA * multiplier + 1;
    const incorrectNumerators = getRandomSubArrayFromArray(
      countRange(length, 1).filter(val => val !== answerIndex * multiplier),
      3
    );

    return {
      wholeA,
      denominatorA,
      multiplier,
      answerIndex,
      incorrectNumerators
    };
  },
  Component: props => {
    const {
      question: { wholeA, denominatorA, multiplier, answerIndex, incorrectNumerators },
      translate,
      displayMode
    } = props;

    const denominatorB = denominatorA * multiplier;

    const topTickValues = countRange(denominatorA + 1).map(i =>
      i === 0
        ? wholeA.toLocaleString()
        : i === denominatorA
        ? (wholeA + 1).toLocaleString()
        : wholeA > 0
        ? `<frac w='${wholeA.toLocaleString()}' n='${i}' d='${denominatorA}' />`
        : `<frac n='${i}' d='${denominatorA}' />`
    );

    const bottomTickValues = countRange(denominatorB + 1).map(i =>
      i === 0
        ? wholeA.toLocaleString()
        : i === denominatorB
        ? (wholeA + 1).toLocaleString()
        : i === answerIndex * multiplier
        ? `<ans/>`
        : ''
    );

    const correctOption = [answerIndex * multiplier, denominatorB];
    const allOptions = [correctOption, ...incorrectNumerators.map(val => [val, denominatorB])];

    const options = allOptions.map(([n, d], i) => {
      return {
        component: (
          <TextStructure
            key={i}
            sentence={
              wholeA > 0
                ? `<frac w='${wholeA.toLocaleString()}' n='${n}' d='${d}' />`
                : `<frac n='${n}' d='${d}' />`
            }
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 28 : 40,
              fontWeight: '700'
            }}
          />
        ),
        value: `${n}/${d}`
      };
    });

    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsToCompleteEquivalentFractions()}
        pdfTitle={translate.instructions.useCardsToCompleteEquivalentFractions()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={[`${correctOption[0]}/${correctOption[1]}`]}
        customFontSize={displayMode === 'digital' ? 28 : 40}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aMV',
  description: 'aMV',
  keywords: [
    'Double number line',
    'Improper fraction',
    'Mixed number',
    'Integer',
    'Numerator',
    'Denominator',
    'Equivalent'
  ],
  schema: z.object({
    denominator1: z.number().int().min(2).max(6),
    denominator2: z.number().int().min(4).max(18),
    whole: z.number().int().min(1).max(5),
    multiplier: z.number().int().min(2).max(3),
    answerIndex: z.number().int().min(1).max(6),
    answerTopOrBottom: z.enum(['top', 'bottom'])
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(2, 6);
    const multiplier = randomIntegerInclusive(2, 3);
    const whole = randomIntegerInclusive(1, 5);
    const denominator2 = denominator1 * multiplier;

    const answerIndex = randomIntegerInclusiveStep(multiplier, 6, multiplier);
    const answerTopOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return {
      denominator1,
      denominator2,
      multiplier,
      whole,
      answerIndex,
      answerTopOrBottom
    };
  },
  Component: props => {
    const {
      question: { denominator1, denominator2, multiplier, whole, answerIndex, answerTopOrBottom },
      translate,
      displayMode
    } = props;

    const selectableValuesSmall = range(0, 6, multiplier);
    const selectableValuesLarge = countRange(7);

    const selectables = [selectableValuesSmall, selectableValuesLarge];

    const labelsValuesSmall = countRange(7).map(val =>
      selectableValuesSmall.includes(val)
        ? `<frac n='${whole * denominator1 + val / multiplier}' d='${denominator1}' />`
        : ''
    );
    const labelsValuesLarge = countRange(7).map(
      val => `<frac n='${whole * denominator2 + val}' d='${denominator2}' />`
    );

    const labels = [labelsValuesSmall, labelsValuesLarge];

    const ticks = shuffle(
      countRange(2).map(val => ({ selectables: selectables[val], label: labels[val] })),
      { random: seededRandom(props.question) }
    );

    const tickValues = ticks.map(val => val.label);
    const selectableIndexes = ticks.map(val => val.selectables);

    const answer = answerTopOrBottom === 'top' ? [[answerIndex], []] : [[], [answerIndex]];
    const fraction =
      answerTopOrBottom === 'top' ? tickValues[1][answerIndex] : tickValues[0][answerIndex];

    if (answerTopOrBottom === 'top') {
      selectableIndexes[1] = [];
    } else {
      selectableIndexes[0] = [];
    }

    return (
      <QF17dSelectTheNumberLine
        title={translate.instructions.selectTheFractionEquivalentToX(fraction)}
        testCorrect={answer}
        selectableIndexes={selectableIndexes}
        tickValues={tickValues}
        questionHeight={1000}
        customFontSize={displayMode === 'digital' ? 32 : 50}
      />
    );
  },
  questionHeight: 1000
});

const Question4V2 = newQuestionContent({
  uid: 'aMV2',
  description: 'aMV',
  keywords: ['Number line', 'Fraction', 'Mixed number', 'Numerators', 'Denominators', 'Equivalent'],
  schema: z.object({
    denominatorA: z.number().int().min(2).max(5),
    multiplier: z.number().int().min(2).max(5),
    answerIndex: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 5);
    const multiplier = randomIntegerInclusive(2, 5, {
      constraint: x => (denominatorA === 3 ? x !== 4 : x * denominatorA <= 12)
    });

    const answerIndex = randomIntegerInclusive(1, denominatorA - 1);

    return {
      denominatorA,
      multiplier,
      answerIndex
    };
  },
  Component: props => {
    const {
      question: { denominatorA, multiplier, answerIndex },
      translate,
      displayMode
    } = props;

    const answer = answerIndex * multiplier;
    const denominatorB = denominatorA * multiplier;

    const ticksA = countRange(denominatorA + 1).map(i =>
      i === 0
        ? (0).toLocaleString()
        : i === denominatorA
        ? (1).toLocaleString()
        : `<frac n='${i}' d='${denominatorA}' />`
    );

    const ticksB = countRange(denominatorB + 1).map(i =>
      i === 0 ? (0).toLocaleString() : i === denominatorB ? (1).toLocaleString() : ''
    );

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.completeEquivalentFraction()}<br/>${translate.instructions.useNumberLineToHelp()}`}
        testCorrect={[answer.toString()]}
        sentence={`<frac n='${answerIndex}' d='${denominatorA}' /> = <frac nAns='' d='${denominatorB}' />`}
        fractionContainerStyle={{ height: 96 }}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <NumberLineTicksTopBottomInputWithState
            topTickValues={ticksA.map(val => val.toLocaleString())}
            bottomTickValues={ticksB.map(val => val.toLocaleString())}
            dimens={dimens}
            customFontSize={displayMode === 'digital' ? 28 : undefined}
            id={'numberLine'}
          />
        )}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aMW',
  description: 'aMW',
  keywords: [
    'Double number line',
    'Fraction',
    'Mixed number',
    'Integer',
    'Numerator',
    'Denominator',
    'Equivalent'
  ],
  schema: z.object({
    denominatorA: z.number().int().min(3).max(6),
    whole: z.number().int().min(1).max(9),
    numeratorA: z.number().int().min(1).max(5),
    randomMultiplier: z.boolean(),
    ansVariation: numberEnum([1, 2, 3, 4])
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(3, 6);
    const whole = randomIntegerInclusive(1, 9);
    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
    const randomMultiplier = getRandomBoolean();
    const ansVariation = getRandomFromArray([1, 2, 3, 4] as const);

    return { denominatorA, whole, numeratorA, randomMultiplier, ansVariation };
  },
  Component: props => {
    const {
      question: { denominatorA, whole, numeratorA, randomMultiplier, ansVariation },
      translate
    } = props;

    const multiplier = randomMultiplier ? 2 : 3;
    const denominatorB = denominatorA * multiplier;
    const numeratorB = numeratorA * multiplier;
    const endingNumber = whole + 1;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeTheEquivalentFractions()}
        sentence={
          ansVariation === 1
            ? `<frac w='${whole.toLocaleString()}' n='${numeratorA}' d='${denominatorA}' /> = <frac wAns='' n='${numeratorB}' dAns='' />`
            : ansVariation === 2
            ? `<frac wAns='' n='${numeratorA}' dAns='' /> = <frac w='${whole.toLocaleString()}' n='${numeratorB}' d='${denominatorB}' />`
            : ansVariation === 3
            ? `<frac w='${whole.toLocaleString()}' n='${numeratorA}' d='${denominatorA}' /> = <frac wAns='' nAns='' d='${denominatorB}' />`
            : `<frac wAns='' nAns='' d='${denominatorA}' /> = <frac w='${whole.toLocaleString()}' n='${numeratorB}' d='${denominatorB}' />`
        }
        inputMaxCharacters={1}
        mainPanelStyle={{ alignItems: 'center' }}
        fractionContainerStyle={{ height: 96 }}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              width: dimens.width,
              height: dimens.height
            }}
          >
            <NumberLineTicksTopBottomInputWithState
              id={'numberline'}
              topTickValues={['', ...countRange(denominatorA - 1).map(() => ''), '']}
              bottomTickValues={[
                `${whole.toLocaleString()}`,
                ...countRange(denominatorB).map(idx => {
                  if (idx === denominatorB - 1) {
                    return `${endingNumber.toLocaleString()}`;
                  } else {
                    return '';
                  }
                })
              ]}
              arrowIndices={[[numeratorA], [null]]}
              dimens={{
                height: dimens.height,
                width: dimens.width
              }}
            />
          </View>
        )}
        testCorrect={userAnswer => {
          return ansVariation === 1
            ? compareFractions(
                [userAnswer[0], numeratorB, userAnswer[1]],
                [whole, numeratorB, denominatorB]
              )
            : ansVariation === 2
            ? compareFractions(
                [userAnswer[0], numeratorA, userAnswer[1]],
                [whole, numeratorA, denominatorA]
              )
            : ansVariation === 3
            ? compareFractions(
                [userAnswer[0], userAnswer[1], denominatorB],
                [whole, numeratorB, denominatorB]
              )
            : compareFractions(
                [userAnswer[0], userAnswer[1], denominatorA],
                [whole, numeratorA, denominatorA]
              );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay:
            ansVariation === 1
              ? [whole.toLocaleString(), denominatorB.toLocaleString()]
              : ansVariation === 2
              ? [whole.toLocaleString(), denominatorA.toLocaleString()]
              : ansVariation === 3
              ? [whole.toLocaleString(), numeratorB.toLocaleString()]
              : [whole.toLocaleString(), numeratorA.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

export const Question6 = newQuestionContent({
  uid: 'aMX',
  description: 'aMX',
  keywords: [
    'Double number line',
    'Equivalent fractions',
    'Mixed number',
    'Integer',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    whole: z.number().int().min(1).max(9),
    denominatorA: z.number().int().min(2).max(6),
    denominatorB: z.number().int().min(4).max(18),
    numerator: z.number().int().min(1).max(17)
  }),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 9);
    const denominatorA = randomIntegerInclusive(2, 6);
    const multiplier = getRandomFromArray([2, 3] as const);
    const denominatorB = denominatorA * multiplier;
    const numerator = randomIntegerInclusive(1, denominatorB - 1, {
      constraint: x => x % denominatorA !== 0
    });

    return { whole, denominatorA, denominatorB, numerator };
  },
  Component: props => {
    const {
      question: { whole, denominatorA, denominatorB, numerator },
      translate
    } = props;

    const answer = whole + fractionToDecimal(numerator, denominatorB);

    const denominatorALength = countRange(denominatorA);

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragArrowEstimatePositionOfNumOnNumberLine(
          `<frac w='${whole}' n='${numerator}' d='${denominatorB}' />`
        )}
        pdfTitle={translate.instructions.drawArrowEstimatePositionOfNumOnNumberLine(
          `<frac w='${whole}' n='${numerator}' d='${denominatorB}' />`
        )}
        testCorrect={[answer - 0.05, answer + 0.05]}
        min={whole}
        max={whole + 1}
        sliderStep={0.01}
        tickValues={[
          `${whole}`,
          ...denominatorALength.map(idx => {
            const endNumber = convertMixedNumberToWhole(whole, idx + 1, denominatorA);

            return endNumber.length > 1
              ? `<frac w='${endNumber[0].toLocaleString()}' n='${endNumber[1]}' d='${
                  endNumber[2]
                }' />`
              : endNumber.toLocaleString();
          })
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsOnANumberLine',
  questionTypes: [Question1V2, Question2, Question3V2, Question4V2, Question5, Question6],
  archivedQuestionTypes: [Question1, Question3, Question4]
});
export default SmallStep;
