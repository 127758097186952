import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomNumberInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import {
  createRectangleFromSquares,
  createShapeWithSquares,
  isRectangle,
  isRectilinear,
  missingChunkAndWholeShapePairs,
  trueCount
} from '../../../../utils/shapes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { useMemo } from 'react';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import QF24aCreateShapeAndGivenShape from '../../../../components/question/questionFormats/QF24aCreateShapeAndGivenShape';
import { View } from 'react-native';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getShapeSvgName } from '../../../../utils/shapeImages/shapes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'afi',
  description: 'afi',
  keywords: ['Area'],
  schema: z.object({
    shape: z.enum(['squares', 'circles', 'triangles', 'kites']),
    smallerOrGreater: z.enum(['smaller', 'greater']),
    scale: z.number().min(1.3).max(1.8),
    xYBoth: z.enum(['x', 'y', 'both'])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['squares', 'circles', 'triangles', 'kites'] as const);
    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);
    const scale = randomNumberInclusive(1.3, 1.8, 1);
    const xYBoth = getRandomFromArray(['x', 'y', 'both'] as const);

    return { shape, smallerOrGreater, scale, xYBoth };
  },
  Component: props => {
    const {
      question: { shape, smallerOrGreater, scale, xYBoth },
      translate,
      displayMode
    } = props;

    const shapeSVGName = getShapeSvgName(shape, props.question);

    const normalXScale = 0.3;
    const normalYScale = displayMode === 'digital' ? 0.7 : 0.5;

    const stretchedShape =
      xYBoth === 'x' ? [scale, 1] : xYBoth === 'y' ? [1, scale] : [scale, scale];

    const shapes = shuffle(
      [
        { scale: [1, 1], smallerOrGreater: 'smaller' },
        { scale: stretchedShape, smallerOrGreater: 'greater' }
      ],
      { random: seededRandom(props.question) }
    );

    const greaterOrSmallerTitle =
      smallerOrGreater === 'smaller'
        ? translate.instructions.selectShapeHasSmallerArea()
        : translate.instructions.selectShapeHasGreaterArea();

    const greaterOrSmallerPdfTitle =
      smallerOrGreater === 'smaller'
        ? translate.instructions.circleShapeHasSmallerArea()
        : translate.instructions.circleShapeHasGreaterArea();

    return (
      <QF11SelectImagesUpTo4
        title={greaterOrSmallerTitle}
        pdfTitle={greaterOrSmallerPdfTitle}
        testCorrect={[smallerOrGreater]}
        numItems={2}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.smallerOrGreater,
            component: (
              <View
                style={{
                  transform: [{ scaleX: shape.scale[0] }, { scaleY: shape.scale[1] }]
                }}
              >
                <AssetSvg
                  name={shapeSVGName}
                  width={dimens.width * normalXScale}
                  height={dimens.height * normalYScale}
                />
              </View>
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'afj',
  description: 'afj',
  keywords: ['Area'],
  schema: z.object({
    givenShapeWidth: z.number().int().min(1).max(3),
    givenShapeHeight: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    // Allowable dimensions as set out on the spreadsheet, to ensure a larger rectangle than this can be made.
    const givenShapeWidth = randomIntegerInclusive(1, 3);

    const givenShapeHeight = (() => {
      if (givenShapeWidth === 1) {
        return randomIntegerInclusive(3, 5);
      } else if (givenShapeWidth === 2) {
        return randomIntegerInclusive(2, 4);
      } else {
        return 3;
      }
    })();

    return { givenShapeWidth, givenShapeHeight };
  },
  Component: props => {
    const {
      question: { givenShapeWidth, givenShapeHeight },
      translate
    } = props;
    return (
      <QF24aCreateShapeAndGivenShape
        title={translate.instructions.createRectangleGreaterAreaOnGrid()}
        pdfTitle={translate.instructions.createRectangleGreaterAreaOnGridPdf()}
        givenShape={createRectangleFromSquares(givenShapeWidth, givenShapeHeight)}
        numberOfRows={4}
        numberOfCols={5}
        testCorrect={userAnswer =>
          trueCount(userAnswer) > givenShapeWidth * givenShapeHeight && isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyShapeWithAreaGreaterThanX(
            givenShapeHeight * givenShapeWidth
          )
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'afk',
  description: 'afk',
  keywords: ['Area'],
  schema: z.object({
    givenShapeWidth: z.number().int().min(2).max(4),
    givenShapeHeight: z.number().int().min(3).max(5)
  }),
  simpleGenerator: () => {
    // Allowable dimensions as set out on the spreadsheet, to ensure a smaller rectangle than this can be made.
    const givenShapeWidth = randomIntegerInclusive(2, 4);

    const givenShapeHeight = (() => {
      if (givenShapeWidth === 2) {
        return randomIntegerInclusive(4, 5);
      } else if (givenShapeWidth === 3) {
        return randomIntegerInclusive(3, 5);
      } else {
        return 4;
      }
    })();

    return { givenShapeWidth, givenShapeHeight };
  },
  Component: props => {
    const {
      question: { givenShapeWidth, givenShapeHeight },
      translate
    } = props;
    return (
      <QF24aCreateShapeAndGivenShape
        title={translate.instructions.createRectangleSmallerAreaOnGrid()}
        pdfTitle={translate.instructions.createRectangleSmallerAreaOnGridPdf()}
        givenShape={createRectangleFromSquares(givenShapeWidth, givenShapeHeight)}
        numberOfRows={4}
        numberOfCols={5}
        testCorrect={userAnswer =>
          trueCount(userAnswer) < givenShapeWidth * givenShapeHeight && isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyShapeWithAreaLessThanX(
            givenShapeHeight * givenShapeWidth
          )
        }}
        questionHeight={700}
      />
    );
  },
  questionHeight: 700
});

const Question4 = newQuestionContent({
  uid: 'afl',
  description: 'afl',
  keywords: ['Area'],
  schema: z.object({
    shapePair: z.number().int().min(1).max(4),
    smallerOrGreater: z.enum(['smaller', 'greater'])
  }),
  simpleGenerator: () => {
    const shapePair = randomIntegerInclusive(1, 4);

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { shapePair, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { shapePair, smallerOrGreater },
      translate
    } = props;

    const shapes = useMemo(() => {
      const [normalShapePath, shapeWithChunkMissingPath] =
        missingChunkAndWholeShapePairs[shapePair - 1];

      return shuffle(
        [
          { shapePath: normalShapePath, smallerOrGreater: 'greater' },
          { shapePath: shapeWithChunkMissingPath, smallerOrGreater: 'smaller' }
        ],
        { random: seededRandom(props.question) }
      );
    }, [props.question, shapePair]);

    const smallerOrGreaterTitle =
      smallerOrGreater === 'smaller'
        ? translate.instructions.whichShapeHasSmallerArea()
        : translate.instructions.whichShapeHasGreaterArea();
    return (
      <QF11SelectImagesUpTo4
        title={`${smallerOrGreaterTitle} ${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${smallerOrGreaterTitle}<br/>${translate.instructions.circleYourAnswer()}`}
        testCorrect={shapes.filter(shape => shape.smallerOrGreater === smallerOrGreater)}
        numItems={2}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape,
            component: (
              <AssetSvg
                name={shape.shapePath as SvgName}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'afm',
  description: 'afm',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    areaOfRectilinearShape: z.number().int().min(3).max(11),
    areaOfNonRectilinearShape: z.number().int().min(3).max(7)
  }),
  simpleGenerator: () => {
    const areaOfRectilinearShape = randomIntegerInclusive(3, 11);

    const areaOfNonRectilinearShape = randomIntegerInclusive(3, 7);

    return { areaOfRectilinearShape, areaOfNonRectilinearShape };
  },
  Component: props => {
    const {
      question: { areaOfRectilinearShape, areaOfNonRectilinearShape },
      translate
    } = props;

    const [shapes] = useMemo(() => {
      const random = seededRandom({ areaOfRectilinearShape, areaOfNonRectilinearShape });

      const rect = {
        shape: createShapeWithSquares(3, 4, areaOfRectilinearShape, true, { random }),
        isRectilinear: true
      };
      const nonRect = {
        shape: createShapeWithSquares(3, 4, areaOfNonRectilinearShape, false, { random }),
        isRectilinear: false
      };

      return [shuffle([rect, nonRect], { random })];
    }, [areaOfNonRectilinearShape, areaOfRectilinearShape]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectRectilinearShape()}
        pdfTitle={translate.instructions.circleRectilinearShape()}
        testCorrect={shapes.filter(shape => shape.isRectilinear)}
        numItems={2}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape,
            component: <DisplayShapeOnGrid givenShape={shape.shape} dimens={dimens} />
          }));
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'afn',
  description: 'afn',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    expectedArea: z.number().int().min(6).max(15)
  }),
  simpleGenerator: () => {
    const expectedArea = randomIntegerInclusive(6, 15);

    return { expectedArea };
  },
  Component: props => {
    const {
      question: { expectedArea },
      translate
    } = props;

    const exampleAnswerToDisplay = useMemo(
      () => createShapeWithSquares(4, 5, expectedArea, true),
      [expectedArea]
    );

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.createRectilinearShapeNumSquares(expectedArea)}
        numberOfRows={4}
        numberOfCols={5}
        testCorrect={userAnswer =>
          trueCount(userAnswer) === expectedArea && isRectilinear(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerToDisplay: exampleAnswerToDisplay,
          answerText: translate.markScheme.orAnyOtherValidRectilinearShapeWithXSquares(expectedArea)
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'WhatIsArea',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
