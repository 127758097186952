import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { multiplesNumberTrackArray } from '../../../../utils/multiples';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import { filledArray, range } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { MULT } from '../../../../constants';
import { View } from 'react-native';
import {
  containerOfObject,
  getRandomObject,
  objectAsWord,
  objectNames,
  objectSchema
} from '../../../../utils/objects';
import Text from '../../../../components/typography/Text';
import { getObjectImage } from '../../../../utils/objectsImages';
import QF29CreateArray from '../../../../components/question/questionFormats/QF29CreateArray';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'akp',
  description: 'akp',
  keywords: ['Multiply', 'Track', 'Multiple', '4'],
  schema: z.object({
    tileToShow: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const tileToShow = randomIntegerInclusive(2, 9);
    return { tileToShow };
  },
  Component: ({ question: { tileToShow }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(12, 4, 8, tileToShow);

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'akq',
  description: 'akq',
  keywords: ['Multiply', 'Group', 'Multiple', '4'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(12),
      object: objectSchema
    })
    .refine(val => val.groups !== 4, 'groups cannot be 4'),
  questionHeight: 1440,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 12, {
      constraint: x => x !== 4
    });

    const object = getRandomObject();

    return { groups, object };
  },
  Component: props => {
    const {
      question: { groups, object },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        sentences={[
          translate.answerSentences.thereAreAnsX(containerPlural),
          translate.answerSentences.eachGroupHasAnsObjects(containerSingular, objectPlural),
          translate.answerSentences.thereAreAnsObjectsInTotal(objectPlural)
        ]}
        title={translate.instructions.howManyObjectsAreThere(objectPlural)}
        testCorrect={[[groups.toString()], ['4'], [(groups * 4).toString()]]}
        Content={({ dimens }) => {
          const topLine = groups <= 8 ? groups : Math.ceil(groups / 2);

          const botLine = groups - topLine;

          const heightPerImage = botLine > 0 ? dimens.height / 2 : dimens.height;

          const widthPerGroup = botLine > 0 ? dimens.width / topLine : dimens.width / groups;
          return (
            <View
              style={{
                alignSelf: 'center',
                rowGap: 8
              }}
            >
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {range(1, topLine).map(index => (
                  <View key={index}>
                    {getObjectImage(object, 4, heightPerImage * 0.9, widthPerGroup * 0.9)}
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {botLine > 0 &&
                  range(1, botLine).map(index => (
                    <View key={index}>
                      {getObjectImage(object, 4, heightPerImage * 0.9, widthPerGroup * 0.9)}
                    </View>
                  ))}
              </View>
            </View>
          );
        }}
        questionHeight={1440}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'akr',
  description: 'akr',
  keywords: ['Multiply', 'Array', '4'],
  schema: z
    .object({
      columnsOrRows: z.number().int().min(2).max(12)
    })
    .refine(val => val.columnsOrRows !== 4, 'columnsOrRows cannot be 4'),
  simpleGenerator: () => {
    const columnsOrRows = randomIntegerInclusive(2, 12, {
      constraint: x => x !== 4
    });

    return { columnsOrRows };
  },
  Component: props => {
    const {
      question: { columnsOrRows },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[`<ans/> ${MULT} <ans/> = <ans/>`, `<ans/> ${MULT} <ans/> = <ans/>`]}
        title={translate.instructions.writeTwoMultSentencesForArray()}
        testCorrect={answer =>
          ((answer[0][0] === columnsOrRows.toString() &&
            answer[0][1] === '4' &&
            answer[1][0] === '4' &&
            answer[1][1] === columnsOrRows.toString()) ||
            (answer[0][0] === '4' &&
              answer[0][1] === columnsOrRows.toString() &&
              answer[1][0] === columnsOrRows.toString() &&
              answer[1][1] === '4')) &&
          answer[0][2] === (4 * columnsOrRows).toString() &&
          answer[1][2] === (4 * columnsOrRows).toString()
        }
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <ArrayOfObjects
            // Ensure there's no more than 4 rows, to save on space.
            rows={columnsOrRows < 5 ? columnsOrRows : 4}
            columns={columnsOrRows < 5 ? 4 : columnsOrRows}
            dimens={dimens}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              columnsOrRows.toLocaleString(),
              (4).toLocaleString(),
              (4 * columnsOrRows).toLocaleString()
            ],
            [
              (4).toLocaleString(),
              columnsOrRows.toLocaleString(),
              (4 * columnsOrRows).toLocaleString()
            ]
          ],
          answerText: translate.markScheme.validSentencesMatchingContent()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aks',
  description: 'aks',
  keywords: ['Multiply', 'Array', '4'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    fourIsFirstOrSecond: z.enum(['first', 'second'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const fourIsFirstOrSecond = getRandomFromArray(['first', 'second'] as const);

    return { number1, fourIsFirstOrSecond };
  },
  Component: props => {
    const {
      question: { number1, fourIsFirstOrSecond },
      translate
    } = props;

    const multiplicationSentence =
      fourIsFirstOrSecond === 'first'
        ? `4 ${MULT} ${number1.toLocaleString()}`
        : `${number1.toLocaleString()} ${MULT} 4`;

    return (
      <QF29CreateArray
        numberOfRows={6}
        numberOfCols={10}
        title={translate.instructions.createArrayToShow(multiplicationSentence)}
        testCorrect={[4, number1]}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.arrayDimensCanBeFlipped() }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'akt',
  description: 'akt',
  keywords: ['Multiply', '4'],
  schema: z.object({
    groupA: z.number().int().min(1).max(4).array().length(4),
    objects: z.array(objectSchema).length(3)
  }),
  simpleGenerator: () => {
    const groupA = shuffle([1, 2, 3, 4]);

    // Get 3 different objects
    const objects = getRandomSubArrayFromArray(objectNames, 3);

    return { groupA, objects };
  },
  Component: props => {
    const {
      question: { groupA, objects },
      translate,
      displayMode
    } = props;

    // All the same
    const A1 = 4;
    const A3 = 4;
    const B2 = 4;
    const B4 = 4;

    const [B1, A2, B3, A4] = groupA;
    const [objectA, objectB, objectC] = objects;

    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 100, width: 325 } : { height: 150, width: 450 };

    // Container & Object words
    const itemImageA = getObjectImage(objectA, B1, dimens.height, dimens.width / A1);
    const itemImageB = getObjectImage(objectB, B2, dimens.height, dimens.width / A2);
    const itemImageC = getObjectImage(objectC, B3, dimens.height, dimens.width / A3);

    const statements = [
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A1}
              customImage={itemImageA}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A1} ${MULT} ${B1}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A2}
              customImage={itemImageB}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A2} ${MULT} ${B2}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects
              dimens={dimens}
              rows={1}
              columns={A3}
              customImage={itemImageC}
              rowStyle={{ columnGap: 4, flex: 1 }}
            />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A3} ${MULT} ${B3}`
      },
      {
        lhsComponent: (
          <>
            <ArrayOfObjects dimens={dimens} rows={Math.min(A4, B4)} columns={Math.max(A4, B4)} />
            <Text variant="WRN400">=</Text>
          </>
        ),
        correctAnswer: `${A4} ${MULT} ${B4}`
      }
    ];

    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchPicturesToNumberSentences()}
        pdfTitle={translate.instructions.useCardsToMatchPicturesToNumberSentences()}
        items={items}
        statementStyle={{ justifyContent: 'center' }}
        statements={shuffledStatements}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aku',
  description: 'aku',
  keywords: ['Multiply', '4', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 8);

    return { number1 };
  },

  Component: ({ question: { number1 }, translate, ...props }) => {
    const multiplier = 4;
    const number3 = number1 * multiplier;

    const barArray = filledArray(multiplier, number1);

    const numbers = [[number3], barArray];

    const answerIndices = [[0], []];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        sameRowColor
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy4',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
