import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import Text from '../../../../components/typography/Text';
import { newQuestionContent } from '../../../Question';
import { countRange, sortNumberArray } from '../../../../utils/collections';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';

////
// Questions
////

const objects = ['flower', 'ribbons', 'pencil', 'giraffe', 'teddy'] as const;
type Objects = (typeof objects)[number];

const Question1 = newQuestionContent({
  uid: 'bds',
  description: 'bds',
  keywords: ['Longer', 'Taller', 'Shorter', 'Height', 'Length', 'Compare'],
  schema: z.object({
    lengths: z.array(z.number().int().min(4).max(9)).length(2),
    isVertical: z.boolean(),
    object: z.enum(objects),
    selectShorter: z.boolean(),
    cubeColor: z.enum(['blue', 'green', 'orange', 'purple', 'red', 'yellow'])
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const selectShorter = getRandomBoolean();
    const verticalObjects = ['flower', 'giraffe', 'teddy'] as const;
    const horizontalObjects = ['ribbons', 'pencil'] as const;
    const cubeColor = getRandomFromArray([
      'blue',
      'green',
      'orange',
      'purple',
      'red',
      'yellow'
    ] as const);

    const { object, lengths } = rejectionSample(
      () => {
        const object = isVertical
          ? getRandomFromArray(verticalObjects)
          : getRandomFromArray(horizontalObjects);

        const length1 = randomIntegerInclusive(4, 9);
        const length2 = randomIntegerInclusive(4, 9, {
          constraint: x => length1 !== x
        });
        return { object, lengths: [length1, length2] };
      },
      ({ lengths }) => Math.abs(lengths[0] - lengths[1]) < 7 // If the length difference is too large the smaller ones cant be seen
    );

    return { object, lengths, isVertical, selectShorter, cubeColor };
  },

  Component: props => {
    const {
      question: { object, lengths, isVertical, selectShorter, cubeColor },
      translate
    } = props;

    const random = seededRandom(props.question);
    const getSvgNames = (object: Objects) => {
      switch (object) {
        case 'flower':
          return getRandomSubArrayFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            2,
            { random }
          );
        case 'ribbons':
          return getRandomSubArrayFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, 2, {
            random
          });
        case 'pencil':
          return getRandomSubArrayFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            2,
            { random }
          );
        case 'giraffe':
          return ['Giraffe', 'Giraffe'] as const;
        case 'teddy':
          return ['Teddy_bear_1', 'Teddy_bear_1'] as const;
      }
    };

    const [answer] = sortNumberArray(lengths, selectShorter ? 'ascending' : 'descending');

    const [svg1, svg2] = getSvgNames(object);

    const objectTranslated =
      object === 'flower'
        ? translate.objects.Flower()
        : object === 'pencil'
        ? translate.objects.Pencil()
        : object === 'ribbons'
        ? translate.objects.Ribbon()
        : object === 'giraffe'
        ? translate.items.toyGiraffe(1)
        : translate.items.Teddy(1);

    return (
      <QF11SelectImagesUpTo4
        title={
          selectShorter
            ? translate.ks1Instructions.selectTheShorterXObject(objectTranslated)
            : isVertical
            ? translate.ks1Instructions.selectTheTallerXObject(objectTranslated)
            : translate.ks1Instructions.selectTheLongerXObject(objectTranslated)
        }
        pdfTitle={
          selectShorter
            ? translate.ks1PDFInstructions.tickTheShorterXObject(objectTranslated)
            : isVertical
            ? translate.ks1PDFInstructions.tickTheTallerXObject(objectTranslated)
            : translate.ks1PDFInstructions.tickTheLongerXObject(objectTranslated)
        }
        numItems={2}
        itemStyle={{ justifyContent: 'flex-end', paddingBottom: 32 }}
        renderItems={({ dimens }) => {
          return lengths.map((length, lengthIdx) => {
            return {
              value: length,
              component: (
                <View
                  style={{
                    flexDirection: isVertical
                      ? lengthIdx === 0
                        ? 'row'
                        : 'row-reverse'
                      : 'column',
                    alignItems: 'flex-end'
                  }}
                >
                  <AssetSvg
                    name={lengthIdx === 0 ? svg1 : svg2}
                    height={isVertical ? (dimens.height - 40) * (length * 0.1) : undefined}
                    width={!isVertical ? (dimens.width - 40) * (length * 0.1) : undefined}
                  />
                  <View style={{ flexDirection: isVertical ? 'column' : 'row' }}>
                    {countRange(length).map(idx => (
                      <AssetSvg
                        key={idx}
                        name={`Multi_link_cubes/front_column_${cubeColor}` as const}
                        height={
                          isVertical ? ((dimens.height - 40) * (length * 0.1)) / length : undefined
                        }
                        width={
                          !isVertical ? ((dimens.width - 40) * (length * 0.1)) / length : undefined
                        }
                      />
                    ))}
                  </View>
                </View>
              )
            };
          });
        }}
        testCorrect={[answer]}
      />
    );
  }
});

const objects2 = ['flower', 'tree', 'ribbon', 'pencil', 'giraffe', 'teddy'] as const;
type Objects2 = (typeof objects)[number];

const Question2 = newQuestionContent({
  uid: 'bdt',
  description: 'bdt',
  keywords: ['Longer', 'Shorter', 'Taller', 'Length', 'Height', 'Measure', 'Compare'],
  schema: z.object({
    length: z.number().int().min(4).max(9),
    isVertical: z.boolean(),
    object: z.enum(objects2),
    cubeColor: z.enum(['blue', 'green', 'orange', 'purple', 'red', 'yellow'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const verticalObjects = ['flower', 'tree', 'giraffe', 'teddy'] as const;
    const horizontalObjects = ['ribbon', 'pencil'] as const;
    const cubeColor = getRandomFromArray([
      'blue',
      'green',
      'orange',
      'purple',
      'red',
      'yellow'
    ] as const);

    const object = isVertical
      ? getRandomFromArray(verticalObjects)
      : getRandomFromArray(horizontalObjects);

    const length = randomIntegerInclusive(4, 9);

    return { object, length, isVertical, cubeColor };
  },
  Component: props => {
    const {
      question: { object, length, isVertical, cubeColor },
      translate
    } = props;
    const random = seededRandom(props.question);

    const getSvgName = (object: Objects2[number]) => {
      switch (object) {
        case 'flower':
          return getRandomFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            { random }
          );
        case 'tree':
          return getRandomFromArray(['Tree_A', 'Tree_B'] as const, { random });
        case 'ribbon':
          return getRandomFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, { random });
        case 'pencil':
          return getRandomFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            { random }
          );
        case 'giraffe':
          return 'Giraffe';
        case 'teddy':
          return getRandomFromArray(['Teddy_bear_1', 'Teddy_bear_2'] as const, { random });
      }
    };

    const answer = length;

    const svg = getSvgName(object);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentence()}
        testCorrect={[answer.toString()]}
        sentence={translate.ks1AnswerSentences.theXObjectIsAnsCubesYLength(
          object,
          isVertical ? translate.misc.Tall() : translate.misc.Long()
        )}
        pdfDirection="column"
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                flexDirection: isVertical ? 'row' : 'column'
              }}
            >
              <AssetSvg
                name={svg as SvgName}
                height={isVertical ? (dimens.height + 100) * (length * 0.1) : undefined}
                width={!isVertical ? dimens.width * (length * 0.1) : undefined}
              />
              <View style={{ flexDirection: isVertical ? 'column' : 'row' }}>
                {countRange(length).map(idx => (
                  <AssetSvg
                    key={idx}
                    name={`Multi_link_cubes/front_column_${cubeColor}` as const}
                    height={
                      isVertical ? ((dimens.height + 100) * (length * 0.1)) / length : undefined
                    }
                    width={!isVertical ? (dimens.width * (length * 0.1)) / length : undefined}
                  />
                ))}
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdu',
  description: 'bdu',
  keywords: ['Length', 'Height', 'Measure', 'Cubes', 'Tall', 'Long'],
  schema: z.object({
    length: z.number().int().min(5).max(9),
    isVertical: z.boolean(),
    object: z.enum(objects),
    cubeColor: z.enum(['blue', 'green', 'orange', 'purple', 'red', 'yellow']),
    lengthScale: z.number().int().min(2).max(6).step(2),
    longerObjectAlignment: z.enum(['center', 'right']),
    randomIncorrectNumber: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const verticalObjects = ['flower', 'giraffe', 'teddy'] as const;
    const horizontalObjects = ['ribbons', 'pencil'] as const;
    const cubeColor = getRandomFromArray([
      'blue',
      'green',
      'orange',
      'purple',
      'red',
      'yellow'
    ] as const);

    const longerObjectAlignment = getRandomFromArray(['center', 'right'] as const);

    const object = isVertical
      ? getRandomFromArray(verticalObjects)
      : getRandomFromArray(horizontalObjects);

    const lengthScale = isVertical
      ? randomIntegerInclusiveStep(2, 4, 2)
      : randomIntegerInclusiveStep(2, 6, 2);

    const length = randomIntegerInclusive(5, 9, {
      constraint: x => x > lengthScale
    });

    const randomIncorrectNumber = randomIntegerInclusive(1, 6);

    return {
      object,
      length,
      isVertical,
      cubeColor,
      lengthScale,
      longerObjectAlignment,
      randomIncorrectNumber
    };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: {
        object,
        length,
        isVertical,
        cubeColor,
        lengthScale,
        longerObjectAlignment,
        randomIncorrectNumber
      },
      translate
    } = props;
    const random = seededRandom(props.question);

    const getSvgName = (object: Objects) => {
      switch (object) {
        case 'flower':
          return getRandomFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            { random }
          );
        case 'ribbons':
          return getRandomFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, { random });
        case 'pencil':
          return getRandomFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            { random }
          );
        case 'giraffe':
          return 'Giraffe';
        case 'teddy':
          return getRandomFromArray(['Teddy_bear_1', 'Teddy_bear_2'] as const, { random });
      }
    };

    const svg = getSvgName(object);

    // Statements
    const statements = shuffle(
      [
        {
          sentence: <Text variant="WRN400">{length}</Text>,
          value: 'A',
          correct: true
        },
        {
          sentence: <Text variant="WRN400">{length + lengthScale}</Text>,
          value: 'B',
          correct: false
        },
        {
          sentence: (
            <Text variant="WRN400">
              {isVertical
                ? lengthScale / 2
                : longerObjectAlignment === 'center'
                ? lengthScale / 2
                : length + lengthScale - length}
            </Text>
          ),
          value: 'C',
          correct: false
        },
        {
          sentence: (
            <Text variant="WRN400">
              {isVertical
                ? length + lengthScale - lengthScale / 2
                : longerObjectAlignment === 'center'
                ? length + lengthScale - lengthScale / 2
                : length + lengthScale + randomIncorrectNumber}
            </Text>
          ),
          value: 'D',
          correct: false
        }
      ],
      { random }
    );

    // Filter correct answers
    const correctAnswers = statements.filter(it => it.correct);

    const objectTranslated =
      object === 'flower'
        ? translate.objects.Flower()
        : object === 'pencil'
        ? translate.objects.Pencil()
        : object === 'ribbons'
        ? translate.objects.Ribbon()
        : object === 'giraffe'
        ? translate.items.toyGiraffe(1)
        : translate.items.Teddy(1);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={
          isVertical
            ? translate.ks1Instructions.whatIsTheXLengthOfTheYObjectInCubesSelectYourAnswer(
                translate.measurements.Height(),
                objectTranslated
              )
            : translate.ks1Instructions.whatIsTheXLengthOfTheYObjectInCubesSelectYourAnswer(
                translate.measurements.Length(),
                objectTranslated
              )
        }
        pdfTitle={
          isVertical
            ? translate.ks1PDFInstructions.whatIsTheXLengthOfTheYObjectInCubesTickYourAnswer(
                translate.measurements.Height(),
                objectTranslated
              )
            : translate.ks1PDFInstructions.whatIsTheXLengthOfTheYObjectInCubesTickYourAnswer(
                translate.measurements.Length(),
                objectTranslated
              )
        }
        testCorrect={correctAnswers.map(it => it.value)}
        itemLayout={isVertical ? 'column' : 'row'}
        itemStyle={{ width: isVertical ? 300 : 200, height: 100 }}
        mainPanelFlexDirection={isVertical ? 'row' : 'column'}
        mainPanelContainer={{ justifyContent: 'center', rowGap: 48 }}
        numItems={4}
        questionHeight={1000}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                flexDirection: isVertical ? 'row' : 'column',
                alignItems: !isVertical
                  ? longerObjectAlignment === 'center'
                    ? 'center'
                    : 'flex-end'
                  : 'center',
                justifyContent: isVertical ? 'center' : undefined,
                width: isVertical ? dimens.width : undefined
              }}
            >
              <AssetSvg
                name={svg as SvgName}
                height={isVertical ? (dimens.height / 1.25) * (length * 0.1) : undefined}
                width={!isVertical ? (dimens.width / 1.5) * (length * 0.1) : undefined}
              />
              <View style={{ flexDirection: isVertical ? 'column' : 'row' }}>
                {countRange(length + lengthScale).map(idx => (
                  <AssetSvg
                    key={idx}
                    name={`Multi_link_cubes/front_column_${cubeColor}` as const}
                    height={
                      isVertical ? ((dimens.height / 1.25) * (length * 0.1)) / length : undefined
                    }
                    width={
                      !isVertical ? ((dimens.width / 1.5) * (length * 0.1)) / length : undefined
                    }
                  />
                ))}
              </View>
            </View>
          );
        }}
        renderItems={statements.map(({ sentence, value }) => ({
          value,
          component: sentence
        }))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureLengthUsingObjects',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
