import z from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { DIV, MULT } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import Text from '../../../../components/typography/Text';
import { arrayOfObjectColorsSchema, getArrayOfObjectsColors } from '../../../../utils/arrayObjects';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bi4',
  description: 'bi4',
  keywords: ['Array', 'Multiply', 'Divide'],
  schema: z
    .object({
      rowsOrColumnsA: z.number().int().min(2).max(12),
      rowsOrColumnsBAre2: z.enum(['rows', 'columns']),
      color: arrayOfObjectColorsSchema
    })
    .refine(
      val =>
        val.rowsOrColumnsBAre2 === 'rows' ? val.rowsOrColumnsA <= 12 : val.rowsOrColumnsA <= 5,
      "If rowsOrColumnsBAre2 is 'rows', rowsOrColumnsA must be less than or equal to 12, otherwise it must be less than or equal to 5"
    ),
  simpleGenerator: () => {
    const rowsOrColumnsBAre2 = getRandomFromArray(['rows', 'columns'] as const);
    const rowsOrColumnsA = randomIntegerInclusive(2, rowsOrColumnsBAre2 === 'rows' ? 12 : 5);
    const color = getArrayOfObjectsColors();

    return { rowsOrColumnsA, rowsOrColumnsBAre2, color };
  },
  Component: props => {
    const {
      question: { rowsOrColumnsA, rowsOrColumnsBAre2, color },
      translate
    } = props;

    // One of either rows or columns must be 2 - the other must be rowsOrColumnsA:
    const rows = rowsOrColumnsBAre2 === 'rows' ? 2 : rowsOrColumnsA;
    const columns = rowsOrColumnsBAre2 === 'columns' ? 2 : rowsOrColumnsA;

    return (
      <QF1ContentAndSentences
        sentences={[
          `<ans/> ${MULT} ${(2).toLocaleString()} = <ans/>`,
          `<ans/> ${DIV} ${(2).toLocaleString()} = <ans/>`
        ]}
        title={translate.ks1Instructions.completeTheCalculationsToMatchArray()}
        testCorrect={[
          [rowsOrColumnsA.toString(), (rowsOrColumnsA * 2).toString()],
          [(rowsOrColumnsA * 2).toString(), rowsOrColumnsA.toString()]
        ]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(
              (dimens.width - 20 * columns) / columns,
              dimens.height / (rows + 1)
            )}
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bi5',
  description: 'bi5',
  keywords: ['Multiply', 'Divide', '2 times-table'],
  schema: z.object({
    groups: z.number().int().min(1).max(12),
    answerOptions: z.array(z.number().int()).length(4).refine(arrayHasNoDuplicates),
    groupsFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const { groups, uniqueAnswers } = rejectionSample(
      () => {
        const groups = randomIntegerInclusive(1, 12);

        const answer1 = 2 * groups;
        const incorrectAnswers = [
          groups + 2,
          answer1 - 2,
          answer1 + 2,
          groups + 1,
          groups - 1
        ].filter(val => val > 0);

        const uniqueAnswers = [...new Set([answer1, groups, ...incorrectAnswers])];

        return { groups, uniqueAnswers };
      },
      val => val.uniqueAnswers.length >= 4
    );

    const incorrectAnswers = getRandomSubArrayFromArray(
      [...uniqueAnswers.filter(val => val !== 2 * groups && val !== groups)],
      2
    );
    const groupsFirst = getRandomBoolean();

    return {
      groups,
      answerOptions: shuffle([2 * groups, groups, ...incorrectAnswers]),
      groupsFirst
    };
  },
  Component: props => {
    const {
      question: { groups, answerOptions, groupsFirst },
      translate
    } = props;

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragCardsToCompleteTheCalculations()}
        pdfTitle={translate.ks1PDFInstructions.useCardsToCompleteTheCalculations()}
        sentences={[
          groupsFirst
            ? `${groups.toLocaleString()} ${MULT} ${(2).toLocaleString()} = <ans/>`
            : `${(2).toLocaleString()} ${MULT} ${groups.toLocaleString()} = <ans/>`,
          `${(groups * 2).toLocaleString()} ${DIV} ${(2).toLocaleString()} = <ans/>`
        ]}
        testCorrect={[[groups * 2], [groups]]}
        pdfLayout="itemsTop"
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        items={answerOptions.map(value => ({
          value,
          component: <Text variant="WRN700">{value.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bi6',
  description: 'bi6',
  keywords: ['2 times-table', 'Divide'],
  schema: z.object({
    dividend: z.number().min(2).max(24).step(2),
    order: z.enum(['quotientLast', 'quotientFirst', 'dividendFirst', 'dividendLast'])
  }),
  simpleGenerator: () => {
    const dividend = randomIntegerInclusiveStep(2, 24, 2);

    const order = getRandomFromArrayWithWeights(
      ['quotientLast', 'quotientFirst', 'dividendFirst', 'dividendLast'] as const,
      [4, 2, 1, 1]
    );

    return {
      dividend,
      order
    };
  },
  Component: ({ question: { dividend, order }, translate }) => {
    const [sentence, answer] = ((): [string, number] => {
      switch (order) {
        case 'quotientLast':
          return [
            `${dividend.toLocaleString()} ${DIV} ${(2).toLocaleString()} = <ans/>`,
            dividend / 2
          ];
        case 'quotientFirst':
          return [
            `<ans/> = ${dividend.toLocaleString()} ${DIV} ${(2).toLocaleString()}`,
            dividend / 2
          ];
        case 'dividendFirst':
          return [
            `<ans/> ${DIV} ${(2).toLocaleString()} = ${(dividend / 2).toLocaleString()}`,
            dividend
          ];
        case 'dividendLast':
          return [
            `<ans/> ${DIV} ${(2).toLocaleString()} = ${(dividend / 2).toLocaleString()}`,
            dividend
          ];
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheDivision()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy2',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
