import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { ADD } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  PartWholeModel,
  TextPartition
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aLe',
  description: 'aLe',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Centimetres', 'Millimetres'],
  questionHeight: 800,
  schema: z.object({
    displayOptionsIndex: z.array(z.number().int().min(0).max(3)).length(2)
  }),
  simpleGenerator: () => {
    const displayOptionsIndex = randomUniqueIntegersInclusive(0, 3, 2);

    return {
      displayOptionsIndex
    };
  },
  Component: props => {
    const {
      question: { displayOptionsIndex },
      translate
    } = props;

    const options: {
      string: 'millimetres' | 'centimetres' | 'metres' | 'kilometres';
      value: string;
    }[] = [
      { string: 'millimetres', value: translate.units.mm() },
      { string: 'centimetres', value: translate.units.cm() },
      { string: 'metres', value: translate.units.m() },
      { string: 'kilometres', value: translate.units.km() }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchAbbreviations()}
        pdfTitle={translate.instructions.useCardsToMatchAbbreviations()}
        questionHeight={800}
        items={shuffle(
          options.map(i => i.value),
          { random: seededRandom(props.question) }
        )}
        sentences={options
          .filter((_x, idx) => displayOptionsIndex.includes(idx))
          .map(x => `${translate.units[x.string](2)} = <ans/>`)}
        testCorrect={options
          .filter((_x, idx) => displayOptionsIndex.includes(idx))
          .map(x => [x.value])}
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aLf',
  description: 'aLf',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Centimetres', 'Millimetres', 'Compare'],
  schema: z.object({
    number: z.number().int().min(1).max(5),
    measurements: z.array(z.enum(['millimetres', 'centimetres', 'metres', 'kilometres']))
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 5);
    const [option1, option2, option3] = getRandomSubArrayFromArray(
      ['millimetres', 'centimetres', 'metres', 'kilometres'] as const,
      3
    );
    const remainder = ['millimetres', 'centimetres', 'metres', 'kilometres'].filter(
      i => i !== option3
    );

    const option4 = getRandomFromArray(remainder) as unknown as
      | 'millimetres'
      | 'centimetres'
      | 'metres'
      | 'kilometres';

    const measurements = [option1, option2, option3, option4];

    return { number, measurements };
  },
  Component: props => {
    const {
      question: { number, measurements },
      translate
    } = props;

    const greaterOrLess = (
      unit1: 'millimetres' | 'centimetres' | 'metres' | 'kilometres',
      unit2: 'millimetres' | 'centimetres' | 'metres' | 'kilometres'
    ) => {
      const value1 = ['millimetres', 'centimetres', 'metres', 'kilometres'].indexOf(unit1);
      const value2 = ['millimetres', 'centimetres', 'metres', 'kilometres'].indexOf(unit2);

      return lessThanGreaterThanOrEqualTo(value1, value2);
    };

    const sentences = [
      {
        string: `${number} ${translate.units[measurements[0]](
          number
        )} <ans/> ${number} ${translate.units[measurements[1]](number)}`,
        correctAnswer: greaterOrLess(measurements[0], measurements[1])
      },
      {
        string: `${number} ${translate.units[measurements[2]](
          number
        )} <ans/> ${number} ${translate.units[measurements[3]](number)}`,
        correctAnswer: greaterOrLess(measurements[2], measurements[3])
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompareLengths()}
        pdfTitle={translate.instructions.useCardsCompareLengths()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={sentences.map(x => x.string)}
        testCorrect={sentences.map(x => [x.correctAnswer])}
        moveOrCopy="copy"
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aLf2',
  description: 'aLf2',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Centimetres', 'Millimetres', 'Compare'],
  schema: z.object({
    number: z.number().int().min(1).max(5),
    measurements: z.array(z.enum(['millimetres', 'centimetres', 'metres', 'kilometres']))
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 5);
    const [option1, option2, option3] = getRandomSubArrayFromArray(
      ['millimetres', 'centimetres', 'metres', 'kilometres'] as const,
      3
    );
    const remainder = ['millimetres', 'centimetres', 'metres', 'kilometres'].filter(
      i => i !== option3
    );

    const option4 = getRandomFromArray(remainder) as unknown as
      | 'millimetres'
      | 'centimetres'
      | 'metres'
      | 'kilometres';

    const measurements = [option1, option2, option3, option4];

    return { number, measurements };
  },
  Component: props => {
    const {
      question: { number, measurements },
      translate
    } = props;

    const greaterOrLess = (
      unit1: 'millimetres' | 'centimetres' | 'metres' | 'kilometres',
      unit2: 'millimetres' | 'centimetres' | 'metres' | 'kilometres'
    ): '<' | '>' | '=' => {
      const value1 = ['millimetres', 'centimetres', 'metres', 'kilometres'].indexOf(unit1);
      const value2 = ['millimetres', 'centimetres', 'metres', 'kilometres'].indexOf(unit2);

      return lessThanGreaterThanOrEqualTo(value1, value2);
    };

    const statements = [
      {
        lhsComponent: `${number} ${translate.units[measurements[0]](number)}`,
        rhsComponent: `${number} ${translate.units[measurements[1]](number)}`,
        correctAnswer: greaterOrLess(measurements[0], measurements[1])
      },
      {
        lhsComponent: `${number} ${translate.units[measurements[2]](number)}`,
        rhsComponent: `${number} ${translate.units[measurements[3]](number)}`,
        correctAnswer: greaterOrLess(measurements[2], measurements[3])
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompareLengths()}
        pdfTitle={translate.instructions.useCardsCompareLengths()}
        statements={statements}
        items={['<', '>', '=']}
        itemVariant="square"
        moveOrCopy="copy"
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
        statementMaxFontSize={32}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aLg',
  description: 'aLg',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Part-whole', 'Parts', 'Whole'],
  schema: z.object({
    km: z.number().int().min(1).max(9),
    m: z.number().int().min(100).max(900).step(100),
    part: z.enum(['partA', 'partB'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const part = getRandomFromArray(['partA', 'partB'] as const);
    const km = randomIntegerInclusive(1, 9);
    const m = randomIntegerInclusiveStep(100, 900, 100);
    return { km, m, part };
  },
  Component: ({ question, translate }) => {
    const { km, m, part } = question;
    let partition: TextPartition = [];
    if (part === 'partA') {
      partition = [`? ${translate.units.km()}`, translate.units.numberOfM(m)];
    } else if (part === 'partB') {
      partition = [translate.units.numberOfKm(km), `? ${translate.units.m()}`];
    }
    const correctAnswer = (() => {
      switch (part) {
        case 'partA':
          return km;
        case 'partB':
          return m;
      }
    })();
    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePartWholeModelToWorkOutMissingValue()}
        testCorrect={[correctAnswer.toString()]}
        Content={({ dimens }) => (
          <PartWholeModel
            top={`${translate.units.numberOfKm(km)}<br/>${translate.units.numberOfM(m)}`}
            partition={partition}
            dimens={dimens}
          />
        )}
        sentence={`? = <ans/>`}
        questionHeight={1200}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aLh',
  description: 'aLh',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Bar model'],
  schema: z.object({
    answerPosition: z.enum(['bottom left', 'bottom right']),
    km: z.number().int().min(1).max(9),
    m: z.number().int().min(10).max(990).step(10)
  }),
  simpleGenerator: () => {
    const km = randomIntegerInclusive(1, 9);
    const m = randomIntegerInclusiveStep(10, 990, 10);

    const answerPosition = getRandomFromArray(['bottom left', 'bottom right'] as const);

    return { km, m, answerPosition };
  },

  Component: ({ question: { km, m, answerPosition }, translate }) => {
    const topKm = km + m / 1000;
    const kmToM = km * 1000;
    const isMTooSmall = m < kmToM + m * 0.2;

    const topM = isMTooSmall ? m * 5 : kmToM + m;

    const kmScaled = isMTooSmall ? topM - m : kmToM;

    const numbers = [
      [answerPosition === 'bottom left' ? topM : topKm],
      answerPosition === 'bottom left' ? [m, kmScaled] : [m / 1000, km]
    ];

    const strings = [
      [`${translate.units.numberOfKm(km)} ${translate.units.numberOfM(m)}`],
      [translate.units.numberOfM(m), translate.units.numberOfKm(km)]
    ];

    const answerIndices = (() => {
      switch (answerPosition) {
        case 'bottom left':
          return [[], [0]];
        case 'bottom right':
          return [[], [1]];
      }
    })();

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        strings={strings}
        answerIndices={answerIndices}
        total={answerPosition === 'bottom left' ? topM : topKm}
        postAnswerString={
          answerPosition === 'bottom right' ? translate.units.km() : translate.units.m()
        }
        oneFontSize
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aLi',
  description: 'aLi',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Compare'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(100).max(900).step(50),
    number3: z.number().int().min(1).max(9),
    number4: z.number().int().min(1).max(999),
    number5: z.number().int().min(10).max(990).step(10),
    number6: z.number().int().min(1).max(9),
    number7: z.number().int().min(1).max(9),
    number8: z.number().int().min(10).max(990).step(10),
    number9: z.number().int().min(100).max(500).step(100),
    number10: z.number().int().min(100).max(400).step(100),
    number11: z.number().int().min(1).max(9),
    number12: z.number().int().min(200).max(900).step(100)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const number2 = randomIntegerInclusiveStep(100, 900, 50);
    const number3 = randomIntegerInclusive(1, 9);
    const number4 = randomIntegerInclusive(1, 999);
    const number5 = randomIntegerInclusiveStep(10, 990, 10, { constraint: x => x !== number4 });
    const [number6, number7] = randomUniqueIntegersInclusive(1, 9, 2);
    const number8 = randomIntegerInclusiveStep(10, 990, 10);
    const number9 = randomIntegerInclusiveStep(100, 500, 100);
    const number10 = randomIntegerInclusiveStep(100, 400, 100);
    const number11 = randomIntegerInclusive(1, 9);
    const number12 = randomIntegerInclusiveStep(200, 900, 100);

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      number10,
      number11,
      number12
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number9,
        number10,
        number11,
        number12
      },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          string: `${translate.units.numberOfKm(number1)} <ans/> ${translate.units.numberOfKm(
            number1
          )} ${translate.units.numberOfM(number2)}`,
          correctAnswer: '<'
        },
        {
          string: `${translate.units.numberOfKm(number3)} ${translate.units.numberOfM(
            number4
          )} <ans/> ${translate.units.numberOfKm(number3)} ${translate.units.numberOfM(number5)}`,
          correctAnswer: lessThanGreaterThanOrEqualTo(number4, number5)
        },
        {
          string: `${translate.units.numberOfKm(number6)} <ans/> ${translate.units.numberOfKm(
            number7
          )} ${translate.units.numberOfM(number8)}`,
          correctAnswer:
            lessThanGreaterThanOrEqualTo(number6, number7) === '='
              ? '<'
              : lessThanGreaterThanOrEqualTo(number6, number7)
        },
        {
          string: `${translate.units.numberOfM(number9)} ${ADD} ${translate.units.numberOfM(
            number10
          )} ${ADD} ${translate.units.numberOfKm(number11)} <ans/> ${translate.units.numberOfKm(
            number11
          )} ${translate.units.numberOfM(number12)}`,
          correctAnswer: lessThanGreaterThanOrEqualTo(number9 + number10, number12)
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useInequalitiesToCompleteStatements()}
        items={['<', '>', '=']}
        pdfLayout="itemsHidden"
        questionHeight={1000}
        actionPanelVariant="end"
        sentences={sentences.map(x => x.string)}
        testCorrect={sentences.map(x => [x.correctAnswer])}
        moveOrCopy="copy"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
      />
    );
  },
  questionHeight: 1000
});

const Question5v2 = newQuestionContent({
  uid: 'aLi2',
  description: 'aLi2',
  keywords: ['Measure', 'Length', 'Kilometres', 'Metres', 'Compare'],
  schema: z.object({
    equation: z.enum(['A', 'B', 'C', 'D']),
    km1: z.number().int().min(1).max(9),
    km2: z.number().int().min(1).max(9),
    m1: z.number().int().min(1).max(999),
    m2: z.number().int().min(10).max(990).step(10),
    m3: z.number().int().min(200).max(900).step(100)
  }),
  simpleGenerator: () => {
    const equation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);

    const [km1, km2] = randomUniqueIntegersInclusive(1, 9, 2);

    const m1 = (() => {
      switch (equation) {
        case 'A':
          return randomIntegerInclusiveStep(100, 900, 50);
        case 'B':
          return randomIntegerInclusive(1, 999);
        case 'C':
          return randomIntegerInclusiveStep(10, 990, 10);
        case 'D':
          return randomIntegerInclusiveStep(100, 500, 100);
      }
    })();

    const m2 = (() => {
      switch (equation) {
        case 'A':
          return 10;
        case 'B':
          return randomIntegerInclusiveStep(10, 990, 10, { constraint: x => x !== m1 });
        case 'C':
          return 10;
        case 'D':
          return randomIntegerInclusiveStep(100, 400, 100);
      }
    })();

    const m3 = randomIntegerInclusiveStep(200, 900, 100);

    return {
      equation,
      km1,
      km2,
      m1,
      m2,
      m3
    };
  },
  Component: props => {
    const {
      question: { equation, km1, km2, m1, m2, m3 },
      translate
    } = props;

    const [sentence, answer] = (() => {
      switch (equation) {
        case 'A':
          return [
            `${translate.units.numberOfKm(km1)} <ans/> ${translate.units.numberOfKm(
              km1
            )} ${translate.units.numberOfM(m1)}`,
            '<'
          ];
        case 'B':
          return [
            `${translate.units.numberOfKm(km1)} ${translate.units.numberOfM(
              m1
            )} <ans/> ${translate.units.numberOfKm(km1)} ${translate.units.numberOfM(m2)}`,
            lessThanGreaterThanOrEqualTo(m1, m2)
          ];
        case 'C':
          return [
            `${translate.units.numberOfKm(km1)} <ans/> ${translate.units.numberOfKm(
              km2
            )} ${translate.units.numberOfM(m1)}`,
            lessThanGreaterThanOrEqualTo(km1, km2) === '='
              ? '<'
              : lessThanGreaterThanOrEqualTo(km1, km2)
          ];
        case 'D':
          return [
            `${translate.units.numberOfM(m1)} ${ADD} ${translate.units.numberOfM(
              m2
            )} ${ADD} ${translate.units.numberOfKm(km1)} <ans/> ${translate.units.numberOfKm(
              km1
            )} ${translate.units.numberOfM(m3)}`,
            lessThanGreaterThanOrEqualTo(m1 + m2, m3)
          ];
      }
    })();

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragLessThanGreaterThanOrEqualsToCompleteStatement()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatement()}
        items={['<', '>', '=']}
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
        sentence={sentence}
        testCorrect={[answer]}
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aLj',
  description: 'aLj',
  keywords: ['Divide', 'Share', 'Group'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(10).max(900),
    number3: z.number().int().min(1).max(9),
    isShortest: z.boolean()
  }),
  simpleGenerator: () => {
    const [number1, number3] = randomUniqueIntegersInclusive(1, 9, 2);
    const number2 = getRandomFromArray([number1 * 10, number1 * 100] as const);
    const isShortest = getRandomBoolean();

    return { number1, number2, number3, isShortest };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, isShortest },
      translate
    } = props;

    const title = isShortest
      ? translate.instructions.selectTheShortestMeasurement()
      : translate.instructions.selectTheLongestMeasurement();
    const pdfTitle = isShortest
      ? translate.instructions.selectTheShortestMeasurementPdf()
      : translate.instructions.selectTheLongestMeasurementPdf();

    const items = shuffle(
      [number1, number2, number1, number3].map((num, i) => {
        return {
          value: i < 2 ? translate.units.numberOfM(num) : translate.units.numberOfKm(num),
          string: i < 2 ? translate.units.numberOfM(num) : translate.units.numberOfKm(num)
        };
      }),
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        numItems={4}
        testCorrect={
          isShortest
            ? [translate.units.numberOfM(number1)]
            : [translate.units.numberOfKm(Math.max(number1, number3))]
        }
        renderItems={items.map(item => ({
          value: item.value,
          component: <Text variant="WRN700">{item.string}</Text>
        }))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInKilometresAndMetres',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question2, Question5]
});
export default SmallStep;
