import { View, ViewStyle, StyleProp, TextStyle } from 'react-native';
import { filledArray } from '../../../utils/collections';
import { useContext } from 'react';
import { Theme } from '../../../theme';
import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import { isEqualUnordered } from '../../../utils/matchers';
import { DraggableVariant } from '../../draganddrop/utils';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import TextStructure from '../../molecules/TextStructure';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * The correct grouping of values.
   * Must agree with the number of rows and columns, as defined by `zoneNames`.
   *
   * Note that values will be compared by identity (i.e. ===), so make sure that the
   * values are primitives (e.g. string or number), or they are references to the same objects as those passed into
   * the other prop `items`. Order of each sub-array doesn't matter.
   *
   * Alternatively, you can provide the correct answer as a function.
   */
  testCorrect: T[][] | ((userAnswer: T[][]) => boolean);
  /** Custom mark scheme answer. If you provide testCorrect as a function, you _must_ provide this. */
  customMarkSchemeAnswer?: { answerToDisplay?: T[][]; answerText?: string };

  /** Shape of the items. Default: square. */
  itemVariant?: DraggableVariant;
  pdfItemVariant?: DraggableVariant;

  /** The items to show as draggables. Each item can be passed as a plain string or number. */
  items: ((T & (string | number)) | ItemInfo<T>)[];
  // used if items contains plain strings or numbers
  itemsTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;

  /** Default: endWide. */
  actionPanelVariant?: 'end' | 'endWide' | 'bottom' | 'endMid';

  /** Default: itemsTop. */
  pdfLayout?: 'itemsTop' | 'itemsBottom';

  /** Default: move. */
  moveOrCopy?: 'move' | 'copy';

  /**
   * The names of each zone.
   */
  zoneNames: string[];
  /**
   * How many items fit into each zone. Default: 9.
   * Note: ideally this could be worked out for you from the zone's size and the item's size, but we don't have
   * access to that information without introducing a MeasureView, which would negatively impact performance.
   * supports our custom markup.
   */
  zoneCapacity?: number;
  /** PDF Question Height */
  questionHeight?: number;
  headerHeight?: number;
  headerTextStyle?: StyleProp<TextStyle>;
  headerStyle?: StyleProp<ViewStyle>;
};

/**
 * Question Format 8: Drag into up to 3 groups
 *
 * Title at the top, draggables on the right, and grouped positions to drag them into on the left.
 *
 * This is a many-(2 or 3) drag and drop.
 *
 * No hard restriction on the number of draggables.
 *
 * Note: this can support up to 3 groups.
 */
export default function QF8DragIntoUpTo3Groups<T>({
  title,
  pdfTitle,
  testCorrect,
  customMarkSchemeAnswer,
  items: itemsProp,
  zoneNames,
  zoneCapacity = 9,
  actionPanelVariant = 'endWide',
  moveOrCopy = 'move',
  itemVariant = 'square',
  itemsTextVariant = 'WRN700',
  itemsLetterEmWidth,
  itemsMaxLines,
  pdfItemVariant,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  headerHeight,
  headerTextStyle,
  headerStyle,
  pdfLayout = 'itemsTop',
  ...props
}: Props<T>) {
  if (typeof testCorrect === 'function' && customMarkSchemeAnswer === undefined) {
    throw new Error('testCorrect is a function, so you must provide the exampleCorrectAnswer prop');
  }

  const displayMode = useContext(DisplayMode);

  const items: ItemInfo<T>[] = itemsProp.map(item =>
    typeof item === 'object' ? item : { component: item.toLocaleString(), value: item }
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  const numZones = zoneNames.length;
  const emptyState = filledArray([], numZones);

  const itemStyle =
    actionPanelVariant === 'endMid' ? ({ justifyContent: 'center' } as StyleProp<ViewStyle>) : {};

  const dropSource = (
    <DragAndDropSection
      itemsStyle={[displayMode !== 'digital' && { justifyContent: 'center' }, itemStyle]}
    >
      {items.map((_item, index) => (
        <EasyDragAndDrop.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  const dragZones = (
    <>
      {/* column headers */}
      <View
        style={{
          height: displayMode === 'digital' ? headerHeight ?? 56 : 112,
          flexDirection: 'row'
        }}
      >
        {zoneNames.map((name, index) => (
          <View
            key={index}
            style={[
              {
                backgroundColor:
                  displayMode === 'digital' ? colors.pacificBlue600 : colors.greys100,
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                marginHorizontal: 3,
                borderColor: displayMode === 'digital' ? colors.white : colors.black,
                borderWidth: displayMode === 'digital' ? 0 : 4
              },
              headerStyle
            ]}
          >
            <TextStructure
              style={{ justifyContent: 'center' }}
              textVariant="WRN400"
              textStyle={[
                displayMode === 'digital'
                  ? { fontSize: 25, fontWeight: '400', color: colors.white }
                  : { fontSize: 40, fontWeight: '400', color: colors.black },
                headerTextStyle
              ]}
              fractionTextStyle={
                displayMode === 'digital'
                  ? { fontSize: 25, color: colors.white }
                  : { fontSize: 40, color: colors.black }
              }
              fractionDividerStyle={{
                borderColor: displayMode === 'digital' ? colors.white : colors.black,
                marginVertical: displayMode === 'digital' ? -4 : -8
              }}
              sentence={name}
            />
          </View>
        ))}
      </View>

      <View style={{ flex: 1, flexDirection: 'row' }}>
        {zoneNames.map((_name, index) => (
          <View
            key={index}
            style={{
              marginLeft: index !== 0 ? (displayMode === 'digital' ? -3 : -6) : 0,
              flex: 1,
              // Used to hide borders, when combined by a negative top margin in its child
              overflow: 'hidden'
            }}
          >
            <EasyDragAndDrop.ZoneMultiple
              id={index}
              capacity={zoneCapacity}
              style={{
                flex: 1,
                borderStyle: displayMode === 'digital' ? 'dashed' : 'solid',
                // Hide some borders. Note that we can't use borderTopWidth or borderLeftWidth on native as these are
                // broken with borderStyle: 'dashed'. Instead we use negative margins to hide borders.
                // Hide the border which is up against the title row.
                marginTop: displayMode === 'digital' ? -3 : -6,
                // Hide right-borders, where they are overlapped by the zone to the right.
                marginRight: index === numZones - 1 ? 0 : displayMode === 'digital' ? -3 : -6,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: index === 0 ? 8 : 0,
                borderBottomRightRadius: index === numZones - 1 ? 8 : 0,
                minWidth: undefined,
                minHeight: undefined
              }}
            />
          </View>
        ))}
      </View>
    </>
  );

  if (displayMode === 'pdf') {
    return (
      <EasyDragAndDrop.ProviderWithState
        id="draganddrop"
        items={items}
        variant={pdfItemVariant ?? itemVariant}
        textVariant={itemsTextVariant}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={itemsLetterEmWidth}
        maxLines={itemsMaxLines}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              {pdfLayout === 'itemsTop' ? (
                <View
                  style={{
                    paddingBottom: 32,
                    alignItems: 'center'
                  }}
                >
                  {dropSource}
                </View>
              ) : null}
              {dragZones}
              {pdfLayout === 'itemsBottom' ? (
                <View
                  style={{
                    paddingTop: 32,
                    alignItems: 'center'
                  }}
                >
                  {dropSource}
                </View>
              ) : null}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDrop.ProviderWithState>
    );
  }

  if (displayMode === 'markscheme') {
    // Mark scheme a table showing values - figure out what those values are
    const isNumber = (x: unknown): x is number => typeof x === 'number';
    let defaultState: T[][];
    if (customMarkSchemeAnswer?.answerToDisplay !== undefined) {
      defaultState = customMarkSchemeAnswer.answerToDisplay;
    } else {
      defaultState = (testCorrect as T[][]).map(zone =>
        // Make a best effort to sort - if they're all numbers then sort numerically, else lexicographically
        [...zone].sort(zone.every(isNumber) ? (a, b) => (a as number) - (b as number) : undefined)
      );
    }

    return (
      <EasyDragAndDrop.ProviderWithState
        id="draganddrop"
        items={items}
        variant={pdfItemVariant ?? itemVariant}
        defaultState={defaultState}
        textVariant={itemsTextVariant}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={itemsLetterEmWidth}
        maxLines={itemsMaxLines}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              {/* Add empty view to account for space that draggable options take up */}
              <View style={{ height: 182 }}></View>
              {dragZones}
              {customMarkSchemeAnswer?.answerText &&
                renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDrop.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState<T>
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={itemVariant}
      defaultState={emptyState}
      // Complete if all cards have been dragged
      testComplete={userAnswer => userAnswer.flat().length === items.length}
      testCorrect={
        typeof testCorrect === 'function'
          ? testCorrect
          : state => state.every((zone, index) => isEqualUnordered(testCorrect[index])(zone))
      }
      textVariant={itemsTextVariant}
      textAutoScale={longestItemTextLength}
      textLetterEmWidth={itemsLetterEmWidth}
      maxLines={itemsMaxLines}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={dropSource}
        mainPanelContents={dragZones}
        {...props}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}
