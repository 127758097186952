import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { sortNumberArray } from '../../../../utils/collections';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import AutoScaleText from '../../../../components/typography/AutoScaleText';
import { useMemo } from 'react';
import { ADD, SUB } from '../../../../constants';
import { roundToSignificantFigures } from '../../../../utils/math';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ajm',
  description: 'ajm',
  keywords: ['Addition', 'Subtraction', 'Compare', 'Bar model'],
  schema: z
    .object({
      greaterOrSmaller: z.enum(['greater', 'smaller']),
      number1: z.number().int().min(100).max(799),
      numberA2: z.number().int().min(100).max(799)
    })
    .refine(val => val.number1 + val.numberA2 < 900, 'number1 + numberA2 must be less than 900'),
  simpleGenerator: () => {
    const greaterOrSmaller = getRandomFromArray(['greater', 'smaller'] as const);

    const number1 = randomIntegerInclusive(100, 799);
    const numberA2 = randomIntegerInclusive(100, 899 - number1);

    return { greaterOrSmaller, number1, numberA2 };
  },
  Component: props => {
    const {
      question: { greaterOrSmaller, number1, numberA2 },
      translate,
      displayMode
    } = props;

    const numberB2 = numberA2 + 100;

    // Randomly order these equations
    const eqs = useMemo(() => {
      // Both eqs need the same total to align number1 in both.
      const eqA = { array: [[number1, numberA2]], value: 'smaller', total: number1 + numberB2 };
      const eqB = { array: [[number1, numberB2]], value: 'greater', total: number1 + numberB2 };
      return shuffle([eqA, eqB], { random: seededRandom(props.question) });
    }, [number1, numberA2, numberB2, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={
          greaterOrSmaller === 'greater'
            ? translate.instructions.selectCalcWithGreaterAnswer()
            : translate.instructions.selectCalcWithSmallerAnswer()
        }
        pdfTitle={
          greaterOrSmaller === 'greater'
            ? translate.instructions.circleCalcWithGreaterAnswer()
            : translate.instructions.circleCalcWithSmallerAnswer()
        }
        testCorrect={eqs.filter(eq => eq.value === greaterOrSmaller)}
        itemLayout={'column'}
        itemStyle={{ margin: 8 }}
        numItems={2}
        renderItems={({ dimens }) => {
          return eqs.map(eq => ({
            value: eq,
            component: (
              <View
                style={[
                  dimens,
                  { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }
                ]}
              >
                <Text
                  variant="WRN400"
                  style={{ fontSize: displayMode !== 'digital' ? 50 : 26 }}
                >{`${eq.array[0][0].toLocaleString()} ${ADD} ${eq.array[0][1].toLocaleString()}`}</Text>
                <BarModel
                  numbers={eq.array}
                  total={eq.total}
                  dimens={{ height: dimens.height, width: dimens.width * 0.75 }}
                  maxFontSize={displayMode !== 'digital' ? 50 : 26}
                />
              </View>
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ajn',
  description: 'ajn',
  keywords: ['Addition', 'Subtraction', 'Compare', 'Bar model'],
  schema: z
    .object({
      greaterOrSmaller: z.enum(['greater', 'smaller']),
      number1: z.number().int().min(100).max(799),
      numberA2: z.number().int().min(100).max(799)
    })
    .refine(val => val.number1 + val.numberA2 < 900, 'number1 + numberA2 must be less than 900'),
  simpleGenerator: () => {
    const greaterOrSmaller = getRandomFromArray(['greater', 'smaller'] as const);

    const number1 = randomIntegerInclusive(100, 799);
    const numberA2 = randomIntegerInclusive(100, 899 - number1);

    return { greaterOrSmaller, number1, numberA2 };
  },
  Component: props => {
    const {
      question: { greaterOrSmaller, number1, numberA2 },
      translate,
      displayMode
    } = props;

    const number3 = number1 + numberA2;
    const numberB2 = numberA2 + 100;

    // Randomly order these equations
    const eqs = useMemo(() => {
      // Both eqs need the same total to align number1 in both.
      const eqA = {
        array: [[number3], [number1, numberA2]],
        value: 'greater',
        total: number3
      };
      const eqB = {
        array: [[number3], [number1 - 100, numberB2]],
        value: 'smaller',
        total: number3
      };
      return shuffle([eqA, eqB], { random: seededRandom(props.question) });
    }, [number1, number3, numberA2, numberB2, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={
          greaterOrSmaller === 'greater'
            ? translate.instructions.selectCalcWithGreaterAnswer()
            : translate.instructions.selectCalcWithSmallerAnswer()
        }
        pdfTitle={
          greaterOrSmaller === 'greater'
            ? translate.instructions.circleCalcWithGreaterAnswer()
            : translate.instructions.circleCalcWithSmallerAnswer()
        }
        testCorrect={eqs.filter(eq => eq.value === greaterOrSmaller)}
        itemLayout={'column'}
        itemStyle={{ margin: 8 }}
        numItems={2}
        renderItems={({ dimens }) => {
          return eqs.map(eq => ({
            value: eq,
            component: (
              <View
                style={[
                  dimens,
                  { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }
                ]}
              >
                <Text
                  variant="WRN400"
                  style={{ fontSize: displayMode !== 'digital' ? 50 : 32 }}
                >{`${eq.array[0][0].toLocaleString()} ${SUB} ${eq.array[1][1].toLocaleString()}`}</Text>
                <View style={{ height: dimens.height, justifyContent: 'space-evenly' }}>
                  <BarModel
                    numbers={[eq.array[0]]}
                    total={eq.total}
                    dimens={{ height: dimens.height / 2.5, width: dimens.width * 0.75 }}
                    maxFontSize={displayMode !== 'digital' ? 50 : 32}
                  />
                  <BarModel
                    numbers={[eq.array[1]]}
                    total={eq.total}
                    dimens={{ height: dimens.height / 2.5, width: dimens.width * 0.75 }}
                    strings={[['']]}
                    arrowIndices={[[1]]}
                    cellColors={[[colors.acidGreen]]}
                    maxFontSize={displayMode !== 'digital' ? 50 : 32}
                  />
                </View>
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'ajo',
  description: 'ajo',
  keywords: ['Addition', 'Subtraction', 'Compare'],
  schema: z
    .object({
      numberA1: z.number().int().min(1000).max(998999),
      numberA2: z.number().int().min(1000).max(998999),
      numberB1: z.number().int().min(1000).max(998999),
      numberB2: z.number().int().min(1000).max(998999),
      numberC1: z.number().int().min(1000).max(998999),
      numberC2: z.number().int().min(1000).max(998999),
      numberC2Factor: z.number().int().min(-1000).max(1000),
      numberD1: z.number().int().min(1000).max(998999),
      numberD2: z.number().int().min(1000).max(998999),
      numberD3Factor: z.number().int().min(-1000).max(1000)
    })
    .refine(
      val =>
        val.numberA1 + val.numberA2 < 1000000 &&
        val.numberB1 + val.numberB2 < 1000000 &&
        val.numberC1 + val.numberC2 < 1000000 &&
        val.numberD1 + val.numberD2 < 1000000,
      'Each number pair must sum to less than 1,000,000'
    )
    .refine(
      val => val.numberA1 !== roundToSignificantFigures(val.numberA1, 2),
      'numberA1 must be different to numberA1 rounded to 2 significant figures.'
    )
    .refine(
      val => val.numberB2 !== roundToSignificantFigures(val.numberB2, 2),
      'numberB2 must be different to numberA1 rounded to 2 significant figures.'
    )
    .refine(
      val => Math.abs(val.numberC2Factor) > 9 && Math.abs(val.numberD3Factor) > 9,
      'numberC2Factor and numberD3Factor must not be between -9 and 9'
    ),
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(1000, 998999, {
      constraint: x => x !== roundToSignificantFigures(x, 2)
    });
    const numberA2 = randomIntegerInclusive(1000, 999999 - numberA1);

    const numberB1 = randomIntegerInclusive(1000, 998999);
    const numberB2 = randomIntegerInclusive(1000, 999999 - numberB1, {
      constraint: x => x !== roundToSignificantFigures(x, 2)
    });

    const numberC1 = randomIntegerInclusive(1000, 998999);
    const numberC2 = randomIntegerInclusive(1000, 999999 - numberC1);
    const numberC2Factor = randomIntegerInclusive(-1000, 1000, {
      constraint: x => Math.abs(x) > 9
    });

    const numberD1 = randomIntegerInclusive(1000, 998999);
    const numberD2 = randomIntegerInclusive(1000, 999999 - numberD1);
    const numberD3Factor = randomIntegerInclusive(-1000, 1000, {
      constraint: x => Math.abs(x) > 9
    });

    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberC2Factor,
      numberD1,
      numberD2,
      numberD3Factor
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        numberC1,
        numberC2,
        numberC2Factor,
        numberD1,
        numberD2,
        numberD3Factor
      },
      translate
    } = props;

    const numberA1Rounded = roundToSignificantFigures(numberA1, 2);
    const numberB2Rounded = roundToSignificantFigures(numberB2, 2);
    const numberC3 = numberC1 + numberC2;
    const numberD3 = numberD1 + numberD2;

    const sentences = getRandomSubArrayFromArray(
      [
        {
          sentence: `${numberA1.toLocaleString()} ${ADD} ${numberA2.toLocaleString()} <ans /> ${numberA1Rounded.toLocaleString()} ${ADD} ${numberA2.toLocaleString()}`,
          answer: numberA1 < numberA1Rounded ? '<' : '>'
        },
        {
          sentence: `${numberB1.toLocaleString()} ${ADD} ${numberB2.toLocaleString()} <ans /> ${numberB1.toLocaleString()} ${ADD} ${numberB2Rounded.toLocaleString()}`,
          answer: numberB2 < numberB2Rounded ? '<' : '>'
        },
        {
          sentence: `${numberC3.toLocaleString()} ${SUB} ${numberC2.toLocaleString()} <ans /> ${numberC3.toLocaleString()} ${SUB} ${(
            numberC2 + numberC2Factor
          ).toLocaleString()}`,
          answer: numberC2Factor < 0 ? '<' : '>'
        },
        {
          sentence: `${numberD3.toLocaleString()} ${SUB} ${numberD2.toLocaleString()} <ans /> ${(
            numberD3 + numberD3Factor
          ).toLocaleString()} ${SUB} ${numberD2.toLocaleString()}`,
          answer: numberD3Factor > 0 ? '<' : '>'
        }
      ],
      3,
      { random: seededRandom(props.question) }
    );

    const shuffledSentences = shuffle(sentences, { random: seededRandom(props.question) });

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsToCompareCalculations()}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        pdfTitle={translate.instructions.useCardsToCompareCalculationsEachCardCanBeUsedMoreThanOnce()}
        items={['<', '>', '=']}
        sentences={shuffledSentences.map(({ sentence }) => sentence)}
        testCorrect={shuffledSentences.map(({ answer }) => [answer])}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'ajp',
  description: 'ajp',
  keywords: ['Addition', 'Subtraction', 'Compare'],
  schema: z
    .object({
      greatestOrSmallest: z.enum(['greatest', 'smallest']),
      number1: z.number().int().min(101).max(99998),
      number2: z.number().int().min(100).max(99997),
      number4: z.number().int().min(1001).max(99999)
    })
    .refine(
      val => val.number2 < val.number1 && val.number1 < val.number4,
      'number2 must be less than number1, and number1 must be less than number4'
    ),
  simpleGenerator: () => {
    const number4 = randomIntegerInclusive(1001, 99999);
    const number1 = randomIntegerInclusive(101, number4 - 1);
    const number2 = randomIntegerInclusive(100, number1 - 1);
    const greatestOrSmallest = getRandomFromArray(['greatest', 'smallest'] as const);
    return { number1, number2, number4, greatestOrSmallest };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, greatestOrSmallest },
      translate
    } = props;

    const items = shuffle(
      [
        {
          sentence: `${number4.toLocaleString()} ${ADD} ${number2.toLocaleString()}`,
          isCorrect: false
        },
        {
          sentence: `${number4.toLocaleString()} ${SUB} ${number2.toLocaleString()}`,
          isCorrect: false
        },
        {
          sentence: `${number4.toLocaleString()} ${ADD} ${number1.toLocaleString()}`,
          isCorrect: greatestOrSmallest === 'greatest' ? true : false
        },
        {
          sentence: `${number4.toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
          isCorrect: greatestOrSmallest === 'smallest' ? true : false
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={
          greatestOrSmallest === 'greatest'
            ? translate.instructions.whichCalcHasGreatestAnswer()
            : translate.instructions.whichCalcHasSmallestAnswer()
        }
        testCorrect={items.filter(it => it.isCorrect === true).map(it => it.sentence)}
        items={items.map(({ sentence }) => ({
          value: sentence,
          component: sentence
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ajq',
  description: 'ajq',
  keywords: ['Addition', 'Subtraction', 'Order'],
  schema: z.object({
    number1: z.number().int().min(101).max(999),
    number3: z.number().int().min(2001).max(9999)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 999);
    const number3 = randomIntegerInclusive(2001, 9999);

    return { number1, number3 };
  },
  Component: props => {
    const {
      question: { number1, number3 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        string: `${number3.toLocaleString()} ${ADD} ${number1.toLocaleString()}`,
        value: number3 + number1
      };
      const eqB = {
        string: `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
        value: number3 - number1
      };
      const eqC = {
        string: `${number3.toLocaleString()} ${ADD} ${(number1 + 100).toLocaleString()}`,
        value: number3 + number1 + 100
      };
      const eqD = {
        string: `${number3.toLocaleString()} ${SUB} ${(number1 + 100).toLocaleString()}`,
        value: number3 - (number1 + 100)
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [number1, number3, props.question]);

    return (
      <QF4DragOrderVertical
        title={translate.instructions.dragCardsInAscendingsOrderOfValue()}
        pdfTitle={translate.instructions.drawLinesToPlaceCardsInAscendingOrderOfValue()}
        testCorrect={sortNumberArray(
          eqs.map(x => x.value),
          'ascending'
        )}
        items={eqs.map(({ value, string }) => ({
          value,
          component: (
            <AutoScaleText
              group={0}
              containerStyle={{ width: '100%', height: '100%' }}
              maxLines={1}
              textStyle={{ fontWeight: '700' }}
            >
              {string}
            </AutoScaleText>
          )
        }))}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'ajr',
  description: 'ajr',
  keywords: ['Addition', 'Subtraction', 'Compare'],
  schema: z
    .object({
      greaterOrSmaller: z.enum(['greater', 'smaller']),
      number1: z.number().int().min(1001).max(9999),
      number3: z.number().int().min(20100).max(99900),
      line5Factor: z.number().int().min(1000).max(9000).multipleOf(1000),
      line6Factor: z.number().int().min(1000).max(9000).multipleOf(1000)
    })
    .refine(val => val.number1 % 100 !== 0, 'number1 must not be a multiple of 100')
    .refine(val => val.number3 % 1000 !== 0, 'number3 must not be a multiple of 1,000'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % 100 !== 0
    });

    const number3 = randomIntegerInclusiveStep(20100, 99900, 100, {
      constraint: x => x % 1000 !== 0
    });

    const line5Factor = randomIntegerInclusiveStep(1000, 9000, 1000);
    const line6Factor = randomIntegerInclusiveStep(1000, 9000, 1000);

    const greaterOrSmaller = getRandomFromArray(['greater', 'smaller'] as const);

    return { number1, number3, line5Factor, line6Factor, greaterOrSmaller };
  },
  Component: props => {
    const {
      question: { number1, number3, line5Factor, line6Factor, greaterOrSmaller },
      translate
    } = props;

    const items = shuffle(
      [
        {
          sentence: `${number3.toLocaleString()} ${SUB} ${(number1 - 1000).toLocaleString()}`,
          value: number3 - (number1 - 1000)
        },
        {
          sentence: `${(number3 + 1000).toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
          value: number3 + 1000 - number1
        },
        {
          sentence: `${roundToSignificantFigures(
            number3,
            2
          ).toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
          value: roundToSignificantFigures(number3, 2) - number1
        },
        {
          sentence: `${number3.toLocaleString()} ${SUB} ${roundToSignificantFigures(
            number1,
            2
          ).toLocaleString()}`,
          value: number3 - roundToSignificantFigures(number1, 2)
        },
        {
          sentence: `${number3.toLocaleString()} ${SUB} ${(
            number1 + line5Factor
          ).toLocaleString()}`,
          value: number3 - (number1 + line5Factor)
        },
        {
          sentence: `${(number3 - line6Factor).toLocaleString()} ${SUB} ${roundToSignificantFigures(
            number1,
            2
          ).toLocaleString()}`,
          value: number3 - line6Factor - number1
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={
          greaterOrSmaller === 'greater'
            ? translate.instructions.selectCalcsWithAnswerGreaterThanAnsToX(
                `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`
              )
            : translate.instructions.selectCalcsWithAnswerLessThanAnsToX(
                `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`
              )
        }
        pdfTitle={
          greaterOrSmaller === 'greater'
            ? translate.instructions.circleCalcsWithAnswerGreaterThanAnsToX(
                `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`
              )
            : translate.instructions.circleCalcsWithAnswerLessThanAnsToX(
                `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`
              )
        }
        testCorrect={items
          .filter(it =>
            greaterOrSmaller === 'greater'
              ? it.value > number3 - number1
              : it.value < number3 - number1
          )
          .map(it => it.sentence)}
        items={items.map(({ sentence }) => ({
          value: sentence,
          component: sentence
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareCalculations',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
