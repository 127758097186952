import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  createRectangleFromSquares,
  createShapeWithSquares,
  getRectangleDimensions,
  isRectangle,
  isRectilinear,
  trueCount
} from '../../../../utils/shapes';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import {
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  countRange
} from '../../../../utils/collections';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { useMemo } from 'react';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF24aCreateShapeAndGivenShape from '../../../../components/question/questionFormats/QF24aCreateShapeAndGivenShape';
import { CreateShapeFromSquaresWithState } from '../../../../components/question/representations/CreateShapeFromSquares';
import { TestCorrect } from '../../../../stateTree';
import deepEqual from 'react-fast-compare';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aWs',
  description: 'aWs',
  keywords: ['Area', 'Length', 'Width', 'Rectangle'],
  schema: z.object({
    width: z.number().int().min(2).max(4),
    length: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 4);
    const length = randomIntegerInclusive(1, 6);

    return { width, length };
  },
  Component: ({ question, translate }) => {
    const { width, length } = question;

    const rectangle = createRectangleFromSquares(width, length);
    const area = length * width;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid
            cellSizeLabel={translate.units.numberOfCm(1)}
            gridCellSize={60}
            givenShape={rectangle}
            dimens={dimens}
            noGrid
          />
        )}
        inputMaxCharacters={2}
        style={{ alignItems: 'flex-end' }}
        sentences={[
          translate.answerSentences.lengthIsAnsCm(),
          translate.answerSentences.widthIsAnsCm(),
          translate.answerSentences.areaIsAnsCm2()
        ]}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(
            [userAnswer[0][0], userAnswer[1][0]],
            [length.toString(), width.toString()]
          ) && userAnswer[2][0] === area.toString()
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [length.toLocaleString()],
            [width.toLocaleString()],
            [area.toLocaleString()]
          ],
          answerText: translate.markScheme.lengthAndWidthCanBeFlipped()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aWt',
  description: 'aWt',
  keywords: ['Area', 'Rectangle'],
  schema: z
    .object({
      w1: z.number().int().min(1).max(5),
      w2: z.number().int().min(1).max(5),
      l1: z.number().int().min(1).max(6),
      l2: z.number().int().min(1).max(6),
      isSame: z.boolean()
    })
    .refine(
      ({ w1, w2, l1, l2 }) => !arraysHaveSameContents([w1, l1], [w2, l2]),
      'rectangles should be different'
    ),
  simpleGenerator: () => {
    const rectangleHaveSameArea = getRandomBoolean();

    const { w1, w2, l1, l2 } = rejectionSample(
      () => {
        const [l1, l2] = countRange(2).map(_ => randomIntegerInclusive(1, 6));
        const w1 = randomIntegerInclusive(1, 5);
        const w2 = randomIntegerInclusive(1, 5);

        return { l1, l2, w1, w2 };
      },

      ({ l1, l2, w1, w2 }) => {
        // rectangles should be different
        if (l1 === l2 && w2 === w1) return false;

        const area1 = l1 * w1;
        const area2 = l2 * w2;

        if (rectangleHaveSameArea) return area1 === area2;
        // and if they have the different areas, difference should be less than 4
        if (area1 !== area2 && Math.abs(area1 - area2) > 4) return false;
        return true;
      }
    );

    const isSame = getRandomBoolean();

    return { l1, l2, w1, w2, isSame };
  },
  Component: ({ question, translate }) => {
    const { l1, l2, w1, w2, isSame } = question;

    const shape1 = createRectangleFromSquares(w1, l1);
    const shape2 = createRectangleFromSquares(w2, l2);

    const areasAreSame = l1 * w1 === l2 * w2;

    const statement = isSame ? 'theShapesHaveTheSameArea' : 'theShapesHaveDifferentAreas';

    return (
      <QF39ContentWithSelectablesOnRight
        title={`${translate.instructions.isStatementTrueOrFalse()}<br/>${translate.answerSentences[
          statement
        ]()}`}
        pdfTitle={`${translate.instructions.isStatementTrueOrFalsePDF()}<br/>${translate.answerSentences[
          statement
        ]()}`}
        leftContent={
          <MeasureView>
            {dimens => (
              <View style={{ flexDirection: 'row', columnGap: 50 }}>
                <DisplayShapeOnGrid
                  gridCellSize={60}
                  givenShape={shape1}
                  dimens={{ height: dimens.height, width: dimens.width * 0.5 }}
                  noGrid
                />
                <DisplayShapeOnGrid
                  gridCellSize={60}
                  givenShape={shape2}
                  dimens={{ height: dimens.height, width: dimens.width * 0.5 }}
                  noGrid
                />
              </View>
            )}
          </MeasureView>
        }
        selectables={{ true: translate.misc.True(), false: translate.misc.False() }}
        selectableTextStyle={{ textTransform: 'uppercase' }}
        correctAnswer={[isSame === areasAreSame ? 'true' : 'false']}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aWu',
  description: 'aWu',
  keywords: ['Area', 'Rectangle'],
  schema: z.object({
    width: z.number().int().min(1).max(10),
    length: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const [width, length] = getRandomFromArray([
      [1, 4],
      [2, 2],
      [4, 1],
      [6, 1],
      [2, 3],
      [3, 2],
      [8, 1],
      [4, 2],
      [2, 4],
      [9, 1],
      [10, 1],
      [5, 2],
      [2, 5],
      [6, 2],
      [3, 4],
      [4, 3]
    ]);

    return { width, length };
  },
  Component: props => {
    const {
      question: { width, length },
      translate
    } = props;

    const area = width * length;
    const isSameRect = (shape: boolean[][]) => {
      const { width: userW, height: userL } = getRectangleDimensions(shape);
      return arraysHaveSameContents([width, length], [userW, userL]);
    };

    return (
      <QF24aCreateShapeAndGivenShape
        title={`${translate.instructions.areaOfTheRectangleIsXcm2(
          area.toLocaleString()
        )}<br/>${translate.instructions.createADifferentRectangleUsingTheSameArea()}`}
        givenShape={createRectangleFromSquares(width, length)}
        numberOfRows={5}
        numberOfCols={5}
        createCellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isRectangle(userAnswer) && trueCount(userAnswer) === area && !isSameRect(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithAreaOfX(
            translate.units.numberOfCm2(area)
          )
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aWv',
  description: 'aWv',
  keywords: ['Area', 'Rectilinear'],
  schema: z
    .object({
      correctShapeArea: z.number().int().min(2).max(20),
      incorrectShapeArea: z.number().int().min(2).max(20)
    })
    .refine(
      val => val.correctShapeArea !== val.incorrectShapeArea,
      'correctShapeArea cannot be the same as incorrectShapeArea.'
    ),
  simpleGenerator: () => {
    const correctShapeArea = randomIntegerInclusive(2, 20);

    const incorrectShapeArea = randomIntegerInclusive(
      Math.max(2, correctShapeArea - 2),
      Math.min(20, correctShapeArea + 2),
      {
        constraint: x => x !== correctShapeArea
      }
    );

    return { correctShapeArea, incorrectShapeArea };
  },
  Component: props => {
    const {
      question: { correctShapeArea, incorrectShapeArea },
      translate
    } = props;

    // Randomly order these shapes
    const shapes = useMemo(() => {
      const random = seededRandom(props.question);

      const shapeA = {
        shape: createShapeWithSquares(4, 5, correctShapeArea, true, { random }),
        isCorrect: true
      };

      const shapeB = {
        shape: createShapeWithSquares(4, 5, incorrectShapeArea, true, { random }),
        isCorrect: false
      };

      return shuffle([shapeA, shapeB], { random });
    }, [correctShapeArea, incorrectShapeArea, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.selectShapeThatHasAnAreaOfXcm2(
          correctShapeArea
        )}<br/>${translate.instructions.eachSquareRepresents1cm2()}`}
        pdfTitle={`${translate.instructions.circleShapeThatHasAnAreaOfXcm2(
          correctShapeArea
        )}<br/>${translate.instructions.eachSquareRepresents1cm2()}`}
        testCorrect={shapes.filter(shape => shape.isCorrect).map(shape => shape.shape)}
        numItems={2}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.shape,
            component: <DisplayShapeOnGrid givenShape={shape.shape} dimens={dimens} />
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aWw',
  description: 'aWw',
  keywords: ['Area', 'Rectilinear'],
  schema: z.object({
    area: z.number().int().min(7).max(13)
  }),
  simpleGenerator: () => {
    const area = randomIntegerInclusive(7, 13);

    return { area };
  },
  Component: ({ question: { area }, translate, displayMode }) => {
    return (
      <QF3Content
        title={translate.instructions.tapToCreateTwoRectilinearShapesThatHaveAnAreaOfXcm2(
          area.toLocaleString()
        )}
        pdfTitle={translate.instructions.shadeToCreateTwoRectilinearShapesThatHaveAnAreaOfXcm2(
          area.toLocaleString()
        )}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              columnGap: displayMode === 'digital' ? 70 : 200,
              alignSelf: 'flex-start'
            }}
          >
            <CreateShapeFromSquaresWithState
              id="createshape1"
              dimens={dimens}
              cellSizeLabel={translate.units.numberOfCm(1)}
              numberOfCols={5}
              numberOfRows={5}
              testCorrect={userAnswer =>
                isRectilinear(userAnswer) && trueCount(userAnswer) === area
              }
            />
            <CreateShapeFromSquaresWithState
              id="createshape2"
              dimens={dimens}
              cellSizeLabel={translate.units.numberOfCm(1)}
              numberOfCols={5}
              numberOfRows={5}
              testCorrect={userAnswer =>
                isRectilinear(userAnswer) && trueCount(userAnswer) === area
              }
            />
            <TestCorrect<{ createshape1: boolean[][]; createshape2: boolean[][] }>
              // Test to make sure the two shapes are different
              testCorrect={({ createshape1, createshape2 }) =>
                createshape1 &&
                createshape2 &&
                !arraysHaveSameContents(createshape1, createshape2, deepEqual)
              }
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyXRectilinearShapeWithAreaOfY(
            (2).toLocaleString(),
            translate.units.numberOfCm2(area)
          )
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aWx',
  description: 'aWx',
  keywords: ['Area', 'Rectilinear', 'Rectangle'],
  schema: z
    .object({
      width: z.number().int().min(2).max(5),
      length: z.number().int().min(1).max(5)
    })
    .refine(({ width, length }) => width * length < 25),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 5);
    const length = randomIntegerInclusive(1, 5, {
      constraint: x => x * width < 25 && x !== width
    });

    return { width, length };
  },
  Component: props => {
    const {
      question: { width, length },
      translate
    } = props;

    const area = width * length;
    const isSameRect = (shape: boolean[][]) => {
      const { width: userW, height: userL } = getRectangleDimensions(shape);
      return arraysHaveSameContents([width, length], [userW, userL]);
    };

    return (
      <QF24aCreateShapeAndGivenShape
        title={translate.instructions.tapSquaresToCreateADifferentRectilinearShapeThatHasTheSameAreaAsTheRectangle()}
        pdfTitle={translate.instructions.shadeSquaresToCreateADifferentRectilinearShapeThatHasTheSameAreaAsTheRectangle()}
        givenShape={createRectangleFromSquares(width, length)}
        numberOfRows={5}
        numberOfCols={5}
        testCorrect={userAnswer =>
          isRectilinear(userAnswer) && trueCount(userAnswer) === area && !isSameRect(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectilinearShapeWithAreaOfX(
            translate.units.numberOfCm2(area)
          )
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ShapesSameArea',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
