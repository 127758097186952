import { type QuestionToken, isQuestionToken, parseTokens } from 'common/SchemeOfLearning';
import { parse } from 'expo-linking';

/**
 * Parse QR code data of the form:
 *
 * ```
 * wreinfinity://questionstore/quiz?tokens=aaa~eyJvbmVzIjoyLCJ0ZW5zIjo1fQ.aag~eyJvbmVzIjo4LCJ0ZW5zIjo1fQ&quizName=My%20Quiz
 * ```
 *
 * scheme: wreinfinity
 * hostname: questionstore
 * path: quiz
 *
 * This is a legacy QR code form which works offline, since all the questions are encoded in the QR code.
 *
 * See {@link parseOfflineQueryParams} for details on these query params.
 */
export function parseOfflineQrCode(
  data: string
): { tokens: QuestionToken[]; quizName: string } | string {
  const parsedUrl = parse(data);

  if (parsedUrl.scheme === 'wreinfinity') {
    if (parsedUrl.hostname === 'questionstore') {
      if (parsedUrl.path === 'quiz') {
        // Success
        return parseOfflineQueryParams(parsedUrl.queryParams ?? {});
      } else {
        return `rest of path ${parsedUrl.path} was not "quiz"`;
      }
    } else {
      return `first part of path ${parsedUrl.hostname} was not "questionstore"`;
    }
  } else if (parsedUrl.scheme === 'exp') {
    // Note: In development, URLs can also be in the form of:
    // exp://192.168.0.13:8081/--/questionstore/quiz?tokens=apl.apj&quizName=Test
    // scheme: exp
    // hostname: 192.168.0.13:8081 (the URL parser _might_ append "/--", we've seen this on native)
    // path: questionstore/quiz (the URL parser _might_ prepend "--/", we've seen this on web)
    // Check this first, before erroring
    if (parsedUrl.path?.includes('questionstore/quiz')) {
      // Success
      return parseOfflineQueryParams(parsedUrl.queryParams ?? {});
    } else {
      return `rest of path ${parsedUrl.path} was not "questionstore/quiz"`;
    }
  } else {
    return `scheme ${parsedUrl.scheme} was not 'wreinfinity' or 'exp'`;
  }
}

/**
 * Parse query params which look like:
 *
 * - tokens: a .-separated string, where each entry can be of the form:
 *     - `[UID]`, e.g. `abc` - represents a Question Type
 *     - `[UID]~[base64EncodedData]`, e.g. `abc~eyJudW1iZXIiOjQyLCJsZWZ0UGFydCI6MjB9` - represents a Specific Question
 *         - this encoding uses the characters 0-9, A-Z, a-z, - and _.
 * - quizName: which is a string
 */
export function parseOfflineQueryParams(
  queryParams: Record<string, unknown>
): { tokens: QuestionToken[]; quizName: string } | string {
  if (Object.keys(queryParams).length === 0) return 'no params';
  const { tokens, quizName } = queryParams;
  if (quizName === undefined || typeof quizName !== 'string') return '"quizName" was not a string';
  if (tokens === undefined || typeof tokens !== 'string') return '"tokens" was not a string';

  // Extract tokens and decode their data.
  const parsedTokens = parseTokens(tokens);
  const correctlyEncodedTokens = parsedTokens.filter(isQuestionToken);
  if (correctlyEncodedTokens.length !== parsedTokens.length)
    return 'some tokens were not encoded correctly';

  return { quizName, tokens: correctlyEncodedTokens };
}

/**
 * Parse QR code data of the form:
 *
 * ```
 * wreinfinity://quiz/JGTSTQ-391623
 * ```
 *
 * scheme: wreinfinity
 * hostname: quiz
 * path: <schoolCode>-<quizPIN>
 */
export function parseOnlineQrCode(data: string):
  | {
      learningGroupShareCode: string;
      quizVersionShareShareCode: string;
    }
  | string {
  let parsedUrl;
  try {
    parsedUrl = parse(data);
  } catch (e) {
    return 'error parsing url';
  }

  if (parsedUrl.scheme !== 'wreinfinity') {
    return 'scheme was not "wreinfinity"';
  }

  if (parsedUrl.hostname !== 'quiz') {
    return `first part of path ${parsedUrl.hostname} was not "quiz"`;
  }

  const defaultPath = parsedUrl.path ?? '';
  const [learningGroupShareCode, quizVersionShareShareCode, _] = defaultPath.split('-');

  return { learningGroupShareCode, quizVersionShareShareCode };
}
