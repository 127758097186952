import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { fractionSchema } from '../../../../utils/zod';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import deepCompare from 'react-fast-compare';
import { isInRange } from '../../../../utils/matchers';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  logUniformSample,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { AssetSvg, type SvgName } from '../../../../assets/svg';
import { compareFractions } from '../../../../utils/fractions';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';

////
// Questions
////

const equalFractionShapes = {
  2: ['Circle', 'Hexagon', 'Kite', 'Octagon', 'Rectangle', 'Square', 'Triangle'],
  3: ['Circle', 'Square', 'Triangle'],
  4: ['Circle', 'Hexagon', 'Octagon', 'Rectangle', 'Square'],
  5: ['Circle', 'Pentagon', 'Square']
} as const;

type EqualFractionShapeObject =
  | {
      n: 1 | 2;
      d: 2;
      shape: (typeof equalFractionShapes)[2][number];
      color: 'blue' | 'green' | 'yellow';
    }
  | {
      n: 1 | 2 | 3;
      d: 3;
      shape: (typeof equalFractionShapes)[3][number];
      color: 'blue' | 'green' | 'yellow';
    }
  | {
      n: 1 | 2 | 3 | 4;
      d: 4;
      shape: (typeof equalFractionShapes)[4][number];
      color: 'blue' | 'green' | 'yellow';
    }
  | {
      n: 1 | 2 | 3 | 4 | 5;
      d: 5;
      shape: (typeof equalFractionShapes)[5][number];
      color: 'blue' | 'green' | 'yellow';
    };

const equalFractionShapeObjectSchema = z
  .object({
    n: z.number().int().min(1).max(5),
    d: z.number().int().min(2).max(5),
    shape: z.enum([
      'Circle',
      'Hexagon',
      'Kite',
      'Octagon',
      'Rectangle',
      'Square',
      'Triangle',
      'Pentagon'
    ]),
    color: z.enum(['blue', 'green', 'yellow'])
  })
  .refine(val => val.n <= val.d, 'numerator must be between 1 and denominator inclusive')
  .refine(
    val => (equalFractionShapes[val.d as 2 | 3 | 4 | 5] as readonly string[]).includes(val.shape),
    'we do not have that shape for the given denominator'
  ) as z.Schema<EqualFractionShapeObject>; // The refines have checked that this cast is valid

function equalFractionShapesObjectToSvgName(obj: EqualFractionShapeObject): SvgName {
  type Obj = typeof obj;
  // This cast is clearly justified because we're casting it to the same shape string.
  // It's necessary because without it typescript doesn't consider each case of the union
  // EqualFractionShapeObject separately. The pointless ternary is a trick to help with this.
  return `Equal_shapes_${obj.d}_parts/${obj.shape}_equal_${obj.d}-${obj.n}_1_${obj.color}` as Obj extends string
    ? `Equal_shapes_${Obj['d']}_parts/${Obj['shape']}_equal_${Obj['d']}-${Obj['n']}_1_${Obj['color']}`
    : never;
}

const Question1 = newQuestionContent({
  uid: 'bkC',
  description: 'bkC',
  keywords: ['Fraction', 'Non-unit fraction', 'Parts'],
  schema: equalFractionShapeObjectSchema,
  simpleGenerator: () => {
    const d = randomIntegerInclusive(2, 5) as 2 | 3 | 4 | 5;
    const n = randomIntegerInclusive(2, d); // Non-unit fraction, is allowed to be 1
    const shape = getRandomFromArray(equalFractionShapes[d]);
    const color = getRandomFromArray(['blue', 'green', 'yellow'] as const);
    return { d, n, shape, color } as EqualFractionShapeObject; // This cast will immediately get verified by the schema
  },
  Component: ({ question, translate }) => {
    const { n, d } = question;
    const svgName = equalFractionShapesObjectToSvgName(question);
    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsEqualParts(),
          translate.ks1AnswerSentences.thereAreAnsPartsShaded(),
          translate.ks1AnswerSentences.theFractionShadedIsAns()
        ]}
        mainPanelStyle={{ flexDirection: 'row-reverse', gap: 20 }}
        testCorrect={ans =>
          ans[0][0] === d.toString() &&
          ans[1][0] === n.toString() &&
          compareFractions(ans[2], [n, d])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [[d.toString()], [n.toString()], [n.toString(), d.toString()]]
        }}
        inputMaxCharacters={2}
        Content={({ dimens }) => <AssetSvg name={svgName} {...dimens} />}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bkD',
  description: 'bkD',
  keywords: ['Fraction', 'Non-unit fraction', 'Parts'],
  schema: equalFractionShapeObjectSchema,
  simpleGenerator: () => {
    const d = randomIntegerInclusive(2, 5) as 2 | 3 | 4 | 5;
    const n = randomIntegerInclusive(2, d); // Non-unit fraction, is allowed to be 1
    const shape = getRandomFromArray(equalFractionShapes[d]);
    const color = getRandomFromArray(['blue', 'green', 'yellow'] as const);
    return { d, n, shape, color } as EqualFractionShapeObject; // This cast will immediately get verified by the schema
  },
  Component: ({ question, translate }) => {
    const { n, d } = question;
    const svgName = equalFractionShapesObjectToSvgName(question);
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatFractionIsShaded()}
        sentence='<frac nAns="" dAns="" />'
        mainPanelStyle={{ flexDirection: 'row', gap: 20 }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={ans => compareFractions(ans, [n, d])}
        customMarkSchemeAnswer={{
          answersToDisplay: [n.toString(), d.toString()]
        }}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <AssetSvg name={svgName} width={dimens.width} height={dimens.height * 0.6} />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkE',
  description: 'bkE',
  keywords: ['Sort', 'Fraction', 'Unit fraction', 'Non-unit fraction'],
  schema: z.object({
    items: z
      .array(fractionSchema())
      .length(9)
      .refine(val => arrayHasNoDuplicates(val, deepCompare), 'items must have no duplicates')
      .refine(
        val => isInRange(3, 6)(val.filter(([n, _d]) => n === 1).length),
        'must be at least 3 of each type'
      )
  }),
  simpleGenerator: () => {
    const numUnit = randomIntegerInclusive(3, 4);
    const numNonUnitImproper = randomIntegerInclusive(1, 2);
    const numNonUnitProper = 9 - numUnit - numNonUnitImproper; // between 3 and 5

    const allUnit = range(2, 5).map(d => [1, d] as [number, number]);
    const units = getRandomSubArrayFromArray(allUnit, numUnit);

    const allNonUnitProper = range(3, 5).flatMap(d =>
      range(2, d - 1).map(n => [n, d] as [number, number])
    );
    const nonUnitPropers = getRandomSubArrayFromArray(allNonUnitProper, numNonUnitProper);

    const nonUnitImpropers = randomUniqueIntegersInclusive(1, 5, numNonUnitImproper, {
      sample: logUniformSample // Add bias to choosing 1
    }).map(d => [randomIntegerInclusive(Math.max(d, 2), 9), d] as [number, number]);

    return { items: shuffle([...units, ...nonUnitPropers, ...nonUnitImpropers]) };
  },
  Component: ({ question: { items }, translate, displayMode }) => {
    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheFractions()}
        pdfTitle={translate.ks1PDFInstructions.writeTheFractionsInTheCorrectColumn()}
        zoneNames={[
          translate.tableHeaders.unitFractions(),
          translate.tableHeaders.nonUnitFractions()
        ]}
        items={items.map(([n, d]) => ({
          value: `${n}/${d}`,
          component: (
            <TextStructure
              sentence={`<frac n="${n}" d="${d}"/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 28 : 40,
                fontWeight: '700'
              }}
            />
          )
        }))}
        pdfItemVariant="pdfSquare"
        testCorrect={[
          items.filter(([n, _d]) => n === 1).map(([n, d]) => `${n}/${d}`),
          items.filter(([n, _d]) => n !== 1).map(([n, d]) => `${n}/${d}`)
        ]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NonUnitFractions',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
