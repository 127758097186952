import z from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  countRange,
  filledArray,
  nestedArrayHasNoDuplicates
} from '../../../../utils/collections';
import { ADD, MULT } from '../../../../constants';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import Text from '../../../../components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { getGroupSvgName } from '../../../../utils/objectsImages';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biM',
  description: 'biM',
  keywords: ['Equal groups', 'Repeated addition', 'Multiplication'],
  schema: z.object({
    groups: z.number().int().min(2).max(6),
    item: z.enum([
      'Apple',
      'Pear',
      'Banana',
      'Donut',
      'Egg',
      'Pencil',
      'Muffin',
      'Fish',
      'Cookie',
      'MuffinRack'
    ]),
    inEachGroup: numberEnum([2, 3, 4, 5, 6, 10])
  }),
  simpleGenerator: () => {
    const inEachGroup = getRandomFromArray([2, 3, 4, 5, 6, 10] as const);
    const max = [3, 4, 6].includes(inEachGroup) ? 3 : 6;
    const groups = randomIntegerInclusive(2, max);

    const item = getRandomFromArray(
      inEachGroup > 5
        ? // TODO add pencils in when we get them for the 10
          (['Fish', 'Donut', 'Egg', 'MuffinRack'] as const)
        : ([
            'Apple',
            'Pear',
            'Banana',
            'Donut',
            'Egg',
            'Pencil',
            'Cookie',
            'MuffinRack',
            'Muffin',
            'Fish'
          ] as const)
    );

    return { groups, item, inEachGroup };
  },
  Component: props => {
    const {
      question: { groups, item, inEachGroup },
      translate
    } = props;

    const svgName = getGroupSvgName(item, inEachGroup);

    const rows = Math.ceil(groups / 3);
    const array = filledArray(inEachGroup.toLocaleString(), groups);

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheMultiplication()}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            {countRange(rows).map(rowId => (
              <View
                key={rowId}
                style={{
                  flexDirection: 'row',
                  gap: 25,
                  alignItems: rowId === rows - 1 ? 'flex-start' : undefined
                }}
              >
                {countRange(Math.min(3, groups - 3 * rowId)).map(i => (
                  <AssetSvg
                    key={`${rowId}_${i}`}
                    name={svgName}
                    width={dimens.width / 3}
                    height={rows > 1 ? dimens.height / 2.6 : dimens.height / 1.6}
                  />
                ))}
              </View>
            ))}
          </View>
        )}
        questionHeight={900}
        pdfDirection="column"
        style={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        pdfSentenceStyle={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        inputMaxCharacters={2}
        sentences={[
          `${array.join(` ${ADD} `)} = ${(groups * inEachGroup).toLocaleString()}`,
          `<ans/> ${MULT} <ans/> = ${(groups * inEachGroup).toLocaleString()}`
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: [[], [groups.toLocaleString(), inEachGroup.toLocaleString()]],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(
            [groups, inEachGroup].map(val => val.toString()),
            userAnswer[1]
          )
        }
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'biN',
  description: 'biN',
  keywords: ['Equal groups', 'Repeated addition', 'Multiplication'],
  schema: z.object({
    groups: z.number().int().min(2).max(5),
    item: z.enum([
      'Apple',
      'Pear',
      'Banana',
      'Donut',
      'Egg',
      'Pencil',
      'Muffin',
      'Fish',
      'Cookie',
      'MuffinRack'
    ]),
    inEachGroup: numberEnum([2, 3, 4, 5, 6, 10])
  }),
  simpleGenerator: () => {
    const inEachGroup = getRandomFromArray([2, 3, 4, 5, 6, 10] as const);
    const max = [3, 4, 6].includes(inEachGroup) ? 3 : 5;
    const groups = randomIntegerInclusive(2, max);

    const item = getRandomFromArray(
      inEachGroup > 5
        ? // TODO add pencils in when we get them for the 10
          (['Fish', 'Donut', 'Egg', 'MuffinRack'] as const)
        : ([
            'Apple',
            'Pear',
            'Banana',
            'Donut',
            'Egg',
            'Pencil',
            'Cookie',
            'MuffinRack',
            'Muffin',
            'Fish'
          ] as const)
    );

    return { groups, item, inEachGroup };
  },
  Component: props => {
    const {
      question: { groups, item, inEachGroup },
      translate
    } = props;

    const svgName = getGroupSvgName(item, inEachGroup);

    const array = filledArray('<ans/>', groups);

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheNumberSentencesToMatchThePicture()}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              gap: 25
            }}
          >
            {countRange(groups).map(i => (
              <AssetSvg
                key={i}
                name={svgName}
                width={dimens.width / (groups + 1)}
                height={dimens.height / 1.25}
              />
            ))}
          </View>
        )}
        questionHeight={900}
        inputMaxCharacters={2}
        sentences={[
          `${array.join(` ${ADD} `)} = ${(groups * inEachGroup).toLocaleString()}`,
          `<ans/> ${MULT} <ans/> = ${(groups * inEachGroup).toLocaleString()}`
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            filledArray(inEachGroup.toLocaleString(), groups),
            [groups.toLocaleString(), inEachGroup.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        pdfDirection="column"
        style={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        pdfSentenceStyle={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer[0], filledArray(inEachGroup.toString(), groups)) &&
          arraysHaveSameContentsUnordered(
            [groups, inEachGroup].map(val => val.toString()),
            userAnswer[1]
          )
        }
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'biO',
  description: 'biO',
  keywords: ['Repeated addition', 'Multiplication'],
  schema: z.object({
    numbers: z
      .array(z.tuple([z.number().int().min(2).max(10), z.number().int().min(2).max(5)]))
      .length(3),
    isNumber1First: z.boolean().array().length(3)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const number1 = randomUniqueIntegersInclusive(2, 10, 3);
        const number2 = number1.map(val => {
          const max = [2, 5].includes(val) ? 5 : val === 10 ? 4 : 3;
          return randomIntegerInclusive(2, max, { constraint: x => x !== number1[val] });
        });

        const numbers = countRange(3).map(val => [number1[val], number2[val]] as [number, number]);

        const isNumber1First = countRange(3).map(getRandomBoolean);

        return { numbers, isNumber1First };
      },
      val =>
        nestedArrayHasNoDuplicates(val.numbers) &&
        !arrayHasNoDuplicates([...val.numbers[0], ...val.numbers[1]])
    ),
  Component: props => {
    const {
      question: { numbers, isNumber1First },
      translate,
      displayMode
    } = props;

    const statements = numbers.map(val => ({
      lhsComponent: (
        <View style={{ width: displayMode === 'digital' ? 400 : 400, alignItems: 'center' }}>
          <Text variant="WRN400">{filledArray(val[0], val[1]).join(` ${ADD} `)}</Text>
        </View>
      ),
      correctAnswer: val.toString()
    }));

    const items = shuffle(
      numbers.map((val, index) => ({
        value: val.toString(),
        component: (
          <Text variant="WRN700">{`${val[
            isNumber1First[index] ? 0 : 1
          ].toLocaleString()} ${MULT} ${val[
            isNumber1First[index] ? 1 : 0
          ].toLocaleString()}`}</Text>
        )
      })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheAdditionsToMultiplications()}
        pdfTitle={translate.ks1PDFInstructions.matchTheAdditionsToMultiplications()}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={items}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'IntroduceMultiplicationSymbol',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
