import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { vehicleObjectNames, vehicleObjectSchema } from '../../../../utils/objects';
import { ADD, MULT } from '../../../../constants';
import { digitAtPowerIsNumber, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { all, create, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aO0',
  description: 'aO0',
  keywords: ['Multiplication', 'Related facts'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0),
    number2: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number2] = randomUniqueIntegersInclusive(21, 49, 2, {
      constraint: x => x % 10 !== 0
    });

    return { number1, number2 };
  },

  Component: ({ question: { number1, number2 }, translate }) => {
    const eqs = [
      getBinOpEquation({ left: number1, right: 100, sign: 'multiply', answer: 'result' }),
      getBinOpEquation({ left: number1, right: 99, sign: 'multiply', answer: 'result' }),
      getBinOpEquation({ left: number2, right: 100, sign: 'multiply', answer: 'result' }),
      getBinOpEquation({ left: number2, right: 99, sign: 'multiply', answer: 'result' })
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aO02',
  description: 'aO0',
  keywords: ['Multiplication', 'Related facts'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 49, {
      constraint: x => x % 10 !== 0
    });

    return { number1 };
  },

  Component: ({ question: { number1 }, translate, displayMode }) => {
    const eqs = [
      getBinOpEquation({ left: number1, right: 100, sign: 'multiply', answer: 'result' }),
      getBinOpEquation({ left: number1, right: 99, sign: 'multiply', answer: 'result' })
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aO1',
  description: 'aO1',
  keywords: ['Compare', 'Inequality', '4-digit', 'Multiplication'],
  schema: z.object({
    multiplicand: z
      .number()
      .int()
      .min(1010)
      .max(4090)
      .refine(x => x % 10 === 0 && x % 100 !== 0),
    multiplier: z
      .number()
      .int()
      .min(51)
      .max(95)
      .refine(x => x % 10 !== 0),
    multiplyMultiplicand: z.boolean(),
    multiplyMultiplier: z.boolean()
  }),
  simpleGenerator: () => {
    const multiplicand = randomIntegerInclusiveStep(1010, 4090, 10, {
      constraint: x => x % 100 !== 0
    });
    const multiplier = randomIntegerInclusive(51, 95, {
      constraint: x => x % 10 !== 0
    });

    const multiplyMultiplicand = getRandomBoolean();
    const multiplyMultiplier = getRandomBoolean();

    return { multiplicand, multiplier, multiplyMultiplicand, multiplyMultiplier };
  },

  Component: ({
    question: { multiplicand, multiplier, multiplyMultiplicand, multiplyMultiplier },
    translate
  }) => {
    const rhsMultiplicand = multiplyMultiplicand ? multiplicand * 10 : multiplicand / 10;
    const rhsMultiplier = multiplyMultiplier ? multiplier * 10 : multiplier / 10;

    const statement = `${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} <ans/> ${rhsMultiplicand.toLocaleString()} ${MULT} ${rhsMultiplier.toLocaleString()}`;

    const lhsProduct = number(math.evaluate(`${multiplicand} * ${multiplier}`));
    const rhsProduct = number(math.evaluate(`${rhsMultiplicand * rhsMultiplier}`));

    return (
      <QF37SentenceDrag
        title={translate.instructions.withoutCalculatingDragSymbolsToCompareTheCalculation()}
        pdfTitle={translate.instructions.withoutCalculatingUseSymbolsToCompareTheCalculation()}
        items={['<', '>', '=']}
        pdfLayout="itemsHidden"
        sentence={statement}
        testCorrect={[lessThanGreaterThanOrEqualTo(lhsProduct, rhsProduct)]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aO2',
  description: 'aO2',
  keywords: ['Multiplication', 'Related facts', 'Factor pairs'],
  schema: z
    .object({
      multiplicand: z
        .number()
        .int()
        .min(11)
        .max(25)
        .refine(x => x % 10 !== 0),
      multiplierA: z.number().int().min(2).max(5),
      multiplierB: z.number().int().min(2).max(5)
    })
    .refine(({ multiplierA, multiplierB }) => multiplierA >= multiplierB),
  simpleGenerator: () => {
    const multiplicandTens = getRandomFromArray([10, 20]);
    const multiplicandOnes = randomIntegerInclusive(1, 5);
    const multiplicand = multiplicandTens + multiplicandOnes;

    const multiplierA1 = randomIntegerInclusive(2, 5);
    const multiplierB1 = randomIntegerInclusive(2, 5, {
      constraint: x => x * multiplierA1 !== 10 && x <= multiplierA1
    });

    const [multiplierB, multiplierA] = [multiplierA1, multiplierB1].sort();

    return {
      multiplicand,
      multiplierA,
      multiplierB
    };
  },

  Component: ({ question: { multiplicand, multiplierA, multiplierB }, translate }) => {
    const multiplier = multiplierA * multiplierB;

    const formattedMultiplier = multiplier === 20 ? `${10} ${MULT} ${2}` : multiplier;

    const lhs = [`${multiplicand} ${MULT} ${formattedMultiplier}`, '', ''];
    const rhs = [
      `${multiplicand} ${MULT} ${multiplierA} ${MULT} <ans/>`,
      `<ans/> ${MULT} ${multiplierB}`,
      `<ans/>`
    ];

    return (
      <QF2AlignedEquations
        title={translate.instructions.workOutTheMultiplications()}
        leftSide={lhs}
        rightSide={rhs}
        testCorrect={{
          right: [
            [multiplierB.toString()],
            [(multiplicand * multiplierA).toString()],
            [(multiplicand * multiplier).toString()]
          ]
        }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aO22',
  description: 'aO2',
  keywords: ['Multiplication', 'Related facts', 'Factor pairs'],
  schema: z
    .object({
      multiplicand: z
        .number()
        .int()
        .min(11)
        .max(25)
        .refine(
          val => digitAtPowerIsNumber(val, 0, [1, 2, 3, 4, 5]),
          'Multiplicand must end in a 1, 2, 3, 4 or 5'
        ),
      multiplierA: numberEnum([2, 3, 4, 5, 10]),
      multiplierB: z.number().int().min(2).max(5)
    })
    .refine(
      val => val.multiplierA * val.multiplierB <= 25,
      'multiplierA x mulitplierB must be less than or equal to 25.'
    )
    .refine(
      val => val.multiplierA * val.multiplierB !== 10,
      'multiplierA x mulitplierB must not equal 10'
    )
    .refine(
      val => (val.multiplierA === 5 ? val.multiplierB !== 4 : true),
      'If multiplierA is 5, multiplierB cannot be 4'
    ),
  simpleGenerator: () => {
    const multiplicandTens = getRandomFromArray([10, 20]);
    const multiplicandOnes = randomIntegerInclusive(1, 5);
    const multiplicand = multiplicandTens + multiplicandOnes;

    const largerOrEqualMultiplier = getRandomFromArray([2, 3, 4, 5, 10] as const);
    const smallerOrEqualMultiplier = randomIntegerInclusive(2, 5, {
      constraint: x =>
        x <= largerOrEqualMultiplier &&
        x * largerOrEqualMultiplier !== 10 &&
        x * largerOrEqualMultiplier <= 25 &&
        (largerOrEqualMultiplier === 5 ? x !== 4 : true)
    });

    // This should be the smaller multiplier, unless the larger multiplier is 10:
    const multiplierA =
      largerOrEqualMultiplier === 10
        ? largerOrEqualMultiplier
        : (smallerOrEqualMultiplier as 2 | 3 | 4 | 5 | 10);

    // This should be the larger multiplier, unless the larger multiplier is 10:
    const multiplierB =
      largerOrEqualMultiplier === 10
        ? smallerOrEqualMultiplier
        : (largerOrEqualMultiplier as 2 | 3 | 4 | 5);

    return {
      multiplicand,
      multiplierA,
      multiplierB
    };
  },

  Component: ({ question: { multiplicand, multiplierA, multiplierB }, translate }) => {
    const multiplier = multiplierA * multiplierB;

    const lhs = [`${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()}`, '', ''];
    const rhs = [
      `${multiplicand.toLocaleString()} ${MULT} ${multiplierA.toLocaleString()} ${MULT} <ans/>`,
      `<ans/> ${MULT} ${multiplierB.toLocaleString()}`,
      `<ans/>`
    ];

    return (
      <QF2AlignedEquations
        title={translate.instructions.workOutTheMultiplications()}
        leftSide={lhs}
        rightSide={rhs}
        testCorrect={{
          right: [
            [multiplierB.toString()],
            [(multiplicand * multiplierA).toString()],
            [(multiplicand * multiplier).toString()]
          ]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aO3',
  description: 'aO3',
  keywords: ['Multiplication', 'Related facts', 'Partition'],
  schema: z
    .object({
      multiplicand: z
        .number()
        .int()
        .min(11)
        .max(25)
        .refine(x => x % 10 !== 0),
      multiplierA: z.number().int().min(10).max(50).step(10),
      multiplierB: z.number().int().min(1).max(5),
      answerLeftSide: z.boolean()
    })
    .refine(({ multiplierA, multiplierB }) => multiplierA >= multiplierB),
  simpleGenerator: () => {
    const multiplicandTens = getRandomFromArray([10, 20]);
    const multiplicandOnes = randomIntegerInclusive(1, 5);
    const multiplicand = multiplicandTens + multiplicandOnes;

    const multiplierA = randomIntegerInclusiveStep(10, 50, 10);
    const multiplierB = randomIntegerInclusive(1, 5);

    const answerLeftSide = getRandomBoolean();

    return { multiplicand, multiplierA, multiplierB, answerLeftSide };
  },

  Component: ({
    question: { multiplicand, multiplierA, multiplierB, answerLeftSide },
    translate
  }) => {
    const multiplier = multiplierA + multiplierB;
    const productA = multiplicand * multiplierA;
    const productB = multiplicand * multiplierB;

    const [productString, productAns] = answerLeftSide
      ? [`<ans/> ${ADD} ${productB.toLocaleString()}`, productA]
      : [`${productA.toLocaleString()} ${ADD} <ans/>`, productB];

    const lhs = [`${multiplicand} ${MULT} ${multiplier}`, '', ''];
    const rhs = [
      `${multiplicand} ${MULT} ${multiplierA} ${ADD} ${multiplicand} ${MULT} <ans/>`,
      `${productString}`,
      `<ans/>`
    ];

    return (
      <QF2AlignedEquations
        title={translate.instructions.workOutTheMultiplications()}
        leftSide={lhs}
        rightSide={rhs}
        inputMaxCharacters={4}
        testCorrect={{
          right: [
            [multiplierB.toString()],
            [productAns.toString()],
            [(multiplicand * multiplier).toString()]
          ]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aO4',
  description: 'aO4',
  keywords: ['Multiplication', '3-digit', '2-digit'],
  schema: z.object({
    dinners: z
      .number()
      .int()
      .min(101)
      .max(499)
      .refine(x => x % 10 !== 0),
    weeks: z.number().int().min(4).max(9)
  }),
  simpleGenerator: () => {
    const dinners = randomIntegerInclusive(101, 499, {
      constraint: x => x % 10 !== 0
    });
    const weeks = randomIntegerInclusive(4, 9);

    return { dinners, weeks };
  },

  Component: ({ question: { dinners, weeks }, translate }) => {
    const totalDinners = dinners * weeks * 5;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.schoolServesXDinnersEveryDayThereAreYWeekHowManyInTotal(
          dinners,
          weeks
        )}
        testCorrect={[totalDinners.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aO5',
  description: 'aO5',
  keywords: ['Multiplication', '3-digit', '2-digit'],
  schema: z
    .object({
      vehicle1: vehicleObjectSchema,
      vehicle2: vehicleObjectSchema,
      distance1: z
        .number()
        .int()
        .min(101)
        .max(199)
        .refine(x => x % 10 !== 0),
      distance2: z
        .number()
        .int()
        .min(101)
        .max(199)
        .refine(x => x % 10 !== 0),
      days1: z
        .number()
        .int()
        .min(21)
        .max(49)
        .refine(x => x % 10 !== 0),
      days2: z
        .number()
        .int()
        .min(21)
        .max(49)
        .refine(x => x % 10 !== 0)
    })
    .refine(({ vehicle1, vehicle2 }) => vehicle1 !== vehicle2, 'Vehicles should be different')
    .refine(({ distance1, distance2 }) => distance1 !== distance2, 'Distances should be different')
    .refine(({ days1, days2 }) => days1 !== days2, 'Days should be different')
    .refine(
      ({ distance1, distance2, days1, days2 }) => distance1 * days1 !== distance2 * days2,
      'Difference should be different'
    ),
  simpleGenerator: () => {
    const [vehicle1, vehicle2] = getRandomSubArrayFromArray(vehicleObjectNames, 2);
    const [distance1, distance2] = randomUniqueIntegersInclusive(101, 199, 2, {
      constraint: x => x % 10 !== 0
    });
    const days1 = randomIntegerInclusive(21, 49, {
      constraint: x => x % 10 !== 0
    });
    const days2 = randomIntegerInclusive(21, 49, {
      constraint: x => x !== days1 && x % 10 !== 0 && days1 * distance1 !== distance2 * x
    });

    return { vehicle1, vehicle2, distance1, distance2, days1, days2 };
  },

  Component: ({
    question: { vehicle1, vehicle2, distance1, distance2, days1, days2 },
    translate
  }) => {
    const differenceTraveled = Math.abs(days1 * distance1 - days2 * distance2);

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.xTravelsYkmEachDayForZdays(
          vehicle1.toLocaleLowerCase(),
          distance1,
          days1
        )}<br/>${translate.instructions.xTravelsYkmEachDayForZdays(
          vehicle2.toLocaleLowerCase(),
          distance2,
          days2
        )}<br/>${translate.instructions.whatIsTheDifferenceInTheDistanceTravelledByXAndDistanceTravelledByY(
          vehicle1.toLocaleLowerCase(),
          vehicle2.toLocaleLowerCase()
        )}`}
        testCorrect={[differenceTraveled.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithMultiplication',
  questionTypes: [Question1v2, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1, Question3]
});
export default SmallStep;
