import { countRange } from '../../../utils/collections';
import { BarModelInteractiveWithCrossesWithState } from '../representations/BarModelInteractiveWithCrosses';
import { BarModelColorsKey } from '../../../theme/colors';
import Text from '../../typography/Text';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import QF1ContentAndSentence from './QF1ContentAndSentence';

type Props = {
  shadedCellsColor?: BarModelColorsKey;
  bars: {
    rows: number;
    cols: number;
    label?: string | JSX.Element;
    /**
     * If this is provided, cells with column indices between min and max (inclusive) are always shaded and interactive.
     * Note that this applies to _every_ row within this bar model.
     */
    shadedCells?: { min: number; max: number };
    /**
     * If this is provided, cells with column indices between min and max (inclusive) are always crossed.
     * Note that this applies to _every_ row within this bar model.
     */
    crossedCells?: { min: number; max: number };
  }[];
  title: string;
  sentence: string;
  tableHeight?: number;
  tableWidth?: number;
  textStyle?: StyleProp<TextStyle>;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /**
   * Text to display before each bar model. Optional prop.
   */
  preBarText?: string[];
  barModelShadingTestComplete?: ((value: boolean[][]) => boolean) | undefined;
  testCorrect: ((answer: string[]) => boolean) | string[];
  inputMaxCharacters?: number;
  /** If true renders a full width horizontal line with text above bar model */
  fullWidthTopBrace?: string | number;
  interactive?: boolean;
  /**
   * Direction for this question's PDF content to be displayed.
   * Optional prop, defaults to row.
   */
  pdfDirection?: 'row' | 'column';
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
};

/**
 * Question format showing a bar model and sentence.
 *
 * bar model or bar models which have pre-shaded cells, and when clicked toggle a cross.
 * This uses {@link BarModelInteractiveWithCrosses}.
 */

export default function QF20cInteractiveBarModelWithCrossesAndSentence({
  shadedCellsColor,
  bars,
  title,
  sentence,
  tableHeight = 100,
  tableWidth,
  textStyle,
  fractionContainerStyle,
  fractionTextStyle,
  preBarText = [],
  barModelShadingTestComplete,
  testCorrect: testCorrectProp,
  fullWidthTopBrace,
  interactive = true,
  ...props
}: Props) {
  // A 3D array indexed by bar, row, column with shaded cells set to true.
  // Controls which cells are interactive.
  const shadedCells: boolean[][][] = bars.map(bar =>
    countRange(bar.rows).map(_rowIndex =>
      countRange(bar.cols).map(colIndex => {
        if (bar.shadedCells !== undefined) {
          // Some shaded cells were defined - set these to true and rest to false.
          return bar.shadedCells.min <= colIndex && colIndex <= bar.shadedCells.max;
        } else {
          // Shaded cells were not defined. Set all cells to false.
          return false;
        }
      })
    )
  );

  // A 3D array indexed by bar, row, column with crossed cells set to true.
  // Controls which cells are crossed.
  const crossedCells: boolean[][][] = bars.map(bar =>
    countRange(bar.rows).map(_rowIndex =>
      countRange(bar.cols).map(colIndex => {
        if (bar.crossedCells !== undefined) {
          // Some crossed cells were defined - set these to true and rest to false.
          return bar.crossedCells.min <= colIndex && colIndex <= bar.crossedCells.max;
        } else {
          // crossed cells were not defined. Set all cells to false.
          return false;
        }
      })
    )
  );

  return (
    <QF1ContentAndSentence
      title={title}
      testCorrect={testCorrectProp}
      sentence={sentence}
      textStyle={textStyle}
      fractionContainerStyle={fractionContainerStyle}
      fractionTextStyle={fractionTextStyle}
      Content={({ dimens }) =>
        bars.map((bar, idx) => (
          <View key={idx} style={{ flexDirection: 'row' }}>
            {bar.label && (
              <Text
                style={{
                  alignSelf: 'center',
                  fontSize: 18,
                  paddingRight: 20,
                  position: 'relative',
                  top: -7.5
                }}
              >
                {bar.label}
              </Text>
            )}
            <BarModelInteractiveWithCrossesWithState
              id={`barmodel-${idx}`}
              shadedCells={shadedCells[idx]}
              crossedCells={crossedCells[idx]}
              numberOfRows={bar.rows}
              numberOfCols={bar.cols}
              shadedCellsColor={shadedCellsColor}
              tableHeight={tableHeight > dimens.height ? dimens.height : tableHeight}
              tableWidth={dimens.width}
              preBarText={preBarText[idx]}
              style={{ marginTop: 15, top: -15 }}
              fractionContainerStyle={fractionContainerStyle}
              fractionTextStyle={fractionTextStyle}
              fullWidthTopBrace={fullWidthTopBrace}
              interactive={interactive}
              testComplete={barModelShadingTestComplete}
            />
          </View>
        ))
      }
      {...props}
    />
  );
}
