import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { all, create, number } from 'mathjs';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { GattegnoChart } from '../../../../components/question/representations/Gattegno Chart/GattegnoChart';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { DIV } from '../../../../constants';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aCk',
  description: 'aCk',
  keywords: ['Decimals', 'Division', 'Gattegno'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(9))
      .length(2)
      .refine(numArray => arrayHasNoDuplicates(numArray), 'all numbers must be unique')
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 9, 2);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const [number1, number2] = numbers;

    const number3 = number2 / 10;
    const number4 = number(math.evaluate(`${number1} + ${number3}`));

    // Options
    const number5 = number4 / 10;
    const number6 = number4 * 10;
    const number7 = number4 / 100;
    const number8 = number(math.evaluate(`${number1} / 10 + ${number3} / 100`));
    const number9 = number(math.evaluate(`${number4} + 10`));

    const random = seededRandom(props.question);

    const incorrectOptions = shuffle([number6, number7, number8, number9], { random });

    const shuffledItems = shuffle([number5, ...incorrectOptions.slice(0, 3)], { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.workOutXAndSelectYourAnswer(
          `${number4.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        pdfTitle={translate.instructions.workOutXAndCircleYourAnswer(
          `${number4.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        testCorrect={[number5]}
        itemLayout={'row'}
        numItems={4}
        Content={({ dimens }) => (
          <GattegnoChart
            number={number4}
            rowsToShow={[1, 0, -1, -2]}
            dimens={{ width: dimens.width, height: dimens.height - 48 }}
            arrowDirection="down"
          />
        )}
        renderItems={shuffledItems.map(value => ({
          value,
          component: (
            <Text variant="WRN700">
              {value.toLocaleString(undefined, { minimumFractionDigits: 0 })}
            </Text>
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aCl',
  description: 'aCl',
  keywords: ['Decimals', 'Division', 'Gattegno'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(9))
      .length(3)
      .refine(numArray => arrayHasNoDuplicates(numArray), 'all numbers must be unique')
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 9, 3);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const [number1, number2, number3] = numbers;

    const number4 = number2 / 10;
    const number5 = number3 / 100;
    const number6 = number(math.evaluate(`${number1} + ${number4} + ${number5}`));

    // Options
    const number7 = number6 / 10;
    const number8 = number6 / 100;
    const number9 = number(math.evaluate(`${number1} / 10 + ${number4} / 100 + ${number5} / 100`));
    const number10 = number(math.evaluate(`${number1} / 10 + ${number4} / 10 + ${number5} / 100`));
    const number11 = number(math.evaluate(`${number6} * 10`));
    const number12 = number(math.evaluate(`${number6} + 10`));

    const random = seededRandom(props.question);

    const incorrectOptions = shuffle([number8, number9, number10, number11, number12], { random });

    const shuffledItems = shuffle([number7, ...incorrectOptions.slice(0, 3)], { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.workOutXAndSelectYourAnswer(
          `${number6.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        pdfTitle={translate.instructions.workOutXAndCircleYourAnswer(
          `${number6.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
        )}
        testCorrect={[number7]}
        itemLayout={'row'}
        numItems={4}
        Content={({ dimens }) => (
          <GattegnoChart
            number={number6}
            rowsToShow={[1, 0, -1, -2, -3]}
            dimens={{ width: dimens.width, height: dimens.height - 48 }}
          />
        )}
        renderItems={shuffledItems.map(value => ({
          value,
          component: (
            <Text variant="WRN700">
              {value.toLocaleString(undefined, { minimumFractionDigits: 1 })}
            </Text>
          )
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aCm',
  description: 'aCm',
  keywords: ['Decimal', 'Division', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumber: z.number().min(1.01).max(99.99)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b', 'c', 'd']);

    const questionNumber =
      questionToShow === 'a'
        ? randomIntegerInclusive(11, 99, {
            constraint: x => x % 10 !== 0
          }) / 10
        : questionToShow === 'b'
        ? randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 100
        : questionToShow === 'c'
        ? randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 10
        : randomIntegerInclusive(1001, 9999, {
            constraint: x => x % 10 !== 0
          }) / 100;

    return {
      questionNumber
    };
  },
  Component: props => {
    const {
      question: { questionNumber },
      translate
    } = props;

    const [sentence, answer] = [
      `${questionNumber.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
      number(math.evaluate(`${questionNumber} / 10`))
    ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeDivision()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={5}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aCn',
  description: 'aCn',
  keywords: ['Decimal', 'Division', 'Place value chart'],
  schema: z.object({
    givenNumber: z.number().min(1.1).max(9.9)
  }),
  simpleGenerator: () => {
    const givenNumber =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;

    return { givenNumber };
  },
  Component: props => {
    const {
      question: { givenNumber },
      translate
    } = props;

    const answer1 = givenNumber / 10;
    const answer2 = givenNumber / 100;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.usePlaceValueChartToHelpCompleteDivisions()}
        sentences={[
          `${givenNumber.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
          `${givenNumber.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) && compareFloats(userAnswer[1][0], answer2)
        }
        inputMaxCharacters={5}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(givenNumber)}
            columnsToShow={[3, 2, 1, 0, -1, -2, -3]}
            headerVariant="shortName"
            counterVariant="number"
            dimens={dimens}
          />
        )}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aCo',
  description: 'aCo',
  keywords: ['Decimal', 'Division', 'Place value chart'],
  schema: z.object({
    givenNumber: z
      .number()
      .int()
      .min(101)
      .max(999)
      .refine(val => val % 10 !== 0)
      .or(z.number().min(10.1).max(99.9))
  }),
  simpleGenerator: () => {
    const integerOrDecimal = getRandomFromArray(['decimal', 'integer']);

    const givenNumber =
      integerOrDecimal === 'decimal'
        ? randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 10
        : randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 });

    return { givenNumber };
  },
  Component: props => {
    const {
      question: { givenNumber },
      translate
    } = props;

    const answer1 = givenNumber / 10;
    const answer2 = givenNumber / 100;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.usePlaceValueChartToHelpCompleteDivisions()}
        sentences={[
          `${givenNumber.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
          `${givenNumber.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`
        ]}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answer1) && compareFloats(userAnswer[1][0], answer2)
        }
        inputMaxCharacters={5}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(givenNumber)}
            columnsToShow={[2, 1, 0, -1, -2, -3]}
            counterVariant="number"
            headerVariant="shortName"
            dimens={dimens}
          />
        )}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [[answer1.toLocaleString()], [answer2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aCp',
  description: 'aCp',
  keywords: ['Decimal', 'Division', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().min(10.1).max(99.9).multipleOf(0.01)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 =
      randomIntegerInclusive(1010, 9990, {
        constraint: x => x % 100 !== 0
      }) / 100;

    return {
      number1
    };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const [sentence1, answer1] = [
      `${number1.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
      number(math.evaluate(`${number1} / 10`))
    ];

    const [sentence2, answer2] = [
      `${number1.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`,
      number(math.evaluate(`${number1} / 100`))
    ];

    const [sentence3, answer3] = [
      `${number1.toLocaleString()} ${DIV} ${(1000).toLocaleString()} = <ans/>`,
      number(math.evaluate(`${number1} / 1000`))
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisions()}
        extraSymbol="decimalPoint"
        testCorrect={answer =>
          compareFloats(answer[0][0], answer1) &&
          compareFloats(answer[1][0], answer2) &&
          compareFloats(answer[2][0], answer3)
        }
        sentences={[sentence1, sentence2, sentence3]}
        inputMaxCharacters={7}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [answer1.toLocaleString()],
            [answer2.toLocaleString(undefined, { maximumFractionDigits: 4 })],
            [answer3.toLocaleString(undefined, { maximumFractionDigits: 5 })]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy10100And1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
