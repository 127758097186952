import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import {
  calculateDistanceBetweenTwoPoints,
  isEqualPerimeter,
  isRectangle,
  isSquareShape,
  isValidIsoscelesTriangle,
  isValidRightAngledTriangle
} from '../../../../utils/shapes';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import { LabelledRATriangle } from '../../../../components/question/representations/LabelledRATriangle';
import { ALGEBRAIC_B } from '../../../../constants';
import QF50aDraggableProtractor from '../../../../components/question/questionFormats/QF50aDraggableProtractor';
import { isInRange } from '../../../../utils/matchers';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { algebraicSymbolSchema, getAlgebraicSymbol } from '../../../../utils/algebraicSymbols';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { arraysHaveSameContentsUnordered } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aEQ',
  description: 'aEQ',
  keywords: ['Draw shapes', 'Perimeter'],
  schema: z.object({
    perimeter: z.number().int().min(4).max(20).multipleOf(4)
  }),
  simpleGenerator: () => {
    const perimeter = randomIntegerInclusiveStep(4, 20, 4);

    return { perimeter };
  },
  Component: ({ question: { perimeter }, translate }) => {
    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.tapGridToCreateSquareThatHasPerimOfXcm(perimeter)}
        pdfTitle={translate.instructions.shadeGridToCreateSquareThatHasPerimOfXcm(perimeter)}
        numberOfRows={5}
        numberOfCols={8}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, perimeter) && isSquareShape(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anySquareWithPerimeterOfX(
            translate.units.numberOfCm(perimeter)
          )
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aET',
  description: 'aET',
  keywords: ['Draw shapes', 'Triangles', 'Measure', 'Length'],
  schema: z.object({
    name: nameSchema,
    length: z.number().int().min(3).max(14),
    start: z.number().int().min(0).max(5),
    variationIndex: z.number().int().min(0).max(4)
  }),
  simpleGenerator: () => {
    const start = randomIntegerInclusive(0, 5);
    const length = randomIntegerInclusive(3, 14, { constraint: x => start + x < 15 });
    const name = getRandomName();
    const variationIndex = randomIntegerInclusive(0, 4);

    return {
      length,
      name,
      start,
      variationIndex
    };
  },
  Component: ({ question, translate }) => {
    const { length, name, start, variationIndex } = question;

    const triangleVariations = [
      'Isosceles_triangles_wide/triangle_isos_wide_blue',
      'Isosceles_triangles_wide/triangle_isos_wide_green',
      'Isosceles_triangles_wide/triangle_isos_wide_pink',
      'Isosceles_triangles_wide/triangle_isos_wide_purple',
      'Isosceles_triangles_wide/triangle_isos_wide_yellow'
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.xIsMeasuringATriangleWhatIsLengthOfSide(name)}
        sentence={`<ans/> ${translate.units.cm()}`}
        testCorrect={[length.toString()]}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{
          justifyContent: 'flex-end'
        }}
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={15}
            items={[
              {
                start,
                length,
                svgInfo: { name: triangleVariations[variationIndex] as SvgName }
              }
            ]}
          />
        )}
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aET2',
  description: 'aET2',
  keywords: ['Draw shapes', 'Perimeter'],
  schema: z.object({
    perimeter: z.number().int().min(4).max(20).multipleOf(2)
  }),
  simpleGenerator: () => {
    const perimeter = randomIntegerInclusiveStep(4, 20, 2);

    return { perimeter };
  },
  Component: ({ question: { perimeter }, translate }) => {
    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.createRectangleThatHasPerimeterOfNumCm(perimeter)}
        numberOfRows={5}
        numberOfCols={8}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, perimeter) && isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithPerimeterOfX(
            translate.units.numberOfCm(perimeter)
          )
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aER',
  description: 'aER',
  questionHeight: 900,
  keywords: ['Draw shapes', 'Right angles', 'Triangles', 'Angles', 'Length'],
  schema: z.object({
    name: nameSchema,
    length: z.number().int().min(3).max(6),
    hyp: z.number().int().min(4).max(9),
    giveCorrectLength: z.boolean(),
    variation: z.enum(['A', 'B', 'C', 'D'])
  }),
  simpleGenerator: () => {
    const name = getRandomName();
    const length = randomIntegerInclusive(3, 6);
    const hyp = randomIntegerInclusive(length + 1, 9);
    const giveCorrectLength = getRandomBoolean();
    const variation = getRandomFromArray(['A', 'B', 'C', 'D'] as const);

    return { name, length, hyp, giveCorrectLength, variation };
  },
  Component: props => {
    const {
      question: { name, length, hyp, giveCorrectLength, variation },
      translate,
      displayMode
    } = props;

    const givenLength = giveCorrectLength ? hyp.toLocaleString() : length.toLocaleString();

    return (
      <QF38ContentWithSentenceTrueOrFalse
        questionHeight={900}
        title={translate.instructions.isCharacterCorrect(name)}
        pdfTitle={translate.instructions.isCharacterCorrectPDF(name)}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        correctAnswer={giveCorrectLength}
        content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }
            ]}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignSelf: 'stretch',
                justifyContent: 'center'
              }}
            >
              <SpeechBubble
                flickLocation="bottom-right"
                style={{
                  maxWidth: dimens.width * 0.2,
                  maxHeight: dimens.height * 0.5,
                  margin: dimens.width * 0.07,
                  alignSelf: 'flex-start'
                }}
              >
                {translate.answerSentences.iHaveDrawnRightAngledTriangleLongestSideIsXcm(
                  givenLength.toLocaleString()
                )}
              </SpeechBubble>
              <AssetSvg
                name={getCharacterHeadSvgName(name)}
                height={displayMode === 'digital' ? dimens.height * 0.3 : dimens.height * 0.4}
                style={{ alignSelf: 'center', marginTop: 64 }}
              />
            </View>

            <View style={{ flexDirection: 'row' }}>
              <LabelledRATriangle
                dimens={{ height: dimens.height, width: dimens.width * 0.5 }}
                labels={['', translate.units.numberOfCm(length), translate.units.numberOfCm(hyp)]}
                variation={variation}
              />
            </View>
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aES',
  description: 'aES',
  questionHeight: 900,
  keywords: ['Draw shapes', 'Isosceles', 'Triangles', 'Angles', 'Length'],
  schema: z.object({
    name: nameSchema,
    length: z.number().int().min(3).max(6),
    base: z.number().int().min(4).max(9),
    isCorrect: z.boolean()
  }),
  simpleGenerator: () => {
    const name = getRandomName();
    const length = randomIntegerInclusive(3, 6);
    const base = randomIntegerInclusive(length + 1, 9);
    const isCorrect = getRandomBoolean();
    return { name, length, base, isCorrect };
  },
  Component: props => {
    const {
      question: { name, length, base, isCorrect },
      translate,
      displayMode
    } = props;
    const givenLength = isCorrect ? base : length;
    const labels = [translate.units.numberOfCm(base), translate.units.numberOfCm(length)];

    return (
      <QF38ContentWithSentenceTrueOrFalse
        questionHeight={900}
        title={translate.instructions.isCharacterCorrect(name)}
        pdfTitle={translate.instructions.isCharacterCorrectPDF(name)}
        trueButtonLabel={translate.misc.Yes()}
        falseButtonLabel={translate.misc.No()}
        correctAnswer={isCorrect}
        content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }
            ]}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignSelf: 'stretch',
                justifyContent: 'center'
              }}
            >
              <SpeechBubble
                flickLocation="bottom-right"
                style={{
                  maxWidth: dimens.width * 0.2,
                  maxHeight: dimens.height * 0.5,
                  margin: dimens.width * 0.07,
                  alignSelf: 'flex-start'
                }}
              >
                {translate.answerSentences.iHaveDrawnAnIsoscelesTriangleSoTheRemainingLengthWillBeXCm(
                  givenLength.toLocaleString()
                )}
              </SpeechBubble>
              <AssetSvg
                name={getCharacterHeadSvgName(name)}
                height={displayMode === 'digital' ? dimens.height * 0.3 : dimens.height * 0.4}
                style={{ alignSelf: 'center', marginTop: 64 }}
              />
            </View>
            <LabelledTriangle
              assetSvgName={'Shapes_with_interior_angles/Isosceles_triangle2_interior_angles'}
              dimens={{ width: dimens.width / 2, height: dimens.height }}
              labels={labels}
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aEU',
  description: 'aEU',
  keywords: ['Angles', 'Protractor', 'Measure', 'Polygon'],
  schema: z.object({
    shape: z.enum([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'scalene_triangle1',
      'scalene_triangle2',
      'scalene_triangle3'
    ]),
    algebraicLetter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'isoceles_acute_triangle1',
      'isoceles_acute_triangle2',
      'scalene_triangle1',
      'scalene_triangle2',
      'scalene_triangle3'
    ] as const);

    const algebraicLetter = getAlgebraicSymbol();

    return {
      shape,
      algebraicLetter
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { shape, algebraicLetter } = question;

    const { angleLabels, answer } = ((): {
      angleLabels: string[];
      answer: number;
    } => {
      switch (shape) {
        case 'isoceles_acute_triangle1':
          return {
            angleLabels: ['', algebraicLetter, ''],
            answer: 71
          };
        case 'isoceles_acute_triangle2':
          return {
            angleLabels: ['', algebraicLetter, ''],
            answer: 65
          };
        case 'scalene_triangle1':
          return {
            angleLabels: [algebraicLetter, '', ''],
            answer: 34
          };
        case 'scalene_triangle2':
          return {
            angleLabels: ['', algebraicLetter, ''],
            answer: 48
          };
        case 'scalene_triangle3':
          return {
            angleLabels: ['', algebraicLetter, ''],
            answer: 57
          };
      }
    })();

    return (
      <QF50aDraggableProtractor
        title={translate.instructions.measureTheSizeOfTheAngleXDragTheCircleToMoveTheProtractor(
          algebraicLetter
        )}
        pdfTitle={translate.instructions.measureTheSizeOfTheAngleXYouWillNeedAProtractorToHelpYou(
          algebraicLetter
        )}
        questionHeight={1000}
        shapeImage={
          <LabelledShape
            dimens={{ height: 350, width: displayMode === 'digital' ? 400 : 600 }}
            shapeName={shape}
            angleLabels={angleLabels}
          />
        }
        sentence={`${algebraicLetter} = <ans />°`}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={ans => isInRange(answer - 1, answer + 1)(parseFloat(ans[0]))}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
      />
    );
  },
  questionHeight: 1000
});

const Question5v2 = newQuestionContent({
  uid: 'aEU2',
  description: 'aEU2',
  keywords: ['Right-angled triangle', 'Draw'],
  schema: z.object({
    base: z.number().int().min(2).max(8),
    height: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const base = randomIntegerInclusive(2, 8);

    const height = randomIntegerInclusive(2, 5);

    return { base, height };
  },
  Component: ({ question: { base, height }, translate }) => {
    const baseAndHeightTestCorrect = (userAnswer: Array<{ x: number; y: number }>): boolean => {
      const [point1, point2, point3] = userAnswer;

      // Calculate the distances between points
      const dist1to2 = calculateDistanceBetweenTwoPoints(point1, point2);
      const dist2to3 = calculateDistanceBetweenTwoPoints(point2, point3);
      const dist1to3 = calculateDistanceBetweenTwoPoints(point1, point3);

      const sortedDistances = [dist1to2, dist2to3, dist1to3].sort((a, b) => a - b);

      // The longest distance will be the hypoteneuse of the triangle - the base and height of the RA triangle
      // will be the other two distances.
      const baseOrHeight1 = sortedDistances[0];
      const baseOrHeight2 = sortedDistances[1];

      return (
        (baseOrHeight1 === base && baseOrHeight2 === height) ||
        (baseOrHeight2 === base && baseOrHeight1 === height)
      );
    };

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.instructions.tapDotsToDrawRightAngledTriangleWithBaseXAndHeightY(
          base,
          height
        )}
        pdfTitle={translate.instructions.tapDotsToDrawRightAngledTriangleWithBaseXAndHeightYPDF(
          base,
          height
        )}
        numPoints={3}
        cellSizeLabel={translate.units.numberOfCm(1)}
        closeShape
        testCorrect={userAnswer => {
          const [point1, point2, point3] = userAnswer;
          if (!point1 || !point2 || !point3) return false;
          return (
            isValidRightAngledTriangle(
              [point1.x, point1.y],
              [point2.x, point2.y],
              [point3.x, point3.y]
            ) && baseAndHeightTestCorrect(userAnswer)
          );
        }}
        questionHeight={900}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptValidVerticesForShape() }}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aEV',
  description: 'aEV',
  keywords: ['Draw shapes', 'Triangles', 'Angles', 'Right angles'],
  schema: z.object({
    name: nameSchema,
    angle: z
      .number()
      .int()
      .min(25)
      .max(55)
      .refine(x => x !== 45, 'Angle should not be 45'),
    variation: z.enum(['E', 'F'])
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusiveStep(25, 55, 5, { constraint: x => x !== 45 });
    const name = getRandomName();
    const variation = getRandomFromArray(['E', 'F'] as const);

    return {
      name,
      angle,
      variation
    };
  },
  Component: ({ question, translate }) => {
    const { name, angle, variation } = question;

    const answer = 90 - angle;

    const angleLabels =
      angle > 45
        ? [translate.units.numberOfDegrees(angle), ALGEBRAIC_B]
        : [ALGEBRAIC_B, translate.units.numberOfDegrees(angle)];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.xHasDrawnARATriangleWhatIsTheSizeOfMissingAngle(name)}
        sentence={`${ALGEBRAIC_B} = ${translate.answerSentences.ansDeg()}`}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{
          justifyContent: 'flex-end'
        }}
        questionHeight={900}
        Content={({ dimens }) => (
          <LabelledRATriangle dimens={dimens} variation={variation} angleLabels={angleLabels} />
        )}
        sentenceStyle={{
          justifyContent: 'flex-end'
        }}
      />
    );
  },
  questionHeight: 900
});

const Question6v2 = newQuestionContent({
  uid: 'aEV2',
  description: 'aEV2',
  keywords: ['Isosceles triangle', 'Draw'],
  schema: z.object({
    base: z.number().int().min(2).max(6).multipleOf(2),
    height: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const base = randomIntegerInclusiveStep(2, 6, 2);

    const height = randomIntegerInclusive(1, 5);

    return { base, height };
  },
  Component: ({ question: { base, height }, translate }) => {
    const shape = (() => {
      if (base === height) {
        return 'Shapes_with_arrows/Isosceles_triangle_with_arrows_equal';
      } else if (base < height) {
        return 'Shapes_with_arrows/Isosceles_triangle_with_arrows_tall';
      } else {
        return 'Shapes_with_arrows/Isosceles_triangle_with_arrows_short';
      }
    })();

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.instructions.tapDotsToDrawShapeInDiagramAccuratelyOnGrid()}
        pdfTitle={translate.instructions.drawShapeInDiagramAccuratelyOnGrid()}
        numPoints={3}
        cellSizeLabel={translate.units.numberOfCm(1)}
        squareDottedWidth={6}
        elementToLeft={
          <MeasureView>
            {dimens => (
              <LabelledShape
                dimens={{
                  width: dimens.width * 0.9,
                  height: dimens.height
                }}
                shapeName={shape}
                labels={[translate.units.numberOfCm(height), translate.units.numberOfCm(base)]}
              />
            )}
          </MeasureView>
        }
        closeShape
        testCorrect={userAnswer => {
          const [point1, point2, point3] = userAnswer;
          if (!point1 || !point2 || !point3) return false;
          return (
            isValidIsoscelesTriangle(
              [point1.x, point1.y],
              [point2.x, point2.y],
              [point3.x, point3.y]
            ) &&
            // Height and base are equal to the max of the x values - min of the x values, and max of the y values - min of the y values.
            // Acceptable in any orientation, so can be in either order:
            arraysHaveSameContentsUnordered(
              [
                Math.max(point1.x, point2.x, point3.x) - Math.min(point1.x, point2.x, point3.x),
                Math.max(point1.y, point2.y, point3.y) - Math.min(point1.y, point2.y, point3.y)
              ],
              [height, base]
            )
          );
        }}
        questionHeight={800}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptValidVerticesForShape() }}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawShapesAccurately',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question2, Question5, Question6]
});
export default SmallStep;
