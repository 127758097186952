import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  algebraicSymbolSchema,
  algebraicSymbols,
  getAlgebraicSymbol
} from '../../../../utils/algebraicSymbols';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ALGEBRAIC_X, DEGREES } from '../../../../constants';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { all, create, number } from 'mathjs';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { ShapeNames } from '../../../../utils/labelPositions';
import { compareFloats } from '../../../../utils/math';
import { sortNumberArray } from '../../../../utils/collections';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aEs',
  description: 'aEs',
  keywords: ['Triangles', 'Angles', 'Isosceles', 'Scalene', 'Straight lines'],
  schema: z.object({
    selection: z
      .object({
        sentence: z.enum([
          'inIsosTriangleTwoAnglesAreEqual',
          'anglesInTriangleSumTo180',
          'verticallyOppositeAnglesAreEqual',
          'anglesOnStraightLineSumTo180',
          'anglesAroundAPointSumTo360'
        ]),
        svgName: z.string()
      })
      .array()
      .length(3)
  }),
  simpleGenerator: () => {
    const selection = getRandomSubArrayFromArray(
      [
        {
          sentence: 'inIsosTriangleTwoAnglesAreEqual',
          svgName: 'Other_shapes/Isosceles_triangle_angles_card'
        },
        { sentence: 'anglesInTriangleSumTo180', svgName: 'Other_shapes/Interior_angles_card' },
        {
          sentence: 'verticallyOppositeAnglesAreEqual',
          svgName: 'Other_shapes/Vertically_opposite_angles_card'
        },
        {
          sentence: 'anglesOnStraightLineSumTo180',
          svgName: 'Other_shapes/Angles_on_a_straight_line'
        },
        { sentence: 'anglesAroundAPointSumTo360', svgName: 'Other_shapes/4_angles_around_a_point' }
      ] as const,
      3
    );

    return { selection };
  },
  Component: ({ question: { selection }, translate, displayMode }) => {
    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 100, width: 325 } : { height: 150, width: 450 };

    const statements = selection.map(val => ({
      lhsComponent: (
        <AssetSvg name={val.svgName as SvgName} height={dimens.height} width={dimens.width} />
      ),
      correctAnswer: translate.answerSentences[val.sentence]()
    }));

    const shuffledStatements = shuffle(statements, { random: seededRandom({ selection }) });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchRuleToDiagram()}
        pdfTitle={translate.instructions.useCardsToMatchRuleToDiagram()}
        items={items}
        itemsMaxLines={displayMode === 'digital' ? 2 : 3}
        statementStyle={{ justifyContent: 'center' }}
        statements={shuffledStatements}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

/** Archived */
const Question2 = newQuestionContent({
  uid: 'aEx',
  description: 'aEx',
  keywords: [
    'Triangles',
    'Angles',
    'Scalene',
    'Straight lines',
    'Reflex',
    'Isosceles',
    'Calculate'
  ],
  schema: z.object({
    algebraicLetter: algebraicSymbolSchema,
    triangle: z.enum([
      'Scalene_Isosceles_joined_triangle1',
      'Scalene_Isosceles_joined_triangle2',
      'Scalene_Isosceles_joined_triangle3',
      'Scalene_Isosceles_joined_triangle4'
    ]),
    angleA: z
      .number()
      .int()
      .min(135)
      .max(155)
      .refine(angleA => angleA % 10 !== 0, 'angleA cannot be a multiple of 10'),
    angleB: z
      .number()
      .int()
      .min(19)
      .max(39)
      .refine(angleA => angleA % 5 !== 0, 'angleB cannot be a multiple of 5'),
    answer: z.number().int().min(85).max(105)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const algebraicLetter = getAlgebraicSymbol();
    const triangle = getRandomFromArray([
      'Scalene_Isosceles_joined_triangle1',
      'Scalene_Isosceles_joined_triangle2',
      'Scalene_Isosceles_joined_triangle3',
      'Scalene_Isosceles_joined_triangle4'
    ] as const);

    const { angleA, angleB, answer } = rejectionSample(
      () => {
        const angleA = randomIntegerInclusive(135, 155, { constraint: x => x % 10 !== 0 });
        const angleB = randomIntegerInclusive(19, 39, {
          constraint: x => x % 5 !== 0
        });

        const var3 = number(math.evaluate(`180 - (${angleB} * 2)`));

        const var4 = number(math.evaluate(`180 - ${var3}`));

        const var5 = number(math.evaluate(`180 - ${angleA}`));

        const answer = number(math.evaluate(`180 - (${var4} + ${var5})`));

        return { angleA, angleB, answer };
      },
      ({ answer }) => answer >= 85 && answer <= 105
    );

    return { angleA, angleB, algebraicLetter, triangle, answer };
  },
  Component: props => {
    const {
      question: { angleA, angleB, algebraicLetter, triangle, answer },
      translate
    } = props;

    const labels =
      triangle === 'Scalene_Isosceles_joined_triangle2' ||
      triangle === 'Scalene_Isosceles_joined_triangle3'
        ? [
            translate.units.numberOfDegrees(angleB),
            algebraicLetter,
            translate.units.numberOfDegrees(angleA)
          ]
        : [
            translate.units.numberOfDegrees(angleA),
            algebraicLetter,
            translate.units.numberOfDegrees(angleB)
          ];

    return (
      <QF1ContentAndSentence
        sentence={`${algebraicLetter} = <ans/> ${DEGREES}`}
        inputMaxCharacters={4}
        title={translate.instructions.workOutTheSizesOfTheUnknownAngle()}
        testCorrect={[answer.toString()]}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => {
          return (
            <LabelledShape
              dimens={{ width: dimens.width, height: dimens.height }}
              shapeName={triangle as ShapeNames}
              angleLabels={labels}
            />
          );
        }}
        customMarkSchemeAnswer={{ answersToDisplay: [answer.toLocaleString()] }}
        pdfDirection="column"
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aEx2',
  description: 'aEx',
  keywords: ['Triangles', 'Angles', 'Calculate', 'Decimals'],
  schema: z.object({
    angle1: z
      .number()
      .min(31)
      .max(99)
      .refine(x => x % 10 !== 0, 'angle should not be a multiple of 10'),
    angle2: z
      .number()
      .min(11)
      .max(49)
      .refine(x => x % 5 !== 0, 'angle should not be a multiple of 5'),
    angle3: z.number().min(32).max(138),
    triangle: z.enum(['scalene_triangle1', 'scalene_triangle2', 'scalene_triangle3'])
  }),
  simpleGenerator: () => {
    const triangle = getRandomFromArray([
      'scalene_triangle1',
      'scalene_triangle2',
      'scalene_triangle3'
    ] as const);

    const { angle1, angle2, angle3 } = rejectionSample(
      () => {
        const angle1 = randomIntegerInclusive(31, 99, { constraint: x => x % 10 !== 0 });
        const angle2 = randomIntegerInclusive(11, 49, { constraint: x => x % 5 !== 0 });
        const angle3 = 180 - angle1 - angle2;
        return { angle1, angle2, angle3 };
      },
      val =>
        triangle === 'scalene_triangle1' || triangle === 'scalene_triangle2'
          ? val.angle1 > 90 || val.angle2 > 90 || val.angle3 > 90
          : triangle === 'scalene_triangle3'
          ? val.angle1 < 90 && val.angle2 < 90 && val.angle3 < 90
          : true
    );

    return { angle1, angle2, angle3, triangle };
  },
  Component: props => {
    const {
      question: { angle1, angle2, angle3, triangle },
      translate
    } = props;

    const labels = sortNumberArray([angle1, angle2, angle3], 'ascending').map(deg =>
      deg === angle3 ? ALGEBRAIC_X : translate.units.numberOfDegrees(deg)
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        Content={({ dimens }) => (
          <LabelledShape
            dimens={dimens}
            shapeName={triangle}
            angleLabels={labels}
            unknownLargerFontIndex={[labels.indexOf(ALGEBRAIC_X)]}
          />
        )}
        inputMaxCharacters={3}
        sentence={`${ALGEBRAIC_X} = ${translate.answerSentences.ansDeg()}`}
        testCorrect={[angle3.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aEt',
  description: 'aEt',
  keywords: ['Triangles', 'Angles', 'Vertically opposite', 'Calculate'],
  schema: z.object({
    algebraicLetterA: algebraicSymbolSchema,
    algebraicLetterB: algebraicSymbolSchema,
    angleA: z
      .number()
      .int()
      .min(29)
      .max(74)
      .refine(angleA => angleA % 5 !== 0, 'angleA cannot be a multiple of 5'),
    angleB: z
      .number()
      .int()
      .min(59)
      .max(84)
      .refine(angleB => angleB % 5 !== 0, 'angleB cannot be a multiple of 5'),
    triangle: z.enum([
      'Scalene_triangle1',
      'Scalene_triangle2',
      'Scalene_triangle3',
      'Scalene_triangle4'
    ])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [algebraicLetterA, algebraicLetterB] = getRandomSubArrayFromArray(algebraicSymbols, 2);

    const triangle = getRandomFromArray([
      'Scalene_triangle1',
      'Scalene_triangle2',
      'Scalene_triangle3',
      'Scalene_triangle4'
    ] as const);

    const { angleA, angleB } = ((): { angleA: number; angleB: number } => {
      switch (triangle) {
        case 'Scalene_triangle1': {
          const angleA = randomIntegerInclusive(54, 74, { constraint: x => x % 5 !== 0 });
          const angleB = randomIntegerInclusive(61, 81, {
            constraint: x => x > angleA && x % 5 !== 0
          });
          return { angleA, angleB };
        }
        case 'Scalene_triangle2': {
          const angleA = randomIntegerInclusive(29, 49, { constraint: x => x % 5 !== 0 });
          const angleB = randomIntegerInclusive(64, 84, {
            constraint: x => x % 5 !== 0
          });
          return { angleA, angleB };
        }
        case 'Scalene_triangle3': {
          const angleA = randomIntegerInclusive(41, 61, { constraint: x => x % 5 !== 0 });
          const angleB = randomIntegerInclusive(59, 79, {
            constraint: x => x > angleA && x % 5 !== 0
          });
          return { angleA, angleB };
        }
        default: {
          const angleA = randomIntegerInclusive(38, 58, {
            constraint: x => x % 5 !== 0
          });
          const angleB = randomIntegerInclusive(64, 84, { constraint: x => x % 5 !== 0 });
          return { angleA, angleB };
        }
      }
    })();

    return { angleA, angleB, triangle, algebraicLetterA, algebraicLetterB };
  },
  Component: props => {
    const {
      question: { angleA, angleB, triangle, algebraicLetterA, algebraicLetterB },
      translate
    } = props;

    const answerB = number(math.evaluate(`180 - (${angleA} + ${angleB})`));

    const labels =
      triangle === 'Scalene_triangle2' || triangle === 'Scalene_triangle3'
        ? [
            translate.units.numberOfDegrees(angleB),
            translate.units.numberOfDegrees(angleA),
            algebraicLetterA,
            algebraicLetterB
          ]
        : [
            translate.units.numberOfDegrees(angleA),
            translate.units.numberOfDegrees(angleB),
            algebraicLetterA,
            algebraicLetterB
          ];

    const answerA =
      triangle === 'Scalene_triangle2' || triangle === 'Scalene_triangle3'
        ? angleB.toString()
        : angleA.toString();

    return (
      <QF1ContentAndSentences
        sentences={[
          `${algebraicLetterA} = <ans/> ${DEGREES}`,
          `${algebraicLetterB} = <ans/> ${DEGREES}`
        ]}
        title={translate.instructions.workOutTheSizesOfTheUnknownAngles()}
        testCorrect={[[answerA.toString()], [answerB.toString()]]}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-around' }}
        style={{ flexDirection: 'row' }}
        Content={({ dimens }) => {
          return (
            <LabelledShape
              dimens={{ width: dimens.width, height: dimens.height }}
              shapeName={triangle as ShapeNames}
              angleLabels={labels}
              hasMinLabelWidth
            />
          );
        }}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aEu',
  description: 'aEu',
  keywords: ['Triangles', 'Angles', 'Straight lines', 'Calculate'],
  schema: z.object({
    number1: z.number().int().min(31).max(79),
    number2: z.number().int().min(101).max(149),
    letter: algebraicSymbolSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(31, 79, { constraint: x => x % 10 !== 0 });
    const number2 = randomIntegerInclusive(101, 149, {
      constraint: x => x % 10 !== 0 && 180 - x !== number1 && number1 + (180 - x) !== 90
    });
    const letter = getAlgebraicSymbol();

    return { number1, number2, letter };
  },
  Component: props => {
    const {
      question: { number1, number2, letter },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`${letter} = <ans /> ${DEGREES}`}
        title={translate.instructions.workOutSizeOfUnknownAngle()}
        testCorrect={[(180 - (number1 + (180 - number2))).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <>
            <AssetSvg
              name="Other_shapes/Triangle5_line_extension_3_angles"
              height={dimens.height * 0.8}
            />
            <Text
              variant="WRN400"
              style={{
                position: 'absolute',
                left: dimens.width * (displayMode === 'digital' ? 0.32 : 0.28),
                top: dimens.height * 0.75,
                fontSize: displayMode === 'digital' ? 28 : 40
              }}
            >
              {letter}
            </Text>
            <Text
              variant="WRN400"
              style={{
                position: 'absolute',
                left: dimens.width * (displayMode === 'digital' ? 0.66 : 0.7),
                top: dimens.height * 0.75,
                fontSize: displayMode === 'digital' ? 28 : 40
              }}
            >
              {`${number2.toLocaleString()} ${DEGREES}`}
            </Text>
            <Text
              variant="WRN400"
              style={{
                position: 'absolute',
                left: dimens.width * (displayMode === 'digital' ? 0.42 : 0.395),
                top: dimens.height * 0.3,
                fontSize: displayMode === 'digital' ? 28 : 40
              }}
            >
              {`${number1.toLocaleString()} ${DEGREES}`}
            </Text>
          </>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aEv',
  description: 'aEv',
  keywords: ['Triangles', 'Angles', 'Isosceles', 'Reflex', 'Calculate'],
  schema: z.object({
    algebraicLetter: algebraicSymbolSchema,
    triangle: z.enum(['Isosceles_triangle1', 'Isosceles_triangle2']),
    angleA: z
      .number()
      .int()
      .min(48)
      .max(68)
      .refine(angleA => angleA % 5 !== 0, 'angleA cannot be a multiple of 5')
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const angleA = randomIntegerInclusive(48, 68, { constraint: x => x % 5 !== 0 });
    const algebraicLetter = getAlgebraicSymbol();
    const triangle = getRandomFromArray(['Isosceles_triangle1', 'Isosceles_triangle2'] as const);

    return { angleA, algebraicLetter, triangle };
  },
  Component: props => {
    const {
      question: { angleA, algebraicLetter, triangle },
      translate
    } = props;

    const answer = number(math.evaluate(`360 - (180 - (${angleA} * 2))`));

    return (
      <QF1ContentAndSentence
        sentence={`${algebraicLetter} = <ans/> ${DEGREES}`}
        title={translate.instructions.workOutTheSizesOfTheUnknownAngle()}
        testCorrect={[answer.toString()]}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          return (
            <LabelledShape
              dimens={{ width: dimens.width, height: dimens.height }}
              shapeName={triangle as ShapeNames}
              angleLabels={[algebraicLetter, translate.units.numberOfDegrees(angleA)]}
            />
          );
        }}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aEw',
  description: 'aEw',
  keywords: [
    'Triangles',
    'Angles',
    'Scalene',
    'Straight lines',
    'Vertically opposite',
    'Calculate',
    'Decimals'
  ],
  schema: z
    .object({
      algebraicLetter: algebraicSymbolSchema,
      triangle: z.enum([
        'Scalene_triangle5',
        'Scalene_triangle6',
        'Scalene_triangle7',
        'Scalene_triangle8'
      ]),
      angleA: z
        .number()
        .min(96)
        .max(124.9)
        .refine(angleA => angleA % 5 !== 0, 'angleA cannot be a mulitple of 5'),
      angleB: z
        .number()
        .min(108)
        .max(158.9)
        .refine(angleB => angleB % 5 !== 0, 'angleB cannot be a mulitple of 5')
    })
    .refine(val => val.angleA !== val.angleB, 'angleA cannot equal angleB'),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA, numberB] = randomUniqueIntegersInclusive(1, 9, 2);

    const algebraicLetter = getAlgebraicSymbol();
    const triangle = getRandomFromArray([
      'Scalene_triangle5',
      'Scalene_triangle6',
      'Scalene_triangle7',
      'Scalene_triangle8'
    ] as const);

    const { angleA, angleB } = rejectionSample(
      () => {
        const { wholeAngleA, wholeAngleB } = ((): { wholeAngleA: number; wholeAngleB: number } => {
          switch (triangle) {
            case 'Scalene_triangle5': {
              const wholeAngleA = randomIntegerInclusive(97, 117, { constraint: x => x % 5 !== 0 });
              const wholeAngleB = randomIntegerInclusive(114, 134, {
                constraint: x => x % 5 !== 0 && x > wholeAngleA
              });
              return { wholeAngleA, wholeAngleB };
            }
            case 'Scalene_triangle6': {
              const wholeAngleA = randomIntegerInclusive(101, 121, {
                constraint: x => x % 5 !== 0
              });
              const wholeAngleB = randomIntegerInclusive(138, 158, {
                constraint: x => x % 5 !== 0
              });
              return { wholeAngleA, wholeAngleB };
            }
            case 'Scalene_triangle7': {
              const wholeAngleA = randomIntegerInclusive(96, 116, { constraint: x => x % 5 !== 0 });
              const wholeAngleB = randomIntegerInclusive(108, 128, {
                constraint: x => x % 5 !== 0 && x > wholeAngleA
              });
              return { wholeAngleA, wholeAngleB };
            }
            default: {
              const wholeAngleA = randomIntegerInclusive(104, 124, {
                constraint: x => x % 5 !== 0
              });
              const wholeAngleB = randomIntegerInclusive(136, 156, {
                constraint: x => x % 5 !== 0 && x > wholeAngleA
              });
              return { wholeAngleA, wholeAngleB };
            }
          }
        })();

        const angleA = number(math.evaluate(`${wholeAngleA} + (${numberA} / 10)`));
        const angleB = number(math.evaluate(`${wholeAngleB} + (${numberB} / 10)`));

        const answer = number(math.evaluate(`180 - (${180 - angleA} + ${180 - angleB} )`));

        return { angleA, angleB, answer };
      },
      ({ answer }) => answer < 90
    );

    return { angleA, angleB, algebraicLetter, triangle, numberA, numberB };
  },
  Component: props => {
    const {
      question: { angleA, angleB, algebraicLetter, triangle },
      translate
    } = props;

    const answer = number(math.evaluate(`180 - (${180 - angleA} + ${180 - angleB} )`));

    const labels =
      triangle === 'Scalene_triangle6'
        ? [
            translate.units.numberOfDegrees(angleB),
            algebraicLetter,
            translate.units.numberOfDegrees(angleA)
          ]
        : [
            translate.units.numberOfDegrees(angleA),
            algebraicLetter,
            translate.units.numberOfDegrees(angleB)
          ];

    return (
      <QF1ContentAndSentence
        sentence={`${algebraicLetter} = <ans/> ${DEGREES}`}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        title={translate.instructions.workOutTheSizesOfTheUnknownAngle()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => {
          return (
            <LabelledShape
              dimens={{ width: dimens.width, height: dimens.height }}
              shapeName={triangle as ShapeNames}
              angleLabels={labels}
              hasMinLabelWidth
            />
          );
        }}
        customMarkSchemeAnswer={{ answersToDisplay: [answer.toLocaleString()] }}
        pdfDirection="column"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AnglesInATriangleMissingAngles',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
