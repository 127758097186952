import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import QF67SelectingRandomlyArrangedShapes from '../../../../components/question/questionFormats/QF67SelectingRandomlyArrangedShapes';
import { filledArray } from '../../../../utils/collections';
import QF68DragSvgsIntoGroups from '../../../../components/question/questionFormats/QF68DragSvgsIntoGroups';
import Counter from '../../../../assets/svg/Counter';

////
// Questions
////

const objectImages = {
  ApplesGreen: 'Array_objects/AppleGreen',
  ApplesRed: 'Array_objects/AppleRed',
  Oranges: 'Array_objects/Orange',
  Pears: 'Array_objects/Pear',
  Pineapples: 'Array_objects/Pineapple',
  Strawberries: 'Array_objects/Strawberry',
  Bananas: 'Array_objects/Banana',
  Counters: 'CounterRed'
} as const;

const Question1 = newQuestionContent({
  uid: 'bet',
  description: 'bet',
  keywords: ['Equal groups', 'Group', 'Half'],
  schema: z.object({
    items: z.enum([
      'ApplesGreen',
      'ApplesRed',
      'Oranges',
      'Pears',
      'Pineapples',
      'Strawberries',
      'Bananas',
      'Counters'
    ]),
    total: z.number().int().min(6).max(20).multipleOf(2)
  }),
  simpleGenerator: () => {
    const items = getRandomFromArray([
      'ApplesGreen',
      'ApplesRed',
      'Oranges',
      'Pears',
      'Pineapples',
      'Strawberries',
      'Bananas',
      'Counters'
    ] as const);

    const total = randomIntegerInclusiveStep(6, 20, 2);

    return { items, total };
  },
  Component: props => {
    const {
      question: { items, total },
      translate
    } = props;

    const svgName = objectImages[items];
    const objectName =
      items === 'ApplesGreen' || items === 'ApplesRed'
        ? translate.objects.Apples()
        : items === 'Counters'
        ? translate.objects.Counters(total)
        : translate.objects[items]();

    return (
      <QF68DragSvgsIntoGroups
        title={translate.ks1Instructions.dragTheObjectToShareThemIntoXEqualGroups(objectName, 2)}
        pdfTitle={translate.ks1PDFInstructions.drawNumCountersSharedIntoXEqualGroups(total, 2)}
        numberOfGroups={2}
        draggableItem={svgName}
        draggableCount={total}
        testCorrect={filledArray(total / 2, 2)}
        maxCapacity={15}
        pdfComponent={<Counter color="red" />}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'beu',
  description: 'beu',
  keywords: ['Whole', 'Half', 'Group'],
  schema: z.object({
    numberOfCircles: z.number().int().min(2).max(10).step(2),
    randomSeed: z.number().int().min(1).max(1000),
    isRandom: z.boolean()
  }),
  simpleGenerator: () => {
    const numberOfCircles = randomIntegerInclusiveStep(2, 10, 2);
    const randomSeed = randomIntegerInclusive(1, 1000);
    const isRandom = getRandomBoolean();

    return { numberOfCircles, isRandom, randomSeed };
  },
  Component: props => {
    const {
      question: { numberOfCircles, isRandom, randomSeed },
      translate
    } = props;

    const randomArrangement = getRandomBooleanArray(
      4,
      4,
      numberOfCircles,
      seededRandom({ randomSeed })
    );

    return (
      <QF67SelectingRandomlyArrangedShapes
        title={translate.ks1Instructions.tapToShadeHalfOfTheCircles()}
        pdfTitle={translate.ks1PDFInstructions.shadeHalfOfTheCircles()}
        shapesArray={filledArray({ shape: 'Circle' }, numberOfCircles)}
        testCorrect={filledArray('Circle', numberOfCircles / 2)}
        arrangement={
          isRandom ? randomArrangement : filledArray(filledArray(true, numberOfCircles / 2), 2)
        }
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptAnyValidAnswer() }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bev',
  description: 'bev',
  keywords: ['Equal groups', 'Group'],
  schema: z.object({
    rows: z.number().int().min(2).max(5),
    columns: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 5);

    const columns = randomIntegerInclusive(2, 10, {
      constraint: x => x * rows >= 6 && x * rows <= 20 && (x * rows) % 2 === 0
    });

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate
    } = props;

    const total = rows * columns;

    const answer = total / 2;

    return (
      <QF1ContentAndSentence
        sentence={translate.ks1AnswerSentences.halfOfXIsAns(rows * columns)}
        title={translate.ks1Instructions.completeTheSentenceUseTheArrayToHelpYou()}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} />}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAHalfOfAQuantity',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
