import { newSmallStepContent } from '../../../SmallStep';
import {
  aiI,
  aiG,
  aiJ,
  aiK,
  aiL
} from '../../../Year 4/Autumn/AdditionAndSubtraction/10CheckingStrategies';
import { newQuestionContent } from '../../../Question';
import {
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { ADD, SUB } from '../../../../constants';
import { useMemo } from 'react';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1: typeof aiG = { ...aiG, uid: 'aja', description: 'aja' };

const Question2 = newQuestionContent({
  uid: 'ajb',
  description: 'ajb',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Match'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(9890).multipleOf(10),
      numberA2: z.number().int().min(100).max(9890).multipleOf(10),
      numberB1: z.number().int().min(100).max(9890).multipleOf(10),
      numberB2: z.number().int().min(100).max(9890).multipleOf(10),
      numberC1: z.number().int().min(100).max(9890).multipleOf(10)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.numberA1,
          val.numberA2,
          val.numberB1,
          val.numberB2,
          val.numberC1
        ]),
      'All numbers should be different.'
    )
    .refine(
      val => val.numberA1 + val.numberA2 < 10000,
      'numberA1 + numberA2 must be less than 10,000'
    )
    .refine(
      val => val.numberB1 + val.numberB2 < 10000,
      'numberB1 + numberB2 must be less than 10,000'
    )
    .refine(
      val => val.numberC1 + val.numberB2 < 10000,
      'numberD1 + numberB2 must be less than 10,000'
    )
    .refine(
      val => val.numberA1 + val.numberA2 === val.numberC1 + val.numberB2,
      'numberA1 + numberA2 must total to the same as numberC1 + numberB2'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2, numberB1, numberB2, numberC1 } = rejectionSample(
      () => {
        // Get a1+a2 < 10,000
        const sumA = randomIntegerInclusiveStep(310, 9990, 10);
        const numberA1 = randomIntegerInclusiveStep(100, sumA - 100, 10);
        const numberA2 = sumA - numberA1;

        // Get b1+b2 < 10,000
        const sumB = randomIntegerInclusiveStep(310, 9990, 10);
        const numberB1 = randomIntegerInclusiveStep(100, sumB - 100, 10);
        const numberB2 = sumB - numberB1;

        const numberC1 = numberA1 + numberA2 - numberB2;

        return { numberA1, numberA2, numberB1, numberB2, numberC1 };
      },
      ({ numberA1, numberA2, numberB1, numberB2, numberC1 }) =>
        numberC1 >= 100 &&
        numberC1 <= 9890 &&
        arrayHasNoDuplicates([numberA1, numberA2, numberB1, numberB2, numberC1])
    );

    return { numberA1, numberA2, numberB1, numberB2, numberC1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1 },
      translate,
      displayMode
    } = props;

    // Some of these numbers are just duplicates of others - created here to make code further down more readable.
    const numberA3 = numberA1 + numberA2;
    const numberB3 = numberB1 + numberB2;
    const numberC2 = numberB2;
    const numberC3 = numberC1 + numberC2;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: `${numberA1.toLocaleString()} ${ADD} ${numberA2.toLocaleString()} = ${numberA3.toLocaleString()}`,
        value: 'A'
      };
      const statement3 = {
        statement: `${numberB3.toLocaleString()} ${SUB} ${numberB2.toLocaleString()} = ${numberB1.toLocaleString()}`,
        value: 'B'
      };
      const statement4 = {
        statement: `${numberC3.toLocaleString()} = ${numberC1.toLocaleString()} ${ADD} ${numberC2.toLocaleString()}`,
        value: 'C'
      };
      return shuffle([statement1, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [
      numberA1,
      numberA2,
      numberA3,
      numberB1,
      numberB2,
      numberB3,
      numberC1,
      numberC2,
      numberC3,
      props.question
    ]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchInverseCalcs()}
        pdfTitle={translate.instructions.useCardsMatchInverseCalcs()}
        items={[
          {
            component: (
              <Text
                variant="WRN700"
                style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 24 : 30 }}
              >
                {`${numberA3.toLocaleString()} ${SUB} ${numberA2.toLocaleString()} = ${numberA1.toLocaleString()}`}
              </Text>
            ),
            value: 'A'
          },
          {
            component: (
              <Text
                variant="WRN700"
                style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 24 : 30 }}
              >
                {`${numberB2.toLocaleString()} ${ADD} ${numberB1.toLocaleString()} = ${numberB3.toLocaleString()}`}
              </Text>
            ),
            value: 'B'
          },
          {
            component: (
              <Text
                variant="WRN700"
                style={{ textAlign: 'center', fontSize: displayMode === 'digital' ? 24 : 30 }}
              >
                {`${numberC3.toLocaleString()} ${SUB} ${numberC2.toLocaleString()} = ${numberC1.toLocaleString()}`}
              </Text>
            ),
            value: 'C'
          }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement}        <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        itemMaxLines={1}
        itemsLetterEmWidth={0.5}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = { ...aiI, uid: 'ajc', description: 'ajc' as const };
const Question4 = { ...aiJ, uid: 'ajd', description: 'ajd' as const };
const Question5 = { ...aiK, uid: 'aje', description: 'aje' as const };
const Question6 = { ...aiL, uid: 'ajf', description: 'ajf' as const };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InverseOperationsAdditionAndSubtraction',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
