import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import Text from '../../typography/Text';
import { AssetSvg, SvgName, getSvgInfo } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import { getRandomFromArray, seededRandom } from '../../../utils/random';

type Props = {
  /**
   * @param labels An array of strings to hold the labels that go around the shape.
   * Should be given in form [top, upper-left, upper-right, lower-left, lower-right, bottom]
   */
  labels: string[];
  /**
   * Prop to determine the label in the middle of the shape.
   * Optional prop, defaults to undefined.
   */
  centerLabel?: string;
  /**
   * Prop to decide if a hexagon SVG with all arrows should be displayed.
   * Defaults to false.
   */
  allArrows?: boolean;
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
};
/**
 * This component renders a hexagon with labels on the sides.
 */
export const LabelledHexagon = (props: Props) => {
  const {
    labels,
    dimens: { width },
    centerLabel,
    allArrows
  } = props;
  const displayMode = useContext(DisplayMode);
  const shapeWidth = width * 0.35;
  const longestItemTextLength = labels.reduce((max, item) => Math.max(max, item.length), 0);
  const labelWidth =
    displayMode === 'digital' ? longestItemTextLength * 18 : longestItemTextLength * 25;
  const styles = useStyles(shapeWidth);
  const font = displayMode === 'digital' ? 32 : 40;
  const smallFont = displayMode === 'digital' ? 22 : 32;

  const hexagonSvgPath = getRandomFromArray(
    allArrows
      ? [
          'Shapes_with_dimension_arrows/hexagon_blue_arrows',
          'Shapes_with_dimension_arrows/hexagon_green_arrows',
          'Shapes_with_dimension_arrows/hexagon_orange_arrows',
          'Shapes_with_dimension_arrows/hexagon_purple_arrows',
          'Shapes_with_dimension_arrows/hexagon_red_arrows'
        ]
      : [
          'Hexagons/hexagon_blue',
          'Hexagons/hexagon_green',
          'Hexagons/hexagon_pink',
          'Hexagons/hexagon_purple',
          'Hexagons/hexagon_yellow'
        ],
    {
      random: seededRandom(labels)
    }
  );

  const centerLabelColor =
    hexagonSvgPath === 'Hexagons/hexagon_pink' || hexagonSvgPath === 'Hexagons/hexagon_yellow'
      ? colors.prussianBlue
      : colors.white;

  const { aspectRatio } = getSvgInfo(hexagonSvgPath as SvgName);
  const shapeHeight = shapeWidth / aspectRatio;

  // Get labels for shapes and absolute positions
  const shapeLabels = () => {
    const labelHeight = font * 1.5;
    const style = [
      { top: -labelHeight }, // Top
      { right: shapeWidth * 0.9, bottom: shapeHeight * 0.7 }, // Upper-left
      { left: shapeWidth * 0.9, bottom: shapeHeight * 0.7 }, // Upper-right
      { left: shapeWidth * 0.9, top: shapeHeight * 0.7 }, // Lower-left
      { right: shapeWidth * 0.9, top: shapeHeight * 0.7 }, // Lower-right
      { bottom: -labelHeight } // Bottom
    ];

    return labels.map((_label, index) => {
      return (
        <View key={index} style={[style[index], { position: 'absolute', minWidth: labelWidth }]}>
          <View>
            <Text variant="WRN700" style={{ fontSize: smallFont, textAlign: 'center' }}>
              {labels[index]}
            </Text>
          </View>
        </View>
      );
    });
  };

  return (
    <View>
      <View style={[styles.imageWrapper]}>
        {shapeLabels()}
        <View style={{ position: 'absolute', zIndex: 1 }}>
          {centerLabel && (
            <Text variant="WRN700" style={{ fontSize: font, color: centerLabelColor }}>
              {centerLabel}
            </Text>
          )}
        </View>
        {<AssetSvg name={hexagonSvgPath as SvgName} width={shapeWidth} />}
      </View>
    </View>
  );
};

const useStyles = (width: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageWrapper: {
          alignItems: 'center',
          justifyContent: 'center',
          width: width
        }
      }),
    [width]
  );
};
