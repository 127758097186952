import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF43DraggableLineOnTopOfStaticRuler from '../../../../components/question/questionFormats/QF43DraggableLineOnTopOfStaticRuler';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import { getShapeSvgName } from '../../../../utils/shapeImages/shapes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import QF41MovableLinesForCreatingAngles from '../../../../components/question/questionFormats/QF41MovableLinesForCreatingAngles';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aAc',
  description: 'aAc',
  keywords: ['Draw', 'Length', 'Measure', 'Ruler', 'cm', 'mm'],
  schema: z.object({
    length: z.number().int().min(15).max(145),
    inCm: z.boolean()
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusiveStep(15, 145, 5),
    inCm: getRandomBoolean()
  }),
  Component: ({ question: { length, inCm }, translate }) => {
    const unit = inCm ? 'cm' : 'mm';

    const lengthInCm = length / 10;
    return (
      <QF43DraggableLineOnTopOfStaticRuler
        title={translate.instructions.dragLineSoItIsLengthLong({
          length: (inCm ? lengthInCm : length).toLocaleString(),
          unit: translate.units[unit]()
        })}
        pdfTitle={translate.instructions.dragLineSoItIsLengthLongPdf({
          length: (inCm ? lengthInCm : length).toLocaleString(),
          unit
        })}
        rulerKind={'cm'}
        rulerLength={15}
        testCorrect={lengthInCm}
        snapToNearest={0.5}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAd',
  description: 'aAd',
  keywords: ['Draw', 'Angles', 'Measure', 'Protractor'],
  schema: z.object({
    angle: z
      .number()
      .int()
      .min(10)
      .max(170)
      .step(10)
      .refine(x => x !== 90, 'Angles should be different so cant be 90')
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusiveStep(10, 170, 10, { constraint: x => x !== 90 });

    return { angle };
  },
  Component: ({ question, translate }) => {
    const { angle } = question;

    const angles = shuffle([angle, 180 - angle], { random: seededRandom(question) });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectXAngle(angle)}
        pdfTitle={translate.instructions.circleXAngle(angle)}
        numItems={2}
        renderItems={({ dimens }) => {
          return angles.map(angle => ({
            value: angle,
            component: <AngleFromLines degrees={[-90, angle - 90]} dimens={dimens} protractor />
          }));
        }}
        testCorrect={[angle]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aAe',
  description: 'aAe',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    angle: z.number().int().min(5).max(175).multipleOf(5)
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusiveStep(5, 175, 5);

    return { angle };
  },
  Component: ({ question: { angle }, translate }) => {
    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragLinesToMakeAngleThatIsXDegrees(angle)}
        pdfTitle={translate.instructions.drawAngleThatIsXDegreesYouWillNeedAProtractorToHelpYou(
          angle
        )}
        testCorrect={userAnswer => {
          return userAnswer >= angle - 1 && userAnswer <= angle + 1;
        }}
        startAngles={[90, 90]}
        markScheme={{ exampleCorrectAnswer: [90 - angle, 90] }}
        protractor
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aAe2',
  description: 'aAe',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    angle: z.number().int().min(5).max(175).multipleOf(5),
    startingAngles: z.tuple([z.number().int().min(-90).max(90), z.number().int().min(-90).max(90)])
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusiveStep(5, 175, 5);
    const isBothCirclesSame = getRandomBoolean();

    const startingAngleA = getRandomFromArray([90, -90]);

    // 50% of the time have both angles start on the LHS of the protractor
    const startingAngleB = isBothCirclesSame
      ? startingAngleA
      : getRandomFromArrayWithWeights(
          [
            randomIntegerInclusive(-80, -1, { constraint: x => x !== angle }),
            randomIntegerInclusive(0, 89, { constraint: x => x !== angle })
          ],
          [3, 1]
        );

    const startingAngles = [startingAngleB, startingAngleA] as [number, number];

    return { angle, startingAngles };
  },
  Component: ({ question: { angle, startingAngles }, translate }) => {
    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragLinesToMakeAngleThatIsXDegrees(angle)}
        pdfTitle={translate.instructions.drawAngleThatIsXDegreesYouWillNeedAProtractorToHelpYou(
          angle
        )}
        testCorrect={userAnswer => {
          return userAnswer >= angle - 1 && userAnswer <= angle + 1;
        }}
        smallArmFixed={true}
        startAngles={startingAngles}
        markScheme={{ exampleCorrectAnswer: [90 - angle, 90] }}
        protractor
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aAf',
  description: 'aAf',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    angleStartLhs: z.boolean().optional(),
    angle: z
      .number()
      .int()
      .min(11)
      .max(179)
      .refine(x => x % 5 !== 0, 'Angle should not be multiple of 5')
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(11, 179, { constraint: x => x % 5 !== 0 });
    const angleStartLhs = getRandomBoolean();

    return { angle, angleStartLhs };
  },
  Component: ({ question: { angle, angleStartLhs = false }, translate }) => {
    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragLinesToMakeAngleThatIsXDegrees(angle)}
        pdfTitle={translate.instructions.drawAngleThatIsXDegreesYouWillNeedAProtractorToHelpYou(
          angle
        )}
        testCorrect={userAnswer => {
          return userAnswer >= angle - 1 && userAnswer <= angle + 1;
        }}
        startAngles={angleStartLhs ? [-90, -90] : [90, 90]}
        markScheme={{ exampleCorrectAnswer: [90 - angle, 90] }}
        protractor
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aAg',
  description: 'aAg',
  keywords: ['Inverse', 'Subtraction', 'Check'],
  schema: z.object({
    name: nameSchema,
    squareLength: z.number().int().min(45).max(85).step(5),
    difference: z.number().int().min(-2).max(2)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const name = getRandomName();
    const squareLength = randomIntegerInclusiveStep(45, 85, 10);
    const difference = getRandomBoolean() ? 0 : getRandomFromArray([-2, -1, 1, 2]);

    return { name, squareLength, difference };
  },
  Component: ({ question, translate, displayMode }) => {
    const { name, squareLength, difference } = question;
    const squareLengthInCm = squareLength / 10;
    const perimeter = squareLengthInCm * 4 + difference;

    const square =
      displayMode === 'digital' ? getShapeSvgName('squares', { question }) : 'Square/square_white';
    return (
      <QF38ContentWithSentenceTrueOrFalse
        questionHeight={1000}
        title={translate.instructions.xHasDrawnSquareWithPerimeterYcmMeasureLengthOfOneSideToFindOutIfTrueOrFalse(
          name,
          perimeter
        )}
        pdfTitle={translate.instructions.xHasDrawnSquareWithPerimeterYcmMeasureLengthOfOneSideToFindOutIfTrueOrFalsePDF(
          name,
          perimeter
        )}
        correctAnswer={difference === 0}
        content={({ dimens }) => (
          <ItemsAgainstRuler
            height={dimens.height}
            width={dimens.width}
            rulerKind="cm"
            rulerLength={15}
            items={[{ length: squareLengthInCm, svgInfo: { name: square } }]}
          />
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aAh',
  description: 'aAh',
  keywords: ['Angles', 'Measure', 'Protractor'],
  schema: z.object({
    start: z.number().int().min(5).max(40).multipleOf(5),
    angle: z.number().int().min(10).max(140).multipleOf(5)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const angle = randomIntegerInclusiveStep(10, 140, 5);
    const start = randomIntegerInclusiveStep(5, 40, 5);

    return { start, angle };
  },
  Component: ({ question: { angle, start }, translate }) => {
    return (
      <QF41MovableLinesForCreatingAngles
        title={translate.instructions.dragLineToMakeAngleThatIsXDegrees(angle)}
        pdfTitle={translate.instructions.drawAngleThatIsXDegreesYouWillNeedAProtractorToHelpYou(
          angle
        )}
        testCorrect={userAnswer => {
          return userAnswer >= angle - 1 && userAnswer <= angle + 1;
        }}
        startAngles={[0, 90 - start]}
        markScheme={{ exampleCorrectAnswer: [90 - start - angle, 90 - start] }}
        protractor
        smallArmFixed={true}
      />
    );
  }
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawLinesAndAnglesAccurately',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
