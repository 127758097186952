import { useContext } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../utils/react';
import { ActionPanelVariant } from './BaseLayout';
import { colors } from '../../theme/colors';
import { QuestionNavigationContext } from '../../SchemeOfLearning/Question';

type Props = {
  children?: ElementOrRenderFunction;
  variant?: ActionPanelVariant;
  style?: StyleProp<ViewStyle>;
};

/**
 * Action panel for the right-edge of the screen, containing navigation buttons at the bottom. This one is wide.
 */
export default function ActionPanel({ children, variant = 'end', style }: Props) {
  const { QuizNavigation } = useContext(QuestionNavigationContext);

  const isEndVariant = variant === 'end' || variant === 'endWide' || variant === 'endMid';
  const isThinVariant =
    variant === 'bottom' ||
    variant === 'bottomTallCircleCont' ||
    variant === 'end' ||
    variant === 'endMid';

  return (
    <View
      style={[styles.container, isEndVariant ? styles.containerEnd : styles.containerBottom, style]}
    >
      <View style={[styles.content, isEndVariant ? styles.contentEnd : styles.contentBottom]}>
        {resolveElementOrRenderFunction(children)}
      </View>
      <View
        style={isThinVariant ? styles.navigationContainerThin : styles.navigationContainerNormal}
      >
        {resolveElementOrRenderFunction(QuizNavigation, {
          variant: isThinVariant ? 'thin' : 'normal'
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // Subtract the border width from the padding.
    // For some reason Figma places the border within the padding, which isn't how CSS works.
    padding: 35 - 3,
    backgroundColor: colors.greyMatter,
    borderWidth: 3,
    borderColor: colors.prussianBlue
  },
  containerEnd: {
    flexDirection: 'column',
    borderLeftWidth: 0,
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24
  },
  containerBottom: {
    flexDirection: 'row',
    borderTopWidth: 0,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24
  },

  content: {
    flex: 1
  },
  contentEnd: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  },
  contentBottom: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },

  navigationContainerThin: {
    alignSelf: 'center'
  },
  navigationContainerNormal: {
    alignSelf: 'flex-end'
  }
});
