import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { colors } from '../../../../theme/colors';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import {
  isValidIsoscelesTriangle,
  isValidRectangle,
  isValidRightAngledTriangle,
  isValidSquare
} from '../../../../utils/shapes';
import { arraysHaveSameContents, sortNumberArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'azw',
  description: 'azw',
  keywords: ['Coordinate', 'Coordinate grid', 'Rectangle', 'Vertex'],
  schema: z.object({
    x1: z.number().int().min(0).max(5),
    x2: z.number().int().min(0).max(5),
    y1: z.number().int().min(0).max(5),
    y2: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const [x1, x2] = randomUniqueIntegersInclusive(0, 5, 2);
    const y1 = randomIntegerInclusive(0, 5);
    // need to ensure that if x1 and x2 are only one apart then we have y1 and y2 at least 2 apart
    const y2 = randomIntegerInclusive(0, 5, {
      constraint: y => (Math.abs(x2 - x1) === 1 && Math.abs(y - y1) > 1) || Math.abs(y - y1) > 0
    });

    return { x1, x2, y1, y2 };
  },
  Component: ({ question: { x1, x2, y1, y2 }, translate, theme, displayMode }) => {
    const coordinates = [
      [x1, y1] as [number, number],
      [x1, y2] as [number, number],
      [x2, y2] as [number, number]
    ];

    const correctAnswer = [x2, y1] as [number, number];
    const isSquare = Math.abs(y1 - y2) === Math.abs(x1 - x2);

    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToCompleteVerticesOfShape(
                1,
                isSquare ? translate.shapes.aSquare() : translate.shapes.aRectangle()
              )
            : translate.instructions.drawCrossToCompleteVerticesOfAShape(
                isSquare ? translate.shapes.aSquare() : translate.shapes.aRectangle()
              )
        }
        testCorrect={ans => arraysHaveSameContents(ans[0], correctAnswer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer]
        }}
        gridProps={{
          xMax: 5,
          yMax: 5,
          squareGrid: true
        }}
        snapToGrid
        gridChildren={coordinates.map((val, i) => (
          <GridImage
            key={i}
            mathCoord={val}
            item={{
              component:
                displayMode === 'digital'
                  ? 'Coordinates/CirclePointCustomizable'
                  : 'Coordinates/CrossPointCustomizable',
              svgProps: { fill: colors.pacificBlue }
            }}
          />
        ))}
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'azx',
  description: 'azx',
  keywords: ['Coordinate', 'Coordinate grid', 'Triangle', 'Vertex'],
  schema: z.object({
    x1: z.number().int().min(0).max(5),
    x2: z.number().int().min(0).max(5),
    y1: z.number().int().min(0).max(5),
    y2: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const [x1, x2] = randomUniqueIntegersInclusive(0, 5, 2);
    const [y1, y2] = randomUniqueIntegersInclusive(0, 5, 2);

    return { x1, x2, y1, y2 };
  },
  Component: ({ question: { x1, x2, y1, y2 }, translate, theme, displayMode }) => {
    const coordinates = [[x1, y1] as [number, number], [x2, y2] as [number, number]];

    const examplePoint = [x1, y2];

    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToCompleteVerticesOfShape(
                1,
                translate.shapes.aRightAngledTriangle()
              )
            : translate.instructions.drawCrossToCompleteVerticesOfAShape(
                translate.shapes.aRightAngledTriangle()
              )
        }
        testCorrect={ans =>
          isValidRightAngledTriangle(
            [x1, y1] as [number, number],
            [x2, y2] as [number, number],
            ans[0]
          )
        }
        snapToGrid
        customMarkSchemeAnswer={{
          answersToDisplay: [examplePoint as [number, number]],
          answerText: translate.markScheme.acceptValidVerticesForShape()
        }}
        gridProps={{
          xMax: 5,
          yMax: 5,
          squareGrid: true
        }}
        gridChildren={coordinates.map((val, i) => (
          <GridImage
            key={i}
            mathCoord={val}
            item={{
              component:
                displayMode === 'digital'
                  ? 'Coordinates/CirclePointCustomizable'
                  : 'Coordinates/CrossPointCustomizable',
              svgProps: { fill: colors.pacificBlue }
            }}
          />
        ))}
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'azy',
  description: 'azy',
  keywords: ['Coordinate', 'Coordinate grid', 'Rectangle', 'Vertex'],
  schema: z.object({
    x1: z.number().int().min(0).max(5),
    x2: z.number().int().min(0).max(5),
    y1: z.number().int().min(0).max(5),
    y2: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const [x1, x2] = randomUniqueIntegersInclusive(0, 5, 2);
    const [y1, y2] = randomUniqueIntegersInclusive(0, 5, 2);

    return { x1, x2, y1, y2 };
  },
  Component: ({ question: { x1, x2, y1, y2 }, translate, theme, displayMode }) => {
    const showCoords = [[x1, y1] as [number, number], [x2, y2] as [number, number]];
    const correctAnswers = [[x1, y2] as [number, number], [x2, y1] as [number, number]];
    const isSquare = Math.abs(y1 - y2) === Math.abs(x1 - x2);

    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToCompleteVerticesOfShape(
                2,
                isSquare ? translate.shapes.aSquare() : translate.shapes.aRectangle()
              )
            : translate.instructions.drawCrossesToCompleteVerticesOfAShape(
                isSquare ? translate.shapes.aSquare() : translate.shapes.aRectangle()
              )
        }
        testCorrect={userAnswer =>
          isSquare
            ? isValidSquare(showCoords[0], showCoords[1], userAnswer[0], userAnswer[1])
            : isValidRectangle(showCoords[0], showCoords[1], userAnswer[0], userAnswer[1])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: correctAnswers,
          answerText: translate.markScheme.acceptValidVerticesForShape()
        }}
        gridProps={{
          xMax: 5,
          yMax: 5,
          squareGrid: true
        }}
        snapToGrid
        gridChildren={showCoords.map((val, i) => (
          <GridImage
            key={i}
            mathCoord={val}
            item={{
              component:
                displayMode === 'digital'
                  ? 'Coordinates/CirclePointCustomizable'
                  : 'Coordinates/CrossPointCustomizable',
              svgProps: { fill: colors.pacificBlue }
            }}
          />
        ))}
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          },
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'azz',
  description: 'azz',
  keywords: ['Coordinate', 'Coordinate grid', 'Triangle', 'Vertex'],
  schema: z.object({
    x1: z.number().int().min(0).max(5),
    x2: z.number().int().min(0).max(5),
    y1: z.number().int().min(0).max(5),
    y2: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const xPlane = getRandomBoolean();
    const x1 = randomIntegerInclusive(0, 5);
    const x2 = xPlane
      ? randomIntegerInclusive(0, 5, {
          constraint: x => Math.abs(x - x1) === 2 || Math.abs(x - x1) === 4
        })
      : x1;
    const y1 = randomIntegerInclusive(0, 5);
    const y2 = xPlane
      ? y1
      : randomIntegerInclusive(0, 5, {
          constraint: y => Math.abs(y - y1) === 2 || Math.abs(y - y1) === 4
        });

    return { x1, x2, y1, y2 };
  },
  Component: ({ question: { x1, x2, y1, y2 }, translate, theme, displayMode }) => {
    const coordinates = [[x1, y1] as [number, number], [x2, y2] as [number, number]];

    // find which plane the base of the triangle is on, then go in the middle of the two base points
    const examplePoint =
      x1 === x2
        ? [
            // add 2 unless that takes it off the grid then -2
            x1 + 2 > 5 ? x1 - 2 : x1 + 2,
            // add difference as long as this doesnt take us off the grid
            Math.min(y1, y2) + Math.abs(y1 - y2) / 2 > 5
              ? Math.min(y1, y2) - Math.abs(y1 - y2) / 2
              : Math.min(y1, y2) + Math.abs(y1 - y2) / 2
          ]
        : [
            // add difference as long as this doesnt take us off the grid
            Math.min(x1, x2) + Math.abs(x1 - x2) / 2 > 5
              ? Math.min(x1, x2) - Math.abs(x1 - x2) / 2
              : Math.min(x1, x2) + Math.abs(x1 - x2) / 2,
            // add 2 unless that takes it off the grid then -2
            y1 + 2 > 5 ? y1 - 2 : y1 + 2
          ];

    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToCompleteVerticesOfShape(
                1,
                translate.shapes.anIsoscelesTriangle()
              )
            : translate.instructions.drawCrossToCompleteVerticesOfAShape(
                translate.shapes.anIsoscelesTriangle()
              )
        }
        testCorrect={ans =>
          isValidIsoscelesTriangle(
            [x1, y1] as [number, number],
            [x2, y2] as [number, number],
            ans[0]
          )
        }
        snapToGrid
        customMarkSchemeAnswer={{
          answersToDisplay: [examplePoint as [number, number]],
          answerText: translate.markScheme.acceptValidVerticesForShape()
        }}
        gridProps={{
          xMax: 5,
          yMax: 5,
          squareGrid: true
        }}
        gridChildren={coordinates.map((val, i) => (
          <GridImage
            key={i}
            mathCoord={val}
            item={{
              component:
                displayMode === 'digital'
                  ? 'Coordinates/CirclePointCustomizable'
                  : 'Coordinates/CrossPointCustomizable',
              svgProps: { fill: colors.pacificBlue }
            }}
          />
        ))}
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'azA',
  description: 'azA',
  keywords: ['Coordinate', 'Coordinate grid', 'Triangle', 'Vertex'],
  schema: z.object({
    x1: z.number().int().min(0).max(5),
    x2: z.number().int().min(0).max(5),
    y1: z.number().int().min(0).max(5),
    y2: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const [x1, x2] = randomUniqueIntegersInclusive(0, 5, 2);
    // set a max to make sure we can always fit an answer on a 6x6 grid.
    // If xMax + difference is greater than 6 and xMin - difference is < 0 then the base cannot lie on the x axis
    // So force it to be on the y by setting a max of 3 for the y
    const orderX = sortNumberArray([x1, x2], 'descending');
    const max =
      orderX[0] + (orderX[0] - orderX[1]) > 5 && orderX[1] - (orderX[0] - orderX[1]) < 0 ? 2 : 5;
    const [y1, y2] = randomUniqueIntegersInclusive(0, max, 2);

    return { x1, x2, y1, y2 };
  },
  Component: ({ question: { x1, x2, y1, y2 }, translate, theme, displayMode }) => {
    const coordinates = [[x1, y1] as [number, number], [x2, y2] as [number, number]];

    const orderX = sortNumberArray([x1, x2], 'descending');
    const orderY = sortNumberArray([y1, y2], 'descending');
    const minPointToLeft =
      (x1 === orderX[1] && y1 === orderY[1]) || (x2 === orderX[1] && y2 === orderY[1]);
    let examplePoint;
    if (minPointToLeft) {
      // if the gird isn't big enough for x to be base then example x === xMin
      if (orderX[0] + (orderX[0] - orderX[1]) > 5) {
        examplePoint = [orderX[1], orderY[0] + (orderY[0] - orderY[1])];
        // else the base can be the x axis
      } else {
        examplePoint = [orderX[0] + (orderX[0] - orderX[1]), orderY[1]];
      }
    } else {
      // if the gird isn't big enough for x to be base then example x === xMin
      if (orderX[0] + (orderX[0] - orderX[1]) > 5) {
        examplePoint = [orderX[0], orderY[0] + (orderY[0] - orderY[1])];
        // else the base can be the x axis
      } else {
        examplePoint = [orderX[0] + (orderX[0] - orderX[1]), orderY[0]];
      }
    }

    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToCompleteVerticesOfShape(
                1,
                translate.shapes.anIsoscelesTriangle()
              )
            : translate.instructions.drawCrossToCompleteVerticesOfAShape(
                translate.shapes.anIsoscelesTriangle()
              )
        }
        testCorrect={ans =>
          isValidIsoscelesTriangle(
            [x1, y1] as [number, number],
            [x2, y2] as [number, number],
            ans[0]
          )
        }
        snapToGrid
        customMarkSchemeAnswer={{
          answersToDisplay: [examplePoint as [number, number]],
          answerText: translate.markScheme.acceptValidVerticesForShape()
        }}
        gridProps={{
          xMax: 5,
          yMax: 5,
          squareGrid: true
        }}
        gridChildren={coordinates.map((val, i) => (
          <GridImage
            key={i}
            mathCoord={val}
            item={{
              component:
                displayMode === 'digital'
                  ? 'Coordinates/CirclePointCustomizable'
                  : 'Coordinates/CrossPointCustomizable',
              svgProps: { fill: colors.pacificBlue }
            }}
          />
        ))}
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'azB',
  description: 'azB',
  keywords: ['Coordinate', 'Coordinate grid', 'Rectangle', 'Vertex'],
  schema: z.object({
    coordinate1: z.number().int().min(0).max(5).array().length(2),
    coordinate2: z.number().int().min(0).max(5).array().length(2),
    coordinate3: z.number().int().min(0).max(5).array().length(2),
    coordinate4: z.number().int().min(0).max(5).array().length(2),
    answerIndex: z.number().int().min(0).max(3),
    isSquare: z.boolean()
  }),
  simpleGenerator: () => {
    const isSquare = getRandomBoolean();

    // we are going to progamitically work around the shape
    // randomly generate which angle the square is at
    const isLeftTilt = getRandomBoolean();

    // lets create first coord in the bottom segment
    const x1 = isLeftTilt ? randomIntegerInclusive(1, 3) : randomIntegerInclusive(2, 4);
    const y1 = randomIntegerInclusive(0, 2);

    // get the change in y for when we are dealing with the 'bottom side'.
    const bottomLengthChangeInY = isSquare ? 2 : 1;
    // get the change in y for when we are dealing with the 'top side'.
    const topLengthChangeInY = isSquare ? 1 : 2;

    // for second coord we want 'bottomLengthChangeInY' up in y and -1 in x when tilted left and + 1 when tilted right
    const x2 = isLeftTilt ? x1 - 1 : x1 + 1;
    const y2 = y1 + bottomLengthChangeInY;

    // then for third coord we want 'topLengthChangeInY' up in the y and 2 across in x when tilted left and -2 when tilted right
    const x3 = isLeftTilt ? x2 + 2 : x2 - 2;
    const y3 = y2 + topLengthChangeInY;

    // finally for bottom right we want 'bottomLengthChangeInY' down in y and 1 across in x when tilted left and -1 when tilted right
    const x4 = isLeftTilt ? x3 + 1 : x3 - 1;
    const y4 = y3 - bottomLengthChangeInY;

    const [coordinate1, coordinate2, coordinate3, coordinate4] = [
      [x1, y1],
      [x2, y2],
      [x3, y3],
      [x4, y4]
    ];
    const answerIndex = randomIntegerInclusive(0, 3);

    return { coordinate1, coordinate2, coordinate3, coordinate4, answerIndex, isSquare };
  },

  Component: ({
    question: { coordinate1, coordinate2, coordinate3, coordinate4, answerIndex, isSquare },
    translate,
    displayMode,
    theme
  }) => {
    const coordinates = [coordinate1, coordinate2, coordinate3, coordinate4];
    const showCoords = coordinates.filter((_val, i) => i !== answerIndex);

    return (
      <QF46PlotCoordinate
        // In PDF mode, we use a cross instead.
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointsToCompleteVerticesOfShape(
                1,
                isSquare ? translate.shapes.aSquare() : translate.shapes.aRectangle()
              )
            : translate.instructions.drawCrossesToCompleteVerticesOfAShape(
                isSquare ? translate.shapes.aSquare() : translate.shapes.aRectangle()
              )
        }
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer[0], coordinates[answerIndex] as [number, number])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [coordinates[answerIndex] as [number, number]]
        }}
        gridProps={{
          xMax: 5,
          yMax: 5,
          squareGrid: true
        }}
        snapToGrid
        gridChildren={showCoords.map((val, i) => (
          <GridImage
            key={i}
            mathCoord={val as [number, number]}
            item={{
              component:
                displayMode === 'digital'
                  ? 'Coordinates/CirclePointCustomizable'
                  : 'Coordinates/CrossPointCustomizable',
              svgProps: { fill: colors.pacificBlue }
            }}
          />
        ))}
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Draw2DShapesOnAGrid',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
