import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  range,
  rangeAsString,
  sortNumberArray
} from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baQ',
  description: 'baQ',
  keywords: ['Number line'],
  schema: z.object({
    number: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 10);
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    // Create array of empty strings
    const numArray = range(0, 10, 1);
    const tickValues = numArray.map(val => {
      return `${val}`.toLocaleString();
    });

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragTheArrowToNum({ numberA: number })}
        pdfTitle={translate.ks1PDFInstructions.circleTheNumberNum({ numberA: number })}
        testCorrect={[number, number]}
        min={0}
        max={10}
        sliderStep={1}
        tickValues={tickValues}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'baR',
  description: 'baR',
  keywords: ['Number line'],
  schema: z.object({
    numbers: z.array(z.number().int().min(0).max(10)).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const startingNumber = 0;
    const endNumber = 10;
    const interval = 1;

    const numAnswers = randomIntegerInclusive(4, 6);

    const choices = range(startingNumber + interval, endNumber - interval, interval);
    const numbers = rejectionSample(
      () => getRandomSubArrayFromArray(choices, numAnswers),
      x => {
        const [i, j, k] = x.map(it => choices.indexOf(it)).sort();
        return (j === i + 1 && k !== j + 1) || (k === j + 1 && j !== i + 1);
      }
    );

    // Sort the numbers from lowest to highest
    // This means the answers index matches with the user answers index.
    return { numbers: sortNumberArray(Array.from(numbers)) };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;
    const startingNumber = 0;
    const endNumber = 10;
    const interval = 1;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, endNumber, interval, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.completeTheNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'baS',
  description: 'baS',
  keywords: ['Number line'],
  schema: z.object({
    number: z.number().int().min(0).max(10),
    moreOrLess: z.enum(['more', 'less'])
  }),
  simpleGenerator: () => {
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);
    const number =
      moreOrLess === 'more' ? randomIntegerInclusive(0, 9) : randomIntegerInclusive(1, 10);
    return { number, moreOrLess };
  },
  Component: props => {
    const {
      question: { number, moreOrLess },
      translate
    } = props;

    // Create array of empty strings
    const numArray = range(0, 10, 1);
    const tickValues = numArray.map(val => {
      return `${val}`.toLocaleString();
    });

    const title =
      moreOrLess === 'more'
        ? translate.ks1Instructions.dragTheArrowTo1MoreThanNum({ numberA: number })
        : translate.ks1Instructions.dragTheArrowTo1LessThanNum({ numberA: number });
    const pdfTitle =
      moreOrLess === 'more'
        ? translate.ks1PDFInstructions.circleTheNumber1MoreThanNum({ numberA: number })
        : translate.ks1PDFInstructions.circleTheNumber1LessThanNum({ numberA: number });
    const correctValue = moreOrLess === 'more' ? number + 1 : number - 1;

    return (
      <QF19NumberLineDragArrow
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[correctValue, correctValue]}
        min={0}
        max={10}
        sliderStep={1}
        tickValues={tickValues}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TheNumberLine',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
