import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep
} from '../../../../utils/random';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import { algebraicSymbolSchema, getAlgebraicSymbolPair } from '../../../../utils/algebraicSymbols';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { ADD, SUB } from '../../../../constants';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { portionToText } from '../../../../utils/fractions';
import {
  cheapObjectWithArticle,
  cheapObjectSchema,
  getRandomUniqueCheapObject,
  cheapObjectAsWord,
  CheapObjectName
} from '../../../../utils/objects';
import { getCheapObjectSvgName } from '../../../../utils/objectsImages';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { compareFloats } from '../../../../utils/math';
import { isValidMoneyAnswer } from '../../../../utils/money';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aUC',
  description: 'aUC',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(8).max(16),
      var2: z.number().int().min(2).max(14),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(8, 16);
    const var2 = randomIntegerInclusive(2, var1 - 2);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, var2, symb1, symb2 };
  },
  Component: ({ question: { var1, var2, symb1, symb2 }, translate, displayMode }) => {
    const total = var1 + var2;
    const diff = var1 - var2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.sumOfTwoNumbersXYisZ(symb1, symb2, total.toLocaleString())}
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                height: (dimens.height / 5) * 4
              }}
            >
              <View style={{ justifyContent: 'space-evenly', height: (dimens.height / 5) * 4 }}>
                <BarModel
                  numbers={[[var1]]}
                  strings={[[symb1]]}
                  total={var1}
                  dimens={{ width: dimens.width * 0.95, height: dimens.height / 2.5 }}
                  maxFontSize={displayMode === 'digital' ? 32 : 50}
                />
                <BarModel
                  numbers={[[var2, diff]]}
                  strings={[[symb2]]}
                  arrowIndices={[[1]]}
                  total={var1}
                  dimens={{ width: dimens.width * 0.95, height: dimens.height / 2.5 }}
                  maxFontSize={displayMode === 'digital' ? 32 : 50}
                />
              </View>
              <View
                style={[
                  {
                    justifyContent: 'flex-end',
                    width: displayMode === 'digital' ? dimens.height * 0.63 : dimens.height * 0.6,
                    height: dimens.height,
                    left: displayMode === 'digital' ? 72 : 88
                  },
                  { transform: [{ rotate: '90deg' }] }
                ]}
              >
                <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
              </View>
            </View>
            <View style={{ height: dimens.height / 5 }}>
              <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {translate.instructions.workOutTheValuesOfXAndY(symb1, symb2)}
              </Text>
            </View>
          </View>
        )}
        testCorrect={[var1.toString(), var2.toString()]}
        sentence={translate.answerSentences.XAnsYAns(symb1, symb2)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'aUD',
  description: 'aUD',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(8).max(16),
      var2: z.number().int().min(2).max(14),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema,
      moreThan: z.boolean()
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(8, 16);
    const var2 = randomIntegerInclusive(2, var1 - 2);
    const [symb1, symb2] = getAlgebraicSymbolPair();
    const moreThan = getRandomBoolean();

    return { var1, var2, symb1, symb2, moreThan };
  },
  Component: ({ question: { var1, var2, symb1, symb2, moreThan }, translate, displayMode }) => {
    const total = var1 + var2;
    const diff = var1 - var2;

    const secondInstruction = moreThan
      ? translate.instructions.xIsYMoreThanZ(symb1, diff.toLocaleString(), symb2)
      : translate.instructions.xIsYLessThanZ(symb2, diff.toLocaleString(), symb1);

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${secondInstruction}`}
        Content={({ dimens }) => (
          <View>
            <View style={[dimens, { justifyContent: 'space-evenly' }]}>
              <BarModel
                numbers={[[var1]]}
                strings={[[symb1]]}
                total={var1}
                dimens={{ width: dimens.width, height: dimens.height / 2.5 }}
                maxFontSize={displayMode === 'digital' ? 32 : 50}
              />
              <BarModel
                numbers={[[var2]]}
                strings={[[symb2]]}
                total={var1}
                dimens={{ width: dimens.width, height: dimens.height / 2.5 }}
                maxFontSize={displayMode === 'digital' ? 32 : 50}
              />
            </View>
            <View style={{ height: dimens.height / 5 }}>
              <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {translate.instructions.workOutTheValuesOfXAndY(symb1, symb2)}
              </Text>
            </View>
          </View>
        )}
        sentence={translate.answerSentences.XAnsYAns(symb1, symb2)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aUE',
  description: 'aUE',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(6),
      times: z.number().int().min(2).max(5),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 6);
    const times = randomIntegerInclusive(2, 5);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, times, symb1, symb2 };
  },
  Component: ({ question: { var1, times, symb1, symb2 }, translate }) => {
    const var2 = var1 * times;
    const total = var1 + var2;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${translate.instructions.xIsYTimesZ(symb2, times.toLocaleString(), symb1)}`}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row' }]}>
            <View style={{ justifyContent: 'space-evenly' }}>
              <ShadedFractionBarModel
                preBarText={symb1}
                totalSubSections={1}
                width={(dimens.width * 0.8) / times}
                containerStyle={{ alignItems: 'flex-start' }}
              />
              <ShadedFractionBarModel
                preBarText={symb2}
                totalSubSections={times}
                width={dimens.width * 0.8}
              />
            </View>
            <View
              style={[
                {
                  justifyContent: 'flex-end',
                  width: dimens.height,
                  height: dimens.height
                },
                { transform: [{ rotate: '90deg' }] }
              ]}
            >
              <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.workOutTheValuesXY(symb1, symb2)}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aUE2',
  description: 'aUE',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(8),
      times: z.number().int().min(2).max(5),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2)
    .refine(({ var1, times }) => var1 * (times + 1) <= 40),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 8);
    const times = randomIntegerInclusive(2, 5, { constraint: x => var1 * (x + 1) <= 40 });
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, times, symb1, symb2 };
  },
  Component: ({ question: { var1, times, symb1, symb2 }, translate, displayMode }) => {
    const var2 = var1 * times;
    const total = var1 + var2;

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${translate.instructions.xIsYTimesZ(symb2, times.toLocaleString(), symb1)}`}
        Content={({ dimens }) => (
          <View style={{ justifyContent: 'flex-start' }}>
            <View style={[dimens, { flexDirection: 'row' }]}>
              <View style={{ justifyContent: 'space-evenly' }}>
                <ShadedFractionBarModel
                  preBarText={symb1}
                  totalSubSections={1}
                  width={(dimens.width * 0.8) / times}
                  height={dimens.height * 0.2}
                  containerStyle={{ alignItems: 'flex-start' }}
                />
                <ShadedFractionBarModel
                  preBarText={symb2}
                  totalSubSections={times}
                  width={dimens.width * 0.8}
                  height={dimens.height * 0.2}
                />
              </View>
              <View
                style={[
                  {
                    justifyContent: 'flex-end',
                    width: dimens.height * 0.7,
                    height: dimens.height
                  },
                  { transform: [{ rotate: '90deg' }, { translateY: -dimens.height * 0.3 }] }
                ]}
              >
                <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
              </View>
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.workOutTheValues()}
            </Text>
          </View>
        )}
        sentence={translate.answerSentences.XAnsYAns(symb1, symb2)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={900}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aUF',
  description: 'aUF',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(6),
      fraction: z.number().int().min(2).max(4),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 6);
    const fraction = randomIntegerInclusive(2, 4);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, fraction, symb1, symb2 };
  },
  Component: ({ question: { var1, fraction, symb1, symb2 }, translate }) => {
    const var2 = var1 * fraction;
    const total = var1 + var2;

    const getfractionWord = portionToText(fraction, translate, 1);

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${translate.instructions.xIsYOfZ(symb1, getfractionWord, symb2)}`}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row' }]}>
            <View style={{ justifyContent: 'space-evenly' }}>
              <ShadedFractionBarModel
                preBarText={symb1}
                totalSubSections={1}
                width={(dimens.width * 0.8) / fraction}
                containerStyle={{ alignItems: 'flex-start' }}
              />
              <ShadedFractionBarModel
                preBarText={symb2}
                totalSubSections={fraction}
                width={dimens.width * 0.8}
              />
            </View>
            <View
              style={[
                {
                  justifyContent: 'flex-end',
                  width: dimens.height,
                  height: dimens.height
                },
                { transform: [{ rotate: '90deg' }] }
              ]}
            >
              <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
            </View>
          </View>
        )}
        sentence={translate.answerSentences.workOutTheValuesXY(symb1, symb2)}
        testCorrect={[var1.toString(), var2.toString()]}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aUF2',
  description: 'aUF2',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(8),
      fraction: z.number().int().min(2).max(4),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 8);
    const fraction = randomIntegerInclusive(2, 4);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, fraction, symb1, symb2 };
  },
  Component: ({ question: { var1, fraction, symb1, symb2 }, translate, displayMode }) => {
    const var2 = var1 * fraction;
    const total = var1 + var2;

    const fractionWord = (() => {
      switch (fraction) {
        case 2:
          return translate.fractions.halves(1);
        case 3:
          return translate.fractions.aThird();
        case 4:
          return translate.fractions.aQuarter();
      }
    })();

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.sumOfTwoNumbersXYisZ(
          symb1,
          symb2,
          total.toLocaleString()
        )}<br/>${translate.instructions.xIsYTheSizeOfZ(symb1, fractionWord, symb2)}`}
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                height: dimens.height * 0.8
              }}
            >
              <View style={{ justifyContent: 'space-evenly', height: dimens.height * 0.8 }}>
                <ShadedFractionBarModel
                  preBarText={symb1}
                  totalSubSections={1}
                  width={(dimens.width * 0.8) / fraction}
                  containerStyle={{ alignItems: 'flex-start' }}
                />
                <ShadedFractionBarModel
                  preBarText={symb2}
                  totalSubSections={fraction}
                  width={dimens.width * 0.8}
                />
              </View>
              <View
                style={[
                  {
                    justifyContent: 'flex-end',
                    width: dimens.height * 0.59,
                    height: dimens.height,
                    left: displayMode === 'digital' ? 72 : 88
                  },
                  { transform: [{ rotate: '90deg' }] }
                ]}
              >
                <BarModelCurlyBrace braceText={total.toLocaleString()} sideText />
              </View>
            </View>
            <View style={{ height: dimens.height / 5 }}>
              <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
                {translate.instructions.workOutTheValuesOfXAndY(symb1, symb2)}
              </Text>
            </View>
          </View>
        )}
        sentence={translate.answerSentences.XAnsYAns(symb1, symb2)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[var1.toString(), var2.toString()]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aUG',
  description: 'aUG',
  keywords: ['Solve', 'Equation'],
  schema: z
    .object({
      price1: z.number().int().min(15).max(95).step(5),
      price2: z.number().int().min(15).max(95).step(5),
      obj1: cheapObjectSchema,
      obj2: cheapObjectSchema
    })
    .refine(({ price1, price2 }) => price1 !== price2),

  simpleGenerator: () => {
    const [price1, price2] = randomUniqueIntegersInclusiveStep(15, 95, 5, 2);
    const [obj1, obj2] = getRandomUniqueCheapObject(2);

    return { price1, price2, obj1, obj2 };
  },
  Component: ({ question: { price1, price2, obj1, obj2 }, translate }) => {
    const total = price1 + price2;
    const diff = price1 - price2;

    const obj1Name = cheapObjectAsWord(obj1, translate);
    const obj2Name = cheapObjectAsWord(obj2, translate);
    const obj1WithArticle = cheapObjectWithArticle(obj1, translate);
    const obj2WithArticle = cheapObjectWithArticle(obj2, translate);

    const secondInstruction =
      diff < 0
        ? translate.instructions.xCostsYLessThanZ(
            obj1WithArticle,
            Math.abs(diff).toLocaleString(),
            obj2WithArticle
          )
        : translate.instructions.xCostsYMoreThanZ(
            obj1WithArticle,
            diff.toLocaleString(),
            obj2WithArticle
          );
    return (
      <QF1ContentAndSentences
        title={`${translate.instructions.totalCostOfXandYIsZ(
          obj1Name,
          obj2Name,
          (total / 100).toFixed(2).toLocaleString()
        )}<br/>${secondInstruction}`}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', gap: 50 }}>
            <AssetSvg name={getCheapObjectSvgName(obj1)} height={dimens.height} />
            <AssetSvg name={getCheapObjectSvgName(obj2)} height={dimens.height} />
          </View>
        )}
        inputMaxCharacters={2}
        sentences={[
          translate.answerSentences.costOfXis(obj1WithArticle),
          translate.answerSentences.costOfXis(obj2WithArticle)
        ]}
        testCorrect={[[price1.toString()], [price2.toString()]]}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aUG2',
  description: 'aUG2',
  keywords: ['Solve', 'Equation'],
  schema: z
    .object({
      obj1: cheapObjectSchema,
      obj2: cheapObjectSchema,
      price1: z.number().int().min(100).max(3500).step(5),
      price2: z.number().int().min(100).max(3500).step(5)
    })
    .refine(val => val.obj1 !== val.obj2, 'obj1 and obj2 must be different.')
    .refine(val => val.price1 !== val.price2, 'price1 and price2 must be different.'),
  simpleGenerator: () => {
    const [obj1, obj2] = getRandomUniqueCheapObject(2);

    const objectToPriceRange = (obj: CheapObjectName) => {
      switch (obj) {
        case 'Balloon':
          return [100, 500];
        case 'Book':
          return [300, 2000];
        case 'BreadLoaf':
          return [150, 300];
        case 'CrayonBox':
          return [100, 600];
        case 'FizzyDrink':
        case 'JuiceBottle':
        case 'LemonadeBottle':
          return [100, 400];
        case 'Kettle':
          return [800, 3500];
        case 'Mug':
          return [100, 800];
        case 'PencilCase':
          return [200, 1500];
      }
    };

    const price1Range = objectToPriceRange(obj1);

    const price2Range = objectToPriceRange(obj2);

    const price1 = randomIntegerInclusiveStep(price1Range[0], price1Range[1], 5);

    const price2 = randomIntegerInclusiveStep(price2Range[0], price2Range[1], 5, {
      constraint: x => x !== price1
    });

    return { obj1, obj2, price1, price2 };
  },
  Component: ({ question: { obj1, obj2, price1, price2 }, translate }) => {
    const total = price1 + price2;

    const diff = price1 - price2;

    const diffInPoundsAndPence = Math.abs(diff / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    const objectToString = (obj: CheapObjectName) => {
      switch (obj) {
        case 'Book':
          return translate.objects.Book();
        case 'Balloon':
          return translate.objects.Balloon();
        case 'Kettle':
          return translate.objects.Kettle();
        case 'JuiceBottle':
          return translate.objects.BottleOfJuice();
        case 'Mug':
          return translate.objects.Mug();
        case 'CrayonBox':
          return translate.objects.CrayonBox();
        case 'BreadLoaf':
          return translate.objects.LoafOfBread();
        case 'PencilCase':
          return translate.objects.PencilCase();
        case 'LemonadeBottle':
          return translate.objects.BottleOfLemonade();
        case 'FizzyDrink':
          return translate.objects.FizzyDrink();
      }
    };

    const obj1String = objectToString(obj1);
    const obj2String = objectToString(obj2);
    const obj1StringWithArticle = cheapObjectWithArticle(obj1, translate);
    const obj2StringWithArticle = cheapObjectWithArticle(obj2, translate);

    return (
      <QF1ContentAndSentences
        title={
          diff < 0
            ? translate.instructions.theTotalCostOfOneXAndOneYIsPriceAXCostsNumPLessThanAYCompleteTheSentences(
                obj1String,
                obj2String,
                (total / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }),
                obj1StringWithArticle,
                diffInPoundsAndPence,
                obj2StringWithArticle
              )
            : translate.instructions.theTotalCostOfOneXAndOneYIsPriceAXCostsNumPMoreThanAYCompleteTheSentences(
                obj1String,
                obj2String,
                (total / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }),
                obj1StringWithArticle,
                diffInPoundsAndPence,
                obj2StringWithArticle
              )
        }
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', gap: 50 }}>
            <AssetSvg name={getCheapObjectSvgName(obj1)} height={dimens.height} />
            <AssetSvg name={getCheapObjectSvgName(obj2)} height={dimens.height} />
          </View>
        )}
        sentences={[
          translate.answerSentences.theCostOfXIsPoundAns(obj1StringWithArticle),
          translate.answerSentences.theCostOfXIsPoundAns(obj2StringWithArticle)
        ]}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        testCorrect={answer =>
          compareFloats(answer[0][0], price1 / 100) &&
          compareFloats(answer[1][0], price2 / 100) &&
          isValidMoneyAnswer(answer[0][0]) &&
          isValidMoneyAnswer(answer[1][0])
        }
        pdfDirection="column"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              (price1 / 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            ],
            [
              (price2 / 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            ]
          ]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aUH',
  description: 'aUH',
  keywords: ['Bar model', 'Value', 'Equation'],
  schema: z
    .object({
      var1: z.number().int().min(30).max(99),
      var2: z.number().int().min(10).max(98),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(30, 99);
    const var2 = randomIntegerInclusive(10, var1 - 1);
    const [symb1, symb2] = getAlgebraicSymbolPair();

    return { var1, var2, symb1, symb2 };
  },
  Component: ({ question: { var1, var2, symb1, symb2 }, translate }) => {
    const total = var1 + var2;
    const diff = var1 - var2;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutTheValuesOfXAndY(symb1, symb2)}
        Content={
          <View>
            <Text variant="WRN400">{`${symb1} ${ADD} ${symb2} = ${total.toLocaleString()}`}</Text>
            <Text variant="WRN400">{`${symb1} ${SUB} ${symb2} = ${diff.toLocaleString()}`}</Text>
          </View>
        }
        inputMaxCharacters={2}
        sentences={[`${symb1} = <ans/>`, `${symb2} = <ans/>`]}
        style={{ flexDirection: 'row' }}
        pdfDirection={'column'}
        pdfSentenceStyle={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
        testCorrect={[[var1.toString()], [var2.toString()]]}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aUH2',
  description: 'aUH',
  keywords: [
    'Unknowns',
    'Constant',
    'Variable',
    'Multiplication',
    'Multiplicative',
    'Addition',
    'Total'
  ],
  schema: z
    .object({
      mass1: z.number().int().min(1).max(90),
      multiplier: z.number().int().min(2).max(12),
      symb1: algebraicSymbolSchema,
      symb2: algebraicSymbolSchema,
      isGrams: z.boolean()
    })
    .refine(({ symb1, symb2 }) => symb1 !== symb2),

  simpleGenerator: () => {
    const mass1 = getRandomBoolean()
      ? randomIntegerInclusive(1, 12)
      : randomIntegerInclusiveStep(20, 90, 10);
    const multiplier = randomIntegerInclusive(2, 12);
    const [symb1, symb2] = getAlgebraicSymbolPair();
    const isGrams = getRandomBoolean();

    return { mass1, multiplier, symb1, symb2, isGrams };
  },
  Component: ({ question: { mass1, multiplier, symb1, symb2, isGrams }, translate }) => {
    const mass2 = mass1 * multiplier;
    const totalMass = mass1 + mass2;
    const totalSentence = isGrams
      ? translate.units.numberOfG(totalMass)
      : translate.units.numberOfKg(totalMass);

    const answerSentence = isGrams
      ? translate.answerSentences.ansG()
      : translate.answerSentences.ansKg();

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.theTotalMassOfAAndBIsXMassOfAIsYTimesWorkOutAAndB({
          symbol1: symb1,
          symbol2: symb2,
          totalMass: totalSentence,
          multiplier
        })}
        mainPanelContainerStyle={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%'
        }}
        pdfMainPanelContainerStyle={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%'
        }}
        inputMaxCharacters={3}
        sentences={[`${symb1} = ${answerSentence}`, `${symb2} = ${answerSentence}`]}
        testCorrect={[[mass2.toString()], [mass1.toString()]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithTwoUnknowns',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5v2, Question6v2],
  archivedQuestionTypes: [Question3, Question4, Question5, Question6]
});
export default SmallStep;
