import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  randomIntegerInclusive,
  shuffle,
  getRandomFromArray,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { numberToRomanNumerals } from '../../../../components/question/representations/RomanNumerals';
import AutoScaleText from '../../../../components/typography/AutoScaleText';
import Clock from '../../../../components/question/representations/Clock';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { convert12hToSpokenString } from '../../../../utils/time';
import {
  NonEmptyArray,
  arrayHasNoDuplicates,
  sortNumberArray
} from '../../../../utils/collections';
import QF14aCompleteNumberTrackDraggable from '../../../../components/question/questionFormats/QF14aCompleteNumberTrackDraggable';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import { numberEnum } from '../../../../utils/zod';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { isEqual } from '../../../../utils/matchers';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'atC',
  description: 'atC',
  keywords: ['Roman numerals', 'Time'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(12))
      .length(4)
      .refine(arrayHasNoDuplicates, 'values should all be unique')
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 12, 4);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToMatchRomanNumeralsToNumbers()}
        pdfTitle={translate.instructions.matchRomanNumeralsToNumbers()}
        items={sortNumberArray(numbers).map(number => numberToRomanNumerals(number))}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endWide"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        itemsTextVariant="roman"
        sentences={numbers.map(number => `${number.toLocaleString()} <ans/>`)}
        testCorrect={numbers.map(number => [numberToRomanNumerals(number)])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'atD',
  description: 'atD',
  keywords: ['Roman numerals', 'Time'],
  schema: z.object({
    number: z.number().int().min(1).max(12)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 12);

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheRomanNumeralAsANumber()}
        testCorrect={[number.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View
            style={{ borderColor: 'black', borderWidth: 2, borderRadius: 12, alignSelf: 'center' }}
          >
            <AutoScaleText
              variant="roman"
              containerStyle={{
                width: displayMode === 'digital' ? dimens.width / 1.2 : dimens.width / 4,
                height: dimens.height / 2
              }}
              group={0}
              maxLines={1}
            >
              {numberToRomanNumerals(number)}
            </AutoScaleText>
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'atE',
  description: 'atE',
  keywords: ['Roman numerals', 'Time', 'Track'],
  schema: z.object({
    shuffledAnswers: z
      .array(z.number().int().min(1).max(12))
      .length(3)
      .refine(arrayHasNoDuplicates, 'Duplicate numbers are not allowed.'),
    numbers: z
      .array(z.number().int().min(1).max(12))
      .length(5)
      .refine(arrayHasNoDuplicates, 'Duplicate numbers are not allowed.'),
    answerIndices: z.array(z.number().int().min(0).max(4)).length(3)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 8);
    const numbers = [number1, number1 + 1, number1 + 2, number1 + 3, number1 + 4];

    const answerIndices = randomUniqueIntegersInclusive(0, 4, 3);

    const answerOptions = numbers.filter((_, idx) => answerIndices.includes(idx));
    const shuffledAnswers = shuffle(answerOptions);

    return {
      shuffledAnswers,
      numbers,
      answerIndices
    };
  },
  Component: props => {
    const {
      question: { shuffledAnswers, numbers, answerIndices },
      translate
    } = props;

    const numerals = numbers.map(numberToRomanNumerals);

    const numberArray = numerals.map((num, idx) => (answerIndices.includes(idx) ? '<ans/>' : num));
    const correctOrder = sortNumberArray(shuffledAnswers);

    return (
      <QF14aCompleteNumberTrackDraggable
        title={translate.instructions.dragCardsCompleteNumberTrack()}
        pdfTitle={translate.instructions.useCardsCompleteNumberTrack()}
        items={shuffledAnswers.map(number => ({
          value: number,
          component: numberToRomanNumerals(number)
        }))}
        pdfItemVariant="square"
        contentTextVariant="roman"
        itemsTextVariant="roman"
        customBoxWidth={320}
        boxValues={numberArray}
        testCorrect={correctOrder}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'atE2',
  description: 'atE',
  keywords: ['Roman numerals', 'Time', 'Track'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(1).max(12))
      .length(5)
      .refine(arrayHasNoDuplicates, 'Duplicate numbers are not allowed.'),
    answerIndices: z.array(z.number().int().min(0).max(4)).length(3),
    ascendingOrDescending: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 8);
    const numbers = [number1, number1 + 1, number1 + 2, number1 + 3, number1 + 4];

    const answerIndices = randomUniqueIntegersInclusive(0, 4, 3);

    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    return {
      numbers: ascendingOrDescending === 'descending' ? numbers.reverse() : numbers,
      answerIndices,
      ascendingOrDescending
    };
  },
  Component: props => {
    const {
      question: { numbers, answerIndices, ascendingOrDescending },
      translate
    } = props;

    const answers = numbers.filter((_, idx) => answerIndices.includes(idx));

    const numerals = numbers.map(num => numberToRomanNumerals(num));

    const { answer, titleA, titleB } = (() => {
      switch (ascendingOrDescending) {
        case 'descending':
          return {
            titleA: translate.keywords.Greatest(),
            titleB: translate.keywords.Smallest(),
            answer: sortNumberArray(answers).reverse()
          };
        default:
          return {
            titleA: translate.keywords.Smallest(),
            titleB: translate.keywords.Greatest(),
            answer: sortNumberArray(answers)
          };
      }
    })();

    const shuffledAnswers = shuffle(answers, { random: seededRandom(props.question) });

    const sentence = numerals
      .map((x, index) => (answerIndices.includes(index) ? `<ans/>` : x))
      .join(' ,  ');

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToOrderRomanNumeralsFromXToY(titleA, titleB)}
        pdfTitle={translate.instructions.useCardsToOrderRomanNumeralsFromXToY(titleA, titleB)}
        sentence={sentence}
        sentencesTextVariant="roman"
        items={shuffledAnswers.map(number => ({
          value: number,
          component: numberToRomanNumerals(number)
        }))}
        itemsTextVariant="roman"
        moveOrCopy="move"
        pdfLayout="itemsTop"
        testCorrect={answer}
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'atF',
  description: 'atF',
  keywords: ['Roman numerals', 'Time', "O'clock", 'Analogue'],
  schema: z
    .object({
      hoursToShow: z.number().int().min(0).max(11),
      options: z
        .number()
        .int()
        .min(0)
        .max(11)
        .array()
        .length(3)
        .refine(arrayHasNoDuplicates, 'All hours must be different')
    })
    .refine(
      ({ hoursToShow, options }) => options.includes(hoursToShow),
      'options must include the correct answer'
    ),
  simpleGenerator: () => {
    const options = randomUniqueIntegersInclusive(0, 11, 3) as NonEmptyArray<number>;
    return { hoursToShow: getRandomFromArray(options), options };
  },
  Component: ({ question: { hoursToShow, options }, translate }) => {
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTimeShownOnClock()}
        testCorrect={[hoursToShow]}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hoursToShow, minutes: 0 }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            isRoman
          />
        )}
        renderItems={options.map(hours => ({
          value: hours,
          component: <Text variant="WRN700">{convert12hToSpokenString(translate, hours, 0)}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'atG',
  description: 'atG',
  keywords: ['Roman numerals', 'Time', 'Half past', 'Analogue'],
  schema: z
    .object({
      hoursToShow: z.number().int().min(0).max(11),
      options: z
        .number()
        .int()
        .min(0)
        .max(11)
        .array()
        .length(3)
        .refine(arrayHasNoDuplicates, 'All hours must be different')
    })
    .refine(
      ({ hoursToShow, options }) => options.includes(hoursToShow),
      'options must include the correct answer'
    ),
  simpleGenerator: () => {
    const options = randomUniqueIntegersInclusive(0, 11, 3) as NonEmptyArray<number>;
    return { hoursToShow: getRandomFromArray(options), options };
  },
  Component: ({ question: { hoursToShow, options }, translate }) => {
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTimeShownOnClock()}
        testCorrect={[hoursToShow]}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hoursToShow, minutes: 30 }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            isRoman
          />
        )}
        renderItems={options.map(hours => ({
          value: hours,
          component: <Text variant="WRN700">{convert12hToSpokenString(translate, hours, 30)}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'atH',
  description: 'atH',
  keywords: ['Roman numerals', 'Time', "O'clock", 'Half past', 'Analogue'],
  schema: z
    .object({
      minutes: numberEnum([0, 30]),
      hours: z.number().int().min(0).max(11)
    })
    .refine(
      ({ minutes, hours }) => !(hours === 0 && minutes === 0),
      "Time to find cannot be 12 o'clock"
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const minutes = getRandomFromArray([0, 30] as const);
        const hours = randomIntegerInclusive(0, 11);

        return { minutes, hours };
      },
      ({ hours, minutes }) => !(hours === 0 && minutes === 0)
    ),
  Component: ({ question: { hours, minutes }, translate }) => {
    const translatedTime = `${convert12hToSpokenString(translate, hours, minutes)}${
      minutes === 0 ? '.' : ''
    }`;

    return (
      <QF40SetTheClockFace
        title={translate.instructions.moveTheHandsOfClockToShowX(translatedTime)}
        pdfTitle={translate.instructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes })}
        exampleCorrectAnswer={{ hours, minutes }}
        isRoman={true}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RomanNumeralsTo12',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
