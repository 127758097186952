import z from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import { countRange, filledArray } from '../../../../utils/collections';
import { View } from 'react-native';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { fruitAsWord } from '../../../../utils/fruits';
import { colors } from '../../../../theme/colors';
import { numberEnum } from '../../../../utils/zod';
import QF60DragCountersIntoGroups from '../../../../components/question/questionFormats/QF60DragCountersIntoGroups';
import QF68DragSvgsIntoGroups from '../../../../components/question/questionFormats/QF68DragSvgsIntoGroups';

////
// Questions
////

const objectImages: Record<
  'AppleRed' | 'AppleGreen' | 'Banana' | 'Pear' | 'Muffin' | 'Cookie',
  SvgName
> = {
  AppleRed: 'Array_objects/AppleRed',
  AppleGreen: 'Array_objects/AppleGreen',
  Banana: 'Array_objects/Banana',
  Pear: 'Array_objects/Pear',
  Muffin: 'Muffins_individually/Muffin_1',
  Cookie: 'Cookie'
};

const Question1 = newQuestionContent({
  uid: 'beh',
  description: 'beh',
  keywords: ['Share', 'Division', 'Equal'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(6),
      item: z.enum(['AppleRed', 'AppleGreen', 'Muffin', 'Cookie']),
      total: numberEnum([2, 3, 4, 5, 6, 8, 9, 10, 12, 15, 16, 20])
    })
    .refine(val => val.total % val.groups === 0, 'groups must be a factor of total')
    .refine(val => val.total / val.groups <= 6, 'must be less than 7 in each group')
    .refine(
      val => (val.groups <= 5 && val.total > 10) || val.groups <= 6,
      'maximum of 5 groups when the total is over 10'
    ),
  simpleGenerator: () => {
    const item = getRandomFromArray(['AppleRed', 'AppleGreen', 'Muffin', 'Cookie'] as const);
    const total = getRandomFromArray([2, 3, 4, 5, 6, 8, 9, 10, 12, 15, 16, 20] as const);
    const groups = randomIntegerInclusive(2, total > 10 ? 5 : 6, {
      constraint: x => total % x === 0 && total / x <= 6
    });

    return { groups, item, total };
  },
  Component: props => {
    const {
      question: { groups, item, total },
      translate
    } = props;

    const svgName = objectImages[item];
    const inEachGroup = total / groups;

    const localisedPluralItem =
      item === 'AppleRed' || item === 'AppleGreen'
        ? fruitAsWord('Apple', translate, true)
        : item === 'Muffin'
        ? translate.food.Muffins(2)
        : translate.food.Cookies(2);

    return (
      <QF68DragSvgsIntoGroups
        numberOfGroups={groups}
        title={translate.ks1Instructions.dragToShareAllTheObjectsEquallyBetweenTrays(
          localisedPluralItem
        )}
        pdfTitle={translate.ks1PDFInstructions.shareAllTheObjectsEquallyBetweenTrays(
          localisedPluralItem
        )}
        draggableItem={svgName}
        draggableCount={total}
        testCorrect={filledArray(inEachGroup, groups)}
        questionHeight={900}
        showTrays
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bei',
  description: 'bei',
  keywords: ['Equal groups', 'Sharing', 'Division'],
  schema: z
    .object({
      groups: z.number().int().min(2).max(6),
      item: z.enum(['AppleRed', 'AppleGreen', 'Pear', 'Banana', 'Muffin', 'Cookie']),
      inEachGroup: z.number().int().min(1).max(4)
    })
    .refine(val => val.groups * val.inEachGroup <= 20, 'must be less than 21 objects'),
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 6);
    const item = getRandomFromArray([
      'AppleRed',
      'AppleGreen',
      'Pear',
      'Banana',
      'Muffin',
      'Cookie'
    ] as const);
    const inEachGroup = randomIntegerInclusive(1, 4, { constraint: x => x * groups <= 20 });

    return { groups, item, inEachGroup };
  },
  Component: props => {
    const {
      question: { groups, item, inEachGroup },
      translate,
      displayMode
    } = props;

    const svgName = objectImages[item];

    const localisedPluralItem =
      item === 'AppleRed' || item === 'AppleGreen'
        ? fruitAsWord('Apple', translate, true)
        : item === 'Pear' || item === 'Banana'
        ? fruitAsWord(item, translate, true)
        : item === 'Muffin'
        ? translate.food.Muffins(2)
        : translate.food.Cookies(2);

    const localisedSingularItem =
      item === 'AppleRed' || item === 'AppleGreen'
        ? fruitAsWord('Apple', translate, false)
        : item === 'Pear' || item === 'Banana'
        ? fruitAsWord(item, translate, false)
        : item === 'Muffin'
        ? translate.food.Muffins(1)
        : translate.food.Cookies(1);

    const color = displayMode === 'digital' ? colors.prussianBlue : 'black';
    const borderWidth = displayMode === 'digital' ? 2 : 4;

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={[
          translate.ks1AnswerSentences.xObjectsAreSharedEquallyBetweenAnsBoxes(
            inEachGroup * groups,
            localisedPluralItem
          ),
          inEachGroup === 1
            ? translate.ks1AnswerSentences.thereIsAnsObjectInEachBox(localisedSingularItem)
            : translate.ks1AnswerSentences.thereAreAnsObjectInEachBox(localisedPluralItem)
        ]}
        testCorrect={[[groups.toString()], [inEachGroup.toString()]]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            <View
              style={{
                flexDirection: 'column',
                gap: 15
              }}
            >
              {countRange(groups === 6 ? 2 : 1).map(rowId => {
                const viewDimens = Math.min(
                  dimens.width / 5.5,
                  dimens.height / (groups > 5 ? 2 : 1.5)
                );
                return (
                  <View key={rowId} style={{ flexDirection: 'row', gap: 15 }}>
                    {countRange(groups === 6 ? 3 : groups).map(i => (
                      <View
                        key={i}
                        style={{
                          borderColor: color,
                          borderWidth,
                          width: viewDimens,
                          height: viewDimens,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: 10,
                            justifyContent: 'center'
                          }}
                        >
                          {countRange(inEachGroup).map(i => (
                            <AssetSvg
                              key={`${i}_${i}`}
                              name={svgName as SvgName}
                              width={viewDimens * 0.4}
                              height={viewDimens * 0.4}
                            />
                          ))}
                        </View>
                      </View>
                    ))}
                  </View>
                );
              })}
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bej',
  description: 'bej',
  keywords: ['Share', 'Equal', 'Division'],
  schema: z
    .object({
      numberOfGroups: numberEnum([2, 3, 4, 5, 6, 7, 8, 9, 10]),
      number: z.number().int().min(2).max(20),
      color: z.enum(['red', 'yellow', 'blue', 'green'])
    })
    .refine(
      val => val.number % val.numberOfGroups === 0,
      'Number must be a multiple of numberOfGroups'
    ),
  simpleGenerator: () => {
    const numberOfGroups = getRandomFromArray([2, 3, 4, 5, 6, 7, 8, 9, 10] as const);

    const number = randomIntegerInclusive(2, 20, { constraint: x => x % numberOfGroups === 0 });

    const color = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);

    return {
      numberOfGroups,
      number,
      color
    };
  },
  Component: props => {
    const {
      question: { number, numberOfGroups, color },
      translate
    } = props;

    const testCorrect = countRange(numberOfGroups).map(() =>
      filledArray(color, number / numberOfGroups)
    );

    const numBoxesAsWords = translate.numbersAsWords[numberOfGroups]();
    return (
      <QF60DragCountersIntoGroups
        numberOfGroups={numberOfGroups}
        title={translate.ks1Instructions.dragToShareNumCountersEquallyBetweenNumBoxes(
          number,
          numBoxesAsWords
        )}
        pdfTitle={translate.ks1PDFInstructions.shareNumCountersEquallyBetweenTheNumBoxes(
          number,
          numBoxesAsWords
        )}
        items={[color]}
        testCorrect={testCorrect}
        maxCapacity={numberOfGroups === 5 ? 4 : undefined}
        prefilledBoxes={{
          type: 'random',
          value: countRange(numberOfGroups).map(() => '<ans/>' as const)
        }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeEqualGroupsSharing',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
