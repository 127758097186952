import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import { ADD } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import MultiRowPlaceValueChartWithOperator from '../../../../components/question/representations/Place Value Chart/MultiRowPlaceValueChartWithOperator';
import { ScientificNotation } from '../../../../utils/math';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhe',
  description: 'bhe',
  keywords: ['Add', 'Base 10', 'Place value chart'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(58),
      numB: z.number().int().min(11).max(58),
      reversed: z.boolean()
    })
    .refine(({ numA, numB }) => numbersDoNotExchange(numA, numB), 'numbers do not exchange'),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(11, 58, {
      constraint: x => x % 10 !== 0 && x % 10 !== 9
    });
    const numB = randomIntegerInclusive(11, 58, {
      constraint: x => x % 10 !== 0 && x !== numA && numbersDoNotExchange(numA, x)
    });
    const reversed = getRandomBoolean();

    return { numA, numB, reversed };
  },
  Component: props => {
    const {
      question: { numA, numB, reversed },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA + numB], ADD, 2, {
      reversed
    });

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <MultiRowPlaceValueChartWithOperator
            numOfRows={2}
            columnsToShow={[1, 0]}
            dimens={dimens}
            numbersPerRow={[
              ScientificNotation.fromNumber(numA),
              ScientificNotation.fromNumber(numB)
            ]}
            operator={ADD}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bhf',
  description: 'bhf',
  keywords: ['Add'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(78),
      numB: z.number().int().min(11).max(78),
      b10Variant: z.enum(['Marbles', 'Balloons', 'Sweets', 'Pencils'])
    })
    .refine(({ numA, numB }) => numbersDoNotExchange(numA, numB), 'numbers do not exchange'),
  simpleGenerator: () => {
    const b10Variant = getRandomFromArray(['Marbles', 'Balloons', 'Sweets', 'Pencils'] as const);

    // The maximum number of items horizontal is 13 due to space/readability constraints
    const sumOfDigits = (x: number) => Math.floor(x / 10) + (x % 10);
    const numA = randomIntegerInclusive(11, b10Variant === 'Marbles' ? 68 : 78, {
      constraint: x => x % 10 !== 0 && x % 10 !== 9 && sumOfDigits(x) < 13
    });
    const numB = randomIntegerInclusive(11, b10Variant === 'Marbles' ? 68 : 78, {
      constraint: x =>
        x % 10 !== 0 && x !== numA && numbersDoNotExchange(numA, x) && sumOfDigits(x) < 13
    });

    return { numA, numB, b10Variant };
  },
  Component: props => {
    const {
      question: { numA, numB, b10Variant },
      translate,
      displayMode
    } = props;

    const answer = numA + numB;

    const [tensImage, onesImage, onesDimens, tensDimens] = (() => {
      switch (b10Variant) {
        case 'Marbles':
          return [
            'Base_Ten/Marbles10',
            'Base_Ten/Marbles1',
            { height: 50, width: 20 },
            { height: 100, width: 80 }
          ] as const;
        case 'Balloons':
          return [
            'Base_Ten/Balloons10',
            'Base_Ten/Balloons1',
            { height: 50, width: 40 },
            { height: 100, width: 70 }
          ] as const;
        case 'Sweets':
          return [
            'Base_Ten/Sweets10',
            'Base_Ten/Sweets1',
            { height: 50, width: 40 },
            { height: 100, width: 70 }
          ] as const;
        case 'Pencils':
          return [
            'pencil_packs/Pack_of_pencils_10',
            'Measure/pencil',
            { height: 50, width: 40 },
            { height: 100, width: 70 }
          ] as const;
      }
    })();

    const digitsA = ScientificNotation.fromNumber(numA).digits;
    const digitsB = ScientificNotation.fromNumber(numB).digits;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => {
          return (
            <View
              style={{
                ...dimens,
                flexDirection: 'column',
                justifyContent: 'space-evenly'
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  columnGap: displayMode === 'digital' ? 10 : 20,
                  alignItems: 'center'
                }}
              >
                {countRange(digitsA[0]).map((_, i) => (
                  <AssetSvg
                    key={i}
                    name={tensImage}
                    width={tensDimens.width * (displayMode === 'digital' ? 1 : 1.5)}
                    height={tensDimens.height * (displayMode === 'digital' ? 1 : 1.5)}
                  />
                ))}
                {countRange(digitsA[1]).map((_, i) => (
                  <AssetSvg
                    key={i}
                    name={onesImage}
                    width={onesDimens.width * (displayMode === 'digital' ? 1 : 1.5)}
                    height={onesDimens.height * (displayMode === 'digital' ? 1 : 1.5)}
                  />
                ))}
              </View>
              <View style={{ flexDirection: 'row', columnGap: 10, alignItems: 'center' }}>
                {/* numB all horizontal, tens then ones */}
                {countRange(digitsB[0]).map((_, i) => (
                  <AssetSvg
                    key={i}
                    name={tensImage}
                    width={tensDimens.width * (displayMode === 'digital' ? 1 : 1.5)}
                    height={tensDimens.height * (displayMode === 'digital' ? 1 : 1.5)}
                  />
                ))}
                {countRange(digitsB[1]).map((_, i) => (
                  <AssetSvg
                    key={i}
                    name={onesImage}
                    width={onesDimens.width * (displayMode === 'digital' ? 1 : 1.5)}
                    height={onesDimens.height * (displayMode === 'digital' ? 1 : 1.5)}
                  />
                ))}
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bhg',
  description: 'bhg',
  keywords: ['Add', 'Bar model'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(88),
      numB: z.number().int().min(11).max(88),
      ansTopOrBottom: z.enum(['top', 'bottom'])
    })
    .refine(({ numA, numB }) => numbersDoNotExchange(numA, numB), 'numbers do not exchange'),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(11, 88, {
      constraint: x => x % 10 !== 0 && x % 10 !== 9
    });
    const numB = randomIntegerInclusive(11, 88, {
      constraint: x => x % 10 !== 0 && x !== numA && numbersDoNotExchange(numA, x)
    });

    const ansTopOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return { numA, numB, ansTopOrBottom };
  },

  Component: props => {
    const {
      question: { numA, numB, ansTopOrBottom },
      translate
    } = props;

    const total = numA + numB;
    const numbers = ansTopOrBottom === 'top' ? [[numA, numB], [total]] : [[total], [numA, numB]];
    const answerIndices = ansTopOrBottom === 'top' ? [[], [0]] : [[0], []];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={total}
        oneFontSize
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwo2DigitNumbersNotAcrossA10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
