import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF59DrawLineGraph from '../../../../components/question/questionFormats/QF59DrawLineGraph';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arraysHaveSameContents, countRange, range } from '../../../../utils/collections';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import TableWithLeftHeaders from '../../../../components/question/representations/TableWithLeftHeaders';
import { colors } from '../../../../theme/colors';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import LineGraph from '../../../../components/question/representations/Coordinates/LineGraph';
import { all, create, number } from 'mathjs';
// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSi',
  description: 'aSi',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z.object({
    answersArray: z.array(z.number().min(5).max(60).step(5)).length(6),
    incorrectStartingPoint: z.number().min(5).max(60).step(5)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(5, 15, 5);
    const number2 = randomIntegerInclusiveStep(number1, 25, 5);
    const number3 = randomIntegerInclusiveStep(number2, 35, 5);
    const number4 = randomIntegerInclusiveStep(number3, 45, 5);
    const number5 = randomIntegerInclusiveStep(number4, 55, 5);
    const number6 = randomIntegerInclusiveStep(number5, 60, 5);

    const answersArray = [number1, number2, number3, number4, number5, number6];

    const incorrectStartingPoint = randomIntegerInclusiveStep(5, 60, 5, {
      constraint: x => !answersArray.includes(x)
    });

    return {
      answersArray,
      incorrectStartingPoint
    };
  },
  Component: props => {
    const {
      question: { answersArray, incorrectStartingPoint },
      translate
    } = props;

    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragPointsToCompleteTheLineGraph()}
        pdfTitle={translate.instructions.drawPointsToCompleteTheLineGraph()}
        defaultState={shuffle([incorrectStartingPoint, 0, 0, 0, 0, 0], {
          random: seededRandom(props.question)
        })}
        correctAnswer={answersArray}
        gridProps={{
          xAxis: 0,
          xMin: 1,
          xMax: 6,
          xStepSize: 1,
          yAxis: 1,
          yMin: 0,
          yMax: 60,
          yStepSize: 10,
          yAxisLabel: translate.misc.massKg(),
          xAxisLabel: translate.timePeriod.week(),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={5}
        questionHeight={1000}
        pdfVariant="plotAllPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aSj',
  description: 'aSj',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z.object({
    answersArray: z.array(z.number().min(5).max(60).step(5)).length(6),
    incorrectStartingPointsArray: z.array(z.number().min(5).max(60).step(5))
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(5, 15, 5);
    const number2 = randomIntegerInclusiveStep(number1, 25, 5);
    const number3 = randomIntegerInclusiveStep(number2, 35, 5);
    const number4 = randomIntegerInclusiveStep(number3, 45, 5);
    const number5 = randomIntegerInclusiveStep(number4, 55, 5);
    const number6 = randomIntegerInclusiveStep(number5, 60, 5);

    const answersArray = [number1, number2, number3, number4, number5, number6];

    const incorrectStartingPointsArray = answersArray;
    // Get 3 random points to be incorrect
    const incorrectIndexes = randomUniqueIntegersInclusive(0, 5, 3);
    // Replace the points with incorrect values
    incorrectIndexes.forEach(
      index =>
        (incorrectStartingPointsArray[index] = randomIntegerInclusiveStep(5, 60, 5, {
          constraint: x => x !== answersArray[index]
        }))
    );

    return {
      answersArray,
      incorrectStartingPointsArray
    };
  },
  Component: props => {
    const {
      question: { answersArray, incorrectStartingPointsArray },
      translate
    } = props;

    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragIncorrectPointsToCorrectTheLineGraph()}
        pdfTitle={translate.instructions.dragIncorrectPointsToCorrectLineGraphPdf(0)}
        defaultState={incorrectStartingPointsArray}
        correctAnswer={answersArray}
        gridProps={{
          xAxis: 0,
          xMin: 1,
          xMax: 6,
          xStepSize: 1,
          yAxis: 1,
          yMin: 0,
          yMax: 60,
          yStepSize: 10,
          yAxisLabel: translate.misc.massKg(),
          xAxisLabel: translate.timePeriod.week(),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={5}
        questionHeight={1000}
        pdfVariant="fixIncorrectPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aSk',
  description: 'aSk',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z.object({
    startingPointYAxis: z.number().min(10).max(50).step(10),
    answersArray: z.array(z.number().min(15).max(110).step(5)).length(6)
  }),
  simpleGenerator: () => {
    const startingPointYAxis = randomIntegerInclusiveStep(10, 50, 10);
    const number1 = randomIntegerInclusiveStep(startingPointYAxis + 5, startingPointYAxis + 15, 5);
    const number2 = randomIntegerInclusiveStep(number1, startingPointYAxis + 25, 5);
    const number3 = randomIntegerInclusiveStep(number2, startingPointYAxis + 35, 5);
    const number4 = randomIntegerInclusiveStep(number3, startingPointYAxis + 45, 5);
    const number5 = randomIntegerInclusiveStep(number4, startingPointYAxis + 55, 5);
    const number6 = randomIntegerInclusiveStep(number5, startingPointYAxis + 60, 5);

    const answersArray = [number1, number2, number3, number4, number5, number6];

    return {
      answersArray,
      startingPointYAxis
    };
  },
  Component: props => {
    const {
      question: { answersArray, startingPointYAxis },
      translate
    } = props;

    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragPointsToCompleteTheLineGraph()}
        pdfTitle={translate.instructions.drawPointsToCompleteTheLineGraph()}
        correctAnswer={answersArray}
        gridProps={{
          xAxis: startingPointYAxis,
          xMin: 1,
          xMax: 6,
          xStepSize: 1,
          yAxis: 1,
          yMin: startingPointYAxis,
          yMax: startingPointYAxis + 60,
          yStepSize: 10,
          yAxisLabel: translate.misc.heightCm(),
          xAxisLabel: translate.timePeriod.week(),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null
        }}
        snapToNearest={5}
        questionHeight={1000}
        pdfVariant="plotAllPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aSl',
  description: 'aSl',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z.object({
    startingPointYAxis: z.number().min(1000).max(4000).step(1000),
    xAxisLabels: z.array(z.number().min(1990).max(2006)).length(6),
    answersArray: z.array(z.number().min(1500).max(10000).step(5)).length(6)
  }),
  simpleGenerator: () => {
    const startingPointYAxis = randomIntegerInclusiveStep(1000, 4000, 1000);
    const startingPointXAxis = randomIntegerInclusive(1990, 2000);
    const number1 = randomIntegerInclusiveStep(
      startingPointYAxis + 500,
      startingPointYAxis + 1500,
      500
    );
    const number2 = randomIntegerInclusiveStep(number1, startingPointYAxis + 2500, 500);
    const number3 = randomIntegerInclusiveStep(number2, startingPointYAxis + 3500, 500);
    const number4 = randomIntegerInclusiveStep(number3, startingPointYAxis + 4500, 500);
    const number5 = randomIntegerInclusiveStep(number4, startingPointYAxis + 5500, 500);
    const number6 = randomIntegerInclusiveStep(number5, startingPointYAxis + 6000, 500);

    const answersArray = [number1, number2, number3, number4, number5, number6];
    const xAxisLabels = range(startingPointXAxis, startingPointXAxis + 5);

    return {
      answersArray,
      startingPointYAxis,
      xAxisLabels
    };
  },
  Component: props => {
    const {
      question: { answersArray, startingPointYAxis, xAxisLabels },
      translate
    } = props;

    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragPointsToCompleteTheLineGraph()}
        pdfTitle={translate.instructions.drawPointsToCompleteTheLineGraph()}
        correctAnswer={answersArray}
        gridProps={{
          xAxis: startingPointYAxis,
          xMin: 1,
          xMax: 6,
          xStepSize: 1,
          yAxis: 1,
          yMin: startingPointYAxis,
          yMax: startingPointYAxis + 6000,
          yStepSize: 1000,
          yAxisLabel: translate.misc.population(),
          xAxisLabel: translate.timePeriod.year(),
          xLabels: xAxisLabels.map(x => x.toString()),
          yLabels: countRange(7).map(i => (startingPointYAxis + i * 1000).toLocaleString()),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null,
          yAxisLabelOffset: 20
        }}
        snapToNearest={500}
        questionHeight={1000}
        pdfVariant="plotAllPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aSm',
  description: 'aSm',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z.object({
    startingPointYAxis: z.number().min(1000).max(4000).step(1000),
    xAxisLabels: z.array(z.number().min(1990).max(2006)).length(6),
    answersArray: z.array(z.number().min(1500).max(10000).step(5)).length(6),
    incorrectStartingPointsArray: z.array(z.number().min(1500).max(10000).step(5)).length(6)
  }),
  simpleGenerator: () => {
    const startingPointYAxis = randomIntegerInclusiveStep(1000, 4000, 1000);
    const startingPointXAxis = randomIntegerInclusive(1990, 2000);
    const number1 = randomIntegerInclusiveStep(
      startingPointYAxis + 500,
      startingPointYAxis + 1500,
      500
    );
    const number2 = randomIntegerInclusiveStep(number1, startingPointYAxis + 2500, 500);
    const number3 = randomIntegerInclusiveStep(number2, startingPointYAxis + 3500, 500);
    const number4 = randomIntegerInclusiveStep(number3, startingPointYAxis + 4500, 500);
    const number5 = randomIntegerInclusiveStep(number4, startingPointYAxis + 5500, 500);
    const number6 = randomIntegerInclusiveStep(number5, startingPointYAxis + 6000, 500);

    const answersArray = [number1, number2, number3, number4, number5, number6];
    const xAxisLabels = range(startingPointXAxis, startingPointXAxis + 5);

    const incorrectPointsCount = randomIntegerInclusive(1, 3);
    const incorrectIndexes = randomUniqueIntegersInclusive(0, 5, incorrectPointsCount);

    const incorrectStartingPointsArray = answersArray.map((number, i) =>
      incorrectIndexes.includes(i)
        ? randomIntegerInclusiveStep(startingPointYAxis + 500, startingPointYAxis + 6000, 500, {
            constraint: x => x !== number
          })
        : number
    );

    return {
      answersArray,
      startingPointYAxis,
      xAxisLabels,
      incorrectStartingPointsArray
    };
  },
  Component: props => {
    const {
      question: { answersArray, startingPointYAxis, xAxisLabels, incorrectStartingPointsArray },
      translate
    } = props;

    return (
      <QF59DrawLineGraph
        title={translate.instructions.dragIncorrectPointsToCorrectTheLineGraph()}
        pdfTitle={translate.instructions.dragIncorrectPointsToCorrectLineGraphPdf(0)}
        correctAnswer={answersArray}
        defaultState={incorrectStartingPointsArray}
        gridProps={{
          xAxis: startingPointYAxis,
          xMin: 1,
          xMax: 6,
          xStepSize: 1,
          yAxis: 1,
          yMin: startingPointYAxis,
          yMax: startingPointYAxis + 6000,
          yStepSize: 1000,
          yAxisLabel: translate.misc.population(),
          xAxisLabel: translate.timePeriod.year(),
          xLabels: xAxisLabels.map(x => x.toString()),
          yLabels: countRange(7).map(i => (startingPointYAxis + i * 1000).toLocaleString()),
          xAxisArrowLabel: null,
          yAxisArrowLabel: null,
          yAxisLabelOffset: 20
        }}
        snapToNearest={500}
        questionHeight={1000}
        pdfVariant="fixIncorrectPoints"
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aSn',
  description: 'aSn',
  keywords: ['Line graphs', 'Draw', 'Axes'],
  schema: z.object({
    startingPointYAxis: z.number().min(10000).max(30000).step(10000),
    xAxisLabels: z.array(z.number().min(1970).max(2010)).length(3),
    isGraphAccurate: z.boolean(),
    correctPopulation: z.array(z.number().min(10000).max(90000).step(5000)).length(3),
    plottedPopulation: z.array(z.number().min(10000).max(90000).step(5000)).length(3)
  }),
  simpleGenerator: () => {
    const startingPointYAxis = randomIntegerInclusiveStep(10000, 30000, 10000);
    const startingPointXAxis = randomIntegerInclusiveStep(1970, 2000, 5);
    const xAxisLabels = [startingPointXAxis, startingPointXAxis + 5, startingPointXAxis + 10];
    const isGraphAccurate = getRandomBoolean();

    const number1 = randomIntegerInclusiveStep(
      startingPointYAxis,
      startingPointYAxis + 20000,
      5000
    );
    const number2 = randomIntegerInclusiveStep(number1 + 5000, startingPointYAxis + 30000, 5000);
    const number3 = randomIntegerInclusiveStep(number2 + 5000, startingPointYAxis + 60000, 5000);

    const correctPopulation = [number1, number2, number3];

    const getIncorrectPopulation = () => {
      const randomNumber = getRandomFromArray([10000, 5000]);
      const randomOp = getRandomFromArray(['-', '+']);
      const randomIndex = getRandomFromArray([0, 1, 2]);

      return correctPopulation.map((population, index) => {
        if (index <= randomIndex) {
          return population < startingPointYAxis + 10000
            ? number(math.evaluate(`${population} + ${randomNumber}`))
            : population > startingPointYAxis + 50000
            ? number(math.evaluate(`${population} - ${randomNumber}`))
            : number(math.evaluate(`${population} ${randomOp} ${randomNumber}`));
        }
        return population;
      });
    };

    const plottedPopulation = getRandomBoolean() ? correctPopulation : getIncorrectPopulation();

    return {
      startingPointYAxis,
      xAxisLabels,
      isGraphAccurate,
      correctPopulation,
      plottedPopulation
    };
  },
  Component: ({
    question: { startingPointYAxis, xAxisLabels, correctPopulation, plottedPopulation },
    translate,
    displayMode
  }) => {
    const drawnAccurately = arraysHaveSameContents(correctPopulation, plottedPopulation);

    return (
      <QF39ContentWithSelectablesOnRight
        title={`${translate.instructions.hasTheLineGraphBeenDrawnAccurately()}<br/>${translate.instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.instructions.hasTheLineGraphBeenDrawnAccurately()}<br/>${translate.instructions.circleYourAnswer()}`}
        selectables={{ true: translate.misc.Yes(), false: translate.misc.No() }}
        selectableTextStyle={{ textTransform: 'uppercase' }}
        correctAnswer={[drawnAccurately ? 'true' : 'false']}
        topContent={
          <TableWithLeftHeaders
            headers={[translate.timePeriod.year(), translate.misc.population()]}
            items={[
              xAxisLabels.map(it => it.toString()),
              correctPopulation.map(it => it.toLocaleString())
            ]}
            style={{ width: '80%', alignSelf: 'center', marginBottom: 20 }}
            headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
            textStyle={displayMode === 'digital' && { fontSize: 21.667, lineHeight: 35 }}
          />
        }
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid
                width={dimens.width}
                height={dimens.height}
                xAxis={startingPointYAxis}
                xMin={1}
                xMax={3}
                xStepSize={1}
                yAxis={1}
                yMin={startingPointYAxis}
                yMax={startingPointYAxis + 60000}
                yStepSize={10000}
                yAxisLabel={translate.misc.population()}
                xAxisLabel={translate.timePeriod.year()}
                xLabels={xAxisLabels.map(it => it.toString())}
                yLabels={countRange(7).map(i => (startingPointYAxis + i * 10000).toLocaleString())}
                xAxisArrowLabel={null}
                yAxisArrowLabel={null}
                yAxisLabelOffset={20}
              >
                <LineGraph points={plottedPopulation} />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DrawLineGraphs',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
