import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  arrayHasNoDuplicates,
  arrayIntersection,
  arraysHaveSameContentsUnordered,
  assertNonEmptyArray
} from '../../../../utils/collections';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { AssetSvg, type SvgName, getSvgInfo } from '../../../../assets/svg';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const q1Variants = ['bicycle', 'house', 'icecream'] as const;
type Q1Variant = (typeof q1Variants)[number];

const q1Parts = {
  bicycle: {
    includedParts: ['Frame', 'Handlebar', 'Pedal', 'Seat', 'Wheel'],
    otherParts: ['Helmet', 'Water_bottle'],
    wholeSvgFilename: 'Bicycle'
  },
  house: {
    includedParts: [
      'Chimney',
      'Door_blue',
      'Roof_brown',
      'Window_bottomLeft',
      'Window_topLeft',
      'Window_topRight'
    ],
    otherParts: ['Door_black', 'Door_red', 'Roof_black'],
    wholeSvgFilename: 'House'
  },
  icecream: {
    includedParts: ['Cone', 'Flake', 'Scoop_strawberry', 'Scoop_vanilla', 'Sprinkles'],
    otherParts: ['Scoop_chocolate', 'Scoop_mint', 'Tub'],
    wholeSvgFilename: 'Icecream'
  }
} as const satisfies Record<
  Q1Variant,
  { includedParts: readonly string[]; otherParts: readonly string[]; wholeSvgFilename: string }
>;

const q1GetWholeSvgName = <Variant extends Q1Variant>(variant: Variant): SvgName => {
  // This cast is clearly justified because we're casting it to the same shape string.
  // The ternary appears to be useless, but for some reason it's needed for typescript to consider each case.
  return `parts_of_a_whole/${variant}/${q1Parts[variant].wholeSvgFilename}` as Variant extends string
    ? `parts_of_a_whole/${Variant}/${(typeof q1Parts)[Variant]['wholeSvgFilename']}`
    : never;
};

const q1GetPartSvgName = <Variant extends Q1Variant>(
  variant: Variant,
  part:
    | (typeof q1Parts)[Variant]['includedParts'][number]
    | (typeof q1Parts)[Variant]['otherParts'][number]
): SvgName => {
  // Water bottle is a special case, as the SVG is located in a different folder
  if (variant === 'bicycle' && part === 'Water_bottle') {
    return 'Water_bottle';
  }
  type Part = Exclude<typeof part, 'Water_bottle'>;

  // This cast is clearly justified because we're casting it to the same shape string.
  // The ternary appears to be useless, but for some reason it's needed for typescript to consider each case.
  return `parts_of_a_whole/${variant}/${part}` as Variant extends string
    ? `parts_of_a_whole/${Variant}/${Part}`
    : never;
};

const q1Schemas = q1Variants.map(variant => {
  const { includedParts, otherParts } = q1Parts[variant];
  const otherPartsStrings: readonly string[] = otherParts; // Safely assign to a less specific type for use later
  return z.object({
    variant: z.literal(variant),
    parts: z
      .enum([...includedParts, ...otherParts])
      .array()
      .length(4)
      .refine(arrayHasNoDuplicates, 'parts must have no duplicates')
      .refine(
        arr => arr.some(part => otherPartsStrings.includes(part)),
        'parts must include at least one part not in the image of the whole'
      )
  });
});

assertNonEmptyArray(q1Schemas); // Needed to make z.discriminatedUnion happy

const Question1 = newQuestionContent({
  uid: 'bj8',
  description: 'bj8',
  keywords: ['Whole', 'Part'],
  schema: z.discriminatedUnion('variant', q1Schemas),
  simpleGenerator: () => {
    const variant = getRandomFromArray(q1Variants);
    const { includedParts, otherParts } = q1Parts[variant];
    const wrongAnswers = randomIntegerInclusive(1, Math.min(otherParts.length, 2));
    const otherPartsToUse = getRandomSubArrayFromArray(otherParts, wrongAnswers);
    const includedPartsToUse = getRandomSubArrayFromArray(includedParts, 4 - wrongAnswers);
    return { variant, parts: shuffle([...otherPartsToUse, ...includedPartsToUse]) };
  },
  Component: ({ question: { variant, parts }, translate, displayMode }) => {
    const { includedParts } = q1Parts[variant];

    // Calculated in advance, not using MeasureView.
    const wholeSvgUsableDimens = { width: 550, height: 455 };
    const wholeSvgName = q1GetWholeSvgName(variant);
    const wholeSvgInfo = getSvgInfo(wholeSvgName);
    const wholeSvgScale = Math.min(
      wholeSvgUsableDimens.width / wholeSvgInfo.width,
      wholeSvgUsableDimens.height / wholeSvgInfo.height
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.thisIsTheWhole()}
        multiSelect
        mainPanelFlexDirection="row"
        itemLayout="grid"
        mainPanelContainer={{ alignSelf: 'stretch' }}
        contentContainerStyle={{ flex: 1 }}
        innerContainerStyle={{ width: null, flex: 1 }}
        itemStyle={{ height: null }}
        numItems={4}
        testCorrect={arrayIntersection(parts, includedParts)}
        renderItems={({ dimens }) =>
          parts.map(part => {
            const svgName = q1GetPartSvgName(variant, part);
            const partSvgInfo = getSvgInfo(svgName);
            // Ensure that parts mostly use the same scale as the SVG for the whole, unless that doesn't fit, in which
            // case they're allowed to be shrunk
            const partSvgScale = Math.min(
              (dimens.width * 0.95) / partSvgInfo.width,
              (dimens.height * 0.95) / partSvgInfo.height,
              wholeSvgScale
            );
            return {
              value: part,
              component: (
                <AssetSvg
                  name={svgName}
                  width={partSvgScale * partSvgInfo.width}
                  height={partSvgScale * partSvgInfo.height}
                />
              )
            };
          })
        }
        Content={
          <>
            <AssetSvg
              name={wholeSvgName}
              width={wholeSvgScale * wholeSvgInfo.width}
              height={wholeSvgScale * wholeSvgInfo.height}
              style={{ flex: 1 }}
            />
            <TextStructure
              style={{ alignSelf: 'flex-start' }}
              sentence={
                displayMode === 'digital'
                  ? translate.ks1Instructions.selectTheParts()
                  : translate.ks1PDFInstructions.tickTheParts()
              }
            />
          </>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bj9',
  description: 'bj9',
  keywords: ['Whole', 'Part'],
  questionHeight: 1200,
  schema: z
    .object({
      partA: z.number().int().min(1).max(19),
      partB: z.number().int().min(1).max(19),
      wholeOrPart: z.enum(['whole', 'part']),
      representation: z.discriminatedUnion('type', [
        z.object({
          type: z.literal('partWholeModel'),
          variation: z.enum(['topDown', 'leftRight'])
        }),
        z.object({
          type: z.literal('barModel')
        })
      ])
    })
    .refine(
      ({ partA, partB }) => [10, 20].includes(partA + partB) && partA !== partB,
      'whole should either be 10 or 20 and parts should be unique'
    ),
  simpleGenerator: () => {
    const whole = getRandomFromArray([10, 20]);
    const partA = randomIntegerInclusive(1, whole - 1, { constraint: x => x !== whole / 2 });
    const partB = whole - partA;
    const wholeOrPart = getRandomFromArray(['whole', 'part'] as const);

    const representation = getRandomBoolean()
      ? { type: 'barModel' as const }
      : {
          type: 'partWholeModel' as const,
          variation: getRandomFromArray(['topDown', 'leftRight'] as const)
        };

    return { partA, partB, wholeOrPart, representation };
  },
  Component: props => {
    const {
      question: { partA, partB, representation, wholeOrPart },
      translate,
      displayMode
    } = props;
    const whole = partA + partB;
    const sentence = wholeOrPart === 'whole' ? 'wholeIsAns' : 'ansIsAPart';

    return (
      <QF1ContentAndSentence
        questionHeight={1100}
        title={translate.ks1Instructions.completeTheSentence()}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => {
          return representation.type === 'barModel' ? (
            <BarModel dimens={dimens} total={whole} numbers={[[whole], [partA, partB]]} />
          ) : (
            <PartWholeModel
              dimens={{
                height: dimens.height,
                width:
                  representation.variation === 'topDown'
                    ? dimens.width
                    : displayMode === 'digital'
                    ? dimens.width * 0.7
                    : dimens.width * 0.6
              }}
              variation={representation.variation}
              top={whole}
              partition={[partA, partB]}
            />
          );
        }}
        sentence={translate.ks1AnswerSentences[sentence]()}
        testCorrect={([userAnswer]) =>
          wholeOrPart === 'whole'
            ? userAnswer === whole.toString()
            : [partA.toString(), partB.toString()].includes(userAnswer)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: wholeOrPart === 'whole' ? [whole.toLocaleString()] : undefined,
          answerText:
            wholeOrPart === 'part'
              ? translate.markScheme.anyOfTheFollowingXYZ({
                  acceptableAnswers: `${partA.toLocaleString()}, ${partB.toLocaleString()}`
                })
              : undefined
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bka',
  description: 'bka',
  keywords: ['Whole', 'Part'],
  questionHeight: 1000,
  schema: z
    .object({
      partA: z.number().int().min(1).max(19),
      partB: z.number().int().min(1).max(19)
    })
    .refine(
      ({ partA, partB }) => [10, 20].includes(partA + partB) && partA !== partB,
      'whole should either be 10 or 20 and parts should be unique'
    ),
  simpleGenerator: () => {
    const whole = getRandomFromArray([10, 20]);
    const partA = randomIntegerInclusive(1, whole - 1, { constraint: x => x !== whole / 2 });
    const partB = whole - partA;

    return { partA, partB };
  },
  Component: props => {
    const {
      question: { partA, partB },
      translate
    } = props;
    const whole = partA + partB;

    return (
      <QF1ContentAndSentences
        questionHeight={1000}
        title={translate.ks1Instructions.completeTheSentences()}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => {
          return <BarModel dimens={dimens} total={whole} numbers={[[whole], [partA, partB]]} />;
        }}
        sentences={[
          translate.ks1AnswerSentences.wholeIsAns(),
          translate.ks1AnswerSentences.ansIsAPart(),
          translate.ks1AnswerSentences.ansIsAnotherPart()
        ]}
        testCorrect={([[userWhole], [userPartA], [userPartB]]) =>
          userWhole === whole.toString() &&
          arraysHaveSameContentsUnordered(
            [userPartA, userPartB],
            [partA.toString(), partB.toString()]
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [whole.toLocaleString()],
            [partA.toLocaleString()],
            [partB.toLocaleString()]
          ],
          answerText: translate.markScheme.partsCanBeInAnyOrder()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'IntroductionToPartsAndWhole',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
