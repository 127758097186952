import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import { ADD, SUB } from '../../../../constants';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import ContentBox from '../../../../components/molecules/ContentBox';
import { View } from 'react-native';
import { colors } from '../../../../theme/colors';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF27MissingDigitColumnOperations, {
  getDecimalMissingDigits,
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aBQ',
  description: 'aBQ',
  keywords: ['Place value chart', 'Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    numberADigits: z.array(z.number().int().min(1).max(6)).length(2),
    numberBDigits: z.array(z.number().int().min(1).max(6)).length(3)
  }),
  simpleGenerator: () => {
    // Limit the digit to 6 (otherwise counters do not fit)
    const { numberADigits, numberBDigits } = rejectionSample(
      () => {
        const numberADigits = Array.from({ length: 2 }, () => randomIntegerInclusive(1, 6));
        const numberBDigits = Array.from({ length: 3 }, () => randomIntegerInclusive(1, 6));

        return { numberADigits, numberBDigits };
      },
      ({ numberADigits: [onesA, tenthsA], numberBDigits: [onesB, tenthsB] }) =>
        onesA + onesB < 10 && tenthsA + tenthsB < 10
    );

    return { numberADigits, numberBDigits };
  },
  Component: ({ question: { numberADigits, numberBDigits }, translate, displayMode }) => {
    const firstNumber = number(math.evaluate(`${numberADigits[0]} + ${numberADigits[1]} / 10`));
    const secondNumber = number(
      math.evaluate(`${numberBDigits[0]} + ${numberBDigits[1]} / 10 + ${numberBDigits[2]} / 100`)
    );

    const correctAnswer = number(math.evaluate(`${firstNumber} + ${secondNumber}`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToHelpCompleteAddition()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(firstNumber)}
            secondNumber={ScientificNotation.fromNumber(secondNumber)}
            rowsToUse={3}
            columnsToShow={[0, -1, -2]}
            counterVariant="greyCounter"
            counterSize={displayMode === 'digital' ? undefined : 70}
            dimens={{ height: dimens.height, width: dimens.width * 0.6 }}
          />
        )}
        sentence={`${firstNumber.toLocaleString()} ${ADD} ${secondNumber.toLocaleString()} = <ans/>`}
        testCorrect={userAnswer => compareFloats(userAnswer[0], correctAnswer)}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

/** @deprecated */
const Question2 = newQuestionContent({
  uid: 'aBR',
  description: 'aBR',
  keywords: ['Place value chart', 'Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    numberADigits: z.array(z.number().int().min(1).max(6)).length(2),
    numberBDigits: z.array(z.number().int().min(1).max(6)).length(3),
    addOrSubtract: z.enum([ADD, SUB])
  }),
  simpleGenerator: () => {
    // Limit digits to 6 (otherwise counters do not fit)
    const { numberADigits, numberBDigits } = rejectionSample(
      () => {
        const numberADigits = Array.from({ length: 2 }, () => randomIntegerInclusive(1, 6));
        const numberBDigits = Array.from({ length: 3 }, () => randomIntegerInclusive(1, 6));

        return { numberADigits, numberBDigits };
      },
      ({ numberADigits: [onesA, tenthsA], numberBDigits: [onesB, tenthsB] }) =>
        onesA + onesB >= 10 !== tenthsA + tenthsB >= 10 // Exchange happens at either ones or tenths, never both (xor)
    );

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { numberADigits, numberBDigits, addOrSubtract };
  },
  Component: props => {
    const {
      question: {
        numberADigits: [onesA, tenthsA],
        numberBDigits: [onesB, tenthsB, hundredthsB],
        addOrSubtract
      },
      translate,
      displayMode
    } = props;

    const firstNumber = number(math.evaluate(`${onesA} + ${tenthsA} / 10`));
    const secondNumber = number(math.evaluate(`${onesB} + ${tenthsB} / 10 + ${hundredthsB} / 100`));

    const random = seededRandom(props.question);

    const correctOption = number(math.evaluate(`${firstNumber} + ${secondNumber}`));
    const incorrectOptions = getRandomSubArrayFromArray(
      [
        number(math.evaluate(`${correctOption} - 1`)),
        number(math.evaluate(`${correctOption} ${addOrSubtract === ADD ? '+' : '-'} 0.1`)),
        number(
          math.evaluate(
            `${onesA} + ${onesB} + 0.1 * (${tenthsA} / 10 + ${tenthsB} / 10 + ${hundredthsB} / 100)`
          )
        ),
        number(
          math.evaluate(
            `${onesA} + ${onesB} + ${tenthsA} / 10 + ${tenthsB} / 10 + 0.1 * ${tenthsB} / 10 + ${hundredthsB} / 100 + (${hundredthsB} / 100 * 0.1)`
          )
        )
      ],
      3,
      { random, constraint: x => arrayHasNoDuplicates([correctOption, x]) }
    );

    const shuffledOptions = shuffle([correctOption, ...incorrectOptions], { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.usePlaceValueChartToSelectCorrectAnswerToXAddY(
          firstNumber,
          secondNumber
        )}
        pdfTitle={translate.instructions.usePlaceValueChartToCircleCorrectAnswerToXAddYPDF(
          firstNumber,
          secondNumber
        )}
        testCorrect={[correctOption]}
        numItems={4}
        itemLayout="row"
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(firstNumber)}
            secondNumber={ScientificNotation.fromNumber(secondNumber)}
            rowsToUse={3}
            columnsToShow={[0, -1, -2]}
            counterVariant="greyCounter"
            counterSize={displayMode === 'digital' ? undefined : 70}
            dimens={{ width: dimens.width, height: dimens.height * 0.9 }}
          />
        )}
        renderItems={shuffledOptions.map(opt => ({
          value: opt,
          component: <Text variant="WRN700">{opt.toLocaleString()}</Text>
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2v2 = newQuestionContent({
  uid: 'aBR2',
  description: 'aBR',
  keywords: ['Place value chart', 'Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    numberADigits: z.array(z.number().int().min(1).max(6)).length(2),
    numberBDigits: z.array(z.number().int().min(1).max(6)).length(3),
    addOrSubtract: z.enum([ADD, SUB])
  }),
  simpleGenerator: () => {
    // Limit digits to 6 (otherwise counters do not fit)
    const { numberADigits, numberBDigits } = rejectionSample(
      () => {
        const numberADigits = Array.from({ length: 2 }, () => randomIntegerInclusive(1, 6));
        const numberBDigits = Array.from({ length: 3 }, () => randomIntegerInclusive(1, 6));

        return { numberADigits, numberBDigits };
      },
      ({ numberADigits: [onesA, tenthsA], numberBDigits: [onesB, tenthsB] }) =>
        onesA + onesB >= 10 !== tenthsA + tenthsB >= 10 // Exchange happens at either ones or tenths, never both (xor)
    );

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { numberADigits, numberBDigits, addOrSubtract };
  },
  Component: props => {
    const {
      question: {
        numberADigits: [onesA, tenthsA],
        numberBDigits: [onesB, tenthsB, hundredthsB],
        addOrSubtract
      },
      translate,
      displayMode
    } = props;

    const firstNumber = number(math.evaluate(`${onesA} + ${tenthsA} / 10`));
    const secondNumber = number(math.evaluate(`${onesB} + ${tenthsB} / 10 + ${hundredthsB} / 100`));

    const random = seededRandom(props.question);

    const correctOption = number(math.evaluate(`${firstNumber} + ${secondNumber}`));
    const incorrectOptions = getRandomSubArrayFromArray(
      [
        number(math.evaluate(`${correctOption} - 1`)),
        number(math.evaluate(`${correctOption} ${addOrSubtract === ADD ? '+' : '-'} 0.1`)),
        number(
          math.evaluate(
            `${onesA} + ${onesB} + ${tenthsA} / 100 + ${tenthsB} / 10 + ${hundredthsB} / 100`
          )
        )
      ],
      2,
      { random, constraint: x => arrayHasNoDuplicates([correctOption, x]) }
    );

    const shuffledOptions = shuffle([correctOption, ...incorrectOptions], { random });

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.workOutXAddYUsePVCToSelectAnswer(firstNumber, secondNumber)}
        pdfTitle={translate.instructions.workOutXAddYUsePVCToCircleAnswer(
          firstNumber,
          secondNumber
        )}
        correctAnswer={[correctOption.toString()]}
        leftContent={
          <MeasureView>
            {dimens => (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(firstNumber)}
                secondNumber={ScientificNotation.fromNumber(secondNumber)}
                rowsToUse={3}
                columnsToShow={[0, -1, -2]}
                counterVariant="greyCounter"
                counterSize={displayMode === 'digital' ? undefined : 70}
                dimens={{ width: dimens.width, height: dimens.height * 0.9 }}
              />
            )}
          </MeasureView>
        }
        selectables={Object.fromEntries(
          shuffledOptions.map(selectable => [selectable.toString(), selectable.toLocaleString()])
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aBS',
  description: 'aBS',
  keywords: ['Column addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().min(1.1).max(9.9),
    number2: z.number().min(0.11).max(7.99),
    topOrBottom: z.enum(['top', 'bottom'])
  }),
  simpleGenerator: () => {
    const number1 =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;
    const number2 =
      randomIntegerInclusive(11, 799, {
        constraint: x => x % 10 !== 0
      }) / 100;

    const topOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return { number1, number2, topOrBottom };
  },
  Component: ({ question: { number1, number2, topOrBottom }, translate }) => {
    const topNumber = topOrBottom === 'top' ? number1 : number2;
    const bottomNumber = topOrBottom === 'top' ? number2 : number1;

    const correctAnswer = number(math.evaluate(`${topNumber} + ${bottomNumber}`));
    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutAddition()}
        topNumber={topNumber}
        bottomNumber={bottomNumber}
        operation={ADD}
        answerNumber={correctAnswer}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aBT',
  description: 'aBT',
  keywords: ['Column addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().min(0.11).max(9.99),
    number2: z.number().min(10.1).max(19.9),
    topOrBottom: z.enum(['top', 'bottom'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(11, 999, 10) / 100;
    const number2 =
      randomIntegerInclusive(101, 199, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const topOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return { number1, number2, topOrBottom };
  },
  Component: ({ question: { number1, number2, topOrBottom }, translate }) => {
    const topNumber = topOrBottom === 'top' ? number1 : number2;
    const bottomNumber = topOrBottom === 'top' ? number2 : number1;

    const correctAnswer = number(math.evaluate(`${topNumber} + ${bottomNumber}`));
    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutAddition()}
        topNumber={topNumber}
        bottomNumber={bottomNumber}
        operation={ADD}
        answerNumber={correctAnswer}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aBU',
  description: 'aBU',
  keywords: ['Column addition', 'Tenths'],
  schema: z.object({
    questionNum1: z.number().min(1.1).max(99.9).multipleOf(0.1),
    questionNum2: z.number().min(0.11).max(9.99),
    numberAOrBFirst: z.enum(['a', 'b'])
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const number1 =
      randomIntegerInclusive(11, 99, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const number2 =
      randomIntegerInclusive(11, 999, {
        constraint: x => x % 10 !== 0
      }) / 100;

    const numberAOrBFirst = getRandomFromArray(['a', 'b'] as const);

    const number3 =
      randomIntegerInclusive(101, 999, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const number4 =
      randomIntegerInclusive(11, 999, {
        constraint: x => x % 10 !== 0
      }) / 100;

    const questionToShow = getRandomFromArray(['question1', 'question2'] as const);

    return {
      questionNum1: questionToShow === 'question1' ? number1 : number3,
      questionNum2: questionToShow === 'question1' ? number2 : number4,
      numberAOrBFirst
    };
  },
  Component: props => {
    const {
      question: { questionNum1, questionNum2, numberAOrBFirst },
      translate
    } = props;

    const [sentence, answer] = [
      numberAOrBFirst === 'a'
        ? `${questionNum1.toLocaleString()} ${ADD} ${questionNum2.toLocaleString()} = <ans/>`
        : `${questionNum2.toLocaleString()} ${ADD} ${questionNum1.toLocaleString()} = <ans/>`,
      math.evaluate(`${questionNum1} + ${questionNum2}`)
    ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.useColumnMethodToWorkOutAddition()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={6}
        questionHeight={800}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aBV',
  description: 'aBV',
  keywords: ['Column addition', 'Tenths', 'Hundredths', 'Select', 'Problem solving'],
  schema: z.object({
    numbers: z.array(z.number().min(0.111).max(19.9)).length(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 }) / 10;
    const number2 =
      randomIntegerInclusive(101, 999, {
        constraint: x => x % 10 !== 0
      }) / 100;
    const number3 =
      randomIntegerInclusive(111, 999, {
        constraint: x => x % 10 !== 0
      }) / 1000;
    const number4 =
      randomIntegerInclusive(101, 199, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const numbers = shuffle([number1, number2, number3, number4]);

    return { numbers };
  },
  Component: ({ question: { numbers }, translate }) => {
    const highestNumber = Math.max(...numbers);
    const secondHighest = Math.max(...numbers.filter(num => num !== highestNumber));

    const correctAnswer = number(math.evaluate(`${highestNumber} + ${secondHighest}`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsTheGreatestTotalByAddingTwoOfTheNumbers()}
        Content={
          <ContentBox>
            <View
              style={{
                height: 250,
                width: 500,
                justifyContent: 'space-around'
              }}
            >
              <View
                style={{
                  height: 100,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}
              >
                <View
                  style={{
                    backgroundColor: colors.prussianBlue,
                    padding: 15,
                    borderRadius: 20,
                    minWidth: 150
                  }}
                >
                  <Text variant="WRN400" style={{ color: colors.white, textAlign: 'center' }}>
                    {numbers[0]}
                  </Text>
                </View>

                <View
                  style={{
                    backgroundColor: colors.prussianBlue,
                    padding: 15,
                    borderRadius: 20,
                    minWidth: 150
                  }}
                >
                  <Text variant="WRN400" style={{ color: colors.white, textAlign: 'center' }}>
                    {numbers[1]}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  height: 100,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}
              >
                <View
                  style={{
                    backgroundColor: colors.prussianBlue,
                    padding: 15,
                    borderRadius: 20,
                    minWidth: 150
                  }}
                >
                  <Text variant="WRN400" style={{ color: colors.white, textAlign: 'center' }}>
                    {numbers[2]}
                  </Text>
                </View>

                <View
                  style={{
                    backgroundColor: colors.prussianBlue,
                    padding: 15,
                    borderRadius: 20,
                    minWidth: 150
                  }}
                >
                  <Text variant="WRN400" style={{ color: colors.white, textAlign: 'center' }}>
                    {numbers[3]}
                  </Text>
                </View>
              </View>
            </View>
          </ContentBox>
        }
        sentence={'<ans/>'}
        testCorrect={answer => compareFloats(answer[0], correctAnswer)}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddDecimalsWithDifferentNumbersOfDecimalPlaces',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
