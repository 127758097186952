import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { all, create, number } from 'mathjs';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { ScientificNotation, digitAtPowerIsNumber } from '../../../../utils/math';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { MarkupAssets } from '../../../../markup';
import { AssetSvg } from '../../../../assets/svg';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQ8',
  description: 'aQ8',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Integer',
    'Number line',
    'Round',
    'Nearest integer',
    'Closer to'
  ],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(9),
    tenths: z
      .number()
      .int()
      .min(1)
      .max(9)
      .refine(val => val !== 5, 'tenths cannot be 5')
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 9);

    const tenths = randomIntegerInclusive(1, 9, {
      constraint: x => x !== 5
    });

    return { startingNumber, tenths };
  },
  Component: props => {
    const {
      question: { startingNumber, tenths },
      translate
    } = props;

    const focusNumber = number(math.evaluate(`${startingNumber} + ${tenths / 10}`));

    // Array of tick values for number line
    const tickValues = [
      startingNumber.toLocaleString(),
      ...filledArray('', 9),
      (startingNumber + 1).toLocaleString()
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.nearestWholeNumberToXIsAns(focusNumber)}
        testCorrect={[Math.round(focusNumber).toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={focusNumber}
            showFocusNumber
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aQ9',
  description: 'aQ9',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Integer',
    'Number line',
    'Round',
    'Nearest integer',
    'Closer to'
  ],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(99)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 99);

    return { startingNumber };
  },
  Component: props => {
    const {
      question: { startingNumber },
      translate
    } = props;

    const focusNumber = number(math.evaluate(`${startingNumber} + 0.5`));

    // Array of tick values for number line
    const tickValues = [
      startingNumber.toLocaleString(),
      ...filledArray('', 9),
      (startingNumber + 1).toLocaleString()
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.numRoundedToTheNearestWholeNumberIsAns(focusNumber)}
        testCorrect={[(startingNumber + 1).toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={focusNumber}
            showFocusNumber
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aRa',
  description: 'aRa',
  keywords: ['Decimal', 'Ones', 'Tenths', 'Round'],
  schema: z.object({
    numberA: z
      .number()
      .min(0.1)
      .max(9.9)
      .multipleOf(0.1)
      .refine(val => val % 1 !== 0, 'numberA cannot be a whole number.'),
    numberB: z
      .number()
      .min(0.1)
      .max(20.9)
      .multipleOf(0.1)
      .refine(val => val % 1 !== 0, 'numberB cannot be a whole number.'),
    numberC: z
      .number()
      .min(0.1)
      .max(100.9)
      .multipleOf(0.1)
      .refine(val => val % 1 !== 0, 'numberC cannot be a whole number.')
  }),
  simpleGenerator: () => {
    const numberA =
      randomIntegerInclusive(1, 99, {
        // Prevent numberA resolving to a whole number
        constraint: x => x % 10 !== 0
      }) / 10;

    const numberB =
      randomIntegerInclusive(1, 209, {
        // Prevent numberB resolving to a whole number
        constraint: x => x % 10 !== 0
      }) / 10;

    const numberC =
      randomIntegerInclusive(1, 1009, {
        // Prevent numberC resolving to a whole number
        constraint: x => x % 10 !== 0
      }) / 10;

    return { numberA, numberB, numberC };
  },
  Component: props => {
    const {
      question: { numberA, numberB, numberC },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundTheNumbersToTheNearestWholeNumber()}
        testCorrect={[
          [Math.round(numberA).toString()],
          [Math.round(numberB).toString()],
          [Math.round(numberC).toString()]
        ]}
        sentences={[
          `${numberA.toLocaleString()} <ans/>`,
          `${numberB.toLocaleString()} <ans/>`,
          `${numberC.toLocaleString()} <ans/>`
        ]}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aRa2',
  description: 'aRa2',
  keywords: ['Decimal', 'Ones', 'Tenths', 'Round'],
  schema: z.object({
    number: z
      .number()
      .min(0.1)
      .max(100.9)
      .multipleOf(0.1)
      .refine(val => val % 1 !== 0, 'numberA cannot be a whole number.')
  }),
  simpleGenerator: () => {
    const number =
      randomIntegerInclusive(1, 1009, {
        // Prevent number resolving to a whole number
        constraint: x => x % 10 !== 0
      }) / 10;

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.roundTheNumberToTheNearestWholeNumber()}
        testCorrect={[Math.round(number).toString()]}
        sentence={`${number.toLocaleString()} <ans/>`}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aRb',
  description: 'aRb',
  keywords: [
    'Decimal',
    'Ones',
    'Tenths',
    'Hundredths',
    'Integer',
    'Number line',
    'Round',
    'Nearest integer',
    'Closer to'
  ],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(9),
    hundredths: z
      .number()
      .int()
      .min(1)
      .max(99)
      .refine(val => val % 10 !== 0, 'hundredths must not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 9);

    const hundredths = randomIntegerInclusive(1, 99, {
      constraint: x => x % 10 !== 0
    });

    return { startingNumber, hundredths };
  },
  Component: props => {
    const {
      question: { startingNumber, hundredths },
      translate
    } = props;

    const focusNumber = number(math.evaluate(`${startingNumber} + ${hundredths / 100}`));

    const midPointNumber = number(math.evaluate(`${startingNumber} + 0.5`));

    // Array of tick values for number line
    const tickValues = [
      startingNumber.toLocaleString(),
      ...filledArray('', 4),
      midPointNumber.toLocaleString(),
      ...filledArray('', 4),
      (startingNumber + 1).toLocaleString()
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.nearestWholeNumberToXIsAns(focusNumber)}
        testCorrect={[Math.round(focusNumber).toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={tickValues}
            dimens={dimens}
            focusNumber={focusNumber}
            showFocusNumber
          />
        )}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aRc',
  description: 'aRc',
  keywords: ['Decimal', 'Ones', 'Tenths', 'Hundredths', 'Round'],
  schema: z.object({
    numberA: z
      .number()
      .min(0.01)
      .max(9.99)
      .multipleOf(0.01)
      .refine(
        val => !digitAtPowerIsNumber(val, 'hundredths', [0]),
        'numberA must not have zero hundredths.'
      ),
    numberB: z
      .number()
      .min(0.01)
      .max(20.99)
      .multipleOf(0.01)
      .refine(
        val => !digitAtPowerIsNumber(val, 'hundredths', [0]),
        'numberB must not have zero hundredths.'
      ),
    numberC: z
      .number()
      .min(0.01)
      .max(100.99)
      .multipleOf(0.01)
      .refine(
        val => !digitAtPowerIsNumber(val, 'hundredths', [0]),
        'numberC must not have zero hundredths.'
      )
  }),
  simpleGenerator: () => {
    const numberA =
      randomIntegerInclusive(1, 999, {
        constraint: x => !digitAtPowerIsNumber(x, 'ones', [0])
      }) / 100;

    const numberB =
      randomIntegerInclusive(1, 2099, {
        constraint: x => !digitAtPowerIsNumber(x, 'ones', [0])
      }) / 100;

    const numberC =
      randomIntegerInclusive(1, 10099, {
        constraint: x => !digitAtPowerIsNumber(x, 'ones', [0])
      }) / 100;

    return { numberA, numberB, numberC };
  },
  Component: props => {
    const {
      question: { numberA, numberB, numberC },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.roundTheNumbersToTheNearestWholeNumber()}
        testCorrect={[
          [Math.round(numberA).toString()],
          [Math.round(numberB).toString()],
          [Math.round(numberC).toString()]
        ]}
        sentences={[
          `${numberA.toLocaleString()} <ans/>`,
          `${numberB.toLocaleString()} <ans/>`,
          `${numberC.toLocaleString()} <ans/>`
        ]}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aRc2',
  description: 'aRc2',
  keywords: ['Decimal', 'Ones', 'Tenths', 'Hundredths', 'Round'],
  schema: z.object({
    number: z
      .number()
      .min(0.01)
      .max(100.99)
      .multipleOf(0.01)
      .refine(
        val => !digitAtPowerIsNumber(val, 'hundredths', [0]),
        'numberA must not have zero hundredths.'
      )
  }),
  simpleGenerator: () => {
    const number =
      randomIntegerInclusive(1, 10099, {
        constraint: x => !digitAtPowerIsNumber(x, 'ones', [0])
      }) / 100;

    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.roundTheNumberToTheNearestWholeNumber()}
        testCorrect={[Math.round(number).toString()]}
        sentence={`${number.toLocaleString()} <ans/>`}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aRd',
  description: 'aRd',
  keywords: ['Decimals', 'Ones', 'Tenths', 'Round'],
  schema: z.object({
    symbol: z.enum(['circle', 'triangle', 'square', 'trapezium']),
    roundedNumber: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const symbol = getRandomFromArray(['circle', 'triangle', 'square', 'trapezium'] as const);

    const roundedNumber = randomIntegerInclusive(1, 99);
    return { symbol, roundedNumber };
  },
  Component: props => {
    const {
      question: { symbol, roundedNumber },
      translate,
      displayMode
    } = props;

    const answerCheck = (answer: string): boolean => {
      const answerAsNum = number(math.evaluate(`${answer}`));

      const answerToSciNot = ScientificNotation.fromNumber(answerAsNum);

      return (
        // Check that the lowest power of the answer is the tenths
        answerToSciNot.resolution === -1 &&
        // Check that the answer rounds to roundedNumber:
        Math.round(answerAsNum) === roundedNumber &&
        // Check that the last character is not 0, i.e. no trailing zeroes:
        answer.charAt(answer.length - 1) !== '0'
      );
    };

    const assetPath = (() => {
      if (displayMode === 'digital') {
        switch (symbol) {
          case 'circle':
            return 'Circles/circle_yellow';
          case 'triangle':
            return 'Triangle';
          case 'square':
            return 'Square';
          case 'trapezium':
            return 'Trapezium/trapezium_isosceles_blue';
        }
      } else {
        switch (symbol) {
          case 'circle':
            return 'Circles/circle_white';
          case 'triangle':
            return 'TriangleWhite';
          case 'square':
            return 'Square/square_white';
          case 'trapezium':
            return 'Trapezium/trapezium_isosceles_white';
        }
      }
    })();

    return (
      <MarkupAssets
        elements={{
          symbol: <AssetSvg name={assetPath} width={64} />
        }}
      >
        <QF2AnswerBoxOneSentence
          sentence={'<ans/> <ans/> <ans/>'}
          title={translate.instructions.symbolRoundedToNearestWholeIsNum(
            `<asset name='symbol'/>`,
            roundedNumber
          )}
          // marginVertical needed to ensure symbols don't touch each other across different lines:
          titleTextStyle={{ marginVertical: 16 }}
          inputMaxCharacters={4}
          extraSymbol="decimalPoint"
          testCorrect={userAnswer =>
            userAnswer.every(ans => answerCheck(ans)) && arrayHasNoDuplicates(userAnswer)
          }
        />
      </MarkupAssets>
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RoundToTheNearestWholeNumber',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question3, Question5]
});
export default SmallStep;
