import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import Grid, {
  GridSvgChildren
} from '../../../../components/question/representations/Coordinates/Grid';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import { arraysHaveSameContents, sortNumberArray } from '../../../../utils/collections';
import { Point2d } from '../../../../utils/vectors';
import {
  isValidIsoscelesTriangle,
  isValidRectangle,
  isValidSquare
} from '../../../../utils/shapes';
import { GridPolygon } from '../../../../utils/gridUtils';
import Text from '../../../../components/typography/Text';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aE2',
  description: 'aE2',
  keywords: ['Coordinate', 'First quadrant', 'Axes'],
  schema: z.object({
    coordinate: z.number().int().min(1).max(8).array().length(2)
  }),
  simpleGenerator: () => ({
    coordinate: [randomIntegerInclusive(1, 8), randomIntegerInclusive(1, 8)]
  }),
  Component: ({ question: { coordinate }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatAreTheCoordinatesOfThePoints(1)}
        sentence="( <ans/> , <ans/> )"
        testCorrect={coordinate.map(it => it.toString())}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={8} yMax={8} squareGrid>
            <GridImage
              mathCoord={coordinate as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aE3',
  description: 'aE3',
  keywords: ['Axes', 'Coordinate', 'Plot', 'First quadrant'],
  schema: z.object({
    coordinate: z.tuple([z.number().int().min(1).max(10), z.number().int().min(1).max(8)])
  }),
  simpleGenerator: () => ({
    coordinate: [randomIntegerInclusive(1, 10), randomIntegerInclusive(1, 8)] as [number, number]
  }),
  Component: ({ question: { coordinate }, translate, displayMode, theme }) => {
    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragTheCircleToThePointXY(
                coordinate[0].toLocaleString(),
                coordinate[1].toLocaleString()
              )
            : translate.instructions.drawACrossToThePointXY(
                coordinate[0].toLocaleString(),
                coordinate[1].toLocaleString()
              )
        }
        snapToGrid
        testCorrect={ans => {
          return arraysHaveSameContents(ans[0], coordinate);
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [coordinate]
        }}
        gridProps={{ xMax: 10, yMax: 8, squareGrid: true }}
        items={[
          {
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aE4',
  description: 'aE4',
  keywords: ['Coordinate', 'Axes', 'Rectangle', 'First quadrant'],
  schema: z
    .object({
      coordinates1: z.tuple([z.number().int().min(0).max(8), z.number().int().min(1).max(8)]),
      coordinates2: z.tuple([z.number().int().min(0).max(8), z.number().int().min(1).max(8)]),
      coordinates3: z.tuple([z.number().int().min(0).max(8), z.number().int().min(1).max(8)]),
      coordinates4: z.tuple([z.number().int().min(0).max(8), z.number().int().min(1).max(8)]),
      answerCoordinates: z.number().int().min(0).max(3).array().length(2)
    })
    .refine(({ coordinates1, coordinates2, coordinates3, coordinates4 }) =>
      isValidRectangle(coordinates1, coordinates2, coordinates3, coordinates4)
    ),
  simpleGenerator: () => {
    const x1 = randomIntegerInclusive(0, 7);
    const x2 = randomIntegerInclusive(x1 + 1, 8);
    const y1 = randomIntegerInclusive(1, 7);
    const y2 = randomIntegerInclusive(y1 + 1, 8);

    const coordinates1 = [x1, y1] as [number, number];
    const coordinates2 = [x1, y2] as [number, number];
    const coordinates3 = [x2, y2] as [number, number];
    const coordinates4 = [x2, y1] as [number, number];

    const answerCoordinates = randomUniqueIntegersInclusive(0, 3, 2);

    return { coordinates1, coordinates2, coordinates3, coordinates4, answerCoordinates };
  },
  Component: props => {
    const {
      question: { coordinates1, coordinates2, coordinates3, coordinates4, answerCoordinates },
      translate,
      displayMode
    } = props;

    const coords = [coordinates1, coordinates2, coordinates3, coordinates4];
    const x = 0;
    const y = 1;

    const maxY = sortNumberArray(
      coords.map(point => point[y]),
      'descending'
    )[0];

    const maxX = sortNumberArray(
      coords.map(point => point[x]),
      'descending'
    )[0];

    const letters = [
      translate.letters.A(),
      translate.letters.B(),
      translate.letters.C(),
      translate.letters.D()
    ];

    const yMaxOffset = displayMode === 'digital' ? 25 : 40;
    const yMinOffset = displayMode === 'digital' ? 0 : 20;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.whatAreTheCoordinatesOfVerticesXY(
          letters[answerCoordinates[0]],
          letters[answerCoordinates[1]]
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid {...dimens} xMax={8} yMax={8} squareGrid>
            {({ mathToSvgX, mathToSvgY }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon points={coords as [number, number][]} showBorder />
                </GridSvgChildren>
                {letters.map((val, i) => (
                  <Text
                    key={i}
                    variant="WRN700"
                    style={{
                      position: 'absolute',
                      top:
                        coords[i][y] === maxY
                          ? mathToSvgY(coords[i][y]) - yMaxOffset
                          : mathToSvgY(coords[i][y]) + yMinOffset,
                      left:
                        coords[i][x] === maxX || coords[i][x] === 0
                          ? mathToSvgX(coords[i][x])
                          : mathToSvgX(coords[i][x] - 0.3),
                      fontSize: displayMode === 'digital' ? 21 : 50,
                      lineHeight: 24,
                      color: colors.prussianBlue
                    }}
                  >
                    {val}
                  </Text>
                ))}
              </>
            )}
          </Grid>
        )}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        sentences={[
          `${letters[answerCoordinates[0]]}  ( <ans/> , <ans/> )`,
          `${letters[answerCoordinates[1]]}  ( <ans/> , <ans/> )`
        ]}
        inputMaxCharacters={3}
        testCorrect={[
          coords[answerCoordinates[0]].map(val => val.toString()),
          coords[answerCoordinates[1]].map(val => val.toString())
        ]}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'aE5',
  description: 'aE5',
  keywords: ['Coordinate', 'Vertex', 'Rectangle', 'Axes', 'Plot'],
  schema: z.object({
    x1: z.number().int().min(2).max(10),
    x2: z.number().int().min(0).max(10),
    y1: z.number().int().min(2).max(8),
    y2: z.number().int().min(0).max(8)
  }),
  simpleGenerator: () => {
    const x1 = randomIntegerInclusive(2, 10);
    const x2 = randomIntegerInclusive(0, 10, { constraint: x => x !== x1 && x1 - x > 1 });

    const y1 = randomIntegerInclusive(2, 8);
    const y2 = randomIntegerInclusive(0, 8, { constraint: x => x !== y1 && y1 - x > 1 });

    return { x1, x2, y1, y2 };
  },
  Component: ({ question: { x1, x2, y1, y2 }, translate, theme, displayMode }) => {
    const coordinates = [
      [x1, y1] as [number, number],
      [x2, y1] as [number, number],
      [x1, y2] as [number, number]
    ];

    const correctAnswer = [x2, y2] as [number, number];
    const isSquare = Math.abs(y1 - y2) === Math.abs(x1 - x2);

    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragPointToCompleteTheVerticesOfAShape(
                isSquare ? translate.shapes.aSquare() : translate.shapes.aRectangle()
              )
            : translate.instructions.drawCrossToCompleteTheVerticesOfAShape(
                isSquare ? translate.shapes.aSquare() : translate.shapes.aRectangle()
              )
        }
        testCorrect={ans => arraysHaveSameContents(ans[0], correctAnswer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer]
        }}
        gridProps={{
          xMax: 10,
          yMax: 8,
          squareGrid: true
        }}
        snapToGrid
        gridChildren={coordinates.map((val, i) => (
          <GridImage
            key={i}
            mathCoord={val}
            item={{
              component:
                displayMode === 'digital'
                  ? 'Coordinates/CirclePointCustomizable'
                  : 'Coordinates/CrossPointCustomizable',
              svgProps: { fill: colors.pacificBlue }
            }}
          />
        ))}
        items={[
          {
            // In PDF mode, we use a cross instead.
            component:
              displayMode === 'digital'
                ? 'Coordinates/CirclePointCustomizable'
                : 'Coordinates/CrossPointCustomizable',
            svgProps: { fill: theme.colors.tertiary }
          }
        ]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aE6',
  description: 'aE6',
  keywords: ['Coordinate', 'Square'],
  schema: z.object({
    x1: z.number().int().min(2).max(12),
    y1: z.number().int().min(2).max(12),
    width: z.number().int().min(2).max(8),
    isHorizontal: z.boolean()
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 8);
    const x1 = randomIntegerInclusive(width, 12);
    const y1 = randomIntegerInclusive(width, 12);
    const isHorizontal = getRandomBoolean();

    return { x1, y1, width, isHorizontal };
  },
  Component: props => {
    const {
      question: { x1, y1, width, isHorizontal },
      translate
    } = props;

    const coord1 = [x1, y1];
    const coord2 = isHorizontal ? [x1, y1 + width] : [x1 + width, y1];

    const coord3 = isHorizontal ? [x1 + width, y1] : [x1, y1 + width];
    const coord4 = [x1 + width, y1 + width];

    const coord5 = isHorizontal ? [x1 - width, y1] : [x1, y1 - width];
    const coord6 = isHorizontal ? [x1 - width, y1 + width] : [x1 + width, y1 - width];

    const exampleAnswer = [
      [...coord3.map(val => val.toLocaleString()), ...coord4.map(val => val.toLocaleString())],
      [...coord5.map(val => val.toLocaleString()), ...coord6.map(val => val.toLocaleString())]
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.hereAreCoordsOfSquareWhatCouldOtherVerticesBe(
          coord1[0],
          coord1[1],
          coord2[0],
          coord2[1]
        )}
        testCorrect={userAnswer =>
          !arraysHaveSameContents(userAnswer[0], userAnswer[1]) &&
          userAnswer.every(sentence =>
            isValidSquare(
              coord1 as [number, number],
              coord2 as [number, number],
              [Number(sentence[0]), Number(sentence[1])],
              [Number(sentence[2]), Number(sentence[3])]
            )
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: exampleAnswer,
          answerText: translate.markScheme.acceptAnyCoordinateThatMakeAShape(
            translate.shapes.squares(1)
          )
        }}
        inputMaxCharacters={2}
        sentences={[
          translate.answerSentences.coordAnsAndcoordAns(),
          translate.answerSentences.coordAnsAndcoordAns()
        ]}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aE62',
  description: 'aE6',
  keywords: ['Coordinate', 'Square'],
  schema: z.object({
    x1: z.number().int().min(2).max(12),
    y1: z.number().int().min(2).max(12),
    width: z.number().int().min(2).max(8),
    isHorizontal: z.boolean()
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(2, 8);
    const x1 = randomIntegerInclusive(width, 12);
    const y1 = randomIntegerInclusive(width, 12);
    const isHorizontal = getRandomBoolean();

    return { x1, y1, width, isHorizontal };
  },
  Component: props => {
    const {
      question: { x1, y1, width, isHorizontal },
      translate
    } = props;

    const coord1 = [x1, y1];
    const coord2 = isHorizontal ? [x1, y1 + width] : [x1 + width, y1];

    const coord3 = isHorizontal ? [x1 + width, y1] : [x1, y1 + width];
    const coord4 = [x1 + width, y1 + width];

    const exampleAnswer = [
      ...coord3.map(val => val.toLocaleString()),
      ...coord4.map(val => val.toLocaleString())
    ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.twoVerticesAreAtXYWhatCouldOtherCoordsBe(
          coord1[0],
          coord1[1],
          coord2[0],
          coord2[1]
        )}
        testCorrect={userAnswer =>
          isValidSquare(
            coord1 as [number, number],
            coord2 as [number, number],
            [Number(userAnswer[0]), Number(userAnswer[1])],
            [Number(userAnswer[2]), Number(userAnswer[3])]
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: exampleAnswer,
          answerText: translate.markScheme.acceptAnyCoordinateThatMakeAShape(
            translate.shapes.squares(1)
          )
        }}
        inputMaxCharacters={2}
        sentence={translate.answerSentences.coordAnsAndcoordAns()}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aE7',
  description: 'aE7',
  keywords: ['Coordinate', 'Triangle'],
  schema: z.object({
    coordinates1: z.number().int().min(35).max(155).array().length(2),
    coordinates2: z.number().int().min(35).max(155).array().length(2),
    coordinates3: z.number().int().min(35).max(155).array().length(2),
    triangleDirection: z.enum(['e', 's', 'w', 'n']),
    coordinateToHide: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const triangleDirection = getRandomFromArray(['e', 's', 'w', 'n'] as const);

    const width = randomIntegerInclusiveStep(20, 80, 10);
    const height = randomIntegerInclusiveStep(20, 80, 10);

    const coordinateToHide = getRandomFromArray(['left', 'right'] as const);

    const { coordinates1, coordinates2, coordinates3 } = rejectionSample(
      () => {
        const coordinate1 = randomIntegerInclusiveStep(35, 75, 5, {
          constraint: x => x + height < 150
        });
        const coordinate2 = randomIntegerInclusiveStep(35, 75, 5, {
          constraint: x => x + width < 150
        });
        const coordinate3 = coordinate1 + height;
        const coordinate4 = coordinate2 + width;

        const coordinate5 = number(math.evaluate(`${coordinate1} + (${height} / 2)`));
        const coordinate6 = number(math.evaluate(`${coordinate2} + (${width} / 2)`));

        const [coordinates1, coordinates2, coordinates3] = (() => {
          switch (triangleDirection) {
            case 'e':
              return [
                [coordinate1, coordinate2],
                [coordinate1, coordinate4],
                [coordinate3, coordinate6]
              ];
            case 's':
              return [
                [coordinate1, coordinate4],
                [coordinate3, coordinate4],
                [coordinate5, coordinate2]
              ];
            case 'w':
              return [
                [coordinate3, coordinate2],
                [coordinate3, coordinate4],
                [coordinate1, coordinate6]
              ];
            default:
              return [
                [coordinate1, coordinate2],
                [coordinate3, coordinate2],
                [coordinate5, coordinate4]
              ];
          }
        })();

        return {
          coordinates1,
          coordinates2,
          coordinates3,
          triangleDirection,
          coordinateToHide
        };
      },
      ({ coordinates1, coordinates2, coordinates3 }) => {
        return isValidIsoscelesTriangle(
          coordinates1 as [number, number],
          coordinates2 as [number, number],
          coordinates3 as [number, number]
        );
      }
    );

    return { coordinates1, coordinates2, coordinates3, coordinateToHide, triangleDirection };
  },
  Component: props => {
    const {
      question: { coordinates1, coordinates2, coordinates3, triangleDirection, coordinateToHide },
      translate,
      displayMode
    } = props;

    const start1 = new Point2d(coordinates1[0], coordinates1[1]);
    const start2 = new Point2d(coordinates2[0], coordinates2[1]);
    const start3 = new Point2d(coordinates3[0], coordinates3[1]);

    const startXPoints = [start1.x, start2.x, start3.x];
    const startYPoints = [start1.y, start2.y, start3.y];

    const longestPointY = startYPoints.find(
      (number, _, arr) => arr.indexOf(number) === arr.lastIndexOf(number)
    );

    const longestPointX = startXPoints.find(
      (number, _, arr) => arr.indexOf(number) === arr.lastIndexOf(number)
    );

    const longestPointYIndex = startYPoints.indexOf(longestPointY as number);

    const longestPointXIndex = startXPoints.indexOf(longestPointX as number);

    const [remainingIndexA, remainingIndexB] = [0, 1, 2].filter(num =>
      triangleDirection === 'n' || triangleDirection === 's'
        ? num !== longestPointYIndex
        : num !== longestPointXIndex
    );

    const leftPoint = [startXPoints[remainingIndexA], startYPoints[remainingIndexA]] as [
      number,
      number
    ];
    const rightPoint = [startXPoints[remainingIndexB], startYPoints[remainingIndexB]] as [
      number,
      number
    ];

    const longestPoints =
      triangleDirection === 'n' || triangleDirection === 's'
        ? ([startXPoints[longestPointYIndex], startYPoints[longestPointYIndex]] as [number, number])
        : ([startXPoints[longestPointXIndex], startYPoints[longestPointXIndex]] as [
            number,
            number
          ]);

    const answer =
      coordinateToHide === 'left'
        ? [leftPoint[0].toString(), leftPoint[1].toString()]
        : [rightPoint[0].toString(), rightPoint[1].toString()];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutTheMissingCoordinateOfTheIsoscelesTriangle()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid
            {...dimens}
            xLabels={null}
            yLabels={null}
            xMax={150}
            yMax={150}
            squareGrid
            hideGridLines
          >
            {({ mathToSvgX, mathToSvgY, svgWidth, svgHeight }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon
                    points={[
                      [start1.x, start1.y],
                      [start2.x, start2.y],
                      [start3.x, start3.y]
                    ]}
                    color={`${colors.white}`}
                    showBorder
                  />
                </GridSvgChildren>
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    left:
                      triangleDirection === 'n'
                        ? mathToSvgX(longestPoints[0]) - 100
                        : triangleDirection === 's'
                        ? mathToSvgX(longestPoints[0]) - 100
                        : triangleDirection === 'e'
                        ? mathToSvgX(longestPoints[0])
                        : mathToSvgX(longestPoints[0]) - 200,
                    right:
                      triangleDirection === 'n'
                        ? svgWidth - (mathToSvgX(longestPoints[0]) + 100)
                        : triangleDirection === 's' && displayMode === 'digital'
                        ? svgWidth - (mathToSvgX(longestPoints[0]) + 100)
                        : triangleDirection === 'e' && displayMode !== 'digital'
                        ? svgWidth - (mathToSvgX(longestPoints[0]) + 200)
                        : triangleDirection === 'w' && displayMode !== 'digital'
                        ? svgWidth - mathToSvgX(longestPoints[0])
                        : svgWidth - (mathToSvgX(longestPoints[0]) + 110),
                    bottom:
                      triangleDirection === 'n'
                        ? svgHeight - mathToSvgY(longestPoints[1])
                        : triangleDirection === 's'
                        ? svgHeight - mathToSvgY(longestPoints[1]) - 20
                        : svgHeight - mathToSvgY(longestPoints[1]) - 10,
                    fontSize: displayMode === 'digital' ? 21 : 40,
                    lineHeight: 24,
                    color: colors.prussianBlue,
                    textAlign: 'center',
                    textShadowColor: 'white',
                    textShadowRadius: 3
                  }}
                >
                  {`(${longestPoints[0].toLocaleString()}, ${longestPoints[1].toLocaleString()})`}
                </Text>
                {coordinateToHide === 'right' ? (
                  <Text
                    variant="WRN700"
                    style={{
                      position: 'absolute',
                      left:
                        triangleDirection === 'n' && displayMode !== 'digital'
                          ? mathToSvgX(leftPoint[0]) - 200
                          : triangleDirection === 's' && displayMode !== 'digital'
                          ? mathToSvgX(leftPoint[0]) - 230
                          : mathToSvgX(leftPoint[0]) - 100,
                      right:
                        (triangleDirection === 'n' && displayMode === 'digital') ||
                        (triangleDirection === 's' && displayMode === 'digital')
                          ? svgWidth - mathToSvgX(leftPoint[0]) - 25
                          : triangleDirection === 'n' && displayMode !== 'digital'
                          ? svgWidth - mathToSvgX(leftPoint[0]) - 50
                          : svgWidth - mathToSvgX(leftPoint[0]) - 100,
                      bottom:
                        triangleDirection === 'e' || triangleDirection === 'w'
                          ? svgHeight - mathToSvgY(leftPoint[1]) - 25
                          : triangleDirection === 'n' && displayMode === 'digital'
                          ? svgHeight - mathToSvgY(leftPoint[1])
                          : svgHeight - mathToSvgY(leftPoint[1]) + 5,
                      fontSize: displayMode === 'digital' ? 21 : 40,
                      lineHeight: 24,
                      color: colors.prussianBlue,
                      textAlign: 'center',
                      textShadowColor: 'white',
                      textShadowRadius: 3
                    }}
                  >
                    {`(${leftPoint[0].toLocaleString()}, ${leftPoint[1].toLocaleString()})`}
                  </Text>
                ) : null}
                {coordinateToHide === 'left' ? (
                  <Text
                    variant="WRN700"
                    style={{
                      position: 'absolute',
                      left:
                        triangleDirection === 'n' || triangleDirection === 's'
                          ? mathToSvgX(rightPoint[0]) - 75
                          : mathToSvgX(rightPoint[0]) - 100,
                      right:
                        (triangleDirection === 'n' && displayMode === 'digital') ||
                        (triangleDirection === 's' && displayMode === 'digital')
                          ? svgWidth - mathToSvgX(rightPoint[0]) - 160
                          : triangleDirection === 's' && displayMode !== 'digital'
                          ? svgWidth - mathToSvgX(rightPoint[0]) - 220
                          : triangleDirection === 'n' && displayMode !== 'digital'
                          ? svgWidth - mathToSvgX(rightPoint[0]) - 220
                          : triangleDirection === 'w'
                          ? svgWidth - mathToSvgX(rightPoint[0]) - 100
                          : svgWidth - (mathToSvgX(rightPoint[0]) + 100),
                      bottom:
                        triangleDirection === 's'
                          ? svgHeight - mathToSvgY(rightPoint[1]) + 5
                          : svgHeight - mathToSvgY(rightPoint[1]),
                      fontSize: displayMode === 'digital' ? 21 : 40,
                      lineHeight: 24,
                      color: colors.prussianBlue,
                      textAlign: 'center',
                      textShadowColor: 'white',
                      textShadowRadius: 3
                    }}
                  >
                    {`(${rightPoint[0].toLocaleString()}, ${rightPoint[1].toLocaleString()})`}
                  </Text>
                ) : null}
              </>
            )}
          </Grid>
        )}
        sentenceStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
        sentence={'( <ans/> , <ans/> )'}
        inputMaxCharacters={3}
        testCorrect={answer}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6v2 = newQuestionContent({
  uid: 'aE72',
  description: 'aE7',
  keywords: ['Coordinate', 'Triangle'],
  schema: z.object({
    coordinates1: z.number().int().min(4).max(20).array().length(2),
    coordinates2: z.number().int().min(4).max(20).array().length(2),
    coordinates3: z.number().int().min(4).max(20).array().length(2),
    triangleDirection: z.enum(['e', 's', 'w', 'n']),
    coordinateToHide: z.enum(['left', 'right'])
  }),
  simpleGenerator: () => {
    const triangleDirection = getRandomFromArray(['e', 's', 'w', 'n'] as const);

    const width = randomIntegerInclusiveStep(4, 10, 2);
    const height = randomIntegerInclusiveStep(4, 10, 2);

    const coordinateToHide = getRandomFromArray(['left', 'right'] as const);

    const { coordinates1, coordinates2, coordinates3 } = rejectionSample(
      () => {
        const coordinate1 = randomIntegerInclusiveStep(4, 20, 2, {
          constraint: x => x + height < 20
        });
        const coordinate2 = randomIntegerInclusiveStep(4, 20, 2, {
          constraint: x => x + width < 20
        });
        const coordinate3 = coordinate1 + height;
        const coordinate4 = coordinate2 + width;

        const coordinate5 = number(math.evaluate(`${coordinate1} + (${height} / 2)`));
        const coordinate6 = number(math.evaluate(`${coordinate2} + (${width} / 2)`));

        const [coordinates1, coordinates2, coordinates3] = (() => {
          switch (triangleDirection) {
            case 'e':
              return [
                [coordinate1, coordinate2],
                [coordinate1, coordinate4],
                [coordinate3, coordinate6]
              ];
            case 's':
              return [
                [coordinate1, coordinate4],
                [coordinate3, coordinate4],
                [coordinate5, coordinate2]
              ];
            case 'w':
              return [
                [coordinate3, coordinate2],
                [coordinate3, coordinate4],
                [coordinate1, coordinate6]
              ];
            default:
              return [
                [coordinate1, coordinate2],
                [coordinate3, coordinate2],
                [coordinate5, coordinate4]
              ];
          }
        })();

        return {
          coordinates1,
          coordinates2,
          coordinates3,
          triangleDirection,
          coordinateToHide
        };
      },
      ({ coordinates1, coordinates2, coordinates3 }) => {
        return isValidIsoscelesTriangle(
          coordinates1 as [number, number],
          coordinates2 as [number, number],
          coordinates3 as [number, number]
        );
      }
    );

    return { coordinates1, coordinates2, coordinates3, coordinateToHide, triangleDirection };
  },
  Component: props => {
    const {
      question: { coordinates1, coordinates2, coordinates3, triangleDirection, coordinateToHide },
      translate,
      displayMode
    } = props;

    const start1 = new Point2d(coordinates1[0], coordinates1[1]);
    const start2 = new Point2d(coordinates2[0], coordinates2[1]);
    const start3 = new Point2d(coordinates3[0], coordinates3[1]);

    const startXPoints = [start1.x, start2.x, start3.x];
    const startYPoints = [start1.y, start2.y, start3.y];

    const longestPointY = startYPoints.find(
      (number, _, arr) => arr.indexOf(number) === arr.lastIndexOf(number)
    );

    const longestPointX = startXPoints.find(
      (number, _, arr) => arr.indexOf(number) === arr.lastIndexOf(number)
    );

    const longestPointYIndex = startYPoints.indexOf(longestPointY as number);

    const longestPointXIndex = startXPoints.indexOf(longestPointX as number);

    const [remainingIndexA, remainingIndexB] = [0, 1, 2].filter(num =>
      triangleDirection === 'n' || triangleDirection === 's'
        ? num !== longestPointYIndex
        : num !== longestPointXIndex
    );

    const leftPoint = [startXPoints[remainingIndexA], startYPoints[remainingIndexA]] as [
      number,
      number
    ];
    const rightPoint = [startXPoints[remainingIndexB], startYPoints[remainingIndexB]] as [
      number,
      number
    ];

    const longestPoints =
      triangleDirection === 'n' || triangleDirection === 's'
        ? ([startXPoints[longestPointYIndex], startYPoints[longestPointYIndex]] as [number, number])
        : ([startXPoints[longestPointXIndex], startYPoints[longestPointXIndex]] as [
            number,
            number
          ]);

    const answer =
      coordinateToHide === 'left'
        ? [leftPoint[0].toString(), leftPoint[1].toString()]
        : [rightPoint[0].toString(), rightPoint[1].toString()];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.theTriangleIsIsoscelesWorkOutCoordinatesOfA()}
        sentenceStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <Grid
            {...dimens}
            xLabels={null}
            yLabels={null}
            xMax={20}
            yMax={20}
            squareGrid
            hideGridLines
          >
            {({ mathToSvgX, mathToSvgY, svgWidth, svgHeight }) => (
              <>
                <GridSvgChildren>
                  <GridPolygon
                    points={[
                      [start1.x, start1.y],
                      [start2.x, start2.y],
                      [start3.x, start3.y]
                    ]}
                    color={`${colors.white}`}
                    showBorder
                  />
                </GridSvgChildren>
                {/* Top point of the triangle */}
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    left:
                      triangleDirection === 'n'
                        ? mathToSvgX(longestPoints[0]) - 100
                        : triangleDirection === 's'
                        ? mathToSvgX(longestPoints[0]) - 100
                        : triangleDirection === 'e'
                        ? mathToSvgX(longestPoints[0])
                        : mathToSvgX(longestPoints[0]) - 200,
                    right:
                      triangleDirection === 'n'
                        ? svgWidth - (mathToSvgX(longestPoints[0]) + 100)
                        : triangleDirection === 's' && displayMode === 'digital'
                        ? svgWidth - (mathToSvgX(longestPoints[0]) + 100)
                        : triangleDirection === 'e' && displayMode !== 'digital'
                        ? svgWidth - (mathToSvgX(longestPoints[0]) + 200)
                        : triangleDirection === 'w' && displayMode !== 'digital'
                        ? svgWidth - mathToSvgX(longestPoints[0])
                        : svgWidth - (mathToSvgX(longestPoints[0]) + 110),
                    bottom:
                      triangleDirection === 'n'
                        ? svgHeight - mathToSvgY(longestPoints[1])
                        : triangleDirection === 's'
                        ? svgHeight - mathToSvgY(longestPoints[1]) - 20
                        : svgHeight - mathToSvgY(longestPoints[1]) - 10,
                    fontSize: displayMode === 'digital' ? 21 : 40,
                    lineHeight: 24,
                    color: colors.prussianBlue,
                    textAlign: 'center',
                    textShadowColor: 'white',
                    textShadowRadius: 3
                  }}
                >
                  {`(${longestPoints[0].toLocaleString()}, ${longestPoints[1].toLocaleString()})`}
                </Text>
                {/* Left point on triangle */}
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    left:
                      triangleDirection === 'n' && displayMode !== 'digital'
                        ? mathToSvgX(leftPoint[0]) - 200
                        : triangleDirection === 's' && displayMode !== 'digital'
                        ? mathToSvgX(leftPoint[0]) - 230
                        : mathToSvgX(leftPoint[0]) - 100,
                    right:
                      (triangleDirection === 'n' && displayMode === 'digital') ||
                      (triangleDirection === 's' && displayMode === 'digital')
                        ? svgWidth - mathToSvgX(leftPoint[0]) - 25
                        : triangleDirection === 'n' && displayMode !== 'digital'
                        ? svgWidth - mathToSvgX(leftPoint[0]) - 50
                        : svgWidth - mathToSvgX(leftPoint[0]) - 100,
                    bottom:
                      triangleDirection === 'e' || triangleDirection === 'w'
                        ? svgHeight - mathToSvgY(leftPoint[1]) - 25
                        : triangleDirection === 'n' && displayMode === 'digital'
                        ? svgHeight - mathToSvgY(leftPoint[1])
                        : svgHeight - mathToSvgY(leftPoint[1]) + 5,
                    fontSize: displayMode === 'digital' ? 21 : 40,
                    lineHeight: 24,
                    color: colors.prussianBlue,
                    textAlign: 'center',
                    textShadowColor: 'white',
                    textShadowRadius: 3
                  }}
                >
                  {coordinateToHide === 'right'
                    ? `(${leftPoint[0].toLocaleString()}, ${leftPoint[1].toLocaleString()})`
                    : translate.letters.A()}
                </Text>
                {/* Right point on triangle */}
                <Text
                  variant="WRN700"
                  style={{
                    position: 'absolute',
                    left:
                      triangleDirection === 'n' || triangleDirection === 's'
                        ? mathToSvgX(rightPoint[0]) - 75
                        : mathToSvgX(rightPoint[0]) - 100,
                    right:
                      (triangleDirection === 'n' && displayMode === 'digital') ||
                      (triangleDirection === 's' && displayMode === 'digital')
                        ? svgWidth - mathToSvgX(rightPoint[0]) - 160
                        : triangleDirection === 's' && displayMode !== 'digital'
                        ? svgWidth - mathToSvgX(rightPoint[0]) - 220
                        : triangleDirection === 'n' && displayMode !== 'digital'
                        ? svgWidth - mathToSvgX(rightPoint[0]) - 220
                        : triangleDirection === 'w'
                        ? svgWidth - mathToSvgX(rightPoint[0]) - 100
                        : svgWidth - (mathToSvgX(rightPoint[0]) + 100),
                    bottom:
                      triangleDirection === 's'
                        ? svgHeight - mathToSvgY(rightPoint[1]) + 5
                        : svgHeight - mathToSvgY(rightPoint[1]),
                    fontSize: displayMode === 'digital' ? 21 : 40,
                    lineHeight: 24,
                    color: colors.prussianBlue,
                    textAlign: 'center',
                    textShadowColor: 'white',
                    textShadowRadius: 3
                  }}
                >
                  {coordinateToHide === 'left'
                    ? `(${rightPoint[0].toLocaleString()}, ${rightPoint[1].toLocaleString()})`
                    : translate.letters.A()}
                </Text>
              </>
            )}
          </Grid>
        )}
        sentence="( <ans/> , <ans/> )"
        inputMaxCharacters={3}
        testCorrect={answer}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TheFirstQuadrant',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
