import { newBlockContent } from '../../../Block';

import SortObjects from './1SortObjects';
import CountObjects from './2CountObjects';
import CountObjectsFromALargerGroup from './3CountObjectsFromALargerGroup';
import RepresentObjects from './4RepresentObjects';
import RecogniseNumbersAsWords from './5RecogniseNumbersAsWords';
import CountOnFromAnyNumber from './6CountOnFromAnyNumber';
import OneMore from './7OneMore';
import CountBackwardsWithin10 from './8CountBackwardsWithin10';
import OneLess from './9OneLess';
import CompareGroupsByMatching from './10CompareGroupsByMatching';
import FewerMoreSame from './11FewerMoreSame';
import LessThanGreaterThanEqualTo from './12LessThanGreaterThanEqualTo';
import CompareNumbers from './13CompareNumbers';
import OrderObjectsAndNumbers from './14OrderObjectsAndNumbers';
import TheNumberLine from './15TheNumberLine';

const Block = newBlockContent({
  block: 'PlaceValueWithin10',
  field: 'Number',
  weeks: [1, 5],
  smallSteps: [
    SortObjects, // Step 1
    CountObjects, // Step 2
    CountObjectsFromALargerGroup, // Step 3
    RepresentObjects, // Step 4
    RecogniseNumbersAsWords, // Step 5
    CountOnFromAnyNumber, // Step 6
    OneMore, // Step 7
    CountBackwardsWithin10, // Step 8
    OneLess, // Step 9
    CompareGroupsByMatching, // Step 10
    FewerMoreSame, // Step 11
    LessThanGreaterThanEqualTo, // Step 12
    CompareNumbers, // Step 13
    OrderObjectsAndNumbers, // Step 14
    TheNumberLine // Step 15
  ]
});
export default Block;
