import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF20aBarModelInteractive from '../../../../components/question/questionFormats/QF20aBarModelInteractive';
import { findFactors } from '../../../../utils/factors';
import QF20bInteractiveBarModelWithSentence from '../../../../components/question/questionFormats/QF20bInteractiveBarModelWithSentence';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { fractionToDecimal } from '../../../../utils/fractions';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { filledArray } from '../../../../utils/collections';
import { FractionsWithArrowsWithState } from '../../../../components/question/representations/ValuesWithArrows/FractionsWithArrows';
import { DIV, MULT } from '../../../../constants';
import { isEqual } from '../../../../utils/matchers';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aoY',
  description: 'aoY',
  keywords: ['Fraction', 'Equivalent'],
  schema: z.object({
    number1: z.number().int().min(3).max(6),
    number2: z.number().int().min(2).max(5),
    number3: z.number().int().min(2).max(4)
  }),
  questionHeight: 1400,
  simpleGenerator: () => {
    const number3 = randomIntegerInclusive(2, 4);
    const number1 = randomIntegerInclusive(3, 6, {
      constraint: x => x * number3 <= 16
    });
    const number2 = randomIntegerInclusive(2, number1 - 1);

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const ans1 = number1 * number3; // Max 16
    const ans2 = (ans1 / number1) * number2;

    const bars = [
      { rows: 1, cols: number1 },
      { rows: 1, cols: ans1 }
    ];

    const sentence = `<frac n='${number2}' d='${number1}'/> = <frac nAns='' dAns=''/>`;

    return (
      <QF20bInteractiveBarModelWithSentence
        title={`${translate.instructions.findAnEquivalentFractionTo(
          `<frac n='${number2}' d='${number1}' />`
        )}<br/>${translate.instructions.shadeBarModelsToHelp()}`}
        testCorrect={{ content: [number2, ans2], sentence: [ans2, ans1] }}
        sentence={sentence}
        bars={bars}
        questionHeight={1400}
        pdfTableHeight={200}
        customMarkSchemeAnswer={{
          answerText: `${translate.markScheme.barModelsDoNotNeedShading()}<br/>${translate.markScheme.acceptEquivalentFractions()}`
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aoZ',
  description: 'aoZ',
  keywords: ['Unit fraction', 'Equivalent'],
  schema: z
    .object({
      rectangleWidth: z.number().int().min(3).max(10),
      rectangleHeight: z.number().int().min(2).max(6),
      numerator: z.number().int(),
      denominator: z.number().int()
    })
    .refine(
      val => (val.rectangleWidth * val.rectangleHeight) % val.denominator === 0,
      'denominator must be a factor of width times height'
    )
    .refine(
      val =>
        2 <= val.numerator &&
        val.numerator < val.denominator &&
        val.denominator < val.rectangleWidth * val.rectangleHeight,
      'must be a proper non-unit fraction'
    )
    .refine(
      val => val.rectangleHeight <= val.rectangleWidth,
      'for display purposes, rectangle height must be not be greater than width'
    ),
  simpleGenerator: () => {
    const rectangleWidth = randomIntegerInclusive(3, 10);
    const rectangleHeight = randomIntegerInclusive(2, 6, {
      constraint: x => x <= rectangleWidth
    });
    const totalCells = rectangleWidth * rectangleHeight;
    // this has to be a factor of totalCells (so that the user needs to shade a whole number of cells) - this is the 1st refine
    // however, also pay attention to the 2nd refine. The numerator needs to be >= 2 and < denominator.
    //  - if we choose denominator=1, then we can only make 0/1 and 1/1, neither of which have suitable numerators
    //  - if we choose denominator=2, then we can only make 0/2, 1/2 or 2/2, same as above
    // therefore the denominator needs to be >= 3.
    // just to check that there's always going to be at least one possible denominator: rectangleWidth is always a factor of totalCells, and also >=3
    const possibleDenominators = findFactors(totalCells).filter(
      factor => factor >= 3 && factor < totalCells
    );
    const denominator = getRandomFromArray(possibleDenominators) as number;
    const numerator = randomIntegerInclusive(2, denominator - 1); // random number >=2 and <denominator
    return { rectangleWidth, rectangleHeight, numerator, denominator };
  },
  Component: props => {
    const {
      question: { rectangleWidth, rectangleHeight, numerator, denominator },
      translate
    } = props;

    const totalCells = rectangleWidth * rectangleHeight;
    const answer = (numerator / denominator) * totalCells;

    return (
      <QF20aBarModelInteractive
        numberOfRows={rectangleHeight}
        numberOfCols={rectangleWidth}
        title={translate.instructions.shadeFractionOfTheRectangle(
          `<frac n='${numerator}' d='${denominator}' />`
        )}
        testCorrect={answer}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'ao0',
  description: 'ao0',
  keywords: ['Unit fraction', 'Equivalent', 'Number line'],
  schema: z
    .object({
      numberLineDenominator: z.number().int().min(3).max(8),
      multiplier: z.number().int().min(2).max(5),
      randomNumerator: z.number().int().min(2).max(7)
    })
    .refine(
      val => val.randomNumerator < val.numberLineDenominator,
      'fraction to find must be proper fraction'
    ),
  simpleGenerator: () => {
    const numberLineDenominator = randomIntegerInclusive(3, 8);
    const multiplier = randomIntegerInclusive(2, 5);
    const randomNumerator = randomIntegerInclusive(2, numberLineDenominator - 1);

    return { numberLineDenominator, multiplier, randomNumerator };
  },
  Component: props => {
    const {
      question: { numberLineDenominator, multiplier, randomNumerator },
      translate
    } = props;

    const denominator = numberLineDenominator * multiplier;
    const numerator = randomNumerator * multiplier;

    const startingNumber = 0;
    const endNumber = 1;
    const tickInterval = 1 / numberLineDenominator;
    const sliderStep = 1 / (numberLineDenominator * 50);

    const fractionToFind = `<frac n='${numerator}' d='${denominator}'/>`;

    // Create array of empty strings
    const numTicks = (endNumber - startingNumber) / tickInterval + 1;

    const tickValues = [];

    for (let i = 0; i < numTicks; i++) {
      tickValues.push(`<frac n='${i}' d='${numberLineDenominator}'/>`);
    }
    tickValues[0] = startingNumber.toLocaleString();

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToShowPositionOfNum(fractionToFind)}
        pdfTitle={translate.instructions.showPositionOfNumPdf(fractionToFind)}
        testCorrect={[
          fractionToDecimal(numerator, denominator) - 1 / (numberLineDenominator * 25),
          fractionToDecimal(numerator, denominator) + 1 / (numberLineDenominator * 25)
        ]}
        min={startingNumber}
        max={endNumber}
        sliderStep={sliderStep}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ao1',
  description: 'ao1',
  keywords: ['Unit fraction', 'Equivalent', 'Number line'],
  schema: z
    .object({
      numberLineDenominator: z.number().int().min(3).max(8),
      randomNumerator: z.number().int().min(2).max(7),
      multiplier: z.number().int().min(2).max(5)
    })
    .refine(
      val => val.randomNumerator < val.numberLineDenominator,
      'fraction to find must be proper fraction'
    ),
  simpleGenerator: () => {
    const numberLineDenominator = randomIntegerInclusive(3, 8);
    const randomNumerator = randomIntegerInclusive(2, numberLineDenominator - 1);

    const multiplier = randomIntegerInclusive(2, 5);

    return { numberLineDenominator, randomNumerator, multiplier };
  },
  Component: props => {
    const {
      question: { numberLineDenominator, randomNumerator, multiplier },
      translate,
      displayMode
    } = props;

    const fracToFindDenominator = numberLineDenominator * multiplier;
    const answer = randomNumerator * multiplier;

    const startingNumber = 0;
    const fractionToFind = `<frac nAns='' d='${fracToFindDenominator}'/>`;

    // Create tick value array
    const tickInterval = 1 / fracToFindDenominator;
    const numTicks = 1 / tickInterval + 1;
    const tickArray = filledArray('', numTicks);

    const tickValues = tickArray.map((tick, index) => {
      if (index % multiplier === 0) {
        return `<frac n='${index / multiplier}' d='${numberLineDenominator}'/>`;
      } else {
        return tick;
      }
    });

    tickValues[0] = startingNumber.toLocaleString();
    tickValues[answer] = fractionToFind;

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeEquivalentFractionOnNumberLine()}
        testCorrect={[answer.toString()]}
        tickValues={tickValues}
        containerStyle={{ top: 20 }}
        textVerticalPadding={displayMode === 'digital' ? 0 : 8}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ao2',
  description: 'ao2',
  keywords: ['Fractions', 'Equivalent'],
  schema: z.object({
    denominator1: z.number().int().min(3).max(10),
    numerator1: z.number().int().min(2).max(9),
    factor1: z.number().int().min(2).max(6),
    factor2: z.number().int().min(2).max(6),
    answer3: z.number().int().min(3).max(10),
    answer4: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 10);
    const numerator1 = randomIntegerInclusive(2, denominator1 - 1);
    const factor1 = randomIntegerInclusive(2, 6);
    const factor2 = randomIntegerInclusive(2, 6);
    const answer3 = randomIntegerInclusive(3, 10);
    const answer4 = randomIntegerInclusive(2, answer3 - 1);

    return { denominator1, numerator1, factor1, factor2, answer3, answer4 };
  },
  Component: props => {
    const {
      question: { denominator1, numerator1, factor1, factor2, answer3, answer4 },
      translate,
      displayMode
    } = props;

    const answer1 = denominator1 * factor1;
    const answer2 = numerator1 * factor1;
    const denominator2 = answer3 * factor2;
    const numerator2 = answer4 * factor2;

    return (
      <QF3Content
        title={translate.instructions.completeEquivalentFractions()}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            <FractionsWithArrowsWithState
              id={'fracA'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              numerators={[numerator1.toString(), '<ans/>']}
              denominators={[denominator1.toString(), '<ans/>']}
              factors={[`${MULT} ${factor1}`, `${MULT} ${factor1}`]}
              arrowDirection="right"
              testCorrect={isEqual([answer2.toString(), answer1.toString()])}
              defaultState={
                displayMode === 'markscheme'
                  ? [answer2.toLocaleString(), answer1.toLocaleString()]
                  : undefined
              }
            />
            <FractionsWithArrowsWithState
              id={'fracB'}
              dimens={{ height: dimens.height, width: dimens.width / 2 }}
              numerators={['<ans/>', numerator2.toString()]}
              denominators={['<ans/>', denominator2.toString()]}
              factors={[`${DIV} ${factor2}`, `${DIV} ${factor2}`]}
              arrowDirection="left"
              testCorrect={isEqual([answer4.toString(), answer3.toString()])}
              defaultState={
                displayMode === 'markscheme'
                  ? [answer4.toLocaleString(), answer3.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ao3',
  description: 'ao3',
  keywords: ['Fractions', 'Equivalent'],
  schema: z.object({
    number1: z.number().int().min(3).max(7),
    number2: z.number().int().min(2).max(6),
    number3: z.number().int().min(2).max(10),
    number5: z.number().int().min(7).max(12),
    number6: z.number().int().min(2).max(11),
    number7: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 7);
    const number2 = randomIntegerInclusive(2, number1 - 1);
    const number3 = randomIntegerInclusive(2, 10);

    const number5 = randomIntegerInclusive(7, 12);
    const number6 = randomIntegerInclusive(2, number5 - 1);
    const number7 = randomIntegerInclusive(2, 10);

    return { number1, number2, number3, number5, number6, number7 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number5, number6, number7 },
      translate
    } = props;

    const number4 = number2 * number3;
    const answer1 = number1 * number3;

    const number8 = number5 * number7;
    const answer2 = number6 * number7;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeEquivalentFractions()}
        testCorrect={[[answer1.toString()], [answer2.toString()]]}
        sentences={[
          `<frac n='${number2}' d='${number1}'/> = <frac n='${number4}' dAns=''/>`,
          `<frac n='${number6}' d='${number5}'/> = <frac nAns='' d='${number8}'/>`
        ]}
        fractionContainerStyle={{ height: 96 }}
        pdfContainerStyle={{ rowGap: 128 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindFractionsEquivalentToANonUnitFraction',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
