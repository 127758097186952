import { ADD } from '../../../constants';
import QF1ContentAndSentence from './QF1ContentAndSentence';
import { BaseTenRepCalcGridsAndScale } from '../representations/Base Ten/BaseTenRepresentations';
import { PartitionNumbersBlockRepresentation } from '../representations/PartitionNumbersBlockRepresentation';
import { useI18nContext } from '../../../i18n/i18n-react';
import { arraysHaveSameContentsUnordered } from '../../../utils/collections';

type Props = {
  number: number;
  leftPart: number;
};

export default function IdentifyPartition({ number, leftPart }: Props) {
  const rightPart = number - leftPart;
  const translate = useI18nContext().LL;

  return (
    <QF1ContentAndSentence
      title={translate.instructions.usePartitionToCompleteTheNumberSentence()}
      testCorrect={userAnswer =>
        arraysHaveSameContentsUnordered(userAnswer, [leftPart.toString(), rightPart.toString()])
      }
      customMarkSchemeAnswer={{
        answersToDisplay: [leftPart.toLocaleString(), rightPart.toLocaleString()]
      }}
      inputMaxCharacters={2}
      sentence={`${number} = <ans/> ${ADD} <ans/>`}
      Content={({ dimens }) => {
        const usableWidth = dimens.width;
        const usableHeight = dimens.height;
        const leftTens = Math.floor((leftPart / 10) % 10);
        const leftOnes = Math.floor(leftPart % 10);
        const rightTens = Math.floor((rightPart / 10) % 10);
        const rightOnes = Math.floor(rightPart % 10);
        const scale = Math.min(
          BaseTenRepCalcGridsAndScale(
            usableWidth / 2,
            usableHeight,
            { ones: leftOnes, tens: leftTens },
            'Cubes'
          ).scale,
          BaseTenRepCalcGridsAndScale(
            usableWidth / 2,
            usableHeight,
            { ones: rightOnes, tens: rightTens },
            'Cubes'
          ).scale
        );

        return (
          <PartitionNumbersBlockRepresentation
            usableWidth={usableWidth}
            usableHeight={usableHeight}
            partition={[leftPart, rightPart]}
            scale={scale}
          />
        );
      }}
    />
  );
}
