// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import InterpretCharts from './1InterpretCharts';
import ComparisonSumAndDifference from './2ComparisonSumAndDifference';
import InterpretLineGraphs from './3InterpretLineGraphs';
import DrawLineGraphs from './4DrawLineGraphs';

const Block = newBlockContent({
  block: 'Statistics',

  weeks: [10, 10],
  smallSteps: [
    InterpretCharts, // Step 1
    ComparisonSumAndDifference, // Step 2
    InterpretLineGraphs, // Step 3
    DrawLineGraphs // Step 4
  ]
});
export default Block;
