import { View } from 'react-native';
import { all, create, number } from 'mathjs';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { SUB } from '../../../../constants';
import { characterNameLabel, getCharacterHeadImage } from '../../../../utils/characters';
import { range } from '../../../../utils/collections';
import QF27MissingDigitColumnOperations, {
  getDecimalMissingDigits,
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aBK',
  description: 'aBK',
  keywords: ['Place value chart', 'Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().int().min(20).max(80).step(10),
    number2: z.number().int().min(3).max(8),
    number3: z.number().int().min(3).max(8),
    number4: z.number().int().min(3).max(8),
    number6: z.number().int().min(10).max(70).step(10),
    number7: z.number().int().min(1).max(7),
    number8: z.number().int().min(1).max(7),
    number9: z.number().int().min(1).max(7),
    arrayIndex: z.number().int().min(0).max(2),
    chosenQuestion: z.enum(['question1', 'question2'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(20, 80, 10);
    const number2 = randomIntegerInclusive(3, 8);
    const number3 = randomIntegerInclusive(3, 8);
    const number4 = randomIntegerInclusive(3, 8);
    const number6 = randomIntegerInclusiveStep(10, number1 - 10, 10);
    const number7 = randomIntegerInclusive(1, number2 - 1);
    const number8 = randomIntegerInclusive(1, number3 - 1);
    const number9 = randomIntegerInclusive(1, number4 - 1);
    const arrayIndex = randomIntegerInclusive(0, 2);
    const chosenQuestion = getRandomFromArray(['question1', 'question2'] as const);

    return {
      number1,
      number2,
      number3,
      number4,
      number6,
      number7,
      number8,
      number9,
      arrayIndex,
      chosenQuestion
    };
  },
  Component: ({
    question: {
      number1,
      number2,
      number3,
      number4,
      number6,
      number7,
      number8,
      number9,
      arrayIndex,
      chosenQuestion
    },
    translate
  }) => {
    const number5 = number(
      math.evaluate(`${number1} + ${number2} + ${number3} / 10 + ${number4} / 100`)
    );
    const number10 = number(
      math.evaluate(`${number6} + ${number7} + ${number8} / 10 + ${number9} / 100`)
    );

    const choice1 = number(math.evaluate(`${number6} + ${number7} + ${number9} / 100`));
    const choice2 = number(math.evaluate(`${number6} + ${number8} / 10 + ${number9} / 100`));
    const choice3 = number(math.evaluate(`${number7} + ${number8} / 10 + ${number9} / 100`));
    const choices = [choice1, choice2, choice3];

    const number11 = choices[arrayIndex];

    const correctAnswer =
      chosenQuestion === 'question1'
        ? number(math.evaluate(`${number5} - ${number10}`))
        : number(math.evaluate(`${number5} - ${number11}`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeTheSubtractionUseThePlaceValueChartToHelp()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number5)}
            columnsToShow={[1, 0, -1, -2]}
            counterVariant={'greyCounter'}
            dimens={dimens}
          />
        )}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        sentence={
          chosenQuestion === 'question1'
            ? `${number5.toLocaleString()} ${SUB} ${number10.toLocaleString()} = <ans/>`
            : `${number5.toLocaleString()} ${SUB} ${number11.toLocaleString()} = <ans/>`
        }
        testCorrect={answer => compareFloats(answer[0], correctAnswer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'aBL',
  description: 'aBL',
  keywords: ['Column subtraction', 'Tenths'],
  schema: z
    .object({
      number1: z.number().int().min(21).max(99),
      number2: z.number().int().min(11).max(98)
    })
    .refine(val => val.number1 > val.number2, 'number2 must be less than number1'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 99, {
      constraint: x => x % 10 !== 0
    });
    const number2 = randomIntegerInclusive(11, number1 - 1, {
      constraint: x => x % 10 !== 0
    });
    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const decimal1 = number1 / 10;
    const decimal2 = number2 / 10;
    const correctAnswer = number(math.evaluate(`${decimal1}-${decimal2}`));
    const scientificNotation = ScientificNotation.fromNumber(correctAnswer);
    const answerMissingDigits = range(
      scientificNotation.resolution,
      correctAnswer.toString().length - 1 + scientificNotation.resolution - 1
    );

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutSubtraction()}
        topNumber={decimal1}
        bottomNumber={decimal2}
        operation={SUB}
        answerNumber={correctAnswer}
        persistExchangeBoxes
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aBM',
  description: 'aBM',
  keywords: ['Column subtraction', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      number1: z.number().int().min(201).max(999),
      number2: z.number().int().min(101).max(998)
    })
    .refine(val => val.number1 > val.number2, 'number2 must be less than number1'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(201, 999, {
      constraint: x => x % 10 !== 0
    });
    const number2 = randomIntegerInclusive(101, number1 - 1, {
      constraint: x => x % 10 !== 0
    });
    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const decimal1 = number1 / 100;
    const decimal2 = number2 / 100;
    const correctAnswer = number(math.evaluate(`${decimal1}-${decimal2}`));
    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutSubtraction()}
        topNumber={decimal1}
        bottomNumber={decimal2}
        operation={SUB}
        answerNumber={correctAnswer}
        persistExchangeBoxes
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aBN',
  description: 'aBN',
  keywords: ['Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    height1: z.number().int().min(81).max(169),
    height2: z.number().int().min(81).max(169),
    name1: nameSchema,
    name2: nameSchema
  }),
  simpleGenerator: () => {
    const [height1, height2] = randomUniqueIntegersInclusive(81, 169, 2, {
      constraint: x => x % 10 !== 0
    });

    const [name1, name2] = getRandomUniqueNames(2);

    return { height1, height2, name1, name2 };
  },
  Component: props => {
    const {
      question: { height1, height2, name1, name2 },
      translate
    } = props;

    const height1InMeters = height1 / 100;
    const height2InMeters = height2 / 100;
    const correctAnswer = number(math.evaluate(`${height1InMeters} - ${height2InMeters}`));

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.ansM()}
        title={translate.instructions.char1AndChar2HeightDifference(
          name1,
          name2,
          height1InMeters,
          height2InMeters
        )}
        testCorrect={answer => compareFloats(answer[0], Math.abs(correctAnswer))}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-evenly' }]}>
            <View
              style={[
                dimens,
                {
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  width: dimens.width * 0.3
                }
              ]}
            >
              {getCharacterHeadImage(name1, dimens.height * 0.6, dimens.width * 0.3)}
              {characterNameLabel(name1, dimens.width * 0.3)}
            </View>
            <View
              style={[
                dimens,
                {
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  width: dimens.width * 0.3
                }
              ]}
            >
              {getCharacterHeadImage(name2, dimens.height * 0.6, dimens.width * 0.3)}
              {characterNameLabel(name2, dimens.width * 0.3)}
            </View>
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [Math.abs(correctAnswer).toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aBN2',
  description: 'aBN',
  keywords: ['Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    height1: z.number().int().min(81).max(169),
    height2: z.number().int().min(81).max(169),
    name1: nameSchema,
    name2: nameSchema
  }),
  simpleGenerator: () => {
    const [height1, height2] = randomUniqueIntegersInclusive(81, 169, 2, {
      constraint: x => x % 10 !== 0
    });

    const [name1, name2] = getRandomUniqueNames(2);

    return { height1, height2, name1, name2 };
  },
  Component: props => {
    const {
      question: { height1, height2, name1, name2 },
      translate
    } = props;

    const height1InMeters = height1 / 100;
    const height2InMeters = height2 / 100;
    const correctAnswer = number(math.evaluate(`${height1InMeters} - ${height2InMeters}`));

    return (
      <QF2AnswerBoxOneSentence
        sentence={translate.answerSentences.ansM()}
        title={translate.instructions.char1AndChar2HeightDifference(
          name1,
          name2,
          height1InMeters,
          height2InMeters
        )}
        testCorrect={answer => compareFloats(answer[0], Math.abs(correctAnswer))}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{
          answersToDisplay: [Math.abs(correctAnswer).toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aBO',
  description: 'aBO',
  keywords: ['Column subtraction', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      number1: z.number().int().min(1101).max(1999),
      number2: z.number().int().min(1001).max(1899)
    })
    .refine(val => val.number1 > val.number2, 'number2 must be less than number1'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1101, 1999, {
      constraint: x => x % 10 !== 0
    });
    const number2Min = Math.min(number1 - 1, 1899);
    const number2 = randomIntegerInclusive(1001, number2Min, {
      constraint: x => x % 10 !== 0
    });
    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const decimal1 = number1 / 100;
    const decimal2 = number2 / 100;
    const correctAnswer = number(math.evaluate(`${decimal1}-${decimal2}`));
    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutSubtraction()}
        topNumber={decimal1}
        bottomNumber={decimal2}
        operation={SUB}
        answerNumber={correctAnswer}
        answerMissingDigits={answerMissingDigits}
        persistExchangeBoxes
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aBP',
  description: 'aBP',
  keywords: ['Column subtraction', 'Tenths', 'Hundredths', 'Thousandths'],
  schema: z
    .object({
      number1: z.number().int().min(2001).max(9999),
      number2: z.number().int().min(111).max(9998)
    })
    .refine(val => val.number1 > val.number2, 'number2 must be less than number1'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2001, 9999, {
      constraint: x => x % 10 !== 0
    });
    const number2 = randomIntegerInclusive(111, number1 - 1, {
      constraint: x => x % 10 !== 0
    });
    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const decimal1 = number(math.evaluate(`${number1}/1000`));
    const decimal2 = number(math.evaluate(`${number2}/1000`));
    const correctAnswer = number(math.evaluate(`${decimal1}-${decimal2}`));
    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 3);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutSubtraction()}
        topNumber={decimal1}
        bottomNumber={decimal2}
        operation={SUB}
        answerNumber={correctAnswer}
        answerMissingDigits={answerMissingDigits}
        persistExchangeBoxes
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: correctAnswer
              .toString()
              .replace('.', '')
              .padEnd(answerMissingDigits.length, '0')
              .split('')
              .reverse()
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractDecimalsWithTheSameNumberOfDecimalPlaces',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
