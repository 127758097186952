import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { range } from '../../../../utils/collections';
import { NumberLineVariableTick } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';
import { SUB } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  PartWholeModel,
  TextPartition
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcY',
  description: 'bcY',
  keywords: ['Part-whole model', 'Fact families', 'Add', 'Missing number'],
  questionHeight: 1200,
  schema: z
    .object({
      numberA: z.number().int().min(2).max(18),
      numberB: z.number().int().min(2).max(18),
      answerNumberA: z.boolean(),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft'])
    })
    .refine(
      val => val.numberA + val.numberB <= 20,
      'numberA + numberB must be less than or equal to 20'
    ),
  simpleGenerator: () => {
    // Both numberA and numberB need to be a min of 2 to prevent possible duplicate answers.
    const numberA = randomIntegerInclusive(2, 18);

    const numberB = randomIntegerInclusive(2, 20 - numberA);

    const answerNumberA = getRandomBoolean();

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { numberA, numberB, answerNumberA, variation };
  },
  Component: props => {
    const {
      question: { numberA, numberB, answerNumberA, variation },
      translate
    } = props;

    const correctAnswer = answerNumberA ? numberA : numberB;

    const total = numberA + numberB;

    const totalOfShownNumbers = total + (answerNumberA ? numberB : numberA);

    const random = seededRandom(props.question);

    const otherIncorrectAnswer = getRandomFromArray([correctAnswer + 1, correctAnswer - 1], {
      random
    });

    const items = shuffle(
      [
        correctAnswer.toLocaleString(),
        totalOfShownNumbers.toLocaleString(),
        otherIncorrectAnswer.toLocaleString()
      ],
      { random }
    );

    const partition: TextPartition = answerNumberA ? [numberB, ''] : ['', numberA];

    const sentence = answerNumberA
      ? `<ans/> + ${numberB.toLocaleString()} = ${total.toLocaleString()}`
      : `${numberA.toLocaleString()} + <ans/> = ${total.toLocaleString()}`;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheNumberSentence()}
        pdfTitle={translate.ks1PDFInstructions.useACardToCompleteTheNumberSentence()}
        pdfLayout="itemsAboveContent"
        testCorrect={[correctAnswer.toLocaleString()]}
        actionPanelVariant="end"
        mainPanelStyle={{ flexDirection: 'row' }}
        questionHeight={1200}
        Content={({ dimens }) => (
          <PartWholeModel
            top={total}
            variation={variation}
            partition={partition}
            isInteractive
            // Reduce width slightly to add more space between part-whole model and sentence.
            dimens={{ height: dimens.height, width: dimens.width * 0.75 }}
          />
        )}
        items={items}
        sentence={sentence}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcZ',
  description: 'bcZ',
  keywords: ['Missing number', 'Inverse', 'Number line'],
  schema: z
    .object({
      minuend: z.number().int().min(2).max(15),
      subtrahend: z.number().int().min(1).max(14),
      sentenceArrangement: z.enum([
        'Subtraction minuend',
        'Subtraction subtrahend',
        'Addition difference',
        'Addition subtrahend'
      ])
    })
    .refine(val => val.minuend > val.subtrahend, 'minuend must be more than subtrahend'),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(2, 15);

    const subtrahend = randomIntegerInclusive(1, minuend - 1);

    const sentenceArrangement = getRandomFromArray([
      'Subtraction minuend',
      'Subtraction subtrahend',
      'Addition difference',
      'Addition subtrahend'
    ] as const);

    return { minuend, subtrahend, sentenceArrangement };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend, sentenceArrangement },
      translate,
      displayMode
    } = props;

    const difference = minuend - subtrahend;

    const tickValues = range(0, 15).map(num => ({
      position: num,
      label: num.toLocaleString()
    }));

    const jumpArrowArray =
      sentenceArrangement === 'Subtraction minuend' ||
      sentenceArrangement === 'Subtraction subtrahend'
        ? range(minuend, difference + 1).map(num => ({
            start: num,
            end: num - 1,
            label: ''
          }))
        : range(difference, minuend - 1).map(num => ({
            start: num,
            end: num + 1,
            label: ''
          }));

    const [sentence, answer] = (() => {
      switch (sentenceArrangement) {
        case 'Subtraction minuend':
          return [
            `<ans/> ${SUB} ${subtrahend.toLocaleString()} = ${difference.toLocaleString()}`,
            minuend.toString()
          ];
        case 'Subtraction subtrahend':
          return [
            `${minuend.toLocaleString()} ${SUB} <ans/> = ${difference.toLocaleString()}`,
            subtrahend.toString()
          ];
        case 'Addition difference':
          return [
            `<ans/> + ${subtrahend.toLocaleString()} = ${minuend.toLocaleString()}`,
            difference.toString()
          ];
        case 'Addition subtrahend':
          return [
            `${difference.toLocaleString()} + <ans/> = ${minuend.toLocaleString()}`,
            subtrahend.toString()
          ];
      }
    })();

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheNumberSentence()}
        sentence={sentence}
        testCorrect={[answer]}
        Content={({ dimens }) => (
          <NumberLineVariableTick
            tickValues={tickValues}
            dimens={dimens}
            customFontSize={displayMode === 'digital' ? 32 : 50}
            start={0}
            end={15}
            jumpArrowArray={jumpArrowArray}
            subtraction={
              sentenceArrangement === 'Subtraction minuend' ||
              sentenceArrangement === 'Subtraction subtrahend'
            }
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bc0',
  description: 'bc0',
  keywords: ['Missing number', 'Inverse', 'Add', 'Subtract'],
  schema: z
    .object({
      minuend: z.number().int().min(1).max(15),
      subtrahend: z.number().int().min(0).max(15),
      sentenceArrangement: z.enum([
        'Subtraction minuend',
        'Subtraction subtrahend',
        'Addition difference',
        'Addition subtrahend'
      ])
    })
    .refine(
      val => val.minuend >= val.subtrahend,
      'minuend must greater than or equal to subtrahend'
    ),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(1, 15);

    const subtrahend = randomIntegerInclusive(0, minuend);

    const sentenceArrangement = getRandomFromArray([
      'Subtraction minuend',
      'Subtraction subtrahend',
      'Addition difference',
      'Addition subtrahend'
    ] as const);

    return { minuend, subtrahend, sentenceArrangement };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend, sentenceArrangement },
      translate
    } = props;

    const difference = minuend - subtrahend;

    const [sentence, answer] = (() => {
      switch (sentenceArrangement) {
        case 'Subtraction minuend':
          return [
            `<ans/> ${SUB} ${subtrahend.toLocaleString()} = ${difference.toLocaleString()}`,
            minuend.toString()
          ];
        case 'Subtraction subtrahend':
          return [
            `${minuend.toLocaleString()} ${SUB} <ans/> = ${difference.toLocaleString()}`,
            subtrahend.toString()
          ];
        case 'Addition difference':
          return [
            `<ans/> + ${subtrahend.toLocaleString()} = ${minuend.toLocaleString()}`,
            difference.toString()
          ];
        case 'Addition subtrahend':
          return [
            `${difference.toLocaleString()} + <ans/> = ${minuend.toLocaleString()}`,
            subtrahend.toString()
          ];
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={
          sentenceArrangement === 'Subtraction minuend' ||
          sentenceArrangement === 'Subtraction subtrahend'
            ? translate.ks1Instructions.completeTheSubtraction()
            : translate.ks1Instructions.completeTheAddition()
        }
        sentence={sentence}
        testCorrect={[answer]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MissingNumberProblems',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
