import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import Svg, { Line } from 'react-native-svg';
import { colors } from '../../../../theme/colors';
import { Dimens } from '../../../../theme/scaling';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import QF43DraggableLineOnTopOfStaticRuler from '../../../../components/question/questionFormats/QF43DraggableLineOnTopOfStaticRuler';
import { getRandomUniqueFlags } from '../../../../utils/countries';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  getRandomShapeWithSymmetryLines,
  getRandomUniqueSymmetryImages,
  symmeticalShapes
} from '../../../../utils/shapeImages/symmetry';

////
// Questions
////

const lineSVG = (
  line: 'horizontal' | 'vertical' | 'diag1' | 'diag2',
  dimens: Dimens,
  strokeColor: string
) => {
  switch (line) {
    case 'horizontal':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={dimens.height / 2}
            x2={dimens.width - 10}
            y2={dimens.height / 2}
            stroke={strokeColor}
            strokeWidth={4}
          />
        </Svg>
      );
    case 'vertical':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={dimens.width / 2}
            y1={10}
            x2={dimens.width / 2}
            y2={dimens.height - 10}
            stroke={strokeColor}
            strokeWidth={4}
          />
        </Svg>
      );
    case 'diag1':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={10}
            y1={10}
            x2={dimens.width - 10}
            y2={dimens.height - 10}
            stroke={strokeColor}
            strokeWidth={4}
          />
        </Svg>
      );
    case 'diag2':
      return (
        <Svg
          width={dimens.width}
          height={dimens.height}
          viewBox={`0 0 ${dimens.width} ${dimens.height}`}
        >
          <Line
            x1={dimens.width - 10}
            y1={10}
            x2={10}
            y2={dimens.height - 10}
            stroke={strokeColor}
            strokeWidth={4}
          />
        </Svg>
      );
  }
};

const Question1 = newQuestionContent({
  uid: 'ava',
  description: 'ava',
  keywords: ['Horizontal', 'Vertical'],
  schema: z.object({
    isHorizontal: z.boolean(),
    seed: z.number().int().min(1).max(999)
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();
    const seed = randomIntegerInclusive(1, 999);

    return { isHorizontal, seed };
  },
  Component: ({ question, translate, displayMode }) => {
    const { isHorizontal, seed } = question;
    const strokeColor = displayMode === 'digital' ? colors.prussianBlue : colors.black;

    const options = (dimens: Dimens) =>
      shuffle(
        [
          {
            value: 'horizontal',
            component: lineSVG('horizontal', dimens, strokeColor)
          },
          {
            value: 'vertical',
            component: lineSVG('vertical', dimens, strokeColor)
          },
          {
            value: 'diag1',
            component: lineSVG('diag1', dimens, strokeColor)
          },
          {
            value: 'diag2',
            component: lineSVG('diag2', dimens, strokeColor)
          }
        ],
        {
          random: seededRandom({ isHorizontal, seed })
        }
      );

    return (
      <QF11SelectImagesUpTo4
        title={
          isHorizontal
            ? translate.instructions.selectLineThatIsHorizontal()
            : translate.instructions.selectLineThatIsVertical()
        }
        pdfTitle={
          isHorizontal
            ? translate.instructions.circleLineThatIsHorizontal()
            : translate.instructions.circleLineThatIsVertical()
        }
        numItems={4}
        renderItems={({ dimens }) => {
          return options({ width: dimens.width - 40, height: dimens.height - 40 });
        }}
        testCorrect={[isHorizontal ? 'horizontal' : 'vertical']}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'avb',
  description: 'avb',
  keywords: ['Horizontal', 'Vertical'],
  schema: z.object({
    line: z.enum(['horizontal', 'vertical', 'diag1', 'diag2'])
  }),
  simpleGenerator: () => {
    const line = getRandomFromArray(['horizontal', 'vertical', 'diag1', 'diag2'] as const);

    return { line };
  },

  Component: props => {
    const {
      question: { line },
      translate,
      displayMode
    } = props;

    const items = [
      { text: translate.symmetry.horizontal(), values: ['horizontal'] },
      { text: translate.symmetry.vertical(), values: ['vertical'] },
      { text: translate.symmetry.neither(), values: ['diag1', 'diag2'] }
    ];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectWhetherLineIsHorizontalVerticalNeither()}
        pdfTitle={translate.instructions.circleWhetherLineIsHorizontalVerticalNeither()}
        testCorrect={items.filter(item => item.values.includes(line)).map(item => item.text)}
        numItems={3}
        mainPanelContainer={{ justifyContent: 'space-evenly' }}
        itemLayout="row"
        Content={({ dimens }) =>
          lineSVG(
            line,
            { height: dimens.height * 0.7, width: dimens.width * 0.5 },
            displayMode === 'digital' ? colors.prussianBlue : colors.black
          )
        }
        renderItems={items.map(({ text }) => ({
          value: text,
          component: <Text variant="WRN700">{text}</Text>
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'avc',
  description: 'avc',
  keywords: ['Measure', 'Length', 'Horizontal', 'Centimetres'],
  schema: z.object({
    length: z.number().int().min(2).max(15)
  }),
  simpleGenerator: () => ({
    length: randomIntegerInclusive(2, 15)
  }),
  Component: ({ question: { length }, translate }) => {
    return (
      <QF43DraggableLineOnTopOfStaticRuler
        title={translate.instructions.dragHorizontalLineSoItIsLengthLong({
          length: length.toLocaleString(),
          unit: translate.units.cm()
        })}
        pdfTitle={translate.instructions.dragHorizontalLineSoItIsLengthLongPdf({
          length: length.toLocaleString(),
          unit: translate.units.cm()
        })}
        rulerKind="cm"
        rulerLength={15}
        testCorrect={length}
        snapToNearest={0.1}
        wiggleRoom={0.1}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'avd',
  description: 'avd',
  keywords: ['Horizontal', 'Vertical'],
  schema: z.object({
    isHorizontal: z.boolean(),
    flags: z.array(
      z.object({
        country: z.string(),
        svgName: z.string(),
        stripe: z.enum(['horizontal', 'vertical', 'both'])
      })
    )
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();
    const numOfAnswers = randomIntegerInclusive(1, 3);
    const correct = getRandomUniqueFlags(
      numOfAnswers,
      isHorizontal ? 'vertical' : 'horizontal',
      false
    );
    const incorrect = getRandomUniqueFlags(
      4 - numOfAnswers,
      isHorizontal ? 'vertical' : 'horizontal'
    );

    const flags = shuffle([...correct, ...incorrect]);

    return { isHorizontal, flags };
  },
  Component: ({ question, translate }) => {
    const { isHorizontal, flags } = question;

    return (
      <QF11SelectImagesUpTo4
        title={
          isHorizontal
            ? translate.instructions.hereAreFlagsSelectHorizontalLines()
            : translate.instructions.hereAreFlagsSelectVerticalLines()
        }
        pdfTitle={
          isHorizontal
            ? translate.instructions.hereAreFlagsCircleHorizontalLines()
            : translate.instructions.hereAreFlagsCircleVerticalLines()
        }
        multiSelect
        numItems={4}
        renderItems={({ dimens }) => {
          return flags.map(flag => ({
            value: flag.country,
            component: <AssetSvg name={flag.svgName as SvgName} height={dimens.height * 0.8} />
          }));
        }}
        testCorrect={
          isHorizontal
            ? flags.filter(val => val.stripe !== 'vertical').map(val => val.country)
            : flags.filter(val => val.stripe !== 'horizontal').map(val => val.country)
        }
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ave',
  description: 'ave',
  keywords: ['Horizontal', 'Vertical', 'Lines of symmetry'],
  schema: z.object({
    isHorizontal: z.boolean(),
    shapes: z.array(
      z.object({
        svgName: z.string(),
        symmetry: z.enum(['horizontal', 'vertical', 'both'])
      })
    )
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();
    const numOfAnswers = randomIntegerInclusive(1, 3);
    const correct = getRandomUniqueSymmetryImages(
      numOfAnswers,
      isHorizontal ? 'vertical' : 'horizontal',
      false
    );
    const incorrect = getRandomUniqueSymmetryImages(
      4 - numOfAnswers,
      isHorizontal ? 'vertical' : 'horizontal'
    );

    const shapes = shuffle([...correct, ...incorrect]);

    return { isHorizontal, shapes };
  },
  Component: ({ question, translate }) => {
    const { isHorizontal, shapes } = question;

    return (
      <QF11SelectImagesUpTo4
        title={
          isHorizontal
            ? translate.instructions.selectShapesWithHorizontalLineOfSymmetry()
            : translate.instructions.selectShapesWithVeritcalLineOfSymmetry()
        }
        pdfTitle={
          isHorizontal
            ? translate.instructions.circleShapesWithHorizontalLineOfSymmetry()
            : translate.instructions.circleShapesWithVeritcalLineOfSymmetry()
        }
        multiSelect
        numItems={4}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.svgName,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
        testCorrect={
          isHorizontal
            ? shapes.filter(val => val.symmetry !== 'vertical').map(val => val.svgName)
            : shapes.filter(val => val.symmetry !== 'horizontal').map(val => val.svgName)
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'avf',
  description: 'avf',
  keywords: ['Horizontal', 'Vertical', 'Lines of symmetry'],
  schema: z.object({
    isHorizontal: z.boolean(),
    shapes: z.object({
      horizontal: z.string(),
      vertical: z.string(),
      incorrect1: z.string(),
      incorrect2: z.string()
    })
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();
    const shape = getRandomFromArray(symmeticalShapes);
    const shapes = getRandomShapeWithSymmetryLines(shape);

    return { isHorizontal, shapes };
  },
  Component: ({ question, translate }) => {
    const { isHorizontal, shapes } = question;

    const items = shuffle(
      [
        { value: 'horizontal', svgName: shapes.horizontal },
        { value: 'vertical', svgName: shapes.vertical },
        { value: 'incorrect1', svgName: shapes.incorrect1 },
        { value: 'incorrect2', svgName: shapes.incorrect2 }
      ],
      { random: seededRandom(question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          isHorizontal
            ? translate.instructions.selectTheShapeThatHasACorrectHorizontalLineOfSymmetryShown()
            : translate.instructions.selectTheShapeThatHasACorrectVerticalLineOfSymmetryShown()
        }
        pdfTitle={
          isHorizontal
            ? translate.instructions.circleTheShapeThatHasACorrectHorizontalLineOfSymmetryShown()
            : translate.instructions.circleTheShapeThatHasACorrectVerticalLineOfSymmetryShown()
        }
        numItems={4}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item.value,
            component: (
              <AssetSvg
                name={item.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
        testCorrect={[isHorizontal ? 'horizontal' : 'vertical']}
      />
    );
  }
});

export const avf = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HorizontalAndVertical',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
