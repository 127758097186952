import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { convertUnitsSuffix } from '../../../../utils/unitConversion';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import { sortNumberArray } from '../../../../utils/collections';
import { GREATER_THAN, LESS_THAN } from '../../../../constants';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aHa',
  description: 'aHa',
  keywords: ['Metric', 'Convert', 'Length', 'Millimetres', 'Centimetres', 'Metres'],
  schema: z.object({
    centimetresA: z.number().int().min(2).max(9),
    centimetresB: z.number().int().min(2).max(9),
    metresC: z.number().int().min(2).max(9),
    metresD: z.number().int().min(2).max(9)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [centimetresA, centimetresB] = randomUniqueIntegersInclusive(2, 9, 2);

    const [metresC, metresD] = randomUniqueIntegersInclusive(2, 9, 2);

    return {
      centimetresA,
      centimetresB,
      metresC,
      metresD
    };
  },
  Component: props => {
    const {
      question: { centimetresA, centimetresB, metresC, metresD },
      translate
    } = props;

    const millimetresA = centimetresA * 10;
    const millimetresB = centimetresB * 10;
    const centimetresC = metresC * 100;
    const centimetresD = metresD * 100;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[
          [centimetresA.toString()],
          [millimetresB.toString()],
          [metresC.toString()],
          [centimetresD.toString()]
        ]}
        sentences={[
          `${translate.units.numberOfMillimetres(
            millimetresA
          )} = <ans/> ${translate.units.centimetres(2)}`,
          `${translate.units.numberOfCentimetres(
            centimetresB
          )} = <ans/> ${translate.units.millimetres(2)}`,
          `${translate.units.numberOfCentimetres(centimetresC)} = <ans/> ${translate.units.metres(
            2
          )}`,
          `${translate.units.numberOfMetres(metresD)} = <ans/> ${translate.units.centimetres(2)}`
        ]}
        textStyle={{ fontSize: 32 }}
        questionHeight={900}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aHa2',
  description: 'aHa',
  keywords: ['Metric', 'Convert', 'Length', 'Millimetres', 'Centimetres', 'Metres'],
  schema: z.object({
    centimetresA: z.number().int().min(2).max(9),
    metresB: z.number().int().min(2).max(9),
    sentenceOption: z.number().int().min(0).max(3)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const centimetresA = randomIntegerInclusive(2, 9);

    const metresB = randomIntegerInclusive(2, 9);

    const sentenceOption = randomIntegerInclusive(0, 3);

    return {
      centimetresA,
      metresB,
      sentenceOption
    };
  },
  Component: props => {
    const {
      question: { centimetresA, metresB, sentenceOption },
      translate,
      displayMode
    } = props;

    const millimetresA = centimetresA * 10;
    const centimetresC = metresB * 100;

    const sentencesAndAnswers = [
      {
        answer: centimetresA.toString(),
        sentence: `${translate.units.numberOfMillimetres(
          millimetresA
        )} = <ans/> ${translate.units.centimetres(2)}`
      },
      {
        answer: millimetresA.toString(),
        sentence: `${translate.units.numberOfCentimetres(
          centimetresA
        )} = <ans/> ${translate.units.millimetres(2)}`
      },
      {
        answer: metresB.toString(),
        sentence: `${translate.units.numberOfCentimetres(
          centimetresC
        )} = <ans/> ${translate.units.metres(2)}`
      },
      {
        answer: (metresB * 100).toString(),
        sentence: `${translate.units.numberOfMetres(
          metresB
        )} = <ans/> ${translate.units.centimetres(2)}`
      }
    ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheEquivalentLength()}
        testCorrect={[sentencesAndAnswers[sentenceOption].answer]}
        sentence={sentencesAndAnswers[sentenceOption].sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aHb',
  description: 'aHb',
  keywords: ['Metric', 'Convert', 'Length', 'Millimetres', 'Centimetres', 'Metres'],
  schema: z.object({
    centimetresA: z.number().int().min(1).max(9),
    centimetresB: z.number().int().min(1).max(9),
    millimetresA: z.number().int().min(1).max(9),
    millimetresB: z.number().int().min(1).max(99),
    millimetresC: z.number().int().min(11).max(99),
    metresA: z.number().int().min(1).max(9)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const centimetresA = randomIntegerInclusive(1, 9);

    const centimetresB = randomIntegerInclusive(1, 9, {
      constraint: x => x !== centimetresA
    });

    const millimetresA = randomIntegerInclusive(1, 9);

    const millimetresB = randomIntegerInclusive(
      centimetresA + 1,
      centimetresA * 10 + millimetresA - 1
    );

    const millimetresC = randomIntegerInclusive(11, 99, {
      constraint: x => x !== millimetresB
    });

    const metresA = randomIntegerInclusive(1, centimetresA);

    return { centimetresA, centimetresB, millimetresA, millimetresB, millimetresC, metresA };
  },
  Component: props => {
    const {
      question: { centimetresA, centimetresB, millimetresA, millimetresB, millimetresC, metresA },
      translate
    } = props;

    const centimetresC = centimetresA + 1;

    const [lessThan, moreThan]: [string[], string[]] = [[], []];
    const arrayOfCorrectAnswers = [lessThan, moreThan];

    const items = shuffle(
      [
        {
          string: translate.units.numberOfCm(centimetresA),
          valueInMm: convertUnitsSuffix(centimetresA, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfCm(centimetresB),
          valueInMm: convertUnitsSuffix(centimetresB, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfCm(centimetresC),
          valueInMm: convertUnitsSuffix(centimetresC, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfMm(millimetresB),
          valueInMm: millimetresB
        },
        {
          string: translate.units.numberOfMm(millimetresC),
          valueInMm: millimetresC
        },
        {
          string: translate.units.numberOfM(metresA),
          valueInMm: convertUnitsSuffix(metresA, 'm', 'mm').value
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Organize the equations into their respective Sets:
    items.forEach(item =>
      item.valueInMm < convertUnitsSuffix(centimetresA, 'cm', 'mm').value + millimetresA
        ? lessThan.push(item.string)
        : moreThan.push(item.string)
    );

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortLengthsIntoTable()}
        pdfTitle={translate.instructions.useCardsToSortLengthsIntoTable()}
        zoneNames={[
          translate.answerSentences.shorterThanXCmYMm(centimetresA, millimetresA),
          translate.answerSentences.longerThanXCmYMm(centimetresA, millimetresA)
        ]}
        items={items.map(item => item.string)}
        actionPanelVariant="bottom"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        itemsMaxLines={1}
        testCorrect={arrayOfCorrectAnswers}
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHc',
  description: 'aHc',
  keywords: ['Metric', 'Convert', 'Length', 'Millimetres', 'Centimetres', 'Metres'],
  schema: z.object({
    shortestOrLongestFirst: z.enum(['shortest', 'longest']),
    number1: z.number().int().min(1).max(9),
    number3: z.number().int().min(10).max(90).multipleOf(10),
    number4AddOrSubtract: z.enum(['add', 'subtract']),
    number5: z.number().int().min(10).max(90),
    number6: z.number().int().min(1).max(9),
    number7AddOrSubtract: z.enum(['add', 'subtract'])
  }),
  simpleGenerator: () => {
    const shortestOrLongestFirst = getRandomFromArray(['shortest', 'longest'] as const);

    const number1 = randomIntegerInclusive(1, 9);

    const number3 = randomIntegerInclusiveStep(10, 90, 10);

    const number4AddOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    const number5 = randomIntegerInclusive(10, 90, {
      constraint: x => x !== number3
    });

    const number6 = randomIntegerInclusive(1, 9);

    const number7AddOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    return {
      shortestOrLongestFirst,
      number1,
      number3,
      number4AddOrSubtract,
      number5,
      number6,
      number7AddOrSubtract
    };
  },
  Component: props => {
    const {
      question: {
        shortestOrLongestFirst,
        number1,
        number3,
        number4AddOrSubtract,
        number5,
        number6,
        number7AddOrSubtract
      },
      translate
    } = props;

    const number2 = number1 * 100;

    const number4 = number4AddOrSubtract === 'add' ? number2 + number3 : number2 - number3;

    const number7 = number7AddOrSubtract === 'add' ? number4 + number6 : number4 - number6;

    // Randomly order these equations
    const items = getRandomSubArrayFromArray(
      [
        {
          string: translate.units.numberOfM(number1),
          valueInMm: convertUnitsSuffix(number1, 'm', 'mm').value
        },
        {
          string: translate.units.numberOfCm(number4),
          valueInMm: convertUnitsSuffix(number4, 'cm', 'mm').value
        },
        {
          string: `${translate.units.numberOfM(number1)} ${translate.units.numberOfCm(number5)}`,
          valueInMm:
            convertUnitsSuffix(number1, 'm', 'mm').value +
            convertUnitsSuffix(number5, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfMm(number4),
          valueInMm: number4
        },
        {
          string: translate.units.numberOfCm(number7),
          valueInMm: convertUnitsSuffix(number7, 'cm', 'mm').value
        }
      ],
      4,
      { random: seededRandom(props.question) }
    );

    const shuffledItems = shuffle(items, { random: seededRandom(props.question) });

    return (
      <QF4DragOrderVertical
        title={
          shortestOrLongestFirst === 'shortest'
            ? translate.instructions.dragCardsToOrderLengthsStartWithShortest()
            : translate.instructions.dragCardsToOrderLengthsStartWithLongest()
        }
        pdfTitle={
          shortestOrLongestFirst === 'shortest'
            ? translate.instructions.useCardsToOrderLengthsStartWithShortest()
            : translate.instructions.useCardsToOrderLengthsStartWithLongest()
        }
        testCorrect={sortNumberArray(
          shuffledItems.map(measurement => measurement.valueInMm),
          shortestOrLongestFirst === 'shortest' ? 'ascending' : 'descending'
        )}
        items={shuffledItems.map(measurement => ({
          value: measurement.valueInMm,
          component: measurement.string
        }))}
        topLabel={
          shortestOrLongestFirst === 'shortest'
            ? translate.misc.Shortest()
            : translate.misc.Longest()
        }
        bottomLabel={
          shortestOrLongestFirst === 'shortest'
            ? translate.misc.Longest()
            : translate.misc.Shortest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aHd',
  description: 'aHd',
  keywords: ['Metric', 'Convert', 'Length', 'Millimetres', 'Centimetres', 'Metres'],
  schema: z.object({
    centimetresA: z.number().int().min(1).max(9),
    centimetresB: z.number().int().min(101).max(899),
    metresC: z.number().int().min(1).max(9)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const centimetresA = randomIntegerInclusive(1, 9);

    const centimetresB = randomIntegerInclusive(101, 899, {
      constraint: x => x % 100 !== 0
    });

    const metresC = randomIntegerInclusive(1, 9);

    return {
      centimetresA,
      centimetresB,
      metresC
    };
  },
  Component: props => {
    const {
      question: { centimetresA, centimetresB, metresC },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.makeStatementsCorrect()}
        testCorrect={userAnswer =>
          parseInt(userAnswer[0][0]) > centimetresA * 10 &&
          parseInt(userAnswer[1][0]) > centimetresB / 100 &&
          parseInt(userAnswer[2][0]) > metresC * 100
        }
        inputMaxCharacters={4}
        sentences={[
          `${translate.units.numberOfCm(centimetresA)} ${LESS_THAN} <ans/> ${translate.units.mm()}`,
          `${translate.units.numberOfCm(centimetresB)} ${LESS_THAN} <ans/> ${translate.units.m()}`,
          `<ans/> ${translate.units.cm()} ${GREATER_THAN} ${translate.units.numberOfM(metresC)}`
        ]}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.allNumberSentencesMustBeCorrect()
        }}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aHe',
  description: 'aHe',
  keywords: ['Metric', 'Convert', 'Length', 'Millimetres', 'Centimetres', 'Metres'],
  schema: z.object({
    centimetresA: z.number().int().min(11).max(99),
    centimetresB: z.number().int().min(101).max(999),
    centimetresC: z
      .number()
      .int()
      .min(90)
      .max(110)
      .refine(x => x !== 100, 'centimetresC cannot equal 100'),
    millimetresA: z.number().int().min(11).max(99),
    millimetresB: z.number().int().min(110).max(990).multipleOf(10),
    metresA: z.number().int().min(2).max(9)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const centimetresA = randomIntegerInclusive(11, 99);

    const centimetresB = randomIntegerInclusive(101, 999);

    const centimetresC = randomIntegerInclusive(90, 110, {
      constraint: x => x !== 100
    });

    const millimetresA = randomIntegerInclusive(11, 99);

    const millimetresB = randomIntegerInclusiveStep(110, 990, 10);

    const metresA = randomIntegerInclusive(2, 9);

    return { centimetresA, centimetresB, centimetresC, millimetresA, millimetresB, metresA };
  },
  Component: props => {
    const {
      question: { centimetresA, centimetresB, centimetresC, millimetresA, millimetresB, metresA },
      translate
    } = props;

    const [lessThan, moreThan]: [string[], string[]] = [[], []];
    const arrayOfCorrectAnswers = [lessThan, moreThan];

    const items = shuffle(
      [
        {
          string: translate.units.numberOfCm(centimetresA),
          valueInMm: convertUnitsSuffix(centimetresA, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfCm(centimetresB),
          valueInMm: convertUnitsSuffix(centimetresB, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfCm(centimetresC),
          valueInMm: convertUnitsSuffix(centimetresC, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfMm(millimetresA),
          valueInMm: millimetresA
        },
        {
          string: translate.units.numberOfMm(millimetresB),
          valueInMm: millimetresB
        },
        {
          string: translate.units.numberOfM(metresA),
          valueInMm: convertUnitsSuffix(metresA, 'm', 'mm').value
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Organize the equations into their respective Sets:
    items.forEach(item =>
      item.valueInMm < convertUnitsSuffix(1, 'm', 'mm').value
        ? lessThan.push(item.string)
        : moreThan.push(item.string)
    );

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortLengthsIntoTable()}
        pdfTitle={translate.instructions.useCardsToSortLengthsIntoTable()}
        zoneNames={[
          translate.answerSentences.shorterThanAMetre(),
          translate.answerSentences.longerThanAMetre()
        ]}
        actionPanelVariant="bottom"
        items={items.map(item => item.string)}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        itemsMaxLines={1}
        testCorrect={arrayOfCorrectAnswers}
        questionHeight={1000}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aHf',
  description: 'aHf',
  keywords: ['Metric', 'Convert', 'Length', 'Millimetres', 'Centimetres', 'Metres'],
  schema: z.object({
    centimetresA: z.number().int().min(1).max(9),
    centimetresB: z.number().int().min(1).max(19),
    millimetresA: z.number().int().min(11).max(99),
    millimetresB: z.number().int().min(101).max(999),
    millimetresC: z
      .number()
      .int()
      .min(10)
      .max(200)
      .multipleOf(10)
      .refine(x => x !== 100, 'millimetresC cannot equal 100'),
    metresA: z.number().int().min(1).max(9)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const centimetresA = randomIntegerInclusive(1, 9);

    const centimetresB = randomIntegerInclusive(1, 19, {
      constraint: x => x !== centimetresA && x !== 10
    });

    const millimetresA = randomIntegerInclusive(11, 99);

    const millimetresB = randomIntegerInclusive(101, 999);

    const millimetresC = randomIntegerInclusiveStep(10, 200, 10, {
      constraint: x => x !== 100
    });

    const metresA = randomIntegerInclusive(1, 9);

    return { centimetresA, centimetresB, millimetresA, millimetresB, millimetresC, metresA };
  },
  Component: props => {
    const {
      question: { centimetresA, centimetresB, millimetresA, millimetresB, millimetresC, metresA },
      translate
    } = props;

    const [lessThan, moreThan]: [string[], string[]] = [[], []];
    const arrayOfCorrectAnswers = [lessThan, moreThan];

    const items = shuffle(
      [
        {
          string: translate.units.numberOfCm(centimetresA),
          valueInMm: convertUnitsSuffix(centimetresA, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfCm(centimetresB),
          valueInMm: convertUnitsSuffix(centimetresB, 'cm', 'mm').value
        },
        {
          string: translate.units.numberOfMm(millimetresA),
          valueInMm: millimetresA
        },
        {
          string: translate.units.numberOfMm(millimetresB),
          valueInMm: millimetresB
        },
        {
          string: translate.units.numberOfMm(millimetresC),
          valueInMm: millimetresC
        },
        {
          string: translate.units.numberOfM(metresA),
          valueInMm: convertUnitsSuffix(metresA, 'm', 'mm').value
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Organize the equations into their respective Sets:
    items.forEach(item =>
      item.valueInMm < convertUnitsSuffix(10, 'cm', 'mm').value
        ? lessThan.push(item.string)
        : moreThan.push(item.string)
    );

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortLengthsIntoTable()}
        pdfTitle={translate.instructions.useCardsToSortLengthsIntoTable()}
        zoneNames={[
          translate.answerSentences.shorterThanXCm(10),
          translate.answerSentences.longerThanXCm(10)
        ]}
        actionPanelVariant="bottom"
        items={items.map(item => item.string)}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        testCorrect={arrayOfCorrectAnswers}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareLengths',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
