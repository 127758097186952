import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { filledArray } from '../../../utils/collections';
import BaseLayout, { ActionPanelVariant } from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import { Theme } from '../../../theme';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { useContext } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import Text from '../../typography/Text';
import { DisplayMode } from '../../../contexts/displayMode';
import { colors } from '../../../theme/colors';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { DRAGGABLE_DIMENS, DraggableVariant } from '../../draganddrop/utils';
import { AssetSvg } from '../../../assets/svg';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import { horizontalArrow } from '../representations/LineSvgs';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  /**
   * Either an array of the correct order of values or an object containing a test correct function and a
   * length property (length of user answer).
   */
  testCorrect: T[];

  itemVariant?: DraggableVariant;
  pdfItemVariant?: DraggableVariant;

  /** The items to show as draggables. Each item can be passed as a plain string or number. */
  items: ((T & (string | number)) | ItemInfo<T>)[];
  // used if items contains plain strings or numbers
  itemsTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;
  pdfItemsLetterEmWidth?: number;
  /** Default: move */
  moveOrCopy?: 'move' | 'copy';
  /** Default: endWide */
  actionPanelVariant?: ActionPanelVariant;
  /** Optional custom mark scheme answer */
  markSchemeAnswer?: string;
  /** Question Height */
  questionHeight?: number;
  leftLabel?: string;
  rightLabel?: string;
  labelsPosition?: 'top' | 'bottom';
  arrowWidth?: number;
  pdfDraggableWidth?: number;
  mainPanelItemStyle?: StyleProp<ViewStyle>;
};

/**
 * Question Format 5: Order Horizontal (drag and drop)
 *
 * Title at the top, draggables on the right, and positions to drag them into on the left.
 *
 * This is one-to-one by default, but can be made many-to-one with moveOrCopy='copy'.
 */
export default function QF5DragOrderHorizontal<T extends string | number>({
  title,
  pdfTitle,
  testCorrect,
  markSchemeAnswer,
  items: itemsProp,
  itemVariant = 'square',
  pdfItemVariant = 'tallRectangle',
  itemsTextVariant = 'WRN700',
  itemsLetterEmWidth,
  pdfItemsLetterEmWidth,
  itemsMaxLines = 1,
  moveOrCopy = 'move',
  actionPanelVariant = 'bottom',
  leftLabel,
  rightLabel,
  labelsPosition = 'top',
  arrowWidth,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  mainPanelItemStyle,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const answerLength = testCorrect.length;

  const items: ItemInfo<T>[] = itemsProp.map(item =>
    typeof item === 'object' ? item : { component: item.toLocaleString(), value: item }
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  const answerBoxesPdf = filledArray('', testCorrect.length);

  // get the width of each draggable to dictate the length of the horizontal arrow
  // if arrowWidth is set we will only use the below for pdf
  const draggableWidth =
    DRAGGABLE_DIMENS[displayMode === 'digital' ? itemVariant : pdfItemVariant ?? itemVariant].width;
  const offset = displayMode !== 'digital' ? 1.5 : itemVariant === 'square' ? 2.75 : 1.75;
  const arrowLength = draggableWidth * (items.length - offset);

  const labelsStylesPDF = (
    <View style={styles.labelContainerPdf}>
      {leftLabel && rightLabel && (
        <>
          <View style={{ width: 210 }}>
            <Text variant="WRN400" style={styles.labelPDF}>
              {leftLabel}
            </Text>
          </View>
          {horizontalArrow(arrowLength, 6)}
          <View style={{ width: 210 }}>
            <Text variant="WRN400" style={styles.labelPDF}>
              {rightLabel}
            </Text>
          </View>
        </>
      )}
    </View>
  );

  const labelsStyles = (
    <View style={styles.labelContainer}>
      {leftLabel && rightLabel && (
        <>
          <Text style={styles.label}>{leftLabel}</Text>
          {horizontalArrow(arrowWidth ?? arrowLength)}
          <Text style={styles.label}>{rightLabel}</Text>
        </>
      )}
    </View>
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    // Sort items into correct order to use as placeholders in answer boxes
    const correctItemOrder = testCorrect.map(it => {
      const [temp] = items.filter(item => item.value === it);
      return temp.value;
    });

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="draganddrop"
        items={items}
        variant={pdfItemVariant ?? itemVariant}
        textVariant={itemsTextVariant}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={pdfItemsLetterEmWidth ?? itemsLetterEmWidth}
        maxLines={itemsMaxLines}
        moveOrCopy="copy"
        defaultState={displayMode === 'markscheme' ? correctItemOrder : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <View style={styles.mainPanelPdf}>
              <DragAndDropSectionPDF itemsStyle={styles.draggablesPdf}>
                {items.map((_item, index) => (
                  <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
                ))}
              </DragAndDropSectionPDF>
              {labelsPosition === 'top' && labelsStylesPDF}
              <View style={styles.dropZoneRow}>
                {answerBoxesPdf.map((_dropZone, dropZoneIndex) => (
                  <View key={dropZoneIndex}>
                    {displayMode === 'markscheme' && (
                      <AssetSvg
                        name="True"
                        width={50}
                        style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
                      />
                    )}
                    <EasyDragAndDropWithSingleZones.ZoneSingle id={dropZoneIndex} />
                  </View>
                ))}
              </View>
              {labelsPosition === 'bottom' && labelsStylesPDF}
            </View>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={itemVariant}
      defaultState={filledArray(undefined, answerLength)}
      testCorrect={userAnswer =>
        userAnswer.every((it, index) => {
          if (it === testCorrect[index]) {
            return true;
          }

          if (testCorrect[index] === WILDCARD) {
            // Correct answer had a wildcard character here, so any value is allowed. Mark as valid.
            // Special case: if T represents a digit of a number, the first digit shouldn't be 0, even
            // if we're matching against a wildcard character.
            return !(index === 0 && it === '0');
          }

          // Answer differed from correctAnswer
          return false;
        })
      }
      testComplete={answer => answer.every(it => it !== undefined)}
      textVariant={itemsTextVariant}
      textAutoScale={longestItemTextLength}
      textLetterEmWidth={pdfItemsLetterEmWidth ?? itemsLetterEmWidth}
      maxLines={itemsMaxLines}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={
          <DragAndDropSection
            style={{ padding: 0, maxWidth: actionPanelVariant === 'bottomTall' ? '95%' : '100%' }}
          >
            {items.map((_item, index) => (
              <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <View style={[styles.dropZoneRow, { flexDirection: 'column' }]}>
            {labelsPosition === 'top' && labelsStyles}
            <View style={styles.dropZoneRow}>
              {filledArray(undefined, answerLength).map((_, dropZoneIndex) => (
                <EasyDragAndDropWithSingleZones.ZoneSingle
                  key={dropZoneIndex}
                  id={dropZoneIndex}
                  style={mainPanelItemStyle}
                />
              ))}
            </View>
            {labelsPosition === 'bottom' && labelsStyles}
          </View>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  dropZoneRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16
  },
  label: {
    color: colors.grey,
    fontSize: 32,
    textAlign: 'center'
  },
  labelPDF: {
    color: colors.grey,
    textAlign: 'center'
  },
  labelContainer: {
    flexDirection: 'row',
    gap: 32,
    alignItems: 'center'
  },
  labelContainerPdf: {
    flexDirection: 'row',
    gap: 60,
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainPanelPdf: {
    flex: 1,
    justifyContent: 'space-between',
    rowGap: 32
  },
  draggablesPdf: {
    flexDirection: 'row',
    columnGap: 16
  }
});

export const WILDCARD = '*';
