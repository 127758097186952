import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  base10ObjectToNumber,
  numberToBase10Object,
  ScientificNotation
} from '../../../../utils/math';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  sumNumberArray
} from '../../../../utils/collections';
import { ADD } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { AlignedEquationsWithState } from '../../../../components/question/representations/AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'adQ',
  description: 'adQ',
  keywords: ['Place value', 'Partition', 'Counters', '1,000,000', 'Million'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    hundreds: z.number().int().min(1).max(9),
    thousands: z.number().int().min(1).max(9),
    tenThousands: z.number().int().min(1).max(9),
    hundredThousands: z.number().int().min(1).max(9)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    const thousands = randomIntegerInclusive(1, 9);
    const tenThousands = randomIntegerInclusive(1, 9);
    const hundredThousands = randomIntegerInclusive(1, 9);

    return {
      ones,
      tens,
      hundreds,
      thousands,
      tenThousands,
      hundredThousands
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, thousands, tenThousands, hundredThousands },
      translate
    } = props;

    const number = base10ObjectToNumber({
      ones,
      tens,
      hundreds,
      thousands,
      tenThousands,
      hundredThousands
    });

    // Totals the parsed integers of all the strings from the answer boxes.
    const totalOfAnswer = (answer: string[]) => {
      const reducer = (a: number, b: string) => a + parseInt(b);
      return answer.reduce(reducer, 0);
    };

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        title={translate.instructions.completeNumberSentence()}
        testCorrect={answer =>
          totalOfAnswer(answer) === number - hundredThousands * 100000 &&
          answer.every(it => it !== '0')
        }
        inputMaxCharacters={5}
        sentence={`${number.toLocaleString()} = ${(
          hundredThousands * 100000
        ).toLocaleString()} + <ans/> + <ans/> + <ans/> + <ans/> + <ans/>`}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{
              variant: 'Counters',
              numbers: { ones, tens, hundreds, thousands, tenThousands, hundredThousands },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (tenThousands * 10000).toLocaleString(),
            (thousands * 1000).toLocaleString(),
            (hundreds * 100).toLocaleString(),
            (tens * 10).toLocaleString(),
            ones.toLocaleString()
          ]
        }}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'adQ2',
  description: 'adQ',
  keywords: ['Place value', 'Partition', 'Counters', '1,000,000', 'Million'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    hundreds: z.number().int().min(1).max(9),
    thousands: z.number().int().min(1).max(9),
    tenThousands: z.number().int().min(1).max(9),
    hundredThousands: z.number().int().min(1).max(9)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(1, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    const thousands = randomIntegerInclusive(1, 9);
    const tenThousands = randomIntegerInclusive(1, 9);
    const hundredThousands = randomIntegerInclusive(1, 9);

    return {
      ones,
      tens,
      hundreds,
      thousands,
      tenThousands,
      hundredThousands
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, thousands, tenThousands, hundredThousands },
      translate,
      displayMode
    } = props;

    const number = base10ObjectToNumber({
      ones,
      tens,
      hundreds,
      thousands,
      tenThousands,
      hundredThousands
    });

    const isDigital = displayMode === 'digital';

    const answerCheck = (answer: string[]) => {
      const totalOfAnswers =
        sumNumberArray(answer.map(value => parseInt(value))) + hundredThousands * 100_000;
      return totalOfAnswers === number && !answer.includes('0');
    };

    return (
      <QF3Content
        actionPanelVariant="endWide"
        inputType="numpad"
        questionHeight={1000}
        title={translate.instructions.completeNumberSentence()}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentenceMatchingContent()
        }}
        Content={({ dimens }) => (
          <>
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Counters',
                numbers: { ones, tens, hundreds, thousands, tenThousands, hundredThousands },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height * 0.55}
            />

            <AlignedEquationsWithState
              id="alignedEq"
              inputMaxCharacters={5}
              leftSide={[number.toLocaleString()]}
              rightSide={[
                `${(
                  hundredThousands * 100_000
                ).toLocaleString()} + <ans/> + <ans/> + <ans/> + <ans/> + <ans/>`
              ]}
              rightColumnStyle={{
                width: isDigital ? 650 : undefined,
                flexWrap: 'wrap'
              }}
              leftColumnStyle={{
                alignSelf: isDigital ? 'flex-start' : 'center',
                height: 130,
                justifyContent: 'center'
              }}
              middleCharacterStyle={{
                alignSelf: isDigital ? 'flex-start' : 'center',
                height: 130,
                justifyContent: 'center'
              }}
              mainPanelStyle={{ justifyContent: 'flex-start' }}
              testCorrect={({ right }) => answerCheck(right[0])}
              defaultState={
                displayMode === 'markscheme'
                  ? {
                      left: [],
                      right: [
                        [
                          (tenThousands * 10000).toLocaleString(),
                          (thousands * 1000).toLocaleString(),
                          (hundreds * 100).toLocaleString(),
                          (tens * 10).toLocaleString(),
                          ones.toLocaleString()
                        ]
                      ]
                    }
                  : undefined
              }
            />
          </>
        )}
      />
    );
  }
});

const Question1v3 = newQuestionContent({
  uid: 'adQ3',
  description: 'adQ',
  keywords: ['Place value', 'Partition', 'Counters', '1,000,000', 'Million'],
  schema: z.object({
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(1).max(9),
    hundreds: z.number().int().min(1).max(9),
    thousands: z.number().int().min(1).max(9),
    tenThousands: z.number().int().min(1).max(9),
    hundredThousands: z.number().int().min(1).max(9)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { ones, tens, hundreds, thousands, tenThousands, hundredThousands } = rejectionSample(
      () => {
        const ones = randomIntegerInclusive(1, 9);
        const tens = randomIntegerInclusive(1, 9);
        const hundreds = randomIntegerInclusive(1, 9);
        const thousands = randomIntegerInclusive(1, 9);
        const tenThousands = randomIntegerInclusive(1, 9);
        const hundredThousands = randomIntegerInclusive(1, 9);

        return { ones, tens, hundreds, thousands, tenThousands, hundredThousands };
      },
      // Prevent 3 rows to ensure AlignedEquations displays correctly
      // If 3 rows the equations and answer boxes are misaligned
      // There doesn't appear to be a consistent way of handling this without preventing it altogether
      ({ ones, tens, hundreds, thousands, tenThousands, hundredThousands }) =>
        [ones, tens, hundreds, thousands, tenThousands, hundredThousands].some(num => num > 6)
    );

    return {
      ones,
      tens,
      hundreds,
      thousands,
      tenThousands,
      hundredThousands
    };
  },
  Component: props => {
    const {
      question: { ones, tens, hundreds, thousands, tenThousands, hundredThousands },
      translate,
      displayMode
    } = props;

    const number = base10ObjectToNumber({
      ones,
      tens,
      hundreds,
      thousands,
      tenThousands,
      hundredThousands
    });

    const isDigital = displayMode === 'digital';

    const answerCheck = (answer: string[]) => {
      const totalOfAnswers =
        sumNumberArray(answer.map(value => parseInt(value))) + hundredThousands * 100_000;
      return totalOfAnswers === number && !answer.includes('0');
    };

    return (
      <QF3Content
        actionPanelVariant="endWide"
        inputType="numpad"
        questionHeight={1000}
        title={translate.instructions.completeNumberSentence()}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentenceMatchingContent()
        }}
        Content={({ dimens }) => (
          <>
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Counters',
                numbers: { ones, tens, hundreds, thousands, tenThousands, hundredThousands },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height * 0.55}
            />

            <AlignedEquationsWithState
              id="alignedEq"
              inputMaxCharacters={5}
              leftSide={[number.toLocaleString()]}
              rightSide={[
                `${(
                  hundredThousands * 100_000
                ).toLocaleString()} + <ans/> + <ans/> + <ans/> + <ans/> + <ans/>`
              ]}
              rightColumnStyle={{
                width: isDigital ? 650 : undefined,
                flexWrap: 'wrap'
              }}
              leftColumnStyle={{
                alignSelf: isDigital ? 'flex-start' : 'center',
                height: 130,
                justifyContent: 'center'
              }}
              middleCharacterStyle={{
                alignSelf: isDigital ? 'flex-start' : 'center',
                height: 130,
                justifyContent: 'center'
              }}
              mainPanelStyle={{ justifyContent: 'flex-start' }}
              testCorrect={({ right }) => answerCheck(right[0])}
              defaultState={
                displayMode === 'markscheme'
                  ? {
                      left: [],
                      right: [
                        [
                          (tenThousands * 10000).toLocaleString(),
                          (thousands * 1000).toLocaleString(),
                          (hundreds * 100).toLocaleString(),
                          (tens * 10).toLocaleString(),
                          ones.toLocaleString()
                        ]
                      ]
                    }
                  : undefined
              }
            />
          </>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'adR',
  description: 'adR',
  keywords: ['Place value', 'Partition', 'Chart', '1,000,000', 'Million'],
  schema: z.object({
    number: z.number().int().min(100001).max(999999)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(100001, 999999, {
      // Enforce that at least one digit (not 100,000s) is 0.
      constraint: x => {
        const sci = ScientificNotation.fromNumber(x);
        const zeroCount = sci.digits.filter(v => v === 0).length;
        const limit = zeroCount > 1 ? 9 : 6;
        return (
          [0, 1, 2, 3, 4].some(pow => sci.digitAt(pow) === 0) && sci.digits.every(i => i < limit)
        );
      }
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const powerArray = ScientificNotation.fromNumber(number);

    const firstLineOfAnswersArray: number[] = [];

    let answerString = `${number.toLocaleString()} =`;

    powerArray.digits.forEach(value => {
      if (value !== 0) {
        firstLineOfAnswersArray.push(value);
      }
    });

    // This generates the line of answers, returning the correct amount of answer boxes.
    firstLineOfAnswersArray.forEach((_value, index) => {
      if (index === 0) {
        answerString += ' <ans/> ';
      } else {
        answerString += `${ADD} <ans/> `;
      }
    });

    const answerCheck = (answer: string[]) => {
      let totalOfAnswers = 0;

      // This adds the line of answers to totalOfAnswers.
      answer.forEach(value => (totalOfAnswers += parseInt(value)));

      // Check totalOfAnswers equals number, and that no zeroes have been passed in any of the answer boxes.
      return totalOfAnswers === number && !answer.includes('0');
    };

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        title={translate.instructions.completeNumberSentenceToPartition()}
        testCorrect={answer => answerCheck(answer)}
        inputMaxCharacters={6}
        sentence={answerString}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[5, 4, 3, 2, 1, 0]}
            counterVariant="greyCounter"
            headerVariant="shortName"
            dimens={dimens}
          />
        )}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.validSentenceMatchingContent() }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'adR2',
  description: 'adR',
  keywords: ['Place value', 'Partition', 'Chart', '1,000,000', 'Million'],
  schema: z.object({
    number: z.number().int().min(100001).max(999999)
  }),
  questionHeight: 1100,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(100001, 999999, {
      // Enforce that at least one digit (not 100,000s) is 0.
      constraint: x => {
        const sci = ScientificNotation.fromNumber(x);
        const zeroCount = sci.digits.filter(v => v === 0).length;
        const limit = zeroCount > 1 ? 9 : 6;
        return (
          [0, 1, 2, 3, 4].some(pow => sci.digitAt(pow) === 0) && sci.digits.every(i => i < limit)
        );
      }
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    const powerArray = ScientificNotation.fromNumber(number);

    const firstLineOfAnswersArray: number[] = [];

    let answerString = '';

    powerArray.digits.forEach(value => {
      if (value !== 0) {
        firstLineOfAnswersArray.push(value);
      }
    });

    // This generates the line of answers, returning the correct amount of answer boxes.
    firstLineOfAnswersArray.forEach((_value, index) => {
      if (index === 0) {
        answerString += '<ans/> ';
      } else {
        answerString += `${ADD} <ans/> `;
      }
    });

    const answerCheck = (answer: string[]) => {
      let totalOfAnswers = 0;

      // This adds the line of answers to totalOfAnswers.
      answer.forEach(value => (totalOfAnswers += parseInt(value)));

      // Check totalOfAnswers equals number, and that no zeroes have been passed in any of the answer boxes.
      return totalOfAnswers === number && !answer.includes('0');
    };
    const markSchemeAnswer = powerArray.digits
      .map((digit, index) => (digit * Math.pow(10, powerArray.e - index)).toLocaleString())
      .filter(val => val !== '0');

    const isDigital2LineAns = displayMode === 'digital' && firstLineOfAnswersArray.length > 3;

    return (
      <QF3Content
        actionPanelVariant="endWide"
        inputType="numpad"
        questionHeight={1100}
        title={translate.instructions.completeNumberSentenceToPartition()}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.validSentenceMatchingContent()
        }}
        Content={({ dimens }) => (
          <>
            <PlaceValueChart
              number={ScientificNotation.fromNumber(number)}
              columnsToShow={[5, 4, 3, 2, 1, 0]}
              counterVariant="greyCounter"
              headerVariant="shortName"
              dimens={{
                width: dimens.width,
                height: dimens.height * (isDigital2LineAns ? 0.5 : 0.7)
              }}
              counterSize={displayMode === 'markscheme' ? 90 : undefined}
            />
            <AlignedEquationsWithState
              id="alignedEq"
              inputMaxCharacters={6}
              leftSide={[number.toLocaleString()]}
              rightSide={[answerString]}
              rightColumnStyle={{
                width: isDigital2LineAns
                  ? // Atleast 2 answer on 2nd row
                    firstLineOfAnswersArray.length === 4
                    ? 450
                    : 650
                  : undefined,
                flexWrap: 'wrap'
              }}
              leftColumnStyle={{
                alignSelf: isDigital2LineAns ? 'flex-start' : 'center',
                height: 110,
                justifyContent: 'center'
              }}
              middleCharacterStyle={{
                alignSelf: isDigital2LineAns ? 'flex-start' : 'center',
                height: 110,
                justifyContent: 'center'
              }}
              mainPanelStyle={{ justifyContent: 'flex-start' }}
              testCorrect={({ right }) => answerCheck(right[0])}
              defaultState={
                displayMode === 'markscheme' ? { left: [], right: [markSchemeAnswer] } : undefined
              }
            />
          </>
        )}
      />
    );
  }
});

export const Question3 = newQuestionContent({
  uid: 'adS',
  description: 'adS',
  keywords: ['Place value', 'Partition', 'Part-whole', '1,000,000', 'Million'],
  schema: z.object({
    thousands: z.number().int().min(1000).max(9000).step(1000),
    tenThousands: z.number().int().min(10000).max(90000).step(10000),
    hundredThousands: z.number().int().min(100000).max(900000).step(100000),
    unitOfAnswer: z.enum(['thousands', 'ten thousands', 'hundred thousands'])
  }),
  simpleGenerator: () => {
    const unitOfAnswer = getRandomFromArray([
      'thousands',
      'ten thousands',
      'hundred thousands'
    ] as const);
    const thousands = randomIntegerInclusiveStep(1000, 9000, 1000);
    const tenThousands = randomIntegerInclusiveStep(10000, 90000, 10000);
    const hundredThousands = randomIntegerInclusiveStep(100000, 900000, 100000);

    return { thousands, tenThousands, hundredThousands, unitOfAnswer };
  },
  Component: ({ question, ...props }) => {
    const { thousands, tenThousands, hundredThousands, unitOfAnswer } = question;

    const number = thousands + tenThousands + hundredThousands;
    const { translate } = props;

    let partition: (number | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'thousands') {
      partition = ['$ans', tenThousands, hundredThousands];
      correctAnswer = thousands;
    } else if (unitOfAnswer === 'ten thousands') {
      partition = [thousands, '$ans', hundredThousands];
      correctAnswer = tenThousands;
    } else {
      partition = [thousands, tenThousands, '$ans'];
      correctAnswer = hundredThousands;
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'adT',
  description: 'adT',
  keywords: ['Place value', 'Partition', '10,000'],
  schema: z.object({
    number: z.number().int().min(100001).max(999999)
  }),
  example: {
    number: 202153
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(100001, 999999, {
      // Enforce that at least one digit (not 100,000s) is 0.
      constraint: x => {
        const sci = ScientificNotation.fromNumber(x);
        return [0, 1, 2, 3, 4].some(pow => sci.digitAt(pow) === 0);
      }
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const powerArray = ScientificNotation.fromNumber(number);

    const firstLineOfAnswersArray: number[] = [];

    let answerString = `${number.toLocaleString()} = `;

    powerArray.digits.forEach(value => {
      if (value !== 0) {
        firstLineOfAnswersArray.push(value);
      }
    });

    // This generates the line of answers, returning the correct amount of answer boxes.
    firstLineOfAnswersArray.forEach((_value, index) => {
      if (index === 0) {
        answerString += '<ans/>';
      } else {
        answerString += ' + <ans/>';
      }
    });

    const answerCheck = (answer: string[]) => {
      let totalOfAnswers = 0;

      // This adds the line of answers to totalOfAnswers.
      answer.forEach(value => (totalOfAnswers += parseInt(value)));

      // Check totalOfAnswers equals number, and that no zeroes have been passed in any of the answer boxes.
      return totalOfAnswers === number && !answer.includes('0');
    };

    const markSchemeAnswer = powerArray.digits
      .map((digit, index) => (digit * Math.pow(10, powerArray.e - index)).toLocaleString())
      .filter(val => val !== '0');

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.partitionNumber()}
        testCorrect={answer => answerCheck(answer)}
        sentence={answerString}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        inputMaxCharacters={6}
        customMarkSchemeAnswer={{ answersToDisplay: markSchemeAnswer }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'adT2',
  description: 'adT',
  keywords: ['Place value', 'Partition', '10,000'],
  schema: z.object({
    number: z.number().int().min(100001).max(999999)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(100001, 999999, {
      // Enforce that at least one digit (not 100,000s) is 0.
      constraint: x => {
        const sci = ScientificNotation.fromNumber(x);
        return [0, 1, 2, 3, 4].some(pow => sci.digitAt(pow) === 0);
      }
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    const powerArray = ScientificNotation.fromNumber(number);

    const firstLineOfAnswersArray: number[] = [];

    let answerString = '';

    powerArray.digits.forEach(value => {
      if (value !== 0) {
        firstLineOfAnswersArray.push(value);
      }
    });

    // This generates the line of answers, returning the correct amount of answer boxes.
    firstLineOfAnswersArray.forEach((_value, index) => {
      if (index === 0) {
        answerString += '<ans/>';
      } else {
        answerString += ' + <ans/>';
      }
    });

    const answerCheck = (answer: string[]) => {
      const totalOfAnswers = sumNumberArray(answer.map(value => parseInt(value)));

      // Check totalOfAnswers equals number, and that no zeroes have been passed in any of the answer boxes.
      return totalOfAnswers === number && !answer.includes('0');
    };

    const markSchemeAnswer = powerArray.digits
      .map((digit, index) => (digit * Math.pow(10, powerArray.e - index)).toLocaleString())
      .filter(val => val !== '0');

    const isDigital = displayMode === 'digital';
    const ansIs2Lines = firstLineOfAnswersArray.length === 5;

    return (
      <QF2AlignedEquations
        actionPanelVariant="bottomTall"
        title={translate.instructions.partitionNumber()}
        testCorrect={({ right }) => answerCheck(right[0])}
        leftSide={[number.toLocaleString()]}
        rightSide={[answerString]}
        // Atleast 2 answer boxes on second row
        rightColumnStyle={{ width: isDigital && ansIs2Lines ? 750 : undefined, flexWrap: 'wrap' }}
        leftColumnStyle={{
          alignSelf: isDigital && ansIs2Lines ? 'flex-start' : 'center',
          height: 110,
          justifyContent: 'center'
        }}
        middleCharacterStyle={{
          alignSelf: isDigital && ansIs2Lines ? 'flex-start' : 'center',
          height: 110,
          justifyContent: 'center'
        }}
        mainPanelStyle={{ justifyContent: 'flex-start' }}
        inputMaxCharacters={6}
        customMarkSchemeAnswer={{
          answersToDisplay: { right: [markSchemeAnswer] },
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
      />
    );
  }
});

export const Question5 = newQuestionContent({
  uid: 'adU',
  description: 'adU',
  keywords: ['Place value', 'Partition', '1,000,000', 'Million', 'Gattegno'],
  schema: z.object({
    number: z.number().int().min(10001).max(99999)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(10001, 99999);
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF12CreateGattegnoChart
        correctAnswer={number}
        rowsToShow={[4, 3, 2, 1, 0]}
        title={translate.instructions.useGattegnotoShowNum(number.toLocaleString())}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'adV',
  description: 'adV',
  keywords: ['Place value', 'Flexible partition', '1,000,000', 'Million'],
  schema: z
    .object({
      number: z
        .number()
        .int()
        .min(210000)
        .max(999999)
        .refine(x => {
          const { hundredThousands = 0, tenThousands = 0, thousands = 0 } = numberToBase10Object(x);
          return hundredThousands !== 0 && tenThousands !== 0 && thousands !== 0;
        }, 'hundred-thousands, ten-thousands, and thousands digits must not be 0'),
      partitions: z.number().int().array().min(2).array().length(4)
    })
    .refine(
      ({ partitions }) =>
        arrayHasNoDuplicates(partitions.map(partition => JSON.stringify(partition))),
      'Duplicate partitions are not allowed'
    )
    .refine(
      ({ number, partitions }) => partitions.map(sumNumberArray).includes(number),
      'At least one partition must be correct'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const number = randomIntegerInclusive(210000, 999999, {
          constraint: x => {
            const {
              hundredThousands = 0,
              tenThousands = 0,
              thousands = 0
            } = numberToBase10Object(x);
            return hundredThousands !== 0 && tenThousands !== 0 && thousands !== 0;
          }
        });
        const {
          hundredThousands = 0,
          tenThousands = 0,
          thousands = 0,
          hundreds = 0,
          tens = 0,
          ones = 0
        } = numberToBase10Object(number);
        const standardPartition = [
          hundredThousands * 100000,
          tenThousands * 10000,
          thousands * 1000,
          hundreds * 100,
          tens * 10,
          ones
        ].filter(part => part !== 0);

        // See aaP for context of how this works
        const makePartition = (x: number): number[] => {
          return rejectionSample(
            () => {
              let {
                hundredThousands = 0,
                tenThousands = 0,
                thousands = 0,
                hundreds = 0,
                tens = 0,
                ones = 0
              } = numberToBase10Object(x);

              const borrowedTens = randomIntegerInclusive(0, tens);
              tens -= borrowedTens;
              ones += borrowedTens * 10;

              const borrowedHundreds = randomIntegerInclusive(0, hundreds);
              hundreds -= borrowedHundreds;
              tens += borrowedHundreds * 10;

              const borrowedThousands = randomIntegerInclusive(0, thousands);
              thousands -= borrowedThousands;
              hundreds += borrowedThousands * 10;

              const borrowedTenThousands = randomIntegerInclusive(0, tenThousands);
              tenThousands -= borrowedTenThousands;
              thousands += borrowedTenThousands * 10;

              const borrowedHundredThousands = randomIntegerInclusive(0, hundredThousands);
              hundredThousands -= borrowedHundredThousands;
              tenThousands += borrowedHundredThousands * 10;

              return [
                hundredThousands * 100000,
                tenThousands * 10000,
                thousands * 1000,
                hundreds * 100,
                tens * 10,
                ones
              ].filter(it => it !== 0);
            },
            partition =>
              partition.length >= 2 && !arraysHaveSameContents(partition, standardPartition)
          );
        };

        const partitions = shuffle([
          makePartition(number - 110000),
          makePartition(number),
          makePartition(number),
          makePartition(number)
        ]);

        return { number, partitions };
      },
      ({ partitions }) =>
        arrayHasNoDuplicates(partitions.map(partition => JSON.stringify(partition)))
    ),
  Component: ({ question: { number, partitions }, translate, displayMode }) => {
    const {
      hundredThousands = 0,
      tenThousands = 0,
      thousands = 0,
      hundreds = 0,
      tens = 0,
      ones = 0
    } = numberToBase10Object(number);
    const standardPartition = [
      hundredThousands * 100000,
      tenThousands * 10000,
      thousands * 1000,
      hundreds * 100,
      tens * 10,
      ones
    ].filter(part => part !== 0);

    const partitionToNumberSentence = (partition: number[]): string =>
      partition.map(part => part.toLocaleString()).join(` ${ADD} `);

    const partitionsInfo = partitions.map((partition, index) => ({
      value: index,
      text: partitionToNumberSentence(partition),
      correct: sumNumberArray(partition) === number
    }));

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectNumberSentencesThatAreEqualToX(
          partitionToNumberSentence(standardPartition)
        )}
        pdfTitle={translate.instructions.circleNumberSentencesThatAreEqualToX(
          partitionToNumberSentence(standardPartition)
        )}
        testCorrect={partitionsInfo
          .filter(partition => partition.correct)
          .map(partition => partition.value)}
        renderItems={partitionsInfo.map(({ value, text }) => ({
          value,
          component: (
            <Text variant="WRN700" style={{ fontSize: displayMode !== 'digital' ? 40 : 22 }}>
              {text}
            </Text>
          )
        }))}
        multiSelect
        numItems={4}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PartitionNumbersTo1000000',
  questionTypes: [Question1v3, Question2v2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question1, Question1v2, Question2, Question4]
});
export default SmallStep;
