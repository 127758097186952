import { View } from 'react-native';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import { addDurationTo24hTime } from '../../../../utils/time';
import { ClockWithState } from '../../../../components/question/representations/Clock';
import { DigitalClock } from '../../../../components/question/representations/DigitalClock';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { QF51CompleteTheDigitalClock } from '../../../../components/question/questionFormats/QF51CompleteTheDigitalClock';
import {
  getRandomSchoolSubject,
  schoolSubjectAsWord,
  schoolSubjectAsWordWithPrefix,
  schoolSubjectsSchema
} from '../../../../utils/schoolSubjects';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import Text from '../../../../components/typography/Text';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'auo',
  description: 'auo',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z.object({
    startHour: z.number().int().min(9).max(10),
    startMinute: z.number().int().min(5).max(25).multipleOf(5),
    duration: z.number().int().min(10).max(30).multipleOf(5)
  }),
  simpleGenerator: () => {
    const startHour = randomIntegerInclusive(9, 10);

    const startMinute = randomIntegerInclusiveStep(5, 25, 5);

    const duration = randomIntegerInclusiveStep(10, 30, 5);

    return { startHour, startMinute, duration };
  },

  Component: props => {
    const {
      question: { startHour, startMinute, duration },
      translate
    } = props;

    return (
      <QF51CompleteTheDigitalClock
        title={translate.instructions.breakTimeStartsAtTimeAm(
          startHour,
          startMinute.toString().padStart(2, '0'),
          duration
        )}
        testCorrect={[startHour.toString(), (startMinute + duration).toString()]}
        hours={'<ans/>'}
        minutes={'<ans/>'}
        amOrPm="am"
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question2 = newQuestionContent({
  uid: 'aup',
  description: 'aup',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z.object({
    name: nameSchema,
    startHour: z.number().int().min(15).max(16),
    startMinute: z.number().int().min(30).max(55).multipleOf(5),
    duration: z.number().int().min(35).max(50).multipleOf(5)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const name = getRandomName();

    const startHour = randomIntegerInclusive(15, 16);

    const startMinute = randomIntegerInclusiveStep(30, 55, 5);

    const duration = randomIntegerInclusiveStep(35, 50, 5);

    return { name, startHour, startMinute, duration };
  },

  Component: props => {
    const {
      question: { name, startHour, startMinute, duration },
      translate
    } = props;

    let correctMinute = (startMinute + duration - 60).toString();
    if (startMinute + duration - 60 < 10) {
      correctMinute = '0' + correctMinute;
    }

    return (
      <QF1ContentAndSentences
        title={translate.instructions.characterStartsWatchingACartoonAtThisTime(name)}
        testCorrect={[[], [(startHour - 11).toString(), correctMinute]]}
        sentences={[
          translate.answerSentences.theCartoonLastsForNumMinutes(duration),
          translate.answerSentences.ansAnsPm()
        ]}
        Content={({ dimens }) => (
          <>
            <DigitalClock
              hours={startHour - 12}
              minutes={startMinute}
              dimens={dimens}
              amOrPm="pm"
            />
          </>
        )}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aup2',
  description: 'aup',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z.object({
    name: nameSchema,
    startHour: z.number().int().min(15).max(16),
    startMinute: z.number().int().min(30).max(55).multipleOf(5),
    duration: z.number().int().min(35).max(50).multipleOf(5)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const name = getRandomName();

    const startHour = randomIntegerInclusive(15, 16);

    const startMinute = randomIntegerInclusiveStep(30, 55, 5);

    const duration = randomIntegerInclusiveStep(35, 50, 5);

    return { name, startHour, startMinute, duration };
  },

  Component: props => {
    const {
      question: { name, startHour, startMinute, duration },
      translate,
      displayMode
    } = props;

    let correctMinute = (startMinute + duration - 60).toString();
    if (startMinute + duration - 60 < 10) {
      correctMinute = '0' + correctMinute;
    }

    return (
      <QF1ContentAndSentences
        title={translate.instructions.characterStartsWatchingACartoonAtThisTime(name)}
        testCorrect={[[(startHour - 11).toString(), correctMinute]]}
        sentences={[translate.answerSentences.ansAnsPm()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'flex-start', width: dimens.width, rowGap: 24 }}>
            <View style={{ alignSelf: 'center' }}>
              <DigitalClock
                hours={startHour - 12}
                minutes={startMinute}
                dimens={{ height: dimens.height * 0.7, width: dimens.width * 0.7 }}
                amOrPm="pm"
              />
            </View>
            <View style={{ alignItems: 'flex-start' }}>
              <Text style={{ fontSize: displayMode === 'digital' ? 32 : 50 }} variant="WRN400">
                {translate.answerSentences.theCartoonLastsForNumMinutes(duration)}
              </Text>
            </View>
          </View>
        )}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'auq',
  description: 'auq',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z.object({
    duration: z.number().int().min(35).max(55).multipleOf(5),
    amOrPm: z.enum(['am', 'pm']),
    startHour: z.number().int().min(2).max(10),
    startMinute: z.number().int().min(5).max(30).multipleOf(5),
    schoolSubject: schoolSubjectsSchema
  }),
  simpleGenerator: () => {
    const amOrPm = getRandomFromArray(['am', 'pm'] as const);

    const startHour =
      amOrPm === 'am' ? randomIntegerInclusive(9, 10) : randomIntegerInclusive(2, 3);

    const startMinute = randomIntegerInclusiveStep(5, 30, 5);

    const duration = randomIntegerInclusiveStep(35, 55, 5);

    const schoolSubject = getRandomSchoolSubject();

    return { duration, amOrPm, startHour, startMinute, schoolSubject };
  },

  Component: props => {
    const {
      question: { duration, amOrPm, startHour, startMinute, schoolSubject },
      translate
    } = props;

    const subjectString = schoolSubjectAsWord(schoolSubject, translate);
    const prefixSubjectString = schoolSubjectAsWordWithPrefix(schoolSubject, translate);

    const title =
      amOrPm === 'am'
        ? translate.instructions.theDurationOfSchoolSubjectIsNumMinutesAm(
            prefixSubjectString,
            duration,
            subjectString,
            startHour,
            startMinute.toString().padStart(2, '0')
          )
        : translate.instructions.theDurationOfSchoolSubjectIsNumMinutesPm(
            prefixSubjectString,
            duration,
            subjectString,
            startHour,
            startMinute.toString().padStart(2, '0')
          );

    return (
      <QF51CompleteTheDigitalClock
        title={title}
        testCorrect={[(startHour - 1).toString(), (60 - duration + startMinute).toString()]}
        hours={'<ans/>'}
        minutes={'<ans/>'}
        amOrPm={amOrPm}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question4 = newQuestionContent({
  uid: 'aur',
  description: 'aur',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Analogue', 'Duration'],
  schema: z.object({
    durationHours: z.number().int().min(1).max(2),
    durationMinutes: z.number().int().min(26).max(59),
    startHour: z.number().int().min(1).max(6),
    startMinute: z.number().int().min(35).max(59)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const durationHours = randomIntegerInclusive(1, 2);

    const durationMinutes = randomIntegerInclusive(26, 59, {
      constraint: x => x % 5 !== 0
    });

    const startHour = randomIntegerInclusive(1, 6);

    const startMinute = randomIntegerInclusive(35, 59);

    return { durationHours, durationMinutes, startHour, startMinute };
  },
  Component: ({
    question: { durationHours, durationMinutes, startHour, startMinute },
    translate,
    displayMode
  }) => {
    const [endHour, endMinute] = addDurationTo24hTime(
      startHour,
      startMinute,
      durationHours * 60 + durationMinutes
    );
    const interactive = displayMode === 'digital' ? true : false;
    const showHands = displayMode === 'pdf' ? false : true;
    const initialState =
      displayMode === 'markscheme' ? { hours: endHour, minutes: endMinute } : undefined;

    return (
      <QF3Content
        title={translate.instructions.aConcertLastsNumHoursAndNumMinutes(
          durationHours,
          durationMinutes
        )}
        pdfTitle={translate.instructions.aConcertLastsNumHoursAndNumMinutesDraw(
          durationHours,
          durationMinutes
        )}
        Content={({ dimens }) => (
          <>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-around'
              }}
            >
              <Text variant="WRN700">{translate.misc.startTime()}</Text>
              <Text variant="WRN700">{translate.misc.finishTime()}</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-around'
              }}
            >
              <DigitalClock
                hours={startHour}
                minutes={startMinute}
                dimens={{
                  height: dimens.height,
                  width: Math.min(dimens.width * 0.4, dimens.height)
                }}
                amOrPm="pm"
              />
              <ClockWithState
                id="clock"
                width={Math.min(dimens.width * 0.4, dimens.height)}
                testCorrect={answer => answer.hours === endHour && answer.minutes === endMinute}
                interactive={interactive}
                showHands={showHands}
                defaultState={initialState}
              />
            </View>
          </>
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aus',
  description: 'aus',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z.object({
    name: nameSchema,
    startHour: z.number().int().min(6).max(8),
    startMinute: z.number().int().min(40).max(55).multipleOf(5),
    durationHours: z.number().int().min(1).max(3),
    durationMinutes: z.number().int().min(21).max(59)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const { startHour, startMinute, durationHours, durationMinutes } = rejectionSample(
      () => {
        const startHour = randomIntegerInclusive(6, 8);

        const startMinute = randomIntegerInclusiveStep(40, 55, 5);

        const durationHours = randomIntegerInclusive(1, 3);

        const durationMinutes = randomIntegerInclusive(21, 59, {
          constraint: x => x % 5 !== 0
        });
        return { startHour, startMinute, durationHours, durationMinutes };
      },
      // Only permit them if they don't go past the 12:00 mark
      ({ startHour, startMinute, durationHours, durationMinutes }) => {
        if (startHour + durationHours === 11 && startMinute + durationMinutes > 59) {
          return false;
        }
        return true;
      }
    );

    return { name, startHour, startMinute, durationHours, durationMinutes };
  },

  Component: props => {
    const {
      question: { name, startHour, startMinute, durationHours, durationMinutes },
      translate
    } = props;

    const [correctHour, correctMinute] = addDurationTo24hTime(
      startHour,
      startMinute,
      durationHours * 60 + durationMinutes
    );

    return (
      <QF51CompleteTheDigitalClock
        title={translate.instructions.characterStartsWatchingAFilmAtTimeWhatTimeFinish(
          name,
          startHour,
          startMinute.toString().padStart(2, '0'),
          durationHours,
          durationMinutes
        )}
        testCorrect={[correctHour.toString(), correctMinute.toString()]}
        hours={'<ans/>'}
        minutes={'<ans/>'}
        amOrPm="pm"
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question6 = newQuestionContent({
  uid: 'aut',
  description: 'aut',
  keywords: ['Time', 'Hours', 'Minutes', 'Digital', 'Duration'],
  schema: z.object({
    durationHours: z.number().int().min(1).max(2),
    durationMinutes: z.number().int().min(11).max(59),
    endHour: z.number().int().min(7).max(9),
    endMinute: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const durationHours = randomIntegerInclusive(1, 2);

    const durationMinutes = randomIntegerInclusive(11, 59, {
      constraint: x => x % 5 !== 0
    });

    const endHour = randomIntegerInclusive(7, 9);

    const endMinute = randomIntegerInclusive(1, 10);

    return { durationHours, durationMinutes, endHour, endMinute };
  },

  Component: props => {
    const {
      question: { durationHours, durationMinutes, endHour, endMinute },
      translate
    } = props;

    const correctHour = endHour - (durationHours + 1);
    const correctMinute = endMinute + 60 - durationMinutes;

    return (
      <QF51CompleteTheDigitalClock
        title={translate.instructions.itTakesMrSmithXHoursAndYMinsToDriveToWork(
          durationHours,
          durationMinutes,
          endHour,
          endMinute.toString().padStart(2, '0')
        )}
        testCorrect={[correctHour.toString(), correctMinute.toString()]}
        hours={'<ans/>'}
        minutes={'<ans/>'}
        amOrPm="am"
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HoursAndMinutesUseDurations',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
