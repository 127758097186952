import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { filledArray } from '../../../../utils/collections';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { compareFractions } from '../../../../utils/fractions';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aGY',
  description: 'aGY',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    metres: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const metres = randomIntegerInclusive(2, 5);

    return { metres };
  },
  Component: ({ question: { metres }, translate }) => {
    const numbers = filledArray(1, metres);

    const strings = [
      filledArray(translate.units.numberOfM(1), metres),
      filledArray(translate.units.numberOfCm(100), metres)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteSentence()}
        sentence={translate.answerSentences.thereAreAnsCmInNumM(metres)}
        testCorrect={[(metres * 100).toString()]}
        Content={({ dimens }) => (
          <BarModel
            total={metres}
            numbers={[numbers, numbers]}
            strings={strings}
            dimens={dimens}
            sameRowColor
            oneFontSize
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGZ',
  description: 'aGZ',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    metres: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const metres = randomIntegerInclusive(2, 5);

    return { metres };
  },
  Component: ({ question: { metres }, translate }) => {
    const numbers = filledArray(1, metres);

    const strings = [
      filledArray(translate.units.numberOfM(1), metres),
      filledArray(translate.units.numberOfCm(100), metres)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteSentence()}
        sentence={translate.answerSentences.thereAreNumCmInAnsM(metres * 100)}
        testCorrect={[metres.toString()]}
        Content={({ dimens }) => (
          <BarModel
            total={metres}
            numbers={[numbers, numbers]}
            strings={strings}
            dimens={dimens}
            sameRowColor
            oneFontSize
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aG0',
  description: 'aG0',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    metres: z.number().int().min(1).max(4),
    centimetres: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const metres = randomIntegerInclusive(1, 4);

    const centimetres = randomIntegerInclusive(1, 99);

    return { metres, centimetres };
  },
  Component: ({ question: { metres, centimetres }, translate, displayMode }) => {
    const total = metres * 100 + centimetres;

    const cmPerc = centimetres / total;
    // force them to be big enough to have font size 40
    const cmNumber = cmPerc < 0.32 ? total * 0.32 : centimetres;

    const numbers = [[total], [total - cmNumber, cmNumber]];

    const strings = [
      [translate.units.numberOfCm(total)],
      [translate.units.stringM('___'), translate.units.stringCm('___')]
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteSentence()}
        sentence={translate.answerSentences.numCmEqualsAnsMAndAnsCm(total)}
        inputMaxCharacters={2}
        testCorrect={userAnswer =>
          // Ensure equivalent answers are accepted:
          parseInt(userAnswer[0]) * 100 + parseInt(userAnswer[1]) === total
        }
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            total={total}
            numbers={numbers}
            strings={strings}
            dimens={dimens}
            oneFontSize
            maxFontSize={displayMode === 'digital' ? 40 : 50}
          />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [metres.toLocaleString(), centimetres.toLocaleString()],
          answerText: translate.markScheme.acceptValidAnswersForContent()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aG1',
  description: 'aG1',
  keywords: ['Bar model', 'Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    metres: z.number().int().min(1).max(4),
    centimetres: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const metres = randomIntegerInclusive(1, 4);

    const centimetres = randomIntegerInclusive(1, 99);

    return { metres, centimetres };
  },
  Component: ({ question: { metres, centimetres }, translate, displayMode }) => {
    const total = metres * 100 + centimetres;

    const cmPerc = centimetres / total;
    // force them to be big enough to have font size 40
    const cmNumber = cmPerc < 0.22 ? total * 0.22 : centimetres;

    const numbers = [[total], [total - cmNumber, cmNumber]];

    const strings = [
      [translate.units.stringCm('___')],
      [translate.units.numberOfM(metres), translate.units.numberOfCm(centimetres)]
    ];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToCompleteSentence()}
        sentence={translate.answerSentences.ansCmEqualsNumMAndNumCm(metres, centimetres)}
        testCorrect={[total.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BarModel
            total={total}
            numbers={numbers}
            strings={strings}
            dimens={dimens}
            oneFontSize
            maxFontSize={displayMode === 'digital' ? 40 : 50}
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aG2',
  description: 'aG2',
  keywords: ['Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    metresA: z.number().int().min(1).max(9),
    centimetresA: z.number().int().min(1).max(99),
    metresB: z.number().int().min(1).max(9),
    centimetresB: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const [metresA, metresB] = randomUniqueIntegersInclusive(1, 9, 2);

    const [centimetresA, centimetresB] = randomUniqueIntegersInclusive(1, 99, 2);

    return { metresA, metresB, centimetresA, centimetresB };
  },
  Component: ({ question: { metresA, metresB, centimetresA, centimetresB }, translate }) => {
    const totalA = metresA * 100 + centimetresA;

    const totalB = metresB * 100 + centimetresB;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        sentences={[
          translate.answerSentences.numCmEqualsAnsMAndAnsCm(totalA),
          translate.answerSentences.numMAndNumCmEqualsAnsCm(metresB, centimetresB)
        ]}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          // Ensure equivalent answers are accepted for line A:
          parseInt(userAnswer[0][0]) * 100 + parseInt(userAnswer[0][1]) === totalA &&
          userAnswer[1][0] === totalB.toString()
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [metresA.toLocaleString(), centimetresA.toLocaleString()],
            [totalB.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptValidAnswersForContent()
        }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aG22',
  description: 'aG2',
  keywords: ['Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    metres: z.number().int().min(1).max(9),
    centimetres: z.number().int().min(1).max(99),
    sentenceId: numberEnum([0, 1])
  }),
  simpleGenerator: () => {
    const metres = randomIntegerInclusive(1, 9);

    const centimetres = randomIntegerInclusive(1, 99);

    const sentenceId = getRandomFromArray([0, 1] as const);

    return { metres, centimetres, sentenceId };
  },
  Component: ({ question: { metres, centimetres, sentenceId }, translate }) => {
    const total = metres * 100 + centimetres;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        sentence={
          sentenceId
            ? translate.answerSentences.numCmEqualsAnsMAndAnsCm(total)
            : translate.answerSentences.numMAndNumCmEqualsAnsCm(metres, centimetres)
        }
        inputMaxCharacters={3}
        testCorrect={
          sentenceId
            ? [metres.toLocaleString(), centimetres.toLocaleString()]
            : [total.toLocaleString()]
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aG3',
  description: 'aG3',
  keywords: ['Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    metresA: z.number().int().min(0).max(9),
    metresB: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const metresA = randomIntegerInclusive(0, 9);

    const metresB = randomIntegerInclusive(1, 9, {
      constraint: x => x !== metresA
    });

    return { metresA, metresB };
  },
  Component: ({ question: { metresA, metresB }, translate }) => {
    const totalA = metresA * 100 + 50;

    const totalB = metresB * 100 + 50;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        sentences={[
          translate.answerSentences.xMEqualsAnsCm(
            metresA === 0
              ? `<frac n='${(1).toLocaleString()}' d='${(2).toLocaleString()}' />`
              : `<frac w='${metresA.toLocaleString()}' n='${(1).toLocaleString()}' d='${(2).toLocaleString()}' />`
          ),
          translate.answerSentences.numCmEqualsXM(totalB, `<frac wAns='' nAns='' dAns='' />`)
        ]}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          userAnswer[0][0] === totalA.toString() && compareFractions(userAnswer[1], [metresB, 1, 2])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [totalA.toLocaleString()],
            [metresB.toLocaleString(), (1).toLocaleString(), (2).toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aG32',
  description: 'aG3',
  keywords: ['Metric', 'Converting units', 'Length', 'Metres', 'Centimetres'],
  schema: z.object({
    metres: z.number().int().min(0).max(9),
    sentenceId: numberEnum([0, 1])
  }),
  simpleGenerator: () => {
    const metres = randomIntegerInclusive(0, 9);
    const sentenceId = getRandomFromArray([0, 1] as const);

    return { metres, sentenceId };
  },
  Component: ({ question: { metres, sentenceId }, translate, displayMode }) => {
    const total = metres * 100 + 50;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        sentence={
          sentenceId
            ? translate.answerSentences.xMEqualsAnsCm(
                metres === 0
                  ? `<frac n='${(1).toLocaleString()}' d='${(2).toLocaleString()}' />`
                  : `<frac w='${metres.toLocaleString()}' n='${(1).toLocaleString()}' d='${(2).toLocaleString()}' />`
              )
            : translate.answerSentences.numCmEqualsXM(total, `<frac wAns='' nAns='' dAns='' />`)
        }
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          sentenceId
            ? userAnswer[0] === total.toString()
            : compareFractions(userAnswer, [metres, 1, 2])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: sentenceId
            ? [total.toLocaleString()]
            : [metres.toLocaleString(), (1).toLocaleString(), (2).toLocaleString()],
          answerText: sentenceId ? '' : translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentLengthsMetresAndCentimetres',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
