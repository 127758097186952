import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { simplify } from '../../../../utils/fractions';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { AssetSvg } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { arraysHaveSameContents, countRange, filledArray } from '../../../../utils/collections';
import { compareRatios } from '../../../../utils/ratios';
import { lowercase } from 'typesafe-i18n/formatters';
import Text from '../../../../components/typography/Text';
import { Dimens } from '../../../../theme/scaling';
import {
  labelledCubeColors,
  LabelledCubeColorSchema,
  labelledCubeSvg
} from '../../../../utils/shapeImages/shapes';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aSS',
  description: 'aSS',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBAmount: z.number().int().min(3).max(12),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBAmount = randomIntegerInclusive(animalAAmount + 1, 12);

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBAmount, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBAmount, animalAFirst },
      translate
    } = props;

    const [firstAnimalRatio, secondAnimalRatio] = animalAFirst
      ? simplify(animalAAmount, animalBAmount)
      : simplify(animalBAmount, animalAAmount);

    const animalsAArray = filledArray(animalA, animalAAmount);

    const animalsBArray = filledArray(animalB, animalBAmount);

    const totalRows = Math.ceil(animalsAArray.length / 6) + Math.ceil(animalsBArray.length / 6);

    const itemA =
      animalA === 'Cow'
        ? translate.animals.cowsParentheses()
        : animalA === 'Horse'
        ? translate.animals.horsesParentheses()
        : translate.animals.sheep();

    const itemB =
      animalB === 'Cow'
        ? translate.animals.cowsParentheses()
        : animalB === 'Horse'
        ? translate.animals.horsesParentheses()
        : translate.animals.sheep();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={[itemA, itemB]}
        itemVariant="shortRectangle"
        actionPanelVariant="endMid"
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsAArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={dimens.width / 6}
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsBArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={dimens.width / 6}
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
          </View>
        )}
        sentence={translate.answerSentences.forEveryXAnsThereAreYAns(
          firstAnimalRatio,
          secondAnimalRatio
        )}
        testCorrect={animalAFirst ? [itemA, itemB] : [itemB, itemA]}
        pdfItemVariant="tallRectangle"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question1v2 = newQuestionContent({
  uid: 'aSS2',
  description: 'aSS',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBAmount: z.number().int().min(3).max(12),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBAmount = randomIntegerInclusive(animalAAmount + 1, 12);

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBAmount, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBAmount, animalAFirst },
      translate,
      displayMode
    } = props;

    const [firstAnimalRatio, secondAnimalRatio] = animalAFirst
      ? [animalAAmount, animalBAmount]
      : [animalBAmount, animalAAmount];

    const animalsAArray = filledArray(animalA, animalAAmount);

    const animalsBArray = filledArray(animalB, animalBAmount);

    const totalRows = Math.ceil(animalsAArray.length / 6) + Math.ceil(animalsBArray.length / 6);

    const itemA = lowercase(
      animalA === 'Cow'
        ? translate.animals.Cow(animalAAmount)
        : animalA === 'Horse'
        ? translate.animals.Horse(animalAAmount)
        : translate.animals.sheep()
    );

    const itemB = lowercase(
      animalB === 'Cow'
        ? translate.animals.Cow(animalBAmount)
        : animalB === 'Horse'
        ? translate.animals.Horse(animalBAmount)
        : translate.animals.sheep()
    );

    const widthProportionA = animalA === 'Cow' ? 1 : animalA === 'Horse' ? 0.9 : 0.7;
    const widthProportionB = animalB === 'Cow' ? 1 : animalB === 'Horse' ? 0.9 : 0.7;

    const widthProportionAPDF = animalA === 'Horse' ? 1 : animalA === 'Cow' ? 0.9 : 0.5;
    const widthProportionBPDF = animalB === 'Horse' ? 1 : animalB === 'Cow' ? 0.9 : 0.5;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={[
          { value: 'A', component: <Text variant="WRN700">{itemA}</Text> },
          { value: 'B', component: <Text variant="WRN700">{itemB}</Text> }
        ]}
        itemVariant="shortRectangle"
        actionPanelVariant="endMid"
        sentencesStyle={{ alignItems: 'flex-start' }}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'center' }}>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsAArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={
                    (dimens.width / 6) *
                    (displayMode === 'digital' ? widthProportionA : widthProportionAPDF)
                  }
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsBArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={
                    (dimens.width / 6) *
                    (displayMode === 'digital' ? widthProportionB : widthProportionBPDF)
                  }
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
          </View>
        )}
        sentence={
          secondAnimalRatio === 1
            ? translate.answerSentences.forEveryXAnsThereIsYAns(firstAnimalRatio, secondAnimalRatio)
            : translate.answerSentences.forEveryXAnsThereAreYAns(
                firstAnimalRatio,
                secondAnimalRatio
              )
        }
        testCorrect={animalAFirst ? ['A', 'B'] : ['B', 'A']}
        pdfItemVariant="tallRectangle"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'aST',
  description: 'aST',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBAmount: z.number().int().min(3).max(12),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBAmount = randomIntegerInclusive(animalAAmount + 1, 12);

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBAmount, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBAmount, animalAFirst },
      translate
    } = props;

    const animalsAArray = filledArray(animalA, animalAAmount);

    const animalsBArray = filledArray(animalB, animalBAmount);

    const totalRows = Math.ceil(animalsAArray.length / 6) + Math.ceil(animalsBArray.length / 6);

    const animalAString = translate.animals[animalA](animalAAmount);

    const animalBString = translate.animals[animalB](animalBAmount);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsAArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={dimens.width / 6}
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsBArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={dimens.width / 6}
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
          </View>
        )}
        sentence={
          animalAFirst
            ? translate.answerSentences.forEveryAnsXThereAreAnsY(animalAString, animalBString)
            : translate.answerSentences.forEveryAnsXThereAreAnsY(animalBString, animalAString)
        }
        testCorrect={userAnswer =>
          compareRatios(
            [userAnswer[0], userAnswer[1]],
            animalAFirst ? [animalAAmount, animalBAmount] : [animalBAmount, animalAAmount]
          )
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: animalAFirst
            ? [animalAAmount.toLocaleString(), animalBAmount.toLocaleString()]
            : [animalBAmount.toLocaleString(), animalAAmount.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aST2',
  description: 'aST',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBAmount: z.number().int().min(3).max(12),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAFirst = getRandomBoolean();
    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBAmount = randomIntegerInclusive(animalAAmount + 1, 12, {
      // does not simplify to 1 : x
      constraint: x => x % animalAAmount !== 0
    });

    return { animalA, animalAAmount, animalB, animalBAmount, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBAmount, animalAFirst },
      translate
    } = props;

    const animalsAArray = filledArray(animalA, animalAAmount);

    const animalsBArray = filledArray(animalB, animalBAmount);

    const totalRows = Math.ceil(animalsAArray.length / 6) + Math.ceil(animalsBArray.length / 6);

    const animalAString = translate.animals[animalA](animalAAmount);

    const animalBString = translate.animals[animalB](animalBAmount);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsAArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={dimens.width / 7}
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsBArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={dimens.width / 7}
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
          </View>
        )}
        sentence={
          animalAFirst
            ? translate.answerSentences.forEveryAnsXThereAreAnsY(animalAString, animalBString)
            : translate.answerSentences.forEveryAnsXThereAreAnsY(animalBString, animalAString)
        }
        testCorrect={userAnswer =>
          compareRatios(
            [userAnswer[0], userAnswer[1]],
            animalAFirst ? [animalAAmount, animalBAmount] : [animalBAmount, animalAAmount]
          )
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: animalAFirst
            ? [animalAAmount.toLocaleString(), animalBAmount.toLocaleString()]
            : [animalBAmount.toLocaleString(), animalAAmount.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aSU',
  description: 'aSU',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBMultiplier: z.number().int().min(3).max(5),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBMultiplier = randomIntegerInclusive(3, 5, {
      constraint: x => x !== animalAAmount
    });

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBMultiplier, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBMultiplier, animalAFirst },
      translate
    } = props;

    const animalBAmount = animalAAmount * animalBMultiplier;

    const animalsAArray = filledArray(animalA, animalAAmount);

    const animalsBArray = filledArray(animalB, animalBAmount);

    const totalRows = Math.ceil(animalsAArray.length / 6) + Math.ceil(animalsBArray.length / 6);

    const animalAString = translate.animals[animalA](animalAAmount);

    const animalBString = translate.animals[animalB](animalBAmount);

    const items = shuffle(
      [
        animalAAmount.toLocaleString(),
        animalBAmount.toLocaleString(),
        animalBMultiplier.toLocaleString()
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={items}
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View style={dimens}>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsAArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={dimens.width / 6}
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsBArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={dimens.width / 6}
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
          </View>
        )}
        sentence={
          animalAFirst
            ? translate.answerSentences.forEveryAnsXThereAreAnsY(animalAString, animalBString)
            : translate.answerSentences.forEveryAnsXThereAreAnsY(animalBString, animalAString)
        }
        testCorrect={
          animalAFirst
            ? [animalAAmount.toLocaleString(), animalBAmount.toLocaleString()]
            : [animalBAmount.toLocaleString(), animalAAmount.toLocaleString()]
        }
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3v2 = newQuestionContent({
  uid: 'aSU2',
  description: 'aSU',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBAmount: z.number().int().min(3).max(12),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different')
    .refine(
      val =>
        !arraysHaveSameContents(simplify(val.animalAAmount, val.animalBAmount), [
          val.animalAAmount,
          val.animalBAmount
        ]),
      'animalAAmount, animalAmountB must simplify'
    ),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBAmount = randomIntegerInclusive(animalAAmount + 1, 12, {
      constraint: x => !arraysHaveSameContents(simplify(animalAAmount, x), [animalAAmount, x])
    });

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBAmount, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBAmount, animalAFirst },
      translate,
      displayMode
    } = props;

    const [simplifiedA, simplifiedB] = simplify(animalAAmount, animalBAmount);

    const [firstAnimalRatio, secondAnimalRatio] = animalAFirst
      ? [simplifiedA, simplifiedB]
      : [simplifiedB, simplifiedA];

    const animalsAArray = filledArray(animalA, animalAAmount);

    const animalsBArray = filledArray(animalB, animalBAmount);

    const totalRows = Math.ceil(animalsAArray.length / 6) + Math.ceil(animalsBArray.length / 6);

    const itemA = lowercase(
      animalA === 'Cow'
        ? translate.animals.Cow(simplifiedA)
        : animalA === 'Horse'
        ? translate.animals.Horse(simplifiedA)
        : translate.animals.sheep()
    );

    const itemB = lowercase(
      animalB === 'Cow'
        ? translate.animals.Cow(simplifiedB)
        : animalB === 'Horse'
        ? translate.animals.Horse(simplifiedB)
        : translate.animals.sheep()
    );

    const widthProportionA = animalA === 'Cow' ? 1 : animalA === 'Horse' ? 0.9 : 0.7;
    const widthProportionB = animalB === 'Cow' ? 1 : animalB === 'Horse' ? 0.9 : 0.7;

    const widthProportionAPDF = animalA === 'Horse' ? 1 : animalA === 'Cow' ? 0.9 : 0.5;
    const widthProportionBPDF = animalB === 'Horse' ? 1 : animalB === 'Cow' ? 0.9 : 0.5;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={[
          { value: 'A', component: <Text variant="WRN700">{itemA}</Text> },
          { value: 'B', component: <Text variant="WRN700">{itemB}</Text> }
        ]}
        itemVariant="shortRectangle"
        actionPanelVariant="endMid"
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'center' }}>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsAArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={
                    (dimens.width / 6) *
                    (displayMode === 'digital' ? widthProportionA : widthProportionAPDF)
                  }
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
            <View
              style={[{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {animalsBArray.map((animal, index) => (
                <AssetSvg
                  key={index}
                  name={animal}
                  width={
                    (dimens.width / 6) *
                    (displayMode === 'digital' ? widthProportionB : widthProportionBPDF)
                  }
                  height={dimens.height / totalRows}
                />
              ))}
            </View>
          </View>
        )}
        sentencesStyle={{ alignItems: 'flex-start' }}
        sentence={
          secondAnimalRatio === 1
            ? translate.answerSentences.forEveryXAnsThereIsYAns(firstAnimalRatio, secondAnimalRatio)
            : translate.answerSentences.forEveryXAnsThereAreYAns(
                firstAnimalRatio,
                secondAnimalRatio
              )
        }
        testCorrect={animalAFirst ? ['A', 'B'] : ['B', 'A']}
        pdfItemVariant="tallRectangle"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4 = newQuestionContent({
  uid: 'aSV',
  description: 'aSV',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBAmount: z.number().int().min(3).max(12),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBAmount = randomIntegerInclusive(animalAAmount + 1, 12);

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBAmount, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBAmount, animalAFirst },
      translate
    } = props;

    const [firstAnimalRatio, secondAnimalRatio] = animalAFirst
      ? simplify(animalAAmount, animalBAmount)
      : simplify(animalBAmount, animalAAmount);

    const animalsArray = shuffle(
      [...filledArray(animalA, animalAAmount), ...filledArray(animalB, animalBAmount)],
      {
        random: seededRandom(props.question)
      }
    );

    const itemA =
      animalA === 'Cow'
        ? translate.animals.cowsParentheses()
        : animalA === 'Horse'
        ? translate.animals.horsesParentheses()
        : translate.animals.sheep();

    const itemB =
      animalB === 'Cow'
        ? translate.animals.cowsParentheses()
        : animalB === 'Horse'
        ? translate.animals.horsesParentheses()
        : translate.animals.sheep();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={[itemA, itemB]}
        itemVariant="shortRectangle"
        actionPanelVariant="endMid"
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }
            ]}
          >
            {animalsArray.map((animal, index) => (
              <AssetSvg
                key={index}
                name={animal}
                width={dimens.width / 6}
                height={dimens.height / Math.ceil(animalsArray.length / 6)}
              />
            ))}
          </View>
        )}
        sentence={translate.answerSentences.forEveryXAnsThereAreYAns(
          firstAnimalRatio,
          secondAnimalRatio
        )}
        testCorrect={animalAFirst ? [itemA, itemB] : [itemB, itemA]}
        pdfItemVariant="tallRectangle"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4v2 = newQuestionContent({
  uid: 'aSV2',
  description: 'aSV',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBAmount: z.number().int().min(3).max(12),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBAmount = randomIntegerInclusive(animalAAmount + 1, 12, {
      // does not simplify to 1 : x
      constraint: x => x % animalAAmount !== 0
    });

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBAmount, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBAmount, animalAFirst },
      translate,
      displayMode
    } = props;

    const [firstAnimalRatio, secondAnimalRatio] = animalAFirst
      ? simplify(animalAAmount, animalBAmount)
      : simplify(animalBAmount, animalAAmount);

    const widthProportionA = animalA === 'Cow' ? 1 : animalA === 'Horse' ? 0.9 : 0.7;
    const widthProportionB = animalB === 'Cow' ? 1 : animalB === 'Horse' ? 0.9 : 0.7;

    const widthProportionAPDF = animalA === 'Horse' ? 1 : animalA === 'Cow' ? 0.9 : 0.5;
    const widthProportionBPDF = animalB === 'Horse' ? 1 : animalB === 'Cow' ? 0.9 : 0.5;

    const content = (dimens: Dimens) =>
      shuffle(
        [
          ...countRange(animalAAmount).map(i => (
            <View
              key={`A_${i}`}
              style={{
                height: dimens.height / 3,
                justifyContent: 'flex-end'
              }}
            >
              <AssetSvg
                name={animalA}
                width={
                  (dimens.width / 6) *
                  (displayMode === 'digital' ? widthProportionA : widthProportionAPDF)
                }
                height={dimens.height / 3}
              />
            </View>
          )),
          ...countRange(animalBAmount).map(i => (
            <View key={`B_${i}`} style={{ height: dimens.height / 3, justifyContent: 'flex-end' }}>
              <AssetSvg
                name={animalB}
                width={
                  (dimens.width / 6) *
                  (displayMode === 'digital' ? widthProportionB : widthProportionBPDF)
                }
                height={dimens.height / 3}
              />
            </View>
          ))
        ],
        {
          random: seededRandom(props.question)
        }
      );

    const itemA = lowercase(
      animalA === 'Cow'
        ? translate.animals.Cow(2)
        : animalA === 'Horse'
        ? translate.animals.Horse(2)
        : translate.animals.sheep()
    );

    const itemB = lowercase(
      animalB === 'Cow'
        ? translate.animals.Cow(2)
        : animalB === 'Horse'
        ? translate.animals.Horse(2)
        : translate.animals.sheep()
    );

    const [firstAnimal, secondAnimal] = animalAFirst ? [itemA, itemB] : [itemB, itemA];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }
            ]}
          >
            {content(dimens)}
          </View>
        )}
        sentence={translate.answerSentences.forEveryAnsXThereAreAnsY(firstAnimal, secondAnimal)}
        testCorrect={userAnswer =>
          arraysHaveSameContents(simplify(Number(userAnswer[0]), Number(userAnswer[1])), [
            firstAnimalRatio,
            secondAnimalRatio
          ])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [firstAnimalRatio.toLocaleString(), secondAnimalRatio.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
        pdfDirection="column"
        inputMaxCharacters={2}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aSW',
  description: 'aSW',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBAmount: z.number().int().min(3).max(12),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBAmount = randomIntegerInclusive(animalAAmount + 1, 12);

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBAmount, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBAmount, animalAFirst },
      translate
    } = props;

    const animalsArray = shuffle(
      [...filledArray(animalA, animalAAmount), ...filledArray(animalB, animalBAmount)],
      {
        random: seededRandom(props.question)
      }
    );

    const animalAString = translate.animals[animalA](animalAAmount);

    const animalBString = translate.animals[animalB](animalBAmount);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }
            ]}
          >
            {animalsArray.map((animal, index) => (
              <AssetSvg
                key={index}
                name={animal}
                width={dimens.width / 6}
                height={dimens.height / Math.ceil(animalsArray.length / 6)}
              />
            ))}
          </View>
        )}
        sentence={
          animalAFirst
            ? translate.answerSentences.forEveryAnsXThereAreAnsY(animalAString, animalBString)
            : translate.answerSentences.forEveryAnsXThereAreAnsY(animalBString, animalAString)
        }
        testCorrect={userAnswer =>
          compareRatios(
            [userAnswer[0], userAnswer[1]],
            animalAFirst ? [animalAAmount, animalBAmount] : [animalBAmount, animalAAmount]
          )
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: animalAFirst
            ? [animalAAmount.toLocaleString(), animalBAmount.toLocaleString()]
            : [animalBAmount.toLocaleString(), animalAAmount.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aSW2',
  description: 'aSW',
  keywords: ['Ratio'],
  schema: z
    .object({
      color1: LabelledCubeColorSchema,
      amount1: z.number().int().min(2).max(5),
      color2: LabelledCubeColorSchema,
      amount2: z.number().int().min(3).max(12),
      color1First: z.boolean()
    })
    .refine(val => val.color1 !== val.color2, 'color1 and color2 must be different'),
  simpleGenerator: () => {
    const [color1, color2] = getRandomSubArrayFromArray(labelledCubeColors, 2);

    const amount1 = randomIntegerInclusive(2, 5);

    const amount2 = randomIntegerInclusive(amount1 + 1, 12, {
      // does not simplify to 1 : x
      constraint: x => x % amount1 !== 0
    });

    const color1First = getRandomBoolean();

    return { color1, amount1, color2, amount2, color1First };
  },
  Component: props => {
    const {
      question: { color1, amount1, color2, amount2, color1First },
      translate
    } = props;

    const [firstRatio, secondRatio] = color1First
      ? simplify(amount1, amount2)
      : simplify(amount2, amount1);

    const content = (dimens: Dimens) =>
      shuffle(
        [
          ...countRange(amount1).map(i => (
            <View key={`A_${i}`} style={{ width: dimens.width / 6 }}>
              <AssetSvg
                name={labelledCubeSvg(color1)}
                width={dimens.width / 8}
                height={dimens.height / Math.ceil((amount1 + amount2) / 6)}
              />
            </View>
          )),
          ...countRange(amount2).map(i => (
            <View key={`B_${i}`} style={{ width: dimens.width / 6 }}>
              <AssetSvg
                name={labelledCubeSvg(color2)}
                width={dimens.width / 8}
                height={dimens.height / Math.ceil((amount1 + amount2) / 6)}
              />
            </View>
          ))
        ],
        {
          random: seededRandom(props.question)
        }
      );

    const translatedColor1 = translate.colors[color1]();
    const translatedColor2 = translate.colors[color2]();

    const [firstColor, secondColor] = color1First
      ? [translatedColor1, translatedColor2]
      : [translatedColor2, translatedColor1];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentence()}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }
            ]}
          >
            {content(dimens)}
          </View>
        )}
        sentence={translate.answerSentences.forEveryAnsColor1CubesThereAreAnsColor1Cubes(
          firstColor,
          secondColor
        )}
        testCorrect={userAnswer =>
          arraysHaveSameContents(simplify(Number(userAnswer[0]), Number(userAnswer[1])), [
            firstRatio,
            secondRatio
          ])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [firstRatio.toLocaleString(), secondRatio.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentRatios()
        }}
        pdfDirection="column"
        inputMaxCharacters={2}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aSX',
  description: 'aSX',
  keywords: ['Ratio'],
  schema: z
    .object({
      animalA: z.enum(['Cow', 'Horse', 'Sheep']),
      animalAAmount: z.number().int().min(2).max(5),
      animalB: z.enum(['Cow', 'Horse', 'Sheep']),
      animalBMultiplier: z.number().int().min(3).max(5),
      animalAFirst: z.boolean()
    })
    .refine(val => val.animalA !== val.animalB, 'animalA and animalB must be different'),
  simpleGenerator: () => {
    const [animalA, animalB] = getRandomSubArrayFromArray(['Cow', 'Horse', 'Sheep'] as const, 2);

    const animalAAmount = randomIntegerInclusive(2, 5);

    const animalBMultiplier = randomIntegerInclusive(3, 5, {
      constraint: x => x !== animalAAmount
    });

    const animalAFirst = getRandomBoolean();

    return { animalA, animalAAmount, animalB, animalBMultiplier, animalAFirst };
  },
  Component: props => {
    const {
      question: { animalA, animalAAmount, animalB, animalBMultiplier, animalAFirst },
      translate
    } = props;

    const animalBAmount = animalAAmount * animalBMultiplier;

    const animalsArray = shuffle(
      [...filledArray(animalA, animalAAmount), ...filledArray(animalB, animalBAmount)],
      {
        random: seededRandom(props.question)
      }
    );

    const animalAString = translate.animals[animalA](animalAAmount);

    const animalBString = translate.animals[animalB](animalBAmount);

    const items = shuffle(
      [
        animalAAmount.toLocaleString(),
        animalBAmount.toLocaleString(),
        animalBMultiplier.toLocaleString()
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={items}
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }
            ]}
          >
            {animalsArray.map((animal, index) => (
              <AssetSvg
                key={index}
                name={animal}
                width={dimens.width / 6}
                height={dimens.height / Math.ceil(animalsArray.length / 6)}
              />
            ))}
          </View>
        )}
        sentence={
          animalAFirst
            ? translate.answerSentences.forEveryAnsXThereAreAnsY(animalAString, animalBString)
            : translate.answerSentences.forEveryAnsXThereAreAnsY(animalBString, animalAString)
        }
        testCorrect={
          animalAFirst
            ? [animalAAmount.toLocaleString(), animalBAmount.toLocaleString()]
            : [animalBAmount.toLocaleString(), animalAAmount.toLocaleString()]
        }
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6v2 = newQuestionContent({
  uid: 'aSX2',
  description: 'aSX',
  keywords: ['Ratio'],
  schema: z
    .object({
      color1: LabelledCubeColorSchema,
      amount1: z.number().int().min(2).max(6),
      color2: LabelledCubeColorSchema,
      amount2: z.number().int().min(3).max(6),
      sentenceId: numberEnum([0, 1]),
      multiplier: z.number().int().min(2).max(3),
      amount3: z.number().int().min(4).max(18)
    })
    .refine(val => val.color1 !== val.color2, 'color1 and color2 must be different'),
  simpleGenerator: () => {
    const [color1, color2] = getRandomSubArrayFromArray(labelledCubeColors, 2);

    const amount1 = randomIntegerInclusive(2, 5);

    const amount2 = randomIntegerInclusive(amount1 + 1, 6, {
      constraint: x => x !== amount1
    });

    const sentenceId = getRandomFromArray([0, 1] as const);

    const multiplier = randomIntegerInclusive(2, 3);
    const amount3 = sentenceId ? multiplier * amount1 : multiplier * amount2;

    return { color1, amount1, color2, amount2, sentenceId, multiplier, amount3 };
  },
  Component: props => {
    const {
      question: { color1, amount1, color2, amount2, sentenceId, multiplier, amount3 },
      translate,
      displayMode
    } = props;

    const answer = sentenceId ? multiplier * amount2 : multiplier * amount1;

    const translatedColor1 = sentenceId ? translate.colors[color1]() : translate.colors[color2]();
    const translatedColor2 = sentenceId ? translate.colors[color2]() : translate.colors[color1]();

    return (
      <QF1ContentAndSentence
        title={translate.instructions.forEveryXColourCubesThereAreYColourCubes(
          sentenceId ? amount1 : amount2,
          translatedColor1,
          sentenceId ? amount2 : amount1,
          translatedColor2
        )}
        Content={({ dimens }) => (
          <View style={[dimens]}>
            <View
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 1,
                justifyContent: 'space-around'
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                {countRange(sentenceId ? amount1 : amount2).map(i => (
                  <View key={`A_${i}`} style={{ width: dimens.width / 6 }}>
                    <AssetSvg
                      name={labelledCubeSvg(sentenceId ? color1 : color2)}
                      width={dimens.width / 8}
                    />
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: 'row' }}>
                {countRange(sentenceId ? amount2 : amount1).map(i => (
                  <View key={`B_${i}`} style={{ width: dimens.width / 6 }}>
                    <AssetSvg
                      name={labelledCubeSvg(sentenceId ? color2 : color1)}
                      width={dimens.width / 8}
                    />
                  </View>
                ))}
              </View>
            </View>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.ifThereAreXColourCubes(amount3, translatedColor1)}
            </Text>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.answerSentences.howManyYColourCubesAreThere(translatedColor2)}
            </Text>
          </View>
        )}
        sentence={'<ans />'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        inputMaxCharacters={2}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UseRatioLanguage',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4v2, Question5v2, Question6v2],
  archivedQuestionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
