import { newSmallStepContent } from '../../../SmallStep';
import { View } from 'react-native';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import { newQuestionContent } from '../../../Question';
import { countRange } from '../../../../utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import { AssetSvg } from '../../../../assets/svg';
import { colors } from '../../../../theme/colors';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkw',
  description: 'bkw',
  keywords: ['Whole', 'Part', 'Bar model', 'Third', 'Quarter', 'Half'],
  schema: z.object({
    parts: z.number().int().min(1).max(6),
    fraction: z.enum(['half', 'third', 'quarter'])
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(1, 6);
    const fraction = getRandomFromArray(['half', 'third', 'quarter'] as const);

    return { parts, fraction };
  },
  Component: props => {
    const {
      question: { parts, fraction },
      translate,
      displayMode
    } = props;

    const fractionAmount = fraction === 'half' ? 2 : fraction === 'third' ? 3 : 4;

    const total = fractionAmount * parts;

    const partWidth = displayMode === 'digital' ? 65 : 100;
    const totalWidth = partWidth * Math.min(parts, 3) * fractionAmount;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.workOutTheMissingNumber()}
        sentence={translate.ks1AnswerSentences.fracOfAnsIsX(
          `<frac n='1' d='${fractionAmount}'/>`,
          parts
        )}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={[total.toString()]}
        pdfDirection="column"
        Content={
          <View style={{ width: totalWidth }}>
            <BarModelCurlyBrace topOrBottomBrace="top" braceText={'?'} />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              {countRange(fractionAmount).map((_amount, idx) => (
                <View
                  key={idx}
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    borderWidth: 2,
                    borderColor: colors.prussianBlue,
                    borderRightWidth: idx === fractionAmount - 1 ? 2 : 0,
                    padding: 8,
                    gap: 8,
                    justifyContent: parts === 1 ? 'center' : 'space-evenly',
                    width: totalWidth / fractionAmount,
                    maxWidth: partWidth * 3
                  }}
                >
                  {countRange(parts).map((_part, idx) => {
                    return (
                      <AssetSvg
                        key={idx}
                        name={'CounterRed'}
                        width={displayMode === 'digital' ? 50 : 80}
                      />
                    );
                  })}
                </View>
              ))}
            </View>
          </View>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bkx',
  description: 'bkx',
  keywords: ['Whole', 'Part', 'Bar model', 'Third', 'Quarter', 'Half'],
  schema: z.object({
    parts: z.number().int().min(1).max(6),
    fraction: z.enum(['half', 'third', 'quarter'])
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(1, 6);
    const fraction = getRandomFromArray(['half', 'third', 'quarter'] as const);

    return { parts, fraction };
  },
  Component: props => {
    const {
      question: { parts, fraction },
      translate,
      displayMode
    } = props;

    const fractionAmount = fraction === 'half' ? 2 : fraction === 'third' ? 3 : 4;

    const total = fractionAmount * parts;

    const partWidth = displayMode === 'digital' ? 65 : 100;
    const totalWidth = partWidth * Math.min(parts, 3) * fractionAmount;

    return (
      <QF1ContentAndSentence
        sentence={translate.ks1AnswerSentences.fracOfAnsIsX(
          `<frac n='1' d='${fractionAmount}'/>`,
          parts
        )}
        title={`${translate.ks1Instructions.workOutTheMissingNumber()}<br/>${translate.ks1Instructions.useTheBarModelToHelp()}`}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={[total.toString()]}
        pdfDirection="column"
        Content={
          <View style={{ width: totalWidth }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              {countRange(fractionAmount).map((_amount, idx) => (
                <View
                  key={idx}
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    borderWidth: 2,
                    borderColor: colors.prussianBlue,
                    borderRightWidth: idx === fractionAmount - 1 ? 2 : 0,
                    padding: 8,
                    gap: 8,
                    justifyContent: parts === 1 ? 'center' : 'space-evenly',
                    width: totalWidth / fractionAmount,
                    maxWidth: partWidth * 3
                  }}
                >
                  {idx === 0 &&
                    countRange(parts).map((_part, idx) => {
                      return (
                        <AssetSvg
                          key={idx}
                          name={'CounterRed'}
                          width={displayMode === 'digital' ? 50 : 80}
                        />
                      );
                    })}
                </View>
              ))}
            </View>
          </View>
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bky',
  description: 'bky',
  keywords: ['Whole', 'Part', 'Bar model', 'Third', 'Quarter', 'Half'],
  schema: z.object({
    parts: z.number().int().min(1).max(10),
    fraction: z.enum(['half', 'third', 'quarter'])
  }),
  simpleGenerator: () => {
    const fraction = getRandomFromArray(['half', 'third', 'quarter'] as const);
    const parts = ['third', 'quarter'].includes(fraction)
      ? getRandomBoolean()
        ? randomIntegerInclusive(1, 5)
        : 10
      : randomIntegerInclusive(1, 10);

    return { parts, fraction };
  },
  Component: props => {
    const {
      question: { parts, fraction },
      translate,
      displayMode
    } = props;

    const fractionAmount = fraction === 'half' ? 2 : fraction === 'third' ? 3 : 4;

    const total = fractionAmount * parts;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.workOutTheMissingNumber()}
        sentenceStyle={{ alignSelf: 'center' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        mainPanelContainerStyle={{ justifyContent: 'center' }}
        testCorrect={[total.toString()]}
        sentence={translate.ks1AnswerSentences.fracOfAnsIsX(
          `<frac n='1' d='${fractionAmount}'/>`,
          parts
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindTheWhole',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
