import { type StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import { CompleteTheSentenceWithState } from '../../molecules/CompleteTheSentence';
import { type ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../../utils/react';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput, { type ExtraSymbols } from '../../molecules/UserInput';
import { MeasureView } from '../../atoms/MeasureView';
import { MINIMUM_QUESTION_HEIGHT, type Dimens } from '../../../theme/scaling';
import { useContext, useMemo } from 'react';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { isEqual } from '../../../utils/matchers';
import { DisplayMode } from '../../../contexts/displayMode';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { parseSymbolsToString } from '../../../utils/parse';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /** Sentence to complete. This uses <ans/> to indicate answer boxes. See {@link CompleteTheSentence}. */
  sentence: string;
  mainPanelStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<TextStyle>;
  sentenceStyle?: StyleProp<TextStyle>;
  pdfSentenceStyle?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
  pdfTextStyle?: StyleProp<TextStyle>;
  pdfContentStyle?: StyleProp<ViewStyle>;
  /** Content to fill the area under the title. */
  Content: ElementOrRenderFunction<{
    dimens: Dimens;
  }>;
  /** Can provide an array of correct answers, or a function for finer control. */
  testCorrect: string[] | ((userAnswer: string[]) => boolean);
  /**
   * The width of each text input in characters. If left undefined and testCorrect is given as strings, it will
   * default to the longest correct answer.
   */
  inputMaxCharacters?: number;
  /**
   * Direction for this question's PDF content to be displayed.
   * Optional prop, defaults to row.
   */
  pdfDirection?: 'row' | 'column';
  /**
   * Decides whether or not to hide the sentence from the PDF.
   * Optional prop, defaults to false.
   */
  pdfHideSentence?: boolean;
  extraSymbol?: ExtraSymbols;
  /** Default: endWide. */
  actionPanelVariant?: 'endWide' | 'bottomTall';
  /** Props for extra content modal */
  promptButton?: string;
  modalTitle?: string;
  modalContent?: JSX.Element;
  fractionContainerStyle?: StyleProp<ViewStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: string[]; answerText?: string };
};

/**
 * Layout containing a title, numpad, one-line sentence, and content only.
 */
export default function QF1ContentAndSentence({
  title,
  pdfTitle,
  sentence,
  Content,
  mainPanelStyle,
  inputStyle,
  sentenceStyle,
  pdfSentenceStyle,
  textStyle,
  pdfTextStyle,
  pdfContentStyle,
  testCorrect: testCorrectProp,
  inputMaxCharacters: inputMaxCharactersProp,
  pdfDirection = 'row',
  pdfHideSentence = false,
  extraSymbol,
  /** Default: endWide. */
  actionPanelVariant = 'endWide',
  promptButton,
  modalTitle,
  modalContent,
  fractionContainerStyle,
  fractionTextStyle,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(displayMode, pdfDirection, questionHeight);

  // Handle testCorrect
  const testCorrect = useMemo(
    () => (typeof testCorrectProp === 'function' ? testCorrectProp : isEqual(testCorrectProp)),
    [testCorrectProp]
  );

  // Try to make the input boxes a suitable width.
  const inputMaxCharacters = (() => {
    if (inputMaxCharactersProp !== undefined) {
      return inputMaxCharactersProp;
    } else if (Array.isArray(testCorrectProp)) {
      return Math.max(...testCorrectProp.map(it => it.length));
    } else {
      throw new Error(
        'You must specify either the inputMaxCharacters prop or provide testCorrect as an array'
      );
    }
  })();

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp.map(ans => {
            // Temporary variable to convert simple string to localized string
            const temp = Number(parseSymbolsToString(ans));
            return temp.toLocaleString();
          });
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <View style={styles.mainPanel}>
              <MeasureView style={pdfContentStyle}>
                {dimens => resolveElementOrRenderFunction(Content, { dimens })}
              </MeasureView>
              <CompleteTheSentenceWithState
                id="sentence"
                sentence={sentence}
                inputMaxCharacters={inputMaxCharacters}
                inputStyle={inputStyle}
                style={[
                  styles.sentence,
                  styles.pdfSentence,
                  pdfHideSentence && { display: 'none' },
                  pdfSentenceStyle
                ]}
                textStyle={[styles.pdfText, pdfTextStyle]}
                fractionContainerStyle={fractionContainerStyle}
                fractionTextStyle={fractionTextStyle}
                defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
              />
            </View>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
        modalContent={modalContent}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant={actionPanelVariant}
      actionPanelContents={
        <UserInput
          inputType="numpad"
          extraSymbol={extraSymbol}
          variant={actionPanelVariant === 'endWide' ? 'tall' : 'wide'}
        />
      }
      mainPanelContents={
        <View style={[styles.mainPanel, mainPanelStyle]}>
          <MeasureView>{dimens => resolveElementOrRenderFunction(Content, { dimens })}</MeasureView>
          <CompleteTheSentenceWithState
            id="sentence"
            testCorrect={testCorrect}
            sentence={sentence}
            autoFocus
            inputMaxCharacters={inputMaxCharacters}
            inputStyle={inputStyle}
            style={[styles.sentence, sentenceStyle]}
            textStyle={textStyle}
            fractionContainerStyle={fractionContainerStyle}
            fractionTextStyle={fractionTextStyle}
          />
        </View>
      }
      promptButton={promptButton}
      modalTitle={modalTitle}
      modalContent={modalContent}
      {...props}
    />
  );
}

function useStyles(
  displayMode: 'digital' | 'pdf' | 'markscheme',
  pdfDirection: 'row' | 'column',
  questionHeight: number
) {
  return useMemo(
    () =>
      StyleSheet.create({
        mainPanel: {
          flex: 1,
          justifyContent: 'space-evenly',
          flexDirection: displayMode !== 'digital' ? pdfDirection : 'column'
        },
        sentence: {
          marginTop: 24
        },
        pdfContainer: {
          flexDirection: 'row'
        },
        pdfSentence: {
          justifyContent: pdfDirection === 'row' ? 'flex-end' : 'flex-start',
          alignContent: 'center',
          paddingLeft: 16,
          flex: questionHeight <= MINIMUM_QUESTION_HEIGHT ? 1 : undefined,
          marginTop: questionHeight > MINIMUM_QUESTION_HEIGHT ? 64 : 0
        },
        pdfText: {
          fontSize: 50,
          lineHeight: 75
        }
      }),
    [displayMode, pdfDirection, questionHeight]
  );
}
