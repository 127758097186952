import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import { multiplesNumberTrackArray } from '../../../../utils/multiples';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF31NumberGridInteractive from '../../../../components/question/questionFormats/QF31NumberGridInteractive';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { MULT } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'alL',
  description: 'alL',
  keywords: ['Times-table', '7', '100 square'],
  schema: z.object({
    startNumber: numberEnum([1, 11, 21, 31, 41])
  }),
  simpleGenerator: () => {
    const startNumber = getRandomFromArray([1, 11, 21, 31, 41] as const);

    return { startNumber };
  },
  Component: props => {
    const {
      question: { startNumber },
      translate
    } = props;

    const correctNumbers = range(startNumber, startNumber + 49).filter(number => number % 7 === 0);

    return (
      <QF31NumberGridInteractive
        startNumber={startNumber}
        finishNumber={startNumber + 49}
        title={translate.instructions.selectAllMultiplesOfNum(7)}
        pdfTitle={translate.instructions.shadeMultiplesOfX(7)}
        testCorrect={correctNumbers}
        initialState={[correctNumbers[0]]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'alM',
  description: 'alM',
  keywords: ['Times-table', '7', 'Track'],
  schema: z.object({
    interval: numberEnum([-7, 7]),
    startingNumber: z.number().int().min(7).max(84).multipleOf(7),
    tileToShow: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray([-7, 7] as const);
    const startingNumber =
      interval === 7 ? randomIntegerInclusiveStep(7, 21, 7) : randomIntegerInclusiveStep(70, 84, 7);
    const tileToShow = randomIntegerInclusive(2, 9);

    return { interval, startingNumber, tileToShow };
  },
  Component: ({ question: { interval, startingNumber, tileToShow }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(
      startingNumber,
      interval,
      8,
      tileToShow
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'alN',
  description: 'alN',
  keywords: ['Times-table', '7', 'Multiply'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 7, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 7, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ left: 7, right: numberC1, sign: 'multiply', answer: 'result' });

    const eqD = getBinOpEquation({ left: 7, right: numberD1, sign: 'multiply', answer: 'right' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'alO',
  description: 'alO',
  keywords: ['Times-table', '7', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ right: 7, result: numberA1, sign: 'divide', answer: 'result' });

    const eqB = getBinOpEquation({ right: 7, result: numberB1, sign: 'divide', answer: 'left' });

    const eqC = getBinOpEquation({ right: 7, result: numberC1, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ right: 7, result: numberD1, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'alP',
  description: 'alP',
  keywords: ['Multiple', '7'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(84)
      .array()
      .length(6)
      .refine(numbers => numbers.filter(x => x % 7 === 0).length >= 2, 'At least 2 multiples of 7')
  }),
  simpleGenerator: () => {
    const numMultiples = randomIntegerInclusive(2, 5);

    const multiples = randomUniqueIntegersInclusive(1, 84, numMultiples, {
      constraint: x => x % 7 === 0
    });

    const notMultiples = randomUniqueIntegersInclusive(1, 84, 6 - numMultiples, {
      constraint: x => x % 7 !== 0
    });

    const numbers = [...multiples, ...notMultiples];

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const shuffledNumbers = shuffle(numbers, { random: seededRandom(props.question) });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMultiplesOfX(7)}
        pdfTitle={translate.instructions.tickTheMultipleOfX(7)}
        testCorrect={numbers.filter(it => it % 7 === 0)}
        multiSelect
        items={() =>
          shuffledNumbers.map(number => ({
            value: number,
            component: number.toLocaleString()
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'alQ',
  description: 'alQ',
  keywords: ['7', 'Times-table', 'Division facts'],
  schema: z.object({
    multiplicandA: z.number().int().min(2).max(12),
    multiplicandAGetsLarger: z.boolean()
  }),
  simpleGenerator: () => {
    const multiplicandA = randomIntegerInclusive(2, 12);

    const multiplicandAGetsLarger = getRandomBoolean();

    return { multiplicandA, multiplicandAGetsLarger };
  },
  Component: props => {
    const {
      question: { multiplicandA, multiplicandAGetsLarger },
      translate
    } = props;

    const multiplierA = 7;

    const multiplicandB = multiplicandAGetsLarger ? multiplicandA * 10 : multiplicandA;

    const multiplierB = multiplicandAGetsLarger ? multiplierA : multiplierA * 10;

    const multiplicandC = multiplicandAGetsLarger ? multiplicandA * 100 : multiplicandA;

    const multiplierC = multiplicandAGetsLarger ? multiplierA : multiplierA * 100;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [(multiplicandA * multiplierA).toString()],
          [(multiplicandB * multiplierB).toString()],
          [(multiplicandC * multiplierC).toString()]
        ]}
        sentences={[
          `${multiplicandA.toLocaleString()} ${MULT} ${multiplierA.toLocaleString()} = <ans/>`,
          `${multiplicandB.toLocaleString()} ${MULT} ${multiplierB.toLocaleString()} = <ans/>`,
          `${multiplicandC.toLocaleString()} ${MULT} ${multiplierC.toLocaleString()} = <ans/>`
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Num7TimesTableAndDivisionFacts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
