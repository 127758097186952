import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  logUniformSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation } from '../../../../utils/math';
import { MULT } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import { useMemo } from 'react';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'am1',
  description: 'am1',
  keywords: ['Multiply', '10'],
  schema: z.object({
    number: z.number().int().min(2).max(999)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 999, {
      sample: logUniformSample
    });

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToHelpCompleteCalculation()}
        testCorrect={[(number * 10).toString()]}
        sentence={`${number.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[3, 2, 1, 0]}
            dimens={{ height: dimens.height / 2, width: dimens.width }}
            counterVariant={'number'}
          />
        )}
        questionHeight={800}
        pdfDirection="column"
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'am2',
  description: 'am2',
  keywords: ['Multiply', '100', '1,000'],
  schema: z.object({
    number: z.number().int().min(2).max(999)
  }),
  questionHeight: 975,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 999, {
      sample: logUniformSample
    });

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.usePlaceValueChartToHelpCompleteCalculations()}
        testCorrect={[[(number * 100).toString()], [(number * 1000).toString()]]}
        sentences={[
          `${number.toLocaleString()} ${MULT} ${(100).toLocaleString()} = <ans/>`,
          `${number.toLocaleString()} ${MULT} ${(1000).toLocaleString()} = <ans/>`
        ]}
        questionHeight={975}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[5, 4, 3, 2, 1, 0]}
            dimens={dimens}
            counterVariant={'number'}
            headerVariant={'shortName'}
          />
        )}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'am3',
  description: 'am3',
  keywords: ['Multiply', '10', '100', '1,000'],
  schema: z.object({
    number: z.number().int().min(2).max(999),
    answerIndex: z.number().min(0).max(1)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 999, {
      sample: logUniformSample
    });
    const answerIndex = randomIntegerInclusive(0, 1);

    return { number, answerIndex };
  },

  Component: props => {
    const {
      question: { number, answerIndex },
      translate
    } = props;

    const answer2 = [(number * 100).toString(), (number * 1000).toString()][answerIndex];
    const multiplier2 = [100, 1000][answerIndex];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.usePlaceValueChartToHelpYouCompleteCalculations()}
        testCorrect={[[(number * 10).toString()], [answer2]]}
        sentences={[
          `${number.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`,
          `${number.toLocaleString()} ${MULT} ${multiplier2.toLocaleString()} = <ans/>`
        ]}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[5, 4, 3, 2, 1, 0]}
            dimens={dimens}
            counterVariant={'number'}
            headerVariant={'shortName'}
          />
        )}
        pdfDirection="column"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'am4',
  description: 'am4',
  keywords: ['Multiply', '10', '100', '1,000'],
  schema: z.object({
    number1: z
      .number()
      .int()
      .min(41)
      .max(999)
      .refine(val => val % 10 !== 0, 'number1 must not be a multiple of 10')
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(41, 999, {
      constraint: x => x % 10 !== 0
    });

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMultSentences()}
        testCorrect={[
          [(number1 * 10).toString()],
          [(number1 * 100).toString()],
          [(number1 * 1000).toString()]
        ]}
        sentences={[
          `${number1.toLocaleString()} ${MULT} ${(10).toLocaleString()} = <ans/>`,
          `${number1.toLocaleString()} ${MULT} ${(100).toLocaleString()} = <ans/>`,
          `${number1.toLocaleString()} ${MULT} ${(1000).toLocaleString()} = <ans/>`
        ]}
        questionHeight={800}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'am5',
  description: 'am5',
  keywords: ['Multiply', '10', '100', '1,000'],
  schema: z.object({
    number1A: z.number().int().min(1).max(999),
    number1B: z.number().int().min(1).max(999),
    number1C: z.number().int().min(10).max(990).multipleOf(10),
    number1D: z.number().int().min(100).max(900).multipleOf(100),
    number2s: numberEnum([10, 100, 1000]).array().length(4)
  }),
  simpleGenerator: () => {
    const number1A = randomIntegerInclusive(1, 999);
    const number1B = randomIntegerInclusive(1, 999);
    const number1C = randomIntegerInclusiveStep(10, 990, 10);
    const number1D = randomIntegerInclusiveStep(100, 900, 100);

    const number2s = [
      getRandomFromArray([10, 100, 1000] as const),
      getRandomFromArray([10, 100, 1000] as const),
      getRandomFromArray([10, 100, 1000] as const),
      getRandomFromArray([10, 100, 1000] as const)
    ];

    return { number1A, number1B, number1C, number1D, number2s };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, number1D, number2s },
      translate
    } = props;

    const [number2A, number2B, number2C, number2D] = number2s;

    const number3A = number1A * number2A;
    const number3B = number1B * number2B;
    const number3C = number1C * number2C;
    const number3D = number1D * number2D;

    const answerOptions = [10, 100, 1000] as const;

    const statements = useMemo(() => {
      const questionsAndAnswers = [
        [`${number1A.toLocaleString()} ${MULT} <ans/> = ${number3A.toLocaleString()}`, number2A],
        [`${number1B.toLocaleString()} ${MULT} <ans/> = ${number3B.toLocaleString()}`, number2B],
        [`${number1C.toLocaleString()} ${MULT} <ans/> = ${number3C.toLocaleString()}`, number2C],
        [`${number1D.toLocaleString()} ${MULT} <ans/> = ${number3D.toLocaleString()}`, number2D]
      ] as const;

      return shuffle(questionsAndAnswers, { random: seededRandom(props.question) });
    }, [
      number1A,
      number1B,
      number1C,
      number1D,
      number2A,
      number2B,
      number2C,
      number2D,
      number3A,
      number3B,
      number3C,
      number3D,
      props.question
    ]);

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardCanBeUsedMoreThanOnce()}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        items={answerOptions}
        sentences={statements.map(it => it[0])}
        testCorrect={statements.map(it => [it[1]])}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5v2 = newQuestionContent({
  uid: 'am52',
  description: 'am52',
  keywords: ['Multiply', '10', '100', '1,000'],
  schema: z
    .object({
      numberA1: z.number().int().min(1).max(999),
      numberA2: numberEnum([10, 100, 1000]),
      numberB1: z.number().int().min(1).max(999),
      numberB2: numberEnum([10, 100, 1000]),
      numberC1: z.number().int().min(1).max(999),
      numberC2: numberEnum([10, 100, 1000])
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1]),
      'numberA1, numberB1 and numberC1 must all be different.'
    ),
  simpleGenerator: () => {
    const number1Ranges = shuffle([
      [1, 999],
      [10, 990],
      [100, 900]
    ]);

    const numberA1 =
      number1Ranges[0][0] === 1
        ? randomIntegerInclusive(number1Ranges[0][0], number1Ranges[0][1])
        : randomIntegerInclusiveStep(number1Ranges[0][0], number1Ranges[0][1], number1Ranges[0][0]);

    const numberA2 = getRandomFromArray([10, 100, 1000] as const);

    const numberB1 =
      number1Ranges[1][0] === 1
        ? randomIntegerInclusive(number1Ranges[1][0], number1Ranges[1][1], {
            constraint: x => x !== numberA1
          })
        : randomIntegerInclusiveStep(
            number1Ranges[1][0],
            number1Ranges[1][1],
            number1Ranges[1][0],
            {
              constraint: x => x !== numberA1
            }
          );

    const numberB2 = getRandomFromArray([10, 100, 1000] as const);

    const numberC1 =
      number1Ranges[2][0] === 1
        ? randomIntegerInclusive(number1Ranges[2][0], number1Ranges[2][1], {
            constraint: x => x !== numberA1 && x !== numberB1
          })
        : randomIntegerInclusiveStep(
            number1Ranges[2][0],
            number1Ranges[2][1],
            number1Ranges[2][0],
            {
              constraint: x => x !== numberA1 && x !== numberB1
            }
          );

    const numberC2 = getRandomFromArray([10, 100, 1000] as const);

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2 },
      translate
    } = props;

    const numberA3 = numberA1 * numberA2;
    const numberB3 = numberB1 * numberB2;
    const numberC3 = numberC1 * numberC2;

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardCanBeUsedMoreThanOnce()}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        sentencesStyle={{ alignItems: 'flex-start', alignSelf: 'center' }}
        items={[(10).toLocaleString(), (100).toLocaleString(), (1000).toLocaleString()]}
        sentences={[
          `${numberA1.toLocaleString()} ${MULT} <ans/> = ${numberA3.toLocaleString()}`,
          `${numberB1.toLocaleString()} ${MULT} <ans/> = ${numberB3.toLocaleString()}`,
          `${numberC1.toLocaleString()} ${MULT} <ans/> = ${numberC3.toLocaleString()}`
        ]}
        testCorrect={[
          [numberA2.toLocaleString()],
          [numberB2.toLocaleString()],
          [numberC2.toLocaleString()]
        ]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'am6',
  description: 'am6',
  keywords: ['Multiply', '10', '100', '1,000'],
  schema: z
    .object({
      numberA1: z.number().int().min(1).max(999),
      numberA2: z.union([z.literal(10), z.literal(100), z.literal(1000)]),
      numberB1: z.number().int().min(1).max(999),
      numberB2: z.union([z.literal(10), z.literal(100), z.literal(1000)]),
      numberC1: z.number().int().min(10).max(990).multipleOf(10),
      numberC2: z.union([z.literal(10), z.literal(100), z.literal(1000)]),
      numberD1: z.number().int().min(100).max(900).multipleOf(100),
      numberD2: z.union([z.literal(10), z.literal(100), z.literal(1000)])
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(1, 999);

    const numberA2 = getRandomFromArray([10, 100, 1000] as const);

    const numberB1 = randomIntegerInclusive(1, 999, {
      constraint: x => x !== numberA1
    });

    const numberB2 = getRandomFromArray([10, 100, 1000] as const);

    const numberC1 = randomIntegerInclusiveStep(10, 990, 10, {
      constraint: x => x !== numberA1 && x !== numberB1
    });

    const numberC2 = getRandomFromArray([10, 100, 1000] as const);

    const numberD1 = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => x !== numberA1 && x !== numberB1 && x !== numberC1
    });

    const numberD2 = getRandomFromArray([10, 100, 1000] as const);
    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: numberA1,
      right: numberA2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      left: numberB1,
      right: numberB2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      left: numberC1,
      right: numberC2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqD = getBinOpEquation({
      left: numberD1,
      right: numberD2,
      sign: 'multiply',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={1000}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'am62',
  description: 'am62',
  keywords: ['Multiply', '10', '100', '1,000'],
  schema: z
    .object({
      numberA1: z.number().int().min(1).max(999),
      numberA2: numberEnum([10, 100, 1000]),
      numberB1: z.number().int().min(10).max(990).multipleOf(10),
      numberB2: numberEnum([10, 100, 1000]),
      numberC1: z.number().int().min(100).max(900).multipleOf(100),
      numberC2: numberEnum([10, 100, 1000])
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1]),
      'All numbers must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(1, 999);

    const numberA2 = getRandomFromArray([10, 100, 1000] as const);

    const numberB1 = randomIntegerInclusiveStep(10, 990, 10, {
      constraint: x => x !== numberA1
    });

    const numberB2 = getRandomFromArray([10, 100, 1000] as const);

    const numberC1 = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => x !== numberA1 && x !== numberB1
    });

    const numberC2 = getRandomFromArray([10, 100, 1000] as const);

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2 },
      translate,
      displayMode
    } = props;

    const [eqA, eqB, eqC] = [
      [numberA1, numberA2],
      [numberB1, numberB2],
      [numberC1, numberC2]
    ].map(([num1, num2]) => {
      return getBinOpEquation({
        left: num1,
        right: num2,
        sign: 'multiply',
        answer: 'result'
      });
    });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={1000}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyBy10100And1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
