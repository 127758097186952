import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { compareFloats } from '../../../../utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { createHundredSquareShape, rotateShape270 } from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { arrayHasNoDuplicates, countRange, range } from '../../../../utils/collections';
import { all, create, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import { decimalToFraction, fractionToDecimal } from '../../../../utils/fractions';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQy',
  description: 'aQy',
  keywords: ['Hundred square', 'Decimals', 'Fraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(10).max(90).step(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusiveStep(10, 90, 10);
    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const shape = rotateShape270(createHundredSquareShape(numberOfShadedSquares));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeEquivalentFractionsAndDecimalHundredSquare()}
        sentence={`<frac nAns='' d='${(10).toLocaleString()}' /> = <frac nAns='' d='${(100).toLocaleString()}' /> = <ans/>`}
        questionHeight={1000}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'column' }}
        inputMaxCharacters={4}
        fractionContainerStyle={{ height: 96 }}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], number(math.evaluate(`${numberOfShadedSquares} / 10`))) &&
          compareFloats(userAnswer[1], numberOfShadedSquares) &&
          compareFloats(userAnswer[2], number(math.evaluate(`${numberOfShadedSquares} / 100`)))
        }
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number(math.evaluate(`${numberOfShadedSquares} / 10`)).toLocaleString(),
            numberOfShadedSquares.toLocaleString(),
            number(math.evaluate(`${numberOfShadedSquares} / 100`)).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQz',
  description: 'aQz',
  keywords: ['Hundred square', 'Decimals', 'Fraction', 'Fifths', 'Hundredths'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(20).max(80).step(20)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusiveStep(20, 80, 20);
    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const shape = rotateShape270(createHundredSquareShape(numberOfShadedSquares));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeEquivalentFractionsAndDecimalHundredSquare()}
        sentence={`<frac nAns='' d='${(5).toLocaleString()}' /> = <frac nAns='' d='${(100).toLocaleString()}' /> = <ans/>`}
        questionHeight={1000}
        fractionContainerStyle={{ height: 96 }}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'column' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], number(math.evaluate(`${numberOfShadedSquares} / 20`))) &&
          compareFloats(userAnswer[1], numberOfShadedSquares) &&
          compareFloats(userAnswer[2], number(math.evaluate(`${numberOfShadedSquares} / 100`)))
        }
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <DisplayShapeOnGrid givenShape={shape} dimens={dimens} />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            number(math.evaluate(`${numberOfShadedSquares} / 20`)).toLocaleString(),
            numberOfShadedSquares.toLocaleString(),
            number(math.evaluate(`${numberOfShadedSquares} / 100`)).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aQA',
  description: 'aQA',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Equivalent'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(10),
    numeratorB: z.number().int().min(1).max(5),
    numeratorC: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(1, 10);
    const numeratorB = randomIntegerInclusive(1, 5);
    const numeratorC = randomIntegerInclusive(1, 4);

    return { numeratorA, numeratorB, numeratorC };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, numeratorC },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: `<frac n='${numeratorA.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorA} / 10`))
        },
        {
          sentence: `<frac n='${numeratorB.toLocaleString()}' d='${(5).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorB} / 5`))
        },
        {
          sentence: `<frac n='${numeratorC.toLocaleString()}' d='${(4).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorC} / 4`))
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        title={translate.instructions.workOutTheEquivalentDecimals()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sentences[0].answer) &&
          compareFloats(userAnswer[1][0], sentences[1].answer) &&
          compareFloats(userAnswer[2][0], sentences[2].answer)
        }
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aQA2',
  description: 'aQA',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Equivalent'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(10),
    numeratorB: z.number().int().min(1).max(5),
    numeratorC: z.number().int().min(1).max(4),
    randomQuestion: numberEnum([1, 2, 3])
  }),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(1, 10);
    const numeratorB = randomIntegerInclusive(1, 5);
    const numeratorC = randomIntegerInclusive(1, 4);
    const randomQuestion = getRandomFromArray([1, 2, 3] as const);

    return { numeratorA, numeratorB, numeratorC, randomQuestion };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, numeratorC, randomQuestion },
      translate
    } = props;

    const sentence =
      randomQuestion === 1
        ? `<frac n='${numeratorA.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`
        : randomQuestion === 2
        ? `<frac n='${numeratorB.toLocaleString()}' d='${(5).toLocaleString()}' /> = <ans/>`
        : `<frac n='${numeratorC.toLocaleString()}' d='${(4).toLocaleString()}' /> = <ans/>`;

    const answer =
      randomQuestion === 1
        ? number(math.evaluate(`${numeratorA} / 10`))
        : randomQuestion === 2
        ? number(math.evaluate(`${numeratorB} / 5`))
        : number(math.evaluate(`${numeratorC} / 4`));

    return (
      <QF2AnswerBoxOneSentence
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        title={translate.instructions.workOutTheEquivalentDecimal()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
        sentence={sentence}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQB',
  description: 'aQB',
  keywords: ['Fifths', 'Decimals', 'Number line'],
  schema: z.object({
    steps: numberEnum([1, 2]),
    missingFractions: z.boolean(),
    variablesShown: z.array(z.number().int().min(2).max(8)).length(3),
    missingVariables: z.array(z.number().int().min(2).max(8)).length(2)
  }),
  simpleGenerator: () => {
    const steps = getRandomFromArray([1, 2] as const);
    const missingFractions = getRandomBoolean();
    const variablesShown = randomUniqueIntegersInclusiveStep(2, 8, 2, 3);
    const missingVariables = getRandomSubArrayFromArray(variablesShown, 2);

    return { steps, missingFractions, variablesShown, missingVariables };
  },

  Component: props => {
    const {
      question: { steps, missingFractions, variablesShown, missingVariables },
      translate,
      displayMode
    } = props;

    const topTickValues = range(0, 10, steps).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;
      if (variablesShown.includes(i))
        return missingFractions && missingVariables.includes(i)
          ? '<ans/>'
          : `<frac n='${decimalToFraction(i / 10)[0]}' d='${decimalToFraction(i / 10)[1]}' />`;
      return ' ';
    });

    const bottomTickValues = range(0, 10, steps).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;
      if (variablesShown.includes(i)) {
        return !missingFractions && missingVariables.includes(i)
          ? '<ans/>'
          : `${(i / 10).toLocaleString()}`;
      }
      return ' ';
    });

    let draggables;

    const [num1, num2] = missingVariables;
    const num3 = [2, 4, 6, 8].find(x => !variablesShown.includes(x))!;

    const dec1 = num1 / 10;
    const dec2 = num2 / 10;
    const dec3 = num3 / 10;

    // 1step !missingFractions
    if (!missingFractions && steps === 1) {
      const wrongDec1 = dec1 + 0.1 === dec2 ? dec1 - 0.1 : dec1 + 0.1;
      const wrongDec2 = dec2 + 0.1 === dec1 ? dec2 - 0.1 : dec2 + 0.1;

      draggables = [
        dec1,
        dec2,
        wrongDec1,
        wrongDec2,
        dec1 / 10,
        dec2 / 10,
        wrongDec1 * 10,
        wrongDec2 + 0.01
      ].map(i => i.toLocaleString());
    } else if (!missingFractions && steps === 2) {
      const wrongDec1 = dec1 * 5 + 0.5;
      const wrongDec2 = dec2 * 5 + 0.5;
      const wrongDec3 = dec3 * 5 + 0.5;

      draggables = [
        ...new Set([
          dec1,
          dec2,
          dec3,
          wrongDec1,
          wrongDec2,
          wrongDec3,
          dec1 / 2,
          dec2 / 2,
          dec3 / 2
        ])
      ].map(i => i.toLocaleString());
    } else {
      const dec4 = dec1 * 5;
      const dec5 = dec2 * 5;
      const fracOptionsAsDec = [
        ...new Set([dec1, dec2, dec4, dec5, dec4 + 4, dec5 + 4, dec3, dec3 * 10])
      ];

      draggables = fracOptionsAsDec.map(dec => {
        const [num, denom] = decimalToFraction(dec);
        return {
          component: (
            <TextStructure
              key={dec}
              sentence={`<frac n='${num.toLocaleString()}' d='${denom.toLocaleString()}' />`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          ),
          value: `${dec}`
        };
      });
    }

    const answers = [...missingVariables].sort().map(x => `${x / 10}`);

    const items = shuffle([...draggables], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={answers}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4v2 = newQuestionContent({
  uid: 'aQB2',
  description: 'aQB',
  keywords: ['Fifths', 'Decimals', 'Number line'],
  schema: z.object({
    step: numberEnum([1, 2]),
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(1).max(9),
    number3: z.number().int().min(1).max(9),
    ansBoxIndexes: z.array(z.number().int().min(1).max(9)),
    draggablesIncorrect: z.array(z.number().int().min(1).max(9)),
    randomIncorrectFractionAnswers: z.array(z.array(z.number().int(), z.number().int())),
    randomIncorrectDecimalAnswers: z.array(z.number()),
    fractionOrDecimal: z.enum(['Fraction', 'Decimal'])
  }),
  simpleGenerator: () => {
    const step = getRandomFromArray([1, 2] as const); // Refers to 0.1 or 0.2 respectively
    const fractionOrDecimal = getRandomFromArray(['Fraction', 'Decimal'] as const);

    // Added rejection sample to prevent randomIncorrectDecimalAnswers from having duplicates
    const { number1, number2, number3, ansBoxIndexes, randomIncorrectDecimalAnswers } =
      rejectionSample(
        () => {
          // 3 numbers display on the numberline
          // But 2 of them are hidden i.e displayed as answer boxes
          const [number1, number2, number3] =
            step === 1
              ? randomUniqueIntegersInclusive(1, 9, 3)
              : randomUniqueIntegersInclusiveStep(2, 8, 2, 3);

          // Pick the index of the 2 answer boxes displayed on the numberline
          const ansBoxIndexes = getRandomSubArrayFromArray([number1, number2, number3], 2);

          // Incorrect decimals
          const incorrectDecimalAnswersA = randomUniqueIntegersInclusive(1, 9, 5, {
            constraint: x => x !== ansBoxIndexes[0] && x !== ansBoxIndexes[1]
          });

          const incorrectDecimalA = decimalToFraction(number2 / 10);
          const incorrectDecimalB = decimalToFraction(number3 / 10);
          const incorrectDecimalC = getRandomSubArrayFromArray([1, 2, 3, 4, 5, 6, 7, 8, 9], 1, {
            constraint: x => x !== ansBoxIndexes[0] && x !== ansBoxIndexes[1]
          });

          const randomIncorrectDecimalAnswers =
            step === 1
              ? [
                  ...ansBoxIndexes.map(num => num / 10),
                  ...incorrectDecimalAnswersA.map(num => num / 10)
                ]
              : [
                  ...ansBoxIndexes.map(num => num / 10),
                  number2 / 10 / 2,
                  number3 / 10 / 2,
                  incorrectDecimalA[0] / 10 + incorrectDecimalA[1] / 100,
                  incorrectDecimalB[0] / 10 + incorrectDecimalA[1] / 100,
                  incorrectDecimalC[0] / 10
                ];

          return { number1, number2, number3, ansBoxIndexes, randomIncorrectDecimalAnswers };
        },
        ({ randomIncorrectDecimalAnswers }) => arrayHasNoDuplicates(randomIncorrectDecimalAnswers)
      );

    // Find the index of the number displayed on the numberline i.e Not one of the 2 answer boxes
    const indexOfNumberDisplayed = [number1, number2, number3].find(
      num => !ansBoxIndexes.includes(num)
    );

    // There are 7 draggables in total for both 0.1 and 0.2 steps
    // Below creates the 7 draggables for both 0.1 and 0.2 steps
    // Not all the incorrect draggables use the same approach, so we have to create them step by step using different methods

    // Create the first 3 incorrect draggable options for both 0.1 and 0.2 steps
    // Ensure the draggables are not one of the correct answers i.e ansBoxIndexes
    // Also ensure the draggables are not not equivalent to the number displayed on the numberline i.e indexOfNumberDisplayed
    const draggablesIncorrect =
      step === 1
        ? getRandomSubArrayFromArray([1, 2, 3, 4, 5, 6, 7, 8, 9], 3, {
            constraint: x => !ansBoxIndexes.includes(x) && x !== indexOfNumberDisplayed
          })
        : [2, 4, 6, 8]
            .filter(num => !ansBoxIndexes.includes(num) && num !== indexOfNumberDisplayed)
            .map(num => num / 2);

    // Incorrect fractions
    const incorrectAnswersFractions = [
      [1, 3],
      [2, 3],
      [1, 4],
      [3, 4],
      [1, 6],
      [5, 6],
      ...(step === 1
        ? [
            [1, 5],
            [2, 5],
            [3, 5],
            [4, 5]
          ]
        : [
            [1, 10],
            [3, 10],
            [5, 10],
            [7, 10],
            [9, 10]
          ])
    ];

    const randomIncorrectFractionAnswers = getRandomSubArrayFromArray(
      incorrectAnswersFractions,
      step === 1 ? 2 : 4
    );

    return {
      step,
      number1,
      number2,
      number3,
      ansBoxIndexes,
      draggablesIncorrect,
      randomIncorrectFractionAnswers,
      randomIncorrectDecimalAnswers,
      fractionOrDecimal
    };
  },

  Component: props => {
    const {
      question: {
        step,
        number1,
        number2,
        number3,
        ansBoxIndexes,
        draggablesIncorrect,
        randomIncorrectFractionAnswers,
        randomIncorrectDecimalAnswers,
        fractionOrDecimal
      },
      translate,
      displayMode
    } = props;

    const [ansBoxIdx1, ansBoxIdx2] = ansBoxIndexes;

    const startAndEndValues = (i: number) => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === 10) return `${(1).toLocaleString()}`;

      return;
    };

    const topTickValuesFractions = range(0, 10, step).map(i => {
      const startEndValues = startAndEndValues(i);

      if (startEndValues) return startEndValues;

      if (i === ansBoxIdx1 || i === ansBoxIdx2) return '<ans />';

      if (step === 1) {
        return i === number1 || i === number2 || i === number3 ? `<frac n="${i}" d="10" />` : ' ';
      } else {
        return i === number1 || i === number2 || i === number3
          ? `<frac n='${decimalToFraction(i / 10)[0]}' d='${decimalToFraction(i / 10)[1]}' />`
          : ' ';
      }
    });

    const topTickValuesDecimals = range(0, 10, step).map(i => {
      const startEndValues = startAndEndValues(i);

      if (startEndValues) return startEndValues;

      if (i === ansBoxIdx1 || i === ansBoxIdx2) return '<ans />';

      return `${(i / 10).toLocaleString()}`;
    });

    const bottomTickValuesFractions = range(0, 10, step).map(i => {
      return `${(i / 10).toLocaleString()}`;
    });

    const bottomTickValuesDecimals = range(0, 10, step).map(i => {
      const startEndValues = startAndEndValues(i);

      if (startEndValues) return startEndValues;

      if (step === 1) {
        return `<frac n="${i}" d="10" />`;
      } else {
        return `<frac n='${decimalToFraction(i / 10)[0]}' d='${decimalToFraction(i / 10)[1]}' />`;
      }
    });

    const itemsAnsBoxIndexes = step === 1 ? ansBoxIndexes : ansBoxIndexes.map(idx => idx / 2);
    const itemsFractionsA = [...draggablesIncorrect, ...itemsAnsBoxIndexes].map(num => ({
      component: (
        <TextStructure
          sentence={step === 1 ? `<frac n='${num}' d='10' />` : `<frac n='${num}' d='5' />`}
          fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          fractionDividerStyle={{ marginVertical: 2 }}
          textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        />
      ),
      value: fractionToDecimal(num, step === 1 ? 10 : 5)
    }));

    const itemsFractionsB = randomIncorrectFractionAnswers.map(([numerator, denominator]) => ({
      component: (
        <TextStructure
          sentence={`<frac n='${numerator}' d='${denominator}' />`}
          fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          fractionDividerStyle={{ marginVertical: 2 }}
          textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        />
      ),
      value: fractionToDecimal(numerator, denominator)
    }));

    const itemsDecimals = randomIncorrectDecimalAnswers.map(num => {
      return {
        component: (
          <TextStructure
            sentence={`${num.toLocaleString()}`}
            textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
          />
        ),
        value: num
      };
    });

    const sortedAnswers = [
      fractionToDecimal(ansBoxIdx1, 10),
      fractionToDecimal(ansBoxIdx2, 10)
    ].sort((a, b) => a - b);

    const items =
      fractionOrDecimal === 'Fraction' ? [...itemsFractionsA, ...itemsFractionsB] : itemsDecimals;

    const shuffledItems = shuffle(items, {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={
          fractionOrDecimal === 'Fraction' ? bottomTickValuesFractions : bottomTickValuesDecimals
        }
        topTickValues={
          fractionOrDecimal === 'Fraction' ? topTickValuesFractions : topTickValuesDecimals
        }
        items={shuffledItems}
        testCorrect={sortedAnswers}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aQC',
  description: 'aQC',
  keywords: ['Hundredths', 'Fractions', 'Decimals', 'Mixed number'],
  schema: z.object({
    numberOfShadedSquares: z.number().int().min(110).max(490).step(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfShadedSquares = randomIntegerInclusiveStep(110, 490, 10, {
      constraint: x => x % 100 !== 0
    });
    return { numberOfShadedSquares };
  },
  Component: props => {
    const {
      question: { numberOfShadedSquares },
      translate
    } = props;

    const hundreds = Math.floor(number(math.evaluate(`${numberOfShadedSquares} / 100`)));
    const remaining = number(math.evaluate(`${numberOfShadedSquares} - (${hundreds} * 100)`));

    const remainingSquare = createHundredSquareShape(remaining);

    const denominator = remaining % 20 === 0 ? 5 : 10;
    const numerarator = denominator === 5 ? remaining / 20 : remaining / 10;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatFractionAndDecimalIsRepresent()}
        sentence={`<frac wAns='' nAns='' d='${denominator.toLocaleString()}' /> = <ans/>`}
        questionHeight={1000}
        sentenceStyle={{ alignSelf: 'center' }}
        mainPanelStyle={{ flexDirection: 'column' }}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], hundreds) &&
          compareFloats(userAnswer[1], numerarator) &&
          compareFloats(userAnswer[2], number(math.evaluate(`${numberOfShadedSquares} / 100`)))
        }
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row' }}>
            {countRange(hundreds).map(number => {
              return (
                <View key={number} style={{ marginRight: 12 }}>
                  <DisplayShapeOnGrid
                    givenShape={createHundredSquareShape(100)}
                    dimens={{ width: dimens.width / (hundreds + 1), height: dimens.height }}
                  />
                </View>
              );
            })}
            <DisplayShapeOnGrid
              givenShape={remainingSquare}
              dimens={{ width: dimens.width / (hundreds + 1), height: dimens.height }}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            hundreds.toLocaleString(),
            numerarator.toLocaleString(),
            (numberOfShadedSquares / 100).toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aQD',
  description: 'aQD',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Equivalent', 'Fractions', 'Improper'],
  schema: z.object({
    numeratorA: z.number().int().min(11).max(19),
    numeratorB: z.number().int().min(21).max(49),
    numeratorC: z.number().int().min(1).max(50)
  }),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(11, 19);
    const numeratorB = randomIntegerInclusive(21, 49);
    const numeratorC = randomIntegerInclusive(1, 50, { constraint: x => x % 2 !== 0 });

    return { numeratorA, numeratorB, numeratorC };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, numeratorC },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: `<frac n='${numeratorA.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorA} / 10`))
        },
        {
          sentence: `<frac n='${numeratorB.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorB} / 10`))
        },
        {
          sentence: `<frac n='${numeratorC.toLocaleString()}' d='${(2).toLocaleString()}' /> = <ans/>`,
          answer: number(math.evaluate(`${numeratorC} / 2`))
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        title={translate.instructions.convertImproperFractionsToDecimals()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], sentences[0].answer) &&
          compareFloats(userAnswer[1][0], sentences[1].answer) &&
          compareFloats(userAnswer[2][0], sentences[2].answer)
        }
        sentences={sentences.map(sentence => sentence.sentence)}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aQD2',
  description: 'aQD',
  keywords: ['Decimals', 'Tenths', 'Hundredths', 'Equivalent', 'Fractions', 'Improper'],
  schema: z.object({
    numeratorA: z.number().int().min(11).max(19),
    numeratorB: z.number().int().min(21).max(49),
    numeratorC: z.number().int().min(1).max(50),
    randomQuestion: numberEnum([1, 2, 3])
  }),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(11, 19);
    const numeratorB = randomIntegerInclusive(21, 49);
    const numeratorC = randomIntegerInclusive(1, 50, { constraint: x => x % 2 !== 0 });
    const randomQuestion = getRandomFromArray([1, 2, 3] as const);

    return { numeratorA, numeratorB, numeratorC, randomQuestion };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, numeratorC, randomQuestion },
      translate
    } = props;

    const sentence =
      randomQuestion === 1
        ? `<frac n='${numeratorA.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`
        : randomQuestion === 2
        ? `<frac n='${numeratorB.toLocaleString()}' d='${(10).toLocaleString()}' /> = <ans/>`
        : `<frac n='${numeratorC.toLocaleString()}' d='${(2).toLocaleString()}' /> = <ans/>`;

    const answer =
      randomQuestion === 1
        ? number(math.evaluate(`${numeratorA} / 10`))
        : randomQuestion === 2
        ? number(math.evaluate(`${numeratorB} / 10`))
        : number(math.evaluate(`${numeratorC} / 2`));

    return (
      <QF2AnswerBoxOneSentence
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        title={translate.instructions.convertTheImproperFractionToDecimal()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
        sentence={sentence}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsAndDecimals',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5, Question6v2],
  archivedQuestionTypes: [Question3, Question4, Question6]
});
export default SmallStep;
