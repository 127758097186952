import { z } from 'zod';
import { View } from 'react-native';

import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import { range } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import { ADD } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNK',
  description: 'aNK',
  keywords: ['Tenths', 'Place value chart', 'Decimals'],
  schema: z.object({
    ones: z.number().int().min(1).max(6),
    tenths: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 6);
    const tenths = randomIntegerInclusive(2, 6);

    return { ones, tenths };
  },
  Component: props => {
    const {
      question: { ones, tenths },
      translate
    } = props;
    const answer = ones + tenths / 10;

    return (
      <QF1ContentAndSentences
        sentences={[
          ones > 1
            ? translate.answerSentences.thereAreAnsWholesAndAnsY(translate.fractions.tenths(tenths))
            : translate.answerSentences.thereIsAnsWholeAndAnsX(translate.fractions.tenths(tenths)),
          translate.answerSentences.theNumberIsAns()
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={userAnswer =>
          userAnswer[0][0] === ones.toString() &&
          userAnswer[0][1] === tenths.toString() &&
          compareFloats(userAnswer[1][0], answer)
        }
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={3}
        Content={({ dimens }) => {
          return (
            <PlaceValueChart
              number={ScientificNotation.fromNumber(answer)}
              columnsToShow={[0, -1]}
              counterVariant={'decimalCounter'}
              dimens={dimens}
            />
          );
        }}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aNL',
  description: 'aNL',
  keywords: ['Tenths', 'Place value chart', 'Decimals'],
  schema: z.object({
    ones: z.number().min(1).max(9),
    tenths: z.number().min(0).max(9)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 9);
    const tenths = randomIntegerInclusive(0, 9);

    return { ones, tenths };
  },
  Component: ({ question: { ones, tenths }, translate }) => {
    const title =
      ones > 1
        ? translate.instructions.thereAreXOnesAndXDragCountersToRepresentNumber(
            ones,
            `${tenths} ${translate.fractions.tenths(tenths)}`
          )
        : translate.instructions.thereIsXOneAndXDragCountersToRepresentNumber(
            ones,
            `${tenths} ${translate.fractions.tenths(tenths)}`
          );
    const pdfTitle =
      ones > 1
        ? translate.instructions.thereAreXOnesAndXDrawCountersToRepresentNumber(
            ones,
            `${tenths} ${translate.fractions.tenths(tenths)}`
          )
        : translate.instructions.thereIsXOneAndXDrawCountersToRepresentNumber(
            ones,
            `${tenths} ${translate.fractions.tenths(tenths)}`
          );

    return (
      <QF23CreatePlaceValueChart
        title={title}
        pdfTitle={pdfTitle}
        number={ScientificNotation.fromNumber(ones + tenths / 10)}
        columnsToShow={[0, -1]}
        counterVariant="greyCounter"
        headerVariant="name"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aNM',
  description: 'aNM',
  keywords: ['Tenths', 'Place value chart', 'Decimals', 'Exchange'],
  schema: z.object({
    tenths: z.number().int().min(11).max(19)
  }),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(11, 19);

    return { tenths };
  },
  Component: props => {
    const {
      question: { tenths },
      translate
    } = props;

    const data: (string | JSX.Element)[][] = [
      [
        '',
        <View
          key={'tenths'}
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: 350,
            justifyContent: 'center',
            gap: 8
          }}
        >
          {range(0, tenths - 1).map(i => (
            <AssetSvg key={i} name="Place_value/0.1" width={60} />
          ))}
        </View>
      ]
    ];

    return (
      <QF1ContentAndSentence
        sentence={`<ans/>`}
        title={translate.instructions.whatNumberHasBeenMade()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], tenths / 10)}
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={3}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[0].color
                },
                textStyle: {
                  color: placeValueColumnInfo[0].textColor
                }
              },
              {
                label: translate.keywords.Tenths(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[-1].color
                },
                textStyle: {
                  color: placeValueColumnInfo[-1].textColor
                }
              }
            ]}
            tableData={data}
            tenthsColumnIndex={1}
          />
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aNN',
  description: 'aNN',
  keywords: ['Tenths', 'Place value chart', 'Decimals'],
  schema: z.object({
    ones: z.number().min(0).max(6),
    tenths: z.number().min(1).max(6)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 6, { constraint: x => x !== 1 });
    const tenths = randomIntegerInclusive(2, 6);

    return { ones, tenths };
  },
  Component: ({ question: { ones, tenths }, translate }) => {
    const answer = ones + tenths / 10;
    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsOnesAndAnsTenths(),
          `<ans/> ${ADD} <ans/> = <ans/>`
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={userAnswer =>
          userAnswer[0][0] === ones.toString() &&
          userAnswer[0][1] === tenths.toString() &&
          ((userAnswer[1][0] === ones.toString() && compareFloats(userAnswer[1][1], tenths / 10)) ||
            (compareFloats(userAnswer[1][0], tenths / 10) &&
              userAnswer[1][1] === ones.toString())) &&
          compareFloats(userAnswer[1][2], answer)
        }
        extraSymbol={'decimalPoint'}
        inputMaxCharacters={3}
        Content={({ dimens }) => {
          return (
            <PlaceValueChart
              number={ScientificNotation.fromNumber(answer)}
              columnsToShow={[0, -1]}
              counterVariant={'decimalCounter'}
              dimens={dimens}
            />
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ones.toLocaleString(), tenths.toLocaleString()],
            [ones.toLocaleString(), (tenths / 10).toLocaleString(), answer.toLocaleString()]
          ]
        }}
        pdfDirection="column"
        questionHeight={1050}
      />
    );
  },
  questionHeight: 1050
});

const Question5 = newQuestionContent({
  uid: 'aNO',
  description: 'aNO',
  keywords: ['Tenths', 'Decimal'],
  schema: z.object({
    ones: z.number().int().min(0).max(9),
    tenths: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const tenths = randomIntegerInclusive(1, 9);

    return { ones, tenths };
  },
  Component: ({ question: { ones, tenths }, translate }) => {
    const answer = ones + tenths / 10;

    return (
      <QF2AnswerBoxOneSentence
        title={
          ones !== 1
            ? translate.instructions.thereAreXandYWhatIsDecimal(
                `${ones.toLocaleString()} ${translate.powersOfTen.ones(ones)}`,
                `${tenths.toLocaleString()} ${translate.fractions.tenths(tenths)}`
              )
            : translate.instructions.thereIsXandYWhatIsDecimal(
                `${ones.toLocaleString()} ${translate.powersOfTen.ones(ones)}`,
                `${tenths.toLocaleString()} ${translate.fractions.tenths(tenths)}`
              )
        }
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={'<ans/>'}
        customMarkSchemeAnswer={{ answersToDisplay: [answer.toLocaleString()] }}
        inputMaxCharacters={3}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aNP',
  description: 'aNP',
  keywords: ['Tenths', 'Place value chart', 'Decimals'],
  schema: z.object({
    ones: z.array(z.number().int().min(0).max(4)).length(3),
    tenths: z.array(z.number().int().min(0).max(6)).length(3)
  }),
  simpleGenerator: () => {
    const ones = randomUniqueIntegersInclusive(0, 4, 3);
    const tenths = randomUniqueIntegersInclusive(0, 6, 3);

    return {
      ones,
      tenths
    };
  },
  Component: props => {
    const {
      question: { ones, tenths },
      translate,
      displayMode
    } = props;

    const dimens =
      displayMode === 'digital' ? { width: 400, height: 130 } : { width: 600, height: 300 };
    const statements = range(0, 2).map(i => {
      return {
        lhsComponent: (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(ones[i] + tenths[i] / 10)}
            columnsToShow={[0, -1]}
            counterVariant={'decimalCounter'}
            dimens={dimens}
            rowsToUse={2}
          />
        ),
        correctAnswer: `${(ones[i] * 10 + tenths[i]).toLocaleString()} ${translate.fractions.tenths(
          ones[i] * 10 + tenths[i]
        )}`
      };
    });

    const answerOptions = range(0, 2).map(
      i =>
        `${(ones[i] * 10 + tenths[i]).toLocaleString()} ${translate.fractions.tenths(
          ones[i] * 10 + tenths[i]
        )}`
    );
    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchPlaceValueCharts()}
        pdfTitle={translate.instructions.useCardsToMatchPlaceValueCharts()}
        items={shuffledAnswerOptions}
        statements={statements}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TenthsOnAPlaceValueChart',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
