import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { Thermometer } from '../../../../components/question/representations/Thermometer';
import QF66InteractiveThermometer from '../../../../components/question/questionFormats/QF66InteractiveThermometer';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bj5',
  description: 'bj5',
  keywords: ['Temperature', 'Thermometer'],
  schema: z
    .object({
      interval: numberEnum([2, 5, 10]),
      temp: z.number().int().min(1).max(100)
    })
    .refine(({ temp, interval }) => temp <= interval * 10, 'temp cannot be more then the topScale'),
  simpleGenerator: () => {
    const interval = getRandomFromArray([2, 5, 10] as const);

    // temp on intervals at 5s, halfway at 2s and either for 10s
    const temp = randomIntegerInclusiveStep(
      interval === 5 ? interval : interval / 2,
      interval * 10,
      interval === 10 ? interval / 2 : interval
    );

    return { interval, temp };
  },
  Component: ({ question: { interval, temp }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatIsTheTemperatureShownOnTheThermometer()}
        Content={({ dimens }) => (
          <Thermometer
            bottomScale={0}
            topScale={interval * 10}
            step={interval}
            dimens={dimens}
            temperature={temp}
          />
        )}
        sentence={translate.ks1AnswerSentences.ansDegC()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[temp.toString()]}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'bj6',
  description: 'bj6',
  keywords: ['Temperature', 'Thermometer'],
  schema: z
    .object({
      interval: numberEnum([1, 2, 5, 10]),
      temp: z.number().int().min(1).max(100)
    })
    .refine(({ temp, interval }) => temp <= interval * 10, 'temp cannot be more then the topScale'),
  simpleGenerator: () => {
    const interval = getRandomFromArray([1, 2, 5, 10] as const);

    const temp = randomIntegerInclusiveStep(
      [2, 10].includes(interval) ? interval / 2 : interval,
      interval * 10,
      [2, 10].includes(interval) ? interval / 2 : interval
    );

    return { interval, temp };
  },
  Component: ({ question: { interval, temp }, translate }) => {
    return (
      <QF66InteractiveThermometer
        title={translate.ks1Instructions.dragTheArrowToShowXCOnTheThermometer(temp)}
        pdfTitle={translate.ks1PDFInstructions.drawArrowToShowXCOnTheThermometer(temp)}
        bottomScale={0}
        topScale={interval * 10}
        step={interval}
        testCorrect={temp}
        questionHeight={1100}
        snapToNearest={[1, 5].includes(interval) ? interval : interval / 2}
        showArrowMarkscheme
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'bj7',
  description: 'bj7',
  keywords: ['Temperature', 'Coldest', 'Warmest', 'Thermometer'],
  schema: z.object({
    numbers: z.array(z.number().min(1).max(50)).length(4).refine(arrayHasNoDuplicates),
    ascending: z.boolean()
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 50, 4);

    const ascending = getRandomBoolean();

    return { numbers, ascending };
  },
  Component: props => {
    const {
      question: { numbers, ascending },
      translate
    } = props;

    const startWith = ascending ? 'startWithTheColdest' : 'startWithTheWarmest';

    return (
      <QF4DragOrderVertical
        title={`${translate.ks1Instructions.dragTheCardsToOrderTheTemperature()}<br/>${translate.ks1Instructions[
          startWith
        ]()}`}
        pdfTitle={`${translate.ks1PDFInstructions.useTheCardsToOrderTheTemperature()}<br/>${translate.ks1Instructions[
          startWith
        ]()}`}
        testCorrect={sortNumberArray(numbers, ascending ? 'ascending' : 'descending')}
        items={numbers.map(number => ({
          value: number,
          component: `${translate.units.stringDegreesC(number.toLocaleString())}`
        }))}
        topLabel={ascending ? translate.misc.Coldest() : translate.misc.Warmest()}
        bottomLabel={ascending ? translate.misc.Warmest() : translate.misc.Coldest()}
        pdfItemsLayout={'topRow'}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Temperature',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
