import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import { ADD, SUB } from '../../../../constants';
import { useMemo } from 'react';
import { ScientificNotation, roundToTheNearest } from '../../../../utils/math';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

const constants = { SUB, ADD };

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'ajs',
  description: 'ajs',
  keywords: ['Missing', 'Place value', 'Inverse'],
  schema: z
    .object({
      var1: z.number().int().min(10_000).max(99_999),
      var2: z.number().int().min(10).max(5000),
      operation: z.enum(['ADD', 'SUB'])
    })
    .refine(
      val => val.var2 % 10 === 0 || val.var2 % 100 === 0 || val.var2 % 1000 === 0,
      'var2 should be a multiple of 10, 100 or 1000'
    )
    .refine(
      val =>
        val.operation === 'ADD'
          ? numbersDoNotExchange(val.var1, val.var2)
          : numbersDoNotExchange(val.var1, -val.var2),
      'numbers should not exchange'
    ),

  simpleGenerator: () => {
    const operation = getRandomFromArray(['ADD', 'SUB'] as const);
    const PVFactor = getRandomFromArray([10, 100, 1000]);

    //The constraint on var1 depends on which place value number is being changed and operation
    const var1 = randomIntegerInclusive(10_000, 99_000, {
      constraint: x =>
        x % 10_000 !== 0 && operation === 'ADD'
          ? x % (PVFactor * 10) < 5 * PVFactor
          : x % (PVFactor * 10) > 5 * PVFactor
    });

    const var2 = randomIntegerInclusiveStep(1 * PVFactor, 5 * PVFactor, PVFactor);

    return {
      var1,
      var2,
      operation
    };
  },
  Component: ({ question: { var1, var2, operation }, translate }) => {
    const var3 = operation === 'ADD' ? var1 + var2 : var1 - var2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useThePVCToHelpYouWorkOutTheMissingNumber()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(var1)}
            columnsToShow={[4, 3, 2, 1, 0]}
            counterVariant={'greyCounter'}
            headerVariant="shortName"
            dimens={dimens}
          />
        )}
        sentence={`${var1.toLocaleString()} ${
          constants[operation]
        } <ans/> = ${var3.toLocaleString()}`}
        testCorrect={[var2.toString()]}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'ajt',
  description: 'ajt',
  keywords: ['Missing', 'Place value', 'Inverse'],
  schema: z
    .object({
      var3: z.number().int().min(9999).max(99_000),
      var2: z.number().int().min(10).max(5000),
      operation: z.enum(['ADD', 'SUB'])
    })
    .refine(
      val => val.var2 % 10 === 0 || val.var2 % 100 === 0 || val.var2 % 1000 === 0,
      'var2 should be a multiple of 10, 1000 or 1,000'
    )
    .refine(
      val =>
        val.operation === 'ADD'
          ? numbersDoNotExchange(val.var3, -val.var2)
          : numbersDoNotExchange(val.var3, val.var2),
      'numbers should not exchange'
    ),

  simpleGenerator: () => {
    const operation = getRandomFromArray(['ADD', 'SUB'] as const);
    const PVFactor = getRandomFromArray([10, 100, 1000]);

    //The constraint on var3 depends on which place value number is being changed and operation
    const var3 = randomIntegerInclusive(10_001, 98_999, {
      constraint: x => {
        const operationContraint =
          operation === 'ADD'
            ? x % (PVFactor * 10) > 5 * PVFactor
            : x % (PVFactor * 10) < 5 * PVFactor;
        return x % 10_000 !== 0 && operationContraint;
      }
    });

    const var2 = randomIntegerInclusiveStep(1 * PVFactor, 5 * PVFactor, PVFactor);

    return {
      var3,
      var2,
      operation
    };
  },
  Component: ({ question: { var3, var2, operation }, translate }) => {
    const var1 = operation === 'ADD' ? var3 - var2 : var3 + var2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useThePVCToHelpYouWorkOutTheMissingNumber()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(var3)}
            columnsToShow={[4, 3, 2, 1, 0]}
            counterVariant={'greyCounter'}
            headerVariant={'shortName'}
            dimens={dimens}
          />
        )}
        sentence={`<ans/> ${
          constants[operation]
        } ${var2.toLocaleString()} = ${var3.toLocaleString()}`}
        testCorrect={[var1.toString()]}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'ajv',
  description: 'ajv',
  keywords: ['Missing', 'Inverse'],
  schema: z
    .object({
      numberA1: z
        .number()
        .int()
        .min(10)
        .max(8990)
        .refine(val => val % 1000 !== 0, 'numberA1 must not be a multiple of 1,000'),
      numberA2: z
        .number()
        .int()
        .min(10)
        .max(8990)
        .refine(val => val % 1000 !== 0, 'numberA2 must not be a multiple of 1,000'),
      numberB1: z.number().int().min(1000).max(9000).multipleOf(1000),
      numberB2: z
        .number()
        .int()
        .min(10)
        .max(8990)
        .refine(val => val % 1000 !== 0, 'numberB2 must not be a multiple of 1,000'),
      numberC1: z
        .number()
        .int()
        .min(10)
        .max(8990)
        .refine(val => val % 1000 !== 0, 'numberC1 must not be a multiple of 1,000'),
      numberC2: z
        .number()
        .int()
        .min(10)
        .max(8990)
        .refine(val => val % 1000 !== 0, 'numberC2 must not be a multiple of 1,000'),
      numberD1: z.number().int().min(1000).max(9000).multipleOf(1000),
      numberD2: z
        .number()
        .int()
        .min(10)
        .max(8990)
        .refine(val => val % 1000 !== 0, 'numberD2 must not be a multiple of 1,000'),
      diffC: z.number().int().min(2).max(5),
      diffD: z.number().int().min(2).max(5)
    })
    .refine(
      val => (val.numberA1 + val.numberA2) % 1000 === 0 && val.numberA1 + val.numberA2 < 10000,
      'numberA1 + numberA2 must equal a multiple of 1,000 below 10,000'
    )
    .refine(
      val => (val.numberC1 + val.numberC2) % 1000 === 0 && val.numberC1 + val.numberC2 < 10000,
      'numberC1 + numberC2 must equal a multiple of 1,000 below 10,000'
    )
    .refine(
      val => val.numberB1 + val.numberB2 < 10000,
      'numberB1 + numberB2 must be less than 10,000'
    )
    .refine(
      val => val.numberD1 + val.numberD2 < 10000,
      'numberD1 + numberD2 must be less than 10,000'
    ),
  simpleGenerator: () => {
    const [numberA3, numberC3, numberB1, numberD1] = randomUniqueIntegersInclusiveStep(
      1000,
      9000,
      1000,
      4
    );

    const numberB2 = randomIntegerInclusive(10, 8990, {
      constraint: x => x % 1000 !== 0 && x + numberB1 < 10000
    });

    const numberD2 = randomIntegerInclusive(10, 8990, {
      constraint: x => x % 1000 !== 0 && x + numberD1 < 10000
    });

    const numberA1 = randomIntegerInclusive(10, numberA3 - 10, {
      constraint: x => x * 2 !== numberA3 && x % 1000 !== 0
    });

    const numberA2 = numberA3 - numberA1;

    const numberC1 = randomIntegerInclusive(10, numberC3 - 10, {
      constraint: x => x * 2 !== numberC3 && x % 1000 !== 0
    });

    const numberC2 = numberC3 - numberC1;

    const diffC = randomIntegerInclusive(2, 5);
    const diffD = randomIntegerInclusive(2, 5);

    return {
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      diffC,
      diffD
    };
  },
  Component: props => {
    const {
      question: {
        numberA1,
        numberA2,
        numberB1,
        numberB2,
        numberC1,
        numberC2,
        numberD1,
        numberD2,
        diffC,
        diffD
      },
      translate
    } = props;

    const numberB3 = numberB1 + numberB2;
    const numberD3 = numberD1 + numberD2;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: `${(
          numberA2 - 1
        ).toLocaleString()} ${ADD} ${numberA1.toLocaleString()} = ${numberA2.toLocaleString()} ${ADD}`,
        value: numberA1 - 1
      };
      const statement2 = {
        statement: `${(
          numberB3 - 1
        ).toLocaleString()} ${SUB} ${numberB1.toLocaleString()} = ${numberB3.toLocaleString()} ${SUB}`,
        value: numberB1 + 1
      };
      const statement3 = {
        statement: `${(
          numberC1 - diffC
        ).toLocaleString()} ${ADD} ${numberC2.toLocaleString()} = ${numberC1.toLocaleString()} ${ADD}`,
        value: numberC2 - diffC
      };
      const statement4 = {
        statement: `${(
          numberD3 - diffD
        ).toLocaleString()} ${SUB} ${numberD2.toLocaleString()} = ${numberD3.toLocaleString()} ${SUB}`,
        value: numberD2 + diffD
      };
      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [
      diffC,
      diffD,
      numberA1,
      numberA2,
      numberB1,
      numberB3,
      numberC1,
      numberC2,
      numberD2,
      numberD3,
      props.question
    ]);

    const items = statements.map(({ value }) => value).sort((a, b) => a - b);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToCompleteTheNumberSentences()}
        pdfTitle={translate.instructions.useTheCardsToCompleteTheNumberSentences()}
        items={items}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question4 = newQuestionContent({
  uid: 'aju',
  description: 'aju',
  keywords: ['Missing', 'Inverse', 'Bar model'],
  schema: z
    .object({
      diff: z.number().int().min(-5).max(5),
      number2: z.number().min(11).max(999)
    })
    .refine(val => val.diff !== 0, 'diff cannot be 0')
    .refine(
      val => numbersDoNotExchange(val.number2, val.diff),
      'number2 and diff must not exchange.'
    ),
  simpleGenerator: () => {
    const diff = randomIntegerInclusive(-5, 5, {
      constraint: x => x !== 0
    });

    const number2 = randomIntegerInclusive(11, 999, {
      constraint: x => numbersDoNotExchange(x, diff)
    });

    return { diff, number2 };
  },
  Component: ({ question: { diff, number2 }, translate }) => {
    const number1 = 100 + diff;
    const total = number1 + number2;
    const number3 = total - 100;

    const stringTopRow =
      diff < 0
        ? [(number1 - 20).toLocaleString(), (number2 + 20).toLocaleString()]
        : [(number1 + 20).toLocaleString(), (number2 - 20).toLocaleString()];
    const strings = [stringTopRow, [(100).toLocaleString(), number3.toLocaleString()]];

    // Proportions of numbers changed to make clearer, wider/shorter cells between number1 and 100.
    const numbers =
      diff < 0
        ? [
            [number1 - 20, number2 + 20],
            [100, number3]
          ]
        : [
            [number1 + 20, number2 - 20],
            [100, number3]
          ];
    const answerIndices = [[], [1]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={total}
        strings={strings}
        oneFontSize
        proportional
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question5 = newQuestionContent({
  uid: 'ajw',
  description: 'ajw',
  keywords: ['Missing', 'Bar model'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(3010)
        .max(9990)
        .refine(val => val % 100 !== 0, 'number1 must not be a multiple of 100'),
      number2: z
        .number()
        .int()
        .min(-9)
        .max(9)
        .refine(val => val !== 0, 'number2 cannot be zero.'),
      number4: z
        .number()
        .int()
        .min(1010)
        .max(8980)
        .refine(val => val % 1000 !== 0, 'number4 must not be a multiple of 1,000'),
      number4Left: z.boolean()
    })
    .refine(
      val => val.number4 <= val.number1 - 1010,
      'number4 must be less than or equal to number1 - 1,010'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(3010, 9990, 10, {
      constraint: x => x % 100 !== 0
    });

    const number2 = randomIntegerInclusive(-9, 9, {
      constraint: x => x !== 0
    });

    const number4 = randomIntegerInclusive(1010, number1 - 1010, {
      constraint: x => x % 1000 !== 0
    });

    const number4Left = getRandomBoolean();

    return {
      number1,
      number2,
      number4,
      number4Left
    };
  },

  Component: ({ question: { number1, number2, number4, number4Left }, translate }) => {
    const number3 = number1 + number2;

    const number5 = number3 - number4;

    const number6 = number1 - number4;

    const number7 = number3 - number6;

    const number8 = roundToTheNearest(number4, 1000);

    const number9 = number3 - number8;

    const numbers = [
      [number3],
      number4Left ? [number4, number5] : [number5, number4],
      number4Left ? [number7, number6] : [number6, number7],
      number4Left ? [number8, number9] : [number9, number8]
    ];

    const answerIndices = [[], [], number4Left ? [0] : [1], number4Left ? [1] : [0]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.workOutTheMissingNumbers()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        questionHeight={900}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ajx',
  description: 'ajx',
  keywords: ['Missing', 'Inverse'],
  schema: z
    .object({
      diffA: z.union([
        z.literal(1),
        z.literal(10),
        z.literal(100),
        z.literal(3),
        z.literal(30),
        z.literal(300)
      ]),
      numberA1: z.number().int().min(2).max(9998),
      numberA3: z.number().int().min(1000).max(9000).multipleOf(1000),
      diffB: z.union([
        z.literal(1),
        z.literal(10),
        z.literal(100),
        z.literal(3),
        z.literal(30),
        z.literal(300)
      ]),
      numberB1: z.number().int().min(2).max(9998),
      numberB3: z.number().int().min(1000).max(9000).multipleOf(1000),
      diffC: z.union([
        z.literal(1),
        z.literal(10),
        z.literal(100),
        z.literal(3),
        z.literal(30),
        z.literal(300)
      ]),
      numberC1: z.number().int().min(2).max(9998),
      numberC3: z.number().int().min(1000).max(9000).multipleOf(1000),
      diffD: z.union([
        z.literal(1),
        z.literal(10),
        z.literal(100),
        z.literal(3),
        z.literal(30),
        z.literal(300)
      ]),
      numberD1: z.number().int().min(2).max(9998),
      numberD3: z.number().int().min(1000).max(9000).multipleOf(1000)
    })
    .refine(
      val => val.numberA1 > val.diffA && val.numberA1 < val.numberA3,
      'numberA1 must be greater than diff and less than numberA3'
    )
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.diffA),
      'numberA1 and diff cannot exchange when added.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberA1, -val.diffA),
      'numberA1 and diff cannot exchange when subtracted.'
    )
    .refine(
      val => val.numberB1 > val.diffB && val.numberB1 < val.numberB3,
      'numberB1 must be greater than diff and less than numberB3'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.diffB),
      'numberB1 and diff cannot exchange when added.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, -val.diffB),
      'numberB1 and diff cannot exchange when subtracted.'
    )
    .refine(
      val => val.numberC1 > val.diffC && val.numberC1 < val.numberC3,
      'numberC1 must be greater than diff and less than numberC3'
    )
    .refine(
      val => numbersDoNotExchange(val.numberC1, val.diffC),
      'numberC1 and diff cannot exchange when added.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberC1, -val.diffC),
      'numberC1 and diff cannot exchange when subtracted.'
    )
    .refine(
      val => val.numberD1 > val.diffD && val.numberD1 < val.numberD3,
      'numberD1 must be greater than diff and less than numberD3'
    )
    .refine(
      val => numbersDoNotExchange(val.numberD1, val.diffD),
      'numberD1 and diff cannot exchange when added.'
    )
    .refine(
      val => numbersDoNotExchange(val.numberD1, -val.diffD),
      'numberD1 and diff cannot exchange when subtracted.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const numberA3 = randomIntegerInclusiveStep(1000, 9000, 1000);
    const numberB3 = randomIntegerInclusiveStep(1000, 9000, 1000);
    const numberC3 = randomIntegerInclusiveStep(1000, 9000, 1000);
    const numberD3 = randomIntegerInclusiveStep(1000, 9000, 1000);

    const diffA = getRandomFromArray([1, 10, 100, 3, 30, 300] as const);
    const diffB = getRandomFromArray([1, 10, 100, 3, 30, 300] as const);
    const diffC = getRandomFromArray([1, 10, 100, 3, 30, 300] as const);
    const diffD = getRandomFromArray([1, 10, 100, 3, 30, 300] as const);

    // Number cannot exchange when adding or subtracting its diff.
    const numberA1 = randomIntegerInclusive(diffA + 1, numberA3 - 1, {
      constraint: x => numbersDoNotExchange(x, diffA) && numbersDoNotExchange(x, -diffA)
    });

    const numberB1 = randomIntegerInclusive(diffB + 1, numberB3 - 1, {
      constraint: x => numbersDoNotExchange(x, diffB) && numbersDoNotExchange(x, -diffB)
    });

    const numberC1 = randomIntegerInclusive(diffC + 1, numberC3 - 1, {
      constraint: x => numbersDoNotExchange(x, diffC) && numbersDoNotExchange(x, -diffC)
    });

    const numberD1 = randomIntegerInclusive(diffD + 1, numberD3 - 1, {
      constraint: x => numbersDoNotExchange(x, diffD) && numbersDoNotExchange(x, -diffD)
    });

    return {
      diffA,
      numberA1,
      numberA3,
      diffB,
      numberB1,
      numberB3,
      diffC,
      numberC1,
      numberC3,
      diffD,
      numberD1,
      numberD3
    };
  },

  Component: props => {
    const {
      question: {
        diffA,
        numberA1,
        numberA3,
        diffB,
        numberB1,
        numberB3,
        diffC,
        numberC1,
        numberC3,
        diffD,
        numberD1,
        numberD3
      },
      translate,
      displayMode
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheMissingNumbers()}
        testCorrect={[
          [(numberA1 + diffA).toString()],
          [(numberB1 - diffB).toString()],
          [(numberC1 + diffC).toString()],
          [(numberD1 - diffD).toString()]
        ]}
        sentences={[
          `${(
            numberA3 - diffA
          ).toLocaleString()} ${SUB} ${numberA1.toLocaleString()} = ${numberA3.toLocaleString()} ${SUB} <ans/>`,
          `${(
            numberB3 - diffB
          ).toLocaleString()} ${ADD} ${numberB1.toLocaleString()} = ${numberB3.toLocaleString()} ${ADD} <ans/>`,
          `${(
            numberC3 + diffC
          ).toLocaleString()} ${ADD} ${numberC1.toLocaleString()} = ${numberC3.toLocaleString()} ${ADD} <ans/>`,
          `${(
            numberD3 + diffD
          ).toLocaleString()} ${SUB} ${numberD1.toLocaleString()} = ${numberD3.toLocaleString()} ${SUB} <ans/>`
        ]}
        textStyle={{ fontSize: displayMode !== 'digital' ? 50 : 32 }}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindMissingNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
