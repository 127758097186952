import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { DIV } from '../../../../constants';
import {
  compareFloats,
  lessThanGreaterThanOrEqualTo,
  ScientificNotation
} from '../../../../utils/math';
import {
  randomIntegerInclusive,
  getRandomFromArray,
  shuffle,
  seededRandom,
  getRandomSubArrayFromArray,
  getRandomBoolean,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import { newQuestionContent } from '../../../Question';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { binOpEquationToSentenceString, getBinOpEquation } from '../../../../utils/fourOperations';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { ArrowAnswerBoxesKeyboardWithState } from '../../../../components/question/representations/ArrowAnswerBoxes/ArrowAnswerBoxesInput';
import Text from '../../../../components/typography/Text';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVc',
  description: 'aVc',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths'
  ],
  schema: z.object({
    tens: z.number().min(0).max(9),
    hundreds: z.number().min(0).max(9),
    divisor: numberEnum([10, 100, 1000])
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(0, 9);
    const min = tens === 0 ? 1 : 0;
    const hundreds = randomIntegerInclusive(min, 9);
    const divisor = getRandomFromArray([10, 100, 1000] as const);

    return { tens, hundreds, divisor };
  },
  Component: ({ question: { tens, hundreds, divisor }, translate }) => {
    const total = number(math.evaluate(`(${hundreds} * 100) + (${tens} * 100)`));
    const answer = number(math.evaluate(`${total} / ${divisor}`));

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.completeCalculation()}<br/>${translate.instructions.useCountersToHelp()}`}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={`${total.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`}
        inputMaxCharacters={4}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(total)}
            columnsToShow={[3, 2, 1, 0, -1, -2]}
            dimens={dimens}
            counterVariant={'greyCounter'}
            headerVariant="shortName"
          />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question1v2 = newQuestionContent({
  uid: 'aVc2',
  description: 'aVc',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths'
  ],
  schema: z
    .object({
      tens: z.number().min(0).max(9),
      hundreds: z.number().min(0).max(9),
      divisor: numberEnum([100, 1000])
    })
    .refine(
      ({ tens, hundreds, divisor }) => ((tens * 10 + hundreds * 100) / divisor) % 1 !== 0,
      'answer should always be a decimal'
    ),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(0, 9);
    const min = tens === 0 ? 1 : 0;
    const hundreds = randomIntegerInclusive(min, 9);
    const divisor = tens === 0 ? 1000 : getRandomFromArray([100, 1000] as const);

    return { tens, hundreds, divisor };
  },
  Component: ({ question: { tens, hundreds, divisor }, translate }) => {
    const total = number(math.evaluate(`(${hundreds} * 100) + (${tens} * 10)`));
    const answer = number(math.evaluate(`${total} / ${divisor}`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeDivision()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={`${total.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`}
        inputMaxCharacters={4}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()]
        }}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(total)}
            columnsToShow={[3, 2, 1, 0, -1, -2]}
            dimens={dimens}
            counterVariant={'greyCounter'}
            headerVariant="shortName"
          />
        )}
        pdfDirection="column"
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'aVd',
  description: 'aVd',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    ones: z.number().min(0).max(9),
    tens: z.number().min(0).max(9),
    hundreds: z.number().min(1).max(9),
    divisor: numberEnum([1, 10, 100, 1000])
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(0, 9);
    const min = ones === 0 ? 1 : 0;
    const tens = randomIntegerInclusive(min, 9);
    const hundreds = randomIntegerInclusive(1, 9);
    const divisor = getRandomFromArray([1, 10, 100, 1000] as const);

    return { ones, tens, hundreds, divisor };
  },
  Component: ({ question: { ones, tens, hundreds, divisor }, translate }) => {
    const total = number(math.evaluate(`(${hundreds} * 100) + (${tens} * 10) + ${ones}`));
    const answer = number(math.evaluate(`${total} / ${divisor}`));
    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragCountersIntoPVCToRepresentAnswerTo(
          `${total.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersIntoPVCToRepresentAnswerTo(
          `${total.toLocaleString()} ${DIV} ${divisor.toLocaleString()}`
        )}
        number={ScientificNotation.fromNumber(answer)}
        columnsToShow={[3, 2, 1, 0, -1, -2, -3]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aVe',
  description: 'aVe',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    number1: z.number().int().min(11).max(999),
    answerBoxLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 999, {
      constraint: x => x % 100 !== 0
    });

    const answerBoxLeft = getRandomBoolean();

    return { number1, answerBoxLeft };
  },
  Component: props => {
    const {
      question: { number1, answerBoxLeft },
      translate
    } = props;

    // Answers
    const ans1 = number(math.evaluate(`${number1} / 1`));
    const ans2 = number(math.evaluate(`${number1} / 10`));
    const ans3 = number(math.evaluate(`${number1} / 100`));
    const ans4 = number(math.evaluate(`${number1} / 1000`));

    const sentences = shuffle(
      [
        {
          sentence: answerBoxLeft
            ? `<ans/> = ${number1.toLocaleString()} ${DIV} ${(1).toLocaleString()}`
            : `${number1.toLocaleString()} ${DIV} ${(1).toLocaleString()} = <ans/>`,
          answer: ans1
        },
        {
          sentence: answerBoxLeft
            ? `<ans/> = ${number1.toLocaleString()} ${DIV} ${(10).toLocaleString()}`
            : `${number1.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
          answer: ans2
        },
        {
          sentence: answerBoxLeft
            ? `<ans/> = ${number1.toLocaleString()} ${DIV} ${(100).toLocaleString()}`
            : `${number1.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`,
          answer: ans3
        },
        {
          sentence: answerBoxLeft
            ? `<ans/> = ${number1.toLocaleString()} ${DIV} ${(1000).toLocaleString()}`
            : `${number1.toLocaleString()} ${DIV} ${(1000).toLocaleString()} = <ans/>`,
          answer: ans4
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );
    const answerOptions = shuffle(
      sentences.map(i => i.answer),
      {
        random: seededRandom(props.question)
      }
    );
    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useCardsToCompleteCalculations()}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsRight"
        pdfSentencesStyle={{
          alignItems: answerBoxLeft ? 'flex-start' : 'flex-end',
          alignSelf: 'center'
        }}
        sentencesStyle={{
          alignItems: answerBoxLeft ? 'flex-start' : 'flex-end',
          alignSelf: 'center'
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aVf',
  description: 'aVf',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    number1: z.number().int().min(11).max(99),
    number2Tenths: z.number().min(111).max(999),
    number3: z.number().int().min(101).max(999),
    number4Hundredths: z.number().min(101).max(9999),
    divisor: numberEnum([10, 100, 1000])
  }),
  questionHeight: 1050,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99);
    const number2Tenths = randomIntegerInclusive(111, 999, {
      constraint: x => x % 10 !== 0
    });
    const number3 = randomIntegerInclusive(101, 999);
    const number4Hundredths = randomIntegerInclusive(101, 9999);
    const divisor = getRandomFromArray([10, 100, 1000] as const);

    return { number1, number2Tenths, number3, number4Hundredths, divisor };
  },

  Component: props => {
    const {
      question: { number1, number2Tenths, number3, number4Hundredths, divisor },
      translate
    } = props;

    // Divide number2/number4 by 10 to get desired number
    const number2Dividend = number(math.evaluate(`${number2Tenths} / 10`));
    const number4Dividend = number(math.evaluate(`${number4Hundredths} / 100`));

    const eqA = getBinOpEquation({
      left: number1,
      right: 10,
      sign: 'divide'
    });

    const eqB = getBinOpEquation({
      left: number2Dividend,
      right: 100,
      sign: 'divide'
    });

    const eqC = getBinOpEquation({
      left: number3,
      right: 1000,
      sign: 'divide'
    });

    const eqD = getBinOpEquation({
      left: number4Dividend,
      right: divisor,
      sign: 'divide'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={userAnswer =>
          userAnswer.every((answer, index) => compareFloats(answer[0], eqs[index].result))
        }
        sentences={eqs.map(binOpEquationToSentenceString)}
        extraSymbol="decimalPoint"
        inputMaxCharacters={6}
        questionHeight={1050}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [number(math.evaluate(`${number1} / 10`)).toLocaleString()],
            [number(math.evaluate(`${number2Dividend} / 100`)).toLocaleString()],
            [number(math.evaluate(`${number3} / 1000`)).toLocaleString()],
            [number(math.evaluate(`${number4Dividend} / ${divisor}`)).toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aVf2',
  description: 'aVf2',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    dividend: z.number().int().min(11).max(9999),
    divisor: numberEnum([10, 100, 1000])
  }),
  simpleGenerator: () => {
    const divisor = getRandomFromArray([10, 100, 1000] as const);

    // Prevent answer being over 3 d.p.
    const constraint = (x: number) =>
      number(math.evaluate(`${x} / ${divisor}`)).toString().length < 6;
    const integer = getRandomBoolean();
    const dividend = integer
      ? randomIntegerInclusive(11, 999, { constraint })
      : randomIntegerInclusive(101, 9999, { constraint });

    return { dividend, divisor };
  },

  Component: props => {
    const {
      question: { dividend, divisor },
      translate
    } = props;

    const answer = number(math.evaluate(`${dividend} / ${divisor}`));

    const sentence = `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeDivision()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        extraSymbol="decimalPoint"
        inputMaxCharacters={5}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aVg',
  description: 'aVg',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    number1: z.number().int().min(1).max(9999),
    number2: z.number().int().min(1).max(9999),
    number3: z.number().int().min(1).max(9999)
  }),
  simpleGenerator: () => {
    const [number1, number2, number3] = randomUniqueIntegersInclusive(1, 9999, 3);
    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate,
      displayMode
    } = props;
    const answer1 = number1 / 1000;
    const answer2 = number2 / 1000;
    const answer3 = number3 / 1000;
    return (
      <QF3Content
        title={translate.instructions.completeDiagram()}
        inputType="numpad"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <ArrowAnswerBoxesKeyboardWithState
            id={'trackA'}
            dimens={{ height: dimens.height, width: dimens.width }}
            boxValues={[
              [number1.toLocaleString(), `<ans/>`],
              [number2.toLocaleString(), `<ans/>`],
              [number3.toLocaleString(), `<ans/>`]
            ]}
            arrowValues={[
              [`${DIV} ${(1000).toLocaleString()}`],
              [`${DIV} ${(1000).toLocaleString()}`],
              [`${DIV} ${(1000).toLocaleString()}`]
            ]}
            testCorrect={userAnswer =>
              compareFloats(userAnswer[0][0], answer1.toString()) &&
              compareFloats(userAnswer[1][0], answer2.toString()) &&
              compareFloats(userAnswer[2][0], answer3.toString())
            }
            inputMaxCharacters={5}
            defaultState={
              displayMode === 'markscheme'
                ? [
                    [answer1.toLocaleString()],
                    [answer2.toLocaleString()],
                    [answer3.toLocaleString()]
                  ]
                : undefined
            }
          />
        )}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aVg2',
  description: 'aVg',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths'
  ],
  schema: z.object({
    num: z.number().int().min(1).max(9999)
  }),
  simpleGenerator: () => {
    const num = randomIntegerInclusive(1, 9999);
    return { num };
  },
  Component: props => {
    const {
      question: { num },
      translate,
      displayMode
    } = props;
    const answer = num / 1000;

    return (
      <QF3Content
        title={translate.instructions.workOutMissingValue()}
        inputType="numpad"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <ArrowAnswerBoxesKeyboardWithState
            id={'trackA'}
            dimens={{ height: dimens.height, width: dimens.width }}
            boxValues={[[num.toLocaleString(), `<ans/>`]]}
            arrowValues={[[`${DIV} ${(1000).toLocaleString()}`]]}
            testCorrect={userAnswer => compareFloats(userAnswer[0][0], answer.toString())}
            inputMaxCharacters={5}
            defaultState={displayMode === 'markscheme' ? [[answer.toLocaleString()]] : undefined}
          />
        )}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

const Question6 = newQuestionContent({
  uid: 'aVh',
  description: 'aVh',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Compare',
    'Greater than',
    'Less than',
    'Equal to'
  ],
  schema: z.object({
    number1: z.number().int().min(1).max(9999),
    number4: z.number().int().min(1).max(9999),
    number7: z.number().int().min(1).max(9999),
    number10: z.number().int().min(1).max(9999),
    divisorA: z.number(),
    divisorB: z.number(),
    divisorC: z.number(),
    divisorD: z.number(),
    divisorStructureA: z.array(z.array(z.number().int())).length(2),
    divisorStructureB: z.array(z.array(z.number().int())).length(2),
    divisorStructureC: z.array(z.array(z.number().int())).length(2),
    divisorStructureD: z.array(z.array(z.number().int())).length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9999);
    const number4 = randomIntegerInclusive(1, 9999);
    const number7 = randomIntegerInclusive(1, 9999);
    const number10 = randomIntegerInclusive(1, 9999);

    // Divisors
    const divisorA = getRandomFromArray([number1, 10, 100, 1000] as const);
    const divisorB = getRandomFromArray([number4, 10, 100, 1000] as const);
    const divisorC = getRandomFromArray([number7, 10, 100, 1000] as const);
    const divisorD = getRandomFromArray([number10, 10, 100, 1000] as const);

    // Divisor structures
    const divisorStructureA = getRandomSubArrayFromArray(
      [[10], [100], [1000], [10, 10], [10, 10, 10], [10, 100], [100, 10]],
      2
    );

    const divisorStructureB = getRandomSubArrayFromArray(
      [[10], [100], [1000], [10, 10], [10, 10, 10], [10, 100], [100, 10]],
      2
    );

    const divisorStructureC = getRandomSubArrayFromArray(
      [[10], [100], [1000], [10, 10], [10, 10, 10], [10, 100], [100, 10]],
      2
    );

    const divisorStructureD = getRandomSubArrayFromArray(
      [[10], [100], [1000], [10, 10], [10, 10, 10], [10, 100], [100, 10]],
      2
    );

    return {
      number1,
      number4,
      number7,
      number10,
      divisorA,
      divisorB,
      divisorC,
      divisorD,
      divisorStructureA,
      divisorStructureB,
      divisorStructureC,
      divisorStructureD
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number4,
        number7,
        number10,
        divisorA,
        divisorB,
        divisorC,
        divisorD,
        divisorStructureA,
        divisorStructureB,
        divisorStructureC,
        divisorStructureD
      },
      translate,
      displayMode
    } = props;

    // Divisors
    const [number2, number5, number8, number11] = [divisorA, divisorB, divisorC, divisorD];

    // Divisor structures
    const [number3, number13] = divisorStructureA;
    const [number6, number14] = divisorStructureB;
    const [number9, number15] = divisorStructureC;
    const [number12, number16] = divisorStructureD;

    const calculateSum = (numA: number, numB: number[]) => {
      return number(math.evaluate(`${numA}${numB.map(num => ` / ${num}`).join('')}`));
    };

    const sum1A = calculateSum(number1, number3);
    const sum1B = calculateSum(number2, number13);

    const sum2A = calculateSum(number4, number6);
    const sum2B = calculateSum(number5, number14);

    const sum3A = calculateSum(number7, number9);
    const sum3B = calculateSum(number8, number15);

    const sum4A = calculateSum(number10, number12);
    const sum4B = calculateSum(number11, number16);

    const statements = shuffle(
      [
        {
          lhsComponent: `${number1.toLocaleString()} ${DIV} ${number3
            .join(` ${DIV} `)
            .toLocaleString()}`,
          rhsComponent: `${number2.toLocaleString()} ${DIV} ${number13
            .join(` ${DIV} `)
            .toLocaleString()}`,
          correctAnswer: lessThanGreaterThanOrEqualTo(sum1A, sum1B)
        },
        {
          lhsComponent: `${number4.toLocaleString()} ${DIV} ${number6
            .join(` ${DIV} `)
            .toLocaleString()}`,
          rhsComponent: `${number5.toLocaleString()} ${DIV} ${number14
            .join(` ${DIV} `)
            .toLocaleString()}`,
          correctAnswer: lessThanGreaterThanOrEqualTo(sum2A, sum2B)
        },
        {
          lhsComponent: `${number7.toLocaleString()} ${DIV} ${number9
            .join(` ${DIV} `)
            .toLocaleString()}`,
          rhsComponent: `${number8.toLocaleString()} ${DIV} ${number15
            .join(` ${DIV} `)
            .toLocaleString()}`,
          correctAnswer: lessThanGreaterThanOrEqualTo(sum3A, sum3B)
        },
        {
          lhsComponent: `${number10.toLocaleString()} ${DIV} ${number12
            .join(` ${DIV} `)
            .toLocaleString()}`,
          rhsComponent: `${number11.toLocaleString()} ${DIV} ${number16
            .join(` ${DIV} `)
            .toLocaleString()}`,
          correctAnswer: lessThanGreaterThanOrEqualTo(sum4A, sum4B)
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareDivisions()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareDivisons()}
        items={['<', '>', '=']}
        itemVariant="square"
        statements={statements.map(({ lhsComponent, rhsComponent, correctAnswer }) => ({
          lhsComponent: (
            <Text
              variant="WRN400"
              style={{ flex: 1, textAlign: 'right', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {lhsComponent}
            </Text>
          ),
          rhsComponent: (
            <Text
              variant="WRN400"
              style={{ flex: 1, fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {rhsComponent}
            </Text>
          ),
          correctAnswer
        }))}
        actionPanelVariant="end"
        moveOrCopy="copy"
        pdfLayout="itemsHidden"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6v2 = newQuestionContent({
  uid: 'aVh2',
  description: 'aVh2',
  keywords: [
    'Divide by 10',
    'Divide by 100',
    'Divide by 1,000',
    'Division',
    'Decimals',
    'Thousands',
    'Hundreds',
    'Tens',
    'Ones',
    'Tenths',
    'Hundredths',
    'Thousandths',
    'Compare',
    'Greater than',
    'Less than',
    'Equal to'
  ],
  schema: z
    .object({
      dividendA: z.number().int().min(1).max(9999),
      dividendB: z.number().int().min(1).max(9999),
      divisorStructure: z.array(z.array(z.number().int().min(10).max(1000))).length(2)
    })
    .refine(
      val =>
        val.dividendB === val.dividendA ||
        val.dividendB === 10 ||
        val.dividendB === 100 ||
        val.dividendB === 1000,
      'dividendB must be either 10, 100, 1,000, or the same as dividendA.'
    ),
  simpleGenerator: () => {
    const dividendA = randomIntegerInclusive(1, 9999);

    const dividendB = getRandomFromArray([dividendA, 10, 100, 1000] as const);

    // Divisor structures
    const divisorStructure = getRandomSubArrayFromArray(
      [[10], [100], [1000], [10, 10], [10, 10], [10, 100], [100, 10]],
      2
    );

    return {
      dividendA,
      dividendB,
      divisorStructure
    };
  },
  Component: props => {
    const {
      question: { dividendA, dividendB, divisorStructure },
      translate,
      displayMode
    } = props;

    // Divisor structures
    const [divisorsA, divisorsB] = divisorStructure;

    const calculateDivision = (dividend: number, divisors: number[]) => {
      return number(math.evaluate(`${dividend}${divisors.map(num => ` / ${num}`).join('')}`));
    };

    const quotientA = calculateDivision(dividendA, divisorsA);
    const quotientB = calculateDivision(dividendB, divisorsB);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToCompareDivisions()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareDivisons()}
        items={['<', '>', '=']}
        itemVariant="square"
        pdfItemVariant="square"
        statements={[
          {
            lhsComponent: (
              <Text
                variant="WRN400"
                style={{
                  flex: 1,
                  textAlign: 'right',
                  fontSize: displayMode === 'digital' ? 40 : 50
                }}
              >
                {`${dividendA.toLocaleString()}${divisorsA
                  .map(divisor => ` ${DIV} ${divisor.toLocaleString()}`)
                  .join('')}`}
              </Text>
            ),
            rhsComponent: (
              <Text
                variant="WRN400"
                style={{ flex: 1, fontSize: displayMode === 'digital' ? 40 : 50 }}
              >
                {`${dividendB.toLocaleString()} ${divisorsB
                  .map(divisor => ` ${DIV} ${divisor.toLocaleString()}`)
                  .join('')}`}
              </Text>
            ),
            correctAnswer: lessThanGreaterThanOrEqualTo(quotientA, quotientB)
          }
        ]}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy10100And1000',
  questionTypes: [Question1v2, Question2, Question3, Question4v2, Question5v2, Question6v2],
  archivedQuestionTypes: [Question1, Question4, Question5, Question6]
});
export default SmallStep;
