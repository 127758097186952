import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import { newQuestionContent } from '../../../Question';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { AssetSvg } from '../../../../assets/svg';
import { getRandomName, nameSchema } from '../../../../utils/names';
import Text from '../../../../components/typography/Text';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import Clock, { ClockWithState } from '../../../../components/question/representations/Clock';
import { countRange } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { lineSvg } from '../../../../components/question/representations/LineSvgs';
import { addDurationTo24hTime, displayDigitalTime } from '../../../../utils/time';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const arrowColors = ['blue', 'green', 'pink', 'purple', 'yellow'] as const;
const turns = [
  'fullTurnClockwise',
  'fullTurnAntiClockwise',
  'halfTurnClockwise',
  'halfTurnAntiClockwise',
  'quarterTurnClockwise',
  'quarterTurnAntiClockwise',
  'threeQuarterTurnAntiClockwise',
  'threeQuarterTurnClockwise'
] as const;
type Turn = (typeof turns)[number];

const Question1 = newQuestionContent({
  uid: 'auM',
  description: 'auM',
  keywords: ['Angles'],
  schema: z.object({
    incorrectLines: z.array(z.enum(['parallel', 'noIntersect', 'curvedCrossing'])).length(2),
    correctLines: z.array(z.enum(['perpendicular', 'meetingPoint', 'intersect'])).length(2),
    isLeft: z.boolean().array().length(4)
  }),
  simpleGenerator: () => {
    const incorrectLines = getRandomSubArrayFromArray(
      ['parallel', 'noIntersect', 'curvedCrossing'] as const,
      2
    );

    const correctLines = getRandomSubArrayFromArray(
      ['perpendicular', 'meetingPoint', 'intersect'] as const,
      2
    );

    const isLeft = countRange(4).map(getRandomBoolean);

    return { incorrectLines, correctLines, isLeft };
  },
  Component: props => {
    const {
      question: { incorrectLines, isLeft, correctLines },
      translate
    } = props;

    const lines = shuffle([...incorrectLines, ...correctLines], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPicturesThatShowAtLeastOneAngle()}
        pdfTitle={translate.instructions.circlePicturesThatShowAtLeastOneAngle()}
        testCorrect={correctLines}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return lines.map((line, i) => ({
            value: line,
            component: lineSvg(
              { height: dimens.height * 0.8, width: dimens.width * 0.8 },
              line,
              isLeft[i]
            )
          }));
        }}
      />
    );
  }
});

// Reused by ayc
const Question2 = newQuestionContent({
  uid: 'auN',
  description: 'auN',
  keywords: ['Turns'],
  schema: z.object({
    startingAngle: numberEnum([90, 180, 270, 360]),
    colors: z.enum(['blue', 'green', 'pink', 'purple', 'yellow']).array().length(4),
    isClockwise: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingAngle = getRandomFromArray([90, 180, 270, 360] as const);
    const colors = getRandomSubArrayFromArray(arrowColors, 4);
    const isClockwise = getRandomBoolean();

    return { startingAngle, colors, isClockwise };
  },
  Component: ({ question: { startingAngle, colors, isClockwise }, translate, displayMode }) => {
    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 100, width: 250 } : { height: 150, width: 450 };

    const quarter = translate.directions.quarterTurn();
    const half = translate.directions.halfTurn();
    const full = translate.directions.fullTurn();
    const threeQuarter = translate.directions.threeQuarterTurn();

    const options = isClockwise
      ? [quarter, half, threeQuarter, full]
      : [threeQuarter, half, quarter, full];

    const statements = shuffle(
      options.map((val, i) => ({
        lhsComponent: (
          <View
            style={{
              flexDirection: 'row',
              gap: 30,
              justifyContent: 'center'
            }}
          >
            <View style={{ transform: `rotate(${startingAngle - 90}deg)` }}>
              <AssetSvg name={`Arrows/arrow_${colors[i]}`} width={dimens.height} />
            </View>
            <View style={{ transform: `rotate(${startingAngle + 90 * i}deg)` }}>
              <AssetSvg name={`Arrows/arrow_${colors[i]}`} width={dimens.height} />
            </View>
          </View>
        ),
        correctAnswer: val
      })),
      { random: seededRandom({ startingAngle, colors }) }
    );

    const title = isClockwise
      ? translate.instructions.theArrowsAreBeingTurnedClockwiseDragToMatch()
      : translate.instructions.theArrowsAreBeingTurnedAntiClockwiseDragToMatch();

    const pdfTitle = isClockwise
      ? translate.instructions.theArrowsAreBeingTurnedClockwiseUseToMatch()
      : translate.instructions.theArrowsAreBeingTurnedAntiClockwiseUseToMatch();

    return (
      <QF6DragMatchStatements
        pdfTitle={pdfTitle}
        title={title}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={[quarter, half, threeQuarter, full]}
        actionPanelVariant="endWide"
        questionHeight={1000}
      />
    );
  }
});
export const auN = Question2;

// Reused by aye
const Question3 = newQuestionContent({
  uid: 'auO',
  description: 'auO',
  keywords: ['Direction', 'Compass'],
  schema: z.object({
    startingPosition: numberEnum([90, 180, 270, 360]),
    name: nameSchema,
    turn: numberEnum([90, 180, 270, 360]),
    isClockwise: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const startingPosition = getRandomFromArray([90, 180, 270, 360] as const);
    const name = getRandomName();
    const turn = getRandomFromArray([90, 180, 270, 360] as const);
    const isClockwise = getRandomBoolean();

    return { startingPosition, name, turn, isClockwise };
  },

  Component: props => {
    const {
      question: { startingPosition, name, turn, isClockwise },
      translate
    } = props;

    const directionMappingTable = [
      ['360', translate.directions.north()],
      ['90', translate.directions.east()],
      ['180', translate.directions.south()],
      ['270', translate.directions.west()]
    ];

    const turnMappingTable = [
      [
        '90',
        isClockwise ? translate.directions.quarterTurn() : translate.directions.threeQuarterTurn()
      ],
      ['180', translate.directions.halfTurn()],
      [
        '270',
        isClockwise ? translate.directions.threeQuarterTurn() : translate.directions.quarterTurn()
      ],
      ['360', translate.directions.fullTurn()]
    ];

    const mod = (startingPosition + turn) % 360;
    const answer = mod === 0 ? 360 : mod;

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.compassXIsFacingYTheyTurnSelectWhatDirectionNow(
          name,
          Object.fromEntries(directionMappingTable)[startingPosition],
          Object.fromEntries(turnMappingTable)[turn],
          isClockwise ? translate.directions.clockwise() : translate.directions.antiClockwise()
        )}
        pdfTitle={translate.instructions.compassXIsFacingYTheyTurnCircleWhatDirectionNow(
          name,
          Object.fromEntries(directionMappingTable)[startingPosition],
          Object.fromEntries(turnMappingTable)[turn],
          isClockwise ? translate.directions.clockwise() : translate.directions.antiClockwise()
        )}
        selectables={Object.fromEntries(directionMappingTable)}
        correctAnswer={[answer.toString()]}
        questionHeight={1000}
        selectableTextStyle={{ fontWeight: '700' }}
        leftContent={
          <MeasureView>
            {dimens => (
              <AssetSvg
                name="Compass_4_labels"
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});
export const auO = Question3;

const Question4 = newQuestionContent({
  uid: 'auP',
  description: 'auP',
  keywords: ['Turns', 'Direction'],
  schema: z.object({
    startingPosition: numberEnum([0, 90, 180, 270]),
    name: nameSchema,
    turn: numberEnum([0, 90, 180, 270]),
    options: z
      .object({ string: z.enum(turns), isCorrect: z.boolean() })
      .array()
      .length(4)
  }),
  simpleGenerator: () => {
    const startingPosition = getRandomFromArray([0, 90, 180, 270] as const);
    const name = getRandomName();
    const turn = getRandomFromArray([0, 90, 180, 270] as const);

    const clockwiseIndex = (turn / 90) % 4;
    const antiClockwiseIndex = ((360 - turn) / 90) % 4;

    const correctOptions = [
      [
        'fullTurnClockwise',
        'quarterTurnClockwise',
        'halfTurnClockwise',
        'threeQuarterTurnClockwise'
      ][clockwiseIndex],
      [
        'fullTurnAntiClockwise',
        'quarterTurnAntiClockwise',
        'halfTurnAntiClockwise',
        'threeQuarterTurnAntiClockwise'
      ][antiClockwiseIndex]
    ];

    const incorrectOptions = getRandomSubArrayFromArray(
      [...turns.filter(val => !correctOptions.includes(val))] as const,
      2
    );

    const options = shuffle([
      ...correctOptions.map(val => ({ string: val as Turn, isCorrect: true })),
      ...incorrectOptions.map(val => ({ string: val as Turn, isCorrect: false }))
    ]);

    return { startingPosition, name, turn, options };
  },
  Component: props => {
    const {
      question: { startingPosition, name, turn, options },
      translate
    } = props;

    const directionMappingTable = [
      ['0', translate.directions.north()],
      ['90', translate.directions.east()],
      ['180', translate.directions.south()],
      ['270', translate.directions.west()]
    ];

    const endPosition = (startingPosition + turn) % 360;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.characterIsFacingXTheyMakeATurnAndFaceYSelectTurns(
          name,
          Object.fromEntries(directionMappingTable)[startingPosition],
          Object.fromEntries(directionMappingTable)[endPosition]
        )}
        pdfTitle={translate.instructions.characterIsFacingXTheyMakeATurnAndFaceYCircleTurns(
          name,
          Object.fromEntries(directionMappingTable)[startingPosition],
          Object.fromEntries(directionMappingTable)[endPosition]
        )}
        testCorrect={options.filter(val => val.isCorrect).map(val => val.string)}
        numItems={4}
        multiSelect
        renderItems={options.map(option => ({
          value: option.string,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center' }}>
              {translate.directions[option.string as Turn]()}
            </Text>
          )
        }))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'auQ',
  description: 'auQ',
  keywords: ['Turns', 'Analogue'],
  schema: z.object({
    minutes: numberEnum([15, 30, 45]),
    hours: z.number().int().min(0).max(11),
    addMins: numberEnum([15, 30, 45])
  }),
  simpleGenerator: () => {
    const minutes = getRandomFromArray([15, 30, 45] as const);
    const addMins = getRandomFromArray([15, 30, 45] as const);
    const hours = randomIntegerInclusive(0, 11);
    return { minutes, addMins, hours };
  },
  Component: props => {
    const {
      question: { minutes, addMins, hours },
      translate,
      displayMode
    } = props;

    const timeMapping = [
      [15, translate.time.quarterOfAnHour()],
      [30, translate.time.halfAnHour()],
      [45, translate.time.threeQuartersOfAnHour()]
    ];

    const [endHour, endMinute] = addDurationTo24hTime(hours, minutes, addMins);

    const options = shuffle(
      [
        addDurationTo24hTime(hours, minutes, 15),
        addDurationTo24hTime(hours, minutes, 30),
        addDurationTo24hTime(hours, minutes, 45),
        [hours, minutes]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.theClockShowsStartOfTVShowItFinishesXLaterSelectEndTime(
          Object.fromEntries(timeMapping)[addMins]
        )}
        pdfTitle={translate.instructions.theClockShowsStartOfTVShowItFinishesXLaterCircleEndTime(
          Object.fromEntries(timeMapping)[addMins]
        )}
        Content={({ dimens }) => (
          <Clock width={dimens.height * 0.6} time={{ hours, minutes }} interactive={false} />
        )}
        testCorrect={[displayDigitalTime(endHour, endMinute)]}
        numItems={4}
        itemLayout="row"
        itemStyle={{ height: displayMode === 'digital' ? 200 : 350 }}
        multiSelect
        questionHeight={1000}
        renderItems={({ dimens }) =>
          options.map(option => ({
            value: displayDigitalTime(option[0], option[1]),
            component: (
              <Clock
                width={dimens.height * 0.7}
                time={{ hours: option[0], minutes: option[1] }}
                interactive={false}
              />
            )
          }))
        }
      />
    );
  },
  questionHeight: 1000
});

// Reused by ayf
const Question6 = newQuestionContent({
  uid: 'auR',
  description: 'auR',
  keywords: ['Turn', 'Analogue'],
  schema: z
    .object({
      hours: z.number().int().min(1).max(12),
      minutes: z.number().int().min(5).max(55).step(5),
      addMins: numberEnum([15, 30, 45])
    })
    .refine(
      ({ hours, minutes, addMins }) => !(hours === 11 && minutes + addMins === 60),
      "Time to show cannot be 12 o'clock"
    ),
  questionHeight: 1200,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const hours = randomIntegerInclusive(1, 12);
        const minutes = randomIntegerInclusiveStep(5, 55, 5);
        const addMins = getRandomFromArray([15, 30, 45] as const);

        return { hours, minutes, addMins };
      },
      ({ hours, minutes, addMins }) => !(hours === 11 && minutes + addMins === 60)
    ),
  Component: ({ question: { hours, minutes, addMins }, translate, displayMode }) => {
    const hoursAnswer = minutes + addMins > 60 ? hours + 1 : hours;
    const hoursMod = hoursAnswer % 12;
    const minsAnswers = (minutes + addMins) % 60;

    const interactive = displayMode === 'digital' ? true : false;
    const showHands = displayMode === 'pdf' ? false : true;
    const initialState =
      displayMode === 'markscheme' ? { hours: hoursMod, minutes: minsAnswers } : undefined;

    const mappingTable = [
      ['15', translate.time.quarterOfAnHour()],
      ['30', translate.time.halfAnHour()],
      ['45', translate.time.threeQuartersOfAnHour()]
    ];

    return (
      <QF3Content
        title={translate.instructions.dragTheHandsOfClockToShowEndTime()}
        pdfTitle={translate.instructions.drawTheHandsOfClockToShowEndTime()}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Clock
              time={{ hours: hours, minutes: minutes }}
              interactive={false}
              width={Math.min(dimens.width * 0.25, dimens.height)}
            />
            <View style={{ width: dimens.width * 0.2 }}>
              <AssetSvg name="ArrowRightLong" width={dimens.width * 0.2} />
              <Text
                variant="WRN400"
                style={{ fontSize: displayMode === 'digital' ? 28 : 50, textAlign: 'center' }}
              >
                {translate.answerSentences.xLater(Object.fromEntries(mappingTable)[addMins])}
              </Text>
            </View>
            <ClockWithState
              id="interactiveClock"
              width={Math.min(dimens.width * 0.45, dimens.height)}
              testCorrect={answer => answer.hours === hoursMod && answer.minutes === minsAnswers}
              interactive={interactive}
              showHands={showHands}
              defaultState={initialState}
            />
          </View>
        )}
        questionHeight={1200}
      />
    );
  }
});
export const auR = Question6;

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TurnsAndAngles',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
