import { View } from 'react-native';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import { parseToSUB } from '../../../../utils/parse';
import { SequenceBoxesWithState } from '../../../../components/question/representations/SequenceBoxes';
import { numberEnum } from '../../../../utils/zod';
import { citiesSchema, getRandomUniqueColdCities } from '../../../../utils/cities';
import QF7InteractiveTable from '../../../../components/question/questionFormats/QF7InteractiveTable';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aCC',
  description: 'aCC',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    tickValues: z.array(z.number().min(-5).max(5)).length(6),
    answerIndexes: z.array(numberEnum([0, 1, 2, 3, 4, 5])).length(2)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([-1, -2, -3, -4, -5] as const);
    const answerIndexes = getRandomSubArrayFromArray([0, 1, 2, 3, 4, 5] as const, 2);
    const tickValues = range(startingNumber, startingNumber + 5);

    return { tickValues, answerIndexes };
  },
  Component: props => {
    const {
      question: { tickValues, answerIndexes },
      translate
    } = props;

    const [indexA, indexB] = sortNumberArray(answerIndexes);

    const formattedTickValues = tickValues.map((number, index) => {
      if (index === indexA || index === indexB) {
        return '<ans/>';
      }
      return parseToSUB(number.toLocaleString());
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={[
          parseToSUB(tickValues[indexA].toString()),
          parseToSUB(tickValues[indexB].toString())
        ]}
        answerPositions="bottom"
        extraSymbol="minus"
        tickValues={formattedTickValues}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aCD',
  description: 'aCD',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    startingNumber: numberEnum([-5, -6, -7, -8, -9, -10]),
    answerIndexes: z.array(z.number().int().min(0).max(10)).length(3)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([-5, -6, -7, -8, -9, -10] as const);

    const zeroIndex = Math.abs(startingNumber);

    const answerIndexes: number[] = [];

    countRange(3).forEach(_ =>
      answerIndexes.push(
        randomIntegerInclusive(0, 10, {
          constraint: x =>
            arrayHasNoDuplicates([...answerIndexes, x]) &&
            // Missing numbers should have no more than 1 positive number
            [...answerIndexes, x].filter(i => i >= zeroIndex).length <= 1
        })
      )
    );

    return { startingNumber, answerIndexes };
  },
  Component: props => {
    const {
      question: { startingNumber, answerIndexes },
      translate
    } = props;

    const tickValues = range(startingNumber, startingNumber + 10);

    const [indexA, indexB, indexC] = sortNumberArray(answerIndexes);

    const formattedTickValues = tickValues.map((number, index) => {
      if (index === indexA || index === indexB || index === indexC) {
        return '<ans/>';
      }
      return parseToSUB(number.toLocaleString());
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={[
          parseToSUB(tickValues[indexA].toString()),
          parseToSUB(tickValues[indexB].toString()),
          parseToSUB(tickValues[indexC].toString())
        ]}
        answerPositions="bottom"
        extraSymbol="minus"
        tickValues={formattedTickValues}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aCE',
  description: 'aCE',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    tickValues: z.array(z.number().min(-30).max(0)).length(11),
    answerIndexes: z.array(numberEnum([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])).length(3)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(-30, -10);
    const answerIndexes = getRandomSubArrayFromArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9] as const, 3);
    const tickValues = range(startingNumber, startingNumber + 10);

    return { tickValues, answerIndexes };
  },
  Component: props => {
    const {
      question: { tickValues, answerIndexes },
      translate
    } = props;

    const [indexA, indexB, indexC] = sortNumberArray(answerIndexes);

    const formattedTickValues = tickValues.map((number, index) => {
      if (index === indexA || index === indexB || index === indexC) {
        return '<ans/>';
      }
      return parseToSUB(number.toLocaleString());
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={[
          parseToSUB(tickValues[indexA].toString()),
          parseToSUB(tickValues[indexB].toString()),
          parseToSUB(tickValues[indexC].toString())
        ]}
        answerPositions="bottom"
        extraSymbol="minus"
        tickValues={formattedTickValues}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aCF',
  description: 'aCF',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    tickValues: z.array(z.number().min(-9).max(3)).length(11),
    answerIndex: numberEnum([3, 4, 5, 6, 7, 8])
  }),
  simpleGenerator: () => {
    const lastNumber = randomIntegerInclusive(1, 3);
    const answerIndex = getRandomFromArray([3, 4, 5, 6, 7, 8] as const);
    const tickValues = range(lastNumber - 10, lastNumber);

    return { tickValues, answerIndex };
  },
  Component: props => {
    const {
      question: { tickValues, answerIndex },
      translate
    } = props;

    const formattedTickValues = tickValues.map((number, index) => {
      if (index !== 0 && index !== 1 && index !== 10) {
        return null;
      }
      return number;
    });

    const number = tickValues[answerIndex];

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.fillInMissingNumberOnNumberLine()}
        testCorrect={[parseToSUB(tickValues[answerIndex].toString())]}
        freeNumberLineAnswer={[number]}
        extraSymbol="minus"
        tickValues={formattedTickValues}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aCG',
  description: 'aCG',
  keywords: ['Negative numbers', 'Number line'],
  schema: z.object({
    startingNumber: z.number().min(-20).max(2)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(-20, 2);

    return { startingNumber };
  },
  Component: props => {
    const {
      question: { startingNumber },
      translate,
      displayMode
    } = props;

    const sequence = range(startingNumber - 6, startingNumber);

    const answer = [sequence[3], sequence[4], sequence[5], sequence[6]];

    return (
      <QF3Content
        title={translate.instructions.workOutTheNextFourNumbersInTheSequence()}
        inputType="numpad"
        inputVariant="wide"
        actionPanelVariant="bottomTall"
        extraSymbol="minus"
        Content={({ dimens }) => (
          <View style={{ gap: 20 }}>
            <SequenceBoxesWithState
              id="seq1"
              boxes={[
                [
                  parseToSUB(sequence[0].toLocaleString()),
                  parseToSUB(sequence[1].toLocaleString()),
                  parseToSUB(sequence[2].toLocaleString()),
                  `<ans/>`,
                  `<ans/>`,
                  `<ans/>`,
                  `<ans/>`
                ]
              ]}
              dimens={dimens}
              testCorrect={userAnswer =>
                arraysHaveSameContents(
                  answer.map(el => parseToSUB(el.toString())),
                  userAnswer[0]
                )
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [answer.map(el => parseToSUB(el.toLocaleString()))]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aCH',
  description: 'aCH',
  keywords: ['Negative numbers', 'Temperature'],
  schema: z.object({
    cityAColderBy: z.number().int().min(4).max(9),
    cityBTemperature: z.number().int().min(-9).max(2),
    cityCTemperature: z.number().int().min(-3).max(5),
    cityDTemperature: z.number().int().min(-6).max(4),
    titleCityIndex: numberEnum([1, 2, 3]),
    cities: z.array(citiesSchema).length(4)
  }),
  simpleGenerator: () => {
    const cityAColderBy = randomIntegerInclusive(4, 9);
    const cityBTemperature = randomIntegerInclusive(-9, 2);
    const cityCTemperature = randomIntegerInclusive(-3, 5, {
      constraint: x => x !== cityBTemperature
    });
    const cityDTemperature = randomIntegerInclusive(-6, 4, {
      constraint: x => x !== cityBTemperature && x !== cityCTemperature
    });
    const titleCityIndex = getRandomFromArray([1, 2, 3] as const);
    const cities = getRandomUniqueColdCities(4);

    return {
      cityAColderBy,
      cityBTemperature,
      cityCTemperature,
      cityDTemperature,
      titleCityIndex,
      cities
    };
  },
  Component: props => {
    const {
      question: {
        cityAColderBy,
        cityBTemperature,
        cityCTemperature,
        cityDTemperature,
        titleCityIndex,
        cities
      },
      translate
    } = props;

    const cityA = cities[0];
    const cityB = cities[titleCityIndex];

    const answer =
      [cityBTemperature, cityCTemperature, cityDTemperature][titleCityIndex - 1] - cityAColderBy;

    return (
      <QF7InteractiveTable
        title={translate.instructions.theTempInXIsYDegreesColderThanZWhatIsTheTempInX(
          translate.cities[cityA](),
          cityAColderBy,
          translate.cities[cityB]()
        )}
        extraSymbol="minus"
        cellHeaders={cities.map(city => {
          return { label: translate.cities[city](), textStyle: { fontSize: 30 } };
        })}
        tableData={[
          [
            `${translate.units.numberOfDegreesC('<ans/>')}`,
            translate.units.numberOfDegreesC(parseToSUB(cityBTemperature.toLocaleString())),
            translate.units.numberOfDegreesC(parseToSUB(cityCTemperature.toLocaleString())),
            translate.units.numberOfDegreesC(parseToSUB(cityDTemperature.toLocaleString()))
          ]
        ]}
        testCorrect={[parseToSUB(answer.toString())]}
        questionHeight={800}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountThroughZeroIn1s',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
