import { useContext, useEffect, useMemo } from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { Gesture, GestureDetector, PanGesture } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  SharedValue,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated';
import { ScaleFactorContext } from '../../../theme/scaling';
import { withStateHOC } from '../../../stateTree';
import { noop } from '../../../utils/flowControl';
import { Circle, Line, Svg, type SvgProps } from 'react-native-svg';
import { useTheme } from '../../../theme';
import { SetState, projectSetState } from '../../../utils/react';
import { DEGREES_PER_RAD, RADS_PER_DEGREE, describeArc } from '../../../utils/angles';
import { colors } from '../../../theme/colors';
import { AssetSvg } from '../../../assets/svg';
import { AnimatedPath } from '../../atoms/animatedWrappings';

export type Variants = 'fullHeight' | 'halfHeight' | 'specialProtractor';

function getConstants(variant: Variants) {
  const ARC_RADIUS = 50;
  let LONG_HAND_LENGTH, SHORT_HAND_LENGTH, HANDLE_WIDTH, HANDLE_VISIBLE_WIDTH, WIDTH, HEIGHT;
  switch (variant) {
    case 'fullHeight':
      LONG_HAND_LENGTH = 200;
      SHORT_HAND_LENGTH = 110;
      HANDLE_WIDTH = 91;
      HANDLE_VISIBLE_WIDTH = 54;
      WIDTH = (LONG_HAND_LENGTH + HANDLE_WIDTH / 2) * 2;
      HEIGHT = WIDTH;
      break;
    case 'halfHeight':
      LONG_HAND_LENGTH = 300;
      SHORT_HAND_LENGTH = 210;
      HANDLE_WIDTH = 91;
      HANDLE_VISIBLE_WIDTH = 54;
      WIDTH = (LONG_HAND_LENGTH + HANDLE_WIDTH / 2) * 2;
      HEIGHT = WIDTH / 2;
      break;
    case 'specialProtractor':
      LONG_HAND_LENGTH = 252;
      SHORT_HAND_LENGTH = 252;
      HANDLE_WIDTH = 91;
      HANDLE_VISIBLE_WIDTH = 54;
      WIDTH = (LONG_HAND_LENGTH + HANDLE_WIDTH / 2) * 2;
      HEIGHT = WIDTH;
      break;
  }

  return {
    LONG_HAND_LENGTH,
    SHORT_HAND_LENGTH,
    HANDLE_WIDTH,
    HANDLE_VISIBLE_WIDTH,
    WIDTH,
    HEIGHT,
    ARC_RADIUS
  };
}

export type AngleFromMovableLinesProps = {
  degrees?: [number, number];
  onDegreesChanged?: SetState<[number, number]>;
  /**
   * Whether to allow reflex angles.
   * This has a few effects:
   * - If false, the handles are clamped to the upper half-plane.
   * - If true, the angle formed is always the angle clockwise from the long hand to the short hand, which may be
   *   reflex. This is used for:
   *   - Drawing the arc
   * Default: false
   */
  allowReflexAngles?: boolean;
  protractor?: boolean;
  /**
   * Whether to fix the small arm in initial position.
   * Default: false
   */
  smallArmFixed?: boolean;
  /**
   * Variant of component to use.
   * Note: This will change the dimensions of the moveable lines.
   * Default: 'halfHeight'
   */
  variant?: Variants;
};

/**
 * Manipulative of two lines ("hands") forming an angle.
 *
 * This is a controlled component. However, {@link AngleFromMovableLinesProps.onDegreesChanged} is only called when
 * the user releases a hand, finishing the gesture.
 */
export default function AngleFromMovableLines({
  degrees = [0, 0],
  onDegreesChanged = noop,
  allowReflexAngles = false,
  protractor = false,
  smallArmFixed = false,
  variant = 'halfHeight'
}: AngleFromMovableLinesProps) {
  // Implementation note: internally we refer to the two handles as long hand a short hand.
  const { WIDTH, HEIGHT, ARC_RADIUS } = getConstants(variant);

  const scaleFactor = useContext(ScaleFactorContext);

  // Animated value for the degrees to display
  const animatedLongHandDegrees = useSharedValue(degrees[0]);
  const animatedShortHandDegrees = useSharedValue(degrees[1]);

  // If `degrees` changes from outside, update the animated value
  useEffect(() => {
    animatedLongHandDegrees.value = degrees[0];
    animatedShortHandDegrees.value = degrees[1];
  }, [animatedLongHandDegrees, animatedShortHandDegrees, degrees]);

  // Gesture handlers for dragging on the hands' handles - updates animated value
  const longHandGesture = useHandGesture(
    'long',
    animatedLongHandDegrees,
    projectSetState(onDegreesChanged, 0),
    scaleFactor,
    !allowReflexAngles,
    variant
  );
  const shortHandGesture = useHandGesture(
    'short',
    animatedShortHandDegrees,
    projectSetState(onDegreesChanged, 1),
    scaleFactor,
    !allowReflexAngles,
    variant
  );

  // Styles using animated value to show the correct angle
  const longHandRotationStyle = useAnimatedStyle(
    () => ({
      transform: [
        { translateY: WIDTH / 4 },
        { rotate: `${RADS_PER_DEGREE * animatedLongHandDegrees.value}rad` },
        { translateY: -WIDTH / 4 }
      ]
    }),
    [WIDTH, animatedLongHandDegrees]
  );
  const shortHandRotationStyle = useAnimatedStyle(
    () => ({
      transform: [
        { translateY: WIDTH / 4 },
        { rotate: `${RADS_PER_DEGREE * animatedShortHandDegrees.value}rad` },
        { translateY: -WIDTH / 4 }
      ]
    }),
    [WIDTH, animatedShortHandDegrees]
  );
  const arcPath = useAnimatedProps(() => {
    let startAngle = smallArmFixed ? animatedShortHandDegrees.value : animatedLongHandDegrees.value;
    let endAngle = smallArmFixed ? animatedLongHandDegrees.value : animatedShortHandDegrees.value;
    // this is the same as using mod from mathjs. Have extracted the maths since mod() caused an error on ios
    const modAngle = endAngle - startAngle - 360 * Math.floor((endAngle - startAngle) / 360);
    // describeArc sweeps clockwise from start angle to end angle.
    // If allowReflexAngles is true, this behaviour is always correct - sweep clockwise from long hand to short hand.
    // If allowReflexAngles is false, we always want to sweep the shortest angle.
    if (!allowReflexAngles && modAngle > 180) {
      // Swap them
      [startAngle, endAngle] = [endAngle, startAngle];
    }
    return {
      d: describeArc(WIDTH / 2, WIDTH / 2, ARC_RADIUS, startAngle, endAngle)
    };
  }, [
    ARC_RADIUS,
    WIDTH,
    allowReflexAngles,
    animatedLongHandDegrees,
    animatedShortHandDegrees,
    smallArmFixed
  ]);

  return (
    <View style={{ width: WIDTH, height: HEIGHT }}>
      {protractor && (
        <AssetSvg
          name={'Protractor180'}
          width={WIDTH * 0.9}
          height={HEIGHT * 0.9}
          style={{ position: 'absolute', top: HEIGHT * 0.177, left: WIDTH * 0.05 }}
        />
      )}
      {/* Arc indicating the angle */}
      <Svg width={WIDTH} height={HEIGHT} style={StyleSheet.absoluteFill}>
        <AnimatedPath
          animatedProps={arcPath}
          fill="none"
          stroke={colors.prussianBlue}
          strokeWidth={2}
        />
      </Svg>
      {/* Hands */}
      <Animated.View
        style={[
          {
            position: 'absolute',
            top: 0,
            alignSelf: 'center',
            zIndex: 11
          },
          longHandRotationStyle,
          variant === 'specialProtractor' && { zIndex: 999 }
        ]}
        pointerEvents="box-none"
      >
        <HandSvg kind="long" variant={variant} />
        <HandGestureDetector kind="long" gesture={longHandGesture} variant={variant} />
      </Animated.View>
      <Animated.View
        style={[
          {
            position: 'absolute',
            top: 0,
            alignSelf: 'center',
            zIndex: 12
          },
          shortHandRotationStyle
        ]}
        pointerEvents="box-none"
      >
        <HandSvg kind="short" isFixed={smallArmFixed} variant={variant} />
        {!smallArmFixed && (
          <HandGestureDetector kind="short" gesture={shortHandGesture} variant={variant} />
        )}
      </Animated.View>
    </View>
  );
}

/**
 * Gesture handler for either the long or short hand. Pulled out into a separate function since they share a lot
 * of code.
 */
function useHandGesture(
  type: 'short' | 'long',
  animatedDegrees: SharedValue<number>,
  onDegreesChanged: (degrees: number) => void = noop,
  scaleFactor: number,
  clampedToUpperHalf: boolean,
  variant: Variants
) {
  const { LONG_HAND_LENGTH, SHORT_HAND_LENGTH, HANDLE_WIDTH } = getConstants(variant);

  const startPositionFromAnglePointCenter = useSharedValue<{ x: number; y: number } | null>(null);
  const handleOffset = type === 'long' ? LONG_HAND_LENGTH : SHORT_HAND_LENGTH;

  // Used to calculate translationX/Y since we don't trust those values from the event. (Scaled.)
  const beginAbsX = useSharedValue<number | null>(null);
  const beginAbsY = useSharedValue<number | null>(null);

  return useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginAbsX.value = event.absoluteX;
          beginAbsY.value = event.absoluteY;

          let { x, y } = event;
          if (Platform.OS === 'web') {
            // On web these values are post-scaling-transformation, so unscale to get back to the component's coordinate
            // system
            x /= scaleFactor;
            y /= scaleFactor;
          }

          // Work out where the start position (as given by this event) is relative to the angle center, and store it
          // off in the context.
          // To do this, we use the following vectors:
          // 1) angle center -> handle center
          // 2) origin -> handle center
          // 3) origin -> start position
          // where the origin is (0, 0) in the coordinate system of the event.

          // For (1), we calculate using the handle's rotation around the angle's center and its offset.
          const handleAngle = RADS_PER_DEGREE * animatedDegrees.value;
          const handleCenterFromAnglePointCenter = {
            x: Math.sin(handleAngle) * handleOffset,
            y: -(Math.cos(handleAngle) * handleOffset) // Negative because, in canvas coordinates, y points down.
          };

          // For (2), it stands to reason that the origin is the top left of the handle, which is square.
          // However, the handle may have been rotated, and in that case the coordinates actually originate from the top
          // left of the *smallest unrotated square that bounds the handle*. This can be easily calculated from the
          // handle's width and rotation, and the handle's center is in the middle of this box.
          const boundingBoxWidth =
            HANDLE_WIDTH * (Math.abs(Math.sin(handleAngle)) + Math.abs(Math.cos(handleAngle)));
          const handleCenterFromOrigin = {
            x: boundingBoxWidth / 2,
            y: boundingBoxWidth / 2
          };

          // For (3), this is easy - it's just the event's x/y coordinates by definition!
          const startPositionFromOrigin = { x, y };

          // Now we use vector addition/subtraction to get the vector we want.
          startPositionFromAnglePointCenter.value = {
            x:
              handleCenterFromAnglePointCenter.x -
              handleCenterFromOrigin.x +
              startPositionFromOrigin.x,
            y:
              handleCenterFromAnglePointCenter.y -
              handleCenterFromOrigin.y +
              startPositionFromOrigin.y
          };
        })

        .onUpdate(event => {
          // Calculate translationX/Y manually rather than using the one from the event, since the one from the event
          // is the diff since the second onUpdate, which is wrong because the pointer might have already moved before
          // then!
          const translationX = (event.absoluteX - beginAbsX.value!) / scaleFactor;
          const translationY = (event.absoluteY - beginAbsY.value!) / scaleFactor;

          const currentPositionFromAnglePointCenter = {
            x: startPositionFromAnglePointCenter.value!.x + translationX,
            y: startPositionFromAnglePointCenter.value!.y + translationY
          };

          let angleToCurrentPosition = Math.atan2(
            currentPositionFromAnglePointCenter.x,
            -currentPositionFromAnglePointCenter.y // Negative because, in canvas coordinates, y points down.
          );

          if (angleToCurrentPosition < -Math.PI) {
            // Keep angle between -pi and pi, equivalent to -180 and 180. This covers every possible angle.
            angleToCurrentPosition += 2 * Math.PI;
          }

          let newValue = DEGREES_PER_RAD * angleToCurrentPosition;
          if (clampedToUpperHalf) {
            // Keep angle between -90 and 90. This covers just the upper half of the possible angles.
            newValue = Math.min(Math.max(newValue, -90), 90);
          }
          animatedDegrees.value = newValue;
        })

        .onFinalize(() => {
          // The gesture has finished. Update ancestors with new angle.
          runOnJS(onDegreesChanged)(animatedDegrees.value);
        }),
    [
      HANDLE_WIDTH,
      animatedDegrees,
      beginAbsX,
      beginAbsY,
      clampedToUpperHalf,
      handleOffset,
      onDegreesChanged,
      scaleFactor,
      startPositionFromAnglePointCenter
    ]
  );
}

function HandSvg({
  kind,
  isFixed = false,
  variant,
  ...props
}: SvgProps & {
  kind: 'long' | 'short';
  isFixed?: boolean;
  variant: Variants;
}) {
  const { LONG_HAND_LENGTH, SHORT_HAND_LENGTH, HANDLE_VISIBLE_WIDTH, WIDTH } =
    getConstants(variant);

  const theme = useTheme();

  return (
    <View style={{ width: WIDTH, height: WIDTH / 2 }} pointerEvents="none">
      <Svg width={WIDTH} height={WIDTH / 2} stroke="black" fill={theme.colors.tertiary} {...props}>
        <Line
          x1={WIDTH / 2}
          x2={WIDTH / 2}
          y1={WIDTH / 2}
          y2={WIDTH / 2 - (kind === 'long' ? LONG_HAND_LENGTH : SHORT_HAND_LENGTH)}
          strokeWidth={3.466}
        />
        {!(variant === 'specialProtractor' && kind === 'short') && (
          <Circle
            r={HANDLE_VISIBLE_WIDTH / 2}
            cx={WIDTH / 2}
            cy={WIDTH / 2 - (kind === 'long' ? LONG_HAND_LENGTH : SHORT_HAND_LENGTH)}
            strokeWidth={2.516}
            fill={isFixed ? colors.grey : undefined}
          />
        )}
      </Svg>
    </View>
  );
}

function HandGestureDetector({
  kind,
  gesture,
  variant
}: {
  kind: 'long' | 'short';
  gesture: PanGesture;
  variant: Variants;
}) {
  const { LONG_HAND_LENGTH, SHORT_HAND_LENGTH, HANDLE_WIDTH, WIDTH } = getConstants(variant);

  return (
    <View
      style={{ position: 'absolute', width: WIDTH, height: WIDTH / 2, pointerEvents: 'box-none' }}
      pointerEvents="box-none"
    >
      {/* Touchable rectangle */}
      <GestureDetector gesture={gesture}>
        <View
          style={{
            width: HANDLE_WIDTH,
            height: HANDLE_WIDTH,
            position: 'absolute',
            left: WIDTH / 2 - HANDLE_WIDTH / 2,
            top:
              WIDTH / 2 -
              (kind === 'long' ? LONG_HAND_LENGTH : SHORT_HAND_LENGTH) -
              HANDLE_WIDTH / 2,
            zIndex: 99
          }}
        />
      </GestureDetector>
    </View>
  );
}

export const AngleFromMovableLinesWithState = withStateHOC(AngleFromMovableLines, {
  stateProp: 'degrees',
  setStateProp: 'onDegreesChanged',
  defaults: {
    defaultState: [0, 0]
  }
});
