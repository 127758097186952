import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import { ScientificNotation, compareFloats } from '../../../../utils/math';
import { SUB } from '../../../../constants';
import { all, create, number } from 'mathjs';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF27MissingDigitColumnOperations, {
  getDecimalMissingDigits,
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aBW',
  description: 'aBW',
  keywords: ['Place value chart', 'Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().int().min(3).max(8),
    number2: z.number().int().min(3).max(8),
    number3: z.number().int().min(3).max(8),
    number5: z.number().int().min(1).max(7),
    number6: z.number().int().min(1).max(7),
    number7: z.number().int().min(1).max(7),
    questionToShow: z.enum(['a', 'b'])
  }),
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b'] as const);
    const number1 = randomIntegerInclusive(3, 8);
    const number2 = randomIntegerInclusive(3, 8);
    const number3 = randomIntegerInclusive(3, 8);
    const number5 = randomIntegerInclusive(1, number1 - 1);
    const number6 = randomIntegerInclusive(1, number2 - 1);
    const number7 = randomIntegerInclusive(1, number3 - 1);

    return {
      number1,
      number2,
      number3,
      number5,
      number6,
      number7,
      questionToShow
    };
  },
  Component: ({
    question: { number1, number2, number3, number5, number6, number7, questionToShow },
    translate
  }) => {
    const questionNumberA = number(
      math.evaluate(`${number1} + ${number2} / 10 + ${number3} / 100`)
    );
    const questionNumberB =
      questionToShow === 'a'
        ? number(math.evaluate(`${number6} / 10 + ${number7} / 100`))
        : number(math.evaluate(`${number5} + ${number6} / 10`));

    const correctAnswer = number(math.evaluate(`${questionNumberA} - ${questionNumberB}`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeTheSubtractionUseThePlaceValueChartToHelp()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(questionNumberA)}
            columnsToShow={[0, -1, -2]}
            counterVariant={'greyCounter'}
            dimens={{ height: dimens.height, width: dimens.width * 0.8 }}
          />
        )}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        sentence={`${questionNumberA.toLocaleString()} ${SUB} ${questionNumberB.toLocaleString()} = <ans/>`}
        testCorrect={answer => compareFloats(answer[0], correctAnswer)}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'aBX',
  description: 'aBX',
  keywords: ['Column subtraction', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      number1: z.number().int().min(201).max(999),
      number2: z.number().int().min(11).max(99)
    })
    .refine(
      val => val.number1 > 10 * val.number2,
      'number1 must be more than 10 times bigger than number2'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(201, 999, {
      constraint: x => x % 10 !== 0
    });
    const number2 = randomIntegerInclusive(11, 99, {
      constraint: x => x % 10 !== 0 && x < number1 / 10
    });

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const decimal1 = number(math.evaluate(`${number1}/100`));
    const decimal2 = number(math.evaluate(`${number2}/10`));
    const correctAnswer = number(math.evaluate(`${decimal1}-${decimal2}`));

    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutSubtraction()}
        topNumber={decimal1}
        bottomNumber={decimal2}
        operation={SUB}
        answerNumber={correctAnswer}
        answerMissingDigits={answerMissingDigits}
        persistExchangeBoxes
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aBY',
  description: 'aBY',
  keywords: ['Column subtraction', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      number1: z.number().min(21).max(99),
      number2: z.number().min(101).max(989)
    })
    .refine(val => 10 * val.number1 > val.number2, 'ten times number1 must be more than number2'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 99, {
      constraint: x => x % 10 !== 0
    });
    const number2 = randomIntegerInclusive(101, 989, {
      constraint: x => x % 10 !== 0 && x < number1 * 10
    });

    return {
      number1,
      number2
    };
  },
  Component: ({ question: { number1, number2 }, translate }) => {
    const decimal1 = number(math.evaluate(`${number1}/10`));
    const decimal2 = number(math.evaluate(`${number2}/100`));
    const correctAnswer = number(math.evaluate(`${decimal1}-${decimal2}`));
    const answerMissingDigits = getDecimalMissingDigits(correctAnswer, 2);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.useColumnMethodToWorkOutSubtraction()}
        topNumber={decimal1}
        bottomNumber={decimal2}
        operation={SUB}
        answerNumber={correctAnswer}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(correctAnswer, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aBZ',
  description: 'aBZ',
  keywords: ['Column subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().min(0.11).max(99.9),
    number2: z.number().min(0.11).max(9.99).multipleOf(0.01)
  }),
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b', 'c'] as const);

    const number1 =
      questionToShow === 'a'
        ? randomIntegerInclusive(1001, 1999, {
            constraint: x => x % 10 !== 0
          }) / 100
        : questionToShow === 'b'
        ? randomIntegerInclusive(21, 99, {
            constraint: x => x % 10 !== 0
          }) / 10
        : randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 10;

    const number2 =
      questionToShow === 'a'
        ? randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 100
        : questionToShow === 'b'
        ? randomIntegerInclusive(11, number1 * 100 - 1, {
            constraint: x => x % 10 !== 0
          }) / 100
        : randomIntegerInclusive(101, 999, {
            constraint: x => x % 10 !== 0
          }) / 100;

    return {
      number1,
      number2
    };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const [sentence, answer] = [
      `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`,
      number(math.evaluate(`${number1} - ${number2}`))
    ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheSubtraction()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={6}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aB0',
  description: 'aB0',
  keywords: ['Column subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().min(10.1).max(49.9).multipleOf(0.1),
    number2: z.number().min(1.11).max(9.99).multipleOf(0.01)
  }),
  simpleGenerator: () => {
    const number1 =
      randomIntegerInclusive(101, 499, {
        constraint: x => x % 10 !== 0
      }) / 10;

    const number2 =
      randomIntegerInclusive(111, 999, {
        constraint: x => x % 10 !== 0
      }) / 100;

    return {
      number1,
      number2
    };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number(math.evaluate(`${number1} - ${number2}`));

    const bottomMissingDigits = getDecimalMissingDigits(number2, 2);
    const botttomMarkSchemeAns = getMarkSchemeAnswer(number2, bottomMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        operation={SUB}
        topNumber={number1}
        topMissingDigits={[0]}
        bottomNumber={number2}
        bottomMissingDigits={[-2]}
        answerNumber={number3}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(number1, number1.toString().length),
            bottom: botttomMarkSchemeAns
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aB1',
  description: 'aB1',
  keywords: ['Column subtraction', 'Tenths', 'Hundredths', 'Integers'],
  schema: z.object({
    number1: z.number().int().min(10).max(29),
    number2: z.number().min(0.11).max(9.999)
  }),
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b', 'c'] as const);
    const number1 = randomIntegerInclusive(10, 29);

    const number2 =
      questionToShow === 'a'
        ? randomIntegerInclusive(11, 99, {
            constraint: x => x % 10 !== 0
          }) / 10
        : questionToShow === 'b'
        ? randomIntegerInclusive(11, 999, {
            constraint: x => x % 10 !== 0
          }) / 100
        : randomIntegerInclusive(1111, 9999, {
            constraint: x => x % 10 !== 0
          }) / 1000;

    return {
      number1,
      number2
    };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const [sentence, answer] = [
      `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`,
      number(math.evaluate(`${number1} - ${number2}`))
    ];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheSubtraction()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={6}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractDecimalsWithDifferentNumbersOfDecimalPlaces',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
