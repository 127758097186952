// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import ApplyNumberBondsWithin10 from './1ApplyNumberBondsWithin10';
import AddAndSubtract1s from './2AddAndSubtract1s';
import AddAndSubtract10s from './3AddAndSubtract10s';
import AddAndSubtract100s from './4AddAndSubtract100s';
import SpotThePattern from './5SpotThePattern';
import Add1sAcrossA10 from './6Add1sAcrossA10';
import Add10sAcrossA100 from './7Add10sAcrossA100';
import Subtract1sAcrossA10 from './8Subtract1sAcrossA10';
import Subtract10sAcrossA100 from './9Subtract10sAcrossA100';
import MakeConnections from './10MakeConnections';
import AddTwoNumbersNoExchange from './11AddTwoNumbersNoExchange';
import SubtractTwoNumbersNoExchange from './12SubtractTwoNumbersNoExchange';
import AddTwoNumbersAcrossA10 from './13AddTwoNumbersAcrossA10';
import AddTwoNumbersAcrossA100 from './14AddTwoNumbersAcrossA100';
import SubtractTwoNumbersAcrossA10 from './15SubtractTwoNumbersAcrossA10';
import SubtractTwoNumbersAcrossA100 from './16SubtractTwoNumbersAcrossA100';
import Add2DigitAnd3DigitNumbers from './17Add2DigitAnd3DigitNumbers';
import SubtractA2DigitNumberFromA3DigitNumber from './18SubtractA2DigitNumberFromA3DigitNumber';
import ComplementsTo100 from './19ComplementsTo100';
import EstimateAnswers from './20EstimateAnswers';
import InverseOperations from './21InverseOperations';
import MakeDecisions from './22MakeDecisions';

const Block = newBlockContent({
  block: 'AdditionAndSubtraction',
  field: 'Number',
  weeks: [4, 8],
  smallSteps: [
    ApplyNumberBondsWithin10, // Step 1
    AddAndSubtract1s, // Step 2
    AddAndSubtract10s, // Step 3
    AddAndSubtract100s, // Step 4
    SpotThePattern, // Step 5
    Add1sAcrossA10, // Step 6
    Add10sAcrossA100, // Step 7
    Subtract1sAcrossA10, // Step 8
    Subtract10sAcrossA100, // Step 9
    MakeConnections, // Step 10
    AddTwoNumbersNoExchange, // Step 11
    SubtractTwoNumbersNoExchange, // Step 12
    AddTwoNumbersAcrossA10, // Step 13
    AddTwoNumbersAcrossA100, // Step 14
    SubtractTwoNumbersAcrossA10, // Step 15
    SubtractTwoNumbersAcrossA100, // Step 16
    Add2DigitAnd3DigitNumbers, // Step 17
    SubtractA2DigitNumberFromA3DigitNumber, // Step 18
    ComplementsTo100, // Step 19
    EstimateAnswers, // Step 20
    InverseOperations, // Step 21
    MakeDecisions // Step 22
  ]
});
export default Block;
