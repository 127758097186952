import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  getRandomFromArray,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  shuffle,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import { multiplesNumberTrackArray } from '../../../../utils/multiples';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF31NumberGridInteractive from '../../../../components/question/questionFormats/QF31NumberGridInteractive';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

export const Question1 = newQuestionContent({
  uid: 'ajQ',
  description: 'ajQ',
  keywords: ['Number line', 'Multiple', '2'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(88).multipleOf(2),
    secondOrThirdNumberGiven: z.enum(['second', 'third'])
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(0, 88, 2);

    const secondOrThirdNumberGiven = getRandomFromArray(['second', 'third'] as const);

    return { startingNumber, secondOrThirdNumberGiven };
  },
  Component: props => {
    const {
      question: { startingNumber, secondOrThirdNumberGiven },
      translate
    } = props;

    const endNumber = startingNumber + 10;

    const answerInterval = 2;

    const choices = range(
      startingNumber + answerInterval,
      endNumber - answerInterval,
      answerInterval
    );

    /* We want the answers to not be the first or last value on the line,
     * also have two values that are consecutive and third that isn't.
     * Since this only leaves 4 numbers that are selectable here there are only
     * 2 choices variants we can chose here. */
    const numbers =
      secondOrThirdNumberGiven === 'second'
        ? [choices[0], choices[2], choices[3]]
        : [choices[0], choices[1], choices[3]];

    const tickInterval = 1;
    const labelInterval = 2;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map(number => {
      return number % labelInterval ? '' : number.toLocaleString();
    });

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ajR',
  description: 'ajR',
  keywords: ['Multiple', 'Track', '2'],
  schema: z.object({
    interval: numberEnum([-2, 2]),
    startingNumber: z.number().int().min(2).max(38).multipleOf(2),
    tileToShow: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray([-2, 2] as const);
    const startingNumber = 20 - interval * randomIntegerInclusive(0, 9);
    const tileToShow = randomIntegerInclusive(2, 9);
    return { interval, startingNumber, tileToShow };
  },
  Component: ({ question: { interval, startingNumber, tileToShow }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(
      startingNumber,
      interval,
      8,
      tileToShow
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ajS',
  description: 'ajS',
  keywords: ['Multiple', '2', '100 square'],
  schema: z.object({
    startNumber: numberEnum([1, 11, 21, 31, 41])
  }),
  simpleGenerator: () => {
    const startNumber = getRandomFromArray([1, 11, 21, 31, 41] as const);

    return { startNumber };
  },
  Component: props => {
    const {
      question: { startNumber },
      translate
    } = props;
    return (
      <QF31NumberGridInteractive
        startNumber={startNumber}
        finishNumber={startNumber + 49}
        title={translate.instructions.selectAllMultiplesOfNum(2)}
        pdfTitle={translate.instructions.shadeMultiplesOfX(2)}
        testCorrect={range(startNumber, startNumber + 49).filter(number => number % 2 === 0)}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'ajT',
  description: 'ajT',
  keywords: ['Multiple', '2'],
  schema: z
    .object({
      numberArray: z.number().int().min(0).max(20).array().length(8),
      name: nameSchema
    })
    .refine(val => {
      const evenNums = val.numberArray.filter(number => number % 2 === 0);
      return evenNums.length > 1 && evenNums.length < 7;
    }, 'numberArray must contain at least 2 even numbers and 2 odd numbers.')
    .refine(
      val => arrayHasNoDuplicates(val.numberArray),
      'All numbers in numberArray must be different.'
    ),
  simpleGenerator: () => {
    // Create two even numbers.
    const [number1, number2] = randomUniqueIntegersInclusiveStep(0, 20, 2, 2);

    // Create two odd numbers.
    const [number3, number4] = randomUniqueIntegersInclusiveStep(1, 19, 2, 2);

    // Create four other numbers.
    const [number5, number6, number7, number8] = randomUniqueIntegersInclusive(0, 20, 4, {
      constraint: x => x !== number1 && x !== number2 && x !== number3 && x !== number4
    });

    const numberArray = shuffle([
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8
    ]);

    const name = getRandomName();

    return { numberArray, name };
  },
  Component: props => {
    const {
      question: { numberArray, name },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.characterCountsUpIn2sFromZero(name)}
        pdfTitle={translate.instructions.characterCountsUpIn2sFromZeroPDF(name)}
        testCorrect={numberArray.filter(it => it % 2 === 0)}
        items={numberArray.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'ajU',
  description: 'ajU',
  keywords: ['Multiple', '2', 'Even', 'Odd'],
  schema: z.object({
    numbers: z
      .array(z.number().int().min(0).max(100))
      .length(8)
      .refine(
        numbers => numbers.filter(x => x % 2 === 0).length >= 2,
        'At least 2 numbers are even'
      )
      .refine(numbers => numbers.filter(x => x % 2 !== 0).length >= 2, 'At least 2 numbers are odd')
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const even = randomUniqueIntegersInclusive(0, 100, 2, {
      constraint: x => x % 2 === 0
    });
    const odd = randomUniqueIntegersInclusive(0, 100, 2, {
      constraint: x => x % 2 !== 0
    });

    let numbers = [...even, ...odd];

    const fillers = randomUniqueIntegersInclusive(0, 100, 4, {
      constraint: x => !numbers.includes(x)
    });
    numbers = numbers.concat(fillers);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    const even = numbers.filter(x => x % 2 === 0);
    const odd = numbers.filter(x => x % 2 !== 0);

    const correctOrder = [even, odd];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortNumbersIntoTheTable()}
        pdfTitle={translate.instructions.sortNumbersPDF()}
        testCorrect={correctOrder}
        zoneNames={[translate.instructions.evenNumbers(), translate.instructions.oddNumbers()]}
        items={numbers}
        questionHeight={800}
        pdfItemVariant="pdfSquare"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ajV',
  description: 'ajV',
  keywords: ['2', 'Multiple'],
  schema: z
    .object({
      digits: z.array(z.number().int().min(0).max(9)).length(5)
    })
    .refine(x => x.digits.filter(i => i % 2 === 0).length === 1, 'There must be 1 even digit'),
  simpleGenerator: () => {
    const odd = randomUniqueIntegersInclusive(0, 9, 4, {
      constraint: x => x % 2 !== 0
    });
    const even = randomIntegerInclusive(0, 9, {
      constraint: x => x % 2 === 0
    });

    const digits = [...odd, even];

    return { digits };
  },
  Component: props => {
    const {
      question: { digits },
      translate
    } = props;

    const testCorrect = (ans: readonly (string | undefined)[]): boolean => {
      // Mark as wrong if first digit of created number is 0.
      if (ans[0] === '0') return false;

      const userNumber = parseInt(ans.join(''));
      return userNumber % 2 === 0;
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToMakeMultipleOfX(2)}
        pdfTitle={translate.instructions.useCardsToMakeMultipleOfX(2)}
        sentence={'<ans/> '.repeat(3)}
        items={digits.map(num => num.toLocaleString())}
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyMultipleOfXUsingAvailCards(2)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplesOf2',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
