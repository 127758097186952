import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { MarkupAssets } from '../../../../markup';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import { arraysHaveSameContents, range } from '../../../../utils/collections';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import {
  algebraicSymbolSchema,
  getAlgebraicSymbol,
  getAlgebraicSymbolPair
} from '../../../../utils/algebraicSymbols';
import { ADD, DIV, SUB } from '../../../../constants';
import ContentBox from '../../../../components/molecules/ContentBox';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import { LabelledQuadrilateral } from '../../../../components/question/representations/LabelledQuadrilateral';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aUe',
  description: 'aUe',
  keywords: ['Formula', 'Equation'],
  schema: z.object({
    symbol: algebraicSymbolSchema,
    cubes: z.number().int().min(2).max(9),
    cubeValue: z.number().int().min(2).max(10),
    cubeColour: z.enum(['blue', 'green', 'red', 'yellow'])
  }),
  simpleGenerator: () => {
    const cubes = randomIntegerInclusive(2, 9);
    const cubeValue = randomIntegerInclusive(2, 10);
    const symbol = getAlgebraicSymbol();
    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    return { cubes, cubeValue, symbol, cubeColour };
  },
  Component: props => {
    const {
      question: { cubes, cubeValue, symbol, cubeColour },
      translate
    } = props;
    const total = cubes * cubeValue;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;
    const arrayOfCubes = range(1, cubes).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cube_${i}`} />
    ));

    const selectables = shuffle(
      [
        ['A', `${cubes.toLocaleString()}${symbol} = ${total.toLocaleString()}`],
        ['B', `${total.toLocaleString()}${symbol} = ${cubes.toLocaleString()}`],
        ['C', `${symbol} ${ADD} ${cubes.toLocaleString()} = ${total.toLocaleString()}`],
        ['D', `${total.toLocaleString()} ${ADD} ${symbol} = ${(total + cubes).toLocaleString()}`]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <MarkupAssets
        elements={{
          cube: <AssetSvg key="cube" name={cubesSvgName} height={70} />
        }}
      >
        <QF39ContentWithSelectablesOnRight
          title={translate.instructions.selectEquationRepresentedByCubes()}
          pdfTitle={translate.instructions.circleEquationRepresentedByCubes()}
          leftContent={
            <MeasureView>
              {dimens => (
                <View style={{ ...dimens, alignItems: 'center' }}>
                  <ContentBox
                    containerStyle={{
                      flexDirection: 'row',
                      alignSelf: 'flex-start',
                      marginBottom: 100
                    }}
                  >
                    <TextStructure sentence={`<asset name='cube'/> = ${symbol}`} />
                  </ContentBox>
                  <View>
                    <View style={{ width: arrayOfCubes.length * 75 }}>
                      <BarModelCurlyBrace braceText={total.toLocaleString()} />
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 5
                      }}
                    >
                      {arrayOfCubes}
                    </View>
                  </View>
                </View>
              )}
            </MeasureView>
          }
          selectables={Object.fromEntries(selectables)}
          correctAnswer={['A']}
          questionHeight={900}
        />
      </MarkupAssets>
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aUf',
  description: 'aUf',
  keywords: ['Formula', 'Equation', 'Sequence'],
  schema: z
    .object({
      symbol: algebraicSymbolSchema,
      cubes: z.number().int().min(2).max(6),
      ones: z.number().int().min(1).max(5),
      cubeValue: z.number().int().min(2).max(10),
      cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
      onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
    })
    .refine(({ cubes, ones }) => cubes + ones < 10 && cubes !== ones),
  simpleGenerator: () => {
    const cubes = randomIntegerInclusive(2, 6);
    const ones = randomIntegerInclusive(1, 5, { constraint: x => x + cubes < 10 && x !== cubes });

    const symbol = getAlgebraicSymbol();
    const cubeValue = randomIntegerInclusive(2, 10);

    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);

    return { cubes, cubeValue, symbol, ones, cubeColour, onesCubeColur };
  },
  Component: props => {
    const {
      question: { cubes, cubeValue, symbol, ones, cubeColour, onesCubeColur },
      translate
    } = props;
    const total = cubes * cubeValue + ones;
    const totalObjects = cubes + ones;

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfCubes = range(1, cubes).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cube_${i}`} />
    ));
    const arrayOfOnes = range(1, ones).map(i => (
      <AssetSvg name={onesSvgName} width={70} key={`ones_${i}`} />
    ));

    const selectables = shuffle(
      [
        [
          'A',
          `${cubes.toLocaleString()}${symbol} ${ADD} ${ones.toLocaleString()} = ${total.toLocaleString()}`
        ],
        [
          'B',
          `${ones.toLocaleString()}${symbol} ${ADD} ${cubes.toLocaleString()} = ${total.toLocaleString()}`
        ],
        ['C', `${symbol} ${ADD} ${totalObjects.toLocaleString()} = ${total.toLocaleString()}`],
        ['D', `${totalObjects.toLocaleString()}${symbol} = ${total.toLocaleString()}`]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <MarkupAssets
        elements={{
          cube: <AssetSvg key="cube" name={cubesSvgName} height={70} />,
          ones: <AssetSvg key="ones" name={onesSvgName} height={70} />
        }}
      >
        <QF39ContentWithSelectablesOnRight
          title={translate.instructions.selectEquationRepresentedByCubes()}
          pdfTitle={translate.instructions.circleEquationRepresentedByCubes()}
          leftContent={
            <MeasureView>
              {dimens => (
                <View style={{ ...dimens, alignItems: 'center' }}>
                  <ContentBox
                    containerStyle={{
                      flexDirection: 'row',
                      alignSelf: 'flex-start',
                      marginBottom: 100,
                      gap: 32
                    }}
                  >
                    <TextStructure sentence={`<asset name='cube'/> = ${symbol}`} />
                    <TextStructure sentence={`<asset name='ones'/> = ${(1).toLocaleString()}`} />
                  </ContentBox>
                  <View>
                    <View style={{ width: totalObjects * 75 }}>
                      <BarModelCurlyBrace braceText={total.toLocaleString()} />
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 5
                      }}
                    >
                      {[...arrayOfCubes, ...arrayOfOnes]}
                    </View>
                  </View>
                </View>
              )}
            </MeasureView>
          }
          selectables={Object.fromEntries(selectables)}
          correctAnswer={['A']}
          questionHeight={900}
        />
      </MarkupAssets>
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aUg',
  description: 'aUg',
  keywords: ['Cubes', 'Equation'],
  schema: z
    .object({
      ones: z.number().int().min(1).max(6),
      cubes: z.number().int().min(1).max(4),
      cubeValue: z.number().int().min(1).max(6),
      symbol: algebraicSymbolSchema,
      cubeColour: z.enum(['blue', 'green', 'red', 'yellow']),
      onesCubeColur: z.enum(['red', 'yellow', 'blue', 'green', 'orange', 'purple'])
    })
    .refine(({ cubes, ones }) => cubes !== ones),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 6);
    const cubes = randomIntegerInclusive(2, 4, { constraint: x => x !== ones });
    const cubeValue = randomIntegerInclusive(1, 6);
    const symbol = getAlgebraicSymbol();
    const cubeColour = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const onesCubeColur = getRandomFromArray([
      'red',
      'yellow',
      'blue',
      'green',
      'orange',
      'purple'
    ] as const);
    return { cubes, ones, cubeValue, symbol, cubeColour, onesCubeColur };
  },
  Component: props => {
    const {
      question: { cubes, ones, cubeValue, symbol, cubeColour, onesCubeColur },
      translate
    } = props;
    const total = cubes * cubeValue + ones;

    const onesSvgName: SvgName = `Cubes_blank/Coloured_cube_unlabelled_${onesCubeColur}`;
    const cubesSvgName: SvgName = `Multi_link_cubes/Multilink_cube_individual_vertical_${cubeColour}`;

    const arrayOfOnes = range(1, ones).map(i => (
      <AssetSvg name={onesSvgName} width={70} key={`one_${i}`} />
    ));
    const arrayOfCubes = range(1, cubes).map(i => (
      <AssetSvg name={cubesSvgName} width={70} key={`cube_${i}`} />
    ));
    const arrayOfObjects = [...arrayOfCubes, ...arrayOfOnes];

    const answers = [
      [`${cubes}${symbol}`, `${ones}`, `${total}`],
      [`${ones}`, `${cubes}${symbol}`, `${total}`]
    ];
    return (
      <MarkupAssets
        elements={{
          cube: <AssetSvg key="cube" name={cubesSvgName} height={70} />,
          ones: <AssetSvg key="ones" name={onesSvgName} height={70} />
        }}
      >
        <QF1ContentAndSentence
          title={translate.instructions.completeEquationRepresentedByCubes()}
          extraSymbol={symbol}
          Content={({ dimens }) => (
            <View
              style={{
                ...dimens
              }}
            >
              <ContentBox
                containerStyle={{
                  flexDirection: 'row',
                  alignSelf: 'flex-start',
                  marginBottom: 100,
                  gap: 10
                }}
              >
                <TextStructure sentence={`<asset name='cube'/> = ${symbol}`} />
                <TextStructure sentence={`<asset name='ones'/> = ${(1).toLocaleString()}`} />
              </ContentBox>
              <View style={{ alignItems: 'center' }}>
                <View style={{ width: arrayOfObjects.length * 80 }}>
                  <BarModelCurlyBrace braceText={total.toLocaleString()} />
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 10
                  }}
                >
                  {arrayOfObjects}
                </View>
              </View>
            </View>
          )}
          inputMaxCharacters={2}
          sentenceStyle={{ justifyContent: 'center' }}
          sentence={`<ans/> ${ADD} <ans/> = <ans/>`}
          testCorrect={userAnswer =>
            arraysHaveSameContents(userAnswer, answers[0]) ||
            arraysHaveSameContents(userAnswer, answers[1])
          }
          customMarkSchemeAnswer={{
            answersToDisplay: [
              `${cubes.toLocaleString()}${symbol}`,
              `${ones.toLocaleString()}`,
              `${total.toLocaleString()}`
            ],
            answerText: translate.markScheme.acceptAnyOrder()
          }}
        />
      </MarkupAssets>
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aUh',
  description: 'aUh',
  keywords: ['Equation'],
  schema: z.object({
    symbol: algebraicSymbolSchema,
    number: z.number().int().min(2).max(5),
    varValue: z.number().int().min(2).max(4),
    operation: z.enum(['ADD', 'SUB1', 'SUB2', 'MULT', 'DIV1', 'DIV2']),
    selectables: z.array(z.enum(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']))
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 5);

    const symbol = getAlgebraicSymbol();
    const varValue = randomIntegerInclusive(2, 4);

    const operation = getRandomFromArray(['ADD', 'SUB1', 'SUB2', 'MULT', 'DIV1', 'DIV2'] as const);

    let selectables: ('A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H')[];
    switch (operation) {
      case 'ADD':
        selectables = [
          'A',
          'B',
          ...getRandomFromArray([
            ['E', 'F'],
            ['G', 'H']
          ] as const)
        ];
        break;
      case 'SUB1':
      case 'SUB2':
        selectables = [
          'C',
          'D',
          ...getRandomFromArray([
            ['E', 'F'],
            ['G', 'H']
          ] as const)
        ];
        break;
      case 'MULT':
        selectables = [
          'E',
          'F',
          ...getRandomFromArray([
            ['A', 'B'],
            ['C', 'D']
          ] as const)
        ];
        break;
      case 'DIV1':
      case 'DIV2':
        selectables = [
          'G',
          'H',
          ...getRandomFromArray([
            ['A', 'B'],
            ['C', 'D']
          ] as const)
        ];
        break;
    }

    return { number, varValue, symbol, operation, selectables };
  },
  Component: props => {
    const {
      question: { number, varValue, symbol, operation, selectables },
      translate
    } = props;
    let answer;
    let equation: string;
    const number2 = varValue * number;

    switch (operation) {
      case 'ADD': {
        answer = 'A';
        equation = `${symbol} ${ADD} ${number.toLocaleString()} = ${number2.toLocaleString()}`;
        break;
      }
      case 'SUB1': {
        answer = 'C';
        equation = `${symbol} ${SUB} ${number.toLocaleString()} = ${number2.toLocaleString()}`;
        break;
      }
      case 'SUB2': {
        answer = 'D';
        equation = `${number2.toLocaleString()} ${SUB} ${symbol} = ${number.toLocaleString()}`;
        break;
      }
      case 'MULT': {
        answer = 'E';
        equation = `${number.toLocaleString()}${symbol} = ${number2.toLocaleString()}`;
        break;
      }
      case 'DIV1': {
        answer = 'G';
        equation = `${symbol} ${DIV} ${number.toLocaleString()} = ${number2.toLocaleString()}`;
        break;
      }
      case 'DIV2': {
        answer = 'H';
        equation = `${number2.toLocaleString()} ${DIV} ${symbol} = ${number.toLocaleString()}`;
        break;
      }
    }

    const selectableList = {
      A: translate.answerSentences.xMoreThanYIsEqualToZ(
        number.toLocaleString(),
        symbol,
        number2.toLocaleString()
      ),
      B: translate.answerSentences.xMoreThanYIsEqualToZ(
        number2.toLocaleString(),
        symbol,
        number.toLocaleString()
      ),
      C: translate.answerSentences.xLessThanYIsEqualToZ(
        number.toLocaleString(),
        symbol,
        number2.toLocaleString()
      ),
      D: translate.answerSentences.xLessThanYIsEqualToZ(
        symbol,
        number2.toLocaleString(),
        number.toLocaleString()
      ),
      E: translate.answerSentences.xLotsOfYIsEqualToZ(
        number.toLocaleString(),
        symbol,
        number2.toLocaleString()
      ),
      F: translate.answerSentences.xLotsOfYIsEqualToZ(
        number2.toLocaleString(),
        symbol,
        number.toLocaleString()
      ),
      G: translate.answerSentences.xDividedByYIsEqualToZ(
        symbol,
        number.toLocaleString(),
        number2.toLocaleString()
      ),
      H: translate.answerSentences.xDividedByYIsEqualToZ(
        number2.toLocaleString(),
        symbol,
        number.toLocaleString()
      )
    };

    const shuffledSelectables = shuffle(selectables, { random: seededRandom(props.question) });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectStatementThatMatchesEquation()}
        pdfTitle={translate.instructions.selectStatementThatMatchesEquationPDF()}
        Content={({ dimens }) => (
          <ContentBox
            containerStyle={{
              alignSelf: 'center',
              justifyContent: 'center',
              height: dimens.height * 0.75,
              width: dimens.width * 0.75
            }}
          >
            <Text variant="WRN700">{equation}</Text>
          </ContentBox>
        )}
        renderItems={shuffledSelectables.map(x => ({
          value: x,
          component: (
            <Text variant="WRN700" style={{ textAlign: 'center', paddingHorizontal: 10 }}>
              {selectableList[x]}
            </Text>
          )
        }))}
        numItems={4}
        testCorrect={[answer]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aUi',
  description: 'aUi',
  keywords: ['Bar model', 'Equation'],
  schema: z.object({
    symbol: algebraicSymbolSchema,
    multiplier: z.number().int().min(2).max(6),
    number: z.number().int().min(1).max(20),
    varValue: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const multiplier = randomIntegerInclusive(2, 6);
    const number = randomIntegerInclusive(1, 20);

    const symbol = getAlgebraicSymbol();
    const varValue = randomIntegerInclusive(1, 6);

    return { multiplier, number, symbol, varValue };
  },
  Component: props => {
    const {
      question: { multiplier, number, symbol, varValue },
      translate,
      displayMode
    } = props;
    const total = multiplier * varValue + number;

    const items = shuffle(
      [
        `${ADD}`,
        `=`,
        `${symbol}`,
        `${number.toLocaleString()}`,
        `${multiplier.toLocaleString()}`,
        `${total.toLocaleString()}`
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const variableBar = range(1, multiplier).map(_ => varValue);
    const variableLabel = range(1, multiplier).map(_ => symbol);

    const possibleAnswerSequence = [
      [`${multiplier}`, `${symbol}`, `${ADD}`, `${number}`, `=`, `${total}`],
      [`${number}`, `${ADD}`, `${multiplier}`, `${symbol}`, `=`, `${total}`],
      [`${total}`, `=`, `${number}`, `${ADD}`, `${multiplier}`, `${symbol}`],
      [`${total}`, `=`, `${multiplier}`, `${symbol}`, `${ADD}`, `${number}`]
    ];

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragTheCardsToCreateAnEquationToRepresentTheBarModel()}
        pdfTitle={translate.instructions.useTheCardsToCreateAnEquationToRepresentTheBarModel()}
        Content={({ dimens }) => (
          <BarModel
            dimens={dimens}
            total={total}
            numbers={[[total], [...variableBar, number]]}
            strings={[[total.toLocaleString()], [...variableLabel, number.toLocaleString()]]}
            maxFontSize={displayMode === 'digital' ? 32 : 50}
            sameRowColor
          />
        )}
        sentence={`<ans/> <ans/> <ans/> <ans/> <ans/> <ans/>`}
        items={items}
        testCorrect={userAnswer =>
          possibleAnswerSequence.some(seq => arraysHaveSameContents(seq, userAnswer))
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [possibleAnswerSequence[0] as (string | undefined)[]],
          answerText: translate.markScheme.acceptValidAnswersForContent()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aUj',
  description: 'aUj',
  keywords: ['Perimeter', 'Equation'],
  schema: z.object({
    symbol1: algebraicSymbolSchema,
    symbol2: algebraicSymbolSchema,
    shape: z.enum(['Rectangle', 'Trapezium', 'Triangle']),
    varValue1: z.number().int().min(1).max(6),
    varValue2: z.number().int().min(1).max(6),
    varMultiplier1: z.number().int().min(1).max(4),
    varMultiplier2: z.number().int().min(1).max(5),
    varMultiplier3: z.number().int().min(2).max(8) //for trapezium only
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['Rectangle', 'Trapezium', 'Triangle'] as const);
    const [symbol1, symbol2] = getAlgebraicSymbolPair();

    const varValue1 = randomIntegerInclusive(1, 6);
    const varValue2 = randomIntegerInclusive(1, 6);

    const varMultiplier1 = randomIntegerInclusive(1, 4);
    const varMultiplier2 = randomIntegerInclusive(varMultiplier1, 5);
    const varMultiplier3 = randomIntegerInclusive(varMultiplier2 + 1, varMultiplier2 + 3);

    return {
      shape,
      symbol1,
      symbol2,
      varValue1,
      varValue2,
      varMultiplier1,
      varMultiplier2,
      varMultiplier3
    };
  },
  Component: props => {
    const {
      question: {
        shape,
        symbol1,
        symbol2,
        varValue1,
        varValue2,
        varMultiplier1,
        varMultiplier2,
        varMultiplier3
      },
      translate
    } = props;

    let labels: string[];

    const totalVar1 = 2 * varMultiplier1;
    let totalVar2 = 0;

    switch (shape) {
      case 'Rectangle': {
        totalVar2 = 2 * varMultiplier2;
        labels = [
          `${varMultiplier2.toLocaleString()}${symbol2}`,
          `${varMultiplier1.toLocaleString()}${symbol1}`
        ];
        break;
      }
      case 'Trapezium': {
        totalVar2 = varMultiplier2 + varMultiplier3;
        labels = [
          `${varMultiplier2.toLocaleString()}${symbol2}`,
          `${varMultiplier1.toLocaleString()}${symbol1}`,
          `${varMultiplier3.toLocaleString()}${symbol2}`
        ];
        break;
      }
      case 'Triangle': {
        totalVar2 = varMultiplier2;
        labels = [
          `${varMultiplier1.toLocaleString()}${symbol1}`,
          `${varMultiplier2.toLocaleString()}${symbol2}`,
          `${varMultiplier1.toLocaleString()}${symbol1}`
        ];
        break;
      }
    }
    const perimeter = totalVar1 * varValue1 + totalVar2 * varValue2;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.perimeterIs(perimeter.toLocaleString())}
        Content={({ dimens }) => (
          <>
            {shape === 'Triangle' ? (
              <LabelledTriangle labels={labels} dimens={dimens} />
            ) : (
              <LabelledQuadrilateral
                dimens={dimens}
                shape={shape}
                labels={labels}
                shapeSize="large"
              />
            )}
          </>
        )}
        sentence={`${translate.instructions.completeTheEquationToRepresentThePerimeterOfTheShape()}<br/><ans/>${symbol1} ${ADD} <ans/>${symbol2} = <ans/>`}
        testCorrect={[totalVar1.toString(), totalVar2.toString(), perimeter.toString()]}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FormEquations',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
