import { Pressable, ScrollView, StyleSheet } from 'react-native';
import { Portal } from '../portal';
import Modal from './Modal';
import { useState } from 'react';
import { colors } from '../../theme/colors';
import Text from '../typography/Text';
import { useI18nContext } from '../../i18n/i18n-react';
import IconButton from '../atoms/IconButton';
import { AssetSvg } from '../../assets/svg';

type ExtraContentModalProps = {
  /** Text for modal button (will be displayed on question) */
  promptButton?: string | JSX.Element;
  promptButtonPosition?: 'left' | 'center' | 'right';
  /** Title for modal container */
  modalTitle?: string;
  children?: JSX.Element;
};

const ExtraContentModal = ({
  promptButton,
  promptButtonPosition = 'center',
  modalTitle = 'Hint',
  children
}: ExtraContentModalProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const styles = useStyles(promptButtonPosition);

  const translate = useI18nContext().LL;

  // Early return if nothing passed in
  if (children === undefined) {
    return null;
  }

  const resolvePromptButton = () => {
    if (!promptButton) {
      promptButton = translate.extraContentModal.defaultButtonText();
    }

    return typeof promptButton !== 'string' ? (
      <Pressable onPress={toggleModal} style={{ alignSelf: 'center', justifyContent: 'flex-end' }}>
        {promptButton}
      </Pressable>
    ) : (
      <Text onPress={toggleModal} variant="WRN700" style={styles.buttonText} numberOfLines={1}>
        {promptButton}
      </Text>
    );
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <>
      {resolvePromptButton()}
      <Portal>
        {modalVisible && (
          <Modal
            onDismiss={toggleModal}
            style={styles.modalStyle}
            containerStyle={styles.contentContainerStyle}
          >
            <Text variant="WRN700" style={styles.modalTitle}>
              {modalTitle === undefined
                ? translate.extraContentModal.defaultModalTitle()
                : modalTitle}
            </Text>
            <ScrollView
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              style={styles.scrollView}
            >
              {children}
            </ScrollView>
            <IconButton style={styles.closeModal} onPress={toggleModal}>
              <AssetSvg name="CloseCustomizable" svgProps={{ fill: colors.greys500 }} />
            </IconButton>
          </Modal>
        )}
      </Portal>
    </>
  );
};

function useStyles(promptButtonPosition?: 'left' | 'center' | 'right') {
  return StyleSheet.create({
    buttonText: {
      fontSize: 30,
      textDecorationLine: 'underline',
      alignSelf:
        promptButtonPosition === 'left'
          ? 'flex-start'
          : promptButtonPosition === 'center'
          ? 'center'
          : 'flex-end'
    },
    modalStyle: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    contentContainerStyle: {
      width: 1024,
      height: 640,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      paddingTop: 70
    },
    modalTitle: {
      left: 0,
      top: 0,
      position: 'absolute',
      fontSize: 43,
      paddingLeft: 32,
      paddingTop: 24
    },
    scrollView: {
      marginHorizontal: 32,
      marginTop: 30
    },
    closeModal: {
      right: 10,
      top: 10,
      position: 'absolute'
    }
  });
}

export default ExtraContentModal;
