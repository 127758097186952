import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBooleanArray,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import { arrayHasNoDuplicates, filledArray, sortNumberArray } from '../../../../utils/collections';
import TextStructure from '../../../../components/molecules/TextStructure';
import { AssetSvg } from '../../../../assets/svg';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { numberEnum } from '../../../../utils/zod';
import {
  counterVariants,
  counterVariantSchema
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import QF60DragCountersIntoGroups from '../../../../components/question/questionFormats/QF60DragCountersIntoGroups';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baN',
  description: 'baN',
  keywords: ['Group', 'Order', 'Compare'],
  schema: z
    .object({
      numA: z.number().int().min(1).max(6),
      numB: z.number().int().min(1).max(6),
      numC: z.number().int().min(1).max(6),
      fruitA: z.enum(['apple', 'orange', 'banana', 'strawberry', 'pear']),
      fruitB: z.enum(['apple', 'orange', 'banana', 'strawberry', 'pear']),
      fruitC: z.enum(['apple', 'orange', 'banana', 'strawberry', 'pear']),
      smallestGreatest: z.enum(['smallest', 'greatest'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.fruitA, val.fruitB, val.fruitC]),
      'All fruits must be different'
    )
    .refine(
      val => arrayHasNoDuplicates([val.numA, val.numB, val.numC]),
      'All numbers must be different'
    ),
  simpleGenerator: () => {
    const [numA, numB, numC] = randomUniqueIntegersInclusive(1, 6, 3);

    const [fruitA, fruitB, fruitC] = getRandomSubArrayFromArray(
      ['apple', 'orange', 'banana', 'strawberry', 'pear'] as const,
      3
    );

    const smallestGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    return { numA, numB, numC, fruitA, fruitB, fruitC, smallestGreatest };
  },

  Component: ({
    question: { numA, numB, numC, fruitA, fruitB, fruitC, smallestGreatest },
    translate
  }) => {
    const fruitSvgPaths = {
      banana: 'Array_objects/Banana' as const,
      pear: 'Array_objects/Pear' as const,
      orange: 'Array_objects/Orange' as const,
      apple: 'Array_objects/AppleGreen' as const,
      strawberry: 'Array_objects/Strawberry' as const
    };

    const fruitsA = Array.from(new Array(numA), (_, index) => {
      return (
        <AssetSvg
          name={fruitSvgPaths[fruitA]}
          width={100}
          height={100}
          style={{ marginLeft: 30 }}
          key={index}
        />
      );
    });

    const fruitsB = Array.from(new Array(numB), (_, index) => {
      return (
        <AssetSvg
          name={fruitSvgPaths[fruitB]}
          width={100}
          height={100}
          style={{ marginLeft: 30 }}
          key={index}
        />
      );
    });
    const fruitsC = Array.from(new Array(numC), (_, index) => {
      return (
        <AssetSvg
          name={fruitSvgPaths[fruitC]}
          width={100}
          height={100}
          style={{ marginLeft: 30 }}
          key={index}
        />
      );
    });

    const correctAnswer = (smallestGreatest === 'smallest' ? Math.min : Math.max)(numA, numB, numC);

    const sentence =
      smallestGreatest === 'smallest'
        ? translate.ks1AnswerSentences.groupAnsIsTheSmallest()
        : translate.ks1AnswerSentences.groupAnsIsTheGreatest();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1Instructions.completeTheSentence()}
        itemVariant="square"
        items={[
          { value: numA, component: 'A' },
          { value: numB, component: 'B' },
          { value: numC, component: 'C' }
        ]}
        moveOrCopy="move"
        actionPanelVariant="end"
        sentenceStyle={{ alignSelf: 'flex-start' }}
        pdfLayout="itemsHidden"
        Content={({ dimens }) => (
          <View style={{ width: dimens.width, height: dimens.height, flexDirection: 'column' }}>
            <View
              style={{
                width: dimens.width,
                height: dimens.height / 3,
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              <TextStructure
                style={{
                  width: dimens.width / 8,
                  height: dimens.height / 3,
                  alignContent: 'center'
                }}
                textStyle={{ lineHeight: dimens.height / 3, textAlignVertical: 'center' }}
                sentence={translate.ks1MiscStrings.groupX('A')}
              />

              {fruitsA}
            </View>
            <View
              style={{
                width: dimens.width,
                height: dimens.height / 3,
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              <TextStructure
                style={{
                  width: dimens.width / 8,
                  height: dimens.height / 3,
                  alignContent: 'center'
                }}
                textStyle={{ lineHeight: dimens.height / 3, textAlignVertical: 'center' }}
                sentence={translate.ks1MiscStrings.groupX('B')}
              />
              {fruitsB}
            </View>
            <View
              style={{
                width: dimens.width,
                height: dimens.height / 3,
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              <TextStructure
                style={{
                  width: dimens.width / 8,
                  height: dimens.height / 3,
                  alignContent: 'center'
                }}
                textStyle={{ lineHeight: dimens.height / 3, textAlignVertical: 'center' }}
                sentence={translate.ks1MiscStrings.groupX('C')}
              />
              {fruitsC}
            </View>
          </View>
        )}
        sentence={sentence}
        testCorrect={[correctAnswer]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'baO',
  description: 'baO',
  keywords: ['Order'],
  schema: z
    .object({
      numA: z.number().min(0).max(10),
      numB: z.number().min(0).max(10),
      numC: z.number().min(0).max(10),
      ordering: z.enum(['ascending', 'descending'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.numA, val.numB, val.numC]),
      'All fruits must be different'
    ),
  simpleGenerator: () => {
    const [numA, numB, numC] = randomUniqueIntegersInclusive(0, 10, 3);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numA, numB, numC, ordering };
  },
  Component: props => {
    const {
      question: { numA, numB, numC, ordering },
      translate
    } = props;

    const correctOrder = sortNumberArray([numA, numB, numC], ordering);

    return (
      <QF5DragOrderHorizontal
        title={translate.ks1Instructions.dragTheCardsToOrderTheNumbers()}
        pdfTitle={
          ordering === 'ascending'
            ? translate.ks1PDFInstructions.orderTheNumbersFromSmallestToGreatest()
            : translate.ks1PDFInstructions.orderTheNumbersFromGreatestToSmallest()
        }
        labelsPosition="bottom"
        testCorrect={correctOrder}
        items={[numA, numB, numC]}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        moveOrCopy="move"
        arrowWidth={200}
        mainPanelItemStyle={{ marginHorizontal: 50 }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'baP',
  description: 'baP',
  keywords: ['Order'],
  schema: z
    .object({
      emptyBoxIdx: numberEnum([0, 1, 2]),
      numberOfCounters: z.array(z.number().int().min(1).max(10)).length(2),
      counterColor: counterVariantSchema,
      ordering: z.enum(['ascending', 'descending'])
    })
    .refine(val => {
      if (val.emptyBoxIdx === 0) {
        return val.ordering === 'ascending'
          ? Math.min(val.numberOfCounters[0], val.numberOfCounters[1]) >= 2
          : Math.max(val.numberOfCounters[0], val.numberOfCounters[1]) <= 9;
      } else if (val.emptyBoxIdx === 1) {
        return Math.abs(val.numberOfCounters[0] - val.numberOfCounters[1]) >= 2;
      } else {
        return val.ordering === 'ascending'
          ? Math.max(val.numberOfCounters[0], val.numberOfCounters[1]) <= 9
          : Math.min(val.numberOfCounters[0], val.numberOfCounters[1]) >= 2;
      }
    }, 'Numbers generated must allow for at least 1 possible answer!'),
  simpleGenerator: () => {
    const emptyBoxIdx = getRandomFromArray([0, 1, 2] as const);
    const counterColor = getRandomFromArray(counterVariants);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    // Ensure question is answerable!
    const { num1, num2 } = rejectionSample(
      () => {
        const [num1, num2] = randomUniqueIntegersInclusive(1, 10, 2);

        return { num1, num2 };
      },
      ({ num1, num2 }) => {
        if (emptyBoxIdx === 0) {
          return ordering === 'ascending' ? Math.min(num1, num2) >= 2 : Math.max(num1, num2) <= 9;
        } else if (emptyBoxIdx === 1) {
          return Math.abs(num1 - num2) >= 2;
        } else {
          return ordering === 'ascending' ? Math.max(num1, num2) <= 9 : Math.min(num1, num2) >= 2;
        }
      }
    );

    return {
      emptyBoxIdx,
      numberOfCounters: [num1, num2],
      counterColor,
      ordering
    };
  },
  Component: props => {
    const {
      question: { emptyBoxIdx, numberOfCounters, counterColor, ordering },
      translate
    } = props;

    const sortedNumbers = sortNumberArray(numberOfCounters, ordering);

    const random = seededRandom(props.question);

    const pdfInstruction =
      ordering === 'ascending'
        ? translate.ks1PDFInstructions.theCountersAreInOrderFromGreatestToSmallestDrawTheMissingCounters()
        : translate.ks1PDFInstructions.theCountersAreInOrderFromSmallestToGreatestDrawTheMissingCounters();

    const prefilledBoxes: (boolean[][] | '<ans/>')[] = [
      getRandomBooleanArray(5, 4, sortedNumbers[0], random),
      getRandomBooleanArray(5, 4, sortedNumbers[1], random)
    ];
    // Insert answer box at index
    prefilledBoxes.splice(emptyBoxIdx, 0, '<ans/>');

    const markSchemeAnswer = (() => {
      switch (emptyBoxIdx) {
        case 0:
          return ordering === 'ascending'
            ? filledArray(counterColor, sortedNumbers[0] - 1)
            : filledArray(counterColor, sortedNumbers[0] + 1);
        case 1:
          return ordering === 'ascending'
            ? filledArray(counterColor, sortedNumbers[0] + 1)
            : filledArray(counterColor, sortedNumbers[0] - 1);
        case 2:
          return ordering === 'ascending'
            ? filledArray(counterColor, sortedNumbers[1] + 1)
            : filledArray(counterColor, sortedNumbers[1] - 1);
      }
    })();

    return (
      <QF60DragCountersIntoGroups
        numberOfGroups={3}
        title={translate.ks1Instructions.dragCountersToFillTheEmptyBox()}
        pdfTitle={pdfInstruction}
        items={[counterColor]}
        testCorrect={userAnswer => {
          if (emptyBoxIdx === 0) {
            return ordering === 'ascending'
              ? userAnswer[0].length < sortedNumbers[0]
              : userAnswer[0].length > sortedNumbers[0];
          } else if (emptyBoxIdx === 1) {
            return ordering === 'ascending'
              ? userAnswer[0].length > sortedNumbers[0] && userAnswer[0].length < sortedNumbers[1]
              : userAnswer[0].length < sortedNumbers[0] && userAnswer[0].length > sortedNumbers[1];
          } else {
            return ordering === 'ascending'
              ? userAnswer[0].length > sortedNumbers[1]
              : userAnswer[0].length < sortedNumbers[1];
          }
        }}
        prefilledBoxes={{ type: 'random', value: prefilledBoxes }}
        markSchemeAnswer={{
          answerToDisplay: [markSchemeAnswer],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
        maxCapacity={20}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderObjectsAndNumbers',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
