import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import WoodenBalanceScale, {
  getScaledWeights,
  weightToDenominations
} from '../../../../components/question/representations/WoodenBalanceScale';
import { AssetSvg } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import Scales from '../../../../components/question/representations/Scales';
import QF65SetTheScales from '../../../../components/question/questionFormats/QF65SetTheScales';
import { isEqual } from '../../../../utils/matchers';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjN',
  description: 'bjN',
  keywords: ['Measure', 'Mass', 'Kilograms', 'Balance scales'],
  schema: z.object({
    weight: z.number().int().min(5).max(100),
    object: z.enum(['suitcase', 'bricks', 'box', 'flour', 'barrel', 'tyre'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'suitcase',
      'bricks',
      'box',
      'flour',
      'barrel',
      'tyre'
    ] as const);

    const weight = randomIntegerInclusive(5, 100, {
      constraint: x => weightToDenominations(x, [50, 20, 5, 2, 1]).length < 4
    });

    return { weight, object };
  },
  Component: props => {
    const {
      question: { object, weight },
      translate,
      displayMode
    } = props;

    const objectSvg = {
      suitcase: ['Suitcase', 140],
      bricks: ['Labelled_box_bricks', 160],
      box: ['CardboardBox', 160],
      flour: ['Bag_of_Flour', 100],
      barrel: ['Wooden_barrel', 100],
      tyre: ['Car_tyre', 120]
    } as const;

    const weights = weightToDenominations(weight, [50, 20, 5, 2, 1]);
    const scaledWeights = getScaledWeights({ weights, unit: 'kg' });

    const weightComponent = weights.map((x, i) => (
      <AssetSvg
        width={scaledWeights[i] * (displayMode === 'digital' ? 100 : 180)}
        key={`weight_${x}_${i}`}
        name={`Weights/Weights_${x}kg` as const}
      />
    ));
    const title = {
      suitcase: 'whatIsTheMassOfTheSuitcase',
      bricks: 'whatIsTheMassOfTheBricks',
      box: 'whatIsTheMassOfTheBox',
      flour: 'whatIsTheMassOfTheFlour',
      barrel: 'whatIsTheMassOfTheBarrel',
      tyre: 'whatIsTheMassOfTheTyre'
    } as const;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions[title[object]]()}
        sentence={translate.ks1AnswerSentences.ansKg()}
        testCorrect={[weight.toString()]}
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={[
              <AssetSvg
                key={'object'}
                name={objectSvg[object][0]}
                width={objectSvg[object][1] * (displayMode === 'digital' ? 1 : 1.8)}
                style={{ justifyContent: 'flex-end' }}
              />,
              <View key="weights" style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                {weightComponent}
              </View>
            ]}
            containerStyle={{ marginTop: 80 }}
            dimens={dimens}
            stackInfront
          />
        )}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bjO',
  description: 'bjO',
  keywords: ['Mass', 'Measure', 'Scales', 'Kilograms'],
  schema: z.object({
    scaleIntervalKg: numberEnum([2, 5, 10]),
    weightKg: z.number().int().min(2).max(100),
    image: z.enum(['suitcase', 'bricks', 'box', 'flour', 'barrel', 'tyre'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const scaleIntervalKg = getRandomFromArray([2, 5] as const);
    const weightKg = randomIntegerInclusiveStep(
      scaleIntervalKg,
      scaleIntervalKg * 10,
      scaleIntervalKg
    );
    const image = getRandomFromArray(['suitcase', 'bricks', 'box', 'barrel', 'tyre'] as const);
    return { weightKg, image, scaleIntervalKg };
  },
  Component: ({ question: { weightKg, image, scaleIntervalKg }, translate }) => {
    const imageSvg = {
      suitcase: 'Suitcase',
      bricks: 'Labelled_box_bricks',
      box: 'CardboardBox',
      // Kept flour here to avoid TypeScript errors below when rendering the image
      flour: 'Bag_of_Flour',
      barrel: 'Wooden_barrel',
      tyre: 'Car_tyre'
    } as const;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatIsTheMass()}
        sentence={translate.answerSentences.ansKg()}
        testCorrect={[weightKg.toString()]}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1200}
        Content={({ dimens }) => {
          return (
            <Scales
              dynamicScale={{
                maxScale: scaleIntervalKg * 10_000,
                majorTicks: scaleIntervalKg * 1000,
                labels: 'kg'
              }}
              weightG={weightKg * 1000}
              scaleWidth={dimens.height * 0.55}
              weightImage={imageSvg[image]}
              weightImageWidth={dimens.height * 0.3}
              weightStyle={{ bottom: -5, zIndex: 1 }}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjP',
  description: 'bjP',
  keywords: ['Mass', 'Measure', 'Scales', 'Grams'],
  schema: z.object({
    scaleIntervalKg: numberEnum([2, 5, 10]),
    weight: z.number().int().min(1).max(100)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const scaleIntervalKg = getRandomFromArray([2, 5, 10] as const);
    const step = scaleIntervalKg === 2 ? 1 : 5;
    const weight = randomIntegerInclusiveStep(step, scaleIntervalKg * 10, step);
    return { weight, scaleIntervalKg };
  },
  Component: ({ question: { weight, scaleIntervalKg }, translate }) => {
    const weightG = weight * 1000;
    return (
      <QF65SetTheScales
        title={translate.ks1Instructions.dragTheScaleToShowTheMassOfXKg(weight)}
        pdfTitle={translate.ks1PDFInstructions.drawArrowToShowTheMassOfXKg(weight)}
        dynamicScale={{
          maxScale: scaleIntervalKg * 10_000,
          majorTicks: scaleIntervalKg * 1000,
          minorTicks: scaleIntervalKg === 5 ? undefined : (scaleIntervalKg / 2) * 1000,
          labels: 'kg'
        }}
        answerGrams={weightG}
        questionHeight={1000}
        testCorrect={isEqual(weightG)}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureInKilograms',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
