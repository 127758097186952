import { useMemo } from 'react';
import { View } from 'react-native';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import TextStructure from '../../../../components/molecules/TextStructure';
import Table from '../../../../components/molecules/Table';
import { characterNameLabel, getCharacterHeadSvgName } from '../../../../utils/characters';
import { Dimens } from '../../../../theme/scaling';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { numberToBase10Object } from '../../../../utils/math';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { filledArray } from '../../../../utils/collections';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'asW',
  description: 'asW',
  keywords: ['Fractions', 'Bar model', 'Divide', 'Non-unit'],
  schema: z.object({
    var1: z.number().int().min(2).max(4),
    parts: z.number().int().min(3).max(5),
    counters: z.number().int().min(6).max(20).step(2)
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(3, 5);
    const counters = randomIntegerInclusiveStep(6, 20, 2, { constraint: x => x % parts === 0 });

    const var1 = randomIntegerInclusive(2, parts - 1);

    return { var1, parts, counters };
  },
  Component: ({ question: { var1, parts, counters }, translate }) => {
    const amountPerPart = counters / parts;
    const answer = (counters / parts) * var1;

    const sectionOfCounters = (number: number, { height, width }: Dimens) => {
      return <ArrayOfObjects dimens={{ height, width }} rows={1} columns={number} />;
    };

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheBarModelToHelpYouCompleteTheNumberSentence()}
        Content={({ dimens }) => (
          <Table
            items={[
              filledArray(
                sectionOfCounters(amountPerPart, {
                  width: dimens.width / parts - 20,
                  height: dimens.height / 2
                }),
                parts
              )
            ]}
            cellStyle={{
              //cellStyle in table has paddingTop and paddingLeft defined, so need to explicitly write them add and can't use just "padding"
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 16,
              paddingBottom: 16
            }}
          />
        )}
        sentence={`${translate.answerSentences.xOfY(
          `<frac n="${var1}" d="${parts}" />`,
          counters
        )} = <ans/>`}
        textStyle={{ fontSize: 40 }}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'asX',
  description: 'asX',
  keywords: ['Fractions', 'Divide', 'Non-unit'],
  schema: z.object({
    number1: z.number().int().min(2).max(7),
    number2: numberEnum([3, 4, 5, 8]),
    number3: z.number().int().min(6).max(20),
    number4: z.number().int().min(2).max(7),
    number5: numberEnum([3, 4, 5, 8]),
    number6: z.number().int().min(6).max(20),
    number7: z.number().int().min(2).max(7),
    number8: numberEnum([3, 4, 5, 8]),
    number9: z.number().int().min(6).max(20),
    number10: z.number().int(),
    number11: z.number().int(),
    number12: z.number().int()
  }),
  simpleGenerator: () => {
    const [number2, number5, number8] = getRandomSubArrayFromArray([3, 4, 5, 8] as const, 3);

    const number1 = randomIntegerInclusive(2, number2 - 1);

    const number3 = randomIntegerInclusive(6, 20, {
      constraint: x => x % number2 === 0
    });

    const number4 = randomIntegerInclusive(2, number5 - 1);

    const number6 = randomIntegerInclusive(6, 20, {
      constraint: x => x % number5 === 0
    });

    const number7 = randomIntegerInclusive(2, number8 - 1);

    const number9 = randomIntegerInclusive(6, 20, {
      constraint: x => x % number8 === 0
    });

    const number10 = (number3 / number2) * number1;
    const number11 = (number6 / number5) * number4;
    const number12 = (number9 / number8) * number7;

    return {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      number10,
      number11,
      number12
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const {
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      number10,
      number11,
      number12
    } = question;

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="${number1}" d="${number2}" />`,
                number3
              )}   =`}
            />
          </View>
        ),
        correctAnswer: number10
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="${number4}" d="${number5}" />`,
                number6
              )}   =`}
            />
          </View>
        ),
        correctAnswer: number11
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              sentence={`${translate.answerSentences.xOfY(
                `<frac n="${number7}" d="${number8}" />`,
                number9
              )}   =`}
            />
          </View>
        ),
        correctAnswer: number12
      }
    ];

    const items = shuffle([number10, number11, number12], { random: seededRandom(question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToMatchQuestionsToTheAnswers()}
        pdfTitle={translate.instructions.matchQuestionsToTheAnswers()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={items}
        moveOrCopy="move"
        actionPanelVariant="end"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'asY',
  description: 'asY',
  keywords: ['Fractions', 'Bar model', 'Base 10', 'Divide', 'Non-unit'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(4),
      number2: z.number().int().min(3).max(5),
      number3: z.number().int().min(33).max(99)
    })
    .refine(val => val.number2 > val.number1, 'number 2 should be greater than number 1'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 5);
    const number1 = randomIntegerInclusive(2, number2 - 1);

    const number3a = randomIntegerInclusiveStep(number2, 9, number2) * 10;
    const number3b = randomIntegerInclusiveStep(number2, 9, number2);

    const number3 = number3a + number3b;

    return { number1, number2, number3 };
  },
  Component: ({ question: { number1, number2, number3 }, translate }) => {
    const answer = (number3 / number2) * number1;

    const base10Images = (number: number, { height, width }: Dimens) => {
      return (
        <BaseTenRepresentation
          usableHeight={height * 0.75}
          usableWidth={width * 0.75}
          b10Rep={{
            variant: 'Cubes',
            numbers: numberToBase10Object(number),
            arrangement: 'ltr'
          }}
        />
      );
    };

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useBarModelToFindFractionOfAnAmount()}
        Content={({ dimens }) => (
          <Table
            rowStyle={{ justifyContent: 'flex-end' }}
            items={[filledArray(base10Images(number3 / number2, dimens), number2)]}
            cellStyle={{
              //cellStyle in table has paddingTop and paddingLeft defined, so need to explicitly write them add and can't use just "padding"
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 16,
              paddingBottom: 16
            }}
          />
        )}
        sentence={`${translate.answerSentences.xOfY(
          `<frac n="${number1}" d="${number2}"/>`,
          number3.toLocaleString()
        )} = <ans/>`}
        fractionContainerStyle={{ height: 42 }}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asZ',
  description: 'asZ',
  keywords: ['Fractions', 'Bar model', 'Place value', 'Counters', 'Divide', 'Non-unit'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(7),
      number2: numberEnum([3, 4, 5, 8]),
      number3: z.number().int().min(45).max(96)
    })
    .refine(val => val.number2 > val.number1, 'number 2 should be greater than number 1'),
  simpleGenerator: () => {
    const number2 = getRandomFromArray([3, 4, 5, 8] as const);
    const number1 = randomIntegerInclusive(2, number2 - 1);

    const getNumber3 = (number: number): number => {
      if (number === 3) {
        return getRandomFromArray([45, 48, 72, 75, 78] as const);
      } else if (number === 4) {
        return getRandomFromArray([52, 56, 92, 96] as const);
      } else if (number === 5) {
        return getRandomFromArray([60, 65] as const);
      } else {
        return 96;
      }
    };

    const number3 = getNumber3(number2);

    return { number1, number2, number3 };
  },
  Component: ({ question: { number1, number2, number3 }, translate }) => {
    const answer = (number3 / number2) * number1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheFractionOfTheAmount()}
        sentence={`${translate.answerSentences.xOfY(
          `<frac n="${number1}" d="${number2}"/>`,
          number3.toLocaleString()
        )} = <ans/>`}
        textStyle={{ fontSize: 40 }}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'as0',
  description: 'as0',
  keywords: ['Fractions', 'Divide', 'Non-unit'],
  schema: z.object({
    number1: z.number().int().min(1).max(5),
    number2: numberEnum([4, 5, 8, 10]),
    number3: z.number().int().min(12).max(51)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const number2 = getRandomFromArray([4, 5, 8, 10] as const);
    const number1 = randomIntegerInclusive(1, 5, {
      constraint: x => x < number2 - 2
    });
    const number3 = randomIntegerInclusive(12, 51, {
      constraint: x => x % number2 === 0
    });

    return { number1, number2, number3 };
  },
  Component: ({ question: { number1, number2, number3 }, translate }) => {
    const number4 = ((number3 / number2) * number1).toString();
    const number5 = number1 + 1;
    const number6 = ((number3 / number2) * number5).toString();
    const number7 = number1 + 2;
    const number8 = ((number3 / number2) * number7).toString();

    const statements = [
      `${translate.answerSentences.xOfY(
        `<frac n="${number1}" d="${number2}" />`,
        number3
      )} = <ans/>`,
      `${translate.answerSentences.xOfY(
        `<frac n="${number5}" d="${number2}" />`,
        number3
      )} = <ans/>`,
      `${translate.answerSentences.xOfY(
        `<frac n="${number7}" d="${number2}" />`,
        number3
      )} = <ans/>`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.findTheFractionsOfTheAmounts()}
        sentences={statements}
        testCorrect={[[number4], [number6], [number8]]}
        questionHeight={800}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'as1',
  description: 'as1',
  keywords: ['Fractions', 'Divide', 'Non-unit'],
  schema: z.object({
    char1: nameSchema,
    char2: nameSchema,
    number1: z.number().int().min(16).max(50),
    number2: z.number().int().min(2).max(7),
    number3: numberEnum([3, 4, 5, 8]),
    number4: z.number().int().min(2).max(7),
    number5: numberEnum([3, 4, 5, 8])
  }),
  simpleGenerator: () => {
    const [char1, char2] = getRandomUniqueNames(2);

    const [number3, number5] = getRandomSubArrayFromArray([3, 4, 5, 8] as const, 2);

    const number1 = randomIntegerInclusive(16, 50, {
      constraint: x => x % number3 === 0 && x % number5 === 0
    });

    const number2 = randomIntegerInclusive(2, number3 - 1);

    const number4 = randomIntegerInclusive(2, number5 - 1, {
      constraint: x => (number1 / number5) * x !== (number1 / number3) * number2
    });

    return { char1, char2, number1, number2, number3, number4, number5 };
  },
  Component: ({ question, translate }) => {
    const { char1, char2, number1, number2, number3, number4, number5 } = question;

    const blocks = useMemo(() => {
      const items = [
        {
          value: (number1 / number3) * number2,
          character: char1,
          statement: `${translate.answerSentences.iHaveXOfYCounters(
            `<frac n="${number2}" d="${number3}" />`,
            number1.toLocaleString()
          )}`
        },
        {
          value: (number1 / number5) * number4,
          character: char2,
          statement: `${translate.answerSentences.iHaveXOfYCounters(
            `<frac n="${number4}" d="${number5}" />`,
            number1.toLocaleString()
          )}`
        }
      ];
      return shuffle(items);
    }, [char1, char2, number1, number2, number3, number4, number5, translate]);

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.instructions.characterAAndCharacterBEachHaveAFractionOfXCounters(
          blocks[0].character,
          blocks[1].character,
          number1
        )} <br/>${translate.instructions.selectCharacterWithTheMostCounters()}`}
        pdfTitle={`${translate.instructions.characterAAndCharacterBEachHaveAFractionOfXCounters(
          blocks[0].character,
          blocks[1].character,
          number1
        )} <br/>${translate.instructions.circleCharacterWithTheMostCounters()}`}
        numItems={2}
        testCorrect={[Math.max(...blocks.map(block => block.value))]}
        renderItems={({ dimens }) => {
          return blocks.map(block => ({
            value: block.value,
            component: (
              <View
                style={[
                  dimens,
                  { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }
                ]}
              >
                <SpeechBubble
                  flickLocation="top-right"
                  style={{
                    maxWidth: 0.35 * dimens.width,
                    maxHeight: 0.5 * dimens.height,
                    marginRight: 55,
                    top: 40
                  }}
                >
                  {block.statement}
                </SpeechBubble>
                <View style={{ rowGap: 15 }}>
                  <AssetSvg
                    name={getCharacterHeadSvgName(block.character)}
                    height={dimens.height * 0.5}
                    width={dimens.width * 0.3}
                  />

                  {characterNameLabel(block.character, dimens.width * 0.3)}
                </View>
              </View>
            )
          }));
        }}
      />
    );
  }
});

////
// Small Step
////
const SmallStep = newSmallStepContent({
  smallStep: 'NonUnitFractionsOfASetOfObjects',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});

export default SmallStep;
