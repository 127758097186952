import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  countRange,
  filledArray,
  range,
  sortNumberArray
} from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { integerToWord } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import { View } from 'react-native';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import { chunk } from '../../../../utils/chunk';
import { getCounterArrangementScale } from '../../../../components/question/representations/CounterBoxArrangement/counterArrangements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bav',
  description: 'bav',
  keywords: ['Number track', 'Backwards'],
  schema: z.object({
    number: z.number().int().min(3).max(10),
    variation: z.enum(['counters', 'dominos']),
    items: z.array(z.number().int()).length(8),
    color: z.enum(['blue', 'green', 'red', 'yellow']),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['counters', 'dominos'] as const);
    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;

    const { number, items } = rejectionSample(
      () => {
        const number = variation === 'counters' ? 10 : 6;

        const selectables = filledArray(0, 11).map((_, index) => index);

        const items = getRandomSubArrayFromArray(selectables, 8);

        return { number, items };
      },
      ({ number, items }) => {
        const numbers = [number, number - 1, number - 2, number - 3];

        // Check selectables have answers
        return numbers.every(num => items.includes(num));
      }
    );

    return { number, variation, items, color, seed };
  },
  Component: props => {
    const {
      question: { number, variation, items, color, seed },
      translate,
      displayMode
    } = props;

    const scale = variation === 'dominos' ? getCounterArrangementScale(number) : number * 0.6;

    return (
      <QF36ContentAndSentencesDrag
        actionPanelVariant="bottom"
        title={translate.ks1Instructions.dragTheCardsToMatchThePicturesToTheNumbers()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToMatchThePicturesToTheNumbers()}
        items={items}
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            {countRange(4).map(idx => {
              const numbers = filledArray(true, number - idx);
              const chunkedNumbers = chunk(numbers, 2);

              return (
                <View
                  key={idx}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 296,
                    height: 230,
                    padding: 4
                  }}
                >
                  {variation === 'dominos' ? (
                    <CounterBoxArrangement
                      key={idx}
                      counters={number - idx}
                      color={color}
                      seed={seed}
                      dimens={{ height: 220, width: 220 }}
                      scale={scale}
                    />
                  ) : (
                    <View style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
                      <CounterBoxArrangement
                        key={idx}
                        counters={number - idx}
                        color={color}
                        seed={seed}
                        dimens={{ height: 220, width: 220 }}
                        scale={scale}
                        arrangement={chunkedNumbers}
                      />
                    </View>
                  )}
                </View>
              );
            })}
          </View>
        )}
        sentencesStyle={{
          flexDirection: 'row',
          justifyContent: displayMode === 'digital' ? 'flex-start' : 'center'
        }}
        sentenceStyle={{
          justifyContent: 'center',
          width: 296
        }}
        sentences={['<ans />', '<ans />', '<ans />', '<ans />']}
        testCorrect={[[number], [number - 1], [number - 2], [number - 3]]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'baw',
  description: 'baw',
  keywords: ['Number track', 'Backwards'],
  schema: z.object({
    endNumber: z.number().min(0).max(5),
    answerIndexes: z.array(z.number().min(2).max(7)).min(1).refine(arrayHasNoDuplicates),
    numCells: z.number().min(6).max(8)
  }),
  simpleGenerator: () => {
    const numCells = randomIntegerInclusive(6, 8);
    const endNumber = randomIntegerInclusive(numCells, 11) - numCells;
    const numberOfAns = numCells - 3;
    const answerIndexes = randomUniqueIntegersInclusive(2, numCells - 1, numberOfAns);
    return { endNumber, answerIndexes, numCells };
  },
  Component: ({ question: { endNumber, answerIndexes, numCells }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = range(endNumber + numCells - 1, endNumber);

    const ansArray = numberArray
      .filter((_val, i) => answerIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      answerIndexes.includes(id) ? '<ans/>' : val.toLocaleString()
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bax',
  description: 'bax',
  keywords: ['Words', 'Numeral'],
  schema: z
    .object({
      startingNumber: z.number().min(4).max(10),
      answerIndexes: z.array(z.number().min(0).max(4)).length(2),
      options: z
        .array(z.number().min(0).max(10))
        .length(4)
        .refine(arrayHasNoDuplicates, 'options must not contain duplicates')
    })
    .refine(
      val => val.answerIndexes.every(i => val.options.includes(val.startingNumber - i)),
      'options must include all correct answers'
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(4, 10);
    const answerIndexes = randomUniqueIntegersInclusive(0, 4, 2);
    const answerA = startingNumber - answerIndexes[0];
    const answerB = startingNumber - answerIndexes[1];
    const [incorrectA, incorrectB] = randomUniqueIntegersInclusive(0, 10, 2, {
      constraint: x => x !== answerA && x !== answerB
    });
    const options = shuffle([answerA, answerB, incorrectA, incorrectB]);
    return { startingNumber, answerIndexes, options };
  },
  Component: ({ question: { startingNumber, answerIndexes, options }, translate, displayMode }) => {
    const numberArray = range(startingNumber, startingNumber - 4);
    const stringArray = numberArray.map(integerToWord);
    const sentence = stringArray
      .map((string, index) => (answerIndexes.includes(index) ? '<ans/>' : string))
      .join(',   ');
    const items = options.map(x => ({
      value: x,
      component: <TextStructure sentence={integerToWord(x)} />
    }));
    const correctAnswer = sortNumberArray(answerIndexes).map(index => startingNumber - index);
    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardsToPutThemInOrder()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToPutThemInOrder()}
        sentence={sentence}
        textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        items={items}
        moveOrCopy="move"
        itemVariant="shortRectangle"
        actionPanelVariant="bottom"
        pdfLayout="itemsTop"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        pdfItemVariant="shortRectangle"
        testCorrect={correctAnswer}
        questionHeight={900}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountBackwardsWithin10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
