import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { useCallback, useContext, useMemo } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import {
  ElementOrRenderFunction,
  resolveElementOrRenderFunction,
  resolveThingOrFunction,
  ThingOrFunction
} from '../../../utils/react';
import { Dimens } from '../../../theme/scaling';
import { arraysHaveSameContents, filledArray } from '../../../utils/collections';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { MeasureView } from '../../atoms/MeasureView';
import TextStructure from '../../molecules/TextStructure';
import EasyDragAndDropWithSingleZonesWithGrid from '../../draganddrop/EasyDragAndDropWithSingleZonesWithGrid';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { parseMarkup } from '../../../markup';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import React from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import { AssetSvg } from '../../../assets/svg';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  /** Content to fill the area under the title. */
  Content: ElementOrRenderFunction<{ dimens: Dimens }>;

  /** Sentences to complete. These use the markup language defined at {@link parseMarkup}. */
  sentences: string[];
  /** Generally speaking you don't need to provide this. Defaults all drop zones empty. */
  sentencesInitialState?: (T | undefined)[][];
  sentenceStyle?: StyleProp<ViewStyle>;
  sentencesStyle?: StyleProp<ViewStyle>;
  mainPanelStyle?: StyleProp<ViewStyle>;
  /**
   * The draggable items to show. Each one can just be a string/number, or it can have a custom component using the
   * {@link ItemInfo} type.
   *
   * If providing just numbers, .toLocaleString() will be called for you before rendering.
   */
  items: ThingOrFunction<((T & (string | number)) | ItemInfo<T>)[]>;
  itemVariant?: 'square' | 'rectangle';
  pdfItemVariant?: 'square' | 'pdfSquare' | 'rectangle';
  /** Default: bottom. */
  actionPanelVariant?: 'end' | 'endWide' | 'bottom';
  /**
   * Whether each draggable can exist in multiple drop zones or not, and thus whether they are moved around
   * vs copies of them are moved around. Default 'move'.
   */
  moveOrCopy?: 'move' | 'copy';

  /** Defaults to checking all answer boxes have a draggable in. */
  testComplete?: (userAnswer: (T | undefined)[][]) => boolean;

  /** Either an array of correct answers, or a function for more advanced cases. */
  testCorrect: (T | undefined)[][] | ((userAnswer: (T | undefined)[][]) => boolean);
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: { answersToDisplay?: (T | undefined)[][]; answerText?: string };
};

/**
 * Layout containing a title above, drag and drop zone in the action panel, and content with 2, 3 or 4 sentences
 * laid out in a grid format below in the main panel.
 * This is based heavily off of QF36 - the only notable difference is the grid layout of the sentences.
 *
 * ### Item values
 *
 * The draggable item's values can be either string or number. If number, .toLocaleString() is called for you for
 * rendering.
 *
 * ### Generic arguments
 *
 * The generic argument is the type that the draggables use for their value. Most of the time this is string | number,
 * but specifying it explicitly as something more specific may make it easier to write testComplete/testCorrect functions.
 *
 * ### Testing functions (testCorrect/testComplete)
 *
 * The easiest way to use these is to omit testComplete, and provide an array for testCorrect, e.g.
 *
 * ```
 *   testCorrect={[[1, 2], [3]]}
 * ```
 */
export default function QF35ContentAndSentencesDragGridLayout<T>({
  title,
  pdfTitle,
  Content,
  sentences,
  sentencesInitialState,
  sentenceStyle,
  sentencesStyle,
  mainPanelStyle,
  items: itemsProp,
  itemVariant = 'square',
  pdfItemVariant = 'pdfSquare',
  actionPanelVariant = 'bottom',
  moveOrCopy = 'move',
  testComplete: testCompleteProp,
  testCorrect: testCorrectProp,
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const borderColor = displayMode === 'digital' ? colors.prussianBlue : colors.black;
  const borderWidth = displayMode === 'digital' ? 1 : 2;
  const separatorLineLength = displayMode === 'digital' ? 122 : 180;

  // Set up items - handle the case where we were given a list of numbers/strings.
  const items = useMemo(() => {
    return resolveThingOrFunction(itemsProp).map(it =>
      typeof it === 'number' || typeof it === 'string'
        ? { value: it, component: it.toLocaleString() }
        : it
    );
  }, [itemsProp]);

  // Set up testComplete
  const testComplete = useCallback(
    (userAnswer: (T | undefined)[][]) => {
      return testCompleteProp !== undefined
        ? // If provided a function for sentences, check that
          testCompleteProp(userAnswer)
        : // Otherwise default to checking every answer is not undefined
          userAnswer.every(sentenceAnswer => sentenceAnswer.every(it => it !== undefined));
    },
    [testCompleteProp]
  );

  // Set up testCorrect
  const testCorrect = useCallback(
    (userAnswer: (T | undefined)[][]) => {
      if (typeof testCorrectProp === 'function') {
        return testCorrectProp(userAnswer);
      } else {
        return arraysHaveSameContents(userAnswer, testCorrectProp, arraysHaveSameContents);
      }
    },
    [testCorrectProp]
  );

  const numberOfAnsArray = sentences.map(parseMarkup).map(it => it.numberOfAns);
  const defaultState =
    sentencesInitialState ??
    numberOfAnsArray.map(answersThisSentence => filledArray(undefined, answersThisSentence));

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const markSchemeAnswer =
      typeof testCorrectProp === 'function'
        ? customMarkSchemeAnswer?.answersToDisplay
        : testCorrectProp;

    return (
      <EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState
        id="draganddrop"
        items={items}
        moveOrCopy="copy"
        variant={pdfItemVariant}
        columnsPerRow={numberOfAnsArray}
        textVariant="WRN700"
        defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <>
              <DragAndDropSectionPDF
                style={{ flex: 0.2 }}
                itemsStyle={{ flexDirection: 'row', gap: 16, flexWrap: 'wrap' }}
              >
                {items.map((_item, index) => (
                  <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
                ))}
              </DragAndDropSectionPDF>
              <MeasureView>
                {dimens => resolveElementOrRenderFunction(Content, { dimens })}
              </MeasureView>
              <View
                style={[
                  styles.sentences,
                  { flexDirection: 'row', flexWrap: 'wrap' },
                  sentencesStyle
                ]}
              >
                {sentences.map((sentence, sentenceIndex) => {
                  return (
                    <React.Fragment key={sentenceIndex}>
                      {/* Grid lines drawn using the View elements below: */}
                      {sentenceIndex === 1 && (
                        <View
                          style={{
                            width: 2,
                            borderWidth,
                            height: separatorLineLength,
                            borderColor
                          }}
                        ></View>
                      )}
                      {sentenceIndex === 3 && (
                        <View
                          style={{
                            width: 2,
                            borderWidth,
                            height: separatorLineLength,
                            borderColor
                          }}
                        ></View>
                      )}
                      {sentenceIndex === 2 && (
                        <View
                          style={{
                            width: '100%',
                            borderWidth,
                            height: 2,
                            borderColor
                          }}
                        ></View>
                      )}
                      <TextStructure
                        key={sentenceIndex}
                        sentence={sentence}
                        style={[
                          {
                            width: '49%',
                            justifyContent: 'center',
                            paddingTop: sentenceIndex < 2 ? 0 : 10,
                            paddingBottom: sentenceIndex < 2 ? 10 : 0
                          },
                          sentenceStyle
                        ]}
                        inputBox={({ index }) => (
                          <>
                            {displayMode === 'markscheme' && (
                              <AssetSvg
                                name="True"
                                width={50}
                                style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
                              />
                            )}
                            <EasyDragAndDropWithSingleZonesWithGrid.ZoneSingle
                              key={index}
                              row={sentenceIndex}
                              column={index}
                            />
                          </>
                        )}
                      />
                    </React.Fragment>
                  );
                })}
              </View>
            </>
          }
          questionHeight={questionHeight}
          {...props}
        />
      </EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={itemVariant}
      columnsPerRow={numberOfAnsArray}
      defaultState={defaultState}
      testComplete={testComplete}
      testCorrect={testCorrect}
      textVariant="WRN700"
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={
          <DragAndDropSection
            style={{ alignSelf: 'center' }}
            itemsStyle={{ justifyContent: 'center', gap: 8 }}
          >
            {items.map((_item, index) => (
              <EasyDragAndDropWithSingleZonesWithGrid.Source key={index} id={index} />
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <View style={[styles.mainPanel, mainPanelStyle]}>
            <MeasureView>
              {dimens => resolveElementOrRenderFunction(Content, { dimens })}
            </MeasureView>
            <View
              style={[styles.sentences, { flexDirection: 'row', flexWrap: 'wrap' }, sentencesStyle]}
            >
              {sentences.map((sentence, sentenceIndex) => {
                return (
                  <React.Fragment key={sentenceIndex}>
                    {/* Grid lines drawn using the View elements below: */}
                    {sentenceIndex === 1 && (
                      <View
                        style={{
                          width: 2,
                          borderWidth,
                          height: separatorLineLength,
                          borderColor
                        }}
                      ></View>
                    )}
                    {sentenceIndex === 3 && (
                      <View
                        style={{
                          width: 2,
                          borderWidth,
                          height: separatorLineLength,
                          borderColor
                        }}
                      ></View>
                    )}
                    {sentenceIndex === 2 && (
                      <View
                        style={{
                          width: '100%',
                          borderWidth,
                          height: 2,
                          borderColor
                        }}
                      ></View>
                    )}
                    <TextStructure
                      key={sentenceIndex}
                      sentence={sentence}
                      style={[
                        {
                          width: '49%',
                          justifyContent: 'center',
                          paddingTop: sentenceIndex < 2 ? 0 : 10,
                          paddingBottom: sentenceIndex < 2 ? 10 : 0
                        },
                        sentenceStyle
                      ]}
                      inputBox={({ index }) => (
                        <EasyDragAndDropWithSingleZonesWithGrid.ZoneSingle
                          key={index}
                          row={sentenceIndex}
                          column={index}
                        />
                      )}
                    />
                  </React.Fragment>
                );
              })}
            </View>
          </View>
        }
        {...props}
      />
    </EasyDragAndDropWithSingleZonesWithGrid.ProviderWithState>
  );
}

const styles = StyleSheet.create({
  mainPanel: {
    flex: 1,
    justifyContent: 'space-evenly'
  },

  sentences: {
    marginTop: 16,
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly'
  }
});
