import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { filledArray } from '../../../../utils/collections';
import { QuadrilateralWithDimens } from '../../../../components/question/representations/QuadrilateralWithDimens';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { compareFractions, simplify } from '../../../../utils/fractions';
import { MULT } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import { containAspectRatio } from '../../../../theme/scaling';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aq7',
  description: 'aq7',
  keywords: ['Fraction', 'Multiply'],
  schema: z.object({
    denominatorA: z.number().int().min(3).max(5),
    denominatorB: z.number().int().min(3).max(7)
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(3, 5);

    const denominatorB = randomIntegerInclusive(3, 7, {
      constraint: x => x !== denominatorA
    });

    return { denominatorA, denominatorB };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB },
      translate
    } = props;

    // Calculate arrays for displaying shape
    const shadedRow = filledArray(true, 1).concat(filledArray(false, denominatorA - 1));
    const shadedArea = Array.from({ length: 1 }, () => [...shadedRow]);
    const unshadedArea = Array.from({ length: denominatorB - 1 }, () => [
      ...filledArray(false, denominatorA)
    ]);

    const area = shadedArea.concat(unshadedArea);

    // Calculate answer
    const ansNumerator = 1;
    const ansDenominator = denominatorA * denominatorB;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheDiagramToCompleteMultiplication()}
        sentence={`<frac n='${(1).toLocaleString()}' d='${denominatorA.toLocaleString()}'/> ${MULT} <frac n='${(1).toLocaleString()}' d='${denominatorB.toLocaleString()}'/> = <frac nAns='' dAns=''/>`}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid
            givenShape={area}
            // Both height and width need to use dimens.height to make the grid itself a square:
            dimens={{ height: dimens.height, width: dimens.height }}
            cellsAreNotSquares
            borderWidthAmount={2}
          />
        )}
        testCorrect={answer => compareFractions([...answer], [ansNumerator, ansDenominator])}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [ansNumerator.toLocaleString(), ansDenominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'aq8',
  description: 'aq8',
  keywords: ['Fraction', 'Multiply'],
  schema: z.object({
    number1: z.number().int().min(2).max(4),
    number2: z.number().int().min(3).max(5),
    number3: z.number().int().min(1).max(7),
    number4: z.number().int().min(3).max(7)
  }),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 5);
    const number1 = randomIntegerInclusive(2, number2 - 1);

    const number4 = randomIntegerInclusive(3, 7, {
      constraint: x => x !== number2
    });
    const number3 = randomIntegerInclusive(1, number4 - 1);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;
    // Simplify display fractions
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);
    const [simplifiedNum3, simplifiedNum4] = simplify(number3, number4);

    // Calculate arrays for displaying shape
    const shadedRow = filledArray(true, simplifiedNum1).concat(
      filledArray(false, simplifiedNum2 - simplifiedNum1)
    );
    const shadedArea = Array.from({ length: simplifiedNum3 }, () => [...shadedRow]);
    const unshadedArea = Array.from({ length: simplifiedNum4 - simplifiedNum3 }, () => [
      ...filledArray(false, simplifiedNum2)
    ]);

    const area = shadedArea.concat(unshadedArea);

    // Calculate answer
    const ansNumerator = number1 * number3;
    const ansDenominator = number2 * number4;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheDiagramToCompleteMultiplication()}
        sentence={`<frac n='${simplifiedNum1}' d='${simplifiedNum2}'/> ${MULT} <frac n='${simplifiedNum3}' d='${simplifiedNum4}'/> = <frac nAns='' dAns=''/>`}
        Content={({ dimens }) => (
          <DisplayShapeOnGrid
            givenShape={area}
            // Both height and width need to use dimens.height to make the grid itself a square:
            dimens={{ height: dimens.height, width: dimens.height }}
            cellsAreNotSquares
            borderWidthAmount={2}
          />
        )}
        testCorrect={answer => compareFractions([...answer], [ansNumerator, ansDenominator])}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [ansNumerator.toLocaleString(), ansDenominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        pdfDirection="column"
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'aq9',
  description: 'aq9',
  keywords: ['Fraction', 'Multiply'],
  schema: z
    .object({
      number1: z.number().int().min(1).max(7),
      number2: z.number().int().min(4).max(8),
      number3: z.number().int().min(1).max(5),
      number4: z.number().int().min(3).max(6)
    })
    .refine(val => val.number1 < val.number2, 'number1 must be less than number2')
    .refine(val => val.number3 < val.number4, 'number3 must be less than number4')
    .refine(val => val.number4 !== val.number2, 'number4 must not be equal to number2'),
  simpleGenerator: () => {
    const number3 = randomIntegerInclusive(1, 5);

    const number4LowerBound = number3 < 3 ? 3 : number3 + 1;

    const number4 = randomIntegerInclusive(number4LowerBound, 6);

    const number1 = randomIntegerInclusive(1, 7);

    const number2LowerBound = number1 < 4 ? 4 : number1 + 1;

    const number2 = randomIntegerInclusive(number2LowerBound, 8, {
      constraint: x => x !== number4
    });

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate,
      displayMode
    } = props;

    const [simplifiedANumerator, simplifiedADenominator] = simplify(number1, number2);

    const [simplifiedBNumerator, simplifiedBDenominator] = simplify(number3, number4);

    const [simplifiedAnswerNumerator, simplifiedAnswerDenominator] = simplify(
      number1 * number3,
      number2 * number4
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        inputMaxCharacters={2}
        testCorrect={answer =>
          compareFractions(
            [answer[0], answer[1]],
            [simplifiedAnswerNumerator, simplifiedAnswerDenominator]
          )
        }
        sentence={`<frac nAns='' dAns=''/> = <frac n='${simplifiedANumerator}' d='${simplifiedADenominator}'/> ${MULT} <frac n='${simplifiedBNumerator}' d='${simplifiedBDenominator}'/>`}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            simplifiedAnswerNumerator.toLocaleString(),
            simplifiedADenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ara',
  description: 'ara',
  keywords: ['Fraction', 'Multiply'],
  schema: z
    .object({
      numeratorA: z.number().int().min(1).max(8),
      denominatorA: z.number().int().min(2).max(9),
      numeratorB: z.number().int().min(1).max(8),
      denominatorB: z.number().int().min(2).max(9),
      equationLeftOrRight: z.enum(['left', 'right']),
      answerPosition: z.enum([
        'numeratorA',
        'denominatorA',
        'numeratorB',
        'denominatorB',
        'numeratorC',
        'denominatorC'
      ])
    })
    .refine(val => val.numeratorA < val.denominatorA, 'numeratorA must be less than denominatorA.')
    .refine(val => val.numeratorB < val.denominatorB, 'numeratorB must be less than denominatorB.'),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 9);

    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

    const denominatorB = randomIntegerInclusive(2, 9);

    const numeratorB = randomIntegerInclusive(1, denominatorB - 1);

    const answerPosition = getRandomFromArray([
      'numeratorA',
      'denominatorA',
      'numeratorB',
      'denominatorB',
      'numeratorC',
      'denominatorC'
    ] as const);

    const equationLeftOrRight = getRandomFromArray(['left', 'right'] as const);

    return {
      numeratorA,
      denominatorA,
      numeratorB,
      denominatorB,
      answerPosition,
      equationLeftOrRight
    };
  },
  Component: props => {
    const {
      question: {
        numeratorA,
        denominatorA,
        numeratorB,
        denominatorB,
        answerPosition,
        equationLeftOrRight
      },
      translate
    } = props;

    const numeratorC = numeratorA * numeratorB;

    const denominatorC = denominatorA * denominatorB;

    const fractionA =
      answerPosition === 'numeratorA'
        ? `<frac nAns='' d='${denominatorA.toLocaleString()}'/>`
        : answerPosition === 'denominatorA'
        ? `<frac n='${numeratorA.toLocaleString()}' dAns=''/>`
        : `<frac n='${numeratorA.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`;

    const fractionB =
      answerPosition === 'numeratorB'
        ? `<frac nAns='' d='${denominatorB.toLocaleString()}'/>`
        : answerPosition === 'denominatorB'
        ? `<frac n='${numeratorB.toLocaleString()}' dAns=''/>`
        : `<frac n='${numeratorB.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`;

    const fractionC =
      answerPosition === 'numeratorC'
        ? `<frac nAns='' d='${denominatorC.toLocaleString()}'/>`
        : answerPosition === 'denominatorC'
        ? `<frac n='${numeratorC.toLocaleString()}' dAns=''/>`
        : `<frac n='${numeratorC.toLocaleString()}' d='${denominatorC.toLocaleString()}'/>`;

    const [title, expectedAnswer] = (() => {
      switch (answerPosition) {
        case 'numeratorA':
          return [translate.instructions.workOutMissingNumerator(), numeratorA.toString()];
        case 'denominatorA':
          return [translate.instructions.workOutMissingDenominator(), denominatorA.toString()];
        case 'numeratorB':
          return [translate.instructions.workOutMissingNumerator(), numeratorB.toString()];
        case 'denominatorB':
          return [translate.instructions.workOutMissingDenominator(), denominatorB.toString()];
        case 'numeratorC':
          return [translate.instructions.workOutMissingNumerator(), numeratorC.toString()];
        case 'denominatorC':
          return [translate.instructions.workOutMissingDenominator(), denominatorC.toString()];
      }
    })();

    const sentence =
      equationLeftOrRight === 'left'
        ? `${fractionA} ${MULT} ${fractionB} = ${fractionC}`
        : `${fractionC} = ${fractionA} ${MULT} ${fractionB}`;

    return (
      <QF2AnswerBoxOneSentence
        title={title}
        testCorrect={[expectedAnswer]}
        sentence={sentence}
        textStyle={{ fontSize: 40 }}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'arb',
  description: 'arb',
  keywords: ['Fraction', 'Multiply'],
  schema: z.object({
    numerator: z.number().int().min(1).max(4),
    denominator: numberEnum([4, 5, 10])
  }),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([4, 5, 10] as const);

    const numerator = (() => {
      switch (denominator) {
        case 4:
          return 1;
        case 5:
          return randomIntegerInclusive(1, 4);
        case 10:
          return getRandomFromArray([1, 3] as const);
      }
    })();

    return { numerator, denominator };
  },
  Component: props => {
    const {
      question: { numerator, denominator },
      translate
    } = props;

    const fraction = numerator / denominator;

    const correctAnswer = 20 * fraction;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        inputMaxCharacters={2}
        testCorrect={answer => parseInt(answer[0]) * parseInt(answer[1]) === correctAnswer}
        sentence={`<frac n='${numerator}' d='${denominator}'/> = <frac nAns='' d='4'/> ${MULT} <frac nAns='' d='5'/>`}
        textStyle={{ fontSize: 40 }}
        fractionContainerStyle={{ height: 96 }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationThatMultiplyToX(numerator)
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'arc',
  description: 'arc',
  keywords: ['Fraction', 'Multiply'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number2: z.number().int().min(3).max(10),
    number3: z.number().int().min(1).max(5),
    number4: z.number().int().min(3).max(8)
  }),
  questionHeight: 1500,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(3, 10);
    const number1 = randomIntegerInclusive(2, number2 - 1);

    const number4 = randomIntegerInclusive(3, 8, {
      constraint: x => x !== number2
    });
    const number3 = randomIntegerInclusive(1, 5, {
      constraint: x => x < number4
    });

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate,
      displayMode
    } = props;

    // Simplify all fractions displayed
    const [simplifedNum1, simplifiedNum2] = simplify(number1, number2);
    const [simplifedNum3, simplifiedNum4] = simplify(number3, number4);

    // Calculate answer in simplest form
    const [ansNumerator, ansDenominator] = simplify(number1 * number3, number2 * number4);

    const xValue = number1 / number2;
    const yValue = number3 / number4;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.calcAreaOfShapeGiveAnsInSimplestForm()}
        sentence={translate.units.stringM2(`<frac nAns='' dAns=''/>`)}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{
              width: dimens.width * 0.6,
              height: displayMode !== 'digital' ? dimens.height * 0.6 : dimens.height
            }}
            x={xValue}
            y={yValue}
            widthLabel={translate.units.stringM(
              `<frac n="${simplifedNum1}" d="${simplifiedNum2}" />`
            )}
            heightLabel={translate.units.stringM(
              `<frac n='${simplifedNum3}' d='${simplifiedNum4}'/>`
            )}
            // Font size and positioning of labels needs overriding for PDFs:
            labelTextStyle={displayMode !== 'digital' && { fontSize: 50 }}
            labelTopContainerStyle={displayMode !== 'digital' && { top: -16 }}
            containerStyle={{
              alignSelf: displayMode === 'digital' ? 'flex-start' : 'center',
              marginTop: 40,
              marginLeft:
                displayMode === 'digital'
                  ? containAspectRatio(dimens, xValue / yValue).width < 320
                    ? 100
                    : 0
                  : -180
            }}
          />
        )}
        testCorrect={[ansNumerator.toString(), ansDenominator.toString()]}
        inputMaxCharacters={2}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        customMarkSchemeAnswer={{
          answersToDisplay: [ansNumerator.toLocaleString(), ansDenominator.toLocaleString()],
          answerText: translate.markScheme.fractionMustBeInSimplestForm()
        }}
        questionHeight={1500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyFractionsByFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
