import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  randomNumberWithSpecificDigit,
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle,
  rejectionSample
} from '../../../../utils/random';
import { CounterVariantNoBlockSchema } from '../../../../components/question/representations/types';
import ReadPlaceValueChart from '../../../../components/question/questionFormats/ReadPlaceValueChart';
import CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import {
  Digit,
  numberToBase10Object,
  powersOfTenPowToWord,
  ScientificNotation
} from '../../../../utils/math';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';

////
// Questions
////

export const Question1 = newQuestionContent({
  uid: 'ads',
  description: 'ads',
  keywords: ['Place value', 'Represent', '1,000,000', 'Million', 'Chart'],
  schema: z.object({
    number: z.number().int().min(100001).max(999999),
    counterVariant: CounterVariantNoBlockSchema
  }),
  simpleGenerator: () => {
    // Generate random number between 100001 and 999999
    const number = randomIntegerInclusive(100001, 999999);

    const counterVariant = 'greyCounter' as const;

    return { number, counterVariant };
  },
  Component: ({ question }) => (
    <ReadPlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[5, 4, 3, 2, 1, 0]}
      counterVariant={question.counterVariant}
      headerVariant={'shortName'}
      questionHeight={1050}
    />
  ),
  questionHeight: 1050
});

export const Question2 = newQuestionContent({
  uid: 'adt',
  description: 'adt',
  keywords: ['Place value', 'Represent', '1,000,000', 'Million', 'Chart'],
  schema: z.object({
    number: z.number().min(100001).max(999999)
  }),
  questionHeight: 850,
  example: {
    number: 132465
  },
  simpleGenerator: () => {
    const number = randomIntegerInclusive(100001, 999999);
    return { number };
  },
  Component: ({ question, translate, ...props }) => (
    <CreatePlaceValueChart
      number={ScientificNotation.fromNumber(question.number)}
      columnsToShow={[5, 4, 3, 2, 1, 0]}
      counterVariant="greyCounter"
      headerVariant="shortName"
      questionHeight={850}
      pdfTitle={translate.instructions.drawCountersToRepresentNum(question.number.toLocaleString())}
      {...props}
    />
  )
});

export const Question3 = newQuestionContent({
  uid: 'adu',
  description: 'adu',
  keywords: ['Place value', 'Represent', '1,000,000', 'Million', 'Bar model'],
  schema: z.object({
    total: z.number().min(100001).max(999999),
    powerOfAnswer: z.number().int().min(2).max(5) as z.Schema<2 | 3 | 4 | 5>,
    answerIndex: z.number().min(0).max(1)
  }),
  simpleGenerator: () => {
    const powerOfAnswer = randomIntegerInclusive(2, 5) as 2 | 3 | 4 | 5;

    // Prevents answer being 0 and rejects multiples of 100,000
    const total = rejectionSample(
      () =>
        randomNumberWithSpecificDigit(
          powerOfAnswer,
          { lower: 100001, upper: 999999 },
          randomIntegerInclusive(1, 9) as Digit
        ),
      x => x % 100000 !== 0
    );

    const answerIndex = randomIntegerInclusive(0, 1);

    return { total, powerOfAnswer, answerIndex };
  },
  Component: ({ question: { total, powerOfAnswer, answerIndex }, translate, ...props }) => {
    const hiddenBarFactor = ScientificNotation.fromNumber(total).unsignedDigitAt(powerOfAnswer);
    const hiddenBarAmount = hiddenBarFactor ? Math.pow(10, powerOfAnswer) * hiddenBarFactor : 0;
    const givenNumber = total - hiddenBarAmount;

    const numbers = [
      [total],
      answerIndex === 0 ? [hiddenBarAmount, givenNumber] : [givenNumber, hiddenBarAmount]
    ];
    const answerIndices = [[], [answerIndex]];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={total}
        oneFontSize
        {...props}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'adv',
  description: 'adv',
  keywords: ['Place value', 'Represent', '1,000,000', 'Million', 'Select'],
  schema: z.object({
    numbers: z.number().int().min(100000).max(999999).array().length(8),
    digit: z.number().int().min(1).max(9),
    power: z.enum(['hundreds', 'thousands', 'tenThousands', 'hundredThousands'])
  }),
  example: {
    numbers: [214040, 359865, 211234, 751009, 315249, 462048, 586000, 844616],
    digit: 4,
    power: 'thousands'
  },
  simpleGenerator: () => {
    const power = getRandomFromArray([
      'hundreds',
      'thousands',
      'tenThousands',
      'hundredThousands'
    ] as const);
    const digit = randomIntegerInclusive(1, 9) as Digit;
    const range = { lower: 100000, upper: 999999 };

    const num1 = randomNumberWithSpecificDigit(5, range, digit);
    const num2 = randomNumberWithSpecificDigit(5, range, digit);
    const num3 = randomNumberWithSpecificDigit(4, range, digit);
    const num4 = randomNumberWithSpecificDigit(4, range, digit);
    const num5 = randomNumberWithSpecificDigit(3, range, digit);
    const num6 = randomNumberWithSpecificDigit(3, range, digit);
    const num7 = randomNumberWithSpecificDigit(2, range, digit);
    const num8 = randomNumberWithSpecificDigit(2, range, digit);

    return { numbers: shuffle([num1, num2, num3, num4, num5, num6, num7, num8]), digit, power };
  },
  Component: props => {
    const {
      question: { numbers, digit, power },
      translate
    } = props;

    const powerAsString = translate.powersOfTen[power](0);

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectNumbersThatHaveXInTheYPlace(digit, powerAsString)}
        pdfTitle={translate.instructions.circleNumbersThatHaveXInTheYPlace(digit, powerAsString)}
        testCorrect={numbers.filter(it => numberToBase10Object(it)[power] === digit)}
        {...props}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'adw',
  description: 'adw',
  keywords: ['Place value', 'Represent', '1,000,000', 'Million'],
  schema: z
    .object({
      digits: z.array(z.number().int().min(0).max(9)).min(6).max(6),
      powerOfTen: z.number().int().min(2).max(5) as z.Schema<2 | 3 | 4 | 5>,
      value: z.number().int().min(0).max(9)
    })
    .refine(val => val.digits.includes(val.value), 'The value must be one of the choices'),
  example: {
    value: 5,
    digits: [1, 5, 2, 7, 6, 8],
    powerOfTen: 2
  },
  simpleGenerator: () => {
    const numOfValues = 6;
    const value = randomIntegerInclusive(0, 9);

    // Ensure question doesn't ask for 0 in the first column - also, only allow the first four unit types to be selected
    const powerOfTen = (
      value === 0 ? randomIntegerInclusive(2, 4) : randomIntegerInclusive(2, 5)
    ) as 2 | 3 | 4 | 5;

    // Ensure each digit will be unique
    const digits = new Set<number>();

    digits.add(value);
    while (digits.size < numOfValues) {
      digits.add(randomIntegerInclusive(0, 9));
    }

    const shuffled = shuffle(Array.from(digits));

    return { digits: shuffled, value, powerOfTen };
  },
  Component: props => {
    const {
      question: { digits, value, powerOfTen },
      translate
    } = props;

    const testCorrect = (ans: readonly (string | undefined)[]): boolean => {
      if (ans[0] === (0).toLocaleString()) {
        return false;
      }
      return ScientificNotation.fromFixedString(ans.join('')).digitAt(powerOfTen) === value;
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.create6DigitNumberWith(
          value,
          translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
        )}
        pdfTitle={translate.instructions.create6DigitNumberWithDigitsOnlyUsedOnce(
          value,
          translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
        )}
        items={digits.map(num => num.toLocaleString())}
        sentence={'<ans/> '.repeat(6)}
        testCorrect={testCorrect}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyNumberWithXInTheYPlace(
            value,
            translate.powersOfTen[powersOfTenPowToWord[powerOfTen]](2) // 2 to make sure it is plural
          )
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

export const Question6 = newQuestionContent({
  uid: 'adx',
  description: 'adx',
  keywords: ['Place value', 'Represent', '1,000,000', 'Million', 'Part-whole'],
  schema: z
    .object({
      number: z.number().int().min(101000).max(999000).step(1000),
      thousands: z.number().int().min(0).max(9000),
      tenThousands: z.number().int().min(0).max(90000),
      hundredThousands: z.number().int().min(100000).max(900000),
      unitOfAnswer: z.enum(['thousands', 'ten thousands', 'hundred thousands'])
    })
    .refine(
      val => val.thousands + val.tenThousands + val.hundredThousands === val.number,
      'Thousands, ten thousands and hundred thousands must be equal to the whole'
    ),
  simpleGenerator: () => {
    const unitOfAnswer = getRandomFromArray([
      'thousands',
      'ten thousands',
      'hundred thousands'
    ] as const);
    // Conditions to ensure the answer needed is never 0
    const thousands =
      unitOfAnswer === 'thousands'
        ? randomIntegerInclusive(1, 9) * 1000
        : randomIntegerInclusive(0, 9) * 1000;
    // Added condition of ensuring that thousands and ten-thousands are never both 0
    const tenThousands =
      thousands === 0 || unitOfAnswer === 'ten thousands'
        ? randomIntegerInclusive(1, 9) * 10000
        : randomIntegerInclusive(0, 9) * 10000;
    const hundredThousands = randomIntegerInclusive(1, 9) * 100000;
    const number = thousands + tenThousands + hundredThousands;

    return { number, thousands, tenThousands, hundredThousands, unitOfAnswer };
  },
  Component: ({ question, ...props }) => {
    const { number, thousands, tenThousands, hundredThousands, unitOfAnswer } = question;

    const { translate } = props;

    let partition: (number | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'thousands') {
      partition = ['$ans', hundredThousands + tenThousands];
      correctAnswer = thousands;
    } else if (unitOfAnswer === 'ten thousands') {
      partition = ['$ans', hundredThousands + thousands];
      correctAnswer = tenThousands;
    } else {
      partition = [tenThousands + thousands, '$ans'];
      correctAnswer = hundredThousands;
    }

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NumbersTo1000000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
