import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Clock from '../../../../components/question/representations/Clock';
import {
  randomIntegerInclusive,
  seededRandom,
  shuffle,
  getRandomBoolean
} from '../../../../utils/random';
import {
  clockColourVariantSchema,
  convert12hToSpokenString,
  getRandomClockColourVariant
} from '../../../../utils/time';
import { newQuestionContent } from '../../../Question';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { isEqual } from '../../../../utils/matchers';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfJ',
  description: 'bfJ',
  keywords: ['Half past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    incorrectHoursVariation: z.boolean(),
    clockColourVariant: clockColourVariantSchema
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);
    const incorrectHoursVariation = getRandomBoolean();
    const clockColourVariant = getRandomClockColourVariant();

    return { hours, incorrectHoursVariation, clockColourVariant };
  },
  Component: props => {
    const {
      question: { hours, incorrectHoursVariation, clockColourVariant },
      translate
    } = props;

    const minutes = 30;

    const random = seededRandom(props.question);

    const correctTime = {
      string: convert12hToSpokenString(translate, hours, minutes),
      value: 'C'
    };

    const incorrectHoursA =
      hours === 11 ? 0 : hours === 0 || incorrectHoursVariation ? hours + 1 : hours - 1;

    const incorrectTimeA = {
      string: convert12hToSpokenString(translate, incorrectHoursA, minutes),
      value: 'A'
    };

    const incorrectTimeB = {
      string: convert12hToSpokenString(translate, hours, 0),
      value: 'B'
    };

    const options = shuffle([correctTime, incorrectTimeA, incorrectTimeB], { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheTimeShown()}
        pdfTitle={translate.ks1PDFInstructions.tickTheTimeShown()}
        testCorrect={['C']}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours, minutes }}
            width={Math.min(dimens.width * 0.9, dimens.height * 0.9)}
            interactive={false}
            clockColourVariant={clockColourVariant}
            withoutMinuteIntervals={true}
          />
        )}
        renderItems={options.map(({ string, value }) => ({
          value,
          component: <Text variant="WRN700">{string}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bfK',
  description: 'bfK',
  keywords: ['Half past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    clockColourVariant: clockColourVariantSchema
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);
    const clockColourVariant = getRandomClockColourVariant();

    return { hours, clockColourVariant };
  },
  Component: props => {
    const {
      question: { hours, clockColourVariant },
      translate
    } = props;

    const minutes = 30;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatTimeDoesTheClockShow()}
        sentence={translate.ks1AnswerSentences.halfPastAns()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[hours.toString()]}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours === 12 ? 0 : hours, minutes }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
            clockColourVariant={clockColourVariant}
            withoutMinuteIntervals={true}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bfL',
  description: 'bfL',
  keywords: ['Half past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    clockColourVariant: clockColourVariantSchema
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);
    const clockColourVariant = getRandomClockColourVariant();

    return { hours, clockColourVariant };
  },
  Component: props => {
    const {
      question: { hours, clockColourVariant },
      translate
    } = props;

    const minutes = 30;

    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    return (
      <QF40SetTheClockFace
        title={translate.ks1Instructions.dragTheHandsOfClockToShowX(translatedTime)}
        pdfTitle={translate.ks1PDFInstructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes })}
        exampleCorrectAnswer={{ hours, minutes }}
        startTime={{ hours: 0, minutes: 0 }}
        snapMinutes={5}
        clockColourVariant={clockColourVariant}
        withoutMinuteIntervals={true}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TellTheTimeToTheHalfHour',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
