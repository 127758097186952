import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from '../i18n/i18n-types';

export const dayNames = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
] as const;

export const daySchema = z.enum(dayNames);

export function getRandomDay() {
  return getRandomFromArray(dayNames);
}

export function getRandomUniqueDays(numberOfDays: number) {
  return getRandomSubArrayFromArray(dayNames, numberOfDays);
}

export type DayName = (typeof dayNames)[number];

export const isDay = (x: string): x is DayName => dayNames.includes(x as DayName);

export const dayAsWord = (day: DayName, translate: TranslationFunctions) => {
  return translate.time[day]();
};

/**
 *
 * Get a random amount of days whilst ensuring a specific day is included in the returned list
 * @param count - Amount of days returned
 * @param requiredDay - Specific day that needs to be included in days returned
 */

export const getRandomAmountOfDaysIncludingDay = (count: number, requiredDay: DayName) => {
  if (count > dayNames.length) {
    throw new Error(`Count cannot be greater than the length of ${dayNames.length}.`);
  }

  // Ensure requiredDay exists in the array
  const requiredIndex = dayNames.indexOf(requiredDay);
  if (requiredIndex === -1) {
    throw new Error(`The required value "${requiredDay}" does not exist in the array.`);
  }

  // Get a random start index and adjust it to include the requiredDay
  let startIndex = Math.floor(Math.random() * dayNames.length);

  // Adjust startIndex if requiredDay is not within the selected range
  if (!(startIndex <= requiredIndex && requiredIndex < startIndex + count)) {
    startIndex =
      (requiredIndex - Math.floor(Math.random() * count) + dayNames.length) % dayNames.length;
  }

  // Collect 'count' items starting from startIndex, wrapping around if necessary
  return Array.from({ length: count }, (_, i) => dayNames[(startIndex + i) % dayNames.length]);
};
