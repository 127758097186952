import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  seededRandom
} from '../../../../utils/random';
import QF24bCreateSymmetricalShape from '../../../../components/question/questionFormats/QF24bCreateSymmetricalShape';
import { createShapeWithSquares, createShapeWithSquaresDiagonal } from '../../../../utils/shapes';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import { Point2d } from '../../../../utils/vectors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ayQ',
  description: 'ayQ',
  keywords: ['Lines of symmetry', 'Horizontal', 'Vertical'],
  schema: z.object({
    squaresInShape: z.number().int().min(4).max(12),
    isInY: z.boolean()
  }),
  simpleGenerator: () => {
    const squaresInShape = randomIntegerInclusive(4, 12);
    const isInY = getRandomBoolean();

    return { squaresInShape, isInY };
  },
  Component: props => {
    const {
      question: { squaresInShape, isInY },
      translate
    } = props;

    const rows = isInY ? 5 : 2;
    const cols = isInY ? 4 : 8;

    const random = seededRandom(props.question);

    const shape = createShapeWithSquares(
      rows,
      cols,
      squaresInShape,
      false,
      { random },
      undefined,
      false
    );

    return (
      <QF24bCreateSymmetricalShape
        title={translate.instructions.tapSquaresToMakePatternSymmetrical()}
        pdfTitle={translate.instructions.shadeSquaresToMakePatternSymmetrical()}
        givenShape={shape}
        symmetryLine={isInY ? 'Y' : 'X'}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ayR',
  description: 'ayR',
  keywords: ['Lines of symmetry', 'Horizontal', 'Vertical'],
  schema: z.object({
    squaresInShape: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const squaresInShape = randomIntegerInclusive(2, 6);

    return { squaresInShape };
  },
  Component: props => {
    const {
      question: { squaresInShape },
      translate
    } = props;

    const rows = 2;
    const cols = 4;

    const random = seededRandom(props.question);

    const shape = createShapeWithSquares(
      rows,
      cols,
      squaresInShape,
      false,
      { random },
      undefined,
      false
    );

    return (
      <QF24bCreateSymmetricalShape
        title={translate.instructions.tapSquaresToMakePatternSymmetrical()}
        pdfTitle={translate.instructions.shadeSquaresToMakePatternSymmetrical()}
        givenShape={shape}
        symmetryLine={'XandY'}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ayS',
  description: 'ayS',
  keywords: ['Lines of symmetry', 'Regular', 'Horizontal', 'Vertical'],
  schema: z.object({
    points: z.array(
      z.object({ x: z.number().int().min(-5).max(7), y: z.number().int().min(0).max(5) })
    ),
    isHorizontal: z.boolean()
  }),
  simpleGenerator: () => {
    const isHorizontal = getRandomBoolean();
    // trapeziums don't fit well vertically
    const shape = isHorizontal
      ? getRandomFromArray(['rectangle', 'triangle', 'trapezium'])
      : getRandomFromArray(['rectangle', 'triangle']);

    let x1;
    let x2;
    let y1;
    let y2;
    if (isHorizontal) {
      x1 = randomIntegerInclusive(-4, shape === 'trapezium' ? -2 : -1);
      x2 = 0;
      y1 = randomIntegerInclusive(1, 3);
      y2 = randomIntegerInclusive(y1 + 1, 4);
    } else {
      x2 = randomIntegerInclusive(1, shape === 'trapezium' ? 3 : 4);
      x1 = randomIntegerInclusive(x2 + 1, 7);
      y1 = 1;
      y2 = 0;
    }

    let points: { x: number; y: number }[];

    switch (shape) {
      case 'rectangle':
        points = isHorizontal
          ? [
              { x: x2, y: y2 },
              { x: x1, y: y2 },
              { x: x1, y: y1 },
              { x: x2, y: y1 }
            ]
          : [
              { x: x2, y: y2 },
              { x: x2, y: y1 },
              { x: x1, y: y1 },
              { x: x1, y: y2 }
            ];
        break;
      case 'triangle':
        points = isHorizontal
          ? [
              { x: x2, y: y2 },
              { x: x1, y: y1 },
              { x: x2, y: y1 }
            ]
          : [
              { x: x2, y: y2 },
              { x: x1, y: y1 },
              { x: x1, y: y2 }
            ];
        break;
      default:
        points = [
          { x: x2, y: y2 },
          { x: randomIntegerInclusive(x1 + 1, -1), y: y2 },
          { x: x1, y: y1 },
          { x: x2, y: y1 }
        ];
        break;
    }

    return { points, isHorizontal };
  },
  Component: ({ question, translate }) => {
    const { points, isHorizontal } = question;

    const finalPoints = isHorizontal
      ? points
      : points.map(val => {
          const point = new Point2d(val.x, val.y);
          return point.add(new Point2d(0, 2));
        });

    const fixedPoints = [finalPoints[0]];

    const endPoints = points.map(coord =>
      isHorizontal ? { x: -coord.x, y: coord.y } : { x: coord.x, y: -coord.y }
    );
    // remove first point as this is given
    endPoints.shift();
    // remove last point as this is given
    endPoints.pop();

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={
          points.length > 3
            ? translate.instructions.completeShapeAccordingToLineOfSymmetryTapToShowVertices()
            : translate.instructions.completeShapeAccordingToLineOfSymmetryTapToShowVertex()
        }
        pdfTitle={translate.instructions.completeShapeAccordingToLineOfSymmetry()}
        closeShape={false}
        testCorrect={endPoints.map(val => {
          const point = new Point2d(val.x, val.y);
          const endpoint = point.add(new Point2d(0, isHorizontal ? 0 : 2));
          return { x: endpoint.x, y: endpoint.y };
        })}
        gridChildrenPoints={finalPoints}
        fixedPoints={fixedPoints}
        gridVariant="grid"
        symmetryLine={isHorizontal ? 'Y' : 'X'}
        joinToGridChild
        // number of points is minus the start and finish
        numPoints={points.length - 2}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ayT',
  description: 'ayT',
  keywords: ['Lines of symmetry', 'Irregular', 'Horizontal', 'Vertical'],
  schema: z.object({
    points: z.array(
      z.object({ x: z.number().int().min(-5).max(7), y: z.number().int().min(0).max(5) })
    ),
    isHorizontal: z.boolean()
  }),
  simpleGenerator: () => {
    // we have more horizontal options so weight this higher
    const isHorizontal = getRandomFromArrayWithWeights([true, false] as const, [5, 3]);
    const shape = isHorizontal
      ? getRandomFromArray(['pentagon', 'hexagon', 'heptagon', 'octagon'])
      : getRandomFromArray(['pentagon', 'hexagon']);

    let x1;
    let x2;
    let x3;
    let y1;
    let y2;
    let y3;
    if (isHorizontal) {
      x1 = randomIntegerInclusive(
        shape === 'hexagon' || shape === 'heptagon' ? -3 : shape === 'octagon' ? -2 : -4,
        -1
      );
      x2 = 0;
      y1 = randomIntegerInclusive(1, 2);
      y2 = randomIntegerInclusive(y1 + 2, 4);
      y3 = y1 + 1;
      x3 = x1 - 1;
    } else {
      x1 = randomIntegerInclusive(2, 5);
      x2 = randomIntegerInclusive(x1 + 2, 7);
      y1 = 1;
      y2 = 0;
      x3 = randomIntegerInclusive(x1 + 1, x2 - 1);
      y3 = 0;
    }

    let points: { x: number; y: number }[];
    const option1 = getRandomBoolean();

    switch (shape) {
      case 'pentagon':
        points = !isHorizontal
          ? option1
            ? [
                { x: x1, y: y2 },
                { x: x1, y: y1 },
                { x: x2, y: y1 },
                { x: x3, y: y2 }
              ]
            : [
                { x: x1, y: y2 },
                { x: x1, y: y1 },
                { x: x3, y: y1 },
                { x: x2, y: y2 }
              ]
          : option1
          ? [
              { x: x2, y: y3 },
              { x: x1, y: y2 },
              { x: x1, y: y1 },
              { x: x2, y: y1 }
            ]
          : [
              { x: x2, y: y2 },
              { x: x1, y: y3 },
              { x: x1, y: y1 },
              { x: x2, y: y1 }
            ];
        break;
      case 'hexagon':
        points = !isHorizontal
          ? [
              { x: x3, y: y2 },
              { x: x1, y: y1 },
              { x: x3, y: y1 },
              { x: x2, y: y2 }
            ]
          : option1
          ? [
              { x: x2, y: y2 },
              { x: x3, y: y1 },
              { x: x1, y: y1 },
              { x: x2, y: y3 }
            ]
          : [
              { x: x2, y: y3 },
              { x: x1, y: y2 },
              { x: x1, y: y1 },
              { x: x2, y: y1 - 1 }
            ];
        break;
      case 'heptagon':
        points = option1
          ? [
              { x: x2, y: y2 },
              { x: x1, y: y3 },
              { x: x3, y: y3 },
              { x: x3, y: y1 },
              { x: x2, y: y1 }
            ]
          : [
              { x: x2, y: y2 },
              { x: x3, y: y3 },
              { x: x1, y: y3 },
              { x: x1, y: y1 },
              { x: x2, y: y1 }
            ];
        break;
      default: {
        const yTwo = randomIntegerInclusive(y1 + 1, 3);
        const yThree = randomIntegerInclusive(yTwo + 1, 4);
        const yFour = randomIntegerInclusive(yThree + 1, 5);
        points = option1
          ? [
              { x: x2, y: yFour },
              { x: x1, y: yThree },
              { x: x3, y: yThree },
              { x: x3, y: yTwo },
              { x: x2, y: y1 }
            ]
          : [
              { x: x2, y: yFour },
              { x: x3, y: yThree },
              { x: x1, y: yThree },
              { x: x3, y: yTwo },
              { x: x2, y: y1 }
            ];
        break;
      }
    }

    return { points, isHorizontal };
  },
  Component: ({ question, translate }) => {
    const { points, isHorizontal } = question;

    const finalPoints = isHorizontal
      ? points
      : points.map(val => {
          const point = new Point2d(val.x, val.y);
          return point.add(new Point2d(0, 2));
        });

    const fixedPoints = [finalPoints[0]];

    const endPoints = points.map(coord =>
      isHorizontal ? { x: -coord.x, y: coord.y } : { x: coord.x, y: -coord.y }
    );
    // remove first point as this is given
    endPoints.shift();
    // remove last point as this is given
    endPoints.pop();

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={
          points.length > 3
            ? translate.instructions.completeShapeAccordingToLineOfSymmetryTapToShowVertices()
            : translate.instructions.completeShapeAccordingToLineOfSymmetryTapToShowVertex()
        }
        pdfTitle={translate.instructions.completeShapeAccordingToLineOfSymmetry()}
        closeShape={false}
        testCorrect={endPoints.map(val => {
          const point = new Point2d(val.x, val.y);
          const endpoint = point.add(new Point2d(0, isHorizontal ? 0 : 2));
          return { x: endpoint.x, y: endpoint.y };
        })}
        gridChildrenPoints={finalPoints}
        fixedPoints={fixedPoints}
        gridVariant="grid"
        symmetryLine={isHorizontal ? 'Y' : 'X'}
        joinToGridChild
        // number of points is minus the start and finish
        numPoints={points.length - 2}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ayU',
  description: 'ayU',
  keywords: ['Lines of symmetry', 'Diagonal'],
  schema: z.object({
    squaresInShape: z.number().int().min(4).max(8)
  }),
  simpleGenerator: () => {
    const squaresInShape = randomIntegerInclusive(4, 8);
    return { squaresInShape };
  },
  Component: props => {
    const {
      question: { squaresInShape },
      translate
    } = props;

    const rows = 5;
    const cols = 5;

    const random = seededRandom(props.question);

    const shape = createShapeWithSquaresDiagonal(
      rows,
      cols,
      squaresInShape,
      false,
      { random },
      undefined,
      false
    );

    return (
      <QF24bCreateSymmetricalShape
        title={translate.instructions.tapSquaresToMakePatternSymmetrical()}
        pdfTitle={translate.instructions.shadeSquaresToMakePatternSymmetrical()}
        givenShape={shape}
        symmetryLine={'Y=X'}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ayV',
  description: 'ayV',
  keywords: ['Lines of symmetry', 'Regular', 'Irregular', 'Diagonal'],
  schema: z.object({
    points: z.array(
      z.object({ x: z.number().int().min(0).max(5), y: z.number().int().min(0).max(5) })
    )
  }),
  simpleGenerator: () => {
    const numberOfPoints = randomIntegerInclusive(3, 4);

    const lastPoint = randomIntegerInclusive(1, 2);
    const firstPoint = randomIntegerInclusive(lastPoint + 1, 4);

    const otherPoints = () => {
      if (numberOfPoints === 3) {
        const x1 = randomIntegerInclusive(1, 3);
        const y1 = randomIntegerInclusive(x1 + 1, 4);
        return [{ x: x1, y: y1 }];
      } else if (numberOfPoints === 4) {
        const x2 = randomIntegerInclusive(1, 2);
        const x1 = randomIntegerInclusive(x2 + 1, 3);
        const y1 = randomIntegerInclusive(x1 + 1, 4);
        const y2 = randomIntegerInclusive(x2 + 1, 3, { constraint: y => y !== y1 });
        return [
          { x: x1, y: y1 },
          { x: x2, y: y2 }
        ];
      } else return [{ x: 1, y: 1 }];
    };

    const points = [
      { x: firstPoint, y: firstPoint },
      ...otherPoints(),
      { x: lastPoint, y: lastPoint }
    ];

    return { points };
  },
  Component: ({ question, translate }) => {
    const { points } = question;

    const fixedPoints = [points[0]];
    const endPoints = points.map(coord => ({ x: coord.y, y: coord.x }));
    // remove first point as this is given
    endPoints.shift();
    // remove last point as this is given
    endPoints.pop();

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={
          points.length > 3
            ? translate.instructions.completeShapeAccordingToLineOfSymmetryTapToShowVertices()
            : translate.instructions.completeShapeAccordingToLineOfSymmetryTapToShowVertex()
        }
        pdfTitle={translate.instructions.completeShapeAccordingToLineOfSymmetry()}
        closeShape={false}
        testCorrect={endPoints}
        gridChildrenPoints={points}
        fixedPoints={fixedPoints}
        gridVariant="grid"
        symmetryLine={'Y=X'}
        joinToGridChild
        // number of points is minus the start and finish
        numPoints={points.length - 2}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompleteASymmetricFigure',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
