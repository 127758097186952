import { View } from 'react-native';
import { Dimens } from '../../../theme/scaling';
import { AssetSvg, getSvgInfo } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { Line, Svg } from 'react-native-svg';
import { getRandomFromArray, seededRandom } from '../../../utils/random';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /**
   * @param shape Chosen shape to display line of symmetry on.
   */
  shape:
    | 'square'
    | 'rectangleVertial'
    | 'rectangleHorizontal'
    | 'chocolateBar'
    | 'pizza'
    | 'circle';
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  linesOfSymmetry: Array<
    'horizontal' | 'vertical' | 'diagonal' | 'diagonal left' | 'diagonal right'
  >;
  /* a decimal multiplier to move the line of symmetry */
  offset?: number;
};

/**
 * This component renders a 2D image with lines of symmetry.
 * If you want a false line of symmetry use the optional offset prop.
 */
export const LinesOfSymmetry = (props: Props) => {
  const { shape, dimens, linesOfSymmetry, offset = 1 } = props;
  const displayMode = useContext(DisplayMode);
  const lineWidth = 8;
  const svgName = (() => {
    switch (shape) {
      case 'chocolateBar':
        return 'Bar_of_chocolate';
      case 'pizza':
        return 'Food_fractions/Pizza/Pizza_Whole/Pizza';
      case 'circle':
        return getRandomFromArray(
          [
            'Circles/circle_blue',
            'Circles/circle_green',
            'Circles/circle_grey',
            'Circles/circle_pink',
            'Circles/circle_yellow'
          ] as const,
          { random: seededRandom(props.shape) }
        );
      case 'square':
        return getRandomFromArray(
          [
            'Square/square_blue',
            'Square/square_green',
            'Square/square_grey',
            'Square/square_pink',
            'Square/square_yellow'
          ] as const,
          { random: seededRandom(props.shape) }
        );
      default:
        return getRandomFromArray(
          [
            'Rectangle/rectangle_blue',
            'Rectangle/rectangle_green',
            'Rectangle/rectangle_pink',
            'Rectangle/rectangle_yellow'
          ] as const,
          { random: seededRandom(props.shape) }
        );
    }
  })();
  const height = dimens.height;

  // go vertical rectangle
  const info = getSvgInfo(svgName);
  const shapeWidth = height * info.aspectRatio;

  const lineOverhang = 15;

  const lines = linesOfSymmetry.map(linesOfSymmetry => {
    switch (linesOfSymmetry) {
      case 'horizontal': {
        return {
          x1: 0,
          x2: shapeWidth + 2 * lineOverhang,
          y1: (height / 2) * offset,
          y2: (height / 2) * offset,
          svgHeight: height,
          svgWidth: shapeWidth + 2 * lineOverhang
        };
      }
      case 'vertical': {
        return {
          x1: (shapeWidth / 2) * offset,
          x2: (shapeWidth / 2) * offset,
          y1: 0,
          y2: height + 2 * lineOverhang,
          svgHeight: height + 2 * lineOverhang,
          svgWidth: shapeWidth
        };
      }
      case 'diagonal left': {
        return {
          x1: 0,
          x2: shapeWidth + lineOverhang,
          y1: 0,
          y2: height + lineOverhang,
          svgHeight: height + lineOverhang,
          svgWidth: shapeWidth + lineOverhang
        };
      }
      case 'diagonal right': {
        return {
          x1: 0,
          x2: shapeWidth + lineOverhang,
          y1: height + lineOverhang,
          y2: 0,
          svgHeight: height + lineOverhang,
          svgWidth: shapeWidth + lineOverhang
        };
      }
      default: {
        const randomDirection = getRandomFromArray(['left', 'right'], {
          random: seededRandom(props.shape)
        });
        return {
          x1: offset !== 1 ? 100 * offset : 0,
          x2: offset !== 1 ? shapeWidth + lineOverhang + 100 * offset : shapeWidth + lineOverhang,
          y1: randomDirection === 'left' ? 0 : height + lineOverhang,
          y2: randomDirection === 'left' ? height + lineOverhang : 0,
          svgHeight: height + lineOverhang,
          svgWidth: shapeWidth + lineOverhang
        };
      }
    }
  });

  const symmetryLines = lines.map(({ svgWidth, svgHeight, x1, y1, x2, y2 }, idx) => {
    return (
      <Svg
        key={`symmetry_svg_${idx}`}
        width={svgWidth}
        height={svgHeight}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        pointerEvents={'none'}
      >
        <Line
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
          strokeWidth={lineWidth}
          key={'symmetry'}
        />
      </Svg>
    );
  });

  // Top line

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <AssetSvg name={svgName} height={height} />
      {symmetryLines.map((symmetryLine, idx) => {
        return (
          <View key={`symmetry_line_${idx}`} style={{ position: 'absolute' }}>
            {symmetryLine}
          </View>
        );
      })}
    </View>
  );
};
