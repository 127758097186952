import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { AssetSvg } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  getRandomUniqueWeightObjects,
  objectsWithWeightsNameSchema,
  objectsWithWeights,
  ObjectsWithWeightsName
} from '../../../../utils/objects';
import { getRandomFromArray, rejectionSample } from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import QF13DragLiquidInJug from '../../../../components/question/questionFormats/QF13DragLiquidInJug';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import { greatest, smallest } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdH',
  description: 'bdH',
  keywords: ['Full', 'Empty', 'Capacity'],
  schema: z.object({
    smallestOrGreatest: z.enum(['smallest', 'greatest']),
    randomObjects: z.array(objectsWithWeightsNameSchema).length(2)
  }),
  simpleGenerator: () => {
    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);
    const { randomObjectsWithInfo } = rejectionSample(
      () => {
        const randomObjectsWithInfo = getRandomUniqueWeightObjects(2).map(name => ({
          name,
          ...objectsWithWeights[name]
        }));
        return { smallestOrGreatest, randomObjectsWithInfo };
      },

      ({ randomObjectsWithInfo }) => {
        // Ensure there's at least a gap of 2 in weight between objects
        // Ensures it's not too difficult to distinguish between similar sizes
        if (smallestOrGreatest === 'smallest') {
          const [smallestObject, secondSmallestObject] = [...randomObjectsWithInfo].sort(
            (a, b) => a.weight - b.weight
          );

          return Math.abs(smallestObject.weight - secondSmallestObject.weight) > 1;
        } else {
          const [greatestObject, secondGreatestObject] = [...randomObjectsWithInfo].sort(
            (a, b) => b.weight - a.weight
          );

          return Math.abs(greatestObject.weight - secondGreatestObject.weight) > 1;
        }
      }
    );

    return { smallestOrGreatest, randomObjects: randomObjectsWithInfo.map(it => it.name) };
  },
  Component: props => {
    const {
      question: { smallestOrGreatest, randomObjects },
      translate
    } = props;

    const correctAnswer = (smallestOrGreatest === 'smallest' ? smallest : greatest)(
      randomObjects,
      name => objectsWithWeights[name].weight
    );

    return (
      <QF11SelectImagesUpTo4<ObjectsWithWeightsName>
        title={
          smallestOrGreatest === 'greatest'
            ? translate.ks1Instructions.selectTheContainerWithTheGreaterCapacity()
            : translate.ks1Instructions.selectTheContainerWithTheSmallerCapacity()
        }
        pdfTitle={
          smallestOrGreatest === 'greatest'
            ? translate.ks1PDFInstructions.tickTheContainerWithTheGreaterCapacity()
            : translate.ks1PDFInstructions.tickTheContainerWithTheSmallerCapacity()
        }
        testCorrect={[correctAnswer]}
        numItems={2}
        itemStyle={{ justifyContent: 'flex-end', paddingBottom: 24 }}
        renderItems={({ dimens }) =>
          randomObjects.map(name => {
            const { icon, scale } = objectsWithWeights[name];
            return {
              value: name,
              component: (
                <AssetSvg
                  name={icon}
                  width={dimens.width * 0.8 * scale}
                  height={dimens.height * 0.8}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdI',
  description: 'bdI',
  keywords: ['Empty', 'Nearly empty', 'Nearly full', 'Full', 'Volume', 'Capacity'],
  schema: z.object({
    amount: z.enum(['Empty', 'Nearly Empty', 'Nearly Full', 'Full']),
    containerType: z.enum(['glass', 'jug'])
  }),
  simpleGenerator: () => {
    const amount = getRandomFromArray(['Empty', 'Nearly Empty', 'Nearly Full', 'Full'] as const);
    const containerType =
      amount === 'Full' ? 'glass' : getRandomFromArray(['jug', 'glass'] as const);

    return {
      amount,
      containerType
    };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { amount, containerType },
      translate,
      displayMode
    } = props;

    const full = translate.misc.full();
    const nearlyFull = translate.misc.nearlyFull();
    const empty = translate.misc.empty();
    const nearlyEmpty = translate.misc.nearlyEmpty();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentence()}
        sentence={translate.ks1AnswerSentences.theXContainerIsAns(containerType)}
        sentencesStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 48 }}
        pdfLayout="itemsTop"
        Content={({ dimens }) => {
          return (
            <JugWithLiquid
              dimens={dimens}
              jugCapacity={1000}
              liquidAmount={
                amount === 'Empty'
                  ? 0
                  : amount === 'Nearly Empty'
                  ? 100
                  : amount === 'Nearly Full'
                  ? 900
                  : 1000
              }
              hideAllLabels
              tickValue={500}
              liquidType="orange"
              unitsPerMajorTick={10}
              isInteractive={false}
              containerType={containerType}
            />
          );
        }}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        actionPanelVariant="endWide"
        questionHeight={1000}
        items={[full, nearlyFull, empty, nearlyEmpty]}
        testCorrect={
          amount === 'Empty'
            ? [empty]
            : amount === 'Nearly Empty'
            ? [nearlyEmpty]
            : amount === 'Nearly Full'
            ? [nearlyFull]
            : [full]
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdJ',
  description: 'bdJ',
  keywords: ['Empty', 'Nearly empty', 'Nearly full', 'Full', 'Volume', 'Capacity'],
  schema: z.object({
    amount: z.enum(['Nearly Empty', 'Nearly Full', 'Full']),
    containerTypeVariation: z.enum(['jug', 'glass'])
  }),
  simpleGenerator: () => {
    const amount = getRandomFromArray(['Nearly Empty', 'Nearly Full', 'Full'] as const);
    const containerTypeVariation = ['Nearly Full', 'Full'].includes(amount)
      ? 'glass'
      : getRandomFromArray(['jug', 'glass'] as const);

    return { amount, containerTypeVariation };
  },
  Component: props => {
    const {
      question: { amount, containerTypeVariation },
      translate
    } = props;

    const title =
      amount === 'Nearly Empty'
        ? translate.misc.nearlyEmpty()
        : amount === 'Nearly Full'
        ? translate.misc.nearlyFull()
        : translate.misc.full();

    return (
      <QF13DragLiquidInJug
        title={translate.ks1Instructions.theXContainerIsYAmountDragTheArrowToShowHowMuchJuiceCouldBeInTheXContainer(
          containerTypeVariation,
          title
        )}
        pdfTitle={translate.ks1PDFInstructions.theXContainerIsYAmountDrawHowMuchJuiceCouldBeInTheXContainer(
          containerTypeVariation,
          title
        )}
        testCorrect={userAnswer => {
          return amount === 'Nearly Empty'
            ? userAnswer !== 0 && userAnswer < 500
            : amount === 'Nearly Full'
            ? userAnswer > 500
            : userAnswer === 1000;
        }}
        jugCapacity={1000}
        tickValue={500}
        liquidType="orange"
        unitsPerMajorTick={10}
        containerType={containerTypeVariation}
        hideAllLabels
        customMarkSchemeAnswer={{
          answerText:
            amount === 'Nearly Empty'
              ? translate.markScheme.acceptAnyAmountBelowHalfwayOfTheXContainer(
                  translate.objects.Glass()
                )
              : amount === 'Nearly Full'
              ? translate.markScheme.acceptAnyAmountOverHalfwayOfTheXContainer(
                  translate.objects.Glass()
                )
              : translate.markScheme.theXContainerShouldBeFull(translate.objects.Glass())
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FullAndEmpty',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
