import { useCallback, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View, Platform } from 'react-native';
import { createStoreWithContext } from '../../utils/stores';
import { readonlyMapEntry } from '../../utils/collections';
import Button from '../atoms/Button';
import { DECIMAL_POINT, SUB } from '../../constants';
import Text from '../typography/Text';
import { colors } from '../../theme/colors';
import { Portal } from '../portal';
import { OkModal } from '../modals';
import { useI18nContext } from '../../i18n/i18n-react';
import { AlgebraicSymbols, algebraicSymbols } from '../../utils/algebraicSymbols';
import { AssetSvg } from '../../assets/svg';
import { getPlayer, soundChoices } from '../../utils/Audio';

export type NumpadEvent = {
  inputType: 'numpad';
  button: NumpadButton;
};

export type ExtraSymbols = 'decimalPoint' | 'minus' | AlgebraicSymbols;

type NumpadButton =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | DECIMAL_POINT
  | 'del'
  | ''
  | SUB
  | AlgebraicSymbols;

/** Discriminated union of all possible input events */
export type UserInputEvent = NumpadEvent;

export type UserInputReceiver = (event: UserInputEvent) => void;

type UserInputState = {
  selectedInputReceiver?: string;
  registeredInputReceivers: ReadonlyMap<string, UserInputReceiver>;

  selectInputReceiver: (id: string) => void;
  deselectInputReceiver: (id: string) => boolean;
  registerInputReceiver: (id: string, recv: UserInputReceiver) => () => void;
};

export const { Provider: UserInputStoreProvider, useStore: useUserInputStore } =
  createStoreWithContext<UserInputState>(set => ({
    registeredInputReceivers: new Map(),

    selectInputReceiver: id => set({ selectedInputReceiver: id }),
    deselectInputReceiver: id => {
      let wasSelected = false;
      set(old => {
        if (old.selectedInputReceiver === id) {
          wasSelected = true;
          return { selectedInputReceiver: undefined };
        }
        return {};
      });
      return wasSelected;
    },
    registerInputReceiver: (id, recv) => {
      // Update registrations.
      set(old => ({
        registeredInputReceivers: readonlyMapEntry(old.registeredInputReceivers, id, oldEntry => {
          if (oldEntry !== recv) {
            return recv;
          } else {
            return oldEntry as UserInputReceiver;
          }
        })
      }));

      // Return function to unregister
      return () =>
        set(old => ({
          selectedInputReceiver:
            old.selectedInputReceiver === id ? undefined : old.selectedInputReceiver,
          registeredInputReceivers: readonlyMapEntry(
            old.registeredInputReceivers,
            id,
            () => undefined
          )
        }));
    }
  }));

type Props = {
  inputType: UserInputEvent['inputType'];
  /** Default: tall */
  variant?: 'tall' | 'wide';
  extraSymbol?: ExtraSymbols;
};

/**
 * A input pad.
 *
 * This is a replacement for the soft-keyboard on mobile devices, which we don't use.
 */
function UserInput({ inputType, variant = 'tall', extraSymbol }: Props) {
  const translate = useI18nContext().LL;
  const [modalVisible, setModalVisible] = useState(false);

  const selectedInputReceiver = useUserInputStore(state => state.selectedInputReceiver);
  const registeredInputReceivers = useUserInputStore(state => state.registeredInputReceivers);

  const onKeyboardDown = useCallback(
    (event: KeyboardEvent) => {
      if (selectedInputReceiver !== undefined) {
        const recv = registeredInputReceivers.get(selectedInputReceiver);
        if (recv !== undefined) {
          if (event.code === 'Tab') {
            event.stopPropagation();
            event.preventDefault();
          } else {
            let value;
            switch (event.code) {
              case 'Digit1':
              case 'Numpad1': {
                value = '1';
                break;
              }
              case 'Digit2':
              case 'Numpad2': {
                value = '2';
                break;
              }
              case 'Digit3':
              case 'Numpad3': {
                value = '3';
                break;
              }
              case 'Digit4':
              case 'Numpad4': {
                value = '4';
                break;
              }
              case 'Digit5':
              case 'Numpad5': {
                value = '5';
                break;
              }
              case 'Digit6':
              case 'Numpad6': {
                value = '6';
                break;
              }
              case 'Digit7':
              case 'Numpad7': {
                value = '7';
                break;
              }
              case 'Digit8':
              case 'Numpad8': {
                value = '8';
                break;
              }
              case 'Digit9':
              case 'Numpad9': {
                value = '9';
                break;
              }
              case 'Digit0':
              case 'Numpad0': {
                value = '0';
                break;
              }
              case 'Backspace': {
                value = 'del';
                break;
              }
              case 'Minus':
              case 'NumpadSubtract': {
                value = SUB;
                break;
              }
              case 'Period':
              case 'NumpadDecimal': {
                value = DECIMAL_POINT;
                break;
              }
              default: {
                return;
              }
            }
            recv({
              inputType: 'numpad',
              button: value as NumpadButton
            });
          }
        }
      }
    },
    [registeredInputReceivers, selectedInputReceiver]
  );

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.addEventListener('keydown', onKeyboardDown);
    }
    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('keydown', onKeyboardDown);
      }
    };
  }, [onKeyboardDown]);

  const toggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const onInputEvent = useCallback(
    (event: UserInputEvent) => {
      if (selectedInputReceiver === undefined) {
        setModalVisible(true);
      }
      if (selectedInputReceiver !== undefined) {
        const recv = registeredInputReceivers.get(selectedInputReceiver);
        if (recv !== undefined) {
          recv(event);
        }
      }
    },
    [registeredInputReceivers, selectedInputReceiver]
  );

  return (
    <View style={styles.userInput}>
      {(() => {
        switch (inputType) {
          case 'numpad': {
            return (
              <>
                {modalVisible && (
                  <Portal>
                    <OkModal
                      title={translate.quiz.modals.selectAnAnswerBox()}
                      text={translate.quiz.modals.thenTypeYourAnswer()}
                      onDismiss={toggleModal}
                      modalHeight={380}
                    />
                  </Portal>
                )}
                <Numpad onInputEvent={onInputEvent} variant={variant} extraSymbol={extraSymbol} />
              </>
            );
          }
        }
      })()}
    </View>
  );
}

export default UserInput;

type ChildProps = {
  onInputEvent: (event: UserInputEvent) => void;
  variant: 'tall' | 'wide';
  extraSymbol?: ExtraSymbols;
};

function Numpad({ onInputEvent, variant, extraSymbol }: ChildProps) {
  let extraSymbolLabel: NumpadButton;

  // Can be extended in the future
  if (extraSymbol === 'decimalPoint') extraSymbolLabel = DECIMAL_POINT;
  else if (extraSymbol === 'minus') extraSymbolLabel = SUB;
  else if (extraSymbol && algebraicSymbols.includes(extraSymbol)) extraSymbolLabel = extraSymbol;
  else extraSymbolLabel = '';

  const numpadContents =
    variant === 'tall'
      ? ([
          ['7', '8', '9'],
          ['4', '5', '6'],
          ['1', '2', '3'],
          [extraSymbolLabel, '0', 'del']
        ] as const)
      : ([
          ['0', '1', '2', '3', '4', '5'],
          ['6', '7', '8', '9', extraSymbolLabel, 'del']
        ] as const);

  const specialCharacters = ['del', DECIMAL_POINT, SUB];

  const player = getPlayer();

  return (
    <ScrollView
      scrollEnabled={false}
      // Prevents button presses from stealing focus from textInputs. Actually has nothing to do with the keyboard.
      // This prop is the sole reason for using a non-scrolling ScrollView. It's not available on regular views.
      keyboardShouldPersistTaps="handled"
    >
      <View style={styles.numpad}>
        {numpadContents.map((row, rowIndex) => (
          <View key={rowIndex} style={styles.row}>
            {row.map(button =>
              button === '' ? (
                <Button
                  key={button}
                  disabled
                  variant="circle"
                  style={[styles.emptyButton, variant === 'tall' ? { opacity: 0 } : { opacity: 1 }]}
                  baseColor={variant === 'tall' ? 'transparent' : colors.greys200}
                />
              ) : (
                <Button
                  key={button}
                  variant="circle"
                  onPress={() => {
                    if (isNaN(Number(button))) {
                      player.playSound('numpaddel' as soundChoices);
                    } else {
                      player.playSound('numpad', parseInt(button));
                    }
                    onInputEvent({ inputType: 'numpad', button });
                  }}
                  baseColor={specialCharacters.includes(button) ? colors.greys200 : colors.white}
                  style={button === 'del' ? { paddingTop: 0, paddingBottom: 0 } : undefined}
                >
                  {button === 'del' ? (
                    <AssetSvg name="Backspace" />
                  ) : (
                    <Text variant="WRN700">{button}</Text>
                  )}
                </Button>
              )
            )}
          </View>
        ))}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  userInput: {
    alignItems: 'center'
  },
  numpad: {
    flexDirection: 'column',
    rowGap: 16
  },
  row: {
    flexDirection: 'row',
    columnGap: 16
  },
  emptyButton: {
    borderWidth: 0
  }
});
