import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  createGridAroundShape,
  createRectangleFromSquares,
  isEqualPerimeter,
  isRectangle,
  isRectilinear,
  isSquareShape,
  perimeterCount
} from '../../../../utils/shapes';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import {
  brokenAndUnbrokenShapeSchema,
  brokenAndUnbrokenShapeToAssetPath,
  brokenAndUnbrokenShapes,
  brokenShapeSchema,
  getRandomBrokenShape,
  getRandomUnbrokenShape,
  isBrokenOrUnbroken,
  unbrokenShapeSchema
} from '../../../../utils/brokenAndUnbrokenShapes';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { DisplayShapeOnGridWithBorder } from '../../../../components/question/representations/DisplayShapeOnGridWithBorder';
import { barModelColors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aHs',
  description: 'aHs',
  keywords: ['Perimeter'],
  schema: z
    .object({
      shapeA: unbrokenShapeSchema,
      shapeB: brokenShapeSchema,
      shapeC: brokenAndUnbrokenShapeSchema,
      shapeD: brokenAndUnbrokenShapeSchema
    })
    .refine(
      val => arrayHasNoDuplicates([val.shapeA, val.shapeB, val.shapeC, val.shapeD]),
      'All shapes must be different.'
    ),
  simpleGenerator: () => {
    // Enforce one shape to always be true:
    const shapeA = getRandomUnbrokenShape();

    // Enforce one shape to always be false:
    const shapeB = getRandomBrokenShape();

    const [shapeC, shapeD] = getRandomSubArrayFromArray(
      [...brokenAndUnbrokenShapes.broken, ...brokenAndUnbrokenShapes.unbroken] as const,
      2,
      {
        constraint: x => arrayHasNoDuplicates([x, shapeA, shapeB])
      }
    );

    return { shapeA, shapeB, shapeC, shapeD };
  },
  Component: props => {
    const {
      question: { shapeA, shapeB, shapeC, shapeD },
      translate
    } = props;

    const shapes = shuffle(
      [
        {
          shape: shapeA,
          isCorrect: isBrokenOrUnbroken(shapeA) === 'unbroken'
        },
        {
          shape: shapeB,
          isCorrect: isBrokenOrUnbroken(shapeB) === 'unbroken'
        },
        {
          shape: shapeC,
          isCorrect: isBrokenOrUnbroken(shapeC) === 'unbroken'
        },
        {
          shape: shapeD,
          isCorrect: isBrokenOrUnbroken(shapeD) === 'unbroken'
        }
      ],
      { random: seededRandom(props.question) }
    );

    const answer = shapes.filter(shape => shape.isCorrect).map(shape => shape.shape);

    return (
      <QF11SelectImagesUpTo4
        title={
          answer.length === 1
            ? translate.instructions.selectTheShapeThatHasAPerimeter()
            : translate.instructions.selectTheShapesThatHaveAPerimeter()
        }
        pdfTitle={
          answer.length === 1
            ? translate.instructions.circleTheShapeThatHaveAPerimeter()
            : translate.instructions.circleTheShapesThatHaveAPerimeter()
        }
        testCorrect={answer}
        numItems={4}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.shape,
            component: (
              <AssetSvg
                name={brokenAndUnbrokenShapeToAssetPath[shape.shape] as SvgName}
                width={dimens.width - 32}
                height={dimens.height - 32}
              />
            )
          }));
        }}
        multiSelect
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aHt',
  description: 'aHt',
  keywords: ['Perimeter', 'Compare'],
  schema: z.object({
    shape1: z.array(z.array(z.boolean())),
    shape2: z.array(z.array(z.boolean())),
    smallerOrGreater: z.enum(['smaller', 'greater'])
  }),
  simpleGenerator: () => {
    const { shape1, shape2 } = rejectionSample(
      () => {
        const squareDimens = randomIntegerInclusive(1, 8);
        const rectangleWidth = randomIntegerInclusive(1, 8);
        const rectangleHeight = randomIntegerInclusive(1, 5, {
          constraint: x => x !== rectangleWidth
        });
        const shape1 = createRectangleFromSquares(squareDimens, squareDimens);
        const shape2 = createRectangleFromSquares(rectangleWidth, rectangleHeight);
        return { shape1, shape2 };
      },
      val => {
        const perim1 = perimeterCount(val.shape1);
        const perim2 = perimeterCount(val.shape2);
        return (
          // Ensure perimeters are not equal
          perim1 !== perim2 &&
          // Ensure the greatest perimeter count is at least 10 greater to show a clear difference in perimeter of shapes
          (perim1 > perim2 ? Math.abs(perim1 - perim2) >= 10 : Math.abs(perim2 - perim1) >= 10)
        );
      }
    );
    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { shape1, shape2, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { shape1, shape2, smallerOrGreater },
      translate
    } = props;
    const colors = getRandomSubArrayFromArray(Object.values(barModelColors), 2, {
      random: seededRandom({ shape1, shape2, smallerOrGreater })
    }) as string[];

    const shapes = shuffle([shape1, shape2], { random: seededRandom(props.question) });
    const answer =
      smallerOrGreater === 'smaller'
        ? Math.min(perimeterCount(shape1), perimeterCount(shape2))
        : Math.max(perimeterCount(shape1), perimeterCount(shape2));
    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectShapeThatHasTheSmallerPerimeter()
            : translate.instructions.selectShapeThatHasTheGreaterPerimeter()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.circleShapeThatHasTheSmallerPerimeter()
            : translate.instructions.circleShapeThatHasTheGreaterPerimeter()
        }
        numItems={2}
        testCorrect={[answer]}
        renderItems={({ dimens }) =>
          shapes.map((shape, i) => {
            return {
              value: perimeterCount(shape),
              component: (
                <DisplayShapeOnGridWithBorder
                  givenShape={shape}
                  noGrid
                  dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
                  color={colors[i]}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHu',
  description: 'aHu',
  keywords: ['Grid', 'Centimetres', 'Perimeter'],
  schema: z.object({
    shapeWidth: z.number().int().min(1).max(8),
    shapeHeight: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => {
    const shapeWidth = randomIntegerInclusive(1, 8);

    const shapeHeight = randomIntegerInclusive(1, 5);

    return { shapeWidth, shapeHeight };
  },
  Component: ({ question: { shapeWidth, shapeHeight }, translate }) => {
    const shape = createRectangleFromSquares(shapeWidth, shapeHeight);

    const shapeWithGrid = createGridAroundShape([shapeWidth + 2, shapeHeight + 2], shape, 'center');

    return (
      <QF1ContentAndSentence
        title={translate.instructions.workOutThePerimeterOfTheShadedShape()}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        Content={({ dimens }) => (
          <DisplayShapeOnGrid
            givenShape={shapeWithGrid}
            dimens={dimens}
            cellSizeLabel={translate.units.numberOfCm(1)}
          />
        )}
        testCorrect={[(shapeWidth * 2 + shapeHeight * 2).toString()]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aHv',
  description: 'aHv',
  keywords: ['Grid', 'Centimetres', 'Perimeter'],
  schema: z.object({
    possibleShapeHeight: z.number().int().min(1).max(5),
    possibleShapeWidth: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const possibleShapeHeight = randomIntegerInclusive(1, 5);

    const possibleShapeWidth = randomIntegerInclusive(1, 8);

    return { possibleShapeHeight, possibleShapeWidth };
  },
  Component: ({ question: { possibleShapeHeight, possibleShapeWidth }, translate }) => {
    const perimeter = 2 * possibleShapeHeight + 2 * possibleShapeWidth;

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.tapToCreateShapeThatHasPerimeterOfNumCm(perimeter)}
        pdfTitle={translate.instructions.createShapeThatHasPerimeterOfNumCm(perimeter)}
        numberOfRows={5}
        numberOfCols={8}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, perimeter) && isRectilinear(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyShapeWithPerimeterOfX(
            translate.units.numberOfCm(perimeter)
          )
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aHw',
  description: 'aHw',
  keywords: ['Grid', 'Centimetres', 'Perimeter', 'Rectangle'],
  schema: z.object({
    possibleShapeHeight: z.number().int().min(1).max(5),
    possibleShapeWidth: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const possibleShapeHeight = randomIntegerInclusive(1, 5);

    const possibleShapeWidth = randomIntegerInclusive(1, 8);

    return { possibleShapeHeight, possibleShapeWidth };
  },
  Component: ({ question: { possibleShapeHeight, possibleShapeWidth }, translate }) => {
    const perimeter = 2 * possibleShapeHeight + 2 * possibleShapeWidth;

    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.tapToCreateRectangleThatHasPerimeterOfNumCm(perimeter)}
        pdfTitle={translate.instructions.createRectangleThatHasPerimeterOfNumCm(perimeter)}
        numberOfRows={5}
        numberOfCols={8}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, perimeter) && isRectangle(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyRectangleWithPerimeterOfX(
            translate.units.numberOfCm(perimeter)
          )
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aHx',
  description: 'aHx',
  keywords: ['Grid', 'Centimetres', 'Perimeter', 'Square'],
  schema: z.object({
    perimeter: z.number().int().min(4).max(20).multipleOf(4)
  }),
  simpleGenerator: () => {
    const perimeter = randomIntegerInclusiveStep(4, 20, 4);

    return { perimeter };
  },
  Component: ({ question: { perimeter }, translate }) => {
    return (
      <QF24CreateShapeFromSquares
        title={translate.instructions.tapToCreateSquareThatHasPerimeterOfNumCm(perimeter)}
        pdfTitle={translate.instructions.createSquareThatHasPerimeterOfNumCm(perimeter)}
        numberOfRows={5}
        numberOfCols={8}
        cellSizeLabel={translate.units.numberOfCm(1)}
        testCorrect={userAnswer =>
          isEqualPerimeter(userAnswer, perimeter) && isSquareShape(userAnswer)
        }
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anySquareWithPerimeterOfX(
            translate.units.numberOfCm(perimeter)
          )
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'WhatIsPerimeter',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
