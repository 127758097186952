import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import Pictogram from '../../../../components/question/representations/Pictogram/Pictogram';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomUniqueIceCreamFlavourNames,
  iceCreamFlavoursAsWord,
  iceCreamFlavoursSchema
} from '../../../../utils/food';
import {
  PictogramColors,
  barColorNames,
  barColorsNamesArray,
  barColorsNamesSchema
} from '../../../../theme/colors';
import QF64CreatePictogram from '../../../../components/question/questionFormats/QF64CreatePictogram';
import {
  countriesSchema,
  countryAsWord,
  getRandomUniqueCountries
} from '../../../../utils/countries';
import QF62DrawBarCharts from '../../../../components/question/questionFormats/QF62DrawBarCharts';
import { numberEnum } from '../../../../utils/zod';
import MultiBarChart from '../../../../components/question/representations/Coordinates/MultiBarChart';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ayW',
  description: 'ayW',
  keywords: ['Pictogram', 'Key', 'Interpret'],
  schema: z.object({
    numbers: z.array(z.number().min(0).max(8).step(0.5)).length(4),
    flavours: z
      .array(iceCreamFlavoursSchema)
      .length(4)
      .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    answerFlavourIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const [number1, number2, number3, number4] = countRange(4).map(
      () => randomIntegerInclusiveStep(0, 80, 5) / 10
    );
    const flavours = getRandomUniqueIceCreamFlavourNames(4);
    const answerFlavourIndex = randomIntegerInclusive(0, 3);
    const numbers = [number1, number2, number3, number4];

    return { numbers, flavours, answerFlavourIndex };
  },
  Component: ({ question, translate }) => {
    const { numbers, flavours, answerFlavourIndex } = question;
    const color = getRandomFromArray(PictogramColors, {
      random: seededRandom(question)
    }) as string;

    const answer = numbers[answerFlavourIndex] * 2;
    const localeFlavours = flavours.map(i => [iceCreamFlavoursAsWord(i, translate)]);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.oneCircleIs2IceCreamsHowManyWereSold(
          localeFlavours[answerFlavourIndex][0]
        )}
        testCorrect={[answer.toString()]}
        sentence={`<ans/>`}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <Pictogram
            displayValues={numbers}
            columnNames={[translate.tableHeaders.Flavour(), translate.tableHeaders.IceCreamsSold()]}
            rowData={localeFlavours}
            dimens={dimens}
            color={color}
            keyValue={translate.keys.numIceCreams(2)}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ayX',
  description: 'ayX',
  keywords: ['Pictogram', 'Key', 'Interpret'],
  schema: z.object({
    numbers: z.array(z.number().min(0).max(8).step(0.5)).length(4),
    multiple: numberEnum([2, 4, 10]),
    flavours: z
      .array(iceCreamFlavoursSchema)
      .length(4)
      .refine(arrayHasNoDuplicates, 'duplicates are not allowed')
  }),
  simpleGenerator: () => {
    const [number1, number2, number3, number4] = countRange(4).map(
      () => randomIntegerInclusiveStep(0, 80, 5) / 10
    );
    const multiple = getRandomFromArray([2, 4, 10] as const);
    const flavours = getRandomUniqueIceCreamFlavourNames(4);
    const numbers = [number1, number2, number3, number4];

    return { numbers, multiple, flavours };
  },
  Component: ({ question, translate }) => {
    const { numbers, multiple, flavours } = question;
    const color = getRandomFromArray(PictogramColors, {
      random: seededRandom(question)
    }) as string;

    const answer = numbers.reduce((a, b) => a + b, 0) * multiple;
    const localeFlavours = flavours.map(i => [iceCreamFlavoursAsWord(i, translate)]);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyIceCreamsWereSoldInTotal()}
        testCorrect={[answer.toString()]}
        sentence={`<ans/>`}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <Pictogram
            displayValues={numbers}
            columnNames={[translate.tableHeaders.Flavour(), translate.tableHeaders.IceCreamsSold()]}
            rowData={localeFlavours}
            dimens={dimens}
            color={color}
            keyValue={translate.keys.numIceCreams(multiple)}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ayY',
  description: 'ayY',
  keywords: ['Pictogram', 'Draw'],
  questionHeight: 850,
  schema: z.object({
    key: z.number().int().min(2).max(10).step(2),
    wood: z.array(z.enum(['Oak', 'Beech', 'Ash', 'Willow', 'Pine', 'Elm', 'Cedar'])).length(3),
    numbers: z.array(z.number().min(0).max(6).step(0.5)).length(3)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(10, 60, 5) / 10;
    // these two should produce half a pictogram image i.e. be a multiple fo 0.5 but not 1
    const number2 = randomIntegerInclusiveStep(25, 55, 10) / 10;
    const number3 =
      randomIntegerInclusiveStep(0, 60, 5, {
        constraint: x => x === 0 || x % 10 !== 0
      }) / 10;
    const key = randomIntegerInclusiveStep(2, 10, 2, {
      constraint: x => x * number1 >= 5 && x * number2 >= 20
    });

    const numbers = [number1, number2, number3];
    const wood = getRandomSubArrayFromArray(
      ['Oak', 'Beech', 'Ash', 'Willow', 'Pine', 'Elm', 'Cedar'] as const,
      3
    );

    return { numbers, wood, key };
  },
  Component: ({ question, translate }) => {
    const { numbers, wood, key } = question;
    const rowData = wood.map((x, idx) => [
      translate.woods[x](),
      (numbers[idx] * key).toLocaleString()
    ]);

    return (
      <QF64CreatePictogram
        title={translate.instructions.dragCircleToCompletePictogram()}
        pdfTitle={translate.instructions.completePictogram()}
        testCorrect={numbers}
        draggableItems={['whole', 'half']}
        columnNames={[
          translate.tableHeaders.Material(),
          translate.tableHeaders.Number(),
          translate.tableHeaders.NumberOfHouses()
        ]}
        questionHeight={850}
        rowData={rowData}
        keyValue={translate.misc.XHouses(key.toLocaleString())}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationsThatSumToTotal()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ayZ',
  description: 'ayZ',
  keywords: ['Bar chart', 'Interpret'],
  schema: z
    .object({
      maxNumberOfChildren: z.number().int().min(10).max(14).multipleOf(2),
      colors: barColorsNamesSchema
        .array()
        .length(5)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      data: z.number().int().min(0).max(14).multipleOf(2).array().length(5),
      countries: z
        .array(countriesSchema)
        .length(5)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      answerIdx: z.number().int().min(0).max(4)
    })
    .refine(
      ({ maxNumberOfChildren, data }) => data.every(it => it <= maxNumberOfChildren),
      'all entries in the data must be <= maxNumberOfChildren'
    ),
  simpleGenerator: () => {
    const maxNumberOfChildren = randomIntegerInclusiveStep(10, 14, 2);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5);
    const data = countRange(5).map(idx =>
      randomIntegerInclusiveStep(idx === 2 ? 0 : 2, maxNumberOfChildren, 2)
    );
    const countries = getRandomUniqueCountries(5);
    const answerIdx = randomIntegerInclusive(0, 4);

    return {
      maxNumberOfChildren,
      colors,
      data,
      countries,
      answerIdx
    };
  },
  Component: props => {
    const {
      question: { maxNumberOfChildren, colors, data, countries, answerIdx },
      translate
    } = props;

    const selectedCountry = countryAsWord(countries[answerIdx], translate);

    const countriesData = countRange(countries.length).map(idx => {
      return {
        country: countryAsWord(countries[idx], translate),
        value: data[idx]
      };
    });

    // Answer
    const ans = countriesData[answerIdx].value;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.howManyChildrenHaveVisitedXCountry(selectedCountry)}
        testCorrect={[ans.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1150}
        Content={({ dimens }) => {
          return (
            <MultiBarChart
              width={dimens.width}
              height={dimens.height}
              barValues={countriesData
                .map(data => data.value)
                .map((val, i) => ({ option: i, values: [val] }))}
              barLabels={countriesData.map(country => country.country)}
              barColors={colors.map(key => barColorNames[key])}
              snapToNearest="grid"
              yMax={maxNumberOfChildren}
              yStepSize={2}
              xAxisLabel={translate.misc.countryVisited()}
              yAxisLabel={translate.misc.numberOfChildren()}
              yAxisArrowLabel={null}
            />
          );
        }}
      />
    );
  },
  questionHeight: 1150
});

const Question5 = newQuestionContent({
  uid: 'ay0',
  description: 'ay0',
  keywords: ['Bar chart', 'Interpret'],
  schema: z
    .object({
      maxNumberOfChildren: z.number().int().min(10).max(14).multipleOf(2),
      colors: barColorsNamesSchema
        .array()
        .length(5)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      data: z.number().int().min(0).max(14).array().length(5),
      countries: z
        .array(countriesSchema)
        .length(5)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      answerIdx: z.number().int().min(0).max(4)
    })
    .refine(
      ({ maxNumberOfChildren, data }) => data.every(it => it <= maxNumberOfChildren),
      'all entries in the data must be <= maxNumberOfChildren'
    ),
  simpleGenerator: () => {
    const maxNumberOfChildren = randomIntegerInclusiveStep(10, 14, 2);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 5);
    const data = countRange(5).map(idx =>
      randomIntegerInclusive(idx === 2 ? 0 : 2, maxNumberOfChildren)
    );
    const countries = getRandomUniqueCountries(5);
    const answerIdx = randomIntegerInclusive(0, 4);

    return {
      maxNumberOfChildren,
      colors,
      data,
      countries,
      answerIdx
    };
  },
  Component: props => {
    const {
      question: { maxNumberOfChildren, colors, data, countries, answerIdx },
      translate
    } = props;

    const selectedCountry = countryAsWord(countries[answerIdx], translate);

    const countriesData = countRange(countries.length).map(idx => {
      return {
        country: countryAsWord(countries[idx], translate),
        value: data[idx]
      };
    });

    // Answer
    const ans = countriesData[answerIdx].value;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.howManyChildrenVisitedXCountry(selectedCountry)}
        testCorrect={[ans.toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1150}
        Content={({ dimens }) => {
          return (
            <MultiBarChart
              width={dimens.width}
              height={dimens.height}
              barValues={countriesData
                .map(data => data.value)
                .map((val, i) => ({ option: i, values: [val] }))}
              barLabels={countriesData.map(country => country.country)}
              barColors={colors.map(key => barColorNames[key])}
              snapToNearest="grid"
              yMax={maxNumberOfChildren}
              yStepSize={2}
              xAxisLabel={translate.misc.countryVisited()}
              yAxisLabel={translate.misc.numberOfChildren()}
              yAxisArrowLabel={null}
            />
          );
        }}
      />
    );
  },
  questionHeight: 1150
});

const Question6 = newQuestionContent({
  uid: 'ay1',
  description: 'ay1',
  keywords: ['Bar chart', 'Draw'],
  schema: z
    .object({
      maxNumberOfChildren: z.number().int().min(10).max(14).multipleOf(2),
      colors: barColorsNamesSchema
        .array()
        .length(4)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      data: z.number().int().min(0).max(14).array().length(4),
      countries: z
        .array(countriesSchema)
        .length(4)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed')
    })
    .refine(
      ({ maxNumberOfChildren, data }) => data.every(it => it <= maxNumberOfChildren),
      'all entries in the data must be <= maxNumberOfChildren'
    ),
  simpleGenerator: () => {
    const maxNumberOfChildren = randomIntegerInclusiveStep(10, 14, 2);
    const colors = getRandomSubArrayFromArray(barColorsNamesArray, 4);
    const data = countRange(4).map(idx =>
      randomIntegerInclusive(idx === 2 ? 0 : 2, maxNumberOfChildren)
    );
    const countries = getRandomUniqueCountries(4);
    return { maxNumberOfChildren, colors, data, countries };
  },
  Component: ({ question: { maxNumberOfChildren, colors, data, countries }, translate }) => {
    const countriesData = countRange(countries.length).map(idx => {
      return {
        country: countryAsWord(countries[idx], translate),
        value: data[idx]
      };
    });
    return (
      <QF62DrawBarCharts
        title={translate.instructions.dragTheBarsToCompleteTheBarChart()}
        pdfTitle={translate.instructions.dragTheBarsToCompleteTheBarChartPdf()}
        correctAnswer={data}
        initialState={[0, 0, 0, 0]}
        interactiveIndices={[0, 1, 2, 3]}
        barLabels={countriesData.map(country => country.country)}
        barColors={colors.map(key => barColorNames[key])}
        snapToNearest={1}
        yMax={maxNumberOfChildren}
        yStepSize={2}
        xAxisLabel={translate.misc.countryVisited()}
        yAxisLabel={translate.misc.numberOfChildren()}
        questionHeight={1150}
        yAxisArrowLabel={null}
      />
    );
  },
  questionHeight: 1150
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InterpretCharts',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
