import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import {
  filledNumberTrackArray,
  findCommonMultiples,
  haveCommonMultipleWithinXSteps,
  leastCommonMultiple,
  multiplesNumberTrackArray
} from '../../../../utils/multiples';
import { NumberTrackKeyboardWithState } from '../../../../components/question/representations/Number Track/NumberTrackKeyboard';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { isEqual, isEqualUnordered } from '../../../../utils/matchers';
import { View } from 'react-native';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF31aInteractiveNumberGridAndSelectables from '../../../../components/question/questionFormats/QF31aInteractiveNumberGridAndSelectables';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

// Question1 exported to Q anp
const Question1 = newQuestionContent({
  uid: 'amr',
  description: 'amr',
  keywords: ['Multiple', 'Common'],
  schema: z
    .object({
      num1: z.number().int().min(2).max(10),
      num2: z.number().int().min(2).max(10)
    })
    .refine(val => val.num1 !== val.num2, 'num1 and num2 must be different.')
    .refine(
      val => haveCommonMultipleWithinXSteps(val.num1, val.num2, 7),
      'number1 and number2 must have a common multiple between their first 7 multiples.'
    ),
  questionHeight: 950,
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(2, 10);
    const num2 = randomIntegerInclusive(2, 10, {
      constraint: x => x !== num1 && haveCommonMultipleWithinXSteps(x, num1, 7)
    });

    return { num1, num2 };
  },
  Component: ({ question: { num1, num2 }, translate }) => {
    //Create an array for the first 7 multiples of each number to go on the track
    const num1Multiples = filledNumberTrackArray(num1, num1, 7);
    const num2Multiples = filledNumberTrackArray(num2, num2, 7);

    //Get the first 3 common multiples of the two numbers (a max of 3 can be on the track)
    const commonMultiples = findCommonMultiples(num1, num2, 3);
    // Filter for the the common multiples that will be on the track
    const commonMultiplesInTrack = commonMultiples.filter(
      multiple => multiple <= 7 * Math.min(num1, num2)
    );

    const numberOfCommmonMultiples = commonMultiplesInTrack.length;

    // Answer sentence will be based on how many common multiples are in the track
    const sentenceToFill = () => {
      if (numberOfCommmonMultiples === 1)
        return translate.answerSentences.ansIsACommonMultipleOfNumandNum(num1, num2);
      if (numberOfCommmonMultiples === 2)
        return translate.answerSentences.ansAndAnsAreCommonMultiplesOfNumAndNum(num1, num2);
      return translate.answerSentences.ansAnsAndAnsAreCommonMultiplesOfNumAndNum(num1, num2);
    };

    //Convert the numbers, in the track, to strings to shade the cells and to match answer.
    const commonMultiplesAsStrings = commonMultiplesInTrack.map(x => x.toString());

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={950}
        title={
          numberOfCommmonMultiples === 1
            ? translate.instructions.hereAreTheMultiplesOfXandYIdentifyCommonMultiple(num1, num2)
            : translate.instructions.hereAreTheMultiplesOfXandYIdentifyCommonMultiples(num1, num2)
        }
        Content={({ dimens }) => (
          <View style={{ gap: 20 }}>
            <NumberTrackKeyboardWithState
              id="track1"
              boxValues={num1Multiples}
              dimens={{ ...dimens, height: dimens.height / 3 }}
            />
            <NumberTrackKeyboardWithState
              id="track2"
              boxValues={num2Multiples}
              dimens={{ ...dimens, height: dimens.height / 3 }}
            />
          </View>
        )}
        sentence={sentenceToFill()}
        testCorrect={isEqualUnordered(commonMultiplesAsStrings)}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: commonMultiplesInTrack.map(ans => ans.toLocaleString()),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

// Question2 exported to Q anq
const Question2 = newQuestionContent({
  uid: 'ams',
  description: 'ams',
  keywords: ['Multiple', 'Common', '100 square'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(10),
      var2: z.number().int().min(2).max(10),
      numbers: z.array(z.number().int().min(2).max(30)).length(6)
    })
    .refine(val => val.var1 !== val.var2, 'var1 and var2 must be different'),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 10);

    //Make sure there is atleast one common multiple that is < 30
    const var2 = randomIntegerInclusive(2, 10, {
      constraint: x => x !== var1 && leastCommonMultiple(x, var1) <= 30
    });

    const lcm = leastCommonMultiple(var1, var2);

    const randomNumbers = randomUniqueIntegersInclusive(2, 30, 5, {
      constraint: x => lcm !== x
    });

    const numbers = [lcm, ...randomNumbers] as number[];
    return { var1, var2, numbers: shuffle(numbers) };
  },
  Component: ({ question: { var1, var2, numbers }, translate, displayMode }) => {
    const multiple1 = range(var1, 30, var1);
    const commonMultiples = [] as number[];

    // Get the common multiples
    numbers.forEach(number => {
      if (number % var1 === 0 && number % var2 === 0) {
        commonMultiples.push(number);
      }
    });

    return (
      <QF31aInteractiveNumberGridAndSelectables
        title={translate.instructions.theGridHasMultiplesOfXShadedShadeMultiplesOfYAndSelect(
          var1,
          var2
        )}
        pdfTitle={translate.instructions.hereIsAGridWithMultiplesOfXShadedInCircleMultiplesOfY(
          var1,
          var2
        )}
        startNumber={1}
        finishNumber={30}
        preshaded={multiple1}
        items={numbers}
        testCorrect={commonMultiples}
        sentence={
          displayMode !== 'digital'
            ? translate.instructions.whichNumsAreMultsOfXAndY(var1, var2)
            : translate.instructions.selectNumbersThatAreMultiplesOfBothXAndY(var1, var2)
        }
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question2v2 = newQuestionContent({
  uid: 'ams2',
  description: 'ams2',
  keywords: ['Multiple', 'Common', '100 square'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(10),
      number2: z.number().int().min(2).max(10),
      answers: z
        .array(z.number().int().min(2).max(30))
        .length(6)
        .refine(arrayHasNoDuplicates, 'All answers must be different')
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 must be different'),
  simpleGenerator: () => {
    const { number1, number2, selectedAnswers } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 10);

        const number2 = randomIntegerInclusive(2, 10, {
          // Make sure there are at least two common multiples that are < 30
          constraint: x => x !== number1 && leastCommonMultiple(x, number1) * 2 <= 30
        });

        const lcm = leastCommonMultiple(number1, number2);

        const otherPossibleCommonMultiples = range(2, 30).filter(
          num => num !== lcm && num % lcm === 0
        );

        const otherCommonMultiple = getRandomFromArray(otherPossibleCommonMultiples);

        const multiplesOfNumber1 = range(2, 30).filter(
          num => num % number1 === 0 && num !== lcm && num !== otherCommonMultiple
        );

        const otherAnswerA = getRandomFromArray(multiplesOfNumber1);

        const multiplesOfNumber2 = range(2, 30).filter(
          num =>
            num % number2 === 0 &&
            num !== lcm &&
            num !== otherCommonMultiple &&
            num !== otherAnswerA
        );

        const otherAnswerB = getRandomFromArray(multiplesOfNumber2);

        const randomNumbers = randomUniqueIntegersInclusive(2, 30, 2, {
          constraint: x =>
            arrayHasNoDuplicates([x, lcm, otherCommonMultiple, otherAnswerA, otherAnswerB])
        });

        const selectedAnswers = shuffle([
          lcm,
          otherCommonMultiple,
          otherAnswerA,
          otherAnswerB,
          ...randomNumbers
        ]);
        return { number1, number2, selectedAnswers };
      },
      ({ selectedAnswers }) =>
        // Some combinations and selected answers will cause remaining options to only be null. If any answers are null, re-run the rejection sample.
        arrayHasNoDuplicates(selectedAnswers) &&
        selectedAnswers.every(answer => typeof answer === 'number')
    );

    const answers = selectedAnswers as number[];

    return { number1, number2, answers };
  },
  Component: ({ question: { number1, number2, answers }, translate, displayMode }) => {
    const multiple1 = range(number1, 30, number1);

    const commonMultiples = answers.filter(
      answer => answer % number1 === 0 && answer % number2 === 0
    );

    return (
      <QF31aInteractiveNumberGridAndSelectables
        title={translate.instructions.theGridHasMultiplesOfXShadedShadeMultiplesOfYAndSelect(
          number1,
          number2
        )}
        pdfTitle={translate.instructions.hereIsAGridWithMultiplesOfXShadedInCircleMultiplesOfY(
          number1,
          number2
        )}
        startNumber={1}
        finishNumber={30}
        preshaded={multiple1}
        items={answers}
        testCorrect={commonMultiples}
        sentence={
          displayMode !== 'digital'
            ? translate.instructions.whichNumsAreMultsOfXAndY(number1, number2)
            : translate.instructions.selectNumbersThatAreMultiplesOfBothXAndY(number1, number2)
        }
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'amt',
  description: 'amt',
  keywords: ['Multiple', 'Common'],
  schema: z
    .object({
      var1: z.number().int().min(2).max(12),
      var2: z.number().int().min(2).max(12),
      numbers: z.array(z.number().int().min(2).max(99)).length(6)
    })
    .refine(val => val.var1 !== val.var2, 'var1 and var2 must be different'),
  questionHeight: 800,
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(2, 12);

    //Make sure there is atleast one common multiple that is < 100
    const var2 = randomIntegerInclusive(2, 12, {
      constraint: x => x !== var1 && leastCommonMultiple(x, var1) < 100
    });

    const commonMultiple = leastCommonMultiple(var1, var2);

    const multiples = randomUniqueIntegersInclusive(2, 99, 5, {
      constraint: x => commonMultiple !== x && (x % var1 === 0 || x % var2 === 0)
    });

    const numbers = [commonMultiple, ...multiples] as number[];
    return { var1, var2, numbers: shuffle(numbers) };
  },
  Component: ({ question: { var1, var2, numbers }, translate }) => {
    const multiple1: number[] = [];
    const multiple2: number[] = [];
    const multipleBoth: number[] = [];

    //Organize the numbers into their respective Sets
    numbers.forEach(number => {
      if (number % var1 === 0 && number % var2 === 0) {
        multipleBoth.push(number);
      } else if (number % var1 === 0) {
        multiple1.push(number);
      } else {
        multiple2.push(number);
      }
    });

    const correctOrder = [multiple1, multipleBoth, multiple2];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsSortMultiplesOfXAndY(
          var1.toLocaleString(),
          var2.toLocaleString()
        )}
        pdfTitle={translate.instructions.sortMultiplesOfXAndYPDF(
          var1.toLocaleString(),
          var2.toLocaleString()
        )}
        zoneNames={[
          translate.instructions.multiplesOfXOnly(var1.toLocaleString()),
          translate.instructions.multiplesOfXAndY(var1.toLocaleString(), var2.toLocaleString()),
          translate.instructions.multiplesOfXOnly(var2.toLocaleString())
        ]}
        items={numbers}
        testCorrect={correctOrder}
        pdfItemVariant="pdfSquare"
        questionHeight={800}
      />
    );
  }
});

// Question4v2 exported to Q ans2
const Question4v2 = newQuestionContent({
  uid: 'amu2',
  description: 'amu',
  keywords: ['Multiple', 'Common'],
  schema: z
    .object({
      num1: z.number().int().min(2).max(10),
      num2: z.number().int().min(2).max(10)
    })
    .refine(val => val.num1 !== val.num2, 'num1 and num2 must be different.')
    .refine(
      val => haveCommonMultipleWithinXSteps(val.num1, val.num2, 7),
      'number1 and number2 must have a common multiple between their first 7 multiples.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(2, 10);
    const num2 = randomIntegerInclusive(2, 10, {
      constraint: x => x !== num1 && haveCommonMultipleWithinXSteps(x, num1, 7)
    });

    return { num1, num2 };
  },
  Component: ({ question: { num1, num2 }, translate, displayMode }) => {
    // Create an array of length 7, showing the first two multiples and ans boxes for the remaining 5.
    const num1Multiples = multiplesNumberTrackArray(num1, num1, 5, 2);
    const num2Multiples = multiplesNumberTrackArray(num2, num2, 5, 2);

    //Get the first 3 common multiples of the two numbers (a max of 3 can be on the track)
    const commonMultiples = findCommonMultiples(num1, num2, 20);
    // Filter for the the common multiples that will be on the track
    const commonMultiplesInTrack = commonMultiples.filter(
      multiple => multiple <= 7 * (num1 < num2 ? num1 : num2)
    );

    // Answer sentence will be based on how many common multiples are in the track
    const sentenceToFill = (() => {
      const numberOfCommmonMultiples = commonMultiplesInTrack.length;
      if (numberOfCommmonMultiples === 1)
        return translate.answerSentences.ansIsACommonMultipleOfNumandNum(num1, num2);
      if (numberOfCommmonMultiples === 2)
        return translate.answerSentences.ansAndAnsAreCommonMultiplesOfNumAndNum(num1, num2);
      return translate.answerSentences.ansAnsAndAnsAreCommonMultiplesOfNumAndNum(num1, num2);
    })();

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        title={translate.instructions.fillInNumberTracksToHelpCompleteSentence()}
        Content={({ dimens }) => (
          <View style={{ gap: 20 }}>
            <NumberTrackKeyboardWithState
              id="track1"
              testCorrect={isEqual(num1Multiples.answerArray)}
              boxValues={num1Multiples.numberTrackArray}
              dimens={{ ...dimens, height: dimens.height / 3 }}
              defaultState={displayMode !== 'markscheme' ? undefined : num1Multiples.answerArray}
            />
            <NumberTrackKeyboardWithState
              id="track2"
              testCorrect={isEqual(num2Multiples.answerArray)}
              boxValues={num2Multiples.numberTrackArray}
              dimens={{ ...dimens, height: dimens.height / 3 }}
              defaultState={displayMode !== 'markscheme' ? undefined : num2Multiples.answerArray}
            />
          </View>
        )}
        sentence={sentenceToFill}
        testCorrect={userAnswer =>
          userAnswer.every(val => Number(val) % num1 === 0 && Number(val) % num2 === 0) &&
          arrayHasNoDuplicates(userAnswer)
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: commonMultiplesInTrack.map(num => num.toLocaleString()),
          answerText: translate.markScheme.orAnyOtherCommonMultiples()
        }}
      />
    );
  }
});

// Question4 exported to Q ans
const Question4 = newQuestionContent({
  uid: 'amu',
  description: 'amu',
  keywords: ['Multiple', 'Common'],
  schema: z
    .object({
      num1: z.number().int().min(2).max(10),
      num2: z.number().int().min(2).max(10)
    })
    .refine(val => val.num1 !== val.num2, 'num1 and num2 must be different.')
    .refine(
      val => haveCommonMultipleWithinXSteps(val.num1, val.num2, 7),
      'number1 and number2 must have a common multiple between their first 7 multiples.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(2, 10);
    const num2 = randomIntegerInclusive(2, 10, {
      constraint: x => x !== num1 && haveCommonMultipleWithinXSteps(x, num1, 7)
    });

    return { num1, num2 };
  },
  Component: ({ question: { num1, num2 }, translate, displayMode }) => {
    // Create an array of length 7, showing the first two multiples and ans boxes for the remaining 5.
    const num1Multiples = multiplesNumberTrackArray(num1, num1, 5);
    const num2Multiples = multiplesNumberTrackArray(num2, num2, 5);

    //Get the first 3 common multiples of the two numbers (a max of 3 can be on the track)
    const commonMultiples = findCommonMultiples(num1, num2, 20);
    // Filter for the the common multiples that will be on the track
    const commonMultiplesInTrack = commonMultiples.filter(
      multiple => multiple <= 7 * (num1 < num2 ? num1 : num2)
    );

    // Answer sentence will be based on how many common multiples are in the track
    const sentenceToFill = (() => {
      const numberOfCommmonMultiples = commonMultiplesInTrack.length;
      if (numberOfCommmonMultiples === 1)
        return translate.answerSentences.ansIsACommonMultipleOfNumandNum(num1, num2);
      if (numberOfCommmonMultiples === 2)
        return translate.answerSentences.ansAndAnsAreCommonMultiplesOfNumAndNum(num1, num2);
      return translate.answerSentences.ansAnsAndAnsAreCommonMultiplesOfNumAndNum(num1, num2);
    })();

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        title={translate.instructions.fillInNumberTracksToHelpCompleteSentence()}
        Content={({ dimens }) => (
          <View style={{ gap: 20 }}>
            <NumberTrackKeyboardWithState
              id="track1"
              testCorrect={isEqual(num1Multiples.answerArray)}
              boxValues={num1Multiples.numberTrackArray}
              dimens={{ ...dimens, height: dimens.height / 3 }}
              defaultState={displayMode === 'digital' ? undefined : num1Multiples.answerArray}
            />
            <NumberTrackKeyboardWithState
              id="track2"
              testCorrect={isEqual(num2Multiples.answerArray)}
              boxValues={num2Multiples.numberTrackArray}
              dimens={{ ...dimens, height: dimens.height / 3 }}
              defaultState={displayMode === 'digital' ? undefined : num2Multiples.answerArray}
            />
          </View>
        )}
        sentence={sentenceToFill}
        testCorrect={userAnswer =>
          userAnswer.every(val => Number(val) % num1 === 0 && Number(val) % num2 === 0) &&
          arrayHasNoDuplicates(userAnswer)
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: commonMultiplesInTrack.map(num => num.toLocaleString()),
          answerText: translate.markScheme.orAnyOtherCommonMultiples()
        }}
      />
    );
  }
});

// Question5 exported to Q ant
const Question5 = newQuestionContent({
  uid: 'amv',
  description: 'amv',
  keywords: ['Multiple', 'Common'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(12),
      number2: z.number().int().min(2).max(6)
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 must be different.')
    .refine(
      val => haveCommonMultipleWithinXSteps(val.number1, val.number2, 6),
      'number1 and number2 must have a common multiple between their first 6 multiples.'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusive(2, 6);
    const number1 = randomIntegerInclusive(2, 12, {
      constraint: x => x !== number2 && haveCommonMultipleWithinXSteps(x, number2, 6)
    });
    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const correctArrayNums = findCommonMultiples(number1, number2, 3);

    const correctArray = correctArrayNums.map(String);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.listTheFirstThreeCommonMultiplesOfXAndY(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        // Allow for the answers to be in any order.
        testCorrect={answer =>
          correctArray.includes(answer[0][0]) &&
          correctArray.includes(answer[1][0]) &&
          correctArray.includes(answer[2][0]) &&
          arrayHasNoDuplicates([answer[0][0], answer[1][0], answer[2][0]])
        }
        inputMaxCharacters={2}
        sentences={['<ans/>, ', '<ans/>, ', '<ans/>']}
        containerStyle={{ flexDirection: 'row', alignItems: 'center' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        pdfMainPanelContainerStyle={{ justifyContent: 'center' }}
        pdfDirection="row"
        customMarkSchemeAnswer={{
          answersToDisplay: [...correctArrayNums.map(num => [num.toLocaleString()])]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'amw',
  description: 'amw',
  keywords: ['Multiple', 'Common'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(5),
      number2: z.number().int().min(2).max(10),
      correctArrayNums: z.array(z.number().int()).length(4),
      incorrectArrayNums: z.array(z.number().int().min(19).max(132)).length(2)
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 cannot be the same')
    .refine(val => val.correctArrayNums.every(i => i <= 132), 'max common multiple should be 132'),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 5);

        const number2 = randomIntegerInclusive(2, 10, {
          constraint: x => x !== number1
        });

        const correctArrayNums = findCommonMultiples(number1, number2, 4);

        // Not a common multiple of number1 and number2
        const incorrectArrayNums = randomUniqueIntegersInclusive(19, 132, 2, {
          constraint: x => x % number1 !== 0 || x % number2 !== 0
        });

        return {
          number1,
          number2,
          correctArrayNums,
          incorrectArrayNums
        };
      },
      val => val.correctArrayNums.every(i => i <= 132)
    ),
  Component: props => {
    const {
      question: { number1, number2, correctArrayNums, incorrectArrayNums },
      translate
    } = props;

    const renderNumbers = [...correctArrayNums, ...incorrectArrayNums];

    // Randomise numbers
    const randomNumbers = shuffle(renderNumbers, {
      random: seededRandom(props.question)
    });

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectCommonMultiples(
          number1.toLocaleString(),
          number2.toLocaleString()
        )}
        testCorrect={correctArrayNums}
        multiSelect
        items={randomNumbers.map((number: number) => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CommonMultiples',
  questionTypes: [Question1, Question2v2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question2, Question4]
});

export const amr = Question1;
export const ams = Question2;
export const amt = Question3;
export const amu = Question4;
export const amu2 = Question4v2;
export const amv = Question5;
export default SmallStep;
