import { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';
import { Dimens } from '../../../../theme/scaling';
import Text from '../../../typography/Text';
import NoKeyboardTextInput from '../../../atoms/NoKeyboardTextInput';
import { colors } from '../../../../theme/colors';
import { AST, parseMarkup } from '../../../../markup';
import { SetState } from '../../../../utils/react';
import { withStateHOC } from '../../../../stateTree';
import { filledArray } from '../../../../utils/collections';
import { DisplayMode } from '../../../../contexts/displayMode';

type UserAnswer = string;

type Props = {
  userAnswer: UserAnswer[];
  setUserAnswer: SetState<UserAnswer[]>;
  /**
   * @param lhs A string for the label that goes on the left hand side
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> lhs should return a number string
   */
  lhs: string;
  /**
   * @param rhs A string for the label that goes on the right hand side
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> rhs should return a number string
   */
  rhs: string;
  /**
   * @param factors A string array to hold the label for the arrows.
   * The labels utilise our markup language, so use <ans/> where an answer box is required.
   * If used in a non-interactive way i.e not using <ans/> factors should return an array of number strings
   */
  factors: string[];
  /**
   * @param dimens Usable dimensions
   */
  dimens: Dimens;
  /**
   * Flag for which way the arrows should be pointing. Top is first then bottom.
   */
  arrowDirection: ('left' | 'right')[];
  /**
   * leading symbol in front of input box for the factors. Top is first then bottom.
   */
  leadingSymbols?: string[];
};

/**
 * This component renders a lhs and rhs with arrows horizontally across (left or right pointing)
 * The <ans/> string is used to annotate where a user answer input field is require.
 * The factors and the lhs and rhs can have input boxes.
 * The userAnswer array take the form of [lhsAnswer, rhsAnswer, factors]
 */
export const NumbersWithArrows = (props: Props) => {
  const {
    userAnswer = [],
    setUserAnswer = () => {},
    lhs,
    rhs,
    factors,
    dimens: { width, height },
    leadingSymbols,
    arrowDirection
  } = props;

  const displayMode = useContext(DisplayMode);

  // Parse all values to identify answer boxes
  const lhsParsed = parseMarkup(lhs);
  const rhsParsed = parseMarkup(rhs);
  const factorsParsed = factors.map(factors => parseMarkup(factors));

  // Starting variables for curve
  // These may need to be altered (including the svg width) if we need to increase length or curve of arrow
  const startPointX = 10;
  const endPointX = 700;
  const curve = 300;
  const arrowHalfWidth = 50;

  const styles = useStyles(width, height);
  const answerBoxWidth = displayMode === 'digital' ? 96 : 200;

  let index = -1;
  const getDisplay = (
    parsedValues: { tokens: AST[]; numberOfAns: number },
    position: 'left' | 'right'
  ) => {
    if (parsedValues.tokens[0].type === 'text') {
      const alignment = position === 'left' ? 'flex-end' : 'flex-start';
      const left = position === 'left' ? -10 : -5;
      return (
        <View style={{ width: answerBoxWidth, alignItems: alignment, left: left }}>
          <Text variant="WRN400">{parsedValues.tokens[0].value}</Text>
        </View>
      );
    } else {
      index++;
      const adjustedAnswerBoxWidth = displayMode === 'digital' ? answerBoxWidth / 2 : 0;
      const style =
        position === 'left'
          ? { left: adjustedAnswerBoxWidth - 10 }
          : { left: -adjustedAnswerBoxWidth };
      return (
        <View style={style}>
          <NoKeyboardTextInput
            value={userAnswer[index]}
            onChangeText={text => {
              const newState = [...userAnswer];
              newState[index] = text;
              setUserAnswer(newState);
            }}
          />
        </View>
      );
    }
  };

  const sentence = () => {
    const lhsDisplay = getDisplay(lhsParsed, 'left');
    const rhsDisplay = getDisplay(rhsParsed, 'right');
    const answerPresent = lhsParsed.numberOfAns > 0 || rhsParsed.numberOfAns > 0;
    return (
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          gap: answerPresent ? arrowHalfWidth * 2 : arrowHalfWidth * 2 - 20,
          height: answerBoxWidth + 20
        }}
      >
        {lhsDisplay}
        {rhsDisplay}
      </View>
    );
  };

  // Create arrow with head to the right
  const arrowRight = (
    <G key={'arrow'}>
      <Path
        d={`M${startPointX},${curve} C${startPointX},10 ${endPointX},0 ${endPointX},${curve}`}
        fill="none"
        stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
        strokeWidth={30}
      />
      <Path
        d={`M${endPointX - arrowHalfWidth} ${curve - arrowHalfWidth * 0.5} L${
          endPointX + arrowHalfWidth
        } ${curve - arrowHalfWidth * 0.5} L ${endPointX} ${curve + 50} Z`}
        fill={displayMode !== 'digital' ? colors.black : colors.burntSienna}
        stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
      />
    </G>
  );

  // Create arrow with head to the left
  const arrowLeft = (
    <G key={'arrow'}>
      <Path
        d={`M${startPointX + arrowHalfWidth},${curve} C${
          startPointX + arrowHalfWidth
        },10 ${endPointX},0 ${endPointX},${curve}`}
        fill="none"
        stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
        strokeWidth={30}
      />
      <Path
        d={`M${startPointX} ${curve - arrowHalfWidth * 0.5} L${startPointX + 2 * arrowHalfWidth} ${
          curve - arrowHalfWidth * 0.5
        } L ${startPointX + arrowHalfWidth} ${curve + 50} Z`}
        fill={displayMode !== 'digital' ? colors.black : colors.burntSienna}
        stroke={displayMode !== 'digital' ? colors.black : colors.burntSienna}
      />
    </G>
  );

  const rightViewBox = `0 0 ${endPointX + arrowHalfWidth} ${curve}`;
  const leftViewBox = `${startPointX} 0 ${endPointX} ${curve}`;

  // Get answer count for input indexing
  const factorAnswersCount = factorsParsed[0].numberOfAns + factorsParsed[1].numberOfAns;
  const answerCount = getAnswerCount(props);

  // Generate the factors to sit above the arrows
  const getFactor = (index: number) => {
    const factor = factorsParsed[index];
    const answerIndex = index === 0 ? 1 : factorAnswersCount;
    const leadingSymbol = leadingSymbols ? leadingSymbols[index] : undefined;
    return factor.numberOfAns > 0 ? (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {leadingSymbol && (
          <View style={{ position: 'absolute', left: -40 }}>
            <Text variant="WRN400">{`${leadingSymbol} `}</Text>
          </View>
        )}
        <NoKeyboardTextInput
          value={userAnswer[answerCount - answerIndex]}
          onChangeText={text => {
            const newState = [...userAnswer];
            newState[answerCount - answerIndex] = text;
            setUserAnswer(newState);
          }}
        />
      </View>
    ) : (
      factor.tokens[0].type === 'text' && <Text variant="WRN400">{factor.tokens[0].value}</Text>
    );
  };

  const svgWidth = 120;
  const svgHeight = 70;
  // Define padding to centralise the arrow. We need padding to ensure the arrows are central as the markup has padding
  const arrowPadding = { paddingRight: 14 };

  return (
    <View style={styles.containerHorizontal}>
      <View
        style={{
          ...arrowPadding,
          alignItems: 'center'
        }}
      >
        {getFactor(0)}
        <View>
          <Svg
            height={svgHeight}
            width={svgWidth}
            viewBox={arrowDirection[0] === 'right' ? rightViewBox : leftViewBox}
            style={{ top: 0, left: 0 }}
            pointerEvents={'none'}
          >
            {arrowDirection[0] === 'right' ? arrowRight : arrowLeft}
          </Svg>
        </View>
      </View>
      {sentence()}
      <View
        style={{
          ...arrowPadding,
          alignItems: 'center'
        }}
      >
        <View style={{ transform: [{ rotate: '180deg' }] }}>
          <Svg
            height={svgHeight}
            width={svgWidth}
            viewBox={arrowDirection[1] === 'right' ? leftViewBox : rightViewBox}
            style={{ top: 0, left: 0 }}
            pointerEvents={'none'}
          >
            {arrowDirection[1] === 'right' ? arrowLeft : arrowRight}
          </Svg>
        </View>
        {getFactor(1)}
      </View>
    </View>
  );
};

export const NumbersWithArrowsWithState = withStateHOC(NumbersWithArrows, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray('', getAnswerCount(props)),
    testComplete: userAnswer => userAnswer.every(i => i !== '')
  })
});

const getAnswerCount = (props: Omit<Props, 'userAnswer' | 'setUserAnswer'>) => {
  const factorsParsed = props.factors
    .map(factors => parseMarkup(factors))
    .filter(i => i.numberOfAns > 0);
  const lhsParsed = parseMarkup(props.lhs).numberOfAns;
  const rhsParsed = parseMarkup(props.rhs).numberOfAns;
  return lhsParsed + rhsParsed + factorsParsed.length;
};

const useStyles = (width: number, height: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        containerHorizontal: {
          width: width,
          height: height,
          alignItems: 'center',
          justifyContent: 'center'
        }
      }),
    [width, height]
  );
};
