import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from '../i18n/i18n-types';

const sportNames = [
  'Badminton',
  'Basketball',
  'Cricket',
  'Football',
  'Hockey',
  'Rugby',
  'Tennis',
  'Volleyball'
] as const;

export const sportSchema = z.enum(sportNames);

export function getRandomSport() {
  return getRandomFromArray(sportNames);
}

export function getRandomUniqueSports(numberOfSports: number) {
  return getRandomSubArrayFromArray([...sportNames] as const, numberOfSports);
}

export type SportName = (typeof sportNames)[number];

export const isSport = (x: string): x is SportName => sportNames.includes(x as SportName);

export const sportAsWord = (object: SportName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Badminton':
      return translate.sports.Badminton();
    case 'Basketball':
      return translate.sports.Basketball();
    case 'Cricket':
      return translate.sports.Cricket();
    case 'Football':
      return translate.sports.Football();
    case 'Hockey':
      return translate.sports.Hockey();
    case 'Rugby':
      return translate.sports.Rugby();
    case 'Tennis':
      return translate.sports.Tennis();
    case 'Volleyball':
      return translate.sports.Volleyball();
  }
};
