import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';
import QF17cCompleteTheDoubleNumberLine from '../../../../components/question/questionFormats/QF17cCompleteTheDoubleNumberLine';
import { compareFloats } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'aDo',
  description: 'aDo',
  keywords: ['Days', 'Weeks', 'Converting units'],
  schema: z.object({
    week2: z.number().int().min(3).max(20)
  }),
  simpleGenerator: () => {
    const week2 = randomIntegerInclusive(3, 20);
    return { week2 };
  },
  Component: props => {
    const {
      question: { week2 },
      translate,
      displayMode
    } = props;

    const statements = [
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{
              width: displayMode === 'digital' ? 300 : 450,
              textAlign: 'right',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {translate.answerSentences.daysIn1Week()}
          </Text>
        ),
        correctAnswer: (7).toLocaleString()
      },
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{
              width: displayMode === 'digital' ? 300 : 450,
              textAlign: 'right',
              fontSize: displayMode === 'digital' ? 32 : 50
            }}
          >
            {translate.answerSentences.daysInXWeeks(week2.toLocaleString())}
          </Text>
        ),
        correctAnswer: (week2 * 7).toLocaleString()
      }
    ];

    const items = shuffle(
      statements.map(({ correctAnswer }) => correctAnswer),
      { random: seededRandom(props.question) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useCardsCompleteStatements()}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={items}
        moveOrCopy="move"
        actionPanelVariant="endWide"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'aDo2',
  description: 'aDo',
  keywords: ['Days', 'Weeks', 'Converting units'],
  schema: z.object({
    week2: z.number().int().min(3).max(20),
    items: z.array(z.number().min(3).max(200)).length(4).refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const week2 = randomIntegerInclusive(3, 20);

    const incorrectItems = getRandomSubArrayFromArray(
      [week2 * 5, week2 * 10, (week2 + 1) * 7, (week2 - 1) * 7],
      3
    );

    const items = shuffle([week2 * 7, ...incorrectItems]);

    return { week2, items };
  },
  Component: props => {
    const {
      question: { week2, items },
      translate
    } = props;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardToCompleteStatement()}
        pdfTitle={translate.instructions.useCardToCompleteStatement()}
        sentence={translate.answerSentences.thereAreAnsDaysInNumWeeks(week2)}
        testCorrect={[week2 * 7]}
        items={items.map(el => ({ value: el, component: el.toLocaleString() }))}
        itemVariant="shortRectangle"
        actionPanelVariant="endMid"
        moveOrCopy="move"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aDp',
  description: 'aDp',
  keywords: ['Converting units', 'Years', 'Months', 'Double number line'],
  schema: z
    .object({
      topRowNumbers: z.array(z.number().min(0).max(19)),
      bottomRowNumbers: z.array(z.number().min(0).max(228)),
      topRowAnswerIndexes: z.array(z.number().min(0).max(5)).length(2),
      bottomRowAnswerIndexes: z.array(z.number().min(0).max(5)).length(2)
    })
    .refine(
      val => arrayHasNoDuplicates([...val.topRowAnswerIndexes, ...val.bottomRowAnswerIndexes]),
      'all answer indexes must be different'
    ),
  simpleGenerator: () => {
    const topRowNumberA = 1;
    const topRowNumberB = randomIntegerInclusive(topRowNumberA + 1, 5);
    const topRowNumberC = randomIntegerInclusive(topRowNumberB + 1, 9);
    const topRowNumberD = randomIntegerInclusive(topRowNumberC + 1, 13);
    const topRowNumberE = randomIntegerInclusive(topRowNumberD + 1, 19);

    const topRowNumbers = [
      0,
      topRowNumberA,
      topRowNumberB,
      topRowNumberC,
      topRowNumberD,
      topRowNumberE
    ];

    const bottomRowNumbers = topRowNumbers.map(num => num * 12);

    const topRowAnswerIndexes = randomUniqueIntegersInclusive(0, 5, 2);
    const bottomRowAnswerIndexes = randomUniqueIntegersInclusive(0, 5, 2, {
      constraint: x => !topRowAnswerIndexes.includes(x)
    });

    return {
      topRowNumbers,
      bottomRowNumbers,
      topRowAnswerIndexes,
      bottomRowAnswerIndexes
    };
  },

  Component: props => {
    const {
      question: { topRowNumbers, bottomRowNumbers, topRowAnswerIndexes, bottomRowAnswerIndexes },
      translate
    } = props;

    const sortedTopRowAnswerIndexes = sortNumberArray(topRowAnswerIndexes);
    const sortedBottomRowAnswerIndexes = sortNumberArray(bottomRowAnswerIndexes);

    const measurements = [translate.units.years(2), translate.units.months(2)];

    const topRowTickValues = topRowNumbers.map((num, index) =>
      sortedTopRowAnswerIndexes.includes(index) ? '<ans/>' : num
    );

    const bottomRowTickValues = bottomRowNumbers.map((num, index) =>
      sortedBottomRowAnswerIndexes.includes(index) ? '<ans/>' : num
    );

    return (
      <QF17cCompleteTheDoubleNumberLine
        title={translate.instructions.completeConversions()}
        topTickValues={topRowTickValues}
        bottomTickValues={bottomRowTickValues}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], topRowNumbers[sortedTopRowAnswerIndexes[0]]) &&
          compareFloats(userAnswer[1], topRowNumbers[sortedTopRowAnswerIndexes[1]]) &&
          compareFloats(userAnswer[2], bottomRowNumbers[sortedBottomRowAnswerIndexes[0]]) &&
          compareFloats(userAnswer[3], bottomRowNumbers[sortedBottomRowAnswerIndexes[1]])
        }
        extraSymbol="decimalPoint"
        precedingLinesText={[measurements[0], measurements[1]]}
        questionHeight={700}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            topRowNumbers[sortedTopRowAnswerIndexes[0]],
            topRowNumbers[sortedTopRowAnswerIndexes[1]],
            bottomRowNumbers[sortedBottomRowAnswerIndexes[0]],
            bottomRowNumbers[sortedBottomRowAnswerIndexes[1]]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  },
  questionHeight: 700
});

const Question3 = newQuestionContent({
  uid: 'aDq',
  description: 'aDq',
  keywords: ['Converting units', 'Hours', 'Minutes', 'Double number line'],
  schema: z
    .object({
      topRowNumbers: z.array(z.number().min(0).max(100)),
      bottomRowNumbers: z.array(z.number().min(0).max(6000)),
      topRowAnswerIndexes: z.array(z.number().min(0).max(5)).length(2),
      bottomRowAnswerIndexes: z.array(z.number().min(0).max(5)).length(2)
    })
    .refine(
      val => arrayHasNoDuplicates([...val.topRowAnswerIndexes, ...val.bottomRowAnswerIndexes]),
      'all answer indexes must be different'
    ),
  simpleGenerator: () => {
    const topRowNumberA = getRandomFromArray([0.25, 0.5, 0.75] as const);
    const topRowNumberB = 1;
    const topRowNumberC = randomIntegerInclusive(2, 4);
    const topRowNumberD = randomIntegerInclusive(topRowNumberC + 1, 11);
    const topRowNumberE = randomIntegerInclusiveStep(12, 100, 10);

    const topRowNumbers = [
      0,
      topRowNumberA,
      topRowNumberB,
      topRowNumberC,
      topRowNumberD,
      topRowNumberE
    ];

    const bottomRowNumbers = topRowNumbers.map(num => num * 60);

    const topRowAnswerIndexes = randomUniqueIntegersInclusive(0, 5, 2);
    const bottomRowAnswerIndexes = randomUniqueIntegersInclusive(0, 5, 2, {
      constraint: x => !topRowAnswerIndexes.includes(x)
    });

    return {
      topRowNumbers,
      bottomRowNumbers,
      topRowAnswerIndexes,
      bottomRowAnswerIndexes
    };
  },

  Component: props => {
    const {
      question: { topRowNumbers, bottomRowNumbers, topRowAnswerIndexes, bottomRowAnswerIndexes },
      translate
    } = props;

    const sortedTopRowAnswerIndexes = sortNumberArray(topRowAnswerIndexes);
    const sortedBottomRowAnswerIndexes = sortNumberArray(bottomRowAnswerIndexes);

    const measurements = [translate.time.hours(2), translate.time.minutes(2)];

    const topRowTickValues = topRowNumbers.map((num, index) =>
      sortedTopRowAnswerIndexes.includes(index) ? '<ans/>' : num
    );

    const bottomRowTickValues = bottomRowNumbers.map((num, index) =>
      sortedBottomRowAnswerIndexes.includes(index) ? '<ans/>' : num
    );

    return (
      <QF17cCompleteTheDoubleNumberLine
        title={translate.instructions.completeConversions()}
        topTickValues={topRowTickValues}
        bottomTickValues={bottomRowTickValues}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], topRowNumbers[sortedTopRowAnswerIndexes[0]]) &&
          compareFloats(userAnswer[1], topRowNumbers[sortedTopRowAnswerIndexes[1]]) &&
          compareFloats(userAnswer[2], bottomRowNumbers[sortedBottomRowAnswerIndexes[0]]) &&
          compareFloats(userAnswer[3], bottomRowNumbers[sortedBottomRowAnswerIndexes[1]])
        }
        extraSymbol="decimalPoint"
        precedingLinesText={[measurements[0], measurements[1]]}
        questionHeight={700}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            topRowNumbers[sortedTopRowAnswerIndexes[0]],
            topRowNumbers[sortedTopRowAnswerIndexes[1]],
            bottomRowNumbers[sortedBottomRowAnswerIndexes[0]],
            bottomRowNumbers[sortedBottomRowAnswerIndexes[1]]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  },
  questionHeight: 700
});

const Question3v2 = newQuestionContent({
  uid: 'aDq2',
  description: 'aDq',
  keywords: ['Converting units', 'Hours', 'Minutes', 'Double number line'],
  schema: z
    .object({
      topRowNumbers: z.array(z.number().min(0).max(100)),
      bottomRowNumbers: z.array(z.number().min(0).max(6000)),
      topRowAnswerIndexes: z
        .array(z.number().min(0).max(5))
        .refine(arr => [1, 2].includes(arr.length)),
      bottomRowAnswerIndexes: z
        .array(z.number().min(0).max(5))
        .refine(arr => [1, 2].includes(arr.length))
    })
    .refine(
      val => arrayHasNoDuplicates([...val.topRowAnswerIndexes, ...val.bottomRowAnswerIndexes]),
      'all answer indexes must be different'
    ),
  simpleGenerator: () => {
    const topRowNumberA = getRandomFromArray([0.25, 0.5, 0.75] as const);
    const topRowNumberC = randomIntegerInclusive(2, 4);
    const topRowNumberD = randomIntegerInclusive(topRowNumberC + 1, 11);
    const topRowNumberE = randomIntegerInclusiveStep(12, 100, 10);

    const topRowNumbers = [0, topRowNumberA, 1, topRowNumberC, topRowNumberD, topRowNumberE];

    const bottomRowNumbers = topRowNumbers.map(num => num * 60);

    const showFourAnswerBoxes = bottomRowNumbers.every(num => num < 999);

    const removeFromTop = getRandomBoolean();

    const topRowAnswerIndexes = randomUniqueIntegersInclusive(
      0,
      5,
      showFourAnswerBoxes ? 2 : removeFromTop ? 1 : 2
    );
    const bottomRowAnswerIndexes = randomUniqueIntegersInclusive(
      0,
      5,
      showFourAnswerBoxes ? 2 : !removeFromTop ? 1 : 2,
      {
        constraint: x => !topRowAnswerIndexes.includes(x)
      }
    );

    return {
      topRowNumbers,
      bottomRowNumbers,
      topRowAnswerIndexes,
      bottomRowAnswerIndexes
    };
  },

  Component: props => {
    const {
      question: { topRowNumbers, bottomRowNumbers, topRowAnswerIndexes, bottomRowAnswerIndexes },
      translate,
      displayMode
    } = props;

    const sortedTopRowAnswerIndexes = sortNumberArray(topRowAnswerIndexes);
    const sortedBottomRowAnswerIndexes = sortNumberArray(bottomRowAnswerIndexes);

    const measurements = [translate.time.hours(2), translate.time.minutes(2)];

    const topRowTickValues = topRowNumbers.map((num, index) =>
      sortedTopRowAnswerIndexes.includes(index) ? '<ans/>' : num
    );

    const bottomRowTickValues = bottomRowNumbers.map((num, index) =>
      sortedBottomRowAnswerIndexes.includes(index) ? '<ans/>' : num
    );

    const numberOfAnswers = topRowAnswerIndexes.length + bottomRowAnswerIndexes.length;

    const testCorrect = (userAnswer: string[]) => {
      const answerVariation =
        numberOfAnswers === 4 ? 'a' : sortedTopRowAnswerIndexes.length === 1 ? 'b' : 'c';

      switch (answerVariation) {
        case 'a':
          return (
            compareFloats(userAnswer[0], topRowNumbers[sortedTopRowAnswerIndexes[0]]) &&
            compareFloats(userAnswer[1], topRowNumbers[sortedTopRowAnswerIndexes[1]]) &&
            compareFloats(userAnswer[2], bottomRowNumbers[sortedBottomRowAnswerIndexes[0]]) &&
            compareFloats(userAnswer[3], bottomRowNumbers[sortedBottomRowAnswerIndexes[1]])
          );
        case 'b':
          return (
            compareFloats(userAnswer[0], topRowNumbers[sortedTopRowAnswerIndexes[0]]) &&
            compareFloats(userAnswer[1], bottomRowNumbers[sortedBottomRowAnswerIndexes[0]]) &&
            compareFloats(userAnswer[2], bottomRowNumbers[sortedBottomRowAnswerIndexes[1]])
          );
        case 'c':
          return (
            compareFloats(userAnswer[0], topRowNumbers[sortedTopRowAnswerIndexes[0]]) &&
            compareFloats(userAnswer[1], topRowNumbers[sortedTopRowAnswerIndexes[1]]) &&
            compareFloats(userAnswer[2], bottomRowNumbers[sortedBottomRowAnswerIndexes[0]])
          );
      }
    };

    return (
      <QF17cCompleteTheDoubleNumberLine
        title={translate.instructions.completeConversions()}
        topTickValues={topRowTickValues}
        bottomTickValues={bottomRowTickValues}
        inputMaxCharacters={3}
        testCorrect={userAnswer => testCorrect(userAnswer)}
        extraSymbol="decimalPoint"
        precedingLinesText={[measurements[0], measurements[1]]}
        questionHeight={700}
        customFontSize={displayMode === 'digital' ? 32 : 50}
        customMarkSchemeAnswer={{
          answersToDisplay:
            numberOfAnswers === 4
              ? [
                  topRowNumbers[sortedTopRowAnswerIndexes[0]].toLocaleString(),
                  topRowNumbers[sortedTopRowAnswerIndexes[1]].toLocaleString(),
                  bottomRowNumbers[sortedBottomRowAnswerIndexes[0]].toLocaleString(),
                  bottomRowNumbers[sortedBottomRowAnswerIndexes[1]].toLocaleString()
                ]
              : sortedTopRowAnswerIndexes.length === 1
              ? [
                  topRowNumbers[sortedTopRowAnswerIndexes[0]].toLocaleString(),
                  bottomRowNumbers[sortedBottomRowAnswerIndexes[0]].toLocaleString(),
                  bottomRowNumbers[sortedBottomRowAnswerIndexes[1]].toLocaleString()
                ]
              : [
                  topRowNumbers[sortedTopRowAnswerIndexes[0]].toLocaleString(),
                  topRowNumbers[sortedTopRowAnswerIndexes[1]].toLocaleString(),
                  bottomRowNumbers[sortedBottomRowAnswerIndexes[0]].toLocaleString()
                ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  },
  questionHeight: 700
});

const Question4 = newQuestionContent({
  uid: 'aDr',
  description: 'aDr',
  keywords: ['Converting units', 'Years', 'Months', 'Weeks', 'Days', 'Hours', 'Minutes', 'Seconds'],
  schema: z.object({
    units: z.number().int().min(2).max(12),
    unitsToUse: z.enum(['weeks', 'years', 'mins', 'hours'])
  }),
  simpleGenerator: () => {
    const unitsToUse = getRandomFromArray(['weeks', 'years', 'mins', 'hours'] as const);
    const units = randomIntegerInclusive(2, 12);

    return { units, unitsToUse };
  },

  Component: ({ question: { units, unitsToUse }, translate }) => {
    const equation =
      unitsToUse === 'weeks'
        ? {
            lhs: `${translate.time.numWeeks(units)}`,
            rhs: `${translate.answerSentences.ansDays()}`,
            correctAns: units * 7
          }
        : unitsToUse === 'years'
        ? {
            lhs: `${translate.time.numYears(units)}`,
            rhs: `${translate.answerSentences.ansMonths()}`,
            correctAns: units * 12
          }
        : unitsToUse === 'mins'
        ? {
            lhs: `${translate.time.numMinutes(units)}`,
            rhs: `${translate.answerSentences.ansSeconds()}`,
            correctAns: units * 60
          }
        : {
            lhs: `${translate.time.numMinutes(units * 60)}`,
            rhs: `${translate.answerSentences.ansHours()}`,
            correctAns: units
          };

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeConversion()}
        leftSide={[equation.lhs]}
        rightSide={[equation.rhs]}
        testCorrect={{
          right: [[equation.correctAns.toString()]]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aDs',
  description: 'aDs',
  keywords: [
    'Fraction',
    'Tenths',
    'Minutes',
    'Seconds',
    'Faster',
    'Quicker',
    'Slower',
    'Greater than',
    'Less than'
  ],
  schema: z.object({
    name1: nameSchema,
    name2: nameSchema,
    mins1: z.number().int().min(1).max(10),
    secs1: z.number().int().min(1).max(59),
    secs2: z.number().int().min(2).max(689)
  }),
  simpleGenerator: () => {
    const [name1, name2] = getRandomUniqueNames(2);
    const mins1 = randomIntegerInclusive(1, 10);
    const secs1 = randomIntegerInclusive(1, 59);

    const diff = randomIntegerInclusive(1, 30);
    const totalSecs1 = mins1 * 60 + secs1;
    const secs2 = getRandomBoolean() ? totalSecs1 + diff : totalSecs1 - diff;

    return { name1, name2, mins1, secs1, secs2 };
  },
  Component: props => {
    const {
      question: { name1, name2, mins1, secs1, secs2 },
      translate
    } = props;

    const totalSecs1 = mins1 * 60 + secs1;
    const items = shuffle(
      [
        { timeSecs: totalSecs1, name: name1 },
        { timeSecs: secs2, name: name2 }
      ],
      { random: seededRandom(props.question) }
    );
    return (
      <QF10SelectNumbers
        title={`${translate.instructions.xAndYRanARaceXRanTheRaceInAMinAndBSecsYRanTheRaceInCSecs(
          name1,
          name2,
          mins1,
          secs1,
          secs2
        )}<br/>${translate.instructions.whoWasFasterSelectYourAnswer()}`}
        items={items.map(({ timeSecs, name }) => ({
          value: timeSecs,
          component: name
        }))}
        testCorrect={[Math.min(totalSecs1, secs2)]}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aDs2',
  description: 'aDs',
  keywords: [
    'Fraction',
    'Tenths',
    'Minutes',
    'Seconds',
    'Faster',
    'Quicker',
    'Slower',
    'Greater than',
    'Less than'
  ],
  schema: z.object({
    name1: nameSchema,
    name2: nameSchema,
    mins1: z.number().int().min(1).max(10),
    secs1: z.number().int().min(1).max(59),
    secs2: z.number().int().min(2).max(689),
    isFaster: z.boolean()
  }),
  simpleGenerator: () => {
    const [name1, name2] = getRandomUniqueNames(2);
    const mins1 = randomIntegerInclusive(1, 10);
    const secs1 = randomIntegerInclusive(1, 59);

    const diff = randomIntegerInclusive(1, 30);
    const totalSecs1 = mins1 * 60 + secs1;
    const secs2 = getRandomBoolean() ? totalSecs1 + diff : totalSecs1 - diff;

    const isFaster = getRandomBoolean();

    return { name1, name2, mins1, secs1, secs2, isFaster };
  },
  Component: props => {
    const {
      question: { name1, name2, mins1, secs1, secs2, isFaster },
      translate
    } = props;

    const totalSecs1 = mins1 * 60 + secs1;
    const items = shuffle(
      [
        { timeSecs: totalSecs1, name: name1 },
        { timeSecs: secs2, name: name2 }
      ],
      { random: seededRandom(props.question) }
    );
    return (
      <QF10SelectNumbers
        title={`${translate.instructions.xAndYRanARaceXRanTheRaceInAMinAndBSecsYRanTheRaceInCSecs(
          name1,
          name2,
          mins1,
          secs1,
          secs2
        )}<br/>${
          isFaster
            ? translate.instructions.whoWasFasterSelectYourAnswer()
            : translate.instructions.whoWasSlowerSelectYourAnswer()
        }`}
        pdfTitle={`${translate.instructions.xAndYRanARaceXRanTheRaceInAMinAndBSecsYRanTheRaceInCSecs(
          name1,
          name2,
          mins1,
          secs1,
          secs2
        )}<br/>${
          isFaster
            ? translate.instructions.whoWasFasterCircleYourAnswer()
            : translate.instructions.whoWasSlowerCircleYourAnswer()
        }`}
        items={items.map(({ timeSecs, name }) => ({
          value: timeSecs,
          component: name
        }))}
        testCorrect={[isFaster ? Math.min(totalSecs1, secs2) : Math.max(totalSecs1, secs2)]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aDt',
  description: 'aDt',
  keywords: ['Converting units', 'Days', 'Weeks'],
  schema: z.object({
    name1: nameSchema,
    name2: nameSchema,
    weeks: z.number().int().min(12).max(20),
    days: z.number().int().min(1).max(6)
  }),
  simpleGenerator: () => {
    const [name1, name2] = getRandomUniqueNames(2);
    const weeks = randomIntegerInclusive(12, 20);
    const days = randomIntegerInclusive(1, 6);

    return { name1, name2, weeks, days };
  },

  Component: props => {
    const {
      question: { name1, name2, weeks, days },
      translate
    } = props;

    const totalDays = weeks * 7 + days;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.xAndYHaveKnownEachOtherForXDaysForHowManyWeeksAndDaysHaveTheyKnownEachOtherCompleteTheStatement(
          name1,
          name2,
          totalDays
        )}
        sentence={`${translate.answerSentences.ansWeeksAnsDays()}`}
        testCorrect={[weeks.toString(), days.toString()]}
        sentenceStyle={{ justifyContent: 'flex-start' }}
      />
    );
  }
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertUnitsOfTime',
  questionTypes: [Question1v2, Question2, Question3v2, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question1, Question3, Question5]
});
export default SmallStep;
