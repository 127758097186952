import { StyleSheet, View } from 'react-native';
import { colors } from '../../../theme/colors';
import TextStructure from '../../molecules/TextStructure';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Props = {
  /** Text to display above or below the curly brace */
  braceText: string | number;
  /** Determine whether the brace is to be above or below the bar model. Optional prop, defaults to 'top'. */
  topOrBottomBrace?: 'top' | 'bottom';
  sideText?: boolean;
};

/**
 * This is a component to return a curly brace, to be used above or below a bar model. Defaults to display above a bar model.
 */
export const BarModelCurlyBrace = ({
  braceText,
  topOrBottomBrace = 'top',
  sideText = false
}: Props) => {
  const styles = useStyles();

  return (
    <View
      style={[
        styles.wholeContainer,
        topOrBottomBrace === 'bottom' && { transform: [{ scale: -1 }] }
      ]}
    >
      <View style={styles.wholeHorizontalLine}>
        <TextStructure
          style={[
            styles.braceText,
            // If bottom brace, need to un-revert the flipping on text so it displays upright:
            topOrBottomBrace === 'bottom' && { transform: [{ scale: -1 }] },
            sideText && { transform: [{ rotate: '270deg' }] }
          ]}
          sentence={braceText.toString()}
        />
        <View
          style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineMiddle]}
        ></View>
        <View
          style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineLeft]}
        ></View>
        <View
          style={[styles.wholeHorizontalVerticalLine, styles.wholeHorizontalVerticalLineRight]}
        ></View>
      </View>
    </View>
  );
};

const useStyles = () => {
  const displayMode = useContext(DisplayMode);

  return StyleSheet.create({
    wholeContainer: {
      paddingBottom: 20
    },
    wholeHorizontalLine: {
      backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      height: displayMode === 'digital' ? 2 : 4,
      position: 'relative'
    },
    wholeHorizontalVerticalLine: {
      backgroundColor: displayMode === 'digital' ? colors.prussianBlue : colors.black,
      height: 10,
      position: 'absolute',
      top: 0,
      width: displayMode === 'digital' ? 2 : 4
    },
    wholeHorizontalVerticalLineLeft: {
      left: 0
    },
    wholeHorizontalVerticalLineRight: {
      right: 0
    },
    wholeHorizontalVerticalLineMiddle: {
      alignSelf: 'center',
      height: 20,
      top: -20
    },
    braceText: {
      alignSelf: 'center',
      position: 'absolute',
      bottom: 20
    }
  });
};
