import { CounterVariant } from '../representations/types';
import PlaceValueChart, {
  getDraggableItems,
  numberToColState
} from '../representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation } from '../../../utils/math';
import { PlaceValueColumn } from '../representations/Place Value Chart/PlaceValueCounters';
import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from '../../atoms/MeasureView';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { HeaderVariant } from '../representations/Place Value Chart/TableHeaderRow';
import { useI18nContext } from '../../../i18n/i18n-react';
import { equal } from 'mathjs';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import { filledArray } from '../../../utils/collections';

type Props = TitleStyleProps & {
  title?: string;
  pdfTitle?: string;
  initialState?: number;
  number: ScientificNotation;
  columnsToShow: PlaceValueColumn[];
  counterVariant: Exclude<CounterVariant, 'number'>;
  headerVariant?: HeaderVariant;
  questionHeight?: number;
  actionPanelVariant?: 'end' | 'bottom';
};

export default function QF23CreatePlaceValueChart({
  title,
  pdfTitle,
  initialState,
  number,
  columnsToShow,
  counterVariant,
  headerVariant,
  questionHeight,
  actionPanelVariant = 'bottom',
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  const displayTitle =
    (displayMode === 'pdf' || displayMode === 'markscheme') && pdfTitle
      ? pdfTitle
      : title ??
        translate.instructions.dragCountersToRepresentNum(number.toNumber().toLocaleString());

  const items = getDraggableItems(counterVariant, columnsToShow);

  const draggableSource = (
    <DragAndDropSection style={{ padding: 0 }}>
      {items.map((_item, index) => (
        <EasyDragAndDrop.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  const defaultState = initialState
    ? numberToColState(ScientificNotation.fromNumber(initialState), columnsToShow, counterVariant)
    : filledArray([], columnsToShow.length);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <EasyDragAndDrop.ProviderWithState
        id="draganddrop"
        items={items}
        moveOrCopy="copy"
        hideBackground
        draggableStyle={{
          borderWidth: 0,
          shadowOpacity: 0,
          backgroundColor: 'transparent',
          alignItems: 'flex-start'
        }}
      >
        <BaseLayoutPDF
          title={displayTitle}
          mainPanelContents={
            <MeasureView>
              {dimens => (
                <PlaceValueChart
                  number={
                    displayMode === 'markscheme'
                      ? number
                      : initialState
                      ? ScientificNotation.fromNumber(initialState)
                      : undefined
                  }
                  columnsToShow={columnsToShow}
                  counterVariant={counterVariant}
                  headerVariant={headerVariant}
                  dimens={dimens}
                />
              )}
            </MeasureView>
          }
          questionHeight={questionHeight}
        />
      </EasyDragAndDrop.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy="copy"
      hideBackground
      defaultState={defaultState}
      // Complete if at least one zone has at least one item.
      testComplete={answer => Object.values(answer).some(array => array.length > 0)}
      testCorrect={answer => {
        const noRogueCounters =
          // grey can go anywhere so they are never rogue
          counterVariant === 'greyCounter'
            ? true
            : // else the counter should map directly to the same index in the columnsToShow
              answer.every((array, i) => array.every(counter => counter === columnsToShow[i]));
        let countersTotal = 0;
        answer.forEach((array, idx) => {
          countersTotal += array.length * Math.pow(10, columnsToShow[idx]);
        });
        const numbersEqual = equal(countersTotal, number.toNumber()) as boolean;
        return noRogueCounters && numbersEqual;
      }}
      draggableStyle={{
        borderWidth: 0,
        shadowOpacity: 0,
        backgroundColor: 'transparent',
        alignItems: 'flex-start'
      }}
    >
      <BaseLayout
        title={displayTitle}
        actionPanelVariant={counterVariant === 'greyCounter' ? 'end' : actionPanelVariant}
        actionPanelContents={draggableSource}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <PlaceValueChart
                columnsToShow={columnsToShow}
                counterVariant={counterVariant}
                headerVariant={headerVariant}
                dimens={dimens}
                interactive
              />
            )}
          </MeasureView>
        }
        {...props}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}
