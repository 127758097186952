import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import {
  findExchanges,
  numbersDoNotExchange,
  numbersDoNotExchangeAt,
  numbersExchangeAt
} from '../../../../utils/exchanges';
import ColumnOperations from '../../../../components/question/representations/ColumnOperations/ColumnOperations';
import QF27MissingDigitColumnOperations, {
  getMarkSchemeAnswer,
  getMissingDigits
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import { SUB } from '../../../../constants';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { useMemo } from 'react';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import deepEqual from 'react-fast-compare';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aio',
  description: 'aio',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      minuend: z.number().int().min(2000).max(9999),
      subtrahend: z.number().int().min(1000).max(8999)
    })
    .refine(val => val.minuend > val.subtrahend, 'minuend must be more than subtrahend.'),
  simpleGenerator: () => {
    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(2000, 9999);

        const subtrahend = randomIntegerInclusive(1000, minuend - 1000);

        return { minuend, subtrahend };
      },
      // Only permit them if they have more than one exchange, not at the tens, and the difference between the two numbers is at least 1,000
      ({ minuend, subtrahend }) =>
        numbersDoNotExchangeAt(minuend, -subtrahend, 'tens') &&
        findExchanges(minuend, -subtrahend).length === 2 &&
        minuend - subtrahend >= 1000
    );

    return {
      minuend,
      subtrahend
    };
  },
  Component: ({ question: { minuend, subtrahend }, translate }) => {
    const difference = minuend - subtrahend;

    const answerMissingDigits = range(0, difference.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={minuend}
        bottomNumber={subtrahend}
        operation={SUB}
        answerNumber={difference}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(difference, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aip',
  description: 'aip',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      minuend: z.number().int().min(2000).max(9999),
      subtrahend: z.number().int().min(1000).max(8999)
    })
    .refine(val => val.minuend > val.subtrahend, 'minuend must be more than subtrahend.'),
  simpleGenerator: () => {
    const { minuend, subtrahend } = rejectionSample(
      () => {
        const minuend = randomIntegerInclusive(2000, 9999);

        const subtrahend = randomIntegerInclusive(1000, minuend - 1000);

        return { minuend, subtrahend };
      },
      // Only permit them if they have more than one exchange, one at the tens, and the difference between the two numbers is at least 1,000
      ({ minuend, subtrahend }) =>
        numbersExchangeAt(minuend, -subtrahend, 'tens') &&
        findExchanges(minuend, -subtrahend).length > 1 &&
        minuend - subtrahend >= 1000
    );

    return {
      minuend,
      subtrahend
    };
  },
  Component: ({ question: { minuend, subtrahend }, translate }) => {
    const difference = minuend - subtrahend;

    const answerMissingDigits = range(0, difference.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={minuend}
        bottomNumber={subtrahend}
        operation={SUB}
        answerNumber={difference}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(difference, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aiq',
  description: 'aiq',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      numberA1: z.number().int().min(1000).max(8999),
      numberA2: z.number().int().min(1000).max(8999),
      numberB1: z.number().int().min(1000).max(8999),
      numberB2: z.number().int().min(1000).max(8999),
      numberC1: z.number().int().min(1000).max(8999),
      numberC2: z.number().int().min(1000).max(8999),
      numberD1: z.number().int().min(1000).max(8999),
      numberD2: z.number().int().min(1000).max(8999)
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 and numberA2 must not exchange.'
    )
    .refine(
      val =>
        findExchanges(val.numberB1, val.numberB2).length === 1 &&
        val.numberB1 + val.numberB2 < 10000,
      'numberB1 + numberB2 must have one exchange and total less than 10,000'
    )
    .refine(
      val =>
        findExchanges(val.numberC1, val.numberC2).length === 2 &&
        val.numberC1 + val.numberC2 < 10000,
      'numberC1 + numberC2 must have two exchanges and total less than 10,000'
    )
    .refine(
      val =>
        findExchanges(val.numberD1, val.numberD2).length === 3 &&
        val.numberD1 + val.numberD2 < 10000,
      'numberD1 + numberD2 must have three exchanges and total less than 10,000'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberA1 = randomIntegerInclusive(1000, 8999);
        const numberA2 = randomIntegerInclusive(1000, 9999 - numberA1);
        return { numberA1, numberA2 };
      },
      // Only permit them if they have no exchanges.
      ({ numberA1, numberA2 }) => numbersDoNotExchange(numberA1, numberA2)
    );

    const { numberB1, numberB2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberB1 = randomIntegerInclusive(1000, 8999);
        const numberB2 = randomIntegerInclusive(1000, 9999 - numberB1);
        return { numberB1, numberB2 };
      },
      // Only permit them if they have exactly one exchange.
      ({ numberB1, numberB2 }) => findExchanges(numberB1, numberB2).length === 1
    );

    const { numberC1, numberC2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberC1 = randomIntegerInclusive(1000, 8999);
        const numberC2 = randomIntegerInclusive(1000, 9999 - numberC1);
        return { numberC1, numberC2 };
      },
      // Only permit them if they have exactly two exchanges.
      ({ numberC1, numberC2 }) => findExchanges(numberC1, numberC2).length === 2
    );

    const { numberD1, numberD2 } = rejectionSample(
      () => {
        // Generate 2 random integers that sum to less than 10,000.
        const numberD1 = randomIntegerInclusive(1000, 8999);
        const numberD2 = randomIntegerInclusive(1000, 9999 - numberD1);
        return { numberD1, numberD2 };
      },
      // Only permit them if they have exactly three exchanges.
      ({ numberD1, numberD2 }) => findExchanges(numberD1, numberD2).length === 3
    );

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = { topNumber: numberA1 + numberA2, bottomNumber: numberA2, isCorrect: false };
      const eqB = { topNumber: numberB1 + numberB2, bottomNumber: numberB2, isCorrect: false };
      const eqC = { topNumber: numberC1 + numberC2, bottomNumber: numberC2, isCorrect: true };
      const eqD = { topNumber: numberD1 + numberD2, bottomNumber: numberD2, isCorrect: true };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      numberA1,
      numberA2,
      numberB1,
      numberB2,
      numberC1,
      numberC2,
      numberD1,
      numberD2,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheSubtractionsThatWillNeedMoreThanOneExchange()}
        pdfTitle={translate.instructions.circleTheSubtractionsThatWillNeedMoreThanOneExchange()}
        testCorrect={eqs.filter(eq => eq.isCorrect)}
        numItems={4}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation,
            component: (
              <ColumnOperations
                topNumber={equation.topNumber}
                bottomNumber={equation.bottomNumber}
                operation={SUB}
                dimens={{ width: dimens.width, height: dimens.height - 30 }}
                removeExtraCells
              />
            )
          }));
        }}
        multiSelect
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'air',
  description: 'air',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      minuend: z.number().int().min(3000).max(9000).multipleOf(1000),
      subtrahend: z
        .number()
        .int()
        .min(1001)
        .max(7999)
        .refine(val => val % 10 !== 0, 'subtrahend must not be a multiple of 10')
    })
    .refine(val => val.minuend > val.subtrahend, 'minuend must be more than subtrahend.'),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusiveStep(3000, 9000, 1000);

    const subtrahend = randomIntegerInclusive(1001, minuend - 1000, {
      // Subtrahend cannot be a multiple of 10, and the difference must be at least 1,000:
      constraint: x => x % 10 !== 0 && minuend - x >= 1000
    });

    return {
      minuend,
      subtrahend
    };
  },
  Component: ({ question: { minuend, subtrahend }, translate }) => {
    const difference = minuend - subtrahend;

    const answerMissingDigits = range(0, difference.toString().length - 1);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeColumnSubtraction()}
        topNumber={minuend}
        bottomNumber={subtrahend}
        operation={SUB}
        answerNumber={difference}
        answerMissingDigits={answerMissingDigits}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(difference, answerMissingDigits.length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'ais',
  description: 'ais',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z.object({
    subtractions: z
      .array(
        z
          .object({
            minuend: z.number().int().min(1).max(9999),
            subtrahend: z.number().int().min(1).max(9999)
          })
          .refine(
            ({ minuend, subtrahend }) => 0 < minuend - subtrahend && minuend - subtrahend <= 9999,
            'Result must be > 0 and <= 9,999'
          )
      )
      .refine(
        sums =>
          sums.some(({ minuend, subtrahend }) => findExchanges(minuend, -subtrahend).length > 1),
        'There must be at least one calculation which exchanges.'
      )
      .refine(calcs => arrayHasNoDuplicates(calcs, deepEqual), 'No duplicate calculations allowed')
  }),
  simpleGenerator: () => {
    return rejectionSample(
      () => {
        // 6 options:
        // (Uses terminology: minuend - subtrahend = difference, where var1=difference, var2=subtrahend, var3=minuend)
        // All variables between 1000 and 9999
        // - A) Minuend is multiple of 1000
        // - B) Minuend is one less than minuend of (A), same difference as (A)
        // - C) Minuend is multiple of 100, but not 1000
        // - D) Minuend is one less than minuend of (C), same difference as (C)
        // - E) Subtrahend is multiple of 1000
        // - F) Minuend is one less than minuend of (E), same difference as (E)
        const [am, as] = rejectionSample(
          () => [randomIntegerInclusiveStep(1000, 9000, 1000), randomIntegerInclusive(1000, 9999)],
          ([m, s]) => m - s >= 1000 && m - 1 >= 1000 && s - 1 >= 1000
        );

        const [bm, bs] = [am - 1, as - 1];

        const [cm, cs] = rejectionSample(
          () => [
            randomIntegerInclusiveStep(1000, 9900, 100, { constraint: x => x % 1000 !== 0 }),
            randomIntegerInclusive(1000, 9999)
          ],
          ([m, s]) => m - s >= 1000 && m - 1 >= 1000 && s - 1 >= 1000
        );

        const [dm, ds] = [cm - 1, cs - 1];

        const [em, es] = rejectionSample(
          () => [randomIntegerInclusive(1000, 9999), randomIntegerInclusiveStep(1000, 9000, 1000)],
          ([m, s]) => m - s >= 1000 && m - 1 >= 1000 && s - 1 >= 1000
        );

        const [fm, fs] = [em - 1, es - 1];

        const subtractions = shuffle(
          [
            [am, as],
            [bm, bs],
            [cm, cs],
            [dm, ds],
            [em, es],
            [fm, fs]
          ].map(([m, s]) => ({ minuend: m, subtrahend: s }))
        );

        return { subtractions };
      },
      ({ subtractions }) =>
        arrayHasNoDuplicates(subtractions, deepEqual) &&
        subtractions.some(
          ({ minuend, subtrahend }) => findExchanges(minuend, -subtrahend).length > 1
        )
    );
  },
  Component: ({ question: { subtractions }, translate }) => {
    const calculations = subtractions.map(({ minuend, subtrahend }, i) => ({
      value: i,
      text: `${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()}`,
      exchange: findExchanges(minuend, -subtrahend).length > 1
    }));

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheSubtractionsThatWillNeedMoreThanOneExchange()}
        pdfTitle={translate.instructions.circleTheSubtractionsThatWillNeedMoreThanOneExchange()}
        testCorrect={calculations.filter(it => it.exchange).map(it => it.value)}
        items={calculations.map(({ value, text }) => ({
          value,
          component: text
        }))}
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'ait',
  description: 'ait',
  keywords: ['Subtraction', 'Column', 'Exchange'],
  schema: z
    .object({
      answerNumber: z.number().int().min(1000).max(8999),
      bottomNumber: z.number().int().min(1000).max(8999),
      missingOnes: z.enum(['top', 'bottom', 'answer']),
      missingTens: z.enum(['top', 'bottom', 'answer']),
      missingHundreds: z.enum(['top', 'bottom', 'answer']),
      missingThousands: z.enum(['top', 'bottom', 'answer'])
    })
    .refine(
      val => findExchanges(val.answerNumber, val.bottomNumber).length > 1,
      'answerNumber and bottomNumber must have more than one exchange.'
    )
    .refine(
      val => val.answerNumber + val.bottomNumber < 10000,
      'answerNumber + bottomNumber must be less than 10,000'
    ),
  simpleGenerator: () => {
    const { answerNumber, bottomNumber } = rejectionSample(
      () => {
        // Generate 2 random integers, keeping number1 uniformly distributed
        const answerNumber = randomIntegerInclusive(1000, 8999);
        const bottomNumber = randomIntegerInclusive(1000, 9999 - answerNumber);
        return { answerNumber, bottomNumber };
      },
      // Only permit them if they have more than 1 exchange.
      ({ answerNumber, bottomNumber }) => findExchanges(answerNumber, bottomNumber).length > 1
    );

    const numberWithExtraMissingDigit = getRandomFromArray(['top', 'bottom', 'answer'] as const);

    const [missingOnes, missingTens, missingHundreds, missingThousands] = shuffle([
      'top',
      'bottom',
      'answer',
      numberWithExtraMissingDigit
    ] as const);

    return {
      answerNumber,
      bottomNumber,
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    };
  },

  Component: props => {
    const {
      question: {
        answerNumber,
        bottomNumber,
        missingOnes,
        missingTens,
        missingHundreds,
        missingThousands
      },
      translate
    } = props;
    const { topMissingDigits, bottomMissingDigits, answerMissingDigits } = getMissingDigits(
      missingOnes,
      missingTens,
      missingHundreds,
      missingThousands
    );
    const topNumber = answerNumber + bottomNumber;

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.workOutTheMissingDigits()}
        topNumber={topNumber}
        topMissingDigits={topMissingDigits}
        bottomNumber={bottomNumber}
        bottomMissingDigits={bottomMissingDigits}
        answerNumber={answerNumber}
        answerMissingDigits={answerMissingDigits}
        operation={SUB}
        customMarkSchemeAnswer={{
          answerToDisplay: {
            top: getMarkSchemeAnswer(topNumber, topNumber.toString().length),
            bottom: getMarkSchemeAnswer(bottomNumber, bottomNumber.toString().length),
            answer: getMarkSchemeAnswer(answerNumber, answerNumber.toString().length)
          },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwo4DigitNumbersMoreThanOneExchange',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
