import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import ItemsAgainstRuler from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { LineGraphColors } from '../../../../theme/colors';
import { all, create, number } from 'mathjs';
import { numberEnum } from '../../../../utils/zod';
import { compareFloats } from '../../../../utils/math';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { arraysHaveSameContents, countRange, sortNumberArray } from '../../../../utils/collections';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aDc',
  description: 'aDc',
  keywords: [
    'Fraction',
    'Tenths',
    'Bar model',
    'Measure',
    'cm',
    'Centimetres',
    'mm',
    'Millimetres'
  ],
  schema: z.object({
    length: z.number().min(1).max(8)
  }),
  simpleGenerator: () => ({
    length: number(math.evaluate(`${randomIntegerInclusive(10, 80)} / 10`))
  }),
  Component: props => {
    const {
      question: { length },
      translate
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const acceptableAnswers = [
      [length, number(math.evaluate(`${length} * 10`))],
      [number(math.evaluate(`${length} - 0.1`)), number(math.evaluate(`(${length} - 0.1) * 10`))],
      [number(math.evaluate(`${length} + 0.1`)), number(math.evaluate(`(${length} + 0.1) * 10`))]
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.howLongIsTheLineGiveAnswerInCmAndMm()}
        sentences={[`${translate.answerSentences.ansCm()}`, `${translate.answerSentences.ansMm()}`]}
        inputMaxCharacters={4}
        pdfDirection="column"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={8}
            items={[{ length, lineColor: color }]}
          />
        )}
        style={{ flexDirection: 'row', alignSelf: 'flex-end', columnGap: 130 }}
        testCorrect={userAnswer =>
          acceptableAnswers.some(answer =>
            arraysHaveSameContents(
              [userAnswer[0][0], userAnswer[1][0]],
              answer.map(ans => ans.toString())
            )
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [length.toLocaleString()],
            [number(math.evaluate(`${length} * 10`)).toLocaleString()]
          ],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aDd',
  description: 'aDd',
  keywords: [
    'Fraction',
    'Tenths',
    'Bar model',
    'Measure',
    'cm',
    'Centimetres',
    'mm',
    'Millimetres'
  ],
  schema: z.object({
    length: z.number().min(1).max(6),
    startingPosition: numberEnum([1, 2])
  }),
  simpleGenerator: () => ({
    length: number(math.evaluate(`${randomIntegerInclusive(10, 60)} / 10`)),
    startingPosition: getRandomFromArray([1, 2] as const)
  }),
  Component: props => {
    const {
      question: { length, startingPosition },
      translate
    } = props;

    const color = getRandomFromArray(LineGraphColors, {
      random: seededRandom(props.question)
    }) as string;

    const acceptableAnswers = [
      [length, number(math.evaluate(`${length} * 10`))],
      [number(math.evaluate(`${length} - 0.1`)), number(math.evaluate(`(${length} - 0.1) * 10`))],
      [number(math.evaluate(`${length} + 0.1`)), number(math.evaluate(`(${length} + 0.1) * 10`))]
    ];

    return (
      <QF1ContentAndSentences
        title={translate.instructions.howLongIsTheLineGiveAnswerInCmAndMm()}
        sentences={[`${translate.answerSentences.ansCm()}`, `${translate.answerSentences.ansMm()}`]}
        inputMaxCharacters={4}
        pdfDirection="column"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <ItemsAgainstRuler
            width={dimens.width}
            height={dimens.height}
            rulerKind="cm"
            rulerLength={8}
            items={[{ length, lineColor: color, start: startingPosition }]}
          />
        )}
        style={{ flexDirection: 'row', alignSelf: 'flex-end', columnGap: 130 }}
        testCorrect={userAnswer =>
          acceptableAnswers.some(answer =>
            arraysHaveSameContents(
              [userAnswer[0][0], userAnswer[1][0]],
              answer.map(ans => ans.toString())
            )
          )
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [length.toLocaleString()],
            [number(math.evaluate(`${length} * 10`)).toLocaleString()]
          ],
          answerText: translate.markScheme.allowXMmMarginOfError(1)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aDe',
  description: 'aDe',
  keywords: ['Converting units', 'cm', 'Centimetres', 'mm', 'Millimetres'],
  schema: z
    .object({
      centimetres: z.number().min(1).max(9),
      millimetres: z.number().int().min(10).max(300).step(10)
    })
    .refine(
      val => val.millimetres !== val.centimetres * 10,
      'millimetres cannot equal centimetres * 10'
    ),
  questionHeight: 500,
  simpleGenerator: () => {
    const centimetres = randomIntegerInclusive(1, 9);
    const millimetres = randomIntegerInclusiveStep(10, 300, 10, {
      constraint: x => x !== centimetres * 10
    });

    return { centimetres, millimetres };
  },
  Component: props => {
    const {
      question: { centimetres, millimetres },
      translate
    } = props;
    const sentences = shuffle(
      [
        {
          sentence: translate.answerSentences.numCmEqualsAnsMm(centimetres),
          answer: number(math.evaluate(`${centimetres} * 10`))
        },
        {
          sentence: translate.answerSentences.numMmEqualsAnsCm(millimetres),
          answer: number(math.evaluate(`${millimetres} / 10`))
        }
      ],
      { random: seededRandom(props.question) }
    );
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={userAnswer =>
          userAnswer.every((ans, i) => compareFloats(ans[0], sentences[i].answer.toString()))
        }
        inputMaxCharacters={5}
        questionHeight={500}
        sentenceStyle={{ alignItems: 'center', alignSelf: 'flex-end' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        sentences={sentences.map(sentence => sentence.sentence)}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [sentences[0].answer.toLocaleString()],
            [sentences[1].answer.toLocaleString()]
          ]
        }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aDe2',
  description: 'aDe',
  keywords: ['Converting units', 'cm', 'Centimetres', 'mm', 'Millimetres'],
  schema: z.discriminatedUnion('isCentimetres', [
    z.object({
      isCentimetres: z.literal(true),
      numberA: z.number().int().min(1).max(9)
    }),
    z.object({
      isCentimetres: z.literal(false),
      numberA: z.number().int().min(10).max(300).step(10)
    })
  ]),
  simpleGenerator: () => {
    const isCentimetres = getRandomBoolean();
    const numberA = isCentimetres
      ? randomIntegerInclusive(1, 9)
      : randomIntegerInclusiveStep(10, 300, 10);
    return { isCentimetres, numberA };
  },
  Component: props => {
    const {
      question: { isCentimetres, numberA },
      translate
    } = props;
    const { sentence, answer } = isCentimetres
      ? {
          sentence: translate.answerSentences.numCmEqualsAnsMm(numberA),
          answer: number(math.evaluate(`${numberA} * 10`))
        }
      : {
          sentence: translate.answerSentences.numMmEqualsAnsCm(numberA),
          answer: number(math.evaluate(`${numberA} / 10`))
        };
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeConversion()}
        testCorrect={[answer.toString()]}
        inputMaxCharacters={5}
        sentence={sentence}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aDf',
  description: 'aDf',
  keywords: ['Converting units', 'cm', 'Centimetres', 'mm', 'Millimetres'],
  schema: z.object({
    millimetresA: z.number().int().min(1).max(100),
    millimetresB: z.number().int().min(500).max(10000).step(500),
    centimetres: z.number().int().min(10).max(1200).step(10),
    isAnsBoxLHSA: z.boolean(),
    isAnsBoxLHSB: z.boolean(),
    isAnsBoxLHSC: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const millimetresA = randomIntegerInclusive(1, 100);
    const millimetresB = randomIntegerInclusiveStep(500, 10000, 500);
    const centimetres = randomIntegerInclusiveStep(10, 1200, 10);

    const [isAnsBoxLHSA, isAnsBoxLHSB, isAnsBoxLHSC] = countRange(3).map(_ => getRandomBoolean());

    return { millimetresA, millimetresB, centimetres, isAnsBoxLHSA, isAnsBoxLHSB, isAnsBoxLHSC };
  },
  Component: props => {
    const {
      question: {
        millimetresA,
        millimetresB,
        centimetres,
        isAnsBoxLHSA,
        isAnsBoxLHSB,
        isAnsBoxLHSC
      },
      translate
    } = props;

    const sentences = [
      {
        sentence: isAnsBoxLHSA
          ? `${translate.answerSentences.ansMm()} = ${translate.units.numberOfCm(
              number(math.evaluate(`${millimetresA} / 10`))
            )}`
          : `${translate.units.numberOfMm(millimetresA)} = ${translate.answerSentences.ansCm()}`,
        answer: isAnsBoxLHSA ? millimetresA : number(math.evaluate(`${millimetresA} / 10`))
      },
      {
        sentence: isAnsBoxLHSB
          ? `${translate.answerSentences.ansMm()} = ${translate.units.numberOfM(
              number(math.evaluate(`${millimetresB} / 1000`))
            )}`
          : `${translate.units.numberOfMm(millimetresB)} = ${translate.answerSentences.ansM()}`,
        answer: isAnsBoxLHSB ? millimetresB : number(math.evaluate(`${millimetresB} / 1000`))
      },
      {
        sentence: isAnsBoxLHSC
          ? `${translate.answerSentences.ansCm()} = ${translate.units.numberOfM(
              number(math.evaluate(`${centimetres} / 100`))
            )}`
          : `${translate.units.numberOfCm(centimetres)} = ${translate.answerSentences.ansM()}`,
        answer: isAnsBoxLHSC ? centimetres : number(math.evaluate(`${centimetres} / 100`))
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={userAnswer =>
          userAnswer.every((ans, i) => compareFloats(ans[0], sentences[i].answer.toString()))
        }
        questionHeight={1000}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        sentenceStyle={{ alignItems: 'center', alignSelf: 'flex-end' }}
        pdfContainerStyle={{ alignItems: 'center' }}
        sentences={sentences.map(sentence => sentence.sentence)}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [sentences[0].answer.toLocaleString()],
            [sentences[1].answer.toLocaleString()],
            [sentences[2].answer.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aDf2',
  description: 'aDf',
  keywords: ['Converting units', 'cm', 'Centimetres', 'mm', 'Millimetres'],
  schema: z.discriminatedUnion('sentenceId', [
    z.object({
      sentenceId: z.literal(0),
      numberA: z.number().int().min(1).max(100),
      isAnsBoxLHS: z.boolean()
    }),
    z.object({
      sentenceId: z.literal(1),
      numberA: z.number().int().min(500).max(10000).step(500),
      isAnsBoxLHS: z.boolean()
    }),
    z.object({
      sentenceId: z.literal(2),
      numberA: z.number().int().min(10).max(1200).step(10),
      isAnsBoxLHS: z.boolean()
    })
  ]),
  simpleGenerator: () => {
    const sentenceId = getRandomFromArray([0, 1, 2] as const);
    const numberA =
      sentenceId === 0
        ? randomIntegerInclusive(1, 100)
        : sentenceId === 1
        ? randomIntegerInclusiveStep(500, 10000, 500)
        : randomIntegerInclusiveStep(10, 1200, 10);
    const isAnsBoxLHS = getRandomBoolean();
    return { sentenceId, numberA, isAnsBoxLHS };
  },
  Component: props => {
    const {
      question: { sentenceId, numberA, isAnsBoxLHS },
      translate
    } = props;
    const { sentence, answer } =
      sentenceId === 0
        ? {
            sentence: isAnsBoxLHS
              ? `${translate.answerSentences.ansMm()} = ${translate.units.numberOfCm(
                  number(math.evaluate(`${numberA} / 10`))
                )}`
              : `${translate.units.numberOfMm(numberA)} = ${translate.answerSentences.ansCm()}`,
            answer: isAnsBoxLHS ? numberA : number(math.evaluate(`${numberA} / 10`))
          }
        : sentenceId === 1
        ? {
            sentence: isAnsBoxLHS
              ? `${translate.answerSentences.ansMm()} = ${translate.units.numberOfM(
                  number(math.evaluate(`${numberA} / 1000`))
                )}`
              : `${translate.units.numberOfMm(numberA)} = ${translate.answerSentences.ansM()}`,
            answer: isAnsBoxLHS ? numberA : number(math.evaluate(`${numberA} / 1000`))
          }
        : {
            sentence: isAnsBoxLHS
              ? `${translate.answerSentences.ansCm()} = ${translate.units.numberOfM(
                  number(math.evaluate(`${numberA} / 100`))
                )}`
              : `${translate.units.numberOfCm(numberA)} = ${translate.answerSentences.ansM()}`,
            answer: isAnsBoxLHS ? numberA : number(math.evaluate(`${numberA} / 100`))
          };
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeConversion()}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer.toString())}
        inputMaxCharacters={5}
        extraSymbol="decimalPoint"
        sentence={sentence}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aDg',
  description: 'aDg',
  keywords: [
    'Ordering',
    'Converting units',
    'mm',
    'Millimetres',
    'cm',
    'Centimetres',
    'm',
    'Metres'
  ],
  schema: z.object({
    metresA: z.number().min(0.1).max(9.9),
    centimetresA: z.number().int().min(10).max(1000).step(10),
    millimetresA: z.number().int().min(100).max(10000).step(100),
    duplicateOption: z.number().min(0.1).max(10000),
    duplicatedUnit: z.enum(['mm', 'cm', 'm']),
    isAscending: z.boolean()
  }),
  simpleGenerator: () => {
    const [millimetresA, millimetresB] = randomUniqueIntegersInclusive(100, 10000, 2, {
      constraint: x => x % 100 === 0
    });

    const centimetresA = randomIntegerInclusiveStep(10, 1000, 10, {
      constraint: x => x * 10 !== millimetresA && x * 10 !== millimetresB
    });

    const centimetresB = randomIntegerInclusive(1, 1000, {
      constraint: x => x !== centimetresA && x * 10 !== millimetresA && x * 10 !== millimetresB
    });

    const [metresA, metresB] = randomUniqueIntegersInclusive(10, 99, 2, {
      constraint: x =>
        x * 100 !== centimetresA * 10 &&
        x * 100 !== centimetresB * 10 &&
        x * 100 !== millimetresA &&
        x * 100 !== millimetresB
    }).map(num => num / 10);

    const duplicatedUnit = getRandomFromArray(['mm', 'cm', 'm'] as const);

    const duplicateOption = (() => {
      switch (duplicatedUnit) {
        case 'mm':
          return millimetresB;
        case 'cm':
          return centimetresB;
        default:
          return metresB;
      }
    })();

    const isAscending = getRandomBoolean();

    return {
      metresA,
      centimetresA,
      millimetresA,
      duplicateOption,
      duplicatedUnit,
      isAscending
    };
  },
  Component: props => {
    const {
      question: {
        metresA,
        centimetresA,
        millimetresA,
        duplicateOption,
        duplicatedUnit,
        isAscending
      },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: number(math.evaluate(`${metresA} * 1000`)),
          component: translate.units.numberOfM(metresA)
        },
        {
          value: number(math.evaluate(`${centimetresA} * 10`)),
          component: translate.units.numberOfCm(centimetresA)
        },
        {
          value: millimetresA,
          component: translate.units.numberOfMm(millimetresA)
        },
        {
          value:
            duplicatedUnit === 'cm'
              ? number(math.evaluate(`${duplicateOption} * 10`))
              : duplicatedUnit === 'm'
              ? number(math.evaluate(`${duplicateOption} * 1000`))
              : duplicateOption,
          component:
            duplicatedUnit === 'cm'
              ? translate.units.numberOfCm(duplicateOption)
              : duplicatedUnit === 'm'
              ? translate.units.numberOfM(duplicateOption)
              : translate.units.numberOfMm(duplicateOption)
        }
      ],
      { random: seededRandom(props.question) }
    );
    const headings = [translate.misc.Longest(), translate.misc.Shortest()];

    return (
      <QF4DragOrderVertical
        title={translate.instructions.dragTheCardsToOrderXFromYToZ(
          translate.misc.distances(),
          isAscending ? headings[1] : headings[0],
          isAscending ? headings[0] : headings[1]
        )}
        pdfTitle={translate.instructions.useCardsToOrderXFromYToZ(
          translate.misc.distances(),
          isAscending ? headings[1] : headings[0],
          isAscending ? headings[0] : headings[1]
        )}
        testCorrect={sortNumberArray(
          items.map(el => el.value),
          isAscending ? 'ascending' : 'descending'
        )}
        items={items.map(({ component, value }) => ({
          value,
          component
        }))}
        topLabel={isAscending ? headings[1] : headings[0]}
        bottomLabel={isAscending ? headings[0] : headings[1]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aDh',
  description: 'aDh',
  keywords: ['Converting units', 'cm', 'Centimetres', 'm', 'Metres'],
  schema: z.object({
    characterAHeight: z.number().int().min(120).max(180),
    HeightDifferenceInMetres: z.number().min(0.1).max(0.5).step(0.01),
    characterA: nameSchema,
    characterB: nameSchema,
    isShorter: z.boolean()
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const characterAHeight = randomIntegerInclusive(120, 180);
    const HeightDifferenceInMetres = number(
      math.evaluate(`${randomIntegerInclusive(10, 50)} / 100`)
    );
    const [characterA, characterB] = getRandomUniqueNames(2);
    const isShorter = getRandomBoolean();

    return {
      characterAHeight,
      HeightDifferenceInMetres,
      characterA,
      characterB,
      isShorter
    };
  },
  Component: ({
    question: { characterAHeight, HeightDifferenceInMetres, characterA, characterB, isShorter },
    translate
  }) => {
    const answer = isShorter
      ? number(math.evaluate(`(${characterAHeight} / 100) - ${HeightDifferenceInMetres}`))
      : number(math.evaluate(`(${characterAHeight} / 100) + ${HeightDifferenceInMetres}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterAIsXTallCharacterBIsYMShorterOrTallerThanCharAHowTallIsCharBGiveYourAnswerInMetres(
          characterA,
          characterAHeight,
          characterB,
          HeightDifferenceInMetres,
          isShorter ? translate.misc.Shorter() : translate.misc.Taller()
        )}
        inputMaxCharacters={4}
        questionHeight={500}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer.toString())}
        extraSymbol="decimalPoint"
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
        sentence={translate.answerSentences.ansM()}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertUnitsOfLength',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question3, Question4]
});
export default SmallStep;
