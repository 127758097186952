import { z } from 'zod';
import { View } from 'react-native';

import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { ADD, DIV, MULT, SUB } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF2DraggableAlignedEquations from '../../../../components/question/questionFormats/QF2DraggableAlignedEquations';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import { AlignedEquations } from '../../../../components/question/representations/AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aK8',
  description: 'aK8',
  keywords: ['Multiply factors', 'Factor pairs', 'Mental'],
  schema: z.object({
    number1: z.number().int().min(2).max(9),
    number3: z.number().int().min(2).max(9),
    number7: z.number().int().min(2).max(17)
  }),
  simpleGenerator: () => {
    const [number1, number3] = randomUniqueIntegersInclusive(2, 9, 2);

    const number7 = getRandomFromArray([number1 + number3, number3]);

    return { number1, number3, number7 };
  },
  Component: props => {
    const {
      question: { number1, number3, number7 },
      translate
    } = props;
    const number2 = number1 + 10;
    const number4 = number3 * 10;
    const number5 = number1 * number3;
    const number6 = number2 * number3;
    const number8 = number4 + number7;

    const answerOptions = shuffle([number5, number6, number7, number8], {
      random: seededRandom(props.question)
    });

    const sentences = [
      {
        sentence: `${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()} = ${(10).toLocaleString()} ${MULT} ${number3.toLocaleString()} ${ADD} ${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()}`,
        answer: []
      },
      {
        sentence: `${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()} = ${number4.toLocaleString()} + <ans/>`,
        answer: [number5]
      },
      {
        sentence: `${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()} = <ans/>`,
        answer: [number6]
      }
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.hereIsOneWayDragCardsToCompleteCalcs(
          `${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.hereIsOneWayUseCardsToCompleteCalcs(
          `${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()}`
        )}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => answer)}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        actionPanelVariant="end"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question1v2 = newQuestionContent({
  uid: 'aK82',
  description: 'aK82',
  keywords: ['Multiply factors', 'Factor pairs', 'Mental'],
  schema: z
    .object({
      multiplier: z.number().int().min(12).max(19),
      multiplicand: z.number().int().min(2).max(9),
      items: z
        .array(z.number().int().min(2).max(172))
        .length(5)
        .refine(arrayHasNoDuplicates, 'items must have no duplicates.')
    })
    .refine(
      val => val.multiplier - 10 !== val.multiplicand,
      'multiplier - 10 cannot equal multiplicand.'
    )
    .refine(
      val => val.items.includes((val.multiplier - 10) * val.multiplicand),
      'items must include the first correct answer: (multiplier - 10) x multiplicand'
    )
    .refine(
      val => val.items.includes((val.multiplier - 10) * val.multiplicand),
      'items must include the second correct answer: multiplier  x multiplicand'
    ),
  simpleGenerator: () => {
    const { multiplier, multiplicand, items } = rejectionSample(
      () => {
        const multiplier = randomIntegerInclusive(12, 19);

        const multiplicand = randomIntegerInclusive(2, 9, {
          constraint: x => x !== multiplier - 10
        });

        const correctAnswerA = (multiplier - 10) * multiplicand;
        const correctAnswerB = multiplier * multiplicand;
        const incorrectAnswerA = getRandomFromArray([multiplier - 10 + multiplicand, multiplicand]);
        const incorrectAnswerB = multiplicand * 10 + incorrectAnswerA;
        const incorrectAnswerC = randomIntegerInclusive(correctAnswerB - 10, correctAnswerB + 10, {
          constraint: x => x !== correctAnswerB
        });

        const items = shuffle([
          correctAnswerA,
          correctAnswerB,
          incorrectAnswerA,
          incorrectAnswerB,
          incorrectAnswerC
        ]);
        return { multiplier, multiplicand, items };
      },
      // All items should be unique, and should all be between 2 and 172:
      ({ items }) => arrayHasNoDuplicates(items) && items.every(item => item >= 2 && item <= 172)
    );

    return { multiplier, multiplicand, items };
  },
  Component: props => {
    const {
      question: { multiplier, multiplicand, items },
      translate
    } = props;
    const correctAnswerA = (multiplier - 10) * multiplicand;
    const correctAnswerB = multiplier * multiplicand;

    const answerOptions = items.map(value => ({ value, component: value.toLocaleString() }));

    const sentences = [
      {
        left: `${multiplier.toLocaleString()} ${MULT} ${multiplicand.toLocaleString()}`,
        right: `${(10).toLocaleString()} ${MULT} ${multiplicand.toLocaleString()} ${ADD} ${(
          multiplier - 10
        ).toLocaleString()} ${MULT} ${multiplicand.toLocaleString()}`
      },
      {
        right: `${(multiplicand * 10).toLocaleString()} ${ADD} <ans/>`
      },
      {
        right: `<ans/>`
      }
    ];

    return (
      <QF2DraggableAlignedEquations
        title={translate.instructions.hereIsOneWayDragCardsToCompleteCalcs(
          `${multiplier.toLocaleString()} ${MULT} ${multiplicand.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.hereIsOneWayUseCardsToCompleteCalcs(
          `${multiplier.toLocaleString()} ${MULT} ${multiplicand.toLocaleString()}`
        )}
        items={answerOptions}
        sentences={sentences}
        // Need to reduce vertical padding to fit three sentences without answer boxes touching.
        // paddingVertical does not work on mobile, so paddingTop and paddingBottom need to be set individually:
        mainPanelContainerStyle={{ paddingTop: 8, paddingBottom: 8 }}
        testCorrect={[
          {},
          {
            right: [correctAnswerA]
          },
          { right: [correctAnswerB] }
        ]}
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

const Question2 = newQuestionContent({
  uid: 'aK9',
  description: 'aK9',
  keywords: ['Multiply factors', 'Factor pairs', 'Mental'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(6),
      number2: z.number().int().min(2).max(6),
      number4: z.number().int().min(3).max(9),
      number7: z.number().int().min(3).max(54)
    })
    .refine(
      val => val.number1 * val.number2 >= 12 && val.number1 * val.number2 <= 25,
      'number1 * number2 must be greater than 14 and less than 26'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const [number1, number2] = randomUniqueIntegersInclusive(2, 6, 2);
        return { number1, number2 };
      },
      ({ number1, number2 }) => number1 * number2 >= 12 && number1 * number2 <= 25
    );

    const number4 = randomIntegerInclusive(3, 9, {
      constraint: x => x !== number1 && x !== number2
    });
    const number7 = getRandomFromArray([number1 * number4, number4, number1 * number2]);

    return { number1, number2, number4, number7 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number7 },
      translate
    } = props;
    const number3 = number1 * number2;
    const number5 = number2 * number4;
    const number6 = number3 * number4;
    const number8 = number1 + number7;

    const answerOptions = shuffle([number5, number6, number7, number8], {
      random: seededRandom(props.question)
    });

    const sentences = [
      {
        sentence: `${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()} = ${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${MULT} ${number4.toLocaleString()}`,
        answer: []
      },
      {
        sentence: `${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()} = ${number1.toLocaleString()} ${MULT} <ans/>`,
        answer: [number5]
      },
      {
        sentence: `${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()} = <ans/>`,
        answer: [number6]
      }
    ];
    return (
      <QF37SentencesDrag
        title={translate.instructions.hereIsOneWayDragCardsToCompleteCalcs(
          `${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.hereIsOneWayUseCardsToCompleteCalcs(
          `${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
        )}
        items={answerOptions}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => answer)}
        sentencesStyle={{ alignSelf: 'center' }}
        pdfSentencesStyle={{ alignSelf: 'center' }}
        pdfLayout="itemsRight"
        actionPanelVariant="end"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2v2 = newQuestionContent({
  uid: 'aK92',
  description: 'aK92',
  keywords: ['Multiply factors', 'Factor pairs', 'Mental'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(6),
      number2: z.number().int().min(3).max(6),
      number4: z.number().int().min(3).max(9),
      number7: z.number().int().min(3).max(54)
    })
    .refine(
      val => val.number1 * val.number2 > 12 && val.number1 * val.number2 < 26,
      'number1 * number2 must be greater than 12 and less than 26'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const [number1, number2] = randomUniqueIntegersInclusive(3, 6, 2);
        return { number1, number2 };
      },
      ({ number1, number2 }) => number1 * number2 > 12 && number1 * number2 < 26
    );

    const number4 = randomIntegerInclusive(3, 9, {
      constraint: x => x !== number1 && x !== number2
    });
    const number7 = getRandomFromArray([number1 * number4, number4, number1 * number2]);

    return { number1, number2, number4, number7 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number7 },
      translate
    } = props;
    const number3 = number1 * number2;
    const number5 = number2 * number4;
    const number6 = number3 * number4;
    const number8 = number1 + number7;

    const answerOptions = shuffle([number5, number6, number7, number8], {
      random: seededRandom(props.question)
    }).map(value => ({ value, component: value.toLocaleString() }));

    const sentences = [
      {
        left: `${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()}`,
        right: `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
      },
      {
        right: `${number1.toLocaleString()} ${MULT} <ans/>`
      },
      {
        right: `<ans/>`
      }
    ];

    return (
      <QF2DraggableAlignedEquations
        title={translate.instructions.hereIsOneWayDragCardsToCompleteCalcs(
          `${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.hereIsOneWayUseCardsToCompleteCalcs(
          `${number3.toLocaleString()} ${MULT} ${number4.toLocaleString()}`
        )}
        items={answerOptions}
        sentences={sentences}
        actionPanelVariant={'end'}
        testCorrect={[
          {},
          {
            right: [number5]
          },
          { right: [number6] }
        ]}
        questionHeight={1300}
      />
    );
  },
  questionHeight: 1300
});

const Question3 = newQuestionContent({
  uid: 'aLa',
  description: 'aLa',
  keywords: ['Multiply', 'Partition', 'Mental'],
  schema: z.object({
    number1: z.number().int().min(12).max(19),
    number2: numberEnum([5, 10]),
    number3: numberEnum([1, 2])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(12, 19);
    const number2 = getRandomFromArray([5, 10] as const);
    const number3 = getRandomFromArray([1, 2] as const);

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = number2 - number3;
    const number5 = number1 * number2;
    const number6 = number1 * number3;
    const number7 = number1 * number4;
    const sentences = [
      {
        sentence: `${number1} ${MULT} ${number4} = ${number1} ${MULT} ${number2} ${SUB} ${number1} ${MULT} ${number3}`,
        answer: []
      },
      {
        sentence: `${number1} ${MULT} ${number4} = <ans/> ${SUB} <ans/>`,
        answer: [number5.toString(), number6.toString()]
      },
      { sentence: `${number1} ${MULT} ${number4} = <ans/>`, answer: [number7.toString()] }
    ];

    return (
      <QF2AnswerBoxManySentences
        sentences={sentences.map(({ sentence }) => sentence)}
        title={translate.instructions.hereIsOneWayCompleteCalcs(`${number1} ${MULT} ${number4}`)}
        testCorrect={sentences.map(({ answer }) => answer)}
        containerStyle={{ alignSelf: 'center', alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignSelf: 'center', alignItems: 'flex-start' }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aLa2',
  description: 'aLa2',
  keywords: ['Multiply', 'Partition', 'Mental'],
  schema: z.object({
    number1: z.number().int().min(12).max(19),
    number2: numberEnum([5, 10]),
    number3: numberEnum([1, 2])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(12, 19);
    const number2 = getRandomFromArray([5, 10] as const);
    const number3 = getRandomFromArray([1, 2] as const);

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const number4 = number2 - number3;
    const number5 = number1 * number2;
    const number6 = number1 * number3;
    const number7 = number1 * number4;

    return (
      <QF2AlignedEquations
        title={translate.instructions.hereIsOneWayCompleteCalcs(`${number1} ${MULT} ${number4}`)}
        leftSide={[`${number1.toLocaleString()} ${MULT} ${number4.toLocaleString()}`, '', '']}
        rightSide={[
          `${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} ${SUB} ${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()}`,
          `<ans/> ${SUB} <ans/>`,
          '<ans/>'
        ]}
        testCorrect={{
          right: [[], [number5.toString(), number6.toString()], [number7.toString()]]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aLb',
  description: 'aLb',
  keywords: ['Multiply', 'Related facts', 'Mental'],
  schema: z.object({
    number1: numberEnum([30, 50]),
    number3: z.number().int().min(6).max(9)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([30, 50] as const);
    const number3 = randomIntegerInclusive(6, 9);

    return { number1, number3 };
  },
  Component: props => {
    const {
      question: { number1, number3 },
      translate
    } = props;

    const number2 = number1 / 2;
    const number5 = number1 * number3;
    const number6 = number2 * number3;
    const sentences = [
      {
        sentence: `${number2} ${MULT} ${number3} = ${number1} ${MULT} ${number3} ${DIV} 2`,
        answer: []
      },
      {
        sentence: `${number2} ${MULT} ${number3} = <ans/> ${DIV} 2`,
        answer: [number5.toString()]
      },
      { sentence: `${number2} ${MULT} ${number3} = <ans/>`, answer: [number6.toString()] }
    ];

    return (
      <QF2AnswerBoxManySentences
        sentences={sentences.map(({ sentence }) => sentence)}
        title={translate.instructions.hereIsOneWayCompleteCalcs(`${number2} ${MULT} ${number3}`)}
        testCorrect={sentences.map(({ answer }) => answer)}
        containerStyle={{ alignSelf: 'center', alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignSelf: 'center', alignItems: 'flex-start' }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aLb2',
  description: 'aLb2',
  keywords: ['Multiply', 'Related facts', 'Mental'],
  schema: z.object({
    number1: numberEnum([30, 50]),
    number3: z.number().int().min(6).max(9)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([30, 50] as const);
    const number3 = randomIntegerInclusive(6, 9);

    return { number1, number3 };
  },
  Component: props => {
    const {
      question: { number1, number3 },
      translate
    } = props;

    const number2 = number1 / 2;
    const number5 = number1 * number3;
    const number6 = number2 * number3;

    return (
      <QF2AlignedEquations
        leftSide={[`${number2.toLocaleString()} ${MULT} ${number3.toLocaleString()}`, '', '']}
        rightSide={[
          `${number1.toLocaleString()} ${MULT} ${number3.toLocaleString()} ${DIV} ${(2).toLocaleString()}`,
          `<ans/> ${DIV} ${(2).toLocaleString()}`,
          '<ans/>'
        ]}
        title={translate.instructions.hereIsOneWayCompleteCalcs(`${number2} ${MULT} ${number3}`)}
        testCorrect={{
          right: [[], [number5.toString()], [number6.toString()]]
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aLc',
  description: 'aLc',
  keywords: ['Multiply', 'Related facts', 'Mental'],
  schema: z.object({
    number1: z.number().int().min(20).max(80).step(20),
    number2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(20, 80, 20);
    const number2 = randomIntegerInclusive(1, 9);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;
    const number4 = number3 * 10;
    const number5 = number3 * 5;
    const sentences = [
      { sentence: `${number3} ${MULT} 5 = ${number3} ${MULT} 10 ${DIV} 2`, answer: [] },
      {
        sentence: `${number3} ${MULT} 5 = <ans/> ${DIV} 2`,
        answer: [number4.toString()]
      },
      { sentence: `${number3} ${MULT} 5 = <ans/>`, answer: [number5.toString()] }
    ];

    return (
      <QF2AnswerBoxManySentences
        sentences={sentences.map(({ sentence }) => sentence)}
        title={translate.instructions.hereIsOneWayCompleteCalcs(`${number3} ${MULT} 5`)}
        testCorrect={sentences.map(({ answer }) => answer)}
        containerStyle={{ alignSelf: 'center', alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignSelf: 'center', alignItems: 'flex-start' }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aLc2',
  description: 'aLc2',
  keywords: ['Multiply', 'Related facts', 'Mental'],
  schema: z.object({
    number1: z.number().int().min(20).max(80).step(20),
    number2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(20, 80, 20);
    const number2 = randomIntegerInclusive(1, 9);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;
    const number4 = number3 * 10;
    const number5 = number3 * 5;

    return (
      <QF2AlignedEquations
        title={translate.instructions.hereIsOneWayCompleteCalcs(
          `${number3} ${MULT} ${(5).toLocaleString()}`
        )}
        leftSide={[`${number3} ${MULT} ${(5).toLocaleString()}`, '', '']}
        rightSide={[
          `${number3} ${MULT} ${(10).toLocaleString()} ${DIV} ${(2).toLocaleString()}`,
          `<ans/> ${DIV} ${(2).toLocaleString()}`,
          '<ans/>'
        ]}
        testCorrect={{
          right: [[], [number4.toString()], [number5.toString()]]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aLd',
  description: 'aLd',
  keywords: ['Multiply', 'Related facts', 'Mental'],
  schema: z.object({
    number1: z.number().int().min(2).max(19),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 19);
    const name = getRandomName();

    return { number1, name };
  },
  Component: props => {
    const {
      question: { number1, name },
      translate
    } = props;

    const number2 = number1 * 10;
    const number3 = number1 * 9;
    const number4 = number1 * 99;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.characterHasWorkedOutXUsingMethod(
          name,
          `${number1} ${MULT} ${(9).toLocaleString()}`
        )}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height * 0.75, width: dimens.width }}>
            <AlignedEquations
              userAnswer={{
                left: [],
                right: []
              }}
              leftSide={[`${number1} ${MULT} ${(9).toLocaleString()}`, '', '']}
              rightSide={[
                `${number1.toLocaleString()} ${MULT} ${(10).toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
                `${number2.toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
                number3.toLocaleString()
              ]}
            />
          </View>
        )}
        sentences={[
          translate.answerSentences.adaptCharactersMethodToWorkOut(name),
          `${number1} ${MULT} ${(99).toLocaleString()} = <ans/>`
        ]}
        pdfDirection="column"
        testCorrect={[[], [number4.toString()]]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EfficientMultiplication',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question1, Question2, Question3, Question4, Question5]
});
export default SmallStep;
