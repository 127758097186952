import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import ItemsAgainstRuler, {
  calculateScaling
} from '../../../../components/question/representations/Measurement/ItemsAgainstRuler';
import { sortNumberArray } from '../../../../utils/collections';
import {
  getRandomBoolean,
  rejectionSample,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  getRandomFromArray,
  seededRandom
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import QF42bDraggableShapeOnRuler from '../../../../components/question/questionFormats/QF42bDraggableShapeOnRuler';
import { TranslationFunctions } from '../../../../i18n/i18n-types';

////
// Questions
////

const objects = ['flower', 'tree', 'ribbons', 'pencil', 'train'] as const;
type Objects = (typeof objects)[number];

const objectToWord = (object: Objects, translate: TranslationFunctions) => {
  switch (object) {
    case 'flower':
      return translate.items.flowers(1);
    case 'pencil':
      return translate.items.pencils(1);
    case 'ribbons':
      return translate.items.ribbons(1);
    case 'train':
      return translate.items.toyTrain(1);
    case 'tree':
      return translate.items.tree(1);
  }
};

const Question1 = newQuestionContent({
  uid: 'bdv',
  description: 'bdv',
  keywords: [
    'Longer',
    'Shorter',
    'Taller',
    'Length',
    'Height',
    'Measure',
    'Compare',
    'Ruler',
    'Centimetres'
  ],
  schema: z.object({
    lengths: z.array(z.number().int().min(1).max(10)).length(2),
    isVertical: z.boolean(),
    object: z.enum(['flower', 'ribbons', 'pencil', 'train']),
    selectShorter: z.boolean()
  }),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const selectShorter = getRandomBoolean();
    const verticalObjects = ['flower'] as const;
    const horizontalObjects = ['ribbons', 'pencil', 'train'] as const;

    const { object, lengths } = rejectionSample(
      () => {
        const object = isVertical
          ? getRandomFromArray(verticalObjects)
          : getRandomFromArray(horizontalObjects);

        // Some svgs can't be seen well when they are too small/long
        const getMinMax = (obj: Objects): [number, number] =>
          ['ribbons', 'pencil'].includes(obj) ? [4, 10] : [1, 10];

        const minMax = getMinMax(object);

        const length1 = randomIntegerInclusive(minMax[0], minMax[1]);
        const length2 = randomIntegerInclusive(minMax[0], minMax[1], {
          constraint: x => length1 !== x
        });
        return { object, lengths: [length1, length2] };
      },
      ({ lengths, object }) => {
        const maxDifference = object === 'ribbons' ? 2 : 5;
        return Math.abs(lengths[0] - lengths[1]) < maxDifference;
      } // If the length difference is too large the smaller ones cant be seen
    );

    return { object, lengths, isVertical, selectShorter };
  },
  Component: props => {
    const {
      question: { object, lengths, isVertical, selectShorter },
      translate
    } = props;

    const random = seededRandom(props.question);
    const getSvgNames = (object: 'flower' | 'ribbons' | 'pencil' | 'train') => {
      switch (object) {
        case 'flower':
          return getRandomSubArrayFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            2,
            { random }
          );
        case 'ribbons':
          return getRandomSubArrayFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, 2, {
            random
          });
        case 'pencil':
          return getRandomSubArrayFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            2,
            { random }
          );
        case 'train':
          return ['Transport_toys/Train_carriage', 'Transport_toys/Train_carriage'] as const;
      }
    };

    const [answer] = sortNumberArray(lengths, selectShorter ? 'ascending' : 'descending');

    const [svg1, svg2] = getSvgNames(object);

    const maxLength = Math.max(...lengths);
    const rulerLength = maxLength > 7 ? 10 : maxLength + 2;

    const translatedObject = objectToWord(object, translate);

    return (
      <QF11SelectImagesUpTo4
        title={
          selectShorter
            ? translate.ks1Instructions.selectTheShorterXObject(translatedObject)
            : isVertical
            ? translate.ks1Instructions.selectTheTallerXObject(translatedObject)
            : translate.ks1Instructions.selectTheLongerXObject(translatedObject)
        }
        pdfTitle={
          selectShorter
            ? translate.ks1PDFInstructions.tickTheShorterXObject(translatedObject)
            : isVertical
            ? translate.ks1PDFInstructions.tickTheTallerXObject(translatedObject)
            : translate.ks1PDFInstructions.tickTheLongerXObject(translatedObject)
        }
        testCorrect={[answer]}
        numItems={2}
        itemStyle={{
          margin: isVertical ? 0 : 8,
          justifyContent: isVertical ? 'center' : 'flex-end',
          paddingBottom: isVertical ? 0 : 48
        }}
        renderItems={({ dimens }) => {
          const scaleFactor = Math.min(
            ...lengths.map((length, i) =>
              calculateScaling({
                width: dimens.width * 0.9,
                height: dimens.height * 0.9,
                vertical: isVertical,
                rulerKind: 'cm',
                rulerLength,
                items: [
                  {
                    length,
                    svgInfo: { name: i === 0 ? svg1 : svg2 },
                    guidelines: true
                  }
                ]
              })
            )
          );
          return lengths.map((length, i) => ({
            value: length,
            component: (
              <ItemsAgainstRuler
                width={dimens.width * 0.9}
                height={dimens.height * 0.9}
                vertical={isVertical}
                scaleFactor={scaleFactor}
                rulerKind="cm"
                hideMillimetres
                rulerLength={rulerLength}
                items={[
                  {
                    length,
                    svgInfo: { name: i === 0 ? svg1 : svg2 },
                    guidelines: true
                  }
                ]}
              />
            )
          }));
        }}
      />
    );
  }
});

const objects2 = ['flower', 'ribbons', 'pencil', 'train', 'elephant', 'giraffe', 'teddy'] as const;
type Objects2 = (typeof objects2)[number];

const Question2 = newQuestionContent({
  uid: 'bdw',
  description: 'bdw',
  keywords: [
    'Longer',
    'Shorter',
    'Taller',
    'Length',
    'Height',
    'Measure',
    'Compare',
    'Ruler',
    'Centimetres'
  ],
  questionHeight: 1000,
  schema: z
    .object({
      length: z.number().int().min(1).max(10),
      isVertical: z.boolean(),
      object: z.enum(objects2)
    })
    .refine(
      val => (val.isVertical ? val.length <= 7 : val.length <= 10),
      'vertical height must be less than 8'
    ),
  simpleGenerator: () => {
    const isVertical = getRandomBoolean();
    const verticalObjects = ['flower', 'elephant', 'giraffe', 'teddy'] as const;
    const horizontalObjects = ['ribbons', 'pencil', 'train'] as const;

    const object = isVertical
      ? getRandomFromArray(verticalObjects)
      : getRandomFromArray(horizontalObjects);

    // Some svgs can't be seen well when they are too small/long
    const getMinMax = (obj: Objects2): [number, number] =>
      ['ribbons', 'pencil'].includes(obj) ? [4, isVertical ? 7 : 10] : [1, isVertical ? 7 : 10];

    const length = randomIntegerInclusive(...getMinMax(object));

    return { object, length, isVertical };
  },
  Component: props => {
    const {
      question: { object, length, isVertical },
      translate
    } = props;

    const random = seededRandom(props.question);
    const getSvgNames = (object: Objects2) => {
      switch (object) {
        case 'flower':
          return getRandomSubArrayFromArray(
            [
              'Flowers/Flower_Red',
              'Flowers/Flower_Orange',
              'Flowers/Flower_Blue',
              'Flowers/Flower_Purple'
            ] as const,
            2,
            { random }
          );
        case 'ribbons':
          return getRandomSubArrayFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, 2, {
            random
          });
        case 'pencil':
          return getRandomSubArrayFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            2,
            { random }
          );
        case 'train':
          return ['Transport_toys/Train_carriage', 'Transport_toys/Train_carriage'] as const;
        case 'elephant':
          return ['Elephant'] as const;
        case 'giraffe':
          return ['Giraffe'] as const;
        case 'teddy':
          return getRandomSubArrayFromArray(['Teddy_bear_1', 'Teddy_bear_2'] as const, 2, {
            random
          });
      }
    };

    const answer = length;

    const svg = getSvgNames(object)[0];

    const rulerLength = isVertical ? 8 : 10;

    let objectTranslated;

    switch (object) {
      case 'flower':
        objectTranslated = translate.items.flowers(1);
        break;
      case 'pencil':
        objectTranslated = translate.items.pencils(1);
        break;
      case 'ribbons':
        objectTranslated = translate.items.ribbons(1);
        break;
      case 'train':
        objectTranslated = translate.items.toyTrain(1);
        break;
      case 'elephant':
        objectTranslated = translate.items.toyElephant(1);
        break;
      case 'giraffe':
        objectTranslated = translate.items.toyGiraffe(1);
        break;
      case 'teddy':
        objectTranslated = translate.items.teddy(1);
        break;
    }

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={translate.ks1AnswerSentences.theXObjectIsAnsCmYLength(
          objectTranslated,
          isVertical ? translate.misc.Tall() : translate.misc.Long()
        )}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => {
          return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <ItemsAgainstRuler
                width={dimens.width * 0.9}
                height={dimens.height * 0.9}
                vertical={isVertical}
                rulerKind="cm"
                hideMillimetres
                rulerLength={rulerLength}
                items={[
                  {
                    length,
                    svgInfo: { name: svg },
                    guidelines: true
                  }
                ]}
              />
            </View>
          );
        }}
        questionHeight={isVertical ? 1000 : undefined}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdx',
  description: 'bdx',
  keywords: [
    'Longer',
    'Shorter',
    'Taller',
    'Length',
    'Height',
    'Measure',
    'Compare',
    'Ruler',
    'Centimetres'
  ],
  questionHeight: 1000,
  schema: z
    .object({
      object: z.enum(['ribbons', 'pencil', 'train']),
      lengthOfCm: z.number().int().min(3).max(8),
      offsetX: z.number().int().min(0).max(12),
      offsetY: z.number().int().min(1).max(3)
    })
    .refine(
      val => val.lengthOfCm + val.offsetX <= 15,
      `offsetX + lengthOfCm must be less than or equal to 15`
    ),
  simpleGenerator: () => {
    const objects = ['ribbons', 'pencil', 'train'] as const;

    const object = getRandomFromArray(objects);

    const lengthOfCm = randomIntegerInclusive(3, 8);
    const offsetX = randomIntegerInclusive(0, 15 - lengthOfCm);
    const offsetY = randomIntegerInclusive(1, 3);

    return { object, offsetX, offsetY, lengthOfCm };
  },
  Component: props => {
    const {
      question: { object, offsetX, offsetY, lengthOfCm },
      translate
    } = props;

    const random = seededRandom(props.question);
    const getSvgNames = (object: 'ribbons' | 'pencil' | 'train') => {
      switch (object) {
        case 'ribbons':
          return getRandomSubArrayFromArray(['RibbonGreen', 'RibbonRedStriped'] as const, 2, {
            random
          });
        case 'pencil':
          return getRandomSubArrayFromArray(
            ['Pencils/Pencil_green', 'Pencils/Pencil_red', 'Pencils/Pencil_yellow'] as const,
            2,
            { random }
          );
        case 'train':
          return ['Transport_toys/Train_carriage', 'Transport_toys/Train_carriage'] as const;
      }
    };

    const svg = getSvgNames(object)[0];

    const rulerLength = 15;

    const objectTranslated = objectToWord(object, translate);

    return (
      <QF42bDraggableShapeOnRuler
        title={translate.ks1Instructions.measureTheLengthOfTheXObjectDragTheXObjectToHelpYou(
          objectTranslated
        )}
        pdfTitle={translate.ks1PDFInstructions.measureTheLengthOfTheXObjectUsingARuler(
          objectTranslated
        )}
        rulerKind={'cm'}
        rulerLength={rulerLength}
        sentence={translate.ks1AnswerSentences.theXObjectIsAnsCmYLength(
          objectTranslated,
          translate.misc.Long()
        )}
        shapeIconName={svg}
        length={lengthOfCm}
        offsetX={offsetX}
        offsetY={-offsetY}
        testCorrect={[lengthOfCm.toString()]}
        markSchemeAnswer={[lengthOfCm.toLocaleString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MeasureLengthInCentimetres',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
