import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { SUB } from '../../../../constants';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import {
  PartWholeModel,
  TextPartition
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { numberToBase10Object } from '../../../../utils/math';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { range } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcM',
  description: 'bcM',
  keywords: ['Subtract', 'Number bonds', 'Base 10'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const number2 = randomIntegerInclusive(1, number1);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = 10 + number1;

    const baseTen1 = numberToBase10Object(number1);
    const baseTen2 = numberToBase10Object(number3);

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSubtractions()}
        sentences={[
          `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`,
          `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`
        ]}
        testCorrect={[[(number1 - number2).toString()], [(number3 - number2).toString()]]}
        mainPanelStyle={{ flexDirection: 'row' }}
        style={{ justifyContent: 'space-evenly', alignItems: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'space-evenly', alignItems: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ justifyContent: 'space-evenly', height: dimens.height }}>
            <View style={{ height: dimens.height * 0.5, justifyContent: 'center' }}>
              <SimpleBaseTenWithCrossOut
                ones={baseTen1.ones}
                tens={baseTen1.tens}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.45 }}
                crossedOutIndices={{ ones: range(number1 - 1, number1 - number2), tens: [] }}
              />
            </View>
            <View style={{ height: dimens.height * 0.5, justifyContent: 'center' }}>
              <SimpleBaseTenWithCrossOut
                ones={baseTen2.ones}
                tens={baseTen2.tens}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.45 }}
                crossedOutIndices={{
                  ones: range((baseTen2.ones ?? 0) - 1, (baseTen2.ones ?? 0) - number2),
                  tens: []
                }}
              />
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bcN',
  description: 'bcN',
  keywords: ['Number bonds', 'Subtract'],
  schema: z
    .object({
      minuend: z.number().int().min(1).max(10),
      subtrahend: z.number().int().min(1).max(10)
    })
    .refine(
      val => val.minuend >= val.subtrahend,
      'minuend must be greater than or equal to subtrahend.'
    ),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(1, 10);

    const subtrahend = randomIntegerInclusive(1, minuend);

    return { minuend, subtrahend };
  },

  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate
    } = props;

    const differenceA = minuend - subtrahend;

    const differenceB = minuend + 10 - subtrahend;

    const sentences = [
      `${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()} = <ans/>`,
      `${(minuend + 10).toLocaleString()} ${SUB} ${subtrahend.toLocaleString()} = <ans/>`
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.ks1Instructions.completeTheSubtractions()}
        sentences={sentences}
        testCorrect={[[differenceA.toString()], [differenceB.toString()]]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcO',
  description: 'bcO',
  keywords: ['Part-whole model', 'Subtract', 'Number bonds'],
  questionHeight: 1200,
  schema: z
    .object({
      numberA: z.number().int().min(1).max(9),
      total: z.number().int().min(11).max(20),
      startWithNumberA: z.boolean(),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft'])
    })
    .refine(
      val => val.total - 10 >= val.numberA,
      'total - 10 must be greater than or equal to numberA.'
    )
    .refine(val => val.total - val.numberA >= 10, 'total - numberA must be at least 10'),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(1, 9);

    const total = randomIntegerInclusive(numberA + 10, 20, {
      constraint: x => x - numberA >= 10
    });

    const startWithNumberA = getRandomBoolean();

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { numberA, total, startWithNumberA, variation };
  },
  Component: ({ question, translate, displayMode }) => {
    const { numberA, total, startWithNumberA, variation } = question;

    const numberB = total - numberA;

    const partition: TextPartition = startWithNumberA ? ['$ans', numberA] : [numberA, '$ans'];

    return (
      <QF3InteractiveContent
        title={translate.ks1Instructions.completeThePartWholeModel()}
        initialState={displayMode === 'markscheme' ? [numberB.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={userAnswer => userAnswer[0] === numberB.toString()}
        inputType="numpad"
        questionHeight={1200}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={total}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            variation={variation}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractOnesUsingNumberBonds',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
