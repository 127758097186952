import { SvgName } from '../assets/svg';
import { Dimens } from '../theme/scaling';
import { getActualDimens } from './getActualDimens';
import { getRandomFromArray, seededRandom } from './random';

export type ShapeNames =
  | 'Compound_shape1_arrows'
  | 'Compound_shape2_arrows'
  | 'Compound_shape3_arrows'
  | 'Compound_shape4_arrows'
  | 'Compound_shape5_arrows'
  | 'Compound_shape6_arrows'
  | 'Compound_shape7_arrows'
  | 'cube1_arrow'
  | 'cube2_arrow'
  | 'cube3_arrow'
  | 'Cube_3_arrows_blue'
  | 'Cube_3_arrows_green'
  | 'Cube_3_arrows_red'
  | 'Cube_3_arrows_white'
  | 'Cube_3_arrows_yellow'
  | 'cuboid1_3_arrows'
  | 'cuboid2_3_arrows'
  | 'cuboid3_3_arrows'
  | 'cuboid4_3_arrows'
  | 'cuboid5_3_arrows'
  | 'cuboid5_3_arrows_small'
  | 'cuboid6_3_arrows'
  | 'irregular_triangle1'
  | 'irregular_triangle2'
  | 'irregular_triangle3'
  | 'isoceles_acute_triangle1'
  | 'isoceles_acute_triangle2'
  | 'isoceles_obtuse_triangle1'
  | 'Kite_two_isosceles_triangles1'
  | 'Kite_two_isosceles_triangles2'
  | 'Kite_two_isosceles_triangles3'
  | 'Kite_two_isosceles_triangles4'
  | 'Labelled_compound_shape1'
  | 'Labelled_compound_shape2'
  | 'Labelled_compound_shape3'
  | 'Labelled_compound_shape4'
  | 'Labelled_compound_shape5'
  | 'Labelled_compound_shape6'
  | 'quadrilateral_1'
  | 'quadrilateral_2'
  | 'quadrilateral_3'
  | 'quadrilateral_4'
  | 'Shapes_with_interior_angles/Quadrilateral5_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral6_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral7_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral1_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral2_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral3_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral4_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles'
  | 'Quadrilateral1_interior_angles'
  | 'Quadrilateral2_interior_angles'
  | 'Quadrilateral3_interior_angles'
  | 'Quadrilateral4_interior_angles'
  | 'Quadrilateral5_interior_angles'
  | 'Rhombus1_interior_angles'
  | 'Rhombus3_interior_angles'
  | 'Rectangle1_interior_lines_and_angles'
  | 'Rectangle2_interior_lines_and_angles'
  | 'Rectangle3_interior_lines_and_angles'
  | 'Rectangle4_interior_lines_and_angles'
  | 'scalene_triangle1'
  | 'scalene_triangle2'
  | 'scalene_triangle3'
  | 'scalene_triangle5'
  | 'Trapezium1_interior_angles'
  | 'Trapezium2_interior_angles'
  | 'Trapezium3_interior_angles'
  | 'Trapezium4_interior_angles'
  | 'Triangle1_line_extension_3_angles'
  | 'Rectilinear_shape1_all_arrows'
  | 'Rectilinear_shape2_all_arrows'
  | 'Rectilinear_shape3_all_arrows'
  | 'Rectilinear_shape4_all_arrows'
  | 'Rectilinear_shape5_all_arrows'
  | 'Rectilinear_shape6_all_arrows'
  | 'Rectilinear_shape7_all_arrows'
  | 'Rectilinear_shape8_all_arrows'
  | 'Rectilinear_shape9_all_arrows'
  | 'Rectilinear_shape10_all_arrows'
  | 'Rectilinear_shape11_all_arrows'
  | 'Rectilinear_shape12_all_arrows'
  | 'Rectilinear_shape13_all_arrows'
  | 'Rectilinear_shape14_all_arrows'
  | 'Rectilinear_shape15_all_arrows'
  | 'Rectilinear_shape16_all_arrows'
  | 'Rectilinear_shape17_all_arrows'
  | 'Rectilinear_shape18_all_arrows'
  | 'Rectilinear_shape19_all_arrows'
  | 'Rectilinear_shape20_all_arrows'
  | 'Rectilinear_shape21_all_arrows'
  | 'Rectilinear_shape22_all_arrows'
  | 'Rectilinear_shape23_all_arrows'
  | 'Rectilinear_shape24_all_arrows'
  | 'Rectilinear_shape25_all_arrows'
  | 'Rectilinear_shape26_all_arrows'
  | 'Rectilinear_shape27_all_arrows'
  | 'Rectilinear_shape28_all_arrows'
  | 'Rectilinear_shape29_all_arrows'
  | 'Rectilinear_shape30_all_arrows'
  | 'Rectilinear_shape31_all_arrows'
  | 'Rectilinear_shape32_all_arrows'
  | 'Rectilinear_shape33_all_arrows'
  | 'Rectilinear_shape34_all_arrows'
  | 'Rectilinear_shape35_all_arrows'
  | 'Rectilinear_shape36_all_arrows'
  | 'Rectilinear_shape37_all_arrows'
  | 'Rectilinear_shape38_all_arrows'
  | 'LShape_1'
  | 'LShape_2'
  | 'LShape_3'
  | 'LShape_4'
  | 'triangle_equal_1_arrow'
  | 'Square_1_arrow'
  | 'Pentagon_1_arrow'
  | 'Hexagon_1_arrow'
  | 'Heptagon_1_arrow'
  | 'Octagon_1_arrow'
  | 'Nonagon_1_arrow'
  | 'Decagon_1_arrow'
  | 'Shapes_with_arrows/Right_angle_triangle1_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle2_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle3_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle4_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle6_2_arrows'
  | 'Shapes_with_arrows/Triangle2_2_arrows'
  | 'Shapes_with_arrows/Triangle3_2_arrows'
  | 'Shapes_with_arrows/Triangle4_2_arrows'
  | 'Shapes_with_arrows/Triangle5_2_arrows'
  | 'Shapes_with_arrows/Triangle1_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle2_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle3_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle4_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle6_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle7_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle9_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle1_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle2_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle3_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle4_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle6_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle7_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle9_4_arrows_right_angle'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_medium_A'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_medium_B'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_medium_C'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_large_A'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_large_B'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_large_C'
  | 'Shapes_with_dimension_arrows/triangle_scalene_purple_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_blue_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_green_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_orange_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_red_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_purple_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_blue_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_green_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_orange_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_red_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA2_purple_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA2_blue_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA2_green_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA2_orange_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA2_red_arrows'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_yellow'
  | 'Shapes_with_arrows/Isosceles_triangle_with_arrows_equal'
  | 'Shapes_with_arrows/Isosceles_triangle_with_arrows_short'
  | 'Shapes_with_arrows/Isosceles_triangle_with_arrows_tall'
  | 'Shapes_with_dimension_arrows/pentagon_green_arrows'
  | 'Shapes_with_dimension_arrows/pentagon_orange_arrows'
  | 'Shapes_with_dimension_arrows/pentagon_blue_arrows'
  | 'Shapes_with_dimension_arrows/pentagon_purple_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_green_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_blue_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_orange_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_purple_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_red_arrows'
  | 'Scalene_triangle1'
  | 'Scalene_triangle2'
  | 'Scalene_triangle3'
  | 'Scalene_triangle4'
  | 'Scalene_triangle5'
  | 'Scalene_triangle6'
  | 'Scalene_triangle7'
  | 'Scalene_triangle8'
  | 'Isosceles_triangle1'
  | 'Isosceles_triangle2'
  | 'Scalene_Isosceles_joined_triangle1'
  | 'Scalene_Isosceles_joined_triangle2'
  | 'Scalene_Isosceles_joined_triangle3'
  | 'Scalene_Isosceles_joined_triangle4';

const getDimens = (
  svgName: SvgName,
  dimens: Dimens,
  shapeDimens: { shapeWidth: number; shapeHeight: number }
) => {
  const { width, height } = dimens;
  const { shapeWidth, shapeHeight } = shapeDimens;

  const { actualHeight, actualWidth } = getActualDimens(svgName, {
    width: shapeWidth,
    height: shapeHeight
  });

  const widthDiff = (width - actualWidth) * 0.5;
  const heightDiff = (height - actualHeight) * 0.5;

  return {
    actualWidth,
    actualHeight,
    widthDiff,
    heightDiff
  };
};

export const labelPositions = (
  shapeName: ShapeNames,
  dimens: Dimens,
  shapeDimens: { shapeWidth: number; shapeHeight: number },
  isPDF?: boolean,
  seed?: string | number | object,
  color?: 'pink' | 'blue' | 'green' | 'purple' | 'yellow'
) => {
  const seeded =
    seed !== undefined
      ? {
          random: seededRandom(seed)
        }
      : undefined;
  switch (shapeName) {
    case 'Compound_shape1_arrows': {
      const svgName = 'Shapes_with_arrows/Compound_shape1_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.35,
            right: widthDiff + actualWidth
          },
          // Top label
          {
            left: widthDiff + actualWidth * 0.4,
            bottom: heightDiff + actualHeight * 0.8
          },
          // Right label
          {
            top: heightDiff + actualHeight * 0.35,
            left: widthDiff + actualWidth
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.05
          }
        ]
      };
    }
    case 'Compound_shape2_arrows': {
      const svgName = 'Shapes_with_arrows/Compound_shape2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.35,
            right: widthDiff + actualWidth
          },
          // Top label
          {
            left: widthDiff + actualWidth * 0.4,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Right label
          {
            top: heightDiff + actualHeight * 0.15,
            left: widthDiff + actualWidth
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.1
          }
        ]
      };
    }
    case 'Compound_shape3_arrows': {
      const svgName = 'Shapes_with_arrows/Compound_shape3_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.35,
            right: widthDiff + actualWidth
          },
          // Top label
          {
            left: widthDiff + actualWidth * 0.4,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Right-Top label
          {
            top: heightDiff + actualHeight * 0.15,
            left: widthDiff + actualWidth
          },
          // Right-Top (Horizontal) label
          {
            top: heightDiff + actualHeight * 0.43,
            left: widthDiff + actualWidth * 0.65
          },
          // Right-Middle label
          {
            top: heightDiff + actualHeight * 0.53,
            left: widthDiff + actualWidth * 0.45
          },
          // Right-Middle (Horizontal) label
          {
            top: heightDiff + actualHeight * 0.65,
            left: widthDiff + actualWidth * 0.65
          },
          // Right-Bottom  label
          {
            top: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.4
          }
        ]
      };
    }
    case 'Compound_shape4_arrows': {
      const svgName = 'Shapes_with_arrows/Compound_shape4_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.35,
            right: widthDiff + actualWidth
          },
          // Top label
          {
            left: widthDiff + actualWidth * 0.4,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Middle label
          {
            top: heightDiff + actualHeight * 0.4,
            left: widthDiff + actualWidth * 0.55
          },
          // Bottom-Right label
          {
            top: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.05
          },
          // Bottom-Left label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.1
          }
        ]
      };
    }
    case 'Compound_shape5_arrows': {
      const svgName = 'Shapes_with_arrows/Compound_shape5_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.35,
            right: widthDiff + actualWidth
          },
          // Top-Left label
          {
            left: widthDiff + actualWidth * 0.15,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Top-Right label
          {
            right: widthDiff + actualWidth * 0.1,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Middle label
          {
            top: heightDiff + actualHeight * 0.65,
            left: widthDiff + actualWidth * 0.53
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.45
          }
        ]
      };
    }
    case 'Compound_shape6_arrows': {
      const svgName = 'Shapes_with_arrows/Compound_shape6_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            left: widthDiff + actualWidth * 0.45,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Right-Top label
          {
            left: widthDiff + actualWidth,
            bottom: heightDiff + actualHeight * 0.55
          },
          // Right-Bottom label
          {
            left: widthDiff + actualWidth * 0.5,
            bottom: heightDiff + actualHeight * 0.1
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.15
          }
        ]
      };
    }
    case 'Compound_shape7_arrows': {
      const svgName = 'Shapes_with_arrows/Compound_shape7_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.2,
            right: widthDiff + actualWidth
          },
          // Top label
          {
            left: widthDiff + actualWidth * 0.45,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Right label
          {
            left: widthDiff + actualWidth,
            bottom: heightDiff + actualHeight * 0.45
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.6,
            left: widthDiff + actualWidth * 0.25
          }
        ]
      };
    }
    case 'cube1_arrow': {
      const svgName = 'Cubes_With_1_Arrow/cube1_arrow';

      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            right: widthDiff + actualWidth,
            top: heightDiff + actualHeight * 0.53
          }
        ]
      };
    }
    case 'cube2_arrow': {
      const svgName = 'Cubes_With_1_Arrow/cube2_arrow';

      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.25,
            top: heightDiff + actualHeight * 0.95
          }
        ]
      };
    }
    case 'cube3_arrow': {
      const svgName = 'Cubes_With_1_Arrow/cube3_arrow';

      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.9,
            top: heightDiff + actualHeight * 0.8
          }
        ]
      };
    }
    case 'Cube_3_arrows_blue':
    case 'Cube_3_arrows_green':
    case 'Cube_3_arrows_red':
    case 'Cube_3_arrows_white':
    case 'Cube_3_arrows_yellow': {
      const svgName = `Cubes_With_3_Arrows/${shapeName}` as SvgName;

      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );

      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Height
          {
            right: widthDiff + actualWidth,
            top: isPDF ? heightDiff + actualHeight * 0.5 : heightDiff + actualHeight * 0.42
          },
          // Width
          {
            left: widthDiff + actualWidth * 0.25,
            top: isPDF ? heightDiff + actualHeight * 0.95 : heightDiff + actualHeight * 0.9
          },
          // Depth
          {
            left: widthDiff + actualWidth * 0.9,
            bottom: isPDF ? heightDiff + actualHeight * 0.05 : heightDiff * 0.5
          }
        ]
      };
    }
    case 'cuboid1_3_arrows':
    case 'cuboid4_3_arrows': {
      const svgName = `Cuboids_With_3_Arrows/${shapeName}` as SvgName;

      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Shortest length - right
          {
            left: widthDiff + actualWidth * 0.96,
            top: heightDiff - 12 + actualHeight * 0.8
          },
          // left
          {
            right: widthDiff + actualWidth,
            top: heightDiff - 12 + actualHeight * 0.45
          },
          // Longest length - middle
          {
            left: widthDiff + actualWidth * 0.35,
            top: heightDiff - 12 + actualHeight * 0.96
          }
        ]
      };
    }
    case 'cuboid2_3_arrows': {
      const svgName = 'Cuboids_With_3_Arrows/cuboid2_3_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.95,
            top: heightDiff + actualHeight * 0.8
          },
          {
            left: widthDiff + actualWidth * 0.3,
            top: heightDiff + actualHeight * 0.95
          },
          {
            right: widthDiff + actualWidth,
            top: heightDiff + actualHeight * 0.45
          }
        ]
      };
    }
    case 'cuboid3_3_arrows': {
      const svgName = 'Cuboids_With_3_Arrows/cuboid3_3_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.9,
            top: heightDiff + actualHeight * 0.8
          },
          {
            left: widthDiff + actualWidth * 0.28,
            top: heightDiff + actualHeight * 0.95
          },
          {
            right: widthDiff + actualWidth,
            top: heightDiff + actualHeight * 0.45
          }
        ]
      };
    }
    case 'cuboid5_3_arrows': {
      const svgName = 'Cuboids_With_3_Arrows/cuboid5_3_arrows';

      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Shortest length - right
          {
            left: widthDiff + actualWidth * 0.95,
            top: heightDiff + actualHeight * 0.8
          },
          // left
          {
            right: widthDiff + actualWidth,
            bottom: heightDiff + actualHeight * 0.25
          },
          // Longest length - middle
          {
            left: widthDiff + actualWidth * 0.4,
            top: heightDiff + actualHeight
          }
        ]
      };
    }
    case 'cuboid5_3_arrows_small': {
      const svgName = 'Cuboids_With_3_Arrows/cuboid5_3_arrows';

      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Shortest length - right
          {
            left: widthDiff + actualWidth * 0.92,
            top: heightDiff + actualHeight * 0.55
          },
          // left
          {
            right: widthDiff + actualWidth,
            bottom: heightDiff
          },
          // Longest length - middle
          {
            left: widthDiff + actualWidth * 0.35,
            top: heightDiff + actualHeight * 0.7
          }
        ]
      };
    }
    case 'cuboid6_3_arrows': {
      const svgName = 'Cuboids_With_3_Arrows/cuboid6_3_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.93,
            top: heightDiff + actualHeight * 0.88
          },
          {
            left: widthDiff + actualWidth * 0.25,
            top: heightDiff + actualHeight * 0.95
          },
          {
            right: widthDiff + actualWidth,
            top: heightDiff + actualHeight * 0.45
          }
        ]
      };
    }
    case 'isoceles_acute_triangle1': {
      const svgName = 'Shapes_with_interior_angles/Isosceles_triangle1_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - top
          {
            // if the view it's in is align center then we do not need a left position for this shape
            top: heightDiff + actualHeight * 0.15
          },
          //  bottom left
          { bottom: heightDiff, right: widthDiff + actualWidth * 0.15 },
          // bottom right
          { bottom: heightDiff, left: widthDiff + actualWidth * 0.15 }
        ],
        sideLabelPositions: []
      };
    }
    case 'isoceles_acute_triangle2': {
      const svgName = 'Shapes_with_interior_angles/Isosceles_triangle2_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - top
          {
            // if the view it's in is align center then we dont need a left position for this shape
            top: heightDiff + actualHeight * 0.1
          },
          // bottom left
          { bottom: heightDiff, right: widthDiff + actualWidth * 0.17 },
          // bottom right
          { bottom: heightDiff, left: widthDiff + actualWidth * 0.17 }
        ],
        sideLabelPositions: []
      };
    }
    case 'isoceles_obtuse_triangle1': {
      const svgName = 'Shapes_with_interior_angles/Interior_angles_in_triangle3';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // bottom left
          { bottom: heightDiff * 0.9, right: widthDiff + actualWidth * 0.15 },
          // bottom right
          { bottom: heightDiff * 0.9, left: widthDiff + actualWidth * 0.13 },
          // Largest angle - top
          {
            // if the view it's in is align center then we dont need a left position for this shape
            top: heightDiff + actualHeight * 0.16
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'quadrilateral_1': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral1_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            right: widthDiff + actualWidth * 0.83,
            top: heightDiff + actualHeight * 0.01
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.05, right: widthDiff + actualWidth * 0.6 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.23, left: widthDiff + actualWidth * 0.85 },
          // Right top label
          {
            left: widthDiff + actualWidth * 0.7,
            bottom: heightDiff + actualHeight * 0.62
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'quadrilateral_2': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral2_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            right: widthDiff + actualWidth * 0.65,
            top: heightDiff + actualHeight * 0.013
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.4, right: widthDiff + actualWidth * 0.03 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.17, left: widthDiff + actualWidth * 0.89 },
          // Right top label
          {
            left: widthDiff + actualWidth * 0.82,
            bottom: heightDiff + actualHeight * 0.62
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'quadrilateral_3': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral3_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.15,
            top: heightDiff + actualHeight * 0.33
          },
          // Left bottom label
          { left: widthDiff + actualWidth * 0.03, bottom: heightDiff + actualHeight * 0.03 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.25, right: widthDiff + actualWidth * 0.05 },
          // Right top label
          { top: heightDiff + actualHeight * 0.05, right: widthDiff + actualWidth * 0.17 }
        ],
        sideLabelPositions: []
      };
    }
    case 'quadrilateral_4': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral4_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.17,
            top: heightDiff + actualHeight * 0.03
          },
          // Left bottom label
          { left: widthDiff + actualWidth * 0.03, bottom: heightDiff + actualHeight * 0.03 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.2, right: widthDiff + actualWidth * 0.05 },
          // Right top label
          { top: heightDiff + actualHeight * 0.2, right: widthDiff + actualWidth * 0.35 }
        ],
        sideLabelPositions: []
      };
    }
    case 'Shapes_with_interior_angles/Irregular_quadrilateral1_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_quadrilateral1_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.3,
            top: heightDiff + actualHeight * 0.08
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.07, left: widthDiff + actualWidth * 0.09 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.3, right: widthDiff + actualWidth * 0.08 },
          // Right top label
          {
            right: widthDiff + actualWidth * 0.15,
            top: heightDiff + actualHeight * 0.15
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_quadrilateral2_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_quadrilateral2_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.16,
            top: heightDiff + actualHeight * 0.26
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.05, left: widthDiff + actualWidth * 0.05 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.1, right: widthDiff + actualWidth * 0.08 },
          // Right top label
          {
            right: widthDiff + actualWidth * 0.3,
            top: heightDiff + actualHeight * 0.1
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_quadrilateral2_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_quadrilateral2_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.43,
            top: heightDiff + actualHeight * 0.12
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.45, left: widthDiff + actualWidth * 0.1 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.08, right: widthDiff + actualWidth * 0.05 },
          // Right top label
          {
            right: widthDiff + actualWidth * 0.18,
            top: heightDiff + actualHeight * 0.32
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_quadrilateral3_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_quadrilateral3_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.06,
            top: heightDiff + actualHeight * 0.08
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.12, left: widthDiff + actualWidth * 0.15 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.47, right: widthDiff + actualWidth * 0.12 },
          // Right top label
          {
            right: widthDiff + actualWidth * 0.06,
            top: heightDiff + actualHeight * 0.08
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.35,
            top: heightDiff + actualHeight * 0.09
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.6, left: widthDiff + actualWidth * 0.12 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.15, right: widthDiff + actualWidth * 0.1 },
          // Right top label
          {
            right: widthDiff + actualWidth * 0.12,
            top: heightDiff + actualHeight * 0.25
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_quadrilateral4_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_quadrilateral4_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.25,
            top: heightDiff + actualHeight * 0.1
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.18, left: widthDiff + actualWidth * 0.09 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.07, right: widthDiff + actualWidth * 0.09 },
          // Right top label
          {
            right: widthDiff + actualWidth * 0.37,
            top: heightDiff + actualHeight * 0.32
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top middle label
          {
            left: widthDiff + actualWidth * 0.44,
            top: heightDiff + actualHeight * 0.1
          },
          // middle right label
          { top: heightDiff + actualHeight * 0.26, right: widthDiff + actualWidth * 0.1 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.1, right: widthDiff + actualWidth * 0.33 },
          // left bottom label
          {
            left: widthDiff + actualWidth * 0.3,
            bottom: heightDiff + actualHeight * 0.1
          },
          // middle left label
          {
            left: widthDiff + actualWidth * 0.12,
            bottom: heightDiff + actualHeight * 0.35
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top right label
          {
            right: widthDiff + actualWidth * 0.25,
            top: heightDiff + actualHeight * 0.12
          },
          // middle right label
          { bottom: heightDiff + actualHeight * 0.34, right: widthDiff + actualWidth * 0.14 },
          // bottom middle label
          { bottom: heightDiff + actualHeight * 0.12, right: widthDiff + actualWidth * 0.35 },
          // middle left label
          {
            left: widthDiff + actualWidth * 0.3,
            bottom: heightDiff + actualHeight * 0.3
          },
          // top left label
          {
            left: widthDiff + actualWidth * 0.15,
            top: heightDiff + actualHeight * 0.25
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top middle label
          {
            left: widthDiff + actualWidth * 0.37,
            top: heightDiff + actualHeight * 0.15
          },
          // middle right label
          { bottom: heightDiff + actualHeight * 0.38, right: widthDiff + actualWidth * 0.12 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.15, right: widthDiff + actualWidth * 0.4 },
          // left bottom label
          {
            left: widthDiff + actualWidth * 0.25,
            bottom: heightDiff + actualHeight * 0.15
          },
          // middle left label
          {
            left: widthDiff + actualWidth * 0.08,
            top: heightDiff + actualHeight * 0.38
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top right label
          {
            right: widthDiff + actualWidth * 0.45,
            top: heightDiff + actualHeight * 0.14
          },
          // middle right label
          { top: heightDiff + actualHeight * 0.28, right: widthDiff + actualWidth * 0.16 },
          // bottom middle label
          { bottom: heightDiff + actualHeight * 0.13, left: widthDiff + actualWidth * 0.3 },
          // bottom left label
          {
            left: widthDiff + actualWidth * 0.13,
            bottom: heightDiff + actualHeight * 0.03
          },
          // middle left label
          {
            left: widthDiff + actualWidth * 0.35,
            top: heightDiff + actualHeight * 0.43
          },
          // top left label
          {
            left: widthDiff + actualWidth * 0.13,
            top: heightDiff + actualHeight * 0.03
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top left label
          {
            left: widthDiff + actualWidth * 0.22,
            top: heightDiff + actualHeight * 0.14
          },
          // top right label
          {
            right: widthDiff + actualWidth * 0.3,
            top: heightDiff + actualHeight * 0.3
          },
          // middle right label
          { bottom: heightDiff + actualHeight * 0.35, right: widthDiff + actualWidth * 0.12 },
          // bottom right label
          { bottom: heightDiff + actualHeight * 0.1, right: widthDiff + actualWidth * 0.18 },
          // bottom left label
          {
            bottom: heightDiff + actualHeight * 0.1,
            left: widthDiff + actualWidth * 0.28
          },
          // middle left label
          {
            left: widthDiff + actualWidth * 0.12,
            top: heightDiff + actualHeight * 0.43
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top right label
          {
            right: widthDiff + actualWidth * 0.33,
            top: heightDiff + actualHeight * 0.12
          },
          // middle right label
          { top: heightDiff + actualHeight * 0.4, right: widthDiff + actualWidth * 0.13 },
          // bottom middle label
          { bottom: heightDiff + actualHeight * 0.13, right: widthDiff + actualWidth * 0.45 },
          // bottom left label
          {
            left: widthDiff + actualWidth * 0.23,
            bottom: heightDiff + actualHeight * 0.08
          },
          // middle left label
          {
            left: widthDiff + actualWidth * 0.12,
            bottom: heightDiff + actualHeight * 0.2
          },
          // top left label
          {
            left: widthDiff + actualWidth * 0.28,
            top: heightDiff + actualHeight * 0.32
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top middle label
          {
            right: widthDiff + actualWidth * 0.46,
            top: heightDiff + actualHeight * 0.33
          },
          // top right label
          { top: heightDiff + actualHeight * 0.12, right: widthDiff + actualWidth * 0.23 },
          // middle right label
          { top: heightDiff + actualHeight * 0.23, right: widthDiff + actualWidth * 0.1 },
          // bottom right label
          {
            right: widthDiff + actualWidth * 0.3,
            top: heightDiff + actualHeight * 0.32
          },
          // bottom middle label
          {
            right: widthDiff + actualWidth * 0.44,
            bottom: heightDiff + actualHeight * 0.12
          },
          // bottom left label
          {
            left: widthDiff + actualWidth * 0.25,
            bottom: heightDiff + actualHeight * 0.38
          },
          // top left label
          {
            left: widthDiff + actualWidth * 0.08,
            top: heightDiff + actualHeight * 0.08
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top middle label
          {
            left: widthDiff + actualWidth * 0.38,
            top: heightDiff + actualHeight * 0.12
          },
          // middle middle label
          { bottom: heightDiff + actualHeight * 0.34, right: widthDiff + actualWidth * 0.32 },
          // middle right label
          { top: heightDiff + actualHeight * 0.39, right: widthDiff + actualWidth * 0.09 },
          // bottom right label
          {
            right: widthDiff + actualWidth * 0.25,
            bottom: heightDiff + actualHeight * 0.24
          },
          // bottom middle label
          {
            left: widthDiff + actualWidth * 0.25,
            bottom: heightDiff + actualHeight * 0.08
          },
          // bottom left label
          {
            left: widthDiff + actualWidth * 0.12,
            bottom: heightDiff + actualHeight * 0.23
          },
          // top left label
          {
            left: widthDiff + actualWidth * 0.2,
            top: heightDiff + actualHeight * 0.2
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top middle label
          {
            right: widthDiff + actualWidth * 0.42,
            top: heightDiff + actualHeight * 0.37
          },
          // top right label
          { top: heightDiff + actualHeight * 0.18, right: widthDiff + actualWidth * 0.11 },
          // middle right label
          { bottom: heightDiff + actualHeight * 0.43, right: widthDiff + actualWidth * 0.28 },
          // bottom right label
          {
            right: widthDiff + actualWidth * 0.1,
            bottom: heightDiff + actualHeight * 0.1
          },
          // bottom middle label
          {
            right: widthDiff + actualWidth * 0.16,
            bottom: heightDiff + actualHeight * 0.3
          },
          // bottom left label
          {
            left: widthDiff + actualWidth * 0.12,
            bottom: heightDiff + actualHeight * 0.3
          },
          // top left label
          {
            left: widthDiff + actualWidth * 0.08,
            top: heightDiff + actualHeight * 0.08
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top middle left label
          {
            left: widthDiff + actualWidth * 0.2,
            top: heightDiff + actualHeight * 0.13
          },
          // top middle right label
          { top: heightDiff + actualHeight * 0.44, right: widthDiff + actualWidth * 0.38 },
          // top right label
          { top: heightDiff + actualHeight * 0.42, right: widthDiff + actualWidth * 0.16 },
          // bottom right label
          {
            right: widthDiff + actualWidth * 0.13,
            bottom: heightDiff + actualHeight * 0.28
          },
          // bottom middle right label
          {
            right: widthDiff + actualWidth * 0.25,
            bottom: heightDiff + actualHeight * 0.07
          },
          // bottom middle left label
          {
            left: widthDiff + actualWidth * 0.34,
            bottom: heightDiff + actualHeight * 0.07
          },
          // bottom left label
          {
            left: widthDiff + actualWidth * 0.07,
            bottom: heightDiff + actualHeight * 0.16
          },
          // top left label
          {
            left: widthDiff + actualWidth * 0.09,
            top: heightDiff + actualHeight * 0.25
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top middle right label
          { top: heightDiff + actualHeight * 0.08, right: widthDiff + actualWidth * 0.39 },
          // top right label
          { top: heightDiff + actualHeight * 0.3, right: widthDiff + actualWidth * 0.1 },
          // bottom right label
          {
            right: widthDiff + actualWidth * 0.13,
            bottom: heightDiff + actualHeight * 0.24
          },
          // bottom middle right label
          {
            right: widthDiff + actualWidth * 0.32,
            bottom: heightDiff + actualHeight * 0.32
          },
          // bottom middle left label
          {
            left: widthDiff + actualWidth * 0.29,
            bottom: heightDiff + actualHeight * 0.08
          },
          // bottom left label
          {
            left: widthDiff + actualWidth * 0.31,
            bottom: heightDiff + actualHeight * 0.36
          },
          // top left label
          {
            left: widthDiff + actualWidth * 0.12,
            top: heightDiff + actualHeight * 0.36
          },
          // top middle left label
          {
            left: widthDiff + actualWidth * 0.35,
            top: heightDiff + actualHeight * 0.36
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // top middle label
          { top: heightDiff + actualHeight * 0.21, left: widthDiff + actualWidth * 0.33 },
          // top right label
          { top: heightDiff + actualHeight * 0.03, right: widthDiff + actualWidth * 0.13 },
          // middle right label
          {
            right: widthDiff + actualWidth * 0.4,
            top: heightDiff + actualHeight * 0.29
          },
          // bottom right label
          {
            right: widthDiff + actualWidth * 0.09,
            bottom: heightDiff + actualHeight * 0.28
          },
          // bottom middle right label
          {
            right: widthDiff + actualWidth * 0.27,
            bottom: heightDiff + actualHeight * 0.38
          },
          // bottom middle left label
          {
            left: widthDiff + actualWidth * 0.38,
            bottom: heightDiff + actualHeight * 0.09
          },
          // bottom left label
          {
            left: widthDiff + actualWidth * 0.22,
            bottom: heightDiff + actualHeight * 0.29
          },
          // top left left label
          {
            left: widthDiff + actualWidth * 0.06,
            top: heightDiff + actualHeight * 0.06
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Quadrilateral5_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral5_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.4,
            top: heightDiff + actualHeight * 0.06
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.04, left: widthDiff + actualWidth * 0.04 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.22, right: widthDiff + actualWidth * 0.04 },
          // Right top label
          {
            right: widthDiff + actualWidth * 0.07,
            top: heightDiff + actualHeight * 0.48
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Quadrilateral6_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral6_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.05,
            top: heightDiff + actualHeight * 0.05
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.07, left: widthDiff + actualWidth * 0.3 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.1, right: widthDiff + actualWidth * 0.12 },
          // Right top label
          {
            left: widthDiff + actualWidth * 0.72,
            bottom: heightDiff + actualHeight * 0.35
          }
        ],
        sideLabelPositions: []
      };
    }

    case 'Shapes_with_interior_angles/Quadrilateral7_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral7_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.27,
            top: heightDiff + actualHeight * 0.45
          },
          // Left bottom label
          { bottom: heightDiff + actualHeight * 0.15, left: widthDiff + actualWidth * 0.27 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.17, right: widthDiff + actualWidth * 0.34 },
          // Right top label
          {
            right: widthDiff + actualWidth * 0.45,
            top: heightDiff + actualHeight * 0.11
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Trapezium1_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Trapezium1_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.44,
            top: isPDF ? heightDiff + actualHeight * 0.13 : heightDiff + actualHeight * 0.1
          },
          // Left bottom label
          {
            left: widthDiff + actualWidth * 0.1,
            bottom: isPDF ? heightDiff + actualHeight * 0.1 : heightDiff + actualHeight * 0.03
          },
          // Right bottom label
          {
            bottom: isPDF ? heightDiff + actualHeight * 0.1 : heightDiff + actualHeight * 0.03,
            right: widthDiff + actualWidth * 0.1
          },
          // Right top label
          {
            top: isPDF ? heightDiff + actualHeight * 0.13 : heightDiff + actualHeight * 0.1,
            right: widthDiff + actualWidth * 0.13
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Trapezium2_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Trapezium2_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.1,
            top: isPDF ? heightDiff + actualHeight * 0.15 : heightDiff + actualHeight * 0.1
          },
          // Left bottom label
          {
            left: widthDiff + actualWidth * 0.1,
            bottom: isPDF ? heightDiff + actualHeight * 0.15 : heightDiff + actualHeight * 0.1
          },
          // Right bottom label
          {
            bottom: isPDF ? heightDiff + actualHeight * 0.1 : heightDiff + actualHeight * 0.05,
            right: widthDiff + actualWidth * 0.15
          },
          // Right top label
          {
            top: isPDF ? heightDiff + actualHeight * 0.2 : heightDiff + actualHeight * 0.12,
            right: widthDiff + actualWidth * 0.48
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Trapezium3_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Trapezium3_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: isPDF ? widthDiff + actualWidth * 0.07 : widthDiff + actualWidth * 0.09,
            top: isPDF ? heightDiff + actualHeight * 0.07 : heightDiff + actualHeight * 0.03
          },
          // Left bottom label
          {
            left: isPDF ? widthDiff + actualWidth * 0.25 : widthDiff + actualWidth * 0.2,
            bottom: heightDiff + actualHeight * 0.1
          },
          // Right bottom label
          {
            bottom: heightDiff + actualHeight * 0.1,
            right: isPDF ? widthDiff + actualWidth * 0.3 : widthDiff + actualWidth * 0.25
          },
          // Right top label
          {
            top: isPDF ? heightDiff + actualHeight * 0.07 : heightDiff + actualHeight * 0.03,
            right: isPDF ? widthDiff + actualWidth * 0.08 : widthDiff + actualWidth * 0.1
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Rectangle1_interior_lines_and_angles': {
      const svgName = 'Shapes_with_interior_angles/Rectangle1_interior_lines_and_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // smaller angle
          {
            left: widthDiff + actualWidth * 0.12,
            bottom: heightDiff + actualHeight * 0.82
          },
          // larger angle
          { left: widthDiff + actualWidth * 0.04, top: heightDiff + actualHeight * 0.05 }
        ],
        sideLabelPositions: []
      };
    }
    case 'Rectangle2_interior_lines_and_angles': {
      const svgName = 'Shapes_with_interior_angles/Rectangle2_interior_lines_and_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // smaller angle
          {
            right: widthDiff + actualWidth * 0.12,
            bottom: heightDiff + actualHeight * 0.82
          },
          // larger angle
          { right: widthDiff + actualWidth * 0.05, top: heightDiff + actualHeight * 0.13 }
        ],
        sideLabelPositions: []
      };
    }
    case 'Rectangle3_interior_lines_and_angles': {
      const svgName = 'Shapes_with_interior_angles/Rectangle3_interior_lines_and_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // smaller label
          {
            left: widthDiff + actualWidth * 0.15,
            top: heightDiff + actualHeight * 0.725
          },
          // larger label
          { left: widthDiff + actualWidth * 0.05, bottom: heightDiff + actualHeight * 0.15 }
        ],
        sideLabelPositions: []
      };
    }
    case 'Rectangle4_interior_lines_and_angles': {
      const svgName = 'Shapes_with_interior_angles/Rectangle4_interior_lines_and_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // smaller label
          { right: widthDiff + actualWidth * 0.75, top: heightDiff + actualHeight * 0.18 },
          // larger label
          {
            left: widthDiff + actualWidth * 0.25,
            bottom: heightDiff + actualHeight * 0.8
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Trapezium4_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Trapezium4_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Left top label
          {
            left: widthDiff + actualWidth * 0.3,
            top: isPDF ? heightDiff + actualHeight * 0.12 : heightDiff + actualHeight * 0.09
          },
          // Left bottom label
          { left: widthDiff + actualWidth * 0.08, bottom: heightDiff + actualHeight * 0.08 },
          // Right bottom label
          { bottom: heightDiff + actualHeight * 0.08, right: widthDiff + actualWidth * 0.05 },
          // Right top label
          {
            top: isPDF ? heightDiff + actualHeight * 0.12 : heightDiff + actualHeight * 0.09,
            right: widthDiff + actualWidth * 0.22
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Rhombus1_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Rhombus1_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top label
          {
            top: isPDF ? heightDiff + actualHeight * 0.12 : heightDiff + actualHeight * 0.09
          },
          // Left label
          {
            left: widthDiff + actualWidth * 0.13,
            bottom: isPDF ? heightDiff + actualHeight * 0.43 : heightDiff + actualHeight * 0.37
          },
          // Bottom label
          { bottom: isPDF ? heightDiff + actualHeight * 0.12 : heightDiff + actualHeight * 0.09 },
          // Right label
          {
            right: widthDiff + actualWidth * 0.13,
            bottom: isPDF ? heightDiff + actualHeight * 0.43 : heightDiff + actualHeight * 0.37
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Rhombus3_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Rhombus3_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top label
          {
            top: isPDF ? heightDiff + actualHeight * 0.14 : heightDiff + actualHeight * 0.09
          },
          // Left label
          {
            left: widthDiff + actualWidth * 0.13,
            bottom: isPDF ? heightDiff + actualHeight * 0.43 : heightDiff + actualHeight * 0.37
          },
          // Bottom label
          { bottom: isPDF ? heightDiff + actualHeight * 0.14 : heightDiff + actualHeight * 0.09 },
          // Right label
          {
            right: widthDiff + actualWidth * 0.13,
            bottom: isPDF ? heightDiff + actualHeight * 0.42 : heightDiff + actualHeight * 0.37
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Kite_two_isosceles_triangles1': {
      const svgName = 'Shapes_with_interior_angles/Kite_two_isosceles_triangles1';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Bottom-left label
          {
            top: isPDF ? heightDiff + actualHeight * 0.13 : heightDiff + actualHeight * 0.12,
            left: widthDiff + actualWidth * 0.16
          },
          // Top label
          {
            left: widthDiff + actualWidth * 0.42,
            bottom: heightDiff + actualHeight * 0.77
          },
          // Bottom-right label
          {
            bottom: heightDiff + actualHeight * 0.72,
            left: widthDiff + actualWidth * 0.66
          },
          // Top-left label
          {
            bottom: heightDiff + actualHeight * 0.63,
            left: widthDiff + actualWidth * 0.15
          },
          // Bottom label
          {
            right: widthDiff + actualWidth * 0.4,
            bottom: isPDF ? heightDiff + actualHeight * 0.11 : heightDiff + actualHeight * 0.09
          },
          // Top-right label
          {
            top: isPDF ? heightDiff + actualHeight * 0.24 : heightDiff + actualHeight * 0.22,
            left: widthDiff + actualWidth * 0.66
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Kite_two_isosceles_triangles2': {
      const svgName = 'Shapes_with_interior_angles/Kite_two_isosceles_triangles2';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Bottom-right label
          {
            top: isPDF ? heightDiff + actualHeight * 0.27 : heightDiff + actualHeight * 0.245,
            left: widthDiff + actualWidth * 0.58
          },
          // Top label
          {
            left: widthDiff + actualWidth * 0.36,
            bottom: heightDiff + actualHeight * 0.8
          },
          // Bottom-left label
          {
            bottom: isPDF ? heightDiff + actualHeight * 0.61 : heightDiff + actualHeight * 0.6,
            left: widthDiff + actualWidth * 0.16
          },
          // Top-left label
          {
            bottom: heightDiff + actualHeight * 0.5,
            left: widthDiff + actualWidth * 0.14
          },
          // Bottom label
          {
            right: widthDiff + actualWidth * 0.34,
            bottom: heightDiff + actualHeight * 0.1
          },
          // Top-right label
          {
            bottom: heightDiff + actualHeight * 0.5,
            left: widthDiff + actualWidth * 0.58
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Kite_two_isosceles_triangles3': {
      const svgName = 'Shapes_with_interior_angles/Kite_two_isosceles_triangles3';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top-right label
          {
            top: isPDF ? heightDiff + actualHeight * 0.14 : heightDiff + actualHeight * 0.11,
            left: isPDF ? widthDiff + actualWidth * 0.22 : widthDiff + actualWidth * 0.19
          },
          // Left label
          {
            left: widthDiff + actualWidth * 0.08,
            bottom: isPDF ? heightDiff + actualHeight * 0.43 : heightDiff + actualHeight * 0.4
          },
          // Bottom-right label
          {
            bottom: isPDF ? heightDiff + actualHeight * 0.15 : heightDiff + actualHeight * 0.15,
            left: isPDF ? widthDiff + actualWidth * 0.22 : widthDiff + actualWidth * 0.19
          },
          // Bottom-left label
          {
            bottom: isPDF ? heightDiff + actualHeight * 0.11 : heightDiff + actualHeight * 0.11,
            left: widthDiff + actualWidth * 0.3
          },
          // Right label
          {
            right: isPDF ? widthDiff + actualWidth * 0.14 : widthDiff + actualWidth * 0.13,
            bottom: isPDF ? heightDiff + actualHeight * 0.43 : heightDiff + actualHeight * 0.4
          },
          // Top-left label
          {
            top: isPDF ? heightDiff + actualHeight * 0.1 : heightDiff + actualHeight * 0.08,
            left: widthDiff + actualWidth * 0.3
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Kite_two_isosceles_triangles4': {
      const svgName = 'Shapes_with_interior_angles/Kite_two_isosceles_triangles4';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Bottom-left label
          {
            top: isPDF ? heightDiff + actualHeight * 0.25 : heightDiff + actualHeight * 0.23,
            left: widthDiff + actualWidth * 0.14
          },
          // Top label
          {
            left: widthDiff + actualWidth * 0.45,
            bottom: heightDiff + actualHeight * 0.74
          },
          // Bottom-right label
          {
            top: isPDF ? heightDiff + actualHeight * 0.25 : heightDiff + actualHeight * 0.24,
            right: widthDiff + actualWidth * 0.14
          },
          // Top-left label
          {
            bottom: heightDiff + actualHeight * 0.5,
            left: widthDiff + actualWidth * 0.13
          },
          // Bottom label
          {
            right: widthDiff + actualWidth * 0.44,
            bottom: isPDF ? heightDiff + actualHeight * 0.14 : heightDiff + actualHeight * 0.13
          },
          // Top-right label
          {
            bottom: heightDiff + actualHeight * 0.5,
            right: widthDiff + actualWidth * 0.13
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Labelled_compound_shape1': {
      const svgName = 'Shapes_with_arrows/Labelled_compound_shape1';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.4,
            right: widthDiff + actualWidth
          },
          // Top-Left label
          {
            left: widthDiff + actualWidth * 0.08,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Top-Right label
          {
            bottom: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.3
          },
          // Right label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff + actualWidth
          }
        ]
      };
    }
    case 'Labelled_compound_shape2': {
      const svgName = 'Shapes_with_arrows/Labelled_compound_shape2';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.4,
            right: widthDiff + actualWidth
          },
          // Top-Left label
          {
            left: widthDiff + actualWidth * 0.2,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Top-Right label
          {
            bottom: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.15
          },
          // Right label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff + actualWidth
          }
        ]
      };
    }
    case 'Labelled_compound_shape3': {
      const svgName = 'Shapes_with_arrows/Labelled_compound_shape3';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.4,
            right: widthDiff + actualWidth
          },
          // Top-Left label
          {
            left: widthDiff + actualWidth * 0.3,
            bottom: heightDiff + actualHeight * 0.95
          },
          // Top-Right label
          {
            bottom: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.1
          },
          // Right label
          {
            top: heightDiff + actualHeight * 0.2,
            left: widthDiff + actualWidth
          }
        ]
      };
    }
    case 'Labelled_compound_shape4': {
      const svgName = 'Shapes_with_arrows/Labelled_compound_shape4';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.4,
            right: widthDiff + actualWidth
          },
          // Right label
          {
            bottom: heightDiff + actualHeight * 0.2,
            left: widthDiff + actualWidth
          },
          // Bottom-Right label
          {
            top: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.3
          },
          // Bottom-Left label
          {
            left: widthDiff + actualWidth * 0.1,
            top: heightDiff + actualHeight * 0.95
          }
        ]
      };
    }
    case 'Labelled_compound_shape5': {
      const svgName = 'Shapes_with_arrows/Labelled_compound_shape5';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.4,
            right: widthDiff + actualWidth
          },
          // Right label
          {
            bottom: heightDiff + actualHeight * 0.3,
            left: widthDiff + actualWidth
          },
          // Bottom-Right label
          {
            top: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.3
          },
          // Bottom-Left label
          {
            left: widthDiff + actualWidth * 0.1,
            top: heightDiff + actualHeight * 0.95
          }
        ]
      };
    }
    case 'Labelled_compound_shape6': {
      const svgName = 'Shapes_with_arrows/Labelled_compound_shape6';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Left label
          {
            top: heightDiff + actualHeight * 0.4,
            right: widthDiff + actualWidth
          },
          // Right label
          {
            bottom: heightDiff + actualHeight * 0.05,
            left: widthDiff + actualWidth
          },
          // Bottom-Right label
          {
            top: heightDiff + actualHeight * 0.95,
            right: widthDiff + actualWidth * 0.3
          },
          // Bottom-Left label
          {
            left: widthDiff + actualWidth * 0.1,
            top: heightDiff + actualHeight * 0.95
          }
        ]
      };
    }
    case 'Quadrilateral1_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral1_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top left label
          {
            left: widthDiff + actualWidth * 0.08,
            top: isPDF ? heightDiff + actualHeight * 0.04 : heightDiff + actualHeight * 0.02
          },
          // Bottom left label
          {
            bottom: isPDF ? heightDiff + actualHeight * 0.09 : heightDiff + actualHeight * 0.06,
            left: isPDF ? widthDiff + actualWidth * 0.3 : widthDiff + actualWidth * 0.28
          },
          // Bottom right label
          { bottom: heightDiff + actualHeight * 0.23, right: widthDiff + actualWidth * 0.07 },
          // Top right label
          {
            right: isPDF ? widthDiff + actualWidth * 0.24 : widthDiff + actualWidth * 0.22,
            top: isPDF ? heightDiff + actualHeight * 0.19 : heightDiff + actualHeight * 0.17
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Quadrilateral2_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral2_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top-left label
          {
            top: heightDiff + actualHeight * 0.05,
            left: widthDiff + actualWidth * 0.27
          },
          // Bottom-left label
          {
            right: widthDiff + actualWidth * 0.18,
            top: heightDiff + actualHeight * 0.26
          },
          // Bottom-right label
          { bottom: heightDiff + actualHeight * 0.2, left: widthDiff + actualWidth * 0.91 },
          // Top-right label
          {
            right: widthDiff + actualWidth * 0.09,
            top: isPDF ? heightDiff + actualHeight * 0.22 : heightDiff + actualHeight * 0.2
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Quadrilateral3_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral3_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top-left label
          {
            left: widthDiff + actualWidth * 0.2,
            top: heightDiff + actualHeight * 0.33
          },
          // Bottom-left label
          { left: widthDiff + actualWidth * 0.05, bottom: heightDiff + actualHeight * 0.05 },
          // Bottom-right label
          {
            bottom: heightDiff + actualHeight * 0.24,
            right: isPDF ? widthDiff + actualWidth * 0.09 : widthDiff + actualWidth * 0.08
          },
          // Top-right label
          {
            top: isPDF ? heightDiff + actualHeight * 0.08 : heightDiff + actualHeight * 0.06,
            right: widthDiff + actualWidth * 0.17
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Quadrilateral4_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral4_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top-left label
          {
            left: widthDiff + actualWidth * 0.18,
            top: isPDF ? heightDiff + actualHeight * 0.08 : heightDiff + actualHeight * 0.06
          },
          // Bottom-left label
          {
            left: widthDiff + actualWidth * 0.05,
            bottom: isPDF ? heightDiff + actualHeight * 0.06 : heightDiff + actualHeight * 0.04
          },
          // Bottom-right label
          { bottom: heightDiff + actualHeight * 0.2, right: widthDiff + actualWidth * 0.09 },
          // Top-right label
          { top: heightDiff + actualHeight * 0.23, right: widthDiff + actualWidth * 0.37 }
        ],
        sideLabelPositions: []
      };
    }
    case 'Quadrilateral5_interior_angles': {
      const svgName = 'Shapes_with_interior_angles/Quadrilateral5_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top-left label
          {
            left: widthDiff + actualWidth * 0.41,
            top: isPDF ? heightDiff + actualHeight * 0.08 : heightDiff + actualHeight * 0.07
          },
          // Bottom-left label
          {
            left: widthDiff + actualWidth * 0.08,
            bottom: isPDF ? heightDiff + actualHeight * 0.06 : heightDiff + actualHeight * 0.03
          },
          // Bottom-right label
          {
            bottom: heightDiff + actualHeight * 0.2,
            right: isPDF ? widthDiff + actualWidth * 0.11 : widthDiff + actualWidth * 0.09
          },
          // Top-right label
          { top: heightDiff + actualHeight * 0.47, right: widthDiff + actualWidth * 0.12 }
        ],
        sideLabelPositions: []
      };
    }
    case 'Triangle1_line_extension_3_angles': {
      const svgName = 'Other_shapes/Triangle1_line_extension_3_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // left inner angle label
          { left: widthDiff + actualWidth * 0.2, bottom: heightDiff + actualHeight * 0.13 },
          // left outer angle label
          { left: widthDiff + actualWidth * 0.05, bottom: heightDiff + actualHeight * 0.2 },
          // top inner angle label
          { right: widthDiff + actualWidth * 0.43, top: heightDiff + actualHeight * 0.25 },
          // top outer angle label
          {
            left: widthDiff + actualWidth * 0.6,
            bottom: heightDiff + actualHeight * 0.75
          },
          // right inner angle label
          { right: widthDiff + actualWidth * 0.15, bottom: heightDiff + actualHeight * 0.13 },
          // right outer angle label
          { right: widthDiff + actualWidth * 0.13, top: heightDiff + actualHeight * 0.85 }
        ],
        sideLabelPositions: []
      };
    }
    case 'scalene_triangle1': {
      const svgName = 'Shapes_with_interior_angles/Scalene_triangle1_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - bottom left
          { bottom: heightDiff, left: widthDiff + actualWidth * 0.15 },
          // Middle angle - bottom right
          { bottom: heightDiff + actualHeight * 0.03, right: widthDiff + actualWidth * 0.13 },
          // Largest angle - top
          {
            left: widthDiff + actualWidth * 0.6,
            top: heightDiff + actualHeight * 0.2
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'scalene_triangle2': {
      const svgName = 'Shapes_with_interior_angles/Scalene_triangle2_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - bottom right
          { bottom: heightDiff, right: widthDiff + actualWidth * 0.13 },
          // Middle angle - bottom left
          { bottom: heightDiff + actualHeight * 0.05, left: widthDiff + actualWidth * 0.13 },
          // Largest angle - top
          {
            left: widthDiff + actualWidth * 0.35,
            top: heightDiff + actualHeight * 0.2
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'scalene_triangle3': {
      const svgName = 'Shapes_with_interior_angles/Scalene_triangle3_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - bottom right
          { bottom: heightDiff, right: widthDiff + actualWidth * 0.13 },
          // Middle angle - bottom left
          { bottom: heightDiff + actualHeight * 0.03, left: widthDiff + actualWidth * 0.12 },
          // Largest angle - top
          {
            left: widthDiff + actualWidth * 0.36,
            top: heightDiff + actualHeight * 0.15
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'scalene_triangle5': {
      const svgName = 'Shapes_with_interior_angles/Scalene_triangle5_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - bottom right
          { bottom: heightDiff + actualHeight * 0.01, right: widthDiff + actualWidth * 0.22 },
          // Middle angle - bottom left
          { bottom: heightDiff + actualHeight * 0.01, left: widthDiff + actualWidth * 0.14 },
          // Largest angle - top
          {
            left: widthDiff + actualWidth * 0.36,
            top: heightDiff + actualHeight * 0.31
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'irregular_triangle1': {
      const svgName = 'Shapes_with_interior_angles/Irregular_triangle1_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - bottom right
          { bottom: heightDiff + actualHeight * 0.02, right: widthDiff + actualWidth * 0.22 },
          // Middle angle - bottom left
          { bottom: heightDiff + actualHeight * 0.1, left: widthDiff + actualWidth * 0.14 },
          // Largest angle - top
          {
            left: widthDiff + actualWidth * 0.25,
            top: heightDiff + actualHeight * 0.2
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'irregular_triangle2': {
      const svgName = 'Shapes_with_interior_angles/Irregular_triangle2_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - top
          {
            right: widthDiff + actualWidth * 0.24,
            top: heightDiff + actualHeight * 0.08
          },
          // Middle angle - bottom
          {
            bottom: heightDiff + actualHeight * 0.15,
            left: widthDiff + actualWidth * 0.08
          },
          // Largest angle - middle right
          { top: heightDiff + actualHeight * 0.42, left: widthDiff + actualWidth * 0.14 }
        ],
        sideLabelPositions: []
      };
    }
    case 'irregular_triangle3': {
      const svgName = 'Shapes_with_interior_angles/Irregular_triangle3_marked_interior_angles';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Smallest angle - top
          {
            left: widthDiff + actualWidth * 0.18,
            top: heightDiff + actualHeight * 0.12
          },
          // Middle angle - bottom
          {
            bottom: heightDiff + actualHeight * 0.1,
            left: widthDiff + actualWidth * 0.58
          },
          // Largest angle - middle right
          { bottom: heightDiff + actualHeight * 0.35, right: widthDiff + actualWidth * 0.17 }
        ],
        sideLabelPositions: []
      };
    }
    case 'LShape_1': {
      const svg =
        'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top edge
          { top: -10, left: widthDiff + actualWidth * 0.4 },
          // right vertical edge
          { top: heightDiff + actualHeight * 0.15, left: widthDiff + actualWidth },
          // right horizontal edge
          { top: heightDiff + actualHeight * 0.5, left: widthDiff + actualWidth * 0.55 },
          // bottom vertical edge
          { top: heightDiff + actualHeight * 0.65, left: widthDiff + actualWidth * 0.32 },
          // bottom horizontal edge
          { top: heightDiff + actualHeight * 0.95, left: widthDiff + actualWidth * 0.1 },
          // left vertical edge
          { top: heightDiff + actualHeight * 0.4, left: widthDiff - 70 }
        ]
      };
    }
    case 'LShape_2': {
      const svg =
        'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top left edge
          { top: -10, left: widthDiff + actualWidth * 0.1 },
          // left vertical edge
          { top: heightDiff + actualHeight * 0.15, left: widthDiff + actualWidth * 0.32 },
          // horizontal edge
          { top: heightDiff + actualHeight * 0.3, left: widthDiff + actualWidth * 0.55 },
          // right vertical edge
          { top: heightDiff + actualHeight * 0.65, left: widthDiff + actualWidth },
          // bottom horizontal edge
          { top: heightDiff + actualHeight * 0.95, left: widthDiff + actualWidth * 0.45 },
          // left vertical edge
          { top: heightDiff + actualHeight * 0.4, left: widthDiff - 70 }
        ]
      };
    }
    case 'LShape_3': {
      const svg =
        'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top left edge
          { top: -10, left: widthDiff + actualWidth * 0.78 },
          // right vertical edge
          { top: heightDiff + actualHeight * 0.4, left: widthDiff + actualWidth },
          // bottom horizontal edge
          { top: heightDiff + actualHeight * 0.95, left: widthDiff + actualWidth * 0.45 },
          // left vertical edge
          { top: heightDiff + actualHeight * 0.65, left: widthDiff - 70 },
          // mid horizontal edge
          { top: heightDiff + actualHeight * 0.3, left: widthDiff + actualWidth * 0.3 },
          // left vertical edge
          { top: heightDiff + actualHeight * 0.2, left: widthDiff + actualWidth * 0.55 }
        ]
      };
    }
    case 'LShape_4': {
      const svg =
        'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top edge
          { top: -10, left: widthDiff + actualWidth * 0.45 },
          // right vertical edge
          { top: heightDiff + actualHeight * 0.4, left: widthDiff + actualWidth },
          // bottom horizontal edge
          { top: heightDiff + actualHeight * 0.95, left: widthDiff + actualWidth * 0.8 },
          // left vertical edge
          { top: heightDiff + actualHeight * 0.65, left: widthDiff + actualWidth * 0.55 },
          // mid horizontal edge
          { top: heightDiff + actualHeight * 0.5, left: widthDiff + actualWidth * 0.3 },
          // left vertical edge
          { top: heightDiff + actualHeight * 0.2, left: widthDiff - 70 }
        ]
      };
    }
    case 'Rectilinear_shape1_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape1_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          { top: heightDiff - 30 + actualHeight * 0.3, left: widthDiff + actualWidth * 1.02 },
          {
            left: widthDiff + actualWidth * 0.6 - 100,
            top: heightDiff - 12 + actualHeight * 0.64,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 0.3,
            top: heightDiff - 12 + actualHeight * 0.75
          },
          {
            left: widthDiff,
            top: heightDiff - 12 + actualHeight * 0.98,
            minWidth: actualWidth * 0.25
          },
          {
            right: widthDiff + actualWidth * 1.02,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape2_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape2_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );

      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            right: widthDiff + actualWidth * 0.5 - 100
          },
          { bottom: heightDiff + actualHeight * 0.7, left: widthDiff + actualWidth * 0.83 },
          {
            left: widthDiff + actualWidth * 0.85,
            bottom: heightDiff - 12 + actualHeight * 0.57
          },
          {
            left: widthDiff + actualWidth * 1.02,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            left: widthDiff + actualWidth * 0.85,
            top: heightDiff - 12 + actualHeight * 0.57
          },
          {
            left: widthDiff + actualWidth * 0.83,
            top: heightDiff - 12 + actualHeight * 0.73
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            right: widthDiff + actualWidth * 0.5 - 100
          },
          {
            right: widthDiff + actualWidth * 0.83,
            top: heightDiff - 12 + actualHeight * 0.73
          },
          {
            right: widthDiff + actualWidth * 0.85,
            top: heightDiff - 12 + actualHeight * 0.57
          },
          {
            right: widthDiff + actualWidth * 1.02,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            right: widthDiff + actualWidth * 0.85,
            bottom: heightDiff - 12 + actualHeight * 0.57
          },
          { bottom: heightDiff + actualHeight * 0.7, right: widthDiff + actualWidth * 0.83 }
        ]
      };
    }
    case 'Rectilinear_shape3_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape3_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            minWidth: actualWidth * 0.45,
            left: widthDiff
          },
          { bottom: heightDiff - 12 + actualHeight * 0.55, left: widthDiff + actualWidth * 0.44 },
          {
            minWidth: 200,
            left: widthDiff + actualWidth * 0.55 - 100,
            bottom: heightDiff - 12 + actualHeight * 0.2
          },
          {
            right: widthDiff + actualWidth * 0.32,
            bottom: heightDiff + actualHeight * 0.33
          },
          {
            minWidth: actualWidth * 0.3,
            right: widthDiff,
            bottom: heightDiff - 12 + actualHeight * 0.68
          },
          {
            left: widthDiff + actualWidth * 1.02,
            top: heightDiff - 30 + actualHeight * 0.6
          },
          {
            minWidth: 200,
            right: widthDiff + actualWidth * 0.5 - 100,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.02,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape4_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape4_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.5 - 100
          },
          { top: heightDiff - 30 + actualHeight * 0.25, left: widthDiff + actualWidth * 1.02 },
          {
            right: widthDiff + actualWidth * 0.1,
            top: heightDiff - 12 + actualHeight * 0.53
          },
          {
            right: widthDiff + actualWidth * 0.32,
            top: heightDiff - 12 + actualHeight * 0.33
          },
          {
            minWidth: 200,
            left: widthDiff + actualWidth * 0.55 - 100,
            top: heightDiff - 12 + actualHeight * 0.23
          },
          {
            left: widthDiff + actualWidth * 0.43,
            bottom: heightDiff + actualHeight * 0.25
          },
          {
            left: widthDiff + actualWidth * 0.15,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.02,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape5_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape5_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.65,
            left: widthDiff - 100 + actualWidth * 0.4,
            minWidth: 200
          },
          {
            right: widthDiff + actualWidth * 0.28,
            bottom: heightDiff - 30 + actualHeight * 0.8
          },
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            right: widthDiff,
            minWidth: actualWidth * 0.25
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            right: widthDiff + actualWidth * 0.5 - 100
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 12 + actualHeight * 0.25
          }
        ]
      };
    }
    case 'Rectilinear_shape6_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape6_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.5 - 100
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.8
          },
          {
            top: heightDiff - 12 + actualHeight * 0.38,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.6 - 100
          },
          {
            left: widthDiff + actualWidth * 0.3,
            bottom: heightDiff - 30 + actualHeight * 0.3
          },
          {
            minWidth: actualWidth * 0.27,
            left: widthDiff,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape7_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape7_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff - 12 + actualHeight * 0.86, right: widthDiff + actualWidth * 0.65 },
          {
            right: widthDiff + actualWidth * 0.43,
            bottom: heightDiff - 12 + actualHeight * 0.88
          },
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            minWidth: actualWidth * 0.4,
            right: widthDiff
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.5 - 100
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.4
          }
        ]
      };
    }
    case 'Rectilinear_shape8_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape8_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.23,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.4 - 100
          },
          {
            right: widthDiff + actualWidth * 0.28,
            bottom: heightDiff - 30 + actualHeight * 0.65
          },
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            right: widthDiff,
            minWidth: actualWidth * 0.27
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.5 - 100
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 12 + actualHeight * 0.08
          }
        ]
      };
    }
    case 'Rectilinear_shape9_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape9_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.3,
            left: widthDiff,
            minWidth: actualWidth * 0.5
          },
          {
            right: widthDiff + actualWidth * 0.55,
            bottom: heightDiff - 30 + actualHeight * 0.7
          },
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            right: widthDiff,
            minWidth: actualWidth * 0.5
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.5 - 100
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.15
          }
        ]
      };
    }
    case 'Rectilinear_shape10_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape10_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.5 - 100
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.75
          },
          {
            top: heightDiff - 12 + actualHeight * 0.52,
            minWidth: 200,
            left: widthDiff + actualWidth * 0.6 - 100
          },
          {
            left: widthDiff + actualWidth * 0.33,
            top: heightDiff - 12 + actualHeight * 0.7
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.3,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape11_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape11_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff,
            minWidth: actualWidth * 0.45
          },
          {
            left: widthDiff + actualWidth * 0.47,
            bottom: heightDiff - 30 + actualHeight * 0.6
          },
          {
            bottom: heightDiff - 12 + actualHeight * 0.31,
            left: widthDiff + actualWidth * 0.7 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.15
          },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape12_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape12_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            right: widthDiff,
            minWidth: actualWidth * 0.43
          },
          {
            right: widthDiff + actualWidth * 0.43,
            bottom: heightDiff - 30 + actualHeight * 0.4
          },
          {
            left: widthDiff + actualWidth * 0.4 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.2
          },
          {
            left: widthDiff + actualWidth * 0.23,
            bottom: heightDiff - 30 + actualHeight * 0.55
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.23,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape13_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape13_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.8
          },
          {
            top: heightDiff - 12 + actualHeight * 0.43,
            left: widthDiff + actualWidth * 0.6 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 0.33,
            bottom: heightDiff - 30 + actualHeight * 0.3
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.3,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape14_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape14_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff,
            minWidth: actualWidth * 0.32
          },
          {
            left: widthDiff + actualWidth * 0.33,
            bottom: heightDiff - 30 + actualHeight * 0.7
          },
          {
            bottom: heightDiff - 12 + actualHeight * 0.38,
            left: widthDiff + actualWidth * 0.65 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.2
          },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape15_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape15_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            right: widthDiff,
            minWidth: actualWidth * 0.32
          },
          {
            right: widthDiff + actualWidth * 0.32,
            bottom: heightDiff - 30 + actualHeight * 0.16
          },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.52
          },
          {
            left: widthDiff + actualWidth * 0.32,
            bottom: heightDiff - 30 + actualHeight * 0.26
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff,
            minWidth: actualWidth * 0.32
          },
          {
            right: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape16_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape16_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.75
          },
          {
            top: heightDiff - 12 + actualHeight * 0.47,
            left: widthDiff + actualWidth * 0.63 - 100,
            minWidth: 200
          },
          { top: heightDiff - 30 + actualHeight * 0.7, left: widthDiff + actualWidth * 0.28 },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.27,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape17_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape17_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            right: widthDiff,
            minWidth: actualWidth * 0.45
          },
          { top: heightDiff - 30 + actualHeight * 0.65, right: widthDiff + actualWidth * 0.46 },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.55,
            top: heightDiff - 12 + actualHeight * 0.23
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.13
          }
        ]
      };
    }
    case 'Rectilinear_shape18_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape18_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.87
          },
          {
            top: heightDiff - 12 + actualHeight * 0.24,
            left: widthDiff + actualWidth * 0.65 - 100,
            minWidth: 200
          },
          { top: heightDiff - 30 + actualHeight * 0.6, left: widthDiff + actualWidth * 0.35 },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.35,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape19_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape19_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff,
            minWidth: actualWidth * 0.32
          },
          {
            left: widthDiff + actualWidth * 0.32,
            bottom: heightDiff - 30 + actualHeight * 0.77
          },
          {
            bottom: heightDiff - 12 + actualHeight * 0.52,
            left: widthDiff + actualWidth * 0.65 - 100,
            minWidth: 200
          },
          { top: heightDiff - 30 + actualHeight * 0.73, left: widthDiff + actualWidth * 1.01 },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape20_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape20_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.83
          },
          {
            top: heightDiff - 12 + actualHeight * 0.33,
            left: widthDiff + actualWidth * 0.65 - 100,
            minWidth: 200
          },
          { top: heightDiff - 30 + actualHeight * 0.7, left: widthDiff + actualWidth * 0.36 },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.35,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape21_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape21_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.35 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.4
          },
          {
            right: widthDiff + actualWidth * 0.36,
            top: heightDiff - 30 + actualHeight * 0.3
          },
          {
            bottom: heightDiff - 12 + actualHeight * 0.99,
            right: widthDiff,
            minWidth: actualWidth * 0.33
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.5
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          { top: heightDiff - 30 + actualHeight * 0.8, right: widthDiff + actualWidth * 1.01 }
        ]
      };
    }
    case 'Rectilinear_shape22_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape22_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            left: widthDiff + actualWidth * 1.01,
            bottom: heightDiff - 30 + actualHeight * 0.85
          },
          {
            top: heightDiff - 12 + actualHeight * 0.32,
            left: widthDiff + actualWidth * 0.65 - 100,
            minWidth: 200
          },
          { top: heightDiff - 30 + actualHeight * 0.65, left: widthDiff + actualWidth * 0.36 },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.35,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape23_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape23_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff,
            minWidth: actualWidth * 0.29,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 12 + actualHeight * 0.6, left: widthDiff + actualWidth * 0.29 },
          {
            bottom: heightDiff - 12 + actualHeight * 0.38,
            left: widthDiff + actualWidth * 0.63 - 100,
            minWidth: 200
          },
          { top: heightDiff - 30 + actualHeight * 0.8, left: widthDiff + actualWidth * 1.01 },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape24_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape24_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff,
            minWidth: actualWidth * 0.48,
            bottom: heightDiff - 12 + actualHeight * 0.39
          },
          {
            right: widthDiff + actualWidth * 0.6,
            top: heightDiff - 30 + actualHeight * 0.3
          },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.58,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.5, left: widthDiff + actualWidth * 1.01 },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.8
          }
        ]
      };
    }
    case 'Rectilinear_shape25_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape25_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.63, left: widthDiff + actualWidth * 1.01 },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.51,
            top: heightDiff - 12 + actualHeight * 0.73
          },
          {
            left: widthDiff + actualWidth * 0.57,
            top: heightDiff - 12 + actualHeight * 0.82
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.55,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape26_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape26_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.5, left: widthDiff + actualWidth * 1.01 },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.68,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 0.69,
            top: heightDiff - 30 + actualHeight * 0.85
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.38,
            top: heightDiff - 12 + actualHeight * 0.64
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.3
          }
        ]
      };
    }
    case 'Rectilinear_shape27_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape27_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            bottom: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff,
            minWidth: actualWidth * 0.44
          },
          { bottom: heightDiff - 30 + actualHeight * 0.87, left: widthDiff + actualWidth * 0.45 },
          {
            left: widthDiff + actualWidth * 0.68 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.72
          },
          {
            left: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.6
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape28_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape28_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff,
            minWidth: actualWidth * 0.3,
            bottom: heightDiff - 12 + actualHeight * 0.71
          },
          { bottom: heightDiff - 12 + actualHeight * 0.81, right: widthDiff + actualWidth * 0.75 },
          {
            left: widthDiff + actualWidth * 0.63 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          {
            left: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 12 + actualHeight * 0.53
          }
        ]
      };
    }
    case 'Rectilinear_shape29_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape29_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff,
            minWidth: actualWidth * 0.35,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.83, left: widthDiff + actualWidth * 0.36 },
          {
            left: widthDiff + actualWidth * 0.65 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.6
          },
          {
            left: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.68
          },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape30_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape30_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.5, left: widthDiff + actualWidth * 1.01 },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.65,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 0.63,
            top: heightDiff - 30 + actualHeight * 0.87
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.42,
            top: heightDiff - 30 + actualHeight * 0.74
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 12 + actualHeight * 0.3
          }
        ]
      };
    }
    case 'Rectilinear_shape31_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape31_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.5, left: widthDiff + actualWidth * 1.01 },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.48,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 0.5,
            top: heightDiff - 12 + actualHeight * 0.8
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.55,
            top: heightDiff - 12 + actualHeight * 0.68
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.35
          }
        ]
      };
    }
    case 'Rectilinear_shape32_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape32_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.5, left: widthDiff + actualWidth * 1.01 },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.32,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 0.32,
            top: heightDiff - 30 + actualHeight * 0.75
          },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 30 + actualHeight * 0.49
          },
          {
            left: widthDiff + actualWidth * 0.33,
            top: heightDiff - 30 + actualHeight * 0.63
          },
          {
            top: heightDiff - 12 + actualHeight * 0.98,
            left: widthDiff,
            minWidth: actualWidth * 0.32
          },
          { bottom: heightDiff - 30 + actualHeight * 0.5, right: widthDiff + actualWidth * 1.01 }
        ]
      };
    }
    case 'Rectilinear_shape33_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape33_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { top: heightDiff - 30 + actualHeight * 0.18, left: widthDiff + actualWidth * 1.01 },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.47,
            top: heightDiff - 12 + actualHeight * 0.35
          },
          {
            left: widthDiff + actualWidth * 0.6,
            top: heightDiff - 12 + actualHeight * 0.6
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.59,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape34_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape34_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.7, left: widthDiff + actualWidth * 1.01 },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.48,
            top: heightDiff - 12 + actualHeight * 0.68
          },
          {
            left: widthDiff + actualWidth * 0.6,
            top: heightDiff - 12 + actualHeight * 0.81
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.59,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape35_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape35_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.65, left: widthDiff + actualWidth * 1.01 },
          {
            left: widthDiff + actualWidth * 0.67 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.7
          },
          {
            left: widthDiff + actualWidth * 0.4,
            top: heightDiff - 12 + actualHeight * 0.8
          },
          {
            left: widthDiff,
            minWidth: actualWidth * 0.39,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape36_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape36_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.88, left: widthDiff + actualWidth * 1.01 },
          {
            left: widthDiff + actualWidth * 0.54,
            minWidth: actualWidth * 0.44,
            top: heightDiff - 12 + actualHeight * 0.23
          },
          {
            left: widthDiff + actualWidth * 0.59,
            top: heightDiff - 30 + actualHeight * 0.6
          },
          {
            left: widthDiff + actualWidth * 0.15,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Rectilinear_shape37_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape37_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff,
            minWidth: actualWidth * 0.44,
            bottom: heightDiff - 12 + actualHeight * 0.24
          },
          {
            right: widthDiff + actualWidth * 0.67,
            top: heightDiff - 12 + actualHeight * 0.3
          },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.63,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.5, left: widthDiff + actualWidth * 1.02 },
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.87
          }
        ]
      };
    }
    case 'Rectilinear_shape38_all_arrows': {
      const colour = getRandomFromArray(['green', 'yellow', 'blue', 'purple', 'pink'], seeded);

      const svgName = `Shapes_with_arrows/Rectilinear_shape38_all_arrows_${colour}` as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          {
            left: widthDiff + actualWidth * 0.5 - 100,
            minWidth: 200,
            bottom: heightDiff - 12 + actualHeight * 0.98
          },
          { bottom: heightDiff - 30 + actualHeight * 0.5, left: widthDiff + actualWidth * 1.01 },
          {
            right: widthDiff,
            minWidth: actualWidth * 0.4,
            top: heightDiff - 12 + actualHeight * 0.98
          },
          {
            right: widthDiff + actualWidth * 0.42,
            top: heightDiff - 12 + actualHeight * 0.7
          },
          {
            left: widthDiff + actualWidth * 0.34 - 100,
            minWidth: 200,
            top: heightDiff - 12 + actualHeight * 0.52
          },
          {
            right: widthDiff + actualWidth * 1.01,
            top: heightDiff - 30 + actualHeight * 0.28
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Right_angle_triangle1_2_arrows': {
      const svgName = 'Shapes_with_arrows/Right_angle_triangle1_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff - actualHeight * 0.15, right: widthDiff + actualWidth * 0.45 },
          {
            left: widthDiff - actualWidth * 0.15,
            bottom: heightDiff + actualHeight * 0.42
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Right_angle_triangle2_2_arrows': {
      const svgName = 'Shapes_with_arrows/Right_angle_triangle2_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff + actualHeight * 0.72, right: widthDiff + actualWidth * 0.36 },
          {
            left: widthDiff - actualWidth * 0.02,
            bottom: heightDiff + actualHeight * 0.42
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Right_angle_triangle3_2_arrows': {
      const svgName = 'Shapes_with_arrows/Right_angle_triangle3_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff - actualHeight * 0.15, right: widthDiff + actualWidth * 0.45 },
          {
            left: widthDiff - actualWidth * 0.16,
            bottom: heightDiff + actualHeight * 0.42
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Right_angle_triangle4_2_arrows': {
      const svgName = 'Shapes_with_arrows/Right_angle_triangle4_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff - actualHeight * 0.15, right: widthDiff + actualWidth * 0.45 },
          {
            left: widthDiff - actualWidth * 0.14,
            bottom: heightDiff + actualHeight * 0.42
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Right_angle_triangle6_2_arrows': {
      const svgName = 'Shapes_with_arrows/Right_angle_triangle6_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff + actualHeight * 0.5, right: widthDiff - actualWidth * 0.05 },
          {
            left: widthDiff + actualWidth * 0.2,
            top: heightDiff - actualHeight * 0.08
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle2_2_arrows': {
      const svgName = 'Shapes_with_arrows/Triangle2_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff + actualHeight * 0.4, left: widthDiff + actualWidth * 0.28 },
          {
            left: widthDiff + actualWidth * 0.38,
            bottom: heightDiff - actualHeight * 0.15
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle3_2_arrows': {
      const svgName = 'Shapes_with_arrows/Triangle3_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff + actualHeight * 0.4, left: widthDiff + actualWidth * 0.46 },
          {
            left: widthDiff - actualWidth * 0.03,
            bottom: heightDiff + actualHeight * 0.42
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle4_2_arrows': {
      const svgName = 'Shapes_with_arrows/Triangle4_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff + actualHeight * 0.4, left: widthDiff + actualWidth * 0.22 },
          {
            left: widthDiff + actualWidth * 0.38,
            bottom: heightDiff - actualHeight * 0.15
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle5_2_arrows': {
      const svgName = 'Shapes_with_arrows/Triangle5_2_arrows';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          { bottom: heightDiff + actualHeight * 0.51, left: widthDiff + actualWidth * 0.46 },
          {
            left: widthDiff - actualWidth * 0.07,
            bottom: heightDiff + actualHeight * 0.42
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle1_3_arrows_right_angle':
    case 'Shapes_with_arrows/Triangle3_3_arrows_right_angle':
    case 'Shapes_with_arrows/Triangle1_4_arrows_right_angle':
    case 'Shapes_with_arrows/Triangle3_4_arrows_right_angle': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // height
          { bottom: heightDiff + actualHeight * 0.4, left: widthDiff + actualWidth * 0.3 },
          // base
          {
            left: widthDiff + actualWidth * 0.4,
            top: heightDiff + actualHeight
          },
          // edge 1
          {
            left: widthDiff + actualWidth * 0.75,
            bottom: heightDiff + actualHeight * 0.4
          },
          // edge 2
          {
            right: widthDiff + actualWidth * 0.9,
            bottom: heightDiff + actualHeight * 0.4
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle2_3_arrows_right_angle':
    case 'Shapes_with_arrows/Triangle2_4_arrows_right_angle': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // height
          { bottom: heightDiff + actualHeight * 0.3, left: widthDiff + actualWidth * 0.25 },
          // base
          {
            left: widthDiff + actualWidth * 0.65,
            bottom: heightDiff + actualHeight * 0.5
          },
          // edge 1
          {
            left: widthDiff + actualWidth * 0.4,
            top: heightDiff + actualHeight * 0.95
          },
          // edge 2
          {
            right: widthDiff + actualWidth * 0.95,
            bottom: heightDiff + actualHeight * 0.4
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle4_3_arrows_right_angle':
    case 'Shapes_with_arrows/Triangle4_4_arrows_right_angle': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // height
          { bottom: heightDiff + actualHeight * 0.25, left: widthDiff + actualWidth * 0.3 },
          // base
          {
            left: widthDiff + actualWidth * 0.75,
            bottom: heightDiff + actualHeight * 0.45
          },
          // edge 1
          {
            left: widthDiff + actualWidth * 0.4,
            top: heightDiff + actualHeight
          },
          // edge 2
          {
            right: widthDiff + actualWidth * 0.9,
            bottom: heightDiff + actualHeight * 0.4
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle6_3_arrows_right_angle':
    case 'Shapes_with_arrows/Triangle6_4_arrows_right_angle': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // height
          { top: heightDiff + actualHeight * 0.35, right: widthDiff + actualWidth * 0.5 },
          // base
          {
            right: widthDiff + actualWidth * 0.9,
            top: heightDiff + actualHeight * 0.35
          },
          // edge 1
          {
            left: widthDiff + actualWidth * 0.55,
            top: heightDiff + actualHeight * 0.78
          },
          // edge 2
          {
            left: widthDiff + actualWidth * 0.55,
            bottom: heightDiff + actualHeight * 0.75
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle7_3_arrows_right_angle':
    case 'Shapes_with_arrows/Triangle7_4_arrows_right_angle': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // height
          { top: heightDiff + actualHeight * 0.5, right: widthDiff + actualWidth * 0.5 },
          // base
          {
            left: widthDiff + actualWidth * 0.6,
            bottom: heightDiff + actualHeight * 0.7
          },
          // edge
          {
            left: widthDiff + actualWidth * 0.55,
            top: heightDiff + actualHeight * 0.75
          },
          // edge 2
          {
            right: widthDiff + actualWidth,
            bottom: heightDiff + actualHeight * 0.5
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Triangle9_3_arrows_right_angle':
    case 'Shapes_with_arrows/Triangle9_4_arrows_right_angle': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // height
          { top: heightDiff + actualHeight * 0.55, right: widthDiff + actualWidth * 0.45 },
          // base
          {
            left: widthDiff + actualWidth * 0.6,
            bottom: heightDiff + actualHeight * 0.55
          },
          // edge 1
          {
            left: widthDiff + actualWidth * 0.6,
            top: heightDiff + actualHeight * 0.85
          },
          // edge 2
          {
            right: widthDiff + actualWidth * 0.85,
            bottom: heightDiff + actualHeight * 0.35
          }
        ]
      };
    }
    case 'triangle_equal_1_arrow': {
      const svg = 'Shapes_with_dimension_arrows/triangle_equal_1_arrow';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // left
          { top: heightDiff + actualHeight * 0.35, right: widthDiff + actualWidth * 0.8 }
        ]
      };
    }
    case 'Square_1_arrow': {
      const svg = 'Square/square_1_arrow';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // left
          { top: heightDiff + actualHeight * 0.45, right: widthDiff + actualWidth }
        ]
      };
    }
    case 'Pentagon_1_arrow': {
      const svg = 'Pentagons/pentagon_1_arrow';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // bottom
          { top: heightDiff + actualHeight * 0.95, left: widthDiff + actualWidth * 0.43 }
        ]
      };
    }
    case 'Hexagon_1_arrow': {
      const svg = 'Hexagons/hexagon_1_arrow';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // bottom
          { top: heightDiff + actualHeight * 0.95, left: widthDiff + actualWidth * 0.43 }
        ]
      };
    }
    case 'Heptagon_1_arrow': {
      const svg = 'Other_shapes/Regular_heptagon_1_arrow';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // bottom
          { top: heightDiff + actualHeight * 0.95, left: widthDiff + actualWidth * 0.43 }
        ]
      };
    }
    case 'Octagon_1_arrow': {
      const svg = 'Octagons/Regular_octagon_1_arrow';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // right
          { top: heightDiff + actualHeight * 0.43, left: widthDiff + actualWidth }
        ]
      };
    }
    case 'Nonagon_1_arrow': {
      const svg = 'Nonagons/Regular_nonagon_1_arrow';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // right
          { top: heightDiff + actualHeight * 0.55, left: widthDiff + actualWidth * 0.85 }
        ]
      };
    }
    case 'Decagon_1_arrow': {
      const svg = 'Other_shapes/Regular_decagon_1_arrow';
      const svgName: SvgName =
        color && ['pink', 'blue', 'green', 'purple', 'yellow'].includes(color)
          ? `${svg}_${color}`
          : svg;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // right
          { top: heightDiff + actualHeight * 0.85, left: widthDiff + actualWidth * 0.85 }
        ]
      };
    }
    case 'Circles/Inner_outer_circles/Inner_outer_circle_medium_A': {
      const svgName = 'Circles/Inner_outer_circles/Inner_outer_circle_medium_A' as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Inner radius label
          {
            left: widthDiff + actualWidth * 0.45,
            top: isPDF ? heightDiff + actualHeight * 0.3 : heightDiff + actualHeight * 0.28
          },
          // Outer radius label
          {
            left: widthDiff + actualWidth * 0.79,
            bottom: isPDF ? heightDiff + actualHeight * 0.5 : heightDiff + actualHeight * 0.47
          }
        ]
      };
    }
    case 'Circles/Inner_outer_circles/Inner_outer_circle_medium_B': {
      const svgName = 'Circles/Inner_outer_circles/Inner_outer_circle_medium_B' as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Inner radius label
          {
            left: widthDiff + actualWidth * 0.28,
            top: isPDF ? heightDiff + actualHeight * 0.37 : heightDiff + actualHeight * 0.35
          },
          // Outer radius label
          {
            left: widthDiff + actualWidth * 0.79,
            bottom: isPDF ? heightDiff + actualHeight * 0.3 : heightDiff + actualHeight * 0.27
          }
        ]
      };
    }
    case 'Circles/Inner_outer_circles/Inner_outer_circle_medium_C': {
      const svgName = 'Circles/Inner_outer_circles/Inner_outer_circle_medium_C' as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Inner radius label
          {
            left: widthDiff + actualWidth * 0.28,
            top: isPDF ? heightDiff + actualHeight * 0.36 : heightDiff + actualHeight * 0.34
          },
          // Outer radius label
          {
            left: widthDiff + actualWidth * 0.75,
            bottom: isPDF ? heightDiff + actualHeight * 0.22 : heightDiff + actualHeight * 0.2
          }
        ]
      };
    }
    case 'Circles/Inner_outer_circles/Inner_outer_circle_large_A': {
      const svgName = 'Circles/Inner_outer_circles/Inner_outer_circle_large_A' as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Inner radius label
          {
            left: widthDiff + actualWidth * 0.23,
            top: isPDF ? heightDiff + actualHeight * 0.36 : heightDiff + actualHeight * 0.34
          },
          // Outer radius label
          {
            left: widthDiff + actualWidth * 0.54,
            top: isPDF ? heightDiff : heightDiff + actualHeight * -0.02
          }
        ]
      };
    }
    case 'Circles/Inner_outer_circles/Inner_outer_circle_large_B': {
      const svgName = 'Circles/Inner_outer_circles/Inner_outer_circle_large_B' as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Inner radius label
          {
            left: widthDiff + actualWidth * 0.21,
            bottom: heightDiff + actualHeight * 0.36
          },
          // Outer radius label
          {
            left: widthDiff + actualWidth * 0.54,
            top: isPDF ? heightDiff : heightDiff + actualHeight * -0.02
          }
        ]
      };
    }
    case 'Circles/Inner_outer_circles/Inner_outer_circle_large_C': {
      const svgName = 'Circles/Inner_outer_circles/Inner_outer_circle_large_C' as SvgName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Inner radius label
          {
            left: widthDiff + actualWidth * 0.63,
            top: isPDF ? heightDiff + actualHeight * 0.34 : heightDiff + actualHeight * 0.32
          },
          // Outer radius label
          {
            left: widthDiff + actualWidth * 0.53,
            bottom: isPDF ? heightDiff + actualHeight * 0.01 : heightDiff + actualHeight * -0.01
          }
        ]
      };
    }
    case 'Scalene_triangle1': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_triangle1';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top angle on extending lines
          {
            top: heightDiff + actualHeight * 0.15,
            left: widthDiff + actualWidth * 0.3
          },
          // Bottom left angle inside triangle
          {
            left: widthDiff + actualWidth * 0.25,
            bottom: heightDiff + actualHeight * 0.01
          },
          // Top middle angle inside triangle
          {
            left: widthDiff + actualWidth * 0.35,
            top: heightDiff + actualHeight * 0.5
          },
          // Bottom right angle inside triangle
          {
            right: widthDiff + actualWidth * 0.15,
            bottom: heightDiff + actualHeight * 0.01
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_triangle2': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_triangle2';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top angle on extending lines
          {
            top: heightDiff + actualHeight * 0.05,
            left: widthDiff + actualWidth * 0.65
          },
          // Bottom left angle inside triangle
          {
            left: widthDiff + actualWidth * 0.22,
            bottom: heightDiff + actualHeight * 0.01
          },
          // Top middle angle inside triangle
          {
            top: heightDiff + actualHeight * 0.55,
            left: widthDiff + actualWidth * 0.55
          },
          // Bottom right angle inside triangle
          {
            right: widthDiff + actualWidth * 0.2,
            bottom: heightDiff + actualHeight * 0.01
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_triangle3': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_triangle3';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top angle on extending lines
          {
            top: heightDiff + actualHeight * 0.08,
            left: widthDiff + actualWidth * 0.55
          },
          // Bottom left angle inside triangle
          {
            left: widthDiff + actualWidth * 0.18,
            bottom: heightDiff + actualHeight * 0.01
          },
          // Top middle angle inside triangle
          {
            top: heightDiff + actualHeight * 0.45,
            left: widthDiff + actualWidth * 0.5
          },
          // Bottom right angle inside triangle
          {
            right: widthDiff + actualWidth * 0.12,
            bottom: heightDiff + actualHeight * 0.01
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_triangle4': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_triangle4';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top angle on extending lines
          {
            bottom: heightDiff + actualHeight * 0.88,
            left: widthDiff + actualWidth * 0.17
          },
          // Bottom left angle inside triangle
          {
            left: widthDiff + actualWidth * 0.15,
            bottom: heightDiff + actualHeight * 0.01
          },
          // Top middle angle inside triangle
          {
            top: heightDiff + actualHeight * 0.35,
            left: widthDiff + actualWidth * 0.25
          },
          // Bottom right angle inside triangle
          {
            right: widthDiff + actualWidth * 0.15,
            bottom: heightDiff + actualHeight * 0.01
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_triangle5': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_triangle5';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Bottom left angle on extending lines
          {
            right: widthDiff + actualWidth * 0.9,
            bottom: heightDiff + actualHeight * 0.06
          },
          // Top angle on extending lines
          {
            top: heightDiff + actualHeight * 0.02,
            left: widthDiff + actualWidth * 0.27
          },
          // Bottom right angle on extending lines
          {
            left: widthDiff + actualWidth * 0.9,
            bottom: heightDiff + actualHeight * 0.06
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_triangle6': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_triangle6';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Bottom left angle on extending lines
          {
            left: widthDiff + actualWidth * 0.12,
            bottom: heightDiff + actualHeight * 0.2
          },
          // Top angle on extending lines
          {
            bottom: heightDiff + actualHeight * 0.82,
            right: widthDiff + actualWidth * 0.2
          },
          // Bottom right angle on extending lines
          {
            right: widthDiff + actualWidth * 0.05,
            bottom: heightDiff + actualHeight * 0.2
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_triangle7': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_triangle7';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Bottom left angle on extending lines
          {
            right: widthDiff + actualWidth * 0.85,
            bottom: heightDiff + actualHeight * 0.06
          },
          // Top angle on extending lines
          {
            bottom: heightDiff + actualHeight * 0.85,
            left: widthDiff + actualWidth * 0.21
          },
          // Bottom right angle on extending lines
          {
            left: widthDiff + actualWidth * 0.75,
            bottom: heightDiff + actualHeight * 0.06
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_triangle8': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_triangle8';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Bottom left angle on extending lines
          {
            left: widthDiff + actualWidth * 0.05,
            bottom: heightDiff + actualHeight * 0.2
          },
          // Top angle on extending lines
          {
            bottom: heightDiff + actualHeight * 0.83,
            left: widthDiff + actualWidth * 0.22
          },
          // Bottom right angle on extending lines
          {
            right: widthDiff + actualWidth * 0.05,
            bottom: heightDiff + actualHeight * 0.2
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_Isosceles_joined_triangle1': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle1';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // bottom left angle on extending lines
          {
            top: heightDiff + actualHeight * 0.32,
            left: widthDiff + actualWidth * 0.09
          },
          // Top middle angle inside triangle
          {
            left: widthDiff + actualWidth * 0.48,
            top: heightDiff + actualHeight * 0.24
          },
          // Bottom right angle inside triangle
          {
            right: widthDiff + actualWidth * 0.12,
            top: heightDiff + actualHeight * 0.41
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_Isosceles_joined_triangle2': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle2';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Bottom left angle inside triangle
          {
            top: heightDiff + actualHeight * 0.41,
            left: widthDiff + actualWidth * 0.12
          },
          // Top middle angle inside triangle
          {
            left: widthDiff + actualWidth * 0.43,
            top: heightDiff + actualHeight * 0.25
          },
          // Bottom right angle inside triangle
          {
            right: widthDiff + actualWidth * 0.1,
            bottom: heightDiff + actualHeight * 0.5
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_Isosceles_joined_triangle3': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle3';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top left angle inside triangle
          {
            left: widthDiff + actualWidth * 0.12,
            top: heightDiff + actualHeight * 0.4
          },
          // Bottom middle angle inside triangle
          {
            left: widthDiff + actualWidth * 0.42,
            bottom: heightDiff + actualHeight * 0.27
          },
          // Top right angle on extending line
          {
            right: widthDiff + actualWidth * 0.1,
            bottom: heightDiff + actualHeight * 0.3
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Scalene_Isosceles_joined_triangle4': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle4';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top left angle on extending line
          {
            left: widthDiff + actualWidth * 0.15,
            bottom: heightDiff + actualHeight * 0.3
          },
          // Bottom middle angle inside triangle
          {
            left: widthDiff + actualWidth * 0.48,
            bottom: heightDiff + actualHeight * 0.25
          },
          // Top right angle inside triangle
          {
            right: widthDiff + actualWidth * 0.1,
            bottom: heightDiff + actualHeight * 0.42
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Isosceles_triangle1': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Isosceles_triangle1';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top angle on extending lines
          {
            bottom: heightDiff + actualHeight * 0.96,
            left: widthDiff + actualWidth * 0.42
          },
          // Bottom left angle inside triangle
          {
            left: widthDiff + actualWidth * 0.2,
            bottom: heightDiff + actualHeight * 0.03
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Isosceles_triangle2': {
      const svgName = 'Angles_in_a_triangle_multiple_lines/Isosceles_triangle2';
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [
          // Top angle on extending lines
          {
            bottom: heightDiff + actualHeight * 0.96,
            left: widthDiff + actualWidth * 0.42
          },
          // Bottom right angle inside triangle
          {
            right: widthDiff + actualWidth * 0.2,
            bottom: heightDiff + actualHeight * 0.03
          }
        ],
        sideLabelPositions: []
      };
    }
    case 'Shapes_with_dimension_arrows/triangle_scalene_purple_arrows':
    case 'Shapes_with_dimension_arrows/triangle_scalene_blue_arrows':
    case 'Shapes_with_dimension_arrows/triangle_scalene_green_arrows':
    case 'Shapes_with_dimension_arrows/triangle_scalene_orange_arrows':
    case 'Shapes_with_dimension_arrows/triangle_scalene_red_arrows': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Right label
          {
            top: heightDiff + actualHeight * 0.05,
            left: widthDiff + actualWidth * 0.6
          },
          // Bottom label
          {
            left: widthDiff + actualWidth * 0.45,
            top: heightDiff + actualHeight * 0.95
          },
          // Left label
          {
            top: heightDiff + actualHeight * 0.15,
            right: widthDiff + actualWidth * 0.9
          }
        ]
      };
    }

    case 'Shapes_with_dimension_arrows/triangle_RA_purple_arrows':
    case 'Shapes_with_dimension_arrows/triangle_RA_blue_arrows':
    case 'Shapes_with_dimension_arrows/triangle_RA_green_arrows':
    case 'Shapes_with_dimension_arrows/triangle_RA_orange_arrows':
    case 'Shapes_with_dimension_arrows/triangle_RA_red_arrows': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Right label
          {
            top: heightDiff + actualHeight * 0.4,
            left: widthDiff + actualWidth
          },
          // Bottom label
          {
            left: widthDiff + actualWidth * 0.4,
            bottom: -heightDiff
          },
          // Left label
          {
            top: heightDiff + actualHeight * 0.25,
            left: widthDiff + actualWidth * 0.01
          }
        ]
      };
    }

    case 'Shapes_with_dimension_arrows/triangle_RA2_purple_arrows':
    case 'Shapes_with_dimension_arrows/triangle_RA2_blue_arrows':
    case 'Shapes_with_dimension_arrows/triangle_RA2_green_arrows':
    case 'Shapes_with_dimension_arrows/triangle_RA2_orange_arrows':
    case 'Shapes_with_dimension_arrows/triangle_RA2_red_arrows': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Right label
          {
            top: heightDiff + actualHeight * 0.4,
            left: widthDiff + actualWidth
          },
          // Bottom label
          {
            left: widthDiff + actualWidth * 0.45,
            bottom: -heightDiff
          },
          // Left label
          {
            top: heightDiff + actualHeight * 0.25,
            left: widthDiff + actualWidth * 0.25
          }
        ]
      };
    }

    case 'Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows':
    case 'Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows':
    case 'Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            bottom: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.45
          },
          // Right label
          {
            left: widthDiff + actualWidth * 0.9,
            top: heightDiff + actualHeight * 0.4
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.4
          },
          // Left label
          {
            left: widthDiff - actualWidth * 0.1,
            top: heightDiff + actualHeight * 0.4
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top right label
          {
            bottom: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth * 0.8
          },
          // Right bottom label
          {
            left: widthDiff + actualWidth * 0.95,
            top: heightDiff + actualHeight * 0.6
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.4
          },
          // Left bottom label
          {
            left: widthDiff - actualWidth * 0.1,
            top: heightDiff + actualHeight * 0.6
          },
          // Top left label
          {
            bottom: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth * 0.05
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            top: heightDiff + actualHeight * 0.02,
            left: widthDiff + actualWidth * 0.25
          },
          // Right top label
          {
            left: widthDiff + actualWidth * 0.8,
            top: heightDiff + actualHeight * 0.15
          },
          // Right top bottom
          {
            left: widthDiff + actualWidth * 0.9,
            top: heightDiff + actualHeight * 0.6
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.9,
            left: widthDiff + actualWidth * 0.4
          },
          // Left label
          {
            left: widthDiff - actualWidth * 0.1,
            top: heightDiff + actualHeight * 0.6
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            bottom: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.35
          },
          // Right top label
          {
            left: widthDiff + actualWidth * 0.92,
            top: heightDiff + actualHeight * 0.05
          },
          // Right top bottom
          {
            left: widthDiff + actualWidth * 0.9,
            top: heightDiff + actualHeight * 0.75
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.35
          },
          // Left label
          {
            right: widthDiff + actualWidth,
            top: heightDiff + actualHeight * 0.45
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top right label
          {
            bottom: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth * 0.6
          },
          // Right label
          {
            top: heightDiff + actualHeight * 0.4,
            left: widthDiff + actualWidth
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.4
          },
          // Left label
          {
            left: widthDiff - actualWidth * 0.15,
            top: heightDiff + actualHeight * 0.4
          },
          // Top left label
          {
            bottom: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth * 0.25
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top right label
          {
            bottom: heightDiff + actualHeight * 0.55,
            left: widthDiff + actualWidth * 0.8
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.98,
            left: widthDiff + actualWidth * 0.85
          },
          // Middle right label
          {
            left: widthDiff + actualWidth * 0.55,
            top: heightDiff + actualHeight * 0.7
          },
          // Middle left
          {
            top: heightDiff + actualHeight * 0.7,
            left: widthDiff + actualWidth * 0.35
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.05
          },
          // Top left label
          {
            bottom: heightDiff + actualHeight * 0.55,
            left: widthDiff + actualWidth * 0.05
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            bottom: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.07
          },
          // vertical
          {
            top: heightDiff + actualHeight * 0.2,
            left: widthDiff + actualWidth * 0.27
          },
          // Middle left label
          {
            left: widthDiff + actualWidth * 0.45,
            bottom: heightDiff + actualHeight * 0.45
          },
          // Middle left
          {
            top: heightDiff + actualHeight * 0.6,
            left: widthDiff + actualWidth * 0.95
          },
          // Bottom
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.45
          },
          // left label
          {
            top: heightDiff + actualHeight * 0.45,
            right: widthDiff + actualWidth
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top right label
          {
            bottom: heightDiff + actualHeight * 0.7,
            left: widthDiff + actualWidth * 0.8
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.7,
            left: widthDiff + actualWidth
          },
          // Middle right label
          {
            left: widthDiff + actualWidth * 0.6,
            top: heightDiff + actualHeight * 0.7
          },
          // Middle left
          {
            top: heightDiff + actualHeight * 0.7,
            left: widthDiff + actualWidth * 0.3
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.7,
            right: widthDiff + actualWidth
          },
          // Top left label
          {
            bottom: heightDiff + actualHeight * 0.7,
            left: widthDiff + actualWidth * 0.05
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            top: -10,
            left: widthDiff + actualWidth * 0.25
          },
          // Top right label
          {
            top: heightDiff + actualHeight * 0.2,
            left: widthDiff + actualWidth * 0.7
          },
          // Middle label
          {
            top: heightDiff + actualHeight * 0.45,
            left: widthDiff + actualWidth * 0.7
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.7,
            left: widthDiff + actualWidth
          },
          // Bottom label
          {
            bottom: -10,
            left: widthDiff + actualWidth * 0.4
          },

          //  left label
          {
            top: heightDiff + actualHeight * 0.4,
            right: widthDiff + actualWidth
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            top: heightDiff - actualHeight * 0.15,
            left: widthDiff + actualWidth * 0.45
          },
          // Top right label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff + actualWidth * 0.9
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.75,
            left: widthDiff + actualWidth * 0.9
          },
          // Bottom label
          {
            bottom: heightDiff - actualHeight * 0.2,
            left: widthDiff + actualWidth * 0.45
          },
          // Bottom left label
          {
            top: heightDiff + actualHeight * 0.75,
            left: widthDiff - actualWidth * 0.1
          },
          // Top left label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff - actualWidth * 0.1
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            bottom: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.45
          },
          // Top right label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff + actualWidth * 0.95
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.2,
            left: widthDiff + actualWidth * 0.45
          },
          // Bottom label
          {
            top: heightDiff + actualHeight * 0.4,
            left: widthDiff + actualWidth * 0.25
          },
          // Bottom left label
          {
            top: heightDiff + actualHeight * 0.9,
            right: widthDiff + actualWidth * 0.75
          },
          // Top left label
          {
            top: heightDiff + actualHeight * 0.4,
            right: widthDiff + actualWidth
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            bottom: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.35
          },
          // right label
          {
            top: heightDiff + actualHeight * 0.45,
            left: widthDiff + actualWidth
          },
          // Bottom  label
          {
            top: heightDiff + actualHeight * 0.95,
            left: widthDiff + actualWidth * 0.45
          },
          // Bottom left label
          {
            top: heightDiff + actualHeight * 0.65,
            right: widthDiff + actualWidth * 0.7
          },
          // left middle label
          {
            top: heightDiff + actualHeight * 0.5,
            right: widthDiff + actualWidth * 0.7
          },
          // Top left label
          {
            top: heightDiff + actualHeight * 0.15,
            right: widthDiff + actualWidth
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_blue':
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_green':
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_pink':
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_purple':
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_yellow': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top left label
          {
            bottom: heightDiff + actualHeight * 0.9,
            left: widthDiff + actualWidth * 0.1
          },
          // middle top label
          {
            bottom: heightDiff + actualHeight * 0.9,
            left: widthDiff + actualWidth * 0.55
          },
          // top right  label
          {
            bottom: heightDiff + actualHeight * 0.8,
            left: widthDiff + actualWidth * 0.8
          },
          // right label
          {
            top: heightDiff + actualHeight * 0.45,
            left: widthDiff + actualWidth * 0.95
          },
          // right bottom label
          {
            top: heightDiff + actualHeight * 0.75,
            right: widthDiff + actualWidth * 0.3
          },
          // Bottom middle label
          {
            top: heightDiff + actualHeight * 0.85,
            right: widthDiff + actualWidth * 0.35
          },
          // Bottom left label
          {
            top: heightDiff + actualHeight * 0.85,
            right: widthDiff + actualWidth * 0.85
          },
          // left label
          {
            top: heightDiff + actualHeight * 0.45,
            right: widthDiff + actualWidth
          }
        ]
      };
    }
    case 'Shapes_with_dimension_arrows/pentagon_green_arrows':
    case 'Shapes_with_dimension_arrows/pentagon_orange_arrows':
    case 'Shapes_with_dimension_arrows/pentagon_blue_arrows':
    case 'Shapes_with_dimension_arrows/pentagon_purple_arrows': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            top: -10,
            left: widthDiff + actualWidth * 0.45
          },
          // Top right label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff + actualWidth * 0.9
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.75,
            left: widthDiff + actualWidth * 0.9
          },
          // Bottom label
          {
            bottom: -10,
            left: widthDiff + actualWidth * 0.45
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.75,
            left: widthDiff
          },
          // Top right label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff
          }
        ]
      };
    }
    case 'Shapes_with_dimension_arrows/hexagon_green_arrows':
    case 'Shapes_with_dimension_arrows/hexagon_blue_arrows':
    case 'Shapes_with_dimension_arrows/hexagon_orange_arrows':
    case 'Shapes_with_dimension_arrows/hexagon_purple_arrows':
    case 'Shapes_with_dimension_arrows/hexagon_red_arrows': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Top label
          {
            top: -10,
            left: widthDiff + actualWidth * 0.45
          },
          // Top right label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff + actualWidth * 0.9
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.75,
            left: widthDiff + actualWidth * 0.9
          },
          // Bottom label
          {
            bottom: -10,
            left: widthDiff + actualWidth * 0.45
          },
          // Bottom right label
          {
            top: heightDiff + actualHeight * 0.75,
            left: widthDiff
          },
          // Top right label
          {
            top: heightDiff + actualHeight * 0.1,
            left: widthDiff
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Isosceles_triangle_with_arrows_equal': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Height label
          {
            top: heightDiff + actualHeight * 0.45,
            left: widthDiff + actualWidth * 0.52
          },
          // Width label
          {
            bottom: heightDiff + actualHeight * -0.18,
            left: widthDiff + actualWidth * 0.45
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Isosceles_triangle_with_arrows_short': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Height label
          {
            top: heightDiff + actualHeight * 0.4,
            left: widthDiff + actualWidth * 0.52
          },
          // Width label
          {
            bottom: isPDF ? heightDiff + actualHeight * -0.22 : heightDiff + actualHeight * -0.28,
            left: widthDiff + actualWidth * 0.45
          }
        ]
      };
    }
    case 'Shapes_with_arrows/Isosceles_triangle_with_arrows_tall': {
      const svgName = shapeName;
      const { actualWidth, actualHeight, widthDiff, heightDiff } = getDimens(
        svgName,
        dimens,
        shapeDimens
      );
      return {
        svgName,
        angleLabelsPositions: [],
        sideLabelPositions: [
          // Height label
          {
            top: heightDiff + actualHeight * 0.5,
            left: widthDiff + actualWidth * 0.52
          },
          // Width label
          {
            bottom: isPDF ? heightDiff + actualHeight * -0.2 : heightDiff + actualHeight * -0.12,
            left: widthDiff + actualWidth * 0.45
          }
        ]
      };
    }
  }
};
