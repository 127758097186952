import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF15CreateBaseTenNumber from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import { ScientificNotation } from '../../../../utils/math';
import { numberEnum } from '../../../../utils/zod';
import QF31NumberGridInteractive from '../../../../components/question/questionFormats/QF31NumberGridInteractive';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'be9',
  description: 'be9',
  keywords: ['More', 'Less', 'Base 10'],
  schema: z.object({
    number: z.number().int().min(20).max(100),
    moreOrLess: z.enum(['more', 'less'])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(20, 100);

    const moreOrLess =
      number === 20
        ? 'more'
        : number === 100
        ? 'less'
        : getRandomFromArray(['more', 'less'] as const);

    return { number, moreOrLess };
  },

  Component: props => {
    const {
      question: { number, moreOrLess },
      translate
    } = props;
    const answer = moreOrLess === 'more' ? number + 1 : number - 1;
    return (
      <QF15CreateBaseTenNumber
        title={
          moreOrLess === 'more'
            ? translate.ks1Instructions.dragBase10ToShowXMore(1, number)
            : translate.ks1Instructions.dragBase10ToShowXLess(1, number)
        }
        pdfTitle={
          moreOrLess === 'more'
            ? translate.ks1PDFInstructions.drawBase10ToShowXMore(1, number)
            : translate.ks1PDFInstructions.drawBase10ToShowXLess(1, number)
        }
        number={ScientificNotation.fromNumber(answer)}
        draggablesToShow={[1, 0]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bfa',
  description: 'bfa',
  keywords: ['Table', 'Addition', 'More', 'Less'],
  schema: z.object({
    startNumber: z.number().int().min(20).max(94),
    numberOfCells: numberEnum([6, 7, 8]),
    moreOrLess: z.enum(['more', 'less']),
    titleNumber: z.number().int().min(20).max(100)
  }),
  simpleGenerator: () => {
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);
    const numberOfCells = getRandomFromArray([6, 7, 8] as const);
    const maxNumber = numberOfCells === 6 ? 94 : numberOfCells === 7 ? 93 : 92;
    const startNumber = randomIntegerInclusive(20, maxNumber);

    const titleNumber =
      moreOrLess === 'more'
        ? randomIntegerInclusive(startNumber, startNumber + numberOfCells - 1)
        : randomIntegerInclusive(startNumber + 1, startNumber + numberOfCells);

    return { startNumber, numberOfCells, moreOrLess, titleNumber };
  },
  Component: props => {
    const {
      question: { startNumber, numberOfCells, moreOrLess, titleNumber },
      translate
    } = props;

    const answer = moreOrLess === 'more' ? titleNumber + 1 : titleNumber - 1;

    const [title, pdfTitle] =
      moreOrLess === 'more'
        ? [
            translate.ks1Instructions.tapXMoreThanY(1, titleNumber.toLocaleString()),
            translate.ks1PDFInstructions.circleXMoreThanY(1, titleNumber.toLocaleString())
          ]
        : [
            translate.ks1Instructions.tapXLessThanY(1, titleNumber.toLocaleString()),
            translate.ks1PDFInstructions.circleXLessThanY(1, titleNumber.toLocaleString())
          ];

    return (
      <QF31NumberGridInteractive
        startNumber={startNumber}
        finishNumber={startNumber + numberOfCells}
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[answer]}
        questionHeight={500}
        multiSelect={false}
        customMarkSchemeAnswer={{ circleOrShade: 'circle' }}
      />
    );
  },
  questionHeight: 500
});

const Question3 = newQuestionContent({
  uid: 'bfb',
  description: 'bfb',
  keywords: ['Addition'],
  schema: z.object({
    number: z.number().int().min(0).max(100),
    isAnsBoxFirst: z.boolean(),
    moreOrLess: z.enum(['more', 'less'])
  }),
  simpleGenerator: () => {
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);
    const number =
      moreOrLess === 'more' ? randomIntegerInclusive(0, 99) : randomIntegerInclusive(1, 100);
    const isAnsBoxFirst = getRandomFromArrayWithWeights([false, true], [3, 1]);

    return { number, isAnsBoxFirst, moreOrLess };
  },
  Component: props => {
    const {
      question: { number, isAnsBoxFirst, moreOrLess },
      translate
    } = props;

    const [sentence, answer] = isAnsBoxFirst
      ? moreOrLess === 'more'
        ? [translate.ks1AnswerSentences.xMoreThanYIsAns(1, number), number + 1]
        : [translate.ks1AnswerSentences.xLessThanYIsAns(1, number), number - 1]
      : moreOrLess === 'more'
      ? [translate.ks1AnswerSentences.xMoreThanAnsIsY(1, number + 1), number]
      : [translate.ks1AnswerSentences.xLessThanAnsIsY(1, number - 1), number];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        testCorrect={[answer.toString()]}
        sentence={sentence}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OneMoreOneLess',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
