import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  monthNames,
  monthAsWord,
  monthSchema,
  getRandomUniqueMonths,
  getNumberOfDaysInMonth,
  getDayNameForGivenDate,
  getNumberOfDaysInMonthByYear,
  getRandomMonth
} from '../../../../utils/months';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import Text from '../../../../components/typography/Text';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { numberEnum } from '../../../../utils/zod';
import { Calendar } from '../../../../components/question/representations/Calendar';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { daySchema, dayNames } from '../../../../utils/days';
import { getNumeralOrdinal } from '../../../../utils/ordinals';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfA',
  description: 'bfA',
  keywords: ['Months of the year', 'Before', 'After', 'Time'],
  schema: z.object({
    beforeOrAfter: z.enum(['Before', 'After']),
    selectedMonthIdx: z.number().int().min(0).max(6)
  }),
  simpleGenerator: () => {
    const beforeOrAfter = getRandomFromArray(['Before', 'After'] as const);
    const selectedMonthIdx = randomIntegerInclusive(0, 6);

    return { beforeOrAfter, selectedMonthIdx };
  },
  Component: props => {
    const {
      question: { beforeOrAfter, selectedMonthIdx },
      translate
    } = props;

    const selectedMonth = monthNames[selectedMonthIdx];

    const currentMonthIdxInMonths = monthNames.indexOf(selectedMonth);

    const prevMonthIdx = (currentMonthIdxInMonths - 1 + monthNames.length) % monthNames.length;
    const prevMonth = monthNames[prevMonthIdx];

    const nextMonthIdx = (currentMonthIdxInMonths + 1) % monthNames.length;
    const nextMonth = monthNames[nextMonthIdx];

    const incorrectMonthIdx = (currentMonthIdxInMonths + 2) % monthNames.length;
    const incorrectMonth = monthNames[incorrectMonthIdx];

    const items = shuffle(
      [
        { value: prevMonth, component: <Text variant="WRN700">{prevMonth.toLocaleString()}</Text> },
        { value: nextMonth, component: <Text variant="WRN700">{nextMonth.toLocaleString()}</Text> },
        {
          value: incorrectMonth,
          component: <Text variant="WRN700">{incorrectMonth.toLocaleString()}</Text>
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={items}
        itemVariant="rectangle"
        sentenceStyle={{ alignItems: 'center', justifyContent: 'center' }}
        actionPanelVariant="endWide"
        sentence={translate.ks1AnswerSentences.theMonthBeforeOrAfterXMonthIsAns(
          beforeOrAfter === 'Before'
            ? translate.ks1MiscStrings.before()
            : translate.ks1MiscStrings.after(),
          monthAsWord(selectedMonth, translate)
        )}
        pdfLayout="itemsTop"
        pdfItemVariant="tallRectangle"
        testCorrect={beforeOrAfter === 'Before' ? [prevMonth] : [nextMonth]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bfB',
  description: 'bfB',
  keywords: ['Months of the year', 'Days', 'Time'],
  schema: z.object({
    amountOfDays: numberEnum([30, 31]),
    months: z.array(monthSchema).length(6)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const { amountOfDays, months } = rejectionSample(
      () => {
        const amountOfDays = getRandomFromArray([30, 31] as const);
        const months = getRandomUniqueMonths(6);

        const amountOfCorrectMonths = months.map(
          month => getNumberOfDaysInMonth(month) === amountOfDays
        );

        return { amountOfDays, months, amountOfCorrectMonths };
      },
      ({ amountOfCorrectMonths }) => amountOfCorrectMonths.length >= 2
    );

    return { amountOfDays, months: shuffle(months) };
  },

  Component: props => {
    const {
      question: { amountOfDays, months },
      translate,
      displayMode
    } = props;

    const [monthA, monthB, monthC, monthD, monthE, monthF] = months;

    const items = [monthA, monthB, monthC, monthD, monthE, monthF];

    const correctMonths = months.filter(month => getNumberOfDaysInMonth(month) === amountOfDays);

    const correct = correctMonths.map(month => items.indexOf(month));

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectTheMonthsThatHaveExactlyXDays(amountOfDays)}
        pdfTitle={translate.ks1PDFInstructions.tickTheMonthsThatHaveExactlyXDays(amountOfDays)}
        testCorrect={correct}
        multiSelect
        itemsMaxLines={1}
        itemsTextAutoScale={false}
        itemsTextStyle={{ fontSize: displayMode === 'digital' ? 22 : 40 }}
        items={items.map((displayString, index) => ({
          value: index,
          component: <Text variant="WRN700">{displayString.toLocaleString()}</Text>
        }))}
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bfC',
  description: 'bfC',
  keywords: ['Months of the year', 'Days of the week', 'Calendar', 'Time'],
  schema: z
    .object({
      month: monthSchema,
      dateInMonth: z.number().int().min(1).max(31),
      year: z.number().int().min(2016).max(2023),
      correctDay: daySchema,
      incorrectDayA: daySchema,
      incorrectDayB: daySchema
    })
    .refine(
      val => getNumberOfDaysInMonthByYear(val.month, val.year) >= val.dateInMonth,
      'dateInMonth must not be larger than number of days in selected month.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.incorrectDayA, val.incorrectDayB]),
      'days for answers must all be different.'
    ),
  simpleGenerator: () => {
    const month = getRandomMonth();
    const year = randomIntegerInclusive(2016, 2023);

    const dateInMonth = randomIntegerInclusive(1, getNumberOfDaysInMonthByYear(month, year));

    const correctDay = getDayNameForGivenDate(
      new Date(year, monthNames.indexOf(month), dateInMonth)
    );

    const [incorrectDayA, incorrectDayB] = getRandomSubArrayFromArray(
      dayNames.filter(day => day !== correctDay),
      2
    );

    return { month, dateInMonth, year, correctDay, incorrectDayA, incorrectDayB };
  },
  Component: props => {
    const {
      question: { month, dateInMonth, year, correctDay, incorrectDayA, incorrectDayB },
      locale,
      translate
    } = props;

    const ordinal = getNumeralOrdinal(dateInMonth, locale, translate);

    const statements = [
      {
        value: correctDay
      },
      {
        value: incorrectDayA
      },
      {
        value: incorrectDayB
      }
    ];

    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.whatDayOfTheWeekIsTheXDate(ordinal)}
        pdfTitle={translate.ks1PDFInstructions.tickWhatDayOfTheWeekTheXDateIsOn(ordinal)}
        testCorrect={[correctDay]}
        itemLayout={'row'}
        numItems={3}
        Content={({ dimens }) => (
          <Calendar
            month={month}
            year={year}
            dimens={{ width: dimens.width * 0.9, height: dimens.height }}
          />
        )}
        renderItems={shuffledStatements.map(({ value }) => ({
          value,
          component: <Text variant="WRN700">{translate.time[value]()}</Text>
        }))}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MonthsOfTheYear',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
