import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { AssetSvg } from '../../../../assets/svg';
import { MarkupAssets } from '../../../../markup';
import { get3DShapeFullColorsSVGPath } from '../../../../utils/threeDShapes';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import { numberEnum } from '../../../../utils/zod';
import { arrayHasNoDuplicates, filledArray, flatten2dArray } from '../../../../utils/collections';
import deepEqual from 'react-fast-compare';
import { View } from 'react-native';

////
// Questions
////

const shapes2d = ['circle', 'rectangle', 'square', 'triangle'] as const;
const shapes3d = ['Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid', 'Triangle_pyramid'] as const;
const allShapes = [...shapes2d, ...shapes3d] as const;
const colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

const Question1 = newQuestionContent({
  uid: 'bh6',
  description: 'bh6',
  keywords: ['Pattern'],
  schema: z
    .object({
      shape: z.enum(allShapes),
      correctColor: z.enum(colors),
      incorrectColor: z.enum(colors),
      scaleA: numberEnum([0.6, 1]),
      scaleB: numberEnum([0.6, 1]),
      pattern: z.enum(['AB', 'AAB', 'ABB']),
      itemOrder: z
        .array(z.enum(['A', 'B', 'C', 'D']))
        .length(4)
        .refine(
          val => arrayHasNoDuplicates([val]),
          'itemOrder must contain one each of A, B, C and D.'
        )
    })
    .refine(
      val => val.correctColor !== val.incorrectColor,
      'correctColor and incorrectColor cannot be the same.'
    )
    .refine(val => val.scaleA !== val.scaleB, 'scaleA and scaleB cannot be the same.'),
  simpleGenerator: () => {
    const shape = getRandomFromArray(allShapes);

    const [correctColor, incorrectColor] = getRandomSubArrayFromArray(colors, 2);

    const [scaleA, scaleB] = shuffle([0.6, 1] as const);

    const pattern = getRandomFromArrayWithWeights(['AB', 'AAB', 'ABB'] as const, [2, 1, 1]);

    const itemOrder = shuffle(['A', 'B', 'C', 'D'] as const);

    return {
      shape,
      correctColor,
      incorrectColor,
      scaleA,
      scaleB,
      pattern,
      itemOrder
    };
  },
  Component: props => {
    const {
      question: { shape, correctColor, incorrectColor, scaleA, scaleB, pattern, itemOrder },
      translate
    } = props;

    const [correctSvg, incorrectSvg] = (() => {
      switch (shape) {
        case 'Cuboid':
        case 'Cylinder':
        case 'Sphere':
        case 'Square_pyramid':
        case 'Triangle_pyramid':
          return [
            get3DShapeFullColorsSVGPath(correctColor, shape),
            get3DShapeFullColorsSVGPath(incorrectColor, shape)
          ];
        case 'circle':
        case 'rectangle':
        case 'square':
        case 'triangle':
          return [
            getShapeSvgByShapeAndColor(shape, correctColor),
            getShapeSvgByShapeAndColor(shape, incorrectColor)
          ];
      }
    })();

    const assetA = `<asset name='shapeA'/>`;

    const assetB = `<asset name='shapeB'/>`;

    const assetPattern = (() => {
      switch (pattern) {
        case 'AB':
          return `${assetA} ${assetB}`;
        case 'AAB':
          return `${assetA} ${assetA} ${assetB}`;
        case 'ABB':
          return `${assetA} ${assetB} ${assetB}`;
      }
    })();

    const sentence = `${assetPattern} ${assetPattern} ${assetPattern} <ans/> <ans/>`;

    const options = {
      A: <AssetSvg name={correctSvg} height={84 * scaleA} width={84 * scaleA} />,
      B: <AssetSvg name={correctSvg} height={84 * scaleB} width={84 * scaleB} />,
      C: <AssetSvg name={incorrectSvg} height={84 * scaleA} width={84 * scaleA} />,
      D: <AssetSvg name={incorrectSvg} height={84 * scaleB} width={84 * scaleB} />
    } as const;

    return (
      <MarkupAssets
        elements={{
          shapeA: <AssetSvg name={correctSvg} height={84 * scaleA} width={84 * scaleA} />,
          shapeB: <AssetSvg name={correctSvg} height={84 * scaleB} width={84 * scaleB} />
        }}
      >
        <QF37SentenceDrag
          title={translate.ks1Instructions.dragTheNextTwoShapesToContinueThePattern()}
          pdfTitle={translate.ks1PDFInstructions.drawTheNextTwoShapesToContinueThePattern()}
          moveOrCopy="copy"
          itemVariant="square"
          pdfLayout="itemsHidden"
          items={itemOrder.map(x => ({
            value: x,
            component: options[x]
          }))}
          sentence={sentence}
          testCorrect={pattern === 'AAB' ? ['A', 'A'] : ['A', 'B']}
        />
      </MarkupAssets>
    );
  }
});

const q2Shapes3d = ['Cube', 'Cuboid', 'Cylinder', 'Sphere'] as const;
const q2Shapes = [...shapes2d, ...q2Shapes3d] as const;

const Question2 = newQuestionContent({
  uid: 'bh7',
  description: 'bh7',
  keywords: ['Pattern'],
  schema: z
    .object({
      items: z
        .array(
          z.object({
            shape: z.enum(q2Shapes),
            color: z.enum(colors)
          })
        )
        .length(4)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
        .refine(
          items => new Set<string>(items.map(item => item.shape)).size === 4,
          'items must consist of exactly four shapes'
        )
        .refine(
          items => new Set<string>(items.map(item => item.color)).size === 4,
          'items must consist of exactly four colors'
        ),
      shapeA: z.enum(q2Shapes),
      shapeB: z.enum(q2Shapes),
      pattern: z.enum(['AB', 'AAB', 'ABB'])
    })
    .refine(val => val.shapeA !== val.shapeB, 'shapeA and shapeB cannot be the same.'),
  simpleGenerator: () => {
    const pattern = getRandomFromArrayWithWeights(['AB', 'AAB', 'ABB'] as const, [14, 3, 3]);

    const shapeType = getRandomFromArray(['2-D', '3-D']);

    // We need to filter out rectangle and cuboid from ever being the 'double' AA/BB shape, as this really overcomplicates the testCorrect.
    const shapeA =
      shapeType === '2-D'
        ? ((pattern === 'AAB'
            ? getRandomFromArray(shapes2d.filter(shape => shape !== 'rectangle'))
            : getRandomFromArray(shapes2d)) as (typeof shapes2d)[number])
        : ((pattern === 'AAB'
            ? getRandomFromArray(q2Shapes3d.filter(shape => shape !== 'Cuboid'))
            : getRandomFromArray(q2Shapes3d)) as (typeof q2Shapes3d)[number]);

    const shapeB =
      shapeType === '2-D'
        ? ((pattern === 'ABB'
            ? getRandomFromArray(
                shapes2d.filter(
                  shape =>
                    shape !== 'rectangle' &&
                    shape !== shapeA &&
                    // If rectangle is the single shape, we want to filter out 'square' as being the other shape,
                    // otherwise this also could lead to strange answers.
                    (shapeA === 'rectangle' ? shape !== 'square' : true)
                )
              )
            : getRandomFromArray(
                shapes2d.filter(
                  shape => shape !== shapeA && (shapeA === 'rectangle' ? shape !== 'square' : true)
                )
              )) as (typeof shapes2d)[number])
        : ((pattern === 'ABB'
            ? getRandomFromArray(
                q2Shapes3d.filter(
                  shape =>
                    shape !== 'Cuboid' &&
                    shape !== shapeA &&
                    // If cuboid is the single shape, we want to filter out 'Cube' as being the other shape,
                    // otherwise this also could lead to strange answers.
                    (shapeA === 'Cuboid' ? shape !== 'Cube' : true)
                )
              )
            : getRandomFromArray(
                q2Shapes3d.filter(
                  shape => shape !== shapeA && (shapeA === 'Cuboid' ? shape !== 'Cube' : true)
                )
              )) as (typeof q2Shapes3d)[number]);

    const [color1, color2, color3, color4] = getRandomSubArrayFromArray(colors, 4);

    const [shape1, shape2, shape3, shape4] = getRandomSubArrayFromArray(
      shapeType === '2-D' ? shapes2d : q2Shapes3d,
      4
    );

    const items = shuffle([
      { shape: shape1, color: color1 },
      { shape: shape2, color: color2 },
      { shape: shape3, color: color3 },
      { shape: shape4, color: color4 }
    ]);

    return {
      items,
      shapeA,
      shapeB,
      pattern
    };
  },
  Component: props => {
    const {
      question: { items, shapeA, shapeB, pattern },
      translate
    } = props;

    const shapeToString = (shape: (typeof q2Shapes)[number]) => {
      switch (shape) {
        case 'circle':
          return translate.shapes.Circles(1);
        case 'rectangle':
          return translate.shapes.Rectangles(1);
        case 'square':
          return translate.shapes.Squares(1);
        case 'triangle':
          return translate.shapes.Triangles(1);
        case 'Cube':
          return translate.shapes.cubes(1);
        case 'Cuboid':
          return translate.shapes.cuboids(1);
        case 'Cylinder':
          return translate.shapes.cylinders(1);
        case 'Sphere':
          return translate.shapes.spheres(1);
      }
    };

    const shapeAString = shapeToString(shapeA);
    const shapeBString = shapeToString(shapeB);

    const shapeToSvg = (shape: (typeof q2Shapes)[number], color: (typeof colors)[number]) => {
      switch (shape) {
        case 'circle':
        case 'rectangle':
        case 'square':
        case 'triangle':
          return getShapeSvgByShapeAndColor(shape, color);
        case 'Cube':
        case 'Cuboid':
        case 'Cylinder':
        case 'Sphere':
          return get3DShapeFullColorsSVGPath(color, shape);
      }
    };

    // This function is called into the testCorrect function, and will accept any squares where 'rectangle' is required, so long as *every* other matching shape is also a square.
    // Alternatively, it will accept any cubes where 'cuboid' is required, so long as *every* other matching shape is also a cube.
    const allRectanglesSquaresCuboidsCubesCheck = (
      userAnswer: ((typeof q2Shapes)[number] | undefined)[],
      shapeToCheck: (typeof q2Shapes)[number]
    ) => {
      return shapeToCheck === 'rectangle'
        ? userAnswer.every(ans => ans === 'rectangle') || userAnswer.every(ans => ans === 'square')
        : shapeToCheck === 'Cuboid'
        ? userAnswer.every(ans => ans === 'Cuboid') || userAnswer.every(ans => ans === 'Cube')
        : userAnswer.every(ans => ans === shapeToCheck);
    };

    const patternAns =
      pattern === 'AB'
        ? [shapeA, shapeB]
        : pattern === 'AAB'
        ? [shapeA, shapeA, shapeB]
        : [shapeA, shapeB, shapeB];

    return (
      <QF37SentenceDrag
        title={
          pattern === 'AB'
            ? translate.ks1Instructions.aPatternGoesXYThenItRepeatsDragTheShapes(
                shapeAString,
                shapeBString
              )
            : translate.ks1Instructions.aPatternGoesXYZThenItRepeatsDragTheShapes(
                shapeAString,
                pattern === 'AAB' ? shapeAString : shapeBString,
                shapeBString
              )
        }
        pdfTitle={
          pattern === 'AB'
            ? translate.ks1PDFInstructions.aPatternGoesXYThenItRepeatsDrawTheShapes(
                shapeAString,
                shapeBString
              )
            : translate.ks1PDFInstructions.aPatternGoesXYZThenItRepeatsDrawTheShapes(
                shapeAString,
                pattern === 'AAB' ? shapeAString : shapeBString,
                shapeBString
              )
        }
        moveOrCopy="copy"
        itemVariant="square"
        pdfLayout="itemsHidden"
        items={items.map(shape => ({
          component: (
            <AssetSvg
              key={shape.shape}
              name={shapeToSvg(shape.shape, shape.color)}
              height={84}
              width={84}
            />
          ),
          value: shape.shape
        }))}
        sentence={
          pattern === 'AB'
            ? '<ans/> <ans/> <ans/> <ans/> <ans/> <ans/>'
            : '<ans/> <ans/> <ans/> <ans/> <ans/> <ans/> <ans/> <ans/> <ans/>'
        }
        testCorrect={userAnswer => {
          return pattern === 'AB'
            ? allRectanglesSquaresCuboidsCubesCheck(
                [userAnswer[0], userAnswer[2], userAnswer[4]],
                shapeA
              ) &&
                allRectanglesSquaresCuboidsCubesCheck(
                  [userAnswer[1], userAnswer[3], userAnswer[5]],
                  shapeB
                )
            : pattern === 'AAB'
            ? allRectanglesSquaresCuboidsCubesCheck(
                [
                  userAnswer[0],
                  userAnswer[1],
                  userAnswer[3],
                  userAnswer[4],
                  userAnswer[6],
                  userAnswer[7]
                ],
                shapeA
              ) &&
              allRectanglesSquaresCuboidsCubesCheck(
                [userAnswer[2], userAnswer[5], userAnswer[8]],
                shapeB
              )
            : allRectanglesSquaresCuboidsCubesCheck(
                [userAnswer[0], userAnswer[3], userAnswer[6]],
                shapeA
              ) &&
              allRectanglesSquaresCuboidsCubesCheck(
                [
                  userAnswer[1],
                  userAnswer[2],
                  userAnswer[4],
                  userAnswer[5],
                  userAnswer[7],
                  userAnswer[8]
                ],
                shapeB
              );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [flatten2dArray(filledArray(patternAns, 3)).flatArray]
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bh8',
  description: 'bh8',
  keywords: ['Pattern'],
  schema: z
    .object({
      items: z
        .array(
          z.object({
            pattern: z.enum(['AA', 'BB', 'AB', 'BA'])
          })
        )
        .length(4)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
        .refine(
          items => new Set<string>(items.map(item => item.pattern)).size === 4,
          'items must consist of exactly four patterns'
        ),
      shapeA: z.enum(allShapes),
      shapeB: z.enum(allShapes),
      colorA: z.enum(colors),
      colorB: z.enum(colors),
      pattern: z.enum(['AB', 'AAB', 'ABB']),
      firstShapeIndexToHide: z.number().int().min(1).max(6)
    })
    .refine(val => val.shapeA !== val.shapeB, 'shapeA and shapeB cannot be the same.')
    .refine(
      val => (val.pattern === 'AB' ? val.firstShapeIndexToHide <= 3 : true),
      'If pattern is AB, the maximum that firstShapeIndexToHide can be is 3'
    ),
  simpleGenerator: () => {
    const shapeType = getRandomFromArray(['2-D', '3-D']);

    const [shapeA, shapeB] = getRandomSubArrayFromArray(
      shapeType === '2-D' ? shapes2d : shapes3d,
      2
    );

    const colorA = getRandomFromArray(colors);

    // Normally we could just use getRandomSubArrayFromArray to assign two colors to colorA and colorB,
    // but colors need to be distinct in this Q, so we can't just pick any two colors at all as some are too similar,
    // e.g. red and pink. The following cases should prevent any similar colours being selected:
    const colorBChoices = (() => {
      switch (colorA) {
        case 'blue':
          return ['green', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'green':
          return ['blue', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'pink':
          return ['blue', 'green', 'yellow'] as const;
        case 'purple':
          return ['blue', 'green', 'red', 'yellow'] as const;
        case 'red':
          return ['blue', 'green', 'purple', 'yellow'] as const;
        case 'yellow':
          return ['blue', 'green', 'pink', 'purple', 'red'] as const;
      }
    })();

    const colorB = getRandomFromArray(colorBChoices);

    const pattern = getRandomFromArrayWithWeights(['AB', 'AAB', 'ABB'] as const, [2, 1, 1]);

    // Pattern 'AB' will create a pattern of only six shapes, whereas the other patterns will be nine shapes
    // - so this needs a much lower maximum boundary.
    const firstShapeIndexToHide = randomIntegerInclusive(1, pattern === 'AB' ? 3 : 6);

    const itemPatterns = ['AA', 'BB', 'AB', 'BA'] as const;

    const items = shuffle([
      { pattern: itemPatterns[0] },
      { pattern: itemPatterns[1] },
      { pattern: itemPatterns[2] },
      { pattern: itemPatterns[3] }
    ]);

    return {
      items,
      shapeA,
      shapeB,
      colorA,
      colorB,
      pattern,
      firstShapeIndexToHide
    };
  },
  Component: props => {
    const {
      question: { items, shapeA, shapeB, colorA, colorB, pattern, firstShapeIndexToHide },
      translate
    } = props;

    const assetA = `<asset name='shapeA'/>`;

    const assetB = `<asset name='shapeB'/>`;

    const shapeToSvg = (
      shape:
        | 'circle'
        | 'rectangle'
        | 'square'
        | 'triangle'
        | 'Cuboid'
        | 'Cylinder'
        | 'Sphere'
        | 'Square_pyramid'
        | 'Triangle_pyramid',
      color: 'blue' | 'green' | 'pink' | 'purple' | 'red' | 'yellow'
    ) => {
      switch (shape) {
        case 'circle':
        case 'rectangle':
        case 'square':
        case 'triangle':
          return getShapeSvgByShapeAndColor(shape, color);
        case 'Cuboid':
        case 'Cylinder':
        case 'Sphere':
        case 'Square_pyramid':
        case 'Triangle_pyramid':
          return get3DShapeFullColorsSVGPath(color, shape);
      }
    };

    const shapeASvg = shapeToSvg(shapeA, colorA);
    const shapeBSvg = shapeToSvg(shapeB, colorB);

    const fullPattern = (() => {
      switch (pattern) {
        case 'AB':
          return [assetA, assetB, assetA, assetB, assetA, assetB];
        case 'AAB':
          return [assetA, assetA, assetB, assetA, assetA, assetB, assetA, assetA, assetB];
        case 'ABB':
          return [assetA, assetB, assetB, assetA, assetB, assetB, assetA, assetB, assetB];
      }
    })();

    const correctPattern = `${fullPattern[firstShapeIndexToHide] === assetA ? 'A' : 'B'}${
      fullPattern[firstShapeIndexToHide + 1] === assetA ? 'A' : 'B'
    }`;

    const sentence = fullPattern
      .map((char, index) =>
        index === firstShapeIndexToHide
          ? '<ans/> '
          : index === firstShapeIndexToHide + 1
          ? ''
          : `${char} `
      )
      .join('');

    return (
      <MarkupAssets
        elements={{
          shapeA: <AssetSvg name={shapeASvg} height={84} width={84} />,
          shapeB: <AssetSvg name={shapeBSvg} height={84} width={84} />
        }}
      >
        <QF37SentenceDrag
          title={translate.ks1Instructions.dragTheShapesToCompleteThePattern()}
          pdfTitle={translate.ks1PDFInstructions.tickTheShapesThatCompleteThePattern()}
          itemVariant="twoThirdsRectangle"
          pdfItemVariant="twoThirdsRectangle"
          items={items.map(item => ({
            component: (
              <View key={item.pattern} style={{ flexDirection: 'row', columnGap: 8 }}>
                {item.pattern.split('').map((char, index) => (
                  <AssetSvg
                    key={index}
                    name={char === 'A' ? shapeASvg : shapeBSvg}
                    height={84}
                    width={84}
                  />
                ))}
              </View>
            ),
            value: item.pattern
          }))}
          sentence={sentence}
          testCorrect={[correctPattern]}
        />
      </MarkupAssets>
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakePatternsWith2DAnd3DShapes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
