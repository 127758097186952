import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { TranslationFunctions } from '../i18n/i18n-types';
import { SvgName } from '../assets/svg';

/**
 * BREAD
 */

// Bread names
const breadNames = ['White', 'Brown', 'Rye', 'Ciabatta', 'Baguette'] as const;

export type breadName = (typeof breadNames)[number];

export const breadSchema = z.enum(breadNames);

export const breadAsWord = (object: breadName, translate: TranslationFunctions) => {
  switch (object) {
    case 'White':
      return translate.food.bread.White();
    case 'Brown':
      return translate.food.bread.Brown();
    case 'Rye':
      return translate.food.bread.Rye();
    case 'Ciabatta':
      return translate.food.bread.Ciabatta();
    case 'Baguette':
      return translate.food.bread.Baguette();
  }
};

export function getRandomBread() {
  return getRandomFromArray(breadNames);
}

export function getRandomUniqueBreadNames(numberOfBreadNames: number) {
  return getRandomSubArrayFromArray([...breadNames] as const, numberOfBreadNames);
}

// Bread fillings
export const breadFillingsNames = ['Cheese', 'Tuna', 'Chicken', 'Ham', 'Turkey'] as const;

export type breadFillingsName = (typeof breadFillingsNames)[number];

export const breadFillingsSchema = z.enum(breadFillingsNames);

export const breadFillingsAsWord = (object: breadFillingsName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Cheese':
      return translate.food.bread.fillings.Cheese();
    case 'Tuna':
      return translate.food.bread.fillings.Tuna();
    case 'Chicken':
      return translate.food.bread.fillings.Chicken();
    case 'Ham':
      return translate.food.bread.fillings.Ham();
    case 'Turkey':
      return translate.food.bread.fillings.Turkey();
  }
};

export function getRandomBreadFillings() {
  return getRandomFromArray(breadFillingsNames);
}

export function getRandomUniqueBreadFillingsNames(numberOfBreadFillingsNames: number) {
  return getRandomSubArrayFromArray([...breadFillingsNames] as const, numberOfBreadFillingsNames);
}

/**
 * ICE CREAM
 */

// Ice cream flavours
export const iceCreamFlavoursNames = [
  'Vanilla',
  'Chocolate',
  'Strawberry',
  'Lemon',
  'Banana',
  'Coconut',
  'Mint choc chip',
  'Pistachio',
  'Bubblegum',
  'Hazelnut',
  'Raspberry'
] as const;

export type iceCreamFlavoursName = (typeof iceCreamFlavoursNames)[number];

export const iceCreamFlavoursSchema = z.enum(iceCreamFlavoursNames);

export const iceCreamFlavoursAsWord = (
  object: iceCreamFlavoursName,
  translate: TranslationFunctions,
  lowerCase = false
) => {
  switch (object) {
    case 'Vanilla':
      return translate.food.iceCream.flavours[lowerCase ? 'vanilla' : 'Vanilla']();
    case 'Chocolate':
      return translate.food.iceCream.flavours[lowerCase ? 'chocolate' : 'Chocolate']();
    case 'Strawberry':
      return translate.food.iceCream.flavours[lowerCase ? 'strawberry' : 'Strawberry']();
    case 'Lemon':
      return translate.food.iceCream.flavours[lowerCase ? 'lemon' : 'Lemon']();
    case 'Banana':
      return translate.food.iceCream.flavours[lowerCase ? 'banana' : 'Banana']();
    case 'Coconut':
      return translate.food.iceCream.flavours[lowerCase ? 'coconut' : 'Coconut']();
    case 'Mint choc chip':
      return translate.food.iceCream.flavours[lowerCase ? 'mint choc chip' : 'Mint choc chip']();
    case 'Pistachio':
      return translate.food.iceCream.flavours[lowerCase ? 'pistachio' : 'Pistachio']();
    case 'Bubblegum':
      return translate.food.iceCream.flavours[lowerCase ? 'bubblegum' : 'Bubblegum']();
    case 'Hazelnut':
      return translate.food.iceCream.flavours[lowerCase ? 'hazelnut' : 'Hazelnut']();
    case 'Raspberry':
      return translate.food.iceCream.flavours[lowerCase ? 'raspberry' : 'Raspberry']();
  }
};

export function getRandomIceCreamFlavours() {
  return getRandomFromArray(iceCreamFlavoursNames);
}

export function getRandomUniqueIceCreamFlavourNames(numberOfIceCreamFlavoursNames: number) {
  return getRandomSubArrayFromArray(
    [...iceCreamFlavoursNames] as const,
    numberOfIceCreamFlavoursNames
  );
}

// Ice cream toppings
export const iceCreamToppingsNames = [
  'Sauce',
  'Wafer',
  'Honey',
  'Whipped Cream',
  'Maple Syrup',
  'Sprinkles'
] as const;

export type iceCreamToppingsName = (typeof iceCreamToppingsNames)[number];

export const iceCreamToppingsItemsSchema = z.enum(iceCreamToppingsNames);

export const iceCreamToppingsAsWord = (
  object: iceCreamToppingsName,
  translate: TranslationFunctions
) => {
  switch (object) {
    case 'Sauce':
      return translate.food.iceCream.toppings.Sauce();
    case 'Wafer':
      return translate.food.iceCream.toppings.Wafer();
    case 'Honey':
      return translate.food.iceCream.toppings.Honey();
    case 'Whipped Cream':
      return translate.food.iceCream.toppings.WhippedCream();
    case 'Maple Syrup':
      return translate.food.iceCream.toppings.MapleSyrup();
    case 'Sprinkles':
      return translate.food.iceCream.toppings.Sprinkles();
  }
};

export function getRandomIceCreamTopping() {
  return getRandomFromArray(iceCreamToppingsNames);
}

export function getRandomUniqueIceCreamToppingNames(numberOfIceCreamToppingsNames: number) {
  return getRandomSubArrayFromArray(
    [...iceCreamToppingsNames] as const,
    numberOfIceCreamToppingsNames
  );
}

/**
 * FOOD
 */

// Food items for old questions - aTE, aTF, aTG, aTH, aTI, aTJ, aTJ2
export const foodNamesOld = [
  'Jam tart',
  'Fairy bread',
  'Eton mess',
  'Scone',
  'Pancake',
  'Churro',
  'Rock cake'
] as const;

// Food items for new questions
export const foodNamesNew = [
  'Jam tart',
  'Fairy cakes',
  'Eton mess',
  'Scone',
  'Pancake',
  'Churro',
  'Rock cake'
] as const;

export type foodNameOld = (typeof foodNamesOld)[number];
export type foodNameNew = (typeof foodNamesNew)[number];

export const foodSchemaOld = z.enum(foodNamesOld);
export const foodSchemaNew = z.enum(foodNamesNew);

export const foodAsWord = (
  object: foodNameOld | foodNameNew,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'Jam tart':
      return translate.food.JamTart(amount);
    case 'Fairy bread':
      return translate.food.FairyBread(amount);
    case 'Fairy cakes':
      return translate.food.FairyCakes(amount);
    case 'Eton mess':
      return translate.food.EtonMess(amount);
    case 'Scone':
      return translate.food.Scone(amount);
    case 'Pancake':
      return translate.food.Pancake(amount);
    case 'Churro':
      return translate.food.Churro(amount);
    case 'Rock cake':
      return translate.food.RockCake(amount);
  }
};

// Food items for old questions - aTE, aTF, aTG, aTH, aTI, aTJ, aTJ2
export function getRandomFoodOld() {
  return getRandomFromArray(foodNamesOld);
}

export function getRandomFoodNew() {
  return getRandomFromArray(foodNamesNew);
}

// Food ingredients
export const foodIngredients = (translate: TranslationFunctions, amount: number) => {
  return [
    {
      name: translate.food.JamTart(amount),
      makes: 4,
      ingredients: [
        {
          ingredient: translate.food.ingredients.Pastry(),
          amount: 80,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Butter(),
          amount: 40,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Jam(),
          amount: 50,
          unit: translate.food.units.g()
        }
      ]
    },
    {
      name: translate.food.FairyBread(amount),
      makes: 10,
      ingredients: [
        {
          ingredient: translate.food.ingredients.Bread(),
          amount: 2,
          unit: translate.food.units.slices()
        },
        {
          ingredient: translate.food.ingredients.Butter(),
          amount: 50,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Sprinkles(),
          amount: 30,
          unit: translate.food.units.g()
        }
      ]
    },
    {
      name: translate.food.FairyCakes(amount),
      makes: 10,
      ingredients: [
        {
          ingredient: translate.food.ingredients.Bread(),
          amount: 2,
          unit: translate.food.units.slices()
        },
        {
          ingredient: translate.food.ingredients.Butter(),
          amount: 50,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Sprinkles(),
          amount: 30,
          unit: translate.food.units.g()
        }
      ]
    },
    {
      name: translate.food.EtonMess(amount),
      makes: 3,
      ingredients: [
        {
          ingredient: translate.food.ingredients.Strawberries(),
          amount: 120,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Meringue(),
          amount: 3,
          unit: translate.food.units.nests()
        },
        {
          ingredient: translate.food.ingredients.Cream(),
          amount: 100,
          unit: translate.food.units.g()
        }
      ]
    },
    {
      name: translate.food.Scone(amount),
      makes: 5,
      ingredients: [
        {
          ingredient: translate.food.ingredients.Scone(),
          amount: 150,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Jam(),
          amount: 50,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Cream(),
          amount: 100,
          unit: translate.food.units.g()
        }
      ]
    },
    {
      name: translate.food.Pancake(amount),
      makes: 8,
      ingredients: [
        {
          ingredient: translate.food.ingredients.Flour(),
          amount: 200,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Eggs(),
          amount: 2,
          unit: translate.food.units.eggs()
        },
        {
          ingredient: translate.food.ingredients.Milk(),
          amount: 300,
          unit: translate.food.units.ml()
        }
      ]
    },
    {
      name: translate.food.Churro(amount),
      makes: 6,
      ingredients: [
        {
          ingredient: translate.food.ingredients.Pastry(),
          amount: 250,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.ChocolateSauce(),
          amount: 150,
          unit: translate.food.units.g()
        }
      ]
    },
    {
      name: translate.food.RockCake(amount),
      makes: 12,
      ingredients: [
        {
          ingredient: translate.food.ingredients.Flour(),
          amount: 200,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Butter(),
          amount: 100,
          unit: translate.food.units.g()
        },
        {
          ingredient: translate.food.ingredients.Sugar(),
          amount: 100,
          unit: translate.food.units.g()
        }
      ]
    }
  ] as const;
};

// Food mass
export const foodMassNames = [
  'Cake',
  'Pie',
  'Box of doughnuts',
  'Crate of apples',
  'Crate of bananas',
  'Crate of pears'
] as const;

export type foodMassName = (typeof foodMassNames)[number];

export const foodMassSchema = z.enum(foodMassNames);

export const foodMassAsWord = (
  object: foodMassName,
  translate: TranslationFunctions,
  amount: number
) => {
  switch (object) {
    case 'Cake':
      return translate.food.mass.Cake(amount);
    case 'Pie':
      return translate.food.mass.Pie(amount);
    case 'Box of doughnuts':
      return translate.food.mass.BoxOfDoughnuts(amount);
    case 'Crate of apples':
      return translate.food.mass.CrateOfApples(amount);
    case 'Crate of bananas':
      return translate.food.mass.CrateOfBananas(amount);
    case 'Crate of pears':
      return translate.food.mass.CrateOfPears(amount);
  }
};

export function getRandomFoodMass() {
  return getRandomFromArray(foodMassNames);
}

export function getRandomUniqueFoodMassNames(numberOfFoodMassNames: number) {
  return getRandomSubArrayFromArray([...foodMassNames] as const, numberOfFoodMassNames);
}

export const foodIngredientsArray = [
  'carrots',
  'chocolateChips',
  'flour',
  'pasta',
  'potatoes',
  'rice',
  'sugar',
  'chocolate'
] as const;

export type FoodIngredient = (typeof foodIngredientsArray)[number];

export function getFoodIngredientsSvg(foodIngredient: FoodIngredient): SvgName {
  switch (foodIngredient) {
    case 'carrots':
      return 'Bag_of_carrots';
    case 'chocolateChips':
      return 'Bag_of_chocolate_chips';
    case 'flour':
      return 'Bag_of_Flour';
    case 'pasta':
      return 'Bag_of_Pasta';
    case 'potatoes':
      return 'Bag_of_potatoes';
    case 'rice':
      return 'Bag_of_Rice';
    case 'sugar':
      return 'Bag_of_white_sugar';
    case 'chocolate':
      return 'Bar_of_chocolate';
  }
}
