import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { range, rangeAsString, sortNumberArray } from '../../../../utils/collections';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';

import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';

////
// Questions
////

const fiftyItemsArray = [
  'Balloons',
  'Bricks',
  'Lollipops',
  'Marbles',
  'Sweets',
  'Crayons'
] as const;

const Question1 = newQuestionContent({
  uid: 'abq',
  description: 'abq',
  keywords: ['Place value', 'Hundreds', '50s', 'Objects'],
  schema: z.object({
    object: z.enum(['Balloons', 'Bricks', 'Lollipops', 'Marbles', 'Sweets', 'Crayons']),
    number: z.number().int().min(100).max(500).step(50)
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(fiftyItemsArray);
    const number = getRandomFromArray(range(100, 500, 50));
    return { object, number };
  },
  Component: props => {
    const {
      question: { object, number },
      translate,
      displayMode
    } = props;

    const imageCount = number / 50;

    const maxHeight = displayMode === 'digital' ? 150 : 200;
    const maxWidth = displayMode === 'digital' ? 120 : 170;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.howManyObjectsAreThere(object)}
        testCorrect={[number.toString()]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={1}
            customImage={
              <AssetSvg
                name={`CountIn50/${object}50`}
                height={Math.min((dimens.height / (imageCount / 6), maxHeight))}
                width={Math.min((dimens.width / 6, maxWidth))}
              />
            }
            rowStyle={{ flexWrap: 'wrap', gap: 20 }}
            columns={imageCount}
            dimens={dimens}
          />
        )}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

export const Question2 = newQuestionContent({
  uid: 'abr',
  description: 'abr',
  keywords: ['Place value', '50', 'Track'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(0).max(950)).min(3).max(3),
      startingNumber: z.number().int().min(0).max(700),
      endNumber: z.number().int().min(250).max(950)
    })
    .refine(
      val => val.numbers.every(number => number >= val.startingNumber),
      val => ({ message: `All answers must be greater than or equal to ${val.startingNumber}` })
    )
    .refine(
      val => val.numbers.every(number => number <= val.endNumber),
      val => ({ message: `All answers must less than or equal to ${val.endNumber}` })
    )
    .refine(val => val.endNumber === val.startingNumber + 250, {
      message: 'Track end number should be starting number + 250'
    }),
  simpleGenerator: () => {
    const interval = 50;
    const startingNumber = getRandomFromArray(range(0, 700, interval));
    const endNumber = startingNumber + 250;

    const numbers = new Set<number>();
    const choices = range(startingNumber, endNumber, interval);
    // Add the third number in the track
    numbers.add(choices[2]);
    while (numbers.size < 3) {
      // Make this miss the first two numbers
      numbers.add(getRandomFromArray(choices.slice(2))!);
    }

    // Convert the set in to an array and sort the numbers from lowest to highest
    // This means the answers index matches with the user answers index.
    return { numbers: sortNumberArray(Array.from(numbers)), startingNumber, endNumber };
  },
  Component: ({ question, translate }) => {
    const { numbers, startingNumber, endNumber } = question;

    const interval = 50;

    // Create array to pass to number track
    const numberArray = rangeAsString(startingNumber, endNumber, interval);

    // Set where the answers should go
    numbers.forEach(number => {
      numberArray[numberArray.indexOf(number.toString())] = '<ans/>';
    });

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        testCorrect={numbers.map(it => it.toString())}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'abs',
  description: 'abs',
  keywords: ['Place value', 'Hundreds', '50s', 'Multiples'],
  schema: z.object({
    numbers: z.number().int().min(0).max(1000).array().length(8),
    name: nameSchema
  }),
  example: {
    numbers: [200, 350, 145, 430, 600, 750, 120, 860],
    name: 'Alex'
  },
  simpleGenerator: () => {
    const pickedNumbers: number[] = [];
    const pickNumber = (sample: () => number) =>
      pickedNumbers.push(rejectionSample(sample, x => !pickedNumbers.includes(x)));

    // Odd multiple of 50 up to 950
    pickNumber(() => randomIntegerInclusive(1, 10) * 100 - 50);

    // Multiple of 100 up to 1000
    pickNumber(() => randomIntegerInclusive(1, 10) * 100);

    // Odd multiple of 5 up to 1000
    pickNumber(() => randomIntegerInclusive(1, 100) * 10 - 5);

    // Multiples of 10 up to 1000
    pickNumber(() => randomIntegerInclusive(1, 100) * 10);
    pickNumber(() => randomIntegerInclusive(1, 100) * 10);
    pickNumber(() => randomIntegerInclusive(1, 100) * 10);
    pickNumber(() => randomIntegerInclusive(1, 100) * 10);
    pickNumber(() => randomIntegerInclusive(1, 100) * 10);

    const name = getRandomName();

    return { numbers: shuffle(pickedNumbers), name };
  },
  Component: props => {
    const {
      question: { numbers, name },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.characterCountsUpIn50sFromZero(name)}
        testCorrect={numbers.filter(it => it % 50 === 0)}
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

export const Question4 = newQuestionContent({
  uid: 'abt',
  description: 'abt',
  keywords: ['Place value', '50', 'Track'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(0).max(950)).min(3).max(3),
      endNumber: z.number().int().min(0).max(700),
      startingNumber: z.number().int().min(250).max(950)
    })
    .refine(
      val => val.numbers.every(number => number <= val.startingNumber),
      val => ({ message: `All answers must be less than or equal to ${val.startingNumber}` })
    )
    .refine(
      val => val.numbers.every(number => number >= val.endNumber),
      val => ({ message: `All answers must more than or equal to ${val.endNumber}` })
    )
    .refine(val => val.endNumber === val.startingNumber - 250, {
      message: 'Track end number should be starting number - 250'
    }),
  simpleGenerator: () => {
    const interval = 50; // A negative interval throws and error from the range function.
    const endNumber = getRandomFromArray(range(700, 0, interval));
    const startingNumber = endNumber + 250;

    const numbers = new Set<number>();
    const choices = range(startingNumber, endNumber, interval);
    while (numbers.size < 3) {
      // Make this miss the first two numbers
      numbers.add(getRandomFromArray(choices));
    }

    // Convert the set in to an array and sort the numbers from highest to lowest
    // This means the answers index matches with the user answers index.
    return {
      numbers: sortNumberArray(Array.from(numbers), 'descending'),
      startingNumber,
      endNumber
    };
  },
  Component: ({ question, translate }) => {
    const { numbers, startingNumber, endNumber } = question;
    const interval = 50;

    // Create array to pass to Number Line
    const numberArray = rangeAsString(startingNumber, endNumber, interval);

    // Set where the answers should go
    numbers.forEach(number => {
      numberArray[numberArray.indexOf(number.toString())] = '<ans/>';
    });

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        testCorrect={numbers.map(it => it.toString())}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'abu',
  description: 'abu',
  keywords: ['Place value', 'Number line', '50s'],
  schema: z
    .object({
      startingNumber: z.number().int().min(0).max(500).multipleOf(50),
      focusNumber: z.number().int().min(0).max(1000).multipleOf(50)
    })
    .refine(
      val => val.focusNumber >= val.startingNumber && val.focusNumber <= val.startingNumber + 500,
      'focusNumber must be equal to or greater than startingNumber, and less than or equal to startingNumber + 500'
    ),
  simpleGenerator: () => {
    const step = 50;
    const startingNumber = randomIntegerInclusiveStep(0, 500, step);
    const middle = startingNumber + 250;
    const focusNumber = randomIntegerInclusiveStep(
      startingNumber + step,
      startingNumber + 500 - step,
      step,
      { constraint: x => x !== middle }
    );

    return { startingNumber, focusNumber };
  },
  Component: props => {
    const {
      question: { startingNumber, focusNumber },
      translate
    } = props;
    const endNumber = startingNumber + 500;
    const tickInterval = 50;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, endNumber, tickInterval).map((number, index) => {
      return number === startingNumber ||
        number === startingNumber + 250 ||
        number === startingNumber + 500
        ? startingNumber + tickInterval * index
        : null;
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[focusNumber.toString()]}
        freeNumberLineAnswer={[focusNumber]}
        tickValues={tickValues}
        {...props}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'abv',
  description: 'abv',
  keywords: ['Place value', 'Hundreds', '50', 'Money'],
  schema: z.object({
    number: z.number().int().min(2).max(19)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2, 19);

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const numberOfRows = Math.ceil(number / 5);
    const numberOfCoinsInLastRow = number - 5 * (numberOfRows - 1);

    return (
      <QF1ContentAndSentence
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence="<ans/>p"
        title={translate.instructions.howManyPenceAreThere()}
        testCorrect={[(number * 50).toString()]}
        {...props}
        Content={({ dimens }) => {
          const coinHeight = dimens.height / (numberOfRows + 0.5);
          const coinWidth = number > 5 ? dimens.width / 6 : dimens.width / (number + 1);

          return (
            <View style={[dimens, { justifyContent: 'center', rowGap: 8 }]}>
              {range(1, numberOfRows).map(row => {
                return row < numberOfRows ? (
                  <View style={{ flexDirection: 'row', columnGap: 8 }} key={row}>
                    {range(1, 5).map(coin => (
                      <AssetSvg
                        name="Money/Pence50"
                        height={coinHeight}
                        width={coinWidth}
                        key={coin}
                      />
                    ))}
                  </View>
                ) : (
                  <View style={{ flexDirection: 'row', columnGap: 8 }} key={row}>
                    {range(1, numberOfCoinsInLastRow).map(coin => (
                      <AssetSvg
                        name="Money/Pence50"
                        height={coinHeight}
                        width={coinWidth}
                        key={coin}
                      />
                    ))}
                  </View>
                );
              })}
            </View>
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountIn50s',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
