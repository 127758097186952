import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF46PlotCoordinate from '../../../../components/question/questionFormats/QF46PlotCoordinate';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arraysHaveSameContents, countRange } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Grid from '../../../../components/question/representations/Coordinates/Grid';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';
import { ALGEBRAIC_X, ALGEBRAIC_Y } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'azq',
  description: 'azq',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.tuple([z.number().int().min(1).max(5), z.number().int().min(1).max(5)])
  }),
  simpleGenerator: () => ({
    coordinate: [randomIntegerInclusive(1, 5), randomIntegerInclusive(1, 5)] as [number, number]
  }),
  Component: ({ question: { coordinate }, translate, displayMode }) => {
    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragTheCircleToThePointXY(
                coordinate[0].toLocaleString(),
                coordinate[1].toLocaleString()
              )
            : translate.instructions.drawACrossToThePointXY(
                coordinate[0].toLocaleString(),
                coordinate[1].toLocaleString()
              )
        }
        snapToGrid
        testCorrect={ans => {
          return arraysHaveSameContents(ans[0], coordinate);
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [coordinate]
        }}
        gridProps={{ xMax: 5, yMax: 5, squareGrid: true }}
        items={[
          {
            component: 'Coordinates/CirclePointCustomizable',
            svgProps: { fill: colors.pacificBlue }
          }
        ]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'azr',
  description: 'azr',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z
    .object({
      coordinate1A: z.number().int().min(1).max(5),
      coordinate1B: z.number().int().min(1).max(5)
    })
    .refine(
      val => val.coordinate1A !== val.coordinate1B,
      'coordinate1A must be different to coordinate1B'
    ),
  simpleGenerator: () => {
    const coordinate1A = randomIntegerInclusive(1, 5);
    const coordinate1B = randomIntegerInclusive(1, 5, {
      constraint: x => x !== coordinate1A
    });

    return { coordinate1A, coordinate1B };
  },
  Component: props => {
    const {
      question: { coordinate1A, coordinate1B },
      translate
    } = props;

    const coordinate2A = coordinate1A - 0.5;
    const coordinate2B = coordinate1B - 0.5;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheCoordinateGridWithThePointXYMarked(
          coordinate1A.toLocaleString(),
          coordinate1B.toLocaleString()
        )}
        pdfTitle={translate.instructions.circleTheCoordinateGridWithThePointXYMarked(
          coordinate1A.toLocaleString(),
          coordinate1B.toLocaleString()
        )}
        testCorrect={['A']}
        numItems={2}
        renderItems={({ dimens: { width, height } }) =>
          shuffle(
            [
              {
                value: 'A',
                component: (
                  <Grid width={width} height={height - 20} xMax={5} yMax={5} squareGrid>
                    <GridImage
                      mathCoord={[coordinate1A, coordinate1B] as [number, number]}
                      item={{
                        component: 'Coordinates/CirclePointCustomizable',
                        svgProps: { fill: colors.pacificBlue }
                      }}
                    />
                  </Grid>
                )
              },
              {
                value: 'B',
                component: (
                  <Grid width={width} height={height - 20} xMax={5} yMax={5} squareGrid>
                    <GridImage
                      mathCoord={[coordinate2A, coordinate2B] as [number, number]}
                      item={{
                        component: 'Coordinates/CirclePointCustomizable',
                        svgProps: { fill: colors.pacificBlue }
                      }}
                    />
                  </Grid>
                )
              }
            ],
            { random: seededRandom(props.question) }
          )
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'azs',
  description: 'azs',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => ({
    coordinate: randomIntegerInclusive(1, 5)
  }),
  Component: ({ question: { coordinate }, translate, displayMode }) => {
    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragTheCircleToThePointXY(
                '0'.toLocaleString(),
                coordinate.toLocaleString()
              )
            : translate.instructions.drawACrossToThePointXY(
                '0'.toLocaleString(),
                coordinate.toLocaleString()
              )
        }
        snapToGrid
        testCorrect={ans => {
          return arraysHaveSameContents(ans[0], [0, coordinate]);
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [[0, coordinate]]
        }}
        gridProps={{ xMax: 5, yMax: 5, squareGrid: true }}
        items={[
          {
            component: 'Coordinates/CirclePointCustomizable',
            svgProps: { fill: colors.pacificBlue }
          }
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'azt',
  description: 'azt',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(1).max(5)
  }),
  simpleGenerator: () => ({
    coordinate: randomIntegerInclusive(1, 5)
  }),
  Component: ({ question: { coordinate }, translate, displayMode }) => {
    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragTheCircleToThePointXY(
                coordinate.toLocaleString(),
                '0'.toLocaleString()
              )
            : translate.instructions.drawACrossToThePointXY(
                coordinate.toLocaleString(),
                '0'.toLocaleString()
              )
        }
        snapToGrid
        testCorrect={ans => {
          return arraysHaveSameContents(ans[0], [coordinate, 0]);
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [[coordinate, 0]]
        }}
        gridProps={{ xMax: 5, yMax: 5, squareGrid: true }}
        items={[
          {
            component: 'Coordinates/CirclePointCustomizable',
            svgProps: { fill: colors.pacificBlue }
          }
        ]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'azu',
  description: 'azu',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z
    .object({
      coordinateA: z.number().int().min(0).max(5),
      coordinateB: z.number().int().min(0).max(5)
    })
    .refine(
      val => val.coordinateA !== val.coordinateB,
      'coordinateA must be different to coordinateB'
    ),
  simpleGenerator: () => {
    const coordinateA = randomIntegerInclusive(0, 5);
    const coordinateB = randomIntegerInclusive(0, 5, {
      constraint: x => x !== coordinateA
    });

    return { coordinateA, coordinateB };
  },
  Component: props => {
    const {
      question: { coordinateA, coordinateB },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheCoordinateGridWithThePointXY(
          coordinateA.toLocaleString(),
          coordinateB.toLocaleString()
        )}
        pdfTitle={translate.instructions.circleTheCoordinateGridWithThePointXY(
          coordinateA.toLocaleString(),
          coordinateB.toLocaleString()
        )}
        testCorrect={['A']}
        numItems={2}
        renderItems={({ dimens: { width, height } }) =>
          shuffle(
            [
              {
                value: 'A',
                component: (
                  <Grid width={width} height={height - 20} xMax={5} yMax={5} squareGrid>
                    <GridImage
                      mathCoord={[coordinateA, coordinateB] as [number, number]}
                      item={{
                        component: 'Coordinates/CirclePointCustomizable',
                        svgProps: { fill: colors.pacificBlue }
                      }}
                    />
                  </Grid>
                )
              },
              {
                value: 'B',
                component: (
                  <Grid width={width} height={height - 20} xMax={5} yMax={5} squareGrid>
                    <GridImage
                      mathCoord={[coordinateB, coordinateA] as [number, number]}
                      item={{
                        component: 'Coordinates/CirclePointCustomizable',
                        svgProps: { fill: colors.pacificBlue }
                      }}
                    />
                  </Grid>
                )
              }
            ],
            { random: seededRandom(props.question) }
          )
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'azv',
  description: 'azv',
  keywords: ['Coordinate', 'Coordinate grid', 'X-Coordinate', 'Y-Coordinate'],
  schema: z.object({
    coordinate: z.number().int().min(0).max(5),
    xOrY: z.enum([ALGEBRAIC_X, ALGEBRAIC_Y])
  }),
  simpleGenerator: () => ({
    coordinate: randomIntegerInclusive(0, 5),
    xOrY: getRandomFromArray([ALGEBRAIC_X, ALGEBRAIC_Y] as const)
  }),
  Component: ({ question: { coordinate, xOrY }, translate, displayMode }) => {
    return (
      <QF46PlotCoordinate
        title={
          displayMode === 'digital'
            ? translate.instructions.dragCiclesToPlot2PointsWithAXPointOfY(
                xOrY,
                coordinate.toLocaleString()
              )
            : translate.instructions.drawCrossesToPlot2PointsWithAXPointOfY(
                xOrY,
                coordinate.toLocaleString()
              )
        }
        snapToGrid
        testCorrect={ans => {
          const crossA = countRange(6)
            .map(idx => {
              return xOrY === ALGEBRAIC_X
                ? arraysHaveSameContents([coordinate, idx], ans[0])
                : arraysHaveSameContents([idx, coordinate], ans[0]);
            })
            .some(bool => bool);

          const crossB = countRange(6)
            .map(idx => {
              return xOrY === ALGEBRAIC_X
                ? arraysHaveSameContents([coordinate, idx], ans[1])
                : arraysHaveSameContents([idx, coordinate], ans[1]);
            })
            .some(bool => bool);

          return crossA && crossB;
        }}
        customMarkSchemeAnswer={{
          answersToDisplay:
            xOrY === ALGEBRAIC_X
              ? [
                  [coordinate, 0],
                  [coordinate, 4]
                ]
              : [
                  [0, coordinate],
                  [4, coordinate]
                ]
        }}
        gridProps={{ xMax: 5, yMax: 5, squareGrid: true }}
        items={[
          {
            component: 'Coordinates/CirclePointCustomizable',
            svgProps: { fill: colors.pacificBlue }
          },
          {
            component: 'Coordinates/CirclePointCustomizable',
            svgProps: { fill: colors.pacificBlue }
          }
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PlotCoordinates',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
