import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, getRandomSubArrayFromArray, shuffle } from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { avf } from '../../../Year 3/Summer/Shape/5HorizontalAndVertical';
import QF9DragIntoTableOfGroups from '../../../../components/question/questionFormats/QF9DragIntoTableOfGroups';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomShapeWithCorrectSymmetryLines,
  getRandomShapeWithIncorrectSymmetryLines,
  getRandomUniqueSymmetryImages,
  irregularSymmetryLines,
  regularSymmetryLines
} from '../../../../utils/shapeImages/symmetry';
import {
  parallelograms,
  raTrapeziums,
  rectangles,
  rhombuses,
  squares,
  trapeziums
} from '../../../../utils/shapeImages/polygons';
import {
  equilateralTriangles,
  raTriangles,
  raTrianglesLong
} from '../../../../utils/shapeImages/triangles';
import { getShapeSvgName } from '../../../../utils/shapeImages/shapes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ayK',
  description: 'ayK',
  keywords: ['Lines of symmetry'],
  schema: z.object({
    shapes: z.array(
      z.object({
        svgName: z.string(),
        symmetry: z.enum(['horizontal', 'vertical', 'both', 'none'])
      })
    )
  }),
  simpleGenerator: () => {
    const correct = getRandomUniqueSymmetryImages(3);
    const incorrect = getRandomFromArray([
      { svgName: getRandomFromArray([...raTrapeziums] as const), symmetry: 'none' },
      { svgName: getRandomFromArray([...raTrianglesLong] as const), symmetry: 'none' },
      { svgName: getRandomFromArray([...parallelograms] as const), symmetry: 'none' }
    ] as const);

    const shapes = shuffle([...correct, incorrect]);

    return { shapes };
  },
  Component: ({ question, translate }) => {
    const { shapes } = question;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithAtLeastOneSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithAtLeastOneSymmetry()}
        multiSelect
        numItems={4}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.svgName,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
        testCorrect={shapes.filter(val => val.symmetry !== 'none').map(val => val.svgName)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ayL',
  description: 'ayL',
  keywords: ['Lines of symmetry'],
  schema: z.object({
    shapes: z.array(
      z.object({
        svgName: z.string(),
        symmetry: z.enum(['horizontal', 'vertical', 'both', 'none'])
      })
    )
  }),
  simpleGenerator: () => {
    const incorrect = getRandomUniqueSymmetryImages(2);
    const correct = getRandomSubArrayFromArray(
      [
        { svgName: getRandomFromArray([...raTrapeziums] as const), symmetry: 'none' },
        { svgName: getRandomFromArray([...raTrianglesLong] as const), symmetry: 'none' },
        { svgName: getRandomFromArray([...parallelograms] as const), symmetry: 'none' }
      ] as const,
      2
    );

    const shapes = shuffle([...correct, ...incorrect]);

    return { shapes };
  },
  Component: ({ question, translate }) => {
    const { shapes } = question;

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithNoSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithNoSymmetry()}
        multiSelect
        numItems={4}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.svgName,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
        testCorrect={shapes.filter(val => val.symmetry === 'none').map(val => val.svgName)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ayM',
  description: 'ayM',
  keywords: ['Lines of symmetry'],
  schema: z.object({
    shapes: z.array(
      z.object({
        svgName: z.string(),
        isCorrect: z.boolean()
      })
    )
  }),
  simpleGenerator: () => {
    const correct = getRandomShapeWithCorrectSymmetryLines(2);
    const incorrect = getRandomShapeWithIncorrectSymmetryLines(2);

    const incorrectObject = incorrect.map(val => ({ svgName: val.svgName, isCorrect: false }));
    const correctObject = correct.map(val => ({ svgName: val.svgName, isCorrect: true }));

    const shapes = shuffle([...incorrectObject, ...correctObject]);
    return { shapes };
  },
  Component: ({ question, translate }) => {
    const { shapes } = question;
    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectShapesWithCorrectSymmetry()}
        pdfTitle={translate.instructions.circleShapesWithCorrectSymmetry()}
        multiSelect
        numItems={4}
        renderItems={({ dimens }) => {
          return shapes.map(shape => ({
            value: shape.svgName,
            component: (
              <AssetSvg
                name={shape.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
        testCorrect={shapes.filter(val => val.isCorrect).map(val => val.svgName)}
      />
    );
  }
});

const Question4 = { ...avf, uid: 'ayN', description: 'ayN' as const };

const Question5 = newQuestionContent({
  uid: 'ayO',
  description: 'ayO',
  keywords: ['Lines of symmetry', 'Triangles', 'Quadrilaterals'],
  schema: z.object({
    shapes: z.array(
      z.object({
        svgName: z.string(),
        symmetry: z.boolean(),
        shape: z.enum(['triangle', 'quadrilateral'])
      })
    )
  }),
  simpleGenerator: () => {
    const triangleTypes = getRandomSubArrayFromArray(['RA', 'RA_long', 'Equalateral'] as const, 2);
    const shapes: { svgName: SvgName; symmetry: boolean; shape: 'triangle' | 'quadrilateral' }[] =
      [];
    triangleTypes.forEach(val => {
      switch (val) {
        case 'Equalateral':
          shapes.push({
            svgName: getRandomFromArray([...equilateralTriangles] as const),
            symmetry: true,
            shape: 'triangle'
          });
          break;
        case 'RA_long':
          shapes.push({
            svgName: getRandomFromArray([...raTrianglesLong] as const),
            symmetry: false,
            shape: 'triangle'
          });
          break;
        case 'RA':
          shapes.push({
            svgName: getRandomFromArray([...raTriangles] as const),
            symmetry: true,
            shape: 'triangle'
          });
          break;
      }
    });

    const quadrilateralTypes = getRandomSubArrayFromArray(
      ['Square', 'Rectangle', 'Rhombus', 'Parallelogram', 'Trapezium'] as const,
      2
    );

    quadrilateralTypes.map(val => {
      switch (val) {
        case 'Square':
          shapes.push({
            svgName: getRandomFromArray([...squares] as const),
            symmetry: true,
            shape: 'quadrilateral'
          });
          break;
        case 'Rectangle':
          shapes.push({
            svgName: getRandomFromArray([...rectangles] as const),
            symmetry: true,
            shape: 'quadrilateral'
          });
          break;
        case 'Rhombus':
          shapes.push({
            svgName: getRandomFromArray([...rhombuses] as const),
            symmetry: false,
            shape: 'quadrilateral'
          });
          break;
        case 'Parallelogram':
          shapes.push({
            svgName: getRandomFromArray([...parallelograms] as const),
            symmetry: false,
            shape: 'quadrilateral'
          });
          break;
        case 'Trapezium':
          shapes.push({
            svgName: getRandomFromArray([...trapeziums] as const),
            symmetry: true,
            shape: 'quadrilateral'
          });
          break;
      }
    });

    return { shapes: shuffle(shapes) };
  },
  Component: ({ question: { shapes }, translate, displayMode }) => {
    const correctAnswer = [
      [[], []],
      [[], []]
    ] as [[triangleYes: string[], triangleNo: string[]], [quadYes: string[], quadNo: string[]]];

    shapes.forEach(val => {
      const rowIndex = val.shape === 'triangle' ? 0 : 1; // 0th column is triangle, 1st is quadrilateral
      const columnIndex = val.symmetry ? 0 : 1; // 0th column is one or more symmetry, 1st is none
      correctAnswer[rowIndex][columnIndex].push(val.svgName);
    });

    return (
      <QF9DragIntoTableOfGroups
        title={translate.instructions.dragCardsToSortTheShapes()}
        pdfTitle={translate.instructions.useCardsToSortTheShapes()}
        rowNames={[translate.keywords.Triangle(), translate.keywords.Quadrilateral()]}
        columnNames={[
          translate.tableHeaders.oneOrMoreLineOfSymmetry(),
          translate.tableHeaders.noLinesOfSymmetry()
        ]}
        testCorrect={correctAnswer}
        items={shapes.map(val => ({
          value: val.svgName,
          component: (
            <AssetSvg name={val.svgName as SvgName} height={displayMode === 'digital' ? 75 : 120} />
          )
        }))}
        actionPanelVariant="endMid"
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
        zoneCapacity={4}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'ayP',
  description: 'ayP',
  keywords: ['Lines of symmetry'],
  schema: z.object({
    shape: z.enum([
      'scaleneTriangles',
      'isoscelesTriangles',
      'equilateralTriangles',
      'squares',
      'rectangles',
      'rhombuses',
      'parallelograms',
      'irregularPentagons',
      'pentagons',
      'hexagons',
      'irregularHexagons'
    ]),
    svgName: z.string()
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'scaleneTriangles',
      'isoscelesTriangles',
      'equilateralTriangles',
      'squares',
      'rectangles',
      'rhombuses',
      'parallelograms',
      'irregularPentagons',
      'pentagons',
      'hexagons',
      'irregularHexagons'
    ] as const);

    const svgName = getShapeSvgName(shape);

    return { shape, svgName };
  },
  Component: props => {
    const {
      question: { shape, svgName },
      translate
    } = props;

    const linesOfSymmetry =
      shape === 'irregularPentagons' || shape === 'irregularHexagons'
        ? irregularSymmetryLines(svgName as SvgName)
        : regularSymmetryLines(shape);

    const translateMapping = (() => {
      switch (shape) {
        case 'pentagons':
          return 'regularPentagons';
        case 'hexagons':
          return 'regularHexagons';
        case 'irregularPentagons':
          return 'pentagons';
        case 'irregularHexagons':
          return 'hexagons';
        default:
          return shape;
      }
    })();

    return (
      <QF1ContentAndSentence
        sentence={'<ans />'}
        title={translate.instructions.howManyLinesOfSymmetryDoesShapeHave(
          translate.shapes[translateMapping](1)
        )}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[linesOfSymmetry.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <AssetSvg
            name={svgName as SvgName}
            height={dimens.height * 0.8}
            width={dimens.width * 0.8}
          />
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LinesOfSymmetry',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
