import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { View } from 'react-native';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg } from '../../../../assets/svg';
import { countRange } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'beq',
  description: 'beq',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z.object({
    numberOfCounters: numberEnum([4, 6, 8]),
    incorrectOptionAGroupAWrongCounter: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const numberOfCounters = getRandomFromArray([4, 6, 8] as const);
    const incorrectOptionAGroupAWrongCounter = randomIntegerInclusive(1, numberOfCounters - 1, {
      constraint: x => x !== numberOfCounters / 2
    });

    return { numberOfCounters, incorrectOptionAGroupAWrongCounter };
  },
  Component: props => {
    const {
      question: { numberOfCounters, incorrectOptionAGroupAWrongCounter },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctOption = { groups: [[numberOfCounters / 2], [numberOfCounters / 2]] };

    const incorrectOptions = [
      {
        groups: [
          [incorrectOptionAGroupAWrongCounter],
          [numberOfCounters - incorrectOptionAGroupAWrongCounter]
        ]
      },
      {
        groups:
          numberOfCounters === 6
            ? [[2], [2], [2]]
            : numberOfCounters === 4
            ? [[2], [1], [1]]
            : [[2], [2], [2], [2]]
      },
      { groups: [[numberOfCounters]] }
    ];

    const options = shuffle([correctOption, ...incorrectOptions], { random });

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheGroupThatShowsX(translate.fractions.halves(2))}
        pdfTitle={translate.ks1PDFInstructions.circleTheGroupThatShowsX(
          translate.fractions.halves(2)
        )}
        testCorrect={[correctOption]}
        numItems={4}
        renderItems={() => {
          return options.map(option => ({
            value: option,
            component: (
              <View style={{ flexDirection: 'row' }}>
                {option.groups.map((group, idx) => {
                  return (
                    <View
                      style={{
                        borderColor: 'black',
                        borderWidth: 3,
                        borderRadius: 12,
                        flexDirection: 'row',
                        margin: 1,
                        paddingTop: 2
                      }}
                      key={`group_${idx}`}
                    >
                      {group.map(num => {
                        return countRange(num).map(idx => (
                          <View key={`counters_${idx}`}>
                            <AssetSvg name="CounterRed" height={55} width={55} />
                          </View>
                        ));
                      })}
                    </View>
                  );
                })}
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'ber',
  description: 'ber',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z.object({
    numberOfCorrectAnswers: numberEnum([1, 2]),
    numberOfCounters: numberEnum([4, 6, 8, 10, 12, 14, 16]),
    incorrectOptionsRedCounters: z.array(z.number().int().min(1).max(16)),
    shouldAnswerRedsBeFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const numberOfCorrectAnswers = getRandomFromArray([1, 2] as const);
    const numberOfCounters = getRandomFromArray([4, 6, 8, 10, 12, 14, 16] as const);

    const incorrectOptionsRedCounters = randomUniqueIntegersInclusive(
      1,
      numberOfCounters,
      4 - numberOfCorrectAnswers,
      { constraint: x => x !== numberOfCounters / 2 }
    );

    const shouldAnswerRedsBeFirst = getRandomBoolean();

    return {
      numberOfCounters,
      numberOfCorrectAnswers,
      incorrectOptionsRedCounters,
      shouldAnswerRedsBeFirst
    };
  },
  Component: props => {
    const {
      question: {
        numberOfCounters,
        numberOfCorrectAnswers,
        shouldAnswerRedsBeFirst,
        incorrectOptionsRedCounters
      },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctOptions = countRange(numberOfCorrectAnswers).map(num => {
      return {
        numberOfRed: numberOfCounters / 2,
        numberOfWhites: numberOfCounters / 2,
        shouldAnswerRedsBeFirst: num === 1 ? shouldAnswerRedsBeFirst : !shouldAnswerRedsBeFirst
      };
    });

    const incorrectOptions = countRange(4 - numberOfCorrectAnswers).map((_, index) => {
      return {
        numberOfRed: incorrectOptionsRedCounters[index],
        numberOfWhites: numberOfCounters - incorrectOptionsRedCounters[index],
        shouldAnswerRedsBeFirst: getRandomBoolean()
      };
    });

    const options = [...correctOptions, ...incorrectOptions];

    const shuffledOptions = shuffle(options, { random });

    const title =
      numberOfCorrectAnswers === 1
        ? translate.ks1Instructions.selectTheGroupThatHasAXShaded(translate.fractions.halves(1))
        : translate.ks1Instructions.selectTheGroupsThatHaveAXShaded(translate.fractions.halves(1));

    const pdfTitle =
      numberOfCorrectAnswers === 1
        ? translate.ks1PDFInstructions.circleTheGroupThatHasAXShaded(translate.fractions.halves(1))
        : translate.ks1PDFInstructions.circleTheGroupsThatHaveAXShaded(
            translate.fractions.halves(1)
          );

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={correctOptions}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return shuffledOptions.map(option => ({
            value: option,
            component: (
              <View
                style={{
                  flexDirection: 'row',
                  maxWidth:
                    numberOfCounters === 10
                      ? dimens.width * 0.7
                      : numberOfCounters === 12
                      ? dimens.width * 0.8
                      : numberOfCounters === 14
                      ? dimens.width * 0.9
                      : dimens.width,
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                {countRange(option.numberOfRed).map((_, index) => {
                  return option.shouldAnswerRedsBeFirst ? (
                    <AssetSvg
                      key={`counters_red_${index}`}
                      name="CounterRed"
                      height={60}
                      width={60}
                    />
                  ) : (
                    <AssetSvg
                      key={`counters_white_${index}`}
                      name="CounterWhite"
                      height={60}
                      width={60}
                    />
                  );
                })}
                {countRange(option.numberOfWhites).map((_, index) => {
                  return option.shouldAnswerRedsBeFirst ? (
                    <AssetSvg
                      key={`counters_white_${index}`}
                      name="CounterWhite"
                      height={60}
                      width={60}
                    />
                  ) : (
                    <AssetSvg
                      key={`counters_red_${index}`}
                      name="CounterRed"
                      height={60}
                      width={60}
                    />
                  );
                })}
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bes',
  description: 'bes',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z.object({
    numberOfCorrectAnswers: numberEnum([1, 2]),
    options: z.array(z.array(z.enum(['CounterRed', 'CounterWhite'])))
  }),
  simpleGenerator: () => {
    const numberOfCorrectAnswers = getRandomFromArray([1, 2] as const);
    const numberOfCounters = getRandomFromArray([4, 6, 8] as const);

    const incorrectOptionsRedCounters = randomUniqueIntegersInclusive(
      1,
      numberOfCounters,
      4 - numberOfCorrectAnswers,
      { constraint: x => x !== numberOfCounters / 2 }
    );

    const checkSplitHalf = (
      firstHalf: ('CounterRed' | 'CounterWhite')[],
      secondHalf: ('CounterRed' | 'CounterWhite')[]
    ) => {
      return (
        new Set(firstHalf).size === 1 &&
        new Set(secondHalf).size === 1 &&
        firstHalf[0] !== secondHalf[0]
      );
    };

    const correctFormations = countRange(numberOfCorrectAnswers).map((_, index) => {
      const array =
        index === 0
          ? [
              ...countRange(numberOfCounters / 2).map(() => 'CounterRed' as const),
              ...countRange(numberOfCounters / 2).map(() => 'CounterWhite' as const)
            ]
          : [
              ...countRange(numberOfCounters / 2).map(() => 'CounterWhite' as const),
              ...countRange(numberOfCounters / 2).map(() => 'CounterRed' as const)
            ];
      let shuffledArray = [];
      do {
        shuffledArray = shuffle(array);
      } while (
        checkSplitHalf(
          shuffledArray.slice(0, shuffledArray.length / 2),
          shuffledArray.slice(shuffledArray.length / 2)
        )
      );
      return shuffledArray;
    });

    const incorrectFormations = countRange(4 - numberOfCorrectAnswers).map((_, index) => {
      return shuffle([
        ...countRange(incorrectOptionsRedCounters[index]).map(() => 'CounterRed' as const),
        ...countRange(numberOfCounters - incorrectOptionsRedCounters[index]).map(
          () => 'CounterWhite' as const
        )
      ]);
    });

    const options = shuffle([...correctFormations, ...incorrectFormations]);
    return {
      numberOfCorrectAnswers,
      options
    };
  },
  Component: props => {
    const {
      question: { numberOfCorrectAnswers, options },
      translate
    } = props;
    const title =
      numberOfCorrectAnswers === 1
        ? translate.ks1Instructions.selectTheGroupThatHasAXShaded(translate.fractions.halves(1))
        : translate.ks1Instructions.selectTheGroupsThatHaveAXShaded(translate.fractions.halves(1));
    const pdfTitle =
      numberOfCorrectAnswers === 1
        ? translate.ks1PDFInstructions.circleTheGroupThatHasAXShaded(translate.fractions.halves(1))
        : translate.ks1PDFInstructions.circleTheGroupsThatHaveAXShaded(
            translate.fractions.halves(1)
          );
    const answer = options.filter(
      arr =>
        arr.filter(str => str === 'CounterRed').length ===
        arr.filter(str => str === 'CounterWhite').length
    );
    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={answer}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map(option => {
            return {
              value: option,
              component: (
                <View
                  style={{
                    flexDirection: 'row',
                    maxWidth: dimens.width
                  }}
                >
                  {option.map((counter, index) => {
                    return (
                      <AssetSvg key={`counter_${index}`} name={counter} height={55} width={55} />
                    );
                  })}
                </View>
              )
            };
          });
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAHalfOfAQuantity',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
