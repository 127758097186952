import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  countRange,
  sortNumberArray,
  sumNumberArray
} from '../../../../utils/collections';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  colors,
  drawPieChartColors,
  drawPieChartColorsArray,
  drawPieChartColorsSchema
} from '../../../../theme/colors';
import PieChart from '../../../../components/question/representations/PieChart';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { generateRandomPartitions } from '../../../../utils/graphs';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import {
  getRandomUniqueIceCreamFlavourNames,
  iceCreamFlavoursAsWord,
  iceCreamFlavoursSchema
} from '../../../../utils/food';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { all, create, number } from 'mathjs';
import { lowercase } from 'typesafe-i18n/formatters';

////
// Questions
////

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

const colorOptions = [
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Purple',
  'Blue',
  'Grey',
  'White'
] as const;
const colorOptionsLowercase = {
  Red: 'red',
  Orange: 'orange',
  Yellow: 'yellow',
  Green: 'green',
  Purple: 'purple',
  Blue: 'blue',
  Grey: 'grey',
  White: 'white'
} as const;

const colorMapping = { ...drawPieChartColors, Grey: colors.greys400, White: 'white' };

const Question1 = newQuestionContent({
  uid: 'aXw',
  description: 'aXw',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Greater',
    'Equal parts',
    'Unequal parts',
    'Percentage',
    'Amount'
  ],
  questionHeight: 900,
  schema: z
    .object({
      flavours: z
        .array(iceCreamFlavoursSchema)
        .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
      people: numberEnum([10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000]),
      quantities: z.array(z.number().int().min(1).max(500)),
      colors: z.array(drawPieChartColorsSchema),
      flavourIndex: z.number().int().min(0).max(5),
      items: z.number().int().min(1).max(500).array()
    })
    .refine(
      val => val.quantities.every(x => x / val.people >= 0.1),
      'should all be greater than or equal to 10 %'
    )
    .refine(
      val =>
        val.quantities.every(x => {
          const tenPerc = val.people / 10;
          return x % tenPerc === 0;
        }),
      'all multiples of 10 %'
    ),
  simpleGenerator: () => {
    const people = getRandomFromArray([
      10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000
    ] as const);
    const parts = randomIntegerInclusive(4, 5);

    const min = people / 10;
    const quantities = generateRandomPartitions({
      total: people,
      parts: parts,
      min: min,
      max: people / 2,
      step: min
    });

    const uniqueQuantities = [...new Set(quantities)];
    const otherAnswers = randomUniqueIntegersInclusiveStep(
      min,
      people / 2,
      min,
      4 - uniqueQuantities.length,
      {
        constraint: x => !uniqueQuantities.includes(x)
      }
    );

    const items = shuffle([...uniqueQuantities, ...otherAnswers]);
    const flavours = getRandomUniqueIceCreamFlavourNames(parts);
    const colors = getRandomSubArrayFromArray(drawPieChartColorsArray, parts);
    const flavourIndex = randomIntegerInclusive(0, parts - 1);

    return { colors, flavours, people, quantities, flavourIndex, items };
  },
  Component: props => {
    const {
      question: { colors, flavours, people, quantities, flavourIndex, items },
      translate
    } = props;

    const translatedFlavours = flavours.map(val => iceCreamFlavoursAsWord(val, translate, true));
    const translateAcronyms = flavours.map(val => translate.acronyms[val]());

    const options = quantities.map((val, i) => {
      return {
        ratioOfSlices: val,
        color: drawPieChartColors[colors[i]],
        innerLabel: translateAcronyms[i]
      };
    });

    const keys = quantities.map((_val, i) => {
      return {
        color: drawPieChartColors[colors[i]],
        label: `${translatedFlavours[i]} (${translateAcronyms[i]})`
      };
    });

    const ans = quantities[flavourIndex];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.xPeoplePickedIceCreamSelectHowManyChoseY(
          people,
          translatedFlavours[flavourIndex]
        )}
        pdfTitle={translate.instructions.xPeoplePickedIceCreamCircleHowManyChoseY(
          people,
          translatedFlavours[flavourIndex]
        )}
        selectables={Object.fromEntries(
          items.map((item, i) => [['A', 'B', 'C', 'D'][i], item.toLocaleString()])
        )}
        questionHeight={900}
        correctAnswer={[['A', 'B', 'C', 'D'][items.indexOf(ans)]]}
        leftContent={
          <MeasureView>
            {dimens => (
              <PieChart
                pieOptions={options}
                radius={dimens.height * 0.4}
                keyValues={keys}
                showPercentages
                pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aXx',
  description: 'aXx',
  questionHeight: 900,
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Greater',
    'Equal parts',
    'Unequal parts',
    'Percentage',
    'Amount',
    'Subtraction',
    'Difference',
    'How many more'
  ],
  schema: z.object({
    flavours: z
      .array(iceCreamFlavoursSchema)
      .refine(arrayHasNoDuplicates, 'duplicates are not allowed'),
    people: numberEnum([10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000]),
    quantities: z.array(z.number().int().min(1).max(500)),
    colors: z.array(drawPieChartColorsSchema),
    flavourIndexes: z.number().int().min(0).max(4).array().length(2),
    answerDifferences: z.array(z.number().int().min(0).max(500))
  }),
  simpleGenerator: () => {
    const people = getRandomFromArray([
      10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000
    ] as const);
    const parts = randomIntegerInclusive(4, 5);
    const flavours = getRandomUniqueIceCreamFlavourNames(parts);
    const flavourIndexes = randomUniqueIntegersInclusive(0, parts - 1, 2);
    const colors = getRandomSubArrayFromArray(drawPieChartColorsArray, parts);

    const min = people / 10;
    const quantities = generateRandomPartitions({
      total: people,
      parts: parts,
      min: min,
      max: people / 2,
      step: min
    });

    const differences = [];
    // get all differences
    for (let i = 0; i < parts - 1; i += 1) {
      for (let j = i + 1; j < parts; j += 1) {
        differences.push(Math.abs(quantities[i] - quantities[j]));
      }
    }

    const uniqueDifferences = [...new Set(differences)];
    const otherAnswers = randomUniqueIntegersInclusive(0, parts, 4 - uniqueDifferences.length, {
      constraint: x => !uniqueDifferences.includes(x)
    });

    const answerDifferences = shuffle([...uniqueDifferences, ...otherAnswers]);

    return {
      people,
      flavours,
      quantities,
      colors,
      flavourIndexes,
      answerDifferences
    };
  },
  Component: props => {
    const {
      question: { people, flavours, quantities, colors, flavourIndexes, answerDifferences },
      translate
    } = props;

    const translatedFlavours = flavours.map(val => iceCreamFlavoursAsWord(val, translate, true));
    const translateAcronyms = flavours.map(val => translate.acronyms[val]());

    const options = quantities.map((val, i) => {
      return {
        ratioOfSlices: val,
        color: drawPieChartColors[colors[i]],
        innerLabel: translateAcronyms[i]
      };
    });

    const keys = quantities.map((_val, i) => {
      return {
        color: drawPieChartColors[colors[i]],
        label: `${translatedFlavours[i]} (${translateAcronyms[i]})`
      };
    });

    const ans = Math.abs(quantities[flavourIndexes[0]] - quantities[flavourIndexes[1]]);

    const indexOrder = sortNumberArray(
      [quantities[flavourIndexes[0]], quantities[flavourIndexes[1]]],
      'descending'
    );
    const flavoursOrdered = arraysHaveSameContents(
      [quantities[flavourIndexes[0]], quantities[flavourIndexes[1]]],
      indexOrder
    )
      ? [translatedFlavours[flavourIndexes[0]], translatedFlavours[flavourIndexes[1]]]
      : [translatedFlavours[flavourIndexes[1]], translatedFlavours[flavourIndexes[0]]];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.xPeoplePickedIceCreamSelectDifferenceBetweenYZ(
          people,
          flavoursOrdered[0],
          flavoursOrdered[1]
        )}
        pdfTitle={translate.instructions.xPeoplePickedIceCreamCircleDifferenceBetweenYZ(
          people,
          flavoursOrdered[1],
          flavoursOrdered[0]
        )}
        selectables={Object.fromEntries(
          answerDifferences.map((selectable, i) => [
            ['A', 'B', 'C', 'D'][i],
            selectable.toLocaleString()
          ])
        )}
        questionHeight={900}
        leftContent={
          <MeasureView>
            {dimens => (
              <PieChart
                pieOptions={options}
                radius={dimens.height * 0.4}
                keyValues={keys}
                showPercentages
                pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
              />
            )}
          </MeasureView>
        }
        correctAnswer={[['A', 'B', 'C', 'D'][answerDifferences.indexOf(ans)]]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aXy',
  description: 'aXy',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Greater',
    'Equal parts',
    'Unequal parts',
    'Percentage',
    'Amount'
  ],
  questionHeight: 1200,
  schema: z
    .object({
      activities: z
        .array(z.enum(['Reading', 'Cricket', 'Swimming', 'Dancing', 'Football', 'Art']))
        .refine(arrayHasNoDuplicates, 'activities must be different'),
      children: numberEnum([20, 40, 60, 80, 100, 200, 300, 400, 500, 1000]),
      quantities: z.array(z.number().int().min(2).max(1000)),
      colors: z.array(drawPieChartColorsSchema),
      activityIndex: z.number().int().min(0).max(5),
      items: z.number().int().min(2).max(500).array().length(4)
    })
    .refine(
      val => val.quantities.every(x => x / val.children >= 0.1),
      'should all be greater than or equal to 10 %'
    )
    .refine(
      val =>
        val.quantities.every(x => {
          const fivePerc = val.children / 20;
          return x % fivePerc === 0;
        }),
      'all multiples of 5 %'
    ),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(4, 6);
    const activities = getRandomSubArrayFromArray(
      ['Reading', 'Cricket', 'Swimming', 'Dancing', 'Football', 'Art'] as const,
      parts
    );
    const colors = getRandomSubArrayFromArray(drawPieChartColorsArray, parts);
    const children = getRandomFromArray([20, 40, 60, 80, 100, 200, 300, 400, 500, 1000] as const);

    // minimum of 10%
    const min = children / 10;
    const quantities = rejectionSample(
      () =>
        generateRandomPartitions({
          total: children,
          parts: parts,
          min: min,
          max: children / 2,
          step: children / 20
        }),
      val => {
        const unique = [...new Set(val)];
        return unique.length >= 4;
      }
    );

    const activityIndex = randomIntegerInclusive(0, parts - 1);
    const items = getRandomSubArrayFromArray([...new Set(quantities)], 4);

    return { colors, activities, children, quantities, activityIndex, items };
  },
  Component: props => {
    const {
      question: { colors, activities, children, quantities, activityIndex, items },
      translate,
      displayMode
    } = props;

    const options = quantities.map((val, i) => {
      return {
        ratioOfSlices: val,
        color: drawPieChartColors[colors[i]],
        innerLabel: translate.acronyms[activities[i]]()
      };
    });

    const activitiesLowercase = {
      Reading: 'reading',
      Cricket: 'cricket',
      Swimming: 'swimming',
      Dancing: 'dancing',
      Football: 'football',
      Art: 'art'
    } as const;

    const keys = quantities.map((_val, i) => {
      return {
        color: drawPieChartColors[colors[i]],
        label: `${translate.activities[activitiesLowercase[activities[i]]]()} (${translate.acronyms[
          activities[i]
        ]()})`
      };
    });

    const correctAnswer = quantities[activityIndex].toLocaleString();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.xChildrenChoseActivitiesDragCardsToShowHowManyY(children)}
        pdfTitle={translate.instructions.xChildrenChoseActivitiesUseCardsToShowHowManyY(children)}
        items={shuffle(
          items.map(i => i.toLocaleString()),
          { random: seededRandom(props.question) }
        )}
        questionHeight={1200}
        testCorrect={[correctAnswer]}
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <PieChart
            pieOptions={options}
            radius={dimens.height * (displayMode === 'digital' ? 0.5 : 0.4)}
            keyValues={keys}
            showPercentages
            pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
          />
        )}
        sentence={translate.answerSentences.ansChildrenChoseX(
          translate.activities[activities[activityIndex]]()
        )}
        sentenceStyle={{ alignSelf: 'flex-start' }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aXz',
  description: 'aXz',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Greater',
    'Equal parts',
    'Unequal parts',
    'Percentage',
    'Amount'
  ],
  schema: z
    .object({
      activities: z
        .array(z.enum(['Reading', 'Cricket', 'Swimming', 'Dancing', 'Football', 'Art']))
        .refine(arrayHasNoDuplicates, 'activities must be different'),
      numberOfActivities: z.number().int().min(4).max(6),
      total: numberEnum([20, 40, 60, 80, 100, 200, 300, 400, 500, 1000]),
      quantities: z.array(z.number().int().min(1).max(700)),
      colors: z.array(drawPieChartColorsSchema),
      answerActivitiesIndex: z.array(z.number().int().min(0).max(5)).length(2)
    })
    .refine(
      val => val.quantities.every(x => x / val.total >= 0.1),
      'should all be greater than or equal to 10 %'
    )
    .refine(
      val =>
        val.quantities.every(x => {
          const fivePerc = val.total / 20;
          return x % fivePerc === 0;
        }),
      'all multiples of 5 %'
    ),
  simpleGenerator: () => {
    const numberOfActivities = randomIntegerInclusive(4, 6);
    const activities = getRandomSubArrayFromArray(
      ['Reading', 'Cricket', 'Swimming', 'Dancing', 'Football', 'Art'] as const,
      numberOfActivities
    );

    const total = getRandomFromArray([20, 40, 60, 80, 100, 200, 300, 400, 500, 1000] as const);
    const tenPerc = total / 10;
    const seventyPerc = total * 0.7;
    const fivePerc = total / 20;
    const fiftyPerc = total / 2;
    const fourtyPerc = total * 0.4;

    const max =
      numberOfActivities === 4 ? seventyPerc : numberOfActivities === 5 ? fiftyPerc : fourtyPerc;

    const value1 = randomIntegerInclusiveStep(tenPerc, max, fivePerc);
    const value2 = randomIntegerInclusiveStep(
      tenPerc,
      (total - value1) / (numberOfActivities - 1),
      fivePerc
    );
    const value3 = randomIntegerInclusiveStep(
      tenPerc,
      (total - value1 - value2) / (numberOfActivities - 2),
      fivePerc
    );
    const value4 =
      numberOfActivities === 4
        ? total - value1 - value2 - value3
        : randomIntegerInclusiveStep(
            tenPerc,
            (total - value1 - value2 - value3) / (numberOfActivities - 3),
            fivePerc
          );
    const value5 =
      numberOfActivities === 4
        ? 0
        : numberOfActivities === 5
        ? total - value1 - value2 - value3 - value4
        : randomIntegerInclusiveStep(
            tenPerc,
            (total - value1 - value2 - value3 - value4) / (numberOfActivities - 4),
            fivePerc
          );
    const value6 =
      numberOfActivities === 4 || numberOfActivities === 5
        ? 0
        : total - value1 - value2 - value3 - value4 - value5;

    const values = [value1, value2, value3, value4, value5, value6].filter(i => i !== 0);

    const quantities = shuffle(values);
    const colors = getRandomSubArrayFromArray(drawPieChartColorsArray, numberOfActivities);
    const answerActivitiesIndex = randomUniqueIntegersInclusive(0, numberOfActivities - 1, 2);

    return { numberOfActivities, activities, quantities, colors, total, answerActivitiesIndex };
  },
  Component: props => {
    const {
      question: {
        numberOfActivities,
        activities,
        quantities,
        colors,
        total,
        answerActivitiesIndex
      },
      translate
    } = props;

    const options = countRange(numberOfActivities).map(i => {
      return {
        ratioOfSlices: quantities[i],
        color: drawPieChartColors[colors[i]],
        innerLabel: translate.acronyms[activities[i]]()
      };
    });

    const keys = countRange(numberOfActivities).map(i => {
      return {
        color: drawPieChartColors[colors[i]],
        label: `${lowercase(translate.activities[activities[i]]())} (${translate.acronyms[
          activities[i]
        ]()})`
      };
    });

    const correctAnswer = sumNumberArray(answerActivitiesIndex.map(i => quantities[i]));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.xChildrenChoseActivityHowManyChoseYZ(
          total,
          translate.activities[activities[answerActivitiesIndex[0]]](),
          translate.activities[activities[answerActivitiesIndex[1]]]()
        )}
        testCorrect={[correctAnswer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <PieChart
            pieOptions={options}
            radius={dimens.height * 0.5}
            keyValues={keys}
            showPercentages
            pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
          />
        )}
        sentence={`<ans/>`}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aXA',
  description: 'aXA',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Greater',
    'Equal parts',
    'Unequal parts',
    'Percentage',
    'Amount',
    'Total'
  ],
  schema: z
    .object({
      colors: z
        .array(z.enum(colorOptions))
        .refine(arrayHasNoDuplicates, 'colors must be different'),
      marbles: numberEnum([
        100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 2000, 3000
      ]),
      quantities: z.array(z.number().int().min(2).max(1000)),
      colorIndex: z.number().int().min(0).max(5)
    })
    .refine(
      val => val.quantities.every(x => x / val.marbles >= 0.1),
      'should all be greater than or equal to 10 %'
    )
    .refine(
      val =>
        val.quantities.every(x => {
          const onePerc = val.marbles / 100;
          return x % onePerc === 0;
        }),
      'all multiples of 1 %'
    ),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(4, 6);
    const colors = getRandomSubArrayFromArray(colorOptions, parts);
    const marbles = getRandomFromArray([
      100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 2000, 3000
    ] as const);

    // minimum of 10%
    const min = marbles / 10;
    const quantities = generateRandomPartitions({
      total: marbles,
      parts: parts,
      min: min,
      max: 1000,
      step: marbles / 100
    });

    const colorIndex = randomIntegerInclusive(0, parts - 1);

    return { colors, marbles, quantities, colorIndex };
  },
  Component: props => {
    const {
      question: { colors, marbles, quantities, colorIndex },
      translate
    } = props;

    const options = quantities.map((val, i) => {
      return {
        ratioOfSlices: val,
        color: colorMapping[colors[i]],
        innerLabel: translate.acronyms[colors[i]]()
      };
    });

    const keys = quantities.map((_val, i) => {
      return {
        color: colorMapping[colors[i]],
        label: `${translate.colors[colorOptionsLowercase[colors[i]]]()} (${translate.acronyms[
          colors[i]
        ]()})`
      };
    });

    const correctAnswer = quantities[colorIndex];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thereAreXMarblesInAJarHowManyColorY(
          marbles,
          translate.colors[colors[colorIndex]]()
        )}
        testCorrect={[correctAnswer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <PieChart
            pieOptions={options}
            radius={dimens.height * 0.5}
            keyValues={keys}
            showPercentages
            pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
          />
        )}
        sentence={`<ans/>`}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aXB',
  description: 'aXB',
  keywords: [
    'Pie chart',
    'Parts',
    'Whole',
    'Greater',
    'Equal parts',
    'Unequal parts',
    'Percentage',
    'Amount',
    'Total'
  ],
  questionHeight: 900,
  schema: z
    .object({
      colors: z
        .array(z.enum(colorOptions))
        .refine(arrayHasNoDuplicates, 'colors must be different'),
      marbles: numberEnum([
        100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 2000, 3000
      ]),
      quantities: z.array(z.number().int().min(1).max(1000)),
      colorIndex: z.number().int().min(0).max(5),
      items: z.number().int().min(1).max(1000).array()
    })
    .refine(
      val => val.quantities.every(x => x / val.marbles >= 0.1),
      'should all be greater than or equal to 10 %'
    )
    .refine(
      val =>
        val.quantities.every(x => {
          const onePerc = val.marbles / 100;
          return x % onePerc === 0;
        }),
      'all multiples of 1 %'
    ),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(4, 6);
    const colors = getRandomSubArrayFromArray(colorOptions, parts);
    const marbles = getRandomFromArray([
      100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 2000, 3000
    ] as const);

    // minimum of 10%
    const quantities = rejectionSample(
      () =>
        generateRandomPartitions({
          total: marbles,
          parts: parts,
          min: marbles / 10,
          max: 1000,
          step: marbles / 100
        }),
      val => {
        const unique = [...new Set(val)];
        return unique.length >= 4;
      }
    );

    const uniqueQuantities = [...new Set(quantities)];
    const colorIndex = randomIntegerInclusive(0, parts - 1);

    const items = getRandomSubArrayFromArray(
      uniqueQuantities.filter(i => i !== quantities[colorIndex]),
      3
    );

    return { colors, marbles, quantities, colorIndex, items };
  },
  Component: props => {
    const {
      question: { colors, marbles, quantities, colorIndex, items },
      translate
    } = props;

    const translatedColors = colors.map(val => translate.colors[colorOptionsLowercase[val]]());
    const translateAcronyms = colors.map(val => translate.acronyms[val]());

    const options = quantities.map((val, i) => {
      return {
        ratioOfSlices: val,
        color: colorMapping[colors[i]],
        innerLabel: translateAcronyms[i]
      };
    });

    const keys = quantities.map((_val, i) => {
      return {
        color: colorMapping[colors[i]],
        label: `${translatedColors[i]} (${translateAcronyms[i]})`
      };
    });

    const incorrectItems = items.map(val => {
      const colorMarbles = quantities[colorIndex];
      const colorPercent = number(math.evaluate(`(${quantities[colorIndex]} / ${marbles}) * 100`));
      const onePercentage = number(math.evaluate(`${colorMarbles} / ${colorPercent}`));
      return number(math.evaluate(`${onePercentage} * ${val}`));
    });

    const selectables = shuffle(
      [
        ['A', marbles.toLocaleString()],
        ...incorrectItems.map((val, i) => [['B', 'C', 'D'][i], val.toLocaleString()])
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.xColorMarblesSelectHowManyInTotal(
          quantities[colorIndex],
          translatedColors[colorIndex]
        )}
        pdfTitle={translate.instructions.xColorMarblesCircleHowManyInTotal(
          quantities[colorIndex],
          translatedColors[colorIndex]
        )}
        selectables={Object.fromEntries(selectables)}
        questionHeight={900}
        correctAnswer={['A']}
        leftContent={
          <MeasureView>
            {dimens => (
              <PieChart
                pieOptions={options}
                radius={dimens.height * 0.4}
                keyValues={keys}
                showPercentages
                pieChartContainerStyle={{ flexDirection: 'row', gap: 50, alignItems: 'center' }}
              />
            )}
          </MeasureView>
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PieChartsWithPercentages',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
