import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcG',
  description: 'bcG',
  keywords: ['Double', 'Counters', 'Ten frame'],
  schema: z.object({
    num1: z.number().int().min(1).max(10),
    counterColors: z.array(z.enum(['red', 'yellow', 'blue', 'green'])).length(2),
    itemOrdering: z.enum(['rowFirst', 'columnFirst'])
  }),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(1, 10);
    const counterColors = getRandomSubArrayFromArray(
      ['red', 'yellow', 'blue', 'green'] as const,
      2
    );
    const itemOrdering = getRandomFromArray(['rowFirst', 'columnFirst'] as const);

    return {
      num1,
      counterColors,
      itemOrdering
    };
  },
  Component: ({ question, translate }) => {
    const { num1, counterColors, itemOrdering } = question;
    const [counter1, counter2] = counterColors;
    const numberOfTenFrames: 1 | 2 = num1 * 2 > 10 ? 2 : 1;
    /** Maps from indices to a position from 1 to 20. */
    const indicesToPosition = (tenFrameIndex: number, index: number) => {
      if (numberOfTenFrames === 1) {
        return index + 1;
      }
      if (itemOrdering === 'rowFirst') {
        // Count first row of both ten frames before looking at second row
        // So first ten frame is 1 to 5 and 11 to 15, and second ten frame is 6 to 10 and 16 to 20
        return index + 1 <= 5
          ? tenFrameIndex * 5 + index + 1
          : 10 + tenFrameIndex * 5 + (index - 5) + 1;
      } else {
        // First ten frame is 1 to 10, and second ten frame is 11 to 20
        return tenFrameIndex * 10 + index + 1;
      }
    };
    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragCountersToShowDoubleX(num1)}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToShowDoubleX(num1)}
        items={[counter2]}
        itemOrdering={itemOrdering}
        initialState={(tenFrameIndex, index) => {
          const position = indicesToPosition(tenFrameIndex, index);
          return position <= num1 ? counter1 : null;
        }}
        tenFrameFlexDirection="row"
        interactiveCells="notInitial"
        numberOfTenFrames={numberOfTenFrames}
        testCorrect={totalCountersIs(num1 * 2)}
        exampleCorrectAnswer={(tenFrameIndex, index) => {
          const position = indicesToPosition(tenFrameIndex, index);
          return position <= num1 ? counter1 : position <= num1 * 2 ? counter2 : null;
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcH',
  description: 'bcH',
  keywords: ['Double'],
  schema: z
    .object({
      numberA: z.number().int().min(0).max(10),
      numberB: z.number().int().min(0).max(10)
    })
    .refine(val => val.numberA !== val.numberB, 'numberA and numberB cannot be the same.'),
  simpleGenerator: () => {
    const [numberA, numberB] = randomUniqueIntegersInclusive(2, 10, 2);
    return {
      numberA,
      numberB
    };
  },
  Component: props => {
    const {
      question: { numberA, numberB },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctA = numberA * 2;
    const correctB = numberB * 2;

    const items = [correctA, correctB];

    const possibleIncorrectAnswers1 = [numberA + 2, numberB + 2];

    const incorrectAnswer1 = getRandomFromArray(
      // Need to filter to prevent possible duplicate answers.
      possibleIncorrectAnswers1.filter(num => !items.includes(num)),
      { random }
    ) as number;

    items.push(incorrectAnswer1);

    const possibleIncorrectAnswers2 = [correctA + 1, correctA - 1, correctB + 1, correctB - 1];

    const incorrectAnswer2 = getRandomFromArray(
      // Need to filter to prevent possible duplicate answers.
      possibleIncorrectAnswers2.filter(num => !items.includes(num)),
      { random }
    ) as number;

    items.push(incorrectAnswer2);

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheSentences()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentences()}
        sentences={[numberA, numberB].map(num => translate.ks1AnswerSentences.doubleNumIsAns(num))}
        items={shuffle(items, { random }).map(num => num.toLocaleString())}
        testCorrect={[[correctA.toLocaleString()], [correctB.toLocaleString()]]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcI',
  description: 'bcI',
  keywords: ['Double', 'Halve'],
  schema: z.object({
    answer: z.number().int().min(0).max(10)
  }),
  simpleGenerator: () => {
    const answer = randomIntegerInclusive(0, 10);
    return { answer };
  },

  Component: props => {
    const {
      question: { answer },
      translate
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={translate.ks1AnswerSentences.doubleAnsIsNum(answer * 2)}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Doubles',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
