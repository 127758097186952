import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg } from '../../../../assets/svg';
import {
  equalShapePartsSchema,
  getRandomEqualShapePart,
  getRandomUnequalShapePart,
  shapePartsArray,
  shapePartsSchema,
  unequalShapePartsSchema
} from '../../../../utils/shapes';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { getObjectSvgName } from '../../../../utils/objectsImages';
import { View } from 'react-native';
import { getMultiLinkCubeSvgName } from '../../../../utils/multiLinkCubesImages';
import { numberToBase10Object } from '../../../../utils/math';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkb',
  description: 'bkb',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole'],
  questionHeight: 1200,
  schema: z.object({
    isEqual: z.boolean(),
    correctValues: z.enum(['A', 'B', 'C', 'D']).array(),
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.literal('Objects'),
        items: z.array(
          z.object({
            value: z.enum(['A', 'B', 'C', 'D']),
            numberOfItems: z.number().int().min(1).max(6).array(),
            itemName: z.enum([
              'Flowers',
              'Cookies',
              'Muffins',
              'Eggs',
              'Pencils',
              'Apples',
              'Bananas'
            ])
          })
        )
      }),
      z.object({
        variant: z.literal('Cubes'),
        items: z.array(
          z.object({
            value: z.enum(['A', 'B', 'C', 'D']),
            color: z.enum(['Orange', 'Blue', 'Purple', 'Green', 'Red']),
            numberOfCubes: z.number().int().min(1).max(6).array()
          })
        )
      }),
      z.object({
        variant: z.literal('Base10'),
        items: z.array(
          z.object({
            value: z.enum(['A', 'B', 'C', 'D']),
            numberOfTensAndOnes: z
              .object({
                tens: z.number().int().min(0).max(2),
                ones: z.number().int().min(0).max(10)
              })
              .array()
          })
        )
      })
    ])
  }),
  simpleGenerator: () => {
    const isEqual = getRandomBoolean();

    const numberOfCorrectAnswers = getRandomFromArray([1, 2, 3, 4] as const);

    const values = ['A', 'B', 'C', 'D'] as const;

    const correctValues = values.slice(0, numberOfCorrectAnswers);

    const incorrectAnswer = values.slice(numberOfCorrectAnswers);

    const variant = getRandomFromArray(['Object', 'Cubes', 'Base10'] as const);

    const item = getRandomFromArray([
      'Flowers',
      'Cookies',
      'Muffins',
      'Eggs',
      'Pencils',
      'Apples',
      'Bananas'
    ] as const);

    const valuesToMapEqual = isEqual ? correctValues : incorrectAnswer;
    const valuesToMapUnequal = isEqual ? incorrectAnswer : correctValues;

    const allEqualObjects = valuesToMapEqual.map(value => {
      const numberOfItemsPerSvg = randomIntegerInclusive(1, 6);
      const numberOfSvg = randomIntegerInclusive(2, 6);
      return {
        value,
        numberOfItems: filledArray(numberOfItemsPerSvg, numberOfSvg),
        itemName: item
      };
    });
    const nonEqualObjects = valuesToMapUnequal.map(value => {
      const numberOfEqualSvgs = randomIntegerInclusive(1, 5);
      const numberOfEqualItemsPerSvg = randomIntegerInclusive(1, 6);
      const arrayOfCorrectAmounts = filledArray(numberOfEqualItemsPerSvg, numberOfEqualSvgs);

      const numberOfIncorrectSvgs = randomIntegerInclusive(1, 5, {
        constraint: x => x + numberOfEqualSvgs <= 6
      });
      const numberOfIncorrectItemsPerSvg = filledArray(
        randomIntegerInclusive(1, 6, { constraint: x => x !== numberOfEqualItemsPerSvg }),
        numberOfIncorrectSvgs
      );

      const numberOfItems = shuffle([...arrayOfCorrectAmounts, ...numberOfIncorrectItemsPerSvg]);

      return {
        value,
        numberOfItems: numberOfItems,
        itemName: item
      };
    });

    const equalCubeTowers = valuesToMapEqual.map(value => {
      const amountOfTower = randomIntegerInclusive(2, 6);
      const amountOfBlocksPerTower = randomIntegerInclusive(1, 6);
      const color = getRandomFromArray(['Orange', 'Blue', 'Purple', 'Green', 'Red'] as const);

      return {
        value,
        numberOfCubes: filledArray(amountOfBlocksPerTower, amountOfTower),
        color
      };
    });

    const nonEqualCubeTowers = valuesToMapUnequal.map(value => {
      const amountOfEqualTowers = randomIntegerInclusive(1, 5);
      const amountOfBlocksPerEqualTower = randomIntegerInclusive(1, 6);
      const arrayOfCorrectAmounts = filledArray(amountOfBlocksPerEqualTower, amountOfEqualTowers);

      const color = getRandomFromArray(['Orange', 'Blue', 'Purple', 'Green', 'Red'] as const);

      const amountOfUnequalTowers = randomIntegerInclusive(1, 5, {
        constraint: x => x + amountOfEqualTowers <= 6
      });

      const amountOfBlocksPerUnequalTower = filledArray(
        randomIntegerInclusive(1, 6, { constraint: x => x !== amountOfBlocksPerEqualTower }),
        amountOfUnequalTowers
      );

      const numberOfCubes = shuffle([...arrayOfCorrectAmounts, ...amountOfBlocksPerUnequalTower]);

      return { value, numberOfCubes, color };
    });

    const equalBase10 = rejectionSample(
      () => {
        const equalBase10 = valuesToMapEqual.map(value => {
          const numberOfObjects = randomIntegerInclusive(2, 4);

          const numberOfTens = randomIntegerInclusive(0, 2);
          const numberOfOnes =
            numberOfTens === 0
              ? randomIntegerInclusive(1, 10)
              : numberOfTens === 1
              ? randomIntegerInclusive(1, 5)
              : 0;

          const numberOfTensAndOnes = filledArray(
            { tens: numberOfTens, ones: numberOfOnes },
            numberOfObjects
          );

          return { value, numberOfTensAndOnes };
        });

        return equalBase10;
      },
      equalBase10 =>
        arrayHasNoDuplicates(
          equalBase10.map(
            ({ numberOfTensAndOnes }) =>
              numberOfTensAndOnes.map(({ ones, tens }) => ones + tens * 10)[0]
          )
        )
    );

    const unequalBase10 = valuesToMapUnequal.map(value => {
      const unequalNumbers = randomUniqueIntegersInclusive(1, 20, 4);

      const unequalBase10 = unequalNumbers.map(num => {
        const base10 = numberToBase10Object(num);
        return { tens: base10.tens ?? 0, ones: base10.ones ?? 0 };
      });

      return { value, numberOfTensAndOnes: unequalBase10 };
    });

    const objectItems = shuffle([...allEqualObjects, ...nonEqualObjects]);
    const cubeItems = shuffle([...equalCubeTowers, ...nonEqualCubeTowers]);
    const base10Items = shuffle([...equalBase10, ...unequalBase10]);

    const subQuestion =
      variant === 'Cubes'
        ? { variant: 'Cubes' as const, items: cubeItems }
        : variant === 'Base10'
        ? { variant: 'Base10' as const, items: base10Items }
        : { variant: 'Objects' as const, items: objectItems };

    return { isEqual, correctValues, subQuestion };
  },
  Component: props => {
    const {
      question: { isEqual, correctValues, subQuestion },
      translate,
      displayMode
    } = props;
    const only1Ans = correctValues.length === 1;
    const title = isEqual
      ? only1Ans
        ? 'selectThePictureThatShowsEqualGroups'
        : 'selectThePicturesThatShowEqualGroups'
      : only1Ans
      ? 'selectThePictureThatShowsUnequalGroups'
      : 'selectThePicturesThatShowUnequalGroups';

    const pdfTitle = isEqual
      ? only1Ans
        ? 'tickThePictureThatShowsEqualGroups'
        : 'tickThePicturesThatShowEqualGroups'
      : only1Ans
      ? 'tickThePictureThatShowsUnequalGroups'
      : 'tickThePicturesThatShowUnequalGroups';

    const getSvgNames = (
      itemName: 'Flowers' | 'Cookies' | 'Muffins' | 'Eggs' | 'Pencils' | 'Apples' | 'Bananas',
      numberOfItems: number
    ) => {
      const numberForSvgs = numberOfItems as 1 | 2 | 3 | 4 | 5 | 6;

      switch (itemName) {
        case 'Flowers':
          return `Equal_groups/Flowers${numberForSvgs}` as const;
        case 'Cookies':
          return `Equal_groups/Cookies${numberForSvgs}` as const;
        case 'Muffins':
          return `Muffins_donuts/Muffins_on_a_rack_${numberForSvgs}` as const;
        case 'Eggs':
          return `Equal_groups/Eggs${numberForSvgs}` as const;
        case 'Pencils':
          return `Equal_groups/Pencils${numberForSvgs}` as const;
        case 'Apples':
          return `Equal_groups/Apples${numberForSvgs}` as const;
        case 'Bananas':
          return `Bunches_of_bananas/Bunch_of_bananas_${numberForSvgs}` as const;
      }
    };

    const itemsWithSvgs =
      subQuestion.variant === 'Objects' &&
      subQuestion.items.map(item => {
        return {
          ...item,
          svgNames: item.numberOfItems.map(num => getSvgNames(item.itemName, num))
        };
      });

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions[title]()}
        pdfTitle={translate.ks1PDFInstructions[pdfTitle]()}
        numItems={4}
        multiSelect
        pdfShowBorder
        renderItems={({ dimens }) =>
          subQuestion.variant === 'Objects' && itemsWithSvgs
            ? itemsWithSvgs.map(({ value, svgNames }) => ({
                value,
                component: (
                  <View
                    style={{
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 8,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {svgNames.map((name, index) => (
                      <AssetSvg
                        key={index}
                        name={name}
                        width={displayMode === 'digital' ? 80 : 120}
                        height={displayMode === 'digital' ? 80 : 120}
                      />
                    ))}
                  </View>
                )
              }))
            : subQuestion.variant === 'Cubes'
            ? subQuestion.items.map(({ color, numberOfCubes, value }) => ({
                value,
                component: (
                  <View style={{ flexDirection: 'row', alignItems: 'flex-end', columnGap: 16 }}>
                    {numberOfCubes.map((num, index) => (
                      <AssetSvg
                        key={index}
                        name={getMultiLinkCubeSvgName(color, num)}
                        width={dimens.width * 0.1}
                      />
                    ))}
                  </View>
                )
              }))
            : subQuestion.variant === 'Base10'
            ? subQuestion.items.map(({ value, numberOfTensAndOnes: selectable }) => ({
                value,
                component: (
                  <View
                    style={{
                      flexDirection: 'row',
                      gap: 16,
                      width: dimens.width,
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {selectable.map(({ ones, tens }, index) => (
                      <View
                        style={{
                          width: dimens.width * 0.4,
                          height: dimens.height * 0.4,
                          borderColor: 'black',
                          borderWidth: 1,
                          justifyContent: 'center'
                        }}
                        key={index}
                      >
                        <View
                          style={{
                            transform: `rotate(90deg)`,
                            right: tens || ones >= 5 ? (displayMode === 'digital' ? 15 : 30) : 0
                          }}
                        >
                          <SimpleBaseTenWithCrossOut
                            dimens={{ width: dimens.width * 0.4, height: dimens.height * 0.4 }}
                            ones={ones}
                            tens={tens}
                            scale={displayMode === 'digital' ? 0.15 : 0.3}
                          />
                        </View>
                      </View>
                    ))}
                  </View>
                )
              }))
            : []
        }
        testCorrect={correctValues}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bkc',
  description: 'bkc',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole'],
  schema: z
    .object({
      items: z.array(
        z.object({
          name: equalShapePartsSchema.or(shapePartsSchema).or(unequalShapePartsSchema),
          isCorrect: z.boolean(),
          value: numberEnum([1, 2, 3, 4])
        })
      )
    })
    .refine(
      val => arrayHasNoDuplicates([val.items.map(item => item.name)]),
      'All shapes must be different.'
    ),
  simpleGenerator: () => {
    const equalShape = getRandomEqualShapePart();

    const unequalShape = getRandomUnequalShapePart();

    const [equalOrUnequalShapeA, equalOrUnequalShapeB] = getRandomSubArrayFromArray(
      shapePartsArray,
      2,
      {
        constraint: x => arrayHasNoDuplicates([x, equalShape, unequalShape])
      }
    );

    const items = shuffle([
      {
        name: equalShape,
        isCorrect: true,
        value: 1 as const
      },
      {
        name: unequalShape,
        isCorrect: false,
        value: 2 as const
      },
      {
        name: equalOrUnequalShapeA,
        isCorrect: !equalOrUnequalShapeA.includes('Unequal'),
        value: 3 as const
      },
      {
        name: equalOrUnequalShapeB,
        isCorrect: !equalOrUnequalShapeB.includes('Unequal'),
        value: 4 as const
      }
    ]);

    return {
      items
    };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    const correctAnswers = items.filter(item => item.isCorrect);

    return (
      <QF11SelectImagesUpTo4
        title={
          correctAnswers.length > 1
            ? translate.instructions.selectShapesThatHaveBeenSplitIntoEqualParts()
            : translate.instructions.selectShapeThatHasBeenSplitIntoEqualParts()
        }
        pdfTitle={
          correctAnswers.length > 1
            ? translate.instructions.circleShapesThatHaveBeenSplitIntoEqualParts()
            : translate.instructions.circleShapeThatHasBeenSplitIntoEqualParts()
        }
        testCorrect={correctAnswers.map(item => item.value)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return items.map(({ name, value }) => ({
            value,
            component: <AssetSvg name={name} height={dimens.height * 0.9} />
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bkd',
  description: 'bkd',
  keywords: ['Equal groups', 'Group'],
  schema: z
    .object({
      numberOfGroups: z.number().int().min(2).max(6),
      itemsPerGroups: numberEnum([1, 2, 3, 4, 5]),
      item: z.enum(['Muffin', 'Flower', 'Apple', 'Banana'])
    })
    .refine(
      val => val.itemsPerGroups !== val.numberOfGroups,
      'items per groups cannot be the same the number of groups'
    ),
  simpleGenerator: () => {
    const itemsPerGroups = getRandomFromArray([1, 2, 3, 4, 5] as const);
    const numberOfGroups = randomIntegerInclusive(2, 6, { constraint: x => x !== itemsPerGroups });
    const item = getRandomFromArray(['Muffin', 'Flower', 'Apple', 'Banana'] as const);

    return { numberOfGroups, itemsPerGroups, item };
  },
  Component: props => {
    const {
      question: { numberOfGroups, itemsPerGroups, item },
      translate
    } = props;

    const svgName =
      item === 'Muffin'
        ? (`Food_on_a_plate/Muffins_plate_${itemsPerGroups}` as const)
        : item === 'Banana'
        ? (`Bunches_of_bananas/Bunch_of_bananas_${itemsPerGroups}` as const)
        : getObjectSvgName(item, itemsPerGroups);

    const itemsLookup = {
      Apple: itemsPerGroups === 1 ? translate.fruits.Apple() : translate.fruits.Apples(),
      Flower: itemsPerGroups === 1 ? translate.objects.Flower() : translate.objects.Flowers(),
      Muffin:
        itemsPerGroups === 1 ? translate.weightObjects.muffin() : translate.weightObjects.muffins(),
      Banana: itemsPerGroups === 1 ? translate.fruits.Banana() : translate.fruits.Bananas()
    } as const;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsEqualGroups(),
          translate.ks1AnswerSentences.eachGroupHasAnsX(itemsLookup[item])
        ]}
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'column', gap: 25 }}>
            <View style={{ flexDirection: 'row', gap: 25 }}>
              {countRange(numberOfGroups).map(i => (
                <AssetSvg
                  key={i}
                  name={svgName}
                  width={dimens.width / 7}
                  height={dimens.height / 2}
                />
              ))}
            </View>
          </View>
        )}
        pdfDirection="column"
        questionHeight={1000}
        testCorrect={[[numberOfGroups.toString()], [itemsPerGroups.toString()]]}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EqualAndUnequalParts',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
