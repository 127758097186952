import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from '../i18n/i18n-types';
import { Name } from './names';
import { SvgName } from '../assets/svg';

export const objectNames = ['Apple', 'Cookie', 'Egg', 'Flower', 'Pencil'] as const;

export const objectSchema = z.enum(objectNames);

export function getRandomObject() {
  return getRandomFromArray(objectNames);
}

export type ObjectName = (typeof objectNames)[number];

export const isObject = (x: string): x is ObjectName => objectNames.includes(x as ObjectName);

export const objectAsWord = (
  object: ObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Apple':
      return isPlural ? translate.objects.Apples() : translate.objects.Apple();
    case 'Cookie':
      return isPlural ? translate.objects.Cookies() : translate.objects.Cookie();
    case 'Egg':
      return isPlural ? translate.objects.Eggs() : translate.objects.Egg();
    case 'Flower':
      return isPlural ? translate.objects.Flowers() : translate.objects.Flower();
    case 'Pencil':
      return isPlural ? translate.objects.Pencils() : translate.objects.Pencil();
  }
};

export const objectPrepositionWord = (
  object: ObjectName,
  translate: TranslationFunctions,
  movementPreposition = false
) => {
  switch (object) {
    case 'Apple':
    case 'Egg':
    case 'Flower':
    case 'Pencil':
      return movementPreposition ? translate.prepositions.into() : translate.prepositions.in();
    case 'Cookie':
      return movementPreposition ? translate.prepositions.onto() : translate.prepositions.on();
  }
};

export const containerOfObject = (
  object: ObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Apple':
      return isPlural ? translate.objects.Bags() : translate.objects.Bag();
    case 'Cookie':
      return isPlural ? translate.objects.Plates() : translate.objects.Plate();
    case 'Egg':
      return isPlural ? translate.objects.Boxes() : translate.objects.Box();
    case 'Flower':
      return isPlural ? translate.objects.Vases() : translate.objects.Vase();
    case 'Pencil':
      return isPlural ? translate.objects.Pots() : translate.objects.Pot();
  }
};

export const objectSentenceMaker = (
  name: Name,
  object: ObjectName,
  translate: TranslationFunctions,
  numGroups: number,
  numObjPerGroup: number
): [string, string, string] => {
  switch (object) {
    case 'Apple':
      return [
        translate.answerSentences.characterHasNumBags({ name, numGroups }),
        translate.answerSentences.characterPutsNumApples({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Apples())
      ];
    case 'Cookie':
      return [
        translate.answerSentences.characterHasNumPlates({ name, numGroups }),
        translate.answerSentences.characterPutsNumCookies({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Cookies())
      ];
    case 'Egg':
      return [
        translate.answerSentences.characterHasNumBoxes({ name, numGroups }),
        translate.answerSentences.characterPutsNumEggs({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Eggs())
      ];
    case 'Flower':
      return [
        translate.answerSentences.characterHasNumVases({ name, numGroups }),
        translate.answerSentences.characterPutsNumFlowers({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Flowers())
      ];
    case 'Pencil':
      return [
        translate.answerSentences.characterHasNumPots({ name, numGroups }),
        translate.answerSentences.characterPutsNumPencils({ name, numObjPerGroup }),
        translate.answerSentences.characterHasAnsObjectAltogether(name, translate.objects.Pencils())
      ];
  }
};

export const shopObjectNames = [
  'Bunches of flowers',
  'Boxes of eggs',
  'Cartons of milk',
  'Tins of beans'
] as const;

export const shopObjectSchema = z.enum(shopObjectNames);

export function getRandomShopObject() {
  return getRandomFromArray(shopObjectNames);
}

export type ShopObjectName = (typeof shopObjectNames)[number];

export const isShopObject = (x: string): x is ShopObjectName =>
  shopObjectNames.includes(x as ShopObjectName);

export const shopObjectAsWord = (object: ShopObjectName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Bunches of flowers':
      return translate.objects.bunchesOfFlowers();
    case 'Boxes of eggs':
      return translate.objects.boxesOfEggs();
    case 'Cartons of milk':
      return translate.objects.cartonsOfMilk();
    case 'Tins of beans':
      return translate.objects.tinsOfBeans();
  }
};

export const expensiveObjectNames = [
  'Bike',
  'Games console',
  'Laptop',
  'Phone',
  'Rucksack',
  'Scooter',
  'Television'
] as const;

export const expensiveObjectSchema = z.enum(expensiveObjectNames);

export function getRandomExpensiveObject() {
  return getRandomFromArray(expensiveObjectNames);
}

export type ExpensiveObjectName = (typeof expensiveObjectNames)[number];

export const isExpensiveObject = (x: string): x is ExpensiveObjectName =>
  expensiveObjectNames.includes(x as ExpensiveObjectName);

export const expensiveObjectAsWord = (
  object: ExpensiveObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Bike':
      return isPlural ? translate.objects.Bikes() : translate.objects.Bike();
    case 'Games console':
      return isPlural ? translate.objects.GamesConsoles() : translate.objects.GamesConsole();
    case 'Laptop':
      return isPlural ? translate.objects.Laptops() : translate.objects.Laptop();
    case 'Phone':
      return isPlural ? translate.objects.Phones() : translate.objects.Phone();
    case 'Rucksack':
      return isPlural ? translate.objects.Rucksacks() : translate.objects.Rucksack();
    case 'Scooter':
      return isPlural ? translate.objects.Scooters() : translate.objects.Scooter();
    case 'Television':
      return isPlural ? translate.objects.Televisions() : translate.objects.Television();
  }
};

export const lessThanOnePoundObjectNames = ['Apple', 'Banana', 'Pear'] as const;

export const lessThanOnePoundObjectSchema = z.enum(lessThanOnePoundObjectNames);

export function getRandomLessThanOnePoundObject() {
  return getRandomFromArray(lessThanOnePoundObjectNames);
}

export type lessThanOnePoundObjectName = (typeof lessThanOnePoundObjectNames)[number];

export const cheapObjectNames = [
  'Balloon',
  'Book',
  'BreadLoaf',
  'CrayonBox',
  'FizzyDrink',
  'JuiceBottle',
  'LemonadeBottle',
  'Mug',
  'PencilCase',
  'Kettle'
] as const;

export const cheapObjectSchema = z.enum(cheapObjectNames);

export function getRandomCheapObject() {
  return getRandomFromArray(cheapObjectNames);
}

export function getRandomUniqueCheapObject(quantity: number) {
  return getRandomSubArrayFromArray([...cheapObjectNames] as const, quantity);
}

export type CheapObjectName = (typeof cheapObjectNames)[number];

export const isCheapObject = (x: string): x is CheapObjectName =>
  cheapObjectNames.includes(x as CheapObjectName);

export const cheapObjectAsWord = (
  object: CheapObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Book':
      return isPlural ? translate.objects.Books() : translate.objects.Book();
    case 'Balloon':
      return isPlural ? translate.objects.Balloons() : translate.objects.Balloon();
    case 'Kettle':
      return isPlural ? translate.objects.Kettles() : translate.objects.Kettle();
    case 'JuiceBottle':
      return isPlural ? translate.objects.JuiceBottles() : translate.objects.JuiceBottle();
    case 'Mug':
      return isPlural ? translate.objects.Mugs() : translate.objects.Mug();
    case 'CrayonBox':
      return isPlural ? translate.objects.CrayonBoxes() : translate.objects.CrayonBox();
    case 'BreadLoaf':
      return isPlural ? translate.objects.Bread() : translate.objects.Bread();
    case 'PencilCase':
      return isPlural ? translate.objects.PencilCases() : translate.objects.PencilCase();
    case 'LemonadeBottle':
      return isPlural ? translate.objects.Lemonades() : translate.objects.Lemonade();
    case 'FizzyDrink':
      return isPlural ? translate.objects.FizzyDrinks() : translate.objects.FizzyDrink();
  }
};

export const cheapObjectWithArticle = (
  object: CheapObjectName,
  translate: TranslationFunctions
) => {
  switch (object) {
    case 'Book':
      return translate.objects.aBook();
    case 'Balloon':
      return translate.objects.aBalloon();
    case 'Kettle':
      return translate.objects.aKettle();
    case 'JuiceBottle':
      return translate.objects.aBottleOfJuice();
    case 'Mug':
      return translate.objects.aMug();
    case 'CrayonBox':
      return translate.objects.aCrayonBox();
    case 'BreadLoaf':
      return translate.objects.aLoafOfBread();
    case 'PencilCase':
      return translate.objects.aPencilCase();
    case 'LemonadeBottle':
      return translate.objects.aBottleOfLemonade();
    case 'FizzyDrink':
      return translate.objects.aFizzyDrink();
  }
};

export const foodFractionObjectNames = ['Cake', 'Pie', 'Pizza'] as const;

export const foodFractionObjectSchema = z.enum(foodFractionObjectNames);

export function getRandomFoodFractionObject() {
  return getRandomFromArray(foodFractionObjectNames);
}

export type FoodFractionObjectName = (typeof foodFractionObjectNames)[number];

export const isFoodFractionObject = (x: string): x is FoodFractionObjectName =>
  foodFractionObjectNames.includes(x as FoodFractionObjectName);

export const foodFractionObjectAsWord = (
  object: FoodFractionObjectName,
  translate: TranslationFunctions,
  isPlural?: boolean
) => {
  switch (object) {
    case 'Cake':
      return isPlural ? translate.objects.Cakes() : translate.objects.Cake();
    case 'Pie':
      return isPlural ? translate.objects.Pies() : translate.objects.Pie();
    case 'Pizza':
      return isPlural ? translate.objects.Pizzas() : translate.objects.Pizza();
  }
};

export const vehicleObjectNames = ['Car', 'Bus', 'Lorry', 'Train', 'Coach'] as const;

export const vehicleObjectSchema = z.enum(vehicleObjectNames);

export function getRandomvehicleObjectObject() {
  return getRandomFromArray(vehicleObjectNames);
}

export type vehicleObjectName = (typeof vehicleObjectNames)[number];

export const domesticAnimalsObjectNames = ['Cat', 'Dog', 'Bird', 'Hamster'] as const;

export const domesticAnimalsObjectSchema = z.enum(domesticAnimalsObjectNames);
export function getRandomdomesticAnimalsObject() {
  return getRandomFromArray(domesticAnimalsObjectNames);
}
export type domesticAnimalsObjectName = (typeof domesticAnimalsObjectNames)[number];

export const measureObjectNames = [
  'lollipop',
  'pencil',
  'stick',
  'drinking_straw',
  'toy_car',
  'crayon'
] as const;

export type MeasureObjectName = (typeof measureObjectNames)[number];

export const measureObjectWithArticle = (
  object: MeasureObjectName,
  translate: TranslationFunctions
) => {
  switch (object) {
    case 'drinking_straw':
      return translate.objects.theStraw();
    case 'lollipop':
      return translate.objects.theLollipop();
    case 'pencil':
      return translate.objects.thePencil();
    case 'stick':
      return translate.objects.theStick();
    case 'toy_car':
      return translate.objects.theToyCar();
    case 'crayon':
      return translate.objects.theCrayon();
  }
};

// Scale Objects

/**
 * Old scaleObjects schema - Currently used on aI9 to prevent schema breaking on older versions of infinity
 */
export const scaleObjectNamesOld = ['orange', 'apple'] as const;
export function getRandomUniqueScaleObjectNamesOld(numberOfScaleObjectNamesOld: number) {
  return getRandomSubArrayFromArray(scaleObjectNamesOld, numberOfScaleObjectNamesOld);
}
export const scaleObjectsSchemaOld = z.enum(scaleObjectNamesOld);

export const scaleObjectNames = [
  'strawberry',
  'muffin',
  'orange',
  'apple',
  'banana',
  'blue cube',
  'green cube',
  'orange cube',
  'purple cube',
  'red cube',
  'pear'
] as const;
export type ScaleObjectName = (typeof scaleObjectNames)[number];
export const scaleObjectsSchema = z.enum(scaleObjectNames);
export type ScaleObjectInfo = {
  name: SvgName;
  width: number;
  rotation: number;
  /** Only used by `PyramidOfObjects` at time of writing. */
  weight: number;
  /** Only used by `PyramidOfObjects` at time of writing. */
  topOffset: number;
  /** Only used by `PyramidOfObjects` at time of writing. */
  leftOffset?: number;
};

// TODO: Add grapes, lemon, peach, carrot, cherries
export const scaleObjects: Record<ScaleObjectName, ScaleObjectInfo> = {
  strawberry: {
    name: 'Array_objects/Strawberry',
    width: 80,
    rotation: 0,
    weight: 1,
    topOffset: 35,
    leftOffset: 10
  },
  muffin: {
    name: 'Muffins_individually/Muffin_1',
    width: 80,
    rotation: 0,
    weight: 2,
    topOffset: 35,
    leftOffset: 20
  },
  orange: {
    name: 'Array_objects/Orange',
    width: 80,
    rotation: 0,
    weight: 3,
    topOffset: 35,
    leftOffset: 20
  },
  apple: {
    name: 'Array_objects/AppleGreen',
    width: 80,
    rotation: 0,
    weight: 4,
    topOffset: 20
  },
  banana: {
    name: 'Array_objects/Banana',
    width: 100,
    rotation: 0,
    weight: 5,
    topOffset: 20
  },
  pear: {
    name: 'Array_objects/Pear',
    width: 75,
    rotation: 0,
    weight: 6,
    topOffset: 20,
    leftOffset: 10
  },
  ['blue cube']: {
    name: 'Cubes_blank/Coloured_cube_unlabelled_blue',
    width: 80,
    rotation: 18,
    weight: 7,
    topOffset: 20
  },
  ['green cube']: {
    name: 'Cubes_blank/Coloured_cube_unlabelled_green',
    width: 80,
    rotation: 18,
    weight: 7,
    topOffset: 20
  },
  ['orange cube']: {
    name: 'Cubes_blank/Coloured_cube_unlabelled_orange',
    width: 80,
    rotation: 18,
    weight: 7,
    topOffset: 20
  },
  ['purple cube']: {
    name: 'Cubes_blank/Coloured_cube_unlabelled_purple',
    width: 80,
    rotation: 18,
    weight: 7,
    topOffset: 20
  },
  ['red cube']: {
    name: 'Cubes_blank/Coloured_cube_unlabelled_red',
    width: 80,
    rotation: 18,
    weight: 7,
    topOffset: 20
  }
};

export function getRandomUniqueScaleObjectNames(numberOfScaleObjectNames: number) {
  return getRandomSubArrayFromArray(scaleObjectNames, numberOfScaleObjectNames);
}

export const scaleObjectAsWord = (
  scaleObjectName: ScaleObjectName,
  translate: TranslationFunctions,
  plural = false
) => {
  switch (scaleObjectName) {
    case 'strawberry':
      return plural ? translate.weightObjects.strawberries() : translate.weightObjects.strawberry();
    case 'muffin':
      return plural ? translate.weightObjects.muffins() : translate.weightObjects.muffin();
    case 'orange':
      return plural ? translate.weightObjects.oranges() : translate.weightObjects.orange();
    case 'apple':
      return plural ? translate.weightObjects.apples() : translate.weightObjects.apple();
    case 'banana':
      return plural ? translate.weightObjects.bananas() : translate.weightObjects.banana();
    case 'pear':
      return plural ? translate.weightObjects.pears() : translate.weightObjects.pear();
    case 'blue cube':
      return translate.weightObjects['blue cube']();
    case 'green cube':
      return translate.weightObjects['green cube']();
    case 'orange cube':
      return translate.weightObjects['orange cube']();
    case 'purple cube':
      return translate.weightObjects['purple cube']();
    case 'red cube':
      return translate.weightObjects['red cube']();
  }
};

export const scaleObjectAsArticleWord = (
  scaleObjectName: ScaleObjectName,
  translate: TranslationFunctions
) => {
  switch (scaleObjectName) {
    case 'strawberry':
      return translate.objects.theStrawberry();
    case 'muffin':
      return translate.objects.theMuffin();
    case 'orange':
      return translate.objects.theOrange();
    case 'apple':
      return translate.objects.theApple();
    case 'banana':
      return translate.objects.theBanana();
    case 'pear':
      return translate.objects.thePear();
    case 'blue cube':
    case 'green cube':
    case 'orange cube':
    case 'purple cube':
    case 'red cube':
      return translate.objects.theCube();
  }
};

export const heavyObjectNames = ['Laptop', 'Rucksack', 'BookRed'] as const;

export const heavyObjectSchema = z.enum(heavyObjectNames);

export function getRandomHeavyObject() {
  return getRandomFromArray(heavyObjectNames);
}

export function getRandomUniqueHeavyObject(quantity: number) {
  return getRandomSubArrayFromArray(heavyObjectNames, quantity);
}

export type HeavyObjectName = (typeof heavyObjectNames)[number];

export const heavyObjectAsWord = (object: HeavyObjectName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Laptop':
      return translate.objects.Laptop();
    case 'Rucksack':
      return translate.objects.Rucksack();
    case 'BookRed':
      return translate.objects.Book();
  }
};

export const waterVolumeObjects = [
  {
    svg: 'Glasses_of_water/glass_water_quarter_full',
    value: 0.25
  },
  {
    svg: 'Glasses_of_water/glass_water_half_full',
    value: 0.5
  },
  {
    svg: 'Glasses_of_water/glass_water_three_quarters_full',
    value: 0.75
  },
  {
    svg: 'Glasses_of_water/glass_water_full',
    value: 1
  }
];

export const objectsWithWeightsNameArray = [
  'spoon',
  'cup',
  'box',
  'jug',
  'fishtank',
  'bath'
] as const;

export type ObjectsWithWeightsName = (typeof objectsWithWeightsNameArray)[number];
export const objectsWithWeightsNameSchema = z.enum(objectsWithWeightsNameArray);

export const objectsWithWeights: Record<
  ObjectsWithWeightsName,
  { icon: SvgName; weight: number; scale: number; pdfPixelScale: number }
> = {
  spoon: {
    icon: 'tablespoon',
    weight: 1,
    scale: 0.2,
    pdfPixelScale: 200
  },
  cup: {
    icon: 'teacup',
    weight: 2,
    scale: 0.3,
    pdfPixelScale: 250
  },
  box: {
    icon: 'CardboardBox',
    weight: 3,
    scale: 0.4,
    pdfPixelScale: 300
  },
  jug: {
    icon: 'Empty_jug',
    weight: 4,
    scale: 0.375,
    pdfPixelScale: 300
  },
  fishtank: {
    icon: 'Goldfish_in_a_tank/Goldfish_in_a_tank_0',
    weight: 5,
    scale: 0.75,
    pdfPixelScale: 600
  },
  bath: {
    icon: 'Bath',
    weight: 6,
    scale: 0.95,
    pdfPixelScale: 650
  }
};

export const getRandomUniqueWeightObjects = (quantity: number) =>
  getRandomSubArrayFromArray(objectsWithWeightsNameArray, quantity);

// Before and after
export type BeforeAndAfterObject = {
  name: 'chocolate' | 'banana' | 'balloon' | 'cookie' | 'donut' | 'sunflower' | 'gift' | 'drawing';
  before: SvgName;
  during: SvgName;
  after: SvgName;
};

export const beforeAndAfterObjects: BeforeAndAfterObject[] = [
  {
    name: 'chocolate',
    before: 'Bar_of_chocolate',
    during: 'Before_and_after_images/Bar_of_chocolate_unwrapped',
    after: 'Before_and_after_images/Bar_of_chocolate_bite'
  },
  {
    name: 'banana',
    before: 'Before_and_after_images/Banana_single',
    during: 'Before_and_after_images/Banana_peeled',
    after: 'Before_and_after_images/Banana_bite'
  },
  {
    name: 'balloon',
    before: 'Before_and_after_images/Balloon_blue_uninflated',
    during: 'BalloonBlue',
    after: 'Before_and_after_images/Balloon_blue_popped'
  },
  {
    name: 'cookie',
    before: 'Cookie_biscuit/Cookie_biscuit_5',
    during: 'Before_and_after_images/Cookie_biscuit_bite',
    after: 'Before_and_after_images/Cookie_biscuit_crumbs'
  },
  {
    name: 'donut',
    before: 'Donuts_individual/Donut_1',
    during: 'Before_and_after_images/Donut_bite',
    after: 'Before_and_after_images/Donut_crumbs'
  },
  {
    name: 'sunflower',
    before: 'Before_and_after_images/Sunflower_seed',
    during: 'Before_and_after_images/Plant_pot_soil',
    after: 'Before_and_after_images/Plant_pot_sunflower'
  },
  {
    name: 'gift',
    before: 'Before_and_after_images/Not_wrapped_gift',
    during: 'Before_and_after_images/Wrapped_gift_without_bow',
    after: 'Wrapped_gifts_red'
  },
  {
    name: 'drawing',
    before: 'Before_and_after_images/Paper_blank',
    during: 'Before_and_after_images/Paper_drawing_house_line',
    after: 'Before_and_after_images/Paper_drawing_house_colour'
  }
];
