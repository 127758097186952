import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { ADD, SUB } from '../../../../constants';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  compareFractions,
  improperFractionToMixedNumber,
  simplify
} from '../../../../utils/fractions';
import { useMemo } from 'react';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF20cInteractiveBarModelWithCrossesAndSentence from '../../../../components/question/questionFormats/QF20cInteractiveBarModelWithCrossesAndSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'asm',
  description: 'asm',
  keywords: ['Fraction', 'Mixed number', 'Subtraction'],
  schema: z.object({
    denominator: z.number().int().min(3).max(8),
    fracANumerator: z.number().int().min(1).max(6),
    fracBNumerator: z.number().int().min(2).max(7)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const fracANumerator = randomIntegerInclusive(1, denominator - 2);
    const fracBNumerator = randomIntegerInclusive(fracANumerator + 1, denominator - 1);

    return { denominator, fracANumerator, fracBNumerator };
  },
  Component: props => {
    const {
      question: { denominator, fracANumerator, fracBNumerator },
      translate,
      displayMode
    } = props;

    const bars = [
      {
        rows: 1,
        cols: denominator,
        shadedCells: { min: 0, max: denominator - 1 }
      },
      {
        rows: 1,
        cols: denominator,
        shadedCells: { min: 0, max: fracANumerator - 1 }
      }
    ];

    // Answers
    const answer2 = denominator;
    const answer3 = denominator + fracANumerator - fracBNumerator;

    // Simplify fractions for testCorrect
    const [simplifiedAnswerFracNumerator, simplifiedAnswerFracDenominator] = simplify(
      answer3,
      answer2
    );

    const sentence = `<frac w='1' n='${fracANumerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> ${SUB} <frac n='${fracBNumerator.toLocaleString()}' d='${denominator.toLocaleString()}'/> = <frac nAns='' dAns='' />`;

    return (
      <QF20cInteractiveBarModelWithCrossesAndSentence
        title={`${translate.instructions.completeSubtraction()}<br/>${translate.instructions.youCanUseTheBarModelToHelp()}`}
        barModelShadingTestComplete={() => true}
        testCorrect={userAnswer =>
          compareFractions(userAnswer, [
            simplifiedAnswerFracNumerator,
            simplifiedAnswerFracDenominator
          ])
        }
        sentence={sentence}
        bars={bars}
        inputMaxCharacters={2}
        tableHeight={displayMode === 'digital' ? 100 : 150}
        textStyle={{ fontSize: 40 }}
        pdfDirection="column"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            simplifiedAnswerFracNumerator.toLocaleString(),
            simplifiedAnswerFracDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'asn',
  description: 'asn',
  keywords: ['Fraction', 'Mixed number', 'Subtraction'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(10),
      number2: z.number().int().min(3).max(12),
      number3: z.number().int().min(1).max(10),
      number4: z.number().int().min(2).max(11)
    })
    .refine(
      val => val.number3 < val.number2 - 1 && val.number3 < val.number4,
      'number3 must be less than number2 - 1, and must be less than number4'
    )
    .refine(val => val.number4 < val.number2, 'number4 must be less than number2'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = randomIntegerInclusive(3, 12);

    const number3 = randomIntegerInclusive(1, number2 - 2);

    const number4 = randomIntegerInclusive(number3 + 1, number2 - 1);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const [simplifiedNumerator, simplifiedDenominator] = simplify(
      number2 + number3 - number4,
      number2
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheSubtraction()}
        inputMaxCharacters={2}
        testCorrect={answer =>
          answer[0] === (number1 - 1).toString() &&
          compareFractions([answer[1], answer[2]], [simplifiedNumerator, simplifiedDenominator])
        }
        sentence={`<frac w='${number1.toLocaleString()}' n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/> ${SUB} <frac n='${number4.toLocaleString()}' d='${number2.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (number1 - 1).toLocaleString(),
            simplifiedNumerator.toLocaleString(),
            simplifiedDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aso',
  description: 'aso',
  keywords: ['Fraction', 'Mixed number', 'Subtraction'],
  schema: z.object({
    fracBDenominator: z.number().int().min(2).max(5),
    fracBNumerator: z.number().int().min(1).max(4),
    fracADenominator: z.number().int().min(4).max(10).step(2),
    fracANumerator: z.number().int().min(1).max(4)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const fracBDenominator = randomIntegerInclusive(2, 5);
    const fracBNumerator = randomIntegerInclusive(fracBDenominator / 2, fracBDenominator - 1);
    const fracADenominator = fracBDenominator * 2;
    const fracANumerator = randomIntegerInclusive(1, fracADenominator / 2 - 1);

    return { fracBDenominator, fracBNumerator, fracADenominator, fracANumerator };
  },
  Component: props => {
    const {
      question: { fracBDenominator, fracBNumerator, fracADenominator, fracANumerator },
      translate
    } = props;

    const bars = [
      {
        rows: 1,
        cols: fracADenominator,
        shadedCells: { min: 0, max: fracADenominator - 1 }
      },
      {
        rows: 1,
        cols: fracADenominator,
        shadedCells: { min: 0, max: fracANumerator - 1 }
      }
    ];

    // Answers
    const answer1 = fracADenominator;
    const answer2 = fracADenominator + fracANumerator - 2 * fracBNumerator;

    // Simplify fractions for testCorrect
    const [simplifiedAnswerFracNumerator, simplifiedAnswerFracDenominator] = simplify(
      answer2,
      answer1
    );

    const sentence = `<frac w='1' n='${fracANumerator.toLocaleString()}' d='${fracADenominator.toLocaleString()}'/> ${SUB} <frac n='${fracBNumerator.toLocaleString()}' d='${fracBDenominator.toLocaleString()}'/> = <frac nAns='' dAns='' />`;

    return (
      <QF20cInteractiveBarModelWithCrossesAndSentence
        title={`${translate.instructions.completeSubtraction()}<br/>${translate.instructions.youCanUseTheBarModelToHelp()}`}
        barModelShadingTestComplete={() => true}
        testCorrect={userAnswer =>
          compareFractions(userAnswer, [
            simplifiedAnswerFracNumerator,
            simplifiedAnswerFracDenominator
          ])
        }
        sentence={sentence}
        bars={bars}
        inputMaxCharacters={2}
        tableHeight={100}
        textStyle={{ fontSize: 40 }}
        pdfDirection="column"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            simplifiedAnswerFracNumerator.toLocaleString(),
            simplifiedAnswerFracDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asp',
  description: 'asp',
  keywords: ['Fraction', 'Mixed number', 'Subtraction', 'Whole'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: z.number().int().min(2).max(6),
    number3: z.number().int().min(1).max(5),
    number4: z.number().int().min(2).max(5),
    number6: z.number().int().min(1).max(14),
    number7: z.number().int().min(2).max(10),
    number8: z.number().int().min(3).max(6),
    number9: z.number().int().min(1).max(2),
    number10: z.number().int().min(2).max(5),
    number12: z.number().int().min(3).max(29),
    number13: z.number().int().min(2).max(10),
    number14: z.number().int().min(2).max(6),
    number15: z.number().int().min(1).max(5),
    number16: z.number().int().min(2).max(5),
    number19: z.number().int().min(2).max(10),
    number20: z.number().int().min(2).max(5),
    number21: z.number().int().min(3).max(6),
    number22: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    // Equation A
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = randomIntegerInclusive(2, 6);

    const number3LowerBound = Math.ceil(number2 / 2);

    const number3 = randomIntegerInclusive(number3LowerBound, number2 - 1);

    const number4 = randomIntegerInclusive(2, 5);

    const number5 = number2 * number4;

    const number6UpperBound =
      Math.floor(number5 / 2) === number5 / 2 ? number5 / 2 - 1 : Math.floor(number5 / 2);

    const number6 = randomIntegerInclusive(1, number6UpperBound);

    // Equation B
    const number7 = randomIntegerInclusive(2, 10);

    const number8 = randomIntegerInclusive(3, 6);

    const number9UpperBound =
      Math.floor(number8 / 2) === number8 / 2 ? number8 / 2 - 1 : Math.floor(number8 / 2);

    const number9 = randomIntegerInclusive(1, number9UpperBound);

    const number10 = randomIntegerInclusive(2, 5);

    const number11 = number8 * number10;

    const number12LowerBound = Math.ceil(number11 / 2);

    const number12 = randomIntegerInclusive(number12LowerBound, number11 - 1);

    // Equation C
    const number13 = randomIntegerInclusive(2, 10);

    const number14 = randomIntegerInclusive(2, 6);

    const number15 = randomIntegerInclusive(1, number14 - 1);

    const number16 = randomIntegerInclusive(2, 5);

    // Equation D
    const number19 = randomIntegerInclusive(2, 10);

    const number21 = randomIntegerInclusive(3, 6);

    const number20 = randomIntegerInclusive(2, number21 - 1);

    const number22 = randomIntegerInclusive(2, 5);

    return {
      number1,
      number2,
      number3,
      number4,
      number6,
      number7,
      number8,
      number9,
      number10,
      number12,
      number13,
      number14,
      number15,
      number16,
      number19,
      number20,
      number21,
      number22
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number6,
        number7,
        number8,
        number9,
        number10,
        number12,
        number13,
        number14,
        number15,
        number16,
        number19,
        number20,
        number21,
        number22
      },
      translate
    } = props;

    const number5 = number2 * number4;

    const number11 = number8 * number10;

    const number17 = number14 * number16;

    const number18 = number15 * number16;

    const number23 = (number20 - 1) * number22;
    const number24 = number21 * number22;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        sentence: `<frac w='${number1.toLocaleString()}' n='${number6.toLocaleString()}' d='${number5.toLocaleString()}'/> ${SUB} <frac n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/>`,
        isCorrect: true
      };
      const eqB = {
        sentence: `<frac w='${number7.toLocaleString()}' n='${number12.toLocaleString()}' d='${number11.toLocaleString()}'/> ${SUB} <frac n='${number9.toLocaleString()}' d='${number8.toLocaleString()}'/>`,
        isCorrect: false
      };
      const eqC = {
        sentence: `<frac w='${number13.toLocaleString()}' n='${number18.toLocaleString()}' d='${number17.toLocaleString()}'/> ${SUB} <frac n='${number15.toLocaleString()}' d='${number14.toLocaleString()}'/>`,
        isCorrect: false
      };
      const eqD = {
        sentence: `<frac w='${number19.toLocaleString()}' n='${number20.toLocaleString()}' d='${number21.toLocaleString()}'/> ${SUB} <frac n='${number23.toLocaleString()}' d='${number24.toLocaleString()}'/>`,
        isCorrect: false
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [
      number1,
      number11,
      number12,
      number13,
      number14,
      number15,
      number17,
      number18,
      number2,
      number3,
      number5,
      number6,
      number7,
      number8,
      number9,
      number19,
      number20,
      number21,
      number23,
      number24,
      props.question
    ]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectCalcThatWillBreakWhole()}
        pdfTitle={translate.instructions.circleCalcThatWillBreakWhole()}
        testCorrect={eqs.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={4}
        renderItems={() => {
          return eqs.map(equation => ({
            value: equation.sentence,
            component: (
              <TextStructure
                sentence={equation.sentence}
                style={{ justifyContent: 'center' }}
                textStyle={{ fontWeight: '700' }}
                fractionTextStyle={{ fontWeight: '700' }}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'asq',
  description: 'asq',
  keywords: ['Fraction', 'Mixed number', 'Subtraction'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: z.number().int().min(2).max(6),
    number3: z.number().int().min(1).max(5),
    number4: z.number().int().min(2).max(5),
    number6: z.number().int().min(1).max(14)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = randomIntegerInclusive(2, 6);

    const number3LowerBound = Math.ceil(number2 / 2);

    const number3 = randomIntegerInclusive(number3LowerBound, number2 - 1);

    const number4 = randomIntegerInclusive(2, 5);

    const number5 = number2 * number4;

    const number6UpperBound =
      Math.floor(number5 / 2) === number5 / 2 ? number5 / 2 - 1 : Math.floor(number5 / 2);

    const number6 = randomIntegerInclusive(1, number6UpperBound);

    return { number1, number2, number3, number4, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number6 },
      translate
    } = props;

    const number5 = number2 * number4;

    const [simplifiedNumerator, simplifiedDenominator] = simplify(
      number5 + number6 - number4 * number3,
      number5
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheSubtraction()}
        inputMaxCharacters={2}
        testCorrect={answer =>
          answer[0] === (number1 - 1).toString() &&
          compareFractions([answer[1], answer[2]], [simplifiedNumerator, simplifiedDenominator])
        }
        sentence={`<frac w='${number1.toLocaleString()}' n='${number6.toLocaleString()}' d='${number5.toLocaleString()}'/> ${SUB} <frac n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (number1 - 1).toLocaleString(),
            simplifiedNumerator.toLocaleString(),
            simplifiedDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'asr',
  description: 'asr',
  keywords: ['Fraction', 'Mixed number', 'Subtraction'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    number2: z.number().int().min(2).max(6),
    number3: z.number().int().min(1).max(5),
    number4: z.number().int().min(2).max(5),
    number6: z.number().int().min(1).max(14),
    number7: z.number().int().min(1).max(9),
    number8: z.number().int().min(2).max(10),
    number9: z.number().int().min(2).max(6),
    number10: z.number().int().min(1).max(5),
    number11: z.number().int().min(2).max(5),
    number13: z.number().int().min(1).max(29),
    number14: z.number().int().min(1).max(29),
    variation: z.boolean()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);

    const number2 = randomIntegerInclusive(2, 6);

    const number3LowerBound = Math.ceil(number2 / 2);

    const number3 = randomIntegerInclusive(number3LowerBound, number2 - 1);

    const number4 = randomIntegerInclusive(2, 5);

    const number5 = number2 * number4;

    const number6UpperBound =
      Math.floor(number5 / 2) === number5 / 2
        ? Math.floor(number5 / 2) - 1
        : Math.floor(number5 / 2);
    const number6 = randomIntegerInclusive(1, number6UpperBound);

    const number7 = randomIntegerInclusive(1, 9);

    const number8 = randomIntegerInclusive(2, 10);

    const number9 = randomIntegerInclusive(2, 6);

    const number10 = randomIntegerInclusive(1, number9 - 1);

    const number11 = randomIntegerInclusive(2, 5);

    const number12 = number9 * number11;

    const number13 = randomIntegerInclusive(1, number12 - 1);

    const number14 = randomIntegerInclusive(1, number12 - 1);

    const variation = getRandomBoolean();

    return {
      number1,
      number2,
      number3,
      number4,
      number6,
      number7,
      number8,
      number9,
      number10,
      number11,
      number13,
      number14,
      variation
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        number6,
        number7,
        number8,
        number9,
        number10,
        number11,
        number13,
        number14,
        variation
      },
      translate
    } = props;

    const number5 = number2 * number4;

    const number12 = number9 * number11;

    const answerNumeratorA = number5 + number6 - number4 * number3;

    const answer6 = number8 * number12 + number13 + number14 - number11 * number10;

    const [integerB, answerNumeratorB, answerDenominatorB] = improperFractionToMixedNumber(
      answer6,
      number12
    );

    const [simplifiedNumeratorA, simplifiedDenominatorA] = simplify(answerNumeratorA, number5);

    const [simplifiedNumeratorB, simplifiedDenominatorB] = simplify(
      answerNumeratorB,
      answerDenominatorB
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        inputMaxCharacters={2}
        textStyle={{ fontSize: 40 }}
        testCorrect={answer => {
          return variation
            ? answer[0] === (number1 + number7 - 1).toString() &&
                compareFractions(
                  [answer[1], answer[2]],
                  [simplifiedNumeratorA, simplifiedDenominatorA]
                )
            : answer[0] === integerB.toString() &&
                (answerNumeratorB === 0 ||
                  compareFractions(
                    [answer[1], answer[2]],
                    [simplifiedNumeratorB, simplifiedDenominatorB]
                  ));
        }}
        sentence={
          variation
            ? `<frac w='${number1.toLocaleString()}' n='${number6.toLocaleString()}' d='${number5.toLocaleString()}'/> ${ADD} ${number7.toLocaleString()} ${SUB} <frac n='${number3.toLocaleString()}' d='${number2.toLocaleString()}'/> = <frac wAns='' nAns='' dAns=''/>`
            : `<frac w='${number8.toLocaleString()}' n='${number13.toLocaleString()}' d='${number12.toLocaleString()}'/> ${ADD} <frac n='${number14.toLocaleString()}' d='${number12.toLocaleString()}'/> ${SUB} <frac n='${number10.toLocaleString()}' d='${number9.toLocaleString()}'/> = ${
                answerNumeratorB !== 0 ? `<frac wAns='' nAns='' dAns=''/>` : `<ans />`
              }`
        }
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: variation
            ? [
                (number1 + number7 - 1).toLocaleString(),
                simplifiedNumeratorA.toLocaleString(),
                simplifiedDenominatorA.toLocaleString()
              ]
            : [
                integerB.toLocaleString(),
                simplifiedNumeratorB.toLocaleString(),
                simplifiedDenominatorB.toLocaleString()
              ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractFromAMixedNumberBreakingTheWhole',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
