import { BarModel, BarModelWithState } from '../representations/BarModel';
import { ComponentProps, useContext } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { MeasureView } from '../../atoms/MeasureView';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { filledArray } from '../../../utils/collections';

type PropsFromBarModel = Omit<
  ComponentProps<typeof BarModel>,
  'dimens' | 'userAnswer' | 'setUserAnswer'
>;

type UserAnswer = string[][];

type Props = PropsFromBarModel &
  TitleStyleProps & {
    title: string;
    extraSymbol?: ExtraSymbols;
    /** PDF Question Height */
    questionHeight?: number;
    /**
     * Action panel variant to use.
     * Default: 'endWide'.
     */
    actionPanelVariant?: 'endWide' | 'bottomTall';
  };

export default function QF20CompleteTheBarModel({
  title,
  numbers,
  strings,
  total,
  answerIndices = [],
  postAnswerString,
  rowHeight,
  sameRowColor,
  oneFontSize,
  proportional,
  extraSymbol,
  questionHeight,
  maxFontSize,
  arrowIndices,
  actionPanelVariant = 'endWide',
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  /** Check that every input field given by CompleteTheBarModel has a non-empty user string set. */
  const testComplete = (answer: UserAnswer) =>
    answerIndices.every((row, rowIndex) =>
      row.every(pos => {
        const userString = (answer[rowIndex] ?? [])[pos] ?? '';
        return userString !== '';
      })
    );
  let inputMaxCharacters: number | undefined = undefined;

  /** Check that every input field given by CompleteTheBarModel agrees with the number from that position. */
  const testCorrect = (answer: UserAnswer) =>
    answerIndices.every((row, rowIndex) =>
      row.every(pos => {
        const number = numbers[rowIndex][pos];
        const userString = (answer[rowIndex] ?? [])[pos] ?? '';
        return number.toString() === userString;
      })
    );

  if (postAnswerString) {
    answerIndices.forEach((row, rowIndex) => {
      row.forEach(pos => {
        const number = numbers[rowIndex][pos].toString().length;

        inputMaxCharacters =
          inputMaxCharacters === undefined || number > inputMaxCharacters
            ? number
            : inputMaxCharacters;
      });
    });
  }

  const numpadVariant = actionPanelVariant === 'endWide' ? 'tall' : 'wide';

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const emptyMarkSchemeAnswer = filledArray(filledArray('', numbers[0].length), numbers.length);
    const deepMarkSchemeCopy = JSON.parse(JSON.stringify(emptyMarkSchemeAnswer));
    answerIndices.forEach((row, rowIndex) => {
      row.forEach(pos => {
        deepMarkSchemeCopy[rowIndex][pos] = numbers[rowIndex][pos].toLocaleString();
      });
    });

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <BarModel
                numbers={numbers}
                answerIndices={answerIndices}
                strings={strings}
                postAnswerString={postAnswerString}
                total={total}
                dimens={dimens}
                oneFontSize={oneFontSize}
                proportional={proportional}
                rowHeight={150}
                sameRowColor={sameRowColor}
                userAnswer={displayMode === 'markscheme' ? deepMarkSchemeCopy : undefined}
                maxFontSize={maxFontSize ?? 50}
                arrowIndices={arrowIndices}
              />
            )}
          </MeasureView>
        }
        questionHeight={questionHeight}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant={actionPanelVariant}
      actionPanelContents={
        <UserInput inputType="numpad" variant={numpadVariant} extraSymbol={extraSymbol} />
      }
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <BarModelWithState
              id="barmodel"
              defaultState={[]}
              testComplete={testComplete}
              testCorrect={testCorrect}
              numbers={numbers}
              answerIndices={answerIndices}
              strings={strings}
              postAnswerString={postAnswerString}
              total={total}
              dimens={dimens}
              oneFontSize={oneFontSize}
              proportional={proportional}
              rowHeight={rowHeight}
              sameRowColor={sameRowColor}
              maxFontSize={maxFontSize ?? 40}
              inputMaxCharacters={inputMaxCharacters}
              arrowIndices={arrowIndices}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
