import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { base10ObjectToNumber } from '../../../../utils/math';
import { ADD } from '../../../../constants';
import { View } from 'react-native';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { isInRange } from '../../../../utils/matchers';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { colors } from '../../../../theme/colors';
import {
  Scale,
  SimpleBaseTenWithCrossOut
} from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bf7',
  description: 'bf7',
  keywords: ['Tens', 'Ones', 'Expanded', 'Add'],
  schema: z.object({
    numberOfOnes: z.number().int().min(1).max(9),
    numberOfTens: z.number().int().min(2).max(9),
    variation: z.enum(['tenFrame', 'rekenrek', 'Cubes', 'Straws']),
    isTensAnswer: z.boolean()
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['tenFrame', 'rekenrek', 'Cubes', 'Straws'] as const);
    const numberOfOnes = randomIntegerInclusive(1, 9);
    const numberOfTens =
      variation === 'tenFrame' ? randomIntegerInclusive(2, 5) : randomIntegerInclusive(2, 9);
    const isTensAnswer = getRandomBoolean();

    return { numberOfOnes, numberOfTens, variation, isTensAnswer };
  },
  Component: props => {
    const {
      question: { numberOfOnes, numberOfTens, variation, isTensAnswer },
      translate
    } = props;

    const total = base10ObjectToNumber({ ones: numberOfOnes, tens: numberOfTens });

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) =>
          variation === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={total} />
          ) : variation === 'Straws' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: variation,
                numbers: { ones: numberOfOnes, tens: numberOfTens },
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
            />
          ) : variation === 'Cubes' ? (
            <SimpleBaseTenWithCrossOut
              tens={numberOfTens}
              ones={numberOfOnes}
              dimens={{ width: dimens.width, height: dimens.height }}
            />
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              {countRange(Math.ceil(total / 10)).map(i => (
                <TenFrameLayout
                  items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                  orientation="vertical"
                  size="xsmall"
                  key={i}
                />
              ))}
            </View>
          )
        }
        pdfDirection="column"
        sentence={`${translate.ks1AnswerSentences.numEqualsAnsTensAnsOnes(
          total,
          numberOfTens,
          numberOfOnes
        )}<br/>${
          isTensAnswer
            ? `${total.toLocaleString()} = <ans/> ${ADD} ${numberOfOnes.toLocaleString()}`
            : `${total.toLocaleString()} = ${(numberOfTens * 10).toLocaleString()} ${ADD} <ans/> `
        }`}
        testCorrect={[
          numberOfTens.toString(),
          numberOfOnes.toString(),
          isTensAnswer ? (numberOfTens * 10).toString() : numberOfOnes.toString()
        ]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bf8',
  description: 'bf8',
  keywords: ['Base 10', 'Flexible partition', 'Add', 'Expanded'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(21)
      .max(99)
      .refine(num => num % 10 !== 0),
    isAnswerBoxLast: z.boolean(),
    isNumberShown: z.boolean(),
    variation: z.enum(['Straws', 'Cubes']),
    tensPartition: z.number().int().min(10).max(80)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(21, 99, { constraint: x => x % 10 !== 0 });
    const isNumberShown = getRandomFromArrayWithWeights([true, false], [3, 1]);
    const isAnswerBoxLast = getRandomBoolean();
    const variation = getRandomFromArray(['Straws', 'Cubes'] as const);

    const tensPartition = randomIntegerInclusiveStep(10, number - 10, 10);

    return { number, isAnswerBoxLast, isNumberShown, variation, tensPartition };
  },
  Component: props => {
    const {
      question: { number, isAnswerBoxLast, isNumberShown, variation, tensPartition },
      translate,
      displayMode
    } = props;

    const color = displayMode === 'digital' ? colors.prussianBlue : 'black';
    const borderWidth = displayMode === 'digital' ? 2 : 4;

    const numberOfTens = Math.floor(number / 10);
    const numberOfOnes = number % 10;

    const sentence = isNumberShown
      ? `${number.toLocaleString()} = <ans/> ${ADD} <ans/>`
      : isAnswerBoxLast
      ? `<ans/> = ${((numberOfTens - 1) * 10).toLocaleString()} ${ADD} <ans/>`
      : `<ans/> = <ans/> ${ADD} ${(numberOfOnes + 10).toLocaleString()}`;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheNumberSentence()}
        sentence={sentence}
        inputMaxCharacters={2}
        testCorrect={userAnswer => {
          if (isNumberShown) {
            return (
              (userAnswer[0] === tensPartition.toString() &&
                userAnswer[1] === Math.floor(number - tensPartition).toString()) ||
              (userAnswer[0] === Math.floor(number - tensPartition).toString() &&
                userAnswer[1] === (tensPartition * 10).toString())
            );
          }
          return isAnswerBoxLast
            ? userAnswer[0] === number.toString() &&
                userAnswer[1] === Math.floor(number - tensPartition).toString()
            : userAnswer[0] === number.toString() && userAnswer[1] === tensPartition.toString();
        }}
        pdfDirection="column"
        Content={({ dimens }) => {
          const scale = BaseTenRepCalcGridsAndScale(
            dimens.width * 0.9,
            dimens.height * 0.9,
            { ones: numberOfOnes, tens: numberOfTens },
            'Cubes'
          ).scale;

          const simpleBaseTenScale = Scale(dimens.width * 0.4, dimens.height, {
            ones: numberOfOnes,
            tens: numberOfTens
          });

          return (
            <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 20 }}>
              <View
                style={{
                  borderRadius: 50,
                  borderWidth,
                  borderColor: color,
                  width: dimens.width * 0.4,
                  height: dimens.height,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {variation === 'Cubes' ? (
                  <SimpleBaseTenWithCrossOut
                    tens={tensPartition / 10}
                    dimens={{ width: dimens.width * 0.4, height: dimens.height }}
                    scale={simpleBaseTenScale}
                  />
                ) : (
                  <BaseTenRepresentation
                    b10Rep={{
                      variant: variation,
                      numbers: { tens: tensPartition / 10 },
                      arrangement: 'ltr'
                    }}
                    usableWidth={dimens.width * 0.9}
                    usableHeight={dimens.height * 0.9}
                    scale={numberOfTens > 5 ? scale * 1.75 : scale}
                    align="center"
                  />
                )}
              </View>
              <View
                style={{
                  borderRadius: 50,
                  borderWidth,
                  borderColor: color,
                  width: dimens.width * 0.4,
                  height: dimens.height,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {variation === 'Cubes' ? (
                  <SimpleBaseTenWithCrossOut
                    ones={numberOfOnes}
                    tens={Math.floor((number - tensPartition) / 10)}
                    dimens={{ width: dimens.width * 0.4, height: dimens.height }}
                    scale={simpleBaseTenScale}
                  />
                ) : (
                  <BaseTenRepresentation
                    b10Rep={{
                      variant: variation,
                      numbers: {
                        tens: Math.floor((number - tensPartition) / 10),
                        ones: numberOfOnes
                      },
                      arrangement: 'ltr'
                    }}
                    usableWidth={dimens.width * 0.9}
                    usableHeight={dimens.height * 0.9}
                    scale={numberOfTens > 5 ? scale * 1.75 : scale}
                    align="center"
                  />
                )}
              </View>
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: isNumberShown
            ? [tensPartition.toLocaleString(), Math.floor(number - tensPartition).toLocaleString()]
            : [
                number.toLocaleString(),
                isAnswerBoxLast
                  ? tensPartition.toLocaleString()
                  : Math.floor(number - tensPartition).toLocaleString()
              ],
          answerText: isNumberShown ? translate.markScheme.acceptAnyOrder() : ''
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bf9',
  description: 'bf9',
  keywords: ['Match', 'Add', 'Expanded', 'Tens', 'Ones', 'Flexible partition'],
  schema: z.object({
    items: z
      .array(z.number().int().min(21).max(99))
      .refine(num => num.every(num => num % 10 !== 0) && arrayHasNoDuplicates(num)),
    statements: z.array(z.object({ lhsComponent: z.string(), correctAnswer: z.number() }))
  }),
  simpleGenerator: () => {
    const { numberA, numberB, numberC } = rejectionSample(
      () => {
        const numberA = randomIntegerInclusive(21, 99, {
          constraint: x => x % 10 !== 0 && x % 11 !== 0
        });

        const numberB = parseInt(numberA.toString().split('').reverse().join(''));

        const numberC = getRandomBoolean() ? numberA + 10 : numberB + 10;

        return { numberA, numberB, numberC };
      },
      ({ numberA, numberB, numberC }) =>
        arrayHasNoDuplicates([numberA, numberB, numberC]) &&
        [numberA, numberB, numberC].every(num => isInRange(21, 99)(num))
    );

    const numberD = randomIntegerInclusive(21, 99, {
      constraint: x => ![numberA, numberB, numberC].includes(x) && x % 10 !== 0
    });

    const items = shuffle([numberA, numberB, numberC, numberD]);

    const isOneFirst = getRandomBoolean();

    const statements = shuffle([
      {
        lhsComponent: `${Math.floor(items[0] / 10) * 10} ${ADD} ${items[0] % 10} = <ans/>`,
        correctAnswer: items[0]
      },
      {
        lhsComponent: `${items[1] % 10} ${ADD} ${Math.floor(items[1] / 10) * 10} = <ans/>`,
        correctAnswer: items[1]
      },
      {
        lhsComponent: `${Math.floor(items[2] / 10) * 10 - 10} ${ADD} ${
          (items[2] % 10) + 10
        } = <ans/>`,
        correctAnswer: items[2]
      },
      {
        lhsComponent: isOneFirst
          ? `${Math.floor(items[3] / 10) * 10} ${ADD} ${items[3] % 10} = <ans/>`
          : ` ${items[3] % 10} ${ADD} ${Math.floor(items[3] / 10) * 10} = <ans/>`,
        correctAnswer: items[3]
      }
    ]);

    return { items, statements };
  },
  Component: props => {
    const {
      question: { items, statements },
      translate
    } = props;

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheNumberSentences()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheNumberSentences()}
        items={items.map(num => num.toString())}
        actionPanelVariant="end"
        pdfLayout="itemsTop"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        pdfSentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        sentences={statements.map(({ lhsComponent }) => lhsComponent)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer.toString()])}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'WriteNumbersTo100InExpandedForm',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
