import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import TextStructure from '../../../../components/molecules/TextStructure';
import { fractionSchema } from '../../../../utils/zod';
import { Fraction, fractionToDecimal } from '../../../../utils/fractions';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import { barModelColors } from '../../../../theme/colors';
import deepEqual from 'react-fast-compare';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apm',
  description: 'apm',
  keywords: ['Compare', 'Fractions', 'Common denominators'],
  schema: z.object({
    fraction1: fractionSchema(),
    fraction2: fractionSchema(),
    numeratorColor: z.string()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 8);
    const number2 = randomIntegerInclusive(1, number1 - 2);
    const number3 = randomIntegerInclusive(number2 + 1, number1 - 1);
    const numeratorColor = getRandomFromArray(Object.values(barModelColors)) as string;
    const reverse = Math.random() < 0.5;

    const [fracA, fracB]: Fraction[] = [
      [number2, number1],
      [number3, number1]
    ];

    return {
      fraction1: reverse ? fracA : fracB,
      fraction2: reverse ? fracB : fracA,
      numeratorColor
    };
  },
  Component: props => {
    const {
      question: { fraction1, fraction2, numeratorColor },
      translate,
      displayMode
    } = props;

    const [fracANumerator, fracADenominator] = fraction1;
    const [fracBNumerator, fracBDenominator] = fraction2;

    // Bar Model 1
    const numeratorColorArray1 = filledArray(numeratorColor, fracANumerator);
    const remainder1 = filledArray('white', fracADenominator - fracANumerator);

    // Bar Model 2
    const numeratorColorArray2 = filledArray(numeratorColor, fracBNumerator);
    const remainder2 = filledArray('white', fracBDenominator - fracBNumerator);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    // Answers
    const answerOptions = ['>', '<', '='];

    // Fractions
    const fractionDecimal1 = fractionToDecimal(fracANumerator, fracADenominator);
    const fractionDecimal2 = fractionToDecimal(fracBNumerator, fracBDenominator);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.useBarModelsToCompareFractionsDragCardsToMakeStatementCorrect()}
        pdfTitle={translate.instructions.useBarModelsToCompareFractionsUseLessThanGreaterThanEqualsToMakeStatementCorrect()}
        pdfLayout="itemsHidden"
        items={answerOptions}
        itemVariant="square"
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <ShadedFractionBarModel
              totalSubSections={fracADenominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${fracANumerator.toLocaleString()}' d='${fracADenominator.toLocaleString()}'/>`}
            />
            <ShadedFractionBarModel
              totalSubSections={fracBDenominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${fracBNumerator.toLocaleString()}' d='${fracBDenominator.toLocaleString()}'/>`}
            />
          </View>
        )}
        sentence={`<frac n='${fracANumerator.toLocaleString()}' d='${fracADenominator.toLocaleString()}'/> <ans/> <frac n='${fracBNumerator.toLocaleString()}' d='${fracBDenominator.toLocaleString()}'/>`}
        testCorrect={[lessThanGreaterThanOrEqualTo(fractionDecimal1, fractionDecimal2)]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'apn',
  description: 'apn',
  keywords: ['Compare', 'Fractions', 'Common numerators'],
  schema: z.object({
    fraction1: fractionSchema(),
    fraction2: fractionSchema(),
    numeratorColor: z.string()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(4, 8);
    const number2 = randomIntegerInclusive(3, number1 - 1);
    const number3 = randomIntegerInclusive(1, number2 - 1);
    const numeratorColor = getRandomFromArray(Object.values(barModelColors)) as string;
    const reverse = Math.random() < 0.5;

    const [fracA, fracB]: Fraction[] = [
      [number3, number1],
      [number3, number2]
    ];

    return {
      fraction1: reverse ? fracA : fracB,
      fraction2: reverse ? fracB : fracA,
      numeratorColor
    };
  },
  Component: props => {
    const {
      question: { fraction1, fraction2, numeratorColor },
      translate,
      displayMode
    } = props;

    const [fracANumerator, fracADenominator] = fraction1;
    const [fracBNumerator, fracBDenominator] = fraction2;

    // Bar Model 1
    const numeratorColorArray1 = filledArray(numeratorColor, fracANumerator);
    const remainder1 = filledArray('white', fracADenominator - fracANumerator);

    // Bar Model 2
    const numeratorColorArray2 = filledArray(numeratorColor, fracBNumerator);
    const remainder2 = filledArray('white', fracBDenominator - fracBNumerator);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    // Answers
    const answerOptions = ['>', '<', '='];

    // Fractions
    const fractionDecimal1 = fractionToDecimal(fracANumerator, fracADenominator);
    const fractionDecimal2 = fractionToDecimal(fracBNumerator, fracBDenominator);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.useBarModelsToCompareFractionsDragCardsToMakeStatementCorrect()}
        pdfTitle={translate.instructions.useBarModelsToCompareFractionsUseLessThanGreaterThanEqualsToMakeStatementCorrect()}
        pdfLayout="itemsHidden"
        items={answerOptions}
        itemVariant="square"
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <ShadedFractionBarModel
              totalSubSections={fracADenominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${fracANumerator.toLocaleString()}' d='${fracADenominator.toLocaleString()}'/>`}
            />
            <ShadedFractionBarModel
              totalSubSections={fracBDenominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${fracBNumerator.toLocaleString()}' d='${fracBDenominator.toLocaleString()}'/>`}
            />
          </View>
        )}
        sentence={`<frac n='${fracANumerator.toLocaleString()}' d='${fracADenominator.toLocaleString()}'/> <ans/> <frac n='${fracBNumerator.toLocaleString()}' d='${fracBDenominator.toLocaleString()}'/>`}
        testCorrect={[lessThanGreaterThanOrEqualTo(fractionDecimal1, fractionDecimal2)]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'apo',
  description: 'apo',
  keywords: ['Compare', 'Fractions'],
  schema: z.object({
    shuffledA: fractionSchema(z.number().int().min(1).max(11), z.number().int().min(3).max(12))
      .array()
      .length(2),
    shuffledB: fractionSchema(
      z.number().int().min(1).max(99),
      z.number().int().min(10).max(100).multipleOf(10)
    )
      .array()
      .length(2),
    shuffledC: fractionSchema(z.number().int().min(1).max(7), z.number().int().min(3).max(24))
      .array()
      .length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 12);
    const number2 = randomIntegerInclusive(1, number1 - 2);
    const number3 = randomIntegerInclusive(number2 + 1, number1 - 1);

    const number4 = randomIntegerInclusiveStep(10, 100, 10);
    const number5 = randomIntegerInclusive(1, number4 / 2);
    const number6 = randomIntegerInclusive(number5 + 1, number4 - 1);

    const number7 = randomIntegerInclusive(3, 8);
    const number8 = randomIntegerInclusive(1, number7 - 1);
    const number9 = randomIntegerInclusive(2 * number7, 3 * number7);

    const statementAFractions: Fraction[] = [
      [number2, number1],
      [number3, number1]
    ];

    const statementBFractions: Fraction[] = [
      [number5, number4],
      [number6, number4]
    ];

    const statementCFractions: Fraction[] = [
      [number8, number7],
      [number8, number9]
    ];

    // Shuffle individual fractions
    const shuffledA = shuffle(statementAFractions);
    const shuffledB = shuffle(statementBFractions);
    const shuffledC = shuffle(statementCFractions);

    return { shuffledA, shuffledB, shuffledC };
  },
  Component: props => {
    const {
      question: { shuffledA, shuffledB, shuffledC },
      translate
    } = props;

    const shuffledFractions = shuffle(
      [
        [...shuffledA[0], ...shuffledA[1]],
        [...shuffledB[0], ...shuffledB[1]],
        [...shuffledC[0], ...shuffledC[1]]
      ],
      { random: seededRandom(props.question) }
    );

    const correctAnswers = shuffledFractions.map(frac => {
      const lhs = fractionToDecimal(frac[0], frac[1]);
      const rhs = fractionToDecimal(frac[2], frac[3]);

      return [lessThanGreaterThanOrEqualTo(lhs, rhs)];
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useInequalitiesToCompleteNumberSentencesEachSymbolCanBeUsedMoreThanOnce()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={shuffledFractions.map(
          frac =>
            `<frac n='${frac[0].toLocaleString()}' d='${frac[1].toLocaleString()}'/> <ans/> <frac n='${frac[2].toLocaleString()}' d='${frac[3].toLocaleString()}'/>`
        )}
        testCorrect={correctAnswers}
        moveOrCopy="copy"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3v2 = newQuestionContent({
  uid: 'apo2',
  description: 'apo2',
  keywords: ['Compare', 'Fractions'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(99),
    denominatorA: z.number().int().min(3).max(100),
    numeratorB: z.number().int().min(1).max(99),
    denominatorB: z.number().int().min(3).max(100)
  }),
  simpleGenerator: () => {
    const equationForm = getRandomFromArrayWithWeights(
      ['sameDenominatorA', 'sameDenominatorB', 'differentDenominator'] as const,
      [1, 1, 2]
    );

    const firstDenominator = (() => {
      switch (equationForm) {
        case 'sameDenominatorA':
          return randomIntegerInclusive(3, 12);
        case 'sameDenominatorB':
          return randomIntegerInclusiveStep(10, 100, 10);
        case 'differentDenominator':
          return randomIntegerInclusive(3, 8);
      }
    })();

    const firstNumerator = (() => {
      switch (equationForm) {
        case 'sameDenominatorA':
          return randomIntegerInclusive(1, firstDenominator - 2);
        case 'sameDenominatorB':
          return randomIntegerInclusive(1, firstDenominator / 2);
        case 'differentDenominator':
          return randomIntegerInclusive(1, firstDenominator - 1);
      }
    })();

    const secondDenominator = (() => {
      switch (equationForm) {
        case 'sameDenominatorA':
        case 'sameDenominatorB':
          return firstDenominator;
        case 'differentDenominator':
          return randomIntegerInclusive(2 * firstDenominator, 3 * firstDenominator);
      }
    })();

    const secondNumerator = (() => {
      switch (equationForm) {
        case 'sameDenominatorA':
        case 'sameDenominatorB':
          return randomIntegerInclusive(firstNumerator + 1, secondDenominator - 1);
        case 'differentDenominator':
          return firstNumerator;
      }
    })();

    const statementFractions = [
      [firstNumerator, firstDenominator],
      [secondNumerator, secondDenominator]
    ];

    // Shuffle individual fractions
    const [[numeratorA, denominatorA], [numeratorB, denominatorB]] = shuffle(statementFractions);

    return { numeratorA, denominatorA, numeratorB, denominatorB };
  },
  Component: props => {
    const {
      question: { numeratorA, denominatorA, numeratorB, denominatorB },
      translate
    } = props;

    const lhs = fractionToDecimal(numeratorA, denominatorA);
    const rhs = fractionToDecimal(numeratorB, denominatorB);

    const testCorrect = lessThanGreaterThanOrEqualTo(lhs, rhs);

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragACardToCompareTheFractions()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useInequalitySymbolsToCompareTheFractions()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentence={`<frac n='${numeratorA}' d='${denominatorA}'/> <ans/> <frac n='${numeratorB}' d='${denominatorB}'/>`}
        testCorrect={[testCorrect]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'app',
  description: 'app',
  keywords: ['Compare', 'Fractions'],
  schema: z.object({
    fraction1: fractionSchema(),
    fraction2: fractionSchema(),
    numeratorColor: z.string()
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 5);
    const number2 = randomIntegerInclusive(1, number1 - 1);
    const number3 = number1 * 2;
    const number4 = randomIntegerInclusive(number2, number3 - 1);
    const numeratorColor = getRandomFromArray(Object.values(barModelColors)) as string;
    const reverse = Math.random() < 0.5;

    const [fracA, fracB]: Fraction[] = [
      [number2, number1],
      [number4, number3]
    ];

    return {
      fraction1: reverse ? fracA : fracB,
      fraction2: reverse ? fracB : fracA,
      numeratorColor
    };
  },
  Component: props => {
    const {
      question: { fraction1, fraction2, numeratorColor },
      translate,
      displayMode
    } = props;

    const [fracANumerator, fracADenominator] = fraction1;
    const [fracBNumerator, fracBDenominator] = fraction2;

    // Bar Model 1
    const numeratorColorArray1 = filledArray(numeratorColor, fracANumerator);
    const remainder1 = filledArray('white', fracADenominator - fracANumerator);

    // Bar Model 2
    const numeratorColorArray2 = filledArray(numeratorColor, fracBNumerator);
    const remainder2 = filledArray('white', fracBDenominator - fracBNumerator);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    // Answers
    const answerOptions = ['>', '<', '='];

    // Fractions
    const fractionDecimal1 = fractionToDecimal(fracANumerator, fracADenominator);
    const fractionDecimal2 = fractionToDecimal(fracBNumerator, fracBDenominator);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.useBarModelsToCompareFractionsDragCardsToMakeStatementCorrect()}
        pdfTitle={translate.instructions.useBarModelsToCompareFractionsUseLessThanGreaterThanEqualsToMakeStatementCorrect()}
        pdfLayout="itemsHidden"
        items={answerOptions}
        itemVariant="square"
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View>
            <ShadedFractionBarModel
              totalSubSections={fracADenominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${fracANumerator.toLocaleString()}' d='${fracADenominator.toLocaleString()}'/>`}
            />
            <ShadedFractionBarModel
              totalSubSections={fracBDenominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${fracBNumerator.toLocaleString()}' d='${fracBDenominator.toLocaleString()}'/>`}
            />
          </View>
        )}
        sentence={`<frac n='${fracANumerator.toLocaleString()}' d='${fracADenominator.toLocaleString()}'/> <ans/> <frac n='${fracBNumerator.toLocaleString()}' d='${fracBDenominator.toLocaleString()}'/>`}
        testCorrect={[lessThanGreaterThanOrEqualTo(fractionDecimal1, fractionDecimal2)]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'apq',
  description: 'apq',
  keywords: ['Compare', 'Fractions'],
  schema: z.object({
    statementFractions: fractionSchema().array().length(6)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 10);
    const number2 = randomIntegerInclusive(1, number1 - 1);
    const number3 = number1 * 2;
    const number4 = randomIntegerInclusive(1, number3 - 1);

    const number5 = randomIntegerInclusive(3, 10);
    const number6 = randomIntegerInclusive(1, number5 - 1);
    const number7 = number5 * 3;
    const number8 = randomIntegerInclusive(number6, number7 - 1);

    const number9 = randomIntegerInclusive(3, 10);
    const number10 = randomIntegerInclusive(1, number9 - 1);
    const number11 = number9 * 5;
    const number12 = randomIntegerInclusive(number9, number11 - 1);

    const statementFractions: Fraction[] = [
      [number2, number1],
      [number4, number3],
      [number6, number5],
      [number8, number7],
      [number10, number9],
      [number12, number11]
    ];

    return { statementFractions };
  },
  Component: props => {
    const {
      question: { statementFractions },
      translate
    } = props;

    const fracs = [
      [...statementFractions[0], ...statementFractions[1]],
      [...statementFractions[2], ...statementFractions[3]],
      [...statementFractions[4], ...statementFractions[5]]
    ];

    const correctAnswers = fracs.map(frac => {
      const lhs = fractionToDecimal(frac[0], frac[1]);
      const rhs = fractionToDecimal(frac[2], frac[3]);

      return [lessThanGreaterThanOrEqualTo(lhs, rhs)];
    });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMakeStatementsCorrect()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementsCorrect()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentences={fracs.map(
          frac =>
            `<frac n='${frac[0].toLocaleString()}' d='${frac[1].toLocaleString()}'/> <ans/> <frac n='${frac[2].toLocaleString()}' d='${frac[3].toLocaleString()}'/>`
        )}
        testCorrect={correctAnswers}
        moveOrCopy="copy"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'apr',
  description: 'apr',
  keywords: ['Half', 'Compare', 'Fractions'],
  schema: z.object({
    greaterOrLessThan: z.enum(['<', '>']),
    answerOptions: fractionSchema().array().length(6)
  }),
  simpleGenerator: () => {
    const greaterOrLessThan = getRandomFromArray(['<', '>'] as const);

    const answerOptions: Fraction[] = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 12);
        const number2 = number1 + 1;

        const number3 = getRandomFromArray([1, 2] as const);
        const number4 = randomIntegerInclusive(5, 12);

        const number5 = randomIntegerInclusive(2, 20);
        const number6 = number5 * 2;

        const number7 = randomIntegerInclusive(2, 20);
        const number8 = randomIntegerInclusive(2 * number7 + 1, 3 * number7 - 1);

        const number9 = randomIntegerInclusive(6, 20);
        const number10 = randomIntegerInclusive(number9 + 1, 2 * number9 - 1);

        const number12 = randomIntegerInclusiveStep(10, 90, 10);
        const number11 = randomIntegerInclusive(1, number12);

        const answerOptions: Fraction[] = [
          [number1, number2],
          [number3, number4],
          [number5, number6],
          [number7, number8],
          [number9, number10],
          [number11, number12]
        ];

        return answerOptions;
      },
      answerOptions => arrayHasNoDuplicates(answerOptions, deepEqual)
    );

    return { greaterOrLessThan, answerOptions };
  },
  Component: props => {
    const {
      question: { greaterOrLessThan, answerOptions },
      translate,
      displayMode
    } = props;

    return (
      <QF10SelectNumbers<Fraction>
        title={translate.instructions.selectFractionsGreaterOrLessThanAHalf(
          greaterOrLessThan === '<' ? 'less' : 'greater'
        )}
        pdfTitle={translate.instructions.circleFractionsGreaterOrLessThanAHalf(
          greaterOrLessThan === '<' ? 'less' : 'greater'
        )}
        testCorrect={
          greaterOrLessThan === '<'
            ? answerOptions.filter(frac => fractionToDecimal(...frac) < 0.5)
            : answerOptions.filter(frac => fractionToDecimal(...frac) > 0.5)
        }
        items={answerOptions.map(frac => ({
          component: (
            <TextStructure
              sentence={`<frac n='${frac[0].toLocaleString()}' d='${frac[1].toLocaleString()}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: frac
        }))}
        multiSelect
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareFractionsLessThan1',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
