import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  eqsToAlignedEqs,
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import { filledArray } from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { AssetSvg } from '../../../../assets/svg';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'alX',
  description: 'alX',
  keywords: ['Times-table', '12', 'Multiply'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 12, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 12, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ left: 12, right: numberC1, sign: 'multiply', answer: 'result' });

    const eqD = getBinOpEquation({ left: 12, right: numberD1, sign: 'multiply', answer: 'right' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'alX2',
  description: 'alX',
  keywords: ['Times-table', '12', 'Multiply'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 12, sign: 'multiply', answer: 'result' });
    const eqB = getBinOpEquation({ left: numberB1, right: 12, sign: 'multiply', answer: 'left' });
    const eqC = getBinOpEquation({ left: 12, right: numberC1, sign: 'multiply', answer: 'result' });
    const eqD = getBinOpEquation({ left: 12, right: numberD1, sign: 'multiply', answer: 'right' });

    const eqs = [eqA, eqB, eqC, eqD];

    const { leftSide, rightSide, leftAnswers, rightAnswers } = eqsToAlignedEqs(eqs);

    return (
      <QF2AlignedEquations
        questionHeight={900}
        title={translate.instructions.completeNumberSentences()}
        leftSide={leftSide}
        rightSide={rightSide}
        testCorrect={{ left: leftAnswers, right: rightAnswers }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'alY',
  description: 'alY',
  keywords: ['Times-table', '12', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ right: 12, result: numberA1, sign: 'divide', answer: 'result' });

    const eqB = getBinOpEquation({ right: 12, result: numberB1, sign: 'divide', answer: 'left' });

    const eqC = getBinOpEquation({ right: 12, result: numberC1, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ right: 12, result: numberD1, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'alY2',
  description: 'alY',
  keywords: ['Times-table', '12', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ right: 12, result: numberA1, sign: 'divide', answer: 'result' });
    const eqB = getBinOpEquation({ right: 12, result: numberB1, sign: 'divide', answer: 'left' });
    const eqC = getBinOpEquation({ right: 12, result: numberC1, sign: 'divide', answer: 'result' });
    const eqD = getBinOpEquation({ right: 12, result: numberD1, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    const { leftSide, rightSide, leftAnswers, rightAnswers } = eqsToAlignedEqs(eqs);

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeNumberSentences()}
        leftSide={leftSide}
        rightSide={rightSide}
        testCorrect={{ left: leftAnswers, right: rightAnswers }}
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'alZ',
  description: 'alZ',
  keywords: ['Times-table', '12', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    variant: z.enum(['completeBottomRow', 'splitBottomRowBy12', 'fillBottomRowWith12'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const variant = getRandomFromArray([
      'completeBottomRow',
      'splitBottomRowBy12',
      'fillBottomRowWith12'
    ] as const);

    return { number1, variant };
  },

  Component: ({ question: { number1, variant }, translate, ...props }) => {
    const multiplier = 12;
    const number3 = number1 * multiplier;

    let answerBoxes: number[];
    let answerIndices: number[][];
    let barArray: number[];

    // Three variantions of bar model
    switch (variant) {
      case 'fillBottomRowWith12':
        answerIndices = [[0], []];
        barArray = filledArray(multiplier, number1);
        break;
      case 'splitBottomRowBy12':
        answerIndices = [[0], []];
        barArray = filledArray(number1, multiplier);
        break;
      case 'completeBottomRow':
        answerBoxes = [...Array(multiplier).keys()];
        answerIndices = [[], answerBoxes];
        barArray = filledArray(number1, multiplier);
        break;
    }

    const numbers = [[number3], barArray];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        sameRowColor
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'al0',
  description: 'al0',
  keywords: ['Multiple', '12'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(144)
      .array()
      .length(6)
      .refine(
        numbers => numbers.filter(x => x % 12 === 0).length >= 2,
        'At least 2 multiples of 12'
      )
  }),
  simpleGenerator: () => {
    const numMultiples = randomIntegerInclusive(2, 5);

    const multiples = randomUniqueIntegersInclusive(1, 144, numMultiples, {
      constraint: x => x % 12 === 0
    });

    const notMultiples = randomUniqueIntegersInclusive(1, 144, 6 - numMultiples, {
      constraint: x => x % 12 !== 0
    });

    const numbers = shuffle([...multiples, ...notMultiples]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMultiplesOfX(12)}
        pdfTitle={translate.instructions.circleTheMultiplesOfX(12)}
        testCorrect={numbers.filter(it => it % 12 === 0)}
        multiSelect
        items={() =>
          numbers.map(number => ({
            value: number,
            component: number.toLocaleString()
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'al1',
  description: 'al1',
  keywords: ['Multiply', 'Divide', '12', 'Problem'],
  schema: z.object({
    numberA1: z.number().int().min(2).max(12),
    numberB1: z.number().int().min(2).max(12)
  }),
  simpleGenerator: () => {
    const [numberA1, numberB1] = randomUniqueIntegersInclusive(2, 12, 2);
    return { numberA1, numberB1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1 },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeSentences()}
        testCorrect={[[], [(numberA1 * 12).toString()], [numberB1.toString()]]}
        sentences={[
          translate.answerSentences.eggBoxContains12Eggs(),
          translate.answerSentences.thereAreAnsEggsInNumberBoxes(numberA1.toLocaleString()),
          translate.answerSentences.thereAreNumberEggsInAnsBoxes((numberB1 * 12).toLocaleString())
        ]}
        pdfDirection="column"
        questionHeight={900}
        Content={<AssetSvg name="Carton_of_eggs_0_-12/Carton_of_eggs_12" />}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'al2',
  description: 'al2',
  keywords: ['Times-table', '12', 'Multiply'],
  schema: z.object({
    number1: z.number().int().min(13).max(45)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(13, 45);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: number1, right: 10, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: number1, right: 2, sign: 'multiply', answer: 'result' });

    const eqC = getBinOpEquation({ left: number1, right: 12, sign: 'multiply', answer: 'result' });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Num12TimesTableAndDivisionFacts',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1, Question2]
});
export default SmallStep;
