import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import GridImage from '../../../../components/question/representations/Coordinates/GridImage';
import { colors } from '../../../../theme/colors';
import {
  arraysHaveSameContents,
  countRange,
  nestedArrayHasNoDuplicates
} from '../../../../utils/collections';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { isValidTriangle } from '../../../../utils/shapes';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import Svg from 'react-native-svg';
import { GridPolygon } from '../../../../utils/gridUtils';
import Grid from '../../../../components/question/representations/Coordinates/Grid';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'azk',
  description: 'azk',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate1: z.number().int().min(1).max(5).array().length(2),
    coordinate2: z.number().int().min(1).max(5).array().length(2),
    coordinate3: z.number().int().min(1).max(5).array().length(2),
    coordinate4: z.number().int().min(1).max(5).array().length(2)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = randomUniqueIntegersInclusive(1, 5, 2);
        const coordinate2 =
          coordinate1[0] !== coordinate1[1] && getRandomBoolean()
            ? [...coordinate1].reverse()
            : randomUniqueIntegersInclusive(1, 5, 2);
        const coordinate3 = randomUniqueIntegersInclusive(1, 5, 2);
        const coordinate4 = randomUniqueIntegersInclusive(1, 5, 2);
        return { coordinate1, coordinate2, coordinate3, coordinate4 };
      },
      val =>
        nestedArrayHasNoDuplicates(
          [val.coordinate1, val.coordinate2, val.coordinate3, val.coordinate4],
          true
        )
    ),
  Component: props => {
    const {
      question: { coordinate1, coordinate2, coordinate3, coordinate4 },
      translate
    } = props;
    const options = [coordinate1, coordinate2, coordinate3, coordinate4];

    const selectables = shuffle(
      options.map((val, i) => [
        ['A', 'B', 'C', 'D'][i],
        translate.answerSentences.xYCoordinate(val[0].toLocaleString(), val[1].toLocaleString())
      ]),
      { random: seededRandom(props.question) }
    );
    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectCoordOfPoint()}
        pdfTitle={translate.instructions.circleCoordOfPoint()}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={['A']}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid width={dimens.width} height={dimens.height} xMax={5} yMax={5} squareGrid>
                <GridImage
                  mathCoord={coordinate1 as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.pacificBlue }
                  }}
                />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'azl',
  description: 'azl',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(1).max(5).array().length(2)
  }),
  simpleGenerator: () => ({
    coordinate: [randomIntegerInclusive(1, 5), randomIntegerInclusive(1, 5)]
  }),
  Component: ({ question: { coordinate }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatAreTheCoordinatesOfThePoints(1)}
        sentence="( <ans/> , <ans/> )"
        testCorrect={coordinate.map(it => it.toString())}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={coordinate as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'azm',
  description: 'azm',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate1: z.number().int().min(0).max(5).array().length(2),
    coordinate2: z.number().int().min(0).max(5).array().length(2),
    coordinate3: z.number().int().min(0).max(5).array().length(2),
    coordinate4: z.number().int().min(0).max(5).array().length(2)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const isX0 = getRandomBoolean();
        const coordinate1 = [
          isX0 ? 0 : randomIntegerInclusive(1, 5),
          isX0 ? randomIntegerInclusive(1, 5) : 0
        ];
        const coordinate2 =
          coordinate1[0] !== coordinate1[1] && getRandomBoolean()
            ? [...coordinate1].reverse()
            : randomUniqueIntegersInclusive(0, 5, 2);
        const coordinate3 = randomUniqueIntegersInclusive(0, 5, 2);
        const coordinate4 = randomUniqueIntegersInclusive(0, 5, 2);
        return { coordinate1, coordinate2, coordinate3, coordinate4 };
      },
      val =>
        nestedArrayHasNoDuplicates(
          [val.coordinate1, val.coordinate2, val.coordinate3, val.coordinate4],
          true
        )
    ),
  Component: props => {
    const {
      question: { coordinate1, coordinate2, coordinate3, coordinate4 },
      translate
    } = props;
    const options = [coordinate1, coordinate2, coordinate3, coordinate4];

    const selectables = shuffle(
      options.map((val, i) => [
        ['A', 'B', 'C', 'D'][i],
        translate.answerSentences.xYCoordinate(val[0].toLocaleString(), val[1].toLocaleString())
      ]),
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectCoordOfPoint()}
        pdfTitle={translate.instructions.circleCoordOfPoint()}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={['A']}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid width={dimens.width} height={dimens.height} xMax={5} yMax={5} squareGrid>
                <GridImage
                  mathCoord={coordinate1 as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.pacificBlue }
                  }}
                />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'azn',
  description: 'azn',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate: z.number().int().min(0).max(5).array().length(2)
  }),
  simpleGenerator: () => {
    const isX0 = getRandomBoolean();
    const coordinate = [
      isX0 ? 0 : randomIntegerInclusive(1, 5),
      isX0 ? randomIntegerInclusive(1, 5) : 0
    ];
    return { coordinate };
  },
  Component: ({ question: { coordinate }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatAreTheCoordinatesOfThePoints(1)}
        sentence="( <ans/> , <ans/> )"
        testCorrect={coordinate.map(it => it.toLocaleString())}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <GridImage
              mathCoord={coordinate as [number, number]}
              item={{
                component: 'Coordinates/CirclePointCustomizable',
                svgProps: { fill: colors.pacificBlue }
              }}
            />
          </Grid>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'azo',
  description: 'azo',
  keywords: ['Coordinate', 'Coordinate grid'],
  schema: z.object({
    coordinate1: z.number().int().min(0).max(5).array().length(2),
    coordinate2: z.number().int().min(0).max(5).array().length(2),
    coordinate3: z.number().int().min(0).max(5).array().length(2),
    coordinate4: z.number().int().min(0).max(5).array().length(2),
    answerIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinates = countRange(4).map(() => randomUniqueIntegersInclusive(0, 5, 2));
        const [answerIndex, other] = randomUniqueIntegersInclusive(0, 3, 2);
        const answerCoord = coordinates[answerIndex];
        const [coordinate1, coordinate2, coordinate3, coordinate4] = coordinates.map((val, i) => {
          if (i === other) {
            return answerCoord[0] === answerCoord[1] ? val : [...answerCoord].reverse();
          } else {
            return val;
          }
        });

        return { coordinate1, coordinate2, coordinate3, coordinate4, answerIndex };
      },
      val =>
        nestedArrayHasNoDuplicates(
          [val.coordinate1, val.coordinate2, val.coordinate3, val.coordinate4],
          true
        )
    ),
  Component: props => {
    const {
      question: { coordinate1, coordinate2, coordinate3, coordinate4, answerIndex },
      translate
    } = props;
    const coordinates = [coordinate1, coordinate2, coordinate3, coordinate4];

    const selectables = [
      ['A', translate.shapes.crosses(1)],
      ['B', translate.shapes.triangles(1)],
      ['C', translate.shapes.squares(1)],
      ['D', translate.shapes.circles(1)]
    ];

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.instructions.selectShapeAtCoordinateXY(
          coordinates[answerIndex][0].toLocaleString(),
          coordinates[answerIndex][1].toLocaleString()
        )}
        pdfTitle={translate.instructions.circleShapeAtCoordinateXY(
          coordinates[answerIndex][0].toLocaleString(),
          coordinates[answerIndex][1].toLocaleString()
        )}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={[['A', 'B', 'C', 'D'][answerIndex]]}
        leftContent={
          <MeasureView>
            {dimens => (
              <Grid width={dimens.width} height={dimens.height} xMax={5} yMax={5} squareGrid>
                <GridImage
                  mathCoord={coordinate1 as [number, number]}
                  item={{
                    component: 'Coordinates/CrossPointCustomizable',
                    svgProps: { fill: colors.pacificBlue }
                  }}
                />
                <GridImage
                  mathCoord={coordinate2 as [number, number]}
                  item={{
                    component: 'Coordinates/TrianglePointCustomizable',
                    svgProps: { fill: colors.orange2 }
                  }}
                />
                <GridImage
                  mathCoord={coordinate3 as [number, number]}
                  item={{
                    component: 'Coordinates/FilledSquarePointCustomizable',
                    svgProps: { fill: colors.green }
                  }}
                />
                <GridImage
                  mathCoord={coordinate4 as [number, number]}
                  item={{
                    component: 'Coordinates/CirclePointCustomizable',
                    svgProps: { fill: colors.yellow }
                  }}
                />
              </Grid>
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'azp',
  description: 'azp',
  keywords: ['Coordinate', 'Coordinate grid'],
  questionHeight: 850,
  schema: z.object({
    coordinate1: z.number().int().min(0).max(4).array().length(2),
    coordinate2: z.number().int().min(0).max(4).array().length(2),
    coordinate3: z.number().int().min(0).max(4).array().length(2)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const coordinate1 = [randomIntegerInclusive(0, 4), randomIntegerInclusive(0, 4)];
        const coordinate2 = [randomIntegerInclusive(0, 4), randomIntegerInclusive(0, 4)];
        const coordinate3 = [randomIntegerInclusive(0, 4), randomIntegerInclusive(0, 4)];
        return { coordinate1, coordinate2, coordinate3 };
      },
      val => {
        const point1 = val.coordinate1 as [number, number];
        const point2 = val.coordinate2 as [number, number];
        const point3 = val.coordinate3 as [number, number];

        return (
          isValidTriangle(point1, point2, point3, 2) &&
          // make sure its always bigger than 1x2
          (Math.abs(point1[0] - point2[0]) > 1 || Math.abs(point1[1] - point2[1]) > 1) &&
          (Math.abs(point2[0] - point3[0]) > 1 || Math.abs(point2[1] - point3[1]) > 1) &&
          (Math.abs(point3[0] - point1[0]) > 1 || Math.abs(point3[1] - point1[1]) > 1)
        );
      }
    ),
  Component: ({ question: { coordinate1, coordinate2, coordinate3 }, translate, displayMode }) => {
    const answer = [coordinate1, coordinate2, coordinate3].map(i => [
      i[0].toString(),
      i[1].toString()
    ]);
    return (
      <QF1ContentAndSentences
        title={translate.instructions.whatAreTheCoordinatesOfTheVerticesOfTheTriangle()}
        sentences={['( <ans/> , <ans/> )', '( <ans/> , <ans/> )', '( <ans/> , <ans/> )']}
        questionHeight={850}
        testCorrect={userAnswer =>
          userAnswer.every(
            array =>
              arraysHaveSameContents(array, answer[0]) ||
              arraysHaveSameContents(array, answer[1]) ||
              arraysHaveSameContents(array, answer[2])
          ) && nestedArrayHasNoDuplicates(userAnswer, true)
        }
        inputMaxCharacters={1}
        customMarkSchemeAnswer={{
          answersToDisplay: [coordinate1, coordinate2, coordinate3].map(i => [
            i[0].toLocaleString(),
            i[1].toLocaleString()
          ]),
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens: { width, height } }) => (
          <Grid width={width} height={height} xMax={5} yMax={5} squareGrid>
            <Svg height={height}>
              <GridPolygon
                points={[
                  coordinate1 as [number, number],
                  coordinate2 as [number, number],
                  coordinate3 as [number, number]
                ]}
                color={displayMode !== 'digital' ? `${colors.pdfShading}70` : undefined}
                showBorder
              />
            </Svg>
          </Grid>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribePositionUsingCoordinates',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
