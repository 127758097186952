import { View } from 'react-native';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  barColorNames,
  barColorsNamesSchema,
  barModelColors,
  barModelColorsArray,
  barModelColorsSchema
} from '../../../../theme/colors';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ADD, SUB } from '../../../../constants';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import Text from '../../../../components/typography/Text';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNa',
  description: 'aNa',
  keywords: [
    'Bar model',
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      whole: z.number().int().min(1).max(2),
      denominator: z.number().int().min(3).max(8),
      numerator1: z.number().int().min(1).max(6),
      numerator2: z.number().int().min(1).max(7),
      numeratorColors: z.array(barModelColorsSchema).length(2),
      isMixed: z.boolean()
    })
    .refine(
      val => val.numerator1 + val.numerator2 < val.denominator,
      'numerator1 + numerator2 must be less than denominator.'
    ),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 2);
    const denominator = randomIntegerInclusive(3, 8);
    const numerator1 = randomIntegerInclusive(1, denominator - 2);
    const numerator2 = randomIntegerInclusive(1, denominator - numerator1 - 1);
    const numeratorColors = getRandomSubArrayFromArray(barModelColorsArray, 2);
    const isMixed = getRandomBoolean();

    return { whole, denominator, numerator1, numerator2, numeratorColors, isMixed };
  },
  questionHeight: 900,
  Component: props => {
    const {
      question: { whole, denominator, numerator1, numerator2, numeratorColors, isMixed },
      translate
    } = props;

    const barModelArrays = [
      ...countRange(whole).map(() => filledArray(barModelColors[numeratorColors[0]], denominator)),
      countRange(denominator).map(i =>
        i < numerator1
          ? barModelColors[numeratorColors[0]]
          : i < numerator1 + numerator2
          ? barModelColors[numeratorColors[1]]
          : 'white'
      )
    ];

    const answerBoxes = isMixed ? `<frac wAns='' nAns='' dAns='' />` : `<frac nAns='' dAns='' />`;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeAdditionUseBarModelToHelp()}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <View style={{ gap: 16 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / barModelArrays.length + 1}
                customColorMap={array}
              />
            ))}
          </View>
        )}
        sentence={`<frac w= '${whole.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator.toLocaleString()}' /> ${ADD} <frac n='${numerator2.toLocaleString()}' d='${denominator.toLocaleString()}' /> = ${answerBoxes}`}
        customMarkSchemeAnswer={{
          answersToDisplay: isMixed
            ? [
                whole.toLocaleString(),
                (numerator1 + numerator2).toLocaleString(),
                denominator.toLocaleString()
              ]
            : [
                (whole * denominator + numerator1 + numerator2).toLocaleString(),
                denominator.toLocaleString()
              ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        testCorrect={answer =>
          isMixed
            ? compareFractions(
                [answer[0], answer[1], answer[2]],
                [whole, numerator1 + numerator2, denominator]
              )
            : compareFractions(
                [answer[0], answer[1]],
                [whole * denominator + numerator1 + numerator2, denominator]
              )
        }
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aNa2',
  description: 'aNa',
  keywords: [
    'Bar model',
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      whole: z.number().int().min(1).max(2),
      denominator: z.number().int().min(3).max(8),
      numerator1: z.number().int().min(1).max(6),
      numerator2: z.number().int().min(1).max(7),
      numeratorColors: z.array(barColorsNamesSchema).length(2),
      isMixed: z.boolean()
    })
    .refine(
      val => val.numerator1 + val.numerator2 < val.denominator,
      'numerator1 + numerator2 must be less than denominator.'
    ),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 2);
    const denominator = randomIntegerInclusive(3, 8);
    const numerator1 = randomIntegerInclusive(1, denominator - 2);
    const numerator2 = randomIntegerInclusive(1, denominator - numerator1 - 1);
    const color1 = getRandomFromArray(['Pink', 'Orange', 'Pink'] as const);
    const color2 = getRandomFromArray(['Blue', 'Green', 'Purple', 'Yellow'] as const);
    const numeratorColors = shuffle([color1, color2]);
    const isMixed = getRandomBoolean();

    return { whole, denominator, numerator1, numerator2, numeratorColors, isMixed };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { whole, denominator, numerator1, numerator2, numeratorColors, isMixed },
      translate
    } = props;

    const barModelArrays = [
      ...countRange(whole).map(() => filledArray(barColorNames[numeratorColors[0]], denominator)),
      countRange(denominator).map(i =>
        i < numerator1
          ? barColorNames[numeratorColors[0]]
          : i < numerator1 + numerator2
          ? barColorNames[numeratorColors[1]]
          : 'white'
      )
    ];

    const answerBoxes = isMixed ? `<frac wAns='' nAns='' dAns='' />` : `<frac nAns='' dAns='' />`;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeAdditionUseBarModelToHelp()}
        pdfDirection="column"
        questionHeight={1000}
        Content={({ dimens }) => (
          <View style={{ gap: 16 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / barModelArrays.length + 1}
                customColorMap={array}
              />
            ))}
          </View>
        )}
        sentence={`<frac w= '${whole.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator.toLocaleString()}' /> ${ADD} <frac n='${numerator2.toLocaleString()}' d='${denominator.toLocaleString()}' /> = ${answerBoxes}`}
        customMarkSchemeAnswer={{
          answersToDisplay: isMixed
            ? [
                whole.toLocaleString(),
                (numerator1 + numerator2).toLocaleString(),
                denominator.toLocaleString()
              ]
            : [
                (whole * denominator + numerator1 + numerator2).toLocaleString(),
                denominator.toLocaleString()
              ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        testCorrect={answer =>
          isMixed
            ? compareFractions(
                [answer[0], answer[1], answer[2]],
                [whole, numerator1 + numerator2, denominator]
              )
            : compareFractions(
                [answer[0], answer[1]],
                [whole * denominator + numerator1 + numerator2, denominator]
              )
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aNb',
  description: 'aNb',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      whole: z.number().int().min(1).max(9),
      denominator: z.number().int().min(3).max(8),
      numerator1: z.number().int().min(1).max(6),
      numerator2: z.number().int().min(1).max(7)
    })
    .refine(
      val => val.numerator1 + val.numerator2 < val.denominator,
      'numerator1 + numerator2 must be less than denominator.'
    ),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 2);
    const denominator = randomIntegerInclusive(3, 8);
    const numerator1 = randomIntegerInclusive(1, denominator - 2);
    const numerator2 = randomIntegerInclusive(1, denominator - numerator1 - 1);

    return { whole, denominator, numerator1, numerator2 };
  },
  Component: ({ question: { whole, denominator, numerator1, numerator2 }, translate }) => {
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={answer =>
          compareFractions(answer, [whole, numerator1 + numerator2, denominator])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            whole.toLocaleString(),
            (numerator1 + numerator2).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        sentence={`<frac w= '${whole.toLocaleString()}' n='${numerator1}' d='${denominator}' /> ${ADD} <frac n='${numerator2}' d='${denominator}' /> = <frac wAns='' nAns='' dAns='' />`}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aNc',
  description: 'aNc',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number'
  ],
  schema: z
    .object({
      whole: z.number().int().min(1).max(5),
      denominator: z.number().int().min(3).max(15),
      numerator1: z.number().int().min(1).max(14),
      numerator2: z.number().int().min(1).max(14),
      incorrectAnswers: z.array(z.number().int().min(1).max(14)).length(3)
    })
    .refine(
      val => val.numerator2 + val.numerator1 < val.denominator,
      'The sum of the numerators must be less than denominator.'
    ),
  simpleGenerator: () => {
    const { whole, incorrectAns1, denominator, numerator1, numerator2 } = rejectionSample(
      () => {
        const whole = randomIntegerInclusive(1, 5);
        const addOrSub = getRandomFromArray([ADD, SUB] as const);
        const incorrectAns1 = addOrSub === ADD || whole === 1 ? whole + 1 : whole - 1;
        const denominator = randomIntegerInclusive(5, 8, {
          constraint: x => ![incorrectAns1, whole].includes(x)
        });
        const numerator1 = randomIntegerInclusive(1, denominator - 1, {
          constraint: x => ![incorrectAns1, whole].includes(x)
        });
        // make them not equal to avoid duplicate options
        const numerator2 = randomIntegerInclusive(1, denominator - 1, {
          constraint: x => ![incorrectAns1, whole, numerator1].includes(x)
        });
        return { whole, incorrectAns1, denominator, numerator1, numerator2 };
      },
      val =>
        val.numerator2 + val.numerator1 < val.denominator &&
        arrayHasNoDuplicates([
          val.whole,
          val.incorrectAns1,
          val.denominator,
          val.numerator1 + val.numerator2
        ])
    );

    const incorrectAnswer2 = randomIntegerInclusive(1, 14, {
      constraint: x =>
        ![incorrectAns1, whole, numerator1 + numerator2, denominator].includes(x) &&
        !compareFractions([whole, x], [numerator1 + numerator2, denominator]) &&
        !compareFractions([x, whole], [numerator1 + numerator2, denominator]) &&
        !compareFractions([incorrectAns1, x], [numerator1 + numerator2, denominator]) &&
        !compareFractions([x, incorrectAns1], [numerator1 + numerator2, denominator])
    });

    const incorrectAnswer3 = randomIntegerInclusive(1, 14, {
      constraint: x =>
        ![incorrectAns1, whole, numerator1 + numerator2, incorrectAnswer2, denominator].includes(
          x
        ) &&
        !compareFractions([whole, x], [numerator1 + numerator2, denominator]) &&
        !compareFractions([x, whole], [numerator1 + numerator2, denominator]) &&
        !compareFractions([incorrectAnswer2, x], [numerator1 + numerator2, denominator]) &&
        !compareFractions([x, incorrectAnswer2], [numerator1 + numerator2, denominator]) &&
        !compareFractions([incorrectAns1, x], [numerator1 + numerator2, denominator]) &&
        !compareFractions([x, incorrectAns1], [numerator1 + numerator2, denominator])
    });

    const incorrectAnswers = [incorrectAns1, incorrectAnswer2, incorrectAnswer3];

    return {
      whole,
      denominator,
      numerator1,
      numerator2,
      incorrectAnswers
    };
  },
  Component: props => {
    const {
      question: { whole, denominator, numerator1, numerator2, incorrectAnswers },
      translate
    } = props;

    const answerOptions = [whole, numerator1 + numerator2, denominator, ...incorrectAnswers];
    const items = shuffle(
      answerOptions.map((x, i) => {
        return {
          component: <Text variant="WRN700">{x.toLocaleString()}</Text>,
          value: i
        };
      }),
      { random: seededRandom(props.question) }
    );
    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsCompleteAddition()}
        pdfTitle={translate.instructions.useCardsCompleteAddition()}
        items={items}
        actionPanelVariant="endWide"
        sentence={`<frac w='${whole.toLocaleString()}' n='${numerator1}' d='${denominator}' /> ${ADD} <frac n='${numerator2}' d='${denominator}' /> = <frac wAns='' nAns='' dAns='' />`}
        testCorrect={countRange(3)}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aNd',
  description: 'aNd',
  keywords: [
    'Bar model',
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      whole: z.number().int().min(1).max(3),
      denominator: z.number().int().min(4).max(8),
      numerator1: z.number().int().min(2).max(7),
      numerator2: z.number().int().min(2).max(7),
      numeratorColors: z.array(barModelColorsSchema).length(2),
      addOrSub: z.array(z.enum([ADD, SUB])).length(9),
      incorrectAnswerIndexes: z.array(z.number().int().min(0).max(8)).length(3)
    })
    .refine(
      val => val.numerator2 + val.numerator1 > val.denominator,
      'The sum of the numerators must be greater than denominator.'
    ),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 3);
    const denominator = randomIntegerInclusive(4, 8);
    const numerator1 = randomIntegerInclusive(2, denominator - 1);
    // make them not equal to avoid duplicate options
    const numerator2 = randomIntegerInclusive(2, denominator - 1, {
      constraint: x => x !== numerator1 && x + numerator1 > denominator
    });
    const addOrSub = countRange(9).map(() => getRandomFromArray([ADD, SUB] as const));
    const incorrectAnswerIndexes = randomUniqueIntegersInclusive(0, 8, 3);
    const numeratorColors = getRandomSubArrayFromArray(barModelColorsArray, 2);
    return {
      whole,
      denominator,
      numeratorColors,
      numerator1,
      numerator2,
      incorrectAnswerIndexes,
      addOrSub
    };
  },
  Component: props => {
    const {
      question: {
        whole,
        denominator,
        numeratorColors,
        numerator1,
        numerator2,
        incorrectAnswerIndexes,
        addOrSub
      },
      translate,
      displayMode
    } = props;

    const barModelArrays = [
      ...countRange(whole).map(() => filledArray(barModelColors[numeratorColors[0]], denominator)),
      countRange(denominator).map(i =>
        i < numerator1 ? barModelColors[numeratorColors[0]] : barModelColors[numeratorColors[1]]
      ),
      countRange(denominator).map(i =>
        i < (numerator1 + numerator2) % denominator ? barModelColors[numeratorColors[1]] : 'white'
      )
    ];

    const incorrectOptions = [
      [
        addOrSub[0] === ADD || whole === 1
          ? (whole + 1).toLocaleString()
          : (whole - 1).toLocaleString(),
        numerator1,
        numerator2,
        denominator,
        addOrSub[0] === ADD || whole === 1 ? (whole + 2).toLocaleString() : whole.toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        whole.toLocaleString(),
        numerator1,
        numerator2,
        addOrSub[1] === ADD ? denominator + 1 : denominator - 1,
        (whole + 1).toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        whole.toLocaleString(),
        addOrSub[2] === ADD ? numerator1 + 1 : numerator1 - 1,
        addOrSub[2] === ADD ? numerator2 + 1 : numerator2 - 1,
        denominator,
        (whole + 1).toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        whole.toLocaleString(),
        numerator2,
        numerator1,
        denominator,
        (whole + 1).toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        addOrSub[3] === ADD || whole === 1
          ? (whole + 1).toLocaleString()
          : (whole - 1).toLocaleString(),
        numerator1,
        numerator2,
        denominator + 1,
        addOrSub[3] === ADD || whole === 1 ? (whole + 2).toLocaleString() : whole.toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        addOrSub[4] === ADD || whole === 1
          ? (whole + 1).toLocaleString()
          : (whole - 1).toLocaleString(),
        addOrSub[4] === ADD ? numerator1 + 1 : numerator1 - 1,
        addOrSub[4] === ADD ? numerator2 + 1 : numerator2 - 1,
        denominator,
        addOrSub[4] === ADD || whole === 1 ? (whole + 2).toLocaleString() : whole.toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        whole.toLocaleString(),
        addOrSub[5] === ADD ? numerator1 + 1 : numerator1 - 1,
        addOrSub[5] === ADD ? numerator2 + 1 : numerator2 - 1,
        addOrSub[5] === ADD ? denominator + 1 : denominator - 1,
        (whole + 1).toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        addOrSub[6] === ADD || whole === 1
          ? (whole + 1).toLocaleString()
          : (whole - 1).toLocaleString(),
        numerator2,
        numerator1,
        denominator,
        addOrSub[6] === ADD || whole === 1 ? (whole + 2).toLocaleString() : whole.toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        whole.toLocaleString(),
        numerator2,
        numerator1,
        addOrSub[7] === ADD ? denominator + 1 : denominator - 1,
        (whole + 1).toLocaleString(),
        (numerator1 + numerator2) % denominator
      ],
      [
        whole.toLocaleString(),
        addOrSub[8] === ADD ? numerator2 + 1 : numerator2 - 1,
        addOrSub[8] === ADD ? numerator1 + 1 : numerator1 - 1,
        denominator,
        (whole + 1).toLocaleString(),
        (numerator1 + numerator2) % denominator
      ]
    ].filter((_x, i) => incorrectAnswerIndexes.includes(i));

    const answerOptions = shuffle(
      [
        {
          component: (
            <TextStructure
              sentence={`<frac w='${whole.toLocaleString()}' n='${numerator1}' d='${denominator}' /> ${ADD} <frac n='${numerator2}' d='${denominator}' /> = <frac w ='${(
                whole + 1
              ).toLocaleString()}' n='${
                (numerator1 + numerator2) % denominator
              }' d='${denominator}' />`}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 40, fontWeight: '700' }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 40,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: 'A'
        },
        ...incorrectOptions.map((x, i) => {
          return {
            component: (
              <TextStructure
                sentence={`<frac w ='${x[0]}' n='${x[1]}' d='${x[3]}' /> ${ADD} <frac n='${x[2]}' d='${x[3]}' /> = <frac w='${x[4]}' n='${x[2]}' d='${x[3]}' />`}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 40, fontWeight: '700' }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 40,
                  fontWeight: '700'
                }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value: `${i}`
          };
        })
      ],
      { random: seededRandom(props.question) }
    );
    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardToMatchCalcToRep()}
        pdfTitle={translate.instructions.useCardsToMatchCalcToRep()}
        items={answerOptions}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        actionPanelVariant="endWide"
        pdfLayout="itemsTop"
        sentencesStyle={{ alignItems: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ gap: 15 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / (barModelArrays.length + 1)}
                customColorMap={array}
              />
            ))}
          </View>
        )}
        sentence={`<ans/>`}
        testCorrect={['A']}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aNe',
  description: 'aNe',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      whole: z.number().int().min(1).max(9),
      denominator: z.number().int().min(3).max(10),
      numerator1: z.number().int().min(2).max(9),
      numerator2: z.number().int().min(2).max(9),
      addOrSub: z.enum([ADD, SUB]),
      incorrectAnswerIndexes: z.array(z.number().int().min(1).max(7)).length(3)
    })
    .refine(
      val => val.numerator1 + val.numerator2 > val.denominator,
      'numerator1 + numerator2 must be greater than denominator.'
    ),
  simpleGenerator: () => {
    const { whole, denominator, numerator1, numerator2, addOrSub, uniqueOptions, optionsArray } =
      rejectionSample(
        () => {
          const whole = randomIntegerInclusive(1, 9);
          const denominator = randomIntegerInclusive(3, 10);
          const numerator1 = randomIntegerInclusive(2, denominator - 1);
          const numerator2 = randomIntegerInclusive(
            Math.max(2, denominator - numerator1 + 1),
            denominator - 1
          );
          const addOrSub = getRandomFromArray([ADD, SUB] as const);
          const var9 =
            addOrSub === ADD || (numerator1 + numerator2) % denominator === 1
              ? ((numerator1 + numerator2) % denominator) + 1
              : ((numerator1 + numerator2) % denominator) - 1;
          const optionsArray = [
            fractionToDecimal(whole * denominator + numerator1 + numerator2, denominator),
            fractionToDecimal(whole * 2 * denominator + numerator1 + numerator2, 2 * denominator),
            fractionToDecimal((whole + 1) * denominator + numerator1 + numerator2, denominator),
            fractionToDecimal(
              (whole + 1) * 2 * denominator + numerator1 + numerator2,
              2 * denominator
            ),
            fractionToDecimal(
              whole * denominator + ((numerator1 + numerator2) % denominator),
              denominator
            ),
            fractionToDecimal(
              whole * 2 * denominator + ((numerator1 + numerator2) % denominator),
              2 * denominator
            ),
            fractionToDecimal(whole * denominator + var9, denominator),
            fractionToDecimal((whole + 1) * denominator + var9, denominator)
          ];
          const uniqueOptions = optionsArray.filter((item, i, ar) => ar.indexOf(item) === i);
          return {
            whole,
            denominator,
            numerator1,
            numerator2,
            addOrSub,
            uniqueOptions,
            optionsArray
          };
        },
        // get at least 4 unique options
        ({ uniqueOptions }) => uniqueOptions.length >= 4
      );
    const unqiueIndexes = uniqueOptions.map(u => optionsArray.indexOf(u));
    // remove the correct answer
    unqiueIndexes.shift();
    const incorrectAnswerIndexes = getRandomSubArrayFromArray(unqiueIndexes, 3);
    return { whole, denominator, numerator1, numerator2, addOrSub, incorrectAnswerIndexes };
  },
  Component: props => {
    const {
      question: { whole, denominator, numerator1, numerator2, addOrSub, incorrectAnswerIndexes },
      translate
    } = props;

    const var9 =
      addOrSub === ADD || (numerator1 + numerator2) % denominator === 1
        ? ((numerator1 + numerator2) % denominator) + 1
        : ((numerator1 + numerator2) % denominator) - 1;

    const incorrectOptions = [
      [whole + 1, (numerator1 + numerator2) % denominator, denominator * 2],
      [whole + 1, numerator1 + numerator2, denominator],
      [whole + 1, numerator1 + numerator2, denominator * 2],
      [whole, (numerator1 + numerator2) % denominator, denominator],
      [whole, (numerator1 + numerator2) % denominator, denominator * 2],
      [whole, var9, denominator],
      [whole, var9, denominator * 2]
    ].filter((_x, i) => incorrectAnswerIndexes.includes(i + 1));

    const answerOptions = shuffle(
      [
        {
          component: (
            <TextStructure
              sentence={`<frac w= '${(whole + 1).toLocaleString()}' n='${
                (numerator1 + numerator2) % denominator
              }' d='${denominator}' />`}
              textStyle={{ fontSize: 40, fontWeight: '700' }}
              fractionTextStyle={{ fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: fractionToDecimal(numerator1 + numerator2, denominator)
        },
        ...incorrectOptions.map(x => {
          return {
            component: (
              <TextStructure
                sentence={`<frac w='${x[0].toLocaleString()}' n='${x[1]}' d='${x[2]}' />`}
                textStyle={{ fontSize: 40, fontWeight: '700' }}
                fractionTextStyle={{ fontWeight: '700' }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value: fractionToDecimal(x[0] * x[2] + x[1], x[2])
          };
        })
      ],
      { random: seededRandom(props.question) }
    );
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectCorrectAnswerAddition()}
        pdfTitle={translate.instructions.circleCorrectAnswerAddition()}
        testCorrect={[fractionToDecimal(numerator1 + numerator2, denominator)]}
        numItems={4}
        Content={
          <TextStructure
            sentence={`<frac w='${whole.toLocaleString()}' n='${numerator1}' d='${denominator}' /> ${ADD} <frac n='${numerator2}' d='${denominator}' />`}
          />
        }
        renderItems={answerOptions}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aNf',
  description: 'aNf',
  keywords: [
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      whole: z.number().int().min(1).max(9),
      denominator: z.number().int().min(3).max(10),
      numerator1: z.number().int().min(2).max(9),
      numerator2: z.number().int().min(2).max(9)
    })
    .refine(
      val => val.numerator1 + val.numerator2 > val.denominator,
      'numerator1 + numerator2 must be greater than denominator.'
    ),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(1, 9);
    const denominator = randomIntegerInclusive(3, 10);
    const numerator1 = randomIntegerInclusive(2, denominator - 1);
    const numerator2 = randomIntegerInclusive(
      Math.max(2, denominator - numerator1 + 1),
      denominator - 1
    );

    return { whole, denominator, numerator1, numerator2 };
  },
  Component: ({ question: { whole, denominator, numerator1, numerator2 }, translate }) => {
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeAddition()}
        testCorrect={answer =>
          compareFractions(answer, [
            whole + 1,
            (numerator1 + numerator2) % denominator,
            denominator
          ])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (whole + 1).toLocaleString(),
            ((numerator1 + numerator2) % denominator).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={2}
        sentence={`<frac w= '${whole.toLocaleString()}' n='${numerator1}' d='${denominator}' /> ${ADD} <frac n='${numerator2}' d='${denominator}' /> = <frac wAns='' nAns='' dAns='' />`}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddFractionsAndMixedNumbers',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
