import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { DIV, MULT } from '../../../../constants';
import { useMemo } from 'react';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  objectSchema,
  getRandomObject,
  containerOfObject,
  objectAsWord,
  objectPrepositionWord,
  cheapObjectSchema,
  getRandomCheapObject,
  cheapObjectAsWord
} from '../../../../utils/objects';
import { arraysHaveSameContentsUnordered, range } from '../../../../utils/collections';
import { getCheapObjectImage, getObjectImage } from '../../../../utils/objectsImages';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import Text from '../../../../components/typography/Text';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'akZ',
  description: 'akZ',
  keywords: ['Times-table', '2', '4', '8', 'Pattern'],
  schema: z.object({
    numberPerGroup: z.number().int().min(3).max(6),
    object: objectSchema
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(3, 6);

    const object = getRandomObject();

    return { numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, object },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        sentences={[
          translate.answerSentences.numGroupsOperation(
            4,
            containerPlural,
            `${numberPerGroup} ${MULT} 4 = <ans/>`
          ),
          translate.answerSentences.numGroupsOperation(
            2,
            containerPlural,
            `${numberPerGroup} ${MULT} 2 = <ans/>`
          ),
          translate.answerSentences.numGroupsOperation(
            8,
            containerPlural,
            `${numberPerGroup} ${MULT} 8 = <ans/>`
          )
        ]}
        title={translate.instructions.eachGroupHasAnsObjects(
          containerSingular,
          numberPerGroup,
          objectPlural,
          preposition
        )}
        testCorrect={[
          [(numberPerGroup * 4).toString()],
          [(numberPerGroup * 2).toString()],
          [(numberPerGroup * 8).toString()]
        ]}
        Content={({ dimens }) => (
          <View style={dimens}>
            {getObjectImage(object, numberPerGroup, dimens.height, dimens.width)}
          </View>
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ak0',
  description: 'ak0',
  keywords: ['Times-table', '2', '4', '8', 'Pattern'],
  schema: z.object({
    number1: z.number().int().min(3).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 12);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: number1, right: 2, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: number1, right: 4, sign: 'multiply', answer: 'result' });

    const eqC = getBinOpEquation({ left: number1, right: 8, sign: 'multiply', answer: 'result' });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        {...props}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ak1',
  description: 'ak1',
  keywords: ['Times-table', '2', '4', '8', 'Equivalent'],
  schema: z.object({
    number1: z.number().int().min(2).max(12),
    number2: z.number().int().min(16).max(96).multipleOf(8),
    optionA: z.enum([
      `number1 ${MULT} 2 ${MULT} 2 ${MULT} 2`,
      `number1 ${MULT} 4 ${MULT} 2`,
      'Double number1, then double it again and again'
    ]),
    optionB: z.enum([`number1 ${MULT} 2 ${MULT} 2`, 'Double number1, then double it again']),
    optionC: z.enum([
      `number2 ${DIV} 2 ${DIV} 2 ${DIV} 2`,
      `number2 ${DIV} 2 ${DIV} 4`,
      'Halve number2, then halve it again and again'
    ]),
    optionD: z.enum([`number2 ${DIV} 2 ${DIV} 2`, 'Halve number2, then halve it again'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 12);

    const number2 = randomIntegerInclusiveStep(16, 96, 8);

    const optionA = getRandomFromArray([
      `number1 ${MULT} 2 ${MULT} 2 ${MULT} 2`,
      `number1 ${MULT} 4 ${MULT} 2`,
      'Double number1, then double it again and again'
    ] as const);

    const optionB = getRandomFromArray([
      `number1 ${MULT} 2 ${MULT} 2`,
      'Double number1, then double it again'
    ] as const);

    const optionC = getRandomFromArray([
      `number2 ${DIV} 2 ${DIV} 2 ${DIV} 2`,
      `number2 ${DIV} 2 ${DIV} 4`,
      'Halve number2, then halve it again and again'
    ] as const);

    const optionD = getRandomFromArray([
      `number2 ${DIV} 2 ${DIV} 2`,
      'Halve number2, then halve it again'
    ] as const);

    return { number1, number2, optionA, optionB, optionC, optionD };
  },
  Component: props => {
    const {
      question: { number1, number2, optionA, optionB, optionC, optionD },
      translate,
      displayMode
    } = props;

    const translatedTwo = (2).toLocaleString();
    const translatedFour = (4).toLocaleString();
    const translatedEight = (8).toLocaleString();

    const answerOptions = [
      {
        component: (
          <Text
            variant="WRN700"
            style={{
              textAlign: 'center',
              fontSize: displayMode === 'digital' ? 24 : 32,
              lineHeight: displayMode === 'digital' ? 36 : 48
            }}
          >
            {(() => {
              switch (optionA) {
                case `number1 ${MULT} 2 ${MULT} 2 ${MULT} 2`:
                  return `${number1.toLocaleString()} ${MULT} ${translatedTwo} ${MULT} ${translatedTwo} ${MULT} ${translatedTwo}`;
                case `number1 ${MULT} 4 ${MULT} 2`:
                  return `${number1.toLocaleString()} ${MULT} ${translatedFour} ${MULT} ${translatedTwo}`;
                case 'Double number1, then double it again and again':
                  return translate.answerSentences.doubleNumThenDoubleItAgainAndAgain(number1);
              }
            })()}
          </Text>
        ),
        value: 'A'
      },
      {
        component: (
          <Text
            variant="WRN700"
            style={{
              textAlign: 'center',
              fontSize: displayMode === 'digital' ? 24 : 32,
              lineHeight: displayMode === 'digital' ? 36 : 48
            }}
          >
            {(() => {
              switch (optionB) {
                case `number1 ${MULT} 2 ${MULT} 2`:
                  return `${number1.toLocaleString()} ${MULT} ${translatedTwo} ${MULT} ${translatedTwo}`;
                case 'Double number1, then double it again':
                  return translate.answerSentences.doubleNumThenDoubleItAgain(number1);
              }
            })()}
          </Text>
        ),
        value: 'B'
      },
      {
        component: (
          <Text
            variant="WRN700"
            style={{
              textAlign: 'center',
              fontSize: displayMode === 'digital' ? 24 : 32,
              lineHeight: displayMode === 'digital' ? 36 : 48
            }}
          >
            {(() => {
              switch (optionC) {
                case `number2 ${DIV} 2 ${DIV} 2 ${DIV} 2`:
                  return `${number2.toLocaleString()} ${DIV} ${translatedTwo} ${DIV} ${translatedTwo} ${DIV} ${translatedTwo}`;
                case `number2 ${DIV} 2 ${DIV} 4`:
                  return `${number2.toLocaleString()} ${DIV} ${translatedTwo} ${DIV} ${translatedFour}`;
                case 'Halve number2, then halve it again and again':
                  return translate.answerSentences.halveNumThenHalveItAgainAndAgain(number2);
              }
            })()}
          </Text>
        ),
        value: 'C'
      },
      {
        component: (
          <Text
            variant="WRN700"
            style={{
              textAlign: 'center',
              fontSize: displayMode === 'digital' ? 24 : 32,
              lineHeight: displayMode === 'digital' ? 36 : 48
            }}
          >
            {(() => {
              switch (optionD) {
                case `number2 ${DIV} 2 ${DIV} 2`:
                  return `${number2.toLocaleString()} ${DIV} ${translatedTwo} ${DIV} ${translatedTwo}`;
                case 'Halve number2, then halve it again':
                  return translate.answerSentences.halveNumThenHalveItAgain(number2);
              }
            })()}
          </Text>
        ),
        value: 'D'
      }
    ];

    const statements = shuffle(
      [
        { statement: `${number1.toLocaleString()} ${MULT} ${translatedEight}`, answer: 'A' },
        {
          statement: `${number1.toLocaleString()} ${MULT} ${translatedFour}`,
          answer: 'B'
        },
        {
          statement: `${number2.toLocaleString()} ${DIV} ${translatedEight}`,
          answer: 'C'
        },
        { statement: `${number2.toLocaleString()} ${DIV} ${translatedFour}`, answer: 'D' }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchEquivalentCalcs()}
        pdfTitle={translate.instructions.useTheCardsToMatchEquivalentCalcs()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        itemMaxLines={1}
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ak2',
  description: 'ak2',
  keywords: ['Times-table', '2', '4', '8', 'Given one find other', 'Object'],
  schema: z.object({
    price: numberEnum([3, 5, 6, 7, 9, 11]),
    object: cheapObjectSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const price = getRandomFromArray([3, 5, 6, 7, 9, 11] as const);

    const object = getRandomCheapObject();

    return { price, object };
  },
  Component: props => {
    const {
      question: { price, object },
      translate
    } = props;

    const objectPlural = cheapObjectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.whatIsTheCostOfNumObjects(4, objectPlural),
          translate.answerSentences.whatIsTheCostOfNumObjects(8, objectPlural)
        ]}
        title={translate.instructions.theCostOfNumObjectIsNum(2, objectPlural, price)}
        testCorrect={[[(price * 2).toString()], [(price * 4).toString()]]}
        Content={({ dimens }) => (
          <View style={[dimens, { flexDirection: 'row', justifyContent: 'space-evenly' }]}>
            {range(1, 2).map(index => (
              <View key={index}>{getCheapObjectImage(object, dimens.height)}</View>
            ))}
          </View>
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ak3',
  description: 'ak3',
  keywords: ['Times-table', '2', '4', '8', 'Equivalent'],
  schema: z.object({
    number1A: z.number().int().min(13).max(99),
    number1B: z.number().int().min(13).max(99),
    number1C: z.number().int().min(13).max(99),
    multiplicationSet: z.enum(['a', 'b'])
  }),
  simpleGenerator: () => {
    const number1A = randomIntegerInclusive(13, 99);
    const number1B = randomIntegerInclusive(13, 99);
    const number1C = randomIntegerInclusive(13, 99);
    const multiplicationSet = getRandomFromArray(['a', 'b'] as const);

    return { number1A, number1B, number1C, multiplicationSet };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, multiplicationSet },
      translate
    } = props;

    // Shuffle the sentences:
    const statements = useMemo(() => {
      const sentences =
        multiplicationSet === 'a'
          ? [
              {
                sentence: `${number1A.toLocaleString()} ${MULT} <ans/> ${MULT} <ans/> = ${number1A.toLocaleString()} ${MULT} 4`,
                answer: [2, 2]
              },
              {
                sentence: `${number1B.toLocaleString()} ${MULT} 8 = ${number1B.toLocaleString()} ${MULT} <ans/> ${MULT} <ans/>`,
                answer: [2, 4]
              },
              {
                sentence: `${number1C.toLocaleString()} ${MULT} 2 ${MULT} 2 ${MULT} 2 = ${number1C.toLocaleString()} ${MULT} <ans/>`,
                answer: [8]
              }
            ]
          : [
              {
                sentence: `${number1A.toLocaleString()} ${MULT} 2 ${MULT} 2 = ${number1A.toLocaleString()} ${MULT} <ans/>`,
                answer: [4]
              },
              {
                sentence: `${number1B.toLocaleString()} ${MULT} <ans/> = ${number1B.toLocaleString()} ${MULT} 2 ${MULT} 4`,
                answer: [8]
              },
              {
                sentence: `${number1C.toLocaleString()} ${MULT} <ans/> ${MULT} <ans/> ${MULT} 2 = ${number1C.toLocaleString()} ${MULT} 8`,
                answer: [2, 2]
              }
            ];

      return shuffle(sentences, { random: seededRandom(props.question) });
    }, [multiplicationSet, number1A, number1B, number1C, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragNumberCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useNumberCardsToCompleteCalculationEachNumberUsedMoreThanOnce()}
        items={[2, 4, 8]}
        sentences={statements.map(statement => statement.sentence)}
        testCorrect={userAnswer =>
          userAnswer.every((answer, index) =>
            arraysHaveSameContentsUnordered(answer, statements[index].answer)
          )
        }
        moveOrCopy="copy"
        actionPanelVariant="end"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: statements.map(statement => statement.answer),
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  },
  questionHeight: 1200
});

const Question5v2 = newQuestionContent({
  uid: 'ak32',
  description: 'ak3',
  keywords: ['Times-table', '2', '4', '8', 'Equivalent'],
  schema: z.object({
    number1A: z.number().int().min(13).max(49),
    number1B: z.number().int().min(13).max(49),
    number1C: z.number().int().min(13).max(49),
    multiplicationSet: z.enum(['a', 'b'])
  }),
  simpleGenerator: () => {
    const number1A = randomIntegerInclusive(13, 49);
    const number1B = randomIntegerInclusive(13, 49);
    const number1C = randomIntegerInclusive(13, 49);
    const multiplicationSet = getRandomFromArray(['a', 'b'] as const);

    return { number1A, number1B, number1C, multiplicationSet };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, multiplicationSet },
      translate
    } = props;

    // Shuffle the sentences:
    const statements = useMemo(() => {
      const sentences =
        multiplicationSet === 'a'
          ? [
              {
                sentence: `${number1A.toLocaleString()} ${MULT} <ans/> ${MULT} <ans/> = ${number1A.toLocaleString()} ${MULT} 4`,
                answer: [2, 2]
              },
              {
                sentence: `${number1B.toLocaleString()} ${MULT} 8 = ${number1B.toLocaleString()} ${MULT} <ans/> ${MULT} <ans/>`,
                answer: [2, 4]
              },
              {
                sentence: `${number1C.toLocaleString()} ${MULT} 2 ${MULT} 2 ${MULT} 2 = ${number1C.toLocaleString()} ${MULT} <ans/>`,
                answer: [8]
              }
            ]
          : [
              {
                sentence: `${number1A.toLocaleString()} ${MULT} 2 ${MULT} 2 = ${number1A.toLocaleString()} ${MULT} <ans/>`,
                answer: [4]
              },
              {
                sentence: `${number1B.toLocaleString()} ${MULT} <ans/> = ${number1B.toLocaleString()} ${MULT} 2 ${MULT} 4`,
                answer: [8]
              },
              {
                sentence: `${number1C.toLocaleString()} ${MULT} <ans/> ${MULT} <ans/> ${MULT} 2 = ${number1C.toLocaleString()} ${MULT} 8`,
                answer: [2, 2]
              }
            ];

      return shuffle(sentences, { random: seededRandom(props.question) });
    }, [multiplicationSet, number1A, number1B, number1C, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragNumberCardsToCompleteCalculations()}
        pdfTitle={translate.instructions.useNumberCardsToCompleteCalculationEachNumberUsedMoreThanOnce()}
        items={[2, 4, 8]}
        sentences={statements.map(statement => statement.sentence)}
        testCorrect={userAnswer =>
          userAnswer.every((answer, index) =>
            arraysHaveSameContentsUnordered(answer, statements[index].answer)
          )
        }
        moveOrCopy="copy"
        actionPanelVariant="end"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: statements.map(statement => statement.answer),
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  },
  questionHeight: 1200
});

const Question6 = newQuestionContent({
  uid: 'ak4',
  description: 'ak4',
  keywords: ['Divide', 'Times-table', 'Known facts'],
  schema: z
    .object({
      dividend: z.number().int().min(16).max(96),
      divisor: numberEnum([4, 8])
    })
    .refine(val => val.dividend % val.divisor === 0, 'dividend must be a multiple of divisor.'),
  simpleGenerator: () => {
    const divisor = getRandomFromArray([4, 8] as const);

    // Dividend must be a multiple of divisor to avoid decimal answers.
    const dividend = randomIntegerInclusiveStep(16, 96, divisor);

    return { dividend, divisor };
  },
  Component: props => {
    const {
      question: { dividend, divisor },
      translate
    } = props;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.useTheFactThatXToCompleteCalc(
          `${dividend.toLocaleString()} ${DIV} ${(2).toLocaleString()} = ${(
            dividend / 2
          ).toLocaleString()}`
        )}
        sentence={`${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`}
        testCorrect={[(dividend / divisor).toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The24And8TimesTables',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
