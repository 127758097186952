import { View } from 'react-native';
import { Svg, Path } from 'react-native-svg';
import { colors } from '../../../theme/colors';
import Text from '../../typography/Text';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { countRange, sumNumberArray } from '../../../utils/collections';
import { AssetSvg } from '../../../assets/svg';

type Props = {
  /**
   * The angles to split the circle into and an optional label
   */
  angles: { angle: number; innerLabel?: string; fill?: string }[];
  /**
   * Viewbox is relative to the radius of the pie chart
   */
  radius: number;
  /**
   * Whether to show the angles in a circle. Defaults to false i.e. angles around a point
   */
  showOuterCircle?: boolean;
  /**
   * optional number array to only show the inner arc on certain angle slices.
   * If nothing set they will all be present
   */
  innerArcSegments?: number[];
  /**
   * optional number array array to specify which angles should be same size.
   * Example: [[0,2], [1,3]] would make angle index 0 and 2 the same size and angle index 1 and 3 the same size
   * If undefined, all arcs will be the same height.
   */
  arcSizeGroups?: number[][];
  startAngle?: number;
  /**
   * Boolean to determine whether to display a 180° protractor underneath the angles.
   * Optional prop, defaults to false.
   * NOTE: This currently only works nicely with multiple angles totalling between 91° and 180°.
   */
  showProtractor?: boolean;
  /**
   * Scale by which to move the labels away from the default position.
   * Numbers greater than 1 will move labels further away from the midpoint,
   * numbers between 0 and 1 will move labels closer to the midpoint.
   * Optional prop, defaults to 1, i.e. default label position.
   */
  labelPositionTransformScale?: number;
};

const AnglesAroundAPoint = ({
  angles,
  radius,
  showOuterCircle = false,
  innerArcSegments: innerArcSegmentsProp,
  arcSizeGroups,
  startAngle: startAngleProp,
  showProtractor = false,
  labelPositionTransformScale = 1
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isHalf = sumNumberArray(angles.map(val => val.angle)) <= 180;
  const isQuarter = sumNumberArray(angles.map(val => val.angle)) <= 90;

  const innerArcSegments = innerArcSegmentsProp ?? countRange(angles.length);

  // Calculate viewbox dimensions
  const strokeWidth = 3;
  const viewboxWidth = isQuarter ? radius + 10 : 2 * radius + 10;
  const viewboxHeight = isHalf ? radius + 10 : 2 * radius + 10;

  const centerX = isQuarter ? 5 : radius + 5;
  const centerY = radius + 5;

  function getPoints(radius: number, angleInDeg: number, radialFactor: number = 1) {
    const radians = ((angleInDeg - 90) * Math.PI) / 180;
    return {
      x: centerX + radialFactor * radius * Math.cos(radians),
      y: centerY + radialFactor * radius * Math.sin(radians)
    };
  }

  const renderAngle = (): JSX.Element[] => {
    const lines = [];
    let startAngle = startAngleProp ?? (isHalf && !isQuarter ? -90 : 0);
    // Loop to calculate and append segment paths to pieChart array
    for (let idx = 0; idx < angles.length; idx++) {
      // Angle of the slice
      const angle = angles[idx].angle;
      const endAngle = startAngle + angle;

      // Calculate start and end coordinates of the arc
      const { x: startX, y: startY } = getPoints(radius, startAngle);
      const { x: endX, y: endY } = getPoints(radius, endAngle);

      // Create SVG path for the angle
      const section = showOuterCircle
        ? `M${centerX},${centerY} L${startX},${startY} A${radius},${radius} 0 ${
            angle > 180 ? 1 : 0
          },1 ${endX},${endY} Z`
        : `M${startX},${startY} L${centerX},${centerY} L${endX},${endY} `;

      // Update startAngle for the next angle
      startAngle = endAngle;

      lines.push(
        <Path
          key={`slice-${idx}`}
          d={section}
          stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
          strokeWidth={strokeWidth}
          fill={angles[idx].fill ?? 'none'}
          strokeLinejoin="round"
        />
      );
    }

    return lines;
  };

  const renderInnerAngle = (): JSX.Element[] => {
    const lines: JSX.Element[] = [];
    let startAngle = startAngleProp ?? (isHalf && !isQuarter ? -90 : 0);
    const arcFactors = countRange(arcSizeGroups?.length ?? 0).map(val =>
      Math.max(0.4 - val * 0.05, 0.1)
    );
    for (let idx = 0; idx < angles.length; idx++) {
      // Angle of the slice
      const angle = angles[idx].angle;
      const arcIndex = arcSizeGroups
        ? arcSizeGroups.findIndex(arrays => arrays.some(val => val === idx))
        : 0;
      // this will only work if we have less than 9 angles. For now this is fine
      const factor = arcFactors.length === 0 ? 0.3 : arcFactors[arcIndex];
      const innerRadius = angle === 90 ? radius * 0.25 : radius * factor;

      const endAngle = startAngle + angle;

      if (innerArcSegments.includes(idx)) {
        // Calculate start and end coordinates of the arc
        const { x: startX, y: startY } = getPoints(innerRadius, startAngle);
        const { x: endX, y: endY } = getPoints(innerRadius, endAngle);
        // Only for when we have 90 degree angle - get the mid angle
        const { x: midX, y: midY } = getPoints(innerRadius * Math.sqrt(2), startAngle + angle / 2);

        // Create SVG path for the angle
        const section =
          angle === 90
            ? `M${startX},${startY} L${midX},${midY} L${endX},${endY}`
            : `M${startX},${startY} A${innerRadius},${innerRadius} 0 ${
                angle > 180 ? 1 : 0
              },1 ${endX},${endY}`;

        lines.push(
          <Path
            key={`slice-${idx}`}
            d={section}
            stroke={colors.red}
            strokeWidth={strokeWidth}
            fill="none"
          />
        );
      }
      // Update startAngle for the next angle
      startAngle = endAngle;
    }

    return lines;
  };

  const renderInSegmentLabels = () => {
    let startAngle = startAngleProp ?? (isHalf && !isQuarter ? -90 : 0);
    const labelFontSize = displayMode === 'digital' ? 28 : 42;
    const labelLineHeight = displayMode === 'digital' ? 30 : 48;

    return angles.map((val, idx) => {
      // Angle of the slice
      const angle = val.angle;
      const midAngle = startAngle + angle / 2;
      const arcFactors = countRange(arcSizeGroups?.length ?? 0).map(val =>
        Math.max(0.65 - val * 0.05, 0.35)
      );
      const arcIndex = arcSizeGroups
        ? arcSizeGroups.findIndex(arrays => arrays.some(val => val === idx))
        : 0;
      // this will only work if we have less than 9 angles. For now this is fine
      const factor = arcFactors.length === 0 ? 0.65 : arcFactors[arcIndex];
      // get mid points
      const { x: xLabel, y: yLabel } = getPoints(
        radius * labelPositionTransformScale,
        midAngle,
        factor
      );
      // Update startAngle for the next slice
      const endAngle = startAngle + angle;
      startAngle = endAngle;

      const sentence = val.innerLabel ?? '';

      return (
        <View
          key={`perc-${idx}`}
          style={{
            position: 'absolute',
            width: viewboxWidth,
            height: viewboxHeight,
            left: xLabel - viewboxWidth / 2,
            top: yLabel - viewboxHeight / 2,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Text
            style={{
              fontSize: labelFontSize,
              lineHeight: labelLineHeight,
              textAlign: 'center'
            }}
          >
            {sentence}
          </Text>
        </View>
      );
    });
  };

  return (
    <View>
      {showProtractor && (
        <AssetSvg
          name={'Protractor180'}
          width={radius * 1.6}
          height={radius * 1.6}
          style={{
            position: 'absolute',
            zIndex: -2,
            bottom: displayMode === 'digital' ? radius * -0.42 : radius * -0.43,
            left: displayMode === 'digital' ? radius * 0.215 : radius * 0.205
          }}
        />
      )}
      <Svg width={viewboxWidth} height={viewboxHeight}>
        {renderAngle()}
        {renderInnerAngle()}
      </Svg>
      {renderInSegmentLabels()}
    </View>
  );
};

export default AnglesAroundAPoint;
