import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  arrayHasNoDuplicates,
  NonEmptyArray,
  sortNumberArray
} from '../../../../utils/collections';
import { Digit, roundToTheNearest, ScientificNotation } from '../../../../utils/math';
import {
  logUniformSample,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { z } from 'zod';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import QF6DragMatchStatements, {
  toNumberWithString
} from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ad2',
  description: 'ad2',
  keywords: ['Place value', 'Compare', '100,000', 'Chart'],
  schema: z.object({
    numbers: z.number().int().min(100).max(88888).array().length(2).refine(arrayHasNoDuplicates),
    lookingForLarger: z.boolean()
  }),
  simpleGenerator: () => {
    const numCounters = (x: number) =>
      (ScientificNotation.fromNumber(x).digits as number[]).reduce((sum, y) => sum + y, 0);

    // The larger number should have <= half the counters of the smaller number. Also, we want
    // the numbers to be roughly as likely to be 3, 4 or 5 digits, so we will use logUniformSample.

    // By far the easiest way to write this is with rejection sampling.
    // I experimentally measured a ~3% success rate, which is quite low, but the sample code runs fast, so we
    // decided this was acceptable.
    const [a, b] = rejectionSample(
      () =>
        randomUniqueIntegersInclusive(100, 88888, 2, {
          sample: logUniformSample,
          constraint: x => {
            return ScientificNotation.fromNumber(x).digits.every(i => i < 9);
          }
        }),
      ([a, b]) => {
        const [l, s] = a > b ? [a, b] : [b, a];
        return numCounters(l) <= numCounters(s) / 2;
      }
    );

    return {
      numbers: shuffle([a, b]),
      lookingForLarger: getRandomFromArray([true, false] as const)
    };
  },
  Component: ({ question: { numbers, lookingForLarger }, translate, displayMode }) => {
    const lookingForLargerTitle = lookingForLarger
      ? translate.instructions.selectNumberThatIsGreater()
      : translate.instructions.selectNumberThatIsSmaller();

    const lookingForLargerTitlePdf = lookingForLarger
      ? translate.instructions.circleNumberThatIsGreater()
      : translate.instructions.circleNumberThatIsSmaller();

    return (
      <QF11SelectImagesUpTo4
        title={lookingForLargerTitle}
        pdfTitle={lookingForLargerTitlePdf}
        testCorrect={[lookingForLarger ? Math.max(...numbers) : Math.min(...numbers)]}
        numItems={numbers.length as 2 | 3 | 4}
        renderItems={({ dimens }) => {
          const height = numbers.length > 2 ? dimens.height - 30 : dimens.height / 2;
          return numbers.map(number => ({
            value: number,
            component: (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(number)}
                columnsToShow={[4, 3, 2, 1, 0]}
                dimens={{ height, width: dimens.width - 30 }}
                counterVariant="greyCounter"
                headerVariant={'shortName'}
                counterSize={displayMode === 'digital' ? undefined : 70}
              />
            )
          }));
        }}
        questionHeight={1200}
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'ad3',
  description: 'ad3',
  keywords: ['Place value', 'Compare', '100,000'],
  schema: z.object({
    comparitor: z.number().int().multipleOf(1000).min(2000).max(80000),
    numbers: z.number().int().min(1).max(99999).array().length(6).refine(arrayHasNoDuplicates),
    lookingForLarger: z.boolean()
  }),
  simpleGenerator: () => {
    const comparitor = randomIntegerInclusiveStep(2000, 80000, 1000);

    const pickedNumbers: number[] = [];
    const pickNumber = (sample: () => number) =>
      pickedNumbers.push(rejectionSample(sample, x => !pickedNumbers.includes(x)));

    // 3 numbers < target - includes one number within 1000
    pickNumber(() => randomIntegerInclusive(comparitor - 1000, comparitor - 1));
    pickNumber(() => randomIntegerInclusive(1, comparitor - 1));
    pickNumber(() => randomIntegerInclusive(1, comparitor - 1));

    // 3 numbers > target - includes one multiple of 5000 and one number within 1000
    pickNumber(() =>
      randomIntegerInclusiveStep(roundToTheNearest(comparitor + 1, 5000, 'up'), 95000, 5000)
    );
    pickNumber(() => randomIntegerInclusive(comparitor + 1, comparitor + 1000));
    pickNumber(() => randomIntegerInclusive(comparitor + 1, 99999));

    return {
      comparitor,
      numbers: shuffle(pickedNumbers),
      lookingForLarger: getRandomFromArray([true, false] as const)
    };
  },
  Component: ({ question: { comparitor, numbers, lookingForLarger }, translate }) => (
    <QF10SelectNumbers
      title={
        lookingForLarger
          ? translate.instructions.selectAllNumbersGreaterThanNum(comparitor.toLocaleString())
          : translate.instructions.selectAllNumbersLessThanNum(comparitor.toLocaleString())
      }
      pdfTitle={
        lookingForLarger
          ? translate.instructions.circleAllNumbersGreaterThanNum(comparitor.toLocaleString())
          : translate.instructions.circleAllNumbersLessThanNum(comparitor.toLocaleString())
      }
      testCorrect={numbers.filter(it => (lookingForLarger ? it > comparitor : it < comparitor))}
      items={numbers.map(number => ({
        value: number,
        component: number.toLocaleString()
      }))}
      multiSelect
      questionHeight={800}
    />
  ),

  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'ad4',
  description: 'ad4',
  keywords: ['Place value', 'Order', '100,000'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(99999)
      .array()
      .length(4)
      .refine(numbers => arrayHasNoDuplicates(numbers), 'Numbers must not have duplicates'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const numbers = shuffle([
      randomIntegerInclusive(10, 99),
      randomIntegerInclusive(100, 999),
      randomIntegerInclusive(1000, 9999),
      randomIntegerInclusive(10000, 99999)
    ]);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);
    return { numbers, ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsStartingGreatest' : 'dragCardsStartingSmallest';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsStartingGreatest' : 'useCardsStartingSmallest';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ad5',
  description: 'ad5',
  keywords: ['Place value', 'Order', '100,000'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1000)
      .max(99999)
      .array()
      .length(4)
      .refine(numbers => arrayHasNoDuplicates(numbers), 'Numbers must not have duplicates'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1000, 99999, 4);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);
    return { numbers, ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsStartingGreatest' : 'dragCardsStartingSmallest';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsStartingGreatest' : 'useCardsStartingSmallest';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ad6',
  description: 'ad6',
  keywords: ['Place value', 'Compare', '100,000', 'Inequality'],
  schema: z.object({
    sentences: z
      .tuple([z.number().int().min(1000).max(99999), z.number().int().min(1000).max(99999)])
      .refine(([lhs, rhs]) => lhs !== rhs, 'numbers in a sentence must not be equal')
      .array()
      .length(4)
  }),
  simpleGenerator: () => {
    // For the first pair, their tenThousands column is the same.
    const aStart = randomIntegerInclusive(1, 9) * 10000;
    const aRest = randomUniqueIntegersInclusive(0, 9999, 2);
    const a0 = aStart + aRest[0];
    const a1 = aStart + aRest[1];

    // For the second pair, the larger is a multiple of 10000.
    const b0 = randomIntegerInclusive(1, 9) * 10000;
    const b1 = randomIntegerInclusive(1000, b0 - 1);

    // For the third pair, they only differ in ones.
    const cStart = randomIntegerInclusive(1000, 9999) * 10;
    const cRest = randomUniqueIntegersInclusive(0, 9, 2);
    const c0 = cStart + cRest[0];
    const c1 = cStart + cRest[1];

    // For the fourth pair, one number is <10000, but its first digit is larger than the other number's first digit.
    const dFirstDigit = randomIntegerInclusive(2, 9);
    const d0 = dFirstDigit * 1000 + randomIntegerInclusive(0, 999);
    const d1 = randomIntegerInclusive(10000, dFirstDigit * 10000 - 1);

    // Shuffle everything and return
    return {
      sentences: shuffle([
        shuffle([a0, a1]) as [number, number],
        shuffle([b0, b1]) as [number, number],
        shuffle([c0, c1]) as [number, number],
        shuffle([d0, d1]) as [number, number]
      ])
    };
  },
  Component: ({ question: { sentences: sentencesProp }, displayMode, translate }) => {
    const items = ['>', '<'] as const;

    // Numbers in sentences might have been given as just numbers, or numbers with strings to display. Convert them all
    // to the latter format.
    const sentences = sentencesProp.map(
      ([lhs, rhs]) => [toNumberWithString(lhs), toNumberWithString(rhs)] as const
    );

    return (
      <QF6DragMatchStatements
        moveOrCopy="copy"
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        useRedLinesOnMarkScheme={false}
        title={translate.instructions.dragCardsMakeStatementsCorrect()}
        pdfTitle={translate.instructions.useGreaterThanAndLessThanToCompleteStatements()}
        items={items.map(item => {
          return {
            value: item,
            component: item
          };
        })}
        statementStyle={{ justifyContent: 'center' }}
        statements={sentences.map(([lhs, rhs]) => {
          return {
            correctAnswer: lhs.value > rhs.value ? '>' : lhs.value < rhs.value ? '<' : '=',
            lhsComponent: (
              <Text
                variant="WRN400"
                style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'right' }}
              >
                {lhs.string}
              </Text>
            ),
            rhsComponent: (
              <Text
                variant="WRN400"
                style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'left' }}
              >
                {rhs.string}
              </Text>
            )
          };
        })}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'ad7',
  description: 'ad7',
  keywords: ['Place value', 'Order', '100,000'],
  schema: z.object({
    numbers: z
      .number()
      .int()
      .min(1)
      .max(99999)
      .array()
      .length(4)
      .refine(numbers => arrayHasNoDuplicates(numbers), 'Numbers must not have duplicates'),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    // Pick 3 non-zero distinct digits
    const digits = randomUniqueIntegersInclusive(1, 9, 3) as NonEmptyArray<Digit>;

    const pickedNumbers: number[] = [];

    // Make 4 distinct numbers out of these digits, with a repeated digit, with 0.
    const pickNumber = () =>
      pickedNumbers.push(
        rejectionSample(
          () => {
            // Pick a digit to repeat.
            const repeated = getRandomFromArray(digits);
            const numberDigits = shuffle([...digits, repeated, 0 as Digit]);
            return ScientificNotation.create(numberDigits).toNumber();
          },
          x => !pickedNumbers.includes(x)
        )
      );
    pickNumber();
    pickNumber();
    pickNumber();
    pickNumber();

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { numbers: pickedNumbers, ordering };
  },
  Component: ({ question: { numbers, ordering }, translate }) => {
    const correctOrder = sortNumberArray(numbers, ordering);

    const instruction =
      ordering === 'descending' ? 'dragCardsStartingGreatest' : 'dragCardsStartingSmallest';

    const instructionPdf =
      ordering === 'descending' ? 'useCardsStartingGreatest' : 'useCardsStartingSmallest';

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions[instruction]()}
        pdfTitle={translate.instructions[instructionPdf]()}
        testCorrect={correctOrder}
        items={numbers}
        itemVariant="shortRectangle"
        pdfItemVariant="tallRectangle"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderNumbersTo100000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
