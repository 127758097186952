import { Circle, Line, Path, Svg, Text } from 'react-native-svg';
import {
  describeArc,
  describeArrowHeadTranslation,
  describeSquareArc,
  polarToCartesian
} from '../../../utils/angles';
import { colors } from '../../../theme/colors';
import { equal, mod } from 'mathjs';
import { range } from '../../../utils/collections';
import { Dimens } from '../../../theme/scaling';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { AssetSvg } from '../../../assets/svg';

const NATURAL_ARC_RADIUS = 30;
const NATURAL_LINE_LENGTH = 100;

export type AngleFromLinesProps = {
  /**
   * Angles of the two lines, clockwise from north. The angle is drawn clockwise from the first number to the second.
   * If the two numbers are a multiple of 360 apart, then draw a full circle (unless they are equal).
   */
  degrees: [number, number];
  /**
   * The space available to the component to render in. The component will scale to fit in this rectangle.
   * Provide this or `lineLength` but not both.
   */
  dimens?: Dimens;
  /**
   * The length of a line in the representation. The component will use up as much space as needed to show the angle
   * with this line length.
   * Provide this or `dimens` but not both.
   */
  lineLength?: number;
  /** Defaults to lineLength / 50 */
  strokeWidth?: number;
  /** whether to show right angle as square arc. Default is true */
  includeRightAngle?: boolean;
  showArrowHead?: boolean;
  /** Shows a grey 90 and 45 degree angle behind as a guide. Default is false*/
  showGuide?: boolean;
  strokeColor?: string;
  /** whether to show a protractor behind. Default is false */
  protractor?: boolean;
  angleLabel?: {
    label: string;
    fontSize: number;
    xSpacing: number;
    ySpacing: number;
  };
};

/**
 * Representation of two lines forming an angle.
 *
 * If the angle is 90 degrees, we use a square arc instead of a round one.
 */
export default function AngleFromLines({
  degrees,
  dimens,
  lineLength: lineLengthProp,
  strokeWidth,
  includeRightAngle = true,
  showArrowHead = false,
  strokeColor,
  protractor = false,
  showGuide = false,
  angleLabel
}: AngleFromLinesProps) {
  let [theta1, theta2] = degrees;
  const displayMode = useContext(DisplayMode);

  // Make sure the angles are between 0 (inclusive) and 360 (exclusive)
  theta1 = theta1 > 360 ? mod(theta1, 360) : theta1;
  theta2 = theta2 > 360 ? mod(theta2, 360) : theta2;
  const fullTurn = Math.abs(theta1 - theta2) === 360;

  // First we need to figure out the scaling.
  // Build up a picture of what we're making with a line length of 100 - add a few key points
  const points: { x: number; y: number }[] = protractor
    ? [
        { x: 0, y: 0 },
        polarToCartesian(0, 0, NATURAL_LINE_LENGTH, -180),
        polarToCartesian(0, 0, NATURAL_LINE_LENGTH, -90),
        polarToCartesian(0, 0, NATURAL_LINE_LENGTH, 0)
      ]
    : [
        { x: 0, y: 0 },
        polarToCartesian(0, 0, NATURAL_LINE_LENGTH, theta1 - 90),
        polarToCartesian(0, 0, NATURAL_LINE_LENGTH, theta2 - 90)
      ];

  // Add in some points along the arc too, so that we make room for the arc
  for (const angle of fullTurn
    ? range(0, 270, 90)
    : range(theta1, mod(theta2 - theta1, 360) + theta1, 5)) {
    points.push(polarToCartesian(0, 0, NATURAL_ARC_RADIUS, angle - 90));
  }

  // Now we can calculate how big the image would be in its natural size.
  const naturalWidth =
    Math.max(...points.map(it => it.x)) - Math.min(...points.map(it => it.x)) + 10;
  const naturalHeight =
    Math.max(...points.map(it => it.y)) - Math.min(...points.map(it => it.y)) + 10;
  const naturalCenter = {
    x: -Math.min(...points.map(it => it.x)) + 5,
    y: -Math.min(...points.map(it => it.y)) + 5
  };

  // Next, we need to calculate how much to scale up or down this picture to actually fit in the constraints given
  let scaleFactor: number;
  if (dimens !== undefined) {
    scaleFactor = Math.min(dimens.width / naturalWidth, dimens.height / naturalHeight);
  } else if (lineLengthProp !== undefined) {
    {
      scaleFactor = lineLengthProp / NATURAL_LINE_LENGTH;
    }
  } else {
    throw new Error('Neither dimens nor lineLength props given.');
  }

  const width = naturalWidth * scaleFactor;
  const height = naturalHeight * scaleFactor;
  const lineLength = NATURAL_LINE_LENGTH * scaleFactor;

  const center = protractor
    ? {
        x: naturalCenter.x * scaleFactor,
        y: (naturalCenter.y - 9.4) * scaleFactor + (height - naturalCenter.y * scaleFactor) * 0.5
      }
    : { x: naturalCenter.x * scaleFactor, y: naturalCenter.y * scaleFactor };
  strokeWidth = strokeWidth ?? lineLength / 50;

  const line1End = polarToCartesian(center.x, center.y, lineLength, theta1 - 90);
  const line2End = polarToCartesian(center.x, center.y, lineLength, theta2 - 90);
  const arrowHeadEndingSize = 8 * scaleFactor;

  const line0End = polarToCartesian(center.x, center.y, lineLength, -90);
  const line45End = polarToCartesian(center.x, center.y, lineLength, -45);
  const line90End = polarToCartesian(center.x, center.y, lineLength, 0);

  let middleAngle = (theta1 + theta2) / 2;
  if (middleAngle > 360) {
    middleAngle -= 360;
  }

  const textOffset = NATURAL_ARC_RADIUS * scaleFactor;
  const textPosition = polarToCartesian(center.x, center.y, textOffset, middleAngle - 90);

  // Now finally render the SVG at that scale factor
  return (
    <>
      {protractor && (
        <AssetSvg
          name={'Protractor180'}
          width={lineLength * 1.9}
          height={lineLength * 1.9}
          style={{ position: 'absolute' }}
        />
      )}
      <Svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{ flexShrink: 0 }}
      >
        <Circle cx={center.x} cy={center.y} r={strokeWidth} fill="black" />
        {fullTurn ? (
          <Circle
            cx={center.x}
            cy={center.y}
            r={NATURAL_ARC_RADIUS * scaleFactor}
            strokeWidth={strokeWidth * 0.8}
            stroke={displayMode === 'digital' ? strokeColor ?? colors.prussianBlue : colors.black}
            fill="none"
          />
        ) : (
          <>
            <Path
              d={
                (equal(theta2 - theta1, 90) || equal(theta2 - theta1, -270)) && includeRightAngle
                  ? describeSquareArc(center.x, center.y, NATURAL_ARC_RADIUS * scaleFactor, theta1)
                  : describeArc(
                      center.x,
                      center.y,
                      NATURAL_ARC_RADIUS * scaleFactor,
                      theta1,
                      theta2
                    )
              }
              stroke={displayMode === 'digital' ? strokeColor ?? colors.prussianBlue : colors.black}
              strokeWidth={strokeWidth * 0.8}
              fill="none"
            />
            {angleLabel && (
              <Text
                x={textPosition.x - angleLabel.xSpacing}
                y={textPosition.y - angleLabel.ySpacing}
                fontFamily="White_Rose_Noto-Bold"
                fontSize={angleLabel.fontSize}
                fill={colors.prussianBlue}
              >
                {angleLabel.label}
              </Text>
            )}
          </>
        )}

        {showArrowHead && (
          <Path
            d={`M ${(arrowHeadEndingSize / 5) * 2} 0
                      L ${arrowHeadEndingSize} ${arrowHeadEndingSize / 2}
                      L ${(arrowHeadEndingSize / 5) * 2} ${arrowHeadEndingSize}`}
            stroke={displayMode === 'digital' ? colors.prussianBlue : colors.black}
            fill={displayMode === 'digital' ? strokeColor ?? colors.burntSienna : colors.black}
            transform={`${describeArrowHeadTranslation(
              center.x,
              center.y,
              NATURAL_ARC_RADIUS * scaleFactor,
              theta1,
              theta2,
              arrowHeadEndingSize,
              theta1 < theta2
            )}`}
          ></Path>
        )}
        {showGuide && (
          <>
            <Path
              d={describeSquareArc(center.x, center.y, 15 * scaleFactor, 0)}
              stroke={colors.greys300}
              strokeWidth={strokeWidth * 0.6}
              fill="none"
            />
            <Line
              x1={center.x}
              y1={center.y}
              x2={line90End.x}
              y2={line90End.y}
              stroke={colors.greys300}
              strokeWidth={strokeWidth * 0.8}
              strokeLinecap="round"
            />
            <Line
              x1={center.x}
              y1={center.y}
              x2={line45End.x}
              y2={line45End.y}
              stroke={colors.greys300}
              strokeWidth={strokeWidth * 0.8}
              strokeLinecap="round"
              strokeDasharray="10"
            />
            <Line
              x1={center.x}
              y1={center.y}
              x2={line0End.x}
              y2={line0End.y}
              stroke={colors.greys300}
              strokeWidth={strokeWidth * 0.8}
              strokeLinecap="round"
            />
          </>
        )}

        <Line
          x1={center.x}
          y1={center.y}
          x2={line1End.x}
          y2={line1End.y}
          stroke={displayMode === 'digital' ? colors.prussianBlue : 'black'}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        <Line
          x1={center.x}
          y1={center.y}
          x2={line2End.x}
          y2={line2End.y}
          stroke={displayMode === 'digital' ? colors.prussianBlue : 'black'}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
      </Svg>
    </>
  );
}
