import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { randomIntegerInclusive, randomIntegerInclusiveStep } from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { MULT } from '../../../../constants';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF27aLongMultiplicationDeprecated from '../../../../components/question/questionFormats/QF27aLongMultiplicationDeprecated';
import { getMarkSchemeAnswer } from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF27aLongMultiplication from '../../../../components/question/questionFormats/QF27aLongMultiplication';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aOI',
  description: 'aOI',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    multiplicand: z.number().int().min(2).max(9),
    multiplier: z.number().int().min(2).max(9)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const multiplicand = randomIntegerInclusive(2, 9);
    const multiplier = randomIntegerInclusive(2, 9);

    return { multiplicand, multiplier };
  },
  Component: ({ question: { multiplicand, multiplier }, translate }) => {
    const multiplicand2 = multiplicand * 10;
    const multiplier2 = multiplier * 10;

    const sentences = [
      {
        sentence: `${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
        answers: [(multiplicand * multiplier).toString()]
      },
      {
        sentence: `${multiplicand.toLocaleString()} ${MULT} ${multiplier2.toLocaleString()} = <ans/>`,
        answers: [(multiplicand * multiplier2).toString()]
      },
      {
        sentence: `${multiplicand2.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
        answers: [(multiplicand2 * multiplier).toString()]
      },
      {
        sentence: `${multiplicand2.toLocaleString()} ${MULT} ${multiplier2.toLocaleString()} = <ans/>`,
        answers: [(multiplicand2 * multiplier2).toString()]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        sentences={sentences.map(sentence => sentence.sentence)}
        testCorrect={sentences.map(sentence => sentence.answers)}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aOJ',
  description: 'aOJ',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    multiplicandOnes: z.number().int().min(2).max(9),
    multiplicandTens: z.number().int().min(10).max(50).step(10),
    multiplier: z.number().int().min(2).max(5)
  }),
  simpleGenerator: () => {
    const multiplicandOnes = randomIntegerInclusive(2, 9);
    const multiplicandTens = randomIntegerInclusiveStep(10, 50, 10);
    const multiplier = randomIntegerInclusive(2, 5);

    return { multiplicandOnes, multiplicandTens, multiplier };
  },
  Component: ({ question: { multiplicandOnes, multiplicandTens, multiplier }, translate }) => {
    const multiplicand = multiplicandOnes + multiplicandTens;

    const sentences = [
      {
        sentence: `${multiplicandOnes.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
        answers: [(multiplicandOnes * multiplier).toString()]
      },
      {
        sentence: `${multiplicandTens.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
        answers: [(multiplicandTens * multiplier).toString()]
      },
      {
        sentence: `${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
        answers: [(multiplicand * multiplier).toString()]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        sentences={sentences.map(sentence => sentence.sentence)}
        testCorrect={sentences.map(sentence => sentence.answers)}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aOK',
  description: 'aOK',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    multiplicand: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0, 'Multiplicand should not be a multiple of 10'),
    multiplier: z.number().int().min(13).max(19)
  }),
  simpleGenerator: () => {
    const multiplicand = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const multiplier = randomIntegerInclusive(13, 19);

    return { multiplicand, multiplier };
  },
  Component: ({ question: { multiplicand, multiplier }, translate }) => {
    const answer = multiplicand * multiplier;

    return (
      <QF27aLongMultiplicationDeprecated
        title={translate.instructions.workOutX(`${multiplicand} ${MULT} ${multiplier}`)}
        topNumber={multiplicand}
        multiplier={multiplier}
        showPartials
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'aOK2',
  description: 'aOK',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    multiplicand: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0, 'Multiplicand should not be a multiple of 10'),
    multiplier: z.number().int().min(13).max(19)
  }),
  simpleGenerator: () => {
    const multiplicand = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const multiplier = randomIntegerInclusive(13, 19);

    return { multiplicand, multiplier };
  },
  Component: ({ question: { multiplicand, multiplier }, translate }) => {
    const answer = multiplicand * multiplier;

    return (
      <QF27aLongMultiplication
        title={translate.instructions.workOutX(`${multiplicand} ${MULT} ${multiplier}`)}
        topNumber={multiplicand}
        multiplier={multiplier}
        showPartials
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aOL',
  description: 'aOL',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    multiplicand: z
      .number()
      .int()
      .min(41)
      .max(59)
      .refine(x => x % 10 !== 0, 'Multiplicand should not be a multiple of 10'),
    multiplier: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0, 'Multiplier should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const multiplicand = randomIntegerInclusive(41, 59, { constraint: x => x % 10 !== 0 });
    const multiplier = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });

    return { multiplicand, multiplier };
  },
  Component: ({ question: { multiplicand, multiplier }, translate }) => {
    const answer = multiplicand * multiplier;

    return (
      <QF27aLongMultiplicationDeprecated
        title={translate.instructions.workOutX(`${multiplicand} ${MULT} ${multiplier}`)}
        topNumber={multiplicand}
        multiplier={multiplier}
        showPartials
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4v2 = newQuestionContent({
  uid: 'aOL2',
  description: 'aOL',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    multiplicand: z
      .number()
      .int()
      .min(41)
      .max(59)
      .refine(x => x % 10 !== 0, 'Multiplicand should not be a multiple of 10'),
    multiplier: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(x => x % 10 !== 0, 'Multiplier should not be a multiple of 10')
  }),
  simpleGenerator: () => {
    const multiplicand = randomIntegerInclusive(41, 59, { constraint: x => x % 10 !== 0 });
    const multiplier = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });

    return { multiplicand, multiplier };
  },
  Component: ({ question: { multiplicand, multiplier }, translate }) => {
    const answer = multiplicand * multiplier;

    return (
      <QF27aLongMultiplication
        title={translate.instructions.workOutX(`${multiplicand} ${MULT} ${multiplier}`)}
        topNumber={multiplicand}
        multiplier={multiplier}
        showPartials
        customMarkSchemeAnswer={{
          answerToDisplay: {
            answer: getMarkSchemeAnswer(answer, answer.toString().length)
          }
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aOM',
  description: 'aOM',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    multiplicandOnes: z.number().int().min(2).max(6),
    multiplicandTens: z.number().int().min(20).max(60).step(10),
    multiplier: z
      .number()
      .int()
      .min(21)
      .max(69)
      .refine(x => x % 10 !== 0, 'Multiplier should not be divisible by 0')
  }),
  simpleGenerator: () => {
    const multiplicandOnes = randomIntegerInclusive(2, 6);
    const multiplicandTens = randomIntegerInclusiveStep(20, 60, 10);
    const multiplier = randomIntegerInclusive(21, 69, { constraint: x => x % 10 !== 0 });

    return { multiplicandOnes, multiplicandTens, multiplier };
  },
  Component: ({ question: { multiplicandOnes, multiplicandTens, multiplier }, translate }) => {
    const multiplicand = multiplicandOnes + multiplicandTens;

    const sentences = [
      {
        sentence: `${multiplicandOnes.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
        answers: [(multiplicandOnes * multiplier).toString()]
      },
      {
        sentence: `${multiplicandTens.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
        answers: [(multiplicandTens * multiplier).toString()]
      },
      {
        sentence: `${multiplicand.toLocaleString()} ${MULT} ${multiplier.toLocaleString()} = <ans/>`,
        answers: [(multiplicand * multiplier).toString()]
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        sentences={sentences.map(sentence => sentence.sentence)}
        testCorrect={sentences.map(sentence => sentence.answers)}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aON',
  description: 'aON',
  keywords: ['2-digit', 'Multiplication'],
  schema: z.object({
    pages: z
      .number()
      .int()
      .min(21)
      .max(69)
      .refine(x => x % 10 !== 0, 'Pages should not be divisible by 10'),
    weeks: z.number().int().min(2).max(9),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const weeks = randomIntegerInclusive(2, 9);
    const pages = randomIntegerInclusive(21, 69, { constraint: x => x % 10 !== 0 });
    const name = getRandomName();

    return { name, pages, weeks };
  },
  Component: ({ question: { name, pages, weeks }, translate }) => {
    const totalPages = weeks * pages * 7;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterReadXPagesOfBookEveryNightForYWeeks(
          name,
          pages,
          weeks
        )}
        sentence={'<ans/>'}
        testCorrect={[totalPages.toString()]}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////
const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyA2DigitNumberByA2DigitNumber',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question3, Question4]
});
export default SmallStep;
