import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';
import { TranslationFunctions } from '../i18n/i18n-types';
import { DayName, dayNames } from './days';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
] as const;

export const monthSchema = z.enum(monthNames);

export function getRandomMonth() {
  return getRandomFromArray(monthNames);
}

export function getRandomUniqueMonths(quantity: number) {
  return getRandomSubArrayFromArray([...monthNames] as const, quantity);
}

export type MonthName = (typeof monthNames)[number];

// DateOfMonth type below required to stop TypeScript complaining in various places where a date is required.
type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

type Range<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

export type DateOfMonth = Range<1, 31>;

export const isMonth = (x: string): x is MonthName => monthNames.includes(x as MonthName);

export const monthAsWord = (month: MonthName, translate: TranslationFunctions) => {
  return translate.time[month]();
};

export const getNumberOfDaysInMonth = (month: MonthName, isLeapYear?: boolean) => {
  switch (month) {
    case 'January':
    case 'March':
    case 'May':
    case 'July':
    case 'August':
    case 'October':
    case 'December':
      return 31;
    case 'February':
      return isLeapYear ? 29 : 28;
    case 'April':
    case 'June':
    case 'September':
    case 'November':
      return 30;
  }
};

/**
 * Function that uses the actual calendar year to get you the number of days in the month. Supports leap year by default
 */
export const getNumberOfDaysInMonthByYear = (month: MonthName, year: number) => {
  return new Date(year, monthNames.indexOf(month) + 1, 0).getDate();
};

/**
 * Function to return the day name for a given date. Used when accuracy is important for calendar dates
 */
export const getDayNameForGivenDate = (date: Date) => {
  const day = date.getDay() === 0 ? 6 : date.getDay() - 1;
  return dayNames[day];
};

/**
 * Function to return the date of the first of a particular day in a month.
 * Calculations based on what day the month starts,
 * e.g. if the month starts on a Tuesday, the first Wednesday will be the 2nd day of the month, the first Monday will be the 7th.
 */
export function getFirstDateOfDayInMonthByYear(
  year: number,
  month: MonthName,
  selectedDay: DayName
) {
  const startingDayOfMonth = getDayNameForGivenDate(new Date(year, monthNames.indexOf(month), 1));

  const numberOfBlankDaysAtStart = dayNames.indexOf(startingDayOfMonth);

  const dayIndex = dayNames.indexOf(selectedDay);

  let correctDate = dayIndex - numberOfBlankDaysAtStart + 1;

  if (correctDate <= 0) {
    correctDate += 7;
  }

  return correctDate;
}

/**
 * Function to return the date of the first of a particular day in a month.
 * Calculations based on what day the month starts and what month it is,
 * e.g. if the month of May starts on a Thursday, the last Wednesday will be the 28th, the last Sunday will be the 25th.
 */
export function getLastDateOfDayInMonthByYear(
  year: number,
  month: MonthName,
  selectedDay: DayName
) {
  // Get the first occurrence of the selected day in the month
  let lastDate = getFirstDateOfDayInMonthByYear(year, month, selectedDay);

  // Get the number of days in the month
  const numberOfDaysInMonth = getNumberOfDaysInMonthByYear(month, year);

  // Keep adding 7 days until the lastDate falls within the valid range
  while (lastDate + 7 <= numberOfDaysInMonth) {
    lastDate += 7;
  }

  return lastDate;
}

/**
 * Function to return date based on number ordinal index e.g 1,2,3,4 (first, second, third, fourth)
 * Calculations based on what day the month starts and what month it is
 * e.g if the month of October starts on Thursday 1st
 * And the selected day is Sunday
 * And the ordinal number is 2 (Second Sunday)
 * Then 11 (11th) is returned
 */
export function getSelectedDateFromFirstDateOfDayInMonth(
  year: number,
  month: MonthName,
  selectedDay: DayName,
  ordinalNumberIndex: 1 | 2 | 3 | 4
) {
  return getFirstDateOfDayInMonthByYear(year, month, selectedDay) + 7 * (ordinalNumberIndex - 1);
}

/**
 * Function to get amount of consecutive months.
 * Starting a specific month index from 1 to 12.
 * Or a random month index if no month index passed
 */
export const getConsecutiveMonths = (amountOfMonths: number, startMonthIndex?: number) => {
  const startIndex = startMonthIndex ? startMonthIndex - 1 : Math.floor(Math.random() * 12); // start month index from 1 to 12 or random start index between 0 and 11
  const selectedMonths: MonthName[] = [];

  for (let i = 0; i < amountOfMonths; i++) {
    const index = (startIndex + i) % 12;
    selectedMonths.push(monthNames[index]);
  }

  return selectedMonths;
};
