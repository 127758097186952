import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from '../../../../utils/random';
import CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import { base10ObjectToNumber, ScientificNotation } from '../../../../utils/math';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import { MULT } from '../../../../constants';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2DraggableAlignedEquations from '../../../../components/question/questionFormats/QF2DraggableAlignedEquations';
import { countRange } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'adE',
  description: 'adE',
  keywords: ['Place value', 'Powers of 10', 'Chart'],
  schema: z
    .object({
      number: z.number().min(11).max(9900),
      powerOfTen: z.number().min(1).max(3)
    })
    .refine(val => val.number * Math.pow(10, val.powerOfTen) < 1000000),
  simpleGenerator: () => {
    const firstDigit = randomIntegerInclusive(1, 9);
    const secondDigit = randomIntegerInclusive(1, 9);
    // This generates a number with exactly two consecutive significant digits, from 11 to 9900
    const number =
      base10ObjectToNumber({ ones: firstDigit, tens: secondDigit }) *
      Math.pow(10, randomIntegerInclusive(0, 2));

    // This prevents the question asking to create a number into the millions:
    const powerOfTen = number >= 1000 ? randomIntegerInclusive(1, 2) : randomIntegerInclusive(1, 3);
    return { number, powerOfTen };
  },
  Component: ({ question: { number, powerOfTen }, translate, displayMode, ...props }) => {
    const power = Math.pow(10, powerOfTen);

    return (
      <CreatePlaceValueChart
        number={ScientificNotation.fromNumber(number * power)}
        columnsToShow={[5, 4, 3, 2, 1, 0]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        title={translate.instructions.dragCountersToShowNumTimesNum(
          number.toLocaleString(),
          power.toLocaleString()
        )}
        pdfTitle={translate.instructions.drawCountersToShowTheAnswerToX(
          `${number.toLocaleString()} ${MULT} ${(10).toLocaleString()}`
        )}
        initialState={displayMode === 'digital' ? number : undefined}
        {...props}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'adF',
  description: 'adF',
  keywords: ['Place value', 'Powers of 10'],
  schema: z.object({
    powerOfNum1: z.number().min(2).max(5),
    powerOfNum2: z.number().min(3).max(6)
  }),
  simpleGenerator: () => {
    const powerOfNum1 = randomIntegerInclusive(2, 5);
    const powerOfNum2 = rejectionSample(
      () => randomIntegerInclusive(3, 6),
      x => x !== powerOfNum1
    );

    return { powerOfNum1, powerOfNum2 };
  },
  Component: ({ question: { powerOfNum1, powerOfNum2 }, translate }) => {
    const number1 = Math.pow(10, powerOfNum1);
    const number2 = Math.pow(10, powerOfNum2);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[(number1 * 10).toString()], [(number2 / 10).toString()]]}
        sentences={[
          translate.answerSentences.numTimes10Equals(number1.toLocaleString()),
          translate.answerSentences.ansTimes10Equals(number2.toLocaleString())
        ]}
        containerStyle={{ alignItems: 'center' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'adG',
  description: 'adG',
  keywords: ['Place value', 'Powers of 10'],
  schema: z.object({
    number1: z.number().min(200).max(900).multipleOf(100),
    number2: z.number().min(1000).max(9000).multipleOf(1000),
    number3: z
      .number()
      .min(200)
      .max(9900)
      .multipleOf(100)
      .refine(val => val % 1000 !== 0, 'Number must not be a multiple of 1,000')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9) * 100;
    const number2 = randomIntegerInclusive(1, 9) * 1000;
    const number3 =
      randomIntegerInclusive(2, 99, {
        constraint: x => (x * 100) % 1000 !== 0 && x * 100 !== number1 && x * 100 !== number2
      }) * 100;

    return { number1, number2, number3 };
  },
  Component: ({ question: { number1, number2, number3 }, translate }) => {
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        sentences={[
          translate.answerSentences.numIsEquivalentToAns100s(number1),
          translate.answerSentences.numIsEquivalentToAns100s(number2),
          translate.answerSentences.numIsEquivalentToAns100s(number3)
        ]}
        testCorrect={[
          [(number1 / 100).toString()],
          [(number2 / 100).toString()],
          [(number3 / 100).toString()]
        ]}
        containerStyle={{ alignItems: 'flex-start' }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'adH',
  description: 'adH',
  keywords: ['Place value', 'Powers of 10', 'Gattegno'],
  schema: z.object({
    number: z.number().int().min(1000).max(99000).multipleOf(1000),
    powerOfTen: z.union([z.literal(1), z.literal(-1)])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 99) * 1000;
    const powerOfTen = getRandomFromArray([-1, 1] as const);
    return { number, powerOfTen };
  },
  Component: props => {
    const {
      question: { number, powerOfTen },
      translate
    } = props;

    const answer = number * Math.pow(10, powerOfTen);

    return (
      <QF12CreateGattegnoChart
        correctAnswer={answer}
        rowsToShow={[5, 4, 3, 2]}
        title={
          powerOfTen === -1
            ? translate.instructions.showNumOneTenthSizeOfNumOnGattegno(number.toLocaleString())
            : translate.instructions.showNum10TimesSizeOfNumOnGattegno(number.toLocaleString())
        }
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'adI',
  description: 'adI',
  keywords: ['Place value', 'Powers of 10'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int().min(10).max(90).multipleOf(10),
    number3: z.number().int().min(100).max(990).multipleOf(10),
    number4: z.number().int().min(100).max(900).multipleOf(100),
    powerOfNum1Unit: z.number().int().min(1).max(3),
    powerOfNum2Unit: z.number().int().min(1).max(3),
    powerOfNum3Unit: z.number().int().min(1).max(3),
    powerOfNum4Unit: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99);

    // number2 must be multiple of 10 from 10 to 90
    const number2 = randomIntegerInclusive(1, 9) * 10;

    // number3 must be multiple of 10 from 100 to 990
    const number3 = randomIntegerInclusive(10, 99) * 10;

    // number4 must be multiple of 100 from 100 to 900
    const number4 = randomIntegerInclusive(1, 9) * 100;

    const powerOfNum1Unit = randomIntegerInclusive(1, 3);
    const powerOfNum2Unit = randomIntegerInclusive(1, 3);
    const powerOfNum3Unit = randomIntegerInclusive(1, 3);
    const powerOfNum4Unit = randomIntegerInclusive(1, 3);
    return {
      number1,
      number2,
      number3,
      number4,
      powerOfNum1Unit,
      powerOfNum2Unit,
      powerOfNum3Unit,
      powerOfNum4Unit
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        powerOfNum1Unit,
        powerOfNum2Unit,
        powerOfNum3Unit,
        powerOfNum4Unit
      },
      translate
    } = props;

    const power1 = Math.pow(10, powerOfNum1Unit);
    const power2 = Math.pow(10, powerOfNum2Unit);
    const power3 = Math.pow(10, powerOfNum3Unit);
    const power4 = Math.pow(10, powerOfNum4Unit);

    const numbersAndPowers = [
      [number1, power1],
      [number2, power2],
      [number3, power3],
      [number4, power4]
    ];
    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardCanBeUsedMoreThanOnce()}
        pdfLayout="itemsRight"
        items={[10, 100, 1000]}
        sentences={numbersAndPowers.map(
          ([number, power]) =>
            `${number.toLocaleString()} ${MULT} <ans/> = ${(number * power).toLocaleString()}`
        )}
        testCorrect={numbersAndPowers.map(([_number, power]) => [power])}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5v2 = newQuestionContent({
  uid: 'adI2',
  description: 'adI',
  keywords: ['Place value', 'Powers of 10'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int().min(10).max(90).multipleOf(10),
    number3: z.number().int().min(100).max(990).multipleOf(10),
    number4: z.number().int().min(100).max(900).multipleOf(100),
    powerOfNumUnits: z.array(z.number().int().min(1).max(3)).length(4)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99);

    // number2 must be multiple of 10 from 10 to 90
    const number2 = randomIntegerInclusive(1, 9) * 10;

    // number3 must be multiple of 10 from 100 to 990
    const number3 = randomIntegerInclusive(10, 99) * 10;

    // number4 must be multiple of 100 from 100 to 900
    const number4 = randomIntegerInclusive(1, 9) * 100;

    const powerOfNumUnits = countRange(4).map(() => randomIntegerInclusive(1, 3));
    return {
      number1,
      number2,
      number3,
      number4,
      powerOfNumUnits
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, powerOfNumUnits },
      translate
    } = props;

    const [power1, power2, power3, power4] = powerOfNumUnits.map(powerOfNumUnit =>
      Math.pow(10, powerOfNumUnit)
    );

    const numbersAndPowers = [
      [number1, power1],
      [number2, power2],
      [number3, power3],
      [number4, power4]
    ];

    const statements = numbersAndPowers.map(([number, power]) => ({
      left: `${number.toLocaleString()} ${MULT} <ans/>`,
      right: (number * power).toLocaleString(),
      answer: [power]
    }));
    // All answers are on the lhs
    const answers = statements.map(({ answer }) => ({ left: answer }));

    return (
      <QF2DraggableAlignedEquations
        actionPanelVariant="end"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardCanBeUsedMoreThanOnce()}
        moveOrCopy="copy"
        pdfLayout="itemsRight"
        items={[10, 100, 1000].map(value => ({ value, component: value.toLocaleString() }))}
        sentences={statements}
        testCorrect={answers}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'adJ',
  description: 'adJ',
  keywords: ['Place value', 'Powers of 10', 'Conversion', 'Units'],
  schema: z.object({
    number: z.number().int().min(1).max(9),
    powerOfTen: z.number().int().min(1).max(3)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);
    const powerOfTen = randomIntegerInclusive(1, 3);
    return { number, powerOfTen };
  },
  Component: props => {
    const {
      question: { number, powerOfTen },
      translate,
      displayMode
    } = props;

    const numbers = [new Array(number).fill(1), new Array(number).fill(1)];

    const strings = [
      new Array(number).fill(`${translate.units.numberOfM(1)}`),
      new Array(number).fill(`${translate.units.numberOfCm(100)}`)
    ];

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={translate.instructions.oneMetreIs100Cm()}
        testCorrect={[
          [(number * 100).toString()],
          [(number * 100 * Math.pow(10, powerOfTen)).toString()]
        ]}
        sentences={[
          translate.answerSentences.howManyCmAreThereInNumM(number.toLocaleString()),
          translate.answerSentences.howManyCmAreThereInNumM(
            (number * Math.pow(10, powerOfTen)).toLocaleString()
          )
        ]}
        textStyle={{ fontSize: displayMode === 'digital' ? 28 : 40 }}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={number}
            dimens={dimens}
            sameRowColor
          />
        )}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PowersOf10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
