import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { View } from 'react-native';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import { barModelColors } from '../../../../theme/colors';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkn',
  description: 'bkn',
  keywords: ['Equal groups', 'Group', 'Quarter'],
  schema: z
    .object({
      rows: z.number().int().min(2).max(5),
      columns: z.number().int().min(2).max(10)
    })
    .refine(val => val.columns * val.rows <= 20 && (val.columns * val.rows) % 4 === 0),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(2, 5);

    const columns = randomIntegerInclusive(2, 10, {
      constraint: x => x * rows <= 20 && (x * rows) % 4 === 0
    });

    return { rows, columns };
  },
  Component: props => {
    const {
      question: { rows, columns },
      translate,
      displayMode
    } = props;

    const total = rows * columns;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.findFracOfX(
          "<frac n='1' d='4' />",
          total.toLocaleString()
        )}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={translate.ks1AnswerSentences.fracOfXIsAns("<frac n='1' d='4'/>", total)}
        testCorrect={[(total / 4).toString()]}
        Content={({ dimens }) => <ArrayOfObjects rows={rows} columns={columns} dimens={dimens} />}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bko',
  description: 'bko',
  keywords: ['Whole', 'Part', 'Bar model', 'Quarter'],
  schema: z.object({
    total: z.number().int().min(4).max(20),
    barModelColor: z.string()
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusiveStep(4, 20, 4);

    const barModelColor = getRandomFromArray(Object.values(barModelColors)) as string;

    return { total, barModelColor };
  },
  Component: props => {
    const {
      question: { total, barModelColor },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.findFracOfX(
          "<frac n='1' d='4' />",
          total.toLocaleString()
        )}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentence={translate.ks1AnswerSentences.fracOfXIsAns("<frac n='1' d='4'/>", total)}
        testCorrect={[(total / 4).toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View>
            <BarModel
              dimens={{ width: dimens.width + 40, height: 100 }}
              total={total}
              numbers={[[total]]}
              rowColors={[barModelColor]}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              cellStyle={{ borderBottomWidth: 0 }}
            />
            <BarModelInteractiveWithState
              id="barmodel"
              numberOfRows={1}
              numberOfCols={4}
              tableHeight={100}
              tableWidth={dimens.width}
              testCorrect={() => true}
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkp',
  description: 'bkp',
  keywords: ['Fraction', 'Quarter'],
  schema: z.object({
    total: z
      .number()
      .int()
      .min(4)
      .max(100)
      .step(4)
      .refine(num => isInRange(4, 24)(num) || [40, 60, 80, 100].includes(num))
  }),
  questionHeight: 400,
  simpleGenerator: () => {
    const total = randomIntegerInclusiveStep(4, 100, 4, {
      constraint: x => isInRange(4, 24)(x) || [40, 60, 80, 100].includes(x)
    });

    return { total };
  },
  Component: props => {
    const {
      question: { total },
      translate,
      displayMode
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.fillInTheMissingNumber()}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={[(total / 4).toString()]}
        sentence={translate.ks1AnswerSentences.fracOfXIsAns("<frac n='1' d='4'/>", total)}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        questionHeight={400}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAQuarter',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
