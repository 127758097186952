import {
  Base10RepTo10sVariant,
  Base10RepTo10sVariantSchema
} from '../../../../components/question/representations/types';
import { getRandomFromArray } from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { randomIntegerInclusive } from '../../../../utils/random';
import QF15CreateBaseTenNumber, {
  sumDigits
} from '../../../../components/question/questionFormats/QF15CreateBaseTenNumber';
import { ScientificNotation } from '../../../../utils/math';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  Scale,
  SimpleBaseTenWithCrossOut
} from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aaa',
  description: 'aaa',
  keywords: ['Place value', 'Represent', '100', 'Hundred', 'Object'],
  schema: z.object({
    ones: z.number().int().min(2).max(9),
    tens: z.number().int().min(2).max(9)
  }),
  questionHeight: 1440,
  example: {
    ones: 4,
    tens: 2
  },
  simpleGenerator: () => {
    // Limit ones to 2-9 and tens to 2-9
    const ones = randomIntegerInclusive(2, 9);
    const tens = randomIntegerInclusive(2, 9);
    return {
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;

    const variant = 'Straws' as Base10RepTo10sVariant;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        testCorrect={[tens.toString(), ones.toString(), (tens * 10 + ones).toString()]}
        sentence={translate.answerSentences.thereAreAnsTensAndAnsOnes()}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        pdfDirection="column"
        questionHeight={1440}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aab',
  description: 'aab',
  keywords: ['Place value', 'Represent', '100', 'Hundred', 'Base 10'],
  schema: z.object({
    variant: Base10RepTo10sVariantSchema,
    ones: z.number().int().min(2).max(9),
    tens: z.number().int().min(2).max(9)
  }),
  questionHeight: 1440,
  example: {
    variant: 'Cubes',
    ones: 4,
    tens: 2
  },
  simpleGenerator: () => {
    const variant = 'Cubes' as Base10RepTo10sVariant;
    // Limit ones to 2-9 and tens to 2-9
    const tens = randomIntegerInclusive(2, 9);
    const ones = randomIntegerInclusive(2, 9);
    return {
      variant,
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        testCorrect={[tens.toString(), ones.toString(), (tens * 10 + ones).toString()]}
        sentence={translate.answerSentences.thereAreAnsTensAndAnsOnes()}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        pdfDirection="column"
        questionHeight={1440}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aac',
  description: 'aac',
  keywords: ['Place value', 'Represent', '100', 'Hundred', 'Base 10', 'Partition', 'Flexible'],
  schema: z.object({
    ones: z.number().int().min(10).max(19),
    tens: z.number().int().min(2).max(8)
  }),
  questionHeight: 1440,
  example: {
    ones: 14,
    tens: 2
  },
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(2, 8);
    const ones = randomIntegerInclusive(10, 19);
    return {
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;

    const variant = 'Cubes' as Base10RepTo10sVariant;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeSentences()}
        testCorrect={[tens.toString(), ones.toString(), (tens * 10 + ones).toString()]}
        sentence={translate.answerSentences.thereAreAnsTensAndAnsOnes()}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
        pdfDirection="column"
        questionHeight={1440}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aad',
  description: 'aad',
  keywords: ['Place value', 'Represent', '100', 'Hundred', 'Base 10', 'Partition', 'Flexible'],
  schema: z.object({
    ones: z.number().int().min(10).max(19),
    tens: z.number().int().min(1).max(8)
  }),
  example: {
    ones: 14,
    tens: 2
  },
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(1, 8);
    const ones = randomIntegerInclusive(10, 19);
    return {
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;

    const variant = 'Cubes' as Base10RepTo10sVariant;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[(tens * 10 + ones).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        {...props}
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question4V2 = newQuestionContent({
  uid: 'aad2',
  description: 'aad',
  keywords: ['Place value', 'Represent', '100', 'Hundred', 'Base 10', 'Partition', 'Flexible'],
  schema: z.object({
    ones: z.number().int().min(10).max(89),
    tens: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const maxTotal = 99;
    const onesTippingPoint = 50;
    const tens = randomIntegerInclusive(1, 8);

    // The total for this question is 99.
    // When we have less than 5 tens (the tipping point) the sum for the total ones is too high to display
    // For every ten less than 5 we can have 5 less ones than the total
    const onesScaler = tens < 5 ? onesTippingPoint + 5 * (5 - tens) : onesTippingPoint;
    const ones = randomIntegerInclusive(10, Math.min(maxTotal - tens * 10, onesScaler));

    return {
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={'<ans/>'}
        title={translate.instructions.whatNumberIsShown()}
        testCorrect={[(tens * 10 + ones).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <SimpleBaseTenWithCrossOut
            ones={ones}
            tens={tens}
            dimens={dimens}
            splitOnesIntoGroupsOfTen
          />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aae',
  description: 'aae',
  keywords: ['Place value', 'Represent', '100', 'Hundred', 'Base 10'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(11)
      .max(99)
      .refine(
        number => sumDigits(number) <= 12,
        'number must be representable with <=12 base ten counters'
      )
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(11, 99, {
      constraint: number => sumDigits(number) <= 12
    });
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF15CreateBaseTenNumber
        title={translate.instructions.dragTheBase10ToMakeTheNumberX(number)}
        pdfTitle={translate.instructions.drawBaseTenToMakeNumberX(number)}
        number={ScientificNotation.fromNumber(number)}
        draggablesToShow={[1, 0]}
        counterSize="large"
        rows={2}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'aaf',
  description: 'aaf',
  keywords: [
    'Place value',
    'Represent',
    '100',
    'Hundred',
    'Base 10',
    'Select',
    'Flexible partition'
  ],
  schema: z
    .object({
      variant: Base10RepTo10sVariantSchema,
      number: z.number().int().min(11).max(99),
      numberPartition: z.object({
        tens: z.number().int().min(1).max(9),
        ones: z.number().int().min(1).max(9)
      }),
      wrongNumberPartitions: z
        .array(
          z.object({
            tens: z.number().int().min(0).max(9),
            ones: z.number().int().min(0).max(9)
          })
        )
        .nonempty(),
      correctPosition: z.number().int().min(0).max(3)
    })
    .refine(
      val => val.wrongNumberPartitions.every(it => it.tens * 10 + it.ones !== val.number),
      'Each wrong number must not sum to the number provided'
    ),
  example: {
    variant: 'Cubes',
    number: 45,
    numberPartition: { tens: 4, ones: 5 },
    wrongNumberPartitions: [
      { tens: 4, ones: 7 },
      { tens: 2, ones: 5 },
      { tens: 6, ones: 3 }
    ],
    correctPosition: 3
  },
  simpleGenerator: () => {
    const variant = 'Cubes' as Base10RepTo10sVariant;

    // Chose the number
    const tens = randomIntegerInclusive(1, 9);
    const ones = randomIntegerInclusive(1, 9);
    const number = tens * 10 + ones;

    type Partition = { tens: number; ones: number };
    const numberPartition = { tens: tens, ones: ones };

    const wrongNumberPartitions: [Partition, ...Partition[]] = [
      { tens: tens <= 5 ? tens + 2 : tens - 2, ones: ones },
      { tens: tens, ones: ones <= 5 ? ones + 2 : ones - 2 },
      { tens: tens <= 5 ? tens + 3 : tens - 3, ones: ones <= 5 ? ones + 3 : ones - 3 }
    ];

    const correctPosition = getRandomFromArray([0, 1, 2, 3] as const);
    return { variant, number, numberPartition, wrongNumberPartitions, correctPosition };
  },
  Component: props => {
    const {
      question: { number, numberPartition, wrongNumberPartitions, correctPosition },
      translate
    } = props;
    const allPartitions = [
      ...wrongNumberPartitions.slice(0, correctPosition),
      numberPartition,
      ...wrongNumberPartitions.slice(correctPosition)
    ];

    return (
      <QF11SelectImagesUpTo4<number>
        title={translate.instructions.selectTheBoxThatShowsX(number)}
        pdfTitle={translate.instructions.circleTheBoxThatShowsX(number)}
        testCorrect={[correctPosition]}
        numItems={4}
        renderItems={({ dimens }) => {
          const scale = Math.min(
            ...allPartitions.map(part => Scale(dimens.width, dimens.height, part))
          );
          return allPartitions.map((partition, index) => ({
            value: index,
            component: (
              <SimpleBaseTenWithCrossOut
                ones={partition.ones}
                tens={partition.tens}
                dimens={{ width: dimens.width, height: dimens.height - 20 }}
                scale={scale}
              />
            )
          }));
        }}
        {...props}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RepresentNumbersTo100',
  questionTypes: [Question1, Question2, Question3, Question4V2, Question5, Question6],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
