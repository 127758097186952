import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersDoNotExchangeAt, numbersExchangeAt } from '../../../../utils/exchanges';
import { ADD, MULT, SUB } from '../../../../constants';
import { useMemo } from 'react';
import { numberToBase10Object } from '../../../../utils/math';
import { aiD } from '../../../Year 4/Autumn/AdditionAndSubtraction/9EstimateAnswers';
import { ai9 } from '../../../Year 5/Autumn/AdditionAndSubtraction/4RoundToCheckAnswers';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import * as math from 'mathjs';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'anT',
  description: 'anT',
  keywords: ['Mental', 'Addition'],
  schema: z
    .object({
      number1: z.number().int().min(11).max(99),
      number2: z.number().int().min(11).max(99),
      answerBox: z.enum(['left', 'right'])
    })
    .refine(val => val.number1 % 10 !== 0, 'numberA1 cannot be a multiple of 10')
    .refine(val => val.number1 % 10 !== 9, 'number1 cannot end in a 9')
    .refine(val => val.number2 % 10 !== 0, 'numberA2 cannot be a multiple of 10')
    .refine(
      val =>
        (numbersExchangeAt(val.number1, val.number2, 'ones') &&
          numbersDoNotExchangeAt(val.number1, val.number2, 'tens')) ||
        (numbersExchangeAt(val.number1, val.number2, 'tens') &&
          numbersDoNotExchangeAt(val.number1, val.number2, 'ones')),
      'number1 + number2 must have one exchange.'
    ),
  simpleGenerator: () => {
    // If number1 ended in a 9, number2 would have to either have one digit or end in a 0, which is not allowed.
    const number1 = randomIntegerInclusive(11, 98, {
      constraint: x => x % 10 !== 0 && x % 10 !== 9
    });

    const number2 = randomIntegerInclusive(11, 99, {
      constraint: x =>
        x % 10 !== 0 &&
        ((numbersExchangeAt(number1, x, 'ones') && numbersDoNotExchangeAt(number1, x, 'tens')) ||
          (numbersExchangeAt(number1, x, 'tens') && numbersDoNotExchangeAt(number1, x, 'ones')))
    });

    const answerBox = getRandomFromArray(['left', 'right'] as const);

    return { number1, number2, answerBox };
  },

  Component: props => {
    const {
      question: { number1, number2, answerBox },
      translate
    } = props;

    const number3 = number1 + number2;

    const number4 =
      (numberToBase10Object(number1).tens ?? 0) * 10 +
      (numberToBase10Object(number2).tens ?? 0) * 10;

    const number5 =
      (numberToBase10Object(number1).ones ?? 0) + (numberToBase10Object(number2).ones ?? 0);

    const exchangeAtOnes = numbersExchangeAt(number1, number2, 'ones');

    const number6 = exchangeAtOnes
      ? number1 + (numberToBase10Object(number2).tens ?? 0) * 10
      : number1 + (numberToBase10Object(number2).ones ?? 0);

    const number7 = exchangeAtOnes
      ? numberToBase10Object(number2).ones ?? 0
      : (numberToBase10Object(number2).tens ?? 0) * 10;

    const eqA =
      answerBox === 'left'
        ? `${number1} + ${number2} = <ans/> + ${number5} = <ans/>`
        : `${number1} + ${number2} = ${number4} + <ans/> = <ans/>`;
    const eqB = `${number1} + ${number2} = ${number6} + <ans/> = <ans/>`;

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [answerBox === 'left' ? number4.toString() : number5.toString(), number3.toString()],
          [number7.toString(), number3.toString()]
        ]}
        sentences={eqs}
        {...props}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'anU',
  description: 'anU',
  keywords: ['Mental', 'Efficient'],
  schema: z.object({
    number1: z.number().int().min(1001).max(9999),
    number4: numberEnum([100, 1000]),
    number5: numberEnum([1, 2, 3, 5, 10, 20, 30]),
    addOrSubtract: z.enum([ADD, SUB])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % 10 !== 0
    });

    const number4 = getRandomFromArray([100, 1000] as const);

    const number5 =
      number4 === 100
        ? getRandomFromArray([1, 2, 3, 5] as const)
        : getRandomFromArray([1, 2, 3, 10, 20, 30] as const);

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { number1, number4, number5, addOrSubtract };
  },
  Component: props => {
    const {
      question: { number1, number4, number5, addOrSubtract },
      translate
    } = props;

    const number2 = number4 - number5;

    const draggables = [number4, number5, '+', '-'];

    return (
      <QF37SentenceDrag<string | number>
        title={translate.instructions.dragCardsCompleteANumberSentence()}
        pdfTitle={translate.instructions.useCardsCompleteANumberSentencePDF()}
        items={draggables}
        sentence={`${number1.toLocaleString()} ${addOrSubtract} ${number2.toLocaleString()} = ${number1.toLocaleString()} <ans/> <ans/> <ans/> <ans/>`}
        testCorrect={userAnswer => {
          const [ans1, ans2, ans3, ans4] = userAnswer;

          let theirAnswer: number;
          // Check user has provided answers before evaluating
          try {
            theirAnswer = math.evaluate(`${number1} ${ans1} ${ans2} ${ans3} ${ans4}`);
          } catch {
            return false;
          }

          const correctAnswer = addOrSubtract === ADD ? number1 + number2 : number1 - number2;

          // Answers can either be a number or string e.g (5 or '+')
          // Answer boxes should follow pattern ['+', num, '-', num | '-', num, '+', num]
          // Check this type pattern exists
          if (
            typeof ans1 !== 'string' ||
            typeof ans2 !== 'number' ||
            typeof ans3 !== 'string' ||
            typeof ans4 !== 'number'
          ) {
            return false;
          }

          // Check ans1 & ans3 are '+' and '-' pattern
          if (ans1 === '+' && ans3 === '-') {
            return theirAnswer === correctAnswer;
          }
          // Or ans1 & ans3 are '-' and '+' pattern
          else if (ans1 === '-' && ans3 === '+') {
            return theirAnswer === correctAnswer;
          }
          // Must be either of these two patterns or incorrect
          else {
            return false;
          }
        }}
        moveOrCopy="move"
        sentenceStyle={{ flexWrap: 'nowrap' }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidNumberSentenceUsingAvailCards()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'anV',
  description: 'anV',
  keywords: ['Mental'],
  schema: z.object({
    number: z.number().int().min(10).max(100)
  }),
  simpleGenerator: () => {
    // Needed in order to get variation each time it's generated
    const number = randomIntegerInclusiveStep(10, 100, 2);
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: `${(25).toLocaleString()} ${MULT} ${number.toLocaleString()} ${MULT} ${(4).toLocaleString()}`,
        value: number * 100
      };
      const statement2 = {
        statement: `${number.toLocaleString()} ${MULT} ${(50).toLocaleString()}`,
        value: number * 50
      };
      const statement3 = {
        statement: `${(2).toLocaleString()} ${MULT} ${number.toLocaleString()} ${MULT} ${(5).toLocaleString()}`,
        value: number * 10
      };
      const statement4 = {
        statement: `${(5).toLocaleString()} ${MULT} ${number.toLocaleString()} ${MULT} ${(4).toLocaleString()}`,
        value: number * 20
      };
      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [number, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchEquivalentCalcs()}
        pdfTitle={translate.instructions.useCardsToMatchEquivalentCalcs()}
        items={[
          {
            component: `${number.toLocaleString()} ${MULT} ${(100).toLocaleString()}`,
            value: number * 100
          },
          {
            component: `${(number / 2).toLocaleString()} ${MULT} ${(100).toLocaleString()}`,
            value: number * 50
          },
          {
            component: `${number.toLocaleString()} ${MULT} ${(10).toLocaleString()}`,
            value: number * 10
          },
          {
            component: `${number.toLocaleString()} ${MULT} ${(20).toLocaleString()}`,
            value: number * 20
          }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} = <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'anW',
  description: 'anW',
  keywords: ['Mental'],
  schema: z
    .object({
      numberA1: z.number().int().min(21).max(98),
      numberA2: z.number().int().min(21).max(99),
      numberB1: z.number().int().min(160).max(990).multipleOf(10),
      numberB2: z.number().int().min(150).max(950).multipleOf(50),
      numberC1: z.number().int().min(16).max(99),
      numberC2: z.union([z.literal(2), z.literal(4), z.literal(8), z.literal(20), z.literal(40)])
    })
    .refine(val => val.numberA1 % 10 !== 0, 'numberA1 cannot be a multiple of 10')
    .refine(val => val.numberA1 % 10 !== 9, 'numberA1 cannot end in a 9')
    .refine(val => val.numberA2 % 10 !== 0, 'numberA2 cannot be a multiple of 10')
    .refine(
      val =>
        (numbersExchangeAt(val.numberA1, val.numberA2, 'ones') &&
          numbersDoNotExchangeAt(val.numberA1, val.numberA2, 'tens')) ||
        (numbersExchangeAt(val.numberA1, val.numberA2, 'tens') &&
          numbersDoNotExchangeAt(val.numberA1, val.numberA2, 'ones')),
      'numberA1 + numberA2 must have one exchange.'
    )
    .refine(
      val => val.numberB1 >= val.numberB2,
      'numberB1 must be greater than or equal to numberB2.'
    )
    .refine(val => val.numberC1 % 10 !== 0, 'numberC1 cannot be a multiple of 10'),
  simpleGenerator: () => {
    // If numberA1 ended in a 9, numberA2 would have to either have one digit or end in a 0, which is not allowed.
    const numberA1 = randomIntegerInclusive(21, 98, {
      constraint: x => x % 10 !== 0 && x % 10 !== 9
    });

    const numberA2 = randomIntegerInclusive(21, 99, {
      constraint: x =>
        x % 10 !== 0 &&
        ((numbersExchangeAt(numberA1, x, 'ones') && numbersDoNotExchangeAt(numberA1, x, 'tens')) ||
          (numbersExchangeAt(numberA1, x, 'tens') && numbersDoNotExchangeAt(numberA1, x, 'ones')))
    });

    const numberB1 = randomIntegerInclusiveStep(160, 990, 10);

    // numberB1 - numberB2 cannot be negative
    const numberB2UpperBound = Math.floor(numberB1 / 50) * 50;

    const numberB2 = randomIntegerInclusiveStep(150, numberB2UpperBound, 50);

    const numberC1 = randomIntegerInclusive(16, 99, {
      constraint: x => x % 10 !== 0
    });

    const numberC2 = getRandomFromArray([2, 4, 8, 20, 40] as const);

    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2 };
  },

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2 },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        sentence: `${numberA1} + ${numberA2} = <ans/>`,
        testCorrect: (numberA1 + numberA2).toString()
      };
      const eqB = {
        sentence: `${numberB1} ${SUB} ${numberB2} = <ans/>`,
        testCorrect: (numberB1 - numberB2).toString()
      };
      const eqC = {
        sentence: `${numberC2} ${MULT} ${numberC1} ${MULT} 5 = <ans/>`,
        testCorrect: (numberC2 * numberC1 * 5).toString()
      };

      return shuffle([eqA, eqB, eqC], { random: seededRandom(props.question) });
    }, [numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, props.question]);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[eqs[0].testCorrect], [eqs[1].testCorrect], [eqs[2].testCorrect]]}
        sentences={eqs.map(object => object.sentence)}
        {...props}
      />
    );
  }
});

const Question5: typeof aiD = {
  ...aiD,
  uid: 'anX',
  description: 'anX',
  keywords: ['Mental', 'Estimate']
};

const Question6: typeof ai9 = {
  ...ai9,
  uid: 'anY',
  description: 'anY',
  keywords: ['Mental', 'Estimate']
};

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MentalCalculationsAndEstimation',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
