import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { arrayHasNoDuplicates, filledArray, range } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import { multiplesNumberTrackArray } from '../../../../utils/multiples';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { DIV, MULT } from '../../../../constants';
import { getRandomObject, objectSchema } from '../../../../utils/objects';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { getObjectImage } from '../../../../utils/objectsImages';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'akT',
  description: 'akT',
  keywords: ['Times-table', '8', 'Object', 'Multiply'],
  schema: z.object({
    numberPerGroup: z.number().int().min(2).max(6),
    object: objectSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    return { numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, object },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        title={translate.instructions.whatMultIsShown()}
        testCorrect={answer =>
          ((answer[0] === '8' && answer[1] === numberPerGroup.toString()) ||
            (answer[0] === numberPerGroup.toString() && answer[1] === '8')) &&
          answer[2] === (numberPerGroup * 8).toString()
        }
        questionHeight={900}
        pdfDirection="column"
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }
            ]}
          >
            {range(1, 8).map(index => (
              <View key={index}>
                {getObjectImage(object, numberPerGroup, dimens.height / 2, dimens.width / 4.2)}
              </View>
            ))}
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            numberPerGroup.toString(),
            (8).toLocaleString(),
            (numberPerGroup * 8).toString()
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'akU',
  description: 'akU',
  keywords: ['Times-table', '8', 'Array', 'Multiply', 'Fact family'],
  schema: z.object({
    columns: z.number().int().min(2).max(6)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(2, 6);

    return { columns };
  },
  Component: props => {
    const {
      question: { columns },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.writeTwoMultAndTwoDivFactsShownByArray()}
        testCorrect={answer =>
          ((answer[0][0] === columns.toString() &&
            answer[0][1] === '8' &&
            answer[1][0] === '8' &&
            answer[1][1] === columns.toString()) ||
            (answer[0][0] === '8' &&
              answer[0][1] === columns.toString() &&
              answer[1][0] === columns.toString() &&
              answer[1][1] === '8')) &&
          answer[0][2] === (8 * columns).toString() &&
          answer[1][2] === (8 * columns).toString() &&
          ((answer[2][1] === columns.toString() &&
            answer[2][2] === '8' &&
            answer[3][1] === '8' &&
            answer[3][2] === columns.toString()) ||
            (answer[2][1] === '8' &&
              answer[2][2] === columns.toString() &&
              answer[3][1] === columns.toString() &&
              answer[3][2] === '8')) &&
          answer[2][0] === (8 * columns).toString() &&
          answer[3][0] === (8 * columns).toString()
        }
        inputMaxCharacters={1}
        {...props}
        Content={({ dimens }) => <ArrayOfObjects rows={8} columns={columns} dimens={dimens} />}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [columns.toLocaleString(), (8).toLocaleString(), (8 * columns).toLocaleString()],
            [(8).toLocaleString(), columns.toLocaleString(), (8 * columns).toLocaleString()],
            [(8 * columns).toLocaleString(), columns.toLocaleString(), (8).toLocaleString()],
            [(8 * columns).toLocaleString(), (8).toLocaleString(), columns.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'akV',
  description: 'akV',
  keywords: ['Times-table', '8', 'Track'],
  schema: z.object({
    interval: numberEnum([-8, 8]),
    startingNumber: z.number().int().min(8).max(96).multipleOf(8),
    tileToShow: z.number().int().min(2).max(7)
  }),
  simpleGenerator: () => {
    const interval = getRandomFromArray([-8, 8] as const);
    const startingNumber =
      interval === -8
        ? randomIntegerInclusiveStep(64, 96, 8)
        : randomIntegerInclusiveStep(8, 40, 8);
    const tileToShow = randomIntegerInclusive(2, 7);
    return { interval, startingNumber, tileToShow };
  },
  Component: ({ question: { interval, startingNumber, tileToShow }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(
      startingNumber,
      interval,
      6,
      tileToShow
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'akW',
  description: 'akW',
  keywords: ['Times-table', '8', 'Fact family', 'Inverse', 'Multiply', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 8, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 8, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ result: numberC1, right: 8, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ result: numberD1, right: 8, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'akX',
  description: 'akX',
  keywords: ['Times-table', '8', 'Bar model'],
  schema: z.object({
    parts: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const parts = randomIntegerInclusive(2, 10);

    return { parts };
  },
  Component: ({ question: { parts }, translate }) => {
    const answerIndices = [[0], []];

    const barArray = filledArray(8, parts);

    const numbers = [[parts * 8], barArray];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={parts * 8}
        oneFontSize
        sameRowColor
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'akY',
  description: 'akY',
  keywords: ['Times-table', '8', 'Given one find other'],
  schema: z.object({
    numberA1: z.number().int().min(3).max(12),
    numberB1Relative: z.union([z.literal(-1), z.literal(1)])
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(3, 12);

    const numberB1Relative = getRandomFromArray([-1, 1]) as -1 | 1;

    return { numberA1, numberB1Relative };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1Relative },
      translate
    } = props;

    const numberB1 = numberA1 + numberB1Relative;
    const numberC1 = numberA1 + 10;

    const eqB = getBinOpEquation({ left: numberB1, right: 8, sign: 'multiply', answer: 'result' });

    const eqC = getBinOpEquation({ left: numberC1, right: 8, sign: 'multiply', answer: 'result' });

    const eqs = [eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.useTheFactThatXToCompleteCalcs(
          `${numberA1} ${MULT} ${(8).toLocaleString()} = ${(numberA1 * 8).toLocaleString()}`
        )}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The8TimesTable',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
