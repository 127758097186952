import { newSmallStepContent } from '../../../SmallStep';
import {
  amr,
  ams,
  amt,
  amu,
  amu2,
  amv
} from '../../../Year 5/Autumn/MultiplicationAndDivisionA/2CommonMultiples';
import { z } from 'zod';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import {
  getRandomBoolean,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { findCommonMultiples } from '../../../../utils/multiples';

////
// Questions
////

const Question1 = { ...amr, uid: 'anp', description: 'anp' as const };

const Question2 = { ...ams, uid: 'anq', description: 'anq' as const };

const Question3 = { ...amt, uid: 'anr', description: 'anr' as const };

const Question4v2 = { ...amu2, uid: 'ans2', description: 'ans' as const };

const Question4 = { ...amu, uid: 'ans', description: 'ans' as const };

const Question5 = { ...amv, uid: 'ant', description: 'ant' as const };

const Question6 = newQuestionContent({
  uid: 'anu',
  description: 'anu',
  keywords: ['Multiple', 'Common'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(12),
      number2: z.number().int().min(6).max(12),
      correctArrayNums: z.array(z.number().int()).length(4),
      incorrectArrayNums: z.array(z.number().int().min(19).max(100)).length(4),
      isSixOptions: z.boolean()
    })
    .refine(val => val.number1 !== val.number2, 'number1 and number2 cannot be the same'),
  simpleGenerator: () => {
    const isSixOptions = getRandomBoolean();

    const { number1, number2, correctArrayNums } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 12);
        const number2 = randomIntegerInclusive(6, 12, {
          constraint: x => x !== number1
        });
        const correctArrayNums = findCommonMultiples(number1, number2, 4);
        return { number1, number2, correctArrayNums };
      },
      ({ correctArrayNums }) => correctArrayNums.every(number => number < 100)
    );

    // Not a common multiple of number1 and number2
    const incorrectArrayNums = randomUniqueIntegersInclusive(19, 99, 4, {
      constraint: x => x % number1 !== 0 || x % number2 !== 0
    });

    return {
      number1,
      number2,
      correctArrayNums,
      incorrectArrayNums,
      isSixOptions
    };
  },

  Component: props => {
    const {
      question: { number1, number2, correctArrayNums, incorrectArrayNums, isSixOptions },
      translate
    } = props;

    const renderNumbers = shuffle(
      [
        ...correctArrayNums,
        ...getRandomSubArrayFromArray(incorrectArrayNums, isSixOptions ? 2 : 4, {
          random: seededRandom(props.question)
        })
      ],
      {
        random: seededRandom(props.question)
      }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectCommonMultiples(number1, number2)}
        testCorrect={correctArrayNums}
        multiSelect
        items={renderNumbers.map((number: number) => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CommonMultiples',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
