import { StyleSheet, View } from 'react-native';
import { Dimens, containAspectRatio } from '../../../theme/scaling';
import { useContext, useMemo } from 'react';
import { getRandomFromArray, seededRandom } from '../../../utils/random';
import { colors, digitalClockColors } from '../../../theme/colors';
import AutoScaleText from '../../typography/AutoScaleText';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { SetState } from '../../../utils/react';
import { DisplayMode } from '../../../contexts/displayMode';
import { withStateHOC } from '../../../stateTree';

type UserAnswer = string;

type Props = TitleStyleProps & {
  userAnswer?: UserAnswer[];
  setUserAnswer?: SetState<UserAnswer[]>;
  hours: number | string;
  minutes: number | string;
  /**
   * Whether to show 'AM', 'PM', or nothing after hours:minutes in the digital clock.
   */
  amOrPm?: 'am' | 'pm';
  /**
   * Color of the clock. Optional prop, defaults to randomly picking a color from the digitalClockColors object.
   */
  color?: string;
  /** Dimens */
  dimens: Dimens;
};

export const DigitalClock = ({
  userAnswer = [],
  setUserAnswer = () => {},
  hours,
  minutes,
  amOrPm,
  color,
  dimens
}: Props) => {
  const displayMode = useContext(DisplayMode);

  const { width: digitalClockWidth, height: digitalClockHeight } = containAspectRatio(
    dimens,
    2.62420382166
  );

  // This is the approximate proportion of the outer colored width as a proportion of the entire clock's height.
  const outerColorWidth = Math.floor(digitalClockHeight / 14.66667);

  const colorToUse =
    color ??
    getRandomFromArray(Object.values(digitalClockColors), {
      random: seededRandom({ hours, minutes, amOrPm })
    })!;

  const styles = useStyles(
    displayMode,
    digitalClockWidth,
    digitalClockHeight,
    outerColorWidth,
    colorToUse
  );

  const hoursString = typeof hours === 'number' ? hours.toLocaleString() : hours;
  // When displaying the minutes, always use at least 2 digits. I.e. 1:02, not 1:2
  const minutesString = typeof minutes === 'number' ? minutes.toString().padStart(2, '0') : minutes;

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.numberContainer}>
          {hours === '<ans/>' ? (
            <NoKeyboardTextInput
              value={userAnswer[0]}
              onChangeText={text => {
                const newState = [...userAnswer];
                newState[0] = text;
                setUserAnswer(newState);
              }}
              style={styles.input}
            />
          ) : (
            <AutoScaleText
              variant="WRN400"
              textStyle={displayMode === 'digital' ? styles.numberText : styles.numberTextPDF}
              containerStyle={{
                height: digitalClockHeight,
                maxWidth:
                  hoursString.length === 2 ? digitalClockWidth * 0.3 : digitalClockWidth * 0.23,
                flex: 1,
                alignItems: 'flex-end'
              }}
              group={'numbers'}
              letterEmWidth={0.5}
              maxLines={1}
            >
              {hoursString}
            </AutoScaleText>
          )}
          <AutoScaleText
            variant="WRN400"
            textStyle={displayMode === 'digital' ? styles.numberText : styles.numberTextPDF}
            containerStyle={{
              height: digitalClockHeight,
              maxWidth: digitalClockWidth / 10,
              flex: 1,
              alignItems: 'center',
              bottom: hours === '<ans/>' || minutes === '<ans/>' ? 16 : undefined
            }}
            group={'numbers'}
            letterEmWidth={0.5}
            maxLines={1}
          >
            {`:`}
          </AutoScaleText>
          {minutes === '<ans/>' ? (
            <NoKeyboardTextInput
              value={userAnswer[hours === '<ans/>' ? 1 : 0]}
              onChangeText={text => {
                const newState = [...userAnswer];
                newState[hours === '<ans/>' ? 1 : 0] = text;
                setUserAnswer(newState);
              }}
              style={styles.input}
            />
          ) : (
            <AutoScaleText
              variant="WRN400"
              textStyle={displayMode === 'digital' ? styles.numberText : styles.numberTextPDF}
              containerStyle={{
                height: digitalClockHeight,
                width: digitalClockWidth * 0.3,
                flex: 1,
                alignItems: 'flex-start'
              }}
              group={'numbers'}
              letterEmWidth={0.5}
              maxLines={1}
            >
              {minutesString}
            </AutoScaleText>
          )}
        </View>

        {amOrPm && (
          <View style={styles.amPmContainer}>
            <AutoScaleText
              variant="WRN400"
              textStyle={displayMode === 'digital' ? styles.amPmText : styles.amPmTextPDF}
              containerStyle={{
                height: digitalClockHeight / 2,
                width: digitalClockWidth / 5,
                marginLeft: minutes === '<ans/>' ? 32 : 0
              }}
              group={'amOrPm'}
              letterEmWidth={0.5}
              maxLines={1}
            >
              {amOrPm}
            </AutoScaleText>
          </View>
        )}
      </View>
    </View>
  );
};

export const DigitalClockWithState = withStateHOC(DigitalClock, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer'
});

function useStyles(
  displayMode: 'markscheme' | 'digital' | 'pdf',
  digitalClockWidth: number,
  digitalClockHeight: number,
  outerColorWidth: number,
  colorToUse: string
) {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          alignItems: 'center',
          backgroundColor: colorToUse,
          borderColor: 'black',
          borderWidth: displayMode !== 'digital' ? 4 : 2,
          borderRadius: digitalClockHeight / 6,
          width: digitalClockWidth,
          height: digitalClockHeight,
          flexDirection: 'row',
          justifyContent: 'center'
        },
        innerContainer: {
          backgroundColor: colors.digitalClockBackground,
          width: digitalClockWidth - outerColorWidth,
          height: digitalClockHeight - outerColorWidth,
          borderColor: 'black',
          borderWidth: 2,
          borderRadius: (digitalClockHeight - outerColorWidth) / 6,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        },
        numberText: {
          fontSize: 180,
          lineHeight: 186
        },
        numberTextPDF: {
          fontSize: 110,
          lineHeight: 165
        },
        amPmText: {
          fontSize: 108,
          lineHeight: 112,
          position: 'relative',
          textTransform: 'uppercase'
        },
        amPmTextPDF: {
          fontSize: 88,
          lineHeight: 112,
          position: 'relative',
          textTransform: 'uppercase'
        },
        numberContainer: {
          height: (digitalClockHeight - outerColorWidth) / 2,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          width: (digitalClockWidth / 3) * 2
        },
        amPmContainer: {
          height: (digitalClockHeight - outerColorWidth) / 2,
          justifyContent: 'flex-end'
        },
        input: {
          maxWidth: digitalClockWidth * 0.3,
          height: digitalClockHeight / 2,
          flex: 1,
          fontSize: digitalClockHeight * 0.4,
          lineHeight: digitalClockHeight / 2
        }
      }),
    [colorToUse, digitalClockHeight, digitalClockWidth, displayMode, outerColorWidth]
  );
}
