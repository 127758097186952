import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg } from '../../../../assets/svg';
import { get3DShapeFullColorsSVGPath, shapeInfo3D } from '../../../../utils/threeDShapes';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import deepEqual from 'react-fast-compare';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const curvedShapes3d = ['Cone', 'Cylinder', 'Sphere'] as const;

const pyramidShapes3d = ['Square_pyramid', 'Triangle_pyramid'] as const;

const nonCurvedShapes3d = [
  'Cube',
  'Cuboid',
  'Hexagonal_prism',
  'Pentagonal_prism',
  ...pyramidShapes3d,
  'Triangular_prism'
] as const;

const shapes3d = [...curvedShapes3d, ...nonCurvedShapes3d] as const;

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bh0',
  description: 'bh0',
  keywords: ['3-D shape', 'Vertices'],
  schema: z.object({
    shape: z.enum(nonCurvedShapes3d),
    rotation: numberEnum([0, 90, 270])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(nonCurvedShapes3d);

    const rotation = getRandomFromArray([0, 90, 270] as const);

    return { shape, rotation };
  },
  Component: ({ question: { shape, rotation }, translate }) => {
    const vertices = shapeInfo3D[shape].vertices;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyVerticesDoesTheShapeHave()}
        Content={({ dimens }) => (
          <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
            <AssetSvg
              name={get3DShapeFullColorsSVGPath('transparent', shape)}
              width={rotation === 90 || rotation === 270 ? dimens.height * 0.9 : dimens.width * 0.9}
              height={
                rotation === 90 || rotation === 270 ? dimens.width * 0.9 : dimens.height * 0.9
              }
            />
          </View>
        )}
        sentence="<ans/>"
        testCorrect={[vertices.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bh1',
  description: 'bh1',
  keywords: ['Face', '3-D shape', 'Vertices'],
  schema: z
    .object({
      selectedShape: z.enum(nonCurvedShapes3d),
      shapes: z
        .array(
          z.object({
            shape: z.enum(nonCurvedShapes3d),
            rotation: numberEnum([0, 90, 270]),
            scale: numberEnum([1, 0.85])
          })
        )
        .length(4)
        .refine(
          shapes => arrayHasNoDuplicates(shapes, deepEqual),
          'shapes must not have duplicates'
        )
    })
    .refine(
      val => val.shapes.some(shapeObj => shapeObj.shape === val.selectedShape),
      'shapes must include selectedShape.'
    )
    .refine(
      val =>
        val.shapes.filter(
          shape => shapeInfo3D[shape.shape].vertices === shapeInfo3D[val.selectedShape].vertices
        ).length === 1,
      'Only selectedShape can have the correct number of vertices.'
    ),
  simpleGenerator: () => {
    const { selectedShape, otherShapeA, otherShapeB, otherShapeC } = rejectionSample(
      () => {
        const [selectedShape, otherShapeA, otherShapeB, otherShapeC] = getRandomSubArrayFromArray(
          nonCurvedShapes3d,
          4
        );
        return { selectedShape, otherShapeA, otherShapeB, otherShapeC };
      },
      ({ selectedShape, otherShapeA, otherShapeB, otherShapeC }) => {
        return [otherShapeA, otherShapeB, otherShapeC].every(
          shape => shapeInfo3D[shape].vertices !== shapeInfo3D[selectedShape].vertices
        );
      }
    );

    const shapes = shuffle([
      {
        shape: selectedShape,
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapeA,
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapeB,
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      },
      {
        shape: otherShapeC,
        rotation: getRandomFromArray([0, 90, 270] as const),
        scale: getRandomFromArray([1, 0.85] as const)
      }
    ]);

    return { selectedShape, shapes };
  },
  Component: ({ question: { selectedShape, shapes }, translate }) => {
    const vertices = shapeInfo3D[selectedShape].vertices;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheShapeWithNumVertices(vertices)}
        pdfTitle={translate.ks1PDFInstructions.circleTheShapeWithNumVertices(vertices)}
        testCorrect={[selectedShape]}
        numItems={4}
        renderItems={({ dimens }) =>
          shapes.map(({ shape, rotation, scale }) => {
            return {
              component: (
                <View style={{ transform: [{ rotate: `${rotation}deg` }, { scale: scale }] }}>
                  <AssetSvg
                    name={get3DShapeFullColorsSVGPath('transparent', shape)}
                    width={
                      rotation === 90 || rotation === 270 ? dimens.height * 0.9 : dimens.width * 0.9
                    }
                    height={
                      rotation === 90 || rotation === 270 ? dimens.width * 0.9 : dimens.height * 0.9
                    }
                  />
                </View>
              ),
              value: shape
            };
          })
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q3Colors = [
  'blue',
  'green',
  'orange',
  'pink',
  'purple',
  'red',
  'transparent',
  'yellow'
] as const;

const Question3 = newQuestionContent({
  uid: 'bh2',
  description: 'bh2',
  keywords: ['Vertex', 'Vertices', 'Side', 'Sides', '3-D shape'],
  schema: z.object({
    ordering: z.enum(['ascending', 'descending']),
    color: z.enum(q3Colors),
    shapes: z
      .array(z.enum(shapes3d))
      .length(4)
      .refine(
        shapes => arrayHasNoDuplicates(shapes.map(shape => shapeInfo3D[shape].vertices)),
        'All shapes must have different numbers of vertices.'
      )
  }),
  simpleGenerator: () => {
    const { shapes } = rejectionSample(
      () => {
        const shapes = getRandomSubArrayFromArray(shapes3d, 4);
        return { shapes };
      },
      ({ shapes }) => {
        return (
          arrayHasNoDuplicates(shapes.map(shape => shapeInfo3D[shape].vertices)) &&
          // Only allow up to one, not both, of the pyramid shapes to be included:
          pyramidShapes3d.filter(pyramid => shapes.includes(pyramid)).length <= 1
        );
      }
    );

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const color = getRandomFromArray(q3Colors);

    return { shapes, ordering, color };
  },
  Component: props => {
    const {
      question: { shapes, ordering, color },
      translate,
      displayMode
    } = props;

    const correctOrder = sortNumberArray(
      shapes.map(shape => shapeInfo3D[shape].vertices),
      ordering
    );

    return (
      <QF5DragOrderHorizontal
        title={
          ordering === 'ascending'
            ? translate.ks1Instructions.dragTheCardsToSortTheShapesInOrderOfTheNumberOfVerticesStartWithTheFewestNumberOfVertices()
            : translate.ks1Instructions.dragTheCardsToSortTheShapesInOrderOfTheNumberOfVerticesStartWithTheMostNumberOfVertices()
        }
        pdfTitle={
          ordering === 'ascending'
            ? translate.ks1PDFInstructions.sortTheShapesInOrderOfTheNumberOfVerticesStartWithTheFewestNumberOfVertices()
            : translate.ks1PDFInstructions.sortTheShapesInOrderOfTheNumberOfVerticesStartWithTheMostNumberOfVertices()
        }
        labelsPosition="bottom"
        testCorrect={correctOrder}
        pdfItemVariant="largeSquare"
        items={shapes.map((shape, index) => {
          return {
            component: (
              <>
                <MeasureView key={shape}>
                  {dimens => (
                    <AssetSvg
                      name={get3DShapeFullColorsSVGPath(color, shape)}
                      width={dimens.width * 0.9}
                      height={dimens.height * 0.9}
                    />
                  )}
                </MeasureView>
                {displayMode !== 'digital' && (
                  // This returns the strings 'A', 'B', 'C' and 'D', for indexes 0 to 3, needed to label the cards on PDF:
                  <Text variant="WRN700">{String.fromCharCode(index + 1 + 64)}</Text>
                )}
              </>
            ),
            value: shapeInfo3D[shape].vertices
          };
        })}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Fewest() : translate.keywords.Most()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Most() : translate.keywords.Fewest()
        }
        moveOrCopy="move"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountVerticesOn3DShapes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
