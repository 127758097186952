import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { nameSchema, getRandomName } from '../../../../utils/names';
import { DIV, MULT } from '../../../../constants';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { numberToBase10Object } from '../../../../utils/math';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { useMemo } from 'react';
import { placeSchema } from '../../../../utils/places';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ContentBox from '../../../../components/molecules/ContentBox';
import { MarkupAssets } from '../../../../markup';
import { AssetSvg } from '../../../../assets/svg';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import Division from '../../../../components/question/representations/ColumnOperations/Division';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'apK',
  description: 'apK',
  keywords: ['Multiples', 'Divide', 'Long division', 'Written method'],
  schema: z
    .object({
      divisor: z.number().int().min(13).max(19),
      number: z.number().int().min(101).max(999)
    })
    .refine(val => val.number % val.divisor === 0, 'number must be a multiple of divisor'),
  questionHeight: 1700,
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(13, 19);

    const number = randomIntegerInclusive(101, 999, {
      // Quotient must be a whole number, and quotient must not be a multiple of 10:
      constraint: x => x % divisor === 0 && (x / divisor) % 10 !== 0
    });

    return { divisor, number };
  },
  Component: props => {
    const {
      question: { divisor, number },
      translate,
      displayMode
    } = props;

    const answer = number / divisor;

    return (
      <QF1ContentAndSentence
        pdfHideSentence
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.useMultsOfNumToWorkOutDivision(divisor)}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => {
          const divisionHeight = (dimens.height / 6) * 5;
          return (
            <>
              <View style={{ flexDirection: 'row', marginBottom: 8 }}>
                {range(1, 7).map(num => (
                  <ContentBox
                    key={num}
                    containerStyle={{ width: dimens.width / 10, marginHorizontal: 4, padding: 8 }}
                  >
                    <Text
                      variant="WRN400"
                      style={{
                        fontSize: displayMode === 'digital' ? 28 : 50
                      }}
                    >
                      {(divisor * num).toLocaleString()}
                    </Text>
                  </ContentBox>
                ))}
              </View>
              <Division
                divisor={divisor}
                dividend={number}
                dimens={{ width: dimens.width, height: divisionHeight }}
                extraRows={4}
                labelCellFontStyle={{
                  fontSize: displayMode === 'digital' ? 28 : 50,
                  lineHeight: displayMode === 'digital' ? 36 : 75
                }}
                noExchangeBoxes
              />
            </>
          );
        }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.validDivisionWithAnsX(answer) }}
        questionHeight={1700}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'arH',
  description: 'arH',
  keywords: ['Divide', 'Long division', 'Written method'],
  schema: z
    .object({
      divisor: z.number().int().min(21).max(29),
      number: z.number().int().min(401).max(999)
    })
    .refine(val => val.number % val.divisor === 0, 'number must be a multiple of divisor'),
  questionHeight: 1440,
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(21, 29);

    const number = randomIntegerInclusive(401, 999, {
      constraint: x => x % divisor === 0
    });

    return { divisor, number };
  },
  Component: props => {
    const {
      question: { divisor, number },
      translate,
      displayMode
    } = props;

    const answer = number / divisor;

    return (
      <QF1ContentAndSentence
        pdfHideSentence
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        title={translate.instructions.workOutTheDivision()}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => (
          <Division
            divisor={divisor}
            dividend={number}
            dimens={dimens}
            extraRows={4}
            noExchangeBoxes
            labelCellFontStyle={{
              fontSize: displayMode === 'digital' ? 28 : 50,
              lineHeight: displayMode === 'digital' ? 36 : 75
            }}
          />
        )}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.validDivisionWithAnsX(answer) }}
        questionHeight={1440}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'arI',
  description: 'arI',
  keywords: ['Divide', 'Long division', 'Written method'],
  schema: z
    .object({
      length: z.number().int().min(60).max(125),
      width: z.number().int().min(59).max(79),
      place: placeSchema
    })
    .refine(val => val.width < val.length, 'width must be less than length.'),
  questionHeight: 900,
  simpleGenerator: () => {
    const length = randomIntegerInclusive(60, 125);

    const width = randomIntegerInclusive(59, 79, {
      constraint: x => x < length
    });

    // TODO: Add rest of the places inside places.ts once images are available
    // We do not have any other svgs for launch but may get more post launch so leaving the TODO in
    const place = 'Park' as const;

    return { length, width, place };
  },
  Component: props => {
    const {
      question: { length, width, place },
      displayMode,
      translate
    } = props;

    const area = length * width;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.areaEqualsNumM2(area),
          translate.answerSentences.wEqualsAnsM()
        ]}
        sentenceStyle={{ justifyContent: 'space-between' }}
        pdfSentenceStyle={{ justifyContent: 'center' }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        title={translate.instructions.whatIsTheWidthOfThePlace(place)}
        testCorrect={userAnswer => userAnswer[1][0] === width.toString()}
        inputMaxCharacters={2}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [[''], [width.toLocaleString()]]
        }}
        Content={({ dimens }) => (
          <View style={{ position: 'relative' }}>
            <Text
              variant="WRN400"
              style={{
                fontSize: displayMode === 'digital' ? 28 : 48,
                lineHeight: displayMode === 'digital' ? 36 : 56,
                textAlign: 'center'
              }}
            >
              {translate.units.numberOfM(length)}
            </Text>
            <TextStructure
              textVariant="WRN400"
              style={{
                position: 'absolute',
                left: displayMode === 'digital' ? -50 : -80,
                top: displayMode === 'digital' ? dimens.height / 2 : dimens.height / 2 - 48
              }}
              textStyle={{
                fontSize: displayMode === 'digital' ? 28 : 48,
                lineHeight: displayMode === 'digital' ? 36 : 56
              }}
              sentence={translate.answerSentences.wEquals()}
            />
            <AssetSvg
              name="park_birds_eye_view_with_arrows"
              width={displayMode === 'digital' ? 460 : 800}
            />
          </View>
        )}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'arI2',
  description: 'arI2',
  keywords: ['Divide', 'Long division', 'Written method'],
  schema: z
    .object({
      place: z.enum(['Park', 'Playground']),
      length: z.number().int().min(21).max(125),
      width: z.number().int().min(21).max(79),
      answerToFind: z.enum(['length', 'width'])
    })
    .refine(val => val.length >= val.width, 'length must be greater than or equal to width.'),
  questionHeight: 900,
  simpleGenerator: () => {
    const place = getRandomFromArray(['Park', 'Playground'] as const);

    const [lengthLowerBound, lengthUpperBound] = place === 'Park' ? [59, 125] : [21, 79];

    const length = randomIntegerInclusive(lengthLowerBound, lengthUpperBound);

    const [widthLowerBound, widthUpperBound] =
      place === 'Park' ? [59, Math.min(79, length)] : [21, Math.min(39, length)];

    const width = randomIntegerInclusive(widthLowerBound, widthUpperBound, {
      constraint: x => x <= length
    });

    // If length is 3-digits, this must be set as the answer to find - otherwise, randomly select between length and width:
    const answerToFind =
      length >= 100 ? 'length' : getRandomFromArray(['length', 'width'] as const);

    return { place, length, width, answerToFind };
  },
  Component: props => {
    const {
      question: { place, length, width, answerToFind },
      displayMode,
      translate
    } = props;

    const area = length * width;

    const placeToSvgName =
      place === 'Park'
        ? 'park_birds_eye_view_with_arrows'
        : 'Playground_birds_eye_view_with_arrows';

    const placeToString =
      place === 'Park' ? translate.places.park() : translate.places.playground();

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.areaEqualsNumM2(area),
          answerToFind === 'length'
            ? translate.answerSentences.lEqualsAnsM()
            : translate.answerSentences.wEqualsAnsM()
        ]}
        sentenceStyle={{ justifyContent: 'space-between' }}
        pdfSentenceStyle={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        pdfDirection="column"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        title={
          answerToFind === 'length'
            ? translate.instructions.whatIsTheLengthOfThePlace(placeToString)
            : translate.instructions.whatIsTheWidthOfThePlace(placeToString)
        }
        testCorrect={[[], [answerToFind === 'length' ? length.toString() : width.toString()]]}
        inputMaxCharacters={3}
        questionHeight={900}
        Content={({ dimens }) => {
          const labelTopPosition =
            place === 'Park'
              ? displayMode === 'digital'
                ? dimens.height / 2
                : dimens.height / 2 + 72
              : displayMode === 'digital'
              ? dimens.height / 2 - 24
              : dimens.height / 2 + 36;

          const labelLeftPosition =
            answerToFind === 'length'
              ? displayMode === 'digital'
                ? -72
                : -120
              : displayMode === 'digital'
              ? -30
              : -50;

          return (
            <View style={{ position: 'relative' }}>
              <TextStructure
                textVariant="WRN400"
                textStyle={{
                  fontSize: displayMode === 'digital' ? 28 : 48,
                  lineHeight: displayMode === 'digital' ? 36 : 56,
                  textAlign: 'center'
                }}
                sentence={
                  answerToFind === 'length' ? '<b>l</b>' : translate.units.numberOfM(length)
                }
              />
              <TextStructure
                textVariant="WRN400"
                style={{
                  position: 'absolute',
                  left: labelLeftPosition,
                  top: labelTopPosition
                }}
                textStyle={{
                  fontSize: displayMode === 'digital' ? 28 : 48,
                  lineHeight: displayMode === 'digital' ? 36 : 56
                }}
                sentence={answerToFind === 'width' ? '<b>w</b>' : translate.units.numberOfM(width)}
              />
              <AssetSvg name={placeToSvgName} width={displayMode === 'digital' ? 460 : 800} />
            </View>
          );
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'arJ',
  description: 'arJ',
  keywords: ['Divide', 'Long division', 'Written method'],
  schema: z
    .object({
      answerAAndB: z.number().int().min(40).max(499),
      number2: z
        .number()
        .int()
        .min(21)
        .max(49)
        .refine(val => val % 10 !== 0, 'number2 must not be a multiple of 10'),
      number4: z
        .number()
        .int()
        .min(21)
        .max(49)
        .refine(val => val % 10 !== 0, 'number4 must not be a multiple of 10'),
      answerC: z.number().int().min(40).max(499),
      number6: z
        .number()
        .int()
        .min(21)
        .max(49)
        .refine(val => val % 10 !== 0, 'number6 must not be a multiple of 10'),
      number7: z
        .number()
        .int()
        .min(21)
        .max(49)
        .refine(val => val % 10 !== 0, 'number7 must not be a multiple of 10'),
      answerD: z.number().int().min(40).max(499)
    })

    .refine(
      val => arrayHasNoDuplicates([val.answerAAndB, val.answerC, val.answerD]),
      'answerAAndB, answerC and answerD must be different.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.number2, val.number4, val.number6]),
      'number2, number4 and number6 must all be different.'
    ),
  simpleGenerator: () => {
    // Generate number2, number4 and answerAAndB
    const { number2, number4, answerAAndB } = rejectionSample(
      () => {
        const [number2, number4] = randomUniqueIntegersInclusive(21, 49, 2, {
          constraint: x => x % 10 !== 0
        });

        const answerAAndB = randomIntegerInclusive(40, 499);

        return { number2, number4, answerAAndB };
      },
      ({ number2, number4, answerAAndB }) => {
        const number1 = number2 * answerAAndB;

        const number1Thousands = numberToBase10Object(number1).thousands;

        const number3 = number4 * answerAAndB;

        const number3Thousands = numberToBase10Object(number3).thousands;

        return (
          answerAAndB * number2 > 2000 &&
          answerAAndB * number2 < 10000 &&
          answerAAndB * number4 > 2000 &&
          answerAAndB * number4 < 10000 &&
          number1Thousands === number3Thousands
        );
      }
    );

    let answerD: number;
    if (answerAAndB - 25 <= 40) {
      answerD = answerAAndB + 25;
    } else if (answerAAndB + 25 >= 499) {
      answerD = answerAAndB - 25;
    } else {
      answerD = getRandomBoolean() ? answerAAndB + 25 : answerAAndB - 25;
    }

    const number7 = randomIntegerInclusive(21, 49, {
      constraint: x => x % 10 !== 0
    });

    // Generate number6 and answerC
    const { number6, answerC } = rejectionSample(
      () => {
        const number6 = randomIntegerInclusive(21, 49, {
          constraint: x => x % 10 !== 0 && x !== number2 && x !== number4
        });

        const answerC = randomIntegerInclusive(40, 499, {
          constraint: x => {
            const number1 = number2 * answerAAndB;

            const number1Thousands = numberToBase10Object(number1).thousands;

            const number5 = x * number6;
            const number5Thousands = numberToBase10Object(number5).thousands;

            return (
              x * number6 > 2000 &&
              x * number6 < 10000 &&
              x !== answerAAndB &&
              number1Thousands === number5Thousands &&
              answerD !== x
            );
          }
        });

        return { number6, answerC };
      },
      ({ number6, answerC }) => answerC * number6 > 2000 && answerC * number6 < 10000
    );

    return { number2, number4, number6, number7, answerAAndB, answerC, answerD };
  },
  Component: props => {
    const {
      question: { number2, number4, number6, number7, answerAAndB, answerC, answerD },
      translate
    } = props;

    // Randomly order these equations
    const eqs = useMemo(() => {
      const number1 = answerAAndB * number2;
      const number3 = answerAAndB * number4;
      const number5 = answerC * number6;
      const number8 = answerD * number7;

      const eqA = {
        sentence: `${number1.toLocaleString()} ${DIV} ${number2.toLocaleString()}`,
        isCorrect: true
      };
      const eqB = {
        sentence: `${number3.toLocaleString()} ${DIV} ${number4.toLocaleString()}`,
        isCorrect: true
      };
      const eqC = {
        sentence: `${number5.toLocaleString()} ${DIV} ${number6.toLocaleString()}`,
        isCorrect: false
      };
      const eqD = {
        sentence: `${number8.toLocaleString()} ${DIV} ${number7.toLocaleString()}`,
        isCorrect: false
      };
      return shuffle([eqA, eqB, eqC, eqD], { random: seededRandom(props.question) });
    }, [answerAAndB, answerC, number2, number4, number6, number7, answerD, props.question]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectTheCalcsThatGiveSameAnswer()}
        pdfTitle={translate.instructions.circleTheCalcsThatGiveSameAnswer()}
        testCorrect={eqs.filter(eq => eq.isCorrect).map(eq => eq.sentence)}
        numItems={4}
        renderItems={() => {
          return eqs.map(eq => ({
            value: eq.sentence,
            component: <Text variant="WRN700">{eq.sentence}</Text>
          }));
        }}
        multiSelect
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'arK',
  description: 'arK',
  keywords: ['Divide', 'Long division', 'Written method'],
  schema: z
    .object({
      name: nameSchema,
      starsPerFlag: z
        .number()
        .int()
        .min(13)
        .max(49)
        .refine(val => val % 10 !== 0, 'starsPerFlag must not be a multiple of 10'),
      heartsPerFlag: z
        .number()
        .int()
        .min(13)
        .max(49)
        .refine(val => val % 10 !== 0, 'starsPerFlag must not be a multiple of 10'),
      stars: z.number().int().min(101).max(999),
      hearts: z.number().int().min(101).max(999)
    })
    .refine(val => val.stars % val.starsPerFlag === 0, 'stars must be a multiple of starsPerFlag')
    .refine(
      val => val.hearts % val.heartsPerFlag === 0,
      'hearts must be a multiple of heartsPerFlag'
    ),
  simpleGenerator: () => {
    const name = getRandomName();

    const starsPerFlag = randomIntegerInclusive(13, 49, {
      constraint: x => x % 10 !== 0
    });

    const heartsPerFlag = randomIntegerInclusive(13, 49, {
      constraint: x => x % 10 !== 0
    });

    const stars = randomIntegerInclusive(101, 999, {
      constraint: x => x % starsPerFlag === 0
    });

    const hearts = randomIntegerInclusive(101, 999, {
      constraint: x => x % heartsPerFlag === 0
    });

    return { name, starsPerFlag, heartsPerFlag, stars, hearts };
  },
  Component: props => {
    const {
      question: { name, starsPerFlag, heartsPerFlag, stars, hearts },
      translate,
      displayMode
    } = props;

    const flags = Math.min(stars / starsPerFlag, hearts / heartsPerFlag);

    const title = `${translate.instructions.characterIsMakingFlags(
      name
    )}<br/>${translate.answerSentences.characterSewsNumStarsAndNumHearts(
      name,
      starsPerFlag,
      heartsPerFlag
    )}<br/>${translate.answerSentences.characterHasNumStarsAndNumHearts(
      name,
      stars,
      hearts
    )}<br/>${translate.answerSentences.howManyFlagsCanCharacterComplete(name)}`;

    return (
      <QF2AnswerBoxManySentences
        title={title}
        testCorrect={[[flags.toString()]]}
        sentences={['<ans/>']}
        mainPanelContainerStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        titleStyle={{ alignSelf: 'flex-start' }}
        textStyle={displayMode === 'digital' ? { fontSize: 32 } : undefined}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'arL',
  description: 'arL',
  keywords: ['Divide', 'Long division', 'Written method'],
  schema: z
    .object({
      number1: z.number().int().min(13).max(99),
      number2: z.number().int().min(1001).max(9999),
      number3: z.number().int().min(1001).max(9999),
      number4: z.number().int().min(13).max(99)
    })
    .refine(val => val.number2 % val.number1 === 0, 'number2 must be a multiple of number1')
    .refine(val => val.number3 % val.number4 === 0, 'number3 must be a multiple of number4'),
  questionHeight: 600,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(13, 99);

    const number2 = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % number1 === 0
    });

    const number4 = randomIntegerInclusive(13, 99);

    const number3 = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % number4 === 0
    });

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const answer = (number2 / number1) * (number3 / number4);

    return (
      <MarkupAssets
        elements={{
          moon: <AssetSvg key="moon" name={'Moon_white'} height={70} />,
          star: <AssetSvg key="star" name={'Star_yellow'} height={70} />
        }}
      >
        <QF2AnswerBoxOneSentence
          title={`${number1.toLocaleString()} = ${number2.toLocaleString()} ${DIV} <asset name='star'/><br/>${number3.toLocaleString()} ${DIV} <asset name='moon'/> = ${number4.toLocaleString()}<br/>${translate.instructions.workOutTheMultiplication()}`}
          testCorrect={[answer.toString()]}
          sentence={`<asset name='star'/> ${MULT} <asset name='moon'/> = <ans/>`}
          questionHeight={600}
        />
      </MarkupAssets>
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'IntroductionToLongDivision',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
