import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DIV } from '../../../../constants';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { AssetSvg } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import { numbersDoNotHaveDivisionExchange } from '../../../../utils/exchanges';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { getImagesByAmount } from '../../../../utils/images';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import { colors } from '../../../../theme/colors';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';

////
// Questions
////

const Question1v2 = newQuestionContent({
  uid: 'aGc2',
  description: 'aGc',
  keywords: ['Division', 'Counters', 'Array'],
  schema: z.object({
    columns: z.number().int().min(2).max(12),
    rows: numberEnum([2, 3, 4, 5, 8]),
    onesOrTens: z.enum(['ones', 'tens'])
  }),
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(2, 12);
    const rows = getRandomFromArray([2, 3, 4, 5, 8] as const);
    const onesOrTens = getRandomFromArray(['ones', 'tens'] as const);

    return { columns, rows, onesOrTens };
  },
  Component: props => {
    const {
      question: { columns, rows, onesOrTens },
      translate
    } = props;

    const product = onesOrTens === 'ones' ? columns * rows : columns * rows * 10;

    return (
      <QF1ContentAndSentence
        sentence={`${product.toLocaleString()} ${DIV} ${rows.toLocaleString()} = <ans/>`}
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[columns.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={columns}
            dimens={dimens}
            customImage={
              <AssetSvg
                name={onesOrTens === 'ones' ? 'Place_value/1' : 'Place_value/10'}
                width={Math.min(dimens.width / columns, dimens.height / rows)}
              />
            }
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question1 = newQuestionContent({
  uid: 'aGc',
  description: 'aGc',
  keywords: ['Array', 'Multiplication', 'Division', 'Fact family', 'Ones', 'Counters'],
  schema: z.object({
    columns: z.number().int().min(2).max(12),
    rows: numberEnum([2, 3, 4, 5, 8])
  }),
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(2, 12);

    const rows = getRandomFromArray([2, 3, 4, 5, 8] as const);

    return { columns, rows };
  },
  Component: props => {
    const {
      question: { columns, rows },
      translate
    } = props;

    const product = columns * rows;

    return (
      <QF1ContentAndSentence
        sentence={`${product.toLocaleString()} ${DIV} ${rows.toLocaleString()} = <ans/>`}
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[columns.toString()]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={columns}
            dimens={dimens}
            customImage={
              <AssetSvg
                name="Place_value/1"
                width={Math.min(dimens.width / columns, dimens.height / rows)}
              />
            }
          />
        )}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aGd2',
  description: 'aGd2',
  keywords: ['Partition', 'Division'],
  questionHeight: 1200,
  schema: z.object({
    divisor: numberEnum([2, 3, 4, 5, 8]),
    answer1: z.number().int().min(10).max(40).step(10),
    answer2: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const divisor = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const answer1 = randomIntegerInclusiveStep(10, 40, 10);
        const answer2 = randomIntegerInclusive(1, 8, { constraint: x => x * divisor >= 10 });

        return {
          divisor,
          answer1,
          answer2
        };
      },
      val => (val.answer1 + val.answer2) * val.divisor < 100
    ),
  Component: props => {
    const {
      question: { divisor, answer1, answer2 },
      translate,
      displayMode
    } = props;
    const dividend = (answer1 + answer2) * divisor;
    const part1 = divisor * answer1;
    const part2 = dividend - part1;
    const sentences = [
      `${DIV} ${divisor.toLocaleString()} = <ans/>`,
      `${DIV} ${divisor.toLocaleString()} = <ans/>`,
      `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`
    ];
    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutTheDivisions()}
        testCorrect={[[answer1.toString()], [answer2.toString()], [(answer1 + answer2).toString()]]}
        sentences={sentences}
        mainPanelStyle={{
          flexDirection: 'row'
        }}
        questionHeight={1200}
        style={{ alignItems: 'flex-end', right: 80 }}
        pdfSentenceStyle={
          displayMode !== 'digital' && {
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            rowGap: 200,
            right: 400
          }
        }
        Content={({ dimens }) => (
          <View style={{ position: 'absolute', top: 10 }}>
            <PartWholeModel
              top={dividend}
              partition={[part1, part2]}
              dimens={{
                width: displayMode === 'digital' ? dimens.width / 1.3 : dimens.width / 2,
                height: dimens.height / 1.5
              }}
              variation="leftRight"
            />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aGd',
  description: 'aGd',
  keywords: ['Array', 'Multiplication', 'Division', 'Fact family', 'Tens', 'Counters'],
  schema: z.object({
    columns: z.number().int().min(2).max(12),
    rows: numberEnum([2, 3, 4, 5, 8])
  }),
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(2, 12);

    const rows = getRandomFromArray([2, 3, 4, 5, 8] as const);

    return { columns, rows };
  },
  Component: props => {
    const {
      question: { columns, rows },
      translate
    } = props;

    const product = columns * rows * 10;

    return (
      <QF1ContentAndSentence
        sentence={`${product.toLocaleString()} ${DIV} ${rows.toLocaleString()} = <ans/>`}
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[(columns * 10).toString()]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={columns}
            dimens={dimens}
            customImage={
              <AssetSvg
                name="Place_value/10"
                width={Math.min(dimens.width / columns, dimens.height / rows)}
              />
            }
          />
        )}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aGe2',
  description: 'aGe2',
  keywords: ['Division', 'Partition'],
  schema: z.object({
    dividend: z.number().int().min(1).max(128),
    divisor: numberEnum([2, 3, 4, 5, 8]),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const divisor = getRandomFromArray([2, 3, 4, 5, 8] as const);

    // Dividend must *not* be within x12 of divisor
    const lowerBound = divisor === 8 ? divisor * 10 + 1 : divisor * 12 + 1;
    const dividend = randomIntegerInclusive(lowerBound, 99, {
      constraint: x => x % divisor === 0 && x % 10 !== 0
    });

    const name = getRandomName();

    return { divisor, dividend, name };
  },
  Component: props => {
    const {
      question: { divisor, dividend, name },
      translate
    } = props;

    return (
      <QF3InteractiveContent
        title={translate.instructions.charIsWorkingOutXDivYCompletePartWholeToShowHowCharCouldPartitionX(
          name,
          dividend,
          divisor
        )}
        inputType="numpad"
        initialState={['', '']}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        testCorrect={userAnswer => {
          const [ansA, ansB] = userAnswer.map(Number);
          return ansA % divisor === 0 && ansB % divisor === 0 && ansA + ansB === dividend;
        }}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={dividend.toLocaleString()}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={['$ans', '$ans']}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1000}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.anyValidPartition() }}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'aGe',
  description: 'aGe',
  keywords: ['Flexible partition', '2-digit'],
  schema: z
    .object({
      number1: z.number().int().min(20).max(90).multipleOf(10),
      number2: z.number().int().min(1).max(9),
      number4: z.number().int().min(10).max(30).multipleOf(10),
      givenPart: z.enum(['left', 'right'])
    })
    .refine(val => val.number4 < val.number1, 'number4 must be less than number1'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(20, 90, 10);

    const number2 = randomIntegerInclusive(1, 9);

    const number4 = randomIntegerInclusiveStep(10, Math.min(30, number1 - 10), 10);

    const givenPart = getRandomFromArray(['left', 'right'] as const);

    return { number1, number2, number4, givenPart };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, number4, givenPart } = question;

    const number3 = number1 + number2;

    const number5 = number1 - number4;

    const number6 = number2 + number4;

    const partition =
      givenPart === 'left'
        ? ['$ans', number5.toLocaleString()]
        : [number6.toLocaleString(), '$ans'];

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={
          displayMode === 'markscheme'
            ? [givenPart === 'left' ? number6.toLocaleString() : number5.toLocaleString()]
            : ['']
        }
        testComplete={answer => answer[0].length !== 0}
        testCorrect={answer =>
          givenPart === 'left' ? answer[0] === number6.toString() : answer[0] === number5.toString()
        }
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={number3}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aGf',
  description: 'aGf',
  keywords: ['Division', 'Sharing', '2-digit', 'No exchange'],
  schema: z.object({
    tensPerRow: z.number().int().min(10).max(40).multipleOf(10),
    onesPerRow: z.number().int().min(1).max(5),
    numberOfRow: numberEnum([2, 3, 4, 5, 8]),
    incorrectAnswer: z.enum(['+10', '-10', '+1', '-1', '+11', '-11', '+ a row', '- a row'])
  }),
  simpleGenerator: () => {
    const numberOfRow = getRandomFromArray([2, 3, 4, 5, 8] as const);

    const { onesPerRow, tensPerRow } = rejectionSample(
      () => {
        const onesPerRow = randomIntegerInclusive(1, 5);

        const tensPerRow = randomIntegerInclusiveStep(10, 40, 10);

        return { onesPerRow, tensPerRow };
      },
      // Only permit if the total of the correct PVC produced is less than 100
      ({ onesPerRow, tensPerRow }) => onesPerRow * numberOfRow + tensPerRow * numberOfRow < 100
    );

    const incorrectAnswer = getRandomFromArray(
      tensPerRow === 10 && onesPerRow === 1
        ? // Do not allow '-11' to be selected as an incorrectAnswer form if the number composed above is 11:
          (['+10', '-10', '+1', '-1', '+11', '+ a row', '- a row'] as const)
        : (['+10', '-10', '+1', '-1', '+11', '-11', '+ a row', '- a row'] as const)
    );

    return { tensPerRow, onesPerRow, numberOfRow, incorrectAnswer };
  },
  Component: props => {
    const {
      question: { tensPerRow, onesPerRow, numberOfRow, incorrectAnswer },
      translate,
      displayMode
    } = props;

    const total = (tensPerRow + onesPerRow) * numberOfRow;

    const amountOfTensPerRow = tensPerRow / 10;

    const [amountOfIncorrectTens, amountOfIncorrectOnes] = (() => {
      switch (incorrectAnswer) {
        case '+10':
          return [amountOfTensPerRow + 1, onesPerRow];
        case '-10':
          return [amountOfTensPerRow - 1, onesPerRow];
        case '+1':
          return [amountOfTensPerRow, onesPerRow + 1];
        case '-1':
          return [amountOfTensPerRow, onesPerRow - 1];
        case '+11':
          return [amountOfTensPerRow + 1, onesPerRow + 1];
        case '-11':
          return [amountOfTensPerRow - 1, onesPerRow - 1];
        case '+ a row':
        case '- a row':
          return [amountOfTensPerRow, onesPerRow];
      }
    })();

    const numOfIncorrectRows =
      incorrectAnswer === '+ a row'
        ? numberOfRow + 1
        : incorrectAnswer === '- a row'
        ? numberOfRow - 1
        : numberOfRow;

    const tensImagesCorrect = getImagesByAmount('Place_value/10', amountOfTensPerRow);
    const tensImagesIncorrect = getImagesByAmount('Place_value/10', amountOfIncorrectTens);

    // Correct amount of ones
    const onesImagesCorrect = getImagesByAmount('Place_value/1', onesPerRow);
    // Incorrect amount of ones
    const onesImagesIncorrect = getImagesByAmount('Place_value/1', amountOfIncorrectOnes);

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };

    const scalesToCheck = [
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesCorrect,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesCorrect,
        4
      )
    ];

    if (amountOfIncorrectTens > 0) {
      scalesToCheck.push(
        calcRowOfImagesScaleFactor(
          rowContainerDimens.width,
          rowContainerDimens.height,
          tensImagesIncorrect,
          4
        )
      );
    }

    if (amountOfIncorrectOnes > 0) {
      scalesToCheck.push(
        calcRowOfImagesScaleFactor(
          rowContainerDimens.width,
          rowContainerDimens.height,
          onesImagesIncorrect,
          4
        )
      );
    }

    const scaleFactor = Math.min(...scalesToCheck);

    // Correct data
    const dataA = countRange(numberOfRow).map(i => {
      return [
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesCorrect}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesCorrect}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Incorrect data
    const dataB = countRange(numOfIncorrectRows).map(i => {
      return [
        <RowOfImages
          key={`${i}-incorrectTens`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesIncorrect}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={`${i}-incorrectOnes`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesIncorrect}
          scaleFactor={scaleFactor}
        />
      ];
    });

    const charts = shuffle(
      [
        {
          tensImages: tensImagesCorrect,
          onesImages: onesImagesCorrect,
          data: dataA,
          isCorrect: true,
          value: 'correct'
        },
        {
          tensImages: tensImagesIncorrect,
          onesImages: onesImagesIncorrect,
          data: dataB,
          isCorrect: false,
          value: 'incorrect'
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.whichPlaceValueChartRepresentsXDivY(total, numberOfRow)}
        pdfTitle={translate.instructions.whichPlaceValueChartRepresentsXDivYPDF(total, numberOfRow)}
        testCorrect={['correct']}
        numItems={2}
        renderItems={({ dimens }) => {
          return charts.map(chart => ({
            value: chart.value,
            component: (
              <CustomizableTable
                cellHeaders={[
                  {
                    label: translate.keywords.Tens(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[1].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[1].textColor
                    }
                  },
                  {
                    label: translate.keywords.Ones(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[0].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[0].textColor
                    }
                  }
                ]}
                tableData={chart.data}
                tableStyle={{ width: dimens.width * 0.9 }}
                tableFontSize={displayMode === 'digital' ? 24 : 50}
              />
            )
          }));
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question4v2 = newQuestionContent({
  uid: 'aGf2',
  description: 'aGf2',
  keywords: ['Division', 'Sharing'],
  schema: z
    .object({
      characterName: nameSchema,
      friends: numberEnum([3, 4, 8]),
      sweets: z.number().int().min(39).max(120)
    })
    .refine(val => val.sweets % val.friends === 0, 'sweets must be a multiple of friends.'),
  simpleGenerator: () => {
    const characterName = getRandomName();

    const friends = getRandomFromArray([3, 4, 8] as const);

    const [sweetsLowerBound, sweetsUpperBound] = (() => {
      switch (friends) {
        case 3:
          return [13 * friends, 99];
        case 4:
          return [13 * friends, 100];
        case 8:
          return [13 * friends, 120];
      }
    })();

    const sweets = randomIntegerInclusiveStep(sweetsLowerBound, sweetsUpperBound, friends);

    return { characterName, friends, sweets };
  },
  Component: ({ question, translate }) => {
    const { characterName, friends, sweets } = question;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterSharesNumSweetsEquallyBetweenNumFriendsHowManySweetsDoesEachFriendGet(
          characterName,
          sweets,
          friends
        )}
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(sweets / friends).toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aGg',
  description: 'aGg',
  keywords: ['Division', 'Sharing', '2-digit', 'Exchange'],
  schema: z.object({
    divisorA: numberEnum([2, 3, 4, 5, 8]),
    dividendA: z.number().int().min(11).max(99),
    divisorB: numberEnum([2, 3, 4, 5, 8]),
    dividendB: z.number().int().min(11).max(99),
    divisorC: numberEnum([2, 3, 4, 5, 8]),
    dividendC: z.number().int().min(11).max(99),
    divisorD: numberEnum([2, 3, 4, 5, 8]),
    dividendD: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    // Equation A:
    const { divisorA, dividendA } = rejectionSample(
      () => {
        const divisorA = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorA > 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10);
        const dividendA = (ones + tens) * divisorA;

        return { divisorA, dividendA };
      },
      ({ dividendA }) => dividendA < 100
    );

    // Equation B:
    const { divisorB, dividendB } = rejectionSample(
      () => {
        const divisorB = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorB > 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10);
        const dividendB = (ones + tens) * divisorB;

        return { divisorB, dividendB };
      },
      ({ dividendB }) => dividendB < 100 && arrayHasNoDuplicates([dividendA, dividendB])
    );

    // Equation C:
    const { divisorC, dividendC } = rejectionSample(
      () => {
        const divisorC = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorC > 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10);
        const dividendC = (ones + tens) * divisorC;

        return { divisorC, dividendC };
      },
      ({ dividendC }) => dividendC < 100 && arrayHasNoDuplicates([dividendA, dividendB, dividendC])
    );

    // Equation D:
    const { divisorD, dividendD } = rejectionSample(
      () => {
        const divisorD = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorD > 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10);
        const dividendD = (ones + tens) * divisorD;

        return { divisorD, dividendD };
      },
      ({ dividendD }) =>
        dividendD < 100 && arrayHasNoDuplicates([dividendA, dividendB, dividendC, dividendD])
    );

    return { divisorA, dividendA, divisorB, dividendB, divisorC, dividendC, divisorD, dividendD };
  },
  Component: props => {
    const {
      question: {
        divisorA,
        dividendA,
        divisorB,
        dividendB,
        divisorC,
        dividendC,
        divisorD,
        dividendD
      },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: `${dividendA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()} = <ans/>`,
          answer: (dividendA / divisorA).toString()
        },
        {
          sentence: `${dividendB.toLocaleString()} ${DIV} ${divisorB.toLocaleString()} = <ans/>`,
          answer: (dividendB / divisorB).toString()
        },
        {
          sentence: `${dividendC.toLocaleString()} ${DIV} ${divisorC.toLocaleString()} = <ans/>`,
          answer: (dividendC / divisorC).toString()
        },
        {
          sentence: `<ans/> = ${dividendD.toLocaleString()} ${DIV} ${divisorD.toLocaleString()}`,
          answer: (dividendD / divisorD).toString()
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={sentences.map(sentenceObject => [sentenceObject.answer])}
        sentences={sentences.map(sentenceObject => sentenceObject.sentence)}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aGg2',
  description: 'aGg',
  keywords: ['Division', 'Sharing', '2-digit', 'Exchange'],
  schema: z.object({
    divisorA: numberEnum([2, 3, 4, 5, 8]),
    dividendA: z.number().int().min(11).max(99),
    isReversed: z.boolean()
  }),
  simpleGenerator: () => {
    // Equation A:
    const { divisorA, dividendA } = rejectionSample(
      () => {
        const divisorA = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorA > 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10);
        const dividendA = (ones + tens) * divisorA;

        return { divisorA, dividendA };
      },
      ({ dividendA }) => dividendA < 100
    );

    const isReversed = getRandomBoolean();

    return { divisorA, dividendA, isReversed };
  },
  Component: props => {
    const {
      question: { divisorA, dividendA, isReversed },
      translate
    } = props;

    const sentence = getBinOpEquation({
      left: dividendA,
      right: divisorA,
      sign: 'divide',
      flipped: isReversed,
      answer: 'result'
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeDivision()}
        sentence={binOpEquationToSentenceString(sentence)}
        testCorrect={binOpEquationsToTestCorrect([sentence])[0]}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question6 = newQuestionContent({
  uid: 'aGh',
  description: 'aGh',
  keywords: ['Division', 'Sharing', '2-digit', 'No exchange', 'Exchange'],
  schema: z.object({
    divisorA: numberEnum([2, 3, 4, 5, 8]),
    dividendA: z.number().int().min(11).max(99),
    divisorB: numberEnum([2, 3, 4, 5, 8]),
    dividendB: z.number().int().min(11).max(99),
    divisorC: numberEnum([2, 3, 4, 5, 8]),
    dividendC: z.number().int().min(11).max(99),
    divisorD: numberEnum([2, 3, 4, 5, 8]),
    dividendD: z.number().int().min(11).max(99)
  }),
  simpleGenerator: () => {
    // Equation A:
    const { divisorA, dividendA } = rejectionSample(
      () => {
        const divisorA = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorA > 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10);
        const dividendA = (ones + tens) * divisorA;

        return { divisorA, dividendA };
      },
      ({ dividendA }) => dividendA < 100
    );

    // Equation B:
    const { divisorB, dividendB } = rejectionSample(
      () => {
        const divisorB = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorB > 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10);
        const dividendB = (ones + tens) * divisorB;

        return { divisorB, dividendB };
      },
      ({ dividendB }) => dividendB < 100 && arrayHasNoDuplicates([dividendA, dividendB])
    );

    // Equation C:
    const { divisorC, dividendC } = rejectionSample(
      () => {
        const divisorC = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorC < 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10, {
          constraint: x => x * divisorC < 100
        });
        const dividendC = (ones + tens) * divisorC;

        return { divisorC, dividendC };
      },
      ({ dividendC }) => dividendC < 100
    );

    // Equation D:
    const { divisorD, dividendD } = rejectionSample(
      () => {
        const divisorD = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const ones = randomIntegerInclusive(1, 9, {
          constraint: x => x * divisorD < 10
        });
        const tens = randomIntegerInclusiveStep(10, 40, 10, {
          constraint: x => x * divisorD < 100
        });
        const dividendD = (ones + tens) * divisorD;

        return { divisorD, dividendD };
      },
      ({ dividendD }) => dividendD < 100 && arrayHasNoDuplicates([dividendC, dividendD])
    );

    return { divisorA, dividendA, divisorB, dividendB, divisorC, dividendC, divisorD, dividendD };
  },
  Component: props => {
    const {
      question: {
        divisorA,
        dividendA,
        divisorB,
        dividendB,
        divisorC,
        dividendC,
        divisorD,
        dividendD
      },
      translate
    } = props;

    const [exchanges, noExchanges]: [string[], string[]] = [[], []];
    const arrayOfCorrectAnswers = [exchanges, noExchanges];

    const equations = shuffle(
      [
        {
          equation: `${dividendA.toLocaleString()} ${DIV} ${divisorA.toLocaleString()}`,
          doesNotExchange: numbersDoNotHaveDivisionExchange(dividendA, divisorA)
        },
        {
          equation: `${dividendB.toLocaleString()} ${DIV} ${divisorB.toLocaleString()}`,
          doesNotExchange: numbersDoNotHaveDivisionExchange(dividendB, divisorB)
        },
        {
          equation: `${dividendC.toLocaleString()} ${DIV} ${divisorC.toLocaleString()}`,
          doesNotExchange: numbersDoNotHaveDivisionExchange(dividendC, divisorC)
        },
        {
          equation: `${dividendD.toLocaleString()} ${DIV} ${divisorD.toLocaleString()}`,
          doesNotExchange: numbersDoNotHaveDivisionExchange(dividendD, divisorD)
        }
      ],
      { random: seededRandom(props.question) }
    );

    // Organize the equations into their respective Sets:
    equations.forEach(equation => {
      if (equation.doesNotExchange) {
        noExchanges.push(equation.equation);
      } else {
        exchanges.push(equation.equation);
      }
    });

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortCalculationsInTable()}
        pdfTitle={translate.instructions.useCardsToSortCalculationsInTable()}
        zoneNames={[
          translate.tableHeaders.divisionsWithExchange(),
          translate.tableHeaders.divisonsWithNoExchange()
        ]}
        items={equations.map(equation => equation.equation)}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        testCorrect={arrayOfCorrectAnswers}
        questionHeight={800}
        zoneCapacity={3}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA2DigitNumberByA1DigitNumberFlexiblePartitioning',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4v2, Question5v2, Question6],
  archivedQuestionTypes: [Question1, Question2, Question3, Question4, Question5]
});
export default SmallStep;
