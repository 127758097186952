import { View } from 'react-native';
import { z } from 'zod';
import { all, create, number } from 'mathjs';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { ScientificNotation, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { isGreaterThan, isLessThan } from '../../../../utils/matchers';
import Text from '../../../../components/typography/Text';
import { GREATER_THAN, LESS_THAN } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import ContentBox from '../../../../components/molecules/ContentBox';
import TextStructure from '../../../../components/molecules/TextStructure';
import { NoKeyboardTextInputWithState } from '../../../../components/atoms/NoKeyboardTextInput';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { TestCorrect } from '../../../../stateTree';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'awI',
  description: 'awI',
  keywords: ['Compare', 'Decimals', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    var1: z.number().int().min(0).max(5),
    var2: z.number().int().min(1).max(8),
    var3: z.number().int().min(1).max(8),
    var4: z.number().int().min(0).max(9),
    var5: z.number().int().min(0).max(9)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(0, 5);
    const var2 = randomIntegerInclusive(1, 8);
    const var3 = randomIntegerInclusive(1, 8);
    const var4 = randomIntegerInclusive(var2 - 1, var2 + 1);
    const var5 = randomIntegerInclusive(var3 - 1, var3 + 1, {
      constraint: x => (var1 === 0 && var4 === 0 ? x > 0 : x >= 0)
    });

    return { var1, var2, var3, var4, var5 };
  },
  Component: ({ question: { var1, var2, var3, var4, var5 }, translate, displayMode }) => {
    const lhsValue = var1 + var2 / 10 + var3 / 100;
    const rhsValue = var1 + var4 / 10 + var5 / 100;

    const counterVariant = 'decimalCounter';
    const dimens =
      displayMode === 'digital' ? { height: 200, width: 450 } : { height: 350, width: 800 };
    const headerVariant = 'shortName';

    const statement = [
      {
        lhsComponent: (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(lhsValue)}
            columnsToShow={[0, -1, -2]}
            counterVariant={counterVariant}
            headerVariant={headerVariant}
            dimens={dimens}
          />
        ),
        rhsComponent: (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(rhsValue)}
            columnsToShow={[0, -1, -2]}
            counterVariant={counterVariant}
            headerVariant={headerVariant}
            dimens={dimens}
          />
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(lhsValue, rhsValue)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsMakeStatementCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementCorrect()}
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        statements={statement}
        items={['>', '<', '=']}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'awJ',
  description: 'awJ',
  keywords: ['Compare', 'Decimals', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    var1: z.number().int().min(0).max(5),
    var2: z.number().int().min(1).max(8),
    var3: z.number().int().min(1).max(8),
    var4: z.number().int().min(0).max(9),
    var5: z.number().int().min(0).max(9)
  }),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(0, 5);
    const var2 = randomIntegerInclusive(1, 8);
    const var3 = randomIntegerInclusive(1, 8);
    const var4 = randomIntegerInclusive(var2 - 1, var2 + 1);
    const var5 = randomIntegerInclusive(var3 - 1, var3 + 1, {
      constraint: x => (var1 === 0 && var4 === 0 ? x > 0 : x >= 0)
    });

    return { var1, var2, var3, var4, var5 };
  },
  Component: ({ question: { var1, var2, var3, var4, var5 }, translate, displayMode }) => {
    const lhsValue = var1 + var2 / 10 + var3 / 100;
    const rhsValue = var1 + var4 / 10 + var5 / 100;

    const counterVariant = 'number';
    const dimens =
      displayMode === 'digital' ? { height: 200, width: 450 } : { height: 350, width: 800 };
    const headerVariant = 'shortName';

    const statement = [
      {
        lhsComponent: (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(lhsValue)}
            columnsToShow={[0, -1, -2]}
            counterVariant={counterVariant}
            headerVariant={headerVariant}
            showZerosOnNegativeColumnPow
            dimens={dimens}
          />
        ),
        rhsComponent: (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(rhsValue)}
            columnsToShow={[0, -1, -2]}
            counterVariant={counterVariant}
            headerVariant={headerVariant}
            showZerosOnNegativeColumnPow
            dimens={dimens}
          />
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(lhsValue, rhsValue)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsMakeStatementCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementCorrect()}
        itemVariant="square"
        pdfItemVariant="pdfSquare"
        statements={statement}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'awK',
  description: 'awK',
  keywords: ['Compare', 'Inequality', 'Decimals', 'Tens', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumberA: z.number().min(1.1).max(98.9),
    questionNumberB: z.number().min(0.1).max(89.9)
  }),
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b', 'c', 'd'] as const);

    const [questionNumberA, questionNumberB] = (() => {
      switch (questionToShow) {
        case 'a': {
          const var1a = randomIntegerInclusive(101, 188);
          const var1 = var1a / 10;
          const var2a = randomIntegerInclusive(var1a - 100, 99);
          const var2 = var2a / 10;
          return [var1, var2];
        }
        case 'b': {
          const var3 = randomIntegerInclusive(2, 9);
          const var4a = randomIntegerInclusive(1, 8);
          const var4 = var4a / 10;
          const var5 = var3 + var4;

          const var6 = randomIntegerInclusive(1, var3 - 1);
          const var7a = randomIntegerInclusive(var4a - 1, 9);
          const var7 = var7a / 10;
          const var8 = var6 + var7;
          return [var5, var8];
        }
        case 'c': {
          const var9 = randomIntegerInclusiveStep(20, 90, 10);
          const var10a = randomIntegerInclusive(1, 89);
          const var10 = var10a / 10;
          const var11 = var9 + var10;

          const var12 = var9 - 10;
          const var13a = randomIntegerInclusive(var10a, 99);
          const var13 = var13a / 10;
          const var14 = var12 + var13;
          return [var11, var14];
        }
        case 'd': {
          const var15a = randomIntegerInclusive(11, 99);
          const var15 = var15a / 10;
          const var16a = randomIntegerInclusive(101, 999, {
            constraint: x => x - var15a * 10 > 100 || var15a * 10 - x > 100
          });
          const var16 = var16a / 100;
          return [var15, var16];
        }
      }
    })();

    return { questionNumberA, questionNumberB };
  },
  Component: ({ question: { questionNumberA, questionNumberB }, translate }) => {
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsMakeStatementCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementCorrect()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={[
          {
            lhsComponent: <Text variant="WRN400">{questionNumberA.toFixed(1)}</Text>,
            rhsComponent: <Text variant="WRN400">{questionNumberB.toFixed(1)}</Text>,
            correctAnswer: lessThanGreaterThanOrEqualTo(
              Number(questionNumberA),
              Number(questionNumberB)
            )
          }
        ]}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="move"
        actionPanelVariant="end"
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awL',
  description: 'awL',
  keywords: ['Compare', 'Inequality', 'Decimals', 'Tens', 'Ones', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      var4: z.number().min(1.1).max(99).step(0.1),
      var5: z.number().min(1.1).max(9.9).step(0.1),
      var8: z.number().min(1.11).max(9.99).step(0.01),
      var9: z.number().min(1.11).max(9.99).step(0.01),
      var10: z.number().min(1.11).max(9.99).step(0.01),
      var11: z.number().min(1.11).max(9.99).step(0.01)
    })
    .refine(
      ({ var4, var5, var8, var9, var10, var11 }) =>
        var4 !== var5 && var8 !== var9 && var10 !== var11,
      'All three comparisons must be < or > (not =)'
    ),
  simpleGenerator: () => {
    const var1 = randomIntegerInclusive(1, 9);
    const var2a = randomIntegerInclusive(1, 9);
    const var2 = var2a / 10;
    const var3a = randomIntegerInclusive(1, 9, { constraint: x => x !== var2a });
    const var3 = var3a / 10;
    const var4 = var1 + var2;
    const var5 = var1 + var3;

    const var6a = randomIntegerInclusive(1, 9);
    const var6 = var6a / 100;

    const var7a = randomIntegerInclusive(1, 9, { constraint: x => x !== var6a });
    const var7 = var7a / 100;

    const var8 = number(math.evaluate(`${var1} + ${var2} + ${var6}`));
    const var9 = number(math.evaluate(`${var1} + ${var2} + ${var7}`));

    const var10 = number(math.evaluate(`${var1} + ${var2} + ${var6}`));
    const var11 = number(math.evaluate(`${var1} + ${var3} + ${var6}`));

    return { var4, var5, var8, var9, var10, var11 };
  },
  Component: ({ question: { var4, var5, var8, var9, var10, var11 }, translate }) => {
    const numbers = [
      [var4.toFixed(1), var5.toFixed(1)],
      [var8.toFixed(2), var9.toFixed(2)],
      [var10.toFixed(2), var11.toFixed(2)]
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsMakeStatementsCorrect()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToMakeStatementsCorrect()}
        itemVariant="square"
        pdfLayout="itemsHidden"
        statements={numbers.map(value => ({
          lhsComponent: <Text variant="WRN400">{value[0]}</Text>,
          rhsComponent: <Text variant="WRN400">{value[1]}</Text>,
          correctAnswer: lessThanGreaterThanOrEqualTo(Number(value[0]), Number(value[1]))
        }))}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'awM',
  description: 'awM',
  keywords: ['Place value', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumberA: z.number().int().min(1).max(9),
    questionNumberB: z.number().int().min(1).max(9),
    questionNumberC: z.number().int().min(1).max(9),
    symbol: z.enum([LESS_THAN, GREATER_THAN]),
    questionIndex: numberEnum([0, 1, 2])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const questionIndex = getRandomFromArray([0, 1, 2] as const);

    const questionNumberA =
      questionIndex === 2 ? randomIntegerInclusive(1, 8) : randomIntegerInclusive(1, 9);

    const questionNumberB =
      questionIndex === 0 ? randomIntegerInclusive(1, 8) : randomIntegerInclusive(1, 9);

    const questionNumberC =
      questionIndex === 0
        ? questionNumberA
        : questionIndex === 1
        ? randomIntegerInclusive(1, 8)
        : randomIntegerInclusive(1, 9);

    const symbol = getRandomFromArray([LESS_THAN, GREATER_THAN] as const);

    return { questionNumberA, questionNumberB, questionNumberC, symbol, questionIndex };
  },
  Component: ({
    question: { questionNumberA, questionNumberB, questionNumberC, symbol, questionIndex },
    translate,
    displayMode
  }) => {
    const statementAnswers = [
      {
        validAnswer:
          symbol === GREATER_THAN ? isLessThan(questionNumberB) : isGreaterThan(questionNumberB) // Answer is on the rhs so the check the reverse of the symbol
      },
      {
        validAnswer:
          symbol === GREATER_THAN ? isLessThan(questionNumberC) : isGreaterThan(questionNumberC) // Answer is on the rhs so the check the reverse of the symbol
      },
      {
        validAnswer: (answer: number) => {
          if (answer === questionNumberA) {
            // If the answer equals questionNumberA, check the 100th column
            return symbol === GREATER_THAN
              ? questionNumberB > questionNumberC
              : questionNumberB < questionNumberC;
          } else
            return symbol === GREATER_THAN ? questionNumberA > answer : questionNumberA < answer;
        }
      }
    ];

    return (
      <QF3Content
        title={translate.instructions.fillInMissingDigitToMakeStatementCorrect()}
        inputType="numpad"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.numberSentenceMustBeCorrect()
        }}
        Content={({ dimens }) => {
          const decimalPointText = (
            <View style={{ justifyContent: 'flex-end' }}>
              <Text variant="WRN400">.</Text>
            </View>
          );

          const numberBox = (number: number) => (
            <ContentBox
              containerStyle={{
                width: dimens.width / 8,
                height: displayMode === 'digital' ? 100 : 150,
                justifyContent: 'center'
              }}
            >
              <Text variant="WRN400">{number.toLocaleString()}</Text>
            </ContentBox>
          );

          const answerBox = (
            <NoKeyboardTextInputWithState
              id="textbox"
              testCorrect={answer => statementAnswers[questionIndex].validAnswer(Number(answer))}
              singleCharacterMode
              style={{ width: dimens.width / 8 }}
            />
          );

          const symbolText = (
            <View style={{ justifyContent: 'center', marginHorizontal: 10 }}>
              <TextStructure sentence={symbol} />
            </View>
          );

          return (
            <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
              {questionIndex === 1 ? numberBox(questionNumberA) : numberBox(0)}
              {decimalPointText}
              {questionIndex === 1 ? numberBox(questionNumberB) : numberBox(questionNumberA)}
              {questionIndex === 1 ? numberBox(questionNumberC) : numberBox(questionNumberB)}
              {symbolText}
              {questionIndex === 1 ? numberBox(questionNumberA) : numberBox(0)}
              {decimalPointText}
              {questionIndex === 0
                ? numberBox(questionNumberC)
                : questionIndex === 1
                ? numberBox(questionNumberB)
                : answerBox}
              {questionIndex === 2 ? numberBox(questionNumberC) : answerBox}
            </View>
          );
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'awN',
  description: 'awN',
  keywords: ['Place value', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumberA: z.number().int().min(1).max(9),
    questionNumberB: z.number().int().min(1).max(9),
    symbol: z.enum([LESS_THAN, GREATER_THAN]),
    questionIndex: numberEnum([0, 1, 2])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const questionIndex = getRandomFromArray([0, 1, 2] as const);

    const questionNumberA = randomIntegerInclusive(1, 9);

    const questionNumberB =
      questionIndex === 0
        ? questionNumberA
        : questionIndex === 1
        ? randomIntegerInclusive(1, 9)
        : randomIntegerInclusive(1, 8);

    const symbol = getRandomFromArray([LESS_THAN, GREATER_THAN] as const);

    return { questionNumberA, questionNumberB, symbol, questionIndex };
  },
  Component: ({
    question: { questionNumberA, questionNumberB, symbol, questionIndex },
    translate,
    displayMode
  }) => {
    const statementAnswers = [
      {
        validAnswer: (lAns: number, rAns: number) => {
          return symbol === GREATER_THAN ? lAns > rAns : lAns < rAns;
        }
      },
      {
        validAnswer: (lAns: number, rAns: number) => {
          return symbol === GREATER_THAN ? lAns > rAns : lAns < rAns;
        }
      },
      {
        validAnswer: (lAns: number, rAns: number) => {
          if (questionNumberA === rAns) {
            return symbol === GREATER_THAN ? lAns > questionNumberB : lAns < questionNumberB;
          } else return symbol === GREATER_THAN ? questionNumberA > rAns : questionNumberA < rAns;
        }
      }
    ];

    return (
      <QF3Content
        title={translate.instructions.fillInMissingDigitsToMakeStatementCorrect()}
        inputType="numpad"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.numberSentenceMustBeCorrect()
        }}
        Content={({ dimens }) => {
          const decimalPointText = (
            <View style={{ justifyContent: 'flex-end' }}>
              <Text variant="WRN400">.</Text>
            </View>
          );

          const numberBox = (number: number) => (
            <ContentBox
              containerStyle={{
                width: dimens.width / 8,
                height: displayMode === 'digital' ? 100 : 150,
                justifyContent: 'center'
              }}
            >
              <Text variant="WRN400">{number.toLocaleString()}</Text>
            </ContentBox>
          );

          const answerBox = (lhsOrRhs: 'lhs' | 'rhs') => (
            <NoKeyboardTextInputWithState
              // Note we don't do testCorrect here, instead we do it in a separate TestCorrect component
              id={lhsOrRhs}
              singleCharacterMode
              style={{ width: dimens.width / 8 }}
            />
          );

          const testCorrect = (
            <TestCorrect<{ lhs: string; rhs: string }>
              testCorrect={({ lhs, rhs }) =>
                statementAnswers[questionIndex].validAnswer(Number(lhs), Number(rhs))
              }
            />
          );

          const symbolText = (
            <View style={{ justifyContent: 'center', marginHorizontal: 10 }}>
              <TextStructure sentence={symbol} />
            </View>
          );

          return (
            <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
              {questionIndex === 1 ? numberBox(questionNumberA) : numberBox(0)}
              {decimalPointText}
              {questionIndex === 1 ? answerBox('lhs') : numberBox(questionNumberA)}
              {questionIndex === 1 ? numberBox(questionNumberB) : answerBox('lhs')}
              {symbolText}
              {questionIndex === 1 ? numberBox(questionNumberA) : numberBox(0)}
              {decimalPointText}
              {questionIndex === 0 ? numberBox(questionNumberB) : answerBox('rhs')}
              {questionIndex === 0 ? answerBox('rhs') : numberBox(questionNumberB)}
              {testCorrect}
            </View>
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
