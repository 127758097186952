import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import { all, create, equal, number } from 'mathjs';
import { ScientificNotation, compareFloats, isValidDecimalString } from '../../../../utils/math';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import { ADD, SUB } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { nestedArrayHasNoDuplicates } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'awC',
  description: 'awC',
  keywords: ['Decimals', 'Ones', 'Tenths'],
  schema: z.object({
    givenTenths: z.number().min(0.1).max(0.5).step(0.1),
    givenHundredths: z.number().min(0.11).max(0.49).step(0.01)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const givenTenths = randomIntegerInclusive(1, 5) / 10;

    const givenHundredths = randomIntegerInclusive(11, 49) / 100;

    return { givenTenths, givenHundredths };
  },
  Component: ({ question, translate, displayMode }) => {
    const { givenTenths, givenHundredths } = question;

    const total = number(math.evaluate(`${givenTenths} + ${givenHundredths}`));

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        extraSymbol="decimalPoint"
        inputType="numpad"
        initialState={displayMode === 'markscheme' ? [total.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => compareFloats(answer[0], total)}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={'$ans'}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={[givenHundredths.toLocaleString(), givenTenths.toLocaleString()]}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'awD',
  description: 'awD',
  keywords: ['Decimals', 'Ones', 'Tenths'],
  schema: z
    .object({
      total: z.number().min(0.33).max(0.99).step(0.01),
      givenPart: z.number().min(0.11).max(0.91).step(0.01),
      answerPosition: z.enum(['left', 'right'])
    })
    .refine(val => val.givenPart < val.total, 'givenPart must be less than total'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const total = randomIntegerInclusive(33, 99) / 100;

    const givenPart =
      randomIntegerInclusiveStep(11, 91, 20, {
        constraint: x => x / 100 < total
      }) / 100;

    const answerPosition = getRandomFromArray(['left', 'right'] as const);

    return { total, givenPart, answerPosition };
  },
  Component: ({ question, translate, displayMode }) => {
    const { total, givenPart, answerPosition } = question;

    const answerPart = number(math.evaluate(`${total} - ${givenPart}`));

    const partition =
      answerPosition === 'left'
        ? [givenPart.toLocaleString(), '$ans']
        : ['$ans', givenPart.toLocaleString()];

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        extraSymbol="decimalPoint"
        inputType="numpad"
        initialState={displayMode === 'markscheme' ? [answerPart.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => compareFloats(answer[0], answerPart)}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={total.toLocaleString()}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'awE',
  description: 'awE',
  keywords: ['Decimals', 'Ones', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      total: z.number().min(3.33).max(9.99).step(0.01),
      givenPart: z.number().min(1.11).max(8.91).step(0.01),
      answerPositions: z.enum(['left and middle', 'left and right', 'middle and right'])
    })
    .refine(val => val.givenPart < val.total, 'givenPart must be less than total'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const givenPart = randomIntegerInclusiveStep(111, 891, 20) / 100;

    const total =
      randomIntegerInclusive(Math.max(givenPart * 100 + 101, 333), 999, {
        // Ensure the total selected does not require any exchanges from the givenPart and the missing answers.
        constraint: x => numbersDoNotExchange(x, givenPart * -100)
      }) / 100;

    const answerPositions = getRandomFromArray([
      'left and middle',
      'left and right',
      'middle and right'
    ] as const);

    return { total, givenPart, answerPositions };
  },
  Component: ({ question, translate }) => {
    const { total, givenPart, answerPositions } = question;

    const partition = (() => {
      switch (answerPositions) {
        case 'left and middle':
          return [givenPart.toLocaleString(), '$ans', '$ans'];
        case 'left and right':
          return ['$ans', givenPart.toLocaleString(), '$ans'];
        case 'middle and right':
          return ['$ans', '$ans', givenPart.toLocaleString()];
      }
    })();

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        extraSymbol="decimalPoint"
        inputType="numpad"
        initialState={['', '']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => {
          // If more than 1 decimal point in an answer, mark as false
          if (!answer.every(ans => isValidDecimalString(ans))) {
            return false;
          } else {
            const userTotal = math.evaluate(`${answer[0]} + ${answer[1]} + ${givenPart}`);
            return compareFloats(userTotal, total);
          }
        }}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={total.toLocaleString()}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationThatSumsToX(total)
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'awF',
  description: 'awF',
  keywords: ['Decimals', 'Partition', 'Tenths', 'Hundredths'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().min(1.1).max(9.9).step(0.1),
    number3: z.number().min(1.11).max(9.99).step(0.01),
    number4: z.number().min(0.09).max(0.99),
    option1: z.number().min(0.1).max(8.9).array().length(2),
    option2: z.number().min(0.1).max(9.8).array().length(2),
    option3: z.number().min(0.01).max(9.98).array().length(2),
    option4: z.number().min(0.01).max(0.98).array().length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const number2 = randomIntegerInclusive(11, 99) / 10;
    const number3 = randomIntegerInclusive(111, 999) / 100;
    const number4 = randomIntegerInclusiveStep(9, 99, 10) / 100;

    // Generate answer options pairs
    const option1A = randomIntegerInclusive(1, Math.min(number1 * 10 - 1, 89)) / 10;
    const option1B = number(math.evaluate(`${number1} - ${option1A}`));

    const option2A = randomIntegerInclusive(1, Math.min(number2 * 10 - 1, 98)) / 10;
    const option2B = number(math.evaluate(`${number2} - ${option2A}`));

    const option3A = randomIntegerInclusive(1, Math.min(number3 * 100 - 1, 998)) / 100;
    const option3B = number(math.evaluate(`${number3} - ${option3A}`));

    const option4A = randomIntegerInclusive(1, Math.min(number4 * 100 - 1, 98)) / 100;
    const option4B = number(math.evaluate(`${number4} - ${option4A}`));

    const option1 = [option1A, option1B];
    const option2 = [option2A, option2B];
    const option3 = [option3A, option3B];
    const option4 = [option4A, option4B];

    return { number1, number2, number3, number4, option1, option2, option3, option4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, option1, option2, option3, option4 },
      translate
    } = props;

    // Ensure number3 & number4 are 2 decimal places
    const number3LocalizedString = number3.toLocaleString(undefined, { minimumFractionDigits: 2 });
    const number4LocalizedString = number4.toLocaleString(undefined, { minimumFractionDigits: 2 });

    const statements = [
      {
        lhsComponent: number1,
        correctAnswer: number1
      },
      {
        lhsComponent: number2,
        correctAnswer: number2
      },
      {
        lhsComponent: number3LocalizedString,
        correctAnswer: number3
      },
      {
        lhsComponent: number4LocalizedString,
        correctAnswer: number4
      }
    ];

    const items = [
      {
        text: `${option1[0].toLocaleString()} ${ADD} ${option1[1].toLocaleString()}`,
        value: number1
      },
      {
        text: `${option2[0].toLocaleString()} ${ADD} ${option2[1].toLocaleString()}`,
        value: number2
      },
      {
        text: `${option3[0].toLocaleString()} ${ADD} ${option3[1].toLocaleString()}`,
        value: number3
      },
      {
        text: `${option4[0].toLocaleString()} ${ADD} ${option4[1].toLocaleString()}`,
        value: number4
      }
    ];

    const random = seededRandom(props.question);

    const shuffledStatements = shuffle(statements, { random });
    const shuffledItems = shuffle(items, { random });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsMatchNumbersWithCalculations()}
        pdfTitle={translate.instructions.useCardsMatchNumbersWithCalculations()}
        items={shuffledItems.map(({ text, value }) => {
          return {
            component: text,
            value
          };
        })}
        statements={shuffledStatements.map(({ lhsComponent, correctAnswer }) => {
          return {
            lhsComponent: (
              <Text variant="WRN700" style={{ textAlign: 'center', width: 200 }}>
                {lhsComponent.toLocaleString()}
              </Text>
            ),
            correctAnswer
          };
        })}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'awG',
  description: 'awG',
  keywords: ['Decimals', 'Partition', 'Tenths', 'Hundredths'],
  schema: z.object({
    givenNumber: z.number().min(1.01).max(9.99),
    fullyPartitionedOptions: z.array(z.number().min(0.01).max(9).array()).length(2),
    flexiblyPartitionedOptions: z.array(z.number().min(0.01).max(10.98).array()).length(5)
  }),
  simpleGenerator: () => {
    const givenNumberOnes = randomIntegerInclusive(1, 9);
    const givenNumberTenths = randomIntegerInclusive(1, 9);
    const givenNumberHundredths = randomIntegerInclusive(1, 9, {
      constraint: x => x !== givenNumberTenths
    });

    const givenNumber = number(`${givenNumberOnes}.${givenNumberTenths}${givenNumberHundredths}`);

    // Split number into digits
    const digits = ScientificNotation.fromNumber(givenNumber).digits;

    // Full partitioning
    const correctPartition = [
      digits[0],
      number(math.evaluate(`${digits[1]} * 0.1`)),
      number(math.evaluate(`${digits[2]} * 0.01`))
    ];
    const incorrectPartition = [
      digits[0],
      number(math.evaluate(`${digits[2]} * 0.1`)),
      number(math.evaluate(`${digits[1]} * 0.01`))
    ];

    const { option1A, option2A, option1B, option2B } = rejectionSample(
      () => {
        // 2 Correct: flexibly partitioned
        const [option1A, option2A] = randomUniqueIntegersInclusive(
          1,
          Math.min(givenNumber * 100 - 1, 998),
          2
        ).map(it => it / 100);
        const option1B = number(math.evaluate(`${givenNumber} - ${option1A}`));
        const option2B = number(math.evaluate(`${givenNumber} - ${option2A}`));

        return { option1A, option2A, option1B, option2B };
      },
      ({ option1A, option2A, option1B, option2B }) =>
        numbersDoNotExchange(option1A, option1B) && numbersDoNotExchange(option2A, option2B)
    );

    const { option3A, option3B } = rejectionSample(
      () => {
        // 3 incorrect answers that are +/- 1 integer/tenth/hundredth from correct
        const choice3 = getRandomFromArray([ADD, SUB]);
        const option3A =
          randomIntegerInclusive(
            1,
            Math.min(givenNumber * 100 + (choice3 === ADD ? 100 : -100) - 1, 998)
          ) / 100;
        const option3B = number(
          math.evaluate(`${givenNumber} + ${choice3 === ADD ? 1 : -1} - ${option3A}`)
        );

        return { option3A, option3B };
      },
      ({ option3A, option3B }) => numbersDoNotExchange(option3A, option3B)
    );

    const { option4A, option4B } = rejectionSample(
      () => {
        const choice4 = getRandomFromArray([ADD, SUB]);
        const option4A =
          randomIntegerInclusive(
            1,
            Math.min(givenNumber * 100 + (choice4 === ADD ? 10 : -10) - 1, 998),
            {
              constraint: x => x !== option3A
            }
          ) / 100;
        const option4B = number(
          math.evaluate(`${givenNumber} + ${choice4 === ADD ? 0.1 : -0.1} - ${option4A}`)
        );

        return { option4A, option4B };
      },
      ({ option4A, option4B }) => numbersDoNotExchange(option4A, option4B)
    );

    const { option5A, option5B } = rejectionSample(
      () => {
        const choice5 = getRandomFromArray([ADD, SUB]);
        const option5A =
          randomIntegerInclusive(
            1,
            Math.min(givenNumber * 100 + (choice5 === ADD ? 1 : -1) - 1, 998),
            {
              constraint: x => x !== option3A && x !== option4A
            }
          ) / 100;
        const option5B = number(
          math.evaluate(`${givenNumber} + ${choice5 === ADD ? 0.01 : -0.01} - ${option5A}`)
        );

        return { option5A, option5B };
      },
      ({ option5A, option5B }) => numbersDoNotExchange(option5A, option5B)
    );

    const fullyPartitionedOptions = [correctPartition, incorrectPartition];
    const flexiblyPartitionedOptions = [
      [option1A, option1B],
      [option2A, option2B],
      [option3A, option3B],
      [option4A, option4B],
      [option5A, option5B]
    ];

    return { givenNumber, fullyPartitionedOptions, flexiblyPartitionedOptions };
  },
  Component: props => {
    const {
      question: { givenNumber, fullyPartitionedOptions, flexiblyPartitionedOptions },
      translate
    } = props;

    const fullyPartitionedItems = fullyPartitionedOptions.map(opt => {
      return {
        component: opt.map(num => num.toLocaleString()).join(` ${ADD} `),
        value: opt.join('+')
      };
    });

    const flexiblyPartitionedItems = flexiblyPartitionedOptions.map(opt => {
      return {
        component: opt.map(num => num.toLocaleString()).join(` ${ADD} `),
        value: opt.join('+')
      };
    });

    const shuffledItems = getRandomSubArrayFromArray(
      [...fullyPartitionedItems, ...flexiblyPartitionedItems],
      6,
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectCalcsThatEqualX(
          givenNumber.toLocaleString(undefined, { minimumFractionDigits: 2 })
        )}
        pdfTitle={translate.instructions.circleCalcsThatEqualX(
          givenNumber.toLocaleString(undefined, { minimumFractionDigits: 2 })
        )}
        testCorrect={shuffledItems
          .filter(item => number(math.evaluate(item.value)) === givenNumber)
          .map(item => item.value)}
        multiSelect
        items={shuffledItems}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'awH',
  description: 'awH',
  keywords: ['Partition', 'Decimals', 'Ones', 'Tenths', 'Hundredths'],
  schema: z.object({
    givenNumber: z.number().min(1.11).max(9.99)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const givenNumber = randomIntegerInclusive(111, 999) / 100;

    return { givenNumber };
  },
  Component: props => {
    const {
      question: { givenNumber },
      translate
    } = props;

    const localeString = givenNumber.toLocaleString(undefined, { minimumFractionDigits: 2 });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.partitionXThreeDifferentWays(localeString)}
        testCorrect={userAnswer => {
          // Check if all answers are valid decimals
          if (userAnswer.some(sentence => sentence.some(ans => !isValidDecimalString(ans)))) {
            return false;
          } else {
            return (
              nestedArrayHasNoDuplicates(userAnswer) &&
              userAnswer.every(
                sentence =>
                  equal(math.evaluate(`${sentence[0]} + ${sentence[1]}`), givenNumber) &&
                  !sentence.includes('0')
              )
            );
          }
        }}
        sentences={[
          `${localeString} = <ans/> ${ADD} <ans/>`,
          `${localeString} = <ans/> ${ADD} <ans/>`,
          `${localeString} = <ans/> ${ADD} <ans/>`
        ]}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        questionHeight={900}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.anyValidPartitions() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FlexiblyPartitionDecimals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
