import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  randomIntegerInclusive,
  getRandomSubArrayFromArray,
  seededRandom,
  shuffle,
  getRandomFromArray
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import { MULT, DIV } from '../../../../constants';
import { useMemo } from 'react';
import { numberEnum } from '../../../../utils/zod';
import { View } from 'react-native';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  objectSchema,
  getRandomObject,
  containerOfObject,
  objectAsWord,
  objectPrepositionWord,
  cheapObjectAsWord,
  cheapObjectSchema,
  getRandomCheapObject
} from '../../../../utils/objects';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { getCheapObjectSvgName, getObjectSvgName } from '../../../../utils/objectsImages';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { AssetSvg } from '../../../../assets/svg';
import QF2DraggableAlignedEquations from '../../../../components/question/questionFormats/QF2DraggableAlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'alz',
  description: 'alz',
  keywords: ['Times-table', '3', '6', '9', 'Pattern'],
  schema: z.object({
    numberPerGroup: z.number().int().min(2).max(6),
    object: objectSchema
  }),
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(2, 6);

    const object = getRandomObject();

    return { numberPerGroup, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, object },
      translate
    } = props;

    const containerPlural = containerOfObject(object, translate, true);
    const containerSingular = containerOfObject(object, translate, false);
    const objectPlural = objectAsWord(object, translate, true);
    const preposition = objectPrepositionWord(object, translate);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.eachContainerHasNumObjects(
          containerSingular,
          numberPerGroup,
          objectPlural
        )}
        sentences={[
          translate.instructions.workOutTheNumOfObjectPreposition(objectPlural, preposition),
          translate.answerSentences.numGroupsOperation(
            3,
            containerPlural,
            `${numberPerGroup} ${MULT} 3 = <ans/>`
          ),
          translate.answerSentences.numGroupsOperation(
            6,
            containerPlural,
            `${numberPerGroup} ${MULT} 6 = <ans/>`
          ),
          translate.answerSentences.numGroupsOperation(
            9,
            containerPlural,
            `${numberPerGroup} ${MULT} 9 = <ans/>`
          )
        ]}
        testCorrect={[
          [],
          [(numberPerGroup * 3).toString()],
          [(numberPerGroup * 6).toString()],
          [(numberPerGroup * 9).toString()]
        ]}
        Content={({ dimens }) => (
          <AssetSvg
            name={getObjectSvgName(object, numberPerGroup)}
            height={dimens.height}
            width={dimens.width}
          />
        )}
        questionHeight={1100}
        pdfDirection="column"
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'alA',
  description: 'alA',
  keywords: ['Times-table', '3', '6', '9', 'Pattern'],
  schema: z.object({
    number1: z.number().int().min(3).max(12)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 12);
    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: number1, right: 3, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: number1, right: 6, sign: 'multiply', answer: 'result' });

    const eqC = getBinOpEquation({ left: number1, right: 9, sign: 'multiply', answer: 'result' });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeMultiplications()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'alB',
  description: 'alB',
  keywords: ['Times-table', '3', '6', '9', 'Equivalent'],
  schema: z.object({
    seed: z.number().int().min(1).max(999)
  }),
  simpleGenerator: () => {
    // Needed in order to get variation each time it's generated
    const seed = randomIntegerInclusive(1, 999);
    return { seed };
  },
  Component: props => {
    const { translate } = props;

    const translatedThree = (3).toLocaleString();
    const translatedSix = (6).toLocaleString();
    const translatedNine = (9).toLocaleString();
    const random = seededRandom(props.question);

    const answerOptions = [
      {
        component: translate.answerSentences.xThenY(`${MULT} ${translatedThree}`, 'double'),
        value: `${MULT} 3 then double`
      },
      {
        component: translate.answerSentences.xThenY(
          `${MULT} ${translatedThree}`,
          `${MULT} ${translatedThree}`
        ),
        value: `${MULT} 3 then ${MULT} 3`
      },
      { component: `${MULT} ${translatedThree}`, value: `${MULT} 3` },
      {
        component: translate.answerSentences.xThenY(`${DIV} ${translatedThree}`, 'halve'),
        value: `${DIV} 3 then halve`
      },
      {
        component: translate.answerSentences.xThenY(
          `${DIV} ${translatedThree}`,
          `${DIV} ${translatedThree}`
        ),
        value: `${DIV} 3 then ${DIV} 3`
      }
    ];

    const statements = useMemo(() => {
      const questionsAndAnswers = [
        {
          statement: translate.answerSentences.xByY('Multiply', translatedThree),
          answer: `${MULT} 3`
        },
        {
          statement: translate.answerSentences.xByY('Multiply', translatedSix),
          answer: `${MULT} 3 then double`
        },
        {
          statement: translate.answerSentences.xByY('Multiply', translatedNine),
          answer: `${MULT} 3 then ${MULT} 3`
        },
        {
          statement: translate.answerSentences.xByY('Divide', translatedSix),
          answer: `${DIV} 3 then halve`
        },
        {
          statement: translate.answerSentences.xByY('Divide', translatedNine),
          answer: `${DIV} 3 then ${DIV} 3`
        }
      ];

      return getRandomSubArrayFromArray(questionsAndAnswers, 4, {
        random
      });
    }, [random, translate.answerSentences, translatedNine, translatedSix, translatedThree]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.matchTheOperations()}
        items={shuffle(
          statements.map(({ answer }) => answerOptions.filter(opt => opt.value === answer)[0]),
          { random }
        )}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ answer }) => [answer])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'alC',
  description: 'alC',
  keywords: ['Times-table', '3', '6', '9', 'Given one find other', 'Object'],
  schema: z.object({
    price: z
      .number()
      .int()
      .min(2)
      .max(13)
      .refine(val => val % 3 !== 0, 'price must not be a multiple of 3'),
    object: cheapObjectSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const price = randomIntegerInclusive(2, 13, {
      constraint: x => x % 3 !== 0
    });

    const object = getRandomCheapObject();

    return { price, object };
  },
  Component: props => {
    const {
      question: { price, object },
      translate
    } = props;

    const objectPlural = cheapObjectAsWord(object, translate, true);

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.whatIsTheCostOfNumObjects(6, objectPlural),
          translate.answerSentences.whatIsTheCostOfNumObjects(9, objectPlural)
        ]}
        title={translate.instructions.theCostOfNumObjectIsNum(3, objectPlural, price)}
        testCorrect={[[(price * 2).toString()], [(price * 3).toString()]]}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 16 }
            ]}
          >
            {range(1, 3).map(index => (
              <View key={index}>
                <AssetSvg
                  name={getCheapObjectSvgName(object)}
                  height={dimens.height * 0.6}
                  width={dimens.width * 0.2}
                />
              </View>
            ))}
          </View>
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'alD',
  description: 'alD',
  keywords: ['Times-table', '3', '6', '9', 'Equivalent'],
  schema: z.object({
    number1A: z.number().int().min(13).max(99),
    number1B: z.number().int().min(13).max(99),
    number1C: z.number().int().min(13).max(99),
    number1D: z.number().int().min(13).max(99)
  }),
  simpleGenerator: () => {
    const number1A = randomIntegerInclusive(13, 99);
    const number1B = randomIntegerInclusive(13, 99);
    const number1C = randomIntegerInclusive(13, 99);
    const number1D = randomIntegerInclusive(13, 99);

    return { number1A, number1B, number1C, number1D };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, number1D },
      translate
    } = props;

    const translatedTwo = (2).toLocaleString();
    const translatedThree = (3).toLocaleString();

    // Shuffle the sentences:
    const statements = useMemo(() => {
      const statement1 = {
        sentence: `${number1A.toLocaleString()} ${MULT} <ans/> ${MULT} ${translatedTwo} = ${number1A.toLocaleString()} ${MULT} ${(6).toLocaleString()}`,
        answer: [3]
      };

      const statement2 = {
        sentence: `${number1B.toLocaleString()} ${MULT} <ans/> = ${number1B.toLocaleString()} ${MULT} ${translatedThree} ${MULT} ${translatedThree}`,
        answer: [9]
      };

      const statement3 = {
        sentence: `${number1C.toLocaleString()} ${MULT} <ans/> ${MULT} <ans/> = ${number1C.toLocaleString()} ${MULT} ${(9).toLocaleString()}`,
        answer: [3, 3]
      };

      const statement4 = {
        sentence: `${number1D.toLocaleString()} ${MULT} <ans/> = ${number1D.toLocaleString()} ${MULT} ${translatedTwo} ${MULT} ${translatedThree}`,
        answer: [6]
      };

      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [number1A, number1B, number1C, number1D, props.question, translatedThree, translatedTwo]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardOnlyUsedOnce()}
        pdfLayout="itemsRight"
        items={[3, 6, 9]}
        sentences={statements.map(statement => statement.sentence)}
        testCorrect={statements.map(statement => statement.answer)}
        moveOrCopy="copy"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5V2 = newQuestionContent({
  uid: 'alD2',
  description: 'alD',
  keywords: ['Times-table', '3', '6', '9', 'Equivalent'],
  schema: z.object({
    number1A: z.number().int().min(13).max(99),
    number1B: z.number().int().min(13).max(99),
    number1C: z.number().int().min(13).max(99),
    number1D: z.number().int().min(13).max(99)
  }),
  simpleGenerator: () => {
    const number1A = randomIntegerInclusive(13, 99);
    const number1B = randomIntegerInclusive(13, 99);
    const number1C = randomIntegerInclusive(13, 99);
    const number1D = randomIntegerInclusive(13, 99);

    return { number1A, number1B, number1C, number1D };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, number1D },
      translate
    } = props;

    const translatedTwo = (2).toLocaleString();
    const translatedThree = (3).toLocaleString();

    // Shuffle the sentences:
    const statements: { left: string; right: string; answer: number[] }[] = useMemo(() => {
      const statement1 = {
        left: `${number1A.toLocaleString()} ${MULT} <ans/> ${MULT} ${translatedTwo}`,
        right: `${number1A.toLocaleString()} ${MULT} ${(6).toLocaleString()}`,
        answer: [3]
      };

      const statement2 = {
        left: `${number1B.toLocaleString()} ${MULT} <ans/>`,
        right: `${number1B.toLocaleString()} ${MULT} ${translatedThree} ${MULT} ${translatedThree}`,
        answer: [9]
      };

      const statement3 = {
        left: `${number1C.toLocaleString()} ${MULT} <ans/> ${MULT} <ans/>`,
        right: `${number1C.toLocaleString()} ${MULT} ${(9).toLocaleString()}`,
        answer: [3, 3]
      };

      const statement4 = {
        left: `${number1D.toLocaleString()} ${MULT} <ans/>`,
        right: `${number1D.toLocaleString()} ${MULT} ${translatedTwo} ${MULT} ${translatedThree}`,
        answer: [6]
      };

      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [number1A, number1B, number1C, number1D, props.question, translatedThree, translatedTwo]);

    // All answers are on the lhs
    const answers = statements.map(({ answer }) => ({ left: answer }));

    return (
      <QF2DraggableAlignedEquations
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardCanBeUsedMoreThanOnce()}
        items={[3, 6, 9].map(value => ({ value, component: value.toLocaleString() }))}
        sentences={statements}
        testCorrect={answers}
        moveOrCopy="copy"
        pdfLayout="itemsRight"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'alE',
  description: 'alE',
  keywords: ['Times-table', '3', '6', '9', 'Given one find other'],
  schema: z
    .object({
      multiplicand: numberEnum([11, 12, 14, 15, 17, 18, 20, 21, 24, 25, 30, 31, 40, 50]),
      multiplierA: numberEnum([3, 6]),
      multiplierB: numberEnum([3, 6, 9]),
      multiplierC: numberEnum([3, 6, 9])
    })
    .refine(
      val => arrayHasNoDuplicates([val.multiplierA, val.multiplierB, val.multiplierC]),
      'All multipliers must be different.'
    ),
  simpleGenerator: () => {
    const multiplicand = getRandomFromArray([
      11, 12, 14, 15, 17, 18, 20, 21, 24, 25, 30, 31, 40, 50
    ] as const);

    const multiplierA = getRandomFromArray([3, 6] as const);

    const [multiplierB, multiplierC] = getRandomSubArrayFromArray([3, 6, 9] as const, 2, {
      constraint: x => x !== multiplierA
    });

    return { multiplicand, multiplierA, multiplierB, multiplierC };
  },
  Component: props => {
    const {
      question: { multiplicand, multiplierA, multiplierB, multiplierC },
      translate
    } = props;
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.useTheFactThatToWorkOut(
          multiplicand,
          multiplierA,
          multiplicand * multiplierA
        )}
        sentences={[
          `${multiplicand.toLocaleString()} ${MULT} ${multiplierB.toLocaleString()} = <ans/>`,
          `${multiplicand.toLocaleString()} ${MULT} ${multiplierC.toLocaleString()} = <ans/>`
        ]}
        testCorrect={[
          [(multiplicand * multiplierB).toString()],
          [(multiplicand * multiplierC).toString()]
        ]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The36And9TimesTables',
  questionTypes: [Question1, Question2, Question3, Question4, Question5V2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
