import { useContext, useMemo, useRef } from 'react';
import { View, Platform, StyleProp, ViewStyle } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  SharedValue,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated';

import { ScaleFactorContext } from '../../../theme/scaling';
import { withStateHOC } from '../../../stateTree';
import { AssetSvg, SvgName, getSvgInfo } from '../../../assets/svg';
import { noop } from '../../../utils/flowControl';
import { colors } from '../../../theme/colors';
import DynamicScale from './DynamicScale';

type Weight = number;

// For some of the scales, the center of the scale is not in the center of the image.
// In order for the arrow to be aligned, add some white space to the bottom of the svg before adding here
const scalesSvgArray: SvgName[] = [
  'Scales/1000g_scale_50g_100g',
  'Scales/1000g_scale_25g_100g',
  'Scales/1000g_scale_20g_100g',
  'Scales/500g_scale_10g',
  'Scales/500g_scale_20g',
  'Scales/500g_scale_25g',
  'Scales/1_kg_scale_50g',
  'Scales/4_kg_scale_100_g',
  'Scales/5_kg_scale_100_g',
  'Scales/100g_scale_10g',
  'Scales/1_kg_scale_500_g',
  'Scales/3_kg_scale_500_g',
  'Scales/4_kg_scale_500_g',
  'Scales/5_kg_scale_500_g',
  'Scales/6_kg_scale_500_g',
  'Scales/1_kg_scale_250_g',
  'Scales/3_kg_scale_250_g',
  'Scales/4_kg_scale_250_g',
  'Scales/5_kg_scale_250_g',
  'Scales/6_kg_scale_250_g',
  'Scales/1_kg_scale_200_g',
  'Scales/3_kg_scale_200_g',
  'Scales/4_kg_scale_200_g',
  'Scales/5_kg_scale_200_g',
  'Scales/6_kg_scale_200_g',
  'Scales/1000g_scale_100g',
  'Scales/1000g_scale_200g',
  'Scales/1000g_scale_250g',
  'Scales/Various_kg_scale_4kg',
  'Scales/Various_kg_scale_5kg',
  'Scales/Various_kg_scale_6kg',
  'Scales/Various_kg_scale_7kg',
  'Scales/Various_kg_scale_8kg',
  'Scales/Various_kg_scale_9kg',
  'Scales/Various_kg_scale_10kg'
];

export type ScalesSvg = (typeof scalesSvgArray)[number];
const svgDetails: {
  svgName: ScalesSvg;
  maxKg: number;
  numberOfIntervals: number;
  blankAmount: number;
}[] = [
  {
    svgName: 'Scales/1000g_scale_50g_100g',
    maxKg: 1,
    numberOfIntervals: 20,
    blankAmount: 0.1
  },
  {
    svgName: 'Scales/1000g_scale_25g_100g',
    maxKg: 1,
    numberOfIntervals: 40,
    blankAmount: 0.1
  },
  {
    svgName: 'Scales/1000g_scale_20g_100g',
    maxKg: 1,
    numberOfIntervals: 50,
    blankAmount: 0.1
  },
  {
    svgName: 'Scales/500g_scale_10g',
    maxKg: 0.5,
    numberOfIntervals: 50,
    blankAmount: 0.05
  },
  {
    svgName: 'Scales/500g_scale_20g',
    maxKg: 0.5,
    numberOfIntervals: 25,
    blankAmount: 0.05
  },
  {
    svgName: 'Scales/500g_scale_25g',
    maxKg: 0.5,
    numberOfIntervals: 20,
    blankAmount: 0.05
  },
  { svgName: 'Scales/1_kg_scale_50g', maxKg: 1, numberOfIntervals: 20, blankAmount: 0.1 },
  { svgName: 'Scales/3_kg_scale_100_g', maxKg: 3, numberOfIntervals: 30, blankAmount: 0.28 },
  { svgName: 'Scales/4_kg_scale_100_g', maxKg: 4, numberOfIntervals: 40, blankAmount: 0.24 },
  { svgName: 'Scales/5_kg_scale_100_g', maxKg: 5, numberOfIntervals: 50, blankAmount: 0.3 },
  { svgName: 'Scales/100g_scale_10g', maxKg: 0.1, numberOfIntervals: 20, blankAmount: 0.01 },
  { svgName: 'Scales/100g_scale_20g', maxKg: 0.1, numberOfIntervals: 5, blankAmount: 0.01 },
  { svgName: 'Scales/200g_scale_10g', maxKg: 0.2, numberOfIntervals: 20, blankAmount: 0.02 },
  { svgName: 'Scales/200g_scale_20g', maxKg: 0.2, numberOfIntervals: 10, blankAmount: 0.02 },
  { svgName: 'Scales/200g_scale_100g', maxKg: 0.2, numberOfIntervals: 2, blankAmount: 0.02 },
  { svgName: 'Scales/1_kg_scale_500_g', maxKg: 1, numberOfIntervals: 2, blankAmount: 0.1 },
  { svgName: 'Scales/3_kg_scale_500_g', maxKg: 3, numberOfIntervals: 6, blankAmount: 0.27 },
  { svgName: 'Scales/4_kg_scale_500_g', maxKg: 4, numberOfIntervals: 8, blankAmount: 0.17 },
  { svgName: 'Scales/5_kg_scale_500_g', maxKg: 5, numberOfIntervals: 10, blankAmount: 0.32 },
  { svgName: 'Scales/6_kg_scale_500_g', maxKg: 6, numberOfIntervals: 12, blankAmount: 0.55 },
  { svgName: 'Scales/1_kg_scale_250_g', maxKg: 1, numberOfIntervals: 4, blankAmount: 0.1 },
  { svgName: 'Scales/3_kg_scale_250_g', maxKg: 3, numberOfIntervals: 12, blankAmount: 0.27 },
  { svgName: 'Scales/4_kg_scale_250_g', maxKg: 4, numberOfIntervals: 16, blankAmount: 0.25 },
  { svgName: 'Scales/5_kg_scale_250_g', maxKg: 5, numberOfIntervals: 20, blankAmount: 0.32 },
  { svgName: 'Scales/6_kg_scale_250_g', maxKg: 6, numberOfIntervals: 24, blankAmount: 0.55 },
  { svgName: 'Scales/1_kg_scale_200_g', maxKg: 1, numberOfIntervals: 5, blankAmount: 0.1 },
  { svgName: 'Scales/3_kg_scale_200_g', maxKg: 3, numberOfIntervals: 15, blankAmount: 0.25 },
  { svgName: 'Scales/4_kg_scale_200_g', maxKg: 4, numberOfIntervals: 20, blankAmount: 0.25 },
  { svgName: 'Scales/5_kg_scale_200_g', maxKg: 5, numberOfIntervals: 25, blankAmount: 0.27 },
  { svgName: 'Scales/6_kg_scale_200_g', maxKg: 6, numberOfIntervals: 30, blankAmount: 0.52 },
  { svgName: 'Scales/1000g_scale_100g', maxKg: 1, numberOfIntervals: 10, blankAmount: 0.11 },
  { svgName: 'Scales/1000g_scale_200g', maxKg: 1, numberOfIntervals: 5, blankAmount: 0.11 },
  { svgName: 'Scales/1000g_scale_250g', maxKg: 1, numberOfIntervals: 4, blankAmount: 0.11 },
  { svgName: 'Scales/Various_kg_scale_4kg', maxKg: 4, numberOfIntervals: 4, blankAmount: 0.2 },
  { svgName: 'Scales/Various_kg_scale_5kg', maxKg: 5, numberOfIntervals: 5, blankAmount: 0.27 },
  { svgName: 'Scales/Various_kg_scale_6kg', maxKg: 6, numberOfIntervals: 6, blankAmount: 0.5 },
  { svgName: 'Scales/Various_kg_scale_7kg', maxKg: 7, numberOfIntervals: 7, blankAmount: 0.3 },
  { svgName: 'Scales/Various_kg_scale_8kg', maxKg: 8, numberOfIntervals: 8, blankAmount: 0.5 },
  { svgName: 'Scales/Various_kg_scale_9kg', maxKg: 9, numberOfIntervals: 9, blankAmount: 0.68 },
  { svgName: 'Scales/Various_kg_scale_10kg', maxKg: 10, numberOfIntervals: 10, blankAmount: 0.6 },
  { svgName: 'Scales/kg_scale_2', maxKg: 1, numberOfIntervals: 2, blankAmount: 0 },
  { svgName: 'Scales/kg_scale_3', maxKg: 1, numberOfIntervals: 3, blankAmount: 0 },
  { svgName: 'Scales/kg_scale_4', maxKg: 1, numberOfIntervals: 4, blankAmount: 0 }
];
function getScaleSvgDetails(svgName: SvgName) {
  return svgDetails.filter(val => val.svgName === svgName)[0];
}

export const defaultWeightImages = [
  'Bag_of_Flour',
  'Bag_of_Pasta',
  'Bag_of_Rice',
  'Bag_of_white_sugar'
] as const;

export type DynamicScaleInfo = {
  /**
   * Default g.
   */
  labels?: 'kg' | 'g';
  /**
   * Scale capacity in "g"
   */
  maxScale: number;
  /**
   * Major labelled intervals in "g"
   */
  majorTicks: number;
  /**
   * Minor intervals in "g".
   */
  minorTicks?: number;
  /**
   * Amount in g for the arrow to incrementally snap to.
   * If left undefined, the arrow will simply snap to the minor or major tick values.
   */
  snapToNearest?: number;
};

export type ScaleProps = {
  weightG?: Weight;
  onWeightChanged?: (weight: Weight) => void;
  /** Whether the component is interactive, i.e. the scale hand can be moved. Defaults to true. */
  interactive?: boolean;
  /**
   * (Interactive mode only) whether to snap the hands positions to the nearest allowed position whilst dragging.
   * Defaults to true.
   */
  snapHandsToNearest?: boolean;
  /** Whether the component shows hands, this is mainly used for the PDF. Defaults to true */
  showHand?: boolean;
  /** This component is always square: width = height. (excluding the item on top) */
  scaleWidth: number;
  /** @deprecated Use the dynamic scale if you can */
  svgName?: ScalesSvg;
  /** Single or an array of SVGs to sit on top of the scale */
  weightImage?: SvgName | SvgName[];
  /**
   * Width of image that sits on scale - default 35% of scale width.
   * Single width passed will be applied to all weightImages.
   * Array of widths will only be applied a corresponding array of weightImages.
   */
  weightImageWidth?: number | number[];
  weightStyle?: StyleProp<ViewStyle>;
  dynamicScale?: DynamicScaleInfo;
};

/**
 * Manipulative of a Scale.
 *
 * This is a controlled component. However, {@link Scales.onWeightChanged} is only called when the user releases a
 * hand, finishing the gesture.
 */
export default function Scales({
  weightG = 0,
  onWeightChanged = noop,
  interactive = false,
  snapHandsToNearest = true,
  showHand = true,
  scaleWidth,
  svgName = 'Scales/blank_scale',
  weightImage,
  weightImageWidth,
  weightStyle,
  dynamicScale
}: ScaleProps) {
  const scaleFactor = useContext(ScaleFactorContext);

  // Constants
  // Position the interactive handles on each hand. These fractions were obtained empirically.
  const handleWidth = scaleWidth * 0.24;
  const handleOffset = scaleWidth * 0.15;

  // work out the height of the scale
  const svgInfo = useMemo(() => getSvgInfo(svgName), [svgName]);
  const { aspectRatio, width: naturalWidth } = svgInfo;
  const height = scaleWidth / aspectRatio;

  // get intervals and maxKg
  const { maxKg, numberOfIntervals, blankAmount } = dynamicScale
    ? {
        maxKg: dynamicScale.maxScale / 1000,
        numberOfIntervals:
          dynamicScale.maxScale /
          (dynamicScale.snapToNearest ?? dynamicScale.minorTicks ?? dynamicScale.majorTicks),

        blankAmount: undefined
      }
    : getScaleSvgDetails(svgName);

  const intervalValue = (maxKg / numberOfIntervals) * 1000;

  // Animated value for the weight to display
  const animatedWeight = useSharedValue(weightG);

  // Angle constants, the dynamicScale has no blankAmount and the scale will use 11/6 π (330°) of the scale face
  const G_PER_RAD = blankAmount
    ? (maxKg * 1000 + blankAmount * 1000) / (2 * Math.PI)
    : (maxKg * 1000) / ((11 / 6) * Math.PI);
  const RADS_PER_G = 1 / G_PER_RAD;

  // If `weight` changes from outside, update the animated value
  const lastWeight = useRef(weightG);
  if (lastWeight.current !== weightG) {
    lastWeight.current = weightG;
    animatedWeight.value = weightG;
  }

  // Gesture handlers for dragging on the hands handles - updates animated value
  const weightHandGesture = useGesture(
    animatedWeight,
    handleWidth,
    handleOffset,
    scaleFactor,
    onWeightChanged,
    maxKg,
    snapHandsToNearest,
    intervalValue
  );

  // Styles using animated value to show the correct weight
  const handRotationStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: `${handAngle(animatedWeight.value, RADS_PER_G)}rad` }]
    };
  }, [RADS_PER_G, animatedWeight]);
  const handHandleStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { rotate: `${handAngle(animatedWeight.value, RADS_PER_G)}rad` },
        { translateY: -handleOffset }
      ]
    };
  }, [RADS_PER_G, animatedWeight, handleOffset]);

  // For the dynamic scale, the arrow uses the same dimens as the scale face which is a square
  const arrowHeight = dynamicScale ? scaleWidth : height;

  return (
    <View>
      <View
        style={[
          {
            width: scaleWidth,
            alignItems: 'flex-end',
            flexDirection: 'row',
            justifyContent: 'center'
          },
          weightStyle
        ]}
      >
        {weightImage && typeof weightImage === 'string' && (
          <AssetSvg
            name={weightImage}
            width={
              weightImageWidth && typeof weightImageWidth === 'number'
                ? weightImageWidth
                : scaleWidth * 0.35
            }
          />
        )}
        {weightImage &&
          typeof weightImage === 'object' &&
          weightImage.map((image, i) => (
            <AssetSvg
              key={`${image}_${i}`}
              name={image}
              width={
                weightImageWidth
                  ? typeof weightImageWidth === 'number'
                    ? weightImageWidth
                    : weightImageWidth[i]
                  : scaleWidth * 0.35
              }
            />
          ))}
      </View>
      <View style={{ width: scaleWidth }}>
        {dynamicScale ? (
          DynamicScale({
            svgName,
            scaleFactor: scaleWidth / naturalWidth,
            scaleWidth,
            height,
            radsPerG: RADS_PER_G,
            ...dynamicScale
          })
        ) : (
          <AssetSvg name={svgName} width={scaleWidth} />
        )}

        {showHand && (
          // Hands
          <>
            <Animated.View
              style={[
                {
                  width: scaleWidth,
                  height: arrowHeight,
                  position: 'absolute',
                  // lower the center because of the scale "plate"
                  top: dynamicScale ? height * 0.06 : undefined,
                  zIndex: 5
                },
                handRotationStyle
              ]}
            >
              <AssetSvg name="Scales/arrow" height={arrowHeight} width={scaleWidth} />
            </Animated.View>
            {/* If show hands & interactive */}
            {interactive && (
              // Grabable Circles on Hands
              <>
                <View
                  pointerEvents="box-none"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 5
                  }}
                >
                  <GestureDetector gesture={weightHandGesture}>
                    <Animated.View
                      style={[
                        {
                          zIndex: 1,
                          width: handleWidth,
                          height: handleWidth,
                          borderRadius: 1000,
                          backgroundColor: 'transparent',
                          justifyContent: 'center',
                          alignItems: 'center'
                        },
                        handHandleStyle
                      ]}
                    >
                      <View
                        style={{
                          width: handleWidth * 0.6,
                          height: handleWidth * 0.6,
                          borderRadius: 1000,
                          borderWidth: 2,
                          backgroundColor: colors.burntSienna
                        }}
                      />
                    </Animated.View>
                  </GestureDetector>
                </View>
              </>
            )}
          </>
        )}
      </View>
    </View>
  );
}

/**
 * Gesture handler for either the scale hand. Pulled out into a separate function
 */
function useGesture(
  animatedWeight: SharedValue<number>,
  handleWidth: number,
  handleOffset: number,
  scaleFactor: number,
  onWeightChanged: (weight: Weight) => void,
  maxKg: number,
  snapHandsToNearest: boolean,
  intervalValue: number
) {
  const startPositionFromCenter = useSharedValue<{ x: number; y: number } | null>(null);
  // Used to calculate translationX/Y since we don't trust those values from the event. (Scaled.)
  const beginAbsX = useSharedValue<number | null>(null);
  const beginAbsY = useSharedValue<number | null>(null);

  const G_PER_RAD = (maxKg * 1000) / (2 * Math.PI);
  const RADS_PER_G = 1 / G_PER_RAD;

  return useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginAbsX.value = event.absoluteX;
          beginAbsY.value = event.absoluteY;

          let { x, y } = event;
          if (Platform.OS === 'web') {
            // On web these values are post-scaling-transformation, so unscale to get back to the Scales's coordinate
            // system
            x /= scaleFactor;
            y /= scaleFactor;
          }

          // Work out where the start position (as given by this event) is relative to the scale center, and store it off
          // in the context.
          // To do this, we use the following vectors:
          // 1) scale center -> handle center
          // 2) origin -> handle center
          // 3) origin -> start position
          // where the origin is (0, 0) in the coordinate system of the event.

          // For (1), we calculate using the handle's rotation around the scale face and its offset.
          const handleAngle = handAngle(animatedWeight.value, RADS_PER_G);
          const handleCenterFromClockCenter = {
            x: Math.sin(handleAngle) * handleOffset,
            y: -(Math.cos(handleAngle) * handleOffset) // Negative because, in canvas coordinates, y points down.
          };

          // For (2), it stands to reason that the origin is the top left of the handle, which is square.
          // However, the handle may have been rotated, and in that case the coordinates actually originate from the top
          // left of the *smallest unrotated square that bounds the handle*. This can be easily calculated from the
          // handle's width and rotation, and the handle's center is in the middle of this box.
          const boundingBoxWidth =
            handleWidth * (Math.abs(Math.sin(handleAngle)) + Math.abs(Math.cos(handleAngle)));
          const handleCenterFromOrigin = {
            x: boundingBoxWidth / 2,
            y: boundingBoxWidth / 2
          };

          // For (3), this is easy - it's just the event's x/y coordinates by definition!
          const startPositionFromOrigin = { x, y };

          // Now we use vector addition/subtraction to get the vector we want.
          startPositionFromCenter.value = {
            x: handleCenterFromClockCenter.x - handleCenterFromOrigin.x + startPositionFromOrigin.x,
            y: handleCenterFromClockCenter.y - handleCenterFromOrigin.y + startPositionFromOrigin.y
          };
        })

        .onUpdate(event => {
          // Calculate translationX/Y manually rather than using the one from the event, since the one from the event
          // is the diff since the second onUpdate, which is wrong because the pointer might have already moved before
          // then!
          const translationX = (event.absoluteX - beginAbsX.value!) / scaleFactor;
          const translationY = (event.absoluteY - beginAbsY.value!) / scaleFactor;

          const currentPositionFromClockCenter = {
            x: startPositionFromCenter.value!.x + translationX,
            y: startPositionFromCenter.value!.y + translationY
          };

          let angleToCurrentPosition = Math.atan2(
            currentPositionFromClockCenter.x,
            -currentPositionFromClockCenter.y // Negative because, in canvas coordinates, y points down.
          );
          if (angleToCurrentPosition < 0) {
            angleToCurrentPosition += 2 * Math.PI;
          }

          let newWeight = animatedWeight.value;
          const gs = angleToCurrentPosition * G_PER_RAD;
          const remainder = gs % intervalValue;
          const weight = snapHandsToNearest
            ? remainder < intervalValue / 2
              ? gs - remainder
              : gs - remainder + intervalValue
            : gs;
          newWeight = weight;
          animatedWeight.value = newWeight;
        })

        .onFinalize(() => {
          // The gesture has finished. Update ancestors with new weight.
          runOnJS(onWeightChanged)(animatedWeight.value);
        }),
    [
      G_PER_RAD,
      RADS_PER_G,
      animatedWeight,
      beginAbsX,
      beginAbsY,
      handleOffset,
      handleWidth,
      intervalValue,
      onWeightChanged,
      scaleFactor,
      snapHandsToNearest,
      startPositionFromCenter
    ]
  );
}

const handAngle = (weight: number, RADS_PER_G: number): number => {
  'worklet';
  return weight * RADS_PER_G;
};

export const ScalesWithState = withStateHOC(Scales, {
  stateProp: 'weightG',
  setStateProp: 'onWeightChanged',
  defaults: {
    defaultState: 0
  }
});
