import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import { z } from 'zod';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import ContentBox from '../../../../components/molecules/ContentBox';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { range } from '../../../../utils/collections';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { DIV } from '../../../../constants';
import Division from '../../../../components/question/representations/ColumnOperations/Division';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'arM',
  description: 'arM',
  keywords: ['Divide', 'Multiples', 'Long division', 'Remainders', 'Written method'],
  schema: z
    .object({
      divisor: z.number().int().min(13).max(19),
      number: z.number().int().min(100).max(1300)
    })
    .refine(val => val.number % val.divisor !== 0, 'number must not be a multiple of divisor'),
  questionHeight: 1200,
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(13, 19);

    // Maths taken directly from powerpoint to calculate number
    const number8 = randomIntegerInclusiveStep(20, 60, 10);
    const number9 = randomIntegerInclusive(1, 6);
    const number10 = number8 + number9;
    const number11 = divisor * number10;
    const number12 = randomIntegerInclusive(1, divisor - 1);
    const number13 = number11 + number12;

    return { divisor, number: number13 };
  },
  Component: props => {
    const {
      question: { divisor, number },
      translate,
      displayMode
    } = props;

    const integerAnswer = Math.floor(number / divisor);

    const remainder = number % divisor;

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${translate.misc.remainderSymbol()} <ans/>`}
        title={translate.instructions.useTheseMultsOfNumToCompleteLongDivision(divisor)}
        testCorrect={[integerAnswer.toString(), remainder.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => {
          const divisionHeight = (dimens.height / 6) * 5;
          return (
            <>
              <View
                style={{ flexDirection: 'row', marginBottom: displayMode === 'digital' ? 8 : 32 }}
              >
                {range(1, 7).map(num => (
                  <ContentBox
                    key={num}
                    containerStyle={{ width: dimens.width / 10, marginHorizontal: 4, padding: 8 }}
                  >
                    <Text
                      variant="WRN400"
                      style={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
                    >
                      {(divisor * num).toLocaleString()}
                    </Text>
                  </ContentBox>
                ))}
              </View>
              <Division
                divisor={divisor}
                dividend={number}
                noExchangeBoxes
                dimens={{ width: dimens.width, height: divisionHeight }}
                extraRows={4}
                labelCellFontStyle={{
                  fontSize: displayMode === 'digital' ? 28 : 50,
                  lineHeight: displayMode === 'digital' ? 36 : 75
                }}
              />
            </>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [integerAnswer.toLocaleString(), remainder.toLocaleString()],
          answerText: translate.markScheme.withValidDivision()
        }}
        questionHeight={1200}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'arN',
  description: 'arN',
  keywords: ['Divide', 'Long division', 'Remainders', 'Written method'],
  schema: z
    .object({
      divisor: z.number().int().min(13).max(19),
      number: z.number().int().min(1001).max(9999)
    })
    .refine(val => val.number % val.divisor !== 0, 'number must not be a multiple of divisor'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(13, 19);

    const number = randomIntegerInclusive(1001, 9999, {
      constraint: x => x % divisor !== 0
    });

    return { divisor, number };
  },
  Component: props => {
    const {
      question: { divisor, number },
      translate
    } = props;

    const integerAnswer = Math.floor(number / divisor);

    const remainder = number % divisor;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheDivision()}
        testCorrect={[integerAnswer.toString(), remainder.toString()]}
        sentence={`${number.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/> ${translate.misc.remainderSymbol()} <ans/>`}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'arO',
  description: 'arO',
  keywords: ['Divide', 'Long division', 'Remainders', 'Written method'],
  schema: z
    .object({
      staff: z.number().int().min(101).max(999),
      managers: z.number().int().min(13).max(29),
      directors: z.number().int().min(3).max(9),
      seatsPerTable: z.number().int().min(11).max(19)
    })
    .refine(
      val => (val.staff + val.managers + val.directors) % val.seatsPerTable !== 0,
      'staff + managers + directors must not total to a multiple of seatsPerTable.'
    ),
  simpleGenerator: () => {
    const seatsPerTable = randomIntegerInclusive(11, 19);

    const { staff, managers, directors } = rejectionSample(
      () => {
        const staff = randomIntegerInclusive(101, 999);

        const managers = randomIntegerInclusive(13, 29);

        const directors = randomIntegerInclusive(3, 9);

        return { staff, managers, directors };
      },
      ({ staff, managers, directors }) => (staff + managers + directors) % seatsPerTable !== 0
    );

    return { staff, managers, directors, seatsPerTable };
  },
  Component: props => {
    const {
      question: { staff, managers, directors, seatsPerTable },
      translate
    } = props;

    const answer = Math.ceil((staff + managers + directors) / seatsPerTable);

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.aCompanyHasNumMembersOfStaff(
          staff,
          managers,
          directors
        )}<br/>${translate.answerSentences.everyoneIsInvitedToASpecialMeal()}<br/>${translate.answerSentences.eachTableSeatsNumPeople(
          seatsPerTable
        )}<br/>${translate.answerSentences.howManyTablesAreNeeded()}`}
        testCorrect={[answer.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{
          justifyContent: 'flex-end',
          alignSelf: 'flex-end'
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'arP',
  description: 'arP',
  keywords: ['Divide', 'Long division', 'Remainders', 'Written method'],
  schema: z
    .object({
      divisor: z.number().int().min(13).max(19),
      number: z.number().int().min(101).max(999)
    })
    .refine(val => val.number % val.divisor !== 0, 'number must not be a multiple of divisor'),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(13, 19);

    const number = randomIntegerInclusive(101, 999, {
      constraint: x => x % divisor !== 0
    });

    return { divisor, number };
  },
  Component: props => {
    const {
      question: { divisor, number },
      translate
    } = props;

    const integerAnswer = Math.floor(number / divisor);

    const remainder = number % divisor;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheDivision()}
        testCorrect={[integerAnswer.toString(), remainder.toString()]}
        sentence={`${number.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/> ${translate.misc.remainderSymbol()} <ans/>`}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'arQ',
  description: 'arQ',
  keywords: ['Divide', 'Long division', 'Remainders', 'Written method'],
  schema: z
    .object({
      paperPerNewspaper: z.number().int().min(12).max(48).multipleOf(2),
      paperPerPacket: z.number().int().min(200).max(300).multipleOf(10)
    })
    .refine(
      val => val.paperPerPacket % val.paperPerNewspaper !== 0,
      'paperPerPacket must not be a multiple of paperPerNewspaper.'
    ),
  simpleGenerator: () => {
    const paperPerNewspaper = randomIntegerInclusiveStep(12, 48, 2);

    const paperPerPacket = randomIntegerInclusiveStep(200, 300, 10, {
      constraint: x => x % paperPerNewspaper !== 0
    });

    return { paperPerNewspaper, paperPerPacket };
  },
  Component: props => {
    const {
      question: { paperPerNewspaper, paperPerPacket },
      translate
    } = props;

    const packets = 10;

    const answer = Math.floor((paperPerPacket * packets) / paperPerNewspaper);

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.msSmithIsPrintingTheSchoolNewspaper()}<br/>${translate.answerSentences.eachNewspaperNeedNumSheets(
          paperPerNewspaper
        )}<br/>${translate.answerSentences.paperComesInPacketsOfNum(
          paperPerPacket
        )}<br/>${translate.answerSentences.howManyNewspapersCanMsSmithPrint(packets)}`}
        testCorrect={[answer.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'arR',
  description: 'arR',
  keywords: ['Divide', 'Long division', 'Remainders', 'Written method'],
  schema: z.object({
    lorryCrates: z.number().int().min(21).max(99),
    cratesProduced: z.number().int().min(500).max(9900).multipleOf(50),
    food: z.enum(['Apples', 'Bananas', 'Mangoes', 'Lemons', 'Oranges', 'Pears', 'Strawberries'])
  }),
  simpleGenerator: () => {
    const cratesProduced = randomIntegerInclusiveStep(500, 9900, 50);

    const lorryCrates = randomIntegerInclusive(21, 99, {
      constraint: x => cratesProduced % x !== 0
    });

    const food = getRandomFromArray([
      'Apples',
      'Bananas',
      'Mangoes',
      'Lemons',
      'Oranges',
      'Pears',
      'Strawberries'
    ] as const);

    return { lorryCrates, cratesProduced, food };
  },
  Component: props => {
    const {
      question: { lorryCrates, cratesProduced, food },
      translate
    } = props;

    const answer = Math.ceil(cratesProduced / lorryCrates);

    return (
      <QF2AnswerBoxOneSentence
        title={`${translate.instructions.farmProducesXCratesOfYFoodLorryCarriesYCrates(
          cratesProduced,
          food,
          lorryCrates
        )}<br/>${translate.instructions.howManyTripsWillItTakeToTransportCrates()}`}
        testCorrect={[answer.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignSelf: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LongDivisionWithRemainders',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
