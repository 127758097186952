import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { multiplesNumberTrackArray } from '../../../../utils/multiples';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { range } from '../../../../utils/collections';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aFw',
  description: 'aFw',
  keywords: ['Track', 'Multiples'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(920).multipleOf(10)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(0, 920, 10);
    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    const { numberTrackArray, answerArray } = multiplesNumberTrackArray(startingNumber, 10, 5, 2);

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberTrackArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aFx',
  description: 'aFx',
  keywords: ['Track', 'Multiples'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(940).multipleOf(10)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(0, 940, 10);
    return { startingNumber };
  },
  Component: props => {
    const {
      question: { startingNumber },
      translate
    } = props;
    const answerBoxIndexs = getRandomFromArray(
      [
        [0, 2],
        [1, 3],
        [2, 4],
        [3, 5]
      ],
      { random: seededRandom(props.question) }
    );

    const allNumbers = range(0, 5).map(index => startingNumber + index * 10);

    const boxValues: string[] = [];
    const answers: string[] = [];

    allNumbers.forEach((num, index) => {
      if (answerBoxIndexs.includes(index)) {
        boxValues.push(num.toString());
      } else {
        boxValues.push('<ans/>');
        answers.push(num.toLocaleString());
      }
    });

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={boxValues}
        testCorrect={answers}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aFy',
  description: 'aFy',
  keywords: ['Number line', 'Multiples'],
  schema: z.object({
    startingNumber: z.number().int().min(10).max(890).multipleOf(10),
    answerBoxIndex: z.number().int().min(3).max(8)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(10, 890, 10);

    const answerBoxIndex = randomIntegerInclusive(3, 8);

    return { startingNumber, answerBoxIndex };
  },
  Component: props => {
    const {
      question: { startingNumber, answerBoxIndex },
      translate
    } = props;

    const answerIndex = answerBoxIndex === 3 ? 4 : answerBoxIndex;

    // Create array to pass to Number Line
    const tickValues = range(startingNumber, startingNumber + 90, 10).map((number, index) => {
      return number === startingNumber ||
        number === startingNumber + 10 ||
        number === startingNumber + 20 ||
        number === startingNumber + 90
        ? (startingNumber + 10 * index).toLocaleString()
        : number === startingNumber + answerIndex * 10
        ? '<ans/>'
        : '';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.fillInMissingNumber()}
        testCorrect={[(startingNumber + answerIndex * 10).toString()]}
        tickValues={tickValues}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aFz',
  description: 'aFz',
  keywords: ['Multiplication', '10'],
  schema: z.object({
    number1: z.number().int().min(0).max(99),
    number2: z.number().int().min(0).max(99),
    number3: z.number().int().min(0).max(99),
    number4: z.number().int().min(0).max(99)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number1, number2, number3, number4] = randomUniqueIntegersInclusive(0, 99, 4);
    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const eq1 = getBinOpEquation({ left: number1, right: 10, sign: 'multiply', answer: 'result' });

    const eq2 = getBinOpEquation({
      left: 10,
      right: number2,
      sign: 'multiply',
      answer: 'result'
    });

    const eq3 = getBinOpEquation({
      left: number3,
      right: 10,
      sign: 'multiply',
      answer: 'result'
    });

    const eq4 = getBinOpEquation({
      left: 10,
      right: number4,
      sign: 'multiply',
      answer: 'result'
    });

    const eqs = [eq1, eq2, eq3, eq4];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aFA',
  description: 'aFA',
  keywords: ['Multiples', '10'],
  schema: z.object({
    numbers: z.number().int().min(1).max(999).array().length(8)
  }),
  simpleGenerator: () => {
    const numMultiples = randomIntegerInclusive(2, 7);

    const multiples = randomUniqueIntegersInclusiveStep(10, 990, 10, numMultiples);

    const notMultiples = randomUniqueIntegersInclusive(1, 999, 8 - numMultiples, {
      constraint: x => x % 10 !== 0
    });

    const numbers = shuffle([...multiples, ...notMultiples]);

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate
    } = props;

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMultiplesOfX(10)}
        testCorrect={numbers.filter(it => it % 10 === 0)}
        multiSelect
        items={numbers}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'aFB',
  description: 'aFB',
  keywords: ['Multiply'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(11).max(99),
    number3: z.number().int().min(11).max(99),
    number4: z.number().int().min(10).max(90).multipleOf(10)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    const [number2, number3] = randomUniqueIntegersInclusive(11, 99, 2, {
      constraint: x => x % 10 !== 0
    });

    const number4 = randomIntegerInclusiveStep(10, 90, 10);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    const eq1 = getBinOpEquation({
      left: number1,
      right: 10,
      sign: 'multiply',
      answer: 'left'
    });

    const eq2 = getBinOpEquation({
      left: 10,
      right: number2,
      sign: 'multiply',
      answer: 'right'
    });

    const eq3 = getBinOpEquation({
      left: number3,
      right: 10,
      sign: 'multiply',
      answer: 'left'
    });

    const eq4 = getBinOpEquation({
      left: 10,
      right: number4,
      sign: 'multiply',
      answer: 'right'
    });

    const eqs = [eq1, eq2, eq3, eq4];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplesOf10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
