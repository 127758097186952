import { z } from 'zod';
import { View } from 'react-native';
import { all, create, number } from 'mathjs';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { countRange } from '../../../../utils/collections';
import { MULT } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { compareFloats } from '../../../../utils/math';
import { AssetSvg } from '../../../../assets/svg';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import { getImagesByAmount } from '../../../../utils/images';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import QF27MissingDigitColumnOperations, {
  getDecimalMissingDigits,
  getMarkSchemeAnswer
} from '../../../../components/question/questionFormats/QF27MissingDigitColumnOperations';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aVi',
  description: 'aVi',
  keywords: ['Decimal', 'Integer', 'Multiplication', 'Multiply', 'Product', 'Place value counters'],
  schema: z.object({
    columns: z.number().int().min(1).max(9),
    rows: z.number().int().min(1).max(5),
    calcLeft: z.boolean(),
    display1: z.boolean()
  }),
  simpleGenerator: () => {
    const columns = randomIntegerInclusive(1, 9);
    const rows = randomIntegerInclusive(1, 5);
    const calcLeft = getRandomBoolean();
    const display1 = getRandomBoolean();

    return { columns, rows, calcLeft, display1 };
  },

  Component: ({ question: { columns, rows, calcLeft, display1 }, translate, displayMode }) => {
    const multiplicand = columns / 10;
    const answer = number(math.evaluate(`${multiplicand} * ${rows}`));
    const displayNumber1 = display1 ? (columns / 10).toLocaleString() : `<ans/>`;
    const displayNumber2 = display1 ? `<ans/>` : rows.toLocaleString();
    const sentence = calcLeft
      ? `${displayNumber1} ${MULT} ${displayNumber2} = <ans/>`
      : `<ans/> = ${displayNumber1} ${MULT} ${displayNumber2}`;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatCalculationIsShownByPVC()}
        Content={({ dimens }) => (
          <ArrayOfObjects
            rows={rows}
            columns={columns}
            dimens={dimens}
            customImage={
              <AssetSvg name={'Place_value/0.1'} width={displayMode === 'digital' ? 60 : 100} />
            }
          />
        )}
        sentence={sentence}
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          calcLeft && display1
            ? compareFloats(userAnswer[0], rows) && compareFloats(userAnswer[1], answer)
            : calcLeft && !display1
            ? compareFloats(userAnswer[0], columns / 10) && compareFloats(userAnswer[1], answer)
            : !calcLeft && display1
            ? compareFloats(userAnswer[0], answer) && compareFloats(userAnswer[1], rows)
            : compareFloats(userAnswer[0], answer) && compareFloats(userAnswer[1], columns / 10)
        }
        extraSymbol="decimalPoint"
        pdfDirection="column"
        customMarkSchemeAnswer={{
          answersToDisplay: calcLeft
            ? [
                display1 ? rows.toLocaleString() : multiplicand.toLocaleString(),
                answer.toLocaleString()
              ]
            : [
                answer.toLocaleString(),
                display1 ? rows.toLocaleString() : multiplicand.toLocaleString()
              ]
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aVj',
  description: 'aVj',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Place value chart',
    'Place value counters'
  ],
  schema: z.object({
    ones: z.number().int().min(1).max(5),
    tenths: z.number().int().min(1).max(5),
    rows: z.number().int().min(2).max(4),
    calcLeft: z.boolean()
  }),
  simpleGenerator: () => {
    const ones = randomIntegerInclusive(1, 5);
    const tenths = randomIntegerInclusive(1, 5);
    const max = ones > 4 || tenths > 4 ? 3 : 4;
    const rows = randomIntegerInclusive(2, max);
    const calcLeft = getRandomBoolean();

    return { ones, tenths, rows, calcLeft };
  },
  Component: props => {
    const {
      question: { ones, tenths, rows, calcLeft },
      translate,
      displayMode
    } = props;

    const total = number(math.evaluate(`${ones} + ${tenths}/10`));
    const answer = number(math.evaluate(`${total} * ${rows}`));

    const onesCounters = getImagesByAmount('Place_value/1', ones);
    const tenthsCounters = getImagesByAmount('Place_value/0.1', tenths);

    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(200, 50, tenthsCounters, 8),
      calcRowOfImagesScaleFactor(200, 50, onesCounters, 8)
    );

    const data = countRange(rows).map(i => {
      return [
        <RowOfImages
          key={i}
          containerStyle={{
            width: 200,
            height: 50,
            flexBasis: 'auto'
          }}
          style={{ gap: 8 }}
          images={onesCounters}
          scaleFactor={scaleFactor}
        />,

        <RowOfImages
          key={i}
          containerStyle={{
            width: 200,
            height: 50,
            flexBasis: 'auto'
          }}
          style={{ gap: 8 }}
          images={tenthsCounters}
          scaleFactor={scaleFactor}
        />
      ];
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToWorkOutMultiplication()}
        pdfDirection="column"
        Content={() => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[0].color,
                  padding: 0
                },
                textStyle: {
                  color: placeValueColumnInfo[0].textColor
                }
              },
              {
                label: translate.keywords.Tenths(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[-1].color,
                  padding: 0
                },
                textStyle: {
                  color: placeValueColumnInfo[-1].textColor
                }
              }
            ]}
            tableData={data}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
          />
        )}
        sentence={
          calcLeft
            ? `${total.toLocaleString()} ${MULT} ${rows} = <ans/>`
            : `<ans/> = ${total.toLocaleString()} ${MULT} ${rows}`
        }
        sentenceStyle={{ justifyContent: 'center' }}
        inputMaxCharacters={4}
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer.toString())}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{ answersToDisplay: [answer.toLocaleString()] }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aVk',
  description: 'aVk',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Column multiplication'
  ],
  schema: z.object({
    number1: z.number().min(1.1).max(9.9).multipleOf(0.1),
    number2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 }) / 10;
    const number2 = randomIntegerInclusive(1, 9);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const total = number(math.evaluate(`${number1} * ${number2}`));
    const answerMissingDigits = getDecimalMissingDigits(total, 1);
    const markSchemeAnswer = getMarkSchemeAnswer(total, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeMultiplication()}
        topNumber={number1}
        bottomNumber={number2}
        answerNumber={total}
        answerMissingDigits={answerMissingDigits}
        operation={MULT}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3v2 = newQuestionContent({
  uid: 'aVk2',
  description: 'aVk',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Column multiplication'
  ],
  schema: z.object({
    number1: z.number().min(1.1).max(9.9).multipleOf(0.1),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 }) / 10;
    const number2 = randomIntegerInclusive(2, 9);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const total = number(math.evaluate(`${number1} * ${number2}`));
    const answerMissingDigits = getDecimalMissingDigits(total, 1);
    const markSchemeAnswer = getMarkSchemeAnswer(total, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeMultiplication()}
        topNumber={number1}
        bottomNumber={number2}
        answerNumber={total}
        answerMissingDigits={answerMissingDigits}
        operation={MULT}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aVl',
  description: 'aVl',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Place value chart',
    'Place value counters'
  ],
  schema: z.object({
    tens: z.number().int().min(1).max(5),
    ones: z.number().int().min(1).max(5),
    tenths: z.number().int().min(1).max(5),
    rows: z.number().int().min(2).max(4),
    incorrectAnswer: z.enum(['+ 0.1', '- 0.1', '+ 1', '- 1', '+ 10', '- 10'])
  }),
  simpleGenerator: () => {
    const tens = randomIntegerInclusive(1, 5);
    const ones = randomIntegerInclusive(1, 5);
    const tenths = randomIntegerInclusive(1, 5);
    const max = ones > 4 || tenths > 4 || tens > 4 ? 3 : 4;
    const rows = randomIntegerInclusive(2, max);
    const incorrectAnswer = getRandomFromArray([
      '+ 0.1',
      '- 0.1',
      '+ 1',
      '- 1',
      '+ 10',
      '- 10'
    ] as const);

    return { tens, ones, tenths, rows, incorrectAnswer };
  },
  Component: props => {
    const {
      question: { tens, ones, tenths, rows, incorrectAnswer },
      translate,
      displayMode
    } = props;

    const total = number(math.evaluate(`(${tens} * 10 + ${ones} + ${tenths}/10)`));
    const answer = number(math.evaluate(`${total} * ${rows}`));

    let incorrectOption;
    switch (incorrectAnswer) {
      case '+ 10':
        incorrectOption = number(
          math.evaluate(`((${tens} + 1) * 10 + ${ones} + ${tenths} / 10) * ${rows}`)
        );
      case '- 10':
        incorrectOption = number(
          math.evaluate(`((${tens} - 1) * 10 + ${ones} + ${tenths} / 10) * ${rows}`)
        );
      case '+ 1':
        incorrectOption = number(
          math.evaluate(`(${tens} * 10 + ${ones} + 1 + ${tenths} / 10) * ${rows}`)
        );
      case '- 1':
        incorrectOption = number(
          math.evaluate(`(${tens} * 10 + ${ones} - 1 + ${tenths} / 10 ) * ${rows}`)
        );
      case '+ 0.1':
        incorrectOption = number(
          math.evaluate(`(${tens} * 10 + ${ones} + (${tenths} + 1) / 10) * ${rows}`)
        );
      case '- 0.1':
        incorrectOption = number(
          math.evaluate(`(${tens} * 10 + ${ones} + (${tenths} - 1) / 10) * ${rows}`)
        );
    }

    const options = shuffle([answer, incorrectOption], { random: seededRandom(props.question) });

    const tensCounters = getImagesByAmount('Place_value/10', tens);
    const onesCounters = getImagesByAmount('Place_value/1', ones);
    const tenthsCounters = getImagesByAmount('Place_value/0.1', tenths);

    const dimens =
      displayMode === 'digital'
        ? {
            width: 200,
            height: 50
          }
        : {
            width: 450,
            height: 100
          };

    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(dimens.width, dimens.height, tensCounters, 8),
      calcRowOfImagesScaleFactor(dimens.width, dimens.height, tenthsCounters, 8),
      calcRowOfImagesScaleFactor(dimens.width, dimens.height, onesCounters, 8)
    );

    const data = countRange(rows).map(i => {
      return [
        <RowOfImages
          key={i}
          containerStyle={dimens}
          style={{ gap: 8 }}
          images={tensCounters}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={dimens}
          style={{ gap: 8 }}
          images={onesCounters}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={dimens}
          style={{ gap: 8 }}
          images={tenthsCounters}
          scaleFactor={scaleFactor}
        />
      ];
    });

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={`${translate.instructions.usePlaceValueChartToWorkOutX(
          `${total.toLocaleString()} ${MULT} ${rows.toLocaleString()}`
        )}<br/>${translate.instructions.selectCorrectAnswer()}`}
        pdfTitle={`${translate.instructions.usePlaceValueChartToWorkOutX(
          `${total.toLocaleString()} ${MULT} ${rows.toLocaleString()}`
        )}<br/>${translate.instructions.circleCorrectAnswer()}`}
        content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'center', alignItems: 'center' }]}>
            <CustomizableTable
              cellHeaders={[
                {
                  label: translate.keywords.Tens(),
                  containerStyle: {
                    backgroundColor: placeValueColumnInfo[1].color,
                    padding: 0
                  },

                  textStyle: displayMode === 'digital' && {
                    color: placeValueColumnInfo[1].textColor
                  }
                },
                {
                  label: translate.keywords.Ones(),
                  containerStyle: {
                    backgroundColor: placeValueColumnInfo[0].color,
                    padding: 0
                  },
                  textStyle: displayMode === 'digital' && {
                    color: placeValueColumnInfo[0].textColor
                  }
                },
                {
                  label: translate.keywords.Tenths(),
                  containerStyle: {
                    backgroundColor: placeValueColumnInfo[-1].color,
                    padding: 0
                  },
                  textStyle: displayMode === 'digital' && {
                    color: placeValueColumnInfo[-1].textColor
                  }
                }
              ]}
              tableData={data}
              tableFontSize={displayMode === 'digital' ? 24 : 50}
              tenthsColumnIndex={2}
            />
          </View>
        )}
        correctAnswer={answer === options[0]}
        trueButtonLabel={options[0].toLocaleString()}
        falseButtonLabel={options[1].toLocaleString()}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5 = newQuestionContent({
  uid: 'aVm',
  description: 'aVm',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Column multiplication'
  ],
  schema: z.object({
    number1: z.number().min(1.01).max(9.99).multipleOf(0.01),
    number2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 }) / 100;
    const number2 = randomIntegerInclusive(1, 9);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const total = number(math.evaluate(`${number1} * ${number2}`));
    const answerMissingDigits = getDecimalMissingDigits(total, 2);
    const markSchemeAnswer = getMarkSchemeAnswer(total, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeMultiplication()}
        topNumber={number1}
        bottomNumber={number2}
        answerNumber={total}
        answerMissingDigits={answerMissingDigits}
        operation={MULT}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5v2 = newQuestionContent({
  uid: 'aVm2',
  description: 'aVm',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Column multiplication'
  ],
  schema: z.object({
    number1: z.number().min(1.01).max(9.99).multipleOf(0.01),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(101, 999, { constraint: x => x % 10 !== 0 }) / 100;
    const number2 = randomIntegerInclusive(2, 9);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const total = number(math.evaluate(`${number1} * ${number2}`));
    const answerMissingDigits = getDecimalMissingDigits(total, 2);
    const markSchemeAnswer = getMarkSchemeAnswer(total, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeMultiplication()}
        topNumber={number1}
        bottomNumber={number2}
        answerNumber={total}
        answerMissingDigits={answerMissingDigits}
        operation={MULT}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aVn',
  description: 'aVn',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Column multiplication'
  ],
  schema: z.object({
    number1: z.number().min(1.001).max(9.999).multipleOf(0.001),
    number2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 9999, { constraint: x => x % 10 !== 0 }) / 1000;
    const number2 = randomIntegerInclusive(1, 9);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const total = number(math.evaluate(`${number1} * ${number2}`));
    const answerMissingDigits = getDecimalMissingDigits(total, 3);
    const markSchemeAnswer = getMarkSchemeAnswer(total, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeMultiplication()}
        topNumber={number1}
        bottomNumber={number2}
        answerNumber={total}
        answerMissingDigits={answerMissingDigits}
        operation={MULT}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6V2 = newQuestionContent({
  uid: 'aVn2',
  description: 'aVn',
  keywords: [
    'Decimal',
    'Integer',
    'Multiplication',
    'Multiply',
    'Product',
    'Column multiplication'
  ],
  schema: z.object({
    number1: z.number().min(1.001).max(9.999).multipleOf(0.001),
    number2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1001, 9999, { constraint: x => x % 10 !== 0 }) / 1000;
    const number2 = randomIntegerInclusive(2, 9);

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const total = number(math.evaluate(`${number1} * ${number2}`));
    const answerMissingDigits = getDecimalMissingDigits(total, 3);
    const markSchemeAnswer = getMarkSchemeAnswer(total, answerMissingDigits.length);

    return (
      <QF27MissingDigitColumnOperations
        title={translate.instructions.completeMultiplication()}
        topNumber={number1}
        bottomNumber={number2}
        answerNumber={total}
        answerMissingDigits={answerMissingDigits}
        operation={MULT}
        customMarkSchemeAnswer={{
          answerToDisplay: { answer: markSchemeAnswer },
          answerText: translate.markScheme.exchangeBoxesAreUnmarked()
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplyDecimalsByIntegers',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5v2, Question6V2],
  archivedQuestionTypes: [Question3, Question5, Question6]
});
export default SmallStep;
