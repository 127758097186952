import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import Clock from '../../../../components/question/representations/Clock';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { convert12hToSpokenString } from '../../../../utils/time';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkU',
  description: 'bkU',
  keywords: ['Quarter to', 'Quarter past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: numberEnum([15, 45])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);

    const minutes = getRandomFromArray([15, 45] as const);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctTime = {
      string: convert12hToSpokenString(translate, hours, minutes),
      value: 'correct'
    };

    const [incorrectTimeA, incorrectTimeB] = getRandomSubArrayFromArray(
      minutes === 15
        ? [
            {
              string: convert12hToSpokenString(translate, hours === 11 ? 0 : hours + 1, minutes),
              value: 'incorrectA'
            },
            {
              string: convert12hToSpokenString(translate, hours === 0 ? 11 : hours - 1, minutes),
              value: 'incorrectB'
            },
            {
              string: convert12hToSpokenString(translate, hours, 30),
              value: 'incorrectC'
            },
            {
              string: convert12hToSpokenString(translate, hours, 45),
              value: 'incorrectD'
            }
          ]
        : [
            {
              string: convert12hToSpokenString(translate, hours, 15),
              value: 'incorrectE'
            },
            {
              string: convert12hToSpokenString(translate, hours, 30),
              value: 'incorrectF'
            },
            {
              string: convert12hToSpokenString(translate, hours === 0 ? 11 : hours - 1, minutes),
              value: 'incorrectG'
            },
            {
              string: convert12hToSpokenString(translate, hours === 11 ? 0 : hours + 1, 15),
              value: 'incorrectH'
            }
          ],
      2,
      { random }
    );

    const options = shuffle([correctTime, incorrectTimeA, incorrectTimeB], { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheTimeShown()}
        pdfTitle={translate.ks1PDFInstructions.tickTheTimeShown()}
        testCorrect={['correct']}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours, minutes }}
            width={Math.min(dimens.width * 0.9, dimens.height * 0.9)}
            interactive={false}
          />
        )}
        renderItems={options.map(({ string, value }) => ({
          value,
          component: <Text variant="WRN700">{string}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bkV',
  description: 'bkV',
  keywords: ['Quarter to', 'Quarter past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    minutes: numberEnum([15, 45])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);

    const minutes = getRandomFromArray([15, 45] as const);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatTimeDoesTheClockShow()}
        sentence={
          minutes === 15
            ? translate.ks1AnswerSentences.quarterPast()
            : translate.ks1AnswerSentences.quarterTo()
        }
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[
          minutes === 15
            ? hours.toString()
            : // If hours is 12 and minutes is 45, the time is 12:45 - so the correct answer is quarter to '1'.
              (hours === 12 ? 1 : hours + 1).toString()
        ]}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours === 12 ? 0 : hours, minutes }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bkW',
  description: 'bkW',
  keywords: ['Quarter to', 'Quarter past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: numberEnum([15, 45])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);

    const minutes = getRandomFromArray([15, 45] as const);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;

    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    return (
      <QF40SetTheClockFace
        title={translate.ks1Instructions.dragTheHandsOfClockToShowX(translatedTime)}
        pdfTitle={translate.ks1PDFInstructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes })}
        exampleCorrectAnswer={{ hours, minutes }}
        snapMinutes={5}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'QuarterPastAndQuarterTo',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
