import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  logUniformSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation } from '../../../../utils/math';
import { DIV } from '../../../../constants';
import { useMemo } from 'react';
import { numberEnum } from '../../../../utils/zod';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'am7',
  description: 'am7',
  keywords: ['Divide', '10'],
  schema: z.object({
    number: z.number().int().min(20).max(9990)
  }),
  simpleGenerator: () => {
    const number =
      randomIntegerInclusive(2, 999, {
        sample: logUniformSample
      }) * 10;

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeCalculation()}
        testCorrect={[(number / 10).toString()]}
        sentence={`${number.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[3, 2, 1, 0]}
            dimens={
              displayMode === 'digital'
                ? { height: dimens.height / 2, width: dimens.width }
                : dimens
            }
            counterVariant={'number'}
          />
        )}
        pdfDirection="column"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'am8',
  description: 'am8',
  keywords: ['Divide', '10', '100'],
  schema: z.object({
    number: z.number().int().min(200).max(99900).multipleOf(100)
  }),
  simpleGenerator: () => {
    const number =
      randomIntegerInclusive(2, 999, {
        sample: logUniformSample
      }) * 100;

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate,
      displayMode
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[[(number / 10).toString()], [(number / 100).toString()]]}
        sentences={[
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
          `${number.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`
        ]}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[4, 3, 2, 1, 0]}
            dimens={
              displayMode === 'digital'
                ? { height: (dimens.height / 3) * 2, width: dimens.width }
                : dimens
            }
            counterVariant={'number'}
            headerVariant={'shortName'}
          />
        )}
        pdfDirection="column"
        questionHeight={975}
      />
    );
  },
  questionHeight: 975
});

const Question3 = newQuestionContent({
  uid: 'am9',
  description: 'am9',
  keywords: ['Divide', '10', '100', '1,000'],
  schema: z.object({
    number: z.number().int().min(2000).max(999000).multipleOf(1000)
  }),
  simpleGenerator: () => {
    const number =
      randomIntegerInclusive(2, 999, {
        sample: logUniformSample
      }) * 1000;

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.completeCalculations()}
        testCorrect={[
          [(number / 10).toString()],
          [(number / 100).toString()],
          [(number / 1000).toString()]
        ]}
        sentences={[
          `${number.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
          `${number.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`,
          `${number.toLocaleString()} ${DIV} ${(1000).toLocaleString()} = <ans/>`
        ]}
        pdfDirection="column"
        questionHeight={1200}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[5, 4, 3, 2, 1, 0]}
            dimens={dimens}
            counterVariant={'number'}
            headerVariant={'shortName'}
          />
        )}
      />
    );
  },
  questionHeight: 1200
});

const Question4 = newQuestionContent({
  uid: 'ana',
  description: 'ana',
  keywords: ['Divide', '10', '100', '1,000'],
  schema: z.object({
    number1: z.number().int().min(2000).max(999000).multipleOf(1000)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(2000, 999000, 1000);

    return { number1 };
  },
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeDivisionSentences()}
        testCorrect={[
          [(number1 / 10).toString()],
          [(number1 / 100).toString()],
          [(number1 / 1000).toString()]
        ]}
        sentences={[
          `${number1.toLocaleString()} ${DIV} ${(10).toLocaleString()} = <ans/>`,
          `${number1.toLocaleString()} ${DIV} ${(100).toLocaleString()} = <ans/>`,
          `${number1.toLocaleString()} ${DIV} ${(1000).toLocaleString()} = <ans/>`
        ]}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        questionHeight={800}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'anb',
  description: 'anb',
  keywords: ['Divide', '10', '100', '1,000'],
  schema: z.object({
    number1A: z.number().int().min(1).max(999),
    number1B: z.number().int().min(1).max(999),
    number1C: z.number().int().min(10).max(990).multipleOf(10),
    number1D: z.number().int().min(100).max(900).multipleOf(100),
    number2s: numberEnum([10, 100, 1000]).array().length(4)
  }),
  simpleGenerator: () => {
    const number1A = randomIntegerInclusive(1, 999);
    const number1B = randomIntegerInclusive(1, 999);
    const number1C = randomIntegerInclusiveStep(10, 990, 10);
    const number1D = randomIntegerInclusiveStep(100, 900, 100);

    const number2s = [
      getRandomFromArray([10, 100, 1000] as const),
      getRandomFromArray([10, 100, 1000] as const),
      getRandomFromArray([10, 100, 1000] as const),
      getRandomFromArray([10, 100, 1000] as const)
    ];

    return { number1A, number1B, number1C, number1D, number2s };
  },
  Component: props => {
    const {
      question: { number1A, number1B, number1C, number1D, number2s },
      translate
    } = props;

    const [number2A, number2B, number2C, number2D] = number2s as number[];

    const number3A = number1A * number2A;
    const number3B = number1B * number2B;
    const number3C = number1C * number2C;
    const number3D = number1D * number2D;

    const answerOptions = [10, 100, 1000];

    const statements = useMemo(() => {
      const questionsAndAnswers = [
        [`${number3A.toLocaleString()} ${DIV} <ans/> = ${number1A.toLocaleString()}`, number2A],
        [`${number3B.toLocaleString()} ${DIV} <ans/> = ${number1B.toLocaleString()}`, number2B],
        [`${number3C.toLocaleString()} ${DIV} <ans/> = ${number1C.toLocaleString()}`, number2C],
        [`${number3D.toLocaleString()} ${DIV} <ans/> = ${number1D.toLocaleString()}`, number2D]
      ] as const;

      return shuffle(questionsAndAnswers, { random: seededRandom(props.question) });
    }, [
      number1A,
      number1B,
      number1C,
      number1D,
      number2A,
      number2B,
      number2C,
      number2D,
      number3A,
      number3B,
      number3C,
      number3D,
      props.question
    ]);

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        actionPanelVariant="end"
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardCanBeUsedMoreThanOnce()}
        pdfLayout="itemsRight"
        items={answerOptions}
        sentences={statements.map(it => it[0])}
        testCorrect={statements.map(it => [it[1]])}
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5v2 = newQuestionContent({
  uid: 'anb2',
  description: 'anb',
  keywords: ['Divide', '10', '100', '1,000'],
  schema: z.object({
    number2s: numberEnum([10, 100, 1000]).array().length(4),
    numbers: z.array(z.number().int().min(1).max(999))
  }),
  simpleGenerator: () => {
    const [number1A, number1B] = countRange(2).map(() => randomIntegerInclusive(1, 999));
    const number1C = randomIntegerInclusiveStep(10, 990, 10);
    const number1D = randomIntegerInclusiveStep(100, 900, 100);

    const number2s = countRange(4).map(() => getRandomFromArray([10, 100, 1000] as const));

    const numbers = shuffle([number1A, number1B, number1C, number1D]);

    return { numbers, number2s };
  },
  Component: props => {
    const {
      question: { numbers, number2s },
      translate,
      displayMode
    } = props;

    const answerOptions = [10, 100, 1000].map(value => {
      return {
        component: (
          <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
            {value}
          </Text>
        ),
        value
      };
    });

    const statements = numbers.map((num, index) => {
      const number3 = num * number2s[index];
      return {
        lhsComponent: (
          <View style={{ width: 230 }}>
            <Text
              style={{ alignSelf: 'flex-end', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >{`${number3.toLocaleString()} ${DIV}`}</Text>
          </View>
        ),
        rhsComponent: (
          <View style={{ width: 230 }}>
            <Text
              style={{ alignSelf: 'flex-start', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >{`= ${num.toLocaleString()}`}</Text>
          </View>
        ),
        correctAnswer: number2s[index]
      };
    });

    return (
      <QF6DragMatchStatements
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentencesEachCardCanBeUsedMoreThanOnce()}
        items={answerOptions}
        pdfItemVariant="tallRectangle"
        actionPanelVariant="end"
        pdfLayout="itemsRight"
        itemVariant="square"
        mainPanelStyle={{ alignSelf: 'center' }}
        statementStyle={{
          columnGap: displayMode === 'digital' ? 0 : 32,
          justifyContent: displayMode === 'digital' ? undefined : 'center'
        }}
        questionHeight={1000}
        statements={statements}
      />
    );
  },
  questionHeight: 1000
});

const Question6 = newQuestionContent({
  uid: 'anc',
  description: 'anc',
  keywords: ['Divide', '10', '100', '1,000'],
  schema: z
    .object({
      numberA1: z.number().int().min(1).max(999),
      numberA2: z.union([z.literal(10), z.literal(100), z.literal(1000)]),
      numberB1: z.number().int().min(1).max(999),
      numberB2: z.union([z.literal(10), z.literal(100), z.literal(1000)]),
      numberC1: z.number().int().min(10).max(990).multipleOf(10),
      numberC2: z.union([z.literal(10), z.literal(100), z.literal(1000)]),
      numberD1: z.number().int().min(100).max(900).multipleOf(100),
      numberD2: z.union([z.literal(10), z.literal(100), z.literal(1000)])
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(1, 999);

    const numberA2 = getRandomFromArray([10, 100, 1000] as const);

    const numberB1 = randomIntegerInclusive(1, 999, {
      constraint: x => x !== numberA1
    });

    const numberB2 = getRandomFromArray([10, 100, 1000] as const);

    const numberC1 = randomIntegerInclusiveStep(10, 990, 10, {
      constraint: x => x !== numberA1 && x !== numberB1
    });

    const numberC2 = getRandomFromArray([10, 100, 1000] as const);

    const numberD1 = randomIntegerInclusiveStep(100, 900, 100, {
      constraint: x => x !== numberA1 && x !== numberB1 && x !== numberC1
    });

    const numberD2 = getRandomFromArray([10, 100, 1000] as const);
    return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2, numberD1, numberD2 },
      translate,
      displayMode
    } = props;

    const eqA = getBinOpEquation({
      right: numberA2,
      result: numberA1,
      sign: 'divide',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      right: numberB2,
      result: numberB1,
      sign: 'divide',
      answer: 'result'
    });

    const eqC = getBinOpEquation({
      right: numberC2,
      result: numberC1,
      sign: 'divide',
      answer: 'result'
    });

    const eqD = getBinOpEquation({
      right: numberD2,
      result: numberD1,
      sign: 'divide',
      answer: 'result'
    });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={900}
        inputMaxCharacters={4}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy10100And1000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
