import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { ADD } from '../../../../constants';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  sumNumberArray
} from '../../../../utils/collections';
import {
  digitAtPowerIsNumber,
  numberOfZeroDigits,
  numberToBase10Object,
  ScientificNotation
} from '../../../../utils/math';
import {
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { colors } from '../../../../theme/colors';
import { useMemo } from 'react';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';

////
// Questions
////

const numberAndPartitionMaker = (
  numberOfZeroesLimit: number,
  partitionLength: number
): [number, number[]] => {
  const number = randomIntegerInclusive(2221, 9999, {
    constraint: x => numberOfZeroDigits(x) < numberOfZeroesLimit
  });

  const partition = (() => {
    const { thousands = 0, hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);
    const standardPartition = [thousands * 1000, hundreds * 100, tens * 10, ones].filter(
      part => part !== 0
    );

    return rejectionSample(
      () => {
        let { thousands = 0, hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);

        const borrowedTens = randomIntegerInclusive(0, tens);
        tens -= borrowedTens;
        ones += borrowedTens * 10;

        const borrowedHundreds = randomIntegerInclusive(0, hundreds);
        hundreds -= borrowedHundreds;
        tens += borrowedHundreds * 10;

        const borrowedThousands = randomIntegerInclusive(0, thousands);
        thousands -= borrowedThousands;
        hundreds += borrowedThousands * 10;

        return [thousands * 1000, hundreds * 100, tens * 10, ones].filter(it => it !== 0);
      },
      partition =>
        partition.length === partitionLength &&
        !arraysHaveSameContents(partition, standardPartition)
    );
  })();

  return [number, partition];
};

const Question1 = newQuestionContent({
  uid: 'ab6',
  description: 'ab6',
  keywords: ['Place value', 'Flexible partition', 'Base 10', '10,000'],
  schema: z
    .object({
      number: z.number().int().min(2221).max(5599),
      partition: z.number().int().array().min(4).max(4)
    })
    .refine(val => val.number % 100 !== 0, 'number must not be a multiple of 100')
    .refine(
      val =>
        digitAtPowerIsNumber(val.number, 2, [0, 1, 2, 3, 4, 5]) &&
        digitAtPowerIsNumber(val.number, 3, [2, 3, 4, 5]),
      'number must have 0-5 hundreds and 2-5 thousands.'
    )
    .refine(val => numberOfZeroDigits(val.number) < 2, 'number must have less than 2 zeroes.')
    .refine(
      val => sumNumberArray(val.partition) === val.number,
      'All parts of partition must total to number.'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(2221, 5599, {
      constraint: x =>
        numberOfZeroDigits(x) < 2 &&
        digitAtPowerIsNumber(x, 2, [0, 1, 2, 3, 4, 5]) &&
        digitAtPowerIsNumber(x, 3, [2, 3, 4, 5])
    });

    const partition = (() => {
      const { thousands = 0, hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);
      const standardPartition = [thousands * 1000, hundreds * 100, tens * 10, ones].filter(
        part => part !== 0
      );

      return rejectionSample(
        () => {
          let { thousands = 0, hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);

          const borrowedTens = randomIntegerInclusive(0, tens);
          tens -= borrowedTens;
          ones += borrowedTens * 10;

          const borrowedHundreds = randomIntegerInclusive(0, hundreds);
          hundreds -= borrowedHundreds;
          tens += borrowedHundreds * 10;

          const borrowedThousands = randomIntegerInclusive(0, thousands);
          thousands -= borrowedThousands;
          hundreds += borrowedThousands * 10;

          return [thousands * 1000, hundreds * 100, tens * 10, ones].filter(it => it !== 0);
        },
        partition => partition.length === 4 && !arraysHaveSameContents(partition, standardPartition)
      );
    })();

    return { number, partition };
  },
  Component: props => {
    const {
      question: { number, partition },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.completeNumberSentenceToMatchImage()}
        testCorrect={answer =>
          arraysHaveSameContentsUnordered(
            partition.map(part => part.toString()),
            answer
          )
        }
        inputMaxCharacters={4}
        sentence={`${number.toLocaleString()} = <ans/> ${ADD} <ans/> ${ADD} <ans/> ${ADD} <ans/>`}
        {...props}
        Content={({ dimens }) => {
          const width = dimens.width / 4 - 24;
          const height = dimens.height - 24;
          const scales = partition.map(
            part =>
              BaseTenRepCalcGridsAndScale(width, height, numberToBase10Object(part), 'Cubes', 1)
                .scale
          );
          const scale = Math.min(...scales);

          return (
            <View
              style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-evenly' }}
            >
              {partition.map((part, index) => (
                <View
                  style={{
                    borderWidth: 2,
                    borderColor: colors.pacificBlue,
                    borderRadius: 12,
                    padding: 12,
                    height: dimens.height
                  }}
                  key={index}
                >
                  <BaseTenRepresentation
                    b10Rep={{
                      variant: 'Cubes',
                      numbers: numberToBase10Object(part),
                      arrangement: 'ltr'
                    }}
                    usableWidth={width}
                    usableHeight={height}
                    align={'center'}
                    numCols={1}
                    scale={scale}
                  />
                </View>
              ))}
            </View>
          );
        }}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: partition.map(num => num.toLocaleString())
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ab7',
  description: 'ab7',
  keywords: ['Place value', 'Flexible partition', 'Base 10', '10,000'],
  schema: z
    .object({
      number: z.number().int().min(2221).max(9999),
      partition: z.number().int().array().min(4).max(4),
      answerPosition: z.number().int().min(0).max(3)
    })
    .refine(val => val.number % 100 !== 0, 'number must not be a multiple of 100')
    .refine(
      val => sumNumberArray(val.partition) === val.number,
      'All parts of partition must total to number.'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const [number, partition] = numberAndPartitionMaker(2, 4);
    const answerPosition = randomIntegerInclusive(0, 3);

    return { number, partition, answerPosition };
  },
  Component: props => {
    const {
      question: { number, partition, answerPosition },
      translate
    } = props;

    const correctAnswer = partition[answerPosition];

    // Create a copy of the partition array, replacing the answerPosition value with '<ans/>'.
    const modifiedPartition = partition.map((value, index) =>
      index === answerPosition ? '<ans/>' : value.toLocaleString()
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[correctAnswer.toString()]}
        sentence={`${number.toLocaleString()} = ${modifiedPartition[0]} ${ADD} ${
          modifiedPartition[1]
        } ${ADD} ${modifiedPartition[2]} ${ADD} ${modifiedPartition[3]}`}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(number)}
            columnsToShow={[3, 2, 1, 0]}
            counterVariant={'decimalCounter'}
            dimens={dimens}
          />
        )}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ab8',
  description: 'ab8',
  keywords: ['Place value', 'Flexible partition', 'Part-whole', '10,000'],
  schema: z
    .object({
      number: z.number().int().min(2221).max(9999),
      partition: z.number().int().array().min(4).max(4),
      answerPosition: z.number().int().min(0).max(3)
    })
    .refine(val => numberOfZeroDigits(val.number) < 2, 'number must have less than two zeroes.')
    .refine(
      val => sumNumberArray(val.partition) === val.number,
      'All parts of partition must total to number.'
    ),
  simpleGenerator: () => {
    const [number, partition] = numberAndPartitionMaker(2, 4);
    const answerPosition = randomIntegerInclusive(0, 3);

    return { number, partition, answerPosition };
  },
  Component: ({ question: { number, partition, answerPosition }, translate }) => {
    const correctAnswer = partition[answerPosition];

    // Create a copy of the partition array, replacing the answerPosition value with '$ans'.
    const modifiedPartition = partition.map((value, index) =>
      index === answerPosition ? '$ans' : value.toLocaleString()
    );

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={modifiedPartition}
            isInteractive
            dimens={dimens}
            top={number}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'ab9',
  description: 'ab9',
  keywords: ['Place value', 'Flexible partition', 'Part-whole', '10,000'],
  schema: z
    .object({
      number: z.number().int().min(2221).max(9999),
      partition: z.number().int().array().min(3).max(3),
      answerPosition: z.number().int().min(0).max(2)
    })
    .refine(val => numberOfZeroDigits(val.number) < 3, 'number must have less than three zeroes.')
    .refine(
      val => sumNumberArray(val.partition) === val.number,
      'All parts of partition must total to number.'
    ),
  simpleGenerator: () => {
    // number must have less than three zeroes for partition to be able to create a valid flexible partition with three parts.
    const [number, partition] = numberAndPartitionMaker(3, 3);
    const answerPosition = randomIntegerInclusive(0, 2);

    return { number, partition, answerPosition };
  },
  Component: ({ question: { number, partition, answerPosition }, translate }) => {
    const correctAnswer = partition[answerPosition];

    // Create a copy of the partition array, replacing the answerPosition value with '$ans'.
    const modifiedPartition = partition.map((value, index) =>
      index === answerPosition ? '$ans' : value.toLocaleString()
    );

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        inputType="numpad"
        initialState={['']}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            top={number}
            partition={modifiedPartition}
            isInteractive
            dimens={dimens}
          />
        )}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer.toLocaleString()] }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aca',
  description: 'aca',
  keywords: ['Place value', 'Flexible partition', '10,000'],
  schema: z.object({
    digit1: z.number().int().min(2).max(9),
    digit2: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const digit1 = randomIntegerInclusive(2, 9);
    const digit2 = randomIntegerInclusive(2, 9);

    return { digit1, digit2 };
  },
  Component: props => {
    const {
      question: { digit1, digit2 },
      translate
    } = props;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: translate.misc.numberOfOnes(digit1 * 10 + digit2),
        value: digit1 * 10 + digit2
      };

      const statement2 = {
        statement: `${translate.misc.numberOfTens(
          digit1 * 10
        )} ${ADD} ${translate.misc.numberOfOnes(digit2)}`,
        value: digit1 * 100 + digit2
      };

      const statement3 = {
        statement: translate.misc.numberOfTens(digit1 * 10 + digit2),
        value: (digit1 * 10 + digit2) * 10
      };

      const statement4 = {
        statement: translate.misc.numberOfHundreds(digit1 * 10 + digit2),
        value: digit1 * 1000 + digit2 * 100
      };

      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [translate.misc, digit1, digit2, props.question]);

    const items = statements.map(({ value }) => value).sort((a, b) => a - b);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchTheSentences()}
        pdfTitle={translate.instructions.matchNumbersWithStatements()}
        items={items}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'acb',
  description: 'acb',
  keywords: ['Place value', 'Flexible partition', '10,000'],
  schema: z
    .object({
      number: z
        .number()
        .int()
        .min(2211)
        .max(9999)
        .refine(x => x % 100 !== 0, 'Must not be a multiple of 100'),
      partitions: z.number().int().array().min(2).array().length(4)
    })
    .refine(
      ({ partitions }) =>
        arrayHasNoDuplicates(partitions.map(partition => JSON.stringify(partition))),
      'Duplicate partitions are not allowed'
    )
    .refine(
      ({ number, partitions }) => partitions.map(sumNumberArray).includes(number),
      'At least one partition must be correct'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const number = randomIntegerInclusive(2211, 9999, { constraint: x => x % 100 !== 0 });
        const { thousands = 0, hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);
        const standardPartition = [thousands * 1000, hundreds * 100, tens * 10, ones].filter(
          part => part !== 0
        );
        const numberOfCorrectAnswers = randomIntegerInclusive(1, 3);

        // See aaP for context of how this works
        const makePartition = (x: number): number[] => {
          return rejectionSample(
            () => {
              let { thousands = 0, hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(x);

              const borrowedTens = randomIntegerInclusive(0, tens);
              tens -= borrowedTens;
              ones += borrowedTens * 10;

              const borrowedHundreds = randomIntegerInclusive(0, hundreds);
              hundreds -= borrowedHundreds;
              tens += borrowedHundreds * 10;

              const borrowedThousands = randomIntegerInclusive(0, thousands);
              thousands -= borrowedThousands;
              hundreds += borrowedThousands * 10;

              return [thousands * 1000, hundreds * 100, tens * 10, ones].filter(it => it !== 0);
            },
            partition =>
              partition.length >= 2 && !arraysHaveSameContents(partition, standardPartition)
          );
        };

        const partitions = (() => {
          switch (numberOfCorrectAnswers) {
            case 3:
              return [
                makePartition(number - 1100),
                makePartition(number),
                makePartition(number),
                makePartition(number)
              ];
            case 2:
              return [
                makePartition(number - 1100),
                makePartition(number - 1100),
                makePartition(number),
                makePartition(number)
              ];
            default:
              return [
                makePartition(number - 1100),
                makePartition(number - 1100),
                makePartition(number - 1100),
                makePartition(number)
              ];
          }
        })();

        return { number, partitions };
      },
      ({ partitions }) =>
        arrayHasNoDuplicates(partitions.map(partition => JSON.stringify(partition)))
    ),
  Component: props => {
    const {
      question: { number, partitions },
      translate
    } = props;

    const { thousands = 0, hundreds = 0, tens = 0, ones = 0 } = numberToBase10Object(number);
    const standardPartition = [thousands * 1000, hundreds * 100, tens * 10, ones].filter(
      part => part !== 0
    );

    const partitionToNumberSentence = (partition: number[]): string =>
      partition.map(part => part.toLocaleString()).join(` ${ADD} `);

    const partitionsInfo = shuffle(
      partitions.map((partition, index) => ({
        value: index,
        text: partitionToNumberSentence(partition),
        correct: sumNumberArray(partition) === number
      })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={translate.instructions.whichNumberSentencesAreEqualTo(
          partitionToNumberSentence(standardPartition)
        )}
        testCorrect={partitionsInfo
          .filter(partition => partition.correct)
          .map(partition => partition.value)}
        items={partitionsInfo.map(({ value, text }) => ({
          value,
          component: text
        }))}
        multiSelect
        itemsMaxLines={1}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FlexiblePartitioningOfNumbersTo10000',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
