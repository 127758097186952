import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { NonEmptyArray, range } from '../../../../utils/collections';
import QF17bCompleteNumberLineDraggable from '../../../../components/question/questionFormats/QF17bCompleteNumberLineDraggable';
import TextStructure from '../../../../components/molecules/TextStructure';
import { compareFractions } from '../../../../utils/fractions';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import NumberLineTicksTopBottom from '../../../../components/question/representations/Number Line/NumberLineTicksTopBottom';
import { numberEnum } from '../../../../utils/zod';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aIw',
  description: 'aIw',
  keywords: ['Number line', 'Equivalent fractions', 'Numerator', 'Denominator'],
  questionHeight: 1000,
  schema: z.object({
    topDenom: numberEnum([2, 4, 5]),
    bottomDenom: z.number().int().min(4).max(10),
    missingNum: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const topDenom = getRandomFromArray([2, 4, 5] as const);
    const bottomDenom = randomIntegerInclusive(4, 10, {
      constraint: x => x !== topDenom && x % topDenom === 0
    });
    const missingNum = randomIntegerInclusive(1, topDenom - 1);

    return { topDenom, bottomDenom, missingNum };
  },

  Component: props => {
    const {
      question: { topDenom, bottomDenom, missingNum },
      translate,
      displayMode
    } = props;
    const scale = bottomDenom / topDenom;
    const topTickValues = range(0, topDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === topDenom) return `${(1).toLocaleString()}`;
      return `<frac n='${i.toLocaleString()}' d='${topDenom.toLocaleString()}' />`;
    });

    const bottomTickValues = range(0, bottomDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (i === bottomDenom) return `${(1).toLocaleString()}`;
      return `<frac n='${i.toLocaleString()}' d='${bottomDenom.toLocaleString()}' />`;
    });

    const correctOption = [missingNum * scale, bottomDenom];
    const allOptions = [
      correctOption,
      [bottomDenom, missingNum * scale],
      [missingNum * scale + 1, bottomDenom],
      [missingNum * scale - 1, bottomDenom]
    ];

    const options = allOptions.map(([n, d], i) => {
      return {
        component: (
          <TextStructure
            key={i}
            sentence={`<frac n='${n.toLocaleString()}' d='${d.toLocaleString()}' />`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 30 : 50,
              fontWeight: '700'
            }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: `${n}/${d}`
      };
    });

    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF36ContentAndSentenceDrag
        questionHeight={1000}
        title={translate.instructions.dragACardToCompleteSentence()}
        pdfTitle={translate.instructions.useACardToCompleteSentence()}
        items={items}
        pdfLayout="itemsBottom"
        actionPanelVariant={'end'}
        Content={({ dimens }) => (
          <NumberLineTicksTopBottom
            topTickValues={topTickValues}
            bottomTickValues={bottomTickValues}
            dimens={dimens}
          />
        )}
        sentence={translate.answerSentences.xIsEquivalentToAns(
          `<frac n='${missingNum.toLocaleString()}' d='${topDenom.toLocaleString()}' />`
        )}
        testCorrect={[`${correctOption[0]}/${correctOption[1]}`]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aIx',
  description: 'aIx',
  keywords: ['Number line', 'Equivalent fractions', 'Numerator', 'Denominator'],
  schema: z.object({
    topDenom: numberEnum([2, 4, 5]),
    bottomDenom: z.number().int().min(4).max(10),
    givenNum: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const topDenom = getRandomFromArray([2, 4, 5] as const);
    const bottomDenom = randomIntegerInclusive(4, 10, {
      constraint: x => x !== topDenom && x % topDenom === 0
    });
    const givenNum = randomIntegerInclusive(1, topDenom - 1);

    return { topDenom, bottomDenom, givenNum };
  },

  Component: props => {
    const {
      question: { topDenom, bottomDenom, givenNum },
      translate
    } = props;

    const scale = bottomDenom / topDenom;
    const topTickValues = range(0, topDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      return '';
    });

    const bottomTickValues = range(0, bottomDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      else if (i === bottomDenom) return `${(1).toLocaleString()}`;
      return '';
    });

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useNumberLineToCompleteEquivFraction()}
        Content={({ dimens }) => (
          <NumberLineTicksTopBottom
            dimens={dimens}
            bottomTickValues={bottomTickValues}
            topTickValues={topTickValues}
          />
        )}
        inputMaxCharacters={2}
        pdfDirection="column"
        sentence={`<frac n="${(
          givenNum * scale
        ).toLocaleString()}" d="${bottomDenom.toLocaleString()}" /> = <frac nAns="" dAns=""/>`}
        testCorrect={userAnswer => compareFractions(userAnswer, [givenNum * scale, bottomDenom])}
        customMarkSchemeAnswer={{
          answersToDisplay: [`${givenNum.toLocaleString()}`, `${topDenom.toLocaleString()}`],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aIy',
  description: 'aIy',
  keywords: ['Double number line', 'Equivalent fractions', 'Numerator', 'Denominator'],
  schema: z.object({
    topDenom: z.number().int().min(2).max(4),
    bottomDenom: z.number().int().min(4).max(9),
    missingNum: z.number().int().min(1).max(3),
    wrongOptions: z.array(z.number().int().min(0).max(12)).length(2)
  }),
  simpleGenerator: () => {
    const topDenom = randomIntegerInclusive(2, 4);
    const bottomDenom = randomIntegerInclusive(4, 9, {
      constraint: x => x !== topDenom && x % topDenom === 0
    });

    const missingNum = randomIntegerInclusive(1, topDenom - 1);

    const wrongOptions = [];

    const notScale = [2, 3, 4].filter(x => x !== bottomDenom / topDenom) as NonEmptyArray<number>;

    wrongOptions.push(bottomDenom + 1, missingNum * getRandomFromArray(notScale));

    return { topDenom, bottomDenom, missingNum, wrongOptions };
  },

  Component: props => {
    const {
      question: { topDenom, bottomDenom, missingNum, wrongOptions },
      translate,
      displayMode
    } = props;

    const scale = bottomDenom / topDenom;
    const topTickValues = range(0, topDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      return `<frac n='${i.toLocaleString()}' d='${topDenom.toLocaleString()}' />`;
    });

    const bottomTickValues = range(0, bottomDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (missingNum * scale === i) return '<ans/>';
      if (i === bottomDenom)
        return `<frac n='${i.toLocaleString()}' d='${bottomDenom.toLocaleString()}' />`;
      return ``;
    });

    const correctOption = [missingNum * scale, bottomDenom];
    const allOptions = [
      correctOption,
      [missingNum, bottomDenom],
      [wrongOptions[0], bottomDenom],
      [wrongOptions[1] * scale, bottomDenom]
    ];

    const options = allOptions.map(([n, d], i) => {
      return {
        component: (
          <TextStructure
            key={i}
            sentence={`<frac n='${n.toLocaleString()}' d='${d.toLocaleString()}' />`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 28 : 40,
              fontWeight: '700'
            }}
            textStyle={{ fontSize: 40 }}
          />
        ),
        value: `${n}/${d}`
      };
    });

    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsToCompleteEquivalentFractions()}
        pdfTitle={translate.instructions.useCardsToCompleteEquivalentFractions()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={[`${correctOption[0]}/${correctOption[1]}`]}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aIy2',
  description: 'aIy',
  keywords: ['Double number line', 'Equivalent fractions', 'Numerator', 'Denominator'],
  schema: z.object({
    topDenom: z.number().int().min(3).max(5),
    bottomDenom: z.number().int().min(6).max(10),
    missingNum: z.number().int().min(1).max(4),
    wrongOptions: z.array(z.number().int().min(0).max(12)).length(2)
  }),
  simpleGenerator: () => {
    const topDenom = randomIntegerInclusive(3, 5);
    const bottomDenom = randomIntegerInclusive(6, 10, {
      constraint: x => x !== topDenom && x % topDenom === 0
    });

    const missingNum = randomIntegerInclusive(1, topDenom - 1);

    const wrongOptions = randomUniqueIntegersInclusive(1, bottomDenom - 1, 2, {
      constraint: x => x !== missingNum * (bottomDenom / topDenom) && x !== missingNum
    });
    return { topDenom, bottomDenom, missingNum, wrongOptions };
  },
  Component: props => {
    const {
      question: { topDenom, bottomDenom, missingNum, wrongOptions },
      translate,
      displayMode
    } = props;

    const scale = bottomDenom / topDenom;
    const topTickValues = range(0, topDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      return `<frac n='${i.toLocaleString()}' d='${topDenom.toLocaleString()}' />`;
    });

    const bottomTickValues = range(0, bottomDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (missingNum * scale === i) return '<ans/>';
      if (i === bottomDenom)
        return `<frac n='${i.toLocaleString()}' d='${bottomDenom.toLocaleString()}' />`;
      return ``;
    });

    const correctOption = [missingNum * scale, bottomDenom];
    const allOptions = [
      correctOption,
      [missingNum, bottomDenom],
      [wrongOptions[0], bottomDenom],
      [wrongOptions[1], bottomDenom]
    ];

    const options = allOptions.map(([n, d], i) => {
      return {
        component: (
          <TextStructure
            key={i}
            sentence={`<frac n='${n.toLocaleString()}' d='${d.toLocaleString()}' />`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 28 : 40,
              fontWeight: '700'
            }}
            textStyle={{ fontSize: 40 }}
          />
        ),
        value: `${n}/${d}`
      };
    });

    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsToCompleteEquivalentFractions()}
        pdfTitle={translate.instructions.useCardsToCompleteEquivalentFractions()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={[`${correctOption[0]}/${correctOption[1]}`]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aIz',
  description: 'aIz',
  keywords: ['Double number line', 'Equivalent fractions', 'Numerator', 'Denominator'],
  schema: z.object({
    topDenom: z.number().int().min(3).max(5),
    bottomDenom: z.number().int().min(6).max(10),
    missingNum: z.number().int().min(2).max(4)
  }),
  simpleGenerator: () => {
    const topDenom = randomIntegerInclusive(3, 5);
    const bottomDenom = randomIntegerInclusive(6, 10, {
      constraint: x => x !== topDenom && x % topDenom === 0
    });

    const missingNum = randomIntegerInclusive(2, topDenom - 1);

    return { topDenom, bottomDenom, missingNum };
  },

  Component: props => {
    const {
      question: { topDenom, bottomDenom, missingNum },
      translate,
      displayMode
    } = props;

    const scale = bottomDenom / topDenom;
    const topTickValues = range(0, topDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if ([1, missingNum].includes(i)) return '<ans/>';
      return `<frac n='${i.toLocaleString()}' d='${topDenom.toLocaleString()}' />`;
    });

    const bottomTickValues = range(0, bottomDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if ([scale, missingNum * scale].includes(i)) return '<ans/>';
      return `<frac n='${i.toLocaleString()}' d='${bottomDenom.toLocaleString()}' />`;
    });

    const correctOptionsOrder = [
      [1, topDenom],
      [missingNum, topDenom],
      [scale, bottomDenom],
      [missingNum * scale, bottomDenom]
    ];

    const options = correctOptionsOrder.map(([n, d], i) => {
      return {
        component: (
          <TextStructure
            key={i}
            sentence={`<frac n='${n.toLocaleString()}' d='${d.toLocaleString()}' />`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 28 : 40,
              fontWeight: '700'
            }}
            textStyle={{ fontSize: 40 }}
          />
        ),
        value: `${n}/${d}`
      };
    });

    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragEachCardToCompleteEquivalentFractions()}
        pdfTitle={translate.instructions.useEachCardToCompleteEquivalentFractions()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        testCorrect={correctOptionsOrder.map(([n, d]) => `${n}/${d}`)}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'aIA',
  description: 'aIA',
  keywords: ['Double number line', 'Equivalent fractions', 'Numerator', 'Denominator'],
  schema: z.object({
    topDenom: z.number().int().min(2).max(4),
    bottomDenom: z.number().int().min(4).max(8),
    missingTop: z.boolean(),
    missingNum: z.number().int().min(1).max(7),
    wrongOptions: z.array(z.number().int().min(0).max(10)).length(2)
  }),
  simpleGenerator: () => {
    const topDenom = randomIntegerInclusive(2, 4);
    const bottomDenom = randomIntegerInclusive(4, 8, {
      constraint: x => x !== topDenom && x % topDenom === 0
    });
    const missingTop = getRandomBoolean();
    const missingNum = randomIntegerInclusive(1, topDenom - 1);

    const wrongOptions = [];
    wrongOptions.push(missingNum - 1, missingNum + 1 * getRandomFromArray([2, 3, 4]));

    return { topDenom, bottomDenom, missingTop, missingNum, wrongOptions };
  },

  Component: props => {
    const {
      question: { topDenom, bottomDenom, missingTop, missingNum, wrongOptions },
      translate,
      displayMode
    } = props;

    const scale = bottomDenom / topDenom;
    const topTickValues = range(0, topDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (missingTop && missingNum === i) return '<ans/>';
      return `<frac n='${i.toLocaleString()}' d='${topDenom.toLocaleString()}' />`;
    });

    const bottomTickValues = range(0, bottomDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (!missingTop && missingNum * scale === i) return '<ans/>';
      return `<frac n='${i.toLocaleString()}' d='${bottomDenom.toLocaleString()}' />`;
    });

    const correctOption = missingTop ? [missingNum, topDenom] : [missingNum * scale, bottomDenom];
    const allOptions = [
      correctOption,
      [topDenom, missingNum],
      [wrongOptions[0], topDenom],
      [bottomDenom, missingNum * scale],
      [wrongOptions[0] * scale, bottomDenom],
      [wrongOptions[1], topDenom],
      [wrongOptions[1] * scale, bottomDenom]
    ];

    const options = allOptions.map(([n, d], i) => {
      return {
        component: (
          <TextStructure
            key={i}
            sentence={`<frac n='${n.toLocaleString()}' d='${d.toLocaleString()}' />`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 28 : 40,
              fontWeight: '700'
            }}
            textStyle={{ fontSize: 40 }}
          />
        ),
        value: `${n}/${d}`
      };
    });

    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsToCompleteEquivalentFractions()}
        pdfTitle={translate.instructions.useCardsToCompleteEquivalentFractions()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        questionHeight={900}
        testCorrect={[`${correctOption[0]}/${correctOption[1]}`]}
      />
    );
  },
  questionHeight: 900
});

const Question5v2 = newQuestionContent({
  uid: 'aIA2',
  description: 'aIA',
  keywords: ['Double number line', 'Equivalent fractions', 'Numerator', 'Denominator'],
  schema: z.object({
    topDenom: z.number().int().min(3).max(5),
    bottomDenom: z.number().int().min(6).max(10),
    missingNumsTop: z.array(z.number().int().min(1).max(5)).length(2),
    missingNumsBottom: z.array(z.number().int().min(1).max(10)).length(2)
  }),
  simpleGenerator: () => {
    const topDenom = randomIntegerInclusive(3, 5);
    const bottomDenom = randomIntegerInclusive(6, 10, {
      constraint: x => x !== topDenom && x % topDenom === 0
    });
    const missingNumsTop = randomUniqueIntegersInclusive(1, topDenom, 2).sort();
    const missingNumsBottom = randomUniqueIntegersInclusive(1, bottomDenom, 2).sort();

    return { topDenom, bottomDenom, missingNumsTop, missingNumsBottom };
  },

  Component: props => {
    const {
      question: { topDenom, bottomDenom, missingNumsTop, missingNumsBottom },
      translate,
      displayMode
    } = props;

    const topTickValues = range(0, topDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (missingNumsTop.indexOf(i) >= 0) return '<ans/>';
      return `<frac n='${i.toLocaleString()}' d='${topDenom.toLocaleString()}' />`;
    });

    const bottomTickValues = range(0, bottomDenom).map(i => {
      if (i === 0) return `${(0).toLocaleString()}`;
      if (missingNumsBottom.indexOf(i) >= 0) return '<ans/>';
      return `<frac n='${i.toLocaleString()}' d='${bottomDenom.toLocaleString()}' />`;
    });

    const correctOptionsOrder = [
      [missingNumsTop[0], topDenom],
      [missingNumsTop[1], topDenom],
      [missingNumsBottom[0], bottomDenom],
      [missingNumsBottom[1], bottomDenom]
    ];

    const options = correctOptionsOrder.map(([n, d], i) => {
      return {
        component: (
          <TextStructure
            key={i}
            sentence={`<frac n='${n.toLocaleString()}' d='${d.toLocaleString()}' />`}
            fractionTextStyle={{
              fontSize: displayMode === 'digital' ? 28 : 40,
              fontWeight: '700'
            }}
            textStyle={{ fontSize: 40 }}
          />
        ),
        value: `${n}/${d}`
      };
    });

    const items = shuffle([...options], {
      random: seededRandom(props.question)
    });

    return (
      <QF17bCompleteNumberLineDraggable
        title={translate.instructions.dragCardsCompleteNumberLine()}
        pdfTitle={translate.instructions.useCardsCompleteNumberLine()}
        bottomTickValues={bottomTickValues}
        topTickValues={topTickValues}
        items={items}
        questionHeight={900}
        testCorrect={correctOptionsOrder.map(([n, d]) => `${n}/${d}`)}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aIB',
  description: 'aIB',
  keywords: ['Number line', 'Equivalent fractions', 'Numerator', 'Denominator'],
  schema: z.object({
    simplifiedDenom: z.number().int().min(2).max(6),
    numerator: z
      .number()
      .int()
      .min(1)
      .max(11)
      .refine(x => x % 2 !== 0)
  }),
  simpleGenerator: () => {
    const simplifiedDenom = randomIntegerInclusive(2, 6);
    const numerator = randomIntegerInclusive(1, simplifiedDenom * 2 - 1, {
      constraint: x => x % 2 !== 0
    });

    return { simplifiedDenom, numerator };
  },
  Component: props => {
    const {
      question: { simplifiedDenom, numerator },
      translate
    } = props;
    const denom = simplifiedDenom * 2;

    const numberArray = [
      (0).toLocaleString(),
      ...range(1, simplifiedDenom).map(
        i => `<frac n="${i.toLocaleString()}" d="${simplifiedDenom.toLocaleString()}" />`
      )
    ];

    return (
      <QF19NumberLineDragArrow
        title={translate.instructions.dragTheArrowToEstimateWhereFracBelongsOnNumberLine(
          `<frac n="${numerator.toLocaleString()}" d="${denom.toLocaleString()}" />`
        )}
        pdfTitle={translate.instructions.drawArrowToEstimateWhereFracBelongsOnNumberLine(
          `<frac n="${numerator.toLocaleString()}" d="${denom.toLocaleString()}" />`
        )}
        min={0}
        // Numberline deals with fractions but is scaled up to denom
        max={denom}
        sliderStep={1}
        tickValues={numberArray}
        testCorrect={[numerator, numerator]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsOnANumberLine',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question3, Question5]
});
export default SmallStep;
