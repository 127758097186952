import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  ScientificNotation,
  digitAtPowerIsNumber,
  numberToBase10Object
} from '../../../../utils/math';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { useMemo } from 'react';
import { arrayHasNoDuplicates, sortNumberArray } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { decimalToFraction, improperFractionToMixedNumber } from '../../../../utils/fractions';
import TextStructure from '../../../../components/molecules/TextStructure';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aQ2',
  description: 'aQ2',
  keywords: ['Hundredths', 'Tenths', 'Place value chart', 'Compare'],
  schema: z
    .object({
      number1Hundredths: z.number().min(101).max(599),
      number2Hundredths: z.number().min(101).max(599),
      smallerOrGreater: z.enum(['smaller', 'greater'])
    })
    .refine(
      val => val.number1Hundredths !== val.number2Hundredths,
      'number1Hundredths and number2Hundredths must be different.'
    ),
  simpleGenerator: () => {
    const number1Hundredths = randomIntegerInclusive(101, 599, {
      // Enforced to have 0 tens:
      constraint: x => digitAtPowerIsNumber(x, 'tens', [0])
    });

    const number2Hundredths = randomIntegerInclusive(101, 599, {
      // Enforced to not have 0 tens:
      constraint: x => !digitAtPowerIsNumber(x, 'tens', [0]) && x !== number1Hundredths
    });

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { number1Hundredths, number2Hundredths, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { number1Hundredths, number2Hundredths, smallerOrGreater },
      translate,
      displayMode
    } = props;

    const items = useMemo(
      () =>
        shuffle(
          [
            {
              number: number1Hundredths / 100,
              isCorrect:
                smallerOrGreater === 'smaller'
                  ? number1Hundredths < number2Hundredths
                  : number1Hundredths > number2Hundredths
            },
            {
              number: number2Hundredths / 100,
              isCorrect:
                smallerOrGreater === 'smaller'
                  ? number1Hundredths > number2Hundredths
                  : number1Hundredths < number2Hundredths
            }
          ],
          { random: seededRandom(props.question) }
        ),
      [number1Hundredths, number2Hundredths, props.question, smallerOrGreater]
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectSmallerNumber()
            : translate.instructions.selectGreaterNumber()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.circleSmallerNumber()
            : translate.instructions.circleGreaterNumber()
        }
        testCorrect={items.filter(item => item.isCorrect)}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item,
            component: (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(item.number)}
                columnsToShow={[0, -1, -2]}
                dimens={{
                  height: displayMode === 'digital' ? dimens.height * 0.4 : dimens.height * 0.7,
                  width: displayMode === 'digital' ? dimens.width * 0.9 : dimens.width * 0.95
                }}
                counterVariant={'decimalCounter'}
                headerVariant={'shortName'}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aQ3',
  description: 'aQ3',
  keywords: ['Hundredths', 'Tenths', 'Place value chart', 'Compare'],
  schema: z.object({
    number1Hundredths: z.number().min(101).max(999),
    number2Tenths: z.number().min(101).max(399),
    smallerOrGreater: z.enum(['smaller', 'greater'])
  }),
  simpleGenerator: () => {
    const number1Hundredths = randomIntegerInclusive(101, 999);

    const number2Tenths = randomIntegerInclusive(101, 399);

    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    return { number1Hundredths, number2Tenths, smallerOrGreater };
  },
  Component: props => {
    const {
      question: { number1Hundredths, number2Tenths, smallerOrGreater },
      translate,
      displayMode
    } = props;

    const items = useMemo(
      () =>
        shuffle(
          [
            {
              number: number1Hundredths / 100,
              isCorrect: smallerOrGreater === 'smaller'
            },
            {
              number: number2Tenths / 10,
              isCorrect: smallerOrGreater === 'greater'
            }
          ],
          { random: seededRandom(props.question) }
        ),
      [number1Hundredths, number2Tenths, props.question, smallerOrGreater]
    );

    return (
      <QF11SelectImagesUpTo4
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectSmallerNumber()
            : translate.instructions.selectGreaterNumber()
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.circleSmallerNumber()
            : translate.instructions.circleGreaterNumber()
        }
        testCorrect={items.filter(item => item.isCorrect)}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item,
            component: (
              <PlaceValueChart
                number={ScientificNotation.fromNumber(item.number)}
                columnsToShow={[1, 0, -1, -2]}
                dimens={{
                  height: displayMode === 'digital' ? dimens.height * 0.4 : dimens.height * 0.7,
                  width: displayMode === 'digital' ? dimens.width * 0.9 : dimens.width * 0.95
                }}
                counterVariant={'decimalCounter'}
                headerVariant={'shortName'}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aQ4',
  description: 'aQ4',
  keywords: ['Thousandths', 'Hundredths', 'Tenths', 'Decimals', 'Compare'],
  schema: z
    .object({
      number1: z.number().min(2).max(9),
      number2: z.number().min(2.1).max(9.9).step(0.1),
      number3: z.number().min(2.01).max(9.99).step(0.01),
      number4: z.number().min(2.01).max(9.99).step(0.01),
      smallestOrGreatest: z.enum(['smallest', 'greatest'])
    })
    .refine(
      val => arrayHasNoDuplicates([val.number1, val.number2, val.number3, val.number4]),
      'All numbers must be different.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);

    const number2TenthsLowerBound = number1 * 10 + 1;
    const number2TenthsUpperBound = number1 * 10 + 9;

    const number2 =
      randomIntegerInclusive(number2TenthsLowerBound, number2TenthsUpperBound, {
        constraint: x => x % 10 !== 0 && x / 10 !== number1
      }) / 10;

    const number3And4HundredthsLowerBound = number1 * 100 + 1;
    const number3And4HundredthsUpperBound = number1 * 100 + 9;

    const number3 =
      randomIntegerInclusive(number3And4HundredthsLowerBound, number3And4HundredthsUpperBound, {
        constraint: x => x % 10 !== 0 && arrayHasNoDuplicates([x / 100, number1, number2])
      }) / 100;

    const number4 =
      randomIntegerInclusive(number3And4HundredthsLowerBound, number3And4HundredthsUpperBound, {
        constraint: x => x % 10 !== 0 && arrayHasNoDuplicates([x / 100, number1, number2, number3])
      }) / 100;

    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    return { number1, number2, number3, number4, smallestOrGreatest };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, smallestOrGreatest },
      translate
    } = props;

    const items = shuffle([number1, number2, number3, number4], {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.selectSmallestNumber()
            : translate.instructions.selectGreatestNumber()
        }
        pdfTitle={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.circleSmallestNumber()
            : translate.instructions.circleGreatestNumber()
        }
        testCorrect={
          smallestOrGreatest === 'smallest' ? [Math.min(...items)] : [Math.max(...items)]
        }
        numItems={4}
        renderItems={items.map(item => ({
          value: item,
          component: <Text variant="WRN700">{item.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aQ5',
  description: 'aQ5',
  keywords: ['Order', 'Decimals', 'Thousandths', 'Hundredths', 'Tenths'],
  schema: z.object({
    numbersAsThousandths: z
      .array(z.number().int().min(1001).max(8999))
      .length(3)
      .refine(val => arrayHasNoDuplicates(val), 'All numbers must be different.'),
    smallestOrGreatest: z.enum(['smallest', 'greatest'])
  }),
  simpleGenerator: () => {
    const basisNumber = randomIntegerInclusive(11, 89, {
      constraint: x => x % 10 !== 0
    });

    const numbersLowerBound = basisNumber * 100 - 99;
    const numbersUpperBound = basisNumber * 100 + 99;

    const numbersAsThousandths = randomUniqueIntegersInclusive(
      numbersLowerBound,
      numbersUpperBound,
      3,
      {
        // Only allow the number to resolve to have either 2 or 3 decimal places:
        constraint: x => x % 100 !== 0
      }
    );

    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);

    return {
      numbersAsThousandths,
      smallestOrGreatest
    };
  },
  Component: props => {
    const {
      question: { numbersAsThousandths, smallestOrGreatest },
      translate,
      displayMode
    } = props;

    const [number1Thousandths, number2Thousandths, number3Thousandths] = numbersAsThousandths;

    const number1 = number1Thousandths / 1000;
    const number2 = number2Thousandths / 1000;
    const number3 = number3Thousandths / 1000;

    const items = [number1, number2, number3];

    const correctAnswer =
      smallestOrGreatest === 'smallest' ? Math.min(...items) : Math.max(...items);

    const tableData: (string | JSX.Element)[][] = [];

    items.forEach(number => {
      const numberAsDigits = numberToBase10Object(number);

      tableData.push([
        numberAsDigits['ones']?.toLocaleString() ?? '',
        numberAsDigits['tenths']?.toLocaleString() ?? '',
        numberAsDigits['hundredths']?.toLocaleString() ?? '',
        // Enforce last cell to be blank if there are zero thousandths:
        numberAsDigits['thousandths'] === 0
          ? ''
          : (numberAsDigits['thousandths']?.toLocaleString() as string)
      ]);
    });

    return (
      <QF36ContentAndSentenceDrag
        title={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.dragCardToShowSmallestNumberFromPVC()
            : translate.instructions.dragCardToShowGreatestNumberFromPVC()
        }
        pdfTitle={
          smallestOrGreatest === 'smallest'
            ? translate.instructions.writeSmallestNumberFromPVC()
            : translate.instructions.writeGreatestNumberFromPVC()
        }
        pdfLayout="itemsBottom"
        pdfItemVariant="tallRectangle"
        sentence={'<ans/>'}
        Content={({ dimens }) => (
          <CustomizableTable
            cellHeaders={[
              {
                label: translate.keywords.Ones(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[0].color
                },
                textStyle: {
                  color: placeValueColumnInfo[0].textColor
                }
              },
              {
                label: translate.keywords.Tenths(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[-1].color
                },
                textStyle: {
                  color: placeValueColumnInfo[-1].textColor
                }
              },
              {
                label: translate.keywords.Hundredths(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[-2].color
                },
                textStyle: {
                  color: placeValueColumnInfo[-2].textColor
                }
              },
              {
                label: translate.keywords.Thousandths(),
                containerStyle: {
                  backgroundColor: placeValueColumnInfo[-3].color
                },
                textStyle: {
                  color: placeValueColumnInfo[-3].textColor
                }
              }
            ]}
            tableData={tableData}
            tableStyle={{ width: dimens.width }}
            tableFontSize={displayMode === 'digital' ? 24 : 50}
            tenthsColumnIndex={1}
          />
        )}
        items={items}
        testCorrect={[correctAnswer]}
        itemVariant="rectangle"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'aQ6',
  description: 'aQ6',
  keywords: ['Order', 'Decimals', 'Thousandths', 'Hundredths', 'Tenths'],
  schema: z.object({
    thousandths: z
      .array(z.number().int().min(900).max(9100))
      .length(4)
      .refine(val => arrayHasNoDuplicates(val), 'All thousandths must be different.'),
    ascendingOrDescending: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const basisThousandths = randomIntegerInclusiveStep(1000, 9000, 100);

    const lowerBound = basisThousandths - 100;

    const upperBound = basisThousandths + 100;

    const [thousandthsA, thousandthsB] = randomUniqueIntegersInclusive(lowerBound, upperBound, 2, {
      // Ensures numbers generated are in the thousandths
      constraint: x => x % 10 !== 0
    });

    const thousandthsC = randomIntegerInclusiveStep(lowerBound, upperBound, 10, {
      // Ensures numbers generated is in the hundredths
      constraint: x => x % 100 !== 0
    });

    const thousandsDArray = [];

    // Ensures number selected is in the tenths
    if (lowerBound % 1000 !== 0) {
      thousandsDArray.push(lowerBound);
    }

    if (upperBound % 1000 !== 0) {
      thousandsDArray.push(upperBound);
    }

    const thousandthsD = getRandomFromArray(thousandsDArray);

    const thousandths = shuffle([
      thousandthsA,
      thousandthsB,
      thousandthsC,
      thousandthsD
    ]) as number[];

    const ascendingOrDescending = getRandomFromArray(['ascending', 'descending'] as const);

    return {
      thousandths,
      ascendingOrDescending
    };
  },
  Component: props => {
    const {
      question: { thousandths, ascendingOrDescending },
      translate
    } = props;

    const [number1Thousandths, number2Thousandths, number3Thousandths, number4Thousandths] =
      thousandths;

    const items = [
      {
        string: (number1Thousandths / 1000).toLocaleString(),
        value: number1Thousandths
      },
      {
        string: (number2Thousandths / 1000).toLocaleString(),
        value: number2Thousandths
      },
      {
        string: (number3Thousandths / 1000).toLocaleString(),
        value: number3Thousandths
      },
      {
        string: (number4Thousandths / 1000).toLocaleString(),
        value: number4Thousandths
      }
    ];

    return (
      <QF4DragOrderVertical
        title={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.dragCardsToOrderNumbersInAscendingOrder()
            : translate.instructions.dragCardsToOrderNumbersInDescendingOrder()
        }
        pdfTitle={
          ascendingOrDescending === 'ascending'
            ? translate.instructions.useCardsToOrderNumbersInAscendingOrder()
            : translate.instructions.useCardsToOrderNumbersInDescendingOrder()
        }
        testCorrect={sortNumberArray(
          items.map(measurement => measurement.value),
          ascendingOrDescending
        )}
        items={items.map(measurement => ({
          value: measurement.value,
          component: measurement.string
        }))}
        topLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Smallest()
            : translate.keywords.Greatest()
        }
        bottomLabel={
          ascendingOrDescending === 'ascending'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question6 = newQuestionContent({
  uid: 'aQ7',
  description: 'aQ7',
  keywords: ['Fractions', 'Decimals', 'Tenths', 'Hundredths', 'Thousandths', 'Compare'],
  schema: z
    .object({
      smallerOrGreater: z.enum(['smaller', 'greater']),
      comparisonNumberThousandths: z.number().int().min(2300).max(9700),
      decimalAThousandths: z.number().int().min(2010).max(9990),
      decimalBThousandths: z.number().int().min(2001).max(9999),
      fractionAThousandths: z.number().int().min(2500).max(9500),
      fractionBThousandths: z.number().int().min(2100).max(9900),
      fractionCThousandths: z.number().int().min(2200).max(9800),
      fractionDThousandths: z.number().int().min(2010).max(9990)
    })
    .refine(
      val => val.decimalAThousandths !== val.decimalBThousandths,
      'Decimals must be different.'
    )
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.fractionAThousandths,
          val.fractionBThousandths,
          val.fractionCThousandths,
          val.fractionDThousandths
        ]),
      'All fractions must be different.'
    ),
  simpleGenerator: () => {
    const smallerOrGreater = getRandomFromArray(['smaller', 'greater'] as const);

    const enforceDecimalOrFractionCorrect = getRandomFromArray(['decimal', 'fraction']);

    const lowerBound = randomIntegerInclusiveStep(2000, 9000, 1000);

    const upperBound = lowerBound + 1000;

    const comparisonNumberThousandths = randomIntegerInclusive(lowerBound + 300, lowerBound + 700);

    // Decimals:

    const { decimalAThousandths, decimalBThousandths } = rejectionSample(
      () => {
        const decimalAThousandths = randomIntegerInclusiveStep(lowerBound, upperBound, 10, {
          // Avoid selecting what will become whole numbers:
          constraint: x => x % 1000 !== 0
        });

        const decimalBThousandths = randomIntegerInclusive(lowerBound + 1, upperBound - 1, {
          // decimalBThousandths will always be in thousandths or hundredths:
          constraint: x =>
            x % 100 !== 0 &&
            // This decimal will always be a correct selectable if enforceDecimalOrFractionCorrect selected 'decimal'
            enforceDecimalOrFractionCorrect === 'decimal'
              ? smallerOrGreater === 'smaller'
                ? x < comparisonNumberThousandths
                : x > comparisonNumberThousandths
              : true
        });
        return { decimalAThousandths, decimalBThousandths };
      },
      // Only permit them if their products are all different.
      ({ decimalAThousandths, decimalBThousandths }) =>
        arrayHasNoDuplicates([decimalAThousandths, decimalBThousandths])
    );

    // Fractions:

    const {
      fractionAThousandths,
      fractionBThousandths,
      fractionCThousandths,
      fractionDThousandths
    } = rejectionSample(
      () => {
        const fractionAThousandths = lowerBound + 500;

        const fractionBThousandths = randomIntegerInclusiveStep(lowerBound, upperBound, 100, {
          // Avoid selecting what will become whole numbers:
          constraint: x => x % 1000 !== 0
        });

        const fractionCThousandths = randomIntegerInclusiveStep(lowerBound, upperBound, 200, {
          // Avoid selecting what will become whole numbers:
          constraint: x => x % 1000 !== 0
        });

        const fractionDThousandths = randomIntegerInclusiveStep(
          lowerBound + 10,
          upperBound - 10,
          10,
          {
            // Avoid selecting what will become whole numbers or tenths:
            constraint: x =>
              x % 100 !== 0 &&
              // This fraction will always be a correct selectable if enforceDecimalOrFractionCorrect selected 'fraction'
              enforceDecimalOrFractionCorrect === 'fraction'
                ? smallerOrGreater === 'smaller'
                  ? x < comparisonNumberThousandths
                  : x > comparisonNumberThousandths
                : true
          }
        );
        return {
          fractionAThousandths,
          fractionBThousandths,
          fractionCThousandths,
          fractionDThousandths
        };
      },
      // Only permit them if their products are all different.
      ({
        fractionAThousandths,
        fractionBThousandths,
        fractionCThousandths,
        fractionDThousandths
      }) =>
        arrayHasNoDuplicates([
          fractionAThousandths,
          fractionBThousandths,
          fractionCThousandths,
          fractionDThousandths
        ])
    );

    return {
      smallerOrGreater,
      comparisonNumberThousandths,
      decimalAThousandths,
      decimalBThousandths,
      fractionAThousandths,
      fractionBThousandths,
      fractionCThousandths,
      fractionDThousandths
    };
  },
  Component: props => {
    const {
      question: {
        smallerOrGreater,
        comparisonNumberThousandths,
        decimalAThousandths,
        decimalBThousandths,
        fractionAThousandths,
        fractionBThousandths,
        fractionCThousandths,
        fractionDThousandths
      },
      translate
    } = props;

    const improperFractionA = decimalToFraction(fractionAThousandths / 1000);
    const improperFractionB = decimalToFraction(fractionBThousandths / 1000);

    const improperFractionC = decimalToFraction(fractionCThousandths / 1000);

    const improperFractionD = decimalToFraction(fractionDThousandths / 1000);

    const fractionA = improperFractionToMixedNumber(improperFractionA[0], improperFractionA[1]);

    const fractionB = improperFractionToMixedNumber(improperFractionB[0], improperFractionB[1]);

    const fractionC = improperFractionToMixedNumber(improperFractionC[0], improperFractionC[1]);

    const fractionD = improperFractionToMixedNumber(improperFractionD[0], improperFractionD[1]);

    const items = shuffle(
      [
        {
          sentence: `${(decimalAThousandths / 1000).toLocaleString()}`,
          value: decimalAThousandths
        },
        {
          sentence: `${(decimalBThousandths / 1000).toLocaleString()}`,
          value: decimalBThousandths
        },
        {
          sentence: `<frac w='${fractionA[0]?.toLocaleString()}' n='${fractionA[1]?.toLocaleString()}' d='${fractionA[2]?.toLocaleString()}' />`,
          value: fractionAThousandths
        },
        {
          sentence: `<frac w='${fractionB[0]?.toLocaleString()}' n='${fractionB[1]?.toLocaleString()}' d='${fractionB[2]?.toLocaleString()}' />`,
          value: fractionBThousandths
        },
        {
          sentence: `<frac w='${fractionC[0]?.toLocaleString()}' n='${fractionC[1]?.toLocaleString()}' d='${fractionC[2]?.toLocaleString()}' />`,
          value: fractionCThousandths
        },
        {
          sentence: `<frac w='${fractionD[0]?.toLocaleString()}' n='${fractionD[1]?.toLocaleString()}' d='${fractionD[2]?.toLocaleString()}' />`,
          value: fractionDThousandths
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF10SelectNumbers
        title={
          smallerOrGreater === 'smaller'
            ? translate.instructions.selectNumbersThatAreSmallerThanNum(
                comparisonNumberThousandths / 1000
              )
            : translate.instructions.selectNumbersThatAreGreaterThanNum(
                comparisonNumberThousandths / 1000
              )
        }
        pdfTitle={
          smallerOrGreater === 'smaller'
            ? translate.instructions.circleNumbersThatAreSmallerThanNum(
                comparisonNumberThousandths / 1000
              )
            : translate.instructions.circleNumbersThatAreGreaterThanNum(
                comparisonNumberThousandths / 1000
              )
        }
        items={items.map(item => ({
          component: (
            <TextStructure
              sentence={item.sentence}
              key={item.sentence}
              textStyle={{ fontSize: 30, fontWeight: '700' }}
              fractionTextStyle={{ fontSize: 30, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: item.sentence
        }))}
        testCorrect={
          smallerOrGreater === 'smaller'
            ? items
                .filter(number => number.value < comparisonNumberThousandths)
                .map(item => item.sentence)
            : items
                .filter(number => number.value > comparisonNumberThousandths)
                .map(item => item.sentence)
        }
        multiSelect
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OrderAndCompareAnyDecimalsWithUpTo3DecimalPlaces',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
