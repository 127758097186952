import { sortNumberArray } from './collections';
import * as math from 'mathjs';

/**
 * {@link https://en.wikipedia.org/wiki/Pythagorean_triple Primitive pythagorean triples}, up to 100.
 */
const PRIMITIVE_PYTHAGOREAN_TRIPLES: [number, number, number][] = [
  [3, 4, 5],
  [5, 12, 13],
  [8, 15, 17],
  [7, 24, 25],
  [20, 21, 29],
  [12, 35, 37],
  [9, 40, 41],
  [28, 45, 53],
  [11, 60, 61],
  [16, 63, 65],
  [33, 56, 65],
  [48, 55, 73],
  [13, 84, 85],
  [36, 77, 85],
  [39, 80, 89],
  [65, 72, 97]
];

/**
 * Get a list of all possible integer side lengths of a right-angled triangle, e.g. [3,4,5]
 *
 * Note: we only use pythagorean triples up to 100, and currently aren't
 * {@link https://en.wikipedia.org/wiki/Formulas_for_generating_Pythagorean_triples generating them}. Therefore, this
 * function will miss some possibilities when max>100.
 */
export function getIntegerRightAngleTriangleLengths({
  min = 0,
  max = 100
}: {
  min?: number;
  max?: number;
}): [number, number, number][] {
  const isValid = ([a, _b, c]: [number, number, number]) => a >= min && c <= max;

  return PRIMITIVE_PYTHAGOREAN_TRIPLES.flatMap(lengths => {
    const validTriples: [number, number, number][] = [];
    let multiple = 1;
    let currentTriple = lengths;

    while (isValid(currentTriple)) {
      validTriples.push(currentTriple);
      multiple++;
      currentTriple = lengths.map(length => length * multiple) as [number, number, number];
    }

    return validTriples;
  });
}

/** Categorise a triangle by its side lengths, or return null if it's not a triangle. */
export function categoriseTriangle(
  sideLengths: [number, number, number]
): ('isosceles' | 'right' | 'equilateral' | 'scalene')[] | null {
  const [a, b, c] = sortNumberArray(sideLengths);
  // Check for degeneracy
  if (a + b <= c) return null;

  if (math.equal(a, b) || math.equal(b, c)) {
    // Isosceles
    if (math.equal(a, c)) return ['isosceles', 'equilateral'];
    if (math.equal(a ** 2 + b ** 2, c ** 2)) return ['isosceles', 'right'];
    return ['isosceles'];
  } else {
    // Scalene
    if (math.equal(a ** 2 + b ** 2, c ** 2)) return ['scalene', 'right'];
    return ['scalene'];
  }
}
