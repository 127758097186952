import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { View } from 'react-native';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { ADD, SUB } from '../../../../constants';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { useMemo } from 'react';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF35ContentAndSentencesDragGridLayout from '../../../../components/question/questionFormats/QF35ContentAndSentencesDragGridLayout';
import { NumberLineVariableTickWithState } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';
import { findExchanges } from '../../../../utils/exchanges';
import QF38ContentWithSentenceTrueOrFalse from '../../../../components/question/questionFormats/QF38ContentWithSentenceTrueOrFalse';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ahC',
  description: 'ahC',
  keywords: ['Inverse', 'Fact family', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(10).max(800).multipleOf(10),
    number2: z.number().int().min(10).max(800).multipleOf(10),
    number3: z.number().int().min(20).max(900)
  }),
  simpleGenerator: () => {
    //$var1 and $var2 are both (i) multiple of 10 <100 or (ii) multiples of 100 <1000 (equal chance). $var3=$var1+$var2 no exchange. $var3<1000
    let number1 = randomIntegerInclusive(1, 8);
    let number2 = randomIntegerInclusive(1, 9 - number1, {
      constraint: x => x !== number1
    });
    let number3 = number1 + number2;

    const multiplier = getRandomFromArray([10, 100] as const);
    number1 *= multiplier;
    number2 *= multiplier;
    number3 *= multiplier;

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;
    const numbers = [[number3], [number1, number2]];

    return (
      <QF35ContentAndSentencesDragGridLayout
        moveOrCopy="copy"
        title={translate.instructions.dragCardsMakeFourDifferentNumberSentences()}
        pdfTitle={translate.instructions.useCardsMakeFourDifferentNumberSentences()}
        sentenceStyle={{ justifyContent: 'flex-end', paddingRight: 96 }}
        items={[number1, number2, number3]}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            total={number3}
            dimens={{ width: dimens.width * 0.6, height: dimens.height }}
            oneFontSize
          />
        )}
        sentences={[
          `${number1.toLocaleString()} ${ADD} <ans /> = <ans />`,
          `<ans /> ${SUB} ${number1.toLocaleString()} = <ans />`,
          `<ans /> ${ADD} <ans /> = <ans />`,
          `<ans /> ${SUB} <ans /> = <ans />`
        ]}
        testCorrect={[
          [number2, number3],
          [number3, number2],
          [number2, number1, number3],
          [number3, number2, number1]
        ]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'ahD',
  description: 'ahD',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Match'],
  schema: z
    .object({
      numberA1: z.number().int().min(10).max(980).multipleOf(10),
      numberA2: z.number().int().min(10).max(980).multipleOf(10),
      numberB2: z.number().int().min(10).max(980).multipleOf(10),
      numberC1: z.number().int().min(10).max(980).multipleOf(10),
      numberC2: z.number().int().min(10).max(980).multipleOf(10),
      numberD1: z.number().int().min(10).max(980).multipleOf(10)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.numberA1,
          val.numberA2,
          val.numberB2,
          val.numberC1,
          val.numberC2,
          val.numberD1
        ]),
      'All numbers should be different.'
    )
    .refine(
      val => val.numberA1 + val.numberA2 < 1000,
      'numberA1 + numberA2 must be less than 1,000'
    )
    .refine(
      val => val.numberA1 + val.numberB2 < 1000,
      'numberA1 + numberB2 must be less than 1,000'
    )
    .refine(
      val => val.numberC1 + val.numberC2 < 1000,
      'numberC1 + numberC2 must be less than 1,000'
    )
    .refine(
      val => val.numberD1 + val.numberC2 < 1000,
      'numberD1 + numberC2 must be less than 1,000'
    )
    .refine(
      val => val.numberA1 + val.numberA2 === val.numberD1 + val.numberC2,
      'numberA1 + numberA2 must total to the same as numberD1 + numberC2'
    ),
  simpleGenerator: () => {
    const { numberA1, numberA2, numberB2, numberC1, numberC2, numberD1 } = rejectionSample(
      () => {
        // Get a1+a2 < 1,000
        const sumA = randomIntegerInclusiveStep(30, 990, 10);
        const numberA1 = randomIntegerInclusiveStep(10, sumA - 10, 10);
        const numberA2 = sumA - numberA1;

        // Get c1+c2 < 1,000
        const sumC = randomIntegerInclusiveStep(30, 990, 10);
        const numberC1 = randomIntegerInclusiveStep(10, sumC - 10, 10);
        const numberC2 = sumC - numberC1;

        // Get b2 such that a1+b2 < 1,000
        const numberB2 = randomIntegerInclusiveStep(10, 980, 10, {
          constraint: x => numberA1 + x < 1000
        });

        const numberD1 = numberA1 + numberA2 - numberC2;

        return { numberA1, numberA2, numberC1, numberC2, numberB2, numberD1 };
      },
      ({ numberA1, numberA2, numberC1, numberC2, numberB2, numberD1 }) =>
        numberD1 >= 10 &&
        arrayHasNoDuplicates([numberA1, numberA2, numberC1, numberC2, numberB2, numberD1])
    );

    return { numberA1, numberA2, numberB2, numberC1, numberC2, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB2, numberC1, numberC2, numberD1 },
      translate
    } = props;

    // Some of these numbers are just duplicates of others - created here to make code further down more readable.
    const numberA3 = numberA1 + numberA2;
    const numberB1 = numberA1;
    const numberB3 = numberB1 + numberB2;
    const numberC3 = numberC1 + numberC2;
    const numberD2 = numberC2;
    const numberD3 = numberD1 + numberD2;

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: `${numberA1.toLocaleString()} ${ADD} ${numberA2.toLocaleString()} = ${numberA3.toLocaleString()}`,
        value: 'A'
      };
      const statement2 = {
        statement: `${numberB1.toLocaleString()} ${ADD} ${numberB2.toLocaleString()} = ${numberB3.toLocaleString()}`,
        value: 'B'
      };
      const statement3 = {
        statement: `${numberC3.toLocaleString()} ${SUB} ${numberC2.toLocaleString()} = ${numberC1.toLocaleString()}`,
        value: 'C'
      };
      const statement4 = {
        statement: `${numberD3.toLocaleString()} = ${numberD1.toLocaleString()} ${ADD} ${numberD2.toLocaleString()}`,
        value: 'D'
      };
      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [
      numberA1,
      numberA2,
      numberA3,
      numberB1,
      numberB2,
      numberB3,
      numberC1,
      numberC2,
      numberC3,
      numberD1,
      numberD2,
      numberD3,
      props.question
    ]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchInverseCalcs()}
        pdfTitle={translate.instructions.useCardsMatchInverseCalcs()}
        items={[
          {
            component: `${numberA3.toLocaleString()} ${SUB} ${numberA2.toLocaleString()} = ${numberA1.toLocaleString()}`,
            value: 'A'
          },
          {
            component: `${numberB3.toLocaleString()} = ${numberB2.toLocaleString()} ${ADD} ${numberB1.toLocaleString()}`,
            value: 'B'
          },
          {
            component: `${numberC2.toLocaleString()} ${ADD} ${numberC1.toLocaleString()} = ${numberC3.toLocaleString()}`,
            value: 'C'
          },
          {
            component: `${numberD3.toLocaleString()} ${SUB} ${numberD2.toLocaleString()} = ${numberD1.toLocaleString()}`,
            value: 'D'
          }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        itemMaxLines={1}
        itemsLetterEmWidth={0.5}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'ahE',
  description: 'ahE',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Check'],
  schema: z
    .object({
      number1: z.number().int().min(10).max(99),
      number2: z.number().int().min(100).max(999),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(val => val.number1 + val.number2 < 1000, 'number1 plus number2 must be less than 1000'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(10, 99);
    const number2 = randomIntegerInclusive(100, 999 - number1);
    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return {
      addOrSubtract,
      number1,
      number2
    };
  },

  Component: props => {
    const {
      question: { addOrSubtract, number1, number2 },
      translate
    } = props;
    const number3 = number1 + number2;
    const question =
      addOrSubtract === ADD
        ? `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = ${number3.toLocaleString()}`
        : `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()} = ${number2.toLocaleString()}`;

    const random = seededRandom(props.question);

    const answers =
      addOrSubtract === ADD
        ? shuffle(
            [
              `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
              `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()}`,
              `${number1.toLocaleString()} ${SUB} ${number3.toLocaleString()}`,
              `${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()}`
            ],
            { random }
          )
        : shuffle(
            [
              `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()}`,
              `${number2.toLocaleString()} ${ADD} ${number1.toLocaleString()}`,
              `${number3.toLocaleString()} ${ADD} ${number2.toLocaleString()}`,
              `${number1.toLocaleString()} ${ADD} ${number3.toLocaleString()}`
            ],
            { random }
          );

    const correct =
      addOrSubtract === ADD
        ? [
            `${number3.toLocaleString()} ${SUB} ${number1.toLocaleString()}`,
            `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()}`
          ]
        : [
            `${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()}`,
            `${number2.toLocaleString()} ${ADD} ${number1.toLocaleString()}`
          ];

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectCalcsThatCanBeUsedToCheckCalc(question)}
        pdfTitle={translate.instructions.circleCalcsThatCanBeUsedToCheckCalc(question)}
        testCorrect={correct}
        multiSelect
        items={answers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
      />
    );
  }
});
export const ahC = Question1;

const Question4 = newQuestionContent({
  uid: 'ahF',
  description: 'ahF',
  keywords: ['Inverse', 'Addition', 'Subtraction', 'Check'],
  schema: z
    .object({
      name: nameSchema,
      number3: z.number().int().min(300).max(900).multipleOf(100),
      number1: z.number().int().min(101).max(799),
      addOrSubtract: z.enum(['add', 'subtract'])
    })
    .refine(
      val => val.number1 <= val.number3 - 101,
      'number1 must be at least 101 less than number3.'
    )
    .refine(val => val.number1 % 100 !== 0, 'number1 cannot be a multiple of 100'),
  simpleGenerator: () => {
    const name = getRandomName();

    const number3 = randomIntegerInclusiveStep(300, 900, 100);

    const number1 = randomIntegerInclusive(101, number3 - 101, {
      constraint: x => x % 100 !== 0
    });

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);

    return { name, number1, number3, addOrSubtract };
  },

  Component: props => {
    const {
      question: { name, number1, number3, addOrSubtract },
      translate
    } = props;

    const number2 = number3 - number1;

    return (
      <QF2AnswerBoxOneSentence
        title={
          addOrSubtract === 'add'
            ? translate.instructions.characterWorksOutAddition({
                name,
                leftNum: number1.toLocaleString(),
                rightNum: number2.toLocaleString(),
                result: number3.toLocaleString()
              })
            : translate.instructions.characterWorksOutSubtraction({
                name,
                leftNum: number3.toLocaleString(),
                rightNum: number2.toLocaleString(),
                result: number1.toLocaleString()
              })
        }
        testCorrect={answer =>
          addOrSubtract === 'add'
            ? answer[0] === number3.toString() &&
              (answer[1] === number1.toString() || answer[1] === number2.toString())
            : (answer[0] === number1.toString() && answer[1] === number2.toString()) ||
              (answer[0] === number2.toString() && answer[1] === number1.toString())
        }
        inputMaxCharacters={3}
        sentence={addOrSubtract === 'add' ? `<ans/> ${SUB} <ans/>` : `<ans/> ${ADD} <ans/>`}
        customMarkSchemeAnswer={{
          answersToDisplay:
            addOrSubtract === 'add'
              ? [number3.toLocaleString(), number1.toLocaleString()]
              : [number1.toLocaleString(), number2.toLocaleString()],
          answerText: translate.markScheme.orAnyOtherValidAnswer()
        }}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

const Question5 = newQuestionContent({
  uid: 'ahG',
  description: 'ahG',
  keywords: ['Number line', 'Inverse', 'Addition', 'Subtraction'],
  schema: z.object({
    number5: z.number().int().min(100).max(300).multipleOf(100),
    number4: z.number().int().min(25).max(99),
    number3: z.number().int().min(300).max(900).multipleOf(100)
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const number5 = randomIntegerInclusiveStep(100, 300, 100);
    const number4 = randomIntegerInclusive(25, 99);
    const number3 = randomIntegerInclusiveStep(number5 + 200, 900, 100);

    return { number3, number4, number5 };
  },
  Component: props => {
    const {
      question: { number3, number4, number5 },
      translate,
      displayMode
    } = props;

    const number6 = number3 - number5;
    const number2 = number6 - number4;
    const ans = number3 - number2;

    // Adjust the middle tick to atleast a min of 20% of the line otherwise the ticks are too close
    const minMiddleTick = Math.max(number2 + ans * 0.2, number6);

    const startingNumber = number2;
    const endNumber = number3;

    const tickArray = [
      { label: number2.toLocaleString(), position: number2 },
      { label: number6.toLocaleString(), position: minMiddleTick },
      { label: number3.toLocaleString(), position: number3 }
    ];

    const jumpArrowArray = [
      { start: number3, end: minMiddleTick, label: `${SUB}<ans/>` },
      { start: minMiddleTick, end: number2, label: `${SUB}${number4.toLocaleString()}` }
    ];

    const sentence = `${number3} ${SUB} ${number2} = <ans/>`;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useNumberLineToFindDiffBetweenXAnsY(
          number3.toLocaleString(),
          number2.toLocaleString()
        )}
        testCorrect={[ans.toString()]}
        sentence={sentence}
        pdfDirection="column"
        Content={({ dimens }) => (
          <NumberLineVariableTickWithState
            id="numberline"
            dimens={
              displayMode === 'digital'
                ? dimens
                : { height: dimens.height, width: dimens.width * 0.9 }
            }
            tickValues={tickArray}
            start={startingNumber}
            end={endNumber}
            jumpArrowArray={jumpArrowArray}
            subtraction
            testCorrect={answer => answer[0] === number5.toString()}
            defaultState={displayMode === 'markscheme' ? [number5.toLocaleString()] : undefined}
            // Need a slightly smaller font size on digital to prevent number under ticks being too close together:
            customFontSize={displayMode !== 'digital' ? undefined : 32}
          />
        )}
        // This is because the answer boxes are absolutely positioned so this just nudges it away from title slightly
        mainPanelStyle={{ paddingTop: 40 }}
        questionHeight={1200}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ahH',
  description: 'ahH',
  keywords: ['Inverse', 'Subtraction', 'Check'],
  schema: z.object({
    topAddend: z.number().int().min(1).max(998),
    bottomAddend: z.number().int().min(1).max(998),
    totalToDisplay: z.number().int().min(2).max(999)
  }),
  simpleGenerator: () => {
    const { topAddend, bottomAddend } = rejectionSample(
      () => {
        const topAddend = randomIntegerInclusive(10, 899);
        const bottomAddend = randomIntegerInclusive(100, 989);
        return { topAddend, bottomAddend };
      },
      // Only permit them if they total less than 1,000 and have 0 or 1 exchanges.
      ({ topAddend, bottomAddend }) =>
        topAddend + bottomAddend < 1000 && findExchanges(topAddend, bottomAddend).length <= 1
    );

    const actualTotal = topAddend + bottomAddend;

    const incorrectAnswerDifference = getRandomFromArray(
      [-100, -10, 10, 100].filter(
        num => num + actualTotal < 1000 && num + actualTotal > bottomAddend
      )
    );

    const totalToDisplay = actualTotal + getRandomFromArray([0, incorrectAnswerDifference ?? 0]);

    return { topAddend, bottomAddend, totalToDisplay };
  },
  Component: ({ question: { topAddend, bottomAddend, totalToDisplay }, translate }) => {
    const actualTotal = topAddend + bottomAddend;

    return (
      <QF38ContentWithSentenceTrueOrFalse
        title={translate.instructions.useAdditionToWorkOutWhetherThisCalcIsCorrect()}
        pdfTitle={translate.instructions.useAdditionToWorkOutWhetherThisCalcIsCorrectPDF()}
        correctAnswer={actualTotal === totalToDisplay}
        trueButtonLabel={translate.misc.Correct()}
        falseButtonLabel={translate.misc.Incorrect()}
        content={({ dimens }) => (
          <View
            style={[
              dimens,
              {
                alignItems: 'center',
                justifyContent: 'center'
              }
            ]}
          >
            <Text variant="WRN400">{`${totalToDisplay.toLocaleString()} ${SUB} ${bottomAddend.toLocaleString()} = ${topAddend.toLocaleString()}`}</Text>
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InverseOperations',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
