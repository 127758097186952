import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import {
  getShapeSvgName,
  regularShapes,
  regularShapeWidthFromSideLength
} from '../../../../utils/shapeImages/shapes';
import { getIrregularShapeSvgName } from '../../../../utils/shapeImages/irregular';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import QF42bDraggableShapeOnRuler from '../../../../components/question/questionFormats/QF42bDraggableShapeOnRuler';
import {
  getRandomUniqueShapeAnglesWithVertices,
  isValidIrregularShape,
  shapeAnglesWithVerticesSchema
} from '../../../../utils/shapes';
import QF50bRotatableProtractor from '../../../../components/question/questionFormats/QF50bRotatableProtractor';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aAA',
  description: 'aAA',
  keywords: ['Length', 'Ruler', 'Measure', 'cm', 'Polygon'],
  schema: z.object({
    shapeName: z.enum(regularShapes),
    length: z.number().int().min(1).max(12),
    offsetX: z.number().int().min(0).max(14),
    offsetY: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const shapeName = getRandomFromArray(regularShapes);
    const minBound = !['equilateralTriangles', 'squares'].includes(shapeName) ? 1 : 4;
    const maxBound = (() => {
      switch (shapeName) {
        case 'equilateralTriangles':
          return 10;
        case 'squares':
          return 10;
        case 'pentagons':
          return 6;
        case 'hexagons':
          return 5;
        case 'heptagons':
          return 5;
        case 'octagons':
          return 4;
        case 'nonagons':
          return 3;
        case 'decagons':
          return 2;
      }
    })();
    const length = randomIntegerInclusive(minBound, maxBound);
    const offsetX = randomIntegerInclusive(0, 15 - length);
    const offsetY = randomIntegerInclusive(1, 3);

    return {
      shapeName,
      length,
      offsetX,
      offsetY
    };
  },
  Component: props => {
    const {
      question: { shapeName, length, offsetX, offsetY },
      translate
    } = props;

    const random = props.question;
    const regularShape = getShapeSvgName(shapeName, random);
    const shapeWidth = regularShapeWidthFromSideLength(shapeName, length);

    return (
      <QF42bDraggableShapeOnRuler
        title={translate.instructions.measureTheSidesOfRegularXShapeYouCanDragTheXShapeToHelp(
          translate.shapes[shapeName](1)
        )}
        pdfTitle={translate.instructions.measureTheSidesOfRegularXShape(
          translate.shapes[shapeName](1)
        )}
        rulerKind={'cm'}
        rulerLength={15}
        sentence={translate.answerSentences.lengthOfSidesEqualsAnsCm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        length={shapeWidth}
        offsetX={offsetX}
        pdfOffsetX={-(shapeWidth - length) / 2}
        offsetY={-offsetY}
        expandClamp={!['equilateralTriangles', 'squares'].includes(shapeName)}
        testCorrect={[length.toString()]}
        markSchemeAnswer={[length.toLocaleString()]}
        shapeIconName={regularShape}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aAB',
  description: 'aAB',
  keywords: ['Angles', 'Protractor', 'Measure', 'Polygon'],
  schema: z.object({
    shapeAngles: shapeAnglesWithVerticesSchema,
    isShapeRHS: z.boolean(),
    additionalRotation: z.number().int().min(-20).max(20)
  }),
  simpleGenerator: () => {
    const shapeAngles = getRandomUniqueShapeAnglesWithVertices();
    const isShapeRHS = getRandomBoolean();
    // swap the min and max rotation to prevent shape being cut off
    const additionalRotation = isShapeRHS
      ? randomIntegerInclusive(-4, 20, { constraint: x => x !== 0 })
      : randomIntegerInclusive(-20, 4, { constraint: x => x !== 0 });

    return {
      shapeAngles,
      additionalRotation,
      isShapeRHS
    };
  },
  Component: ({ question, translate }) => {
    const { shapeAngles, additionalRotation, isShapeRHS } = question;

    const { answer, vertex } = isShapeRHS ? shapeAngles.rhs : shapeAngles.lhs;

    return (
      <QF50bRotatableProtractor
        title={translate.instructions.measureTheSizeOfTheAngleDragToRotateProtractor()}
        pdfTitle={translate.instructions.measureTheSizeOfTheAngle()}
        sentence="<ans/>°"
        testCorrect={ans => isInRange(answer - 1, answer + 1)(parseFloat(ans[0]))}
        shapeToMeasure={{
          type: 'svg',
          name: isShapeRHS
            ? (shapeAngles.rhs.svgName as SvgName)
            : (shapeAngles.lhs.svgName as SvgName),
          height: shapeAngles.rhs.svgName.includes('triangle_RA_long') ? 150 : 200,
          vertexX: vertex.x,
          vertexY: vertex.y
        }}
        additionalRotation={additionalRotation}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.answerWithinRange(1)
        }}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aAC',
  description: 'aAC',
  keywords: ['Polygons'],
  schema: z.object({
    regularPolygonSvg: z.string(),
    irregularPolygonsSvgs: z.array(z.string()).length(3)
  }),
  simpleGenerator: () => {
    const regularPolygon = getRandomFromArray([
      'triangles',
      'squares',
      'pentagons',
      'hexagons',
      'heptagons',
      'octagons',
      'nonagons',
      'decagons'
    ] as const);
    const regularPolygonSvg = getShapeSvgName(regularPolygon);

    const irregularPolygons = getRandomSubArrayFromArray(
      [
        'hexagons',
        'pentagons',
        'octagons',
        'heptagons',
        'nonagons',
        'decagons',
        'dodecagons',
        'rectangles'
      ] as const,
      3
    );

    const irregularPolygonsSvgs = irregularPolygons.map(shape => getIrregularShapeSvgName(shape));

    return {
      regularPolygonSvg,
      irregularPolygonsSvgs
    };
  },
  Component: props => {
    const {
      question: { regularPolygonSvg, irregularPolygonsSvgs },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: 'A',
          svgName: regularPolygonSvg
        },
        {
          value: 'B',
          svgName: irregularPolygonsSvgs[0]
        },
        {
          value: 'C',
          svgName: irregularPolygonsSvgs[1]
        },
        {
          value: 'D',
          svgName: irregularPolygonsSvgs[2]
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectRegularPolygon()}
        pdfTitle={translate.instructions.circleRegularPolygon()}
        testCorrect={['A']}
        numItems={4}
        renderItems={({ dimens }) => {
          return items.map(item => ({
            value: item.value,
            component: (
              <AssetSvg
                name={item.svgName as SvgName}
                height={dimens.height * 0.8}
                width={dimens.width * 0.8}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aAD',
  description: 'aAD',
  keywords: ['Polygon'],
  schema: z.object({}),
  simpleGenerator: () => {
    return {};
  },
  Component: ({ translate }) => {
    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.instructions.createAnIrregularPolygonOnTheGridTapDotsToShowVertices()}
        pdfTitle={translate.instructions.createAnIrregularPolygonOnTheGridUseDotsToShowVertices()}
        closeShape
        testCorrect={userAnswer => isValidIrregularShape(userAnswer)}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptValidVerticesForIrregularPolygon()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aAE',
  description: 'aAE',
  keywords: ['Length', 'Perimeter', 'Polygons'],
  schema: z.object({
    name: nameSchema,
    numberSides: numberEnum([3, 4, 5, 6, 7, 8, 9, 10]),
    lengthOfSide: z.number().int().min(6).max(14)
  }),

  simpleGenerator: () => {
    const name = getRandomName();
    const numberSides = getRandomFromArray([5, 6, 7, 8, 9, 10] as const);

    const lengthOfSide = randomIntegerInclusive(6, 14);

    return { name, numberSides, lengthOfSide };
  },

  Component: props => {
    const {
      question: { name, numberSides, lengthOfSide },
      translate
    } = props;

    const shape = {
      3: 'triangles',
      4: 'squares',
      5: 'pentagons',
      6: 'hexagons',
      7: 'heptagons',
      8: 'octagons',
      9: 'nonagons',
      10: 'decagons'
    } as const;

    const perimeter = lengthOfSide * numberSides;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.xDrawsARegularYEachSideIsZCmWhatIsThePerimeter(
          name,
          translate.shapes[shape[numberSides]](1),
          lengthOfSide.toLocaleString()
        )}
        sentence={translate.answerSentences.ansCm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[perimeter.toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aAF',
  description: 'aAF',
  keywords: ['Length', 'Perimeter', 'Polygons'],
  schema: z
    .object({
      numberSides: numberEnum([5, 6, 7, 8, 9, 10]),
      perimeter: z.number().int().min(50).max(99)
    })
    .refine(
      ({ numberSides, perimeter }) => perimeter % numberSides === 0,
      'perimeter must be multiple of sides'
    ),
  simpleGenerator: () => {
    const numberSides = getRandomFromArray([5, 6, 7, 8, 9, 10] as const);

    const perimeter = randomIntegerInclusiveStep(numberSides * (15 - numberSides), 99, numberSides);

    return { numberSides, perimeter };
  },

  Component: props => {
    const {
      question: { numberSides, perimeter },
      translate
    } = props;

    const shape = {
      5: 'pentagons',
      6: 'hexagons',
      7: 'heptagons',
      8: 'octagons',
      9: 'nonagons',
      10: 'decagons'
    } as const;

    const lengthOfSide = perimeter / numberSides;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.perimeterOfRegularXisYMmWhatIsLengthOfEachSide(
          translate.shapes[shape[numberSides]](1),
          perimeter.toLocaleString()
        )}
        sentence={translate.answerSentences.ansMm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[lengthOfSide.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RegularAndIrregularPolygons',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
