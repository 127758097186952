import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from '../../../../utils/random';
import { base10ObjectToNumber, ScientificNotation } from '../../../../utils/math';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import QF12CreateGattegnoChart from '../../../../components/question/questionFormats/QF12CreateGattegnoChart';
import { DIV, MULT } from '../../../../constants';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aeO',
  description: 'aeO',
  keywords: ['Place value', 'Powers of 10', 'Chart'],
  schema: z
    .object({
      number: z.number().min(11).max(99000),
      powerOfTen: z.number().min(1).max(4)
    })
    .refine(val => val.number * Math.pow(10, val.powerOfTen) < 1000000),
  simpleGenerator: () => {
    const firstDigit = randomIntegerInclusive(1, 9);
    const secondDigit = randomIntegerInclusive(1, 9);
    // This generates a number with exactly two consecutive significant digits, from 11 to 99000
    const number =
      base10ObjectToNumber({ ones: firstDigit, tens: secondDigit }) *
      Math.pow(10, randomIntegerInclusive(0, 3));

    // This function prevents asking the user to ever create a number into the millions:
    const powerOfTenSwitch = () => {
      if (number >= 10000) {
        return 1;
      }
      if (number >= 1000) {
        return randomIntegerInclusive(1, 2);
      }
      if (number >= 100) {
        return randomIntegerInclusive(1, 3);
      }
      return randomIntegerInclusive(1, 4);
    };

    const powerOfTen = powerOfTenSwitch();
    return { number, powerOfTen };
  },
  Component: ({ question: { number, powerOfTen }, translate, displayMode }) => {
    const power = Math.pow(10, powerOfTen);

    return (
      <QF23CreatePlaceValueChart
        initialState={displayMode === 'digital' ? number : undefined}
        number={ScientificNotation.fromNumber(number * power)}
        columnsToShow={[5, 4, 3, 2, 1, 0]}
        counterVariant="greyCounter"
        headerVariant="shortName"
        title={translate.instructions.dragCountersToShowTheAnswerToX(
          `${number.toLocaleString()} ${MULT} ${power.toLocaleString()}`
        )}
        pdfTitle={translate.instructions.drawCountersToShowTheAnswerToX(
          `${number.toLocaleString()} ${MULT} ${power.toLocaleString()}`
        )}
        questionHeight={850}
      />
    );
  },
  questionHeight: 850
});

const Question2 = newQuestionContent({
  uid: 'aeP',
  description: 'aeP',
  keywords: ['Place value', 'Powers of 10'],
  schema: z.object({
    powerOfNum1: z.number().min(2).max(5),
    powerOfNum2: z.number().min(2).max(5)
  }),
  simpleGenerator: () => {
    const powerOfNum1 = randomIntegerInclusive(2, 5);
    const powerOfNum2 = rejectionSample(
      () => randomIntegerInclusive(2, 5),
      x => x !== powerOfNum1
    );

    return { powerOfNum1, powerOfNum2 };
  },
  Component: ({ question: { powerOfNum1, powerOfNum2 }, translate }) => {
    const number1 = Math.pow(10, powerOfNum1);
    const number2 = Math.pow(10, powerOfNum2);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={[[(number1 * 10).toString()], [(number2 * 10).toString()]]}
        sentences={[
          translate.answerSentences.numTimes10Equals(number1.toLocaleString()),
          translate.answerSentences.ansDivBy10Equals(number2.toLocaleString())
        ]}
        containerStyle={{ alignItems: 'center' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aeQ',
  description: 'aeQ',
  keywords: ['Place value', 'Powers of 10'],
  schema: z.object({
    number1: z.number().min(2000).max(9000).multipleOf(1000),
    number2: z.number().min(10000).max(90000).multipleOf(10000),
    number3: z
      .number()
      .min(2000)
      .max(99000)
      .multipleOf(1000)
      .refine(val => val % 10000 !== 0, 'Number must not be a multiple of 10,000')
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9) * 1000;
    const number2 = randomIntegerInclusive(1, 9) * 10000;
    const number3 = rejectionSample(
      () => randomIntegerInclusive(2, 99) * 1000,
      x => x % 10000 !== 0
    );

    return { number1, number2, number3 };
  },
  Component: ({ question: { number1, number2, number3 }, translate }) => {
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[
          [(number1 / 1000).toString()],
          [(number2 / 1000).toString()],
          [(number3 / 1000).toString()]
        ]}
        sentences={[
          translate.answerSentences.numHasAns1000s(number1),
          translate.answerSentences.numHasAns1000s(number2),
          translate.answerSentences.numHasAns1000s(number3)
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aeR',
  description: 'aeR',
  keywords: ['Place value', 'Powers of 10', 'Gattegno'],
  schema: z.object({
    number: z.number().int().min(100).max(9900).multipleOf(100),
    powerOfTen: z.union([z.literal(2), z.literal(-2)])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 99) * 100;
    const powerOfTen = getRandomFromArray([-2, 2] as const);
    return { number, powerOfTen };
  },
  Component: props => {
    const {
      question: { number, powerOfTen },
      translate
    } = props;

    const answer = number * Math.pow(10, powerOfTen);

    const preshadedThousands = ScientificNotation.fromNumber(number).digitAt('thousands') * 1000;

    const preshadedHundreds = ScientificNotation.fromNumber(number).digitAt('hundreds') * 100;

    return (
      <QF12CreateGattegnoChart
        correctAnswer={answer}
        rowsToShow={[5, 4, 3, 2, 1, 0]}
        preshaded={[preshadedThousands, preshadedHundreds]}
        title={
          powerOfTen === -2
            ? translate.instructions.showNumOneHundredthSizeOfNumOnGattegno(number.toLocaleString())
            : translate.instructions.showNum100TimesSizeOfNumOnGattegno(number.toLocaleString())
        }
        questionHeight={1000}
      />
    );
  }
});
const Question5 = newQuestionContent({
  uid: 'aeS',
  description: 'aeS',
  keywords: ['Place value', 'Powers of 10'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int().min(10).max(90).multipleOf(10),
    number3: z.number().int().min(100).max(990).multipleOf(10),
    number4: z.number().int().min(100).max(900).multipleOf(100),
    powerOfNum1Unit: z.number().int().min(1).max(3),
    powerOfNum2Unit: z.number().int().min(1).max(3),
    powerOfNum3Unit: z.number().int().min(1).max(3),
    powerOfNum4Unit: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 99);

    // number2 must be multiple of 10 from 10 to 90
    const number2 = randomIntegerInclusive(1, 9) * 10;

    // number3 must be multiple of 10 from 100 to 990
    const number3 = randomIntegerInclusive(10, 99) * 10;

    // number4 must be multiple of 100 from 100 to 900
    const number4 = randomIntegerInclusive(1, 9) * 100;

    const powerOfNum1Unit = randomIntegerInclusive(1, 3);
    const powerOfNum2Unit = randomIntegerInclusive(1, 3);
    const powerOfNum3Unit = randomIntegerInclusive(1, 3);
    const powerOfNum4Unit = randomIntegerInclusive(1, 3);
    return {
      number1,
      number2,
      number3,
      number4,
      powerOfNum1Unit,
      powerOfNum2Unit,
      powerOfNum3Unit,
      powerOfNum4Unit
    };
  },
  Component: props => {
    const {
      question: {
        number1,
        number2,
        number3,
        number4,
        powerOfNum1Unit,
        powerOfNum2Unit,
        powerOfNum3Unit,
        powerOfNum4Unit
      },
      translate
    } = props;

    const power1 = Math.pow(10, powerOfNum1Unit);
    const power2 = Math.pow(10, powerOfNum2Unit);
    const power3 = Math.pow(10, powerOfNum3Unit);
    const power4 = Math.pow(10, powerOfNum4Unit);

    const numbersAndPowersAndSign: [number, number, 'mult' | 'div'][] = [
      [number1, power1, 'mult'],
      [number2, power2, 'div'],
      [number3, power3, 'mult'],
      [number4, power4, 'div']
    ];

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteCalculations()}
        actionPanelVariant="end"
        pdfLayout="itemsRight"
        pdfTitle={translate.instructions.useCardsCompleteCalculationsEachCardCanBeUsedMoreThanOnce()}
        items={[10, 100, 1000]}
        pdfItemVariant="tallRectangle"
        sentences={numbersAndPowersAndSign.map(
          ([number, power, sign]) =>
            (sign === 'mult'
              ? `${number.toLocaleString()} ${MULT} `
              : `${(number * power).toLocaleString()} ${DIV} `) +
            ' <ans/> ' +
            (sign === 'mult'
              ? ` = ${(number * power).toLocaleString()}`
              : ` = ${number.toLocaleString()}`)
        )}
        testCorrect={numbersAndPowersAndSign.map(([_number, power, _sign]) => [power])}
        moveOrCopy="copy"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aeT',
  description: 'aeT',
  keywords: ['Place value', 'Powers of 10', 'Conversion', 'Units'],
  schema: z.object({
    number: z.number().int().min(1).max(9),
    powerOfTen: z.number().int().min(1).max(2)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 9);
    const powerOfTen = randomIntegerInclusive(1, 2);
    return { number, powerOfTen };
  },
  Component: props => {
    const {
      question: { number, powerOfTen },
      translate
    } = props;

    const numbers = [new Array(number).fill(1), new Array(number).fill(1)];

    const strings = [
      new Array(number).fill(`${translate.units.numberOfKg(1)}`),
      new Array(number).fill(`${translate.units.numberOfG(1000)}`)
    ];

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={translate.instructions.oneKgIs1000g()}
        testCorrect={[
          [(number * 1000).toString()],
          [(number * 1000 * Math.pow(10, powerOfTen)).toString()]
        ]}
        sentences={[
          translate.answerSentences.howManyGramsAreThereInNumKg(number.toLocaleString()),
          translate.answerSentences.howManyGramsAreThereInNumKg(
            (number * Math.pow(10, powerOfTen)).toLocaleString()
          )
        ]}
        {...props}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={strings}
            total={number}
            dimens={dimens}
            sameRowColor
          />
        )}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PowersOf10',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
