import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { useMemo } from 'react';
import { range, sortNumberArray } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import {
  Centilitres,
  Centimetres,
  CubicCentimetres,
  Grams,
  Kilograms,
  Kilometres,
  CubicKilometres,
  Litres,
  Metres,
  CubicMetres,
  Milligrams,
  Millilitres,
  Millimetres,
  CubicMillimetres,
  Tonnes
} from '../../../../utils/unitConversion';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import {
  Item,
  capacityItems,
  itemGetImage,
  itemSchema,
  itemWithTheArticle,
  lengthItems,
  massItems,
  volumeItems
} from '../../../../utils/items';
import { View } from 'react-native';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { measurementsWithTheArticle } from '../../../../utils/measure';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'an0',
  description: 'an0',
  keywords: ['Metric', 'Length', 'Mass', 'Volume', 'Capacity'],
  schema: z
    .object({
      selectedMeasure1: z.enum(['Capacity', 'Length', 'Mass', 'Volume']),
      selectedMeasure2: z.enum(['Capacity', 'Length', 'Mass', 'Volume'])
    })
    .refine(
      val => val.selectedMeasure1 !== val.selectedMeasure2,
      'selectedMeasure1 and selectedMeasure2 cannot be the same.'
    ),
  simpleGenerator: () => {
    const [selectedMeasure1, selectedMeasure2] = getRandomSubArrayFromArray(
      ['Capacity', 'Length', 'Mass', 'Volume'] as const,
      2
    );

    return { selectedMeasure1, selectedMeasure2 };
  },
  Component: props => {
    const {
      question: { selectedMeasure1, selectedMeasure2 },
      translate
    } = props;

    const items = useMemo(() => {
      const itemsArray = [];

      if (selectedMeasure1 === 'Capacity' || selectedMeasure2 === 'Capacity') {
        itemsArray.push(
          { measure: 'Capacity', value: 'ml' },
          { measure: 'Capacity', value: 'cl' },
          { measure: 'Capacity', value: 'l' }
        );
      }
      if (selectedMeasure1 === 'Length' || selectedMeasure2 === 'Length') {
        itemsArray.push(
          { measure: 'Length', value: 'mm' },
          { measure: 'Length', value: 'cm' },
          { measure: 'Length', value: 'm' },
          { measure: 'Length', value: 'km' }
        );
      }
      if (selectedMeasure1 === 'Mass' || selectedMeasure2 === 'Mass') {
        itemsArray.push(
          { measure: 'Mass', value: 'mg' },
          { measure: 'Mass', value: 'g' },
          { measure: 'Mass', value: 'kg' },
          { measure: 'Mass', value: translate.units.tonnes(1) }
        );
      }
      if (selectedMeasure1 === 'Volume' || selectedMeasure2 === 'Volume') {
        itemsArray.push(
          { measure: 'Volume', value: 'mm³' },
          { measure: 'Volume', value: 'cm³' },
          { measure: 'Volume', value: 'm³' },
          { measure: 'Volume', value: 'km³' }
        );
      }

      return shuffle(itemsArray, { random: seededRandom(props.question) });
    }, [props.question, selectedMeasure1, selectedMeasure2, translate.units]);

    const measure1Items = items.filter(x => x.measure === selectedMeasure1).map(item => item.value);
    const measure2Items = items.filter(x => x.measure === selectedMeasure2).map(item => item.value);

    const correctOrder = [measure1Items, measure2Items];

    return (
      <QF8DragIntoUpTo3Groups<string>
        title={translate.instructions.dragCardsSortMetricUnitsIntoCorrectCategories()}
        pdfTitle={translate.instructions.sortMetricUnitsIntoCorrectCategoriesPDF()}
        testCorrect={correctOrder}
        zoneNames={[translate.keywords[selectedMeasure1](), translate.keywords[selectedMeasure2]()]}
        items={items.map(item => item.value)}
        itemsLetterEmWidth={1}
        pdfItemVariant="pdfSquare"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'an1',
  description: 'an1',
  keywords: ['Metric', 'Length', 'Mass', 'Volume', 'Capacity'],
  schema: z
    .object({
      numbers: z.array(z.number().int().min(1).max(9)).length(8),
      selectedMeasure: z.enum(['Capacity', 'Length', 'Mass', 'Volume']),
      capacityUnit1: z.enum(['ml', 'cl', 'l']),
      capacityUnit2: z.enum(['ml', 'cl', 'l']),
      lengthUnit1: z.enum(['mm', 'cm', 'm', 'km']),
      lengthUnit2: z.enum(['mm', 'cm', 'm', 'km']),
      massUnit1: z.enum(['mg', 'g', 'kg', 'tonnes']),
      massUnit2: z.enum(['mg', 'g', 'kg', 'tonnes']),
      volumeUnit1: z.enum(['mm³', 'cm³', 'm³', 'km³']),
      volumeUnit2: z.enum(['mm³', 'cm³', 'm³', 'km³'])
    })
    .refine(
      val => val.capacityUnit1 !== val.capacityUnit2,
      'capacityUnit1 and capacityUnit2 cannot be the same.'
    )
    .refine(
      val => val.lengthUnit1 !== val.lengthUnit2,
      'lengthUnit1 and lengthUnit2 cannot be the same.'
    )
    .refine(val => val.massUnit1 !== val.massUnit2, 'massUnit1 and massUnit2 cannot be the same.')
    .refine(
      val => val.volumeUnit1 !== val.volumeUnit2,
      'volumeUnit1 and volumeUnit2 cannot be the same.'
    ),
  simpleGenerator: () => {
    const numbers = range(1, 8).map(() => randomIntegerInclusive(1, 9));

    const selectedMeasure = getRandomFromArray(['Capacity', 'Length', 'Mass', 'Volume'] as const);

    const [capacityUnit1, capacityUnit2] = getRandomSubArrayFromArray(
      ['ml', 'cl', 'l'] as const,
      2
    );

    const [lengthUnit1, lengthUnit2] = getRandomSubArrayFromArray(
      ['mm', 'cm', 'm', 'km'] as const,
      2
    );

    const [massUnit1, massUnit2] = getRandomSubArrayFromArray(
      ['mg', 'g', 'kg', 'tonnes'] as const,
      2
    );

    const [volumeUnit1, volumeUnit2] = getRandomSubArrayFromArray(
      ['mm³', 'cm³', 'm³', 'km³'] as const,
      2
    );

    return {
      numbers,
      selectedMeasure,
      capacityUnit1,
      capacityUnit2,
      lengthUnit1,
      lengthUnit2,
      massUnit1,
      massUnit2,
      volumeUnit1,
      volumeUnit2
    };
  },
  Component: props => {
    const {
      question: {
        numbers,
        selectedMeasure,
        capacityUnit1,
        capacityUnit2,
        lengthUnit1,
        lengthUnit2,
        massUnit1,
        massUnit2,
        volumeUnit1,
        volumeUnit2
      },
      translate
    } = props;

    const statements = useMemo(() => {
      const capacity1 = {
        measure: 'Capacity',
        value: `${numbers[0].toLocaleString()} ${capacityUnit1}`
      };

      const capacity2 = {
        measure: 'Capacity',
        value: `${numbers[1].toLocaleString()} ${capacityUnit2}`
      };

      const length1 = {
        measure: 'Length',
        value: `${numbers[2].toLocaleString()} ${lengthUnit1}`
      };

      const length2 = {
        measure: 'Length',
        value: `${numbers[3].toLocaleString()} ${lengthUnit2}`
      };

      const mass1 = {
        measure: 'Mass',
        value: `${numbers[4].toLocaleString()} ${
          massUnit1 === 'tonnes' ? translate.units.tonnes(numbers[4]) : massUnit1
        }`
      };

      const mass2 = {
        measure: 'Mass',
        value: `${numbers[5].toLocaleString()} ${
          massUnit2 === 'tonnes' ? translate.units.tonnes(numbers[5]) : massUnit2
        }`
      };

      const volume1 = {
        measure: 'Volume',
        value: `${numbers[6].toLocaleString()} ${volumeUnit1}`
      };

      const volume2 = {
        measure: 'Volume',
        value: `${numbers[7].toLocaleString()} ${volumeUnit2}`
      };

      return shuffle([capacity1, capacity2, length1, length2, mass1, mass2, volume1, volume2], {
        random: seededRandom(props.question)
      });
    }, [
      capacityUnit1,
      capacityUnit2,
      lengthUnit1,
      lengthUnit2,
      massUnit1,
      massUnit2,
      numbers,
      props.question,
      translate.units,
      volumeUnit1,
      volumeUnit2
    ]);

    return (
      <QF10SelectNumbers
        title={translate.instructions.selectTheMeasureOfX(translate.keywords[selectedMeasure]())}
        pdfTitle={translate.instructions.circleTheMeasuresOfX(
          translate.keywords[selectedMeasure]()
        )}
        testCorrect={statements.filter(it => it.measure === selectedMeasure).map(it => it.value)}
        items={statements.map(it => it.value)}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'an2',
  description: 'an2',
  keywords: ['Metric units', 'Length', 'Mass', 'Volume', 'Capacity'],
  schema: z.object({
    capacityUnit: z.enum(['ml', 'cl', 'l']),
    lengthUnit: z.enum(['mm', 'cm', 'm', 'km']),
    massUnit: z.enum(['mg', 'g', 'kg', 'tonnes']),
    volumeUnit: z.enum(['mm³', 'cm³', 'm³', 'km³'])
  }),
  simpleGenerator: () => {
    const capacityUnit = getRandomFromArray(['ml', 'cl', 'l'] as const);

    const lengthUnit = getRandomFromArray(['mm', 'cm', 'm', 'km'] as const);

    const massUnit = getRandomFromArray(['mg', 'g', 'kg', 'tonnes'] as const);

    const volumeUnit = getRandomFromArray(['mm³', 'cm³', 'm³', 'km³'] as const);

    return { capacityUnit, lengthUnit, massUnit, volumeUnit };
  },
  Component: props => {
    const {
      question: { capacityUnit, lengthUnit, massUnit, volumeUnit },
      translate
    } = props;

    const items = [
      { component: capacityUnit, value: 'capacity' },
      { component: lengthUnit, value: 'length' },
      { component: massUnit === 'tonnes' ? translate.units.tonnes(0) : massUnit, value: 'mass' },
      { component: volumeUnit, value: 'volume' }
    ];

    const statements = useMemo(() => {
      const questionsAndAnswers = [
        { statement: translate.keywords.Capacity(), value: 'capacity' },
        { statement: translate.keywords.Length(), value: 'length' },
        { statement: translate.keywords.Mass(), value: 'mass' },
        { statement: translate.keywords.Volume(), value: 'volume' }
      ];

      return shuffle(questionsAndAnswers, { random: seededRandom(props.question) });
    }, [props.question, translate.keywords]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchMeasureToMetrics()}
        pdfTitle={translate.instructions.matchMeasuresToMetricUnits()}
        items={items}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'an3',
  description: 'an3',
  keywords: ['Metric units', 'Length', 'Mass', 'Volume', 'Capacity'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(9)).length(4),
    selectedMeasure: z.enum(['Capacity', 'Length', 'Mass', 'Volume'])
  }),
  simpleGenerator: () => {
    const numbers = range(1, 4).map(() => randomIntegerInclusive(1, 9));

    const selectedMeasure = getRandomFromArray(['Capacity', 'Length', 'Mass', 'Volume'] as const);

    return { numbers, selectedMeasure };
  },
  Component: props => {
    const {
      question: { numbers, selectedMeasure },
      translate
    } = props;

    // Randomly order these equations
    const items = (() => {
      switch (selectedMeasure) {
        case 'Capacity':
          return [new Millilitres(numbers[1]), new Centilitres(numbers[2]), new Litres(numbers[3])];
        case 'Length':
          return [
            new Millimetres(numbers[0]),
            new Centimetres(numbers[1]),
            new Metres(numbers[2]),
            new Kilometres(numbers[3])
          ];
        case 'Mass':
          return [
            new Milligrams(numbers[0]),
            new Grams(numbers[1]),
            new Kilograms(numbers[2]),
            new Tonnes(numbers[3])
          ];
        case 'Volume':
          return [
            new CubicMillimetres(numbers[0]),
            new CubicCentimetres(numbers[1]),
            new CubicMetres(numbers[2]),
            new CubicKilometres(numbers[3])
          ];
      }
    })();

    const shuffledItems = shuffle(items, { random: seededRandom(props.question) });

    return (
      <QF4DragOrderVertical
        title={translate.instructions.orderTheseMeasurementsFromSmallestToGreatest()}
        pdfTitle={translate.instructions.useCardsToOrderTheseMeasurementsFromSmallestToGreatest()}
        testCorrect={sortNumberArray(
          shuffledItems.map(measurement => measurement.toBaseMetric().value),
          'ascending'
        )}
        items={shuffledItems.map(measurement => ({
          value: measurement.toBaseMetric().value,
          component: `${measurement.value} ${measurement.suffix}`
        }))}
        topLabel={translate.keywords.Smallest()}
        bottomLabel={translate.keywords.Greatest()}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question5 = newQuestionContent({
  uid: 'an4',
  description: 'an4',
  keywords: ['Metric', 'Length', 'Mass', 'Volume', 'Capacity'],
  schema: z.object({
    digits: z.array(z.number().int().min(0).max(9)).length(6),
    greatestOrSmallest: z.enum(['greatest', 'smallest']),
    selectedMeasure: z.enum(['Capacity', 'Length', 'Mass', 'Volume']),
    omittedUnit: z.number().int().min(0).max(2),
    omittedUnits: z.array(z.number().int().min(0).max(3)).length(2)
  }),
  simpleGenerator: () => {
    const digits = range(1, 6).map(() => randomIntegerInclusive(1, 9));

    const greatestOrSmallest = getRandomFromArray(['greatest', 'smallest'] as const);

    const selectedMeasure = getRandomFromArray(['Capacity', 'Length', 'Mass', 'Volume'] as const);

    const omittedUnit = randomIntegerInclusive(0, 2);
    const omittedUnits = randomUniqueIntegersInclusive(0, 3, 2);

    return { digits, greatestOrSmallest, selectedMeasure, omittedUnit, omittedUnits };
  },
  Component: props => {
    const {
      question: { digits, greatestOrSmallest, selectedMeasure, omittedUnit, omittedUnits },
      translate
    } = props;

    const orderedDigits =
      greatestOrSmallest === 'greatest'
        ? sortNumberArray(digits, 'descending')
        : sortNumberArray(digits, 'ascending');
    // Add the numbers to the correct answer array
    const correctAnswer = orderedDigits.slice(0, 3).map(String);
    const answerNumber = Number(correctAnswer.join(''));
    const selectedMeasureUnits = (() => {
      switch (selectedMeasure) {
        case 'Capacity': {
          const caps = [
            new Millilitres(answerNumber),
            new Centilitres(answerNumber),
            new Litres(answerNumber)
          ];
          caps.splice(omittedUnit, 1);
          return caps;
        }
        case 'Length': {
          const lens = [
            new Millimetres(answerNumber),
            new Centimetres(answerNumber),
            new Metres(answerNumber),
            new Kilometres(answerNumber)
          ];
          const selectedLens = lens.filter((_, index) => !omittedUnits.includes(index));
          return selectedLens;
        }
        case 'Mass': {
          const mass = [
            new Milligrams(answerNumber),
            new Grams(answerNumber),
            new Kilograms(answerNumber)
          ];
          mass.splice(omittedUnit, 1);
          return mass;
        }
        case 'Volume': {
          const vol = [
            new CubicMillimetres(answerNumber),
            new CubicCentimetres(answerNumber),
            new CubicMetres(answerNumber),
            new CubicKilometres(answerNumber)
          ];
          const selectedVol = vol.filter((_, index) => !omittedUnits.includes(index));
          return selectedVol;
        }
      }
    })();

    // Add the correct unit to the answer array
    correctAnswer.push(
      greatestOrSmallest === 'greatest'
        ? selectedMeasureUnits[1].suffix
        : selectedMeasureUnits[0].suffix
    );

    // Shuffle
    const shuffledUnits = shuffle(selectedMeasureUnits, { random: seededRandom(props.question) });

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsToCreateXPossibleMeasureUsingTheseDigits(
          greatestOrSmallest === 'greatest'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest(),
          selectedMeasure
        )}
        pdfTitle={translate.instructions.createXPossibleMeasureUsingTheseDigitsEachDigitOnlyUsedOnce(
          greatestOrSmallest === 'greatest'
            ? translate.keywords.Greatest()
            : translate.keywords.Smallest(),
          selectedMeasure
        )}
        sentence={'<ans/> '.repeat(correctAnswer.length)}
        actionPanelVariant="bottom"
        items={[
          ...digits.map(digit => digit.toLocaleString()),
          ...shuffledUnits.map(measure => measure.suffix)
        ]}
        testCorrect={correctAnswer}
        itemsLetterEmWidth={0.8}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'an5',
  description: 'an5',
  keywords: ['Estimate', 'Metric', 'Length', 'Mass', 'Volume', 'Capacity'],
  schema: z.object({
    measure: z.enum(['Mass', 'Volume', 'Capacity', 'Length']),
    item: itemSchema
  }),
  simpleGenerator: () => {
    // Pick which measure to use
    const measure = getRandomFromArray(['Mass', 'Volume', 'Capacity', 'Length'] as const);

    // Had to assign this to stop typescript complaining
    let item: Item;
    let itemName;

    // Choosing item depends on measurement chosen
    switch (measure) {
      case 'Capacity':
        itemName = getRandomFromArray(['mug', 'kettle', 'juice_bottle', 'fish_tank'] as const);
        item = capacityItems[itemName].generate();
        break;

      case 'Length':
        itemName = getRandomFromArray(['ant', 'giraffe'] as const);
        item = lengthItems[itemName].generate();
        break;

      case 'Mass':
        itemName = getRandomFromArray([
          'apple',
          'cookie',
          'elephant',
          'bee',
          'bike',
          'plane'
        ] as const);
        item = massItems[itemName].generate();
        break;

      case 'Volume':
        itemName = getRandomFromArray(['balloon', 'dice', 'pound_coin'] as const);
        item = volumeItems[itemName].generate();
        break;
    }

    return { measure, item };
  },
  Component: props => {
    const {
      question: { measure, item },
      translate
    } = props;

    const [option1, option2, option3] = item.wrongAnswers;
    const correctAnswer = `${item.value.toLocaleString()} ${item.units}`;

    // Statements to select
    const statements = [
      {
        sentence: correctAnswer,
        value: correctAnswer
      },
      {
        sentence: option1,
        value: option1
      },
      {
        sentence: option2,
        value: option2
      },
      {
        sentence: option3,
        value: option3
      }
    ];
    const shuffledStatements = shuffle(statements, { random: seededRandom(props.question) });

    const measurement = measurementsWithTheArticle(measure, translate);
    const itemName = itemWithTheArticle(item.name, translate);

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectBestEstimateForXofTheY(measurement, itemName)}
        testCorrect={[correctAnswer]}
        numItems={4}
        Content={({ dimens }) => (
          <View
            style={{
              height: dimens.height * 0.75,
              width: dimens.width,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {itemGetImage(item as Item)}
          </View>
        )}
        renderItems={shuffledStatements.map(({ sentence, value }) => ({
          component: <Text variant="WRN700">{sentence}</Text>,
          value
        }))}
        itemLayout="column"
        itemsOuterContainerStyle={{ rowGap: 16 }}
        mainPanelFlexDirection="row"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MetricMeasures',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
