// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import MetricMeasures from './1MetricMeasures';
import ConvertMetricMeasures from './2ConvertMetricMeasures';
import CalculateWithMetricMeasures from './3CalculateWithMetricMeasures';
import MilesAndKilometres from './4MilesAndKilometres';
import ImperialMeasures from './5ImperialMeasures';

const Block = newBlockContent({
  block: 'ConvertingUnits',
  field: 'Measurement',
  weeks: [12, 12],
  smallSteps: [
    MetricMeasures, // Step 1
    ConvertMetricMeasures, // Step 2
    CalculateWithMetricMeasures, // Step 3
    MilesAndKilometres, // Step 4
    ImperialMeasures // Step 5
  ]
});
export default Block;
