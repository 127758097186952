import { useMemo } from 'react';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import {
  getBinOpEquation,
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import {
  PartWholeModel,
  TextPartition
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import { ScientificNotation } from '../../../../utils/math';
import { numberToBase10Object } from '../../../../utils/math';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { ADD, SUB } from '../../../../constants';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { View } from 'react-native';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';

const constants = { SUB, ADD };

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'afY',
  description: 'afY',
  keywords: ['Addition', 'Subtraction', '100s', 'Base 10'],
  schema: z.object({
    name: nameSchema,
    number1: z.number().int().min(101).max(999),
    number2: z.number().int().min(100).max(900),
    addOrSubtract: z.enum([ADD, SUB])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const name = getRandomName();

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const number2 = randomIntegerInclusiveStep(100, addOrSubtract === ADD ? 400 : 900, 100);

    const number1 = randomIntegerInclusive(
      addOrSubtract === ADD ? 101 : number2 + 1,
      addOrSubtract === ADD ? 599 : 999
    );

    return { name, number1, number2, addOrSubtract };
  },

  Component: props => {
    const {
      question: { name, number1, number2, addOrSubtract },
      translate
    } = props;

    const number3 = addOrSubtract === ADD ? number1 + number2 : number1 - number2;

    return (
      <QF1ContentAndSentences
        sentences={[
          addOrSubtract === ADD
            ? translate.answerSentences.characterAddsXToNumber(name, number2)
            : translate.answerSentences.characterSubtractsXFromNumber(name, number2),
          `<ans /> ${addOrSubtract} <ans /> = <ans />`
        ]}
        title={translate.instructions.characterHasMadeTheNumberX(name, number1)}
        inputMaxCharacters={3}
        testCorrect={userAnswer => {
          if (addOrSubtract === ADD) {
            return (
              ((userAnswer[1][0] === number1.toString() &&
                userAnswer[1][1] === number2.toString()) ||
                (userAnswer[1][0] === number2.toString() &&
                  userAnswer[1][1] === number1.toString())) &&
              userAnswer[1][2] === number3.toString()
            );
          } else {
            return (
              userAnswer[1][0] === number1.toString() &&
              userAnswer[1][1] === number2.toString() &&
              userAnswer[1][2] === number3.toString()
            );
          }
        }}
        Content={({ dimens }) => {
          return (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Cubes',
                numbers: numberToBase10Object(number1),
                arrangement: 'ltr'
              }}
              usableWidth={dimens.width}
              usableHeight={dimens.height}
              containerStyle={{ alignItems: 'center' }}
            />
          );
        }}
        pdfDirection="column"
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [],
            [number1.toLocaleString(), number2.toLocaleString(), number3.toLocaleString()]
          ],
          answerText:
            addOrSubtract === ADD ? translate.markScheme.acceptAddendsInEitherOrder() : undefined
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'afZ',
  description: 'afZ',
  keywords: ['Addition', 'Subtraction', '100s', 'Counters'],
  schema: z
    .object({
      var1: z.number().int().min(100).max(900),
      var2: z.number().int().min(100).max(500).step(100),
      operation: z.enum(['ADD', 'SUB'])
    })
    .refine(
      val =>
        (val.operation === 'ADD' && val.var1 % 1000 < 500) ||
        (val.operation === 'SUB' && val.var1 % 1000 > 500)
    ),
  simpleGenerator: () => {
    const operation = getRandomFromArray(['ADD', 'SUB'] as const);

    const var1 = randomIntegerInclusive(100, 900, {
      constraint: x => (operation === 'ADD' ? x % 1000 < 500 : x % 1000 > 500)
    });
    const var2 = randomIntegerInclusiveStep(100, 500, 100);

    return {
      operation,
      var1,
      var2
    };
  },
  Component: ({ question: { var1, var2, operation }, translate }) => {
    const ans = operation === 'ADD' ? var1 + var2 : var1 - var2;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.usePlaceValueChartToCompleteNumberSentence()}
        Content={({ dimens }) => (
          <PlaceValueChart
            number={ScientificNotation.fromNumber(var1)}
            columnsToShow={[2, 1, 0]}
            counterVariant={'decimalCounter'}
            dimens={dimens}
          />
        )}
        sentence={`${var1} ${constants[operation]} ${var2} = <ans/>`}
        testCorrect={[ans.toString()]}
        pdfDirection="column"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'af0',
  description: 'af0',
  keywords: ['Addition', 'Subtraction', '100s'],
  schema: z
    .object({
      numberA1: z.number().int().min(101).max(899),
      numberA2: z.number().int().min(100).max(800).multipleOf(100),
      answerBoxA: z.enum(['left', 'right', 'result']),
      numberB1: z.number().int().min(101).max(899),
      numberB2: z.number().int().min(100).max(800).multipleOf(100),
      answerBoxB: z.enum(['left', 'right', 'result'])
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 + numberA2 must not exchange'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.numberB2),
      'numberB1 + numberB2 must not exchange'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const numberA2 = randomIntegerInclusiveStep(100, 800, 100);
        const numberA1 = randomIntegerInclusive(101, 899, {
          constraint: x => numbersDoNotExchange(x, numberA2)
        });

        const numberB2 = randomIntegerInclusiveStep(100, 800, 100);
        const numberB1 = randomIntegerInclusive(101, 899, {
          constraint: x => numbersDoNotExchange(x, numberB2)
        });

        const answerBoxA = getRandomFromArray(['left', 'right', 'result'] as const);
        const answerBoxB = getRandomFromArray(['left', 'right', 'result'] as const);

        return { numberA1, numberA2, numberB1, numberB2, answerBoxA, answerBoxB };
      },
      val =>
        arrayHasNoDuplicates([val.numberA1, val.numberB1]) &&
        arrayHasNoDuplicates([val.numberA2, val.numberB2])
    ),

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, answerBoxA, answerBoxB },
      translate
    } = props;

    const eqA = getBinOpEquation({
      left: numberA1,
      right: numberA2,
      sign: 'add',
      answer: answerBoxA
    });

    const eqB = getBinOpEquation({
      left: numberB1,
      right: numberB2,
      sign: 'add',
      answer: answerBoxB
    });

    // Remove = as QF2AlignedEquations includes its own
    const eqAContents = binOpEquationToSentenceString(eqA).split('=');
    const eqBContents = binOpEquationToSentenceString(eqB).split('=');

    // switch statement to create correct array for markScheme left.
    const leftMarkScheme = (() => {
      switch (answerBoxA) {
        case 'left': {
          switch (answerBoxB) {
            case 'left':
              return [[numberA1.toString()], [numberB1.toString()]];
            case 'right':
              return [[numberA1.toString()], [numberB2.toString()]];
            case 'result':
              return [[numberA1.toString()], []];
          }
        }
        case 'right': {
          switch (answerBoxB) {
            case 'left':
              return [[numberA2.toString()], [numberB1.toString()]];
            case 'right':
              return [[numberA2.toString()], [numberB2.toString()]];
            case 'result':
              return [[numberA2.toString()], []];
          }
        }
        case 'result': {
          switch (answerBoxB) {
            case 'left':
              return [[], [numberB1.toString()]];
            case 'right':
              return [[], [numberB2.toString()]];
          }
        }
        default:
          return [[], []];
      }
    })();

    // switch statement to create correct array for markScheme right.
    const rightMarkScheme = (() => {
      switch (answerBoxB) {
        case 'left':
        case 'right': {
          switch (answerBoxA) {
            case 'result':
              return [[eqA[eqA.answer].toString()], []];
          }
        }
        case 'result': {
          switch (answerBoxA) {
            case 'left':
              return [[], [eqB[eqB.answer].toString()]];
            case 'right':
              return [[], [eqB[eqB.answer].toString()]];
            case 'result':
              return [[eqA[eqA.answer].toString()], [eqB[eqB.answer].toString()]];
          }
        }
        default:
          return [[], []];
      }
    })();

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeNumberSentences()}
        leftSide={[eqAContents[0], eqBContents[0]]}
        rightSide={[eqAContents[1], eqBContents[1]]}
        inputMaxCharacters={2}
        sentenceStyle={{ height: 150 }}
        testCorrect={userAnswer => {
          const answers = [...userAnswer.left.flat(), ...userAnswer.right.flat()];
          // If eqB is not equal to result and eqA ans is result
          // We need to check eqB ans first
          // Because furthest left ans gets put into array as first index
          if (eqB.answer !== 'result' && eqA.answer === 'result') {
            if (
              eqB[eqB.answer].toString() === answers[0].toString() &&
              eqA[eqA.answer].toString() === answers[1].toString()
            ) {
              return true;
            }
          } else {
            // Else check eqA answers first
            if (
              eqA[eqA.answer].toString() === answers[0].toString() &&
              eqB[eqB.answer].toString() === answers[1].toString()
            ) {
              return true;
            }
          }

          // Incorrect
          return false;
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: {
            right: rightMarkScheme,
            left: leftMarkScheme
          }
        }}
      />
    );
  }
});

export const Question4 = newQuestionContent({
  uid: 'af1',
  description: 'af1',
  keywords: ['Addition', 'Subtraction', '100s', 'Part-whole'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(898),
      number2: z.number().int().min(100).max(800).multipleOf(100),
      part: z.enum(['top', 'partA', 'partB'])
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(100, 800, 100);
    const number1 = randomIntegerInclusive(101, 898, {
      constraint: x => numbersDoNotExchange(x, number2)
    });

    const part = getRandomFromArray(['top', 'partA', 'partB'] as const);

    return { number1, number2, part };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, part } = question;

    const number3 = number1 + number2;

    let partition: TextPartition = [];

    if (part === 'partA') {
      partition = ['$ans', number2];
    } else if (part === 'partB') {
      partition = [number1, '$ans'];
    } else {
      partition = [number1, number2];
    }

    const correctAnswer = (() => {
      switch (part) {
        case 'top':
          return number3;
        case 'partA':
          return number1;
        case 'partB':
          return number2;
      }
    })();

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        initialState={displayMode === 'markscheme' ? [correctAnswer.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={userAnswer => userAnswer[0] === correctAnswer.toString()}
        inputType="numpad"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={part === 'top' ? '$ans' : number3}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
        questionHeight={1000}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'af2',
  description: 'af2',
  keywords: ['Addition', 'Subtraction', '100s', 'Inverse'],
  schema: z
    .object({
      numberA1: z.number().int().min(100).max(899),
      numberA2: z.number().int().min(100).max(800).multipleOf(100),
      numberB1: z.number().int().min(100).max(899),
      numberB2: z.number().int().min(100).max(800).multipleOf(100),
      numberC1: z.number().int().min(100).max(899),
      numberC2: z.number().int().min(100).max(800).multipleOf(100)
    })
    .refine(
      val => numbersDoNotExchange(val.numberA1, val.numberA2),
      'numberA1 + numberA2 must not exchange'
    )
    .refine(
      val => numbersDoNotExchange(val.numberB1, val.numberB2),
      'numberB1 + numberB2 must not exchange'
    )
    .refine(
      val => numbersDoNotExchange(val.numberC1, val.numberC2),
      'numberC1 + numberC2 must not exchange'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const numberA2 = randomIntegerInclusiveStep(100, 800, 100);
        const numberA1 = randomIntegerInclusive(100, 899, {
          constraint: x => numbersDoNotExchange(x, numberA2)
        });

        const numberB2 = randomIntegerInclusiveStep(100, 800, 100);
        const numberB1 = randomIntegerInclusive(100, 899, {
          constraint: x => numbersDoNotExchange(x, numberB2)
        });

        const numberC2 = randomIntegerInclusiveStep(100, 800, 100);
        const numberC1 = randomIntegerInclusive(100, 899, {
          constraint: x => numbersDoNotExchange(x, numberC2)
        });

        return { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2 };
      },
      val =>
        arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1]) &&
        arrayHasNoDuplicates([val.numberA2, val.numberB2, val.numberC2])
    ),
  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2, numberC1, numberC2 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: numberA2, sign: 'add', answer: 'right' });

    const eqB = getBinOpEquation({
      right: numberB2,
      result: numberB1,
      sign: 'subtract',
      answer: 'right'
    });

    const eqC = getBinOpEquation({ left: numberC1, right: numberC2, sign: 'add', answer: 'left' });

    const eqs = [eqA, eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        {...props}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'af3',
  description: 'af3',
  keywords: ['Addition', 'Subtraction', '100s'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(998),
      number2: z.number().int().min(100).max(800).multipleOf(100),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(100, 800, 100);
    const number1 = randomIntegerInclusive(101, 998, {
      constraint: x => numbersDoNotExchange(x, number2)
    });

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { number1, number2, addOrSubtract };
  },
  Component: props => {
    const {
      question: { number1, number2, addOrSubtract },
      translate
    } = props;

    const number3 = number1 + number2;

    const splitNumbers = (numbers: number[]) => {
      return Array.from([numbers].join(''), Number);
    };

    const splitNumber1 = splitNumbers([number1]);
    const splitNumber3 = splitNumbers([number3]);

    const answerOptions = useMemo(() => {
      const draggables =
        addOrSubtract === ADD ? [...splitNumber1, number2 / 100] : [...splitNumber3, number2 / 100];
      return shuffle(draggables, { random: seededRandom(props.question) });
    }, [number2, splitNumber1, splitNumber3, addOrSubtract, props.question]);

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardsCompleteNumberSentence()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentence()}
        items={answerOptions}
        sentence={
          addOrSubtract === ADD
            ? `<ans/> <ans/> <ans/> ${ADD} <ans/>00 = ${number3.toLocaleString()}`
            : `<ans/> <ans/> <ans/> ${SUB} <ans/>00 = ${number1.toLocaleString()}`
        }
        testCorrect={userAnswer => {
          const [ans1Hundreds, ans1Tens, ans1Ones, ans2] = userAnswer;

          const lhs = Number([ans1Hundreds, ans1Tens, ans1Ones].join(''));
          const rhs = Number([ans2, 0, 0].join(''));

          return addOrSubtract === ADD ? lhs + rhs === number3 : lhs - rhs === number1;
        }}
        moveOrCopy="move"
        customMarkSchemeAnswer={{
          answerText:
            addOrSubtract === ADD
              ? translate.markScheme.anyValidAdditionUsingAvailCards()
              : translate.markScheme.anyValidSubtractionUsingAvailCards()
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'af32',
  description: 'af3',
  keywords: ['Addition', 'Subtraction', '100s'],
  schema: z
    .object({
      number1: z.number().int().min(101).max(998),
      number2: z.number().int().min(100).max(800).multipleOf(100),
      addOrSubtract: z.enum([ADD, SUB]),
      answerOptions: z.array(z.number().int().min(0).max(9))
    })
    .refine(
      val => numbersDoNotExchange(val.number1, val.number2),
      'number1 + number2 must not exchange'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(100, 800, 100);
    const number1 = randomIntegerInclusive(101, 998, {
      constraint: x => numbersDoNotExchange(x, number2)
    });

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    const splitNumber1 = number1
      .toString()
      .split('')
      .map(str => parseInt(str));
    const splitNumber3 = (number1 + number2)
      .toString()
      .split('')
      .map(str => parseInt(str));

    const answerOptions =
      addOrSubtract === ADD
        ? shuffle([...splitNumber1, number2 / 100])
        : shuffle([...splitNumber3, number2 / 100]);

    return { number1, number2, addOrSubtract, answerOptions };
  },
  Component: props => {
    const {
      question: { number1, number2, addOrSubtract, answerOptions },
      translate,
      displayMode
    } = props;

    const number3 = number1 + number2;

    const splitNumber1 = number1.toString().split('');
    const splitNumber3 = number3.toString().split('');

    const stringsToUse =
      addOrSubtract === ADD
        ? splitNumber3.map(num => num.toLocaleString())
        : splitNumber1.map(num => num.toLocaleString());

    // Used for answersToDisplay
    const numberTwoTens = numberToBase10Object(number2).hundreds as number;
    const combinedNumber = parseInt(`${addOrSubtract === ADD ? number1 : number3}${numberTwoTens}`);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsCompleteNumberSentence()}
        pdfTitle={translate.instructions.useCardsCompleteNumberSentence()}
        items={answerOptions.map(el => el.toString())}
        actionPanelVariant="end"
        mainPanelStyle={{ flexDirection: 'row-reverse', justifyContent: 'center' }}
        Content={() => {
          return (
            <View
              style={{
                flexDirection: 'row',
                marginTop: 16,
                left: displayMode === 'digital' ? 4 : -100
              }}
            >
              {['0', '0', '=', ...stringsToUse].map((string, index) => (
                <ContentBox
                  key={index}
                  containerStyle={{
                    width: displayMode === 'digital' ? 85 : 150,
                    height: displayMode === 'digital' ? 95 : 150,
                    justifyContent: 'center',
                    marginRight: displayMode === 'digital' ? 6 : 12
                  }}
                >
                  <Text variant="WRN400">{string}</Text>
                </ContentBox>
              ))}
            </View>
          );
        }}
        sentence={
          addOrSubtract === ADD
            ? `<ans/> <ans/> <ans/> ${ADD} <ans/>`
            : `<ans/> <ans/> <ans/> ${SUB} <ans/>`
        }
        testCorrect={userAnswer => {
          const [ans1Hundreds, ans1Tens, ans1Ones, ans2] = userAnswer;

          const lhs = Number([ans1Hundreds, ans1Tens, ans1Ones].join(''));
          const rhs = Number([ans2, 0, 0].join(''));

          return addOrSubtract === ADD ? lhs + rhs === number3 : lhs - rhs === number1;
        }}
        pdfLayout="itemsAboveContent"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            combinedNumber
              .toString()
              .split('')
              .map(str => str.toLocaleString())
          ],
          answerText:
            addOrSubtract === ADD
              ? translate.markScheme.anyValidAdditionUsingAvailCards()
              : translate.markScheme.anyValidSubtractionUsingAvailCards()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtract100s',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question6]
});
export default SmallStep;
