import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { compareFloats } from '../../../../utils/math';
import { ADD } from '../../../../constants';
import { all, create, number } from 'mathjs';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import {
  createHundredSquareShape,
  reflectShapeOnX,
  rotateShape270
} from '../../../../utils/shapes';
import { DisplayShapeOnGrid } from '../../../../components/question/representations/DisplayShapeOnGrid';
import { numberEnum } from '../../../../utils/zod';
import { useMemo } from 'react';
import { View } from 'react-native';
import { BarModelWithState } from '../../../../components/question/representations/BarModel';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { barModelColors } from '../../../../theme/colors';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aBs',
  description: 'aBs',
  keywords: ['100 square', 'Addition', 'Number bonds', 'Tenths'],
  schema: z.object({
    tenths: z.number().int().min(1).max(9),
    topOrLeft: z.enum(['top', 'left'])
  }),
  simpleGenerator: () => {
    const tenths = randomIntegerInclusive(1, 9);
    const topOrLeft = getRandomFromArray(['top', 'left'] as const);

    return { tenths, topOrLeft };
  },
  Component: props => {
    const {
      question: { tenths, topOrLeft },
      translate
    } = props;

    const squareToDisplay =
      topOrLeft === 'top'
        ? createHundredSquareShape(tenths * 10)
        : rotateShape270(createHundredSquareShape(tenths * 10));
    const decimalToDisplay = number(math.evaluate(`${tenths}/10`));
    const correctAnswer = number(math.evaluate(`1-${decimalToDisplay}`));

    return (
      <QF1ContentAndSentence
        sentence={`${decimalToDisplay.toLocaleString()} ${ADD} <ans /> = 1`}
        title={translate.instructions.theHundredSquareRepresentsOneWhole()}
        testCorrect={answer => compareFloats(answer[0], correctAnswer)}
        inputMaxCharacters={3}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={squareToDisplay} dimens={dimens} />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aBt',
  description: 'aBt',
  keywords: ['100 square', 'Addition', 'Number bonds', 'Hundredths'],
  schema: z.object({
    hundredths: z.number().int().min(1).max(99),
    topOrLeft: z.enum(['top', 'left'])
  }),
  simpleGenerator: () => {
    const hundredths = randomIntegerInclusive(1, 99, {
      constraint: x => x % 10 !== 0
    });
    const topOrLeft = getRandomFromArray(['top', 'left'] as const);

    return { hundredths, topOrLeft };
  },
  Component: props => {
    const {
      question: { hundredths, topOrLeft },
      translate
    } = props;

    const squareToDisplay =
      topOrLeft === 'top'
        ? createHundredSquareShape(hundredths)
        : reflectShapeOnX(rotateShape270(createHundredSquareShape(hundredths)));
    const decimalToDisplay = number(math.evaluate(`${hundredths}/100`));
    const correctAnswer = number(math.evaluate(`1-${decimalToDisplay}`));

    return (
      <QF1ContentAndSentence
        sentence={`${decimalToDisplay.toLocaleString()} ${ADD} <ans /> = 1`}
        title={translate.instructions.theHundredSquareRepresentsOneWhole()}
        testCorrect={answer => compareFloats(answer[0], correctAnswer)}
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <DisplayShapeOnGrid givenShape={squareToDisplay} dimens={dimens} />
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [correctAnswer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aBu',
  description: 'aBu',
  keywords: ['Bar model', 'Addition', 'Number bonds', 'Tenths', 'Hundredths'],
  schema: z.object({
    wholeRowTopOrBottom: z.enum(['top', 'bottom']),
    givenNumberLeftOrRight: z.enum(['left', 'right']),
    numberA: z.number().int().min(5).max(95)
  }),
  simpleGenerator: () => {
    const wholeRowTopOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    const givenNumberLeftOrRight = getRandomFromArray(['left', 'right'] as const);

    const numberA = randomIntegerInclusive(5, 95);

    return { wholeRowTopOrBottom, givenNumberLeftOrRight, numberA };
  },
  Component: ({
    question: { wholeRowTopOrBottom, givenNumberLeftOrRight, numberA },
    translate,
    displayMode
  }) => {
    const numberB = numberA / 100;

    const answerA = 100 - numberA;

    const answerB = answerA / 100;

    const splitRowA = givenNumberLeftOrRight === 'left' ? [numberA, answerA] : [answerA, numberA];

    const splitRowB = givenNumberLeftOrRight === 'left' ? [numberB, answerB] : [answerB, numberB];

    const numbersA = wholeRowTopOrBottom === 'top' ? [[100], splitRowA] : [splitRowA, [100]];

    const numbersB = wholeRowTopOrBottom === 'top' ? [[1], splitRowB] : [splitRowB, [1]];

    const answerIndices =
      displayMode === 'markscheme'
        ? undefined
        : wholeRowTopOrBottom === 'top'
        ? [[], [givenNumberLeftOrRight === 'left' ? 1 : 0]]
        : [[givenNumberLeftOrRight === 'left' ? 1 : 0], []];

    // Provides the correct index and subindex for the testCorrect to check for all possible answer box positions.
    const userAnswerRow = wholeRowTopOrBottom === 'top' ? 1 : 0;
    const userAnswerIndex = givenNumberLeftOrRight === 'left' ? 1 : 0;

    return (
      <QF3Content
        title={translate.instructions.completeBarModels()}
        inputType="numpad"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <View style={{ rowGap: 32 }}>
            <BarModelWithState
              id="bar-model-A"
              numbers={numbersA}
              answerIndices={answerIndices}
              total={100}
              oneFontSize
              maxFontSize={displayMode === 'digital' ? 32 : 50}
              dimens={dimens}
              testCorrect={userAnswer =>
                compareFloats(userAnswer[userAnswerRow][userAnswerIndex], answerA)
              }
            />
            <BarModelWithState
              id="bar-model-B"
              numbers={numbersB}
              answerIndices={answerIndices}
              total={1}
              dimens={dimens}
              oneFontSize
              maxFontSize={displayMode === 'digital' ? 32 : 50}
              testCorrect={userAnswer =>
                compareFloats(userAnswer[userAnswerRow][userAnswerIndex], answerB)
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aBv',
  description: 'aBv',
  keywords: ['Bar model', 'Addition', 'Number bonds', 'Tenths', 'Hundredths'],
  schema: z.object({
    wholeRowTopOrBottom: z.enum(['top', 'bottom']),
    answerBoxPosition: z.enum(['left', 'middle', 'right']),
    numberA1: z.number().int().min(5).max(49),
    numberA2: z.number().int().min(5).max(49)
  }),
  simpleGenerator: () => {
    const wholeRowTopOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    const answerBoxPosition = getRandomFromArray(['left', 'middle', 'right'] as const);

    const numberA1 = randomIntegerInclusive(5, 49);

    const numberA2 = randomIntegerInclusive(5, 49);

    return { wholeRowTopOrBottom, answerBoxPosition, numberA1, numberA2 };
  },
  Component: ({
    question: { wholeRowTopOrBottom, answerBoxPosition, numberA1, numberA2 },
    translate,
    displayMode
  }) => {
    const numberB1 = numberA1 / 100;
    const numberB2 = numberA2 / 100;

    const answerA = 100 - (numberA1 + numberA2);

    const answerB = answerA / 100;

    // Strings override the numbers to show the originally-intended numbers, not accounting for the extra 10 added for spacing.
    const paddingAnswerA = answerA < 15 ? 15 : 0;
    const paddingNumberA1 = numberA1 < 15 ? 15 : 0;
    const paddingNumberA2 = numberA2 < 15 ? 15 : 0;
    const paddingATotal = paddingAnswerA + paddingNumberA1 + paddingNumberA2;

    // Strings override the numbers to show the originally-intended numbers, not accounting for the extra 0.1 added for spacing.
    const paddingAnswerB = answerB < 0.15 ? 0.15 : 0;
    const paddingNumberB1 = numberB1 < 0.15 ? 0.15 : 0;
    const paddingNumberB2 = numberB2 < 0.15 ? 0.15 : 0;
    const paddingBTotal = paddingAnswerB + paddingNumberB1 + paddingNumberB2;

    // Provides the correct index and subindex for the testCorrect to check for all possible answer box positions.
    const userAnswerRow = wholeRowTopOrBottom === 'top' ? 1 : 0;
    let userAnswerIndex: number;
    let splitRowA, splitRowB, stringsRowA, stringsRowB;

    switch (answerBoxPosition) {
      case 'left':
        userAnswerIndex = 0;
        splitRowA = [
          answerA + paddingAnswerA,
          numberA1 + paddingNumberA1,
          numberA2 + paddingNumberA2
        ];
        splitRowB = [
          answerB + paddingAnswerB,
          numberB1 + paddingNumberB1,
          numberB2 + paddingNumberB2
        ];
        stringsRowA = [
          answerA.toLocaleString(),
          numberA1.toLocaleString(),
          numberA2.toLocaleString()
        ];
        stringsRowB = [
          answerB.toLocaleString(),
          numberB1.toLocaleString(),
          numberB2.toLocaleString()
        ];
      case 'middle':
        userAnswerIndex = 1;
        splitRowA = [
          numberA1 + paddingNumberA1,
          answerA + paddingAnswerA,
          numberA2 + paddingNumberA2
        ];
        splitRowB = [
          numberB1 + paddingNumberB1,
          answerB + paddingAnswerB,
          numberB2 + paddingNumberB2
        ];
        stringsRowA = [
          numberA1.toLocaleString(),
          answerA.toLocaleString(),
          numberA2.toLocaleString()
        ];
        stringsRowB = [
          numberB1.toLocaleString(),
          answerB.toLocaleString(),
          numberB2.toLocaleString()
        ];
      case 'right':
        userAnswerIndex = 2;
        splitRowA = [
          numberA1 + paddingNumberA1,
          numberA2 + paddingNumberA2,
          answerA + paddingAnswerA
        ];
        splitRowB = [
          numberB1 + paddingNumberB1,
          numberB2 + paddingNumberB2,
          answerB + paddingAnswerB
        ];
        stringsRowA = [
          numberA1.toLocaleString(),
          numberA2.toLocaleString(),
          answerA.toLocaleString()
        ];
        stringsRowB = [
          numberB1.toLocaleString(),
          numberB2.toLocaleString(),
          answerB.toLocaleString()
        ];
    }

    const numbersA =
      wholeRowTopOrBottom === 'top'
        ? [[100 + paddingATotal], splitRowA]
        : [splitRowA, [100 + paddingATotal]];

    const stringsA =
      wholeRowTopOrBottom === 'top'
        ? [[(100).toLocaleString()], stringsRowA]
        : [stringsRowA, [(100).toLocaleString()]];

    const numbersB =
      wholeRowTopOrBottom === 'top'
        ? [[1 + paddingBTotal], splitRowB]
        : [splitRowB, [1 + paddingBTotal]];

    const stringsB =
      wholeRowTopOrBottom === 'top'
        ? [[(1).toLocaleString()], stringsRowB]
        : [stringsRowB, [(1).toLocaleString()]];

    const answerIndices =
      displayMode === 'markscheme'
        ? undefined
        : wholeRowTopOrBottom === 'top'
        ? [[], [userAnswerIndex]]
        : [[userAnswerIndex], []];

    const [barModelColourA, barModelColourB, barModelColourC] = getRandomSubArrayFromArray(
      Object.values(barModelColors),
      3,
      { random: seededRandom({ wholeRowTopOrBottom, answerBoxPosition, numberA1, numberA2 }) }
    );

    const cellColours =
      wholeRowTopOrBottom === 'top'
        ? [[barModelColourA], [barModelColourB, barModelColourC]]
        : [[barModelColourA, barModelColourB], [barModelColourC]];

    return (
      <QF3Content
        title={translate.instructions.completeBarModels()}
        inputType="numpad"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <View style={{ rowGap: 32 }}>
            <BarModelWithState
              id="bar-model-A"
              numbers={numbersA}
              strings={stringsA}
              answerIndices={answerIndices}
              total={100 + paddingATotal}
              dimens={dimens}
              maxFontSize={displayMode === 'digital' ? 32 : 50}
              oneFontSize
              cellColors={cellColours}
              testCorrect={userAnswer =>
                compareFloats(userAnswer[userAnswerRow][userAnswerIndex], answerA)
              }
            />
            <BarModelWithState
              id="bar-model-B"
              numbers={numbersB}
              strings={stringsB}
              answerIndices={answerIndices}
              total={1 + paddingBTotal}
              oneFontSize
              cellColors={cellColours}
              maxFontSize={displayMode === 'digital' ? 32 : 50}
              dimens={dimens}
              testCorrect={userAnswer =>
                compareFloats(userAnswer[userAnswerRow][userAnswerIndex], answerB)
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aBw',
  description: 'aBw',
  keywords: ['Match', 'Addition', 'Number bonds', 'Tenths', 'Hundredths'],
  schema: z
    .object({
      number1: z.number().min(0.01).max(0.89).step(0.01),
      number5Difference: z.number().min(-0.009).max(0.009).step(0.001),
      number7Difference: numberEnum([0.1, 0.01])
    })
    .refine(val => val.number1 + val.number5Difference < 1, 'number 5 must be less than one')
    .refine(
      val => val.number1 + val.number5Difference + val.number7Difference < 1,
      'number 7 must be less than one'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 89) / 100;
    const number5Difference = randomIntegerInclusive(-9, 9, { constraint: x => x !== 0 }) / 1000;
    const number7Difference = getRandomFromArray([0.1, 0.01] as const);

    return { number1, number5Difference, number7Difference };
  },
  Component: props => {
    const {
      question: { number1, number5Difference, number7Difference },
      translate
    } = props;

    const number2 = number(math.evaluate(`1-${number1}`));
    const number3 = number(math.evaluate(`${number1}+0.1`));
    const number4 = number(math.evaluate(`1-${number3}`));
    const number5 = number(math.evaluate(`${number1}+${number5Difference}`));
    const number6 = number(math.evaluate(`1-${number5}`));
    const number7 = number(math.evaluate(`${number5} + ${number7Difference}`));
    const number8 = number(math.evaluate(`1-${number7}`));

    // Randomly order these statements
    const statements = useMemo(() => {
      const statement1 = {
        statement: `${number1.toLocaleString()}`,
        value: 'A'
      };
      const statement2 = {
        statement: `${number3.toLocaleString()}`,
        value: 'B'
      };
      const statement3 = {
        statement: `${number5.toLocaleString()}`,
        value: 'C'
      };
      const statement4 = {
        statement: `${number7.toLocaleString()}`,
        value: 'D'
      };
      return shuffle([statement1, statement2, statement3, statement4], {
        random: seededRandom(props.question)
      });
    }, [number1, number3, number5, number7, props.question]);

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchThePairsOfDecimlasThatAddTogetherToMakeOneWhole()}
        pdfTitle={translate.instructions.useCardsToMatchThePairsOfDecimlasThatAddTogetherToMakeOneWhole()}
        items={[
          {
            component: `${number2.toLocaleString()}`,
            value: 'A'
          },
          {
            component: `${number4.toLocaleString()}`,
            value: 'B'
          },
          {
            component: `${number6.toLocaleString()}`,
            value: 'C'
          },
          {
            component: `${number8.toLocaleString()}`,
            value: 'D'
          }
        ]}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center', rowGap: 8 }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aBx',
  description: 'aBx',
  keywords: ['Addition', 'Number bonds', 'Tenths', 'Hundredths'],
  schema: z.object({
    questionNumber: z.number().min(0.001).max(0.999),
    questionToShow: z.enum(['a', 'b', 'c', 'd'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const questionToShow = getRandomFromArray(['a', 'b', 'c', 'd'] as const);

    const questionNumber =
      questionToShow === 'a'
        ? randomIntegerInclusive(1, 9) / 10
        : questionToShow === 'b'
        ? randomIntegerInclusive(1, 99, {
            constraint: x => x % 10 !== 0
          }) / 100
        : randomIntegerInclusive(1, 999, {
            constraint: x => x % 10 !== 0
          }) / 1000;

    return { questionNumber, questionToShow };
  },
  Component: props => {
    const {
      question: { questionNumber, questionToShow },
      translate
    } = props;

    const { sentence, answer } =
      questionToShow === 'd'
        ? {
            sentence: `1 = ${questionNumber.toLocaleString()} ${ADD} <ans/>`,
            answer: number(math.evaluate(`1 - ${questionNumber} `))
          }
        : {
            sentence: `${questionNumber.toLocaleString()} ${ADD} <ans/> = 1`,
            answer: number(math.evaluate(`1 - ${questionNumber} `))
          };

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.workOutTheCalculation()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], answer)}
        sentence={sentence}
        inputMaxCharacters={5}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [answer.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ComplementsTo1',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
