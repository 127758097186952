// Autogenerated with: yarn tsx scripts/generateSolDataCodeAndFiles.ts files
import { newBlockContent } from '../../../Block';
import WriteMoneyUsingDecimals from './1WriteMoneyUsingDecimals';
import ConvertBetweenPoundsAndPence from './2ConvertBetweenPoundsAndPence';
import CompareAmountsOfMoney from './3CompareAmountsOfMoney';
import EstimateWithMoney from './4EstimateWithMoney';
import CalculateWithMoney from './5CalculateWithMoney';
import SolveProblemsWithMoney from './6SolveProblemsWithMoney';

const Block = newBlockContent({
  block: 'Money',
  field: 'Measurement',
  weeks: [3, 4],
  smallSteps: [
    WriteMoneyUsingDecimals, // Step 1
    ConvertBetweenPoundsAndPence, // Step 2
    CompareAmountsOfMoney, // Step 3
    EstimateWithMoney, // Step 4
    CalculateWithMoney, // Step 5
    SolveProblemsWithMoney // Step 6
  ]
});
export default Block;
