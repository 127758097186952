import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { BirdsEyeView } from '../../../../components/question/representations/BirdsEyeView';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { getRandomName, nameSchema } from '../../../../utils/names';
import { Intersection } from '../../../../components/question/representations/Intersection';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Text from '../../../../components/typography/Text';
import { getCharacterHeadSvgName } from '../../../../utils/characters';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';
import { getPlacesStringName } from '../../../../utils/places';

////
// Questions
////

const degreeMapping = {
  quarterTurn: 90,
  halfTurn: 180,
  threeQuarterTurn: 270,
  fullTurn: 360
};

const Question1 = newQuestionContent({
  uid: 'azO',
  description: 'azO',
  keywords: ['Turns', 'Direction'],
  questionHeight: 1000,
  schema: z.object({
    name: z.enum(['Alex', 'Annie', 'Jack', 'Mo']),
    places: z.enum(['Trees', 'Park', 'School', 'Pool']).array().length(4),
    startingPosition: numberEnum([0, 90, 180, 270]),
    typeOfTurn: z.enum([
      'full',
      'half',
      'quarter turn clockwise',
      'quarter turn anti-clockwise',
      'three-quarter turn clockwise',
      'three-quarter turn anti-clockwise'
    ])
  }),
  simpleGenerator: () => {
    const places = shuffle(['Trees', 'Park', 'School', 'Pool'] as const);
    const name = getRandomFromArray(['Alex', 'Annie', 'Jack', 'Mo'] as const);
    const startingPosition = getRandomFromArray([0, 90, 180, 270] as const);
    const typeOfTurn = getRandomFromArray([
      'full',
      'half',
      'quarter turn clockwise',
      'quarter turn anti-clockwise',
      'three-quarter turn clockwise',
      'three-quarter turn anti-clockwise'
    ] as const);

    return { places, name, startingPosition, typeOfTurn };
  },

  Component: props => {
    const {
      question: { places, name, startingPosition, typeOfTurn },
      translate
    } = props;

    const selectables = places.map(place => getPlacesStringName(translate, place));
    const index = [0, 90, 180, 270].indexOf(startingPosition);

    const [title, pdfTitle, finalIndex] = (() => {
      switch (typeOfTurn) {
        case 'full':
          return [
            translate.instructions.characterFacingXAndMakesFullTurnWhatAreTheyFacingNow(
              name,
              selectables[index]
            ),
            translate.instructions.characterFacingXAndMakesFullTurnWhatAreTheyFacingNowPDF(
              name,
              selectables[index]
            ),
            index
          ];
        case 'half':
          return [
            translate.instructions.characterFacingXAndMakesHalfTurnWhatAreTheyFacingNow(
              name,
              selectables[index]
            ),
            translate.instructions.characterFacingXAndMakesHalfTurnWhatAreTheyFacingNowPDF(
              name,
              selectables[index]
            ),
            (index + 2) % 4
          ];
        case 'quarter turn anti-clockwise':
          return [
            translate.instructions.characterFacingXAndMakesQuaterTurnAntiClockwiseWhatAreTheyFacingNow(
              name,
              selectables[index]
            ),
            translate.instructions.characterFacingXAndMakesQuaterTurnAntiClockwiseWhatAreTheyFacingNowPDF(
              name,
              selectables[index]
            ),
            (index + 3) % 4
          ];
        case 'quarter turn clockwise':
          return [
            translate.instructions.characterFacingXAndMakesQuaterTurnClockwiseWhatAreTheyFacingNow(
              name,
              selectables[index]
            ),
            translate.instructions.characterFacingXAndMakesQuaterTurnClockwiseWhatAreTheyFacingNowPDF(
              name,
              selectables[index]
            ),
            (index + 1) % 4
          ];
        case 'three-quarter turn clockwise':
          return [
            translate.instructions.characterFacingXAndMakesThreeQuaterTurnClockwiseWhatAreTheyFacingNow(
              name,
              selectables[index]
            ),
            translate.instructions.characterFacingXAndMakesThreeQuaterTurnClockwiseWhatAreTheyFacingNowPDF(
              name,
              selectables[index]
            ),
            (index + 3) % 4
          ];
        case 'three-quarter turn anti-clockwise':
          return [
            translate.instructions.characterFacingXAndMakesThreeQuaterTurnAntiClockwiseWhatAreTheyFacingNow(
              name,
              selectables[index]
            ),
            translate.instructions.characterFacingXAndMakesThreeQuaterTurnAntiClockwiseWhatAreTheyFacingNowPDF(
              name,
              selectables[index]
            ),
            (index + 1) % 4
          ];
      }
    })();

    return (
      <QF39ContentWithSelectablesOnRight
        title={title}
        pdfTitle={pdfTitle}
        leftContent={
          <MeasureView>
            {dimens => (
              <BirdsEyeView
                character={name}
                places={places}
                dimens={dimens}
                startingPosition={startingPosition}
              />
            )}
          </MeasureView>
        }
        correctAnswer={[selectables[finalIndex]]}
        questionHeight={1000}
        selectables={Object.fromEntries(selectables.map(selectable => [selectable, selectable]))}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'azP',
  description: 'azP',
  keywords: ['Turns', 'Angles'],
  schema: z.object({
    turn: z.enum(['quarterTurn', 'halfTurn', 'threeQuarterTurn', 'fullTurn']),
    svgName: z.string()
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const turn = getRandomFromArray([
      'quarterTurn',
      'halfTurn',
      'threeQuarterTurn',
      'fullTurn'
    ] as const);

    let svgName: SvgName;
    switch (turn) {
      case 'quarterTurn':
        svgName = getRandomFromArray([
          'Other_shapes/Anti_clockwise_shaded_diagram_quarter_turn',
          'Other_shapes/Clockwise_shaded_diagram_quarter_turn'
        ] as const);
        break;
      case 'halfTurn':
        svgName = getRandomFromArray([
          'Other_shapes/Anti_clockwise_shaded_diagram_half_turn',
          'Other_shapes/Clockwise_shaded_diagram_half_turn'
        ] as const);
        break;
      case 'threeQuarterTurn':
        svgName = getRandomFromArray([
          'Other_shapes/Anti_clockwise_shaded_diagram_three_quarter_turn',
          'Other_shapes/Clockwise_shaded_diagram_three_quarter_turn'
        ] as const);
        break;
      case 'fullTurn':
        svgName = getRandomFromArray([
          'Other_shapes/Anti_clockwise_shaded_diagram_full_turn',
          'Other_shapes/Clockwise_shaded_diagram_full_turn'
        ] as const);
        break;
    }

    return { turn, svgName };
  },

  Component: props => {
    const {
      question: { turn, svgName },
      translate
    } = props;

    const sentenceMapping = {
      quarterTurn: translate.answerSentences.thereAreAnsDegreesInAQuarterTurn(),
      halfTurn: translate.answerSentences.thereAreAnsDegreesInAHalfTurn(),
      threeQuarterTurn: translate.answerSentences.thereAreAnsDegreesInAThreeQuarterTurn(),
      fullTurn: translate.answerSentences.thereAreAnsDegreesInAFullTurn()
    };

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardsToCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={shuffle(
          [
            (90).toLocaleString(),
            (180).toLocaleString(),
            (270).toLocaleString(),
            (360).toLocaleString()
          ],
          { random: seededRandom({ turn, svgName }) }
        )}
        Content={({ dimens }) => (
          <AssetSvg
            name={svgName as SvgName}
            height={dimens.height * 0.8}
            width={dimens.width * 0.8}
          />
        )}
        questionHeight={1000}
        sentence={sentenceMapping[turn]}
        actionPanelVariant="end"
        testCorrect={[degreeMapping[turn].toLocaleString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'azQ',
  description: 'azQ',
  keywords: ['Turns', 'Angles'],
  schema: z.object({
    name: z.enum(['Alex', 'Annie', 'Jack', 'Mo']),
    turn: z.enum(['quarterTurn', 'halfTurn', 'threeQuarterTurn', 'fullTurn'])
  }),
  simpleGenerator: () => {
    const name = getRandomFromArray(['Alex', 'Annie', 'Jack', 'Mo'] as const);
    const turn = getRandomFromArray([
      'quarterTurn',
      'halfTurn',
      'threeQuarterTurn',
      'fullTurn'
    ] as const);

    return { name, turn };
  },

  Component: props => {
    const {
      question: { name, turn },
      translate
    } = props;

    const selectables = shuffle([90, 180, 270, 360], { random: seededRandom(props.question) });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.charMakeXTurnSelectHowManyDegreesCharHasTurnedThrough(
          name,
          translate.directions[turn]()
        )}
        pdfTitle={translate.instructions.charMakeXTurnCircleHowManyDegreesCharHasTurnedThrough(
          name,
          translate.directions[turn]()
        )}
        testCorrect={[degreeMapping[turn].toLocaleString()]}
        numItems={4}
        renderItems={selectables.map(item => ({
          value: item.toString(),
          component: <Text variant="WRN700">{item.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'azR',
  description: 'azR',
  keywords: ['Turns', 'Angles'],
  questionHeight: 900,
  schema: z.object({
    name: nameSchema,
    places: z.enum(['Bank', 'School', 'House', 'Bakery', 'Shop', 'Field']).array().length(4),
    startingPosition: numberEnum([0, 90, 180, 270]),
    turn: numberEnum([90, 180, 270, 360]),
    isClockwise: z.boolean()
  }),
  simpleGenerator: () => {
    const places = rejectionSample(
      () =>
        getRandomSubArrayFromArray(
          ['Bank', 'School', 'House', 'Bakery', 'Shop', 'Field'] as const,
          4
        ), // make sure the long words aren't on the ends
      val => val[1].length < 6 && val[3].length < 6
    );
    const name = getRandomFromArray(['Alex', 'Annie', 'Jack', 'Mo'] as const);
    const startingPosition = getRandomFromArray([0, 90, 180, 270] as const);
    const turn = getRandomFromArray([90, 180, 270, 360] as const);
    const isClockwise = getRandomBoolean();

    return { places, name, startingPosition, turn, isClockwise };
  },

  Component: props => {
    const {
      question: { places, name, startingPosition, turn, isClockwise },
      translate
    } = props;

    const selectables = places.map(place => getPlacesStringName(translate, place));
    const index = [0, 90, 180, 270].indexOf(startingPosition);

    const finalIndex = isClockwise ? index + ((turn / 90) % 4) : index - ((turn / 90) % 4);
    const pointLabel = translate.letters.A();

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          isClockwise
            ? translate.instructions.characterAtPointFacingXTurnsYDegsClockwiseWhatAreTheyFacingNow(
                name,
                pointLabel,
                selectables[index],
                turn
              )
            : translate.instructions.characterAtPointFacingXTurnsYDegsAntiClockwiseWhatAreTheyFacingNow(
                name,
                pointLabel,
                selectables[index],
                turn
              )
        }
        pdfTitle={
          isClockwise
            ? translate.instructions.characterAtPointFacingXTurnsYDegsClockwiseWhatAreTheyFacingNowPDF(
                name,
                pointLabel,
                selectables[index],
                turn
              )
            : translate.instructions.characterAtPointFacingXTurnsYDegsAntiClockwiseWhatAreTheyFacingNowPDF(
                name,
                pointLabel,
                selectables[index],
                turn
              )
        }
        leftContent={
          <MeasureView>
            {dimens => (
              <Intersection places={selectables} dimens={dimens} centerLabel={pointLabel} />
            )}
          </MeasureView>
        }
        questionHeight={900}
        correctAnswer={[selectables[finalIndex < 0 ? 4 + finalIndex : finalIndex]]}
        selectables={Object.fromEntries(selectables.map(selectable => [selectable, selectable]))}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'azS',
  description: 'azS',
  keywords: ['Turns', 'Angles'],
  schema: z.object({
    directions: z
      .enum(['north', 'east', 'south', 'west', 'northEast', 'southEast', 'northWest', 'southWest'])
      .array()
      .length(2),
    isClockwise: z.boolean(),
    answer: z.number(),
    name: nameSchema
  }),
  simpleGenerator: () => {
    const isStandard = getRandomBoolean();
    const directions = getRandomSubArrayFromArray(
      isStandard
        ? (['north', 'east', 'south', 'west'] as const)
        : (['northEast', 'southEast', 'northWest', 'southWest'] as const),
      2
    );
    const isClockwise = getRandomBoolean();
    const startIndex = isStandard
      ? ['north', 'east', 'south', 'west'].indexOf(directions[0]) + 1
      : ['northEast', 'southEast', 'southWest', 'northWest'].indexOf(directions[0]) + 1;
    const finishIndex = isStandard
      ? ['north', 'east', 'south', 'west'].indexOf(directions[1]) + 1
      : ['northEast', 'southEast', 'southWest', 'northWest'].indexOf(directions[1]) + 1;

    let answer;
    if (isClockwise) {
      if (startIndex < finishIndex) {
        answer = (finishIndex - startIndex) * 90;
      } else {
        answer = 360 - (startIndex - finishIndex) * 90;
      }
    } else {
      if (startIndex > finishIndex) {
        answer = (startIndex - finishIndex) * 90;
      } else {
        answer = 360 - (finishIndex - startIndex) * 90;
      }
    }

    const name = getRandomName();

    return { directions, isClockwise, answer, name };
  },

  Component: props => {
    const {
      question: { directions, isClockwise, answer, name },
      translate
    } = props;

    const directionsAsWords = directions.map(val => translate.directions[val]());

    return (
      <QF1ContentAndSentence
        title={translate.instructions.howManyDegreesDidXTurnThrough(name)}
        sentence={translate.answerSentences.ansDegrees()}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }
            ]}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'stretch'
              }}
            >
              <SpeechBubble
                flickLocation="bottom-right"
                style={{
                  maxWidth: 0.35 * dimens.width,
                  marginHorizontal: 0.075 * dimens.width,
                  top: -20,
                  zIndex: 1
                }}
              >
                {translate.answerSentences.iAmFacingXITurnYToFaceZ(
                  directionsAsWords[0],
                  isClockwise
                    ? translate.directions.clockwise()
                    : translate.directions.antiClockwise(),
                  directionsAsWords[1]
                )}
              </SpeechBubble>
              <View style={{ top: 20, marginRight: 20, zIndex: 0 }}>
                <AssetSvg name={getCharacterHeadSvgName(name)} width={0.2 * dimens.width} />
              </View>
            </View>
            <AssetSvg name="Compass_8_labels" width={dimens.width * 0.2} />
          </View>
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'azT',
  description: 'azT',
  keywords: ['Turns', 'Angles'],
  questionHeight: 900,
  schema: z.object({
    name: nameSchema,
    places: z
      .enum(['Pool', 'Cafe', 'Library', 'Hospital', 'School', 'Park', 'Bank', 'Shop'])
      .array()
      .length(8),
    startingPosition: numberEnum([45, 90, 135, 180, 225, 270, 315, 360]),
    turn: numberEnum([45, 90, 135, 180, 225, 270, 315, 360]),
    isClockwise: z.boolean(),
    optionsIndexes: z.array(z.number().int().min(0).max(8)).length(4)
  }),
  simpleGenerator: () => {
    const places = rejectionSample(
      () =>
        getRandomSubArrayFromArray(
          ['Pool', 'Cafe', 'Library', 'Hospital', 'School', 'Park', 'Bank', 'Shop'] as const,
          8
        ),
      // make sure the long words aren't on the ends
      val => val[2].length < 7 && val[6].length < 7
    );
    const name = getRandomFromArray(['Alex', 'Annie', 'Jack', 'Mo'] as const);
    const startingPosition = getRandomFromArray([45, 90, 135, 180, 225, 270, 315, 360] as const);
    const turn = getRandomFromArray([45, 90, 135, 180, 225, 270, 315, 360] as const);
    const isClockwise = getRandomBoolean();

    const index = [45, 90, 135, 180, 225, 270, 315, 360].indexOf(startingPosition);
    const finalIndex = isClockwise ? (index + turn / 45) % 8 : (index - turn / 45) % 8;
    const wrongIndex = isClockwise ? (index - turn / 45) % 8 : (index + turn / 45) % 8;

    const optionsIndexes =
      turn === 360 || turn === 180
        ? [finalIndex < 0 ? 8 + finalIndex : finalIndex]
        : [
            finalIndex < 0 ? 8 + finalIndex : finalIndex,
            wrongIndex < 0 ? 8 + wrongIndex : wrongIndex
          ];
    const incorrectOptions = randomUniqueIntegersInclusive(
      0,
      7,
      turn === 360 || turn === 180 ? 3 : 2,
      {
        constraint: x => !optionsIndexes.includes(x)
      }
    );
    optionsIndexes.push(...incorrectOptions);

    return { places, name, startingPosition, turn, isClockwise, optionsIndexes };
  },

  Component: props => {
    const {
      question: { places, name, startingPosition, turn, isClockwise, optionsIndexes },
      translate
    } = props;

    const selectables = optionsIndexes.map(i => getPlacesStringName(translate, places[i]));
    const index = [45, 90, 135, 180, 225, 270, 315, 360].indexOf(startingPosition);

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          isClockwise
            ? translate.instructions.characterAtCenterFacingXTurnsYDegsClockwiseWhatAreTheyFacingNow(
                name,
                places[index],
                turn
              )
            : translate.instructions.characterAtCenterFacingXTurnsYDegsAntiClockwiseWhatAreTheyFacingNow(
                name,
                places[index],
                turn
              )
        }
        pdfTitle={
          isClockwise
            ? translate.instructions.characterAtCenterFacingXTurnsYDegsClockwiseWhatAreTheyFacingNowPDF(
                name,
                places[index],
                turn
              )
            : translate.instructions.characterAtCenterFacingXTurnsYDegsAntiClockwiseWhatAreTheyFacingNowPDF(
                name,
                places[index],
                turn
              )
        }
        questionHeight={900}
        leftContent={
          <MeasureView>
            {dimens => (
              <Intersection
                places={places.map(place => getPlacesStringName(translate, place))}
                dimens={dimens}
              />
            )}
          </MeasureView>
        }
        correctAnswer={[selectables[0]]}
        selectables={Object.fromEntries(
          shuffle(selectables, {
            random: seededRandom({
              places,
              name,
              startingPosition,
              turn,
              isClockwise,
              optionsIndexes
            })
          }).map(selectable => [selectable, selectable])
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandAndUseDegrees',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
