import { ScientificNotation } from '../../../utils/math';
import BaseLayout from '../../molecules/BaseLayout';
import UserInput, { ExtraSymbols } from '../../molecules/UserInput';
import { MeasureView } from '../../atoms/MeasureView';
import { TitleStyleProps } from '../../molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import Division, { DivisionWithState } from '../representations/ColumnOperations/Division';

type UserAnswer = {
  divisor: string[];
  dividend: string[];
  quotient: string[];
  remainder: string[];
  exchanges: string[];
};

type Props = TitleStyleProps & {
  title: string;
  /**
   * The number to be shown on the left-hand side of the division operation.
   */
  divisor: number;
  /**
   * Digits to be replaced with answer boxes for the divisor. Array of powers of ten.
   */
  divisorMissingDigits?: number[];
  /**
   * The number to be shown on the right-hand side of the division operation.
   */
  dividend: number;
  /**
   * Digits to be replaced with answer boxes for the dividend. Array of powers of ten.
   */
  dividendMissingDigits?: number[];
  /**
   * The number to be shown on the top of the division operation. Optional prop, defaults to a blank line.
   * quotient cannot have more digits than dividend.
   */
  quotient?: number;
  /**
   * Digits to be replaced with answer boxes for the quotient. Array of powers of ten.
   */
  quotientMissingDigits?: number[];
  /**
   * Number to be shown for the remainder. Optional prop, defaults to not showing at all.
   * If show, the remainder symbol will be shown to the right of the quotient, followed by the remainder given.
   */
  remainder?: number;
  /**
   * Digits to be replaced with answer boxes for the remainder. Array of powers of ten.
   */
  remainderMissingDigits?: number[];
  extraSymbol?: ExtraSymbols;
  /**
   * Defaulted to false. When true the exchange values will be given and the row will be non-interactive.
   * When false they will be optional input boxes.
   * Does not make sense to use alongside the removeExchangeRow prop.
   */
  showNumberExchanges?: boolean;
  /**
   * Removes extra cells from top/bottom rows and left/right columns around the operation.
   * Defaults to false, except for mark scheme that always shows this for the teacher's benefit.
   */
  removeExtraCells?: boolean;
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answerToDisplay?: { [K in keyof UserAnswer]: { [P in K]: UserAnswer[K] } }[keyof UserAnswer];
    answerText?: string;
  };
};

/**
 * This component renders a division operation on a grid, made to represent grid paper.
 * This component is different to ColumnOperations, due to the nature of division being
 * markedly different to the other operations.
 * Answer boxes are determined by the missing digits passed in for the quotient/divisor/dividend/remainder.
 *
 * Limitations:
 * - Does not currently allow answer boxes for leading zeroes,
 * e.g. 15000 / 2 will not currently allow an answer box in the ten-thousands of the quotient.
 * - Is not currently configured to work fully with decimals.
 */
export default function QF27bShortDivision({
  title,
  divisor,
  divisorMissingDigits = [],
  dividend,
  dividendMissingDigits = [],
  quotient,
  quotientMissingDigits = [],
  remainder,
  remainderMissingDigits = [],
  extraSymbol,
  showNumberExchanges = false,
  removeExtraCells = false,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer
}: Props) {
  const displayMode = useContext(DisplayMode);

  const divisorSci = ScientificNotation.fromNumber(divisor);
  const dividendSci = ScientificNotation.fromNumber(dividend);
  const quotientSci = ScientificNotation.fromNumber(quotient ?? dividend / divisor);
  const remainderNumberSci = ScientificNotation.fromNumber(remainder ?? dividend % divisor);

  const minDivisorPower = Math.min(divisorSci.resolution, 0);
  const minDividendPower = Math.min(dividendSci.resolution, 0);
  const minAnswerPower = Math.min(quotientSci.resolution, 0);
  const minRemainderPower = Math.min(remainderNumberSci.resolution, 0);

  // Need to use adjusted missing digits to account for when we have decimals.
  // The test complete checks the array per pow but decimal powers include negatives so we need to adjust
  const adjustMissingDigits = (missingDigits: number[], minPower: number): number[] => {
    return missingDigits.map(i => i - minPower);
  };

  const adjustedDivisorMissingDigits = adjustMissingDigits(divisorMissingDigits, minDivisorPower);
  const adjustedDividendMissingDigits = adjustMissingDigits(
    dividendMissingDigits,
    minDividendPower
  );
  const adjustedAnswerMissingDigits = adjustMissingDigits(quotientMissingDigits, minAnswerPower);
  const adjustedRemainderMissingDigits = adjustMissingDigits(
    remainderMissingDigits,
    minRemainderPower
  );

  /** Check that every digit given by adjustedXMissingDigits has a non-empty user string set. */
  const testComplete = (answer: UserAnswer) =>
    adjustedDivisorMissingDigits.every(
      pow => answer.divisor[pow] !== undefined && answer.divisor[pow]?.length > 0
    ) &&
    adjustedDividendMissingDigits.every(
      pow => answer.dividend[pow] !== undefined && answer.dividend[pow]?.length > 0
    ) &&
    adjustedAnswerMissingDigits.every(
      pow => answer.quotient[pow] !== undefined && answer.quotient[pow]?.length > 0
    ) &&
    adjustedRemainderMissingDigits.every(
      pow => answer.remainder[pow] !== undefined && answer.remainder[pow]?.length > 0
    );

  /** Check that every digit given by adjustedXMissingDigits agrees with the digit from the number. */
  /** Also need to adjust unsignedDigitAt by the minDivisorPower i.e if we are looking at 2.3 tenths then the adjusted pow will be 1 but we want (1 + -1) */

  const testCorrect = (answer: UserAnswer) =>
    adjustedDivisorMissingDigits.every(
      pow => answer.divisor[pow] === divisorSci.unsignedDigitAt(pow + minDivisorPower).toString()
    ) &&
    adjustedDividendMissingDigits.every(
      pow => answer.dividend[pow] === dividendSci.unsignedDigitAt(pow + minDividendPower).toString()
    ) &&
    adjustedAnswerMissingDigits.every(
      pow => answer.quotient[pow] === quotientSci.unsignedDigitAt(pow + minAnswerPower).toString()
    ) &&
    adjustedRemainderMissingDigits.every(
      pow =>
        answer.remainder[pow] ===
        remainderNumberSci.unsignedDigitAt(pow + minAnswerPower).toString()
    );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const correctAnswerDefault = {
      divisor: divisor ? Array.from(divisor.toString()).reverse() : [],
      dividend: dividend ? Array.from(dividend.toString()).reverse() : [],
      quotient: quotient ? Array.from(quotient.toString()).reverse() : [],
      remainder: remainder ? Array.from(remainder.toString()).reverse() : [],
      exchanges: []
    };

    return (
      <BaseLayoutPDF
        title={title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <Division
                  divisor={divisor}
                  divisorMissingDigits={divisorMissingDigits}
                  dividend={dividend}
                  dividendMissingDigits={dividendMissingDigits}
                  quotient={quotient}
                  quotientMissingDigits={quotientMissingDigits}
                  remainder={remainder}
                  remainderMissingDigits={remainderMissingDigits}
                  dimens={dimens}
                  showNumberExchanges={displayMode === 'markscheme' ? true : showNumberExchanges}
                  removeExtraCells={removeExtraCells}
                  userAnswer={
                    displayMode === 'markscheme'
                      ? { ...correctAnswerDefault, ...customMarkSchemeAnswer?.answerToDisplay }
                      : undefined
                  }
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      actionPanelVariant="endWide"
      actionPanelContents={<UserInput inputType="numpad" extraSymbol={extraSymbol} />}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <DivisionWithState
              id="column-operation"
              divisor={divisor}
              divisorMissingDigits={divisorMissingDigits}
              dividend={dividend}
              dividendMissingDigits={dividendMissingDigits}
              quotient={quotient}
              quotientMissingDigits={quotientMissingDigits}
              remainder={remainder}
              remainderMissingDigits={remainderMissingDigits}
              dimens={dimens}
              showNumberExchanges={showNumberExchanges}
              removeExtraCells={removeExtraCells}
              testComplete={testComplete}
              testCorrect={testCorrect}
              defaultState={{
                divisor: [],
                dividend: [],
                quotient: [],
                remainder: [],
                exchanges: []
              }}
            />
          )}
        </MeasureView>
      }
    />
  );
}
