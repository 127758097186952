import { newSmallStepContent } from '../../../SmallStep';
import {
  aiW,
  aiX
} from '../../../Year 5/Autumn/AdditionAndSubtraction/2AddWholeNumbersWithMoreThanFourDigits';
import {
  ai1,
  ai3
} from '../../../Year 5/Autumn/AdditionAndSubtraction/3SubtractWholeNumbersWithMoreThanFourDigits';
import { ai8 } from '../../../Year 5/Autumn/AdditionAndSubtraction/4RoundToCheckAnswers';
import { aiD } from '../../../Year 4/Autumn/AdditionAndSubtraction/9EstimateAnswers';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import { z } from 'zod';
import { ADD, SUB } from '../../../../constants';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const Question1 = { ...aiW, uid: 'ajy', description: 'ajy' as const };

const Question2 = { ...ai1, uid: 'ajz', description: 'ajz' as const };

const Question3 = { ...ai8, uid: 'ajA', description: 'ajA' as const };

const Question3v2 = newQuestionContent({
  uid: 'ajA2',
  description: 'ajA2',
  keywords: ['Estimate'],
  schema: z
    .object({
      number1: z.number().int().min(20000).max(70000).multipleOf(10000),
      number2: z.number().int().min(20000).max(70000).multipleOf(10000),
      number4Factor: z.number().int().min(-2400).max(2400),
      number5Factor: z.number().int().min(-2400).max(2400),
      addOrSubtract: z.enum([ADD, SUB])
    })
    .refine(
      val => val.number1 + val.number2 < 100000,
      'number1 + number2 must be less than 100,000'
    )
    .refine(val => val.number4Factor !== 0, 'number4Factor must not be 0')
    .refine(val => val.number5Factor !== 0, 'number5Factor must not be 0'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(20000, 70000, 10000);

    const number2 = randomIntegerInclusiveStep(20000, 70000, 10000, {
      constraint: x => x + number1 < 100000
    });

    const number4Factor = randomIntegerInclusive(-2400, 2400, {
      constraint: x => x !== 0
    });

    const number5Factor = randomIntegerInclusive(-2400, 2400, {
      constraint: x => x !== 0
    });

    const addOrSubtract = getRandomFromArray([ADD, SUB] as const);

    return { number1, number2, number4Factor, number5Factor, addOrSubtract };
  },

  Component: props => {
    const {
      question: { number1, number2, number4Factor, number5Factor, addOrSubtract },
      translate,
      displayMode
    } = props;

    const number3 = number1 + number2;

    const number4 = number1 + number4Factor;

    const number5 = number2 + number5Factor;

    const number6 = number4 + number5;

    return (
      <QF2AlignedEquations
        title={translate.instructions.roundToNearestXToEstimateAnswer(10000)}
        sentenceStyle={{ height: 96 }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        leftSide={
          addOrSubtract === ADD
            ? [number4.toLocaleString(), '<ans/>']
            : [number6.toLocaleString(), '<ans/>']
        }
        rightSide={[number5.toLocaleString(), '<ans/> = <ans/>']}
        middleCharacter={addOrSubtract}
        testCorrect={
          addOrSubtract === ADD
            ? {
                right: [[], [number2.toString(), number3.toString()]],
                left: [[], [number1.toString()]]
              }
            : {
                right: [[], [number2.toString(), number1.toString()]],
                left: [[], [number3.toString()]]
              }
        }
        inputMaxCharacters={5}
      />
    );
  }
});

const Question4 = { ...aiX, uid: 'ajB', description: 'ajB' as const };

const Question5 = { ...ai3, uid: 'ajC', description: 'ajC' as const };

const Question6 = { ...aiD, uid: 'ajD', description: 'ajD' as const };

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtractIntegers',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
