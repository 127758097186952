import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  compareFractions,
  Fraction,
  fractionToDecimal,
  simplify
} from '../../../../utils/fractions';
import { filledArray } from '../../../../utils/collections';
import { fractionSchema, numberEnum } from '../../../../utils/zod';
import { barModelColors } from '../../../../theme/colors';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle,
  seededRandom,
  rejectionSample,
  randomUniqueIntegersInclusiveStep,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import FractionWall from '../../../../components/question/representations/FractionWall';
import { isPrime } from '../../../../utils/primes';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { MarkupAssets } from '../../../../markup';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ap9',
  description: 'ap9',
  keywords: ['Simplify', 'Fraction'],
  schema: z.object({
    numerators: z.number().int().min(2).max(8).multipleOf(2).array().length(2),
    denominators: z.number().int().min(4).max(10).multipleOf(2).array().length(2)
  }),
  questionHeight: 1500,
  simpleGenerator: () => {
    const numerators = randomUniqueIntegersInclusiveStep(2, 8, 2, 2).sort((a, b) => a - b);

    const denominator2 = randomIntegerInclusiveStep(4, 10, 2, {
      constraint: x => x > numerators[1]
    });
    const denominator1 = randomIntegerInclusiveStep(4, 10, 2, {
      constraint: x => x > numerators[0] && x !== denominator2
    });

    const denominators = [denominator1, denominator2];

    return { numerators, denominators };
  },
  Component: props => {
    const {
      question: { numerators, denominators },
      translate
    } = props;

    const [answer1, answer2] = simplify(numerators[0], denominators[0]);
    const [answer3, answer4] = simplify(numerators[1], denominators[1]);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.writeFractionsInSimplestFormUseFractionWallToHelp()}
        promptButton={translate.extraContentModal.viewFractionWall()}
        modalTitle={translate.extraContentModal.fractionWall()}
        modalContent={<FractionWall />}
        containerStyle={{ rowGap: 0 }}
        testCorrect={[
          [answer1.toString(), answer2.toString()],
          [answer3.toString(), answer4.toString()]
        ]}
        sentences={[
          `<frac n='${numerators[0]}' d='${denominators[0]}'/> = <frac nAns='' dAns=''/>`,
          `<frac n='${numerators[1]}' d='${denominators[1]}'/> = <frac nAns='' dAns=''/>`
        ]}
        pdfDirection="row"
        questionHeight={1500}
        pdfContainerStyle={{ columnGap: 128 }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aqa',
  description: 'aqa',
  keywords: ['Fraction', 'Simplify'],
  schema: z
    .object({
      number1: z.number().int().min(6).max(12),
      number2: z.number().int().min(8).max(12),
      number3: z.number().int().min(1).max(4),
      number4: numberEnum([3, 4, 5]),
      numeratorColor: z.string()
    })
    .refine(val => val.number3 < val.number4, 'number3 must be less than number4'),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { number1, number2, number3, number4 } = rejectionSample(
      () => {
        const number4 = getRandomFromArray([3, 4, 5] as const);
        // Must be less than number4
        const number3 = randomIntegerInclusive(1, number4 - 1);

        // Scale up number1/number2 by using multiplier
        const multiplier = getRandomFromArray([2, 3, 4] as const);
        const number1 = number3 * multiplier;
        const number2 = number4 * multiplier;

        return { number1, number2, number3, number4 };
      },
      // Ensure number1/number2 meet ranges set in Question specification
      ({ number1, number2 }) => number1 > 5 && number1 < 13 && number2 > 7 && number2 < 13
    );

    const numeratorColor = getRandomFromArray(Object.values(barModelColors)) as string;

    return { number1, number2, number3, number4, numeratorColor };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, numeratorColor },
      translate
    } = props;

    const numeratorColorArray = filledArray(numeratorColor, number1);

    const remainder = filledArray('white', number2 - number1);

    const customColorMap = [...numeratorColorArray, ...remainder];

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.completeEquivalentFraction()}<br/>${translate.instructions.shadeBarModelsToHelp()}`}
        testCorrect={[number3.toString(), number4.toString()]}
        sentence={`<frac n='${number1.toLocaleString()}' d='${number2.toLocaleString()}'/> = <frac nAns='' dAns=''/>`}
        Content={({ dimens: { width } }) => (
          <View style={{ rowGap: 32 }}>
            <ShadedFractionBarModel
              totalSubSections={number2}
              customColorMap={customColorMap}
              width={width}
              height={100}
            />
            <BarModelInteractiveWithState
              id="barmodel"
              numberOfRows={1}
              numberOfCols={number4}
              tableHeight={100}
              tableWidth={width}
            />
          </View>
        )}
        pdfDirection="column"
        questionHeight={1000}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.barModelsDoNotNeedShading() }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aqb',
  description: 'aqb',
  keywords: ['Equivalent', 'Fraction'],
  schema: z.object({
    statementFractions: fractionSchema(
      z.number().int().min(1).max(7),
      z.number().int().min(2).max(8)
    )
      .array()
      .length(2),
    answerFractions: fractionSchema(
      z.number().int().min(4).max(35),
      z.number().int().min(4).max(50)
    )
      .array()
      .length(3)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 3, 5, 7] as const);
    const number2 = randomIntegerInclusive(number1 + 1, 8, {
      // Extra constraint to prevent fraction ever equally a half and num1 + 2 / num2 + 2 equalling half:
      constraint: x => x !== number1 * 2 && !compareFractions([number1 + 2, x + 2], [1, 2])
    });
    const [simplifiedNum1, simplifiedNum2] = simplify(number1, number2);

    // Always set to a half
    const number3 = 1;
    const number4 = 2;

    const number6 = randomIntegerInclusive(18, 50, {
      constraint: x => x % 2 === 0
    });
    const number5 = number6 / 2;

    const multiplier = randomIntegerInclusive(2, 5, {
      // Prevent this multiplier ever creating a fraction equivalent to other items:
      constraint: x =>
        !compareFractions([number1 * x, number2 * x], [1, 2]) &&
        !compareFractions([number1 * x, number2 * x], [number1 + 2, number2 + 2])
    });
    const number7 = number1 * multiplier;
    const number8 = number2 * multiplier;

    const number9 = number1 + 2;
    const number10 = number2 + 2;

    const statementFractions: Fraction[] = [
      [simplifiedNum1, simplifiedNum2],
      [number3, number4]
    ];

    const answerFractions: Fraction[] = [
      [number5, number6],
      [number7, number8],
      [number9, number10]
    ];

    return { statementFractions, answerFractions };
  },
  Component: props => {
    const {
      question: { statementFractions, answerFractions },
      translate,
      displayMode
    } = props;

    const shuffledAnswerFractions = shuffle(answerFractions, {
      random: seededRandom(props.question)
    });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsMatchEquivalentFractions()}
        pdfTitle={translate.instructions.useCardsMatchEquivalentFractions()}
        items={shuffledAnswerFractions.map(frac => ({
          component: (
            <TextStructure
              sentence={`<frac n='${frac[0].toLocaleString()}' d='${frac[1].toLocaleString()}'/>`}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 30 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
              textVariant="WRN700"
              textStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
            />
          ),
          value: fractionToDecimal(...frac)
        }))}
        statements={statementFractions.map(frac => ({
          lhsComponent: (
            <TextStructure
              sentence={`<frac n='${frac[0].toLocaleString()}' d='${frac[1].toLocaleString()}'/> =`}
            />
          ),
          correctAnswer: fractionToDecimal(...frac)
        }))}
        statementStyle={{ justifyContent: 'center' }}
        useRedLinesOnMarkScheme={false}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqc',
  description: 'aqc',
  keywords: ['Simplify', 'Fraction'],
  schema: z.object({
    number1: z.number().int().min(6).max(20),
    number2: z.number().int().min(9).max(25),
    number3: z.number().int().min(12).max(30)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const baseNum1 = randomIntegerInclusive(2, 4);
    const baseNum2 = randomIntegerInclusive(3, 5, {
      constraint: x => x > baseNum1
    });

    const multiplier = getRandomFromArray([3, 4, 5] as const);

    const number1 = baseNum1 * multiplier;
    const number2 = baseNum2 * multiplier;
    const number3 = number2 + multiplier;

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const [answer1, answer2] = simplify(number1, number2);
    const [answer3, answer4] = simplify(number1, number3);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.simplifyFractions()}
        testCorrect={[
          [answer1.toString(), answer2.toString()],
          [answer3.toString(), answer4.toString()]
        ]}
        sentences={[
          `<frac n='${number1}' d='${number2}'/> = <frac nAns='' dAns=''/>`,
          `<frac n='${number1}' d='${number3}'/> = <frac nAns='' dAns=''/>`
        ]}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.fractionsMustBeInSimplestForm()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aqd',
  description: 'aqd',
  keywords: ['Simplify', 'Fraction', 'Equivalent'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(9),
      number2: z.number().int().min(5).max(12)
    })
    .refine(val => val.number1 < val.number2, 'number1 must be less than number 2'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 9);
    const number2 = randomIntegerInclusive(Math.max(5, number1 + 1), 12);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const baseFrac = [number1, number2];

    const [simplifiedNumerator, simplifiedDenominator] = simplify(number1, number2);

    const testCorrect = (answer: string[]): boolean => {
      const frac1 = [answer[0], answer[1]];
      const frac2 = [answer[2], answer[3]];
      const frac3 = [answer[4], answer[5]];

      return (
        compareFractions(frac1, baseFrac) &&
        compareFractions(frac2, baseFrac) &&
        compareFractions(frac3, baseFrac)
      );
    };

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.findThreeEquivalentFractions(
          `<frac n='${simplifiedNumerator.toLocaleString()}' d='${simplifiedDenominator.toLocaleString()}'/>`
        )}
        testCorrect={testCorrect}
        inputMaxCharacters={4}
        sentence={`<frac nAns='' dAns=''/> <frac nAns='' dAns=''/> <frac nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aqe',
  description: 'aqe',
  keywords: ['Simplify', 'Fraction'],
  schema: z.object({
    fiveOrTen: numberEnum([5, 10]),
    starSvg: z.enum(['Star_green', 'Star_pink', 'Star_purple', 'Star_yellow'])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const fiveOrTen = getRandomFromArray([5, 10] as const);
    const starSvg = getRandomFromArray([
      'Star_green',
      'Star_pink',
      'Star_purple',
      'Star_yellow'
    ] as const);

    return { fiveOrTen, starSvg };
  },
  Component: props => {
    const {
      question: { fiveOrTen, starSvg },
      translate
    } = props;

    return (
      <MarkupAssets
        elements={{
          star: <AssetSvg key="star" name={starSvg} height={60} />,
          heart: <AssetSvg key="heart" name={'Heart_purple'} height={60} />,
          starSmall: <AssetSvg key="star" name={starSvg} height={40} />,
          heartSmall: <AssetSvg key="heart" name={'Heart_purple'} height={40} />
        }}
      >
        <QF2AnswerBoxManySentences
          title={translate.instructions.findPossibleValues(
            "<asset name='starSmall'/>",
            "<asset name='heartSmall'/>",
            fiveOrTen,
            `<frac><n><asset name="starSmall"/></n> <d><asset name="heartSmall"/></d></frac>`
          )}
          testCorrect={userAnswer => {
            const ans1 = parseInt(userAnswer[0][0]);
            const ans2 = parseInt(userAnswer[1][0]);

            return isPrime(ans1) && ans2 % ans1 === 0 && ans2 % fiveOrTen === 0;
          }}
          inputMaxCharacters={3}
          sentences={[`<asset name='star'/> = <ans/>`, `<asset name='heart'/> = <ans/>`]}
          questionHeight={900}
        />
      </MarkupAssets>
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EquivalentFractionsAndSimplifying',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
