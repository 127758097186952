import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { numberEnum } from '../../../../utils/zod';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { all, create, number } from 'mathjs';
import { compareFloats } from '../../../../utils/math';
import { rangeAsString } from '../../../../utils/collections';
import { View } from 'react-native';
import { ADD, SUB } from '../../../../constants';
import { NumberTrackKeyboardWithState } from '../../../../components/question/representations/Number Track/NumberTrackKeyboard';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aB8',
  description: 'aB8',
  keywords: ['Decimals', 'Sequence', 'Addition', 'Tenths'],
  schema: z.object({
    startingNumber: z.number().int().min(1).max(7),
    interval: z.number().min(0.1).max(0.5).step(0.1)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(1, 7);
    const interval = randomIntegerInclusive(1, 5) / 10;

    return { startingNumber, interval };
  },
  Component: ({ question: { startingNumber, interval }, translate }) => {
    const endNumber = number(math.evaluate(`${startingNumber} + (2 * ${interval})`));

    const answer1 = number(math.evaluate(`${endNumber} + ${interval}`));
    const answer2 = number(math.evaluate(`${answer1} + ${interval}`));
    const answer3 = number(math.evaluate(`${answer2} + ${interval}`));

    // Create array to pass to number track & set where the answers go
    const numberArray = rangeAsString(startingNumber, endNumber, interval, true);
    numberArray.push('<ans/>', '<ans/>', '<ans/>');

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], answer1) &&
          compareFloats(userAnswer[1], answer2) &&
          compareFloats(userAnswer[2], answer3)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answerToDisplay: [answer1.toString(), answer2.toString(), answer3.toString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aB9',
  description: 'aB9',
  keywords: ['Decimals', 'Sequence', 'Addition', 'Tenths', 'Hundredths'],
  schema: z.object({
    startingNumber: z.number().min(1.01).max(6.99),
    intervalA: z.number().min(0.01).max(0.05).step(0.01)
  }),
  simpleGenerator: () => {
    const startingNumber =
      randomIntegerInclusiveStep(101, 699, 10, {
        constraint: x => x % 10 !== 0
      }) / 100;
    const intervalA = randomIntegerInclusive(1, 5) / 100;

    return { startingNumber, intervalA };
  },
  Component: ({ question: { startingNumber, intervalA }, translate, displayMode }) => {
    // Create Number Track A
    const endNumberA = number(math.evaluate(`${startingNumber} + (2 * ${intervalA})`));

    const answer1 = number(math.evaluate(`${endNumberA} + ${intervalA}`));
    const answer2 = number(math.evaluate(`${answer1} + ${intervalA}`));
    const answer3 = number(math.evaluate(`${answer2} + ${intervalA}`));

    const numberArrayA = rangeAsString(startingNumber, endNumberA, intervalA, true);
    numberArrayA.push('<ans/>', '<ans/>', '<ans/>');

    // Create Number Track B
    const intervalB = intervalA * 10;

    const endNumberB = number(math.evaluate(`${startingNumber} + (2 * ${intervalB})`));

    const answer4 = number(math.evaluate(`${endNumberB} + ${intervalB}`));
    const answer5 = number(math.evaluate(`${answer4} + ${intervalB}`));
    const answer6 = number(math.evaluate(`${answer5} + ${intervalB}`));

    const numberArrayB = rangeAsString(startingNumber, endNumberB, intervalB, true);
    numberArrayB.push('<ans/>', '<ans/>', '<ans/>');

    return (
      <QF3Content
        title={translate.instructions.completeNumberTracks()}
        actionPanelVariant="bottomTall"
        inputVariant="wide"
        inputType="numpad"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <View style={{ rowGap: 40 }}>
            <NumberTrackKeyboardWithState
              id="trackA"
              boxValues={numberArrayA}
              dimens={{ ...dimens, height: dimens.height / 3 }}
              testCorrect={userAnswer =>
                compareFloats(userAnswer[0], answer1) &&
                compareFloats(userAnswer[1], answer2) &&
                compareFloats(userAnswer[2], answer3)
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [answer1.toLocaleString(), answer2.toLocaleString(), answer3.toLocaleString()]
                  : undefined
              }
            />
            <NumberTrackKeyboardWithState
              id="trackB"
              boxValues={numberArrayB}
              dimens={{ ...dimens, height: dimens.height / 3 }}
              testCorrect={userAnswer =>
                compareFloats(userAnswer[0], answer4) &&
                compareFloats(userAnswer[1], answer5) &&
                compareFloats(userAnswer[2], answer6)
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [answer4.toLocaleString(), answer5.toLocaleString(), answer6.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aCa',
  description: 'aCa',
  keywords: ['Decimals', 'Sequence', 'Subtraction', 'Tenths'],
  schema: z.object({
    startingNumber: z.number().int().min(3).max(9),
    interval: z.number().min(0.1).max(0.5).step(0.1)
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(3, 9);
    const interval = randomIntegerInclusive(1, 5) / 10;

    return { startingNumber, interval };
  },
  Component: ({ question: { startingNumber, interval }, translate }) => {
    const endNumber = number(math.evaluate(`${startingNumber} - (2 * ${interval})`));

    const answer1 = number(math.evaluate(`${endNumber} - ${interval}`));
    const answer2 = number(math.evaluate(`${answer1} - ${interval}`));
    const answer3 = number(math.evaluate(`${answer2} - ${interval}`));

    // Create array to pass to number track & set where the answers go
    const numberArray = rangeAsString(startingNumber, endNumber, interval, true);
    numberArray.push('<ans/>', '<ans/>', '<ans/>');

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0], answer1) &&
          compareFloats(userAnswer[1], answer2) &&
          compareFloats(userAnswer[2], answer3)
        }
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answerToDisplay: [answer1.toString(), answer2.toString(), answer3.toString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aCb',
  description: 'aCb',
  keywords: ['Decimals', 'Sequence', 'Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    startingNumber: z.number().min(3.01).max(9.99),
    intervalA: z.number().min(0.01).max(0.05).step(0.01)
  }),
  simpleGenerator: () => {
    const startingNumber =
      randomIntegerInclusiveStep(301, 999, 10, {
        constraint: x => x % 10 !== 0
      }) / 100;
    const intervalA = randomIntegerInclusive(1, 5) / 100;

    return { startingNumber, intervalA };
  },
  Component: ({ question: { startingNumber, intervalA }, translate, displayMode }) => {
    // Create Number Track A
    const endNumberA = number(math.evaluate(`${startingNumber} - (2 * ${intervalA})`));

    const answer1 = number(math.evaluate(`${endNumberA} - ${intervalA}`));
    const answer2 = number(math.evaluate(`${answer1} - ${intervalA}`));
    const answer3 = number(math.evaluate(`${answer2} - ${intervalA}`));

    const numberArrayA = rangeAsString(startingNumber, endNumberA, intervalA, true);
    numberArrayA.push('<ans/>', '<ans/>', '<ans/>');

    // Create Number Track B
    const intervalB = intervalA * 10;

    const endNumberB = number(math.evaluate(`${startingNumber} - (2 * ${intervalB})`));

    const answer4 = number(math.evaluate(`${endNumberB} - ${intervalB}`));
    const answer5 = number(math.evaluate(`${answer4} - ${intervalB}`));
    const answer6 = number(math.evaluate(`${answer5} - ${intervalB}`));

    const numberArrayB = rangeAsString(startingNumber, endNumberB, intervalB, true);
    numberArrayB.push('<ans/>', '<ans/>', '<ans/>');

    return (
      <QF3Content
        title={translate.instructions.completeNumberTracks()}
        actionPanelVariant="bottomTall"
        inputVariant="wide"
        inputType="numpad"
        extraSymbol="decimalPoint"
        Content={({ dimens }) => (
          <View style={{ rowGap: 40 }}>
            <NumberTrackKeyboardWithState
              id="trackA"
              boxValues={numberArrayA}
              dimens={{ ...dimens, height: dimens.height / 3 }}
              testCorrect={userAnswer =>
                compareFloats(userAnswer[0], answer1) &&
                compareFloats(userAnswer[1], answer2) &&
                compareFloats(userAnswer[2], answer3)
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [answer1.toLocaleString(), answer2.toLocaleString(), answer3.toLocaleString()]
                  : undefined
              }
            />
            <NumberTrackKeyboardWithState
              id="trackB"
              boxValues={numberArrayB}
              dimens={{ ...dimens, height: dimens.height / 3 }}
              testCorrect={userAnswer =>
                compareFloats(userAnswer[0], answer4) &&
                compareFloats(userAnswer[1], answer5) &&
                compareFloats(userAnswer[2], answer6)
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [answer4.toLocaleString(), answer5.toLocaleString(), answer6.toLocaleString()]
                  : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aCc',
  description: 'aCc',
  keywords: ['Decimals', 'Sequence', 'Addition', 'Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    startingNumber: z.number().min(3.01).max(6.99),
    intervalA: z.number().min(0.01).max(0.05).step(0.01)
  }),
  simpleGenerator: () => {
    const startingNumber =
      randomIntegerInclusiveStep(301, 699, 10, {
        constraint: x => x % 10 !== 0
      }) / 100;
    const intervalA = randomIntegerInclusive(1, 5) / 100;

    return { startingNumber, intervalA };
  },
  Component: props => {
    const {
      question: { startingNumber, intervalA },
      translate
    } = props;

    const seqANumA = number(math.evaluate(`${startingNumber} + ${intervalA}`));
    const seqANumB = number(math.evaluate(`${seqANumA} + ${intervalA}`));

    const seqBNumA = number(math.evaluate(`${startingNumber} - ${intervalA}`));
    const seqBNumB = number(math.evaluate(`${seqBNumA} - ${intervalA}`));

    const intervalB = intervalA * 10;
    const seqCNumA = number(math.evaluate(`${startingNumber} + ${intervalB}`));
    const seqCNumB = number(math.evaluate(`${seqCNumA} + ${intervalB}`));

    const seqDNumA = number(math.evaluate(`${startingNumber} - ${intervalB}`));
    const seqDNumB = number(math.evaluate(`${seqDNumA} - ${intervalB}`));

    const sequenceStatements = [
      {
        statement: `${startingNumber.toLocaleString()},  ${seqANumA.toLocaleString()},  ${seqANumB.toLocaleString()}`,
        correctAnswer: `${ADD} ${intervalA.toLocaleString()}`
      },
      {
        statement: `${startingNumber.toLocaleString()},  ${seqBNumA.toLocaleString()},  ${seqBNumB.toLocaleString()}`,
        correctAnswer: `${SUB} ${intervalA.toLocaleString()}`
      },
      {
        statement: `${startingNumber.toLocaleString()},  ${seqCNumA.toLocaleString()},  ${seqCNumB.toLocaleString()}`,
        correctAnswer: `${ADD} ${intervalB.toLocaleString()}`
      },
      {
        statement: `${startingNumber.toLocaleString()},  ${seqDNumA.toLocaleString()},  ${seqDNumB.toLocaleString()}`,
        correctAnswer: `${SUB} ${intervalB.toLocaleString()}`
      }
    ];

    const ruleOptions = [
      `${ADD} ${intervalA.toLocaleString()}`,
      `${SUB} ${intervalA.toLocaleString()}`,
      `${ADD} ${intervalB.toLocaleString()}`,
      `${SUB} ${intervalB.toLocaleString()}`
    ];

    // Shuffle statements & options
    const random = seededRandom(props.question);
    const shuffledsequenceStatements = shuffle(sequenceStatements, {
      random
    });
    const shuffledRuleOptions = shuffle(ruleOptions, { random });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragTheCardsToMatchSequenceWithRule()}
        pdfTitle={translate.instructions.matchSequenceWithRule()}
        items={shuffledRuleOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={shuffledsequenceStatements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={shuffledsequenceStatements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
        pdfItemVariant="tallRectangle"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'aCd',
  description: 'aCd',
  keywords: ['Decimal', 'Sequence', 'Subtraction', 'Tenths', 'Hundredths'],
  schema: z.object({
    name: nameSchema,
    pencePerDay: numberEnum([25, 35, 45, 55, 65, 75]),
    days: z.number().int().min(10).max(19)
  }),
  simpleGenerator: () => {
    const name = getRandomName();

    const pencePerDay = getRandomFromArray([25, 35, 45, 55, 65, 75] as const);

    const days = randomIntegerInclusive(10, 19);

    return { name, pencePerDay, days };
  },
  Component: props => {
    const {
      question: { name, pencePerDay, days },
      translate
    } = props;

    const pounds = (pencePerDay * days) / 100;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterHasNumPoundsEveryDayCharacterSpendsNumP(
          name,
          pounds.toLocaleString(undefined, { minimumFractionDigits: 2 }),
          pencePerDay
        )}
        testCorrect={[days.toString()]}
        sentence={translate.answerSentences.ansDays()}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DecimalSequences',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
