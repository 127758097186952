import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { DIV } from '../../../../constants';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { useMemo } from 'react';
import RowOfImages, {
  calcRowOfImagesScaleFactor
} from '../../../../components/molecules/RowOfImages';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { countRange } from '../../../../utils/collections';
import { getImagesByAmount } from '../../../../utils/images';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { placeValueColumnInfo } from '../../../../components/question/representations/Place Value Chart/PlaceValueCounters';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { colors } from '../../../../theme/colors';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1v2 = newQuestionContent({
  uid: 'aKK2',
  description: 'aKK',
  keywords: ['Division', 'Image'],
  schema: z.object({
    tens: z.number().int().min(20).max(60).multipleOf(10),
    ones: z.number().int().min(2).max(9),
    multiple: z.number().int().min(2).max(6),
    image: z.enum(['lollipops', 'crayons', 'red_pens', 'blue_pens'])
  }),
  simpleGenerator: () => {
    const multiple = randomIntegerInclusive(2, 6);
    const tens = randomIntegerInclusiveStep(20, 60, 10, {
      constraint: x => (x / 10) % multiple === 0
    });
    const ones = randomIntegerInclusive(2, 9, {
      constraint: x => x % multiple === 0
    });

    const image = getRandomFromArray(['lollipops', 'crayons', 'red_pens', 'blue_pens'] as const);

    return { tens, ones, multiple, image };
  },
  Component: props => {
    const {
      question: { tens, ones, multiple, image },
      translate,
      displayMode
    } = props;

    // Part of question title
    const sum = tens + ones;

    // Answer
    const ans = sum / multiple;

    const tensSvg = (() => {
      switch (image) {
        case 'lollipops':
          return 'Base_Ten/Lollipops10';
        case 'crayons':
          return 'Base_Ten/Crayons10';
        case 'red_pens':
          return 'Base_Ten/10RedPens';
        case 'blue_pens':
          return 'Base_Ten/10BluePens';
      }
    })();

    const onesSvg = (() => {
      switch (image) {
        case 'lollipops':
          return 'Base_Ten/Lollipops1';
        case 'crayons':
          return 'Base_Ten/Crayons1_blue';
        case 'red_pens':
          return 'Base_Ten/1RedPen';
        case 'blue_pens':
          return 'Base_Ten/1BluePen';
      }
    })();

    return (
      <QF1ContentAndSentence
        sentence={`${sum.toLocaleString()} ${DIV} ${multiple} = <ans/>`}
        title={translate.instructions.completeNumberSentence()}
        pdfDirection="column"
        testCorrect={[ans.toString()]}
        Content={({ dimens }) => {
          return (
            <View
              style={{ display: 'flex', alignItems: 'center', rowGap: 12, width: dimens.width }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {countRange(tens / 10).map(i => (
                  <View key={i}>
                    <AssetSvg
                      name={tensSvg}
                      width={displayMode === 'digital' ? 80 : 120}
                      height={displayMode === 'digital' ? 80 : 120}
                    />
                  </View>
                ))}
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {countRange(ones).map(i => (
                  <View key={i}>
                    <AssetSvg
                      name={onesSvg}
                      width={displayMode === 'digital' ? 80 : 120}
                      height={displayMode === 'digital' ? 80 : 120}
                    />
                  </View>
                ))}
              </View>
            </View>
          );
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question1 = newQuestionContent({
  uid: 'aKK',
  description: 'aKK',
  keywords: ['Division', 'Image'],
  schema: z.object({
    tens: z.number().int().min(20).max(60).multipleOf(10),
    ones: z.number().int().min(2).max(9),
    multiple: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const multiple = randomIntegerInclusive(2, 6);
    const tens = randomIntegerInclusiveStep(20, 60, 10, {
      constraint: x => (x / 10) % multiple === 0
    });
    const ones = randomIntegerInclusive(2, 9, {
      constraint: x => x % multiple === 0
    });

    return { tens, ones, multiple };
  },
  Component: props => {
    const {
      question: { tens, ones, multiple },
      translate
    } = props;

    // Part of question title
    const sum = tens + ones;

    // Answer
    const ans = sum / multiple;

    return (
      <QF1ContentAndSentence
        sentence={`${sum.toLocaleString()} ${DIV} ${multiple} = <ans/>`}
        sentenceStyle={{ justifyContent: 'center' }}
        title={translate.instructions.completeNumberSentence()}
        testCorrect={[ans.toString()]}
        Content={({ dimens }) => {
          return (
            <View
              style={{ display: 'flex', alignItems: 'center', rowGap: 12, width: dimens.width }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {countRange(tens / 10).map(i => (
                  <View key={i}>
                    <AssetSvg name="Base_Ten/Lollipops10" width={80} height={80} />
                  </View>
                ))}
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {countRange(ones).map(i => (
                  <View key={i}>
                    <AssetSvg name="Base_Ten/Lollipops1" width={80} height={80} />
                  </View>
                ))}
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aKL2',
  description: 'aKL',
  keywords: ['Division', 'Place value chart'],
  schema: z
    .object({
      tens: z.number().int().min(20).max(90).multipleOf(10),
      ones: z.number().int().min(2).max(9),
      numOfRows: z.number().int().min(2).max(5),
      addOrSubtract: z.enum(['add', 'subtract']),
      tensOrOnes: z.enum(['tens', 'ones'])
    })
    .refine(
      val => Math.floor((val.tens + val.ones) / val.numOfRows / 10) > 0,
      'division answer must have > 0 in tens column'
    ),
  simpleGenerator: () => {
    const numOfRows = randomIntegerInclusive(2, 5);
    const { ones, tens } = rejectionSample(
      () => {
        const tens = randomIntegerInclusiveStep(20, 90, 10);
        const ones = randomIntegerInclusive(2, 9);

        return { ones, tens };
      },
      ({ ones, tens }) =>
        (ones + tens) % numOfRows === 0 &&
        ((ones + tens) / numOfRows) % 10 <= 5 &&
        Math.floor((ones + tens) / numOfRows / 10) <= 5 &&
        Math.floor((ones + tens) / numOfRows / 10) > 0
    );

    const addOrSubtract = getRandomFromArray(['add', 'subtract'] as const);
    const tensOrOnes = getRandomFromArray(['tens', 'ones'] as const);

    return { tens, ones, numOfRows, addOrSubtract, tensOrOnes };
  },
  Component: props => {
    const {
      question: { tens, ones, numOfRows, addOrSubtract, tensOrOnes },
      translate,
      displayMode
    } = props;

    // Part of question title
    const number3 = tens + ones;
    const divisionAns = (tens + ones) / numOfRows;

    // Get digits of answer
    const amountOfTens = Math.floor(divisionAns / 10);
    const amountOfOnes = divisionAns % 10;

    // Correct amount of tens
    const tensImagesA = getImagesByAmount('Place_value/10', amountOfTens);
    // Incorrect amount of tens
    const incorrectTensPerRow =
      tensOrOnes === 'tens' ? (addOrSubtract ? amountOfTens + 1 : amountOfTens - 1) : amountOfTens;
    const tensImagesB = getImagesByAmount('Place_value/10', incorrectTensPerRow);

    // Correct amount of ones
    const onesImagesA = getImagesByAmount('Place_value/1', amountOfOnes);
    // Incorrect amount of ones
    const incorrectOnesPerRow =
      tensOrOnes === 'ones' ? (addOrSubtract ? amountOfOnes + 1 : amountOfOnes - 1) : amountOfOnes;
    const onesImagesB = getImagesByAmount('Place_value/1', incorrectOnesPerRow);

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };

    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesA,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesA,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesB,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesB,
        4
      )
    );

    // Correct data
    const dataA = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesA}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesA}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Incorrect data
    const dataB = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={`${i}-incorrectTens`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesB}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={`${i}-incorrectOnes`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesB}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        tensImages: tensImagesA,
        onesImages: onesImagesA,
        data: dataA,
        value: 'A'
      };
      const eqB = {
        tensImages: tensImagesB,
        onesImages: onesImagesB,
        data: dataB,
        value: 'B'
      };
      return shuffle([eqA, eqB], { random: seededRandom(props.question) });
    }, [dataA, dataB, onesImagesA, onesImagesB, props.question, tensImagesA, tensImagesB]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPlaceValueChartRepresentsXDivideY(number3, numOfRows)}
        pdfTitle={translate.instructions.selectPlaceValueChartRepresentsXDivideYPDF(
          number3,
          numOfRows
        )}
        testCorrect={['A']}
        numItems={2}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation.value,
            component: (
              <CustomizableTable
                cellHeaders={[
                  {
                    label: translate.keywords.Tens(),
                    containerStyle: {
                      backgroundColor: placeValueColumnInfo[1].color
                    },
                    textStyle: {
                      color: placeValueColumnInfo[1].textColor
                    }
                  },
                  {
                    label: translate.keywords.Ones(),
                    containerStyle: {
                      backgroundColor: placeValueColumnInfo[0].color
                    },
                    textStyle: {
                      color: placeValueColumnInfo[0].textColor
                    }
                  }
                ]}
                tableData={equation.data}
                tableStyle={{ width: dimens.width * 0.9 }}
                tableFontSize={displayMode === 'digital' ? 24 : 50}
              />
            )
          }));
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aKL',
  description: 'aKL',
  keywords: ['Division', 'Place value chart'],
  schema: z.object({
    tens: z.number().int().min(20).max(50).multipleOf(10),
    ones: z.number().int().min(2).max(5),
    numOfRows: z.number().int().min(2).max(4),
    incorrectAnswer: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const numOfRows = randomIntegerInclusive(2, 4);
    const tens = randomIntegerInclusiveStep(20, 50, 10, {
      constraint: x => (x / 10) % numOfRows === 0
    });
    const ones = randomIntegerInclusive(2, 5, {
      constraint: x => x % numOfRows === 0
    });

    const incorrectAnswer = randomIntegerInclusive(1, 3);

    return { tens, ones, numOfRows, incorrectAnswer };
  },
  Component: props => {
    const {
      question: { tens, ones, numOfRows, incorrectAnswer },
      translate,
      displayMode
    } = props;

    // Part of question title
    const number3 = tens + ones;

    // Divide amount of tens by 10 to know how many rows deep counters should be
    const amountOfTens = tens / 10;

    // Correct amount of tens
    const tensImagesA = getImagesByAmount('Place_value/10', amountOfTens);
    // Incorrect amount of tens
    const tensImagesB = getImagesByAmount('Place_value/10', amountOfTens + 1);

    // Correct amount of ones
    const onesImagesA = getImagesByAmount('Place_value/1', ones);
    // Incorrect amount of ones
    const onesImagesB = getImagesByAmount('Place_value/1', ones + 1);

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };

    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesA,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesA,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesB,
        4
      ),
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        onesImagesB,
        4
      )
    );

    // Correct data
    const dataA = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesA}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesA}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Incorrect data
    const incorrectRows = incorrectAnswer === 1 ? numOfRows + 1 : numOfRows;

    const incorrectTensImages =
      incorrectAnswer === 2
        ? getImagesByAmount('Place_value/10', amountOfTens + 1)
        : getImagesByAmount('Place_value/10', amountOfTens);

    const incorrectOnesImages =
      incorrectAnswer === 3
        ? getImagesByAmount('Place_value/1', ones + 1)
        : getImagesByAmount('Place_value/1', ones);

    const dataB = countRange(incorrectRows).map(i => {
      return [
        <RowOfImages
          key={`${i}-incorrectTens`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={incorrectTensImages}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={`${i}-incorrectOnes`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={incorrectOnesImages}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        tensImages: tensImagesA,
        onesImages: onesImagesA,
        data: dataA,
        isCorrect: true,
        value: 'A'
      };
      const eqB = {
        tensImages: tensImagesB,
        onesImages: onesImagesB,
        data: dataB,
        isCorrect: false,
        value: 'B'
      };
      return shuffle([eqA, eqB], { random: seededRandom(props.question) });
    }, [dataA, dataB, onesImagesA, onesImagesB, props.question, tensImagesA, tensImagesB]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPlaceValueChartRepresentsXDivideY(number3, numOfRows)}
        pdfTitle={translate.instructions.selectPlaceValueChartRepresentsXDivideYPDF(
          number3,
          numOfRows
        )}
        testCorrect={['A']}
        numItems={2}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation.value,
            component: (
              <CustomizableTable
                cellHeaders={[
                  {
                    label: translate.keywords.Tens(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[1].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[1].textColor
                    }
                  },
                  {
                    label: translate.keywords.Ones(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[0].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[0].textColor
                    }
                  }
                ]}
                tableData={equation.data}
                tableStyle={{ width: dimens.width * 0.9 }}
                tableFontSize={displayMode === 'digital' ? 24 : 50}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aKM',
  description: 'aKM',
  keywords: ['Division', 'Tens', 'Ones'],
  schema: z.object({
    tens: z.number().int().min(20).max(90).multipleOf(10),
    ones: z.number().int().min(2).max(9),
    multiple: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const multiple = randomIntegerInclusive(2, 9);
    const tens = randomIntegerInclusiveStep(20, 90, 10, {
      constraint: x => (x / 10) % multiple === 0
    });
    const ones = randomIntegerInclusive(2, 9, {
      constraint: x => x % multiple === 0
    });

    return { tens, ones, multiple };
  },

  Component: props => {
    const {
      question: { tens, ones, multiple },
      translate
    } = props;

    // Part of question title
    const sum = tens + ones;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentencesToWorkOutXDivideY(sum, multiple)}
        testCorrect={[
          [(tens / 10).toString(), (tens / 10 / multiple).toString()],
          [ones.toString(), (ones / multiple).toString()],
          [(sum / multiple).toString()]
        ]}
        sentences={[
          `<ans/> ${translate.powersOfTen.tens(
            tens
          )} ${DIV} ${multiple.toLocaleString()} = <ans/> ${translate.powersOfTen.tens(tens)}`,
          `<ans/> ${translate.powersOfTen.ones(
            ones
          )} ${DIV} ${multiple.toLocaleString()} = <ans/> ${translate.powersOfTen.ones(ones)}`,
          `${sum.toLocaleString()} ${DIV} ${multiple.toLocaleString()} = <ans/>`
        ]}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        sentenceStyle={{ alignSelf: 'flex-start' }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aKO',
  description: 'aKO',
  keywords: ['Partition', '2-digit', 'Part-whole model', 'Division'],
  questionHeight: 1200,
  schema: z.object({
    divisor: numberEnum([2, 3, 4, 5, 8]),
    answer1: z.number().int().min(10).max(40).step(10),
    answer2: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const divisor = getRandomFromArray([2, 3, 4, 5, 8] as const);
        const answer1 = randomIntegerInclusiveStep(10, 40, 10);
        const answer2 = randomIntegerInclusive(1, 8, { constraint: x => x * divisor >= 10 });

        return {
          divisor,
          answer1,
          answer2
        };
      },
      val => (val.answer1 + val.answer2) * val.divisor < 100
    ),
  Component: props => {
    const {
      question: { divisor, answer1, answer2 },
      translate,
      displayMode
    } = props;
    const dividend = (answer1 + answer2) * divisor;
    const part1 = divisor * answer1;
    const part2 = dividend - part1;
    const sentences = [
      `${DIV} ${divisor.toLocaleString()} = <ans/>`,
      `${DIV} ${divisor.toLocaleString()} = <ans/>`,
      `${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`
    ];
    return (
      <QF1ContentAndSentences
        title={translate.instructions.workOutTheDivisions()}
        testCorrect={[[answer1.toString()], [answer2.toString()], [(answer1 + answer2).toString()]]}
        sentences={sentences}
        mainPanelStyle={{
          flexDirection: 'row'
        }}
        questionHeight={1200}
        style={{ alignItems: 'flex-end', right: 80 }}
        pdfSentenceStyle={
          displayMode !== 'digital' && {
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            rowGap: 200,
            right: 400
          }
        }
        Content={({ dimens }) => (
          <View style={{ position: 'absolute', top: 10 }}>
            <PartWholeModel
              top={dividend}
              partition={[part1, part2]}
              dimens={{
                width: displayMode === 'digital' ? dimens.width / 1.3 : dimens.width / 2,
                height: dimens.height / 1.5
              }}
              variation="leftRight"
            />
          </View>
        )}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aKP2',
  description: 'aKP',
  keywords: ['Part-whole model', 'Division', 'Partition'],
  questionHeight: 900,
  schema: z.object({
    dividend: z.number().int().min(11).max(99),
    divisor: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 7);
    const dividend = randomIntegerInclusive(11, 99, {
      constraint: x => x % divisor === 0 && x / divisor >= 12
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { dividend, divisor },
      translate
    } = props;

    // Try divisor x 20, else use divisor x 10
    // Work out partitions
    let partition: number[];
    if (divisor * 20 < dividend) {
      partition = [divisor * 20, dividend - divisor * 20];
    } else {
      partition = [divisor * 10, dividend - divisor * 10];
    }

    // Answer
    const ans = dividend / divisor;

    return (
      <QF1ContentAndSentence
        sentence={`${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
        title={translate.instructions.workOutXDivideY(dividend, divisor)}
        testCorrect={[ans.toString()]}
        questionHeight={900}
        Content={({ dimens }) => (
          <PartWholeModel top={dividend} partition={partition} isInteractive dimens={dimens} />
        )}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aKP',
  description: 'aKP',
  keywords: ['Part-whole model', 'Division', 'Partition'],
  questionHeight: 900,
  schema: z.object({
    number1: z.number().int().min(10).max(40),
    number2: z.number().int().min(2).max(9),
    number3: z.number().int().min(12).max(49),
    number4: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const { number1, number2, number3, number4 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(10, 40);
        const number2 = randomIntegerInclusive(2, 9);
        const number3 = number1 + number2;
        const number4 = randomIntegerInclusive(2, 8);
        return { number1, number2, number3, number4 };
      },
      ({ number2, number3, number4 }) =>
        number4 * number3 < 100 &&
        number4 * number2 > 10 &&
        (number4 * number2) % 10 !== 0 &&
        (number3 * number4) % 10 !== 0
    );
    return {
      number1,
      number2,
      number3,
      number4
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    // More numbers
    const number5 = number3 * number4;
    const number6 = number1 * number4;
    const number7 = number2 * number4;

    // Answer
    const ans = number5 / number4;

    return (
      <QF1ContentAndSentence
        sentence={`${number5.toLocaleString()} ${DIV} ${number4.toLocaleString()} = <ans/>`}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
        title={translate.instructions.workOutXDivideY(number5, number4)}
        testCorrect={[ans.toString()]}
        questionHeight={900}
        Content={({ dimens }) => (
          <PartWholeModel
            top={number5}
            partition={[number6, number7]}
            isInteractive
            dimens={dimens}
          />
        )}
      />
    );
  }
});

// Question6 & Question6v2 used to be Question4, however they were rearranged following a rewrite for v2.
// So the UID will not be in alphabetical order.
const Question6v2 = newQuestionContent({
  uid: 'aKN2',
  description: 'aKN2',
  keywords: ['Division'],
  schema: z.object({
    divisor: z.number().int().min(2).max(8),
    dividend: z.number().int().min(10).max(99)
  }),
  simpleGenerator: () => {
    const divisor = randomIntegerInclusive(2, 8);
    const dividend = randomIntegerInclusive(10, 99, {
      constraint: x => x % divisor === 0 && x / divisor >= 12
    });

    return { divisor, dividend };
  },
  Component: props => {
    const {
      question: { divisor, dividend },
      translate
    } = props;

    const answer = dividend / divisor;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeDivision()}
        sentence={`${dividend.toLocaleString()} ${DIV} ${divisor.toLocaleString()} = <ans/>`}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aKN',
  description: 'aKN',
  keywords: ['Division', 'Place value chart'],
  schema: z.object({
    tens: z.number().int().min(30).max(50).multipleOf(10),
    ones: z.number().int().min(0).max(5),
    numOfRows: z.number().int().min(2).max(4),
    incorrectAnswer: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const { tens, ones, numOfRows, incorrectAnswer } = rejectionSample(
      () => {
        const tens = randomIntegerInclusiveStep(30, 50, 10);
        const ones = randomIntegerInclusive(0, 5, {
          constraint: x => x !== 1
        });
        const numOfRows = randomIntegerInclusive(2, 4);

        const incorrectAnswer = randomIntegerInclusive(1, 3);

        return { tens, ones, numOfRows, incorrectAnswer };
      },
      ({ tens, ones, numOfRows }) => (tens + ones) % numOfRows === 0
    );

    return { tens, ones, numOfRows, incorrectAnswer };
  },
  Component: props => {
    const {
      question: { tens, ones, numOfRows, incorrectAnswer },
      translate,
      displayMode
    } = props;

    // Part of question title
    const number3 = tens + ones;

    // Divide amount of tens by 10 to know how many rows deep counters should be
    const amountOfTens = tens / 10;

    // Correct amount of tens
    const tensImagesA = getImagesByAmount('Place_value/10', amountOfTens);
    // Incorrect amount of tens
    const tensImagesB = getImagesByAmount('Place_value/10', amountOfTens + 1);

    // Correct amount of ones
    const onesImagesA = getImagesByAmount('Place_value/1', ones);
    // Incorrect amount of ones
    const onesImagesB = getImagesByAmount('Place_value/1', ones + 1);

    const rowContainerDimens =
      displayMode === 'digital' ? { width: 200, height: 50 } : { width: 400, height: 100 };

    // If ones is zero return 1 for the scale factor, so we don't show any counters
    // Else return counters with relevant scale factor
    const scaleFactor = Math.min(
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesA,
        4
      ),
      onesImagesA.length > 0
        ? calcRowOfImagesScaleFactor(
            rowContainerDimens.width,
            rowContainerDimens.height,
            onesImagesA,
            4
          )
        : 1,
      calcRowOfImagesScaleFactor(
        rowContainerDimens.width,
        rowContainerDimens.height,
        tensImagesB,
        4
      ),
      onesImagesB.length > 0
        ? calcRowOfImagesScaleFactor(
            rowContainerDimens.width,
            rowContainerDimens.height,
            onesImagesB,
            4
          )
        : 1
    );

    // Correct data
    const dataA = countRange(numOfRows).map(i => {
      return [
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={tensImagesA}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={i}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={onesImagesA}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Incorrect data
    const incorrectRows = incorrectAnswer === 1 ? numOfRows + 1 : numOfRows;

    const incorrectTensImages =
      incorrectAnswer === 2
        ? getImagesByAmount('Place_value/10', amountOfTens + 1)
        : getImagesByAmount('Place_value/10', amountOfTens);

    const incorrectOnesImages =
      incorrectAnswer === 3
        ? getImagesByAmount('Place_value/1', ones + 1)
        : getImagesByAmount('Place_value/1', ones);

    const dataB = countRange(incorrectRows).map(i => {
      return [
        <RowOfImages
          key={`${i}-incorrectTens`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={incorrectTensImages}
          scaleFactor={scaleFactor}
        />,
        <RowOfImages
          key={`${i}-incorrectOnes`}
          containerStyle={rowContainerDimens}
          style={{ gap: 4 }}
          images={incorrectOnesImages}
          scaleFactor={scaleFactor}
        />
      ];
    });

    // Randomly order these equations
    const eqs = useMemo(() => {
      const eqA = {
        tensImages: tensImagesA,
        onesImages: onesImagesA,
        data: dataA,
        isCorrect: true,
        value: 'A'
      };
      const eqB = {
        tensImages: tensImagesB,
        onesImages: onesImagesB,
        data: dataB,
        isCorrect: false,
        value: 'B'
      };
      return shuffle([eqA, eqB], { random: seededRandom(props.question) });
    }, [dataA, dataB, onesImagesA, onesImagesB, props.question, tensImagesA, tensImagesB]);

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectPlaceValueChartRepresentsXDivideY(number3, numOfRows)}
        pdfTitle={translate.instructions.selectPlaceValueChartRepresentsXDivideYPDF(
          number3,
          numOfRows
        )}
        testCorrect={['A']}
        numItems={2}
        renderItems={({ dimens }) => {
          return eqs.map(equation => ({
            value: equation.value,
            component: (
              <CustomizableTable
                cellHeaders={[
                  {
                    label: translate.keywords.Tens(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[1].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[1].textColor
                    }
                  },
                  {
                    label: translate.keywords.Ones(),
                    containerStyle: {
                      backgroundColor:
                        displayMode === 'digital' ? placeValueColumnInfo[0].color : colors.greys100
                    },
                    textStyle: displayMode === 'digital' && {
                      color: placeValueColumnInfo[0].textColor
                    }
                  }
                ]}
                tableData={equation.data}
                tableStyle={{ width: dimens.width * 0.9 }}
                tableFontSize={displayMode === 'digital' ? 24 : 50}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DivideA2DigitNumberByA1DigitNumber1',
  questionTypes: [Question1v2, Question2v2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question1, Question2, Question5, Question6]
});
export default SmallStep;
