import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomName, nameSchema } from '../../../../utils/names';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { compareFractions, fractionArithmetic } from '../../../../utils/fractions';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import PieChart from '../../../../components/question/representations/PieChart';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import { barModelColors } from '../../../../theme/colors';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { ADD } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aH2',
  description: 'aH2',
  keywords: ['Fraction', 'Whole', 'Equal', 'Parts', 'Numerator', 'Denominator'],
  schema: z.object({
    equalParts: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const equalParts = randomIntegerInclusive(2, 8);

    return { equalParts };
  },
  questionHeight: 1400,
  Component: props => {
    const {
      question: { equalParts },
      translate,
      displayMode
    } = props;

    const equalPartsString = equalParts.toString();

    return (
      <QF1ContentAndSentences
        questionHeight={1400}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [equalParts.toString()],
            [equalParts.toString()],
            [equalParts.toString()]
          ]
        }}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          translate.answerSentences.theWholeHasBeenSplitInto(),
          translate.answerSentences.xEqualParts(),
          translate.answerSentences.xPartsAreShaded(),
          translate.answerSentences.theFractionShadedIs()
        ]}
        pdfDirection={displayMode === 'digital' ? 'row' : 'column'}
        textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        title={translate.instructions.completeSentencesForShape()}
        testCorrect={[
          [],
          [equalPartsString],
          [equalPartsString],
          [equalPartsString, equalPartsString]
        ]}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <PieChart
            pieOptions={filledArray({ ratioOfSlices: 1 }, equalParts)}
            radius={dimens.height / 3}
          />
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aH3',
  description: 'aH3',
  keywords: ['Fraction', 'Whole', 'Bar model', 'Equal', 'Parts', 'Numerator', 'Denominator'],
  schema: z.object({
    numerator: z.number().int().min(1).max(8),
    denominator: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(1, 8);
    const denominator = randomIntegerInclusive(2, 10, { constraint: x => x > numerator });

    return { numerator, denominator };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { numerator, denominator },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const answerNumerator = denominator - numerator;

    const numeratorColorArray1 = filledArray(numeratorColor, numerator);
    const remainder1 = filledArray('white', denominator - numerator);

    const customColorMap = [[...numeratorColorArray1, ...remainder1]];

    return (
      <QF1ContentAndSentences
        questionHeight={1000}
        pdfDirection="column"
        customMarkSchemeAnswer={{
          answersToDisplay: [[], [answerNumerator.toString(), denominator.toString()]],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        sentences={[
          translate.answerSentences.fractionOfShapeIsShaded({
            frac: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`
          }),
          translate.answerSentences.fractionOfShapeNeedsShadingToCompleteWhole({
            frac: `<frac nAns='' dAns=''/>`
          })
        ]}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        inputMaxCharacters={2}
        title={translate.instructions.completeSentence()}
        testCorrect={userAnswer => compareFractions(userAnswer[1], [answerNumerator, denominator])}
        Content={({ dimens }) => {
          return (
            <ShadedFractionBarModel
              totalSubSections={denominator}
              customColorMap={customColorMap[0]}
              width={dimens.width}
            />
          );
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aH4',
  description: 'aH4',
  keywords: ['Fraction', 'Whole', 'Parts', 'Numerator', 'Denominator'],
  schema: z.object({
    numerator: z.number().int().min(1).max(8),
    denominator: z.number().int().min(2).max(10),
    colourOne: z.enum([
      'Red',
      'Blue',
      'Green',
      'Orange',
      'Pink',
      'Yellow',
      'Grey',
      'Black',
      'White',
      'Purple'
    ]),
    colourTwo: z.enum([
      'Red',
      'Blue',
      'Green',
      'Orange',
      'Pink',
      'Yellow',
      'Grey',
      'Black',
      'White',
      'Purple'
    ])
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const [colourOne, colourTwo] = getRandomSubArrayFromArray(
      [
        'Red',
        'Blue',
        'Green',
        'Orange',
        'Pink',
        'Yellow',
        'Grey',
        'Black',
        'White',
        'Purple'
      ] as const,
      2
    );
    const numerator = randomIntegerInclusive(1, 8);
    const denominator = randomIntegerInclusive(2, 10, { constraint: x => x > numerator });

    return { numerator, denominator, colourOne, colourTwo };
  },
  Component: props => {
    const {
      question: { numerator, denominator, colourOne, colourTwo },
      translate,
      displayMode
    } = props;

    const translatedColourOne = translate.colors[colourOne]();
    const translatedColourTwo = translate.colors[colourTwo]();

    const answerNumerator = denominator - numerator;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.countersInABagAreTwoDifferentColours(
          translatedColourOne,
          translatedColourTwo
        )}
        testCorrect={userAnswer => compareFractions(userAnswer[1], [answerNumerator, denominator])}
        inputMaxCharacters={2}
        containerStyle={{ alignItems: 'flex-start' }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentences={[
          translate.answerSentences.fractionOfCountersInTheBag({
            color: translatedColourOne,
            frac: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`
          }),
          translate.answerSentences.fractionOfCountersAreX({
            color: translatedColourTwo,
            frac: `<frac nAns='' dAns=''/>`
          })
        ]}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [[], [answerNumerator.toLocaleString(), denominator.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aH5',
  description: 'aH5',
  keywords: ['Fraction', 'Whole', 'Parts', 'Numerator', 'Denominator'],
  schema: z.object({
    numeratorA: z.number().int().min(2).max(10),
    numeratorB: z.number().int().min(21).max(50),
    denominatorA: z.number().int().min(11).max(20),
    denominatorB: z.number().int().min(52).max(250)
  }),
  simpleGenerator: () => {
    const numeratorA = randomIntegerInclusive(2, 10);
    const numeratorB = randomIntegerInclusive(21, 50);
    const denominatorA = randomIntegerInclusive(11, 20);
    const denominatorB = randomIntegerInclusive(52, 250);

    return { numeratorA, numeratorB, denominatorA, denominatorB };
  },
  Component: props => {
    const {
      question: { numeratorA, numeratorB, denominatorA, denominatorB },
      translate
    } = props;

    const sentences = [
      `<frac n='${numeratorA.toLocaleString()}' dAns='' />`,
      `<frac nAns='' d='${denominatorA.toLocaleString()}'/> `,
      `<frac n='${numeratorB.toLocaleString()}' dAns=''/>`,
      `<frac nAns='' d='${denominatorB.toLocaleString()}'/>`
    ];

    const correctAnswer = [
      [numeratorA.toString()],
      [denominatorA.toString()],
      [numeratorB.toString()],
      [denominatorB.toString()]
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeEachFractionToEqualXWhole('1')}
        testCorrect={correctAnswer}
        inputMaxCharacters={2}
        sentenceStyle={{ marginHorizontal: 32 }}
        sentences={sentences}
        fractionContainerStyle={{ height: 96 }}
        pdfDirection="row"
        containerStyle={{ flexDirection: 'row' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aH6',
  description: 'aH6',
  keywords: ['Fraction', 'Whole', 'Parts', 'Numerator', 'Denominator'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(8),
    denominator: z.number().int().min(5).max(9),
    randomNumberA: z.number().int().min(1).max(9),
    randomNumberB: z.number().int().min(1).max(9),
    randomNumberC: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 9);
    const numeratorA = randomIntegerInclusive(1, denominator - 1);
    const [randomNumberA, randomNumberB, randomNumberC] = randomUniqueIntegersInclusive(1, 9, 3, {
      constraint: x => x !== numeratorA && x !== denominator && x !== denominator - numeratorA
    });

    return { denominator, numeratorA, randomNumberA, randomNumberB, randomNumberC };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, randomNumberA, randomNumberB, randomNumberC },
      translate
    } = props;

    const numeratorB = denominator - numeratorA;

    const items = [
      denominator,
      numeratorA,
      randomNumberA,
      randomNumberB,
      randomNumberC,
      numeratorB
    ];

    const testCorrect = (userAnswer: readonly (number | undefined)[]): boolean => {
      if (userAnswer.every(ans => ans !== undefined)) {
        const [numeratorA, denominatorA, numeratorB, denominatorB] = userAnswer as number[];

        const [numerator, denominator] = fractionArithmetic(
          [numeratorA, denominatorA],
          [numeratorB, denominatorB],
          ADD
        );

        return numerator === denominator;
      } else {
        return false;
      }
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.twoFractionsAreEqualToOneWhole()}
        pdfTitle={translate.instructions.twoFractionsAreEqualToOneWholePdf()}
        items={items}
        sentence={`<frac dAns='' nAns='' />  <frac dAns='' nAns='' />`}
        testCorrect={testCorrect}
        moveOrCopy="copy"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptValidFractionsThatTotalXFromAvailCards(1)
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question5v2 = newQuestionContent({
  uid: 'aH62',
  description: 'aH6',
  keywords: ['Fraction', 'Whole', 'Parts', 'Numerator', 'Denominator'],
  schema: z.object({
    numeratorA: z.number().int().min(1).max(8),
    numeratorB: z.number().int().min(1).max(8),
    denominator: z.number().int().min(5).max(9),
    randomNumberA: z.number().int().min(1).max(9),
    randomNumberB: z.number().int().min(1).max(9),
    randomNumberC: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 9);
    const numeratorA = randomIntegerInclusive(1, denominator - 1);
    const [randomNumberA, randomNumberB, randomNumberC] = randomUniqueIntegersInclusive(1, 9, 3, {
      constraint: x => x !== numeratorA && x !== denominator && x !== denominator - numeratorA
    });

    let numeratorB = denominator - numeratorA;

    if (numeratorB === numeratorA) {
      numeratorB = randomIntegerInclusive(1, denominator - 1, {
        constraint: x =>
          arrayHasNoDuplicates([
            x,
            numeratorA,
            denominator,
            randomNumberA,
            randomNumberB,
            randomNumberC
          ])
      });
    }
    return { denominator, numeratorA, numeratorB, randomNumberA, randomNumberB, randomNumberC };
  },
  Component: props => {
    const {
      question: {
        denominator,
        numeratorA,
        numeratorB,
        randomNumberA,
        randomNumberB,
        randomNumberC
      },
      translate
    } = props;

    const items = shuffle(
      [denominator, numeratorA, randomNumberA, randomNumberB, randomNumberC, numeratorB],
      { random: seededRandom(props.question) }
    );

    const testCorrect = (userAnswer: readonly (number | undefined)[]): boolean => {
      if (userAnswer.every(ans => ans !== undefined)) {
        const [numeratorA, denominatorA, numeratorB, denominatorB] = userAnswer as number[];

        const [numerator, denominator] = fractionArithmetic(
          [numeratorA, denominatorA],
          [numeratorB, denominatorB],
          ADD
        );

        return numerator === denominator;
      } else {
        return false;
      }
    };

    return (
      <QF37SentenceDrag
        title={translate.instructions.twoFractionsAreEqualToOneWhole()}
        pdfTitle={translate.instructions.twoFractionsAreEqualToOneWholePdf()}
        items={items}
        sentence={`<frac dAns='' nAns='' />  <frac dAns='' nAns='' />`}
        testCorrect={testCorrect}
        moveOrCopy="copy"
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptValidFractionsThatTotalXFromAvailCards(1)
        }}
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'aH7',
  description: 'aH7',
  keywords: ['Fraction', 'Whole', 'Parts', 'Numerator', 'Denominator'],
  schema: z.object({
    numerator: z.number().int().min(1).max(8),
    denominator: z.number().int().min(5).max(9),
    character: nameSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const character = getRandomName();
    const denominator = randomIntegerInclusive(5, 9);
    const numerator = randomIntegerInclusive(1, 8, { constraint: x => x < denominator });

    return { numerator, denominator, character };
  },
  Component: props => {
    const {
      question: { numerator, denominator, character },
      translate,
      displayMode
    } = props;

    const answerNumerator = denominator - numerator;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.characterIsThinkingOfAFraction(character)}
        testCorrect={userAnswer => compareFractions(userAnswer[1], [answerNumerator, denominator])}
        inputMaxCharacters={2}
        containerStyle={{ alignItems: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        sentences={[
          translate.answerSentences.fractionMoreThanCharactersFractionIsAWhole({
            name: character,
            frac: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>`
          }),
          translate.answerSentences.charactersFractionIs({
            name: character,
            frac: `<frac nAns='' dAns=''/>`
          })
        ]}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answersToDisplay: [[], [answerNumerator.toLocaleString(), denominator.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'UnderstandTheWhole',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6],
  archivedQuestionTypes: [Question5]
});
export default SmallStep;
