import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import { all, create, number } from 'mathjs';

import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import {
  ScientificNotation,
  compareFloats,
  lessThanGreaterThanOrEqualTo,
  numberWithEnforcedDigit
} from '../../../../utils/math';
import PlaceValueChart from '../../../../components/question/representations/Place Value Chart/PlaceValueChart';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF23CreatePlaceValueChart from '../../../../components/question/questionFormats/QF23CreatePlaceValueChart';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import TextStructure from '../../../../components/molecules/TextStructure';
import Text from '../../../../components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aOk',
  description: 'aOk',
  keywords: ['Hundredths', 'Tenths', 'Ones', 'Place value chart'],
  schema: z.object({
    ones: z.number().int().min(0).max(6),
    tenths: z.number().int().min(0).max(6),
    hundredths: z.number().int().min(0).max(6)
  }),
  questionHeight: 1000,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const ones = randomIntegerInclusive(0, 6, { constraint: x => x !== 1 });
        const tenths = randomIntegerInclusive(0, 6, { constraint: x => x !== 1 });
        const hundredths = randomIntegerInclusive(0, 6, { constraint: x => x !== 1 });

        return { ones, tenths, hundredths };
      },
      val => val.ones + val.tenths + val.hundredths > 0
    ),
  Component: props => {
    const {
      question: { ones, tenths, hundredths },
      translate
    } = props;
    const answer = number(math.evaluate(`${ones} + ${tenths} / 10 + ${hundredths} / 100`));
    return (
      <QF1ContentAndSentences
        sentences={[
          translate.answerSentences.thereAreAnsOnesAnsTenthsAndAnsHundredths(),
          translate.answerSentences.theNumberIsAns()
        ]}
        title={translate.instructions.completeSentences()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ones.toString()) &&
          compareFloats(userAnswer[0][1], tenths.toString()) &&
          compareFloats(userAnswer[0][2], hundredths.toString()) &&
          compareFloats(userAnswer[1][0], answer.toString())
        }
        inputMaxCharacters={4}
        extraSymbol="decimalPoint"
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ones.toLocaleString(), tenths.toLocaleString(), hundredths.toLocaleString()],
            [answer.toLocaleString()]
          ]
        }}
        questionHeight={1000}
        pdfDirection="column"
        Content={({ dimens }) => {
          return (
            <PlaceValueChart
              columnsToShow={[0, -1, -2]}
              counterVariant="decimalCounter"
              dimens={dimens}
              number={ScientificNotation.fromNumber(answer)}
              headerVariant="numberTitle"
            />
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aOl',
  description: 'aOl',
  keywords: ['Hundredths', 'Tenths', 'Ones', 'Place value chart'],
  schema: z.object({
    number: z.number().min(0.01).max(2).step(0.01)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 200) / 100;
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    return (
      <QF23CreatePlaceValueChart
        title={translate.instructions.dragInCountersToMakeNum(number.toLocaleString())}
        pdfTitle={translate.instructions.drawCountersToMakeNum(number.toLocaleString())}
        number={ScientificNotation.fromNumber(number)}
        columnsToShow={[0, -1, -2]}
        counterVariant="decimalCounter"
        headerVariant="numberTitle"
        questionHeight={800}
        actionPanelVariant="end"
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'aOm',
  description: 'aOm',
  keywords: ['Hundredths', 'Tenths', 'Exchange'],
  schema: z.object({
    tenths1: z.number().int().min(1).max(9),
    tenths2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const [tenths1, tenths2] = randomUniqueIntegersInclusive(1, 9, 2);

    return { tenths1, tenths2 };
  },
  Component: props => {
    const {
      question: { tenths1, tenths2 },
      translate
    } = props;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeSentences()}
        testCorrect={[[(tenths1 * 10).toString()], [(tenths2 * 10).toString()]]}
        sentences={[
          translate.answerSentences.xCanBeExchangedForHundredths(
            `${tenths1.toLocaleString()} ${translate.fractions.tenths(tenths1)}`
          ),
          translate.answerSentences.xCanBeExchangedForHundredths(
            `${tenths2.toLocaleString()} ${translate.fractions.tenths(tenths2)}`
          )
        ]}
        textStyle={{ fontSize: 32 }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aOn',
  description: 'aOn',
  keywords: ['Hundredths', 'Tenths', 'Equivalent'],
  schema: z.object({
    tenths1: z.number().int().min(1).max(9),
    hundredths1: z.number().int().min(1).max(9),
    tenths2: z.number().int().min(1).max(9),
    hundredths2: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const [tenths1, tenths2] = randomUniqueIntegersInclusive(1, 9, 2);
    const [hundredths1, hundredths2] = randomUniqueIntegersInclusive(1, 9, 2);

    return { tenths1, tenths2, hundredths1, hundredths2 };
  },
  Component: props => {
    const {
      question: { tenths1, tenths2, hundredths1, hundredths2 },
      translate,
      displayMode
    } = props;

    const number1 = number(math.evaluate(`${tenths1} * 10 + ${hundredths1}`));
    const number2 = number(math.evaluate(`${tenths2} * 10 + ${hundredths2}`));

    return (
      <QF2AnswerBoxManySentences
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        title={translate.instructions.completeSentences()}
        actionPanelVariant="bottomTall"
        inputMaxCharacters={3}
        testCorrect={userAnswer =>
          userAnswer[0][0] === number1.toString() &&
          (Number(userAnswer[1][0]) * 10 + Number(userAnswer[1][1])).toString() ===
            number2.toString()
        }
        sentences={[
          translate.answerSentences.xAndYEquivalentToAnsHundredths(
            `${tenths1.toLocaleString()} ${translate.fractions.tenths(tenths1)}`,
            `${hundredths1.toLocaleString()} ${translate.fractions.hundredths(hundredths1)}`
          ),
          translate.answerSentences.ansTenthsAnsHundrethsEquivalentToX(
            `${number2.toLocaleString()} ${translate.fractions.hundredths(number2)}`
          )
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: [[number1.toString()], [tenths2.toString(), hundredths2.toString()]],
          answerText: translate.markScheme.anyValidPartitions()
        }}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aOo',
  description: 'aOo',
  keywords: ['Hundredths', 'Part-whole model', 'Partition'],
  schema: z.object({
    number1: z.number().min(0.21).max(9.99),
    number2: z.number().min(0.21).max(9.99),
    part: z.enum(['partA', 'partB']),
    variation: z.enum(['topDown', 'bottomUp', 'leftRight'])
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const part = getRandomFromArray(['partA', 'partB'] as const);
    const variation = getRandomFromArray(['topDown', 'bottomUp', 'leftRight'] as const);

    const { number1, number2 } = rejectionSample(
      () => {
        const randomPower = getRandomFromArray([0, -1, -2] as const);
        const number1 = randomIntegerInclusive(22, 999) / 100;
        const number2Random =
          randomIntegerInclusive(22, 999, {
            constraint: x => x / 100 !== number1
          }) / 100;

        const number2 = numberWithEnforcedDigit(
          number2Random,
          randomPower,
          ScientificNotation.fromNumber(number1).unsignedDigitAt(randomPower)
        );
        return { number1, number2 };
      },
      val => val.number1 !== val.number2 && val.number2 >= 0.21
    );

    return { part, variation, number1, number2 };
  },
  Component: ({ question, translate, displayMode }) => {
    const { part, number1, number2, variation } = question;

    const maxNumber = Math.max(number1, number2);
    const givenPart = Math.min(number1, number2);
    const number3 = maxNumber - givenPart;

    const partition = part === 'partA' ? ['$ans', givenPart] : [givenPart, '$ans'];

    return (
      <QF3InteractiveContent
        title={translate.instructions.completePartWholeModel()}
        initialState={displayMode === 'markscheme' ? [number3.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={answer => compareFloats(answer[0], number3)}
        inputType="numpad"
        extraSymbol="decimalPoint"
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={maxNumber}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            partition={partition}
            variation={variation}
            isInteractive
            dimens={{ height: dimens.height, width: dimens.width * 0.8 }}
          />
        )}
        questionHeight={1200}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aOp',
  description: 'aOp',
  keywords: ['Decimals', 'Divide by 10', '2-digit'],
  schema: z.object({
    number1: z.number().int().min(1).max(99),
    number2: z.number().int().min(1).max(99),
    number3: z.number().int().min(1).max(9),
    number4: z.number().int().min(1).max(99),
    number5: z.number().int().min(1).max(99)
  }),
  simpleGenerator: () => {
    const oneAndTwoEqual = getRandomBoolean();
    const number1 = randomIntegerInclusive(1, 99);
    const number2 = oneAndTwoEqual ? number1 : randomIntegerInclusive(1, 99);
    const number3 = randomIntegerInclusive(1, 9);
    const number4 = randomIntegerInclusive(1, 99);
    const number5 = !oneAndTwoEqual ? number4 : randomIntegerInclusive(1, 99);

    return {
      number1,
      number2,
      number3,
      number4,
      number5
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5 },
      translate,
      displayMode
    } = props;

    const display1 = number1 / 100;
    const display3A = number3 / 10;
    const display3B = number3 / 100;
    const display5 = number5 / 100;

    const statements = [
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'right' }}
          >
            {display1.toLocaleString()}
          </Text>
        ),
        rhsComponent: (
          <View
            style={{ width: displayMode === 'digital' ? 280 : 360, justifyContent: 'flex-end' }}
          >
            <TextStructure sentence={`<frac n="${number2}" d="100" />`} />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(number1, number2)
      },
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'right' }}
          >
            {display3A.toLocaleString()}
          </Text>
        ),
        rhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'left' }}
          >
            {display3B.toLocaleString()}
          </Text>
        ),
        correctAnswer: '>'
      },
      {
        lhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'right' }}
          >{`${number4.toLocaleString()} ${translate.fractions.hundredths(number4)}`}</Text>
        ),
        rhsComponent: (
          <Text
            variant="WRN400"
            style={{ width: displayMode === 'digital' ? 280 : 360, textAlign: 'left' }}
          >
            {display5.toLocaleString()}
          </Text>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(number4, number5)
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompleteStatements()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompleteStatements()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HundredthsOnAPlaceValueChart',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
