import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numberEnum } from '../../../../utils/zod';
import { getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import { getCharacterHeadImage, characterNameLabel } from '../../../../utils/characters';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { filledArray } from '../../../../utils/collections';
import { colors } from '../../../../theme/colors';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { AssetSvg } from '../../../../assets/svg';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import SpeechBubble from '../../../../components/molecules/SpeechBubble';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'auu',
  description: 'auu',
  keywords: ['Time', 'Minutes', 'Seconds', 'Digital'],
  schema: z
    .object({
      secondsA: z.number().int().min(10).max(50).multipleOf(10),
      secondsB: z.number().int().min(10).max(50).multipleOf(10)
    })
    .refine(val => val.secondsB !== val.secondsA, 'secondsB must be different to secondsA'),
  simpleGenerator: () => {
    const [secondsA, secondsB] = randomUniqueIntegersInclusiveStep(10, 50, 10, 2);

    return {
      secondsA,
      secondsB
    };
  },
  Component: props => {
    const {
      question: { secondsA, secondsB },
      translate
    } = props;

    const minute = 1;
    const secondsC = secondsA / 10;

    // Statements
    const statements = [
      {
        statement: translate.answerSentences.xMinutesAndYSeconds(minute, secondsA),
        value: 'A'
      },
      {
        statement: translate.answerSentences.xMinutesAndYSeconds(minute, secondsB),
        value: 'B'
      },
      {
        statement: translate.answerSentences.xMinutesAndYSeconds(minute, secondsC),
        value: 'C'
      }
    ];

    const answerOptions = [
      {
        component: `00:0${minute}:${secondsA}`,
        value: 'A'
      },
      {
        component: `00:0${minute}:${secondsB}`,
        value: 'B'
      },
      {
        component: `00:0${minute}:0${secondsC}`,
        value: 'C'
      }
    ];

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsMatchTimes()}
        pdfTitle={translate.instructions.useCardsMatchTimes()}
        items={shuffledAnswerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement} <ans/>`)}
        testCorrect={statements.map(({ value }) => [value])}
        pdfLayout="itemsRight"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'auv',
  description: 'auv',
  keywords: ['Time', 'Minutes', 'Seconds', 'Digital', 'Bar model'],
  schema: z.object({
    minutes: z.number().int().min(1).max(4),
    seconds: z.number().int().min(1).max(19)
  }),
  simpleGenerator: () => {
    const minutes = randomIntegerInclusive(1, 4);
    const seconds = randomIntegerInclusive(1, 19);

    return { minutes, seconds };
  },
  Component: props => {
    const {
      question: { minutes, seconds },
      translate,
      displayMode
    } = props;

    // Create array filled with 60 * how many minutes for e.g [60, 60, 60]
    const minutesTotal = filledArray(60, minutes);
    // If seconds is less than 15 force higher number to prevent small bar model width
    const secondsValue = seconds < 15 ? 15 : seconds;

    // Create numbers array which calculates the width of the cells in the bar model
    const numbers = [[...minutesTotal, secondsValue]];

    // Convert minutesTotal to array of strings
    const minutesTotalToStr = minutesTotal.map(minute => minute.toLocaleString());

    // Create numbers string array for the bar model cell values
    const numbersStr = [[...minutesTotalToStr, seconds.toLocaleString()]];

    // Answer
    const answer = minutes * 60 + seconds;

    // Cell colors
    const minuteColors = filledArray(colors.pacificBlue600, minutes);
    const secondColors = colors.acidGreen;
    const cellMinuteAndSecondColors = [[...minuteColors, secondColors]];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.instructions.useTheBarModelToHelpYouConvertXMinutesAndYSecondsIntoSeconds(
          minutes,
          seconds
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.xMinutesAndYSecondsEqualsAnsSeconds(minutes, seconds)}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            strings={numbersStr}
            maxFontSize={displayMode === 'digital' ? 32 : 50}
            total={60 * minutes + secondsValue}
            dimens={dimens}
            cellColors={cellMinuteAndSecondColors}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'auw',
  description: 'auw',
  keywords: ['Time', 'Minutes', 'Seconds'],
  schema: z.object({
    minutes: numberEnum([2, 3, 4, 5, 10]),
    seconds: z.number().int().min(1).max(30)
  }),
  simpleGenerator: () => {
    const minutes = getRandomFromArray([2, 3, 4, 5, 10] as const);

    const seconds = randomIntegerInclusive(1, 30);

    return { minutes, seconds };
  },

  Component: props => {
    const {
      question: { minutes, seconds },
      translate
    } = props;

    const totalSeconds = minutes * 60 + seconds;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeStatement()}
        testCorrect={[seconds.toString()]}
        sentence={translate.answerSentences.xMinutesAndAnsSecondsEqualsYSeconds(
          minutes,
          totalSeconds
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aux',
  description: 'aux',
  keywords: ['Time', 'Minutes', 'Seconds'],
  schema: z.object({
    itemMinutes: z.number().int().min(1).max(5),
    itemSeconds: z.number().int().min(1).max(30),
    itemSeconds2: z.number().int().min(61).max(199)
  }),
  simpleGenerator: () => {
    const itemMinutes = randomIntegerInclusive(1, 5);
    const itemSeconds2 = randomIntegerInclusive(61, 199);
    const itemSeconds = randomIntegerInclusive(1, 30);

    return {
      itemMinutes,
      itemSeconds,
      itemSeconds2
    };
  },
  Component: props => {
    const {
      question: { itemMinutes, itemSeconds, itemSeconds2 },
      translate
    } = props;

    // Statements
    const statements = shuffle(
      [
        {
          sentence: translate.answerSentences.xMinutesAndYSecondsAnsZSeconds(
            itemMinutes,
            itemSeconds,
            itemSeconds2
          ),
          answer: lessThanGreaterThanOrEqualTo(itemMinutes * 60 + itemSeconds, itemSeconds2)
        },
        {
          sentence: translate.answerSentences.xSecondsAnsYMinutesAndZSeconds(
            itemSeconds2,
            itemMinutes,
            itemSeconds
          ),
          answer: lessThanGreaterThanOrEqualTo(itemSeconds2, itemMinutes * 60 + itemSeconds)
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardCompareTimes()}
        pdfTitle={translate.instructions.useCardsCompareTimes()}
        pdfLayout="itemsHidden"
        items={['<', '>', '=']}
        sentence={statements[0].sentence}
        testCorrect={[statements[0].answer]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'auy',
  description: 'auy',
  keywords: ['Time', 'Minutes', 'Seconds'],
  schema: z.object({
    minutes: z.number().int().min(2).max(3),
    seconds: z.number().int().min(41).max(59)
  }),
  simpleGenerator: () => {
    const minutes = randomIntegerInclusive(2, 3);
    const seconds = randomIntegerInclusive(41, 59, {
      constraint: x => x % 10 !== 0
    });

    return { minutes, seconds };
  },
  Component: props => {
    const {
      question: { minutes, seconds },
      translate
    } = props;

    const imageSeconds = minutes * 60 + seconds;

    // Answer
    const answerMinutes = Math.floor(imageSeconds / 60);
    const answerSeconds = imageSeconds - answerMinutes * 60;

    return (
      <QF1ContentAndSentence
        sentence={'<ans /> minutes and <ans /> seconds'}
        title={translate.instructions.howLongDoesTheRollercoasterRideLastInMinutesAndSeconds()}
        testCorrect={[answerMinutes.toString(), answerSeconds.toString()]}
        Content={
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            <AssetSvg width={300} height={300} name={'Roller_coaster_ad_duration'} />
            <Text style={{ color: colors.black, fontSize: 28, bottom: 60 }}>
              {translate.answerSentences.xSeconds(imageSeconds.toLocaleString())}
            </Text>
          </View>
        }
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'auz',
  description: 'auz',
  keywords: ['Time', 'Minutes', 'Seconds'],
  schema: z
    .object({
      nameA: nameSchema,
      nameB: nameSchema,
      nameC: nameSchema,
      nameATime: z.number().int().min(101).max(239),
      nameBTime: z.number().int().min(121).max(239),
      nameCSecondsFasterThanNameA: z.number().int().min(10).max(20).multipleOf(10)
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.nameATime,
          val.nameBTime,
          val.nameATime - val.nameCSecondsFasterThanNameA
        ]),
      'All calculated times must be different.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.nameA, val.nameB, val.nameC]),
      'Names must all be different.'
    ),
  simpleGenerator: () => {
    const [nameA, nameB, nameC] = getRandomUniqueNames(3);

    const nameATime = randomIntegerInclusive(101, 239, {
      constraint: x => x % 60 !== 0
    });

    const nameCSecondsFasterThanNameA = randomIntegerInclusiveStep(10, 20, 10);

    const nameBTime = randomIntegerInclusive(121, 239, {
      constraint: x => x !== nameATime && x !== nameATime - nameCSecondsFasterThanNameA
    });

    return { nameA, nameB, nameC, nameATime, nameBTime, nameCSecondsFasterThanNameA };
  },
  Component: props => {
    const {
      question: { nameA, nameB, nameC, nameATime, nameBTime, nameCSecondsFasterThanNameA },
      translate
    } = props;

    const nameCTime = nameATime - nameCSecondsFasterThanNameA;

    const nameBSeconds = nameBTime % 60;

    const nameBMinutes = (nameBTime - nameBSeconds) / 60;

    const statements = [
      {
        name: nameA,
        sentence: translate.answerSentences.iDidItInNumSeconds(nameATime),
        value: nameATime
      },
      {
        name: nameB,
        sentence: translate.answerSentences.iDidItInNumMinutesAndNumSeconds(
          nameBMinutes,
          nameBSeconds
        ),
        value: nameBTime
      },
      {
        name: nameC,
        sentence: translate.answerSentences.iDidItNumSecondsFasterThanChar(
          nameCSecondsFasterThanNameA,
          nameA
        ),
        value: nameCTime
      }
    ];
    const shuffledStatements = shuffle(statements, {
      random: seededRandom(props.question)
    });

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.charactersAreDoingAPuzzle(nameA, nameB, nameC)}
        pdfTitle={translate.instructions.charactersAreDoingAPuzzlePDF(nameA, nameB, nameC)}
        testCorrect={[Math.min(nameATime, nameBTime, nameCTime)]}
        numItems={3}
        renderItems={({ dimens }) => {
          return shuffledStatements.map(({ name, sentence, value }) => ({
            value,
            component: (
              <View style={{ flexDirection: 'row', width: '100%' }}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <SpeechBubble
                    flickLocation="top-right"
                    style={{
                      maxWidth: 0.5 * dimens.width,
                      maxHeight: 0.5 * dimens.height,
                      top: 20
                    }}
                  >
                    {sentence}
                  </SpeechBubble>
                </View>
                <View
                  style={{ rowGap: 10, justifyContent: 'center', marginLeft: 10, marginRight: 20 }}
                >
                  {getCharacterHeadImage(name, dimens.height / 2, dimens.width / 3 - 20)}
                  {characterNameLabel(name, dimens.width / 3 - 20)}
                </View>
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MinutesAndSeconds',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
