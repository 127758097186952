import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { barModelColors, colors } from '../../../../theme/colors';
import { countRange, filledArray } from '../../../../utils/collections';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { ADD, SUB } from '../../../../constants';
import { compareFractions, fractionToDecimal } from '../../../../utils/fractions';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aNg',
  description: 'aNg',
  keywords: ['Subtraction', 'Parts', 'Whole', 'Fraction', 'Numerator', 'Denominator'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(8),
      numerator1: z.number().int().min(2).max(7),
      numerator2: z.number().int().min(1).max(6),
      shadedIndexes: z.array(z.number().int().min(0).max(7)),
      crossedOutIndexes: z.array(z.number().int().min(0).max(7))
    })
    .refine(val => val.numerator1 < val.denominator, 'numerator1 must be less than denominator.')
    .refine(val => val.numerator2 < val.denominator, 'numerator2 must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator1 = randomIntegerInclusive(2, denominator - 1);
    const numerator2 = randomIntegerInclusive(1, numerator1 - 1);
    const shadedIndexes = randomUniqueIntegersInclusive(0, denominator - 1, numerator1);
    const crossedOutIndexes = getRandomSubArrayFromArray(shadedIndexes, numerator2);
    return { denominator, numerator1, numerator2, shadedIndexes, crossedOutIndexes };
  },
  Component: props => {
    const {
      question: { denominator, numerator1, numerator2, shadedIndexes, crossedOutIndexes },
      translate
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barModelArray = countRange(denominator).map(i =>
      shadedIndexes.includes(i) ? numeratorColor : 'white'
    );

    return (
      <QF1ContentAndSentence
        title={translate.instructions.useTheBarModelToHelpCompleteTheSubtraction()}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={denominator}
            width={dimens.width}
            height={dimens.height}
            customColorMap={barModelArray}
            strikeThroughIndexes={crossedOutIndexes}
          />
        )}
        sentence={`<frac n='${numerator1}' d='${denominator}' /> ${SUB} <frac n='${numerator2}' d='${denominator}' /> = <frac nAns='' dAns='' />`}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (numerator1 - numerator2).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
        inputMaxCharacters={1}
        testCorrect={answer =>
          compareFractions([answer[0], answer[1]], [numerator1 - numerator2, denominator])
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aNh',
  description: 'aNh',
  keywords: ['Subtraction', 'Parts', 'Whole', 'Fraction', 'Numerator', 'Denominator', 'Bar model'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(8),
      numerator1: z.number().int().min(2).max(7),
      numerator2: z.number().int().min(1).max(6),
      addOrSub: z.array(z.enum([ADD, SUB])).length(6),
      incorrectOptionIndexes: z.array(z.number().int().min(0).max(5)).length(3)
    })
    .refine(val => val.numerator1 < val.denominator, 'numerator1 must be less than denominator.')
    .refine(val => val.numerator2 < val.denominator, 'numerator2 must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator1 = randomIntegerInclusive(2, denominator - 1);
    const numerator2 = randomIntegerInclusive(1, numerator1 - 1);
    const addOrSub = countRange(6).map(() => getRandomFromArray([ADD, SUB] as const));
    const incorrectOptionIndexes = randomUniqueIntegersInclusive(0, 5, 3);

    return { denominator, numerator1, numerator2, addOrSub, incorrectOptionIndexes };
  },
  Component: props => {
    const {
      question: { denominator, numerator1, numerator2, addOrSub, incorrectOptionIndexes },
      translate,
      displayMode
    } = props;

    const numeratorColor =
      displayMode === 'digital'
        ? (getRandomFromArray(Object.values(barModelColors), {
            random: seededRandom(props.question)
          }) as string)
        : colors.pdfShading;

    const incorrectOptions = [
      addOrSub[0] === ADD
        ? [denominator + 1, numerator1, numerator2]
        : [denominator - 1, numerator1, numerator2],
      addOrSub[1] === ADD
        ? [denominator, numerator1 + 1, numerator2]
        : [denominator, numerator1 - 1, numerator2],
      addOrSub[2] === ADD
        ? [denominator, numerator1, numerator2 + 1]
        : [denominator, numerator1, numerator2 - 1],
      addOrSub[3] === ADD
        ? [denominator + 1, numerator1 + 1, numerator2]
        : [denominator - 1, numerator1 - 1, numerator2],
      addOrSub[4] === ADD
        ? [denominator + 1, numerator1, numerator2 + 1]
        : [denominator - 1, numerator1, numerator2 - 1],
      addOrSub[5] === ADD
        ? [denominator, numerator1 + 1, numerator2 + 1]
        : [denominator, numerator1 - 1, numerator2 - 1]
    ].filter((_x, idx) => incorrectOptionIndexes.includes(idx));

    const shadedBarModel = (
      denominator: number,
      numerator1: number,
      numerator2: number,
      width: number
    ) => {
      return (
        <ShadedFractionBarModel
          totalSubSections={denominator}
          height={displayMode === 'digital' ? 50 : 100}
          width={width * 0.9}
          customColorMap={countRange(denominator).map(i =>
            i < numerator1 ? numeratorColor : 'white'
          )}
          strikeThroughIndexes={countRange(numerator2, numerator1 - numerator2)}
        />
      );
    };

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectRepresentationThatMatchesSubtraction()}
        pdfTitle={translate.instructions.circleRepresentationThatMatchesSubtraction()}
        testCorrect={['A']}
        numItems={4}
        Content={
          <TextStructure
            sentence={`<frac n='${numerator1}' d='${denominator}' /> ${SUB} <frac n='${numerator2}' d='${denominator}' /> = <frac n='${
              numerator1 - numerator2
            }' d='${denominator}' />`}
          />
        }
        renderItems={({ dimens }) =>
          shuffle(
            [
              {
                component: shadedBarModel(denominator, numerator1, numerator2, dimens.width),
                value: 'A'
              },
              ...incorrectOptions.map((x, idx) => {
                return {
                  component: shadedBarModel(x[0], x[1], x[2], dimens.width),
                  value: `${idx}`
                };
              })
            ],
            { random: seededRandom(props.question) }
          )
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'aNi',
  description: 'aNi',
  keywords: ['Subtraction', 'Difference', 'Fraction', 'Numerator', 'Denominator'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(8),
      numerator1: z.number().int().min(2).max(7),
      numerator2: z.number().int().min(1).max(6),
      addOrSub: z.enum([ADD, SUB]),
      incorrectAnswerIndexes: z.array(z.number().int().min(1).max(7)).length(3)
    })
    .refine(val => val.numerator1 < val.denominator, 'numerator1 must be less than denominator.')
    .refine(val => val.numerator2 < val.denominator, 'numerator2 must be less than denominator.'),
  simpleGenerator: () => {
    const { denominator, numerator1, numerator2, addOrSub, uniqueOptions, optionsArray } =
      rejectionSample(
        () => {
          const denominator = randomIntegerInclusive(3, 8);
          const numerator1 = randomIntegerInclusive(2, denominator - 1);
          const numerator2 = randomIntegerInclusive(1, numerator1 - 1);
          const addOrSub = getRandomFromArray([ADD, SUB] as const);
          const optionsArray = [
            numerator1 - numerator2,
            numerator1,
            addOrSub === ADD || numerator1 - numerator2 === 1
              ? numerator1 - numerator2 + 1
              : numerator1 - numerator2 - 1,
            denominator - numerator2,
            numerator2,
            denominator - numerator1,
            denominator - numerator1 + numerator2,
            (numerator1 - numerator2) / 2
          ];
          const uniqueOptions = optionsArray.filter((item, i, ar) => ar.indexOf(item) === i);

          return { denominator, numerator1, numerator2, addOrSub, uniqueOptions, optionsArray };
        },
        // get at least 4 unique options
        ({ uniqueOptions }) => uniqueOptions.length >= 4
      );

    const unqiueIndexes = uniqueOptions.map(u => optionsArray.indexOf(u));
    // remove the correct answer
    unqiueIndexes.shift();
    const incorrectAnswerIndexes = getRandomSubArrayFromArray(unqiueIndexes, 3);

    return { denominator, numerator1, numerator2, addOrSub, incorrectAnswerIndexes };
  },
  Component: props => {
    const {
      question: { denominator, numerator1, numerator2, addOrSub, incorrectAnswerIndexes },
      translate
    } = props;

    const incorrectOptions = [
      [numerator1, denominator],
      [
        addOrSub === ADD || numerator1 - numerator2 === 1
          ? numerator1 - numerator2 + 1
          : numerator1 - numerator2 - 1,
        denominator
      ],
      [denominator - numerator2, denominator],
      [numerator2, denominator],
      [denominator - numerator1, denominator],
      [denominator - numerator1 + numerator2, denominator],
      [numerator1 - numerator2, denominator * 2]
    ].filter((_x, i) => incorrectAnswerIndexes.includes(i + 1));

    const answerOptions = shuffle(
      [
        {
          component: (
            <TextStructure
              sentence={`<frac n='${numerator1 - numerator2}' d='${denominator}' />`}
              textStyle={{ fontSize: 40, fontWeight: '700' }}
              fractionTextStyle={{ fontSize: 40, fontWeight: '700' }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: fractionToDecimal(numerator1 - numerator2, denominator)
        },
        ...incorrectOptions.map(x => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${x[0]}' d='${x[1]}' />`}
                textStyle={{ fontSize: 40, fontWeight: '700' }}
                fractionTextStyle={{ fontSize: 40, fontWeight: '700' }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value: fractionToDecimal(x[0], x[1])
          };
        })
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheDifferenceBetweenTheFractionsUseBarModelToHelp()}
        pdfTitle={translate.instructions.selectTheDifferenceBetweenTheFractionsUseBarModelToHelpPDF()}
        testCorrect={[fractionToDecimal(numerator1 - numerator2, denominator)]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', gap: 30, alignItems: 'center' }}>
            <View
              style={{
                height: dimens.height,
                alignItems: 'center'
              }}
            >
              <ShadedFractionBarModel
                totalSubSections={denominator}
                width={dimens.width / 3}
                height={dimens.height / 3}
                coloredSections={countRange(numerator1)}
              />
              <ShadedFractionBarModel
                totalSubSections={denominator}
                width={dimens.width / 3}
                height={dimens.height / 3}
                coloredSections={countRange(numerator2)}
              />
            </View>
            <TextStructure
              sentence={`<frac n='${numerator1}' d='${denominator}' /> ${SUB} <frac n='${numerator2}' d='${denominator}' />`}
            />
          </View>
        )}
        renderItems={answerOptions}
        itemLayout="row"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aNj',
  description: 'aNj',
  keywords: [
    'Bar model',
    'Addition',
    'Total',
    'Parts',
    'Whole',
    'Fraction',
    'Numerator',
    'Denominator',
    'Integer',
    'Mixed number',
    'Improper fraction',
    'Convert'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(8),
      numerator1: z.number().int().min(4).max(15),
      numerator2: z.number().int().min(1).max(14),
      addOrSub: z.enum([ADD, SUB]),
      incorrectAnswerIndexes: z.array(z.number().int().min(0).max(8)).length(3)
    })
    .refine(val => val.numerator2 < val.numerator1, 'The numerator2 must be less than numerator1.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 8);
    const numerator1 = randomIntegerInclusive(denominator + 1, denominator * 2 - 1);
    const numerator2 = randomIntegerInclusive(1, numerator1 - 1);
    const addOrSub = getRandomFromArray([ADD, SUB] as const);
    const incorrectAnswerIndexes = randomUniqueIntegersInclusive(0, 8, 3);
    return { denominator, numerator1, numerator2, incorrectAnswerIndexes, addOrSub };
  },
  Component: props => {
    const {
      question: { denominator, numerator1, numerator2, incorrectAnswerIndexes, addOrSub },
      translate,
      displayMode
    } = props;
    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const barModelArrays = [
      filledArray(numeratorColor, denominator),
      [
        ...filledArray(numeratorColor, numerator1 % denominator),
        ...filledArray('white', denominator - (numerator1 % denominator))
      ]
    ];

    const numerator1Incorrect = addOrSub === ADD ? numerator1 + 1 : numerator1 - 1;
    const numerator2Incorrect = addOrSub === ADD ? numerator2 + 1 : numerator2 - 1;
    const denominatorIncorrect = addOrSub === ADD ? denominator + 1 : denominator - 1;

    const incorrectOptions = [
      [numerator2, numerator1, denominator, numerator1 - numerator2],
      [numerator1 * 2, numerator2, denominator, numerator1 - numerator2],
      [numerator1, numerator2, denominatorIncorrect, numerator1 - numerator2],
      [numerator1Incorrect, numerator2, denominator, numerator1 - numerator2],
      [numerator1, numerator2Incorrect, denominator, numerator1 - numerator2],
      [numerator1Incorrect, numerator2Incorrect, denominator, numerator1 - numerator2],
      [numerator2, numerator1Incorrect, denominator, numerator1 - numerator2],
      [numerator1, numerator2Incorrect, denominator * 2, numerator1 - numerator2],
      [denominator * 2, denominator * 2 - numerator1, denominator, denominator * 2 - numerator1]
    ].filter((_x, i) => incorrectAnswerIndexes.includes(i));

    const answerOptions = shuffle(
      [
        {
          component: (
            <TextStructure
              sentence={`<frac n='${numerator1}' d='${denominator}' /> ${SUB} <frac n='${numerator2}' d='${denominator}' /> = <frac n='${
                numerator1 - numerator2
              }' d='${denominator}' />`}
              textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50, fontWeight: '700' }}
              fractionTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                fontWeight: '700'
              }}
              fractionDividerStyle={{ marginVertical: 2 }}
            />
          ),
          value: 'A'
        },
        ...incorrectOptions.map((x, i) => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${x[0]}' d='${x[2]}' /> ${SUB} <frac n='${x[1]}' d='${x[2]}' /> = <frac n='${x[3]}' d='${x[2]}' />`}
                textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50, fontWeight: '700' }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  fontWeight: '700'
                }}
                fractionDividerStyle={{ marginVertical: 2 }}
              />
            ),
            value: `${i}`
          };
        })
      ],
      { random: seededRandom(props.question) }
    );
    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardToMatchCalcToRep()}
        pdfTitle={translate.instructions.useCardsToMatchCalcToRep()}
        items={answerOptions}
        sentencesStyle={{ alignItems: 'flex-end' }}
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        pdfItemVariant="tallRectangle"
        Content={({ dimens }) => (
          <View style={{ gap: displayMode === 'digital' ? 8 : 32 }}>
            {barModelArrays.map((array, idx) => (
              <ShadedFractionBarModel
                key={idx}
                totalSubSections={denominator}
                width={dimens.width}
                height={dimens.height / (barModelArrays.length + 1)}
                customColorMap={array}
                strikeThroughIndexes={
                  numerator2 > numerator1 % denominator
                    ? idx === barModelArrays.length - 1
                      ? countRange(numerator1 % denominator)
                      : countRange(
                          numerator2 - (numerator1 % denominator),
                          denominator - (numerator2 - (numerator1 % denominator))
                        )
                    : idx === barModelArrays.length - 1
                    ? countRange(numerator2, (numerator1 % denominator) - numerator2)
                    : undefined
                }
              />
            ))}
          </View>
        )}
        sentence={`<ans/>`}
        testCorrect={['A']}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'aNk',
  description: 'aNk',
  keywords: ['Subtraction', 'Difference', 'Fraction', 'Numerator', 'Denominator'],
  questionHeight: 850,
  schema: z
    .object({
      denominator1: z.number().int().min(3).max(15),
      numerator1A: z.number().int().min(2).max(14),
      numerator1B: z.number().int().min(1).max(13),
      denominator2: z.number().int().min(3).max(12),
      numerator2A: z.number().int().min(4).max(23),
      numerator2B: z.number().int().min(1).max(11),
      isLeft: z.array(z.boolean()).length(2)
    })
    .refine(
      val => val.numerator1A > val.numerator1B,
      'The numerator1B must be less than the numerator1A.'
    )
    .refine(
      val => val.numerator2A > val.numerator2B,
      'The numerator2B must be less than the numerator2A.'
    ),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(3, 15);
    const denominator2 = randomIntegerInclusive(3, 12, { constraint: x => x !== denominator1 });
    const numerator1A = randomIntegerInclusive(2, denominator1 - 1);
    const numerator2A = randomIntegerInclusive(denominator2 + 1, denominator2 * 2 - 1);
    const numerator1B = randomIntegerInclusive(1, numerator1A - 1);
    const numerator2B = randomIntegerInclusive(1, denominator2 - 1);
    const isLeft = countRange(2).map(getRandomBoolean);

    return {
      denominator1,
      denominator2,
      numerator1A,
      numerator1B,
      numerator2A,
      numerator2B,
      isLeft
    };
  },
  Component: props => {
    const {
      question: {
        denominator1,
        denominator2,
        numerator1A,
        numerator1B,
        numerator2A,
        numerator2B,
        isLeft
      },
      translate
    } = props;
    // Sentences
    const sentences = [
      isLeft[0]
        ? `<frac nAns='' dAns='' /> = <frac n='${numerator1A}' d='${denominator1}' /> ${SUB} <frac n='${numerator1B}' d='${denominator1}' />`
        : `<frac n='${numerator1A}' d='${denominator1}' /> ${SUB} <frac n='${numerator1B}' d='${denominator1}' /> = <frac nAns='' dAns='' />`,
      isLeft[1]
        ? `<frac nAns='' dAns='' /> = <frac n='${numerator2A}' d='${denominator2}' /> ${SUB} <frac n='${numerator2B}' d='${denominator2}' />`
        : `<frac n='${numerator2A}' d='${denominator2}' /> ${SUB} <frac n='${numerator2B}' d='${denominator2}' /> = <frac nAns='' dAns='' />`
    ];
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        questionHeight={850}
        testCorrect={userAnswer =>
          compareFractions(userAnswer[0], [numerator1A - numerator1B, denominator1]) &&
          compareFractions(userAnswer[1], [numerator2A - numerator2B, denominator2])
        }
        sentences={sentences}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(numerator1A - numerator1B).toLocaleString(), denominator1.toLocaleString()],
            [(numerator2A - numerator2B).toLocaleString(), denominator2.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aNk2',
  description: 'aNk2',
  keywords: ['Subtraction', 'Difference', 'Fraction', 'Numerator', 'Denominator'],
  questionHeight: 600,
  schema: z
    .object({
      denominator: z.number().int().min(3).max(15),
      numeratorA: z.number().int().min(2).max(23),
      numeratorB: z.number().int().min(1).max(13),
      isLeft: z.boolean()
    })
    .refine(
      val => val.numeratorA > val.numeratorB,
      'The numeratorB must be less than the numeratorA.'
    ),
  simpleGenerator: () => {
    const isOption1 = getRandomBoolean();
    const denominator = randomIntegerInclusive(3, isOption1 ? 15 : 12);
    const numeratorA = isOption1
      ? randomIntegerInclusive(2, denominator - 1)
      : randomIntegerInclusive(denominator + 1, denominator * 2 - 1);
    const numeratorB = isOption1
      ? randomIntegerInclusive(1, numeratorA - 1)
      : randomIntegerInclusive(1, denominator - 1);
    const isLeft = getRandomBoolean();

    return {
      denominator,
      numeratorA,
      numeratorB,
      isLeft
    };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, numeratorB, isLeft },
      translate
    } = props;

    // Sentence
    const sentence = isLeft
      ? `<frac nAns='' dAns='' /> = <frac n='${numeratorA}' d='${denominator}' /> ${SUB} <frac n='${numeratorB}' d='${denominator}' />`
      : `<frac n='${numeratorA}' d='${denominator}' /> ${SUB} <frac n='${numeratorB}' d='${denominator}' /> = <frac nAns='' dAns='' />`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        questionHeight={600}
        testCorrect={userAnswer =>
          compareFractions(userAnswer, [numeratorA - numeratorB, denominator])
        }
        sentence={sentence}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            (numeratorA - numeratorB).toLocaleString(),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aNl',
  description: 'aNl',
  keywords: [
    'Subtraction',
    'Difference',
    'Fraction',
    'Numerator',
    'Denominator',
    'Equivalent',
    'Equal to'
  ],
  questionHeight: 850,
  schema: z
    .object({
      denominator1: z.number().int().min(4).max(15),
      numerator1A: z.number().int().min(2).max(29),
      numerator1B: z.number().int().min(1).max(28),
      denominator2: z.number().int().min(3).max(15),
      numerator2A: z.number().int().min(2).max(14),
      numerator2B: z.number().int().min(1).max(13),
      numerator2C: z.number().int().min(1).max(14),
      answerBoxIndexes: z.array(z.number().int().min(0).max(1)).length(2)
    })
    .refine(
      val => val.numerator1A > val.numerator1B,
      'The numerator1B must be less than the numerator1A.'
    )
    .refine(
      val => val.numerator2A >= val.numerator2B,
      'The numerator2B must be less than the numerator2A.'
    )
    .refine(
      val => val.numerator2C > val.numerator2A - val.numerator2B,
      'The numerator2C must be greater than numerator2A - numerator2B.'
    ),
  simpleGenerator: () => {
    const [denominator1, denominator2] = randomUniqueIntegersInclusive(4, 15, 2);
    const numerator1A = randomIntegerInclusive(2, denominator1 * 2 - 1);
    const numerator1B = randomIntegerInclusive(1, Math.min(denominator1 - 1, numerator1A - 1));
    const numerator2A = randomIntegerInclusive(2, denominator2 - 2);
    const numerator2B = randomIntegerInclusive(1, numerator2A - 1);
    const numerator2C = randomIntegerInclusive(numerator2A + 1, denominator2 - 1, {
      constraint: x => x !== numerator2B
    });
    const answerBoxIndexes = countRange(2).map(() => randomIntegerInclusive(0, 1));

    return {
      denominator1,
      denominator2,
      numerator1A,
      numerator1B,
      numerator2A,
      numerator2B,
      numerator2C,
      answerBoxIndexes
    };
  },
  Component: props => {
    const {
      question: {
        denominator1,
        denominator2,
        numerator1A,
        numerator1B,
        numerator2A,
        numerator2B,
        numerator2C,
        answerBoxIndexes
      },
      translate
    } = props;

    // Sentences
    const sentences = [
      answerBoxIndexes[0] === 0
        ? `<frac nAns='' d='${denominator1}' /> ${SUB} <frac n='${numerator1B}' d='${denominator1}' /> = <frac n='${
            numerator1A - numerator1B
          }' d='${denominator1}' />`
        : `<frac n='${numerator1A}' d='${denominator1}' /> ${SUB} <frac nAns='' d='${denominator1}' /> = <frac n='${
            numerator1A - numerator1B
          }' d='${denominator1}' />`,
      answerBoxIndexes[1] === 0
        ? `<frac n='${numerator2A}' d='${denominator2}' /> ${SUB} <frac n='${numerator2B}' d='${denominator2}' /> = <frac nAns='' d='${denominator2}' /> ${SUB} <frac n='${
            numerator2C - (numerator2A - numerator2B)
          }' d='${denominator2}'/>`
        : `<frac n='${numerator2A}' d='${denominator2}' /> ${SUB} <frac n='${numerator2B}' d='${denominator2}' /> = <frac n='${numerator2C}' d='${denominator2}' /> ${SUB} <frac nAns='' d='${denominator2.toLocaleString()}'/>`
    ];

    const answers = [
      [answerBoxIndexes[0] === 0 ? numerator1A.toString() : numerator1B.toString()],
      [
        answerBoxIndexes[1] === 0
          ? numerator2C.toString()
          : (numerator2C - (numerator2A - numerator2B)).toString()
      ]
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.fillInMissingNumeratorsCompleteNumberSentences()}
        questionHeight={850}
        testCorrect={answers}
        sentences={sentences}
        fractionContainerStyle={{ height: 96 }}
        pdfContainerStyle={{ rowGap: 128 }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aNl2',
  description: 'aNl2',
  keywords: [
    'Subtraction',
    'Difference',
    'Fraction',
    'Numerator',
    'Denominator',
    'Equivalent',
    'Equal to'
  ],
  questionHeight: 600,
  schema: z
    .object({
      denominator: z.number().int().min(4).max(15),
      numeratorParts: z.array(z.number().int().min(1).max(29)).min(2).max(3),
      answerBoxIndex: z.number().int().min(0).max(1)
    })
    .refine(
      val =>
        (val.numeratorParts.length === 2 && val.numeratorParts[0] > val.numeratorParts[1]) ||
        val.numeratorParts.length !== 2,
      'When two numerator parts - numerator2 must be less than numerator1'
    )
    .refine(
      val =>
        (val.numeratorParts.length === 3 &&
          val.numeratorParts[0] >= val.numeratorParts[1] &&
          val.numeratorParts[2] > val.numeratorParts[0] - val.numeratorParts[1]) ||
        val.numeratorParts.length !== 3,
      'The numerator2 must be less than the numerator1 and numerator3 must be greater than numerator1 - numerator2'
    ),
  simpleGenerator: () => {
    const numberOfParts = randomIntegerInclusive(2, 3);
    const denominator = randomIntegerInclusive(4, 15);

    const numeratorParts = (() => {
      if (numberOfParts === 2) {
        const numerator1A = randomIntegerInclusive(2, denominator * 2 - 1);
        const numerator1B = randomIntegerInclusive(1, Math.min(denominator - 1, numerator1A - 1));

        return [numerator1A, numerator1B];
      } else {
        const numerator2A = randomIntegerInclusive(2, denominator - 2);
        const numerator2B = randomIntegerInclusive(1, numerator2A - 1);
        const numerator2C = randomIntegerInclusive(numerator2A + 1, denominator - 1, {
          constraint: x => x !== numerator2B
        });

        return [numerator2A, numerator2B, numerator2C];
      }
    })();

    const answerBoxIndex = randomIntegerInclusive(0, 1);

    return {
      denominator,
      numeratorParts,
      answerBoxIndex
    };
  },
  Component: props => {
    const {
      question: { denominator, numeratorParts, answerBoxIndex },
      translate
    } = props;

    const lastValue =
      numeratorParts.length === 2
        ? numeratorParts[0] - numeratorParts[1]
        : numeratorParts[2] - (numeratorParts[0] - numeratorParts[1]);

    const sentence =
      numeratorParts.length === 2
        ? `${numeratorParts
            .map(
              (numerator, i) =>
                `<frac ${
                  answerBoxIndex === i ? `nAns=''` : `n='${numerator}'`
                } d='${denominator}' />`
            )
            .join(` ${SUB} `)} = <frac n='${lastValue}' d='${denominator}' />`
        : `${countRange(2)
            .map(
              i =>
                `<frac n='${numeratorParts[i]}'
                 d='${denominator}' />`
            )
            .join(` ${SUB} `)} = <frac ${
            answerBoxIndex === 0 ? `nAns=''` : `n='${numeratorParts[2]}'`
          } d='${denominator}' /> ${SUB} <frac ${
            answerBoxIndex === 1 ? `nAns=''` : `n='${lastValue}'`
          } d='${denominator}' />`;

    const answer =
      numeratorParts.length === 2
        ? numeratorParts[answerBoxIndex].toString()
        : [numeratorParts[2], lastValue][answerBoxIndex].toString();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumeratorCompleteNumberSentence()}
        questionHeight={600}
        testCorrect={[answer]}
        sentence={sentence}
        fractionContainerStyle={{ height: 96 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwoFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5v2, Question6v2],
  archivedQuestionTypes: [Question5, Question6]
});
export default SmallStep;
