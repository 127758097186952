import { useContext, useMemo, useRef } from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  SharedValue,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated';

import { ScaleFactorContext } from '../../../theme/scaling';
import { z } from 'zod';
import { withStateHOC } from '../../../stateTree';
import { Circle, Svg } from 'react-native-svg';
import { colors } from '../../../theme/colors';
import { DEGREES_PER_RAD, describeArc, describeSector } from '../../../utils/angles';
import { AssetSvg } from '../../../assets/svg';
import { clockColourVariantType } from '../../../utils/time';
import { AnimatedPath } from '../../atoms/animatedWrappings';

export const Time12hSchema = z.object({
  minutes: z.number().int().min(0).max(59),
  hours: z.number().int().min(0).max(11)
});
export type Time12h = z.infer<typeof Time12hSchema>;

export type Time12hIndependentHands = Time12h & {
  /**
   * Minutes that the hour hand is past the hour.
   * For a valid clock, this should be the same as `minutes`! Defaults to the same as `minutes`.
   */
  hourHandMinutes?: number;
};

export type ClockProps = {
  time?: Time12hIndependentHands;
  onTimeChanged?: (time: Time12hIndependentHands) => void;
  /** Whether the component is interactive, i.e. the hands can be moved. Defaults to true. */
  interactive?: boolean;
  /**
   * (Interactive mode only) whether the hands can be moved independently. Defaults to false.
   *
   * When this is false, moving the minute hand will also affect the hour hand.
   *
   * When this is true, in `onTimeChanged` the property `hourHandMinutes` will be present and  represent the number of
   * minutes past the hour that the hour hand is placed at (possibly different to `minutes`). Additionally, the hour
   * hand can be moved freely without snapping to the nearest valid position.
   */
  independentHands?: boolean;
  /**
   * (Interactive mode only) whether to snap the hands positions to the nearest allowed position whilst dragging.
   * Defaults to true.
   *
   * Note that when `independentHands` is false, the hour hand has only one allowed position each hour, but when
   * `independentHands` is true there are at most 60 allowed positions each hour.
   * This interval can be changed with snapMinutes.
   */
  snapHandsToNearest?: boolean;
  /** (Interactive mode only). Number of minutes to snap to, when using snapping. Should divide 60 evenly.
   *
   * Default: 1, i.e. snap to the nearest minutes. E.g. snapMinutes={5} means that the minute hand snaps to every 5
   * minutes.
   */
  snapMinutes?: number;
  /** Whether the component shows hands, this is mainly used for the PDF. Defaults to true */
  showHands?: boolean;
  /**
   * Boolean to replace the numbers for the clock face's hours with Roman Numerals. Defaults to false.
   */
  isRoman?: boolean;
  /**
   * Boolean to remove minute intervals from the clock face. Defaults to false.
   */
  withoutMinuteIntervals?: boolean;
  /**
   * Information for to showing an angle clockwise, either with an arc or a sector. Default: don't show.
   */
  showAngle?: {
    /** Where to start the angle. Use the strings options to make it track those hands. */
    startMinutes: number | 'hour' | 'minute';
    /**
     * Where to end the angle. Use the strings options to make it track those hands.
     *
     * The angle is always drawn clockwise from startMinutes to endMinutes. For example, if startMinutes=10 and
     * endMinutes=10, this draws an angle covering 50 minutes (a reflex angle of 300 degrees).
     *
     * endMinutes === startMinutes is a special value which draws an angle of 0 degrees. If endMinutes-startMinutes is
     * any other multiple of 60, a full circle is drawn.
     */
    endMinutes: number | 'hour' | 'minute';
    /** How to show the angle. Can be an arc or a sector (shaded arc). Default: arc. */
    variant?: 'arc' | 'sector';
  };
  /**
   * Whether to replace the minute hand with a red circle, or hide the hand altogether. Default: 'hand'.
   */
  minuteHandVariant?: 'hand' | 'circle' | 'absent';
  /**
   * Whether to show or hide the hour hand. Default: 'hand'.
   */
  hourHandVariant?: 'hand' | 'absent';
  /** Make the minute hand undraggable */
  staticMinuteHand?: boolean;
  /** This component is always square: width = height. */
  width: number;
  /** Provide a colour for the outer border of clock */
  clockColourVariant?: clockColourVariantType;
};

/**
 * Manipulative of a clock.
 *
 * This is a controlled component. However, {@link ClockProps.onTimeChanged} is only called when the user releases a
 * hand, finishing the gesture.
 */
export default function Clock({
  time = { hours: 0, minutes: 0 },
  onTimeChanged = () => {
    /* do nothing */
  },
  independentHands = false,
  snapHandsToNearest = true,
  snapMinutes = 1,
  interactive = true,
  showHands = true,
  showAngle,
  isRoman = false,
  withoutMinuteIntervals = false,
  minuteHandVariant = 'hand',
  hourHandVariant = 'hand',
  staticMinuteHand = false,
  width,
  clockColourVariant = 'yellow'
}: ClockProps) {
  const scaleFactor = useContext(ScaleFactorContext);

  // Constants
  // Position the interactive handles on each hand. These fractions were obtained empirically.
  const handleWidth = width * 0.14;
  const minuteHandleOffset = width * 0.28;
  const hourHandleOffset = width * 0.15;

  // Animated value for the time to display
  /** Hours position of the hour hand. */
  const animatedHours = useSharedValue(time.hours);
  /** Minutes position of the hour hand. (Equal to animatedMinutes when independentHands is false.) */
  const animatedHourHandMinutes = useSharedValue(time.hourHandMinutes ?? time.minutes);
  /** Minutes position of the minutes hand. */
  const animatedMinutes = useSharedValue(time.minutes);

  // If `time` changes from outside, update the animated value
  const lastTime = useRef(time);
  if (lastTime.current !== time) {
    lastTime.current = time;
    animatedHours.value = time.hours;
    animatedMinutes.value = time.minutes;
  }

  // Gesture handlers for dragging on the hands' handles - updates animated value
  const minuteHandGesture = useClockGesture(
    'minute',
    animatedHours,
    animatedHourHandMinutes,
    animatedMinutes,
    handleWidth,
    minuteHandleOffset,
    scaleFactor,
    onTimeChanged,
    independentHands,
    snapHandsToNearest,
    snapMinutes
  );
  const hourHandGesture = useClockGesture(
    'hour',
    animatedHours,
    animatedHourHandMinutes,
    animatedMinutes,
    handleWidth,
    hourHandleOffset,
    scaleFactor,
    onTimeChanged,
    independentHands,
    snapHandsToNearest,
    snapMinutes
  );

  // Styles using animated value to show the correct time
  const minuteHandRotationStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: `${minuteHandAngle(animatedMinutes.value)}rad` }]
    };
  }, [animatedMinutes]);
  const hourHandRotationStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { rotate: `${hourHandAngle(animatedHours.value, animatedHourHandMinutes.value)}rad` }
      ]
    };
  }, [animatedHourHandMinutes, animatedHours]);

  /** Props for showing an angle in a <Path> component. */
  const anglePathProps = useAnimatedProps(() => {
    if (!showAngle) return { d: '', fill: 'none' };
    const { variant = 'arc', startMinutes, endMinutes } = showAngle;
    const radius = variant === 'arc' ? width * 0.18 : width * 0.28;

    const hourAngle =
      hourHandAngle(animatedHours.value, animatedHourHandMinutes.value) * DEGREES_PER_RAD;
    const minuteAngle = minuteHandAngle(animatedMinutes.value) * DEGREES_PER_RAD;
    const startDegrees =
      startMinutes === 'hour'
        ? hourAngle
        : startMinutes === 'minute'
        ? minuteAngle
        : startMinutes * 6;
    const endDegrees =
      endMinutes === 'hour' ? hourAngle : endMinutes === 'minute' ? minuteAngle : endMinutes * 6;

    let d: string;
    if (endDegrees !== startDegrees && (endDegrees - startDegrees) % 360 === 0) {
      // The path is a full circle
      d =
        `M${width / 2 + radius},${width / 2} ` +
        `A${radius},${radius} 0 1,0 ${width / 2 - radius},${width / 2} ` +
        `A${radius},${radius} 0 1,0 ${width / 2 + radius},${width / 2} `;
    } else {
      d = (variant === 'arc' ? describeArc : describeSector)(
        width / 2,
        width / 2,
        radius,
        startDegrees,
        endDegrees
      );
    }

    return { d };
  }, [animatedHourHandMinutes, animatedHours, animatedMinutes, showAngle, width]);

  const handHandleStyles = StyleSheet.create({
    handHandle: {
      zIndex: 1,
      position: 'absolute',
      width: handleWidth,
      height: handleWidth,
      borderRadius: 1000,
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center'
    }
  });

  return (
    <View style={{ width, height: width }}>
      {/* Roman clock faces */}
      {isRoman
        ? withoutMinuteIntervals
          ? (() => {
              switch (clockColourVariant) {
                case 'yellow':
                  return <AssetSvg name="ClockFaceRomanYellowWithoutMinuteIntervals" />;
                case 'blue':
                  return <AssetSvg name="ClockFaceRomanBlueWithoutMinuteIntervals" />;
                case 'orange':
                  return <AssetSvg name="ClockFaceRomanOrangeWithoutMinuteIntervals" />;
                default:
                  return null;
              }
            })()
          : (() => {
              switch (clockColourVariant) {
                case 'yellow':
                  return <AssetSvg name="ClockFaceRomanYellow" />;
                case 'blue':
                  return <AssetSvg name="ClockFaceRomanBlue" />;
                case 'orange':
                  return <AssetSvg name="ClockFaceRomanOrange" />;
                default:
                  return null;
              }
            })()
        : null}

      {/* Regular clock faces */}
      {!isRoman
        ? withoutMinuteIntervals
          ? (() => {
              switch (clockColourVariant) {
                case 'yellow':
                  return <AssetSvg name="ClockFaceYellowWithoutMinuteIntervals" />;
                case 'blue':
                  return <AssetSvg name="ClockFaceBlueWithoutMinuteIntervals" />;
                case 'orange':
                  return <AssetSvg name="ClockFaceOrangeWithoutMinuteIntervals" />;
                default:
                  return null;
              }
            })()
          : (() => {
              switch (clockColourVariant) {
                case 'yellow':
                  return <AssetSvg name="ClockFaceYellow" />;
                case 'blue':
                  return <AssetSvg name="ClockFaceBlue" />;
                case 'orange':
                  return <AssetSvg name="ClockFaceOrange" />;
                default:
                  return null;
              }
            })()
        : null}

      {showAngle && (
        <Svg width={width} height={width} style={StyleSheet.absoluteFill}>
          <>
            <AnimatedPath
              animatedProps={anglePathProps}
              stroke={colors.prussianBlue}
              fill={(showAngle.variant ?? 'arc') === 'arc' ? 'none' : colors.yellow}
              strokeWidth={2}
            />
            {showAngle.variant === 'sector' && (
              // Awkwardly, we need to add in another center circle, since it's getting covered by the sector
              <Circle cx={width / 2} cy={width / 2} r={width * 0.02371} fill="black" />
            )}
          </>
        </Svg>
      )}
      {/* Minute hand */}
      {showHands && (
        <Animated.View
          style={[StyleSheet.absoluteFill, minuteHandRotationStyle]}
          pointerEvents="box-none"
        >
          <View style={StyleSheet.absoluteFill} pointerEvents="none">
            {(() => {
              switch (minuteHandVariant) {
                case 'hand':
                  return <AssetSvg name="ClockMinute" />;
                case 'circle':
                  return <AssetSvg name="ClockMinuteCircle" />;
                case 'absent':
                  return null;
              }
            })()}
          </View>
          {interactive && minuteHandVariant !== 'absent' && !staticMinuteHand && (
            <View
              pointerEvents="box-none"
              style={[StyleSheet.absoluteFill, { justifyContent: 'center', alignItems: 'center' }]}
            >
              <GestureDetector gesture={minuteHandGesture}>
                <View
                  style={[
                    handHandleStyles.handHandle,
                    { transform: [{ translateY: -minuteHandleOffset }] }
                  ]}
                >
                  <View
                    style={{
                      width: handleWidth / 2,
                      height: handleWidth / 2,
                      borderRadius: 1000,
                      borderWidth: 2,
                      backgroundColor: colors.burntSienna
                    }}
                  />
                </View>
              </GestureDetector>
            </View>
          )}
        </Animated.View>
      )}
      {/* Hour hand */}
      {showHands && (
        <Animated.View
          style={[{ width, height: width, position: 'absolute' }, hourHandRotationStyle]}
          pointerEvents="box-none"
        >
          <View style={StyleSheet.absoluteFill} pointerEvents="none">
            {(() => {
              switch (hourHandVariant) {
                case 'hand':
                  return <AssetSvg name="ClockHour" />;
                case 'absent':
                  return null;
              }
            })()}
          </View>
          {interactive && hourHandVariant !== 'absent' && (
            <View
              pointerEvents="box-none"
              style={[StyleSheet.absoluteFill, { justifyContent: 'center', alignItems: 'center' }]}
            >
              <GestureDetector gesture={hourHandGesture}>
                <Animated.View
                  style={[
                    handHandleStyles.handHandle,
                    { transform: [{ translateY: -hourHandleOffset }] }
                  ]}
                >
                  <View
                    style={{
                      width: handleWidth / 2,
                      height: handleWidth / 2,
                      borderRadius: 1000,
                      borderWidth: 2,
                      backgroundColor: colors.burntSienna
                    }}
                  />
                </Animated.View>
              </GestureDetector>
            </View>
          )}
        </Animated.View>
      )}
    </View>
  );
}

/**
 * Gesture handler for either the minute or the hour hand. Pulled out into a separate function since they share a lot
 * of code.
 */
function useClockGesture(
  type: 'minute' | 'hour',
  animatedHours: SharedValue<number>,
  animatedHourHandMinutes: SharedValue<number>,
  animatedMinutes: SharedValue<number>,
  handleWidth: number,
  handleOffset: number,
  scaleFactor: number,
  onTimeChanged: (time: Time12hIndependentHands) => void,
  independentHands: boolean,
  snapHandsToNearest: boolean,
  snapMinutes: number
) {
  const startPositionFromClockCenter = useSharedValue<{ x: number; y: number } | null>(null);
  const lastMinutes = useSharedValue<number>(animatedMinutes.value);

  // Used to calculate translationX/Y since we don't trust those values from the event. (Scaled.)
  const beginAbsX = useSharedValue<number | null>(null);
  const beginAbsY = useSharedValue<number | null>(null);

  return useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginAbsX.value = event.absoluteX;
          beginAbsY.value = event.absoluteY;

          let { x, y } = event;
          if (Platform.OS === 'web') {
            // On web these values are post-scaling-transformation, so unscale to get back to the Clock's coordinate
            // system
            x /= scaleFactor;
            y /= scaleFactor;
          }

          // Work out where the start position (as given by this event) is relative to the clock center, and store it off
          // in the context.
          // To do this, we use the following vectors:
          // 1) clock center -> handle center
          // 2) origin -> handle center
          // 3) origin -> start position
          // where the origin is (0, 0) in the coordinate system of the event.

          // For (1), we calculate using the handle's rotation around the clock face and its offset.
          const handleAngle =
            type === 'minute'
              ? minuteHandAngle(animatedMinutes.value)
              : hourHandAngle(animatedHours.value, animatedHourHandMinutes.value);
          const handleCenterFromClockCenter = {
            x: Math.sin(handleAngle) * handleOffset,
            y: -(Math.cos(handleAngle) * handleOffset) // Negative because, in canvas coordinates, y points down.
          };

          // For (2), it stands to reason that the origin is the top left of the handle, which is square.
          // However, the handle may have been rotated, and in that case the coordinates actually originate from the top
          // left of the *smallest unrotated square that bounds the handle*. This can be easily calculated from the
          // handle's width and rotation, and the handle's center is in the middle of this box.
          const boundingBoxWidth =
            handleWidth * (Math.abs(Math.sin(handleAngle)) + Math.abs(Math.cos(handleAngle)));
          const handleCenterFromOrigin = {
            x: boundingBoxWidth / 2,
            y: boundingBoxWidth / 2
          };

          // For (3), this is easy - it's just the event's x/y coordinates by definition!
          const startPositionFromOrigin = { x, y };

          // Now we use vector addition/subtraction to get the vector we want.
          startPositionFromClockCenter.value = {
            x: handleCenterFromClockCenter.x - handleCenterFromOrigin.x + startPositionFromOrigin.x,
            y: handleCenterFromClockCenter.y - handleCenterFromOrigin.y + startPositionFromOrigin.y
          };

          // Also store the last minutes we recorded, which we use later to adjust for winding.
          lastMinutes.value = animatedMinutes.value;
        })

        .onUpdate(event => {
          // Calculate translationX/Y manually rather than using the one from the event, since the one from the event
          // is the diff since the second onUpdate, which is wrong because the pointer might have already moved before
          // then!
          const translationX = (event.absoluteX - beginAbsX.value!) / scaleFactor;
          const translationY = (event.absoluteY - beginAbsY.value!) / scaleFactor;

          const currentPositionFromClockCenter = {
            x: startPositionFromClockCenter.value!.x + translationX,
            y: startPositionFromClockCenter.value!.y + translationY
          };

          let angleToCurrentPosition = Math.atan2(
            currentPositionFromClockCenter.x,
            -currentPositionFromClockCenter.y // Negative because, in canvas coordinates, y points down.
          );
          if (angleToCurrentPosition < 0) {
            angleToCurrentPosition += 2 * Math.PI;
          }

          const newTime = {
            hours: animatedHours.value,
            hourHandMinutes: animatedHourHandMinutes.value,
            minutes: animatedMinutes.value
          };

          switch (type) {
            case 'minute': {
              let minutes = angleToCurrentPosition * MINUTES_PER_RAD;
              if (snapHandsToNearest) {
                minutes = Math.round(minutes / snapMinutes) * snapMinutes;
                if (minutes >= 60) {
                  // Rounded up to the next hour
                  minutes -= 60;
                }
              }

              newTime.minutes = minutes;
              if (!independentHands) {
                newTime.hourHandMinutes = minutes;
              }
              break;
            }
            case 'hour': {
              let hours = Math.floor(angleToCurrentPosition * HOURS_PER_RAD);

              const remainder = angleToCurrentPosition * HOURS_PER_RAD - hours;
              let hourHandMinutes = remainder * 60;
              if (snapHandsToNearest) {
                hourHandMinutes = Math.round(hourHandMinutes / snapMinutes) * snapMinutes;
                if (hourHandMinutes >= 60) {
                  // Rounded up to the next hour
                  hourHandMinutes -= 60;
                  hours += 1;
                  hours = hours < 12 ? hours : hours - 12;
                }
              }

              newTime.hours = hours;
              if (independentHands) {
                newTime.hourHandMinutes = hourHandMinutes;
              }
              break;
            }
          }

          if (!independentHands) {
            // Adjust for winding, i.e. passing between hours
            if (lastMinutes.value > 45 && newTime.minutes < 15) {
              // Gone up an hour
              newTime.hours += 1;
              if (newTime.hours >= 12) {
                newTime.hours -= 12;
              }
            }
            if (lastMinutes.value < 15 && newTime.minutes > 45) {
              // Gone down an hour
              newTime.hours -= 1;
              if (newTime.hours < 0) {
                newTime.hours += 12;
              }
            }
          }

          lastMinutes.value = newTime.minutes;
          animatedHours.value = newTime.hours;
          animatedHourHandMinutes.value = newTime.hourHandMinutes;
          animatedMinutes.value = newTime.minutes;
        })

        .onFinalize(() => {
          // The gesture has finished. Update ancestors with new time.
          runOnJS(onTimeChanged)(
            independentHands
              ? {
                  hours: animatedHours.value,
                  hourHandMinutes: animatedHourHandMinutes.value,
                  minutes: animatedMinutes.value
                }
              : { hours: animatedHours.value, minutes: animatedMinutes.value }
          );
        }),
    [
      animatedHourHandMinutes,
      animatedHours,
      animatedMinutes,
      beginAbsX,
      beginAbsY,
      handleOffset,
      handleWidth,
      independentHands,
      lastMinutes,
      onTimeChanged,
      scaleFactor,
      snapHandsToNearest,
      snapMinutes,
      startPositionFromClockCenter,
      type
    ]
  );
}

const MINUTES_PER_RAD = 60 / (2 * Math.PI);
const RADS_PER_MINUTE = 1 / MINUTES_PER_RAD;
const HOURS_PER_RAD = 12 / (2 * Math.PI);
const RADS_PER_HOUR = 1 / HOURS_PER_RAD;

const minuteHandAngle = (minutes: number): number => {
  'worklet';
  return minutes * RADS_PER_MINUTE;
};

const hourHandAngle = (hours: number, minutes: number): number => {
  'worklet';
  return hours * RADS_PER_HOUR + (minutes * RADS_PER_MINUTE) / 12;
};

export const ClockWithState = withStateHOC(Clock, {
  stateProp: 'time',
  setStateProp: 'onTimeChanged',
  defaults: {
    defaultState: { hours: 0, minutes: 0 }
  }
});
