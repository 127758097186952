import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import TextStructure from '../../../../components/molecules/TextStructure';
import { ADD, SUB } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import { getRandomName, getRandomUniqueNames, nameSchema } from '../../../../utils/names';
import { moneyToHighestDenominations, displayMoney } from '../../../../utils/money';
import { View } from 'react-native';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { greatestCommonDivisor } from '../../../../utils/multiples';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import Text from '../../../../components/typography/Text';
import { all, create, number } from 'mathjs';
import { countRange } from '../../../../utils/collections';
import QF2DraggableAlignedEquations from '../../../../components/question/questionFormats/QF2DraggableAlignedEquations';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'as2',
  description: 'as2',
  keywords: ['Fractions', 'Divide', 'Unit', 'Non-unit'],
  schema: z
    .object({
      numberOfChildren: z.number().int().min(12).max(50),
      numerator: z.number().int().min(2).max(9),
      denominator: numberEnum([3, 4, 5, 8, 10])
    })
    .refine(
      val => val.numberOfChildren % val.denominator === 0,
      'numberOfChildren must be a multiple of the denominator'
    )
    .refine(val => val.numerator < val.denominator, 'numerator must be less than the denominator'),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([3, 4, 5, 8, 10] as const);
    const numberOfChildren = randomIntegerInclusive(12, 50, {
      constraint: x => x % denominator === 0
    });
    const numerator = randomIntegerInclusive(2, denominator - 1);

    return { denominator, numberOfChildren, numerator };
  },
  Component: props => {
    const {
      question: { denominator, numberOfChildren, numerator },
      displayMode,
      translate
    } = props;

    // Answers
    const answer1 = (numberOfChildren / denominator) * numerator;
    const answer2 = numberOfChildren - answer1;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.inAClassOfXChildrenFracOfClassHaveBrotherOrSister({
          numOfChildren: numberOfChildren,
          fracOfClass: `<frac n='${numerator}' d='${denominator}' />`
        })}
        testCorrect={[[answer1.toString()], [answer2.toString()]]}
        sentences={[
          `${translate.answerSentences.howManyChildrenHaveABrotherOrSister()} <ans/>`,
          `${translate.answerSentences.howManyChildrenDoNotHaveABrotherOrSister()} <ans/>`
        ]}
        titleFractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        titleTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        textStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        containerStyle={{ alignItems: 'flex-start' }}
        mainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfMainPanelContainerStyle={{ alignSelf: 'flex-start' }}
        pdfContainerStyle={{ alignItems: 'flex-start' }}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'as22',
  description: 'as2',
  keywords: ['Fractions', 'Divide', 'Unit', 'Non-unit'],
  schema: z
    .object({
      numberOfChildren: z.number().int().min(12).max(50),
      numerator: z.number().int().min(2).max(9),
      denominator: numberEnum([3, 4, 5, 8, 10])
    })
    .refine(
      val => val.numberOfChildren % val.denominator === 0,
      'numberOfChildren must be a multiple of the denominator'
    )
    .refine(val => val.numerator < val.denominator, 'numerator must be less than the denominator'),
  simpleGenerator: () => {
    const denominator = getRandomFromArray([3, 4, 5, 8, 10] as const);
    const numberOfChildren = randomIntegerInclusive(12, 50, {
      constraint: x => x % denominator === 0
    });
    const numerator = randomIntegerInclusive(2, denominator - 1);

    return { denominator, numberOfChildren, numerator };
  },
  Component: props => {
    const {
      question: { denominator, numberOfChildren, numerator },
      displayMode,
      translate
    } = props;

    // Answers
    const answer1 = (numberOfChildren / denominator) * numerator;
    const answer2 = numberOfChildren - answer1;

    return (
      <QF1ContentAndSentences
        title={translate.instructions.inAClassOfXChildrenFracOfClassHaveBrotherOrSister({
          numOfChildren: numberOfChildren,
          fracOfClass: `<frac n='${numerator}' d='${denominator}' />`
        })}
        testCorrect={[[answer1.toString()], [answer2.toString()]]}
        sentences={['<ans/>', '<ans/>']}
        titleFractionTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        titleTextStyle={{ fontSize: displayMode === 'digital' ? 28 : 50 }}
        Content={({ dimens }) => (
          <View
            style={{
              alignSelf: 'flex-start',
              height: dimens.height,
              justifyContent: 'space-around',
              width: displayMode === 'digital' ? dimens.width : dimens.width * 1.8
            }}
          >
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 28 : 50,
                top: displayMode === 'digital' ? 22 : 66,
                width: displayMode === 'digital' ? dimens.width : dimens.width * 1.8
              }}
            >
              {translate.answerSentences.howManyChildrenHaveABrotherOrSister()}
            </Text>
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 28 : 50,
                bottom: displayMode === 'digital' ? 6 : -28,
                width: displayMode === 'digital' ? dimens.width : dimens.width * 1.8
              }}
            >
              {translate.answerSentences.howManyChildrenDoNotHaveABrotherOrSister()}
            </Text>
          </View>
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'as3',
  description: 'as3',
  keywords: ['Fractions', 'Divide', 'Unit', 'Non-unit'],
  schema: z
    .object({
      denominator: numberEnum([5, 8, 10]),
      number: z.number().int().min(20).max(99)
    })
    .refine(
      val => val.number % 2 === 0 && val.number % 4 === 0 && val.number % val.denominator === 0,
      'number must be a common multiple of 2, 4 and denominator'
    ),
  simpleGenerator: () => {
    const { denominator, number } = rejectionSample(
      () => {
        const denominator = getRandomFromArray([5, 8, 10] as const);
        const number = randomIntegerInclusive(20, 99);
        return { denominator, number };
      },
      // Only permit if number is a common multiple of 2, 4 and the denominator
      ({ denominator, number }) =>
        number % 2 === 0 && number % 4 === 0 && number % denominator === 0
    );

    return {
      denominator,
      number
    };
  },
  Component: props => {
    const {
      question: { denominator, number },
      translate,
      displayMode
    } = props;

    const numerator = denominator - 1;

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 180 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${numerator}' d='${denominator}' /> of ${number} =`}
            />
          </View>
        ),
        correctAnswer: (number / denominator) * numerator
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 180 : 300, alignItems: 'flex-end' }}>
            <TextStructure sentence={`<frac n='1' d='2' /> of ${number} =`} />
          </View>
        ),
        correctAnswer: number / 2
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 180 : 300, alignItems: 'flex-end' }}>
            <TextStructure sentence={`<frac n='2' d='${denominator}' /> of ${number} =`} />
          </View>
        ),
        correctAnswer: (number / denominator) * 2
      }
    ];

    const answerOptionsANumerator = denominator - numerator;

    const answerOptions = [
      {
        component: (
          <TextStructure
            sentence={`${number.toLocaleString()} ${SUB} <frac n='${answerOptionsANumerator}' d='${denominator}' /> of ${number}`}
            fractionTextStyle={
              displayMode === 'digital'
                ? { fontSize: 28, fontWeight: '700' }
                : { fontSize: 30, lineHeight: 54, fontWeight: '700' }
            }
            fractionDividerStyle={{ marginVertical: 2 }}
            textStyle={
              displayMode === 'digital'
                ? { fontSize: 28, fontWeight: '700' }
                : { fontSize: 30, lineHeight: 54, fontWeight: '700' }
            }
          />
        ),
        value: number - number / denominator
      },
      {
        component: (
          <TextStructure
            sentence={`<frac n='2' d='4' /> of ${number.toLocaleString()}`}
            fractionTextStyle={
              displayMode === 'digital'
                ? { fontSize: 28, fontWeight: '700' }
                : { fontSize: 30, lineHeight: 54, fontWeight: '700' }
            }
            fractionDividerStyle={{ marginVertical: 2 }}
            textStyle={
              displayMode === 'digital'
                ? { fontSize: 28, fontWeight: '700' }
                : { fontSize: 30, lineHeight: 54, fontWeight: '700' }
            }
          />
        ),
        value: (number / 4) * 2
      },
      {
        component: (
          <TextStructure
            sentence={`<frac n='1' d='${denominator}' /> of ${number.toLocaleString()} ${ADD} <frac n='1' d='${denominator}' /> of ${number.toLocaleString()}`}
            fractionTextStyle={
              displayMode === 'digital'
                ? { fontSize: 28, fontWeight: '700' }
                : { fontSize: 30, lineHeight: 54, fontWeight: '700' }
            }
            fractionDividerStyle={{ marginVertical: 2 }}
            textStyle={
              displayMode === 'digital'
                ? { fontSize: 28, fontWeight: '700' }
                : { fontSize: 30, lineHeight: 54, fontWeight: '700' }
            }
          />
        ),
        value: (number / denominator) * 2
      }
    ];

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        items={shuffledAnswerOptions}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'as32',
  description: 'as3',
  keywords: ['Fractions', 'Divide', 'Unit', 'Non-unit'],
  schema: z.object({
    denominators: z.array(numberEnum([2, 4, 5, 8, 10])).length(3),
    number: z.number().int().min(20).max(99),
    isFracInItems: z.array(z.boolean()).length(3)
  }),
  simpleGenerator: () => {
    const { denominators, number } = rejectionSample(
      () => {
        const denominators = getRandomSubArrayFromArray([2, 4, 5, 8, 10] as const, 3);
        const number = randomIntegerInclusive(20, 99);
        return { denominators, number };
      },
      // Only permit if number is a common multiple of all the denominators
      ({ denominators, number }) =>
        number % 2 === 0 &&
        number % 4 === 0 &&
        number % denominators[0] === 0 &&
        number % denominators[1] === 0 &&
        number % denominators[2] === 0
    );

    const isFracInItems = countRange(3).map(() => getRandomBoolean());

    return {
      denominators,
      number,
      isFracInItems
    };
  },
  Component: props => {
    const {
      question: { denominators, number, isFracInItems },
      translate,
      displayMode
    } = props;

    const statements = denominators.map((denominator, index) => {
      return {
        left: translate.answerSentences.fracOfNum(
          `<frac n='${denominator - 1}' d='${denominator}'/>`,
          number
        ),
        right: '<ans/>',
        answer: index
      };
    });

    const answerOptions = shuffle(
      denominators.map((denominator, index) => {
        const answerOptionsANumerator = denominator - (denominator - 1);
        return {
          component: (
            <TextStructure
              sentence={
                isFracInItems[index]
                  ? translate.answerSentences.numSubFracOfNum(
                      number,
                      `<frac n='${answerOptionsANumerator}' d='${denominator}' />`
                    )
                  : `${number} ${SUB} ${number / denominator}`
              }
              fractionTextStyle={
                displayMode === 'digital'
                  ? { fontSize: 32, fontWeight: '700' }
                  : { fontSize: 48, lineHeight: 40, fontWeight: '700' }
              }
              fractionDividerStyle={{ marginVertical: 2 }}
              textStyle={
                displayMode === 'digital'
                  ? { fontSize: 32, fontWeight: '700' }
                  : { fontSize: 48, lineHeight: 40, fontWeight: '700' }
              }
            />
          ),
          value: index
        };
      }),
      { random: seededRandom(props.question) }
    );

    return (
      <QF2DraggableAlignedEquations
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        items={answerOptions}
        sentences={statements.map(({ left, right }) => {
          return { left, right };
        })}
        testCorrect={[
          { left: [], right: [statements[0].answer] },
          { left: [], right: [statements[1].answer] },
          { left: [], right: [statements[2].answer] }
        ]}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'as4',
  description: 'as4',
  keywords: ['Fractions', 'Divide', 'Non-unit'],
  schema: z.object({
    number1: z.number().int().min(3).max(8),
    number2: z.number().int().min(100).max(1000).multipleOf(100)
  }),
  simpleGenerator: () => {
    const number1 = Math.random() < 0.5 ? randomIntegerInclusive(3, 5) : 8;
    const number2 = randomIntegerInclusiveStep(100, 1000, 100, {
      constraint: x => x % number1 === 0
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    // Answer
    const answer = (number2 / number1) * (number1 - 1);

    const answerOptionsANumerator = number1 - 1;

    return (
      <QF2AnswerBoxOneSentence
        sentence={`<frac n='${answerOptionsANumerator}' d='${number1}' /> of ${number2} = <ans />`}
        title={translate.instructions.completeCalculation()}
        testCorrect={[answer.toString()]}
        textStyle={{ fontSize: 40 }}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'as42',
  description: 'as4',
  keywords: ['Fractions', 'Divide', 'Non-unit'],
  schema: z.discriminatedUnion('sentenceId', [
    z.object({
      sentenceId: z.literal(0),
      numerator: z.number().int().min(2).max(7),
      denominator: z.number().int().min(3).max(8),
      wholeNumber: z.number().int().min(30).max(352)
    }),
    z.object({
      sentenceId: z.literal(1),
      numerator: z.number().int().min(2).max(7),
      denominator: z.number().int().min(3).max(8),
      wholeNumber: z.number().int().min(60).max(2400)
    })
  ]),
  simpleGenerator: () => {
    const sentenceId = getRandomFromArray([0, 1] as const);

    if (sentenceId === 0) {
      const { wholeNumber, denominator } = rejectionSample(
        () => {
          const denominator = getRandomFromArray([3, 4, 5, 8] as const);
          const var3 = randomIntegerInclusive(1, 4, { constraint: x => x * denominator < 10 });
          const var2 = randomIntegerInclusiveStep(10, 40, 10, { constraint: x => x + var3 > 12 });
          const var4 = var2 + var3;
          const wholeNumber = denominator * var4;

          return { wholeNumber, denominator };
        },
        ({ wholeNumber }) => wholeNumber < 100
      );

      const numerator = denominator - 1;

      return { sentenceId, wholeNumber, numerator, denominator };
    } else {
      const denominator = getRandomFromArray([3, 4, 5, 8] as const);
      const var2 = getRandomFromArray([20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300] as const);
      const wholeNumber = denominator * var2;
      const numerator = denominator - 1;
      return { sentenceId, wholeNumber, numerator, denominator };
    }
  },
  Component: props => {
    const {
      question: { wholeNumber, numerator, denominator },
      translate,
      displayMode
    } = props;

    // Answer
    const answer = number(math.evaluate(`(${wholeNumber} / ${denominator}) * ${numerator}`));

    return (
      <QF2AnswerBoxOneSentence
        sentence={translate.answerSentences.fracOfNumEqualsAns(
          `<frac n='${numerator}' d='${denominator}'/>`,
          wholeNumber
        )}
        title={translate.instructions.completeCalculation()}
        testCorrect={[answer.toString()]}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'as5',
  description: 'as5',
  keywords: ['Fractions', 'Divide', 'Non-unit'],
  schema: z
    .object({
      metric: z.enum(['mm', 'cm', 'm', 'mg', 'g', 'ml']),
      fracANumerator: z.number().int().min(2).max(9),
      denominator: z.number().int().min(3).max(10),
      number: z.number().int().min(15).max(100)
    })
    .refine(val => val.number % val.denominator === 0, 'number must be multiple of denominator'),
  simpleGenerator: () => {
    const metric = getRandomFromArray(['mm', 'cm', 'm', 'mg', 'g', 'ml'] as const);
    const denominator = getRandomBoolean()
      ? randomIntegerInclusive(3, 5)
      : getRandomBoolean()
      ? 8
      : 10;
    const fracANumerator = randomIntegerInclusive(2, denominator - 1, {
      constraint: x => greatestCommonDivisor([x, denominator]) === 1
    });
    const number = randomIntegerInclusive(15, 100, {
      constraint: x => x % denominator === 0
    });

    return { metric, fracANumerator, denominator, number };
  },
  Component: props => {
    const {
      question: { metric, fracANumerator, denominator, number },
      translate
    } = props;

    const fracBNumerator = denominator - fracANumerator;

    return (
      <QF2AnswerBoxOneSentence
        sentence={`<frac n='${fracANumerator}' d='${denominator}' /> of ${number} ${metric}  ${ADD} <frac n='${fracBNumerator}' d='${denominator}' /> of ${number} ${metric} = <ans /> ${metric}`}
        title={translate.instructions.completeCalculation()}
        testCorrect={[number.toString()]}
        textStyle={{ fontSize: 36 }}
        fractionTextStyle={{ fontSize: 36 }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'as52',
  description: 'as5',
  keywords: ['Fractions', 'Divide', 'Non-unit'],
  schema: z
    .object({
      metric: z.enum(['mm', 'cm', 'm', 'mg', 'g', 'ml']),
      fracANumerator: z.number().int().min(1).max(9),
      denominator: z.number().int().min(3).max(10),
      fracBNumerator: z.number().int().min(1).max(9),
      wholeNumber: z.number().int().min(15).max(100)
    })
    .refine(
      val => val.wholeNumber % val.denominator === 0,
      'number must be multiple of denominator'
    ),
  simpleGenerator: () => {
    const isAnswerWhole = getRandomBoolean();

    const metric = getRandomFromArray(['mm', 'cm', 'm', 'mg', 'g', 'ml'] as const);

    const denominator = getRandomFromArray([randomIntegerInclusive(3, 5), 8, 10]);

    const wholeNumber = randomIntegerInclusive(15, 100, {
      constraint: x => x % denominator === 0
    });

    if (isAnswerWhole) {
      const fracANumerator = randomIntegerInclusive(2, denominator - 1, {
        constraint: x => greatestCommonDivisor([x, denominator]) === 1
      });

      const fracBNumerator = denominator - fracANumerator;

      return { fracANumerator, fracBNumerator, metric, denominator, wholeNumber };
    } else {
      const { fracANumerator, fracBNumerator } = rejectionSample(
        () => {
          const fracANumerator = randomIntegerInclusive(1, denominator - 1);
          const fracBNumerator = randomIntegerInclusive(1, denominator - 1, {
            constraint: x => x + fracANumerator !== denominator
          });

          return { fracANumerator, fracBNumerator };
        },
        ({ fracANumerator, fracBNumerator }) => fracANumerator + fracBNumerator < denominator
      );

      return { metric, denominator, wholeNumber, fracANumerator, fracBNumerator };
    }
  },
  Component: props => {
    const {
      question: { metric, fracANumerator, denominator, wholeNumber, fracBNumerator },
      translate,
      displayMode
    } = props;

    // Answer
    const answer = number(
      math.evaluate(
        `(${wholeNumber} / ${denominator}) * ${fracANumerator} + (${wholeNumber} / ${denominator}) * ${fracBNumerator}`
      )
    );

    const metricTranslated = translate.units[metric]();

    return (
      <QF2AnswerBoxOneSentence
        sentence={translate.answerSentences.fracOfWholeXAddFracOfWholeXEqualsAnsX(
          `<frac n='${fracANumerator}' d='${denominator}'/>`,
          wholeNumber,
          metricTranslated,
          `<frac n='${fracBNumerator}' d='${denominator}'/>`
        )}
        title={translate.instructions.completeCalculation()}
        testCorrect={[answer.toString()]}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'as6',
  description: 'as6',
  keywords: ['Fractions', 'Divide', 'Unit', 'Non-unit'],
  schema: z
    .object({
      bagOfSweets: z.number().int().min(12).max(50),
      fracADenominator: z.number().int().min(2).max(10),
      fracBDenominator: z.number().int().min(2).max(10),
      number: z.number().int().min(1).max(5),
      nameA: nameSchema,
      nameB: nameSchema
    })
    .refine(
      val => val.bagOfSweets % val.fracADenominator === 0,
      'bagOfSweets should be a common multiple of fracADenominator'
    )
    .refine(
      val => val.fracBDenominator !== val.fracADenominator,
      'fracBDenominator should not be equal to fracADenominator'
    )
    .refine(
      val =>
        (val.bagOfSweets - val.bagOfSweets / val.fracADenominator) % val.fracBDenominator === 0,
      `The remainder from (bagOfSweets ${SUB} bagOfSweets / fracADenominator) must be a multiple of fracBDenominator`
    )
    .refine(
      val =>
        val.number <
        val.bagOfSweets -
          val.bagOfSweets / val.fracADenominator -
          (val.bagOfSweets - val.bagOfSweets / val.fracADenominator) / val.fracBDenominator,
      'number must be less than the remainder'
    ),
  simpleGenerator: () => {
    const { bagOfSweets, fracADenominator, fracBDenominator, number } = rejectionSample(
      () => {
        const bagOfSweets = randomIntegerInclusive(12, 50);
        const fracADenominator = getRandomBoolean()
          ? randomIntegerInclusive(2, 5)
          : getRandomBoolean()
          ? 8
          : 10;
        const fracBDenominator = getRandomBoolean()
          ? randomIntegerInclusive(2, 5)
          : getRandomBoolean()
          ? 8
          : 10;

        const number = randomIntegerInclusive(1, 5);

        return { bagOfSweets, fracADenominator, fracBDenominator, number };
      },
      ({ bagOfSweets, fracADenominator, fracBDenominator, number }) =>
        // Ensure bagOfSweets is a multiple of fracADenominator
        bagOfSweets % fracADenominator === 0 &&
        // Ensure fracBDenominator does not equal fracBDenominator
        fracBDenominator !== fracADenominator &&
        // Ensure the remainder of bagOfSweets - (bagOfSweets / fracADenominator) is a multiple of fracBDenominator
        (bagOfSweets - bagOfSweets / fracADenominator) % fracBDenominator === 0 &&
        // Ensure number is less than the remainder
        number <
          bagOfSweets -
            bagOfSweets / fracADenominator -
            (bagOfSweets - bagOfSweets / fracADenominator) / fracBDenominator
    );

    const [nameA, nameB] = getRandomUniqueNames(2);

    return { bagOfSweets, fracADenominator, fracBDenominator, number, nameA, nameB };
  },
  Component: props => {
    const {
      question: { bagOfSweets, fracADenominator, fracBDenominator, number, nameA, nameB },
      translate
    } = props;

    // Calculate the first remainder
    const numSweetsLeftA = bagOfSweets - bagOfSweets / fracADenominator;
    // Calculate the second remainder
    const numSweetsLeftB = numSweetsLeftA - numSweetsLeftA / fracBDenominator;

    // Answer
    const answer = numSweetsLeftB - number;

    return (
      <QF2AnswerBoxOneSentence
        sentence={'<ans />'}
        title={translate.instructions.characterHasABagOfXSweetsHowManySweetsDoesCharacterHaveLeft({
          amountOfSweetsA: bagOfSweets,
          amountOfSweetsB: number,
          characterA: nameA,
          characterB: nameB,
          fracA: `<frac n='1' d='${fracADenominator}' />`,
          fracB: `<frac n='1' d='${fracBDenominator}' />`
        })}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        textStyle={{ fontSize: 40 }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'as7',
  description: 'as7',
  keywords: ['Fractions', 'Divide', 'Unit', 'Non-unit', 'Money'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(5),
      coins: z.number().int().min(9).max(15),
      coinsToDisplay: z.string().array(),
      name: nameSchema
    })
    .refine(
      val => val.coins % (val.denominator - 1) === 0,
      'coins must be a multiple of denominator - 1'
    )
    .refine(val => val.coins !== 10, 'coins must not be equal to 10'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 5);
    const coins = randomIntegerInclusive(9, 15, {
      // Coins must be equal to denominator - 1 and not equal to 10
      constraint: x => x % (denominator - 1) === 0 && x !== 10
    });

    // Find minimum amount of pence needed required to total the sum of coins
    const coinsToDisplay = moneyToHighestDenominations(coins, 'pence');

    const name = getRandomName();

    return { denominator, coins, name, coinsToDisplay };
  },
  Component: props => {
    const {
      question: { denominator, coins, name, coinsToDisplay },
      translate
    } = props;

    // Answer
    const answer = coins / (denominator - 1);

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.howMuchDidCharacterSpendAns(name)}
        title={translate.instructions.characterSpendsFracOfMoneyCharacterHasTheseCoinsLeft({
          character: name,
          frac: `<frac n='1' d='${denominator}' />`
        })}
        testCorrect={[answer.toString()]}
        Content={() => {
          return (
            <View style={{ flexDirection: 'row', columnGap: 24 }}>
              {/* Loop over coinsToDisplay e.g ['10p', '2p'] */}
              {displayMoney(coinsToDisplay)}
            </View>
          );
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'as72',
  description: 'as7',
  keywords: ['Fractions', 'Divide', 'Unit', 'Non-unit', 'Money'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(5),
      coins: z.number().int().min(9).max(15),
      coinsToDisplay: z.string().array(),
      name: nameSchema
    })
    .refine(
      val => val.coins % (val.denominator - 1) === 0,
      'coins must be a multiple of denominator - 1'
    )
    .refine(val => val.coins !== 10, 'coins must not be equal to 10'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 5);
    const coins = randomIntegerInclusive(9, 15, {
      // Coins must be equal to denominator - 1 and not equal to 10
      constraint: x => x % (denominator - 1) === 0 && x !== 10
    });

    // Find minimum amount of pence needed required to total the sum of coins
    const coinsToDisplay = moneyToHighestDenominations(coins, 'pence');

    const name = getRandomName();

    return { denominator, coins, name, coinsToDisplay };
  },
  Component: props => {
    const {
      question: { denominator, coins, name, coinsToDisplay },
      translate,
      displayMode
    } = props;

    // Answer
    const answer = coins / (denominator - 1);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.characterSpendsFracOfMoneyCharacterHasTheseCoinsLeft({
          character: name,
          frac: `<frac n='1' d='${denominator}' />`
        })}
        testCorrect={[answer.toString()]}
        questionHeight={900}
        sentence={translate.answerSentences.ansP()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View
            style={{
              alignSelf: 'flex-start',
              height: dimens.height,
              justifyContent: 'space-around'
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                columnGap: 24,
                justifyContent: 'center',
                width: dimens.width,
                alignItems: 'center'
              }}
            >
              {/* Loop over coinsToDisplay e.g ['10p', '2p'] */}
              {displayMoney(
                coinsToDisplay,
                displayMode === 'digital' ? 100 : 200,
                displayMode === 'digital' ? 100 : 200,
                true
              )}
            </View>
            <Text
              style={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                top: 20
              }}
            >
              {translate.answerSentences.howMuchDidCharacterSpend(name)}
            </Text>
          </View>
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ReasoningWithFractionsOfAnAmount',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4v2, Question5, Question6v2],
  archivedQuestionTypes: [Question1, Question2, Question3, Question4, Question6]
});
export default SmallStep;
