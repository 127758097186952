import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { countRange, filledArray } from '../../../../utils/collections';
import { SUB } from '../../../../constants';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjectsWithCrossesWithState } from '../../../../components/question/representations/ArrayOfObjectsWithCrosses';
import TextStructure from '../../../../components/molecules/TextStructure';
import { View } from 'react-native';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bbz',
  description: 'bbz',
  keywords: ['Subtract', 'Take away', 'Cross out', 'Ten frames', 'Counters'],
  schema: z.object({
    colourCounter: z.enum(['red', 'yellow', 'blue', 'green']),
    isFlipped: z.array(z.boolean()).length(3),
    items: z
      .object({
        A: z.tuple([z.number().int().min(3).max(10), z.number().int().min(0).max(10)]),
        B: z.tuple([z.number().int().min(3).max(10), z.number().int().min(0).max(10)]),
        C: z.tuple([z.number().int().min(3).max(10), z.number().int().min(0).max(10)])
      })
      .refine(
        x => (
          Object.values(x).every(([minuend, subtrahend]) => minuend >= subtrahend),
          'minuend should be greater or equal to subtrahend'
        )
      )
  }),
  simpleGenerator: () => {
    const colourCounter = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);
    const isFlipped = countRange(3).map(_ => getRandomBoolean());

    const keys = shuffle(['A', 'B', 'C'] as const);

    const minuends = countRange(3).map(_ => randomIntegerInclusive(3, 10));
    const subtrahends = countRange(3).map(i => randomIntegerInclusive(0, minuends[i]));

    type ItemType = {
      [K in (typeof keys)[number]]: [number, number];
    };
    const items: ItemType = Object.fromEntries(
      keys.map((key, i) => [key, [minuends[i], subtrahends[i]]])
    ) as ItemType;

    return { colourCounter, isFlipped, items };
  },
  Component: props => {
    const {
      question: { colourCounter, isFlipped, items },
      translate
    } = props;

    const equation = (left: number, right: number, ans: number, flipped: boolean) =>
      flipped
        ? `${ans.toLocaleString()} = ${left.toLocaleString()} ${SUB} ${right.toLocaleString()}`
        : `${left.toLocaleString()} ${SUB} ${right.toLocaleString()} = ${ans.toLocaleString()}`;

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragToMatchTheSubtractionsToTheTenFrames()}
        pdfTitle={translate.ks1PDFInstructions.matchTheTenFramesToTheSubtractions()}
        items={Object.entries(items).map(([x, [num1, num2]], i) => ({
          value: x,
          component: <Text variant="WRN400">{equation(num1, num2, num1 - num2, isFlipped[i])}</Text>
        }))}
        statements={(['A', 'B', 'C'] as const).map(x => ({
          lhsComponent: (
            <TenFrameLayout
              size="small"
              items={[...filledArray(colourCounter, items[x][0])]}
              crossedOutCells={index =>
                countRange(items[x][1], items[x][0] - items[x][1]).includes(index)
              }
            />
          ),
          correctAnswer: x
        }))}
        actionPanelVariant="endWide"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'bbA',
  description: 'bbA',
  keywords: ['Subtract', 'Take away'],
  schema: z
    .object({
      object: z.enum(['bird', 'car', 'cookie']),
      amountOfObjects: z.number().int().min(3).max(10),
      amountSubtracted: z.number().int().min(1).max(10)
    })
    .refine(({ amountSubtracted, amountOfObjects }) => amountSubtracted <= amountOfObjects),
  simpleGenerator: () => {
    const object = getRandomFromArray(['bird', 'car', 'cookie'] as const);
    const amountOfObjects = randomIntegerInclusive(3, 10);
    const amountSubtracted = randomIntegerInclusive(1, amountOfObjects);

    return {
      object,
      amountOfObjects,
      amountSubtracted
    };
  },

  Component: props => {
    const {
      question: { object, amountOfObjects, amountSubtracted },
      displayMode,
      translate
    } = props;

    const [icon, firstStatement, middleStatements] = (() => {
      switch (object) {
        case 'bird':
          return ['bird_blue', 'thereAreXBirds', 'xBirdsFlyAwayHowManyAreLeft'] as const;
        case 'car':
          return [
            'Car',
            'thereAreXCarsInACarPark',
            'xOfTheCarsLeaveHowManyCarsAreLeftInTheCarPark'
          ] as const;
        case 'cookie':
          return [
            'Cookie',
            'thereAreXCookiesOnAPlate',
            'xOfTheCookiesAreEatenHowManyCookiesAreLeftOnThePlate'
          ] as const;
      }
    })();

    const endStatement =
      displayMode === 'digital'
        ? `<br/>${translate.ks1Instructions.youCanTapThePicturesToHelpYou()}`
        : '';

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions[firstStatement](amountOfObjects)}
        sentence="<ans/>"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[(amountOfObjects - amountSubtracted).toString()]}
        Content={({ dimens }) => (
          <View style={{ ...dimens, justifyContent: 'space-around' }}>
            <ArrayOfObjectsWithCrossesWithState
              dimens={dimens}
              icon={{
                name: icon,
                width:
                  displayMode === 'digital'
                    ? amountOfObjects < 4
                      ? dimens.width / amountOfObjects / 3
                      : 70
                    : 110
              }}
              amountOfColumns={amountOfObjects}
              id="objects"
              // Does not need to be completed and is not marked
              testComplete={() => true}
            />

            <TextStructure
              sentence={`${translate.ks1Instructions[middleStatements](
                amountSubtracted
              )}${endStatement}`}
            />
          </View>
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bbB',
  description: 'bbB',
  keywords: ['Number sentence', 'Subtract', 'Take away'],
  schema: z
    .object({
      num1: z.number().int().min(2).max(10),
      num2: z.number().int().min(0).max(10),
      flipped: z.boolean()
    })
    .refine(({ num1, num2 }) => num1 >= num2, 'num1 should be greater or equal to num2'),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(2, 10);
    const num2 = randomIntegerInclusive(0, num1);
    const flipped = getRandomFromArrayWithWeights([false, true], [3, 1]);
    return {
      num1,
      num2,
      flipped
    };
  },
  Component: ({ question, translate }) => {
    const { num1, num2, flipped } = question;
    const sentence = getBinOpEquation({
      left: num1,
      right: num2,
      result: num1 - num2,
      flipped,
      sign: 'subtract',
      answer: 'result'
    });
    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        sentence={binOpEquationToSentenceString(sentence)}
        testCorrect={binOpEquationsToTestCorrect([sentence])[0]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractionTakeAway',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
