import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle,
  seededRandom,
  rejectionSample,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { numberEnum } from '../../../../utils/zod';
import { SUB } from '../../../../constants';
import { compareFractions, fractionToDecimal, simplify } from '../../../../utils/fractions';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { filledArray } from '../../../../utils/collections';
import QF20cInteractiveBarModelWithCrossesAndSentence from '../../../../components/question/questionFormats/QF20cInteractiveBarModelWithCrossesAndSentence';
import { View } from 'react-native';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'asa',
  description: 'asa',
  keywords: ['Fractions', 'Subtraction', 'Equivalent fractions'],
  schema: z.object({
    denominatorA: z.number().int().min(3).max(8),
    numeratorA: z.number().int().min(1).max(7),
    numeratorB: z.number().int().min(1).max(24),
    numberOfRows: z.number().int().min(2).max(3)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(3, 8);
    const numberOfRows = randomIntegerInclusive(2, 3);
    const numeratorA = randomIntegerInclusive(1, denominatorA - 1);
    const numeratorB = randomIntegerInclusive(1, numeratorA * numberOfRows - 1);

    return { denominatorA, numberOfRows, numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { denominatorA, numberOfRows, numeratorA, numeratorB },
      translate
    } = props;

    const denominatorB = denominatorA * numberOfRows;
    const numeratorC = numeratorA * numberOfRows;
    const numeratorD = numeratorC - numeratorB;

    const bars = [
      {
        rows: numberOfRows,
        cols: denominatorA,
        shadedCells: { min: 0, max: numeratorA - 1 }
      }
    ];

    const sentence = `<frac n='${numeratorA}' d='${denominatorA}'/> ${SUB} <frac n='${numeratorB}' d='${denominatorB}'/> = <frac nAns='' d='${denominatorB}' /> ${SUB} <frac nAns='' d='${denominatorB}' /> = <frac nAns='' d='${denominatorB}' />`;

    // Answers
    const answer1 = numeratorC;
    const answer2 = numeratorB;
    const answer3 = numeratorD;

    return (
      <QF20cInteractiveBarModelWithCrossesAndSentence
        title={translate.instructions.completeTheCalculationUseTheBarModelToHelpYou()}
        testCorrect={[answer1.toString(), answer2.toString(), answer3.toString()]}
        sentence={sentence}
        bars={bars}
        tableHeight={numberOfRows * 100}
        pdfDirection="column"
        questionHeight={1000}
        textStyle={{ fontSize: 40 }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.barModelsDoNotNeedShading()
        }}
        fractionContainerStyle={{ height: 96 }}
        barModelShadingTestComplete={() => true}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'asb',
  description: 'asb',
  keywords: ['Fraction', 'Subtraction', 'Match', 'Equivalent fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(5),
    number2: z.number().int().min(1).max(4),
    number4: numberEnum([1, 5, 7, 11, 13]),
    number6: z.number().int().min(4).max(14),
    number8: z.number().int().min(3).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 5);

    const number2 = randomIntegerInclusive(Math.ceil(number1 / 2), number1 - 1);

    const number3 = number1 * 6;

    const number4Array = [1, 5, 7, 11, 13].filter(num => num < number3 / 2);

    const number4 = getRandomFromArray(number4Array) as 1 | 5 | 7 | 11 | 13;

    const number5 = number1 * 3;

    const number6LowerBound =
      Math.ceil(number5 / 2) === number5 / 2 ? Math.ceil(number5 / 2) + 1 : Math.ceil(number5 / 2);
    const number6 = randomIntegerInclusive(number6LowerBound, number5 - 1, {
      // Prevent number9 and number10 from being the same, i.e. prevent drag items being identical.
      constraint: x => x * 2 !== number2 * 6
    });

    const number7 = number1 * 2;

    const number8LowerBound =
      Math.ceil(number7 / 2) === number7 / 2 ? Math.ceil(number7 / 2) + 1 : Math.ceil(number7 / 2);
    const number8 = randomIntegerInclusive(number8LowerBound, number7 - 1, {
      // Prevent number9, number10 and number11 from being the same, i.e. prevent drag items being identical.
      constraint: x => x * 3 !== number2 * 6 && x * 3 !== number6 * 2
    });

    return { number1, number2, number4, number6, number8 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number6, number8 },
      translate,
      displayMode
    } = props;

    const number3 = number1 * 6;
    const number5 = number1 * 3;
    const number7 = number1 * 2;
    const number9 = number2 * 6;
    const number10 = number6 * 2;
    const number11 = number8 * 3;

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${number2}' d='${number1}'/> ${SUB} <frac n='${number4}' d='${number3}'/> =`}
            />
          </View>
        ),
        correctAnswer: 'A'
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${number6}' d='${number5}'/> ${SUB} <frac n='${number4}' d='${number3}'/> =`}
            />
          </View>
        ),
        correctAnswer: 'B'
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${number8}' d='${number7}'/> ${SUB} <frac n='${number4}' d='${number3}'/> =`}
            />
          </View>
        ),
        correctAnswer: 'C'
      }
    ];

    const answerOptions = [
      {
        component: (
          <TextStructure
            sentence={`<frac n='${number9}' d='${number3}'/> ${SUB} <frac n='${number4}' d='${number3}'/>`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: 'A'
      },
      {
        component: (
          <TextStructure
            sentence={`<frac n='${number10}' d='${number3}'/> ${SUB} <frac n='${number4}' d='${number3}'/>`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: 'B'
      },
      {
        component: (
          <TextStructure
            sentence={`<frac n='${number11}' d='${number3}'/> ${SUB} <frac n='${number4}' d='${number3}'/>`}
            fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
            fractionDividerStyle={{ marginVertical: 2 }}
          />
        ),
        value: 'C'
      }
    ];

    const shuffledAnswerOptions = shuffle(answerOptions, { random: seededRandom(props.question) });

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchCalcs()}
        pdfTitle={translate.instructions.matchCalcs()}
        items={shuffledAnswerOptions}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'asc',
  description: 'asc',
  keywords: ['Common denominator', 'Subtract fractions'],
  schema: z.object({
    number1: z.number().int().min(5).max(8),
    number2: z.number().int().min(3).max(7),
    number4: z.number().int().min(1).max(7)
  }),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(5, 8);

        const number2LowerBound =
          Math.ceil(number1 / 2) === number1 / 2
            ? Math.ceil(number1 / 2) + 1
            : Math.ceil(number1 / 2);

        const number2 = randomIntegerInclusive(number2LowerBound, number1 - 1);
        return { number1, number2 };
      },
      // Only permit them if they are in their simplest fraction form.
      ({ number1, number2 }) => {
        const [simplifiedNumerator, simplifiedDenominator] = simplify(number2, number1);

        return number2 === simplifiedNumerator && number1 === simplifiedDenominator;
      }
    );

    const number3 = number1 * 2;

    const number4 = randomIntegerInclusive(1, number1 - 1, {
      constraint: x => {
        const [simplifiedNumerator] = simplify(x, number3);

        return x !== number2 && x === simplifiedNumerator;
      }
    });

    return { number1, number2, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4 },
      translate
    } = props;

    const number3 = number1 * 2;

    const answerNumeratorA = number2 * 2;

    const answerNumeratorB = answerNumeratorA - number4;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSubtraction()}
        inputMaxCharacters={1}
        testCorrect={answer =>
          compareFractions([answer[0], number3], [answerNumeratorA, number3]) &&
          compareFractions([answer[1], number3], [answerNumeratorB, number3])
        }
        sentence={`<frac n='${number2}' d='${number1}'/> ${SUB} <frac n='${number4}' d='${number3}'/> = <frac nAns='' d='${number3}'/> ${SUB} <frac n='${number4}' d='${number3}'/> = <frac nAns='' d='${number3}'/>`}
        fractionContainerStyle={{ height: 96 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [answerNumeratorA.toString(), answerNumeratorB.toString()]
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'asd',
  description: 'asd',
  keywords: ['Difference', 'Subtraction', 'Fractions'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(6),
      number2: z.number().int().min(1).max(5),
      number3: z.number().int().min(2).max(6),
      number5: z.number().int().min(1).max(11)
    })
    .refine(val => val.number2 < val.number1, 'number2 must be less than number1'),
  questionHeight: 1100,
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 6);
        const number2 = randomIntegerInclusive(1, number1 - 1);
        return { number1, number2 };
      },
      // Only permit them if they are in their simplest fraction form.
      ({ number1, number2 }) => {
        const [simplifiedNumerator, simplifiedDenominator] = simplify(number2, number1);

        return number2 === simplifiedNumerator && number1 === simplifiedDenominator;
      }
    );

    const { number3, number5 } = rejectionSample(
      () => {
        const number3 = randomIntegerInclusive(2, 6, {
          constraint: x => x * number1 <= 12
        });

        const number4 = number1 * number3;
        const number5 = randomIntegerInclusive(1, number4 - 1);
        return { number3, number4, number5 };
      },
      // Only permit them if they are in their simplest fraction form.
      ({ number4, number5 }) => {
        const [simplifiedNumerator, simplifiedDenominator] = simplify(number5, number4);

        return number5 === simplifiedNumerator && number4 === simplifiedDenominator;
      }
    );

    return { number1, number2, number3, number5 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number5 },
      translate,
      displayMode
    } = props;

    const number4 = number1 * number3;

    const bars = [
      { rows: 1, cols: number1 },
      { rows: 1, cols: number4 }
    ];

    const [simplifiedNumerator, simplifiedDenominator] = simplify(
      Math.abs(number2 * number3 - number5),
      number4
    );

    const preBarLabels = [
      `<frac n='${number2.toLocaleString()}' d='${number1.toLocaleString()}'/>`,
      `<frac n='${number5.toLocaleString()}' d='${number4.toLocaleString()}'/>`
    ];

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.completeSentence()}<br/>${translate.instructions.youCanShadeTheBarModelsToHelp()}`}
        testCorrect={answer =>
          compareFractions([answer[0], answer[1]], [simplifiedNumerator, simplifiedDenominator])
        }
        sentence={translate.answerSentences.theDiffBetweenXAndYIsZ(
          `<frac n='${number2.toLocaleString()}' d='${number1.toLocaleString()}'/>`,
          `<frac n='${number5.toLocaleString()}' d='${number4.toLocaleString()}'/>`,
          `<frac nAns='' dAns=''/>`
        )}
        inputMaxCharacters={2}
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            {bars.map((bar, idx) => (
              <BarModelInteractiveWithState
                id={`bar${idx}`}
                key={`bar${idx}`}
                preBarText={preBarLabels[idx]}
                numberOfRows={bar.rows}
                numberOfCols={bar.cols}
                tableHeight={displayMode === 'digital' ? 100 : 160}
                tableWidth={displayMode === 'digital' ? dimens.width - 64 : dimens.width - 128}
                fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
                fractionDividerStyle={{ marginVertical: 0 }}
              />
            ))}
          </View>
        )}
        pdfDirection="column"
        questionHeight={1100}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            simplifiedNumerator.toLocaleString(),
            simplifiedDenominator.toLocaleString()
          ],
          answerText: translate.markScheme.barModelsDoNotNeedShading()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ase',
  description: 'ase',
  keywords: ['Difference', 'Subtraction', 'Fractions', 'Improper fractions'],
  schema: z
    .object({
      number1: z.number().int().min(2).max(6),
      number2: z.number().int().min(1).max(5),
      number3: z.number().int().min(2).max(6),
      number5: z.number().int().min(1).max(35)
    })
    .refine(val => val.number2 < val.number1, 'number2 must be less than number1')
    .refine(
      val => val.number5 < val.number1 * val.number3,
      'number5 must be less than the product of number1 and number3'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 6);

        const number2 = randomIntegerInclusive(1, number1 - 1);
        return { number1, number2 };
      },
      // Only permit them if they are in their simplest fraction form.
      ({ number1, number2 }) => {
        const [simplifiedNumerator, simplifiedDenominator] = simplify(number2, number1);

        return number2 === simplifiedNumerator && number1 === simplifiedDenominator;
      }
    );

    const { number3, number5 } = rejectionSample(
      () => {
        const number3 = randomIntegerInclusive(2, 6);

        const number4 = number1 * number3;

        const number5 = randomIntegerInclusive(1, number4 - 1);
        return { number3, number4, number5 };
      },
      // Only permit them if they are in their simplest fraction form.
      ({ number4, number5 }) => {
        const [simplifiedNumerator, simplifiedDenominator] = simplify(number5, number4);

        return (
          number5 === simplifiedNumerator &&
          number4 === simplifiedDenominator &&
          // Ensure first fraction is greater than second
          fractionToDecimal(number5, number4) > fractionToDecimal(number2, number1)
        );
      }
    );

    return { number1, number2, number3, number5 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number5 },
      translate
    } = props;

    const number4 = number1 * number3;

    const answerNumeratorA = Math.abs(number2 * number3 - number5);

    const [simplifiedNumeratorA, simplifiedDenominatorA] = simplify(answerNumeratorA, number4);

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheDifference()}
        inputMaxCharacters={2}
        testCorrect={answer =>
          compareFractions(
            [answer[0][0], answer[0][1]],
            [simplifiedNumeratorA, simplifiedDenominatorA]
          )
        }
        sentences={[
          `<frac n='${number5.toLocaleString()}' d='${number4.toLocaleString()}'/> ${SUB} <frac n='${number2.toLocaleString()}' d='${number1.toLocaleString()}'/> = <frac nAns='' dAns=''/>`
        ]}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [simplifiedNumeratorA.toLocaleString(), simplifiedDenominatorA.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'asf',
  description: 'asf',
  keywords: ['Midpoint', 'Fractions', 'Number line'],
  schema: z.object({
    denominator: z.number().int().min(6).max(12).multipleOf(2),
    numeratorA: z.number().int().min(1).max(5),
    numeratorB: z.number().int().min(7).max(23)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusiveStep(6, 12, 2);
    const numeratorA = randomIntegerInclusiveStep(1, 5, 2);
    const numeratorB = randomIntegerInclusiveStep(7, 23, 2);

    return { denominator, numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, numeratorB },
      translate
    } = props;
    // Create the tick values array
    const tickValues = filledArray('', 3);
    tickValues[0] = `<frac n='${numeratorA}' d='${denominator}'/>`;
    tickValues[1] = `<frac nAns='' dAns=''/>`;
    tickValues[2] = `<frac n='${numeratorB}' d='${denominator}'/>`;

    const ansNumerator = (numeratorA + numeratorB) / 2;
    const correctAnswer = [ansNumerator, denominator];

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.findValueOfMidpoint()}
        testCorrect={userAnswer => compareFractions(userAnswer, correctAnswer)}
        inputMaxCharacters={2}
        firstNumber={0}
        lastNumber={1}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answersToDisplay: [ansNumerator.toLocaleString(), denominator.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractFractions',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
