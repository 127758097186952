import z from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered
} from '../../../../utils/collections';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { DIV, MULT } from '../../../../constants';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import Text from '../../../../components/typography/Text';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjm',
  description: 'bjm',
  keywords: ['Array', 'Multiply', 'Divide'],
  schema: z
    .object({
      rows: z.number().int().min(1).max(5),
      columns: z.number().int().min(1).max(12),
      color: z.string()
    })
    .refine(val => val.rows === 5 || val.columns === 5, 'rows or columns must equal 5'),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(1, 5);
    const columns = rows !== 5 ? 5 : randomIntegerInclusive(1, 12);
    const color = getRandomFromArray(Object.values(ArrayOfObjectsColors)) as string;
    return { rows, columns, color };
  },
  Component: props => {
    const {
      question: { rows, columns, color },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        sentences={[`<ans/> ${MULT} <ans/> = <ans/>`, `<ans/> ${DIV} <ans/> = <ans/>`]}
        title={translate.ks1Instructions.completeTheCalculationsToMatchArray()}
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(
            [userAnswer[0][0], userAnswer[0][1]],
            [rows.toString(), columns.toString()]
          ) &&
          userAnswer[0][2] === (columns * rows).toString() &&
          arraysHaveSameContentsUnordered(
            [userAnswer[1][1], userAnswer[1][2]],
            [rows.toString(), columns.toString()]
          ) &&
          userAnswer[1][0] === (columns * rows).toString()
        }
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [rows.toLocaleString(), columns.toLocaleString(), (rows * columns).toLocaleString()],
            [(rows * columns).toLocaleString(), columns.toLocaleString(), rows.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(
              (dimens.width - 20 * columns) / columns,
              dimens.height / (rows + 1)
            )}
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bjn',
  description: 'bjn',
  keywords: ['Multiply', 'Divide', '5 times-table'],
  schema: z.object({
    groups: z.number().int().min(1).max(12),
    answerOptions: z.array(z.number().int()).length(4).refine(arrayHasNoDuplicates),
    groupsFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const { groups, uniqueAnswers } = rejectionSample(
      () => {
        const groups = randomIntegerInclusive(1, 12);

        const answer1 = 5 * groups;
        const incorrectAnswers = [
          groups + 5,
          answer1 - 5,
          answer1 + 5,
          groups + 1,
          groups - 1
        ].filter(val => val > 0);

        const uniqueAnswers = [...new Set([answer1, groups, ...incorrectAnswers])];

        return { groups, uniqueAnswers };
      },
      val => val.uniqueAnswers.length >= 4
    );

    const incorrectAnswers = getRandomSubArrayFromArray(
      [...uniqueAnswers.filter(val => val !== 5 * groups && val !== groups)],
      2
    );
    const groupsFirst = getRandomBoolean();

    return {
      groups,
      answerOptions: shuffle([5 * groups, groups, ...incorrectAnswers]),
      groupsFirst
    };
  },
  Component: props => {
    const {
      question: { groups, answerOptions, groupsFirst },
      translate
    } = props;

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragCardsToCompleteTheCalculations()}
        pdfTitle={translate.ks1PDFInstructions.useCardsToCompleteTheCalculations()}
        sentences={[
          groupsFirst
            ? `${groups.toLocaleString()} ${MULT} ${(5).toLocaleString()} = <ans/>`
            : `${(5).toLocaleString()} ${MULT} ${groups.toLocaleString()} = <ans/>`,
          `${(groups * 5).toLocaleString()} ${DIV} ${(5).toLocaleString()} = <ans/>`
        ]}
        testCorrect={[[groups * 5], [groups]]}
        pdfLayout="itemsTop"
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        items={answerOptions.map(value => ({
          value,
          component: <Text variant="WRN700">{value.toLocaleString()}</Text>
        }))}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bjo',
  description: 'bjo',
  keywords: ['5 times-table', 'Divide'],
  schema: z.object({
    dividend: z.number().min(5).max(60).step(5),
    order: z.enum(['quotientLast', 'quotientFirst', 'dividendFirst', 'dividendLast'])
  }),
  simpleGenerator: () => {
    const dividend = randomIntegerInclusiveStep(5, 60, 5);

    const order = getRandomFromArrayWithWeights(
      ['quotientLast', 'quotientFirst', 'dividendFirst', 'dividendLast'] as const,
      [4, 2, 1, 1]
    );

    return {
      dividend,
      order
    };
  },
  Component: ({ question: { dividend, order }, translate }) => {
    const [sentence, answer] = ((): [string, number] => {
      switch (order) {
        case 'quotientLast':
          return [
            `${dividend.toLocaleString()} ${DIV} ${(5).toLocaleString()} = <ans/>`,
            dividend / 5
          ];
        case 'quotientFirst':
          return [
            `<ans/> = ${dividend.toLocaleString()} ${DIV} ${(5).toLocaleString()}`,
            dividend / 5
          ];
        case 'dividendFirst':
          return [
            `<ans/> ${DIV} ${(5).toLocaleString()} = ${(dividend / 5).toLocaleString()}`,
            dividend
          ];
        case 'dividendLast':
          return [
            `<ans/> ${DIV} ${(5).toLocaleString()} = ${(dividend / 5).toLocaleString()}`,
            dividend
          ];
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheDivision()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

// //
// Small Step
// //

const SmallStep = newSmallStepContent({
  smallStep: 'DivideBy5',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
