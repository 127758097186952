import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { simplify } from '../../../../utils/fractions';
import QF20bInteractiveBarModelWithSentence from '../../../../components/question/questionFormats/QF20bInteractiveBarModelWithSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { barModelColors } from '../../../../theme/colors';
import { filledArray } from '../../../../utils/collections';
import { algebraicSymbolSchema, getAlgebraicSymbol } from '../../../../utils/algebraicSymbols';
import { View } from 'react-native';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'arv',
  description: 'arv',
  keywords: ['Fraction', 'Divide', 'Amount'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(6),
      number2: z.number().int().min(14).max(72)
    })
    .refine(val => val.number2 % val.number1 === 0, 'number2 must be a multiple of number1'),
  questionHeight: 900,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 6);
    const number2 = randomIntegerInclusive(14, 72, {
      // Must be a multiple of number1, and the final answer must be 12 or less:
      constraint: x => x % number1 === 0 && x / number1 <= 12
    });

    return { number1, number2 };
  },

  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const bars = [{ rows: 1, cols: number1 }];

    const ans = number2 / number1;

    return (
      <QF20bInteractiveBarModelWithSentence
        title={translate.instructions.useDiagramToHelpCompleteCalculation()}
        testCorrect={{ sentence: [ans] }}
        sentence={translate.answerSentences.whatIsFracOfX(`<frac n='1' d='${number1}'/>`, number2)}
        bars={bars}
        fullWidthTopBrace={number2}
        braceTextStyle={{
          fontSize: displayMode === 'digital' ? 40 : 50,
          top: displayMode === 'digital' ? -72 : -84
        }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.barModelDoesNotNeedToBeShaded()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'arw',
  description: 'arw',
  keywords: ['Fraction', 'Divide', 'Amount'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(5),
      number2: z.number().int(),
      number4: z.number().int().min(6).max(9),
      number5: z.number().int(),
      number7: z.number().int().min(10).max(12),
      number8: z.number().int()
    })
    .refine(val => val.number2 % val.number1 === 0, 'number2 must be a multiple of number1')
    .refine(val => val.number5 % val.number4 === 0, 'number5 must be a multiple of number4')
    .refine(val => val.number8 % val.number7 === 0, 'number8 must be a multiple of number7'),
  questionHeight: 600,
  simpleGenerator: () => {
    const multipleA = randomIntegerInclusive(1, 12);
    const multipleB = randomIntegerInclusive(1, 12);
    const multipleC = randomIntegerInclusive(1, 12);

    const number1 = randomIntegerInclusive(3, 5);
    const number2 = number1 * multipleA;
    const number4 = randomIntegerInclusive(6, 9);
    const number5 = number4 * multipleB;
    const number7 = randomIntegerInclusive(10, 12);
    const number8 = number7 * multipleC;

    return { number1, number2, number4, number5, number7, number8 };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number5, number7, number8 },
      translate
    } = props;

    const number3 = number2 / number1;
    const number6 = number5 / number4;
    const number9 = number8 / number7;

    const essential = {
      sentence: translate.answerSentences.fractionOfNumberEqualsAns({
        frac: `<frac n='1' d='${number1.toLocaleString()}'/>`,
        number: number2
      }),
      answer: [number3.toString()]
    };

    const sentenceTwo = getRandomFromArray(
      [
        {
          sentence: translate.answerSentences.fractionOfPoundsEqualsAns({
            frac: `<frac n='1' d='${number4.toLocaleString()}'/>`,
            number: number5
          }),
          answer: [number6.toString()]
        },
        {
          sentence: translate.answerSentences.fractionOfMetresEqualsAns({
            frac: `<frac n='1' d='${number7.toLocaleString()}'/>`,
            number: number8
          }),
          answer: [number9.toString()]
        }
      ] as const,
      { random: seededRandom(props.question) }
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.useYourTimesTableKnowledgeToWorkOutTheAmounts()}
        testCorrect={[essential.answer, [...sentenceTwo.answer]]}
        sentences={[essential.sentence, sentenceTwo.sentence]}
        questionHeight={600}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'arw2',
  description: 'arw2',
  keywords: ['Fraction', 'Divide', 'Amount'],
  schema: z
    .object({
      number1: z.number().int().min(3).max(5),
      number2: z.number().int(),
      number4: z.number().int().min(6).max(9),
      number5: z.number().int(),
      number7: z.number().int().min(10).max(12),
      number8: z.number().int(),
      isPounds: z.boolean()
    })
    .refine(val => val.number2 % val.number1 === 0, 'number2 must be a multiple of number1')
    .refine(val => val.number5 % val.number4 === 0, 'number5 must be a multiple of number4')
    .refine(val => val.number8 % val.number7 === 0, 'number8 must be a multiple of number7'),
  questionHeight: 600,
  simpleGenerator: () => {
    const multipleA = randomIntegerInclusive(1, 12);
    const multipleB = randomIntegerInclusive(1, 12);
    const multipleC = randomIntegerInclusive(1, 12);

    const number1 = randomIntegerInclusive(3, 5);
    const number2 = number1 * multipleA;
    const number4 = randomIntegerInclusive(6, 9);
    const number5 = number4 * multipleB;
    const number7 = randomIntegerInclusive(10, 12);
    const number8 = number7 * multipleC;

    const isPounds = getRandomBoolean();

    return { number1, number2, number4, number5, number7, number8, isPounds };
  },
  Component: props => {
    const {
      question: { number1, number2, number4, number5, number7, number8, isPounds },
      translate,
      displayMode
    } = props;

    const number3 = number2 / number1;
    const number6 = number5 / number4;
    const number9 = number8 / number7;

    const LHS = [
      translate.answerSentences.fractionOfNumber(1, number1.toLocaleString(), number2),
      isPounds
        ? translate.answerSentences.fractionOfPounds(1, number4.toLocaleString(), number5)
        : translate.answerSentences.fractionOfMetres(1, number7.toLocaleString(), number8)
    ];

    const RHS = [
      '<ans/>',
      isPounds ? translate.answerSentences.poundAns() : translate.answerSentences.ansM()
    ];

    return (
      <QF2AlignedEquations
        title={translate.instructions.useYourTimesTableKnowledgeToWorkOutTheAmounts()}
        testCorrect={{
          left: [[], []],
          right: [[number3.toString()], [isPounds ? number6.toString() : number9.toString()]]
        }}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        leftSide={LHS}
        rightSide={RHS}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'arx',
  description: 'arx',
  keywords: ['Fraction', 'Divide', 'Amount'],
  schema: z
    .object({
      totalParts: z.number().int().min(3).max(12),
      shadedParts: z.number().int().min(2).max(11),
      amountPerPart: z.number().int().min(10).max(100)
    })
    .refine(val => val.shadedParts < val.totalParts, 'shadedParts must be less than totalParts.'),
  questionHeight: 1100,
  simpleGenerator: () => {
    const totalParts = randomIntegerInclusive(3, 12);

    const shadedParts = randomIntegerInclusive(2, totalParts - 1, {
      constraint: x => {
        const simplifiedFraction = simplify(x, totalParts);

        // Should produce a fraction already in its simplest form:
        return simplifiedFraction[0] === x;
      }
    });

    const amountPerPart = randomIntegerInclusive(10, 100);

    return { totalParts, shadedParts, amountPerPart };
  },

  Component: props => {
    const {
      question: { totalParts, shadedParts, amountPerPart },
      translate,
      displayMode
    } = props;

    const totalOfBarModel = totalParts * amountPerPart;

    const totalOfShadedSection = shadedParts * amountPerPart;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numeratorColorArray = filledArray(numeratorColor, shadedParts);
    const remainderColorArray = filledArray('white', totalParts - shadedParts);

    const customColorMap = [...numeratorColorArray, ...remainderColorArray];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1100}
        sentence={translate.answerSentences.xOfYAns(
          `<frac n='${shadedParts}' d='${totalParts}'/>`,
          totalOfBarModel
        )}
        title={translate.instructions.useDiagramToHelpCompleteCalculation()}
        testCorrect={[totalOfShadedSection.toString()]}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        Content={({ dimens }) => (
          <ShadedFractionBarModel
            totalSubSections={totalParts}
            width={dimens.width}
            height={dimens.height / 3}
            customColorMap={customColorMap}
            fullWidthTopBrace={totalOfBarModel.toLocaleString()}
            topBraceTextStyle={{
              fontSize: displayMode === 'digital' ? 40 : 50,
              top: displayMode === 'digital' ? -72 : -84
            }}
            partWidthBottomBrace={{
              size: shadedParts,
              text: '?'
            }}
            bottomBraceTextStyle={{
              fontSize: displayMode === 'digital' ? 40 : 50
            }}
          />
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ary',
  description: 'ary',
  keywords: ['Fraction', 'Divide', 'Amount'],
  schema: z
    .object({
      number1: z.number().int().min(200).max(700),
      number2: z.number().int().min(2).max(9),
      number3: z.number().int().min(3).max(10)
    })
    .refine(val => val.number1 % val.number3 === 0, 'number1 must be a multiple of number3')
    .refine(val => val.number2 < val.number3, 'number2 must be less than number3'),
  simpleGenerator: () => {
    const number3 = randomIntegerInclusive(3, 10);
    const number1 =
      randomIntegerInclusive(Math.ceil(200 / number3), Math.floor(700 / number3)) * number3;
    const number2 = randomIntegerInclusive(2, 9, {
      constraint: x => x < number3
    });

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    // Answer
    const answer = ((number1 / number3) * number2).toString();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aFactoryMakesXCarsHowManyAreElectric({
          numberOfCars: number1,
          frac: `<frac n='${number2.toLocaleString()}' d='${number3.toLocaleString()}'/>`
        })}
        titleStyle={{ rowGap: 2 }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[answer]}
        sentence={translate.objects.xCars('<ans/>')}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'arz',
  description: 'arz',
  keywords: ['Fraction', 'Divide', 'Amount'],
  schema: z
    .object({
      numerator: z.number().int().min(1).max(11),
      denominator: z.number().int().min(3).max(12),
      kgMultiplier: z.number().int().min(10).max(100).multipleOf(10)
    })
    .refine(val => val.numerator < val.denominator, 'numerator must be less than denominator.'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 12);

    const numerator = randomIntegerInclusive(1, denominator - 1, {
      constraint: x => {
        const simplifiedFraction = simplify(x, denominator);

        // Should produce a fraction already in its simplest form:
        return simplifiedFraction[0] === x;
      }
    });

    const kgMultiplier = randomIntegerInclusiveStep(10, 100, 10);

    return { numerator, denominator, kgMultiplier };
  },
  Component: props => {
    const {
      question: { numerator, denominator, kgMultiplier },
      translate,
      displayMode
    } = props;

    const totalKg = denominator * kgMultiplier;

    const totalKgString = translate.units.numberOfKg(totalKg);

    const productKg = numerator * kgMultiplier;

    const productKgString = translate.units.numberOfKg(productKg);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumber()}
        testCorrect={[numerator.toString()]}
        fractionContainerStyle={{ height: 96 }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        sentence={translate.answerSentences.xOfYEqualsZ(
          `<frac nAns='' d='${denominator}' />`,
          totalKgString,
          productKgString
        )}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'arA',
  description: 'arA',
  keywords: ['Fraction', 'Amount'],
  schema: z.object({
    number1: z.number().int().min(2).max(12),
    number2: z.number().int().min(2).max(5),
    number3: z.number().int().min(1).max(4),
    number5: z.number().int().min(2).max(8),
    number6: z.number().int().min(1).max(7),
    algebraicLetter: algebraicSymbolSchema
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const { number1, number2, number5, number6 } = rejectionSample(
      () => {
        const number1 = randomIntegerInclusive(2, 12);

        const number2 = randomIntegerInclusive(2, 5);

        const number5 = randomIntegerInclusive(2, 8);

        const number6LowerBound = Math.ceil(number5 / 2);

        const number6 = randomIntegerInclusive(number6LowerBound, number5 - 1);
        return { number1, number2, number5, number6 };
      },
      ({ number1, number2, number6 }) => {
        const number7 = number1 * number2;
        const number8 = number7 / number6;

        // variables must allow number8 to be a whole number:
        return number8 % 1 === 0;
      }
    );

    const number3 = randomIntegerInclusive(1, number2 - 1);

    const algebraicLetter = getAlgebraicSymbol();

    return {
      number1,
      number2,
      number3,
      number5,
      number6,
      algebraicLetter
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number5, number6, algebraicLetter },
      translate,
      displayMode
    } = props;
    const number4 = number1 * number3;
    const number7 = number1 * number2;
    const number8 = number7 / number6;
    const number9 = number8 * number5;

    const [numeratorColorA, numeratorColorB] = getRandomSubArrayFromArray(
      Object.values(barModelColors),
      2,
      {
        random: seededRandom(props.question)
      }
    ) as string[];

    // Bar model A
    const numeratorColorArrayA = filledArray(numeratorColorA, number6);
    const remainderA = filledArray('white', number5 - number6);
    const customColorMapA = [...numeratorColorArrayA, ...remainderA];

    // Bar model B
    const numeratorColorArrayB = filledArray(numeratorColorB, number3);
    const remainderB = filledArray('white', number2 - number3);
    const customColorMapB = [...numeratorColorArrayB, ...remainderB];

    const barModelBScale = number6 / number5;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        sentence={`${algebraicLetter} = <ans/>`}
        title={translate.instructions.findValueOfX(algebraicLetter)}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[number4.toString()]}
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'flex-start' }]}>
            <ShadedFractionBarModel
              containerStyle={{ justifyContent: 'flex-end' }}
              totalSubSections={number5}
              width={dimens.width}
              height={dimens.height / 3}
              customColorMap={customColorMapA}
              fullWidthTopBrace={number9.toLocaleString()}
              topBraceTextStyle={{
                fontSize: displayMode === 'digital' ? 32 : 50,
                top: displayMode === 'digital' ? -64 : -84
              }}
            />
            <ShadedFractionBarModel
              containerStyle={{ justifyContent: 'flex-start', marginTop: 16 }}
              totalSubSections={number2}
              width={dimens.width * barModelBScale}
              height={dimens.height / 3}
              customColorMap={customColorMapB}
              partWidthBottomBrace={{ size: number3, text: algebraicLetter }}
              bottomBraceTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
            />
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FractionOfAnAmount',
  questionTypes: [Question1, Question2v2, Question3, Question4, Question5, Question6],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
