import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import { nameSchema, getRandomName } from '../../../../utils/names';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { CompleteTheSentenceWithState } from '../../../../components/molecules/CompleteTheSentence';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'arY',
  description: 'arY',
  keywords: ['Multiply', 'Multi-step'],
  schema: z.object({
    rows: z.number().int().min(12).max(18).multipleOf(2),
    columns: z.number().int().min(22).max(28).multipleOf(2),
    sheets: z
      .number()
      .int()
      .min(31)
      .max(59)
      .refine(val => val % 10 !== 0, 'sheets must not be a multiple of 10')
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const rows = randomIntegerInclusiveStep(12, 18, 2);

    const columns = randomIntegerInclusiveStep(22, 28, 2);

    const sheets = randomIntegerInclusive(31, 59, {
      constraint: x => x % 10 !== 0
    });

    return { rows, columns, sheets };
  },
  Component: props => {
    const {
      question: { rows, columns, sheets },
      translate
    } = props;

    const total = rows * columns * sheets;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.aSheetOfStampsHasNumRowsAndNumColsHowManyStampsAreThereOnNumSheets(
          rows,
          columns,
          sheets
        )}
        testCorrect={[[], [total.toString()]]}
        sentences={['', '<ans/>']}
        mainPanelContainerStyle={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          alignSelf: 'flex-end'
        }}
        pdfMainPanelContainerStyle={{
          alignSelf: 'flex-end'
        }}
        textStyle={{ fontSize: 32 }}
        questionHeight={500}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'arZ',
  description: 'arZ',
  keywords: ['Divide', 'Multiply', 'Multi-step'],
  schema: z
    .object({
      sweetsPerBag: z.number().int().min(12).max(16).multipleOf(2),
      bags: z.number().int().min(14).max(20),
      sweetsOrBagsEaten: z.number().int().min(4).max(11),
      sweetsOrBags: z.enum(['sweets', 'bags'])
    })
    .refine(val => val.sweetsPerBag !== val.bags, 'sweetsPerBag must not be equal to bags.'),
  simpleGenerator: () => {
    const sweetsPerBag = randomIntegerInclusiveStep(12, 16, 2);

    const bags = randomIntegerInclusive(14, 20, {
      constraint: x => x !== sweetsPerBag
    });

    const sweetsOrBagsEaten = randomIntegerInclusive(4, 11);

    const sweetsOrBags = getRandomFromArray(['sweets', 'bags'] as const);

    return { sweetsPerBag, bags, sweetsOrBagsEaten, sweetsOrBags };
  },
  Component: props => {
    const {
      question: { sweetsPerBag, bags, sweetsOrBagsEaten, sweetsOrBags },
      translate
    } = props;

    const sweetsRemainingAfterSweetsEaten = sweetsPerBag * bags - sweetsOrBagsEaten;

    const sweetsRemainingAfterBagsEaten = sweetsPerBag * bags - sweetsOrBagsEaten * sweetsPerBag;

    const instruction =
      sweetsOrBags === 'sweets'
        ? translate.instructions.thereAreNumSweetsInABagIfTheyEatNumSweets(
            sweetsPerBag,
            bags,
            sweetsOrBagsEaten
          )
        : translate.instructions.thereAreNumSweetsInABagIfTheyEatNumBagsOfSweets(
            sweetsPerBag,
            bags,
            sweetsOrBagsEaten
          );

    return (
      <QF2AnswerBoxOneSentence
        title={instruction}
        testCorrect={[
          sweetsOrBags === 'sweets'
            ? sweetsRemainingAfterSweetsEaten.toString()
            : sweetsRemainingAfterBagsEaten.toString()
        ]}
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ar0',
  description: 'ar0',
  keywords: ['Divide', 'Multiply', 'Multi-step'],
  schema: z.object({
    pastaPerPortion: z.number().int().min(40).max(100).multipleOf(10),
    portions: z.number().int().min(8).max(16),
    kilosOfPasta: z.number().int().min(14).max(20).multipleOf(2)
  }),
  simpleGenerator: () => {
    const pastaPerPortion = randomIntegerInclusiveStep(40, 100, 10);

    const portions = randomIntegerInclusive(8, 16);

    const kilosOfPasta = randomIntegerInclusiveStep(14, 20, 2);

    return { pastaPerPortion, portions, kilosOfPasta };
  },
  Component: props => {
    const {
      question: { pastaPerPortion, portions, kilosOfPasta },
      translate,
      displayMode
    } = props;

    const pastaForNumPortions = pastaPerPortion * portions;

    const portionsFromNumKilosOfPasta = Math.floor((kilosOfPasta * 1000) / pastaPerPortion);

    return (
      <QF3Content
        title={translate.instructions.thereIsNumGOfPasta(pastaPerPortion)}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-between' }]}>
            <Text
              variant="WRN400"
              style={{ alignSelf: 'flex-start', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.answerSentences.howMuchPastaIsNeededForNumPortions(portions)}
            </Text>
            <CompleteTheSentenceWithState
              id="sentenceA"
              sentence={translate.answerSentences.ansG()}
              testCorrect={userAnswer => userAnswer[0] === pastaForNumPortions.toString()}
              defaultState={
                displayMode === 'markscheme' ? [pastaForNumPortions.toLocaleString()] : undefined
              }
              style={{ alignSelf: 'flex-end' }}
              inputMaxCharacters={4}
            />
            <Text
              variant="WRN400"
              style={{ alignSelf: 'flex-start', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.answerSentences.howManyPortionsCanBeMadeFromNumKgOfPasta(kilosOfPasta)}
            </Text>
            <CompleteTheSentenceWithState
              id="sentenceB"
              sentence={translate.answerSentences.ansPortions()}
              testCorrect={userAnswer => userAnswer[0] === portionsFromNumKilosOfPasta.toString()}
              defaultState={
                displayMode === 'markscheme'
                  ? [portionsFromNumKilosOfPasta.toLocaleString()]
                  : undefined
              }
              style={{ alignSelf: 'flex-end' }}
              inputMaxCharacters={4}
            />
          </View>
        )}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'ar02',
  description: 'ar02',
  keywords: ['Divide', 'Multiply', 'Multi-step'],
  schema: z.object({
    pastaPerPortion: z.number().int().min(40).max(100).multipleOf(10),
    portions: z.number().int().min(8).max(16),
    kilosOfPasta: z.number().min(1).max(5).multipleOf(0.5)
  }),
  simpleGenerator: () => {
    const pastaPerPortion = randomIntegerInclusiveStep(40, 100, 10);

    const portions = randomIntegerInclusive(8, 16);

    const kilosOfPasta = randomIntegerInclusiveStep(10, 50, 5) / 10;

    return { pastaPerPortion, portions, kilosOfPasta };
  },
  Component: props => {
    const {
      question: { pastaPerPortion, portions, kilosOfPasta },
      translate,
      displayMode
    } = props;

    const pastaForNumPortions = pastaPerPortion * portions;

    const portionsFromNumKilosOfPasta = Math.floor((kilosOfPasta * 1000) / pastaPerPortion);

    return (
      <QF3Content
        title={translate.instructions.thereIsNumGOfPasta(pastaPerPortion)}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-between' }]}>
            <Text
              variant="WRN400"
              style={{ alignSelf: 'flex-start', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.answerSentences.howMuchPastaIsNeededForNumPortions(portions)}
            </Text>
            <CompleteTheSentenceWithState
              id="sentenceA"
              sentence={translate.answerSentences.ansG()}
              testCorrect={userAnswer => userAnswer[0] === pastaForNumPortions.toString()}
              defaultState={
                displayMode === 'markscheme' ? [pastaForNumPortions.toLocaleString()] : undefined
              }
              style={{ alignSelf: 'flex-end' }}
              inputMaxCharacters={4}
            />
            <Text
              variant="WRN400"
              style={{ alignSelf: 'flex-start', fontSize: displayMode === 'digital' ? 32 : 50 }}
            >
              {translate.answerSentences.howManyPortionsCanBeMadeFromNumKgOfPasta(kilosOfPasta)}
            </Text>
            <CompleteTheSentenceWithState
              id="sentenceB"
              sentence={translate.answerSentences.ansPortions()}
              testCorrect={userAnswer => userAnswer[0] === portionsFromNumKilosOfPasta.toString()}
              defaultState={
                displayMode === 'markscheme'
                  ? [portionsFromNumKilosOfPasta.toLocaleString()]
                  : undefined
              }
              style={{ alignSelf: 'flex-end' }}
              inputMaxCharacters={4}
            />
          </View>
        )}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ar1',
  description: 'ar1',
  keywords: ['Divide', 'Multiply', 'Multi-step'],
  schema: z.object({
    miles: z.number().int().min(11).max(19),
    minutes: numberEnum([10, 15, 20, 30]),
    hours: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const minutes = getRandomFromArray([10, 15, 20, 30] as const);
    const miles = randomIntegerInclusive(11, 19, {
      constraint: x => {
        const mph = x / (minutes / 60);
        return mph <= 70;
      }
    });

    const hours = randomIntegerInclusive(2, 6);

    return { miles, minutes, hours };
  },
  Component: props => {
    const {
      question: { miles, minutes, hours },
      translate
    } = props;

    const totalMiles = (60 / minutes) * miles * hours;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.aCarTravelsNumMilesInNumMinutes(miles, minutes, hours)}
        testCorrect={[totalMiles.toString()]}
        sentence={translate.answerSentences.ansMiles()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ar2',
  description: 'ar2',
  keywords: ['Divide', 'Multiply', 'Multi-step'],
  schema: z
    .object({
      name: nameSchema,
      friends: z.number().int().min(6).max(9),
      bags: z.number().int().min(3).max(5),
      marbles: z
        .number()
        .int()
        .min(101)
        .max(199)
        .refine(val => val % 10 !== 0, 'marbles cannot be a multiple of 10')
    })
    .refine(
      val => (val.bags * val.marbles) % (val.friends + 1) === 0,
      'bags x marbles must be a multiple of friends + 1'
    ),
  questionHeight: 700,
  simpleGenerator: () => {
    const name = getRandomName();

    const { friends, bags, marbles } = rejectionSample(
      () => {
        const friends = randomIntegerInclusive(6, 9);

        const bags = randomIntegerInclusive(3, 5);

        const marbles = randomIntegerInclusive(101, 199, {
          constraint: x => x % 10 !== 0
        });

        return { name, friends, bags, marbles };
      },
      // Only permit them if they have no exchanges.
      ({ friends, bags, marbles }) => (bags * marbles) % (friends + 1) === 0
    );

    return { name, friends, bags, marbles };
  },
  Component: props => {
    const {
      question: { name, friends, bags, marbles },
      translate
    } = props;

    const marblesPerPerson = (bags * marbles) / (friends + 1);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.characterIsPlayingMarblesWithFriends(
          name,
          friends,
          bags,
          marbles
        )}
        testCorrect={[marblesPerPerson.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        questionHeight={700}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ar3',
  description: 'ar3',
  keywords: ['Divide', 'Multiply', 'Multi-step', 'Shape'],
  schema: z.object({
    lengthPerSide: z.number().int().min(2).max(10),
    shapes: z.enum(['hexagon', 'octagon']),
    shapeColours: z.enum(['blue and orange', 'green and yellow', 'red and blue']),
    numberOfShapes: z.number().int().min(3).max(6)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const lengthPerSide = randomIntegerInclusive(2, 10);

    const shapes = getRandomFromArray(['hexagon', 'octagon'] as const);

    const numberOfShapes = randomIntegerInclusive(3, 6);

    const shapeColours = getRandomFromArray([
      'blue and orange',
      'green and yellow',
      'red and blue'
    ] as const);

    return { lengthPerSide, shapes, numberOfShapes, shapeColours };
  },

  Component: props => {
    const {
      question: { lengthPerSide, shapes, numberOfShapes, shapeColours },
      translate,
      displayMode
    } = props;

    const svgPath = (() => {
      if (shapeColours === 'blue and orange') {
        if (shapes === 'hexagon') {
          switch (numberOfShapes) {
            case 3:
              return 'Shape_patterns/Linked_hexagons/3_blue_orange_hexagon_pattern';
            case 4:
              return 'Shape_patterns/Linked_hexagons/4_blue_orange_hexagon_pattern';
            case 5:
              return 'Shape_patterns/Linked_hexagons/5_blue_orange_hexagon_pattern';
            case 6:
              return 'Shape_patterns/Linked_hexagons/6_blue_orange_hexagon_pattern';
          }
        } else {
          switch (numberOfShapes) {
            case 3:
              return 'Shape_patterns/Linked_octagons/3_blue_orange_octagon_pattern';
            case 4:
              return 'Shape_patterns/Linked_octagons/4_blue_orange_octagon_pattern';
            case 5:
              return 'Shape_patterns/Linked_octagons/5_blue_orange_octagon_pattern';
            case 6:
              return 'Shape_patterns/Linked_octagons/6_blue_orange_octagon_pattern';
          }
        }
      }
      if (shapeColours === 'green and yellow') {
        if (shapes === 'hexagon') {
          switch (numberOfShapes) {
            case 3:
              return 'Shape_patterns/Linked_hexagons/3_green_yellow_hexagon_pattern';
            case 4:
              return 'Shape_patterns/Linked_hexagons/4_green_yellow_hexagon_pattern';
            case 5:
              return 'Shape_patterns/Linked_hexagons/5_green_yellow_hexagon_pattern';
            case 6:
              return 'Shape_patterns/Linked_hexagons/6_green_yellow_hexagon_pattern';
          }
        } else {
          switch (numberOfShapes) {
            case 3:
              return 'Shape_patterns/Linked_octagons/3_green_yellow_octagon_pattern';
            case 4:
              return 'Shape_patterns/Linked_octagons/4_green_yellow_octagon_pattern';
            case 5:
              return 'Shape_patterns/Linked_octagons/5_green_yellow_octagon_pattern';
            case 6:
              return 'Shape_patterns/Linked_octagons/6_green_yellow_octagon_pattern';
          }
        }
      }
      if (shapes === 'hexagon') {
        switch (numberOfShapes) {
          case 3:
            return 'Shape_patterns/Linked_hexagons/3_red_blue_hexagon_pattern';
          case 4:
            return 'Shape_patterns/Linked_hexagons/4_red_blue_hexagon_pattern';
          case 5:
            return 'Shape_patterns/Linked_hexagons/5_red_blue_hexagon_pattern';
          case 6:
            return 'Shape_patterns/Linked_hexagons/6_red_blue_hexagon_pattern';
        }
      } else {
        switch (numberOfShapes) {
          case 3:
            return 'Shape_patterns/Linked_octagons/3_red_blue_octagon_pattern';
          case 4:
            return 'Shape_patterns/Linked_octagons/4_red_blue_octagon_pattern';
          case 5:
            return 'Shape_patterns/Linked_octagons/5_red_blue_octagon_pattern';
          case 6:
            return 'Shape_patterns/Linked_octagons/6_red_blue_octagon_pattern';
        }
      }
    })();

    const sidesPerShape = shapes === 'hexagon' ? 6 : 8;

    const totalSides = 2 * (sidesPerShape - 1) + (numberOfShapes - 2) * (sidesPerShape - 2);

    const perimeterOfPattern = lengthPerSide * totalSides;

    const perimeterPerShape = lengthPerSide * sidesPerShape;

    const shapeStringPlural =
      shapes === 'hexagon'
        ? translate.shapes.hexagons(numberOfShapes)
        : translate.shapes.octagons(numberOfShapes);

    const shapeStringSingle =
      shapes === 'hexagon' ? translate.shapes.hexagons(1) : translate.shapes.octagons(1);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.aPatternIsMadeUpOfRegularShapes(shapeStringPlural)}
        testCorrect={[perimeterPerShape.toString()]}
        sentence={`<ans/> ${translate.units.cm()}`}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <AssetSvg name={svgPath as SvgName} width={dimens.width} height={dimens.height * 0.5} />
            <Text
              variant="WRN400"
              style={{ fontSize: displayMode === 'digital' ? 32 : 50, alignSelf: 'flex-start' }}
            >
              {translate.answerSentences.thePerimeterOfTheWholePatternIs(perimeterOfPattern)}
            </Text>
            <Text
              variant="WRN400"
              style={{ fontSize: displayMode === 'digital' ? 32 : 50, alignSelf: 'flex-start' }}
            >
              {translate.answerSentences.whatIsThePerimeterOfEachShape(shapeStringSingle)}
            </Text>
          </View>
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveMultiStepProblems',
  questionTypes: [Question1, Question2, Question3v2, Question4, Question5, Question6],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
