import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { BarModel } from '../../../../components/question/representations/BarModel';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { numberEnum } from '../../../../utils/zod';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { PieChartInteractiveWithState } from '../../../../components/question/representations/PieChartInteractive';
import { colors } from '../../../../theme/colors';
import { isEqual } from '../../../../utils/matchers';
import { View } from 'react-native';
import { CreateShapeFromSquaresWithState } from '../../../../components/question/representations/CreateShapeFromSquares';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkF',
  description: 'bkF',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half', 'Quarter'],
  schema: z.object({
    halfOrQuarter: z.enum(['half', 'quarter']),
    shape: z.enum([
      'circle',
      'squareDiagonal',
      'squareGrid',
      'rectangleGrid',
      'rectangle',
      'square'
    ]),
    startingAngle: numberEnum([0, 45, 90])
  }),
  simpleGenerator: () => {
    const halfOrQuarter = getRandomFromArray(['half', 'quarter'] as const);
    const shape =
      halfOrQuarter === 'half'
        ? getRandomFromArray([
            'circle',
            'squareDiagonal',
            'squareGrid',
            'rectangleGrid',
            'rectangle',
            'square'
          ] as const)
        : // squareDiagonal doesn't work when you only have 2 parts
          getRandomFromArray([
            'circle',
            'squareGrid',
            'rectangleGrid',
            'rectangle',
            'square'
          ] as const);

    const startingAngle = ['circle', 'squareDiagonal'].includes(shape)
      ? getRandomFromArray([0, 45] as const)
      : getRandomFromArray([0, 90] as const);

    return { halfOrQuarter, shape, startingAngle };
  },
  Component: ({ question: { halfOrQuarter, shape, startingAngle }, translate, displayMode }) => {
    const fraction = halfOrQuarter === 'half' ? '<frac n="1" d="2" />' : '<frac n="2" d="4" />';

    return (
      <QF3Content
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.tapToShadeInXOfTheShape(fraction)}
        pdfTitle={translate.ks1PDFInstructions.shadeInXOfTheShape(fraction)}
        Content={({ dimens }) => {
          switch (shape) {
            case 'circle':
            case 'squareDiagonal':
              return (
                <PieChartInteractiveWithState
                  numberOfSlices={halfOrQuarter === 'half' ? 4 : 2}
                  radius={Math.min(dimens.width, dimens.height) * 0.5}
                  options={[{ color: colors.burntSienna, label: '' }]}
                  id={'pieChart'}
                  noCurve={shape === 'squareDiagonal'}
                  testCorrect={userAnswer =>
                    isEqual(userAnswer.filter(val => val === 1).length)(
                      halfOrQuarter === 'half' ? 2 : 1
                    )
                  }
                  testComplete={userAnswer => userAnswer.some(it => it !== 0)}
                  borderWidth={3}
                  startAngle={startingAngle}
                  defaultState={
                    displayMode === 'markscheme'
                      ? halfOrQuarter === 'half'
                        ? [1, 1, 0, 0]
                        : [1, 0]
                      : undefined
                  }
                  showShadingInMarkScheme
                />
              );
            case 'squareGrid':
            case 'rectangleGrid':
            case 'rectangle':
            case 'square': {
              const squareDimens = Math.min(dimens.width, dimens.height);
              const usableDimens =
                shape === 'square'
                  ? { width: squareDimens, height: squareDimens }
                  : startingAngle === 90
                  ? { width: dimens.height * 0.8, height: dimens.width * 0.8 }
                  : { width: dimens.width * 0.8, height: dimens.height * 0.8 };
              return (
                <View style={{ transform: `rotate(${startingAngle}deg)` }}>
                  <CreateShapeFromSquaresWithState
                    dimens={usableDimens}
                    numberOfRows={['squareGrid', 'rectangleGrid'].includes(shape) ? 2 : 1}
                    numberOfCols={
                      ['squareGrid', 'rectangleGrid'].includes(shape)
                        ? halfOrQuarter === 'half'
                          ? 2
                          : 1
                        : halfOrQuarter === 'half'
                        ? 4
                        : 2
                    }
                    id={'squareGridA'}
                    isSquare={shape === 'squareGrid'}
                    testCorrect={userAnswer =>
                      isEqual(userAnswer.flat().filter(val => val).length)(
                        halfOrQuarter === 'half' ? 2 : 1
                      )
                    }
                    defaultState={
                      displayMode === 'markscheme'
                        ? halfOrQuarter === 'half'
                          ? [[true, true, false, false]]
                          : [[true, false]]
                        : undefined
                    }
                  />
                </View>
              );
            }
          }
        }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.anyXPartsShaded(1) }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bkG',
  description: 'bkG',
  keywords: ['Fraction', 'Parts', 'Half', 'Quarter'],
  schema: z.object({
    whole: z.number().int().min(8).max(24).multipleOf(4)
  }),
  simpleGenerator: () => {
    const whole = randomIntegerInclusiveStep(8, 24, 4);

    return { whole };
  },
  Component: props => {
    const {
      question: { whole },
      translate,
      displayMode
    } = props;
    const numbers = [[whole], [whole / 2, whole / 2], [whole / 4, whole / 4, whole / 4, whole / 4]];
    const sentenceAndAnswers = shuffle(
      [
        {
          sentence: translate.ks1AnswerSentences.xOfYEqualsAns('<frac n="1" d="2" />', whole),
          answer: whole / 2
        },
        {
          sentence: translate.ks1AnswerSentences.xOfYEqualsAns('<frac n="1" d="4" />', whole),
          answer: whole / 4
        },
        {
          sentence: translate.ks1AnswerSentences.xOfYEqualsAns('<frac n="2" d="4" />', whole),
          answer: whole / 2
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.workOutTheMissingNumbers()}
        Content={({ dimens }) => (
          <BarModel
            numbers={numbers}
            total={whole}
            dimens={{ height: dimens.height * 0.5, width: dimens.width * 0.95 }}
            maxFontSize={displayMode === 'digital' ? 32 : 44}
            sameRowColor
          />
        )}
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={sentenceAndAnswers.map(({ sentence }) => sentence)}
        fractionTextStyle={displayMode === 'digital' && { fontSize: 32 }}
        testCorrect={sentenceAndAnswers.map(({ answer }) => [answer.toString()])}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkH',
  description: 'bkH',
  keywords: ['Fraction', 'Parts', 'Half', 'Quarter', 'Equivalence'],
  schema: z.object({
    whole: z.number().int().min(8).max(24).multipleOf(4),
    units: z.enum(['£', 'kg', 'l', 'ml', 'g'])
  }),
  simpleGenerator: () => {
    const whole = randomIntegerInclusiveStep(8, 24, 4);
    const units = getRandomFromArray(['£', 'kg', 'l', 'ml', 'g'] as const);

    return { whole, units };
  },
  Component: props => {
    const {
      question: { whole, units },
      translate
    } = props;

    const { sentence, title } = (() => {
      switch (units) {
        case '£':
          return {
            sentence: translate.ks1AnswerSentences.poundAns(),
            title: translate.ks1AnswerSentences.poundsX(whole.toLocaleString())
          };
        case 'g':
          return {
            sentence: translate.ks1AnswerSentences.ansG(),
            title: translate.units.numberOfG(whole)
          };
        case 'kg':
          return {
            sentence: translate.ks1AnswerSentences.ansKg(),
            title: translate.units.numberOfKg(whole)
          };
        case 'ml':
          return {
            sentence: translate.ks1AnswerSentences.ansMl(),
            title: translate.units.numberOfMl(whole)
          };
        case 'l':
          return {
            sentence: translate.ks1AnswerSentences.ansL(),
            title: translate.units.numberOfL(whole)
          };
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.findFracOfX('<frac n="2" d="4" />', title)}
        sentence={sentence}
        testCorrect={[(whole / 2).toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        questionHeight={400}
      />
    );
  },
  questionHeight: 400
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseEquivalenceOfHalfAndTwoQuarters',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
