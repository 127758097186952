import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { View } from 'react-native';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import {
  BarModelColorsKey,
  barModelColors,
  barModelColorsArray,
  barModelColorsSchema
} from '../../../../theme/colors';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { fractionToDecimal } from '../../../../utils/fractions';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import {
  countRange,
  nestedArrayHasNoDuplicates,
  sortNumberArray
} from '../../../../utils/collections';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aMu',
  description: 'aMu',
  keywords: [
    'Bar model',
    'Fraction',
    'Mixed number',
    'Numerators',
    'Denominators',
    'Whole number',
    'Whole',
    'Parts'
  ],
  schema: z.object({
    sections1: z.number().int().min(2).max(6),
    shadedSections1: z.number().int().min(1).max(6),
    whole1: z.number().int().min(1).max(4),
    sections2: z.number().int().min(2).max(6),
    shadedSections2: z.number().int().min(1).max(6),
    whole2: z.number().int().min(1).max(4)
  }),
  simpleGenerator: () => {
    const sections1 = randomIntegerInclusive(2, 6);
    const sections2 = randomIntegerInclusive(2, 6);

    const shadedSections1 = randomIntegerInclusive(1, sections1);
    const shadedSections2 = randomIntegerInclusive(1, sections2);

    const whole1 = randomIntegerInclusive(1, 4);
    const whole2 = randomIntegerInclusive(
      1,
      4,
      sections1 === sections2 && shadedSections1 === shadedSections2
        ? { constraint: x => x !== whole1 }
        : undefined
    );

    return {
      sections1,
      sections2,
      shadedSections1,
      shadedSections2,
      whole1,
      whole2
    };
  },
  Component: props => {
    const {
      question: { sections1, sections2, shadedSections1, shadedSections2, whole1, whole2 },
      translate
    } = props;

    const barModelColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    }) as BarModelColorsKey;

    const items = shuffle(
      [
        {
          value: fractionToDecimal(whole1 * sections1 + shadedSections1, sections1),
          whole: whole1,
          parts: sections1,
          shaded: shadedSections1
        },
        {
          value: fractionToDecimal(whole2 * sections2 + shadedSections2, sections2),
          whole: whole2,
          parts: sections2,
          shaded: shadedSections2
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectImageOfMixedNumberThatIsGreater()}
        pdfTitle={translate.instructions.selectImageOfMixedNumberThatIsGreater()}
        testCorrect={[
          Math.max(
            fractionToDecimal(whole1 * sections1 + shadedSections1, sections1),
            fractionToDecimal(whole2 * sections2 + shadedSections2, sections2)
          )
        ]}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(({ whole, parts, shaded, value }) => ({
            component: (
              <>
                {countRange(whole + 1).map(i =>
                  i === whole ? (
                    <ShadedFractionBarModel
                      key={i}
                      totalSubSections={parts}
                      width={dimens.width * 0.8}
                      color={barModelColors[barModelColor]}
                      coloredSections={countRange(shaded)}
                    />
                  ) : (
                    <ShadedFractionBarModel
                      key={i}
                      totalSubSections={parts}
                      width={dimens.width * 0.8}
                      color={barModelColors[barModelColor]}
                      coloredSections={countRange(parts)}
                    />
                  )
                )}
              </>
            ),
            value
          }));
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question1v2 = newQuestionContent({
  uid: 'aMu2',
  description: 'aMu',
  keywords: [
    'Bar model',
    'Fraction',
    'Mixed number',
    'Numerators',
    'Denominators',
    'Whole number',
    'Whole',
    'Parts'
  ],
  schema: z
    .object({
      sections1: z.number().int().min(2).max(6),
      shadedSections1: z.number().int().min(1).max(5),
      whole1: z.number().int().min(1).max(4),
      sections2: z.number().int().min(2).max(6),
      shadedSections2: z.number().int().min(1).max(5),
      whole2: z.number().int().min(1).max(4),
      barModelColor: barModelColorsSchema
    })
    .refine(
      val => val.shadedSections1 < val.sections1,
      'shaded sections 1 must be less than sections 1'
    )
    .refine(
      val => val.shadedSections2 < val.sections2,
      'shaded sections 2 must be less than sections 2'
    ),
  simpleGenerator: () => {
    const sections1 = randomIntegerInclusive(2, 6);
    const sections2 = randomIntegerInclusive(2, 6);

    const shadedSections1 = randomIntegerInclusive(1, sections1 - 1);
    const shadedSections2 = randomIntegerInclusive(1, sections2 - 1);

    const whole1 = randomIntegerInclusive(1, 4);
    const whole2 = randomIntegerInclusive(
      1,
      4,
      sections1 === sections2 && shadedSections1 === shadedSections2
        ? { constraint: x => x !== whole1 }
        : undefined
    );

    const barModelColor = getRandomFromArray(barModelColorsArray) as BarModelColorsKey;

    return {
      sections1,
      sections2,
      shadedSections1,
      shadedSections2,
      whole1,
      whole2,
      barModelColor
    };
  },
  Component: props => {
    const {
      question: {
        sections1,
        sections2,
        shadedSections1,
        shadedSections2,
        whole1,
        whole2,
        barModelColor
      },
      translate
    } = props;

    const items = shuffle(
      [
        {
          value: fractionToDecimal(whole1 * sections1 + shadedSections1, sections1),
          whole: whole1,
          parts: sections1,
          shaded: shadedSections1
        },
        {
          value: fractionToDecimal(whole2 * sections2 + shadedSections2, sections2),
          whole: whole2,
          parts: sections2,
          shaded: shadedSections2
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectImageOfMixedNumberThatIsGreater()}
        pdfTitle={translate.instructions.circleImageOfMixedNumberThatIsGreater()}
        testCorrect={[
          Math.max(
            fractionToDecimal(whole1 * sections1 + shadedSections1, sections1),
            fractionToDecimal(whole2 * sections2 + shadedSections2, sections2)
          )
        ]}
        numItems={2}
        renderItems={({ dimens }) => {
          return items.map(({ whole, parts, shaded, value }) => ({
            component: (
              <>
                {countRange(whole + 1).map(i =>
                  i === whole ? (
                    <ShadedFractionBarModel
                      key={i}
                      totalSubSections={parts}
                      width={dimens.width * 0.8}
                      color={barModelColors[barModelColor]}
                      coloredSections={countRange(shaded)}
                    />
                  ) : (
                    <ShadedFractionBarModel
                      key={i}
                      totalSubSections={parts}
                      width={dimens.width * 0.8}
                      color={barModelColors[barModelColor]}
                      coloredSections={countRange(parts)}
                    />
                  )
                )}
              </>
            ),
            value
          }));
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aMv',
  description: 'aMv',
  keywords: ['Fraction', 'Mixed number', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    denominator1: z.number().int().min(2).max(5),
    numerator1: z.number().int().min(1).max(5),
    whole1: z.number().int().min(1).max(3),
    denominator2: z.number().int().min(2).max(5),
    numerator2: z.number().int().min(1).max(5),
    whole2: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(2, 5);
    const numerator1 = randomIntegerInclusive(1, denominator1);
    const whole1 = randomIntegerInclusive(1, 3);
    const denominator2 = randomIntegerInclusive(2, 5);
    const numerator2 = randomIntegerInclusive(1, denominator2);
    const whole2 = randomIntegerInclusive(1, 3);

    return {
      denominator1,
      numerator1,
      whole1,
      denominator2,
      numerator2,
      whole2
    };
  },
  Component: props => {
    const {
      question: { denominator1, numerator1, whole1, denominator2, numerator2, whole2 },
      translate,
      displayMode
    } = props;

    const barModelColor = getRandomFromArray(barModelColorsArray, {
      random: seededRandom(props.question)
    }) as BarModelColorsKey;

    const statements = [
      {
        lhsComponent: (
          <View style={{ justifyContent: 'space-evenly', alignItems: 'center', height: '100%' }}>
            {countRange(whole1 + 1).map(i =>
              i === whole1 ? (
                <ShadedFractionBarModel
                  key={`lhs_${i}`}
                  totalSubSections={denominator1}
                  width={displayMode === 'digital' ? 400 : 500}
                  color={barModelColors[barModelColor]}
                  coloredSections={countRange(numerator1)}
                />
              ) : (
                <ShadedFractionBarModel
                  key={`lhs_${i}`}
                  totalSubSections={denominator1}
                  width={displayMode === 'digital' ? 400 : 500}
                  color={barModelColors[barModelColor]}
                  coloredSections={countRange(denominator1)}
                />
              )
            )}
            <TextStructure
              textVariant="WRN400"
              sentence={`<frac w='${whole1.toLocaleString()}' n='${numerator1.toLocaleString()}' d='${denominator1.toLocaleString()}'/>`}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ justifyContent: 'space-evenly', alignItems: 'center', height: '100%' }}>
            {countRange(whole2 + 1).map(i =>
              i === whole2 ? (
                <ShadedFractionBarModel
                  key={`rhs_${i}`}
                  totalSubSections={denominator2}
                  width={displayMode === 'digital' ? 400 : 500}
                  color={barModelColors[barModelColor]}
                  coloredSections={countRange(numerator2)}
                />
              ) : (
                <ShadedFractionBarModel
                  key={`rhs_${i}`}
                  totalSubSections={denominator2}
                  width={displayMode === 'digital' ? 400 : 500}
                  color={barModelColors[barModelColor]}
                  coloredSections={countRange(denominator2)}
                />
              )
            )}
            <TextStructure
              textVariant="WRN400"
              sentence={`<frac w='${whole2.toLocaleString()}' n='${numerator2.toLocaleString()}' d='${denominator2.toLocaleString()}'/>`}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          fractionToDecimal(whole1 * denominator1 + numerator1, denominator1),
          fractionToDecimal(whole2 * denominator2 + numerator2, denominator2)
        )
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsCompareMixedNumbers()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareMixedNumbers()}
        statements={statements}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        items={['>', '<', '=']}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'aMv2',
  description: 'aMv',
  keywords: ['Fraction', 'Mixed number', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    denominator1: z.number().int().min(2).max(5),
    numerator1: z.number().int().min(1).max(5),
    whole1: z.number().int().min(1).max(3),
    denominator2: z.number().int().min(2).max(5),
    numerator2: z.number().int().min(1).max(5),
    whole2: z.number().int().min(1).max(3),
    barModelColor: barModelColorsSchema
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(2, 5);
    const numerator1 = randomIntegerInclusive(1, denominator1);
    const whole1 = randomIntegerInclusive(1, 3);
    const denominator2 = randomIntegerInclusive(2, 5);
    const numerator2 = randomIntegerInclusive(1, denominator2);
    const whole2 = randomIntegerInclusive(1, 3);
    const barModelColor = getRandomFromArray(barModelColorsArray) as BarModelColorsKey;

    return {
      denominator1,
      numerator1,
      whole1,
      denominator2,
      numerator2,
      whole2,
      barModelColor
    };
  },
  Component: props => {
    const {
      question: {
        denominator1,
        numerator1,
        whole1,
        denominator2,
        numerator2,
        whole2,
        barModelColor
      },
      translate,
      displayMode
    } = props;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragLessThanGreaterThanOrEqualsToCompareMixedNumbers()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareMixedNumbers()}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        items={['>', '<', '=']}
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', ...dimens, gap: 130, justifyContent: 'center' }}>
            {[whole1, whole2].map((val, wholeId) => {
              const denominator = wholeId === 0 ? denominator1 : denominator2;
              const numerator = wholeId === 0 ? numerator1 : numerator2;
              return (
                <View
                  key={`${wholeId}`}
                  style={{ justifyContent: 'space-evenly', alignItems: 'center', height: '100%' }}
                >
                  {countRange(val + 1).map(i =>
                    i === val ? (
                      <ShadedFractionBarModel
                        key={`${wholeId}_${i}`}
                        totalSubSections={denominator}
                        width={displayMode === 'digital' ? 400 : 500}
                        color={barModelColors[barModelColor]}
                        coloredSections={countRange(numerator)}
                      />
                    ) : (
                      <ShadedFractionBarModel
                        key={`${wholeId}_${i}`}
                        totalSubSections={denominator}
                        width={displayMode === 'digital' ? 400 : 500}
                        color={barModelColors[barModelColor]}
                        coloredSections={countRange(denominator)}
                      />
                    )
                  )}
                </View>
              );
            })}
          </View>
        )}
        sentence={`<frac w='${whole1.toLocaleString()}' n='${numerator1}' d='${denominator1}'/>    <ans/>    <frac w='${whole2.toLocaleString()}' n='${numerator2}' d='${denominator2}'/>`}
        testCorrect={[
          lessThanGreaterThanOrEqualTo(
            fractionToDecimal(whole1 * denominator1 + numerator1, denominator1),
            fractionToDecimal(whole2 * denominator2 + numerator2, denominator2)
          )
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aMw',
  description: 'aMw',
  keywords: ['Fraction', 'Mixed number', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    denominator1: z.number().int().min(2).max(10),
    numerator1A: z.number().int().min(1).max(9),
    whole1A: z.number().int().min(1).max(10),
    numerator1B: z.number().int().min(1).max(9),
    whole1B: z.number().int().min(1).max(10),
    denominator2: z.number().int().min(2).max(10),
    numerator2A: z.number().int().min(1).max(9),
    whole2A: z.number().int().min(1).max(10),
    numerator2B: z.number().int().min(1).max(9),
    whole2B: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(2, 10);
    const numerator1A = randomIntegerInclusive(1, denominator1 - 1);
    const whole1A = randomIntegerInclusive(1, 10);
    const numerator1B = randomIntegerInclusive(1, denominator1 - 1);
    const whole1B = randomIntegerInclusive(1, 10);

    const denominator2 = randomIntegerInclusive(2, 10);
    const numerator2A = randomIntegerInclusive(1, denominator1 - 1);
    const whole2A = randomIntegerInclusive(1, 10);
    const numerator2B = randomIntegerInclusive(1, denominator1 - 1);
    const whole2B = randomIntegerInclusive(
      1,
      10,
      denominator1 === denominator2 &&
        numerator1A === numerator2A &&
        whole1A === whole2A &&
        numerator1B === numerator2B
        ? { constraint: x => x !== whole1B }
        : undefined
    );
    return {
      denominator1,
      denominator2,
      whole1A,
      whole1B,
      whole2A,
      whole2B,
      numerator1A,
      numerator1B,
      numerator2A,
      numerator2B
    };
  },
  Component: props => {
    const {
      question: {
        denominator1,
        denominator2,
        whole1A,
        whole1B,
        whole2A,
        whole2B,
        numerator1A,
        numerator1B,
        numerator2A,
        numerator2B
      },
      translate
    } = props;

    const sentences = shuffle(
      [
        {
          sentence: `<frac w='${whole1A.toLocaleString()}' n='${numerator1A.toLocaleString()}' d='${denominator1.toLocaleString()}' /> <ans/> <frac w='${whole1B.toLocaleString()}' n='${numerator1B.toLocaleString()}' d='${denominator1.toLocaleString()}' />`,
          answer: lessThanGreaterThanOrEqualTo(
            fractionToDecimal(whole1A * denominator1 + numerator1A, denominator1),
            fractionToDecimal(whole1B * denominator1 + numerator1B, denominator1)
          )
        },
        {
          sentence: `<frac w='${whole2A.toLocaleString()}' n='${numerator2A.toLocaleString()}' d='${denominator2.toLocaleString()}' /> <ans/> <frac w='${whole2B.toLocaleString()}' n='${numerator2B.toLocaleString()}' d='${denominator2.toLocaleString()}' />`,
          answer: lessThanGreaterThanOrEqualTo(
            fractionToDecimal(whole2A * denominator2 + numerator2A, denominator2),
            fractionToDecimal(whole2B * denominator2 + numerator2B, denominator2)
          )
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.dragCardsCompleteNumberSentences()}
        moveOrCopy="copy"
        pdfTitle={translate.instructions.useInequalitiesToCompleteNumberSentences()}
        pdfLayout="itemsHidden"
        items={['>', '<', '=']}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aMw2',
  description: 'aMw',
  keywords: ['Fraction', 'Mixed number', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    denominator1: z.number().int().min(2).max(10),
    numerator1A: z.number().int().min(1).max(9),
    whole1A: z.number().int().min(1).max(10),
    numerator1B: z.number().int().min(1).max(9),
    whole1B: z.number().int().min(1).max(10)
  }),
  simpleGenerator: () => {
    const denominator1 = randomIntegerInclusive(2, 10);
    const numerator1A = randomIntegerInclusive(1, denominator1 - 1);
    const whole1A = randomIntegerInclusive(1, 10);
    const numerator1B = randomIntegerInclusive(1, denominator1 - 1);
    const fractionsWithEqualWholes = getRandomBoolean();
    const whole1B = fractionsWithEqualWholes
      ? whole1A
      : randomIntegerInclusive(1, 10, {
          constraint: x => x !== whole1A
        });

    return {
      denominator1,
      whole1A,
      whole1B,
      numerator1A,
      numerator1B
    };
  },
  Component: props => {
    const {
      question: { denominator1, whole1A, whole1B, numerator1A, numerator1B },
      translate
    } = props;

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragInequalities0rEqualToCompareMixedNumbers()}
        moveOrCopy="copy"
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareMixedNumbers()}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        items={['>', '<', '=']}
        sentence={`<frac w='${whole1A.toLocaleString()}' n='${numerator1A.toLocaleString()}' d='${denominator1.toLocaleString()}' /> <ans/> <frac w='${whole1B.toLocaleString()}' n='${numerator1B.toLocaleString()}' d='${denominator1.toLocaleString()}' />`}
        testCorrect={[
          lessThanGreaterThanOrEqualTo(
            fractionToDecimal(whole1A * denominator1 + numerator1A, denominator1),
            fractionToDecimal(whole1B * denominator1 + numerator1B, denominator1)
          )
        ]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aMx',
  description: 'aMx',
  keywords: ['Fraction', 'Mixed number', 'Compare', 'Denominator', 'Greater than', 'Less than'],
  schema: z.object({
    denominator: z.number().int().min(3).max(9),
    numerator: z.number().int().min(1).max(8),
    whole: z.number().int().min(2).max(19),
    answerWholes: z.array(z.number().int().min(1).max(20)).length(6),
    answerNumerators: z.array(z.number().int().min(1).max(8)).length(6)
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(2, 19);
    const denominator = randomIntegerInclusive(4, 9);
    // tweak bounds to ensure there are options below and above
    const numerator = randomIntegerInclusive(
      whole < 3 ? 3 : 1,
      whole === 19 ? denominator - 2 : denominator - 1
    );

    // force two to be less than
    const wholeMaxL = numerator === 1 ? whole - 1 : whole;
    const [answerWhole1, answerWhole2] = randomUniqueIntegersInclusive(1, wholeMaxL, 2);
    const numMax1 = answerWhole1 === whole ? numerator - 1 : denominator - 1;
    const answerNumerator1 = randomIntegerInclusive(1, numMax1);
    const numMax2 = answerWhole2 === whole ? numerator - 1 : denominator - 1;
    const answerNumerator2 = randomIntegerInclusive(1, numMax2);

    // force two to be greater than
    const wholeMinG = numerator === denominator - 1 ? whole + 1 : whole;
    const [answerWhole3, answerWhole4] = randomUniqueIntegersInclusive(wholeMinG, 20, 2);
    const numMin3 = answerWhole3 === whole ? numerator + 1 : 1;
    const answerNumerator3 = randomIntegerInclusive(numMin3, denominator - 1);
    const numMax4 = answerWhole4 === whole ? numerator + 1 : 1;
    const answerNumerator4 = randomIntegerInclusive(numMax4, denominator - 1);

    const remainingAnswerWholes = randomUniqueIntegersInclusive(1, 20, 2, {
      constraint: x => ![whole, answerWhole1, answerWhole2, answerWhole3, answerWhole4].includes(x)
    });

    const remainingAnswerNumerators = countRange(2).map(() =>
      randomIntegerInclusive(1, denominator - 1)
    );

    const answerWholes = [
      ...remainingAnswerWholes,
      answerWhole1,
      answerWhole2,
      answerWhole3,
      answerWhole4
    ];
    const answerNumerators = [
      ...remainingAnswerNumerators,
      answerNumerator1,
      answerNumerator2,
      answerNumerator3,
      answerNumerator4
    ];

    return {
      numerator,
      denominator,
      whole,
      answerNumerators,
      answerWholes
    };
  },
  Component: props => {
    const {
      question: { numerator, denominator, whole, answerNumerators, answerWholes },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      countRange(6).map(i => ({
        sentence: `<frac w='${answerWholes[i].toLocaleString()}' n='${answerNumerators[
          i
        ].toLocaleString()}' d='${denominator.toLocaleString()}' />`,
        value: fractionToDecimal(answerWholes[i] * denominator + answerNumerators[i], denominator)
      })),
      { random: seededRandom(props.question) }
    );

    const less: string[] = [];
    const more: string[] = [];
    items.forEach(item =>
      item.value < fractionToDecimal(whole * denominator + numerator, denominator)
        ? less.push(item.sentence)
        : more.push(item.sentence)
    );

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortFractionsIntoTheTable()}
        pdfTitle={translate.instructions.useCardsToSortFractionsIntoTable()}
        zoneNames={[
          translate.tableHeaders.lessThan(
            `<frac w='${whole.toLocaleString()}' n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`
          ),
          translate.tableHeaders.greaterThan(
            `<frac w='${whole.toLocaleString()}' n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}' />`
          )
        ]}
        items={items.map(({ sentence }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                fractionDividerStyle={{ marginVertical: 1 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
              />
            ),
            value: sentence
          };
        })}
        testCorrect={[less, more]}
        pdfItemVariant="pdfSquare"
        questionHeight={900}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aMx2',
  description: 'aMx',
  keywords: ['Fraction', 'Mixed number', 'Compare', 'Denominator', 'Greater than', 'Less than'],
  schema: z
    .object({
      denominator: z.number().int().min(3).max(9),
      numerator: z.number().int().min(2).max(8),
      whole: z.number().int().min(3).max(18),
      answerWholes: z.array(z.number().int().min(1).max(20)).length(6),
      answerNumerators: z.array(z.number().int().min(1).max(8)).length(6)
    })
    .refine(
      val =>
        nestedArrayHasNoDuplicates(
          countRange(6).map(i => [val.answerWholes[i], val.answerNumerators[i]]),
          true
        ),
      'no duplicate options'
    ),
  questionHeight: 900,
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(3, 18);
    const denominator = randomIntegerInclusive(4, 9);
    // tweak bounds to ensure there are options below and above
    const numerator = randomIntegerInclusive(
      whole < 3 ? 3 : 2,
      whole === 19 ? denominator - 2 : denominator - 1
    );

    // force two to be less than
    const [answerWhole1, answerWhole2] = randomUniqueIntegersInclusive(1, whole - 1, 2);
    const numMax1 = answerWhole1 === whole ? numerator - 1 : denominator - 1;
    const answerNumerator1 = randomIntegerInclusive(1, numMax1);
    const numMax2 = answerWhole2 === whole ? numerator - 1 : denominator - 1;
    const answerNumerator2 = randomIntegerInclusive(1, numMax2);

    // force two to be greater than
    const [answerWhole3, answerWhole4] = randomUniqueIntegersInclusive(whole + 1, 20, 2);
    const numMin3 = answerWhole3 === whole ? numerator + 1 : 1;
    const answerNumerator3 = randomIntegerInclusive(numMin3, denominator - 1);
    const numMax4 = answerWhole4 === whole ? numerator + 1 : 1;
    const answerNumerator4 = randomIntegerInclusive(numMax4, denominator - 1);

    // force two to have same whole value as table
    const remainingAnswerWholes = [whole, whole];

    const remainingAnswerNumerators = randomUniqueIntegersInclusive(1, denominator - 1, 2, {
      constraint: x => x !== numerator
    });

    const answerWholes = [
      ...remainingAnswerWholes,
      answerWhole1,
      answerWhole2,
      answerWhole3,
      answerWhole4
    ];
    const answerNumerators = [
      ...remainingAnswerNumerators,
      answerNumerator1,
      answerNumerator2,
      answerNumerator3,
      answerNumerator4
    ];

    return {
      numerator,
      denominator,
      whole,
      answerNumerators,
      answerWholes
    };
  },
  Component: props => {
    const {
      question: { numerator, denominator, whole, answerNumerators, answerWholes },
      translate,
      displayMode
    } = props;

    const items = shuffle(
      countRange(6).map(i => ({
        sentence: `<frac w='${answerWholes[i].toLocaleString()}' n='${
          answerNumerators[i]
        }' d='${denominator}' />`,
        value: fractionToDecimal(answerWholes[i] * denominator + answerNumerators[i], denominator)
      })),
      { random: seededRandom(props.question) }
    );

    const less: string[] = [];
    const more: string[] = [];
    items.forEach(item =>
      item.value < fractionToDecimal(whole * denominator + numerator, denominator)
        ? less.push(item.sentence)
        : more.push(item.sentence)
    );

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortFractionsIntoTheTable()}
        pdfTitle={translate.instructions.useCardsToSortFractionsIntoTable()}
        zoneNames={[
          translate.tableHeaders.lessThan(
            `<frac w='${whole.toLocaleString()}' n='${numerator}' d='${denominator}' />`
          ),
          translate.tableHeaders.greaterThan(
            `<frac w='${whole.toLocaleString()}' n='${numerator}' d='${denominator}' />`
          )
        ]}
        items={items.map(({ sentence }) => {
          return {
            component: (
              <TextStructure
                sentence={sentence}
                fractionDividerStyle={{ marginVertical: 1 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
              />
            ),
            value: sentence
          };
        })}
        testCorrect={[less, more]}
        pdfItemVariant="pdfSquare"
        questionHeight={900}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aMy',
  description: 'aMy',
  keywords: ['Mixed number', 'Fraction', 'Order', 'Compare', 'Denominator', 'Greatest', 'Smallest'],
  schema: z.object({
    denominator: z.number().int().min(3).max(9),
    numerators: z.array(z.number().min(1).max(8)).length(5),
    wholes: z.array(z.number().min(1).max(20)).length(5),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 9);
    const numerators = countRange(5).map(() => randomIntegerInclusive(2, denominator - 1));
    const wholes = randomUniqueIntegersInclusive(1, 20, 5);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return {
      denominator,
      numerators,
      wholes,
      ordering
    };
  },
  Component: props => {
    const {
      question: { denominator, numerators, wholes, ordering },
      translate,
      displayMode
    } = props;

    const values = countRange(5).map(i => ({
      component: (
        <TextStructure
          sentence={`<frac w='${wholes[i].toLocaleString()}' n='${numerators[
            i
          ].toLocaleString()}' d='${denominator.toLocaleString()}' />`}
          fractionDividerStyle={{ marginVertical: 2 }}
          fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
        />
      ),
      value: fractionToDecimal(wholes[i] * denominator + numerators[i], denominator)
    }));

    const correctOrder = sortNumberArray(
      values.map(i => i.value),
      ordering
    );

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions.dragCardsToOrderMixedNumbers(
          `${
            ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
          }`
        )}
        pdfTitle={translate.instructions.useCardsToOrderMixedNumbers(
          `${
            ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
          }`
        )}
        testCorrect={correctOrder}
        items={values}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        moveOrCopy="move"
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aMy2',
  description: 'aMy',
  keywords: ['Mixed number', 'Fraction', 'Order', 'Compare', 'Denominator', 'Greatest', 'Smallest'],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(9),
      numeratorWholes: z
        .array(z.tuple([z.number().min(1).max(20), z.number().min(1).max(8)]))
        .length(5),
      ordering: z.enum(['ascending', 'descending'])
    })
    .refine(val => nestedArrayHasNoDuplicates(val.numeratorWholes, true), 'no duplicate options'),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 9);
    const numOfEqualWholes = randomIntegerInclusive(2, Math.min(denominator - 2, 3));
    const equalWhole = randomIntegerInclusive(1, 20);
    const equalWholeNumerators = randomUniqueIntegersInclusive(
      2,
      denominator - 1,
      numOfEqualWholes
    );
    const otherNumerators = countRange(5 - numOfEqualWholes).map(() =>
      randomIntegerInclusive(2, denominator - 1)
    );
    const otherWholes = randomUniqueIntegersInclusive(1, 20, 5 - numOfEqualWholes, {
      constraint: x => x !== equalWhole
    });

    const numeratorWholes: [number, number][] = shuffle([
      ...countRange(numOfEqualWholes).map(
        i => [equalWhole, equalWholeNumerators[i]] as [number, number]
      ),
      ...countRange(5 - numOfEqualWholes).map(
        i => [otherWholes[i], otherNumerators[i]] as [number, number]
      )
    ]);

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return {
      denominator,
      numeratorWholes,
      ordering
    };
  },
  Component: props => {
    const {
      question: { denominator, numeratorWholes, ordering },
      translate,
      displayMode
    } = props;

    const values = numeratorWholes.map(val => ({
      component: (
        <TextStructure
          sentence={`<frac w='${val[0].toLocaleString()}' n='${val[1]}' d='${denominator}' />`}
          fractionDividerStyle={{ marginVertical: 2 }}
          fractionTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50, fontWeight: '700' }}
        />
      ),
      value: fractionToDecimal(val[0] * denominator + val[1], denominator)
    }));

    const correctOrder = sortNumberArray(
      values.map(i => i.value),
      ordering
    );

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions.dragCardsToOrderMixedNumbers(
          `${
            ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
          }`
        )}
        pdfTitle={translate.instructions.useCardsToOrderMixedNumbers(
          `${
            ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
          }`
        )}
        testCorrect={correctOrder}
        items={values}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        moveOrCopy="move"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aMz',
  description: 'aMz',
  keywords: [
    'Mixed number',
    'Fraction',
    'Compare',
    'Numerator',
    'Denominator',
    'Greater than',
    'Less than',
    'Integer'
  ],
  schema: z.object({
    denominator: z.number().int().min(4).max(9),
    numerators: z.array(z.number().min(1).max(8)).length(2),
    wholes: z.array(z.number().min(1).max(15)).length(2)
  }),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(4, 9);
        const wholes = countRange(2).map(() => randomIntegerInclusive(1, 15));
        const numerator1 = randomIntegerInclusive(1, denominator - 1);
        const numerator2 = randomIntegerInclusive(1, denominator - 1);

        return {
          denominator,
          numerators: [numerator1, numerator2],
          wholes
        };
      },
      val =>
        val.numerators[1] <= val.numerators[0] - 2 || val.numerators[1] >= val.numerators[0] + 2
    ),
  Component: props => {
    const {
      question: { denominator, numerators, wholes },
      translate
    } = props;

    const decimal1 = fractionToDecimal(numerators[0] + wholes[0] * denominator, denominator);
    const decimal2 = fractionToDecimal(numerators[1] + wholes[1] * denominator, denominator);

    const symbol = decimal1 > decimal2 ? `&gt;` : `&lt;`;

    const possibleAnswers = () => {
      if (wholes[0] === wholes[1]) {
        if (decimal1 > decimal2) {
          return [wholes[0], numerators[0] - 1];
        } else {
          return [wholes[0], numerators[0] + 1];
        }
      } else if (decimal1 > decimal2) {
        if (numerators[0] === 1) return [wholes[0] - 1, denominator - 1];
        return [wholes[0], numerators[0] - 1];
      } else {
        if (numerators[0] === denominator - 1) return [wholes[0] + 1, 1];
        return [wholes[0], numerators[0] + 1];
      }
    };

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.whatCouldMixedNumberBe()}
        sentence={`<frac w='${wholes[0].toLocaleString()}' n='${numerators[0].toLocaleString()}' d='${denominator.toLocaleString()}' /> ${symbol} <frac wAns='' nAns='' dAns='' />  ${symbol} <frac w='${wholes[1].toLocaleString()}' n='${numerators[1].toLocaleString()}' d='${denominator.toLocaleString()}' />`}
        testCorrect={answer => {
          const answerDecimal = fractionToDecimal(
            Number(answer[0]) * Number(answer[2]) + Number(answer[1]),
            Number(answer[2])
          );
          return symbol === `&gt;`
            ? answerDecimal < decimal1 && answerDecimal > decimal2
            : answerDecimal > decimal1 && answerDecimal < decimal2;
        }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ...possibleAnswers().map(i => i.toLocaleString()),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.anyCorrectAnswersThatSatisfyInequalities()
        }}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aMz2',
  description: 'aMz',
  keywords: [
    'Mixed number',
    'Fraction',
    'Compare',
    'Numerator',
    'Denominator',
    'Greater than',
    'Less than',
    'Integer'
  ],
  schema: z
    .object({
      denominator: z.number().int().min(4).max(9),
      numerators: z.array(z.number().min(1).max(8)).length(2),
      wholeA: z.number().min(1).max(15),
      wholeB: z.number().min(1).max(15)
    })
    .refine(
      val => val.wholeA + 1 !== val.wholeB || val.wholeA - 1 !== val.wholeB,
      'There must only be a difference of 1 between wholeA and wholeB'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const denominator = randomIntegerInclusive(4, 9);
        const wholeGreaterOrLessThanDiffOfOne = getRandomBoolean();
        const fractionsWithEqualWholes = getRandomBoolean();
        const wholeA = randomIntegerInclusive(1, 15);

        let wholeB: number;

        // 50% of the time make the fraction wholes the same
        if (fractionsWithEqualWholes) {
          wholeB = wholeA;
          // The other 50% of the time the fraction wholes should have a difference of 1
          // If wholeA is min or max number, ensure wholeB is greater or less respectively
        } else if (wholeA === 1) {
          wholeB = 2;
        } else if (wholeA === 15) {
          wholeB = 14;
        } else if (wholeGreaterOrLessThanDiffOfOne) {
          wholeB = wholeA - 1;
        } else {
          wholeB = wholeA + 1;
        }

        const numerator1 = randomIntegerInclusive(1, denominator - 1);
        const numerator2 = randomIntegerInclusive(1, denominator - 1);

        return {
          denominator,
          numerators: [numerator1, numerator2],
          wholeA,
          wholeB
        };
      },
      val =>
        val.numerators[1] <= val.numerators[0] - 2 || val.numerators[1] >= val.numerators[0] + 2
    ),
  Component: props => {
    const {
      question: { denominator, numerators, wholeA, wholeB },
      translate
    } = props;

    const decimal1 = fractionToDecimal(numerators[0] + wholeA * denominator, denominator);
    const decimal2 = fractionToDecimal(numerators[1] + wholeB * denominator, denominator);

    const symbol = decimal1 > decimal2 ? `&gt;` : `&lt;`;

    const possibleAnswers = () => {
      if (wholeA === wholeB) {
        if (decimal1 > decimal2) {
          return [wholeA, numerators[0] - 1];
        } else {
          return [wholeA, numerators[0] + 1];
        }
      } else if (decimal1 > decimal2) {
        if (numerators[0] === 1) return [wholeA - 1, denominator - 1];
        return [wholeA, numerators[0] - 1];
      } else {
        if (numerators[0] === denominator - 1) return [wholeA + 1, 1];
        return [wholeA, numerators[0] + 1];
      }
    };

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.whatCouldMixedNumberBe()}
        sentence={`<frac w='${wholeA.toLocaleString()}' n='${
          numerators[0]
        }' d='${denominator}' /> ${symbol} <frac wAns='' nAns='' dAns='' />  ${symbol} <frac w='${wholeB.toLocaleString()}' n='${
          numerators[1]
        }' d='${denominator}' />`}
        testCorrect={answer => {
          const answerDecimal = fractionToDecimal(
            Number(answer[0]) * Number(answer[2]) + Number(answer[1]),
            Number(answer[2])
          );
          return symbol === `&gt;`
            ? answerDecimal < decimal1 && answerDecimal > decimal2
            : answerDecimal > decimal1 && answerDecimal < decimal2;
        }}
        inputMaxCharacters={2}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            ...possibleAnswers().map(i => i.toLocaleString()),
            denominator.toLocaleString()
          ],
          answerText: translate.markScheme.anyCorrectAnswersThatSatisfyInequalities()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderMixedNumbers',
  questionTypes: [Question1v2, Question2v2, Question3v2, Question4v2, Question5v2, Question6v2],
  archivedQuestionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
