import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import { newQuestionContent } from '../../../Question';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { AssetSvg } from '../../../../assets/svg';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import QF4DragOrderVertical from '../../../../components/question/questionFormats/QF4DragOrderVertical';
import {
  getRandomUniqueWeightObjects,
  objectsWithWeights,
  ObjectsWithWeightsName,
  objectsWithWeightsNameSchema
} from '../../../../utils/objects';
import { greatest, smallest } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdQ',
  description: 'bdQ',
  keywords: ['Full', 'Empty', 'Capacity', 'Compare'],
  questionHeight: 1000,
  schema: z.object({
    smallestOrGreatest: z.enum(['smallest', 'greatest']),
    randomObjects: z.array(objectsWithWeightsNameSchema).length(4)
  }),
  simpleGenerator: () => {
    const smallestOrGreatest = getRandomFromArray(['smallest', 'greatest'] as const);
    const { randomObjectsWithInfo } = rejectionSample(
      () => {
        const randomObjectsWithInfo = getRandomUniqueWeightObjects(4).map(name => ({
          name,
          ...objectsWithWeights[name]
        }));
        return { randomObjectsWithInfo };
      },

      ({ randomObjectsWithInfo }) => {
        // Ensure there's at least a gap of 2 in weight between objects
        // Ensures it's not too difficult to distinguish between similar sizes
        if (smallestOrGreatest === 'smallest') {
          const [smallestObject, secondSmallestObject] = [...randomObjectsWithInfo].sort(
            (a, b) => a.weight - b.weight
          );

          return (
            Math.abs(smallestObject.weight - secondSmallestObject.weight) > 1 &&
            // Jug and Cup should not be together at the same time as a possible answer
            // Only checked on smallest because when checking for largest
            // Either Bath or Fish Tank will be the answer
            !(
              randomObjectsWithInfo.some(obj => obj.name === 'cup') &&
              randomObjectsWithInfo.some(obj => obj.name === 'jug')
            )
          );
        } else {
          const [greatestObject, secondGreatestObject] = [...randomObjectsWithInfo].sort(
            (a, b) => b.weight - a.weight
          );

          return Math.abs(greatestObject.weight - secondGreatestObject.weight) > 1;
        }
      }
    );

    return { smallestOrGreatest, randomObjects: randomObjectsWithInfo.map(it => it.name) };
  },
  Component: props => {
    const {
      question: { smallestOrGreatest, randomObjects },
      translate
    } = props;

    const correctAnswer = (smallestOrGreatest === 'smallest' ? smallest : greatest)(
      randomObjects,
      name => objectsWithWeights[name].weight
    );

    return (
      <QF11SelectImagesUpTo4<ObjectsWithWeightsName>
        questionHeight={1000}
        title={
          smallestOrGreatest === 'greatest'
            ? translate.ks1Instructions.selectTheContainerWithTheGreatestCapacity()
            : translate.ks1Instructions.selectTheContainerWithTheSmallestCapacity()
        }
        pdfTitle={
          smallestOrGreatest === 'greatest'
            ? translate.ks1PDFInstructions.tickTheContainerWithTheGreatestCapacity()
            : translate.ks1PDFInstructions.tickTheContainerWithTheSmallestCapacity()
        }
        testCorrect={[correctAnswer]}
        numItems={4}
        renderItems={({ dimens }) =>
          randomObjects.map(name => {
            const { icon, scale } = objectsWithWeights[name];
            return {
              value: name,
              component: (
                <AssetSvg
                  name={icon}
                  width={dimens.width * 0.8 * scale}
                  height={dimens.height * 0.8}
                />
              )
            };
          })
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdR',
  description: 'bdR',
  keywords: ['Less than', 'Greater than', 'Equal to', 'Capacity', 'Compare'],
  schema: z.object({
    containerSizeA: z.number().min(2).max(6),
    containerSizeB: z.number().min(2).max(6)
  }),
  simpleGenerator: () => {
    const containerSizeA = randomIntegerInclusive(2, 6);
    const containerSizeB = randomIntegerInclusive(2, 6);

    return { containerSizeA, containerSizeB };
  },
  Component: props => {
    const {
      question: { containerSizeA, containerSizeB },
      translate
    } = props;

    const lhsComponent = <AssetSvg name="Containers/container" width={50 * containerSizeA} />;
    const rhsComponent = <AssetSvg name="Containers/container" width={50 * containerSizeB} />;

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragACardToCompareTheCapacityOfTheContainers()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompareTheCapacityOfTheContainers()}
        items={['<', '>', '=']}
        itemVariant="square"
        statementStyle={{ justifyContent: 'center' }}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        statements={[
          {
            correctAnswer: lessThanGreaterThanOrEqualTo(containerSizeA, containerSizeB),
            lhsComponent: lhsComponent,
            rhsComponent: rhsComponent
          }
        ]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdS',
  description: 'bdS',
  keywords: ['Capacity', 'Compare', 'Order'],
  schema: z.object({
    randomObjects: z.array(objectsWithWeightsNameSchema).length(3),
    order: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const randomObjects = getRandomUniqueWeightObjects(3);
    const order = getRandomFromArray(['ascending', 'descending'] as const);

    return { randomObjects, order };
  },
  Component: props => {
    const {
      question: { randomObjects, order },
      translate
    } = props;

    const randomObjectsWithInfo = randomObjects.map(name => ({
      name,
      ...objectsWithWeights[name]
    }));
    const sortedObjectsByWeight =
      order === 'ascending'
        ? [...randomObjectsWithInfo].sort((a, b) => a.weight - b.weight)
        : [...randomObjectsWithInfo].sort((a, b) => b.weight - a.weight);
    const correctAnswer = sortedObjectsByWeight.map(it => it.name);

    return (
      <QF4DragOrderVertical<ObjectsWithWeightsName>
        title={
          order === 'ascending'
            ? translate.ks1Instructions.dragCardsOrderObjectsStartWithSmallestCapacity()
            : translate.ks1Instructions.dragCardsOrderObjectsStartWithGreatestCapacity()
        }
        pdfTitle={
          order === 'ascending'
            ? translate.ks1PDFInstructions.orderObjectsStartWithSmallestCapacity()
            : translate.ks1PDFInstructions.orderObjectsStartWithGreatestCapacity()
        }
        testCorrect={correctAnswer}
        items={randomObjectsWithInfo.map(randomObject => ({
          value: randomObject.name,
          component: <AssetSvg name={randomObject.icon} width={190 * randomObject.scale} />
        }))}
        draggableVariant="tallRectangle"
        topLabel={
          order === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        bottomLabel={
          order === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareCapacity',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
