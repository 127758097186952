import { getRandomFromArray, getRandomSubArrayFromArray, randomIntegerInclusive } from './random';
import { z } from 'zod';
import { TranslationFunctions } from '../i18n/i18n-types';
import { range, sortNumberArray } from './collections';

const stationNames = [
  'Alnmouth',
  'Bristol',
  'Cardiff',
  'Darlington',
  'Doncaster',
  'Durham',
  'Edinburgh',
  'Grantham',
  'Leeds',
  'London',
  'Manchester',
  'Newcastle',
  'Newport',
  'Nuneaton',
  'Reading',
  'Stockport',
  'Stoke-on-Trent',
  'Stevenage',
  'Swindon',
  'Wakefield',
  'York'
] as const;

export const stationNamesSchema = z.enum(stationNames);

export function getRandomStation() {
  return getRandomFromArray(stationNames);
}

export const routes = [
  ['Manchester', 'Stockport', 'Stoke-on-Trent', 'Nuneaton', 'London'],
  ['Leeds', 'Wakefield', 'Doncaster', 'Grantham', 'Stevenage', 'London'],
  ['Leeds', 'York', 'Darlington', 'Durham', 'Newcastle', 'Alnmouth', 'Edinburgh'],
  ['Cardiff', 'Newport', 'Bristol', 'Swindon', 'Reading', 'London']
];

export const routeTimes = [
  {
    route: ['Manchester', 'Stockport', 'Stoke-on-Trent', 'Nuneaton', 'London'],
    times: [
      randomIntegerInclusive(7, 15),
      randomIntegerInclusive(25, 45),
      randomIntegerInclusive(25, 35),
      randomIntegerInclusive(50, 75)
    ]
  },
  {
    route: ['Leeds', 'Wakefield', 'Doncaster', 'Grantham', 'Stevenage', 'London'],
    times: [
      randomIntegerInclusive(10, 16),
      randomIntegerInclusive(17, 35),
      randomIntegerInclusive(25, 40),
      randomIntegerInclusive(40, 50),
      randomIntegerInclusive(28, 40)
    ]
  },
  {
    route: ['Leeds', 'York', 'Darlington', 'Durham', 'Newcastle', 'Alnmouth', 'Edinburgh'],
    times: [
      randomIntegerInclusive(20, 40),
      randomIntegerInclusive(25, 35),
      randomIntegerInclusive(15, 20),
      randomIntegerInclusive(15, 20),
      randomIntegerInclusive(20, 40),
      randomIntegerInclusive(60, 75)
    ]
  },
  {
    route: ['Cardiff', 'Newport', 'Bristol', 'Swindon', 'Reading', 'London'],
    times: [
      randomIntegerInclusive(10, 16),
      randomIntegerInclusive(30, 40),
      randomIntegerInclusive(25, 40),
      randomIntegerInclusive(30, 45),
      randomIntegerInclusive(25, 35)
    ]
  }
] as const;

export function getRandomRouteByNumberOfStops(numberOfStops: number) {
  const filteredRoutesArray = routes.map(route => {
    const indexesToShow = sortNumberArray(
      getRandomSubArrayFromArray(range(0, route.length - 1), numberOfStops)
    );
    const filteredRoutes = route.filter((_, index) => {
      return indexesToShow.includes(index);
    });
    return filteredRoutes;
  });
  return getRandomFromArray(filteredRoutesArray) as StationName[];
}

export function getRandomRouteAndTimeByNumberOfStops(numberOfStops: number): {
  route: StationName[];
  duration: number[];
} {
  const { times, route } = getRandomFromArray(routeTimes);
  const indexesToShow = sortNumberArray(
    getRandomSubArrayFromArray(range(0, route.length - 1), numberOfStops)
  );
  const filteredRoutes = route.filter((_, index) => {
    return indexesToShow.includes(index);
  });

  const durationTimes: number[] = [];

  indexesToShow.slice(0, -1).forEach((shownIdx, i) => {
    const chunk = times.slice(shownIdx, indexesToShow[i + 1]);
    const chunkSum = chunk.reduce((sum, num) => sum + num, 0);
    durationTimes.push(chunkSum);
  });

  return { route: filteredRoutes, duration: durationTimes };
}

export type StationName = (typeof stationNames)[number];

export const stationAsWord = (object: StationName, translate: TranslationFunctions) => {
  switch (object) {
    case 'Alnmouth':
      return translate.stations.Alnmouth();
    case 'Bristol':
      return translate.stations.Bristol();
    case 'Cardiff':
      return translate.stations.Cardiff();
    case 'Darlington':
      return translate.stations.Darlington();
    case 'Doncaster':
      return translate.stations.Doncaster();
    case 'Durham':
      return translate.stations.Durham();
    case 'Edinburgh':
      return translate.stations.Edinburgh();
    case 'Grantham':
      return translate.stations.Grantham();
    case 'Leeds':
      return translate.stations.Leeds();
    case 'London':
      return translate.stations.London();
    case 'Manchester':
      return translate.stations.Manchester();
    case 'Newcastle':
      return translate.stations.Newcastle();
    case 'Newport':
      return translate.stations.Newport();
    case 'Nuneaton':
      return translate.stations.Nuneaton();
    case 'Reading':
      return translate.stations.Reading();
    case 'Stockport':
      return translate.stations.Stockport();
    case 'Stoke-on-Trent':
      return translate.stations['Stoke-on-Trent']();
    case 'Stevenage':
      return translate.stations.Stevenage();
    case 'Swindon':
      return translate.stations.Swindon();
    case 'Wakefield':
      return translate.stations.Wakefield();
    case 'York':
      return translate.stations.York();
  }
};
