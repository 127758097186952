import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import {
  TwoDShape,
  getRandom2DShape,
  getRandomUnqiueMarkedLineShape,
  shapeProperties
} from '../../../../utils/shapeImages/polygons';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF45CompletePolygonOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45CompletePolygonOnSquareDottedPaper';
import { isValidTrapezium } from '../../../../utils/shapes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ayy',
  description: 'ayy',
  keywords: ['Quadrilaterals'],
  schema: z.object({
    markedShapes: z.array(z.object({ svgName: z.string(), shape: z.string() })).length(3)
  }),
  simpleGenerator: () => {
    const markedShapes = getRandomUnqiueMarkedLineShape(3);

    return { markedShapes };
  },
  Component: props => {
    const {
      question: { markedShapes },
      translate,
      displayMode
    } = props;

    const random = seededRandom(props.question);

    // Usable area next to answer boxes
    const dimens =
      displayMode === 'digital' ? { height: 100, width: 250 } : { height: 150, width: 450 };

    const translations = markedShapes.map(val => translate.shapes[val.shape as TwoDShape](1));

    const statements = markedShapes.map((val, i) => ({
      lhsComponent: (
        <AssetSvg name={val.svgName as SvgName} width={dimens.width} height={dimens.height} />
      ),
      correctAnswer: translations[i]
    }));

    const shuffledStatements = shuffle(statements, { random });

    const items = statements.map(({ correctAnswer }) => correctAnswer);

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchShapeToQuadrilateral()}
        pdfTitle={translate.instructions.useCardsToMatchShapeToQuadrilateral()}
        items={items}
        statementStyle={{ justifyContent: 'center' }}
        statements={shuffledStatements}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'ayz',
  description: 'ayz',
  keywords: ['Quadrilaterals'],
  schema: z.object({
    markedShapes: z.array(z.object({ svgName: z.string(), shape: z.string() })).length(4),
    answerIndex: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const markedShapes = getRandomUnqiueMarkedLineShape(4);
    const answerIndex = randomIntegerInclusive(0, 3);

    return { markedShapes, answerIndex };
  },
  Component: props => {
    const {
      question: { markedShapes, answerIndex },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectMathematicalNameQuadrilateral()}
        pdfTitle={translate.instructions.circleMathematicalNameQuadrilateral()}
        testCorrect={[markedShapes[answerIndex].shape]}
        numItems={4}
        Content={({ dimens }) => (
          <AssetSvg
            name={markedShapes[answerIndex].svgName as SvgName}
            width={dimens.width * 0.9}
            height={dimens.height * 0.9}
          />
        )}
        renderItems={markedShapes.map(val => ({
          value: val.shape,
          component: <Text variant="WRN700">{translate.shapes[val.shape as TwoDShape](1)}</Text>
        }))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ayA',
  description: 'ayA',
  keywords: ['Quadrilaterals', 'Parallel'],
  schema: z.object({
    shape: z.string()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const shape = getRandom2DShape();

    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    const shapeProps = shapeProperties[shape as TwoDShape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsQuadrilateralHowManyParallelLinePairs(
          translate.shapes[shape as TwoDShape](1)
        )}
        testCorrect={[shapeProps.parallelSidePairs.toString()]}
        Content={({ dimens }) => (
          <AssetSvg
            name={shapeProps.svgName as SvgName}
            width={dimens.width * 0.9}
            height={dimens.height * 0.9}
          />
        )}
        sentence="<ans/>"
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'ayB',
  description: 'ayB',
  keywords: ['Quadrilaterals', 'Right angles'],
  schema: z.object({
    shape: z.string()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const shape = getRandom2DShape();

    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    const shapeProps = shapeProperties[shape as TwoDShape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsQuadrilateralHowManyRightAngles(
          translate.shapes[shape as TwoDShape](1)
        )}
        testCorrect={[shapeProps.rightAngles.toString()]}
        Content={({ dimens }) => (
          <AssetSvg
            name={shapeProps.svgName as SvgName}
            width={dimens.width * 0.9}
            height={dimens.height * 0.9}
          />
        )}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        sentence="<ans/>"
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'ayC',
  description: 'ayC',
  keywords: ['Quadrilaterals', 'Equal sides'],
  schema: z.object({
    shape: z.string()
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const shape = getRandom2DShape();

    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate
    } = props;

    const shapeProps = shapeProperties[shape as TwoDShape];

    return (
      <QF1ContentAndSentence
        title={translate.instructions.hereIsQuadrilateralHowManyEqualSidePairs(
          translate.shapes[shape as TwoDShape](1)
        )}
        testCorrect={[shapeProps.equalSidesPairs.toString()]}
        Content={({ dimens }) => (
          <AssetSvg
            name={shapeProps.svgName as SvgName}
            width={dimens.width * 0.9}
            height={dimens.height * 0.9}
          />
        )}
        sentence="<ans/>"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ayD',
  description: 'ayD',
  keywords: ['Quadrilaterals'],
  schema: z.object({
    shape: z.enum(['rhombuses', 'parallelograms', 'trapeziums']),
    points: z
      .array(z.object({ x: z.number().int().min(0).max(9), y: z.number().int().min(0).max(4) }))
      .length(4),
    missingIndex: z.number().int().min(1).max(3)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['rhombuses', 'parallelograms', 'trapeziums'] as const);

    const points = rejectionSample(
      () => {
        const x1 = randomIntegerInclusive(
          0,
          shape === 'parallelograms' ? 1 : shape === 'trapeziums' ? 2 : 3
        );
        const y1 = randomIntegerInclusive(0, shape === 'rhombuses' ? 1 : 2);
        const xOffset = randomIntegerInclusive(1, 2);
        const width = randomIntegerInclusive(shape === 'trapeziums' ? 4 : 3, 9 - x1 - xOffset - 1);
        const height = randomIntegerInclusive(1, 4 - y1, {
          constraint: x => (shape === 'parallelograms' && x !== width) || shape !== 'parallelograms'
        });

        let points = [];
        switch (shape) {
          case 'trapeziums': {
            const offset = width <= 4 ? 1 : xOffset;
            points = [
              { x: x1, y: y1 },
              { x: x1 + width, y: y1 },
              { x: x1 + width - offset, y: y1 + height },
              { x: x1 + offset, y: y1 + height }
            ];
            break;
          }
          case 'parallelograms':
            points = [
              { x: x1, y: y1 },
              { x: x1 + width, y: y1 },
              { x: x1 + width + xOffset, y: y1 + height },
              { x: x1 + xOffset, y: y1 + height }
            ];
            break;
          case 'rhombuses': {
            const yOffset = 3;
            const xOffset = 4;
            const width = 5;
            points = [
              { x: x1, y: y1 },
              { x: x1 + width, y: y1 },
              { x: x1 + width + xOffset, y: y1 + yOffset },
              { x: x1 + xOffset, y: y1 + yOffset }
            ];
            break;
          }
        }
        return points;
      },
      val => val.every(point => point.x >= 0 && point.x <= 9 && point.y >= 0 && point.y <= 4)
    );

    const missingIndex = randomIntegerInclusive(1, 3);

    return { shape, points, missingIndex };
  },
  Component: ({ question, translate }) => {
    const { shape, points, missingIndex } = question;
    const shapeDisplayString = translate.shapes[shape](1);

    return (
      <QF45CompletePolygonOnSquareDottedPaper
        title={translate.instructions.selectWhereTheCrossForTheFinalVertexOfTheXWouldBe(
          shapeDisplayString
        )}
        pdfTitle={translate.instructions.completeShapeBySelectingADotPdf(shapeDisplayString)}
        polygon={points}
        missingIndex={missingIndex}
        testCorrect={
          shape === 'trapeziums'
            ? userAnswer =>
                isValidTrapezium(
                  points.map(val => [val.x, val.y]),
                  missingIndex as 0 | 1 | 2 | 3,
                  userAnswer.map(val => [val.x, val.y] as [number, number])[0]
                )
            : undefined
        }
        hideLines={true}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Quadrilaterals',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
