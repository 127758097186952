import { newSmallStepContent } from '../../../SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { SUB } from '../../../../constants';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import {
  compareFractions,
  fractionArithmetic,
  fractionToDecimal,
  improperFractionToMixedNumber,
  mixedNumberToImproperFraction,
  simplify
} from '../../../../utils/fractions';
import { leastCommonMultiple } from '../../../../utils/multiples';
import { numberEnum } from '../../../../utils/zod';
import { nameSchema, getRandomName, getRandomUniqueNames } from '../../../../utils/names';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import { filledArray } from '../../../../utils/collections';
import { barModelColors } from '../../../../theme/colors';
import { compareFloats } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aqP',
  description: 'aqP',
  keywords: ['Subtraction', 'Mixed numbers', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    number2: z.number().int().min(2).max(8),
    number3: z.number().int().min(3).max(9),
    number4: z.number().int().min(1).max(8)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);

    // number4 < number2 < number3
    const number3 = randomIntegerInclusive(3, 9);
    const number2 = randomIntegerInclusive(2, number3 - 1);
    const number4 = randomIntegerInclusive(1, number2 - 1);

    return { number1, number2, number3, number4 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4 },
      translate
    } = props;

    // Simplify fractions in sentence
    const [simplifiedNumber2, simplifiedNumber3a] = simplify(number2, number3);
    const [simplifiedNumber4, simplifiedNumber3b] = simplify(number4, number3);

    // answer1 === number1
    const answer2 = number2 - number4;
    // Simplify the answer
    const [simplifiedAnswer2, simplifiedAnswer3] = simplify(answer2, number3);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[
          number1.toString(),
          simplifiedAnswer2.toString(),
          simplifiedAnswer3.toString()
        ]}
        sentence={`<frac w='${number1}' n='${simplifiedNumber2}' d='${simplifiedNumber3a}'/> ${SUB} <frac n='${simplifiedNumber4}' d='${simplifiedNumber3b}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.fractionMustBeInSimplestForm()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aqQ',
  description: 'aqQ',
  keywords: ['Subtraction', 'Mixed numbers', 'Fractions'],
  schema: z.object({
    number1: z.number().int().min(2).max(3),
    number2: z.number().int().min(1).max(3),
    number3: numberEnum([3, 4]),
    number4: z.number().int().min(1).max(2),
    number5: z.number().int().min(1).max(8),
    number6: numberEnum([6, 8, 9])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 3);
    const number4 = randomIntegerInclusive(1, number1 - 1);

    const number3 = getRandomFromArray([3, 4] as const);
    const number6: 6 | 8 | 9 = number3 === 3 ? getRandomFromArray([6, 9] as const) : 8;

    const number2 = randomIntegerInclusive(1, number3 - 1, {
      constraint: x => {
        const simplifiedA = simplify(x, number3);
        // Fractions must be in their simplest forms when generated.
        return x === simplifiedA[0];
      }
    });
    const number5 = randomIntegerInclusive(1, number6 - 1, {
      constraint: x => {
        const simplifiedB = simplify(x, number3);
        // Fractions must be in their simplest forms when generated.
        return x === simplifiedB[0];
      }
    });

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    // Convert both fractions to improper and calculate answers
    const [numerator1, denominator1] = mixedNumberToImproperFraction(number1, number2, number3);
    const [numerator2, denominator2] = mixedNumberToImproperFraction(number4, number5, number6);

    const answerNumerator = numerator1 * denominator2 - numerator2 * denominator1;
    const answerDenominator = denominator1 * denominator2;

    const [answer1, answer2, answer3] = improperFractionToMixedNumber(
      answerNumerator,
      answerDenominator
    );

    const answerSentenceFraction =
      answer1 === 0 ? `<frac nAns='' dAns=''/>` : `<frac wAns='' nAns='' dAns=''/>`;

    // Bar models
    const [numeratorColorA, numeratorColorB] = getRandomSubArrayFromArray(
      Object.values(barModelColors),
      2,
      {
        random: seededRandom(props.question)
      }
    ) as string[];

    const shaded1 = number1 * number3 + number2;
    const unshaded1 = (number1 + 1) * number3;
    const shadedArray1 = filledArray(numeratorColorA, shaded1);
    const unshadedArray1 = filledArray('white', unshaded1);

    const rectangle1Colors = shadedArray1.concat(unshadedArray1);

    const shaded2 = number4 * number6 + number5;
    const unshaded2 = (number4 + 1) * number6;
    const shadedArray2 = filledArray(numeratorColorB, shaded2);
    const unshadedArray2 = filledArray('white', unshaded2);

    const rectangle2Colors = shadedArray2.concat(unshadedArray2);

    // Calculate ratio to use as scaling factor for the smaller of the 2 bar models
    // Ensures the width of sections for both bar models match
    const sectionsWidth1 = unshaded1 / number3;
    const sectionsWidth2 = unshaded2 / number6;
    const widthRatio = sectionsWidth2 / sectionsWidth1;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        questionHeight={1000}
        title={translate.instructions.useTheBarModelToCompleteCalc()}
        testCorrect={userAnswer =>
          answer1 === 0
            ? compareFractions(userAnswer, [answer2, answer3])
            : compareFractions(userAnswer, [answer1, answer2, answer3])
        }
        inputMaxCharacters={2}
        sentence={`<frac w='${number1.toLocaleString()}' n='${number2}' d='${number3}'/> ${SUB} <frac w='${number4.toLocaleString()}' n='${number5}' d='${number6}'/> = ${answerSentenceFraction}`}
        Content={({ dimens }) => (
          <View style={[dimens, { alignItems: 'flex-start', flexWrap: 'wrap' }]}>
            <ShadedFractionBarModel
              totalSubSections={unshaded1}
              totalPerSection={number3}
              customColorMap={rectangle1Colors}
              width={dimens.width}
              height={dimens.height / 3}
            />
            <ShadedFractionBarModel
              totalSubSections={unshaded2}
              totalPerSection={number6}
              customColorMap={rectangle2Colors}
              width={dimens.width * widthRatio}
              height={dimens.height / 3}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            answer1.toLocaleString(),
            answer2.toLocaleString(),
            answer3.toLocaleString()
          ],
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aqR',
  description: 'aqR',
  keywords: ['Subtraction', 'Fractions', 'Mixed numbers'],
  schema: z
    .object({
      number1: z.number().int().min(4).max(9),
      number2: z.number().int().min(1).max(5),
      number3: z.number().int().min(2).max(6),
      number4: z.number().int().min(1).max(5),
      number5: z.number().int().min(4).max(7),
      number6: z.number().int().min(6).max(15)
    })
    .refine(
      val => leastCommonMultiple(val.number3, val.number6) < 25,
      'number3 and number6 must have a common multiple less than 26'
    )
    .refine(
      val => val.number2 * val.number6 - val.number5 * val.number3 !== 0,
      'numerator does not sum to 0'
    )
    .refine(
      val => val.number2 * val.number6 - val.number5 * val.number3 !== 1,
      'numerator does not sum to 1'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(4, 9);
    const number4 = randomIntegerInclusive(1, 5, {
      // number4 is always at least 2 less than number1 (in case of exchange)
      constraint: x => x < number1 - 1
    });

    // numerator must not sum to 0 or 1
    const { number2, number3, number5, number6 } = rejectionSample(
      () => {
        const number3 = randomIntegerInclusive(2, 6);
        const number2 = randomIntegerInclusive(1, 5, {
          constraint: x => x < number3
        });

        const number6 = randomIntegerInclusive(6, 15, {
          constraint: x => leastCommonMultiple(number3, x) < 25
        });

        const number5 = randomIntegerInclusive(4, 7, {
          constraint: x => x < number6
        });

        return { number2, number3, number5, number6 };
      },
      ({ number2, number3, number5, number6 }) =>
        number2 * number6 - number5 * number3 !== 0 && number2 * number6 - number5 * number3 !== 1
    );

    return { number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, number4, number5, number6 },
      translate
    } = props;

    let answer1 = number1 - number4;
    let answer2 = number2 * number6 - number5 * number3;
    const answer3 = number3 * number6;

    // If exchange occurs for integer
    if (answer2 < 0) {
      answer1--;
      answer2 = answer3 - Math.abs(answer2);
    }

    // Simplify all fractions
    const [simplifiedNum2, simplifiedNum3] = simplify(number2, number3);
    const [simplifiedNum5, simplifiedNum6] = simplify(number5, number6);

    const [simplifiedAns2, simplifiedAns3] = simplify(answer2, answer3);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[answer1.toString(), simplifiedAns2.toString(), simplifiedAns3.toString()]}
        sentence={`<frac w='${number1}' n='${simplifiedNum2}' d='${simplifiedNum3}'/> ${SUB} <frac w='${number4}' n='${simplifiedNum5}' d='${simplifiedNum6}'/> = <frac wAns='' nAns='' dAns=''/>`}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.fractionMustBeInSimplestForm()
        }}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aqS',
  description: 'aqS',
  keywords: ['Subtraction', 'Fractions', 'Mixed numbers', 'Missing value'],
  schema: z.object({
    number1: z.number().int().min(6).max(9),
    number3: numberEnum([8, 9]),
    number4: z.number().int().min(1).max(4),
    number5: numberEnum([1, 2, 3]),
    number6: numberEnum([3, 4])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(6, 9);
    const number4 = randomIntegerInclusive(1, 4);

    const number3 = getRandomFromArray([8, 9] as const);
    const number6 = number3 === 8 ? (4 as const) : (3 as const);

    const number5 =
      number6 === 3 ? getRandomFromArray([1, 2] as const) : getRandomFromArray([1, 2, 3] as const);

    return { number1, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { number1, number3, number4, number5, number6 },
      translate
    } = props;

    const number2 = 1;

    let number7 = number1 - number4;
    let number8 = number2 * number6 - number5 * number3;
    const number9 = number3 * number6;

    // If exchange occurs for integer
    if (number8 < 0) {
      number7--;
      number8 = number9 - Math.abs(number8);
    }

    // Simplify all fractions
    const [simplifiedNum2, simplifiedNum3] = simplify(number2, number3);
    const [simplifiedNum5, simplifiedNum6] = simplify(number5, number6);
    const [simplifiedNum8, simplifiedNum9] = simplify(number8, number9);

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculationAnswerInSimplestForm()}
        testCorrect={[number4.toString(), simplifiedNum5.toString()]}
        sentence={`<frac w='${number1}' n='${simplifiedNum2}' d='${simplifiedNum3}'/> ${SUB} <frac wAns='' nAns='' d='${simplifiedNum6}'/> = <frac w='${number7}' n='${simplifiedNum8}' d='${simplifiedNum9}'/>`}
        fractionContainerStyle={{ height: 96 }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aqT',
  description: 'aqT',
  keywords: ['Subtraction', 'Fractions', 'Mixed numbers', 'Difference'],
  schema: z
    .object({
      name: nameSchema,
      number1: z.number().int().min(4).max(7),
      number2: z.number().int().min(1).max(9),
      number3: z.number().int().min(3).max(10),
      number4: z.number().int().min(1).max(5),
      number5: z.number().int().min(1).max(5),
      number6: z.number().int().min(3).max(10)
    })
    .refine(
      val => leastCommonMultiple(val.number3, val.number6) < 41,
      'number3 and number6 must have a common multiple less than 41'
    )
    .refine(
      val => simplify(val.number2, val.number3)[1] !== 1,
      'Fraction number2/number3 must not simplify to an integer'
    )
    .refine(
      val =>
        fractionToDecimal(val.number2, val.number3) > fractionToDecimal(val.number5, val.number6),
      'Fraction number2/number3 must be greater than fraction number5/number6'
    ),
  questionHeight: 1100,
  simpleGenerator: () => {
    const name = getRandomName();

    const number1 = randomIntegerInclusive(4, 7);

    const number4 = randomIntegerInclusive(1, 5, {
      constraint: x => x < number1
    });

    const { number2, number3, number5, number6 } = rejectionSample(
      () => {
        // Numbers must be different and have LCM < 41
        const [number3, number6] = randomUniqueIntegersInclusive(3, 10, 2);

        const number2 = randomIntegerInclusive(1, number3 - 1);

        const number5 = randomIntegerInclusive(1, 5, {
          constraint: x => x !== number6
        });

        return { number2, number3, number5, number6 };
      },
      ({ number2, number3, number5, number6 }) =>
        leastCommonMultiple(number3, number6) < 41 &&
        // Fraction subtraction must not exchange, i.e. first fraction must be greater than second fraction:
        fractionToDecimal(number2, number3) > fractionToDecimal(number5, number6)
    );

    return { name, number1, number2, number3, number4, number5, number6 };
  },
  Component: props => {
    const {
      question: { name, number1, number2, number3, number4, number5, number6 },
      displayMode,
      translate
    } = props;

    // Calculate answer
    const [frac1Numerator, frac1Denominator] = mixedNumberToImproperFraction(
      number1,
      number2,
      number3
    );
    const [frac2Numerator, frac2Denominator] = mixedNumberToImproperFraction(
      number4,
      number5,
      number6
    );

    const ansNumerator = frac1Numerator * frac2Denominator - frac2Numerator * frac1Denominator;
    const ansDenominator = frac1Denominator * frac2Denominator;

    // Simplify all fractions
    const [simplifiedNum2, simplifiedNum3] = simplify(number2, number3);
    const [simplifiedNum5, simplifiedNum6] = simplify(number5, number6);

    const [ansInteger, simplifiedAns1, simplifiedAns2] = improperFractionToMixedNumber(
      ansNumerator,
      ansDenominator
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.howMuchHeavierAreThePotatoesThanCarrots(
          name,
          `<frac w='${number1.toLocaleString()}' n='${simplifiedNum2.toLocaleString()}' d='${simplifiedNum3.toLocaleString()}'/>`,
          `<frac w='${number4.toLocaleString()}' n='${simplifiedNum5.toLocaleString()}' d='${simplifiedNum6.toLocaleString()}'/>`
        )}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? 36 : 64 }}
        titleFractionDividerStyle={{ marginVertical: 4 }}
        testCorrect={[ansInteger.toString(), simplifiedAns1.toString(), simplifiedAns2.toString()]}
        sentence={translate.units.stringKg(`<frac wAns='' nAns='' dAns=''/>`)}
        questionHeight={1100}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aqU',
  description: 'aqU',
  keywords: ['Subtraction', 'Fractions', 'Mixed numbers', 'Difference'],
  schema: z
    .object({
      nameA: nameSchema,
      nameB: nameSchema,
      wholeA: numberEnum([4, 5]),
      numeratorA: z.number().int().min(1).max(9),
      denominatorA: z.number().int().min(3).max(10),
      wholeB: numberEnum([1, 2]),
      numeratorB: z.number().int().min(1).max(9),
      denominatorB: z.number().int().min(3).max(10)
    })
    .refine(val => val.numeratorA < val.denominatorA, 'numeratorA must be less than denominatorA')
    .refine(val => val.numeratorB < val.denominatorB, 'numeratorB must be less than denominatorB'),
  questionHeight: 1100,
  simpleGenerator: () => {
    const [nameA, nameB] = getRandomUniqueNames(2);

    const { wholeA, denominatorA, numeratorA, wholeB, denominatorB, numeratorB } = rejectionSample(
      () => {
        const wholeA = getRandomFromArray([4, 5] as const);
        const denominatorA = randomIntegerInclusive(3, 10);
        const numeratorA = randomIntegerInclusive(1, denominatorA - 1);

        const wholeB = getRandomFromArray([1, 2] as const);
        const denominatorB = randomIntegerInclusive(3, 10);
        const numeratorB = randomIntegerInclusive(1, denominatorB - 1);
        return { wholeA, denominatorA, numeratorA, wholeB, denominatorB, numeratorB };
      },
      // Only permit them if numeratorA/denominatorA is not the same as numeratorB/denominatorB
      // and the answer is greater than 1
      ({ wholeA, denominatorA, numeratorA, wholeB, denominatorB, numeratorB }) => {
        if (compareFloats(numeratorA / denominatorA, numeratorB / denominatorB)) {
          return false;
        } else if (wholeA + numeratorA / denominatorA - wholeB - numeratorB / denominatorB < 1) {
          return false;
        }
        return true;
      }
    );

    return { nameA, nameB, wholeA, numeratorA, denominatorA, wholeB, numeratorB, denominatorB };
  },
  Component: props => {
    const {
      question: {
        nameA,
        nameB,
        wholeA,
        numeratorA,
        denominatorA,
        wholeB,
        numeratorB,
        denominatorB
      },
      displayMode,
      translate
    } = props;

    // Simplify all fractions
    const [simplifiedNumA, simplifiedDenomA] = simplify(numeratorA, denominatorA);
    const [simplifiedNumB, simplifiedDenomB] = simplify(numeratorB, denominatorB);

    // Calculate answer
    const [ansWhole, ansNumerator, ansDenominator] = improperFractionToMixedNumber(
      ...fractionArithmetic(
        [wholeA, simplifiedNumA, simplifiedDenomA],
        [wholeB, simplifiedNumB, simplifiedDenomB],
        SUB
      )
    );

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.twoCharsLiveDistFromParkWorkOutMissingDist({
          nameA,
          nameB,
          distanceA: `<frac w='${wholeA}' n='${simplifiedNumA}' d='${simplifiedDenomA}'/>`,
          distanceB: `<frac w='${wholeB}' n='${simplifiedNumB}' d='${simplifiedDenomB}'/>`
        })}
        titleFractionContainerStyle={{ height: displayMode === 'digital' ? 36 : 64 }}
        titleFractionDividerStyle={{ marginVertical: 4 }}
        testCorrect={[ansWhole.toString(), ansNumerator.toString(), ansDenominator.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
        sentence={`<frac wAns='' nAns='' dAns=''/> ${translate.units.km()}`}
        questionHeight={1100}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.fractionMustBeInSimplestForm() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractMixedNumbers',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
