import { newQuestionContent } from '../../../Question';
import { newSmallStepContent } from '../../../SmallStep';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import { z } from 'zod';
import {
  binOpEquationToSentenceString,
  binOpEquationsToTestCorrect,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { View } from 'react-native';
import { DIV, MULT } from '../../../../constants';
import { colors } from '../../../../theme/colors';
import { isPrime } from 'mathjs';
import { findFactors } from '../../../../utils/factors';
import { compareFloats, numberToBase10Object } from '../../../../utils/math';
import { BarModelWithState } from '../../../../components/question/representations/BarModel';
import AutoScaleText from '../../../../components/typography/AutoScaleText';
import Text from '../../../../components/typography/Text';
import { countRange, filledArray } from '../../../../utils/collections';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { CompleteTheSentenceWithState } from '../../../../components/molecules/CompleteTheSentence';
import ContentBox from '../../../../components/molecules/ContentBox';
import { all, create, number } from 'mathjs';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aoA',
  description: 'aoA',
  keywords: ['Multiply', 'Problem', 'Bar model'],
  schema: z
    .object({
      number1: z.number().int().min(151).max(499),
      number2: z.number().int().min(11).max(25)
    })
    .refine(val => val.number1 % 10 !== 0, 'number1 must not be a multiple of 10')
    .refine(
      val => val.number2 % 10 > 0 && val.number2 % 10 < 6,
      'number2 must have 1, 2, 3, 4 or 5 ones.'
    ),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(151, 499, {
      constraint: x => x % 10 !== 0
    });

    const number2 = randomIntegerInclusive(11, 25, {
      constraint: x => x % 10 > 0 && x % 10 < 6
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const number2Base10Object = numberToBase10Object(number2);

    const number2Ones = number2Base10Object.ones || 0;
    const number2Tens = number2Base10Object.tens || 0;

    const tens = filledArray(10, number2Tens);

    // Just always treat the ones as though it's at least 4 in terms of its proportional width
    // so its answer box is always wide enough.
    const numbers = [number2Ones < 4 ? [...tens, 4] : [...tens, number2Ones]];

    const strings = [
      ...countRange(number2Tens).map(_ => `${MULT} ${(10).toLocaleString()}`),
      `${MULT} ${number2Ones.toLocaleString()}`
    ];

    const totalOfNumbers = numbers[0].reduce((a, b) => a + b);
    const answerIndices = [countRange(number2Tens + 1).map((_, index) => index)];

    return (
      <QF3Content
        title={translate.instructions.useBarModelToWorkOutTheMult()}
        actionPanelVariant="endWide"
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-between' }]}>
            <View style={{ height: dimens.height * 0.7, justifyContent: 'center' }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: dimens.width * 0.1
                }}
              >
                {strings.map((string, index) => (
                  <Text
                    key={index}
                    variant="WRN400"
                    style={{
                      width: (dimens.width * 0.9 - 40) * (numbers[0][index] / totalOfNumbers),
                      textAlign: 'center'
                    }}
                  >
                    {string}
                  </Text>
                ))}
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text variant="WRN400" style={{ width: dimens.width * 0.1 }}>
                  {number1.toLocaleString()}
                </Text>
                <BarModelWithState
                  numbers={numbers}
                  total={totalOfNumbers}
                  dimens={{ height: dimens.height / 2, width: dimens.width * 0.9 }}
                  sameRowColor
                  answerIndices={answerIndices}
                  id={'barModel'}
                />
              </View>
            </View>
            <CompleteTheSentenceWithState
              id="sentence"
              sentence={`${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} = <ans/>`}
              testCorrect={userAnswer => userAnswer[0] === (number1 * number2).toString()}
              textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
              style={{ alignSelf: 'flex-start' }}
              inputMaxCharacters={5}
              defaultState={
                displayMode === 'markscheme' ? [(number1 * number2).toLocaleString()] : undefined
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aoB',
  description: 'aoB',
  keywords: ['Multiply', 'Strategy'],
  schema: z
    .object({
      number1: z.number().int().min(21).max(99),
      numberA2: numberEnum([10, 100, 1000])
    })
    .refine(val => val.number1 % 10 !== 0, 'number1 must not be a multiple of 10'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 99, {
      constraint: x => x % 10 !== 0
    });

    const numberA2 = getRandomFromArray([10, 100, 1000] as const);

    return { number1, numberA2 };
  },

  Component: props => {
    const {
      question: { number1, numberA2 },
      translate
    } = props;

    const numberB2 = numberA2 - 1;

    const eqs = [
      getBinOpEquation({ left: number1, right: numberA2, sign: 'multiply', answer: 'result' }),
      getBinOpEquation({ left: number1, right: numberB2, sign: 'multiply', answer: 'result' })
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.useTheAnswerFromFirstCalcToWorkOutSecondCalc()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aoC',
  description: 'aoC',
  keywords: ['Multiply', 'Strategy'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(101)
      .max(9999)
      .refine(val => val % 10 !== 0, 'number must not be a multiple of 10')
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const lessThan1000 = getRandomBoolean();

    const number = lessThan1000
      ? randomIntegerInclusive(101, 999, {
          constraint: x => x % 10 !== 0
        })
      : randomIntegerInclusive(1001, 9999, {
          constraint: x => x % 10 !== 0
        });

    return { number };
  },

  Component: props => {
    const {
      question: { number },
      translate
    } = props;
    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={translate.instructions.hereIsStrategyForMultBy5()}
        testCorrect={[[], [(number * 5).toString()]]}
        sentences={[
          translate.answerSentences.useTheStratToWorkOutTheMult(),
          `${number.toLocaleString()} ${MULT} ${(5).toLocaleString()} = <ans/>`
        ]}
        Content={({ dimens }) => (
          <View
            style={{ borderWidth: 2, borderColor: 'black', backgroundColor: colors.greenTextBox }}
          >
            <AutoScaleText
              variant="WRN400"
              containerStyle={{ height: dimens.height / 4, width: dimens.width - 50 }}
              group={'strategy'}
              letterEmWidth={0.6}
              maxLines={1}
            >
              {translate.answerSentences.multiplyNumBy10FindHalf()}
            </AutoScaleText>
          </View>
        )}
        questionHeight={1000}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aoD',
  description: 'aoD',
  keywords: ['Multiply', 'Factors', 'Factor pairs'],
  schema: z
    .object({
      number1: z.number().int().min(21).max(99),
      number2: z.number().int().min(12).max(18),
      number2Factor: z.number().int().min(2).max(9)
    })
    .refine(val => val.number1 % 10 !== 0, 'number1 must not be a multiple of 10')
    .refine(val => !isPrime(val.number2), 'number2 must not be a prime number.')
    .refine(
      val => findFactors(val.number2).includes(val.number2Factor),
      'number2Factor must be a factor of number2 that is not 1 or number2'
    ),
  questionHeight: 500,
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(21, 99, {
      constraint: x => x % 10 !== 0
    });

    const number2 = randomIntegerInclusive(12, 18, {
      constraint: x => !isPrime(x)
    });

    const number2AllFactors = findFactors(number2);

    // Remove first factor from array:
    number2AllFactors.shift();

    // Remove final factor from array:
    number2AllFactors.pop();

    const number2Factor = getRandomFromArray(number2AllFactors) as number;

    return { number1, number2, number2Factor };
  },

  Component: props => {
    const {
      question: { number1, number2, number2Factor },
      translate,
      displayMode
    } = props;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.fillInMissingNumber()}
        testCorrect={[(number2 / number2Factor).toString()]}
        sentence={`${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} = ${number1.toLocaleString()} ${MULT} ${number2Factor.toLocaleString()} ${MULT} <ans/>`}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        questionHeight={500}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aoE',
  description: 'aoE',
  keywords: ['Multiply', 'Factors', 'Factor pairs'],
  schema: z.object({
    number1: z.number().int().min(3).max(9),
    number2: z.number().int().min(2).max(5),
    number3: z.number().int().min(3).max(9)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(3, 9);
    const number2 = randomIntegerInclusive(2, 5);
    const number3 = randomIntegerInclusive(3, 9);

    return { number1, number2, number3 };
  },
  Component: props => {
    const {
      question: { number1, number2, number3 },
      translate
    } = props;

    const ans1 = number1 * number2 * number3;

    const ans2 = number2 * number3;

    return (
      <QF2AnswerBoxManySentences
        sentences={[
          `${number1} ${MULT} ${number2} ${MULT} ${number3} = <ans/>`,
          `${number1} ${MULT} ${ans2} = <ans/>`
        ]}
        title={translate.instructions.completeCalculations()}
        testCorrect={[[ans1.toString()], [ans1.toString()]]}
        customMarkSchemeAnswer={{
          answersToDisplay: [[ans1.toLocaleString()], [ans1.toLocaleString()]]
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aoF',
  description: 'aoF',
  keywords: ['Multiply', 'How many ways'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(13)
        .max(29)
        .refine(val => val !== 20, 'number1 cannot equal 20'),
      number2: z.number().int().min(6).max(9),
      optionA: z.enum([
        'multiplicationA',
        'multiplicationB',
        'multiplicationC',
        'multiplicationD',
        'multiplicationE',
        'multiplicationF',
        'divisionA',
        'divisionB',
        'divisionC',
        'divisionD',
        'divisionE',
        'divisionF'
      ]),
      optionB: z.enum([
        'multiplicationA',
        'multiplicationB',
        'multiplicationC',
        'multiplicationD',
        'multiplicationE',
        'multiplicationF',
        'divisionA',
        'divisionB',
        'divisionC',
        'divisionD',
        'divisionE',
        'divisionF'
      ])
    })
    .refine(val => val.optionA !== val.optionB, 'optionA and optionB must be different.'),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(13, 29, {
      constraint: x => x !== 20
    });

    const number2 = randomIntegerInclusive(6, 9);

    const [optionA, optionB] = getRandomSubArrayFromArray(
      [
        'multiplicationA',
        'multiplicationB',
        'multiplicationC',
        'multiplicationD',
        'multiplicationE',
        'multiplicationF',
        'divisionA',
        'divisionB',
        'divisionC',
        'divisionD',
        'divisionE',
        'divisionF'
      ] as const,
      2
    );

    return { number1, number2, optionA, optionB };
  },

  Component: props => {
    const {
      question: { number1, number2, optionA, optionB },
      translate,
      displayMode
    } = props;

    const number3 = number1 * number2;
    const number4 = number2 + 1;
    const number5 = number2 - 1;
    const number6 = number1 * 10;
    const number7 = number2 * 10;
    const number8 = number1 + 100;
    const number9 = number3 * 10;
    const number10 = number(math.evaluate(`${number3} / 10`));

    const sentenceAndAnswerMaker = (option: string): [string, number] => {
      switch (option) {
        case 'multiplicationA':
          return [
            `${number1.toLocaleString()} ${MULT} ${number4.toLocaleString()} = <ans/>`,
            number1 * number4
          ];
        case 'multiplicationB':
          return [
            `${number1.toLocaleString()} ${MULT} ${number5.toLocaleString()} = <ans/>`,
            number1 * number5
          ];
        case 'multiplicationC':
          return [
            `${number6.toLocaleString()} ${MULT} ${number2.toLocaleString()} = <ans/>`,
            number6 * number2
          ];
        case 'multiplicationD':
          return [
            `${number6.toLocaleString()} ${MULT} ${number7.toLocaleString()} = <ans/>`,
            number6 * number7
          ];
        case 'multiplicationE':
          return [
            `${number1.toLocaleString()} ${MULT} ${number7.toLocaleString()} = <ans/>`,
            number1 * number7
          ];
        case 'multiplicationF':
          return [
            `${number8.toLocaleString()} ${MULT} ${number2.toLocaleString()} = <ans/>`,
            number8 * number2
          ];
        case 'divisionA':
          return [
            `${number3.toLocaleString()} ${DIV} ${number2.toLocaleString()} = <ans/>`,
            number(math.evaluate(`${number3} / ${number2}`))
          ];
        case 'divisionB':
          return [
            `${number3.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`,
            number(math.evaluate(`${number3} / ${number1}`))
          ];
        case 'divisionC':
          return [
            `${number9.toLocaleString()} ${DIV} ${number2.toLocaleString()} = <ans/>`,
            number(math.evaluate(`${number9} / ${number2}`))
          ];
        case 'divisionD':
          return [
            `${number9.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`,
            number(math.evaluate(`${number9} / ${number1}`))
          ];
        case 'divisionE':
          return [
            `${number10.toLocaleString()} ${DIV} ${number2.toLocaleString()} = <ans/>`,
            number(math.evaluate(`${number10} / ${number2}`))
          ];
        default:
        case 'divisionF':
          return [
            `${number10.toLocaleString()} ${DIV} ${number1.toLocaleString()} = <ans/>`,
            number(math.evaluate(`${number10} / ${number1}`))
          ];
      }
    };

    const [sentenceA, answerA] = sentenceAndAnswerMaker(optionA);
    const [sentenceB, answerB] = sentenceAndAnswerMaker(optionB);

    return (
      <QF1ContentAndSentences
        title={translate.instructions.useMultiplicationGivenToCompleteRelatedFacts()}
        extraSymbol="decimalPoint"
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answerA) && compareFloats(userAnswer[1][0], answerB)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [[answerA.toLocaleString()], [answerB.toLocaleString()]]
        }}
        inputMaxCharacters={5}
        sentences={[sentenceA, sentenceB]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        style={{ alignSelf: 'center' }}
        textStyle={{ fontSize: displayMode === 'digital' ? 40 : 50 }}
        pdfDirection="column"
        Content={
          <ContentBox>
            <Text variant="WRN400">{`${number1.toLocaleString()} ${MULT} ${number2.toLocaleString()} = ${number3.toLocaleString()}`}</Text>
          </ContentBox>
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SolveProblemsWithMultiplication',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6]
});
export default SmallStep;
