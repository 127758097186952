import { newSmallStepContent } from '../../../SmallStep';
import { z } from 'zod';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { useMemo } from 'react';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import { convert, convertUnitsSuffix, createMeasurement } from '../../../../utils/unitConversion';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { compareFloats, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'an6',
  description: 'an6',
  keywords: ['Metric', 'Convert', 'Mass', 'Capacity', 'Length'],
  schema: z.object({
    unitToConvertFrom: z.enum([
      'milligrams',
      'grams',
      'kilograms',
      'millimetres',
      'centimetres',
      'metres',
      'millilitres',
      'centilitres'
    ])
  }),

  simpleGenerator: () => {
    const unitToConvertFrom = getRandomFromArray([
      'milligrams',
      'grams',
      'kilograms',
      'millimetres',
      'centimetres',
      'metres',
      'millilitres',
      'centilitres'
    ] as const);

    return { unitToConvertFrom };
  },

  Component: ({ question: { unitToConvertFrom }, translate }) => {
    const [conversion, smallUnit, bigUnit] = (() => {
      switch (unitToConvertFrom) {
        case 'milligrams':
          return [1000, translate.units.milligrams(1000), translate.units.grams(1)];
        case 'grams':
          return [1000, translate.units.grams(1000), translate.units.kilograms(1)];
        case 'kilograms':
          return [1000, translate.units.kilograms(1000), translate.units.tonnes(1)];
        case 'millimetres':
          return [10, translate.units.millimetres(10), translate.units.centimetres(1)];
        case 'centimetres':
          return [100, translate.units.centimetres(100), translate.units.metres(1)];
        case 'metres':
          return [1000, translate.units.metres(1000), translate.units.kilometres(1)];
        case 'millilitres':
          return [1000, translate.units.millilitres(1000), translate.units.litres(1)];
        case 'centilitres':
          return [100, translate.units.centilitres(100), translate.units.litres(1)];
      }
    })();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeSentence()}
        sentence={translate.answerSentences.thereAreAnsXin1Y(smallUnit, bigUnit)}
        testCorrect={[conversion.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'an7',
  description: 'an7',
  keywords: ['Metric', 'Length', 'Units', 'Convert'],
  schema: z.object({
    numbers: z.number().int().min(1).max(9).array().length(3),
    measurements: z
      .array(z.array(z.enum(['g', 'kg', 'tonnes', 'mm', 'cm', 'm', 'ml', 'cl', 'l'])).length(2))
      .length(3)
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(1, 9, 3);
    const x = countRange(3).map(_ => getRandomFromArray(['mass', 'length', 'capacity'] as const));

    const getMeasurement = (m: string) => {
      switch (m) {
        case 'mass':
          return getRandomSubArrayFromArray(['g', 'kg', 'tonnes'], 2);
        case 'length':
          return getRandomSubArrayFromArray(['mm', 'cm', 'm'], 2);
        case 'capacity':
          return getRandomSubArrayFromArray(['ml', 'cl', 'l'], 2);
      }
    };

    const measurements = x.map(it => {
      return getMeasurement(it) as ['g', 'kg', 'tonnes', 'mm', 'cm', 'm', 'ml', 'cl', 'l'];
    });

    return { numbers, measurements };
  },
  Component: props => {
    const {
      question: { numbers, measurements },
      translate
    } = props;

    const [A1, B1, C1] = numbers;
    const [measurementsA, measurementsB, measurementsC] = measurements;

    const A2 = convert(createMeasurement(A1, measurementsA[0]), measurementsA[1]).value;
    const B2 = convert(createMeasurement(B1, measurementsB[0]), measurementsB[1]).value;
    const C2 = convert(createMeasurement(C1, measurementsC[0]), measurementsC[1]).value;

    const statements = [
      {
        statement: `${A1.toLocaleString()} ${translate.units[measurementsA[0]](A1)}`,
        correctAnswer: `${A2.toLocaleString()} ${translate.units[measurementsA[1]](A2)}`
      },
      {
        statement: `${B1.toLocaleString()} ${translate.units[measurementsB[0]](B1)}`,
        correctAnswer: `${B2.toLocaleString()} ${translate.units[measurementsB[1]](B2)}`
      },
      {
        statement: `${C1.toLocaleString()} ${translate.units[measurementsC[0]](C1)}`,
        correctAnswer: `${C2.toLocaleString()} ${translate.units[measurementsC[1]](C2)}`
      }
    ];

    const answerOptions = shuffle(
      [
        `${A2.toLocaleString()} ${translate.units[measurementsA[1]](A2)}`,
        `${B2.toLocaleString()} ${translate.units[measurementsB[1]](B2)}`,
        `${C2.toLocaleString()} ${translate.units[measurementsC[1]](C2)}`
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentencesDrag
        title={translate.instructions.matchEquivalentMeasurements()}
        items={answerOptions}
        actionPanelVariant="endWide"
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        sentencesStyle={{ alignSelf: 'center' }}
        sentences={statements.map(({ statement }) => `${statement}  =  <ans/>`)}
        testCorrect={statements.map(({ correctAnswer }) => [correctAnswer])}
        pdfLayout="itemsRight"
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'an8',
  description: 'an8',
  keywords: ['Metric', 'Convert', 'Length', 'Mass', 'Capacity'],
  schema: z.object({
    metricTo: z.enum(['mm', 'cm', 'm', 'mg', 'g', 'ml']),
    number1: z.number(),
    number2: z.number(),
    number3: z.number()
  }),
  simpleGenerator: () => {
    const metricTo = getRandomFromArray(['mm', 'cm', 'm', 'mg', 'g', 'ml'] as const);

    const multiple = (() => {
      if (metricTo === 'mm') {
        return 1;
      } else if (metricTo === 'cm') {
        return 100;
      } else {
        return 100;
      }
    })();

    const number1 = randomIntegerInclusive(1, 9) * multiple;
    const number2 = randomIntegerInclusive(11, 19) * multiple;
    const number3 = (randomIntegerInclusive(11, 19) / 10) * multiple;

    return {
      number1,
      number2,
      number3,
      metricTo
    };
  },
  Component: props => {
    const {
      question: { number1, number2, number3, metricTo },
      translate
    } = props;

    const metricFrom = (() => {
      switch (metricTo) {
        case 'mm':
          return 'cm';
        case 'cm':
          return 'm';
        case 'm':
          return 'km';
        case 'mg':
          return 'g';
        case 'g':
          return 'kg';
        case 'ml':
          return 'l';
      }
    })();

    const eqs = [number1, number2, number3].map(number => {
      return `${number.toLocaleString()} ${metricFrom} = <ans/> ${metricTo}`;
    });

    const answers = [number1, number2, number3].map(
      number => convertUnitsSuffix(number, metricFrom, metricTo).value
    );

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={[[answers[0].toFixed(0)], [answers[1].toFixed(0)], [answers[2].toFixed(0)]]}
        sentences={eqs}
        {...props}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'an9',
  description: 'an9',
  keywords: ['Metric', 'Convert', 'Length', 'Mass', 'Capacity'],
  schema: z.object({
    numbers: z.array(z.number()),
    metricChoices: z.array(z.string())
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    type MetricConversionType =
      | 'mm_to_cm'
      | 'cm_to_mm'
      | 'cm_to_m'
      | 'm_to_cm'
      | 'm_to_km'
      | 'km_to_m'
      | 'mg_to_g'
      | 'g_to_mg'
      | 'g_to_kg'
      | 'kg_to_g'
      | 'ml_to_l'
      | 'l_to_ml';

    const generateValidMetricValues = {
      mm_to_cm: () => randomIntegerInclusive(1, 1990),
      cm_to_mm: () => randomIntegerInclusive(1, 199) / 10,
      cm_to_m: () => randomIntegerInclusiveStep(10, 9990, 10),
      m_to_cm: () => randomIntegerInclusive(1, 999) / 10,
      m_to_km: () => randomIntegerInclusiveStep(100, 9900, 100),
      km_to_m: () => randomIntegerInclusive(1, 99) / 10,
      mg_to_g: () => randomIntegerInclusiveStep(100, 9900, 100),
      g_to_mg: () => randomIntegerInclusive(1, 99) / 10,
      g_to_kg: () => randomIntegerInclusiveStep(100, 9900, 100),
      kg_to_g: () => randomIntegerInclusive(1, 99) / 10,
      ml_to_l: () => randomIntegerInclusiveStep(100, 9900, 100),
      l_to_ml: () => randomIntegerInclusive(1, 99) / 10
    };

    // Choose between length units or capacity units
    const metricChoice = getRandomFromArray(['length', 'mass and capacity']);

    const conversions =
      metricChoice === 'length'
        ? ['mm_to_cm', 'cm_to_mm', 'cm_to_m', 'm_to_cm', 'm_to_km', 'km_to_m']
        : ['mg_to_g', 'g_to_mg', 'g_to_kg', 'kg_to_g', 'ml_to_l', 'l_to_ml'];

    // Return metricChoices from length or mass & capacity arrays
    const metricChoices = getRandomSubArrayFromArray(conversions, 4);

    // Loop over metricChoices and pass each conversion to lookup to return numbers
    const numbers = metricChoices.map(choice =>
      generateValidMetricValues[choice as MetricConversionType]()
    );

    return {
      numbers,
      metricChoices
    };
  },
  Component: props => {
    const {
      question: { numbers, metricChoices },
      translate
    } = props;

    // Loop over numbers and pass choice to convertUnits util function to return sum
    const answers = numbers.map((number, idx) => {
      const metricChoice = metricChoices[idx].split('_');
      const metricChoiceA = metricChoice[0];
      const metricChoiceB = metricChoice[2];

      return convertUnitsSuffix(number, metricChoiceA, metricChoiceB).value;
    });

    const eqs = numbers.map((number, idx) => {
      // Split metricChoices into seperate values e.g mm_to_cm -> mm & cm
      const metricChoice = metricChoices[idx].split('_');
      const metricChoiceA = metricChoice[0];
      const metricChoiceB = metricChoice[2];

      // Flip the order of alternate equations
      if (idx % 2 === 0) {
        return `${number.toLocaleString()} ${metricChoiceA} = <ans/> ${metricChoiceB}`;
      } else {
        return `<ans/> ${metricChoiceB} = ${number.toLocaleString()} ${metricChoiceA}`;
      }
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answers[0]) &&
          compareFloats(userAnswer[1][0], answers[1]) &&
          compareFloats(userAnswer[2][0], answers[2]) &&
          compareFloats(userAnswer[3][0], answers[3])
        }
        inputMaxCharacters={6}
        extraSymbol="decimalPoint"
        sentences={eqs}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: answers.map(ans => [ans.toLocaleString()]),
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'an92',
  description: 'an9',
  keywords: ['Metric', 'Convert', 'Length', 'Mass', 'Capacity'],
  schema: z.object({
    numbers: z.array(z.number()),
    metricChoices: z.array(z.string())
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    type MetricConversionType =
      | 'mm_to_cm'
      | 'cm_to_mm'
      | 'cm_to_m'
      | 'm_to_cm'
      | 'm_to_km'
      | 'km_to_m'
      | 'mg_to_g'
      | 'g_to_mg'
      | 'g_to_kg'
      | 'kg_to_g'
      | 'ml_to_l'
      | 'l_to_ml';

    const generateValidMetricValues = {
      mm_to_cm: () => randomIntegerInclusive(1, 1990),
      cm_to_mm: () => randomIntegerInclusive(1, 199) / 10,
      cm_to_m: () => randomIntegerInclusiveStep(10, 9990, 10),
      m_to_cm: () => randomIntegerInclusive(1, 999) / 10,
      m_to_km: () => randomIntegerInclusiveStep(100, 9900, 100),
      km_to_m: () => randomIntegerInclusive(1, 99) / 10,
      mg_to_g: () => randomIntegerInclusiveStep(100, 9900, 100),
      g_to_mg: () => randomIntegerInclusive(1, 99) / 10,
      g_to_kg: () => randomIntegerInclusiveStep(100, 9900, 100),
      kg_to_g: () => randomIntegerInclusive(1, 99) / 10,
      ml_to_l: () => randomIntegerInclusiveStep(100, 9900, 100),
      l_to_ml: () => randomIntegerInclusive(1, 99) / 10
    };

    // Choose between length units or capacity units
    const metricChoice = getRandomFromArray(['length', 'mass and capacity']);

    const conversions =
      metricChoice === 'length'
        ? ['mm_to_cm', 'cm_to_mm', 'cm_to_m', 'm_to_cm', 'm_to_km', 'km_to_m']
        : ['mg_to_g', 'g_to_mg', 'g_to_kg', 'kg_to_g', 'ml_to_l', 'l_to_ml'];

    // Return metricChoices from length or mass & capacity arrays
    const metricChoices = getRandomSubArrayFromArray(conversions, 2);

    // Loop over metricChoices and pass each conversion to lookup to return numbers
    const numbers = metricChoices.map(choice =>
      generateValidMetricValues[choice as MetricConversionType]()
    );

    return {
      numbers,
      metricChoices
    };
  },
  Component: props => {
    const {
      question: { numbers, metricChoices },
      translate
    } = props;

    // Loop over numbers and pass choice to convertUnits util function to return sum
    const answers = numbers.map((number, idx) => {
      const metricChoice = metricChoices[idx].split('_');
      const metricChoiceA = metricChoice[0];
      const metricChoiceB = metricChoice[2];

      return convertUnitsSuffix(number, metricChoiceA, metricChoiceB).value;
    });

    const eqs = numbers.map((number, idx) => {
      // Split metricChoices into seperate values e.g mm_to_cm -> mm & cm
      const metricChoice = metricChoices[idx].split('_');
      const metricChoiceA = metricChoice[0];
      const metricChoiceB = metricChoice[2];

      // Flip the order of alternate equations
      if (idx === 0) {
        return `${number.toLocaleString()} ${metricChoiceA} = <ans/> ${metricChoiceB}`;
      } else {
        return `<ans/> ${metricChoiceB} = ${number.toLocaleString()} ${metricChoiceA}`;
      }
    });

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeConversions()}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], answers[0]) && compareFloats(userAnswer[1][0], answers[1])
        }
        inputMaxCharacters={6}
        extraSymbol="decimalPoint"
        sentences={eqs}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: answers.map(ans => [ans.toLocaleString()]),
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aoa',
  description: 'aoa',
  keywords: ['Metric', 'Convert', 'Length', 'Compare'],
  schema: z
    .object({
      lessOrGreater: z.enum(['less', 'greater']),
      questionNumber: z.number().int().min(1).max(900),
      questionNumberUnit: z.enum(['cm', 'm']),
      mm1AsMm: z.number().int().min(1).max(9999),
      mm2AsMm: z.number().int().min(1).max(9999),
      cm1AsMm: z.number().int().min(1).max(9900),
      cm2AsMm: z.number().int().min(1).max(9900),
      m1AsMm: z.number().int().min(100).max(9990000).multipleOf(100),
      m2AsMm: z.number().int().min(100).max(9990000).multipleOf(100),
      km1AsMm: z.number().int().min(100000).max(99000000).multipleOf(100000),
      km2AsMm: z.number().int().min(100000).max(99000000).multipleOf(100000)
    })
    .refine(val =>
      arrayHasNoDuplicates([
        val.mm1AsMm,
        val.mm2AsMm,
        val.cm1AsMm,
        val.cm2AsMm,
        val.m1AsMm,
        val.m2AsMm,
        val.km1AsMm,
        val.km2AsMm
      ])
    )
    .refine(val => {
      const questionNumberAsMm =
        val.questionNumberUnit === 'cm' ? val.questionNumber * 10 : val.questionNumber * 1000;

      const lessOrGreaterThanNumFactor = [
        val.mm1AsMm,
        val.mm2AsMm,
        val.cm1AsMm,
        val.cm2AsMm,
        val.m1AsMm,
        val.m2AsMm,
        val.km1AsMm,
        val.km2AsMm
      ].filter(unit =>
        val.lessOrGreater === 'less' ? unit < questionNumberAsMm : unit > questionNumberAsMm
      );

      return lessOrGreaterThanNumFactor.length >= 2 && lessOrGreaterThanNumFactor.length <= 5;
    }, "Between 2 and 5 of the mm/cm/m/km units must be less than questionNumber's mm value"),
  simpleGenerator: () => {
    const lessOrGreater = getRandomFromArray(['less', 'greater'] as const);

    const {
      questionNumber,
      questionNumberUnit,
      mm1AsMm,
      mm2AsMm,
      cm1AsMm,
      cm2AsMm,
      m1AsMm,
      m2AsMm,
      km1AsMm,
      km2AsMm
    } = rejectionSample(
      () => {
        const questionNumberFactor = getRandomFromArray([1, 10, 100] as const);

        const questionNumber = randomIntegerInclusive(1, 9) * questionNumberFactor;

        const questionNumberUnit = getRandomFromArray(['cm', 'm'] as const);

        // Two unique mm units:
        const [mm1AsMm, mm2AsMm] = randomUniqueIntegersInclusive(1, 9999, 2);

        // Two unique cm units, selecting different bounds to speed up valid number generation:
        const cm1AsMmBounds = getRandomFromArray([
          [1, 99, 1],
          [100, 990, 10],
          [1000, 9900, 100]
        ] as const);
        const cm1AsMm = randomIntegerInclusiveStep(
          cm1AsMmBounds[0],
          cm1AsMmBounds[1],
          cm1AsMmBounds[2],
          {
            constraint: x => arrayHasNoDuplicates([x, mm1AsMm, mm2AsMm])
          }
        );

        const cm2AsMmBounds = getRandomFromArray([
          [1, 99, 1],
          [100, 990, 10],
          [1000, 9900, 100]
        ] as const);
        const cm2AsMm = randomIntegerInclusiveStep(
          cm2AsMmBounds[0],
          cm2AsMmBounds[1],
          cm2AsMmBounds[2],
          {
            constraint: x => arrayHasNoDuplicates([x, mm1AsMm, mm2AsMm, cm1AsMm])
          }
        );

        // Two unique m units, selecting different bounds to speed up valid number generation:
        const m1AsMmBounds = getRandomFromArray([
          [100, 9900, 100],
          [10000, 99000, 1000],
          [100000, 9990000, 10000]
        ] as const);
        const m1AsMm = randomIntegerInclusiveStep(
          m1AsMmBounds[0],
          m1AsMmBounds[1],
          m1AsMmBounds[2],
          {
            constraint: x => arrayHasNoDuplicates([x, mm1AsMm, mm2AsMm, cm1AsMm, cm2AsMm])
          }
        );

        const m2AsMmBounds = getRandomFromArray([
          [100, 9900, 100],
          [10000, 99000, 1000],
          [100000, 9990000, 10000]
        ] as const);
        const m2AsMm = randomIntegerInclusiveStep(
          m2AsMmBounds[0],
          m2AsMmBounds[1],
          m2AsMmBounds[2],
          {
            constraint: x => arrayHasNoDuplicates([x, mm1AsMm, mm2AsMm, cm1AsMm, cm2AsMm, m1AsMm])
          }
        );

        // Two unique km units, selecting different bounds to speed up valid number generation:
        const km1AsMmBounds = getRandomFromArray([
          [100000, 9900000, 100000],
          [10000000, 99000000, 1000000]
        ] as const);
        const km1AsMm = randomIntegerInclusiveStep(
          km1AsMmBounds[0],
          km1AsMmBounds[1],
          km1AsMmBounds[2],
          {
            constraint: x =>
              arrayHasNoDuplicates([x, mm1AsMm, mm2AsMm, cm1AsMm, cm2AsMm, m1AsMm, m2AsMm])
          }
        );

        const km2AsMmBounds = getRandomFromArray([
          [100000, 9900000, 100000],
          [10000000, 99000000, 1000000]
        ] as const);
        const km2AsMm = randomIntegerInclusiveStep(
          km2AsMmBounds[0],
          km2AsMmBounds[1],
          km2AsMmBounds[2],
          {
            constraint: x =>
              arrayHasNoDuplicates([x, mm1AsMm, mm2AsMm, cm1AsMm, cm2AsMm, m1AsMm, m2AsMm, km1AsMm])
          }
        );

        return {
          questionNumber,
          questionNumberUnit,
          mm1AsMm,
          mm2AsMm,
          cm1AsMm,
          cm2AsMm,
          m1AsMm,
          m2AsMm,
          km1AsMm,
          km2AsMm
        };
      },

      // Only permit them if their are between 2 and 5 correct answers.
      ({
        questionNumber,
        questionNumberUnit,
        mm1AsMm,
        mm2AsMm,
        cm1AsMm,
        cm2AsMm,
        m1AsMm,
        m2AsMm,
        km1AsMm,
        km2AsMm
      }) => {
        const questionNumberAsMm =
          questionNumberUnit === 'cm' ? questionNumber * 10 : questionNumber * 1000;

        const lessOrGreaterThanNumFactor = [
          mm1AsMm,
          mm2AsMm,
          cm1AsMm,
          cm2AsMm,
          m1AsMm,
          m2AsMm,
          km1AsMm,
          km2AsMm
        ].filter(unit =>
          lessOrGreater === 'less' ? unit < questionNumberAsMm : unit > questionNumberAsMm
        );

        return lessOrGreaterThanNumFactor.length >= 2 && lessOrGreaterThanNumFactor.length <= 5;
      }
    );
    return {
      lessOrGreater,
      questionNumber,
      questionNumberUnit,
      mm1AsMm,
      mm2AsMm,
      cm1AsMm,
      cm2AsMm,
      m1AsMm,
      m2AsMm,
      km1AsMm,
      km2AsMm
    };
  },
  Component: props => {
    const {
      question: {
        lessOrGreater,
        questionNumber,
        questionNumberUnit,
        mm1AsMm,
        mm2AsMm,
        cm1AsMm,
        cm2AsMm,
        m1AsMm,
        m2AsMm,
        km1AsMm,
        km2AsMm
      },
      translate
    } = props;

    const questionNumberAsMm = useMemo(() => {
      return questionNumberUnit === 'cm' ? questionNumber * 10 : questionNumber * 1000;
    }, [questionNumber, questionNumberUnit]);

    const statements = useMemo(() => {
      const mm1 = {
        value: mm1AsMm,
        string: `${translate.units.numberOfMm(mm1AsMm)}`
      };

      const mm2 = {
        value: mm2AsMm,
        string: `${translate.units.numberOfMm(mm2AsMm)}`
      };

      const cm1 = {
        value: cm1AsMm,
        string: `${translate.units.numberOfCm(convertUnitsSuffix(cm1AsMm, 'mm', 'cm').value)}`
      };

      const cm2 = {
        value: cm2AsMm,
        string: `${translate.units.numberOfCm(convertUnitsSuffix(cm2AsMm, 'mm', 'cm').value)}`
      };

      const m1 = {
        value: m1AsMm,
        string: `${translate.units.numberOfM(convertUnitsSuffix(m1AsMm, 'mm', 'm').value)}`
      };

      const m2 = {
        value: m2AsMm,
        string: `${translate.units.numberOfM(convertUnitsSuffix(m2AsMm, 'mm', 'm').value)}`
      };

      const km1 = {
        value: km1AsMm,
        string: `${translate.units.numberOfKm(convertUnitsSuffix(km1AsMm, 'mm', 'km').value)}`
      };

      const km2 = {
        value: km2AsMm,
        string: `${translate.units.numberOfKm(convertUnitsSuffix(km2AsMm, 'mm', 'km').value)}`
      };

      return shuffle([mm1, mm2, cm1, cm2, m1, m2, km1, km2], {
        random: seededRandom(props.question)
      });
    }, [
      cm1AsMm,
      cm2AsMm,
      km1AsMm,
      km2AsMm,
      m1AsMm,
      m2AsMm,
      mm1AsMm,
      mm2AsMm,
      props.question,
      translate.units
    ]);

    return (
      <QF10SelectNumbers
        title={
          lessOrGreater === 'less'
            ? translate.instructions.selectLengthsThatAreLessThanNumUnits(
                questionNumber,
                questionNumberUnit
              )
            : translate.instructions.selectLengthsThatAreGreaterThanNumUnits(
                questionNumber,
                questionNumberUnit
              )
        }
        pdfTitle={
          lessOrGreater === 'less'
            ? translate.instructions.circleLengthsThatAreLessThanNumUnits(
                questionNumber,
                questionNumberUnit
              )
            : translate.instructions.circleLengthsThatAreGreaterThanNumUnits(
                questionNumber,
                questionNumberUnit
              )
        }
        testCorrect={
          lessOrGreater === 'less'
            ? statements.filter(it => it.value < questionNumberAsMm).map(it => it.value)
            : statements.filter(it => it.value > questionNumberAsMm).map(it => it.value)
        }
        items={statements.map(statement => ({
          value: statement.value,
          component: statement.string
        }))}
        multiSelect
        questionHeight={1100}
      />
    );
  },
  questionHeight: 1100
});

const Question6 = newQuestionContent({
  uid: 'aob',
  description: 'aob',
  keywords: ['Metric', 'Convert', 'Length', 'Mass', 'Capacity', 'Compare'],
  schema: z
    .object({
      lengthMeasureA: z.enum(['mmToCm', 'mToKm', 'mToCm']),
      lengthMeasureB: z.enum(['mmToCm', 'mToKm', 'mToCm']),
      massMeasure: z.enum(['mgToG', 'kgToG']),
      lengthMeasureNumberA1: z.number().min(0.1).max(9900),
      lengthMeasureNumberA2: z.number().min(0.1).max(9990),
      lengthMeasureNumberB1: z.number().min(0.1).max(9900),
      lengthMeasureNumberB2: z.number().min(0.1).max(9990),
      massMeasureNumber1: z.number().min(0.1).max(9900),
      massMeasureNumber2: z.number().min(0.1).max(9900),
      capacityMeasureNumber1: z.number().int().min(10).max(9900),
      capacityMeasureNumber2: z.number().min(0.1).max(9.9)
    })
    .refine(
      val => val.lengthMeasureA !== val.lengthMeasureB,
      'lengthMeasureA and lengthMeasureB must be different.'
    ),
  simpleGenerator: () => {
    const [lengthMeasureA, lengthMeasureB] = getRandomSubArrayFromArray(
      ['mmToCm', 'mToKm', 'mToCm'] as const,
      2
    );

    const massMeasure = getRandomFromArray(['mgToG', 'kgToG'] as const);

    const getLengthMeasure1 = (lengthMeasure: 'mmToCm' | 'mToKm' | 'mToCm') => {
      switch (lengthMeasure) {
        case 'mmToCm':
          return randomIntegerInclusive(1, 999);
        case 'mToKm': {
          const tensOrHundreds = getRandomFromArray(['tens', 'hundreds']);
          return tensOrHundreds === 'tens'
            ? randomIntegerInclusiveStep(10, 100, 10)
            : randomIntegerInclusiveStep(100, 9900, 100);
        }
        case 'mToCm':
          return randomIntegerInclusive(1, 999) / 10;
      }
    };

    const getLengthMeasure2 = (
      lengthMeasure: 'mmToCm' | 'mToKm' | 'mToCm',
      numberToCompare: number
    ) => {
      switch (lengthMeasure) {
        case 'mmToCm':
          return (
            randomIntegerInclusive(1, 999, {
              // Check that m and cm measures are not equal.
              constraint: x => convertUnitsSuffix(x / 10, 'cm', 'mm').value !== numberToCompare
            }) / 10
          );
        case 'mToKm':
          return (
            randomIntegerInclusive(1, 99, {
              // Check that m and cm measures are not equal.
              constraint: x => convertUnitsSuffix(x / 10, 'km', 'm').value !== numberToCompare
            }) / 10
          );
        case 'mToCm':
          return randomIntegerInclusive(1, 9990, {
            // Check that m and cm measures are not equal.
            constraint: x => convertUnitsSuffix(x, 'cm', 'm').value !== numberToCompare
          });
      }
    };

    const lengthMeasureNumberA1 = getLengthMeasure1(lengthMeasureA);

    const lengthMeasureNumberA2 = getLengthMeasure2(lengthMeasureA, lengthMeasureNumberA1);

    const lengthMeasureNumberB1 = getLengthMeasure1(lengthMeasureB);

    const lengthMeasureNumberB2 = getLengthMeasure2(lengthMeasureB, lengthMeasureNumberB1);

    const massMeasureNumber1 = (() => {
      switch (massMeasure) {
        case 'mgToG': {
          const tensOrHundreds = getRandomFromArray(['tens', 'hundreds']);
          return tensOrHundreds === 'tens'
            ? randomIntegerInclusiveStep(10, 100, 10)
            : randomIntegerInclusiveStep(100, 9900, 100);
        }
        case 'kgToG':
          return randomIntegerInclusive(1, 99) / 10;
      }
    })();

    const massMeasureNumber2 = (() => {
      switch (massMeasure) {
        case 'mgToG':
          return (
            randomIntegerInclusive(1, 99, {
              // Check that mg and g measures are not equal.
              constraint: x => convertUnitsSuffix(x / 10, 'g', 'mg').value !== massMeasureNumber1
            }) / 10
          );
        case 'kgToG': {
          const tensOrHundreds = getRandomFromArray(['tens', 'hundreds']);
          return tensOrHundreds === 'tens'
            ? randomIntegerInclusiveStep(10, 100, 10, {
                // Check that kg and g measures are not equal.
                constraint: x => convertUnitsSuffix(x, 'g', 'kg').value !== massMeasureNumber1
              })
            : randomIntegerInclusiveStep(100, 9900, 100, {
                // Check that kg and g measures are not equal.
                constraint: x => convertUnitsSuffix(x, 'g', 'kg').value !== massMeasureNumber1
              });
        }
      }
    })();

    const tensOrHundredsCapacity = getRandomFromArray(['tens', 'hundreds']);

    const capacityMeasureNumber1 =
      tensOrHundredsCapacity === 'tens'
        ? randomIntegerInclusiveStep(10, 100, 10)
        : randomIntegerInclusiveStep(100, 9900, 100);

    const capacityMeasureNumber2 =
      randomIntegerInclusive(1, 99, {
        // Check that ml and l measures are not equal.
        constraint: x => convertUnitsSuffix(x / 10, 'l', 'ml').value !== capacityMeasureNumber1
      }) / 10;

    return {
      lengthMeasureA,
      lengthMeasureB,
      massMeasure,
      lengthMeasureNumberA1,
      lengthMeasureNumberA2,
      lengthMeasureNumberB1,
      lengthMeasureNumberB2,
      massMeasureNumber1,
      massMeasureNumber2,
      capacityMeasureNumber1,
      capacityMeasureNumber2
    };
  },
  Component: props => {
    const {
      question: {
        lengthMeasureA,
        lengthMeasureB,
        massMeasure,
        lengthMeasureNumberA1,
        lengthMeasureNumberA2,
        lengthMeasureNumberB1,
        lengthMeasureNumberB2,
        massMeasureNumber1,
        massMeasureNumber2,
        capacityMeasureNumber1,
        capacityMeasureNumber2
      },
      translate
    } = props;

    // Randomly order these statements
    const sentences = useMemo(() => {
      const [lengthAUnit1, lengthAUnit2] = (() => {
        switch (lengthMeasureA) {
          case 'mmToCm':
            return ['mm', 'cm'];
          case 'mToKm':
            return ['m', 'km'];
          case 'mToCm':
            return ['m', 'cm'];
        }
      })();

      const lengthMeasureNumberA1Converted = convertUnitsSuffix(
        lengthMeasureNumberA1,
        lengthAUnit1,
        lengthAUnit2
      ).value;

      const lengthLineA = {
        sentence: `${lengthMeasureNumberA1.toLocaleString()} ${lengthAUnit1} <ans /> ${lengthMeasureNumberA2.toLocaleString()} ${lengthAUnit2}`,
        answer: lessThanGreaterThanOrEqualTo(lengthMeasureNumberA1Converted, lengthMeasureNumberA2)
      };

      const [lengthBUnit1, lengthBUnit2] = (() => {
        switch (lengthMeasureB) {
          case 'mmToCm':
            return ['mm', 'cm'];
          case 'mToKm':
            return ['m', 'km'];
          case 'mToCm':
            return ['m', 'cm'];
        }
      })();

      const lengthMeasureNumberB1Converted = convertUnitsSuffix(
        lengthMeasureNumberB1,
        lengthBUnit1,
        lengthBUnit2
      ).value;

      const lengthLineB = {
        sentence: `${lengthMeasureNumberB1.toLocaleString()} ${lengthBUnit1} <ans /> ${lengthMeasureNumberB2.toLocaleString()} ${lengthBUnit2}`,
        answer: lessThanGreaterThanOrEqualTo(lengthMeasureNumberB1Converted, lengthMeasureNumberB2)
      };

      const [massUnit1, massUnit2] = massMeasure === 'mgToG' ? ['mg', 'g'] : ['kg', 'g'];

      const massMeasureNumber1Converted = convertUnitsSuffix(
        massMeasureNumber1,
        massUnit1,
        massUnit2
      ).value;

      const massLine = {
        sentence: `${massMeasureNumber1.toLocaleString()} ${massUnit1} <ans /> ${massMeasureNumber2.toLocaleString()} ${massUnit2}`,
        answer: lessThanGreaterThanOrEqualTo(massMeasureNumber1Converted, massMeasureNumber2)
      };

      const capacityMeasureNumber1Converted = convertUnitsSuffix(
        capacityMeasureNumber1,
        'ml',
        'l'
      ).value;

      const capacityLine = {
        sentence: `${capacityMeasureNumber1.toLocaleString()} ml <ans /> ${capacityMeasureNumber2.toLocaleString()} l`,
        answer: lessThanGreaterThanOrEqualTo(
          capacityMeasureNumber1Converted,
          capacityMeasureNumber2
        )
      };
      return shuffle([lengthLineA, lengthLineB, massLine, capacityLine], {
        random: seededRandom(props.question)
      });
    }, [
      capacityMeasureNumber1,
      capacityMeasureNumber2,
      lengthMeasureA,
      lengthMeasureB,
      lengthMeasureNumberA1,
      lengthMeasureNumberA2,
      lengthMeasureNumberB1,
      lengthMeasureNumberB2,
      massMeasure,
      massMeasureNumber1,
      massMeasureNumber2,
      props.question
    ]);

    return (
      <QF37SentencesDrag
        moveOrCopy="copy"
        title={translate.instructions.dragCardsToCompleteSentences()}
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
        pdfTitle={translate.instructions.useInequalitiesToCompleteSentencesEachSymbolCanBeUsedMoreThanOnce()}
        items={['<', '>', '=']}
        sentences={sentences.map(({ sentence }) => sentence)}
        testCorrect={sentences.map(({ answer }) => [answer])}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6v2 = newQuestionContent({
  uid: 'aob2',
  description: 'aob',
  keywords: ['Metric', 'Convert', 'Length', 'Mass', 'Capacity', 'Compare'],
  schema: z
    .object({
      lengthMeasureA: z.enum(['mmToCm', 'mToKm', 'mToCm']),
      lengthMeasureB: z.enum(['mmToCm', 'mToKm', 'mToCm']),
      massMeasure: z.enum(['mgToG', 'kgToG']),
      lengthMeasureNumberA1: z.number().min(0.1).max(9900),
      lengthMeasureNumberA2: z.number().min(0.1).max(9990),
      lengthMeasureNumberB1: z.number().min(0.1).max(9900),
      lengthMeasureNumberB2: z.number().min(0.1).max(9990),
      massMeasureNumber1: z.number().min(0.1).max(9900),
      massMeasureNumber2: z.number().min(0.1).max(9900),
      capacityMeasureNumber1: z.number().int().min(10).max(9900),
      capacityMeasureNumber2: z.number().min(0.1).max(9.9)
    })
    .refine(
      val => val.lengthMeasureA !== val.lengthMeasureB,
      'lengthMeasureA and lengthMeasureB must be different.'
    ),
  simpleGenerator: () => {
    const [lengthMeasureA, lengthMeasureB] = getRandomSubArrayFromArray(
      ['mmToCm', 'mToKm', 'mToCm'] as const,
      2
    );

    const massMeasure = getRandomFromArray(['mgToG', 'kgToG'] as const);

    const getLengthMeasure1 = (lengthMeasure: 'mmToCm' | 'mToKm' | 'mToCm') => {
      switch (lengthMeasure) {
        case 'mmToCm':
          return randomIntegerInclusive(1, 999);
        case 'mToKm': {
          const tensOrHundreds = getRandomFromArray(['tens', 'hundreds']);
          return tensOrHundreds === 'tens'
            ? randomIntegerInclusiveStep(10, 100, 10)
            : randomIntegerInclusiveStep(100, 9900, 100);
        }
        case 'mToCm':
          return randomIntegerInclusive(1, 999) / 10;
      }
    };

    const getLengthMeasure2 = (
      lengthMeasure: 'mmToCm' | 'mToKm' | 'mToCm',
      numberToCompare: number
    ) => {
      switch (lengthMeasure) {
        case 'mmToCm':
          return (
            randomIntegerInclusive(1, 999, {
              // Check that m and cm measures are not equal.
              constraint: x => convertUnitsSuffix(x / 10, 'cm', 'mm').value !== numberToCompare
            }) / 10
          );
        case 'mToKm':
          return (
            randomIntegerInclusive(1, 99, {
              // Check that m and cm measures are not equal.
              constraint: x => convertUnitsSuffix(x / 10, 'km', 'm').value !== numberToCompare
            }) / 10
          );
        case 'mToCm':
          return randomIntegerInclusive(1, 9990, {
            // Check that m and cm measures are not equal.
            constraint: x => convertUnitsSuffix(x, 'cm', 'm').value !== numberToCompare
          });
      }
    };

    const lengthMeasureNumberA1 = getLengthMeasure1(lengthMeasureA);

    const lengthMeasureNumberA2 = getLengthMeasure2(lengthMeasureA, lengthMeasureNumberA1);

    const lengthMeasureNumberB1 = getLengthMeasure1(lengthMeasureB);

    const lengthMeasureNumberB2 = getLengthMeasure2(lengthMeasureB, lengthMeasureNumberB1);

    const massMeasureNumber1 = (() => {
      switch (massMeasure) {
        case 'mgToG': {
          const tensOrHundreds = getRandomFromArray(['tens', 'hundreds']);
          return tensOrHundreds === 'tens'
            ? randomIntegerInclusiveStep(10, 100, 10)
            : randomIntegerInclusiveStep(100, 9900, 100);
        }
        case 'kgToG':
          return randomIntegerInclusive(1, 99) / 10;
      }
    })();

    const massMeasureNumber2 = (() => {
      switch (massMeasure) {
        case 'mgToG':
          return (
            randomIntegerInclusive(1, 99, {
              // Check that mg and g measures are not equal.
              constraint: x => convertUnitsSuffix(x / 10, 'g', 'mg').value !== massMeasureNumber1
            }) / 10
          );
        case 'kgToG': {
          const tensOrHundreds = getRandomFromArray(['tens', 'hundreds']);
          return tensOrHundreds === 'tens'
            ? randomIntegerInclusiveStep(10, 100, 10, {
                // Check that kg and g measures are not equal.
                constraint: x => convertUnitsSuffix(x, 'g', 'kg').value !== massMeasureNumber1
              })
            : randomIntegerInclusiveStep(100, 9900, 100, {
                // Check that kg and g measures are not equal.
                constraint: x => convertUnitsSuffix(x, 'g', 'kg').value !== massMeasureNumber1
              });
        }
      }
    })();

    const tensOrHundredsCapacity = getRandomFromArray(['tens', 'hundreds']);

    const capacityMeasureNumber1 =
      tensOrHundredsCapacity === 'tens'
        ? randomIntegerInclusiveStep(10, 100, 10)
        : randomIntegerInclusiveStep(100, 9900, 100);

    const capacityMeasureNumber2 =
      randomIntegerInclusive(1, 99, {
        // Check that ml and l measures are not equal.
        constraint: x => convertUnitsSuffix(x / 10, 'l', 'ml').value !== capacityMeasureNumber1
      }) / 10;

    return {
      lengthMeasureA,
      lengthMeasureB,
      massMeasure,
      lengthMeasureNumberA1,
      lengthMeasureNumberA2,
      lengthMeasureNumberB1,
      lengthMeasureNumberB2,
      massMeasureNumber1,
      massMeasureNumber2,
      capacityMeasureNumber1,
      capacityMeasureNumber2
    };
  },
  Component: props => {
    const {
      question: {
        lengthMeasureA,
        lengthMeasureB,
        massMeasure,
        lengthMeasureNumberA1,
        lengthMeasureNumberA2,
        lengthMeasureNumberB1,
        lengthMeasureNumberB2,
        massMeasureNumber1,
        massMeasureNumber2,
        capacityMeasureNumber1,
        capacityMeasureNumber2
      },
      translate
    } = props;

    // Randomly order these statements
    const statements = useMemo(() => {
      const [lengthAUnit1, lengthAUnit2] = (() => {
        switch (lengthMeasureA) {
          case 'mmToCm':
            return ['mm', 'cm'];
          case 'mToKm':
            return ['m', 'km'];
          case 'mToCm':
            return ['m', 'cm'];
        }
      })();

      const lengthMeasureNumberA1Converted = convertUnitsSuffix(
        lengthMeasureNumberA1,
        lengthAUnit1,
        lengthAUnit2
      ).value;

      const lengthLineA = {
        lhsComponent: `${lengthMeasureNumberA1.toLocaleString()} ${lengthAUnit1}`,
        rhsComponent: `${lengthMeasureNumberA2.toLocaleString()} ${lengthAUnit2}`,
        correctAnswer: lessThanGreaterThanOrEqualTo(
          lengthMeasureNumberA1Converted,
          lengthMeasureNumberA2
        )
      };

      const [lengthBUnit1, lengthBUnit2] = (() => {
        switch (lengthMeasureB) {
          case 'mmToCm':
            return ['mm', 'cm'];
          case 'mToKm':
            return ['m', 'km'];
          case 'mToCm':
            return ['m', 'cm'];
        }
      })();

      const lengthMeasureNumberB1Converted = convertUnitsSuffix(
        lengthMeasureNumberB1,
        lengthBUnit1,
        lengthBUnit2
      ).value;

      const lengthLineB = {
        lhsComponent: `${lengthMeasureNumberB1.toLocaleString()} ${lengthBUnit1}`,
        rhsComponent: `${lengthMeasureNumberB2.toLocaleString()} ${lengthBUnit2}`,
        correctAnswer: lessThanGreaterThanOrEqualTo(
          lengthMeasureNumberB1Converted,
          lengthMeasureNumberB2
        )
      };

      const [massUnit1, massUnit2] = massMeasure === 'mgToG' ? ['mg', 'g'] : ['kg', 'g'];

      const massMeasureNumber1Converted = convertUnitsSuffix(
        massMeasureNumber1,
        massUnit1,
        massUnit2
      ).value;

      const massLine = {
        lhsComponent: `${massMeasureNumber1.toLocaleString()} ${massUnit1}`,
        rhsComponent: `${massMeasureNumber2.toLocaleString()} ${massUnit2}`,
        correctAnswer: lessThanGreaterThanOrEqualTo(massMeasureNumber1Converted, massMeasureNumber2)
      };

      const capacityMeasureNumber1Converted = convertUnitsSuffix(
        capacityMeasureNumber1,
        'ml',
        'l'
      ).value;

      const capacityLine = {
        lhsComponent: `${capacityMeasureNumber1.toLocaleString()} ml`,
        rhsComponent: `${capacityMeasureNumber2.toLocaleString()} l`,
        correctAnswer: lessThanGreaterThanOrEqualTo(
          capacityMeasureNumber1Converted,
          capacityMeasureNumber2
        )
      };

      return shuffle([lengthLineA, lengthLineB, massLine, capacityLine], {
        random: seededRandom(props.question)
      });
    }, [
      capacityMeasureNumber1,
      capacityMeasureNumber2,
      lengthMeasureA,
      lengthMeasureB,
      lengthMeasureNumberA1,
      lengthMeasureNumberA2,
      lengthMeasureNumberB1,
      lengthMeasureNumberB2,
      massMeasure,
      massMeasureNumber1,
      massMeasureNumber2,
      props.question
    ]);

    return (
      <QF6DragMatchStatements
        moveOrCopy="copy"
        title={translate.instructions.dragCardsCompleteStatements()}
        actionPanelVariant="end"
        itemVariant="square"
        pdfLayout="itemsHidden"
        pdfTitle={translate.instructions.useInequalitiesToCompleteStatementsEachSymbolCanBeUsedMoreThanOnce()}
        items={['<', '>', '=']}
        statements={statements}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ConvertMetricMeasures',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6v2],
  archivedQuestionTypes: [Question4, Question6]
});
export default SmallStep;
