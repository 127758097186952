import { View, type StyleProp, type ViewStyle, type TextStyle, StyleSheet } from 'react-native';
import Text from '../../typography/Text';
import { colors } from '../../../theme/colors';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { useMinimumSafeWidth } from '../../../theme/scaling';
import { countRange, filledArray, sumNumberArray } from '../../../utils/collections';
import { parseMarkup } from '../../../markup';
import NoKeyboardTextInput from '../../atoms/NoKeyboardTextInput';
import { SetState } from '../../../utils/react';
import { noop } from '../../../utils/flowControl';
import TextStructure from '../../molecules/TextStructure';
import { withStateHOC } from '../../../stateTree';

type UserAnswer = string[];

type Props = {
  /**
   * The user answer
   */
  userAnswer?: UserAnswer;
  /**
   * Set user answer
   */
  setUserAnswer?: SetState<UserAnswer>;
  headers: string[];
  /**
   * What to display in the non-header cells.
   * Outer array chooses a row, inner array chooses a column.
   */
  items: string[][];
  /** Default: 1. */
  borderWidth?: number;
  /** Additional styles for the table itself. Use this to give the table a width and height. */
  style?: StyleProp<ViewStyle>;
  /** Additional styles for all the columns. */
  columnStyle?: StyleProp<ViewStyle>;
  /** Additional styles for all the cells. You can use this to provide max cell widths/heights. */
  cellStyle?: StyleProp<ViewStyle>;
  /** Additional styles for only header cells. You can use this to change the background color. */
  headerCellStyle?: StyleProp<ViewStyle>;
  /** Additional styles for all the text. */
  textStyle?: StyleProp<TextStyle>;
  /** Additional styles for just the headers. */
  headerTextStyle?: StyleProp<TextStyle>;
  /** The width of each text input in characters. */
  inputMaxCharacters?: number;
};

/**
 * Simple table with headers on the left only.
 *
 * Styling:
 * - All cells are one line of text
 * - All rows have the same height:
 *    - there is a fixed minimum height, but they share the table's height evenly if this is larger
 * - The columns may have different widths:
 *    - The left header column will fit its contents
 *    - The remaining columns will fit their contents, but also share any leftover table width.
 * - The table's width can be given via the style prop (e.g. via width or alignSelf: 'stretch')
 *
 * Limitations: not interactive, only holds strings.
 */
export default function TableWithLeftHeaders({
  userAnswer = [],
  setUserAnswer = noop,
  headers,
  items,
  borderWidth = 1,
  style,
  columnStyle,
  cellStyle,
  headerCellStyle,
  textStyle,
  headerTextStyle,
  inputMaxCharacters
}: Props) {
  const displayMode = useContext(DisplayMode);
  const rowHasAnswer = items.map(i => i.some(i => parseMarkup(i).numberOfAns > 0));
  const hasAnswer = rowHasAnswer.filter(i => i === true).length > 0;
  const answerBoxHeight = displayMode === 'digital' ? 96 : 150;
  const answerBoxWidth = displayMode === 'digital' ? 96 : 200;
  const answerPadding = 20;
  const styles = useStyles(borderWidth, hasAnswer, answerBoxWidth + answerPadding, displayMode);
  let ansIndex = -1;

  return (
    <View style={[styles.table, style]}>
      {/* Left headers column */}
      <View style={[styles.column, columnStyle]}>
        {/* Left headers */}
        {headers.map((header, rowIndex) => (
          <View
            key={rowIndex}
            style={[
              styles.cell,
              styles.border,
              styles.headerCell,
              rowIndex !== 0 && styles.overlapTopBorder,
              cellStyle,
              headerCellStyle,
              rowHasAnswer[rowIndex] ? { minHeight: answerBoxHeight + answerPadding } : undefined,
              displayMode === 'pdf' || displayMode === 'markscheme'
                ? { backgroundColor: colors.greys200 }
                : undefined
            ]}
          >
            <Text
              style={[styles.text, styles.headerText, textStyle, headerTextStyle]}
              variant="WRN400"
              numberOfLines={1}
            >
              {header}
            </Text>
          </View>
        ))}
      </View>

      {/* Remaining columns */}
      {countRange(items[0].length).map(columnIndex => (
        <View key={columnIndex} style={[styles.column, columnStyle]}>
          {/* Items */}
          {countRange(headers.length).map(rowIndex => {
            const parsedValue = parseMarkup(items[rowIndex][columnIndex]);
            const ansCount = parsedValue.numberOfAns;
            // Add number of answers
            ansIndex += ansCount;
            // Create scoped copy, otherwise all answer indices will equal final index
            const idx = ansIndex;
            return (
              <View
                key={rowIndex}
                style={[
                  styles.cell,
                  styles.border,
                  rowIndex !== 0 && styles.overlapTopBorder,
                  styles.overlapLeftBorder,
                  cellStyle,
                  rowHasAnswer[rowIndex]
                    ? { minHeight: answerBoxHeight + answerPadding }
                    : undefined
                ]}
              >
                <TextStructure
                  sentence={items[rowIndex][columnIndex]}
                  inputBox={({ index }) => {
                    // Adjust the answer index to be correct for the number of answers
                    // This is done by the following calculation: i-count+(index+1)
                    // i = the total number of answers to date including the answers at this tick
                    // count = number of answers at this tick
                    // index = the index of the answer at this tick (0,1,2)
                    return (
                      <NoKeyboardTextInput
                        key={idx - ansCount + (index + 1)}
                        value={userAnswer[idx - ansCount + (index + 1)]}
                        maxCharacters={inputMaxCharacters}
                        onChangeText={text => {
                          const newState = [...userAnswer];
                          newState[idx - ansCount + (index + 1)] = text;
                          setUserAnswer(newState);
                        }}
                      />
                    );
                  }}
                  textStyle={[styles.text, textStyle]}
                  fractionTextStyle={textStyle}
                />
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
}

export const TableWithLeftHeadersWithState = withStateHOC(TableWithLeftHeaders, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: props => ({
    defaultState: filledArray(
      '',
      sumNumberArray(
        props.items
          .map(item => item.map(str => parseMarkup(str).numberOfAns).reduce((a, b) => a + b))
          .flat(1)
      )
    ),
    testComplete: state => (state ? state.every(it => it !== '') : false)
  })
});

function useStyles(
  borderWidth: number,
  hasAnswer: boolean,
  answerBoxWidth: number,
  displayMode: 'pdf' | 'markscheme' | 'digital'
) {
  // We tweak the styles slightly when displayed in a PDF
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  borderWidth = useMinimumSafeWidth(borderWidth);

  return useMemo(
    () =>
      StyleSheet.create({
        table: { flexDirection: 'row', justifyContent: 'flex-start' },
        column: {
          flexGrow: 1
        },
        cell: {
          minHeight: isPdf ? 60 : 39,
          minWidth: hasAnswer ? answerBoxWidth : undefined,
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: 10,
          borderWidth,
          borderColor: 'transparent'
        },
        border: {
          borderColor: isPdf ? colors.black : colors.prussianBlue
        },
        headerCell: {
          backgroundColor: colors.pacificBlue
        },
        text: {
          fontSize: 32,
          lineHeight: 48
        },
        headerText: { color: isPdf ? 'black' : 'white' },
        overlapTopBorder: { marginTop: -borderWidth },
        overlapLeftBorder: { marginLeft: -borderWidth }
      }),
    [answerBoxWidth, borderWidth, hasAnswer, isPdf]
  );
}
